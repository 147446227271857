import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import { InputField } from "../../../components";
import { UserContext } from "../../../store/context/UserContext";
import { DialogContext } from "../../../store/context/DialogContext";
import VehicleTypeSelectService from "../../../components/Dropdowns/VehicleTypeSelect/VehicleTypeSelectService";
import { ThreeBounce } from "better-react-spinkit";

const Step4 = ({ onChange }) => {
  const [data, setData] = useState({});
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [vehicleTypes, setVehicleTypes] = useState([]);
  // const vehicleTypes = [
  //   {
  //     vehicleType: "Hatchback",
  //     count: "10",
  //     fixed: true,
  //   },
  //   {
  //     vehicleType: "Sedan",
  //     count: "10",
  //     fixed: true,
  //   },
  //   {
  //     vehicleType: "MPV",
  //     count: "10",
  //     fixed: true,
  //   },
  //   {
  //     vehicleType: "Small",
  //     count: "10",
  //     fixed: true,
  //   },
  // ];
  const [options, setOptionData] = useState(vehicleTypes);

  useEffect(() => {
    if (JSON.stringify(data?.options) !== JSON.stringify(options)) {
      setData((prevData) => {
        const newData = { ...prevData, options: options };

        const formattedVehicleTypeCount = options.reduce((acc, option) => {
          if (option.vehicleType && option.count) {
            acc[option.vehicleType.toUpperCase()] = parseInt(option.count, 10);
          }
          return acc;
        }, {});

        onChange({ ...newData, vehicleTypeCount: formattedVehicleTypeCount });
        return newData;
      });
    }
  }, [options, data, onChange]);

  const changeSectionField = (sectionIndex, value, field) => {
    setOptionData((X) =>
      X.map((f, curr) => {
        if (sectionIndex === curr) return { ...f, [field]: value };
        return f;
      })
    );
  };

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await VehicleTypeSelectService.fetch(filterUrl);
      console.log(VehicleTypeSelectService.vehicleTypes);
      setLoading(false);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const updatedVehicleTypes = VehicleTypeSelectService.vehicleTypes.map(
      (row) => {
        const { name: vehicleType, makeModels, pic } = row;
        return {
          vehicleType,
          count: "0",
          fixed: true,
          makeModels,
          pic,
        };
      }
    );

    setVehicleTypes(updatedVehicleTypes);
  }, [VehicleTypeSelectService.vehicleTypes]);

  useEffect(() => {
    fetchData(filterURL);
  }, [filterURL]);

  return (
    <Card className="mt-3" style={{ maxHeight: "320px", minWidth: "420px" }}>
      {loading ? (
        <ThreeBounce size={8} color="#6649f5" />
      ) : (
        <CardBody>
          {/* <div className="mt-3"> */}
          <Row>
            <Col className="col-12">
              <Row className="border-bottom border-dark mb-2">
                <div
                  style={{
                    fontSize: "16px",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="col-sm-6">Vehicle Type</div>
                  <div className="ps-2 col-sm-3 "> Count </div>
                  {/* <i
                className=" bx bxs-plus-circle  text-gray  font-size-24 ps-1 col-sm-1"
                onClick={() => setOptionData([...options, {}])}
              /> */}
                </div>
              </Row>
              <div
                style={{
                  height: "260px",
                  overflow: "scroll",
                  overflowX: "hidden",
                }}
              >
                {vehicleTypes?.map((section, sectionIndex) => (
                  <Row key={sectionIndex} className="pt-2">
                    <div
                      className={`d-flex justify-content-between align-items-center`}
                    >
                      {section?.fixed ? (
                        <p
                          style={{
                            fontSize: "15px",
                            color: "#000000",
                            textAlign: "left",
                            marginLeft: "12px",
                          }}
                          className="col-sm-6"
                        >
                          {section?.vehicleType}
                          <p style={{ fontSize: "small", color: "grey" }}>
                            {section?.makeModels}
                          </p>
                        </p>
                      ) : (
                        <InputField
                          plain
                          noLabel
                          value={section?.vehicleType}
                          onChange={(v) =>
                            changeSectionField(sectionIndex, v, "vehicleType")
                          }
                          style={{
                            fontSize: "15px",
                            textAlign: "left",
                            color: "#000000",
                          }}
                          className=" col-sm-6"
                          type="text"
                        />
                      )}
                      <InputField
                        noLabel
                        outline
                        value={section?.count}
                        onChange={(v) =>
                          changeSectionField(sectionIndex, v, "count")
                        }
                        style={{
                          fontSize: "18px",
                          cursor: 'pointer',
                          marginLeft: section.fixed ? "0" : "4px",
                        }}
                        // label={"Count"}
                        className=" col-sm-4 px-1 py-1"
                        type="number"
                      />{" "}
                      <i
                        style={{
                          cursor: 'pointer'
                        }}
                        className=" text-gray
                                 font-size-24 col-sm-1  dripicons-cross"
                        onClick={() => (
                          setVehicleTypes(
                            vehicleTypes.filter((t, tcurr) => tcurr !== sectionIndex)
                          )
                        )
                        }
                      ></i>
                    </div>
                  </Row>
                ))}
              </div>
              <div className=" d-flex justify-content-start mt-3 ps-0">
                <Button
                  color="light"
                  onClick={() => setVehicleTypes([...vehicleTypes, {
                    vehicleType: "",
                    count: 0,
                    fixed: false,
                  }])}
                  className="d-flex align-items-center"
                >
                  <i className="bx bx-plus  font-size-20 me-2"></i>
                  Add item
                </Button>
              </div>
            </Col>
          </Row>
          {/* </div> */}
        </CardBody>
      )}
    </Card>
  );
};

export default Step4;
