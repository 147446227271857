import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import VehicleService from '../../../pages/Vehicle/VehicleService';
import socketInstance, { SOCKET_EVENT } from '../../../service/socket';
import { doGET } from '../../../util/HttpUtil';
import VehicleFlyout from '../Flyouts/VehicleFlyout';
import SideBarService from '../SideBarService';
import FlyoutButton from '../components/FlyoutButton';
import VehiclePopup from '../popups/VehiclePopup';

const VehicleModule = ({ }) => {

    const [visibleFlyout, setVisibleFlyout] = useState(null) // all/live/disconnected
    const [vehicles, setVehicles] = useState([])
    const [mappedVehicles, setMappedVehicles] = useState([])
    const [movements, setMovements] = useState([])

    const handleVehicleFlyoutButtonClick = useCallback((module) => {
        setVisibleFlyout(module);
    }, []);

    const handleCloseFlyout = useCallback(() => {
        setVisibleFlyout(null);
    }, [])

    const fetchVehicles = async () => {
        try {
            const response = await VehicleService.fetchAll();
            setVehicles(response)
        } catch (error) {
            console.log("Error", error)
        }
    };

    const fetchMovements = async () => {
        try {
            const response = await doGET(`/api/movement/grid?timeSlice=${parseInt(moment().unix() / 100000)}&assetType=VEHICLE`);

            if (response.status === 200) {
                setMovements(response.data.rows)
                const markers = []
                const formattedData = vehicles.map((vehicle, index) => {
                    const matchedRow = response.data.rows.find(row => row.ref_id === vehicle._id);

                    if (matchedRow) {
                        setUpSocketInteractions(vehicle._id)
                        const combinedObject = {
                            ...matchedRow,
                            ...vehicle,
                            kind: "live"
                        };
                        //pushing marker for combinedObject in markers array
                        markers.push({
                            coordinate: { lat: matchedRow?.current?.lat, lng: matchedRow?.current?.lng },
                            popupComponent: <div><VehiclePopup vehicle={combinedObject} /></div>,
                            markerImage: require("./../../../../src/assets/images/car-top.png"),
                            vehicle_id: vehicle._id,
                            // on click of marker we need to select that marker, such that selected on table also 
                            onClick: () => {
                                SideBarService?.setSelectedMarkers({
                                    ...combinedObject,
                                    coordinate: { lat: combinedObject?.current?.lat, lng: combinedObject?.current?.lng },
                                    popupComponent: <div><VehiclePopup vehicle={combinedObject} /></div>,
                                    markerImage: require("./../../../../src/assets/images/car-top.png"),
                                    onClose: () => {
                                        SideBarService?.setSelectedMarkers(null)
                                    }
                                }
                                )
                            }
                        })

                        return combinedObject;
                    }
                    else {
                        return { ...vehicle, kind: "disconnected" }
                    };
                });
                setMappedVehicles(formattedData);
                SideBarService?.setMarkers(markers)
            }

        } catch (error) {
            console.log("Error", error);
        }
    };

    useEffect(() => {
        if (vehicles?.length) {
            fetchMovements()
        }
    }, [vehicles])

    useEffect(() => {
        fetchVehicles()
    }, [VehicleService?.vehicleCache])

    const selectVehicleFromGrid = () => {
        let vehicleFound = false
        vehicles.map((vehicle, index) => {
            const matchedRow = movements.find(row => row.ref_id === SideBarService?.vehicleId);
            if (matchedRow) {
                const combinedObject = {
                    ...matchedRow,
                    ...vehicle,
                    kind: "live"
                };
                if (vehicle?._id == SideBarService?.vehicleId && combinedObject?.current?.lat && combinedObject?.current?.lng) {
                    vehicleFound = true
                    SideBarService?.setSelectedMarkers({
                        ...combinedObject,
                        coordinate: { lat: combinedObject?.current?.lat, lng: combinedObject?.current?.lng },
                        popupComponent: <div><VehiclePopup vehicle={combinedObject} /></div>,
                        markerImage: require("./../../../../src/assets/images/car-top.png"),
                        onClose: () => {
                            SideBarService.setVehicleId(null)
                            SideBarService?.setSelectedMarkers(null)
                        }
                    }
                    )
                }

            }
        })

        if (!vehicleFound) {
            SideBarService?.setSelectedMarkers({ _id: SideBarService?.vehicleId })
        }
    }

    useEffect(() => {
        if (SideBarService?.vehicleId && vehicles?.length && movements?.length) {
            setVisibleFlyout("all")
            selectVehicleFromGrid()
        }
    }, [SideBarService?.vehicleId, vehicles, movements])

    const setUpSocketInteractions = async (vehicle_id) => {
        try {
            let socket = socketInstance.getSocket();
            if (!socket) {
                socket = await socketInstance.initializeSocket();
            }

            socket?.emit('joinRoom', 'vehicle-' + vehicle_id, (response) => {
                const currentSocketVehicleIds = SideBarService.socketVehicleIds;
                currentSocketVehicleIds.push(vehicle_id);
                SideBarService.setSocketVehicleIds(currentSocketVehicleIds);
                console.log("vehicle room join response:", response);
            });

            socket?.off(SOCKET_EVENT.LOCATION_UPDATE)
            socket?.on(SOCKET_EVENT.LOCATION_UPDATE, (data) => {
                //TODO update location of driver

                if (!data) {
                    return;
                }

                if (data.lat && data.lng) {
                    console.log("location update:", data)
                    const prevMarkers = SideBarService?.markers;
                    prevMarkers?.forEach((markerItem, markerIndex) => {
                        if (markerItem?.vehicle_id == data?.vehicle_id) {
                            prevMarkers[markerIndex] = { ...prevMarkers[markerIndex], coordinate: { lat: data.lat, lng: data.lng } }
                        }
                    })
                    SideBarService?.setMarkers(prevMarkers)
                    // TODO : to update vehicle location of vehicle_id
                }

            })

            socket?.on(SOCKET_EVENT.TRIP_STOP, (data) => {
                //TODO update location of driver
                console.log("trip stop :", data);
                if (!data) {
                    return;
                }
                // navigation.navigate(NavName.bookings) 
            })
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div >
            <FlyoutButton
                onClick={() => { handleVehicleFlyoutButtonClick("all") }}
                text="All Vehicles"
                // icon={<FaCarAlt className="me-2 font-size-14" />}
                className="m-2"
                active={visibleFlyout == "all"}
            />
            <FlyoutButton
                onClick={() => { handleVehicleFlyoutButtonClick("live") }}
                text="Live"
                statusColor="#CFFD69"
                // icon={<FaCarAlt className="me-2 font-size-14" />}
                className="m-2"
                active={visibleFlyout == "live"}

            />
            <FlyoutButton
                onClick={() => { handleVehicleFlyoutButtonClick("disconnected") }}
                text="Disconnected"
                statusColor="#ED5151"
                // icon={<FaCarAlt className="me-2 font-size-14" />}
                className="m-2"
                active={visibleFlyout == "disconnected"}
            />

            {visibleFlyout ? <div className="mx-2">
                <VehicleFlyout
                    data={visibleFlyout == "all" ? mappedVehicles : mappedVehicles?.filter(vehicle => vehicle?.kind == visibleFlyout)}
                    onClose={handleCloseFlyout}
                /></div>
                : null}
        </div>
    );
};

export default observer(VehicleModule);
