import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { ThreeBounce } from "better-react-spinkit";
import { UserContext } from "../../store/context/UserContext";
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditDocumentCategories from "./EditDocumentCategory";
import { STRUCTURE } from "./DocumentCategoryConstant";
import { GridColumns } from "./DocumentCategoryGridColumns";
import DocumentCategoryService from "./DocumentCategoryService";

const DocumentCategories = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
    const { userContent } = useContext(UserContext);
    let { edit_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(edit_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/doc-category/create" || edit_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)

    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await DocumentCategoryService.fetch(filterUrl);
            setLoading(false);
        } catch (e) {
            showError(e);
            setLoading(false);
        }

    };

    const fetchCategories = async () => {
        setLoading(true);
        try {
            await DocumentCategoryService.fetchCategories();
            setLoading(false);
        } catch (e) {
            showError(e);
            setLoading(false);
        }

    };

    useEffect(() => {
        const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        fetchCategories();
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }, [])


    useEffect(() => {
        setEditID(edit_id)
    }, [edit_id])

    const onSave = async (e) => {
        e.preventDefault();
        // if (!detailData?.status && !detailData?.remark) {
        //     setHasErr(true)
        //     showError(t("Please enter all required fields"));
        //     return;
        // }
        setLoading(true);
        try {
            if (editID) {
                await DocumentCategoryService.edit(detailData, editID);
                setLoading(false);
                setShowDetailPage(false);
                showMessage(t("Record updated successfully."));
                if (!insidePane) navigate(`/doc-category/edit/${editID}`);
            } else {
                const documenType = await DocumentCategoryService.save(detailData);
                setLoading(false);
                setShowDetailPage(false);
                showMessage(t("Record saved successfully."));
                if (!insidePane) navigate(`/doc-category/edit/${documenType}`);
            }
            fetchData(filterURL);
        } catch (e) {
            showError(e);
            setLoading(false);
        }
        setHasErr(false)
    };
    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await DocumentCategoryService.delete(id);
                setLoading(false);
                setShowDetailPage(false);
                showMessage(t("Deleted"), t("Deleted"));
            } catch (e) {
                showError(e);
                setLoading(false);
            }
        }
    };
    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await DocumentCategoryService.bulkDelete(selectedIDs);
                setLoading(false);
                showMessage(t("Deleted"), t("Deleted Successfully"));
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };



    return (
        <React.Fragment>
            <>
                <Layout
                    small
                    showDetailPage={showDetailPage}
                    backDetailPage={async () => {
                        setShowDetailPage(false);
                        if (!insidePane) navigate("/doc-category");
                        setEditID(null);
                    }}
                     
                    title={t("Document Categories")}
                    filterValues={filterObject}
                    filterStructure={STRUCTURE}
                    onApplyFilter={fetchData}
                    hideSearch
                    onAddClick={() => {
                        if (!insidePane) navigate(`/doc-category/create`);
                        setShowDetailPage(true);
                        setEditID(null);
                    }}
                    insidePane={insidePane}
                    page={DocumentCategoryService.page}
                    rows={DocumentCategoryService.rows}
                    total={DocumentCategoryService.total}
                    fetch={DocumentCategoryService.fetch}
                >
                    <Layout.ActionMenu>
                        <div className="layout-action-menu">
                            <DropdownMenu>
                                <>
                                    {userContent?.rights?.includes(5903) ?
                                    <>
                                        <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                                        <div class="dropdown-divider"></div>
                                        </>
                                        : null}
                                    <DropdownItem>{t("Upload")}</DropdownItem>
                                    <DropdownItem> Download </DropdownItem>
                                </>

                            </DropdownMenu>

                        </div>
                    </Layout.ActionMenu>
                    <Layout.Table>
                        <DataGrid
                           gridLoading={loading}
                            data={DocumentCategoryService.records}
                            total={DocumentCategoryService.total}
                            uiPreference="documentCategory.grid"
                            headers={GridColumns}
                            onSelectChange={(v) => {
                                onSelect(v)
                                setSelectedIDs(v)
                            }}
                            selectedIDs={selectedIDs}
                            page={DocumentCategoryService.page}
                            rowsPerPage={DocumentCategoryService.rowsPerPage}
                            onPaginationChange={DocumentCategoryService.onPaginationChange}
                            renderLastCol={(documentCategory) => {
                                return (
                                    <>
                                        <EditLink
                                            onClick={() => {
                                                if (!insidePane)
                                                    navigate(`/doc-category/edit/${documentCategory?._id}`);
                                                setEditID(documentCategory?._id);
                                                setShowDetailPage(true);

                                            }}
                                        />
                                        {(userContent?.rights?.includes(20) || (userContent?.userType && userContent?.userType === "super-admin"))?
                                            <DeleteLink
                                                onClick={(event) => onDelete(event, documentCategory?._id)}
                                            />
                                            : null}
                                        {!multiMode && insidePane ? (
                                            <CheckButton
                                                onClick={() => {
                                                    onSelect(documentCategory?._id);
                                                }}
                                            />
                                        ) : null}
                                    </>
                                );
                            }}
                        />
                    </Layout.Table>


                    <Layout.DetailPageTitle>
                        {detailData?._id ? t("Edit Document Type") : t("Add Document Type")}
                    </Layout.DetailPageTitle>

                    <Layout.DetailPageBody>
                        <EditDocumentCategories
                            editId={editID}
                            onChange={(v) => {
                                setDetailData(v)
                            }}
                            hasErr={hasErr}
                        />
                    </Layout.DetailPageBody>

                    <Layout.DetailPageFooter>
                        {(editID && (userContent?.rights?.includes(20) || (userContent?.userType && userContent?.userType === "super-admin")))  ?
                            <DeleteButton
                                loading={loading}
                                onClick={(e) => onDelete(e, detailData?._id)} />
                            : null}
                        {(userContent?.rights?.includes(20) || (userContent?.userType && userContent?.userType === "super-admin"))?
                            <SaveButton
                                loading={loading}
                                onClick={onSave} />
                            : null}
                    </Layout.DetailPageFooter>
                </Layout>
            </>
        </React.Fragment>
    );
};
export default observer(DocumentCategories);
