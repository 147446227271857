import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  CheckButton,
  DeleteLink,
  EditLink,
  DeleteButton,
  SaveButton,
  Layout,
} from "../../../components";
import { Row, Col } from "react-bootstrap";
import "../../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { STRUCTURE } from "./TransferGroupConstant";
import TransferGroupService from "./TrasnferGroupService";
import EditTransferGroup from "./EditTransferGroup";

const TransferGroup = (props) => {
  const { t } = useContext(I18nContext);
  let { id } = useParams();
  if (props?.corpId) id = props?.corpId;
  const { showMessage, showConfirm, showError } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(
    props?.corpId ? false : id
  );
  const [data, setData] = useState(id ? {} : { version: 0 });
  const [loading, setLoading] = useState(false);
  const [hasErr, setHasErr] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);

  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await TransferGroupService.fetch(filterUrl, props?.corpId);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    setShowDetailPage(
      window.location.pathname === "/transfergroup/edit" || (id && !props?.corpId)
    );
  }, [id]);

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete TransferGroup ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        await TransferGroupService.delete(id);
      } catch (e) {
        showError(e)
      }
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    setHasErr(true);

    try {
      if (id) {
        await TransferGroupService.edit(data, id);
        showMessage("TransferGroup Updated successfully");
      } else {
        const response = await TransferGroupService.save(data);

        TransferGroupService.records.push(data);


        showMessage("TransferGroup saved successfully");
        if (!props?.insidePane) {
          navigate(`/transfergroup/edit/${response}`);
        }
      }
    } catch (error) {
      showError(error);
    }
  };
  const onRowClick = (id) => {
    if (!props?.insidePane) navigate(`/transfergroup/edit/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };
  const handleRowClick = async (transfergroupId) => {

    if (showDetailPage && transfergroupId !== selected_id && isEditDataChanged) {
      let isProceed = await showConfirm({
        isSaveWarn: true,
        title: t(`Do you want to save the changes ?`),
        description: t("Your changes will be lost if you don't save them"),
      });
      if (isProceed) onRowClick(transfergroupId);
    } else onRowClick(transfergroupId);
  };

  if (loading) return <CardThreeBounce />;
  return (
    <Layout
      showDetailPage={showDetailPage}
       
      title={t("TransferGroup")}
      filterStructure={STRUCTURE}
      onApplyFilter={fetchData}
      backDetailPage={() => {
        setShowDetailPage(false);
        if (!props?.insidePane) navigate("/transfergroup");
        setSelected_id(false);
      }}
      onAddClick={() => {
        if (!props?.insidePane) navigate(`/transfergroup/edit`);
        setShowDetailPage(true);
        setSelected_id(null);
      }}
      page={TransferGroupService.page}
      rows={TransferGroupService.rows}
      total={TransferGroupService.totalRecords}
      onPageChange={async (page, rows) => {
        await TransferGroupService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("Name")}</Th>
            <Th>{t("Country")}</Th>

            <Th>{t("City")}</Th>
            <Th>{t("Transfer Options Count")}</Th>

            <Th>{t("Action")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>


      <Layout.TbodyFull>
        <Tbody>
          {TransferGroupService.records?.map((transfergroup) => (
            <Tr
              key={transfergroup?._id}
              onClick={showDetailPage && (() => handleRowClick(transfergroup?._id))}
            >
              <Td >{transfergroup?.name}</Td>

              <Td>{transfergroup?.country}</Td>
              <Td>{transfergroup?.city}</Td>
              <Td>{transfergroup?.options?.length}</Td>


              <Td>
                <EditLink
                  onClick={() => {
                    if (!props?.insidePane)
                      navigate(`/transfergroup/edit/${transfergroup?._id}`);
                    setSelected_id(transfergroup?._id);
                    setShowDetailPage(true);
                  }}
                />
                <DeleteLink
                  onClick={(event) => {
                    onDelete(event, transfergroup?._id);
                  }}
                />
                {props?.selectMode ? (
                  <CheckButton
                    onClick={() => {
                      props?.onSelect([{ ...transfergroup }]);
                    }}
                  />
                ) : null}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>

      <Layout.DetailPageTitle >
        {/* <Row>
          <Row className="mb-2 py-2 border-bottom-1">
           
            <Col>
              <div>{data?.name}</div>
              <div>{data?.phone}</div>
            </Col>
          </Row>
        </Row> */}
        <h5>TransferGroup</h5>
      </Layout.DetailPageTitle>
      <Layout.DetailPageBody>
        <EditTransferGroup
          insidePane={props?.insidePane}
          onChange={(v) => setIsEditDataChanged(v)}
          id={selected_id}
          data={data}
          setData={setData}
          hasErr={hasErr}
          setHasErr={setHasErr}
          back={() => {
            setShowDetailPage(false);
            if (!props?.insidePane) navigate("/transfergroup");
            setSelected_id(false);
          }}
          fetchData={fetchData}
        />
      </Layout.DetailPageBody>
      <Layout.DetailPageFooter>
        <Row>
          <Col>
            <div
              className="d-flex justify-content-end mt-1"
              style={{ marginTop: "-2.5rem" }}
            >
              {data?._id && <DeleteButton onClick={(e) => onDelete(e, data?._id)} />}
              <SaveButton onClick={onSave} />
            </div>
          </Col>
        </Row>
      </Layout.DetailPageFooter>
    </Layout>
  );
};

export default observer(TransferGroup);
