import { EmployeeSelect } from "../../components";
export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Company Type",
      accessor: "entityType",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.entityType === 1 ? "Vehicle" : dataObj?.entityType === 2 ? "Driver" : dataObj?.entityType === 3 ? "Escord/Guide" : dataObj?.entityType === 4 ? "Self Drive" : "--"}
          </div>
        );
      },
      show: true,
    },
    // {
    //   title: "Employee",
    //   accessor: "employee_id",
    //   show: true,
    // },
    {
      title: "Status",
      accessor: "status",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.status === 0
              ? "In Progress"
              : dataObj?.status === 1
                ? "Approved"
                : dataObj?.status === 2
                  ? "Rejected"
                  : "Unknown"}
          </div>
        );
      },
      show: true,
    },
    {
      title: "Employee",
      accessor: "employee_id",
      renderer: (dataObj) => {
        return <div>
          <EmployeeSelect
            asLabel
            value={dataObj?.employee_id}
            onChange={(v) => { }}
            className=""
          />
        </div>;
      },
      show: true,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
