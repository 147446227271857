import React from 'react'

function FeeSummaryRow({ title, value ,type=""}) {
    return (
        <tr style={{ lineHeight: "0.5" }}>
            <td>
                <strong>{title}</strong>
            </td>
            <td className="w-100 d-flex justify-content-end">
                <strong className={type?`text-${type}`:''}>
                    {value}
                </strong>
            </td>
        </tr>
    )
}

export default FeeSummaryRow