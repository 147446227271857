import React, { useState } from 'react';
import "./styles/SelectOption.scss";
import { Card, CardBody } from 'reactstrap';


function SelectOption({ options, multi, onSelect, label, style }) {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleOptionClick = (option) => {
        let options = [];
        if (multi) {
            if (selectedOptions.includes(option)) {
                options = selectedOptions.filter((item) => item !== option)

            } else {
                options = [...selectedOptions, option];
            }
        } else {
            options = [option];
        }

        setSelectedOptions(options)
        onSelect(options);
    };

    return (
        <div className='selectOptionWrapper' style={{
            ...style
        }}>

            <Card>
                <CardBody>
                    <div className='optionsWrapper'>
                        <div className='labelWrapper'>
                            <span className='label'>{label}</span>
                        </div>
                        {options.map((option) => (
                            <div
                                key={option}
                                onClick={() => handleOptionClick(option)}
                                className={`option ${selectedOptions.includes(option) ? 'selected' : ''}`}
                                style={{ display: 'flex', alignItems: 'flex-start', gap: '6px' }}
                            >
                                <i className={`optionTickImage bx bx-check font-size-22  mr-auto`}></i>
                                <span className={`optionText ${selectedOptions.includes(option) ? 'selected' : ''}`} style={{
                                    alignSelf: 'flex-start',
                                    fontFamily: 'Open Sans, sans-serif',
                                    fontSize: '14px', lineHeight: '14px', fontWeight: 400, letterSpacing: '-0.02em'
                                }}>
                                    {option}</span>
                            </div>
                        ))}
                    </div>
                </CardBody>
            </Card>
        </div >
    );
}

export default SelectOption;
