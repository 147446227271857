import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET } from "../../../util/HttpUtil";
class FundACSelect {
    fundAC = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filterData) {
        if (this.fundAC.length > 0)
            return;
        try {
            const response = await doGET(ENDPOINTS.getAllFundAccounts);
            if (response.status === 200) {
                this.fundAC = response.data.rows.map((v) => { return { label: `${v?.details?.bankName} - ${v?.details?.identificationNumber}`, value: v?._id } });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}
const FundACSelectService = new FundACSelect();
export default FundACSelectService;