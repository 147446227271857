import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { DropdownItem, DropdownMenu, Row, Col } from "reactstrap";
import {
  itemBarSelectedStyle,
  CheckButton,
  EditLink,
  DeleteLink,
  Layout,
  SaveButton,
  DeleteButton,
  UploadLink,
  timestampToyyyy_mm_ddThh_mm,
} from "../../components";
import { CSTRUCTURE } from "./NewFuelConstant";
import { Button } from "react-bootstrap";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import EditNewFuel from "./EditNewFuel";
import NewFuelService from "./NewFuelService";
import DownloadByTemplate from "../../components/DownloadByTemplate/DownloadByTemplate";
import TimeSheetPopup from "../Fuel/TimeSheetPopup";
import toastr from "toastr";

const NewFuel = (props) => {
  const { t } = useContext(I18nContext);
  const { id } = useParams();
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [data, setData] = useState({
    name: "",
    isd: "",
    isoCode: "",
    currency: "",
  });
  const [showDetailPage, setShowDetailPage] = useState(id);
  const [hasErr, setHasErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);
  const [modalId, setModalId] = useState("");
  const [modal, setModal] = useState(false);
  const [modalTravelled, setModalTravelled] = useState(0);
  const [filterVals, setFilterVals] = useState("");
  const [showDownload, setShowDownload] = useState(false);
  const [structure, setStructure] = useState([]);

  const gotData = async (id) => {
    try {
      if (id) {
        const dta = await NewFuelService.get(id);
        console.log({ ...data, ...dta });
        setData({ ...data, ...dta });
      }
    } catch (e) {
      showError("Unauthorised access");
    }
  };

  if (NewFuelService.error) toastr.error("error", NewFuelService.error);
  const toggleDownload = () => {
    setShowDownload(!showDownload);
  };


  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await NewFuelService.fetch(filterUrl);
    } catch (e) {
      showError("error while getting data");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    setHasErr(true);
    setLoading(true);
    // if (!data.name) return;
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    try {
      await NewFuelService.save(formData);
      showMessage("Fuel saved successfully");
      if (!id) {
        setShowDetailPage(false);
        navigate("/newfuel");
      }
      else {
        gotData(id);
      }
      fetchData();
    } catch (error) {
      showError("error", JSON.parse(`${error.message}`).message);
    }
    setLoading(true);

  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete Fuel ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        await NewFuelService.delete(id);
        showMessage("Deleted", "Deleted successfully");
        navigate("/newfuel");
      } catch (e) {
        showError("error", JSON.parse(`${e.message}`).message);
      }
    }
  };

  const importCountries = async () => {
    try {
      await NewFuelService.importCountries();
      showMessage("Success");
    } catch (e) {
      showError("error", JSON.parse(`${e.message}`).customMessage);
      console.log(JSON.parse(`${e.message}`).customMessage);
    }
  };

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    gotData(selected_id);
    setShowDetailPage(window.location.pathname === "/newfuel/edit" || id);
  }, [id, selected_id]);

  const onRowClick = (id) => {
    if (!props?.insidePane) navigate(`/newfuel/edit/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };

  return (
    <>


      {props?.popup ? (
        ""
      ) : (
        <DownloadByTemplate
          filter={filterVals}
          download={NewFuelService.downloadByTemplate}
          kind={16}
          setShowDownload={setShowDownload}
          showDownload={showDownload}
          filterStructure={CSTRUCTURE}
          onApplyFilter={NewFuelService.setDownloadFilter}
        />
      )}
      {modalId && (
        <TimeSheetPopup
          id={modalId}
          totalTravelled={modalTravelled}
          modal={modal}
          setModal={setModal}
        />
      )}
      <Layout
        showDetailPage={showDetailPage}
         
        title={t("Fuel")}
        filterStructure={CSTRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!props?.insidePane) navigate(`/newfuel/edit`);
          setSelected_id(null);
          setShowDetailPage(true);
          // console.log("yhgh");
        }}
        backDetailPage={() => {
          setShowDetailPage(false);
          if (!props?.insidePane) navigate("/newfuel");
          setSelected_id(false);
        }}
        page={NewFuelService.page}
        rows={NewFuelService.rows}
        total={NewFuelService.totalRecords}
        onPageChange={async (page, rows) => {
          await NewFuelService.onPaginationChange(page, rows);
        }}
      >
        {props?.popup ? (
          <></>
        ) : (
          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                {!showDetailPage && (
                  <>
                    <DropdownItem onClick={toggleDownload}>Download</DropdownItem>
                  </>
                )}
              </DropdownMenu>
            </div>
          </Layout.ActionMenu>
        )}

        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>{t("Reg No.")}</Th>
              <Th>{t("Vehicle Owner")} </Th>
              <Th>{t("Vehicle Type")} </Th>
              <Th>{t("Driver Name")} </Th>
              <Th>{t("Time")}</Th>
              <Th>{t("Reciept")}</Th>
              <Th>{t("Fuel")}</Th>
              <Th>{t("Cost")}</Th>
              <Th>{t("KMs")}</Th>
              <Th>{t("Mileage")}</Th>

              <Th className="float-end">{t("Action")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {NewFuelService.records?.map((fuel) => (
              <Tr
                key={fuel?._id}
                style={id === fuel?._id ? itemBarSelectedStyle : {}}
                onClick={
                  showDetailPage &&
                  (async () => {
                    if (
                      showDetailPage &&
                      fuel?._id !== selected_id &&
                      isEditDataChanged
                    ) {
                      let isProceed = await showConfirm({
                        isSaveWarn: true,
                        title: t(`Do you want to save the changes ?`),
                        description: t(
                          "Your changes will be lost if you don't save them"
                        ),
                      });
                      if (isProceed) onRowClick(fuel?._id);
                    } else onRowClick(fuel?._id);
                  })
                }
              >
                <Td>
                  {fuel?.vehicleReg}
                </Td>
                <Td>
                  {fuel?.vehicleOwner}
                </Td>
                <Td>
                  {fuel?.vehicleType}
                </Td>
                <Td>
                  {fuel?.driverName}
                </Td>

                <Td>{timestampToyyyy_mm_ddThh_mm(fuel?.time)}</Td>
                <Td>{fuel?.receiptNo} </Td>

                <Td>
                  {fuel?.qty} {fuel?.qtyType}

                  {/* <br />
                {fuel?.cash === true ? "cash" :"other" } */}
                </Td>
                <Td>
                  {fuel?.currency} {fuel?.cost}

                  <br />
                  {fuel?.fueltype}
                </Td>
                <Td>{fuel?.kms}</Td>
                <Td>{fuel?.mileage}</Td>

                {/* <Td>
                <img src={country.flag} alt="..." width="60px" />
              </Td> */}

                <Td className="float-end">
                  {/* upload link */}
                  {/* <UploadLink
                  onClick={() => {
                    if (!props?.insidePane) navigate(`/fuel/edit/${fuel?._id}`);
                    setSelected_id(fuel?._id);
                    setShowDetailPage(true);
                  }}
                /> */}
                  {/* country and timezone */}
                  <i
                    role={"button"}
                    onClick={() => {
                      setModalId(fuel._id);
                      setModal(true);
                      setModalTravelled(fuel.distance);
                    }}
                    className="fa fa-calendar"
                    aria-hidden="true"
                  ></i>
                  <EditLink
                    onClick={() => {
                      if (!props?.insidePane) navigate(`/newfuel/edit/${fuel?._id}`);
                      setSelected_id(fuel?._id);
                      setShowDetailPage(true);
                    }}
                  />

                  <DeleteLink
                    onClick={(event) => {
                      onDelete(event, fuel?._id);
                    }}
                  />
                  {props?.selectMode ? (
                    <CheckButton
                      onClick={() => {
                        props?.onSelect([{ ...fuel }]);
                      }}
                    />
                  ) : null}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>

        <Layout.DetailPageTitle>
          <Row>
            <h5>{selected_id ? ' Edit Fuel' : 'Create Fuel'}</h5>
          </Row>
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditNewFuel
            insidePane={props?.insidePane}
            onChange={(v) => setIsEditDataChanged(v)}
            id={selected_id}
            back={() => {
              setShowDetailPage(false);
              if (!props?.insidePane) navigate("/newfuel");
              setSelected_id(false);
            }}
            fetchData={fetchData}
            data={data}
            setData={setData}
            hasErr={hasErr}
            setHasErr={setHasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          <Row>
            <Col>
              <div
                className="d-flex justify-content-end mt-1"
                style={{ marginTop: "-2.5rem" }}
              >
                {data?._id && (
                  <>
                    <div className="d-flex justify-content-end">
                      <DeleteButton
                        onClick={(event) => {
                          onDelete(event, data?._id);
                        }}
                      />
                    </div>
                  </>
                )}
                <SaveButton onClick={submitHandler} />
              </div>
            </Col>
          </Row>
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};

export default observer(NewFuel);
