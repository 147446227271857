import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { CrossButton, InputField } from '../../../components'
import { I18nContext } from '../../../store/context/i18nContext';


const ClientStep1 = ({ data, setData }) => {
    const { t } = useContext(I18nContext);
    const { contactPersons = [{}] } = data

    const [contactData, setContactData] = useState(contactPersons)
    useEffect(() => {
        if (JSON.stringify(data?.contactPersons) !== JSON.stringify(contactData))
            setData({ ...data, contactPersons: contactData });
    }, [contactData]);

    console.log(contactData, "error")
    return (
        <>
            <Row className=''>
                <Col className=' px-4'>
                    <InputField
                        label={t("Company Name")}
                        className=" m-auto px-2"
                        value={data?.companyName}
                        onChange={(v) => setData({
                            ...data,
                            companyName: v
                        })}
                        placeholder="Enter Company Name"
                        type="text"
                        error={!data?.companyName ? "Please enter Company Name" : ""}

                        showErr={true}
                    />
                </Col>
            </Row>
            <Row className=' mt-3'>
                <div className=' d-flex flex-column'>
                    <div className=' m-auto '>
                        <Button onClick={() => setContactData([...contactData, {}])} style={{ color: "#DF6463", backgroundColor: "white", border: "1px solid black", borderRadius: "8px" }}> <span className=' fa-1x fw-bolder' style={{}}>+</span> Add Contact</Button>
                    </div>

                    {contactData?.map((contact, i) => {
                        return (

                            <ContactRow index={i} value={contact} contactData={contactData} setContactData={setContactData} />


                        )
                    })}


                </div>
            </Row>

        </>
    )
}


const ContactRow = (props) => {
    const { t } = useContext(I18nContext);
    const { value, onChange, contactData, setContactData, index } = props
    return (
        <>
            <Row className='mt-4 px-4'>
                <Col className='col-sm-3 px-2'>
                    <InputField
                        label={t("Contact Person")}

                        value={value?.name}
                        onChange={(v) =>
                            setContactData(
                                contactData?.map((f, x) => {
                                    if (index === x) return { ...value, name: v };
                                    return f;
                                })
                            )
                        }
                        placeholder="Enter Contact Person Name"
                        type="text"


                        showErr={true}
                    />
                </Col>
                <Col className='col-sm-3 px-2'>

                    <InputField
                        label={t("Email Address")}
                        value={value?.email}
                        onChange={(v) =>
                            setContactData(
                                contactData?.map((f, x) => {
                                    if (index === x) return { ...value, email: v };
                                    return f;
                                })
                            )
                        }
                        placeholder="Email Address"
                        type="text"


                        showErr={true}
                    />
                </Col>
                <Col className='col-sm-3 px-2'>

                    <InputField
                        label={t("Phone Number")}
                        value={value?.phone}
                        onChange={(v) =>
                            setContactData(
                                contactData?.map((f, x) => {
                                    if (index === x) return { ...value, phone: v };
                                    return f;
                                })
                            )
                        }
                        placeholder="Phone Number"
                        type="text"

                        showErr={true}
                    />
                </Col>
                <Col className='col-sm-3'>
                    <div className='cross'>
                        <CrossButton
                            onClick={() => {
                                setContactData(() => {
                                    let newData = contactData?.slice();
                                    newData.splice(index, 1);
                                    return newData;
                                });
                            }}

                        />
                    </div>
                </Col>
            </Row>

        </>
    )

}

export default ClientStep1