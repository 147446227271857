import React, { useEffect, useMemo, useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    UncontrolledDropdown,
} from 'reactstrap';
import './styles.css'



function TripTypeDropdown({ data, value, isDisabled = false, onChange = () => { } }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedTripType, setSelectedTripType] = useState(null)

    useEffect(() => {
        setSelectedTripType(data?.find(trip => trip?.value === value))
    }, [data, value])

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <div className="trip-type-menu">
            <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                <DropdownToggle
                    caret
                    outline
                    color="#ced4da"
                    className="mx-2 d-flex w-100 align-items-center"
                    style={{ border: 0, borderBottom: '1.5px solid #b1b6bc', borderRadius: 0, marginTop: 4 }}
                >
                    <div className='d-flex flex-1 '>
                        {selectedTripType?.label}
                    </div>
                </DropdownToggle>

                <DropdownMenu className='shadow'>
                    {data?.map((trip, index) => (

                        <DropdownItem key={index} onClick={() => onChange(trip)}>
                            {trip?.label}
                        </DropdownItem>
                    ))}

                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
}



export default TripTypeDropdown;
