import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { CitySelect, CountrySelect, InputField, StateSelect } from '../../../components'

const ClientStep3 = ({ data, setData }) => {
    const { offices = [] } = data

    const [officeData, setOfficeData] = useState(offices)

    useEffect(() => {
        if (JSON.stringify(data?.offices) !== JSON.stringify(officeData))
            setData({ ...data, offices: officeData });
    }, [officeData]);
    return (
        <>
            {officeData?.map((office, i) => {
                return (
                    <>
                        <Row className='mt-4'>
                            <Col className=' px-4'>
                                <CountrySelect
                                    className=" m-auto px-2"
                                    value={office?.country}
                                    required={true}
                                    onChange={(v) =>
                                        setOfficeData(
                                            officeData?.map((f, x) => {
                                                if (i === x) return { ...office, country: v.value };
                                                return f;
                                            })
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col className=' px-4'>
                                <StateSelect
                                    value={office?.state}
                                    className=" m-auto px-2"
                                    onChange={(v) =>
                                        setOfficeData(
                                            officeData?.map((f, x) => {
                                                if (i === x) return { ...office, state: v.value };
                                                return f;
                                            })
                                        )
                                    }
                                    country={office?.country}

                                />
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col className=' px-4'>
                                <CitySelect
                                    value={office?.city}
                                    state={office?.state}
                                    className=" m-auto px-2"
                                    country={office?.country}
                                    onChange={(v) =>
                                        setOfficeData(
                                            officeData?.map((f, x) => {
                                                if (i === x) return { ...office, city: v?.value };
                                                return f;
                                            })
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className=' mt-4'>
                            <Col className=' px-4'>
                                <InputField
                                    label={"Address"}
                                    className=" m-auto px-2"
                                    value={office?.addressLine}
                                    onChange={(v) =>
                                        setOfficeData(
                                            officeData?.map((f, x) => {
                                                if (i === x) return { ...office, addressLine: v };
                                                return f;
                                            })
                                        )
                                    }
                                    placeholder="Enter Address Line"
                                    type="text"

                                    showErr={true}
                                />
                            </Col>
                        </Row></>
                )
            })}

            <div className=' mt-3 float-end px-4'>
                <Button onClick={() => setOfficeData([...officeData, {}])} style={{ backgroundColor: "#DF6463", border: "none", borderRadius: "8px" }}> <span className=' fa-1x fw-bolder'>+</span> Add Office</Button>
            </div>

        </>
    )
}

export default ClientStep3