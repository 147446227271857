import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu, Alert } from "reactstrap";
import { Button } from 'react-bootstrap'
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EntityService from "../Company/CompanyService";
import { STRUCTURE } from "./ClientConstant";
import { GridColumns } from "./ClientGridColumns";
import Clientservice from "./ClientService";
import EditClients from "./EditClients";
import OfficeService from "../Office/OfficeService";
import { UserContext } from "../../store/context/UserContext";
import SupervisorService from "../Supervisor/SupervisorService";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import TagPicker from "../../components/TagSelector/TagPicker";
import CustomOperations from "../../components/CustomField/CustomOperations";

const Clients = ({ hideTitleBar, value, insidePane, multiMode, onSelect = () => { } }) => {

  let { client_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { userContent } = useContext(UserContext);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(client_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/client/create" || client_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)
  const [showCustomizationOptions, setShowCustomizationOptions] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await Clientservice.fetch(filterUrl);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      showError(e);
    }

  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])


  useEffect(() => {
    setEditID(client_id)
  }, [client_id])

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.name ) {
      setHasErr(true)
      showError(t("Please Enter Client Name "));
      return;
    }else if (!editID && !detailData?.entityData?.name) {
      setHasErr(true)
      showError(t("Please Enter Company Name"));
      return;
    }else if (!editID && !detailData?.officeData?.name) {
      setHasErr(true)
      showError(t("Please Enter Office Name"));
      return;
    }
    setLoading(true);
    try {
      if (editID) {
        await Clientservice.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Client updated successfully."));
      } else {
        const clientID = await Clientservice.save(detailData);

        let clientOffice = null;
        if (detailData?.officeData?.name) {
          clientOffice = await OfficeService.save({ data: { ...detailData?.officeData, org_id: clientID }, org_id: clientID })
        }

        if (detailData?.entityData?.name) {
          EntityService.save({ ...detailData?.entityData, client_id: clientID, office_id: clientOffice })
        }

        if (detailData?.supervisorData?.name && detailData?.supervisorData?.email && detailData?.supervisorData?.phoneNo && detailData?.supervisorData?.salutation) {
          SupervisorService.save({
            data: {
              ...detailData?.supervisorData
              , adminOf: clientID
            }, corp_id: clientID
          })
        }

        setLoading(false);
        showMessage(t("Client saved successfully."));
        if (!insidePane) navigate(`/client/edit/${clientID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
    setHasErr(false)
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await Clientservice.delete(id);
        setShowDetailPage(false);
        setLoading(false);
        navigate(`/client`);
        showMessage(t("Client Deleted Successfully"), t("Deleted"));
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await Clientservice.bulkDelete(selectedIDs);
        setLoading(false);
        showMessage(t("Deleted"), t("Deleted Successfully"));
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback((client) => {
    return (
      <>
        {userContent?.rights?.includes(1302) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/client/edit/${client?._id}`);
              setEditID(client?._id);
              setShowDetailPage(true);
            }}
          />
        }
        {userContent?.rights?.includes(1303) ?
          <DeleteLink
            onClick={(event) => onDelete(event, client?._id)}
          />
          : null}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(client);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])



  const toggleData = {
    module: [
      { label: t("Clients"), value: "client" },
      { label: t("Client Group"), value: "client-pool" },
    ],
    toggleValue: "client"
  }

  const markCompletedFunc = async () => {
    try {
      await SetupProgressService?.edit("clients");
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch()
    }
  }
    , [SetupProgressService?.records])
  return (
    <React.Fragment>
      <>
        <Layout
          toggleData={toggleData}
          showToggle
          compact
          onToggle={(selectedValue) => {
            if (selectedValue === "client") {
              navigate(`/client`);
            } else if (selectedValue === "client-pool") {
              navigate(`/client-pool`);
            }
          }}
          hideAdd={!userContent?.rights?.includes(1301)}
          showDetailPage={showDetailPage}
          backDetailPage={() => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/client");
            setEditID(null);
          }}
          hideTitleBar={hideTitleBar}
           
          // title={t("Clients")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/client/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={Clientservice.page}
          rows={Clientservice.rows}
          total={Clientservice.total}
          fetch={Clientservice.fetch}
        >

          <Layout.OnAlert>
            {!SetupProgressService?.records?.setUpStatus?.['clients'] ? <Alert color="danger" role="alert">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  Please list all your regular clients here.
                </div>
                <div>
                  <Button onClick={markCompletedFunc}>Mark Complete</Button>
                </div>
              </div>          </Alert> : <></>}
          </Layout.OnAlert>
          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  {userContent?.rights?.includes(1303) ?
                  <>
                    <DropdownItem onClick={() => { onBulkDelete() }}>{t("Bulk Delete")}</DropdownItem>
                    <div class="dropdown-divider"></div>
                    </>
                    : null}
                  < DropdownItem > {t("Upload")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem> {t("Download")} </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={() => { setShowCustomizationOptions(true) }}>{t("Customise")} </DropdownItem>
                </>
              </DropdownMenu>
            </div>
          </Layout.ActionMenu>
          <Layout.Table>
            <DataGrid
              gridLoading={loading}
              data={Clientservice.records}
              total={Clientservice.total}
              uiPreference="Client.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={(v) => {
                onSelect(v)
                setSelectedIDs(v)
              }}
              page={Clientservice.page}
              rowsPerPage={Clientservice.rowsPerPage}
              onPaginationChange={Clientservice.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </Layout.Table>
          <Layout.DetailPageTitle>
            <div className="d-flex align-items-center">
              <div>{detailData?._id ? t("Edit Client ") : t("Add Client")}</div>
              <TagPicker
                className="ms-2"
                kind={"client"}
                onChange={(v) => setDetailData({ ...detailData, tags: v })}
                value={detailData?.tags}
              />
            </div>
          </Layout.DetailPageTitle>
          <Layout.DetailPageBody>
            <EditClients
              editId={editID}
              onChange={(v) => {
                setDetailData(v)
              }}
              hasErr={hasErr}
            />
          </Layout.DetailPageBody>

          <Layout.DetailPageFooter>
            {detailData?._id ? (
              <AuditLogDialog module="Client" id={detailData?._id} />
            ) : null

            }
            {editID && userContent?.rights?.includes(1303) && detailData?._id ? (
              <DeleteButton
                loading={loading}
                onClick={(e) => onDelete(e, detailData?._id)} />
            ) : null}
            {userContent?.rights?.includes(1302) ?
              <SaveButton
                loading={loading}
                onClick={onSave} />
              : null}
          </Layout.DetailPageFooter>
        </Layout>
        {showCustomizationOptions ? <CustomOperations
          isModal
          module="client"
          isOpen={showCustomizationOptions}
          toggleModal={() => { setShowCustomizationOptions(false) }}
        /> : null
        }
      </>
    </React.Fragment >
  );
};
export default observer(Clients);
