
import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

import { Link } from "react-router-dom";
import { FaCheck, FaLock } from "react-icons/fa";
import "./style.css"
import { Breadcrumbs } from "../../components";
import DoughnutChart from "../../components/DoughnutChartjs";
import { doGET } from "../../util/HttpUtil";
import SetupProgressService from "./SetUpProgressService";

const LENGTH_TO_SHOW = 4;

const StepsData = [
    {
        title: "Clients",
        description: " Populate My Clients Details",
        completed: false,
        redirect: "/client",
        key: 'clients'
    },
    {
        title: "Suppliers",
        description: " Populate My Suppliers Details",
        completed: false,
        redirect: "/supplier",
        key: 'supplier'
    },
    {
        title: "Vehicle",
        description: " Populate My Vehicle Details",
        completed: false,
        redirect: "/vehicle",
        key: 'vehicle'
    },
    {
        title: "Drivers ",
        description: " Populate My Drivers Details",
        completed: false,
        redirect: "/employee",
        key: 'employee'
    },

    {
        title: "Rate Area",
        description: " Populate My Rate Area Details",
        completed: false,
        redirect: "/rate-area",
        key: 'rateArea'
    },

    {
        title: "Rate",
        description: " Populate My Rate Details",
        completed: false,
        redirect: "/rate",
        key: 'rate'
    },

    {
        title: "Garage ",
        description: " Populate My Garage Details",
        completed: false,
        redirect: "/garage",
        key: 'garage'
    },

    {
        title: "Invoice Configuration",
        description: " Populate My Invoice Configuration Details",
        completed: false,
        redirect: "/invoice",
        key: 'invoice'
    },

    // {
    //     title: "Client Groups",
    //     description: " Populate My Client Groups Details",
    //     completed: false,
    //     redirect: "/client-pool",
    //     key: 'clientPool'
    // },
    {
        title: "Payment GateWay",
        description: " Populate My Payment GateWay Details",
        completed: false,
        redirect: "/payment-gateway-account",
        key: 'paymentGateway'
    },

    // {
    //     title: "Whatsapp White labelling",
    //     description: " Populate My Whatsapp White labelling Details",
    //     completed: false,
    //     redirect: "/whatsAppWhiteLabelling",
    //     key: 'whatsAppWhiteLabelling'
    // },

    // {
    //     title: "SMS white labelling",
    //     description: " Populate My SMS white labelling Details",
    //     completed: false,
    //     redirect: "/smsWhiteLabelling",
    //     key: 'smsWhiteLabelling'
    // },

];


const SetupProgress = () => {
    const [visibleSteps, setVisibleSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(0)
    const [stepsData, setStepsData] = useState(StepsData)

    const [dountData, setDountData] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: ["#556ee6", "#ebeff2"],
                hoverBackgroundColor: ["#556ee6", "#ebeff2"],
                hoverBorderColor: "#fff",
            },
        ],
    })


    const updatePrgressData = () => {
        const setUpStatus = SetupProgressService?.records?.setUpStatus
        const setUpStatusArray = { ...setUpStatus };
        const keys = Object.keys(setUpStatusArray)
        if (Object.keys(setUpStatusArray).length > 0) {
            const steps = []
            stepsData?.forEach((currentStatus, index) => {
                if (keys?.includes(currentStatus.key) && setUpStatusArray[currentStatus.key]) {
                    currentStatus.completed = true;
                }
                steps?.push(currentStatus)
            })
            setStepsData(steps)
        }
    }

    useEffect(() => {
        SetupProgressService.fetch()
    }, [])

    useEffect(() => {
        updatePrgressData()
    }, [SetupProgressService?.records])


    useEffect(() => {
        let complete = 0;
        for (let step of stepsData) {
            if (step?.completed === true) complete++;
        }

        setDountData(prev => ({
            ...prev,
            datasets: [
                {
                    ...prev.datasets[0],
                    data: [complete, stepsData?.length - complete],
                },
            ],
        }));

    }, [stepsData])

    useEffect(() => {
        const sortedSteps = StepsData.sort((a, b) =>
            a.completed === b.completed ? 0 : a.completed ? -1 : 1
        );
        setVisibleSteps(sortedSteps);
    }, []);

    if (dountData?.datasets[0]?.data?.[0] == (dountData?.datasets[0]?.data?.[1] + dountData?.datasets[0]?.data?.[0]))
        return null;

    return <React.Fragment>
        <div className="d-flex">
            <Container fluid>
                <Card className="d-flex flex-column flex-sm-row row">
                    <div className="col-12 col-sm-2 my-2">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <DoughnutChart data={dountData} />
                            <div className="mt-3 font-size-16">
                                Setup Completion - {((dountData?.datasets[0]?.data?.[0] / (dountData?.datasets[0]?.data?.[1] + dountData?.datasets[0]?.data?.[0])) * 100).toFixed(0)}%
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-sm-flex col-10 row mt-2">
                        <Col lg="12">
                            <div>
                                <div className="hori-timeline">
                                    <div className="owl-carousel  navs-carousel " id="timeline-carousel">
                                        {stepsData?.slice(currentStep, currentStep + LENGTH_TO_SHOW).map((item, index) => {
                                            const actualIndex = currentStep + index;
                                            return (
                                                <div className="col-3 justify-content-center align-items-center " style={{ display: "inline-table" }} key={actualIndex}>
                                                    <div className="  " style={{
                                                        display: 'flex',
                                                        flexDirection: "column",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                        <div className="mb-3 d-flex justify-content-between align-items-center" style={{
                                                            fontSize: "16px",
                                                            fontWeight: 800,
                                                            textAlign: 'center',
                                                            margin: 'auto'
                                                        }}>
                                                            <div>
                                                                <p>
                                                                    {item?.title}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="w-100 d-flex justify-content-center">
                                                            <div className="w-50" style={{
                                                                borderTop: "2px solid #CED4DA",
                                                                opacity: actualIndex == 0 ? 0 : 1
                                                            }}></div>
                                                            <div style={{
                                                                borderRadius: "50%",
                                                                display: 'inline-block',
                                                                height: '30px',
                                                                width: '30px',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                position: 'absolute',
                                                                top: '42px',
                                                                backgroundColor: 'white',
                                                            }}>
                                                                {item.completed ? (
                                                                    <div className="d-flex justify-content-center align-items-center bg-success" style={{
                                                                        height: '30px',
                                                                        width: '30px',
                                                                        borderRadius: '50%',
                                                                    }}>
                                                                        <FaCheck className="text-white font-size-16" />
                                                                    </div>
                                                                ) :
                                                                    <div className=" p-2  rounded-circle d-flex align-items-center justify-content-center  font-size-15" style={{
                                                                        borderRadius: "50%",
                                                                        display: 'inline-block',
                                                                        height: '30px',
                                                                        width: '30px',
                                                                        backgroundColor: '#F5B7B1'
                                                                    }}>
                                                                        {actualIndex + 1}
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="w-50" style={{
                                                                borderTop: "2px solid #CED4DA",
                                                                opacity: actualIndex == stepsData?.length - 1 ? 0 : 1
                                                            }}></div>
                                                        </div>
                                                        <div className="mt-4 p-3" style={{
                                                            fontSize: "14px"
                                                        }}>
                                                            <div className="mb-3 d-flex justify-content-center align-items-center">{item?.description}</div>
                                                            <div >
                                                                <Link style={{
                                                                    fontSize: '16px'
                                                                }} to={`${item?.redirect}`}>Set up now <i className="uil uil-arrow-right"></i></Link>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            )
                                        })}


                                        <div className="" style={{ textAlign: "center", marginBottom: '20px', fontSize: '14px' }}>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setCurrentStep(prev => Math.max(0, prev - 1))
                                                }}
                                                className="border-0 me-2"
                                                style={{
                                                    borderRadius: '50%',
                                                    height: '30px',
                                                    width: '30px',
                                                    backgroundColor: (currentStep == 0 ? "rgba(223, 100, 99, 0.25)" : '#F5B7B1'),
                                                    cursor: (currentStep == 0) ? 'no-drop' : 'pointer'
                                                }}
                                                disabled={currentStep === 0}
                                            >
                                                <i className="mdi mdi-chevron-left" style={{
                                                    fontSize: '18px'
                                                }} />
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setCurrentStep(prev => Math.min(StepsData?.length - LENGTH_TO_SHOW, prev + 1))
                                                }}
                                                className="border-0"
                                                style={{
                                                    borderRadius: '50%',
                                                    height: '30px',
                                                    width: '30px',
                                                    backgroundColor: (currentStep == StepsData?.length - LENGTH_TO_SHOW ? "rgba(223, 100, 99, 0.25)" : '#F5B7B1'),
                                                    cursor: (currentStep == StepsData?.length - LENGTH_TO_SHOW) ? 'no-drop' : 'pointer'
                                                }}
                                                disabled={currentStep === StepsData?.length - LENGTH_TO_SHOW}
                                            >
                                                <i className="mdi mdi-chevron-right" style={{
                                                    fontSize: '18px'
                                                }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* </CardBody> */}
                            </div>
                        </Col>
                    </div>

                    <div className="d-flex d-sm-none col-12 row">
                        <Col lg="12">
                            {stepsData.map((status, key) => (
                                <div key={key} className="row d-flex justify-content-center align-items-start my-2">

                                    <div className="d-flex flex-column col-3">

                                        <div className="">
                                            <div style={{
                                                borderRadius: "50%",
                                                zIndex: 12,
                                                height: '27px',
                                                width: '27px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                                {status.completed ? (
                                                    <div className="d-flex justify-content-center align-items-center bg-success" style={{
                                                        height: '27px',
                                                        width: '27px',
                                                        zIndex: 12,
                                                        borderRadius: '50%',
                                                    }}>
                                                        <FaCheck className="text-white font-size-14" />
                                                    </div>
                                                ) :
                                                    <div className="d-flex justify-content-center align-items-center    font-size-15" style={{
                                                        height: '27px',
                                                        width: '27px',
                                                        zIndex: 12,
                                                        borderRadius: '50%',
                                                        backgroundColor: '#F5B7B1'
                                                    }}>
                                                        {key + 1}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-9 ">
                                        <div style={{
                                            fontWeight: 600,
                                            fontSize: '14px'
                                        }}>{status.title}</div>
                                        <p >{status.description}</p>

                                        <Link style={{
                                            fontSize: '14px'
                                        }} to={`${status?.redirect}`}>Set up now <i className="uil uil-arrow-right"></i></Link>
                                        <div style={{
                                            borderLeft: "3px solid  #CED4DA",
                                            opacity: key == stepsData?.length - 1 ? 0 : 1,
                                            height: 'calc(100% + 20px)',
                                            position: 'absolute',
                                            left: '-54px',
                                            top: '0%',
                                            display: 'flex',
                                            flexDirection: 'row-reverse',
                                            flex: 1
                                        }}></div>
                                    </div>

                                </div>
                            ))}
                        </Col>
                    </div>
                </Card>
            </Container>
        </div >
    </React.Fragment >
};

export default observer(SetupProgress);

