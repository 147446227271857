import moment from 'moment';
import React, { useState } from 'react';
import { observer } from "mobx-react-lite";
import DocViewer from '../../components/DocUpload/DocViewer';
import SupervisorSelect from '../../components/Dropdowns/SupervisorSelect/SupervisorSelect';
import EmployeeSupervisorLabel from '../../components/Dropdowns/EmployeeSupervisorSelect/EmployeeSupervisorLabel';
import './Comment.scss';

const CommentItem = ({ comment }) => {
    const [readMore, setReadMore] = useState(false);
    const commentDate = moment(comment?.at * 1000);
    const timeSinceComment = commentDate.fromNow();

    const commentClass = comment?.by === "System"
        ? "comment-item-system-generated"
        : "comment-item-human";

    const toggleReadMore = () => setReadMore(!readMore);

    const renderContent = (content) => {
        if (!content) return '';

        const lines = content.split('\n');
        const words = content.split(/\s+/);

        // If 'readMore' is true, show full content
        if (readMore) {
            return lines.map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ));
        } else {
            // If content exceeds 3 lines, show first 3 lines
            if (lines.length > 3) {
                return (
                    <>
                        {lines.slice(0, 3).map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                        ...{' '}
                    </>
                );
            }
            // If content exceeds 20 words, truncate it
            else if (words.length > 20) {
                const truncatedContent = words.slice(0, 15).join(' ');
                return `${truncatedContent}... `;
            } else {
                return content;
            }
        }
    };

    const getUser = () => comment?.by ?? comment?.updatedBy ?? comment?.createdBy;
    const user = getUser();

    return (
        <div className={`comment-item ${commentClass} d-flex`}>
            <div className="comment-user d-flex align-items-center">
                <SupervisorSelect value={user} onChange={() => { }} asLabel showName={true} />
            </div>

            <div className="comment-content font-size-14 w-100" style={{ fontWeight: 'bold' }}>
                <div
                    className="comment-remark px-3 pe-0 d-flex flex-column align-items-end justify-content-start"
                    style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', overflowWrap: 'break-word' }}
                >
                    {renderContent(comment?.content?.remark)}

                    {(comment?.content?.remark.split(/\n/).length > 3 || comment?.content?.remark.split(/\s+/)?.length > 20) && (
                        <span className="read-more" onClick={toggleReadMore}>
                            {readMore ? 'Read less' : 'Read more'}
                        </span>
                    )}

                    <DocViewer documents={comment?.content?.attachment?.urls} />
                </div>

                <div className="comment-time d-flex justify-content-end">
                    {timeSinceComment}
                </div>
            </div>
        </div>
    );
};

export default observer(CommentItem);
