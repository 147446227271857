import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import Term from "../../../pages/Terms/Term";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import {
  BottomBarButton,
  Modal,
  ModalComponent,
  SearchButton,
} from "../../index";
import TermSelectService from "./TermSelectService";
import TermService from "../../../pages/Terms/TermService";

const TermSelect = ({
  asLabel,
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  outline,
  borderNone,
  selectFirst
}) => {
  const [terms, setTerms] = useState([]);
  const [modal, setModal] = useState(false);
  const [filterOption, setFilterOption] = useState(false);
  const [gridLoading, setGridLoading] = useState(false);
  const { showError } = useContext(DialogContext);
  const { t } = useContext(I18nContext);

  const fetchTerm = async () => {
    setGridLoading(true);
    try {
      const response = await TermService.fetchAll();
      setTerms(toJS(response));
      setGridLoading(false);
    } catch (error) {
      showError("Error", error);
    }
    setGridLoading(false);
  };

  useEffect(() => {
    if (terms.length === 0) fetchTerm();
  }, []);

  useEffect(() => {
    if (terms?.length && selectFirst && !value && terms[0]?.value) {
      onChange({ value: terms[0]?.value });
    }
  }, [terms]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectTerm = (selectedValue) => {
    fetchTerm();
    onChange({ value: selectedValue });
    closeModal();
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      filterOption={filterOption}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Terms")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={terms}
      outline={outline}
      required={required}
      value={value ? value : (selectFirst ? (terms && terms[0]?.value) : null)}
      label={label || (!isDisabled && t("Term & Conditions"))}
      error={!value ? t(`Please Select ${label || "Term"}`) : ""}
      showErr={showErr}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => <SearchButton onClick={openModal} />}
    />
  );

  return (
    <>
      {asLabel ? (
        <div>
          {terms?.find((term) => term?.value == value)?.label ?? (
            <div style={{ color: "grey" }}>--</div>
          )}
        </div>
      ) : (
        <>
          {modal && (
            <ModalComponent
              position={"top"}
              size={"large"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>{t("Term")}</ModalComponent.Title>

              <ModalComponent.Body>
                <Term value={value} insidePane={true} onSelect={selectTerm} />
              </ModalComponent.Body>
            </ModalComponent>
          )}

          {terms.length > 0
            ? renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      )}
    </>
  );
};

export default observer(TermSelect);
