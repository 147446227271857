import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Row } from "reactstrap";
import {
  DateSelect, EmployeeSelect,
  TimeSelect,
  VehicleSelect
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import './editTimeSheetStyle.css';
import TimesheetService from "./TimesheetService";

const EditTimesheet = ({ editId, onChange = () => { } }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);


  const [data, setData] = useState({
    driverDuties: [{
      employee_id: "",
      start: "",
      end: "",
      startDate: "",
      endDate: ""
    }]
  });

  const fetch = async () => {
    try {
      setLoading(true)
      let sheet = await TimesheetService.get(editId);
      setLoading(false)
      setData(sheet);
    } catch (error) {
      showError(error);
      setLoading(false)
    }
  };

  useEffect(() => {
    setData({
      driverDuties: [{
        employee_id: null,
        start: "",
        end: "",
        startDate: "",
        endDate: ""
      }]
    });
    if (editId) fetch();
  }, [editId, TimesheetService?.version]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <React.Fragment>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <ThreeBounce size={50} color="pink" />
        </div>
      ) : (
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row>
            <VehicleSelect
              value={data?.vehicle_id}
              className="col-sm-4"
              onChange={(v) =>
                setData({
                  ...data,
                  vehicle_id: v?.value,
                })
              }
              required={true}
            />
          </Row>
          <Row className="d-flex justify-content-end">
            <Button
              color="light"
              onClick={() => {

                setData({
                  ...data, driverDuties: [...data?.driverDuties, {
                    employee_id: "",
                    start: "",
                    end: "",
                    startDate: "",
                    endDate: ""
                  }]
                })
              }
              }
              style={{ width: "fit-content" }}
              className="h-36 me-3 d-flex align-items-center mb-2">
              <i className="bx font-size-20 color-blue bxs-plus-circle me-2"></i>
              {t("Charges")}
            </Button>
          </Row>
          <Row  >
            {data?.driverDuties?.map((ele, i) => (
              <>
                <table >
                  <tr>
                    <th style={{ width: '19%' }}>{t("Driver Select")}</th>
                    <th style={{ width: '19%' }}> {t("Start Date")}</th>
                    <th style={{ width: '19%' }} >{t("Start Time")}</th>
                    <th style={{ width: '19%' }} >{t("End Date")}</th>
                    <th style={{ width: '19%' }} >{t("End Time")}</th>
                    <th></th>
                  </tr>
                  <tr>
                    <td style={{ width: '19%' }}>
                      <EmployeeSelect
                        label={"Driver Select"}
                        driver
                        outline
                        value={ele?.employee_id}
                        onChange={(v) => {
                          const currentDriverDuties = data?.driverDuties
                          const updatedcurrentDriverDuties = { ...currentDriverDuties[i], employee_id: v.value }
                          currentDriverDuties[i] = updatedcurrentDriverDuties
                          setData({ ...data, driverDuties: currentDriverDuties })
                        }
                        }
                      />
                    </td>
                    <td style={{ width: '19%' }}> <DateSelect
                      outline
                      value={ele?.startDate}
                      label="Start Date"
                      onChange={(v) => {
                        const currentDriverDuties = data?.driverDuties
                        const updatedcurrentDriverDuties = { ...currentDriverDuties[i], startDate: v }
                        currentDriverDuties[i] = updatedcurrentDriverDuties
                        setData({ ...data, driverDuties: currentDriverDuties })
                      }
                      } /></td>
                    <td style={{ width: '19%' }}>
                      <TimeSelect
                        outline
                        value={ele?.start}
                        label="Start"
                        onChange={(v) => {
                          const currentDriverDuties = data?.driverDuties
                          const updatedcurrentDriverDuties = { ...currentDriverDuties[i], start: v }
                          currentDriverDuties[i] = updatedcurrentDriverDuties
                          setData({ ...data, driverDuties: currentDriverDuties })
                        }}

                      />
                    </td>
                    <td style={{ width: '19%' }}>
                      <DateSelect
                        outline
                        label="End Date"
                        value={ele?.endDate ?? ""}
                        onChange={(v) => {
                          const currentDriverDuties = data?.driverDuties
                          const updatedcurrentDriverDuties = { ...currentDriverDuties[i], endDate: v }
                          currentDriverDuties[i] = updatedcurrentDriverDuties
                          setData({ ...data, driverDuties: currentDriverDuties })
                        }}

                      />
                    </td>

                    <td style={{ width: '19%' }}>
                      <TimeSelect
                        outline
                        value={ele?.end}
                        label="End"
                        onChange={(v) => {
                          const currentDriverDuties = data?.driverDuties
                          const updatedcurrentDriverDuties = { ...currentDriverDuties[i], end: v }
                          currentDriverDuties[i] = updatedcurrentDriverDuties
                          setData({ ...data, driverDuties: currentDriverDuties })
                        }}
                      /></td>
                    <td>
                      <Row
                        onClick={() => {
                          let newData = data?.driverDuties.slice();
                          newData.splice(i, 1);
                          setData({ ...data, driverDuties: newData })
                        }}
                        style={{ cursor: "pointer", position: "relative", marginLeft: '5px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}
                      >
                        <i
                          className="bx color-blue font-size-20 bxs-x-circle  d-flex justify-content-end  p0"
                          style={{
                            position: "absolute",
                            right: 0,
                            fontSize: "40px",
                            marginRight: "30px",
                          }}
                        />
                      </Row>
                    </td>
                  </tr>
                </table>

                {/*
                  <table>
                    <thead className="py-3">
                      <tr>
                        <th >Driver Select</th>
                        <th > Start Date</th>
                        <th >End Date</th>
                        <th >Start</th>
                        <th >End</th>
                        <th colSpan="1" style={{ width: '5%' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td >
                          <EmployeeSelect
                            label={"Driver Select"}
                            driver
                            outline
                            value={ele?.employee_id}
                            onChange={(v) => {
                              const currentDriverDuties = data?.driverDuties
                              const updatedcurrentDriverDuties = { ...currentDriverDuties[i], employee_id: v.value }
                              currentDriverDuties[i] = updatedcurrentDriverDuties
                              setData({ ...data, driverDuties: currentDriverDuties })
                            }
                            }
                          />
                        </td>
                        <td style={{ width: '19%' }}>
                          <DateSelect
                            outline
                            value={ele?.startDate}
                            label="Start Date"
                            onChange={(v) => {
                              const currentDriverDuties = data?.driverDuties
                              const updatedcurrentDriverDuties = { ...currentDriverDuties[i], startDate: v }
                              currentDriverDuties[i] = updatedcurrentDriverDuties
                              setData({ ...data, driverDuties: currentDriverDuties })
                            }
                            }
                          />
                        </td>
                        <td style={{ width: '19%' }}>
                          <DateSelect
                            outline
                            label="End Date"
                            value={ele?.endDate ?? ""}
                            onChange={(v) => {
                              const currentDriverDuties = data?.driverDuties
                              const updatedcurrentDriverDuties = { ...currentDriverDuties[i], endDate: v }
                              currentDriverDuties[i] = updatedcurrentDriverDuties
                              setData({ ...data, driverDuties: currentDriverDuties })
                            }}

                          />
                        </td>
                        <td style={{ width: '19%' }}>
                          <TimeSelect
                            outline
                            value={ele?.start}
                            label="Start"
                            onChange={(v) => {
                              const currentDriverDuties = data?.driverDuties
                              const updatedcurrentDriverDuties = { ...currentDriverDuties[i], start: v }
                              currentDriverDuties[i] = updatedcurrentDriverDuties
                              setData({ ...data, driverDuties: currentDriverDuties })
                            }}

                          />
                        </td>
                        <td style={{ width: '19%' }}>

                          <TimeSelect
                            outline
                            value={ele?.end}
                            label="End"
                            onChange={(v) => {
                              const currentDriverDuties = data?.driverDuties
                              const updatedcurrentDriverDuties = { ...currentDriverDuties[i], end: v }
                              currentDriverDuties[i] = updatedcurrentDriverDuties
                              setData({ ...data, driverDuties: currentDriverDuties })
                            }}
                          />
                        </td>
                        <td style={{ width: '5%' }}>
                          <Row
                            onClick={() => {
                              let newData = data?.driverDuties.slice();
                              newData.splice(i, 1);
                              setData({ ...data, driverDuties: newData })
                            }}
                            style={{ cursor: "pointer", position: "relative", marginLeft: '5px' }}
                          >
                            <i
                              className="bx color-blue font-size-20 bxs-x-circle  d-flex justify-content-end  p-0"
                              style={{
                                position: "absolute",
                                right: 0,
                                fontSize: "20px",
                                marginTop: "15px",
                              }}
                            />
                          </Row>
                        </td>
                        <hr className="mt-2" />

                      </tr>
                    </tbody>
                  </table > */}

                {/* <Row
                  onClick={() => {
                      let newData = data?.driverDuties.slice();
                      newData.splice(i, 1);
                      setData({ ...data, driverDuties: newData })
                  }}
                    style={{ cursor: "pointer", position: "relative", marginLeft: '5px' }}
                >
                  <i
                      className="bx color-blue font-size-20 bxs-x-circle  d-flex justify-content-end  p-0"
                    style={{
                      position: "absolute",
                      right: 0,
                      fontSize: "20px",
                      marginTop: "15px",
                    }}
                  />
                  </Row> */}
                <hr className="mt-2" />
              </>

            ))}
          </Row>
        </Form >
      )}
    </React.Fragment >
  );
};

export default observer(EditTimesheet);
