import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardTitle, Form, Row } from "reactstrap";
import { Breadcrumbs, InputField, ModalComponent, SelectField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import Comments from "./Comments";
import CommentList from "./CommentsLists";
import { SELECTSTRUCTURE } from "./TicketsConstant";
import TicketService from "./TicketsService";

const EditTickets = ({ editId, onChange = () => { }, hasErr }) => {

    const { t } = useContext(I18nContext);
    const { userContent } = useContext(UserContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [showCommentsModal, setShowCommentsModal] = useState(false);
    const [data, setData] = useState(null);
    const fetch = async () => {
        try {
            setLoading(true);
            let employess = await TicketService.get(editId);
            setLoading(false);
            setData(employess);
        } catch (error) {
            setLoading(false);
            showError(error)
        }
    };

    useEffect(() => {
        setData(null)
        if (editId) fetch();
    }, [editId]);

    useEffect(() => {
        onChange(data)
    }, [data]);

    const statusMapping = {
        0: "Open",
        1: "In Progress",
        2: "Closed",
        3: "Rejected",
        4: "Client Input Required"
    };
    const PriorityMapping = {
        1: "Low",
        2: "Medium",
        3: "High",
    };
    const toggleCommentsModal = () => {
        setShowCommentsModal(!showCommentsModal)
    }


    return (
        <React.Fragment>
            {loading ?
                <div className="d-flex justify-content-center align-items-center">
                    <ThreeBounce size={50} color="pink" />
                </div>
                :
                <Form
                    className="p-3"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}

                >
                    {/* <InputField
                        placeholder={t("Category")}
                        required
                        value={data?.category}
                        label={t("Category")}
                        onChange={(v) => setData({ ...data, category: v })}
                        error={data?.category?.length === 0 ? "Please enter the category" : ""}
                        showErr={hasErr}
                        type="text"
                        className="col-sm-12"

                    /> */}
                    <Row>
                        {(editId && (userContent?.rights?.includes(20) || (userContent?.userType && userContent?.userType === "super-admin"))) ?
                            <InputField
                                required
                                placeholder={t("Header")}
                                value={data?.header}
                                label={t("Header")}
                                onChange={(v) => setData({ ...data, header: v })}

                                error={data?.header?.length === 0 ? "Please enter the header" : ""}
                                showErr={hasErr}
                                type="text"
                                className="col-sm-4 col-12"
                            /> :
                            (editId ?
                                <div className="d-flex col-4 mb-4">
                                    <div className="col-2 "><b>Header</b></div>
                                    <div className="col-10 ">{data?.header}</div>
                                </div> : null
                            )
                        }
                        {(editId && (userContent?.rights?.includes(20) || (userContent?.userType && userContent?.userType === "super-admin"))) ?
                            <SelectField
                                required
                                value={data?.priority}
                                label={t("Priority")}
                                onChange={(v) => { setData({ ...data, priority: v.value }); }}
                                error={data?.priority?.length === 0 ? t("Enter Priority") : ""}
                                showErr={hasErr}
                                data={SELECTSTRUCTURE?.priority}
                                type="number"
                                placeholder={t("Priority")}
                                className="col-sm-4"
                            /> :
                            (editId ?
                                <div className="d-flex col-4 mb-4">
                                    <div className="col-2 "><b>Priority</b></div>
                                    <div className="col-10">{PriorityMapping[data?.priority]}</div>
                                </div> : null
                            )
                        }
                        {(editId && (userContent?.rights?.includes(20) || (userContent?.userType && userContent?.userType === "super-admin"))) ?
                            <SelectField
                                placeholder={t("Status")}
                                data={SELECTSTRUCTURE?.status}
                                value={data?.status}
                                required={true}
                                label={t("Status")}
                                onChange={(v) =>
                                    setData({
                                        ...data,
                                        status: v?.value
                                    })
                                }
                                type="text"
                                className="col-sm-4"
                            /> :
                            (editId ?
                                <div className="d-flex col-4 mb-4">
                                    <div className="col-2 "><b>Status </b></div>
                                    <div className="col-10">{statusMapping[data?.status]}</div>
                                </div> : null
                            )
                        }
                    </Row>
                    {(editId && (userContent?.rights?.includes(20) || (userContent?.userType && userContent?.userType === "super-admin"))) ?
                        <div className="mt-3">
                            <div style={{ marginBottom: "0.2rem", }}>{t("Details")}</div>
                            <textarea
                                className="inputfield"
                                rows={5} style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", }}
                                value={data?.details}
                                onChange={(e) => setData({ ...data, details: e?.target?.value })}
                            >
                            </textarea>
                        </div> :
                        (editId ?
                            <>
                                <Row className="my-2"><b>Details</b></Row>
                                <Card className="p-3 shadow" style={{minHeight:"70px",height:"fit-content"}}>
                                    <div className="m-0">{data?.details}</div>
                                </Card>
                            </> : null
                        )
                    }
                    <Card className="pt-3 mt-2">
                        <CardTitle className="px-3">
                            <div className={`d-flex justify-content-between col-"12"`}>
                                <Breadcrumbs title="Comments" breadcrumbItem="Comments" />
                            </div>
                        </CardTitle>
                        <CardBody className="pt-0 px-0 mt-0">
                            <CommentList comments={data?.comments} toggleCommentsModal={toggleCommentsModal} ticketStatus={data?.status}  id={editId} />
                        </CardBody>
                        <CardFooter className="bg-white">
                        </CardFooter>
                    </Card>

                    {/* <InputField
                        placeholder={t("Details")}
                        value={data?.details}
                        label={t("Details")}
                        onChange={(v) => setData({ ...data, details: v })}

                        error={data?.details?.length === 0 ? "Please enter the details" : ""}
                        showErr={hasErr}
                        type="text"
                        className="col-sm-12"

                    /> */}
                </Form>
            }
            {showCommentsModal && (
                <ModalComponent
                    modalSizeStyle={{ height: "350px", width: "800px" }}
                    position={"center"}
                    size={"medium"}
                    onToggle={toggleCommentsModal}
                    isOpen={showCommentsModal}
                >
                    <ModalComponent.Title>
                        {t("Add Comments")}
                    </ModalComponent.Title>

                    <ModalComponent.Body>
                        <Comments
                            toggleCommentsModal={toggleCommentsModal}
                            id={editId}
                            fetchComments={() => {
                                fetch();
                            }}
                        />
                    </ModalComponent.Body>
                </ModalComponent>)
            }
        </React.Fragment>
    );
};

export default observer(EditTickets);
