import React, { useEffect, useState } from "react"

import { InputField } from "../../../../../InputField/InputField"
import ModalComponent from "../../../../../Modal/Modal"
import Editor from "../RichTextEditor/Editor/Editor"
import { Button } from "react-bootstrap"
import { Row } from "reactstrap"

const SendMail = ({ isOpen, onToggle, itinerary }) => {

    const [data, setData] = useState({})

    useEffect(() => {
        const customisedSubject = `✈️ Ready for Your ${itinerary?.title}!`
        const highlightsList = (itinerary?.highlights ?? []).map((highlight, index) => `<li>${highlight}</li>`).join('');


        const customisedContent = `<h2 style="color: #2A5DB0; text-align: center;">${itinerary.title}</h2>
<p>Dear Valued Traveler,</p>
<p>We are thrilled to present your bespoke journey through the enchanting landscapes and vibrant cities of ${itinerary?.location?.city ?? "City"}. Crafted with care, your trip promises an immersive exploration of  ${itinerary?.location?.city ?? "City"} natural splendors, cultural riches, and historical wonders.</p>
<div style="margin-top: 20px; background-color: #F0F0F0; padding: 10px; border-left: 5px solid #2A5DB0;">
    <h3 style="color: #2A5DB0;">Journey Highlights</h3>
    <ul>
        ${highlightsList}
    </ul>
</div>

<br/>

<p>This extraordinary expedition spans <strong>${itinerary.days} days and ${itinerary.nights} nights</strong>, each meticulously planned to ensure a journey of unparalleled joy and ease. From the breathtaking vistas to the depths of cultural heritage, your Swiss adventure awaits to dazzle you.</p>
<p>Enclosed is an in-depth itinerary PDF, elaborating on your day-to-day escapades. Please peruse this document to acquaint yourself fully with the forthcoming adventures.</p>
<div style="text-align: center; margin-top: 20px;">
    <a href="#" style="background-color: #2A5DB0; color: white; padding: 10px 20px; text-decoration: none; border-radius: 5px;">Download Itinerary</a>
</div>
<div style="margin-top: 20px;">
    <p>Should you have any inquiries or require further assistance, please don't hesitate to reach out. Our dedicated team is here to ensure your travel dreams are fully realized.</p>
    <p>With warmest regards,</p>
    <p><strong>Your Travel Agency Team</strong></p>
</div>
`
        setData({ content: customisedContent, subject: customisedSubject })
    }, [itinerary])

    return (
        <div style={{ textAlign: "left" }}>
            <ModalComponent
                position={"top"}
                size={"medium"}
                isOpen={isOpen}
                onToggle={() => onToggle()}
            >
                <ModalComponent.Title>
                    <Row>
                        <span style={{ fontSize: "15px" }}>
                            Send Itinerary
                        </span>
                    </Row>
                </ModalComponent.Title>
                <ModalComponent.Body>
                    <div>
                        <div className="mb-3">
                            <InputField
                                placeholder="Email"
                                outline
                                value={data?.emailId}
                                label={"email"}
                                onChange={(v) => setData({ ...data, emailId: v })}
                                type="text"
                            />
                        </div>

                        <div className="mb-3">
                            <InputField
                                outline
                                placeholder="Subject"
                                value={data?.subject}
                                label={"Subject"}
                                onChange={(v) => setData({ ...data, subject: v })}
                                type="text"
                            />
                        </div>
                        <Editor
                            label="Content"
                            placeholder="Content"
                            value={data?.content}
                            onChange={(htmlContent) => {
                                setData({
                                    ...data,
                                    content: htmlContent
                                });
                            }}
                        />
                    </div>

                </ModalComponent.Body>
                <ModalComponent.Footer>
                    <Button
                        outline
                        className="py-1 me-2 bg-danger"
                        onClick={() => {
                            onToggle()
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        className="py-1  bg-success"

                        color="success"
                    >

                        Send <i className="fab fa-telegram-plane ms-1"></i>
                    </Button>
                </ModalComponent.Footer>
            </ModalComponent>



        </div>
    )
}

export default SendMail