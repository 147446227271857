import React, { useContext, useState } from 'react'
import { AddressField, InputField } from '../../../components';
import { Col, Row } from 'reactstrap';
import { I18nContext } from '../../../store/context/i18nContext';

function AdhocInvoice({ invoice, onChange }) {
    const { t } = useContext(I18nContext);

    const handleValueChange = (key, value) => {
        try {
            onChange({
                client: {
                    ...invoice.client,
                    [key]: value
                }
            })
        } catch (error) {

        }
    }


    return (
        <>
            <Row className="d-flex align-items-center">
                <Col>
                    <InputField
                        placeholder={t("Client Name")}
                        value={invoice?.client?.fullName}
                        required
                        label={t("Client Name")}
                        onChange={(v) =>
                            handleValueChange('fullName', v)}
                        type="text"
                    />
                </Col>
                <Col>
                    <InputField
                        placeholder={t("Email")}
                        value={invoice?.client?.email}
                        required
                        label={t("Client email")}
                        onChange={(v) =>
                            handleValueChange('email', v)}
                        type="text"
                    />
                </Col>
                <Col>
                    <InputField
                        placeholder={t("Phone No")}
                        value={invoice?.client?.phoneNo}
                        required
                        label={t("Client Phone No")}
                        onChange={(v) =>
                            onChange('phoneNo', v)}
                        type="number"
                    />
                </Col>
            </Row>
            <Row className="d-flex align-items-center">
                <Col>
                    <InputField
                        placeholder={t("Tax Id")}
                        value={invoice?.client?.taxId}
                        required
                        label={t("Client Tax Id")}
                        onChange={(v) =>
                            onChange('taxId', v)}
                        type="text"
                    />
                </Col>
                <Col>
                    <AddressField
                        placeholder={t("Address")}
                        label={t("Address")}
                        value={invoice?.client?.address}
                        onChange={(v) =>
                            onChange('address', v)}
                    />
                </Col>
            </Row>
        </>
    )
}

export default AdhocInvoice
//TODO improve it more only pass invoice.cleint here