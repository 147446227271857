import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { CardThreeBounce, CountrySelect, InputField, SelectField, VehicleTypeSelect } from "../../components";
import FileUpload from "../../components/DocUpload/FileUpload";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import MakeModelService from "./MakeModelService";
import SELECTSTRUCTURE from "./MakeModelConstant";

const EditMakeModel = ({ editId, onChange = () => { }, hasErr }) => {

  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetch = async () => {
    try {
      let makeModel = await MakeModelService.get(editId);
      setData(makeModel);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(null)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId,MakeModelService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  return (
    <React.Fragment>
      {loading ?
        <CardThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row>
            <InputField
              placeholder={t("Make")}
              value={data?.make}
              required={true}
              label={t("Make")}
              onChange={(v) => setData({ ...data, make: v })}
              error={data?.make?.length === 0 ? t("Please enter Make") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-4"
            />
            <InputField
              placeholder={t("Model")}
              value={data?.model}
              required={true}
              label={t("Model")}
              onChange={(v) => setData({ ...data, model: v })}
              error={data?.model?.length === 0 ? t("Please enter Model") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-4"
            />
            <SelectField
              className="col-sm-4"
              onChange={(v) => setData({ ...data, bodyType: v?.value })}
              data={SELECTSTRUCTURE?.bodyType}
              value={data?.bodyType}
              label={t("Body Type")}
            />
            {/* <CountrySelect
              placeholder={t("Select countries")}
              value={data?.countries?.length ? data?.countries[0] : "India"}
              label={t("Country")}
              onChange={(v) => {
                setData({ ...data, countries: [v.value] })
              }}
              className="col-sm-4"
            /> */}
            
             <InputField
              placeholder={t("Seats")}
              value={data?.seats}
              required={true}
              label={t("Seats")}
              onChange={(v) => setData({ ...data, seats: v })}            
              type="number"
              className="col-sm-4"
            />
             <InputField
              placeholder={t("Wheels")}
              value={data?.wheels}
              required={true}
              label={t("Wheels")}
              onChange={(v) => setData({ ...data, wheels: v })}            
              type="number"
              className="col-sm-4"
            />

            <VehicleTypeSelect
              className="col-sm-4"
              value={data?.vehicleType}
              onChange={(v) => {
                setData({ ...data, vehicleType: v.value })
              }}
            />
          </Row>
          <Row className="mt-5">
            <FileUpload
              type="image"x
              value={data?.pic}
              id={data?._id}
              module={"make-model"}
              field={"pic"}
              onUpload={() => {
                fetch()
              }}
            />
          </Row>
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditMakeModel);



