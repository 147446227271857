import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { Form } from "reactstrap";
import { AccountSelect, AmountField, CardThreeBounce, ClientSelect, CompanySelect, CurrencySelect, InputField, PaymentStatusSelect, SelectField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import InvoiceSettlements from "./InvoiceSettlements";
import TripSettlements from "./TripSettlements";
import PaymentReceivedService from "./PaymentReceivedService";
import DateSelectCustom from "../../components/DateTimePickerWithInterval/DateSelectCustom";
import { doPOST } from "../../util/HttpUtil";
import PaymentReceivedDropDown from "../Booking/components/PaymentReceivedDropDown/PaymentReceivedDropDown";
import { useNavigate } from "react-router-dom";
import { SELECTSTRUCTURE } from "./PaymentReceivedConstant";

const PaymentReceived = React.forwardRef(({ editId, data, setData, hasErr },ref) => {
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [isTripsPresent, setIsTripsPresent] = useState(false);
    const [invoiceIds, setInvoiceIds] = useState([]);


    const [paymentID, setPaymentId] = useState(editId)

    let navigate = useNavigate();
    const [particularPayment, setparticularPayment] = useState({});
    const [particularAllPayments, setparticularAllPayments] = useState([]);

    const updateInvoiceSettlements = (invoices, data) => {
        let updatedSettlements = [...data?.settlements];
        updatedSettlements = updatedSettlements.map((settlement) => {
            let invoice = invoices.find((invoice) => invoice?._id === settlement?.invoice_id);
            return {
                ...settlement,
                invoiceDate: invoice?.invoiceDate,
                invoiceNo: invoice?.invoiceNo,
                amountDue: invoice?.due,
                totalAmount: invoice?.total,
                writeOff: invoice?.writeOff,
                tdsSettled: 0
            };
        });
        setData((prevData) => ({
            ...prevData,
            settlements: updatedSettlements,
        }));
    };

    const fetchSettledInvoicePayments = async (data) => {
        const invoice_Ids = data?.settlements?.map(settlement => settlement.invoice_id) ?? [];
        setInvoiceIds(invoice_Ids);
        try {
            let invoiceIds = [];
            if (invoice_Ids && invoice_Ids.length) {
                invoiceIds = invoice_Ids.join(",");
            }
            // const excludedIdsQuery = `_id=in[${invoiceIds}]&client.org_id=${data?.client_id}&client.company_id=${data?.clientCompany_id}`
            const excludedIdsQuery = `_id=in[${invoiceIds}]`
            // let response = await doGET(`/api/invoice/grid?_id=in[${invoice_Ids}]`);
            let response = await doPOST(
                `/api/invoice/grid`,
                invoice_Ids && invoice_Ids.length ? { queryURL: excludedIdsQuery } : {}
            );
            updateInvoiceSettlements(response?.data?.rows, data);
            // setInvoicesFetched(true);
        } catch (error) {
            showError(error)
        }
        return invoice_Ids;
    };

    const fetch = async (id) => {
        try {
            let paymentReceived = await PaymentReceivedService.get(id);
            setparticularPayment(paymentReceived)
            setData((prevData) => ({
                ...prevData,
                ...paymentReceived
            }));
            // console.log({
            //     ...data,
            //     ...paymentReceived,
            // })

            fetchparticularpaymentByCompanyIdandClientID(paymentReceived?.client_id, paymentReceived?.clientCompany_id, paymentReceived?._id)
            if (paymentReceived.settlements?.length) {
                for (let i = 0; i < paymentReceived.settlements.length; i++) {
                    if (paymentReceived.settlements[i].trip_id) {
                        setIsTripsPresent(true);
                    }
                }
            }
            fetchSettledInvoicePayments(paymentReceived)
            return paymentReceived;
        } catch (error) {
            showError(error)
        }
    };


    const fetchparticularpaymentByCompanyIdandClientID = async (client_id, clientCompany_id, payment_id) => {
        try {
            let ans = await PaymentReceivedService.getAllPaymentsBetween(client_id, clientCompany_id, payment_id)
            setparticularAllPayments(ans?.rows);
        } catch (error) {
            showError(error)
        }
    };

    const calculateTotalAmountReceived = (data) => {
        let total = 0;
        data?.forEach((acc) => {
            total += acc?.amountSettled || 0;
        });
        // setTotalAmount((total / 100).toFixed(2))
        return total;
    };
    const calculateTotalTDSReceived = (data) => {
        let total = 0;
        data?.forEach((acc) => {
            total += acc?.tdsSettled || 0;
        });
        // setTotalTDS((total / 100).toFixed(2))
        return total;
    };
    const calculateTotalTWriteOff = (data) => {
        let total = 0;
        data?.forEach((acc) => {
            total += acc?.writeOff || 0;
        });
        // setTotalTDS((total / 100).toFixed(2))
        return total;
    };


    const calculateExcessAmount = (data) => {
        const totalTDSSettled = calculateTotalTDSReceived(data?.settlements);
        const totalAmountSettled = calculateTotalAmountReceived(data?.settlements);
        const totalWriteOffAmount = calculateTotalTWriteOff(data?.settlements);
        const excessAmount = (data?.amountReceived + (data?.tds ? data?.tds : 0)) - ((totalTDSSettled ? totalTDSSettled : 0) + (totalAmountSettled ? totalAmountSettled : 0) + (totalWriteOffAmount ? totalWriteOffAmount : 0));
        return excessAmount;
    };

    useEffect(() => {
        const excessAmount = calculateExcessAmount(data);
        setData(prevData => ({
            ...prevData,
            excessAmount: excessAmount
        }));
    }, [data])

    // useEffect(() => {
    //     setData(prevData => ({
    //         ...prevData,
    //         excessAmount: excessAmount
    //     }));
    // }, [excessAmount])


    useEffect(() => {
        setLoading(true);
        if (paymentID) {
            fetch(paymentID);
        }

        setLoading(false)
    }, [paymentID]);

    const formatDate = (dateInt) => {
        if (!dateInt) return '';

        // Convert the integer to a string
        const dateString = dateInt.toString();

        // Ensure dateString is a string and has the correct length
        if (typeof dateString !== 'string' || dateString.length !== 8) return dateString;

        // Extract year, month, and day from the dateString
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);

        // Format the date as YYYY-MM-DD
        return `${day}-${month}-${year}`;
    };


    useEffect(() => {
        setData(prevData => ({
            ...prevData,
            settlements: []
        }));
    }, [data?.client_id]);

    const selectedPayment = particularAllPayments?.find(payment => payment._id === paymentID) || {};


    const addSettlement = (settlement) => {
        let updatedSettlements = data?.settlements;
        updatedSettlements.push(settlement);
        setData((prevData) => ({
            ...prevData,
            settlements: updatedSettlements,
        }));
    }

    const handlePaymentReceivedChanges = async (selectedValue) => {
        setPaymentId(selectedValue._id)
        setLoading(true)
        await fetch(selectedValue._id);
        navigate('/payment-received/edit/' + selectedValue._id)
        // window.location.reload();
        setLoading(false)

    }
    useImperativeHandle(ref,()=>({
        fetch:fetch
    }))

    return (
        <React.Fragment>
            {loading ?
                <CardThreeBounce /> :
                <Form
                    className="p-3 pt-2"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                >

                    <div className="row d-flex align-items-center">

                        <div class="col-4">
                            <CompanySelect
                                isDisabled={editId?true:false}
                                outline
                                value={data?.company_id}
                                innerContainerStyle={{ borderRadius: "6px 6px 6px 6px", }}
                                onChange={(v) => {
                                    setData({ ...data, company_id: v?.value, client_id: null, clientCompany_id: null, account_id: null })
                                }}
                                innerContainerClassName="input-group-post"
                                label="Company"
                            />
                        </div>
                        <div class="col-4">
                            <AccountSelect
                                outline
                                corp_id={data?.company_id}
                                required
                                value={data?.account_id}
                                onChange={(v) => setData({ ...data, account_id: v?.value })}
                            />
                        </div>
                        <div class="col-4">
                            <PaymentStatusSelect
                                asLabel
                                outline
                                value={data?.status}
                                label={"Status"}
                                data={SELECTSTRUCTURE?.paymentStatus}
                                placeholder={"Status"}
                            />
                        </div>
                    </div>
                    <div className="row d-flex align-items-center">
                        <div class="col-4">
                            <ClientSelect
                                isDisabled={editId?true:false}
                                outline
                                value={data?.client_id}
                                onChange={(v) => setData({ ...data, client_id: v?.value, clientCompany_id: null })}
                                className="w-100 "
                                label="Client"
                            />
                        </div>
                        <div class="col-4">
                            <CurrencySelect
                                className={`m-0 `}
                                outline
                                value={data?.currency ? data?.currency : 'INR'}
                                label={"Currency"}
                                showClear={false}

                                onChange={(v) => {
                                    setData({
                                        ...data, currency: v
                                    })
                                }}
                                inputGroup
                            />
                        </div>
                        <div class="col-4">
                            <InputField
                                value={data?.transactionRef}
                                outline
                                label={t("Transaction Ref No.")}
                                placeholder=""
                                type="text"
                                showErr={true}
                                basic
                                onChange={(v) => {
                                    setData({ ...data, transactionRef: v })
                                }}
                                required
                                error={
                                    data?.transactionRef?.length === 0
                                        ? "Please enter Email"
                                        : ""
                                }
                            />
                        </div>
                    </div>
                    <div className="row d-flex align-items-center">

                        <div class="col-4">
                            <CompanySelect
                                isDisabled={editId?true:false}
                                outline
                                className="w-100"
                                corpType="client"
                                value={data?.clientCompany_id}
                                onChange={(v) => setData({ ...data, clientCompany_id: v?.value })}
                                label="Client Company"
                                corp_id={data?.client_id}
                            />
                        </div>
                        <div class="col-4">
                            <AmountField
                                innerStyle={{ border: "0px", }}
                                // label={""}
                                value={data?.amountReceived}
                                onChange={(v) => {
                                    setData({
                                        ...data, amountReceived: v
                                    })
                                }}
                                defaultForm={true}
                                style={{ borderRadius: "6px 6px 6px 6px", border: "1px solid #ced4da", }}
                                type="text"
                                label={"Amount Received"}
                            />

                        </div>
                        <div class="col-4">
                            <AmountField
                                innerStyle={{ border: "0px", }}
                                value={data?.tds}
                                onChange={(v) => {
                                    setData({
                                        ...data, tds: v
                                    })
                                }}
                                defaultForm={true}
                                style={{ borderRadius: "6px 6px 6px 6px", border: "1px solid #ced4da", }}
                                type="text"
                                label={"TDS Amount"}
                            />

                        </div>
                    </div>
                    <div className="row d-flex align-items-center">
                        <div class="col-4">
                            <DateSelectCustom
                                // outline
                                placeholder={("DD-MM-YYYY")}
                                label={"Date"}
                                // outline={false}
                                className=" col-4"
                                value={data?.date}
                                required={true}
                                onChange={(v) => {
                                    setData({ ...data, date: v });
                                }}
                            />
                        </div>
                        <div class="col-4">
                            <AmountField
                                disabled={true}
                                innerStyle={{ border: "0px", }}
                                value={data?.excessAmount}
                                onChange={(v) => {
                                    setData({
                                        ...data, excessAmount: v
                                    })
                                }}
                                defaultForm={true}
                                label={"Excess Amount"}
                                style={{ borderRadius: "6px 6px 6px 6px", border: "1px solid #ced4da", }}
                                type="text"
                            />



                        </div>
                        <div class="col-4">
                            <PaymentReceivedDropDown
                                data={particularAllPayments}
                                onChange={(v) => {
                                    fetch(v?._id)
                                    navigate(`/payment-received/edit/${v?._id}`)
                                }}
                                value={<><b>Dated</b>: {data?.date ? formatDate(data?.date) : "--"} | <b>Transaction Reference</b>: {data?.transactionRef ?? "--"} | <b>Amount Received</b>: {data?.amountReceived / 100} | <b>Excess Amount</b>: {data?.excessAmount / 100}</>}
                                isDisabled={false}
                            // selectedData={selectedPayment}
                            />
                        </div>
                    </div>

                    <div className="row d-flex align-items-center">
                        <div className="col-4">
                            <InputField
                                value={data?.notes}
                                outline
                                label={t('Notes')}
                                placeholder=""
                                type="text"
                                basic
                                onChange={(v) => {
                                    setData({ ...data, notes: v })
                                }}
                            />
                        </div>
                        <div className="col-4">
                            <SelectField
                            outline
                                data={SELECTSTRUCTURE.paymentMode}
                                value={data?.paymentMode}
                                onChange={(v) => {
                                    setData({
                                        ...data,
                                        paymentMode: v.value,
                                    });
                                }}
                                showErr={hasErr}
                                label={t("Payment Mode")}
                            />
                        </div>
                    </div>

                    <div className="row d-flex align-items-center">

                    </div>



                    {data?.client_id &&
                        <>
                            <div className="shadow mt-3 p-3 me-2">
                                <InvoiceSettlements
                                    invoiceIds={invoiceIds}
                                    setInvoiceIds={setInvoiceIds}
                                    editId={editId}
                                    client_id={data?.client_id}
                                    // clientCompany_id={data?.clientCompany_id}
                                    data={data?.settlements.filter(settlement => settlement?.invoice_id)}
                                    onChange={(settlements) => {
                                        setData((prevData) => ({
                                            ...prevData,
                                            settlements: settlements,
                                        }));
                                    }}
                                    addSettlement={(v) => { addSettlement(v) }}
                                    onChangeExcessAmount={() => { calculateExcessAmount() }}
                                />
                            </div>
                            {data?._id && isTripsPresent ?
                                <div className="shadow mt-3 p-3 me-2">
                                    <TripSettlements
                                        client_id={data?.client_id}
                                        // clientCompany_id={data?.clientCompany_id}
                                        data={data?.settlements.filter(settlement => settlement?.trip_id)}
                                        addSettlement={(v) => { addSettlement(v) }}
                                    // onChange={(settlements) => {
                                    //     const updatedSettlements= updateTripSettlements(settlements,data?.settlements);
                                    //     setData((prevData) => ({
                                    //         ...prevData,
                                    //         settlements: updatedSettlements,
                                    //     }))
                                    // }}
                                    // onChangeExcessAmount={(settlementAmount) => {
                                    //     const totalExcessAmount = (data?.amountReceived + data?.tds) - (settlementAmount)
                                    //     setData((prevData) => ({
                                    //         ...prevData,
                                    //         excessAmount: (totalExcessAmount > 0) ? totalExcessAmount : 0,
                                    //     }));
                                    // }}
                                    />
                                </div> : ``
                            }
                        </>
                    }
                </Form>
            }
        </React.Fragment>
    );
});

export default observer(PaymentReceived);



