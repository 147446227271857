import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import AdhocRequestService from "./AdhocRequestService";
import moment from 'moment';
import { AddressField, OfficeSelect } from "../../components";
import DateTimePickerWithInterval from "../../components/DateTimePickerWithInterval";

const EditAdhocRequest = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetch = async () => {
    try {
      let adhocreq = await AdhocRequestService.get(editId);
      setData(adhocreq);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    setData(null);
    setLoading(true);
    if (editId) fetch();
    setLoading(false);
  }, [editId]);

  useEffect(() => {
    onChange(data);
  }, [data]);


  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :

        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row style={{ marginTop: 0, background: "white", padding: "1rem", borderRadius: "0.5rem", fontSize: "12px", }}>
            <div className="d-flex col-8">
              <div className="col-3"><b>Start time</b></div>
              <div className="d-flex col-11" style={{ marginLeft: "0.5rem" }}>
                {data?.startTime ? (
                  <>
                    {(() => {
                      const timestamp = data?.startTime;
                      const date = moment.unix(timestamp);

                      const formattedDateTime = date.format('DD-MM-YYYY, hh:mm A');

                      return (
                        <span>
                          {formattedDateTime}
                        </span>
                      );
                    })()}
                  </>
                ) : "--"}
              </div>
            </div>
            <div className="d-flex col-8" style={{ marginTop: "1rem" }}>
              <div className="col-3"><b>Returning</b></div>
              <div className="d-flex col-11 ms-1">
                <div>
                  {data?.returning == true ? (
                    <i
                      className="mdi mdi-checkbox-marked font-size-20 "
                      style={{ zIndex: "999" }}
                    />
                  ) : (
                    <i
                      className="mdi mdi-checkbox-blank-outline font-size-20 "
                      style={{ zIndex: "999" }}
                    />
                  )}
                </div> </div>
            </div>
            <div className="d-flex col-8" style={{ marginTop: "1rem" }}>
              <div className="col-3"> <b>Source Address</b></div>
              <div className="d-flex col-11" style={{ marginLeft: "0.5rem" }}>
                {(data?.src?.country || data?.src?.city || data?.src?.addressLine) ? <>{(data?.src?.country ?? data?.src?.country) + ' ' + (data?.src?.city ?? data?.src?.city) + ' ' + (data?.src?.addressLine ?? data?.src?.addressLine)}</> : "--"} &nbsp;
              </div>
            </div>
            <div className="d-flex col-8" style={{ marginTop: "1rem", }}>
              <div className="col-3"> <b>Destination Address</b></div>
              <div className="d-flex col-11" style={{ marginLeft: "0.5rem" }}>
                {(data?.dst?.country || data?.dst?.city || data?.dst?.addressLine) ? <>{(data?.dst?.country ?? data?.dst?.country) + ' ' + (data?.dst?.city ?? data?.dst?.city) + ' ' + (data?.dst?.addressLine ?? data?.dst?.addressLine)}</> : "--"} &nbsp;
              </div>
            </div>
            <div className="d-flex col-8" style={{ marginTop: "1rem" }}>
              <div className="col-3"><b>Status</b></div>
              <div className="col-11" style={{ marginLeft: "0.5rem" }}>
                <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -5 }}>
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor:
                        data?.status === 0 ? 'yellow' : data?.status == 1 ? 'green' : data?.status == 2 ? 'blue' : 'red',
                      marginRight: '5px',

                    }}
                  ></div>
                  <span>
                    {data?.status === 0
                      ? "Created"
                      : data?.status === 1
                        ? "Approved"
                        : data?.status === 2
                          ? "Assigned"
                          : "Rejected"}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex col-8" style={{ marginTop: "1rem" }}>
              <div className="col-3"><b>Office</b></div>
              <div className="d-flex col-11" style={{ marginLeft: "0.5rem" }}>
                {data?.office_id ?
                  <OfficeSelect
                    asLabel
                    outline
                    label="Office"
                    value={data?.office_id}
                  /> : "--"
                }
              </div>
            </div>
            <div>

            </div>
          </Row>
          {/* <Row>
            <DateTimePickerWithInterval
              placeholder="Start Time"
              className="col-3"
              outline
              timestamp
              label="Start Time"
              value={data?.startTime}
              onChange={(v) => {
                setData({
                  ...data,
                  startTime: v
                })
              }
              }
            />
          </Row>
          <Row className="d-flex align-items-center">
            <div
              className="col-1"
              style={{
                width: '18px',
                height: '18px',
                borderRadius: '50%',
                backgroundColor: 'green',
                marginRight: '7px',
              }}
            ></div>
            <AddressField
              className="col-8"
              style={{ marginTop: "5px" }}
              value={data?.src}
              outline
              onChange={(v) => {
                setData({
                  ...data,
                  src: v
                })
              }}
            />
          </Row>
          <Row className="d-flex align-items-center">
            <div
              className="col-1"
              style={{
                width: '18px',
                height: '18px',
                backgroundColor: 'red',
                marginRight: '7px',
              }}
            ></div>
            <AddressField
              className="col-8"
              style={{ marginTop: "5px" }}
              value={data?.dst}
              outline
              onChange={(v) => {
                setData({
                  ...data,
                  dst: v
                })
              }}
            />
          </Row> */}
        </Form>
      }
    </React.Fragment>
  );
};


export default observer(EditAdhocRequest);
