import React from 'react';
import user from "../../assets/images/user.svg";
import { tripTypeMapping } from '../BulkModification/BookingColumns';
import { BOOKING_STATUS_MAP } from '../../pages/Booking/BookingConstant';
import { FLIGHT_STATUS_MAP } from '../../pages/Flight/FlightConstant';
import { STAYS_STATUS_MAP } from '../../pages/Stay/StayConstant';
import { formatDateToMonthDay } from '../../util/Util';

const STATUS_MAPS = {
    flight: FLIGHT_STATUS_MAP,
    stays: STAYS_STATUS_MAP,
    booking: BOOKING_STATUS_MAP,
  };

function RecentCard({ header, recentArray, icon, type }) {
    const bookingType = (val) => tripTypeMapping[val] || "";

    const getJourneyStatus = (val, type) => {
        const statusMap = STATUS_MAPS[type];
        if (!statusMap) {
            return;
        }
        const status = statusMap[val];
    
        return (
            <span className="d-inline-flex align-items-center">
                <span
                    className="rounded-circle me-2"
                    style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: status ? status.color : '#000000'
                    }}
                ></span>
                <span className="text-black">
                    {status ? status.label : (type === 'flight' || type === 'booking' ? '""' : 'Unknown')}
                </span>
            </span>
        );
    };
    


    return (
        <React.Fragment>
            <span className='mt-3 fw-bold font-size-14'>
                {header} {icon}
            </span>
            <hr />
            {recentArray?.length > 0 &&
                recentArray?.map((element, idx) =>
                    <div key={idx}>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <span className='fw-semibold font-size-14'>
                                    {type === 'trip' ? (
                                        <>
                                            #{element?.tripNo}
                                            {element?.dutySlipNo && `|${element?.dutySlipNo}`} 
                                            <span className='text-dropzone-light-grey'> {bookingType(element?.tripType)}</span>
                                            {/* Can be used later */}
                                            {/* <MakeModelSelect
                                                asLabel
                                                value={element?.vehicle?.makeModel_id}
                                                className="col-sm-3"
                                            /> */}
                                        </>
                                    ) : type === 'flight' ? (
                                        `#${element?.flightNo}`
                                    ) : (
                                        element?.hotelName
                                    )}
                                </span>
                                <br />
                                <div className='mt-1 font-size-16'>
                                    {type === 'trip' ?
                                        `${element?.src?.city ?? ""}, ${formatDateToMonthDay(element?.tripDate)}`
                                        : type === 'flight' ?
                                            `${element?.fromCity ?? ""} ${formatDateToMonthDay(element?.bookingDate)}`
                                            :
                                            `${formatDateToMonthDay(element?.checkInDate)} - ${formatDateToMonthDay(element?.checkOutDate)}`}
                                </div>
                            </div>
                            <div>
                                <span className='font-size-14 fw-semibold'>
                                    {type === 'trip' ? (
                                        <>
                                            {getJourneyStatus(element?.status,"booking")}
                                        </>
                                    ) : type === 'flight' ? (
                                        <>
                                            {element?.fromCity} - {element?.toCity} &nbsp; {getJourneyStatus(element?.status,"flight")}
                                        </>
                                    ) : (
                                        <>
                                            #{element?.bookingNo} &nbsp;  {getJourneyStatus(element?.status,"stays")}
                                        </>
                                    )}
                                </span>
                                <br />
                                <div className='font-size-16 fw-bold float-end'>
                                    <img src={user} height={15} className='px-2' alt="user" /> 
                                    {type === 'trip' ? element?.passengers?.length
                                        : type === 'flight' ? element?.passengers?.length
                                            : element?.guests?.length}
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                )
            }
        </React.Fragment>
    );
}

export default RecentCard;
