import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  CheckButton,
  DeleteLink,
  EditLink,
  DeleteButton,
  SaveButton,
  Layout,
} from "../../components";
import { Row, Col } from "reactstrap";
import "../../components/SuperResponsiveTableStyle.css";
import { I18nContext } from "../../store/context/i18nContext";
import CityGroupService from "./CityGroupsService";
import EditCityGroups from "./EditCityGroups";
import { DialogContext } from "../../store/context/DialogContext";
import { STRUCTURE } from "./CityGroupsConstant";

const CityGroups = (props) => {
  const { t } = useContext(I18nContext);
  let { id } = useParams();
  if (props?.corpId) id = props?.corpId;
  const { showMessage, showConfirm, showError } = useContext(DialogContext);
  const [data, setData] = useState({});
  const [hasErr, setHasErr] = useState(false);
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(
    props?.corpId ? false : id
  );

  const [loading, setLoading] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);

  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await CityGroupService.fetch(filterUrl, props?.corpId);
      // console.log("fetched data: ", toJS(CityGroupService.records));
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    setShowDetailPage(
      window.location.pathname === "/citygroups/edit" || (id && !props?.corpId)
    );
  }, [id]);

  const submitHandler = async (e) => {
    e.preventDefault();
    // console.log(data);
    setHasErr(true);
    if (!data?.name || !data?.country || !data?.client_id) return;
    setLoading(true);
    try {
      if (id) {
        await CityGroupService.edit(data, id);
        showMessage("CityGroups Updated successfully");
      } else {
        const response = await CityGroupService.save(data);
        showMessage("CityGroups saved successfully");
        CityGroupService.records.push(data);
        if (!props?.insidePane) navigate(`/citygroups`);
      }
      fetchData();
    } catch (e) {
      showError(e);
    }
  };
  const onDelete = async (event, id, name) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: ` Delete City Group ${name}?`,
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        await CityGroupService.delete(id);
      } catch (e) {
        showError(e);
      }
    }
  };

  const onRowClick = (id) => {
    if (!props?.insidePane) navigate(`/citygroups/edit/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };
  const handleRowClick = async (citygroupsId) => {
    if (showDetailPage && citygroupsId !== selected_id && isEditDataChanged) {
      let isProceed = await showConfirm({
        isSaveWarn: true,
        title: t(`Do you want to save the changes ?`),
        description: t("Your changes will be lost if you don't save them"),
      });
      if (isProceed) onRowClick(citygroupsId);
    } else onRowClick(citygroupsId);
  };

  if (loading) return <CardThreeBounce />;
  return (
    <Layout
      showDetailPage={showDetailPage}
       
      title={t("City Groups")}
      filterStructure={STRUCTURE}
      backDetailPage={() => {
        setShowDetailPage(false);
        if (!props?.insidePane) navigate("/citygroups");
        setSelected_id(false);
      }}
      onApplyFilter={fetchData}
      onAddClick={() => {
        if (!props?.insidePane) navigate(`/citygroups/edit`);
        setShowDetailPage(true);
        setSelected_id(null);
      }}
      page={CityGroupService.page}
      rows={CityGroupService.rows}
      total={CityGroupService.totalRecords}
      onPageChange={async (page, rows) => {
        await CityGroupService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("Name")}</Th>
            <Th>{t("Country")}</Th>

            <Th>{t("Cities")}</Th>
            <Th>{t("Action")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {CityGroupService.records?.map((citygroups) => (
            <Tr
              key={citygroups?._id}
              onClick={
                showDetailPage && (() => handleRowClick(citygroups?._id))
              }
            >
              <Td>{citygroups?.name}</Td>
              <Td>{citygroups?.country ? citygroups?.country : "Any"}</Td>
              <Td>
                {citygroups?.cities.length <= 30
                  ? citygroups.cities.map((e) => e).join(", ")
                  : citygroups.cities
                    .slice(0, 30)
                    .map((e) => e)
                    .join(", ") + "..."}
              </Td>
              <Td>
                <Row>
                  <Col className="col-6">
                    <EditLink
                      onClick={() => {
                        if (!props?.insidePane)
                          navigate(`/citygroups/edit/${citygroups?._id}`);
                        setSelected_id(citygroups?._id);
                        setShowDetailPage(true);
                      }}
                    />
                  </Col>
                  <Col className="col-6">
                    <DeleteLink
                      onClick={(event) => {
                        onDelete(event, citygroups?._id, citygroups?.name);
                      }}
                    />
                  </Col>
                </Row>

                {props?.selectMode ? (
                  <CheckButton
                    onClick={() => {
                      props?.onSelect([{ ...citygroups }]);
                    }}
                  />
                ) : null}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>

      <Layout.DetailPageTitle>
        {/* <Row>
          <Row className="mb-2 py-2 border-bottom-1">
            <Col>
              <div>{data?.name}</div>
              <div>{data?.phone}</div>
            </Col>
          </Row>
        </Row> */}
        {id ? <h5>Edit CityGroup</h5> : <h5>Create CityGroup</h5>}

      </Layout.DetailPageTitle>
      <Layout.DetailPageBody>
        <EditCityGroups
          insidePane={props?.insidePane}
          onChange={(v) => setIsEditDataChanged(v)}
          id={selected_id}
          data={data}
          hasErr={hasErr}
          setHasErr={setHasErr}
          setData={setData}
          back={() => {
            setShowDetailPage(false);
            if (!props?.insidePane) navigate("/citygroups");
            setSelected_id(false);
          }}
          fetchData={fetchData}
        />
      </Layout.DetailPageBody>

      <Layout.DetailPageFooter>
        <Row>
          <Col>
            <div
              className="d-flex justify-content-end mt-1"
              style={{ marginTop: "-2.5rem" }}
            >
              {data?._id && (
                <DeleteButton onClick={(e) => onDelete(e, data?._id, data?.name)} />
              )}
              <SaveButton onClick={submitHandler} />
            </div>
          </Col>
        </Row>
      </Layout.DetailPageFooter>
    </Layout>
  );
};

export default observer(CityGroups);
