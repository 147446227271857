import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
// import EditStates from "./EditState";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import EditSuperAdmin from "./EditSuperAdmin";
import { STRUCTURE } from "./SuperAdminConstant";
import { GridColumns } from "./SuperAdminGridColumn";
import SuperAdminService from "./SuperAdminService";

const SuperAdmin = ({ insidePane }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(
    insidePane ? false : window.location.pathname === "/superAdmin/create" || edit_id
  );
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await SuperAdminService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(
      queryParams,
      STRUCTURE
    );
    setFilterURL(filterUrl);
    setFilterObject(filterObject);
    fetchData(filterUrl);
  }, []);

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (editID) {
        await SuperAdminService.edit(detailData, editID);
        showMessage(t("Record updated successfully."));
      } else {
        const superAdminID = await SuperAdminService.save(detailData);
        showMessage(t("Record saved successfully."));
        if (!insidePane) navigate(`/superAdmin/edit/${superAdminID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await SuperAdminService.delete(id);
        showMessage("Deleted", "Deleted");
      } catch (e) {
        showError(e);
      }
    }
  };

  return (
    <>
      <Layout
        medium
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/superAdmin");
          setEditID(null);
        }}
         
        title={t("SuperAdmin")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/superAdmin/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={SuperAdminService.page}
        rows={SuperAdminService.rows}
        total={SuperAdminService.total}
        fetch={SuperAdminService.fetch}
      >
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={SuperAdminService.records}
              total={SuperAdminService.total}
              uiPreference="superAdmin.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={setSelectedIDs}
              page={SuperAdminService.page}
              rowsPerPage={SuperAdminService.rowsPerPage}
              onPaginationChange={SuperAdminService.onPaginationChange}
              renderLastCol={(superAdmin) => {
                return (
                  <>
                    <EditLink
                      onClick={() => {
                        if (!insidePane) navigate(`/superAdmin/edit/${superAdmin?._id}`);
                        setEditID(superAdmin?._id);
                        setShowDetailPage(true);
                      }}
                    />
                    <DeleteLink
                      onClick={(event) => onDelete(event, superAdmin?._id)}
                    />
                  </>
                );
              }}
            />
          </>
        </Layout.Table>

        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit SuperAdmin") : t("Add SuperAdmin")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditSuperAdmin
            editId={editID}
            onChange={(v) => {
              setDetailData(v);
            }}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {detailData?._id ? (
            <AuditLogDialog module="SuperAdmin" id={detailData?._id} />
          ) : null

          }
          {detailData?._id ? (

            <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} />
          ) : null}
          <SaveButton onClick={onSave} />
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(SuperAdmin);
