import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./CorpSubsConstants";
import { doGET, doPOST } from "../../util/HttpUtil";

class Service {
  records = [];
  totalRecords = 0;
  places = [];
  page = 1;
  rows = 20;

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async function (filterData, corpId) {
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rows, filterData, corpId)
    );
    if (response.status === 200) {
      this.records = corpId ? response.data : response.data.rows;
      this.totalRecords = response.data.records;
    }
  };

  get = async function (id) {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      return response.data;
    }
  };

  save = async function (data) {
    await doPOST(ENDPOINTS.save, data);
    this.fetch();
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch();
  };
}

const StaticContentService = new Service();
export default StaticContentService;
