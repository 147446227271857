import React from 'react';
import { Col, Row } from 'reactstrap';
import ProgressBar from '../ProgressBar/ProgressBar';
import { formatNumberToCurrency } from '../../helpers/general';

function IndicativeStats({ data, configs }) {
    return (
        <Row className='mt-2 h-100 pb-3'>
            <Col md="4" className="d-flex flex-column align-items-end justify-content-end">
                <div>
                    <Row className='d-flex justify-content-center text-center'>
                        {configs[0]?.left?.title}
                    </Row>
                    <Row className='d-flex justify-content-center text-center'>
                        <Col className='d-flex justify-content-center align-items-center'>
                            <div className='font-size-16 fw-semibold bluish-info mt-1'>
                                {configs[0]?.left?.Currency}
                            </div>
                            <div className='font-size-20 bluish-info fw-semibold mx-1'>
                                {formatNumberToCurrency(data[0])}
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center text-center'>
                        {configs[0]?.left?.subHeading}
                    </Row>
                </div>
            </Col>
            <Col md="4" className='d-flex flex-column'>
                <Row>
                    {configs[0]?.top?.title}
                </Row>
                <Row className="mb-1">
                    <Col className="text-center">
                        <span className='font-size-16 fw-semibold pinkish-info'>
                            {configs[0]?.top?.Currency}
                        </span>
                        <span className='font-size-20 fw-semibold pinkish-info mx-1'>
                            {formatNumberToCurrency(data[0] + data[1])}
                        </span>
                    </Col>
                </Row>

                <ProgressBar data={[
                    { title: configs[0]?.left?.title, color: "#3498DB", value: data[0] },
                    { title: configs[0]?.right?.title, color: "#167B2C", value: data[1] },

                ]} />
            </Col>
            <Col md="4" className="d-flex flex-column align-items-start justify-content-end">

                <div>
                    <Row className='d-flex justify-content-center text-center'>
                        {configs[0]?.right?.title}
                    </Row>
                    <Row className='d-flex justify-content-center text-center'>
                        <Col className='d-flex justify-content-center align-items-center'>
                            <div className='font-size-16 fw-semibold greenish-info mt-1'>
                                {configs[0]?.right?.Currency}
                            </div>
                            <div className='font-size-20 fw-semibold greenish-info mx-1'>
                                {formatNumberToCurrency(data[1])}
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center text-center'>
                        {configs[0]?.right?.subHeading}
                    </Row>
                </div>
            </Col>
        </Row>
    );
}

export default IndicativeStats;
