import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Form, Row } from "reactstrap";
import { CardThreeBounce } from "../../../components";

import { DialogContext } from "../../../store/context/DialogContext";

import Profile from "./Tabs/Profile";

import TransferGroupService from "./TrasnferGroupService";

import Source from "./Tabs/Source";
import Desitination from "./Tabs/Destination";

const TransferGroup = (props) => {
  const { id, data, setData, setHasErr, hasErr } = props;

  const [freshData, setFreshData] = useState({});
  const [loading, setLoading] = useState(false);

  const { showConfirm, showError } = useContext(DialogContext);

  const navigate = useNavigate();

  async function fetch() {
    let transfergroupD = {};
    if (id) transfergroupD = await TransferGroupService.get(id);
    setFreshData(transfergroupD);
    setData(transfergroupD);
    setHasErr(false);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);

    fetch();
    setHasErr(false);
  }, [id]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(freshData)) {
      props?.onChange(true);
    } else {
      props?.onChange(false);
    }
  }, [data]);

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete TransferGroup ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        const response = await TransferGroupService.delete(id);

        navigate(`/transfergroup/edit`);
      } catch (e) {
        showError(e);
      }
    }
  };

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      <div className="p-0" style={{ overflowY: "auto", height: "100%", overflowX: "hidden" }}>
        <Form className="p-2 mt-3">
          <Row>
            <Profile className="bg-primary color-fff" data={data} setData={setData} />
          </Row>

          <Row>
            <Source className="bg-primary color-fff mb-3" data={data} setData={setData} />
          </Row>

          <Row className="mt-4">
            <Desitination className="bg-primary color-fff" data={data} setData={setData} />
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default observer(TransferGroup);
