import { observer } from "mobx-react-lite";
import { read, utils, writeFile } from 'xlsx';
import React, { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import {
  Button, Card, CardBody, Col, Form, Row
} from "reactstrap";
import { formatBytes } from "../../components";
import { I18nContext } from "../../store/context/i18nContext";
import { MDBDataTable } from "mdbreact"
import "./datatables.scss"

const TestingPage = () => {
  const [data, setData] = useState({ rows: [], columns: [] });
  const [dataHeader, setDataHeader] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([])
  const { t } = useContext(I18nContext);


  return (
    <>
      <div className="page-content">

        <Card className="mx-0 px-2 pt-5">
        Hello<br/>
        Hello<br/>
        Hello<br/>
        Hello<br/>
        </Card>
      </div>
      

    </>

  );
};

export default observer(TestingPage);
