import React from 'react';
import './style.css'; // Make sure to import the CSS file

const ColumnHeader = ({ children }) => {
    return (
        <div className="zr_col-header">
            {children}
        </div>
    );
};

ColumnHeader.Title = ({ children }) => {
    return (
        <div className="zr_col-header-title">
            {children}
        </div>
    );
};

ColumnHeader.Body = ({ children, maxHeight, style }) => {
    return (
        <div className="zr_col-header-body" style={{ maxHeight, ...style }}>
            {children}
        </div>
    );
};

export default ColumnHeader;
