// socketService.js
import io from 'socket.io-client';
import { SOCKET_BASE_URL } from '../../common/utils/Constants';
import { tokenUtil } from '../../util/TokenUtil';

export const SOCKET_EVENT = {
    LOCATION_UPDATE: 'LOCATION_UPDATE',
    TRIP_START: 'TRIP_START',
    TRIP_STOP: 'TRIP_STOP',
    TRIP_CREATED:'TRIP_CREATED'
}

class SocketService {
    constructor() {
        this.socket = null;
    }


    async initializeSocket() {
        console.log("socket initialized...")
        return await this.createSocketWithQueryParams();
    }

    async createSocketWithQueryParams(reconnect = false) {
        try { 
            const token = await tokenUtil.getBearerToken(); 
            if (!this.socket && token) {
                this.socket = io("", {
                    path : "/socket.io",
                    query: { Authorization: token }
                });
                this.configureSocket(reconnect);
            }
            // else {
            //     this.socket && this.socket?.close();
            //     this.socket = io(`http://localhost:3003?userId=${userId}`);
            //     this.configureSocket();
            // }

            return this.socket;

        } catch (error) {
            console.log("error connecting socket", error);
            return null;
        }
    }



    async configureSocket(reconnect = false) {
        this.socket.on('connect', () => {
            console.log('Connected to the socket');
        });
        if (this.socket) {

            this.socket.emit('clientEvent', { reconnect }, (response) => {
                console.log('Emitting client event response:', response);
                if (response.status !== 'success') {
                    this.socket.disconnect();
                } 
            });
        }

        this.socket.on('disconnect', () => {
            console.log('socket Disconnected');
            // this.handleReconnect();
        });

        // client-side
        this.socket.on("connect_error", async (err) => {
            try {
                // console.log(err instanceof Error); // true
                // console.log(err.message); // not authorized
                // console.log(err.data); // { content: "Please retry later" }
                if (err.message == "Unauthorized"){
                    await tokenUtil.updateToken();
                    // this.handleReconnect(true);
                }

            } catch (error) {
                //* means if refresh token also send error then disconnect the socket
                if (this.socket) {
                    this.socket.close(); // Close the existing socket
                    this.socket = null;
                }
            }
        });
    }

    handleReconnect() {
        if (this.socket) {
            this.socket.close(); // Close the existing socket
            this.socket = null; // Set the socket to null to prepare for reconnection
            return this.createSocketWithQueryParams(true);
        } else {
            return null
        }
    }


    getSocket() {
        //TODO here also handle if socket not initialized call initialized method
        return this.socket;
    }

}

const socketInstance = new SocketService();

export default socketInstance;
