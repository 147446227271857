import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useState } from "react";
import EditBooking from "../BookingsNew/EditBooking";
import GroupTourSidebarService from "../GroupTour/sideBar/GroupTourSidebarService";
import { DialogContext } from "../../store/context/DialogContext";

const DemoV3 = () => {
  const [data, setData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const {showMessage} = useContext(DialogContext);
  const [trip, setTrip] = useState(null);
  const [customerType, setCustomerType] = useState(null);

  const postSave = useCallback((updatedBookings, callGrid = false) => {
    console.log("hi")
    showMessage("postSave")
  }, []);

  const handleNavigation = useCallback((bookingID, deleted = false) => {
    showMessage("handleNavigation")

  }, []);


  return (
    <React.Fragment>
      <div className="page-content">

        <EditBooking
          // editID={editID}
          trip={trip}
          setTrip={setTrip}
          customerType={customerType}
          setCustomerType={setCustomerType}
          postSave={postSave}
          handleNavigation={handleNavigation}
          space_id={GroupTourSidebarService?.selectedFolder?._id}
        />

      </div>
    </React.Fragment>
  );
};

export default observer(DemoV3);
