import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./MessageAgentConstant";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";

class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = 40;
  filterOption = "";
  total = -1;
  version =0;

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterUrl) => {

    if (filterUrl) {
      this.filterOption = filterUrl;
    }
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl)
    );

    if (response?.status === 200) {
      this.records = response.data.rows;
      this.page = response?.data?.page;
      if (this.page === 1) this.total = response?.data?.total;
    }
  };

  edit = async function (data, id) {
    const response = await doPUT(ENDPOINTS?.update, data);
    if (response.status === 200) {
      this.version += 1;
    }

  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    return response?.data?._id;
  };

  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);

  };

}

const MessageAgentService = new Service();
export default MessageAgentService;

