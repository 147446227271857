import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditVehicleTypes from "./EditVehicleType";
import { STRUCTURE } from "./VehicleTypeConstant";
import { GridColumns } from "./VehicleTypeGridColumn";
import VehicleTypeService from "./VehicleTypeService";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";


const VehicleTypes = ({ insidePane }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/vehicle-type/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await VehicleTypeService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
  }, [])

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.name) {
      setHasErr(true)
      showError(t("Please enter all fields"));
      return;
    }
    setLoading(true);
    try {
      if (editID) {
        await VehicleTypeService.edit(detailData, editID);
        showMessage(t("Vehicle updated successfully."));
      } else {
        const vehicleTypeID = await VehicleTypeService.save(detailData);
        showMessage(t("Vehicle saved successfully."));
        if (!insidePane) navigate(`/vehicle-type/edit/${vehicleTypeID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setHasErr(false)
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await VehicleTypeService.delete(id);
        showMessage("Deleted", "Vehicle Deleted Successfully");
        if (!insidePane) navigate("/vehicle-type");
        setShowDetailPage(false);
      } catch (e) {
        showError(e);
      }
    }
  };

  return (
    <>
      <Layout
        small
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/vehicle-type");
          setEditID(null);
        }}
         
        title={t("Vehicle Types")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/vehicle-type/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={VehicleTypeService.page}
        rows={VehicleTypeService.rows}
        total={VehicleTypeService.total}
        fetch={VehicleTypeService.fetch}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem>Bulk Delete</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> Download </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={VehicleTypeService.records}
              total={VehicleTypeService.total}
              uiPreference="vehicle-type.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={setSelectedIDs}
              page={VehicleTypeService.page}
              rowsPerPage={VehicleTypeService.rowsPerPage}
              onPaginationChange={VehicleTypeService.onPaginationChange}
              renderLastCol={(vehicleType) => {
                return (
                  <>
                    <EditLink
                      onClick={() => {
                        if (!insidePane)
                          navigate(`/vehicle-type/edit/${vehicleType?._id}`);
                        setEditID(vehicleType?._id);
                        setShowDetailPage(true);
                      }}
                    />
                    <DeleteLink
                      onClick={(event) => onDelete(event, vehicleType?._id)}
                    />
                  </>
                );
              }}
            />
          </>
        </Layout.Table>

        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit Vehicle Type") : t("Add Vehicle Type")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditVehicleTypes
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {detailData?._id ? (
            <AuditLogDialog module="VehicleType" id={detailData?._id} />
          ) : null

          }
          {detailData?._id ?
            <DeleteButton loading={loading} onClick={(e) => onDelete(e, detailData?._id)} /> : null}
          <SaveButton loading={loading} onClick={onSave} />
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(VehicleTypes);
