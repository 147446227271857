
import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";

class TemplateSelect {
    templates = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function ({ kind, filterUrl }) {
        try {
            const response = await doGET(kind ? `/api/template/grid?kind=${kind}&${filterUrl}` : `/api/template/grid?${filterUrl ?? ""}`);

            if (response.status === 200) {
                this.templates = response?.data?.rows?.map((v) => {
                    return { label: v.name, value: v._id };
                });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const TemplateSelectService = new TemplateSelect();
export default TemplateSelectService;

