import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'; 
import "./styles.css"
import { CustomDateCellWrapper, Toolbar, dayPropGetter, eventStyleGetter, monthHeader } from './calendarComponents';
import { DialogContext } from '../../store/context/DialogContext';

const localizer = momentLocalizer(moment); 

function CalendarView({events,onChange,showTimeSelector=false,onTimeChange=()=>{},time="",disabled=false}) { 
  const {showError}=useContext(DialogContext)
  const [viewedMonthStart, setViewedMonthStart] = useState(moment().startOf('month'));
  const [viewedMonthEnd, setViewedMonthEnd] = useState(moment().endOf('month'));

  const formattedEvents=useMemo(() => events.map((event) => {
    const month=moment(event,'YYYYMMDD').format('MM');
    const year=moment(event,'YYYYMMDD').format('YYYY');
    const date=moment(event,'YYYYMMDD').format('DD');
    return { 
     start: new Date(year, month - 1, date), 
     end: new Date(year, month - 1, date),
     allDay:true
    }
  }), [events])  

 
  const handleNavigate = (actionDate) => {
    setViewedMonthStart(moment(actionDate).startOf('month'));
    setViewedMonthEnd(moment(actionDate).endOf('month'));
  };

 
  const onSelectEvent = useCallback((event) => {
     if(events?.includes(parseInt(moment(event?.start).format('YYYYMMDD')))){
       onChange(events?.filter((e) => e != moment(event?.start)?.format('YYYYMMDD')))
     }
  }, [events,onChange])

  const handleSelectSlot = useCallback((event) => {
    const isPastDate = moment().isAfter(event?.start, 'day');
    if(isPastDate){
      showError('Past date not allowed');
      return;
    }
    onChange( Array.from(new Set([...events, ...event?.slots?.map(slot=>moment(slot).format('YYYYMMDD'))])))
    },
    [onChange,showError]
  )

  return (
    <>
      <Calendar
        localizer={localizer}
        onSelectEvent={onSelectEvent}
        onSelectSlot={handleSelectSlot}
        onNavigate={handleNavigate}
        events={formattedEvents}
        startAccessor="start"
        endAccessor="end"
        views={["month"]}
        style={{ height: "calc(100vh - 150px)",pointerEvents:disabled?'none':'auto' }}
        eventPropGetter={eventStyleGetter}
        dayPropGetter={dayPropGetter}
        selectable
        components={{
          toolbar: (props) => <Toolbar time={time} showTimeSelector={showTimeSelector} onTimeChange={onTimeChange}  {...props} />,
          dateCellWrapper: CustomDateCellWrapper,
          month: {
            header: monthHeader,
          },
        }}
      />
    </>
  )
}

export default CalendarView
