import React from "react";
import { useNavigate } from "react-router-dom";


const PrintLink = (props) => {
  let navigate = useNavigate();
  return (
    <div style={{ display: "inline-block", color: "primary", cursor:"pointer" }} onClick={() => { if (props?.to) navigate(props?.to); props?.onClick() }}>
      <i style={{ color: "primary" }} className="bx bxs-printer color-primary font-size-18" />
    </div>
  );
};
export default PrintLink;