import { makeAutoObservable, toJS } from "mobx";
import { doGET, doPUT } from "../../util/HttpUtil";

class Service {
    records = null
    total = -1;
    version = 0;
    constructor() {
        makeAutoObservable(this);
    }

    fetch = async () => {

        try {
            const response = await doGET('/api/corporate-config/detail/setUpStatus');

            if (response?.status === 200) {
                const data = response?.data;
                this.records = data
            }
        } catch (error) {
            console.log(error)
        }
    };

    edit = async function (module) {
        if (!toJS(this.records)) {
            await this.fetch()
        }

        const data = {
            ...toJS(this.records),

            setUpStatus: {
                ...toJS(this.records?.setUpStatus),
                [module]: true

            }
        }
        const response = await doPUT('api/corporate-config/update/setUpStatus', data);
        if (response.status === 200) {
            this.version += 1;
            this.fetch()
        }
    };
}

const SetupProgressService = new Service();
export default SetupProgressService;

