export const ENDPOINTS = {
  grid: (page, sizePerPage) => {

    let s = ` /api/admin/content/grid?rows=${sizePerPage}`;
    return s + "&page=" + page;
  },
  get: (id) => `/api/content/${id}/detail`,
  saveNew: `/api/content/new`,
  edit: (id) => `/api/content/${id}/edit`,
  delete: (id) => `/api/content/${id}/delete`,
};

