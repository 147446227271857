import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import EntityService from "../Company/CompanyService";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import OfficeService from "../Office/OfficeService";
import SupervisorService from "../Supervisor/SupervisorService";
import EditSuppliers from "./EditSupplier";
import { STRUCTURE } from "./SupplierConstant";
import { GridColumns } from "./SupplierGridColumns";
import Supplierservice from "./SupplierService";

const Suppliers = ({ filterUrlProp, value, insidePane, multiMode, onSelect = () => { } }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { userContent } = useContext(UserContext);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/supplier/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)


  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await Supplierservice.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane, filterUrlProp)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])

  }, [])

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])


  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.name) {
      showError(t("Please enter Supplier Name"));
      return;
    }
    // if (!detailData?.entityData?.name) {
    //       showError(t("Please enter Brand Name"));
    //       return;
    //     }
    //     if (!detailData?.entityData?.fullName) {
    //       showError(t("Please enter Full Name"));
    //       return;
    //     }
    //       showError(t("Please enter Office Name"));
    //       return;
    //     }
    //     if (!detailData?.supervisorData?.email) {
    //       showError(t("Please enter Supervisor Email"));
    //       return;
    //     }
    setLoading(true);
    try {
      if (editID) {
        await Supplierservice.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Supplier Updated successfully"));
        await fetchData(filterURL);
        navigate(`/supplier/edit/${editID}`);
      } else {
        if (detailData?.supervisorData?.supervisorExtn?.roles.length === 0) {
          setHasErr(true)
          showError(t("Please Enter Roles For Supervisors"));
          return;
        }
        const supplier = await Supplierservice.save(detailData);
        setLoading(false);
        if (supplier) setEditID(supplier)

        let SupplierOffice = null;
        if (detailData?.officeData?.name) {
          SupplierOffice = await OfficeService.save({ data: { ...detailData?.officeData, org_id: supplier }, org_id: supplier })
        }
        if (detailData?.entityData?.name) {
          EntityService.save({ ...detailData?.entityData, supplier_id: supplier, office_id: SupplierOffice })
        }

        if (detailData?.supervisorData?.name && detailData?.supervisorData?.email && detailData?.supervisorData?.phoneNo && detailData?.supervisorData?.salutation) {
          SupervisorService.save({
            data: {
              ...detailData?.supervisorData
              , adminOf: supplier
            }, corp_id: supplier
          })
        }
        await fetchData(filterURL);
        showMessage(t("Supplier saved successfully."));
        if (!insidePane) navigate(`/supplier/edit/${supplier}`);
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
    setHasErr(false)
  };


  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await Supplierservice.delete(id);
        setLoading(false);
        showMessage(t("Supplier Deleted Successfully"), t("Deleted"));
        setShowDetailPage(false);
        navigate(`/supplier`);
      } catch (e) {
        showError(e);
        setLoading(false);
      }
    }
  };

  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await Supplierservice.bulkDelete(selectedIDs);
        setLoading(false);
        showMessage(t("Supplier Deleted Successfully"), t("Deleted Successfully"));
      } catch (e) {
        showError(e);
        setLoading(false);
      }
    }
  };

  const renderLastCol = useCallback((supplier) => {
    return (
      <>
        {userContent?.rights?.includes(2702) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/supplier/edit/${supplier?._id}`);
              setEditID(supplier?._id);
              setShowDetailPage(true);
            }}
          />
        }
        {userContent?.rights?.includes(2703) ?
          <DeleteLink
            onClick={(event) => onDelete(event, supplier?._id)}
          />
          : null}

        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(supplier?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])

  const markCompletedFunc = async () => {
    try {
      await SetupProgressService?.edit("supplier");
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch()
    }
  }
    , [SetupProgressService?.records])

  return (
    <React.Fragment>
      <>
        <Layout
          hideAdd={!userContent?.rights?.includes(2701)}
          large
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/supplier");
            setEditID(null);
          }}
           
          title={t("Suppliers")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/supplier/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={Supplierservice.page}
          rows={Supplierservice.rows}
          total={Supplierservice.total}
          fetch={Supplierservice.fetch}
        >
          <Layout.OnAlert>
            {!SetupProgressService?.records?.setUpStatus?.['supplier'] ? <Alert color="danger" role="alert">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  Please list all your regular suppliers here.
                </div>
                <div>
                  <Button onClick={markCompletedFunc}>Mark Complete</Button>
                </div>
              </div>          </Alert> : <></>}
          </Layout.OnAlert>
          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  {userContent?.rights?.includes(2703) ?
                    <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                    : null}
                  <div class="dropdown-divider"></div>
                  <DropdownItem>{t("Upload")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem> {t("Download")} </DropdownItem>
                </>
              </DropdownMenu>
            </div>
          </Layout.ActionMenu>

          <Layout.Table>
            <DataGrid
              gridLoading={loading}
              data={Supplierservice.records}
              total={Supplierservice.total}
              uiPreference="Supplier.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={(v) => {
                onSelect(v)
                setSelectedIDs(v)
              }}
              page={Supplierservice.page}
              rowsPerPage={Supplierservice.rowsPerPage}
              onPaginationChange={Supplierservice.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </Layout.Table>
          <Layout.DetailPageTitle>
            {editID ? t("Edit Supplier") : t("Add Supplier")}
          </Layout.DetailPageTitle>

          <Layout.DetailPageBody>
            <EditSuppliers
              loading={loading}
              editId={editID}
              onChange={(v) => {
                setDetailData(v)
              }}
              hasErr={hasErr}
            />
          </Layout.DetailPageBody>

          <Layout.DetailPageFooter>
            {detailData?._id ? (
              <AuditLogDialog module="Supplier" id={detailData?._id} />
            ) : null

            }
            {(editID && userContent?.rights?.includes(2703)) ?
              <DeleteButton loading={loading} onClick={(e) => onDelete(e, detailData?._id)} />
              : null}
            {userContent?.rights?.includes(2702) ?
              <SaveButton loading={loading} onClick={onSave} />
              : null}
          </Layout.DetailPageFooter>
        </Layout>
      </>

    </React.Fragment >
  );
};
export default observer(Suppliers);
