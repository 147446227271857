import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteLink,
  EditLink,
  Layout
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditExpenses from "./EditExpense";
import { STRUCTURE } from "./ExpenseConstant";
import { GridColumns } from "./ExpenseGridColumns";
import ExpenseService from "./ExpenseService";
import { dateToTimestamp } from "../../util/Util";

const Expense = ({ value, insidePane, multiMode, onSelect = () => { }, supplier_id = null }) => {
  let { expense_id } = useParams();
  const location = useLocation();
  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const { userContent } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({ currency: 'INR' });
  const [editID, setEditID] = useState(expense_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/expense/create" || expense_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [fromDate, setFromDate] = useState(null);
  const [tillDate, setTillDate] = useState(null);

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    if (!!fromDate && !!tillDate) {
      filterUrl = filterUrl+`&date=btw[${encodeURIComponent(`${fromDate},${tillDate}`)}]`;
    }
    try {
      await ExpenseService.fetch(filterUrl, supplier_id);
      setLoading(false);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [fromDate,tillDate])

  useEffect(() => {
    setEditID(expense_id)
  }, [expense_id])

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await ExpenseService.delete(id);
        setLoading(false);
        showMessage(t(" Expense Deleted"), t("Deleted"));
        setLoading(false);
        setShowDetailPage(false);
      } catch (e) {
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback((expense) => {
    return (
      <>
        {userContent?.rights?.includes(4402) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/expense/edit/${expense?._id}`);
              setEditID(expense?._id);
              setShowDetailPage(true);
            }}
          />
        }
        {userContent?.rights?.includes(4403) ?
          <DeleteLink
            onClick={(event) => onDelete(event, expense?._id)}
          />
          : null}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(expense);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])

  return (
    <>
      <Layout
        medium
        showDateRange
        fromDate={fromDate}
        tillDate={tillDate}
        setFromDate={setFromDate}
        setTillDate={setTillDate}
        hideAdd={!userContent?.rights?.includes(4401)}
        hideheader={true}
        hidefooter={true}
        showDetailPage={showDetailPage}
        backDetailPage={() => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/expense");
          setEditID(null);
        }}

        title={t("Expenses")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/expense`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={ExpenseService.page}
        rows={ExpenseService.rows}
        total={ExpenseService.total}
        fetch={ExpenseService.fetch}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                {userContent?.rights?.includes(4103) ?
                <>
                  <DropdownItem>{t("Bulk Delete")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  </>
                  : null}
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Download")} </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <DataGrid

            gridLoading={loading}
            data={ExpenseService.records}
            total={ExpenseService.total}
            uiPreference="expense.grid"
            headers={GridColumns}
            selectedIDs={selectedIDs}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            page={ExpenseService.page}
            rowsPerPage={ExpenseService.rowsPerPage}
            onPaginationChange={ExpenseService.onPaginationChange}
            renderLastCol={renderLastCol}

          />
        </Layout.Table>

        <Layout.DetailPageBody>
          <EditExpenses
            onSave={() => { setShowDetailPage(false); fetchData(filterURL) }}
            onDelete={() => {
              setShowDetailPage(false)
              fetchData(filterURL)
            }}
            insidePane={insidePane}
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
          />
        </Layout.DetailPageBody>

      </Layout>
    </>
  );
};
export default observer(Expense);
