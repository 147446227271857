const _ = require('lodash');

export const GridColumns = {

    version: 1,
    columns: [
        {
            title: "Name",
            accessor: "name",
            show: true,
        },
        {
            title: "Price",
            renderer: (dataObj) => {
                return <div className="">
                    <span className='mr-2'>
                        {dataObj?.currency} {"  "}
                    </span>
                    <span className="pr-2">
                        {_.toNumber(dataObj?.unitPrice).toLocaleString()}
                    </span>

                </div>;
            },
            show: true,
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
