import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../../../pages/Tax/TaxConstant";
import { doGET } from "../../../util/HttpUtil";

class TaxSelect {
    taxes = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (props) {
        try {
            const response = await doGET(ENDPOINTS.grid(0,-1));
            if (response.status === 200) {
                this.taxes = response?.data?.rows?.map((v) => {
                    return { ...v, label: v.name, value: v._id };
                });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const TaxSelectService = new TaxSelect();
export default TaxSelectService;
