import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./TransactionConstant";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";

class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = 40;
  filterOption = "";
  total = -1;


  constructor() {
    makeAutoObservable(this);
  }

  determinePaymentFrom = (row) => {
    if (row.expense_id) return "Expense";
    if (row.paymentMade_id) return "Payment Made";
    if (row.paymentReceived_id) return "Payment Received";
    return "--";
};

  getSelectObject = (obj) => {
    var map = [];
    for (var i in obj) {
      map.push({ label: i, value: obj[i] });
    }
    return map;
  };

  // fetch = async (filterUrl="") => {
  //   if (filterUrl) {
  //     console.log(filterUrl)
  //     this.filterOption = filterUrl;
  //   }
  //   const response = await doGET(
  //     ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl)
  //   );

  //   if (response?.status === 200) {
  //     this.records = response.data.rows.map(row => ({
  //       ...row,
  //       addedFrom: row.expense_id ? "Expense" :
  //               row.paymentMade_id ? "Payment Made" :
  //               row.paymentReceived_id ? "Payment Received" :
  //               "--" 
  //     }));
  //     this.page = response?.data?.page;
  //     if (this.page === 1) this.total = response?.data?.total;
  //   }
  // };
  fetch = async (filterUrl) => {
    let adjustedFilterUrl = filterUrl;

    const params = new URLSearchParams(filterUrl);
    const addedFromFilter = params.get('addedFrom');

    params.delete('addedFrom');

    adjustedFilterUrl = params.toString();

    const response = await doGET(
        ENDPOINTS.grid(this.page, this.rowsPerPage, adjustedFilterUrl)
    );

    if (response?.status === 200) {
        let filteredRows = response.data.rows;
        
        if (addedFromFilter === 'Expense') {
            filteredRows = filteredRows.filter(row => row.expense_id);
        } else if (addedFromFilter === 'Payment Made') {
            filteredRows = filteredRows.filter(row => row.paymentMade_id);
        } else if (addedFromFilter === 'Payment Received') {
            filteredRows = filteredRows.filter(row => row.paymentReceived_id);
        }

        this.records = filteredRows.map(row => ({
            ...row,
            addedFrom: this.determinePaymentFrom(row)
        }));

        this.page = response?.data?.page;
        if (this.page === 1) this.total = response?.data?.total;
    }
};

  edit = async function (data, id) {
    await doPUT(ENDPOINTS?.update, data);
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    return response?.data?._id;
  };

  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch({ key: 2 });
    }
  };

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(  this.filterOption );
  };

}

const TransactionService = new Service();
export default TransactionService;



