import React, { useEffect, useState } from 'react'

const TimeLine = ({ icon, boardingDate, boardingTime, landingDate, landingTime, byHours = true }) => {
    const [duration, setDuration] = useState("")
    const dashes = '------------------------------------------------------';

    const dotStyle = {
        width: 15,
        height: 15,
        backgroundColor: "#9E9E9E",
        borderRadius: 100,
        display: "inline-block"
    }
    const lineStyle = { whiteSpace: 'nowrap', overflow: 'hidden', flex: 1, color: "#9E9E9E", overflowX: "hidden" }
    const iconContainer = {
        width: 40,
        height: 40,
        borderRadius: 100,
        border: "1px solid #9E9E9E",
    }

    useEffect(() => {
        function calculateDuration(startDate, startTime, endDate, endTime) {
            const startDateTimeStr = `${startDate} ${startTime}`;
            const endDateTimeStr = `${endDate} ${endTime}`;

            const parseDateTime = (dateTimeStr) => {
                const year = parseInt(dateTimeStr.slice(0, 4));
                const month = parseInt(dateTimeStr.slice(4, 6)) - 1;
                const day = parseInt(dateTimeStr.slice(6, 8));
                const hours = parseInt(dateTimeStr.slice(9, 11));
                const minutes = parseInt(dateTimeStr.slice(11, 13));
                return new Date(year, month, day, hours, minutes);
            };

            const startDateTime = parseDateTime(startDateTimeStr);
            const endDateTime = parseDateTime(endDateTimeStr);

            const durationMillis = endDateTime - startDateTime;
            const totalMinutes = Math.floor(durationMillis / (1000 * 60));
            const totalHours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            const days = Math.floor(totalHours / 24);

            if (byHours) {
                if (isNaN(totalHours) || isNaN(minutes)) setDuration("");
                else setDuration(`${totalHours}h ${minutes}m`);
            }
            else {
                if (isNaN(days)) setDuration("");
                else setDuration(`${days}d`);
            }
        }

        calculateDuration(boardingDate, boardingTime, landingDate, landingTime)
    }, [boardingDate, boardingTime, landingDate, landingTime])

    return (
        <div className='w-100'>
            <p className='p-0 m-0 text-center' style={{ color: "#9E9E9E" }}>{duration}</p>
            <div className="d-flex justify-content-between align-items-center w-100">
                <div style={dotStyle} className='me-1' />
                <div style={lineStyle}>
                    {dashes + dashes}
                </div>
                <div className="d-flex justify-content-center align-items-center mx-1" style={iconContainer}>
                    {icon}
                </div>
                <div style={lineStyle}>
                    {dashes + dashes}
                </div>
                <div style={dotStyle} className='ms-1' />
            </div>
        </div>
    )
}

export default TimeLine;