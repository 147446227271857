import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { CardThreeBounce } from "../../components";
import IncidentStatus from "../../components/Dropdowns/IncidentStatus/IncidentStatus";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import IncidentService from "./IncidentService";
const moment = require('moment');

const EditIncidents = ({ editId, onChange = () => { }, hasErr }) => {
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const fetch = async () => {
        try {
            let incident = await IncidentService.get(editId);
            setData(incident);
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        setData(null)
        setLoading(true);
        if (editId) fetch();
        setLoading(false)
    }, [editId]);

    useEffect(() => {
        onChange(data)
    }, [data]);
    console.log("---------", data)

    const seconds = data?.at;
    const dateTime = moment.unix(seconds);
    const date = dateTime.format('DD-MM-YYYY');
    const time = dateTime.format('HH:mm A');
    return (
        <React.Fragment>
            {loading ?
                <CardThreeBounce /> :
                <Form
                    className="p-3"
                    style={{ height: "100%", padding: "2rem", margin: 0 }}
                >
                    <Row style={{ marginTop: 0, background: "#0000ff11", padding: "1rem", borderRadius: "0.5rem", fontSize: "12px", }}>
                        <div className="d-flex col-8">
                            <div className="col-6 col-md-1">{t("No.")} </div>
                            <div className="d-flex col-6 col-md-11" style={{ marginLeft: "0.5rem" }}>
                                {data?.no}
                            </div>
                        </div>
                        <div className="d-flex col-8" style={{ marginTop: "1rem" }}>
                            <div className="col-6 col-md-1">{t("Issue")} </div>
                            <div className="d-flex col-6 col-md-11" style={{ marginLeft: "0.5rem" }}>
                                {data?.issue}
                            </div>
                        </div>
                        <div className="d-flex col-8" style={{ marginTop: "1rem" }}>
                            <div className="col-6 col-md-1"> {t("Date")} </div>
                            <div className="d-flex col-6 col-md-11" style={{ marginLeft: "0.5rem" }}>&nbsp;{date}
                            </div>
                        </div>
                        <div className="d-flex col-8" style={{ marginTop: "1rem", }}>
                            <div className="col-6 col-md-1"> {t("Reported At")} </div>
                            <div className="d-flex col-6 col-md-11" style={{ marginLeft: "0.5rem" }}>
                                {time} &nbsp;
                            </div>
                        </div>
                        <div className="d-flex col-8" style={{ marginTop: "1rem" }}>
                            <div className="col-6 col-md-1">{t("Detail")}</div>
                            <div className="col-6 col-md-11" style={{ marginLeft: "0.5rem" }}>
                                {data?.detail}
                            </div>
                        </div>
                    </Row>
                    <Row className="col-12 col-md-4" style={{ marginTop: "1rem" }}>
                        <IncidentStatus
                            placeholder={t("Incident status ")}
                            value={data?.status}
                            required={true}
                            label={"Status"}
                            onChange={(v) => setData({ ...data, status: v > 5 ? 5 : (v.value) })}
                            type="number"
                        />
                    </Row>

                    <Row className=" mb-3" style={{ marginTop: "1rem" }}>
                        <div>
                            <div style={{ color: "rgba(121, 121, 121, 0.694)", marginBottom: "0.2rem", }}>{t("Remark")}</div>
                            <textarea className="inputfield" rows={5} style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", }}
                                value={data?.remark}
                                onChange={(e) => {
                                    setData({ ...data, remark: e.target.value });
                                }}
                            >
                            </textarea>
                        </div>
                    </Row>
                </Form>
            }
        </React.Fragment >
    );
};

export default observer(EditIncidents);
