import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { observer } from "mobx-react-lite";
import { PrintLink, Pagination, Breadcrumbs, Filter } from "../../components";
import InvoicesService from "./InvoiceService";


import { STRUCTURE } from "./InvoiceConstant";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { DropdownButton } from "react-bootstrap";


const Invoices = () => {
  const [dropDown, setDropDown] = useState({});

  const fetchData = async (filterUrl) => {
    await InvoicesService.fetch(filterUrl);
  };
  useEffect(() => {
    fetchData();
    
  }, []);

  const getDueDate = (x)=>{
    var date = x.toString().substring(0, 4) + "-" + x.toString().substring(4, 6)+ "-" + x.toString().substring(6, 8);
    return date;
  }

  useEffect(()=>{
    InvoicesService.fetch();
  }, [])

  return (
    <React.Fragment>
      <div className="page-content-2">
        <Row >
            <Card className="mx-0 pt-3">
              <CardTitle className="px-3">
                <div className={`d-flex justify-content-between col-"12" mb-3`}>
                  <Breadcrumbs title="" breadcrumbItem="Invoices" />
                  <div className="flex-1">
                    <Filter
                      className="mb-2"
                      structure={STRUCTURE}
                      applyFunc={fetchData}
                    />
                     {/* filterStructure={STRUCTURE}
                      onApplyFilter={fetchData} */}
                  </div>
                </div>
              </CardTitle>
              <CardBody>
                <div className="table-rep-plugin table-responsive mb-0" data-pattern="priority-columns">
                  <Table className="table mb-0 ">
                    <Thead className="thead-dark">
                      <Tr >
                        <Th className="col-1">Invoice #</Th>
                        <Th className="col-1">Date Created</Th>
                        <Th className="col-1">For Month</Th>
                        <Th className="col-1">Due Date</Th>
                        <Th className="col-1">Amount</Th>
                        <Th className="col-1">TDS Deduction</Th>
                        <Th className="col-1">Payable</Th>
                        <Th className="col-2">Status</Th>
                        <Th className="col-1">Action</Th>
                        <Th className="col-2"></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {InvoicesService.records?.map((invoice, idx) => (
                        <Tr
                          k={idx}
                          className={`striped-table`}
                          key={invoice._id}
                        >
                          <Td>{invoice.invoiceNo}</Td>
                          <Td>{invoice.createdAtStr} </Td>
                          <Td>{invoice.startDate.substring(0,7)}</Td>
                          <Td>{invoice.dueDate?getDueDate(invoice.dueDate):null}</Td>
                          <Td>{invoice.fare?.currency + " " + invoice.fare?.total}</Td>
                          <Td>{(invoice.tds?.tdsPercent?(invoice.tds.tdsPercent + "% | ") : null) + (invoice.tds !== undefined?(invoice.tds.tdsAmount):null)}</Td>
                          <Td>{invoice.fare?.total - (invoice?.tds?.tdsAmount ? (invoice?.tds?.tdsAmount):(0))}</Td>
                          <Td>{invoice.invoiceStatus}</Td>
                          <Td>{<PrintLink onClick={()=>{InvoicesService.print(invoice._id, invoice.invoiceNo)}}/>}</Td>
                          <Td className="align-items-center" style={{display:"flex", flexDirection:"row"}}>
                            <DropdownButton
                              isopen={dropDown[invoice._id]}
                              title="Download Report"
                              toggle={() => {
                                var show = {};
                                for(var i in dropDown){
                                  if(i !== invoice._id) show[i] = false;
                                  else show[i] = true;
                                }
                                setDropDown(show)
                              }}
                              style={{
                                margin: '5px',
                              }}
                            >
                                <DropdownItem href={`/api/corp/premium/subscription/invoice/${invoice._id}/trips`}  target="_blank" download={`${invoice.invoiceNo}.xlsx`}>Download Trips</DropdownItem>
                                <DropdownItem href={`/api/corp/premium/subscription/invoice/${invoice._id}/apilogs`} target="_blank" download={`${invoice.invoiceNo}.xlsx`}>Download API Logs</DropdownItem>
                            </DropdownButton>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </CardBody>

              <Pagination
                page={InvoicesService.page}
                rows={InvoicesService.rows}
                total={InvoicesService.totalRecords}
                onChange={InvoicesService.onPaginationChange}
              />
            </Card>

        </Row>
      </div>
    </React.Fragment>
  );
};

export default observer(Invoices);
