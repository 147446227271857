import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
// import Lead from "../../../pages/Lead/Lead";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, Modal, ModalComponent, SearchButton } from "../../index";
import LeadSelectService from "./LeadSelectService";

const LeadSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  space_id,
  outline,
  asLabel
}) => {

  const [leads, setLeads] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const updateLeads = () => {
    setLeads(
      toJS(LeadSelectService.leads)?.map((lead) => {
        if (
          (space_id == lead?.space_id)
        ) {
          return {
            ...lead,
            value: lead._id,
            label: lead.title
          };
        }
        return null;
      }).filter(Boolean)
    );
  };

  const fetchLeads = async () => {
    try {
      await LeadSelectService.fetch(space_id);
      updateLeads()
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    fetchLeads();
  }, [space_id]);


  useEffect(() => {
    updateLeads()
  }, [LeadSelectService.leads]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectLead = (selectedValue) => {
    onChange({ ...selectedValue, value: selectedValue?._id });
    closeModal();
  };

  const selectLeads = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Leads")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={leads}
      required={required}
      value={value}
      label={label || (!isDisabled && t("Lead"))}
      error={!value ? t(`Please Select ${label || "Lead"}`) : ""}
      showErr={showErr}
      inline={inline}
      outline={outline}
      borderNone={borderNone}
    // RightComponent={() => (
    //   <SearchButton onClick={openModal} />
    // )}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Leads")} />
      )}
      // showBottomBar={true}
      className={className}
      outline={outline}
      onChange={selectLeads}
      data={leads}
      value={value ? leads.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Leads"}`) : ""}
      required={required}
      label={label || (!isDisabled && t("Select Leads"))}
      showErr={showErr}
    // RightComponent={() => (
    //   <SearchButton onClick={openModal} />
    // )}
    />
  );

  return (
    <>
      {asLabel
        ? multi ?
          value?.length > 0 ?
            <div>
              {value?.map((selectedValue, index) => {
                const lead = leads.find((lead) => lead.value == selectedValue);
                return (
                  <span key={selectedValue}>
                    {lead ? lead.label : 'Unknown Lead'}
                    {index < value.length - 1 && ", "}
                  </span>
                );
              })}
            </div>
            : <div style={{ color: "grey" }}>--</div>
          : (<div>{leads?.find((lead) => lead?.value == value)?.label ?? <div style={{ color: "grey" }}>--</div>}</div>) :
        <>

          {/* {modal && (
            <ModalComponent
              position={"top"}
              size={"large"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Lead")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <Lead
                  value={value}
                  insidePane={true}
                  multiMode={multi}
                  onSelect={multi ? selectLeads : selectLead}
                />
              </ModalComponent.Body>

            </ModalComponent>
          )} */}


          {leads.length > 0
            ? multi
              ? renderSelectFieldMultiple()
              : renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      }

    </>

  );
};

export default observer(LeadSelect);
