import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { ENDPOINTS } from "./DashboardConstant";

class Service {
    records = [];
    STRUCTURE = [];
    page = 1;
    rowsPerPage = 40;
    filterOption = "";
    total = -1;
    forSupplier = false;

    constructor() {
        makeAutoObservable(this);
    }

    fetchInvoices = async (filter) => {
        // const response = await doPOST(ENDPOINTS.getMonthlyInvoice(isCurrent, month));
        const response = await doPOST(ENDPOINTS.getInvoices,filter);
        if (response?.status === 200) {
            return response?.data?.length ?response.data: [];
        }
    };
    fetchStats = async (filter) => {
        const response = await doPOST(ENDPOINTS.getStats,filter);
        if (response?.status === 200) {
            return response?.data?.length ?response.data[0]: {}
        }
    };
    fetchVehicleStats = async (filter) => {
        const response = await doPOST(ENDPOINTS.getVehicleStats,filter);
        if (response?.status === 200) {
            return response?.data?.length ?response.data: []
        }
    };
    fetchRegisteredCustomers = async (filter) => {
        const response = await doPOST(ENDPOINTS.getRegisteredCustomers,filter);
        if (response?.status === 200) {
            return response?.data?.length ?response.data: []
        }
    };
    fetchLoggedInCustomers = async (filter) => {
        const response = await doPOST(ENDPOINTS.getLoggedInCustomers,filter);
        if (response?.status === 200) {
            return response?.data?.length ?response.data: []
        }
    };
    fetchSOSStats = async (filter) => {
        const response = await doPOST(ENDPOINTS.getSOSStats,filter);
        if (response?.status === 200) {
            return response?.data?.length ?response.data: []
        }
    };
    fetchEmployeeStats = async (filter) => {
        const response = await doPOST(ENDPOINTS.getEmployeeStats,filter);
        if (response?.status === 200) {
            return response?.data?.length ?response.data: []
        }
    };
    fetchTripStats = async (filter) => {
        const response = await doPOST(ENDPOINTS.getTrips,filter);
        if (response?.status === 200) {
            return response?.data?.length ?response.data: [];
        }
    };
    fetchTripRevenueStats= async(filter)=>{
        const response = await doPOST(ENDPOINTS.getTripsRevenue,filter);
        if (response?.status === 200) {
            return response?.data?.length ?response.data: [];
        }
    }
    fetchTripsMonthly = async (filter) => {
        const response = await doPOST(ENDPOINTS.getStats,filter);
            // ENDPOINTS.getTripsMonthly(startDate, endDate)
        if (response?.status === 200) {
            return response?.data?.length ?response.data[0]: {}
        }
    };
    fetchLastXdaysTrip = async (X) => {
        const response = await doGET(
            ENDPOINTS.getLast10daysTrip()
        );
        if (response?.status === 200) {
            return response.data
        }
    };


    fetchYearlyInvoice = async (month) => {
        const response = await doPOST(
            ENDPOINTS.getYearlyInvoice(month)
        );
        if (response?.status === 200) {
            return response.data
        }
    }
    edit = async function (data, id) {
        await doPUT(ENDPOINTS?.update, data);
    };

    get = async (rateNo, forSupplier) => {
        const response = await doGET(ENDPOINTS.fetchAll(rateNo, forSupplier));
        if (response.status === 200) {
            return response.data.rows ? response.data.rows : response;
        }
    };

    save = async (data) => {
        this.records.push(data);
        const response = await doPOST(ENDPOINTS.save, data);
        return response?.data?._id;
    };

    delete = async (id) => {
        const response = await doDELETE(ENDPOINTS.delete(id));
        if (response.status === 200) {
            this.fetch({ key: 2 });
        }
    };

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch({ filterUrl: this.filterOption });
    };

    onSupplierChange = (isSupplier) => {
        this.forSupplier = isSupplier;
    };
}

const DashboardService = new Service();
export default DashboardService;
