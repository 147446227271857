import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { Button, Row } from 'reactstrap';
import { areTripsValidForModification } from '../../helpers/general';
import BookingService from '../../pages/Booking/BookingService';
import { DialogContext } from '../../store/context/DialogContext';
import { doPUT } from '../../util/HttpUtil';
import DataGrid from '../DataGrid/DataGrid';
import ClientSelect from '../Dropdowns/ClientSelect/ClientSelect';
import CompanySelect from '../Dropdowns/Entity/CompanySelect';
import ModalComponent from '../Modal/Modal';
import { GridColumns } from './BookingColumns';

function BulkModification({ visible, onClose, selectedIDs, onSave = () => { } }) {
  const [loading, setLoading] = useState(false);
  const [trips, setTrips] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { showError, showMessage } = useContext(DialogContext);

  const fetchTrips = async () => {
    setLoading(true);
    try {
      const response = await BookingService.postGrid(`_id=in[${(selectedIDs ?? [])?.filter(Boolean)?.join(',')}]&rows=-1&withExtn=true`);
      if (response?.length) {
        setTrips(response);
        const { isValid, message } = areTripsValidForModification(response)
        if (!isValid) {
          showError((message));
          onClose()
          return;
        } 
      }
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedClient(null);
    setSelectedCompany(null);
    if (visible) fetchTrips();
  }, [visible]);

  const handleClientChange = useCallback((v) => {
    setSelectedClient(v);
    setSelectedCompany(null);
  }, []);

  const handleCompanyChange = useCallback((v) => {
    setSelectedCompany(v);
  }, []);

  const handleSave = async () => {
    if (!selectedClient || !selectedCompany) {
      showError("Please select both client and company");
      return;
    }

    setLoading(true);
    try {
      for (const trip of trips) {
        const updatedTrip = {
          ...trip,
          client: {
            ...trip.client,
            client_id: selectedClient.value,
            company_id: selectedCompany.value,
            name: selectedClient.label
          }
        };
        await doPUT(`/api/trip/update-all`, {billingUpdates : false, trips : [updatedTrip]});
      }
      showMessage("All trips updated successfully");
      onSave();
      onClose(false);
    } catch (error) {
      showError("Error updating trips: " + error.message);
    } finally {
      setLoading(false);
    }
  };



  return (
    <ModalComponent
      position={"top"}
      size={"full-screen"}
      isOpen={visible}
      onToggle={() => onClose(false)}
    >
      <ModalComponent.Title>
        <Row>
          <span style={{ fontSize: "15px" }}>Bulk Modification</span>
        </Row>
      </ModalComponent.Title>
      <ModalComponent.Body>
        <div style={{ fontSize: "12px" }}>
          <div className="d-flex w-100 row flex-1 mb-3">
            <div className="col-5 pe-0">
              <ClientSelect
                value={selectedClient?.value}
                onChange={handleClientChange}
                innerContainerClassName="input-group-pre"
                className="ms-2 me-0 w-100"
                outline={true}
              />
            </div>
            <div className="col-sm-5 p-0">
              <CompanySelect
                className="ms-2 w-100"
                outline
                corpType="client"
                isDisabled={!selectedClient}
                value={selectedCompany?.value}
                onChange={handleCompanyChange}
                innerContainerClassName="input-group-post"
                label="Client Company"
                placeholder="Client Company"
                corp_id={selectedClient?.value}
              />
            </div>
            <div className="col-sm-2 d-flex justify-content-end mt-3">
              <Button color="primary" onClick={handleSave}>
                <FaSave className='me-2' />
                Save
              </Button>
            </div>

          </div>

          <DataGrid
            data={trips}
            gridLoading={loading}
            headers={GridColumns({ insidePane: false })}
            selectedIDs={selectedIDs}
            hidePagination
            hideCheckboxes
            onSelectChange={() => { }}
            renderLastCol={() => <></>}
            rowsPerPage={trips.length ?? 0}
          />
       
        </div>
      </ModalComponent.Body>
    </ModalComponent>
  );
}

export default BulkModification;