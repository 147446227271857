import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";


const TaskCard = ({ item, index, showArrows = false, left = false, right = false, moveLeft, moveRight }) => {
    return (
        <Draggable draggableId={item?.id} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className="drag-information d-flex gap-2 align-items-center">
                        {showArrows && left && (
                            <FaArrowLeftLong className="move-button" onClick={moveLeft} />
                        )}
                        {<div className="task-title">{item.title}</div>}
                        {showArrows && right && (
                            <FaArrowRightLong className="move-button" onClick={moveRight} />
                        )}
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default TaskCard;
