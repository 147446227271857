


export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/company/grid?forClient=true&`;
        if (sizePerPage) {
            url += `rows=${sizePerPage}&`;
        } else {
            url += 'rows=-1&';
        }
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    getClient: (id) => `/api/company/grid?forClient=true&_id=${id}`


};