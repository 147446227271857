import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditTrip_Routes from "./EditTrip_Route";
import { STRUCTURE } from "./Trip_RouteConstant";
import { GridColumns } from "./Trip_RouteGridColumns";
import Trip_RouteService from "./Trip_RouteService";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";


const Trip_Routes = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/route/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      const res = await Trip_RouteService.fetch(filterUrl);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])


  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    // if (!detailData?.trip_Route) {
    //   setHasErr(true)
    //   showError(t("Please enter all required fields"));
    //   return;
    // }
    setLoading(true);
    try {
      if (editID) {
        await Trip_RouteService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Record updated successfully."));
      } else {
        const trip_Route = await Trip_RouteService.save(detailData);
        showMessage(t("Record saved successfully."));
        setLoading(false);
        if (!insidePane) navigate(`/route/edit/${trip_Route}`);
      }
      fetchData(filterURL);

    } catch (e) {
      showError(e);
      setLoading(false);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await Trip_RouteService.delete(id);
        setLoading(false);
        showMessage("Deleted", "Deleted");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);

      try {
        await Trip_RouteService.bulkDelete(selectedIDs);
        setLoading(false);
        showMessage("Deleted", "Deleted Successfully");
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback((trip_Route) => {
    return (
      <>
        {userContent?.rights?.includes(5602) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/route/edit/${trip_Route?._id}`);
              setEditID(trip_Route?._id);
              setShowDetailPage(true);

            }}
          />
        }
        {userContent?.rights?.includes(5603) &&
          <DeleteLink
            onClick={(event) => onDelete(event, trip_Route?._id)}
          />}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(trip_Route?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])
  return (
    <React.Fragment>
      <>
        <Layout
          hideAdd={!userContent?.rights?.includes(5601)}
          medium
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/route");
            setEditID(null);
          }}
           
          title={t("Routes")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/route/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={Trip_RouteService.page}
          rows={Trip_RouteService.rows}
          total={Trip_RouteService.total}
          fetch={Trip_RouteService.fetch}
        >
          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem>{t("Upload")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem> Download </DropdownItem>
                </>

              </DropdownMenu>

            </div>
          </Layout.ActionMenu>
          <Layout.Table>
            <DataGrid
              gridLoading={loading}
              data={Trip_RouteService.records}
              total={Trip_RouteService.total}
              uiPreference="route.grid"
              headers={GridColumns}
              onSelectChange={(v) => {
                onSelect(v)
                setSelectedIDs(v)
              }}
              selectedIDs={selectedIDs}
              page={Trip_RouteService.page}
              rowsPerPage={Trip_RouteService.rowsPerPage}
              onPaginationChange={Trip_RouteService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </Layout.Table>


          <Layout.DetailPageTitle>
            {detailData?._id ? t("Edit Route") : t("Add Route")}
          </Layout.DetailPageTitle>

          <Layout.DetailPageBody>
            <EditTrip_Routes
              editId={editID}
              onChange={(v) => {
                setDetailData(v)
              }}
              hasErr={hasErr}
            />
          </Layout.DetailPageBody>

          <Layout.DetailPageFooter>
            {detailData?._id ? (
              <AuditLogDialog module="Route" id={detailData?._id} />
            ) : null

            }
            {editID && userContent?.rights?.includes(5603) ? <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} /> : null}
            {userContent?.rights?.includes(5602) && <SaveButton onClick={onSave} loading={loading} />}
          </Layout.DetailPageFooter>
        </Layout>
      </>

    </React.Fragment>
  );
};
export default observer(Trip_Routes);
