import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import {
  Checkbox, DateSelect,
  EmployeeSelect,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import LeaveService from "./LeaveService";

const EditLeave = ({ editId, onChange = () => { } }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    firstHalf: false,
    secondHalf: false
  });

  const fetch = async () => {
    try {
      setLoading(true);
      let states = await LeaveService.get(editId);
      setLoading(false);
      setData(states);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    setData({
      firstHalf: false,
      secondHalf: false
    });
    if (editId) fetch();
  }, [editId]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <React.Fragment>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <ThreeBounce size={50} color="pink" />
        </div>
      ) : (
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row>
            <DateSelect
              placeholder={t("Select Date")}
              value={data?.date}
              required={true}
              label={t("Date")}
              onChange={(v) => setData({ ...data, date: v })}
              className="col-sm-12"
            />

            <EmployeeSelect
              required
              label={t(" Select Employee")}
              value={data?.employee_id}
              onChange={(v) => setData({ ...data, employee_id: v?.value })}
              className="col-sm-12"
            />
            {/* <InputField
              placeholder={t("Enter Remark")}
              value={data?.remark}
              required={true}
              label={t("Remark")}
              onChange={(v) => setData({ ...data, remark: v })}
              type="text"
                className="col-sm-12"
            /> */}
            {/* <EmployeeSelect
                label={"Employee Select"}
                value={data?.employee_id}
                onChange={(v) => setData({ ...data, employee_id: v?.value })}
                className="col-sm-3"
              /> */}
          </Row>
          {/* <Row className="mt-4">
            <TagSelector
              kind={"leave"}
              onChange={(v) => {
                setData({ ...data, tags: v })
              }}
              value={data?.tags}
            />
          </Row> */}
          <Row className="mt-4">
            <Checkbox
              label={t("First Half")}
              checked={data?.firstHalf == 1}
              onClick={() =>
                setData({
                  ...data,
                  firstHalf: !data?.firstHalf,
                })
              }
              className="col-sm-6"
            />
            <Checkbox
              label={t("Second Half")}
              checked={data?.secondHalf == 1}
              onClick={() =>
                setData({
                  ...data,
                  secondHalf: !data?.secondHalf,
                })
              }
              className="col-sm-6"
            />
            {/* <TagSelector
              kind={"leave"}
              onChange={(v) => {
                setData({ ...data, tags: v });
              }}
              value={data?.tags}
            /> */}
          </Row>

          <Row className="my-4">
            <div>
              <div style={{ marginBottom: "0.2rem", }}>{t("Remark  ")}         {<font color="red">*</font>} </div>
              <textarea className="inputfield" rows={5} style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", }}
                value={data?.remark ?? ""}

                onChange={(e) => {
                  setData({ ...data, remark: e.target.value });
                }}
              >
              </textarea>
            </div>
          </Row>
        </Form>
      )}
    </React.Fragment>
  );
};

export default observer(EditLeave);
