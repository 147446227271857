import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { forwardRef, useCallback, useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Row,
  UncontrolledAccordion
} from "reactstrap";
import {
  AddressField,
  Checkbox,
  ClientSelect,
  CompanySelect,
  CustomFieldComponent,
  InputField,
  MakeModelSelect,
  SelectField,
  ToggleSelector
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import useDebounce from "../../util/Debounce";
import { doGET } from "../../util/HttpUtil";
import GarageService from "../Garage/GarageService";
import BookedBy from "./BookedBy";
import { ROUTE_TYPE_DATA, SELECTSTRUCTURE, TRIP_TYPE } from "./BookingConstant";
import BookingService from "./BookingService";
import Passengers from "./Passengers";
import TripExpense from "./TripExpense";
import AssignVehicle from "./components/AssignVehicleDriver";
import Billing from "./components/Billing";
// import sampleImage from "../../assets/images/image.png"
import DateTimePickerWithInterval from "../../components/DateTimePickerWithInterval";
import Pictures from "./Pictures";
import { formatSecondsToDuration } from "../../helpers/dateHelper";
import TripTypeDropdown from "./components/TripTypeDropdown";
import moment from "moment";
import ShuttleAddressSelector from "../../components/ShuttleAddressSelector";
import ShuttleScheduler from "../../components/ShuttleScheduler";
import PassengersNew from "./PassengersNew";

const EditBooking = forwardRef(({
  editId,
  trip = () => { },
  setTrip,
  clientBilling,
  detailLoading,
  setClientBilling = null,
  setCustomerType = () => { },
  customerType,
  supplierBillings = [],
  setSupplierBillings,
  billingUpdated,
  setBillingUpdated,
  billingUpdatedObject = {},
  setBillingUpdatedObject = () => { },
}, billingRef) => {
  const { t } = useContext(I18nContext);
  const { showError, showMessage, showInfo } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [billingLoading, setBillingLoading] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const { userContent } = useContext(UserContext);
  const [endKm, setEndKm] = useState(Number(trip?.tripExtn?.startKm ?? 0) +
    Number(trip?.tripExtn?.distance ?? 0) ?? 0)

  const [tripEditTempData, setTripEditTempData] = useState({
    endTime: ''
  })

  useEffect(() => {
    setTripEditTempData(prev => ({
      ...prev,
      endTime: trip?.startTime + Math.ceil(trip?.duration * 60)
    }))
  }, [trip?.duration, trip?.tripExtn?.distance])


  const [billingEditState, setBillingEditState] = useState({
    supplier: -1,  //index of billing
    client: false // bool value of state
  });


  useEffect(() => {
    setTrip({
      ...trip,
      tripType: 1,
      passengers: [
        {
          anonymous: false,
          salutation: "Mr",
          phone: {
            countryCode: 91,
          },
        },
      ],
      tripExtn: {
        ...trip?.tripExtn,
        propertyList: [],
        otpNotRequired: true
      },
    });
  }, []);

  useEffect(() => {
    BookingService.getFieldAccessibilityList();
  }, []);

  //meant to call to fetch trip and billing on billing save only.
  const fetchBillings = useCallback(async () => {
    if (editId) {
      setLoading(true);
      try {
        const { clientBilling: cB, supplierBillings: sBArray } =
          await BookingService.fetchTripBillings(editId);
        setClientBilling(cB);
        setSupplierBillings(sBArray);
      } catch (error) {
        showError(error);
      } finally {
        setLoading(false);
      }
    }
  }, [editId, setClientBilling, setSupplierBillings, showError]);

  // useEffect(() => {
  //   fetchBillings()
  // }, [clientBilling?.client_id, clientBilling?.clientCompany_id])

  const fetchDistanceBetweenCoordinates = useCallback(async ({ src, dst }) => {
    let kms = 0;
    let mins = 0;
    if (src?.lat && dst?.lat && src?.lng && dst?.lng) {
      try {
        const response = await doGET(
          `/api/o/geocode/distance-time?src=${src?.lat},${src?.lng}&dst=${dst?.lat},${dst?.lng}`
        );
        if (response.status === 200) {
          kms = response?.data?.kms;
          mins = response?.data?.mins;
        }
      } catch (error) {
        console.log(error);
      }
    }
    return { kms: kms, mins: mins };
  }, []);

  const handleTripChange = useCallback((fieldName, fieldValue) => {
    if (fieldName && fieldValue) {
      setTrip((prevTrip) => {
        return {
          ...prevTrip,
          [fieldName]: fieldValue,
        };
      });
    }
  }, []);

  useEffect(() => {
    setBillingUpdatedObject({ distance: trip?.tripExtn?.distance });
  }, [trip?.tripExtn?.distance]);
  useEffect(() => {
    setBillingUpdatedObject({ startTime: trip?.startTime });
  }, [trip?.startTime]);
  useEffect(() => {
    setBillingUpdatedObject({ duration: trip?.duration });
  }, [trip?.duration]);
  useEffect(() => {
    setBillingUpdatedObject({ preStartKMs: trip?.tripExtn?.preStartKMs });
  }, [trip?.tripExtn?.preStartKMs]);
  useEffect(() => {
    setBillingUpdatedObject({ postStopKMs: trip?.tripExtn?.postStopKMs });
  }, [trip?.tripExtn?.postStopKMs]);
  useEffect(() => {
    setBillingUpdatedObject({ preStartMins: trip?.tripExtn?.preStartMins });
  }, [trip?.tripExtn?.preStartMins]);
  useEffect(() => {
    setBillingUpdatedObject({ postStopMins: trip?.tripExtn?.postStopMins });
  }, [trip?.tripExtn?.postStopMins]);
  useEffect(() => {
    setBillingUpdatedObject({ expenses: expenses });
  }, [expenses]);

  // const handleDistanceAndTime = useCallback(async () => {
  //   const tripExtn = { ...trip?.tripExtn } || {};
  //   let extnHasChanged = false;
  //   let garage = tripExtn?.garage;
  //   if (garage) {
  //     garage =
  //       garage && garage.garageAddress
  //         ? garage
  //         : garage.garage_id
  //           ? await GarageService.get(garage.garage_id)
  //           : null;
  //   }
  //   if (garage) {
  //     const { kms: preStartKMs, mins: preStartMins } =
  //       await fetchDistanceBetweenCoordinates({
  //         src: { lat: garage.address?.lat, lng: garage.address?.lng },
  //         dst: { lat: trip?.src?.lat, lng: trip?.src?.lng },
  //       });
  //     const { kms: postStopKMs, mins: postStopMins } =
  //       await fetchDistanceBetweenCoordinates({
  //         src: { lat: trip?.dst?.lat, lng: trip?.dst?.lng },
  //         dst: { lat: garage?.address?.lat, lng: garage?.address?.lng },
  //       });
  //     tripExtn["preStartKMs"] = parseInt(preStartKMs) || 0;
  //     tripExtn["postStopKMs"] = parseInt(postStopKMs) || 0;
  //     tripExtn["preStartMins"] = preStartMins || 0;
  //     tripExtn["postStopMins"] = postStopMins || 0;
  //     extnHasChanged = true;

  //   }
  //   const { kms, mins } = await fetchDistanceBetweenCoordinates({
  //     src: { lat: trip?.src?.lat, lng: trip?.src?.lng },
  //     dst: { lat: trip?.dst?.lat, lng: trip?.dst?.lng },
  //   });
  //   //TODO overwrite user provided data from gps data?
  //   if (kms && !tripExtn?.distance) {
  //     tripExtn["distance"] = kms || 0;
  //     extnHasChanged = true;
  //   }
  //   if (extnHasChanged) {
  //     handleTripChange("tripExtn", tripExtn);
  //   }
  //   if (mins && !trip?.duration) {
  //     handleTripChange("duration", mins);
  //   }
  // }, [trip?.src?.lat, trip?.src?.lng, trip?.dst?.lat, trip?.dst?.lng, trip?.tripExtn?.garage?.garage_id, handleTripChange, fetchDistanceBetweenCoordinates]);

  // useEffect(() => {
  //   handleDistanceAndTime();
  // }, [handleDistanceAndTime]);

  const handleDistanceAndTime = useDebounce(async (src, dst, newGarage = null) => {
    const tripExtn = { ...trip?.tripExtn } || {};
    let extnHasChanged = false;
    let garage = newGarage ?? tripExtn?.garage;
    if (garage) {
      garage =
        garage && garage.garageAddress
          ? garage
          : garage.garage_id
            ? await GarageService.get(garage.garage_id)
            : null;
    }
    if (garage) {
      const { kms: preStartKMs, mins: preStartMins } =
        await fetchDistanceBetweenCoordinates({
          src: { lat: garage.address?.lat, lng: garage.address?.lng },
          dst: { lat: src?.lat, lng: src?.lng },
        });
      const { kms: postStopKMs, mins: postStopMins } =
        await fetchDistanceBetweenCoordinates({
          src: { lat: dst?.lat, lng: dst?.lng },
          dst: { lat: garage?.address?.lat, lng: garage?.address?.lng },
        });
      tripExtn["preStartKMs"] = parseInt(preStartKMs) || 0;
      tripExtn["postStopKMs"] = parseInt(postStopKMs) || 0;
      tripExtn["preStartMins"] = preStartMins || 0;
      tripExtn["postStopMins"] = postStopMins || 0;
      if (newGarage?.garage_id) {
        tripExtn["garage"] = { garage_id: newGarage?.garage_id }
      }
      extnHasChanged = true;

    }
    const { kms, mins } = await fetchDistanceBetweenCoordinates({
      src: { lat: src?.lat, lng: src?.lng },
      dst: { lat: dst?.lat, lng: dst?.lng },
    });
    //TODO overwrite user provided data from gps data?
    if (kms && !tripExtn?.distance) {
      tripExtn["distance"] = kms || 0;
      extnHasChanged = true;
    }
    if (extnHasChanged) {
      handleTripChange("tripExtn", tripExtn);
    }
    if (mins && !trip?.duration) {
      handleTripChange("duration", mins);
    }
  }, 600);

  const hanldeTripExtnChange = useCallback(
    (fieldName, fieldValue) => {
      if (fieldName && fieldValue) {
        const tripExtn = { ...trip?.tripExtn } || {};
        tripExtn[fieldName] = fieldValue;
        handleTripChange("tripExtn", tripExtn);
      }
    },
    [trip?.tripExtn, handleTripChange]
  );

  // const setGarageDistance = async (garage) => {

  // }

  const deleteClientBilling = async (e) => {
    e.preventDefault();
    return deleteBilling(clientBilling, "client");
  };
  const deleteSupplierBilling = async (index, e) => {
    e.preventDefault();
    if (!supplierBillings || supplierBillings.length === 0) {
      return null;
    }
    return deleteBilling(supplierBillings[index], "supplier");
  };

  const deleteBilling = async (billing, type) => {
    setLoading(true);
    if (billing?._id) {
      try {
        await BookingService.deleteBilling(billing._id);
        fetchBillings();
        showMessage("Record Deleted successfully");
      } catch (error) {
        showError(error);
      }
    } else {
      const updatedClientBilling = type === "client" ? null : clientBilling;

      const updatedSupplierBillings =
        type === "supplier"
          ? supplierBillings.filter((item) => item !== billing)
          : supplierBillings;

      setClientBilling(updatedClientBilling);
      setSupplierBillings(updatedSupplierBillings);
    }
    setLoading(false);
  };

  const hadleEndKmChange = useDebounce(
    (value, startKm) => {
      if (value < startKm || startKm < 0) {
        showError("End Km should be greater than Start km");
        return;
      }
      let distance =
        value >= startKm
          ? value - startKm
          : Number(trip?.tripExtn?.distance ?? 0);
      setTrip((prev) => ({
        ...prev,
        tripExtn: {
          ...prev?.tripExtn,
          distance,
        },
      }));
    },
    [300]
  );

  const handleStartKm = (v) => {
    setTrip((prev) => ({
      ...prev,
      tripExtn: {
        ...prev?.tripExtn,
        // distance,
        startKm: v,
      },
    }));
  };

  const handleDistance = (v) => {
    setTrip((prev) => ({
      ...prev,
      tripExtn: {
        ...prev?.tripExtn,
        distance: Number(v),
      },
    }));
  };

  const handleClientCompanyChange = useCallback(
    ({ company_id, client_id, defaultCompany_id, name }) => {
      // if ((company_id || corp_id || name)) {
      const eClient = { ...trip?.client } || {};
      if (company_id) {
        eClient["company_id"] = company_id;
      }
      if (client_id) {
        eClient["client_id"] = client_id;
        eClient["company_id"] = defaultCompany_id;
      }
      eClient["name"] = name;
      console.log(eClient);
      handleTripChange("client", eClient);
      // }
    },
    [trip?.client, handleTripChange]
  );

  // useEffect(() => {
  //   if (customerType) {
  //     setTrip((prevTrip) => {
  //       return {
  //         ...prevTrip,
  //         "company_id": "",
  //         "corp_id": "",
  //         "name": ""
  //       };
  //     });
  //   }
  // }, [customerType])

  // useEffect(() => {
  //   if (customerType == "walkIn") {
  //     handleClientCompanyChange({
  //       company_id: null,
  //       corp_id: null,
  //       name: null,
  //     });
  //   }
  // }, [customerType])

  useEffect(() => {
    if (!(trip?.client && trip?.client?.company_id)) {
      setCustomerType("walkIn")
    }
  }, [])


  useEffect(() => {
    if ((trip?._id)) {
      setCustomerType((trip?.client) ? "forClient" : "walkIn")
    }
  }, [trip])

  //planedSrc or planedDst
  const getPlannedAddress = (type) => {
    let address = "";
    if (trip?.tripExtn?.[type]?.addressLine) {
      address = trip?.tripExtn?.[type]?.addressLine
    }
    if (trip?.tripExtn?.[type]?.city) {
      address += `${trip?.tripExtn?.[type]?.city}, `
    }
    if (trip?.tripExtn?.[type]?.state) {
      address += `${trip?.tripExtn?.[type]?.state}, `
    }
    if (trip?.tripExtn?.[type]?.country) {
      address += `${trip?.tripExtn?.[type]?.country}`
    }
    return address
  }

  return (
    <React.Fragment>
      {(loading || detailLoading) ? (
        <div className="d-flex mt-5 justify-content-center align-items-center  flex-1">
          <ThreeBounce size={50} color="pink" />
        </div>
      ) : (
        <Form
          className="p-0 px-3 pe-4"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Col className="col-sm-12">
            <Card className="p-3 py-0 mt-2 mb-2">
              <div className="d-flex justify-content-between align-items-center flex-1">
                <div className="d-flex align-items-center w-50">
                  <ToggleSelector
                    data={[
                      { label: "Walk In ", value: "walkIn" },
                      { label: "For Client", value: "forClient" },
                    ]}
                    className=" w-25"
                    style={{ width: "40%", position: "relative", top: "2.5px" }}
                    value={customerType}
                    onChange={(selectedValue) => {
                      setCustomerType(selectedValue);
                      if (selectedValue === 'walkIn') {
                        setTrip((prevTrip) => ({
                          ...prevTrip,
                          client: null
                        }));
                      }
                    }}
                  />

                  {BookingService.renderField("client.company_id") && customerType == "forClient" && (
                    <div className="d-flex w-100 row flex-1">
                      {/* <ClientCompany
                        isDisabled={BookingService.readOnly("client.company_id")}
                        outline
                        value={trip?.client?.company_id}
                        onChange={(v) => {
                          handleClientCompanyChange({
                            company_id: v?._id,
                            client_id: v?.client_id,
                            name: v?.groupLabel,
                          });
                          setBillingEditState({
                            ...billingEditState,
                            client: true
                          })
                          // setClientBilling(prev => ({
                          //   ...prev,
                          //   client_id: v?.corp_id,
                          //   clientCompany_id: v?._id
                          // }));

                        }}
                        className="col-sm-6 col-lg-6 ms-2"
                      /> */}
                      <div className="col-6 pe-0 ">
                        <ClientSelect
                          value={trip?.client?.client_id}
                          onChange={(v) => {
                            handleClientCompanyChange({
                              client_id: v?.value,
                              defaultCompany_id: v?.defaultCompany_id
                            });
                            if (v.defaultVendorCompany_id) {
                              handleTripChange("vendorCompany_id", v.defaultVendorCompany_id);
                            }
                            setBillingUpdated(true);
                          }}
                          innerContainerClassName="input-group-pre"
                          className="ms-2 me-0 w-100 "
                          outline={true}
                        />


                      </div>


                      {<div className="col-sm-6 p-0">
                        <CompanySelect
                          className="ms-2 w-100"
                          outline
                          corpType="client"
                          isDisabled={!trip?.client?.client_id}
                          value={trip?.client?.company_id}
                          onChange={(v) => {
                            handleClientCompanyChange({
                              company_id: v?.value
                            });
                            setBillingUpdated(true);
                          }}
                          innerContainerClassName="input-group-post"
                          label="Client Company"
                          corp_id={trip?.client?.client_id}
                          hideWhenSingle
                        />
                      </div>
                      }


                    </div>

                  )}
                </div>
                <div className="d-flex align-items-center justify-content-end flex-1 ms-3">
                  {/* <TagPicker
                    kind={"trip"}
                    onChange={(v) => setTrip({ ...trip, tags: v })}
                    value={trip?.tags}
                  /> */}
                  {BookingService.renderField("vendorCompany_id") && (
                    <div className="col-sm-6 col-lg-4 ">
                      <CompanySelect
                        isDisabled={BookingService.readOnly("vendorCompany_id")}
                        className="w-100"
                        outline
                        corpType="self"
                        hideWhenSingle
                        value={trip?.vendorCompany_id}
                        onChange={(v) =>
                          handleTripChange("vendorCompany_id", v?.value)
                        }
                        label="Serving Company"
                      />
                    </div>
                  )}
                  {BookingService.renderField("tripType") && (
                    <>
                      <div className="col-sm-6 col-lg-3 ms-2">
                        {/* <SelectField
                          data={SELECTSTRUCTURE?.bookingType}
                          outline
                          required
                          isDisabled={BookingService.readOnly("tripType")}
                          className="w-100"
                          label={t("Trip Type")}
                          placeholder={t("Trip Type")}
                          value={trip?.tripType}
                          onChange={(v) => handleTripChange("tripType", v?.value)}
                        /> */}
                        <TripTypeDropdown
                          data={SELECTSTRUCTURE?.bookingType}
                          onChange={(v) => handleTripChange("tripType", v?.value)}
                          value={trip?.tripType}
                          isDisabled={BookingService.readOnly("tripType")}
                        />
                      </div>
                    </>
                  )}
                  <div className='mx-4'>
                    <Checkbox
                      leftAlign
                      label={t("Trip Start OTP Required")}
                      checked={!(trip?.tripExtn?.otpNotRequired)}
                      className="col-sm-6 col-md-3 "
                      onClick={(v) => {
                        setTrip((prevTrip) => ({
                          ...prevTrip,
                          tripExtn: {
                            ...trip?.tripExtn,
                            otpNotRequired: !(prevTrip?.tripExtn?.otpNotRequired)
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </Col>

          {trip?.tripType === TRIP_TYPE.Route ? <Card className="px-3">
            <div className=" col-12 col-md-6">
              <ToggleSelector
                data={ROUTE_TYPE_DATA}
                disabled={trip?._id}
                className=" "
                style={{ position: "relative", top: "2.5px" }}
                value={trip?.routeType}
                onChange={(selectedValue) => {
                  setTrip((prev) => ({ ...prev, routeType: selectedValue }));
                }}
              />
            </div>
          </Card> : null}

          {trip?.tripType === TRIP_TYPE.Route && trip?.routeType === 4 ? <Card className="mb-2">  {/* Shuttle */}
            <CardBody className='px-3 py-3 col-12 col-sm-6'>
              <ShuttleAddressSelector disabled={trip?._id} stops={trip?.stops} onChange={(values) => setTrip(prev => ({ ...prev, stops: values, src: values?.length && values[0]?.address, dst: values?.length && values[values?.length - 1]?.address }))} />
            </CardBody>
          </Card> : null}
          {trip?.tripType === TRIP_TYPE.Route && trip?.routeType === 4 ? <Card className="mb-2">  {/* Shuttle */}
            <CardBody className='px-3 py-3 col-12 col-sm-6'>
              <ShuttleScheduler disabled={trip?._id} onChange={setTrip} schedule={trip?.schedule} />
            </CardBody>
          </Card> : null}

          <Card className="mb-2">
            <CardBody className='px-1 py-0 pb-0'>
              {BookingService.renderField("passengers") && (
                <PassengersNew
                  client_id={trip?.client?.client_id}
                  passengers={trip?.passengers}
                  stops={trip?.stops}
                  type={trip?.routeType}
                  onChange={(v) => handleTripChange("passengers", v)}
                />
              )}
              <hr className="my-1" />
              {BookingService.renderField("bookedBy") && (
                <BookedBy
                  className="pb-1"
                  emptyDivStyle={{
                    maxWidth: "196.5px",
                    paddingRight: "0px"
                  }}
                  clientId={trip?.client?.client_id}
                  pax={trip?.tripExtn?.pax ?? trip?.passengers?.length}
                  value={trip?.bookedBy}
                  onChange={(v) => {
                    handleTripChange("bookedBy", v);
                  }}
                  onPaxChange={(v) => {
                    setTrip((prevTrip) => ({
                      ...prevTrip,
                      tripExtn: {
                        ...prevTrip?.tripExtn,
                        pax: v,
                      }
                    }
                    ));
                  }}
                />
              )}

            </CardBody>
          </Card>
          <Card className="mb-1">
            {/* <div className="d-flex justify-content-between">
              <h5 className="p-0 m-0 mx-4 mt-3">
                <strong>Journey Details</strong>
              </h5>
            </div> */}
            <CardBody className="py-1">
              <div className="d-flex justify-content-start align-items-center">
                {/* <i
                  style={{ fontSize: "20px", color: "green" }}
                  className="me-2 uil-location-point"
                /> */}
                <div
                  style={{
                    width: '11px',
                    height: '11px',
                    borderRadius: '50%',
                    backgroundColor: 'green',
                    marginRight: '7px',

                  }}
                ></div>
                {BookingService.renderField("startTime") && (
                  <>
                    <DateTimePickerWithInterval
                      placeholder="Start Time"
                      className="me-2"
                      outline
                      timestamp
                      label="Start Time"
                      value={trip?.startTime}
                      onChange={(v) => {
                        handleTripChange("startTime", v);
                        //endkm or duration aleady present then update duration also
                        if (trip?.duration) {
                          let endTime = trip?.startTime + Math.ceil(trip?.duration * 60)
                          handleTripChange("duration", Math.ceil((endTime - v > 0 ? endTime - v : 0) / 60));
                        }
                        setBillingUpdated(true);
                        setBillingEditState({
                          ...billingEditState,
                          client: true
                        })
                      }
                      }
                    />

                  </>
                )}

                {BookingService.renderField("src") && (
                  <AddressField
                    style={{ width: "55.8%" }}
                    value={trip?.src}
                    outline
                    onChange={(v) => {
                      handleTripChange("src", v)
                      setBillingUpdated(true);
                      setBillingEditState({
                        ...billingEditState,
                        client: true
                      })
                      handleDistanceAndTime(v, trip?.dst)
                    }}
                  />
                )}
                <div className="d-flex justify-content-start align-items-center flex-1">
                  <InputField
                    outline
                    placeholder="Trip Start KM"
                    label="Trip Start KM"
                    isDisabled={BookingService.readOnly("tripExtn.startKm")}
                    value={trip?.tripExtn?.startKm ? trip?.tripExtn?.startKm : 0}
                    onblur={(v) => {
                      let value = Number(v);
                      let endKm = Number(trip?.tripExtn?.startKm ?? 0) + Number(trip?.tripExtn?.distance ?? 0) || 0;
                      let distance = value + Number(trip?.tripExtn?.distance ?? 0);

                      handleStartKm(value);
                      if (trip?.tripExtn?.distance) {
                        handleDistance(Math.max(0, endKm - value));
                      }
                      setBillingUpdated(true);
                      setBillingEditState({
                        ...billingEditState,
                        client: true
                      })
                    }}
                    // onChange={(v) => {
                    //   let value = Number(v);
                    //   let distance = value + Number(trip?.tripExtn?.distance ?? 0);
                    //   handleStartKm(value);
                    // }}
                    type="number"
                    className="input-group-prepend"
                  />
                  <InputField
                    outline
                    placeholder="Garage to Pickup KMs"
                    label="Garage to Pickup KMs"
                    // isDisabled={BookingService.readOnly("tripExtn.startKm")}
                    value={trip?.tripExtn?.preStartKMs ? trip?.tripExtn?.preStartKMs : 0}
                    onblur={(v) => {
                      let value = parseInt(Number(v));
                      let garageKMs = 0;
                      // if (value) {
                      //   garageKMs = garageKMs + value;
                      // }
                      // if (trip?.tripExtn?.postStopKMs) {
                      //   garageKMs = garageKMs + trip?.tripExtn?.postStopKMs;
                      // }

                      setTrip({
                        ...trip,
                        tripExtn: {
                          ...(trip?.tripExtn ?? {}),
                          // garageKMs,
                          preStartKMs: value

                        }
                      })

                      setBillingEditState({
                        ...billingEditState,
                        client: true
                      })
                      setBillingUpdated(true);
                    }}
                    type="number"
                    className="input-group-prepend mx-1"
                  />

                  <InputField
                    outline
                    placeholder="Garage to Pickup Mins"
                    label="Garage to Pickup Mins"
                    value={trip?.tripExtn?.preStartMins ? trip?.tripExtn?.preStartMins : 0}
                    onblur={(v) => {
                      let value = parseInt(Number(v));
                      setTrip({
                        ...trip,
                        tripExtn: {
                          ...(trip?.tripExtn ?? {}),
                          preStartMins: value

                        }
                      })
                      setBillingEditState({
                        ...billingEditState,
                        client: true
                      })
                      setBillingUpdated(true);
                    }}
                    type="number"
                    className="input-group-prepend mx-1"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <div
                  style={{
                    width: '11px',
                    height: '11px',
                    backgroundColor: 'red',
                    marginRight: '7px',

                  }}
                ></div>
                {BookingService.renderField("endTime") && (
                  <DateTimePickerWithInterval
                    placeholder="End Time"
                    className="me-2"
                    outline
                    timestamp
                    label="End Time"
                    value={trip?.startTime + Math.ceil(trip?.duration * 60)}
                    onChange={(v) => {
                      if (!v) return;
                      handleTripChange("duration", Math.ceil((v - trip?.startTime) / 60));
                      setBillingUpdated(true);
                      setBillingEditState({
                        ...billingEditState,
                        client: true
                      })
                    }
                    }
                  />
                )}
                {BookingService.renderField("dst") && (
                  <AddressField
                    style={{ width: "55.8%" }}
                    outline
                    value={trip?.dst}
                    onChange={(v) => {
                      handleTripChange("dst", v)
                      handleDistanceAndTime(trip?.src, v)
                    }}
                  />
                )}
                <InputField
                  outline
                  placeholder="Trip End KM"
                  label="Trip End KM"
                  isDisabled={BookingService.readOnly("tripExtn.endKm")}
                  value={trip?.tripExtn?.distance ? Number(trip?.tripExtn?.startKm ?? 0) + Number(trip?.tripExtn?.distance ?? 0) : ''}
                  // value={tripEditTempData?.endKm}
                  style={{
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                  }}
                  onblur={(v) => {
                    let value = Number(v);
                    let startKm = Number(trip?.tripExtn?.startKm ?? 0) || 0;
                    console.log("blur...", value)
                    handleDistance(Math.max(0, value - Number(trip?.tripExtn?.startKm ?? 0)));
                    setBillingUpdated(true);
                    setBillingEditState({
                      ...billingEditState,
                      client: true
                    })
                  }}
                  type="number"
                  error={(Number(trip?.tripExtn?.startKm ?? 0) + Number(trip?.tripExtn?.distance ?? 0) ?? 0) < Number(trip?.tripExtn?.startKm ?? 0) && 'end km should be greater than start'}
                // showErr={true}
                // className="col-3"
                />

                <InputField
                  outline
                  placeholder="Drop to Garage KMs"
                  label="Drop to Garage KMs"
                  // isDisabled={BookingService.readOnly("tripExtn.startKm")}
                  value={trip?.tripExtn?.postStopKMs ? trip?.tripExtn?.postStopKMs : 0}
                  onblur={(v) => {
                    let value = parseInt(Number(v));
                    // let garageKMs = 0;
                    // if (value) {
                    //   garageKMs = value;
                    // }
                    // if (trip?.tripExtn?.preStartKMs) {
                    //   garageKMs = garageKMs + trip?.tripExtn?.preStartKMs;
                    // }

                    setTrip({
                      ...trip,
                      tripExtn: {
                        ...(trip?.tripExtn ?? {}),
                        // garageKMs,
                        postStopKMs: value

                      }
                    })

                    setBillingEditState({
                      ...billingEditState,
                      client: true
                    })
                    setBillingUpdated(true);
                  }}
                  type="number"
                  className="input-group-prepend mx-1"
                />

                <InputField
                  outline
                  placeholder="Garage Drop Mins"
                  label="Garage Drop Mins"
                  value={trip?.tripExtn?.postStopMins ? trip?.tripExtn?.postStopMins : 0}
                  onblur={(v) => {
                    let value = parseInt(Number(v));
                    setTrip({
                      ...trip,
                      tripExtn: {
                        ...(trip?.tripExtn ?? {}),
                        postStopMins: value

                      }
                    })
                    setBillingEditState({
                      ...billingEditState,
                      client: true
                    })
                    setBillingUpdated(true);
                  }}
                  type="number"
                  className="input-group-prepend mx-1"
                />
              </div>

              <div className="d-flex  align-items-center">
                <div className="mt-3 col-2 font-size-13">
                  <span style={{ color: '#999999' }}>
                    {t(`Distance`)} : &nbsp;
                  </span>
                  <span>
                    {trip?.tripExtn?.distance ?? 0} KMs
                  </span>
                </div>
                <div className="mt-3 col-4 font-size-13">
                  <span style={{ color: '#999999' }}>
                    {t(`Duration`)} : &nbsp;
                  </span>
                  <span>
                    {formatSecondsToDuration(Math.ceil(trip?.duration * 60) ?? 0)}
                  </span>
                </div>
              </div>
              {trip?.tripExtn && <UncontrolledAccordion className="my-1 py-1" defaultOpen="0">
                {getPlannedAddress('planedSrc') ? <div className="d-flex align-items-center">
                  <div className="mt-3">
                    <span style={{ color: '#999999' }}>
                      {t(`Planned Source Address`)}
                    </span>
                    <span>
                      {getPlannedAddress('planedSrc')}
                    </span>
                  </div>
                </div> : null}
                {getPlannedAddress('planedDst') ? <div className="d-flex align-items-center">
                  <div className="mt-3">
                    <span style={{ color: '#999999' }}>
                      {t(`Planned Destination Address`)}
                    </span>
                    <span>
                      {getPlannedAddress('planedDst')}
                    </span>
                  </div>
                </div> : null}
              </UncontrolledAccordion>}
            </CardBody>
          </Card>
          <div>
            <UncontrolledAccordion className="my-1 py-1" defaultOpen="0">
              <AssignVehicle
                trip={trip}
                garage={trip?.tripExtn?.garage}
                driver={trip?.driver}
                vehicle={trip?.vehicle}
                onChangeVehicle={(v) => {
                  handleTripChange("vehicle", v);

                  let isBillingExist = false
                  supplierBillings?.map((supplierBilling) => {
                    if (v?.provider_id && (v?.provider_id == supplierBilling?.vendor_id)) {
                      isBillingExist = true
                    }
                  })

                  if (!isBillingExist && v?.provider_id) {
                    setBillingEditState({
                      ...billingEditState,
                      supplier: 0
                    })
                    setSupplierBillings([
                      {
                        vendor_id: v?.provider_id,
                        fee: {
                          currency: "INR",
                          feeGroups: [
                            {
                              name: `TRIP CHARGES`,
                            },
                          ],
                        },
                      },
                      ...(supplierBillings ?? []),
                    ])
                  }
                }
                }
                onChangeDriver={(v) => handleTripChange("driver", v)}
                onChangeGarage={(v) => {
                  // const garage = {garage_id : v._id, garageAddress : v.address}
                  hanldeTripExtnChange("garage", v)
                  handleDistanceAndTime(trip?.src, trip?.dst, v)
                  setBillingEditState({
                    ...billingEditState,
                    client: true
                  })
                  // setGarageDistance(garage);
                }}
              />
            </UncontrolledAccordion>
            {/* editId &&  */userContent?.rights?.includes(6005) && (
              <Row className="d-flex">
                <Col className="col-12 col-md-6">
                  {BookingService.renderField("clientBilling") && (
                    <UncontrolledAccordion className="mt-1" defaultOpen="1">
                      <Card className="pt-0">
                        <CardHeader>
                          <div
                            className="d-flex justify-content-between"
                            style={{ width: "inherit" }}
                          >
                            <strong>{t(`Client Charges`)}</strong>

                            <span className="d-flex">
                              <span style={{ minWidth: "max-content" }} className="me-1 ">   {clientBilling?.feeRate?.name ? `${clientBilling?.feeRate?.name} |` : ""}          </span>
                              <MakeModelSelect
                                asLabel
                                value={clientBilling?.feeRate?.makeModel}
                              />
                            </span>

                          </div>
                        </CardHeader>
                        <Billing
                          ref={(ref) => {
                            if (!billingRef.current) billingRef.current = {};
                            if (!billingRef.current.clientBillings) billingRef.current.clientBillings = [];
                            if (!billingRef.current.supplierBillings) billingRef.current.supplierBillings = [];
                            billingRef.current.clientBillings[0] = ref;
                          }}
                          key={100}
                          expenses={expenses}
                          billingUpdated={billingUpdated}
                          setBillingUpdated={(v) => {
                            setBillingUpdated(v)
                          }}
                          billingUpdatedObject={billingUpdatedObject}
                          setBillingUpdatedObject={setBillingUpdatedObject}
                          billingEditState={billingEditState}
                          setBillingEditState={setBillingEditState}
                          billing={clientBilling}
                          setBilling={setClientBilling}
                          index={0}
                          loading={billingLoading}
                          onDelete={(e) => deleteClientBilling(e)}
                          onTripChange={(v) => {
                            setTrip((prevTrip) => ({
                              ...prevTrip,
                              tripExtn: {
                                ...prevTrip.tripExtn,
                                requestedVehicleType: v,
                              },
                            }));
                          }}
                          onChange={(v, billingUpdated = true) => {
                            setClientBilling(v);
                            if (billingUpdated) {
                              setBillingUpdated(true);
                            }
                          }}
                          type="Client"
                          customerType={customerType}
                          trip={trip}
                        />
                      </Card>
                    </UncontrolledAccordion>
                  )}
                </Col>
                <Col className="col-12 col-md-6">
                  {BookingService.renderField("supplierFare") && (
                    <UncontrolledAccordion className="mt-1" defaultOpen="1">
                      <Card className="pt-0">
                        <CardHeader>
                          <div
                            className="d-flex justify-content-between"
                            style={{ width: "inherit" }}
                          >
                            <strong>{t(`Supplier Charges`)}</strong>
                          </div>
                        </CardHeader>
                        <div>
                          {/* {JSON.stringify(supplierBillings)} */}
                          {supplierBillings &&
                            supplierBillings.map((billing, index) => {
                              return (
                                <>
                                  <Billing
                                    ref={(ref) => {
                                      if (!billingRef.current) billingRef.current = {};
                                      if (!billingRef.current.clientBillings) billingRef.current.clientBillings = [];
                                      if (!billingRef.current.supplierBillings) billingRef.current.supplierBillings = [];
                                      billingRef.current.supplierBillings[index] = ref;
                                    }}
                                    trip={trip}
                                    billingUpdated={billingUpdated}
                                    setBillingUpdated={(v) => {
                                      setBillingUpdated(v)
                                    }}
                                    billingUpdatedObject={billingUpdatedObject}
                                    setBillingUpdatedObject={setBillingUpdatedObject}
                                    billingEditState={billingEditState}
                                    setBillingEditState={setBillingEditState}
                                    type="Supplier"
                                    customerType={customerType}
                                    loading={billingLoading}
                                    tripCorpAsClient={true}
                                    key={index}
                                    index={index}
                                    billing={billing}
                                    onDelete={(e) => {
                                      deleteSupplierBilling(index, e);
                                    }}
                                    onChange={(newBilling) => {
                                      const localArrayCopy =
                                        supplierBillings.map(
                                          (sB, innerIndex) => {
                                            if (innerIndex === index) {
                                              return newBilling;
                                            }
                                            return sB;
                                          }
                                        );
                                      setSupplierBillings(localArrayCopy);
                                      setBillingUpdated(true);
                                    }}
                                  />

                                  {index != supplierBillings?.length - 1 && (
                                    <hr></hr>
                                  )}
                                </>
                              );
                            })}
                          <div className="d-flex justify-content-end m-2">
                            <div
                              className="btn btn-outline-dark"
                              style={{
                                padding: "3px",
                                paddingRight: "6px",
                              }}
                              onClick={() => {
                                setSupplierBillings([
                                  ...(supplierBillings ?? []),
                                  {
                                    trip_id: trip?._id,
                                    client_id: trip?.corp_id,
                                    clientCompany_id: trip?.vendorCompany_id,
                                    // vendorCompany_id : trip?.vehicle?.providerCompany_id,
                                    // vendor_id:  trip?.vehicle?.provider_id,
                                    fee: {
                                      currency: "INR",
                                      feeGroups: [
                                        {
                                          name: `TRIP CHARGES`,
                                        },
                                      ],
                                    },
                                  },
                                ]);
                                setBillingUpdated(true);
                              }}
                            >
                              <div className="d-flex justify-content-center align-items-center">
                                <i className="bx bx-plus  font-size-18 me-1" />
                                {t("Supplier")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </UncontrolledAccordion>
                  )}
                </Col>
              </Row>
            )}
          </div>
          {/* {editId &&
            <Card className="mb-0">
              <CustomFieldComponent
                module={"Booking"}
                record_id={editId}
                kind={CUSTOMEFIELDS_KIND_DATA?.Booking}
                // propList={data?.vehicleExtn?.propertyList}
                // showGroup={"Vehicle Details"}
                onChange={(v) =>
                  setTrip({
                    ...trip,
                    tripExtn: {
                      ...trip?.tripExtn,
                      propertyList: v,
                    },
                  })
                }
              />
            </Card>
          } */}

          <Row>    {editId &&
            <Col className="col-md-6 pe-0">
              <TripExpense
                disabled={trip?.clientInvoice_id}
                trip_id={editId}
                setExpenses={(v) => {
                  setExpenses(v)
                }}
                onChange={() => {
                  setBillingUpdated(true)
                }}
              />
            </Col>}

            {/* <Col>
              <SelectField
                data={SELECTSTRUCTURE.complianceStatus}
                value={trip?.vehicle?.complianceStatus}
                onChange={(v) =>
                  setTrip({
                    ...trip,
                    vehicle: {
                      ...trip?.vehicle,
                      complianceStatus: v.value
                    },
                  })}
                error={trip?.complianceStatus?.length === 0 ? t("Compliance Status") : ""}
                className="col-sm-12"
                label={t("Compliance Status")}
              />
            </Col> */}
          </Row>
          <Col className="col-md-6 pe-0">
            <CustomFieldComponent
              module={"trip"}
              record_id={trip?._id}
              kind={"trip"}
              propList={trip?.tripExtn?.propertyList}
              onChange={(v) =>
                setTrip({
                  ...trip,
                  tripExtn: {
                    ...trip?.tripExtn,
                    propertyList: v,
                  },
                })
              }
            />
          </Col>

          {/* ============= Pictures ============= */}
          <Row className="mt-2">
            <Pictures title={t(`Trip Images`)} srcPath={trip?.tripExtn?.pathPic} srcSign={trip.tripExtn?.signPic} />
          </Row>
        </Form>
      )}
    </React.Fragment>
  );
});
export default observer(EditBooking);
