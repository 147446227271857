import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { CardThreeBounce, InputField, ClientSelect, CountrySelect } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import ZoneService from "./ZoneService";
import { ThreeBounce } from "better-react-spinkit";


const EditZones = ({ editId, onChange = () => { }, hasErr }) => {

  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const fetch = async () => {
    setLoading(true)
    try {
      let zone = await ZoneService.get(editId);
      setLoading(false)
      setData(zone);
    } catch (error) {
      setLoading(false)
      showError(error)
    }
  };


  useEffect(() => {
    setData(null)
    if (editId) fetch();
    setLoading(false)
  }, [editId, ZoneService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);

  return (
    <React.Fragment>
      {loading ?
        <div className="d-flex justify-content-center align-items-center">
          <ThreeBounce size={50} color="pink" />
        </div> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="mt-3">
            <InputField
              placeholder={t("Name")}
              value={data?.name}
              required={true}
              label={t("Name")}
              onChange={(v) => setData({ ...data, name: v })}
              error={data?.name?.length === 0 ? t("Please enter Zone Name") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-12"
            />
            <CountrySelect
              className="col-sm-12"
              value={data?.country}
              onChange={(v) => setData({ ...data, country: v.value })}
              label={t("Country")}
            />
          </Row>
          <Row className="">
            <ClientSelect
              label={t("Clients")}
              value={data?.clients}
              onChange={(v) => setData({ ...data, clients: v })}
              multi
              className="col-sm-12"
            />

          </Row>

        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditZones);
