import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ItinerarySidebarService from "./ItinerarySidebarService"; // Adjust the import path as necessary
import FolderItem from "./FolderItem";
import "./style.scss";
import { DialogContext } from "../../../store/context/DialogContext";
import { toJS } from "mobx";

const ItinerarySidebar = observer(() => {

  const [collapsedSpaces, setCollapsedSpaces] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [newSpaceName, setNewSpaceName] = useState('');
  const { showError } = useContext(DialogContext);



  
  useEffect(() => {
    const fetch = async () => {
      const response = await ItinerarySidebarService.fetch();
    }

    fetch()
  }, []);

  const handleCreateSpace = async ({
    name,
    parentId = null
  }) => {
    try {
      const response = await ItinerarySidebarService.save({
        module: "itinerary",
        name: name,
        parentSpace_id: parentId,
      });
      // const childResponse = await ItinerarySidebarService.save({
      //   module: "itinerary",
      //   name: "sample 1",
      //   parentSpace_id: response,
      // }, true);
      // ItinerarySidebarService.setSelectedFolder(childResponse);
    } catch (error) {
      showError(error)
    }

  };

  const toggleCollapse = (id) => {
    setCollapsedSpaces((prevCollapsed) => ({
      ...prevCollapsed,
      [id]: !prevCollapsed[id]
    }));
  };

  const handleStartCreatingSpace = () => {
    setIsCreating(true);
  };

  const handleNewSpaceNameChange = (event) => {
    setNewSpaceName(event.target.value);
  };

  const handleNewSpaceKeyDown = async (event) => {
    if (event.key === 'Enter' && newSpaceName.trim() !== '') {
      await handleCreateSpace({ name: newSpaceName.trim() });
      setNewSpaceName('');
      setIsCreating(false);
    }
  };

  const renderSpaces = (spaces, level = 0) => {
    console.log({ spaces: toJS(spaces) })
    return spaces.map((folder) => (
      <div className="" key={folder._id} style={{ paddingLeft: `${level * 20}px` }}>
        <FolderItem
          key={folder._id}
          folder={folder}
          onToggleCollapse={() => toggleCollapse(folder._id)}
          onSpaceClick={() => {
            if (folder?.parentSpace_id) {
              ItinerarySidebarService.setSelectedFolder(folder);
            }
          }}
        />
        {!collapsedSpaces[folder._id] && folder.children && renderSpaces(folder.children, level + 1)}
      </div>
    ));
  };

  return (
    <>
      <div className="p-2" >
        {renderSpaces(ItinerarySidebarService.records)}
        {isCreating ? (
          <input
            autoFocus
            type="text"
            placeholder="Folder name"
            value={newSpaceName}
            onBlur={() => {
              setNewSpaceName('');
              setIsCreating(false);
            }}
            onChange={handleNewSpaceNameChange}
            onKeyDown={handleNewSpaceKeyDown}
            className="mt-2 folder-creation-input" // Make sure to define this class in your CSS
          />
        ) : (
          <div
            style={{ backgroundColor: "#EBEBEB", borderRadius: "4px", padding: "4px" }}
            className="mt-2 d-flex align-items-center clickable"
            onClick={handleStartCreatingSpace}>
            <FaPlus className="me-2" />
            <span className="font-size-14">Create Folder</span>
          </div>
        )}
      </div>
    </>
  );
});

export default ItinerarySidebar;
