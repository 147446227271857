import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FaCartShopping } from 'react-icons/fa6';
import Card from '../../../../components/Cards/Card';
import { doGET } from '../../../../util/HttpUtil';
import CustomerDisplay from './CustomerDisplay';
import CustomerHistory from './CustomerHistory';
import CustomerOrderDetails from './CustomerOrderDetails';
import { DialogContext } from '../../../../store/context/DialogContext';

const CustomerDetails = ({customerId}) => {
    const [gridLoading, setGridLoading] = useState(false);
    const [individualCustomerData, setindividualCustomerData] = useState({});
    const {showError} =useContext(DialogContext)
    const [editpage,setEditPage]=useState(false)


    const fetchPersonalDetail = useCallback(async () => {
        setGridLoading(true)
        if(customerId){
            try {
                let response = await doGET("/api/customer/"+customerId+"/detail");
                const personalDetail = response?.data || {};
                const personalAttributes = {
                    ...personalDetail
                };
                setindividualCustomerData(personalAttributes);
            } catch (error) {
                showError(error)
            } finally {
                setGridLoading(false)
            }

        }

    }, [customerId])

    useEffect(() => {
        fetchPersonalDetail()
    }, [editpage,fetchPersonalDetail])

    return (
        <div className="d-flex flex-column vh-100 pd-5">
            <Card>
                <Card.Body>
                    <CustomerDisplay
                        userData={individualCustomerData}
                        gridLoading={gridLoading}
                        customerId={customerId}
                        handleEditModal={() => setEditPage(!editpage)}
                    />
                </Card.Body>
            </Card>
            <Card>
                {/* <Card.Header>
                    <FaCartShopping
                        size={20}
                        color='#516A76'
                        className='ml-1'
                    />&nbsp;
                    <span className='font-size-13 text-light_blue fw-semibold'>
                        Order Details
                    </span>
                </Card.Header> */}
                <Card.Body>
                    <CustomerHistory
                    customerId={customerId}
                        userData={individualCustomerData}
                        gridLoading={gridLoading}
                    />
                </Card.Body>
            </Card>
            <Card>
                {/* <Card.Header>
                    <FaCartShopping
                        size={20}
                        color='#516A76'
                        className='ml-1'
                    />&nbsp;
                    <span className='font-size-13 text-light_blue fw-semibold'>Order History</span>
                </Card.Header> */}
                <Card.Body height={400}>
                    <CustomerOrderDetails customerId={customerId} />
                </Card.Body>
            </Card>
        </div>
    );
};

export default CustomerDetails;
