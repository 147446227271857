export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/apiLog/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },

  get: (logid) => `/api/apiLog/${logid}/detail`,
  sms: (logid) => `/api/apiLog/${logid}/sms/retry`,
};

export const STRUCTURE = [
  {
    label: "Start Date and Time",
    description: "description...",
    filterLabel: "Start Date and Time",
    filterName: "startTimeFrom",
    type: "dateTimeSelect",
  },
  {
    label: "End Date and Time",
    description: "description...",
    filterLabel: "End Date and Time",
    filterName: "startTimeTo",
    type: "dateTimeSelect",
  },
  {
    label: "API Name",
    description: "description...",
    filterLabel: "API Name",
    filterName: "apiName",
    type: "text",
  },
  {
    label: "Phone",
    descriptionp: "description...",
    filterLabel: "Phone",
    filterName: "phone",
    type: "text",
  },
  {
    label: "Units",
    description: "description...",
    filterLabel: "Units",
    filterName: "smsSize",
    type: "text",
  },
  {
    label: "Kind",
    description: "description...",
    filterLabel: "Kind",
    filterName: "kind",
    type: "select",
    data: [
      { label: "User Login SMS", value: "SMS_0" },
      { label: "Forget Password SMS", value: "SMS_1" },
      { label: "NAV Create Customer", value: "NAV_1" },
      { label: "NAV Modify Customer", value: "NAV_2" },
      { label: "NAV Create Sales Order", value: "NAV_3" },
      { label: "NAV Cancel Sales Order", value: "NAV_4" },
      { label: "NAV Collection", value: "NAV_5" },
      { label: "NAV Refund", value: "NAV_6" },
      { label: "SignUp", value: "SSO_1" },
      { label: "Login", value: "SSO_2" },
      { label: "Profile Detail", value: "SSO_3" },
      { label: "Change Password", value: "SSO_4" },
      { label: "SSOTOKEN authentication", value: "SSO_5" },
      { label: "Get admin SSO token", value: "SSO_6" },
      { label: "Booking Created", value: "B2B_1" },
      { label: "Booking Cancelled", value: "B2B_2" },
      { label: "Booking Amendment", value: "B2B_3" },
      { label: "Zillious system login", value: "ZILLIOUS_1" },
      { label: "Zillious update driver detail", value: "ZILLIOUS_2" },
      { label: "Zillious update invoice detail", value: "ZILLIOUS_3" },
      { label: "Gift Voucher get balance", value: "GIFT_VOUCHER_1" },
      { label: "Gift Voucher withdraw balance", value: "GIFT_VOUCHER_2" },
      {
        label: "Gift Voucher cancel withdraw balance",
        value: "GIFT_VOUCHER_3",
      },
      { label: "Europe Car Book Reservation", value: "EUROPE_CAR_BOOK" },
      { label: "Europe Car Cancel Reservation", value: "EUROPE_CAR_CANCEL" },
      { label: "Europe Car Search Multiple Rates", value: "EUROPE_CAR_SEARCH" },
      {
        label: "Europe Car Search Station by city",
        value: "EUROPE_CAR_STATION_BY_CITY",
      },
      {
        label: "Europe Car Search Car Category",
        value: "EUROPE_CAR_CAR_CATEGORY",
      },
      { label: "TravelLogix Book", value: "TRAVEL_LOGIX_BOOK" },
      { label: "TravelLogix Cancel", value: "TRAVEL_LOGIX_CANCEL" },
    ],
  },
  {
    label: "Is Failed",
    description: "description...",
    filterLabel: "Is Failed",
    filterName: "isFailed",
    type: "select",
    data: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
  {
    label: "Ticket Content",
    description: "description...",
    filterLabel: "Ticket Content",
    filterName: "content",
    type: "text",
  },
  {
    label: "Booking Number",
    description: "description...",
    filterLabel: "Booking Number",
    filterName: "bookingNo",
    type: "text",
  },
];
