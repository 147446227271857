import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import Supervisor from "../../../pages/Supervisor/Supervisor";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, ModalComponent, SearchButton } from "../../index";
import SupervisorSelectService from "./SupervisorSelectService";
import { Tooltip } from "react-tooltip";
import { objectIdToColor, generateUniqueId } from "../../dnd/board/helper";
import "./style.scss"


const SupervisorChip = ({ supervisor = { name: "NA", _id: null } }) => {
  const { name = "NA", _id = generateUniqueId() } = supervisor;

  return (
    <div>
      <Tooltip style={{ zIndex: '200000' }} anchorSelect={`#not-clickable-${_id}`}>
        <div>{name == "NA" ? "Unknown" : name}</div>
      </Tooltip>

      <div id={`not-clickable-${_id}`} className="supervisor-chip">
        <span
          style={{ backgroundColor: name == "NA" ? "#707070" : objectIdToColor(_id) }}
          className="supervisor-initials"
        >{name == "NA" ? "NA" : name.split(' ').map(n => n[0]).join('')}
        </span>
      </div>
    </div >
  );
};

const SupervisorSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  guide,
  driver,
  guard,
  sales,
  outline,
  asLabel,
  showName=false
}) => {

  const [supervisors, setSupervisors] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const updateSupervisors = () => {
    setSupervisors(
      toJS(SupervisorSelectService.supervisors)?.map((supervisor) => {
        if (
          (driver && supervisor?.isDriver) ||
          (sales && supervisor?.isSales) ||
          (guide && supervisor?.isGuide) ||
          (guard && supervisor?.isGuard) ||
          (!driver && !guard && !guide && !sales)
        ) {
          return {
            ...supervisor,
            value: supervisor._id,
            label: supervisor.name
          };
        }
        return null;
      }).filter(Boolean)
    );
  };

  const fetchSupervisors = async () => {
    try {
      await SupervisorSelectService.fetch();
      updateSupervisors()
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (SupervisorSelectService.supervisors.length === 0) fetchSupervisors();
  }, []);

  useEffect(() => {
    updateSupervisors()
  }, [SupervisorSelectService.supervisors]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectSupervisor = (selectedValue) => {
    onChange({ ...selectedValue, value: selectedValue?._id });
    closeModal();
  };

  const selectSupervisors = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Supervisors")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={supervisors}
      required={required}
      value={value}
      label={label || (!isDisabled && t("Supervisor"))}
      error={!value ? t(`Please Select ${label || "Supervisor"}`) : ""}
      showErr={showErr}
      inline={inline}
      outline={outline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Supervisors")} />
      )}
      // showBottomBar={true}
      className={className}
      outline={outline}
      onChange={selectSupervisors}
      data={supervisors}
      value={value ? supervisors.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Supervisors"}`) : ""}
      required={required}
      label={label || (!isDisabled && t("Select Supervisors"))}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
      {asLabel
        ? multi ?
          value?.length > 0 ?
            <div>
              {value?.map((selectedValue, index) => {
                const supervisor = supervisors.find((supervisor) => supervisor.value == selectedValue);
                return (
                  <span key={selectedValue}>
                    {supervisor ? supervisor.label : 'Unknown Supervisor'}
                    {index < value.length - 1 && ", "}
                  </span>
                );
              })}
            </div>
            : <div style={{ color: "grey" }}>Unknown Supervisor</div>
          : (
            <div className="d-flex align-items-center">
              <SupervisorChip
                supervisor={supervisors?.find((supervisor) => supervisor?.value == value)}
              />
             {showName && <b className="mx-1">{(supervisors?.find((supervisor) => supervisor?.value == value)?.name)}</b>}
             </div>)
              :
        <>

          {modal && (
            <ModalComponent
              position={"top"}
              size={"large"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Supervisor")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <Supervisor
                  value={value}
                  insidePane={true}
                  multiMode={multi}
                  onSelect={multi ? selectSupervisors : selectSupervisor}
                />
              </ModalComponent.Body>

            </ModalComponent>
          )}


          {supervisors.length > 0
            ? multi
              ? renderSelectFieldMultiple()
              : renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      }

    </>

  );
};

export default observer(SupervisorSelect);
