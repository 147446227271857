import React from 'react'
import { TaxSelect } from '../../../components'

function FeeGroupTaxSelector({
    isDisabled = false,
    handleTaxSelectChange = () => { },
    value = ""

}) {
    return (
        <>

            <tr>
                <td> </td>
                <td> </td>
                <td> Tax </td>
                <td className="pt-0 pb-0" colSpan="2">
                    <TaxSelect
                        selectZeroTax
                        isDisabled={isDisabled}
                        outline
                        noLabel
                        asLabel={isDisabled}
                        value={value ?? ""}
                        onChange={(v) => handleTaxSelectChange(v)}
                        className="col-sm-12 m-0"
                    />
                </td>
                <td>
                </td>
            </tr>
        </>
    )
}

export default FeeGroupTaxSelector