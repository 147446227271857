import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET, doPOST } from "../../../util/HttpUtil";

class TariffTypeSelect {
    tariffTypes = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filterData) {
        if (this.tariffTypes.length > 0)
            return;
        try {
            const response = await doGET(ENDPOINTS.getAllTariffTypes);
            if (response.status === 200) {
                ;
                this.tariffTypes = response.data.map((k) => { return { label: k, value: k } });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const TariffTypeSelectService = new TariffTypeSelect();
export default TariffTypeSelectService;
