import { CompanySelect } from "../../components";

export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Company",
            renderer: (dataObj) => {
                return <div>
                    <CompanySelect
                        asLabel
                        corpType="self"
                        value={dataObj?.vendorInfo?.company_id}
                        onChange={(v) => { }}
                        className=""
                    />
                </div>;
            },
            show: true
        },
        {
            title: "TaxId",
            renderer: (dataObj) => {
                return <div>{dataObj?.vendorInfo?.taxId ?? '-'}</div>;
            },
            show: true,
        },
        {
            title: "Invoice No. Format",
            accessor: "invNoPrefix",
            show: true,
        },
        {
            title: "Email",
            renderer: (dataObj) => {
                return <div>{dataObj?.vendorInfo?.email ?? '-'}</div>;
            },
            show: true,
        },
        {
            title: "Phone",
            renderer: (dataObj) => {
                return <div>{dataObj?.vendorInfo?.phoneNo ?? '-'}</div>;
            },
            show: true,
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
