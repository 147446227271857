import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { ENDPOINTS } from "./RateConstant";
import { getUiPrefRows } from "../../util/FilterUtil";

class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = null;
  filterOption = "";
  total = -1;
  forSupplier = false;
  version = 0;

  constructor() {
    makeAutoObservable(this);
  }
  fetch = async (filterUrl) => {
    if (!this.rowsPerPage) {
      const rows = await getUiPrefRows("Rate.grid");
      this.rowsPerPage = rows ?? 40;
    }
    if (filterUrl) {
      this.filterOption = filterUrl;
    }
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl, this.forSupplier)
    );

    if (response?.status === 200) {
      this.records = response.data.rows;
      this.page = response?.data?.page;
      if (this.page === 1) this.total = response?.data?.total;
    }
  };

  fetchItemLabels = async () => {
    const response = await doGET(ENDPOINTS.getRateItems());
    if (response?.status === 200) {
      return response.data;
    }
  };

  edit = async function (data, id) {
    const response = await doPUT(ENDPOINTS?.update, data);
    if (response.status === 200) {
      this.version += 1;
    }
  };

  get = async (rateNo, forSupplier) => {
    const response = await doGET(ENDPOINTS.fetchAll(rateNo, forSupplier));
    if (response.status === 200) {
      return response.data.rows ? response.data.rows : response;
    }
  };

  save = async (data) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save(this.forSupplier), data);
    return response;
  };

  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);
  };

  onSupplierChange = (isSupplier) => {
    this.forSupplier = isSupplier;
  };
}

const Rateservice = new Service();
export default Rateservice;
