import { toJS } from "mobx";
import { useCallback, useContext, useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import {
  Button,
  Card,
  CardFooter,
  Col
} from "reactstrap";
import { AmountField, InputField, TaxSelect } from "../../../components";
import RateItemService from "../../../components/Dropdowns/RateItemSelect/RateItemSelectService";
import { I18nContext } from "../../../store/context/i18nContext";
import ChargeName from "../../Booking/components/ChargeName";
import "./style.css";
import RemoveLink from "../../../components/Buttons/RemoveLink";
const Fee = ({
  item,
  onChange,
  itemIndex
}) => {
  const { t } = useContext(I18nContext);
  const ZeroIfNaN = (value) => isNaN(value) ? 0 : (value).toFixed(2);
  const { fee } = item

  const [feeEditState, setFeeEditState] = useState({
    visible: true,
    index: -1
  })
  const [rateNames, setRateNames] = useState({});

  const fetchRateNames = async () => {
    const rateNameResponse = await RateItemService?.getRateItems();
    let rateNameObject = {}

    toJS(rateNameResponse)?.map((rateName) => {
      rateNameObject[rateName?.value] = rateName?.label
      rateNameObject[rateName?.label] = rateName?.label
    });
    setRateNames(rateNameObject)
    return rateNameObject
  }

  useEffect(() => {
    fetchRateNames()
  }, [])

  const updateFee = useCallback(
    (fieldName, fieldValue) => {
      if (fieldName && fieldValue) {
        let feeCopy = { ...fee } || {
          feeGroups: [
            {
              charges: [{}],
              discounts: [{}],
              taxItem: {
                taxes: [{}],
              },
            },
          ],
        };
        feeCopy[fieldName] = fieldValue;
        if (fieldName === "feeGroups") {
          feeCopy = handleFeeCalculation(feeCopy);
        }
        onChange(feeCopy);
      }
    },
    [fee, onChange]
  );



  const handleFeeCalculation = (localFee) => {
    if (localFee) {
      localFee.subTotal =
        localFee.feeGroups?.reduce(
          (acc, feeGroup) => acc + (feeGroup?.subTotal || 0),
          0
        ) || 0;
      localFee.totalDiscounts =
        localFee.feeGroups?.reduce(
          (acc, feeGroup) => acc + (feeGroup?.totalDiscounts || 0),
          0
        ) || 0;
      localFee.totalTaxes =
        localFee.feeGroups?.reduce(
          (acc, feeGroup) => acc + (feeGroup?.totalTaxes || 0),
          0
        ) || 0;
      localFee.total =
        localFee.feeGroups?.reduce(
          (acc, feeGroup) => acc + (feeGroup?.total || 0),
          0
        ) || 0;
    }
    return localFee;
  };

  const updateCharges = (index, chargeIndex, field, value = 0) => {
    if (!fee?.feeGroups?.[index]) return;

    const tempCharges = [...(fee.feeGroups[index]?.charges || [{}])];
    let updatedCharge = tempCharges[chargeIndex];
    updatedCharge = {
      ...updatedCharge,
      [field]: value ? value : typeof value === "string" ? "" : 0,
    };

    if (field === "units" || field === "unitCost") {
      updatedCharge.amount = Number(
        (((updatedCharge.units ?? 0) * (updatedCharge.unitCost ?? 0)) / 100)?.toFixed(2) ?? 0
      );
    }
    //   updatedCharge.amount = parseFloat(
    //     (updatedCharge.units * updatedCharge.unitCost).toFixed(2) ?? "0"
    //   );
    // }

    tempCharges[chargeIndex] = updatedCharge;
    let feeCopy = { ...fee };
    let subTotal = 0;
    tempCharges.map((charge) => {

      subTotal += (charge?.amount >= 0 ? charge?.amount : 0);
    });
    feeCopy.feeGroups[index].subTotal = subTotal;
    feeCopy.feeGroups[index].total =
      subTotal +
      feeCopy.feeGroups[index].totalTaxes -
      feeCopy.feeGroups[index].totalDiscounts;
    feeCopy.feeGroups[index].charges = tempCharges;
    let items = feeCopy?.feeGroups?.[index]?.taxItem?.taxes;
    // Calculate taxes
    let tempTotalTaxes = 0;
    let taxItems = items?.map((item) => {
      let taxAmount =
        (((item?.percent ?? 0)) * feeCopy?.feeGroups?.[index]?.subTotal ?? 0) / 10000;
      tempTotalTaxes += (taxAmount);
      return {
        name: item?.name,
        amount: taxAmount,
        baseAmount: item?.baseAmount,
        percent: item?.percent,
      };
    });
    feeCopy.feeGroups[index].taxItem.taxes = taxItems;
    feeCopy.feeGroups[index].totalTaxes = tempTotalTaxes;

    // Calculate total
    feeCopy.feeGroups[index].total =
      feeCopy.feeGroups[index].subTotal +
      (feeCopy.feeGroups[index].totalTaxes ? feeCopy.feeGroups[index].totalTaxes : 0) -
      (feeCopy.feeGroups[index].totalDiscounts ? feeCopy.feeGroups[index].totalDiscounts : 0);

    let finalSubTotal = 0;
    let finalTotalDiscount = 0;
    let finalTotalTaxes = 0;
    let finalTotal = 0;

    feeCopy?.feeGroups.forEach(feegroup => {
      finalSubTotal += feegroup?.subTotal || 0;
      finalTotalDiscount += feegroup?.totalDiscounts || 0;
      finalTotalTaxes += feegroup?.totalTaxes || 0;
      finalTotal += feegroup?.total || 0;
    });

    feeCopy.subTotal = finalSubTotal;
    feeCopy.totalDiscounts = finalTotalDiscount
    feeCopy.totalTaxes = finalTotalTaxes
    feeCopy.total = finalTotal

    onChange(feeCopy);
  };

  const AddCharges = (index) => {
    if (!fee?.feeGroups || !fee.feeGroups[index]) return;

    const tempCharges = (fee.feeGroups[index]?.charges || []).slice();
    const newCharge = {
      name: "",
      unitCost: 0,
      amount: 0,
      units: 0,
    };
    tempCharges.push(newCharge);
    const feeCopy = { ...fee };
    let subTotal = 0;
    tempCharges.map((charge) => {
      subTotal += charge?.amount;
    });
    feeCopy.feeGroups[index].subTotal = subTotal;
    feeCopy.feeGroups[index].charges = tempCharges;
    feeCopy.feeGroups[index].total =
      subTotal +
      feeCopy.feeGroups[index].totalTaxes -
      feeCopy.feeGroups[index].totalDiscounts;
    onChange(feeCopy);
  };

  const AddDiscount = (groupIndex) => {
    if (!fee?.feeGroups || !fee.feeGroups[groupIndex]) return;

    const tempDiscounts = (
      fee.feeGroups[groupIndex]?.discounts || []
    ).slice();
    const newDiscount = {
      detail: "",
    };
    tempDiscounts.push(newDiscount);
    const feeCopy = { ...fee };
    let tempTotalDiscount = 0;
    tempDiscounts.map((discount) => {
      tempTotalDiscount += discount?.amount;
    });
    feeCopy.feeGroups[groupIndex].totalDiscounts =
      feeCopy.feeGroups[groupIndex].totalDiscounts ?? 0;
    feeCopy.feeGroups[groupIndex].totalDiscounts = tempTotalDiscount;
    feeCopy.feeGroups[groupIndex].discounts = tempDiscounts;
    feeCopy.feeGroups[groupIndex].total =
      feeCopy.feeGroups[groupIndex].subTotal +
      feeCopy.feeGroups[groupIndex].totalTaxes -
      feeCopy.feeGroups[groupIndex].totalDiscounts;
    onChange(feeCopy);
  };

  const updateDiscounts = (discountIndex, groupIndex, field, value) => {
    if (!fee || !fee.feeGroups || fee.feeGroups.length === 0) return;

    let feeCopy = { ...fee };
    let tempDiscounts = feeCopy.feeGroups[groupIndex].discounts
      ? feeCopy.feeGroups[groupIndex].discounts.slice()
      : [];
    tempDiscounts[discountIndex] = {
      ...tempDiscounts[discountIndex],
      [field]: value ? value : 0,
    };
    feeCopy.feeGroups[groupIndex].discounts = tempDiscounts;

    // Calculate the total
    let tempDiscount = 0;
    tempDiscounts.forEach((discount) => {
      tempDiscount += discount.amount || 0;
    });
    // feeCopy.feeGroups[groupIndex].totalDiscounts =
    //   feeCopy.feeGroups[groupIndex].totalDiscounts ?? 0;

    let taxes = feeCopy?.feeGroups[groupIndex]?.taxItem?.taxes;


    feeCopy.feeGroups[groupIndex].totalDiscounts = tempDiscount;




    // updateTaxes()

    let tempTaxes = 0;

    // taxes?.map((tax, taxIndex) => {
    //   tempTaxes += ((tax?.percent / 100) * (feeCopy.feeGroups[groupIndex]?.subTotal - feeCopy.feeGroups[groupIndex]?.totalDiscounts) + (tax?.baseAmount / 100))
    // })


    const taxItems = taxes.map((item) => {
      const taxAmount = (((item.percent) * (fee.feeGroups[groupIndex].subTotal - fee.feeGroups[groupIndex]?.totalDiscounts) + (item?.baseAmount)) ?? 0) / 10000
      tempTaxes += taxAmount;
      return {
        name: item.name,
        amount: taxAmount,
        baseAmount: item.baseAmount,
        percent: item.percent,
      };
    });


    feeCopy.feeGroups[groupIndex].taxItem = {
      tax_id: feeCopy?.feeGroups[groupIndex]?.taxItem?.tax_id,
      taxes: taxItems,
    };

    feeCopy.feeGroups[groupIndex].totalTaxes = tempTaxes

    feeCopy.feeGroups[groupIndex].total =
      (feeCopy.feeGroups[groupIndex].subTotal ? feeCopy.feeGroups[groupIndex].subTotal : 0) +
      (feeCopy.feeGroups[groupIndex].totalTaxes ? feeCopy.feeGroups[groupIndex].totalTaxes : 0) -
      (tempDiscount ? tempDiscount : 0)

    let finalSubTotal = 0;
    let finalTotalDiscount = 0;
    let finalTotalTaxes = 0;
    let finalTotal = 0;

    feeCopy?.feeGroups.forEach(feegroup => {
      finalSubTotal += feegroup?.subTotal || 0;
      finalTotalDiscount += feegroup?.totalDiscounts || 0;
      finalTotalTaxes += feegroup?.totalTaxes || 0;
      finalTotal += feegroup?.total || 0;
    });

    feeCopy.subTotal = finalSubTotal;
    feeCopy.totalDiscounts = finalTotalDiscount
    feeCopy.totalTaxes = finalTotalTaxes
    feeCopy.total = finalTotal

    onChange(feeCopy);
  };

  const updateTaxes = (v, groupIndex) => {
    // Check if fee and its properties exist
    if (
      !fee ||
      !fee.feeGroups ||
      fee.feeGroups.length === 0 ||
      !fee.feeGroups[groupIndex]
    ) {
      return;
    }

    const { items } = v || {};
    if (!items || items.length === 0) {
      return;
    }

    // Calculate taxes and create tax items
    let totalTaxes = 0;
    const taxItems = items.map((item) => {
      const taxAmount = (((item.percent) * (fee.feeGroups[groupIndex].subTotal - fee.feeGroups[groupIndex]?.totalDiscounts) + (item?.baseAmount)) ?? 0) / 10000
      totalTaxes += (taxAmount);
      return {
        name: item.name,
        amount: taxAmount,
        baseAmount: item.baseAmount,
        percent: item.percent,
      };
    });

    // Update fee properties
    const feeCopy = { ...fee };
    feeCopy.feeGroups[groupIndex].totalDiscounts =
      feeCopy.feeGroups[groupIndex].totalDiscounts ?? 0;
    feeCopy.feeGroups[groupIndex].subTotal =
      feeCopy.feeGroups[groupIndex].subTotal ?? 0;
    feeCopy.feeGroups[groupIndex].totalTaxes = totalTaxes;
    feeCopy.feeGroups[groupIndex].taxItem = {
      tax_id: v?._id,
      taxes: taxItems,
    };

    // Calculate total
    feeCopy.feeGroups[groupIndex].total =
      feeCopy.feeGroups[groupIndex].subTotal +
      feeCopy.feeGroups[groupIndex].totalTaxes -
      feeCopy.feeGroups[groupIndex].totalDiscounts;

    // Invoke onChange with updated fee

    let finalSubTotal = 0;
    let finalTotalDiscount = 0;
    let finalTotalTaxes = 0;
    let finalTotal = 0;

    feeCopy?.feeGroups.forEach(feegroup => {
      finalSubTotal += feegroup?.subTotal || 0;
      finalTotalDiscount += feegroup?.totalDiscounts || 0;
      finalTotalTaxes += feegroup?.totalTaxes || 0;
      finalTotal += feegroup?.total || 0;
    });

    feeCopy.subTotal = finalSubTotal;
    feeCopy.totalDiscounts = finalTotalDiscount
    feeCopy.totalTaxes = finalTotalTaxes
    feeCopy.total = finalTotal

    onChange(feeCopy);
  };

  const RemoveDiscount = (groupIndex, DiscountIndex) => {
    if (!fee?.feeGroups || !fee.feeGroups[groupIndex]) return;

    const tempDiscounts = [...fee.feeGroups[groupIndex]?.discounts];
    if (DiscountIndex < 0 || DiscountIndex >= tempDiscounts.length) return;
    tempDiscounts.splice(DiscountIndex, 1);

    const feeCopy = { ...fee };
    let tempDiscountAmount = 0;
    tempDiscounts.map((discount) => {
      tempDiscountAmount += discount?.amount;
    });
    feeCopy.feeGroups[groupIndex].totalDiscounts = tempDiscountAmount;
    feeCopy.feeGroups[groupIndex].total =
      feeCopy.feeGroups[groupIndex].subTotal +
      feeCopy.feeGroups[groupIndex].totalTaxes -
      feeCopy.feeGroups[groupIndex].totalDiscounts;
    feeCopy.feeGroups[groupIndex].discounts = tempDiscounts;

    let finalSubTotal = 0;
    let finalTotalDiscount = 0;
    let finalTotalTaxes = 0;
    let finalTotal = 0;

    feeCopy?.feeGroups.forEach(feegroup => {
      finalSubTotal += feegroup?.subTotal || 0;
      finalTotalDiscount += feegroup?.totalDiscounts || 0;
      finalTotalTaxes += feegroup?.totalTaxes || 0;
      finalTotal += feegroup?.total || 0;
    });

    feeCopy.subTotal = finalSubTotal;
    feeCopy.totalDiscounts = finalTotalDiscount
    feeCopy.totalTaxes = finalTotalTaxes
    feeCopy.total = finalTotal
    onChange(feeCopy);
  };

  const RemoveCharges = (groupIndex, chargeIndex) => {
    if (!fee?.feeGroups || !fee.feeGroups[groupIndex]) return;

    const tempCharges = [...fee.feeGroups[groupIndex]?.charges];
    if (chargeIndex < 0 || chargeIndex >= tempCharges.length) return;
    tempCharges.splice(chargeIndex, 1);

    const feeCopy = { ...fee };
    let subTotal = 0;
    tempCharges.map((charge) => {
      subTotal += charge?.amount;
    });
    feeCopy.feeGroups[groupIndex].total =
      subTotal +
      feeCopy.feeGroups[groupIndex].totalTaxes -
      feeCopy.feeGroups[groupIndex].totalDiscounts;
    feeCopy.feeGroups[groupIndex].subTotal = subTotal;
    feeCopy.feeGroups[groupIndex].charges = tempCharges;

    let finalSubTotal = 0;
    let finalTotalDiscount = 0;
    let finalTotalTaxes = 0;
    let finalTotal = 0;

    feeCopy?.feeGroups.forEach(feegroup => {
      finalSubTotal += feegroup?.subTotal || 0;
      finalTotalDiscount += feegroup?.totalDiscounts || 0;
      finalTotalTaxes += feegroup?.totalTaxes || 0;
      finalTotal += feegroup?.total || 0;
    });

    feeCopy.subTotal = finalSubTotal;
    feeCopy.totalDiscounts = finalTotalDiscount
    feeCopy.totalTaxes = finalTotalTaxes
    feeCopy.total = finalTotal
    onChange(feeCopy);
  };

  const RemoveFeeGroup = (groupIndex) => {
    if (!fee?.feeGroups || !fee.feeGroups[groupIndex]) return;

    const tempFeeGroups = [...fee.feeGroups];
    if (groupIndex < 0 || groupIndex >= tempFeeGroups.length) return;
    tempFeeGroups.splice(groupIndex, 1);

    const feeCopy = { ...fee };
    feeCopy.feeGroups = tempFeeGroups;
    onChange(feeCopy);
  };

  const getAddress = (dataAddress) => {

    // const dataAddress = data?.InvoiceConfig?.vendorInfo?.address;
    let newAddress = ``


    if (dataAddress?.addressLine) {
      newAddress += dataAddress?.addressLine
    }
    if (dataAddress?.city && dataAddress?.addressLine) {
      newAddress += `, `
    }
    if (dataAddress?.city) {
      newAddress += dataAddress?.city
    }
    if (dataAddress?.city && dataAddress?.state) {
      newAddress += `, `
    }
    if (dataAddress?.state) {
      newAddress += dataAddress?.state
    }

    if (dataAddress?.country && dataAddress?.state) {
      newAddress += `, `
    }
    if (dataAddress?.country) {
      newAddress += dataAddress?.country
    }


    if (dataAddress?.country && dataAddress?.pinCode) {
      newAddress += `, `
    }
    if (dataAddress?.pinCode) {
      newAddress += dataAddress?.pinCode
    }

    // newAddress += `</div>`
    return newAddress
  }


  return (
    <div className="pt-2">
      <div>
        <div className="d-flex row align-items-center my-1">
          <div className="row ms-0">
            {item?.invoiceTrip?.trip_id ? <div className="col-sm-6 col-md-4 d-flex" style={{
              paddingLeft: '8px'
            }}>
              <b className="me-2">Start Time:</b>
              <div>{item?.invoiceTrip?.startTime ? item.invoiceTrip.startTime : ""}</div>
            </div> : null}
            {item?.invoiceTrip?.trip_id ? <div className="col-sm-6 col-md-4  d-flex">
              <b className="me-2">Vehicle Type:</b>
              <div>{item?.invoiceTrip?.vehicleMakeModal}</div>
            </div> : null}
            {item?.invoiceTrip?.trip_id ? <div className="col-sm-6 col-md-4  d-flex">
              <b className="me-2">Duty Slip No:</b>
              <div>{item?.invoiceTrip?.dutySlipNo}</div>
            </div> : null}

            {!item?.invoiceTrip?.trip_id &&
              <div className=" d-flex justify-content-center align-items-center btn btn-outline-dark" style={{
                cursor: 'pointer',
                height: "20px",
                width: "68px",
                position: "absolute",
                top: "-15px",
                right: "20px"
              }} onClick={(e) => {
                e.stopPropagation();
                setFeeEditState({
                  visible: true,
                  index: itemIndex
                })
              }}>
                <MdEdit
                  fontSize={16}
                  style={{ cursor: "pointer" }}
                />
                <div style={{
                  fontSize: 12,
                  marginLeft: "5px"
                }}>Edit</div>
              </div>}
          </div>

          {item?.invoiceTrip?.trip_id ? <div className="row ms-0">
            <div className="col-sm-6 col-md-4  d-flex " style={{
              paddingLeft: '8px'
            }}>
              <b className="me-2">End Time:</b>
              <div>{item?.invoiceTrip?.endTime ? item.invoiceTrip.endTime : "--"}</div>
            </div>
            <div className="col-sm-6 col-md-4 d-flex">
              <b className="me-2">Vehicle:</b>
              <div>{item?.invoiceTrip?.vehicleRegNo}</div>
            </div>
          </div> : null}

        </div>
        {item?.invoiceTrip?.trip_id ? <div className="d-flex  align-items-center mt-1 ms-2 font-size-12">
          <b className="me-2">PickUp:</b>
          <div>{item?.invoiceTrip?.src ? getAddress(item?.invoiceTrip?.src) : "--"}</div>
        </div> : null}
        {item?.invoiceTrip?.trip_id ? <div className="d-flex  align-items-center mt-1 ms-2 mb-2 font-size-12">
          <b className="me-2">Drop Point:</b>
          <div>{item?.invoiceTrip?.dst ? getAddress(item?.invoiceTrip?.dst) : "--"}</div>
        </div> : null}
      </div>
      {fee?.feeGroups && fee?.feeGroups.length > 0 &&
        fee?.feeGroups?.map((feeGroup, index) =>
          (feeEditState?.visible) ? (
            <div>
              <div className="d-flex flex-column align-items-end mb-0">
                <div
                  style={{
                    width: "2%",
                    marginLeft: "10px",
                    cursor: "pointer",
                    marginBottom: "10px",
                    alignItems: 'end'
                  }}
                >
                  {fee?.feeGroups.length !== 1 && (
                    <i
                      onClick={() => RemoveFeeGroup(index)}
                      className="bx color-blue font-size-20 bxs-x-circle mt-1  p-0"
                    ></i>
                  )}
                </div>
                <table key={index}>
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>Charge Type</th>
                      <th style={{ width: "30%" }}>Detail</th>
                      <th style={{ width: "20%", marginRight: '4px' }}>Unit Cost</th>
                      <th style={{ width: "20%" }}>No. of Units </th>
                      <th style={{ width: "10%", textAlign: "end" }}>
                        Amount
                      </th>
                      <th style={{ width: "7%" }} className="me-0 pe-0">Action</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {feeGroup?.charges?.map((charge, chargeIndex) => {
                      return (
                        <tr key={chargeIndex}>
                          <td className="p-0 pe-1" >
                            {/* <InputField
                                style={{ marginRight: '3px' }}
                                outline
                                value={charge?.name}
                                onChange={(v) => {
                                  updateCharges(index, chargeIndex, "name", v);
                                }}
                                type="text"
                              /> */}
                            <ChargeName
                              // disabled={formConfig?.diableUserInputForFee}
                              // required={true}
                              outline
                              style={{
                                fontSize: "10px",
                              }}
                              value={charge?.name}
                              onChange={(v) => {
                                updateCharges(index, chargeIndex, "name", v)

                              }}
                            />
                          </td>
                          <td className="p-0 pe-1" style={{ position: "relative" }}>
                            <InputField
                              style={{ marginRight: '3px' }}
                              outline
                              value={charge?.detail}
                              onChange={(v) => {

                                updateCharges(index, chargeIndex, "detail", v);
                              }}
                              type="text"
                            />
                          </td>
                          <td className="p-0 pe-1" style={{ position: "relative" }}>
                            <AmountField
                              style={{ marginRight: '5px' }}
                              outline
                              // value={parseFloat(
                              //   (charge?.unitCost ? charge?.unitCost / 100 : null)?.toFixed(2)
                              // )}
                              value={charge?.unitCost ?? 0}
                              onChange={(v) => {
                                updateCharges(
                                  index,
                                  chargeIndex,
                                  "unitCost",
                                  parseInt(v)
                                );
                              }}
                              type="number"
                            />
                            <div style={{ position: "absolute", right: -9, top: "40%" }}>
                              <i className="mdi font-size-16 mdi-close" />
                            </div>
                          </td>
                          <td className="p-0 pe-1" style={{ position: "relative" }}>
                            <AmountField
                              style={{ marginLeft: '7px', marginRight: '4px' }}
                              outline
                              value={charge?.units}
                              onChange={(v) => {
                                updateCharges(
                                  index,
                                  chargeIndex,
                                  "units",
                                  parseInt(v)
                                );
                              }}
                              type="number"
                            />
                            <div style={{ position: "absolute", right: -5, top: "40%" }}>
                              <div className="font-size-16">= </div>
                            </div>
                          </td>
                          <td
                            className="d-flex  justify-content-end mt-3 me-1"
                            style={{ position: "relative" }}
                          >

                            <AmountField
                              disabled={true}
                              outline
                              asLabel
                              placeholder={"Amount"}
                              // style={{ minWidth: '70px' }}
                              noLabel
                              type="number"
                              value={(charge?.amount ?? 0)}
                              onChange={(v) =>
                                updateCharges({})
                              }
                            />
                          </td>
                          <td
                            className="me-0 pe-0"
                            style={{ position: "relative" }}
                          >
                            <RemoveLink className="ms-1" onClick={() => RemoveCharges(index, chargeIndex)} />
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td className="d-flex align-items-start justify-content-between">
                        <Button
                          color="light"
                          onClick={() => {
                            AddCharges(index);
                          }}
                          className="d-flex align-items-center"
                        >
                          <i className="bx bx-plus  me-2"></i>
                          Charges
                        </Button>
                        <Button
                          color="light"
                          onClick={() => {
                            AddDiscount(index);
                          }}
                          className="d-flex align-items-center"
                        >
                          <i className="bx bx-plus  me-2"></i>
                          Discount
                        </Button>
                      </td>
                      <td>
                      </td>
                      <td colSpan={4}>
                        <Card>
                          <tr className="d-flex">
                            <td style={{ width: "20%" }} className="bold-font">Sub Total</td>
                            <td style={{ width: "30%" }}></td>
                            <td style={{ width: "30%" }}></td>
                            <td style={{ width: "20%" }}></td>
                            <td style={{ width: "25%", textAlign: 'end', paddingRight: "5px" }}>
                              {ZeroIfNaN(feeGroup.subTotal) / 100}
                            </td>
                            <td style={{ width: "10%" }}></td>
                          </tr>
                          {feeGroup?.discounts?.length > 0 &&
                            feeGroup?.discounts?.map(
                              (discount, discountIndex) => (
                                <tr
                                  key={discountIndex}
                                >
                                  <td className="pt-0 pb-0 w-50">
                                    <InputField
                                      className="pt-0 pb-0"
                                      outline
                                      placeholder="Discount Detail"
                                      label="Discount Detail"
                                      value={discount?.detail}
                                      onChange={(v) =>
                                        updateDiscounts(
                                          discountIndex,
                                          index,
                                          "detail",
                                          v
                                        )
                                      }
                                      error={
                                        discount?.amount
                                          ?.length === 0
                                          ? t(
                                            "Please enter Discount Detail"
                                          )
                                          : ""
                                      }
                                      type="text"
                                    />
                                  </td>
                                  <td></td>
                                  <td className=" w-25">
                                    <AmountField
                                      isDisabled={false}
                                      placeholder="Discount "
                                      label="Discount "
                                      noLabel
                                      type="number"
                                      value={
                                        (discount?.amount ?? 0)
                                      }
                                      outline
                                      className="w-100 pt-0 pb-0"
                                      onChange={(v) =>
                                        updateDiscounts(
                                          discountIndex,
                                          index,
                                          "amount",
                                          v
                                        )
                                      }
                                    />
                                  </td>
                                  <td></td>
                                  <td className="w-25" style={{ marginLeft: "auto" }}>
                                    <RemoveLink className="ms-1" onClick={() => RemoveDiscount(index, discountIndex)} />
                                  </td>
                                </tr>
                              )
                            )
                          }
                          <tr className="d-flex">
                            <td className="bold-font w-50 mt-2">Taxes</td>
                            <td></td>
                            <td className="w-25">
                              <TaxSelect
                                outline
                                noLabel
                                value={
                                  fee?.feeGroups[index]?.taxItem
                                    ?.tax_id ?? ""
                                }
                                onChange={(v) => {
                                  updateTaxes(v, index);
                                }}
                                className="m-0"
                              />
                            </td>
                            <td>
                            </td>
                            <td className="w-25">
                            </td>
                          </tr>
                          {fee?.feeGroups[index]?.taxItem?.taxes
                            .length > 0
                            ? fee?.feeGroups[
                              index
                            ]?.taxItem?.taxes.map(
                              (tax, index) => (
                                <tr key={index}>
                                  <>
                                    <td style={{ width: "20%" }} colSpan={2}>{tax.name}</td>
                                    <td style={{ width: "30%" }}></td>
                                    <td style={{ width: "30%" }}>
                                      {tax.percent ? <>
                                        {((tax.percent ?? 0) / 100).toFixed(2)}%
                                      </> : null}

                                      {(tax.percent & tax?.baseAmount) ? <b>
                                        {" "} +{" "}
                                      </b> : null}

                                      {tax.baseAmount ? <>
                                        {(tax.baseAmount) / 100}/-
                                      </> : null}
                                    </td>
                                    <td style={{ width: "20%", textAlign: 'center' }}>
                                      {tax.amount ?
                                        <AmountField
                                          isDisabled={true}
                                          placeholder="tax amount "
                                          label="Amount "
                                          asLabel

                                          type="number"
                                          value={
                                            (tax?.amount ?? 0)
                                          }
                                          outline
                                          className="w-100 pt-0 pb-0"
                                          onChange={(v) => { }}
                                        /> : "--"}
                                    </td>
                                    <td style={{ width: "20%" }}>
                                    </td>
                                  </>
                                </tr>
                              )
                            )
                            : null}
                          <CardFooter className="p-0">
                            <tr className="d-flex">
                              <th style={{ width: "20%" }} className="bold-font">Total</th>
                              <th style={{ width: "30%" }}></th>
                              <th style={{ width: "30%" }}></th>
                              <th style={{ width: "30%", textAlign: 'end' }}> {feeGroup.total
                                ? ((feeGroup.total) / 100)?.toFixed(2)
                                : 0.00}</th>
                              <th style={{ width: "10%", textAlign: 'end' }}>
                              </th>
                            </tr>
                            {/* <div >Total</div>
                                  <div style={{
                                    position: "relative",
                                    right: "2.7rem"
                                  }}>
                                    {feeGroup.total
                                      ? (feeGroup.total)?.toFixed(2)
                                      : 0}
                                  </div> */}
                          </CardFooter>
                        </Card>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>

              </div>
              <div className="d-flex justify-content-end me-4 mb-3">
                {(feeEditState?.visible) ? (
                  <div
                    className=" d-flex align-items-center justify-content-center btn btn-outline-dark"
                    onClick={() => {
                      setFeeEditState({
                        visible: false,
                        index: -1
                      })
                    }}
                    style={{
                      padding: "1px",
                      height: "28px",
                      width: "80px",
                      cursor: "pointer"
                    }}
                  >
                    <span className=' '>
                      {t("Cancel")}
                    </span>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          ) : (
            <>
              {index !== 0 && <hr />}
              {!item?.invoiceTrip?.trip_id ? <Col className="mb-2 d-flex justify-content-end">
                {(feeEditState?.visible) ?
                  <div
                    style={{
                      width: "2%",
                      marginLeft: "10px",
                      cursor: "pointer",
                      marginBottom: "10px"
                    }}
                  >
                    {fee?.feeGroups?.length !== 1 && (
                      <i
                        onClick={() => RemoveFeeGroup(index)}
                        className="bx color-blue font-size-20 bxs-x-circle mt-1   p-0"
                      ></i>
                    )}
                  </div> :
                  <>
                    {/* <div className="me-3 d-flex justify-content-center align-items-center btn btn-outline-dark" style={{
                      cursor: 'pointer',
                      height: "20px",
                      width: "68px"
                    }} onClick={(e) => {
                      e.stopPropagation();
                      setFeeEditState({
                        visible: true,
                        index: index
                      })
                    }}>
                      <MdEdit
                        fontSize={16}
                        style={{ cursor: "pointer" }}
                      />
                      <div style={{
                        fontSize: 12,
                        marginLeft: "5px"
                      }}>Edit</div>
                    </div> */}
                  </>

                }
              </Col> : <></>}
              <Col className="d-flex flex-column">
                <table key={index}>
                  <thead>
                    <tr style={{ lineHeight: 0.1 }}>
                      <th style={{ width: "40%" }}>Charge Type</th>
                      {/* <th style={{ width: "25%" }}>Detail</th> */}
                      <th style={{ textAlign: "right" }}>Unit Cost</th>
                      <th style={{ textAlign: 'center' }}>No. of Units </th>
                      <th style={{ textAlign: "right" }} className="d-flex justify-content-end me-2">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  {feeGroup?.charges?.map((charge, chargeIndex) => {
                    return (
                      <tbody key={chargeIndex} className="">
                        <tr style={{ lineHeight: 0.1 }}>
                          <td>
                            {rateNames[charge?.name] ?? charge?.name}
                            {charge?.label && <span style={{ fontStyle: "italic", color: "#76797c", fontSize: "10px" }}> ({charge?.label})</span>}
                          </td>
                          {/* <td style={{ position: "relative" }}>
                            {charge?.detail}
                          </td> */}
                          <td style={{ position: "relative", textAlign: 'right' }}>
                            {((charge?.unitCost ?? 0) / 100)?.toFixed(2)}
                          </td>
                          <td style={{ position: "relative", textAlign: 'center' }}>
                            {charge?.units}
                          </td>
                          <td
                            className="d-flex  justify-content-end mt-0 me-2"
                            style={{ position: "relative", textAlign: 'right' }}
                          >
                            {/* {charge?.amount
                              ? (charge?.amount / 100)?.toFixed(2)
                              : 0} */}
                            <AmountField
                              disabled={true}
                              outline
                              asLabel
                              placeholder={"Amount"}
                              // style={{ minWidth: '70px' }}
                              noLabel
                              type="number"
                              value={Number(charge?.amount ?? 0)}
                              onChange={(v) =>
                                updateCharges({})
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                  <tr style={{ lineHeight: 0.3 }}>
                    <td className="d-flex align-items-start">
                    </td>
                    {/* <td className="d-flex align-items-start">
                    </td> */}
                    <td></td>
                    <td colSpan={3}>
                      <table>
                        <table>
                          <Card >
                            <tr >
                              <td className="bold-font">Sub Total</td>
                              <td></td>
                              <td
                                className="d-flex justify-content-end  bold-font "
                                style={{ position: "relative" }}
                              >
                                {/* {ZeroIfNaN(feeGroup.subTotal / 100)} */}
                                <AmountField
                                  disabled={true}
                                  outline
                                  asLabel
                                  placeholder={"SubTotal"}
                                  // style={{ minWidth: '70px' }}
                                  noLabel
                                  type="number"
                                  value={Number(feeGroup?.subTotal ?? 0)}
                                  onChange={(v) => { }}
                                />

                              </td>
                            </tr>
                            {feeGroup?.discounts?.length > 0 ?? <tr >
                              <td className="bold-font">Discounts</td>
                              <td></td>
                              <td></td>
                              {
                                feeGroup?.discounts?.map(
                                  (discount, discountIndex) => (
                                    <tr
                                      key={discountIndex}
                                    >
                                      <td style={{ width: "20%" }} className="bold-font"></td>
                                      <td style={{ width: "30%" }}></td>
                                      <td style={{ width: "30%" }}>{discount?.detail}</td>
                                      <td style={{ width: "20%" }}></td>
                                      <td style={{ width: "20%", textAlign: 'end' }}>
                                        {parseFloat(
                                          (
                                            discount?.amount /
                                            100
                                          )?.toFixed(2)
                                        ) ?? 0}
                                      </td>
                                    </tr >
                                  )
                                )
                              }
                            </tr>}
                            {fee?.feeGroups[index]?.taxItem?.taxes.length > 0
                              ? fee?.feeGroups[index]?.taxItem?.taxes.map((tax, index) =>
                                tax?.amount ? <tr key={index}>
                                  <td style={{ width: "20%" }} className="bold-font">{tax.name}</td>
                                  <td style={{ width: "30%" }}></td>
                                  <td style={{ width: "40%" }}>
                                    {tax.percent ? <>
                                      {(tax.percent)}%
                                    </> : null}

                                    {(tax.percent & tax?.baseAmount) ? <b>
                                      {" "} +{" "}
                                    </b> : null}

                                    {tax.baseAmount ? <>
                                      {(tax.baseAmount) / 100}/-
                                    </> : null}
                                  </td>
                                  <td style={{ width: "20%" }}></td>
                                  <td style={{ width: "20%", textAlign: 'end' }}>
                                    {/* {(
                                        tax.amount / 100
                                      )?.toFixed(2)} */}
                                    <AmountField
                                      disabled={true}
                                      outline
                                      asLabel
                                      placeholder={"Amount"}
                                      // style={{ minWidth: '70px' }}
                                      noLabel
                                      type="number"
                                      value={Number(tax?.amount ?? 0)}
                                      onChange={(v) => { }}
                                    />
                                  </td>
                                </tr> : null
                              )
                              : null}
                            <CardFooter className="p-0">
                              <tr className="d-flex">
                                <th colspan={4} style={{ width: "80%" }} className="bold-font">Total</th>
                                {/* <th style={{ width: "30%" }}></th>
                                <th style={{ width: "30%" }}></th>
                                <th style={{ width: "20%" }}></th> */}
                                <th style={{ width: "20%", textAlign: 'end' }}>
                                  {feeGroup.total
                                    ? (feeGroup.total / 100)?.toFixed(2)
                                    : 0}
                                  <AmountField
                                    disabled={true}
                                    outline
                                    asLabel
                                    placeholder={"Total"}
                                    // style={{ minWidth: '70px' }}
                                    noLabel
                                    type="number"
                                    value={Number(feeGroup?.amount ?? 0)}
                                    onChange={(v) =>
                                      updateCharges({})
                                    }
                                  />
                                </th>
                              </tr>
                            </CardFooter>
                            <CardFooter className="p-0">
                              <tr className="d-flex">
                                <th colspan={4} style={{ width: "80%" }} className="bold-font">Amount Payable</th>
                                {/* <th style={{ width: "30%" }}></th>
                                <th style={{ width: "30%" }}></th>
                                <th style={{ width: "20%" }}></th> */}
                                <th style={{ width: "20%", textAlign: 'end' }}>
                                  {feeGroup.amountPayable
                                    ? (feeGroup.amountPayable / 100)?.toFixed(2)
                                    : 0}
                                </th>
                              </tr>
                            </CardFooter>
                          </Card>
                        </table>
                      </table>
                    </td>
                  </tr>
                </table>
              </Col>
            </>
          )
        )}
    </div >
  );
};
export default Fee;
