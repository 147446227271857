import React, { useEffect, useState } from 'react'
import "./styles.css"
import DraggableComponent from '../dragAndDrop/DraggableComponent';
import { getRandomColor } from './utils';
import { getFullAddress, getPassengerColor, getPassengerInitials } from '../../util/Util';
import { BsPersonStanding } from 'react-icons/bs';
import { FaHome } from 'react-icons/fa';
import PassengerCard from './PassengerCard';
import { generateUniqueId } from '../../pages/Rostering/utils/helper';

const maxVisiblePassengers = 2;


function PassengerComponent({ isSelected, trip }) {

  return (
    <div className={!isSelected ? 'd-flex flex-row align-items-center' : 'flex-1 overflow-hidden'}>
      {trip?.passengers?.slice(0, isSelected ? trip?.passengers?.length : maxVisiblePassengers)?.map((passenger, passengerIndex) => (
        <DraggableComponent isDragDisabled={!passenger?.residence} key={passenger?.customer_id} draggableId={generateUniqueId()} index={passengerIndex} className={isSelected ? 'bg-white border-top  d-flex p-2' : ''}>
          {isSelected ? (
            <PassengerCard passenger={passenger} />
          ) : (
            <div className={`circle-container d-flex`} title={passenger.name}>
              <div className="circle" style={{ backgroundColor: getPassengerColor(passenger?.salutation) }}>
                {getPassengerInitials(passenger.name)}
              </div>
            </div>
          )}
        </DraggableComponent>
      ))}
      {!isSelected && trip?.passengers?.length > maxVisiblePassengers && (
        <div className=" d-flex ms-3" >
            {`+${trip?.passengers.length - maxVisiblePassengers} more`}
        </div>
      )}
    </div>
  )
}

export default PassengerComponent