import React, { useEffect, useState } from 'react';
import DoughnutChart from '../Charts/DoughnutChart';
import LineChart from '../Charts/LineChart';
import PieChart from '../Charts/PieChart';
import { COLOR_MAP } from '../../pages/Dashboard/DashboardConstant';


import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);



const StatsChart = ({ chartType,data }) => {
 const [plotData, setPlotData] = useState(null);

 const extractValue = (value) => {
    if (typeof value === 'object' && value !== null) {
        // Assuming the count value is the first property in the nested object
        return value?.count;
    }
    return value;
};

const formatData = (data) => {
    try {
        const formattedData = {
            labels: [],
            datasets: [
                {
                    data: [],
                    radius:49,
                    backgroundColor: COLOR_MAP,
                    borderWidth: 0,
                }
            ],
            options: {
                legend: {
                    display: false,
                    position: 'bottom',
                    labels: {
                        fontColor: '#333',
                    }
                }
            }
        }
        // for (let key in data) { 
        //     if (data.hasOwnProperty(key) && data[key] >= 0) {
        //         formattedData?.labels?.push(`${key} ${data[key]}`);
        //         formattedData?.datasets[0]?.data?.push(data[key]); // currently handling only one dataset
        //     }
        // }
        // setPlotData(formattedData);
        // const extractData = (obj, prefix = '') => {
        //     for (let key in obj) {
        //         if (obj.hasOwnProperty(key)) {
        //             const value = obj[key];
        //             if (typeof value === 'object' && value !== null) {
        //                 extractData(value, `${prefix}${key} `);
        //             } else if (value >= 0) {
        //                 formattedData.labels.push(`${prefix}${key} ${value}`);
        //                 formattedData.datasets[0].data.push(value);
        //             }
        //         }
        //     }
        // };
        // extractData(data);
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                const value = extractValue(data[key]);
                if (value >= 0) {
                    formattedData.labels.push(`${key} ${value}`);
                    formattedData.datasets[0].data.push(value);
                }
            }
        }

        setPlotData(formattedData);
    } catch (error) {
        console.error(error);
    }
}


useEffect(() => {
    if (data) {
        formatData(data);
    }
}, [data]);


if (!plotData) {
    return null; // Return null if plotData is not ready yet
}

    return (
        <>
            {chartType === 'doughnut' && (
                <DoughnutChart disableLegend={true} data={plotData} />
            )}

            {chartType === 'pie' && (
                <PieChart disableLegend={true} data={plotData} />
            )}
            {chartType === 'line' && (
                <LineChart disableLegend={true} data={plotData} />
            )}
        </>
    )
}

export default StatsChart