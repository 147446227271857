import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Form, Row } from "reactstrap";
import { AddressField, CompanySelect, DateSelect, InputField, SelectField } from "../../components";
import FileUpload from "../../components/DocUpload/FileUpload";
import ClientCompany from "../../components/Dropdowns/ClientCompany/ClientCompany";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import BookingService from "../Booking/BookingService";
import { SELECTSTRUCTURE } from "./InvoiceConfigConstant";
import InvoiceConfigservice from "./InvoiceConfigService";

const EditInvoiceConfigs = ({ editId, hasErr, onChange = () => { } }) => {
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const { userContent } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const fetchData = useCallback(async () => {
        // if (!editId) {
        //     setData(null);
        //     return;
        // }
        try {
            setLoading(true);
            let invoiceConfigs = await InvoiceConfigservice.get(editId);
            setLoading(false)
            setData(invoiceConfigs);
        } catch (error) {
            setLoading(false)
            showError(error)
        }

    }, [editId, showError]);


    useEffect(() => {
        setData(null)
        setLoading(true);
        if (editId) fetchData();
        setLoading(false)
    }, [editId, InvoiceConfigservice?.version]);

    useEffect(() => {
        onChange(data)
    }, [data]);

    const hideClientCompanyArray = (array) => {
        const res = array.map(obj => obj.company_id);
        return res;
    }

    return (
        <React.Fragment>
            {loading ?
                <ThreeBounce /> :
                <Form
                    className="p-3"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                >
                    <Row className="mt-3">

                        <CompanySelect
                            corpType="self"
                            required
                            value={data?.vendorInfo?.company_id}
                            onChange={(v) => {
                                return setData({ ...data, vendorInfo: { ...data?.vendorInfo, company_id: v?.value, fullName: v.fullName, taxId: v?.companyExtn?.taxId } })
                            }}
                            corp_id={userContent?.adminOf}
                            className="col-sm-4"
                        />

                        {/* <Checkbox
                            placeholder={t("rcmRequired")}
                            value={data?.rcmRequired}
                            required={true}
                            checked={data?.rcmRequired}
                            label={t("RcmRequired")}
                            onClick={(v) => setData({ ...data, rcmRequired: !data?.rcmRequired })}
                            error={data?.rcmRequired?.length === 0 ? t("Please enter rcmRequired") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-4"
                        /> */}

                        <InputField
                            // required
                            placeholder={t("Full Name")}
                            value={data?.vendorInfo?.fullName}
                            label={t("Full Name")}
                            onChange={(v) => setData({ ...data, vendorInfo: { ...data?.vendorInfo, fullName: v } })}
                            error={data?.vendorInfo?.invNoPrefix?.length === 0 ? t("Please enter  full name") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-4"
                        />

                        <InputField
                            placeholder={t("Tax Id")}
                            value={data?.vendorInfo?.taxId}
                            label={t("Tax Id")}
                            onChange={(v) => setData({ ...data, vendorInfo: { ...data?.vendorInfo, taxId: v } })}
                            error={data?.vendorInfo?.invNoPrefix?.length === 0 ? t("Please enter  Tax Id") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-4"
                        />
                        <InputField
                            // required
                            placeholder={t("Email")}
                            value={data?.vendorInfo?.email}
                            label={t("Email")}
                            onChange={(v) => setData({ ...data, vendorInfo: { ...data?.vendorInfo, email: v } })}
                            error={data?.vendorInfo?.email?.length === 0 ? t("Please enter Email") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-4"
                        />
                        <InputField
                            placeholder={t("Phone No")}
                            value={data?.vendorInfo?.phoneNo}
                            label={t("Phone No")}
                            onChange={(v) => setData({ ...data, vendorInfo: { ...data?.vendorInfo, phoneNo: v } })}
                            error={data?.vendorInfo?.phoneNo?.length === 0 ? t("Please enter Phone No") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-4"
                        />
                        <InputField
                            placeholder={t("HSN/SAC No.")}
                            value={data?.hsn}
                            label={t("HSN/SAC No.")}
                            onChange={(v) => setData({ ...data, hsn: v })}
                            error={data?.hsn?.length === 0 ? t("Please enter HSN/SAC No.") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-4"
                        />
                    </Row>

                    <Row className="mt-3">
                        <div>
                            <InputField
                                placeholder={t("Invoice No. Prefix")}
                                value={data?.invNoPrefix}
                                required={true}
                                label={t("Invoice No. Format")}
                                onChange={(v) => setData({ ...data, invNoPrefix: v })}
                                error={data?.invNoPrefix?.length === 0 ? t("Please enter  Invoice No.") : ""}
                                showErr={hasErr}
                                type="text"
                                className="col-sm-4"
                            />
                        </div>
                        <div>
                            <m>Note 1</m>: <m>Please provide below mentioned format for Invoice No. Prefix.</m> <m>All variables are set using Invoice Date.</m>
                            <br />
                            <m>Note 2</m>: <m>Special characters allowed are </m>'<strong>-</strong>', '<strong>/</strong>', '<strong>_</strong>', '<strong>[</strong>', '<strong>]</strong>'
                            <br />
                            <m>Example</m>: <strong>TRX/[FY]/[XXXX], RRT/[YY]/[XXXX], GHN/[F-Y]/[XXXX], ABC/[MM]/[XXXX], etc.</strong>
                            <br />
                            <m>Short year</m>  :  [YY]  	- 21
                            <br />
                            <m>Full year</m>   :  [YYYY] 	- 2021
                            <br />
                            <m>Month</m>       :  [MM]	   - 01
                            <br />
                            <m>Text Month</m>  :  [MMM]	- JAN
                            <br />
                            <m>Financial year</m>   :  [FY]	   - 2021
                            <br />
                            <m>Financial year</m>   :  [F-Y]	- 21-22
                            <br />
                            <m>Sequence length 1</m> : [X]		- 8
                            <br />
                            <m>Sequence length 2</m> : [XX]	   - 08
                            <br />
                            <m>Sequence length 3</m> : [XXXX]	   - 008
                            <br />
                            <m>Sequence length 4</m> : [XXXX]	- 008
                        </div>
                    </Row>
                    {/* <InputField
                            placeholder={t("Bank Details")}
                            value={data?.bankDetails}
                            required={true}
                            label={t(" Bank Details")}
                            onChange={(v) => setData({ ...data, bankDetails: v })}
                            error={data?.bankDetails?.length === 0 ? t("Please enter  Bank Details") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6"
                        /> */}

                    {/* <InputField
                            placeholder={t(" Header Content")}
                            value={data?.headerContent}
                            required={true}
                            label={t(" Header Content")}
                            onChange={(v) => setData({ ...data, headerContent: v })}
                            error={data?.headerContent?.length === 0 ? t("Please enter  Header Content") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6"
                        /> */}

                    <Row>
                        {/* <InputField
                            placeholder={t("Terms")}
                            value={data?.terms}
                            required={true}
                            label={t("Terms")}
                            onChange={(v) => setData({ ...data, terms: v })}
                            error={data?.terms?.length === 0 ? t("Please enter  terms") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6"
                        /> */}

                        <AddressField
                            placeholder={t("Address")}
                            label={t("Address")}
                            value={data?.vendorInfo?.address}
                            onChange={(v) => setData({ ...data, vendorInfo: { ...data?.vendorInfo, address: v } })}
                            error={data?.vendorInfo?.address?.length === 0 ? t("Please enter address") : ""}
                            showErr={hasErr}
                            type="text"
                            className="mt-2"
                        />

                        <div className="mt-2">
                            <div className="mb-2">{t("Bank Details")} <font color="red">*</font></div>
                            <textarea className="inputfield" rows={3} style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", }}
                                value={data?.bankDetails}
                                onChange={(v) => setData({
                                    ...data,
                                    bankDetails: v.target.value
                                })}
                            >
                            </textarea>
                        </div>
                        <div className="mt-2">
                            <div className="mb-2">{t("Header Content")} <font color="red">*</font></div>
                            <textarea className="inputfield" rows={3} style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", }}
                                value={data?.headerContent}
                                onChange={(v) => setData({
                                    ...data,
                                    headerContent: v.target.value
                                })}
                            >
                            </textarea>
                        </div>
                        <div className="mt-2">
                            <div className="mb-2">{t("Terms")} <font color="red">*</font></div>
                            <textarea className="inputfield" rows={5} style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", }}
                                value={data?.terms}
                                onChange={(v) => setData({
                                    ...data,
                                    terms: v.target.value
                                })}
                            >
                            </textarea>
                        </div>

                    </Row>
                    {editId && <Row className="my-2">
                        <div className="d-flex justify-content-around col-sm-6">
                            <div className="d-flex align-items-center" style={{
                                fontWeight: "600"
                            }}>Invoice Logo</div>
                            <FileUpload
                                create
                                type="image"
                                className="d-flex justify-content-center col-sm-6  mb-2"
                                value={data?.logoUrl}
                                id={data?._id}
                                module="invoice-config"
                                field="logoUrl"
                                onUpload={() => {
                                    fetchData()
                                }}
                                onDelete={() => {
                                    fetchData()
                                }}
                            />
                        </div>
                        <div className="d-flex justify-content-around col-sm-6">
                            <div className="d-flex align-items-center" style={{
                                fontWeight: "600"
                            }}>Invoice Stamp</div>
                            <FileUpload
                                create
                                type="image"
                                className="d-flex justify-content-center col-sm-6  mb-2"
                                value={data?.stampUrl}
                                id={data?._id}
                                module="invoice-config"
                                field="stampUrl"
                                onUpload={() => {
                                    fetchData()
                                }}
                                onDelete={() => {
                                    fetchData()
                                }}
                            />
                        </div>
                    </Row>}
                    <hr />
                    <Row>
                        <Row className="d-flex justify-content-end">
                            <Button
                                color="light"
                                onClick={() => {
                                    setData(prevData => {
                                        const extraInfo = prevData?.tweaks?.[0]?.extraInfo || {};
                                        return {
                                            ...prevData,
                                            tweaks: [
                                                ...(prevData?.tweaks ?? []).map(tweak => ({
                                                    ...tweak,
                                                    extraInfo
                                                })),
                                                {
                                                    tweakType: "",
                                                    extraInfo
                                                }
                                            ]
                                        };
                                    });
                                }}
                                style={{ width: "fit-content" }}
                                className="h-36 me-3 d-flex align-items-center mb-2">
                                <i className="bx font-size-20 color-blue bx-plus me-2"></i>
                                {t("Tweaks")}
                            </Button>
                        </Row>
                    </Row>
                    <Row style={{ width: '98%' }}>
                        {data?.tweaks?.map((ele, idx1) => (
                            <>
                                <Card className=" m-2 p-2">
                                    <CardHeader style={{ backgroundColor: 'transparent' }}>
                                        <div className=""
                                            onClick={() => {
                                                let newData = data?.tweaks.slice();
                                                newData.splice(idx1, 1);
                                                setData({ ...data, tweaks: newData })
                                            }}
                                            style={{ cursor: "pointer", position: "relative", bottom: "5px", marginLeft: '5px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}
                                        >
                                            <i
                                                className="bx color-blue  bxs-x-circle  d-flex justify-content-end  p-0"
                                                style={{
                                                    position: "absolute",
                                                    right: 0,
                                                    fontSize: "25px",
                                                    marginTop: "15px",
                                                }}
                                            />
                                        </div>
                                    </CardHeader>

                                    <CardBody>



                                        <Row className="mt-2" >
                                            {/* <InputField
                                        placeholder={t("Tweak Type")}
                                        value={ele?.tweakType}
                                        label={t("Tweak Type")}
                                    // onChange={(v) => setData({ ...data, tweaks: [...data?.tweaks?.slice(0, idx1), { tweakType: v }, ...data?.tweaks?.slice(idx1 + 1)] })}

                                        onChange={(v) => setData({
                                            ...data,
                                            tweaks: data.tweaks.map((tweak, index) => index === idx1
                                                ? { ...tweak, tweakType: v }
                                                : tweak
                                            )
                                        })}
                                        error={data?.tweaks?.tweakType?.length === 0 ? t("Please enter  tweakType") : ""}
                                        showErr={hasErr}
                                        type="text"
                                        className="col-sm-6"
                                    /> */}

                                            <SelectField
                                                // required={true}
                                                data={SELECTSTRUCTURE.tweakType}
                                                value={ele?.tweakType}
                                                // onChange={(v) =>
                                                //     setInvoiceFormatConfig(
                                                //         invoiceFormatConfig.map((f, x) => {
                                                //             if (i === x) return { ...s, type: v.value };
                                                //             return f;
                                                //         })
                                                //     )
                                                // }
                                                onChange={(v) => setData({
                                                    ...data,
                                                    tweaks: data.tweaks.map((tweak, index) => index === idx1
                                                        ? { ...tweak, tweakType: v.value }
                                                        : tweak
                                                    )
                                                })}
                                                label={t("Tweak Type")}
                                                className="col-sm-6"
                                            />

                                            <InputField
                                                placeholder={t("Lut No")}
                                                isDisabled={idx1 > 0 ? true : false}
                                                value={ele?.extraInfo?.LUT_NO}
                                                label={t("Lut No")}
                                                // onChange={(v) => setData({ ...data, tweaks: [...data?.tweaks?.slice(0, idx1), { extraInfo: { ...tweaks?.extraInfo, LUT_NO: v } }, ...data?.tweaks?.slice(idx1 + 1)] })}
                                                onChange={(v) => setData({
                                                    ...data,
                                                    tweaks: data.tweaks.map((tweak, index) => index === idx1
                                                        ? { ...tweak, extraInfo: { ...tweak.extraInfo, LUT_NO: v } }
                                                        : tweak
                                                    )
                                                })}
                                                error={data?.tweaks?.extraInfo?.LUT_NO?.length === 0 ? t("Please enter  Lut No") : ""}
                                                showErr={hasErr}
                                                type="text"
                                                className="col-sm-6"
                                            />
                                        </Row>


                                        <DateSelect
                                            placeholder={t("Lut date ")}
                                            isDisabled={idx1 > 0 ? true : false}
                                            value={ele?.extraInfo?.LUT_DATE}
                                            label={t("Lut Date")}
                                            onChange={(v) => setData({
                                                ...data,
                                                tweaks: data.tweaks.map((tweak, index) => index === idx1
                                                    ? { ...tweak, extraInfo: { ...tweak.extraInfo, LUT_DATE: v } }
                                                    : tweak
                                                )
                                            })}
                                            type="text"
                                            className="col-sm-3"
                                        />


                                        <Row className="my-2">
                                            <div>
                                                <div style={{ marginBottom: "0.2rem", }}>{t("Header Content")} </div>
                                                <textarea className="inputfield" rows={5} style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", }}
                                                    // value={data?.detail ?? ""}
                                                    value={ele?.extraInfo?.HEADER_CONTENT}
                                                    // onChange={(e) => {
                                                    //     setData({ ...data, detail: e.target.value });
                                                    // }}
                                                    onChange={(v) => setData({
                                                        ...data,
                                                        tweaks: data.tweaks.map((tweak, index) => index === idx1
                                                            ? { ...tweak, extraInfo: { ...tweak.extraInfo, HEADER_CONTENT: v.target.value } }
                                                            : tweak
                                                        )
                                                    })}
                                                >
                                                </textarea>
                                            </div>
                                        </Row>

                                        {/* <Row className="my-2">
                                            <div>
                                                <div style={{ marginBottom: "0.2rem", }}>{t("Footer Content")}</div>
                                                <textarea className="inputfield" rows={5} style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", }}
                                                    value={ele?.extraInfo?.FOOTER_CONTENT}
                                                    onChange={(v) => setData({
                                                        ...data,
                                                        tweaks: data.tweaks.map((tweak, index) => index === idx1
                                                            ? { ...tweak, extraInfo: { ...tweak.extraInfo, FOOTER_CONTENT: v.target.value } }
                                                            : tweak
                                                        )
                                                    })}
                                                >
                                                </textarea>
                                            </div>
                                        </Row> */}

                                        {/* <InputField
                                    placeholder={t("HEADER CONTENT")}
                                    value={ele?.extraInfo?.HEADER_CONTENT}
                                    label={t("HEADER CONTENT")}
                                    // onChange={(v) => setData({ ...data, tweaks: [...data?.tweaks?.slice(0, idx1), { extraInfo: { ...tweaks?.extraInfo, HEADER_CONTENT: v } }, ...data?.tweaks?.slice(idx1 + 1)] })}
                                    onChange={(v) => setData({
                                        ...data,
                                        tweaks: data.tweaks.map((tweak, index) => index === idx1
                                            ? { ...tweak, extraInfo: { ...tweak.extraInfo, HEADER_CONTENT: v } }
                                            : tweak
                                        )
                                    })}
                                    error={data?.tweaks?.extraInfo?.HEADER_CONTENT?.length === 0 ? t("Please enter  HEADER CONTENT") : ""}
                                    showErr={hasErr}
                                    type="text"
                                    className="col-sm-4"
                                /> */}

                                        {/* <InputField
                                    placeholder={t("FOOTER CONTENT")}
                                    value={ele?.extraInfo?.FOOTER_CONTENT}
                                    label={t("FOOTER CONTENT")}
                                    // onChange={(v) => setData({ ...data, tweaks: [...data?.tweaks?.slice(0, idx1), { extraInfo: { ...tweaks?.extraInfo, FOOTER_CONTENT: v } }, ...data?.tweaks?.slice(idx1 + 1)] })}
                                    onChange={(v) => setData({
                                        ...data,
                                        tweaks: data.tweaks.map((tweak, index) => index === idx1
                                            ? { ...tweak, extraInfo: { ...tweak.extraInfo, FOOTER_CONTENT: v } }
                                            : tweak
                                        )
                                    })}
                                    error={data?.tweaks?.extraInfo?.FOOTER_CONTENT?.length === 0 ? t("Please enter  FOOTER CONTENT") : ""}
                                    showErr={hasErr}
                                    type="text"
                                    className="col-sm-4"
                                /> */}

                                        <Row className="d-flex  align-items-top">
                                            <Button
                                                color="light"
                                                onClick={() => {
                                                    setData({
                                                        ...data,
                                                        tweaks: data.tweaks.map((tweak, index) =>
                                                            index === idx1
                                                                ? {
                                                                    ...tweak,
                                                                    clientEntities: [
                                                                        ...(tweak.clientEntities ?? []),
                                                                        {
                                                                            org_id: "",
                                                                            company_id: "",
                                                                        },
                                                                    ],
                                                                }
                                                                : tweak
                                                        ),
                                                    });
                                                }}
                                                style={{ width: "fit-content", left: "10px" }}
                                                className="h-36 my-2 me-3 d-flex align-items-center mb-2 mr-3 col-4"
                                            >
                                                <i className="bx font-size-20 color-blue bx-plus me-2"></i>
                                                {t("Client Entities")}
                                            </Button>


                                        </Row>

                                        <hr />
                                        <table className="table-responsive">
                                            <tr style={{ backgroundColor: '#FAF9FF' }} className="p-0">
                                                <th>{t("Client")}</th>
                                                <th></th>
                                            </tr>

                                            {ele?.clientEntities?.map((clientEntitie, idx2) => (
                                                <>
                                                    < tr >
                                                        <td className="p-0 client-table" >
                                                            <ClientCompany
                                                                isDisabled={BookingService.readOnly("client.company_id")}
                                                                outline
                                                                filterOption={hideClientCompanyArray(ele?.clientEntities)}
                                                                value={clientEntitie?.company_id}
                                                                onChange={(v) => {

                                                                    const isClientExist = ele?.clientEntities.find(ele => ele?.company_id === v?._id);
                                                                    if (isClientExist) {
                                                                        showError('This Client is already selected.');
                                                                        return;
                                                                    }
                                                                    setData(prevData => ({
                                                                        ...prevData,
                                                                        tweaks: prevData.tweaks.map((tweak, index) =>
                                                                            index === idx1
                                                                                ? {
                                                                                    ...tweak,
                                                                                    clientEntities: tweak.clientEntities.map((entity, entityIdx) =>
                                                                                        entityIdx === idx2
                                                                                            ? { ...entity, org_id: v?.client_id, company_id: v?._id }
                                                                                            : entity
                                                                                    ),
                                                                                }
                                                                                : tweak
                                                                        ),
                                                                    }));

                                                                }}
                                                                className="col-9"
                                                            />

                                                            {/* <ClientSelect
                                                                outline
                                                                value={clientEntitie?.org_id}
                                                                onChange={(v) =>
                                                                    setData({
                                                                        ...data,
                                                                        tweaks: data.tweaks.map((tweak, index) =>
                                                                            index === idx1
                                                                                ? {
                                                                                    ...tweak,
                                                                                    clientEntities: tweak.clientEntities.map((entity, entityIdx) =>
                                                                                        entityIdx === idx2
                                                                                            ? { ...entity, org_id: v?.value }
                                                                                            : entity
                                                                                    ),
                                                                                }
                                                                                : tweak
                                                                        ),
                                                                    })
                                                                }
                                                                // inline={true}
                                                                className="col-sm-5 client-table-row"
                                                            /> */}
                                                        </td>
                                                        {/* <td className="p-0 client-table" >
                                                            <CompanySelect
                                                                outline
                                                                value={clientEntitie?.company_id}
                                                                onChange={(v) =>
                                                                    setData({
                                                                        ...data,
                                                                        tweaks: data.tweaks.map((tweak, index) =>
                                                                            index === idx1
                                                                                ? {
                                                                                    ...tweak,
                                                                                    clientEntities: tweak.clientEntities.map((entity, entityIdx) =>
                                                                                        entityIdx === idx2
                                                                                            ? { ...entity, company_id: v?.value }
                                                                                            : entity
                                                                                    ),
                                                                                }
                                                                                : tweak
                                                                        ),
                                                                    })
                                                                }
                                                                className="col-sm-5 client-table-row "
                                                            />
                                                        </td> */}
                                                        <td>
                                                            <Row className="col-sm-1"
                                                                onClick={() => {

                                                                    let newData = { ...data };

                                                                    if (newData.tweaks && newData.tweaks[idx1] && newData.tweaks[idx1].clientEntities) {
                                                                        newData.tweaks[idx1].clientEntities.splice(idx2, 1);
                                                                    }
                                                                    setData(newData);
                                                                }}
                                                                style={{ cursor: "pointer", position: "relative", bottom: "5px", marginLeft: '5px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}
                                                            >
                                                                <i
                                                                    className="bx color-blue bxs-x-circle  d-flex justify-content-end"
                                                                    style={{
                                                                        position: "absolute",
                                                                        right: 0,
                                                                        fontSize: "25px",
                                                                        marginTop: "15px",
                                                                    }}
                                                                />
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td colSpan="10"> <hr className="p-0 m-0" /></td>
                                                    </tr>

                                                    {/* <Row
                                            onClick={() => {
                                                let newData = data?.tweaks?.clientEntities.slice();
                                                newData.splice(idx2, 1);
                                                setData({ ...data, tweaks: newData })
                                            }}
                                            style={{ cursor: "pointer", position: "relative", marginLeft: '5px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}
                                        >
                                            <i
                                                className="bx color-blue font-size-20 bxs-x-circle  d-flex justify-content-end  p-0"
                                                style={{
                                                    position: "absolute",
                                                    right: 0,
                                                    fontSize: "20px",
                                                    marginTop: "15px",
                                                }}
                                            />
                                        </Row> */}
                                                </>
                                            ))}

                                        </table>
                                    </CardBody>
                                </Card>

                            </>
                        ))}
                    </Row>
                    <div className="my-5 py-5">.</div>
                </Form>
            }

        </React.Fragment>
    );
};

export default observer(EditInvoiceConfigs);
