import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET, doPOST } from "../../../util/HttpUtil";

class SupervisorSelect {
    supervisors = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filterData) {
        if (this.supervisors.length > 0)
            return;
        try {
            // const response = await doGET(ENDPOINTS.getAllSupervisors);
            // if (response.status === 200) {
            //     ;
            //     this.supervisors = response.data.map(v => { return { label: v.name, value: v._id } });
            // } else {
            //     this.error = response.data;
            // }
        } catch (err) {
            this.error = err;
        }
    };
}

const SupervisorSelectService = new SupervisorSelect();
export default SupervisorSelectService;
