export const ENDPOINTS = {
  grid: (page, sizePerPage, filterData) => {
    let s = `/api/payment/trans/corp/subscription?page=${page}&size=${sizePerPage}`;
    if (filterData?.fromDate) s += `&fromDate=${filterData.fromDate}`;
    if (filterData?.toDate) s += `&toDate=${filterData.toDate}`;
    if (filterData?.financialYear) s += `&financialYear=${filterData.financialYear.value}`;
    if (filterData?.status) s += `&status=${filterData.status.value}`;
    return s;
  }
};

export const STRUCTURE = [
  {
    label: "From Date",
    description: "description...",
    filterLabel: "From Date",
    filterName: "fromDate",
    type: "date",
  },
  {
    label: "To Date",
    description: "description...",
    filterLabel: "To Date",
    filterName: "toDate",
    type: "date",
  },
  {
    label: "Financial Year",
    description: "description...",
    filterLabel: "Financial Year",
    filterName: "financialYear",
    type: "select",
    data: [
      { label: "2017-18", value: "20170401" },
      { label: "2018-19", value: "20180401" },
      { label: "2019-20", value: "20190401" },
      { label: "2020-21", value: "20200401" },
      { label: "2021-22", value: "20210401" },
      { label: "2022-23", value: "20220401" },
      { label: "2023-24", value: "20230401" },
      { label: "2024-25", value: "20240401" },
      { label: "2025-26", value: "20250401" },
    ]
  },
  {
    label: "Status",
    description: "description...",
    filterLabel: "Status",
    filterName: "status",
    type: "select",
    data: [
      { label: "Created", value: 0 },
      { label: "Initated", value: 1 },
      { label: "Success", value: 2 },
      { label: "Failed", value: 3 },
      { label: "Refund Initated", value: 4 },
      { label: "Refund completed", value: 5 },
      { label: "Paid from wallet", value: 6 },
      { label: "Partial paid by wallet rest by cash", value: 7 },
      { label: "Others", value: 8 },
      { label: "Paid by wallet and cash", value: 9 },
      { label: "Refund to wallet", value: 10 },
      { label: "Paid from gift voucher", value: 11 },
      { label: "Partially paid from gift voucher", value: 12 },
      { label: "Paid by gift voucher and cash", value: 13 },
    ]
  },
];

