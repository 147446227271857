import React, { useEffect, useState } from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import CountUp from 'react-countup';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import DashboardService from '../../../pages/Dashboard/DashboardService';



export const CustomerRegistrationCard = ({ }) => {
  const [customerCount, setCustomerCount] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchCustomers = async (fromDate, tillDate) => {
    if (loading) return;
    setLoading(true);
    try {
      let body = {
        btwFilters: {
          joinDate: [fromDate, tillDate]
        },
        groupBy: {
          joinDate: "joinDate"
        }
      }
      const response = await DashboardService.fetchRegisteredCustomers(body);
      const customersRegistered = response
        .reduce((acc, item) => acc + item.count, 0);
      setCustomerCount(customersRegistered)
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    // const till = parseInt(moment().startOf('day').subtract(15, 'minutes').format('YYYYMMDD'));
    const till = parseInt(moment().format('YYYYMMDD'));
    const from = parseInt(moment().subtract(7, 'days').format('YYYYMMDD'));
    fetchCustomers(from,till);
  }, [])

  return (
    <React.Fragment>
      <Col md={6} xl={3}>
        <Card style={{ height: "116px" }}>
          <CardBody>
            <div className='d-flex flex-column h-100'>
              <h2 className="mb-1 mt-1 flex-1 d-flex justify-content-center">
                <span>
                  <CountUp
                    end={customerCount}
                    separator=","
                    prefix=""
                    suffix=""
                    decimals={0}
                  />
                </span>
              </h2>
              <div>
                <p className="text-center text-muted mt-3 mb-0">Last 7 Days</p>
                <h3 className="text-center text-muted mb-0">Customers Registered</h3>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
};

