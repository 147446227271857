import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import EditGarages from "./EditGarage";
import { STRUCTURE } from "./GarageConstant";
import { GridColumns } from "./GarageGridColumns";
import GarageService from "./GarageService";
import CustomOperations from "../../components/CustomField/CustomOperations";

const Garages = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/garage/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)
  const [showCustomizationOptions, setShowCustomizationOptions] = useState(false)
  const [onAlert, setOnAlert] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await GarageService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])


  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.address?.name) {
      setHasErr(true)
      showError(t("Please enter all required fields"));
      return;
    }
    setLoading(true);
    try {
      if (editID) {
        await GarageService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Garage updated successfully."));
      } else {
        const garage = await GarageService.save(detailData);
        setLoading(false);
        showMessage(t("Garage saved successfully."));
        if (!insidePane) navigate(`/garage/edit/${garage}`);
      }
      fetchData(filterURL);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await GarageService.delete(id);
        setLoading(false);
        showMessage("Garage Deleted SuccessFully", "Deleted");
        navigate("/garage");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await GarageService.bulkDelete(selectedIDs);
        showMessage(t("Deleted"), "Deleted Successfully");
        setShowDetailPage(false);

      } catch (e) {
        showError(e);
      }
    }
  };


  const renderLastCol = useCallback((garage) => {
    return (
      <>
        {userContent?.rights?.includes(1502) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/garage/edit/${garage?._id}`);
              setEditID(garage?._id);
              setShowDetailPage(true);

            }}
          />
        }
        {userContent?.rights?.includes(1503) ?

          <DeleteLink
            onClick={(event) => onDelete(event, garage?._id)}
          />
          : null}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(garage?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])

  const markCompletedFunc = async () => {
    try {
      await SetupProgressService?.edit("garage");
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch()
    }
  }
    , [SetupProgressService?.records])

  return (
    <>
      <Layout
        hideAdd={!userContent?.rights?.includes(1501)}
        medium
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/garage");
          setEditID(null);
        }}
         
        title={t("Garages")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/garage/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={GarageService.page}
        rows={GarageService.rows}
        total={GarageService.total}
        fetch={GarageService.fetch}
      >
        <Layout.OnAlert>
          {!SetupProgressService?.records?.setUpStatus?.['garage'] ? <Alert color="danger" role="alert">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                Please list all your Garages/Dispatch Centers here.
              </div>
              <div>
                <Button onClick={markCompletedFunc}>Mark Complete</Button>
              </div>
            </div>          </Alert> : <></>}
        </Layout.OnAlert>


        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Download")} </DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem onClick={() => { setShowCustomizationOptions(true) }}>{t("Customise")} </DropdownItem>
              </>

            </DropdownMenu>

          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={GarageService.records}
            total={GarageService.total}
            uiPreference="garage.grid"
            headers={GridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            selectedIDs={selectedIDs}
            page={GarageService.page}
            rowsPerPage={GarageService.rowsPerPage}
            onPaginationChange={GarageService.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>


        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit Garage") : t("Add Garage")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditGarages
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {detailData?._id ? (
            <AuditLogDialog module="Garage" id={detailData?._id} />
          ) : null

          }
          {editID && userContent?.rights?.includes(1503) && (detailData?._id) ? <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} /> : null}
          {userContent?.rights?.includes(1502) ? <SaveButton onClick={onSave} loading={loading} /> : null}
        </Layout.DetailPageFooter>
      </Layout>
      {showCustomizationOptions ? <CustomOperations
        isModal
        module="garage"
        isOpen={showCustomizationOptions}
        toggleModal={() => { setShowCustomizationOptions(false) }}
      /> : null
      }
    </>
  );
};
export default observer(Garages);
