import { ClientSelect } from "..";
import { statusMapping } from "../../pages/Booking/BookingGridColumns";
import CustomTooltip from "../../pages/Booking/components/CustomTooltip";
import CompanyLabel from "../Dropdowns/Entity/CompanyLabel";
import TagPicker from "../TagSelector/TagPicker";


export const tripTypeMapping = {
    1: 'Local',
    2: 'Rental',
    3: 'Shared',
    4: 'Transfer',
    5: 'Self Drive',
    6: 'Route',
    7: 'Airport Pick',
    8: 'Airport Drop',
    9: 'Tour Package',
    10: 'Outstation OneWay',
    11: 'Outstation Returning',
    12: 'Lease'
}


export const GridColumns = ({}) => {
    return ({
        version: 1,
        columns: [
            {
                title: "Trip No",
                accessor: "tripNo",
                renderer: (dataObj) => {
                    const statusInfo = statusMapping[dataObj?.status || 0] || { text: "Unknown", color: "#000000" };
                    return <div className="d-flex align-items-center">
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                backgroundColor: statusInfo.color,
                                marginRight: '5px',

                            }}
                        ></div>
                        {dataObj.tripNo}
                    </div>
                },
                show: true,
            },
            {
                title: "Invoice No",
                accessor: "clientInvoiceNo",
                renderer: (dataObj) => {
                    return <div className="d-flex align-items-center">
                        {dataObj.clientInvoiceNo ? dataObj.clientInvoiceNo : "--"}
                    </div>
                },
                show: true,
            },
            {
                title: "Trip Type",
                accessor: "tripType",
                renderer: (dataObj) => {
                    return <div>{tripTypeMapping[dataObj?.tripType]}</div>;
                },
                show: true,
            },
            {
                title: "Tags",
                accessor: "tags",
                renderer: (dataObj, rowIndex, pageSize) => {
                    return (!!dataObj?.tags ? <CustomTooltip>
                        <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>

                            <TagPicker
                                kind={"trip"}
                                data={dataObj?.tags?.filter(tag => tag && tag?.tag_id)}
                            /></CustomTooltip.Body>
                        <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                            <TagPicker
                                kind={"trip"}
                                data={dataObj?.tags?.filter(tag => tag && tag?.tag_id)}
                            />
                        </CustomTooltip.ToolTip>
                    </CustomTooltip>
                        : <>{"--"}</>
                    );
                },
                show: true,
            },
            {
                title: "DutySlip #",
                accessor: "dutySlipNo",
                renderer: (dataObj) => (
                    <div>
                        {dataObj.dutySlipNo}
                    </div>
                ),
                show: true,
            },
            {
                title: "Client",
                accessor: "client",
                renderer: (dataObj, rowIndex, pageSize) => {
                    return <div>{dataObj?.client?.client_id ? <CustomTooltip>
                        <CustomTooltip.Body style={{ maxWidth: 160, overflowX: 'hidden' }}>
                            <ClientSelect
                                asLabel
                                value={dataObj?.client?.client_id}
                            /></CustomTooltip.Body>
                        <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                            <ClientSelect
                                asLabel
                                value={dataObj?.client?.client_id}
                            /></CustomTooltip.ToolTip>
                    </CustomTooltip>
                        : <span style={{ fontStyle: "italic" }}>Walk In</span>}</div>;

                },
                show: true,
            },
            {
                title: "Client Company",
                accessor: "clientCompany",
                renderer: (dataObj, rowIndex, pageSize) => {
                    return <div>{dataObj?.client?.company_id ?

                        <CompanyLabel
                            corpType="client"
                            value={dataObj?.client?.company_id}
                            corp_id={dataObj?.client?.client_id}
                        />

                        : <span style={{ fontStyle: "italic" }}>--</span>}</div>;

                },

                show: true,
            },
        ],
    })
};
