import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { InputField, SelectField, SaveButton } from "../../../components";

export default function Details({ data, setData, submitHandler }) {
  const [showErr, setshowErr] = useState(false);
  const ManagedBy = [
    {
      label: "SELF",
      value: "5b729bb54ccd684db13a81cf",
    },
    {
      label: "Test Vendor",
      value: "5546538fe4b07d94be9d3fa1",
    },
  ];
  const CorporateType = [
    {
      label: "Consumer",
      value: "1",
    },
    {
      label: "Aggregator",
      value: "2",
    },
    {
      label: "Supplier",
      value: "3",
    },
  ];

  const SubscriptionType = [
    {
      label: "Premium",
      value: "1",
    },
    {
      label: "Fremium",
      value: "2",
    },
  ];

  return (
    <div className="active ">
      <Row>
        <Col className="py-2">
          <InputField
            value={data?.unique_id}
            required={true}
            label={"Unique Id"}
            onChange={(v) => {
              setData({ ...data, unique_id: v });
            }}
            showErr={showErr}

            type="text"
            className="col-sm-12"
          />
        </Col>
        <Col className="py-2">
          <InputField
            isDisabled={true}
            value={data?.name}
            required={true}
            label={"Name"}
            onChange={(v) => {
              setData({ ...data, name: v });
            }}
            showErr={showErr}

            type="text"
            className="col-sm-12"
          />
        </Col>
      </Row>
      <Row>
        <Col className="py-2">
          <InputField
            value={
              data?.address?.addressLine +
              " " +
              data?.address?.city +
              " " +
              data?.address?.state +
              " " +
              data?.address?.country
            }
            required={true}
            label={"Address"}
            onChange={(v) => {
              setData({
                ...data,
                address: v,
              });
            }}
            showErr={showErr}

            type="text"
            className="col-sm-12"
          />
        </Col>
      </Row>
      <Row>
        <Col className="py-2">
          <InputField
            isDisabled={true}
            value={data?.email}
            required={true}
            label={"Email"}
            onChange={(v) => {
              setData({ ...data, email: v });
            }}
            showErr={showErr}

            type="email"
            className="col-sm-12"
          />
        </Col>
        <Col className="py-2">
          <SelectField
            className="col-sm-12"
            data={ManagedBy}
            value={data?.managedBy}
            onChange={(v) =>
              setData({
                ...data,
                managedBy: v.value,
              })
            }
            label={"Managed By"}
          />
        </Col>
      </Row>

      <Row>
        <Col className="py-2">
          <SelectField
            className="col-sm-12"
            data={CorporateType}
            value={data?.corpType}
            onChange={(v) =>
              setData({
                ...data,
                corpType: v.value,
              })
            }
            label={"Select Consumer Type"}
          />
        </Col>
        <Col className="py-2">
          <SelectField
            className="col-sm-12"
            data={SubscriptionType}
            value={data?.subscription}
            onChange={(v) =>
              setData({
                ...data,
                subscription: v.value,
              })
            }
            label={"Select Subscription Type"}
          />
        </Col>
      </Row>
      <Row>
        <Col className="py-2">
          <InputField
            value={data?.domain}
            required={true}
            label={"Domain"}
            onChange={(v) => {
              setData({ ...data, domain: v });
            }}
            showErr={showErr}

            type="text"
            className="col-sm-12"
          />
        </Col>
        <Col className="py-2">
          <InputField
            value={data?.b2cDomain}
            required={true}
            label={"Aura B2C Domain"}
            onChange={(v) => {
              setData({ ...data, b2cDomain: v });
            }}
            showErr={showErr}

            type="text"
            className="col-sm-12"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className="d-flex justify-content-end mt-4"
            style={{ marginTop: "-2.5rem" }}
          >
            <SaveButton onClick={submitHandler} />
          </div>
        </Col>
      </Row>
    </div>
  );
}
