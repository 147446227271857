
import React, { useContext, useEffect, useState } from 'react'
import { I18nContext } from "../../../store/context/i18nContext";
import { InputField } from '../../../components'
import ReactTable from "react-table-6";
import "react-table-6/react-table.css"
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'
import { v4 as uuid } from 'uuid';


const TransporterStep5 = ({ data, setData }) => {
    const { t } = useContext(I18nContext);
    const [simple, setSimple] = useState("")
    const ReactTableFixedColumns = withFixedColumns(ReactTable);

    const [ratesData, setRatesData] = useState(data?.inventory ? data?.inventory?.map((e) => {
        return {
            id: uuid(),
            vehicleType: e?.vehicleType,
            vehicleCategory: e?.vehicleCategory,
            pick_drop: "Pick & Drop",
            _4_40: 0,
            basefare: 0,
            _6_60: 0,
            _8_80: 0,
            _10_100: 0,
            _12_120: 0,
            nightHalt: 0,
            extraKm: 0,
            extraMin: 0

        }
    }) : [])

    useEffect(() => {
        if (JSON.stringify(data?.rates) !== JSON.stringify(ratesData))
            setData({ ...data, rates: ratesData });
    }, [ratesData]);

    console.log(ratesData, "shds")

    const handleInputChange = (row, value, field) => {

        setRatesData(prev => prev?.map((d) => {
            console.log({ value, d, row });
            if (d?.id === row?._original?.id) {
                return { ...d, [field]: value ? parseInt(value) : value };
            }
            return d;
        }));
    };

    console.log(ratesData, "shds")
    return (
        <>

            <div className=' px-4 mb-3'>
                <ReactTableFixedColumns
                    showPaginationBottom={false}
                    data={ratesData}
                    columns={[
                        {
                            Header: "Vehicle Type",
                            fixed: "left",
                            columns: [
                                {
                                    accessor: "vehicleType",
                                    width: 150,
                                },

                            ]
                        },
                        {
                            Header: "4 hrs/ 40 km",
                            columns: [
                                {

                                    accessor: "_4_40",
                                    id: '_4_40',
                                    Cell: ({ row }) => (
                                        <>
                                            < InputField
                                                value={row?._4_40}// Set the value from row object
                                                onChange={(e) => {
                                                    // console.log(e, event, "=========");
                                                    handleInputChange(row, e, '_4_40')

                                                }} // Pass row and value to the change handler
                                                type="number"
                                            />
                                        </>


                                    ),

                                },
                            ]
                        },
                        {
                            Header: "6 hrs/ 60 km",
                            columns: [
                                {

                                    accessor: "_6_60",
                                    id: '_6_60',
                                    Cell: ({ row }) => (
                                        <>
                                            < InputField
                                                value={row?._6_60} // Set the value from row object
                                                onChange={(e) => {
                                                    handleInputChange(row, e, '_6_60')
                                                }} // Pass row and value to the change handler
                                                type="number"
                                            />
                                        </>


                                    ),

                                },
                            ]
                        },
                        {
                            Header: "8 hrs/ 80 km",
                            columns: [
                                {
                                    width: 120,
                                    accessor: "_8_80",
                                    Cell: ({ row }) => (
                                        <>
                                            < InputField
                                                value={row?._8_80} // Set the value from row object
                                                onChange={(e) => {
                                                    handleInputChange(row, e, '_8_80')
                                                }} // Pass row and value to the change handler
                                                type="number"
                                            />
                                        </>


                                    ),

                                },
                            ]
                        },
                        {
                            Header: "10 hrs/ 100 km",
                            columns: [
                                {
                                    width: 120,

                                    accessor: "_10_100",
                                    Cell: ({ row }) => (
                                        <>
                                            < InputField
                                                value={row?._10_100} // Set the value from row object
                                                onChange={(e) => {
                                                    handleInputChange(row, e, '_10_100')
                                                }} // Pass row and value to the change handler
                                                type="number"
                                            />
                                        </>


                                    ),

                                },
                            ]
                        },
                        {
                            Header: "12 hrs/ 120 km",
                            columns: [
                                {
                                    width: 120,
                                    accessor: "_12_120",
                                    Cell: ({ row }) => (
                                        <>
                                            < InputField
                                                value={row?._12_120} // Set the value from row object
                                                onChange={(e) => {
                                                    handleInputChange(row, e, '_12_120')
                                                }} // Pass row and value to the change handler
                                                type="number"
                                            />
                                        </>


                                    ),

                                },
                            ]
                        },
                        {
                            Header: "Outstation",
                            columns: [
                                {
                                    Header: "Base Fare",
                                    width: 120,
                                    accessor: "basefare",

                                    Cell: ({ row }) => (
                                        <>
                                            < InputField
                                                value={row?.basefare} // Set the value from row object
                                                onChange={(e) => {
                                                    handleInputChange(row, e, 'basefare')
                                                }} // Pass row and value to the change handler
                                                type="number"
                                            />
                                        </>


                                    ),

                                },
                                {
                                    Header: "Per Km Fare",
                                    accessor: "exrtraKm",
                                    width: 120,

                                    Cell: ({ row }) => (
                                        <>
                                            < InputField
                                                value={row?.exrtraKm} // Set the value from row object
                                                onChange={(e) => {
                                                    handleInputChange(row, e, 'exrtraKm')
                                                }} // Pass row and value to the change handler
                                                type="number"
                                            />
                                        </>


                                    ),

                                },
                                {
                                    Header: "Per Min Fare",
                                    width: 120,
                                    accessor: "extraMin",

                                    Cell: ({ row }) => (
                                        <>
                                            < InputField
                                                value={row?.extraMin} // Set the value from row object
                                                onChange={(e) => {
                                                    handleInputChange(row, e, 'extraMin')
                                                }} // Pass row and value to the change handler
                                                type="number"
                                            />
                                        </>
                                    ),

                                },
                            ]
                        },


                        {
                            Header: "Night Halt Changes",
                            columns: [

                                {

                                    accessor: "nightHalt",
                                    width: 120,
                                    Cell: ({ row }) => (
                                        <>
                                            < InputField
                                                value={row?.nightHalt} // Set the value from row object
                                                onChange={(e) => {
                                                    handleInputChange(row, e, 'nightHalt')
                                                }} // Pass row and value to the change handler
                                                type="number"
                                            />
                                        </>


                                    ),
                                }
                            ]
                        },


                    ]}
                />
            </div>

        </>
    )
}

export default TransporterStep5