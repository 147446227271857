export const ENDPOINTS = {
  grid: (page, sizePerPage, id) => {
    let s = `/api/corp/subscription/5546538fe4b07d94be9d3fa1/invoice/grid?rows=${sizePerPage}&page=1&sortAsc=false&sortBy=invoiceDate&client_id=${id}`;
    return s + `&page=${page}`;
  },
  update: (id) => `/api/invoice/${id}/status/1/update`,
  print: `/api/invoice/bulk/invoices/print2?`,
  email: (id) => `/api/corp/subscription/invoice/${id}/email?pymtLink=true`,
  download: (id) => `/api/corp/premium/subscription/invoice/${id}/apilogs`,
  updateInvoice: (id, st) => `/api/invoice/${id}/status/${st}/update`,
};