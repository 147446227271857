import { GrStatusGood } from "react-icons/gr";

export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/flight/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/flight/${id}/detail`,
  save: `/api/flight/create`,
  delete: (id) => `/api/flight/${id}/delete`,
  bulkDelete: (id) => `/api/flight/delete`,
  update: `/api/flight/update`,
};

export const STRUCTURE = [
  {
    label: "Flight No",
    filterLabel: "Flight No",
    filterName: "flightNo",
    type: "text",
    description: "Filter by Flight No",
    icon: " uil-user-circle font-size-16 ",
  },
  {
    label: "Airline",
    filterLabel: "Airline",
    filterName: "airline",
    description: "Filter by Airline",
    type: "text",
    icon: " uil-plane font-size-16 ",
  },
  {
    label: "Date",
    filterLabel: "Date",
    filterName: "date",
    type: "date",
    description: "Filter by Date",
    icon: "uil-calender font-size-16 "
  },
  {
    label: "Time",
    filterLabel: "Time",
    filterName: "time",
    type: "timeSelect",
    description: "Filter by Time",
    icon: "uil-calender font-size-16 "
  },
  {
    label: "Booking No",
    filterLabel: "Booking No",
    filterName: "bookingNo",
    type: "text",
    description: "Filter by Booking No",
    icon: "uil-plane font-size-16 "
  },
  {
    label: "From City",
    filterLabel: "From City",
    filterName: "fromCity",
    type: "citySelect",
    description: "Filter by City",
    icon: "uil-home font-size-16 "
  },
  {
    label: "From Country",
    filterLabel: "From Country",
    filterName: "fromCountry",
    type: "countrySelect",
    description: "Filter by Country",
    icon: "uil-home font-size-16 "
  },
  {
    label: "To City",
    filterLabel: "To City",
    filterName: "toCity",
    type: "citySelect",
    description: "Filter by City",
    icon: "uil-home font-size-16 "
  },
  {
    label: "From Country",
    filterLabel: "To Country",
    filterName: "toCountry",
    type: "countrySelect",
    description: "Filter by Country",
    icon: "uil-home font-size-16 "
  },
  {
    label: "Booked At",
    filterLabel: "Booked At",
    filterName: "bookedAt",
    type: "dateTimeSelect",
    description: "Filter by Booked At",
    icon: "uil-home font-size-16 "
  },
  {
    label: " Status",
    filterLabel: " Status",
    filterName: "status",
    description: "Filter by  status",
    type: "select",
    iconComponent: <GrStatusGood className="font-size-14" />,
    data: [
      { label: "Booked", value: 1 },
      { label: "Cancelled", value: 2 },
      { label: "Delayed", value: 3 },
    ],
  },
];
export const SELECTSTRUCTURE = {
  airlines: [
    { label: "Air India", value: "Air India" },
    { label: "Indigo", value: "Indigo" },
    { label: "Qatar Airways", value: "Qatar Airways" },
  ],
  status: [
    { label: "Booked", value: 1 },
    { label: "Cancelled", value: 2 },
    { label: "Delayed", value: 3 },
  ],
}
