import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditTickets from "./EditTickets";
import { STRUCTURE } from "./TicketsConstant";
import { GridColumns } from "./TicketsGridColumns";
import TicketService from "./TicketsService";
import { UserContext } from "../../store/context/UserContext";
import TagPicker, { getRandomColor } from "../../components/TagSelector/TagPicker";

const Tickets = ({ value, insidePane, multiMode, onSelect = () => { } }) => {

    let { ticket_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { userContent } = useContext(UserContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(ticket_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/tickets/create" || ticket_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)


    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await TicketService.fetch(filterUrl);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }, [])

    useEffect(() => {
        setEditID(ticket_id)
    }, [ticket_id])

    const onSave = async (e) => {
        e.preventDefault();

        if ((detailData.header == ''  || detailData.priority == undefined)) {
            setHasErr(true)
            showError(t("Please enter all fields"));
            return;
        }
        setLoading(true);
        try {
            if (editID) {
                await TicketService.edit(detailData, editID);
                showMessage(t("Record updated successfully."));
            } else {
                const ticket = await TicketService.save({
                    ...detailData,
                    status:0,
                });
                if (ticket) setEditID(ticket)
                showMessage(t("Record saved successfully."));
                if (!insidePane) navigate(`/tickets/edit/${ticket}`);
            }
            fetchData(filterURL);
        } catch (e) {
            showError(e);
        }
        setHasErr(false)
        setLoading(false);
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            try {
                await TicketService.delete(id);
                showMessage("Deleted", "Deleted");
            } catch (e) {
                showError(e);
            }
        }
    };

    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            try {
                await TicketService.bulkDelete(selectedIDs);
                showMessage("Deleted", "Deleted Successfully");
            } catch (e) {
                showError(e);
            }
        }
    };

    return (
        <>
            <Layout
                medium
                hideAdd={!userContent?.rights?.includes(5901)}
                showDetailPage={showDetailPage}
                backDetailPage={async () => {
                    setShowDetailPage(false);
                    if (!insidePane) navigate("/tickets");
                    setEditID(null);
                }}
                 
                title={t("Tickets")}
                filterValues={filterObject}
                filterStructure={STRUCTURE}
                onApplyFilter={fetchData}
                onAddClick={() => {
                    if (!insidePane) navigate(`/tickets/create`);
                    setShowDetailPage(true);
                    setEditID(null);
                }}
                insidePane={insidePane}
                page={TicketService.page}
                rows={TicketService.rows}
                total={TicketService.total}
                fetch={TicketService.fetch}
            >
                <Layout.ActionMenu>
                    <div className="layout-action-menu">
                        <DropdownMenu>
                            <>
                                <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                                <div class="dropdown-divider"></div>
                                <DropdownItem>{t("Upload")}</DropdownItem>
                                <div class="dropdown-divider"></div>
                                <DropdownItem> {t("Download")} </DropdownItem>
                            </>
                        </DropdownMenu>
                    </div>
                </Layout.ActionMenu>
                <Layout.Table>
                    <DataGrid
              gridLoading={loading}
                        data={TicketService.records}
                        total={TicketService.total}
                        uiPreference="ticket.grid"
                        headers={GridColumns}
                        selectedIDs={selectedIDs}
                        onSelectChange={(v) => {
                            onSelect(v)
                            setSelectedIDs(v)
                        }}
                        page={TicketService.page}
                        rowsPerPage={TicketService.rowsPerPage}
                        onPaginationChange={TicketService.onPaginationChange}
                        renderLastCol={(ticket) => {
                            return (
                                <>
                                    {([5902, 20].some(right => userContent?.rights?.includes(right)) || (userContent?.userType && userContent?.userType === "super-admin")) &&
                                        <EditLink
                                            onClick={() => {
                                                if (!insidePane)
                                                    navigate(`/tickets/edit/${ticket?._id}`);
                                                setEditID(ticket?._id);
                                                setShowDetailPage(true);
                                            }}
                                        />
                                    }
                                    {([20].some(right => userContent?.rights?.includes(right)) || (userContent?.userType && userContent?.userType === "super-admin"))  &&
                                        <DeleteLink
                                            onClick={(event) => onDelete(event, ticket?._id)}
                                        />
                                    }
                                </>
                            );
                        }}
                    />
                </Layout.Table>
                <Layout.DetailPageTitle>
                    {(userContent?.rights?.includes(20) || (userContent?.userType && userContent?.userType === "super-admin"))
                    ?
                    <div className="d-flex align-items-center">
                        <span> {detailData?._id ? "Edit Ticket" : "Add Ticket"}</span>
                            <TagPicker
                                className="ms-2"
                                kind={"ticket"}
                                onChange={(v) => setDetailData({
                                    ...detailData,
                                    tags: v
                                })}
                                value={detailData?.tags}
                            />
                            <br />
                    </div>:
                    detailData?._id ? "Edit Ticket" : "Add Ticket"
                    }
                </Layout.DetailPageTitle>

                <Layout.DetailPageBody>
                    <EditTickets
                        editId={editID}
                        onChange={(v) => {
                            setDetailData(v)
                        }}
                        hasErr={hasErr}

                    />
                </Layout.DetailPageBody>

                <Layout.DetailPageFooter>
                    {detailData?._id &&([20].some(right => userContent?.rights?.includes(right))) && <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />}
                    {([5902, 20].some(right => userContent?.rights?.includes(right))) && detailData?.status!=2 && <SaveButton onClick={onSave} loading={loading} />}
                </Layout.DetailPageFooter>
            </Layout>
        </>
    );
};
export default observer(Tickets);
