import React, { useEffect, useState } from 'react';
import TokenInput, { DEFAULT_SEPARATORS } from 'react-customize-token-input';
import './react-customize-token-input.css';

const Tokenizer = ({ label, value = [], onChange, placeholder, plain }) => {

  const handleTokenValuesChange = (newTokens) => {
    onChange(newTokens);
  };

  const handleBuildTokenValue = (input) => {
    return input;
  };

  const handleGetTokenDisplayLabel = (tokenValue) => {

    return (
      <>
        {tokenValue}
      </>
    );
  };


  const handleTokenValueValidate = (token) => {
    return !isNaN(token.num);
  };

  const handleGetTokenErrorMessage = (token) => {
    if (isNaN(token.num)) {
      return 'Value must be a number';
    }
    return '';
  };

  const handlePreprocess = (inputValues) => {
    const values = inputValues.filter((value) => value.trim() !== '').map((value) => {
      return value.trim();
    });
    return values;
  };


  const tokenClass = plain ? 'token-input-plain' : 'token-input-normal';

  return (
    <div className={`d-flex flex-1 ${tokenClass}`}>
      <TokenInput
        tokenValues={value}
        onTokenValuesChange={handleTokenValuesChange}
        onBuildTokenValue={handleBuildTokenValue}
        onGetTokenDisplayLabel={handleGetTokenDisplayLabel}
        onTokenValueValidate={handleTokenValueValidate}
        onGetTokenErrorMessage={handleGetTokenErrorMessage}
        onPreprocess={handlePreprocess}
        separators={[
          ...DEFAULT_SEPARATORS,
        ]}
        placeholder={placeholder}
        className={plain ? 'plain' : ''}
      />
    </div>
  );
};

export default Tokenizer;
