import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Row } from "reactstrap";
import { AddressField, CitySelect, InputField, NameField, PhoneNum, SelectField, StateSelect } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "./StatusMasterConstant";
import StatusMasterService from "./StatusMasterService";

const EditStatusMaster = ({ editId, onChange = () => { }, hasErr }) => {
  const [center, setCenter] = useState({})
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    contacts: [
      {

        salutation: "",
        name: "",
        designation: "",
        email: "",
        phone: {
          countryCode: "",
          phone: ""
        }
      }
    ]
  });
  const fetch = async () => {
    try {
      let statusMaster = await StatusMasterService.get(editId);
      setData(statusMaster);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData({
      area: [
        {
          state: "",
          cities: [],
          excludedCities: [],


        }
      ],
      contacts: [
        {
          salutation: "",
          name: "",
          designation: "",
          email: "",
          phone: {
            countryCode: "",
            phone: ""
          }
        }
      ]
    });
    // setData(null)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, StatusMasterService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);



  return (
    <React.Fragment>
      {loading ?

        <ThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >

          <Row className="mt-3 mb-3">
            <InputField
              placeholder={t("Module")}
              value={data?.module}
              required={true}
              label={t("Module Name")}
              onChange={(v) => setData({ ...data, module: v })}
              error={data?.module?.length === 0 ? t("Please enter Module Name") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-12"
            />
          </Row>
          <Row className="mt-3 mb-3">
            <InputField
              placeholder={t("Name")}
              value={data?.name}
              label={t("Name")}
              onChange={(v) => setData({ ...data, name: v })}
              error={data?.name?.length === 0 ? t("Please enter Name") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-12"
            />
          </Row>

          <Row className=" mb-3">
            <InputField
              placeholder={t("Value")}
              value={data?.value}
              label={t("Value")}
              onChange={(v) => setData({ ...data, value: v })}
              error={data?.value?.length === 0 ? t("Please enter Value") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-12"
            />
          </Row>
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditStatusMaster);
