export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/payment-received/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl+"&";
    return url + "page=" + page;
  },
  get: (id) => `/api/payment-received/${id}/detail`,
  save: `/api/payment-received/create`,
  delete: (id) => `/api/payment-received/${id}/delete`,
  update: `/api/payment-received/update`,
  getAllPaymentsParticular_to_Client_and_Company: (client_id, clientCompany_id, payment_id) => {
    let url = `/api/payment-received/grid?rows=-1&page=1&client_id=${client_id}`;
    if (payment_id) {
      url = url + "&_id=notin[" + payment_id + "]";
    }
    return url;
  }
};

export const SELECTSTRUCTURE = {

  paymentMode: [
    // New STRUCTURE
    { label: "CARD", value: "CARD" },
    { label: "CASH", value: "CASH" },
    { label: "CHEQUE", value: "CHEQUE" },
    { label: "ONLINE", value: "ONLINE" },
    { label: "UPI", value: "UPI" },
    { label: "OTHER", value: "OTHER" },
  ],

  // paymentMode: [
  //   { label: "ONLINE", value: "ONLINE" },
  //   { label: "CASH", value: "CASH" },
  //   { label: "WALLET", value: "WALLET" },
  //   { label: "DIESEL", value: "DIESEL" },
  //   { label: "OTHER", value: "OTHER" },
  //   { label: "UPI", value: "UPI" },
  //   { label: "VOUCHER", value: "VOUCHER" },
  //   { label: "CARD", value: "CARD" },
  //   { label: "CHEQUE", value: "CHEQUE" },
  //   { label: "WAIVE_OFF", value: "WAIVE_OFF" },
  //   { label: "FUEL", value: "FUEL" },
  //   { label: "COMPANY", value: "COMPANY" },
  // ],
  paidTo: [
    { label: "Paid To Vendor", value: 1 },
    { label: "Paid To Driver", value: 2 },
    { label: "Paid To Agent", value: 3 },
    { label: "Paid to Vehicle Owner", value: 4 },
    { label: "Others", value: 5 },
  ],
  paymentStatus: [
    {
      label: "Excess Amount",
      value: 0,
      color: "orange",
      renderer: () => (
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: "red",
            marginRight: "5px",
          }}
        ></div>
      ),
    },
    {
      label: "Settled",
      value: 1,
      color: "green",
      renderer: () => (
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: "green",
            marginRight: "5px",
          }}
        ></div>
      ),
    }
  ]
}


export const STRUCTURE = [
  {
    label: "Ref No.",
    filterLabel: "Ref No.",
    filterName: "transactionRef",
    type: "text",
  },
  {
    label: "Client",
    description: "",
    filterLabel: "Client Name",
    filterName: "client_id",
    dependencyOnMe: ["clientCompany_id"],
    type: "clientSelect",
  },
  {
    label: "Client Company",
    description: "",
    dependency:{
      client_id:"client_id"
    },
    filterLabel: "Client Company",
    filterName: "clientCompany_id",
    type: "entitySelect",
  },
  {
    label: "My Company",
    description: "",
    filterLabel: "My Company",
    filterName: "company_id",
    type: "entitySelect",
  },
  {
    label: "Status",
    description: "",
    filterLabel: "Status",
    filterName: "status",
    type: "select",
    data: SELECTSTRUCTURE?.paymentStatus,
  },
];

