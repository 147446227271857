import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Form, Container, Button } from "reactstrap";
import CSC from "../../components/Common/CSC";
import SaveButton from '../../components/Buttons/SaveButton';
import BackButton from '../../components/Buttons/BackButton'
import { InputField } from "../../components";
import { observer } from "mobx-react-lite";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate, useSearchParams } from "react-router-dom";
import FuelPrices from "./store";
import MapWithSearchBox from "./MapWithSearchBox";

const EditFuelPrice = () => {
  let { fuelPriceData } = FuelPrices;
  let { name } = fuelPriceData;

  const [showErr, setShowErr] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [selectedAddressLine, setSelectedAddressLine] = useState(
    FuelPrices?.places[0]?.formatted_address
  );

  let navigate = useNavigate();
  useEffect(() => {
    if (!id) fuelPriceData.name = "";
    async function fetching() {
      let fuelPriceD = await FuelPrices.getFuelPriceById(id);
      setSelectedCountry(fuelPriceD?.address?.country);
      setSelectedState(fuelPriceD?.address?.state);
      setSelectedCity(fuelPriceD?.address?.city);
      setSelectedAddressLine(fuelPriceD?.address?.addressLine);
      console.log(fuelPriceD);
    }
    if (id) {
      fetching();
    }
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    setShowErr(true);
    fuelPriceData = {
      ...fuelPriceData,
      address: {
        addressLine: selectedAddressLine,
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
      },
    };
    if (
      !selectedAddressLine ||
      !name ||
      !selectedCountry ||
      !selectedState ||
      !selectedCity
    )
      return;
    await FuelPrices.saveFuelPrice(fuelPriceData);
    navigate("../fuelPrice", { replace: true });
  };

  const setAddress = (prop) => {
    if (prop) setSelectedAddressLine(prop);
  };

  return (
    <React.Fragment>
      {((id && selectedCountry) || !id) && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="FuelPrice" breadcrumbItem="Edit FuelPrice" />
            <Card>
              <CardBody>
                <div className="">
                  <h5 className="font-size-20 mb-2">
                    <i className="mdi mdi-arrow-right text-primary me-1"></i>Add
                    Office
                  </h5>

                  <Form className="pt-2" onSubmit={submitHandler}>
                    <Row className="mb-3">
                      <Col>
                        <InputField
                          value={name}
                          required={true}
                          label={"Office Name"}
                          onChange={(v) => {
                            fuelPriceData.name = v;
                          }}
                          error={
                            name.length === 0 ? "Please enter office name" : ""
                          }
                          className=""
                          showErr={showErr}

                          type="text"
                        />
                      </Col>
                    </Row>

                    {/* Country, State, City */}
                    <CSC
                      setSelectedCountry={setSelectedCountry}
                      setSelectedCity={setSelectedCity}
                      setSelectedState={setSelectedState}
                      selectedCity={selectedCity}
                      selectedState={selectedState}
                      selectedCountry={selectedCountry}
                      showErr={showErr}
                    />

                    {/* Map With SearchBox */}
                    <MapWithSearchBox
                      onChange={(v) => setSelectedAddressLine(v)}
                      value={selectedAddressLine}
                      onMapMoved={setAddress}
                      address={fuelPriceData.address.addressLine}
                    />

                    {/* navigation */}
                    <div className="d-flex justify-content-end mt-4 ">
                      <BackButton link="/fuel-price" />
                      <SaveButton />
                    </div>

                  </Form>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default observer(EditFuelPrice);
