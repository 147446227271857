import Select, { components } from "react-select";
import React, { useState, useEffect, useContext, Fragment } from "react";
import "./style.css";
import { I18nContext } from "../../store/context/i18nContext";
import { Button } from "reactstrap";

const Menu = (props) => {
  return (
    <Fragment>
      <components.Menu {...props} className="w-100">
        <div className="w-100">
          {props?.selectProps.fetchingData ? (
            <span className="fetching">Fetching data...</span>
          ) : (
            <div>{props?.children}</div>
          )}

          <div
            className={"change-data d-flex justify-content-center w-100 "}
            onClick={props?.selectProps.changeOptionsData}
          >
            {/* <props.selectProps.bottomBarChildren /> */}
          </div>


        </div>
      </components.Menu>
    </Fragment>
  );
};



export const SelectFieldMultiple = (props) => {
  const [showAddComponent, setShowAddComponent] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const [value, setValue] = useState(props?.value);
  const [dc, setDc] = useState({});
  const { t } = useContext(I18nContext);
  useEffect(() => {
    document
      .querySelectorAll(".css-yk16xz-control")
      .forEach((ele) => (ele.style.border = "none"));
  }, []);

  useEffect(() => {
    if (JSON.stringify(props?.value) !== JSON.stringify(value) && props?.onChange)
      props?.onChange(value);
  }, [value]);

  useEffect(() => {
    if (JSON.stringify(props?.value) !== JSON.stringify(value))
      setValue(props?.value);
    if (props?.value?.length > 0) {
      setDc({
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      });
    } else {
      setDc({});
    }
  }, [props?.value]);

  const renderLeftComponent = () => {
    if (!props?.LeftComponent) return null;
    return (
      <div
        onClick={(e) =>
          props?.onClickLeft ? props?.onClickLeft(e) : null
        }
        className={
          props?.onClickLeft
            ? "component_pointer Selectfield__leftComponent"
            : "selectfield__leftComponent"
        }
      >
        <props.LeftComponent />
      </div>
    )
  }

  const renderRightComponent = () => {
    if (!props?.RightComponent) return null;
    return (
      <div
        onClick={(e) =>
          props?.onClickRight ? props?.onClickRight(e) : null
        }
        style={{ position: "relative", top: -14 }}
        className={
          props?.onClickRight
            ? "component_pointer Selectfield__rightComponent"
            : "selectfield__rightComponent"
        }
      >
        <props.RightComponent />
      </div>
    )
  }

  const renderAddComponent = () => {
    if (!(showAddComponent && props?.flexible && props?.AddEntityComponent)) return null;
    return (
      <section
        style={{
          height: "100vh",
          width: "100vw",
          backgroundColor: "white",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "1500",
        }}
      >
        <props.AddEntityComponent setShowAddComponent={setShowAddComponent} />
      </section>
    )
  }

  const handleOnChange = (v) => {
    if (v.length > 0) {
      setDc({
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      });
      setValue(v);
    } else {
      setDc({});
      setValue("");
    }
  }

  return (
    <React.Fragment>
      <section

        className={`selectfield__wrapper ${props?.className}`}
      >
        <div style={props?.style}

          className={
            ` ${!(props?.plain || props?.outline || props?.inline) ? "d-flex justify-content-center align-items-center " : " "}      selectfield__container ` +
            (props?.plain && !props?.onFocus ? "selectfield__plain " : "") +
            props?.containerClassName
          }
        >


          {!(props?.plain || props?.outline || props?.inline) && (
            <div
              style={{ width: "120px", fontSize: 12, fontWeight: "500" }}
            >
              {(props?.label)}
              {props?.required && <font color="red">*</font>}
            </div>
          )}

          {props?.suggestion && (
            <b className="selectfield__suggestion">{props?.suggestion}</b>
          )}
          <div
            className={
              props?.borderNone
                ? "border-0 "
                : "" + "selectfield__innercontainer d-flex  flex-1 "
            }
          >
            {renderLeftComponent()}

            {props?.inline ? (
              <div className="input-group">
                <div className="input-group-text">
                  {t(props?.label)}
                  </div>
                {props?.required && <font color="red">*</font>}
                <Select
                  {...(props?.filterOption && { filterOption: props?.filterOption })}
                  menuPosition="fixed"
                  components={dc}
                  setValue={value}
                  value={value}
                  menuPlacement={"auto"}
                  // value={props?.data.filter((d) => d.value  ===  value)}
                  isClearable={props?.showClear}
                  onFocus={() => setOnFocus(true)}
                  onBlur={() => setOnFocus(false)}
                  options={props?.data}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  onChange={handleOnChange}
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: "none",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      boxShadow: "none",
                    }),
                  }}
                  className="form-control px-0 py-0 rounded-right-1"
                  placeholder={"Select " + t(props?.placeholder)}
                  isDisabled={props?.isDisabled || false}
                />
              </div>
            ) : (
                <div className="selectfield_multiple">
                <Select
                  {...(props?.filterOption && { filterOption: props?.filterOption })}

                  components={
                    props?.showBottomBar
                      ? value
                        ? {
                          IndicatorSeparator: () => null,
                          components,
                          Menu,
                          DropdownIndicator: () => null,
                        }
                        : { IndicatorSeparator: () => null, Menu }
                      : value
                        ? {
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                        }
                        : { IndicatorSeparator: () => null }
                  }
                  // bottomBarChildren={props?.bottomBarChildren}
                  setValue={value}
                  menuPlacement={"auto"}
                  isDisabled={props?.isDisabled}
                  value={value}
                  // value={props?.data.filter((d) => d.value  ===  value)}
                  isClearable={props?.showClear}
                  onFocus={() => setOnFocus(true)}
                  onBlur={() => setOnFocus(false)}
                  options={props?.data}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  onChange={(v) => (v ? setValue(v) : setValue(""))}
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: 0,
                      boxShadow: "none",
                      minHeight: 34,
                      maxHeight: "max-content",
                      minHeight: 34,
                      height: "auto"
                    }),
                    container: (base) => ({
                      ...base,
                      height: "max-content",
                    }),
                    indicatorsContainer: (base) => ({
                      ...base,
                      minHeight: 22,
                      height: "auto"
                    }),
                    valueContainer: (base) => ({ ...base, padding: 0, paddingLeft: "12px", }),
                    placeholder: (base) => ({
                      ...base,

                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      width: state.selectProps.width,
                      color: state.selectProps.menuColor,
                    }),
                    input: (base) => ({
                      ...base,
                      margin: 0,

                    }),
                  }}
                  className="selectfield__select  "
                  placeholder={" "}
                />
                <label
                  className={
                    props?.value || onFocus
                      ? "selectfield__label px-2 selectfield__notempty_input"
                      : "selectfield__label px-2"
                  }
                >
                  {t(props?.placeholder)}
                  {/* {props?.required && <font color="red">*</font>} */}
                </label>
              </div>
            )}

            {renderRightComponent()}
          </div>
          {props?.error && props?.showErr && (
            <em className="selectfield__error">{props?.error}</em>
          )}
        </div>
        {props?.flexible && (
          <button onClick={() => setShowAddComponent(true)}>Add Entity</button>
        )}
      </section>
      {renderAddComponent()}
    </React.Fragment>
  );
};
