import React from 'react';
import moment from 'moment';
import './DisplayWhatsapp.scss';
import { IoMdArrowBack } from 'react-icons/io';
import { Button } from 'reactstrap';

const DisplayWhatsapp = ({ whatsapp, onBack = () => {}}) => {
    return (
        <div className="zr_communication_display whatsapp">
            <div className="whatsapp-header">
                <h2 className='d-flex align-items-center'>    <IoMdArrowBack onClick={onBack} className='me-2' size={20} /> WhatsApp</h2>
                <span className="whatsapp-date">{moment(whatsapp.sentAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
            </div>
            <div className="whatsapp-body">
                <p>{whatsapp.content}</p>
            </div>
        </div>
    );
};

export default DisplayWhatsapp;
