import React, { useContext } from 'react'
import { Col, Row } from 'reactstrap'
// import SupplierSelect from '../../../components/Dropdowns/Supplier/SupplierSelect';
import { I18nContext } from '../../../store/context/i18nContext';

const StepThree = ({ data, setData }) => {
    const { t } = useContext(I18nContext);
    return (

        <>
            <div className=' d-flex justify-content-center mb-3 fw-bold'>RFP Visibility</div>
            <div className=' d-flex justify-content-center'>

                <ul
                    className="nav nav-pills mb-3 d-inline-block rounded px-1 pt-1 bg-white border-2"
                    role="tablist"
                    style={{ fontSize: "11px", width: "22.1%", height: "40px", border: "1px solid #DF6463" }}
                >
                    <li
                        className="nav-item waves-effect waves-light me-1 pill-1"
                        role="presentation"
                    >
                        <span
                            className="nav-link active"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-selected="true"
                            onClick={(v) => {
                                v.preventDefault();
                                setData({ ...data, isPublic: true });
                            }}
                        >
                            <span className="d-block">
                                Public
                            </span>
                        </span>
                    </li>
                    <li
                        className="nav-item waves-effect waves-light ms-1 pill-2"
                        role="presentation"
                    >
                        <span
                            className="nav-link"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-selected="false"
                            tabindex="-1"
                            onClick={(v) => {
                                v.preventDefault();
                                setData({ ...data, isPublic: false });
                            }}
                        >
                            <span className="d-block">
                                Private
                            </span>
                        </span>
                    </li>

                </ul>
            </div>
            <Row className='mt-4'>
                <Col className=' col-sm-12 px-4'>
                    {/* <SupplierSelect
                        label={t("Select Preferred Supplier")}
                        placeholder={"Select Preferred Supplier"}
                        value={data?.preferredSuppliers}
                        onChange={(v) => {
                            console.log(v);
                            setData({
                                ...data, preferredSuppliers: v
                            });
                        }}
                    /> */}
                </Col>
            </Row>
        </>
    )
}

export default StepThree