import { makeAutoObservable } from "mobx";
import { doGET, doPOST } from "../../../util/HttpUtil";
import { RateConstants } from "./RateConstants";

class RateSelect {
  rates = [];
  constructor() {
    makeAutoObservable(this);
  }
  fetch = async function () {
    try {
      const response = await doGET(RateConstants.grid());
      if (response.status === 200) {
        this.rates = response?.data?.rows?.map((rate) => {
          let label = "";
          if (rate?.name) {
            label = rate.name + " - " + rate['rateNo'];
          } else {
            label = rate['rateNo'];
          }
          return { ...rate, label: label, value: rate['_id'] };
        })
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };
  delete = async function (id) {
    const response = await doGET(RateConstants.deleteRate(id));
    if (response.status === 200) {
      this.fetch();
    }
  };
  save = async function (data) {
    await doPOST(RateConstants.saveRates, data);
    this.fetch();
  };
}

const RateSelectService = new RateSelect();
export default RateSelectService;
