import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import EditZones from "./EditZones";
import { STRUCTURE } from "./ZoneConstant";
import { GridColumns } from "./ZoneGridColumns";
import ZoneService from "./ZoneService";


const Zones = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { userContent } = useContext(UserContext);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/zones/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await ZoneService.fetch(filterUrl);
      setLoading(false);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs([value])
  }, [])


  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.name && !detailData?.code) {
      setHasErr(true)
      showError(t("Please enter all required fields"));
      return;
    }
    setLoading(true);
    try {
      if (editID) {
        await ZoneService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Zone updated successfully."));
      } else {
        const zone = await ZoneService.save(detailData);
        setLoading(false);
        showMessage(t("Zone saved successfully."));
        if (!insidePane) navigate(`/zones/edit/${zone}`);
      }
      fetchData(filterURL);

    } catch (e) {
      showError(e);
      setLoading(false);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await ZoneService.delete(id);
        setLoading(false);
        showMessage(t("Zone Deleted Successfully"), t("Deleted"));
        if (!insidePane) navigate("/zones");
        setShowDetailPage(false);
      } catch (e) {
        showError(e);
        setLoading(false);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await ZoneService.bulkDelete(selectedIDs);
        setLoading(false);
        showMessage(t("Zone Deleted Successfully"), t("Deleted Successfully"));
      } catch (e) {
        showError(e);
        setLoading(false);
      }
    }
  };

  const renderLastCol = useCallback((zone) => {
    return (
      <>
        {userContent?.rights?.includes(2802) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/zones/edit/${zone?._id}`);
              setEditID(zone?._id);
              setShowDetailPage(true);

            }}
          />
        }
        {userContent?.rights?.includes(2803) ?
          <DeleteLink
            onClick={(event) => onDelete(event, zone?._id)}
          />
          : null}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(zone?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])




  return (
    <React.Fragment>
      <>
        <Layout
          small
          hideAdd={!userContent?.rights?.includes(2801)}
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/zones");
            setEditID(null);
          }}
           
          title={t("Zones")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/zones/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={ZoneService.page}
          rows={ZoneService.rows}
          total={ZoneService.total}
          fetch={ZoneService.fetch}
        >
          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  {userContent?.rights?.includes(2803) ?
                  <>
                  
                    <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                    <div class="dropdown-divider"></div>
                    </>
                    : null}
                    
                  <DropdownItem>{t("Upload")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem> Download </DropdownItem>
                </>

              </DropdownMenu>

            </div>
          </Layout.ActionMenu>
          <Layout.Table>
            <DataGrid
              gridLoading={loading}
              data={ZoneService.records}
              total={ZoneService.total}
              uiPreference="zone.grid"
              headers={GridColumns}
              onSelectChange={(v) => {
                onSelect(v)
                setSelectedIDs(v)
              }}
              selectedIDs={selectedIDs}
              page={ZoneService.page}
              rowsPerPage={ZoneService.rowsPerPage}
              onPaginationChange={ZoneService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </Layout.Table>


          <Layout.DetailPageTitle>
            {detailData?._id ? t("Edit Zone") : t("Add Zone")}
          </Layout.DetailPageTitle>

          <Layout.DetailPageBody>
            <EditZones
              editId={editID}
              onChange={(v) => {
                setDetailData(v)
              }}
              hasErr={hasErr}
            />
          </Layout.DetailPageBody>

          <Layout.DetailPageFooter>
            {detailData?._id ? (
              <AuditLogDialog module="Zone" id={detailData?._id} />
            ) : null

            }

            {userContent?.rights?.includes(2803) && (editID) ?
              <DeleteButton
                loading={loading}
                onClick={(e) => onDelete(e, detailData?._id)} />
              : null}
            {userContent?.rights?.includes(2802) ?
              <SaveButton
                loading={loading}
                onClick={onSave} />
              : null}
          </Layout.DetailPageFooter>
        </Layout>
      </>
    </React.Fragment >
  )
};
export default observer(Zones);
