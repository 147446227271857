import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { EditLink, handleEpandRows, Layout } from "../../components";

import { DialogContext } from "../../store/context/DialogContext";
// import { STRUCTURE } from "./SubscriptionConstant";
import SubscriptionService from "./SubscriptionService";
import EditSubscription from "./EditSubscription";

const Subscription = (props) => {
  const { id } = useParams();
  const { showConfirm, showError } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(id);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEpandRow = (event, SubsId) => {
    setExpandedRows(handleEpandRows(event, SubsId, expandedRows));
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    await SubscriptionService.fetch();
    setLoading(false);
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (e) {
      showError(e);
    }
  }, []);

  let navigate = useNavigate();
  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    setShowDetailPage(window.location.pathname === "/subscription/edit" || id);
  }, [id]);

  const handleRowClick = async (SubsId) => {
    if (showDetailPage && SubsId !== selected_id && isEditDataChanged) {
      let isProceed = await showConfirm({
        isSaveWarn: true,
        title: t(`Do you want to save the changes ?`),
        description: t("Your changes will be lost if you don't save them"),
      });
      if (isProceed) onRowClick(SubsId);
    } else onRowClick(SubsId);
  };
  const onRowClick = (id) => {
    if (!props?.insidePane) navigate(`/subscription/edit/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };

  return (
    <Layout
      showDetailPage={showDetailPage}
       
      title={t("Corporate Subscriptions")}
      onApplyFilter={fetchData}
      onAddClick={() => {
        if (!props?.insidePane) navigate(`/subscription/edit`);
        setShowDetailPage(true);
        setSelected_id(null);
      }}
      page={SubscriptionService.page}
      rows={SubscriptionService.rows}
      total={SubscriptionService.totalRecords}
      onPageChange={async (page, rows) => {
        await SubscriptionService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>Client Name</Th>
            <Th>Start Date</Th>
            <Th>Trial End Date</Th>
            <Th>Notice Date</Th>
            <Th>Lockdown Date</Th>
            <Th>Expiry Date</Th>
            <Th className="float-end">Action</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TheadCompact>
        <Thead className="thead-dark">
          <Tr>
            <Th>Client Name</Th>
            <Th>Start Date</Th>
            <Th>Expiry Date</Th>
          </Tr>
        </Thead>
      </Layout.TheadCompact>

      <Layout.TbodyFull>
        <Tbody>
          {SubscriptionService.records?.map((subs) => (
            <>
              <Tr
                onClick={(event) => {
                  event.preventDefault();
                  if (showDetailPage) {
                    navigate(`/subscription/edit/${subs?._id}`);
                    setSelected_id(subs?._id);
                  } else {
                    handleEpandRow(event, subs?._id);
                  }
                }}
                key={subs?._id}
                className={`striped-table position-relative ${selected_id === subs?._id ? "table-row-selected" : ""
                  }`}
              >
                <Td scope="row">{subs?.clientName}</Td>
                <Td> {subs?.startDate} </Td>
                <Td> {subs?.trialEndDate}</Td>
                <Td>{subs?.noticeDate}</Td>
                <Td>{subs?.lockdownDate}</Td>
                <Td>{subs?.plans[0]?.expiry}</Td>
                <Td className="float-end">
                  <EditLink
                    onClick={() => {
                      setSelected_id(subs?._id);
                    }}
                    to={`/subscription/edit/${subs?._id}`}
                  />
                </Td>
              </Tr>
            </>
          ))}
        </Tbody>
      </Layout.TbodyFull>

      <Layout.TbodyCompact>
        <Tbody>
          {SubscriptionService.records?.map((subs) => (
            <Tr
              className={selected_id === subs?._id && "table-row-selected"}
              style={{ cursor: "pointer" }}
              key={subs?._id}
              onClick={showDetailPage && (() => handleRowClick(subs?._id))}
            >
              <Td scope="row">
                {subs?.clientName} <br />
              </Td>
              <Td>{subs?.startDate}</Td>
              <Td>{subs?.plans[0]?.expiry}</Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyCompact>

      <Layout.DetailPage>
        <EditSubscription
          insidePane={props?.insidePane}
          onChange={(v) => setIsEditDataChanged(v)}
          id={selected_id}
          back={() => {
            setShowDetailPage(false);
            if (!props?.insidePane) navigate("/subscription");
            setSelected_id(false);
          }}
          fetchData={fetchData}
        />
      </Layout.DetailPage>
    </Layout>
  );
};

export default observer(Subscription);
