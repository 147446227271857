import React, { useContext } from 'react'
import { Col, Row } from 'reactstrap'
import { SelectField } from '../../../components'
import VehicleType from '../../../components/Dropdowns/VehicleTypeSelect/VehicleTypeSelect'
import { I18nContext } from '../../../store/context/i18nContext'
import { SELECTSTRUCTURE } from '../MarketPlaceConstant'


const StepTwo = ({ data, setData }) => {
    const { t } = useContext(I18nContext);
    return (
        <>
            <Row className=' mt-4'>
                <Col className=' col-sm-12 px-4'>
                    <SelectField
                        label={t("PickUp Time")}
                        data={[
                            { label: "Morning", value: "Morning" },
                            { label: "Afternoon", value: "Afternoon" },
                            { label: "Evening", value: "Evening" },
                            { label: "Night", value: "Night" },
                        ]}
                        value={data?.pickupTime}
                        onChange={(v) => setData({ ...data, pickupTime: v?.value })}
                    />
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col className='col-sm-12 px-4'>
                    <VehicleType multi
                        value={data?.vehicleType}
                        onChange={(v) => {
                            setData({
                                ...data, vehicleType: v
                            })
                        }

                        } />
                </Col>


            </Row>
            <Row className='mt-5'>
                <Col className='col-sm-12 px-4'>
                    <SelectField
                        data={SELECTSTRUCTURE.servicetype}
                        label={t("Service Type")}
                        value={data?.serviceType}
                        onChange={(v) =>
                            setData({
                                ...data, serviceType: v.value
                            })

                        }

                        type="text"
                        required={true}
                    />
                </Col>
            </Row>


        </>
    )
}

export default StepTwo