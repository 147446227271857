import React, { useEffect, useRef } from "react";
import { CountrySelect, SalutationSelect } from "..";

export default function NameField({
    showErr,
    inline,
    required,
    borderNone,
    className,
    value,
    isDisabled,
    outline,
    name,
    salutationStyle,
    inputStyle,
    salutation,
    onNameChange = () => { },
    onSalutationChange = () => { },
    label,
    hideDropdown = false
}) {
    const inputRef = useRef(null);

    useEffect(() => {
        if (!name && inputRef.current) {
            inputRef.current.value = "";
        }
    }, [name]);

    const handleNameChange = (e) => {
        const regex = new RegExp("[^a-zA-Z0-9 _,.&()\\%|'\\-=:@/#]", "g");
        const sanitizedValue = e.target.value.replace(regex, "");
        onNameChange(sanitizedValue);
    };

    return (
        <div className={className + " d-flex justify-content-center align-items-center "}>
            {outline ??
                <div
                    style={{ width: "120px", fontSize: 12, fontWeight: "500" }}
                >
                    {label ?? "Name"} {required && <font color="red">*</font>}
                </div>}
            <div
                style={{ margin: "10px 0px 5px 0px", height: "36px" }}
                className={" input-group flex-1 "}
            >
                <div className="input-group-prepend">
                    <SalutationSelect
                        className={`m-0 `}
                        innerContainerStyle={{ borderRadius: "0px 0px 0px 0px", paddingLeft: "8px" }}
                        style={{ borderRadius: "0px 0px 0px 0px", }}
                        inputGroup
                        value={salutation}
                        onChange={(v) => {
                            onSalutationChange(v);
                        }}
                        outline
                        noLabel
                        hideDropdown={hideDropdown}
                        showErr={showErr}
                        required={true}
                        inline={inline}
                        borderNone={borderNone}
                        isDisabled={isDisabled}
                    />
                </div>
                <input
                    ref={inputRef}
                    required={true}
                    placeholder={`${label || "Name"}${required ? "*" : ""}`}
                    style={{ height: "36px", border: "none ", borderBottom: "1.5px solid #b1b6bc ", borderRadius: "0px 0px 0px 0px", ...inputStyle }}
                    value={name}
                    onChange={handleNameChange}
                    type="text"
                    className="form-control"
                />
            </div>
        </div>
    );
}
