import { observer } from "mobx-react-lite";

import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, } from "reactstrap";


import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,

  Checkbox,

  InputField,
} from "../../../../components";
import "../../../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../../../store/context/DialogContext";
import { I18nContext } from "../../../../store/context/i18nContext";




import OtherSettingsService from "./OtherSettingsService";





const OtherSettings = (props) => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({})


  const { showMessage } = useContext(DialogContext);
  const [hasErr, setHasErr] = useState(false);

  const submitHandler = async (e) => {
    console.log("submit handler");
    e.preventDefault();

    setLoading(true);

    try {




      await OtherSettingsService.save(data)
      console.log("xsnkdnskn")

      showMessage("OtherSettings saved successfully");
      setLoading(false)
    } catch (e) {
      showError(e)
      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await OtherSettingsService.get();
      setData(response)

      setHasErr(false);
      setLoading(false);

    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {

    setLoading(true);

    fetchData();
    setHasErr(false);

  }, []);



  if (loading) return <CardThreeBounce />;
  return (<div className="p-4 mt-3">



    <Row className="mt-5">


      <Col className='col-lg  mt-3' style={{ backgroundColor: "#EAEAEA", fontSize: "15px", borderBottom: "1px solid rgba(0,0,0,.1)" }}>
        <header>Other Settings</header>
      </Col>
    </Row>

    <Row className="">
      <Col className="col-lg">

        <Checkbox
          label="Enable password + SMS OTP based two factor authentication for Admin"

          checked={data?.twoFactorSMSAuth}

          onClick={() => {
            setData({
              ...data, twoFactorSMSAuth
                : !data?.twoFactorSMSAuth
            })
          }}
        />


      </Col>

    </Row>

    <Row className="">
      <Col className="col-lg">

        <Checkbox
          label="Quality Check Required for Vehicle and Drivers"

          checked={data?.qcRequired}

          onClick={() => {
            setData({ ...data, qcRequired: !data?.qcRequired })
          }}
        />


      </Col>

    </Row>

    <Row className="">
      <Col className="col-lg">

        <Checkbox
          label="Allow Employee to Update Address by Self"

          checked={data?.disableAutoApprove}

          onClick={() => {
            setData({ ...data, disableAutoApprove: !data?.disableAutoApprove })
          }}
        />


      </Col>

    </Row>

    <Row className="">
      <Col className="col-lg">

        <Checkbox
          label="Append Financial Year in Invoice Print/Download"

          checked={data?.appendFinYear}

          onClick={() => {
            setData({ ...data, appendFinYear: !data?.appendFinYear })
          }}
        />


      </Col>

    </Row>


    <Row className="">
      <Col className="col-lg">

        <Checkbox
          label="Show Comfort Seating Capacity"

        // checked={data?.appendFinYear}

        // onClick={() => {
        //   setData({ ...data, appendFinYear: !data?.appendFinYear })
        // }}
        />


      </Col>

    </Row>

    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <InputField
          placeholder="Quality Check Period Validity)"
          // value={data?.stop_other_interval}
          label={"Quality Check Expiry (in Days)"}
          // onChange={(v) =>
          //   setData({
          //     ...data,
          //     stop_other_interval: v
          //   })
          // }



          type="text"

        />

      </Col>
    </Row>

    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <InputField
          placeholder="Geo Fencing Radius(default 100) (in Meters)"
          value={data?.geoFenceRadius
          }
          label={"Geo Fencing Radius(default 100) (in Meters)"}
          onChange={(v) =>
            setData({
              ...data,
              geoFenceRadius
                : v
            })
          }



          type="number"

        />

      </Col>
    </Row>

    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <InputField
          placeholder="Monthly Report Email (Booking / Invoice)"
          value={data?.regularReportEmail}
          label={"Monthly Report Email (Booking / Invoice)"}
          onChange={(v) =>
            setData({
              ...data,
              regularReportEmail
                : v
            })
          }



          type="email"

        />

      </Col>
    </Row>


    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <InputField
          placeholder="Invoice Prefix"
          value={data?.invoicePrefix}
          label={"Invoice Prefix"}
          onChange={(v) =>
            setData({
              ...data,
              invoicePrefix

                : v
            })
          }



          type="text"

        />

      </Col>
    </Row>



    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <InputField
          placeholder="Min Invoice Sequence No"
          // value={data?.invoicePrefix}
          label={"Min Invoice Sequence No"}
          // onChange={(v) =>
          //   setData({
          //     ...data,
          //     invoicePrefix

          //       : v
          //   })
          // }



          type="text"

        />

      </Col>
    </Row>


    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <InputField
          placeholder="Credit Note Prefix"
          value={data?.cnPrefix
          }
          label={"Credit Note Prefix"}
          onChange={(v) =>
            setData({
              ...data,
              cnPrefix
                : v
            })
          }



          type="text"

        />

      </Col>
    </Row>

    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <InputField
          placeholder="Minimum Credit Note Sequence No"
          value={data?.minCNSeq
          }
          label={"Minimum Credit Note Sequence No"}
          onChange={(v) =>
            setData({
              ...data,
              minCNSeq

                : v
            })
          }



          type="number"

        />

      </Col>
    </Row>

    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <InputField
          placeholder="App Name"
          value={data?.appName
          }
          label={"App Name"}
          onChange={(v) =>
            setData({
              ...data,
              appName

                : v
            })
          }



          type="text"

        />

      </Col>
    </Row>


    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <InputField
          placeholder="Android App Link"
          value={data?.androidLink}
          label={"Android App Link"}
          onChange={(v) =>
            setData({
              ...data,
              androidLink

                : v
            })
          }



          type="text"

        />

      </Col>
    </Row>

    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <InputField
          placeholder="IOS App Link"
          value={data?.iosLink
          }
          label={"IOS App Link"}
          onChange={(v) =>
            setData({
              ...data,
              iosLink


                : v
            })
          }



          type="text"

        />

      </Col>
    </Row>

    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Make Credit Note sequence distinct for each Business Unit"

        // checked={data?.appendFinYear}

        // onClick={() => {
        //   setData({ ...data, appendFinYear: !data?.appendFinYear })
        // }}
        />


      </Col>

    </Row>

    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Round Currency to 2 Decimals"

          checked={data?.roundCurrency}

          onClick={() => {
            setData({ ...data, roundCurrency: !data?.roundCurrency })
          }}
        />


      </Col>

    </Row>










    <div className="d-flex justify-content-end  ">
      <div className="me-2">
        <Button type="submit" color="primary" onClick={submitHandler}>Save</Button>{' '}
      </div>




    </div>

  </div>

  );
};

export default observer(OtherSettings);

