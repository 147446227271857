
import L, { Icon } from 'leaflet';

import markerPeople from "../../assets/images/icons/marker_people.png"; 
import markerOffice from "../../assets/images/icons/marker_factory.png"; 

export const PeopleMarkerIcon = new Icon({
    iconUrl: markerPeople,
    iconSize: [30, 30],
    iconAnchor: [16, 16],
})
export const OfficeMarkerIcon = new Icon({
    iconUrl: markerOffice,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
})
 

export const svgIcon = L.divIcon({
    html: `
   <?xml version="1.0" encoding="utf-8"?>
<svg class="blinking-border" viewBox="0 -75 150 500" width="80" height="150" xmlns="http://www.w3.org/2000/svg">
  <ellipse class="blinking-border" style="fill: rgba(255, 255, 255, 1 ); stroke-width: 4; stroke: rgb(255, 255, 255 );" cx="76.913" cy="74.565" rx="68.056" ry="69.967" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
  <ellipse   style="fill: rgb(207, 253, 105);" cx="78.256" cy="73.399" rx="45.282" ry="46.933" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
  <ellipse  style="fill: rgb(255, 255, 255); stroke-width: 8; stroke: rgb(255, 255, 255 );" cx="80.129" cy="170.92" rx="0.127" ry="30.63" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
</svg>

<style>
@keyframes blink {
  0% {
    stroke: blue;
  }
  50% {
    stroke: transparent;
  }
  100% {
    stroke: blue;
  }
}

.blinking-border {
  animation: blink 1s infinite;
}
</style>
`,
    className: "svg-icon",
    iconSize: [20, 20],
    iconAnchor: [16, 16]
  });



export const mapSvgIcon = (borderColor,color="rgb(207, 253, 105)") => L.divIcon({
    html: `
      <svg class="blinking-border" viewBox="0 -75 150 500" width="80" height="150" xmlns="http://www.w3.org/2000/svg">
        <ellipse class="blinking-border" style="fill: rgba(255, 255, 255, 1 ); stroke-width: 4; stroke: rgb(255, 255, 255 );" cx="76.913" cy="74.565" rx="68.056" ry="69.967" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
        <ellipse style="fill: ${color};" cx="78.256" cy="73.399" rx="45.282" ry="46.933" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
        <ellipse style="fill: rgb(255, 255, 255); stroke-width: 8; stroke: rgb(255, 255, 255 );" cx="80.129" cy="170.92" rx="0.127" ry="30.63" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
      </svg>
      <style>
        @keyframes blink {
          0% {
            stroke: ${borderColor};
          }
          50% {
            stroke: transparent;
          }
          100% {
            stroke: ${borderColor};
          }
        }
  
        .blinking-border {
          animation: blink 1s infinite;
        }
      </style>
    `,
    className: "svg-icon",
    iconSize: [80, 150],  // Adjust the size to match the SVG size
    iconAnchor: [45, 80] 
  });
  
  