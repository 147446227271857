import { ThreeBounce } from 'better-react-spinkit';
import _ from 'lodash';
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Form, Row } from "reactstrap";
import { AmountField, CardThreeBounce, Checkbox, CompanySelect, CurrencySelect, DateSelect, DeleteButton, Documents, EmployeeSelect, InputField, SaveButton, SelectField, SupplierSelect, VehicleSelect } from "../../components";
import CurrencyAmountField100 from '../../components/AmountField/CurrencyAmountField100';
import CategorySelect from '../../components/Dropdowns/CategorySelect/CategorySelect';
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from '../../store/context/UserContext';
import { I18nContext } from "../../store/context/i18nContext";
import AuditLogDialog from '../Booking/components/Audit/AuditLog';
import Items from "./ExpenseItems.js";
import ExpenseService from "./ExpenseService";

const getTotalamount = (accounts) => accounts?.reduce((total, curr) => total + curr.total, 0);

const EditExpenses = ({ insidePane, editId, onSave, onDelete, onChange = () => { } }) => {
  const { t } = useContext(I18nContext);
  let navigate = useNavigate();
  const { showError, showMessage, showConfirm } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    currency: "INR",
  });
  const [itemize, setItemize] = useState(false);
  const { userContent } = useContext(UserContext);
  const [hasErr, setHasErr] = useState(false)

  const fetch = async () => {
    try {
      let expenses = await ExpenseService.get(editId);
      setLoading(false);
      if (expenses?.items?.length > 1) {
        setItemize(true)
      }
      setData(expenses);
    } catch (error) {
      showError(error)
      setLoading(false);
    }
  };

  useEffect(() => {
    setData({
      currency: "INR",
    })
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  const handleSave = async (e) => {
    e.preventDefault();
    if (!data?.date) {
      setHasErr(true)
      showError(t("Please Enter Expense Date"));
      return;
    }
    else if (!data?.items?.[0]?.head) {
      setHasErr(true)
      showError(t("Please Enter Expense Head"));
      return;
    }
    else if (!data?.items?.[0]?.unitCost) {
      setHasErr(true)
      showError(t("Please Enter Amount"));
      return;
    }
    try {
      if (editId) {
        await ExpenseService.edit({ ...(data ?? {}), currency: data?.currency ?? "INR" }, editId);
        setLoading(false);
        showMessage(t("Expense updated successfully."));
      } else {
        const expense = await ExpenseService.save({ ...data, currency: data?.currency ?? "INR" });
        setLoading(false);
        showMessage(t("Expense saved successfully."));
        if (!insidePane) navigate(`/expense/edit/${expense}`);
      }
      onSave();
    } catch (e) {
      {
        showError(e);
        setLoading(false);
      }
      setHasErr(false)
    }
  };

  const handleDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await ExpenseService.delete(id);

        showMessage(t(" Expense Deleted"), t("Deleted"));
        onDelete()
      } catch (e) {
        showError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) return <CardThreeBounce />;


  return (
    <React.Fragment>
      {loading ?
        <div className="d-flex justify-content-center align-items-center">
          <ThreeBounce size={50} color="pink" />
        </div>
        :

        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <div className="zr_layout-header p-3 d-flex justify-content-between" style={{ flexShrink: 0 }} >
            {data?._id ? "Edit Expense" : "Add Expense"}
          </div>

          <Form
            className="p-3"
            style={{ height: "100%" }}
          >
            <Row className="d-flex justify-content-between ">

              <div className='col-6'>
                <SelectField
                  required
                  outline
                  placeholder={t("Expense Type ")}
                  value={data?.type}
                  label={t("Expense Type")}
                  onChange={(v) => {
                    setData({
                      ...data,
                      type: v?.value,
                      vehicle_id: null,
                      employee_id: null,
                      category_id: null,
                      subCategory_id: null
                    });
                  }}
                  data={[
                    { label: "General", value: "general" },
                    { label: "Vehicle", value: "vehicle" },
                    { label: "Employee", value: "employee" },
                  ]}
                />
              </div>

              <div className='col-6'>
                <CompanySelect
                  required={true}
                  corpType="self"
                  outline
                  value={data?.company_id}
                  onChange={(v) => setData(
                    {
                      ...data,
                      company_id: v?.value,
                    }
                  )}
                />
              </div>

              {data?.type == "vehicle" && <div className='col-6'><VehicleSelect
                value={data?.vehicle_id}
                onChange={(v) => setData({ ...data, vehicle_id: v?.value })}
                label={"Vehicle"}
                outline
              />       </div>}
              {data?.type == "employee" && <div className='col-6'><EmployeeSelect
                value={data?.employee_id}
                outline
                onChange={(v) => setData({ ...data, employee_id: v?.value })}
                label={"Employee"}

              /> </div>}

              <CategorySelect
                // className={"col-6"}
                // inline
                showSubcategory={true}
                outline
                value={{ category_id: data?.category_id, subCategory_id: data?.subCategory_id }}
                module={"expense-" + data?.type}
                onChange={(v) => {
                  setData({ ...data, category_id: v?.category_id, subCategory_id: v?.subCategory_id })
                }}
              />



              <DateSelect
                required={true}
                placeholder={t("Expense Date ")}
                value={data?.date}
                label={t("Date")}
                outline
                onChange={(v) => setData({ ...data, date: v })}
                className="col-sm-6 "
                timestamp
              />
              {data?.type == "vehicle" &&
                <React.Fragment>
                  <DateSelect
                    required={true}
                    placeholder={t("Next Service Date ")}
                    value={data?.expenseExtn?.nextServiceDate}
                    label={t("Next Service Date")}
                    outline
                    onChange={(v) => setData(prevData => ({
                      ...prevData,
                      expenseExtn: {
                        ...prevData.expenseExtn,
                        nextServiceDate: v
                      }
                    }))}
                    className="col-6 "
                    timestamp
                  />
                  <InputField
                    label="Next Service KM"
                    type="number"
                    placeholder="Next Service KM"
                    className="col-sm-6 "
                    value={data?.expenseExtn?.nextServiceKM}
                    onChange={(v) => setData(prevData => ({
                      ...prevData,
                      expenseExtn: {
                        ...prevData.expenseExtn,
                        nextServiceKM: v
                      }
                    }))}
                    outline

                  />
                  {data?.type == "vehicle" && <InputField
                    label="Current KM"
                    type="number"
                    placeholder="Current KM"
                    className="col-6 align-items-center"
                    value={data?.expenseExtn?.currentKMs}
                    onChange={(v) => setData(prevData => ({
                      ...prevData,
                      expenseExtn: {
                        ...prevData.expenseExtn,
                        currentKMs: v
                      }
                    }))}
                    outline


                  />}
                </React.Fragment>
              }


              <div className='col-6 d-flex '>
                <InputField
                  placeholder={t("Invoice /Bill  Number ")}
                  value={data?.invoiceNo}
                  className={"col-6"}
                  outline
                  label={t("Invoice No./Bill No.")}
                  onChange={(v) => setData({ ...data, invoiceNo: v })}
                  error={data?.invoiceNo?.length === 0 ? t("Please enter Document Number") : ""}
                  showErr={hasErr}
                  type="text"
                />
                {<div className='col-11 mx-4'>
                  <InputField
                    placeholder={t("Supplier Name")}
                    value={data?.supplierName}
                    className={"col-6"}
                    outline
                    label={t("Supplier Name")}
                    onChange={(v) => setData({ ...data, supplierName: v })}
                    // error={data?.invoiceNo?.length === 0 ? t("Please enter Document Number") : ""}
                    // showErr={hasErr}
                    type="text"
                  />
                </div>}
                {/* {data?.type == "vehicle" && <div className='col-11 mx-4'>
                  <SupplierSelect
                    label={"Vehicle Supplier"}
                    value={data?.supplier_id}
                    onChange={(v) => setData({ ...data, supplier_id: v?.value })}

                    className="col-6"
                    placeholder="Vehicle"
                    guide
                  />
                </div>} */}
              </div>

              <div className='d-flex row align-items-center'>

                {!itemize && <div className='col-6' style={{ "height": "auto", }}>
                  <div className='row d-flex flex-column '>
                    <div className='col-12 '>
                      <InputField
                        placeholder={t("Expense Head ")}
                        required
                        outline
                        value={data?.items?.[0]?.head}
                        // required={true}
                        label={t("Expense Head")}
                        onChange={(v) => setData(
                          {
                            ...data,
                            items: [{ ...data?.items?.[0], head: v }]
                          })}
                        error={data?.items?.[0]?.head?.length === 0 ? t("Please enter Expense Head name") : ""}
                        showErr={hasErr}
                        type="text"
                      />

                    </div>
                    <div className="col-sm-12 d-flex align-items-center pe-0">
                      <div style={{ width: "120px", fontSize: 12, fontWeight: "500", marginRight: 0 }}>
                        {"Amount"}
                        {<font color="red">*</font>}
                      </div>
                      <CurrencyAmountField100
                        placeholder={t("Amount")}
                        required={true}
                        outline
                        value={
                          {
                            amount: ((data?.items?.[0]?.unitCost ?? 0.00)).toFixed(2),
                            currency: data?.currency,
                          }
                        }
                        label={t("Amount")}
                        onChange={(v) => {
                          const updatedItem = {
                            ...data?.items?.[0],
                            units: 100,
                            unitCost: Number(v?.amount),
                            subtotal: ((Number(v?.amount)) * (data?.items?.[0]?.units || 100) / 100),
                            total: ((Number(v?.amount)) * (data?.items?.[0]?.units || 100) / 100) + (data?.items?.[0]?.taxAmount || 0),
                            taxAmount: (data?.items?.[0]?.taxAmount ? data?.items?.[0]?.taxAmount : 0)
                          };
                          setData({
                            ...data,
                            items: [updatedItem]
                          });
                        }}
                        error={data?.items?.[0]?.subtotal?.length === 0 ? t("Please enter Subtotal Amount") : ""}
                        showErr={hasErr}
                        type="text"
                        className={"flex-1"}
                      />
                    </div>

                    <div className="col-sm-12 d-flex align-items-center">
                      <div style={{ width: "120px", fontSize: 12, fontWeight: "500", marginRight: 0 }}>
                        {"Tax"}
                        {<font color="red">*</font>}
                      </div>
                      <AmountField
                        placeholder={t("Tax Amount ")}
                        value={((data?.items?.[0]?.taxAmount ?? 0.00)).toFixed(2)}
                        outline
                        label={t("Tax")}
                        onChange={(v) => {
                          const updatedItem = {
                            ...data?.items?.[0],
                            taxAmount: (Number(v) ?? 0.00),
                            total: (data?.items?.[0]?.subtotal) + ((Number(v) ?? 0.00)),
                          };
                          setData({
                            ...data,
                            items: [updatedItem]
                          });
                        }}
                        error={data?.items?.[4]?.taxAmount?.length === 0 ? t("Please enter Tax Amount") : ""}
                        showErr={hasErr}
                        type="text"

                      />
                    </div>


                    <div className="col-6 d-flex justify-content-between mt-3 font-size-13" style={{ fontWeight: 'bold' }}>
                      <div>{t("Total")}</div>
                      <div style={{ marginLeft: "100px" }}> {data?.currency || "INR"} {_.round((data?.items?.[0]?.total ?? 0.00) / 100, 2).toFixed(2)}</div>
                    </div>

                  </div>

                </div>}
                <div className={itemize ? "col-12" : "col-6"}>
                  <div className="mb-2">{t("Note")}</div>
                  <textarea className="inputfield" rows={10} style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", resize: "none" }}
                    value={data?.notes}
                    onChange={(v) => setData({
                      ...data,
                      notes: v.target.value
                    })}
                  >
                  </textarea>
                </div>

              </div>




              {!itemize &&
                <Row className="d-flex justify-content-start mt-2">
                  <Checkbox
                    label={t("Itemize")}
                    className=""
                    checked={itemize}
                    onClick={() => { setItemize(!itemize) }}
                  />
                </Row>}

            </Row>
            {
              itemize && (
                <div className='row'>
                  <div className='col-6'>
                    <CurrencySelect
                      required
                      outline
                      placeholder={t("Select Currency")}
                      value={data?.currency}
                      label={t("Currency")}
                      onChange={(v) => setData({ ...data, currency: v.value })}
                    />
                  </div>
                  <div className="col-6 d-flex justify-content-start mt-2">
                    <Checkbox
                      label={t("Itemize")}
                      className=""
                      checked={itemize}
                      onClick={() => { setItemize(!itemize) }}
                    />
                  </div>
                </div>
              )
            }

            {
              itemize &&
              <>
                <div className="mt-5" />
                <Items
                  itemize={itemize}
                  data={data}
                  items={data?.items}
                  onChange={(bankaccnts) => {
                    setData({
                      ...data, items: bankaccnts,
                      total: getTotalamount(bankaccnts)
                    })
                  }}
                  onChangeExpenseHead={(v) => {
                    setData({
                      ...data, expenseHead: {
                        tag_id: v
                      }
                    })
                  }}
                />
              </>
            }
            {
              editId ?
                <Row className="mt-5" >
                  <Documents
                    title="Attachment"
                    module={"expense"}
                    record_id={data?._id}
                    actionButtons={false}
                  />
                </Row>
                :
                null
            }

          </Form >
          <div className="zr_layout-footer d-flex justify-content-end pe-4" style={{ flexShrink: 0, marginBottom: insidePane ? "20px" : "0px" }}>

            {editId ? (
              <AuditLogDialog module="Expense" id={editId} />
            ) : null

            }
            {editId && userContent?.rights?.includes(4403) ?
              <DeleteButton
                loading={loading}
                onClick={(e) => handleDelete(e, data?._id)} />
              : null}
            {userContent?.rights?.includes(4402) ?
              <SaveButton
                loading={loading}
                onClick={handleSave} />
              : null}

          </div>
        </div>
      }
    </React.Fragment >
  );
};

export default observer(EditExpenses);

