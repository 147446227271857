import { CompanySelect, SupplierSelect } from "../../components";

const paidTo = {
    1: "Paid To Vendor",
    2: "Paid To Driver",
    3: "Paid To Agent",
    4: "Paid to Vehicle Owner",
    5: "Others"
}

export const GridColumns = {
    version: 1,
    columns: [
        // {
        //     title: "Account ID",
        //     accessor: "",
        //     show: true,
        //     renderer: (dataObj) => {
        //         return <div>
        //             {dataObj?.account_id}
        //         </div>;
        //     },
        // },
        {
            title: "Date",
            accessor: "date",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    {(dataObj?.date?.toString())?.slice(6, 8)}-{(dataObj?.date?.toString())?.slice(4, 6)}-{(dataObj?.date.toString())?.slice(0, 4)}
                </div>;
            },
        },
        {
            title: "Paid To",
            accessor: "paidTo",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    {paidTo[dataObj?.paidTo]}
                </div>;
            },
        },
        {
            title: "Status",
            accessor: "status",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    {dataObj?.status === 0 ? "Pending" : "Done"}
                </div>;
            },
        },

        {
            title: "Supplier",
            accessor: "supplier",
            renderer: (dataObj) => {
                return <div>
                    <SupplierSelect
                        asLabel
                        value={dataObj?.supplier_id}
                        onChange={(v) => { }}
                        className=""
                    />
                </div>;
            },
            show: true,
        },

        {
            title: "Company",
            accessor: "company",
            renderer: (dataObj) => {
                return <div>
                    <CompanySelect
                        asLabel
                        corpType="supplier"

                        value={dataObj?.supplierCompany_id}
                        onChange={(v) => { }}
                        className=""
                    />
                </div>;
            },
            show: true,
        },

        {
            title: "Total",
            accessor: "total",
            renderer: (dataObj) => {
                return <div>

                    {dataObj?.currency} {dataObj?.total}
                </div>;
            },
            show: true,
        },

        {
            title: "Amount Paid",
            accessor: "amountPaid",
            show: true,
        },
        {
            title: "Ref No",
            accessor: "refNo",
            show: true,
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
