import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import {
  CardThreeBounce, DateSelect, CompanySelect, InputField, StatusSelect, SupplierSelect, CurrencySelect
} from "../../components";
import SupplierCompany from "../../components/Dropdowns/SupplierCompany/SupplierCompany";

import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import Items from "./Billitems";
import BillsService from "./BillsService";
import moment from "moment";

const EditBills = ({ editId, onChange = () => { }, hasErr }) => {

  const newData = {
    currency: "INR",
    status: 0
  }
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(newData);

  const fetch = async () => {
    try {
      let bill = await BillsService.get(editId);
      setData(bill);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(newData)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      {loading ?
        <CardThreeBounce /> :


        <Form
          className="p-3 pt-2"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="">
            <InputField
              placeholder={t("Bill Number")}
              value={data?.billNo}
              required={true}
              label={t("Bill Number")}
              onChange={(v) => setData({ ...data, billNo: v })}

              error={data?.billNo?.length === 0 ? "Please enter bill number " : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-6"
            />
          </Row>
          <Row>
            <DateSelect
              placeholder={t("Bill Date")}
              value={data?.billDate}
              label={t("Bill Date")}
              onChange={(v) => setData({ ...data, billDate: v })}
              error={data?.billDate?.length === 0 ? t("Please enter Bill Date") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-6"
            />
          </Row>
          <Row>
            <DateSelect
              placeholder={t("Due Date")}
              value={data?.dueDate}
              label={t("Due Date")}
              onChange={(v) => setData({ ...data, dueDate: v })}
              error={data?.dueDate?.length === 0 ? t("Please enter Due Date") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-6"
            />
          </Row>
          <Row >
            <StatusSelect
              placeholder={t("Status")}
              value={data?.status}
              required={true}
              label={t("Status")}
              onChange={(v) => setData({
                ...data, status: v?.value ? v?.value : 0
              })}
              type="text"
              className="col-sm-6"
            />
          </Row>
          <Row>
            <SupplierCompany
              required={true}
              value={data?.supplier_id}
              className="col-sm-6"
              onChange={(v) => setData(
                {
                  ...data,
                  supplierCompany_id: v?.value,
                  supplier_id: v?.supplier_id
                }
              )}
            />
          </Row>
          <Row>
            <CurrencySelect
              required={true}
              placeholder={t("Select Currency")}
              value={data?.currency}
              label={t("Currency")}
              onChange={(v) => setData({ ...data, currency: v?.value ? v?.value : 'INR' })}
              className="col-sm-6"
            />
          </Row>
          <div className="mt-5">
            <Items
              items={data?.items}
              data={data}
              onChange={(billItem) => {
                setData({
                  ...data,
                  items: billItem,
                  subTotal: billItem?.reduce((subTotal, item) => subTotal + parseFloat(item.subTotal || 0), 0)
                })
              }}
            />
          </div>
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditBills);
