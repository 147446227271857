import { useContext, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { I18nContext } from "../../../../store/context/i18nContext";
import OnDemandMessageForTrip from "./OnDemandMessageForTrip";
import { ModalComponent } from "../../../../components";

const MessageDialog = ({ trip, toggleModal, modal }) => {
  const { t } = useContext(I18nContext);
  return (
    <>
      <ModalComponent
        position={"top"}
        size={"medium"}
        isOpen={modal}
        onToggle={() => toggleModal()}
        modalSizeStyle={{ height: "700px" }}
      >
        <ModalComponent.Title>
          <h1>Send Booking Confirmation</h1>
        </ModalComponent.Title>
        <ModalComponent.Body>
          <OnDemandMessageForTrip toggleModal={toggleModal} trip={trip} />
        </ModalComponent.Body>
      </ModalComponent>

    </>
  );
};
const ModalToggleButton = ({ t, toggleModal }) => {
  return (
    // <div className="d-flex justify-content-end my-2">
    <Button
      onClick={toggleModal}
      className="w-100"
      style={{ height: "28px", padding: "2px 10px 2px 4px" }}
      color="info"
    >

      {t(`Send Message`)}
    </Button>
    // </div>
  );
};
export default MessageDialog;
