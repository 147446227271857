import { FaCircleCheck } from "react-icons/fa6";

export const GridColumns = {
    version: 1,
    columns: [
        {
            title: " Name",
            accessor: "name",
            show: true,
        },
        {
            title: "Country",
            accessor: "country",
            show: true,
        },
        {
            title: "State",
            accessor: "state",
            show: true,
        },
        {
            title: "Latitude",
            accessor: "lat",
            show: true,
        },
        {
            title: "Longitude",
            accessor: "lng",
            show: true,
        },
        {
            title: "Geo Hash",
            accessor: "geohash",
            show: true,
        },
        {
            title: "Approved",
            accessor: "approved",
            renderer: (dataObj) => {
                return <div>
                    {dataObj?.approved === true ? <FaCircleCheck color={"green"} size={15} /> : null}
                </div>;
            },
            show: true,
        },
        {
            title: "Has Airport",
            accessor: "hasAirport",
            renderer: (dataObj) => {
                return <div>
                    {dataObj?.hasAirport === true ? <FaCircleCheck color={"green"} size={15} /> : null}
                </div>;
            },
            show: true,
        },
        {
            title: "Has Train Station",
            accessor: "hasTrainStation",
            renderer: (dataObj) => {
                return <div>
                    {dataObj?.hasTrainStation === true ? <FaCircleCheck color={"green"} size={15} /> : null}
                </div>;
            },
            show: true,
        },
        {
            title: "Popular",
            accessor: "popular",
            renderer: (dataObj) => {
                return <div>
                    {dataObj?.popular === true ? <FaCircleCheck color={"green"} size={15} /> : null}
                </div>;
            },
            show: true,
        },
        {
            title: "Synonyms ",
            accessor: "synonyms",
            show: true,
            width: 600,

        },

        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
