import { ApiHandler } from "../store/apiHandler/apiHandler";


class TokenUtil {
    constructor() {
        this.isProcessing = false;
        this.queue = [];
    }



    prepareHeaders(reqParam, contentType, acceptType, token) {
        const headers =
            reqParam instanceof FormData
                ? {
                    Authorization: token,
                    "x-api-key": "web",
                }
                : {
                    Accept: acceptType,
                    "x-api-key": "web",
                    "Content-Type": contentType,
                    Authorization: token,
                };
        return headers;
    }

    async updateToken() {
        try {


            if (this.isProcessing) {
                // If the function is already being processed, queue the call
                return new Promise(resolve => this.queue.push(resolve));
            }

            this.isProcessing = true;
            console.log("calling refresh token...")
            const newBearerTokenResponse = await ApiHandler({
                method: "GET", endPoint: "/auth/refresh-token",
                token: localStorage.getItem("RefreshToken") || ""
            });
            console.log("calling refresh token newBearerTokenResponse...", newBearerTokenResponse?.status, newBearerTokenResponse)
            if (!newBearerTokenResponse || newBearerTokenResponse?.status === 401) {
                localStorage.clear();
                window.location = "/";
                throw new Error('Unauthorized')
            }

            localStorage.setItem("BearerToken", newBearerTokenResponse?.data?.bearerToken);
            localStorage.setItem("TokenExpiry", newBearerTokenResponse?.data.expiry);

            while (this.queue.length > 0) {
                const resolve = this.queue.shift();
                resolve(newBearerTokenResponse);
            }
            this.isProcessing = false;
            return newBearerTokenResponse;
        } catch (error) {
            this.isProcessing = false;
            localStorage.clear();
            window.location = "/";
            this.queue = [];
            throw error
        }

    }

    async getBearerToken() {
        try {
            let expiry = localStorage.getItem("TokenExpiry");
            let token = localStorage.getItem("BearerToken")
            if (token == null) {
                return null;
            }
            return token;
        } catch (error) {
            console.error('Error processing function:', error);
        }
    }

    async callAPI({ endPoint, method, reqParam = {}, token }, retrying = false) {
        const acceptType = "application/json";
        const contentType = reqParam instanceof FormData ? "multipart/form-data" : "application/json";
        let response = null;
        try {
            let content = {
                method: method,
                headers: this?.prepareHeaders(reqParam, contentType, acceptType, token),
            };

            if (method === "POST" || method === "DELETE" || method === "PATCH" || method === "PUT") {
                content = { ...content, body: reqParam instanceof FormData ? reqParam : JSON.stringify(reqParam) };
            }

            if (reqParam instanceof FormData) {
                let headers = this?.prepareHeaders(reqParam, contentType, acceptType, token);
                response = await fetch(endPoint, { method, body: reqParam, headers });
            } else {
                response = await fetch(endPoint, content);
            }
        } catch (e) {
            console.log(e, 'error');
        }

        if (response) {
            const responseContentType = response.headers.get("Content-type");

            let data = null;

            if (responseContentType === null) data = { message: "success" }
            else if (responseContentType === "application/json" || responseContentType?.includes("application/json"))
                data = await response.json();
            else if (responseContentType.includes("text/plain") || responseContentType.includes("text/html"))
                data = await response.text();
            else if (responseContentType.includes("application/zip"))
                data = await response.blob();
            else if (responseContentType === "application/pdf") {
                data = await response.blob();
            } else if (responseContentType === "application/vnd.ms-excel") {
                data = new Blob([await response.blob()], { type: responseContentType });
            } else if (responseContentType === "application/zip") {
                data = await response.blob();
            }
            response = {
                data: data,
                status: response?.status,
                statusText: response?.statusText,
                headers: response.headers,
            };
        }

        if (response?.status === 401 && !retrying) {
            const newBearerTokenResponse = await this.updateToken();
            if (newBearerTokenResponse?.status != 401) {
                const resp = await this.callAPI({ endPoint, method, reqParam, token: newBearerTokenResponse?.data?.bearerToken }, true);
                return resp;
            }
        }
        return response;
    };

}

export const tokenUtil = new TokenUtil();
