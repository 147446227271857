import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./WhatsAppLogsConstant";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { getUiPrefRows } from "../../util/FilterUtil";

class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = null;
  filterOption = "";
  total = -1;
  version = 0;
  type = "sms"
  smsType = "SMS"
  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterUrl, type, smsType) => {
    if(!this?.rowsPerPage) {
      const rows = await getUiPrefRows("WhatsAppLogslogs.grid")
     this.rowsPerPage = rows ?? 40
    }

    if (filterUrl) {
      this.filterOption = filterUrl;
    }

    if (type) {
      this.type = type;
    }

    if (smsType) {
      this.smsType = smsType
    }

    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl, this?.type, this?.smsType)
    );

    if (response?.status === 200) {
      this.records = response.data.rows;
      this.page = response?.data?.page;
      if (this.page === 1) this.total = response?.data?.total;
    }
  };


  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);
  };

}

const LogsService = new Service();
export default LogsService;

