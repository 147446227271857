import React from 'react'
import { useNavigate } from 'react-router-dom'
import { BidsIcon, BidsIcon2, MyRfpIcon, MyRfpIcon2, QueriesIcon, QueriesIcon2, RfpIcon, RfpIcon2, SupplierIcon, SupplierIcon2 } from '../../assets/images'

const RfpTabs = ({ setselectedTab = () => { }, setSuppliarModal = () => { }, selectedtab = 'RFP' }) => {

    const navigate = useNavigate()
    return (
        <div className=" d-flex mt-2 ">
            <div onClick={() => {
                setselectedTab("RFP")
                navigate('/marketplace/dashboard')
            }} className={`${selectedtab === "RFP" ? "selected-market-tab-active " : "selected-market-tab"} d-flex justify-content-center cursor-pointer py-3 px-2`}>
                <img className=' px-2 text-dark' src={selectedtab === "RFP" ? RfpIcon : RfpIcon2} alt="" />RFPs</div>
            <div onClick={() => {
                setselectedTab("Bids")
                navigate('/marketplace/bids/dashboard')
            }} className={`${selectedtab === "Bids" ? "selected-market-tab-active " : "selected-market-tab"} d-flex cursor-pointer justify-content-center py-3 px-2`}>
                <img className=' px-2' style={{ color: "white" }} src={selectedtab === "Bids" ? BidsIcon2 : BidsIcon} alt="" />Bids</div>
            <div onClick={() => {
                setselectedTab("MyRFP")
                navigate('/marketplace/myrfp/dashboard')
            }} className={`${selectedtab === "MyRFP" ? "selected-market-tab-active " : "selected-market-tab"} d-flex cursor-pointer justify-content-center py-3 px-2`}>
                <img className=' px-2' src={selectedtab === "MyRFP" ? MyRfpIcon2 : MyRfpIcon} alt="" />My RFP</div>
            <div onClick={() => {
                setselectedTab("Queries")
                navigate('/marketplace/queries/dashboard')
            }} className={`${selectedtab === "Queries" ? "selected-market-tab-active " : "selected-market-tab"} d-flex cursor-pointer justify-content-center py-3 px-2`}>
                <img className=' px-2' src={selectedtab === "Queries" ? QueriesIcon2 : QueriesIcon} alt="" />Queries</div>
            <div onClick={() => {
                navigate('/marketplace/supplier/dashboard')
                setselectedTab("Suppliers")

            }
            } className={`${selectedtab === "Suppliers" ? "selected-market-tab-active " : "selected-market-tab"} d-flex cursor-pointer justify-content-center py-3 px-2`}>
                <img className=' px-2' src={selectedtab === "Suppliers" ? SupplierIcon2 : SupplierIcon} alt="" />Suppliers</div>
        </div >
    )
}

export default RfpTabs