import moment from "moment";

export const getMessageTime = (sentAt) => {
    const itemDate = moment(sentAt * 1000);
    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    if (itemDate.isSame(today, 'day')) {
        return "TODAY";
    } else if (itemDate.isSame(yesterday, 'day')) {
        return "YESTERDAY";
    } else if (today.diff(itemDate, 'days') <= 7) {
        return itemDate.format("dddd").toUpperCase();
    } else {
        return itemDate.format("DD/MM/YYYY");
    }
};
