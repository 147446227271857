import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./HelplineConstant";
import { doGET, doPUT } from "../../../../util/HttpUtil";

class Service {

  records = [];
  totalRecords = 0;
  places = [];
  page = 1;
  rows = 10;

  constructor() {
    makeAutoObservable(this);
  }



  getHelpLines = async function (id) {

    const response = await doGET(ENDPOINTS.getHelpLine)
    if (response.status === 200) {

      return response?.data;
    }
  };

  editHelpLines = async function (data, id) {
    const response = await doPUT(ENDPOINTS?.editHelpLine, data);
    if (response.status === 200) {
      return response?.data;
    }
  };

}

const HelplineService = new Service();
export default HelplineService;
