import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import socketInstance, { SOCKET_EVENT } from '../../../service/socket';
import { doGET } from '../../../util/HttpUtil';
import TripFlyout from '../Flyouts/TripFlyout';
import SideBarService from '../SideBarService';
import FlyoutButton from '../components/FlyoutButton';
import TripPopup from '../popups/TripPopup';
import { decodePolyline } from '../..';

const TripModule = ({ }) => {

    const [visibleFlyout, setVisibleFlyout] = useState(null) // all/live/dispatched
    const [trips, setTrips] = useState([])
    const [mappedTrips, setMappedTrips] = useState([])
    const [movements, setMovements] = useState([])

    const handleTripFlyoutButtonClick = useCallback((module) => {
        setVisibleFlyout(module);
    }, []);

    const handleCloseFlyout = useCallback(() => {
        setVisibleFlyout(null);
    }, [])

    const fetchTrips = async () => {
        try {
            const response = await SideBarService.fetchTrips();
            setTrips(response)
            setMappedTrips(response?.map((trip) => {
                return trip?.status == 4 ? { ...trip, kind: "live" } : { ...trip, kind: "dispatched" }
            }))
        } catch (error) {
            console.log("Error", error)
        }
    };

    const fetchMovements = async (trip_id) => {
        SideBarService?.setPath([])

        try {
            const response = await doGET(`/api/movement/grid?ref_id=in[${trip_id}]&assetType=TRIP`);

            if (response.status === 200) {
                setMovements(response.data.rows)
                const markers = []
                const path = []
                const formattedData = trips.map((trip, index) => {
                    const matchedRow = response?.data?.rows.find(row => row?.ref_id === trip?._id);
                    if (matchedRow) {
                        SideBarService?.setPath(decodePolyline(matchedRow?.path))
                        setUpSocketInteractions(trip?._id)
                        const combinedObject = {
                            ...matchedRow,
                            ...trip,
                            kind: trip?.status == 4 ? "live"  : "dispatched" 
                        };
                        //pushing marker for combinedObject in markers array
                        markers.push({
                            coordinate: { lat: matchedRow?.current?.lat, lng: matchedRow?.current?.lng },
                            popupComponent: <div><TripPopup trip={combinedObject} /></div>,
                            markerImage: require("./../../../../src/assets/images/car-top.png"),
                            trip_id: trip?._id,
                            // on click of marker we need to select that marker, such that selected on table also 
                            onClick: () => {
                                SideBarService?.setSelectedMarkers({
                                    ...combinedObject,
                                    coordinate: { lat: combinedObject?.current?.lat, lng: combinedObject?.current?.lng },
                                    popupComponent: <div><TripPopup trip={combinedObject} /></div>,
                                    markerImage: require("./../../../../src/assets/images/car-top.png"),
                                    onClose: () => {
                                        SideBarService?.setSelectedMarkers(null)
                                    }
                                }
                                )
                            }
                        })

                        return combinedObject;
                    }
                    else {
                        return {
                            ...trip, kind: trip?.status == 4 ? "live" : "dispatched"}
                    };
                });
                setMappedTrips(formattedData);
                SideBarService?.setMarkers(markers)
            }

        } catch (error) {
            console.log("Error", error);
        }
    };


    useEffect(() => {
        fetchTrips()
    }, [])

    const selectTripFromGrid = () => {
        let tripFound = false
        trips.map((trip, index) => {
            const matchedRow = movements.find(row => row?.ref_id === SideBarService?.tripId);
            if (matchedRow) {
                const combinedObject = {
                    ...matchedRow,
                    ...trip,
                    kind: trip?.status == 4 ? "live" : "dispatched" 
                };
                if (trip?._id == SideBarService?.tripId && combinedObject?.current?.lat && combinedObject?.current?.lng) {
                    tripFound = true
                    SideBarService?.setSelectedMarkers({
                        ...combinedObject,
                        coordinate: { lat: combinedObject?.current?.lat, lng: combinedObject?.current?.lng },
                        popupComponent: <div><TripPopup trip={combinedObject} /></div>,
                        markerImage: require("./../../../../src/assets/images/car-top.png"),
                        onClose: () => {
                            SideBarService?.setSelectedMarkers(null)
                        }
                    })
                }

            }
        })

        if (!tripFound) {
            SideBarService?.setSelectedMarkers({ _id: SideBarService?.tripId })
        }
    }

    useEffect(() => {
        if (SideBarService?.tripId && trips?.length && movements?.length) {
            setVisibleFlyout("all")
            selectTripFromGrid()
        }
    }, [SideBarService?.tripId, trips, movements])

    useEffect(() => {
        if (SideBarService?.tripId && trips?.length) {
            fetchMovements(SideBarService?.tripId)
        }
    }, [SideBarService?.tripId, trips])


    const setUpSocketInteractions = async (trip_id) => {
        try {
            let socket = socketInstance.getSocket();

            if (SideBarService?.socketTripId) {
                socket?.emit('leaveRoom', 'trip-' + SideBarService?.socketTripId, (response) => {
                    console.log("room leave response:", response);
                    SideBarService?.setSocketTripId(null)
                });
            }

            if (!socket) {
                socket = await socketInstance.initializeSocket();
            }

            socket?.emit('joinRoom', 'trip-' + trip_id, (response) => {
                SideBarService?.setSocketTripId(trip_id)
                console.log("trip room join response:", response);
            });

            socket?.off(SOCKET_EVENT.LOCATION_UPDATE)
            socket?.on(SOCKET_EVENT.LOCATION_UPDATE, (data) => {
                //TODO update location of driver
                console.log("location update:", data)
                if (!data) {
                    return;
                }

                if (data.lat && data.lng) { 
                    SideBarService?.setCenter({ lat: data.lat, lng: data.lng })
                    console.log("location update:", data)
                    const prevMarkers = SideBarService?.markers;
                    prevMarkers?.forEach((markerItem, markerIndex) => {
                        if (markerItem?.trip_id == data?.trip_id) {
                            prevMarkers[markerIndex] = { ...prevMarkers[markerIndex], coordinate: { lat: data.lat, lng: data.lng } }
                        }
                    })
                    SideBarService?.setMarkers(prevMarkers)
                    SideBarService?.setSelectedMarkers({})
                    SideBarService?.setPath([...(SideBarService?.path ?? []), { lat: data.lat, lng: data.lng }])
                }

            })

            socket?.on(SOCKET_EVENT.TRIP_STOP, (data) => {
                //TODO update location of driver
                console.log("trip stop :", data);
                if (!data) {
                    return;
                }
                // navigation.navigate(NavName.bookings) 
            })
        } catch (error) {
            console.log(error);
        }
    }



    const filterTrip = (visibleFlyout, status) => {
        switch (visibleFlyout) {
            case 'all':
                return true;
            case 'live':
                return status === 4;
            case 'dispatched':
                return status === 3
            default:
                return false;
        }
    }

    return (
        <div >
            <FlyoutButton
                onClick={() => { handleTripFlyoutButtonClick("all") }}
                text="All Trips"
                // icon={<FaCarAlt className="me-2 font-size-14" />}
                className="m-2"
                active={visibleFlyout == "all"}
            />
            <FlyoutButton
                onClick={() => { handleTripFlyoutButtonClick("live") }}
                text="Live"
                statusColor="#CFFD69"
                // icon={<FaCarAlt className="me-2 font-size-14" />}
                className="m-2"
                active={visibleFlyout == "live"}

            />
            <FlyoutButton
                onClick={() => { handleTripFlyoutButtonClick("dispatched") }}
                text="Dispatched"
                statusColor="#FFBF00"
                // icon={<FaCarAlt className="me-2 font-size-14" />}
                className="m-2"
                active={visibleFlyout == "dispatched"}
            />

            {visibleFlyout ? <div className="mx-2">
                <TripFlyout
                    data={mappedTrips?.filter(trip => {
                        const { status } = trip;
                        return filterTrip(visibleFlyout, status);
                    })}
                    visibleFlyout={visibleFlyout}
                    onClose={handleCloseFlyout}
                /></div>
                : null}
        </div>
    );
};

export default observer(TripModule);



