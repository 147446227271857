import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { observer } from "mobx-react-lite";
import { Pagination, Breadcrumbs, Filter, Layout } from "../../components";
import SOSLogService from "./SOSLogService";
import { STRUCTURE } from "./SOSLogConstant";
import { DialogContext } from "../../store/context/DialogContext";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Badge,
  Row,
} from "reactstrap";
import Map from "./Map";
import Comment from "./Comment";
import { t } from "i18next";


const SOSLog = () => {

  const { id } = useParams();
  const { showConfirm, showMessage, showError } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(id);

  useEffect(() => {
    try {
      SOSLogService.fetch();
    } catch (e) {
      showError(e)
    }
  }, []);

  const fetchData = async (values) => {
    await SOSLogService.fetch(values);
  };
  const [modal, setModal] = useState(false);
  const [comment, setComment] = useState({ _id: "", status: "", comment: "" });
  const [comments, setComments] = useState([]);
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [loading, setLoading] = useState(false);
  const getDateAndTimeString = (ms) => {
    const d = new Date();
    d.setTime(ms * 1000);
    return d.toDateString() + "  " + d.toLocaleTimeString();
  };

  const getClassForStatus = (sts) => {
    switch (sts) {
      case 0:
        return "bg-warning";
      case 1:
        return "bg-success";
      case 2:
        return "bg-primary";
      default:
        return "bg-info";
    }
  };

  const handleCommentSubmit = async (data) => {
    SOSLogService.updateSOSLog(data);
    setModal(false);
    fetchData();
  };

  return (
    <>
      {comment._id && (
        <Comment
          // _id={comment._id}
          setComment={setComment}
          submitFunc={handleCommentSubmit}
          comment={comment}
          comments={comments}
          modal={modal}
          setModal={setModal}
        />
      )}
      <Layout
        showDetailPage={showDetailPage}
         
        title={t("SOSLog")}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}

        page={SOSLogService.page}
        rows={SOSLogService.rows}
        total={SOSLogService.totalRecords}
        onPageChange={async (page, rows) => {
          await SOSLogService.onPaginationChange(page, rows);
        }}
      >
        {/* <div>

             <Td className="col-2">
                                <Map
                      showMarker={true}
                      mapLocation={location}
                      markerLocation={location}
                      // mapStyle={MapStyles}
                      onMapMoved={() => { }}
                    />
                                </Td>
      </div> */}

        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th className="col-2">Name/Phone</Th>
              <Th className="col-3">Address</Th>
              <Th className="col-2" >Time</Th>
              <Th className="col-2"> Status</Th>
              <Th className="col-2">Detail</Th>

            </Tr>
          </Thead>
        </Layout.TheadFull>


        <Layout.TbodyFull >

          <Tbody>
            {SOSLogService.records?.map((sosLog) => (
              <Tr
                key={sosLog?._id}
                className={`${selected_id === sosLog?._id ? "bg-primary color-fff" : ""}`}
              >
                <Td scope="row">
                  {sosLog?.name} | +{sosLog?.countryCode}
                  {sosLog?.phone}
                </Td>

                <Td>{sosLog?.address}</Td>
                <Td>{getDateAndTimeString(sosLog?.at)}</Td>
                <Td>
                  <Badge
                    className={`rounded-pill ${getClassForStatus(
                      sosLog?.status
                    )}`}
                  >
                    {sosLog?.statusStr}
                  </Badge>
                </Td>
                <Td>
                  <i
                    role={"button"}
                    onClick={() =>
                      setLocation({
                        lat: sosLog.lat,
                        lng: sosLog.lon,
                      })
                    }
                    className="fa fa-map"
                    aria-h_idden="true"
                  ></i>
                  <br />
                  <i
                    role={"button"}
                    onClick={() => {
                      setModal(true);
                      setComments(sosLog.comments);
                      setComment({
                        _id: sosLog._id,
                        status: sosLog.status,
                      });
                    }}
                    className="fa fa-reply"
                    aria-h_idden="true"
                  ></i>
                </Td>



              </Tr>
            ))}

          </Tbody>






        </Layout.TbodyFull>









        {/* <Layout.TbodyCompact>
        <Tbody>
          {SOSLogService.records?.map((driver) => (
            <Tr
              className={selected_id === driver?._id && "table-row-selected"}
              style={{ cursor: "pointer" }}
              key={driver?._id}
              onClick={showDetailPage && (() => handleRowClick(driver?._id))}
            >
              <Td> {driver?.name}</Td>
              <Td>
                {" "}
                {driver?.countryCode} - {driver?.phone}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyCompact> */}

        {/* <Layout.DetailPage>
        <EditDriver
          insidePane={props?.insidePane}
          onChange={(v) => setIsEditDataChanged(v)}
          id={selected_id}
          back={() => {
            setShowDetailPage(false);
            if (!props?.insidePane) navigate("/driver");
            setSelected_id(false);
          }}
          fetchData={fetchData}
        />
      </Layout.DetailPage> */}
      </Layout>
    </>

  );
};
export default observer(SOSLog);
