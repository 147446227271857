import moment from "moment";
import AccountLabel from "../../components/Dropdowns/AccountSelect/AccountLabel";
import { getYYYYMMDDToDateString } from "../../util/Util";
export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Account Name",
            accessor: "accountname",
            show: true,
            renderer: (dataObj) => {
                return <div>
                  <AccountLabel value={dataObj?.account_id} />
                </div>;
              },

        },
        {
            title: "Added From",
            accessor: "addedFrom",
            show: true,
            renderer: (dataObj) => {
                return <div>{dataObj?.addedFrom}</div>;
            },

        },

        {
            title: "Transaction Date ",
            accessor: "transactionDate",
            renderer: (dataObj) => {
                return <div>{getYYYYMMDDToDateString(dataObj?.transactionDate)}</div>;
            },
            show: true,

        },
        {
            title: "Created At",
            accessor: "createdAt",
            renderer: (dataObj) => {
                return <div>{moment.unix(dataObj?.createdAt).format("DD-MMM HH:mm")}</div>;
            },
            show: true,

        },

        {
            title: "Reference No ",
            accessor: "referenceNo",
            show: true,

        },
        {
            title: "Credit",
            accessor: "credit",
            show: true,
        },

        {
            title: "Debit",
            accessor: "debit",
            show: true,
        },

        {
            title: "Balance",
            accessor: "balance",
            show: true,
        }
    ],
};
