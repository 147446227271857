import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from "react"
import { connect } from "react-redux"


// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

//i18n
import { withTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import inbox from '../../assets/images/icons/inbox.svg'
import danger from '../../assets/images/icons/danger.png'
import gmail from '../../assets/images/icons/gmail.png'
import message from '../../assets/images/icons/message.png'
import sms from '../../assets/images/icons/sms.png'
import whatsapp from '../../assets/images/icons/whatsapp.png'
import map from "../../assets/images/icons/map.png"
import userGuide from '../../assets/images/icons/user-guide.png'
import InboxService from '../../pages/Inbox/InboxService'
import Tickets from '../../pages/Tickets/Tickets'
import { UserContext } from '../../store/context/UserContext'
import CrossButton from '../Buttons/CrossButton'
import AllProfileDropdown from '../CommonForBoth/TopbarDropdown/AllProfileDropdown'
import LanguageMenu from "../CommonForBoth/TopbarDropdown/LanguageSelect/LanguageMenu"
import MenuDropdown from "../CommonForBoth/TopbarDropdown/MenuDropdown"
import SideBarService from './SideBarService'
import userGuideService from './UserGuide/UserGuideService'
import { doGET } from '../../util/HttpUtil'
import MarketPlaceMainFile from '../../pages/MarketPlace/MarketPlaceComponents/MarketPlaceMainFile';
import marketplace from '../../assets/images/marketPlace.png'
import { pathItems } from '../../common/utils/Constants'
import { t } from 'i18next'
import Breadcrumb from '../Breadcrumb/Breadcrumb'
import Inbox from '../../pages/Inbox/Inbox'
import { DialogContext } from '../../store/context/DialogContext'


// import { noOptionsMessageCSS } from 'react-select/dist/declarations/src/components/Menu'


const Header = props => {
  const [isSearch, setSearch] = useState(false)
  const [inboxCount, setInboxCount] = useState(0)
  const [isTicketOpen, setIsTicketOpen] = useState(false)
  const [activeLink, setActiveLink] = useState("");
  const [showMartketPlace, setShowMartketPlace] = useState(false);
  const { userContent } = useContext(UserContext);
  const { showError } = useContext(DialogContext);

  let navigate = useNavigate();

  const fetchMarketPlace = async () => {
    try {
      const response = await doGET(`/api/corporate-config/detail/showMarketPlace`,)
      if (response.status == 200 && response.data?.showMarketPlace) {
        setShowMartketPlace(response.data?.showMarketPlace)
      }
    } catch (error) {
      console.log(error)
    }

    // setShowMartketPlace(!showMartketPlace)
  }


  useEffect(async () => {
    if (!userContent?.rights?.includes(1)) {
      const count = await SideBarService.fetchInboxCount();
      setInboxCount(count);
    }
    fetchMarketPlace();
  }, []);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  useEffect(() => {
    setActiveLink(window.location.pathname.split('/')[1])
  }, [window.location.pathname])
  return (
    <React.Fragment>
      <div className="navbar-header d-flex justify-content-between align-items-center">
        <div className='d-flex align-items-center'>
          <AllProfileDropdown asLabel />
          <div> <MenuDropdown /></div>
          <div className='mt-2'>
            {/* <div>{t(pathItems[activeLink])}</div> */}
            <Breadcrumb title={pathItems[activeLink]} />
          </div>
        </div>
        <div className="d-flex">
          {/* <div className="dropdown d-inline-block d-lg-none ms-2">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-search-dropdown"
              onClick={() => setSearch(!isSearch)}
            >
              <i className="uil-search"></i>
            </button>
            <div
              className={
                isSearch
                  ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                  : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
              }
              aria-labelledby="page-header-search-dropdown"
            >
              <form className="p-3">
                <div className="form-group m-0">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={props?.t("Search") + "..."}
                      aria-label="Recipient's username"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div> */}


          {!userContent?.rights?.includes(1) ?
            <div className='cursor-pointer d-flex align-items-center mx-4 justify-content-center pt-1 position-relative' onClick={() => { navigate("/crm/email") }} >
              {<img style={{ height: "22px", marginTop: "0px" }} src={message} />}
            </div>
            : null}

          {!userContent?.rights?.includes(1) ?
            <div className='cursor-pointer d-flex align-items-center me-4 ms-2 justify-content-center pt-1 position-relative' onClick={InboxService.toggleModal}>
              {/* <HiBellAlert size={22} color="red" /> */}
              {/* {activeLink != "inbox" ? <MdEmail size={22} /> : <HiOutlineMailOpen size={22} color="black" />} */}
              {<img style={{ height: "22px", marginTop: "0px" }} src={danger} />}
              {inboxCount &&
                <div style={{ position: "absolute", top: 21, right: -5, backgroundColor: "red", height: "15px", width: (inboxCount + "").length > 2 ? (((inboxCount + "").length + 1) * 5) + "px" : "15px", borderRadius: 10, display: 'flex', justifyContent: "center", alignItems: "center" }}>
                  <span className='text-white' style={{ fontSize: "8px" }}>{inboxCount}</span>
                </div>
              }
              {/* {<HiOutlineMailOpen size={22} />} */}
            </div>
            : null}




          {showMartketPlace ? <MarketPlaceMainFile marketplace={marketplace} /> : null}

          <div className='d-flex align-items-center'>
            {/* {SideBarService?.isMapLoadedFirstTime ? <div
              className={`${SideBarService?.isMapVisible
                ? " map-card "
                : " map-card map-card-hide "
                }`}
            >
              <SideMap />
            </div> : null
            } */}
            <div
              className={
                !isTicketOpen
                  ? "detail-page-modal-container-ticket "
                  : "detail-page-modal-container-ticket detail-page-modal-show-ticket "
              }
            >
              <CrossButton onClick={() => {
                setIsTicketOpen(!isTicketOpen);
              }} />
              {isTicketOpen && <Tickets insidePane={true} />}
            </div>

            {!userContent?.rights?.includes(1) ?
              <>
                <div className=" d-block">
                  <button
                    type="button"
                    style={{
                      marginTop: '2px'
                    }}
                    className=" btn-sidebar noti-icon waves-effect py-2 ps-2"
                    onClick={() => {
                      SideBarService?.toggleMap();
                    }}
                  >
                    <img style={{ height: "22px", marginTop: "0px" }} src={map} />
                  </button>
                </div>
              </> : null}
          </div>

          <div onClick={userGuideService.toggleModal} className='cursor-pointer ms-3 d-flex align-items-center justify-content-center pt-1'>
            {<img style={{ height: "22px", marginTop: "0px" }} src={userGuide} alt="user-guide" />}
          </div>

          <div style={{ marginLeft: '10px' }}><LanguageMenu /></div>
          <div style={{ marginTop: '2px' }}><ProfileMenu /></div>
        </div>
      </div>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
