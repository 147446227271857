import React from 'react';
import { getColor } from '../../pages/Dashboard/DashboardConstant'; // Assuming COLOR_MAP is imported from DashboardConstant
import { useNavigate } from 'react-router-dom';

function CountCard({ title,status, data, onClick, className, style }) {
    let navigate = useNavigate();
   
    return (
        <>
            {data && Object.keys(data).map((key, index) => (
                <div
                    onClick={() => onClick(data[key])}
                    key={key}
                    className={`px-2 py-2 flex-1 d-flex flex-column justify-content-between  ${className || ''}`}
                    style={{ ...styles(getColor(index, key)), ...style }}
                >
                    {data[key]?.details ? (
                        <>
                            <div className='d-flex justify-content-between'>
                                <div className='fw-bold truncate text-center col-8' style={{ fontSize: "14px", color: getColor(index, key) }}>{key}</div>
                                <div className='fw-bold text-center col-4' style={{ fontSize: "14px", color: getColor(index, key) }}>{data[key]?.metaValue}</div>
                            </div>
                            {data[key]?.details?.map((detail, detailIndex) => (
                                <div key={detailIndex} className='d-flex justify-content-between'>
                                    <div className='col-8 d-flex justify-content-between mt-0' style={{ color: "black", marginRight: "auto" }}>
                                        <div className='col-3 ms-0'>{detail?.renderer}</div>
                                        <div className='col-9 ms-0 pe-0 me-0'><b style={{"color": "#6b6b6b"}}>{detail?.label}</b></div>
                                    </div>
                                    <div className={`col-4 text-start`}><b>{detail?.value}</b></div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <span onClick={ title=="Vehicle"?()=>
                            {
                                navigate('/vehicle?status='+data[key]?.status)
                            }: ()=>{}
                        }>
                            <div className='fw-bold text-center' style={{ fontSize: "20px", color: getColor(index, key) }}>{data[key]?.metaValue}</div>
                            <div className='fw-bold truncate text-center' style={{ fontSize: "13px", color: getColor(index, key) }}>{key}</div>
                        </span>

                        
                    )}
                </div>
            ))}
        </>
    );
}

export default CountCard;

const styles = (color) => ({
    backgroundColor: "white",
    width: "fit-content",
    height: "fit-content",
    maxWidth: 200,
    minWidth: 130,
    minHeight: 80,
    color: color,
    cursor: "pointer"
});
