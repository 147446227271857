
import React, { useEffect, useState } from "react";
import OtpInput from "./OtpInput";

const SignupOtp = (props) => {
  const { onChange, getSignupOTP } = props;
  const [phoneDisabled, setPhoneDisabled] = useState(true);
  const [emailDisabled, setEmailDisabled] = useState(true);
  const [data, setData] = useState({ phoneOTPValue: " ", emailOTPValue: "" })

  useEffect(() => {
    onChange(data)
  }, [data?.phoneOTPValue, data?.emailOTPValue])


  return (
    <React.Fragment>
      <div className="mt-2 p-0 m-0 d-flex flex-column ">
        <div className="d-flex p-0 m-0 w-100 justify-content-between align-items-center">
          <div className="m-0 p-0 ms-2 ps-1 " style={{ minWidth: "180px" }}>
            <OtpInput
              label={<span style={{ fontSize: '16px' }}>Email OTP</span>}
              value={data?.emailOTPValue}
              onChange={(v) => {
                setData({ ...data, emailOTPValue: v })
              }}

              error={
                data?.emailOTPValue?.length === 0
                  ? "please enter the email otp"
                  : ""
              }
              className="font-size-18"
            />
          </div>
          <div className=" mt-4">
            <button
              className="ms-3 mb-3 mt-2 btn btn-primary waves-effect waves-light"
              style={{
                margin: "auto",
                borderRadius: "5px",
                padding: "7px",
                height: "43px",
                marginTop: "40x",
                width: "142px",
                fontSize: '16px'
              }}
              type="submit"
              onClick={() => {
                props.onresendOTPClick()
                setEmailDisabled(true);
                // getSignupOTP(1);
              }}
            >
              Resend OTP

            </button>
          </div>
        </div>
        <div>
          <div className="d-flex p-0 m-0 w-100 justify-content-between align-items-center">

            <div className="m-0 p-0 ms-2 ps-1 " style={{ minWidth: "180px" }}>

              <OtpInput
                label={<span style={{ fontSize: '16px' }}>Phone OTP</span>}
                value={data?.phoneOTPValue}
                onChange={(v) => {
                  setData({ ...data, phoneOTPValue: v })

                }}

                type="number"
                required
                error={
                  data?.phoneOTPValue?.length === 0
                    ? "please enter the phone otp"
                    : ""
                }
                className=" "
              />
            </div>
            <div className="mt-4">
              <button
                className="ms-3 mb-3 mt-2 btn btn-primary waves-effect waves-light"
                style={{
                  margin: "auto",
                  borderRadius: "5px",
                  padding: "7px",
                  height: "43px",
                  width: "142px",
                  fontSize: '16px'
                }}
                type="submit"
                onClick={() => {
                  props.onresendOTPClick()
                  setPhoneDisabled(true);
                  // getSignupOTP(2);
                }}
              >
                Resend OTP{" "}

              </button>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
  );
};

export default SignupOtp;
