import React from 'react'
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { Button } from 'reactstrap';

const MarketPlaceStep4 = ({ setStep }) => {
    return (
        <div style={{
            minHeight: '500px'
        }} className='d-flex flex-column justify-content-between align-items-center h-100 '>

            <div className='d-flex flex-column justify-content-center align-items-center flex-1'>
                <IoMdCheckmarkCircleOutline size={90} style={{
                    backgroundColor: "#17B117",
                    color: "white",
                    borderRadius: "50%",
                    padding: '5px'
                }} />
                <div className='my-2'>
                    <b className='font-size-16'>Registration Complete</b>
                </div>
                <div className='font-size-14'>
                    We will verify your application and activate your <br /> account. Our team may reach out to you in case more <br /> information required.
                </div>
            </div>

            <div className=' m-auto mb-5  d-flex justify-content-center align-items-center'>
                <Button style={{
                    width: "200px",
                    height: "30px"
                }} className="submit-btn d-flex align-items-center  justify-content-center" onClick={() => {
                    setStep(4)
                }}>
                    Okay
                </Button>
            </div>
        </div>
    )
}

export default MarketPlaceStep4