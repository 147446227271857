import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { CardThreeBounce, } from "../../components";
import TabV from "../../components/Common/Tab/Tab";
import { I18nContext } from "../../store/context/i18nContext";
import BookingSettings from "./Bookings/BookingSettings";
import EmailSetup from "./EmailSetup/EmailSetup";
import SmsSetup from "./SMS Setup/SmsSetup";
import TariffSettings from "./Tabs/TariffSettings";
import RouteSettings from "./Tabs/RouteSettings";
import MyVehicleTypes from "./Tabs/MyVehicleTypes";
import TagSelect from "../../components/Dropdowns/Tags/TagSelect";
import Languages from "../Configurations/Tabs/Languages/Languages";
import Helpline from "../Configurations/Tabs/Helpline/Helpline";
import JourneyGap from "../Configurations/Tabs/JourneyGap/JourneyGap";
import PaymentGateway from "../Configurations/Tabs/PaymentGateway/PaymentGateway";

import OtherSettings from "../Configurations/Tabs/OtherSettings/OtherSettings";
import ConvertCurrency from "../ConvertCurrency/ConvertCurrency";
import AuraSettings from "./Tabs/AuraSettings";

const Settings = (props) => {
  const [stepV, setStepV] = useState(1);
  const { t } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);

  console.log(setLoading)

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col className={`left-scroll-independent col-12`}>
            <Card className="mx-0 pt-4">
              <CardBody>
                <TabV
                  vertical
                  left
                  activeTab={stepV}
                  onChange={(data) => {
                    setStepV(data);
                  }}
                >
                  <div num={1} label={t("Booking Settings")}>
                    <BookingSettings />
                  </div>
                  <div num={2} label={t("Route Settings")}>
                    <RouteSettings />
                  </div>

                  <div num={3} label={t("Tariff Settings")}>
                    <TariffSettings />
                  </div>
                  <div num={4} label={t("Tags")}>
                    <TagSelect />
                  </div>
                  <div num={5} label={t("Payment Gateway")}>
                    <PaymentGateway />
                  </div>
                  <div num={6} label={t("My Vehicle Types")}>
                    <MyVehicleTypes />
                  </div>

                  <div num={7} label={t("Country Currency Mapping")}>
                    <ConvertCurrency />
                  </div>
                  <div className="p-0" num={8} label={t("Languages")}>
                    <Languages />
                  </div>
                  <div num={9} label={t("Jourrney Gap")}>
                    <JourneyGap />
                  </div>
                  <div num={10} label={t("Helpline")}>
                    <Helpline />
                  </div>
                  <div num={11} label={t("Email Setup")}>
                    <EmailSetup />
                  </div>
                  <div num={12} label={t("SMS Setup")}>
                    <SmsSetup />
                  </div>
                  <div num={13} label={t("Other Settings")}>
                    <OtherSettings />
                  </div>
                  <div num={14} label={t("Aura Settings")}>
                    <AuraSettings />
                  </div>
                </TabV>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default observer(Settings);
