import React, { useState } from "react";
import { SelectField } from "../../SelectField/SelectField";

export default function CustomFieldDisplayTypeSelect(props) {
  const data = [
    { label: "Text", value: 1 },
    { label: "Dropdown", value: 2 },
    { label: "Checkbox", value: 3 },
    { label: "Toggle", value: 4 },
    { label: "Date", value: 5 },
    { label: "DateTime", value: 6 },
    { label: "Year", value: 7 },
    { label: "Month", value: 8 },
    // { label: "DayOfWeek", value: 9 },
    { label: "Integer", value: 10 },
    { label: "Currency", value: 12 },
    { label: "Phone", value: 13 },
    { label: "Email", value: 14 },
    { label: "Address", value: 15 },
    { label: "Attachment", value: 16 },
  ];

  const mappedData = data.map((item) => {
    if (item.value === 3) {
      return { label: "Other", value: item.value };
    }
    return item;
  });

  return (
    <React.Fragment>
      <SelectField
        className={props?.className}
        onChange={props?.onChange}
        data={data}
        showClear={props?.showClear}
        value={props?.value}
        label={"Display Type"}
        error={props?.value ? "Please Select Display Type" : ""}
        showErr={props?.showErr}
      />
    </React.Fragment>
  );
}
