import React from 'react'
import { formatNumberToCurrency } from '../../helpers/general';
import Skeleton from 'react-loading-skeleton';

const totalRevenue = (data) => {
    const totalSum = data?.reduce((sum, item) => sum + item.total_fee, 0);
    return totalSum;
}
let showDecimals=true

const calculateLatestMonthlyRevenueChange = (data) => {
    const getMonthYear = date => {
        const year = Math.floor(date / 10000);
        const month = Math.floor((date % 10000) / 100);
        return `${year}-${month.toString().padStart(2, '0')}`;
    };

    // Group data by month
    const monthlyTotals = data.reduce((acc, item) => {
        const monthYear = getMonthYear(item._id.tripDate);
        if (!acc[monthYear]) {
            acc[monthYear] = 0;
        }
        acc[monthYear] += item.total_fee;
        return acc;
    }, {});

    const sortedMonths = Object.keys(monthlyTotals).sort((a, b) => new Date(b) - new Date(a));

    if (sortedMonths.length < 2) {
        return null;
    }

    const percentageChange = (current, previous) => {
        if (previous === 0) return current > 0 ? 100 : 0;
        return (((current - previous) / previous) * 100).toFixed(2);
    };

    const latestMonth = sortedMonths[0];
    const previousMonth = sortedMonths[1];


    const currentMonthRevenue = (monthlyTotals[latestMonth] / 100).toFixed(2);
    const previousMonthRevenue = (monthlyTotals[previousMonth] / 100).toFixed(2);

    return percentageChange(currentMonthRevenue, previousMonthRevenue);
};

function TotalRevenueCard({loading,tripData}) {
  return (
    <div style={{
        fontSize: "larger"
      }}>
        <span style={{ fontWeight: "bold", fontSize: "x-large" }}>
          {loading ?<Skeleton width={"30%"}/>:(formatNumberToCurrency(parseFloat((totalRevenue(tripData)/100).toFixed(2)),showDecimals))+" INR"} 
        </span>
        <br />
        <span>
          Total Revenue
        </span>
        <br />
        <div className='mt-3'>
          <span style={{ color: "green" }}>
          {loading ? (
            <Skeleton width={"30%"}/>
          ) : (
            calculateLatestMonthlyRevenueChange(tripData) === null || isNaN(calculateLatestMonthlyRevenueChange(tripData))
              ? "0 %"
              : `${calculateLatestMonthlyRevenueChange(tripData)} %`
          )}
          </span>
          <span className='px-1'>
            since last Month
          </span>
        </div>
      </div>
  )
}

export default TotalRevenueCard