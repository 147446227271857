export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl, corpId) => {
    if (!corpId) {
      let url = `/api/shift/grid?rows=${sizePerPage}&`;
      if (filterUrl) url += filterUrl;
      return url + `&page=${page}`;
    }
  },
  get: (id) => `/api/shift/${id}/detail`,
  save: `/api/shift/create`,
  delete: (id) => `/api/shift/${id}/delete`,
  update: `/api/shift/update`,

};
export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Shift Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Start Time",
    filterLabel: "Start Time",
    filterName: "startTime",
    type: "timeSelect",
  },
  {
    label: "End Time",
    filterLabel: "End Time",
    filterName: "endTime",
    type: "timeSelect",
  }
  // {
  //   label: "Has Fixed Adhoc Schedule",
  //   filterLabel: "Has Fixed Adhoc Schedule",
  //   renderer: (dataObj) => {
  //     return <div>
  //       {dataObj?.hasFixedAdhocSchedule ? 'true' : 'false'}
  //     </div>;
  //   }, filterName: "hasFixedAdhocSchedule",
  //   type: "text",
  // },
  // {
  //   label: "Has Open Adhoc Schedule",
  //   filterLabel: "Has Open Adhoc Schedule",
  //   renderer: (dataObj) => {
  //     return <div>
  //       {dataObj?.hasOpenAdhocSchedule ? 'true' : 'false'}
  //     </div>;
  //   }, filterName: "hasOpenAdhocSchedule",
  //   type: "text",
  // }
]
