import React, { useEffect, useState, useRef } from 'react';
import { FaCarSide, FaFileAlt, FaGlobe, FaHotel, FaPlane, FaPlus } from 'react-icons/fa';
import SendTripsModal from '../../pages/CRM/WhatsApp/modals/types/SendTripsModal';
import SendFlightsModal from '../../pages/CRM/WhatsApp/modals/types/SendFlightsModal';
import SendStaysModal from '../../pages/CRM/WhatsApp/modals/types/SendStaysModal';
import './ChatAction.css';
import AttachmentWithText from '../DocUpload/AttachmentWithText';
import { useNavigate } from 'react-router-dom';

const ChatActionDropDown = ({ data }) => (
  <div className="zr_crm_chatAction_dropup">
    {data.map((item, index) => (
      <div key={index} className="zr_crm_chatAction_dropup-item" onClick={item.onClick}>
        {item.icon}
        <span>{item.name}</span>
      </div>
    ))}
  </div>
);

const ChatAction = ({
  show = [],
  onChange,
  customer_id
}) => {

  const [showChatActionDropdown, setShowChatActionDropdown] = useState(false);
  const [showModal, setShowModal] = useState({ visible: false, type: '' });
  const [filteredDropActionData, setFilteredDropActionData] = useState([]);
  const dropdownRef = useRef(null);

  let navigate=useNavigate();

  const handleOpenModal = (type) => {
    setShowModal({ visible: true, type });
  };

  const handleCloseModal = () => {
    setShowModal({ visible: false, type: '' });
    setShowChatActionDropdown(false)
  };

  const handleSendTrips = (trips) => {
    onChange("Trips", trips);
    handleCloseModal();
  };

  const handleSendFlightDetails = (details) => {
    onChange("Flights", details);
    handleCloseModal();
  };

  const handleSendStays = (stays) => {
    onChange("Stays", stays);
    handleCloseModal();
  };


  const dropActionData = [
    { icon: <FaGlobe color="#FF5733" />, name: 'Trips', onClick: () => handleOpenModal('trips') },
    { icon: <FaCarSide  color="#FF5733" />, name: 'Create Trip', onClick: () => navigate(`/booking/create`) },
    { icon: <FaPlane color="#1E90FF" />, name: 'Flights', onClick: () => handleOpenModal('flights') },
    { icon: <FaHotel color="#32CD32" />, name: 'Stays', onClick: () => handleOpenModal('stays') },
    { icon: <FaFileAlt color="#8A2BE2" />, name: 'Document', onClick: () => handleOpenModal('document') }
  ];

  useEffect(() => {
    const filteredData = dropActionData.filter(action => show.includes(action.name));
    setFilteredDropActionData(filteredData);
  }, [show]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowChatActionDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="zr_crm_chatAction_action" ref={dropdownRef}>
      <div onClick={() => setShowChatActionDropdown(!showChatActionDropdown)} className="zr_crm_chatAction_plus-icon">
        <FaPlus size={20} />
      </div>
      {showChatActionDropdown && <ChatActionDropDown data={filteredDropActionData} />}

      {showModal.visible && showModal.type === 'trips' && (
        <SendTripsModal
          isModalVisible={showModal.visible}
          handleClose={handleCloseModal}
          onSendTrips={handleSendTrips}
          customer_id={customer_id}
        />
      )}

      {showModal.visible && showModal.type === 'flights' && (
        <SendFlightsModal
          isModalVisible={showModal.visible}
          handleClose={handleCloseModal}
          customer_id={customer_id}
          onSendFlights={handleSendFlightDetails}
        />
      )}

      {showModal.visible && showModal.type === 'stays' && (
        <SendStaysModal
          isModalVisible={showModal.visible}
          customer_id={customer_id}
          handleClose={handleCloseModal}
          onSendStays={handleSendStays}
        />
      )}

      {showModal.visible && showModal.type === 'document' && (
        <AttachmentWithText
          isModalVisible={showModal.visible}
          handleCloseModal={handleCloseModal}
          onSave={(v) => {
            onChange("Document", v);
            handleCloseModal();
          }
          }
        />
      )}
    </div>
  );
};

export default ChatAction;
