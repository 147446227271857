import { default as React, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import {
  Checkbox,
  CityGroupSelect,
  CitySelect,
  CurrencySelect,
  CustomFieldDisplayTypeSelect,
  VehicleTypeSelect,
} from "../../../components";
import { InputField, VendorSelect } from "../../../components";
import CustomFieldKindSelect from "../../../components/Dropdowns/EmployeeKindSelect/EmployeKindSelect";
export default function CustomFieldAdditional({ data, setData, hasErr }) {
  const [showErr, setshowErr] = useState(false);
  const [options, setOptionData] = useState(data?.options ? data?.options : []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    if (JSON.stringify(data?.options) !== JSON.stringify(options)) {
      setData({ ...data, options: options });
    }
  }, [options]);

  const changeSectionField = (sectionIndex, value, field) => {
    console.log(sectionIndex, value, field);
    setOptionData((X) =>
      X.map((f, curr) => {
        if (sectionIndex === curr) return { ...f, [field]: value };
        return f;
      })
    );
  };

  return (
    <div>
      <Row className="mb-3 mt-4">
        <Col className="col-lg">
          <CustomFieldKindSelect
            value={data?.kind}
            onChange={(v) => {
              setData({
                ...data,

                kind: v?.value,
              });
            }}
            // error={
            //   data?.kind?.length === 0
            //     ? "Please Engine No"
            //     : ""
            // }
            showErr={showErr}

          />
        </Col>
        <Col className="col-lg">
          <InputField
            value={data?.group}
            label={"Group Name"}
            onChange={(v) => {
              setData({
                ...data,
                group: v,
              });
            }}
            error={data?.group?.length === 0 ? "Please Enter Group" : ""}
            showErr={showErr}

            type="text"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="col-lg">
          <CustomFieldDisplayTypeSelect
            value={data?.displayType}
            onChange={(v) => {
              setData({
                ...data,
                displayType: v?.value,
              });
            }}
            error={data?.displayType?.length === 0 ? "Please Engine No" : ""}
            showErr={showErr}

          />
        </Col>
        <Col className="col-lg">
          <InputField
            value={data?.section}
            label={"Section Name"}
            onChange={(v) => {
              setData({
                ...data,
                section: v,
              });
            }}
            error={data?.section?.length === 0 ? "Please Enter Section" : ""}
            showErr={showErr}

            type="text"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="col-lg">
          <InputField
            value={data?.maxLength}
            label={"Max Length"}
            onChange={(v) => {
              setData({
                ...data,
                maxLength: v,
              });
            }}
            error={
              data?.maxLength?.length === 0 ? "Please Enter Max Length" : ""
            }
            showErr={showErr}

            type="number"
          />
        </Col>
        <Col className="col-lg">
          <InputField
            value={data?.maxValue}
            label={"Max Value "}
            onChange={(v) => {
              setData({
                ...data,
                maxValue: v,
              });
            }}
            error={data?.maxValue?.length === 0 ? "Please Enter Max Value" : ""}
            showErr={showErr}

            type="number"
          />
        </Col>
        <Col className="col-lg">
          <InputField
            value={data?.minValue}
            label={"Min Value "}
            onChange={(v) => {
              setData({
                ...data,
                minValue: v,
              });
            }}
            error={data?.minValue?.length === 0 ? "Please Enter Min Value" : ""}
            showErr={showErr}

            type="number"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="col-lg">
          <InputField
            value={data?.label}
            label={"Label "}
            onChange={(v) => {
              setData({
                ...data,
                label: v,
              });
            }}
            error={data?.label?.length === 0 ? "Please Enter Label" : ""}
            showErr={showErr}

            type="text"
          />
        </Col>
        <Col className="col-lg">
          <InputField
            value={data?.placeholder}
            label={"Placeholder"}
            onChange={(v) => {
              setData({
                ...data,
                placeholder: v,
              });
            }}
            error={data?.label?.length === 0 ? "Please Enter Placeholder" : ""}
            showErr={showErr}

            type="text"
          />
        </Col>
      </Row>
      {(data?.displayType === 2 ||
        data?.displayType === 4 ||
        data?.displayType === 12) && (
          <>
            <Row className="d-flex mx-1 justify-content-between pb-2 my-1 border-dark border-bottom">
              <Button
                className="col-sm-3"
                onClick={() => setOptionData([...options, {}])}
              >
                Add Options +
              </Button>
            </Row>
            {options?.map((section, sectionIndex) => (
              <Row className="border-bottom border-dark pt-2">
                <Row>
                  <InputField
                    showErr={hasErr}
                    required={true}
                    value={section?.value}
                    onChange={(v) => changeSectionField(sectionIndex, v, "value")}
                    label={"Value"}
                    error={
                      data?.minValue?.length === 0 ? "Please Enter Value" : ""
                    }
                    className=" col-sm-4 px-1 py-1"

                    type="text"
                  />
                  <InputField
                    showErr={hasErr}
                    required={true}
                    value={section?.label}
                    onChange={(v) => changeSectionField(sectionIndex, v, "label")}
                    label={"Label"}
                    error={
                      data?.minValue?.length === 0 ? "Please Enter Label" : ""
                    }
                    className=" col-sm-4 px-1 py-1"

                    type="text"
                  />
                  <Checkbox
                    label="Default"
                    className="col-sm-3"
                    checked={section?.isDefault}
                    onClick={() =>
                      setOptionData(
                        options.map((f, curr) => {
                          if (sectionIndex === curr)
                            return {
                              ...section,
                              isDefault: section?.isDefault ? false : true,
                            };
                          return f;
                        })
                      )
                    }
                  />
                  <Button
                    className=" col-sm-1 px-1 btn btn-danger"
                    style={{ maxHeight: "40px" }}
                    onClick={() =>
                      setOptionData(
                        options.filter((t, tcurr) => tcurr !== sectionIndex)
                      )
                    }
                  >
                    Delete
                  </Button>
                </Row>
              </Row>
            ))}
          </>
        )}
    </div>
  );
}
