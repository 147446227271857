import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { AddressField, InputField, ToggleSelector } from "../../../components";

const Step2 = ({ value, onChange }) => {
  return (
    <>
      <Row>
        <Col>
          <ToggleSelector
            style={{ fontSize: "16px" }}
            data={[
              { label: "Consumer", value: "1" },
              { label: "Transport Supplier", value: "2" },
            ]}
            value={value?.corpType === "1" ? "1" : "2"}
            onChange={(v) => {
              onChange({ ...value, corpType: v });
            }}

          // className="mb-4"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputField
            outline
            value={value?.name}
            label={"Brand Name"}
            placeholder="Brand Name"
            type="text"
            showErr={true}
            basic
            error={value?.name?.length === 0 ? "Please enter Name" : ""}
            LeftComponent={() => (
              <>
                <div
                  style={{ height: "100%" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  {" "}
                  <i
                    role="button"
                    className={`bx bxs-buildings text-gray
                           font-size-24 mx-1`}
                  />
                </div>
              </>
            )}
            onChange={(v) => {
              onChange({
                ...value,
                name: v,
              });
            }}
            required
            style={{ width: "100%", fontSize: "16px" }}
            labelStyle={{ fontSize: "12px" }}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="col-12 ">
          <InputField
            value={value?.fullName}
            outline
            label={"Company FullName"}
            placeholder="Company FullName"
            type="text"
            showErr={true}
            basic
            onChange={(v) => {
              onChange({
                ...value,
                fullName: v,
              });
            }}
            error={value?.fullName?.length === 0 ? "Please enter FullName" : ""}
            LeftComponent={() => (
              <>
                <div
                  style={{ height: "100%" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  {" "}
                  <i
                    role="button"
                    className={`bx bxs-buildings text-gray
                           font-size-24 mx-1`}
                  />
                </div>
              </>
            )}
            required
            style={{ width: "100%", fontSize: "16px" }}
            labelStyle={{ fontSize: "12px" }}
          />
          <Row className="mt-2 ">
            <Col className="col-12 px-2">
              <AddressField
                outline={true}
                inputLargerSize={true}
                placeholder={"Address"}
                label={"Address"}
                value={value?.address}
                onChange={(v) =>
                  onChange({
                    ...value,
                    address: { ...v, name: value?.address?.name },
                  })
                }
                style={{ fontSize: "16px", width: "100%" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
    </>
  );
};

export default Step2;
