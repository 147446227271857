import { observer } from "mobx-react-lite";
import React from 'react';
import { MdOutlineInsertComment } from 'react-icons/md';
import { useModal } from '../../store/context/ModalContext.js';
import Comment from './Comment.js';
import './Comment.scss';
import CommentService from "./CommentService.js";

const CommentModal = ({recordType,recordOf}) => {
    const { isOpen, toggleModal, setModalContent,setSize } = useModal();

    const handleCommentButtonClick = (e) => {
        setSize("small");
        CommentService.toggleCommentModal({ recordType: recordType, recordOf: recordOf })
        !isOpen && toggleModal();
        e?.stopPropagation();
        setModalContent({

            body: (
                   <Comment/>
            ),
        });
    };

    return (
        <div style={{ position: "relative" }} className="comment-component d-flex flex-column flex-1">
            <div onClick={handleCommentButtonClick} className="d-flex gap-2 align-items-center">
                <div>
                    <MdOutlineInsertComment size={20} />
                </div>
                <b className="align-self-center">Comments</b>
            </div>
        </div>
    );
};

export default observer(CommentModal);
