import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import SupplierCompany from "../../components/Dropdowns/SupplierCompany/SupplierCompany";
import { AccountSelect, CardThreeBounce, CurrencyAmountField, DateSelect, CompanySelect, InputField, SelectField, SupplierSelect } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "./PaymentMadeConstant";
import PaymentMadeService from "./PaymentMadeService";
import ExpensePayments from "./ExpensePayments";
import BillPayments from "./BillPayments";

const PaymentMade = ({ editId, onChange = () => { }, hasErr }) => {

    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const fetch = async () => {
        try {
            let paymentMades = await PaymentMadeService.get(editId);
            setData(paymentMades);
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        setData(null)
        setLoading(true);
        if (editId) fetch();
        setLoading(false)
    }, [editId, PaymentMadeService?.version]);

    useEffect(() => {
        onChange(data)
    }, [data]);

    return (
        <React.Fragment>
            {loading ?
                <CardThreeBounce /> :
                <Form
                    className="p-3 pt-2"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                >
                    <Row className="">
                        <SupplierCompany
                            required={true}
                            value={data?.supplier_id}
                            className="col-sm-6"
                            onChange={(v) => setData(
                                {
                                    ...data,
                                    supplierCompany_id: v?.value,
                                    supplier_id: v?.supplier_id
                                }
                            )}
                        />
                        {/* <SupplierSelect
                            required
                            value={data?.supplier_id}
                            onChange={(v) => setData({ ...data, supplier_id: v?.value })}
                            className="col-sm-6 col-12"
                        />
                        <CompanySelect
                            required
                            value={data?.supplierCompany_id}
                            onChange={(v) => setData({ ...data, supplierCompany_id: v?.value })}
                            className="col-sm-6 col-12"
                        /> */}
                        <AccountSelect
                            required
                            value={data?.account_id}
                            onChange={(v) => setData({ ...data, account_id: v?.value })}
                            className="col-sm-6 col-12"
                        />
                        <InputField
                            placeholder={t("Reference Number")}
                            value={data?.refNo}
                            required={true}
                            label={t("Reference Number")}
                            onChange={(v) => setData({ ...data, refNo: v })}
                            error={data?.refNo?.length === 0 ? t("Please enter Reference Number") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6 col-12"
                        />
                        <DateSelect
                            required
                            placeholder={t("Payment Date")}
                            value={data?.date}
                            label={t("Payment Date")}
                            onChange={(v) => setData({ ...data, date: v })}
                            error={data?.date?.length === 0 ? t("Please enter date") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6 col-12"
                        />
                        <CurrencyAmountField
                            className="col-sm-6 col-12"
                            value={{
                                amount: data?.total,
                                currency: data?.currency ? data?.currency : 'INR',
                            }}
                            required
                            label={t("Total")}
                            onChange={(v) => {
                                setData({
                                    ...data,
                                    total: v?.amount,
                                    currency: v?.currency,
                                })
                            }}
                        />
                        <SelectField
                            required
                            value={data?.paidTo}
                            label={t("Paid To")}
                            onChange={(v) => { setData({ ...data, paidTo: v.value }); }}
                            error={data?.paidTo?.length === 0 ? t("Enter Paid To") : ""}
                            showErr={hasErr}
                            data={SELECTSTRUCTURE?.paidTo}
                            type="number"
                            placeholder={t("Paid To")}
                            className="col-sm-6"
                        />
                        <SelectField
                            required
                            value={data?.status}
                            label={t("Payment Status")}
                            onChange={(v) => { setData({ ...data, status: v.value }); }}
                            error={data?.status?.length === 0 ? t("Enter Payment Status") : ""}
                            showErr={hasErr}
                            data={SELECTSTRUCTURE?.paymentStatus}
                            type="number"
                            placeholder={t("Payment Status")}
                            className="col-sm-6"
                        />
                        <InputField
                            placeholder={t("TDS")}
                            value={data?.tds}
                            required={true}
                            label={t("TDS")}
                            onChange={(v) => setData({ ...data, tds: v })}
                            error={data?.tds?.length === 0 ? t("Please enter tds") : ""}
                            showErr={hasErr}
                            type="number"
                            className="col-sm-6 col-12"
                        />
                        <InputField
                            placeholder={t("Amount Paid")}
                            value={data?.amountPaid}
                            required={true}
                            label={t("Amount Paid")}
                            onChange={(v) => setData({ ...data, amountPaid: v })}
                            error={data?.amountPaid?.length === 0 ? t("Please enter Amount Paid") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6 col-12"
                        />
                    </Row>

                    <Row className="mt-4">
                        <ExpensePayments
                            supplier_id={data?.supplier_id}
                            data={data?.expensePayments}
                            onChange={(expenses) => {
                                setData({
                                    ...data, expensePayments: expenses
                                })
                            }}
                        />
                    </Row>

                    <Row className="mt-4">
                        <BillPayments
                            supplier_id={data?.supplier_id}
                            data={data?.billPayments}
                            onChange={(bills) => {
                                setData({
                                    ...data, billPayments: bills
                                })
                            }}
                        />
                    </Row>

                </Form>
            }
        </React.Fragment>
    );
};

export default observer(PaymentMade);



