import InvoiceService from "../InvoiceService";
import { FiMinusCircle } from "react-icons/fi";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { InputField, PhoneNum } from "../../../components";
import { Button, Input } from "reactstrap";
import { IoSend } from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import { useCallback, useContext, useEffect, useState } from "react";
import Invoiceservice from "../InvoiceService";
import { I18nContext } from "../../../store/context/i18nContext";
import PropTypes from "prop-types";
import { DialogContext } from "../../../store/context/DialogContext";;


const OnDemandMessageForInvoice = ({ invoice_id, toggleModal }) => {
  const [loading, setLoading] = useState(false);
  const [Passengers, setPassengers] = useState([{}]);
  const [checkedPassengers, setCheckedPassengers] = useState(new Set());
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);

  const [payload, setPayload] = useState({
    invoice_id: invoice_id,
    receiverList: [
      {
        name: null,
        email: null,
      },
    ],
    customReceiverList: [],
  });

  const fetch = async () => {
    try {
      setLoading(true);
      let invoice = await Invoiceservice.get(invoice_id);
      const items = invoice.invoiceExtn.items;
      const uniquePassengers = new Set();
      const uniquePassengerEmails = new Set();

      items.forEach(item => {
        if (item?.invoiceTrip && item?.invoiceTrip?.passenger) {
          // Extract the passenger's email
          const email = item?.invoiceTrip?.passenger?.email;
          const name = item?.invoiceTrip?.passenger?.name;
          const passenger = {
            name: name,
            email: email
          };
          // Add the passenger to the uniquePassengers set if email is unique
          if (!uniquePassengerEmails.has(email)) {
            uniquePassengers.add(passenger);
            uniquePassengerEmails.add(email);
          }
        }
      });
      // Convert the Set to arrays
      const passengers = Array.from(uniquePassengers);
      const emails = Array.from(uniquePassengerEmails);

      setPassengers(passengers);
      setLoading(false);
    } catch (error) {
      showError(error);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if ((!payload?.receiverList || payload?.receiverList?.length === 0) && (!payload?.customReceiverList || payload?.customReceiverList?.length === 0)) {
      showError(t("Please enter Details"));
      return;
    }
    try {
      const response = await InvoiceService.sendMessage(payload);
      if (response) {
        toggleModal()
      }

    } catch (error) {
      showError(error);
    }
  };


  const addCustomPassenger = () => {
    setPayload((prevPayload) => {
      const updatedCustomReceiverList = [...prevPayload.customReceiverList];
      updatedCustomReceiverList.push({
        name: null,
        email: null
      });
      return {
        ...prevPayload,
        customReceiverList: updatedCustomReceiverList
      };
    });
  };

  const removeCustomPassenger = (indexToRemove) => {
    setPayload((prevPayload) => {
      const updatedCustomReceiverList = prevPayload.customReceiverList.filter((_, index) => index !== indexToRemove);

      return {
        ...prevPayload,
        customReceiverList: updatedCustomReceiverList
      };
    });
  };


  const updateCustomPassenger = (index, field, value) => {
    setPayload((prevPayload) => {
      const updatedCustomReceiverList = [...prevPayload.customReceiverList];
      updatedCustomReceiverList[index] = {
        ...updatedCustomReceiverList[index],
        [field]: value
      };
      return {
        ...prevPayload,
        customReceiverList: updatedCustomReceiverList
      };
    });
  };





  // Function to toggle checkbox for a passenger
  const toggleCheckbox = (passengerEmail) => {
    setCheckedPassengers(prevState => {
      const updatedCheckedPassengers = new Set(prevState);
      if (prevState.has(passengerEmail)) {
        updatedCheckedPassengers.delete(passengerEmail); // Uncheck if already checked
      } else {
        updatedCheckedPassengers.add(passengerEmail); // Check if not already checked
      }
      return updatedCheckedPassengers;
    });
  };

  // Function to update the receiver list based on checked passengers
  const updateReceiverList = () => {
    const updatedReceiverList = Passengers.filter(passenger => checkedPassengers.has(passenger.email));
    setPayload(prevState => ({
      ...prevState,
      receiverList: updatedReceiverList
    }));
  };


  const isChecked = (email) => {
    // Check if the email is present in the receiverList
    return checkedPassengers.has(email);
  };


  // Function to check if all passengers are selected
  const areAllPassengersSelected = () => {
    return checkedPassengers.size === Passengers.length;
  };


  const toggleCheckAllPassengers = () => {
    if (checkedPassengers.size === Passengers.length) {
      // If all passengers are checked, uncheck all
      setCheckedPassengers(new Set());
    }
    else {
      // If not all passengers are checked, check all
      const allPassengerEmails = Passengers.map(passenger => passenger.email);
      setCheckedPassengers(new Set(allPassengerEmails));
    }
  };

  useEffect(() => {
    updateReceiverList();
  }, [checkedPassengers]);


  useEffect(() => {
    fetch();
  }, [])



  return (
    <div className="d-flex flex-column justify-content-between">
      <div >
        <div className="d-flex my-2 justify-content-between">
          <div className="my-3 ps-1 "><h3>Passengers :</h3></div>
          <div className="me-3 d-flex align-items-center justify-content-center">
            <Input
              className="mx-2"
              type="checkbox"
              style={{ cursor: "pointer" }}
              checked={areAllPassengersSelected()}
              onChange={() => {
                toggleCheckAllPassengers();
              }}
            />
            <span><b className="mt-2">Select All</b></span>
          </div>
        </div>
        <table className="table-bordered">
          <thead>
            <tr>
              <th>Name
              </th>
              <th>
                Email
              </th>
              <th className="d-flex align-items-center justify-content-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {Passengers?.map(
              ({ name, email }, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div>{`${name ?? ""}`}</div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span>{email}</span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center" >
                        <Input
                          className="mx-2"
                          type="checkbox"
                          style={{ cursor: "pointer" }}
                          checked={isChecked(email)}
                          onChange={() => {
                            toggleCheckbox(email);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
            {payload?.customReceiverList?.length > 0 ?
              payload?.customReceiverList?.map(({ name, email }, index) => {
                return (
                  <tr key={index}>
                    <td className="py-1">
                      <InputField
                        placeholder={t("Passenger Name  ")}
                        outline
                        value={name}
                        label={t("Name")}
                        className="my-0"
                        onChange={(v) => updateCustomPassenger(index, "name", v)}
                      />
                    </td>
                    <td className="py-1">
                      <InputField
                        outline
                        placeholder="Email"
                        value={email}
                        className="my-0"
                        onChange={(v) => updateCustomPassenger(index, "email", v)}
                      />
                    </td>
                    <td className="py-0 d-flex align-items-center justify-content-between">
                      <div
                        className=""
                        style={{ cursor: "pointer" }}>
                        <FiMinusCircle fontSize={20} style={{ cursor: "pointer" }} onClick={() => { removeCustomPassenger(index) }} />
                        {index === payload?.customReceiverList?.length - 1 && <MdOutlineAddCircleOutline size={22} style={{ cursor: "pointer" }} onClick={addCustomPassenger} />}
                      </div>
                    </td>
                  </tr>
                );
              }) : null}
          </tbody>
        </table>
        <div className="d-flex justify-content-end align-items-center my-2 me-1">
          {payload?.customReceiverList?.length === 0 && <MdOutlineAddCircleOutline style={{ cursor: "pointer" }} size={22} onClick={addCustomPassenger} />}
        </div>
      </div>

      <div className="d-flex justify-content-end my-2">
        <Button
          // onClick={sendMessage}
          className="mx-2"
          style={{ height: "28px", padding: "2px 10px 2px 4px" }}
          color="success"
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ margin: "2px" }}
          >
            <div className="me-2">
              <IoSend size={16} />
            </div>
            <div>
              Send
            </div>

          </div>
        </Button>
      </div>
    </div >
  );
};
OnDemandMessageForInvoice.proptype = {
  invoice: PropTypes.object,
};


export default OnDemandMessageForInvoice;
