export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Name",
            accessor: "name",
            show: true,
        },

        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
