import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import CustomerLabelService from './CustomerLabelService';

const CustomerLabel = ({ value }) => {
  const [customerObject, setCustomerObj] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchCustomerName = async (value) => {
    setLoading(true);
    try {
      const customer = await CustomerLabelService.fetchCustomerNameFromCustomerId(value);
      if (customer) {
        setCustomerObj({...customerObject,
          name: customer.label,
          email:customer?.email,
          phone:customer?.phoneNo?.countryCode + customer?.phoneNo?.phone
        });
      } else {
        setCustomerObj({name:'No Record Found'});
      }
    } catch (error) {
      setCustomerObj({name:'No Record Found'});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (value) {
      fetchCustomerName(value);
    } else {
      setCustomerObj({name:'No Record Found'});
    }
  }, [value]);

  if (loading) {
   return <Skeleton height={20} width={180} className='text-crm-greyish font-size-12 fw-bold mt-0' />
  }

  return (
    <>
      <span className={`zr_category-label ${customerObject?.name === 'No Record Found' ? 'disabled' : ''}`}>
        {customerObject?.name}
      </span>
    </>

  );
};

export default observer(CustomerLabel);
