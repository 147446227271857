import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { DialogContext } from '../../store/context/DialogContext';
import Skeleton from 'react-loading-skeleton';
import { downloadMedia } from '../../util/Util';
import MediaModal from '../MediaModal/MediaModal';

const Image = ({ src, placeholder = "Loading...", style, ...restProps }) => {
    const [imageSrc, setImageSrc] = useState();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const { showError } = useContext(DialogContext);
    const [isDownloading, setDownloading] = useState(false);

    const fetchImage = async (src) => {
        if (src) {
            setLoading(true);
            try {
                const response = await axios({
                    url: src,
                    headers: {
                        Authorization: localStorage.getItem("BearerToken"),
                    },
                    withCredentials: true,
                    responseType: "blob",
                });
                const reader = new FileReader();
                reader.onload = () => {
                    setImageSrc(reader.result);
                };
                reader.readAsDataURL(response?.data);
            } catch (error) {
                console.error("Error fetching image:", error);
                showError("Error fetching image");
            }
            finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (src && (src.slice(1, 4) === "api")) {
            fetchImage(src);
        }
    }, [src]);

    const toggleModal = () => setModal(!modal);


    if (loading) {
        return <Skeleton height={restProps?.height ?? 300} width={restProps?.width ?? 300} />;
    }

    return (
        <div>
            {src && imageSrc ?
                <>
                    <img
                        key={src}
                        src={imageSrc}
                        alt="Loaded Image"
                        style={{ cursor: 'pointer', ...style }}
                        onClick={toggleModal}
                        {...restProps}
                    />
                    <MediaModal isOpen={modal} toggle={toggleModal} centered>
                        <MediaModal.Header
                            title={"Image"}
                            onClose={toggleModal}
                            onDownload={
                                async () => {
                                    await downloadMedia(imageSrc, "image")
                                }
                            } />
                        <MediaModal.Body>
                            <img 
                                src={imageSrc} 
                                alt="Modal Image"
                                height={restProps?.previewheight}
                                width={restProps?.previewwidth}
                                style={{ cursor: 'pointer', ...style }} 
                            />
                        </MediaModal.Body>
                    </MediaModal>
                </>
                : <i className='bx bx-image-alt font-size-20' />}
        </div>
    );
};

export default Image;
