import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./CommentConstant";
import { doGET, doPOST } from "../../util/HttpUtil";

class Service {

    records = [];
    totalRecords = 0;
    places = [];
    page = 1;
    rows = 10;
    isVisible = false
    recordType = "";
    recordOf = "";
    constructor() {
        makeAutoObservable(this);
    }

    fetch = async function (filterUrl, corpId) {
        const response = await doGET(ENDPOINTS.grid(this.recordType, this.recordOf))
        if (response.status === 200) {
            this.records = response.data.rows;
            this.totalRecords = response.data.records;
        }
    };
    toggleModal = ({ recordType, recordOf }) => {
        this.isVisible = !this.isVisible
        this.recordType = recordType;
        this.recordOf = recordOf;
    }
    toggleCommentModal = ({ recordType, recordOf }) => {
        this.recordType = recordType;
        this.recordOf = recordOf;
    }

    save = async function (data) {
        await doPOST(ENDPOINTS.saveNew, data)
        this.fetch();
    };
}

const CommentService = new Service();
export default CommentService;
