import React from "react";
import Select from "react-select";
import { Col, Row, Button } from "reactstrap";
import { ModalPopup } from "../Offers/Modal";

export default function Comment(props) {
  const data = [
    { value: 0, label: "Open" },
    { value: 1, label: "Resolved" },
    { value: 2, label: "Critical" },
  ];
  const getDateAndTimeString = (ms) => {
    const d = new Date();
    d.setTime(ms * 1000);
    return d.toDateString() + "  " + d.toLocaleTimeString();
  };
  return (
    <React.Fragment>
      <ModalPopup
        isOpen={props?.modal}
        setIsOpen={props?.setModal}
        noFooter={true}
        title={"Add Comment"}
        body={
          <div>
            {props?.comments?.map((c) =>
              c.msg ? (
                <Row>
                  <Col className="col-8">{c.msg}</Col>
                  <Col className="col-2">{c.commentBy}</Col>
                  <Col className="col-2">{getDateAndTimeString(c.at)}</Col>
                </Row>
              ) : (
                ""
              )
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props?.submitFunc(props?.comment);
              }}
            >
              <Row>
                <Col lg="12">
                  <div className="my-3">
                    <textarea
                      onChange={(e) =>
                        props?.setComment({
                          ...props?.comment,
                          comment: e.target.value,
                        })
                      }
                      id="basicpill-address-input1"
                      className="form-control"
                      rows="2"
                      placeholder="your text here"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Select
                    isSearchable={false}
                    onChange={(v) =>
                      props?.setComment({ ...props?.comment, status: v.value })
                    }
                    value={data.filter((d) => d.value === props?.comment.status)}
                    placeholder={"Select Status"}
                    options={data}
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-end">
                <Button type="submit" className="mt-2 mx-2 col-1">
                  Add Comment
                </Button>
              </Row>
            </form>
          </div>
        }
      />
    </React.Fragment>
  );
}
