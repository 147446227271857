import React from 'react'
import { Droppable } from 'react-beautiful-dnd'

function DroppableComponent({
    droppableId,
    className="",
    style={},
    children
}) {
  return (
    <Droppable droppableId={droppableId}>
    {(provided) => (
        <div className={className}  {...provided.droppableProps} ref={provided.innerRef} style={style}>
            {children}
            {provided.placeholder}
        </div>
    )}
</Droppable>
  )
}

export default DroppableComponent