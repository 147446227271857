import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Button, Col, Row } from "reactstrap";

import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  CheckButton,
  DeleteLink,
  EditLink,
  Layout,
  SelectField,
  yyyymmddtoTimeStamp,
} from "../../components";
import "../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "./InvoiceSetupConstant";
import { STRUCTURE } from "./InvoiceSetupConstant";
import InvoiceSetupService from "./InvoiceSetupService";
import MydModalWithGrid from "./MydModalWithGrid";

const InvoiceSetup = (props) => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [modal, setModal] = useState(false);
  const [invoices, setinvoices] = useState([]);
  const [showErr, setShowErr] = useState(false);
  const { showMessage } = useContext(DialogContext);

  const toggle = () => setModal(!modal);

  const submitHandler = async (e) => {
    console.log("submit handler");
    e.preventDefault();

    setLoading(true);

    try {
      data.prefixes = invoices;
      setData(() => {
        return {
          ...data,
        };
      });

      // data.prefixes = invoices
      // setData({
      //     ...data,

      // })

      // const response = await InvoiceSetupService.save(data);

      // InvoiceSetupService.records.push(data);

      showMessage("Invoice Setup saved successfully");

      // fetchData();
      setLoading(false);
    } catch (e) {
      showError(e)
      setLoading(false);
    }
  };

  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await InvoiceSetupService.fetch(filterUrl);
      setData(response?.data);
      setinvoices(response?.data?.prefixes);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onDelete = async (event, idx) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete Invoice Setup ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        invoices.splice(idx, 1);
        setinvoices([...invoices]);
      } catch (e) {
        showError(e);
      }
    }
  };

  if (loading) return <CardThreeBounce />;
  return (
    <>
      <MydModalWithGrid
        modal={modal}
        toggle={toggle}
        invoices={invoices}
        setinvoices={setinvoices}
        data={data}
        setData={setData}
      />

      <Layout
         
        title={t("Invoice Setup")}
        onAddClick={toggle}
      >
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>{t("Kind")}</Th>
              <Th>{t("Prefix")}</Th>
              <Th>{t("Date From")}</Th>
              <Th>{t("Date till")}</Th>

              <Th>{t("Action")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {invoices?.map((invoicesetup, idx) => (
              <Tr key={invoicesetup?._id}>
                <Td>{invoicesetup?.kind === 1 ? "General" : "SEZ"}</Td>
                <Td>{invoicesetup?.invoicePrefix}</Td>
                <Td>
                  {" "}
                  {moment
                    .unix(yyyymmddtoTimeStamp(invoicesetup?.from))
                    .format("DD-MM-YYYY")}{" "}
                  &nbsp;
                </Td>
                <Td>
                  {" "}
                  {moment
                    .unix(yyyymmddtoTimeStamp(invoicesetup?.till))
                    .format("DD-MM-YYYY")}{" "}
                  &nbsp;
                </Td>

                <Td>
                  <DeleteLink
                    onClick={(event) => {
                      onDelete(event, idx);
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout>

      <div className="d-flex justify-content-end  ">
        <div className="me-2">
          <Button type="submit" color="primary" onClick={submitHandler}>
            Save
          </Button>{" "}
        </div>
      </div>
    </>
  );
};

export default observer(InvoiceSetup);
