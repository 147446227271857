export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/transaction/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    if (page) url += `&page=${page}`;
    url += `&sortBy=time&sortAsc=false`
    return url
  },
  get: (id) => `/api/transaction/${id}/detail`,
  save: `/api/transaction/create`,
  delete: (id) => `/api/transaction/${id}/delete`,
  update: `/api/transaction/update`,
};

export const SELECTSTRUCTURE = {
  paymentFrom: [
    { label: "Expense", value: "Expense" },
    { label: "Payment Made", value: "Payment Made" },
    { label: "Payment Received", value: "Payment Received" },
  ]

};

export const STRUCTURE = [
  {
    label: "Account",
    filterLabel: "Account Name",
    filterName: "account_id",
    type:"accountSelect"
  },
  {
    label: "Added From",
    filterLabel: "Added From",
    filterName: "addedFrom",
    data:SELECTSTRUCTURE.paymentFrom,
    type: "select",
  },
  {
    label: "Reference No.",
    filterLabel: "Reference No",
    filterName: "referenceNo",
    type:"referenceSelect"
  }
];



