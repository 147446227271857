import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import {
  Address,
  CardThreeBounce,
  CountrySelect,
  InputField,
} from "../../../components";
import NameMasterService from "./NameMasterService";
import { I18nContext } from "../../../store/context/i18nContext";
import { DialogContext } from "../../../store/context/DialogContext";

const EditNameMaster = ({ editId, onChange = () => { } }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetch = async () => {
    try {
      let states = await NameMasterService.get(editId);
      setData(states);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    setData(null);
    setLoading(true);
    if (editId) fetch();
    setLoading(false);
  }, [editId, NameMasterService?.version]);

  useEffect(() => {
    onChange(data);
  }, [data]);


  return (
    <React.Fragment>
      {loading ? (
        <CardThreeBounce />
      ) : (
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row>
            <InputField
              placeholder={t("Name")}
              value={data?.name}
              required={true}
              label={t("Name")}
              onChange={(v) => setData({ ...data, name: v })}
              type="text"
              className="col-sm-4"
            />
          </Row>
        </Form>
      )}
    </React.Fragment>
  );
};

export default observer(EditNameMaster);
