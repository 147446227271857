import React, { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { DialogContext } from "../../store/context/DialogContext";
import { doPOST } from "../../util/HttpUtil";
import Modal from "../Common/Modal";
import Dropzone from "./Dropzone";
import ModalComponent from "../Modal/Modal";

const FileUploadPopup = ({ value, onClose, onFileUpload, fileSizeText, fileSize, id, module, field, setIsUploadPopupOpen, onDelete }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    useEffect(() => {
        if (value) {
            setSelectedFile([value])

        }
    }, [value])
    const { showMessage, showError } = useContext(DialogContext);

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append("file", selectedFile[0]);
        formData.append(
            "data",
            JSON.stringify({
                _id: id,
                field: field,
                module: module,
            })
        );

        try {
            const response = await doPOST(`/api/file/single/upload`, formData);
            if (response?.status === 200) {
                showMessage("Successfully Uploaded");
                onFileUpload(response?.data?._id)
                setIsUploadPopupOpen(false)
            }
        } catch (error) {
            showError(error);
        }
    };

    return (

        <>
            <ModalComponent
                position="centered"
                size="medium"
                isOpen={true}
                onToggle={onClose}
                className="p-3"
            >
                <ModalComponent.Body>
                    <div className="file-upload-popup p-5 m-3">
                        <Dropzone
                            id={id}
                            module={module}
                            field={field}
                            fileSizeText={fileSizeText}
                            fileSize={fileSize}
                            onDelete={onDelete}
                            single
                            value={selectedFile}
                            onChange={(v) => {
                                setSelectedFile(v)
                            }}
                        />
                    </div>
                </ModalComponent.Body>
                <ModalComponent.Footer>
                    <Button onClick={() => handleUpload()}>Upload</Button>
                </ModalComponent.Footer>
            </ModalComponent>
        </>
    );
};

export default FileUploadPopup;
