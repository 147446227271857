import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { useOutsideAlerter } from '../dnd/board/helper';
import CustomTooltip from '../../pages/Booking/components/CustomTooltip';
import Reminder from './Reminder';
import ReminderService from './ReminderService';
import Circle from '../dnd/forms/Circle';
import { MdAddAlarm } from 'react-icons/md';

const ReminderButton = ({ className, module, record_id, space_id  }) => {

    const [isReminderOpen, setIsReminderOpen] = useState(false)

    const dropdownRef = useRef(null);
    useOutsideAlerter(dropdownRef, () => setIsReminderOpen(false));


    return (
        <div className={className ?? "me-5"}>
            <div className="zr_reminder_wrapper" ref={dropdownRef}>
                <div
                    className="zr_reminder_display d-flex align-items-center "
                    onClick={() => setIsReminderOpen(!isReminderOpen)}
                >
                    <MdAddAlarm size = {20} className='me-2'/>  Reminder
                    <div className="d-flex" style={{
                        position: "absolute",
                        top: "-8px",
                        right: "-8px"
                    }}>
                        <CustomTooltip>
                            <CustomTooltip.Body>
                                {/* <Circle number={
                                    ReminderService?.records?.filter(task => !task.completed)?.length
                                } color="orange" /> */}
                            </CustomTooltip.Body>
                            <CustomTooltip.ToolTip>
                                <div style={{ width: "fitContent" }}>Pending</div>
                            </CustomTooltip.ToolTip>
                        </CustomTooltip>
                    </div>
                </div>
                {isReminderOpen && (
                    <div className="zr_reminder_modal">
                        <Reminder
                            module={module}
                            record_id={record_id}
                            space_id={space_id}
                        />
                    </div>
                )}
            </div>
        </div>



    );
};

export default observer(ReminderButton);
