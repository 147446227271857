import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { DeleteButton } from "react-bootstrap-table";

import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";
import {
    BackArrow,
    CardThreeBounce,
    CrossButton,
    DeleteLink,
    SaveButton,
    scrollStyle,
} from "../../components";
import Tab from "../../components/Common/Tab/Tab";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import Helpline from "./Tabs/Helpline/Helpline";
import Languages from "./Tabs/Languages/Languages";

import PaymentGateway from "./Tabs/PaymentGateway/PaymentGateway";
import TariffSettings from "./Tabs/TariffSettings/TariffSettings";
import Config from "./Tabs/Configuration/Config";
import EmailCredentials from "./Tabs/EmailCredentials/EmailCredentials";
import TripJourneyGap from "../TripJourneyGap/TripJourneyGap";
import AppSettings from "./Tabs/AppSettings/AppSettings";




const Configurations = (props) => {
    const { id } = props;
    const { t } = useContext(I18nContext);


    const [step, setStep] = useState(1);
    const [data, setData] = useState(id ? {} : { version: 0 });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    if (loading) return <CardThreeBounce />;

    console.log(data, "data in vechile ");

    return (
        <React.Fragment>
            <div>
                <div className="p-0 mt-4">
                    <Row className="mt-4">
                        <Col
                            style={props?.insidePane ? { width: "100%" } : scrollStyle}

                        >
                            <Card>
                                <CardBody className="p-3 mt-5">
                                    <BackArrow onClick={() => props?.back()} />
                                    <CrossButton onClick={() => props?.back()} />
                                    <Form >

                                        <Tab
                                            vertical
                                            left
                                            activeTab={step}
                                            onChange={(data) => {
                                                setStep(data);
                                            }}
                                            className="d-flex"
                                        >
                                            <div num={1} label="Configuration">
                                                <Config />
                                            </div>
                                            <div num={2} label="App Settings">
                                                <AppSettings />
                                            </div>
                                            <div num={3} label="Helpline">
                                                <Helpline />
                                            </div>

                                            <div num={4} label="Email Credentials">
                                                <EmailCredentials />
                                            </div>

                                            <div num={5} label="Trip Journey Gap">
                                                <TripJourneyGap
                                                    insidePane={true}
                                                    multiMode={true}
                                                />
                                            </div>
                                        </Tab>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default observer(Configurations);
