export const ENDPOINTS = {
    grid: (filterUrl) => {
      let url = `/api/trip/daily/report?&`;
      if (filterUrl) url += filterUrl;
      return url;
    },
  };

  export const TripStructure = ['Total', 'Trips', 'Booking']
  export const SeatStructure = ['Total', 'Occupied', 'No Show', 'Vaccant']
  export const ComplaintStructure = ['Overspeed', 'Missbehaves', 'Delayed']
  
  export const STRUCTURE = [
    {
      label: "From Month",
      description: "From Month and To Month should be same",
      filterLabel: "From",
      filterName: "fromDate",
      type: "date",
    },
    {
      label: "To Month",
      description: "From Month and To Month should be same",
      filterLabel: "To",
      filterName: "toDate",
      type: "date",
    },
    {
      label: "Client",
      description: "Select Client",
      filterLabel: "Client",
      filterName: "client_id",
      type: "clientSelect",
    },
    {
      label: "Supplier",
      description: "Select Vendor",
      filterLabel: "Supplier",
      filterName: "supplier_id",
      type: "vendorSelect",
    },
  ];
  
  export default STRUCTURE;
  