import React from 'react';
import './style.scss';

const Circle = ({ number, color }) => {
    return (
        <div className="reminder_circle" style={{ backgroundColor: color }}>
            {number}
        </div>
    );
}

export default Circle;
