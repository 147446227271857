import React, { useState, useEffect } from "react";
import { SelectField } from "../../SelectField/SelectField";
import { toJS } from "mobx";
import CampusSelectService from "./CampusSelectService";

export default function CampusSelect(props) {
  const [campuses, setCampuses] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
          const response = await CampusSelectService.fetch(
              props?.corp_id
          );

          setCampuses(toJS(CampusSelectService.campuses));
        
      } catch (error) {
      }
    };
    fetch();
  }, [props?.corp_id]);

  return (
    <React.Fragment>
      {campuses.length > 0 && (
        <SelectField
          className={props?.className}
          onChange={props?.onChange}
          data={campuses}
          showErr={props?.showErr}
          required={props?.required}
          showClear={false}
          value={props?.value}
          label={props?.label ? props?.label : "Campus"}
          error={
            !props?.value
              ? "Please Select " + (props?.label ? props?.label : "Campus")
              : ""
          }
          isDisbaled={false}
          inline={props?.inline}
          borderNone={props?.borderNone}
          RightComponent={props?.RightComponent}
          onClickRight={props?.onClickRight}
        />
      )}
      {!campuses.length > 0 && (
        <SelectField
          className={props?.className}
          onChange={props?.onChange}
          label={props?.label ? props?.label : "Campus"}
          error={
            props?.value
              ? "Please Select " + (props?.label ? props?.label : "Campus")
              : ""
          }
          isDisabled={false}
          showErr={props?.showErr}
          inline={props?.inline}
          borderNone={props?.borderNone}
          RightComponent={props?.RightComponent}
          onClickRight={props?.onClickRight}
        />
      )}
    </React.Fragment>
  );
}
