import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Card } from "reactstrap";
import { I18nContext } from "../../store/context/i18nContext";

const SettingUp = () => {
  const { t } = useContext(I18nContext);

  return (
    <div className="page-content">
      <Card className="setting-up-card">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h2 className="m-5">{t("Setting up the page for you")}</h2>
          <div className="m-5">
            <ThreeBounce size={40} color="pink" />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default observer(SettingUp);
