import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { CitySelect, CountrySelect, InputField, StateSelect } from '../../../components'

const TransporterStep1 = ({ data, setData }) => {
    const { offices = [] } = data
    const [officeData, setOfficeData] = useState(offices)

    useEffect(() => {
        if (JSON.stringify(data?.contactPersons) !== JSON.stringify(officeData))
            setData({ ...data, offices: officeData });
    }, [officeData]);
    return (
        <>
            <Row className=''>
                <Col className=' px-4'>
                    <InputField
                        label={"Company Name"}
                        className=" m-auto px-2"
                        value={data?.companyName}
                        onChange={(v) => setData({
                            ...data,
                            companyName: v
                        })}
                        placeholder="Enter Company Name"
                        type="text"
                        error={!data?.companyName ? "Please enter Company Name" : ""}

                        showErr={true}
                    />
                </Col>
            </Row>
            <div className=' mt-4'>
                {officeData?.map((office, i) => {
                    return (
                        <>
                            <Row className='mt-2 px-4'>
                                <Col className='col-sm-4'>
                                    <CountrySelect
                                        className=" m-auto "
                                        value={office?.country}
                                        required={true}
                                        onChange={(v) =>
                                            setOfficeData(
                                                officeData?.map((f, x) => {
                                                    if (i === x) return { ...office, country: v.value };
                                                    return f;
                                                })
                                            )
                                        }
                                    />
                                </Col>
                                <Col className='col-sm-4'>
                                    <StateSelect
                                        value={office?.state}
                                        className=" m-auto "
                                        onChange={(v) =>
                                            setOfficeData(
                                                officeData?.map((f, x) => {
                                                    if (i === x) return { ...office, state: v.value };
                                                    return f;
                                                })
                                            )
                                        }
                                        country={office?.country}

                                    />
                                </Col>
                                <Col className='col-sm-4'>
                                    <CitySelect
                                        value={office?.city}

                                        className=" m-auto "
                                        country={office?.country}
                                        state={office?.state}
                                        onChange={(v) =>
                                            setOfficeData(
                                                officeData?.map((f, x) => {
                                                    if (i === x) return { ...office, city: v?.value };
                                                    return f;
                                                })
                                            )
                                        }
                                    />
                                </Col>


                            </Row>

                            <Row className=' mt-4 px-4'>
                                <Col className=' col-sm-12'>
                                    <InputField
                                        label={"Address"}
                                        className=" m-auto "
                                        value={office?.addressLine}
                                        onChange={(v) =>
                                            setOfficeData(
                                                officeData?.map((f, x) => {
                                                    if (i === x) return { ...office, addressLine: v.value };
                                                    return f;
                                                })
                                            )
                                        }
                                        placeholder="Enter Address Line"
                                        type="text"

                                        showErr={true}
                                    />
                                </Col>

                            </Row></>
                    )
                })}

                <div className=' mt-3 float-end px-4'>
                    <Button onClick={() => setOfficeData([...officeData, {}])} style={{ backgroundColor: "#DF6463", border: "none", borderRadius: "8px" }}> <span className=' fa-1x fw-bolder'>+</span> Add Office</Button>
                </div>

            </div>

        </>
    )
}

export default TransporterStep1