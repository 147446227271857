import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./PaymentConstant";
import { doPOST } from "../../util/HttpUtil";
import InvoiceService from "./InvoiceService";


class Service {
  records = [];
  totalRecords = 0;
  page = 1;
  rows = 20;
  filterOptions = {};

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterData) => {
    if (filterData) this.filterOptions = filterData;
    var data = InvoiceService.records.map((invoice) => (invoice._id));
    console.log(ENDPOINTS.grid(this.page, this.rows, filterData));
    const response = await doPOST(ENDPOINTS.grid(this.page, this.rows, filterData), data);
    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch(this.filterOptions);
  }
}

const PaymentService = new Service();
export default PaymentService;
