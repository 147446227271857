export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {

    let url = `/api/currency/switch/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + `&page=${page}`;

  },
  get: (id) => `/api/currency/switch/${id}/detail`,
  saveNew: `/api/currency/switch/save`,
  edit: (id) => `/api/currency/switch/${id}/edit`,
  delete: (id) => `/api/currency/switch/${id}/delete`,
  getRate: (from, target) => `/api/currency/exchange/${from}/${target}/rate`
};

export const STRUCTURE = [
  {
    label: "From",
    description: "description...",
    filterLabel: "From",
    filterName: "from",
    type: "currencySelect",
  },
  {
    label: "Target",
    description: "description...",
    filterLabel: "Target",
    filterName: "target",
    type: "currencySelect",
  },
];