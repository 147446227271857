import React, { useState, useEffect } from 'react'
import { SelectField } from '../../SelectField/SelectField';
import { toJS } from 'mobx';
import SupervisorRoleSelectService from './SupervisorRoleSelectService';

export default function SupervisorRoleSelect(props) {
    const [supervisorRoles, setSupervisorRoles] = useState([]);
    useEffect(() => {
        const fetch = async () => {
            try {
                await SupervisorRoleSelectService.fetch();
                setSupervisorRoles(toJS(SupervisorRoleSelectService.supervisorRoles))
            } catch (error) {
                console.log(error.message);
            }
        }
        fetch();
    }, [])
    return (
        <React.Fragment>
            {supervisorRoles.length > 0 &&
                <SelectField
                    className={props?.className}
                    onChange={props?.onChange}
                    data={supervisorRoles}

                    value={props?.value}
                    label={"Supervisor Role"}
                    error={
                        props?.value
                            ? "Please Select Supervisor Role"
                            : ""
                    }
                    showErr={props?.showErr}
                    inline={props?.inline}
                    borderNone={props?.borderNone}
                    RightComponent={props?.RightComponent}
                    onClickRight={props?.onClickRight}
                    required={props?.required}
                {...props}

                />}
            {!supervisorRoles.length > 0 &&
                <SelectField
                    className={props?.className}
                    onChange={props?.onChange}
                    data={supervisorRoles}

                    value={props?.value}
                    isDisabled={true}
                    label={"Supervisor Role"}
                    error={
                        props?.value
                            ? "Please Select Supervisor Role"
                            : ""
                    }
                    showErr={props?.showErr}
                    inline={props?.inline}
                    borderNone={props?.borderNone}
                    RightComponent={props?.RightComponent}
                    onClickRight={props?.onClickRight}
                    required={props?.required}
                />}
        </React.Fragment>
    )
}
