

export const TOUR_TOGGLE_DATA = (t, toggleValue)=>    ({
    module: [
        { label: t("Tours"), value: "tours" },
        { label: t("Trips"), value: "trips" },
        { label: t("Stays"), value: "stay" },
        { label: t("Flights"), value: "flights" },
        { label: t("Guests"), value: "guests" },
        { label: t("Calender"), value: "calender" },
    ],
    toggleValue: toggleValue
} )

export const statusToggleData =(t,value)=> ({
        module: [
            { label: t("All"), value: 100 },
            { label: t("New"), value: 0 },
            { label: t("Confirmed"), value: 1 },
            { label: t("Ready"), value: 2 },
            { label: t("Dispatched"), value: 3 },
            { label: t("Live"), value: 4 },
            { label: t("Completed"), value: 5 },
            { label: t("Cancelled"), value: 6 },
            { label: t("Rejected"), value: 10 },
            { label: t("Invoiced"), value: "19|20" },
            { label: t("Settled"), value: "29|30" },
        ],
        toggleValue: value
    })