import React from 'react'
import { getRouteTypeLabel } from '../../pages/Booking/BookingConstant';
import AddressField from '../AddressField/AddressField';
import GeneralDropdown from '../Dropdowns/GeneralDropdown';
import { getFullAddress } from '../../util/Util';

function RoutePassengerAddressPicker({ type, passenger, stops,onChange }) {

    return (
        <>
            {[2, 3]?.includes(type) ? <div className=' d-flex align-items-center'>
                <div style={{ width: 50 }} className='col-1 d-flex  align-items-center me-3'>
                    <h5 style={{ fontWeight: "bold" }} className="text-left mt-2 ms-2 ">
                        <span>{getRouteTypeLabel(type)} </span>
                    </h5>
                </div>

                <AddressField 
                    value={passenger?.home ?? ""}
                    className={"col-12 col-md-6"}
                    outline
                    onChange={(v) => {
                        onChange( 'pickup', v?.value);
                    }}
                />
            </div> : null}
            {type === 4 ? <div className=' d-flex align-items-center ms-3'>
                <GeneralDropdown
                    className="px-4 col-12 col-sm-4"
                    labelClassName='w-100 rounded-3  overflow-x-hidden'
                    value={getFullAddress(passenger?.pickup) ?? ""}
                    label="Select Pickup Location"
                    data={stops?.map(stop => ({ label: getFullAddress(stop?.address), value: stop?.address }))}
                    onChange={(v) => {
                        // onChange(passengers?.map((pax, i) => (i === index ? { ...pax, pickup: v?.value } : pax)));
                        onChange( 'pickup', v?.value);
                    }}
                />
                <GeneralDropdown
                     className="px-4 col-12 col-sm-4"
                    labelClassName='w-100 rounded-3  overflow-x-hidden'
                    value={getFullAddress(passenger?.drop) ?? ""}
                    label="Select Drop Location"
                    data={stops?.map(stop => ({ label: getFullAddress(stop?.address), value: stop?.address }))}
                    onChange={(v) => {
                        onChange( 'drop', v?.value);
                    }}
                />
            </div> : null}
            {/*   shuttle will have dropdown of selected locations and dropoffs */}
        </>
    )
}

export default RoutePassengerAddressPicker