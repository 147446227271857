
import "../BulkUpload/style.scss";
import DateSelect from "../DateSelects/DateSelect";
import HourMinutePicker from "../DateSelects/HourMinutePicker";
import DateTimePickerWithInterval from "../DateTimePickerWithInterval";
import CompanySelect from "../Dropdowns/Entity/CompanySelect";
import MakeModelSelect from "../Dropdowns/MakeModelSelect/MakeModelSelect";
import OfficeSelect from "../Dropdowns/Office/OfficeSelect";
import { SelectField } from "../SelectField/SelectField";
import DataField from "./DataField";

const DataRow = ({
  rowData,
  rowIndex,
  headerRow,
  getDataType,
  getSelectOptions,
  handleUpdate,
}) => {
  function capitalizeWords(str) {
    let words = str?.split(" ");
    for (let i = 0; i < words?.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words?.join(" ");
  }

  return (
    <>
      {headerRow?.map((header, headerIndex) => {
        if (getDataType(header) == "SELECT") {
          return (
            <td
              className=" py-0 my-0 "
              style={{ maxWidth: "200px", border: "1px solid #ccc", }}
            >
              <SelectField
                data={getSelectOptions(header)}
                valueLabel={rowData[header]?.label ?? rowData[header]}
                value={rowData[header]}
                onChange={(v) => {
                  handleUpdate(rowIndex, header, v.value);
                }}
                outline
                noLabel
                label={header}
                className=""
              />
            </td>
          );
        }
        if (getDataType(header) == "MAKE_MODEL") {
          return (
            <td
              className="d-flex justify-content-center align-items-center py-0 my-0 "
              style={{ maxWidth: "200px", border: "1px solid #ccc", }}
            >
              <MakeModelSelect
                outline
                noLabel
                label="Model Model"
                valueLabel={rowData[header]}
                value={rowData[header]}
                onChange={(v) => {
                  handleUpdate(rowIndex, header, v?.value);
                }}
              />

            </td>
          );
        }

        if (getDataType(header) == "COMPANY_ID") {
          return (
            <td
              className="d-flex justify-content-center align-items-center py-0 my-0 "
              style={{ maxWidth: "200px", border: "1px solid #ccc", }}
            >
              <CompanySelect
                outline
                noLabel
                label="Company"
                valueLabel
                value={rowData[header]?.label ?? rowData[header]}
                onChange={(v) => {
                  handleUpdate(rowIndex, header, { value: v.valueId, label: v?.value });
                }}
              />

            </td>
          );
        }
        if (getDataType(header) == "OFFICE_ID") {
          return (
            <td
              className="d-flex justify-content-center align-items-center py-0 my-0 "
              style={{ maxWidth: "200px", border: "1px solid #ccc", }}
            >
              <OfficeSelect
                outline
                noLabel
                valueLabel
                label="Office"
                value={rowData[header]?.label ?? rowData[header]}
                onChange={(v) => {
                  handleUpdate(rowIndex, header, { value: v.valueId, label: v?.value });
                }}
              />
              {/* {JSON.stringify(rowData[header])} */}
            </td>
          );
        }
        // if (getDataType(header) === "COUNTRY") {
        //   return (
        //     <td
        //       className=" py-0 my-0 "
        //       style={{ maxWidth : "200px", border: "1px solid #ccc", }}
        //     >
        //       <CountrySelect
        //         plain
        //         label="Country"
        //         value={capitalizeWords(rowData[header])}
        //         onChange={(v) => {
        //           handleUpdate(rowIndex, header, v.value);
        //         }}
        //       />
        //     </td>
        //   );
        // }
        // if (getDataType(header) === "STATE") {
        //   return (
        //     <td className=" py-0 my-0 " style={{ maxWidth : "200px", border: "1px solid #ccc", }}>
        //       <StateSelect
        //         plain
        //         label="Country"
        //         value={capitalizeWords(rowData[header])}
        //         onChange={(v) => {
        //           handleUpdate(rowIndex, header, v.value);
        //         }}
        //         country={rowData["Country"]}
        //       />
        //     </td>
        //   );
        // }
        // if (getDataType(header) === "CITY") {
        //   return (
        //     <td className=" py-0 my-0 " style={{ maxWidth : "200px", border: "1px solid #ccc", }}>
        //       <CitySelect
        //         plain
        //         label="City"
        //         value={capitalizeWords(rowData[header])}
        //         onChange={(v) => {
        //           handleUpdate(rowIndex, header, v.value);
        //         }}
        //         country={rowData["Country"]}
        //         state={rowData["State"]}
        //       />
        //     </td>
        //   );
        // }
        // if (getDataType(header) === "COUNTRY CODE") {
        //   return (
        //     <td className=" py-0 my-0 " style={{ maxWidth : "200px", border: "1px solid #ccc", }}>
        //       <CountrySelect
        //         plain
        //         isd={true}
        //         label="Country Code"
        //         onChange={(v) => {
        //           handleUpdate(rowIndex, header, v.value);
        //         }}
        //       />
        //     </td>
        //   );
        // }
        if (getDataType(header) === "GENDER") {
          return (
            <td className=" py-0 my-0 " style={{ maxWidth: "200px", border: "1px solid #ccc", }}>
              <SelectField
                plain
                label="Gender"
                data={[
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" },
                  { label: "Rather Not Say", value: "Rather Not Say" },
                ]}
                onChange={(v) => {
                  handleUpdate(rowIndex, header, v.label);
                }}
                value={capitalizeWords(rowData[header])}
              />
            </td>
          );
        }
        if ( getDataType(header) === "TIME_INTEGER") {
          return (
            <td className=" py-0 my-0 " style={{ maxWidth: "200px", border: "1px solid #ccc", }}>

              <HourMinutePicker
                outline
                timestamp
                noLabel
                value={rowData[header]}
                onChange={(v) => {
                  handleUpdate(rowIndex, header, v);
                }}

              />

      
            </td>
          );
        }


        if (getDataType(header) === "TIME_SELECT" ) {
          return (
            <td className=" py-0 my-0 " style={{ maxWidth: "200px", border: "1px solid #ccc", }}>
              <DateTimePickerWithInterval
                outline
                timestamp
                noLabel
                value={rowData[header]}
                onChange={(v) => {
                  handleUpdate(rowIndex, header, v);
                }} />
            </td>
          );
        }

        // if (getDataType(header) === "AMOUNT") {
        //   return (
        //     <td className=" py-0 my-0 " style={{ maxWidth: "200px", border: "1px solid #ccc", }}>
        //       <AmountField
        //         className={""}
        //         noLabel
        //         value={rowData[header]}

        //         onChange={(v) => {
        //           handleUpdate(rowIndex, header, v);
        //         }}
        //         type="text"
        //       />



        //     </td>
        //   );
        // }


        if (getDataType(header) === "DATE_SELECT" || getDataType(header) === "DATE_INTEGER") {
          return (
            <td className=" py-0 my-0 " style={{ maxWidth: "200px", border: "1px solid #ccc", }}>
              <DateSelect
                value={rowData[header]}
                onChange={(v) => {
                  handleUpdate(rowIndex, header, v);
                }}
                outline
                noLabel
              />
            </td>
          );
        }
        return (
          <td
            className=" py-0 my-0 "
            key={rowIndex + header + headerIndex}
            style={{ maxWidth: "200px", border: "1px solid #ccc", }}
          >
            <DataField
              value={rowData[header]}
              onChange={(newValue) => {
                handleUpdate(rowIndex, header, newValue);
              }}
              type={"String"}
            ></DataField>
          </td>
        );
      })}
    </>
  );
};
export default DataRow;
