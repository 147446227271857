import { useEffect, useRef } from 'react';
import _ from 'lodash';


export const useDeepCompareEffect = (callback, dependencies) => {
    const currentDependenciesRef = useRef();
    if (!_.isEqual(currentDependenciesRef.current, dependencies)) {
        currentDependenciesRef.current = dependencies;
    } else {
    }
    useEffect(() => {
        callback();
    }, [currentDependenciesRef.current]);
}