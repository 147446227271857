import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import Invoice from "../../../pages/Invoice/Invoice";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, Modal, ModalComponent, SearchButton } from "../../index";
import InvoiceSelectService from "./InvoiceSelectService";

const InvoiceSelect = ({
    style,
    isDisabled,
    className,
    onChange,
    required,
    value,
    label,
    showErr,
    inline,
    borderNone,
    multi,
    plain,
    asLabel,
    outline,
    valueLabel,
    noLabel
}) => {
    const [invoices, setInvoices] = useState([]);
    const [modal, setModal] = useState(false);
    const { showError } = useContext(DialogContext)
    const { t } = useContext(I18nContext)

    const fetchInvoices = async () => {
        try {
            await InvoiceSelectService.fetch({ valueLabel });
            setInvoices(toJS(InvoiceSelectService.invoices));
        } catch (error) {
            showError("Error", error)
        }
    };

    useEffect(() => {
        fetchInvoices();
    }, [valueLabel]);

    useEffect(() => {
        setInvoices(toJS(InvoiceSelectService.invoices));
    }, [InvoiceSelectService.invoices]);

    useEffect(() => {
        invoices?.map(invoice => {
            if (invoice?.value == value) {
                onChange(invoice)
            }
        })
    }, [invoices]);

    const openModal = () => {
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };
    const selectInvoice = (selectedValue) => {
        onChange({ value: multi ? selectedValue[0] : selectedValue });
        closeModal();
    };
    const selectInvoices = (selectedValues) => {
        onChange(selectedValues.map((c) => c?.value ?? c));
    };
    const renderSelectField = ({ disabled }) => (
        <SelectField
            style={style}
            isDisabled={isDisabled || disabled}
            changeOptionsData={openModal}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage Invoices")} />
            )}
            plain={plain}
            outline={outline}
            noLabel={noLabel}
            // showBottomBar={true}
            className={className}
            onChange={onChange}
            data={invoices}
            required={required}
            value={value}
            label={label || (!isDisabled && t("Invoice"))}
            error={!value ? t(`Please Select ${label || "Invoice"}`) : ""}
            showErr={showErr}
            inline={inline}
            borderNone={borderNone}
            RightComponent={() => (
                <SearchButton onClick={openModal} />
            )}
        />
    );
    const renderSelectFieldMultiple = () => (
        <SelectFieldMultiple
            isDisabled={isDisabled}
            style={style}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage Invoices")} />
            )}
            // showBottomBar={true}
            className={className}
            onChange={selectInvoices}
            data={invoices}
            plain={plain}
            noLabel={noLabel}
            outline={outline}
            value={value && Array?.isArray(value) ? invoices?.filter((c) => value?.includes(c?.value)) : ""}
            error={!value ? t(`Please Select ${label || "Invoices"}`) : ""}
            required={required}
            label={t("Select Invoices")}
            showErr={showErr}
            RightComponent={() => (
                <SearchButton onClick={openModal} />
            )}
        />
    );
    return (
        <>
            {asLabel ? multi ?
                value?.length > 0 ?
                    (<div> {value?.map((selectedValue, index) => (
                        <span key={selectedValue}>
                            {invoices.find((invoice) => invoice.value === selectedValue)?.label}
                            {index < value.length - 1 && ", "}
                        </span>
                    ))}</div>) : <div style={{ color: "grey" }}>No Record Found</div>
                : (<div>{invoices?.find((invoice) => invoice?.value == value)?.label ?? <div style={{ color: "grey" }}>No record Found</div>}</div>) :
                <>
                    {modal && (
                        <ModalComponent
                            position={"top"}
                            size={"large"}
                            onToggle={closeModal}
                            isOpen={modal}
                        >
                            <ModalComponent.Title>
                                {t("Invoice")}
                            </ModalComponent.Title>
                            <ModalComponent.Body>
                                <Invoice
                                    value={value}
                                    insidePane={true}
                                    multiMode={multi}
                                    onSelect={multi ? selectInvoices : selectInvoice}
                                />
                            </ModalComponent.Body>
                        </ModalComponent>
                    )}
                    {invoices?.length > 0
                        ? multi
                            ? renderSelectFieldMultiple()
                            : renderSelectField({ disabled: false })
                        : renderSelectField({ disabled: true })}
                </>

            }
        </>
    );
};

export default observer(InvoiceSelect);
