import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./SubscriptionConstant";
import { doGET, doPOST } from "../../util/HttpUtil";

class SubscriptionService {
  records = [];
  totalRecords = 0;
  places = [];
  page = 1;
  rows = 10;

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async function () {
    const response = await doGET(ENDPOINTS.grid(this.page, this.rows));

    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }
  };

  get = async function (id) {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      return response.data;
    }
  };

  getInvoices = async function (id) {
    const response = await doGET(ENDPOINTS.getInvoices(id));
    if (response.status === 200) {
      return response.data.rows;
    }
  };

  save = async function () {
    await doPOST(ENDPOINTS.save);
    this.fetch();
  };

  //   delete = async function (id) {
  //     const response = await doGET(ENDPOINTS.delete(id));
  //     if (response.status === 200) {
  //       this.fetch();
  //     }
  //   };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch();
  };
}

const Subs = new SubscriptionService();
export default Subs;
