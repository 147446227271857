import { debounce } from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { doDELETE, doPUT } from '../../util/HttpUtil';
import { observer } from 'mobx-react-lite';
import { SelectField } from '../SelectField/SelectField';
import { InputField } from '../InputField/InputField';
import { TiDeleteOutline } from "react-icons/ti";
import { Tooltip } from 'react-tooltip';
import customOperationsService from './CustomOperationsService';

const FIELD_TYPES = [
    { value: "text", label: 'Text' },
    { value: "dropdown", label: 'Dropdown' },
    { value: "checkbox", label: 'Checkbox' },
];

const CreateField = ({ module, field = {}, onSave = () => { } }) => {
    const [fieldState, setFieldState] = useState({
        ...field,
        fieldType: field.fieldType ?? "",
        label: field.label ?? "",
        placeholder: field.placeholder ?? "",
    });

    const debouncedUpdateField = useCallback(debounce(async (fieldData) => {
        const response = await doPUT(`/api/custom-field/update`, fieldData);
        if (response.status === 200) {

            console.log("Field updated successfully.");
            onSave();
        }
    }, 1000), []);

    const updateField = (key, value) => {
        const updatedFieldState = { ...fieldState, [key]: value };
        setFieldState(updatedFieldState);
        debouncedUpdateField(updatedFieldState);
    };

    const deleteField = async (id) => {
        const response = await doDELETE(`/api/custom-field/${id}/delete`);
        if (response.status === 200) {
            await customOperationsService.fetch(module);
        }
    };

    useEffect(() => {
        setFieldState({
            ...field,
            fieldType: field.fieldType,
            label: field.label,
            placeholder: field.placeholder,
        });
    }, [field]);

    return (
        <div className='d-flex align-items-center'>
            <div className="d-flex flex-1  row">
                <SelectField
                    outline
                    label="Field Type"
                    value={fieldState.fieldType}
                    data={FIELD_TYPES}
                    onChange={e => updateField("fieldType", e?.value)}
                    className="col-4"
                />
                <InputField
                    type="text"
                    outline
                    placeholder="Label"
                    label="Label"
                    value={fieldState.label}
                    className="col-4"
                    onChange={e => updateField("label", e)}
                />
                <InputField
                    type="text"
                    outline
                    placeholder="Placeholder"
                    label="Placeholder"
                    value={fieldState.placeholder}
                    className="col-4"
                    onChange={e => updateField("placeholder", e)}
                />
            </div>

            <Tooltip
                style={{
                    zIndex: '200000',
                }}
                anchorSelect={`#not-clickable-${fieldState?._id}-priority`}
            >
                Delete Field
            </Tooltip>

            <TiDeleteOutline
                onClick={() => { deleteField(fieldState?._id) }}
                size={24}
                style={{outline : "none", border : "none"}}
                className='ms-3'
                id={`not-clickable-${fieldState?._id}-priority`}
            />

        </div>

    );
};

export default observer(CreateField);
