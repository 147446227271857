import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { CitySelect, CountrySelect, StateSelect } from '../../../components'

const TransporterStep2 = ({ data, setData }) => {

    const { operationZone = [] } = data;

    const [operatingData, setOperatingData] = useState(operationZone)
    useEffect(() => {
        if (JSON.stringify(data?.contactPersons) !== JSON.stringify(operatingData))
            setData({ ...data, operationZone: operatingData });
    }, [operatingData]);
    return (
        <>
            {operatingData?.map((operating, i) => {
                return (
                    <>
                        <Row className='mt-4'>
                            <Col className=' px-4'>
                                <CountrySelect
                                    className=" m-auto px-2"
                                    value={operating?.country}
                                    required={true}
                                    onChange={(v) =>
                                        setOperatingData(
                                            operatingData?.map((f, x) => {
                                                if (i === x) return { ...operating, country: v.value };
                                                return f;
                                            })
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col className=' px-4'>
                                <StateSelect
                                    value={operating?.state}
                                    className=" m-auto px-2"
                                    onChange={(v) =>
                                        setOperatingData(
                                            operatingData?.map((f, x) => {
                                                if (i === x) return { ...operating, state: v.value };
                                                return f;
                                            })
                                        )
                                    }
                                    country={operating?.country}

                                />
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col className=' px-4'>
                                <CitySelect
                                    value={operating?.city}
                                    state={operating?.state}
                                    className=" m-auto px-2"
                                    country={operating?.country}
                                    onChange={(v) =>
                                        setOperatingData(
                                            operatingData?.map((f, x) => {
                                                if (i === x) return { ...operating, city: v?.value };
                                                return f;
                                            })
                                        )
                                    }
                                />
                            </Col>
                        </Row>

                    </>
                )
            })}

            <div className=' mt-3 float-end px-4'>
                <Button onClick={() => setOperatingData([...operatingData, {}])} style={{ backgroundColor: "#DF6463", border: "none", borderRadius: "8px" }}> <span className=' fa-1x fw-bolder'>+</span> Add Operating Zone</Button>
            </div>

        </>
    )
}

export default TransporterStep2