import React, { useCallback, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { monthNames } from '../../../common/utils/Constants';

const SplineChart = ({ tripData=[], stayData=[], flightData=[] }) => {
    const [monthrange, setMonthrange] = useState([]);
    const [tripRevenue, setTripRevenue] = useState([]);

    const getPreviousMonths = (numMonths = 6) => {
        const months = [];
        const now = new Date();

        for (let i = numMonths - 1; i >= 0; i--) {
            const date = new Date(now);
            date.setMonth(now.getMonth() - i);
            const year = date.getFullYear();
            const monthName = monthNames[date.getMonth()];

            months.push(`${monthName} ${year}`);
        }
        setMonthrange(months);
    };

    const getTotalRevenuePerMonth = useCallback((data) => {
        const monthlyTotals = data.reduce((acc, item) => {
            const year = Math.floor(item._id.tripDate / 10000);
            const month = Math.floor((item._id.tripDate % 10000) / 100);
            const monthYear = `${monthNames[month - 1]} ${year}`;
            
            if (!acc[monthYear]) {
                acc[monthYear] = 0;
            }
            acc[monthYear] += item.total_fee;
            return acc;
        }, {});

        const revenue = monthrange.map(month => (monthlyTotals[month]/100).toFixed(2) || 0);
        setTripRevenue(revenue);
    },[monthNames, monthrange, setTripRevenue]);

    useEffect(() => {
        getPreviousMonths();
    }, []);

    useEffect(() => {
        if (monthrange.length > 0) {
            getTotalRevenuePerMonth(tripData);
        }
    }, [monthrange, tripData,getTotalRevenuePerMonth]);

    const data = {
        categories: monthrange,
        barSeries: [
            {
                name: 'Trips',
                type: 'bar',
                data: tripRevenue
            }
        ],
        areaSeries: [
            {
                name: 'Flights',
                type: 'area',
                data: [0,0,0,0,0,0] // Api Not available
            }
        ],
        lineSeries: [
            {
                name: 'Stays',
                type: 'line',
                data: [0,0,0,0,0,0] // Api Not available
            }
        ]
    };

    const options = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false,
            toolbar: {
                show: false
            }
        },
        stroke: {
            width: [0, 2, 5],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '20%'
            }
        },
        fill: {
            opacity: [0.85, 0.25, 1]
        },
        labels: data.categories,
        markers: {
            size: 0
        },
        xaxis: {
            categories: data.categories
        },
        yaxis: {
            title: {
                text: 'Values'
            },
            min: 0
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== 'undefined') {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }
        }
    };

    const series = [
        ...data.barSeries,
        ...data.areaSeries,
        ...data.lineSeries
    ];

    return (
        <div className="chart-container d-flex flex-column w-100">
            <ReactApexChart options={options} series={series} type="line" height={300} />
        </div>
    );
};

export default SplineChart;
