import React from 'react'
import { Table } from 'reactstrap'
import moment from 'moment'
import SupervisiorAssigner from '../Dropdowns/SupervisiorSelect/SupervisiorAssigner'
import { BiPencil } from 'react-icons/bi'

function ViewGrid({ data = [],onClick }) {

    return (
        <Table striped className="table-borderless table-centered table-nowrap">
            <tbody>
                <tr style={{ borderBottom: "1px solid #EBEBEB" }}>
                    <td>Name</td>
                    <td>From Date</td>
                    <td>Till Date</td>
                    <td>Supervisors</td>
                    <td></td>
                </tr>

                {data?.map((data, index) => (
                    <tr key={index}>
                        <td>
                            <div className="font-size-13 mb-1 fw-normal">  {data.name ?? "-"}</div>
                        </td>

                        <td>
                            <div className="font-size-13 mb-1 fw-normal">{moment(data?.fromDate, 'YYYYMMDD').format("DD-MMM-YYYY") ?? "-"}</div>
                        </td>

                        <td>
                            <div className="font-size-13 mb-1 fw-normal">{moment(data?.tillDate, 'YYYYMMDD').format("DD-MMM-YYYY")}</div>
                        </td>

                        <td>
                            <div>
                                {<SupervisiorAssigner asLabel value={data?.supervisors ?? []} />}
                            </div>
                        </td>
                        <td>
                            <div className='p-2 cursor-pointer' onClick={()=>onClick({editId:data?._id,visible:true})}>
                                <BiPencil />
                            </div>
                            {/* <div>

                            </div> */}
                        </td>



                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default ViewGrid