import React from 'react';

function EmailTags({ type, className, ...props }) {
  const getTagData = () => {
    switch (type) {
      case 'trips':
        return { label: 'Booking', colorClass: '#E36F6E' };
      case 'stays':
        return { label: 'Stays', colorClass: '#7286EA' };
      case 'flights':
        return { label: 'Flights', colorClass: '#567889' };
      default:
        return { label: '', colorClass: '' };
    }
  };

  const { label, colorClass } = getTagData();

  return (
    <div
      className={`d-inline-block px-2 text-white font-size-11 borderRadius-5 py-1 w-mx ${className}`}
      style={{ backgroundColor: colorClass }}
      {...props}
    >
      <span>{label}</span>
    </div>
  );
}

export default EmailTags;
