import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

export default function BackButton(props) {
  return (
    <Link to={props?.link}>
      <Button
        onClick={props?.onClick}
        type="submit"
        className="mx-2 mt-2"
        disabled={props?.disabled}
        style={{ height: "28px", padding: "2px 4px" }}
        color="primary"
      >
        <i
          style={{ fontSize: "18px", marginTop: "2px", marginRight: "5px" }}
        ></i>
        <div style={{ margin: "2px", float: "right" }}>Back</div>
      </Button>
    </Link>
  );
}
