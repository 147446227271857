import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./SOSLogConstant";
import { doGET, doPOST } from "../../util/HttpUtil";

class SOSLog {

    records = [];
    totalRecords = 0;
    places = [];
    page = 1;
    rows = 10;

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async function (filterData) {
        const response = await doGET(ENDPOINTS.getAllSOSLogs(this.page, this.rows, filterData))
        if (response.status === 200) {
            this.records = response.data.rows;
            this.totalRecords = response.data.records;
        }
    };

    updateSOSLog = async function (sosLogData) {
        await doPOST(ENDPOINTS.updateSOSLog, sosLogData)
        this.fetch();
    };


    onPaginationChange = async (page, rows) => {
        this.page = page;
        this.rows = rows;
        this.fetch();
    }

}

const SOSLogService = new SOSLog();
export default SOSLogService;
