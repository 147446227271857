import { makeAutoObservable, toJS } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { ENDPOINTS } from "./CompanyConstant";
import { getUiPrefRows } from "../../util/FilterUtil";
class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = null;
  filterOption = "";
  total = -1;
  version = 0;
  companiesCache = {};

  constructor() {
    makeAutoObservable(this);
  }
  fetch = async (filterUrl) => {
    if (!this?.rowsPerPage) {
      const rows = await getUiPrefRows("entity.grid")
      this.rowsPerPage = rows ?? 40
    }

    if (filterUrl) {
      this.filterOption = filterUrl;
    }
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl)
    );

    if (response?.status === 200) {
      this.records = response.data.rows;
      this.page = response?.data?.page;
      if (this.page === 1) this.total = response?.data?.total;
    }
    return response.data.rows
  };

  fetchAll = async function ({ valueLabel = false, corp_id, corpType }) {

    //TO verify again
    
    if (this.companiesCache[corpType === "self" ? "mine" : corp_id ?? "mine"]) {
      //checking corptype 
      // console.log("this.companiesCache" + corpType, toJS(this.companiesCache["mine"]))
      return this.companiesCache[corpType === "self" ? "mine" : corp_id ?? "mine"]
    }
    try {
      const query = corpType === "supplier" ? `supplier_id=${corp_id}` : corpType === "client" ? `client_id=${corp_id}` : `supplier_id=notExists&client_id=notExists`
      const grid = `${ENDPOINTS.grid()}${query}`;
      const response = await doGET(grid);
      if (response.status === 200) {
        const formattedEntities = response?.data?.rows?.map((v) => {
          if (valueLabel) {
            return { ...v, label: v.name, value: v.name, valueId: v?._id };
          }
          return { ...v, label: v.name, value: v._id };
        });
        this.companiesCache = { ...this.companiesCache, [corpType == "self" ? "mine" : corp_id ?? "mine"]: formattedEntities };
        return formattedEntities
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };


  edit = async function (data, id) {
    const response = await doPUT(ENDPOINTS?.update, data);
    if (response?.status === 200) {
      this.version += 1;
      this.companiesCache = {}
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    if (response?.status === 200) {
      this.companiesCache = {}
    }
    return response?.data?._id;
  };

  delete = async ({ id, client_id, supplier_id }) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      if (client_id) {
        const filterUrl = "client_id=" + client_id;
        this.fetch(filterUrl);
      } else if (supplier_id) {
        const filterUrl = "supplier_id=" + supplier_id;
        this.fetch(filterUrl);
      }
      else {
        this.fetch();
      }
      this.companiesCache = {}
    }
  };

  bulkDelete = async (ids = []) => {
    const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
    if (response.status === 200) {
      this.fetch();
    }
  };

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);

  };



}

const EntityService = new Service();
export default EntityService;
