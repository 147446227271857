
import { CompanySelect } from "../../components";

export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Title",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.title}
          </div>
        );
      },
      show: true,
    },
    {
      title: "Route No.",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.routeNo}
          </div>
        );
      },
      show: true,
    },
    {
      title: "Next Trip Start Time",
      renderer: (dataObj) => {
        return (
          <div>
            <div><strong><span>{
              dataObj.nextRunTime != undefined ? (
                (dataObj.nextEndTime?.toString())?.slice(6, 8) +
                '-' +
                (dataObj.nextEndTime?.toString())?.slice(4, 6) +
                '-' +
                (dataObj.nextEndTime?.toString())?.slice(0, 4)
              ) : (
                '--'
              )}</span></strong>
            </div>
          </div>
        );
      },
      show: true,
    },
    {
      title: "Next Trip End Time",
      renderer: (dataObj) => {
        return (
          <div><strong><span>{
            dataObj.nextEndTime != undefined ? (
              (dataObj.nextEndTime?.toString())?.slice(6, 8) +
              '-' +
              (dataObj.nextEndTime?.toString())?.slice(4, 6) +
              '-' +
              (dataObj.nextEndTime?.toString())?.slice(0, 4)
            ) : (
              '--'
            )}</span></strong>
          </div>
        );
      },
      show: true,
    },
    {
      title: "Status",
      renderer: (dataObj) => {
        return (
          <div style={{
            backgroundColor:
              dataObj.status === 1
                ? "Red"
                : dataObj.status === 2
                  ? "Green"
                  : null,
            width: "0.8rem", // Adjust the size of the circle
            height: "0.8rem", // Adjust the size of the circle
            borderRadius: "50%", // Makes it a circle,
            marginLeft: "10px"
          }}>
          </div>
        );
      },
      show: true,
    },

    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
