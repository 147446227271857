import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FaBell, FaPlus } from 'react-icons/fa';
import { VscCircleFilled } from "react-icons/vsc";
import { I18nContext } from '../../../store/context/i18nContext';
import './style.css';
import { objectIdToColor } from '../board/helper';
import { Button } from 'reactstrap';

const CustomEvent = ({ onAdd = () => { }, event, onRequestGrid = () => { }, onRequestForm = () => { } }) => {
  const { t } = useContext(I18nContext);

  return (
    <div className="w-100 align-items-end d-flex flex-column h-100 flex-1  zr_leadReminders_custom-lead-event" onClick={(e) => { e.stopPropagation() }}>
      <div className='d-flex align-items-center'>
        <Button
          onClick={() => { onAdd() }}
          style={{ padding: "1px" }}
          className="zr_leadReminders_custom-add-button align-items-center me-2 bg-success">
          <FaPlus size={10} className='' />
        </Button>

        {
          event.segments.filter(segment => segment._id).length > 0 && (
            <div onClick={onRequestGrid} className='d-flex align-items-center'>
              <FaBell className="fas fa-ellipsis-v me-1" />
              <span>{event.segments.filter(segment => segment._id).length}</span>
            </div>
          )
        }

      </div>

      <div style={{ flex: 1 }} className='d-flex flex-1 flex-column w-100 align-items-start'>
        {event.segments.length > 2 ? (
          <>
            {event.segments.slice(0, 2).map((segment, index) => {
              if (!segment?._id) return <></>
              return <div key={index} onClick={() => onRequestForm(segment)} className="w-100 zr_leadReminders_segment-item">
                <VscCircleFilled color={objectIdToColor(segment.lead_id)} />
                {segment.action}
              </div>
            })}
            <div onClick={onRequestGrid} className="zr_leadReminders_show-more">{event.segments.length - 2} more</div>
          </>
        ) : (
          event.segments.map((segment, index) => {
            if (!segment?._id) return <></>
            return <div key={index} onClick={() => onRequestForm(segment)} className="w-100 zr_leadReminders_segment-item">
              <VscCircleFilled color={objectIdToColor(segment.lead_id)} />
              {segment.action}
            </div>
          })
        )}
      </div>


    </div>
  );
};



export default observer(CustomEvent);




