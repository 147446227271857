import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import VehicleService from '../../../pages/Vehicle/VehicleService';
import SideBarService from '../SideBarService';
import VehiclePopup from '../popups/VehiclePopup';
import './style.css';
import MakeModelSelect from '../../Dropdowns/MakeModelSelect/MakeModelSelect';
import { VehicleTypeSelect } from '../..';

const VehicleFlyout = ({ onClose, data }) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredVehicles, setFilteredVehicles] = useState([]);

    const handleRowClick = (vehicle, index) => {
        if (data[index]?.current?.lat && data[index]?.current?.lng) {
            SideBarService?.setSelectedMarkers({
                ...data[index],
                coordinate: { lat: data[index]?.current?.lat, lng: data[index]?.current?.lng },
                popupComponent: <div><VehiclePopup vehicle={data[index]} /></div>,
                markerImage: require("./../../../../src/assets/images/car-top.png"),
                onClose: () => {
                    SideBarService?.setSelectedMarkers(null)
                }
            })
        } else {
            SideBarService?.setSelectedMarkers({ _id: vehicle?._id })
        }
        setSelectedRow(vehicle);
    };

    const handleSearch = (e) => {
        const { value } = e.target;
        setSearchQuery(value);
    };


    useEffect(() => {
        if (searchQuery) {
            const filtered = data?.filter(
                (vehicle) =>
                    vehicle.regNo.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredVehicles(filtered);
        } else {
            setFilteredVehicles(data || []);
        }
    }, [searchQuery, data]);

    return (
        <div className='flyout'>
            <div onClick={onClose} className='flyout-close'>
                <RxCross2 />
            </div>
            <div className='padded-container'>
                <div className='flyout-header'>
                    <h2>Vehicles</h2>
                </div>
                <input
                    type='text'
                    placeholder='Search By Reg No'
                    className='search-field'
                    value={searchQuery}
                    onChange={handleSearch}
                />
            </div>

            <div className='flyout-table'>
                <div style={{ backgroundColor: "#f0f0f0" }}>
                    <div className='table-header'>
                        <div className='status-cell'></div>
                        <div className='regNo-cell' >Reg No</div>
                        <div className='header-cell'>Make Model</div>
                    </div>
                </div>
                <div className='table-body'>
                    {(filteredVehicles).map(
                        (vehicle, index) => (
                            <div
                                key={index}
                                className={`table-row ${SideBarService?.selectedMarker?._id === vehicle._id ? `selected-row-${vehicle?.kind}` : ''
                                    }`}
                                onClick={() => handleRowClick(vehicle, index)}
                            >
                                <div className={`status-cell bg-${vehicle?.kind} `}></div>
                                <div className='regNo-cell'>
                                    {/* {vehicle.status === 1
                                    ? 'Active'
                                    : vehicle.status === 2
                                        ? 'Inactive'
                                        : vehicle.status === 3
                                            ? 'Workshop'
                                            : vehicle.status === 4
                                                ? 'Sold'
                                                : null}  */}
                                    {vehicle.regNo}</div>
                                <div className='cell'>
                                    <div className="d-flex">
                                        {/* <VehicleTypeSelect
                                            asLabel
                                            value={vehicle?.vehicleType}
                                            onChange={(v) => { }}
                                        /> */}
                                        <MakeModelSelect
                                            asLabel
                                            value={vehicle?.makeModel}
                                            onChange={(v) => { }}
                                        />
                                    </div>

                                </div>


                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default observer(VehicleFlyout);
