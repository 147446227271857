import React from 'react';
import { Button } from 'reactstrap';
import "./style.css"

const FlyoutButton = ({
    onClick,
    text,
    icon,
    className,
    statusColor,
    style,
    active
}) => {
    const buttonStyle = {
        backgroundColor: active ? '#2B2D2FEE' : '#fff',
        fontWeight: active ? 'bold' : 'normal', // Set font weight based on active prop
        color: active ? '#ffffff' : '#000000' // Change text color based on active prop
    };

    return (
        <div className={`flyout-button position-relative p-0 ${className}`} style={style}>
            <Button
                className="flyout-button__main p-0"
                onClick={onClick}
                style={buttonStyle}
            >
                <div>
                    {statusColor ? <span style={{ borderRadius: "5px 0px 0px 5px", border: "1px solid #ccc", borderRight: "0px solid #ccc", left: 0, top: 0, minWidth: '10px', minHeight: '100%', position: 'absolute', backgroundColor: statusColor }}></span> : null}
                </div>
                <div style={{ padding: '8px 16px' }}>
                    {icon && icon}
                    {text}
                </div>
            </Button>
        </div>
    );
};

export default FlyoutButton;
