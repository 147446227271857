import { ThreeBounce } from "better-react-spinkit";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Row } from "reactstrap";
import { CardThreeBounce, DeleteButton, InputField, SaveButton, SelectField, ToggleSelector } from "../../components";
import DraggableBox from "../../components/Draggable/Draggable";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "./TemplateConstant";
import Templateservice from "./TemplateService";
import { Input } from 'reactstrap';
import { FilterModalButton } from "../Filter/FilterModalButton";

const EditTemplates = ({
  editId,
  onSave,
  onDelete,
  insidePane,
  kind,
  forUpload,
  oncancel
}) => {
  const { t } = useContext(I18nContext);
  let navigate = useNavigate();
  const { userContent } = useContext(UserContext);
  const { showError, showMessage, showConfirm } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [allFields, setAllFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [data, setData] = useState({ forUpload: (forUpload === true || forUpload === false) ? forUpload : true });
  const [hasErr, setHasErr] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [filteredFields, setFilteredFields] = useState([]);
  useEffect(() => {
    setData({ ...data, kind: kind });
  }, [kind]);

  const fetchFields = async ({ templateKind, dbSelectedFields = [] }) => {
    try {
      setLoading(true);
      let fields = await Templateservice.getFields(templateKind ?? data?.kind);
      setAllFields(fields);
      setFilteredFields(fields);
      const preSelectedFields = dbSelectedFields?.length ? dbSelectedFields : fields.filter((field) => field.required);
      setSelectedFields(preSelectedFields);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetch = async () => {
    try {
      setLoading(true);
      const template = await Templateservice.get(editId);
      setData(kind ? { ...template, kind } : template);
      console.log(template);
      fetchFields({ templateKind: template?.kind, dbSelectedFields: template?.contents ?? [] });
      setSelectedFields(template?.contents ?? []);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const renderField = ({ selectedFields, field, onSelectField = () => { } }) => {
    const isSelected = selectedFields.find(v => v.field == field.field)?.title;

    return (
      <div className="d-flex align-items-center mb-2 ">
        <Input
          type="checkbox"
          checked={isSelected}
          onChange={() => onSelectField(field)}
        />
        <span className="ms-2">{field?.title}</span>
      </div>
    );
  };



  const handleSave = async (e) => {
    e.preventDefault();
    if (!data?.name || !data?.kind) {
      showError(t("Please provide template name"));
      return;
    }
    setLoading(true);
    try {
      if (editId) {
        await Templateservice.edit(data, editId);
        onSave(editId);
        showMessage(t("Template Updated successfully"));
      } else {
        const template = await Templateservice.save(data);
        showMessage(t("Template saved successfully."));
        if (!insidePane) navigate(`/templates/edit/${template}`);
        onSave(template);
      }
    } catch (e) {
      showError(e);
    }
    setHasErr(false);
    setLoading(false);
  };

  const handleDelete = async (event, id) => {
    event.stopPropagation();
    if (await showConfirm({
      title: t("Do you want to delete record?"),
      description: t("This is an unrecoverable operation."),
    })) {
      setLoading(true);
      try {
        await Templateservice.delete(id);
        setLoading(false);
        onDelete();
        showMessage(t("Template Deleted Successfully"));
        if (!insidePane) navigate(`/templates`);
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const handleSearch = () => {
    const filtered = allFields.filter((field) =>
      field.title.toLowerCase().includes(searchField.toLowerCase())
    );
    setFilteredFields(filtered);
  };
  useEffect(() => {
    if (selectedFields?.length) {
      setData({ ...data, contents: selectedFields });
    }
  }, [selectedFields]);

  useEffect(() => {
    setData(kind ? { kind, forUpload: (forUpload === true || forUpload === false) ? forUpload : true } : { forUpload: (forUpload === true || forUpload === false) ? forUpload : true });
    setAllFields([]);
    setSelectedFields([]);
    if (insidePane && !editId) {
      fetchFields({ templateKind: kind })
    };
    if (editId) fetch();
  }, [editId]);

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      {loading ?
        <CardThreeBounce /> :
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
          <div className="zr_layout-header p-3 d-flex justify-content-between" style={{ flexShrink: 0 }} >
            <div className="d-flex align-items-center px-2">
              {data?._id ? t("Edit Template") : t("Add Template")}
            </div>
          </div>
          <div
            className="p-3"
            style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
          >
            <Row className="mb-3 d-flex ">
              {!insidePane && <div className="d-flex align-items-center">
                <div style={{ minWidth: "120px ", fontSize: 12, fontWeight: "500" }}>
                  {"Template Type"}
                  {<font color="red">*</font>}
                </div>
                <ToggleSelector
                  compact
                  label={""}
                  zeroMargin
                  data={[
                    { label: t("For Download"), value: "forDownload" },
                    { label: t("For Upload"), value: "forUpload" },
                  ]}
                  value={data?.forUpload ? "forUpload" : "forDownload"}
                  onChange={(selectedValue) => {
                    setData({ ...data, forUpload: selectedValue == "forUpload" ? true : false });
                  }}
                  className="col-sm-6"
                  style={{
                    width: "210px"
                  }}
                />
              </div>}


              <InputField
                placeholder={t("Template Name")}
                value={data?.name}
                required={true}
                label={t("Template Name")}
                onChange={(v) => setData({ ...data, name: v })}
                error={data?.name?.length === 0 ? t("Please enter  Name") : ""}
                showErr={hasErr}
                type="text"
                className="col-md-3 col-12"
              // rightComponent = {()=>}
              />

              {insidePane && data?.kind ? <div className="d-flex"> {/* <b style={{ minWidth: "120px" }}>Module</b> {data?.kind}  */} </div> : <SelectField
                data={SELECTSTRUCTURE.kindType}
                value={data?.kind}
                onChange={(v) => {
                  setData({ ...data, kind: v.value })
                  fetchFields({ templateKind: v.value });

                }
                }
                showErr={hasErr}
                label={t("Module Kind")}
                required={true}
              />}
            </Row>
            <Row>

              <div className="col-12 col-md-7 col-xl-9">
                <div className="d-flex mb-3 col-4 ">
                  <InputField className="w-100" label="Search Field" value={searchField} onChange={(v) => setSearchField(v)}
                  />
                  {searchField && <i
                    onClick={() => {
                      setSearchField("")
                      setFilteredFields(allFields)
                    }}
                    className="dripicons-cross"
                    // id="buttonCloseFilter"
                    size={22}
                    style={{ height: "20.5px", marginTop: "18px", cursor: "pointer", position: "relative", left: "-30px", background: "#d2d4d8", fontSize: "large" }}
                  ></i>}
                  <Button
                    color="primary"
                    style={{
                      position: "relative",
                      padding: "0px",
                      height: "35px", top: "10px", left: "-15px"
                    }}
                    onClick={handleSearch}
                  >
                    <i style={{ fontSize: "24px", margin: "2px 2px" }} className="bx bx-search-alt-2" />
                  </Button>
                </div>
                <h5>All Fields</h5>
                <div className="row ">
                  {filteredFields.map((field, index) => (
                    <div className="col-12 col-sm-6 col-xl-4 " key={field.id}>
                      {renderField({
                        selectedFields,
                        field,
                        index,
                        onSelectField: (field) => {
                          const isSelectedTitle = selectedFields.find(v => v.field == field.field)?.title;
                          if (isSelectedTitle) {
                            setSelectedFields(selectedFields.filter((f) => f.title !== isSelectedTitle));
                          } else {
                            setSelectedFields([...selectedFields, field]);
                          }
                        }
                      })}
                    </div>
                  ))}
                </div>

              </div>


              <div className="col-12 col-md-5 col-xl-3">

                <DraggableBox
                  value={{
                    'Selected Fields': {
                      title: 'Selected Fields',
                      items: selectedFields?.map((v, index) => { return { ...(v ?? {}), id: `${index}` } }),
                    },
                  }}
                  onChange={(v) => { setSelectedFields((v["Selected Fields"] ?? {})?.items ?? []) }}
                />
              </div>

            </Row>
          </div>
          <div className="zr_layout-footer d-flex justify-content-end pe-4" style={{ flexShrink: 0, marginBottom: insidePane ? "20px" : "0px" }}>
            {userContent?.rights?.includes(2403) && data?._id ? <DeleteButton onClick={(e) => handleDelete(e, data?._id)} loading={loading} /> : null}
            {userContent?.rights?.includes(2402) ? <SaveButton onClick={handleSave} loading={loading} /> : null}
            {insidePane && <Button
              color="primary"
              className="ms-3   waves-light mb-1 mt-2  "
              style={{ height: "28px", padding: "2px 10px 2px 4px", width: "90px" }}
              onClick={oncancel}
            >
              {loading ? <ThreeBounce /> : "Cancel"}

            </Button>}
          </div>
        </div>
      }

    </React.Fragment>
  );
};

export default observer(EditTemplates);
