import React, { useContext, useEffect, useState } from 'react';
import { I18nContext } from '../../store/context/i18nContext';
import { InputField } from '../InputField/InputField';
import "./style.css";
import { doGET } from '../../util/HttpUtil';

const TagSelector = ({ header, label, kind, value = [], onChange = () => { }, hasErr, onlyBanners }) => {
  const [selectedTags, setSelectedTags] = useState([]); // Initialize with provided value
  const { t } = useContext(I18nContext);
  const [groups, setGroups] = useState([]);
  const [labels, setLabels] = useState([]);
  const [hoveredGroup, setHoveredGroup] = useState(null); // Track the hovered group
  const [allTags, setAllTags] = useState({})
  const [availableLabels, setAvailableLabels] = useState([]);

  const fetchTags = async () => {
    const response = await doGET(
      "/api/tag-element/grid"
    );
    setAllTags(response?.data)
  }

  useEffect(() => {
    fetchTags()
  }, [])

  useEffect(() => {
    setSelectedTags(value?.map((item) => {
      const correspondingTag = allTags?.rows?.find((tag) => tag?._id === item?.tag_id);
      if (correspondingTag) {
        return {
          ...correspondingTag,
          details: item.details,
          moreDetails: item.moreDetails,
        };
      }
      return null;
    }).filter((tag) => tag !== null));

    // TODO availabelelabel should update here also , selectedTags should not be inside 
  }, [JSON.stringify(value), allTags])



  const loadGroups = () => {
    const selectedKindGroups = allTags?.rows?.filter(tag => tag.kind === kind);
    const uniqueGroups = [...new Set(selectedKindGroups?.map(tag => tag.group))];
    setGroups(uniqueGroups);
  };


  const selectTag = (tag) => {
    const updatedTags = [...selectedTags, tag];
    // Remove the corresponding tag from availableLabels
    const updatedAvailableLabels = availableLabels.filter(label => label._id !== tag._id);
    setAvailableLabels(updatedAvailableLabels);
    onChange(tagFormatter(updatedTags));
  };


  const tagFormatter = (tags) => {

    const formattedTags = tags?.map((tag) => {
      return {
        tag_id: tag?._id ?? tag?.tag_id,
        details: tag?.details,
        moreDetails: tag?.moreDetails
      }
    })

    return formattedTags
  }

  const removeTag = (tag) => {
    const updatedTags = selectedTags.filter(selectedTag => selectedTag._id !== tag._id);
    // Add the corresponding tag back to availableLabels
    const updatedAvailableLabels = [...availableLabels, tag];
    setAvailableLabels(updatedAvailableLabels);
    onChange(tagFormatter(updatedTags));
  };


  useEffect(() => {
    loadGroups();
    const allLabels = allTags?.rows?.filter(tag => tag.kind === kind);
    setLabels(allLabels);
    setAvailableLabels(allLabels); // Initialize availableLabels with all labels
  }, [kind, allTags]);


  const labelsFromGroup = () => {
    if (hoveredGroup) {
      return availableLabels.filter(label => label.group === hoveredGroup);
    }
    return [];
  };

  return (
    <div>

      {
        onlyBanners ?
          <div className='d-flex w-100'>
            {selectedTags?.map((tag, index) => (
              <span
                style={{
                  backgroundColor: tag.color,
                  color: "white"
                }}
                className='me-3 px-2'
              >
                {tag.label}
              </span>
            ))}
          </div>
          :
          <div className="d-flex align-items-center justify-content-start tag-selector ml-3" style={{paddingLeft:'180px'}}>


            <div className="d-flex ">
              {selectedTags?.map((tag, index) => (
                <div style={header ? { position: "absolute", top: 20 } : {}} key={tag?.tag_id}>
                  <div >
                    <div style={{ borderRadius: "10px", backgroundColor: tag?.color }} className="d-flex justify-content-center align-items-center px-2 me-3">
                      <div
                        style={{ color: "white", backgroundColor: tag?.color }}
                        className=" color-white">
                        {tag?.label}
                      </div>
                      {/* <div
                        className=" p-0 px-1 pe-2  "
                        style={{ color: "white", backgroundColor: tag?.color, borderRadius: "0px", }}
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        id={`dropdownMenuButton2-${index}`}
                      >
                        <div className="arrow-down"></div>
                      </div> */}

                      <div
                        className=" p-1 ps-2"
                        style={{
                          color: "white", backgroundColor: tag?.color
                        }}
                        onClick={() => removeTag(tag)}
                      >
                        <i className="bx color-blue bxs-x-circle font-size-16 pt-1" />
                      </div>

                      <div style={{
                        width: "300px"
                      }} aria-labelledby={`dropdownMenuButton2-${index}`} className=" ms-2 mt-2 dropdown-menu border shadow p-0 mb-0">
                        <div className='border mt-0 shadow p-2 pt-1 px-3'>
                          <div className='ps-1'>  {tag?.label}</div>
                          <InputField
                            placeholder={t("Details")}
                            value={tag.details || ''}
                            required={true}
                            label={t("Details")}
                            onChange={(v) => {
                              const updatedTag = { ...tag, details: v };
                              const updatedTags = selectedTags?.map((t) => ((t._id === tag._id) ? updatedTag : t));
                              onChange(tagFormatter(updatedTags));
                            }}
                            showErr={hasErr}
                            type="text"

                          />
                          <InputField
                            placeholder={t("More Details")}
                            value={tag.moreDetails || ''}
                            required={true}
                            label={t("More Details")}
                            onChange={(v) => {
                              const updatedTag = { ...tag, moreDetails: v };
                              const updatedTags = selectedTags?.map((t) => (t?.tag_id === tag?.tag_id ? updatedTag : t));
                              // setSelectedTags(updatedTags);
                              onChange(tagFormatter(updatedTags));
                            }}
                            showErr={hasErr}
                            type="text"

                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="dropdown">

              <div className="btn-group me-3">

                <div className="d-flex align-items-center btn btn-primary">

                  <i className="bx font-size-14 color-blue bxs-plus-circle me-2"></i>
                  <div>{t("Tag")}</div>
                </div>
                <div
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                >
                  <span className="sr-only"></span>
                </div>

                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  {groups?.map(group => (
                    <li key={group}>
                      <div
                        className="dropdown-item d-flex align-items-center p-2 px-3 "
                        onMouseEnter={() => setHoveredGroup(group)}
                      >
                        <i className="bx bx-left-arrow-alt font-size-14" />
                        <div>{group}</div>
                      </div>
                      <ul className="dropdown-menu dropdown-submenu">
                        {labelsFromGroup()?.map(label => (
                          <li key={label._id}>
                            <div className="dropdown-item" onClick={() => selectTag(label)}>
                              {label.label}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>

              </div>
            </div>


          </div >
      }
    </div >

  );
};

export default TagSelector;
