import React, { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { CardBody } from "reactstrap";
import { useModal } from "../store/context/ModalContext";
import "./style.css";


const GeneralModal = ({
  large,
  medium,
  small,
  hideheader,
  hidefooter,
}) => {
  const { isOpen, toggleModal, modalContent,size } = useModal();
  const modalRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        toggleModal();
      }
    }
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggleModal]);

  let modalWidth;

  switch (size) {
    case "large":
      modalWidth = "1800px";
      break;
    case "medium":
      modalWidth = "1200px";
      break;
    case "small":
      modalWidth = "800px";
      break;
    default:
      modalWidth = "calc(100vw - 400px)";
      break;
  }
  
  return isOpen ? (
      <div
      ref={modalRef}
      className={
          !isOpen
            ? "general-modal-container "
            : "general-modal-container general-modal-show  "
        }
        style={{
          maxWidth: `${modalWidth}`, // Set the width based on the "size" prop
        }}
      >
        <Row>
          <Col
            style={{
              minHeight: "calc(100vh - 72px)",
            }}
            className="col-12 p-0 pe-3"
          >
            <CardBody className="h-100">
              <CardBody className="p-0 d-flex flex-column h-100 bg-light_white ">
                {!hideheader && (
                  <>
                   {modalContent.title}
                  </>
                )}
                <div className="ps-3 overflow-y-auto flex-1">
                  {modalContent.body}
                </div>
                {!hidefooter && (
                  <div className="general_modal-footer">
                    {modalContent.footer}
                  </div>
                )}
              </CardBody>
            </CardBody>
          </Col>
        </Row>
      </div>
  ) : <></>;
};

export default GeneralModal;

