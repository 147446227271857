import { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { DateSelect, InputField, SelectField } from "../../components";
import { SELECTSTRUCTURE } from "./InvoiceSetupConstant";

export default function MydModalWithGrid({
  modal,
  toggle,
  invoices,
  setinvoices,
  data,
  setData,
}) {
  const [form, setform] = useState({});

  const submitForm = (e) => {
    e.preventDefault();
    setinvoices([...invoices, form]);

    setform({});
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <ModalBody>
        <Form onSubmit={submitForm}>
          <FormGroup row>
            <Row className="mt-2">
              <SelectField
                className="col-sm-6"
                required={true}
                data={SELECTSTRUCTURE?.kind}
                value={form?.kind}
                onChange={(v) => {
                  setform({ ...form, kind: v.value });
                }}
                label={"Kind"}
              />

              <InputField
                className="col-sm-6"
                value={form?.invoicePrefix}
                required={true}
                label={"Prefix"}
                onChange={(v) =>
                  setform({
                    ...form,
                    invoicePrefix: v,
                  })
                }

                type="text"
              />
              <DateSelect
                className="col-sm-6 mt-3"
                value={form?.from}
                required={true}
                label={"Valid From"}
                onChange={(v) =>
                  setform({
                    ...form,
                    from: v,
                  })
                }
                showClear={false}
                type="date"
              />

              {/* <col className="col-sm-3 py-3">
              <DateSelect
              label={"Date"}
              value={date}
              onChange={setDate}
              
            />
              </col> */}
              <DateSelect
                className="col-sm-6 mt-3"
                value={form?.till}
                required={true}
                label={"Valid Till"}
                onChange={(v) =>
                  setform({
                    ...form,
                    till: v,
                  })
                }
                showClear={false}
                type="date"
              />
            </Row>

            <Row className="mt-3 mb-2">
              <SelectField
                className="col-sm-6"
                onChange={(v) => setData({ ...data, seqLength: v.value })}
                data={SELECTSTRUCTURE.sequence}
                value={data?.seqLength}
                label={"Sequence"}
              />
            </Row>
          </FormGroup>

          <div className="d-flex justify-content-end  ">
            <div className="me-2">
              <Button type="submit" color="primary" onClick={toggle}>
                Add
              </Button>{" "}
            </div>

            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
