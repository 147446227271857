import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./EmailCredentialsConstant";
import { doGET, doPUT } from "../../../../util/HttpUtil";

class Service {

  records = [];
  totalRecords = 0;
  places = [];
  page = 1;
  rows = 10;

  constructor() {
    makeAutoObservable(this);
  }



  getEmailCredentials = async function (id) {

    const response = await doGET(ENDPOINTS.getEmailCredentials)
    if (response.status === 200) {

      return response?.data;
    }
  };

  editEmailCredentials = async function (data, id) {
    const response = await doPUT(ENDPOINTS?.editEmailCredentials, data);
    if (response.status === 200) {
      return response?.data;
    }
  };

}

const EmailCredentialsService = new Service();
export default EmailCredentialsService;
