import React, { useContext, useState } from 'react'
import { AddressField, Checkbox, CompanySelect, DateSelect, InputField, PaymentStatusSelect, SelectField, ToggleSelector } from '../../../components';
import { Col, Row } from 'reactstrap';
import { I18nContext } from '../../../store/context/i18nContext';
import AdhocInvoice from './AdhocInvoice';
import WalkInInvoice from '../WalkInInvoice';
import { UserContext } from '../../../store/context/UserContext';
import { SELECTSTRUCTURE, paymentStatusSelectData, selectFieldData, statusData, toggleData } from '../InvoiceConstant';
import moment from 'moment';
import useDebounce from '../../../util/Debounce';
import Invoiceservice from '../InvoiceService';

function InvoiceHeader({ invoice, onChange, hideToggle, /* handleInvoiceChange, */ fetchInvoiceNumber, concise, noOfInvoices, setNoOfInvoices, error, setError }) {
    const { t } = useContext(I18nContext);
    const { userContent } = useContext(UserContext);

    const [state, SetState] = useState({ clientType: "corporateClient" })

    const handleValueChange = (key, value) => SetState(prev => ({ ...prev, [key]: value }));

    const validateInvoiceNo = useDebounce(async (invoiceNo) => {
        try {
            console.log("validating")
            const isValidInVoiceNo = await Invoiceservice.validateInvoiceNo({ invoiceNo: invoiceNo, invoice_id: invoice?._id });
            console.log({ isValidInVoiceNo })
            if (!isValidInVoiceNo?.data) {
                setError(prev => ({ ...prev, invoiceNo: "Invalid Invoice Number" }))
            }
        } catch (error) {
            setError(prev => ({ ...prev, invoiceNo: "Invalid Invoice Number" }))
        }
    }, 1000)

    return (
        <>
            <Row className={`${hideToggle && concise ? "col-12" : "col-12"}`}>
                {hideToggle ? null :
                    <Col className='col-12 col-sm-4'>
                        <ToggleSelector
                            data={toggleData}
                            className=" w-40"
                            // style={{ width: "40%", position: "relative", top: "2.5px" }}
                            value={state.clientType}
                            onChange={(selectedValue) => {
                                handleValueChange('clientType', selectedValue);
                            }}
                        />
                    </Col>}
                {concise ? <Col className='col-12 col-sm-6 col-md-3 '>
                    <SelectField
                        data={selectFieldData}
                        outline
                        required
                        className=""
                        label={t("No. of Invoices")}
                        placeholder={t("No. of Invoices")}
                        value={noOfInvoices}
                        onChange={(v) => setNoOfInvoices(v.value)}
                    />
                </Col> : null}
                <Col style={{ marginLeft: "auto" }} className='col-12 col-md-6 col-lg-3'>
                    {invoice?.status == 3 ?
                        <div className="d-flex align-items-center justify-content-end">
                            <b className="me-2">Invoice Status:</b>
                            <div
                                style={{
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "50%",
                                    backgroundColor: "red",
                                    marginRight: "5px",
                                }}
                                className="me-2"
                            ></div>
                            <div>Deleted</div>
                        </div>
                        : <PaymentStatusSelect
                            value={invoice?.status}
                            label={t(" Status")}
                            className={" mb-0 col-12 me-0 pe-0 ms-3"}
                            // outline
                            data={statusData}
                            onChange={(v) =>
                                v.value !== invoice?.status && onChange({ status: v.value })}

                            placeholder={t("Invoice Status")}
                        />}
                </Col>
            </Row>

            {state.clientType == "adhoc" && <AdhocInvoice invoice={invoice} onChange={onChange} />}

            {!invoice?.walkInInvoice ?
                <Row className="d-flex align-items-start justify-content-between">
                    <Col className='flex-1'>
                        <Row >
                            <WalkInInvoice
                                invoice={invoice}
                                onChange={onChange}
                            />
                            {concise && (
                                <Row className='col-5' >
                                    <PaymentStatusSelect
                                        value={invoice?.paymentStatus}
                                        label={t("Payment Status")}
                                        onChange={(v) =>
                                            v.value !== invoice?.paymentStatus && onChange({
                                                paymentStatus: v.value,
                                            })}
                                        data={paymentStatusSelectData}
                                        placeholder={t("Payment Status")}
                                    />
                                </Row>
                            )}
                            {concise && (
                                <Row>
                                    <Checkbox
                                        placeholder={t("Proforma")}
                                        checked={invoice?.isProforma ? true : false}
                                        value={invoice?.isProforma ? true : false}
                                        required={true}
                                        label={t("Proforma")}
                                        onClick={() => {
                                            onChange({
                                                isProforma: !invoice?.isProforma,
                                            })
                                        }}
                                        type="text"
                                        className="col-2 my-3 align-content-end"
                                    />
                                </Row>
                            )}
                        </Row>
                    </Col>
                    <Col className='col-md-6 col-lg-3'>
                        <Row>
                            <CompanySelect
                                isDisabled={invoice?.savedStatus !== 1}
                                corpType="self"
                                corp_id={invoice?.corp_id ?? userContent?.adminOf}
                                value={invoice?.vendorCompany_id}
                                onChange={(v) => {
                                    v.value !== invoice?.vendorCompany_id && onChange({
                                        vendorCompany_id: v?.value,
                                    })
                                    fetchInvoiceNumber(v?.value, invoice?.invoiceDate)
                                }}
                                label="Serving Company"
                            />

                            {invoice?.rcm &&
                                <Col xl={12} style={{ alignSelf: "center" }}>
                                    <div style={{ float: "right" }} class="d-flex  justify-content-start align-items-center"><div><div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#ffa02f", marginRight: "5px" }}></div></div><div class="ms-2" style={{ verticalAlign: "top" }}>RCM APPLICABLE</div></div>
                                </Col>
                            }
                        </Row>

                        <Row>
                            <DateSelect
                                required={true}
                                placeholder={t("Select Invoice Date")}
                                value={invoice?.invoiceDate}
                                isDisabled={invoice?.savedStatus !== 1}
                                label={t("Invoice Date")}
                                onChange={(v) => {
                                    v !== invoice?.invoiceDate && onChange({
                                        invoiceDate: v,
                                    })
                                    const newYear = v ? moment(v, 'YYYYMMDD').year() : null;
                                    const currentYear = invoice?.invoiceDate ? moment(invoice.invoiceDate, 'YYYYMMDD').year() : null;
                                    if (!invoice?._id || newYear !== currentYear) {
                                        fetchInvoiceNumber(invoice?.vendorCompany_id, v)
                                    }
                                }}
                                error={
                                    invoice?.invoiceDate?.length === 0
                                        ? t("Please enter Invoice Date")
                                        : ""
                                }
                                type="text"
                            />

                        </Row>

                        <Row>
                            <InputField
                                placeholder={t("Invoice Number")}
                                value={invoice?.invoiceNo}
                                required
                                isDisabled={invoice?.savedStatus !== 1}
                                label={t("Invoice Number")}
                                onChange={(v) => {
                                    setError(prev => ({ ...prev, invoiceNo: null }))
                                    v !== invoice?.invoiceNo && onChange({
                                        invoiceNo: v,
                                    })
                                    validateInvoiceNo(v)

                                }}

                                error={
                                    error?.invoiceNo ? error.invoiceNo :
                                        invoice?.invoiceNo?.length === 0
                                            ? t("Please enter Invoice No")
                                            : ""
                                }
                                showErr
                                type="text"
                                removeBottomMargin={true}
                            />

                        </Row>

                        <Row>
                            <InputField
                                placeholder={t("Charges Label")}
                                value={invoice?.invoiceExtn?.chargesLabel}
                                label={t("Charges Label")}
                                onChange={(v) => {
                                    onChange({ invoiceExtn: { ...invoice?.invoiceExtn, chargesLabel: v } })
                                }}
                                type="text"
                            />
                        </Row>

                        <Row>
                            <InputField
                                placeholder={t("Notes")}
                                value={invoice?.invoiceExtn?.notes}
                                label={t("Notes")}
                                onChange={(v) => {
                                    onChange({ invoiceExtn: { ...invoice?.invoiceExtn, notes: v } })
                                }}
                                type="text"
                            />
                        </Row>
                    </Col>

                </Row>
                :
                <Row>
                    <Col className='col-6 mt-3' >
                        {!invoice?.client?.org_id &&
                            <>
                                <Row>
                                    <div className="col-sm-12 col-md-12 py-1 d-flex">
                                        <b className="me-2">Client Name:</b>
                                        <div>{invoice?.client?.fullName}</div>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-sm-12 col-md-12 py-1 d-flex">
                                        <b className="me-2">Billing Address:</b>
                                        <div>{invoice?.client?.address?.addressLine}</div>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-sm-12 col-md-12 py-1 d-flex">
                                        <b className="me-2">Billing Email:</b>
                                        <div>{invoice?.client?.email}</div>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-sm-12 col-md-12 py-1 d-flex">
                                        <b className="me-2">Billing Phone:</b>
                                        <div>{invoice?.client?.phoneNo} </div>
                                    </div>
                                </Row>
                            </>
                        }
                        {concise && (
                            <Row className='col-5' >
                                <PaymentStatusSelect
                                    value={invoice?.paymentStatus}
                                    label={t("Payment Status")}
                                    onChange={(v) =>
                                        v.value !== invoice?.paymentStatus && onChange({
                                            paymentStatus: v.value,
                                        })}
                                    data={paymentStatusSelectData}
                                    placeholder={t("Payment Status")}
                                />
                            </Row>
                        )}
                        {concise && (
                            <Row>
                                <Checkbox
                                    placeholder={t("Proforma")}
                                    checked={invoice?.isProforma ? true : false}
                                    value={invoice?.isProforma ? true : false}
                                    required={true}
                                    label={t("Proforma")}
                                    onClick={() => {
                                        onChange({
                                            isProforma: !invoice?.isProforma,
                                        })
                                    }}
                                    type="text"
                                    className="col-2 my-3 align-content-end"
                                />
                            </Row>
                        )}
                    </Col>

                    <Col className='col-md-6 col-lg-3' style={{ marginLeft: "auto" }}>
                        <Row>
                            <CompanySelect
                                isDisabled={invoice?.savedStatus !== 1}
                                corpType="self"
                                corp_id={invoice?.corp_id ?? userContent?.adminOf}
                                value={invoice?.vendorCompany_id}
                                onChange={(v) => {
                                    v.value !== invoice?.vendorCompany_id && onChange({
                                        vendorCompany_id: v?.value,
                                    })
                                    fetchInvoiceNumber(v?.value, invoice?.invoiceDate)
                                }}
                                label="Serving Company"
                            />

                            {invoice?.rcm &&
                                <Col xl={12} style={{ alignSelf: "center" }}>
                                    <div style={{ float: "right" }} class="d-flex justify-content-start align-items-center"><div><div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#ffa02f", marginRight: "5px" }}></div></div><div class="ms-2" style={{ verticalAlign: "top" }}>RCM APPLICABLE</div></div>
                                </Col>
                            }
                        </Row>

                        <Row>

                            <DateSelect
                                required={true}
                                placeholder={t("Select Invoice Date")}
                                value={invoice?.invoiceDate}
                                isDisabled={invoice?.savedStatus !== 1}
                                label={t("Invoice Date")}
                                onChange={(v) => {
                                    v !== invoice?.invoiceDate && onChange({
                                        invoiceDate: v,
                                    })
                                    const newYear = v ? moment(v, 'YYYYMMDD').year() : null;
                                    const currentYear = invoice?.invoiceDate ? moment(invoice.invoiceDate, 'YYYYMMDD').year() : null;
                                    if (!invoice?._id || newYear !== currentYear) {
                                        fetchInvoiceNumber(invoice?.vendorCompany_id, v)
                                    }
                                    // if(!invoice?._id){
                                    //     fetchInvoiceNumber(invoice?.vendorCompany_id, v)
                                    // }
                                }}
                                error={
                                    invoice?.invoiceDate?.length === 0
                                        ? t("Please enter Invoice Date")
                                        : ""
                                }
                                type="text"
                            />

                        </Row>

                        <Row>

                            <InputField
                                placeholder={t("Invoice Number")}
                                value={invoice?.invoiceNo}
                                required
                                isDisabled={invoice?.savedStatus !== 1}
                                label={t("Invoice Number")}
                                onChange={(v) =>
                                    v !== invoice?.invoiceNo && onChange({
                                        invoiceNo: v,
                                    })}
                                error={
                                    invoice?.invoiceNo?.length === 0
                                        ? t("Please enter Invoice No")
                                        : ""
                                }
                                type="text"
                            />

                        </Row>



                    </Col>
                </Row>
            }

            <Row className='d-flex justify-content-between'>
                {!concise && (
                    <Col xl={4}>
                        <SelectField
                            value={"Due on Receipt"}
                            label={t("Terms")}
                            onChange={(v) => {
                                let newDueDate;
                                if (v.value === "Due on Receipt") {
                                    newDueDate = invoice?.invoiceDate;
                                } else if (v.value === "Net 7") {
                                    newDueDate = moment(invoice?.invoiceDate, "YYYYMMDD")
                                        .add(7, "days")
                                        .format("YYYYMMDD");
                                } else if (v.value === "Net 15") {
                                    newDueDate = moment(invoice?.invoiceDate, "YYYYMMDD")
                                        .add(15, "days")
                                        .format("YYYYMMDD");
                                } else if (v.value === "Net 30") {
                                    newDueDate = moment(invoice?.invoiceDate, "YYYYMMDD")
                                        .add(30, "days")
                                        .format("YYYYMMDD");
                                } else if (v.value === "Net 60") {
                                    newDueDate = moment(invoice?.invoiceDate, "YYYYMMDD")
                                        .add(60, "days")
                                        .format("YYYYMMDD");
                                } else if (v.value === "End of Month") {
                                    newDueDate = moment(invoice?.invoiceDate, "YYYYMMDD")
                                        .endOf("month")
                                        .add(1, "day")
                                        .format("YYYYMMDD");
                                } else if (v.value === "End of Next Month") {
                                    newDueDate = moment(invoice?.invoiceDate, "YYYYMMDD")
                                        .add(1, "months")
                                        .endOf("month")
                                        .add(1, "day")
                                        .format("YYYYMMDD");
                                } else if (v.value === "Custom Due Date") {
                                    newDueDate = null;
                                }
                                v.value !== invoice?.invoiceDate && onChange({
                                    paymentTerms: v.value,
                                    dueDate: newDueDate,
                                }, "dueDate", newDueDate)
                            }}
                            data={SELECTSTRUCTURE.paymentTerms}
                            placeholder={t("Payment Terms")}
                        />
                    </Col>
                )}
                {!concise &&
                    (invoice?.paymentTerms != "Due on Receipt") && invoice?.invoiceDate && (
                        <Col xl={4}>
                            {!invoice?.dueDate && invoice?.paymentTerms !== "Custom Due Date" ? null : <DateSelect
                                asLabel={invoice?.paymentTerms !== "Custom Due Date"}
                                isDisabled={invoice?.paymentTerms !== "Custom Due Date"}
                                placeholder={t("Due Date")}
                                value={invoice?.dueDate}
                                label={t("Due Date")}
                                onChange={(v) =>
                                    onChange({ dueDate: v }, "dueDate", v)
                                }
                                error={invoice?.dueDate?.length === 0 && t("Please enter Due Date")}
                                type="text"
                            />}
                        </Col>
                    )}
                {
                    !concise && (
                        <Col xl={3}>
                            <PaymentStatusSelect
                                value={invoice?.paymentStatus}
                                label={t("Payment Status")}
                                onChange={(v) =>
                                    v.value !== invoice?.paymentStatus && onChange({
                                        paymentStatus: v.value,
                                    })}
                                data={paymentStatusSelectData}
                                placeholder={t("Payment Status")}
                            />
                        </Col>
                    )

                }
            </Row>
            <Row>
                <Col xl={3} className='ms-auto'>
                    <InputField
                        placeholder={t("Service Code")}
                        value={invoice?.invoiceExtn?.hsn}
                        label={t("Service Code")}
                        onChange={(v) => {
                            onChange({ invoiceExtn: { ...invoice?.invoiceExtn, hsn: v } })
                        }}
                        type="text"
                        removeBottomMargin={true}
                    />
                </Col>
            </Row>
        </>
    )
}

export default InvoiceHeader