import React, { useEffect, useRef, useState } from 'react';
import { FaHotel, FaBed, FaStar, FaBuilding } from 'react-icons/fa';
import { useOutsideAlerter } from '../../../../board/helper';
import './index.scss';
import { observer } from 'mobx-react-lite';

// Assuming you have a way to dynamically choose icons based on room/hotel options
const getIconForRoom = (value) => {
    switch (value) {
        case 'sweetDeluxe':
            return FaBed;
        case 'doubleDeluxe':
            return FaHotel;
        case 'singleRoom':
            return FaBuilding;
        case 'presidentialSuite':
            return FaStar;
        case 'juniorSuite':
            return FaBed;
        default:
            return FaBed; // Default case
    }
};

const roomOptions = [
    { value: 'sweetDeluxe', label: 'Sweet Deluxe' },
    { value: 'doubleDeluxe', label: 'Double Deluxe' },
    { value: 'singleRoom', label: 'Single Room' },
    { value: 'presidentialSuite', label: 'Presidential Suite' },
    { value: 'juniorSuite', label: 'Junior Suite' },
];

const hotelOptions = [
    { value: '1', label: '1 star' },
    { value: '2', label: '2 stars' },
    { value: '3', label: '3 stars' },
    { value: '4', label: '4 stars' },
    { value: '5', label: '5 stars' },
];

const ItineraryStayPicker = ({ 
    noToggle = false, 
    value, 
    onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    useOutsideAlerter(dropdownRef, () => setIsOpen(false));


    const handleSelect = (hotelType, roomType) => {
        onChange({ hotelType, roomType });
        setIsOpen(false);
    };

    return (
        <div className="zr_itineraryStay_wrapper" ref={dropdownRef}>
            <div
                className="zr_itineraryStay_display"
                onClick={() => setIsOpen(!isOpen)}
            >
                {value ? (
                    <>
                        {React.createElement(getIconForRoom(value.roomType), { size: 12, className: "me-2" })}
                        {`${roomOptions.find(r => r.value === value.roomType)?.label}, ${hotelOptions.find(h => h.value === value.hotelType)?.label}`}
                    </>
                ) : 'Select Stay'}
            </div>

            {isOpen&& !noToggle && (
                <div className="zr_itineraryStay_modal">
                    <div className="zr_itineraryStay_roomOptions">
                        <h5>Room Options</h5>
                        {roomOptions.map(option => (
                            <div
                                key={option.value}
                                className="zr_itineraryStay_option"
                                onClick={() => handleSelect(value.hotelType, option.value)}
                            >
                                {React.createElement(getIconForRoom(option.value), { size: 12, className: "me-2" })}
                                {option.label}
                            </div>
                        ))}
                    </div>
                    <div className="zr_itineraryStay_hotelOptions">
                        <h5>Hotel Type</h5>
                        {hotelOptions.map(option => (
                            <div
                                key={option.value}
                                className="zr_itineraryStay_option"
                                onClick={() => handleSelect(option.value, value.roomType)}
                            >
                                <FaStar size={12} className="me-2" color={"#FFD700"} /> {/* Gold color for stars */}
                                {option.label}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default observer(ItineraryStayPicker);
