import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import RateNames from "../../../pages/RateName/RateName";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, Modal, ModalComponent, SearchButton } from "../../index";
import RateNameService from "./../../../pages/RateName/RateNameService";
import { ThreeBounce } from "better-react-spinkit";

const RateNameSelect = ({
    style,
    isDisabled,
    className,
    onChange,
    required,
    value,
    label,
    showErr,
    inline,
    borderNone,
    multi,
    plain,
    outline,
    isSlabRate,
    asLabel
}) => {
    const [rateName, setRateName] = useState([]);
    const [modal, setModal] = useState(false);
    const [gridLoading, setGridLoading] = useState(false);
    const { showError } = useContext(DialogContext)
    const { t } = useContext(I18nContext)

    const fetchRateName = async (isSlabRate) => {
        setGridLoading(true);
        try {
            const response = await RateNameService.fetchAll(isSlabRate);
            setRateName(toJS(response));
            setGridLoading(false);
        } catch (error) {
            showError("Error", error)
        }
        setGridLoading(false);
    };

    useEffect(() => {
        console.log("slabChnges",isSlabRate);
        fetchRateName(isSlabRate);
    }, [isSlabRate]);

    const openModal = () => {
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    const selectRateName = (selectedValue) => {
        fetchRateName()
        onChange({ value: multi ? selectedValue[0] : selectedValue });
        closeModal();
    };

    const selectRateNames = (selectedValues) => {
        onChange(selectedValues.map((c) => c?.value ?? c));
    };

    const renderSelectField = ({ disabled }) => (
        <SelectField
            style={style}
            isDisabled={isDisabled || disabled}
            changeOptionsData={openModal}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage Rate Names")} />
            )}
            showBottomBar={true}
            className={className}
            onChange={onChange}
            data={rateName}
            required={required}
            value={value}
            label={label === null || label === undefined ? t("RateName") : label}
            error={!value ? t(`Please Select ${label || "RateName"}`) : ""}
            showErr={showErr}
            inline={inline}
            plain={plain}
            outline={outline}
            borderNone={borderNone}
            RightComponent={() => (
                <SearchButton onClick={openModal} />
            )}
        />
    );

    const renderSelectFieldMultiple = () => (
        <SelectFieldMultiple
            isDisabled={isDisabled}
            style={style}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage Rate Names")} />
            )}
            showBottomBar={true}
            className={className}
            plain={plain}
            outline={outline}
            onChange={selectRateNames}
            data={rateName}
            value={value ? rateName.filter((c) => value?.includes(c?.value)) : ""}
            error={!value ? t(`Please Select ${label || "Rate Names"}`) : ""}
            required={required}
            label={t("Select Rate Names")}
            showErr={showErr}
            RightComponent={() => (
                <SearchButton onClick={openModal} />
            )}
        />
    );

    return (
        <>
            {gridLoading ? (
                <div className="d-flex align-items-center justify-content-center">
                    <ThreeBounce size={20} color="pink" />
                </div>
            ) : (
                <>
                    {asLabel ? (
                        multi ? (
                            value?.length > 0 ? (
                                <div>
                                    {value.map((selectedValue, index) => (
                                        <span key={selectedValue}>
                                            {rateName.find((rateName) => rateName.value === selectedValue)?.label}
                                            {index < value.length - 1 && ", "}
                                        </span>
                                    ))}
                                </div>
                            ) : (
                                <div style={{ color: "grey" }}>--</div>
                            )
                        ) : (
                            <div>
                                {rateName?.find((rateName) => rateName?.value == value)?.label ?? (
                                    <div style={{ color: "grey" }}>--</div>
                                )}
                            </div>
                        )
                    ) : (
                        <>
                            {modal && (
                                <ModalComponent position={"top"} size={"large"} onToggle={closeModal} isOpen={modal}>
                                    <ModalComponent.Title>{t("RateNames")}</ModalComponent.Title>
                                    <ModalComponent.Body>
                                        <RateNames
                                            value={value}
                                            insidePane={true}
                                            multiMode={multi}
                                            onSelect={multi ? selectRateNames : selectRateName}
                                        />
                                    </ModalComponent.Body>
                                </ModalComponent>
                            )}

                            {rateName.length > 0 ? (multi ? renderSelectFieldMultiple() : renderSelectField({ disabled: false })) : renderSelectField({ disabled: true })}
                        </>
                    )}
                </>
            )}
        </>
    );

};

export default observer(RateNameSelect);
