import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";

class EmployeeSupervisorLabelService {
  categoryCache = {};
  fetchQueue = {};
  loading = {};

  constructor() {
    makeAutoObservable(this);
  }

  fetchEmployeeSupervisor = async (addedById) => {
    if (this.categoryCache[addedById]) {
      return this.categoryCache[addedById];
    }
    if (this.loading[addedById]) {
      return new Promise((resolve, reject) => {
        if (!this.fetchQueue[addedById]) this.fetchQueue[addedById] = [];
        this.fetchQueue[addedById].push({ resolve, reject });
      });
    }
    this.loading[addedById] = true;
  
    try {
      const [supervisorsResponse, employeesResponse] = await Promise.all([
        doGET(`/api/supervisor/grid?rows=-1`),
        doGET(`/api/employee/grid?rows=-1`)
      ]);
  
      if (supervisorsResponse.status === 200 && employeesResponse.status === 200) {
        const supervisors = supervisorsResponse.data.rows.reduce((acc, item) => {
          acc[item._id] = { ...item, label: item.name };
          return acc;
        }, {});
  
        const employees = employeesResponse.data.rows.reduce((acc, item) => {
          acc[item._id] = { ...item, label: item.name };
          return acc;
        }, {});
  
        const combinedCategories = { ...supervisors, ...employees };
  
        this.categoryCache = { ...this.categoryCache, ...combinedCategories };
  
        while (this.fetchQueue[addedById]?.length > 0) {
          const { resolve } = this.fetchQueue[addedById].shift();
          resolve(this.categoryCache[addedById]);
        }
  
        return this.categoryCache[addedById] || null;
      } else {
        throw new Error("Failed to fetch categories");
      }
    } catch (error) {
      throw new Error(error.message);
    } finally {
      delete this.loading[addedById];
    }
  };
  
}

const employee_supervisorLabelService = new EmployeeSupervisorLabelService();
export default employee_supervisorLabelService;
