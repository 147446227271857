import React, { useContext, useEffect, useState } from "react";


import { DialogContext } from "../../../../../../store/context/DialogContext";
import { I18nContext } from "../../../../../../store/context/i18nContext";
import DeleteButton from "../../../../../Buttons/DeleteButton";
import SaveButton from "../../../../../Buttons/SaveButton";
import SupplierSelect from '../../../../../Dropdowns/Supplier/SupplierSelect';
import ModalComponent from "../../../../../Modal/Modal";
import FeeGroup from './FeeGroup';
import SupplierFareService from "./SupplierFareService";
import './index.scss';
import { observer } from "mobx-react-lite";
import CoveragePicker from "../CoveragePicker";
import SupplierStatusPicker from "../SupplierStatusPicker";
import SupplierCompany from "../../../../../Dropdowns/SupplierCompany/SupplierCompany";


const SupplierFareDetail = ({
    onEdit = () => { },
    editId,
    itinerary_id,
    isOpen,
    toggleModal,
}) => {

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});

    const fetch = async () => {
        try {
            setLoading(true)
            let itineraryDetail = await SupplierFareService.get(editId);
            setData(itineraryDetail);
            setLoading(false)

        } catch (error) {
            showError(error)
            setLoading(false)

        }
    };

    const onSave = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            if (data?._id) {
                const lead = await SupplierFareService.edit(data, data?._id);
                setData(lead)
                setLoading(false);
                showMessage(t("Supplier Fare updated successfully."));
                onEdit()
            } else {
                const lead = await SupplierFareService.save({
                    ...data,
                    itinerary_id,
                   
                });
                setData(lead)
                setLoading(false);
                showMessage(t("Supplier Fare saved successfully."));
                onEdit()
            }
        } catch (e) {
            setLoading(false);
            showError(e);
        }
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await SupplierFareService.delete(id);
                setLoading(false);
                showMessage("Supplier Fare Deleted SuccessFully", "Deleted");
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };

    useEffect(() => {
        if (isOpen) {
            setLoading(true);
            if (editId) {
                fetch();
            } else {
                setData({});
            }
            setLoading(false)
        }
    }, [isOpen, editId]);


    return (
        <React.Fragment>
            <ModalComponent
                position={"top"}
                size={"large"}
                isOpen={isOpen}
                onToggle={toggleModal}
            >

                <ModalComponent.Title>
                    <div className="d-flex">

                        <SupplierCompany
                            required={true}
                            value={data?.supplierCompany_id}
                            outline
                            className={"col-4 my-0"}
                            onChange={(v) => setData(
                                {
                                    ...data,
                                    supplier_id: v?.supplier_id,
                                    supplierCompany_id: v?.value,
                                }
                            )}
                        />

                        
                        <div className="ms-3">
                            <CoveragePicker
                                value={data?.coverage}
                                onChange={(v) => {
                                    setData({ ...data, coverage: v });
                                }}
                            />
                        </div>
                        <div className="ms-3">
                            <SupplierStatusPicker
                                value={data?.status}
                                onChange={(v) => {
                                    setData({ ...data, status: v });
                                }}
                            />
                        </div>
                    </div>
                </ModalComponent.Title>

                <ModalComponent.Body>

                    <FeeGroup
                        onChange={(updatedFeeData) => {
                            console.log(updatedFeeData)
                            setData({ ...data, charges: updatedFeeData[0] });
                        }}
                        feeGroups={[data?.charges]}
                    />



                </ModalComponent.Body>

                <ModalComponent.Footer>
                    <div className="d-flex justify-content-end">
                        {data?._id && <DeleteButton onClick={(e) => onDelete(e, data?._id)} loading={loading} />}
                        <SaveButton loading={loading} onClick={onSave} disabled={loading} />
                    </div>
                </ModalComponent.Footer>

            </ModalComponent>



        </React.Fragment>
    );
};

export default observer(SupplierFareDetail);

