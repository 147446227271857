import moment from "moment";
import { CurrencyAmountField } from "../../components";


export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Title",
            accessor: "title",
            width: 120,
            show: true,
        },
        {
            title: "Sub Title",
            accessor: "subTitle",
            show: true,
        },
        {
            title: "Details",
            accessor: "detail",
            width: 165,
            show: true,
        },
        {
            title: "Amount",
            show: true,
            renderer: (dataObj) => {
                return <CurrencyAmountField
                    className="col-sm-6"
                    value={
                        {
                            amount: dataObj?.baseAmount,
                            currency: dataObj?.currency,
                        }
                    }
                    label="Amount"
                    onChange={(v) => {
                    }}
                    asLabel
                />
            }
        },
        {
            title: "Percent Amount",
            accessor: "percentAmount",
            show: true,

        },
        {
            title: "Max Discount",
            accessor: "maxDiscount",
            show: true,

        },
        {
            title: "Min Purchase",
            accessor: "minPurchase",
            show: true,

        },
        {
            title: "Coupon Code",
            accessor: "couponCode",
            show: true,

        }, {
            title: "Max Uses",
            accessor: "maxUses",
            show: true,
        },
        {
            title: "Used Till",
            accessor: "usedTill",
            show: true,
        },

        {
            title: "Valid From",
            accessor: "validFrom",
            width: 160,
            renderer: (dataObj) => {
                if (dataObj.validFrom) {
                  const timestamp = dataObj?.validFrom;
                  const date = moment.unix(timestamp);
                  const formattedDateTime = date.format('DD-MM-YYYY, hh:mm A');
        
                  return (
                    <div>
                      {formattedDateTime}
                    </div>
                  );
                } else {
                  return null;
                }
              },         
            show: true,
        },

        {
            title: "Valid Till",
            accessor: "validTill",
            width: 160,
            renderer: (dataObj) => {
                if (dataObj.validTill) {
                  const timestamp = dataObj?.validTill;
                  const date = moment.unix(timestamp);
                  const formattedDateTime = date.format('DD-MM-YYYY, hh:mm A');
        
                  return (
                    <div>
                      {formattedDateTime}
                    </div>
                  );
                } else {
                  return null;
                }
              },
            
            show: true,
        },

        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 100,
            show: true,
            required: true
        },
    ],
};
