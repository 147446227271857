import React, { useState } from "react";
import { Button } from "reactstrap";
import LeadForm from "./LeadForm";
import "./style.scss";
import { useNavigate } from "react-router-dom";


const LeadAddTask = ({ onEdit = () => {},status, children, onPress = () => { } }) => {

    const [modal, setModal] = useState(false);
    const navigate = useNavigate()

    const toggleModal = () => {
        if (modal) {
            navigate("/crm")
        } else {
            navigate(`/crm/create`);
        }

        setModal(!modal);
    };



    return (
        <React.Fragment>
            <div 
            className="d-flex align-items-center"
            onClick={() => {
                toggleModal()
                onPress()

            }}>
                {children ? children : <Button className="board-add-task-button">
                    Add Lead
                </Button>}
            </div>

            <LeadForm
                onEdit={onEdit }
                isOpen={modal}
                statusMasterId={status}
                toggleModal={toggleModal}
            />

        </React.Fragment>
    );
};

export default LeadAddTask;
