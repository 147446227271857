import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  CheckButton,
  SaveButton,
  DeleteLink,
  EditLink,
  Layout,
  itemBarSelectedStyle,
  DeleteButton,
  Modal,
} from "../../../../components";
import "../../../../components/SuperResponsiveTableStyle.css";
import { Button } from "react-bootstrap";
import { Row, Col } from "reactstrap";

import { I18nContext } from "../../../../store/context/i18nContext";

import PaymentGatewayService from "./PaymentGatewayService";
import EditPaymentGateway from "./EditPaymentGateway";
import { DialogContext } from "../../../../store/context/DialogContext";
import Logs from "../../../Profile/OrganizationProfile/Logs";
import EntityButton from "../../../Profile/OrganizationProfile/components/AuditLog";

const PaymentGateway = (props) => {
  const { t } = useContext(I18nContext);
  const { id } = useParams();
  if (props?.corpId) id = props?.corpId;
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(
    props?.corpId ? false : id
  );
  const [data, setData] = useState({
    bankAccount: {
      address: {},
    },
    currencies: [],
    paytmDeed: {},
    payuBizDeed: {},
    pgKind: "",
    razorPayDeed: {},
    standardBankDeed: {},
    title: "",
    types: [],
  });
  const [loading, setLoading] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);
  const [hasErr, setHasErr] = useState(false);
  const { showLogsP, setShowLogsP, conf } = useContext(DialogContext);

  const gotData = async (id) => {
    // data to fill in edit form
    try {
      const dta = await PaymentGatewayService.get(id);
      setData({ ...data, ...dta });
    } catch (error) {
      showError(error)
    }
  };

  const fetchData = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      await PaymentGatewayService.fetch(values, props?.corpId);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    setHasErr(true);
    setLoading(true);
    if (data?.currencies === undefined || data?.currencies.length === 0) {
      showError(t("Select atleast one currency"));
    }
    else {
      try {
        if (id) {
          await PaymentGatewayService.edit(data, id);
          showMessage("PaymentGateway Updated successfully");
        } else {
          const response = await PaymentGatewayService.save(data);
          PaymentGatewayService.records.push(data);
          showMessage("PaymentGateway saved successfully");
          navigate("/paymentgateway");
          if (!props?.insidePane) {
            navigate(`/paymentgateway/edit/${response}`);
          }
        }
      } catch (error) {
        showError(error);
      }
    }

    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete Payment Gateway ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        const response = await PaymentGatewayService.delete(id);
        showMessage("Deleted", "Deleted");
        navigate(`/paymentgateway/edit`);
      } catch (e) {
        showError(e);
      }
    }
  };

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    gotData(selected_id);
    setShowDetailPage(
      window.location.pathname === "/paymentgateway/edit" ||
      (id && !props?.corpId)
    );
  }, [id]);

  const onRowClick = (id) => {
    if (!props?.insidePane) navigate(`/paymentgateway/edit/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };

  const handleRowClick = async (paymentgatewayId) => {
    if (
      showDetailPage &&
      paymentgatewayId !== selected_id &&
      isEditDataChanged
    ) {
      let isProceed = await showConfirm({
        isSaveWarn: true,
        title: t(`Do you want to save the changes ?`),
        description: t("Your changes will be lost if you don't save them"),
      });
      if (isProceed) onRowClick(paymentgatewayId);
    } else onRowClick(paymentgatewayId);
  };

  if (loading) return <CardThreeBounce />;
  return (
    <Layout
      showDetailPage={showDetailPage}
       
      title={t("Payment Gateways")}
      onAddClick={() => {
        if (!props?.insidePane) navigate(`/paymentgateway/edit`);
        setShowDetailPage(true);
        setSelected_id(null);
      }}
      backDetailPage={() => {
        setShowDetailPage(false);
        if (!props?.insidePane) navigate("/paymentgateway");
        setSelected_id(false);
      }}
      page={PaymentGatewayService.page}
      rows={PaymentGatewayService.rows}
      total={PaymentGatewayService.totalRecords}
      onPageChange={async (page, rows) => {
        await PaymentGatewayService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("Title")}</Th>
            <Th>{t("Business Unit")}</Th>

            <Th>{t("Kind")}</Th>
            <Th>{t("Currency")}</Th>
            <Th>{t("Status")}</Th>

            <Th className="float-none float-sm-end">{t("Action")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {PaymentGatewayService.records?.map((paymentgateway) => (
            <Tr
              key={paymentgateway?._id}
              onClick={
                showDetailPage && (() => handleRowClick(paymentgateway?._id))
              }
              style={id === paymentgateway?._id ? itemBarSelectedStyle : {}}
            >
              <Td>{paymentgateway?.title}</Td>

              <Td>{paymentgateway?.buName ? paymentgateway?.buName : "Any"}</Td>
              <Td>{paymentgateway?.pgKind}</Td>
              <Td>{paymentgateway?.currencies?.map((e) => e + ",")}</Td>

              <Td>
                {paymentgateway?.active ? (
                  <>
                    <i
                      className="bx bxs-circle"
                      style={{
                        color: "green",
                        fontSize: "18px",
                        marginRight: "10px",
                        float: "left",
                      }}
                    />
                    Active
                  </>
                ) : (
                  <>
                    <i
                      className="bx bxs-circle"
                      style={{
                        color: "orange",
                        fontSize: "18px",
                        marginRight: "10px",
                        float: "left",
                      }}
                    />
                    Inactive
                  </>
                )}
              </Td>

              <Td className="float-none float-sm-end">
                <EditLink
                  onClick={() => {
                    if (!props?.insidePane)
                      navigate(`/paymentgateway/edit/${paymentgateway?._id}`);
                    setSelected_id(paymentgateway?._id);
                    setShowDetailPage(true);
                  }}
                />
                <DeleteLink
                  onClick={(event) => {
                    onDelete(event, paymentgateway?._id);
                  }}
                />

                {props?.selectMode ? (
                  <CheckButton
                    onClick={() => {
                      props?.onSelect([{ ...paymentgateway }]);
                    }}
                  />
                ) : null}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>

      <Layout.DetailPageTitle>
        <Row>
          <h5>Details</h5>
        </Row>
      </Layout.DetailPageTitle>

      <Layout.DetailPageBody>
        <EditPaymentGateway
          insidePane={props?.insidePane}
          onChange={(v) => setIsEditDataChanged(v)}
          id={selected_id}
          back={() => {
            setShowDetailPage(false);
            if (!props?.insidePane) navigate("/paymentgateway");
            setSelected_id(false);
          }}
          fetchData={fetchData}
          data={data}
          setData={setData}
          hasErr={hasErr}
          setHasErr={setHasErr}
        />
      </Layout.DetailPageBody>

      <Layout.DetailPageFooter>
        <Row>
          <Col>
            <div
              className="d-flex justify-content-end mt-1"
              style={{ marginTop: "-2.5rem" }}
            >
              <EntityButton entity={"Supervisor"} id={id} />
              {showLogsP && (
                <Modal className="w-75" onChange={() => setShowLogsP(false)}>
                  <Logs corpId={props?.corpId} conf={conf} />
                </Modal>
              )}
              {data?._id && (
                <>
                  <div className="d-flex justify-content-end">
                    <DeleteButton
                      onClick={(event) => {
                        onDelete(event, data?._id);
                      }}
                    />
                  </div>
                </>
              )}
              <SaveButton onClick={submitHandler} />
            </div>
          </Col>
        </Row>
      </Layout.DetailPageFooter>
    </Layout>
  );
};

export default observer(PaymentGateway);
