import React from 'react'
import { GoPlus } from 'react-icons/go';

const AddLink = ({ style,size,className, onClick = () => {} }) => {
  return (
    <div className={` ${className ?? className} `} onClick={onClick} style={{ cursor: "pointer",width:"fit-content", marginLeft: "5px", width: "22px", border: "1px solid black", borderRadius: "20px" , ...style}}>
    <GoPlus style={{color: "black" }} fontSize={`${size ?? 18}`} />
</div>
  );
}

export default AddLink;
