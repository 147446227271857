import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import { CardThreeBounce, CitySelect, CountrySelect, DeleteLink, DriverSelect, InputField, SelectField, StateSelect, } from "../../components";
import ServiceEntity from "../../components/Dropdowns/ServicingEntity/ServicingEntity";
import { SELECTSTRUCTURE } from "./BranchConstant";
import BranchService from "./BranchService";


const EditBranch = (props) => {
  const [loading, setLoading] = useState(false);
  const { id, data, setData, hasErr, setHasErr } = props;
  const [freshData, setFreshData] = useState({});

  const fetch = async () => {
    let branch = {
      staff: [],
      entity: "",
      company_id: null
    };
    if (id) branch = await BranchService.get(id);

    setFreshData(branch);
    setData({ ...data, ...branch });
    setHasErr(false);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetch();
    setHasErr(false);
  }, [id]);

  if (loading) return <CardThreeBounce />;

  return (
    <div>
      <div className="p-0">
        <Form
          className="p-3"

        >
          <Row>
            <Col className="py-2">
              <InputField
                value={data.name}
                required={true}
                label={"Name"}
                onChange={(v) => {
                  setData({ ...data, name: v });
                }}
                error={data.name?.length === 0 ? "Please enter name" : ""}

                type="text"
              />
            </Col>
            <Col className="py-2">
              <ServiceEntity
                value={data?.company_id}
                required={true}
                label={"Company"}
                onChange={(v) => {
                  setData({ ...data, company_id: v.value });
                }}

                showErr={hasErr}
              />
            </Col>

          </Row>

          <Row>

            <Col className="py-2">
              <CountrySelect
                value={data?.address?.country}
                required={true}
                onChange={(v) => {
                  setData({
                    ...data,
                    address: { ...data?.address, country: v?.value },
                  });
                }}

              />
            </Col>
            <Col className="py-2">
              <StateSelect
                value={data?.address?.state}
                required={true}
                label={"State"}
                onChange={(v) => {
                  setData({
                    ...data,
                    address: { ...data?.address, state: v?.value },
                  });
                }}

                country={data?.address?.country}
              />
            </Col>
          </Row>
          <Row>
            <Col className="py-2">
              <CitySelect
                value={data?.address?.city}
                required={true}
                label={"City"}
                onChange={(v) => {
                  setData({
                    ...data,
                    address: { ...data?.address, city: v?.value },
                  });
                }}

                country={data?.address?.country}
                state={data?.address?.state}
              />
            </Col>
            <Col className="py-2">
              <InputField
                value={data?.address?.addressLine}
                required={true}
                label={"AddressLine"}
                onChange={(v) => {
                  console.log(v, "vvvv")
                  setData({
                    ...data,
                    address: { ...data?.address, addressLine: v },
                  });
                }}
                error={data.name?.length === 0 ? "Please enter name" : ""}

                type="text"
              />
            </Col>
          </Row>
          <Items data={data} setData={setData} hasErr={hasErr} />

        </Form>
      </div>
    </div>
  );
};

var Items = ({ data, setData, hasErr, percentage, setPercentage }) => {
  console.log(data, "data")
  return (
    <React.Fragment>
      <Row className="py-2 d-flex">
        <Col className="justify-content-end">
          <Button
            type="button"
            className="btn mx-2 btn-primary"
            onClick={() => {
              setData({
                ...data,
                staff: [...(data.staff ? data.staff : []), { empId: "", title_id: "", }],
              });
            }}
          >
            <i style={{ fontSize: "18px" }} className="uil-plus"></i>
          </Button>
        </Col>
      </Row>
      <Row className="my-2">
        {data.staff?.map((item, idx) => {

          return (
            <Row className="my-2" key={item.id}>
              <Col className="col-5">
                <DriverSelect
                  value={item?.empId}
                  label={"Employee"}
                  onChange={(val) => {
                    var items = data.staff;
                    items[idx].empId = val.value;
                    setData({ ...data, staff: items });

                  }}

                  showErr={hasErr}

                  type="Select"
                  required={true}
                />
              </Col>
              <Col className="col-5">
                <SelectField
                  data={SELECTSTRUCTURE.emp}
                  value={item.title_id}
                  label={"Employee Type"}
                  onChange={(val) => {
                    var items = data.staff;
                    items[idx].title_id = val.value;
                    setData({ ...data, staff: items });
                  }}
                  showErr={hasErr}

                  type="Select"
                />
              </Col>
              <Col className="col-1">
                <DeleteLink
                  onClick={() => {
                    setData({
                      ...data,
                      staff: data.staff.filter((elem, ind) => {
                        return ind !== idx;
                      }),
                    });
                  }}
                />
              </Col>
            </Row>
          );
        })}
      </Row>
    </React.Fragment>
  );
};

export default observer(EditBranch);
