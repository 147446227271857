import React, { useState } from 'react'
import DraggableBox from '../../components/Draggable/Draggable'
import DataDridUIPreferences from '../../components/DataGrid/DataDridUIPreferences';
import { Button } from 'reactstrap';

const DemoV4 = () => {

  const [dragData, setDragData] = useState(
    {
      'To-do': {
        title: 'To-do',
        items: [
          {
            id: '1',
            title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent.',
            subTitle: '25-May-2020',
          },
          {
            id: '2',
            title: 'Fix Styling',
            subTitle: '26-May-2020',
          },
          {
            id: '3',
            title: 'Handle Door Specs',
            subTitle: '27-May-2020',
          },
          {
            id: '4',
            title: 'morbi',
            subTitle: '23-Aug-2020',
          },
          {
            id: '5',
            title: 'proin',
            subTitle: '05-Jan-2021',
          },
        ],
      },
      'In Progress': {
        title: 'In Progress',
        items: [{
          id: '6 ',
          title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent.6',
          subTitle: '25-May-2020',
        },
        {
          id: '7',
          title: 'Fix Styling 7 ',
          subTitle: '26-May-2020',
        },
        {
          id: '8',
          title: 'Handle Door Specs 8 ',
          subTitle: '27-May-2020',
        },
        {
          id: '9',
          title: 'morbi 9',
          subTitle: '23-Aug-2020',
        },
        {
          id: '10',
          title: 'proin 10',
          subTitle: '05-Jan-2021',
        },],
      },
      "Done": {
        title: 'Done',
        items: [],
      },
      "Check In": {
        title: 'Done',
        items: [{
          id: '11',
          title: 'morbi',
          subTitle: '23-Aug-2020',
        },
        {
          id: '12',
          title: 'proin',
          subTitle: '05-Jan-2021',
        },],
      },
    }
  );
  const [modalstate, setmodalstate] = useState(true)

  const [data, setData] = useState([, "e", "d", "c", "Navneet", "raj", "zoyride", "test"]);
  const [selectedColumns, setSelectedColumns] = useState(["zoyride", "test", "c", "m"]);

  const handleValueChange = (newValue) => {
    setSelectedColumns(newValue);
}


  return (
    <div className='page-content page-content-dragdrop'>

      {JSON.stringify(dragData['To-do'], null, 2)} <hr/>
      {JSON.stringify(dragData['In Progress'], null, 2)} <hr/>
      {JSON.stringify(dragData['Done'], null, 2)} <hr/>
      {JSON.stringify(dragData['Check In'], null, 2)} <hr/>
      <DraggableBox
        value={
          dragData
        }
        onChange={(v) => {
          setDragData(v)
        }}
       showArrows
      />


   {JSON.stringify(data, null, 2)} <hr/>
      {JSON.stringify(selectedColumns, null, 2)} <hr/>

<Button 
onClick  = { () => {
  setmodalstate(true) 
}}
>
open modal
</Button>
      <DataDridUIPreferences
        data={data}
        isOpen={modalstate}
        onClose={() => { setmodalstate(false) }}
        onSave = {() => {
          setmodalstate(false) 
        }}
        value={selectedColumns}
        onChange={handleValueChange}
      />
    </div>

  )
}

export default DemoV4