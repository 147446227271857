import React, { useContext } from 'react'
import RosteringService from '../../pages/Rostering/RosteringService'
import { I18nContext } from '../../store/context/i18nContext'
import ToggleSelector from '../Toggle/ToggleSelector'
import RouteTripCard from './RouteTripCard'
import { FaPlus } from 'react-icons/fa'
import FloatingButton from '../FloatingButton'
import { observer } from 'mobx-react-lite'
import SpaceService from '../space/SpaceService'

const data = [{ label: "All", value: -1 },
  { label: "Draft", value: 0 },
  { label: "Confirmed", value: 1 },
  { label: "Ready", value: 2 },
]

const RosterTripGroups = React.forwardRef(({ trips, selectedTrip, onTripClick ,className=""}, cardRefs) => {
  const { t } = useContext(I18nContext);
  const { filterData, selectedTab, addNewRecord } = RosteringService
  const {selectedFolder}=SpaceService;

  return (
    <div className={'d-flex flex-column col-sm-12 position-relative rostering-trip-view '+ className}>

      <ToggleSelector
        data={data}
        value={selectedTab}
        onChange={(v) => {
          filterData(v)
        }}
        label={t(" Status")}
        className="col-sm-6 col-12"
        customStyleClass=" mx-2"
      />
      <div className='overflow-y-auto hide-scroller mb-2' style={{paddingBottom:60}}>
        {trips?.map((trip, index) => (
          <RouteTripCard
            isSelected={selectedTrip === trip?.id}
            index={index}
            trip={trip}
            key={trip?._id}
            ref={(el) => cardRefs.current[trip?.id] = el}
            onClick={onTripClick}
            space={selectedFolder}
          />
        ))}
      </div>
      <FloatingButton
        position={{ bottom: '20px', right: '20px' }}
        onClick={()=>addNewRecord(selectedFolder)}
      >
        <FaPlus />
      </FloatingButton>
    </div>
  )
})

export default observer(RosterTripGroups);