import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./AdhocRequestConstant";
import { GridColumns } from "./AdhocRequestGridColumn";
import AdhocRequestService from "./AdhocRequestService";
import EditAdhocRequest from "./EditAdhocRequest";


const Leave = ({ insidePane }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const { userContent } = useContext(UserContext);
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(
    insidePane ? false : window.location.pathname === "/request/create" || edit_id
  );
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)


  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await AdhocRequestService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(
      queryParams,
      STRUCTURE
    );
    setFilterURL(filterUrl);
    setFilterObject(filterObject);
    fetchData(filterUrl);
  }, []);

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id, AdhocRequestService?.version])

  const onSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (editID) {
        await AdhocRequestService.edit(detailData, editID);
        showMessage(t("Request updated successfully."));
      } else {
        const leaveID = await AdhocRequestService.save(detailData);
        showMessage(t("Request saved successfully."));
        if (!insidePane) navigate(`/request/edit/${leaveID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setHasErr(false)
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete Request?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await AdhocRequestService.delete(id);
        showMessage("Deleted SuccessFully", "Deleted");
        setShowDetailPage(false);
      } catch (e) {
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback((request) => {
    return (
      <>
        <EditLink
          onClick={() => {
            if (!insidePane) navigate(`/request/edit/${request?._id}`);
            setEditID(request?._id);
            setShowDetailPage(true);
          }}
        />
        {userContent?.rights?.includes(1503) ?
          <DeleteLink
            onClick={(event) => onDelete(event, request?._id)}
          />
          : null}

      </>
    );
  }, [insidePane, userContent])


  return (
    <React.Fragment>

      <>
        <Layout
          medium
          // showDetailPage={showDetailPage}
          // onAddClick={() => {
          //   if (!insidePane) navigate(`/request/create`);
          //   setShowDetailPage(true);
          //   setEditID(null);
          // }}
          hideAdd={true}
          // backDetailPage={async () => {
          //   setShowDetailPage(false);
          //   if (!insidePane) navigate("/request");
          //   setEditID(null);
          // }}
          title={t("Adhoc Request")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          insidePane={insidePane}
          page={AdhocRequestService.page}
          rows={AdhocRequestService.rows}
          total={AdhocRequestService.total}
          fetch={AdhocRequestService.fetch}
        >
          <Layout.Table>
            <>
              <DataGrid
               gridLoading={loading}
                data={AdhocRequestService.records}
                total={AdhocRequestService.total}
                uiPreference="request.grid"
                headers={GridColumns}
                selectedIDs={selectedIDs}
                onSelectChange={setSelectedIDs}
                page={AdhocRequestService.page}
                rowsPerPage={AdhocRequestService.rowsPerPage}
                onPaginationChange={AdhocRequestService.onPaginationChange}
                renderLastCol={renderLastCol}
              />
            </>
          </Layout.Table>

          <Layout.DetailPageTitle>
            {detailData?._id ? "Edit Adhoc Request" : "Add Adhoc Request"}
          </Layout.DetailPageTitle>

          <Layout.DetailPageBody>
            <EditAdhocRequest
              editId={editID}
              onChange={(v) => {
                setDetailData(v);
              }}
              hasErr={hasErr}

            />
          </Layout.DetailPageBody>

          <Layout.DetailPageFooter>
            {userContent?.rights?.includes(1503) && detailData?._id ?
              <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />
              : null}
            {userContent?.rights?.includes(1502) ?
              <SaveButton onClick={onSave} loading={loading} />
              : null}

          </Layout.DetailPageFooter>
        </Layout>
      </>
    </React.Fragment>
  );
};
export default observer(Leave);
