import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "reactstrap";
import { CardThreeBounce } from "../../components";
import Tab from "../../components/Common/Tab/Tab";
import CustomFieldProfile from "./Tabs/CustomFieldProfile";
import CustomFieldService from "./CustomFieldService";
import CustomFieldAdditional from "./Tabs/CustomFieldAdditional";
import DCODriverDetails from "./Tabs/DCODriverDetails";
import LoanandFinance from "./Tabs/LoanandFinance";
import SellingDetail from "./Tabs/SellingDetail";
import { ENDPOINTS } from "./CustomFieldConstant";
import DocUploader from "../Driver/DocUploader";

const EditCustomField = (props) => {
  const { id, data, setData } = props;
  const [freshData, setFreshData] = useState({});
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasErr, setHasErr] = useState(false);
  const [isDCO, setIsDCO] = useState(false);

  const navigate = useNavigate();

  async function fetch() {
    let customFieldD = { country: "India" };
    if (id) customFieldD = await CustomFieldService.get(id);

    setFreshData(customFieldD);
    setData({
      ...customFieldD,
      distUnit: customFieldD?.distUnit ? customFieldD?.distUnit : "KM",
      status: customFieldD?.status ? customFieldD?.status : 1,
    });
    setHasErr(false);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    fetch();
    setHasErr(false);
  }, [id]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(freshData)) {
      props?.onChange(true);
    } else {
      props?.onChange(false);
    }
  }, [data]);

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      <Form>
        <CustomFieldAdditional data={data} setData={setData} />
        {/* <Tab
          vertical
          left
          activeTab={step}
          onChange={(data) => {
            setStep(data);
          }}
          className="d-flex"
        >
          <div num={3 + isDCO} label="Additional Properties" className="d-flex">
            <CustomFieldAdditional data={data} setData={setData} />
          </div>

          <div num={4 + isDCO} label="Insurance">
            <>Insurance</>
          </div>

          <div num={5 + isDCO} label="Service Details">
            <>Service Details</>
          </div>

          <div num={6 + isDCO} label="Fitness Certificate">
            <>Fitness Certificate</>
          </div>

          <div num={7 + isDCO} label="PUC">
            <>PUC</>
          </div>

          <div num={8 + isDCO} label="Road Tax">
            <>Road Tax</>
          </div>

          <div num={9 + isDCO} label="Other Expense">
            <></>
          </div>
        </Tab> */}
      </Form>
    </React.Fragment>
  );
};

export default observer(EditCustomField);
