import React from 'react'

function TaxItems({ taxItem, asLabel = false }) {
  return (
    <>
      {taxItem?.taxes?.map((tax, taxIndex) => (
        <>
          {tax?.name && tax?.percent ? <tr key={taxIndex} style={{ /* lineHeight: "0.5" */ }}>
            <td></td>
            <td></td>
            <td>
              {tax?.name} ({(tax?.percent / 100).toFixed(2)}%)
              {tax?.baseAmount>0 && ` & ${(tax?.baseAmount).toFixed(2)}`}
            </td>
            <td className={asLabel && "w-100 d-flex justify-content-end"}>
              {((tax?.amount ?? 0) / 100).toFixed(2)}
            </td>
          </tr> : null}
        </>
      ))}
    </>
  )
}

export default TaxItems