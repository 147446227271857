export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Name",
      accessor: "name",
      show: true,
    },
    // {
    //     title: "Type",
    //     accessor: "rateType",
    //     show: true,
    // },
    // {
    //   title: "Amount",
    //   renderer: (dataObj) => {
    //     return (
    //       <div>
    //         {dataObj?.paymentTerms?.[0]?.amount} {dataObj?.currency}
    //       </div>
    //     );
    //   },
    //   show: true,
    // },
    // {
    //   title: "Cancellation Policy",
    //   renderer: (dataObj) => {
    //     return <div>{dataObj?.cancellationPolicy}</div>;
    //   },
    //   show: true,
    // },
    // {
    //   title: "Booking Policy",
    //   renderer: (dataObj) => {
    //     return <div>{dataObj?.bookingPolicy}</div>;
    //   },
    //   show: true,
    // },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
