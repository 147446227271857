import React, { useContext, useEffect, useRef } from "react";
import Home from "../Home";
import HelpAdminRoom from "./HelpAdminRoom"
import { UserContext } from "../../../store/context/UserContext";
import { useSupportCall } from "../../../helpers/customHooks/supportCall/useSupportCall";

export default function HelpRoom() {

  const userRef = useRef();
  const { supportCallStatusForAdmin } = useContext(UserContext);
  const checkForRejecction = useRef(null);
  const { supportCallInit, supportCallDisconnect, supportCallOpenPeerByAdmin } = useSupportCall('admin', onRemoteStream)
  const userAudio = useRef();
  const userStreamRef = useRef();

  function onRemoteStream(stream) {
    userStreamRef.current = stream;
    if (userStreamRef.current.getVideoTracks()[0].muted) {
      let x = new Audio();
      userAudio.current = x;
      userAudio.current.srcObject = stream
      userAudio.current.onloadedmetadata = () => {
        console.log("Auido connected");
        userAudio.current.play();
      };
    }
  }

  useEffect(() => {
    const fetchData = async () =>
      await supportCallInit("new", { onUserCalling, onUserDisconnected });
    fetchData();
    return supportCallDisconnect;
  }, []);

  useEffect(() => {
    if (supportCallStatusForAdmin === 'waiting-for-call' && !checkForRejecction.current)
      supportCallOpenPeerByAdmin()
  }, [supportCallStatusForAdmin])


  const onUserCalling = (data) => {
    userRef.current = data;
  }
  const onUserDisconnected = () => {
    console.log("diconnected");
  }
  return (
    <>
      {supportCallStatusForAdmin === "call-connected" ? (
        <HelpAdminRoom
          userStreamRef={userStreamRef}
          userAudio={userAudio}
          otherPeerId={userRef.current?.socketId}
          otherPeerUsername={userRef.current?.username}
        />
      ) : (
        <Home otherPeerUsername={userRef.current?.username} checkForRejecction={checkForRejecction} loading={false} />
      )}
    </>
  );
}
