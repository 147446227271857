export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/payment-gateway-account/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/payment-gateway-account/${id}/detail`,
  save: `/api/payment-gateway-account/create`,
  delete: (id) => `/api/payment-gateway-account/${id}/delete`,
  bulkDelete: (id) => `/api/payment-gateway-account/delete`,
  update: `/api/payment-gateway-account/update`,
  gettype: `/api/payment-gateway-account/types`
};
export const SELECTSTRUCTURE = {
  payment_Gateway: [
    { label: "PAYU", value: "PAYU" },
    { label: "PAYPAL", value: "PAYPAL" },
    { label: "RAZORPAY", value: "RAZORPAY" },
    { label: "PAYUBIZ", value: "PAYUBIZ" },
  ],
  active: [
    { label: 'True', value: "True" },
    { label: 'False', value: "False" }
  ]
};

export const STRUCTURE = [

  {
    label: "Account Number",
    filterLabel: "Account Number",
    filterName: "bankAccount.accountNo",
    type: "text",

  },
  {
    label: "Bank Name",
    filterLabel: "Bank Name",
    filterName: "bankAccount.bankName",
    type: "text",
  },
  {
    label: "Holder Name",
    filterLabel: "Holder Name",
    filterName: "bankAccount.holderName",
    type: "text",
  },
  {
    label: "IFSC Code",
    filterLabel: "IFSC Code",
    filterName: "bankAccount.ifsc",
    type: "text",
  },
  // {
  //   label: "Active",
  //   filterLabel: "Active",
  //   filterName: "active",
  //   type: "select",
  //   data:"SELECTSTRUCTURE.active"
  // },
];


