import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FaExternalLinkAlt, FaPlus } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { Button, Card, Table, Input } from 'reactstrap';
import { Checkbox, EditLink, ModalComponent } from '../../components';
import EditFlight from '../Flight/EditFlight';
import FlightService from '../Flight/FlightService';
import GroupTourSidebarService from './sideBar/GroupTourSidebarService';
import "./style.css";

const FlightGrid = ({
    getFlightRecords = () => { },
    hideAdd, passengers = [],
    selectedFlights = [],
    completeObject = false,
    onChange = () => { },
    fixedFlights = []
}) => {
    const [loading, setLoading] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null);
    const [editId, setEditID] = useState("");
    const [modal, setModal] = useState(false);
    const [flights, setFlights] = useState(false);
    const navigate = useNavigate();

    const fetchFlights = async (passengers) => {
        setLoading(true);
        try {
            const passengersId = passengers?.map(passenger => passenger.customer_id)?.join(",");
            const fetchedFlights = await FlightService.fetch(`passengers.customer_id=in[${passengersId}]${!GroupTourSidebarService.selectedFolder._id ? "" : `&space_id=${GroupTourSidebarService.selectedFolder._id}`}`, true);
            getFlightRecords(fetchedFlights);
            setFlights(fetchedFlights);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (passengers?.length) {
            fetchFlights(passengers);
        }
    }, [passengers]);

    const toggleRow = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const handleCheckboxClick = (flight) => {
        let updatedFlights;
        if ((selectedFlights ?? []).some(f => completeObject ? f?._id === flight?._id : f === flight?._id)) {
            updatedFlights = (selectedFlights ?? []).filter(f => completeObject ? f?._id !== flight?._id : f !== flight?._id);
        } else {
            updatedFlights = completeObject ? [...(selectedFlights ?? []), flight] : [...(selectedFlights ?? []), flight?._id];
        }
        onChange(updatedFlights, flight);
    };

    const isFlightSelected = (flight) => {
        return (selectedFlights ?? []).some(f => completeObject ? f?._id === flight?._id : f === flight?._id);
    };

    if ((fixedFlights?.length ? fixedFlights : flights).length === 0) {
        return <div>No Trips found.</div>;
    }

    return (
        <>
            <Card className="pb-3">
                <div style={{ backgroundColor: "#EBEBEB" }} className="h3 ps-3 py-2 d-flex align-items-center justify-content-between">
                    <div> Flights</div>
                    {hideAdd ? null : (
                        <div className="d-flex align-items-center">
                            <Button
                                onClick={() => setModal(true)}
                                className="d-flex align-items-center p-1 me-2">
                                <FaPlus />
                            </Button>
                        </div>
                    )}
                </div>
                {loading ? (
                    <div className='p-3'>
                        loading
                    </div>
                ) : (
                    <div className="zr_flight_accordion_container px-2">
                            {(fixedFlights?.length ? fixedFlights : flights)?.length ? (
                            <>
                                <Table className="table-borderless table-centered table-nowrap zr_flight_accordion_table">
                                    <thead>
                                        <tr style={{ borderBottom: "1px solid #EBEBEB" }}>
                                            <th></th> {/* New Column for Checkbox */}
                                            <th>Flight No</th>
                                            <th>PNR</th>
                                            <th>Booking No</th>
                                            <th>Airline</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            {(fixedFlights?.length ? fixedFlights : flights).map((data, index) => (
                                            <React.Fragment key={index}>
                                                <tr className="zr_flight_accordion_row" onClick={() => toggleRow(index)} style={{ cursor: 'pointer' }}>
                                                    <td>
                                                        <Input
                                                            type="checkbox"
                                                            noLabel
                                                            checked={isFlightSelected(data)}
                                                            value={isFlightSelected(data)}
                                                            onClick={() => handleCheckboxClick(data, index)}
                                                            className="col-2 my-2 align-content-end"
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                        {/* <Checkbox
                                                            noLabel
                                                            checked={isFlightSelected(data)}
                                                            value={isFlightSelected(data)}
                                                            onClick={() => handleCheckboxClick(data,index)}
                                                            type="text"
                                                            className="col-2 my-2 align-content-end"
                                                        /> */}
                                                    </td>
                                                    <td>
                                                        <div className=""> {data.flightNo}</div>
                                                    </td>
                                                    <td className=''>
                                                        <div className=""> {data.PNR}</div>
                                                    </td>
                                                    <td>
                                                        <div>{data?.bookingNo}</div>
                                                    </td>
                                                    <td>
                                                        <div>{data?.airline}</div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {data?.fromCity || data?.fromCountry ? (
                                                                <div>
                                                                    {data?.fromCity ? `${data?.fromCity},` : null}{" "}{" "}<span>{data?.fromCountry}{""}</span><span>{data?.fromCityCode}</span>
                                                                </div>
                                                            ) : <div></div>}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {data?.toCity || data?.toCountry ? (
                                                            <div>
                                                                {data?.toCity ? `${data?.toCity},` : null}{" "}{" "}<span>{data?.toCountry}{""}</span><span>{data?.toCityCode}</span>
                                                            </div>
                                                        ) : <div></div>}
                                                    </td>
                                                    <td>
                                                        {/* <FaExternalLinkAlt
                                                            className="me-2"
                                                            onClick={() => window.open(`/flight/edit/${data?._id}`, '_blank')}
                                                            style={{ cursor: 'pointer' }}
                                                        /> */}
                                                        <EditLink
                                                            onClick={() => {
                                                                setEditID(data?._id);
                                                                setModal(true);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        ) : (
                            <div>No Trips found.</div>
                        )}
                    </div>
                )}
            </Card>

            {modal && (
                <ModalComponent
                    position={"top"}
                    size={"large"}
                    onToggle={() => {
                        setEditID("");
                        setModal(false);
                    }}
                    isOpen={modal}
                >
                    <ModalComponent.Body>
                        <EditFlight
                            insidePane={true}
                            editId={editId}
                            onSave={() => {
                                setModal(false);
                                fetchFlights(passengers);
                            }}
                            onDelete={() => {
                                setModal(false);
                                fetchFlights(passengers);
                            }}
                            formConfig={{
                                showPassengers: false
                            }}
                            prePopulatedData={{
                                passengers
                            }}
                        />
                    </ModalComponent.Body>
                </ModalComponent>
            )}
        </>
    );
};

export default observer(FlightGrid);
