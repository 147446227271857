import React, { useEffect, useState } from 'react';
import { AutoSuggest } from 'react-autosuggestions';
import { doGET } from '../../../util/HttpUtil';
import './style.css';
import { toJS } from 'mobx';
import RateItemService from '../../../components/Dropdowns/RateItemSelect/RateItemSelectService';
import AutoSuggestSelect from '../../../components/AutoSuggestSelect/AutoSuggestSelect';
const ChargeName = ({
outline,
    value,
    onChange,
    className,
    required
}) => {
    const [rateItems, setRateItems] = useState([]);
    const [rateNames, setRateNames] = useState({});

    const fetchRateNames = async () => {
        const rateNameResponse = await RateItemService?.getRateItems();
        setRateItems(toJS(rateNameResponse))
        let rateNameObject = {}

        toJS(rateNameResponse)?.map((rateName) => {
            rateNameObject[rateName?.value] = rateName?.label
            rateNameObject[rateName?.label] = rateName?.label
        });
        setRateNames(rateNameObject)
        return rateNameObject
    }

    useEffect(() => {
        fetchRateNames()
    }, [])

    useEffect(() => {
        console.log(value)
    }, [value])


    return (
        <div className={`d-flex ${className}`}>
            <div className='chargeNameAutoSuggest w-100'>
                <AutoSuggestSelect
                    label="Permit Name"
                    noLabel
                    required
                    className=" col-sm-6"
                    data={rateItems ?? []}
                    value={value}
                    onChange={(event, { newValue }) => {
                        onChange(newValue)
                    }}
                />
                {/* <SelectField
                required={required}
                outline={outline}
                plain={plain}
                noLabel={noLabel}
                data={rateItems}
                value={value}
                onChange={onChange}
                label={("Select Charge")}
                className={className}
                style={{ minWidth: '120px' }}
            /> */}

            </div>
            <div style={{ position: 'relative' }}>
                {required && <font color="red">*</font>}
            </div>
        </div>
    );
};

export default ChargeName;
