export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/payment-made/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  get: (id) => `/api/payment-made/${id}/detail`,
  save: `/api/payment-made/create`,
  delete: (id) => `/api/payment-made/${id}/delete`,
  update: `/api/payment-made/update`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "PaymentMade Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Currency",
    filterLabel: "PaymentMade Currency",
    filterName: "currency",
    type: "currencySelect",
  },
  {
    label: "Referece no",
    filterLabel: "Reference No.",
    filterName: "refNo",
    type: "text",
  },
  {
    label: "Status",
    filterLabel: "Status",
    filterName: "status",
    type: "select",
    data: [
      {
        label: "Pending",
        value: "0",
      },
      {
        label: "Done",
        value: "1",
      },
    ],
  },
];

export const SELECTSTRUCTURE = {
  paymentMode: [
    { label: "ONLINE", value: "ONLINE" },
    { label: "CASH", value: "CASH" },
    { label: "WALLET", value: "WALLET" },
    { label: "DIESEL", value: "DIESEL" },
    { label: "OTHER", value: "OTHER" },
    { label: "UPI", value: "UPI" },
    { label: "VOUCHER", value: "VOUCHER" },
    { label: "CARD", value: "CARD" },
    { label: "CHEQUE", value: "CHEQUE" },
    { label: "WAIVE_OFF", value: "WAIVE_OFF" },
    { label: "FUEL", value: "FUEL" },
    { label: "COMPANY", value: "COMPANY" },
  ],
  paidTo: [
    { label: "Paid To Vendor", value: 1 },
    { label: "Paid To Driver", value: 2 },
    { label: "Paid To Agent", value: 3 },
    { label: "Paid to Vehicle Owner", value: 4 },
    { label: "Others", value: 5 },
  ],
  paymentStatus: [
    { label: "Pending", value: 0 },
    { label: "Done", value: 1 },

  ]
}