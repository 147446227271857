import React, { useEffect, useState } from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { SelectField } from '../../../components';
import NumberInput from './NumberInput';
import './stays.css';
import ColumnHeader from '../module/itineraryDetail/ColumnHeader';

const roomOptions = [
    { value: 'sweetDeluxe', label: 'Sweet Deluxe' },
    { value: 'doubleDeluxe', label: 'Double Deluxe' },
    { value: 'singleRoom', label: 'Single Room' },
    { value: 'presidentialSuite', label: 'Presidential Suite' },
    { value: 'juniorSuite', label: 'Junior Suite' },
];

const hotelOptions = [
    { value: '1', label: '1 star' },
    { value: '2', label: '2 stars' },
    { value: '3', label: '3 stars' },
    { value: '4', label: '4 stars' },
    { value: '5', label: '5 stars' },
];

const Stays = ({ value = [], onChange }) => {
    const [stays, setStays] = useState([]);

    useEffect(() => {
        if (value.length) {
            setStays(value);
        }
    }, [value]);

    const addStay = () => {
        const newStay = {
            hotel: '',
            checkInDay: 1,
            checkOutDay: 2,
            hotelBooking_id: '',
            hotel_id: '',
            supplier_id: '',
            checkInDate: '',
            checkOutDate: ''
        };
        const updatedStays = [...stays, newStay];
        setStays(updatedStays);
        onChange(updatedStays);
    };

    const updateStay = (index, field, value) => {
        const updatedStays = stays.map((stay, i) => i === index ? { ...stay, [field]: value } : stay);
        setStays(updatedStays);
        onChange(updatedStays);
    };

    const removeStay = (index) => {
        const updatedStays = stays.filter((_, i) => i !== index);
        setStays(updatedStays);
        onChange(updatedStays);
    };

    return (
        <div>
            <ColumnHeader>
                <ColumnHeader.Title>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                        <div>Hotels</div>
                        <Button onClick={addStay} className=" d-flex align-items-center py-1">
                            <FaPlus className='me-2' /> Add Stay
                        </Button>
                    </div>
                </ColumnHeader.Title>
                <ColumnHeader.Body maxHeight="calc(100vh - 350px)">

                    {stays.map((stay, index) => (
                        <div key={index} className="card zr_it_stays_stay d-flex flex-column align-items-start p-2">
                            <div className=" d-flex w-100 align-items-center justify-content-end">
                                <FaTimes
                                    onClick={() => removeStay(index)}
                                    fontSize="14"
                                />
                            </div>
                            {/* 
                    <InputField
                        className="zr_it_stays_input w-100 flex-1"
                        value={stay.hotel}
                        outline
                        label="Hotel"
                        onChange={(e) => updateStay(index, 'hotel', e)}
                    /> */}

                            <SelectField
                                outline
                                data={roomOptions}
                                value={roomOptions.find(option => option.value === stay.roomType)}
                                onChange={(selectedOption) => updateStay(index, 'roomType', selectedOption.value)}
                                label="Room Type"
                            />

                            <SelectField
                                outline
                                data={hotelOptions}
                                value={hotelOptions.find(option => option.value === stay.hotelType)}
                                onChange={(selectedOption) => updateStay(index, 'hotelType', selectedOption.value)}
                                label="Hotel Type"
                            />

                            <NumberInput
                                label="Check In Day"
                                value={stay.checkInDay}
                                onChange={(newValue) => updateStay(index, 'checkInDay', newValue)}
                            />
                            <NumberInput
                                label="Check Out Day"
                                max={10}
                                value={stay.checkOutDay}
                                onChange={(newValue) => updateStay(index, 'checkOutDay', newValue)}
                            />

                        </div>
                    ))}
                </ColumnHeader.Body>
            </ColumnHeader>
        </div>
    );
};

export default Stays;
