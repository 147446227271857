import React from 'react'
import { BsPersonStanding } from 'react-icons/bs'
import { FaFemale, FaHome, FaMale } from 'react-icons/fa'
import { getFullAddress, isFemalePassenger } from '../../util/Util'
import { FEMALE_COLOR, MALE_COLOR } from './utils'
import "./styles.css"
import { IoLocationOutline } from 'react-icons/io5'

function PassengerCard({ passenger }) {
    const Icon = React.useMemo(() => {
        return isFemalePassenger(passenger?.salutation) ? <FaFemale size={15} color={FEMALE_COLOR} /> : <FaMale size={15} color={MALE_COLOR} />
    }, [passenger?.salutation])

    return (
        <div className='overflow-text'>
            <div className='align-items-center d-flex'>
                {/* <Icon size={15} className='me-1' color='#999999' /> */}
                <div>
                    {Icon}
                </div>
                <div className='font-size-11'>{passenger.name}</div>
            </div>
            <div className='d-flex align-items-center'>
                <div>
                    <IoLocationOutline size={15} className={`me-1 `} color='#999' />
                </div>
                <div className='font-size-11 overflow-text'>{passenger?.residence ? getFullAddress(passenger?.residence) : '-'}</div>
            </div>
        </div>
    )
}

export default PassengerCard