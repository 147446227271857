import React, { useState } from "react";
import Tab from "../../components/Common/Tab/Tab";
import Invoices from "./Invoices";
import Payment from "./Payment";

export default function MyAccount() {
  const [step, setStep] = useState(1);

  const headingStyle = {
    fontSize: '18px',
    fontWeight: '600',
  }

  return (
    <div style={{ marginTop: "48px" }}>
      <p className="pt-4 px-2 text-start" style={headingStyle}>My Account</p>
      <Tab
        activeTab={step}
        onChange={(data) => {
          setStep(data);
        }}
      >
        <div num={1} label="Recent Invoice">
          <Invoices />
        </div>
        <div num={2} label="Payments">
          <Payment />
        </div>
      </Tab>
    </div>
  );
}
