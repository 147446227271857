import { toJS } from "mobx";
import React, { useEffect, useState, useContext } from "react";
import { SelectField } from "../../SelectField/SelectField";
import TimeZoneSelectService from "./TimeZoneSelectService";
import { I18nContext } from "../../../store/context/i18nContext";

export default function TimeZoneSelect(props) {

    const [timeZones, setTimeZones] = useState([]);
    const { t } = useContext(I18nContext);

    useEffect(() => {
        const fetch = async () => {
            try {
                await TimeZoneSelectService.fetch();
                setTimeZones(toJS(TimeZoneSelectService.timeZones))
            } catch (error) {
                console.log(error.message);
            }
        }
        fetch();
    }, []);

    return (
        <React.Fragment>
            {timeZones.length > 0 &&
                <SelectField
                    plain={props?.plain}
                    className={props?.className}
                    onChange={props?.onChange}
                    data={timeZones}
                    value={props?.value ?? Intl.DateTimeFormat().resolvedOptions().timeZone}
                    label={t("TimeZone")}
                    error={
                        props?.value
                            ? t("Please Select TimeZone")
                            : ""
                    }
                    showErr={props?.showErr}
                    inline={props?.inline}
                    borderNone={props?.borderNone}
                    RightComponent={props?.RightComponent}
                    onClickRight={props?.onClickRight}
                />}
            {!timeZones.length > 0 &&
                <SelectField
                    plain={props?.plain}
                    className={props?.className}
                    onChange={props?.onChange}
                    data={timeZones}
                    value={props?.value}
                    label={t("TimeZone")}
                    error={
                        props?.value
                            ? t("Please Select TimeZone")
                            : ""
                    }
                    isDisabled={true}
                    showErr={props?.showErr}
                    inline={props?.inline}
                    borderNone={props?.borderNone}
                    RightComponent={props?.RightComponent}
                    onClickRight={props?.onClickRight}
                />}
        </React.Fragment>
    )
}
