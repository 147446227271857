import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./OfficeConstant";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import OfficeSelectService from "../../components/Dropdowns/Office/OfficeSelectService";
import { getUiPrefRows } from "../../util/FilterUtil";
class Service {
    records = [];
    STRUCTURE = [];
    page = 1;
    rowsPerPage = null;
    filterOption = "";
    total = -1;
    version = 0;

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async (filterUrl) => {
        if (!this?.rowsPerPage) {
            const rows = await getUiPrefRows("office.grid")
            this.rowsPerPage = rows ?? 40
        }

        if (filterUrl) {
            this.filterOption = filterUrl;
        }
        const response = await doGET(
            ENDPOINTS.grid({ page: this.page, sizePerPage: this.rowsPerPage, filterUrl })
        );

        if (response?.status === 200) {
            this.records = response.data.rows;
            this.page = response?.data?.page;
            if (this.page === 1) this.total = response?.data?.total;
        }
        return response.data.rows
    };

    edit = async function ({ data, org_id }) {
        const response = await doPUT(ENDPOINTS?.update({ org_id }), { ...data, org_id: org_id });
        if (response?.status === 200) {
            this.version += 1;
            // OfficeSelectService?.fetch({})
        }
    };

    get = async ({ editId, org_id }) => {

        const response = await doGET(ENDPOINTS.get({ editId, org_id }));
        console.log(ENDPOINTS.get({ editId, org_id }))
        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    save = async ({ data, org_id }) => {
        this.records.push(data);
        const response = await doPOST(ENDPOINTS.save(), data);
        // if (response?.status === 200) {
        //     OfficeSelectService?.fetch({})
        // }
        return response?.data?._id;
    };

    bulkDelete = async (ids = []) => {
        const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
        if (response.status === 200) {
            this.fetch();
        }
    };
    delete = async (id, org_id) => {
        const response = await doDELETE(ENDPOINTS.delete(id, org_id));
        if (response.status === 200) {
            if (org_id) {
                const filterUrl = "org_id=" + org_id;
                this.fetch(filterUrl);

            } else {
                this.fetch();

            }
        }
    };

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch(this.filterOption);

    };

}

const OfficeService = new Service();
export default OfficeService;

