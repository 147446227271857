import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FaExchangeAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Card, Form, Row } from "reactstrap";
import { CompanySelect, DeleteButton, SaveButton } from "../../components";
import CustomDropdown from "../../components/CustomDropDown";
import ActionMenu from "../../components/Dropdowns/ActionMenu/ActionMenu";
import ReminderButton from "../../components/Reminders/ReminderButton";
import TagPicker from "../../components/TagSelector/TagPicker";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import PassengerSelect from "../Booking/PassengerSelect";
import BookingStatusModifier from "../Booking/components/BookingStatusModifier";
import CommunicationButton from "../Communication/CommunicationButton";
import BookingGrid from "./BookingGrid";
import FlightsGrid from "./FlightsGrid";
import GroupTourService from "./GroupTourService";
import StayGrid from "./StayGrid";
import GroupTourSidebarService from "./sideBar/GroupTourSidebarService";
import ChangeSpaceModal from "./sideBar/changeSpaceModal/ChangeSpaceModal";

const TOURTYPE_DATA = ['Event', 'Meeting', 'Conference']


const guests = [
    {
        index: 0,
        anonymous: false,
        salutation: "Mr",
        phone: {
            countryCode: 91,
        },
    },
]


const EditGroupTour = ({ editId,
    onSave,
    onDelete,
    insidePane,
}) => {
    let navigate = useNavigate();
    const { t } = useContext(I18nContext);
    const { userContent } = useContext(UserContext);

    const { showError, showMessage, showConfirm } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [hasErr, setHasErr] = useState(false)
    const [flights, setFlights] = useState([]);
    const [stays, setStays] = useState([]);
    const [changeSpaceModal, setChangeSpaceModal] = useState(false);

    const [showForm, setShowEditForm] = useState({
        visible: false,
        editId: null,
        type: null
    })

    const fetch = async () => {
        try {
            let data = await GroupTourService.get(editId);
            setData({
                ...data,
                passengers: data?.passengers ? data?.passengers : guests,
            })
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        setData(null)
        setLoading(true);
        if (editId) { fetch() }
        else {
            setData({
                ...data,
                passengers: data?.passengers ? data?.passengers : guests,
            })
        }
        setLoading(false)
    }, [editId, GroupTourService?.version]);


    const handleTourChange = useCallback((fieldName, fieldValue) => {
        if (fieldName && fieldValue) {
            setData(prev => ({
                ...(prev ?? {}),
                [fieldName]: fieldValue,
            }));
        }
    }, []);


    const handleClientCompanyChange = useCallback(({ company_id, client_id, name }) => {

        const eClient = { ...data?.client } || {};
        if (company_id) {
            eClient["company_id"] = company_id;
        }
        if (client_id) {
            eClient["client_id"] = client_id;
            eClient["company_id"] = null;
        }
        eClient["name"] = name;
        handleTourChange("client", eClient);

    },
        [data?.client, handleTourChange]
    );



    const handleSave = async (e) => {
        e.preventDefault();
        // if (!data?.status && !data?.remark) {
        //     setHasErr(true)
        //     showError(t("Please enter all required fields"));
        //     return;
        // }
        setLoading(true);
        try {
            if (editId) {
                await GroupTourService.edit(data, editId);
                setLoading(false);
                showMessage(t("Record updated successfully."));
            } else {
                const tour = await GroupTourService.save({
                    ...data,
                    space_id: GroupTourSidebarService?.selectedFolder?._id
                });
                setLoading(false);
                showMessage(t("Record saved successfully."));
                if (!insidePane) navigate(`/tour/edit/${tour}`);
            }
            onSave();
        } catch (e) {
            showError(e);
            setLoading(false);
        }
        setHasErr(false)
    };


    const handleEditSpace = async ({ payload }) => {
        setLoading(true);
        try {
            if (editId) {
                await GroupTourService.edit(payload, editId);
                setLoading(false);
                showMessage(t('Space changed successfully'));
            }
            onSave();
        } catch (e) {
            showError(e);
            setLoading(false);
        }
        setHasErr(false)
    };

    const handleDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await GroupTourService.delete(id);
                setLoading(false);
                onDelete();

                showMessage(t("Deleted"), t("Deleted"));
            } catch (e) {
                showError(e);
                setLoading(false);
            }
        }
    };

    const onStatusChange = async (e, payload) => {
        e.preventDefault();
        if (!editId || !payload?.status) {
            return;
        }
        setLoading(true);
        try {
            const resp = await GroupTourService.edit({ ...data, status: payload.status }, editId);
            if (resp?.data) {
                setData(resp?.data);
            }
            showMessage("Status Changed successfully");
        } catch (e) {
            showError(e);
        } finally {
            setHasErr(false)
            setLoading(false);
        }
    };
    const statusMapping = {
        0: { text: "Pending", color: "#AEB6BF" },
        1: { text: "Confirmed", color: "#2980B9" },
        2: { text: "Ready", color: "#A569BD" },
        3: { text: "Dispatched", color: "#FFBF00" },
        4: { text: "Live", color: "#FF7F50" },
        5: { text: "Completed", color: "#52BE80" },
        6: { text: "Cancelled", color: "#F1948A" },
        7: { text: "Error", color: "#CB4335" },
        8: { text: "Paused", color: "#CCCCFF" },
        9: { text: "Expired", color: "#7B241C" },
        10: { text: "Rejected", color: "#7B241C" },
    };

    const handleChangeSpace = () => {
        setChangeSpaceModal(true)
    };


    return (
        <React.Fragment>
            {loading ?
                <ThreeBounce /> :
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <div className="zr_layout-header p-2 d-flex justify-content-between" style={{ flexShrink: 0 }} >
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                {data?._id ? t("Edit Tour") : t("Add Tour")}

                                <TagPicker
                                    className="ms-2"
                                    kind={"tour"}
                                    onChange={(v) => setData(prev => ({
                                        ...prev,
                                        tags: v
                                    }))}
                                    value={data?.tags}
                                />


                                {data?._id ? <BookingStatusModifier
                                    apiMethod={onStatusChange}
                                    currentStatus={data?.status}
                                    trip_id={editId}
                                    statusMapping={statusMapping}
                                /> : null}


                            </div>

                            {editId ? <div className="d-flex align-items-center">

                                <ReminderButton
                                    record_id={editId}
                                    module={"tour"}
                                    className={"me-0"}
                                    space_id={GroupTourSidebarService?.selectedFolder?._id}
                                />

                                <ActionMenu
                                    data={[
                                        { label: 'Change Space', icon: <FaExchangeAlt />, onClick: handleChangeSpace },
                                    ]}
                                    menuPosition="right"
                                    className="zr_action_menu me-2"
                                />

                            </div> : null}



                        </div>
                        {/* <CrossButton  onClick={onBack} style={{position:"relative !important"}}/> */}
                    </div>
                    <Form
                        className="p-3"
                        style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                    >
                        <Row className="mt-0 mb-3 d-flex flex-column flex-md-row align-items-center">
                            {/* <div className="col-12 col-md-6">
                            <div className="d-flex row col-sm-10">

                                <div className="col-6 pe-0 ">
                                    <ClientSelect
                                        value={data?.client?.client_id}
                                        onChange={(v) => {
                                            handleClientCompanyChange({
                                                client_id: v?.value
                                            });
                                            // vendorCompany_id
                                        }}
                                        innerContainerClassName="input-group-pre"
                                        className={"pe-0 "}
                                        outline={true}
                                    />
                                </div>
                                <div className="col-6 ps-0 ">
                                    <CompanySelect
                                        outline
                                        corpType="client"
                                        value={data?.client?.company_id}
                                        onChange={(v) => {
                                            handleClientCompanyChange({
                                                company_id: v?.value
                                            });

                                        }}
                                        innerContainerClassName="input-group-post"
                                        className={"ps-0 ms-0"}
                                        label="Client Company"
                                        corp_id={data?.client?.client_id}
                                    />
                                </div>
                            </div>
                        </div> */}



                            <div className="col-12 d-flex justify-content-start align-items-center">

                                <CompanySelect
                                    outline
                                    className={"flex-1 w-100"}
                                    style={{ minWidth: "200px" }}
                                    value={data?.vendorCompany_id}
                                    onChange={(v) =>
                                        setData(prev => ({ ...prev, vendorCompany_id: v?.value }))
                                    }
                                    label="Serving Company"
                                />



                                <CustomDropdown
                                    className="px-4"
                                    value={data?.tourType}
                                    label="Tour Type"
                                    data={TOURTYPE_DATA}
                                    onChange={(v) => {
                                        setData(prev => ({ ...prev, tourType: v }))
                                    }}
                                />

                            </div>




                        </Row>


                        {/* <Row className="mt-3 mb-3">

                        <TokenInput
                            label={t("Activites")}
                            type="text"
                            value={data?.activites ?? ["Click here to edit"]} // [] array of strings
                            onChange={(v) => {
                                setData(prev => ({ ...prev, activites: v }))  // v should be [] array of strings
                                console.log(data)
                            }}
                            placeholder={t("Add Activities")}
                            outline
                            className="col-12 flex-1"
                        />

                    </Row> */}

                        <Row className="mt-3 mb-3">
                            <div><b>Passengers</b></div>
                            <PassengerSelect
                                space_id={GroupTourSidebarService?.selectedFolder?._id}
                                passengers={data?.passengers}
                                onChange={(v) => {
                                    setData({ ...data, passengers: v })
                                }}
                                label={"Select Customer"}
                            />
                        </Row>
                        {/* <Row className="mt-3 mb-3">
                        <BookedBy
                            showPaxCount={false}
                            className="pb-1"
                            emptyDivStyle={{
                                maxWidth: "196.5px",
                                paddingRight: "0px"
                            }}
                            value={data?.bookedBy}
                            onChange={(v) => {
                                setData(prev => ({
                                    ...prev,
                                    bookedBy: v
                                }))
                            }}
                        />
                    </Row> */}
                        {/* {JSON.stringify({ flight: data?.flights })}
                        {JSON.stringify({ stay: data?.stays })} */}
                        {editId ? <>

                            <FlightsGrid
                                passengers={data?.passengers}
                                selectedFlights={data?.flights}
                                onChange={(v) => setData({ ...data, flights: v })}
                                getFlightRecords={setFlights}

                            />
                            {/* {JSON.stringify({data})} */}
                            <StayGrid
                                passengers={data?.passengers}
                                selectedStays={data?.stays}
                                onChange={(v) => setData({ ...data, stays: v })}
                                getStaysRecords={setStays}
                            />

                            <Card className=" pb-3 ">
                                <div style={{ backgroundColor: "#EBEBEB" }} className="h3 ps-3 py-2 d-flex align-items-center justify-content-between">
                                    <div> Trips</div>

                                </div>
                                <div className="px-2 ">
                                    <BookingGrid passengers={data?.passengers} />
                                </div>
                            </Card>

                        </> : null}
                    </Form>
                    <div className="zr_layout-footer d-flex justify-content-end align-items-center pe-4" style={{ flexShrink: 0, marginBottom: insidePane ? "20px" : "0px" }}>

                        <div className="d-flex align-items-center">
                            {data?._id && userContent?.rights?.includes(6103) &&
                                <CommunicationButton
                                    tour_id={editId}
                                    customers={data?.passengers}
                                    flights={flights}
                                    stays={stays}
                                />
                            }

                            {editId && userContent?.rights?.includes(6103) && data?._id ?
                                <DeleteButton
                                    loading={loading}
                                    onClick={(e) => handleDelete(e, data?._id)} />
                                : null}

                            {userContent?.rights?.includes(6103) ?
                                <SaveButton
                                    loading={loading}
                                    onClick={handleSave} />
                                : null}

                        </div>
                    </div>
                </div>
            }

            {changeSpaceModal ?
                < ChangeSpaceModal
                    isOpen={changeSpaceModal}
                    selectedSpaceId={data?.space_id}
                    toggleModal={() => { setChangeSpaceModal(false) }}
                    onSave={(space_id) => {
                        handleEditSpace({ payload: { ...data, space_id } })
                        setData({ ...data, space_id })
                    }}
                /> : null}
        </React.Fragment>
    );
};

export default observer(EditGroupTour);
