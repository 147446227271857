import { toJS } from "mobx";
import React, { useEffect, useState } from "react";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { SelectField } from "../../SelectField/SelectField";
import CityGroupSelectService from "./CityGroupSelectService";

export default function CityGroupSelect(props) {
  const [cityGroups, setCityGroups] = useState([]);
  const [countryCityGroups, setCountryCityGroups] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        await CityGroupSelectService.fetch();
        setCityGroups(
          toJS(CityGroupSelectService.cityGroups)?.map((c) => {
            return {
              ...c,
              value: c?._id,
              label: (
                <div
                  style={{
                    maxWidth: props?.maxWidth ? props?.maxWidth : "unset",
                    wordWrap: "break-word",
                  }}
                >
                  {c.name} -{" "}
                  {c?.cities?.map((x) => (
                    <span>{x}, </span>
                  ))}
                </div>
              ),
            };
          })
        );
      } catch (error) {
        console.log(error.message);
      }
    };
    if (!props?.typeCountry) {
      fetch();
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        await CityGroupSelectService.fetchCityByCountry(props?.country);
        setCountryCityGroups(
          toJS(CityGroupSelectService.countryCityGroups.rows)?.map((c) => {
            return {
              ...c,
              value: c?._id,
              label: (
                <div
                  style={{
                    maxWidth: props?.maxWidth ? props?.maxWidth : "unset",
                    wordWrap: "break-word",
                  }}
                >
                  {c.name} -{" "}
                  {c?.cities?.map((x) => (
                    <span>{x}, </span>
                  ))}
                </div>
              ),
            };
          })
        );
      } catch (error) {
        console.log(error.message);
      }
    };
    fetch();
  }, [props?.country]);

  const handleOnChange = (data) => {
    if (props?.multi) {
      props?.onChange(
        data?.map((c) => {
          return { _id: c._id };
        })
      );
    } else {
      props?.onChange(data._id);
    }
  };

  return (
    <React.Fragment>
      {cityGroups?.length > 0 || countryCityGroups?.length > 0 ? (
        !props?.multi ? (
          props?.typeCountry ? (
            <SelectField
              plain={props?.plain}
              className={props?.className}
              placeholder={props?.plain ? "City Group" : ""}
              onChange={handleOnChange}
              data={countryCityGroups}

              value={props?.value}
              label={"City Group"}
              showErr={props?.showErr}
              required={props?.required}
              error={!props?.value ? "Please Select City Group" : ""}
              isDisabled={!props?.country}
              inline={props?.inline}
              borderNone={props?.borderNone}
              RightComponent={props?.RightComponent}
              onClickRight={props?.onClickRight}
            />
          ) : (
            <SelectField
              plain={props?.plain}
              placeholder={props?.plain ? "City Group" : ""}
              className={props?.className}
              onChange={handleOnChange}
              data={cityGroups}

              value={props?.value}
              label={"City Group"}
              showErr={props?.showErr}
              required={props?.required}
              error={!props?.value ? "Please Select City Group" : ""}
              inline={props?.inline}
              borderNone={props?.borderNone}
              RightComponent={props?.RightComponent}
              onClickRight={props?.onClickRight}
            />
          )
        ) : (
          <SelectFieldMultiple
            className={props?.className}
            showErr={props?.showErr}
            required={props?.required}
            // onChange={(v) => {
            //   props?.onChange(v?.map((c) => { return { _id: c._id, name: c.name } }))
            // }
            // }
            onChange={handleOnChange}
            data={cityGroups}
            value={
              props?.value?.length
                ? cityGroups.filter(
                  (c) => props?.value?.findIndex((x) => x._id === c._id) !== -1
                )
                : ""
            }
            error={props?.value ? "" : "Select City Groups"}

            label={"Select City Groups"}
          />
        )
      ) : (
        <SelectField
          className={props?.className}
          onChange={(v) => { }}
          value={props?.value}

          label={"Select City Groups"}
          isDisabled={true}
          showErr={props?.showErr}
        />
      )}
    </React.Fragment>
  );
}
