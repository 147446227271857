import { Circle } from 'better-react-spinkit';
import React, { useState } from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import './MediaModal.css';

const MediaModal = ({ isOpen, toggle, centered, children }) => {
  if (!isOpen) return null;

  return (
    <div className="media-card-overlay d-flex justify-content-center align-items-center h-100 w-100" onClick={toggle}>
      <div
        className={` bg-white borderRadius-8 d-flex flex-column position-relative overflow-hidden media-card ${centered ? 'centered' : ''}`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

const Header = ({ title, onClose, onDownload }) => {

  const [isDownloading, setDownloading] = useState(false);
  const handleDownload = async () => {
    setDownloading(true);
    try {
      await onDownload();
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div className="media-card-header pb-2 d-flex justify-content-between align-items-center">
      <div className="font-size-16 fw-normal mx-3 mt-1">{title}</div>
      <div className="d-flex mx-3 mt-1 ">
        <button className={`btn bg-transparent border-0 text-dark fs-3 cursor-pointer`} onClick={handleDownload}>
          {isDownloading ? <Circle /> : <MdOutlineFileDownload />}
        </button>
        <button className="bg-transparent border-0 text-dark fs-3 cursor-pointer" onClick={onClose}>
          <RxCross2 />
        </button>
      </div>
    </div>
  );
};

const Body = ({ children, className = "" }) => {
  return (
    <div className={`flex-1 p-3 d-flex justify-content-center align-items-center text-center ${className}`}>
      {children}
    </div>
  );
};

MediaModal.Header = Header;
MediaModal.Body = Body;

export default MediaModal;
