import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { BottomBarButton, Modal, ModalComponent, SearchButton } from "../../index";
import Corporate from "../../../pages/Corporate/Corporate";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import CorporateSelectService from "./CorporateSelectService";


const CorporateSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  asLabel,
  plain,
  outline,
  wrapping,
  asDomain,
  ...props 
}) => {
  const [corporates, setCorporates] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const fetchCorporates = async () => {
    try {
      await CorporateSelectService.fetch();
      const formattedCorporates=toJS(CorporateSelectService?.corporates);
      setCorporates(
        formattedCorporates.map((corporate) => {
          return {
            ...corporate,
            value: corporate?._id,
            menuLabel: (
              <div className="d-flex gap-2">
                <div>{corporate?.name}{" "}</div>
                <div>({corporate?.domain})</div>
              </div>
            ),
          };          
        })
      );
      if (value == "selectFirst") {
        onChange(toJS(corporates)[0])
      }
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (CorporateSelectService?.corporates?.length === 0) {
      fetchCorporates();
    }
  }, []);

  useEffect(() => {
    if (value == "selectFirst") {
      onChange(toJS(CorporateSelectService.corporates)[0])
    }
    fetchCorporates();
  }, [CorporateSelectService.corporates]);




  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectCorporate = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0]?._id : selectedValue?._id });
    closeModal();
  };

  const selectCorporates = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };


  const formatOptionLabel = ({ label, menuLabel }, { context }) => {
    return context === 'menu' ? menuLabel : label;
  };

  const filterOption = (option, inputValue) =>
    !inputValue ||
    ['domain',`name`].some(
      key =>
        option.data?.[key]?.toLowerCase().includes(inputValue.toLowerCase())
    );

  

  const renderSelectField = ({ disabled }) => (
    <SelectField
      formatOptionLabel={formatOptionLabel}
      filterOption={filterOption}
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Corporates")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={corporates}
      required={required}
      value={value}
      label={t(`${label || 'Corporate'}`)}
      error={!value ? t(`Please Select ${label || "Corporate"}`) : ""}
      showErr={showErr}
      plain={plain}
      outline={outline}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
      InputGroupChild={() => {
        return <div className="px-2 d-flex justify-content-center align-items-center">
          {/* {!value && <i className="mdi font-size-20  mdi-car-hatchback"></i>} */}
          {
            toJS(CorporateSelectService?.corporates)?.map((corporate, idx) => {
              if (corporate?._id == value)
                return <div className="d-flex mx-2" key={idx}>
                          <div>{corporate?.domain}</div>
                      </div>;
            })
          }
        </div>
      }}
      {...props}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      formatOptionLabel={formatOptionLabel}
      filterOption={filterOption}
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Corporates")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={selectCorporates}
      data={corporates}
      value={value ? corporates.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Corporates"}`) : ""}
      required={required}
      plain={plain}
      outline={outline}
      label={t("Corporates")}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );
  return (
    <>
      {asLabel ? 
        ( multi ?
         ( value?.length > 0 ?
          (<div>
            {wrapping ?
              <div>
                {value?.slice(0, wrapping).map((selectedValue, index) => (
                  <span key={selectedValue}>
                    {corporates.find((corporate) => corporate.value === selectedValue)?.label || ""}
                    {index < wrapping - 1 && ", "}
                  </span>
                ))}
                {value.length > wrapping &&
                  ` + ${value.length - wrapping} `
                }
              </div>
              :
              value?.map((selectedValue, index) => (
                <span key={selectedValue}>
                  {corporates.find((corporate) => corporate.value === selectedValue)?.label || ""}
                  {index < value.length - 1 && ", "}
                </span>
              ))
            }
               </div>) : <div style={{ color: "grey" }}>No Record Found</div>)
         : (<div>{corporates?.find((corporate) => corporate?.value == value)?.label ?? <div style={{ color: "grey" }}>No record Found</div>}</div>)
        ):
       (asDomain?
          (<div>{corporates?.find((corporate) => corporate?.value == value)?.domain ?? <div style={{ color: "grey" }}>No record Found</div>}</div>) 
         :
         (<>
          {modal && (
            <ModalComponent
              position={"top"}
              size={"medium"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Corporate")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <Corporate
                  value={value}
                  insidePane={true}
                  multiMode={multi}
                  onSelect={multi ? selectCorporates : selectCorporate}
                />
              </ModalComponent.Body>
            </ModalComponent>
          )}
          {corporates.length > 0
            ? multi
              ? renderSelectFieldMultiple()
              : renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>))
      }
    </>
  );
};

export default observer(CorporateSelect);
