import axios from 'axios';
import React, { useContext, useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { DialogContext } from '../../store/context/DialogContext';
import { Circle } from 'better-react-spinkit';
import { MdOutlineFileDownload } from 'react-icons/md';
import { downloadMedia } from '../../util/Util';

const ExcelChatDisplay= ({ url, ...restProps }) => {
  const [loading, setLoading] = useState(false);
  const { showError } = useContext(DialogContext);
  const getFilenameFromUrl = (url) => {
    return url.substring(url.lastIndexOf('/') + 1) || 'download.xlsx';
  };

  const downloadExcel = async (url) => {
    if (url) {
        setLoading(true);
        try {
            const response = await axios({
                url: url,
                headers: {
                    Authorization: localStorage.getItem("BearerToken"),
                },
                withCredentials: true,
                responseType: 'blob',
            });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const blobUrl = window.URL.createObjectURL(blob);

            await downloadMedia(blobUrl, 'excel');
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }
};

  const handleClick = () => {
    if (url) {
      downloadExcel(url);
    }
  };


  return (
    <div  className=' zr_whatsapp_doc_item' {...restProps}>
      <FaFileExcel color='green' size={25} className='me-2' /><b>{getFilenameFromUrl(url)}</b> 
      {loading ? <Circle className='ms-1' /> : <MdOutlineFileDownload className='ms-1' onClick={handleClick} size={25}  />}
    </div>
  );
};

export default ExcelChatDisplay;
