import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  BulkUpload,
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./AccountConstant";
import { GridColumns } from "./AccountGridColumns";
import AccountsService from "./AccountsService";
import EditAccounts from "./EditAccount";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";


const Accounts = ({ value = undefined, insidePane, multiMode = false, onSelect = () => { } }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const { userContent } = useContext(UserContext);
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/accounts/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)
  const [uploadPopup, setUploadPopup] = useState(false);

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await AccountsService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.acDetail?.name && detailData?.acDetail?.accountNo) {
      setHasErr(true)
      showError(t("Please enter all fields"));
      return;
    }
    setHasErr(true)
    setLoading(true);
    try {
      if (editID) {
        await AccountsService.edit(detailData, editID);
        showMessage("Account Updated successfully");
      } else {
        const account = await AccountsService.save(detailData);
        if (account) setEditID(account)
        showMessage(t("Account saved successfully."));
        if (!insidePane) navigate(`/accounts/edit/${account}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setHasErr(false)
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await AccountsService.delete(id);
        showMessage("Account Deleted Successfully", "Deleted");
        setShowDetailPage(false);
        navigate(`/accounts`);
      } catch (e) {
        showError(e);
      }
    }
  };

  const saveBulk = async (data) => {
    try {
      const account = await AccountsService.save(data);
      if (account) {
        return { isUploaded: true, message: "" }
      }
      return { isUploaded: false, message: "Failed unexpectedly" }
    } catch (error) {
      return { isUploaded: false, message: JSON.parse(error?.message)?.customMessage ?? JSON.parse(error?.message)?.message }
    }
  };


  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await AccountsService.bulkDelete(selectedIDs);
        setLoading(false);
        showMessage(t("Deleted"), t("Deleted Successfully"));
        setSelectedIDs([])
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback((account) => {
    return (
      <>
        <EditLink
          onClick={() => {
            if (!insidePane)
              navigate(`/accounts/edit/${account?._id}`);
            setEditID(account?._id);
            setShowDetailPage(true);
          }}
        />
        {userContent?.rights?.includes(4103) ?
          <DeleteLink
            onClick={(event) => onDelete(event, account?._id)}
          />
          : null}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(account?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])


  return (
    <React.Fragment>
      <>
        <BulkUpload
          show={uploadPopup}
          onClose={() => { setUploadPopup(false) }}
          title={t("Account")}
          kind={"Account"}
          upload={saveBulk}
        />
        <Layout
          hideAdd={!userContent?.rights?.includes(4101)}
          showDetailPage={showDetailPage}
          medium
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/accounts");
            setEditID(null);
          }}
           
          title={t("Accounts")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/accounts/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={AccountsService.page}
          rows={AccountsService.rows}
          total={AccountsService.total}
          fetch={AccountsService.fetch}
        >
          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  {userContent?.rights?.includes(4103) ?
                  <>
                    <DropdownItem onClick={() => { onBulkDelete() }}>{t("Bulk Delete")}</DropdownItem>
                    <div class="dropdown-divider"></div>
                    </>
                    : null}
                  <DropdownItem onClick={() => setUploadPopup(true)}>
                    {t("Bulk Upload")}
                  </DropdownItem>
                  <DropdownItem> {t("Download")} </DropdownItem>
                </>
              </DropdownMenu>
            </div>
          </Layout.ActionMenu>

          <Layout.Table>
            <DataGrid
              gridLoading={loading}
              data={AccountsService.records}
              total={AccountsService.total}
              uiPreference="account.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={(v) => {
                onSelect(v)
                setSelectedIDs(v)
              }}
              page={AccountsService.page}
              rowsPerPage={AccountsService.rowsPerPage}
              onPaginationChange={AccountsService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </Layout.Table>
          <Layout.DetailPageTitle>
            <div className="d-flex justify-content-between align-items-center">
              {detailData?._id ? (
                <span>
                  {t(`Edit Account `)}
                  <span style={{ color: '#999999' }}>{detailData?.acDetail?.name}</span>
                </span>
              ) : t("Add Account")}
            </div>
          </Layout.DetailPageTitle>

          <Layout.DetailPageBody>
            <EditAccounts
              editId={editID}
              onChange={(v) => {
                setDetailData(v)
              }}
              hasErr={hasErr}
            />
          </Layout.DetailPageBody>

          <Layout.DetailPageFooter>
            {detailData?._id ? (
              <AuditLogDialog module="Account" id={detailData?._id} />
            ) : null

            }
            {userContent?.rights?.includes(4103) && detailData?._id ? (
              <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />
            ) : null}
            {userContent?.rights?.includes(4102) ?
              <SaveButton onClick={onSave} loading={loading} />
              : null}


          </Layout.DetailPageFooter>
        </Layout>
      </>

    </React.Fragment>
  );
};

export default observer(Accounts);