import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import Select, { components } from "react-select";
import debounce from 'lodash/debounce';

import { I18nContext } from "../../store/context/i18nContext";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import AutoFillService from "./AutoFillService";

const CityCountrySelectAirport = ({ asLabel, style, onChange, value, noLabel, placeholder }) => {
    const [cities, setCities] = useState([]);
    const [fetchError, setFetchError] = useState(false); // New state to track fetch errors
    const { showError } = useContext(DialogContext);
    const { t } = useContext(I18nContext);
    const [selectedCity, setSelectedCity] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const selectRef = useRef(null);
    const { userContent } = useContext(UserContext);

    const updateCities = useCallback(() => {
        setCities(
            toJS(AutoFillService?.cities)?.map((city) => ({
                ...city,
                value: city?.name,
                label: (
                    <div>
                        {city?.name},
                        <span style={{ color: "#707070", fontSize: "11px" }}>
                            {" " + city?.country}
                        </span>
                    </div>
                ),
            }))
        );
    }, []);

    useEffect(() => {
        if (AutoFillService.cities.length === 0) {
            AutoFillService.fetchCitiesWithAirport().then(updateCities).catch(error => showError("Error", error));
        }
    }, [updateCities, showError]);

    useEffect(() => {
        if (value && cities.length) {
            const city = cities.find(c => c.name === value.city && c.country === value.country);
            if (city) setSelectedCity({ ...city, city: city?.name });
        }
    }, [value, cities]);

    useEffect(() => {
        updateCities();
    }, [AutoFillService.cities, updateCities]);

    const fetchNewCity = useCallback(debounce(async () => {
        if (inputValue.trim()) {
            try {
                const response = await AutoFillService.searchNAddCity(inputValue, userContent?.country);
                if (response?.status == 400) {
                    setFetchError(true);
                } else {
                    setFetchError(false);
                    updateCities();
                }

            } catch (error) {
                console.log(error)
                setFetchError(true);
                showError("Error fetching new city", error);
                selectRef.current?.select?.focus();
            }
        }
    }, 500), [updateCities, inputValue, userContent?.country, showError]);

    const noOptionsMessage = useCallback(() => {
        if (fetchError) {
            return t("City not found");
        }
        fetchNewCity();
        return inputValue.trim() ? t("Loading city with name ") + inputValue : t("Start typing to search for a city...");
    }, [fetchNewCity, t, fetchError, inputValue]);

    const onInputChange = useCallback((inputValue, { action }) => {
        if (action === 'input-change') {
            setFetchError(false)
            setInputValue(inputValue);
        }
    }, []);

    return (
        <>
            {asLabel ? (
                <div>{selectedCity?.label ?? <div style={{ color: "grey" }}>{t("No record Found")}</div>}</div>
            ) : (
                <Select
                    ref={selectRef}
                    outline
                    // className="cityCountrySelect"
                    isDisabled={asLabel || cities.length === 0}
                    components={{ Input: components.Input, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    autoComplete="new-password"
                    options={cities}
                    // styles={{ style }}
                    value={selectedCity}
                    // noLabel={noLabel}
                    placeholder={placeholder ?? t("Select City")}
                    onChange={v => {
                        if (v?.name !== value?.city || v?.state !== value?.state || v?.country !== value?.country) {
                            onChange(v);
                        }
                    }}
                    menuPlacement="auto"
                    noOptionsMessage={noOptionsMessage}
                    onInputChange={onInputChange}
                />
            )}
        </>
    );
};

export default observer(CityCountrySelectAirport);