import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import MakeModel from "../../../pages/MakeModel/MakeModel";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { Image, ModalComponent } from "../../index";
import MakeModelService from "./MakeModelSelectService";

const MakeModelSelect = ({
  plain,
  totalRows,
  asLabel,
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  outline,
  inline,
  borderNone,
  multi,
  withImage,
  valueLabel = false,
  ...props
}) => {
  const [makeModels, setMakeModels] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const updateMakeModels = async () => {
    try {
      if (toJS(MakeModelService?.makeModel)?.length > 0) {
        const formattedMakeModels = toJS(MakeModelService?.makeModel)?.map((makeModel) => {
          return {
            ...makeModel,
            value: makeModel?._id,
            label: valueLabel ? makeModel?.make + " " + makeModel?.model : (
              <div className="d-flex row">
                {withImage &&
                  <div className="col-3">
                    <Image
                      src={makeModel?.pic}
                      alt="make-model"
                      style={{
                        display: "inline",
                        maxHeight: "15px",
                        maxWidth: "30px"
                      }}
                    />
                  </div>
                }
                <div className="col-9 ps-3">
                  {makeModel?.make}  {makeModel?.model}
                </div>
              </div>
            ),
          };
        })
        setMakeModels(formattedMakeModels);

        // Check if valueLabel matches an entry and select it
        if (valueLabel) {
          const selectedMakeModel = formattedMakeModels.find(makeModel =>
            makeModel.label.toLowerCase() === valueLabel.toLowerCase()
          );
          if (selectedMakeModel) {
            onChange(selectedMakeModel);
          }
        }
      }
    } catch (error) {
      console.log("valueLabel", valueLabel)
      console.log(error)
      showError("Error", error)
    }
  };

  useEffect(() => {
    const makeModelData = toJS(MakeModelService?.makeModel);
    if (makeModelData?.length > 0) {
      updateMakeModels();
    }
  }, [MakeModelService?.makeModel]);

  const fetch = async () => {
    await MakeModelService.fetch();
  }

  useEffect(() => {
    if (makeModels?.length === 0) fetch();
  }, [makeModels]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectMakeModel = (selectedValue) => {
    fetch()
    onChange({ ...selectedValue, value: selectedValue?._id });
    closeModal();
  };

  const selectMakeModels = (selectedValues) => {
    fetch()
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const filterOption = useCallback((option, inputValue) =>
    !inputValue ||
    ['make', 'model'].some(
      key =>
        option.data?.[key]?.toLowerCase().includes(inputValue.toLowerCase())
    ) || (option.data?.["make"]?.toLowerCase() + " " + option.data?.["model"]?.toLowerCase()).includes(inputValue.toLowerCase()),
    []);

  const renderSelectField = ({ disabled }) => (
    <SelectField
      filterOption={filterOption}
      plain={plain ? plain : null}
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      className={className}
      onChange={onChange}
      data={makeModels}
      required={required}
      value={value}
      label={label || (!isDisabled && t("Select Make Model"))}
      error={!value ? t(`Please Select ${label || "Make Model"}`) : ""}
      showErr={showErr}
      inline={inline}
      outline={outline}
      borderNone={borderNone}
      {...props}
    />
  );

  return (
    <div className="w-100">
      {asLabel ? (<div>{makeModels?.find((makeModel) => makeModel?.value == value)?.make ? <>
        <div className="d-flex align-items-center ">
          {makeModels?.find((makeModel) => makeModel?.value == value)?.make} {makeModels?.find((makeModel) => makeModel?.value == value)?.model}
        </div>
      </> : <div style={{ color: "grey" }}>--</div>}</div>) :
        <>
          {modal && (
            <ModalComponent
              position={"top"}
              size={"large"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("MakeModel")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <MakeModel
                  value={value}
                  insidePane={true}
                  onSelect={selectMakeModel}
                />
              </ModalComponent.Body>

            </ModalComponent>
          )}

          {makeModels?.length > 0 ? renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      }
    </div>
  );
};

export default observer(MakeModelSelect);
