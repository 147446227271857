import React, { useContext, useEffect, useRef } from "react";
import Popup from "./components/Popup";
import { ThreeBounce } from "better-react-spinkit";
import { UserContext } from "../../store/context/UserContext";
import { useSupportCall } from "../../helpers/customHooks/supportCall/useSupportCall";

export default function Home(props) {
  const { supportCallStatusForAdmin } = useContext(UserContext)
  const { supportCallAcceptByAdmin, supportCallRejectByAdmin, } = useSupportCall("admin")
  const timeOut = useRef(null);

  useEffect(() => {
    if (supportCallStatusForAdmin === 'calling') {
      clearTimeout(timeOut.current);
      timeOut.current = setTimeout(() => {
        supportCallRejectByAdmin({});
      }, 30000);
    }
  }, [supportCallStatusForAdmin]);

  const rejectHelpCall = async () => {
    clearTimeout(timeOut.current);
    props.checkForRejecction.current = true;
    supportCallRejectByAdmin({});
  };
  const acceptHelpCall = async () => {
    clearTimeout(timeOut.current);
    props.checkForRejecction.current = false;
    supportCallAcceptByAdmin({});
  };
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        minHeight: "99vh",
      }}
    >
      <ThreeBounce size={15} color="blue" />
      <div style={{ marginLeft: "20px" }}>
        {props.loading ? "Connectecting...." : "Waiting For Someone to Join..."}
      </div>
      <Popup
        show={supportCallStatusForAdmin === 'calling'}
        username={props.otherPeerUsername}
        backdrop="static"
        keyboard={false}
        onPopupConfirmation={acceptHelpCall}
        onPopupRejection={rejectHelpCall}
      />
    </div>
  );
}
