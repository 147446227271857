export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/rate-name/grid?`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    get: (id) => `/api/rate-name/${id}/detail`,
    save: `/api/rate-name/create`,
    delete: (id) => `/api/rate-name/${id}/delete`,
    bulkDelete: (id) => `/api/rate-name/delete`,
    update: `/api/rate-name/update`,
    getAllRateNames:(isSlabRate)=> `/api/rate-name/grid?isSlabRate=${isSlabRate}`,
};

export const STRUCTURE = [
    {
        label: "Name",
        filterLabel: "Name",
        filterName: "name",
        type: "text",
    },
    {
        label: "Rate Type",
        filterLabel: "Rate Type",
        filterName: "rateType",
        type: "text",
    },
];


export const billingDurationData = [
    { label: "MONTHLY", value: "MONTHLY" },
    { label: "WEEKLY", value: "WEEKLY" },
    { label: "BIWEEKLY", value: "BIWEEKLY" },
    { label: "ON_DEMAND", value: "ON_DEMAND" },
]
export const slabCondition = [
    { label: "KM Range Based", value: 1 },
    { label: "Time Range Based", value: 2 },
    { label: "Whichever is Higher", value: 3 },
]