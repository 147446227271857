

import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { toJS } from "mobx";
import { Col, Input, Row } from "reactstrap";
import { CheckButton, EditLink, DeleteLink, SaveButton, Layout, InputField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import TranslationsService from "./TranslationsService";
import { STRUCTURE } from "./TranslationsConstant";

const Translations = (props) => {
  const { t } = useContext(I18nContext);
  const { id } = useParams();
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [data, setData] = useState({});
  const [showDetailPage, setShowDetailPage] = useState(id);
  const [loading, setLoading] = useState(false);
  const [selectedTranslations, setSelectedTranslations] = useState([]);
  const [hasErr, setHasErr] = useState(false);
  const [showInput, setShowInput] = useState(null);
  const [transValue, setTransValue] = useState("");

  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await TranslationsService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete Translation ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        TranslationsService.delete(id);
      } catch (e) {
        showError(e);
      }
    }
  };

  const onApprove = async (event, trans_id, val) => {
    event.stopPropagation();
    if (
      val
        ? await showConfirm({
          title: "Unapprove Translation?",
          description: "Are you sure you want to Unapprove this Translation?",
        })
        : await showConfirm({
          title: "Approve Translation?",
          description: "Are you sure you want to approve this Translation?",
        })

    ) {
      try {
        const formedData = {
          status: !val,
          trans_id: trans_id
        }
        await TranslationsService.approve(formedData, val);
        await TranslationsService.get(trans_id);
        val ? showMessage("Unapproved successfully") : showMessage("Approved successfully");
        fetchData();
      } catch (e) {
        showError(e);
      }
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setHasErr(true);
    setLoading(true);
    try {
      data.val = transValue
      setData(data)
      await TranslationsService.save(data);
      await TranslationsService.get(data._id);
      showMessage("Translations Saved successfully");
      TranslationsService.records.push(data);
      fetchData();
      setShowInput(null);
    } catch (e) {
      showError(e);
    }
  };

  return (
    <>

      <Layout
        showDetailPage={showDetailPage}
         
        title={t("Translations")}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        page={TranslationsService.page}
        rows={TranslationsService.rows}
        total={TranslationsService.totalRecords}
        onPageChange={async (page, rows) => {
          await TranslationsService.onPaginationChange(page, rows);
        }}
      >
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th className="col-sm-1">
                <input
                  type="checkbox"
                  className="select-tariff"
                  checked={
                    JSON.stringify(
                      toJS(TranslationsService?.records).map((x) => x._id)
                    ) === JSON.stringify(selectedTranslations)
                  }
                  onChange={() =>
                    JSON.stringify(
                      toJS(TranslationsService?.records).map((x) => x._id)
                    ) !== JSON.stringify(selectedTranslations)
                      ? setSelectedTranslations(
                        toJS(TranslationsService?.records).map((x) => x._id)
                      )
                      : setSelectedTranslations([])
                  }
                />
              </Th>
              <Th>{t("Translate From")}</Th>
              <Th>{t("Language")}</Th>
              <Th>{t("Translate To ")}</Th>
              <Th>{t("Platfrom")}</Th>
              <Th>{t("Aprroved")}</Th>
              <Th className="float-end">{t("Action")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>
        <Layout.TbodyFull>
          <Tbody>
            {TranslationsService.records?.map((translations) => (
              <Tr
                key={translations?._id}
              >
                <Td scope="row">
                  <input
                    type="checkbox"
                    className="select-tariff"
                    checked={
                      selectedTranslations.findIndex((x) => x === translations._id) !== -1
                    }
                    onChange={() =>
                      selectedTranslations.findIndex((x) => x === translations._id) === -1
                        ? setSelectedTranslations([...selectedTranslations, translations._id])
                        : setSelectedTranslations(
                          selectedTranslations.filter((x) => x._id === translations._id)
                        )
                    }
                  />
                </Td>
                <Td>{translations?.key}<br /> </Td>
                <Td>{translations?.lang}<br /> </Td>
                <Td key={translations?._id} >
                  {showInput === translations._id ? <>
                    <InputField
                      value={transValue}
                      onChange={(v) => {
                        setTransValue(v);
                      }}
                      type="text"
                      autocomplete="off"
                      className="form-control"
                    /><SaveButton onClick={(e) => {
                      submitHandler(e)
                    }} /></> : translations?.val}
                </Td>
                <Td> {translations?.platform}</Td>
                <Td>
                  <Row>
                    <Col className="col-2">
                      <Input
                        type="switch"
                        checked={translations.approved ?
                          true : false}
                        onClick={(event) => {
                          onApprove(event, translations?._id, translations.approved)
                        }}
                      />
                    </Col>

                  </Row>
                </Td>
                <Td className="float-end" key={translations?._id}>
                  <EditLink
                    onClick={() => {
                      setShowInput(translations._id)
                      setTransValue(translations?.val)
                      setData(translations)
                        ;
                    }
                    }
                  />
                  <DeleteLink
                    onClick={(event) => {
                      onDelete(event, translations?._id);
                    }}
                  />
                  {props?.selectMode ? (
                    <CheckButton
                      onClick={() => {
                        props?.onSelect([{ ...translations }]);
                      }}
                    />
                  ) : null}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout>
    </>
  );
};
export default observer(Translations);

