import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { ENDPOINTS } from "./InvoiceConstant";
import { getUiPrefRows } from "../../util/FilterUtil";
import { processApiResponseForFile } from "../../util/Util";
import UIPrefService from "../../components/DataGrid/UIPrefService";
class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = null;
  filterOption = "";
  total = -1;

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterUrl,client_id,clientCompany_id,excludedIds,excludedPaymentStatuses,statuses) => {
    const uiPref = "Invoice.grid"
    let withColumns = [];

    const uiPrefresponse = UIPrefService?.uiPrefs[uiPref]
      ? { status: 200, data: { value: UIPrefService?.uiPrefs[uiPref] } }
      : await doGET('/api/ui-preference/' + uiPref + '/detail');

    if (!UIPrefService?.uiPrefs[uiPref])
      UIPrefService?.updatePrefs(uiPref, uiPrefresponse?.data?.value ?? '[]');

    if (uiPrefresponse?.status === 200 && uiPrefresponse?.data?.value) {
      const availableColumns = JSON.parse(uiPrefresponse?.data?.value);
      const columnMap = {
        // "Invoice Number": "invoiceNo",
        "Client":"clientName",
        "Client Company": "clientCompanyName",
        "Tags": "tagValues",
        "Serving Company":"companyName",
        // "Requested": ["vehicleTypeName", "makeModelName"],
        "Created By": "createdByName",
        // "Total Amount": "total"
      };

      Object.entries(columnMap).forEach(([key, value]) => {
        if (availableColumns?.includes(key)) {
          Array.isArray(value) ? withColumns.push(...value) : withColumns.push(value);
        }
      });
    }
    if (!this?.rowsPerPage) {
      const rows = await getUiPrefRows("Invoice.grid")
      this.rowsPerPage = rows ?? 40
    }
    if (filterUrl) {
      this.filterOption = filterUrl;
    }
    let excluded_ids = [];
    let includedStatuses = [];
    let excluded_Statuses = [];
    let QueryURL = "";
    if (this.rowsPerPage) QueryURL += `&rows=${this.rowsPerPage}`;
    if (filterUrl)  QueryURL += `&${filterUrl}`;
    if (excludedIds && excludedIds.length) {
      excluded_ids = excludedIds.join(",");
      QueryURL += `&_id=notin[${excluded_ids}]`;
    }
    if (excludedPaymentStatuses && excludedPaymentStatuses?.length) {
      excluded_Statuses = excludedPaymentStatuses.join(",");
      QueryURL += `&paymentStatus=notin[${excluded_Statuses}]`;
    }
    if (statuses && statuses?.length) {
      includedStatuses = statuses.join(",");
      QueryURL += `&status=in[${includedStatuses}]`;
    }
    if (client_id){
      QueryURL += `&client.org_id=${client_id}`
    } 
    if (clientCompany_id){
      QueryURL += `&client.company_id=${clientCompany_id}`
    } 
    if (this.page){
      QueryURL += `&page=${this.page}`
    } 
    if(withColumns?.length) {
      QueryURL += `&withColumns=${(withColumns?? []).join(",")}&`;
    }


    // const excludedIdsQuery = `_id=notin[${excluded_ids}]&paymentStatus=notin[${excluded_Statuses}]`
    const response = await doPOST(
      ENDPOINTS.grid(),
     { queryURL: QueryURL }
    );    

    if (response?.status === 200) {
      this.records = response.data.rows;
      this.page = response?.data?.page;
      if (this.page === 1) this.total = response?.data?.total;
    }
  };


  edit = async function (data) {
   return await doPUT(ENDPOINTS?.update, data);
  };

  getInvoiceNumber = async function (data) {
    const response = await doPOST(ENDPOINTS?.getInvoiceNumber, data);
    return response?.data;
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));
    return response?.data;
  };

  fetchTrips = async (trip_ids) => {
    // const response = await doGET(`/api/trip/grid?_id=in[${(trip_ids ?? [])?.join(',')}]`);
    const response = await doPOST(`/api/trip/grid`,{queryURL:`_id=in[${(trip_ids ?? [])?.join(',')}]&rows=-1`});
    return response?.data?.rows;
  };


  getinvoiceConfig = async (id) => {
    const response = await doGET(ENDPOINTS.getinvoiceConfig(id));
    return response?.data;
  };

  getclient = async (id) => {
    const response = await doGET(ENDPOINTS.getClient(id));
    console.log(response, 'bb');

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };


  save = async (data) => {
    const response = await doPOST(ENDPOINTS.save, data);
    return response?.data;
  };
  calculate = async (data) => {
    const response = await doPOST(ENDPOINTS.calculate, data);
    return response?.data;
  };
  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };
  bulkDelete = async (ids = []) => {
    const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
    if (response.status === 200) {
      this.fetch();
    }
  };
  taxChange = async (body) => {
    return await doPOST(ENDPOINTS.taxChange(), body);
  };
  print = async (invoice_ids, configuration) => {
    const obj = { invoice_ids: invoice_ids || [], configuration: configuration || {} };
    const response = await doPOST(ENDPOINTS.print, obj);
    if (response?.status === 200) {
      return processApiResponseForFile(response, "Invoice.zip");
    }
    return null;
  };

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);

  };

  downloadByTemplate = async (filterData, template_id) => {
    const response = await doGET(
      ENDPOINTS.download(this.page, this.rowsPerPage, this.downloadFilterOption, template_id)
    );

    if (response.status === 200) {
      return response;
    }
  };
  setDownloadFilter = (filterData) => {
    if (filterData) this.downloadFilterOption = filterData;
    else this.downloadFilterOption = "";
  };
  sendMessage = async function (payload) {
    return await doPOST(ENDPOINTS.sendMessage, payload);
  }
  validateInvoiceNo = async function (payload) {
    return await doPOST(ENDPOINTS.validateInvoiceNo, payload);
  }
}


const Invoiceservice = new Service();
export default Invoiceservice;
