import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditMessageAgent from "./EditMessageAgent";
import { STRUCTURE } from "./MessageAgentConstant";
import { GridColumns } from "./MessageAgentGrid";
import MessageAgentService from "./MessageAgentService";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";


const MessageAgent = ({ insidePane }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/agent/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)


  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await MessageAgentService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
  }, [])
  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])
  const onSave = async (e) => {
    e.preventDefault();


    setLoading(true);
    try {
      if (editID) {
        await MessageAgentService.edit(detailData, editID);
        showMessage(t("Record updated successfully."));
      } else {
        const ID = await MessageAgentService.save(detailData);
        if (ID) setEditID(ID)

        showMessage(t("Record saved successfully."));
        if (!insidePane) navigate(`/agent/edit/${ID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setHasErr(false)
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await MessageAgentService.delete(id);
        showMessage("Deleted", "Deleted");
        navigate('/agent')
        setShowDetailPage(false)
      } catch (e) {
        showError(e);
      }
    }
  };

  return (
    <>
      <Layout
        medium
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/agent");
          setEditID(null);
        }}
         
        title={t("Message Agent")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/agent/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={MessageAgentService.page}
        rows={MessageAgentService.rows}
        total={MessageAgentService.total}
        fetch={MessageAgentService.fetch}
      >
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={MessageAgentService.records}
              total={MessageAgentService.total}
              uiPreference="agent.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={setSelectedIDs}
              page={MessageAgentService.page}
              rowsPerPage={MessageAgentService.rowsPerPage}
              onPaginationChange={MessageAgentService.onPaginationChange}
              renderLastCol={(state) => {
                return (
                  <>
                    <EditLink
                      onClick={() => {
                        if (!insidePane)
                          navigate(`/agent/edit/${state?._id}`);
                        setEditID(state?._id);
                        setShowDetailPage(true);
                      }}
                    />
                    <DeleteLink
                      onClick={(event) => onDelete(event, state?._id)}
                    />
                  </>
                );
              }}
            />
          </>
        </Layout.Table>

        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit Message Agent") : t("Add Message Agent")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditMessageAgent
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
        {detailData?._id ? (
            <AuditLogDialog module="MessageAgent" id={detailData?._id} />
          ) : null

          }
        {detailData?._id ?
          <DeleteButton loading ={loading} onClick={(e) => onDelete(e, detailData?._id)} />: null}
          <SaveButton loading={loading} onClick={onSave} />
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(MessageAgent);
