import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, DropdownItem, DropdownMenu } from "reactstrap";
import { IoSend } from "react-icons/io5";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  DownloadByTemplate,
  EditLink,
  Layout,
  SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import MessageDialog from "./Component/MessageDialog";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import BulkPrintConfiguration from "./Component/BulkPrintConfiguration";
import { STRUCTURE } from "./InvoiceConstant";
import InvoiceForm from "./InvoiceForm";
import { GridColumns } from "./InvoiceGridColumns";
import Invoiceservice from "./InvoiceService";
import { MdPrint } from "react-icons/md";
import { UserContext } from "../../store/context/UserContext";
import moment from "moment";
import { toJS } from "mobx";

const Invoices = ({excludedIds=null, value,clientCompany_id,statuses=null, insidePane, multiMode, excludedPaymentStatuses=null,client_id=null, onSelect = () => { }, customerInvoice, clientInvoice }) => {
  let { edit_id } = useParams();
  const location = useLocation();


  const { userContent } = useContext(UserContext);
  let navigate = useNavigate();
  const queryParams = useMemo(()=>new URLSearchParams(location.search),[location.search]);
  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [updatedRecords,setUpdatedRecords]=useState([])
  const [loading, setLoading] = useState(false);
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(
    insidePane
      ? false
      : window.location.pathname === "/invoice/create" || edit_id
  );
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [messageModal, setMessageModal] = useState({
    visible: false,
  });
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false);
  // const [invoice, setInvoice] = useState({
  //   paymentTerms: "Due on Receipt",
  //   status: 1,
  //   paymentStatus: 0,
  //   invoiceExtn: {
  //     properties: [{}],
  //     items: [
  //       {
  //         fee: {
  //           feeGroups: [
  //             {
  //               name: "Group 1",
  //               charges: [{}],
  //               discounts: [{}],
  //               taxItem: {
  //                 taxes: [{}],
  //               },
  //             },
  //           ],
  //         },
  //         invoiceTrip: {},
  //       },
  //     ],
  //   },
  // });
  const [modal, setModal] = useState(false);
  const [fromDate, setFromDate] = useState(queryParams.get('fromDate') ? queryParams.get('fromDate') : null);
  const [tillDate, setTillDate] = useState(queryParams.get('tillDate') ? queryParams.get('tillDate') :null);
  const [showDownload, setShowDownload] = useState(false);
  const queryParamsRef = useRef({ prev: new URLSearchParams(), current: new URLSearchParams(location.search) ,initialLoad:true});//initalLoad to determine if it is first time or not (to stop extra api call with no queryParams)

  const isParamsUpdated = useCallback(() => {
    const pathname = location.pathname;
    if (pathname === `/invoice` || pathname === `/invoice/` || insidePane) {
      const currentQuerySearch = new URLSearchParams(location.search);
      if ((queryParamsRef.current?.prev?.toString() !== currentQuerySearch.toString()) || (!currentQuerySearch?.size && !queryParamsRef.current.prev?.size && queryParamsRef.current.initialLoad)) {
        queryParamsRef.current.prev = currentQuerySearch;
        queryParamsRef.current.current = currentQuerySearch;
        queryParamsRef.current.initialLoad=false;
        return true;
      }
    }
    return false;
  }, [location.pathname, location.search]);

  const preFetchGrid = async () => {
    const { filterUrl, filterObject } = prepareFilterArtifacts();
    setFilterObject(filterObject);
    fetchData({filterUrl,client_id,clientCompany_id,excludedIds,excludedPaymentStatuses,statuses});
    
  }


  const fetchData = useCallback(
    async ({filterUrl,client_id=null,clientCompany_id=null,excludedIds=null,excludedPaymentStatuses=null,statuses=null}) => {
      setLoading(true);
      try {
        const query=new URLSearchParams(filterUrl);
        const queryParams = new URLSearchParams(location.search);
        if(!query.has('invoiceDate')){  
            const fromDateQuery=queryParams.get('fromDate');
            const tillDateQuery=queryParams.get('tillDate');

            if(fromDateQuery && tillDateQuery ){
              if(fromDate && tillDate && (fromDateQuery!==fromDate || tillDateQuery!==tillDate)){
                navigate({
                  pathname: location.pathname,
                  search: `?${filterUrl}&fromDate=${fromDate}&tillDate=${tillDate}`,
                  replace:true
                });
                return;
              }
              filterUrl += `&invoiceDate=btw[${fromDateQuery + `,` + tillDateQuery}]`;
              setFromDate(fromDateQuery);
              setTillDate(tillDateQuery); 
            }else{
              if(fromDate && tillDate && fromDate!==fromDateQuery || tillDate!==tillDateQuery){
                navigate({
                  pathname: location.pathname,
                  search: `?${filterUrl}&fromDate=${fromDate}&tillDate=${tillDate}`,
                  replace:true
                });
                return; 
              }
            } 
        }
        await Invoiceservice.fetch(filterUrl,client_id,clientCompany_id,excludedIds,excludedPaymentStatuses,statuses);
        setFilterURL(filterUrl)
        // if (editID) {
        //   const inv = await Invoiceservice.get(editID);
        //   if (inv) {
        //     // setInvoice(inv);
        //   }
        // } else {
        //   // setInvoice({});
        // }
      } catch (e) {
        showError(e);
      } finally {
        setLoading(false);
      }
    },
    [editID, showError,fromDate,tillDate,location.search]
  );

  useEffect(() => {
    if(isParamsUpdated()){
      let { filterUrl, filterObject } = prepareFilterArtifacts(
        queryParams,
        STRUCTURE
      );
      preFetchGrid()
      if (queryParams.has("fromDate") && queryParams.has("tillDate")) {
        filterUrl += `&invoiceDate=btw[${queryParams.get("fromDate") + `,` + queryParams.get("tillDate")}]`;
       setFromDate(queryParams.get("fromDate")); 
       setTillDate(queryParams.get("tillDate"));
      }else{
        setFromDate(queryParams.get(null)); 
        setTillDate(queryParams.get(null));
      }
  
      if (clientInvoice) {
        filterObject = {
          ...filterObject,
          client: {
            corp_id: clientInvoice
          }
        }
        filterUrl += `client.org_id=${clientInvoice}`;
      }
  
      setFilterURL(filterUrl);
      setFilterObject(filterObject);
      fetchData({filterUrl,client_id,clientCompany_id,excludedIds,excludedPaymentStatuses,statuses});
      multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : []);
    }
  }, [queryParams,isParamsUpdated]);

  useEffect(() => {
    setEditID(edit_id);
  }, [edit_id]);



  const toggleMessageModal = (e, invoice_id) => {
    setMessageModal({
      ...messageModal,
      visible: !messageModal?.visible,
      _id: invoice_id,
    });
    e?.stopPropagation();
  };

  const validateFeegroup = (items) => {
    let hasError = false;
    items?.forEach(item => {
      if (item.manual) {
        item?.fee?.feeGroups?.forEach(feeGroup => {
          if (!feeGroup?.charges || feeGroup?.charges?.length === 0) {
            setHasErr(true)
            showError(t("Please add charge"));
            hasError = true;
            return;
          }

          feeGroup?.charges?.forEach(charge => {
            if (!charge?.name || charge?.name?.trim() === '') {
              setHasErr(true)
              hasError = true;
              showError(t("Please fill charge type"));
              return;
            }
            if (!charge?.unitCost || isNaN(charge?.unitCost)) {
              setHasErr(true)
              showError(t("Please fill charge unit cost"));
              hasError = true;
              return;
            }
            if (/* !charge?.amount ||  */isNaN(charge?.amount)) {
              setHasErr(true)
              showError(t("Please fill amount"));
              hasError = true;
              return;
            }
            if (/* !charge?.units ||  */isNaN(charge?.units)) {
              setHasErr(true)
              showError(t("Please fill units"));
              hasError = true;
              return;
            }
          });
        });
      }
    });
    return hasError;
  }

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await Invoiceservice.delete(id);
        showMessage("Deleted", "Deleted");
        navigate(`/invoice`);
        setShowDetailPage(false);
      } catch (e) {
        showError(e);
      }
    }
  };

  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await Invoiceservice.bulkDelete(selectedIDs);
        showMessage("Deleted", "Deleted Successfully");
      } catch (e) {
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback(
    (invoice) => {
      return (
        <>
          {userContent?.rights?.includes(4502) && <EditLink
            onClick={() => {
              // if (!insidePane) navigate(`/invoice/edit/${invoice?._id}`);
              if (!insidePane)
                navigate(`/invoice/edit/${invoice?._id}?return=${encodeURIComponent(`/invoice?${queryParamsRef.current.current?.toString()}`)}`);
              setEditID(invoice?._id);
              setShowDetailPage(true);
            }}
          />
          }

          {(!multiMode && insidePane) ? (
            <CheckButton
              onClick={() => {
                onSelect(invoice);
              }}
            />
          ) : null}
        </>
      );
    },
    [insidePane, multiMode, userContent]
  );

  const markCompletedFunc = async () => {
    try {
      await SetupProgressService?.edit("invoice");
    } catch (error) { }
  };


  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch();
    }
  }, [SetupProgressService?.records]);


  const toggleDownload = () => {
    setShowDownload(!showDownload);
    Invoiceservice.setDownloadFilter(filterURL)
  };

  useEffect(() => {
    setUpdatedRecords(()=>{
      return toJS(Invoiceservice?.records)?.filter(record => !excludedIds?.includes(record._id))
    })
  }, [excludedIds, Invoiceservice?.records])
  

  return (
    <>
      {showDownload ? <DownloadByTemplate
        filter={filterObject}
        download={Invoiceservice.downloadByTemplate}
        kind={"Invoice"}
        onClose={setShowDownload}
        show={showDownload}
        filterStructure={STRUCTURE}
        onApplyFilter={Invoiceservice.setDownloadFilter}
      /> : null}
      <Layout
        hideAdd={!userContent?.rights?.includes(4501)}
        large
        showDateRange
        fromDate={fromDate}
        tillDate={tillDate}
        setFromDate={setFromDate}
        setTillDate={setTillDate}
        showDetailPage={showDetailPage}
        defaultValue={[fromDate, tillDate].filter(date => date).map(date => moment(parseInt(date), 'YYYYMMDD'))}
        backDetailPage={async () => {
          setShowDetailPage(false);
          const returnUrl = window.location.search ? new URLSearchParams(window.location.search).get("return") : "";
          if (!insidePane) navigate( (returnUrl ? returnUrl : `/invoice`));
          // if (!insidePane) navigate("/invoice");
          setEditID(null);
        }}
        title={t("Invoices")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={(url)=>fetchData({filterUrl:url,client_id,clientCompany_id,excludedIds,excludedPaymentStatuses,statuses})}
        onAddClick={() => {
          // if (!insidePane) navigate(`/invoice/create`);
          const encodedUrl = decodeURI(`/invoice/create?return=${encodeURIComponent(`${window.location?.pathname}${window.location.search}`)}`);
          if (!insidePane) navigate(encodedUrl);
          setShowDetailPage(true);
          setEditID(null);
          // fetchInvoiceNumber();
        }}
        insidePane={insidePane}
        page={Invoiceservice.page}
        rows={Invoiceservice.rows}
        total={Invoiceservice.total}
        fetch={Invoiceservice.fetch}
        hideheader={true}
      >
        <Layout.OnAlert>
          {!SetupProgressService?.records?.setUpStatus?.["invoice"] ? (
            <Alert color="danger" role="alert">
              <div className="d-flex justify-content-between align-items-center">
                <div>Set up your invoice configuration here.</div>
                <div>
                  <Button onClick={markCompletedFunc}>Mark Complete</Button>
                </div>
              </div>{" "}
            </Alert>
          ) : (
            <></>
          )}
        </Layout.OnAlert>
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem
                  onClick={() => {
                    onBulkDelete();
                  }}
                >
                  {t("Bulk Delete")}
                </DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem
                  onClick={() => {
                    setModal(!modal);
                  }}
                >
                  {t("Print in Bulk")}
                </DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem onClick={toggleDownload}>{t("Download")}</DropdownItem>
                <div class="dropdown-divider"></div>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>

        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={updatedRecords}
            total={Invoiceservice.total}
            uiPreference="Invoice.grid"
            headers={GridColumns({insidePane})}
            selectedIDs={selectedIDs}
            onSelectChange={(v) => {
              // onSelect(v);
              setSelectedIDs(v);
            }}
            onSaveUiPref={()=>{
              preFetchGrid()
            }}
            page={Invoiceservice.page}
            rowsPerPage={Invoiceservice.rowsPerPage}
            onPaginationChange={Invoiceservice.onPaginationChange}
            renderLastCol={renderLastCol}
            showActionMenu={() => { return true }}
            renderActionMenu={(invoice) => {
              return (
                <>
                  <div className="d-flex flex-column">
                    {invoice?._id ?
                      <Button
                        onClick={(e) => { toggleMessageModal(e, invoice?._id) }}
                        className="d-flex w-100"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", backgroundColor: "white", color: "black", border: "none" }}
                        color="black"
                      >
                        <div className="mx-2">
                          <IoSend fontSize={14} color="black" />
                        </div>
                        {t(`Send Invoice`)}
                      </Button> : null}
                    <Button
                      onClick={(event) => onDelete(event, invoice._id)}
                      className="d-flex w-100"
                      style={{ height: "28px", padding: "2px 10px 2px 4px", backgroundColor: "white", color: "black", border: "none", }}
                      color="black" >
                      <div className="mx-2">
                        <RiDeleteBin5Line fontSize={14} />
                        {/* <DeleteLink style={{ color: "black" }}/> */}
                      </div>
                      {t(`Delete Invoice`)}
                    </Button>

                    {userContent?.rights?.includes(4503) && <Button
                      onClick={() => {
                        setSelectedIDs([invoice?._id])
                        setModal(!modal)
                      }}
                      className="d-flex w-100"
                      style={{ height: "28px", padding: "2px 10px 2px 4px", backgroundColor: "white", color: "black", border: "none", }}
                      color="black" >
                      <div className="mx-2">
                        <MdPrint fontSize={14} />
                      </div>
                      {t(`Print Invoice`)}
                    </Button>}

                    {/* } */}
                  </div >
                </>
              )
            }}
          />
        </Layout.Table>


        <Layout.DetailPageBody>
          <InvoiceForm
            loading={loading}
            insidePane={insidePane}
            editId={editID}
            validateFeegroup={validateFeegroup}
            onSave={() => {
              fetchData({filterURL});
            }}
            onDelete={() => {
              showMessage("Deleted", "Deleted");
              navigate(`/invoice`);
              setShowDetailPage(false);
            }}
            callingType="invoice"
            disableInnerSave={false}
          />
          {/* <EditInvoices
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          /> */}
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          <div className="d-flex justify-content-end align-items-center w-100">
            <div>
              {editID ? (<DeleteButton onClick={(e) => onDelete(e, editID)} />
              ) : null}
            </div>
            <div className="d-flex align-items-center">
              {editID ?
                <div style={{
                  marginRight: "15px"
                }}>
                  <MdPrint
                    fontSize={30}
                    className="fa fa-print"
                    onClick={(e) => {
                      setSelectedIDs([editID])
                      setModal(!modal)
                    }}
                    style={{ color: "grey", cursor: "pointer", paddingLeft: "3px" }}
                  />
                </div> : null}
              {editID ? (
                <AuditLogDialog module="Invoice" id={editID} />
              ) : null}
              {/* {userContent?.rights?.includes(4502) && <SaveButton onClick={onSave} />} */}
            </div>

          </div>

        </Layout.DetailPageFooter>
      </Layout >
      {
        messageModal?._id ?
          <MessageDialog invoice_id={messageModal?._id} modal={messageModal?.visible} toggleModal={(e) => { toggleMessageModal(e, editID) }} />
          : null}
      <BulkPrintConfiguration
        modal={modal}
        setModal={setModal}
        invoice_ids={selectedIDs}
        loading={loading}
        onSuccess={() => setSelectedIDs([])}
        setLoading={setLoading}
      />
    </>
  );
};
export default observer(Invoices);
