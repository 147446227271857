
import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

const options = {
    plugins: {
        legend: {
            position: 'right',
            rtl: true,
            labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                padding: 20,
            }
        }
    },
}

const DoughnutChart = ({ data, disableLegend = false }) => {
    const [empty, setIsEmpty] = useState(false);

    useEffect(() => {
        //show empty data
        if (data?.data) {
            setIsEmpty(!data?.data?.some(val => val))
        }
    }, [data?.data])
    return (
        <Doughnut data={data}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'left',
                        display: !disableLegend,
                        ltr: true,
                        labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            padding: 12,
                            font: {

                                weight: '400',
                                size: 15,
                                color: '#000'
                            }
                        }
                    },
                }
            }} />
    )
}

export default DoughnutChart

