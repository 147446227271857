import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import Customer from "../../../pages/Users/User";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, Modal, ModalComponent, SearchButton } from "../../index";
import CustomerSelectService from "./CustomerSelectService";
import useDebounce from "../../../util/Debounce";
import { FaPhone } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";

const CustomerSelect = ({
  style,
  isDisabled,
  className,
  showClear,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  guide,
  driver,
  guard,
  sales,
  outline,
  asLabel,
  space_id,
  ...props
}) => {

  const [customers, setCustomers] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const updateCustomers = () => {
    setCustomers(
      toJS(CustomerSelectService.customers)?.map((customer) => {
        {
          return {
            ...customer,
            value: customer._id,
            label: <div className="d-flex  align-items-center " >
              <div  style={{minWidth : "140px"}} className="d-flex align-items-center font-size-12">{customer.name}</div>
              <div  style={{minWidth : "140px"}} className="d-flex align-items-center font-size-11"><IoIosCall className="me-2"/> +{customer?.phoneNo?.countryCode}-{customer?.phoneNo?.phone}</div>
              <div style={{ minWidth: "120px" }} className="d-flex align-items-center font-size-12"> <MdEmail className="me-2" /> {customer?.email} </div>
            
            </div>

          };
        }
        return null;
      }).filter(Boolean)
    );
  };

  const fetchCustomers = async () => {
    try {
      const filterUrl = `${space_id ? `space_id=${space_id}` : ""}`;
      await CustomerSelectService.fetch({ filterUrl });
      updateCustomers()
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (CustomerSelectService.customers.length === 0) fetchCustomers();
  }, []);

  useEffect(() => {
     fetchCustomers();
  }, [space_id]);
  

  useEffect(() => {
    updateCustomers()
  }, [CustomerSelectService.customers]);

  const fetchAndUpdateCustomers = async (id) => {
    try {
      const customer = customers.find(customer => customer._id === id)
      if (!customer) {
        const customer = await CustomerSelectService.get(id);
        CustomerSelectService.pushNewData(customer);
        updateCustomers();
      }

    } catch (error) {

    }
  }

  useEffect(() => {
    if (value && customers.length) {
      fetchAndUpdateCustomers(value)
    }
  }, [customers, value]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectCustomer = (selectedValue) => {
    let selectedCustomer = toJS(selectedValue)
    const customer = customers.find(customer => customer._id === selectedCustomer?._id)
    if (!customer) {
      CustomerSelectService.pushNewData(selectedCustomer);
      updateCustomers();
    }
    onChange({ ...selectedCustomer, value: selectedCustomer?._id });
    closeModal();
  };

  const selectCustomers = (selectedValues) => {
    //TODO
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const fetchMoreData = () => {
    console.log("reached end")
    const page = CustomerSelectService.page + 1;
    const rowsPerPage = CustomerSelectService.rowsPerPage;
    const total = CustomerSelectService.total;
    console.log({ page, total, rowsPerPage })
    if (page > 0 && page <= (total % rowsPerPage === 0 ? (total / rowsPerPage) : (total / rowsPerPage) + 1)) {
      CustomerSelectService.onPaginationChange(page, rowsPerPage, space_id);
    }
  }

  const fetchDataOnSearch = useDebounce((value) => {
    const filterUrl = `query=${value}&${space_id ? `space_id=${space_id}` : ""}`;
    CustomerSelectService.fetch({filterUrl});
  }, [500]
  );

  const filterOption = (option, inputValue) => {
    return !inputValue ||
      option?.data?.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
      (`${option?.data?.phoneNo?.phone}`)?.toLowerCase().includes(inputValue.toLowerCase()) ||
      (`${option?.data?.phoneNo?.countryCode}`)?.toLowerCase().includes(inputValue.toLowerCase()) ||
      option?.data?.email?.toLowerCase().includes(inputValue.toLowerCase()) 
  };



  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Customers")} />
      )}
      onMenuScrollToBottom={fetchMoreData}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={customers}
      showClear={showClear}
      required={required}
      value={value}
      label={label || (!isDisabled && "")}
      error={!value ? t(`Please Select ${label || "Customer"}`) : ""}
      showErr={showErr}
      inline={inline}
      minMenuWidth  = {500}
      outline={outline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
      onInputChange={fetchDataOnSearch}
      filterOption={filterOption}
      // getOptionLabel={option => `${option.name} ( +${option?.phoneNo?.countryCode} ${option?.phoneNo?.phone})`}
      {...props}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Customers")} />
      )}
      // showBottomBar={true}
      className={className}
      onMenuScrollToBottom={fetchMoreData}
      outline={outline}
      onChange={selectCustomers}
      data={customers}
      value={value ? customers.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Customers"}`) : ""}
      required={required}
      label={label || (!isDisabled && t("Select Customers"))}
      showErr={showErr}
      onInputChange={fetchDataOnSearch}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
      {asLabel
        ? multi ?
          value?.length > 0 ?
            <div>
              {value?.map((selectedValue, index) => {
                const customer = customers.find((customer) => customer.value == selectedValue);
                return (
                  <span key={selectedValue}>
                    {customer ? customer.label : 'Unknown Customer'}
                    {index < value.length - 1 && ", "}
                  </span>
                );
              })}
            </div>
            : <div style={{ color: "grey" }}>--</div>
          : (<div>{customers?.find((customer) => customer?.value == value)?.label ?? <div style={{ color: "grey" }}>--</div>}</div>) :
        <>

          {modal && (
            <ModalComponent
              position={"top"}
              size={"large"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Customer")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <Customer
                  value={value}
                  insidePane={true}
                  multiMode={multi}
                  onSelect={multi ? selectCustomers : selectCustomer}
                />
              </ModalComponent.Body>

            </ModalComponent>
          )}


          {customers.length > 0
            ? multi
              ? renderSelectFieldMultiple()
              : renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      }

    </>

  );
};

export default observer(CustomerSelect);
