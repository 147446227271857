import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Badge, Button, Card } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import {
    Layout,
    Modal,
    CitySelect,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { MARKETPLACE_BASE_URL, STRUCTURE } from "./MarketPlaceConstant";
import MarketPlaceService from "./MarketPlaceService";

import StepNavigation from "./StepNavigation";
import ClientStep1 from "./ClientSection/ClientStep1";
import Step1 from "./RFPSection/Step1";
import Step2 from "./RFPSection/Step2";
import Step3 from "./RFPSection/Step3";
import Step4 from "./RFPSection/Step4";
import RfpTabs from "./RfpTabs";
import { RFPImage } from "../../assets/images";
import ClientStep2 from "./ClientSection/ClientStep2";
import ClientStep3 from "./ClientSection/ClientStep3";
import TransporterStep1 from "./TransporterSection/TransporterStep1";
import TransporterStep2 from "./TransporterSection/TransporterStep2";
import TransporterStep3 from "./TransporterSection/TransporterStep3";
import TransporterStep4 from "./TransporterSection/TransporterStep4";
import TransporterStep5 from "./TransporterSection/TrasnporterStep5";
import axios from "axios";
import moment from "moment";
import { UserContext } from "../../store/context/UserContext";
import { MyVerticallyCenteredModal } from "./MyVerticallyCenteredModal";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css"
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

const MyRfpDashboard = (props) => {
    const { id } = useParams();
    const ReactTableFixedColumns = withFixedColumns(ReactTable);
    const { showError } = useContext(DialogContext);
    const { t } = useContext(I18nContext);
    const [data, setData] = useState([{}]);
    const [loading, setLoading] = useState(false);
    const [selectedtab, setselectedTab] = useState("RFP")
    const [showform, setshowform] = useState(false)
    const [modalShow, setModalShow] = React.useState(false);
    const labelArray = ['Step 1', 'Step 2', 'Step 3', 'Label 4']
    const [currentStep, updateCurrentStep] = useState(1);
    const [checkSteps, setCheckSteps] = useState([1])
    const { userContent } = useContext(UserContext);
    const [showSubmit, setShowSubmit] = useState(false)
    const [companyId, setCompanyId] = useState('648070109880f1854a5c2881')

    const getCompanyDetails = async (filterUrl) => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await axios.get(`${MARKETPLACE_BASE_URL}/get/company/${companyId}`)
            // setTransporterDetail(response?.data?.data)

        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };


    const fetchData = async (filterUrl) => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await axios.get(`${MARKETPLACE_BASE_URL}/get/rfp/all`)
            setData(response?.data?.data)

        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };
    useEffect(() => {
        setData([{
            id: "#1234",
            usertype: "Regular",
            totalpax: 12,
            pickanddrop: "Pick And Drop",
            vehicletype: "Hatchback",
            pickuptime: "8:00 AM",
            outstation: "Intracity",
            expirydate: "12-1-2024",
            supplier: "Kartik Transport"
        }])
    }, []);
    useEffect(() => {
        getCompanyDetails()
        // fetchData()


    }, []);

    const rfpSteps = {
        1: <Step1 data={data} setData={setData} />,
        2: <Step2 data={data} setData={setData} />,
        3: <Step3 data={data} setData={setData} />,
        4: <Step4 data={data} setData={setData} />,

    }
    function updateStep(step) {
        updateCurrentStep(step);
        setCheckSteps(prev => [...prev, step])
    }


    return (
        <>
            {showform ? (
                <Modal
                    onChange={() => {

                        updateCurrentStep(1)
                        setCheckSteps([1])
                        setshowform(false)
                    }}
                    modalSwitch={true}
                >
                    <div className=" d-flex justify-content-center">
                        <Card className=" client-card h-100 w-50">
                            <h4 className='d-flex justify-content-center fa-2x fw-bold mt-5 mb-4 '>Please fill your RFP details</h4>
                            <div className="close-danger-btn">
                                <Button
                                    style={{ margin: "30px" }}
                                    color="danger"
                                    className="waves-effect waves-light __modal-close-btn"
                                    onClick={() => {
                                        updateCurrentStep(1)
                                        setCheckSteps([1])
                                        setshowform(false)
                                    }}
                                >
                                    <i style={{ fontSize: "38px" }} className="bx bx-x" />
                                </Button>
                            </div>
                            <div className=' d-flex justify-content-center'>
                                <StepNavigation checkSteps={checkSteps} labelArray={labelArray} currentStep={currentStep} updateStep={updateStep}></StepNavigation>
                            </div>
                            <div className='mt-4 mb-5'>
                                {rfpSteps[currentStep]}
                            </div>

                            <div className=' m-auto mb-5  d-flex justify-content-center'>

                                {currentStep === labelArray.length ?
                                    <Button className="submit-btn" onClick={() => setModalShow(true)}>
                                        <span> Submit</span>
                                    </Button> :
                                    <button
                                        disabled={currentStep === labelArray.length} onClick={() => updateStep(currentStep + 1)}
                                        className='next-arrow-btn'>
                                        <i style={{ fontSize: "38px", color: 'white' }} className="dripicons-arrow-thin-right" />
                                    </button>}
                            </div>
                        </Card >
                    </div>
                </Modal>

            ) : (
                <> </>
            )}

            <div className=" d-flex flex-column ">

                <div style={{ marginTop: "100px" }} className="d-flex justify-content-between">
                    <div className=" d-flex justify-content-lg-start px-4 ">
                        <div className=" marketplace-dashboard-header">
                            <RfpTabs selectedtab={selectedtab} setselectedTab={setselectedTab} />
                        </div>

                    </div>
                    <div className='px-4 d-flex justify-content-end py-3'>
                        <Button onClick={() => setshowform(true)} style={{
                            backgroundColor: "#DF6463", border: "none", borderRadius: "8px",
                            width: "120px",
                            height: "52px"
                        }}>
                            <div className=" d-flex justify-content-center mt-1">
                                <span>  <i style={{ fontSize: '25px' }} className="bx bx-plus" /> </span> <span className=" fw-bolder mb-2 py-1">Add RFP</span>
                            </div>

                        </Button>
                    </div>
                </div>

                <div>
                    <div style={{ marginTop: "60px" }}>
                        <Card className=' mt-5  br-20 m-auto px-2' >
                            <ReactTableFixedColumns
                                data={data}
                                columns={[
                                    {

                                        fixed: "left",
                                        columns: [
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                width: 120
                                            },
                                            {
                                                Header: "UserType",
                                                accessor: "usertype",
                                                width: 170
                                            },
                                            {
                                                Header: "No of Vehicles/Total pax",
                                                accessor: "totalpax",
                                                width: 170
                                            },

                                        ]
                                    },

                                    {
                                        columns: [
                                            {
                                                Header: "Pick And Drop",
                                                accessor: "pickanddrop",
                                                width: 150
                                            },
                                            {
                                                Header: "Vehicle Type",
                                                accessor: "vehicletype",
                                                width: 150
                                            },
                                            {
                                                Header: "PickUp Time",
                                                accessor: "pickuptime",
                                                width: 150
                                            },
                                            {
                                                Header: "Intracity/Outstation",
                                                accessor: "outstation",
                                                width: 150
                                            },
                                            {
                                                Header: "Expiry",
                                                accessor: "expirydate",
                                                width: 150
                                            },
                                            {
                                                Header: "Supplier",
                                                accessor: "supplierp",
                                                width: 150
                                            },


                                        ]
                                    },

                                ]}
                                defaultPageSize={50}
                                style={{ height: 500 }}
                                className="-striped"
                            />



                        </Card>

                    </div>

                </div>
            </div>

            <MyVerticallyCenteredModal
                show={showSubmit}
                onHide={() => setShowSubmit(false)}
            />



        </>
    );
};

export default observer(MyRfpDashboard);
