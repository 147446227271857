import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Row } from "reactstrap";
import { AddressField, Checkbox, CountrySelect, CurrencySelect, DateSelect, InputField, SelectField, CitySelect, TimeSelect, VehicleTypeSelect } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "../RFP/RFPConstant";
import Bidservice from "./BidService";
import SupplierCompany from "../../components/Dropdowns/SupplierCompany/SupplierCompany";


const EditBids = ({ editId, onChange = () => { }, hasErr }) => {
  const [center, setCenter] = useState({})
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    requirements: [
      { vehicleType: '', count: 0, duration: '', details: '' },

    ],
    preferredSuppliers: [{
      org_id: '', company_id: ''
    }]

  });
  const fetch = async () => {
    try {
      let bid = await Bidservice.get(editId);
      setData(bid);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData({

      requirements: [
        { vehicleType: '', count: 0, duration: '', details: '' }
      ],
      preferredSuppliers: [{
        org_id: '', company_id: ''
      }]

    }); setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, Bidservice?.version]);



  useEffect(() => {
    onChange(data)
  }, [data]);


  const handleAddSupplier = () => {
    setData(prevData => ({
      ...prevData,
      preferredSuppliers: [
        ...prevData.preferredSuppliers,
        { org_id: '', company_id: '' }
      ]
    }));
  };
  const handleSupplierChange = (index, { org_id, company_id }) => {
    setData(prevData => {
      const updatedSuppliers = [...prevData.preferredSuppliers];
      updatedSuppliers[index] = { org_id, company_id };
      return { ...prevData, preferredSuppliers: updatedSuppliers };
    });
  };


  const handleDeleteSupplier = (index) => {
    setData(prevData => {
      const updatedSuppliers = [...prevData.preferredSuppliers];
      updatedSuppliers.splice(index, 1);
      return { ...prevData, preferredSuppliers: updatedSuppliers };
    });
  };



  const handleAddRequirement = () => {
    const updatedOpenBookingTimes = [...data.requirements,
    {
      vehicleType: "",
      count: 0,
      duration: "",
      details: ""
    }];
    setData({ ...data, requirements: updatedOpenBookingTimes });
  };

  const handleDeleteRequirement = (index) => {
    const updatedOpenBookingTimes = [...data.requirements];
    updatedOpenBookingTimes.splice(index, 1);
    setData({ ...data, requirements: updatedOpenBookingTimes });
  };


  const handleRequirementChange = (index, field, value) => {
    const updatedOpenBookingTimes = [...data.requirements];
    if (!updatedOpenBookingTimes[index]) {
      updatedOpenBookingTimes[index] = {};
    }
    updatedOpenBookingTimes[index][field] = value;
    setData({ ...data, requirements: updatedOpenBookingTimes });
  };


  return (
    <React.Fragment>
      {loading ?

        <ThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="mt-3 mb-3">

            <CountrySelect
              value={data?.country}
              className="col-sm-6"
              error={data?.country?.length === 0 ? t("Please enter City country") : ""}
              showErr={hasErr}
              onChange={(v) =>
                setData({
                  ...data,
                  country: v?.value,
                })
              }
              required={true}
            />
            <CitySelect
              className="col-sm-6"
              multi={true}
              country={data?.country}
              state={data?.state}
              value={data?.pickUpCites}
              onChange={(v) =>
                setData({
                  ...data,
                  pickUpCites: v?.value,
                })
              }

              type="text"
            />
          </Row>
          <Row className="mt-3 mb-3">

            <CurrencySelect
              required={true}
              placeholder={t("Select Currency")}
              value={data?.currency}
              label={t("Currency")}
              onChange={(v) => setData({ ...data, currency: v.value })}
              className="col-md-6 "
            />
            <Checkbox
              label={t("Is Public")}
              checked={data?.isPublic}
              className="col-sm-2"
              onClick={() => {
                setData({
                  ...data,
                  isPublic: !data?.isPublic,
                });
              }}
            />
          </Row>


          <Row className=" mb-3">
            <DateSelect
              required
              placeholder={t("Start Date ")}
              value={data?.date}
              label={t("Start Date")}
              onChange={(v) => {
                setData({ ...data, startDate: v })

              }}
              type="text"
              className="col-sm-6"
            />
            <DateSelect
              placeholder={t("End Date ")}
              value={data?.endDate}
              label={t("End Date")}
              onChange={(v) => {
                setData({ ...data, endDate: v })
              }}
              type="text"
              className="col-sm-6"
            />
          </Row>
          <Row className=" mb-3">


            <TimeSelect
              className="col-sm-6"
              label={t("Pickup Time")}
              value={data?.pickUpTime}
              onChange={(v) =>
                setData({
                  ...data,
                  pickUpTime: v,
                })
              }
            />
            <SelectField
              data={SELECTSTRUCTURE.travelType}
              value={data?.travelType}
              onChange={(v) => {
                setData({ ...data, travelType: v?.label });
              }}
              showErr={hasErr}
              label={t("Travel Type")}
              required={true}
              className="col-sm-6 col-12"
            />
          </Row>

          <Row className=" mb-3">

            <SelectField
              data={SELECTSTRUCTURE.status}
              value={data?.status}
              onChange={(v) => {
                setData({ ...data, status: v?.value });
              }}
              showErr={hasErr}
              label={t("Status")}
              required={true}
              className="col-sm-6 col-12"
            />

          </Row>
          {/* <Row>
            <Row className="mt-4">
              <Button color="light" onClick={handleAddSupplier} className="d-flex h-36 me-3 align-items-center mb-2"
                style={{ maxWidth: "150px" }}
              >
                <i className="bx bx-plus text-dark font-size-18 mx-1" />

                {t("Suppliers")}
              </Button>
            </Row>
            <div>
              {data?.preferredSuppliers?.map((supplier, index) => (
                <div key={index} className="mb-2" >
                  <div className="d-flex justify-content-start align-items-center">
                    <SupplierCompany
                    // className="col-sm-8"
                      key={index}
                      value={{ org_id: supplier._id, company_id: supplier.corp_id }}
                      onChange={(v) => handleSupplierChange(index, { org_id: v?._id, company_id: v?.corp_id })}
                    />

                    <div onClick={() => handleDeleteSupplier(index)} className="ms-2">

                      <i className="bx color-blue font-size-20 bxs-x-circle d-flex justify-content-end p-0" style={{ right: 0, fontSize: "10px", marginTop: "2px" }} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Row> */}
          <Row>
            <Row className="mt-4">
              <Button color="light" onClick={handleAddRequirement} className="d-flex h-35 me-3 align-items-center mb-2"
                style={{ maxWidth: "150px" }}
              >
                <i className="bx bx-plus text-dark font-size-18 mx-1" />
                <span className=" ">{t("Requirement")}</span>
                {/* <i className=" font-size-20 color-blue fas fa-plus me-2 " />
                {t("Requirement")} */}
              </Button>
            </Row>
            <table className="table">
              <thead>
                <tr>
                  <th >Vehicle Type</th>
                  <th>Count</th>
                  <th>Duration</th>
                  <th>Details</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.requirements?.map((item, index) => (
                  <tr key={index}>
                    <td style={{ minWidth: "180px" }}
                    >
                      <VehicleTypeSelect
                        outline
                        value={item?.vehicleType}
                        onChange={(v) => handleRequirementChange(index, 'vehicleType', v?.value)}
                      />
                    </td>
                    <td>
                      <InputField
                        type="number"
                        outline
                        value={item?.count}
                        onChange={(e) => handleRequirementChange(index, 'count', e)}
                      />
                    </td>
                    <td style={{ minWidth: "180px" }}>
                      <SelectField
                        outline
                        data={SELECTSTRUCTURE.duration}
                        value={item?.duration}
                        onChange={(v) => handleRequirementChange(index, 'duration', v?.label)}
                      />
                    </td>
                    <td>
                      {/* <textarea
              className="col-sm-12 p-2"
              style={{ borderColor: "hsl(0, 0%, 80%)", outline: "none" ,borderRadius:'4px',marginTop:'10px'}}
              rows="1"
              value={item?.details}
              onChange={(e) => handleRequirementChange(index, 'details', e)}
              type="text"
            /> */}

                      <InputField
                        outline
                        value={item?.details}
                        onChange={(e) => handleRequirementChange(index, 'details', e)}
                      />
                    </td>
                    <td>
                      <InputField
                        type="number"
                        outline
                        value={item?.price}
                        onChange={(e) => handleRequirementChange(index, 'price', e)}
                      />
                    </td>
                    <td>
                      <div onClick={() => handleDeleteRequirement(index)} >
                        <i className="bx color-blue font-size-20 bxs-x-circle d-flex justify-content p-0" style={{ marginRight: '4px', fontSize: "10px", marginTop: '17px' }} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </Row>

          <Row>

          </Row>



        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditBids);
