
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, Row } from "reactstrap";
import { ENDPOINTS } from '../../../common/utils/Constants';
import { EditLink, PhoneNum } from "../../../components";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { doPOST } from '../../../util/HttpUtil';
import OtpInput from '../../Authentication/OtpInput';
import ProfileService from "../ProfileService";

function ChangePhone({ data, setData, profile, hasErr, fetchData, supervisorId }) {
    const [imageURL, setImageUrl] = useState();
    const [step, setStep] = useState(0);
    const [captcha, setCapcha] = useState()

    const [phonedata, setPhoneData] = useState({});
    const [loading, setLoading] = useState(false);
    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const fetch = async () => {
        try {
            let tag = await ProfileService.getProfileDetail(supervisorId);
            setData(tag);
            setPhoneData({
                ...phonedata, phone: tag.phoneNo?.phone, countryCode: tag.phoneNo?.countryCode
            })
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        setData(null)
        setLoading(true);
        if (supervisorId) fetch();
        setLoading(false)
    }, [supervisorId]);


    const handleClick = () => {
        getCaptcha();
        setStep(1);
    };

    useEffect(() => {
        console.log(phonedata, '-');
    }, [phonedata])

    const getCaptcha = async () => {
        const dta = {
            for: "captcha",
        };
        try {
            let res = await doPOST(ENDPOINTS.LoginGetCaptcha, dta);
            setImageUrl(res.data.image);
            setPhoneData({
                ...phonedata,
                captchaOTP_Id: res.data.captcha_id, otpId: null, otp: null

            })
        } catch (error) {
            showError(error);
        }
    };
    const sendChangedPhone = async () => {

        try {
            let res = await doPOST(ENDPOINTS.changePhone, phonedata);
            showMessage("Phone updated Successfully");
            setStep(3);
        } catch (error) {
            showError(error);
        }
    };
    const newdata = {
        ...phonedata,
        platform: "web",
    };



    const gettoken = async () => {
        try {
            const response = await doPOST("/auth/token", newdata)
            if (response.status === 200) {
                setPhoneData({
                    ...phonedata, otpId: response.data?.phone
                })
                setStep(2)
            } else {
                showError("Incorrect OTP Entered");

            }
        } catch (error) {
            showError(error);
        }
    };

    return (
        <>
            <Row>
                <Card style={{ maxWidth: '1000px' }} className="shadow-none "  >
                    <CardBody className="p-4">
                        <Row className="mt-3 mb-3  align-items-center ">
                            {(step === 0 || step === 1 || step === 2) && (
                                <div className="d-flex align-items-center justify-content-start">
                                    <PhoneNum
                                        outline
                                        isDisabled={step === 0}
                                        placeholder={t("Phone No ")}
                                        value={{
                                            countryCode: phonedata?.countryCode,
                                            phone: phonedata?.phone,
                                        }}
                                        onChange={(v) => {
                                            setPhoneData({ ...phonedata, countryCode: v?.countryCode, phone: v?.phone });

                                        }}
                                        label={"Phone"}
                                        className="col-sm-6"
                                        type="text"
                                    />
                                    <div>
                                        {step === 0 && <EditLink className="ms-2"
                                            onClick={() => { handleClick() }} />}

                                    </div>

                                </div>
                            )}
                        </Row>
                        <Row>
                            {(step === 1) && (
                                <div>
                                    <Row className="d-flex flex-sm-row flex-column justify-content-between align-items-center col-6 px-sm-2 px-0">
                                        <div
                                            style={{ border: "1px solid #ced4da", borderRadius: '0.5rem', minWidth: '180px' }}
                                            className="col-sm-6 col-12 my-3 d-flex "
                                        >
                                            {imageURL && (
                                                <img
                                                    src={`data:image/jpeg;base64,${imageURL}`}
                                                    alt=""
                                                    height={32}
                                                    style={{ margin: 'auto' }}
                                                />
                                            )}

                                            <div className=" ">
                                                <i
                                                    id="captcha"
                                                    onClick={() => getCaptcha()}
                                                    style={{
                                                        fontSize: "25px",
                                                        cursor: "pointer",
                                                    }}
                                                    className="uil-redo rounded-right pe-2"
                                                />
                                            </div>
                                        </div>
                                        <div className="ms-0 col-6" style={{ minWidth: "200px" }}>

                                            <OtpInput
                                                value={phonedata?.captcha}
                                                onChange={(v) => {
                                                    setPhoneData({ ...phonedata, captcha: v });


                                                }}
                                                error={
                                                    phonedata?.captcha?.length === 0
                                                        ? "Please enter Captcha"
                                                        : ""
                                                }
                                                className="flex-1 mb-1"
                                            />
                                        </div>

                                    </Row>

                                    <Button
                                        onClick={gettoken}
                                        className="mx-2 mt-xl-0 mt-4"
                                        style={{ padding: "2px 4px", borderRadius: '30px' }}
                                        color="success"
                                    >
                                        <div
                                            style={{ margin: "2px", float: "right" }}
                                        >Send OTP</div>
                                    </Button>

                                </div>

                            )}



                            {step === 2 && (
                                <div>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div className="col-4" style={{ minWidth: "180px" }}>
                                            <OtpInput
                                                label="Phone OTP"
                                                value={phonedata?.otp}
                                                onChange={(v) => {
                                                    setPhoneData({ ...phonedata, otp: v })
                                                }}

                                                error={
                                                    phonedata?.otp?.length === 0
                                                        ? "please enter the email otp"
                                                        : ""
                                                }
                                                className=" "
                                            />
                                        </div>


                                        <Button
                                            onClick={() => {
                                                handleClick()

                                            }}
                                            className="mx-2"
                                            style={{ padding: "3px 4px", position: "relative", top: "10px", borderRadius: '30px' }} color="success"
                                        >
                                            <div
                                                style={{ margin: "4px", float: "right" }}
                                            >Resend OTP</div>
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() => {

                                            }}
                                            className="mx-2"
                                            style={{ padding: "2px 4px", position: "relative", top: "20px", right: "4px", borderRadius: '30px' }}
                                            color="success"
                                        >
                                            <div
                                                style={{ margin: "4px", float: "right" }}
                                                onClick={() => sendChangedPhone()}
                                            >Save Phone</div>
                                        </Button>

                                    </div>
                                </div>

                            )}


                            {(step === 3) && (
                                <div className="d-flex align-items-center justify-content-start">
                                    <PhoneNum
                                        outline
                                        isDisabled
                                        placeholder={t("Phone No ")}
                                        value={{
                                            countryCode: phonedata?.countryCode,
                                            phone: phonedata?.phone,
                                        }}
                                        onChange={(v) => {
                                            setPhoneData({ ...phonedata, countryCode: v?.countryCode, phone: v?.phone });

                                        }}
                                        label={"Phone"}
                                        className="col-sm-6"
                                        type="text"
                                    />


                                </div>
                            )}

                        </Row>
                    </CardBody>
                </Card>
            </Row>
        </>
    );
}

export default ChangePhone;





