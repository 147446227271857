import { observer } from "mobx-react-lite";

import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";


import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  InputField,
} from "../../../../components";
import "../../../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../../../store/context/DialogContext";
import { I18nContext } from "../../../../store/context/i18nContext";




import RoutingSettingsService from "./RoutingSettingsService";


const RoutingSettings = (props) => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({})


  const { showMessage } = useContext(DialogContext);
  const [hasErr, setHasErr] = useState(false);

  const submitHandler = async (e) => {

    e.preventDefault();

    setLoading(true);

    try {
      await RoutingSettingsService.save(data)


      showMessage("Routing Settings saved successfully");
      setLoading(false)
    } catch (e) {
      showError(e)
      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await RoutingSettingsService.get();
      setData(response)

      setHasErr(false);
      setLoading(false);

    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {

    setLoading(true);

    fetchData();
    setHasErr(false);

  }, []);



  if (loading) return <CardThreeBounce />;
  return (<div className="p-4 mt-3">



    <Row className="mt-5">
      <Col className='col-lg  mt-3' style={{ backgroundColor: "#EAEAEA", fontSize: "15px", borderBottom: "1px solid rgba(0,0,0,.1)" }}>
        <header>Routing Settings</header>
      </Col>
    </Row>


    <Row className="mt-5 mb-3">
      <Col className="col-lg">

        <InputField
          placeholder="Rostering Buffer Percentage"
          value={data?.rosteringBufferPercentage}
          label={"Routing Buffer Percentage (this % Buffer added additonaly to Map APIs provided duration of travel)"}
          onChange={(v) =>
            setData({
              ...data,
              rosteringBufferPercentage: v
            })
          }



          type="number"

        />

      </Col>
    </Row>

    <Row className="mt-5 mb-3">
      <Col className="col-lg">

        <InputField
          placeholder="Rostering Buffer Mins"
          value={data?.rosteringBufferMins}
          label={"Routing Buffer Mins (To ensure all pax reach buffer Mins before Workshit start time)"}
          onChange={(v) =>
            setData({
              ...data,
              rosteringBufferMins: v
            })
          }



          type="number"

        />

      </Col>
    </Row>








    <div className="d-flex justify-content-end  ">
      <div className="me-2">
        <Button type="submit" color="primary" onClick={submitHandler}>Save</Button>{' '}
      </div>




    </div>

  </div>

  );
};

export default observer(RoutingSettings);


