export const ENDPOINTS = {
  saveTariff: `/api/corp/config/tariff/save`,
  getConfig: () => `/api/corp/config/get`,
  saveConfig: () => `/api/corp/config/booking/save`,
  testSMTP: () => `/api/corp/smtp/test`,
  saveSms: () => `/api/corp/config/smsGateway/save`,
  saveSMTP: () => `/api/corp/config/smtpCred/save`,
  saveAura: () => `/api/corp/config/aura/save`,
  getConfigById: (id) => `/api/corp/${id}/config`,
  saveVehicleType: () => `/api/corp/config/myVehicleTypes/save`,
  vehicleMap: `/api/o/corp/vehicleType/map`,
  saveRoute: '/api/corp/config/route/save',
  getTags: '/api/tag/all'
};
