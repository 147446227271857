export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/quality-check/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  get: (id) => `/api/quality-check/${id}/detail`,
  save: `/api/quality-check/create`,
  delete: (id) => `/api/quality-check/${id}/delete`,
  update: `/api/quality-check/update`,
};

export const STRUCTURE = [
  {
    label: "Company Type",
    filterLabel: "Company Type",
    filterName: "entityType",
    type: "select",
    data: [
      { label: "Vehicle", value: 1 },
      { label: "Driver", value: 2 },
      { label: "Escort", value: 3 },
      { label: "Self Drive", value: 4 },

    ],
   
  },
  {
    label: "Status",
    filterLabel: "Status ",
    filterName: "status",
    type: "select",
    data: [
      { label: "In Progress", value: 0 },
      { label: "Approved", value: 1 },
      { label: "Rejected", value: 2 },
    ],
   
  },
];

