import React, { useContext } from "react";
import { SelectField } from "../../SelectField/SelectField";
import { I18nContext } from "../../../store/context/i18nContext";


export default function AppStatusSelect(props) {
  const { t } = useContext(I18nContext);

  const data = [
    { label: t("Not Installed"), value: 0 },
    { label: t("Installed"), value: 1 },
  ];

  return (
    <React.Fragment>
      <SelectField
        className={props?.className}
        onChange={props?.onChange}
        data={data}
        showClear={props?.showClear}
        value={props?.value}
        label={"App Status"}
        error={props?.value ? "Please Select App Status" : ""}
        showErr={props?.showErr}
      />
    </React.Fragment>
  );
}

