import { useSocket } from "../socket/useSocket";
import { usePeer } from '../peer/usePeer'
import { UserContext } from "../../../store/context/UserContext";
import { useContext } from "react";
import { getDevicesInformation, getDisplayMedia, changeAudioInputDevice, changeAudioOutputDevice } from "../../SupportServiceHelpers/utils";
import { getUserMedia } from "../../SupportServiceHelpers/utils";

export const useSupportCall = (whoAmI, onRemoteStream) => {

  const { supportCallRef, supportCallDataRef, setSupportCallStatusForUserSetter, setSupportCallStatusForAdminSetter, setsupportCallScreenShareStatusSetter } = useContext(UserContext);
  let connectionPresentByUserInterval, connectionPresentByAdminInterval;
  const {
    socketInit,
    socketAddListeners,
    socketRemoveListeners,
    socketEmitter,
    socketClose,
    socketInstance,
  } = useSocket();

  const {
    peerInit,
    peerStartWaiting,
    peerInstance,
    peerClose
  } = usePeer(onRemoteStream);

  let audioSource = "default"

  //user
  const supportCallInitByUser = (requestType, {
    onSupportCallAccepted,
    onNoRoomPresent,
    onSupportCallRejected,
    onSupportCallDisconnected,
  }) => {

    if (!socketInstance()) {
      try {
        socketInit("/", "/socket", { whoAmI });
      } catch (error) {
        setTimeout(() => {
          setSupportCallStatusForUserSetter("not-connected");
          if (onNoRoomPresent)
            onNoRoomPresent();
        }, 2000);
      }
    }

    socketEmitter("user-join");
    connectionPresentByUserInterval = setInterval(() => {
      socketEmitter("connection-present-by-user")
    }, 1000);
    setSupportCallStatusForUserSetter("calling");

    //callbacks for events
    const callbackOnNoRoomPresent = () => {
      setTimeout(() => {
        setSupportCallStatusForUserSetter("not-connected");
        if (onNoRoomPresent)
          onNoRoomPresent();
      }, 2000);
    }

    const callbackOnSupportCallAccepted = async (otherPeerUser) => {
      supportCallRef.current = { ...supportCallRef.current, otherPeerUser };
      try {
        await peerInit(otherPeerUser.socketId,);
        const stream = await getUserMedia({
          audio: true,
          video: false,
        });

        const data = await getDevicesInformation();

        const senders = [];
        stream
          .getTracks()
          .forEach((track) =>
            senders.push(peerInstance().addTrack(track, stream))
          );

        if (whoAmI === 'user')
          supportCallDataRef.current = {
            audioInputDevices: data.audioInputDevices,
            audioOutputDevices: data.audioOutputDevices,
            localStream: stream,
            senders
          }

        supportCallRef.current = { ...supportCallRef.current, otherPeerUser: otherPeerUser.socketId }

        setSupportCallStatusForUserSetter("call-connected");
        if (onSupportCallAccepted)
          onSupportCallAccepted()
      } catch (error) {
        supportCallEndByUser()
      }
    }
    const callbackOnSupportCallRejected = async () => {
      supportCallRef.current.status = "not-connected";
      if (onSupportCallRejected)
        onSupportCallRejected();
      setSupportCallStatusForUserSetter("calling");
    };

    const callbackOnSupportDisconnected = () => {
      setSupportCallStatusForUserSetter("not-connected");
      if (onSupportCallDisconnected)
        onSupportCallDisconnected();
    }
    //listen Events using Callbacks
    if (requestType === 'new')
      socketAddListeners(
        [
          {
            event: "no-room-present",
            callback: callbackOnNoRoomPresent
          },
          {
            event: "support-call-connected",
            callback: callbackOnSupportCallAccepted
          },
          {
            event: "support-call-rejected",
            callback: callbackOnSupportCallRejected
          },
          {
            event: "support-disconnected",
            callback: callbackOnSupportDisconnected
          },
        ]
      )
  }
  const supportCallScreenShareInitByUser = ({ onSupportCallScreenShareRequest }) => {
    setsupportCallScreenShareStatusSetter("waiting-for-ask")

    const callbackOnSupportRequestToShareScreen = () => {
      setsupportCallScreenShareStatusSetter("asking")
      if (onSupportCallScreenShareRequest)
        onSupportCallScreenShareRequest()
    }

    socketAddListeners(
      [
        {
          event: "support-request-to-share-screen",
          callback: callbackOnSupportRequestToShareScreen
        },
      ]
    )
  }

  const supportCallScreenShareAcceptedByUser = async ({ onSupportCallScreenShareAccepted }) => {

    try {
      const stream = await getDisplayMedia({ cursor: true })
      const screenTrack = stream.getVideoTracks()[0];

      supportCallDataRef.current = { ...supportCallDataRef.current, localScreenStream: stream };

      supportCallDataRef.current.senders
        .find((sender) => sender.track.kind === "video")
        .replaceTrack(screenTrack);

      screenTrack.onended = function () {
        console.log("Tract Ended");
      };

      socketEmitter("user-accepted-screen-share-request");
      setsupportCallScreenShareStatusSetter("screen-share-started")

      if (onSupportCallScreenShareAccepted)
        onSupportCallScreenShareAccepted()
    } catch (error) {
      setsupportCallScreenShareStatusSetter("waiting-for-ask")
      socketEmitter("user-rejected-screen-share-request");
      console.log(error.message);
    }
  }

  const supportCallScreenShareRejectedByUser = ({ onSupportCallScreenShareRejected }) => {
    setsupportCallScreenShareStatusSetter("waiting-for-ask")
    socketEmitter("user-rejected-screen-share-request");

    if (onSupportCallScreenShareRejected)
      onSupportCallScreenShareRejected()
  }

  const supportCallEndByUser = () => {
    peerClose()
    socketRemoveListeners([
      "no-room-present",
      "support-disconnected",
      "support-call-rejected",
      "support-call-connected",
      "support-request-to-share-screen"
    ])
    socketEmitter("user-end-call");
    clearInterval(connectionPresentByAdminInterval)
    socketClose();
    setSupportCallStatusForUserSetter('not-connected')

    if (supportCallDataRef.current.localStream) {
      const tracks = supportCallDataRef.current.localStream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    if (supportCallDataRef.current.localScreenStream) {
      const tracks = supportCallDataRef.current.localScreenStream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    supportCallRef.current = {
      role: undefined,
      whoAmI: undefined,
      otherPeerUser: null,
    };
    supportCallDataRef.current = {};

  }

  //support
  const supportCallInitByAdmin = async (requestType, {
    onUserCalling,
    onUserDisconnected
  }) => {

    if (!socketInstance()) {
      try {
        socketInit("/", "/socket", { whoAmI });
      } catch (error) {
        console.log(error.message);
      }
    }

    socketEmitter("support-join");
    setSupportCallStatusForAdminSetter("waiting-for-call")

    //callbacks


    const callbackOnUserCalling = (userData) => {
      setSupportCallStatusForAdminSetter("calling")
      supportCallRef.current = { ...supportCallRef.current, otherPeerUser: userData.socketId }
      if (onUserCalling)
        onUserCalling(userData)
    }

    const callbackOnUserDisconnected = () => {

      setSupportCallStatusForAdminSetter("waiting-for-call")
      setsupportCallScreenShareStatusSetter("not-started")
      if (onUserDisconnected)
        onUserDisconnected()
    }
    connectionPresentByAdminInterval = setInterval(() => {
      socketEmitter("connection-present-by-support")
    }, 1000);
    //listeners
    if (requestType === 'new')
      socketAddListeners(
        [
          {
            event: "user-calling",
            callback: callbackOnUserCalling
          },
          {
            event: "user-disconnected",
            callback: callbackOnUserDisconnected
          }
        ]
      )
  }

  const supportCallOpenPeerByAdmin = async () => {
    const onPeerWaitOver = async () => {
      const stream = await getUserMedia({
        audio: true,
        video: false,
      });

      const data = await getDevicesInformation();

      const senders = [];
      stream
        .getTracks()
        .forEach((track) =>
          senders.push(peerInstance().addTrack(track, stream))
        );

      if (whoAmI === 'admin')
        supportCallDataRef.current = {
          audioInputDevices: data.audioInputDevices,
          audioOutputDevices: data.audioOutputDevices,
          localStream: stream,
          senders
        }
    }
    try {
      await peerStartWaiting(onPeerWaitOver, supportCallRef.current.otherPeerUser)
    } catch (error) {
      supportCallEndByAdmin()
    }
  }

  const supportCallAcceptByAdmin = () => {
    socketEmitter("support-call-accepted");
    setSupportCallStatusForAdminSetter("call-connected")
  }

  const supportCallRejectByAdmin = () => {
    socketEmitter("support-call-rejected");
    setSupportCallStatusForAdminSetter("waiting-for-call")
  }

  const supportCallScreenShareInitByAdmin = ({ onSupportUserAcceptedScreenShareRequest,
    onSupportUserRejectedScreenShareRequest }) => {
    setsupportCallScreenShareStatusSetter("waiting-for-ask")

    const callbackOnUserAcceptedScreenShareRequest = () => {
      setsupportCallScreenShareStatusSetter("screen-share-started")
      if (onSupportUserAcceptedScreenShareRequest)
        onSupportUserAcceptedScreenShareRequest()
    }

    const callbackOnUserRejectedScreenShareRequest = () => {
      setsupportCallScreenShareStatusSetter("waiting-for-ask")
      if (onSupportUserRejectedScreenShareRequest)
        onSupportUserRejectedScreenShareRequest()
    }


    socketAddListeners(
      [
        {
          event: "user-accepted-screen-share-request",
          callback: callbackOnUserAcceptedScreenShareRequest
        },
        {
          event: "user-rejected-screen-share-request",
          callback: callbackOnUserRejectedScreenShareRequest
        },
      ]
    )
  }

  const supportCallEndByAdmin = () => {
    peerClose()
    socketRemoveListeners([
      "user-accepted-screen-share-request",
      "user-rejected-screen-share-request",
    ])
    socketEmitter("support-end-call");
    setSupportCallStatusForAdminSetter('waiting-for-call')
    if (supportCallDataRef.current.localStream) {
      const tracks = supportCallDataRef.current.localStream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    supportCallRef.current = {
      role: undefined,
      whoAmI: undefined,
      otherPeerUser: null,
    };
    supportCallDataRef.current = {};
  }

  const supportCallDisconnectByAdmin = () => {
    peerClose()
    socketRemoveListeners([
      "user-disconnected",
      "user-accepted-screen-share-request",
      "user-rejected-screen-share-request",
      'user-calling',
    ])
    // socketEmitter("support-end-call");
    socketClose()
    clearInterval(connectionPresentByUserInterval)
    setSupportCallStatusForAdminSetter('not-connected')
    if (supportCallDataRef.current.localStream) {
      const tracks = supportCallDataRef.current.localStream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    supportCallRef.current = {
      role: undefined,
      whoAmI: undefined,
      otherPeerUser: null,
    };
    supportCallDataRef.current = {};

  }

  const supportCallAskToShareScreenByAdmin = () => {
    socketEmitter("support-request-to-share-screen");
    setsupportCallScreenShareStatusSetter("asking")
  }

  //common
  const supportCallToggleMic = (micStatus) => {
    if (supportCallDataRef.current.localStream) {
      if (micStatus) supportCallDataRef.current.localStream.getAudioTracks()[0].enabled = false;
      else supportCallDataRef.current.localStream.getAudioTracks()[0].enabled = true;
    }
  };

  //remaining
  const supportCallEndScreenSharingByUser = () => {
    socketRemoveListeners([
      "support-request-to-share-screen"
    ])
  }

  if (whoAmI === 'user') {
    return {
      supportCallToggleMic,
      supportCallInit: supportCallInitByUser,
      supportCallEnd: supportCallEndByUser,
      supportCallDisconnect: supportCallEndByUser,
      supportCallScreenShareInitByUser,
      supportCallEndScreenSharingByUser,
      supportCallScreenShareAcceptedByUser,
      supportCallScreenShareRejectedByUser,
    };
  }

  return {
    supportCallInit: supportCallInitByAdmin,
    supportCallEnd: supportCallEndByAdmin,
    supportCallDisconnect: supportCallDisconnectByAdmin,
    supportCallOpenPeerByAdmin,
    supportCallAskToShareScreenByAdmin,
    supportCallAcceptByAdmin,
    supportCallRejectByAdmin,
    supportCallToggleMic,
    supportCallScreenShareInitByAdmin,
  };
};
