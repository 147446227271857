import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";

const ENDPOINTS = {
    grid: ({ record_id, space_id, module }) => {
        let url = `/api/reminder/grid?record_id=${record_id}&module=${module}`;
        return url;
    },
    get: (id) => `/api/reminder/${id}/detail`,
    save: `/api/reminder/create`,
    delete: (id) => `/api/reminder/${id}/delete`,
    bulkDelete: (id) => `/api/reminder/delete`,
    update: `/api/reminder/update`,
};

class Service {
    records = [];
    STRUCTURE = [];
    page = 1;
    rowsPerPage = null;
    filterOption = "";
    total = -1;
    record_id = null
    constructor() {
        makeAutoObservable(this);
    }

    fetch = async ({ record_id, space_id, module }) => {
        if (record_id) {
            this.record_id = record_id;
            const response = await doGET(  ENDPOINTS.grid({ record_id, space_id, module }) );
            if (response?.status === 200) {
                this.records = response.data.rows;
                return response.data.rows
            }
        }
        return []
    };

    edit = async function (data, id) {
        const response = await doPUT(ENDPOINTS?.update, data);
        if (response.status === 200) {
            this.fetch(this.record_id)
            return response.data ? response.data : response;
        }
    };

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    save = async (data) => {
        this.records.push(data);
        const response = await doPOST(ENDPOINTS.save, data);
        this.fetch(this.record_id)
        return response?.data;
    };

    delete = async (id) => {
        const response = await doDELETE(ENDPOINTS.delete(id));
        this.fetch(this.record_id)
        if (response.status === 200) {
            this.fetch();
        }
    };

    bulkDelete = async (ids = []) => {
        const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
        if (response.status === 200) {
            this.fetch();
        }
    };



}

const ReminderService = new Service();
export default ReminderService;

