import React from 'react'

function Successs() {
  return (
    <div style={{marginTop:"100px"}}>
        <h1>
            <strong>
      Success
      </strong>
      </h1>
    </div>
  )
}

export default Successs
