/* FOR Lead ... CURRENTLY INCOMPLETED */

import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";

const ENDPOINTS = {
    grid: (space_id) => {
        let url = `/api/lead/grid?space_id=${space_id}`;
        return url;
    },
    get: (id) => `/api/lead/${id}/detail`,
    save: `/api/lead/create`,
    delete: (id) => `/api/lead/${id}/delete`,
    bulkDelete: (id) => `/api/lead/delete`,
    update: `/api/lead/update`,
};

class LeadSelect {

    leads = [];
    queue = [];
    isFetching = false;

    constructor() {
        makeAutoObservable(this);
    }

    setLeads = ( v)=> {
this.leads = v
    }

    fetchSingle = async (space_id) => {
        return new Promise(async (resolve, reject) => {
            try {

                    const response = await doGET(ENDPOINTS.grid(space_id));
                    if (response.status === 200) {
                        this.leads = response?.data?.rows
                    } else {
                        // Handle error
                        this.error = response.data;
                    }


                while (this.queue.length > 0) {
                    const { resolve } = this.queue.shift();
                    resolve();
                }

            } catch (err) {
                this.error = err;
                while (this.queue.length > 0) {
                    const { reject } = this.queue.shift();
                    reject(err);
                }
            } finally {
                this.isFetching = false;
            }
        });
    }

    fetch = async function (space_id) {
        if (this.isFetching) {
            return new Promise((resolve, reject) => this.queue.push({ resolve, reject }));
        };
        this.isFetching = true;
        this.fetchSingle(space_id)
    };
}

const LeadSelectService = new LeadSelect();
export default LeadSelectService;
