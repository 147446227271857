import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { ThreeBounce } from "better-react-spinkit";
import { UserContext } from "../../store/context/UserContext";
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditIncidents from "./EditIncident";
import { STRUCTURE } from "./IncidentConstant";
import { GridColumns } from "./IncidentGridColumns";
import IncidentService from "./IncidentService";

const Incidents = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
    const { userContent } = useContext(UserContext);
    let { edit_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(edit_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/incident/create" || edit_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)

    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await IncidentService.fetch(filterUrl);
            setLoading(false);
        } catch (e) {
            showError(e);
            setLoading(false);
        }

    };

    useEffect(() => {
        const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }, [location.search])


    useEffect(() => {
        setEditID(edit_id)
    }, [edit_id])

    const onSave = async (e) => {
        e.preventDefault();
        // if (!detailData?.status && !detailData?.remark) {
        //     setHasErr(true)
        //     showError(t("Please enter all required fields"));
        //     return;
        // }
        setLoading(true);
        try {
            if (editID) {
                await IncidentService.edit(detailData, editID);
                setLoading(false);
                setShowDetailPage(false);
                showMessage(t("Record updated successfully."));
            } else {
                const incident = await IncidentService.save(detailData);
                setLoading(false);
                setShowDetailPage(false);
                showMessage(t("Record saved successfully."));
                if (!insidePane) navigate(`/incident/edit/${incident}`);
            }
            fetchData(filterURL);

        } catch (e) {
            showError(e);
            setLoading(false);
        }
        setHasErr(false)
    };
    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await IncidentService.delete(id);
                setLoading(false);
                setShowDetailPage(false);
                showMessage(t("Deleted"), t("Deleted"));
            } catch (e) {
                showError(e);
                setLoading(false);
            }
        }
    };
    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await IncidentService.bulkDelete(selectedIDs);
                setLoading(false);
                showMessage(t("Deleted"), t("Deleted Successfully"));
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };



    return (
        <React.Fragment>
            <>
                <Layout
                    hideAdd
                    medium
                    showDetailPage={showDetailPage}
                    backDetailPage={async () => {
                        setShowDetailPage(false);
                        if (!insidePane) navigate("/incident");
                        setEditID(null);
                    }}
                     
                    title={t("Incidents")}
                    filterValues={filterObject}
                    filterStructure={STRUCTURE}
                    onApplyFilter={fetchData}
                    hideSearch
                    onAddClick={() => {
                        if (!insidePane) navigate(`/incident/create`);
                        setShowDetailPage(true);
                        setEditID(null);
                    }}
                    insidePane={insidePane}
                    page={IncidentService.page}
                    rows={IncidentService.rows}
                    total={IncidentService.total}
                    fetch={IncidentService.fetch}
                >
                    <Layout.ActionMenu>
                        <div className="layout-action-menu">
                            <DropdownMenu>
                                <>
                                    {userContent?.rights?.includes(5903) ?
                                    <>
                                        <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                                        <div class="dropdown-divider"></div>
                                        </>
                                        : null}
                                    <DropdownItem>{t("Upload")}</DropdownItem>
                                    <div class="dropdown-divider"></div>
                                    <DropdownItem> Download </DropdownItem>
                                </>

                            </DropdownMenu>

                        </div>
                    </Layout.ActionMenu>
                    <Layout.Table>
                        <DataGrid
              gridLoading={loading}
                            data={IncidentService.records}
                            total={IncidentService.total}
                            uiPreference="incident.grid"
                            headers={GridColumns}
                            onSelectChange={(v) => {
                                onSelect(v)
                                setSelectedIDs(v)
                            }}
                            selectedIDs={selectedIDs}
                            page={IncidentService.page}
                            rowsPerPage={IncidentService.rowsPerPage}
                            onPaginationChange={IncidentService.onPaginationChange}
                            renderLastCol={(incident) => {
                                return (
                                    <>
                                        <EditLink
                                            onClick={() => {
                                                if (!insidePane)
                                                    navigate(`/incident/edit/${incident?._id}`);
                                                setEditID(incident?._id);
                                                setShowDetailPage(true);

                                            }}
                                        />
                                        {userContent?.rights?.includes(5903) ?
                                            <DeleteLink
                                                onClick={(event) => onDelete(event, incident?._id)}
                                            />
                                            : null}
                                        {!multiMode && insidePane ? (
                                            <CheckButton
                                                onClick={() => {
                                                    onSelect(incident?._id);
                                                }}
                                            />
                                        ) : null}
                                    </>
                                );
                            }}
                        />
                    </Layout.Table>


                    <Layout.DetailPageTitle>
                        {detailData?._id ? t("Edit Incident") : t("Add Incident")}
                    </Layout.DetailPageTitle>

                    <Layout.DetailPageBody>
                        <EditIncidents
                            editId={editID}
                            onChange={(v) => {
                                setDetailData(v)
                            }}
                            hasErr={hasErr}
                        />
                    </Layout.DetailPageBody>

                    <Layout.DetailPageFooter>
                        {userContent?.rights?.includes(5903) && detailData?._id ?
                            <DeleteButton
                                loading={loading}
                                onClick={(e) => onDelete(e, detailData?._id)} />
                            : null}
                        {userContent?.rights?.includes(5902) ?
                            <SaveButton
                                loading={loading}
                                onClick={onSave} />
                            : null}
                    </Layout.DetailPageFooter>
                </Layout>
            </>
        </React.Fragment>
    );
};
export default observer(Incidents);
