import React from "react";
import { useNavigate } from "react-router-dom";

const RedirectLink = (props) => {
  let navigate = useNavigate();
  return (
    <div
      style={{ display: "inline-block", color: "primary", cursor: "pointer" }}
      onClick={() => {
        if (props?.to) navigate(props?.to);
        props?.onClick();
      }}
    >
      <i
        style={{ color: "primary", paddingLeft: "10px" }}
        className=" fas fa-subscript
color-primary font-size-18"
      />
    </div>
  );
};
export default RedirectLink;
