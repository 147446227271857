import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { doGET } from "../../../../util/HttpUtil";
import { ENDPOINTS } from "../../BookingConstant";

const PaymentCollectedTable = ({ trip_id }) => {
    const [payments, setPayments] = useState([]);

    const fetchPayments = async () => {
        if (trip_id) {
            try {
                const data = await doGET(ENDPOINTS.paymentsGrid(trip_id));
                if (data?.data.rows) {
                    setPayments(data?.data.rows);
                }
            } catch (error) {
            }
        }
    }

    useEffect(() => {
        fetchPayments();
    }, [trip_id]);

    return (
        <>
            {
                payments?.length ?
                    <>
                        < div className=" " >
                            <div style={{ backgroundColor: "#EBEBEB" }} className="h3 ps-3 py-2 d-flex align-items-center justify-content-between">
                                <div>Payments  </div>
                            </div>

                            <div className='px-3'>

                                <Table className="table-borderless table-centered table-nowrap">
                                    <tbody>
                                        <tr style={{ borderBottom: "1px solid #EBEBEB" }}>
                                            <td>Paid At</td>
                                            <td>Amount</td>
                                            <td>Status</td>
                                        </tr>

                                        {payments.map((payment, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {payment?.completedAt ? moment.unix(payment?.completedAt).format("DD-MM-YYYY  hh:mm A") : "--"}
                                                </td>
                                                <td>
                                                    {payment?.currency} {payment?.amount ? (payment?.amount/100).toFixed(2) : 0}
                                                </td>
                                                <td className="text-muted fw-semibold text-end h4">
                                                    {payment?.status === 1 ? "Initiated" : payment?.status === 2 ? "Success" : ""}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div >
                    </> :
                    <>
                    </>
            }
        </>
    );
}
export default PaymentCollectedTable;