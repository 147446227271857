import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from 'reactstrap'
import ToggleSelector from '../Toggle/ToggleSelector'
import { DateSelect } from '..'
import MonthPicker from '../Dropdowns/MonthSelect/MonthPicker'
import moment from 'moment'
import _ from 'lodash';
import "./TripStats.css"
import IndicativeStats from './IndicativeStats'
import DashboardService from '../../pages/Dashboard/DashboardService'
import Skeleton from 'react-loading-skeleton'

function InvoiceAmountCard() {
    const [selectedDate, setSelectedDate] = useState(parseInt(moment().format('YYYYMMDD')));
    const [selectedMonth, setSelectedMonth] = useState(parseInt(moment().format('YYYYMM')));
    const [data, setData] = useState({});
    const [toggle, setToggle] = useState('daily');
    const [loading, setLoading] = useState(false);

    const fetchInvoiceData = async ({ startDate, endDate }) => {
        if (loading) return;
        setLoading(true);

        try {
            const body = {
                groupBy: {
                    status: "paymentStatus"
                },
                ...(toggle === 'daily'
                    ? { filters: { invoiceDate: startDate || endDate } }
                    : { btwFilters: { invoiceDate: [startDate, endDate] } }),
                sum: "total"
            };

            const response = await DashboardService.fetchInvoices(body);

            const raisedStatuses = [0, 1, 2, 3, 4];
            const outstandingStatus = 0;
            const collectedStatuses = [1, 2, 3, 4];

            const raisedAmount = response
                .filter(item => raisedStatuses.includes(item._id.status))
                .reduce((acc, item) => acc + item.total, 0);

            const outstandingAmount = response
                .filter(item => item._id.status === outstandingStatus)
                .reduce((acc, item) => acc + item.total, 0);

            const collectedAmount = response
                .filter(item => collectedStatuses.includes(item._id.status))
                .reduce((acc, item) => acc + item.total, 0);

            const raisedCount = response
                .filter(item => raisedStatuses.includes(item._id.status))
                .reduce((acc, item) => acc + item.count, 0);

            const outstandingCount = response
                .filter(item => item._id.status === outstandingStatus)
                .reduce((acc, item) => acc + item.count, 0);

            const collectedCount = response
                .filter(item => collectedStatuses.includes(item._id.status))
                .reduce((acc, item) => acc + item.count, 0);

            setData({
                totalAmountRaised: isNaN(raisedAmount) ? 0 : Math.round(raisedAmount / 100),
                totalAmountOutstanding: isNaN(outstandingAmount) ? 0 : Math.round(outstandingAmount / 100),
                totalAmountCollected: isNaN(raisedAmount - outstandingAmount) ? 0 : Math.round((raisedAmount - outstandingAmount) / 100),
                raised: raisedCount,
                outstanding: outstandingCount,
                collected: collectedCount
            });

        } catch (e) {
            console.error(e);
        }
        finally {
            setLoading(false);
        }

    };


    useEffect(() => {
        if (toggle === 'daily') {
            fetchInvoiceData({ startDate: selectedDate, endDate: selectedDate });
        } else {
            const year = Math.floor(selectedMonth / 100);
            const month = selectedMonth % 100;
            const from = parseInt(moment(`${year}-${month}-01`).startOf('month').format('YYYYMMDD'));
            const till = parseInt(moment(`${year}-${month}-01`).endOf('month').format('YYYYMMDD'));
            fetchInvoiceData({ startDate: from, endDate: till });
        }
    }, [selectedDate, selectedMonth, toggle]);
    return (
        <Col sm={12}>
            <Card className="py-2 px-4 flex-1" style={{ height: "fit-content" }}>
                <div className="zr_trip_stats">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="font-size-14 my-2 d-flex align-items-center">
                            <h2><b>{"Invoice Amount"}</b></h2>
                        </div>

                        <div className="d-flex align-items-center">
                            <ToggleSelector
                                style={{ marginRight: "10px" }}
                                data={[
                                    { label: "Daily", value: "daily" },
                                    { label: "Monthly", value: "monthly" },
                                ]}
                                zeroMargin
                                value={toggle}
                                compact
                                tabClassName={"px-2"}
                                onChange={setToggle}
                            />

                            {toggle === 'daily' ? (
                                <DateSelect
                                    value={selectedDate}
                                    onChange={setSelectedDate}
                                    type="text"
                                    noLabel
                                    className={"my-0"}
                                />
                            ) : (
                                <MonthPicker
                                    value={selectedMonth}
                                    onChange={setSelectedMonth}
                                    type="text"
                                    noLabel
                                    className={"my-0"}

                                />
                            )}
                        </div>
                    </div>

                    {loading ?
                        <div className="d-flex justify-content-center p-4">
                            <Skeleton height={30} width={700} className='text-crm-greyish font-size-12' />
                        </div>
                        : <Row className='mt-2'>
                            <Col className="col-sm-12">
                                <Row className="zr_progress_stats d-flex row h-120">
                                    <div className='h-100'>
                                        <IndicativeStats
                                            data={[Math.round(data?.totalAmountOutstanding) ?? 0,
                                            Math.round(data?.totalAmountCollected) ?? 0]}
                                            configs={[{
                                                left: {
                                                    title: <Col>
                                                        <span className='font-size-14 fw-semibold'>Outstanding</span>
                                                    </Col>,
                                                    Currency: "INR",
                                                    subHeading: <Col>
                                                        <span className='fw-semibold font-size-20 greyish-info'>{data?.outstanding} </span>Invoices
                                                    </Col>
                                                },
                                                right: {
                                                    title: <Col>
                                                        <span className='font-size-14 fw-semibold'>Received</span>
                                                    </Col>,
                                                    Currency: "INR",
                                                    subHeading: <Col>
                                                        <span className='fw-semibold font-size-20 greyish-info'> {data?.collected} </span> Invoices
                                                    </Col>
                                                },
                                                top: {
                                                    title: <Col className="text-center font-size-14 fw-normal">Raised</Col>,
                                                    Currency: "INR"
                                                }
                                            }]}
                                        />


                                    </div>

                                </Row>
                            </Col>
                        </Row>}
                </div>
            </Card>
        </Col>
    )
}

export default InvoiceAmountCard