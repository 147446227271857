import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip';
import { Button, Card, Row, UncontrolledAccordion } from "reactstrap";
import guideIcon from '../../assets/images/icons/tour-guide.svg';
import moment from "moment";


import {
  AccountSelect,
  AutoSuggestField,
  CitySelect,
  ClientPoolSelect,
  ClientSelect,
  ContactPersonSelect,
  CountrySelect,
  CurrencyAmountField,
  CurrencySelect,
  DateSelect,
  EmployeeSelect,
  CompanySelect,
  InvoiceSelect,
  GarageSelect,
  InputField,
  LanguageSelect,
  MakeModelSelect,
  ModalComponent,
  OfficeSelect,
  PhoneNum,
  RoleSelect,
  SalutationSelect,
  SelectField,
  StateSelect,
  SupplierSelect,
  TagSelector,
  TaxInputField,
  TaxSelect,
  ToggleSelector,
  VehicleSelect,
  VehicleTypeSelect,
  TemplateSelect,
  NameField,
  AddressField,
  SelectOption,
  DateTimeSelect

} from "../../components";
import DraggableBox from "../../components/Draggable/Draggable";
import { I18nContext } from "../../store/context/i18nContext";
import { GroupedSelectField } from "../../components/SelectField/GroupedSelectField";
import ClientCompany from "../../components/Dropdowns/ClientCompany/ClientCompany";
import SupplierCompany from "../../components/Dropdowns/SupplierCompany/SupplierCompany";
import RateVehicle from "../../components/Dropdowns/RateVehicle/RateVehicle";
import MultiDatePickerCalendar from "../../components/MultiDatePickerComponent/MultiDatePickerCalendar";
import AmountField from "../../components/AmountField/AmountField";
import Board from "../../components/dnd/board/Board";
import { generateQuoteMap } from "../../components/dnd/mockData";
import UserSelect from "../../components/Dropdowns/UserSelect/UserSelect";
import StatusDropDown from "../../components/Dropdowns/StatusDropDown/StatusDropDown";
import AutoSuggestSelect from "../../components/AutoSuggestSelect/AutoSuggestSelect";
import Billing from "../Booking/components/Billing";
import BookingService from "../Booking/BookingService";
import DateTimePickerWithInterval from "../../components/DateTimePickerWithInterval";
import CityCountrySelect from "../../components/AddressField/CityCountrySelect";
import CustomerSelect from "../../components/Dropdowns/CustomerSelect/CustomerSelect";
import TimeIntegerComponent from "../../components/DateSelects/TimeIntegerInput";
// import boardData from "./boardData";


const DemoV2 = () => {
  const [data, setData] = useState(
    {
      groupedSelect: "purple",
      salutation: "",
      entityLabel: "xasZZ",
      currency: "",
      language: "",
      date: "",
      CountrySelect: "",
      CitySelect: "",
      CityGroupSelect: "",
      StateSelect: "",
      TimeZoneSelect: "",
      ZoneSelect: "",
      DriverSelect: "",
      GarageSelect: "",
      PhoneNum: "",
      vehicle: "",
      makeModel: "",
      vehicle: "",
      address: "",
      multiDate: [],
      isAbs: true,
      autoSuggest: ""
      // tags: [
      //   {
      //     "tag_id": "6502cd7a917bcf09b645a7ab",
      //     "details": "ferf",
      //     "moreDetails": "rgrgr"
      //   },
      //   {
      //     "tag_id": "6502ce16917bcf09b645a7ad",
      //     "details": "grr",
      //     "moreDetails": "rggr"
      //   }
      // ]
    }
  );

  const suggestData = [
    { label: 'Apple', value: 'apple' },
    { label: 'Banana', value: 'banana' },
    { label: 'Cherry', value: 'cherry' },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [dragData, setDragData] = useState(
    {
      'To-do': {
        title: 'To-do',
        items: [
          {
            id: '1',
            title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent.',
            subTitle: '25-May-2020',
          },
          {
            id: '2',
            title: 'Fix Styling',
            subTitle: '26-May-2020',
          },
          {
            id: '3',
            title: 'Handle Door Specs',
            subTitle: '27-May-2020',
          },
          {
            id: '4',
            title: 'morbi',
            subTitle: '23-Aug-2020',
          },
          {
            id: '5',
            title: 'proin',
            subTitle: '05-Jan-2021',
          },
        ],
      },
      'In Progress': {
        title: 'In Progress',
        items: [],
      },
      "Done": {
        title: 'Done',
        items: [],
      },
    }
  );
  // const [selectedOptions, setSelectedOptions] = useState([]);  


  const groupedOptions = [
    {
      label: "Colours",
      options: [
        { value: "ocean", label: "Ocean", groupValue: "1", groupLabel: "Colours" },
        { value: "blue", label: "Blue", groupValue: "2", groupLabel: "Colours" },
        { value: "purple", label: "Purple", groupValue: "3", groupLabel: "Colours" },
      ]
    },
    {
      label: "Flavours",
      options: [
        { value: "vanilla", label: "Vanilla", groupValue: "safe", groupLabel: "Flavours" },
        { value: "chocolate", label: "Chocolate", groupValue: "good", groupLabel: "Flavours" },
        { value: "strawberry", label: "Strawberry", groupValue: "wild", groupLabel: "Flavours" }
      ]
    }
  ];

  const statusMapping = {
    0: { text: "Created", color: "#AEB6BF" },
    1: { text: "Confirmed", color: "#2980B9" },
    2: { text: "Ready", color: "#A569BD" },
    3: { text: "Dispatched", color: "#FFBF00" },
    4: { text: "Live", color: "#FF7F50" },
    5: { text: "Completed", color: "#52BE80" },
    6: { text: "Cancelled", color: "#F1948A" },
    7: { text: "Error", color: "#CB4335" },
    8: { text: "Paused", color: "#CCCCFF" },
    9: { text: "Expired", color: "#7B241C" },
  };

  const [status, setStaus] = useState(3)

  // const [statusInfo, setStatusInfo] = useState(statusMapping[status || 0])

  // useEffect(() => {
  //   setStatusInfo(statusMapping[status])
  // }, [status])


  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };



  const employeeCountOptions = ['1-10', '10-100', '100-500', '500-1000'];
  const servicesOption = ['Dedicated car', 'Adhoc', 'Self-Drive', 'Shuttle', 'Employee Transport'];


  const languages = [
    { label: 'C', value: "1972" },
    { label: 'C#', value: "2000" },
    { label: 'C++', value: "1983" },
    { label: 'Clojure', value: "2007" },
    { label: 'Elm', value: "2012" },
    { label: 'Go', value: "2009" },
    { label: 'Haskell', value: "1990" },
    { label: 'Java', value: "1995" },
    { label: 'JavascriptJavascriptJavascriptJavascript', value: "1995" },
    { label: 'Perl', value: "1987" },
    { label: 'PHP', value: "1995" },
    { label: 'Python', value: "1991" },
    { label: 'Ruby', value: "1995" },
    { label: 'Scala', value: "2003" }
  ];




  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelect = (selected) => {
    setSelectedOptions(selected);
  };



  const [amount, setAmount] = useState(0);

  const handleAmountChange = (newAmount) => {
    setAmount(newAmount);
  };

  const boardData = {
    medium: generateQuoteMap(12),
    large: generateQuoteMap(12)
  };

  console.log(boardData)

  const [trip, setTrip] = useState({});
  const [clientBilling, setClientBilling] = useState({});
  const [supplierBillings, setSupplierBillings] = useState([]);
  const [billingEditState, setBillingEditState] = useState({
    visible: false,
    type: "",
    index: -1,
  });
  const isBillingStateVisible = (type, billingEditState, index) => {
    return (
      billingEditState?.visible &&
      billingEditState?.type == type &&
      billingEditState?.index == index
    );
  };

  const fetchData = async () => {
    let nTrip = null, cBilling = null, sBillings = null;
    try {
      const { booking: b, clientBilling: cB, supplierBillings: sB } = await BookingService.fetchTripAndBilling("660fb135ad447036bd1814e9");
      if (b) { nTrip = b; }
      if (cB) { cBilling = cB; }
      if (sB) { sBillings = sB }
    } catch (e) {
    } finally {
    }
    setTrip(nTrip || {});
    setClientBilling(cBilling /* || getDefaultClientBilling(editID) */);
    setSupplierBillings(sBillings || []);
  }

  useEffect(() => {
    fetchData();
  }, [])


  const { t } = useContext(I18nContext);
  return (
    <React.Fragment>
      <div className="page-content">

        <CustomerSelect
          label={"Customer Select"}
          driver
          value={data?.customer}
          onChange={(v) => setData({ ...data, customer: v?.value })}
          className="col-sm-12"
        />
        {data?.time}
        
        <TimeIntegerComponent
          label={"Included Mins (hh:mm)"}
          value={data?.time}
          onChange={(v) => {
            setData({ ...data, time: v });
          }}
        />




        <CityCountrySelect
          style={{ fontSize: '16px' }}
          value={data?.addressCity}
          onChange={(v) => {
            setData({
              ...data,
              addressCity: {
                ...(data?.addressCity ?? {}),
                city: v?.value,
                country: v?.country,
                state: v?.state,
                lat: v?.lat,
                lng: v?.lng,
                addressLine: v?.addressLine
              }
            })
          }}
        />

        {JSON.stringify(data?.addressCity)}
{/* 
        <Billing
          billingEditState={billingEditState}
          setBillingEditState={setBillingEditState}
          isBillingStateVisible={isBillingStateVisible}
          billing={clientBilling}
          index={0}
          loading={false}
          // onDelete={(e) => deleteClientBilling(e)}
          // onSave={(e) => {
          //   saveClientBilling(e);
          // }}
          onTripChange={(v) => {
            setTrip((prevTrip) => ({
              ...prevTrip,
              tripExtn: {
                ...prevTrip.tripExtn,
                requestedVehicleType: v,
              },
            }));
          }}
          onChange={setClientBilling}
          type="Client"
          trip={trip}
        /> */}


        {amount}
        <AmountField
          label="Enter Amount"
          value={amount}
          className={"col-3"}
          onChange={handleAmountChange}
        />

        <Board
          initial={boardData.large}
          withScrollableColumns
          onChange={() => { }}
        />

        <SelectOption
          options={employeeCountOptions}
          multi={false}
          onSelect={handleSelect}
          label='Employee Count'
        />
        <SelectOption
          options={servicesOption}
          multi={true}
          onSelect={handleSelect}
          label='Services'
        />
        <Card className="mx-0 px-2 pt-1">

          <br />
          <Row className=" ">
            <h3>{JSON.stringify(data)}</h3>
          </Row>

          <Row className=" ">
            <TagSelector
              kind={"employee"}
              onChange={(v) => {
                setData({ ...data, tags: v })
              }}
              value={data?.tags}
            // onlyBanners
            />
          </Row>

          <Row className=" ">
            <InputField
              label="Default Input Label"
              placeholder="Default Input Placeholder"
              value={data?.input}
              onChange={(v) => {
                setData({ ...data, input: v })
              }}
              required={true}
              className="col-sm-3"
            />

            <InputField
              label="Plain Input Label"
              placeholder="Plain Input Placeholder"
              value={data?.plain}
              plain
              onChange={(v) => {
                setData({ ...data, plain: v })
              }}
              className="col-sm-3"
            />

            <InputField
              label="Outline Input Label"
              placeholder="Outline Input Placeholder"
              value={data?.outline}
              onChange={(v) => {
                setData({ ...data, outline: v })
              }}
              outline
              className="col-sm-3"
            />
            <InputField
              label="Inline Input Label"
              placeholder="Inline Input Placeholder"
              value={data?.inline}
              onChange={(v) => {
                setData({ ...data, inline: v })
              }}
              inline
              className="col-sm-3"
            />
          </Row>

          <Row className=" ">
            <DateTimePickerWithInterval

              value={data?.dateTimeSelect}
              onChange={(v) => {
                setData({ ...data, dateTimeSelect: v })
              }}
              timestamp
              className="col-sm-3"
              placeholder="Default Input Placeholder"
              label="Label"
            />
            <DateTimePickerWithInterval
              plain
              value={data?.dateTimeSelect}
              onChange={(v) => {
                setData({ ...data, dateTimeSelect: v })
              }}
              timestamp
              className="col-sm-3"
              placeholder="Default Input Placeholder"
              label="Label"
            />
            <DateTimePickerWithInterval
              label="Outline Input Label"
              placeholder="Outline Input Placeholder"
              value={data?.dateTimeSelect}
              onChange={(v) => {
                setData({ ...data, dateTimeSelect: v })
              }}
              outline
              className="col-sm-3"
            />
            <DateTimePickerWithInterval
              label="Inline Input Label"
              placeholder="Inline Input Placeholder"
              value={data?.dateTimeSelect}
              onChange={(v) => {
                setData({ ...data, dateTimeSelect: v })
              }}
              inline
              className="col-sm-3"
            />



          </Row>

          <Row>
            <SelectField
              data={[
                { label: "Label 1", value: "Value 1" },
                { label: "Label 2 ", value: "Value 2" },
                { label: "Label 3 ", value: "Value 3" },
              ]}
              value={data?.defaultSelect}
              onChange={(v) => {
                setData({ ...data, defaultSelect: v.value });
              }}
              label={"Default Select"}
              className="col-sm-3"
            />


            <SelectField
              data={[
                { label: "Label 1", value: "Value 1" },
                { label: "Label 2 ", value: "Value 2" },
                { label: "Label 3 ", value: "Value 3" },
              ]}
              value={data?.plainSelect}
              onChange={(v) => {
                setData({ ...data, plainSelect: v.value });
              }}
              label={"Plain Select"}
              plain
              className="col-sm-3"
            />


            <SelectField
              data={[
                { label: "Label 1", value: "Value 1" },
                { label: "Label 2 ", value: "Value 2" },
                { label: "Label 3 ", value: "Value 3" },
              ]}
              value={data?.outlineSelec}
              onChange={(v) => {
                setData({ ...data, outlineSelec: v.value });
              }}
              outline
              label={"Outline Select"}
              className="col-sm-3"
            />


            <SelectField
              data={[
                { label: "Label 1", value: "Value 1" },
                { label: "Label 2 ", value: "Value 2" },
                { label: "Label 3 ", value: "Value 3" },
              ]}
              value={data?.inlineSelect}
              onChange={(v) => {
                setData({ ...data, inlineSelect: v.value });
              }}
              inline
              label={"Inline Select"}
              className="col-sm-3"
            />
          </Row>

          <Row className="d-flex  align-items-center">

            <GroupedSelectField
              data={groupedOptions}
              value={data?.groupedSelect}
              onChange={(v) => {
                setData({ ...data, groupedSelect: v?.value, groupedGroup: v?.groupValue });
              }}
              grouped
              label={"Grouped Options Select"}
              className="col-sm-3"
            />

            <SelectField
              data={[
                { label: "Top", value: "top" },
                { label: "Centerd", value: "centered" },
                { label: "Bottom", value: "bottom" },
              ]}
              value={data?.position}
              onChange={(v) => {
                setData({ ...data, position: v.value });
              }}

              label={"Modal Size"}

              className="col-sm-3"
            />

            <SelectField
              data={[
                { label: "Small", value: "small" },
                { label: "Large", value: "large" },
              ]}
              value={data?.size}
              onChange={(v) => {
                setData({ ...data, size: v.value });
              }}

              label={"Modal Position"}

              className="col-sm-3"
            />

            <Button style={{ height: "36px", width: "200px" }} className="col-sm-3 ms-2" onClick={toggleModal}>Open Modal</Button>
            <ModalComponent
              position={data?.position}
              size={data?.size}
              isOpen={modalOpen}
              onToggle={toggleModal}
            >
              <ModalComponent.Title>This is the Modal Title</ModalComponent.Title>
              <ModalComponent.Body>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
                <p>This is the modal body content.</p>
              </ModalComponent.Body>
              <ModalComponent.Footer>
                <button onClick={toggleModal}>Close</button>
              </ModalComponent.Footer>
            </ModalComponent>

          </Row>

          <hr />
          <h3>Module Select Fields</h3>
          <hr />
          <Row className=" ">
            <SalutationSelect
              className="col-sm-3"
              placeholder={("Enter salutation")}
              value={data.salutation}
              label={t("salutation")}
              onChange={(v) => {
                setData({ ...data, salutation: v.value })
              }}
              type="text"
            />
            <CurrencySelect
              placeholder={t("Enter Bank Currency ")}
              value={data.currency}
              label={t("Currency")}
              onChange={(v) => {
                setData({ ...data, currency: v.code })
              }}
              type="text"
              className="col-sm-3"
            />
            <LanguageSelect
              placeholder={t("Enter language ")}
              value={data.language}
              label={t("language")}
              onChange={(v) => {
                setData({ ...data, language: v.code })

              }}
              type="text"
              className="col-sm-3"
            />

            <CountrySelect
              placeholder={t("Enter country ")}
              value={data.CountrySelect}
              label={t("Country")}
              onChange={(v) => {
                setData({ ...data, CountrySelect: v.value })
              }}
              type="text"
              className="col-sm-3"
            />
          </Row>

          <Row>
            <OfficeSelect
              value={data?.office}
              onChange={(v) => setData({ ...data, office: v?.value })}
              className="col-sm-3"
            />
            <OfficeSelect
              value={data?.offices}
              multi
              onChange={(v) => setData({ ...data, offices: v })}
              className="col-sm-3"
            />

            <TaxInputField
              value={data?.totalAmount}
              onChange={(v) =>
                setData({
                  ...data,
                  totalAmount: v,
                })
              }
              isAbs={data?.isAbs}
              onChangeAbs={(v) => {
                setData({
                  ...data,
                  isAbs: v,
                })
              }}
              className="col-sm-3"
            />

            <TaxSelect
              value={data?.tax}
              onChange={(v) => setData({ ...data, tax: v?.value })}
              className="col-sm-3"
            />
          </Row>


          <Row className="">
            <StateSelect
              placeholder={t("Enter state ")}
              country={data?.CountrySelect}
              value={data.StateSelect}
              label={t("State")}
              onChange={(v) => {
                setData({ ...data, StateSelect: v.value })
              }}
              type="text"
              className="col-sm-3"
            />
            <CitySelect
              placeholder={t("Enter city ")}
              value={data.CitySelect}
              label={t("City")}
              country={data?.CountrySelect}
              state={data?.StateSelect}
              onChange={(v) => {
                setData({ ...data, CitySelect: v.value })

              }}
              type="text"
              className="col-sm-3"
            />

            <DateSelect
              placeholder={t("Enter date ")}
              value={data?.date}
              label={t("date")}
              onChange={(v) => {
                setData({ ...data, date: v })

              }}
              type="text"
              className="col-sm-3"
            />
            <PhoneNum
              placeholder={t("Enter PhoneNum ")}
              value={{
                countryCode: data?.PhoneNum?.countryCode,
                phone: data?.PhoneNum?.phone,
              }}
              label={"Phone"}
              className="col-sm-3"
              onChange={(v) => {
                setData({
                  ...data,
                  PhoneNum: {
                    ...data.PhoneNum,
                    countryCode: v?.countryCode,
                    phone: v?.phone,
                  }
                })
              }}
              type="text"
            />

          </Row>
          <Row className="">

            <ClientSelect
              value={data?.client}
              onChange={(v) => setData({ ...data, client: v?.value })}
              className="col-sm-3"
            />
            <ClientSelect
              value={data?.clients}
              onChange={(v) => setData({ ...data, clients: v })}
              multi
              className="col-sm-3"
            />
            <VehicleTypeSelect
              value={data?.vehicle}
              onChange={(v) => setData({ ...data, vehicle: v?.value })}
              className="col-sm-3"
            />
            <MakeModelSelect
              value={data?.makeModel}
              onChange={(v) => setData({ ...data, makeModel: v?.value })}
              className="col-sm-3"
            />

          </Row>
          <Row className="">

            
            <EmployeeSelect
              value={data?.employee}
              onChange={(v) => setData({ ...data, employee: v?.value })}
              className="col-sm-3"
            />


            <EmployeeSelect
              label={"Driver Select"}
              driver
              value={data?.driver}
              onChange={(v) => setData({ ...data, driver: v?.value })}
              className="col-sm-3"
            />
            <EmployeeSelect
              label={"Driver Select Multiple"}
              driver
              value={data?.drivers}
              multi
              onChange={(v) => setData({ ...data, drivers: v })}
              className="col-sm-3"
            />
            <DateTimeSelect
              // className="filterField_checkbox px-1 py-1"
              putline
              value={data?.dateTimeSelect}
              onChange={(v) => {
                setData({ ...data, dateTimeSelect: v })
              }}
              timestamp
              className="col-sm-3"

            />
            <DateTimeSelect
              // className="filterField_checkbox px-1 py-1"
              putline
              value={data?.dateTimeSelect}
              onChange={(v) => {
                setData({ ...data, dateTimeSelect: v })
              }}
              timestamp
              className="col-sm-3"

            />

          </Row>
          <Row className=" ">
            <EmployeeSelect
              label={"Guard Select"}
              type={2}
              guard
              value={data?.guard}
              onChange={(v) => setData({ ...data, guard: v?.value })}
              className="col-sm-3"
            />
            <EmployeeSelect
              label={"Guard Select Multiple"}
              type={2}
              value={data?.guards}
              guard
              multi
              onChange={(v) => setData({ ...data, guards: v })}
              className="col-sm-3"
            />

            <EmployeeSelect
              label={"Guide Select"}
              type={2}
              guide
              value={data?.guard}
              onChange={(v) => setData({ ...data, guard: v?.value })}
              className="col-sm-3"
            />

            <EmployeeSelect
              label={"Guide Select"}

              type={2}
              guide
              value={data?.guard}
              onChange={(v) => setData({ ...data, guard: v?.value })}
              className="col-sm-3"
            />
          </Row>
          <Row >
            <UserSelect
              value={data?.user}
              onChange={(v) => setData({ ...data, user: v?.value })}
              className="col-sm-3"
            />
            <div className="col-sm-6">
              <StatusDropDown
                status={status}
                statusMappingData={statusMapping}
                data={{
                  0: [
                    { label: "Confirmed", value: 1, color: "#2980B9" }
                  ],
                  1: [],
                  2: [
                    { label: "Dispatch", value: 3, color: "#FFBF00" },
                  ],
                  3: [
                    { label: "Completed", value: 5, color: "#52BE80" },
                    { label: "Cancelled", value: 6, color: "#F1948A" },
                  ],
                  4: [
                    { label: "Completed", value: 5, color: "#52BE80" }
                  ],
                  5: [
                    { label: "Cancelled", value: 6, color: "#F1948A" },
                  ],
                  6: [
                    { label: "Error", value: 7, color: "#CB4335" },
                  ],
                  7: []
                }}
                onChange={(v) => {
                  setStaus(v?.target?.id)
                }}
              />
            </div>
            {/* <div className="col-sm-3"> */}
            <AutoSuggestSelect
              label="Auto Suggest"
              required
              className="col-sm-3"
              data={languages}
              value={data?.autoSuggest}
              onChange={(event, { newValue }) => {
                console.log(newValue)
                setData({
                  ...data,
                  autoSuggest: newValue
                })
              }}
            />
            {/* </div> */}
          </Row>

          <Row className=" ">
            <SupplierSelect
              value={data?.supplier}
              onChange={(v) => setData({ ...data, supplier: v?.value })}
              className="col-sm-3"
            />
            <SupplierSelect
              value={data?.suppliers}
              multi
              onChange={(v) => setData({ ...data, suppliers: v })}
              className="col-sm-3"
            />

            <VehicleSelect
              value={data?.vehicle}
              onChange={(v) => setData({ ...data, vehicle: v?.value })}
              className="col-sm-3"
            />

            <VehicleSelect
              value={data?.vehicles}
              multi
              onChange={(v) => setData({ ...data, vehicles: v })}
              className="col-sm-3"
            />
          </Row>
          <Row className=" ">
            <GarageSelect
              value={data?.garage}
              onChange={(v) => setData({ ...data, garage: v?.value })}
              className="col-sm-3"
            />
            <GarageSelect
              value={data?.garages}
              multi
              onChange={(v) => setData({ ...data, garages: v })}
              className="col-sm-3"
            />

            <ContactPersonSelect
              value={data?.contactPerson}
              onChange={(v) => setData({ ...data, contactPerson: v?.value })}
              className="col-sm-3"
            />
            <ContactPersonSelect
              value={data?.contactPersons}
              multi
              onChange={(v) => setData({ ...data, contactPersons: v })}
              className="col-sm-3"
            />
          </Row>
          <Row>
            <Row>
              {JSON.stringify(data?.multiDate)}
              <MultiDatePickerCalendar
                minDate={moment().toDate()}
                value={data?.multiDate}
                onChange={(v) => {
                  console.log(v)
                  setData({ ...data, multiDate: v })
                }}
              />
            </Row>

          </Row>
          <Row className=" ">
            <AccountSelect
              value={data?.account}
              onChange={(v) => setData({ ...data, account: v?.value })}
              className="col-sm-3"
            />

            <AccountSelect
              value={data?.accounts}
              multi
              onChange={(v) => setData({ ...data, accounts: v })}
              className="col-sm-3"
            />

            <ClientPoolSelect
              value={data?.clientPool}
              onChange={(v) => setData({ ...data, clientPool: v?.value })}
              className="col-sm-3"
            />
            <ClientPoolSelect
              value={data?.clientPools}
              multi
              onChange={(v) => setData({ ...data, clientPools: v })}
              className="col-sm-3"
            />
          </Row>
          <Row className=" ">
            <CompanySelect
              value={data?.entity}
              corpType="self"
              onChange={(v) => setData({ ...data, entity: v?.value })}
              className="col-sm-3"
            />

            <InvoiceSelect
              value={data?.invoice}
              onChange={(v) => setData({ ...data, invoice: v?.value })}
              className="col-sm-3"
            />
            {/* <CompanySelect
              value={data?.entityLabel}
              valueLabel
              label={"Company Value"}
              onChange={(v) => setData({ ...data, entityLabel: v?.value, entityId: v?.valueId })}
              className="col-sm-3"
            />
            {data?.entityLabel}
            {data?.entityId} */}
            <CompanySelect
              value={data?.entities}
              corpType="self"
              multi
              onChange={(v) => setData({ ...data, entities: v })}
              className="col-sm-3"
            />
            <RoleSelect
              value={data?.role}
              onChange={(v) => setData({ ...data, role: v?.value })}
              className="col-sm-3"
            />
            <RoleSelect
              value={data?.roles}
              onChange={(v) => setData({ ...data, roles: v })}
              multi
              className="col-sm-3"
            />
            <ClientCompany
              value={data?.companies}
              onChange={(v) => setData({ ...data, companies: v })}
              className="col-sm-3"
            />

            <RateVehicle
              value={data?.rateVehicle}
              onChange={(v) => setData({ ...data, rateVehicle: v })}
              className="col-sm-3"
            />

            <SupplierCompany
              value={data?.suppliersCompanies}
              onChange={(v) => setData({ ...data, suppliersCompanies: v })}
              className="col-sm-3"
            />

            <EmployeeSelect
              value={data?.employees}
              multi
              onChange={(v) => setData({ ...data, employees: v })}
              className="col-sm-3"
            />
          </Row>
          <Row>

            <AddressField
              placeholder={t("Enter Address ")}
              value={{
                city: data?.addressField?.city,
                state: data?.addressField?.state,
                country: data?.addressField?.country,
                addressLine: data?.addressField?.addressLine,
              }}
              label={"Address"}
              className="col-sm-6"
              onChange={(v) => {
                setData({
                  ...data,
                  addressField: {
                    ...data.addressField,
                    city: v?.city,
                    addressLine: v?.addressLine,
                    country: v?.country,
                    state: v?.state,
                  }
                })
              }}
            />
          </Row>
          <Row className=" ">
            <CurrencyAmountField
              className="col-sm-6"
              value={
                {
                  amount: data?.amount,
                  currency: data?.currency,
                }
              }
              label="Amount"
              onChange={(v) => {
                setData({
                  ...data,
                  amount: v?.amount,
                  currency: v?.currency,
                })
              }}
            />

            <NameField
              className="col-sm-6"
              onNameChange={(v) => setData({ ...data, fullName: v })}
              onSalutationChange={(v) => setData({ ...data, salutation: v })}
              name={data?.fullName}
              salutation={data?.salutation}
              label="Name"
            />

          </Row>
          <Row className=" ">

            <TemplateSelect
              value={data?.templates}
              onChange={(v) => setData({ ...data, templates: v?.value })}
              className="col-sm-6"
            />


          </Row>

          <Row>


            <ToggleSelector
              data={[
                { label: "For Download", value: "forDownload" },
                { label: "For Upload", value: "forUpload" },
                { label: "Option Three", value: "optionThree" },
              ]}
              value={
                data?.forUpload
                  ? "forUpload"
                  : data?.optionThree
                    ? "optionThree"
                    : "forDownload"
              }
              onChange={(selectedValue) => {
                const updatedData = { ...data };

                if (selectedValue === "forUpload") {
                  updatedData.forUpload = true;
                  updatedData.optionThree = false;
                  updatedData.forDownload = false;
                } else if (selectedValue === "optionThree") {
                  updatedData.forUpload = false;
                  updatedData.optionThree = true;
                  updatedData.forDownload = false;
                } else {
                  updatedData.forUpload = false;
                  updatedData.optionThree = false;
                  updatedData.forDownload = true;
                }

                setData(updatedData);
              }}
            />
          </Row>
          <Row>
            <AutoSuggestField
              suggestData={suggestData}
              value={data?.address}
              onChange={(v) => setData({ ...data, address: v })}
            />
          </Row>
          <div role="tooltip"
            style={{ top: "1193px" }}
            className="react-tooltip core-styles-module_tooltip__3vRRp styles-module_tooltip__mnnfp styles-module_dark__xNqje react-tooltip__place-top react-tooltip__show core-styles-module_show__Nt9eE"
          >
            <div>Verification Code: 1518 - Hello User, you requested a login. Please enter the above code to continue. Do not
              share this code with anyone. Code validity: 15 minutes. Thank you! Regards, LogicFirst.io.</div>
            <div className="react-tooltip-arrow core-styles-module_arrow__cvMwQ styles-module_arrow__K0L3T"
            ></div>
          </div>
          <Tooltip anchorSelect="#not-clickable">
            <div>Driver</div>
          </Tooltip>
          <div>
            <span id="not-clickable" >
              <img height={20} src={guideIcon} alt="Driver" />
            </span>
          </div>


          <DraggableBox
            value={
              dragData
            }
            onChange={(v) => {
              setDragData(v)
            }}
          />
        </Card>
      </div>
    </React.Fragment>
  );
};

export default observer(DemoV2);
