import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DialogContext } from "../../../../store/context/DialogContext";
import { I18nContext } from "../../../../store/context/i18nContext";
import { DeleteButton, InputField, ModalComponent, SaveButton, Tab } from "../../../index";
import ItineraryLeadService from "./ItineraryLeadService";
import DayNightPicker from "./components/DayNightPicker";
import ItineraryStatusPicker from "./components/ItineraryStatusPicker";
import ItineraryStayPicker from "./components/ItineraryStayPicker";
import SupplierFare from "./components/SupplierFare";
import "./style.scss";
import RichTextEditor from "./components/RichTextEditor";
import FeeGroup from "./components/SupplierFare/FeeGroup";
import { Card } from "reactstrap";
import EditItinerary from "../../../../pages/Itinerary/EditItinerary";

const startingData = {
    status: 0,
    days: 2, nights: 1,
    itineraryExtn: {
        stays: [{ hotelType: '1', roomType: 'singleRoom' }],
        detail: '',
    },
}

const ItineraryForm = ({
    onEdit = () => { },
    editId,
    leadId,
    isOpen,
    toggleModal,
}) => {

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [data, setData] = useState(startingData);

    const fetch = async () => {
        try {
            setLoading(true)
            let itineraryDetail = await ItineraryLeadService.get(editId);
            setData(itineraryDetail);
            setLoading(false)
        } catch (error) {
            showError(error)
            setLoading(false)

        }
    };

    const onSave = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            if (data?._id) {
                const lead = await ItineraryLeadService.edit(data, data?._id);
                setData(lead)
                setLoading(false);
                showMessage(t("Itinerary updated successfully."));
                onEdit()
            } else {
                const lead = await ItineraryLeadService.save({
                    ...data,
                    lead_id: leadId,
                    itineraryExtn: {
                        ...data?.itineraryExtn,
                        // segments: [{ title: data?.itineraryExtn?.detail }]
                    }
                });
                setData(lead)
                setLoading(false);
                showMessage(t("Itinerary saved successfully."));
                onEdit()
            }
        } catch (e) {
            setLoading(false);
            showError(e);
        }
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await ItineraryLeadService.delete(id);
                setLoading(false);
                showMessage("Itinerary Deleted SuccessFully", "Deleted");
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };

    // useEffect(() => {
    //     console.log({ isOpen, editId })
    //     if (isOpen) {
    //         setLoading(true);
    //         if (editId) {
    //             fetch();
    //         } else {
    //             setData(startingData);
    //         }
    //         setLoading(false)
    //     }
    // }, [editId, isOpen]);

    const handleDetailChange = (content) => {
        console.log({ data, content })
        setData({
            ...data,
            itineraryExtn: {
                ...(data?.itineraryExtn ?? {}),
                detail: content,
            },
        });
    };


    return (
        <React.Fragment>
            <ModalComponent
                position={"top"}
                size={"large"}
                isOpen={isOpen}
                onToggle={toggleModal}
            >

                {/* <ModalComponent.Title>
                    <div style={{ fontSize: "12px", }} className="d-flex align-items-center">
                        <DayNightPicker
                            value={{ days: data?.days ?? 2, nights: data?.nights ?? 1 }}
                            onChange={(v) => setData({ ...data, days: v?.days, nights: v?.nights })}
                        />

                        <ItineraryStatusPicker
                            value={data?.status}
                            onChange={(v) => setData({ ...data, status: v, })}
                        />

                        <ItineraryStayPicker
                            value={data?.itineraryExtn?.stays?.[0]}
                            onChange={(v) => setData({ ...data, itineraryExtn: { ...data?.itineraryExtn, stays: [v] }, })}
                        />
                    </div>

                </ModalComponent.Title> */}

                <ModalComponent.Body>
                    <div className="mb-4">


                        {
                            !editId ?
                                <div>
                                    <EditItinerary
                                        editId={editId}
                                        onChange={(itinerary) => {
                                            setData({
                                                ...data,
                                                ...(itinerary ?? {})
                                            })
                                        }}
                                    />
                                </div> :
                                <Tab
                                    vertical={false}
                                    left
                                    activeTab={step}
                                    onChange={(data) => {
                                        setStep(data);
                                    }}
                                    className="d-flex"
                                >
                                    <div num={1} label={t("Itinerary Details")} className="d-flex">
                                        <EditItinerary
                                            editId={editId}
                                            insidePane
                                            onChange={(itinerary) => {
                                                setData({
                                                    ...data,
                                                    ...(itinerary ?? {})
                                                })
                                            }}
                                        />
                                    </div>

                                    <div num={2} label={t("Client Charges")} className="d-flex">
                                        <div className="mt-3 row">
                                            <div className="col-12">
                                                {<Card className=" ">
                                                    <div className="px-2">
                                                        <FeeGroup
                                                            onChange={(updatedFeeData) => {
                                                                setData({
                                                                    ...data,
                                                                    itineraryExtn: {
                                                                        ...data?.itineraryExtn,
                                                                        clientFee: updatedFeeData[0]
                                                                    }
                                                                });
                                                            }}
                                                            feeGroups={[data?.itineraryExtn?.clientFee]}
                                                        />
                                                    </div>
                                                </Card>}
                                            </div>


                                        </div>
                                    </div>

                                    <div num={3} label={t("Supplier Charges")} className="d-flex">
                                        <div className="mt-3 row">
                                            {editId && (
                                                <div className="col-12">
                                                    <Card className=" pb-3 ">
                                                        <div style={{ backgroundColor: "#EBEBEB" }} className="h3 ps-3 py-2"> Supplier Charges</div>
                                                        {editId && <div className="px-2 ">
                                                            <SupplierFare
                                                                itinerary_id={editId}
                                                            />
                                                        </div>}
                                                    </Card>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                </Tab>}

                    </div>

                </ModalComponent.Body>

                <ModalComponent.Footer>
                    <div className="d-flex justify-content-end">
                        <DeleteButton onClick={(e) => onDelete(e, editId)} loading={loading} />
                        <SaveButton loading={loading} onClick={onSave} disabled={loading} />

                    </div>
                </ModalComponent.Footer>

            </ModalComponent>



        </React.Fragment >
    );
};

export default observer(ItineraryForm);
