import { ClientSelect, OfficeSelect, SupplierSelect } from "../../components";
export const GridColumns = ({ isClient, onlySelect }) => {
  let conditionalColunn = isClient ? {
    title: "Client",
    accessor: "client_id",
    renderer: (dataObj) => {
      return (
        <div>
          <ClientSelect
            asLabel
            value={dataObj?.client_id}
            onChange={(v) => { }}
            className=""
          />
        </div>
      );
    },
    show: true,
  } :
    {
      title: "Supplier",
      accessor: "supplier_id",
      renderer: (dataObj) => {
        return (
          <div>
            <SupplierSelect
              asLabel
              value={dataObj?.supplier_id}
              onChange={(v) => { }}
              className=""
            />
          </div>
        );
      },
      show: true,
    };

  return {
    version: 1,
    columns: [
      conditionalColunn,
      {
        title: "Company",
        accessor: "fullName",
        show: true,
      },
      // {
      //   title: "Brand Name",
      //   accessor: "name",
      //   show: true,
      // },
      // {
      //   title: "Time Zone",
      //   accessor: "timezone",
      //   show: true,
      // },
      // {
      //   title: "Currency",
      //   accessor: "currency",
      //   show: true,
      // },
      // {
      //   title: "Tax/Gst ID",
      //   renderer: (dataObj) => {
      //     return <div>
      //       {dataObj?.companyExtn?.taxId}
      //     </div>;
      //   },
      //   show: true,
      // },
      {
        title: "Action",
        accessor: "action",
        fixed: "right",
        width: onlySelect ? 0 :90,
        show: true,
        required: true,
      },
    ],
  }
};
