import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { ThreeBounce } from "better-react-spinkit";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./AddonConstant";
import { GridColumns } from "./AddonGridColumn";
import AddonService from "./AddonService";
import EditAddon from "./EditAddon";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";

const Addons = ({ insidePane }) => {
  let { edit_id } = useParams();
  const location = useLocation();
  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/addon/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const { userContent } = useContext(UserContext);

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await AddonService.fetch(filterUrl);
      setLoading(false);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
  }, [])
  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])
  const onSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (editID) {
        await AddonService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Addon updated successfully."));
      } else {
        const AddonID = await AddonService.save(detailData);
        setLoading(false);
        showMessage(t("Addon saved successfully."));
        if (!insidePane) navigate(`/addon/edit/${AddonID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  };
  // console.log("==================", AddonID)
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await AddonService.delete(id);
        setLoading(false);
        setShowDetailPage(false);
        showMessage(t("Addon Deleted "), t("Deleted"));
      } catch (e) {
        showError(e);
        setLoading(false);
      }
    }
  };
  return (
    <React.Fragment>
      <>
        <Layout
          hideAdd={!userContent?.rights?.includes(3801)}
          medium
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/addon");
            setEditID(null);
          }}
           
          title={t("Addon")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/addon/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={AddonService.page}
          rows={AddonService.rows}
          total={AddonService.total}
          fetch={AddonService.fetch}
        >
          <Layout.Table>
            <>
              <DataGrid
              gridLoading={loading}
                data={AddonService.records}
                total={AddonService.total}
                uiPreference="addon.grid"
                headers={GridColumns}
                selectedIDs={selectedIDs}
                onSelectChange={setSelectedIDs}
                page={AddonService.page}
                rowsPerPage={AddonService.rowsPerPage}
                onPaginationChange={AddonService.onPaginationChange}
                renderLastCol={(addon) => {
                  return (
                    <>
                      <EditLink
                        onClick={() => {
                          if (!insidePane)
                            navigate(`/addon/edit/${addon?._id}`);
                          setEditID(addon?._id);
                          setShowDetailPage(true);
                        }}
                      />
                      {userContent?.rights?.includes(3803) ?
                        <DeleteLink
                          onClick={(event) => onDelete(event, addon?._id)}
                        />
                        : null}
                    </>
                  );
                }}
              />
            </>
          </Layout.Table>
          <Layout.DetailPageTitle>
            {detailData?._id ? t("Edit Addon") : t("Add Addon")}
            {/* {detailData?._id ? t("Edit ") : t("Add")}&nbsp;{t("Addon")} */}
          </Layout.DetailPageTitle>
          <Layout.DetailPageBody>
            <EditAddon
              editId={editID}
              onChange={(v) => {
                setDetailData(v)
              }}
            />
          </Layout.DetailPageBody>
          <Layout.DetailPageFooter>
            {detailData?._id ? (
              <AuditLogDialog module="Addon" id={detailData?._id} />
            ) : null

            }
            {userContent?.rights?.includes(3803) && detailData?._id ?
              <DeleteButton
                loading={loading}
                onClick={(e) => onDelete(e, detailData?._id)} />
              : null}
            {userContent?.rights?.includes(3802) ?
              <SaveButton
                loading={loading}
                onClick={onSave} />
              : null}
          </Layout.DetailPageFooter>
        </Layout>
      </>
    </React.Fragment>
  );
};
export default observer(Addons);
