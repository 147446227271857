import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import { CardThreeBounce, DeleteLink, InputField } from "../../components";
import TaxSlabService from "./TaxSlabService";

const EditTaxSlab = (props) => {
  const [loading, setLoading] = useState(false);
  const { id, data, setData, hasErr, setHasErr } = props;
  async function fetch() {
    if (id) {
      const taxslab = await TaxSlabService.get(id);
      setData(taxslab);
    } else {
      setData({ name: "", amount: 0, desc: "" });
    }
    setLoading(false);
  }
  useEffect(() => {
    setLoading(true);
    fetch();
    setHasErr(false);
  }, [id]);

  if (loading) return <CardThreeBounce />;

  return (
    <div>
      <div className="p-0">
        <Form
          className="p-3"
          style={{
            overflowY: "auto",
            height: "100%",
            overflowX: "hidden",
          }}
        >
          <Row>
            <InputField
              value={data?.name}
              className="col-sm-6 sm-mt-0 mt-3"
              required={true}
              label={"Name"}
              onChange={(v) => {
                setData({ ...data, name: v });
              }}
              error={data?.name?.length === 0 ? "Please enter name" : ""}
              showErr={hasErr}

              type="text"
            />

            <Col className="mt-3 d-flex col-sm-6">
              <InputField
                className="col-sm-10"
                value={data?.amount}
                required={true}
                label={"Amount"}
                onChange={(v) => {
                  setData({ ...data, amount: v });
                }}
                showErr={hasErr}

                type="number"
              />

              <button
                style={{ fontSize: "10px" }}
                className=" col-sm-1"
                className={`btn ${!data?.isAbs ? "btn-info" : "btn-warning"}`}
                type="button"
                onClick={() => setData({ ...data, isAbs: !data?.isAbs })}
              >
                {data?.isAbs ? "-/" : "%"}
              </button>
            </Col>
          </Row>

          <Col className="py-2">
            <InputField
              value={data?.desc}
              label={"Description"}
              onChange={(v) => setData({ ...data, desc: v })}
              error={data?.desc?.length === 0 ? "Please enter description" : ""}
              showErr={hasErr}

              type="text"
            />
          </Col>

          <Row className="py-2">
            <Col>
            
            </Col>
            <Col>
              
            </Col>
          </Row>
          <TaxItems data={data} setData={setData} hasErr={hasErr} />
        </Form>
      </div>
    </div>
  );
};

var TaxItems = ({ data, setData, hasErr, percentage, setPercentage }) => {
  return (
    <React.Fragment>
      <Row className="py-2 d-flex">
        <div className="d-flex justify-content-end">
          <Col className="justify-content-end">

            <Button
              type="button"
              className="btn mx-2 btn-primary"
              onClick={() => {
                setData({
                  ...data,
                  taxItems: [
                    ...(data?.taxItems ? data?.taxItems : []),
                    { title: "", amount: 0, desc: "" },
                  ],
                });
              }}
            >
              Add TaxItems
              {/* <i style={{ fontSize: "18px" }} className="uil-plus"></i> */}
            </Button>
          </Col>
        </div>

      </Row>
      <Row className="my-2">
        {data?.taxItems?.map((item, idx) => {
          return (
            <>

              <Row className="my-2" key={item.name}>
                <Col className="col-3">
                  <InputField
                    value={item.title}
                    label={"Tax Title"}
                    onChange={(val) => {
                      var items = data.taxItems;
                      items[idx].title = val;
                      setData({ ...data, taxItems: items });
                    }}
                    error={item.name?.length === 0 ? "Enter tax Title" : ""}
                    showErr={hasErr}

                    type="text"
                    required={true}
                  />
                </Col>
                <Col className=" col-3">
                  <InputField
                    value={item.amount}
                    label={"Tax Amount"}
                    onChange={(val) => {
                      var items = data.taxItems;
                      items[idx].amount = val;
                      setData({ ...data, taxItems: items });
                    }}
                    showErr={hasErr}

                    type="number"
                  />
                </Col>
                <Col className="col-1 ">
                  <button
                    style={{ fontSize: "10px" }}
                    className="col-sm-1 font-size-14"
                    className={`btn ${!item.isAbs ? "btn-info" : "btn-warning"}`}
                    type="button"
                    onClick={() => {
                      var items = data.taxItems;
                      items[idx].isAbs = !items[idx].isAbs;
                      setData({ ...data, taxItems: items });
                    }}
                  >
                    {item.isAbs ? "-/" : "%"}
                  </button>
                </Col>
                <Col className="col-4">
                  <InputField
                    value={item.desc}
                    label={"Tax Description"}
                    onChange={(val) => {
                      var items = data.taxItems;
                      items[idx].desc = val;
                      setData({ ...data, taxItems: items });
                    }}
                    // error={item.desc?.length === 0 ? "Enter tax Description" : ""}
                    showErr={hasErr}

                    type="text"
                  />
                </Col>
                <Col className="col-1">
                  <DeleteLink
                    onClick={() => {
                      setData({
                        ...data,
                        taxItems: data.taxItems.filter((elem, ind) => {
                          return ind !== idx;
                        }),
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          );
        })}
      </Row>
    </React.Fragment>
  );
};

export default observer(EditTaxSlab);
