import React, { useContext, useEffect, useState } from "react";
import { AddButton, SelectField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doDELETE, doGET, doPOST } from "../../util/HttpUtil";
import PrimaryButton from "../Buttons/PrimaryButton";
import DateSelectCustom from "../DateTimePickerWithInterval/DateSelectCustom";
import DropZone from "./Dropzone";

const DocField = ({
  key = 1,
  field_id,
  onDelete = () => { },
  value,
  onChange = () => { },
  data = { attachment: { name: "", info: "", refNo: "", urls: [] }, version: 0 },
  record_id = "",
  getRecordId = async () => { },
  module = "",
  inline,
  asLabel,
  showDetail,
  handleAddCard,
  handleDeleteCard,
  documentIndex,
  actionButtons,
  documentLength,
  multiple,
  onSubmit = () => { },
  config = { showCategory: false, showExpiry: false },
}) => {
  const { showCategory = true, showExpiry = true } = config;
  const { showError, showMessage, showConfirm } = useContext(DialogContext);
  const { t } = useContext(I18nContext);
  const [document, setDocument] = useState(data);
  const [documentCategories, setDocumentCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [loading, setLoading] = useState(false);
  const subCategoryMap = new Map();

  useEffect(() => {
    const fetchDocumentCategories = async () => {
      try {
        const response = await doGET(`/api/doc-category/grid?module=${module}&rows=-1`);
        if (response.status === 200) {
          const categories = transformData(response.data.rows);
          setDocumentCategories(categories);
        }
      } catch (error) {
        showError(error);
      }
    };
    fetchDocumentCategories();
  }, [module, showError]);

  useEffect(() => {
    const category = documentCategories.find((cat) => cat.value === document.category_id);
    setSelectedCategory(category || {});
  }, [document.category_id, documentCategories]);

  useEffect(() => {
    if (value) fetchDocumentDetails();
  }, [value]);

  const fetchDocumentDetails = async () => {
    try {
      const response = await doGET(`/api/file/${value}/detail`);
      if (response.status === 200) setDocument(response.data);
    } catch (error) {
      showError(error);
    }
  };

  const handleFieldChange = (field, fieldValue) => {
    setDocument((prevDoc) => {
      const updatedDocument = { ...prevDoc, [field]: fieldValue };
      handleSaveDocument(updatedDocument);
      return updatedDocument;
    });
  };

  const handleSaveDocument = async (doc) => {
    setLoading(true);
    const formData = new FormData();
    const formUrls = [];
    let recordId = record_id || (await getRecordId());

    if (needsExpiryDate(doc, selectedCategory)) {
      showError("Please Add Expiry Date");
      setLoading(false);
      return;
    }

    prepareFormData(doc, formData, formUrls);
    try {
      const response = await doPOST(`/api/file/upload`, formData);
      if (response.status === 200) {
        showMessage("Successfully Uploaded");
        setDocument({ ...response.data, expanded: false });
        onChange(response.data._id);
        onSubmit();
      }
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const needsExpiryDate = (doc, category) => {
    return (
      (doc?.subCategory_id && category?.subCategories?.find((subCat) => subCat._id === doc.subCategory_id)?.hasExpiry && !doc.expiry) ||
      (!doc.subCategory_id && category.hasExpiry && !doc.expiry)
    );
  };

  const prepareFormData = (doc, formData, formUrls) => {
    doc.attachment.urls.forEach((url, i) => {
      if (url.key?.slice(1, 13) === "api/o/assets") {
        formUrls.push(url);
      } else {
        formData.append(`file-${i + 1}`, url);
      }
    });

    const docObject = {
      issuer: doc.issuer,
      category_id: doc.category_id,
      subCategory_id: doc.subCategory_id,
      expiry: doc.expiry,
      record_id,
      module,
      version: doc.version,
      attachment: { name: doc.attachment.name, info: doc.attachment.info, refNo: doc.attachment.refNo, urls: formUrls },
      ...(data._id && { _id: data._id }),
      ...(field_id && { field_id }),
    };

    formData.append("data", JSON.stringify(docObject));
  };

  const handleDelete = async () => {
    if (document.attachment.urls.length > 0) {
      const confirmed = await showConfirm({
        title: t("Do you want to delete file?"),
        description: t("This is an unrecoverable operation."),
      });
      if (confirmed) {
        try {
          const response = await doDELETE(`/api/file/${data._id}/delete`);
          if (response.status === 200) {
            showMessage("Deleted", "Deleted");
            onDelete();
          }
        } catch (e) {
          showError(e);
        }
      }
    } else {
      handleDeleteCard();
    }
  };

  const handleAttachmentDelete = async (index) => {
    const confirmed = await showConfirm({
      title: t("Do you want to delete file?"),
      description: t("This is an unrecoverable operation."),
    });
    if (confirmed) {
      try {
        const response = await doDELETE(`/api/file/delete/attachment`, {
          record_id,
          _id: document._id,
          module,
          index,
        });
        if (response.status === 200) {
          setDocument({ ...response.data, expanded: true });
          showMessage("Deleted", "Deleted");
          return true;
        }
      } catch (e) {
        showError(e);
      }
    }
    return false;
  };

  const transformData = (response) => {
    const map = new Map();
    const result = [];

    response.forEach((item) => {
      if (item.parent_id) {
        const children = map.get(item.parent_id) || [];
        children.push({ ...item, value: item._id, label: item.name });
        map.set(item.parent_id, children);
      }
    });

    response.forEach((item) => {
      if (!item.parent_id) {
        result.push({
          ...item,
          label: item.name,
          value: item._id,
          subCategories: map.get(item._id) || [],
        });
        subCategoryMap.set(item._id, map.get(item._id) || []);
      }
    });

    return result;
  };

  return (
    <div key={key}>
      <div className="position-relative">
        <div>
          <div className="d-flex flex-column">
            {showDetail && (
              <textarea
                className="col-sm-12 p-2 inputfield__innercontainer"
                style={{ borderColor: "hsl(0, 0%, 80%)", outline: "none" }}
                rows="5"
                placeholder="Details"
                value={document.attachment.info}
                onChange={(e) => setDocument({ ...document, attachment: { ...document.attachment, info: e.target.value } })}
              />
            )}
          </div>
          <div className="d-flex align-items-center my-0 py-0">
            {showCategory && (
              <>
                <div className="col-sm-4 me-0">
                  <SelectField
                    rightBorderNone
                    placeholder={t("Select Category")}
                    data={documentCategories}
                    value={document?.category_id}
                    required
                    label={t("Category")}
                    onChange={(v) => setDocument({ ...document, category_id: v?.value, subCategory_id: null })}
                    innerContainerStyle={{ borderRadius: selectedCategory.subCategories?.length > 0 ? "8px 0 0 8px" : "" }}
                    className="my-3"
                  />
                </div>
                {selectedCategory.subCategories?.length > 0 && (
                  <div className="col-sm-4 ms-0">
                    <SelectField
                      leftBorderNone
                      outline
                      placeholder={t("Select Sub Category")}
                      data={selectedCategory?.subCategories}
                      value={document?.subCategory_id}
                      label={t("Sub Category")}
                      onChange={(v) => setDocument({ ...document, subCategory_id: v.value })}
                      innerContainerStyle={{ borderRadius: "0 8px 8px 0" }}
                      className="my-3"
                    />
                  </div>
                )}
              </>
            )}
            {showExpiry && (
              <div className="col-sm-4 ms-1 mt-1">
                <DateSelectCustom
                  placeholder="dd-mm-yyyy"
                  label="Expiry Date"
                  className=" col-4"
                  value={document?.expiry}
                  required={
                    document.subCategory_id
                      ? selectedCategory.subCategories?.some((subCat) => subCat._id === document.subCategory_id && subCat.hasExpiry)
                      : selectedCategory.hasExpiry
                  }
                  onChange={(v) => setDocument({ ...document, expiry: v })}
                />
              </div>
            )}
          </div>
          <div className="m-0" style={{ cursor: "pointer" }}>
            <DropZone
              asLabel={asLabel}
              value={document?.attachment?.urls}
              inline={inline}
              onDeleteAttachment={handleAttachmentDelete}
              onChange={(urls) => handleFieldChange("attachment", { ...document.attachment, urls })}
            />
          </div>
          {actionButtons && multiple && (
            <div className="d-flex justify-content-end my-3 me-1">
              <PrimaryButton loading={loading} className="me-2 btn-outline-pink waves-light" onClick={handleDelete} style={{ height: "30px" }}>
                {t("Delete")}
              </PrimaryButton>
              {documentIndex === documentLength - 1 && <AddButton onClick={handleAddCard} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocField;
