

import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { CitySelect, CountrySelect, InputField } from "../../../../components";
import TransferGroupService from "../TrasnferGroupService";




export default function Desitination({ data, setData }) {
    const [hasErr, setHasErr] = useState(false);
    const [address, setaddress] = useState("")

    const [destination, setdestination] = useState(data?.options ? data?.options : [])
    useEffect(() => {
        if (JSON.stringify(data?.options) !== JSON.stringify(destination))
            setData({ ...data, options: destination });
    }, [destination]);

    const addressList = async () => {
        const response = await TransferGroupService.getAddress(address)

        console.log(response, "response inside addressList")
    }

    useEffect(() => {
        addressList()

    }, [address])

    const changeAddress = (v, s, i) => {

        console.log(s, "sss")

        setdestination(
            destination.map((f, x) => {
                if (i === x) return {
                    ...s, dst: {
                        ...s?.dst,
                        addressLine: v
                    }
                };
                return f;
            })
        )

        console.log(s?.src?.addressLine, "s?.src?.addressLine")
        setaddress(v)


    }


    return (

        <>
            <Row className="d-flex justify-content-end ">
                <Button
                    className="col-sm-2"
                    onClick={() => setdestination([...destination, {}])}
                >
                    Add Destination +
                </Button>
            </Row>

            {destination.map((s, i) => (
                <Row className="border-bottom border-dark pt-2">

                    <div className="" onClick={() => {
                        setdestination(() => {
                            let newData = destination.slice()
                            newData.splice(i, 1)
                            return newData
                        })
                    }} style={{ cursor: "pointer", position: "relative" }}>
                        <i style={{ position: "absolute", right: 0, fontSize: "25px" }} className="dripicons-cross" />
                    </div>

                    <Row className='mt-4 mb-3'>
                        <Col className="col-lg">
                            <InputField
                                identifier={i}
                                placeholder="Address Line"
                                value={s?.dst?.addressLine}
                                label={"Address Line"}
                                onChange={(v) => changeAddress(v, s, i)}
                                type="text"

                            />
                        </Col>
                    </Row>
                    <Row className='mt-2 mb-3'>
                        <Col className="col-lg">
                            <CountrySelect
                                showErr={hasErr}
                                value={s?.dst?.country}

                                onChange={(v) =>
                                    setdestination(
                                        destination.map((f, x) => {
                                            if (i === x) return {
                                                ...s, dst: {
                                                    ...s?.dst,
                                                    country: v.value
                                                }
                                            };
                                            return f;
                                        })
                                    )
                                }
                            />
                        </Col>
                        <Col className="col-lg">
                            <CitySelect
                                showErr={hasErr}
                                value={s?.dst?.city}

                                onChange={(v) =>
                                    setdestination(
                                        destination.map((f, x) => {
                                            if (i === x) return {
                                                ...s, dst: {
                                                    ...s?.dst,
                                                    city: v.value
                                                }
                                            };
                                            return f;
                                        })
                                    )
                                }
                                country={s?.dst?.city}

                            />
                        </Col>




                    </Row>
                </Row>
            ))}
        </>

    );
}
