import React, { useEffect, useRef, useState } from 'react';
import { FaBan, FaCheckCircle, FaShareSquare, FaTimesCircle } from 'react-icons/fa';
import { useOutsideAlerter } from '../../../../board/helper';
import './index.scss';
import { observer } from 'mobx-react-lite';

const statusOptions = [
    { key: 0, label: "Not Ready", icon: FaBan, color: "#808080" }, 
    { key: 1, label: "Ready/Shared", icon: FaShareSquare, color: "#007bff" }, 
    { key: 3, label: "Accepted", icon: FaCheckCircle, color: "#28a745" }, 
    { key: 4, label: "Rejected", icon: FaTimesCircle, color: "#dc3545" },
];

const ItineraryStatusPicker = ({ 
    noToggle = false,
    value, 
    onChange 
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    useOutsideAlerter(dropdownRef, () => setIsOpen(false));

    const handleSelect = (key) => {
        onChange(key);
        setIsOpen(false);
    };


    const selectedOption = statusOptions.find(option => option.key === value);

    return (
        <div className="zr_itineraryStatus_wrapper" ref={dropdownRef}>
            <div
                className={`zr_itineraryStatus_display ${noToggle ? ' zr_itineraryStatus_display_aslabel ' : ''}  ${selectedOption ? 'selected' : ''}`}
                style={{
                    borderColor: selectedOption ? selectedOption.color : undefined,
                    backgroundColor: selectedOption ? `${selectedOption.color}1A` : undefined, // Low opacity background
                }}
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedOption?.label}
            </div>


            {isOpen && !noToggle && (
                <ul className="zr_itineraryStatus_modal">
                    {statusOptions.map(option => (
                        <li
                            key={option.key}
                            className="zr_itineraryStatus_item"
                            onClick={() => handleSelect(option.key)}
                        >
                            {React.createElement(option.icon, { size: 14, color: option.color })}
                            <span className="ms-2">{option.label}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default observer(ItineraryStatusPicker);
