import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

import toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";

import { DeleteLink, EditLink, Layout, } from "../../components";


import SupervisorRoles from "./store";
import { t } from "i18next";
import { DialogContext } from "../../store/context/DialogContext";

const SupervisorRole = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { showMessage, showError, showConfirm } = useContext(DialogContext);
  // const [newPage, setNewPage] = useState("1");
  const [entries, setEntries] = useState({ start: 0, end: 0 });
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [delete_id, setdelete_id] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showDetailPage, setShowDetailPage] = useState(id);
  const [loading, setLoading] = useState(false);
  const [selected_id, setSelected_id] = useState(id);

  if (SupervisorRoles.error)
    toastr.error("error", SupervisorRoles.error)

  const fetchData = useCallback(async () => {
    await SupervisorRoles.getAllSupervisorRoles(page, rowsPerPage);
    setEntries(() => {
      return {
        start: (page - 1) * rowsPerPage + 1,
        end: (page - 1) * rowsPerPage + SupervisorRoles.supervisorRolesLength,
      };
    });
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, fetchData]);

  const handleDeleteSupervisorRole = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete UserRole ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        await SupervisorRoles.deleteSupervisorRoleById(id);
        fetchData();
        setconfirm_alert(false)
        showMessage("Deleted", "Deleted");
        navigate(`/supervisor-role`);
        // fetchData();
      } catch (e) {
        showError(e);
      }
    }


  };

  return (
    <Layout
      showDetailPage={showDetailPage}
       
      title={"User Roles"}
      //   filterStructure={stru}
      //   onApplyFilter={fetchData}
      onAddClick={() => {
        if (!props?.insidePane) navigate(`/supervisor-role/edit`);
        setShowDetailPage(true);
        setSelected_id(null);
      }}
      page={SupervisorRoles.page}
      rows={SupervisorRoles.rows}
      total={SupervisorRoles.totalRecords}
      onPageChange={async (page, rows) => {
        await SupervisorRoles.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>Name</Th>

            <Th className="float-end">Action</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>



      <Layout.TbodyFull>
        <Tbody>
          {SupervisorRoles.supervisorRoles?.map((supervisorRole) => (
            <Tr
              key={supervisorRole?._id}

            >
              <Td scope="row">{capitalize(supervisorRole?.name)} {!supervisorRole?.corp_id && <b>(Master Role)</b>}</Td>

              <Td>
                <EditLink
                  onClick={() => {
                    if (!props?.insidePane)
                      navigate(`/supervisor-role/edit?id=${supervisorRole?._id}`);
                    setconfirm_alert(true);
                    setdelete_id(supervisorRole?._id)
                  }}
                />
                {supervisorRole?.corp_id && <DeleteLink
                  onClick={(event) => {
                    handleDeleteSupervisorRole(event, supervisorRole?._id);
                  }}
                />}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>


    </Layout>
  );
};

export default observer(SupervisorRole);
