import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { CrossButton, BackArrow } from "../..";
import "./style.css";

export default function DetailPageModal({
  small = false,
  large = false,
  medium = false,
  insidePane,
  classNameForDetailPage,
  show,
  back,
  _title,
  _footer,
  _body,
  hideheader,
  hidefooter,
  grouping=false
}) {

  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
    } else {
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, 800);
      return () => clearTimeout(timeout);
    }
  }, [show]);

  const modalWidth = large ? "1800px" : medium ? "1200px" : small ? "600px" : "calc(100vw - 40px)";

  return isVisible ? (
    <div
      className={show && insidePane ? "inside_pane_modal" : ""}
      style={{ position: "absolute", top: insidePane ? "0px" : "70px", left: insidePane ? "0px" : "" }}
    >
      <div
        className={
          !show
            ? "detail-page-modal-container "
            : "detail-page-modal-container detail-page-modal-show " +
            classNameForDetailPage
        }
        style={{
          maxWidth: `${modalWidth}`, // Set the width based on the "size" prop
        }}
      >
        <Row>
          <Col
            style={{
              minHeight: insidePane ? "calc(100vh - 72px)" : "calc(100vh - 72px)",
              maxHeight: insidePane ? "calc(100vh - 72px)" : "calc(100vh - 72px)",
            }}
            className="col-12 p-0 pe-4"
          >
            <Card style={{ height: "100%" }}>
              <CardBody
                className="p-0"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: '#FCFCFF'
                }}
              >
                <div
                  style={{
                    float: "right",
                    paddingLeft: "30px",
                    maxHeight: "max-content",
                    zIndex : 2,
                  }}
                >
                  <CrossButton onClick={() => {
                    back()
                  }} />
                </div>
                {!hideheader && <> 
                  <div
                    style={{
                      borderBottom: "1px solid #dfdfdf",
                      height: "max-content",
                      maxHeight: "max-content",
                      minHeight: "max-content",
                      padding: "20px 15px 15px 30px",

                    }}
                    className="d-flex"
                  >
                    <div className="d-flex align-items-center w-100">
                      <div >
                        <BackArrow onClick={back} /> </div>
                      <div className="w-100"> {_title}</div>
                    </div>
                  </div></>}
                <div
                  className="ps-3"
                  style={{
                    flex: 1,
                    overflowY: !grouping ? "auto" : "unset",
                  }}
                >
                  {_body}
                </div>
                {!hidefooter && <div
                  style={{
                    padding: "2px 15px",
                    borderTop: "1px solid #dfdfdf",
                    maxHeight: "max-content",
                    minHeight: "50px",
                  }}
                >
                  {_footer}
                </div>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div >
  ) : null;
}
