import React, { useContext } from 'react'
import CurrencyInputField2 from '../../../components/InputField/CurrencyInputField2';
import { InputField } from '../../../components';
import { I18nContext } from '../../../store/context/i18nContext';
import { BiMinus } from 'react-icons/bi';

function DiscountRow({
    subTotal,
    discounts,
    addDiscount = () => { },
    removeDiscount = () => { },
    updateDiscounts = () => { },
    formConfig = null,
    editAble = false//if not editable means showing as label
}) {
    const { t } = useContext(I18nContext)
    return (
        <>
            {/* header */}
            <tr style={{ lineHeight: "0.5" }}>
                <td> </td>
                <td> </td>
                <td>Sub total </td>
                <td className={`w-100 d-flex justify-content-${editAble ? "start" : "end"}`}>
                    {subTotal ? (subTotal) / 100 : 0}
                </td>
                {editAble ? <td>
                    <div
                        className="btn btn-outline-dark"
                        style={{
                            padding: "3px",
                            paddingRight: "6px",
                            fontSize: "10px",
                        }}
                        onClick={addDiscount}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <i className="bx bx-plus  font-size-10 me-1" />
                            {t("Discount")}
                        </div>
                    </div>
                </td> : null}
            </tr>
            {/* body */}
            {discounts?.map((discount, discountIndex) => {
                return (
                    <tr key={discountIndex} className=" position-relative" style={{ lineHeight: "0.5" }}>
                        <td></td>
                        <td></td>
                        <td >
                            {editAble ? <InputField
                                isDisabled={formConfig?.diableUserInputForFee}
                                // plain
                                outline
                                isReadOnly={!editAble}//editAble check here
                                placeholder={"Discount Detail"}
                                noLabel
                                value={discount?.detail}
                                onChange={(v) =>
                                    updateDiscounts({ discountIndex, key: "detail", v: v })
                                }
                            /> : `Discount (${discount?.detail ?? ""})`}
                        </td>
                        <td className={`w-100 d-flex justify-content-${editAble ? "start" : "end"}`}>
                            {editAble ? <CurrencyInputField2
                                disabled={formConfig?.diableUserInputForFee}
                                isReadOnly={!editAble}//editAble check here
                                // plain
                                placeholder={"Amount"}
                                style={{ minWidth: "70px" }}
                                value={discount?.amount}
                                onChange={(v) =>
                                    updateDiscounts({ discountIndex, key: "amount", v: v })
                                }
                            /> : `${((discount?.amount ?? 0) / 100).toFixed(2)}`}
                        </td>
                        {editAble ? <td>
                            <div
                                className="btn btn-outline-dark d-flex align-items-center"
                                style={{
                                    border: "1px solid #707070",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    padding: "1px",
                                    width: "20px"
                                }}
                            >
                                <BiMinus onClick={() => removeDiscount(discountIndex)} fontSize="16" />
                            </div>

                        </td> : null}
                    </tr>
                );
            })}
        </>
    )
}

export default DiscountRow