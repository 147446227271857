import React, { Children, useEffect, useMemo, useState } from 'react';
import {
    DropdownToggle,
    DropdownMenu,
    UncontrolledDropdown,
    DropdownItem,
} from 'reactstrap';



function GeneralDropdown({ value = "", data = [], label = "",className="",labelClassName="",onChange=()=>{} ,style={},caret=true}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
            <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle} className={`${className}`} >
                <DropdownToggle
                    caret={caret}
                    outline={true}
                    color="#ced4da"
                    className={` d-flex align-items-center justify-content-between ${labelClassName}`}
                    style={{ border: '1px solid #ced4da', backgroundColor: '#fff' ,...style}}
                >
                    {value ? value : label}
                </DropdownToggle>
                <DropdownMenu className='shadow'>
                    {data?.map((v, i) => (
                        <DropdownItem key={i} onClick={()=>onChange(v)}>{v?.label}</DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
       
    );
}



export default GeneralDropdown;
