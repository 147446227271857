import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import {
  InputField,
  MakeModelSelect,
  SelectField,
  SupplierSelect,
  VehicleTypeSelect,
  DateTimeSelect,
  ZoneSelect,
  CustomFieldComponent,
  ToggleSelector
} from "../../../components";
import { I18nContext } from "../../../store/context/i18nContext";
import SELECTSTRUCTURE from "../VehicleConstant";
import DateTimePickerWithInterval from "../../../components/DateTimePickerWithInterval";


export default function VehicleProfile({ data, setData, setIsDCO, isDCO, hasErr }) {
  const [showErr, setshowErr] = useState(false);
  const { t } = useContext(I18nContext);

  const [vehicleType, setVehicleType] = useState(data?.vehicleType);

  return (

    <div className="active container">
      <Row>
        <Col className="flex-1 col-10 ps-4">
          <ToggleSelector
            data={[
              { label: "Self", value: "self" },
              { label: "Supplier", value: "supplier" },
            ]}
            className="w-25"
            style={{ width: "25%", position: "relative", top: "2.5px" }}
            value={data?.vehicleType}
            onChange={(v) => {
              setVehicleType(v)
              setData({ ...data, vehicleType: v })
            }}
          />

        </Col>
      </Row>
      <Row className=" " style={{ padding: "10px" }}>
        <Row className="ms-2">
          {vehicleType == "supplier" && <SupplierSelect
            showClear
            value={data?.supplier_id}
            onChange={(v) => setData({ ...data, supplier_id: v?.value })}
            className="col-sm-6"
          />}

          <InputField
            value={data?.regNo}
            required={true}
            label={t("Registration No")}
            onChange={(v) => {
              setData({ ...data, regNo: v });
            }}
            error={
              data?.regNo?.length === 0 ? t("Enter Vehicle Reg Number") : ""
            }
            showErr={hasErr}
            className="col-sm-6"
            type="text"
          />




        </Row>

        <Row className="ms-2">
          <MakeModelSelect
            value={data?.makeModel}
            required={true}
            className="col-sm-6"
            label={t("Make Model")}
            onChange={(v) =>
              setData({
                ...data, makeModel: v?.value,
                vehicleExtn: {
                  ...data.vehicleExtn,
                  seats: v?.seats,
                  wheels: v?.wheels
                },
              })
            }
          />

        </Row>

        <Row className="ms-2">

          <ZoneSelect
            label={"Zone"}
            className="col-sm-6"
            value={data?.zone_id}
            onChange={(v) => {
              setData({ ...data, zone_id: v.value });
            }}
          />

          <SelectField
            required={true}
            data={SELECTSTRUCTURE.years}
            value={data?.year}
            onChange={(v) => {
              setData({ ...data, year: v.value });
            }}
            error={data?.year?.length === 0 ? t("Enter Mfg year") : ""}
            showErr={hasErr}
            label={t("Mfg Year")}
            className="col-sm-6"
          />

          {/* <VehicleTypeSelect
            isDisabled
            value={data?.vehicleType}
            className="col-sm-6"
          /> */}
        </Row>
        <Row className="ms-2">


          <SelectField
            value={data?.vehicleExtn?.fuelType}
            onChange={(v) => {
              setData({
                ...data,
                vehicleExtn: {
                  ...data.vehicleExtn,
                  fuelType: v.value,
                },
              });
            }}
            data={SELECTSTRUCTURE.fuel}
            showErr={showErr}
            label={t("Fuel Type")}
            className="col-sm-6"
          />
          <SelectField
            value={data?.vehicleExtn?.color}
            onChange={(v) => {
              setData({
                ...data,
                vehicleExtn: { ...data.vehicleExtn, color: v.value },
              });
            }}
            data={SELECTSTRUCTURE.carColorArray}
            showErr={showErr}
            label={t("Color")}
            className="col-sm-6"
          />
        </Row>
        <Row className="ms-2">
          <InputField
            value={data?.vehicleExtn?.wheels}
            label={t("Wheels")}
            onChange={(v) => {
              setData({
                ...data,
                vehicleExtn: { ...data.vehicleExtn, wheels: v },
              });
            }}
            error={data?.vehicleExtn?.wheels?.length === 0 ? t("Enter number of wheels") : ""}
            className="col-sm-6"
            showErr={hasErr}
            type="number"
          />
          <SelectField
            value={data?.vehicleExtn?.seats}
            label={t("Seats")}
            onChange={(v) => {
              setData({ ...data, vehicleExtn: { ...data.vehicleExtn, seats: v.value } })
            }}
            error={data?.vehicleExtn?.seats?.length === 0 ? t("Enter Number of Seats") : ""}
            showErr={hasErr}
            data={SELECTSTRUCTURE.seatTypes}
            className="col-sm-6"
            type="number"
          />
        </Row>
        <Row className="ms-2">
          {/* <SelectField
            data={SELECTSTRUCTURE.status}
            value={data?.status}
            onChange={(v) => setData({ ...data, status: v.value })}
            error={data?.status?.length === 0 ? t("Enter status") : ""}
            className="col-sm-6"
            showErr={hasErr}
            label={t("Status")}
          /> */}
        </Row>
        <Row className="ms-2">
          {/* <div className="col-sm-6">
            <DateTimePickerWithInterval
              label={t("Quality Check Time")}
              placeholder={t("Quality Check Time")}
              className="col-sm-12"
              outerDivStyle={{
                paddingRight: "0px"
              }}
              onChange={(v) => {
                setData({
                  ...data,
                  vehicleExtn: { ...data.vehicleExtn, qcAt: v },
                });
              }}
              value={data?.vehicleExtn?.qcAt}
              type="datetime"
              timestamp={true}
            />
          </div> */}
          <SelectField
            data={SELECTSTRUCTURE.transmission}
            value={data?.vehicleExtn?.transmission}
            onChange={(v) =>
              setData({
                ...data,
                vehicleExtn: {
                  ...data.vehicleExtn,
                  transmission: v.value,
                },
              })
            }
            showErr={showErr}
            className="col-sm-6"
            label={t("Transmission")}
          />
        </Row>

        <Row className="ms-2">
          <InputField
            value={data?.vehicleExtn?.kms}
            label={t("Odometer (KM) ")}
            onChange={(v) => {
              setData({
                ...data,
                vehicleExtn: { ...data.vehicleExtn, kms: v },
              });
            }}
            error={data?.vehicleExtn?.kms < 0 ? t("Distance Can't be Negative") : ""}
            // error={data.kms < 0 ? "Distance Can't be Negative" : ""}
            showErr={hasErr}
            className="col-sm-6"
            type="number"
          />

        </Row>

      </Row>

      <CustomFieldComponent
        module={"vehicle"}
        record_id={data?._id}
        kind={"vehicle"}
        propList={data?.vehicleExtn?.propertyList}
        onChange={(v) =>
          setData({
            ...data,
            vehicleExtn: {
              ...data?.vehicleExtn,
              propertyList: v,
            },
          })
        }
      />
    </div>
  );
}
