import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CurrencyAmountField, DateSelect, DeleteButton, ModalComponent, SaveButton } from '../../../../components';
import { I18nContext } from '../../../../store/context/i18nContext';
import { doDELETE, doGET, doPUT } from '../../../../util/HttpUtil';
import './style.css';
import { DialogContext } from '../../../../store/context/DialogContext';
import { FiMinusCircle } from 'react-icons/fi';
import AmountCurrencyField from '../../../../components/AmountField/AmountCurrencyField';

const softColors = [
    "#f0b3ff", "#b3b3f5", "#b3d9ff", "#b3ffff", "#b3ffe6",
    "#b3ffcc", "#ccffb3", "#e6ffb3", "#ffffb3", "#ffe6b3",
    "#ffccb3", "#ffb3b3", "#ffb3cc", "#ffb3e6", "#ffb3ff",
    "#f0e6c0", "#c0f0e6", "#c0c0f0", "#e6c0f0", "#f0c0d9",
    "#d9c0f0", "#c0d9f0", "#f0d9c0", "#d9f0c0", "#c0f0d9",
    "#a6a6d9", "#d9a6a6", "#a6d9a6", "#d9d9a6", "#a6d9d9",
    "#d9a6d9"
];

const hashPriceCurrency = (price, currency) => {
    const item = `${price}-${currency}`;
    let hash = 0;

    for (let i = 0; i < item.length; i++) {
        const char = item.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash |= 0;
    }

    return Math.abs(hash) % softColors.length;
};

const getColorForPriceCurrency = (currency, price) => {
    const index = hashPriceCurrency(price, currency);
    return softColors[index];
};

const CustomEvent = ({ event, onDelete = () => { } }) => {
    return (
        <div style={{ borderLeft: `8px solid ${event?.backgroundColor ?? "red"}` }} className="w-100 align-items-center d-flex justify-content-between custom-event">
            <div>
                {event?.currency} {((event?.price ?? 0) / 100).toFixed(2)}
            </div>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    onDelete()
                }}
                className="hover-icon"
            >
                <FiMinusCircle color = "red" />
            </div>
        </div>
    );
};


const { RangePicker } = DatePicker;
const localizer = momentLocalizer(moment);

const ItineraryRate = ({ itinerary_id }) => {
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [events, setEvents] = useState([]);
    const [dateRange, setDateRange] = useState([]);
    const [currency, setCurrency] = useState('INR');
    const [price, setPrice] = useState(0);
    const [currentSegment, setCurrentSegment] = useState({});
    const [viewedMonthStart, setViewedMonthStart] = useState(moment().startOf('month'));
    const [viewedMonthEnd, setViewedMonthEnd] = useState(moment().endOf('month'));
    const [dateDetails, setDateDetails] = useState({});

    useEffect(() => {
        fetchRates();
    }, [itinerary_id, viewedMonthStart, viewedMonthEnd]);

    const handleNavigate = (date) => {
        setViewedMonthStart(moment(date).startOf('month'));
        setViewedMonthEnd(moment(date).endOf('month'));
    };

    const aggregateEvents = (rows) => {
        const aggregatedEvents = [];
        let lastRow = null;
        rows.forEach((row) => {
            if (lastRow && moment(row.date, 'YYYYMMDD').subtract(1, 'days').isSame(moment(lastRow.date, 'YYYYMMDD')) &&
                row.currency === lastRow.currency && row.price === lastRow.price) {
                const lastEvent = aggregatedEvents[aggregatedEvents.length - 1];
                lastEvent.end = moment(row.date, 'YYYYMMDD').add(1, 'days').toDate(); // In many calendar systems, including react-big-calendar, the end date of an event is treated as exclusive. 
            } else {
                aggregatedEvents.push({
                    start: moment(row.date, 'YYYYMMDD').toDate(),
                    end: moment(row.date, 'YYYYMMDD').toDate(),
                    title: `${row.currency} ${row.price}`,
                });
            }
            lastRow = row;
        });
        return aggregatedEvents;
    };


    const fetchRates = async () => {
        const queryString = `?date=btw[${viewedMonthStart.format('YYYYMMDD') - 1},${parseInt(viewedMonthEnd.format('YYYYMMDD')) + 1}]&sortAsc=true&sortBy=date`;
        try {
            const response = await doGET(`/api/itinerary-rate/grid${queryString}&itinerary_id=${itinerary_id}&rows=-1`);
            const eventsMapped = response.data.rows.map((row) => {
                return {
                    ...row,
                    start: moment(row.date, 'YYYYMMDD').toDate(),
                    end: moment(row.date, 'YYYYMMDD').toDate(),
                    date: null,
                    title: `${row.currency} ${row.price}`,
                    backgroundColor: getColorForPriceCurrency(row.currency, row.price)
                };
            });
            const newDateDetails = {};
            response.data.rows.forEach(row => {
                newDateDetails[moment(row.date, 'YYYYMMDD').format('YYYY-MM-DD')] = { ...row };
            });
            console.log(eventsMapped)
            setDateDetails(newDateDetails);
            setEvents(eventsMapped);
        } catch (error) {
            console.error('Failed to fetch itinerary rates:', error);
        }
    };

    const deleteEvent = async (id) => {
        try {
            const response = await doDELETE(`/api/itinerary-rate/${id}/delete`);
            fetchRates()
        } catch (error) {
            console.error('Failed to fetch itinerary rates:', error);
        }
    };

    const onSelectEvent = (event) => {
        const { start, end, title } = event;
        const [currency, amount] = title.split(" ");
        const adjustedEnd = moment(start).isSame(moment(end), 'day') ? moment(end) : moment(end).subtract(1, 'days');
        setDateRange([moment(start).format('YYYYMMDD'), adjustedEnd.format('YYYYMMDD')]);
        setCurrency(currency);
        setPrice(parseInt(amount, 10));
        setCurrentSegment(event)
        setIsModalOpen(true);
    };

    const showModal = (slotInfo) => {
        const { start, end } = slotInfo;
        const dateKey = moment(start).format('YYYY-MM-DD');

        if (dateDetails[dateKey]) {
            setCurrentSegment(dateDetails[dateKey])
            setCurrency(dateDetails[dateKey].currency);
            setPrice(dateDetails[dateKey].price);
        } else {
            setCurrentSegment({})
            setCurrency("INR");
            setPrice(0);
        }

        const adjustedEnd = moment(start).isSame(moment(end), 'day') ? end : moment(end).subtract(1, 'days');
        setDateRange([moment(start).format('YYYYMMDD'), moment(adjustedEnd).format('YYYYMMDD')]);
        setIsModalOpen(true);
    };

    const handleSave = async () => {
        const startDate = parseInt(dateRange[0] ?? "");
        const endDate = parseInt(dateRange[1] ?? "");
        if (startDate && endDate) {
            try {
                let currentDate = startDate;
                const updates = [];
                while (currentDate <= endDate) {
                    updates.push({
                        itinerary_id,
                        date: currentDate,
                        currency,
                        price,
                    });
                    currentDate += 1;
                }
                await doPUT('/api/itinerary-rate/update', updates);
                fetchRates();
                setIsModalOpen(false);
            } catch (error) {
                console.error('Failed to update itinerary rates:', error);
            }
        } else {
            showError('Please Select Dates');
        }
    };



    const onDateChange = (date, dateIndex) => {
        const newDateRange = [...(dateRange ?? [])];
        newDateRange[dateIndex] = date;
        setDateRange(newDateRange);
    };

    return (
        <div className="zr_ItineraryRate py-3">
            <div className='d-flex justify-content-center w-100' >
                <div className='flex-1' >
                    <Calendar
                        localizer={localizer}
                        onSelectEvent={onSelectEvent}
                        onNavigate={handleNavigate}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        views={["month"]}
                        style={{ height: "calc(100vh - 250px)" }}
                        onSelectSlot={showModal}
                        components={{
                            event: (props) => <CustomEvent
                                {...props}
                                onDelete={() => deleteEvent(props?.event?._id)}
                            />
                        }}
                        selectable
                    />
                </div>
            </div>


            <ModalComponent
                position={""}
                size={"small"}
                onToggle={() => { setIsModalOpen(false); }}
                isOpen={isModalOpen}
            >
                <ModalComponent.Title>
                    {t("Rate")}
                </ModalComponent.Title>

                <ModalComponent.Body>
                    <div  className=' py-3'>
                        <div className='row w-100 d-flex align-items-center m-auto'>
                            <DateSelect
                                placeholder={t("Enter date ")}
                                value={dateRange[0]}
                                outline
                                label={t("From")}
                                onChange={(v) => {
                                    onDateChange(v, 0)
                                }}
                                type="text"
                                style={{
                                    flex: 0.47
                                }}
                                className=" px-0"
                            />
                            <div className=' text-center' style={{
                                flex: 0.06
                            }}>&#8652;</div>
                            <DateSelect
                                placeholder={t("Enter date ")}
                                value={dateRange[1]}
                                outline
                                label={t("To")}

                                onChange={(v) => {
                                    onDateChange(v, 1)

                                }}
                                type="text"
                                style={{
                                    flex: 0.47
                                }}
                                className="px-0"
                            />
                        </div>

                        <AmountCurrencyField
                            placeholder={t("Enter Rate")}
                            required={true}
                            outline
                            value={{
                                amount: price,
                                currency: currency,
                            }}
                            label={t("Rate")}
                            onChange={(v) => {
                                setPrice(v?.amount);
                                setCurrency(v?.currency);
                            }}
                            type="text"
                            className="flex-1"
                        />

                        <div className='d-flex justify-content-end mt-5'>
                            <SaveButton
                                // loading={loading}
                                onClick={handleSave}
                            // disabled={loading}
                            />
                            {/* {(currentSegment?._id && moment(dateRange[0]).isSame(moment(dateRange[1]), 'day')) && <DeleteButton
                                onClick={async(e) => {
                                    const response = await doDELETE(`/api/itinerary-rate/${currentSegment?._id}/delete`);
                                    setCurrentSegment({})
                                    setPrice(0)
                                    setCurrency("INR")
                                    fetchRates()
                                    setIsModalOpen(false);
                                }}
                                // loading={loading}
                            />} */}
                        </div>

                    </div>
                </ModalComponent.Body>
            </ModalComponent>
        </div>
    );
};

export default ItineraryRate;
