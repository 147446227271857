import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { UserContext } from "../../../store/context/UserContext";
import "./ProfileMenu.scss";
import VERSION from "../../../Constants.js";


// users
// import user4 from "../../../assets/images/users/avatar-4.jpg"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AllProfileModal from "./AllProfileDropdown";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
const ProfileMenu = (props) => {

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const { logout, user, userContent, allProfiles, populateAllProfiles } = useContext(UserContext);
  const userInitial = userContent?.name ? userContent.name.charAt(0).toUpperCase() : '';


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="user-avatar-circle d-sm-none">
            {userInitial}
          </div>
          <div className="d-none d-sm-inline-block ms-1 fw-medium font-size-13"
            onClick={userContent?.superAdmin_id ? null : populateAllProfiles}>

            {userContent?.name}{" "}
            <i className="uil-angle-down d-inline-block font-size-16"></i>{" "}
          </div>
        </DropdownToggle>
        <DropdownMenu className="">
          {!userContent?.superAdmin_id &&
            <DropdownItem>
              <div className="d-flex align-items-center" >
                {userContent?.logo && (
                  <img
                    src={userContent?.logo}
                    alt="logo"
                    style={{ maxWidth: "20px", maxHeight: "20px", height: '18px', width: '18px' }}
                    className="me-1"
                  />
                )}

                {userContent?.companyName}
                {allProfiles && allProfiles.length > 1 && (
                  <div className="d-flex align-items-center" onClick={() => { setProfileModal(true) }}>
                    <i className="uil-angle-down d-inline-block font-size-16"></i>{" "}
                  </div>
                )}
              </div>
            </DropdownItem>}
          <div className="dropdown-divider" />
          <DropdownItem style={{
            cursor: "pointer"
          }} tag="a" onClick={() => { props?.router?.navigate("/profile") }} >
            {" "}
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            {props?.t("Profile")}{" "}
          </DropdownItem>

          {/* <DropdownItem tag="a" onClick={() => { props?.router?.navigate("/settings") }}>
            <i className="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            {props?.t("Settings")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
            {props?.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link
            to="/"
            onClick={() => {
              logout();
            }}
            className="dropdown-item"
          >
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props?.t("Logout")}</span>
          </Link>
          <p style={{ fontSize: "10px", textAlign: "center", marginBottom: "0", marginTop: "10px" }}>
            {" "}
            Version {VERSION}
          </p>
        </DropdownMenu>
      </Dropdown>

      {profileModal && <AllProfileModal open={profileModal} onClose={() => { setProfileModal(false) }} />}

    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
