import React from 'react'
import { Row } from 'reactstrap'
import { FileUpload } from '../../../components'
import ReactDropZone from '../../../components/FileUpload/ReactDropZone'

const StepFour = ({ data, setData }) => {

    return (
        <>

            <textarea className=' px-2 mx-5' style={{ outline: "none", background: "#F2F4FC" }} rows={8} cols={85} placeholder="Details" value={data?.details} onChange={(v) => {
                setData({ ...data, details: v.target.value })
            }} />
            <Row className='mt-4 d-flex justify-content-center'>
                <FileUpload className="col-6" onChange={(f) => console.log(f)} />
            </Row>
        </>
    )
}

export default StepFour