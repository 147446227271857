export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/tax/grid?rows=${sizePerPage}&`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;

    if (filterUrl) url += filterUrl;
    if (page) url += `page=${page}`
    return url ;
  },
  get: (id) => `/api/tax/${id}/detail`,
  save: `/api/tax/create`,
  delete: (id) => `/api/tax/${id}/delete`,
  bulkDelete: (id) => `/api/tax/delete`,

  update: `/api/tax/update`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Tax Name",
    filterName: "name",
    type: "text",
  }

];

