
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Button, Row } from "reactstrap";
import { InputField, TaxInputField } from "../../components";
import { I18nContext } from "../../store/context/i18nContext";
import RemoveLink from "../../components/Buttons/RemoveLink";

const newItem = {
    percent: null,
    baseAmount: null,
    name: ""
}

const Items = (props) => {
    const { data = [newItem], onChange } = props
    const { t } = useContext(I18nContext);

    const addItems = () => {
        onChange([...data, newItem]);
    };

    useEffect(() => { if (props?.data?.length === 0) addItems() }, [])

    const deleteItems = (index) => {
        const updatedData = data.filter((_, i) => i !== index);
        onChange(updatedData);
    };

    const updatedItems = ({ value, index, key }) => {
        const tempArr = [...data];
        tempArr[index] = { ...tempArr[index], [key]: value }
        onChange(tempArr);
    }

    return (
        <React.Fragment>

            {
                <div className="d-flex justify-content-end">
                    <Button color="light" onClick={addItems} className="d-flex h-36 align-items-center">
                        <i className="bx font-size-20 color-blue bxs-plus-circle me-2" />
                        {t("Add Items")}
                    </Button>
                </div>
            }

            {data?.map((account, index) => (
                <div key={index} className="shadow-none">
                    <div>
                        <Row className="d-flex justify-content-center align-items-center" >
                            {<InputField
                                placeholder={t("Items name")}
                                value={account.name}
                                label={t("Items name")}
                                onChange={(v) => {
                                    updatedItems({ value: v, index, key: "name" })
                                }}
                                showError={data?.name?.length === 0 ? t("Please enter Items name") : ""}
                                type="text"
                                className="col-sm-3"
                            />}
                            <TaxInputField
                                label={t("Base Amount")}
                                value={account.baseAmount}
                                onChange={(v) => { updatedItems({ value: Number(v), index, key: "baseAmount" }) }}
                                isAbs={false}
                                className="col-sm-3"
                            />
                            <TaxInputField
                                label={t("Percentage")}
                                value={((account.percent || 0) / 100).toFixed(2)}
                                onChange={(v) => { updatedItems({ value: Number(v)*100, index, key: "percent" }) }}
                                isAbs={true}
                                className="col-sm-3"
                            />
                            <div className="col-sm-3 align-items-center d-flex justify-content-between " style={{ cursor: 'pointer' }}>
                                <RemoveLink className="ms-1" onClick={() => deleteItems(index)} />
                            </div>
                        </Row>
                        <hr className="p-0 m-0" />
                    </div>

                </div>

            ))}




        </React.Fragment>
    );

};

export default observer(Items);
