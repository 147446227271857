import React, { useEffect } from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { observer } from "mobx-react-lite";
import { Pagination, Breadcrumbs, Filter } from "../../components";
import PaymentService from "./PaymentService";


import { STRUCTURE } from "./PaymentConstant";

const Payment = () => {
  useEffect(()=>{
    PaymentService.fetch();
  }, [])

  return (
    <React.Fragment>
      <div className="page-content-2">
        <Row >
            <Card className="mx-0 pt-3">
              <CardTitle className="px-3">
                <div className={`d-flex justify-content-between col-"12" mb-3`}>
                  <Breadcrumbs title="" breadcrumbItem="Payment" />
                  <div className="flex-1">
                    <Filter
                      className="mb-2"
                      structure={STRUCTURE}
                      applyFunc={PaymentService.fetch}
                    />
                  </div>
                </div>
              </CardTitle>
              <CardBody>
                <div className="table-rep-plugin table-responsive mb-0" data-pattern="priority-columns">
                  <Table className="table mb-0 ">
                    <Thead className="thead-dark">
                      <Tr >
                        <Th className="col-2">Paid On</Th>
                        <Th className="col-1">Amount</Th>
                        <Th className="col-1">Status</Th>
                        <Th className="col-1">Payment Mode</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {PaymentService.records?.map((payment, idx) => (
                        <Tr
                          k={idx}
                          className={`striped-table`}
                          key={payment._id}
                        >
                          <Td>{new Date(payment.createdAt*1000)?.toString().substr(4,17)}</Td>
                          <Td>{payment.currency + " " + payment.amount} </Td>
                          <Td>{payment.statusStr}</Td>
                          <Td>{}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </CardBody>

              <Pagination
                page={PaymentService.page}
                rows={PaymentService.rows}
                total={PaymentService.totalRecords}
                onChange={PaymentService.onPaginationChange}
              />
            </Card>

        </Row>
      </div>
    </React.Fragment>
  );
};

export default observer(Payment);
