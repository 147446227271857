import { toJS } from "mobx";
import React, { useEffect, useState,useContext } from "react";
import { SelectField } from "../../SelectField/SelectField";
import StateSelectService from "./StateSelectService";
import { Row, Table } from "reactstrap";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { I18nContext } from "../../../store/context/i18nContext";

export default function StateSelect(props) {
  const [STATES, setStates] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const { t } = useContext(I18nContext);




  useEffect(() => {
    const fetch = async () => {
      try {
        await StateSelectService.fetch(props?.country);
        setStates(toJS(StateSelectService.states[props?.country]));
      } catch (error) {
        console.log(error.message);
      }
    };
    fetch();
  }, [props?.country]);


  useEffect(() => {
    if (STATES && props?.hidestate && props?.hidestate.length > 0) {
      const filtered = STATES.filter(
        (state) => !props?.hidestate?.includes(state?.value)
      );
      setFilteredStates(filtered);
    } else {
      setFilteredStates(STATES);
    }
  }, [STATES, props?.hidestate]);





  // const renderFunc = (props) => {
  //   const {
  //     data,
  //     innerRef,
  //     innerProps,
  //   } = props;
  //   return (
  //     <div
  //       ref={innerRef}
  //       {...innerProps}
  //     >
  //       <Table style={{ margin: "0px" }} className="dropdown-hover">
  //         <thead style={{ margin: "0px" }} >
  //           <tr style={{ margin: "0px" }}>
  //             <td className="py-3">{`${data.value}`}</td>
  //           </tr>
  //         </thead>
  //       </Table>
  //     </div>
  //   );
  // };

  return (
    <>
      {filteredStates?.length > 0 ? (
        !props?.multi ? (
          <SelectField
            placeholder={props?.placeholder}
            noLabel={props?.noLabel}
            style={props?.style}
            className={props?.className}
            // onChange={(v) => {
            //   v.value ? props?.onChange(v) : props?.onChange({ value: null })
            // }}
            onChange={props?.onChange}
            // render={renderFunc}
            required={props?.required}
            innerContainerClassName={props?.innerContainerClassName}
            containerStyle={props?.containerStyle}
            data={filteredStates}
            value={props?.value}
            label={t("State")}
            isDisabled={!props?.country}
            error={!props?.value ? "Please Select State" : ""}
            showErr={props?.showErr}
            inline={props?.inline}
            innerContainerStyle={props?.innerContainerStyle}
            borderNone={props?.borderNone}
            plain={props?.plain}
            RightComponent={props?.RightComponent}
            onClickRight={props?.onClickRight}
            {...props}
          />
        ) : (
          <SelectFieldMultiple
            plain={props?.plain}
            style={props?.style}
            placeholder={props?.placeholder}
            noLabel={props?.noLabel}
            required={props?.required}
            className={props?.className}
            innerContainerClassName={props?.innerContainerClassName}
            onChange={(v) => props?.onChange(v.map((c) => c?.value))}
            data={filteredStates}
            maxWidth={props?.maxWidth}
            containerStyle={props?.containerStyle}
            value={
              props?.value?.length
                ? STATES.filter((c) => props?.value?.includes(c?.value))
                : ""
            }
            error={props?.value ? "" : "Select States"}
            label={props?.label}
            showErr={props?.showErr}
          />
        )
      ) : (
        <SelectField
          placeholder={props?.placeholder}
          noLabel={props?.noLabel}
          className={props?.className}
          style={props?.style}
          // onChange={(v) => {
          //   v.value ? props?.onChange(v) : props?.onChange({ value: null })
          // }}
          onChange={props?.onChange}
          // render={renderFunc}
          data={filteredStates}
          containerStyle={props?.containerStyle}
          required={props?.required}
          value={props?.value}
          plain={props?.plain}
          label={t("State")}
          innerContainerClassName={props?.innerContainerClassName}
          isDisabled={true}
          error={!props?.value ? "Please Select State" : ""}
          showErr={props?.showErr}
          inline={props?.inline}
          borderNone={props?.borderNone}
          RightComponent={props?.RightComponent}
          onClickRight={props?.onClickRight}
        />
      )}
    </>
  );
}
