import { Tooltip } from 'react-tooltip';

export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Module",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.module}
        </div>

      },
      show: true,
    },
    {
      title: "Name",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.name}
        </div>

      },
      show: true,
    },
    {
      title: "Value",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.value}
        </div>

      },
      show: true,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
 
  ],
};
