import React from 'react';
import {Card,} from "reactstrap";
import moment from 'moment';
import "./style.css"



const formatDate = (timestamp) => {
    const date = moment.unix(timestamp);
    const now = moment();
  
    if (date.isSame(now, 'day')) {
      return { prefix: 'Today', time: date.format('hh:mm A') };
    } else if (date.isSame(now.subtract(1, 'day'), 'day')) {
      return { prefix: 'Yesterday', time: date.format('hh:mm A') };
    } else if (date.isSame(now, 'year')) {
      return { prefix: date.format('MMMM DD'), time: date.format('hh:mm A') };
    } else {
      return { prefix: date.format('MMMM DD, YYYY'), time: date.format('hh:mm A') };
    }
  };
  
const CommentItem = ({ message, at, by, avatar }) => {
      
const formattedDate = formatDate(at);
  return (
    <Card className='p-3'>
        <div className="d-flex justify-content-between">
          <span className="comment-by">{by}</span>
              <div>
                  <span style={{ fontWeight: 'bold' }}>{formattedDate.prefix}</span> ,{' '}
                  <span className='comment-at'>{formattedDate.time}</span>
              </div>
        </div>
        <div className='mt-2'>
            {message}
        </div>
    </Card>
  );
};


export default CommentItem;
