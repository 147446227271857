import { doGET } from "../../util/HttpUtil";
const downloadBlob = (blob, fileName) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Name",
      accessor: "name",
      show: true,
    },
    {
      title: "Kind",
      accessor: "kind",
      show: true,
    },

    {
      title: "Content Type",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.forUpload ? "Upload" : "Download"}
          </div>
        )
      },
      show: true,
    },
    {
      title: "Sample File",
      renderer: (dataObj) => {

        const handleDownload = async () => {
          try {

            const response = await doGET(`/api/template/${dataObj?._id}/sample`);
            const blob = response.data;
            const fileName = 'example.xlsx';
            downloadBlob(blob, fileName);
          } catch (error) {
            console.error("Download error:", error);
          }
        };

        return (
          <div>
            {dataObj?.forUpload && (
              <div>
                {dataObj?.forUpload && (
                  <div onClick={handleDownload} style={{ cursor: 'pointer' }}>
                    <i className="bx bxs-download text-secondary bx-md"></i>
                  </div>
                )}
              </div>
            )}
          </div>
        )
      },

      show: true,
    },

    {
      title: "Content Size",
      renderer: (dataObj) => {
        return (
          <div>
            {JSON.parse(JSON.stringify(dataObj?.contents)).length}
          </div>
        )
      },
      show: true,
    },

    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
