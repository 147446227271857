

export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/i18n/grid?rows=${sizePerPage}&page=${page}&`;

    if (filterUrl) url += filterUrl;
    return url;
  },
  approve: `/api/i18n/change/status`,
  save: `/api/i18n/update`,
  get: (id) => ` /api/i18n/${id}/detail`,
  delete: (id) => ` /api/i18n/${id}/delete`,

};
export const STRUCTURE = [
  {
    label: "Language",
    description: "Select Language",
    filterLabel: "Language",
    filterName: "lang",
    type: "select",
    data: [
      { label: "am-Amharic", value: "am" },
      { label: "ar-Arabic", value: "ar" },
      { label: "en-English", value: "en" },
      { label: "de-German", value: "de" },
      { label: "hi-Hindi", value: "hi" },
    ]
  },
  {
    label: "From Content",
    description: "Using Orignal Text",
    filterLabel: "From Content",
    filterName: "key",
    type: "text",
  },
  {
    label: "Status",
    description: "Select Status",
    filterLabel: "Status",
    filterName: "approved",
    type: "select",
    data: [
      { label: "Approved", value: 'true' },
      { label: "Disapproved", value: 'false' },

    ]
  },
  {
    label: "Platform",
    description: "Web / App",
    filterLabel: "Platform",
    filterName: "platform",
    type: "select",
    data: [
      { label: "Web", value: "web" },
      { label: "App", value: "app" },

    ]
  },
];