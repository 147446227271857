import React, { useState, useEffect } from 'react';
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import { generateRandomString } from '..';

function TimeInput(props) {
  const { value, onChange=()=>{}, label, outline, required,readOnly=false } = props;
  const [internalValue, setInternalValue] = useState('');

  useEffect(() => {
    // Convert minutes into hh:mm format and set internalValue
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    setInternalValue(formatTime(hours, minutes));
  }, [value]);

  const formatTime = (hours, minutes) => {
    return `${pad(hours)}:${pad(minutes)}`;
  };

  const pad = (number) => number.toString().padStart(2, '0');

  const handleBlur = () => {
    // Adjust the input to the correct format on blur based on the length of the input
    const nums = internalValue.replace(/:/g, '');
    let formattedTime = '';
    if (nums.length <= 2) {
      formattedTime = `${pad(parseInt(nums) || 0)}:00`;
    } else if (nums.length > 2) {
      formattedTime = `${pad(parseInt(nums.substring(0, 2)))}:${pad(parseInt(nums.substring(2)) || 0)}`;
    }
    setInternalValue(formattedTime);
    const [h, m] = formattedTime.split(':').map(Number);
    onChange(h * 60 + m);
  };

  const handleInputChange = (value) => {
    const nums = value.replace(/:/g, '');
    let formattedTime = '';
    if (nums.length <= 2) {
      formattedTime = nums;
    } else {
      formattedTime = `${nums.substring(0, 2)}:${nums.substring(2)}`;
    }
    setInternalValue(formattedTime);
  };

  const handleKeyPress = (e) => {
    const nums = e.target.value.replace(/:/g, '');
    if (nums.length === 2 && e.key !== 'Backspace') {
      e.target.value = `${nums}:`;
    }
  };

  const id = generateRandomString(36);
  const maskGenerator = createDefaultMaskGenerator('99:99');

  return (
    <div className='inputfield__container'>
      <div className='inputfield__innercontainer_outline'>
        <Label id={id} label={label} required={required} />
        <div className={`inputfield ps-2`}>
          <MaskedInput
            id={id}
            className="inputfield__input"
            maskGenerator={maskGenerator}
            value={internalValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyUp={handleKeyPress}
            placeholder="hh:mm"
            readOnly={readOnly}
          />
        </div>
      </div>
    </div>
  );
}

const Label = ({ label, required, id }) => {
  return (
    <label
      htmlFor={id}
      className="inputfield__label ms-3 font-size-11 px-1"
    >
      {label}
      {required && <font color="#EC734B">*</font>}
    </label>
  );
};

export default TimeInput;
