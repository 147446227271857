import React, { useContext, useEffect, useState } from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import LanguageService from "../../../../pages/Language/LanguageService";
import { I18nContext } from "../../../../store/context/i18nContext";

export default function LanguageMenu({ outline }) {
    const [languages, setLanguages] = useState([]);
    const { changeLang } = useContext(I18nContext);
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [menu, setMenu] = useState(false)

    const changeLanguageAction = (lang) => {
        changeLang(lang)
        setSelectedLanguage(lang);
        if (lang?.code) {
            localStorage.setItem("lang", lang?.code);
        }
        window.location.reload();
    }

    const toggle = () => {
        setMenu(!menu)
    }

    useEffect(() => {
        let myLang = localStorage.getItem("lang");
        if (!myLang)
            myLang = "en";
        const fetch = async () => {
            try {
                const languageData = await LanguageService?.fetch()
                const langmap = languageData?.rows;
                setLanguages(langmap);
                langmap.map(l => {
                    if (l?.code === myLang) {
                        setSelectedLanguage(l);
                    }
                });
            }
            catch (error) {
                console.log(error.message);
            }
        };
        fetch();
    }, []);


    const borderStyles = outline
        ? {
            // border: '1px solid rgb(213,213,213)',
            // borderRadius: '6px',
        }
        : {};


    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={toggle} style={borderStyles}
                className={`d-inline-block language-switch mt-1 `}
            >
                <DropdownToggle
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <img
                        src={`${selectedLanguage?.pic}`}
                        alt={`${selectedLanguage?.label}`}
                        height="16"
                        className="me-3"
                    />
                    <span className="d-none d-sm-inline">
                        {selectedLanguage?.label}
                    </span>
                    {/* {selectedLanguage?.label} */}
                </DropdownToggle>
                <DropdownMenu className="mt-1" 
                    style={{
                        maxHeight: "500px", overflowY: "auto",
                    }} >
                    {languages?.map((l, key) => {
                        return <DropdownItem
                            key={key}
                            onClick={() => changeLanguageAction(l)}
                            className={`notify-item ${selectedLanguage?.lang === l?.value ? "active" : "none"}`}
                        >
                            <img
                                src={l?.pic}
                                alt=""
                                className="me-1"
                                height="12"
                            />
                            <span className="align-middle">
                                {" " + " " + l?.label}
                            </span>
                        </DropdownItem>
                    })}
                </DropdownMenu>
            </Dropdown>

        </React.Fragment >
    );
}
