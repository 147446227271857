import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Form, Row } from "reactstrap";

import {
  BackArrow,
  CardThreeBounce,
  CitySelect,
  ClientSelect,
  CountrySelect,
  CrossButton,
  InputField,
  SaveButton,
  scrollStyle,
  Title,
} from "../../components";

import { useNavigate } from "react-router-dom";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import ClientGroupsService from "./ClientGroupsService";

const EditClientGroups = (props) => {
  const { t } = useContext(I18nContext);
  const { showMessage } = useContext(DialogContext);
  const { id, data, setData, hasErr, setHasErr } = props;
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);

  const [freshData, setFreshData] = useState({});

  const navigate = useNavigate();
  const { userContent } = useContext(UserContext);




  const fetch = async () => {
    let clientGroupsD = {};
    if (id) clientGroupsD = await ClientGroupsService.get(id);
    setFreshData(clientGroupsD);
    setData(clientGroupsD);
    setHasErr(false);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetch();
    setHasErr(false);
  }, [id]);
  const submitHandler = async (e) => {
    e.preventDefault();
    setHasErr(true);
    setLoading(true);
    try {
      // if (id) await ClientGroupsService.edit(data, id);
      // else {
      const response = await ClientGroupsService.save(data);
      ClientGroupsService.records.push(data);
      if (!props?.insidePane) navigate(`/clientgroups/edit`);
      // }
      props?.fetchData();
      fetch();
    } catch (e) {
      showError(e);
    }
  };

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(freshData)) {
      props?.onChange(true);
    } else {
      props?.onChange(false);
    }
  }, [data]);

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      {(id && !loading) || !id ? (
        <div className="p-0">
          <Form className="pt-2">
            <Row className="mt-3 mb-3 ">
              <Col className="col-sm-4">
                <InputField
                  type="text"
                  placeholder="Name"
                  label={"Name"}

                  value={data?.name}
                  required={true}
                  onChange={(v) => {
                    setData({
                      ...data,
                      name: v,
                    });
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-3 mb-3">
              <Col className="col-lg">
                <ClientSelect
                  placeholder="Clients"
                  label="Clients"
                  value={data?.client_ids}
                  multi={true}
                  onChange={(v) => {
                    setData({
                      ...data,
                      client_ids: v,
                    });
                  }}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <CardThreeBounce />
      )}
    </React.Fragment>
  );
};

export default observer(EditClientGroups);
