import React, { useEffect, useState } from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { InputField } from '../../../components';
import NumberInput from './NumberInput';
import './guides.css'; // Ensure you have this CSS file
import ColumnHeader from '../module/itineraryDetail/ColumnHeader';

const Guides = ({ value = [], onChange }) => {
    const [guides, setGuides] = useState([]);

    useEffect(() => {
        if(value.length) {
            setGuides(value);
        }
    }, [value]);

    const addGuide = () => {
        const newGuide = {
            detail: '',
            fromDay: 1,
            tillDay: 2,
            // employee_id: '',
            // supplier_id: ''
        };
        const updatedGuides = [...guides, newGuide];
        setGuides(updatedGuides);
        onChange(updatedGuides);
    };

    const updateGuide = (index, field, value) => {
        const updatedGuides = guides.map((guide, i) => i === index ? { ...guide, [field]: value } : guide);
        setGuides(updatedGuides);
        onChange(updatedGuides);
    };

    const removeGuide = (index) => {
        const updatedGuides = guides.filter((_, i) => i !== index);
        setGuides(updatedGuides);
        onChange(updatedGuides);
    };

    return (
        <div>

            <ColumnHeader>
                <ColumnHeader.Title>
                    <div className="d-flex justify-content-between align-items-center w-100 ">
                        <div>Guides</div>

                        <Button onClick={addGuide} className=" d-flex align-items-center py-1">
                            <FaPlus className='me-2' /> Add Guide
                        </Button>
                    </div>
                </ColumnHeader.Title>
                <ColumnHeader.Body maxHeight="calc(100vh - 350px)">
                    {guides.map((guide, index) => (
                        <div key={index} className="card zr_it_guides_guide d-flex flex-column align-items-start p-2">
                            <div className="d-flex w-100 align-items-center justify-content-end">
                                <FaTimes
                                    onClick={() => removeGuide(index)}
                                    fontSize="14"
                                />
                            </div>

                            <InputField
                                className="zr_it_guides_input w-100"
                                value={guide.detail}
                                outline
                                label="Details"
                                onChange={(e) => updateGuide(index, 'detail', e)}
                            />

                            <NumberInput
                                label="From Day"
                                value={guide.fromDay}
                                onChange={(v) => updateGuide(index, 'fromDay', v)}
                            />

                            <NumberInput
                                label="Till Day"
                                max={10}
                                value={guide.tillDay}
                                onChange={(v) => {
                                    updateGuide(index, 'tillDay', v)
                                }}
                            />
                        </div>
                    ))}
                </ColumnHeader.Body>
            </ColumnHeader>

           
     
            
        </div>
    );
};

export default Guides;
