import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { downloadMedia } from '../../util/Util';
import MediaModal from '../MediaModal/MediaModal';

const GIFPlayer = ({ src, placeholder = "Loading...", style, ...restProps }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isDownloading, setDownloading] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      {loading && <Skeleton height={restProps?.height ?? 200} width={restProps?.width ?? 300} />}
      <img
            src={src}
            alt="GIF Full View"
            {...restProps}
            style={{ width: '100%', height: 'auto' }}
          />

      <MediaModal isOpen={isModalOpen} toggle={toggleModal} centered>
        <MediaModal.Header
          title={"GIF"}
          onClose={toggleModal}
          onDownload={
            async () => {
              await downloadMedia(src, "gif")
          }} />
        <MediaModal.Body>
          <img
            src={src}
            alt="GIF Full View"
            {...restProps}
            style={{ width: '100%', height: 'auto' }}
          />
        </MediaModal.Body>
      </MediaModal>
    </div>
  );
};

export default GIFPlayer;
