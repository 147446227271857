import React, { useState } from "react";
import { SelectField } from "../../SelectField/SelectField";

export default function VehicleStatusSelect(props) {
  const data = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 2 },
    { label: "Blocked", value: 3 },
  ];

  return (
    <React.Fragment>
      <SelectField
        className={props?.className}
        onChange={props?.onChange}
        data={data}
        showClear={props?.showClear}
        value={props?.value}
        label={"Status"}
        error={props?.value ? "Please Select Status" : ""}
        showErr={props?.showErr}
      />
    </React.Fragment>
  );
}
