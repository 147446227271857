import { toJS } from "mobx";
import React, { useEffect, useState } from "react";
import { SelectField } from "../../SelectField/SelectField";
import ServicingEntityService from "./ServicingEntityService";

export default function ServicingEntity(props) {
  const [serviceentity, setServiceEntity] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        await ServicingEntityService.fetch();
        setServiceEntity(toJS(ServicingEntityService.SelectEntity));
      } catch (error) {
        console.log(error.message);
      }
    };
    fetch();
  }, []);

  return (
    <React.Fragment>
      {serviceentity.length > 0 && (
        <SelectField
          className={props?.className}
          onChange={props?.onChange}
          data={serviceentity}

          value={props?.value}
          label={props?.label ? props?.label : "Servicing Entity"}
          error={props?.value ? "Please Select Service Entity" : ""}
          showErr={props?.showErr}
          inline={props?.inline}
          borderNone={props?.borderNone}
          RightComponent={props?.RightComponent}
          onClickRight={props?.onClickRight}
        />
      )}
      {!serviceentity.length > 0 && (
        <SelectField
          className={props?.className}
          onChange={props?.onChange}
          data={serviceentity}

          value={props?.value}
          label={"Tax Slab"}
          error={props?.value ? "Please Select Tax Slab" : ""}
          isDisabled={true}
          showErr={props?.showErr}
          inline={props?.inline}
          borderNone={props?.borderNone}
          RightComponent={props?.RightComponent}
          onClickRight={props?.onClickRight}
        />
      )}
    </React.Fragment>
  );
}
