import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { DialogContext } from '../../store/context/DialogContext';


const AudioPlayer = ({ src, placeholder = "Loading...", style, ...restProps }) => {
  const [audioSrc, setAudioSrc] = useState();
  const [loading, setLoading] = useState(false);
  const { showError } = useContext(DialogContext);

  const fetchAudio = async (src) => {
    if (src) {
      setLoading(true);
      try {
        const response = await axios({
          url: src,
          headers: {
            Authorization: localStorage.getItem("BearerToken"),
          },
          withCredentials: true,
          responseType: "blob",
        });
        const reader = new FileReader();
        reader.onload = () => {
          setAudioSrc(reader.result);
        };
        reader.readAsDataURL(response?.data);
      } catch (error) {
        console.error("Error fetching audio:", error);
        showError("Error fetching audio");
      }
      finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (src && (src.slice(1, 4) === "api")) {
      fetchAudio(src);
    }
  }, [src]);

  if (loading) {
    return <Skeleton height={restProps?.height ?? 30} width={restProps?.width ?? 300} />
  }

  return (
    <div>
      {src && audioSrc ?
        <audio controls className="audio-element" style={style} {...restProps}>
          <source src={audioSrc} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
        : <i className='bx bx-music font-size-20' />}
    </div>
  );
};

export default AudioPlayer;
