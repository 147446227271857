import React from 'react'
import { FaReply } from 'react-icons/fa';
import './style.css'

const ReplyButton = ({ onClick }) => {
    return (<button className="reply-button mt-2  d-flex align-items-center font-weight-14 fw-medium cursor-pointer" onClick={onClick}>
        <FaReply color={"#007bff"} size={16} className='ml-1' />
        <span className="font-size-14 mx-2">Reply</span>
    </button>)

};

export default ReplyButton;