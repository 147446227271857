import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { BottomBarButton, Modal, ModalComponent, SearchButton } from "../../../components/index";
import Zone from "../../../pages/Zone/Zone";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import ZoneSelectService from "./ZoneSelectService";

const ZoneSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  asLabel,
  plain,
  outline,
  ...props
}) => {
  const [zones, setZones] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const fetchZones = async () => {
    try {
      await ZoneSelectService.fetch();
      setZones(toJS(ZoneSelectService.zones));
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (ZoneSelectService.zones.length === 0) fetchZones();
  }, []);

  useEffect(() => {
    setZones(toJS(ZoneSelectService.zones));
  }, [ZoneSelectService.zones]);



  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectZone = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0] : selectedValue });
    closeModal();
  };

  const selectZones = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Zones")} />
      )}
      showBottomBar={true}
      className={className}
      onChange={onChange}
      data={zones}
      required={required}
      value={value}
      label={label || (!isDisabled && t("Zone"))}
      error={!value ? t(`Please Select ${label || "Zone"}`) : ""}
      showErr={showErr}
      plain={plain}
      outline={outline}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
      {...props}

    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Zones")} />
      )}
      showBottomBar={true}
      className={className}
      onChange={selectZones}
      data={zones}
      value={value ? zones.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Zones"}`) : ""}
      required={required}
      plain={plain}
      outline={outline}
      label={t("Zones")}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
      {asLabel ? multi ?
        value?.length > 0 ?
          (<div>
            {value?.map((selectedValue, index) => (
              <span key={selectedValue}>
                {zones.find((zone) => zone.value === selectedValue)?.label || ""}
                {index < value.length - 1 && ", "}
              </span>
            ))}</div>) : <div style={{ color: "grey" }}>No Record Found</div>


        : (<div>{zones?.find((zone) => zone?.value == value)?.label ?? <div style={{ color: "grey" }}>No record Found</div>}</div>) :
        <>

          {modal && (
            <ModalComponent
              position={"top"}
              size={"medium"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Zone")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <Zone
                  value={value}
                  insidePane={true}
                  multiMode={multi}
                  onSelect={multi ? selectZones : selectZone}
                />
              </ModalComponent.Body>
            </ModalComponent>
          )}

          {zones.length > 0
            ? multi
              ? renderSelectFieldMultiple()
              : renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      }
    </>
  );
};

export default observer(ZoneSelect);
