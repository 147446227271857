import { toJS } from 'mobx';
import React, { useEffect, useState } from 'react';
import { SelectField } from '../../SelectField/SelectField';
import RateItemService from './RateItemSelectService';
import { Button, Col, Row } from 'reactstrap';
import { InputField } from '../../InputField/InputField';


const RateItemSelect = ({
    value, onChange
}) => {
    const [rateItem, setRateItem] = useState([]);

    useEffect(() => {
        if (!value) {
            onChange([{}]);
        }
    }, [value, onChange]);

    useEffect(() => {
        const fetch = async () => {
            try {
                await RateItemService.fetch();
                setRateItem(toJS(RateItemService.rateItem))
            } catch (error) {
                console.log(error.message);
            }
        }
        fetch();
    }, []);

    const addEmpty = () => {
        onChange([...value, {}]);
    };

    const handleDelete = (index) => {
        const updatedValue = [...value];
        updatedValue.splice(index, 1);
        onChange(updatedValue);
    };
    const handleItemChange = (index, newItem) => {
        const updatedValue = [...value];
        updatedValue[index] = newItem;
        onChange(updatedValue);
    };

    return (<>
        {(value && value.length > 0) && value.map((ri, index) => {
            //filter data.
            const selectedNames = [];
            for (let i = 0; i < value.length; i++) {
                const item = value[i];
                if (item && item.name) {
                    selectedNames.push(item.name);
                }
            }
            const filteredData = rateItem.filter(
                item => !selectedNames.includes(item.value) || item.value === ri.name
            );
            return (
                <div key={index} className='d-flex align-items-center'>
                    <SingleRateItem
                        data={filteredData}
                        value={ri}
                        onChange={(nri) => handleItemChange(index, nri)} />
                    {index === value.length - 1 && <Button className='ms-3 d-flex align-items-center' style={{
                        padding: '7px',
                        paddingRight: "10px",
                        paddingLeft: "10px",
                        marginTop: "5px"
                    }} outline onClick={addEmpty}>Add</Button>}
                    {index !== 0 && <Button outline onClick={() => handleDelete(index)}>Delete</Button>}
                </div>)

        })}

    </>);
}

const SingleRateItem = ({ data, value, onChange }) => {
    const handleOnChange = (fieldName, fieldValue) => {
        const val = value || {};
        onChange({ ...val, [fieldName]: fieldValue });
    }
    return (<Row>
        <Col>
            <SelectField
                data={data}
                value={value?.name}
                outline
                noLabel
                onChange={(v) => handleOnChange("name", v.value)}
                label={"Rate Item Name"}
            />
        </Col>
        <Col>
            <InputField
                outline
                label="Rate Item Value"
                value={value?.value}
                onChange={(v) => handleOnChange("value", v)}
            />
        </Col>
    </Row>)

}
export default RateItemSelect;









