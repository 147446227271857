import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useRef, useState, useImperativeHandle } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditPaymentReceived from "./EditPaymentReceived";
import { STRUCTURE } from "./PaymentReceivedConstant";
import { GridColumns } from "./PaymentReceivedGridColumn";
import PaymentReceivedService from "./PaymentReceivedService";
import moment from "moment";
import EmployeeSupervisorLabel from "../../components/Dropdowns/EmployeeSupervisorSelect/EmployeeSupervisorLabel";


const PaymentReceived = ({ insidePane }) => {

  let { edit_id } = useParams();
  const location = useLocation();
  const childRef=useRef(null);

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const queryParamsRef = useRef({ prev: new URLSearchParams(), current: new URLSearchParams(location.search) });



  const [fromDate, setFromDate] = useState(queryParams.get('fromDate') ? queryParams.get('fromDate') : null);
  const [tillDate, setTillDate] = useState(queryParams.get('tillDate') ? queryParams.get('tillDate') :null);
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({
    settlements: [{}]
  });
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/payment-received/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await PaymentReceivedService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const prepareFilter = useCallback((filterURL) => {
    try {
      const prevFilterUrl = new URLSearchParams( queryParams.toString());//queryParams for first time
      console.log({ prevFilterUrl })
      //original filter url is stored in BookingService.filterURL so just update it with new filter url whenever anything changes
      // const currentQueryParams = new URLSearchParams(BookingService.filterURL);
      const currentQueryParams = new URLSearchParams(prevFilterUrl);
      const newFilterUrlParams = new URLSearchParams(filterURL);//this one is updated this may contain status or sortBy anything
      // Iterate over newFilterUrlParams and update/append values in currentQueryParams
      for (const [key, value] of newFilterUrlParams.entries()) {
        currentQueryParams.set(key, value);
      }
      let dateQuery = null;
      //if fromDate or tillDate in query is not present // or not same to fromDate or tillDate  then first check fromDate,tillDate state if yes then pick up those else default (as maybe we have changed fromdate in local and not searched yet)
      dateQuery = `btw[${fromDate ?? null},`;
      dateQuery += `${tillDate ?? null}]`;
      if(fromDate && tillDate){
      currentQueryParams.set('date', dateQuery);
      }
      return currentQueryParams.toString();
    } catch (error) {
      showError(error)
    }
  }, [fromDate, tillDate, queryParams]);


  //call this function everytime if whether we are applying status, sorting or anything or reloading
  const onApplyFilter = useCallback((filterURL) => {

    const newFilterUrlParams = prepareFilter(filterURL);
    //in this case directly call fetchData to manually call api on press of filter search button 
    fetchData(newFilterUrlParams) 
  }, [queryParams, prepareFilter, fetchData,]);


  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane);
    let updatedFilterUrl = filterUrl; 
    // if (fromDate && tillDate) {
    //   updatedFilterUrl += `&date=btw[${fromDate},${tillDate}]`;
    // } else {
    //   setFromDate(null); 
    //   setTillDate(null);
    // }
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(updatedFilterUrl);
  }, [])

  const calculateTotalTdsSettled = (data) => {
    let total = 0;
    data?.forEach((acc) => {
        total += acc?.tdsSettled || 0;
    });
    return total;
};
  const calculateTotalAmountSettled = (data) => {
    let total = 0;
    data?.forEach((acc) => {
        total += acc?.amountSettled || 0;
    });
    return total;
};

const excessSettlement = (data) => {
  return data.find(settlement => (settlement?.amountSettled +  settlement?.tdsSettled + settlement?.writeOff) > settlement?.totalAmount)?.invoiceNo;
};


  const onSave = async (e) => {
    if(loading)return;
    e.preventDefault();
    if (!detailData?.amountReceived || !detailData?.clientCompany_id || !detailData?.client_id|| !detailData?.company_id || !detailData?.date || !detailData?.transactionRef) {
      showError(t("Please enter all fields"));
      return;
    }
    setLoading(true);
    try {
      if(detailData?.settlements.length){
        // const totalTDSSettled=calculateTotalTdsSettled(detailData?.settlements);
        // if(detailData?.tds && (totalTDSSettled!=detailData?.tds)){
        //     showError(t("Total TDS settled is not equal to total TDS Amount Received"));
        //     setLoading(false)
        //     return;
        // }
        const totalAmountSettled=calculateTotalAmountSettled(detailData?.settlements);
        if(totalAmountSettled > detailData?.amountReceived+ (detailData?.tds??0)){
            showError(t("Total amount settled is more than total  amount received"));
            setLoading(false)
            return;
        }
        const excessSettledInvoice=excessSettlement(detailData?.settlements);
        if(excessSettledInvoice){
          showError(t(`Amount settled for ${excessSettledInvoice} is more than total invoice amount of the invoice`));
          setLoading(false)
          return;
        }
      }
      if (editID) {
        await PaymentReceivedService.edit(detailData, editID);
        let response=await PaymentReceivedService.get(editID)
        setDetailData(response);
        childRef.current?.fetch(editID)
        showMessage(t("Record updated successfully."));
      } else {
        const paymentReceivedID = await PaymentReceivedService.save(detailData);
        if (paymentReceivedID) {
          setEditID(paymentReceivedID)
          let response = await PaymentReceivedService.get(paymentReceivedID)
          setDetailData(response);
          childRef.current?.fetch(paymentReceivedID)
        }
        showMessage(t("Record saved successfully."));
        if (!insidePane) navigate(`/payment-received/edit/${paymentReceivedID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    finally{
      setLoading(false);
    }
   
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if(loading)return;
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        setLoading(true)
        await PaymentReceivedService.delete(id);
        showMessage("Deleted", "Deleted");
        setEditID(null)
        setShowDetailPage(false);
      } catch (e) {
        showError(e);
      }
      finally{
        setLoading(false)
      }
    }
  };

  return (
    <>
      <Layout
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          setDetailData({});
          if (!insidePane) navigate("/payment-received");
          setEditID(null);
        }}
        large
        showDateRange
        fromDate={fromDate}
        tillDate={tillDate}
        setFromDate={setFromDate}
        setTillDate={setTillDate}
        defaultValue={[fromDate, tillDate].filter(date => date).map(date => moment(parseInt(date), 'YYYYMMDD'))}
        title={t("Payments Made")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={onApplyFilter}
        onAddClick={() => {
          if (!insidePane) navigate(`/payment-received/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={PaymentReceivedService.page}
        rows={PaymentReceivedService.rows}
        total={PaymentReceivedService.total}
        fetch={PaymentReceivedService.fetch}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem>Bulk Delete</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> Download </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={PaymentReceivedService.records}
              total={PaymentReceivedService.total}
              uiPreference="paymentReceived.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={setSelectedIDs}
              page={PaymentReceivedService.page}
              rowsPerPage={PaymentReceivedService.rowsPerPage}
              onPaginationChange={PaymentReceivedService.onPaginationChange}
              renderLastCol={(paymentReceived) => {
                return (
                  <>
                    <EditLink
                      onClick={() => {
                        if (!insidePane)
                          navigate(`/payment-received/edit/${paymentReceived?._id}`);
                        setEditID(paymentReceived?._id);
                        setShowDetailPage(true);
                      }}
                    />
                    {!paymentReceived?.paymentTransaction_id &&
                      <DeleteLink
                        onClick={(event) => onDelete(event, paymentReceived?._id)}
                      />
                    }
                  </>
                );
              }}
            />
          </>
        </Layout.Table>

        <Layout.DetailPageTitle>
          <div className="d-flex justify-content-between align-items-center">
            {detailData?._id ? t(`Edit Payment Received`) : t("Add Payment Received")}
          </div>

          {detailData?._id && <div className="d-flex align-items-center flex-1">
              <div className="mt-3">
                <span style={{ color: '#999999' }}>
                  {t(`Added At`)}
                </span>
                <span>
                  {"  "}
                  {moment.unix(detailData?.createdAt).format("DD-MM-YYYY  hh:mm A")}
                </span>
              </div>
              <div className="mt-3 mx-2 d-flex">
                <span style={{ color: '#999999' }}>
                  {t(`Added By`)} &nbsp;
                </span>
                {"  "}
                <EmployeeSupervisorLabel value={detailData?.createdBy} />
              </div>
            </div>}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditPaymentReceived
            ref={childRef}
            editId={editID}
            data={detailData}
            setData={(v) => {
              setDetailData(v)
            }}
            
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {(detailData?._id && !detailData?.paymentTransaction_id )&& <DeleteButton onClick={(e) => onDelete(e, detailData?._id)}  loading={loading}/>}
         {!detailData?.paymentTransaction_id && <SaveButton onClick={onSave} loading={loading} /> }
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(PaymentReceived);
