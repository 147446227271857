import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditPaymentGateways from "./EditPaymentGateway";
import { STRUCTURE } from "./PaymentGatewayConstant";
import { GridColumns } from "./PaymentGatewayGridColumns";
import PaymentGatewayService from "./PaymentGatewayService";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";


const PaymentGateways = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/payment-gateway/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await PaymentGatewayService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);

      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])


  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.paymentGateway) {
      setHasErr(true)
      showError(t("Please enter all required fields"));
      return;
    }
    setLoading(true);
    try {
      if (editID) {
        await PaymentGatewayService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Record updated successfully."));
      } else {
        const paymentGateway = await PaymentGatewayService.save(detailData);
        showMessage(t("Record saved successfully."));
        setLoading(false);
        if (!insidePane) navigate(`/payment-gateway/edit/${paymentGateway}`);
      }
      fetchData(filterURL);

    } catch (e) {
      showError(e);
      setLoading(false);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await PaymentGatewayService.delete(id);
        setLoading(false);
        showMessage("Deleted", "Deleted");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);

      try {
        await PaymentGatewayService.bulkDelete(selectedIDs);
        setLoading(false);
        showMessage("Deleted", "Deleted Successfully");
        navigate('/payment-gateway')
        setShowDetailPage(false)
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback((paymentGateway) => {
    return (
      <>
        <EditLink
          onClick={() => {
            if (!insidePane)
              navigate(`/payment-gateway/edit/${paymentGateway?._id}`);
            setEditID(paymentGateway?._id);
            setShowDetailPage(true);

          }}
        />
        {userContent?.rights?.includes(3203) ?

          <DeleteLink
            onClick={(event) => onDelete(event, paymentGateway?._id)}
          />
          : null}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(paymentGateway?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])


  return (
    <React.Fragment>
      <>
        <Layout
          hideAdd={!userContent?.rights?.includes(3201)}
          medium
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/payment-gateway");
            setEditID(null);
          }}
           
          title={t("Payment Gateway")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/payment-gateway/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={PaymentGatewayService.page}
          rows={PaymentGatewayService.rows}
          total={PaymentGatewayService.total}
          fetch={PaymentGatewayService.fetch}
        >

          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  {userContent?.rights?.includes(3203) ?
                  <>
                    <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                    <div class="dropdown-divider"></div>
                    </>
                    : null}
                  <DropdownItem>{t("Upload")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem> Download </DropdownItem>
                </>

              </DropdownMenu>

            </div>
          </Layout.ActionMenu>
          <Layout.Table>
            <DataGrid
              gridLoading={loading}
              data={PaymentGatewayService.records}
              total={PaymentGatewayService.total}
              uiPreference="paymentGateway.grid"
              headers={GridColumns}
              onSelectChange={(v) => {
                onSelect(v)
                setSelectedIDs(v)
              }}
              selectedIDs={selectedIDs}
              page={PaymentGatewayService.page}
              rowsPerPage={PaymentGatewayService.rowsPerPage}
              onPaginationChange={PaymentGatewayService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </Layout.Table>


          <Layout.DetailPageTitle>
            {detailData?._id ? t("Edit Payment Gateway") : t("Add Payment Gateway")}
          </Layout.DetailPageTitle>

          <Layout.DetailPageBody>
            <EditPaymentGateways
              editId={editID}
              onChange={(v) => {
                setDetailData(v)
              }}
              hasErr={hasErr}
            />
          </Layout.DetailPageBody>

          <Layout.DetailPageFooter>
            {detailData?._id ? (
              <AuditLogDialog module="PaymentGatewayAccount" id={detailData?._id} />
            ) : null

            }
            {userContent?.rights?.includes(1503) && detailData?._id ? <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />
              : null}
            {userContent?.rights?.includes(1502) ? <SaveButton onClick={onSave} loading={loading} />
              : null}
          </Layout.DetailPageFooter>
        </Layout>
      </>

    </React.Fragment>
  );
};
export default observer(PaymentGateways);
