// CustomOperationsService.js
import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil"; // Ensure these utility methods are implemented for HTTP requests
import { debounce } from "lodash";

class CustomOperationsService {
  groups = [];
  fields = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (module) => {
    // Replace with your API call logic
    const response = await doGET(`/api/custom-field/grid?module=${module}`);
    if (response.status == 200) {
      // this.fields = await response.data?.rows;

      const groupedFields = response.data?.rows?.reduce((acc, row) => {
        const { group, section } = row;
        if (!acc[group]) {
          acc[group] = {};
        }
        if (!acc[group][section]) {
          acc[group][section] = [];
        }
        const field = {
          ...row,
          label: row.label,
          field_id: row?.field_id,
          placeholder: row.placeholder,
          value: row.value ? row.value : "",
          fieldType: row.fieldType,
          options: row.options || [],
        };
        acc[group][section].push(field);
        return acc;
      }, {});

      const mappedFields = Object.entries(groupedFields).map(
        ([groupName, sections]) => {
          const mappedSections = Object.entries(sections).map(
            ([sectionName, fields]) => ({
              name: sectionName ?? "NoSection",
              fields,
            })
          );

          return {
            name: groupName ?? "NoGroup",
            sections: mappedSections,
          };
        }
      );
      console.log({ mappedFields })
      const fieldsData = []
      response.data?.rows?.map((data, index) => {
        if (data?.group) {

        } else {
          fieldsData?.push(data)
        }
      })
      this.groups = mappedFields
      this.fields = fieldsData
    }
  };

  addGroup = () => {
    this.groups.push({ id: Date.now(), name: '', sections: [], isNew: true });
  };

  addSection = (groupId) => {
    const group = this.groups.find(g => g.id === groupId);
    if (group) {
      group.sections.push({ id: Date.now(), name: '', fields: [], isNew: true });
    }
  };

  addAloneField = async ({
    module,
    group = null,
    section = null,
    fieldType = "text",
    label = "Label",
    placeholder = ""
  }) => {
    // const group = this.groups.find(g => g.id === groupId);
    // const section = group?.sections.find(s => s.id === sectionId);

    const field = {
      module,
      label,
      placeholder,
      fieldType,
      group,
      section,
    };

    const response = await doPOST(`/api/custom-field/create`, field);
    if (response.status == 200) {
      this.fetch(module);
    }

  };

  updateField = debounce(async (fieldId, fieldType, label, placeholder) => {
    const response = await doPUT(`/api/custom-field/update`, { fieldId, fieldType, label, placeholder });
    if (response.status == 200) {
      this.fetch();
    }
  }, 1000); // Debounce with a 1-second delay


  updateGroupName = (groupId, newName) => {
    const group = this.groups.find(g => g.id === groupId);
    if (group) group.name = newName;
  };

  updateSectionName = (groupId, sectionId, newName) => {
    const group = this.groups.find(g => g.id === groupId);
    const section = group.sections.find(s => s.id === sectionId);
    if (section) section.name = newName;
  };

  getGroups = () => {
    return this.groups;
  };

  addField = (groupId, sectionId, fieldType, label, placeholder) => {
    const group = this.groups.find(g => g.id === groupId);
    const section = group?.sections.find(s => s.id === sectionId);

    if (section) {
      section.fields.push({
        id: Date.now(),
        fieldType,
        label,
        placeholder,
        isNew: true
      });
    }

    // TODO :  create post api and then called fetch api again
    // TODO :  to show it on UI
    // TODO :  create a new component CreateField.js that show a dropdown to select field type and label and placeholder for field on UI
    // TODO : use debounce to call api if any of fieldType, label or placeholder changes after every 1 seconds
    // TODO : after update api you have to call detail api also , because version is maintained in db , if you pass lower verion update api will throw error 
  };

  updateFieldType = (groupId, sectionId, fieldId, newType) => {
    const group = this.groups.find(g => g.id === groupId);
    const section = group?.sections.find(s => s.id === sectionId);
    const field = section?.fields.find(f => f.id === fieldId);
    if (field) {
      field.fieldType = newType;
    }
    this.version++; // Optionally increment version to trigger MobX reactions if needed
  };

  updateFieldLabel = (groupId, sectionId, fieldId, newLabel) => {
    const group = this.groups.find(g => g.id === groupId);
    const section = group?.sections.find(s => s.id === sectionId);
    const field = section?.fields.find(f => f.id === fieldId);
    if (field) {
      field.label = newLabel;
    }
    this.version++; // Optionally increment version to trigger MobX reactions if needed
  };

  updateFieldPlaceholder = (groupId, sectionId, fieldId, newPlaceholder) => {
    const group = this.groups.find(g => g.id === groupId);
    const section = group?.sections.find(s => s.id === sectionId);
    const field = section?.fields.find(f => f.id === fieldId);
    if (field) {
      field.placeholder = newPlaceholder;
    }
    this.version++; // Optionally increment version to trigger MobX reactions if needed
  };
}

const customOperationsService = new CustomOperationsService();
export default customOperationsService;
