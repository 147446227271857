export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/o/state/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  get: (id) => `/api/state/${id}/detail`,
  save: `/api/state/create`,
  delete: (id) => `/api/state/${id}/delete`,
  update: `/api/state/update`,
};

export const STRUCTURE = [
  {
    label: "Country",
    filterLabel: " Country",
    filterName: "country",
    type: "countrySelect",
  },

  {
    label: "Name",
    filterLabel: "State Name",
    filterName: "name",
    type: "stateSelect",
    dependencyOnMe: ["city"],
    dependency: {
      country: "country",
    },
  }
];

