export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/fuel/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  get: (id) => `/api/fuel/${id}/detail`,
  save: `/api/fuel/save`,
  delete: (id) => `/api/fuel/${id}/delete`,

  download: (filterUrl, template_id) => {
    let url = `/api/fuel/download/template?template_id=${template_id}&`;
    if (filterUrl) url += filterUrl;
    return url;
  },
};


export const CSTRUCTURE = [
  {
    label: "Start Date",
    description: "description...",
    filterLabel: "Start Date",
    filterName: "startDate",
    type: "dateSelect",
  },
  {
    label: "End Date",
    description: "description...",
    filterLabel: "End Date",
    filterName: "endDate",
    type: "dateSelect",
  },
  {
    label: "Vehicle",
    description: "description...",
    filterLabel: "Vehicle",
    filterName: "name",
    type: "select",
  },
  {
    label: "Fuel pump",
    description: "description...",
    filterLabel: "Fuel pump",
    filterName: "pump_id",
    type: "select",
    data: [{ label: "deepak pump", value: "622210df4333853f3f1098f0" }],
  },
  {
    label: "Fuel Type",
    description: "description...",
    filterLabel: "Fuel Type",
    filterName: "fuelType",
    type: "select",
    data: [
      { label: "Diesel", value: "Diesel" },
      { label: "Petrol", value: "Petrol" },
      { label: "CNG", value: "CNG" },
      { label: "Electric", value: "Electric" },
    ],
  },
  {
    label: "Reciept No",
    description: "description...",
    filterLabel: "Reciept No",
    filterName: "receiptNo",
    type: "text",
  },
  {
    label: "Paid in cash ",
    description: "description...",
    filterLabel: "Paid in cash",
    filterName: "cash",
    type: "checkBox",
  },
];

export default CSTRUCTURE;
