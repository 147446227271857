import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { BottomBarButton, Modal, ModalComponent, SearchButton } from "../../../components/index";
import Client from "../../../pages/Clients/Client";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import ClientSelectService from "./ClientSelectService";
import { UserContext } from "../../../store/context/UserContext";

const ClientSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  asLabel,
  plain,
  outline,
  wrapping,
      ...props 
}) => {
  const [clients, setClients] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { userContent } = useContext(UserContext);
  const { t } = useContext(I18nContext)

  const fetchClients = async () => {
    try {
      await ClientSelectService.fetch();
      if (value == "selectFirst") {
        onChange(toJS(ClientSelectService.clients)[0])
      }
      setClients(toJS(ClientSelectService.clients));
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (ClientSelectService.clients.length === 0 && userContent?.owner_id && userContent?.adminOf === userContent?.owner_id) {
      fetchClients();
    }
  }, [ClientSelectService.clients, userContent?.owner_id]);

  useEffect(() => {
    if (value == "selectFirst") {
      onChange(toJS(ClientSelectService.clients)[0])
    }
    setClients(toJS(ClientSelectService.clients));
  }, [ClientSelectService.clients]);




  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectClient = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0]?._id : selectedValue?._id });
    closeModal();
  };

  const selectClients = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Clients")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={clients}
      required={required}
      value={value}
      label={t(`${label || 'Client'}`)}
      error={!value ? t(`Please Select ${label || "Client"}`) : ""}
      showErr={showErr}
      plain={plain}
      outline={outline}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={ (e) => {
          e.stopPropagation()
          if(!isDisabled) {
            openModal()
          }
        }}/>
      )}
      {...props}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Clients")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={selectClients}
      data={clients}
      value={value ? clients.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Clients"}`) : ""}
      required={required}
      plain={plain}
      outline={outline}
      label={t("Clients")}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={ (e) => {
          e.stopPropagation()
          if(!isDisabled) {
            openModal()
          }
        }} />
      )}
    />
  );

  return (
    <>
      {asLabel ? multi ?
        value?.length > 0 ?
          (<div>
            {wrapping ?
              <div>
                {value?.slice(0, wrapping).map((selectedValue, index) => (
                  <span key={selectedValue}>
                    {clients.find((client) => client.value === selectedValue)?.label || ""}
                    {index < wrapping - 1 && ", "}
                  </span>
                ))}
                {value.length > wrapping &&
                  ` + ${value.length - wrapping} `
                }
              </div>
              :
              value?.map((selectedValue, index) => (
                <span key={selectedValue}>
                  {clients.find((client) => client.value === selectedValue)?.label || ""}
                  {index < value.length - 1 && ", "}
                </span>
              ))
            }
          </div>) : <div style={{ color: "grey" }}>
            {userContent?.owner_id && userContent?.adminOf !== userContent?.owner_id ? `Self` : `No Record Found`}</div>


        : (<div>{clients?.find((client) => client?.value == value)?.label ?? <div style={{ color: "grey" }}>{userContent?.owner_id && userContent?.adminOf !== userContent?.owner_id ? `Self` : `No Record Found`}</div>}</div>) :
        <>

          {modal && (
            <ModalComponent
              position={"top"}
              size={"medium"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Client")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <Client
                  value={value}
                  insidePane={true}
                  multiMode={multi}
                  onSelect={multi ? selectClients : selectClient}
                />
              </ModalComponent.Body>
            </ModalComponent>
          )}

          {clients.length > 0
            ? multi
              ? renderSelectFieldMultiple()
              : renderSelectField({ disabled: false })
            : renderSelectField({ disabled: false })}
        </>
      }
    </>
  );
};

export default observer(ClientSelect);
