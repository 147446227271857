export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/permit/grid?`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    get: (id) => `/api/permit/${id}/detail`,
    save: `/api/permit/create`,
    // delete: (id) => `/api/permit/${id}/delete`,
    delete: (id, permitOf, assetKind) => `/api/permit/${id}/delete${permitOf ? `?permitOf=${permitOf}` : ""}${assetKind ? `&assetKind=${assetKind}` : ""}`,
    // delete: (id, permitOf,assetKind) => `/api/permit/${id}/delete${permitOf ? `?permitOf=${permitOf}` : ""}`,
    bulkDelete: (id) => `/api/permit/delete`,

    update: `/api/permit/update`,
};
export const SELECTSTRUCTURE = {
    assetKind: [
        { label: "employee", value: "employee" },
        { label: "Vehicle", value: "vehicle" },

    ],

}
export const STRUCTURE = [
    {
        label: "Name",
        filterLabel: "Permit Name",
        filterName: "name",
        type: "text",
    },
    {
        label: "AssetKind",
        filterLabel: "AssetKind",
        filterName: "assetKind",
        type: "text",
    },
    {
        label: "Info",
        filterLabel: "Info",
        filterName: "info",
        type: "text",
    },
    {
        label: "IssuingAuthority",
        filterLabel: "IssuingAuthority",
        filterName: "issuingAuthority",
        type: "text",
    },
    {
        label: "Start Date",
        filterLabel: "Start Date",
        filterName: "startDate",
        type: "text",
    },
    {
        label: "Cost",
        filterLabel: "Cost",
        filterName: "cost",
        type: "text",
    },
    {
        label: "Expiry",
        filterLabel: "Expiry",
        filterName: "expiry",
        type: "text",
    },
];


export const vehiclePermitData = [
    { label: "RC" },
    { label: "PUC" },
    { label: "Insaurance" },
    { label: "MCD" }
]
