import { makeAutoObservable } from 'mobx';

class CRMService {
  activeTab = 'email';
  selectedEmailId = null;
  selectedMessageId = null;

  constructor() {
    makeAutoObservable(this);
  }

  setTab(tab, navigate) {
    this.activeTab = tab;
    this.selectedEmailId = null;
    this.selectedMessageId = null;
    navigate(`/crm/${tab}`);
  }

  setTabFromLocation(pathname) {
    const parts = pathname.split('/');
    this.activeTab = parts[2] || 'email';
    if (this.activeTab === 'email') {
      this.selectedEmailId = parts[3] || null;
    } else if (this.activeTab === 'whatsapp') {
      this.selectedMessageId = parts[3] || null;
    }
  }

  navigateToEmail(emailId, navigate) {
    navigate(`/crm/email/${emailId}`);
  }

  navigateToMessage(messageId, navigate) {
    navigate(`/crm/whatsapp/${messageId}`);
  }
}

export default new CRMService();
