export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/users/gridForAdmin?sortAsc=true&sortBy=name&rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + `&page=${page}`;
  },
  get: (id) => ` /api/user/${id}/detail`,
};
export const STRUCTURE = [
  {

    label: "Last Login From Date",
    filterLabel: "Last Login From Date",
    filterName: "lastLoginFrom",
    description: "description...",
    type: "dateTimeSelect",

  },

  {

    label: "Last Login To Date",
    filterLabel: "Last Login to Date",
    filterName: "lastLoginTo",
    description: "description...",
    type: "dateTimeSelect",

  },

  {
    label: "Name",
    description: "description...",
    filterLabel: " Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Country Code",
    description: "description...",
    filterLabel: " countryCode",
    filterName: "countryCode",
    type: "text",
  },

  {
    label: "Phone no. ",
    description: "description...",
    filterLabel: " phone ",
    filterName: "phone",
    type: "text",
  },
  {
    label: "Country",
    description: "description...",
    filterLabel: "Country",
    filterName: "address.country",
    type: "countrySelect",
    dependencyOnMe: ["address.city"],
  },
  {
    label: "City",
    description: "description...",
    filterLabel: "City",
    filterName: "address.city",
    type: "citySelect",
    onlyCountry: "onlyCountry",
    dependency: {
      country: "address.country",

    },
  },
  {
    label: "Version Code ",
    description: "description...",
    filterLabel: "Version",
    filterName: "version",
    type: "text",
  },

  {
    label: "Gender",
    description: "description...",
    filterLabel: "Gender",
    filterName: "gender",
    type: "select",
    data: [

      {
        label: "Female",
        value: 1,
      },
      {
        label: "Male",
        value: 0,
      },
      {
        label: "Other",
        value: 2,
      },
    ],
  },

];


