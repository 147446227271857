import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./MarketPlaceConstant";
import { doGET, doPOST } from "../../util/HttpUtil";

class Service {
    records = [];
    totalRecords = 0;
    downloadFilterOption = '';
    page = 1;
    rows = 20;

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async function (filterUrl) {
        if (filterUrl) {
            this.downloadFilterOption = filterUrl;
        }
        const response = await doGET(
            ENDPOINTS.grid(this.page, this.rows, filterUrl)
        );
        if (response.status === 200) {
            this.records = response.data.rows;
            this.totalRecords = response.data.records;
        }
    };

    get = async function (id) {
        const response = await doGET(ENDPOINTS.get(id));

        if (response?.status === 200) {
            return response.data;
        }
    };
    getCompany = async function (id) {
        const response = await doGET(ENDPOINTS.getCompany(id));

        if (response?.status === 200) {
            return response.data;
        }
    };

    saveNew = async function (data) {
        const response = await doPOST(ENDPOINTS.saveNew, data);
        return response?.data?._id;
    };

    save = async function (id, data) {
        await doPOST(ENDPOINTS.save(id), data);

    };

    picUpload = async function (data) {
        await doPOST(ENDPOINTS.picUpload, data);
        this.fetch();
    };

    delete = async function (id) {
        const response = await doGET(ENDPOINTS.delete(id));
        if (response.status === 200) {
            // DriverSelectService.fetch();
            this.fetch();
        }
    };

    picDelete = async function (id, time) {
        const response = await doGET(ENDPOINTS.picDelete(id, time));
        if (response.status === 200) {
        }
    };

    assignDriver = async (vehicleID, driverID) => {
        const response = await doGET(ENDPOINTS.assignDriver(vehicleID, driverID));
    };

    changeProfilePic = async function (id, time) {
        const response = await doGET(ENDPOINTS.changeProfilePic(id, time));
        if (response.status === 200) {
        }
    };

    onPaginationChange = async (page, rows) => {
        this.page = page;
        this.rows = rows;
        this.fetch();
    };

    setDownloadFilter = (filterData) => {
        if (filterData) this.downloadFilterOption = filterData;
        else this.downloadFilterOption = '';
    }

    downloadByTemplate = async (filterData, template_id) => {
        const response = await doGET(
            ENDPOINTS.download(this.downloadFilterOption, template_id)
        );

        if (response.status === 200) {
            console.log(response);
            return response;
        }
    };
}

const MarketPlaceService = new Service();
export default MarketPlaceService;
