import React, { useState } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { MdOutlineEdit, MdOutlinePhone } from 'react-icons/md';
import { CrossButton } from '../../../../components';
import { useModal } from '../../../../store/context/ModalContext';
import EditUser from '../../../Users/EditUser';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { TbEdit } from "react-icons/tb";

const CustomerDisplay = ({ userData, gridLoading, customerId, handleEditModal = () => {} }) => {
    const { toggleModal, setIsOpen, setModalContent, setSize } = useModal();
    const [editID, setEditID] = useState(customerId);

    const handleEditButtonClick = (e) => {
        setSize("large");
        e.stopPropagation();
        setSize("large")
        toggleModal();
        setModalContent({
            title: (
                <div className='zIndex-2 ps-4'>
                    <h3 className="align-self-center fs-5 me-3 mt-3">Edit Customer</h3>
                    <CrossButton className="custom_modal_close" onClick={() => { setIsOpen(false); handleEditModal(); }} />
                </div>
            ),
            body: (
                <EditUser
                    onSave={() => { }}
                    onDelete={() => { }}
                    insidePane
                    onBack={() => setEditID(null)}
                    editId={customerId}
                />
            ),
        });
    };

    const renderSkeletonLoader = () => (
        <>
            <div className='d-flex flex-row justify-content-between m-3 flex-1'>
                <div className='d-flex flex-column flex-1'>
                    <Skeleton height={20} width={180} className='mb-1' />
                    <Skeleton height={20} width={180} className='text-crm-greyish font-size-12' />
                    <Skeleton height={20} width={180} className='text-crm-greyish font-size-12 fw-bold' />
                </div>
            </div>
            <div className='d-flex flex-row justify-content-between m-1 w-100'>
                <div className='d-flex align-items-center ms-2'>
                    <AiOutlineMail size={20} className='mx-1' />
                    <Skeleton height={20} width={100} className='text-crm-greyish font-size-12 fw-bold' />
                </div>
                <div className='d-flex align-items-center me-2'>
                    <MdOutlinePhone size={20} className='mx-1' />
                    <Skeleton height={20} width={60} className='text-crm-greyish font-size-12 fw-bold' />
                </div>
            </div>
        </>
    );

    const renderContent = () => (
        <>
            <div className='d-flex flex-row justify-content-between m-3 flex-1'>
                <div className='d-flex flex-column flex-1'>
                    <span className='text-crm-blue font-size-14 mb-1'>{userData?.name}</span>
                    <span className='text-crm-greyish font-size-12'>{userData?.designation}</span>
                    <span className='text-crm-greyish font-size-12 fw-bold'>
                        {userData?.company || userData?.dept}
                    </span>
                </div>
                <TbEdit size={20} onClick={handleEditButtonClick} className='d-flex flex-row justify-content-end' />
            </div>
            <div className='d-flex flex-row justify-content-between mx-2 w-100 flex-wrap'>
                {userData?.email && <div className='d-flex align-items-center'>
                    <AiOutlineMail size={20} className='mx-1' />
                    <span className='text-crm-greyish font-size-12 fw-bold'>{userData?.email}</span>
                </div>}
                {userData?.phoneNo?.phone && <div className='d-flex align-items-center'>
                    <MdOutlinePhone size={20} className='mx-1' />
                    <span className='text-crm-greyish font-size-12 fw-bold'>
                        {"+ " + userData?.phoneNo?.countryCode + " " + userData?.phoneNo?.phone}
                    </span>
                </div>}
            </div>
        </>
    );

    return gridLoading ? renderSkeletonLoader() : renderContent();
};

export default CustomerDisplay;