import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { ThreeBounce } from "better-react-spinkit";
import { UserContext } from "../../store/context/UserContext";
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditItineraries from "./EditItinerary";
import { STRUCTURE } from "./ItineraryConstant";
import { GridColumns } from "./ItineraryGridColumns";
import ItineraryService from "./ItineraryService";
import ItinerarySidebar from "./sideBar/ItinerarySidebar";
import ItinerarySidebarService from "./sideBar/ItinerarySidebarService";
import "./style.css"

const Itineraries = ({ forClone, value, insidePane, multiMode, onSelect = () => { } }) => {
    const { userContent } = useContext(UserContext);
    let { edit_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(edit_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/itinerary/create" || edit_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)

    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await ItineraryService.fetch(filterUrl);
            setLoading(false);
        } catch (e) {
            showError(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (ItinerarySidebarService.selectedFolder?._id ) {
            const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
            setFilterURL(filterUrl)
            setFilterObject(filterObject)
            fetchData(filterUrl);
            multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
        }
    }, [ItinerarySidebarService.selectedFolder?._id])


    useEffect(() => {
        setEditID(edit_id)
    }, [edit_id])

    const onSave = async (e) => {
        e.preventDefault();
        // if (!detailData?.status && !detailData?.remark) {
        //     setHasErr(true)
        //     showError(t("Please enter all required fields"));
        //     return;
        // }
        setLoading(true);
        try {
            if (editID) {
                await ItineraryService.edit(detailData, editID);
                setLoading(false);
                setShowDetailPage(false);
                showMessage(t("Record updated successfully."));
            } else {
                const itinerary = await ItineraryService.save({
                    ...detailData,
                    space_id: ItinerarySidebarService?.selectedFolder?._id
                });
                setLoading(false);
                setShowDetailPage(false);
                showMessage(t("Record saved successfully."));
                if (!insidePane) navigate(`/itinerary/edit/${itinerary}`);
            }
            fetchData(filterURL);

        } catch (e) {
            showError(e);
            setLoading(false);
        }
        setHasErr(false)
    };
    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await ItineraryService.delete(id);
                setLoading(false);
                setShowDetailPage(false);
                showMessage(t("Deleted"), t("Deleted"));
            } catch (e) {
                showError(e);
                setLoading(false);
            }
        }
    };
    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await ItineraryService.bulkDelete(selectedIDs);
                setLoading(false);
                showMessage(t("Deleted"), t("Deleted Successfully"));
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };



    return (
        <div className="d-flex">
            {loading ? 
            <div className="loading-backdrop">
                <ThreeBounce size = {30} color = "pink"/>
            </div>  : <></>}
            <div className={insidePane ? "" : "page-content"}>
                <ItinerarySidebar />
            </div>

            <div className="flex-1">
                <Layout
                    hideAdd={!userContent?.rights?.includes(6101) || ItinerarySidebarService.records.length == 0}
                    large
                    showDetailPage={showDetailPage}
                    backDetailPage={async () => {
                        setShowDetailPage(false);
                        if (!insidePane) navigate("/itinerary");
                        setEditID(null);
                    }}
                    title={t("Itineraries")}
                    filterValues={filterObject}
                    filterStructure={STRUCTURE}
                    onApplyFilter={fetchData}
                    onAddClick={() => {
                        if (!insidePane) navigate(`/itinerary/create`);
                        setShowDetailPage(true);
                        setEditID(null);
                    }}
                    insidePane={insidePane}
                    page={ItineraryService.page}
                    rows={ItineraryService.rows}
                    total={ItineraryService.total}
                    fetch={ItineraryService.fetch}
                >
                    <Layout.ActionMenu>
                        <div className="layout-action-menu">
                            <DropdownMenu>
                                <>
                                    {userContent?.rights?.includes(5903) ?
                                    <>
                                        <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                                        <div class="dropdown-divider"></div>
                                        </>
                                        : null}
                                    <DropdownItem>{t("Upload")}</DropdownItem>
                                    <div class="dropdown-divider"></div>
                                    <DropdownItem> Download </DropdownItem>
                                </>

                            </DropdownMenu>

                        </div>
                    </Layout.ActionMenu>
                    <Layout.Table>
                        {
                            ItinerarySidebarService.records?.length ? 
                                <DataGrid
              gridLoading={loading}
                                    data={ItineraryService.records}
                                    total={ItineraryService.total}
                                    uiPreference="itinerary.grid"
                                    headers={GridColumns}
                                    onSelectChange={(v) => {
                                        onSelect(v)
                                        setSelectedIDs(v)
                                    }}
                                    selectedIDs={selectedIDs}
                                    page={ItineraryService.page}
                                    rowsPerPage={ItineraryService.rowsPerPage}
                                    onPaginationChange={ItineraryService.onPaginationChange}
                                    renderLastCol={(itinerary) => {
                                        return (
                                            <>
                                                {userContent?.rights?.includes(6102) &&
                                                    <EditLink
                                                        onClick={() => {
                                                            if (!insidePane)
                                                                navigate(`/itinerary/edit/${itinerary?._id}`);
                                                            setEditID(itinerary?._id);
                                                            setShowDetailPage(true);
                                                        }}
                                                    />
                                                }
                                                {userContent?.rights?.includes(6103) ?
                                                    <DeleteLink
                                                        onClick={(event) => onDelete(event, itinerary?._id)}
                                                    />
                                                    : null}

                                                {!multiMode && insidePane ? (
                                                    <CheckButton
                                                        onClick={async () => {
                                                            try {
                                                                if (forClone) {
                                                                    if (
                                                                        await showConfirm({
                                                                            title: t("Do you want to clone itinerary?"),
                                                                            description: t("This will clone itinerary into lead."),
                                                                        })
                                                                    ) {
                                                                        onSelect(itinerary?._id);
                                                                        return
                                                                    }
                                                                } else {
                                                                    onSelect(itinerary?._id);
                                                                }
                                                            } catch (error) {
                                                                console.log(error)
                                                            }
                                                        }}
                                                    />
                                                ) : null}
                                            </>
                                        );
                                    }}
                                /> : <div className="d-flex justify-content-center h3 py-5 my-5"> 
                                    Create Folder to Create Itinerary
                                </div>
                        }
                       
                    </Layout.Table>


                    <Layout.DetailPageTitle>
                        {detailData?._id ? t("Edit Itinerary") : t("Add Itinerary")}
                    </Layout.DetailPageTitle>

                    <Layout.DetailPageBody>
                        <EditItineraries
                            editId={editID}
                            onChange={(v) => {
                                setDetailData(v)
                            }}
                            hasErr={hasErr}
                        />
                    </Layout.DetailPageBody>

                    <Layout.DetailPageFooter>
                        {editID && userContent?.rights?.includes(6103) && detailData?._id ?
                            <DeleteButton
                                loading={loading}
                                onClick={(e) => onDelete(e, detailData?._id)} />
                            : null}
                        {userContent?.rights?.includes(6102) ?
                            <SaveButton
                                loading={loading}
                                onClick={onSave} />
                            : null}
                    </Layout.DetailPageFooter>
                </Layout>
            </div>
        </div>
    );
};
export default observer(Itineraries);
