import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import { FaChevronDown, FaChevronRight, FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import { PiDotsThreeVerticalBold } from 'react-icons/pi';
import { useOutsideAlerter } from '../board/helper';
import SpaceService from '../listView/SpaceService';
import "./style.scss";
import { DialogContext } from '../../../store/context/DialogContext';

const SpaceItem = ({
    space,
    onToggleCollapse,
    onSpaceClick,
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [editId, setEditId] = useState({});
    const [editName, setEditName] = useState("");
    const [isAddingChild, setIsAddingChild] = useState(false);
    const [newChildName, setNewChildName] = useState("");
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const { showError } = useContext(DialogContext);
    useOutsideAlerter(dropdownRef, () => setIsOpen(false));

    const showAddChildInput = () => {
        setIsAddingChild(true);
        setTimeout(() => inputRef.current && inputRef.current.focus(), 0);
    };

    const handleAddChildSpaceKeyDown = async (event) => {
        if (event.key === 'Enter' && newChildName.trim() !== '') {
            // Call the SpaceService save method with parentSpace_id set to current space's _id
            await SpaceService.save({
                module: "lead",
                name: newChildName.trim(),
                parentSpace_id: space._id,
                statusNameMap: {
                    1: {
                        name: "New",
                        color: "#d33d44"
                    },
                    30: {
                        name: "Archived",
                        color: "#5f55ee"
                    },
                }
            });
            setNewChildName('');
            setIsAddingChild(false);
            SpaceService.fetch();
        }
    };

    const handleEditSpace = async ({ e, id }) => {

        try {
            if (e.key === 'Enter') {
                await SpaceService.edit({
                    ...editId,
                    module: "lead",
                    name: editName
                });
                setEditId({});
                setEditName("");
            }
        } catch (error) {
            showError(error)
        }


    };

    const handleDeleteSpace = async (id) => {
        await SpaceService.delete(id);
    };

    return (
        <div ref={dropdownRef} className={`zr_status_dropdown  w-100 space-item-container ${SpaceService.selectedSpace?._id === space._id ? " selectedSpace" : ""} ${space?.parentSpace_id ? "child-space-item" : ""}`} >
            {(editId?._id && (editId?._id === space._id)) ? (
                <input
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                    onKeyDown={(e) => handleEditSpace({ e, id: space._id })}
                    placeholder='Edit Space'
                    autoFocus
                    className='m-0'
                    style={{ height: "30px" }}
                />
            ) : (
                <div
                    className={` space-item d-flex align-items-center`}
                    onClick={() => onSpaceClick(space)}>
                    {!space?.parentSpace_id && <span className="me-2" onClick={onToggleCollapse}>
                        {space.collapsed ? <FaChevronRight /> : <FaChevronDown />}
                    </span>}
                    <div className={!space?.parentSpace_id ? 'fw-bold' : ""} style={{ fontSize: "14px" }}>
                        {space.name}
                    </div>

                    <div className="icon-container d-flex align-items-center ms-auto">
                        <div onClick={(e) => {
                            e.stopPropagation()
                            setIsOpen(true)
                        }}><PiDotsThreeVerticalBold size={20} /></div>
                        {!space?.parentSpace_id && <FaPlus className="ms-2 add-icon" onClick={() => {
                            showAddChildInput()
                        }} />}
                    </div>

                    {isOpen && (
                        <ul className="zr_status_list">
                            <li
                                className={`zr_status_item `}
                                onClick={(e) => { e.stopPropagation(); setEditName(space.name); setEditId(space); }}
                            >
                                <FaPen className="me-2 edit-icon" />
                                Rename
                            </li>

                            <li
                                className={`zr_status_item `}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteSpace(space._id);
                                }}
                            >
                                <FaTrash className="me-2 delete-icon" />
                                Delete
                            </li>
                        </ul>
                    )}

                </div>
            )}
            {isAddingChild && (<input
                ref={inputRef}
                type="text"
                value={newChildName}
                onChange={(e) => setNewChildName(e.target.value)}
                onKeyDown={handleAddChildSpaceKeyDown}
                onBlur={() => {
                    setNewChildName('');
                    setIsAddingChild(false);
                }}
                style={{ marginLeft: "18px" }}
                className="child-space-input"
                placeholder="Space name"
            />)}
        </div>
    );
};

export default observer(SpaceItem);
