import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, DropdownItem, DropdownMenu, Row } from "reactstrap";
import {
  CheckButton,
  AddButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  InputField,
  Layout,
  SaveButton,
} from "../../../components";
import { STRUCTURE } from "./NameMasterConstant";
import { GridColumns } from "./NameGridColumn";
import NameMasterService from "./NameMasterService";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../../util/FilterUtil";
import EditNameMaster from "./EditNameMaster";

const NameMaster = ({ insidePane, kind, newData, setNewdata, multiMode, onSelect = () => { } }) => {
  let { name_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [toggle, setToggle] = useState(false);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({
    groupColor: "#000000"
  });
  const [editID, setEditID] = useState(name_id);
  const [showDetailPage, setShowDetailPage] = useState(
    insidePane
      ? false
      : window.location.pathname === "/namemaster/create" || name_id
  );
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await NameMasterService.fetch(filterUrl, kind);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(
      queryParams,
      STRUCTURE
    );
    setFilterURL(filterUrl);
    setFilterObject(filterObject);
    fetchData(filterUrl);
  }, []);
  const onSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (editID) {
        await NameMasterService.edit(detailData, editID);
        showMessage(t("NameMaster updated successfully."));
      } else {
        const nameMasterId = await NameMasterService.save(detailData);
        showMessage(t("NameMaster saved successfully."));
        if (!insidePane) navigate(`/namemaster/edit/${nameMasterId}`);
      }
      fetchData(filterURL);
      setNewdata(!newData);
      setToggle(false);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete NameMaster?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await NameMasterService.delete(id);
        fetchData(filterURL);
        setNewdata(!newData);

        showMessage("Deleted SucessFully", "Deleted");
      } catch (e) {
        showError(e);
      }
    }
  };

  return (
    <div>
      <Layout
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/namemaster");
          setEditID(null);
        }}
        onAddClick={() => {
          setDetailData({
            groupColor: "#000000"
          });
          setToggle(true);
        }}
         
        title={t("Names")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        insidePane={insidePane}
        page={NameMasterService.page}
        rows={NameMasterService.rows}
        total={NameMasterService.total}
        fetch={NameMasterService.fetch}
      >
        <Layout.Table>
          <div className="mb-4">
            {toggle ? (
              <div>
                <Row className=" mt-4 ml-4">
                  <Col className="col-lg-4">
                    <InputField
                      value={detailData?.name}
                      label={"Name"}
                      onChange={(v) =>
                        setDetailData({ ...detailData, name: v, kind: kind })
                      }
                    />
                  </Col>
                  <Col className="col-lg-4"><InputField
                    value={detailData?.group}
                    label={"Group"}
                    onChange={(v) =>
                      setDetailData({ ...detailData, group: v, kind: kind })
                    }
                  /></Col>
                  <Col className="col-lg-3"><InputField
                    value={detailData?.groupColor}
                    label={"Color"}
                    type="color"
                    onChange={(v) =>
                      setDetailData({ ...detailData, groupColor: v, kind: kind })
                    }
                  /></Col>
                  <SaveButton onClick={onSave} />
                </Row>
              </div>
            ) : (
              ""
            )}
          </div>
          <>
            <DataGrid
              gridLoading={loading}
              data={NameMasterService.records}
              total={NameMasterService.total}
              uiPreference="state.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={setSelectedIDs}
              page={NameMasterService.page}
              rowsPerPage={NameMasterService.rowsPerPage}
              onPaginationChange={NameMasterService.onPaginationChange}
              renderLastCol={(namemaster) => {
                return (
                  <>
                    <EditLink
                      onClick={() => {
                        if (!insidePane)
                          navigate(`/state/edit/${namemaster?._id}`);
                        setEditID(namemaster?._id);
                        setToggle(true);
                        setDetailData(namemaster);
                        // setShowDetailPage(true);
                      }}
                    />
                    <DeleteLink
                      onClick={(event) => onDelete(event, namemaster?._id)}
                    />
                    {!multiMode && insidePane ? (
                      <CheckButton
                        onClick={() => {
                          onSelect(namemaster?.name);
                        }}
                      />
                    ) : null}
                  </>
                );
              }}
            />
          </>
        </Layout.Table>
      </Layout>
    </div>
  );
};
export default observer(NameMaster);
