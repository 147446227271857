import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { TfiTwitter } from "react-icons/tfi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, DropdownItem, DropdownMenu } from "reactstrap";
import {
  BulkUpload,
  CheckButton,
  DataGrid,
  DeleteLink,
  EditLink,
  Layout,
  ModalComponent,
  PaymentStatusSelect,
  SelectField
} from "../../components";

import moment from "moment";
import TimesheetIcon from '../../assets/images/timesheet-outline.png';
import { DeleteButton, SaveButton } from "../../components";
import CustomOperations from "../../components/CustomField/CustomOperations";
import SideBarService from "../../components/HorizontalLayout/SideBarService";
import TagPicker from "../../components/TagSelector/TagPicker";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import DriverAssignTimesheet from "./DriverAssignTimesheet";
import EditVehicle from "./EditVehicle";
import SELECTSTRUCTURE, { STRUCTURE } from "./VehicleConstant";
import { GridColumns } from "./VehicleGridColumns";
import VehicleService from "./VehicleService";
import { FaUser } from "react-icons/fa";


const Vehicle = ({ filterUrlProp, showOnly, breadcrumb, fullHeight, value, insidePane, multiMode, onSelect = () => { }, dontShowCheck = false, markComplete = true }) => {

  let { vehicle_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [uploadPopup, setUploadPopup] = useState(false);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({ status: 1, complianceStatus: 3 });
  const [editID, setEditID] = useState(vehicle_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/vehicle/create" || vehicle_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)
  const [showCustomizationOptions, setShowCustomizationOptions] = useState(false)
  const [driverModal, setDriverModal] = useState({
    visible: false,
    vehicle: null
  });

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await VehicleService.fetch({ filterUrl });
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const preFetchGrid = async () => {
    fetchData(filterURL);
  }

  const onSave = async (e) => {
    e.preventDefault();
    if (!(detailData?.regNo && detailData.year && (detailData.vehicleExtension?.kms ? detailData.vehicleExtension?.kms > 0 : true))) {
      setHasErr(true)
      showError(t("Please enter all required fields"));
      return;
    }
    if (detailData?.vehicleType == "self") delete detailData?.supplier_id

    const payload = {
      ...detailData,
      vehicleExtn: {
        ...detailData?.vehicleExtn,
        tags: VehicleService?.tags
      }
    }

    setLoading(true);
    try {
      if (editID) {
        await VehicleService.edit(payload, editID);
        showMessage(t("Record updated successfully."));
      } else {
        const vehicle = await VehicleService.save(payload);
        if (vehicle) {
          setEditID(vehicle);
        }
        showMessage(t("Record saved successfully."));
        if (!insidePane) navigate(`/vehicle/edit/${vehicle}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
    setHasErr(false)
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane, filterUrlProp);
    console.log({ filterUrl, filterObject, filterUrlProp })
    setFilterURL(filterUrl);
    setFilterObject(filterObject);
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : []);
  }, [insidePane, filterUrlProp]);

  useEffect(() => {
    setEditID(vehicle_id)
  }, [vehicle_id])
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await VehicleService.delete(id);
        showMessage(t("Deleted"), t("Deleted"));
        setShowDetailPage(false);
        if (!insidePane) navigate("/vehicle");
      } catch (e) {
        showError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const saveBulk = async (data) => {
    try {
      const account = await VehicleService.save(data);
      if (account) {
        return { isUploaded: true, message: "" }
      }
      return { isUploaded: false, message: "Failed unexpectedly" }
    } catch (error) {
      return { isUploaded: false, message: JSON.parse(error?.message)?.customMessage ?? JSON.parse(error?.message)?.message }

    }
  };


  const renderLastCol = useCallback((vehicle) => {
    return (
      <>
        {userContent?.rights?.includes(2602) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/vehicle/edit/${vehicle?._id}`);
              setEditID(vehicle?._id);
              setShowDetailPage(true);
            }}
          />
        }
        {!multiMode && insidePane && !dontShowCheck ? (
          <CheckButton
            onClick={() => {
              onSelect(vehicle);
            }}
          />
        ) : null}

        {/* {!multiMode && insidePane && !dontShowCheck ? (
          <CheckButton
            onClick={() => {
              onSelect(entity?._id);
            }}
          />
        ) : null} */}
      </>
    );
  }, [insidePane, multiMode, userContent])

  const markCompletedFunc = async () => {
    try {
      await SetupProgressService?.edit("vehicle");
    } catch (error) {

    }
  }

  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await VehicleService.bulkDelete(selectedIDs);
        showMessage(t("Deleted"), t("Deleted Successfully"));
        setSelectedIDs([])
      } catch (e) {
        showError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch()
    }
  }
    , [SetupProgressService?.records])

  return (
    <React.Fragment>
      <>
        {uploadPopup ? (
          // <BulkUpload
          //   kind={5}
          //   type={2}
          //   showBulkUpload={uploadPopup}
          //   setShowBulkUpload={setUploadPopup}
          //   fetchData={fetchData}
          //   title={t("Vehicles")}
          // />
          <BulkUpload
            fetchData={() => fetchData(filterURL)}
            show={uploadPopup}
            onClose={() => { setUploadPopup(false) }}
            title={t("Vehicle")}
            kind={"Vehicle"}
            upload={saveBulk}
          />
        ) : null}

        <Layout
          medium
          hideAdd={!userContent?.rights?.includes(2601)}
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/vehicle");
            setEditID(null);
          }}

          breadcrumb={breadcrumb ?? ""}
          title={t("Vehicles")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/vehicle/create`);
            setShowDetailPage(true);
            setEditID(null);
            VehicleService?.updateTags([])
          }}
          insidePane={insidePane}
          page={VehicleService.page}
          rows={VehicleService.rows}
          total={VehicleService.total}
          fetch={VehicleService.fetch}
        >
            <Layout.OnAlert>
              {!SetupProgressService?.records?.setUpStatus?.['vehicle']? <Alert color="danger" role="alert">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    Please add all of your owned vehicles and its details here.
                  </div>
                  <div>
                    <Button onClick={markCompletedFunc}>Mark Complete</Button>
                  </div>
                </div>          </Alert> : <></>}
            </Layout.OnAlert>
          <Layout.ActionMenu>
            <div className="layout-action-menu">

              <DropdownMenu>
                <>
                  {userContent?.rights?.includes(2603) ?
                  <>
                    <DropdownItem onClick={() => { onBulkDelete() }}>
                      {t("Bulk Delete")}
                    </DropdownItem>
                    <div class="dropdown-divider"></div>
                    </>
                    : null}
                         
                  <DropdownItem onClick={() => setUploadPopup(true)}>
                    {t("Bulk Upload")}
                  </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem >
                    {t("Download")}
                  </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={() => { setShowCustomizationOptions(true) }}>{t("Customise")} </DropdownItem>
                </>
              </DropdownMenu>
            </div>
          </Layout.ActionMenu>
          <Layout.Table>
            <DataGrid
              gridLoading={loading}
              data={VehicleService.records}
              total={VehicleService.total}
              uiPreference="vehicle.grid"
              onSelectChange={(v) => {
                onSelect(v);
                setSelectedIDs(v);
              }}
              onSaveUiPref={preFetchGrid}
              fullHeight={fullHeight}
              headers={GridColumns}
              selectedIDs={selectedIDs}
              page={VehicleService.page}
              rowsPerPage={VehicleService.rowsPerPage}
              onPaginationChange={VehicleService.onPaginationChange}
              renderLastCol={renderLastCol}
              showActionMenu={() => true}
              renderActionMenu={(vehicle) => {
                return (
                  <>
                    <div className="d-flex flex-column">

                      {userContent?.rights?.includes(2603) && <div className="zr_action_item" onClick={(event) => onDelete(event, vehicle?._id)} >
                        <DeleteLink />
                        <div className="ms-1">Delete</div>
                      </div>}

                      {userContent?.rights?.includes(2603) && <div className="zr_action_item">
                        <div className="d-flex align-items-center" style={{ cursor: "pointer" }} onClick={(e) => {
                          SideBarService.toggleMap()
                          SideBarService.setModule("vehicle")
                          SideBarService.setVehicleId(vehicle?._id)
                          e?.stopPropagation()
                        }}>
                          <TfiTwitter className=" font-size-16" />
                          <div className="ms-1">Bird Eye View</div>
                        </div>
                      </div>}
                      
                      {(userContent?.rights?.includes(2602) || userContent?.rights?.includes(2603)) && <div className="zr_action_item" onClick={() => setDriverModal({ visible: true, vehicle })} >
                        {/* <DeleteLink /> */}
                        <FaUser className=" font-size-16" />
                        <div className="ms-1">Assign Driver</div>
                      </div>}





                    </div>
                  </>
                )
              }}
            />
          </Layout.Table>

          <Layout.DetailPageTitle>
            <div className="d-flex align-items-center w-100 row">
              <div className="col-10">
                {detailData?._id ?
                  <div className="w-100">
                    <div className="d-flex justify-content-centre w-100 align-items-center mb-1">
                      <div> {t(`Edit Vehicle -`)}</div>
                      <div className="ms-2 me-3" style={{ textAlign: "center" }}>{detailData?.regNo ?? ""}</div>
                      <PaymentStatusSelect
                        style={{
                          width: 200
                        }}
                        value={detailData?.status}
                        label={t(" Status")}
                        outline
                        onChange={(v) => setDetailData({ ...detailData, status: v?.value })
                        }
                        data={[
                          {
                            label: "Active",
                            value: 1,
                            color: "#52BE80",
                            renderer: () => (
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#52BE80",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ),
                          },
                          {
                            label: "InActive",
                            value: 2,
                            color: "#CB4335",
                            renderer: () => (
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#CB4335",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ),
                          },
                          {
                            label: "Under Maintanence",
                            value: 3,
                            color: "#FFBF00'",
                            renderer: () => (
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#FFBF00",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ),
                          },
                          {
                            label: "Sold",
                            value: 4,
                            color: "#AEB6BF'",
                            renderer: () => (
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#AEB6BF",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ),
                          },
                          {
                            label: "Dispatched",
                            value: 5,
                            color: "#FFBF00'",
                            renderer: () => (
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#FFBF00",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ),
                          },
                          {
                            label: "On Duty",
                            value: 6,
                            color: "#FF7F50'",
                            renderer: () => (
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#FF7F50",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ),
                          },
                          {
                            label: "Off Duty",
                            value: 7,
                            color: "#CCCCFF'",
                            renderer: () => (
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#CCCCFF",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ),
                          },
                        ]}
                        className="mx-2 col-6"
                        placeholder={t(" Status")}
                      />
                      <SelectField
                        style={{ width: 200 }}
                        outline
                        data={SELECTSTRUCTURE.complianceStatus}
                        value={detailData?.complianceStatus}
                        onChange={(v) => setDetailData({ ...detailData, complianceStatus: v.value })}
                        error={detailData?.complianceStatus?.length === 0 ? t("Enter Compliance Status") : ""}
                        className="col-6"
                        showErr={hasErr}
                        label={t("Compliance Status")}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      {detailData?.vehicleExtn?.qcAt &&
                        <div className="d-flex me-3">
                          <div className="mt-3">
                            <span style={{ color: '#999999' }}>
                              {t(`Quality Check Time`)}
                            </span>
                            <span>
                              {"  :  "}
                              {moment.unix(detailData?.vehicleExtn?.qcAt).format("DD-MM-YYYY  hh:mm A")}
                            </span>

                          </div>

                        </div>
                      }
                      <TagPicker
                        className=" flex-1"
                        kind={"vehicle"}
                        onChange={(v) => VehicleService?.updateTags(v)
                        }
                        value={VehicleService?.tags}
                      />
                    </div>
                  </div>
                  :
                  <div>
                    {/* <div className="me-3">{t("Add Vehicle")}</div>
                    <div>
                      <TagPicker
                        className="flex-1"
                        kind={"vehicle"}
                        onChange={(v) => VehicleService?.updateTags(v)
                        }
                        value={VehicleService?.tags}
                      />
                    </div> */}
                    <div className="d-flex justify-content-centre w-100 align-items-center mb-1">
                      <div className="col-sm-1"> {t(`Add Vehicle`)}</div>
                      <PaymentStatusSelect
                        value={!!detailData?.status ? detailData?.status : 1}
                        style={{
                          width: 200
                        }}
                        label={t(" Status")}
                        outline
                        onChange={(v) => setDetailData({ ...detailData, status: v?.value })
                        }
                        data={[
                          {
                            label: "Active",
                            value: 1,
                            color: "#52BE80",
                            renderer: () => (
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#52BE80",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ),
                          },
                          {
                            label: "InActive",
                            value: 2,
                            color: "#CB4335",
                            renderer: () => (
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#CB4335",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ),
                          },
                          {
                            label: "Under Maintanence",
                            value: 3,
                            color: "#FFBF00'",
                            renderer: () => (
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#FFBF00",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ),
                          },
                          {
                            label: "Sold",
                            value: 4,
                            color: "#AEB6BF'",
                            renderer: () => (
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#AEB6BF",
                                  marginRight: "5px",
                                }}
                              ></div>
                            ),
                          },
                        ]}
                        className="mx-2 col-6"
                        placeholder={t(" Status")}
                      />
                      <SelectField
                        outline
                        style={{ width: 200 }}
                        data={SELECTSTRUCTURE.complianceStatus}
                        value={detailData?.complianceStatus}
                        onChange={(v) => setDetailData({ ...detailData, complianceStatus: v.value })}
                        error={detailData?.complianceStatus?.length === 0 ? t("Enter Compliance Status") : ""}
                        className="col-6"
                        showErr={hasErr}
                        label={t("Compliance Status")}
                      />
                    </div>
                    <TagPicker
                      className="flex-1"
                      kind={"vehicle"}
                      onChange={(v) => VehicleService?.updateTags(v)
                      }
                      value={VehicleService?.tags}
                    />
                  </div>
                }
              </div>
            </div>

          </Layout.DetailPageTitle>

          <Layout.DetailPageBody>
            <EditVehicle
              editId={editID}
              detailData={detailData}
              onChange={(v) => {
                setDetailData(v)
              }}
              hasErr={hasErr}
            />
          </Layout.DetailPageBody>
          <Layout.DetailPageFooter>
            {detailData?._id ? (
              <AuditLogDialog module="Vehicle" id={detailData?._id} />
            ) : null
            }
            {editID && userContent?.rights?.includes(2603) && detailData?._id ? (
              <DeleteButton
                loading={loading}
                onClick={(e) => {
                  onDelete(e, detailData?._id);

                }}
              />) : null}
            {userContent?.rights?.includes(2602) ?
              <SaveButton
                loading={loading}
                onClick={onSave} />
              : null}
          </Layout.DetailPageFooter>
        </Layout>
        {showCustomizationOptions ? <CustomOperations
          isModal
          module="vehicle"
          isOpen={showCustomizationOptions}
          toggleModal={() => { setShowCustomizationOptions(false) }}
        /> : null
        }


        <ModalComponent
          position={"top"}
          size={"small"}
          onToggle={() => setDriverModal({ visible: false, vehicle: null })}
          isOpen={driverModal.visible}
        >
          <ModalComponent.Title>
            {t("Assign Driver")}
          </ModalComponent.Title>

          <ModalComponent.Body>
            <DriverAssignTimesheet vehicle={driverModal.vehicle} />
          </ModalComponent.Body>

        </ModalComponent>

      </>
    </React.Fragment >
  );
};

export default observer(Vehicle);
