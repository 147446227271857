import { includes, indexOf } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { Checkbox, InputField, SelectField } from "../../../../../components";
import { SELECTSTRUCTURE } from "../PaymentGatewayConstant";

export default function Details({ data, setData, kind }) {
  const [hasErr, setHasErr] = useState(false);
  const [currency, setcurrency] = useState(
    data?.currencies ? data?.currencies : []
  );

  const intentTypes = [
    {
      label: "CAPTURE",
      value: "CAPTURE",
    },
    {
      label: "AUTHORIZE",
      value: "AUTHORIZE",
    },
  ];

  useEffect(() => { }, [kind]);
  useEffect(() => {
    if (JSON.stringify(data?.currencies) !== JSON.stringify(currency))
      setData({ ...data, currencies: currency });
  }, [currency]);

  // let currencyArray = SELECTSTRUCTURE.currency;

  const checkCurrency = (value) => {
    if (currency === undefined) currency = [];
    const idx = currency.indexOf(value);
    if (idx > -1) {
      const newarr = currency.filter((e, i) => i !== idx);
      setcurrency(newarr);
    } else setcurrency([...currency, value]);
  };

  return (
    <div className="">
      {kind === "PATUBIZ" ? (
        <>
          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Key"
                value={data?.payuBizDeed?.key}
                required={true}
                label={"Key"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payuBizDeed: {
                      ...data?.payuBizDeed,
                      key: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="Salt"
                value={data?.payuBizDeed?.salt}
                required={true}
                label={"Salt"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payuBizDeed: {
                      ...data?.payuBizDeed,
                      salt: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>
          <Row className="mt-2 mb-2">
            <Col className="col-lg">
              <InputField
                placeholder="Payment URL"
                value={data?.payuBizDeed?.url}
                required={true}
                label={"Payment URL"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payuBizDeed: {
                      ...data?.payuBizDeed,
                      url: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>
        </>
      ) : kind === "DPO" ? (
        <>
          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Company Token"
                value={data?.dpoDeed?.companyToken}
                required={true}
                label={"Company Token"}
                onChange={(v) =>
                  setData({
                    ...data,
                    dpoDeed: {
                      ...data?.dpoDeed,
                      companyToken: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="Company Ref"
                value={data?.dpoDeed?.companyRef}
                required={true}
                label={"Company Ref"}
                onChange={(v) =>
                  setData({
                    ...data,
                    dpoDeed: {
                      ...data?.dpoDeed,
                      companyRef: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>
          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Device Type"
                value={data?.dpoDeed?.serviceType}
                required={true}
                label={"Device Type"}
                onChange={(v) =>
                  setData({
                    ...data,
                    dpoDeed: {
                      ...data?.dpoDeed,
                      serviceType: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="Token Url"
                value={data?.dpoDeed?.tokenUrl}
                required={true}
                label={"Token Url"}
                onChange={(v) =>
                  setData({
                    ...data,
                    dpoDeed: {
                      ...data?.dpoDeed,
                      tokenUrl: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>
          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Payment Url"
                value={data?.dpoDeed?.paymentUrl}
                required={true}
                label={"Payment Url"}
                onChange={(v) =>
                  setData({
                    ...data,
                    dpoDeed: {
                      ...data?.dpoDeed,
                      paymentUrl: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="Time Limit (Minutes)"
                value={data?.dpoDeed?.timeLimit}
                required={true}
                label={"Time Limit (Minutes)"}
                onChange={(v) =>
                  setData({
                    ...data,
                    dpoDeed: {
                      ...data?.dpoDeed,
                      timeLimit: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>
        </>
      ) : kind === "PAYTM" ? (
        <>
          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="MID"
                value={data?.paytmDeed?.mid}
                required={true}
                label={"MID"}
                onChange={(v) =>
                  setData({
                    ...data,
                    paytmDeed: {
                      ...data?.paytmDeed,
                      mid: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="Key"
                value={data?.paytmDeed?.key}
                required={true}
                label={"Key"}
                onChange={(v) =>
                  setData({
                    ...data,
                    paytmDeed: {
                      ...data?.paytmDeed,
                      key: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Website"
                value={data?.paytmDeed?.website}
                required={true}
                label={"Website"}
                onChange={(v) =>
                  setData({
                    ...data,
                    paytmDeed: {
                      ...data?.paytmDeed,
                      website: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="Channel Id"
                value={data?.paytmDeed?.channel_id}
                required={true}
                label={"Channel Id"}
                onChange={(v) =>
                  setData({
                    ...data,
                    paytmDeed: {
                      ...data?.paytmDeed,
                      channel_id: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Industry Type"
                value={data?.paytmDeed?.industryType}
                required={true}
                label={"Industry Type"}
                onChange={(v) =>
                  setData({
                    ...data,
                    paytmDeed: {
                      ...data?.paytmDeed,
                      industryType: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="URL"
                value={data?.paytmDeed?.url}
                required={true}
                label={"URL"}
                onChange={(v) =>
                  setData({
                    ...data,
                    paytmDeed: {
                      ...data?.paytmDeed,
                      url: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Confirm URL"
                value={data?.paytmDeed?.confirmUrl}
                required={true}
                label={"Confirm URL"}
                onChange={(v) =>
                  setData({
                    ...data,
                    paytmDeed: {
                      ...data?.paytmDeed,
                      confirmUrl: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="Refund URL"
                value={data?.paytmDeed?.refundUrl}
                required={true}
                label={"Refund URL"}
                onChange={(v) =>
                  setData({
                    ...data,
                    paytmDeed: {
                      ...data?.paytmDeed,
                      refundUrl: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>
        </>
      ) : kind === "STANDARDBANK" ? (
        <>
          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Real Name"
                value={data?.standardBankDeed?.realName}
                required={true}
                label={"Real Name"}
                onChange={(v) =>
                  setData({
                    ...data,
                    standardBankDeed: {
                      ...data?.standardBankDeed,
                      realName: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt3 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="API Key"
                value={data?.standardBankDeed?.apiKey}
                required={true}
                label={"API Key"}
                onChange={(v) =>
                  setData({
                    ...data,
                    standardBankDeed: {
                      ...data?.standardBankDeed,
                      apiKey: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt3 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Outlet Ref"
                value={data?.standardBankDeed?.outletRef}
                required={true}
                label={"Outlet Ref"}
                onChange={(v) =>
                  setData({
                    ...data,
                    standardBankDeed: {
                      ...data?.standardBankDeed,
                      outletRef: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>
          <Row className="mt3 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Access Token Url"
                value={data?.standardBankDeed?.tokenUrl}
                required={true}
                label={"Access Token Url"}
                onChange={(v) =>
                  setData({
                    ...data,
                    standardBankDeed: {
                      ...data?.standardBankDeed,
                      tokenUrl: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt3 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Base Url"
                value={data?.standardBankDeed?.baseUrl}
                required={true}
                label={"Base Url"}
                onChange={(v) =>
                  setData({
                    ...data,
                    standardBankDeed: {
                      ...data?.standardBankDeed,
                      baseUrl: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>
        </>
      ) : kind === "RAZORPAY" ? (
        <>
          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Key"
                value={data?.razorPayDeed?.key}
                required={true}
                label={"Key"}
                onChange={(v) =>
                  setData({
                    ...data,
                    razorPayDeed: {
                      ...data?.razorPayDeed,
                      key: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="Secret"
                value={data?.razorPayDeed?.secret}
                required={true}
                label={"Secret"}
                onChange={(v) =>
                  setData({
                    ...data,
                    razorPayDeed: {
                      ...data?.razorPayDeed,
                      secret: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>
        </>
      ) : kind === "PAYU" ? (
        <>
          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Key"
                value={data?.payuDeed?.key}
                required={true}
                label={"Key"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payuDeed: {
                      ...data?.payuDeed,
                      key: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="Salt"
                value={data?.payuDeed?.salt}
                required={true}
                label={"Salt"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payuDeed: {
                      ...data?.payuDeed,
                      salt: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Auth Header"
                value={data?.payuDeed?.authHeader}
                required={true}
                label={"Auth Header"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payuDeed: {
                      ...data?.payuDeed,
                      authHeader: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="Service Provider"
                value={data?.payuDeed?.service_provider}
                required={true}
                label={"Service Provider"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payuDeed: {
                      ...data?.payuDeed,
                      service_provider: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Payment URL"
                value={data?.payuDeed?.url}
                required={true}
                label={"Payment URL"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payuDeed: {
                      ...data?.payuDeed,
                      url: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="Retrive Transaction Status URL"
                value={data?.payuDeed?.transStatusUrl}
                required={true}
                label={"Retrive Transaction Status URL"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payuDeed: {
                      ...data?.payuDeed,
                      transStatusUrl: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Refund URL"
                value={data?.payuDeed?.refundUrl}
                required={true}
                label={"Refund URL"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payuDeed: {
                      ...data?.payuDeed,
                      refundUrl: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="Callback URL"
                value={data?.payuDeed?.curl}
                required={true}
                label={"Callback URL"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payuDeed: {
                      ...data?.payuDeed,
                      curl: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="Key"
                value={data?.payPalDeed?.key}
                required={true}
                label={"Key"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payPalDeed: {
                      ...data?.payPalDeed,
                      key: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="Secret"
                value={data?.payPalDeed?.secret}
                required={true}
                label={"Secret"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payPalDeed: {
                      ...data?.payPalDeed,
                      secret: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-3 mb-3 ">
            <Col className="col-lg">
              <InputField
                placeholder="URL"
                value={data?.payPalDeed?.url}
                required={true}
                label={"URL"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payPalDeed: {
                      ...data?.payPalDeed,
                      url: v,
                    },
                  })
                }
                showErr={hasErr}

                type="text"
              />
            </Col>
            <Col className="col-lg">
              {/* <InputField
                placeholder="Secret"
                value={data?.payPalDeed?.secret}
                required={true}
                label={"Secret"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payPalDeed: {
                      ...data?.payPalDeed,
                      secret: v,
                    },
                  })
                }
                showErr={hasErr}
                
                type="text"
              /> */}
              <SelectField
                placeholder="Intent"
                data={intentTypes}
                value={data?.payPalDeed?.intent}
                label={"Intent"}
                onChange={(v) =>
                  setData({
                    ...data,
                    payPalDeed: {
                      ...data?.payPalDeed,
                      intent: v.value,
                    },
                  })
                }
                showErr={hasErr}

              />
            </Col>
          </Row>
        </>
      )}

      <Row className="mt-2 mb-2">
        {SELECTSTRUCTURE.currency.map((e, i) => {
          return (
            <>
              <Col className="col-lg" key={i}>
                <Checkbox
                  label={e.label}
                  checked={data?.currencies?.includes(e.label)} // yha pe dikkat hai
                  onClick={() => checkCurrency(e.label)}
                  id={e.label}
                />
              </Col>
            </>
          );
        })}
      </Row>
    </div>
  );
}
