export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl, corpId) => {
    if (!corpId) {
      let url = `/api/office/data/grid?rows=${sizePerPage}&`;
      if (filterUrl) url += filterUrl;
      return url + `&page=${page}`;
    }
    return `/api/campus/${corpId}/campus/all`;
  },
  get: (id) => `/api/corp/config/${id}/detail`,
  edit: (id) => `/api/corp/campus/save`,
  saveNew: `/api/corp/campus/save`,
  delete: (id) => `/api/corp/campus/${id}/delete`,
};

export const STRUCTURE = [
  {
    label: "Name",
    description: "description...",
    filterLabel: "Office Name",
    filterName: "name",
    type: "text",
  },
];
