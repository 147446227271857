import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { CompanySelect, CustomFieldComponent, InputField, Tab } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import EditEntity from "../Company/EditCompany";
import Entity from "../Company/Company";
import Clientservice from "./ClientService";
import { Card, CardBody, Form, Row } from "reactstrap";
import EditOffice from "../Office/EditOffice";
import Office from "../Office/Office";
import { ThreeBounce } from "better-react-spinkit";
// import { UserContext } from "../../store/context/UserContext";
import EditSupervisors from "../Supervisor/EditSupervisors";
import Supervisor from "../Supervisor/Supervisor";
import ClientService from "./ClientService";
// import TagPicker from "../../components/TagSelector/TagPicker";
// import { CUSTOMEFIELDS_KIND_DATA } from "../../components/CustomField/CustomFieldsConstants";
import Booking from "../Booking/Booking";
import Invoice from "../Invoice/Invoice";

const EditClients = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [step, setStep] = useState(1);


  const fetch = async () => {
    setLoading(true);
    try {
      let clients = await Clientservice.get(editId);
      setLoading(false)
      setData(clients);
    } catch (error) {
      showError(error)
      setLoading(false)
    }
  };

  useEffect(() => {
    setData(null)
    setStep(1)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, ClientService.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);

  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}

        >
          <Tab
            vertical={false}
            left
            activeTab={step}
            onChange={(data) => {
              setStep(data ?? 1);
            }}
            className="d-flex"
          >

            <div num={1} label={t("Profile")}>
              <Row>
                <Card className="p-3">
                  <CardBody>
                    <InputField
                      placeholder={t("Client Name")}
                      value={data?.name}
                      required={true}
                      label={t("Client Name")}
                      onChange={(v) => setData({ ...data, name: v })}
                      error={data?.name?.length === 0 ? t("Please enter Client Name") : ""}
                      showErr={hasErr}
                      type="text"
                      className="col-sm-6"
                    />
                    {/* <CompanySelect
                        corpType="client"
                        required
                        isDisabled={false}
                        value={data?.defaultCompany_id}
                        onChange={(v) => setData({ ...data, defaultCompany_id: v?.value })}
                        corp_id={data?._id}
                        className="col-sm-12 col-md-6 col-lg-6"
                        label="Default Company"
                    /> */}
                    <CompanySelect
                      corpType="self"
                      required
                      value={data?.defaultVendorCompany_id}
                      onChange={(v) => setData({ ...data, defaultVendorCompany_id: v?.value })}
                      className="col-sm-12 col-md-6 col-lg-6"
                      label="Client of"
                      hideWhenSingle
                    />
                    <Row label="Vehicle Details" className="d-flex mt-3 col-12">
                      <CustomFieldComponent
                        module={"client"}
                        record_id={data?._id}
                        kind={"client"}
                        propList={data?.clientExtn?.properties}
                        onChange={(v) =>
                          setData({
                            ...data,
                            clientExtn: {
                              ...data?.clientExtn,
                              properties: v,
                            },
                          })
                        }
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Row>

              <Row>
                {(!editId) &&
                  <Card>

                    <h4 className="mt-3 ms-3">{t("Company Details")}</h4>

                    <EditEntity
                      callingType="client"
                      onChange={(entity) => { setData({ ...data, entityData: entity }) }}
                    />
                  </Card>
                }
              </Row>
              <Row>
                {(!editId) &&
                  <Card>
                    <div>
                      <h4 className="mt-3 ms-3">{t("Office  Details")}</h4>
                      <EditOffice
                        onChange={(office) => { setData({ ...data, officeData: office }) }}
                      />
                    </div>
                  </Card>
                }
              </Row>
              <Row>
                {(!editId) &&
                  <Card>
                    <h4 className="mt-3 ms-3">{t("Supervisor  Details")}</h4>
                    <EditSupervisors
                      hideForApiRole
                      onChange={(supervisor) => { setData({ ...data, supervisorData: supervisor }) }}
                    />
                  </Card>
                }
              </Row>

            </div>

            {editId && <div num={2} label={t("Company")}>
              {<Entity
                callingType="client"
                client_id={editId}
                insidePane={true}
                multiMode={false}
                hideFilterBar={true}
                dontShowCheck={true}
              />}
            </div>}

            {editId && <div num={3} label={t("Offices")}>
              {<Office
                org_id={editId}
                insidePane={true}
                multiMode={false}
                hideFilterBar={true}
                dontShowCheck={true}
              />}
            </div>}

            {editId && <div num={4} label={t("Supervisors")}>
              {<Supervisor
                corp_id={editId}
                insidePane={true}
                multiMode={false}
                hideFilterBar={true}
                dontShowCheck={true}
              />}
            </div>}

            {editId && <div num={5} label={t("Booking")}>
              <Booking
                clientId={editId}
                insidePane
              />
            </div>}
            {editId && <div num={6} label={t("Invoices")}>
              <Invoice
                insidePane
                clientInvoice={editId}
              />
            </div>}
          </Tab>
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditClients);
