export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/contact-person/grid?`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    get: (id) => `/api/contact-person/${id}/detail`,
    save: `/api/contact-person/create`,
    delete: (id) => `/api/contact-person/${id}/delete`,
    update: `/api/contact-person/update`,

};
export const STRUCTURE = [
    {
        label: "Name",
        filterLabel: " Name",
        filterName: "name",
        type: "text",
    },
    {
        label: "Salutation",
        filterLabel: "Salutation ",
        filterName: "salutation",
        type: "salutationselect",
    },
    {
        label: "Designation",
        filterLabel: "Designation ",
        filterName: "designation",
        type: "text",
    },
    {
        label: "Email",
        filterLabel: "Email ",
        filterName: "email",
        type: "text",
    },

];
