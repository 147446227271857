import React, { useContext } from 'react';
import { Card, CardBody, Col, Row } from "reactstrap";
import { InputField, NameField, PhoneNum } from '../../components';
import { I18nContext } from '../../store/context/i18nContext';
import SearchUser from '../Users/SearchUser';
import RemoveIcon from '../../components/Buttons/RemoveIcon';
import RemoveLink from '../../components/Buttons/RemoveLink';

const Attendant = ({ attendants, onChange }) => {

    const { t } = useContext(I18nContext);

    const handleRemoveAttendant = (index) => {
        const updatedAttendants = attendants.filter((_, i) => i !== index);
        onChange(updatedAttendants);
    };

    const handleFieldChange = ({ index, field, v }) => {
        const updatedAttendants = attendants?.map((p, i) => {
            if (i === index) {
                return { ...p, [field]: v };
            }
            return p;
        })
        onChange(updatedAttendants);
    };

    const addAttendant = () => {
        const attendant = {
            salutation: "Mr",
            phone: {
                countryCode: 91,
            }
        };

        const updatedAttendants = attendants ? [...attendants, attendant] : [attendant];
        onChange(updatedAttendants);
    };


    return (
        <Card className='mb-2'>
            <CardBody className='px-1   py-1 '>
                <div className="d-flex justify-content-between align-items-center px-3 ">
                    <h5 className="p-0 m-0">
                        <strong>{t("Attendant Details")}</strong>
                    </h5>

                    <div className='d-flex justify-content-end px-4'>
                        <div
                            className=" d-flex align-items-center justify-content-center"
                            onClick={addAttendant}
                            style={{ cursor: "pointer" }}

                        >
                            <i className="bx bx-plus text-dark font-size-18 mx-1" />
                            <span className=' '>
                                {t(" Add Attendant |")}
                            </span>
                        </div>
                        <SearchUser
                            userType="Attendant"
                            onChange={(v) => {
                                if (v) {
                                    const attendant = {
                                        email: v?.email,
                                        name: v?.name,
                                        salutation: v?.salutation,
                                        phone: {
                                            countryCode: v?.countryCode,
                                            phone: v?.phone,
                                        }
                                    };
                                    const updatedAttendants = attendants ? [...attendants, attendant] : [attendant];
                                    onChange(updatedAttendants);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='p-3 py-1'>
                    <Row>
                        {(attendants || [])?.map((attendant, index) => (
                            <div
                                style={{ position: "relative" }}
                                className='d-flex justify-content-center align-items-center'
                                key={index}
                            >
                                <h5 style={{ fontWeight: "bold" }} className="text-center mt-2">
                                    <span className='me-4'>{index + 1 + "."} </span>
                                </h5>
                                <div style={{ flex: 1 }} className="">
                                    <div className={`display-inline-block pe-0`} style={{ marginLeft: "-20px" }}>
                                        <Row className="mx-1">
                                            <Col className="col-12 col-sm-4">
                                                <NameField
                                                    outline
                                                    onNameChange={(v) => handleFieldChange({ index, field: 'name', v })}
                                                    onSalutationChange={(v) => { handleFieldChange({ index, field: 'salutation', v }) }}
                                                    name={attendant?.name}
                                                    salutation={attendant?.salutation}
                                                    label={t("Name")}
                                                />
                                            </Col>
                                            <Col className="col-12 col-sm-4 py-0 mb-4 mb-sm-0">
                                                {/* {attendant?.salutation && */}
                                                <PhoneNum
                                                    placeholder={t("Phone No ")}
                                                    value={{
                                                        countryCode: attendant?.phone?.countryCode,
                                                        phone: attendant?.phone?.phone,
                                                    }}
                                                    outline
                                                    label={t("Phone")}
                                                    onChange={(v) => {
                                                        if (v) {
                                                            const phone = {
                                                                phone: v.phone,
                                                                countryCode: v.countryCode
                                                            };
                                                            const updatedAttendants = attendants?.map((p, i) => {
                                                                if (i === index) {
                                                                    return {
                                                                        ...p,
                                                                        phone: phone
                                                                    };
                                                                }
                                                                return p;
                                                            })
                                                            onChange(updatedAttendants);
                                                        }
                                                    }}
                                                />
                                                {/* } */}
                                            </Col>
                                            <Col className="col-12 col-sm-4 mb-4 mb-sm-0">
                                                <InputField
                                                    value={attendant?.email}
                                                    outline
                                                    label={t("Email")}
                                                    placeholder="abc@mail.com"
                                                    type="email"
                                                    error={!attendant?.email ? t("Please enter Email") : ""}
                                                    onChange={(v) => handleFieldChange({ index, field: 'email', v })}
                                                    showClear={false}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <RemoveLink className="ms-1" onClick={() => handleRemoveAttendant(index)} />
                            </div>
                        ))}
                    </Row>
                </div>
            </CardBody>
        </Card>
    );
};

export default Attendant;
