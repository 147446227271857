import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { FaWhatsapp, FaSync } from 'react-icons/fa';
import { MdComment, MdEdit, MdOutlineEmail, MdOutlineTextsms } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Pagination } from '../../components';
import { DialogContext } from '../../store/context/DialogContext';
import Comment from '../Comment/Comment';
import './Communication.scss';
import CommunicationService from './CommunicationService';
import ComposeEmail from './ComposeEmail';
import DisplayEmail from './displays/DisplayEmail';
import DisplaySMS from './displays/DisplaySms';
import DisplayWhatsapp from './displays/DisplayWhatsapp';

const Communication = ({ 
    isOpen, 
    toggle, 
    tour_id, 
    trip_id, 
    flights = [], 
    trips = [], 
    stays = [], 
    customers = [], 
    bookers = [] 
}) => {
    
    const [activeTab, setActiveTab] = useState('email');
    const { showError } = useContext(DialogContext);
    const [data, setData] = useState({
        id: null,
        subject: '',
        content: '',
        to: '',
        selectedFlights: [],
        selectedStays: []
    });
    const [view, setView] = useState('list');
    const [pagination, setPagination] = useState({
        null: { page: 1, rowsPerPage: 40, total: 0 },
        email: { page: 1, rowsPerPage: 40, total: 0 },
        whatsapp: { page: 1, rowsPerPage: 40, total: 0 },
        sms: { page: 1, rowsPerPage: 40, total: 0 }
    });

    useEffect(() => {
        const passengerEmails = customers.map(p => p.email).join(',');
        const passengerPhones = customers.map(p => p.phone.phone).join(',');
        if (passengerEmails || passengerPhones) {
            fetchMails(activeTab, passengerEmails, passengerPhones);
        }
    }, [customers, activeTab, pagination[activeTab].page, pagination[activeTab].rowsPerPage]);

    const fetchMails = async (type, emailQuery, phoneQuery) => {
        const { page, rowsPerPage } = pagination[type];

        await CommunicationService.fetchMails(emailQuery, phoneQuery, type, page, rowsPerPage);
        if (page === 1) {
            setPagination(prev => ({
                ...prev,
                [type]: { ...prev[type], total: CommunicationService[`${type}Total`] }
            }));
        }
    };

    const onPaginationChange = (type) => async (page, rowsPerPage) => {
        setPagination(prev => ({
            ...prev,
            [type]: { ...prev[type], page, rowsPerPage }
        }));
    };

    const openMail = (mail) => {
        setData({
            id: mail.id,
            attachment: mail.attachment,
            subject: mail.subject,
            content: mail.body || mail.content,
            to: (mail.to ?? []).join(', ') || mail.phone.phone,
            selectedFlights: mail.flightIds || [],
            selectedStays: mail.stayIds || []
        });
        setView('display');
    };

    const renderMails = (type) => {
        return (CommunicationService[type] ?? []).map(mail => (
            <ListGroupItem onClick={() => openMail(mail)} key={mail.id} className="mail-item d-flex justify-content-between align-items-center">
                <div className="mail-info">
                    <div className="mail-sender">{type === 'email' ? mail.to.join(', ') : mail.phone.phone}</div>
                    <div className="mail-details">
                        <span className="mail-subject">{mail.subject}</span>
                        <span className="mail-content">{mail.content ? `${mail.content}` : ""}</span>
                    </div>
                </div>
            </ListGroupItem>
        ));
    };

    const renderDisplayComponent = () => {
        if (activeTab === 'email') {
            return <DisplayEmail onBack={() => setView('list')} email={data} />;
        } else if (activeTab === 'sms') {
            return <DisplaySMS onBack={() => setView('list')} sms={data} />;
        } else if (activeTab === 'whatsapp') {
            return <DisplayWhatsapp onBack={() => setView('list')} whatsapp={data} />;
        }
    };

    const refreshTabData = (tab) => {
        const passengerEmails = customers.map(p => p.email).join(',');
        const passengerPhones = customers.map(p => p.phone.phone).join(',');
        fetchMails(tab, passengerEmails, passengerPhones);
    };

    return (
        <div className="zr_communication">
            <div className="zr_communication_cross">
                <div className='' style={{ cursor: "pointer" }} onClick={toggle}><RxCross2 size={20} /> </div>
            </div>
            <div className="zr_communication_sidebar">
                <div className={`zr_communication_sidebar-item ${view === 'comments' ? 'active' : ''}`} onClick={() => { setView('comments'); setActiveTab('null') }}>
                    <span>  <MdComment size={20} /> Comments </span>  {/*({CommunicationService.sms.length}) */}
                </div>

                <div className={`zr_communication_sidebar-item ${view === 'compose' ? 'active' : ''}`} onClick={() => { setView('compose'); setActiveTab('null') }}>
                    <span>  <MdEdit size={20} /> Compose Email </span>  
                </div>
                <div className={`zr_communication_sidebar-item ${activeTab === 'email' ? 'active' : ''}`} onClick={() => { setView('list'); setActiveTab('email') }}>
                    <MdOutlineEmail size={20} /> Email
                    <FaSync className="refresh-icon" onClick={(e) => { e.stopPropagation(); refreshTabData('email') }} />
                </div>
                <div className={`zr_communication_sidebar-item ${activeTab === 'whatsapp' ? 'active' : ''}`} onClick={() => { setView('list'); setActiveTab('whatsapp') }}>
                    <FaWhatsapp size={20} /> WhatsApp
                    <FaSync className="refresh-icon" onClick={(e) => { e.stopPropagation(); refreshTabData('whatsapp') }} />
                </div>
                <div className={`zr_communication_sidebar-item ${activeTab === 'sms' ? 'active' : ''}`} onClick={() => { setView('list'); setActiveTab('sms') }}>
                    <MdOutlineTextsms size={20} /> SMS
                    <FaSync className="refresh-icon" onClick={(e) => { e.stopPropagation(); refreshTabData('sms') }} />
                </div>
            </div>
            <div className="zr_communication_content">
                {view === 'list' && (
                    <div div className='p-3'>
                        <div className='zr_communication_list-view'>
                            <ListGroup>
                                {renderMails(activeTab)}
                            </ListGroup>
                        </div>
                        <Pagination
                            page={pagination[activeTab].page}
                            rowsPerPage={pagination[activeTab].rowsPerPage}
                            total={pagination[activeTab].total}
                            uiPreference={'communication'}
                            onChange={onPaginationChange(activeTab)}
                        />
                    </div>
                )}
                {view === 'display' && renderDisplayComponent()}

                {view === 'compose' && (
                    <div div className='p-3'>
                        <ComposeEmail
                            tour_id={tour_id}
                            trip_id={trip_id}
                            flights={flights}
                            trips={trips}
                            stays={stays}
                            customers={customers}
                            bookers={bookers}
                            onBack={() => { setView('list'); setActiveTab('email') }}
                        />
                    </div>
                )}

                {view === 'comments' && (
                    <Comment
                        hideBack
                        module={tour_id ? "tour" : "trip"}
                        module_id={tour_id ?? trip_id}
                    />
                )}
            </div>
        </div>
    );
};

export default observer(Communication);
