import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Row
} from "reactstrap";
import {
  ModalComponent,
  TemplateSelect
} from "../../components";
import EditTemplate from "../../pages/Template/EditTemplate";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doGET } from "../../util/HttpUtil";
import DownloadButton from "../Buttons/DownloadButton";
import DownloadByTemplateService from "./DownloadByTemplateService";

function DownloadByTemplate({
  kind,
  onClose,
  show,
  title,
  filter = {},
  download = () => { },
}) {

  const [templates, setTemplates] = useState([]);
  const [name, setName] = useState(null);
  const { showMessage, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const { t } = useContext(I18nContext);
  const [tFields, setTFields] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const [addTemplate, setAddTemplate] = useState(false);

  useEffect(() => {
    fetch();
    fetchTfields();
    setName(kind);
  }, []);

  const fetch = async () => {
    let returnArray = [];
    if (kind) {
      const data = await DownloadByTemplateService.fetch(kind);
      data?.rows?.map((item) => {
        returnArray.push({ label: item.name, value: item._id });
      });
    }
    setTemplates(returnArray);
  };

  const fetchTfields = async () => {
    if (kind) {
      const data = await DownloadByTemplateService.getList(kind);
      setTFields(data);
    }
  };



  const downloadResFile = async (response) => {
    const contentDisposition = response.headers.get("Content-disposition");
    let fileName = contentDisposition.substr(
      contentDisposition.indexOf("filename=") + 9
    );
    fileName = fileName.replace(/"/g, "");
    const blob = response.data;
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const handleDownload = async () => {
    setLoading(true);
    if (
      kind == 'Trip' &&
      (selectedTemplate !== "" ||
        selectedTemplate !== null ||
        selectedTemplate !== undefined)
    ) {
      const response = await download(
        filter,
        selectedTemplate,
      );
      if (response && (response.status === 200) & (response.data !== null)) {
        downloadResFile(response);
        setLoading(false);
        showMessage("Download Successful");
      } else {
        setLoading(false);
        showError("Error in downloading file");
      }
    } else if (selectedTemplate) {
      const response = await download(filter, selectedTemplate);
      if ((response.status === 200) & (response.data !== null)) {
        downloadResFile(response);
        setLoading(false);
        showMessage("Download Successful");
      } else {
        setLoading(false);
        showError("Error in downloading file");
      }
    }
    setLoading(false);
  };


  return (
    <ModalComponent
      position={"top"}
      size={"full-screen"}
      isOpen={show}
      onToggle={() => onClose(false)}
    >
      <ModalComponent.Title>
        <Row>
          <span style={{ fontSize: "15px" }}>
            Download By Template{title}
          </span>
        </Row>
      </ModalComponent.Title>
      <ModalComponent.Body style={{ height: "85%" }}>
        {!showTemplateForm && !addTemplate &&
          <div style={{ minHeight: "400px" }}>
            <div
              className="row p-3 pb-1">
              <div className="col-10 col-lg-8">
                <TemplateSelect
                  className="col-sm-12"
                  onChange={(v) => setSelectedTemplate(v?.value)}
                  value={selectedTemplate}
                  kind={kind}
                />
              </div>

              <Button
                color="primary"
                className=" col-12  col-lg-3  waves-light mb-1 mt-2  "
                style={{ fontSize: 12, height: "36px", width: "auto" }}
                onClick={() => { setAddTemplate(true) }}
              >
                {loading ? <ThreeBounce /> : "Add Template"}

              </Button>
              {selectedTemplate && <Button
                color="secondary"
                className=" col-12  col-lg-3  waves-light mb-1 mt-2 mx-2  "
                style={{ fontSize: 12, height: "36px", width: "auto" }}
                onClick={() => { setShowTemplateForm(true) }}
              >
                {loading ? <ThreeBounce /> : "Edit Template"}

              </Button>}
            </div>
          </div>}

        {showTemplateForm && <div className="d-flex justify-content-center">
          <EditTemplate
            kind={kind}
            editId={selectedTemplate}
            onSave={(v) => {
              setSelectedTemplate(v)
              setShowTemplateForm(false)
            }}
            onDelete={() => {
              setSelectedTemplate(
                ""
              )
              setShowTemplateForm(false)
            }}
            oncancel={() => {
              setShowTemplateForm(false)
            }}
            insidePane={true}

          />
        </div>}
        {addTemplate && <div className="d-flex justify-content-center">
          <EditTemplate
            kind={kind}
            editId={""}
            onSave={(v) => {
              setSelectedTemplate(v)
              setAddTemplate(false)

            }}
            onDelete={() => {
              setSelectedTemplate(
                ""
              )
              setShowTemplateForm(false)
            }}
            oncancel={() => {
              setAddTemplate(false)

            }}
            insidePane={true}
            forUpload={false}
          />
        </div>}
        
        {!showTemplateForm && !addTemplate && <DownloadButton
          loading={loading}
          onClick={handleDownload}
          isDisabled={selectedTemplate ? false : true}
          style={{ position: "absolute", bottom: "20px", right: "20px" }}
        />}
      </ModalComponent.Body>
    </ModalComponent>
  );
}

export default observer(DownloadByTemplate);
