import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useContext } from "react";
import { Form, Row } from "reactstrap";
import { Address, ClientSelect, CurrencyAmountField, DateSelect, CompanySelect, InputField, InvoiceSelect } from "../../components";
import CreditNoteService from "./CreditNoteService";
import { I18nContext } from "../../store/context/i18nContext";
import { DialogContext } from "../../store/context/DialogContext";
import MAPComponent from "../../components/Map/Map";
import { ThreeBounce } from "better-react-spinkit";
import { DataF } from "@react-google-maps/api";

const EditCreditNotes = ({ corp_id, editId, onChange = () => { }, hasErr }) => {
    const [center, setCenter] = useState({})
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const fetch = async () => {
        setLoading(true);
        try {
            let creditNote = await CreditNoteService.get({ editId, corp_id });
            setLoading(false)
            setData(creditNote);
        } catch (error) {
            setLoading(false)
            showError(error)
        }
    };
    useEffect(() => {
        setData(null)
        setLoading(true);
        if (editId) fetch();
        setLoading(false)
    }, [editId, CreditNoteService?.version]);

    useEffect(() => {
        onChange(data)
    }, [data]);

    useEffect(() => {
        return () => {
            setData(null)
        }
    }, []);

    const handleMarkerDrag = (coordinates) => {
        setCenter(coordinates)
        setData({
            ...data,
            address: {
                ...data?.address,
                lat: center?.lat,
                lng: center?.lng,
            }
        });
    };


    return (
        <React.Fragment>
            {loading ?
                <ThreeBounce /> :
                <Form
                    className="p-3 pt-2"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                >
                    {/* <Row className="">
                        <ClientSelect
                            required
                            value={data?.client?.client_id}
                            onChange={(v) => setData({ ...data, client: { ...data?.client, client_id: v?.value } })}
                            className="col-sm-6"
                        />
                        <CompanySelect
                            required
                            value={data?.client?.clientEntity_id}
                            onChange={(v) => setData({ ...data, client: { ...data?.client, clientEntity_id: v?.value } })}
                            className="col-sm-6"
                        />
                    </Row> */}
                    <Row>
                        <InputField
                            placeholder={t("Name")}
                            value={data?.client?.fullName}
                            required={true}
                            label={t("Name")}
                            onChange={(v) => setData({ ...data, client: { ...data?.client, fullName: v } })}
                            error={data?.name?.length === 0 ? t("Please enter  Name") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6"
                        />
                        <InputField
                            placeholder={t("Tax ID")}
                            value={data?.client?.taxId}
                            required={true}
                            label={t("Tax ID")}
                            onChange={(v) => setData({ ...data, client: { ...data?.client, taxId: v } })}
                            error={data?.client?.taxId?.length === 0 ? t("Please enter  tax ID") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6"
                        />
                    </Row>

                    <Row>
                        <InputField
                            placeholder={t(" Credit Note")}
                            value={data?.creditNoteNo}
                            required={true}
                            label={t("Credit Note")}
                            onChange={(v) => setData({ ...data, creditNoteNo: v })}
                            error={data?.creditNoteNo?.length === 0 ? t("Please enter  Credit Note") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6"
                        />

                        <DateSelect
                            required
                            placeholder={t("Date ")}
                            value={data?.date}
                            label={t("date")}
                            onChange={(v) => {
                                setData({ ...data, date: v })

                            }}
                            type="text"
                            className="col-sm-6"
                        />

                    </Row>
                    <Row>
                        <CompanySelect
                            required
                            corpType="self"
                            value={data?.company_id}
                            onChange={(v) => setData(prevData => ({ ...prevData, company_id: v?.value }))}
                            className="col-sm-6"
                        />

                        <InvoiceSelect
                            multi
                            required
                            value={data?.invoices}
                            onChange={(v) => setData({ ...data, invoices: v })}
                            className="col-sm-6"
                        />
                        <CurrencyAmountField
                            className="col-sm-6"
                            required
                            value={
                                {
                                    amount: data?.amount,
                                    currency: data?.currency,
                                }
                            }
                            label="Amount"
                            onChange={(v) => {
                                setData({
                                    ...data,
                                    amount: v?.amount,
                                    currency: v?.currency,
                                })
                            }}
                        />
                    </Row>
                    <Row className=" mb-3">
                        <Address
                            country city state addressLine
                            placeholder={t("Address")}
                            large
                            label={t("Address")}
                            data={data?.client?.address}
                            onChange={(v) => setData({ ...data, client: { ...data?.client, address: v } })}
                            error={data?.address?.length === 0 ? t("Please enter  address") : ""}
                            showErr={hasErr}
                            type="text"
                        />
                    </Row>
                </Form>
            }
        </React.Fragment>
    );
};

export default observer(EditCreditNotes);


// import React, { useState, useEffect } from "react";

// function EditCreditNote(corp_id, editId, onChange = () => { }, hasErr) {
//     const [data, setData] = useState(null);

//     useEffect(() => {
//         onChange(data)
//     }, [data])

//     return <div>
//         EditCreditNote
//         <CompanySelect
//             required
//             value={data?.company_id}
//             onChange={(v) => setData(prevData => ({ ...prevData, company_id: v?.value }))}
//             className="col-sm-6"
//         />

//     </div>;
// }

// export default EditCreditNote;
