import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import SideMap from "./SideMap";
import "./layout.scss";

import Comment from "../../pages/Comment/Comment";
import CommentService from "../../pages/Comment/CommentService";
import Inbox from "../../pages/Inbox/Inbox";
import InboxService from "../../pages/Inbox/InboxService";
import DownloadService from "../Download/DowloadService";
import Download from "../Download/Download";
import SideBarService from "./SideBarService";
import UserGuide from "./UserGuide";
import userGuideService from "./UserGuide/UserGuideService";
import "./style.css";
import CommunicationService from "../../pages/Communication/CommunicationService";
import Communication from "../../pages/Communication/Communication";

const Sidebar = () => {
  let navigate = useNavigate();
  const { showConfirm, showError } = useContext(DialogContext);
  const [isTicketOpen, setIsTicketOpen] = useState(false)
  const { logout } = useContext(UserContext);
  const { userContent } = useContext(UserContext);

  return (
    <React.Fragment>
      <div className="sidebar-main">
        <div className="d-flex h-100 flex-column justify-content-between">
          <div className="d-flex flex-column">

            {SideBarService?.isMapLoadedFirstTime ?
              <div
                className={`${SideBarService?.isMapVisible
                  ? " map-card "
                  : " map-card map-card-hide "
                  }`}
              >
                <SideMap />
              </div> : null
            }
            {!userContent?.rights?.includes(1) ?
              <>
                <div className={`${userGuideService?.isVisible ? " map-card " : " map-card map-card-hide "}`} >
                  <UserGuide />
                </div>
                <div className={`${InboxService?.isVisible ? " map-card " : " map-card map-card-hide "}`}>
                  {InboxService.isVisible ? <Inbox /> : null}
                </div>
              </>
              : null}
               <div className={`${CommentService?.isVisible ? " map-card " : " map-card map-card-hide "}`}>
                  {CommentService.isVisible ? <Comment/> : null}
                </div>
                <div className={`${DownloadService?.isVisible ? " map-card " : " map-card map-card-hide "}`}>
                  {DownloadService.isVisible ? <Download/> : null}
                </div>


            <div style={{width:"100%"}} className={`${CommunicationService?.isVisible ? " map-card " : " map-card map-card-hide "}`}>
              {CommunicationService?.isVisible ? 
              <Communication tourid={CommunicationService.tourid} /> 
              : null}
            </div>

    
         


          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(Sidebar);
