import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ThreeBounce } from "better-react-spinkit";
import AccountSelectService from './AccountSelectService';

const AccountLabel = ({ value }) => {
  const [accountname, setAccountname] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAccountName = async (value) => {
      setLoading(true);
      try {
        const account_name = await  AccountSelectService.fetchAccountNameFromNumber(value);
        if (account_name) {
            setAccountname(account_name.label);
        } else {
            setAccountname('No Record Found');
        }
      } catch (error) {
        setAccountname('No Record Found');
      }
      setLoading(false);
    };

    if (value) {
        fetchAccountName(value);
    } else {
        setAccountname('No Record Found');
      setLoading(false);
    }
  }, [value]);

  if (loading) {
    return <ThreeBounce size={10} color="pink" className="zr_category-loader" />;
  }

  return (
    <div className={`zr_category-label ${accountname === 'No Record Found' ? 'disabled' : ''}`}>
      {accountname}
    </div>
  );
};

export default observer(AccountLabel);
