import { Fragment, useContext, useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import {
  Button,
} from "reactstrap";
import ExcelToEditableTable from "./ExcelToEditableTable";
import ModalComponent from "../Modal/Modal";
import EditTemplate from "../../pages/Template/EditTemplate";
import TemplateSelect from "../Dropdowns/TemplateSelect/TemplateSelect";
import { I18nContext } from "../../store/context/i18nContext";
import { DialogContext } from "../../store/context/DialogContext";
import { ThreeBounce } from "better-react-spinkit";
import { doGET } from "../../util/HttpUtil";
import ReactDropZone from "../FileUpload/ReactDropZone";


const BulkUpload = ({
  kind,
  onClose,
  onSheetClose = () => { },
  show,
  title,
  upload,
  fetchData
}) => {

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const [addTemplate, setAddTemplate] = useState(false);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const downloadBlob = (blob, fileName) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  const downloadFile = async () => {
    try {
      const response = await doGET(`/api/template/${selectedTemplate}/sample`)
      const blob = response.data;
      const fileName = 'example.xlsx';
      downloadBlob(blob, fileName);
    } catch (error) {
      showError(error);
    }
  };
  const [file, setFile] = useState({});
  const [formData, setFormData] = useState();
  const [id, setId] = useState();


  if (!show) return <></>
  return (
    <Fragment>

      <ModalComponent
        position={"top"}
        size={"full-screen"}
        isOpen={show}
        onToggle={() => onClose(false)}
      >
        <ModalComponent.Title>
          <Row>
            <span style={{ fontSize: "15px" }}>
              Bulk Upload {title}
            </span>
          </Row>
        </ModalComponent.Title>
        <ModalComponent.Body>
          {!showTemplateForm && !addTemplate &&
            <div style={{ minHeight: "400px" }}>
              <div
                className="row p-3 pb-1">
                <div className="col-10 col-lg-8">
                  <TemplateSelect
                    className="col-sm-12"
                    onChange={(v) => setSelectedTemplate(v?.value)}
                    value={selectedTemplate}
                    forUpload
                    kind={kind}
                  />
                </div>

                <Button
                  color="primary"
                  className=" col-12  col-lg-3  waves-light mb-1 mt-2  "
                  style={{ fontSize: 12, height: "36px", width: "auto" }}
                  onClick={() => { setAddTemplate(true) }}
                >
                  {loading ? <ThreeBounce /> : "Add Template"}

                </Button>
                {selectedTemplate && <Button
                  color="secondary"
                  className=" col-12  col-lg-3  waves-light mb-1 mt-2 mx-2  "
                  style={{ fontSize: 12, height: "36px", width: "auto" }}
                  onClick={() => { setShowTemplateForm(true) }}
                >
                  {loading ? <ThreeBounce /> : "Edit Template"}

                </Button>}
              </div>
              {selectedTemplate && <div onClick={() => { downloadFile() }} className="mb-5  color-primary row pt-0 px-4 cursor-pointer">
                Download Sample File
              </div>}
              {selectedTemplate && (
                <>
                  <div>
                    <ReactDropZone
                      preview={true}
                      multiple={false}
                      showFileDetails={true}
                      maxFiles={1}
                      onchange={(fileArray) => {
                        let formD = new FormData();
                        formD.append("file", fileArray[0]);
                        setFormData(formD);
                        setFile(fileArray[0]);
                      }}
                    />
                  </div>

                  <div className="d-flex justify-content-between">

                    {file?.preview && (
                      <Button onClick={() => { setShowTable(true) }}>Process File</Button>
                    )}
                  </div>
                </>
              )}
            </div>}

          {showTemplateForm && <div className="d-flex justify-content-center">
            <EditTemplate
              kind={kind}
              editId={selectedTemplate}
              onSave={(v) => { 
                setSelectedTemplate(v)
                setShowTemplateForm(false)
              }}
              onDelete={() => {
                setShowTemplateForm(false)
              }}
              oncancel={() => {
                setShowTemplateForm(false)
              }}
              insidePane={true}

            />
          </div>}
          {addTemplate && <div className="d-flex justify-content-center">
            <EditTemplate
              kind={kind}
              editId={""}
              onSave={(v) => {setSelectedTemplate(v)
                setAddTemplate(false)
              
              }}
              onDelete={() => {
                setShowTemplateForm(false)
              }}
              oncancel={() => {
                setAddTemplate(false)

              }}
              insidePane={true}

            />
          </div>}
        </ModalComponent.Body>
      </ModalComponent>




      <ModalComponent
        position={"top"}
        size={"full-screen"}
        onToggle={() => { setShowTable(false); onSheetClose() }}
        isOpen={showTable}
      >
        <ModalComponent.Title >{title} - Bulk Upload </ModalComponent.Title>

        <ExcelToEditableTable
          fetchData={fetchData}
          kind={kind}
          template_id={selectedTemplate}
          uploadedFile={file}
          formData={formData}
          showTable={showTable}
          upload={upload}
          onClose={onClose}
          // setShowBulkUpload={setShowBulkUpload}
          setShowTable={setShowTable}
        ></ExcelToEditableTable>
      </ModalComponent>
    </Fragment>
  );
};

export default BulkUpload;
