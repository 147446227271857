import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row } from "reactstrap";
import { ClientSelect, DeleteButton, Documents, SaveButton, Tab } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import Booking from '../BookingsNew/Booking';
import Flight from "../Flight/Flight";
import GroupTourSidebarService from "../GroupTour/sideBar/GroupTourSidebarService";
import Invoice from "../Invoice/Invoice";
import Stays from "../Stay/Stay";
import Feedback from "./Feedback";
import OverView from "./OverView";
import UserProfile from "./UserProfile";
import UserService from "./UserService";
import { getYesterdayDateString } from "../../util/Util";

const EditUsers = ({
  editId,
  onSave,
  onDelete,
  isGuests,
  space,
  insidePane,
  selectedToggleValue,
  prefilData
}) => {
  const { t } = useContext(I18nContext);

  let navigate = useNavigate();
  const { userContent } = useContext(UserContext);

  const { showError, showMessage, showConfirm } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState(null);
  const [hasErr, setHasErr] = useState(false)
  const [trip, setTrip] = useState([]);
  const [stay, setStay] = useState([]);
  const [flight, setFlight] = useState([]);
  const [data, setData] = useState({
    salutation: "Mr",
    client_id: UserService?.client_id

  });
  const [step, setStep] = useState(1);
  const fetch = async () => {
    setLoading(true)
    try {
      let user;
      if (selectedToggleValue === "forClient") {
        user = await UserService.get(editId, UserService.client_id);
      } else {
        user = await UserService.get(editId);
      }
      const yesterdayDate = getYesterdayDateString();
      let trip = await UserService.getTrip(editId, yesterdayDate);
      let flight = await UserService.getFlights(editId, yesterdayDate);
      let stays = await UserService.getStays(editId, yesterdayDate);
      // let user = await UserService.get(editId);
      // user?.status === 0 ? false : true
      setData(user);
      setTrip(trip);
      setFlight(flight);
      setStay(stays);
    } catch (error) {
      showError(error)
    }
    finally{
      setLoading(false)
    }
  };


  useEffect(() => {

    setData({
      salutation: "Mr",
      email: prefilData?.email || "",
      phoneNo: {
        countryCode: 91,
      },
      client_id: UserService?.client_id
    })
    if (editId) {
      fetch();
    }
  }, [editId, UserService?.version]);

  const handleSave = async (e) => {
    e.preventDefault();
    if (!(data?.name)) {
      setHasErr(true)
      showError(t("Please enter all required fields"));
      return;
    }
    setLoading(true);

    try {
      let id = null;
      if (editId) {
        id = await UserService.edit(data, editId);
        showMessage(t("Record updated successfully."));

      } else {
        id = await UserService.save(data);
        showMessage(t("Record saved successfully."));
        if (!insidePane && !isGuests) navigate(`/user/edit/${id}`);
        if (isGuests) navigate(`/guests/edit/${id}`);
      }

      if (space?._id) {
        await GroupTourSidebarService.edit({ ...(space ?? {}), customers: [...(space?.customers ?? []), id] });
      }
      onSave();
    } catch (e) {
      showError(e);
    }
    finally{
      setHasErr(false)
      setLoading(false);

    }
  };

  const handleDelete = async (event, id, name) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: `Delete ${name}`,
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        const response = await UserService.delete(id);
        showMessage("User deleted successfully");
        onDelete();
        if (isGuests) navigate(isGuests ? `/guests` : `/user`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const renderHeader = () => {
    if (isGuests) {
      return data?._id ? "Edit Guests" : "Add Guests";
    } else {
      return data?._id ? (
        <div className="d-flex justify-content-between" style={{ width: "97%" }}>
          <div>
            <span>
              {data?.salutation + "   "}{data?.name}
            </span>
            <br />
            <span style={{ color: "#767373" }}>
              {data?.email}
            </span>
            <br />
            <span style={{ color: "#767373" }}>
              {"+" + data?.phoneNo?.countryCode}  {data?.phoneNo?.phone}
            </span>
          </div>
          <div>

            <br />
            <span style={{ color: "#767373" }}>
              {data?.designation}
            </span>
            <br />
            <span style={{ color: "#767373" }}>
              {data?.dept}
            </span>
          </div>
        </div>
      ) : "Add Customer";
    }
  };

  const content = renderHeader();
  return (
    <React.Fragment>
      {loading ?
        <div className="d-flex justify-content-center align-items-center">
          <ThreeBounce size={50} color="pink" />
        </div>
        :
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <div className="zr_layout-header p-3 d-flex justify-content-between" style={{ flexShrink: 0 }} >
            {typeof content === 'string' ? t(content) : content}
          </div>

          <Form
            className="p-1"
            style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
          >
            <Row className="mt-3 mb-3">
              {selectedToggleValue === "forClient" && (
                <ClientSelect
                  value={UserService?.client_id}
                  isDisabled={true}
                  onChange={(v) => setData({ ...data, client_id: v?.value })}
                  className="col-sm-10"
                />
              )}
              {!editId ? <UserProfile
                hasErr={hasErr}
                data={data}
                setData={setData}
              /> : <Tab
                vertical={false}
                left
                outerClassName={"mt-0 pt-0 "}
                activeTab={step}
                onChange={(data) => {
                  setStep(data);
                }}
                className="d-flex"
              >


                <div num={1} label={t("Overview")}>
                  <OverView
                    hasErr={hasErr}
                    data={data}
                    setData={setData}
                    customer_id={editId}
                    trip={trip}
                    stay={stay}
                    flight={flight}
                  />
                </div>
                <div num={2} label={t("Profile")}>
                  <UserProfile
                    hasErr={hasErr}
                    data={data}
                    setData={setData}
                  />
                </div>

                <div num={3} label={t("Documents")}>
                  <Documents
                    config={{
                      showCategory: true,
                      showExpiry: true,
                    }}
                    module="customer"
                    record_id={data?._id}
                    col={8}
                  />
                </div>
                <div num={4} label={t("Trips")}>
                  <Booking
                    passengerTrip={editId}
                    insidePane
                  />
                </div>
                <div num={5} label={t("Flights")}>
                  <Flight
                    passengerId={editId}
                    insidePane
                    formConfig={{
                      showChecks: false,
                      hideFilter: true
                    }}
                  />
                </div>

                <div num={6} label={t("Stays")}>
                  <Stays
                    passengerId={editId}
                    insidePane
                    formConfig={{
                      showChecks: false,
                      hideFilter: true
                    }}
                  />
                </div>

                <div num={7} label={t("Orders")}>
                  <Booking
                    bookedBy={editId}
                    insidePane
                  />
                </div>
                <div num={8} label={t("Invoices")}>
                  <Invoice
                    insidePane
                    customerInvoice={editId}
                  />
                </div>

                <div num={9} label={t("Feedback")} >
                  <Feedback editId={editId} />
                </div>

              </Tab>}
            </Row>
          </Form>


          <div className="zr_layout-footer d-flex justify-content-end pe-4" style={{ flexShrink: 0, marginBottom: insidePane ? "20px" : "0px" }}>
            {editId ? (
              <AuditLogDialog module="Customer" id={editId} />
            ) : null}

            {editId && userContent?.rights?.includes(3503) ? (
              <DeleteButton loading={loading} onClick={(e) => handleDelete(e, data?._id)} />
            ) : null
            }
            {userContent?.rights?.includes(3502) && <SaveButton onClick={handleSave} loading={loading} />}
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default observer(EditUsers);
