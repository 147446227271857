import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import InvoiceConfigservice from "../InvoiceConfig/InvoiceConfigService";
import { Image } from "../../components";

const InvoiceFooter = ({ invoiceConfig_id, companyName }) => {
    const [invoiceConfigUsed, setInvoiceConfigUsed] = useState({ terms: null, bankDetails: null });

    useEffect(() => {
        async function fetchData() {
            const config = await InvoiceConfigservice.get(invoiceConfig_id);
            if (config) {
                setInvoiceConfigUsed(config);
            } else {
                setInvoiceConfigUsed({ terms: null, bankDetails: null });
            }
        }

        if (invoiceConfig_id) {
            fetchData()
        } else {
            setInvoiceConfigUsed({ terms: null, bankDetails: null });
        }
    }, [invoiceConfig_id]);

    return <>
        <Row className="d-flex justify-content-between me-2">
            <Col className="d-flex flex-column col-sm-12 col-md-6 col-lg-6">
                <Row className="d-flex justify-content-between">
                    <b style={{ textDecoration: "underline" }}>Terms & Conditions</b>
                    <pre style={{textWrap: "balance"}}>{invoiceConfigUsed?.terms}</pre>
                </Row>
                <Row className="d-flex justify-content-between">
                    <b style={{ textDecoration: "underline" }}>Bank details</b>
                    <pre>{invoiceConfigUsed?.bankDetails}</pre>
                </Row>
            </Col>
            <Col className="d-flex flex-column align-items-end col-sm-12 col-md-6 col-lg-6">
                <Row className="d-flex">
                    <b>{companyName}</b>
                </Row>
                <Row className="d-flex" style={{width:"200px", minHeight : "100px"}}>
                    {invoiceConfigUsed.stampUrl ? <Image src={invoiceConfigUsed.stampUrl} style={{width:"200px"}}/> : ""}
                </Row>
                <Row className="d-flex">
                    <b>Authorized Signatory</b>
                </Row>
            </Col>
        </Row>
    </>;
}
export default InvoiceFooter;