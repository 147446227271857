
export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Name",
      accessor: "name",
      show: true,
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.salutation === "0" ? "Miss." : (dataObj?.salutation === "1" ? "Mr." : (dataObj?.salutation === "2" ? "Mrs." : ""))} {dataObj?.name}
          </div>
        );
      }
    },
    {
      title: "Email",
      accessor: "email",
      show: true,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
