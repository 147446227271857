
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import { STRUCTURE } from "./CompanyConstant";
import { GridColumns } from "./CompanyGridColumns";
import EntityService from "./CompanyService";
import EditEntities from "./EditCompany";

const Entities = ({
  corp_id,
  client_id,
  supplier_id,
  value,
  insidePane,
  multiMode,
  onSelect = () => { },
  updateHasEntities = () => { },
  hideTitleBar,
  hideFilterBar,
  callingType,
  dontShowCheck = false
}) => {

  let { company_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { userContent } = useContext(UserContext);
  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(company_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/company/create" || company_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)


  const fetchData = async (filterUrl) => {
    if(!filterUrl){
      setFilterObject ( { ...filterObject, supplier_id : "notExists", client_id : "notExists"})
      filterUrl += "supplier_id=notExists&client_id=notExists" ;
    }
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      const rows = await EntityService.fetch(filterUrl);
      updateHasEntities(rows?.length ? true : false)
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    let { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    if (client_id) {
      filterObject = { ...filterObject, client_id: client_id, };
      filterUrl += "client_id=" + client_id;
    } else if (supplier_id) {
      filterObject = { ...filterObject, supplier_id: supplier_id, };
      filterUrl += "supplier_id=" + supplier_id;
    } else  if (userContent) {
      filterObject = { ...filterObject, supplier_id : "notExists", client_id : "notExists"};
      filterUrl += "supplier_id=notExists&client_id=notExists" ;
    }
    
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    if (client_id || supplier_id || userContent) {
      fetchData(filterUrl);
    }
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [client_id,supplier_id, userContent]);

  useEffect(() => {

  })

  useEffect(() => {
    if (!insidePane) setEditID(company_id)
  }, [company_id]);


  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.name || !detailData?.fullName) {
      setHasErr(true)
      showError(t("Please enter all fields"));
      return;
    }
    setLoading(true);
    try {
      if (editID) {
        await EntityService.edit(detailData, editID);
        showMessage(t("Company updated successfully."));
        await fetchData(filterURL);
        if (!insidePane) navigate(`/company/edit/${editID}`);
      } else {
        var entityDetailData = detailData;
        if (client_id) entityDetailData = { ...detailData, client_id: client_id }
        if (supplier_id) entityDetailData = { ...detailData, supplier_id: supplier_id }
        const entity = await EntityService.save(entityDetailData);
        // showMessage(t("Record saved successfully."));
        if (entity) setEditID(entity)
        showMessage(t("Company saved successfully."));
        await fetchData(filterURL);
        if (!insidePane) navigate(`/company/edit/${entity}`);
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        setLoading(true)
        await EntityService.delete({id, client_id, supplier_id});
        if (!(client_id || supplier_id)) {
          navigate(`/company`);
        }
        showMessage(t("Company Deleted Successfully", "Deleted"));
        setLoading(false)
        setShowDetailPage(false)
      } catch (e) {
        setLoading(false)
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback((entity) => {
    return (
      <>
        {userContent?.rights?.includes(1702) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/company/edit/${entity?._id}`);
              setEditID(entity?._id);
              setShowDetailPage(true);
            }}
          />
        }
        {userContent?.rights?.includes(1703) ?
          <DeleteLink
            onClick={(event) => onDelete(event, entity?._id)}
          />
          : null}
        {!multiMode && insidePane && !dontShowCheck ? (
          <CheckButton
            onClick={() => {
              onSelect(entity?._id);
            }}
          />
        ) : null}
      </>

    )
  }, [insidePane, userContent]);

  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await EntityService.bulkDelete(selectedIDs);
        showMessage(t("Deleted"), t("Deleted Successfully"));
      } catch (e) {
        showError(e);
      }
    }
  };


  return (
    <>
      <Layout
        hideAdd={!userContent?.rights?.includes(1701)}
        medium
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/company");
          setEditID(null);
        }}
         
        title={t("Company")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/company/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={EntityService.page}
        rows={EntityService.rows}
        total={EntityService.total}
        fetch={EntityService.fetch}
        hideTitleBar={hideTitleBar}
        hideSearch={hideFilterBar}
      >

        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                {userContent?.rights?.includes(1703) ?
                <>
                  <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  </>
                  : null}
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> {t("Download")} </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={EntityService.records}
              total={EntityService.total}
              uiPreference="entity.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={(v) => {
                onSelect(v)
                setSelectedIDs(v)
              }}
              page={EntityService.page}
              rowsPerPage={EntityService.rowsPerPage}
              onPaginationChange={EntityService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </>
        </Layout.Table>

        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit Company") : t("Add Company")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditEntities
            corp_id={callingType == "supplier" ? supplier_id : client_id}
            callingType={callingType}
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {detailData?._id ? (
            <AuditLogDialog module="Company" id={detailData?._id} />
          ) : null

          }
          {userContent?.rights?.includes(1703) && detailData?._id ? (
            <DeleteButton loading={loading} onClick={(e) => onDelete(e, detailData?._id)} />
          ) : null}
          {userContent?.rights?.includes(1702) ?
            <SaveButton loading={loading} onClick={onSave} disabled={loading} />
            : null}
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(Entities);
