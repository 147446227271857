import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row } from "reactstrap";

import { formatBytes } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doPOST } from "../../util/HttpUtil";
import DriverService from "./DriverService";

const DocUploader = (props) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [currentFile, setCurrentFile] = useState(null);
  const [data, setData] = useState(props?.data);
  const [photoIndex, setphotoIndex] = useState(0);
  const [isGallery, setisGallery] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [formData, setFormData] = useState();

  function handleAcceptedFiles(files) {
    let formD = new FormData();
    formD.append(props?.fileType, files[0]);
    setFormData(formD);
    setCurrentFile(files[0]);

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  const removeitemOnce = (a, idx) => {
    if (idx > -1) {
      a.splice(idx, 1);
    }
    return a;
  };

  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await doPOST(props?.uploadApi, formData);
      if (response?.status === 200) {
        props?.fetchData();
        setselectedFiles([]);
        setFormData(null);
      }
    } catch (error) {
      showError(error);
    }
  };

  const submitHandlerForPoliceVerify = async (e) => {
    e.preventDefault();
    try {
      const formd = new FormData();
      formd.append("policeVerify", props?.policeVerifyD?.policeVerify);
      formd.append("policeVerifyDate", props?.policeVerifyD?.policeVerifyDate);
      formd.append(props?.fileType, currentFile);
      const response = await doPOST(props?.uploadApi, formd);
      if (response?.status === 200) {
        props?.fetchData();
        setselectedFiles([]);
        setFormData(null);
      }
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Row>
      {isGallery ? (
        <Lightbox
          mainSrc={data[photoIndex]?.url}
          nextSrc={data[photoIndex % data?.length]?.url}
          prevSrc={data[(photoIndex + data?.length) % data?.length]?.url}
          enableZoom={true}
          onCloseRequest={() => {
            setisGallery(false);
          }}
          onMovePrevRequest={() => {
            setphotoIndex((photoIndex + data?.length - 1) % data?.length);
          }}
          onMoveNextRequest={() => {
            setphotoIndex((photoIndex + 1) % data?.length);
          }}
          imageCaption={data[photoIndex]?.name}
          closeLabel={"close"}
          nextLabel={"next"}
          prevLabel={"prev"}
          clickOutsideToClose={true}
        />
      ) : null}

      <div className="popup-gallery">
        <Row>
          {/* {data?.driverDetail?.pictures?.map((d, idx) => ( */}
          {data?.map((d, idx) => (
            <Col xl={4} className="col-6">
              <div style={{ position: "relative" }} className="mt-4">
                <div
                  style={{ border: "1px solid #00000020" }}
                  className="d-flex flex-column justify-content-center align-items-stretch img-fluid"
                >
                  <img
                    src={d?.url}
                    style={{ margin: "0 auto" }}
                    onClick={() => {
                      setisGallery(true);
                      setphotoIndex(idx);
                    }}
                    alt=""
                    className="img-fluid d-block"
                  />
                  <i
                    role="button"
                    className="dripicons-cross text-white font-size-18 mx-1"
                    onClick={async () => {
                      props?.onRemove(removeitemOnce(data, idx));
                      props?.deleteFunction
                        ? props?.deleteFunction()
                        : DriverService.picDelete(props?.idd, d?.time);
                    }}
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "red",
                      right: 0,
                      top: 0,
                      position: "absolute",
                      zIndex: "4",
                    }}
                  />
                  <div className="d-flex justify-content-between py-2 px-4">
                    <div>{d?.name}</div>
                    <div>
                      <a href={d?.url} target="__blank">
                        {t("Download")}
                      </a>
                    </div>
                  </div>
                  {props?.makeProfilePic && (
                    <div className="px-4">
                      <button
                        className="btn btn-outline-dark btn-sm"
                        onClick={async (e) => {
                          e.preventDefault();
                          await DriverService.changeProfilePic(
                            props?.idd,
                            d?.time
                          );
                          await props?.fetchData();
                        }}
                      >
                        {t("Make profile pic")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <hr />
      <hr />
      <Dropzone
        onDrop={(acceptedFiles) => {
          handleAcceptedFiles(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone needsclick mt-3">
            <div className="dz-message " {...getRootProps()}>
              <input {...getInputProps()} />
              <h4>
                <i className="text-muted uil uil-cloud-upload" /> Drop files
                here or click to upload.
              </h4>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="d-flex justify-content-end mt-4 ">
        <Button
          onClick={
            props?.policeverify ? submitHandlerForPoliceVerify : submitHandler
          }
          className="mt-3 mx-2"
          color="primary"
        >
          {t("Upload")}
        </Button>
      </div>
      <div className="dropzone-previews mt-3" id="file-previews">
        {selectedFiles.map((f, i) => {
          return (
            <Card
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={i + "-file"}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={f.name}
                      src={f.preview}
                    />
                  </Col>
                  <Col>
                    <Link to="#" className="text-muted font-weight-bold">
                      {f.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                </Row>
              </div>
            </Card>
          );
        })}
      </div>
      {/* <SaveButton /> */}
    </Row>
  );
};

export default observer(DocUploader);
