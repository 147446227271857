import { makeAutoObservable } from "mobx";
import { ApiHandler } from "../../store/apiHandler/apiHandler";
import { API_METHODS, ENDPOINTS } from "../../common/utils/Constants";

class FuelPriceStore {
  fuelprices = [];
  totalFuelPricees = 0;
  totalFuelPricePages = 1;
  error = "";
  places = [];
  countries= [];
  totalRecords = 0;

  page = 1;
  rows = 20;

  fuelPriceData = {
    address: {
      addressLine: "",
      country: "",
      state: "",
      city: "",
    },
    name: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  getAllFuelPrices = async function (page, sizePerPage, filterName) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllFuelPrices(page, sizePerPage, filterName);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        this.fuelprices = response.data.rows;
        this.totalFuelPricees = response.data.records;
        this.totalFuelPricePages = response.data.total;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };

  getCountries = async () => {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllCountries;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        console.log("resp", response);
        this.countries = response.data[0];
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };

  getFuelPriceById = async function (id) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getFuelPriceById(id);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        this.fuelPriceData = response.data;
        return response.data;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  get fuelPriceesLength() {
    return this.fuelprices.length;
  }

  saveFuelPrice = async function (fuelPriceData) {
    const reqParam = fuelPriceData;
    const method = API_METHODS.POST;
    const endPoint = ENDPOINTS.saveNewFuelPrice;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        console.log("success");
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteFuelPriceById = async function (id) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.deleteFuelPriceById(id);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        return response.data;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };
  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.getAllFuelPrices()
  };
}

const FuelPrices = new FuelPriceStore();
export default FuelPrices;
