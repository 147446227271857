import React, { useEffect } from "react";
// Redux
import { Link } from "react-router-dom";

import { Row, Col, CardBody, Card, Container } from "reactstrap";

// availity-reactstrap-validation
// import { AvForm, AvField } from "availity-reactstrap-validation"

// import images

import logo from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

const Login = () => {
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        {/* <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link> */}
      </div>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img
                    src={logo}
                    alt=""
                    height="22"
                    className="logo logo-dark"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="22"
                    className="logo logo-light"
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p className="text-muted">
                      Sign in to continue to Zoyride.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Logic First Global Pvt Ltd</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
