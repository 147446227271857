// import React from 'react'

// function AddRemoveButton({ onAdd, onRemove, hideAdd = false, hideSub = false }) {
//     return (
//         <div className='d-flex align-items-center gap-2'>
//             <div className='curson-pointer'>
//                 {!hideSub ? <i
//                     id={`not-clickable-add_shuttle`}
//                     style={{
//                         fontSize: "20px",
//                         border: "1px solid black",
//                         borderRadius: "20px",
//                         color: "black",
//                     }}
//                     className="dripicons-minus me-2"
//                     onClick={onRemove}
//                 /> : null}
//             </div>

//             <div className='curson-pointer'>
//                 {!hideAdd && <i
//                     id={`not-clickable-add_shuttle`}
//                     style={{
//                         fontSize: "20px",
//                         border: "1px solid black",
//                         borderRadius: "20px",
//                         color: "black",
//                     }}
//                     className="dripicons-plus "
//                     onClick={onAdd}

//                 />}
//             </div>

//         </div>
//     )
// }

// export default AddRemoveButton


import React from 'react';

const btnStyle={
    fontSize: "20px",
    border: "1px solid black",
    borderRadius: "20px",
    color: "black"
}

function AddRemoveButton({ onAdd, onRemove, hideAdd = false, hideSub = false }) {
    return (
        <div className='d-flex align-items-center gap-2 '>
                <i
                    id={`not-clickable-remove_shuttle  `}
                    style={{...btnStyle,visibility: hideSub ? "hidden" : "visible"}}
                    className="dripicons-minus me-2 cursor-pointer"
                    onClick={onRemove}
                /> 
             
                <i
                    id={`not-clickable-add_shuttle `}
                    style={{...btnStyle,visibility: hideAdd ? "hidden" : "visible"}}
                    className="dripicons-plus cursor-pointer"
                    onClick={onAdd}
                />
        </div>
    );
}

export default AddRemoveButton;
