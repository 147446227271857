
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ClientPool from "../../../pages/ClientPool/ClientPool";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, ModalComponent, Modal, SearchButton } from "../../index";
import ClientPoolService from "./../../../pages/ClientPool/ClientPoolService";
import { ThreeBounce } from "better-react-spinkit";

const ClientPoolSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  plain,
  asLabel,
  multi,
}) => {
  const [clientPools, setClientPools] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const fetchClientPools = async () => {
    setGridLoading(true)
    try {
      const response = await ClientPoolService.fetchAll();
      setClientPools(ClientPoolService.clientPoolCache)
      // setClientPools(toJS(response));
      setGridLoading(false)
    } catch (error) {
      showError("Error", error)
    }
    setGridLoading(false)
  };

  useEffect(() => {
    if (ClientPoolService.clientPoolCache.length === 0) fetchClientPools();
  }, []);
  useEffect(() => {
    setClientPools(ClientPoolService.clientPoolCache);
  }, [ClientPoolService.clientPoolCache]);


  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectClientPool = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0] : selectedValue });
    closeModal();
  };

  const selectClientPools = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage ClientPools")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={clientPools}
      required={required}
      value={value}
      plain={plain}
      label={label || (!isDisabled && t("ClientPool"))}
      error={!value ? t(`Please Select ${label || "ClientPool"}`) : ""}
      showErr={showErr}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Client Group")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={selectClientPools}
      data={clientPools}
      value={value ? clientPools.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Client Group"}`) : ""}
      required={required}
      label={t("Client Group")}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
      {gridLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <ThreeBounce size={20} color="pink" />
        </div>
      ) : (
        <>
          {
            asLabel ? multi ?
              value?.length > 0 ?
                (<div> {value?.map((selectedValue, index) => (

                  <span key={selectedValue}>
                    {clientPools.find((client) => client.value === selectedValue)?.label}
                    {index < value.length - 1 && ", "}
                  </span>
                ))}</div>) : <div style={{ color: "grey" }}>--</div>

              : (<div>{clientPools?.find((client) => client?.value == value)?.label ?? <div style={{ color: "grey" }}>--</div>}</div>) :
              <>
                {modal && (
                  <ModalComponent
                    position={"top"}
                    size={"large"}
                    onToggle={closeModal}
                    isOpen={modal}
                  >
                    <ModalComponent.Title>
                      {t("Client Group")}
                    </ModalComponent.Title>

                    <ModalComponent.Body>
                      <ClientPool
                        value={value}
                        insidePane={true}
                        multiMode={multi}
                        onSelect={multi ? selectClientPools : selectClientPool}
                      />
                    </ModalComponent.Body>

                  </ModalComponent>
                  // <Modal onChange={closeModal}>
                  //   <ClientPool
                  //     value={value}
                  //     insidePane={true}
                  //     multiMode={multi}
                  //     onSelect={multi ? selectClientPools : selectClientPool}
                  //   />
                  // </Modal>
                )}
                {clientPools.length > 0
                  ? multi
                    ? renderSelectFieldMultiple()
                    : renderSelectField({ disabled: false })
                  : renderSelectField({ disabled: false })}
              </>
          }
        </>
      )}
    </>
  );
};

export default observer(ClientPoolSelect);
