export const ENDPOINTS = {

  grid: () => {
    let url = `/api/supplier/grid?`;
    return url;
  },
  filter: (page, sizePerPage, filterUrl) => {
    let url = ``;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/supplier/${id}/detail`,
  saveNew: `/api/supplier/create`,
  save: `/api/supplier/create`,
  delete: (id) => `/api/supplier/${id}/delete`,
  bulkDelete: (id) => `/api/supplier/delete`,
  update: `/api/supplier/update`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Supplier Name",
    filterName: "name",
    type: "text",
  },
  // {
  //   label: "Original_id",
  //   filterLabel: "Supplier original_id",
  //   filterName: "original_id",
  //   type: "text",
  // },
  // {
  //   label: "Company_id",
  //   filterLabel: "Supplier company_id",
  //   filterName: "company_id",
  //   type: "text",
  // },
  // {
  //   label: "office_id",
  //   filterLabel: "Supplier office_id",
  //   filterName: "office_id",
  //   type: "text",
  // },

];
