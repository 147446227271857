import { GiStaticGuard } from "react-icons/gi";
import { RiGuideLine } from "react-icons/ri";
import { GrStatusGood } from "react-icons/gr";
import { GrCompliance } from "react-icons/gr";

export const ENDPOINTS = {
    grid: (page, sizePerPage=-1, filterUrl) => {
        let url = `/api/employee/grid?`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    get: (id) => `/api/employee/${id}/detail`,
    save: `/api/employee/create`,
    delete: (id) => `/api/employee/${id}/delete`,
    bulkDelete: (id) => `/api/employee/delete`,
    update: `/api/employee/update`,
};

export const STRUCTURE = [
    {
        label: "Name",
        filterLabel: "Employee Name",
        filterName: "name",
        description: "Filter by employee name",
        type: "text",
        icon: " uil-user-circle font-size-16 ",
    },
    {
        label: "email",
        filterLabel: "Email Address",
        description: "Filter by email address",
        filterName: "email",
        type: "text",
        icon: "mdi mdi-email-outline font-size-16 ",
    },
    {
        label: "Phone Number",
        filterLabel: "Phone Number",
        filterName: "phoneNo.phone",
        description: "Filter by phone number",
        type: "text",
        icon: "mdi mdi-phone  font-size-16 ",
    },
    {
        label: "Status",
        filterLabel: "Status",
        filterName: "status",
        description: "Filter by the status of the employee",
        type: "select",
        iconComponent: <GrCompliance className="font-size-14" />,
        data: [
            { label: "Inactive", value: 1 },
            { label: "Active", value: 2 },
            { label: "Blocked", value: 3 },
        ],
    },
    {
        label: "Complliance Status",
        filterLabel: "Complliance Status",
        filterName: "complianceStatus",
        description: "Filter by compliance status",
        type: "select",
        iconComponent: <GrStatusGood className="font-size-14" />,
        data: [
            { label: "Compliant", value: 1 },
            { label: "Not Compliant", value: 2 },
        ],
    },
    {
        label: "Drivers",
        description: "Filter for employees who are drivers",
        filterLabel: "Drivers",
        icon: "mdi mdi-steering",
        type: "checkBox",
        filterName: "isDriver",
    },
    {
        label: "Guards",
        description: "Filter for employees who are guards",
        filterLabel: "Guards",
        type: "checkBox",
        iconComponent: <GiStaticGuard className="font-size-14" />,
        filterName: "isGuard",
    },

    {
        label: "Guides",
        description: "Filter for employees who are guides",
        filterLabel: "Guides",
        iconComponent: <RiGuideLine className="font-size-14" />,
        type: "checkBox",
        filterName: "isGuide",
    },
];

export const activeStatusData = {
    0: [
        { label: "Active", value: 1, color: "green" },
        { label: "Blocked", value: 2, color: "blue" }
    ],
    1: [
        { label: "InActive", value: 0, color: "red" },
        { label: "Blocked", value: 2, color: "blue" }
    ],
    2:
        [
            { label: "InActive", value: 0, color: "red" },
            { label: "Active", value: 1, color: "green" }
        ]
}
export const activeStatusMapping = {
    0: { text: "InActive", color: "red" },
    1: { text: "Active", color: "green" },
    2: { text: "Blocked", color: "blue" },
};

export const employeePermitConstants = [
    { label: "Police Verification", value: "Police Verification" },
    { label: "Driving License", value: "Driving License" },
    { label: "Insurance", value: "Insurance" }
]
