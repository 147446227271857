
export const GridColumns = {
  version: 1,
  
  columns: [
    {
      title: "module",
      renderer: (dataObj) => {
    
        const kindLabel = dataObj?.module || "Unknown";        
        return <div>{kindLabel}</div>;
      },
      show: true,
    },
    {
      title: "Group",
      accessor: "group",
      renderer: (dataObj) =>{
        return <div>
          {dataObj?.group}
        </div>
      },
      show: true,
    },
  
    {
      title: "Section",
      accessor: "section",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.section}
        </div>
      },
      show: true,
    },
    {
      title: "DisplayType",
      accessor: "displayType",
      renderer: (dataObj) => {
        const data = [
          { label: "Text", value: 1 },
          { label: "Dropdown", value: 2 },
          { label: "Checkbox", value: 3 },
          { label: "Toggle", value: 4 },
          { label: "Date", value: 5 },
          { label: "DateTime", value: 6 },
          { label: "Year", value: 7 },
          { label: "Month", value: 8 },
          // { label: "DayOfWeek", value: 9 },
          { label: "Integer", value: 10 },
          { label: "Currency", value: 12 },
          { label: "Phone", value: 13 },
          { label: "Email", value: 14 },
          { label: "Address", value: 15 },
          { label: "Attachment", value: 16 },
        ];
        const displayTypeObj = data.find(item => item.value === dataObj?.displayType) || { label: "Unknown" };
        
        return <div>{dataObj.fieldType}</div>;
      },
      show: true,
    },

    {
      title: "Label",
      accessor: "label",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.label}
        </div>
      },
      show: true,
    },

    {
      title: "Placeholder",
      accessor: "placeholder",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.placeholder}
        </div>
      },
      show: true,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
