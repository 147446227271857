import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./CountryConstant";
import { GridColumns } from "./CountryGridColumn";
import CountryService from "./CountryService";
import EditCountries from "./EditCountry";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";


const Countries = ({ insidePane }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/country/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await CountryService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
  }, [])

  const renderLastCol = useCallback((country) => {
    return (
      <>
        <EditLink
          onClick={() => {
            if (!insidePane)
              navigate(`/country/edit/${country?._id}`);
            setEditID(country?._id);
            setShowDetailPage(true);
          }}
        />
        <DeleteLink
          onClick={(event) => onDelete(event, country?._id)}
        />
      </>
    );
  }, [insidePane])

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])


  const onSave = async (e) => {
    e.preventDefault();
    // if (!editData.name) {
    //   showError("Please enter all fields");
    //   return;
    // }
    setLoading(true);
    try {
      if (editID) {
        await CountryService.edit(editData, editID);
        showMessage(t("Record updated successfully."));
      } else {
        const country = await CountryService.save(editData);
        showMessage(t("Record saved successfully."));
        if (!insidePane) navigate(`/country/edit/${country}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await CountryService.delete(id);
        showMessage("Deleted", "Deleted");
        navigate("/country");
        setShowDetailPage(false);
      } catch (e) {
        showError(e);
      }
    }
  };

  return (
    <>
      <Layout
        medium
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/country");
          setEditID(null);
        }}
         
        title={t("Countries")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/country/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={CountryService.page}
        rows={CountryService.rows}
        total={CountryService.total}
        fetch={CountryService.fetch}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem>{t("Bulk Delete")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> {t("Download")} </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={CountryService.records}
              total={CountryService.total}
              uiPreference="country.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={setSelectedIDs}
              page={CountryService.page}
              rowsPerPage={CountryService.rowsPerPage}
              onPaginationChange={CountryService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </>
        </Layout.Table>

        <Layout.DetailPageTitle>
          {editData?._id ? t("Edit Country") : t("Add Country")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditCountries
            editId={editID}
            onChange={(v) => {
              setEditData(v)
            }}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {editID ? (
            <AuditLogDialog module="Country" id={editData?._id} />
          ) : null

          }
          {editID ? (

            <DeleteButton onClick={(e) => onDelete(e, editData?._id)} />
          ) : null

          }
          <SaveButton onClick={onSave} />
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(Countries);
