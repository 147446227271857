import { makeAutoObservable } from "mobx";


class Service {
    isVisible = false
    module=""
    constructor() {
        makeAutoObservable(this);
    }
    toggleModal = (module) => {
        this.isVisible = !this.isVisible
        this.module = module
    }
}
const DownloadService = new Service();
export default DownloadService;
