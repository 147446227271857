import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./CityConstant";
import { GridColumns } from "./CityGridColumns";
import CityService from "./CityService";
import EditCities from "./EditCity";


const Cities = ({ insidePane }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/city/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await CityService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
  }, [])


  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await CityService.bulkDelete(selectedIDs);
        setLoading(false);
        showMessage(t("Deleted"), t("Deleted Successfully"));
        setSelectedIDs([])
      } catch (e) {
        showError(e);
      }
      finally{
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.name) {
      setHasErr(true)
      showError(t("Please enter all fields"));
      return;
    }
    setLoading(true);
    try {
      if (editID) {
        await CityService.edit(detailData, editID);
        showMessage(t("Record updated successfully."));
      } else {
        const cityID = await CityService.save(detailData);
        if (cityID) setEditID(cityID)
        showMessage(t("Record saved successfully."));
        if (!insidePane) navigate(`/city/edit/${cityID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setHasErr(false)
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await CityService.delete(id);
        showMessage("Deleted", "Deleted");
      } catch (e) {
        showError(e);
      }
    }
  };


  return (
    <>
      <Layout
        medium
        showDetailPage={showDetailPage}
        backDetailPage={() => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/city");
          setEditID(null);


        }}
         
        title={t("Cities")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/city/create`);
          setShowDetailPage(true);
          setEditID(null);

        }}
        insidePane={insidePane}
        page={CityService.page}
        rows={CityService.rows}
        total={CityService.total}
        fetch={CityService.fetch}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem onClick={() => { onBulkDelete() }}>Bulk Delete</DropdownItem>
                {/* <div class="dropdown-divider"></div> */}
                {/* <DropdownItem>{t("Upload")}</DropdownItem> */}
                {/* <div class="dropdown-divider"></div> */}
                {/* <DropdownItem> Download </DropdownItem> */}
              </>

            </DropdownMenu>

          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={CityService.records}
            total={CityService.total}
            uiPreference="city.grid"
            headers={GridColumns}
            selectedIDs={selectedIDs}
            onSelectChange={setSelectedIDs}
            page={CityService.page}
            rowsPerPage={CityService.rowsPerPage}
            onPaginationChange={CityService.onPaginationChange}
            renderLastCol={(city) => {
              return (
                <>
                  <EditLink
                    onClick={() => {
                      if (!insidePane)
                        navigate(`/city/edit/${city?._id}`);
                      setShowDetailPage(true);
                      setEditID(city?._id);

                    }}
                  />

                  <DeleteLink
                    onClick={(event) => onDelete(event, city?._id)}
                  />
                </>
              );
            }}
          />
        </Layout.Table>

        <Layout.DetailPageTitle>
          {detailData?._id ? "Edit City" : "Add City"}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditCities
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {detailData?._id && <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} />}
          <SaveButton onClick={onSave} />
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(Cities);




