import { observer } from "mobx-react-lite";
import moment from "moment";

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Button, Col, Row } from "reactstrap";


import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,

  Checkbox,

  CompanySelect,

  InputField,
  Layout,
  SelectField,
  TimeSelect,

} from "../../components";
import "../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";


import CompilancesService from "./CompilancesService";





const Compilances = (props) => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const [bu, setBU] = useState([])



  const { showMessage } = useContext(DialogContext);
  const [hasErr, setHasErr] = useState(false);

  const navigate = useNavigate()

  const submitHandler = async (e) => {

    e.preventDefault();

    setLoading(true);

    try {




      setData(data)
      await CompilancesService.save(data)


      showMessage("Compilances saved successfully");
      setLoading(false)
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {

      console.log("Inside fetchData")
      const response = await CompilancesService.get();
      const BUList = await CompilancesService.getBU()

      setBU(BUList.map((e) => {
        return { label: e.name, value: e._id }
      }))

      console.log(bu, "BUList")


      setData(response)
      setHasErr(false);
      setLoading(false);

    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    fetchData();
    setHasErr(false);

  }, []);

  console.log(data, "data inside ")

  const onDeleteFromArray = async (event, idx) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete Language ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        data.splice(idx, 1)

        setData([...data])
      } catch (e) {
        showError(e);
      }
    }
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    setHasErr(true);
    if (
      await showConfirm({
        title: "Delete this Compilance ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        await CompilancesService.delete(id);
        navigate(`/compilances`);
      } catch (e) {
        showError(e);
      }
    }
  };





  if (loading) return <CardThreeBounce />;
  return (<>





    <Layout

       
      title={t("Compilances")}

      onAddClick={() => setData([...data, {}])}

    >

      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("Business Unit")}</Th>
            <Th>{t("Guard Time")}</Th>
            <Th>{t()}</Th>
            <Th>{t("Guard Allot Type")}</Th>
            <Th>{t("Car Max Age")}</Th>
            <Th>{t("Car Insurance")}</Th>
            <Th>{t("Car PUC")}</Th>
            <Th>{t("Driver RC")}</Th>
            <Th>{t("Driver Police Verification")}</Th>





            <Th>{t("Action")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>





      <Layout.TbodyFull>

        <Tbody>
          {data?.map((l, idx) => (
            <Tr
              key={idx}

            >

              <Td>  <Row className="mt-3 mb-2">
                <Col className="col-lg">
                  <SelectField

                    data={bu}
                    value={l?.bu_id}
                    onChange={(v) =>
                      setData(
                        data.map((f, x) => {
                          if (idx === x) return {
                            ...l, bu_id
                              : v.value,
                            buName: v.label
                          };
                          return f;
                        })
                      )
                    }

                  />

                </Col>
              </Row>
              </Td>
              <Td>
                <Row className="mt-3 mb-2">
                  <Col className="col-lg">
                    <SelectField
                      label={"Select Type"}
                      data={[
                        { label: "Always", value: 1 },
                        { label: "Only During Secific Time", value: 2 },
                      ]}
                      value={l?.guardTime}
                      onChange={(v) =>
                        setData(
                          data.map((f, x) => {
                            if (idx === x) return {
                              ...l, guardTime
                                : v.value
                            };
                            return f;
                          })
                        )
                      }
                    />
                  </Col>
                </Row>
              </Td>



              <Td>
                {l.guardTime === 2 &&
                  <>

                    <TimeSelect

                      value={moment(l?.guardStartTime, "h:mm:ss A").format("HH:mm:ss")}
                      onChange={(v) =>
                        setData(
                          data.map((f, x) => {
                            if (idx === x) return {
                              ...l, guardStartTime
                                : v.value
                            };
                            return f;
                          })
                        )
                      }

                    />


                    <TimeSelect

                      value={moment(l?.guardEndTime, "h:mm:ss A").format("HH:mm:ss")}
                      onChange={(v) =>
                        setData(
                          data.map((f, x) => {
                            if (idx === x) return {
                              ...l, guardEndTime
                                : v.value
                            };
                            return f;
                          })
                        )
                      }

                    /></>}




              </Td>

              <Td>
                <Row className="mt-3 mb-2">
                  <Col className="col-lg">
                    <SelectField
                      label={"Select Type"}
                      data={[
                        { label: "For All", value: 1 },
                        { label: "Only if is Female Passenger Present", value: 2 },
                        { label: "Only if is last drop/first pickup is not male", value: 3 },
                      ]}
                      value={l?.guardAllotType}
                      onChange={(v) =>
                        setData(
                          data.map((f, x) => {
                            if (idx === x) return {
                              ...l, guardAllotType

                                : v.value
                            };
                            return f;
                          })
                        )
                      }
                    />
                  </Col>
                </Row>
              </Td>

              <Td>
                <Row className="mt-3 mb-2">
                  <Col className="col-lg">
                    <InputField
                      identifier={idx}
                      placeholder="Vehicle Max"
                      value={l?.carMaxAge}

                      onChange={(v) =>
                        setData(
                          data.map((f, x) => {
                            if (idx === x) return {
                              ...l, carMaxAge

                                : v
                            };
                            return f;
                          })
                        )
                      }

                      type="number"

                    />
                  </Col>
                </Row>
              </Td>

              <Td>
                <Col className="col-lg">
                  <Checkbox


                    checked={l?.carInsurance}
                    onClick={(v) =>
                      setData(
                        data.map((f, x) => {
                          if (idx === x) return {
                            ...l, carInsurance

                              : !l?.carInsurance
                          };
                          return f;
                        })
                      )
                    }
                  /></Col>
              </Td>

              <Td>
                <Col className="col-lg">
                  <Checkbox


                    checked={l?.carPUC}
                    onClick={(v) =>
                      setData(
                        data.map((f, x) => {
                          if (idx === x) return {
                            ...l, carPUC

                              : !l?.carPUC
                          };
                          return f;
                        })
                      )
                    }
                  /></Col>
              </Td>

              <Td>
                <Col className="col-lg">
                  <Checkbox


                    checked={l?.license}
                    onClick={(v) =>
                      setData(
                        data.map((f, x) => {
                          if (idx === x) return {
                            ...l, license

                              : !l?.license
                          };
                          return f;
                        })
                      )
                    }
                  /></Col>
              </Td>


              <Td>
                <Col className="col-lg">
                  <Checkbox


                    checked={l?.policeVerified}
                    onClick={(v) =>
                      setData(
                        data.map((f, x) => {
                          if (idx === x) return {
                            ...l, policeVerified


                              : !l?.policeVerified

                          };
                          return f;
                        })
                      )
                    }
                  /></Col>
              </Td>





              <Td>
                <Button
                  className="mt-1 mx-2"
                  color="danger"
                  onClick={l?._id ? (event) => {
                    onDelete(event, l?._id);
                  } : (event) => {
                    onDeleteFromArray(event, idx);
                  }}
                >
                  Delete
                </Button>


              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>

    </Layout>



    <div className="d-flex justify-content-end  ">
      <div className="me-2">
        <Button type="submit" color="primary" onClick={submitHandler}>Save</Button>{' '}
      </div>




    </div>

  </>

  );
};

export default observer(Compilances);
