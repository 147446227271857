
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import EditStatusMaster from "./EditStatusMaster";
import { STRUCTURE } from "./StatusMasterConstant";
import { GridColumns } from "./StatusMasterGridColumns";
import StatusMasterService from "./StatusMasterService";

const StatusMaster = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { status } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(status);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/status-master/create" || status);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await StatusMasterService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])


  useEffect(() => {
    setEditID(status)
  }, [status])

  const onSave = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      if (editID) {
        await StatusMasterService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Status Master updated successfully."));
      } else {
        const statusMaster = await StatusMasterService.save(detailData);
        setLoading(false);
        showMessage(t("statusMaster saved successfully."));
        if (!insidePane) navigate(`/status-master/edit/${statusMaster}`);
      }
      fetchData(filterURL);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await StatusMasterService.delete(id);
        setLoading(false);
        showMessage("Status Master Deleted SuccessFully", "Deleted");
        navigate("/status-master");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await StatusMasterService.bulkDelete(selectedIDs);
        showMessage(t("Deleted"), "Deleted Successfully");
        setShowDetailPage(false);

      } catch (e) {
        showError(e);
      }
    }
  };


  const renderLastCol = useCallback((statusMaster) => {
    return (
      <>
        <EditLink
          onClick={() => {
            if (!insidePane)
              navigate(`/status-master/edit/${statusMaster?._id}`);
            setEditID(statusMaster?._id);
            setShowDetailPage(true);

          }}
        />

        {userContent?.rights?.includes(1503) ?

          <DeleteLink
            onClick={(event) => onDelete(event, statusMaster?._id)}
          />
          : null}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(statusMaster?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode])

  const markCompletedFunc = async () => {
    try {
      await SetupProgressService?.edit("status-master");
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch()
    }
  }
    , [SetupProgressService?.records])

  return (
    <>
      <Layout
        hideAdd={!userContent?.rights?.includes(1501)}
        medium
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/status-master");
          setEditID(null);
        }}
         
        title={t("Status Master")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/status-master/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={StatusMasterService.page}
        rows={StatusMasterService.rows}
        total={StatusMasterService.total}
        fetch={StatusMasterService.fetch}
      >


        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> Download </DropdownItem>
              </>

            </DropdownMenu>

          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={StatusMasterService.records}
            total={StatusMasterService.total}
            uiPreference="status-master.grid"
            headers={GridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            selectedIDs={selectedIDs}
            page={StatusMasterService.page}
            rowsPerPage={StatusMasterService.rowsPerPage}
            onPaginationChange={StatusMasterService.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>


        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit Status Master") : t("Add Status Master")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditStatusMaster
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {detailData?._id ? (
            <AuditLogDialog module="StatusMaster" id={detailData?._id} />
          ) : null

          }
          {userContent?.rights?.includes(1503) ? <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} /> : null}
          {userContent?.rights?.includes(1502) ? <SaveButton onClick={onSave} loading={loading} /> : null}
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(StatusMaster);
