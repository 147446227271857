export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/banner/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/banner/${id}/detail`,
  save: `/api/banner/create`,
  delete: (id) => `/api/banner/${id}/delete`,
  bulkDelete: (id) => `/api/garage/delete`,
  update: `/api/banner/update`,
};


export const SELECTSTRUCTURE = {
  kind: [
    { label: 'For App', value: 1 },
    { label: 'For Website', value: 2},
  ]
};

export const STRUCTURE = [
  {
    label: "Banner Name",
    filterLabel: "Banner Name",
    filterName: "name",
    type: "text",
  },
  
];
