"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const defaultGetTokenErrorMessage = (_, meta) => {
    const { error } = meta;
    if (typeof error === 'string') {
        return error;
    }
    return undefined;
};
exports.default = defaultGetTokenErrorMessage;
