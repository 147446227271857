import React from 'react'
import moment from "moment";
import { ModalComponent, SaveButton } from '../../components'
import { Td, Tr } from 'react-super-responsive-table';
import { formatSecondsToDuration } from '../../helpers/dateHelper';
import { Button } from 'reactstrap';
import BookingService from './BookingService';

const DutyClosePopup = ({
    trip,
    isOpen,
    toggleModal,
    onConfirm,
    loading
}) => {

    return (
        <React.Fragment>
            <ModalComponent
                position={"top"}
                size={"md"}
                isOpen={isOpen}
                onToggle={toggleModal}
            >
                <ModalComponent.Title>
                    Duty Close {trip.dutySlipNo ? `# ` + trip.dutySlipNo : ``}
                </ModalComponent.Title>

                <ModalComponent.Body scroll>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3">Pick Up</Td>
                        <Td className="col-6 col-md-3">
                            {trip.src.addressLine}
                            <br></br>
                            {trip.src.city}, {trip.src.state}
                        </Td>
                    </Tr>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3">Drop Off</Td>
                        <Td className="col-6 col-md-3">
                            {trip.dst?.addressLine}
                            <br></br>
                            {trip.dst?.city}, {trip.dst?.state}
                        </Td>
                    </Tr>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3">Start Time</Td>
                        <Td className="col-6 col-md-3">{moment.unix(trip?.startTime).format("DD-MMM-YYYY HH:mm")}</Td>
                    </Tr>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3">End Time</Td>
                        <Td className="col-6 col-md-3">{trip?.duration ? moment.unix(trip?.startTime + trip?.duration*60).format("DD-MMM-YYYY HH:mm") : `--`}</Td>
                    </Tr>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3"><b>Duration</b></Td>
                        <Td className="col-6 col-md-3"><b>{trip?.duration ? formatSecondsToDuration(Math.ceil(trip?.duration * 60) ?? 0) : `--`}</b> </Td>
                    </Tr>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3">Start Km</Td>
                        <Td className="col-6 col-md-3">{trip?.tripExtn?.startKm ? trip?.tripExtn?.startKm : `--`}</Td>
                    </Tr>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3">End Km</Td>
                        <Td className="col-6 col-md-3">{trip?.tripExtn?.distance ? (trip?.tripExtn?.startKm ? trip?.tripExtn?.startKm : 0) + trip?.tripExtn?.distance : `--`}</Td>
                    </Tr>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3"><b>Distance</b></Td>
                        <Td className="col-6 col-md-3"><b>{trip?.tripExtn?.distance ? trip?.tripExtn?.distance + ` Km` : `--`}</b></Td>
                    </Tr>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3">Garage Pickup Distance</Td>
                        <Td className="col-6 col-md-3">{trip?.tripExtn?.preStartKMs ? trip?.tripExtn?.preStartKMs + ` Km` : `--`}</Td>
                    </Tr>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3">Garage Drop Distance</Td>
                        <Td className="col-6 col-md-3">{trip?.tripExtn?.postStopKMs ? trip?.tripExtn?.postStopKMs + ` Km` : `--`}</Td>
                    </Tr>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3"><b>Total Garage Distance</b></Td>
                        <Td className="col-6 col-md-3"><b>{BookingService.findGarageDistance(trip) ? BookingService.findGarageDistance(trip) + " KMs" : `--`}</b></Td>
                    </Tr>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3">Garage Pickup Time</Td>
                        <Td className="col-6 col-md-3">{trip?.tripExtn?.preStartMins ? trip?.tripExtn?.preStartMins + ` mins` : `--`}</Td>
                    </Tr>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3">Garage Drop Time</Td>
                        <Td className="col-6 col-md-3">{trip?.tripExtn?.postStopMins ? trip?.tripExtn?.postStopMins + ` mins` : `--`}</Td>
                    </Tr>
                    <Tr className="row-t">
                        <Td className="col-6 col-md-3"><b>Total Garage Time</b></Td>
                        <Td className="col-6 col-md-3"><b>{BookingService.findGarageTime(trip) ? formatSecondsToDuration(Math.ceil(BookingService.findGarageTime(trip) * 60) ?? 0) : `--`}</b></Td>
                    </Tr>
                </ModalComponent.Body>
                <ModalComponent.Footer>
                    <div className="d-flex justify-content-end">
                        <Button
                            onClick={toggleModal}
                            className="d-flex justify-content-end my-2"
                            style={{ height: "28px", padding: "2px 10px 2px 4px" }}
                            color="info"
                        >
                            <div className="d-flex justify-content-center" style={{ margin: "2px" }}>Continue Editing...</div>
                        </Button>
                        <SaveButton
                            title="Confirm"
                            loading={loading}
                            onClick={onConfirm}
                            disabled={loading}
                        />
                    </div>
                </ModalComponent.Footer>
            </ModalComponent>
        </React.Fragment >
    );
}

export default DutyClosePopup