import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { BackArrow, CardThreeBounce, Checkbox, CurrencySelect, InputField } from "../../components";

import ConvertCurrencyService from "./ConvertCurrencyService";
import { UserContext } from "../../store/context/UserContext";

const EditConvertCurrency = (props) => {
  const [loading, setLoading] = useState(false);
  const { id, data, setData, hasErr, setHasErr } = props;
  const [freshData, setFreshData] = useState({});
  const [rate, setRate] = useState(0);

  const { userContent } = useContext(UserContext);

  const fetch = async () => {
    let convertcurrencyD = {};
    if (id) convertcurrencyD = await ConvertCurrencyService.get(id);
    setFreshData(convertcurrencyD);
    setData(convertcurrencyD);
    setHasErr(false);
    setLoading(false);
  };

  const fetchRate = async () => {
    let response = await ConvertCurrencyService.getRate(data?.from, data?.target);
    setRate(response);
  };

  useEffect(() => {
    setLoading(true);
    fetch();
    setHasErr(false);
  }, [id]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(freshData)) {
      props?.onChange(true);
    } else {
      props?.onChange(false);
    }
    if (data?.from && data?.target) {
      setLoading(true);
      fetchRate();
      setLoading(false);
    }
  }, [data]);

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      {(id && !loading) || !id ? (
        <div style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}>
          <Form className="p-4 mt-3">
            <Row className="mt-3 mb-3 ">
              <Col className="col-lg">
                <CurrencySelect label="From" showErr={hasErr} required={true} value={data?.from} onChange={(v) => setData({ ...data, from: v.value })} />
              </Col>

              <Col className="col-lg">
                <CurrencySelect label="Target" showErr={hasErr} required={true} value={data?.target} onChange={(v) => setData({ ...data, target: v.value })} />
              </Col>
            </Row>

            <Row className="mt-2 mb-2">
              <Col className="col-lg-2">
                <Checkbox
                  label="Manual"
                  checked={data?.manual}
                  id="fieldManager"
                  onClick={(v) =>
                    setData({
                      ...data,
                      manual: !data?.manual,
                    })
                  }
                />
              </Col>
              {data?.manual ? (
                <Col className="col-lg">
                  <InputField
                    placeholder="Conversion Rate"
                    value={data?.conversionRate}
                    required={true}
                    label={"Conversion Rate"}
                    onChange={(v) => setData({ ...data, conversionRate: v })}
                    showErr={hasErr}

                    type="number"
                  />
                </Col>
              ) : (
                <Col className="col-lg">
                  <InputField
                    placeholder="MarkUp"
                    value={data?.markup}
                    required={true}
                    label={"MarkUp"}
                    onChange={(v) => setData({ ...data, markup: v })}
                    showErr={hasErr}

                    type="number"
                  />
                </Col>
              )}
              <Col className="col-lg">
                <InputField isDisabled={true} value={rate} required={true} label={"Current Exchange Rate"} showErr={hasErr} type="number" />
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <CardThreeBounce />
      )}
    </React.Fragment>
  );
};

export default observer(EditConvertCurrency);
