import { makeAutoObservable, runInAction } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../../util/HttpUtil";
import SpaceService from "../listView/SpaceService";


const ENDPOINTS = {
  get: (id) => `/api/lead/${id}/detail`,
  save: `/api/lead/create`,
  delete: (id) => `/api/lead/${id}/delete`,
  bulkDelete: (id) => `/api/lead/delete`,
  update: `/api/lead/update`,
};


class Service {

  records = [];
  // statusMastersRecords = [1, 30];
  statusMastersRecords = [];
  STRUCTURE = [];
  version = {};
  statusRefresher = { version: 0, };
  editVersion = 0;
  statusNameMap = {}
  loadingStates = {};
  // 1: {
  //   name : "New", 
  //   color: "#d33d44"
  // },
  // 30: {
  //   name: "Archived",
  //   color: "#5f55ee"
  // },


  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (key, isLoading) => {
    if (isLoading) {
      this.loadingStates[key] = true;
    } else {
      delete this.loadingStates[key]; // Remove the key when not loading
    }
  };


  get isLoading() {
    return Object.values(this.loadingStates).some(status => status);
  }


  fetchStatusMaster = async () => {
    const response = await doGET("/api/status-master/grid?rows=-1&sortAsc=true&sortBy=value&module=lead")
    if (response?.status === 200) {
      this.statusMastersRecords = response.data.rows;
    }
  };

  fetch = async () => {
    const response = await doGET("/api/lead/grid?rows=-1")
    if (response?.status === 200) {
      this.records = response.data.rows;
    }
  };

      //TODO  dont set to false , remove key from loading state 

  fetchLeadsByStatus = async ({ status, page }) => {
    const actionKey = `fetchLeadsByStatus-${status}-${page}`; // Unique key for each status and page combination
    this.setLoading(actionKey, true);
    try {
      const response = await doGET(`/api/lead/grid?${SpaceService.selectedSpace?._id ? `space_id=${SpaceService.selectedSpace?._id}&` : ""}status=${status}&rows=10&page=${page}&sortAsc=true&sortBy=rank&withExtn=true`)
      if (response?.status === 200) {
        runInAction(() => {

        });
        return response.data;
      } else {
        return { error: "Failed to fetch leads" }
      }
    } catch (error) {
      console.error("Error fetching leads by status:", error);
    } finally {
      this.setLoading(actionKey, false);
    }
  }
  



  edit = async function (data, id, initialStatus) {
    const response = await doPUT(ENDPOINTS?.update, data);
    console.log(response.data._id, this.version[response.data._id])
    if (response.status === 200 && response.data._id && response.data.version) {
      this.version[response.data._id] = response.data.version
      this.statusRefresher = { status: data?.status, prevStatus: initialStatus,  version: this.statusRefresher.version + 1 };
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    if (response.status == 200) {
      this.statusRefresher = { status: data?.status, version: this.statusRefresher.version + 1 };
    }
    return response?.data?._id;
  };

  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };

  bulkDelete = async (ids = []) => {
    const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
    if (response.status === 200) {
      this.fetch();
    }
  };

  toggleEditVersion = () => {
    this.editVersion += 1
  }
}

const LeadMasterService = new Service();
export default LeadMasterService;

