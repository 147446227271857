import React, { useContext, useEffect, useState } from 'react';
import { PiCheckSquareFill } from "react-icons/pi";
import { Col, Row } from "reactstrap";
import { InputField, NameField, PhoneNum } from '../../components';
import SearchUser from '../../pages/Users/SearchUser';
import { I18nContext } from '../../store/context/i18nContext';
import PassengerFieldService from './PassengerFieldService';
import { DialogContext } from '../../store/context/DialogContext';
import { FaCheckSquare } from 'react-icons/fa';
import CustomTooltip from '../../pages/Booking/components/CustomTooltip';


const PassengerField = ({ customer_id, onChange, className, index = 0 }) => {

    const { t } = useContext(I18nContext);
    const { showError, showMessage } = useContext(DialogContext);

    const [currentPassenger, setCurrentPassenger] = useState(null);

    useEffect(() => {
        if (currentPassenger === null || currentPassenger == undefined || (typeof currentPassenger == "object" && Object.keys(currentPassenger).length === 0)) {
            setCurrentPassenger({
                name: "",
                email: "",
                salutation: "",
                phoneNo: {
                    phone: "",
                    countryCode: 91
                }
            })
        }
    }, [])
    const handleFieldChange = ({ field, v }) => {
        setCurrentPassenger(prev => ({
            ...prev,
            [field]: v
        }))
    };


    const fetchCustomerData = async (data, type) => {
        if (!data) {
            return;
        }
        try {
            let url;
            if (type == "phone") {
                url = `&phoneNo.countryCode=${data?.countryCode}&phoneNo.phone=${data?.phone}`;
            } else if (type == 'email') {
                url = `&email=${data}`
            } else {
                url = '';
            }
            const response = await PassengerFieldService?.customerGrid(url);
            if (response?.rows?.length == 0) {
                onChange("")
            }
            if (response?.rows[0]) {
                if (type == 'email' && response?.rows?.length > 0) {
                    const phone = response?.rows[0]?.phoneNo;
                    const name = response?.rows[0]?.name;
                    const salutation = response?.rows[0]?.salutation;
                    const customer_id = response?.rows[0]?._id;
                    setCurrentPassenger(prev => ({
                        ...prev,
                        phone: phone,
                        name: name,
                        salutation: salutation,
                        customer_id: customer_id
                    }))
                    onChange(customer_id)
                } else if (type == 'phone' && response?.rows?.length > 0) {
                    const email = response?.rows[0]?.email;
                    const name = response?.rows[0]?.name;
                    const salutation = response?.rows[0]?.salutation;
                    const customer_id = response?.rows[0]?._id;

                    setCurrentPassenger(prev => ({
                        ...prev,
                        email: email,
                        name: name,
                        salutation: salutation,
                        customer_id: customer_id
                    }))
                    onChange(customer_id)
                }
            }
        } catch (error) {

        }
    }

    const createCustomer = async () => {
        try {
            const passenger = await PassengerFieldService.save(currentPassenger);
            if (passenger) {
                showMessage("Customer created successfully.")
            }
            onChange(passenger)
        } catch (error) {
            showError(error)
        }
    }

    const getCustomer = async (customerId) => {
        try {
            const passenger = await PassengerFieldService.getCustomer(customerId);
            setCurrentPassenger({
                salutation: passenger?.salutation,
                name: passenger?.name,
                email: passenger?.email,
                phoneNo: passenger?.phoneNo,
                customer_id: passenger?._id
            });
        } catch (error) {
            const obj = JSON.parse(error.message);
            if (obj?.code == "record_not_found") {
                onChange(null)
            }
        }
    }
    useEffect(() => {
        if (customer_id) {
            getCustomer(customer_id)
        }
    }, [customer_id])

    return (
        <div className={className}>
            <div className="d-flex justify-content-between align-items-center px-3 ">
                <div className='d-flex justify-content-end px-4'>
                </div>
            </div>
            <div className='p-1 pt-1'>
                <Row>
                    <div
                        style={{ position: "relative" }}
                        className='d-flex justify-content-start align-items-center'
                        key={index}
                    >
                        <div style={{ flex: 1 }} className="">
                            <div className={`display-inline-block pe-0`} style={{ marginLeft: "-20px" }}>
                                <Row className="mx-1">
                                    <Col className="col-12 col-sm-4 py-0 mb-4 mb-sm-0">
                                        <PhoneNum
                                            placeholder={t("Phone No ")}
                                            value={{
                                                countryCode: currentPassenger?.phoneNo?.countryCode,
                                                phone: currentPassenger?.phoneNo?.phone,
                                            }}
                                            outline
                                            onblur={() => {
                                                // if (!currentPassenger?.customer_id) {
                                                fetchCustomerData(currentPassenger?.phoneNo, 'phone')
                                                // }
                                            }}
                                            label={t("Phone")}
                                            rightComponentStyle={{ width: "0px" }}
                                            onChange={(v) => handleFieldChange({ field: 'phoneNo', v })}
                                            RightComponent={() => {
                                                return <>
                                                    <SearchUser
                                                        style={{
                                                            backgroundColor: "#F1F1F1",
                                                            borderRadius: "0px 0px 0px 0px",
                                                            position: "relative",
                                                            height: "34px",
                                                            width: '30px',
                                                            right: '31px',
                                                            top: "1.5px",
                                                            cursor: 'pointer'
                                                        }}
                                                        hideCheckboxes={true}
                                                        onlyIcon={true}
                                                        value={[currentPassenger]}
                                                        userType="Passenger"
                                                        onChange={(v) => {
                                                            if (v) {
                                                                const pax = {
                                                                    email: v?.email,
                                                                    name: v?.name,
                                                                    salutation: v?.salutation,
                                                                    phone: {
                                                                        countryCode: v?.phoneNo?.countryCode,
                                                                        phone: v?.phoneNo?.phone,

                                                                    },
                                                                    customer_id: v?._id
                                                                };
                                                                setCurrentPassenger(pax)
                                                                onChange(v?._id)
                                                            }
                                                        }}
                                                    />
                                                </>
                                            }}
                                        />
                                    </Col>
                                    <Col className="col-12 col-sm-4 mb-4 mb-sm-0">
                                        <InputField
                                            value={currentPassenger?.email}
                                            outline
                                            label={t("Email")}
                                            placeholder="abc@mail.com"
                                            type="email"
                                            onblur={() => {
                                                fetchCustomerData(currentPassenger?.email, 'email')
                                            }}
                                            error={!currentPassenger?.email ? t("Please enter Email") : ""}
                                            onChange={(v) => handleFieldChange({ field: 'email', v })}
                                            showClear={false}
                                            RightComponent={() => {
                                                return <>
                                                    <SearchUser
                                                        style={{
                                                            backgroundColor: "#F1F1F1",
                                                            borderRadius: "0px ",
                                                            position: "relative",
                                                            height: "34px",
                                                            width: '30px',
                                                            right: '-4px',
                                                            top: "-1px",
                                                            cursor: 'pointer'
                                                        }}
                                                        hideCheckboxes={true}
                                                        onlyIcon={true}
                                                        value={[currentPassenger]}
                                                        userType="Passenger"
                                                        onChange={(v) => {
                                                            if (v) {
                                                                const pax = {
                                                                    email: v?.email,
                                                                    name: v?.name,
                                                                    salutation: v?.salutation,
                                                                    phone: {
                                                                        countryCode: v?.phoneNo?.countryCode,
                                                                        phone: v?.phoneNo?.phone,

                                                                    },
                                                                    customer_id: v?._id
                                                                };
                                                                setCurrentPassenger(pax)
                                                                onChange(v?._id)
                                                            }
                                                        }}
                                                    />
                                                </>
                                            }}
                                        />
                                    </Col>
                                    <Col className="col-12 col-sm-4">
                                        <NameField
                                            outline
                                            onNameChange={(v) => handleFieldChange({ field: 'name', v })}
                                            onSalutationChange={(v) => { handleFieldChange({ field: 'salutation', v }) }}
                                            name={currentPassenger?.name}
                                            salutation={currentPassenger?.salutation}
                                            label={t("Name")}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        {(!customer_id && currentPassenger?.email && currentPassenger?.phoneNo) &&
                            <div style={{
                                cursor: "pointer"
                            }}>
                                <CustomTooltip>
                                    <CustomTooltip.Body>
                                        <FaCheckSquare color='#43887F' size={26} onClick={createCustomer} />
                                    </CustomTooltip.Body>
                                    <CustomTooltip.ToolTip>
                                        <div style={{ fontSize: "9px" }}>Save Customer</div>
                                    </CustomTooltip.ToolTip>
                                </CustomTooltip>
                            </div>
                        }
                    </div>
                </Row>
            </div>
        </div>

    );
};

export default PassengerField;
