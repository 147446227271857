import axios from "axios";
import { useParams } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Button, Card, Col, Label, Row } from "reactstrap";
import { InputField, Layout } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { MARKETPLACE_BASE_URL } from "./MarketPlaceConstant";
import { MyVerticallyCenteredModal } from "./MyVerticallyCenteredModal";
import RateSelect from "../../components/Dropdowns/Rate/RateSelect";


const RfpSubmitRate = (props) => {
    const { id } = useParams();
    const { showError } = useContext(DialogContext);
    const { t } = useContext(I18nContext);
    const [data, setData] = useState({})

    const [bids, setBids] = useState([{}])
    const [total, setTotal] = useState(0)
    const [showSubmit, setShowSubmit] = useState(false)

    useEffect(() => {
        if (JSON.stringify(data?.bids) !== JSON.stringify(bids))
            setData({ ...data, bids: bids });
        let total = 0;
        bids?.map((ele, i) => total += ele?.subtotal)
        setTotal(total ? total : 0)
    }, [bids]);


    const createBid = async (e) => {
        try {
            data.companyId = id
            const response = await axios.post(`${MARKETPLACE_BASE_URL}/create/bid`, data)
            setShowSubmit(true)

        } catch (error) {
            console.log(error, "ee")
            showError(error?.response?.data?.message);


        }
    }
    return (
        <>
            <div className=" d-flex flex-column">
                <Card className=' mt-3 m-auto br-20 box-shadow py-4' style={{ height: "100%", width: "98%" }}>
                    <Layout
                        headingCentre={<h1 className=' fw-bold py-3'>Rate Card</h1>}
                    >
                        <Layout.TheadFull>
                            <Thead className="thead-dark">
                                <Tr style={{ textAlign: "center" }}>
                                    <Th ></Th>
                                    <Th >{t("Item")}</Th>
                                    <Th >{t("Quantity")}</Th>
                                    <Th >{t("Package")}</Th>
                                    <Th >{t("UnitPrice")}</Th>
                                    <Th className='d-flex justify-content-center' >{t("Subtotal")}</Th>

                                </Tr>
                            </Thead>
                        </Layout.TheadFull>

                        <Layout.TbodyFull>
                            <Tbody>
                                {bids?.map((bid, i) => (
                                    <Tr>
                                        <Td>{ }</Td>
                                        <Td className="col-sm-2">
                                            <InputField
                                                className="  m-auto px-2"
                                                value={bid?.item}
                                                onChange={(v) =>
                                                    setBids(
                                                        bids?.map((f, x) => {
                                                            if (i === x) return { ...bid, item: v };
                                                            return f;
                                                        })
                                                    )
                                                }
                                                type="text"

                                                showErr={true}
                                            />
                                        </Td>
                                        <Td className="col-sm-2">
                                            <InputField

                                                className=" m-auto px-2"
                                                value={bid?.quantity}
                                                onChange={(v) =>
                                                    setBids(
                                                        bids?.map((f, x) => {
                                                            if (i === x) return { ...bid, quantity: parseInt(v) };
                                                            return f;
                                                        })
                                                    )
                                                }

                                                type="number"

                                                showErr={true}
                                            />
                                        </Td>
                                        <Td className="col-sm-3">
                                            <RateSelect
                                                className=" m-auto px-2"
                                                value={bid?.package}
                                                onChange={(v) =>
                                                    setBids(
                                                        bids?.map((f, x) => {
                                                            if (i === x) return { ...bid, package: v?.value };
                                                            return f;
                                                        })
                                                    )
                                                }

                                                type="text"

                                            />
                                        </Td>
                                        <Td className="col-sm-2">
                                            <InputField
                                                className=" m-auto px-2"
                                                value={bid?.unitprice}
                                                onChange={(v) =>
                                                    setBids(
                                                        bids?.map((f, x) => {
                                                            if (i === x) return {
                                                                ...bid,
                                                                subtotal: bid?.quantity ? bid?.quantity * v : v,
                                                                unitprice: parseInt(v)
                                                            };
                                                            return f;
                                                        })
                                                    )
                                                }

                                                type="number"

                                                showErr={true}
                                            />
                                        </Td>
                                        <Td >
                                            <Label style={{ fontSize: "17px" }}>
                                                {
                                                    bid?.quantity && bid?.unitprice ? "Rs   " + bid?.quantity * bid?.unitprice : ""
                                                }
                                            </Label>
                                        </Td>

                                    </Tr>
                                ))}
                            </Tbody>
                        </Layout.TbodyFull>
                    </Layout>

                    <div className=' mt-3 float-end px-4 d-flex justify-content-end'>
                        <Button onClick={() => setBids([...bids, {}])} style={{ backgroundColor: "#DF6463", border: "none", borderRadius: "8px" }}> <span className=' fa-1x fw-bolder'>+</span> Add Item</Button>
                    </div>
                    <div className=" d-flex justify-content-end mt-3">
                        <h4 className=" px-5 fw-bold fa-2x">Total</h4>
                        <h4 className=" px-5 w-bold fa-2x">{total ? "Rs   " + total : 0}</h4>
                    </div>

                    <div className=" px-2 mt-3 mb-2">
                        <Col className="px-4">
                            Description
                        </Col>
                        <Col className="mt-3 px-4">
                            <textarea
                                rows="5"
                                style={{ outline: "none", border: "1px solid #ced4da", borderRadius: "10px", padding: "4px" }}
                                cols="180"
                                placeholder="Description"
                                value={data?.desc}
                                label={"Description"}
                                onChange={(v) => setData({ ...data, desc: v })}

                                type="text" />
                        </Col>
                    </div>

                    <div className=' mt-3 float-end px-4 d-flex justify-content-center mb-3' >
                        <Button onClick={() => createBid()} style={{
                            backgroundColor: "#DF6463", border: "none", borderRadius: "30px"
                        }}> <span className=" fw-bold fa-2x" style={{ color: "white" }}>Submit</span></Button>
                    </div>
                </Card>

            </div>

            <MyVerticallyCenteredModal
                details={"We Will Notify You Soon"}
                heading={"Bids Submitted Successfully"}
                show={showSubmit}
                buttontext={"Okay"}
                onHide={() => setShowSubmit(false)}
            />





        </>
    );
};

export default observer(RfpSubmitRate);
