import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import "./Carousel.css";

const styles = {};

const OtpInput = ({ data, onChange, label, value }) => {
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();

  const handleInput = (currentRef, nextRef) => {
    if (currentRef.current.value.length >= 1) {
      nextRef.current.focus();
    }
    onChange(
      inputRef1.current.value +
      inputRef2.current.value +
      inputRef3.current.value +
      inputRef4.current.value
    );
  };

  const handleBackspace = (currentRef, prevRef, e) => {
    if (currentRef.current.value === "") {
      prevRef.current.focus();
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").trim().substring(0, 4);
    if (pastedData.length === 4) {
      inputRef1.current.value = pastedData[0];
      inputRef2.current.value = pastedData[1];
      inputRef3.current.value = pastedData[2];
      inputRef4.current.value = pastedData[3];
      onChange(pastedData);
    }
  };

  useEffect(() => {
    if (value === null || value === "") {
      inputRef1.current.value = "";
      inputRef2.current.value = "";
      inputRef3.current.value = "";
      inputRef4.current.value = "";
    }
  }, [value]);

  return (
    <>
      <div style={{ marginVertical: 0, marginHorizontal: 0 }}>
        <div>{label}</div>
        <div style={{ flexDirection: "row", marginTop: 0 }}>
          <Row
            className="otpInput col-12 d-flex justify-content-between"
            style={{ margin: 0 }}
          >
            <div style={{ flex: 1, padding: "0" }}>
              <input
                ref={inputRef1}
                maxLength={1}
                onPaste={handlePaste}
                onChange={() => handleInput(inputRef1, inputRef2)}
                onKeyDown={(e) => {
                  if (e.nativeEvent.key === "Backspace") {
                    handleBackspace(inputRef1, inputRef1, e);
                  }
                }}
                className="otp-container"
                style={styles?.otpContainer}
              />
            </div>
            <div style={{ flex: 1, padding: "0" }}>
              <input
                ref={inputRef2}
                maxLength={1}
                onPaste={handlePaste}
                onChange={() => handleInput(inputRef2, inputRef3)}
                onKeyDown={(e) => {
                  if (e.nativeEvent.key === "Backspace") {
                    handleBackspace(inputRef2, inputRef1, e);
                  }
                }}
                style={styles?.otpContainer}
                className="otp-container"
              />
            </div>
            <div style={{ flex: 1, padding: "0" }}>
              <input
                ref={inputRef3}
                maxLength={1}
                onPaste={handlePaste}
                onChange={() => handleInput(inputRef3, inputRef4)}
                onKeyDown={(e) => {
                  if (e.nativeEvent.key === "Backspace") {
                    handleBackspace(inputRef3, inputRef2, e);
                  }
                }}
                style={styles?.otpContainer}
                className="otp-container"
              />
            </div>
            <div style={{ flex: 1, padding: "0" }}>
              <input
                ref={inputRef4}
                maxLength={1}
                onPaste={handlePaste}
                onChange={() => handleInput(inputRef4, inputRef4)}
                onKeyDown={(e) => {
                  if (e.nativeEvent.key === "Backspace") {
                    handleBackspace(inputRef4, inputRef3, e);
                  }
                }}
                style={styles?.otpContainer}
                className="otp-container"
              />
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default OtpInput;
