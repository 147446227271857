import React from 'react'
import { InputField, PhoneNum } from '../../../components'

const MarketPlaceStep1Component = ({ label, placeholder, value, onChange, type = "" }) => {
    return (
        <div className='my-2'>
            <div>
                {type != "phone" && <InputField
                    label={label}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    required={true}
                    outline
                    className="col-sm-12"
                />}

                {type == "phone" && <PhoneNum
                    placeholder={"Enter PhoneNum "}
                    value={value}
                    label={"Phone"}
                    outline
                    className="col-sm-12"
                    onChange={onChange}
                    type="text"
                />
                }
            </div>
        </div>
    )
}

export default MarketPlaceStep1Component