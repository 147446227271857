import React, { useContext } from 'react'
import ChargeName from '../../Booking/components/ChargeName';
import { AmountField } from '../../../components';
import { BiMinus } from 'react-icons/bi';
import { GoPlus } from 'react-icons/go';
import { I18nContext } from '../../../store/context/i18nContext';

function FeeCharges({
    charges,
    updateCharges,
    formConfig = null,
    addCharge = () => { },
    removeCharge = () => { },
    editAble = false, //if not editable means showing as label
    rateNames
}) {
    const { t } = useContext(I18nContext);

    return (
        <>
            {charges?.length ?
                charges?.map((charge, chargeIndex) => {
                    return (
                        <tr key={chargeIndex} style={!editAble ? { lineHeight: "0.5" } : null}>
                            <td style={!editAble ? { width: "50%" } : null}>
                                {editAble ? <ChargeName
                                    disabled={formConfig?.diableUserInputForFee}
                                    asLabel
                                    noLabel
                                    outline
                                    style={{
                                        fontSize: "10px",
                                    }}
                                    value={rateNames?.[charge?.name] ?? charge?.name}
                                    onChange={(v) =>
                                        updateCharges({ chargeIndex, key: "name", v: v })
                                    }
                                /> : rateNames?.[charge?.name] ?? charge?.name}
                                {charge?.label && <span className='ms-1' style={{ fontStyle: "italic", color: "#76797c", fontSize: "10px", position: "absolute" }}> ({charge?.label})</span>}
                                {!charge?.label && charge?.detail && <span className='ms-1' style={{ fontStyle: "italic", color: "#76797c", fontSize: "10px", position: "absolute" }}> ({charge?.detail})</span>}
                            </td>

                            <td style={{ position: "relative" }}>
                                <div className="d-flex">
                                    <AmountField
                                        outline
                                        disabled={formConfig?.diableUserInputForFee || !editAble}
                                        asLabel={!editAble}
                                        placeholder={"Cost Per Unit"}
                                        value={charge?.unitCost}
                                        onChange={(v) =>
                                            updateCharges({ chargeIndex, key: "unitCost", v: v })
                                        }
                                        type="text"
                                    />

                                </div>

                                {editAble ? <div style={{ position: "absolute", right: -9, top: "35%" }}>
                                    <i className="mdi font-size-16 mdi-close" />
                                </div> : null}
                            </td>
                            <td style={{ position: "relative" }}>
                                <div className="d-flex">
                                    <AmountField
                                        disabled={formConfig?.diableUserInputForFee || !editAble}
                                        outline
                                        asLabel={!editAble}
                                        placeholder={"Units"}
                                        noLabel
                                        type="number"
                                        value={charge?.units}
                                        onChange={(v) =>
                                            updateCharges({
                                                chargeIndex,
                                                key: "units",
                                                v: Number(v),
                                            })
                                        }
                                    />

                                </div>
                                {editAble ? <div style={{ position: "absolute", right: -5, top: "30%" }}>
                                    <div className="font-size-16">= </div>
                                </div> : null}
                            </td>
                            <td className={!editAble ? `d-flex justify-content-end` : ''}>
                                <AmountField
                                    disabled={formConfig?.diableUserInputForFee}
                                    outline
                                    asLabel
                                    placeholder={"Amount"}
                                    // style={{ minWidth: '70px' }}
                                    noLabel
                                    type="number"
                                    value={Number(charge?.amount ?? 0)}
                                    onChange={(v) =>
                                        updateCharges({})
                                    }
                                />
                            </td>
                            {editAble ? <td className="d-flex justify-content-between align-items-center">
                                {charges.length !== 1 ? (
                                    <div
                                        style={{
                                            marginLeft: "5px",
                                            marginTop: "10px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div
                                            className="btn btn-outline-dark d-flex align-items-center"
                                            style={{
                                                border: "1px solid #707070",
                                                borderRadius: "50%",
                                                cursor: "pointer",
                                                padding: "1px",
                                                // backgroundColor:"ebebeb"
                                            }}

                                        >
                                            <BiMinus onClick={() => removeCharge(chargeIndex)} fontSize="16" />
                                        </div>
                                    </div>
                                ) : <div>&nbsp;</div>}
                                {chargeIndex == 0 && (
                                    <div
                                        style={{
                                            marginLeft: "5px",
                                            marginTop: "10px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div className="btn btn-outline-dark d-flex align-items-center"
                                            style={{
                                                border: "1px solid #707070",
                                                borderRadius: "50%",
                                                cursor: "pointer",
                                                padding: "1px"
                                            }}
                                        >
                                            <GoPlus onClick={addCharge} fontSize="16" />
                                        </div>
                                    </div>
                                )}
                            </td> : null}
                        </tr>
                    );
                }) : (editAble ? <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>

                    <td>
                        {(
                            <div
                                className="btn btn-outline-dark"
                                style={{
                                    padding: "3px",
                                    width: "65px",
                                    paddingRight: "6px",
                                    fontSize: "10px",
                                }}
                                onClick={addCharge}
                            >
                                <div className="d-flex justify-content-center align-items-center">
                                    <i className="bx bx-plus  font-size-10 me-1" />
                                    {t("Charge")}
                                </div>
                            </div>
                        )}
                    </td>
                </tr> : null)}
        </>
    )
}

export default FeeCharges