import { makeAutoObservable } from "mobx";
import { doGET, doPOST } from '../../util/HttpUtil'; // Adjust the import based on your project's structure

class CommunicationService {
    isVisible = false;
    email = [];
    whatsapp = [];
    sms = [];
    emailTotal = 0;
    smsTotal = 0;
    whatsappTotal = 0;
    tourid = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchData(type, query, page = 1, rowsPerPage = 40) {
        try {
            let response;
            if (type === 'email') {
                response = await doGET(`/api/email/grid?to=in[${query}]&rows=${rowsPerPage}&page=${page}`);
                this.email = response.data.rows;
                if (page === 1) this.emailTotal = response.data.total;
            } else if (type === 'sms') {
                response = await doGET(`/api/sms/grid?platform=SMS&phone.phone=in[${query}]&rows=${rowsPerPage}&page=${page}`);
                this.sms = response.data.rows;
                if (page === 1) this.smsTotal = response.data.total;
            } else if (type === 'whatsapp') {
                response = await doGET(`/api/sms/grid?platform=WHATSAPP&phone.phone=in[${query}]&rows=${rowsPerPage}&page=${page}`);
                this.whatsapp = response.data.rows;
                if (page === 1) this.whatsappTotal = response.data.total;
            }
        } catch (error) {
            console.error(`Error fetching ${type} data:`, error);
        }
    }

    async fetchMails(emailQuery, phoneQuery, type, page, rowsPerPage) {

        if (type === 'email') {
            await this.fetchData('email', emailQuery, page, rowsPerPage);
            return
        } else if (type === 'sms') {
            await this.fetchData('sms', phoneQuery, page, rowsPerPage);
            return
        } else if (type === 'whatsapp') {
            await this.fetchData('whatsapp', phoneQuery, page, rowsPerPage);
            return
        }
        await this.fetchData(type, emailQuery, page, rowsPerPage);
    }

    toggleVisibility = (tourid) => {

        this.isVisible = !this.isVisible;
        this.email = [];
        this.whatsapp = [];
        this.sms = [];
        this.emailTotal = 0;
        this.smsTotal = 0;
        this.whatsappTotal = 0;
        if (tourid) {
            this.tourid = tourid
        }
    }

    addMail = async (mail) => {

        this.email.push(mail);

    }

    updateMail(updatedMail) {
        this.email = this.email.map(mail => mail.id === updatedMail.id ? updatedMail : mail);
    }
}

export default new CommunicationService();
