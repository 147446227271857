import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Form } from "reactstrap";
import { DialogContext } from "../../../store/context/DialogContext";
import { SaveButton, SelectField } from "../../../components";
import SettingsService from "../SettingsService";
const AuraSettings = () => {
  const [loading, setLoading] = useState(false);
  const { showMessage, showError } = useContext(DialogContext);
  const [data, setData] = useState("");
  const [hasErr, setHasErr] = useState(false);
  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await SettingsService.getConfig();
      setData(res);
      console.log("bookingsettings.js: ", res);
      setHasErr(false);
    } catch (e) {
      showError(e);
      // console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await SettingsService.saveAura(data);
      showMessage("Aura Settings Saved successfully");
      setLoading(false);
      fetchData();
    } catch (error) {
      showError(error);
    }
  };
  const corpconf = [
    {
      label: "Full Fare with Taxes",
      value: null,
    },
    {
      label: "Full Fare without Taxes",
      value: "1",
    },
  ];
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col
          className="col-12 p-2 ps-4 mb-2"
          style={{
            backgroundColor: "#EAEAEA",
            fontSize: "15px",
            borderBottom: "1px solid rgba(0,0,0,.1)",
          }}
        >
          <header>Corporate Configuration</header>
        </Col>
        <Col className="mt-2">
          <SelectField
            data={corpconf}
            value={data?.auraSetting?.displayAmount}
            onChange={(v) =>
              setData({
                ...data,
                auraSetting: {
                  ...data?.auraSetting,
                  displayAmount: v?.value,
                },
              })
            }
          />
        </Col>
        <Col>
          <SaveButton />
        </Col>
      </Row>
    </Form>
  );
};

export default AuraSettings;
