import React, { useContext, useState } from 'react'
import { FaCheck, FaRegClock } from 'react-icons/fa'
import { IoLocationOutline } from 'react-icons/io5'
import { TfiDirection } from 'react-icons/tfi'
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import OfficeService from '../../pages/Office/OfficeService'
import RosteringService from '../../pages/Rostering/RosteringService'
import { populatePassengerPickupOrDrop, populateSrcOrDstOnBasisOfRosterTypeAndPassengers, populateStartTimeAndEndTimeOnBasisOfRosterType, populateTimeAndPassengerOnBasisOfRosterType } from '../../pages/Rostering/utils/helper'
import { DialogContext } from '../../store/context/DialogContext'
import { I18nContext } from '../../store/context/i18nContext'
import { UserContext } from '../../store/context/UserContext'
import { getFullAddress } from '../../util/Util'
import DroppableComponent from '../dragAndDrop/DroppableComponent'
import PassengerComponent from './PassengerComponent' 
import BookingService from '../../pages/Booking/BookingService'
import ActionMenuModal from '../ActionMenuModal'
import ActionMenu from '../BookingNew/ActionMenu'
import moment from 'moment'

const RouteTripCard = React.forwardRef(({ trip, onClick, index, isSelected, space }, ref) => {
  const { showMessage, showError, showConfirm } = useContext(DialogContext);
  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { updateTrip,toggleLoading } = RosteringService

  const saveOrUpdateTrips = async () => {
    try {
      if (!space?._id) {
        showError("Please select space");
        return;
      }
      toggleLoading(true);
      const office = await OfficeService.get({ editId: space?.office_id, org_id: userContent?.corp?._id });
      const {startTime,endTime,passengers,totalDistance=0}=await populateTimeAndPassengerOnBasisOfRosterType(
        space?.pickUpTme,
        space?.dropTme, 
        space?.fromDate, 
        space?.type,
        populatePassengerPickupOrDrop(trip.passengers, space?.type, office?.address),
        office?.address
      )
      const toSaveTrip = {
        ...trip,
        ...(populateSrcOrDstOnBasisOfRosterTypeAndPassengers(trip?.src, trip?.dst, space?.type, office?.address, trip?.passengers) ?? {}),
        // ...(populateStartTimeAndEndTimeOnBasisOfRosterType(trip?.startTime, trip?.endTime, space) ?? {}),
        // passengers: populatePassengerPickupOrDrop(trip.passengers, space?.type, office?.address),
        startTime,
        endTime,
        passengers,
        space_id: space?._id,
        ...(space?.client_id && {
          client:{
            client_id:space?.client_id,
          ...(space?.company_id && {
            company_id:space?.company_id
          })
          }
        }),
        duration:parseFloat(((endTime-startTime)/60).toFixed(2)),
        tripExtn:{
          ...(trip?.tripExtn || {}),
          distance:totalDistance??0
        }
      }
      let updatedBooking = null
      if (trip?._id) {
        updatedBooking = await BookingService.editAll({ trips: [toSaveTrip] });
        showMessage(t("Record Updated successfully"));
      } else {
        updatedBooking = await BookingService.saveAll({ trips: [toSaveTrip] });
        showMessage(t("Record saved successfully."));
      }
      await updateTrip(trip?.id, { ...trip, ...(updatedBooking ?? {}) })
    } catch (error) {
      showError(error)
    }finally{
      toggleLoading(false)
    }
  }
  const deleteTrip = async () => {
    try {
      updateTrip(trip?.id, trip, true)
      showMessage(t("Record Deleted successfully"));
    } catch (error) {
      showError(error)
    }
  }

  return (
    <DroppableComponent key={trip._id} droppableId={`tripList-${index}`} className='m-1'>
      <div
        ref={ref}
        data-id={trip._id}
        className=' rounded-2 p-1 bg-white m-1 shadow route-trip-card overflow-y-hidden'
        onClick={() => onClick(trip)}
      >
        <div className='mx-1 mb-1'>
          <div className=' d-flex justify-content-between cursor-pointer align-items-center'>
            <div className='d-flex align-items-center justify-content-start'>
              <Input type="checkbox" className='me-1' />
              <div className=' fw-bold font-size-13 mb-1 ms-1'>{trip.title ?? trip?.tripNo??`${moment().format('YYYYMMDD')}_${index}`}</div>
            </div>
            <div className='d-flex gap-1 align-items-center'>
              <FaCheck size={15} color={trip?.status ? "green" : "gray"} onClick={saveOrUpdateTrips} />
              <ActionMenuModal orientation='vertical'>
                <ActionMenu
                  booking={trip}
                  onDeleteCallback={deleteTrip}
                  compact={true}
                  postSave={(bookings) => {
                    if(bookings?.length){
                      updateTrip(trip?.id, bookings[0])
                    }
                  }}
                />
              </ActionMenuModal>
            </div>
          </div>

          {trip?.src ? <div className='d-flex justify-content-between pe-1 align-items-center mt-1'>
            <div className='d-flex align-items-center gap-1 overflow-text'>
              <div>
                <IoLocationOutline size={15} color='#999' />
              </div>
              <div className='font-size-11 overflow-text'>{getFullAddress(trip?.src ?? "")}</div>
            </div>
          </div> : null}

          <div className='d-flex flex-1 gap-2 mt-1'>
            <div className='d-flex align-items-center gap-1 '>
              <TfiDirection color='#999' />
              <div className='font-size-10 fw-bold'>{trip?.tripExtn?.distance ? `${(trip?.tripExtn?.distance??0)} kms` : "-"}</div>
            </div>
            <div className='d-flex align-items-center gap-1'>
              <FaRegClock color='#999' />
              <div className='font-size-10 fw-bold'>{trip?.duration ? `${trip?.duration} mins` : "-"}</div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-start pe-1 flex-1'>
          <PassengerComponent isSelected={isSelected} trip={trip} />
        </div>
      </div>
    </DroppableComponent>
  )
})

export default RouteTripCard