export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {

        let url = `/api/clientgroup/grid?rows=${sizePerPage}&`;
        if (filterUrl) url += filterUrl;
        return url + `&page=${page}`;

    },
    get: (id) => `/api/clientgroup/${id}/detail`,
    saveNew: `/api/clientgroup/save`,
    edit: (id) => `/api/clientgroup/${id}/edit`,
    delete: (id) => `/api/clientgroup/${id}/delete`,
};

export const STRUCTURE = [
    {
        label: "Name",
        description: "description...",
        filterLabel: "Name",
        filterName: "name",
        type: "text",
    },
    {
        label: "Client",
        description: "description...",
        filterLabel: "Client",
        filterName: "client_id",
        type: "clientSelect",
    },
];