import React from 'react'
import { BiMinus } from "react-icons/bi";

const RemoveLink = ({ style,size, className,onClick = () => {} }) => {
  return (
    <div
      className={`btn btn-outline-dark d-flex align-items-center ${className ?? className} `}
      style={{
        border: "1px solid #707070",
        borderRadius: "50%",
        cursor: "pointer",
        padding: "1px",
        width:"fit-content",
        ...style
      }}
      onClick={onClick}
    >
      <BiMinus fontSize={`${size ?? 18}`} />
    </div>
  );
}

export default RemoveLink;
