// CustomOperations.js
//Complete TOODs
// TODO isModal coming from props then show it in modal else show it in simple div
import React, { useState, useEffect } from 'react';
import CustomOperationsService from './CustomOperationsService'; // Adjust the path as necessary
import ModalComponent from '../Modal/Modal'; // Adjust the path as necessary
import './CustomOperations.css'; // Path to CSS file
import CreateField from './CreateField';
import { observer } from 'mobx-react-lite';
import { InputField } from '../InputField/InputField';
import { FaPlus } from "react-icons/fa";

const CustomOperations = ({ module, displayType, isOpen, toggleModal, isModal }) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchFields = async () => {
      await CustomOperationsService.fetch(module);
    };
    fetchFields();
  }, [module]);

  useEffect(() => {
    setGroups(CustomOperationsService?.groups)
  }, [CustomOperationsService?.groups])

  const handleAddGroup = () => {
    CustomOperationsService.addGroup();
    setGroups([...CustomOperationsService.getGroups()]);
  };

  const handleAddSection = (groupId) => {
    CustomOperationsService.addSection(groupId);
    setGroups([...CustomOperationsService.getGroups()]);
  };

  const renderCustomOperationsContent = () => (
    <div className="operations-body mb-5 mt-3 pb-5" >
      {groups.map(group => (
        <div key={group.id} className={group.name != "undefined" ? group.name ? " card selected-group" : "group" : null}>
          {group.name != "undefined" ? <>
            <InputField
              className='custom-group-name'
              type="text"
              inline
              label="Group Name"
              placeholder="Group Name"
              value={group.name}
              onChange={e => CustomOperationsService.updateGroupName(group.id, e)}
              autoFocus={group.isNew}
            />
            {/* {group.name && group.name != "undefined" ? <button className='d-flex align-items-center  btn btn-primary   custom-group-add' onClick={() => handleAddSection(group.id)}>Add Section</button> : null} */}
            {group.name && group.name != "undefined" ? <div className='w-100 d-flex justify-content-end'> <button className='d-flex align-items-center  btn btn-primary   custom-group-add' onClick={() => CustomOperationsService.addAloneField({ module, group: group?.name })}> <FaPlus className="me-2" /> Add Field</button>  </div> : null}
          </>
            : null}
          {(group.sections ?? []).map(section => (
            <div key={section.id} className={section.name != "undefined" ? section.name ? "selected-section" : "section" : null}>
              {section.name != "undefined" ? <>
                <InputField
                  type="text"
                  className='custom-section-name'
                  inline
                  placeholder="Section Name"
                  label="Section Name"
                  value={section.name}
                  onChange={e => CustomOperationsService.updateSectionName(group.id, section.id, e)}
                  autoFocus={section.isNew}
                />
                {section.name && section.name != "undefined" ? <button className='d-flex align-items-center  btn btn-primary  custom-section-add' onClick={() => CustomOperationsService.addAloneField({ module, group: group?.name, section: section?.name })}> <FaPlus className="me-2" /> Add Field</button> : null}
              </>
                : null}
              {(section.fields ?? []).map(field => (
                <CreateField
                  module={module}
                  field={field}
                  key={field?._id}
                />
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  )

  const renderCustomOperationsTitle = () => (
    <div className={`d-flex justify-content-between align-items-center pe-${isModal ? "2" : "0"}`}>
      <span>Additional Info Fields</span>
      <div className="operations-header d-flex align-items-center mb-0">
        <button style={{ padding: "0 5px 0 5px" }} className='d-flex align-items-center  btn btn-primary  mb-0 ' onClick={handleAddGroup}> <FaPlus className="me-2" /> Add Group</button>
        <button style={{ padding: "0 5px 0 5px" }} className={`d-flex align-items-center  btn btn-primary  ms-2 me-${isModal ? "4" : "0"} mb-0`} onClick={() => CustomOperationsService.addAloneField({ module })}> <FaPlus className="me-2" /> Add Field</button>
      </div>
    </div>
  )


  return (
    <div className={`custom-operations ${displayType}`}>
      {isModal ?
        <ModalComponent
          size="medium"
          position={"top"}
          isOpen={isOpen} onToggle={toggleModal}>
          <ModalComponent.Title>
            {renderCustomOperationsTitle()}
          </ModalComponent.Title>
          <ModalComponent.Body>
            {renderCustomOperationsContent()}
          </ModalComponent.Body>
          <ModalComponent.Footer>
            {/* Footer content can be added here */}
          </ModalComponent.Footer>
        </ModalComponent> :

        <div>
          {renderCustomOperationsTitle()}
          <hr />
          {renderCustomOperationsContent()}
        </div>
      }
    </div>
  );
};

export default observer(CustomOperations);
