import _ from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu, Row } from "reactstrap";
import {
    BulkUpload,
    CheckButton,
    DataGrid,
    DeleteLink,
    EditLink,
    Layout,
    ModalComponent,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import GroupTourSidebar from "../GroupTour/sideBar/GroupTourSidebar";
import GroupTourSidebarService from "../GroupTour/sideBar/GroupTourSidebarService";
import EditUsers from "./EditUser";
import { STRUCTURE } from "./UserConstant";
import { GridColumns } from "./UserGridColumns";
import UserService from "./UserService";
import BulkEmail from "../Communication/BulkEmail";
import BulkWhatsapp from "../Communication/BulkWhatsapp";
import { TOUR_TOGGLE_DATA } from "../BookingsNew/utils/constants";

const Users = ({
    showOnly,
    label,
    value,
    insidePane,
    multiMode,
    hideToggle,
    breadcrumb,
    formConfig = {
        showChecks: true,
        hideFilter: false,
        showAll: false
    },
    hideCheckboxes = false,
    onSelect = () => { },
    filterUrlProp
}) => {

    let { user_id } = useParams();
    const location = useLocation();
    const isGuests = _.includes(window.location.pathname, "guests");

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { userContent } = useContext(UserContext);
    const { showError, showMessage, showConfirm } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(user_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/user/create" || user_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)
    const [uploadPopup, setUploadPopup] = useState(false);
    const [bulkEmailPopup, setBulkEmailPopup] = useState(false);
    const [bulkWhatsappPopup, setBulkWhatsappPopup] = useState(false);
    const [userRecords, setUserRecords] = useState([]);

    const toggleData = window.location.pathname.includes("guests") ?
        TOUR_TOGGLE_DATA(t, "guests") : {
            module: [
                {
                    label: t("Customers"), value: "forCustomers"
                },
                { label: t("Client Customers"), value: "forClient" },
            ],
            toggleValue: "forCustomers"
        }

    const [selectedToggleValue, setSelectedToggleValue] = useState(toggleData?.toggleValue);

    const fetchData = async (filterUrl) => {

        let filterPayloadUrl = filterUrl
        if (GroupTourSidebarService.selectedFolder?._id && !formConfig?.showAll) {
            filterPayloadUrl += (`&space_id=${GroupTourSidebarService.selectedFolder.type == "single" ? null : GroupTourSidebarService.selectedFolder._id}`)
            setFilterURL(filterPayloadUrl)
        } else {
            setFilterURL(filterPayloadUrl);
        }

        if (loading) return;
        setLoading(true);
        try {
            const records = await UserService.fetch(filterPayloadUrl);
            setUserRecords(records ?? [])
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    const preFetchGrid = () => {
        let { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane, filterUrlProp)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }

    useEffect(() => {
        preFetchGrid()
    }, [UserService?.page, UserService?.rowsPerPage, filterURL, GroupTourSidebarService.selectedFolder?._id])

  
    useEffect(() => {
        if (!insidePane) setEditID(user_id)
    }, [user_id])

    useEffect(() => {
        if (selectedToggleValue === 'forCustomers') {
            UserService.onClientChange(null)
        }

    }, [selectedToggleValue])

    const renderLastCol = useCallback((user) => {
        return (
            <>
                {userContent?.rights?.includes(3502) &&
                    <EditLink
                        onClick={() => {
                            if (!insidePane)
                                navigate(isGuests ? `/guests/edit/${user?._id}` : `/user/edit/${user?._id}`);
                            setEditID(user?._id);
                            setShowDetailPage(true);
                        }}
                    />
                }
                {userContent?.rights?.includes(3503) &&
                    <DeleteLink
                        onClick={(event) => {
                            onDelete(event, user?._id, user?.name);
                        }}
                    />
                }
                {!multiMode && insidePane ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(user);
                        }}
                    />
                ) : null}
            </>
        );
    }, [insidePane, multiMode, userContent])


    const saveBulk = async (data) => {
        try {
            const account = await UserService.save(data);
            if (account) {
                return { isUploaded: true, message: "" }
            }
            return { isUploaded: false, message: "Failed unexpectedly" }
        } catch (error) {
            return { isUploaded: false, message: JSON.parse(error?.message)?.message }
        }
    };

    const onDelete = async (event, id, name) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: `Delete ${name}`,
                description: "This is an unrecoverable operation.",
            })
        ) {
            try {
                await UserService.delete(id);
                await fetchData(filterURL);
                showMessage("User deleted successfully");
                setShowDetailPage(false);
                if (isGuests) navigate(isGuests ? `/guests` : `/user`);
            } catch (e) {
                showError(e);
            }
        }
    };

    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await UserService.bulkDelete(selectedIDs);
                await fetchData(filterURL);
                setLoading(false);
                showMessage(t("Deleted"), t("Deleted Successfully"));
                setSelectedIDs([])
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };

    return (
        <React.Fragment key={userContent?.rights}>
            <div className="d-flex">

                {isGuests && !formConfig?.showAll ? <div style={{ minWidth: "200px" }} className={insidePane ? "" : "page-content"}>
                    <GroupTourSidebar module="events" />
                </div> : null}


                {uploadPopup ? (
                    <BulkUpload
                        show={uploadPopup}
                        onClose={() => { setUploadPopup(false) }}
                        onSheetClose={() => { preFetchGrid() }}
                        title={t("Customer")}
                        kind={"Customer"}
                        upload={saveBulk}
                    />
                ) : null}

                {bulkEmailPopup ? (
                    <ModalComponent
                        position={"top"}
                        size={"full-screen"}
                        isOpen={bulkEmailPopup}
                        onToggle={() => setBulkEmailPopup(false)}
                    >
                        <ModalComponent.Title>
                            <Row>
                                <span style={{ fontSize: "15px" }}>
                                    Bulk Email
                                </span>
                            </Row>
                        </ModalComponent.Title>
                        <ModalComponent.Body>
                            <BulkEmail
                                onBack={() => setBulkEmailPopup(false)}
                                customers={userRecords?.filter(v => selectedIDs?.includes(v?._id))}
                            />
                        </ModalComponent.Body>
                    </ModalComponent>
                ) : null}



                {bulkWhatsappPopup ? (
                    <ModalComponent
                        position={"top"}
                        size={"full-screen"}
                        isOpen={bulkWhatsappPopup}
                        onToggle={() => setBulkWhatsappPopup(false)}
                    >
                        <ModalComponent.Title>
                            <Row>
                                <span style={{ fontSize: "15px" }}>
                                    Bulk Message
                                </span>
                            </Row>
                        </ModalComponent.Title>
                        <ModalComponent.Body>
                            <BulkWhatsapp
                                onBack={() => setBulkWhatsappPopup(false)}
                                customers={userRecords?.filter(v => selectedIDs?.includes(v?._id))}
                                space_id={GroupTourSidebarService.selectedFolder?._id}
                            />
                        </ModalComponent.Body>
                    </ModalComponent>
                ) : null}

                <div className="flex-1" style={{ width: `calc(100vw - ${isGuests ? '250px' : 0})` }}>
                    <Layout
                        hideAdd={!userContent?.rights?.includes(3501)}
                        large
                        compact
                        toggleData={toggleData}
                        breadcrumb={breadcrumb}
                        showToggle={!hideToggle && !label}
                        hideheader={true}
                        hidefooter={true}
                        onToggle={(selectedValue) => {
                            if (window.location.pathname === "/guests") {
                                if (selectedValue === "tours") {
                                    navigate(`/tour`);
                                } else if (selectedValue === "trips") {
                                    navigate(`/trips`);
                                } else if (selectedValue === "stay") {
                                    navigate("/stay");
                                } else if (selectedValue === "flights") {
                                    navigate("/flight");
                                } else if (selectedValue === "guests") {
                                    navigate("/guests");
                                } else if (selectedValue === "calender") {
                                    navigate("/tours/calender");
                                }
                            } else {
                                setSelectedToggleValue(selectedValue);
                                if (selectedValue === "forCustomers") {
                                    UserService.onClientChange(null)
                                    navigate(`/user`);
                                } else if (selectedValue === "forClient") {
                                    navigate('/user');
                                }

                            }
                        }}

                        showClientSelect={selectedToggleValue === "forClient"}
                        showDetailPage={showDetailPage}
                        backDetailPage={async () => {
                            setShowDetailPage(false);
                            if (!insidePane) navigate(isGuests ? `/guests` : (`/user`))

                            setEditID(null);
                        }}

                        title={t(label)}
                        filterValues={filterObject}
                        filterStructure={STRUCTURE}
                        onApplyFilter={fetchData}
                        onAddClick={() => {

                            if (!insidePane) navigate(isGuests ? `/guests/create` : `/user/create`);
                            setShowDetailPage(true);
                            setEditID(null);
                        }}
                        insidePane={insidePane}
                        page={UserService.page}
                        rows={UserService.rows}
                        total={UserService.total}
                        onChangeClient={selectedToggleValue === "forClient" ?
                            (selectedClientId) => {
                                UserService.onClientChange(selectedClientId?.value);
                            } : undefined
                        }
                        clientValue={selectedToggleValue === "forClient" ? UserService?.client_id ?? "selectFirst" : undefined}
                        // clientValue={UserService?.client_id ?? "selectFirst"}
                        fetch={UserService.fetch}
                    >

                        <Layout.ActionMenu>
                            <div className="layout-action-menu">
                                <DropdownMenu>
                                    <>
                                        {userContent?.rights?.includes(3503) ?
                                        <>
                                            <DropdownItem onClick={() => { onBulkDelete() }}>{t("Bulk Delete")}</DropdownItem>
                                            <div class="dropdown-divider"></div>
                                            </>
                                            : null}
                                                            

                                        <DropdownItem onClick={() => setUploadPopup(true)}>
                                            {t("Bulk Upload")}
                                        </DropdownItem>

                                        <div class="dropdown-divider"></div>

                                        <DropdownItem onClick={() => setBulkEmailPopup(true)}>
                                            {t("Bulk Email")}
                                        </DropdownItem>

                                        <div class="dropdown-divider"></div>

                                        {GroupTourSidebarService.selectedFolder?._id ? <DropdownItem onClick={() => setBulkWhatsappPopup(true)}>
                                            {t("Bulk Message")}
                                        </DropdownItem> : null}

                                        <div class="dropdown-divider"></div>

                                        <DropdownItem> {t("Download")} </DropdownItem>
                                    </>
                                </DropdownMenu>

                            </div>
                        </Layout.ActionMenu>
                        <Layout.Table>
                            <DataGrid
                                gridLoading={loading}
                                hideCheckboxes={hideCheckboxes}
                                data={showOnly?.length ? userRecords?.filter(item => showOnly.some(val => val === item._id)) : userRecords}
                                total={UserService.total}
                                uiPreference="user.grid"
                                headers={GridColumns}
                                onSelectChange={(v) => {
                                    onSelect(v)
                                    setSelectedIDs(v)
                                }}
                                selectedIDs={selectedIDs}
                                page={UserService.page}
                                rowsPerPage={UserService.rowsPerPage}
                                onPaginationChange={UserService.onPaginationChange}
                                renderLastCol={renderLastCol}
                            />
                        </Layout.Table>

                        <Layout.DetailPageBody>
                            <EditUsers
                                onSave={() => { setShowDetailPage(false); fetchData(filterURL) }}
                                onDelete={() => {
                                    setShowDetailPage(false)
                                    fetchData(filterURL)
                                }}
                                isGuests={isGuests}
                                insidePane={insidePane}
                                onBack={() => {
                                    setShowDetailPage(false);
                                    if (!insidePane) navigate("/flight");
                                    setEditID(null);
                                }}
                                editId={editID}
                                selectedToggleValue={selectedToggleValue}
                                space={GroupTourSidebarService?.selectedFolder}

                            />
                        </Layout.DetailPageBody>

                    </Layout>
                </div>
            </div>
        </React.Fragment>
    );
};
export default observer(Users);
