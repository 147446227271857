import _ from 'lodash';
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { AmountField, InputField, Modal } from "../../components";
import RemoveLink from "../../components/Buttons/RemoveLink";
import { I18nContext } from "../../store/context/i18nContext";
import Expense from "./Expense";
import './style.css';

const newItem = {
    head: "",
    notes: "",
    unitCost: "",
    units: "",
    subTotal: "",
    taxAmount: "",
    total: "",
    isAbs: false,
}

const Items = ({ itemize, data, items = [newItem], onChange, addBooking, addExpense, }) => {

    const [addModal, setAddModal] = useState(false)
    const { t } = useContext(I18nContext);


    const addItems = () => {
        onChange([...items, newItem]);
    };

    const deleteItems = (index) => {
        const updatedData = items.filter((_, i) => i !== index);
        onChange(updatedData);
    };

    const calculateTotalExpense = (items) => {
        return items.reduce((total, item) => total + parseFloat(item.total || 0.00), 0);
    };

    const gettotalTax = (accounts) => accounts?.reduce((totaltax, curr) => totaltax + curr.taxAmount, 0);
    const gettotalAmount = (items) => {
        return items.reduce((amount, item) => amount + parseFloat(item.subTotal || 0.00), 0.00);
    };

    const updateItem = ({ index, key, value }) => {
        const updatedData = [...items];
        updatedData[index] = { ...updatedData[index], [key]: value };
        if (key === "units" || key === "unitCost" || key === "taxAmount") {
            updatedData[index] = { 
                ...updatedData[index], 
                subTotal: ((updatedData[index].units * updatedData[index].unitCost)/100),
                total: ((updatedData[index].units * updatedData[index].unitCost)/100) + updatedData[index].taxAmount, 
            };
        }
        onChange(updatedData);
    };


    return (

        <div>
            {addModal !== false &&
                <Modal onChange={() => setAddModal(false)}>
                    {addModal == "expense" &&
                        <Expense
                            insidePane={true}
                            multiMode={false}
                        />}
                </Modal>
            }
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    {addBooking && <div onClick={() => { }} className="d-flex align-items-center me-4">
                        <i className="bx bxs-plus-circle font-size-20 me-2"></i>
                        Add Booking
                    </div>}
                    {addExpense &&
                        <div onClick={() => { }} className="d-flex align-items-center">
                            <i className="bx bxs-plus-circle font-size-20 me-2"></i>
                            Add Expense
                        </div>
                    }
                </div>
            </div>
            <div>
                <table className="table-bordered expense">
                    <thead>
                        <tr>
                            <th className="ms-3" >Head</th>
                            <th className="ms-3" >Notes</th>
                            <th className="ms-3">Cost per unit</th >
                            <th className="ms-3">Unit</th>
                            <th className="ms-3" >Tax</th>
                            <th className="ms-3" >Total Amount</th>
                        </tr>
                    </thead>
                    {items.map((account, index) => (
                        <tbody className="expense-table-hover position-relative">
                            <tr key={index}>
                                <td>
                                    <InputField
                                        noLabel={false}
                                        // isDisabled={true}
                                        placeholder={t("Head")}
                                        outline
                                        value={account.head}
                                        onChange={(v) => {
                                            updateItem({ value: v, index, key: "head" })
                                        }}
                                        type="text"
                                    />
                                </td>
                                <td className="test">
                                    <InputField
                                        placeholder={t("Items notes ")}
                                        value={account.notes}
                                        onChange={(v) => {
                                            updateItem({ value: v, index, key: "notes" })
                                        }}
                                        outline
                                        noLabel={false}
                                        type="text"
                                    />
                                </td>
                                <td className=" ">


                                    <AmountField
                                        outline
                                        placeholder={"Cost Per Unit"}
                                        value={!account.unitCost || account.unitCost === 0 ? "0.00" : account.unitCost}
                                        onChange={(v) => {
                                            updateItem({ value: Number(v), index, key: "unitCost" })
                                        }}
                                        type="text"
                                    />
                                </td>
                                <td>
                                    <AmountField
                                        outline
                                        placeholder={"Unit"}
                                        value={(itemize & (!account.units || account.units === 0)) ? "0.00" : account?.units}
                                        onChange={(v) => {
                                            updateItem({ value: Number(v), index, key: "units" })
                                        }}
                                        type="text"
                                    />
                                </td>
                               
                                <td>
                                    <AmountField
                                        value={!account?.taxAmount || account?.taxAmount === 0 ? "0.00" : account?.taxAmount}
                                        onChange={(v) => {
                                            updateItem({ value: Number(v), index, key: "taxAmount" });
                                        }}
                                        outline
                                        noLabel={false}
                                        type="number"
                                    />
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div style={{ width: "80px", color: "black", fontSize: "15px" }}>
                                            {data?.currency} {account?.total !== undefined ? _.round((account.total ?? 0) / 100, 2).toFixed(2) : null}
                                        </div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <RemoveLink className="ms-1" onClick={() => deleteItems(index)} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>

            <Row className="d-flex border-top border-bottom border-dark">

                <Col className="col-md-2 col-12 pt-2">
                    <Button color="light" onClick={addItems} className="d-flex align-items-center">
                        <i className="bx bx-plus  font-size-20 me-2"></i>
                        Add item
                    </Button>
                </Col>
                <Col className="col-md-10 col-12 flex-1 font-size-13" >
                    {/* <div className=" row  my-1 col-12">
                        <span className="d-flex justify-content-end col-md-10 col-9">SubTotal: </span>
                        <div className="col-md-2 col-3  ">
                            {data?.currency} {(_.round((gettotalAmount(items) ?? 0) / 100, 2)).toFixed(2)}
                        </div>
                    </div>
                    <div className=" row  my-1 col-12  ">
                        <span className="d-flex justify-content-end col-md-10 col-9">Total Taxes:</span>
                        <div className="col-md-2 col-3 ">
                            {data?.currency}   {_.round((gettotalTax(items) ?? 0) / 100, 2).toFixed(2)}
                        </div>
                    </div> */}
                    <div className=" row  my-3 col-12 d-flex justify-content-end  align-items-center " style={{ fontWeight: 'bold' }}>
                        <span className="    d-flex justify-content-end col-md-10 col-9">Total Amount:</span>
                        <div className="col-md-2 col-3">
                            {data?.currency}  {_.round((calculateTotalExpense(items) ?? 0) / 100, 2).toFixed(2)}
                        </div>
                    </div>

                </Col>

            </Row>

        </div>
    );
};

export default observer(Items);

