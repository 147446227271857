import { Row } from 'reactstrap';
import CustomTooltip from '../Booking/components/CustomTooltip';
export const GridColumns = {
  version: 1,
  columns: [

    {
      title: "Name",
      accessor: "name",
      show: true,
      renderer: (dataObj) => {
        return <div>
          {dataObj?.name ? `${dataObj?.salutation ?? ""} ${dataObj?.name}` : "--"}
        </div>
      }
    },

    {
      title: "Designation",
      show: true,
      renderer: (dataObj) => {
        return <div>
          {dataObj?.designation ? `${dataObj?.designation}` : "--"}
        </div>
      }
    },
    {
      title: "Department",
      accessor: "dept",
      show: true,
      renderer: (dataObj) => {
        return <div>
          {dataObj?.dept ? `${dataObj?.dept}` : "--"}
        </div>
      }
    },
    {
      title: "Email",
      accessor: "email",
      show: true,
    },

    {
      title: "Phone No.",
      show: true,
      renderer: (dataObj) => {
        return <div>
          {dataObj?.phoneNo?.phone ? `+${dataObj?.phoneNo?.countryCode} ${dataObj?.phoneNo?.phone}` : "--"}
        </div>
      }
    },
    {
      title: "Status",
      accessor: "status",
      show: true,
      renderer: (dataObj, rowIndex, pageSize) => {
        return (
          <div className="d-flex align-items-end">
            {dataObj?.status == 0 || dataObj?.status === undefined ? (
              <div>

                <CustomTooltip>
                  <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                    <i id="not-clickable-isBooker-Inactive" className="fas fa-ban font-size-12 mt-1" style={{ color: 'red' }} />

                  </CustomTooltip.Body>
                  <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                    <div>InActive</div>
                  </CustomTooltip.ToolTip>
                </CustomTooltip>


             </div>
            ) : (

              <div>

                  <CustomTooltip>
                    <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                      <i id="not-clickable-isBooker-active" className="fas fa-check-circle font-size-12" style={{ color: 'green' }} />

                    </CustomTooltip.Body>
                    <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                      <div>Active</div>
                    </CustomTooltip.ToolTip>
                  </CustomTooltip>


              </div>
            )}
          </div>

        );
      },
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
    {
      title: "Type",
      accessor: "type",
      show: true,
      renderer: (dataObj, rowIndex, pageSize) => {

        const iconsData = [
          { condition: dataObj?.isBooker, text: "Booker", icon: "fas fa-user-check fa-lg", color: "green" },
          { condition: dataObj?.isApprover, text: "Approver", icon: "fas fa-user-tie fa-lg", color: "blue" },
        ];

        return (
          <div className="d-flex">
            {iconsData.map((item, index) =>
              item.condition ? (
                <div key={index}>

                  <CustomTooltip>
                    <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                      <span className="col-1" id={`not-clickable-${item.text}`} data-tip={item.text} style={{ marginRight: '30px' }}>
                        <i className={item?.icon} style={{
                          color: item.color,
                        }} />
                      </span>
                      

                    </CustomTooltip.Body>
                    <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                      <div>{item.text}</div>

                    </CustomTooltip.ToolTip>
                  </CustomTooltip>

                
                </div>
              ) : <div key={index}>
                <Row>
                  <span className="col-1" id={`not-clickable-${item.text}`} data-tip={item.text} style={{ marginRight: '50px' }}>
                  </span>
                </Row>
              </div>
            )}
          </div>
        );
      },
    },

    {
      title: "Transport",
      accessor: "transport",
      renderer: (dataObj, rowIndex, pageSize) => {

        const iconsData = [
          { condition: dataObj?.transportAdhoc, text: "Adhoc Transport User", icon: "fas fa-car fa-lg", id: "Adhoc-Transport-User", color: "orange" },
          { condition: dataObj?.transportRegular, text: "Regular Transport User", icon: "fas fa-bus fa-lg", id: "Regular-Transport-User", color: "purple" },
        ];

        return (
          <div className="d-flex">
            {iconsData.map((item, index) =>
              item.condition ? (
                <div key={index}>

                  <CustomTooltip>
                    <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                      <span className="col-1" id={`not-clickable-${item.id}`} data-tip={item.id} style={{ marginRight: '30px' }}>
                        <i style={{
                          color: item.color
                        }} className={item?.icon} />
                      </span>
                    </CustomTooltip.Body>
                    <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                      <div>{item.text}</div>
                    </CustomTooltip.ToolTip>
                  </CustomTooltip>


                </div>
              ) : <div key={index}>
                <Row>
                  <span className="col-1" id={`not-clickable-${item.id}`} data-tip={item.id} style={{ marginRight: '48px' }}>
                  </span>
                </Row>
              </div>
            )}
          </div>

        );
      },
      show: true,
    },

  ],
};
