import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { MdAdd, MdInbox, MdSend } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { CrossButton, Pagination, ToggleSelector } from '../../../components';
import AddCustomer from '../../../components/AddCustomer/AddCustomer';
import { useModal } from '../../../store/context/ModalContext';
import { customerFromCustomerId } from '../../../util/Util';
import EditUser from '../../Users/EditUser';
import CRMService from '../CRMService';
import CustomerDetails from '../components/customerDetails/CustomerDetails';
import EmailDetails from './EmailDetails';
import EmailList from './EmailList';
import EmailService from './EmailService';
import WriteEmail from './WriteEmail';
import { BiSolidEditAlt } from "react-icons/bi";
import './styles.scss';
import { IoMdRefresh } from 'react-icons/io';

const Email = ({ insidePane = false, selectedChat }) => {
    const navigate = useNavigate();
    const { email_id } = useParams();
    const [view, setView] = useState('list');
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [composeEmailView, setComposeEmailView] = useState(false);
    const [inbound, setInbound] = useState(true);
    const [prefilledData, setPrefilledData] = useState({ to: [], subject: "" });
    const [displayEmail, setDisplayEmail] = useState(false);
    const[isLoading,setIsLoading]=useState(false);


    const { toggleModal, setIsOpen, setModalContent } = useModal();

    useEffect(() => {
        if (email_id) {
            EmailService.fetchEmailById(email_id).then(() => {
                setSelectedEmail(EmailService.selectedEmail);
                setView('display');
            });
        }
    }, [email_id]);

    useEffect(() => {
        const customer = customerFromCustomerId(selectedChat?.customer_id);
        EmailService.fetchEmails({ inbound, email: customer?.email });
    }, [inbound, selectedChat]);

    const handleSelectEmail = (email) => {
        if (insidePane) {
            setDisplayEmail(true);
            setSelectedEmail(email);
        } else {
            CRMService.navigateToEmail(email._id, navigate);
        }
    };

    const handleBack = () => {
        if (insidePane) {
            setDisplayEmail(false);
            setSelectedEmail(null);
        } else {
            setView('list');
            navigate('/crm/email');
        }
    };


    const handleEditButtonClick = (e) => {
        e.stopPropagation();
        toggleModal();
        setModalContent({
            title: (
                <div className='zIndex-2 ps-4'>
                    <CrossButton className="custom_modal_close" onClick={() => { setIsOpen(false) }} />
                </div>
            ),
            body: (
                <EditUser
                    onSave={() => { }}
                    onDelete={() => { }}
                    insidePane
                    onBack={() => { }}
                    editId={""}
                    prefilData={{ email: EmailService?.selectedEmail?.from }}
                />
            ),
        });
    };

    const handleInbox = () => {
        setInbound(true);
        EmailService.setPage({ page: 1, rowsPerPage: 10, inbound: true });
    };

    const handleSent = () => {
        setInbound(false);
        EmailService.setPage({ page: 1, rowsPerPage: 10, inbound: false });
    };

    const handleReplyClick = () => {
        const recipients = Array.isArray(selectedEmail?.from) ? selectedEmail.from : [selectedEmail.from];
        setComposeEmailView(true);
        setPrefilledData({ to: recipients, subject: `Re: ${selectedEmail?.subject}` });
    };

    const refreshAction = async () => {
        setIsLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        if (!inbound) {
            handleSent();
        } else {
            handleInbox();
        }
        setIsLoading(false); 
    };

    return (
        <div className="zr_crm_email d-flex crm_email_body">
            <div className='row w-100'>
                <div className={`zr_crm_email-list email_column ${view === 'display' && selectedEmail ? "col-3 px-0" : "col-12 px-0"}`}>
                    {!displayEmail && (
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='flex-1 d-flex align-items-center'>
                               {view !="display"  &&  <div onClick={() => { setView("list"); setComposeEmailView(true) }} className='d-flex gap-2 primary-bg align-items-center me-2 py-1 px-1 borderRadius-8'>
                                   <span className='text-white p-1'><BiSolidEditAlt size={18} /> Compose</span>
                                </div>}
                                

                                <ToggleSelector
                                    style={{ marginRight: "10px" }}
                                    data={[
                                        { label: view != "display" ? "Inbox" : <MdInbox size={18} />, value: true },
                                        { label: view != "display" ? "Sent" : <MdSend size={18} />, value: false },
                                    ]}
                                    zeroMargin
                                    value={inbound}
                                    compact
                                    tabClassName={view != "display" ? "px-2" : "px-0"}
                                    onChange={(v) => { v ? handleInbox() : handleSent() }}
                                />
                                <IoMdRefresh color='#000' size={25} className={isLoading ? 'rotating cursor-pointer' : 'cursor-pointer'} onClick={refreshAction} />


                            </div>
                            <div>
                                <Pagination
                                    page={EmailService.pagination.page}
                                    rowsPerPage={EmailService.pagination.rowsPerPage}
                                    total={EmailService.pagination.total}
                                    style={{ backgroundColor: "transparent" }}
                                    hideRowSelector
                                    onChange={(page, rowsPerPage) => EmailService.setPage({ page, rowsPerPage, inbound })}
                                />
                            </div>
                        </div>
                    )}
                    {displayEmail ? (
                        <EmailDetails
                            inbound={inbound}
                            email={selectedEmail}
                            onBack={handleBack}
                            onReplyClick={handleReplyClick}
                        />
                    ) : (
                        <EmailList
                            showBody={!(view === 'display' && selectedEmail)}
                            inbound={inbound}
                            emails={EmailService.emails}
                            onSelectEmail={handleSelectEmail}
                            selectedMail={email_id}
                            expand={(view === 'display'?false:true)}
                        />
                    )}
                </div>
                {view === 'display' && selectedEmail && (
                    <>
                        <div className='col-6 px-0 email_column'>
                            <EmailDetails
                                inbound={inbound}
                                email={selectedEmail}
                                onBack={handleBack}
                                onReplyClick={handleReplyClick}
                            />
                            {/* todo sanyam EmailDetails component is written twice , find a way because using this practive we miss things
                            // like line 106 have dont have reply button for displaying email , you should move reply button inside EmailDetails, with call back function , onReply  */}
                        </div>
                        {view === 'display' && selectedEmail && (
                            <div className='col-3 px-0 email_column '>
                                {EmailService.selectedEmail?.customer_ids?.length === 0 || !EmailService.selectedEmail?.customer_ids ? (
                                    <AddCustomer email={selectedEmail?.from} onAddCustomer={handleEditButtonClick} />
                                ) : (
                                    <CustomerDetails customerId={EmailService?.selectedEmail?.customer_ids?.[0]} />
                                )}
                            </div>
                        )}

                    </>
                )}
                {composeEmailView && (
                    <div>
                        <WriteEmail
                            prefilledData={prefilledData}
                            onClose={() => { setComposeEmailView(false); setPrefilledData({}) }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default observer(Email);
