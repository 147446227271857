

export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl, corpId) => {
        if (!corpId) {
            let url = `/api/invoicesetup/detail?rows=${sizePerPage}&`;
            if (filterUrl) url += filterUrl;
            return url + `&page=${page}`;
        }
        return `/api/invoicesetup/${corpId}/invoicesetup/all`
    },

    saveNew: `/api/invoicesetup/save`,

};

export const SELECTSTRUCTURE = {
    kind: [
        { label: "General", value: 1 },
        { label: "SEZ", value: 2 },

    ],
    sequence: [
        { label: "Auto", value: 0 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
        { label: 6, value: 6 },
        { label: 7, value: 7 },
        { label: 8, value: 8 },
        { label: 9, value: 9 },
        { label: 10, value: 10 },

    ],

};

