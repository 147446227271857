import { ThreeBounce } from 'better-react-spinkit';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { DialogContext } from '../../store/context/DialogContext';
import { doPOST } from '../../util/HttpUtil';

const BulkWhatsapp = ({ onBack, customers = [], space_id, forFlightOnly, forStayOnly, forTripOnly }) => {
    const [data, setData] = useState({
        customers: customers.map(c => {
            if (c?.phoneNo?.countryCode && c?.phoneNo?.phone && c?.name) {
                return {
                    countryCode: c.phoneNo.countryCode,
                    phone: c.phoneNo.phone,
                    name: c.name
                };
            }
            return null;
        }).filter(Boolean),
        flight: forFlightOnly ? true : false,
        stay: forStayOnly ? true : false,
        trip: forTripOnly ? true : false,
        sms: false,
        whatsapp: false,
        spaceTracking: false,
    });

    const { showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);

    const handleSendWhatsapp = async () => {
        if (!data?.customers?.length) {
            showError("Recipients are missing");
            return;
        }

        setLoading(true);
        try {
            const response = await doPOST("/api/message/data/send/bulk", {
                space_id,
                customers: data.customers.map(v => ({ countryCode: v.countryCode, phone: v.phone })),
                flight: data.flight,
                stay: data.stay,
                trip: data.trip,
                spaceTracking: data.spaceTracking,
                sms: data.sms,
                whatsapp: data.whatsapp,
            });
            if (response?.status === 200) {
                showMessage("Message sent successfully");
                onBack();
            }
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    };

    const toggleCheckbox = (field) => {
        setData(prev => ({ ...prev, [field]: !prev[field] }));
    };

    const handleCustomerSelection = (customer) => {
        const isSelected = data.customers.some(c => c.phone === customer.phoneNo.phone);
        const newCustomers = isSelected
            ? data.customers.filter(c => c.phone !== customer.phoneNo.phone)
            : [...data.customers, { countryCode: customer.phoneNo.countryCode, phone: customer.phoneNo.phone, name: customer.name }];
        setData(prev => ({ ...prev, customers: newCustomers }));
    };

    return (
        <div className='zr_communication_compose'>
            <div style={{ borderBottom: "1px solid #EBEBEB" }} className="row pb-2 my-2">
                {customers.map((passenger, index) => {
                    if (passenger?.phoneNo?.countryCode && passenger?.phoneNo?.phone && passenger?.name) {
                        return (
                            <div key={index} className="col-sm-12 col-md-4 col-lg-3 col-lg-2 d-flex align-items-center mb-2">
                                <Input
                                    type="checkbox"
                                    checked={data.customers.some(c => c.phone === passenger.phoneNo.phone)}
                                    onChange={() => handleCustomerSelection(passenger)}
                                />
                                <span className="ms-2 mt-1"> {`${passenger.phoneNo.countryCode}-${passenger.phoneNo.phone} (${passenger.name})`}</span>
                            </div>
                        );
                    } else {
                        return <></>;
                    }
                })}
            </div>

            {(!forStayOnly && !forTripOnly) && (
                <div className="mb-3 pt-1">
                    <Label className='d-flex align-items-center' check>
                        <Input
                            type="checkbox"
                            checked={data.flight}
                            onChange={() => toggleCheckbox('flight')}
                            disabled={forFlightOnly}
                        />
                        <span className='ms-2'> Send Flight Details</span>
                    </Label>
                </div>
            )}

            {(!forFlightOnly && !forTripOnly) && (
                <div className="mb-3">
                    <Label className='d-flex align-items-center' check>
                        <Input
                            type="checkbox"
                            checked={data.stay}
                            onChange={() => toggleCheckbox('stay')}
                            disabled={forStayOnly}
                        />
                        <span className='ms-2'> Send Stay Details</span>
                    </Label>
                </div>
            )}

            <div className="mb-3">
                <Label className='d-flex align-items-center' check>
                    <Input
                        type="checkbox"
                        checked={data.spaceTracking}
                        onChange={() => toggleCheckbox('spaceTracking')}
                    />
                    <span className='ms-2'> Send Tracking Details</span>
                </Label>
            </div>

            {/* {(!forFlightOnly && !forStayOnly) && (
                <div className="mb-3">
                    <Label className='d-flex align-items-center' check>
                        <Input
                            type="checkbox"
                            checked={data.trip}
                            onChange={() => toggleCheckbox('trip')}
                            disabled={forTripOnly}
                        />
                        <span className='ms-2'> Send Trip Details</span>
                    </Label>
                </div>
            )} */}

            <div style={{ borderTop: "1px solid #EBEBEB" }}  className="mb-3 pt-2">
                <Label className='d-flex align-items-center' check>
                    <Input
                        type="checkbox"
                        checked={data.sms}
                        onChange={() => toggleCheckbox('sms')}
                    />
                    <span className='ms-2'> Send SMS</span>
                </Label>
            </div>

            <div className="mb-3">
                <Label className='d-flex align-items-center' check>
                    <Input
                        type="checkbox"
                        checked={data.whatsapp}
                        onChange={() => toggleCheckbox('whatsapp')}
                    />
                    <span className='ms-2'> Send Whatsapp</span>
                </Label>
            </div>

            <div className="mt-3 d-flex justify-content-end">
                <Button color="success" onClick={handleSendWhatsapp} className="ms-2">
                    {loading ? <ThreeBounce color="#fff" /> : <>Send</>}
                </Button>
            </div>
        </div>
    );
};

export default observer(BulkWhatsapp);
