import { Tooltip } from 'react-tooltip';

export const GridColumns = {
  version: 1,
  columns: [

    {
      title: "Name",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.name}
        </div>

      },
      show: true,
    },

     {
      title: "Kind",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.kind === 1 ? "For App" : "For Website"}
        </div>

      },
      show: true,
    },


    {
      title: "Active",
      accessor: "active",
      renderer: (dataObj) => {
          return (
              <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -5 }}>
                  <div
                      style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor:
                              dataObj?.active === true ? 'green' : dataObj?.active == false ? 'red' : 'blue',
                          marginRight: '5px',

                      }}
                  ></div>
                  <span>
                      {dataObj?.active === false ? "Inactive" : "Active" }
                  </span>
              </div>
          );

      },
      show: true,
      required: true,
  },
   

    {
      title: "Detail",
      renderer: (dataObj) => {
        const truncateString = (inputString, maxLength) => {
          return inputString.length <= maxLength ? inputString : inputString.slice(0, maxLength) + "...";
        }

        return <div>
          {dataObj?.detail?.length > 20 ?
            <div className="row">

              <Tooltip noArrow style={{
                zIndex: 999999,
                maxWidth: '300px',
                height: "auto",
                backgroundColor: '#F2F2F2',
                color: "black",
                whiteSpace: 'pre-wrap',
                overflow: 'auto',
                fontWeight: 'bold'
              }} anchorSelect={`#not-clickable-${dataObj?._id}`}>
                {dataObj?.detail}
              </Tooltip>
              <div id={`not-clickable-${dataObj?._id}`}>
                {truncateString(dataObj?.detail, 20)}
              </div>
            </div> :
            dataObj?.detail
          }
        </div >
      },
      show: true,
    },


    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
    
  ],
};
