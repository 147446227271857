import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Button, Card, Table } from 'reactstrap';
import TripExpenseDetail from './TripExpenseDetail';
import TripExpenseService from './TripExpenseService';
import './index.scss';
import moment from 'moment';
import AddTripExpense from './AddTripExpense';

const TripExpense = ({
    trip_id,
    setExpenses,
    disabled = false,
    onChange = () => { }
}) => {

    const [loading, setLoading] = useState(false);
    const [showTripExpenseDetail, setShowTripExpenseDetail] = useState({ status: false });
    const [addExpenseModal, setAddExpenseModal] = useState(false);

    useEffect(() => {
        const fetchSupplierFares = async () => {
            setLoading(true);
            try {
                setExpenses(await TripExpenseService.fetch(trip_id));
            } catch (error) {
                console.error("Failed to fetch itineraries:", error);
            } finally {
                setLoading(false);
            }
        };

        if (trip_id) {
            fetchSupplierFares();
        }
    }, [trip_id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (

        <Card className=" ">
            <div style={{ backgroundColor: "#EBEBEB" }} className="h3 ps-3 py-2 d-flex align-items-center justify-content-between">
                <div>Trip Expense  </div>

                {disabled ? null : <Button onClick={() => {
                    setAddExpenseModal(true)
                }} className="d-flex align-items-center py-1 me-3">
                    <FaPlus className='me-2' /> Add Trip Expense
                </Button>}

            </div>

            <div className='px-3'>

                {TripExpenseService?.records?.length ?
                    <>

                        <Table className="table-borderless table-centered table-nowrap">
                            <tbody>
                                <tr style={{ borderBottom: "1px solid #EBEBEB" }}>
                                    <td>Category</td>
                                    <td>Paid At</td>
                                    <td>Detail</td>
                                    <td className=" text-end ">Amount</td>
                                    <td className="text-muted fw-semibold text-end"></td>
                                </tr>

                                {TripExpenseService?.records.map((tripExpense, index) => (
                                    <tr>
                                        <td>
                                            <h6 className="font-size-15 mb-1 fw-normal">
                                                {tripExpense?.category}
                                            </h6>
                                        </td>

                                        <td>

                                            {tripExpense?.paidAt ? moment.unix(tripExpense?.paidAt).format("DD-MM-YYYY  hh:mm A") : "--"}


                                        </td>

                                        <td>
                                            {tripExpense?.detail}
                                        </td>

                                        <td className="text-muted fw-semibold text-end h4">

                                            Rs. {tripExpense?.amount ? ((tripExpense?.amount / 100)).toFixed(2) : 0}
                                        </td>

                                        <td className="text-muted text-end">
                                            {disabled ? null : <div
                                                onClick={() => { setShowTripExpenseDetail({ id: tripExpense?._id, status: true }) }}>
                                                <i className="uil uil-pen font-size-18" />
                                            </div>}
                                        </td>


                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </> :
                    <>
                        <div>No Expense found for this trip.</div>
                    </>
                }
                {
                    showTripExpenseDetail?.status && <TripExpenseDetail
                        trip_id={trip_id}
                        editId={showTripExpenseDetail?.id}
                        isOpen={showTripExpenseDetail?.status}
                        setShowTripExpenseDetail={setShowTripExpenseDetail}
                        toggleModal={() => { setShowTripExpenseDetail({ status: false }) }}
                        setExpenses={(v) => {
                            setExpenses(v)
                            onChange(v)
                        }}
                    />
                }
                {
                    addExpenseModal && <AddTripExpense
                        trip_id={trip_id}
                        isOpen={addExpenseModal}
                        toggleModal={() => { setAddExpenseModal(false) }}
                        setExpenses={(v) => {
                            setExpenses(v)
                            onChange(v)
                        }}
                    />
                }

            </div>
        </Card >
    );
};

export default observer(TripExpense);
