import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import Account from "../../../pages/Accounts/Accounts";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, Modal, SearchButton,ModalComponent } from "../../index";
import AccountSelectService from "./AccountSelectService";

const AccountSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  outline,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  corp_id,
  asLabel
}) => {
  const [accounts, setAccounts] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const updateAccounts = () => {
    setAccounts(
      toJS(AccountSelectService?.accounts)?.map((account) => {
        return {
          ...account,
          value: account?._id,
          label: (
            <div>
              {account?.acDetail?.name} ({account?.acDetail?.accountNo})
            </div>
          ),
        };
      })
    );
  }

  const filterOption = useCallback((option, inputValue) => { 
    return !inputValue ||
      ['name', 'accountNo'].some(
        key =>
          option?.data?.acDetail?.[key]?.toLowerCase().includes(inputValue.toLowerCase())
      );
  }, []);
  

  const fetchAccounts = async (corp_id=null) => {
    try {
      await AccountSelectService.fetch(corp_id);
      updateAccounts()
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
     fetchAccounts(corp_id);
  }, [corp_id]);

  useEffect(() => {
    updateAccounts()
  }, [AccountSelectService.accounts]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectAccount = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0] : selectedValue });
    closeModal();
  };

  const selectAccounts = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      filterOption={filterOption}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Accounts")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={accounts}
      required={required}
      value={value}
      label={label || (!isDisabled && t("Account"))}
      error={!value ? t(`Please Select ${label || "Account"}`) : ""}
      showErr={showErr}
      inline={inline}
      outline={outline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      filterOption={filterOption}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Accounts")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={selectAccounts}
      data={accounts}
      value={value ? accounts.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Accounts"}`) : ""}
      required={required}
      outline={outline}
      label={t("Select Accounts")}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
    {modal && (
            <ModalComponent
              position={"top"}
              size={"large"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Accounts")}
              </ModalComponent.Title>

              <ModalComponent.Body>
              <Account
            value={value}
            insidePane={true}
            multiMode={multi}
            onSelect={multi ? selectAccounts : selectAccount}
            />
          </ModalComponent.Body>

        </ModalComponent>
      )}
      {asLabel ? (
        <div>
          {accounts?.find((account) => account?.value == value)?.label ? (
            <div className="d-flex align-items-center gap-3">
              {label?<div><b>{label} :</b></div>:null}
              <div>{accounts?.find((account) => account?.value == value)?.label}</div>
            </div>
          ) : (
            <div style={{ color: "grey" }}>--</div>
          )}
        </div>
      ) : (
        accounts?.length > 0
          ? multi
            ? renderSelectFieldMultiple()
            : renderSelectField({ disabled: false })
          : renderSelectField({ disabled: true })
      )}
    </>
  );
};

export default observer(AccountSelect);
