import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { observer } from "mobx-react-lite";
import { ModalPopup } from "../Offers/Modal";
import Fuels from "./store";
import { toJS } from "mobx";
import { round } from "lodash";

const TimeSheetPopup = (props) => {
  const [bookings, setBookings] = useState([]);
  const [populatedKms, setPopulatedKms] = useState([]);
  // const [totalTravelled, setTotalTravelled] = useState(0);
  const [billable, setBillable] = useState(0);
  useEffect(() => {
    async function fetch() {
      await Fuels.getFuelSummaryTimeSheet(props?.id);
      setBookings(Fuels.timeSheet);
      getKms(toJS(Fuels.timeSheet));
    }
    fetch();
  }, [props?.id]);

  const getKms = (bs) => {
    const x = [];
    let t = 0;
    bs.forEach((b) => {
      let distance = 0;
      if (b.garagePickupMins) distance += b.garagePickupMins;
      if (b.garageDropKMs) distance += b.garageDropKMs;
      if (b.distance) distance += b.distance;
      t += distance;
      x.push(distance);
    });
    setBillable(t);
    setPopulatedKms(x);
  };
  function toDateTime(miliSec) {
    var t = new Date();
    t.setMilliseconds(miliSec);

    return t;
  }

  return (
    <React.Fragment>
      <ModalPopup
        isOpen={props?.modal}
        setIsOpen={props?.setModal}
        noFooter={true}
        title={"Zone Time Sheet"}
        body={
          <div>
            <div className="col-12 my-3 d-flex justify-content-between px-3">
              <p>
                Trips # <b>{bookings.length}</b>
              </p>
              <p>
                Travelled: <b>{props?.totalTravelled} KMs</b>
              </p>
              <p>
                Biilable: <b>{billable} KMs</b>
              </p>
              <p>
                Billable Distance:{" "}
                <b>
                  {props?.totalTravelled
                    ? round((billable / props?.totalTravelled) * 100, 2)
                    : 0}{" "}
                  %
                </b>
              </p>
            </div>
            <div className="table-responsive">
              <Table
                className="table mb-0 table-striped "
                style={{ minHeight: "50vh", maxHeight: "50vh" }}
              >
                <thead className="thead-dark">
                  <tr>
                    <th className="col-2">Booking</th>
                    <th className="col-3">Source</th>
                    <th className="col-3">Destination</th>
                    <th className="col-3">Driver</th>
                    <th className="col-1">Kms</th>
                  </tr>
                </thead>
                <tbody>
                  {bookings?.map((booking, i) => (
                    <tr>
                      <td>
                        <div>{booking.bookingNo}</div>
                        <div>
                          {" "}
                          <i
                            className="fa px-1 py-1 fa-calendar"
                            aria-hidden="true"
                          ></i>
                          {toDateTime(booking.startTime).toLocaleString()}
                        </div>
                        <div>
                          <i
                            className="fa px-1 py-1 fa-calendar"
                            aria-hidden="true"
                          ></i>
                          {toDateTime(booking.endTime).toLocaleString()}
                        </div>
                      </td>
                      <td>{booking?.source?.addressLine}</td>
                      <td>{booking?.destination?.addressLine}</td>
                      <td>
                        <div>{booking?.driver?.name}</div>
                        <div>
                          {"+" +
                            booking?.driver?.countryCode?.toString() +
                            booking?.driver?.phone}
                        </div>
                      </td>
                      <td>{populatedKms[i]}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
};

export default observer(TimeSheetPopup);
