import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import {
  Checkbox,
  CountrySelect,
  InputField,
  LanguageSelect,
  NameField,
  PhoneNum,
  RoleSelect,
  SaveButton,
  SelectField,
} from "../../../components";
import TimeZoneSelect from "../../../components/Dropdowns/TimeZoneSelect/TimeZoneSelect";
import { DialogContext } from "../../../store/context/DialogContext";
import ProfileService from "../ProfileService";
import { I18nContext } from "../../../store/context/i18nContext";
import FileUpload from "../../../components/DocUpload/FileUpload";

function UserProfile({ data, setData, profile, setProfile, hasErr, fetchData, supervisorId }) {
  const [loading, setLoading] = useState(false);
  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  // const [data, setData] = useState([]);

  // const fetchData = async () => {
  //   if (loading) return;
  //   setLoading(true);
  //   try {
  //     const res = await ProfileService.getProfile();
  //     setProfile(res);
  //     setData(res);
  //     setHasErr(false);
  //   } catch (e) {
  //     showError("error", e);
  //   }
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const fetch = async () => {
    try {
      let tag = await ProfileService.getProfileDetail(supervisorId);
      setData(tag);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(null)
    setLoading(true);
    if (supervisorId) fetch();
    setLoading(false)
  }, [supervisorId]);


  // useEffect(() => {
  //   if (JSON.stringify(profile) !== JSON.stringify(data)) setProfile(data);
  // }, [data]);


  const saveHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await ProfileService.editProfile(data)
      showMessage('success', "Organization Profile Updated");
    } catch (error) {
      showError(error)
    }
  }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     setLoading(true);
  //     console.log("after: ", profile);
  //     const res = await ProfileService.save(profile);
  //     console.log(res);
  //     fetchData();
  //     setLoading(false);
  //   } catch (error) {
  //     showError(error);
  //   }
  // };

  return (
    <>
      <Row>
        <Form className="px-sm-5 px-0" style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}>
          <Row className="mt-3 mb-3  align-items-center ">
            <div className="col-sm-6 col-12 ">
              <NameField
                onNameChange={(v) => setData({ ...data, name: v })}
                onSalutationChange={(v) => setData({ ...data, salutation: v })}
                name={data?.name}
                salutation={data?.salutation}
                label="Name"
                placeholder={t("Name")}
              />
            </div>
            {supervisorId &&
              <div className="d-flex align-items-center  col-sm-6">
                <div>Profile Picture</div>
                <FileUpload
                  type="image"
                  className="d-flex justify-content-center col-sm-6 mt-4 ms-5  "
                  value={data?.pic}
                  id={data?._id}
                  module={"supervisor"}
                  field={"pic"}
                  onUpload={() => {
                    fetch()
                  }}
                  onDelete={() => {
                    fetch()
                  }}
                />
              </div>
            }
            <InputField
              placeholder={t("Email")}
              value={data?.email}
              required={true}
              label={"Email"}
              style={{ minWidth: '140px' }}
              onChange={(v) => setData({ ...data, email: v })}
              error={data?.email?.length === 0 ? t("Please enter email") : ""}
              showErr={hasErr}
              type="email"
              className="col-sm-6 "
            />
            <PhoneNum
              isDisabled
              placeholder={t("Phone No ")}
              value={{
                countryCode: data?.phoneNo?.countryCode,
                phone: data?.phoneNo?.phone,
              }}
              label={"Phone"}
              className="col-sm-6"
              // onChange={(v) => {
              //   setData({
              //     ...data,
              //     phoneNo: {
              //       ...data?.phoneNo,
              //       countryCode: v?.countryCode,
              //       phone: v?.phone,
              //     }
              //   })
              // }}
              type="text"
            />
            <RoleSelect
              placeholder={t("Select Roles ")}
              value={data?.supervisorExtn?.roles}
              label={t("Roles")}
              multi={true}
              onChange={(v) => {
                setData({
                  ...data,
                  supervisorExtn: {
                    ...data?.supervisorExtn,
                    roles: v
                  }
                });
              }}

              type="text"
              className="col-sm-6"
            />

            <InputField
              placeholder={t("Department")}
              style={{ minWidth: '120px' }}

              value={data?.supervisorExtn?.department}
              required={true}
              label={"Department"}
              onChange={(v) => setData({ ...data, supervisorExtn: { ...data?.supervisorExtn, department: v } })}
              error={data?.supervisorExtn?.department?.length === 0 ? t("Please enter department") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-6"
            />
            <InputField
              placeholder={t("Designation")}
              style={{ minWidth: '120px' }}

              value={data?.supervisorExtn?.designation}
              required={true}
              label={"Designation"}
              onChange={(v) => setData({ ...data, supervisorExtn: { ...data?.supervisorExtn, designation: v } })}
              error={data?.supervisorExtn?.designation?.length === 0 ? t("Please enter designation") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-6"
            />
            <InputField
              placeholder={t("Address")}
              style={{ minWidth: '120px' }}

              value={data?.address0x}
              label={"Address"}
              onChange={(v) => setData({ ...data, address0x: v })}
              error={data?.address0x?.length === 0 ? t("Please enter address0x") : ""}
              showErr={hasErr}
              type="email"
              className="col-sm-6"
            />
            <InputField
              placeholder={t("API key")}
              style={{ minWidth: '120px' }}

              value={data?.supervisorExtn?.key}
              label={"API Key"}
              onChange={(v) => setData({ ...data, supervisorExtn: { ...data?.supervisorExtn, key: v } })}
              error={data?.supervisorExtn?.key?.length === 0 ? t("Please enter Key") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-6"
            />
            <InputField
              placeholder={t("API secret")}
              style={{ minWidth: '120px' }}

              value={data?.supervisorExtn?.secret}
              label={"API secret"}
              onChange={(v) => setData({ ...data, supervisorExtn: { ...data?.supervisorExtn, secret: v } })}
              error={data?.supervisorExtn?.secret?.length === 0 ? t("Please enter secret") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-6"
            />
            {supervisorId ?
              <>
                <Checkbox
                  label={t("ViewOwnEntityOnly")}
                  className="col-sm-6"

                  checked={data?.viewOwnEntityOnly}
                  onClick={() =>
                    setData({ ...data, viewOwnEntityOnly: !data?.viewOwnEntityOnly })
                  }
                />
                <Checkbox
                  label={t("IsApi")}
                  checked={data?.supervisorExtn?.isAPI}
                  onClick={() =>
                    setData({ ...data, supervisorExtn: { ...data?.supervisorExtn, isAPI: !data.supervisorExtn?.isAPI } })
                  }
                  className="col-sm-6"
                />
              </>
              :
              <>
                <Checkbox
                  label={t("ViewOwnEntityOnly")}
                  className="col-sm-3"
                  checked={data?.viewOwnEntityOnly}
                  onClick={() =>
                    setData({ ...data, viewOwnEntityOnly: !data?.viewOwnEntityOnly })
                  }
                />
                <Checkbox
                  label={t("IsApi")}
                  checked={data?.supervisorExtn?.isAPI}
                  onClick={() =>
                    setData({ ...data, supervisorExtn: { ...data?.supervisorExtn, isAPI: !data.supervisorExtn?.isAPI } })
                  }
                  className="col-sm-3"
                />
              </>
            }
          </Row>
        </Form>
        {/* <Col
            className="col-12 p-2 ps-4"
            style={{
              backgroundColor: "#EAEAEA",
              fontSize: "15px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <header className="d-flex justify-content-between align-items-center">
              <m>{`User Profile`}</m>
            </header>
          </Col>

          <Col className="col-12 mt-3">
            <InputField
              placeholder="Name"
              label={"Name"}

              type="text"
              value={data?.name ? data?.name : ""}
              onChange={(v) => {
                setData({ ...data, name: v });
              }}
            />
          </Col>
          <Col className="col-12 mt-3">
            <InputField
              placeholder="Phone"
              label={"Phone"}
              showClear={false}
              type="number"
              value={data?.phone}
              isDisabled={true}
            />
          </Col>
          <Col className="col-12 mt-3">
            <InputField
              placeholder="Email"
              label={"Email"}
              showClear={false}
              type="text"
              value={data?.email}
              isDisabled={true}
            />
          </Col>
          <Col className="col-12 mt-3">
            <SelectField
              label={"Gender"}
              data={[
                { label: "Male", value: "1" },
                { label: "Female", value: "0" },
                { label: "Other", value: "2" },
              ]}
              value={data?.gender}
              onChange={(v) => setData({ ...data, gender: v })}
            />
          </Col>
          <Col className="col-12 mt-3">
            <LanguageSelect
              value={data?.lang}
              onChange={(v) => setData({ ...data, lang: v })}
            />
          </Col>
          <Col className="col-12 mt-3">
            <TimeZoneSelect
              value={data?.timeZone}
              onChange={(v) => {
                setData({ ...data, timeZone: v.value });
              }}
            />
          </Col> */}

        {/* <Col className="col-12 mt-3">
            <InputField
              placeholder="Name"
              label={"Name"}

              type="text"
              value={data?.name ? data?.name : ""}
              onChange={(v) => {
                setData({ ...data, name: v });
              }}
            />
          </Col> */}
        {/* <Col className="col-12 mt-3">
            <InputField
              placeholder="Phone"
              label={"Phone"}
              showClear={false}
              type="number"
              value={data?.phone}
              isDisabled={true}
            />
          </Col> */}
        {/* <Col className="col-12 mt-3">
            <InputField
              placeholder="Email"
              label={"Email"}
              showClear={false}
              type="text"
              value={data?.email}
              isDisabled={true}
            />
          </Col> */}
        {/* <Col className="col-12 mt-3">
            <SelectField
              label={"Gender"}
              data={[
                { label: "Male", value: "1" },
                { label: "Female", value: "0" },
                { label: "Other", value: "2" },
              ]}
              value={data?.gender}
              onChange={(v) => setData({ ...data, gender: v })}
            />
          </Col> */}
        {/* <Col className="col-12 mt-3">
            <LanguageSelect
              value={data?.lang}
              onChange={(v) => setData({ ...data, lang: v })}
            />
          </Col> */}
        {/* <Col className="col-12 mt-3">
            <TimeZoneSelect
              value={data?.timeZone}
              onChange={(v) => {
                setData({ ...data, timeZone: v.value });
              }}
            />
          </Col> */}
        <Col>
          <SaveButton onClick={(e) => saveHandler(e)} />
        </Col>
      </Row>
    </>
  );
}

export default UserProfile;
