import React, { useState } from "react";
import MAPComponent from "../../components/Map/Map";

const DemoMap = () => {
  const [center, setCenter] = useState({})
  const markers = [
    {
      coordinate: { lat: 28.63064598177579, lng: 77.22539789421589 },
      popupComponent: <div>Delhi Marker 1 Popup Content</div>,
    },
    {
      coordinate: { lat: 28.7041, lng: 77.1025 },
      popupComponent: <div>Nearby Delhi Marker 1 Popup Content</div>,
    },
    {
      coordinate: { lat: 28.6280, lng: 77.2180 },
      popupComponent: <div>Nearby Delhi Marker 2 Popup Content</div>,
    },
    {
      coordinate: { lat: 28.7041, lng: 77.0942 },
      popupComponent: <div>Nearby Delhi Marker 3 Popup Content</div>,
    },

  ];

  const polyLine = {
    src: { lat: 28.7041, lng: 77.1025 },
    dest: { lat: 28.6041, lng: 77.0942 },
  };

  const getGeocode = (address) => {
    console.log("Geocoding address:", address);
  };

  const reverseGeocode = (coordinate) => {
    console.log("Reverse geocoding coordinate:", coordinate);
  };

  const handleMarkerDrag = (coordinates) => {
    setCenter(coordinates)
    console.log("Dragged coordinates:", coordinates);
  };

  return (
    <div className="App">
      <div className="page-content">

        <h1>Sample Map Component {JSON.stringify(center)}</h1>
        <MAPComponent
          search
          markers={markers}
          polyLine={polyLine}
          clustering={true}
          addressLine={null}
          getGeocode={getGeocode}
          mapContainerStyle={{ height: "70vh" }}
          makePolylineBetweenMarkers={
            {
              source: { lat: 28.7041, lng: 77.1025 },
              destination: { lat: 28.6280, lng: 77.2180 }
            }
          }
          reverseGeocode={reverseGeocode}
          showMarker={true}
          onDrag={handleMarkerDrag}

        />
      </div>
    </div>
  );
};

export default DemoMap;
