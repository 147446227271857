import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./ClientGroupsConstant";
import { doGET, doPOST } from "../../util/HttpUtil";

class Service {

    records = [];
    totalRecords = 0;
    places = [];
    page = undefined;
    rows = 20;

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async function (filterUrl) {
        const response = await doGET(ENDPOINTS.grid(this.page, this.rows, filterUrl))
        if (response.status === 200) {
            this.records = response.data.rows;
            this.totalRecords = response.data.records;
        }
    };

    get = async function (id) {
        const response = await doGET(ENDPOINTS.get(id))
        if (response.status === 200) {
            return response.data;
        }
    };

    save = async function (data) {
        const response = await doPOST(ENDPOINTS.saveNew, data)
        this.fetch();
        return response?.data?._id;
    };

    delete = async function (id) {
        const response = await doGET(ENDPOINTS.delete(id))
        if (response.status === 200) {
            this.fetch();
        }
    };

    edit = async function (data, id) {
        const response = await doPOST(ENDPOINTS?.edit(id), data);
        await this.fetch();
        return response;
    };

    onPaginationChange = async (page, rows) => {
        this.page = page;
        this.rows = rows;
        this.fetch();
    }

}

const ClientGroupService = new Service();
export default ClientGroupService;
