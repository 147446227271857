import React, { useCallback, useContext, useEffect, useState } from "react";
import { toJS } from "mobx";
import { I18nContext } from "../../../store/context/i18nContext";
import Image from "../../Image/Image";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import VehicleTypeService from "./VehicleTypeSelectService";
import { observer } from "mobx-react-lite";
import useDebounce from "../../../util/Debounce";

const VehicleType = (props) => {
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const { t } = useContext(I18nContext);

  const updateVehicleTypes = async () => {
    try {
      if (toJS(VehicleTypeService?.vehicleTypes)?.length > 0) {
        setVehicleTypes(
          toJS(VehicleTypeService.vehicleTypes).map((c) => {
            return {
              ...c,
              value: c?._id,
              label: (
                <div className="d-flex justify-content-start">
                  {props.withImage && (

                    <div
                      style={{
                        display: "inline",
                        maxHeight: "15px",
                        maxWidth: "30px",
                      }}
                    >
                      <Image
                        src={c?.pic}
                        alt="vehicle-type"
                        style={{
                          display: "inline",
                          maxHeight: "15px",
                          maxWidth: "30px",
                        }}
                      />
                    </div>
                  )}
                  <div className="ps-2" >
                    <div>
                      <div style={{ verticalAlign: 'top' }}>{c?.name}</div>
                    </div>
                    {c?.makeModels && (
                      <div style={{ fontSize: '11px', verticalAlign: 'top' }}>
                        <div >{c?.makeModels}</div>
                      </div>
                    )}
                  </div>
                </div>
              ),
            };
          })
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    const vehicleTypeData = toJS(VehicleTypeService?.vehicleTypes);
    if (vehicleTypeData?.length > 0) {
      updateVehicleTypes();
    }
  }, [VehicleTypeService?.vehicleTypes]);

  const fetchAndUpdateVehiclesTypes = async (id) => {
    try {
      const vehicleType = vehicleTypes.find(vehicle => vehicle._id === id)
      if (!vehicleType) {
        const vehicleType = await VehicleTypeService.get(id);
        VehicleTypeService.pushNewData(vehicleType);
        updateVehicleTypes();
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (props?.value && vehicleTypes.length) {
      fetchAndUpdateVehiclesTypes(props.value)
    }
  }, [vehicleTypes, props?.value]);

  // useEffect(() => {
  //   if(toJS(VehicleTypeService?.vehicleTypes)?.length > 0){
  //     updateVehicleTypes()
  //   }
  //     }, [])

  const fetch = async () => {
    await VehicleTypeService.fetch();
  }


  useEffect(() => {
    if (vehicleTypes?.length === 0) fetch();
  }, [vehicleTypes]);

  const filterOption = useCallback((option, inputValue) => {
    return (
      !inputValue ||
      option.data?.name?.toLowerCase().includes(inputValue.toLowerCase())
    );
  }, []);

  const fetchMoreData = () => {
    const page = VehicleTypeService.page + 1;
    const rowsPerPage = VehicleTypeService.rowsPerPage;
    const total = VehicleTypeService.total;
    if (page > 0 && page <= (total % rowsPerPage === 0 ? (total / rowsPerPage) : (total / rowsPerPage) + 1)) {
      VehicleTypeService.onPaginationChange(page, rowsPerPage);
    }
  }

  const fetchDataOnSearch = useDebounce((value) => {
    const filterUrl = `name=${value}`;
    VehicleTypeService.fetch(filterUrl);
  }, [500]
  );

  return (
    <React.Fragment>
      {vehicleTypes.length > 0 ? (
        props?.asLabel ? (
          <div>
            {vehicleTypes.find(
              (type) => type?.value === props?.value
            )?.name ??

              <div style={{ color: "grey" }}>--</div>
            }
          </div>
        ) : !props?.multi ? (
          <SelectField
            outline={props?.outline}
            filterOption={filterOption}
            plain={props?.plain ? props?.plain : null}
            isDisabled={props?.isDisabled}
            className={props?.className}
            onChange={props?.onChange}
            data={vehicleTypes}
            asLabel={props.asLabel}

            required={props.required}
            value={
              vehicleTypes.find(
                (x) => x?.value === props?.value || x?.label === props?.value
              )?.value
            }
            label={props?.label ? props?.label : t("Vehicle Type")}
            error={
              props?.value
                ? "Please Select " +
                (props?.label ? props?.label : t("Vehicle Type"))
                : ""
            }
            showErr={props?.showErr}
            inline={props?.inline}
            borderNone={props?.borderNone}
            RightComponent={props?.RightComponent}
            onClickRight={props?.onClickRight}
            onInputChange={fetchDataOnSearch}
            onMenuScrollToBottom={fetchMoreData}
            {...props}
          />
        ) : (
          <SelectFieldMultiple
            outline={props?.outline}
            isDisabled={props?.isDisabled}
            style={props?.style}
            className={props?.className}
            onChange={(v) => props?.onChange(v.map((c) => c?.value))}
            data={vehicleTypes}
            value={
              props?.value
                ? vehicleTypes.filter((c) =>
                  props?.value?.includes(c?.value)
                )
                : ""
            }
            error={
              !props?.value
                ? "Please Select " +
                (props?.label ? props?.label : "Vehicle Types")
                : ""
            }
            required={props?.required}
            label={"Select Vehicle Types"}
            showErr={props?.showErr}
            onInputChange={fetchDataOnSearch}
            onMenuScrollToBottom={fetchMoreData}
          />
        )
      ) : (

        <SelectField
          outline={props?.outline}
          onChange={props?.onChange}
          data={vehicleTypes}
          RightComponent={props?.RightComponent}
          onClickRight={props?.onClickRight}
          value={props?.value}
          className={props?.className}
          label={props?.label ? props?.label : "Vehicle Type"}
          isDisabled={true}
          asLabel={props.asLabel}

          error={
            props?.value
              ? "Please Select " +
              (props?.label ? props?.label : "Vehicle Type")
              : ""
          }
          showErr={props?.showErr}
          inline={props?.inline}
          borderNone={props?.borderNone}
          onInputChange={fetchDataOnSearch}
          onMenuScrollToBottom={fetchMoreData}
        />
      )}
    </React.Fragment>
  );
}


export default observer(VehicleType)