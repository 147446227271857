import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditLanguages from "./EditLanguage";
import { STRUCTURE } from "./LanguageConstant";
import { GridColumns } from "./LanguageGridColumns";
import LanguageService from "./LanguageService";



const Languages = ({ insidePane }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const { userContent } = useContext(UserContext);

  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/language/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await LanguageService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
  }, [])

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.name) {
      setHasErr(true)
      showError(t("Please enter all fields"));
      return;
    }
    setLoading(true);
    try {
      if (editID) {
        await LanguageService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Record updated successfully."));
      } else {
        const lng = await LanguageService?.save(detailData);
        // if (lng) setEditID(lng)
        setLoading(false);
        showMessage(t("Record saved successfully."));
        if (!insidePane) navigate(`/language/edit/${lng}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setHasErr(false)
    setLoading(false);
  };


  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await LanguageService.delete(id);
        showMessage("Deleted", "Deleted");
        navigate('/language')
        setShowDetailPage(false);

      } catch (e) {
        showError(e);
      }
    }
  };


  return (
    <React.Fragment>

      {
        loading ?
          <div className="d-flex mt-5 justify-content-center align-items-center  flex-1">
            <ThreeBounce size={50} color="pink" />
          </div>
          :
          <>
            <Layout
              hideAdd={!userContent?.rights?.includes(21)}
              medium
              showDetailPage={showDetailPage}
              backDetailPage={() => {
                setShowDetailPage(false);
                if (!insidePane) navigate("/language");
                setEditID(null);


              }}
               
              title={t("Languages")}
              filterValues={filterObject}
              filterStructure={STRUCTURE}
              onApplyFilter={fetchData}
              onAddClick={() => {
                if (!insidePane) navigate(`/language/create`);
                setShowDetailPage(true);
                setEditID(null);

              }}
              insidePane={insidePane}
              page={LanguageService.page}
              rows={LanguageService.rows}
              total={LanguageService.total}
              fetch={LanguageService.fetch}
            >
              <Layout.ActionMenu>
                <div className="layout-action-menu">
                  <DropdownMenu>
                    <>
                      {userContent?.rights?.includes(23) ?
                      <>
                        <DropdownItem>{t("Bulk Delete")}</DropdownItem>
                        <div class="dropdown-divider"></div>
                        </>
                        : null}
                      <DropdownItem>{t("Upload")}</DropdownItem>
                      <div class="dropdown-divider"></div>
                      <DropdownItem> Download </DropdownItem>
                    </>

                  </DropdownMenu>

                </div>
              </Layout.ActionMenu>
              <Layout.Table>
                <DataGrid
              gridLoading={loading}
                  data={LanguageService.records}
                  total={LanguageService.total}
                  uiPreference="language.grid"
                  headers={GridColumns}
                  selectedIDs={selectedIDs}
                  onSelectChange={setSelectedIDs}
                  page={LanguageService.page}
                  rowsPerPage={LanguageService.rowsPerPage}
                  onPaginationChange={LanguageService.onPaginationChange}
                  renderLastCol={(language) => {
                    return (
                      <>
                        <EditLink
                          onClick={() => {
                            if (!insidePane)
                              navigate(`/language/edit/${language?._id}`);
                            setShowDetailPage(true);
                            setEditID(language?._id);

                          }}
                        />
                        {userContent?.rights?.includes(23) ?
                          <DeleteLink
                            onClick={(event) => onDelete(event, language?._id)}
                          />
                          : null}

                      </>
                    );
                  }}
                />
              </Layout.Table>

              <Layout.DetailPageTitle>
                {detailData?._id ? t("Edit Language") : t("Add Language")}
              </Layout.DetailPageTitle>

              <Layout.DetailPageBody>
                <EditLanguages
                  editId={editID}
                  onChange={(v) => {
                    setDetailData(v)
                  }}
                  hasErr={hasErr}
                />
              </Layout.DetailPageBody>

              <Layout.DetailPageFooter>
                {userContent?.rights?.includes(23) && detailData?._id ?
                  <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />
                  : null}
                {userContent?.rights?.includes(22) ?
                  <SaveButton onClick={onSave} loading={loading} />
                  : null}

              </Layout.DetailPageFooter>
            </Layout>
          </>
      }
    </React.Fragment>
  );
};
export default observer(Languages);




