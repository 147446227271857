import { makeAutoObservable } from "mobx";
import { doGET, doPOST } from "../../util/HttpUtil";
import { ENDPOINTS } from "./SettingsConstant";

class Service {
    constructor() {
        makeAutoObservable(this);
    }

    getConfig = async function () {
        const resposnse = await doGET(ENDPOINTS.getConfig());
        if (resposnse.status === 200) {
            return resposnse.data;
        }
    };

    getTags = async function () {
        const resposnse = await doGET(ENDPOINTS.getTags);
        if (resposnse.status === 200) {
            return resposnse.data;
        }
    };


    getConfigById = async function (id) {
        const resposnse = await doGET(ENDPOINTS.getConfigById(id));
        if (resposnse.status === 200) {
            return resposnse.data;
        }
    };

    saveRoute = async function (data) {
        const response = await doPOST(ENDPOINTS?.saveRoute, data);
        if (response.data === 200)
            return response?.data;
    };

    saveTariff = async function (data) {
        const response = await doPOST(ENDPOINTS?.saveTariff, data);
        if (response.data === 200)
            return response?.data;
    };

    saveConfig = async function (data) {
        const response = await doPOST(ENDPOINTS.saveConfig(), data);
        if (response.data === 200)
            return response?.data;
    };
    testSMTP = async function (data) {
        const response = await doPOST(ENDPOINTS.testSMTP(), data);
        if (response.data === 200)
            return response?.data;
    };
    saveSms = async function (data) {
        const response = await doPOST(ENDPOINTS.saveSms(), data);
        if (response.data === 200)
            return response?.data;
    };
    saveSMTP = async function (data) {
        const response = await doPOST(ENDPOINTS.saveSMTP(), data);
        if (response.data === 200)
            return response?.data;
    };
    saveAura = async function (data) {
        const response = await doPOST(ENDPOINTS.saveAura(), data);
        if (response.data === 200)
            return response?.data;
    };
    saveVehicleType = async function (data) {
        const response = await doPOST(ENDPOINTS.saveVehicleType(), data);
        if (response.data === 200)
            return response?.data;
    };
    getVehicleMap = async () => {
        if (!this.vehicleMap)
            this.vehicleMap = (await doGET(ENDPOINTS.vehicleMap))?.data;
        var vehicleList = [];
        for (var i in this.vehicleMap) {
            vehicleList.push({ label: i, value: i });
        }
        return vehicleList;
    };
}

const SettingsService = new Service();
export default SettingsService;
