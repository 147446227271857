import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET, doPOST } from "../../../util/HttpUtil";

class ServicingEntity {
  SelectEntity = [];
  constructor() {
    makeAutoObservable(this);
  }
  fetch = async function (filterData) {
    if (this.SelectEntity.length > 0) return;
    try {
      const response = await doGET(ENDPOINTS.getAllSelectEntity);
      if (response.status === 200) {
        console.log(response?.data);
        this.SelectEntity = response.data.map((v) => {
          return { label: v?.name, value: v?._id };
        });
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };
}

const ServicingEntityServic = new ServicingEntity();
export default ServicingEntityServic;
