export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/bid/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/bid/${id}/detail`,
  save: `/api/bid/create`,
  delete: (id) => `/api/bid/${id}/delete`,
  bulkDelete: (id) => `/api/bid/delete`,
  update: `/api/bid/update`,
};

export const SELECTSTRUCTURE = {
  
  status: [
    { label: "Created", value: 1},
    { label: "Published", value: 2 },
    { label: "Closed", value: 3 },
    { label: "Expired", value: 4 },

  ],
  travelType: [
    { label: "LOCAL", value: 1},
    { label: "OUTSTATION", value: 2 },
    { label: "PACKAGE", value: 3 },
    { label: "SELF_DRIVE", value: 4 },

  ],
  duration: [
    { label: "HalfDay", value: 1},
    { label: "FullDay", value: 2 },
    
  ],
}

export const STRUCTURE = [
  {
    label: "Bid Code",
    filterLabel: "Bid Code",
    filterName: "code",
    type: "text",
  },
  {
    label: "Bid Name",
    filterLabel: "Bid Name",
    filterName: "address.name",
    type: "text",
  },
  {
      label: "Has Self Drive ",
      filterLabel: "Has Self Drive",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.hasSelfDrive ? 'true' : 'false'}
        </div>;
      }, 
      filterName: "hasSelfDrive",
      type: "text",
    },
];
