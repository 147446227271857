import { makeAutoObservable } from "mobx";
import { doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { ENDPOINTS } from "./ProfileConstant";

class Service {
    constructor() {
        makeAutoObservable(this);
    }
    // getProfile = async function () {
    //     const resposnse = await doGET(ENDPOINTS.getProfile());
    //     if (resposnse.status === 200) {
    //         return resposnse.data;
    //     }
    // };
    getCorpProfile = async function () {
        const resposnse = await doGET(ENDPOINTS.corpProfile);
        if (resposnse.status === 200) {
            return resposnse.data;
        }
    };

    detailcontact = async (id) => {
        const response = await doGET(ENDPOINTS.detailcontact(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    changePass = async function (data) {
        const resposnse = await doPOST(ENDPOINTS.changePass, data);
        if (resposnse.status === 200) {
            return resposnse.data;
        }
    };

    sendCode = async function (data) {
        const resposnse = await doPOST(ENDPOINTS.sendCode, data);
        if (resposnse.status === 200) {
            return resposnse.data;
        }
    };

    changePhone = async function (data) {
        const resposnse = await doPOST(ENDPOINTS.changePhone, data);
        if (resposnse.status === 200) {
            return resposnse.data;
        }
    };

    sendECode = async function (data) {
        const resposnse = await doPOST(ENDPOINTS.sendECode, data);
        if (resposnse.status === 200) {
            return resposnse.data;
        }
    };

    changeEmail = async function (data) {
        const resposnse = await doPOST(ENDPOINTS.changeEmail, data);
        if (resposnse.status === 200) {
            return resposnse.data;
        }
    };

    saveCorpProfile = async function (data) {
        const resposnse = await doPOST(ENDPOINTS.saveCorpProfile, data);
        if (resposnse.status === 200) {
            return resposnse.data;
        }
    };

    getCorporateDetail = async function (id) {
        const response = await doGET(ENDPOINTS.myCorporateDetail());
        if (response.status === 200) {
            return response.data;
        }
    }

    editCorporate = async function (data, id) {
        await doPUT(ENDPOINTS?.corporateUpdate, data);
    };

    getProfileDetail = async function (id) {
        const response = await doGET(ENDPOINTS.profileDetail(id));
        if (response.status === 200) {
            return response.data;
        }
    }
    editProfile = async function (data, id) {
        await doPUT(ENDPOINTS?.profileUpdate, data);
    };

    saveContact = async function (data) {
        const resposnse = await doPOST(ENDPOINTS.saveContact, data);
        if (resposnse.status === 200) {
            return resposnse.data;
        }
    };

    getConfig = async function (id) {
        const resposnse = await doGET(ENDPOINTS.config(id));
        if (resposnse.status === 200) {
            return resposnse.data;
        }
    };

    getLogs = async function (id, conf) {
        const resposnse = await doGET(ENDPOINTS.logsGrid(id, conf));
        if (resposnse.status === 200) {
            return resposnse.data;
            // return resposnse.data.rows;
        }
    };
}

const ProfileService = new Service();
export default ProfileService;