import React, { useState } from "react";
import { InputField, timeStamptoyyyy_mm_dd } from "../../components";

const yyyy_mm_ddToyyyymmdd = (date) => {
  if (!date) return null;
  const parts = date?.split("-");
  if (parseInt(parts[0]) < 1970) return;
  return parts[0] + parts[1]?.padStart(2, "0") + parts[2]?.padStart(2, "0");
};
const yyyymmddToyyyy_mm_dd = (date) => {
  if (!date) {
    return null;
  }
  return date?.slice(0, 4) + "-" + date?.slice(4, 6) + "-" + date?.slice(6, 8);
};

export default function DateSelect({
  asLabel,
  getTimestamp,
  onChange = () => { },
  value,
  className,
  required,
  label,
  plain,
  isDisabled,
  showErr,
  inline,
  outline,
  style,
  borderNone,
  RightComponent,
  autoFocus,
  noLabel,
  onClickRight = () => { },
}) {
  const changeHandler = (v) => {
    if (getTimestamp) {
      const ts = new Date(v)?.getTime() / 1000;
      onChange(ts);
    } else {
      const val = yyyy_mm_ddToyyyymmdd(v);
      if (val) onChange(parseInt(val));
      else onChange(parseInt(""));
    }
  };
  if (asLabel) {
    const inputDate = value !== undefined ? value.toString() : "";
    const year = inputDate.substring(0, 4);
    const month = inputDate.substring(4, 6);
    const day = inputDate.substring(6, 8);
    const formattedDate = new Date(year, month - 1, day);
    const formattedDateString = formattedDate.toLocaleDateString("en-GB");

    return (
      <div className="mt-3 pt-1">
        <span style={{ marginRight: "60px", fontWeight: "bold" }}>{label}</span>
        {value ? formattedDateString : <span style={{ color: "#999" }}> Invalid Date</span>}
      </div>
    );
  }
  return (
    <React.Fragment>
      <InputField
        className={className}
        style={style}
        value={
          value
            ? getTimestamp
              ? timeStamptoyyyy_mm_dd(value)
              : yyyymmddToyyyy_mm_dd(value?.toString())
            : ""
        }
        required={required}
        label={label}
        isDisabled={isDisabled}
        onChange={(v) => changeHandler(v)}
        error={value?.length === 0 ? "Please Choose a Valid Date" : ""}
        showErr={showErr}
        plain={plain}
        type="date"
        inline={inline}
        outline={outline}
        borderNone={borderNone}
        RightComponent={RightComponent}
        onClickRight={onClickRight}
        autoFocus = {autoFocus}
        noLabel={noLabel}

      />
    </React.Fragment>
  );
}
