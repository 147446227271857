import React, { useCallback, useEffect, useState } from "react";
import { Table } from "reactstrap";
import Zones from "./store";
import Filter from "./Filter";
import { ModalPopup } from "./Modal";
import { toJS } from "mobx";

export default function ClientPopup(props) {
  const [structure, setStructure] = useState([]);
  const [clientsToSelect, setClientsToSelect] = useState([]);
  const [newClients, setNewClients] = useState([]);


  const fetch = useCallback(async () => {
    await Zones.getAllCLients();
    await Zones.getAllZonesList();
    setStructure([
      {
        label: "ID",
        description: "description...",
        filterLabel: "Unique ID",
        filterName: "unique_id",
        type: "text",
      },
      {
        label: "Name",
        description: "description...",
        filterLabel: "Client Name",
        filterName: "name",
        type: "text",
      },
      {
        label: "City",
        description: "description...",
        filterLabel: "City",
        filterName: "city",
        type: "select",
        isDisabled: (values) => !values["country"],
        data: (v) => (v.country ? toJS(Zones.cities[v.country]) : []),
        isDataFunc: true,
      },
      {
        label: "Country",
        description: "description...",
        filterLabel: "Country",
        filterName: "country",
        type: "select",
        data: props?.COUNTRIES,
        onChange: async (v, setFilterValuesFunc) => {
          await Zones.getCityByCountry(v);
          setFilterValuesFunc("city", "");
          setFilterValuesFunc("country", v);
        },
        onClear: (setFilterValuesFunc) => {
          // setFilterValuesFunc("city", "");
          setFilterValuesFunc("city", "");
        },
        isOnClearFunc: true,
      },
      {
        label: "Zone",
        description: "description...",
        filterLabel: "Zone",
        filterName: "zone",
        type: "select",
        data: toJS(Zones.allZonesList).map((z) => {
          return { label: z.name, value: z._id };
        }),
      },
      {
        label: "DOB",
        description: "description...",
        filterLabel: "DOB",
        filterName: "dob",
        type: "date",
        format: "integer/timestamp",
      },

      {
        label: "Client",
        filterName: "corp_id",
        description: "description...",
        filterLabel: "Corporation ID",
        multiSelect: true,
        data: props?.COUNTRIES,
        type: "selectMultiple",
      },
      {
        label: "CheckBox",
        filterName: "ckvalue",
        value: "Value",
        description: "description...",
        filterLabel: "Test Value",
        type: "checkBox",
      },
    ]);
    setClientsToSelect(Zones.clients);
  }, [props?.COUNTRIES]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    setNewClients(props?.clientsList);
  }, [props?.clientsList]);

  const applyFunc = async (values) => {
    const cl = await Zones.getFilteredClients(
      values.unique_id,
      values.name,
      values.zone,
      values.country,
      values.city
    );
    setClientsToSelect(cl);
  };

  return (
    <React.Fragment>
      <ModalPopup
        isOpen={props?.modal}
        setIsOpen={props?.setModal}
        title={"Clients"}
        saveChanges={() => props?.setClientsList(newClients)}
        body={
          <div className="table-responsive">
            {structure.length > 0 && (
              <Filter
                className="mb-5"
                structure={structure}
                applyFunc={applyFunc}
              />
            )}
            <Table
              className="table mb-0 table-striped "
              style={{ minHeight: "50vh", maxHeight: "50vh" }}
            >
              <thead className="thead-dark">
                <tr>
                  <th className="col-1">Select</th>
                  <th className="col-4">Name</th>
                  <th className="col-3">Unique Id</th>
                  <th className="col-3">Email</th>
                </tr>
              </thead>
              <tbody>
                {clientsToSelect?.map((client) => (
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={client?._id}
                        onChange={() => {
                          if (newClients.some((c) => c._id === client._id))
                            setNewClients(
                              newClients.filter((c) => c._id !== client._id)
                            );
                          else
                            setNewClients([
                              ...newClients,
                              {
                                _id: client._id,
                                name: client?.client?.name,
                              },
                            ]);
                        }}
                        checked={newClients.some((c) => c._id === client._id)}
                      />
                    </td>
                    <td>{client?.client?.name}</td>
                    <td>{client?.client?.unique_id}</td>
                    <td>{client?.client?.email}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        }
      />
    </React.Fragment>
  );
}
