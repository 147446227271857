import { makeAutoObservable } from "mobx"; 
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";


const ENDPOINTS = {
  grid: (module = 'views',filterUrl="") => {
    let url=`/api/space/grid?rows=-1&module=${module}&type=1`
    if(filterUrl)  url+=`&${filterUrl}` 
    return url;
  },
  get: (id) => `/api/space/${id}/detail`,
  save: `/api/space/create`,
  delete: (id) => `/api/space/${id}/delete`,
  update: `/api/space/update`,
};


class Service {
  records = [];
  total = -1;
  version = 0;
  selectedFolder = {};
  filterUrl=""

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (module="views",filterUrl) => {
    if(filterUrl){
        this.filterUrl=filterUrl
    }
    const response = await doGET(ENDPOINTS.grid(module,filterUrl));
    if (response?.status === 200 && Array.isArray(response.data.rows)) {

      this.records = response.data.rows;
      return this.records
    }
  };

 
  edit = async function (data) {
    const response = await doPUT(ENDPOINTS?.update, data);
    if (response.status === 200) {
      this.version += 1;
      this.fetch();
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data, fullresponse) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    if (response.status === 200) {
      this.fetch();
      return fullresponse ? response?.data : response?.data?._id;
    }
  };


  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };


}

const viewService = new Service();
export default viewService;
