export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/rate-terms/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`;
    return url;
  },
  get: (id) => `/api/rate-terms/${id}/detail`,
  save: `/api/rate-terms/create`,
  delete: (id) => `/api/rate-terms/${id}/delete`,
  bulkDelete: (id) => `/api/rate-terms/delete`,
  update: `/api/rate-terms/update`,
  getAllTerms: `/api/rate-terms/grid`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Term Type",
    filterLabel: "Term Type",
    filterName: "rate-termsType",
    type: "text",
  },
];


export const defaultPaymentTerms = [
  {
    label: "At time of booking",
    isAbs: true,
    value: 0,
    paymentEvent: -1
  },
  {
    label: "Before Journey",
    isAbs: true,
    value: 0,
    paymentEvent: 0
  },
  {
    label: "Days before Journey",
    isAbs: true,
    value: 0,
    paymentEvent: 99
  }
]

const generateDaysBeforeJoiningData = () => {
  const data = []
  for (let i = 1; i <= 98; i++) {
    data.push({
      label: i,
      value: i
    });
  }
  return data;

}


export const daysBeforeJoiningData = generateDaysBeforeJoiningData()

export const daysBeforeJoiningDataCancellationData = [{
  label: "1 Day",
  value: 1
},
{
  label: "2 Day",
  value: 2
},
{
  label: "3 Day",
  value: 3
},
{
  label: "A week",
  value: 7
},
{
  label: "15 days",
  value: 15
},
{
  label: "30 Days",
  value: 30
}]
