import React, { useState, useEffect, useContext } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
  MarkerClusterer,
  Polyline,
} from "@react-google-maps/api";
import { AddressField, InputField, decodePolyline } from "..";
import { doGET, doPOST } from "../../util/HttpUtil";
import { FaMapPin } from "react-icons/fa";
import { DialogContext } from "../../store/context/DialogContext";
import { observer } from "mobx-react-lite";


const libraries = ["places"];

const MAPComponent = ({
  search,
  markers,
  polyLine,
  polyLinePath = "", 
  clustering,
  addressLine,
  getGeocode = () => { },
  reverseGeocode = () => { },
  onDrag = () => { },
  mapContainerStyle,
  centerLatLng = {},
  showMarker = false,
  selectedMapMarker
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCcIDq5TCEwR8sa2Tkp0YFbnbATPgS_OL8",
    libraries
  });
  const { showError } = useContext(DialogContext)
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState({ content: "hello" });
  const [lineCoordinates, setLineCoordinates] = useState([]);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [visibleMarkers, setVisibleMarkers] = useState([]); // Keep track of markers not clustered
  const [center, setCenter] = useState({ lat: 28.419923, lng: 77.039329 });
  const [address, setAddress] = useState({})
  const [zoom, setZoom] = useState(10); // Initial zoom level
  const [selectedMarker, setSelectedMarker] = useState(10); // Initial zoom level

  const mapDesignStyle = mapStyles;

  const onMapLoad = (map) => {
    try {
      setMapRef(map);
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend({ lat: 28.419923, lng: 77.039329 });
      // map.fitBounds(bounds)
    } catch (error) {
      showError(error)
    }
  };

  const handleBoundsChanged = () => {
    try {
      if (mapRef) {
        const mapCenter = mapRef.getCenter();
        onDrag({ lat: mapCenter.lat(), lng: mapCenter.lng() })
      }
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    try {
      setMapMarkers(markers);
      if (!clustering) {
        setVisibleMarkers(markers?.map((markerData) => visibleMarkers.find((marker) => markerData?.id === marker?.id)));
      }
    } catch (error) {
      showError(error)
    }
  }, [markers, clustering]);

  const fetchPath = async (polyLine) => {
    try {
      const response = await doGET(`/api/geocode/routes?src=${polyLine?.src?.lat},${polyLine?.src?.lng}&dst=${polyLine?.dest?.lat},${polyLine?.dest?.lng}`)
      if (response.status === 200) {
        const coords = decodePolyline(response.data?.paths[0]?.polyline)
        setLineCoordinates(coords);
      }
    } catch (error) {
      showError(error)
    }
  }

  useEffect(() => {
    if (polyLine?.src?.lat && polyLine?.src?.lng && polyLine?.dest?.lat && polyLine?.dest?.lng) {
      fetchPath(polyLine)
    }
  }, [polyLine?.src?.lat, polyLine?.src?.lng, polyLine?.dest?.lat, polyLine?.dest?.lng]);

  useEffect(() => {
    if (addressLine) {
      getGeocode(addressLine);
    }
  }, [addressLine, getGeocode]);

  useEffect(() => {
    if (infoWindowData && infoWindowData.content) {
      reverseGeocode(infoWindowData.content);
    }
  }, [infoWindowData, reverseGeocode]);

  useEffect(() => {
    if (centerLatLng?.lat && centerLatLng?.lng) {
      setCenter({ lat: centerLatLng?.lat, lng: centerLatLng?.lng })
      setZoom(17)
    }
  }, [centerLatLng?.lat, centerLatLng?.lng, mapRef])

  useEffect(() => {
    if (selectedMapMarker?.coordinate) {
      setZoom(16)
      setCenter({ lat: selectedMapMarker?.coordinate?.lat, lng: selectedMapMarker?.coordinate?.lng });
      setSelectedMarker(selectedMapMarker)
    } else {
      setSelectedMarker(null)
    }
  }, [(selectedMapMarker)]);

  useEffect(() => {
      setLineCoordinates(polyLinePath ?? []);
  }, [polyLinePath])


  if (loadError)
    return <div>Error</div>

  return (
    <div className="MAPComponent">
      {!isLoaded ? (
        <div>Loading...</div>
      ) : (

        <div style={{ position: "relative" }} >
          <GoogleMap
            zoom={zoom ?? 10}
            center={center}
            onLoad={onMapLoad}
            onDragEnd={handleBoundsChanged}
            onClick={() => setIsOpen(false)}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
              styles: mapDesignStyle,
            }}
            mapContainerStyle={{
              width: "100%",
              minHeight: "300px",
              ...mapContainerStyle
            }}
          >

            {clustering ? (
              <MarkerClusterer gridSize={60} minimumClusterSize={2}>
                {(clusterer) =>
                  mapMarkers?.map(({ onClick, coordinate, markerImage, color, popupComponent, popupVisible, onClose = () => { } }, index) => (
                    <Marker
                      key={index}
                      position={coordinate}
                      icon={{
                        url: markerImage,
                        // scaledSize: new window.google.maps.Size(60, 50),
                        // rotation: 45,
                      }}
                      onClick={() => {
                        if (!selectedMarker && onClick) {
                          onClick()
                        } else {
                          setIsOpen(true);
                          setInfoWindowData({ content: popupComponent, index: index });
                        }
                      }}
                      clusterer={clusterer}
                    >
                      {(popupVisible || (isOpen && infoWindowData?.content && infoWindowData?.index == index)) && (<InfoWindow
                        onCloseClick={() => {
                          setIsOpen(false);
                          onClose()
                        }}
                      >
                        <div>
                          {infoWindowData.content}
                        </div>
                      </InfoWindow>)}
                    </Marker>
                  ))
                }
              </MarkerClusterer>
            ) : (
              mapMarkers?.map(({ onClose = () => { }, onClick, coordinate, markerImage, color, popupComponent }, index) => (
                <Marker
                  onClick={() => {
                    if (!selectedMarker && onClick) {
                      onClick()
                    } else {
                      setIsOpen(true);
                      setInfoWindowData({ content: popupComponent, index: index });
                    }
                  }}
                  key={index}
                  position={coordinate}
                  icon={markerImage}
                >
                  {isOpen && infoWindowData?.content && infoWindowData?.index == index && (<InfoWindow
                    onCloseClick={() => {
                      setIsOpen(false);
                      onClose()
                    }}
                  >
                    <div>
                      {infoWindowData.content}
                    </div>
                  </InfoWindow>)}
                </Marker>
              ))
            )}


            {selectedMapMarker && <Marker
              onClick={() => {
                console.log("selectedmarker===")
              }}
              position={selectedMapMarker?.coordinate}
              icon={selectedMapMarker?.markerImage}
            >
              {selectedMapMarker?.popupComponent && <InfoWindow

                position={{
                  lat: selectedMapMarker?.coordinate.lat, // Adjust latitude slightly upward
                  lng: selectedMapMarker?.coordinate.lng, // Keep longitude the same
                }}
                options={{ pixelOffset: new window.google.maps.Size(0, -40) }}// Adjust the height to create a margin-bottom effect

                onCloseClick={() => {
                  selectedMapMarker?.onClose()
                }}
              >
                <div>
                  {selectedMapMarker.popupComponent}
                </div>
              </InfoWindow>}
            </Marker>}

            {lineCoordinates.length > 0 && (
              <Polyline
                path={lineCoordinates}
                options={{
                  strokeColor: "#ff2527",
                  strokeOpacity: 0.9,
                  strokeWeight: 5,
                }}
              />
            )}

          </GoogleMap>
          {/* <div>        {JSON.stringify(address)}</div> */}
          {search && <div className="card mt-2 px-3 py-1" style={{ maxWidth: "550px", minWidth: "400px", position: "absolute", top: "0px", left: "0px" }}>
            <AddressField
              outline
              value={address}
              onChange={(v) => {
                setAddress(v)
                if (v?.lat && v?.lng) {
                  setCenter({ lat: v?.lat, lng: v?.lng })
                }
              }}
            />
          </div>}

          {showMarker ? <FaMapPin
            style={{ color: "#F08180", fontSize: "50px", position: "absolute", top: "calc(50% - 60px)", left: "calc(50% - 27px)" }}
          /> : null}
        </div>

      )
      }
    </div >
  );
};

export default observer(MAPComponent);


const mapStyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]