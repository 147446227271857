import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { InputField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import Items from "./Items";
import TaxService from "./TaxService";

const EditTaxes = ({ editId, onChange = () => { } }, hasErr) => {

  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    items: [{
      name: "",
      baseAmount: "",
      percent: ""
    }]
  });

  const fetch = async () => {
    try {
      setLoading(true);
      let taxes = await TaxService.get(editId);
      setLoading(false)
      setData(taxes);
    } catch (error) {
      setLoading(false)
      showError(error)
    }
  };

  useEffect(() => {
    setData({
      items: [{
        name: "",
        baseAmount: "",
        percent: ""
      }]
    })
    if (editId) fetch();
  }, [editId, TaxService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);

  return (
    <React.Fragment>
      {loading ?
        <div className="d-flex justify-content-center align-items-center">
          <ThreeBounce size={50} color="pink" />
        </div> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row>
            <InputField
              placeholder={t("Name")}
              value={data?.name}
              required={true}
              label={t("Name")}
              onChange={(v) => setData({ ...data, name: v })}
              error={data?.name?.length === 0 ? t("Please enter name") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-3"
            />
          </Row>
          <Items
            data={data?.items}
            onChange={(itm) => {
              setData({
                ...data, items: itm
              })
            }}
          />

        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditTaxes);



