import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom"; // Import from react-router-dom
import { doGET } from "../../util/HttpUtil";
import { Container } from "reactstrap";

const Payment = () => {
  const [searchParams] = useSearchParams();
  const paymentTransactionId = searchParams.get('paymentTransactionId');
  const [buttonHTML, setButtonHTML] = useState("");

  const fetchButton = async () => {
    try {
      const response = await doGET(`/api/o/payment/txn/${paymentTransactionId}/button`)
      
      if (response.status == 200) {
      
        setButtonHTML(response?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchButton()
  }, [paymentTransactionId])

  return (


      <React.Fragment>
        <div className="my-5 ">
          <Container>

            <div className={`zr_pymt_status h-100 `}>
              <div>
                {buttonHTML && (
                  <div dangerouslySetInnerHTML={{ __html: buttonHTML }} />
                )}
              </div>
            </div>

          </Container>
        </div>
      </React.Fragment>
     

  );
}

export default Payment;
