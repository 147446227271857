import { observer } from 'mobx-react-lite';
import React from 'react';
import { Input } from 'reactstrap';

const RecipientsList = ({ customers, data, setData }) => (
    <div className="mb-3">
        {customers?.map((passenger, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
                <Input
                    type="checkbox"
                    checked={data.to.includes(passenger.email)}
                    onChange={() => {
                        const email = passenger.email;
                        const isSelectedEmail = data?.to?.includes(email);
                        setData(prevData => ({
                            ...prevData,
                            to: !isSelectedEmail ? [...(prevData.to ?? []), email] : (prevData.to ?? []).filter(toEmail => toEmail !== email)
                        }));
                    }}
                />
                <span className="ms-2 mt-1">{passenger.email}</span>
            </div>
        ))}
    </div>
);

export default observer(RecipientsList);
