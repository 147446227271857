import { makeAutoObservable, toJS } from 'mobx';
import { doGET, doPOST } from '../../../util/HttpUtil';
import moment from 'moment';

class SMSService {
    SMSs = [];
    selectedChat = null;
    defaultChat = null;
    messages = [];


    constructor() {
        makeAutoObservable(this);
    }

    fetchChats = async (chatId=null) => {
        try {
            const response = await doGET("/api/conversation-head/grid?sortAsc=false&sortBy=lastMessage&rows=-1");
            if (response && response.data) {
                this.SMSs = response.data.rows.map(chat => ({
                    phone: chat?.phone?.phone,
                    countryCode: chat.countryCode,
                    id: chat._id,
                    customer_id: chat.customer_id,
                    lastWhatsapp: moment.unix(chat.lastWhatsapp).format('hh:mm A'),
                    lastWhatsappMsg: chat.lastWhatsappMsg,
                    unread: chat.hasUnreadWhatsapp ? 1 : 0,
                }));
               this.selectedChat=chatId ? chatId: (this.SMSs?.length ? this.SMSs[0]?.customer_id:null)
            }
        } catch (error) {
            console.error("Failed to fetch chats", error);
        }
    };

    fetchMessages = async (chatId) => {
        if(chatId){
            try {
                const response = await doGET(`/api/sms/grid?platform=WHATSAPP&customer_id=${chatId}&rows=40&page=1`);
                if (response && response.data) {
                    this.messages = response.data.rows.map(msg => ({
                        id: msg._id,
                        text: msg.content,
                        time: moment.unix(msg.sentAt).format('hh:mm A'),
                        sender: msg.inbound ? 'other' : 'self',
                        sentAt:msg.sentAt,
                    }));
                }
            } catch (error) {
                console.error("Failed to fetch messages", error);
            }
        }
    };

    setSelectedSMS = (chatId) => {
        this.messages=null;
        this.selectedChat = chatId;
    };

}

export default new SMSService();
