import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET } from "../../../util/HttpUtil";

class CampusSelect {
  campuses = [];
  constructor() {
    makeAutoObservable(this);
  }
  fetch = async function (x) {
    try {
      const response = await doGET(ENDPOINTS.getAllCampus(x));

      if (response.status === 200) {
        this.campuses = Object.keys(response.data).map((k) => {
          return { label: response.data[k], value: k };
        });
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };
}

const CampusSelectService = new CampusSelect();
export default CampusSelectService;
