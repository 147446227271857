import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { CiCircleList } from "react-icons/ci";
import { GoSidebarCollapse, GoSidebarExpand } from "react-icons/go";
import { MdOutlineDashboard } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { DialogContext } from "../../../store/context/DialogContext";
import { UserContext } from "../../../store/context/UserContext";
import { I18nContext } from "../../../store/context/i18nContext";
import StatusPicker from "../../Dropdowns/StatusMasterSelect/StatusPicker";

import { ThreeBounce } from "better-react-spinkit";
import { BsCalendar3 } from "react-icons/bs";
import CustomTooltip from "../../../pages/Booking/components/CustomTooltip";
import {
  Layout
} from "../../index";
import Board from "../board/Board";
import LeadMasterService from "../board/LeadMasterService";
import CalenderLeads from "../calender/CalenderLeads";
import CustomiseSpace from "../customiseSpace/CustomiseSpace";
import LeadForm from "../forms/LeadForm";
import LeadSidebar from "../sideBar/LeadSidebar";
import { STRUCTURE } from "./LeadConstant";
import LeadService from "./LeadService";
import SpaceService from "./SpaceService";
import "./style.css";

const Lead = ({ }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [editID, setEditID] = useState(edit_id);
  const [isListView, setIsListView] = useState(false);
  const [isCalenderView, setIsCalenderView] = useState(false);
  const [status, setStatus] = useState("All");
  const [showDetailPage, setShowDetailPage] = useState(window.location.pathname === "/crm/create" || edit_id);
  const [filterObject, setFilterObject] = useState({});
  const [isCustomizeSpacePopup, setIsCustomizeSpacePopup] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  useEffect(() => {
    setEditID(edit_id)
    if (edit_id || window.location.pathname === "/crm/create") {
      setShowDetailPage(true)
    }
  }, [edit_id, window.location.pathname])


  return (
    <>
      {LeadMasterService.isLoading ?

        <div className="crm-loading-backdrop">
          <ThreeBounce size={30} color="pink" />
        </div> :

        <></>}

      <div className="d-flex align-items-start">
        <div className={`crm-sidebar ${isSidebarOpen ? '' : 'collapsed'}`}>
          <LeadSidebar />
        </div>

        <div className={`flex-1 crm-content ${isSidebarOpen ? '' : 'collapsed'}`}>
          <Layout
            hideAdd={SpaceService.records.length == 0}
            hideSearch
            medium
            backDetailPage={async () => {
              setShowDetailPage(false);
              navigate("/crm");
              setEditID(null);
            }}
             
            customTitleComponent={() => {
              return <div className="d-flex align-items-center">
                <div className="d-flex align-items-center me-2 gap-2">

                  <Tooltip
                    place="bottom"
                    style={{ zIndex: '200000' }}
                    anchorSelect={`#not-clickable-crm-sidebar-expand`}>
                    Collapse Sidebar
                  </Tooltip>

                  <Tooltip
                    place="bottom"
                    style={{ zIndex: '200000' }}
                    anchorSelect={`#not-clickable-crm-sidebar-collapse`}>
                    Expand  Sidebar
                  </Tooltip>

                  {isSidebarOpen ?
                    <GoSidebarExpand
                      size={22}
                      style={{ outline: "none", border: "none" }}
                      onClick={toggleSidebar}
                      id={`not-clickable-crm-sidebar-expand`}

                    /> :
                    <GoSidebarCollapse
                      style={{ outline: "none", border: "none" }}
                      onClick={toggleSidebar}
                      size={20}
                      id={`not-clickable-crm-sidebar-collapse`}
                    />}


                  <CustomTooltip>
                    <CustomTooltip.Body>
                      <div className={(isListView && !isCalenderView) ? "highlight-lead-icon" : ""}>
                        <CiCircleList
                          style={{ outline: "none", border: "none" }}
                          onClick={() => { setIsCalenderView(false); setIsListView(true); }}
                          size={22} />
                      </div>

                    </CustomTooltip.Body>
                    <CustomTooltip.ToolTip>
                      <div style={{ width: "70px" }}>List View</div>
                    </CustomTooltip.ToolTip>
                  </CustomTooltip>

                  <CustomTooltip>
                    <CustomTooltip.Body>
                      <div className={(!isListView && !isCalenderView) ? "highlight-lead-icon" : ""}>

                        <MdOutlineDashboard
                          style={{ outline: "none", border: "none" }}
                          onClick={() => { setIsCalenderView(false); setIsListView(false); }}
                          size={22} />
                      </div>

                    </CustomTooltip.Body>
                    <CustomTooltip.ToolTip>
                      <div style={{ width: "90px" }}>Board View</div>
                    </CustomTooltip.ToolTip>
                  </CustomTooltip>

                  <CustomTooltip>
                    <CustomTooltip.Body>
                      <div className={(isCalenderView) ? "highlight-lead-icon" : ""}>

                        <BsCalendar3
                          style={{ outline: "none", border: "none", padding: "2px 0px" }}
                          onClick={() => { setIsCalenderView(true); }}
                          size={21}

                        />
                      </div>

                    </CustomTooltip.Body>
                    <CustomTooltip.ToolTip>
                      <div style={{ width: "115px" }}>Calender View</div>
                    </CustomTooltip.ToolTip>
                  </CustomTooltip>


                </div>
                {isListView ?
                  <div className="ms-2">
                    <StatusPicker
                      onChange={(v) => setStatus(v?.value)}
                      value={status}
                      showAll
                      openLeft
                    />
                  </div> : null
                }
              </div>
            }}

            filterValues={filterObject}
            tableCardClass="pt-0"
            filterStructure={STRUCTURE}
            // onApplyFilter={fetchData}
            onAddClick={() => {
              navigate(`/crm/create`);
              setShowDetailPage(true);
              setEditID(null);
            }}
            page={LeadService.page}
            rows={LeadService.rows}
            total={LeadService.total}
            fetch={LeadService.fetch}
          >

            <Layout.ActionMenu>
              <div className="layout-action-menu">
                <DropdownMenu>
                  <>
                    <DropdownItem onClick={() => { setIsCustomizeSpacePopup(true) }}>{t("Customise")}</DropdownItem>
                  </>
                </DropdownMenu>
              </div>
            </Layout.ActionMenu>
            <Layout.Table>
              {
                SpaceService.records?.length ?
                  <div>
                    {isCalenderView ? <CalenderLeads /> :
                        <Board
                          isList={isListView}
                          showStatus={status}
                          initial={{}}
                        isSidebarOpen={isSidebarOpen}
                          withScrollableColumns
                          onChange={() => { }}
                        />}
                  </div>
                  :
                  <div className="d-flex justify-content-center h3 py-5 my-5">
                    Create Space to Create Lead
                  </div>}
            </Layout.Table>
          </Layout>
        </div>
      </div>

      <LeadForm
        isOpen={showDetailPage}
        toggleModal={() => {
          navigate("/crm")
          setShowDetailPage(false)
        }}
        editId={editID}
      />

      <CustomiseSpace
        isOpen={isCustomizeSpacePopup}
        toggleModal={() => {
          setIsCustomizeSpacePopup(false)
        }}
      />
    </>
  );
};
export default observer(Lead);
