import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FaCarRear } from "react-icons/fa6";
import { Card, Col, Row } from 'reactstrap';
import { Checkbox, EmployeeSelect, InputField, MakeModelSelect, PhoneNum, ToggleSelector } from '../../../components';
import GarageSelect from "../../../components/Dropdowns/GarageSelect/GarageSelect";
import SupplierCompany from '../../../components/Dropdowns/SupplierCompany/SupplierCompany';
import GroupedVehicleSelect from '../../../components/Dropdowns/VehicleSelect/GroupedVehicleSelect';
import { I18nContext } from '../../../store/context/i18nContext';
import TimesheetService from '../../Timesheet/TimesheetService';
import BookingService from '../BookingService';
import "./style.css";

const AssignVehicle = ({
    vehicle,
    trip,
    driver,
    garage,
    onChangeVehicle,
    onChangeDriver,
    onChangeGarage,
    onChangeTripExtn
}) => {
    const [marketVehicle, setMarketVehicle] = useState(!vehicle ? false : !Boolean(vehicle?._id))
    const { t } = useContext(I18nContext);

    useEffect(() => {
        setMarketVehicle(!vehicle ? false : !Boolean(vehicle?._id))
    }, [vehicle?._id])

    const handleVehicleDetailChange = useCallback((fieldName, fieldValue, marketVehicle, vehicleData) => {
        if (fieldName) {
            if (marketVehicle) {
                const newVehicle = { ...vehicle } || {};
                newVehicle[fieldName] = fieldValue;
                onChangeVehicle(newVehicle);
            } else {
                const newVehicle = {};
                newVehicle[fieldName] = fieldValue;
                if (vehicleData?.supplier_id) {
                    newVehicle["provider_id"] = vehicleData?.supplier_id
                }
                onChangeVehicle(newVehicle);
            }
            if (vehicleData?._id) {
                fetchDrivers(vehicleData?._id)
            }
        }
    }, [vehicle, onChangeVehicle]);

    const handleDriverInfoChange = useCallback((fieldName, fieldValue) => {
        if (fieldName) {
            const newDriver = { ...driver } || {};
            newDriver[fieldName] = fieldValue;
            onChangeDriver(newDriver);
        }
    }, [driver, onChangeDriver]);

    const handleVehicleSupplierChange = useCallback((
        {
            provider_id, providerCompany_id
        }
    ) => {
        const newVehicle = { ...vehicle } || {};
        newVehicle.provider_id = provider_id;
        newVehicle.providerCompany_id = providerCompany_id;
        onChangeVehicle(newVehicle);
    }, [vehicle, onChangeVehicle]);

    const handleDriverSupplierChange = useCallback((
        {
            provider_id, providerCompany_id
        }
    ) => {
        const newDriver = { ...driver } || {};
        newDriver.provider_id = provider_id;
        newDriver.providerCompany_id = providerCompany_id;
        onChangeDriver(newDriver);
    }, [driver, onChangeDriver])
    const handleGarageChange = useCallback((v) => {
        let newGarage = { ...v, garage_id: v?._id } || {};
        onChangeGarage(newGarage);
    }, [driver, onChangeGarage])

    const fetchDriverDetails = async (vehicleId) => {
        try {
            let timeSheetFilterURl = `vehicle_id=${vehicleId}`
            await TimesheetService.fetch(timeSheetFilterURl);
            const timeSheetDivers = TimesheetService?.records

            const timestamp = trip?.startTime ? trip?.startTime : (Math.floor(Date.now() / 1000))
            const bookingStartDate = parseInt(moment.unix(timestamp).format('YYYYMMDD'));
            const currentTime = parseInt(moment.unix(timestamp).format('HHmm'));


            const drivers = []

            timeSheetDivers.forEach(duty => {
                const startTime = duty?.start;
                const endTime = duty?.end;
                const startDate = duty?.startDate;
                const endDate = duty?.endDate;

                if (toSelectDriver(currentTime, bookingStartDate, startTime, endTime, startDate, endDate)) {
                    drivers.push(duty);
                }
            })
            console.log(drivers)

        } catch (error) {

        }
    }

    const toSelectDriver = (bookingTime, bookingStartDate, startTime, endTime, startDate, endDate) => {
        if (!startDate || !startTime) {
            return true;
        }
        if (bookingStartDate < startDate) {
            return false;
        }
        if (endDate && bookingStartDate > endDate) {
            return false;
        }
        if (bookingTime < startTime && bookingStartDate === startDate) {
            return false;
        }
        if (endTime && endDate && bookingTime > endTime && bookingStartDate === endDate) {
            return false;
        }
        return true;
    };

    const fetchDrivers = async (vehicleId) => {
        try {
            let timeSheetFilterURl = `vehicle_id=${vehicleId}`
            await TimesheetService.fetch(timeSheetFilterURl);
            const timeSheetList = TimesheetService?.records

            const timestamp = trip?.startTime ? trip?.startTime : Math.floor(Date.now() / 1000);
            const currentTime = parseInt(moment.unix(timestamp).format('HHmm'));
            const bookingStartDate = parseInt(moment.unix(timestamp).format('YYYYMMDD'));
            const filteredEmployeeIds = new Set();
            timeSheetList.forEach((item) => {
                item.driverDuties.forEach((duty) => {
                    const startTime = duty?.start;
                    const endTime = duty?.end;
                    const startDate = duty?.startDate;
                    const endDate = duty?.endDate;
                    if (toSelectDriver(currentTime, bookingStartDate, startTime, endTime, startDate, endDate)) {
                        filteredEmployeeIds.add(duty.employee_id);
                    }
                });
            });
            const filteredEmployeeIdsArray = Array.from(filteredEmployeeIds)
            if (filteredEmployeeIdsArray?.length > 0) {
                const newDriver = { ...driver } || {};
                newDriver["_id"] = filteredEmployeeIdsArray[0];
                onChangeDriver(newDriver);
            }
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <Card className='px-3 py-0 mb-0'>


            <div className=" d-flex align-items-center justify-content-start pt-2" >

                <ToggleSelector
                    data={[
                        { label: "Market Vehicle", value: true },
                        { label: "Own Vehicle", value: false },
                    ]}

                    zeroMargin
                    style={{ width: "240px", marginBottom: "0" }}
                    value={marketVehicle}
                    onChange={(selectedValue) => {
                        setMarketVehicle(selectedValue);
                        onChangeVehicle({})
                    }}
                />
            </div>


            <Row className='my-0 d-flex justify-content-start' style={{ backgroundColor: "#fff" }}>
                <div className='col-3 d-flex justify-content-center align-items-center'>

                    <FaCarRear fontSize={18} className='me-2' />

                    <MakeModelSelect
                        outline
                        className={"flex-1"}
                        label={t("Requested Vehicle")}
                        value={trip?.tripExtn?.requestedMakeModel}
                        onChange={(v) => {
                            onChangeTripExtn({ fieldName: "requestedMakeModel", value: v?._id })
                        }}
                    />
                </div>


                {marketVehicle ?
                    (<Col className="col-3">
                        {BookingService.renderField("vehicle.providerCompany_id") ?

                            <SupplierCompany
                                value={vehicle?.providerCompany_id}
                                outline
                                onChange={(v) => handleVehicleSupplierChange({ provider_id: v?.supplier_id, providerCompany_id: v?.value })}
                                label={"Vehicle Supplier"}
                            />
                            : null}

                    </Col>
                    )
                    : (<>
                        <Col className="col-3">
                            <div>
                                <GroupedVehicleSelect
                                    className="py-0"
                                    innerContainerClassName="assignedVehicleDriver"
                                    outline={true}
                                    value={vehicle?._id}
                                    showClear={true}
                                    onChange={(v) => {
                                        handleVehicleDetailChange("_id", v?.value, marketVehicle, v);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col className="col-3">
                            <EmployeeSelect
                                outline
                                label={t("Driver")}
                                placeholder={t("Select Driver")}
                                showClear
                                driver
                                value={driver?._id}
                                onChange={(v) => handleDriverInfoChange("_id", v?.value)}
                            />
                        </Col>
                    </>
                    )
                }
                <div className="col-3">
                    <GarageSelect
                        outline
                        module="Booking"
                        value={garage?.garage_id}
                        onChange={(v) => handleGarageChange(v)}
                        isDisabled={trip?.clientInvoice_id ? true : false}
                    />
                </div>

                {marketVehicle &&
                    <div className='row'>
                        <Col className="col-3">
                            {BookingService.renderField("vehicle.makeModel_id") &&
                                <MakeModelSelect
                                    outline
                                    value={vehicle?.makeModel_id}
                                    onChange={(v) => handleVehicleDetailChange("makeModel_id", v?.value, marketVehicle)}
                                />
                            }
                        </Col>
                        <Col className="col-3">
                            {BookingService.renderField("vehicle.regNo") &&
                                <InputField
                                    outline
                                    isReadOnly={BookingService.readOnly("vehicle.regNo")}
                                    label="Reg No."
                                    placeholder={"Reg No."}
                                    value={vehicle?.regNo}
                                    onChange={(v) => handleVehicleDetailChange("regNo", v, marketVehicle)}
                                />
                            }
                        </Col>

                        <Col className="col-3">
                            {BookingService.renderField("driver.name") &&
                                <InputField
                                    outline
                                    label="Driver Name"
                                    placeholder={"Name"}
                                    value={driver?.name}
                                    onChange={(v) => handleDriverInfoChange("name", v)}
                                />}
                        </Col>
                        <Col className="col-3">
                            {BookingService.renderField("driver.phone") &&
                                <PhoneNum
                                    outline
                                    placeholder={t("Phone No ")}
                                    value={{
                                        countryCode: driver?.phone?.countryCode,
                                        phone: driver?.phone?.phone,
                                    }}
                                    hideDropdown
                                    hideFlag
                                    label={"Phone No."}
                                    onChange={(v) => handleDriverInfoChange("phone", v)}
                                />}
                        </Col>


                    </div>}
            </Row>

        </Card>
    )
}

export default AssignVehicle