import React, { useContext, useState } from 'react'
import BookingStatusModifier from '../../pages/Booking/components/BookingStatusModifier'
import moment from 'moment'
import TagPicker from '../TagSelector/TagPicker'
import { InputField } from '../InputField/InputField'
import { statusArray, statusMapping } from '../../pages/Booking/BookingGridColumns'
import { I18nContext } from '../../store/context/i18nContext'
import BookingService from '../../pages/Booking/BookingService'
import { DialogContext } from '../../store/context/DialogContext'
import { FaFileInvoiceDollar, FaPhoneAlt } from "react-icons/fa";
import { BiPrinter } from "react-icons/bi";
import { MdOutlineFeedback } from "react-icons/md";
import { MdOutlineInsertComment } from "react-icons/md";
import { FaRegMessage } from "react-icons/fa6";
import { FaRegClone } from "react-icons/fa";
import BookingFooterDropdown from '../../pages/Booking/components/BookingFooterDropdown'
import { DropdownItem } from 'reactstrap'
import AuditLogDialog from '../../pages/Booking/components/Audit/AuditLog'
import { useNavigate } from 'react-router-dom';
import CommentService from '../../pages/Comment/CommentService'
import MessageDialog from '../../pages/Booking/components/OnDemandMessage/MessageDialog'
import ModalComponent from '../Modal/Modal'
import Feedback from '../../pages/Feedbacks/Feedback'
import Stepper from '../Stepper/Stepper'
import CommentActionItem from '../../pages/Comment/CommentActionItem'
import CallAdmin from '../CallButton'



function EditBookingHeader({ booking, editID, setTrip, fromInvoice, billing_ids }) {
    const { t } = useContext(I18nContext);
    const [loading, setLoading] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const { showError, showConfirm } = useContext(DialogContext);
    const queryParams = new URLSearchParams(window.location.search);
    const isCloning = () => queryParams.has('clone_trip_id')
    const navigate = useNavigate();

    const onStatusChange = async (e, payload) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await BookingService.modifyStatus(payload);
            setTrip(response?.data);
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
    };

    const onStatusChangeViaStepper = async (status) => {
        try {
            setLoading(true);
            if (await showConfirm({
                title: t("Change Status"),
                description: t(`Do you want to change trip status to ${statusMapping[status]?.text}?`),
            })) {
                const response = await BookingService.modifyStatus({ status, _id: editID });
                setTrip(response?.data);
            }
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
    };


    const printInvoice = async () => {
        setLoading(true);
        try {
            await BookingService.print([booking?._id] || [], { ...{ templateName: "dutySlip" } } || {})
            // onSuccess()
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
    };

    const onCloneClick = () => {
        queryParams.set('clone_trip_id', editID);
        navigate({
            pathname: window.location.pathname,
            search: `?${queryParams.toString()}`,
            replace: true,
        });
    }

    //modals
    const toggleMessageModal = (e) => {
        setShowMessageModal(!showMessageModal);
        e?.stopPropagation();
    };

    const filterStatus = (statuses, currentStatus) => {

        if (currentStatus == 5 || currentStatus > 19)
            return [...statuses.slice(0, 6), ...statuses.slice(8)];
        if (currentStatus == 3 || currentStatus == 4)
            return [...statuses.slice(0, 6), statuses[6], ...statuses.slice(8)];

        if (currentStatus == 6 || currentStatus == 19)
            return [...statuses.slice(0, 2), statuses[6], ...statuses.slice(8)];
        if (currentStatus == 7 || currentStatus == 9 || currentStatus == 10)
            return [...statuses.slice(0, 2), statuses[7]];

        return statuses;
    }

    return (
        <div className="zr_layout-header p-3 d-flex justify-content-between w-100" style={{ flexShrink: 0 }}>
            {booking?._id && !isCloning() ? (
                <div className='w-100'>
                    <div className="d-flex justify-content-centre align-items-center">
                        <div>
                            {t(`Edit Booking `)}
                            <span>{"  #"}{booking?.tripNo}</span>
                        </div>

                        <div className='d-flex d-md-none'>
                            <BookingStatusModifier
                                isDisabled={fromInvoice}
                                apiMethod={onStatusChange}
                                currentStatus={booking?.status}
                                trip_id={editID}
                                // statusInfo={statusInfo}
                                statusMapping={statusMapping}
                            />
                        </div>

                        <div className='d-none d-md-flex mx-2 me-4 flex-1'>
                            <Stepper
                                data={filterStatus(statusArray, booking?.status)}
                                value={booking?.status}
                                onChange={onStatusChangeViaStepper}
                            />
                        </div>

                    </div>

                    <div className="d-flex justify-content-between align-items-center ">
                        <div className="d-flex flex-1 flex-column">
                            <div className="d-flex align-items-center flex-1 py-2">
                                <div>
                                    <span style={{ color: '#999999' }}>
                                        {t(`Created At`)}
                                    </span>
                                    <span>
                                        {"  "}
                                        {moment.unix(booking?.createdAt).format("DD-MM-YYYY  hh:mm A")}
                                    </span>
                                </div>
                                {booking?.tripExtn && booking?.tripExtn?.updatedAt &&
                                    <div className="mx-3">
                                        <span style={{ color: '#999999' }}>
                                            {t(`Updated At`)}
                                        </span>
                                        <span>
                                            {"  "}
                                            {moment.unix(booking?.tripExtn?.updatedAt).format("DD-MM-YYYY  hh:mm A")}
                                        </span>
                                    </div>
                                }
                            </div>
                            <TagPicker
                                className="ms-2"
                                kind={"trip"}
                                onChange={(v) => setTrip(prev => ({
                                    ...prev,
                                    tags: v
                                }))}
                                value={booking?.tags}
                            />
                        </div>
                        <InputField
                            outline
                            placeholder="Duty Slip No."
                            label="Duty Slip No."
                            value={booking?.dutySlipNo}
                            onChange={(v) => {
                                setTrip((prevTrip) => {
                                    return {
                                        ...prevTrip,
                                        dutySlipNo: v,
                                    };
                                });
                            }}
                            type="text"
                            className="input-group-prepend mx-1 col-3"
                        />
                        {!fromInvoice ? <BookingFooterDropdown className="mt-1">
                            <>
                                {booking?._id ? <DropdownItem>
                                    <div onClick={printInvoice} className="d-flex gap-2">
                                        <div><BiPrinter size={20} /></div>
                                        <b className="align-self-center">{t(`Print`)}</b>
                                    </div>
                                </DropdownItem> : null}

                                {booking?._id ? <DropdownItem>
                                    <div onClick={toggleMessageModal} className="d-flex gap-2">
                                        <div><FaRegMessage size={20} /></div>
                                        <b className="align-self-center">{t(`Send Message`)}</b>
                                    </div>
                                </DropdownItem> : null}
                                {booking?._id ? <DropdownItem>
                                    <AuditLogDialog
                                        asLabel={true}
                                        modules={[
                                            { key: "Trip", value: booking?._id },
                                            { key: "Billing", value: billing_ids }
                                        ]} />
                                </DropdownItem> : null}
                                {booking?._id ? <DropdownItem>
                                    <div onClick={() => setShowFeedback(true)} className="d-flex gap-2">
                                        <div><MdOutlineFeedback size={20} /></div>
                                        <b className="align-self-center">{t(`Feedback`)}</b>
                                    </div>
                                </DropdownItem> : null}
                                {booking?._id ? <DropdownItem>
                                    <div className="d-flex gap-2">
                                        <CommentActionItem recordType={"trip"} recordOf={booking?._id} />
                                    </div>
                                </DropdownItem> : null}
                                {booking?._id && !queryParams.has('clone_trip_id') ? <DropdownItem>
                                    <div onClick={onCloneClick} className="d-flex gap-2">
                                        <div><FaRegClone size={20} /></div>
                                        <b className="align-self-center">{t(`Clone Booking`)}</b>
                                    </div>
                                </DropdownItem> : null}
                                {booking?.driver ? <DropdownItem>
                                    <CallAdmin driverId={booking?.driver?._id} />
                                </DropdownItem> : null}
                            </>
                        </BookingFooterDropdown> : null}
                    </div>
                    {showMessageModal ? <MessageDialog trip={booking} modal={showMessageModal} toggleModal={() => { toggleMessageModal() }} /> : null}
                    {showFeedback &&
                        (<ModalComponent
                            position={"top"}
                            size={"large"}
                            onToggle={() => {
                                setShowFeedback(false)
                            }}
                            isOpen={showFeedback}
                        >
                            <ModalComponent.Title>
                                {t("FeedBacks")}
                            </ModalComponent.Title>

                            <ModalComponent.Body>
                                <Feedback
                                    recordType="trip"
                                    recordOf={booking._id}
                                    insidePane={true}
                                    multiMode={true}
                                />
                            </ModalComponent.Body>

                        </ModalComponent>)
                    }
                </div>

            ) : <div className="d-flex align-items-center">
                <div> {t(`${isCloning() ? 'Clone' : 'Add'} Booking`)}</div>
                <TagPicker
                    className="ms-2"
                    kind={"trip"}
                    onChange={(v) => setTrip(prev => ({
                        ...prev,
                        tags: v
                    }))}
                    value={booking?.tags}
                />
            </div>}
        </div>
    )
}

export default EditBookingHeader