import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { CardThreeBounce, CitySelect, CountrySelect, InputField, StateSelect } from "../../components";
import MapWithSearchBox from "../../components/Map/MapWithSearchBox";
import { I18nContext } from "../../store/context/i18nContext";
import CampusService from "./CampusService";

import { UserContext } from "../../store/context/UserContext";

const EditCampus = (props) => {
  const { t } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const { id, data, setData, hasErr, setHasErr } = props;
  const [freshData, setFreshData] = useState({});
  const { userContent } = useContext(UserContext);

  const fetch = async () => {
    console.log(userContent, "webContetnt");
    let campusD = {
      name: "",
      address: {
        addressLine: "",
        city: "",
        state: "",
        country: userContent?.country,
      },
    };
    if (id) campusD = await CampusService.get(id);
    setFreshData(campusD);
    setData(campusD);
    setHasErr(false);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetch();
    setHasErr(false);
  }, [id]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(freshData)) {
      props?.onChange(true);
    } else {
      props?.onChange(false);
    }
  }, [data]);

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      {(id && !loading) || !id ? (
        <Form
          className="p-3"
          style={{
            overflowY: "auto",
            height: "100%",
            overflowX: "hidden",
          }}
        >
          <Row className="mt-3">
            <InputField
              placeholder="Office"
              value={data?.name}
              required={true}
              label={"Office Name"}
              onChange={(v) => setData({ ...data, name: v })}
              error={data?.name?.length === 0 ? "Please enter office name" : ""}
              showErr={hasErr}

              type="text"
              className="col-sm-6"
            />
            <CountrySelect
              showErr={hasErr}
              value={data?.address?.country}
              className="col-sm-6 mt-sm-0 mt-3"
              onChange={(v) =>
                setData({
                  ...data,
                  address: {
                    ...data.address,
                    country: v.value,
                    state: null,
                    city: null,
                  },
                })
              }
            />
          </Row>
          <Row className="mt-3">
            <StateSelect
              required={true}
              showErr={hasErr}
              value={data?.address?.state}
              className="col-sm-6 mt-sm-0 mt-3"
              onChange={(v) =>
                setData({
                  ...data,
                  address: {
                    ...data.address,
                    state: v.value,
                    city: null,
                  },
                })
              }
              country={data?.address?.country}
            />
            <CitySelect
              required={true}
              showErr={hasErr}
              value={data?.address?.city}
              className="col-sm-6 mt-sm-0 mt-3"
              onChange={(v) =>
                setData({
                  ...data,
                  address: { ...data.address, city: v.value },
                })
              }
              country={data?.address?.country}
              state={data?.address?.state}
            />
          </Row>

          <MapWithSearchBox
            showMarker={true}
            onChange={(v) => {
              setData({
                ...data,
                address: { ...data.address, addressLine: v },
              });
            }}
            value={data?.address?.addressLine}
            onMapMoved={(prop) => {
              if (prop)
                setData({
                  ...data,
                  address: { ...data.address, addressLine: prop },
                });
            }}
            address={data?.address?.addressLine}
          />
        </Form>
      ) : (
        <CardThreeBounce />
      )}
    </React.Fragment>
  );
};

export default observer(EditCampus);
