import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { FaPlus } from "react-icons/fa";
import LeadAddTask from "../forms/LeadAddTask";
import DeleteStatus from "../forms/DeleteStatus";
import LeadListItem from "../items/LeadListItem";
import QuoteList from "../styles/list";
import LeadMasterService from "./LeadMasterService";
import "./style.scss";

const Column = ({ refresher, title, index, isScrollable, isCombineEnabled, useClone }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [leads, setLeads] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [totalDisplay, setTotalDisplay] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const listRef = useRef();

  const refresh = () => {
    setPage(1)
    setTotal(1)
    setHasMore(true)
  }

  useEffect(() => {
    if (title == LeadMasterService.statusRefresher?.status || title == LeadMasterService.statusRefresher?.prevStatus ) {
      refresh();
    }
  }, [LeadMasterService.statusRefresher?.version])

  const makeLeadObject = (leadItem) => {
    const { brandName, fullName } = leadItem;
    const renderer = () => (
      <LeadListItem
        onDelete={() => { refresh() }}
        onEdit={() => { refresh() }}
        title={brandName}
        author={fullName}
        lead={leadItem}
      />
    );

    return {
      id: JSON.stringify(leadItem), // Assuming each item has a unique ID
      item: leadItem,
      content: brandName,
      renderer,
    };
  }

  const fetchLeads = useCallback(async () => {
    if (!hasMore || loading) return;
    setLoading(true)
    const response = await LeadMasterService.fetchLeadsByStatus({ status: title, page });
    const leadRows = response?.rows?.map(makeLeadObject);
    if (page === 1) {
      setTotal(response.total ?? 0);
      setTotalDisplay(response.total ?? 0);
    }
    setPage((prevPage) => prevPage + 1);
    setHasMore((page === 1 ? response.total ?? 0 : total) > leads.length + leadRows.length);
    if (page == 1) {
      setLeads(leadRows);

    } else {
      setLeads((prevLeads) => [...prevLeads, ...leadRows]);

    }
    setLoading(false)
  }, [title, hasMore, page, total, leads.length]);


  useEffect(() => {
    const fillScreenWithData = async () => {
      // Calculate if the current data fills the screen
      if ((listRef.current && hasMore) || page == 1) {
        const { clientHeight, scrollHeight } = listRef.current;
        // If there's room for more data, fetch next page
        if ((scrollHeight <= clientHeight) || page == 1) {
          await fetchLeads();
        }
      }
    };

    fillScreenWithData();
  }, [leads, hasMore, fetchLeads]);

  useEffect(() => {
    if (refresher?.destinationId) {
      const isSourceUpdated = refresher?.sourceId == title
      const isDestinationUpdated = refresher?.destinationId == title
      const isSourceAndDestinationSame = refresher?.destinationId == refresher?.sourceId

      if (isSourceUpdated && !refresher?.onlyUpdateLeadObject && !isSourceAndDestinationSame) {
        const newLeads = [...leads];
        newLeads.splice(refresher.sourceIndex, 1);
        setLeads(newLeads);
        setTotalDisplay((prevTotalDisplay) => prevTotalDisplay - 1);

      }

      if (isDestinationUpdated && !refresher?.onlyUpdateLeadObject && !isSourceAndDestinationSame) {
        const newLeads = [...leads];
        newLeads.splice(refresher.destinationIndex, 0, makeLeadObject(refresher.leadObject));
        setLeads(newLeads);
        setTotalDisplay((prevTotalDisplay) => prevTotalDisplay + 1);
      }

      if (isSourceAndDestinationSame && isDestinationUpdated && !refresher?.onlyUpdateLeadObject) {
        const newLeads = [...leads];
        newLeads.splice(refresher.sourceIndex, 1);
        newLeads.splice(refresher.destinationIndex, 0, makeLeadObject(refresher.leadObject));
        setLeads(newLeads);
      }

      if ((isDestinationUpdated && refresher?.onlyUpdateLeadObject)) {
        const updatedLeads = leads.map(lead => {
          if (lead.item._id === refresher.leadObject._id) {
            return makeLeadObject(refresher.leadObject)
          }
          return lead;
        });
        setLeads(updatedLeads);
      }
    }
  }, [refresher?.sourceId, refresher?.destinationId, refresher?.leadObject?._id, refresher?.leadObject?.version,])

  useEffect(() => {
    const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom && hasMore) {
        fetchLeads();
      }
    };

    const listElement = listRef.current;

    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [fetchLeads, hasMore]);




  return (
    <Draggable draggableId={title} index={index}>
      {(provided, snapshot) => (
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="column-container card"
          ref={provided.innerRef}
          {...provided.draggableProps}>

          <div className="column-header"
           style={{ borderTop: `2px solid ${LeadMasterService.statusNameMap[title]?.color}`, }} 
           isDragging={snapshot.isDragging}
           >
            <div
              className="item-h4 d-flex justify-content-between align-items-center"
              isDragging={snapshot.isDragging}
              {...provided.dragHandleProps}
              aria-label={`${title} quote list`}
            >
              <div className=" flex-1 ">
                {/* <FaBullseye size={20} color={LeadMasterService.statusNameMap[title]?.color} className='me-2' /> */}
                {LeadMasterService.statusNameMap[title]?.name}

              </div>
              <div className="d-flex align-items-center">
                {/* {isHovered && (
                  <LeadAddTask
                    status={title}
                    onEdit={() => { refresh() }}
                  >
                    <FaPlus />
                  </LeadAddTask>
                )} */}
                {(isHovered && !totalDisplay) && (
                  <DeleteStatus id={title} />
                )}
                <div className="item-column-length ms-2">
                  {totalDisplay ?? 0}
                </div>
              </div>

            </div>
          </div>
          <QuoteList
            listId={title}
            listRef={listRef}
            listType="QUOTE"
            style={{
              backgroundColor: snapshot.isDragging ? "#BBB" : null
            }}
            quotes={leads}
            internalScroll={isScrollable}
            isCombineEnabled={Boolean(isCombineEnabled)}
            useClone={Boolean(useClone)}
          />

        </div>
      )}
    </Draggable>
  );
};

export default observer(Column);
