import { makeAutoObservable, } from "mobx";
import { ENDPOINTS } from "./TranslationsConstant";
import { doGET, doPOST } from "../../util/HttpUtil";


class Service {
  records = [];
  totalRecords = 0;
  STRUCTURE = [];
  page = 1;
  rows = 20;
  filterOption = "";
  error = "";
  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterUrl) => {
    if (filterUrl) this.filterOption = filterUrl;
    else this.filterOption = {};

    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rows, filterUrl)
    );

    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }
  };
  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };
  save = async (data) => {
    const response = await doPOST(ENDPOINTS.save, data);
    console.log(response, "inside service vt");
    return response?.data?._id;
  };

  approve = async (data) => {
    const response = await doPOST(ENDPOINTS.approve, data);

    return response?.data?._id;
  };
  edit = async function (data) {
    const response = await doPOST(ENDPOINTS?.edit, data);

    await this.fetch();
  };

  delete = async (id) => {
    const response = await doGET(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };
  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    await this.fetch(this.filterOption);
  };
}

const TranslationsService = new Service();
export default TranslationsService;
