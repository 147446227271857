import React from "react"
// import { Link, useNavigate } from "react-router-dom"
import {
    Row,
    // Col,
    // Button,
    // Modal,
    // ModalHeader,
    // ModalBody,
    // ModalFooter,
    // Input,
    // Card,
    // Media,
} from "reactstrap"
import InboxService from "./InboxService";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import selectedDriverInbox from '../../assets/images/icons/selectedDriverInbox.svg';




const InboxSideBar = ({ SidebarData }) => {

    // let navigate = useNavigate();
    // const [modal, setmodal] = useState(false)

    const handleModuleClick = (module, filterUrl = "") => InboxService.changeModule(module, filterUrl += `module=${module}`);
    return (
        <React.Fragment>
            <div className="email-leftbar">
                <div className="mail-list">
                    {
                        SidebarData?.map(menu => (
                            <Row key={menu._id} className="py-2 my-1 text-start cursor-pointer w-100" style={{ background: '#fff', color: InboxService.module === menu.module ? '#ec5353' : '#343747', borderRadius: 16 }} onClick={() => handleModuleClick(menu?.module)}>
                                <div className="d-flex justify-content-between align-items-center   ">
                                    <div className="d-flex align-items-center row flex-1">
                                        <div className="col-3">
                                            {InboxService.module === "Driver" && InboxService.module === menu.module ? (
                                                <img height="20" src={selectedDriverInbox} alt="Driver" />
                                            ) : (
                                                menu?.renderer()
                                            )}
                                        </div>
                                        <div className="col-9">
                                            <b style={{ color: InboxService.module === menu.module ? '#ec5353' : '#343747' }}>{menu?.title}</b>
                                        </div>
                                    </div>
                                    <div style={{ fontWeight: 600 }}>
                                        {InboxService.module === menu.module ? (
                                            InboxService.module === "Booking" ? InboxService.bookingTotal :
                                                InboxService.module === "Vehicle" ? InboxService.vehicleTotal :
                                                    InboxService.module === "Driver" ? InboxService.driverTotal :
                                                        menu.totalRecords
                                        ) : menu.totalRecords}
                                    </div>
                                </div>
                            </Row>
                        ))
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default InboxSideBar