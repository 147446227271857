import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { BsPeople } from "react-icons/bs";
import { FaCar } from "react-icons/fa";
import { MdOutlineFlight, MdHotel } from "react-icons/md";
import { doGET } from '../../../../util/HttpUtil';
import Skeleton from 'react-loading-skeleton';
import { FLIGHT_STATUS_MAP } from '../../../Flight/FlightConstant';
import { STAYS_STATUS_MAP } from '../../../Stay/StayConstant';
import { tripTypeMapping } from '../../../../components/BulkModification/BookingColumns';
import { MakeModelSelect } from '../../../../components';
import { formatDateToMonthDay, formatTimeToHourMinute, formatUnixTimestampToDateTime } from '../../../../util/Util';

const CustomerOrderDetails = ({ customerId }) => {
    const [tripLoading, setTripLoading] = useState(false)
    const [individualTripData, setIndividualTripData] = useState([]);

    const fetchIndivisualTripDetails = useCallback(async () => {
        if(!customerId) return;
        setTripLoading(true)
        try {
            const responses = await Promise.allSettled([
                doGET("/api/stay/grid?guests.customer_id=" + customerId),
                doGET("/api/flight/grid?passengers.customer_id=" + customerId),
                doGET("/api/trip/grid?passengers.customer_id=" + customerId)
            ]);

            const [stayResult, flightResult, tripResult] = responses;

            const stayArray = stayResult.status === 'fulfilled' ? stayResult.value?.data?.rows || [] : [];
            const flightArray = flightResult.status === 'fulfilled' ? flightResult.value?.data?.rows || [] : [];
            const tripArray = tripResult.status === 'fulfilled' ? tripResult.value?.data?.rows || [] : [];


            const orderHistory = [
                ...stayArray,
                ...flightArray,
                ...tripArray
            ];

            orderHistory?.sort((a, b) => {
                const firstDate = a?.bookingDate ? moment(String(a?.bookingDate), 'YYYYMMDD') : moment.unix(a?.createdAt);
                const secondDate = b?.bookingDate ? moment(String(b?.bookingDate), 'YYYYMMDD') : moment.unix(b?.createdAt);
                return secondDate - firstDate;
            });
            setIndividualTripData(orderHistory);

        } catch (error) {
            console.log(error)
        } finally {
            setTripLoading(false)
        }

    }, [customerId])
    useEffect(() => {
        fetchIndivisualTripDetails()
    }, [fetchIndivisualTripDetails, customerId])


    if (tripLoading) {
        return (
            <div className='d-flex flex-column gap-3 mx-4'>
                {Array.from({ length: 3 }).map((_, index) => (
                    <Skeleton height={30} width={200} />
                ))}
            </div>
        );
    }
    return (
        <>
            {individualTripData?.length === 0 ? (
                <div className="text-crm-blue-greyish font-size-14 fw-medium p-5 text-center w-100">No records Available</div>
            ) : (
                individualTripData?.map((singleOrder, index) => (
                    <OrderCard key={index} singleOrder={singleOrder} />
                ))
            )}
        </>
    );
};

const OrderCard = ({ singleOrder }) => {
    const bookingType = (val) => tripTypeMapping[val] || "";
    const renderOrder = (Icon, headerInfo, commonInfo, details) => (
        <div className='order_border-white text-light_blue font-size-12 fw-semibold d-flex justify-content-between w-100 p-2'>
            <Icon size={24} className='col-2 p-1' />
            <div className='d-flex flex-wrap col-10'>
                {headerInfo}
                {commonInfo}
                {details}
            </div>
        </div>
    );

    const renderHeader = (...headers) => {      
        return (
          <div className={`w-50 font-size-12 text-crm-darkblue d-flex`}>
            {headers.length >= 2 ? (
                <>
              <span>
                {headers[0]}
               
              </span>
               <span className=" ms-1 font-size-9  greyish-info">{headers[1]}</span>
               </>
            ) : (
              headers.map((header, index) => (
                <span key={index}>{header}</span>
              ))
            )}
          </div>
        );
      };

    const renderCommonInfo = (...info) => (
        <div className='w-50 font-size-12 text-crm-darkblue text-end'>
            {info?.filter(Boolean)?.join(' | ')}
        </div>
    );

    const renderDetails = (order, details) => (
        <div className="d-flex w-100 justify-content-between">
            <div className=' w-50 font-size-10 text-crm-orderColor fw-normal'>{details}</div>
            <div className=' w-50 font-size-10 text-crm-orderColor fw-normal text-end'>
                <BsPeople size={12} /> {order?.guests?.length || order?.passengers?.length || 1}
            </div>
        </div>
    );

    const flightDetails = (order) => `${order?.fromCity??"--"}-${order?.toCity??"--"} ${formatDateToMonthDay(order?.date)??""} ${formatTimeToHourMinute(order?.time)}`
    const carDetails = (order) => `${order?.dst?.city??"--"} ${formatUnixTimestampToDateTime(order?.startTime)}`;
    const hotelDetails = (order) => `${order?.address??""} ${formatDateToMonthDay(order?.checkInDate) ?? formatDateToMonthDay(order?.date)?? formatDateToMonthDay(order?.tripDate)} ${formatTimeToHourMinute(order?.checkInTime)} - ${formatDateToMonthDay(order?.checkOutDate)} ${formatTimeToHourMinute(order?.checkOutTime)}`;

    let orderComponent;
    if (singleOrder?.airline) {
        orderComponent = renderOrder(
            MdOutlineFlight,
            renderHeader(singleOrder.flightNo ?? "--",singleOrder?.airline ?? ""),
            renderCommonInfo(`#${singleOrder?.PNR ?? "--"}`, FLIGHT_STATUS_MAP[singleOrder?.status]?.label),
            renderDetails(singleOrder, flightDetails(singleOrder)));
    } else if (singleOrder?.src) {
        orderComponent = renderOrder(
            FaCar,
            renderHeader(<MakeModelSelect
                asLabel
                value={singleOrder?.vehicle?.makeModel_id}
                className="col-sm-3"
            />),
            renderCommonInfo(`#${singleOrder?.tripNo ?? "--"}`, bookingType(singleOrder?.tripType)),
            renderDetails(singleOrder, carDetails(singleOrder)));
    } else {
        orderComponent = renderOrder(
            MdHotel,
            renderHeader(singleOrder?.hotelName ?? "--"),
            renderCommonInfo(`#${singleOrder?.bookingNo??""}`??0, singleOrder?.trackingCode, STAYS_STATUS_MAP[singleOrder?.status]?.label ),
            renderDetails(singleOrder, hotelDetails(singleOrder)));
    }

    return <>
        {orderComponent}
    </>
};

export default CustomerOrderDetails;
