import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { Modal } from "../../../components/index";
// import Vendors from "../../../pages/Vendors/Vendors";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import VendorSelectService from "./VendorSelectService";

const VendorSelect = (props) => {
  const [vendors, setVendors] = useState([]);
  const [modal, setModal] = useState(false);

  const fetch = async () => {
    try {
      await VendorSelectService.fetch();
      setVendors(toJS(VendorSelectService.vendors));
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (VendorSelectService.vendors.length === 0) fetch();
  }, [props?.value]);

  useEffect(() => {
    let updatedVendors = toJS(VendorSelectService.vendors);

    if (props?.withDCO === "vehicleProfile") {
      updatedVendors.splice(1, 1, { label: "Driver Cum Owner", value: 1 });
    }
    setVendors(updatedVendors);
  }, [VendorSelectService.vendors]);

  return (
    <React.Fragment>
      {modal ? (
        <Modal
          onChange={() => {
            setModal(false);
          }}
        >
          {/* <Vendors
            insidePane={true}
            selectMode={true}
            onSelect={(v) => {
              setModal(false);
              props?.onChange({ value: v[0]?._id });
            }}
          /> */}
        </Modal>
      ) : null}
      {vendors.length > 0 ? (
        !props?.multi ? (
          <SelectField
            isDisabled={props?.isDisabled}
            style={props?.style}
            changeOptionsData={() => {
              setModal(true);
            }}
            bottomBarChildren={() => (
              <Button
                color="primary"
                className="btn btn-primary btn-lg btn-block waves-effect waves-light mb-1 px-2 pb-1"
                style={{ fontSize: 12 }}
                onClick={(e) => e.preventDefault()}
              >
                Add/ Manage Owner
              </Button>
            )}
            showBottomBar={true}
            className={props?.className}
            onChange={props?.onChange}
            data={vendors}
            showClear={props?.showClear}
            value={props?.value}
            label={props?.label ? props?.label : ""}
            error={
              !props?.value
                ? "Please Select " + (props?.label ? props?.label : "")
                : ""
            }
            showErr={props?.showErr}
            inline={props?.inline}
            borderNone={props?.borderNone}
            RightComponent={() => (
              <div
                style={{ height: "100%" }}
                className="d-flex align-items-center justify-content-center"
              >
                {" "}
                <i
                  role="button"
                  className="mdi mdi-magnify text-dark font-size-14 mx-1"
                />
              </div>
            )}
            onClickRight={() => {
              setModal(true);
            }}
            {...props}
          />
        ) : (
          <SelectFieldMultiple
            isDisabled={props?.isDisabled}
            className={props?.className}
            style={props?.style}
            onChange={(v) => props?.onChange(v.map((c) => c?.value))}
            data={vendors}
            value={
              props?.value
                ? vendors.filter((c) => props?.value?.includes(c?.value))
                : ""
            }
            error={
              !props?.value
                ? "Please Select " + (props?.label ? props?.label : "")
                : ""
            }
            required={props?.required}

            label={"Vendor"}
            showErr={props?.showErr}
            RightComponent={() => (
              <div
                style={{ height: "100%" }}
                className="d-flex align-items-center justify-content-center"
              >
                {" "}
                <i
                  role="button"
                  className="mdi mdi-magnify text-dark font-size-14 mx-1"
                />
              </div>
            )}
            onClickRight={() => {
              setModal(true);
            }}
          />
        )
      ) : (
        <SelectField
          data={vendors}
          style={props?.style}
          RightComponent={props?.RightComponent}
          onClickRight={props?.onClickRight}
          showClear={props?.showClear}
          value={props?.value}
          onChange={props?.onChange}
          className={props?.className}
          label={props?.label ? props?.label : ""}
          isDisabled={true}
          error={props?.value ? "" + (props?.label ? props?.label : "") : ""}
          showErr={props?.showErr}
          inline={props?.inline}
          borderNone={props?.borderNone}
        />
      )}
    </React.Fragment>
  );
};

export default observer(VendorSelect);
