import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, DropdownItem, DropdownMenu, Row } from "reactstrap";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  Layout,
  SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { I18nContext } from "../../store/context/i18nContext";
import EditTransactions from "./EditTransaction";
import { STRUCTURE } from "./TransactionConstant";
import { GridColumns } from "./TransactionGridColumns";
import TransactionService from "./TransactionService";
import { dateToTimestamp } from "../../util/Util";

const Transactions = ({ insidePane }) => {


  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [tillDate, setTillDate] = useState(null);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/transaction/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});

  const fetchData = async (filterUrl) => {
    // console.log(filterURL);
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    if (!!fromDate && !!tillDate) {
      filterUrl = filterUrl+`&transactionDate=btw[${encodeURIComponent(`${fromDate},${tillDate}`)}]`;
    }
    try {
      await TransactionService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
  }, [fromDate,tillDate])

  // const onSave = async (e) => {
  //   e.preventDefault();
  //   // if (!detailData.name) {
  //   //   showError(t("Please enter all fields"));
  //   //   return;
  //   // }
  //   setLoading(true);
  //   try {
  //     if (editID) {
  //       await TransactionService.edit(detailData, editID);
  //       showMessage(t("Record updated successfully."));
  //     } else {
  //       const transaction = await TransactionService.save(detailData);
  //       showMessage(t("Record saved successfully."));
  //       if (!insidePane) navigate(`/transaction/edit/${transaction}`);
  //     }
  //     fetchData(filterURL);
  //   } catch (e) {
  //     showError(e);
  //   }
  //   setLoading(false);
  // };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await TransactionService.delete(id);
        showMessage("Deleted", "Deleted");
      } catch (e) {
        showError(e);
      }
    }
  };
  return (
    <>
      <Layout
       showDateRange
       fromDate={fromDate}
       tillDate={tillDate}
       setFromDate={setFromDate}
       setTillDate={setTillDate}
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/transaction");
          setEditID(null);
        }}
         
        title={t("Transactions")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        // onAddClick={() => {
        //   if (!insidePane) navigate(`/transaction/create`);
        //   setShowDetailPage(true);
        //   setEditID(null);
        // }}
        insidePane={insidePane}
        page={TransactionService.page}
        rows={TransactionService.rows}
        total={TransactionService.total}
        fetch={TransactionService.fetch}

      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem>Bulk Delete</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> Download </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={TransactionService.records}
            total={TransactionService.total}
            uiPreference="transaction.grid"
            headers={GridColumns}
            selectedIDs={selectedIDs}
            onSelectChange={setSelectedIDs}
            page={TransactionService.page}
            rowsPerPage={TransactionService.rowsPerPage}
            onPaginationChange={TransactionService.onPaginationChange}
            renderLastCol={(transaction) => {
              return (
                <>
                  <DeleteLink
                    onClick={(event) => onDelete(event, transaction?._id)}
                  />
                </>
              );
            }}
          />
        </Layout.Table>
        <Layout.DetailPageTitle>
          {detailData?._id ? "Edit Transaction" : "Add Transaction"}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditTransactions
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
          />
        </Layout.DetailPageBody>

        {/* <Layout.DetailPageFooter>
          {detailData?._id && <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} />}
          <SaveButton onClick={onSave} />
        </Layout.DetailPageFooter> */}
      </Layout>
    </>
  );
};
export default observer(Transactions);




