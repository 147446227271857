import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { Card } from 'reactstrap';
import { UserContext } from '../../store/context/UserContext';
import "./style.css"

const MarketPlace = () => {
    const navigate = useNavigate()

    //1 Client
    //2 Transporter(supplier)
    useEffect(() => {
        document.body.classList.add('add-marketplace-background');
        return function cleanup() {
            document.body.classList.remove('add-marketplace-background');
        };
    }, []);

    return (
        <div >
            <div className=' page-content' >
                <Card className=' mt-3 w-50 h-100  m-auto' style={{ borderRadius: "20px", boxShadow: "0px 4px 29px rgba(0, 0, 0, 0.25)", opacity: "0.8" }}>
                    <h1 className='mt-3 markertplace-header m-auto'>Join Marketplace Now</h1>
                    <div className=' px-4 mt-5 mb-3'>
                        <ul className=' '>
                            <li className=' para ' > Join a vibrant marketplace with direct customer connections.</li>
                            <li className=' para ' > Expand your customer base and reach new audiences.</li>
                            <li className=' para '>  Engage in direct interaction with customers for personalized solutions.</li>
                            <li className=' para ' > Increase sales opportunities and drive business growth.</li>
                            <li className=' para '> Enhance your brand's credibility by associating with a reputable markertplace.</li>
                            <li className=' para '> Experience a seamless platform with user-friendly features.</li>
                            <li className=' para '> Simplified registration process for easy onboarding.</li>
                        </ul>
                    </div>

                    <button onClick={() => {
                        navigate('/marketplace/dashboard')
                    }} className='marketplace-btn mb-4 mt-5 m-auto'><span>Join Marketplace</span></button>
                </Card>
                {/* <div className=' mt-4' >
                <img className=' w-100' src={MarketPlaceImage} alt="" />
            </div> */}
            </div>
        </div>

    )
}

export default MarketPlace