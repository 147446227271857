import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useContext } from "react";
import { Button, Table } from "reactstrap";
import Modal from "../../Common/Modal";
import { SelectField } from "../../SelectField/SelectField";
import RateSelectService from "./RateSelectService";
import Rates from "./Rates";
import { I18nContext } from "../../../store/context/i18nContext";

const RateSelect = (props) => {
  const [rates, setRates] = useState([]);
  const [modal, setModal] = useState(false);
  const { t } = useContext(I18nContext);

  useEffect(() => {
    const fetch = async () => {
      try {
        await RateSelectService.fetch();
        setRates(toJS(RateSelectService.rates));
      } catch (error) {
        console.log(error.message);
      }
    };
    fetch();
  }, []);

  const renderFunc = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps}>
        <Table style={{ margin: "0px" }} className="dropdown-hover">
          <thead style={{ margin: "0px" }}>
            <tr style={{ margin: "0px" }}>
              <td className="col-3 py-3">
                <img
                  src={data.pic}
                  alt="car"
                  style={{
                    display: "inline",
                    maxHeight: "40px",
                    margin: "0px 5px",
                  }}
                />
              </td>
              <td className="col-6 py-3">{`${data.name}`}</td>
            </tr>
          </thead>
        </Table>
      </div>
    );
  };

  return (
    <React.Fragment>
      {modal ? (
        <Modal
          className="col-sm-6"
          onChange={() => {
            setModal(false);
          }}
        >
          <Rates />
        </Modal>
      ) : null}

      <SelectField
        outline={props?.outline ?? false}
        render={renderFunc}
        changeOptionsData={(e) => {
          e.preventDefault();
          setModal(true);
        }}
        bottomBarChildren={() => (
          <Button
            color="primary"
            className="btn btn-primary btn-lg btn-block waves-effect waves-light mb-1"
          >
            {t("Add/ Manage")}
          </Button>
        )}
        showBottomBar={true}
        onInputChange={(v) => {
          if (v.length > 1) fetch(v);
        }}
        className={props?.className}
        onChange={props?.onChange}
        data={rates}
        value={props?.value}
        label={t("Package")}
        error={props?.value ? "Please Select Rate" : ""}
        showErr={props?.showErr}
        inline={props?.inline}
        borderNone={props?.borderNone}
        onClickRight={(e) => {
          e.preventDefault();
          setModal(true);
        }}
      />
    </React.Fragment>
  );
};

export default observer(RateSelect);
