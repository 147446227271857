import axios from 'axios';
import { useState, useEffect } from 'react';

const getCleanVersion=(version)=>{
    let cleanVersion=(version??"").replace("\n","");
    cleanVersion = cleanVersion.replace(/\./g, '');
    return parseInt(cleanVersion, 10);

}

const useVersionChecker = (currentVersion) => {
  const [showPopup, setShowPopup] = useState(false);

  const toggleShowPopup=()=>setShowPopup(false)

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response =await axios(`${window.location.protocol}//${window.location.host}/version?current=${Date.now()}`);
        if(!response.data) return;
        let  fetchedVersion  = getCleanVersion(response.data??"");
        const currVersion=getCleanVersion(currentVersion);
        if (fetchedVersion && currVersion && currVersion < fetchedVersion) { 
          setShowPopup(true);
        }
      } catch (error) {
        console.error('Failed to fetch version:', error);
      }
    };

    const intervalId = setInterval(checkVersion,  10 * 60 *  1000);  //10 mins

    checkVersion();

    return () => clearInterval(intervalId);  
  }, [currentVersion]);

  return {showPopup,toggleShowPopup};
};

export default useVersionChecker;