import { FaBed, FaBus, FaCheckCircle, FaPlane, FaUserSecret, FaInfinity } from 'react-icons/fa';
import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { Tooltip } from 'react-tooltip';

const coverageOptions = [
    { key: 'All', label: "All", icon: FaInfinity },
    { key: 'Stay', label: "Stay", icon: FaBed },
    { key: 'Transport', label: "Transport", icon: FaBus },
    { key: 'Guide', label: "Guide", icon: FaUserSecret },
    { key: 'Flight', label: "Flight", icon: FaPlane },
];

const CoveragePicker = ({
    value = [],
    onChange,
    noToggle
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleSelect = (key) => {
        let newValue;
        if (key === 'All') {
            if (value.length === coverageOptions.length - 1) {
                newValue = [];
            } else {
                newValue = coverageOptions.filter(option => option.key !== 'All').map(option => option.key);
            }
        } else {
            if (value.includes(key)) {
                newValue = value.filter(item => item !== key);
            } else {
                newValue = [...value, key];
            }
        }

        onChange(newValue);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [dropdownRef]);


    const getIconForValue = (key) => {
        const foundOption = coverageOptions.find(option => option.key === key);
        const IconComponent = foundOption ? foundOption.icon : null;
        if (!IconComponent) return null;

        const tooltipId = `tooltip-${key}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
        return (
            <>
                <Tooltip style={{ zIndex: '200000' }} anchorSelect={`#${tooltipId}`}>
                    <div>{foundOption.label}</div>
                </Tooltip>
                <span id={tooltipId} className="zr_coverage_picker_icon mx-1">
                    <IconComponent size={14} />
                </span>
            </>
        );
    };

    return (
        <div className="zr_coverage_picker_wrapper" ref={dropdownRef}>
            <div className={`zr_coverage_picker_display ${noToggle ? 'zr_coverage_picker_display_aslabel' : ''}  ${value.length ? 'selected' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                {
                    <div className="zr_coverage_picker_selectedOptions">

                        {!noToggle ?     <span className={` text-muted ${value?.length > 0 ? "me-2 " : ""}`}>Coverage </span>  : null}
                  
                        {
                            value.length === coverageOptions.length - 1 ? <FaInfinity size={14} /> : <span>{value.map(key => getIconForValue(key))}</span>
                        }

    
                    </div>
                }
            </div>

            {isOpen && !noToggle && (

                <ul className="zr_coverage_picker_modal">
                    {coverageOptions.map(option => (
                        <li
                            key={option.key}
                            className={`zr_coverage_picker_item ${value.includes(option.key) ? 'active' : ''}`}
                            onClick={() => handleSelect(option.key)}
                        >
                            {React.createElement(option.icon, { size: 14, })}
                            <span className="ms-2">{option.label}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CoveragePicker;
