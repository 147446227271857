export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/fund/account/grid?rows=${sizePerPage}&`;
        if (filterUrl) url += filterUrl;
        return url + "&page=" + page;
    },
    get: (id) => `/api/fund/account/${id}/detail`,
    save: `/api/fund/account/save`,
};
export const AccountTypes = [
    {
        label: 'BANK',
        value: 'BANK',
    },
    {
        label: 'WALLET',
        value: 'WALLET',
    },
    {
        label: 'CREDIT_CARD',
        value: 'CREDIT_CARD',
    },
]