export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/payment-gateway/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/payment-gateway/${id}/detail`,
  save: `/api/payment-gateway/create`,
  delete: (id) => `/api/payment-gateway/${id}/delete`,
  bulkDelete: (id) => `/api/payment-gateway/delete`,
  update: `/api/payment-gateway/update`,
  gettype: `/api/payment-gateway/types`
};
export const SELECTSTRUCTURE = {
  paymentGateway: [
    { label: "PAYTM", value: "PAYTM" },
    { label: "PAYU", value: "PAYU" },
    { label: "PAYPAL", value: "PAYPAL" },
    { label: "RAZORPAY", value: "RAZORPAY" },
    { label: "PAYUBIZ", value: "PAYUBIZ" },
  ],
  active: [
    { label: 'True', value: "True" },
    { label: 'False', value: "False" },


  ]
};

export const STRUCTURE = [

  {
    label: "PaymentGateway",
    filterLabel: "PaymentGateway",
    filterName: "code",
    type: "select",
    data: SELECTSTRUCTURE?.paymentGateway

  },
  {
    label: "Currency",
    filterLabel: "Currencies",
    filterName: "currencies",
    type: "currencySelect",
  },
  {
    label: "Active",
    filterLabel: "Active",
    filterName: "active",
    type: "select",
    data: SELECTSTRUCTURE?.active

  },
];


