import { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import {
  Button,
  Card,
  CardFooter
} from "reactstrap";
import { I18nContext } from "../../../../../../store/context/i18nContext";
import AmountField from "../../../../../AmountField/AmountField";
import { InputField, } from "../../../../../InputField/InputField";
import "./index.scss";
import Checkbox from "../../../../../../pages/Offers/checkbox";

const FeeGroup = ({
  onChange,
  feeGroups = [{
    charges: [{}],
    discounts: [{}],
  }],
  showDetail = true,
  label
}) => {

  const ZeroIfNaN = (value) => {
    return isNaN(value) ? 0 : (value).toFixed(2);
  };

  useEffect(() => {
    const updatedFeeGroups = feeGroups.map(feeGroup => ({
      charges: feeGroup?.charges?.length ? feeGroup.charges : [{
        unitCost: 100
      }],
      discounts: feeGroup?.discounts?.length ? feeGroup.discounts : [{
        detail: "Discount",
        amount: 0
      }],
      ...feeGroup
    }));

    if (JSON.stringify(feeGroups) !== JSON.stringify(updatedFeeGroups)) {
      onChange(updatedFeeGroups);
    }
  }, [feeGroups]);


  const { t } = useContext(I18nContext);


  const handleCalculations = (feeG) => {
    if (feeG) {
      const subTotal =
        feeG?.charges?.reduce(
          (acc, charge) => acc + (charge?.amount || 0),
          0
        ) || 0;
      const totalDiscounts =
        feeG?.discounts?.reduce(
          (acc, discount) => acc + (discount?.amount || 0),
          0
        ) || 0;
      const totalTaxes =
        feeG?.taxItem?.taxes?.reduce((acc, taxItem) => {
          const percent = taxItem?.percent;
          const baseAmount = taxItem?.baseAmount ?? 0;
          if (!percent && !baseAmount) {
            return acc;
          }
          if (!percent) {
            return acc + baseAmount;
          }
          return (
            acc + (((subTotal - totalDiscounts) * percent) / 100 + baseAmount)
          );
        }, 0) || 0;
      feeG.subTotal = subTotal;
      feeG.totalDiscounts = totalDiscounts;
      feeG.totalTaxes = totalTaxes;
      feeG.total = subTotal - totalDiscounts + totalTaxes;
    }
    return feeG;
  };


  function updateCharges(feeGroupIndex, chargeIndex, field, value) {
    const newFeeGroups = [...feeGroups];
    let charge = { ...newFeeGroups[feeGroupIndex].charges[chargeIndex] };

    charge[field] = value;

    if (field === "unitCost" || field === "units") {
      const unitCost = charge.unitCost || 0;
      const units = charge.units || 0;
      charge.amount = (unitCost * units);
    }

    newFeeGroups[feeGroupIndex].charges[chargeIndex] = charge;

    const updatedFeeGroup = handleCalculations(newFeeGroups[feeGroupIndex]);
    newFeeGroups[feeGroupIndex] = updatedFeeGroup;

    if (typeof onChange === 'function') {
      onChange(newFeeGroups);
    } else {
      console.error('onChange is not a function');
    }
  }

  function AddCharges(feeGroupIndex) {
    if (!Array.isArray(feeGroups) || feeGroupIndex < 0 || feeGroupIndex >= feeGroups.length) {
      console.error('Invalid feeGroupIndex or feeGroups is not an array');
      return;
    }
    const newFeeGroups = [...feeGroups];
    if (!newFeeGroups[feeGroupIndex]?.charges) {
      newFeeGroups[feeGroupIndex] = { ...newFeeGroups[feeGroupIndex], charges: [] };
    }

    const newCharge = { name: '', detail: '', unitCost: 0, units: 0, amount: 0 };
    newFeeGroups[feeGroupIndex].charges.push(newCharge);

    // Recalculate the updated fee group
    const updatedFeeGroup = handleCalculations(newFeeGroups[feeGroupIndex]);
    newFeeGroups[feeGroupIndex] = updatedFeeGroup;

    if (typeof onChange === 'function') {
      onChange(newFeeGroups);
    } else {
      console.error('onChange is not a function');
    }
  }

  function RemoveCharges(feeGroupIndex, chargeIndex) {
    if (!Array.isArray(feeGroups) || chargeIndex < 0 || feeGroupIndex >= feeGroups.length) {
      console.error('Invalid feeGroupIndex or feeGroups is not an array');
      return;
    }

    const newFeeGroups = [...feeGroups];
    if (!newFeeGroups[feeGroupIndex]?.charges || chargeIndex < 0 || chargeIndex >= newFeeGroups[feeGroupIndex].charges.length) {
      console.error('Invalid chargeIndex or charges array does not exist');
      return;
    }
    newFeeGroups[feeGroupIndex].charges.splice(chargeIndex, 1);

    const updatedFeeGroup = handleCalculations(newFeeGroups[feeGroupIndex]);
    newFeeGroups[feeGroupIndex] = updatedFeeGroup;
    if (typeof onChange === 'function') {
      onChange(newFeeGroups);
    } else {
      console.error('onChange is not a function');
    }
  }

  function AddDiscount(feeGroupIndex) {
    if (!Array.isArray(feeGroups) || feeGroupIndex < 0 || feeGroupIndex >= feeGroups.length) {
      console.error('Invalid feeGroupIndex or feeGroups is not an array');
      return;
    }
    const newFeeGroups = [...feeGroups];
    if (!newFeeGroups[feeGroupIndex]?.discounts) {
      newFeeGroups[feeGroupIndex] = { ...newFeeGroups[feeGroupIndex], discounts: [] };
    }
    const newDiscount = { detail: '', amount: 0 };
    newFeeGroups[feeGroupIndex].discounts.push(newDiscount);

    const updatedFeeGroup = handleCalculations(newFeeGroups[feeGroupIndex]);
    newFeeGroups[feeGroupIndex] = updatedFeeGroup;
    if (typeof onChange === 'function') {
      onChange(newFeeGroups);
    } else {
      console.error('onChange is not a function');
    }
  }

  function updateDiscounts(discountIndex, feeGroupIndex, field, value) {
    const newFeeGroups = [...feeGroups]; // Assuming feeGroups is accessible in this context
    let discount = { ...newFeeGroups[feeGroupIndex].discounts[discountIndex] };
    discount[field] = value;

    newFeeGroups[feeGroupIndex].discounts[discountIndex] = discount;

    const updatedFeeGroup = handleCalculations(newFeeGroups[feeGroupIndex]);
    newFeeGroups[feeGroupIndex] = updatedFeeGroup;

    if (typeof onChange === 'function') {
      onChange(newFeeGroups);
    } else {
      console.error('onChange is not a function');
    }
  }

  function RemoveDiscount(feeGroupIndex, discountIndex) {
    if (!Array.isArray(feeGroups) || feeGroupIndex < 0 || feeGroupIndex >= feeGroups.length) {
      console.error('Invalid feeGroupIndex or feeGroups is not an array');
      return;
    }
    const newFeeGroups = [...feeGroups];
    if (!newFeeGroups[feeGroupIndex]?.discounts || discountIndex < 0 || discountIndex >= newFeeGroups[feeGroupIndex].discounts.length) {
      console.error('Invalid discountIndex or discounts array does not exist');
      return;
    }
    newFeeGroups[feeGroupIndex].discounts.splice(discountIndex, 1);

    const updatedFeeGroup = handleCalculations(newFeeGroups[feeGroupIndex]);
    newFeeGroups[feeGroupIndex] = updatedFeeGroup;
    if (typeof onChange === 'function') {
      onChange(newFeeGroups);
    } else {
      console.error('onChange is not a function');
    }
  }








  return (
    <div className="zr_fee_table-container">
      {
        feeGroups?.map((feeGroup, index) => (
          <div key={index} className="zr_fee_table-group">
            <div className="d-flex flex-column align-items-end mb-0">
              <table className="zr_fee_table">
                <thead>
                  <tr>
                    <th className="zr_fee_table-th">Charge Type</th>
                    {showDetail ? <th className="zr_fee_table-th">Detail</th> : null}
                    <th className="zr_fee_table-th" style={{ textAlign: "end" }}>Unit Cost</th>
                    <th className="zr_fee_table-th" style={{ textAlign: "end" }}>No. of Units</th>
                    <th className="zr_fee_table-th" style={{ width: "10%", textAlign: "end" }}>Amount</th>
                    <th className="zr_fee_table-th" style={{ width: "5%" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {feeGroup?.charges?.map((charge, chargeIndex) => (
                    <tr key={chargeIndex}>
                      <td className="zr_fee_table-td">
                        <InputField
                          outline
                          noLabel
                          className="zr_fee_table-input m-0"
                          value={charge?.name}
                          onChange={(v) => updateCharges(index, chargeIndex, "name", v)}
                        />
                      </td>
                      {showDetail ? (
                        <td className="zr_fee_table-td">
                          <InputField
                            outline
                            className="zr_fee_table-input m-0"
                            value={charge?.detail}
                            onChange={(v) => updateCharges(index, chargeIndex, "detail", v)}
                            type="text"
                          />
                        </td>
                      ) : null}
                      <td className="zr_fee_table-td zr_fee_table-unit-cost">
                        <AmountField
                          placeholder={"Cost Per Unit"}
                          className="zr_fee_table-input m-0"
                          value={charge?.unitCost}
                          onChange={(v) => updateCharges(index, chargeIndex, "unitCost", parseInt(v))}
                          type="text"
                        />
                        <div className="zr_fee_table-close-icon">
                          <i className="mdi font-size-16 mdi-close" />
                        </div>
                      </td>
                      <td className="zr_fee_table-td zr_fee_table-units">
                        <InputField
                          outline
                          className="zr_fee_table-input m-0"
                          value={charge?.units}
                          onChange={(v) => updateCharges(index, chargeIndex, "units", parseInt(v))}
                          type="number"
                        />
                        <div className="zr_fee_table-equal-sign">=</div>
                      </td>
                      <td className=" zr_fee_table-unit_total_amount" >
                        {charge?.amount ? Number(charge?.amount / 100)?.toFixed(2) : 0}
                      </td>
                      <td className="zr_fee_table-td">
                        <div className="zr_fee_table-action-icon">
                          {feeGroup?.charges?.length !== 1 && (
                            <i
                              onClick={() => RemoveCharges(index, chargeIndex)}
                              className="bx color-blue font-size-20 bxs-x-circle"
                            ></i>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td className="d-flex zr_fee_add_buttons">
                      <Button
                        onClick={() => { AddCharges(index); }}
                        className="d-flex align-items-center py-1 me-2"
                      >
                        <FaPlus className='me-2' /> Charges
                      </Button>
                      <Button
                        onClick={() => { AddDiscount(index) }}
                        className="d-flex align-items-center py-1"
                      >
                        <FaPlus className='me-2' /> Discount
                      </Button>
                    </td>
                    <td></td>
                    <td colSpan={4} style={{ maxWidth: "300px" }}>
                      <Card>
                        <table className="zr_fee_table-subtable">
                          <tbody>
                            <tr>
                              <td colSpan={3} style={{ width: "70.5%" }} className="zr_fee_table-td">Sub Total</td>
                              {/* <td className="zr_fee_table-td"></td>
                            <td className="zr_fee_table-td"></td> */}
                              <td className="zr_fee_table-td" style={{ width: "21%", textAlign: "end" }}>{ZeroIfNaN(feeGroup?.subTotal) / 100}</td>
                              <td className="zr_fee_table-td"></td>

                            </tr>
                          </tbody>
                        </table>
                        <table className="zr_fee_table-subtable">
                          <tbody>
                            {feeGroup?.discounts?.map((discount, discountIndex) => (
                              <tr key={discountIndex} className="position-relative">
                                <td colSpan={3} style={{ width: "70.5%" }} className="zr_fee_table-td">
                                  <InputField
                                    outline
                                    className="zr_fee_table-input m-0"
                                    placeholder="Discount Detail"
                                    noLabel
                                    value={discount?.detail}
                                    onChange={(v) => updateDiscounts(discountIndex, index, "detail", v)}
                                    type="text"
                                  />
                                  <div className="zr_fee_table-minus-sign">-</div>
                                </td>
                                <td className="zr_fee_table-td" style={{ width: "21%" }}>
                                  <AmountField
                                    placeholder={"Discount"}
                                    className="zr_fee_table-input m-0"
                                    value={discount?.amount}
                                    onChange={(v) => updateDiscounts(discountIndex, index, "amount", parseInt(v))}
                                    type="text"
                                  />
                                </td>
                                <td className="zr_fee_table-td">
                                  <div className="zr_fee_table-action-icon">
                                    {feeGroup?.discounts?.length !== 1 && (
                                      <i
                                        onClick={() => RemoveDiscount(index, discountIndex)}
                                        className="bx color-blue font-size-20 bxs-x-circle"
                                      ></i>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <CardFooter className="p-0">
                          <table className="zr_fee_table-subtable">
                            <tbody>
                              <tr className="d-flex">
                                <th className="zr_fee_table-th" style={{ width: "70.5%" }}>Total</th>
                                {/* <th className="zr_fee_table-th" style={{ width: "20%" }}></th> */}
                                <th className="zr_fee_table-th" style={{ width: "21%", textAlign: 'end' }}>
                                  {feeGroup?.total ? ((feeGroup?.total) / 100)?.toFixed(2) : 0.00}
                                </th>
                                <th className="zr_fee_table-th" style={{ width: "8.5%", textAlign: 'end' }}></th>
                              </tr>
                            </tbody>
                          </table>
                        </CardFooter>
                      </Card>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))
      }
    </div>
  );

};
export default FeeGroup;
