import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import Driver from "../../../pages/Driver/Driver";
import Modal from "../../Common/Modal";
import { SelectField } from "../../SelectField/SelectField";
import DriverSelectService from "./DriverSelectService";


const DriverSelect = (props) => {

  const [drivers, setDrivers] = useState([]);
  const [modal, setModal] = useState(false);

  const fetch = async (regNo) => {
    try {
      await DriverSelectService.fetch(regNo);
      setDrivers(
        toJS(DriverSelectService?.drivers)?.map((c) => {
          return {
            ...c,
            value: c?._id,
            label: (
              <div>
                <img
                  src={c?.pic}
                  alt={c?.name}
                  style={{
                    display: "inline",
                    maxHeight: "20px",
                    margin: "5px",
                  }}
                />
                {`${c?.name}          ${c.phone}`}
              </div>
            ),
          };
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (DriverSelectService.drivers.length === 0) fetch();
  }, []);

  useEffect(() => {
    setDrivers(
      toJS(DriverSelectService?.drivers)?.map((c) => {
        return {
          ...c,
          value: c?._id,
          label: (
            <div>
              <img
                src={c?.pic}
                alt={c?.name}
                style={{ display: "inline", maxHeight: "20px", margin: "5px" }}
              />
              {`${c?.name}          ${c.phone}`}
            </div>
          ),
        };
      })
    );
  }, [DriverSelectService.drivers]);

  const renderFunc = ({ data, innerRef, innerProps }) => {

    if (props?.asLabel) {
      return (
        <div>{data.name}</div>
      )
    }

    return (
      <div ref={innerRef} {...innerProps}>
        <Table style={{ margin: "0px" }} className="dropdown-hover">
          <thead style={{ margin: "0px" }}>
            <tr style={{ margin: "0px" }}>
              <td className="col-3 py-3">
                <img
                  src={data?.pic}
                  alt={data.name}
                  style={{
                    display: "inline",
                    maxHeight: "20px",
                    margin: "0px 5px",
                  }}
                />
              </td>
              <td className="col-6 py-3">{`${data.name}`}</td>
              <td className="col-3 py-3">{`${data.phone}`}</td>
            </tr>
          </thead>
        </Table>
      </div>
    );
  };



  return (
    <React.Fragment>
      {modal ? (
        <Modal
          onChange={() => {
            setModal(false);
          }}
        >
          <Driver
            insidePane={true}
            selectMode={true}
            onSelect={(v) => {
              DriverSelectService.set(v);
              setModal(false);
              props?.onChange({ value: v[0]?._id });
            }}
          />
        </Modal>
      ) : null}

      {drivers.length > 0 && (
        <SelectField
          changeOptionsData={(e) => {
            e.preventDefault();
            setModal(true);
          }}
          bottomBarChildren={() => (
            <Button
              color="primary"
              className="btn btn-primary btn-lg btn-block waves-effect waves-light mb-1 px-2 pb-1"
              style={{ fontSize: 12 }}
            >
              Manage Driver
            </Button>
          )}
          className={props?.className}
          showBottomBar={true}
          onInputChange={(v) => {
            if (v.length > 1) fetch(v);
          }}
          render={renderFunc}
          // onChange={(v) => {
          //   v.value ? props?.onChange(v) : props?.onChange({value : null})
          // }}
          onChange={props?.onChange}
          data={drivers}
          value={props?.value}

          label={"Driver"}
          error={props?.value ? "Please Select Driver" : ""}
          showErr={props?.showErr}
          inline={props?.inline}
          borderNone={props?.borderNone}
          RightComponent={() => (
            <div
              style={{ height: "100%" }}
              className="d-flex align-items-center justify-content-center"
            >
              {" "}
              <i
                role="button"
                className="mdi mdi-magnify text-dark font-size-14 mx-1"
              />
            </div>
          )}
          onClickRight={(e) => {
            e.preventDefault();
            setModal(true);
          }}
        />
      )}

      {!(drivers?.length > 0) && (
        <SelectField
          className={props?.className}
          onChange={props?.onChange}
          value={props?.value}

          label={"Driver"}
          isDisabled={true}
          error={props?.value?.length === 0 ? "Please Select Driver" : ""}
          showErr={props?.showErr}
          inline={props?.inline}
          borderNone={props?.borderNone}
          RightComponent={props?.RightComponent}
          onClickRight={props?.onClickRight}
        />
      )}
    </React.Fragment>
  );
};

export default observer(DriverSelect);
