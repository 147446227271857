import React, { useEffect, useState } from 'react';
import SaveButton from '../Buttons/SaveButton';
import DraggableBox from '../Draggable/Draggable';
import ModalComponent from '../Modal/Modal';

function DataDridUIPreferences({ data, isOpen, onClose, value, onSave, onChange, title }) {

    const [dragData, setDragData] = useState(
        {
            'Visible Fields': {
                title: 'Visible Fields',
                items: [],
            },
            'Hidden Fields': {
                title: 'Hidden Fields',
                items: [],
            },
        }
    );

    useEffect(() => {
        const visibleItems = value.map((item, index) => ({
            ...item,
            id: `selected-${index + 1}`,
            subtitle: item.title,
        }));


        const hiddenItems = data
            .filter(item => !value.some(val => val.title === item.title))
            .map((item, index) => ({
                ...item,
                id: `hidden-${index + 1}`,
                subtitle: item.title,
            }));


        setDragData({
            'Visible Fields': {
                title: 'Visible Fields',
                items: visibleItems,
            },
            'Hidden Fields': {
                title: 'Hidden Fields',
                items: hiddenItems,
            },
        });
    }, [data, value])

    return (
        <ModalComponent size={"lg"} onToggle={onClose} scrollable centered={true} className='modalContainer'
            modalSizeStyle={{ top: "0px" }}
            isOpen={isOpen}  >
            <ModalComponent.Title><h4>{title}</h4></ModalComponent.Title>
            <ModalComponent.Body >
                <DraggableBox
                    value={dragData}
                    onChange={(v) => setDragData(v)}
                    showArrows
                />
            </ModalComponent.Body>
            <ModalComponent.Footer>
                <SaveButton className="primary-dark-bluish" style={{ height: "36px" }} onClick={() => onSave(dragData['Visible Fields']?.items)} />
            </ModalComponent.Footer>
        </ModalComponent>
    )
}

export default DataDridUIPreferences