import moment from "moment";
import { Link } from "react-router-dom";
export const GridColumns = {
  version: 1,
  columns: [

    {
      title: "At",
      accessor: "at",
      renderer: (dataObj) => {
        if (dataObj?.at) {
          const timestamp = dataObj?.at;
          const date = moment.unix(timestamp);
          const formattedDateTime = date.format('DD-MM-YYYY, hh:mm A');

          return (
            <div>
              {formattedDateTime}
            </div>
          );
        } else {
          return null;
        }
      },
      show: true,
    },
    {
      title: "Record Type",
      accessor: "recordType",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.recordType}
            <Link to={`/${dataObj?.recordType}`}>   <i className="fas fa-external-link-alt" style={{ marginLeft: '7px' }}></i></Link>
          </div>
        );
      },
      show: true,
    },
    {
      title: "By",
      accessor: "byType",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.byType == 1 ? 'Customer' : dataObj?.byType == 2 ? 'Driver' : dataObj?.byType == 3 ? 'Attendant' : dataObj?.byType == 4 ? 'Booker' : dataObj?.byType == 5 ? 'Admin' : 'System'}

          </div>

        );
      },
      show: true,
    },
    {
      title: "For",
      accessor: "forType",
      renderer: (dataObj) => {
        const forTypeDescriptions = {
          1: "Pax",
          2: "Driver",
          3: "Attendant",
          4: "Booker",
          5: "Admin",
        };

        const forTypeValues = dataObj.forType || [];
        const forTypeLabels = forTypeValues.map((type) => forTypeDescriptions[type]).join(', ');

        return (
          <div>
            {forTypeLabels}
          </div>
        );
      },
      show: true,
    }
    ,

    {
      title: "Remark",
      accessor: "content.remark",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.content?.remark}
          </div>

        );
      },
      show: true,
    },

  ],
};
