import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import range from 'lodash/range';
import moment from 'moment';
import { getMonth, getYear } from "date-fns";
import "./style.css"

const years = range(1940, parseInt(moment().format('YYYY')) + 20, 1);
const months = [
  "January", "February", "March", "April", "May", "June", "July", "August",
  "September", "October", "November", "December"
];

const CustomHeader = ({
  date, changeYear, changeMonth, decreaseMonth, increaseMonth,
  prevMonthButtonDisabled, nextMonthButtonDisabled,
}) => (
  <div className='d-flex align-items-center justify-content-center gap-1 m-2'>
    <button
      style={{ padding: '2px 0' }}
      className='border-0 bg-light px-2 rounded-2'
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
    >
      {"<"}
    </button>
    <select
      className='border-0 bg-light py-1 px-2 rounded-2'
      value={getYear(date)}
      onChange={({ target: { value } }) => changeYear(value)}
    >
      {years.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

    <select
      className='border-0 bg-light py-1 px-2 rounded-2'
      value={months[getMonth(date)]}
      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
    >
      {months.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

    <button
      style={{ padding: '2px 0' }}
      className='border-0 bg-light px-2 rounded-2'
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
    >
      {">"}
    </button>
  </div>
);

const yyyymmddToDateFormat = (yyyymmdd) => {
  if (!yyyymmdd || yyyymmdd.length !== 8) return null;
  const year = yyyymmdd.slice(0, 4);
  const month = parseInt(yyyymmdd.slice(4, 6), 10) - 1; // month is 0-indexed in JavaScript Date
  const day = yyyymmdd.slice(6, 8);
  return new Date(year, month, day);
};

export const dateFormatToYYYYMMDD = (date) => {
  if (!date) return '';
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return year + month + day;
};

export default function DateSelectCustom({
  value, onChange, isDisabled, placeholder, outline, label, error, showErr, className, required, minDate, maxDate
}) {
  const [startDate, setStartDate] = useState(() => yyyymmddToDateFormat(value));
  const [inputError, setInputError] = useState('');

  useEffect(() => {
    setStartDate(yyyymmddToDateFormat(`${value}`));
  }, [value]);

  const handleDateChange = (date) => {
    setStartDate(date);
    const formattedDate = dateFormatToYYYYMMDD(date);
    onChange(formattedDate);
    setInputError(''); // Reset error on user interaction
  };

  const handleBlur = () => {
    if (!startDate) setInputError(error);
  };

  const today = new Date();

  return (
    <div className={`d-flex flex-1 w-100 flex-column ${className}`}>
      <div className={`d-flex flex-1 w-100 align-items-center`}>


        {outline ? (
          <label className='outline_label px-1'>
            {!startDate ? "" : label ?? ("Date")}
            {required && <font color="red">*</font>}
          </label>
        ) :
          <div
            style={{ width: "120px", fontSize: 12, fontWeight: "500" }}
          >
            {label ?? ("Date")}
            {required && <font color="red">*</font>}

          </div>}


        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          onBlur={handleBlur}
          placeholderText={placeholder}
          dateFormat="dd-MM-yyyy"
          disabled={isDisabled}
          className={`${outline ? "outline-container" : "form-control"} my-form-input flex-1 w-100`} // Add your form input class here
          wrapperClassName="date-picker react-datepicker-wrapper flex-1 w-100 mb-1"
          renderCustomHeader={CustomHeader}
          minDate={minDate}
          maxDate={maxDate} 
        />
      </div>

      {showErr && inputError && (
        <em className="inputfield__error">
          {inputError ? inputError : "Please enter Something"}
        </em>
      )}
    </div>
  );
}
