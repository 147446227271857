import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FaCalendarAlt, FaHotel, FaInfoCircle, FaMapMarkerAlt, FaUserTie, FaUsers } from "react-icons/fa";
import { Button, FormGroup } from "reactstrap";
import ClientCompanyGrid from "../../pages/ClientCompanyGrid/ClientCompanyGrid";
import Filter from "../../pages/Filter/Filter";
import { isPickerTypeEvent } from "../../pages/Rostering/utils/helper";
import Supervisor from "../../pages/Supervisor/Supervisor";
import User from "../../pages/Users/User";
import Vehicle from "../../pages/Vehicle/Vehicle";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import AddressField from "../AddressField/AddressField";
import Tab from "../Common/Tab/Tab";
import TimeSelect from "../DateSelects/TimeSelect";
import DateSelectCustom from "../DateTimePickerWithInterval/DateSelectCustom";
import Documents from "../DocUpload/Documents";
import ClientSelect from "../Dropdowns/ClientSelect/ClientSelect";
import CompanySelect from "../Dropdowns/Entity/CompanySelect";
import OfficeSelect from "../Dropdowns/Office/OfficeSelect";
import ShiftSelect from "../Dropdowns/ShiftSelect/ShiftSelect";
import { InputField } from "../InputField/InputField";
import ModalComponent from "../Modal/Modal";
import Editor from "../dnd/forms/itinerary/components/RichTextEditor/Editor/Editor";
import SpaceService from "./SpaceService";
import RosterTypeSelection from "./components/RosterTypeSelection";
import SpaceRenderer from "./components/SpaceRenderer";
import SpaceTypeSelection from "./components/SpaceTypeSelection";
import Places from "./events/places/Places";
import "./style.scss";
import { TbLayoutSidebarLeftCollapse } from "react-icons/tb";

const Space = observer(({ toggleCollapse, isCollapsable, filterStructure, module, type = null, showEverything = true, showAsDropdown = false }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { showMessage, showError } = useContext(DialogContext);
  const { userContent } = useContext(UserContext);
  const [eventLoading, setEventLoading] = useState(true);
  const [spaceData, setSpaceData] = useState({
    name: '',
    fromDate: '',
    tillDate: '',
    places: [],
    customers: [],
    suppliers: [],
    vehicles: [],
    spaceType: -1,//event or rostering
    type: -1.//0 Event,1:Group Booking ,no2 = "Regular pickup",   no3 = "Regular drop",   no4 = "Adhoc pickup",  no5 = "Adhoc drop"
  });
  const [parentSpaceData, setParentSpaceData] = useState({});
  const [viewData, setViewData] = useState({
    name: '',
    supervisors: [],
    filterUrl: '',
  });

  useEffect(() => {
    const fetchParentSpaceData = async (parentSpace_id) => {
      try {
        const parentSpace = SpaceService?.records?.find(v => v?._id === parentSpace_id);
        if (parentSpace?._id && parentSpace?.children?.length) {
          const { children, ...parentSpacePayload } = parentSpace;
          setParentSpaceData(parentSpacePayload);
        }
      } catch (error) {
        showError(error);
      }
    };

    if (spaceData?.parentSpace_id) {
      fetchParentSpaceData(spaceData?.parentSpace_id);
    } else {
      setParentSpaceData({});
    }
  }, [spaceData?.parentSpace_id]);

  useEffect(() => {
    const fetch = async (module, type) => {
      await SpaceService.fetch(module, type, showEverything);
      SpaceService.updateDataFromLocalStorage(module);
      if (type) {
        setSpaceData(prev => ({ ...prev, type }));
      }
    };

    if (module) {
      fetch(module, type);
    }
  }, [module, type]);

  useEffect(() => {
    return () => {
      SpaceService?.toggleRendered(false)
    }
  }, [])

  const handleCreateSpace = async (data) => {
    try {
      const parentSpace_id = await SpaceService.save({ module, ...data });
      // const childResponse = await SpaceService.save({ module, name: "sample 1", parentSpace_id }, true);
      SpaceService.setSelectedFolder(parentSpace_id, module);
      return parentSpace_id;
    } catch (error) {
      showError(error);
    }
  };


  const handleStartCreatingSpace = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setCurrentStep(1);
    setSpaceData({
      name: '',
      fromDate: '',
      tillDate: '',
      places: [],
      customers: [],
      suppliers: [],
      vehicles: [],
    });
    setViewData({
      name: '',
      supervisors: [],
      filterUrl: '',
    });
  };

  const handleCreateEvent = async () => {
    setEventLoading(true);
    try {
      if (spaceData?._id) {
        await SpaceService.edit({ ...(spaceData ?? {}) });
        showMessage("Event Edited Successfully")
        // handleModalClose();
      } else {
        const parent_id = await handleCreateSpace(spaceData);
        showMessage("Event Created Successfully");
        setSpaceData(prev => ({ ...prev, _id: parent_id, module }));
      }
    } catch (error) {
      showError(error);
    } finally {
      setEventLoading(false);
    }
  };

  const queryStringToObject = (queryString) => {
    const params = new URLSearchParams(queryString);
    const result = {};
    for (const [key, value] of params.entries()) {
      result[key] = value;
    }
    return result;
  };

  const objectToQueryString = (obj) => {
    const params = new URLSearchParams();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        params.append(key, obj[key] || '');
      }
    }
    return params.toString();
  };

  const prepareFilterStructure = () => {
    if (parentSpaceData?.filterUrl) {
      const filterObject = queryStringToObject(parentSpaceData.filterUrl);
      return filterStructure?.filter(v => !filterObject.hasOwnProperty(v.filterName));
    } else {
      return filterStructure;
    }
  };

  const renderEventStep = (step) => {
    switch (step) {
      case 1:
        return (
          <FormGroup>
            {!spaceData?._id && (
              <div className="todo-section">
                <h3><FaInfoCircle className="fa-icon" /> Event Creation Guide</h3>
                <ul>
                  <li><FaCalendarAlt className="fa-icon" /> <strong className="me-2">Specify Dates:</strong> Select the start and end dates for your event.</li>
                  <li><FaUsers className="fa-icon" /> <strong className="me-2">Guests:</strong> Add the guests who will be attending the event.</li>
                  <li><FaHotel className="fa-icon" /> <strong className="me-2">Hotels:</strong> Choose the hotels where guests will stay.</li>
                  <li><FaMapMarkerAlt className="fa-icon" /> <strong className="me-2">Places:</strong> List the places to be visited during the event.</li>
                  <li><FaUserTie className="fa-icon" /> <strong className="me-2">Supervisors:</strong> Assign supervisors to manage the event.</li>
                </ul>
                <p>Make sure to fill in all the details to create a comprehensive event plan. The more information you provide, the better the event experience will be for everyone involved.</p>
              </div>
            )}

            {!spaceData?._id ?
              <SpaceTypeSelection
                value={spaceData?.spaceType}
                isDisabled={spaceData?._id}
                onChange={(value) => setSpaceData({
                  ...spaceData,
                  type: [0, 1]?.includes(value) ? value : -1,
                  spaceType: value
                })}
              /> : null}


            {spaceData?.spaceType == 'roster' && <RosterTypeSelection value={spaceData?.type} onChange={(value) => setSpaceData({ ...spaceData, type: value })} />}

            {(((spaceData?.spaceType) || (spaceData?.spaceType == 0)) && spaceData?.spaceType != -1) ? <>
              <div className="row">



                <div className="col-12 col-md-6">
                  {spaceData?.spaceType == 'roster' && <OfficeSelect
                    required={true}
                    corp_id={userContent?.corp_id}
                    value={spaceData?.office_id}
                    onChange={(value) => setSpaceData(prev => ({ ...prev, office_id: value?.value }))}
                    className="col-md-12 col-12"
                  />}
                  {spaceData?.spaceType == 'roster' && userContent?.accountType !== 'client' && <div className="d-flex w-100 row flex-1 col-12">

                    <div className="col-6 pe-0 ">
                      <ClientSelect
                        value={spaceData?.client_id}
                        onChange={(v) => {
                          setSpaceData(prevSpace => ({
                            ...prevSpace,
                            client_id: v?.value
                          }));
                        }}
                        innerContainerClassName="input-group-pre"
                        className=" me-0 ms-0 w-100 "
                      />
                    </div>

                    {<div className="col-sm-6 p-0">
                      <CompanySelect
                        className=" w-100"
                        outline
                        corpType="client"
                        isDisabled={!spaceData?.client_id}
                        value={spaceData?.company_id}
                        onChange={(v) => {
                          setSpaceData(prevSpace => ({
                            ...prevSpace,
                            company_id: v?.value
                          }));
                        }}
                        innerContainerClassName="input-group-post"
                        label="Client Company"
                        corp_id={spaceData?.client_id}
                      />
                    </div>
                    }
                  </div>}

                  {spaceData?.spaceType == 'roster' && <ShiftSelect
                    required={true}
                    value={spaceData?.shift_id}
                    onChange={(value) => setSpaceData(prev => ({ ...prev, shift_id: value?.value }))}
                    className="col-md-12 col-12"
                  />}
                  <InputField
                    label="Space Name"
                    placeholder="Space Name"
                    required
                    value={spaceData.name}
                    onChange={(e) => setSpaceData({ ...spaceData, name: e })}
                  />
                  <DateSelectCustom
                    placeholder={("dd-mm-yyyy")}
                    label={`${[0, 1]?.includes(spaceData?.spaceType) ? "From " : ""} Date`}
                    value={spaceData.fromDate}
                    required={[0, 1]?.includes(spaceData?.spaceType) ? false : true}
                    onChange={(e) => setSpaceData({ ...spaceData, fromDate: e })}
                    className="col-sm-12 col-12"
                    minDate={moment(parentSpaceData?.fromDate, "YYYYMMDD").toDate()}
                    maxDate={moment(parentSpaceData?.tillDate, "YYYYMMDD").toDate()}
                  />
                  {[0, 1]?.includes(spaceData?.spaceType) && <DateSelectCustom
                    placeholder={("dd-mm-yyyy")}
                    label="Till Date"
                    value={spaceData.tillDate}
                    onChange={(e) => setSpaceData({ ...spaceData, tillDate: e })}
                    className="col-sm-12 col-12"
                    minDate={moment(parentSpaceData?.fromDate, "YYYYMMDD").toDate()}
                    maxDate={moment(parentSpaceData?.tillDate, "YYYYMMDD").toDate()}
                  />}
                  {[0]?.includes(spaceData?.spaceType) && <div className="">
                    <div style={{ color: "#000", marginBottom: "0.2rem", }}>{"Event Location"}</div>
                    <AddressField
                      placeholder="address"
                      label="Address"
                      outline
                      value={spaceData?.eventLocation}
                      className={"w-100"}
                      onChange={(e) => setSpaceData({ ...spaceData, eventLocation: e })}
                    />
                  </div>}

                  {[1]?.includes(spaceData?.spaceType) && <div className="d-flex align-items-center">
                    <div style={{ width: "120px", fontSize: 12, fontWeight: "500", marginRight: 0 }}>
                      {"Client"}
                      <font color="red">*</font>
                    </div>
                    <div className="col-5 px-0">
                      <ClientSelect
                        value={spaceData?.client_id}
                        onChange={(v) => setSpaceData({ ...spaceData, client_id: v?.value, company_id: null })}
                        innerContainerClassName="input-group-pre"
                        className="mx-0 w-100"
                        label="Client"
                        outline
                      />
                    </div>
                    <div className="col-sm-5 p-0">
                      <CompanySelect
                        className="ms-2 w-100"
                        outline
                        corpType="client"
                        isDisabled={!spaceData?.client_id}
                        value={spaceData?.company_id}
                        onChange={(v) => setSpaceData({ ...spaceData, company_id: v?.value })}
                        innerContainerClassName="input-group-post"
                        label="Client Company"
                        placeholder="Client Company"
                        corp_id={spaceData?.client_id}
                      />
                    </div>

                  </div>}



                  {spaceData?.spaceType == 'roster' && <TimeSelect
                    placeholder={isPickerTypeEvent(spaceData?.type) ? 'Drop Time' : 'Pickup Time'}
                    label={isPickerTypeEvent(spaceData?.type) ? 'Drop Time' : 'Pickup Time'}
                    className=""
                    value={isPickerTypeEvent(spaceData?.type) ? spaceData?.dropTme : spaceData?.pickUpTme}
                    onChange={(v) => {
                      setSpaceData({ ...spaceData, [isPickerTypeEvent(spaceData?.type) ? 'dropTme' : 'pickUpTme']: v })
                    }}
                  />}


                  {[0]?.includes(spaceData?.spaceType) && <div className="mt-2">
                    <div style={{ color: "#000", marginBottom: "0.2rem", }}>{"Event Description"}</div>
                    <textarea
                      className="inputfield"
                      rows={5}
                      style={{ borderRadius: "0.175rem", border: "1px solid #ced4da", outline: "none" }}
                      value={spaceData?.description}
                      onChange={(e) => {
                        setSpaceData({ ...spaceData, description: e.target.value });
                      }}
                    >
                    </textarea>
                  </div>}

                  <div className="col-12  my-3 ">
                    <Editor
                      placeholder="Notes"
                      value={spaceData.notes}
                      onChange={(htmlContent) => setSpaceData({ ...spaceData, notes: htmlContent })}
                    />
                  </div>
                  {spaceData?._id && <div className="col-12">
                    <Documents module="space" record_id={spaceData?._id} />
                  </div>}



                </div>




                {/* {([0, 1]?.includes(spaceData?.spaceType) && parentSpaceData?.filterUrl) && <div className="my-2 d-flex align-items-center">
                  <div style={{ width: "120px", fontSize: 12, fontWeight: "500", marginRight: 0 }}>
                    Parent Filter
                    <font color="red">*</font>
                  </div>
                  <Filter
                    hideSearch
                    className="d-flex"
                    outerClassName={"w-100 ps-1"}
                    filledStyle={{  }}
                    value={queryStringToObject(parentSpaceData?.filterUrl ?? "")}
                    structure={filterStructure}
                    applyFunc={(v) => { }}
                    onChange={() => { }}
                    isDisabled
                  />
                </div>}

                {[0, 1]?.includes(spaceData?.spaceType) && <div className="my-2 d-flex align-items-center">
                  <div style={{ width: "120px", fontSize: 12, fontWeight: "500", marginRight: 0 }}>
                    Filter
                    <font color="red">*</font>
                  </div>
                  <Filter
                    hideSearch
                    outerClassName={"w-100 ps-1"}
                    className="d-flex"
                    filledStyle={{ }}
                    value={queryStringToObject(spaceData?.filterUrl ?? "")}
                    structure={prepareFilterStructure()}
                    applyFunc={(v) => { }}
                    onChange={(v) => { setSpaceData({ ...spaceData, filterUrl: objectToQueryString(v) }) }}
                  />
                </div>} */}




              </div>
            </> : null}
          </FormGroup>
        );
      case 5:
        return (
          <Places
            places={spaceData.places}
            setPlaces={(places) => setSpaceData({ ...spaceData, places })}
          />
        );
      case 4:
        return (
          <FormGroup>
            <ClientCompanyGrid
              value={spaceData.suppliers}
              insidePane={true}
              multiMode
              supplier_id
              filterUrlProp={parentSpaceData.suppliers?.length ? `_id=in[${parentSpaceData.suppliers?.join(",")}]` : ""}
              showOnly={parentSpaceData?.suppliers}
              breadcrumb={"Select Supplier"}
              onlySelect
              onSelect={(selectedValues) =>
                setSpaceData({ ...spaceData, suppliers: selectedValues.map((c) => c?.value ?? c) })
              }
            />
          </FormGroup>
        );
      case 3:
        return (
          <FormGroup>
            <Vehicle
              value={spaceData.vehicles}
              insidePane={true}
              breadcrumb={"Select Vehicles"}
              multiMode
              filterUrlProp={parentSpaceData.vehicles?.length ? `_id=in[${parentSpaceData.vehicles?.join(",")}]` : ""}
              showOnly={parentSpaceData.vehicles?.length ? parentSpaceData.vehicles : []}
              onSelect={(selectedValues) =>
                setSpaceData({ ...spaceData, vehicles: selectedValues.map((c) => c?.value ?? c) })
              }
            />
          </FormGroup>
        );
      case 2:
        return (
          <FormGroup>
            <User
              hideToggle
              value={spaceData.customers}
              insidePane={true}
              formConfig={{
                showAll: true
              }}
              filterUrlProp={parentSpaceData.customers?.length ? `_id=in[${parentSpaceData.customers?.join(",")}]` : ""}
              breadcrumb={"Select Guests"}
              multiMode
              onSelect={(selectedValues) =>
                setSpaceData({ ...spaceData, customers: selectedValues.map((c) => c?.value ?? c) })
              }
            />
          </FormGroup>
        );
      case 6:
        return (
          <FormGroup>
            <Supervisor
              hideToggle
              value={spaceData.supervisors}
              insidePane={true}
              breadcrumb={"Select Supervisors"}
              multiMode
              onSelect={(selectedValues) =>
                setSpaceData({ ...spaceData, supervisors: selectedValues.map((c) => c?.value ?? c) })
              }
            />
          </FormGroup>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={`  ${!showAsDropdown ? "p-2 zr_space_sidebar_container " : ""}`} style={{ maxWidth: '180px' }}>
        {!showAsDropdown && <div className='w-100 d-flex justify-content-between align-items-center ' style={{ paddingLeft: `10px` }}>
          <span className="text-secondary fw-normal fs-6">Spaces</span>
          <>{isCollapsable ? <TbLayoutSidebarLeftCollapse size={20} onClick={toggleCollapse} /> : null}      </>
        </div>}
        <SpaceRenderer
          spaces={[
            ...(showEverything ? [{ module, name: "Everything", _id: null, type: "single" }] : []),
            ...(SpaceService.records ?? [])
          ]}
          module={module}
          useDropdown={showAsDropdown}
          setSpaceData={setSpaceData}
          setCurrentStep={setCurrentStep}
          setModalOpen={setModalOpen}
          handleStartCreatingSpace={handleStartCreatingSpace}
          isCollapsable={isCollapsable}
          onSpaceCollapse={() => toggleCollapse()}
        />
      </div>

      <ModalComponent
        position="top"
        size="full-screen"
        onToggle={handleModalClose}
        isOpen={modalOpen}
      >
        <ModalComponent.Title toggle={handleModalClose}>
          <div className="d-flex">
            {spaceData?._id ? "Edit" : "Create"} Space
          </div>
        </ModalComponent.Title>
        <ModalComponent.Body stretch>
          {spaceData._id ? (
            <Tab
              vertical={false}
              left
              outerClassName="mt-0 pt-0"
              activeTab={currentStep}
              onChange={setCurrentStep}
              className="d-flex"
            >
              <div num={1} label="Details">{renderEventStep(1)}</div>
              <div num={2} label="Guests">{renderEventStep(2)}</div>
              <div num={3} label="Vehicles">{renderEventStep(3)}</div>
              <div num={4} label="Suppliers">{renderEventStep(4)}</div>
              <div num={5} label="Places">{renderEventStep(5)}</div>
              <div num={6} label="Supervisors">{renderEventStep(6)}</div>
            </Tab>
          ) : (
            renderEventStep(1)
          )}
        </ModalComponent.Body>
        <ModalComponent.Footer>
          <Button color="primary" onClick={handleCreateEvent}>
            {spaceData?._id ? "Save" : "Create Space"}
          </Button>
        </ModalComponent.Footer>
      </ModalComponent>
    </>
  );
});

export default Space;
