import React, { useEffect, useState, useContext } from "react";
import SettingsService from "../SettingsService";
import { DialogContext } from "../../../store/context/DialogContext";
import { Col, Form, Row, FormGroup, Label, Input, Table } from "reactstrap";
import { InputField, SaveButton } from "../../../components";

const TariffSettings = () => {
  const [loading, setLoading] = useState(false);
  const { showMessage, showConfirm, showError } = useContext(DialogContext);
  const [data, setData] = useState("");
  const [hasErr, setHasErr] = useState(false);

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await SettingsService.getConfig();
      setData(res);
      setHasErr(false);
    } catch (e) {
      showError(e);
      // console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await SettingsService.saveTariff(data);
      showMessage("Tariff Settings Saved successfully");
      fetchData();
      setLoading(false);
    } catch (error) {
      showError(error);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col
            className="col-12 p-2 ps-4"
            style={{
              backgroundColor: "#EAEAEA",
              fontSize: "15px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <header>Tariff Settings</header>
          </Col>
          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="tariffAutoApprove"
                name="tariffAutoApprove"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    tariffAutoApprove: !data?.tariffAutoApprove,
                  });
                }}
                checked={data?.tariffAutoApprove}
              />
              <Label className="ps-2 pt-1" check for="tariffAutoApprove">
                Auto Approve Tariff
              </Label>
            </FormGroup>
          </Col>
          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="diffSrcDstGarage"
                name="diffSrcDstGarage"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    diffSrcDstGarage: !data?.diffSrcDstGarage,
                  });
                }}
                checked={data?.diffSrcDstGarage}
              />
              <Label className="ps-2 pt-1" check for="diffSrcDstGarage">
                Allow Different Source and Destination Garage
              </Label>
            </FormGroup>
          </Col>
          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="zoneRequired"
                name="zoneRequired"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    zoneRequired: !data?.zoneRequired,
                  });
                }}
                checked={data?.zoneRequired}
              />
              <Label className="ps-2 pt-1" check for="zoneRequired">
                Zone Required
              </Label>
            </FormGroup>
          </Col>

          <Col
            className="col-12 p-2 ps-4"
            style={{
              backgroundColor: "#EAEAEA",
              fontSize: "15px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <header>
              Tariff Create/Edit/Detail Page Configuration
              <span
                style={{ fontSize: "13px" }}
              >{`(Select only those fields which need to be shown on Tariff Create/Edit/Detail Page)`}</span>
            </header>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showTarCatOpt_t"
                name="showTarCatOpt_t"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showTarCatOpt_t: !data?.showTarCatOpt_t });
                }}
                checked={data?.showTarCatOpt_t}
              />
              <Label className="ps-2 pt-1" check for="showTarCatOpt_t">
                Tariff Required
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showVendorBuOpt_t"
                name="showVendorBuOpt_t"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showVendorBuOpt_t: !data?.showVendorBuOpt_t,
                  });
                }}
                checked={data?.showVendorBuOpt_t}
              />
              <Label className="ps-2 pt-1" check for="showVendorBuOpt_t">
                Vendor Business Unit
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showBookingLimOpt_t"
                name="showBookingLimOpt_t"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showBookingLimOpt_t: !data?.showBookingLimOpt_t,
                  });
                }}
                checked={data?.showBookingLimOpt_t}
              />
              <Label className="ps-2 pt-1" check for="showBookingLimOpt_t">
                Serving Business Unit
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showAmmendOpt_t_t"
                name="showAmmendOpt_t_t"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showAmmendOpt_t_t: !data?.showAmmendOpt_t_t,
                  });
                }}
                checked={data?.showAmmendOpt_t_t}
              />
              <Label className="ps-2 pt-1" check for="showAmmendOpt_t_t">
                Market
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showBookingLimOpt_t"
                name="showBookingLimOpt_t"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showBookingLimOpt_t: !data?.showBookingLimOpt_t,
                  });
                }}
                checked={data?.showBookingLimOpt_t}
              />
              <Label className="ps-2 pt-1" check for="showBookingLimOpt_t">
                Booking Limit
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showTaxOpt_t"
                name="showTaxOpt_t"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showTaxOpt_t: !data?.showTaxOpt_t,
                  });
                }}
                checked={data?.showTaxOpt_t}
              />
              <Label className="ps-2 pt-1" check for="showTaxOpt_t">
                Tax(Override)
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showFuelSurOpt_t"
                name="showFuelSurOpt_t"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showFuelSurOpt_t: !data?.showFuelSurOpt_t,
                  });
                }}
                checked={data?.showFuelSurOpt_t}
              />
              <Label className="ps-2 pt-1" check for="showFuelSurOpt_t">
                Fuel Surcharge
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showCancelOpt_t"
                name="showCancelOpt_t"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showCancelOpt_t: !data?.showCancelOpt_t,
                  });
                }}
                checked={data?.showCancelOpt_t}
              />
              <Label className="ps-2 pt-1" check for="showCancelOpt_t">
                Cancellation Charges
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showAmmendOpt_t"
                name="showAmmendOpt_t"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showAmmendOpt_t: !data?.showAmmendOpt_t });
                }}
                checked={data?.showAmmendOpt_t}
              />
              <Label className="ps-2 pt-1" check for="showAmmendOpt_t">
                Ammendment Charges
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showPaymentOpt_t"
                name="showPaymentOpt_t"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showPaymentOpt_t: !data?.showPaymentOpt_t,
                  });
                }}
                checked={data?.showPaymentOpt_t}
              />
              <Label className="ps-2 pt-1" check for="showPaymentOpt_t">
                Payment Terms
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showBlackoutOpt_t"
                name="showBlackoutOpt_t"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showBlackoutOpt_t: !data?.showBlackoutOpt_t,
                  });
                }}
                checked={data?.showBlackoutOpt_t}
              />
              <Label className="ps-2 pt-1" check for="showBlackoutOpt_t">
                Blackout Days
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showDescOpt_t"
                name="showDescOpt_t"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showDescOpt_t: !data?.showDescOpt_t });
                }}
                checked={data?.showDescOpt_t}
              />
              <Label className="ps-2 pt-1" check for="showDescOpt_t">
                Description
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showTncOpt_t"
                name="showTncOpt_t"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showTncOpt_t: !data?.showTncOpt_t,
                  });
                }}
                checked={data?.showTncOpt_t}
              />
              <Label className="ps-2 pt-1" check for="showTncOpt_t">
                Terms And Conditions
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="hideDisposal"
                name="hideDisposal"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, hideDisposal: !data?.hideDisposal });
                }}
                checked={data?.hideDisposal}
              />
              <Label className="ps-2 pt-1" check for="hideDisposal">
                Hide Disposal
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="hideSightseeing"
                name="hideSightseeing"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    hideSightseeing: !data?.hideSightseeing,
                  });
                }}
                checked={data?.hideSightseeing}
              />
              <Label className="ps-2 pt-1" check for="hideSightseeing">
                Hide Sightseeing
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="hidePointToPoint"
                name="hidePointToPoint"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    hidePointToPoint: !data?.hidePointToPoint,
                  });
                }}
                checked={data?.hidePointToPoint}
              />
              <Label className="ps-2 pt-1" check for="hidePointToPoint">
                Hide Point to Point
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="hideSelfDrive"
                name="hideSelfDrive"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, hideSelfDrive: !data?.hideSelfDrive });
                }}
                checked={data?.hideSelfDrive}
              />
              <Label className="ps-2 pt-1" check for="hideSelfDrive">
                Hide Self Drive
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="hideFixedRoute"
                name="hideFixedRoute"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, hideFixedRoute: !data?.hideFixedRoute });
                }}
                checked={data?.hideFixedRoute}
              />
              <Label className="ps-2 pt-1" check for="hideFixedRoute">
                Hide Fixed Route
              </Label>
            </FormGroup>
          </Col>
          <footer className="col-12">
            <SaveButton />
          </footer>
        </Row>
      </Form>
    </div>
  );
};

export default TariffSettings;
