import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditMessageTemplate from "./EditMessageTemplate";
import { STRUCTURE } from "./MessageTemplateConstant";
import { GridColumns } from "./MessageTemplateGrid";
import MessageTemplateService from "./MessageTemplateService";


const MessageAgent = ({ insidePane }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/messagetemplate/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)


  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await MessageTemplateService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
  }, [])

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      if (editID) {
        await MessageTemplateService.edit(detailData, editID);
        showMessage(t("Record updated successfully."));
      } else {
        const ID = await MessageTemplateService.save(detailData);
        showMessage(t("Record saved successfully."));
        if (!insidePane) navigate(`/messagetemplate/edit/${ID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setHasErr(false)
    setLoading(false);
  };



  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await MessageTemplateService.delete(id);
        showMessage("Deleted", "Deleted");
        navigate('/messagetemplate')
        setShowDetailPage(false);
      } catch (e) {
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback((messgtemp) =>{
    return (
      <>
        <EditLink
          onClick={() => {
            if (!insidePane)
              navigate(`/messagetemplate/edit/${messgtemp?._id}`);
            setEditID(messgtemp?._id);
            setShowDetailPage(true);
          }}
        />
        <DeleteLink
          onClick={(event) => onDelete(event, messgtemp?._id)}
        />
      </>
    );

  },[insidePane])

  return (
    <>
      <Layout
        medium
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/messagetemplate");
          setEditID(null);
        }}
         
        title={t("Message Template")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/messagetemplate/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={MessageTemplateService.page}
        rows={MessageTemplateService.rows}
        total={MessageTemplateService.total}
        fetch={MessageTemplateService.fetch}
      >
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={MessageTemplateService.records}
              total={MessageTemplateService.total}
              uiPreference="agent.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={setSelectedIDs}
              page={MessageTemplateService.page}
              rowsPerPage={MessageTemplateService.rowsPerPage}
              onPaginationChange={MessageTemplateService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </>
        </Layout.Table>

        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit Message Template") : t("Add Message Template")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditMessageTemplate
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}

          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {
            detailData?._id ? 
          <DeleteButton loading ={loading} onClick={(e) => onDelete(e, detailData?._id)} />: null}
          <SaveButton loading ={loading} onClick={onSave} />
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(MessageAgent);
