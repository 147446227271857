import WeekdaySelector from "../../components/WeekDaySelect/WeekDaySelect";

import moment from "moment";
export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Name",
      accessor: "name",
      show: true,
    },

    {
      title: "Start Time",
      accessor: "startTime",
      renderer: (dataObj) => {
        const timeStr = dataObj?.startTime?.toString();
        const formattedTime = moment(timeStr, 'Hmm').format('hh:mm A')
        return <div>
          {formattedTime}
        </div>;
      },
      show: true,
    },

    {
      title: "End Time",
      accessor: "endTime",
      renderer: (dataObj) => {
        const timeStr = dataObj?.endTime?.toString();
        const formattedTime = moment(timeStr, 'HHmm').format('hh:mm A')
        return <div>
          {formattedTime}
        </div>;
      },
      show: true,
    },
    {
      title: "Days",
      accessor: "weekDays",
      renderer: (dataObj) => {
        return (
          <div>
            {
              <WeekdaySelector
                data={dataObj?.weekDays}
                disabled={true}
                noLabel
              />
            }
          </div>
        );
      },
      width: 400,
      show: true,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      show: true,
      width: 90,
      required: true,
    },
  ],
};
