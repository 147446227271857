import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditPermits from "./EditPermit";
import { STRUCTURE } from "./PermitConstant";
import { GridColumns } from "./PermitGridColumns";
import PermitService from "./PermitService";

const Permits = ({ value, permitNameData, insidePane, multiMode, onSelect = () => { }, permitOf, assetKind, hideFilterBar, hideCheckBox = false, permintKind, permitTitle }) => {

    let { permit_id } = useParams();
    const location = useLocation();
    const { userContent } = useContext(UserContext);


    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(permit_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/permit/create" || permit_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)


    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await PermitService.fetch(filterUrl);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        let { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        if (permitOf) {
            filterObject = { ...filterObject, permitOf: permitOf, assetKind: assetKind }
            filterUrl += `permitOf=${permitOf}&assetKind=${assetKind}`;

        }
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs([value])
    }, [permitOf, assetKind, editID])

    useEffect(() => {
        setEditID(permit_id)
    }, [permit_id])


    const onSave = async (e) => {
        e.preventDefault();
        if (!(detailData?.name && detailData?.issuingAuthority)) {
            setHasErr(true)
            showError(t("Please enter all fields"));
            return;
        }
        setLoading(true);
        try {
            if (editID) {
                await PermitService.edit(detailData, editID);
                showMessage(t("Permit updated successfully."));

            } else {
                const permit = await PermitService.save(detailData);
                if (permit) setEditID(permit)
                showMessage(t("Permit saved successfully."));
                if (!insidePane) navigate(`/permit/edit/${permit}`);
            }
            fetchData(filterURL);
        } catch (e) {
            showError(e);
        }
        setHasErr(false)
        setLoading(false);
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            try {
                await PermitService.delete(id, permitOf, assetKind);
                showMessage("Permit Deleted Successfully", "Deleted");
            } catch (e) {
                showError(e);
            }
        }
    };

    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            try {
                await PermitService.bulkDelete(selectedIDs);
                showMessage("Deleted", "Deleted Successfully");
            } catch (e) {
                showError(e);
            }
        }
    };

    return (
        <div className="container">
            <Layout
                medium
                hideAdd={!userContent?.rights?.includes(1501)}
                showDetailPage={showDetailPage}
                backDetailPage={async () => {
                    setShowDetailPage(false);
                    if (!insidePane) navigate("/permit");
                    setEditID(null);
                }}

                title={t("Permits")}
                filterValues={filterObject}
                filterStructure={STRUCTURE}
                onApplyFilter={fetchData}
                onAddClick={() => {
                    if (!insidePane) navigate(`/permit/create`);
                    setShowDetailPage(true);
                    setEditID(null);
                }}
                insidePane={insidePane}
                page={PermitService.page}
                rows={PermitService.rows}
                total={PermitService.total}
                fetch={PermitService.fetch}
                hideSearch={hideFilterBar}
            >
                <Layout.ActionMenu>
                    <div className="layout-action-menu">
                        <DropdownMenu>
                            <>
                                <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                                <div class="dropdown-divider"></div>
                                <DropdownItem>{t("Upload")}</DropdownItem>
                                <div class="dropdown-divider"></div>
                                <DropdownItem> {t("Download")} </DropdownItem>
                            </>
                        </DropdownMenu>
                    </div>
                </Layout.ActionMenu>
                <Layout.Table>
                    <DataGrid
                        gridLoading={loading}
                        data={PermitService.records}
                        total={PermitService.total}
                        uiPreference="permit.grid"
                        headers={GridColumns}
                        selectedIDs={selectedIDs}
                        onSelectChange={(v) => {
                            onSelect(v)
                            setSelectedIDs(v)
                        }}
                        page={PermitService.page}
                        rowsPerPage={PermitService.rowsPerPage}
                        onPaginationChange={PermitService.onPaginationChange}
                        renderLastCol={(permit) => {
                            return (
                                <>
                                    <EditLink
                                        onClick={() => {
                                            if (!insidePane)
                                                navigate(`/permit/edit/${permit?._id}`);
                                            setEditID(permit?._id);
                                            setShowDetailPage(true);
                                        }}
                                    />

                                    <DeleteLink
                                        onClick={(event) => onDelete(event, permit?._id)}
                                    />
                                    {(!multiMode && insidePane && !hideCheckBox) ? (
                                        <CheckButton
                                            onClick={() => {
                                                onSelect(permit?._id);
                                            }}
                                        />
                                    ) : null}
                                </>
                            );
                        }}
                    />
                </Layout.Table>
                <Layout.DetailPageTitle>
                    {detailData?._id ? "Edit Permit" : "Add Permit"}
                </Layout.DetailPageTitle>

                <Layout.DetailPageBody>
                    <EditPermits
                        permitNameData={permitNameData}
                        permitTitle={permitTitle}
                        permintKind={permintKind}
                        editId={editID}
                        permitOf={permitOf}
                        assetKind={assetKind}
                        onChange={(v) => {
                            setDetailData(v)
                        }}
                        hasErr={hasErr}

                    />
                </Layout.DetailPageBody>

                <Layout.DetailPageFooter>
                    {userContent?.rights?.includes(1503) && detailData?._id ? (

                        <DeleteButton loading={loading} onClick={(e) => onDelete(e, detailData?._id)} />
                    ) : null
                    }
                    {userContent?.rights?.includes(1502) ?


                        <SaveButton loading={loading} onClick={onSave} />
                        : null}

                </Layout.DetailPageFooter>
            </Layout>
        </div>
    );
};
export default observer(Permits);
