import CitySelect from "../CSC/CitySelect/CitySelect";
import CountrySelect from "../CSC/CountrySelect/CountrySelect";
import StateSelect from "../CSC/StateSelect/StateSelect";
import DateSelect from "../DateSelects/DateSelect";
import { InputField } from "../InputField/InputField";

const DataField = ({ type, value, onChange, add, setAdd }) => {
  if (
    type?.toString().toUpperCase() === "STRING" ||
    type?.toString().toUpperCase() === "INTEGER"
  ) {
    return (
      <div className="mt-2">
        <InputField
          value={value}
          outline
          type={
            type?.toString().toUpperCase() === "INTEGER" ? "number" : "text"
          }
          className = "m-0  p-0"
          onChange={(v) => {
            onChange(v);
          }}
          label=""
          style={{ maxHeight: "32px" }}
        ></InputField>
      </div>
    );
  } else if (
    type?.toString().toUpperCase() === "TIME_24".toUpperCase() ||
    type?.toString().toUpperCase() === "TIME_12".toUpperCase()
  ) {
    return <div>Time Picker</div>;
  } else if (
    type?.toString().toUpperCase() === "DATE_INTEGER".toUpperCase()
  ) {
    return <div className="mt-2">
      <DateSelect
        value={value}
        outline
        type={"date"}
        onChange={(v) => {
          onChange(v);
        }}
        className="m-0  p-0"
        label=""
        style={{ maxHeight: "32px" }}
      ></DateSelect>
    </div>;
  } else if (
    type?.toString().toUpperCase() === "LONG_DTNTIME_24".toUpperCase() ||
    type?.toString().toUpperCase() === "LONG_DTNTIME_12".toUpperCase() ||
    type?.toString().toUpperCase() === "LONG_DTONLY".toUpperCase() ||
    type?.toString().toUpperCase() === "LONG_TIMEONLY_24".toUpperCase() ||
    type?.toString().toUpperCase() === "LONG_TIMEONLY_12".toUpperCase()
  ) {
    return <div>Date Time in long</div>;
  }

  return <div>{value}</div>;
};
export default DataField;
