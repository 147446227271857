import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton,
  TagSelector
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditPaymentMades from "./EditPaymentMade";
import { STRUCTURE } from "./PaymentMadeConstant";
import { GridColumns } from "./PaymentMadeGridColumn";
import PaymentMadeService from "./PaymentMadeService";
import { UserContext } from "../../store/context/UserContext";


const PaymentMade = ({ insidePane }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { userContent } = useContext(UserContext);


  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/payment-made/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await PaymentMadeService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
  }, [])

  const onSave = async (e) => {
    e.preventDefault();
    // if (!detailData.supplier_id || !detailData?.supplierCompany_id || !detailData?.account_id || !detailData?.date || detailData?.paidTo == undefined || detailData?.status == undefined || !detailData?.currency || detailData?.total == undefined || detailData?.tds == undefined || detailData?.amountPaid == undefined || !detailData?.refNo) {

    //   showError(t("Please enter all fields"));
    //   return;
    // }
    setLoading(true);
    try {
      if (editID) {
        await PaymentMadeService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Payment made updated successfully."));
      } else {
        const paymentMadeID = await PaymentMadeService.save(detailData);
        setLoading(false);
        if (paymentMadeID) setEditID(paymentMadeID)
        showMessage(t("Payment made saved successfully."));
        if (!insidePane) navigate(`/payment-made/edit/${paymentMadeID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await PaymentMadeService.delete(id);
        showMessage("Payment made Deleted Successfully", "Deleted");
        setShowDetailPage(false)
        setLoading(false);
      } catch (e) {
        showError(e);
        setLoading(false);
      }
    }
  };

  const renderLastCol = useCallback((paymentMade) => {
    return (
      <>
        {userContent?.rights?.includes(4302) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/payment-made/edit/${paymentMade?._id}`);
              setEditID(paymentMade?._id);
              setShowDetailPage(true);
            }}
          />
        }
        {userContent?.rights?.includes(4303) ?
          <DeleteLink
            onClick={(event) => onDelete(event, paymentMade?._id)}
          />
          : null}

      </>
    );
  }, [insidePane, userContent])

  return (
    <>
      <Layout
        hideAdd={!userContent?.rights?.includes(4301)}
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/payment-made");
          setEditID(null);
        }}
        large
         
        title={t("Payments Made")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/payment-made/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={PaymentMadeService.page}
        rows={PaymentMadeService.rows}
        total={PaymentMadeService.total}
        fetch={PaymentMadeService.fetch}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                {userContent?.rights?.includes(4303) ?
                <>
                  <DropdownItem>{t("Bulk Delete")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  </>
                  : null}
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> Download </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={PaymentMadeService.records}
              total={PaymentMadeService.total}
              uiPreference="paymentMade.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={setSelectedIDs}
              page={PaymentMadeService.page}
              rowsPerPage={PaymentMadeService.rowsPerPage}
              onPaginationChange={PaymentMadeService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </>
        </Layout.Table>

        <Layout.DetailPageTitle>

          <div className="d-flex justify-content-between align-items-center">
            {detailData?._id ? t(`Edit Payment Made`) : t("Add Payment Made")}
            {/* <div className="flex-1 mx-3">
              <TagSelector
                header
                kind={"paymentMade"}
                onChange={(v) => {
                  setDetailData({ ...detailData, paymentMode: v })
                }}
                value={detailData?.paymentMode}
              />
            </div> */}
          </div>

        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditPaymentMades
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {editID && userContent?.rights?.includes(4303) && detailData?._id ? (
            <DeleteButton loading={loading} onClick={(e) => onDelete(e, detailData?._id)} />
          ) : null
          }
          {userContent?.rights?.includes(4302) ?
            <SaveButton loading={loading} onClick={onSave} />
            : null}
        </Layout.DetailPageFooter>
      </Layout >
    </>
  );
};
export default observer(PaymentMade);
