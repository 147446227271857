import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { HiOutlineMail } from "react-icons/hi";
import { IoLogoWhatsapp, IoMdMail } from 'react-icons/io';
import { MdOutlineSms } from 'react-icons/md';
import { TbMessageCircle } from 'react-icons/tb';
import Email from '../Email/Email';
import WhatsappChat from '../WhatsApp/WhatsappChat';
import CustomerDetails from '../components/customerDetails/CustomerDetails';
import InboxService from './InboxService';
import './styles.scss';
import CustomerLabel from '../../../components/Dropdowns/CustomerSelect/CustomerLabel';
import { truncateStringToWords } from '../../../util/Util';
import gmail from '../../../assets/images/icons/gmail.png';
import smsIcon from '../../../assets/images/icons/sms.png';
import whatsappIcon from '../../../assets/images/icons/whatsapp.png';


const iconMap = {
    whatsapp: <img src={whatsappIcon} alt="WhatsApp" height={16} className='me-2' />,
    sms: <img src={smsIcon} alt="SMS" height={16} className='me-2' />,
    email: <img src={gmail} alt="WhatsApp" height={16} className='me-2' />
}

const Inbox = () => {
    const { chats, setSelectedChat, selectedChat } = InboxService;
    const [selectedTab, setSelectedTab] = useState();

    useEffect(() => {
        InboxService.fetchChatHeads();
    }, [])


    useEffect(() => {
        if (selectedChat) {
            setSelectedTab(selectedChat.type ?? 'whatsapp');
        }
    }, [selectedChat]);

    const selectChat = (chat) => {
        setSelectedChat(chat.id);
        setSelectedTab(chat.type);
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'email':
                return (
                    <div className='zr_crm_inbox_email_container email_column'>
                        <Email selectedChat={selectedChat} insidePane />
                    </div>
                );
            case 'sms':
                return <div className='zr_crm_inbox_sms_container email_column'>SMS content here</div>;
            default:
                return (
                    <div className='zr_crm_inbox_whatsapp_chat-container email_column'>
                        <WhatsappChat customer_id={selectedChat?.customer_id} />
                    </div>
                );
        }
    };

    return (
        <div className="zr_crm_inbox_container">
            <div className="zr_crm_inbox_chat_list">
                {chats.map(chat => (
                    <div
                        key={chat.id}
                        className={`zr_crm_inbox_chat_item ${selectedChat && chat.id === selectedChat.id ? 'zr_crm_inbox_chat_item_active' : ''}`}
                        onClick={() => selectChat(chat)}
                    >
                        <span> <b><CustomerLabel value={chat.name} /></b></span>
                        <span className='d-flex align-items-center'>{iconMap[chat.type]} {truncateStringToWords(chat.message, 10)}</span>
                    </div>
                ))}
            </div>
            <div className="zr_crm_inbox_main">
                <div className='zr_crm_inbox_header'>
                    <div className="zr_crm_inbox_tabs">
                        <div className={`zr_crm_inbox_tab ${selectedTab === 'whatsapp' ? 'active' : ''}`} onClick={() => setSelectedTab('whatsapp')}><img src={whatsappIcon} alt="WhatsApp" height="20" /></div>
                        <div className={`zr_crm_inbox_tab ${selectedTab === 'email' ? 'active' : ''}`} onClick={() => setSelectedTab('email')}><img src={gmail} alt="Email" height="20" /></div>
                        <div className={`zr_crm_inbox_tab ${selectedTab === 'sms' ? 'active' : ''}`} onClick={() => setSelectedTab('sms')}><img src={smsIcon} alt="SMS" height="20" /></div>
                    </div>
                </div>
                {renderTabContent()}
            </div>
            <div className="zr_crm_inbox_customerDetails email_column">
                <CustomerDetails customerId={selectedChat?.customer_id} />
            </div>
        </div>
    );
};

export default observer(Inbox);
