import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { MdSend } from 'react-icons/md';
import { Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import './Attachment.css';
import AttachmentUploader from './AttachmentUploader';


const AttachmentWithText = ({
    asLabel = false,
    isModalVisible,
    handleCloseModal,
    onSave = () => { }  // On saving Document
}) => {
    const [attachments, setAttachments] = useState([]);
    const [message, setMessage] = useState('');

    const handleAttachmentUpload = (newAttachments) => {
        setAttachments(newAttachments);
    };

    const toggleModal = () => {
        if (isModalVisible) {
            setAttachments([]);
            setMessage('');
            handleCloseModal()
        }
    };

    const sendAttachment = async () => {
        onSave({ message, attachments });
        setMessage('');
        setAttachments([]);
        toggleModal();
    };

    return (
        <>
            {asLabel && <div onClick={toggleModal}>Document</div>}

            <Modal isOpen={isModalVisible} toggle={toggleModal} centered size="lg">
                <ModalHeader toggle={toggleModal} className="text-white whatsapp_header-color">
                    <span className='text-white'>Document Upload</span>
                </ModalHeader>
                <ModalBody>
                    <AttachmentUploader
                        attachments={attachments}
                        multiple={false}
                        onAttachmentChange={handleAttachmentUpload}
                    />
                    <Row className="mt-3">
                        <Col>
                            <Input
                                type="textarea"
                                placeholder="Your message..."
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <div className="modal-footer">
                    <MdSend size={50} className="cursor-pointer zr_crm_wa_modal_send-button" onClick={sendAttachment} />
                </div>
            </Modal>
        </>
    );
};

export default observer(AttachmentWithText);
