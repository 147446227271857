export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/message-agent/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  get: (id) => `/api/message-agent/${id}/detail`,
  save: `/api/message-agent/create`,
  delete: (id) => `/api/message-agent/${id}/delete`,
  update: `/api/message-agent/update`,
};


export const SELECTSTRUCTURE = {
  STATUS: [
    { label: "InActive", value: 0 },
    { label: "Active", value: 1 },
  ],
  SMTPTYPE: [
    { label: "SSL", value: "SSL" },
    { label: "TLS", value: "TLS" },
  ],
}

export const STRUCTURE = [
  {
    label: "Corporate",
    filterLabel: "Corporate",
    filterName: "corp_id",
    type: "corpSelect",
  },
  {
    label: "Status",
    filterLabel: "Status",
    filterName: "status",
    type: "select",
    data: SELECTSTRUCTURE?.STATUS,
  },
  {
    label: "SMS URL",
    filterLabel: "SMS URL",
    filterName: "smsURL",
    type: "text",
  },
  {
    label: "SMPT Type",
    filterLabel: "SMPT Type",
    filterName: "smtpType",
    type: "select",
    data: SELECTSTRUCTURE?.SMTPTYPE,
  }
];

