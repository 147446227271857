import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Th, Tr } from "react-super-responsive-table";
import { Button } from "reactstrap";
import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  Layout,
} from "../../components";
import "../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import AdminDashboardService from "./AdminDashboardService";

const AdminDashboard = (props) => {
  const { t } = useContext(I18nContext);
  let { id } = useParams();
  if (props?.corpId) id = props?.corpId;
  const [data, setData] = useState({});
  const { showMessage, showConfirm, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await AdminDashboardService.get();
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  let navigate = useNavigate();




  const onClose = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to close All Open Duty logs.",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onRepopulation = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to close All Open Duty logs.",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };

  const onRate = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to close All Open Duty logs.",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onTariff = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to Populate all RateCards",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/company`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onAppRoute = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to Populate all Tariff Cards",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onClearRate = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to Clear App Route Cache",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onRatecard = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to Clear Rates Cache",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onCity = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to Clear City/Country Cache?.",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onView = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "View Wrong Vehicle Types in Tariff.",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onCorporate = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: " Do you want to Clear all corporates cache ?.",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onStatic = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to Clear all Static Content cache ?.",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onCache = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to Clear all Static Content cache ?.",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onVehicle = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Clear all Vehicle Type Cache.",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/company`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onInvoice = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to clear invoice cache ?",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onRun = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to close All Open Duty logs.",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onTimezone = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Populate Timezones in Country and Cities.",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onBooking = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to Close All Open Duty Logs.",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const onGeocode = async (event) => {
    event.preventDefault();
    if (
      await showConfirm({
        description: "Do you want to Populate GeoCode Autofill Cache ?.",
      })
    ) {
      try {
        await AdminDashboardService.get(data);
        navigate(`/success`);
      } catch (e) {
        showError(e);
      }
    }
  };

  if (loading) return <CardThreeBounce />;
  return (<>
    <Layout
      title={t("Dashboard")}
    >
      <Layout.TbodyFull>
        <Tbody>
          <Tr>

            <Th><div><Button color="white" onClick={(event) => { onClose(event,); }}>{t("Close All Open Duty")} LOgs</Button></div> </Th>

            <Th><div><Button color="info" onClick={(event) => { onRepopulation(event); }} >{t("Repopulate All Notices")}</Button></div></Th>
            <Th><div><Button color="danger" onClick={(event) => { onRate(event); }}>{t("Populate all RateCard")}</Button></div></Th>
            <Th><div><Button color="warning" onClick={(event) => { onTariff(event); }}>{t("Populate all Tariff cards")}</Button></div></Th>

          </Tr><br />
          <Tr>
            <Th><div><Button color="warning" onClick={(event) => { onAppRoute(event); }}>{t("Clear App Route Cache")}</Button></div></Th>
            <Th><div><Button color="warning" onClick={(event) => { onClearRate(event); }}>{t("Clear Rates cache")}</Button></div></Th>
            <Th><div><Button color="warning" onClick={(event) => { onRatecard(event); }}>{t("Run Rate Card Purge Scheduler")}</Button></div></Th>
            <Th><div><Button color="warning" onClick={(event) => { onCity(event); }}>{t("Run city,Country Cache")}</Button></div></Th>

          </Tr><br />
          <Tr>
            <Th><div><Button color="warning" onClick={(event) => { onView(event); }}>{t("View Wrong Vehicle Type in Tariff")}</Button></div></Th>
            <Th><div><Button color="warning" onClick={(event) => { onCorporate(event); }}>{t("Clear all corporate cache ")}</Button></div></Th>
            <Th><div><Button color="warning" onClick={(event) => { onStatic(event); }}>{t("clear all static content cache")}</Button></div></Th>
            <Th><div><Button color="warning" onClick={(event) => { onCache(event); }}>{t("clear all cache")}</Button></div></Th>

          </Tr><br />
          <Tr>
            <Th><div><Button color="warning" onClick={(event) => { onVehicle(event); }}>{t("clear vehicle type cache")}</Button></div></Th>
            <Th><div><Button color="success" onClick={(event) => { onInvoice(event); }}>{t("clear invoice cache")}</Button></div></Th>

          </Tr><br />
          <Tr>
            <Th><div><Button color="danger" onClick={(event) => { onRun(event); }}>{t("Run Report")}</Button></div></Th>
            <Th><div><Button color="warning" onClick={(event) => { onTimezone(event); }}>{t("populated timezone in country and cities")}</Button></div></Th>
            <Th><div><Button color="info" onClick={(event) => { onBooking(event); }}>{t("Repopulate Booking Notices")}</Button></div></Th>
            <Th><div><Button color="success" onClick={(event) => { onGeocode(event); }}>{t("populated geo code autofilll cache")}</Button></div></Th>

          </Tr>
        </Tbody>
      </Layout.TbodyFull>

    </Layout>
  </>
  );
};

export default observer(AdminDashboard);
