import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";


import "toastr/build/toastr.min.css";
import {
  AddButton,
  Breadcrumb,
  Breadcrumbs,
  CardThreeBounce,
  Checkbox,
  CitySelect,
  ClientSelect,
  CountrySelect,
  DeleteLink,
  InputField,
  Layout,
  SelectField,

} from "../../../../components";
import "../../../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../../../store/context/DialogContext";
import { I18nContext } from "../../../../store/context/i18nContext";
import EmailCredentialsService from "./EmailCredentialsService";
import { SELECTSTRUCTURE } from "./EmailCredentialsConstant";
import { UserContext } from "../../../../store/context/UserContext";
import RemoveLink from "../../../../components/Buttons/RemoveLink";




const EmailCredentials = () => {
  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({})
  const [modal, setModal] = useState(false);
  const [emailCredentials, setEmailCredentials] = useState([])

  const [version, setVersion] = useState()

  const { showMessage } = useContext(DialogContext);
  const [hasErr, setHasErr] = useState(false);

  const setNewVersion = React.useCallback((version) => {
    setVersion(version)
  }, [])

  const submitHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      data.smtpCredentials = emailCredentials
      data.version = version

      setData(() => {
        return {
          ...data,
        }
      })

      const response = await EmailCredentialsService.editEmailCredentials(data)

      setNewVersion(response?.version)


      showMessage("Email Credentials saved successfully");
      setLoading(false)
    } catch (e) {
      showError(e)
      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await EmailCredentialsService.getEmailCredentials();

      setNewVersion(response?.version)
      setData(response);
      setEmailCredentials(response?.smtpCredentials ?? []);
      setHasErr(false);
      setLoading(false);

    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const deleteEmailCredentials=(index)=> {
    const updatedEmailCredentials= emailCredentials.slice(0, index).concat(emailCredentials.slice(index + 1));
    setData({
      ...data,
      smtpCredentials:updatedEmailCredentials
    })
    setEmailCredentials([...updatedEmailCredentials])
    return ;
  }


  useEffect(() => {
    setLoading(true);

    fetchData();
    setHasErr(false);

  }, []);


  if (loading) return <CardThreeBounce />;


  return (
    <>

      <div className="page-content pt-0">

        <Card className="mx-0 pt-3" style={{boxShadow:"none"}}>
          {userContent?.rights?.includes(1501) &&
            <CardTitle className="px-3">
              <div className={`d-flex justify-content-between col-12`}>
                <Breadcrumbs title="Email Credentials" breadcrumbItem="Email Credentials" />
                <AddButton
                  onClick={() => setEmailCredentials([...emailCredentials, {
                    smtpEmail: "",
                    smtpUsername: "",
                    smtpPassword: "",
                    smtpHost: "",
                    smtpType: "TLS",
                    country: "India",
                    smtpPort: 465,
                    isDefault: true,
                    forInvoice: true
                  }])}
                />
              </div>
            </CardTitle>
          }
          {/* <CardBody className="w-100" data-pattern="priority-columns ng-scope"> */}
            <table className=" striped-table">
            {emailCredentials?.map((l, idx) => (
                <Tr
                  key={idx}
                  className="w-80"
                >
                  <Td className="d-flex w-100">
                  <Card className='p-3 shadow me-0'>
                    <Row className="col-11  mb-3">
                      <Col className="col-3">
                        <InputField
                          placeholder="Email "
                          value={l?.smtpEmail}
                          label={"Email"}
                          onChange={(v) =>
                            setEmailCredentials(
                              emailCredentials.map((f, x) => {
                                if (idx === x) return {
                                  ...f, smtpEmail: v
                                };
                                return f;

                              })
                            )
                          }
                          type="text"
                        />

                      </Col>

                      <Col className="col-3">
                        <InputField
                          placeholder="Username"
                          value={l?.smtpUsername}
                          label={"Username"}
                          onChange={(v) =>
                            setEmailCredentials(
                              emailCredentials.map((f, x) => {
                                if (idx === x) return {
                                  ...f, smtpUsername: v
                                };
                                return f;

                              })
                            )
                          }
                          type="text"
                        />

                      </Col>
                      <Col className="col-3">
                        <InputField
                          placeholder="Password"
                          value={l?.smtpPassword}
                          label={"Password"}
                          onChange={(v) =>
                            setEmailCredentials(
                              emailCredentials.map((f, x) => {
                                if (idx === x) return {
                                  ...f, smtpPassword: v
                                };
                                return f;

                              })
                            )
                          }
                          type="text"
                        />

                      </Col>
                      <Col className="col-3">
                        <InputField
                          placeholder="Host"
                          value={l?.smtpHost}
                          label={"Host"}
                          onChange={(v) =>
                            setEmailCredentials(
                              emailCredentials.map((f, x) => {
                                if (idx === x) return {
                                  ...f, smtpHost: v
                                };
                                return f;

                              })
                            )
                          }
                          type="text"
                        />

                      </Col>

                      <Col className="col-3">
                        <CountrySelect
                          value={l?.country}
                          showErr={hasErr}
                          required={true}
                          onChange={(v) =>
                            setEmailCredentials(
                              emailCredentials.map((f, x) => {
                                if (idx === x) return {
                                  ...f, country: v?.value
                                };
                                return f;
                              })
                            )
                          }
                        />
                      </Col>
                      <Col className="col-3">
                        <InputField
                          placeholder="Port"
                          value={l?.smtpPort}
                          label={"Port"}
                          onChange={(v) =>
                            setEmailCredentials(
                              emailCredentials.map((f, x) => {
                                if (idx === x) return {
                                  ...f, smtpPort: v
                                };
                                return f;
                              })
                            )
                          }
                          type="number"

                        />
                      </Col>
                      <Col className="col-2">
                        <SelectField
                          data={SELECTSTRUCTURE.smtpType}
                          value={l?.smtpType}
                          onChange={(v) => {
                            emailCredentials.map((f, x) => {
                              if (idx === x) return {
                                ...f, smtpType: v?.value
                              };
                              return f;

                            })
                            // setEmailCredentials({ ...data, smtpType: v?.value });
                          }}
                          showErr={hasErr}
                          label={t("Type")}
                          required={true}
                        // className="col-sm-6 col-12"
                        />
                      </Col>



                      <Col className="col-2 d-flex align-items-cente">
                        <Checkbox
                          label="Default"
                          checked={l?.isDefault}
                          onClick={(v) =>
                            setEmailCredentials(
                              emailCredentials.map((f, x) => {
                                if (idx === x) return {
                                  ...f, isDefault: v
                                };
                                return f;
                              })
                            )
                          }
                        />
                      </Col>

                      <Col className="col-2 d-flex align-items-center">
                        <Checkbox
                          label="Invoice"
                          checked={l?.forInvoice}
                          onClick={(v) =>
                            setEmailCredentials(
                              emailCredentials.map((f, x) => {
                                if (idx === x) return {
                                  ...f, forInvoice: v
                                };
                                return f
                              })
                            )
                          }
                        />
                      </Col>
                    </Row>
                    </Card>
                  <Row className="col-1  align-items-start ">
                    {idx != 0 ? <RemoveLink className="ms-1" onClick={() => deleteEmailCredentials(idx)}/>
                      : null}
                  </Row>
                  </Td>
                </Tr>
              ))}
            </table>
          {/* </CardBody> */}
        </Card>
        {userContent?.rights?.includes(1502) &&
          <div className="d-flex justify-content-end  ">
            <div className="me-2">
              <Button type="submit" color="primary" onClick={submitHandler}>Save</Button>{' '}
            </div>
          </div>
        }
      </div>
    </>

  );
};

export default observer(EmailCredentials);






