import React, { useEffect, useRef, useState } from 'react';
import { FaBan, FaCheckCircle, FaShareSquare, FaTimesCircle } from 'react-icons/fa';
import { useOutsideAlerter } from '../../../../board/helper';
import './index.scss';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'react-tooltip';

const statusOptions = [
    { key: 0, label: "Pending", icon: FaBan, color: "#808080" },
    { key: 1, label: "Quote Received", icon: FaShareSquare, color: "#007bff" },
    { key: 2, label: "Shortlisted", icon: FaShareSquare, color: "#ffc107" },
    { key: 3, label: "Selected", icon: FaCheckCircle, color: "#28a745" },
    { key: 4, label: "Rejected", icon: FaTimesCircle, color: "#dc3545" },
];

const SupplierStatusPicker = ({
    noToggle = false,
    value,
    onChange
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    useOutsideAlerter(dropdownRef, () => setIsOpen(false));

    const handleSelect = (key) => {
        onChange(key);
        setIsOpen(false);
    };

    const selectedOption = statusOptions.find(option => option.key === value);

    const tooltipId = `tooltip-${Math.random().toString(36).substr(2, 9)}`;
    return (
        <div className="zr_it_supplier_status_wrapper" ref={dropdownRef}>

            {selectedOption?.icon && (
                <Tooltip style={{ zIndex: '200000' }} anchorSelect={`#${tooltipId}`}>
                    <div>{selectedOption.label}</div>
                </Tooltip>
            )}


            <div
                id={`${tooltipId}`}
                className={`zr_it_supplier_status_display ${noToggle ? ' zr_it_supplier_status_item_aslabel ' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedOption?.icon ? React.createElement(selectedOption.icon, { size: 14, color: selectedOption.color }) : <span className='text-muted m-0 p-0 '>Status</span>} {noToggle ? "" : <span className='ms-2'>{selectedOption?.label ?? ""} </span>}
            </div>

            {isOpen && !noToggle && (
                <ul className="zr_it_supplier_status_modal">
                    {statusOptions.map(option => (
                        <li
                            key={option.key}
                            className="zr_it_supplier_status_item"
                            onClick={() => handleSelect(option.key)}
                        >
                            {React.createElement(option.icon, { size: 14, color: option.color })}
                            <span className="ms-2">{option.label}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default observer(SupplierStatusPicker);
