import React from 'react';
import "./styles.scss"

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

function QuoteItem(props) {
  const { quote, isDragging, isGroupedOver, provided, style, isClone, index ,isHorizontal  } = props;

  return (
    <div
      className={isHorizontal ? 'item-horizontal-container'  : 'item-container'}
      // href={quote.author.url}
      isDragging={isDragging}
      isGroupedOver={isGroupedOver}
      isClone={isClone}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      // data-testid={quote.id}
      data-index={index}
      // aria-label={`${quote.author.name} quote ${quote.content}`}
    >
      {isClone ? <div className='item-clone-badge'>Clone</div> : null}
      <div className='item-content'>
        <div>{quote.renderer()}</div>
        
      </div>
    </div>
  );
}

export default React.memo(QuoteItem);
