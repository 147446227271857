import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";

class CategoryLabelService {
  categoryCache = {};
  fetchQueue = {};
  loading = {};

  constructor() {
    makeAutoObservable(this);
  }

  fetchCategory = async (categoryId) => {
    if (this.categoryCache[categoryId]) {
      return this.categoryCache[categoryId];
    }

    if (this.loading[categoryId]) {
      return new Promise((resolve, reject) => {
        if (!this.fetchQueue[categoryId]) this.fetchQueue[categoryId] = [];
        this.fetchQueue[categoryId].push({ resolve, reject });
      });
    }

    this.loading[categoryId] = true;

    try {
      const response = await doGET(`/api/doc-category/grid?rows=-1`);
      if (response.status === 200) {
        const categories = response.data.rows.reduce((acc, item) => {
          acc[item._id] = { ...item, label: item.name };
          return acc;
        }, {});

        this.categoryCache = { ...this.categoryCache, ...categories };

        while (this.fetchQueue[categoryId]?.length > 0) {
          const { resolve } = this.fetchQueue[categoryId].shift();
          resolve(this.categoryCache[categoryId]);
        }

        return this.categoryCache[categoryId] || null;
      } else {
        throw new Error("Failed to fetch categories");
      }
    } catch (error) {
      throw new Error(error.message);
    } finally {
      delete this.loading[categoryId];
    }
  };
}

const categoryLabelService = new CategoryLabelService();
export default categoryLabelService;
