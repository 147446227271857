import React, { useContext, useEffect, useRef, useState } from "react";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import ContactPersonTooltip from "../../ContactPersonTooltip/ContactPersonTooltip";
import EmployeeAssigner from "../../Dropdowns/EmployeeSelect/EmployeeAssigner";
import StatusMasterPicker from "../../Dropdowns/StatusMasterSelect/StatusMasterPicker";
import DueDateDropdown from "../../DueDateDropdown/DueDateDropdown";
import PriorityDropdown from "../../PriorityDropdown/PriorityDropdown";
import LeadMasterService from "../board/LeadMasterService";
import LeadForm from "../forms/LeadForm";
import "./styles.scss";
import StatusPicker from "../../Dropdowns/StatusMasterSelect/StatusPicker";

const LeadListItem = ({ onEdit = () => { }, lead, onDelete = () => { } }) => {
    const [modal, setModal] = useState(false);
    const [showFixedCard, setShowFixedCard] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const { t } = useContext(I18nContext);
    const { showError, showConfirm, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const navigate = useNavigate()

    const dropdownRef = useRef(null);

    useEffect(() => {
        setData(lead)
    }, [lead])

    const toggleModal = (e) => {
        if (modal) {
            navigate("/crm")
        } else if (lead?._id) {
            navigate(`/crm/edit/${lead?._id}`);
        } else {
            navigate(`/crm/create`);
        }

        e.stopPropagation();
        setModal(!modal);
    };

    const handleCardHover = () => {
        setShowFixedCard(true);
    };

    const handleCardLeave = () => {
        setShowFixedCard(false);
        setShowMenu(false)
    };

    const toggleMenu = (e) => {
        e.stopPropagation();
        setShowMenu(!showMenu);
    };


    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        if (showMenu) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showMenu]);


    const onDeleteLead = async (e) => {
        e.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await LeadMasterService.delete(lead?._id);
                setLoading(false);
                onDelete()
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    }

    const handleTagsChange = async (v) => {
        setData({ ...data, tags: v })
        try {
            await LeadMasterService.edit({ ...data, tags: v })
            await fetchLeadItem()
        } catch (error) {
            // showError(error)
        }
    }

    const handlePriorityChange = async (v) => {
        setData({ ...data, priority: v })
        try {
            await LeadMasterService.edit({ ...data, priority: v })
            await fetchLeadItem()
        } catch (error) {
            // showError(error)
        }
    }

    const handleDueDate = async (v) => {
        console.log(v)
        setData({ ...data, dueDate: v })
        try {
            await LeadMasterService.edit({ ...data, dueDate: v })
            await fetchLeadItem()
        } catch (error) {
            // showError(error)
        }
    }


    const handleAssignesChange = async (v) => {
        setData({ ...data, assignedTo: v })
        try {
            await LeadMasterService.edit({ ...data, assignedTo: v })
            await fetchLeadItem()
        } catch (error) {
            // showError(error)
        }
    }


    const handleStatusMasterIdChange = async (v) => {
        setData({ ...data, status: v })
        try {
            await LeadMasterService.edit({ ...data, status: v })
            await fetchLeadItem()
        } catch (error) {
            // showError(error)
        }
    }


    const fetchLeadItem = async () => {
        try {
            let lead = await LeadMasterService.get(data?._id);
            setData(lead);
        } catch (error) {
            // showError(error)
        }
    }


    const handleStopPropagation = (e) => {
        e.stopPropagation();
    };


    return (
        <React.Fragment>
            <div onClick={toggleModal} className="d-flex py-0 justify-content-between align-items-center row" onMouseEnter={handleCardHover} onMouseLeave={handleCardLeave}>
                <div className='item-block-quote col py-0 my-0 d-flex align-items-center'>
                    <div>

                        <StatusPicker
                            onChange={(v) => handleStatusMasterIdChange(v?.value)}
                            value={lead?.status}
                            className="m-3"
                            hideName
                            openLeft
                        />

                    </div>
                    <div> {lead?.title}</div>
                </div>


                <div className="col py-0 my-0">
                    <EmployeeAssigner
                        tooltipId={data?._id}
                        value={data?.assignedTo}
                        onChange={(v) => { handleAssignesChange(v) }}
                    />
                </div>

                <div className="col">
                    <PriorityDropdown
                        value={data?.priority}
                        toolTipId={data?._id}
                        onChange={(v) => handlePriorityChange(v)}
                    />
                </div>


                {/* 
                <div className="col">
                    {data?._id && data?.leadExtn?.contacts?.length ?
                        <ContactPersonTooltip
                            toolTipId={data?._id}
                            contactPersons={data?.leadExtn?.contacts}
                        />
                        : null}
                </div> */}

                {/* <div className="col">
                    <div className="crm-menu-icon" onClick={toggleMenu}>
                        <PiDotsThreeVerticalBold size={20} />
                    </div>
                </div> */}




                {showMenu && (
                    <div onClick={(e) => { e.stopPropagation() }} className="crm-hz-menu" ref={dropdownRef}>
                        {/* <div className="crm-menu-option">Copy Link</div> */}
                        <div onClick={onDeleteLead} className="crm-menu-option">Delete Lead</div>
                        <div className="crm-menu-option">Mark as Complete</div>
                        {/* <div className="crm-menu-option">Archive</div> */}
                    </div>
                )}
            </div>

            {/* <LeadForm
                isOpen={modal}
                onEdit={onEdit}
                toggleModal={toggleModal}
                editId={lead?._id}
            /> */}

        </React.Fragment>
    );
};

export default LeadListItem;
