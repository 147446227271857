import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./MapLogsConstant";
import { GridColumns } from "./MapLogsGridColumns";
import MapLogsService from "./MapLogsService";
import { UserContext } from "../../store/context/UserContext";

const MapLogs = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await MapLogsService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])


  const renderLastCol = useCallback((logs) => {
    return (
      <>
      </>
    );
  }, [insidePane,])

  const toggleData = {
    module: [
      { label: t("SMS Logs"), value: "sms" },
      { label: t("WhatsApp Logs"), value: "whatsapp" },
      { label: t("Email Logs"), value: "email" },
      { label: t("Map Logs"), value: "map" },
    ],
    toggleValue: "map"
  }


  return (
    <>
      <Layout
        hideAdd={!userContent?.rights?.includes(1501)}
        medium
        showToggle
        toggleData={toggleData}
        onToggle={(selectedValue) => {
          if (selectedValue === "sms") {
            navigate(`/smsLogs`);
          } else if (selectedValue === "whatsapp") {
            navigate(`/whatsAppLogs`);
          } else if (selectedValue === "email") {
            navigate(`/emailLogs`);
          } else if (selectedValue === "map") {
            navigate(`/mapLogs`);
          }
        }}
         
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        insidePane={insidePane}
        page={MapLogsService.page}
        rows={MapLogsService.rows}
        total={MapLogsService.total}
        fetch={MapLogsService.fetch}
      >
        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={MapLogsService.records}
            total={MapLogsService.total}
            uiPreference="logs.grid"
            headers={GridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            selectedIDs={selectedIDs}
            page={MapLogsService.page}
            rowsPerPage={MapLogsService.rowsPerPage}
            onPaginationChange={MapLogsService.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>
      </Layout>
    </>
  );
};
export default observer(MapLogs);
