export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "#",
            accessor: "no",
            show: true,
            width: 100,
        },
        {
            title: "Issue",
            accessor: "issue",
            show: true,
            required: true,
            renderer: (dataObj) => {
                return (
                    <div>
                        <div>{dataObj?.issue}</div>
                    </div>
                )
            },
            // width: 500, // Adjusted for maximum width
        },
        {
            title: "Detail",
            accessor: "detail",
            show: true,
            required: true,
            renderer: (dataObj) => {
                return (
                    <div>
                        <div>{dataObj?.detail}</div>
                    </div>
                )
            },
            width: 700, // Adjusted for maximum width
        },
        {
            title: "Status",
            accessor: "status",
            show: true,
            required: true,
            renderer: (dataObj) => {
                return (
                    <div className="d-flex align-items-center">
                        <div style={{
                            backgroundColor:
                                dataObj.status === 0
                                    ? "Orange"
                                    : dataObj.status === 1
                                        ? "Green"
                                        : dataObj.status === 2
                                            ? "skyblue"
                                            : null,
                            width: "1.4rem", // Adjust the size of the circle
                            height: "1.4rem", // Adjust the size of the circle
                            borderRadius: "50%", // Makes it a circle
                            marginRight: "0.5rem", // Add some space between the circle and the text
                        }}></div>
                        <div>
                            {dataObj.status === 0 ? "Open" : dataObj.status === 1 ? "Resolved" : dataObj.status === 2 ? "Assigned" : null}
                        </div>
                    </div>
                )
            },
            // width: "200", // Adjusted width
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90, // Adjusted for minimum width
            show: true,
        },
    ],
};
