import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./DownloadByTemplateConstants";
import { doGET, doPOST } from "../../util/HttpUtil";

class Service {
    constructor() {
        makeAutoObservable(this);
    }

    fetch = async function (kind) {
        const response = await doGET(ENDPOINTS.fetch(kind))
        if (response.status === 200) {
            return response.data;
        }
    };

    getList = async function (kind) {
        const response = await doGET(ENDPOINTS.getList(kind))
        if (response.status === 200) {
            return response.data;
        }
    };

    save = async function (data) {
        await doPOST(ENDPOINTS.saveNew, data)
        // this.fetch();
    };

}

const DownloadByTemplateService = new Service();
export default DownloadByTemplateService;
