import React, { useContext, useEffect, useState } from "react";
import { DeleteButton } from "react-bootstrap-table";
import {
  Button, Col,
  Form, Input, Label, Row
} from "reactstrap";
import {
  CityGroupSelect, ClientSelect, CountrySelect, InputField,
  SaveButton,
  SelectField
} from "../../../components";
import { DialogContext } from "../../../store/context/DialogContext";
import SettingsService from "../SettingsService";
import EmailModal from "./EmailModal";

function EmailSetup() {
  const [loading, setLoading] = useState(false);
  const { showMessage, showError } = useContext(DialogContext);
  const [data, setData] = useState([]);
  const [config, setConfig] = useState();
  const [hasErr, setHasErr] = useState(false);
  const [modal, setModal] = useState(false);
  const [testOBJ, setTestOBJ] = useState({});

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await SettingsService.getConfig();
      setConfig(res);
      setData(res.smtpCredentials);
      console.log("EmailSetup.js ", res.smtpCredentials);
      console.log("config state: ", res);
      setHasErr(false);
    } catch (e) {
      showError(e);
      // console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOnChange = (index, value, field, original) => {
    // console.log(value);
    setData(
      data.map((curr, ind) => {
        if (index === ind) {
          return { ...original, [field]: value };
        }
        return curr;
      })
    );
  };

  useEffect(() => {
    if (JSON.stringify(config?.smtpCredentials) !== JSON.stringify(data))
      setConfig({ ...config, smtpCredentials: data });
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // console.log("Config going to get saved: ", config);
      const res = await SettingsService.saveSMTP(config);
      fetchData();
      setLoading(false);
    } catch (error) {
      showError(error);
    }
  };

  const handleTest = async () => {
    try {
      setLoading(true);
      delete testOBJ.cityGroup;
      delete testOBJ.client_id;
      delete testOBJ.country;
      delete testOBJ.isDefault;
      // console.log("Data to be tested ", testOBJ);
      const res = await SettingsService.testSMTP(testOBJ);
      if (res.status !== 200) {
        showError("Error");
      }
      fetchData();
      setLoading(false);
    } catch (error) {
      showError(error);
    }
  }

  return (
    <>
      {modal && <EmailModal
        modal={modal}
        testData={testOBJ}
        setTestData={setTestOBJ}
        setModal={setModal}
        onChange={handleOnChange}
        handleTest={handleTest}
      />}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col
            className="col-12 p-2 ps-4"
            style={{
              backgroundColor: "#EAEAEA",
              fontSize: "15px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <header className="d-flex justify-content-between align-items-center">
              <m>{`Email Setup ( For sending emails )`}</m>
              <m>
                <Button
                  type="button"
                  className="btn ms-2 btn-primary"
                  style={{ height: "32px", width: "32px", padding: "0px" }}
                  onClick={() => {
                    console.log("configAdd", config);
                    setData([...data, {}]);
                  }}
                >
                  <i style={{ fontSize: "30px" }} className="bx bx-plus"></i>
                </Button>
              </m>
            </header>
          </Col>

          <Col className="col-12">
            {data &&
              data.map((e, ind) => {
                return (
                  <Row className="mt-2">
                    <Col className="col-md-8">
                      <Row className="mt-2">
                        <Col>
                          <InputField
                            placeholder="SMTP Email"
                            label={"SMTP Email"}

                            type="text"
                            maxLength="50"
                            value={e?.smtpEmail ? e?.smtpEmail : ""}
                            onChange={(v) =>
                              handleOnChange(ind, v, "smtpEmail", e)
                            }
                          />
                        </Col>
                        <Col>
                          <InputField
                            placeholder="SMTP Username"
                            label={"SMTP Username"}

                            type="text"
                            maxLength="50"
                            value={e?.smtpUsername ? e?.smtpUsername : ""}
                            onChange={(v) =>
                              handleOnChange(ind, v, "smtpUsername", e)
                            }
                          />
                        </Col>
                        <Col>
                          <InputField
                            placeholder="SMTP Password"
                            label={"SMTP Password"}

                            type="password"
                            maxLength="100"
                            value={e?.smtpPassword ? e?.smtpPassword : ""}
                            onChange={(v) =>
                              handleOnChange(ind, v, "smtpPassword", e)
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <InputField
                            placeholder="SMTP Host"
                            label={"SMTP Host"}

                            type="text"
                            maxLength="50"
                            value={e?.smtpHost ? e?.smtpHost : ""}
                            onChange={(v) =>
                              handleOnChange(ind, v, "smtpHost", e)
                            }
                          />
                        </Col>
                        <Col>
                          <InputField
                            placeholder="SMTP Port"
                            label={"SMTP Port"}

                            type="text"
                            maxLength="9"
                            value={e?.smtpPort ? e?.smtpPort : ""}
                            onChange={(v) =>
                              handleOnChange(ind, v, "smtpPort", e)
                            }
                          />
                        </Col>
                        <Col>
                          <SelectField
                            label={"Select SMPT Type"}
                            data={[
                              { label: "TLS", value: "TLS" },
                              { label: "SSL", value: "SSL" },
                            ]}
                            value={e?.smtpType}
                            onChange={(v) =>
                              handleOnChange(ind, v?.value, "smtpType", e)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ClientSelect
                            placeholder="Select Client"
                            label={"Select Client"}
                            value={e?.client_id}
                            onChange={(v) =>
                              handleOnChange(ind, v?.value, "client_id", e)
                            }
                          />
                        </Col>
                        <Col>
                          <CountrySelect
                            value={e?.country}
                            onChange={(v) =>
                              handleOnChange(ind, v?.name, "country", e)
                            }
                          />
                        </Col>
                        <Col>
                          <CityGroupSelect
                            typeCountry
                            country={e?.country}
                            value={e?.cityGroup}
                            onChange={(v) => {
                              console.log("cityGroup: ", v);
                              handleOnChange(ind, v, "cityGroup", e);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col className="col-md-4">
                      <Row className="mt-2">
                        <Col>
                          <Input
                            name="isDefault"
                            type="checkbox"
                            onClick={() =>
                              setData(
                                data.map((f, curr) => {
                                  if (ind === curr)
                                    return {
                                      ...e,
                                      isDefault: !e?.isDefault,
                                    };
                                  return f;
                                })
                              )
                            }
                            checked={e?.isDefault}
                          />
                          <Label className="ps-2 pt-1" check for="isDefault">
                            Default
                          </Label>
                        </Col>
                        <Col>
                          <Input
                            name="forInvoice"
                            type="checkbox"
                            onClick={() =>
                              setData(
                                data.map((f, curr) => {
                                  if (ind === curr)
                                    return {
                                      ...e,
                                      forInvoice: !e?.forInvoice,
                                    };
                                  return f;
                                })
                              )
                            }
                            checked={e?.forInvoice}
                          />
                          <Label className="ps-2 pt-1" check for="forInvoice">
                            For Invoice
                          </Label>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <Button
                            color="primary"
                            type="button"
                            onClick={() => {
                              setModal(true);
                              setTestOBJ(e);
                            }}
                          >Test</Button>
                        </Col>
                        <Col>
                          <DeleteButton
                            onClick={() =>
                              setData(data.filter((curr, x) => x !== ind))
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <hr />
                  </Row>
                );
              })}
          </Col>
          <Col>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default EmailSetup;
