import React, { useEffect, useState } from 'react'
import MinimizableComponent from '../MinimizableComponent';
import InvoiceForm from '../../pages/Invoice/InvoiceForm';

function BulkInvoice({visible,onClose,selectedIDs,onSave=()=>{}}) {
  return (
    <>
  
     {visible && <MinimizableComponent
                onClose={onClose}
                title={`Create Invoice    (${selectedIDs?.length>1?`${selectedIDs?.length} items`:`${selectedIDs?.length} item`} )`}
                >
                  <div style={{fontSize:'13px',fontWeight:'normal'}}>
                  <InvoiceForm
                        toggleModal={onClose}
                        trip_ids={selectedIDs}
                        onSave={(invoicedTripIds) => {onSave(invoicedTripIds) }}
                        concise
                    />
                  </div>
                   
                </MinimizableComponent>}
    </>
  )
}

export default BulkInvoice