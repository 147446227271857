import React, { useContext, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { CitySelect, InputField, SelectField, } from '../../../components'
import { I18nContext } from '../../../store/context/i18nContext';

const StepOne = ({ data, setData }) => {
    const { t } = useContext(I18nContext);
    // const [officeData, setOfficeData] = useState(data?.offices)
    return (
        <>
            <div className=' m-auto mb-3' style={{ maxWidth: "580px" }}>
                <div className="separator">
                    <div className="line"></div>
                    <h2 className=" fw-bold">Company Details</h2>
                    <div className="line"></div>
                </div>
            </div>
            <Row className=''>
                <Col className=' px-4 col-sm-12'>
                    <InputField

                        label={t("Company Name")}
                        value={data?.companyName}
                        onChange={(v) => setData({
                            ...data,
                            companyName: v
                        })}
                        placeholder="Company Name"
                        type="text"
                        className='col-sm-10 m-auto'

                        showErr={true}
                    />
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col className=' px-4 col-sm-12'>
                    <InputField
                        label={t("Office Address")}
                        value={data?.officeAddress}
                        onChange={(v) => setData({
                            ...data,
                            officeAddress: v
                        })}
                        placeholder="Office Address"
                        type="text"
                        className='col-sm-10 m-auto'

                        showErr={true}
                    />
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col className=' px-4 col-sm-12'>
                    <InputField
                        label={t("Contact Person")}
                        value={data?.contactPerson}
                        onChange={(v) => setData({
                            ...data,
                            contactPerson: v
                        })}
                        placeholder="Contact Person"
                        type="text"
                        className='col-sm-10 m-auto'

                        showErr={true}
                    />
                </Col>
            </Row>

            <Row className='mt-4'>
                <Col className=' px-4 col-sm-12'>
                    <InputField
                        label={t("Phone")}
                        value={data?.phone}
                        onChange={(v) => setData({
                            ...data,
                            contactPerson: v
                        })}
                        placeholder="Phone"
                        type="number"
                        className='col-sm-10 m-auto'

                        showErr={true}
                    />
                </Col>
            </Row>


            <Row className='mt-4'>
                <Col className=' px-4 col-sm-12'>
                    <InputField
                        label={t("Email")}
                        value={data?.email}
                        onChange={(v) => setData({
                            ...data,
                            email: v
                        })}
                        placeholder="Email"
                        type="email"
                        className='col-sm-10 m-auto'

                        showErr={true}
                    />
                </Col>
            </Row>


        </>
    )
}

export default StepOne