import React from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";

const GMap = (props) => {
  const refMap = React.useRef(null);
  const [center, setCenter] = React.useState(props?.markerLocation);
  const handleBoundsChanged = () => {
    const mapCenter = refMap.current.getCenter();
    setCenter(mapCenter);
  };

  const dragEnd = () => {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${center.lat()},${center.lng()}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        props?.onMapMoved(data?.results[0]?.formatted_address);
      });
  };
  return (
    <GoogleMap
      ref={refMap}
      defaultZoom={10}
      defaultCenter={props?.mapLocation}
      defaultOptions={{ styles: props?.mapStyle }}
      onBoundsChanged={handleBoundsChanged}
      onDragEnd={dragEnd}
    >
      {props?.showMarker && <Marker position={center} />}
    </GoogleMap>
  );
};

const Map = React.memo(
  (props) => {
    const MapWrapped = withScriptjs(withGoogleMap(() => GMap(props)));
    return (
      <div
        id="gmaps-overlay"
        className="gmaps"
        style={{ position: "relative" }}
      >
        <MapWrapped
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.mapLocation.lat === nextProps.mapLocation.lat ||
    prevProps.mapLocation.lng === nextProps.mapLocation.lng
);
export default Map;
