import React, { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";
import FileUploadPopup from "./FIleUploadPopup"; // Create this component for file upload
import { DialogContext } from "../../store/context/DialogContext";
import { doDELETE } from "../../util/HttpUtil";
import { I18nContext } from "../../store/context/i18nContext";
import Image from "../Image/Image";

const FileUpload = ({ type, value, id, fileSizeText, fileSize, create, module, field, onUpload = () => { }, onDelete = () => { }, className = "" }) => {
    const { showConfirm, showMessage, showError } = useContext(DialogContext)
    const { t } = useContext(I18nContext)
    const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
    const [fileUrl, setFileUrl] = useState(value ?? "");

    const handleDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete file?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            try {
                const data = {
                    _id: id,
                    field: field,
                    module: module,
                }
                const response = await doDELETE("/api/file/delete/single", data);
                if (response.status === 200) {
                    showMessage("Deleted", "Deleted");
                    onDelete()
                }
            } catch (e) {
                showError(e);
            }
        }
    };

    useEffect(() => {
        setFileUrl(value)
    }, [value])
    if (!id) {
        return null;
    }

    return (
        <>
            {
                (id || create) && <div className={className}>
                    {type === "image" && fileUrl ? (
                        <div className="position-relative">
                            <Image
                                height="100px"
                                width="100px"
                                src={fileUrl}
                                alt="Uploaded File"
                                className="border-light m-3"
                                style={{ minHeight: "100px", minWidth: "100px", borderRadius: "10px", border: "1px solid", cursor: "pointer", objectFit: "contain" }}
                            />

                            <div
                                className="position-absolute top-0 bg-warning px-1 rounded-5 "
                                onClick={handleDelete}
                                style={{ cursor: "pointer", left: "100px" }}
                            >
                                <i className="  mdi mdi-delete-outline font-size-18" />
                            </div>
                            <div
                                className="position-absolute bottom-0 bg-success px-1 rounded-5 "
                                onClick={() => setIsUploadPopupOpen(true)}
                                style={{ cursor: "pointer", left: "100px" }}
                            >
                                <i className="uil uil-pen font-size-18" />
                            </div>
                        </div>
                    ) : (
                        <div>
                            {type === "image" && (
                                <div className="position-relative">
                                    <i
                                        style={{ fontSize: "70px", borderRadius: "10px" }}
                                        className="mdi border px-3 border-light mdi-image text-light mx-1"></i>
                                    <div
                                        className="position-absolute bottom-0 bg-success px-1 rounded-5 "
                                        onClick={() => setIsUploadPopupOpen(true)}
                                        style={{ cursor: "pointer", left: "100px" }}
                                    >
                                        <i className="uil uil-pen font-size-18" />
                                    </div>
                                </div>
                            )}
                            {type === "document" && (
                                <div>
                                    {!fileUrl ? (
                                        <Button onClick={() => setIsUploadPopupOpen(true)}>
                                            Upload Document
                                        </Button>
                                    ) : (
                                        <Button>
                                            <a style={{ color: "white" }} href={fileUrl} target="_blank" download rel="noreferrer">
                                                Download Document
                                            </a>
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    {isUploadPopupOpen && (
                        <FileUploadPopup
                            value={value}
                            onClose={() => setIsUploadPopupOpen(false)}
                            onFileUpload={onUpload}
                            onDelete={onDelete}
                            id={id}
                            fileSizeText={fileSizeText}
                            fileSize={fileSize}
                            module={module}
                            setIsUploadPopupOpen={setIsUploadPopupOpen}
                            field={field}
                        />
                    )}
                </div>
            }
        </>

    );
};

export default FileUpload;
