import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  CardThreeBounce,
  EditLink,
  InputField,
  PhoneNum,
} from "../../components";
import LanguageMenu from "../../components/CommonForBoth/TopbarDropdown/LanguageSelect/LanguageMenu";
import Timer from "../../components/Timer";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doPOST } from "../../util/HttpUtil";
import OtpInput from "./OtpInput";

const LoginOtp = ({
  data,
  setData,
  loading,
  isCpanelLogin,
  setErrorStatus,
  setStep,
  otpId,
  webContent
}) => {
  const { login, userContent, populateUserContent } = useContext(UserContext);

  let navigate = useNavigate();
  const { showMessage, showError } = useContext(DialogContext);
  const { t } = useContext(I18nContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isTermsSelected, setIsTermsSelected] = useState(false);

  const Resend = async () => {
    setIsDisabled(true);
    try {
      const reqParams = {
        phone: data?.phone,
        countryCode: data?.countryCode,
        otp_id: otpId,
        resend: true,
      };
      const res = await doPOST("/api/o/otp/captcha/login/send", reqParams);
    } catch (error) {
      console.log(error);
      setErrorStatus(error);
      showError(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsDisabled(false);
    }, 30000);
  }, []);

  const onLogin = async () => {
    if (loading) return;
    // if (!isTermsSelected) {
    //   showError("Please Accept Terms & Conditions");
    //   return;
    // }
    try {
      let loginData = {};
      loginData.otpId = otpId;
      loginData.userType = isCpanelLogin ? "superAdmin" : "supervisor";
      if (data?.email) {
        loginData.email = data.email;
      } else if (data?.phone) {
        loginData.countryCode = data?.countryCode ? data?.countryCode : "91";
        loginData.phone = data?.phone;
      } else {
        console.error(
          "Both email and phone are provided. Please provide either email or phone, not both."
        );
        return;
      }
      loginData.otpValue = data.otpValue;
      var response = await login(loginData);
      if (response == "choose-profile") {
        navigate("/choose-profile", { replace: true });
      } else if (response?.status === 200) {
        navigate("/dashboard", { replace: true });
      } else {
        setStep(0);
        showError(JSON.parse(response?.message)?.message);
      }
    } catch (e) {
      setStep(0);
      showError(JSON.parse(response?.message)?.message);
    }
  };

  useEffect(() => {
    document.body.className = "authentication-bg";
    const fetch = async () => {
      await populateUserContent();
      setData({ ...data, countryCode: userContent?.countryCode });
    };
    fetch();
    return function cleanup() {
      document.body.className = "";
    };
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          overflowY: "auto",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row className="mb-4 mt-2 p-0 d-flex justify-content-end">
          <Col className="col-2 p-0 ml-5">
            <LanguageMenu outline />
          </Col>
        </Row>
        <div className="account-pages pt-sm-3 ">
          <Container className="d-flex justify-content-center w-100 p-3">
            <Row
              className="align-items-center justify-content-center w-100"
              style={{
                border: "1px solid #D5D5D5",
                borderRadius: "1.25rem",
                overflow: "hidden",
                maxWidth: "500px",
              }}
            >
              <Col
                className="flex-1 mx-6"
                md={8}
                lg={6}
                xl={6}
                style={{ maxWidth: "500px" }}
              >
                <Card className="shadow-none w-80">
                  <CardBody className="p-4">
                    <Link className="mb-5 mt-2 d-block auth-logo">
                      <img
                        src={webContent?.logo ? `${webContent?.logo}` : "/logo.png"}
                        alt="zoyride-logo"
                        className="logo logo-dark mb-2"
                      />
                    </Link>
                    <h3 className="align-items-center justify-content-center d-flex pb-3 font-size-18">
                      Login
                    </h3>
                    <div>
                      <div className="form-horizontal">
                        <div className="text-center mt-2">
                          <div className="mb-1">
                            <Row className="d-flex  align-items-center p-0">
                              <Col className="  my-3 d-flex flex-row  m-0 p-0  ps-3">
                                <Col className="col-10">
                                  {data?.email && (
                                    <InputField
                                      isDisabled={true}
                                      outline
                                      className="col-12 p-0"
                                      value={data?.email}
                                      label={"Email"}
                                      placeholder="abc@mail.com"
                                      type="email"
                                      showErr={true}
                                      basic
                                      style={{ fontSize: "18px" }}
                                      required
                                      error={
                                        data?.email?.length === 0
                                          ? "Please enter Email"
                                          : ""
                                      }
                                    />
                                  )}
                                  {data?.phone && (
                                    <PhoneNum
                                      outline
                                      placeholder={t("Phone No ")}
                                      required={true}
                                      value={{
                                        countryCode: data?.countryCode,
                                        phone: data?.phone,
                                      }}
                                      label={"CountryCode"}
                                      className="col-12"
                                      isDisabled={true}
                                      showErr={true}
                                      error={
                                        data?.phone?.length === 0
                                          ? "Please enter Phone Number"
                                          : ""
                                      }
                                      countrySelectStyle={{ fontSize: "18x" }}
                                      inputStyle={{ fontSize: "18px" }}
                                      type="text"
                                    />
                                  )}
                                </Col>
                                <Col className="col-2 mt-3">
                                  <EditLink
                                    className="ms-2"
                                    onClick={() => setStep(0)}
                                  />
                                </Col>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <Row className="d-flex  align-items-center mt-1  p-0 ps-3">
                          <Col className="   col-md-6 col-12 my-3 d-flex flex-row w-100 m-0 p-0 ">
                            <div
                              className="ms-0 col-6 me-3"
                              style={{ minWidth: "180px" }}
                            >
                              <OtpInput
                                value={data?.otpValue}
                                onChange={(v) => {
                                  setData({ ...data, otpValue: v });
                                }}
                                style={{ fontSize: "18px" }}
                                error={
                                  data?.otpValue?.length === 0
                                    ? "please enter the OTP"
                                    : ""
                                }
                                className="flex-1 mb-1 w-100"
                              />
                            </div>
                            <Col className=" m-0 p-0 col-md-6 col-12 ms-3 me-0">
                              <button
                                className="ms-1 mb-4  btn btn-secondary waves-effect waves-light "
                                style={{
                                  width: "110px",
                                  margin: "auto",
                                  borderRadius: "30px",
                                  fontSIze: "16px",
                                }}
                                type="submit"
                                onClick={() => {
                                  // Resend()
                                  setStep(0);
                                }}
                              >
                                Resend OTP
                              </button>
                            </Col>
                          </Col>
                        </Row>
                        {isDisabled ? (
                          <Row>
                            <div className=" d-flex justify-content-end">
                              <Timer
                                setDisable={setIsDisabled}
                                initialMinute={0}
                                initialSeconds={20}
                              />
                            </div>
                          </Row>
                        ) : null}
                        {/* <div
                          className="d-flex mt-5  "
                          // style={{ marginLeft: "20px" }}
                        >
                          <div className="me-3 ps-5 ms-5 ">
                            <input
                              type="checkbox"
                              checked={isTermsSelected}
                              onClick={() => {
                                setIsTermsSelected(!isTermsSelected);
                              }}
                            />
                          </div>

                          <p className=" text-center font-size-16">
                            I Agree
                            <span className="m-1" style={{ color: "#ff0000" }}>
                              Terms & Conditions
                            </span>
                          </p>
                        </div> */}
                        <Row className="mb-3 ">
                          <div className="mt-1">
                            <button
                              className="btn btn-secondary waves-effect waves-light"
                              style={{
                                margin: "auto",
                                width: "100%",
                                borderRadius: "30px",
                                padding: "5px",
                              }}
                              type="submit"
                              onClick={onLogin}
                              disabled={data?.otpValue?.length != 4}
                            >
                              {loading ? (
                                <CardThreeBounce />
                              ) : (
                                <div style={{ fontSize: "18px" }}>Login</div>
                              )}
                            </button>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center font-size-16">
                  <p>
                    {webContent?.copyright ? webContent?.copyright : `© ${new Date().getFullYear()} Logic First Global Pvt Ltd`}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginOtp;
