
import 'chart.js/auto';
import React, { useContext } from "react";
import { Row } from "reactstrap";
import '../../../node_modules/react-vis/dist/style.css';
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import "./style.css";
// import DashboardCard from '../../components/DashboardCard/DashboardCard';
import { CustomerCard, CustomerRegistrationCard, SettledTripCard, SosCard, VehicleCard } from "../../components/DashboardCard";
import InvoiceAmountCard from '../../components/DashboardCard/InvoiceAmountCard';
import TripPaymentCard from '../../components/DashboardCard/TripPaymentCard';
import { TripStats } from '../../components/DashboardCard/TripStats';
import SetupProgress from './SetupProgress';

const Dashboard = () => {
  const { showError } = useContext(DialogContext);
  const { userContent } = useContext(UserContext);


  return (
    <React.Fragment >
      <div className="page-content mt-1" style={{ backgroundColor: "#FCFCFF" }}>
        <SetupProgress />
        <Row className='d-flex mt-2'>
          <CustomerCard className="col-3" />
          <CustomerRegistrationCard className="col-3" />
          <SosCard className="col-3" />
          <SettledTripCard className="col-3" />
        </Row>
        <Row className='d-flex flex-wrap mt-2'>

          {userContent?.rights?.includes(2) ?
            <TripStats />
            : null}

          {userContent?.rights?.includes(2) ?
            <VehicleCard
              title="Vehicle"
              showError={showError}
              showTotal
            />
            : null}

          {userContent?.rights?.includes(2) && userContent?.rights?.includes(4500) ?
            <TripPaymentCard /> : null}

          {userContent?.rights?.includes(2) && userContent?.rights?.includes(4500) ?
            <InvoiceAmountCard /> : null}
        </Row>
      </div>
    </React.Fragment >
  )
}

export default Dashboard