import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Col, Button } from "reactstrap";
import { CountrySelect, CurrencySelect } from "../../../components";
// import { ENDPOINTS } from "./CreditNoteConstant";
import {
  CardThreeBounce,
  InputField,
  DeleteLink,
  Checkbox,
} from "../../../components";
// import { DialogContext } from "../../store/context/DialogContext";
import CorpSubsService from "./../CorpSubsService";

const SMSActors = ({ data, setData, hasErr }) => {
  const [details, setdetails] = useState(
    data?.smsActors ? data?.smsActors : []
  );

  console.log(data);

  useEffect(() => {
    if (JSON.stringify(data?.smsActors) !== JSON.stringify(details))
      setData({ ...data, smsActors: details });
  }, [details]);

  return (
    <React.Fragment>
      <Row className="my-2">
        {details?.map((s, i) => (
          <Row className="shadow p-4 mb-3 bg-white rounded">
            <div
              onClick={() => {
                setdetails(() => {
                  let newData = details.slice();
                  newData.splice(i, 1);
                  return newData;
                });
              }}
              style={{ cursor: "pointer", position: "relative" }}
            >
              <i
                style={{ position: "absolute", right: 0, fontSize: "25px" }}
                className="dripicons-cross"
              />
            </div>

            <Row className="mt-4 mb-3">
              <Col className="col-lg">
                <InputField
                  identifier={i}
                  value={s?.url}
                  label={"URL"}
                  placeholder={"URL"}
                  onChange={(v) =>
                    setdetails(
                      details.map((f, x) => {
                        if (i === x) {
                          return {
                            ...s,
                            url: v,
                          };
                        }
                        return f;
                      })
                    )
                  }

                  type="text"
                />
              </Col>
            </Row>

            <Row className="mt-4 mb-3">
              <Col className="col-lg">
                <CountrySelect
                  identifier={i}
                  placeholder="Country"
                  value={s?.country}
                  label={"Country"}
                  onChange={(v) =>
                    setdetails(
                      details.map((f, x) => {
                        if (i === x)
                          return {
                            ...s,
                            country: v.value,
                          };
                        return f;
                      })
                    )
                  }

                />
              </Col>
              <Col className="col-lg">
                <Checkbox
                  label="Use Zoyride Default"
                  checked={s?.providedByZoyride}
                  onClick={() =>
                    setdetails(
                      details.map((f, x) => {
                        if (i === x) {
                          return {
                            ...s,
                            providedByZoyride: !s?.providedByZoyride,
                          };
                        }
                        return f;
                      })
                    )
                  }
                />
              </Col>
              <Col className="col-lg">
                <Checkbox
                  label="Provided By Zoyride"
                  checked={s?.useZoyrideDefault}
                  onClick={() =>
                    setdetails(
                      details.map((f, x) => {
                        if (i === x) {
                          return {
                            ...s,
                            useZoyrideDefault: !s?.useZoyrideDefault,
                          };
                        }
                        return f;
                      })
                    )
                  }
                />
              </Col>
            </Row>
          </Row>
        ))}
      </Row>
      <Row className="d-flex justify-content-end ">
        <Button
          className="col-sm-1"
          onClick={() => setdetails([...details, {}])}
        >
          Add +
        </Button>
      </Row>
    </React.Fragment>
  );
};

export default observer(SMSActors);
