import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { FaFilePdf, FaDownload } from 'react-icons/fa';
import "./styles.scss"
import Image from '../../components/Image/Image';
import PDFViewer from '../../components/Image/PDF';
const DocViewer = ({ documents = [] }) => {
  const [modal, setModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const toggleModal = () => setModal(!modal);

  const truncateFileName = (url) => {
    const fileName = url.split('/').pop();
    const [nameWithoutExtension, fileExtension = ''] = fileName.split(/(\.[a-zA-Z0-9]+$)/);
    const truncatedName = nameWithoutExtension.length > 10
      ? `${nameWithoutExtension.slice(0, 5)}...${nameWithoutExtension.slice(-5)}`
      : nameWithoutExtension;
    return `${truncatedName}${fileExtension}`;
  };

  const handleDocumentClick = (document) => {
    setSelectedDocument(document);
    toggleModal();
  };

  const isPdf = (doc) => doc?.type?.split('/')[0] === 'application' && doc.name.endsWith('.pdf') || doc?.key?.endsWith('.pdf') || doc?.endsWith('.pdf');


  return (
    <>
      {documents.map((document, index) => (
        <div key={index} className="zr_doc_field_file_link" onClick={() => handleDocumentClick(document.key)}>
          <FaFilePdf className="zr_doc_field_icon" />{truncateFileName(document?.key)}
        </div>
      ))}

      <Modal size="xl" isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>View Document</ModalHeader>
        <ModalBody className="zr_doc_field_modal_body  ">
          {selectedDocument ? (
            isPdf(selectedDocument) ? (
              <>
                <PDFViewer src={selectedDocument.key ?? selectedDocument} alt="Document" />
              </>
            ) : (
              <div className='d-flex justify-content-center align-items-center'>
                <Image src={selectedDocument.key ?? selectedDocument} alt="Document" />
              </div>

            )
          ) : <p>No document selected</p>}
        </ModalBody>
      </Modal>
    </>
  );
};

export default DocViewer;
