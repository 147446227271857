import { useContext, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DialogContext } from "../../../store/context/DialogContext";
import Invoiceservice from "../InvoiceService";
import { SelectField } from "../../../components";
import { ThreeBounce } from "better-react-spinkit";

const PRINT_OPTIONS = [
    { label: "Without Passenger", fieldName: 'withoutPax', default: false },
    { label: "Without Pickup", fieldName: 'withoutPickup', default: false },
    { label: "Without Drop", fieldName: 'withoutDrop', default: true },
    { label: "Print on letterhead", fieldName: 'withoutHeader', default: false }
]
const SORT_OPTIONS = [
    { label: "Ascending", value: 1 },
    { label: "Descending", value: 2 }
]

const InvoiceTypeData = [
    { label: 'Invoice A', value: 'Z_invoice_A' },
    { label: 'Invoice B', value: 'Z_invoice_B' }
]
const BulkPrintConfiguration = ({ invoice_ids = [], modal, setModal, loading, setLoading, onSuccess, ...modalProps }) => {
    const { showError } = useContext(DialogContext);
    const [configuration, setConfiguration] = useState({
        templateName: 'Z_invoice_A'
    });
    const toggleModal = () => {
        setModal(!modal);
    }
    const onSave = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await Invoiceservice.print([...invoice_ids] || [], { ...configuration } || {});
            setConfiguration({ templateName: 'Z_invoice_A' });
            // onSuccess()
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal
            isOpen={modal}
            toggle={toggleModal}
            className="zr_action_prevent_modal"
            {...modalProps}

        >
            <ModalHeader toggle={toggleModal}>
                Print Invoice
            </ModalHeader>
            <ModalBody>
                <div className="mt-4">
                    <SelectField
                        data={InvoiceTypeData}
                        value={configuration?.templateName}
                        onChange={(v) => {
                            setConfiguration({ ...configuration, templateName: v?.value });
                        }}
                        // showErr={hasErr}
                        label={"Invoice Type"}
                        required={true}
                        className="col-sm-12 col-12"
                    />
                </div>
                <div className="mt-4">
                    <SelectField
                        data={SORT_OPTIONS}
                        value={configuration?.sortByTime}
                        onChange={(v) => {
                            setConfiguration({ ...configuration, sortByTime: v?.value });
                        }}
                        // showErr={hasErr}
                        label={"Sort by Time"}
                        className="col-sm-12 col-12"
                    />
                </div>
                <div className="d-flex justify-content-start" style={{ width: "100%", flexWrap: "wrap" }}>
                    {PRINT_OPTIONS.map((obj, index) => {
                        return <div key={index} className="d-flex col-sm-6  align-items-center my-2">
                            <Input type="checkbox"
                                defaultValue={obj.default}
                                checked={configuration?.[obj.fieldName]}
                                onClick={() => {
                                    setConfiguration({
                                        ...configuration,
                                        [obj.fieldName]: !configuration?.[obj.fieldName]
                                    })
                                }} />
                            <span className="mx-2">
                                {obj.label}
                            </span>
                        </div>
                    })}
                </div>

            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end my-2">
                    {
                        loading ? <Button
                            className="mx-2"
                            color="success"
                        >
                            <ThreeBounce size={10} color="#FFFFFF" />

                        </Button> : <Button
                            onClick={onSave}
                            className="mx-2"
                            color="success"
                        >
                            <div style={{ margin: "2px", float: "right" }}>Print</div>

                        </Button>
                    }
                </div>
            </ModalFooter>
        </Modal>
    );
}
export default BulkPrintConfiguration;