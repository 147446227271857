export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl, corpId) => {
    if (!corpId) {
      let url = `/api/role/grid?rows=${sizePerPage}`;
      if (filterUrl) url += filterUrl;
      return url;
    }
    // return `/api/supervisor/getAllSupervisor?corp_id=${corpId}`;
  },
  get: (id) => `/api/role/${id}/detail`,
  map: `/api/access/right/map`,

  getKey: "/api/supervisor/getKeySecret",

  save: "/api/role/save",
  delete: (id) => `/api/role/${id}/delete`,

};
