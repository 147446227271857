export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/addon/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  get: (id) => `/api/addon/${id}/detail`,
  save: `/api/addon/create`,
  delete: (id) => `/api/addon/${id}/delete`,
  update: `/api/addon/update`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Name",
    filterName: "name",
    type: "select",
    data: [
      {
        label: "Perfume",
        value: "Perfume",
      },
      {
        label: "Car perfume",
        value: "Car perfume",
      },
      {
        label: "check",
        value: "check",
      },
      {
        label: "Snacks",
        value: "Snacks",
      },
      {
        label: "Water",
        value: "Water",
      },
      {
        label: "Extra",
        value: "extra",
      },
    ],
  },
];

