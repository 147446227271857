import React, { useContext, useEffect, useState } from 'react';
import { FaCarAlt } from "react-icons/fa";
import { Card, Row } from 'reactstrap';
import SplineChart from '../../../components/Charts/SplineChart/SplineChart';
import DetailDurationCard from '../../../components/DetailDurationCard/DetailDurationCard';
import RecentCard from '../../../components/RecentCard/RecentCard';
import TotalRevenueCard from '../../../components/TotalRevenueCard/TotalRevenueCard';
import { DialogContext } from "../../../store/context/DialogContext";
import { doPOST } from '../../../util/HttpUtil';
import { getMonthRangeFilter } from '../../../util/Util';

const OverView = ({ trip, vehicle_id }) => {
  const [tripData, setTripData] = useState([]);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);


  // STATS FETCHING FUNCTION
  const fetchVehicleStats = async (vehicle_id) => {
    setLoading(true);
    try {
      let response = await doPOST("/api/trip/vehicle/statistics", {
        "btwFilters": {
          "tripDate": getMonthRangeFilter(6)
        },
        "filters": {
          "vehicle._id": vehicle_id
        }
      });
      setTripData(response?.data);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (vehicle_id) {
      fetchVehicleStats(vehicle_id);
    }
  }, [vehicle_id]);

  return (
    <div>
      <Row>
        <div className='col-lg-4 col-md-12 col-sm-10 my-3 mx-4'>
          <Card className='p-3'>
            <TotalRevenueCard loading={loading} tripData={tripData} />
          </Card>
          <Card className='p-2 py-4'>
            <DetailDurationCard
                tripData={tripData}
                loading={loading}
            />
          </Card>
        </div>
        <div className='col-lg-7 col-md-12 col-sm-10 my-3'>
          <Card className='p-2'>
            <SplineChart
              tripData={tripData}
            />
          </Card>
        </div>
      </Row>
      <Row>
      <Card className='col-lg-4 col-md-12 col-sm-10 mb-3 mx-4'>
          <RecentCard
            header="Recent Trips"
            icon={<FaCarAlt size={20} />}
            recentArray={trip}
            type={"trip"}
          />
        </Card>
      </Row>
    </div>
  );
};

export default OverView;
