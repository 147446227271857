import React, { useEffect, useState } from "react";
import { pureFinalPropsSelectorFactory } from "react-redux/es/connect/selectorFactory";
import { Button, Col, Row } from "reactstrap";
import { InputField, SelectField } from "../../../../../components";

export default function PaymentOptions({ data, setData }) {
  const [details, setdetails] = useState(data?.types ? data?.types : []);

  console.log("Inside payment");

  useEffect(() => {
    if (JSON.stringify(data?.types) !== JSON.stringify(details))
      setData({ ...data, types: details });
  }, [details]);
  console.log("data: ", data);
  const selectType = data?.paymentTypeOptions?.map((e) => {
    return { label: e, value: e };
  });

  return (
    <>
      <Row className="d-flex justify-content-end ">
        <Button
          className="col-sm-2"
          onClick={() => setdetails([...details, {}])}
        >
          Add +
        </Button>
      </Row>

      {details?.map((s, i) => (
        <Row className="border-bottom border-secondary pt-4">
          <div
            className=""
            onClick={() => {
              setdetails(() => {
                let newData = details.slice();
                newData.splice(i, 1);
                return newData;
              });
            }}
            style={{ cursor: "pointer", position: "relative" }}
          >
            <i
              style={{ position: "absolute", right: 0, fontSize: "25px" }}
              className="dripicons-cross"
            />
          </div>

          <Row className="mt-4 mb-3">
            <Col className="col-12 col-sm-6 mb-3">
              <InputField
                identifier={i}
                placeholder="Title"
                value={s?.title}
                label={"Title"}
                onChange={(v) =>
                  setdetails(
                    details.map((f, x) => {
                      if (i === x)
                        return {
                          ...s,
                          title: v,
                        };
                      return f;
                    })
                  )
                }

                type="text"
              />
            </Col>
            <Col className="col-12 col-sm-6">
              <SelectField
                data={selectType ? selectType : [
                  { value: "0", label: "ALL" },
                  { value: "1", label: "UPI" },
                  { value: "2", label: "RUPAY_DEBIT_CARD" },
                  { value: "3", label: "DEBIT_CARD" },
                  { value: "4", label: "CREDIT_CARD" },
                  { value: "5", label: "NETBANKING" },
                  { value: "6", label: "EMI" },
                  { value: "7", label: "WALLET" }
                ]}
                value={s?.type}
                onChange={(v) =>
                  setdetails(
                    details.map((f, x) => {
                      if (i === x)
                        return {
                          ...s,
                          type: v.value,
                        };
                      return f;
                    })
                  )
                }
                label={"Type"}
              />
            </Col>
          </Row>

          <Row className="mt-3 mb-3">
            <Col className="col-12 col-sm-6 mb-3">
              <InputField
                identifier={i}
                placeholder="Name"
                value={s?.charge?.name}
                label={"Name"}
                onChange={(v) =>
                  setdetails(
                    details.map((f, x) => {
                      if (i === x)
                        return {
                          ...s,
                          charge: {
                            ...s?.charge,
                            name: v,
                          },
                        };
                      return f;
                    })
                  )
                }

                type="text"
              />
            </Col>

            <Col className="col-12 col-sm-6">
              <Row>
                <Col className="col-9 col-sm-10">
                  <InputField
                    className="col-lg"
                    identifier={i}
                    placeholder="Amount"
                    value={s?.charge?.amount}
                    label={"Amount"}
                    onChange={(v) =>
                      setdetails(
                        details.map((f, x) => {
                          if (i === x)
                            return {
                              ...s,
                              charge: {
                                ...s?.charge,
                                amount: v,
                              },
                            };
                          return f;
                        })
                      )
                    }

                    type="number"
                  />
                </Col>
                <Col className="col-3 col-sm-2">
                  <Button
                    onClick={() => {
                      setdetails(
                        details.map((curr, ind) => {
                          if (i === ind) {
                            return {
                              ...s,
                              charge: {
                                ...s?.charge,
                                isAbs: s?.charge?.isAbs ? false : true,
                              },
                            };
                          }
                          return curr;
                        })
                      );
                    }}
                  >
                    {s?.charge?.isAbs ? "/-" : "%"}
                  </Button>
                </Col>
              </Row>


            </Col>
          </Row>
        </Row>
      ))}
    </>
  );
}
