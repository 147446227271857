import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { observer } from "mobx-react-lite";
import {
  CardThreeBounce,
  CheckButton,
  DeleteLink,
  DeleteButton,
  SaveButton,
  EditLink,
  Layout,
  toggleFullscreen,
} from "../../components";
import TaxSlabService from "./TaxSlabService";

import EditTaxSlab from "./EditTaxSlab";

import { STRUCTURE } from "./TaxSlabConstant";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { ToggleButton } from "react-bootstrap";

const TaxSlab = (props) => {
  const { id } = useParams();
  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [hasErr, setHasErr] = useState(false);
  const [showDetailPage, setShowDetailPage] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    TaxSlabService.fetch();
  }, []);

  let navigate = useNavigate();
  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    setShowDetailPage(window.location.pathname === "/taxslab/edit" || id);
  }, [id]);

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete TaxSlab ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        TaxSlabService.delete(id);
      } catch (e) {
        showError(e);
      }
    }
  };

  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await TaxSlabService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setHasErr(true);
    // console.log(data);R
    if (!data.name) return;
    try {
      await TaxSlabService.save(data);
      showMessage("TaxSlab saved successfully");
      setData({});
      navigate(`/taxslab`);
      fetchData();
    } catch (error) {
      showError(error)
    }
  };
  const handleRowClick = async (taxslabId) => {
    if (showDetailPage && taxslabId !== selected_id && isEditDataChanged) {
      let isProceed = await showConfirm({
        isSaveWarn: true,
        title: t(`Do you want to save the changes ?`),
        description: t("Your changes will be lost if you don't save them"),
      });
      if (isProceed) onRowClick(taxslabId);
    } else onRowClick(taxslabId);
  };

  const onRowClick = (id) => {
    if (!props?.insidePane) navigate(`/taxslab/edit/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };

  if (loading) return <CardThreeBounce />;

  return (
    <Layout
      showDetailPage={props?.popup ? undefined : showDetailPage}
       
      title={t("Tax Slab")}
      filterStructure={STRUCTURE}
      onApplyFilter={fetchData}
      onAddClick={props?.popup ? null : () => {
        if (!props?.insidePane) navigate(`/taxslab/edit`);
        setShowDetailPage(true);
        setSelected_id(null);
      }}
      backDetailPage={() => {
        setShowDetailPage(false);
        if (!props?.insidePane) navigate("/taxslab");
        setSelected_id(false);
      }}
      page={TaxSlabService.page}
      rows={TaxSlabService.rows}
      total={TaxSlabService.totalRecords}
      onPageChange={async (page, rows) => {
        await TaxSlabService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Amount</Th>
            {!props?.popup && <Th>Is Default</Th>}
            {!props?.popup && <Th>For Sez</Th>}
            <Th className="float-end">Action</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {TaxSlabService.records?.map((taxslab) => (
            <Tr
              key={taxslab?._id}
            // onClick={showDetailPage && (() => handleRowClick(taxslab?._id))}
            >
              <Td>{taxslab?.name}</Td>

              <Td>{taxslab?.desc}</Td>
              <Td>{taxslab.amount} %</Td>
              {!props?.popup && <Td>{taxslab.isDefault ? "True" : "False"}</Td>}
              {!props?.popup && <Td>{taxslab.forSEZ ? "True" : "False"}</Td>}

              <Td>
                {!props?.popup && <EditLink
                  onClick={() => {
                    if (!props?.insidePane)
                      navigate(`/taxslab/edit/${taxslab?._id}`);
                    setSelected_id(taxslab?._id);
                    setShowDetailPage(true);
                  }}
                />}
                {!props?.popup && <DeleteLink
                  onClick={(event) => {
                    onDelete(event, taxslab?._id);
                  }}
                />}
                {props?.popup ? (
                  <CheckButton
                    onClick={() => {
                      TaxSlabService.addToInvoice(props?.idnt, taxslab?._id)
                      props?.setTaxModal(false)
                      props?.gotData(props?.idnt)
                    }}
                  />
                ) : null}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>

      <Layout.DetailPageTitle>
        <h5>Tax Slab</h5>
      </Layout.DetailPageTitle>
      <Layout.DetailPageBody>
        <EditTaxSlab
          insidePane={props?.insidePane}
          onChange={(v) => setIsEditDataChanged(v)}
          hasErr={hasErr}
          setHasErr={setHasErr}
          id={selected_id}
          back={() => {
            setShowDetailPage(false);
            if (!props?.insidePane) navigate("/taxslab");
            setSelected_id(false);
          }}
          setData={setData}
          data={data}
          fetchData={fetchData}
        />
      </Layout.DetailPageBody>

      <Layout.DetailPageFooter>
        <Row>
          <Col>
            <div
              className="d-flex justify-content-end mt-1"
              style={{ marginTop: "-2.5rem" }}
            >
              {data?._id && (
                <DeleteButton onClick={(e) => onDelete(e, data?._id)} />
              )}
              <SaveButton onClick={submitHandler} />
            </div>
          </Col>
        </Row>
      </Layout.DetailPageFooter>
    </Layout>
  );
};

export default observer(TaxSlab);
