import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { ModalComponent } from "../../../components/index";
import Supplier from "../../../pages/Suppliers/Supplier";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { GroupedSelectField } from "../../SelectField/GroupedSelectField";
import SupplierCompanyService from "./SupplierCompanyService";
import useDebounce from "../../../util/Debounce";

const SupplierCompany = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  asLabel,
  plain,
  outline,
  supplierId,
}) => {
  const [suppliers, setSuppliers] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const fetchSuppliers = async () => {
    try {
      await SupplierCompanyService.fetch();
      setSuppliers(toJS(SupplierCompanyService.suppliers));
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  useEffect(() => {
    if (supplierId && SupplierCompanyService.suppliers?.length) {
      const supplier = SupplierCompanyService.suppliers?.find(supplier =>
        supplier?.options?.length && supplier.options[0]?.supplier_id === supplierId
      );

      if (!supplier) {
        SupplierCompanyService.getDataBySupplierId(supplierId)
      }
    }
  }, [supplierId]);

  useEffect(() => {
    setSuppliers(toJS(SupplierCompanyService.suppliers));
  }, [SupplierCompanyService.suppliers]);


  useEffect(() => {

  }, [supplierId])

  const fetchAndUpdateSupplier = async (id) => {
    try {
      const supplier = suppliers.some(obj => obj?.options?.some(option => option.value === id));
      if (!supplier) {
        await SupplierCompanyService.get(id);
        setSuppliers(toJS(SupplierCompanyService.suppliers));
      }

    } catch (error) {

    }
  }

  useEffect(() => {
    if (value && suppliers.length) {
      fetchAndUpdateSupplier(value)
    }
  }, [suppliers, value]);




  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectSupplier = (selectedValue) => {
    let selectedSupplier = toJS(selectedValue)
    const supplier = suppliers.find(supplier => supplier._id === selectedSupplier?._id)
    if (!supplier) {
      SupplierCompanyService.pushNewData(supplier);
      setSuppliers(toJS(SupplierCompanyService.suppliers));
    }
    onChange({ value: selectedValue });
    closeModal();
  };

  const fetchMoreData = () => {
    const page = SupplierCompanyService.page + 1;
    const rowsPerPage = SupplierCompanyService.rowsPerPage;
    const total = SupplierCompanyService.total;
    console.log({ page, total, rowsPerPage })
    if (page > 0 && page <= (total % rowsPerPage === 0 ? (total / rowsPerPage) : (total / rowsPerPage) + 1)) {
      SupplierCompanyService.onPaginationChange(page, rowsPerPage);
    }
  }

  const fetchDataOnSearch = useDebounce((value) => {
    const filterUrl = `name=${value}`;
    SupplierCompanyService.fetch(filterUrl);
  }, [500]
  );

  const renderSelectField = ({ disabled }) => (
    <GroupedSelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      className={className}
      onChange={(v) => {
        onChange(v)
      }}
      data={suppliers}
      required={required}
      value={value}
      grouped
      label={label || (!isDisabled && t("Supplier"))}
      error={!value ? t(`Please Select ${label || "Supplier"}`) : ""}
      showErr={showErr}
      plain={plain}
      outline={outline}
      inline={inline}
      borderNone={borderNone}
      onInputChange={fetchDataOnSearch}
      onMenuScrollToBottom={fetchMoreData}
    />
  );


  return (
    <>
      {asLabel ? (<div>{suppliers?.find((supplier) => {
        const foundOption = supplier?.options?.find((option) => option._id === value);
        return foundOption?.label;
      })?.label ?? <div style={{ color: "grey" }}>No record Found</div>}</div>) :
        <>
          {modal && (
            <ModalComponent
              position={"top"}
              size={"medium"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Supplier")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <Supplier
                  value={value}
                  insidePane={true}
                  onSelect={selectSupplier}
                />
              </ModalComponent.Body>

            </ModalComponent>
          )}

          {suppliers.length > 0
            ? renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      }
    </>
  );
};

export default observer(SupplierCompany);
