import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./BranchConstant";
import { doGET, doPOST } from "../../util/HttpUtil";

class Service {

  records = [];
  totalRecords = 0;
  places = [];
  page = 1;
  rows = 20;

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async function (filterUrl) {
    const response = await doGET(ENDPOINTS.grid(this.page, this.rows, filterUrl))

    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }
    console.log(response, "zoyride")
    return response?.data?.rows;

  };

  get = async function (id) {
    const response = await doGET(ENDPOINTS.get(id))
    if (response.status === 200) {
      return response.data;
    }
  };

  detail = async function (id) {
    const response = await doGET(ENDPOINTS.detail(id))
    if (response.status === 200) {
      return response.data;
    }
  };

  getname = async function (data) {
    const response = await doGET(ENDPOINTS.getname, data)
    if (response.status === 200) {
      return response.data;
    }
  };


  save = async function (data) {
    const response = await doPOST(ENDPOINTS.save, data)
    if (response.status === 200) {
      return response.data;
    }
  };

  delete = async function (id) {
    const response = await doGET(ENDPOINTS.delete(id))
    if (response.status === 200) {
      this.fetch();
    }
  };
  edit = async function (data) {
    await doPOST(ENDPOINTS.edit, data)
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch();
  }

}

const BranchService = new Service();
export default BranchService
