import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import CompanyLabelService from './CompanyLabelService';
import { ThreeBounce } from "better-react-spinkit";
import { toJS } from 'mobx';

const CompanyLabel = ({ corpType, value, corp_id, multi = false }) => {
  const [companyLabel, setCompanyLabel] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompany = async () => {
      setLoading(true);
      const companyData = await CompanyLabelService.fetchCompany(corpType, corp_id);
      if (multi) {
        const labels = companyData?.filter(company => (value ?? [])?.includes(company?._id))?.map(company => company?.label).join(", ");
        setCompanyLabel(labels)
      } else {
        const foundCompany = companyData?.find(comp => comp.value === value);
        setCompanyLabel(foundCompany?.label);
      }
      setLoading(false);
    };

    fetchCompany();
  }, [corpType, corp_id, value,multi]);

  if (loading) {
    return <ThreeBounce size={10} color="pink" className="zr_company-loader" />;
  }

  return <div className="zr_company-label">{companyLabel || 'Company not found'}</div>;
};

export default observer(CompanyLabel);
