import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import Dropzone from 'react-dropzone';
import { formatBytes } from '../../components';
import { DialogContext } from '../../store/context/DialogContext';
import './EmailAttachment.css';
import FileViewer from '../../components/DocUpload/FileViewer';

const EmailAttachment = ({ attachments, setAttachments }) => {
    const { showError } = useContext(DialogContext);

    const handleAcceptedFiles = (files) => {
        const acceptedFiles = files.filter(file => {
            const fileType = file?.type?.split("/")[0];
            const isAcceptedType = fileType === "image" || (fileType === "application" && file.name.endsWith(".pdf"));
            const isWithinSizeLimit = file.size >= (5 * 1024) && file.size <= (100 * 1024 * 1024);

            if (!isAcceptedType) {
                showError("Only PDF files and images are accepted!");
                return false;
            }

            if (!isWithinSizeLimit) {
                showError(`File must be between 5 KB and 100 MB. ${file.name} is not within the limit.`);
                return false;
            }

            return isAcceptedType && isWithinSizeLimit;
        });

        const updatedAttachments = [...attachments, ...acceptedFiles];
        setAttachments(updatedAttachments);
    };

    const handleDelete = (index) => {
        const updatedAttachments = attachments.filter((_, i) => i !== index);
        setAttachments(updatedAttachments);
    };

    return (
        <div className="zr_communication_email_attachment">
            <div className="zr_communication_email_file_list">
                {attachments.map((file, index) => (
                    <div key={index} className="zr_communication_email_file_item">
                        <FileViewer document={file} />
                        <span className="zr_communication_email_file_name">{file.name}</span>
                        <span className="zr_communication_email_file_size">{formatBytes(file.size)}</span>
                        <button
                            className="zr_communication_email_file_delete"
                            onClick={() => handleDelete(index)}
                        >
                            &times;
                        </button>
                    </div>
                ))}
            </div>
            <Dropzone onDrop={handleAcceptedFiles}>
                {({ getRootProps, getInputProps }) => (
                    <div className="zr_communication_email_dropzone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="zr_communication_email_dropzone_text">
                            Drag & Drop your files here or Click to Select Files
                        </div>
                        <div className="zr_communication_email_dropzone_size">
                            Maximum file size: 100 MB
                        </div>
                    </div>
                )}
            </Dropzone>
        </div>
    );
};

export default observer(EmailAttachment);
