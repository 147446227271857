import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import { FaChevronDown, FaChevronRight, FaCircle, FaCog, FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import { PiDotsThreeVerticalBold } from 'react-icons/pi';
import SpaceService from '../SpaceService';
import '../style.scss';
import { FaRegCircle } from "react-icons/fa";
import { PiFlower, PiFlowerFill } from "react-icons/pi";
import { DialogContext } from '../../../store/context/DialogContext';
import { useOutsideAlerter } from '../../dnd/board/helper';

const Icon = ({ type, selected }) => {
    if (type === 'single') {
        return selected ? <PiFlowerFill /> : <PiFlower />
    } else {
        return selected ? <FaCircle /> : <FaRegCircle />
    }

}

const FolderItem = ({
    folder,
    onToggleCollapse,
    onSpaceClick,
    module,
    handleManage,
    type
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [editId, setEditId] = useState({});
    const [editName, setEditName] = useState('');
    const [isAddingChild, setIsAddingChild] = useState(false);
    const [newChildName, setNewChildName] = useState('');
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    useOutsideAlerter(dropdownRef, () => setIsOpen(false));
    const { showError } = useContext(DialogContext);

    const showAddChildInput = () => {
        setIsAddingChild(true);
        setTimeout(() => inputRef.current && inputRef.current.focus(), 0);
    };

    const handleAddChildSpaceKeyDown = async (event) => {
        try {
            if (event.key === 'Enter' && newChildName.trim() !== '') {
                const { _id, name, ...spacePayload } = folder;
                await SpaceService.save({
                    module,
                    type: 0,
                    name: newChildName.trim(),
                    parentSpace_id: folder._id,
                    ...(spacePayload ?? {})
                });
                setNewChildName('');
                setIsAddingChild(false);
                SpaceService.fetch(module);
            }
        } catch (error) {
            showError(error);
        }
    };

    const handleEditSpace = async ({ e, id }) => {
        try {
            if (e.key === 'Enter') {
                await SpaceService.edit({ ...editId, name: editName });
                setEditId({});
                setEditName('');
            }
        } catch (error) {
            showError(error);
        }
    };

    const handleDeleteSpace = async (id) => {
        try {
            await SpaceService.delete(id, module);
        } catch (error) {
            showError(error);
        }
    };



    return (
        <div ref={dropdownRef} className={`zr_status_dropdown cursor-pointer my-1 px-2  w-100 folder-item-container ${SpaceService.selectedFolder?._id === folder._id ? ' selectedFolder ' : ''} ${folder?.parentSpace_id ? 'child-folder-item' : ''}`} >
            {(editId?._id && (editId?._id === folder._id)) ? (
                <input
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                    onKeyDown={(e) => handleEditSpace({ e, id: folder._id })}
                    placeholder='Edit Folder'
                    autoFocus
                    className='m-0'
                    style={{ height: '30px' }}
                />
            ) : (
                <div className={` folder-item d-flex align-items-center`} onClick={() => onSpaceClick(folder)}>
                    {/* (!folder?.parentSpace_id && type !== 'single') && */ <span className="me-2" onClick={onToggleCollapse}>
                        {/* {folder.collapsed ? <FaChevronRight /> : <FaChevronDown />} */}
                        <Icon type={type} selected={SpaceService.selectedFolder?._id === folder._id} />
                    </span>}
                    {/* {type === 'single' ? <FaCircle className='me-2' /> : null} */}
                    <div className={!folder?.parentSpace_id ? 'fw-400' : ''} style={{ fontSize: '14px' }}>
                        {folder.name}
                    </div>
                    {type !== 'single' ? <div className="icon-container d-flex align-items-center ms-auto">
                        <div onClick={(e) => {
                            e.stopPropagation();
                            setIsOpen(true);
                        }}><PiDotsThreeVerticalBold size={20} /></div>
                        {!folder?.parentSpace_id && <FaPlus className="ms-2 add-icon" onClick={showAddChildInput} />}
                    </div> : null}
                    {isOpen && (
                        <ul className="zr_status_list">
                            <li className={`zr_status_item `} onClick={(e) => { e.stopPropagation(); setEditName(folder.name); setEditId(folder); }}>
                                <FaPen className="me-2 edit-icon" /> Rename
                            </li>
                            <li className={`zr_status_item `} onClick={(e) => { e.stopPropagation(); handleDeleteSpace(folder._id); }}>
                                <FaTrash className="me-2 delete-icon" /> Delete
                            </li>
                            <li className={`zr_status_item `} onClick={(e) => { e.stopPropagation(); handleManage(folder); }}>
                                <FaCog className="me-2 manage-icon" /> Manage
                            </li>
                        </ul>
                    )}
                </div>
            )}
            {isAddingChild && (
                <input
                    ref={inputRef}
                    type="text"
                    value={newChildName}
                    onChange={(e) => setNewChildName(e.target.value)}
                    onKeyDown={handleAddChildSpaceKeyDown}
                    onBlur={() => {
                        setNewChildName('');
                        setIsAddingChild(false);
                    }}
                    style={{ marginLeft: '18px' }}
                    className="child-folder-input"
                    placeholder="Folder name"
                />
            )}

        </div>
    );
};

export default observer(FolderItem);
