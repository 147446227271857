import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DashboardService from "../../pages/Dashboard/DashboardService";
import DateSelect from '../DateSelects/DateSelect';
import MonthPicker from '../Dropdowns/MonthSelect/MonthPicker';
import ToggleSelector from '../Toggle/ToggleSelector';
import { FaCircle } from "react-icons/fa";
import { Card, Col, Row } from 'reactstrap';
import "./TripStats.css"
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { SlUserFemale } from "react-icons/sl";
import Skeleton from 'react-loading-skeleton';

export const TripStats = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        created: {},
        confirmed: {},
        ready: {},
        dispatched: {},
        live: {},
        completed: {},
        cancelled: {},
        invoiced: {},
        rejected: {}
    });
    const [selectedDate, setSelectedDate] = useState(parseInt(moment().format('YYYYMMDD')));
    const [selectedMonth, setSelectedMonth] = useState(parseInt(moment().format('YYYYMM')));
    const [toggle, setToggle] = useState('daily');
    const navigate = useNavigate();

    const redirect = (v) => {
        if (toggle == "daily") {
            return navigate(`/booking?fromDate=${selectedDate}&tillDate=${selectedDate}&status=${v}`);
        } else {
            const year = Math.floor(selectedMonth / 100);
            const month = selectedMonth % 100;
            const from = parseInt(moment(`${year}-${month}-01`).startOf('month').format('YYYYMMDD'));
            const till = parseInt(moment(`${year}-${month}-01`).endOf('month').format('YYYYMMDD'));
            return navigate(`/booking?fromDate=${from}&tillDate=${till}&status=${v}`);
        }
    }

    const fetchStats = async ({ startDate, endDate }) => {
        if (loading) return;

        setLoading(true);

        try {
            const tripBody = {
                groupBy: {
                    status: "status"
                },
                ...(toggle === 'daily'
                    ? { filters: { tripDate: startDate || endDate } }
                    : { btwFilters: { tripDate: [startDate, endDate] } })
            };

            const passengerBody = {
                unwind: "passengers",
                groupBy: {
                    gender: "passengers.salutation",
                    status: "status"
                },
                btwFilters: {
                    tripDate: [startDate, endDate]
                }
            };

            const tripResponse = await DashboardService.fetchTripStats(tripBody);
            const passengerResponse = await DashboardService.fetchTripStats(passengerBody);

            const calculateTripCount = (statusFilters) =>
                tripResponse
                    .filter(item => statusFilters.includes(item._id.status))
                    .reduce((acc, item) => acc + item.count, 0);

            const calculatePassengerCounts = (statusFilters, genderFilters) => {
                if (!genderFilters || genderFilters.length === 0) {
                    return passengerResponse
                        .filter(item => statusFilters.includes(item._id.status))
                        .reduce((acc, item) => acc + item.count, 0);
                } else {
                    return passengerResponse
                        .filter(item => statusFilters.includes(item._id.status) && genderFilters.includes(item._id.gender))
                        .reduce((acc, item) => acc + item.count, 0);
                }
            };

            setData({
                new: {
                    count: calculateTripCount([0]),
                    femalePassengers: calculatePassengerCounts([0], ["Mrs", "Ms", "Miss"]),
                    totalPassengers: calculatePassengerCounts([0], []),
                    type: 0,
                    backgroundColor: "#AEB6BF",
                    color: "#000",
                },
                confirmed: {
                    count: calculateTripCount([1]),
                    type: 1,
                    femalePassengers: calculatePassengerCounts([1], ["Mrs", "Ms", "Miss"]),
                    totalPassengers: calculatePassengerCounts([1], []),
                    backgroundColor: "#2980B9",
                    color: "#fff",
                },
                ready: {
                    count: calculateTripCount([2]),
                    femalePassengers: calculatePassengerCounts([2], ["Mrs", "Ms", "Miss"]),
                    type: 2,
                    totalPassengers: calculatePassengerCounts([2], []),
                    backgroundColor: "#A569BD",
                    color: "#fff",
                },
                dispatched: {
                    count: calculateTripCount([3]),
                    femalePassengers: calculatePassengerCounts([3], ["Mrs", "Ms", "Miss"]),
                    totalPassengers: calculatePassengerCounts([3], []),
                    backgroundColor: "#FFBF00",
                    type: 3,
                    color: "#000",
                },
                live: {
                    count: calculateTripCount([4]),
                    femalePassengers: calculatePassengerCounts([4], ["Mrs", "Ms", "Miss"]),
                    totalPassengers: calculatePassengerCounts([4], []),
                    backgroundColor: "#FF7F50",
                    type: 4,
                    color: "#fff",
                },
                completed: {
                    count: calculateTripCount([5]),
                    femalePassengers: calculatePassengerCounts([5], ["Mrs", "Ms", "Miss"]),
                    totalPassengers: calculatePassengerCounts([5], []),
                    backgroundColor: "#52BE80",
                    type: "5",
                    color: "#fff",
                },
                cancelled: {
                    count: calculateTripCount([6]),
                    femalePassengers: calculatePassengerCounts([6], ["Mrs", "Ms", "Miss"]),
                    totalPassengers: calculatePassengerCounts([6], []),
                    backgroundColor: "#F1948A",
                    type: "6",
                    color: "#fff",
                },
                rejected: {
                    count: calculateTripCount([10]),
                    femalePassengers: calculatePassengerCounts([10], ["Mrs", "Ms", "Miss"]),
                    totalPassengers: calculatePassengerCounts([10], []),
                    backgroundColor: "#7B241C",
                    type: 10,
                    color: "#fff",
                },
                invoiced: {
                    count: calculateTripCount([19,20]),
                    femalePassengers: calculatePassengerCounts([19, 20], ["Mrs", "Ms", "Miss"]),
                    totalPassengers: calculatePassengerCounts([19, 20], []),
                    backgroundColor: "#207645",
                    type: "19|20",
                    color: "#fff",
                },
                settled: {
                    count: calculateTripCount([29, 30]),
                    femalePassengers: calculatePassengerCounts([29, 30], ["Mrs", "Ms", "Miss"]),
                    totalPassengers: calculatePassengerCounts([29, 30], []),
                    backgroundColor: "#95A5A6",
                    type: "29|30",
                    color: "#fff",
                },
            });

        } catch (error) {
            console.error("Error fetching stats:", error);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (toggle === 'daily') {
            fetchStats({ startDate: selectedDate, endDate: selectedDate });
        } else {
            const year = Math.floor(selectedMonth / 100);
            const month = selectedMonth % 100;
            const from = parseInt(moment(`${year}-${month}-01`).startOf('month').format('YYYYMMDD'));
            const till = parseInt(moment(`${year}-${month}-01`).endOf('month').format('YYYYMMDD'));
            fetchStats({ startDate: from, endDate: till });
        }
    }, [selectedDate, selectedMonth, toggle]);

    const getTotalPassengers = () => {
        return Object.values(data).reduce((acc, item) => acc + (item.totalPassengers || 0), 0);
    };

    return (
        <Col sm={12}>
            <Card className="py-2 px-4 flex-1" style={{ height: "fit-content" }}>
                <div className="zr_trip_stats">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="font-size-14 my-2 d-flex align-items-center">
                            <h2><b>{"Trips"}</b></h2>
                        </div>

                        <div className="d-flex align-items-center">
                            <ToggleSelector
                                style={{ marginRight: "10px" }}
                                data={[
                                    { label: "Daily", value: "daily" },
                                    { label: "Monthly", value: "monthly" },
                                ]}
                                zeroMargin
                                value={toggle}
                                compact
                                tabClassName={"px-2"}
                                onChange={setToggle}
                            />

                            {toggle === 'daily' ? (
                                <DateSelect
                                    value={selectedDate}
                                    onChange={setSelectedDate}
                                    type="text"
                                    noLabel
                                    className={"my-0"}
                                />
                            ) : (
                                <MonthPicker
                                    value={selectedMonth}
                                    onChange={setSelectedMonth}
                                    type="text"
                                    noLabel
                                    className={"my-0"}

                                />
                            )}
                        </div>
                    </div>

                    {loading ?
                        <div className="d-flex justify-content-center p-4">
                            <Skeleton height={30} width={700} className='text-crm-greyish font-size-12' />
                        </div>
                    :<Row className='mt-2'>
                        <Col className="col-sm-12">
                            <Row className="zr_progress_stats">
                                {Object.entries(data).map(([key, value]) => (
                                    <Col
                                        onClick={() => redirect(value?.type)}
                                        key={key}
                                        className="w-100 zr_progress_stats_segment"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div className="stat_info">
                                            <div className="d-flex align-items-center key">
                                                <FaCircle className='me-1' color={value?.backgroundColor} />
                                                {_?.capitalize(key)}

                                                <div className="count-sm ms-1">
                                                    ({value.count})
                                                </div>
                                            </div>
                                            <div className="count">
                                                <span>{value.count}</span>
                                            </div>
                                            {value.count ?
                                                <div className="passenger_info">
                                                    <SlUserFemale className='me-1' size={16} color={"#777"} />
                                                    {value.femalePassengers || 0}/<span className='all'>{value.totalPassengers || 0}</span>
                                                </div>
                                                : null}
                                        </div>
                                    </Col>
                                ))}
                            </Row>

                            <div className="d-flex justify-content-start">
                                <div className='d-flex mt-2 font-size-14 gap-1  justify-content-center'>
                                  <p className='text-light-grey font-size-14 fw-semibold'> Total Passenger </p>  <span className='fw-semibold'> {getTotalPassengers()}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>}
                </div>
            </Card>
        </Col>
    );
};
