import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./RouteSettingsConstant";
import { doGET, doPOST } from "../../../../util/HttpUtil";

class Service {

  records = [];
  totalRecords = 0;
  places = [];
  page = 1;
  rows = 10;

  constructor() {
    makeAutoObservable(this);
  }



  get = async function (id) {

    const response = await doGET(ENDPOINTS.get)
    if (response.status === 200) {
      this.records = response.data;
      this.totalRecords = response.data.records;


      return response?.data;
    }
  };

  save = async function (data) {
    const response = await doPOST(ENDPOINTS.saveNew, data)
    this.get()

    return response?.data;
  };


}

const RouteSettingsService = new Service();
export default RouteSettingsService;
