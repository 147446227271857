import React, { useContext, useEffect } from 'react';
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import { Checkbox, InputField, NameField, PhoneNum } from '../../components';
import { I18nContext } from '../../store/context/i18nContext';
import SearchUser from '../Users/SearchUser';
import BookingService from './BookingService';
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import RemoveLink from '../../components/Buttons/RemoveLink';
import AddLink from '../../components/Buttons/AddLink';

const Passenger = ({ passengers, onChange, client_id }) => {

    const { t } = useContext(I18nContext);

    const handleRemovePassenger = (index) => {
        const updatedPassengers = passengers.filter((_, i) => i !== index);
        onChange(updatedPassengers);
    };

    const handleFieldChange = ({ index, field, v }) => {
        const updatedPassengers = passengers?.map((p, i) => {
            if (i === index) {
                // if (field === 'anonymous') {
                //     return {
                //         anonymous: v,
                //         // phone: {
                //         //     countryCode: 91,
                //         // },
                //         // salutation: 'Mr',
                //     }
                // }
                return { ...p, [field]: v };
            }
            return p;
        })
        onChange(updatedPassengers);
    };

    const fetchCustomerData = async (data, index, type) => {
        if (!data) {
            return;
        }
        try {
            let url = "";
            if (type === "phone") {
                if (!data?.countryCode || !data?.phone) {
                    return;
                }
                url = `&phoneNo.countryCode=${data?.countryCode}&phoneNo.phone=${data?.phone}`;
            } else if (type === 'email') {
                if (!data) {
                    return;
                }
                url = `&email=${data}`
            } else {
                return;
            }
            if (client_id) {
                url += `&client_id=${client_id}`
            }
            const response = await BookingService?.customerGrid(url);
            if (response?.rows[0]) {
                let updatedPassengers;
                if (type == 'email' && response?.rows?.length > 0) {
                    const phone = response?.rows[0]?.phoneNo;
                    const name = response?.rows[0]?.name;
                    const salutation = response?.rows[0]?.salutation;
                    const customer_id = response?.rows[0]?._id;
                    updatedPassengers = passengers?.map((p, i) => {
                        if (i === index) {
                            return { ...p, phone: phone, name: name, salutation: salutation, customer_id: customer_id };
                        }
                        return p;
                    })
                } else if (type == 'phone' && response?.rows?.length > 0) {
                    const email = response?.rows[0]?.email;
                    const name = response?.rows[0]?.name;
                    const salutation = response?.rows[0]?.salutation;
                    const customer_id = response?.rows[0]?._id;

                    updatedPassengers = passengers?.map((p, i) => {
                        if (i === index) {
                            return { ...p, email: email, name: name, salutation: salutation, customer_id: customer_id };
                        }
                        return p;
                    })
                }
                onChange(updatedPassengers);
            }
        } catch (error) {

        }
    }

    const addPassenger = () => {
        const pax = {
            index: passengers?.length ?? 0,
            salutation: "Mr",
            phone: {
                countryCode: 91,
            }
        };

        const updatedPassengers = passengers ? [...passengers, pax] : [pax];
        onChange(updatedPassengers);
    };

    const customerIdsArray = passengers?.map(obj => obj?.customer_id);

    useEffect(() => {
        if (!passengers?.length) {
            addPassenger()
        }
    }, [passengers?.length])

    return (
        < >

            {(passengers || [])?.map((passenger, index) => (
                <div className='p-1 pt-1  d-flex align-items-center flex-wrap' key={index} >
                    <div style={{ width: 42 }} className='col-1 d-flex  align-items-center'>
                        <FaPersonWalkingLuggage size={24} />
                        <h5 style={{ fontWeight: "bold" }} className="text-center mt-2 ms-2">
                            <span>{index + 1 + "."} </span>
                        </h5>
                    </div>

                    {!passenger?.anonymous ?
                        <div style={{ flex: 1 }} className="">

                            <Row className="mx-1">
                                <Col className="col-12 col-sm-4 py-0 mb-4 mb-sm-0">
                                    
                                    <PhoneNum
                                        placeholder={t("Phone No ")}
                                        value={{
                                            countryCode: passenger?.phone?.countryCode?? 91,
                                            phone: passenger?.phone?.phone,
                                        }}
                                        outline
                                        hideDropdown
                                        hideFlag
                                        onblur={() => {
                                            if (!passenger?.customer_id) {
                                                fetchCustomerData(passenger?.phone, index, 'phone')
                                            }
                                        }}
                                        label={t("Phone")}
                                        rightComponentStyle={{ width: "0px" }}

                                        onChange={(v) => {
                                            if (v) {
                                                const phone = {
                                                    phone: v.phone,
                                                    countryCode: v.countryCode
                                                };
                                                const updatedPassengers = passengers?.map((p, i) => {
                                                    if (i === index) {
                                                        return {
                                                            ...p,
                                                            phone: phone
                                                        };
                                                    }
                                                    return p;
                                                })
                                                onChange(updatedPassengers);
                                            }
                                        }}
                                        RightComponent={() => {
                                            return <>
                                                <SearchUser
                                                    style={{
                                                        // backgroundColor: "#F1F1F1",
                                                        borderRadius: "0px",
                                                        position: "relative",
                                                        height: "34px",
                                                        width: '30px',
                                                        right: '31px',
                                                        top: "1.5px",
                                                        cursor: 'pointer'
                                                    }}
                                                    hideCheckboxes={true}
                                                    onlyIcon={true}
                                                    value={customerIdsArray}
                                                    userType="Passenger"
                                                    onChange={(v) => {
                                                        if (v) {
                                                            const pax = {
                                                                email: v?.email,
                                                                name: v?.name,
                                                                salutation: v?.salutation,
                                                                phone: {
                                                                    countryCode: v?.phoneNo?.countryCode,
                                                                    phone: v?.phoneNo?.phone,

                                                                },
                                                                customer_id: v?._id
                                                            };
                                                            let updatedPassengers;

                                                            if (passengers[index]?.customer_id) {
                                                                updatedPassengers = passengers ? [...passengers, pax] : [pax];
                                                            }
                                                            else {
                                                                passengers = passengers ? [...passengers] : [];
                                                                passengers[index] = pax;
                                                                updatedPassengers = [...passengers]
                                                            }

                                                            // updatedPassengers = passengers ? [...passengers, pax] : [pax];
                                                            onChange(updatedPassengers);
                                                        }
                                                    }}
                                                />
                                            </>
                                        }}
                                    />
                                    {/* } */}
                                </Col>
                                <Col className="col-12 col-sm-4 mb-4 mb-sm-0">
                                    <InputField
                                        value={passenger?.email}
                                        outline
                                        label={t("Email")}
                                        placeholder="abc@mail.com"
                                        type="email"
                                        onblur={() => {
                                            if (!passenger?.customer_id) {
                                                fetchCustomerData(passenger?.email, index, 'email')
                                            }
                                        }}
                                        error={!passenger?.email ? t("Please enter Email") : ""}
                                        onChange={(v) => handleFieldChange({ index, field: 'email', v })}
                                        showClear={false}
                                        RightComponent={() => {
                                            return <>
                                                <SearchUser
                                                    style={{
                                                        // backgroundColor: "#F1F1F1",
                                                        borderRadius: "0px",
                                                        position: "relative",
                                                        height: "34px",
                                                        width: '30px',
                                                        right: '-4px',
                                                        top: "-1px",
                                                        cursor: 'pointer'
                                                    }}
                                                    hideCheckboxes={true}
                                                    onlyIcon={true}
                                                    value={customerIdsArray}
                                                    userType="Passenger"
                                                    onChange={(v) => {
                                                        if (v) {
                                                            const pax = {
                                                                email: v?.email,
                                                                name: v?.name,
                                                                salutation: v?.salutation,
                                                                phone: {
                                                                    countryCode: v?.phoneNo?.countryCode,
                                                                    phone: v?.phoneNo?.phone,

                                                                },
                                                                customer_id: v?._id
                                                            };

                                                            let updatedPassengers;

                                                            if (passengers[index]?.customer_id) {
                                                                updatedPassengers = passengers ? [...passengers, pax] : [pax];
                                                            }
                                                            else {
                                                                passengers = passengers ? [...passengers] : [];
                                                                passengers[index] = pax;
                                                            }
                                                            // const updatedPassengers = passengers ? [...passengers, pax] : [pax];
                                                            onChange(updatedPassengers);
                                                        }
                                                    }}
                                                />

                                            </>
                                        }}
                                    // onClickRight={
                                    />
                                </Col>
                                <Col className="col-12 col-sm-4">
                                    <NameField
                                        outline
                                        hideDropdown
                                        onNameChange={(v) => handleFieldChange({ index, field: 'name', v })}
                                        onSalutationChange={(v) => { handleFieldChange({ index, field: 'salutation', v }) }}
                                        name={passenger?.name}
                                        salutation={passenger?.salutation}
                                        label={t("Name")}
                                    />
                                </Col>
                            </Row>

                        </div> : <div style={{ flex: 1 } }></div>}

                    <div className=' d-flex align-items-center ' style={{ position: 'relative' }}>

                        <div style={{ width: "100px" }}>
                        <Checkbox
                            leftAlign
                            label={t("Anonymous")}
                            checked={passenger?.anonymous}
                            className="flex-1"
               
                            onClick={(v) => {
                                handleFieldChange({ index, field: 'anonymous', v: !passenger?.anonymous })
                            }}
                        />
                        </div>

                        {(passengers?.length != 1) ?
                            <RemoveLink className="ms-1" onClick={() => handleRemovePassenger(index)} />
                            : <div></div>}
                        <div
                            className='me-1'
                            style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                                width: "22px",
                            }}
                        >
                            {index == passengers?.length - 1 ? (
                                <AddLink className="me-1" onClick={addPassenger} size={20} />
                            ) : <div> </div>}
                        </div>

                    </div>


                </div>
            ))}

        </>

    );
};

export default Passenger;
