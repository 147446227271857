import React, { useState } from 'react';
import { ModalComponent } from '../../../../../components';
import Booking from '../../../../BookingsNew/Booking';
import { Button } from 'reactstrap';

const SendTripsModal = ({ isModalVisible, handleClose, onSendTrips, customer_id }) => {
    const [selectedtrips, setSelectedTrips] = useState([]);

    return (
        <ModalComponent
            position={"top"}
            size={"large"}
            onToggle={handleClose}
            isOpen={isModalVisible}
        >
            <ModalComponent.Title>
                Trips
            </ModalComponent.Title>

            <ModalComponent.Body>
                <Booking
                    passengerTrip={customer_id}
                    insidePane
                    onSelect={(v) => {
                        setSelectedTrips(v);
                        console.log(v)
                    }}
                />
            </ModalComponent.Body>

            <ModalComponent.Footer>
                <Button
                    className='primary-dark-bluish'
                    onClick={() => onSendTrips(selectedtrips)}
                >
                    Send
                </Button>
            </ModalComponent.Footer>
        </ModalComponent>
    );
};

export default SendTripsModal;
