// &trait=1
// &approved=true
export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/corp/all/grid/data?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;

    return url + "&page=" + page;
  },
  // get: (id) => `/api/corp/client/vendor/${id}/detail`,
  // getConfig: (id) => `/api/corp/${id}/config`,
  // saveNew: `/api/create/client/dms`,
  saveNew: `/api/v1/create/client/dms`,
  edit: (id) => `/api/corp/corporate/cpanel/save`,
  get: (id) => `/api/corp/profile/${id}/cpanel/data`,
  edit2: (id) => `/api/corp/cpanel/save`,
  // saveConfig : `/api/corp/config/contact/save`,
  // saveDutyHour: `/api/dutyhrs/saveUpdate`,
  // delete: (id) => `/api/zone/${id}/delete`,
  // deleteDutyHour: (id) => `/api/dutyhrs/${id}/delete`,
  // dutyhrs: (id) =>  `/api/dutyhrs/all?corp_id=${id}`,
  // editZone: (zoneId) => `/api/corp/client/vendor/${zoneId}/update`,
  // approve: (id, val) => `/api/corp/${id}/approve/client/${val}`,
};
export const STRUCTURE = [
  {
    label: "Name",
    description: "description...",
    filterLabel: "Corporate Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Unique Id",
    description: "description...",
    filterLabel: " Id",
    filterName: "unique_id",
    type: "text",
  },
  {
    label: "Subscription",
    description: "description...",
    filterLabel: "Subscription",
    filterName: "subscription",
    type: "select",
    data: [
      {
        label: "All",
      },
      {
        label: "Premium",
        value: 1,
      },
      {
        label: "Fremium",
        value: 2,
      },
    ],
  },
  {
    label: "Status",
    description: "description...",
    filterLabel: "Status",
    filterName: "status",
    type: "select",
    data: [
      {
        label: "All",
      },
      {
        label: "Approved",
        value: 1,
      },
      {
        label: "Pending",
        value: 0,
      },
    ],
  },
  {
    label: "Corporation Type",
    description: "description...",
    filterLabel: "Corporation Type",
    filterName: "corptype",
    type: "select",
    data: [
      {
        label: "ALL",
      },
      {
        label: "Not Set",
        value: "NOT_SET",
      },
      {
        label: "Consumer",
        value: 1,
      },
      {
        label: "Aggregator",
        value: 2,
      },
      {
        label: "Supplier",
        value: 3,
      },
    ],
  },
  {
    label: "Managed By",
    description: "description...",
    filterLabel: "Managed By",
    filterName: "corptype",
    type: "select",
    data: [
      {
        label: "ALL",
      },
      {
        label: "nametester",
        value: "61562595f871e000012d1110",
      },
      {
        label: "Vulnerability Assessment Pvt Ltd",
        value: "613855ff5af519d9acf35bb",
      },
      {
        label: "Test Vendor",
        value: "5546538fe4b07d94be9d3fa1",
      },
      {
        label: "Sea Main",
        value: "61601ae014fdb39f03ceb247",
      },
      {
        label: "Canon Main",
        value: "61601b6014fdb39f03ceb24e",
      },
      {
        label: "hfh",
        value: "63bfe8aa9a789b635c40244f",
      },
    ],
  },
  {
    label: "Managed By Set",
    description: "description...",
    filterLabel: "Managed By Set",
    filterName: "managedbyset",
    type: "checkBox",
    data: [
      {
        label: "abc",
        value: true,
      },
      {
        value: false,
      },
    ],
  },
  {
    label: "Domain Set",
    description: "description...",
    filterLabel: "Domain Set",
    filterName: "domainset",
    type: "checkBox",
    data: [
      {
        value: true,
      },
      {
        value: false,
      },
    ],
  },
];
