/* eslint-disable no-unused-vars */
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Col, DropdownItem, DropdownMenu, Row } from "reactstrap";
import {
  CheckButton, DeleteButton, EditLink,
  itemBarSelectedStyle,
  Layout, Modal, SaveButton
} from "../../components";
import BulkUpload from "../../components/BulkUpload/BulkUpload";
import DownloadByTemplate from "../../components/DownloadByTemplate/DownloadByTemplate";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import Driver from "../Driver/Driver";
import CustomFieldService from "./CustomFieldService";
import EditCustomField from "./EditCustomField";


const CustomField = (props) => {
  const { t } = useContext(I18nContext);
  const { id } = useParams();
  const [data, setData] = useState({});
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(
    props?.insidePane ? false : id
  );
  const [loading, setLoading] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);
  const [selectedCustomField, setSelectedCustomField] = useState([]);
  const [showDownload, setShowDownload] = useState(false);
  const [filterVals, setFilterVals] = useState({});
  const [driverPopup, setDriverPopup] = useState(false);
  const [customFieldId, setcustomFieldId] = useState();
  const [uploadPopup, setUploadPopup] = useState(false);

  const unassign = async (id) => {
    try {
      await CustomFieldService.removeDriver(id);
      fetchData();
      showMessage("Driver Unassigned");
    } catch (error) {
      showError(error);
    }
  };

  const fetchData = async (filterUrl) => {
    setFilterVals(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await CustomFieldService.fetch(filterUrl);
      await CustomFieldService.getStructure();
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    if (!props?.insidePane)
      setShowDetailPage(
        window.location.pathname === "/customfield/edit" || id
      );
  }, [id]);

  const changeZone = async (value, customFieldID) => {
    if (await showConfirm({ title: "Want to change the Zone?" })) {
      await CustomFieldService.updateZone(customFieldID, value.value);
    }
    CustomFieldService.fetch();
  };

  const onRowClick = (id) => {
    if (!props?.insidePane) navigate(`/customfield/edit/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };

  const bulkDeleteHandler = async (e) => {
    e.stopPropagation();
    if (!selectedCustomField.length) {
      showMessage(
        "info",
        "Select CustomFields to delete.",
        "No CustomField Selected"
      );
      return;
    }
    if (
      await showConfirm({
        title: "Delete CustomFields ?",
        description: "This is an unrecoverable operation.",
        className: "col-6",
      })
    ) {
      try {
        await CustomFieldService.bulkDelete({
          customField_ids: selectedCustomField.toString(),
        });

        navigate(`/customfield`);
      } catch (error) {
        showError(error);
      }
    }
    setSelectedCustomField([]);
  };

  const customFieldStatus = (customField) => {
    return customField.status === 2 ? (
      <>
        <i
          className="bx bxs-circle"
          style={{
            color: "red",
            fontSize: "20px",
            marginRight: "10px",
            float: "left",
          }}
        />
        InActive
      </>
    ) : customField.status === 3 ? (
      <>
        <i
          className="bx bxs-circle"
          style={{
            color: "blue",
            fontSize: "20px",
            marginRight: "10px",
            float: "left",
          }}
        />
        Under Maintainence
      </>
    ) : customField.status === 4 ? (
      <>
        <i
          className="bx bxs-circle"
          style={{
            color: "orange",
            fontSize: "20px",
            marginRight: "10px",
            float: "left",
          }}
        />
        Sold
      </>
    ) : (
      <>
        {" "}
        <i
          className="bx bxs-circle"
          style={{
            color: "green",
            fontSize: "20px",
            marginRight: "10px",
            float: "left",
          }}
        />{" "}
        Active
      </>
    );
  };
  // const changeFetchDataToShow = (v) => {
  //   setFetchDataToShow({
  //     pic: v.pic,
  //     regNo: v.regNo,
  //     customFieldType: v.customFieldType,
  //   });
  // };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete CustomField ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        const response = await CustomFieldService.delete(id);
        navigate("/customfield");
        setShowDetailPage(false);
        showMessage("Deleted");
      } catch (e) {
        showError(e);
      }
    }
  };

  const [fields, setFields] = useState([
    { hidden: false, label: t("Kind"), value: "regNo" },
    { hidden: false, label: t("Group"), value: "group" },
    { hidden: false, label: t("Section"), value: "section" },
    { hidden: false, label: t("Display Type"), value: "displayType" },
    { hidden: false, label: t("Options"), value: "options" },
  ]);


  const onSave = async (e) => {
    e.preventDefault();
    console.log(data);
    if (data?.kms < 0) {
      showError("Distance Can't be Negative");
      return;
    }
    try {
      if (id) {
        await CustomFieldService.edit(data, id);
        showMessage("CustomField Updated successfully");
      } else {
        const response = await CustomFieldService.save(data);
        CustomFieldService.records.push(data);
        showMessage("CustomField saved successfully");
        // setShowDetailPage(false);
        if (!props?.insidePane) {
          navigate(`/customfield/edit/${response}`);
        } else navigate(`/customfield`);
      }
      fetchData();
    } catch (error) {
      showError(error);
    }
  };

  const toggleDownload = () => {
    setShowDownload(!showDownload);
  };
  return (
    <>
      {showDownload ? (
        <DownloadByTemplate
          filter={filterVals}
          download={CustomFieldService.downloadByTemplate}
          kind={5}
          setShowDownload={setShowDownload}
          showDownload={showDownload}
          filterStructure={CustomFieldService.STRUCTURE}
          onApplyFilter={CustomFieldService.setDownloadFilter}
        />
      ) : null}
      {uploadPopup ? (
        <BulkUpload
          kind={5}
          type={2}
          showBulkUpload={uploadPopup}
          setShowBulkUpload={setUploadPopup}
          fetchData={fetchData}
          title={"CustomFields"}
        />
      ) : null}
      {driverPopup ? (
        <Modal
          onChange={() => {
            setDriverPopup(false);
          }}
        >
          <Driver
            popup={true}
            customField_id={customFieldId}
            setDriverPopup={setDriverPopup}
            fetchCustomFieldData={fetchData}
          />
        </Modal>
      ) : null}

      <Layout
        showDetailPage={showDetailPage}
        backDetailPage={() => {
          setShowDetailPage(false);
          if (!props?.insidePane) navigate("/customfield");
          setSelected_id(false);
          setData({});
        }}
         
        title={t("CustomFields")}
        filterStructure={CustomFieldService.STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!props?.insidePane) navigate(`/customfield/edit`);
          setShowDetailPage(true);
          setSelected_id(null);
        }}
        page={CustomFieldService.page}
        rows={CustomFieldService.rows}
        total={CustomFieldService.totalRecords}
        insidePane={props?.insidePane}
        onPageChange={async (page, rows) => {
          await CustomFieldService.onPaginationChange(page, rows);
        }}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              {!showDetailPage && (
                <>
                  <DropdownItem onClick={bulkDeleteHandler}>
                    Bulk Delete
                  </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={() => setUploadPopup(true)}>
                    {t("Upload")}
                  </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={toggleDownload}> Download </DropdownItem>
                </>
              )}
            </DropdownMenu>
            <DropdownMenu>
              {!showDetailPage && (
                <>
                  <DropdownItem onClick={bulkDeleteHandler}>
                    Bulk Delete
                  </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={() => setUploadPopup(true)}>
                    {t("Upload")}
                  </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={toggleDownload}> Download </DropdownItem>
                </>
              )}
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>

        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th className="col-sm-1">
                <input
                  type="checkbox"
                  style={{ height: "16px" }}
                  className="select-tariff"
                  checked={
                    JSON.stringify(
                      toJS(CustomFieldService?.records)
                        .map((x) => x._id)
                        .sort()
                    ) === JSON.stringify(selectedCustomField.sort())
                  }
                  onChange={(e) => {
                    if (e.target.checked)
                      setSelectedCustomField(
                        toJS(CustomFieldService?.records).map((x) => x._id)
                      );
                    else setSelectedCustomField([]);
                  }}
                />
              </Th>
              {fields.map(({ hidden, label, value }, index) => {
                if (hidden) return <></>;
                return <Th key={index}>{label}</Th>;
              })}
              <Th className="float-end">{t("Action")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {CustomFieldService.records?.map((customField) => (
              <Tr
                key={customField?._id}
                onClick={
                  showDetailPage &&
                  (async () => {
                    if (
                      showDetailPage &&
                      customField?._id !== selected_id &&
                      isEditDataChanged
                    ) {
                      let isProceed = await showConfirm({
                        isSaveWarn: true,
                        title: t(`Do you want to save the changes ?`),
                        description: t(
                          "Your changes will be lost if you don't save them"
                        ),
                      });
                      if (isProceed) onRowClick(customField?._id);
                    } else onRowClick(customField?._id);
                  })
                }
                style={id === customField?._id ? itemBarSelectedStyle : {}}
              >
                <Td scope="row">
                  <input
                    type="checkbox"
                    className="select-tariff"
                    style={{ height: "16px" }}
                    checked={
                      selectedCustomField.findIndex(
                        (x) => x === customField._id
                      ) !== -1
                    }
                    onChange={(e) => {
                      e.target.checked
                        ? setSelectedCustomField([
                          ...selectedCustomField,
                          customField._id,
                        ])
                        : setSelectedCustomField(
                          selectedCustomField.filter(
                            (x) => x !== customField._id
                          )
                        );
                    }}
                  />
                </Td>

                {fields[0].hidden ? (
                  <></>
                ) : (
                  <Td className="scope-row">
                    {t(customField.kind ? customField.kind : "-")}
                  </Td>
                )}
                {fields[1].hidden ? (
                  <></>
                ) : (
                  <Td className="scope-row">
                    <div
                      data-toggle="tooltip"
                      data-placement="top"
                      title={` Driver Name: ${customField?.group} | Driver Phone: ${customField?.group} | Driver CC: ${customField?.group} `}
                    >
                      {customField?.group}
                    </div>
                  </Td>
                )}
                {fields[2].hidden ? (
                  <></>
                ) : (
                  <Td className="scope-row">
                    {customField.section ? customField.section : "-"}
                  </Td>
                )}
                {fields[3].hidden ? (
                  <></>
                ) : (
                  <Td className="scope-row">
                    {t(customField.displayType ? customField.displayType : "-")}
                  </Td>
                )}
                {fields[4].hidden ? (
                  <></>
                ) : (
                  <Td className="scope-row">
                    {t(
                      customField?.options?.length
                        ? `${customField.options.length}`
                        : "-"
                    )}
                  </Td>
                )}

                {/* {fields[6].hidden ? (
                  <></>
                ) : (
                  <Td className="scope-row">
                    {t(
                      customField.compliant === 1
                        ? "Compliant"
                        : customField.compliant === 2
                        ? "Not Compliant"
                        : customField.compliant === 3
                        ? "DUE"
                        : "-"
                    )}
                  </Td>
                )} */}

                <Td>
                  <EditLink
                    onClick={() => {
                      if (!props?.insidePane)
                        navigate(`/customfield/edit/${customField?._id}`);
                      setSelected_id(customField?._id);
                      setShowDetailPage(true);
                    }}
                  />
                  {props?.selectMode ? (
                    <CheckButton
                      onClick={() => {
                        props?.onSelect([{ ...customField }]);
                      }}
                    />
                  ) : null}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>

        <Layout.DetailPageTitle>
          <h5 style={{ paddingLeft: "9px" }}>
            {data?._id ? "Edit CustomField" : "Add CustomField"}
          </h5>
        </Layout.DetailPageTitle>
        <Layout.DetailPageBody>
          <EditCustomField
            insidePane={props?.insidePane}
            parent={props?.parent}
            onChange={(v) => setIsEditDataChanged(v)}
            id={selected_id}
            data={data}
            setData={setData}
            fetchData={fetchData}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          <Row>
            <Col>
              <div
                className="d-flex justify-content-end mt-1"
                style={{ marginTop: "-2.5rem" }}
              >
                {data?._id && (
                  <DeleteButton onClick={(e) => onDelete(e, data?._id)} />
                )}
                <SaveButton onClick={onSave} />
              </div>
            </Col>
          </Row>
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};

export default observer(CustomField);
