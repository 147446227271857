import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { ENDPOINTS } from "./ClientCompanyConstant";
import { getUiPrefRows } from "../../util/FilterUtil";
class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = null;
  filterOption = "";
  total = -1;
  version = 0;
  companiesCache = {};

  constructor() {
    makeAutoObservable(this);
  }
  fetch = async (filterUrl) => {
    if(!this?.rowsPerPage) {
      const rows = await getUiPrefRows("entity.grid")
     this.rowsPerPage = rows ?? 40
    }

    if (filterUrl) {
      this.filterOption = filterUrl;
    }

    const response = await doPOST(
      ENDPOINTS.grid(), { queryURL: ENDPOINTS.filter(this.page, this.rowsPerPage, filterUrl) }
    );

    if (response?.status === 200) {
      this.records = response.data.rows;
      this.page = response?.data?.page;
      if (this.page === 1) this.total = response?.data?.total;
    }
    return response.data.rows
  };


  edit = async function (data, id) {
    const response = await doPUT(ENDPOINTS?.update, data);
    if (response?.status === 200) {
      this.version += 1;
      this.companiesCache = {}
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    if (response?.status === 200) {
      this.companiesCache = {}
    }
    return response?.data?._id;
  };

  delete = async (id, corp_id) => {
    const response = await doDELETE(ENDPOINTS.delete(id, corp_id));
    if (response.status === 200) {
      if (corp_id) {
        const filterUrl = "corp_id=" + corp_id;
        this.fetch(filterUrl);
      } else {
        this.fetch();
      }
      this.companiesCache = {}
    }
  };

  bulkDelete = async (ids = []) => {
    const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
    if (response.status === 200) {
      this.fetch();
    }
  };

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);

  };



}

const ClientCompanyService = new Service();
export default ClientCompanyService;
