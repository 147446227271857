export const ENDPOINTS = {
  grid: (page, sizePerPage) => {
    let s = `/api/paymentGateway/grid?rows=${sizePerPage}`;

    return s + `&page=${page}`;
  },
  get: (id) => `/api/paymentGateway/${id}/detail`,
  saveNew: `/api/paymentGateway/save`,
  edit: (id) => `/api/paymentGateway/${id}/update`,
  delete: (id) => `/api/paymentGateway/${id}/delete`,
};

export const SELECTSTRUCTURE = {
  currency: [
    { label: "INR", value: "INR" },
    { label: "USD", value: "USD" },
    { label: "MWK", value: "MWK" },
  ],
};
