import { useContext, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DialogContext } from "../../store/context/DialogContext";
import BookingService from "./BookingService";
import { SelectField } from "../../components";

const ASD = [
    { label: "Check for Saved Invoices", fieldName: 'checkForOldPdf', default: true },
    { label: "With Summary", fieldName: 'withSummary', default: false },
    { label: "With Start End KMS", fieldName: 'withStartEndKms', default: false },
]

const InvoiceTypeData = [
    { label: 'Invoice A', value: 'Z_invoice_A' },
    { label: 'Invoice B', value: 'Z_invoice_B' }
]
const PrintDuty = ({ trip_ids = [], modal, setModal, loading, setLoading, onSuccess, ...modalProps }) => {
    const { showError } = useContext(DialogContext);
    const [configuration, setConfiguration] = useState({
        templateName: 'dutySlip'
    });
    const toggleModal = () => {
        setModal(!modal);
    }
    const onSave = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await BookingService.print([...trip_ids] || [], { ...configuration } || {})
            // onSuccess()
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal
            isOpen={modal}
            toggle={toggleModal}
            {...modalProps}

        >
            <ModalHeader toggle={toggleModal}>
                Print Duty
            </ModalHeader>
            <ModalBody>
                <div className="d-flex">
                    {ASD.map((obj, index) => {
                        return <div key={index} className="d-flex justify-content-end align-items-center">
                            <Input type="checkbox"
                                defaultValue={obj.default}
                                value={configuration?.[obj.fieldName]}
                                onClick={() => {
                                    setConfiguration({
                                        ...configuration,
                                        [obj.fieldName]: !configuration?.[obj.fieldName]
                                    })
                                }} />
                            <span className="mx-2">
                                {obj.label}
                            </span>
                        </div>
                    })}
                </div>

                {/* <div className="mt-4">
                    <SelectField
                        data={InvoiceTypeData}
                        value={configuration?.templateName}
                        onChange={(v) => {
                            setConfiguration({ ...configuration, templateName: v?.value });
                        }}
                        label={"Print Duty Type"}
                        required={true}
                        className="col-sm-12 col-12"
                    />
                </div> */}
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end my-2">
                    <Button
                        onClick={onSave}
                        className="mx-2"
                        color="success"
                    >
                        <div style={{ margin: "2px", float: "right" }}>Print</div>
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}
export default PrintDuty