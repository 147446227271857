import { makeAutoObservable } from "mobx";
import { ApiHandler } from "../../store/apiHandler/apiHandler";
import { API_METHODS, ENDPOINTS } from "../../common/utils/Constants";

class ZoneStore {
  zones = [];
  totalZones = 0;
  totalZonePages = 1;
  allZonesList = [];
  error = "";
  cities = {};
  vendors = [];
  clients = [];

  constructor() {
    makeAutoObservable(this);
  }

  getAllZonesList = async function () {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllZonesList;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        this.allZonesList = response.data;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };

  getAllZones = async function (page, sizePerPage, filterName) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllZones(page, sizePerPage, filterName);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        this.zones = response.data.rows;
        this.totalZones = response.data.records;
        this.totalZonePages = response.data.total;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };

  getZoneById = async function (id) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getZoneById(id);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        return response.data;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  get zonesLength() {
    return this.zones.length;
  }

  editZone = async function (zoneData, zoneId) {
    const reqParam = zoneData;
    const method = API_METHODS.POST;
    const endPoint = ENDPOINTS.editZone(zoneId);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        console.log("success");
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
  };
  saveZone = async function (zoneData) {
    const reqParam = zoneData;
    const method = API_METHODS.POST;
    const endPoint = ENDPOINTS.saveNewZone;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        console.log("success");
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteZoneById = async function (id) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.deleteZoneById(id);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        return response.data;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  getCityByCountry = async function (country) {
    if (this.cities[country]) return;
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getCitiesFromCountry(country);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        this.cities = {
          ...this.cities,
          [country]: response.data.map((c) => {
            return { ...c, label: c.name, value: c.name };
          }),
        };
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  getAllVendors = async function () {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllZoneVendors();
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        this.vendors = response.data.rows;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  getAllCLients = async function () {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllZoneCLients();
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        this.clients = response.data.rows;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  getFilteredClients = async function (
    filterClientUniqueId,
    filterClientName,
    filterClientZone,
    filterClientCountry,
    filterClientCity
  ) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getFilteredClients(
      filterClientUniqueId,
      filterClientName,
      filterClientZone,
      filterClientCountry,
      filterClientCity
    );
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        return response.data.rows;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };
  getFilteredVendors = async function (filterVendorName) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getFilteredVendors(filterVendorName);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        return response.data.rows;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };
}

const Zones = new ZoneStore();
export default Zones;
