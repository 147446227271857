export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Name",
            accessor: "name",
            show: true,
        },
        {
            title: "Address Line",
            accessor: "addressLine",
            renderer: (dataObj) => {
                return <div>{dataObj?.address?.addressLine} </div>;
            },
            show: true,

        },
        {
            title: "City",
            accessor: "city",
            renderer: (dataObj) => {
                return <div>{dataObj?.address?.city} </div>;
            },
            show: true,
        },
        {
            title: "State",
            accessor: "state",
            renderer: (dataObj) => {
                return <div>{dataObj?.address?.state} </div>;
            },
            show: true,

        },
        {
            title: "Country",
            accessor: "country",
            renderer: (dataObj) => {
                return <div>{dataObj?.address?.country} </div>;
            },
            show: true,
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
