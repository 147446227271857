import { Image } from "../../components";
import { VehicleTypeSelect } from "../../components";

export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Make",
            accessor: "make",
            show: true,
        },
        {
            title: "Model",
            accessor: "model",
            show: true,
        },

        // {
        //     title: "Body Type",
        //     accessor: "bodyType",
        //     show: true,
        // },

        {
            title: "Image",
            accessor: "pic",
            renderer: (dataObj) => {
                return <Image height="20px" src={dataObj?.pic} />;
            },
            show: true,
        },

        {
            title: "Vehicle Type",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    <VehicleTypeSelect
                        asLabel
                        value={dataObj?.vehicleType}
                        onChange={(v) => { }}
                        className=""
                    />
                </div>
            },
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
