import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { toJS } from "mobx";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
// import EntitySelectService from "../../components/Dropdowns/CompanySelect/EntitySelectService";
import TaxSlabSelectService from "../../components/Dropdowns/TaxSlabSelect/TaxSlabSelectService";
import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  DeleteLink,
  DeleteButton,
  SaveButton,
  EditLink,
  itemBarSelectedStyle,
  Layout,
} from "../../components";
import { Col, Row, Button } from "reactstrap";
import "../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import CorpSubsService from "./CorpSubsService";
import EditCorpSubs from "./EditCorpSubs";
import { integerToDate } from "./SubsInvoice/SubsInvoice";

const CorpSubs = (props) => {
  const { t } = useContext(I18nContext);
  let { id } = useParams();
  if (props?.corpId) id = props?.corpId;
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [hasErr, setHasErr] = useState(false);
  const [showDetailPage, setShowDetailPage] = useState(
    props?.corpId ? false : id
  );
  const [data, setData] = useState({});
  const [entity, setEntity] = useState("");
  const [taxSlab, setTaxSlab] = useState("");

  const [loading, setLoading] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);


  const gotData = async (id) => {
    // data to fill in edit form
    try {
      if (id) {
        const dta = await CorpSubsService.get(id);
        setData(dta);
      }
    } catch (e) {
      showError(e);
    }
  };

  // const getEntityById = (id) => {
  //   toJS(EntitySelectService.entities).map(e => {
  //     console.log(e, 'eeee')
  //     if (e?.value === id) {
  //       return e?.label
  //     }
  //   })
  // }

  const getTaxSlabById = (id) => {
    let ret = ""
    toJS(TaxSlabSelectService.TaxSlab).map(e => {
      if (e?.value === id) {
        ret = e?.label;
      }
    })
    return ret;
  }

  const fetchData = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      await CorpSubsService.fetch(values, props?.corpId);
      // await EntitySelectService.fetch(props?.corpId);
      await TaxSlabSelectService.fetch()
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    gotData(selected_id);
    setShowDetailPage(
      window.location.pathname === "/CorpSubscription/create" ||
      (id && !props?.corpId)
    );
  }, [id, selected_id]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setHasErr(true);
    // if (!data.contentType || !data.businessUnit_id) return;
    setLoading(true);
    try {
      await CorpSubsService.save(data);
      showMessage("Subscription saved successfully");
      fetchData();
    } catch (e) {
      showError(e);
    }
    fetchData();
  };

  const onRowClick = (id) => {
    if (!props?.insidePane) navigate(`/CorpSubscription/create/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };

  if (loading) return <CardThreeBounce />;
  return (
    <Layout
      showDetailPage={showDetailPage}
       
      title={t("Subscriptions")}
      onAddClick={() => {
        if (!props?.insidePane) navigate(`/corporate`);
        setShowDetailPage(true);
        setSelected_id(null);
      }}
      backDetailPage={() => {
        setShowDetailPage(false);
        if (!props?.insidePane) navigate("/CorpSubscription");
        setSelected_id(false);
      }}
      page={CorpSubsService.page}
      rows={CorpSubsService.rows}
      total={CorpSubsService.totalRecords}
      onPageChange={async (page, rows) => {
        await CorpSubsService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("Client Name")}</Th>
            {/* <Th>{t("Company")}</Th> */}
            <Th>{t("Tax Slab")}</Th>
            <Th>{t("Minimum Rental")}</Th>
            <Th>{t("Per Trip Cost")}</Th>
            <Th className="float-end">{t("Action")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {CorpSubsService.records?.map((CorpSub) => (
            <Tr
              key={CorpSub?._id}
              style={id === CorpSub?._id ? itemBarSelectedStyle : {}}
              onClick={
                showDetailPage &&
                (async () => {
                  if (
                    showDetailPage &&
                    CorpSub?._id !== selected_id &&
                    isEditDataChanged
                  ) {
                    let isProceed = await showConfirm({
                      isSaveWarn: true,
                      title: t(`Do you want to save the changes ?`),
                      description: t(
                        "Your changes will be lost if you don't save them"
                      ),
                    });
                    if (isProceed) onRowClick(CorpSub?._id);
                  } else onRowClick(CorpSub?._id);
                })
              }
            >
              <Td>{CorpSub?.clientName}</Td>
              {/* <Td>{getEntityById(CorpSub?.bu_id)}</Td> */}
              <Td>{getTaxSlabById(CorpSub?.taxSlab_id)}</Td>
              <Td>{CorpSub?.plans ? CorpSub?.plans[0]?.minRental : ""}</Td>
              <Td>{CorpSub?.plans ? CorpSub?.plans[0]?.perTripCost : ""}</Td>
              <Td className="float-end">
                <EditLink
                  onClick={() => {
                    if (!props?.insidePane)
                      navigate(`/CorpSubscription/create/${CorpSub?._id}`);
                    setSelected_id(CorpSub?._id);
                    setShowDetailPage(true);
                  }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>

      {/* //edit page baad mai */}

      <Layout.DetailPageTitle>
        <h5>Subscription Detail</h5>
      </Layout.DetailPageTitle>

      <Layout.DetailPageBody>
        <EditCorpSubs
          insidePane={props?.insidePane}
          onChange={(v) => setIsEditDataChanged(v)}
          id={selected_id}
          hasErr={hasErr}
          setHasErr={setHasErr}
          back={() => {
            setShowDetailPage(false);
            if (!props?.insidePane) navigate("/CorpSubs");
            setSelected_id(false);
          }}
          setData={setData}
          data={data}
          fetchData={fetchData}
        />
      </Layout.DetailPageBody>

      <Layout.DetailPageFooter>
        <Row>
          <Col>
            <div className="d-flex justify-content-end mt-0">
              <SaveButton onClick={submitHandler} />
            </div>
          </Col>
        </Row>
      </Layout.DetailPageFooter>
    </Layout>
  );
};

export default observer(CorpSubs);
// export {integerToDate}
