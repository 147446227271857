export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl, recordType, recordOf) => {
    let url = `/api/comment/grid?${filterUrl}`;
    // if (sizePerPage) url += `rows=${sizePerPage}&`;
    // if (filterUrl) url += `${filterUrl}&`;
    // if (page) url += `page=${page}`
    return url;
  },

};

export const STRUCTURE = [
  {
    label: " Feedback Code",
    filterLabel: " Feedback Code",
    filterName: "code",
    type: "text",
  },
];
