import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ContactPerson from "../../../pages/ContactPerson/ContactPerson";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, Modal, SearchButton,ModalComponent } from "../../index";
import ContactPersonSelectService from "./ContactPersonSelectService";

const ContactPersonSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
}) => {
  const [contactPersons, setContactPersons] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const fetchContactPersons = async () => {
    try {
      await ContactPersonSelectService.fetch();
      setContactPersons(toJS(ContactPersonSelectService.contactPersons));
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (ContactPersonSelectService.contactPersons.length === 0) fetchContactPersons();
  }, []);

  useEffect(() => {
    setContactPersons(toJS(ContactPersonSelectService.contactPersons));
  }, [ContactPersonSelectService.contactPersons]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectContactPerson = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0] : selectedValue });
    closeModal();
  };

  const selectContactPersons = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage ContactPersons")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={contactPersons}
      required={required}
      value={value}
      label={label || (!isDisabled && t("ContactPerson"))}
      error={!value ? t(`Please Select ${label || "ContactPerson"}`) : ""}
      showErr={showErr}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage ContactPersons")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={selectContactPersons}
      data={contactPersons}
      value={value ? contactPersons.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "ContactPersons"}`) : ""}
      required={required}
      label={t("Select ContactPersons")}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
     {modal && (
            <ModalComponent
              position={"top"}
              size={"large"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Contact Person")}
              </ModalComponent.Title>

              <ModalComponent.Body>
              <ContactPerson
            value={value}
            insidePane={true}
            multiMode={multi}
            onSelect={multi ? selectContactPersons : selectContactPerson}
          />
              </ModalComponent.Body>
            </ModalComponent>
          )}

      {contactPersons.length > 0
        ? multi
          ? renderSelectFieldMultiple()
          : renderSelectField({ disabled: false })
        : renderSelectField({ disabled: true })}
    </>
  );
};

export default observer(ContactPersonSelect);
