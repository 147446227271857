import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import StatusDropDown from "../../../components/Dropdowns/StatusDropDown/StatusDropDown";


// 0 - created, 1 -- confirm, 2 -- ready, 3 -- distpatch, 4 - live ,  5-- completed, 6 -- cancelled, 7 --error, 8 -- paused, 9 -- expired

const statusFilteredOptions = {
    0: [
        { label: "Confirmed", value: 1, color: "#2980B9" },
        { label: "Cancelled", value: 6, color: "#F1948A" },
        { label: "Rejected", value: 10, color: "#F1948A" },
    ],
    1: [
        { label: "Cancelled", value: 6, color: "#F1948A" },
        { label: "Completed", value: 5, color: "#52BE80" },
        { label: "Rejected", value: 10, color: "#F1948A" },
    ],
    2: [
        { label: "Dispatch", value: 3, color: "#FFBF00" },
        { label: "Completed", value: 5, color: "#52BE80" },
        { label: "Cancelled", value: 6, color: "#F1948A" },
    ],
    3: [
        { label: "Completed", value: 5, color: "#52BE80" },
        { label: "Cancelled", value: 6, color: "#F1948A" },
    ],
    4: [
        { label: "Completed", value: 5, color: "#52BE80" }
    ],
    5: [
        { label: "Cancelled", value: 6, color: "#F1948A" },
    ],
    6: [
        { label: "Error", value: 7, color: "#CB4335" },
    ],
    7: []
}

const errorStatus = [
    { label: "Vehicle Missing", value: 1 },
    { label: "Driver Missing", value: 2 },
    { label: "Not Started", value: 3 },
    { label: "Not Closed", value: 4 },
    { label: "Break Down", value: 5 },
]

const BookingStatusModifier = ({
    currentStatus,
    trip_id,
    apiMethod,
    statusMapping,
    isDisabled=false
}) => {

    const [payload, setPayload] = useState({
        _id: null, status: null, errorStatus: null
    });



    useEffect(() => {
        if (trip_id) {
            setPayload(prevPayload => ({ ...prevPayload, _id: trip_id }));
        }
    }, [trip_id]);

    useEffect(() => {
        if (currentStatus !== null && currentStatus !== undefined) {
            setPayload(prevPayload => ({ ...prevPayload, status: currentStatus }));
        }
    }, [currentStatus]);


    // const [modal, setModal] = useState(false);
    // const toggleModal = () => {
    //     setModal(!modal);
    // }
    const getStatusFilteredOptions = () => {
        return statusFilteredOptions[currentStatus] || []
    }


    const call = async (e, status) => {
        try {
            await apiMethod(e, status);
            // toggleModal();
        } catch (error) {
            console.error("API call failed", error);
        }
    };

    const onChange = (e) => {
        if (e.target.id == "7") {
            setPayload({ ...payload, errorStatus: e.target.id, status: null })
            call(e, { ...payload, errorStatus: e.target.id, status: null })
        } else {
            setPayload({ ...payload, status: e.target.id })
            call(e, { ...payload, status: e.target.id })
        }
    }


    return (
        <div>
            <StatusDropDown
                isDisabled={isDisabled}
                status={currentStatus}
                statusMappingData={statusMapping}
                data={statusFilteredOptions}
                onChange={onChange}
            />

            {/* <UncontrolledDropdown className="ms-2">
                <DropdownToggle
                    caret
                    outline={true}
                    onMouseOver={() => {
                        setHoverTextColor("white")
                        setHoverBGColor(statusInfo?.color)
                    }}

                    onMouseOut={() => {
                        setHoverTextColor(null)
                        setHoverBGColor(null)
                    }}
                    color={statusInfo?.color}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: `1px solid ${statusInfo?.color}`,
                        borderColor: statusInfo?.color,
                        color: hoverTextColor ? hoverTextColor : statusInfo?.color,
                        cursor: 'pointer',
                        borderRadius: "5px",
                        backgroundColor: hoverBGColor ? hoverBGColor : "white",
                        padding: "6px",
                        paddingTop: "3px",
                        paddingBottom: "3px"
                    }}
                >
                    <span className="dot" style={{ backgroundColor: hoverTextColor ? hoverTextColor : statusInfo?.color, marginTop: '0px' }}></span>
                    {statusInfo?.text}
                </DropdownToggle>
                <DropdownMenu>
                    {getStatusFilteredOptions()?.map((item, statusIndex) => (
                        <DropdownItem id={item?.value} onClick={onChange}>
                            <span className="dot" style={{ backgroundColor: item?.color, marginTop: '0px' }}></span>   {item?.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown> */}

            {/* <div className="d-flex justify-content-end my-2">
                <Button
                    onClick={() => { toggleModal(); }}
                    className="mx-2"
                    style={{ height: "28px", padding: "2px 10px 2px 4px" }}
                    color="success"
                >
                    <div className="d-flex justify-content-center" style={{ margin: "2px" }}>{t(`Change Status`)}</div>
                </Button>
            </div>
            <Modal isOpen={modal} toggle={toggleModal} >
                <ModalHeader toggle={toggleModal}>
                    {t(`Set Trip Status`)}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <SelectField
                                data={getStatusFilteredOptions()}
                                value={payload?.status}
                                outline
                                noLabel
                                onChange={(v) => setPayload({ ...payload, status: v?.value })}
                                label={t("Change Status")}
                            />
                        </Col>
                        {currentStatus === 6 && (<Col>
                            <SelectField
                                data={errorStatus}
                                value={payload?.errorStatus}
                                outline
                                noLabel
                                onChange={(v) => setPayload({ ...payload, errorStatus: v?.value })}
                                label={t("Set Error Status")}
                            />
                        </Col>)}
                    </Row>
                    <Row>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={call}
                        className="mx-2"
                        style={{ height: "28px", padding: "2px 10px 2px 4px" }}
                        color="success"
                    >
                        <div className="d-flex justify-content-center" style={{ margin: "2px" }}>{t(`Save Changes`)}</div>
                    </Button>
                </ModalFooter>
            </Modal> */}
        </div>
    )
}
BookingStatusModifier.propTypes = {
    currentStatus: PropTypes.number,
    trip_id: PropTypes.string,
};
export default BookingStatusModifier;