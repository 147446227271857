import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { CrossButton, InputField, VehicleSelect, } from '../../../components'

const TransporterStep4 = ({ data, setData }) => {

    const { inventory = [] } = data

    const [inventoryData, setInventoryData] = useState(inventory)

    useEffect(() => {
        if (JSON.stringify(data?.contactPersons) !== JSON.stringify(inventoryData))
            setData({ ...data, inventory: inventoryData });
    }, [inventoryData]);

    console.log(inventoryData)
    return (
        <div className=' d-flex flex-column'>
            <div className='vehcile-type-box m-auto' style={{ width: "92%" }}>
                <div className=' d-flex justify-content-between'>
                    <div className=' mt-3 px-4'>Select Vehilce Type</div>
                    <div className='mt-2 float-end px-4'>
                        <Button onClick={() => setInventoryData([...inventoryData, {}])} style={{ color: "#DF6463", backgroundColor: "white", border: "none", borderRadius: "8px" }}> <span className=' fa-1x fw-bolder' style={{}}>+</span> Add Inventory</Button>
                    </div>
                </div>

            </div>
            <div className="">

                {inventoryData?.map((inventory, i) => {
                    return (

                        <InventoryRow index={i} value={inventory} inventoryData={inventoryData} setInventoryData={setInventoryData} onChange={v => {
                            setInventoryData([...inventoryData.splice(i, 1), v]);
                        }} />


                    )
                })}
            </div>



        </div>
    )
}

const InventoryRow = (props) => {
    const { value, inventoryData, setInventoryData, index } = props
    return (
        <>
            <Row className='mt-4 px-4'>
                <Col className='col-sm-4 px-4'>
                    <VehicleSelect
                        multi
                        value={value?.vehicleType}
                        onChange={(v) =>
                            setInventoryData(
                                inventoryData?.map((f, x) => {
                                    if (index === x) return {
                                        ...value, vehicleType: v?.vehicleType,
                                        vehicleCategory: v?.vehicleType
                                    };
                                    return f;
                                })
                            )
                        }
                    />
                </Col>
                <Col className='col-sm-4 px-4'>

                    <InputField
                        value={value?.count}
                        type="number"
                        label="Count"
                        onChange={(v) =>
                            setInventoryData(
                                inventoryData?.map((f, x) => {
                                    if (index === x) return { ...value, count: v };
                                    return f;
                                })
                            )
                        }


                    />
                </Col>
                <Col className='col-sm-2 px-2'>
                    <div className='cross'>

                        <CrossButton
                            onClick={() => {
                                setInventoryData(() => {
                                    let newData = inventoryData?.slice();
                                    newData.splice(index, 1);
                                    return newData;
                                });
                            }}

                        />
                    </div>
                </Col>
            </Row>

        </>
    )

}

export default TransporterStep4