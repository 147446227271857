import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu, Row } from "reactstrap";
import {
  BulkUpload,
  CheckButton,
  DataGrid,
  DeleteLink,
  EditLink,
  Layout,
  ModalComponent
} from "../../components";
import CustomOperations from "../../components/CustomField/CustomOperations";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import GroupTourSidebar from "../GroupTour/sideBar/GroupTourSidebar";
import GroupTourSidebarService from "../GroupTour/sideBar/GroupTourSidebarService";
import EditStays from "./EditStay";
import { STRUCTURE } from "./StayConstant";
import { GridColumns } from "./StayGridColumns";
import StayService from "./StayService";
import { toJS } from "mobx";
import BulkWhatsapp from "../Communication/BulkWhatsapp";
import { TOUR_TOGGLE_DATA } from "../BookingsNew/utils/constants";

const getUniquePassengers = (records) => {
  const passengersMap = new Map();
  records.forEach(record => {
    record.guests.forEach(guest => {
      if (!passengersMap.has(guest.customer_id)) {
        passengersMap.set(guest.customer_id, { ...(toJS(guest) ?? {}), phoneNo: guest?.phone });
      }
    });
  });
  return Array.from(passengersMap.values());
};



const Stays = ({
  value,
  insidePane,
  multiMode,
  onSelect = () => { },
  formConfig = {
    showChecks: true,
    hideFilter: false,
  },
  passengerId
}) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/stay/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)
  const [showCustomizationOptions, setShowCustomizationOptions] = useState(false)
  const [uploadPopup, setUploadPopup] = useState(false);
  const [onAlert, setOnAlert] = useState(false)
  const [bulkWhatsappPopup, setBulkWhatsappPopup] = useState(false);

  const multiplyFieldsBy100 = (data) => {
    const multiplyIfExists = (obj, fields) => {
      fields.forEach(field => {
        if (obj[field] !== undefined) {
          obj[field] = obj[field] * 100;
        }
      });
    };

    if (data.clientFee) {
      multiplyIfExists(data.clientFee, ['subTotal', 'totalDiscounts', 'totalTaxes']);
    }

    if (data.supplierFee) {
      multiplyIfExists(data.supplierFee, ['subTotal', 'totalDiscounts', 'totalTaxes']);
    }

    return data;
  };

  const saveBulk = async (data) => {
    try {
      const modifiedData = multiplyFieldsBy100({ ...data });
      const account = await StayService.save({
        ...(modifiedData ?? {}),
        space_id: GroupTourSidebarService.selectedFolder.type == "single" ? null : GroupTourSidebarService.selectedFolder._id,
      });
      if (account) {
        return { isUploaded: true, message: "" }
      }
      return { isUploaded: false, message: "Failed unexpectedly" }
    } catch (error) {
      return { isUploaded: false, message: JSON.parse(error?.message)?.customMessage ?? JSON.parse(error?.message)?.message }
    }
  };


  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await StayService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };


 
  const preFetchGrid = () => {
    let { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    if (GroupTourSidebarService.selectedFolder?._id) {
      if (filterUrl) filterUrl += ("&")
      filterUrl += ('space_id' + "=" + GroupTourSidebarService.selectedFolder._id)
      setFilterURL(filterUrl)
    }

    if (passengerId) {
      filterUrl += (`&guests.customer_id=in[${passengerId}]`)

      setFilterURL(filterUrl)
    }

    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }

  useEffect(() => {
    preFetchGrid()
  }, [GroupTourSidebarService.selectedFolder?._id, passengerId])


  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    // if (!detailData?.address?.name) {
    //   setHasErr(true)
    //   showError(t("Please enter all required fields"));
    //   return;
    // }
    setLoading(true);
    try {
      if (editID) {
        await StayService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Stay updated successfully."));
      } else {
        const stay = await StayService.save({
          ...detailData,
          space_id: GroupTourSidebarService?.selectedFolder?._id
        });
        setLoading(false);
        showMessage(t("Stay saved successfully."));
        if (!insidePane) navigate(`/stay/edit/${stay}`);
      }
      fetchData(filterURL);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await StayService.delete(id);
        setLoading(false);
        showMessage("Stay Deleted SuccessFully", "Deleted");
        navigate("/stay");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await StayService.bulkDelete(selectedIDs);
        showMessage(t("Deleted"), "Deleted Successfully");
        setShowDetailPage(false);

      } catch (e) {
        showError(e);
      }
    }
  };


  const renderLastCol = useCallback((stay) => {
    return (
      <>
        {userContent?.rights?.includes(1502) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/stay/edit/${stay?._id}`);
              setEditID(stay?._id);
              setShowDetailPage(true);

            }}
          />
        }
        {userContent?.rights?.includes(1503) ?

          <DeleteLink
            onClick={(event) => onDelete(event, stay?._id)}
          />
          : null}
        {(!multiMode && insidePane && formConfig?.showChecks) ? (
          <CheckButton
            onClick={() => {
              onSelect(stay?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent, formConfig?.showChecks])

  const markCompletedFunc = async () => {
    try {
      await SetupProgressService?.edit("stay");
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch()
    }
  }
    , [SetupProgressService?.records])

  return (
    <div className="d-flex">

      {insidePane ? null : <div style={{ minWidth: "200px" }} className={insidePane ? "" : "page-content"}>
        <GroupTourSidebar module="events" />
      </div>}


      <BulkUpload
        show={uploadPopup}
        onSheetClose={() => { preFetchGrid() }}
        onClose={() => { setUploadPopup(false) }}
        title={t("Stay")}
        kind={"Stay"}
        upload={saveBulk}
      />

      {bulkWhatsappPopup ? (
        <ModalComponent
          position={"top"}
          size={"full-screen"}
          isOpen={bulkWhatsappPopup}
          onToggle={() => setBulkWhatsappPopup(false)}
        >
          <ModalComponent.Title>
            <Row>
              <span style={{ fontSize: "15px" }}>
                Bulk Message
              </span>
            </Row>
          </ModalComponent.Title>
          <ModalComponent.Body>
            <BulkWhatsapp
              forStayOnly
              onBack={() => setBulkWhatsappPopup(false)}
              customers={getUniquePassengers(StayService?.records?.filter(v => selectedIDs.includes(v._id)))}
              space_id={GroupTourSidebarService.selectedFolder?._id}
            />
          </ModalComponent.Body>
        </ModalComponent>
      ) : null}


      <div className="flex-1" style={{ width: insidePane ?"100%": "calc(100vw - 250px)" }}>
        <Layout
          hideAdd={!userContent?.rights?.includes(1501) || insidePane}
          medium
          hideSearch={formConfig.hideFilter}
          showToggle={!insidePane}
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/stay");
            setEditID(null);
          }}
          toggleData={TOUR_TOGGLE_DATA(t, "stay")}
          compact
          onToggle={(selectedValue) => {
            if (selectedValue === "tours") {
              navigate(`/tour`);
            } else if (selectedValue === "trips") {
              navigate(`/trips`);
            } else if (selectedValue === "stay") {
              navigate("/stay");
            } else if (selectedValue === "flights") {
              navigate("/flight");
            } else if (selectedValue === "guests") {
              navigate("/guests");
            } else if (selectedValue === "calender") {
              navigate("/tours/calender");
            }
          }}

           
          title={t("Stays")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/stay/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={StayService.page}
          rows={StayService.rows}
          hideheader={true}
          hidefooter={true}
          total={StayService.total}
          fetch={StayService.fetch}
        >


          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={() => setUploadPopup(true)}>
                    {t("Bulk Upload")}
                  </DropdownItem>
                  <div class="dropdown-divider"></div>

                  {GroupTourSidebarService.selectedFolder?._id ? <DropdownItem onClick={() => setBulkWhatsappPopup(true)}>
                    {t("Bulk Message")}
                  </DropdownItem> : null}
                  <div class="dropdown-divider"></div>

                  <DropdownItem>{t("Download")} </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={() => { setShowCustomizationOptions(true) }}>{t("Customise")} </DropdownItem>
                </>
              </DropdownMenu>
            </div>
          </Layout.ActionMenu>
          <Layout.Table>
            <DataGrid
              gridLoading={loading}
              data={StayService?.records}
              total={StayService.total}
              uiPreference="stay.grid"
              headers={GridColumns}
              onSelectChange={(v) => {
                onSelect(v)
                setSelectedIDs(v)
              }}
              selectedIDs={selectedIDs}
              page={StayService.page}
              rowsPerPage={StayService.rowsPerPage}
              onPaginationChange={StayService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </Layout.Table>

          {/* 
          <Layout.DetailPageTitle>
            <div className="d-flex align-items-center">
              {detailData?._id ? t("Edit Stay") : t("Add Stay")}
              <TagPicker
                className="ms-2"
                kind={"stay"}
                onChange={(v) => setDetailData(prev => ({
                  ...prev,
                  tags: v
                }))}
                value={detailData?.tags}
              />
            </div>
          </Layout.DetailPageTitle> */}

          <Layout.DetailPageBody>
            <EditStays
              onSave={() => { fetchData(filterURL) }}
              onDelete={() => {
                setShowDetailPage(false)
                fetchData(filterURL)
              }}
              insidePane={insidePane}
              onBack={() => {
                setShowDetailPage(false);
                if (!insidePane) navigate("/flight");
                setEditID(null);
              }}
              editId={editID}
            />
          </Layout.DetailPageBody>

          {/* <Layout.DetailPageFooter>
            {detailData?._id ? (
              <AuditLogDialog module="Stay" id={detailData?._id} />
            ) : null

            }
            {editID && userContent?.rights?.includes(1503) && (detailData?._id) ? <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} /> : null}
            {userContent?.rights?.includes(1502) ? <SaveButton onClick={onSave} loading={loading} /> : null}
          </Layout.DetailPageFooter> */}
        </Layout>
        {showCustomizationOptions ? <CustomOperations
          isModal
          module="stay"
          isOpen={showCustomizationOptions}
          toggleModal={() => { setShowCustomizationOptions(false) }}
        /> : null
        }
      </div>
    </div>
  );
};
export default observer(Stays);
