export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl, space_id) => {
        let url = `/api/tour/grid?`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}&`
        if (space_id) url += `space_id=${space_id}`
        return url;
    },
    get: (id) => `/api/tour/${id}/detail`,
    save: `/api/tour/create`,
    delete: (id) => `/api/tour/${id}/delete`,
    bulkDelete: (id) => `/api/tour/delete`,
    update: `/api/tour/update`,
};

export const STRUCTURE = [
    {
        label: "No.",
        filterLabel: "no ",
        filterName: "name",
        type: "text",
    },
    {
        label: "Itinerary Code",
        filterLabel: "Itinerary Code",
        filterName: "code",
        type: "text",
    },
];
