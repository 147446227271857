import React from 'react'
import { AddressField } from '../../../components'

const MarketPlaceStep3Component = ({ style, data, setData, label, placeholder, onChange }) => {
    return (
        <div className='mb-2' style={{
            ...style
        }}>
            <div className='ms-1' style={{
                fontSize: "13px",
                color: "#2E353F"
            }}>{label}</div>
            <div>
                <AddressField
                    outline={true}
                    placeholder={placeholder}
                    label={label}
                    value={data?.address}
                    onChange={onChange}
                    style={{ fontSize: "16px" }}
                />

            </div>
        </div>
    )
}

export default MarketPlaceStep3Component