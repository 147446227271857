import React from 'react'

//to show total taxes and total summary
function FeeTotal({ totalTaxes, total,asLabel }) {
    return (
        <>
            <tr  style={asLabel?{lineHeight: "0.5"}:null}   >
                <td> </td>
                <td> </td>
                <td><b>Total Taxes </b></td>
                <td className={asLabel && 'w-100 d-flex justify-content-end'}><b>{((totalTaxes ?? 0) / 100).toFixed(2)}</b></td>
            </tr>
            <tr   style={asLabel?{lineHeight: "0.5"}:null} >
                <td> </td>
                <td> </td>
                <td>
                    <strong>Total (INR)</strong>
                </td>
                <td className={asLabel && 'w-100 d-flex justify-content-end'}>
                    {" "}
                    <strong>{(total ? total / 100 : 0).toFixed(2)} </strong>
                </td>
                <td></td>
            </tr>
        </>
    )
}

export default FeeTotal