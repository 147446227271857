import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import {
  Address,
  CardThreeBounce,
  InputField,
  SalutationSelect,
  RoleSelect,
  NameField,
  AddressField
} from "../../components";
import SuperAdminService from "./SuperAdminService";
import { I18nContext } from "../../store/context/i18nContext";
import { DialogContext } from "../../store/context/DialogContext";

const EditSuperAdmin = ({ editId, onChange = () => { } }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    salutation: "Mr"
  });
  // const [data, setData] = useState(null);

  const fetch = async () => {
    try {
      let states = await SuperAdminService.get(editId);
      setData(states);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    setData(null);
    setLoading(true);
    if (editId) fetch();
    setLoading(false);
  }, [editId, SuperAdminService?.version]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <React.Fragment>
      {loading ? (
        <CardThreeBounce />
      ) : (
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="mt-3 mb-3">
            <NameField
              onNameChange={(v) => setData({ ...data, name: v })}
              onSalutationChange={(v) => setData({ ...data, salutation: v })}
              name={data?.name}
              salutation={data?.salutation}
              label="Name"
              placeholder={t("Name")}
              className="col-sm-6 col-md-6"
            />

            <InputField
              placeholder={t("Name")}
              value={data?.name}
              required={true}
              label={t("Name")}
              onChange={(v) => setData({ ...data, name: v })}
              type="text"
              className="col-sm-6 col-md-6"
            /> */}
          </Row>

          <Row className="mt-3 mb-3">
            <InputField
              placeholder={t("Email")}
              value={data?.email}
              required={true}
              label={t("Email")}
              onChange={(v) => setData({ ...data, email: v })}
              type="text"
              className="col-sm-6"
            />
          </Row>

          <Row className="mt-3 mb-3">
            <RoleSelect
              value={data?.role}
              onChange={(v) => setData({ ...data, role: v?.value })}
              className="col-sm-6"
            />


          </Row>
          <Row className="mt-3 mb-3">
            <AddressField
              placeholder={t("SuperAdmin address")}
              label={t(" Address")}
              value={data?.address}
              onChange={(v) => setData({ ...data, address: v })}
              error={data?.address?.length === 0 ? t("Please enter address") : ""}
            />
            {/* <Address
                medium
              country
              state
              placeholder={t("SuperAdmin Address")}
              label={t("SuperAdmin Address ")}
              city
              addressLine
                data={data?.address}
                onChange={(v) => setData({ ...data, address: v })}
            /> */}
          </Row>


        </Form>
      )}
    </React.Fragment>
  );
};

export default observer(EditSuperAdmin);
