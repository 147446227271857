import { observer } from "mobx-react-lite";
import React from "react";
import "./layout.scss";
import "./style.css";

const SidebarModal = ({ children, isOpen, toggle }) => {
  return (
    <React.Fragment>
      <div className="sidebar-main">
        <div className="d-flex h-100 flex-column justify-content-between">
          <div className="d-flex flex-column">
            <div style={{ width: "100%" }} className={`${isOpen ? " map-card " : " map-card map-card-hide "}`}>
              {isOpen ? children : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(SidebarModal);
