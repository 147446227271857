import React, { Children, useEffect, useMemo, useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    UncontrolledDropdown,
} from 'reactstrap';
import './styles.css'
import { BsThreeDotsVertical } from "react-icons/bs";



function BookingFooterDropdown({ data, value, isDisabled = false, onChange = () => { }, style = {}, children,className }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <div className={`booking-footer ${className && className}`} style={style}>
            <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                <DropdownToggle
                    // caret
                    // outline={true}
                    color="#ced4da"
                    className="mx-2 d-flex"
                    style={{ border: '1px solid #fff', backgroundColor: '#e3e3e3', "borderRadius": "7px" }}
                >
                    <BsThreeDotsVertical size={20} />

                </DropdownToggle>

                <DropdownMenu className='shadow'>
                    {children}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
}



export default BookingFooterDropdown;
