export const CUSTOMEFIELDS_KIND_DATA = {
    Client: 1,
    Supplier: 2,
    Booking: 3,
    Route: 4,
    Employee: 5,
    Rate: 6,
    Customer: 7,
    Driver: 8,
    Expense: 9,
    Invoice: 10,
    Office: 11,
    Vehicle: 12
};
