import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./TimesheetConstant";
import { GridColumns } from "./TimesheetGridColumn";
import TimesheetService from "./TimesheetService";
import EditTimesheet from "./EditTimesheet";
import { UserContext } from "../../store/context/UserContext";

const Timesheet = ({ insidePane, multiMode }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { userContent } = useContext(UserContext);


  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(
    insidePane
      ? false
      : window.location.pathname === "/timesheet/create" || edit_id
  );
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await TimesheetService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(
      queryParams,
      STRUCTURE
    );
    setFilterURL(filterUrl);
    setFilterObject(filterObject);
    fetchData(filterUrl);
  }, []);

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.vehicle_id) {
      showError(t("Please enter all required fields"));
      return;
    }
    setLoading(true);
    try {
      if (editID) {
        await TimesheetService.edit(detailData, editID);
        showMessage(t("Timesheet updated successfully."));
      } else {
        const leaveID = await TimesheetService.save(detailData);
        if (leaveID) setEditID(leaveID)
        showMessage(t("Timesheet saved successfully."));
        if (!insidePane) navigate(`/timesheet/edit/${leaveID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete Request?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await TimesheetService.delete(id);
        setLoading(false);
        setShowDetailPage(false)
        showMessage("Deleted SuccessFully", "Deleted");
      } catch (e) {
        showError(e);
        setLoading(false);
      }
    }
  };

  const renderLastCol = useCallback((record) => {
    return (
      <>
        {userContent?.rights?.includes(5202) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/timesheet/edit/${record?._id}`);
              setEditID(record?._id);
              setShowDetailPage(true);
            }}
          />
        }
        {userContent?.rights?.includes(5203) ?
          <DeleteLink
            onClick={(event) => onDelete(event, record?._id)}
          />
          : null}
      </>
    );
  }, [insidePane, multiMode, userContent])

  return (
    <React.Fragment key={userContent?.rights}>
      <>
        <Layout
          large
          hideAdd={!userContent?.rights?.includes(5201)}
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/timesheet");
            setEditID(null);
          }}
           
          title={t("Vehicle Driver Timesheet")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/timesheet/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={TimesheetService.page}
          rows={TimesheetService.rows}
          total={TimesheetService.total}
          fetch={TimesheetService.fetch}
        >
          <Layout.Table>
            <>
              <DataGrid
              gridLoading={loading}
                data={TimesheetService.records}
                total={TimesheetService.total}
                uiPreference="request.grid"
                headers={GridColumns}
                selectedIDs={selectedIDs}
                onSelectChange={setSelectedIDs}
                page={TimesheetService.page}
                rowsPerPage={TimesheetService.rowsPerPage}
                onPaginationChange={TimesheetService.onPaginationChange}
                renderLastCol={renderLastCol}
              />
            </>
          </Layout.Table>

          <Layout.DetailPageTitle>
            {detailData?._id
              ? t("Edit Vehicle Driver Timesheet")
              : t("Add Vehicle Driver Timesheet")}
          </Layout.DetailPageTitle>

          <Layout.DetailPageBody>
            <EditTimesheet
              editId={editID}
              onChange={(v) => {
                setDetailData(v);
              }}
            />
          </Layout.DetailPageBody>

          <Layout.DetailPageFooter>
            {editID && userContent?.rights?.includes(5203) && detailData?._id ? <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} /> : null}
            {userContent?.rights?.includes(5202) ? <SaveButton onClick={onSave} loading={loading} /> : null}
          </Layout.DetailPageFooter>
        </Layout>
      </>
    </React.Fragment>
  );
};
export default observer(Timesheet);
