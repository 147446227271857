
import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { DateSelect, CompanySelect, InputField, OfficeSelect, SelectField, ToggleSelector, AmountField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "./AccountConstant";
import AccountsService from "./AccountsService";
import "./account.scss";


const EditAccounts = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const fetch = async () => {
    try {
      let accounts = await AccountsService.get(editId);
      setData(accounts);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(null)
    setLoading(true);
    if (editId) {
      fetch();
    } else { }
    setData({
      status: 1,
    })
    setLoading(false)
  }, [editId, AccountsService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="">
            <div className="d-flex flex-sm-row flex-column align-items-center justify-content-between ">
              <SelectField
                data={SELECTSTRUCTURE.accountType}
                value={data?.accountType}
                onChange={(v) => {
                  setData({ ...data, accountType: v?.value });
                }}
                showErr={hasErr}
                label={t("Account Type")}
                required={true}
                style={{ "minWidth": "300px" }}
              />
              <ToggleSelector
                data={SELECTSTRUCTURE.status}
                width="50%"
                value={data?.status}
                onChange={(v) => {
                  setData({ ...data, status: v });
                }}
                showErr={hasErr}
                label={t("Account Status")}
                className="col-sm-6 col-12"
                customStyleClass="sm-screen-margin"
              />
            </div>

            <OfficeSelect
              value={data?.office_id}
              onChange={(v) => setData({ ...data, office_id: v?.value })}
            />
            <CompanySelect
              multi
              corpType="self"
              selectFirst={false}
              value={data?.company_ids}
              onChange={(v) => {
                setData({ 
                ...data, company_ids: 
                v })
              }}
            />

            <InputField
              placeholder={t("Account Name")}
              value={data?.acDetail?.name}
              label={t("Account Name")}
              required={true}
              onChange={(v) => setData({ ...data, acDetail: { ...data?.acDetail, name: v } })}
              error={data?.acDetail?.name?.length === 0 ? t("Please enter Account Name") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-12 col-12"
            />

            <InputField
              placeholder={t("Account Number")}
              value={data?.acDetail?.accountNo}
              label={t("Account Number")}
              required={true}
              onChange={(v) => setData({ ...data, acDetail: { ...data?.acDetail, accountNo: v } })}
              error={data?.acDetail?.accountNo?.length === 0 ? t("Please enter Account Number") : ""}
              showErr={hasErr}
              type="number"
              className="col-sm-12 col-12"
            />

            <div className="d-flex align-items-center">
              <div style={{ width: "120px", fontSize: 12, fontWeight: "500", marginRight: 0 }}>
                {t("Account Balance")}
              </div>
              <div className="flex-1">

                <AmountField
                  innerStyle={{ border: "0px", }}
                  value={data?.balance}
                  onChange={(v) => {
                    setData({
                      ...data, balance: v
                    })
                  }}
                  style={{ borderRadius: "6px 6px 6px 6px", border: "1px solid #ced4da", }}
                  type="text"
                />
              </div>
            </div>

            <DateSelect
              placeholder={t("Opening Date")}
              value={data?.openingDate}
              label={t("Opening Date")}
              onChange={(v) => setData({ ...data, openingDate: v })}
              error={data?.openingDate?.length === 0 ? t("Please enter Account Opening Date") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-12 col-12"

            />
            <InputField
              placeholder={t("Bank Name")}
              value={data?.acDetail?.bank}
              label={t("Bank Name")}
              onChange={(v) => setData({ ...data, acDetail: { ...data?.acDetail, bank: v } })}
              error={data?.acDetail?.bank?.length === 0 ? t("Please enter Bank Name") : ""}
              showErr={hasErr}
              required={true}
              type="text"
              className="col-sm-12 col-12"
            />
            <InputField
              placeholder={t("IFSC Code")}
              required={true}
              value={data?.acDetail?.IFSC}
              label={t("IFSC Code")}
              onChange={(v) => setData({ ...data, acDetail: { ...data?.acDetail, IFSC: v } })}
              error={data?.acDetail?.IFSC?.length === 0 ? t("Please enter IFSC Code") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-12 col-12"

            />
            <InputField
              placeholder={t("SWIFT Code")}
              value={data?.acDetail?.SWIFT}
              label={t("SWIFT Code")}
              onChange={(v) => setData({ ...data, acDetail: { ...data?.acDetail, SWIFT: v } })}
              error={data?.acDetail?.SWIFT?.length === 0 ? t("Please enter SWIFT Code") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-12 col-12"
            />

          </Row>
        </Form>
      }

    </React.Fragment>
  );
};

export default observer(EditAccounts);
