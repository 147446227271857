import { makeAutoObservable } from "mobx";
import { doGET, doPOST } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../../../pages/Vehicle/VehicleConstant";

class VehicleSelect {
    vehicles = [];
    queue = [];
    isFetching = false;
    page = 1;
    rowsPerPage = 10;
    filterOption = "";
    total = -1;


    // Vehicle from vehicle_id via caching
    categoryCache = {};
    fetchQueue = {};
    loading = {};


    fetchVehicleFromVehicleId = async(vehicleId)=>
        {
            if(this.categoryCache[vehicleId]){
                return this.categoryCache[vehicleId];
            }
    
            if (this.loading[vehicleId]) {
                return new Promise((resolve, reject) => {
                  if (!this.fetchQueue[vehicleId]) this.fetchQueue[vehicleId] = [];
                  this.fetchQueue[vehicleId].push({ resolve, reject });
                });
              }
              this.loading[vehicleId] = true;
    
              try {
                const response = await doGET(`/api/vehicle/grid?rows=-1`);
                if (response.status === 200) {
                  const categories = response.data.rows.reduce((acc, item) => {
                    acc[item._id] = { ...item, label: item?.regNo+"("+item?.year+")"};
                    return acc;
                  }, {});
          
                  this.categoryCache = { ...this.categoryCache, ...categories };
          
                  while (this.fetchQueue[vehicleId]?.length > 0) {
                    const { resolve } = this.fetchQueue[vehicleId].shift();
                    resolve(this.categoryCache[vehicleId]);
                  }
          
                  return this.categoryCache[vehicleId] || null;
                } else {
                  throw new Error("Failed to fetch vehicles");
                }
              } catch (error) {
                throw new Error(error.message);
              } finally {
                delete this.loading[vehicleId];
              }
    
        }

    


    constructor() {
        makeAutoObservable(this);
    }


    fetchSingle = async (filterUrl) => {
        return new Promise(async (resolve, reject) => {
            try {

                const response = await doPOST(
                    ENDPOINTS.grid(), { queryURL: ENDPOINTS.filter(this.page, this.rowsPerPage, filterUrl) }
                );
           
                if (response.status === 200) {
                    if (this.page === 1) {
                        this.vehicles = response?.data?.rows
                        this.total = response?.data?.total;
                    } else {
                        this.vehicles = [...this.vehicles, ...response?.data?.rows]
                    }
                } else {
                    // Handle error
                    this.error = response.data;
                }


            } catch (err) {
                this.error = err;
            } finally {
                this.isFetching = false;
            }
        });
    };

    fetch = async function (filterUrl = "") {
        if (this.filterOption !== filterUrl) {
            this.page = 1;
            this.total = -1;
        }
        this.isFetching = true;
        this.fetchSingle(filterUrl)
    };

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch(this.filterOption);
    };
    pushNewData = (data) => {
        this.vehicles.push(data)
    }

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));
        if (response.status === 200) {
            return response.data
        }
    }
}

const VehicleSelectService = new VehicleSelect();
export default VehicleSelectService;


