import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./PaymentLogsConstant";
import { doGET } from "../../util/HttpUtil";


class PaymentLog_Service {

  records = [];
  totalRecords = 0;
  places = [];
  page = 1;
  rows = 10;
  STRUCTURE = [];
  filterOption = "";
  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterData) => {
    if (filterData) this.filterOption = filterData;
    const response = await doGET(ENDPOINTS.grid(this.page, this.rows, filterData))
    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }
  };

  structure = async () => {
    this.STRUCTURE = [
      {
        label: "startTimeFrom",
        description: "description...",
        filterLabel: "startTimeFrom",
        filterName: "startTimeFrom",
        type: "dateTimeSelect",
      },
      {
        label: "startTimeTo",
        description: "description...",
        filterLabel: "startTimeTo",
        filterName: "startTimeTo",
        type: "dateTimeSelect",
      },
      {
        label: "amountFrom",
        description: "description...",
        filterLabel: "amountFrom",
        filterName: "amountFrom",
        type: "text",
      },
      {
        label: "amountTo",
        description: "description...",
        filterLabel: "amountTo",
        filterName: "amountTo",
        type: "text",
      },

      {
        label: "status",
        description: "description...",
        filterLabel: "status",
        filterName: "status",
        type: "select",
        data: [
          { label: "Created", value: 0 },
          { label: "Initated", value: 1 },
          { label: "Success", value: 2 },
          { label: "Failed", value: 3 },
          { label: "Refund Initated", value: 4 },
          { label: "Refund completed", value: 5 },
          { label: "Paid from wallet", value: 6 },
          { label: "Partial paid by wallet rest by cash", value: 7 },
          { label: "Others", value: 8 },
          { label: "Paid by wallet and cash", value: 9 },
          { label: "Refund to wallet", value: 10 },
          { label: "Paid from gift voucher", value: 11 },
          { label: "Partially paid from gift voucher", value: 12 },
          { label: "Paid by gift voucher and cash", value: 13 },
        ]
      },
      {
        label: "currency",
        description: "description...",
        filterLabel: "currency",
        filterName: "currency",
        type: "select",
        data: await (async () => {
          var curr = await doGET(ENDPOINTS.currency);
          curr = curr?.data?.map((currency) => ({ label: currency, value: currency }));
          return curr
        })()
      }
    ];
    console.log(this.STRUCTURE, "karan")
  }

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch(this.filterOption);
  }

}

const PaymentLogService = new PaymentLog_Service();
export default PaymentLogService;
