import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu, Alert } from "reactstrap";
import { Button } from 'react-bootstrap'
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditCustomFields from "./EditCustomField";
import { STRUCTURE } from "./CustomFieldConstant";
import { GridColumns } from "./CustomFieldGridColumns";
import CustomFieldService from "./CustomFieldService";
import { UserContext } from "../../store/context/UserContext";
import SetupProgressService from "../Dashboard/SetUpProgressService";

const CustomFields = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/custom-field/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)
  const [onAlert, setOnAlert] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await CustomFieldService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])


  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      if (editID) {
        await CustomFieldService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("CustomField updated successfully."));
      } else {
        const customField = await CustomFieldService.save(detailData);
        setLoading(false);
        showMessage(t("CustomField saved successfully."));
        if (!insidePane) navigate(`/custom-field/edit/${customField}`);
      }
      fetchData(filterURL);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await CustomFieldService.delete(id);
        setLoading(false);
        showMessage("CustomField Deleted SuccessFully", "Deleted");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await CustomFieldService.bulkDelete(selectedIDs);
        showMessage(t("Deleted"), "Deleted Successfully");
        setShowDetailPage(false);

      } catch (e) {
        showError(e);
      }
    }
  };


  const renderLastCol = useCallback((customField) => {
    return (
      <>
        <EditLink
          onClick={() => {
            if (!insidePane)
              navigate(`/custom-field/edit/${customField?._id}`);
            setEditID(customField?._id);
            setShowDetailPage(true);

          }}
        />


        <DeleteLink
          onClick={(event) => onDelete(event, customField?._id)}
        />
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(customField?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane,])

  const markCompletedFunc = async () => {
    try {
      await SetupProgressService?.edit("customField");
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch()
    }
  }
    , [SetupProgressService?.records])

  return (
    <>
      <Layout
        hideAdd={!userContent?.rights?.includes(1501)}
        medium
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/custom-field");
          setEditID(null);
        }}
         
        title={t("CustomFields")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/custom-field/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={CustomFieldService.page}
        rows={CustomFieldService.rows}
        total={CustomFieldService.total}
        fetch={CustomFieldService.fetch}
      >
        <Layout.OnAlert>
          {!SetupProgressService?.records?.setUpStatus?.['customField'] ? <Alert color="danger" role="alert">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                Please add all of your registered company details here, which will be used in invoice generation
              </div>
              <div>
                <Button onClick={markCompletedFunc}>Mark Complete</Button>
              </div>
            </div>          </Alert> : <></>}
        </Layout.OnAlert>


        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> Download </DropdownItem>
              </>

            </DropdownMenu>

          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={CustomFieldService.records}
            total={CustomFieldService.total}
            uiPreference="customField.grid"
            headers={GridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            selectedIDs={selectedIDs}
            page={CustomFieldService.page}
            rowsPerPage={CustomFieldService.rowsPerPage}
            onPaginationChange={CustomFieldService.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>


        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit CustomField") : t("Add CustomField")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditCustomFields
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {userContent?.rights?.includes(1503) ? <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} /> : null}
          {userContent?.rights?.includes(1502) ? <SaveButton onClick={onSave} loading={loading} /> : null}
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(CustomFields);
