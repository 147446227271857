
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import OpenStreetMap from '../../components/OpenStreetMap';
import MarkerSelectionDropDown from '../../components/Rostering/MarkerSelectionDropDown';
import PassengerGrid from '../../components/Rostering/PassengerGrid';
import RosterTripGroups from '../../components/Rostering/RosterTripGroups';
import RosteringHeader from '../../components/Rostering/RosteringHeader';
import CustomRosteringPopup from './CustomRosteringPopup';
import { POLYLINE_COLOR } from './RosteringConstant';
import RosteringService from './RosteringService';
import "./styles.css";
import { toJS } from 'mobx';
import SpaceService from '../../components/space/SpaceService';
import { UserContext } from '../../store/context/UserContext';
import FullScreenLoader from '../../components/Loader/FullScreenLoader';



const RosteringView = () => {
    //TODO call data on space change also  
    const cardRefs = useRef([]);
    const {userContent}=useContext(UserContext);
    const [selectedTrip, setSelectedTrip] = useState(null);
    const [selectedPassenger, setSelectedPassenger] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const { mapData, toggleMapData, data, fetchRecords, updateData, passengers, setPassengers, updatePolyLines ,updateOffice,loading,updatePolyLineForTrip} = RosteringService
    const mapRef=useRef(null)
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
      };
    

useEffect(()=>{
    const updateSpaceIfNotSelected=()=>{ 
        if(SpaceService.records?.length && !localStorage.getItem("roster-space")){
           const foundSpace=SpaceService.records?.find(space=>space?.type>=2);
           foundSpace && SpaceService.setSelectedFolder(foundSpace, "roster");
        }
      }
      updateSpaceIfNotSelected()  
},[SpaceService.records])

    useEffect(() => {
        if (SpaceService.selectedFolder?._id) {
            fetchRecords(SpaceService.selectedFolder);
        }
        if (SpaceService?.selectedFolder?.office_id) {
            updateOffice(SpaceService?.selectedFolder?.office_id, userContent?.corp?._id)
        }
    }, [SpaceService?.selectedFolder?.office_id, userContent?.corp?._id])

    const handleCardSelection = useCallback((id) => {
        if (cardRefs.current[id]) {
            cardRefs.current[id].scrollIntoView({ behavior: 'smooth', block: 'center' });
            // add a class to highlight the selected card
            Object.keys(cardRefs.current).forEach((key) => {
                if (key !== id) {
                    cardRefs.current?.[key]?.classList?.remove('selected-card');
                } else {
                    cardRefs.current?.[key]?.classList?.add('selected-card');
                    setSelectedTrip(key)
                }
            })
        }
    }, [setSelectedTrip, cardRefs.current]);

    const handleMarkerClick = useCallback((marker) => {
        if (marker?.type === 'trip') {
            handleCardSelection(marker?.id);
        } else {
            //TODO check if passenger and he is approved then also do handlecard selection else simple select passenger
            //for now selecting the passenger card 
            setSelectedPassenger(marker?.id)
            //TODO deselect the trip card and select that trip card if he is allocated in any of the trip
        }
    }, [setSelectedPassenger, handleCardSelection, setSelectedTrip, cardRefs.current]);


    const handleOnDragEnd = (result) => {
        const { source, destination } = result;

        // If no destination, return early
        if (!destination) return;

        // If dragged to the same position, return early
        if (source.droppableId === destination.droppableId && source.index === destination.index) return;

        const sourceIndex = source.droppableId?.startsWith('tripList') ? source.droppableId.split('-')[1] : source?.index;
        const destinationIndex = destination.droppableId?.startsWith('tripList') ? destination.droppableId.split('-')[1] : destination.index;
    
        // Determine the source and destination lists
        const sourceList = source.droppableId.startsWith('tripList') ? [...(data[sourceIndex]?.passengers ?? [])] : [...passengers];
        const destinationList = destination.droppableId.startsWith('tripList') ? [...(data[destinationIndex]?.passengers ?? [])] : [...passengers];
    
        // Move the item within the same list or between different lists
        if (source.droppableId === destination.droppableId) {
            const [movedItem] = sourceList.splice(source.index, 1);
            sourceList.splice(destination.index, 0, movedItem);
    
            if (source.droppableId.startsWith('tripList')) {
                const newData = [...data];
                newData[sourceIndex].passengers = sourceList;
                updateData(newData);
                updatePolyLineForTrip(newData[sourceIndex]);
            } else {
                setPassengers([...sourceList]);
            }
        } else {
            const [movedItem] = sourceList.splice(source.index, 1);
            destinationList.splice(destination.index, 0, movedItem);
    
            if (source.droppableId.startsWith('tripList')) {
                const newData = [...data];
                newData[sourceIndex].passengers = sourceList;
                updateData(newData);
                updatePolyLineForTrip(newData[sourceIndex]);
            } else {
                setPassengers([...sourceList]);
            }
    
            if (destination.droppableId.startsWith('tripList')) {
                const newData = [...data];
                newData[destinationIndex].passengers = destinationList;
                updateData(newData);
                updatePolyLineForTrip(newData[destinationIndex]);
            } else {
                setPassengers([...destinationList]);
            }
        }
    };

    const onTripClick = (trip) => {
        handleCardSelection(trip?.id);
        //TODO update only that polyline color and maybe reset bounds to see that trip polylines etc only
        updatePolyLines(
            mapData?.polyLines?.map((polyline) => ({
                ...polyline,
                color: polyline?.cardId === trip?.id ? POLYLINE_COLOR.selected : POLYLINE_COLOR.default,
                pane: polyline?.cardId === trip?.id?'highPane':'lowPane',
                weight: polyline?.cardId === trip?.id ? 7 : 4,
                opacity: polyline?.cardId === trip?.id ? 1 : 0.7,
                fillOpacity: polyline?.cardId === trip?.id ? 1 : 0.7,
                selected: polyline?.cardId === trip?.id
            }))
        )
        // mapRef.current?.updateMapBounds([],mapData?.polyLines?.filter(polyline=>polyline?.cardId===trip?.id));

    }
    const memoizedMap = useMemo(() => (
        <OpenStreetMap
            markers={toJS(mapData.markers)}
            polyLines={toJS(mapData.polyLines)}
            onMarkerClick={handleMarkerClick}
            showPopup
            customPopup={CustomRosteringPopup}
            ref={mapRef}
        />
    ), [mapData.markers, mapData.polyLines, handleMarkerClick,mapRef]);

    const clickMarker=(markerId)=>mapRef.current?.clickMarker(markerId)

    return (
        <div className='page-content'>
            <RosteringHeader />
            <div className='d-flex'>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <div className={`d-flex col-sm-12 col-md-${collapsed?3:4} mt-2`} style={{ maxHeight: '82vh', height: '82vh' }}  >
                        <RosterTripGroups className={collapsed?'col-md-9':'col-md-7'} trips={data} onTripClick={onTripClick} ref={cardRefs} selectedTrip={selectedTrip} />
                        <PassengerGrid clickMarker={clickMarker} selectedPassenger={selectedPassenger} collapsed={collapsed} toggleCollapse={toggleCollapse}/>
                    </div>
                </DragDropContext>

                <div className='d-flex flex-1 mt-2 position-relative'>
                    {/* <MarkerSelectionDropDown /> */}
                    {/* <OpenStreetMap
                        markers={toJS(mapData.markers)}
                        polyLines={toJS(mapData.polyLines)}
                        // polyLine={["gxmlD}ssuMeACWE_@QIICGOIg@YuAu@L{AK_@[y@ES@[Hk@BOc@Ms@g@gD}@_CUqAIw@IsEoAuAWm@GBVHXBb@lApAp@t@MPiCuCy@BuAEeAQ}@Ig@AUBKFMNAVJz@DR_@Lk@NKTD`BMbICjC??@|ECbH?|G?jAFPJX?n@eLKkA@g@Fo@PYPIFOFQ@OAQGKGEISG[ES?w@LeCh@gF`AmBb@mGhAeHzAcCn@eEfA}DfAyBp@{Bn@{C|@gCt@}Bv@eBb@wAXyHdBeEt@mBl@eFjA}A`@QMIUEU@QFSdCq@~Cw@|@U|IuAdDm@tB[t@IbBa@jDgAvFwA|Ae@lDgApCu@zBk@|@UrFcAdFcAC[[cDJ_@~@kB^}@BUC[WiBu@yFQqAJG??HA?D^rC|@`H@ZCXYn@iAzBKZd@~CFXpAQfCc@pFgAPI\\G~@_@h@]DEHGPGTATFTNPX@TCXIVKPIFOFQ@OAQGKGEISG[ES?w@LeCh@gF`AmBb@mGhAeHzAcCn@eEfA}DfAyBp@{Bn@{C|@gCt@}Bv@eBb@wAX{A\\YCeC^{Dr@oE|@_E~@cEbA}A^k@RoD`AgBd@}Bf@aBZwA^qDt@yEz@oBZ_HpA{FhAi@D}FnA_LxBkNlBo[lD_Mp@s@@gA@cBHk@?uE@cID}CH]Oq@@{IDoC@kAKmDq@{IeBkDq@{Fe@_H]i@De@He@N]Pa@^i@j@oBvDeBnDgEhIy@`By@rAWXMJa@Tk@Fu@C}@UcAa@gBw@cCgAeF{B{CwAaHqC[EWAg@Jc@VQXuCpFmBtDy@~AWRu@l@gAv@g@NSLMFkAaAsBeBCa@@SHMLG\\ExDdDJBZGXQ`Au@`@c@h@y@h@gAhAaClBoDt@iAf@c@ZO^Id@?x@PnIrDjEjBrDbBjEjBJKT?J?FAn@Hl@AVGHWb@s@jA_CwAcAyCgBiBgAC?G?COBGDAD?DB?BbCxA","gxmlD}ssuMeACWE_@QIICGOIg@YuAu@L{AK_@[y@ES@[Hk@BOc@Ms@g@gD}@_CUqAIw@IsEoAuAWm@GBVHXBb@lApAp@t@MPiCuCy@BuAEeAQ}@Ig@AUBKFMNAVJz@DR_@Lk@NKTD`BMbICjC??@|ECbH?|G?jAFPJX?n@eLKkA@g@Fo@PYPIFOFQ@OAQGKGEISG[ES?w@LeCh@gF`AmBb@mGhAeHzAcCn@eEfA}DfAyBp@{Bn@{C|@gCt@}Bv@eBb@wAXyHdBeEt@mBl@eFjA}A`@QMIUEU@QFSdCq@~Cw@|@U|IuAdDm@tB[t@IbBa@jDgAvFwA|Ae@lDgApCu@zBk@|@UrFcAdFcAC[[cDJ_@~@kB^}@BUC[WiBu@yFQqAJG??HA?D^rC|@`H@ZCXYn@iAzBKZd@~CFXpAQfCc@pFgAPI\\G~@_@h@]DEHGPGTATFTNPX@TCXIVKPIFOFQ@OAQGKGEISG[ES?w@LeCh@gF`AmBb@mGhAeHzAcCn@eEfA}DfAyBp@{Bn@{C|@gCt@}Bv@eBb@wAX{A\\YCeC^{Dr@oE|@_E~@cEbA}A^k@RoD`AgBd@}Bf@aBZwA^qDt@yEz@oBZ_HpA{FhAi@D}FnA_LxBkNlBo[lD_Mp@s@@gA@cBHk@?uE@cID}CH]Oq@@{IDoC@kAKmDq@{IeBkDq@{Fe@_H]i@De@He@N]Pa@^i@j@oBvDeBnDgEhIy@`By@rAWXMJa@Tk@Fu@C}@UcAa@gBw@cCgAeF{B{CwAaHqC[EWAg@Jc@VQXuCpFmBtDy@~AWRu@l@gAv@g@NSLMFkAaAsBeBCa@@SHMLG\\ExDdDJBZGXQ`Au@`@c@h@y@h@gAhAaClBoDt@iAf@c@ZO^Id@?x@PnIrDjEjBrDbBjEjBJKT?J?FAn@Hl@AVGHWb@s@jA_CwAcAyCgBiBgAC?G?COBGDAD?DB?BbCxA"]}
                        onMarkerClick={handleMarkerClick}
                        showPopup
                        customPopup={CustomRosteringPopup}
                    /> */}
                    {memoizedMap}

                </div>
                {/* <div onClick={() => handleMarkerClick('6675510d995e47691298e98c1')}>click</div> */}
            </div>
            <FullScreenLoader loading={loading} />
        </div>
    );
};

export default observer(RosteringView);