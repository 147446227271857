// import React, { useState } from 'react';
// import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
// import './styles.css';

// const MinimizableComponent = () => {
//   const [isMinimized, setIsMinimized] = useState(false);

//   const toggleMinimize = () => {
//     setIsMinimized(!isMinimized);
//   };

//   return (
//     <div className={`minimizable-component ${isMinimized ? 'minimized' : ''}`}>
//       <div className="header">
//         <h2> Component</h2>
//         <button onClick={toggleMinimize}>
//           {isMinimized ? <FaChevronDown /> : <FaChevronUp />}
//         </button>
//       </div>
//       {!isMinimized && (
//         <div className="content">
//           <p> lsjfjslkfjl jsfoisjdfljs fksdfjklslkfjlksdjfjsljfljkdjfsklfdjsfkljslkjfkljsf.</p>

//         </div>
//       )}
//     </div>
//   );
// };

// export default MinimizableComponent;


import React, { useState } from 'react';
import './styles.css';
import { CloseButton } from 'reactstrap';
import { FiMinimize2, FiMaximize2 } from "react-icons/fi";


const MinimizableComponent = ({ children, onClose, title }) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const toggleMinimize = () => setIsMinimized(prev => !prev);

  return (
    <div className={`modal-minimizable-container ${isMinimized ? 'minimized rounded-2' : 'full-screen rounded-0'}`}>
      <div className={`flex-1 m-0 m-${!isMinimized ? 5 : 0} bg-white rounded-2 minimizable-content-body`}>
        <div className="modal-minimizable-header rounded-2 justify-content-between py-2">

          <div className='fs-6 fw-bold ms-2'>{title}</div>

          <div className='d-flex align-items-center gap-4'>
            <div className='cursor-pointer ' onClick={toggleMinimize}>
              {isMinimized ? <FiMaximize2 size={20} /> : <FiMinimize2 size={20} />}
            </div>
            <CloseButton onClick={onClose} className=' p-2 fs-5' />
          </div>
        </div>
        <div className="modal-minimizable-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default MinimizableComponent;
