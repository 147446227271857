import React, { useContext } from 'react'
import { ClientSelect, CompanySelect } from '../../components';
import { I18nContext } from '../../store/context/i18nContext';
import "./style.css";

const RowComponent = ({ ques, value, isLink, onClick }) => {
  return (
    <div className='d-flex'>
      <div className='fv_detail_account-question'>{ques}</div>
      <div className='mx-1'></div>
      <div onClick={onClick}>{value}</div>
    </div>
  )
}

function WalkInInvoice({ invoice, onChange = () => { } }) {
  const { t } = useContext(I18nContext);
  const address = !invoice?.client?.address ? "--" : [invoice?.client?.address?.addressLine, invoice?.client?.address?.city, invoice?.client?.address?.state].join(", ")
  const taxId = !invoice?.client?.taxId ? "--" : invoice?.client?.taxId;
  return (
    <div className="position-relative">
      <div className="selectfield__wrapper cursor-pointer cursor-style mt-0 pt-0">
        <div className=" d-flex justify-content-center align-items-center selectfield__container">
          <div className="d-flex w-100 row flex-1 flex-column">
            <div className="col-5 px-0 ">
              <ClientSelect
                isDisabled={(invoice?.savedStatus !== 1) || (invoice?.walkInInvoice && !invoice._id)}
                value={invoice?.client?.org_id}
                onChange={(v) => {
                  onChange({
                    client: {
                      ...(invoice?.client ?? {}),
                      org_id: v?.value,
                      company_id: null
                    },
                  });
                }}
                placeholder={t("Client")}
                innerContainerClassName="input-group-pre"
                className="ms-2 me-0 w-100 "
              // outline={true}
              />
            </div>
            <div className="col-sm-5 p-0">
              <CompanySelect
                className="ms-2 w-100"
                // outline
                corpType="client"
                isDisabled={(invoice?.savedStatus !== 1) || (invoice?.walkInInvoice && !invoice._id)}
                value={invoice?.client?.company_id}
                onChange={(v) => {
                  onChange({
                    client: {
                      ...invoice?.client,
                      company_id: v?.value,
                      fullName: v?.fullName,
                      taxId: v?.companyExtn?.taxId,
                    },
                  });
                }}
                innerContainerClassName="input-group-post"
                label="Client Company"
                corp_id={invoice?.client?.org_id}
              />
            </div>
          </div>
        </div>
      </div>
      <RowComponent ques={"GST IN"} value={taxId} />
      <RowComponent ques={"Address"} value={address} />
    </div>
  )
}

export default WalkInInvoice;