import _ from "lodash";
import CategoryLabel from "../../components/Dropdowns/CategorySelect/CategoryLabel";
import { SupplierSelect } from "../../components";
function convertDate(inputDate) {
  const year = Math.floor(inputDate / 10000);
  const month = Math.floor((inputDate % 10000) / 100);
  const day = inputDate % 100;

  const formattedDate = `${day?.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
  return formattedDate;
}
export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Supplier Name",
      show: true,
      renderer: (dataObj) => {
        return <div>
          <SupplierSelect
            label={"Vehicle Type"}
            value={dataObj?.supplier_id}
            className="col-sm-3"
            plain
            asLabel
          />
        </div>
      },
    },
    {
      title: "Type",
      accessor: "type",
      show: true,
      renderer: (dataObj) => {
        return <div>
          {_.capitalize(dataObj?.type) ?? "-"}
        </div>;
      },

    },
    {
      title: "Status",
      accessor: "status",
      show: true,
      renderer: (dataObj) => {
        return <div>
          {dataObj?.status === 1 ? "New" : dataObj?.status === 2 ? "Partially Paid" : dataObj?.status === 3 ? "Paid" : dataObj?.status === 4 ? "Cancelled" : null}
        </div>;
      },

    },
    {
      title: "Date",
      accessor: "date",
      show: true,
      renderer: (dataObj) => {
        return <div>
          {dataObj?.date ? <div>
            {convertDate(dataObj.date)}
          </div> : null}
        </div>;
      },
    },

    {
      title: "Category",
      accessor: "category_id",
      show: true,
      renderer: (dataObj) => {
        return <div>
          <CategoryLabel value={dataObj?.category_id} />
        </div>;
      },
    },

    {
      title: "Sub Category",
      accessor: "subCategory_id",
      show: true,
      renderer: (dataObj) => {
        return <div>
          <CategoryLabel value={dataObj?.subCategory_id} />
        </div>;
      },
    },
    {
      title: "Total",
      accessor: "total",
      show: true,
      renderer: (dataObj) => {
        return <div>
          {dataObj?.currency} 
          
          {" "}{_.round((dataObj?.total ?? 0.00) / 100, 2).toFixed(2)}

        </div>;
      },

    },

    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
