import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function Popup(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Call by a User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props?.username}</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-success" onClick={props?.onPopupConfirmation}>
          Accept
        </Button>
        <Button className="btn-danger" onClick={props?.onPopupRejection}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
