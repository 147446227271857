import { useContext, useEffect, useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  Button
} from "reactstrap";
import { read, utils } from "xlsx";
import { DialogContext } from "../../store/context/DialogContext";
import { doGET } from "../../util/HttpUtil";
import "../BulkUpload/style.scss";
import SaveButton from "../Buttons/SaveButton";
import ModalComponent from "../Modal/Modal";
import PopConfirm from "../PopConfirm/PopConfirm";
import DataRow from "./DataRow";

import CheckButton from "../Buttons/Icons/checkButton";
import "./style.css";
import "./style.scss";
import { ThreeBounce } from "better-react-spinkit";

const getStatusMessage = (status) => {
  if (status == 1) {
    return 'Yet to upload';
  }
  if (status == 2) {
    return 'Uploading';
  }
  if (status == 3) {
    return 'Failed';
  }
  if (status == 4) {
    return 'Success';
  }
}

const ExcelToEditableTable = ({ uploadedFile, template_id, upload,fetchData,onClose }) => {
  const [file, setFile] = useState(uploadedFile ? uploadedFile : null);
  const [data, setData] = useState([]);
  const [bulkData, setBulkData] = useState([]);
  const [header, setHeader] = useState([]);
  const [fields, setFields] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const [rowStates, setRowStates] = useState([]); //  1: Yet to upload , 2: uploading, 3 : failed , 4 : uploaded
  const [enableUploadAllButton, setEnableUploadAll] = useState(false);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false)


  const getDataFromExcel = async () => {
    try {
      if (file && file.preview) {
        const f = await (await fetch(file.preview)).arrayBuffer();
        const wb = read(f);
        const ws = wb.Sheets[wb.SheetNames[0]];
        const dataArray = utils.sheet_to_json(ws, { raw: true });
        const headerArray = utils.sheet_to_json(ws, { header: 1, raw: true })[0] || [];

        setData(dataArray);
        setBulkData(dataArray);
        setRowStates(dataArray?.map(data => ({ status: 1, msg: "" })));
        setHeader(headerArray);
      }
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  }, [uploadedFile]);

  useEffect(() => {
    getDataFromExcel();
  }, [file]);

  const fetchTemplateDetails = async () => {
    try {
      const response = await doGET(`/api/template/${template_id}/detail`)
      setFields(response?.data?.contents)
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    if (template_id) fetchTemplateDetails()
  }, [template_id])

  const getDataType = (header) => {
    let dataType = "STRING"
    fields?.map((field, fieldIndex) => {
      if (header == field?.title) {
        if (field?.enumOptions?.length || Object?.keys(field?.intOptions ?? {})?.length) {
          dataType = "SELECT"
        } else if (field?.kind === "Integer" && field?.field?.endsWith("Date")) {
          dataType = "DATE_SELECT"
        } else if (field?.kind === "TIME_LONG") {
          dataType = "TIME_SELECT"
        } else {
          dataType = field?.kind
        }
      }
    })
    return dataType;
  };

  const getSelectOptions = (header) => {
    let options = []
    fields?.map((field, fieldIndex) => {
      if (header == field?.title) {
        if (field?.enumOptions?.length) {
          options = field?.enumOptions?.map((opt) => {
            return { label: opt, value: opt }
          })
        } else if (Object?.keys(field?.intOptions)?.length) {
          options = [];
          for (const key in field?.intOptions) {
            options.push({ label: field?.intOptions[key], value: parseInt(key) });
          }
        }
      }
    })
    return options;
  };


  function createObjectFromKeys(obj) {
    const result = {};

    for (const key in obj) {
      const keys = key.split('.');
      let nestedObj = result;

      for (let i = 0; i < keys.length; i++) {
        let currentKey = keys[i];
        const nextKey = keys[i + 1];

        if (!isNaN(parseInt(currentKey, 10))) {
          // Current key is a number, indicating an array index
          currentKey = parseInt(currentKey, 10);
        }

        if (nestedObj[currentKey] === undefined) {
          if (i === keys.length - 1) {
            nestedObj[currentKey] = obj[key];
          } else {
            nestedObj[currentKey] = isNaN(parseInt(nextKey, 10)) ? {} : [];
          }
        }

        nestedObj = nestedObj[currentKey];
      }
    }

    return result;
  }


  const mapDataFormat = (data) => {
    let uploadData = {};

    const normalizedData = {};
    Object.keys(data).forEach(key => {
      const normalizedKey = key.replace(/\s+/g, ' ').trim(); // normalize spaces
      normalizedData[normalizedKey] = data[key];
    });

    fields?.forEach((field, fieldIndex) => {
      const fieldTitle = field?.title.replace(/\s+/g, ' ').trim(); // normalize spaces
      const fieldData = normalizedData[fieldTitle];
      if (fieldData || fieldData === 0) {
        uploadData[field?.field] = fieldData?.value ?? fieldData;
      }
    });

    return createObjectFromKeys(uploadData);
  };


  const handleUpdate = (rowIndex, item, newValue) => {
    const newData = [...data];
    const newObj = newData.at(rowIndex);
    newObj[item] = newValue;
    setData(newData);
  };

  const handleDelete = (rowIndex) => {
    //deleting from data array
    const newData = [...data];
    newData.splice(rowIndex, 1);
    setData(newData);
    //deleting from error array
    const newData2 = [...rowStates];
    newData2.splice(rowIndex, 1);
    setRowStates(newData2);
  };

  const massUpload = async () => {
    setLoading(true)
    try {
      const updatedRowStates = [...rowStates];

      for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
        const rowData = data[rowIndex];

        if (updatedRowStates[rowIndex]["status"] !== 4) {
          if (rowIndex === 0) {
            updatedRowStates[rowIndex]["status"] = 2;
            updatedRowStates[rowIndex]["msg"] = "";
            setRowStates(updatedRowStates);
          }
          const response = await upload(mapDataFormat(rowData));
          updatedRowStates[rowIndex] = {
            status: response?.isUploaded ? 4 : 3,
            msg: response?.message || "",
          };
          if (rowIndex + 1 < data?.length) {
            if (updatedRowStates[rowIndex + 1]["status"] === 1 || updatedRowStates[rowIndex + 1]["status"] === 3) {
              updatedRowStates[rowIndex + 1] = { status: 2, msg: "" };
            }
          }
          setRowStates(updatedRowStates);
        }
      }
      setRowStates(updatedRowStates); // setRowStates is not working
      fetchData();
      onClose();
    } catch (error) {
      console.log(error)
      showError(error);
    }
    setLoading(false)
  };

  const isSuccess = true;

  useEffect(() => {
    if (errorArray.length > 0) {
      setEnableUploadAll(errorArray.every(isSuccess));
    }
  }, [errorArray]);


  const addRow = () => {
    const newRow = {}
    header?.forEach(field => (newRow[field] = null))
    setData([...data, newRow])
    setRowStates([...rowStates, { status: 1, msg: "" }])
  }

  return (
    <>
      <ModalComponent.Body>

        {/* {JSON.stringify(rowStates)} */}
        <div className="">
          {data.length > 0 && header.length > 0 && (
            <div className="table-container ps-3">
              <table
                style={{ maxWidth: "100%", overflow: "auto" }}
                id="excelRead"
                className="table table-striped bulk-table  "
              >
                <thead>
                  <tr className="w-100 row p-0 " style={{ flexWrap: "nowrap" }}>
                    <th
                      className="text-center "
                      key={"action"}
                      style={{ minWidth: "50px", maxWidth: "50px", border: "1px solid #ccc", }}
                    >
                      S. No.
                    </th>
                    {header?.map((item) => {
                      return (
                        <th
                          className="text-center  "
                          style={{ maxWidth: "200px", border: "1px solid #ccc", }}
                          key={item}
                        >
                          {item}
                        </th>
                      );
                    })}
                    {rowStates.length > 0 && (
                      <th
                        className="text-center "
                        key={"message"}
                        style={{ maxWidth: "200px", border: "1px solid #ccc", }}
                      >
                        Message
                      </th>
                    )}
                    <th
                      className="text-center "
                      key={"action"}
                      style={{ maxWidth: "100px", maxWidth: "100px", border: "1px solid #ccc", }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data.map((rowData, rowIndex) => {
                    return (
                      <tr
                        className="w-100 row"
                        key={rowIndex}
                        style={{ flexWrap: "nowrap", backgroundColor: (rowIndex % 2 !== 0) ? "#faf9ff" : "#fff" }}
                      >

                        <td
                          className="text-center d-flex justify-content-center align-items-center "
                          style={{ border: "1px solid #ccc", minWidth: "50px", maxWidth: "50px", backgroundColor: (rowIndex % 2 == 0) ? "#fff" : "transparent" }}
                        >
                          {rowIndex}.
                        </td>

                        <DataRow
                          handleUpdate={handleUpdate}
                          getDataType={getDataType}
                          getSelectOptions={getSelectOptions}
                          headerRow={header}
                          headerData={header[rowIndex]}
                          rowIndex={rowIndex}
                          rowData={rowData}
                        />

                        {rowStates.length > 0 && (
                          <td
                            className="text-center "
                            style={{ maxWidth: "200px", border: "1px solid #ccc", }}
                          >
                            <div
                              className="mt-2"
                              style={{ backgroundColor: (rowIndex % 2 !== 0) ? "#fff" : "transparent" }}
                            >
                              {getStatusMessage(rowStates[rowIndex]?.status)}<br />
                              {(rowStates[rowIndex]?.msg)}
                            </div>

                          </td>
                        )}
                        <td
                          className="text-center "
                          style={{ border: "1px solid #ccc", maxWidth: "100px", maxWidth: "100px", backgroundColor: (rowIndex % 2 == 0) ? "#fff" : "transparent" }}
                        >
                          <div
                            style={{ backgroundColor: (rowIndex % 2 == 0) ? "transparent" : "#fff" }}
                            className=" align-items-center justify-content-center d-flex">

                            {(rowStates[rowIndex]?.status == 2 || rowStates[rowIndex]?.status == 4) ? null : <PopConfirm
                              title={"Delete This Entry?"}
                              style={{ postion: "absolute", zIndex: "3" }}
                              onConfirm={() => {
                                handleDelete(rowIndex);
                              }}
                            >
                              <i
                                role="button"
                                className=" dripicons-trash text-danger font-size-18 mx-0"
                              />
                            </PopConfirm>}

                            {rowStates[rowIndex]?.status == 4 ? <i className="uil uil-cloud-check  text-success font-size-20 " /> : null}

                            {rowStates[rowIndex]?.status !== 4 ?
                              <SaveButton
                                onlyIcon
                                key={rowIndex}
                                loading={rowStates[rowIndex]?.status == 2}
                                onClick={async () => {
                                  const updatedRowStates = [...rowStates];
                                  updatedRowStates[rowIndex]["status"] = 2;
                                  setRowStates(updatedRowStates);
                                  const response = await upload(mapDataFormat(rowData));
                                  const updatedstate = [...rowStates];
                                  updatedstate[rowIndex] = { status: response?.isUploaded ? 4 : 3, msg: (response?.message) };
                                  setRowStates(updatedstate);
                                }}
                              /> : null}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  <tr style={{ height: "200px" }}>

                  </tr>
                </tbody>


              </table>
            </div>
          )}
        </div>
      </ModalComponent.Body>
      <ModalComponent.Footer>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => {
              if (!loading) addRow();
            }}
            className="me-3 d-flex align-items-center"
          >
            {loading ? <ThreeBounce size={10} color="#FFFFFF" /> : <> <i className="bx bx-plus me-1 font-size-20" />  Row</>}
          </Button>
          <Button
            onClick={() => {
              if (!loading) {
                massUpload();
              }
            }}
            className="d-flex align-items-center me-3"
          >
            {loading ? <ThreeBounce size={10} color="#FFFFFF" /> : <>  <i className="bx bxs-cloud-upload  font-size-20 me-1 " /> Upload All</>}
          </Button>


        </div>
      </ModalComponent.Footer>
    </>
  );
};

export default ExcelToEditableTable;
