// complete todo 
import { observer } from "mobx-react-lite";
import React, { useContext, useRef, useState } from "react";
import { CirclePicker } from "react-color";
import { FaPlus, FaTimes } from "react-icons/fa";
import { DialogContext } from "../../../store/context/DialogContext";
import { useOutsideAlerter } from "../board/helper";
import SpaceService from "../listView/SpaceService";
import "./styles.scss";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FiMinusCircle } from "react-icons/fi";
import { Button } from "reactstrap";



const SpaceStatuses = ({ statuses = [], onUpdate }) => {

    const { showError, showMessage } = useContext(DialogContext);
    const [activeColorPicker, setActiveColorPicker] = useState(null); // NEW: Track active color picker
    const colorPickerRef = useRef(null);

    useOutsideAlerter(colorPickerRef, () => setActiveColorPicker(null));

    const handleAddStatus = () => {
        // Convert keys to integers and sort them
        const keys = Object.keys(statuses).map(key => parseInt(key)).sort((a, b) => a - b);

        // Find the next available key
        let nextKey = 1; // Start with 1 as the smallest possible key
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === nextKey) {
                nextKey++; // If the current key is taken, move to the next one
            } else {
                break; // Found a gap or reached the end, nextKey is now the smallest available key
            }
        }

        // Ensure nextKey is within the range 1 to 30
        if (nextKey > 30) {
            alert('Maximum number of statuses reached. Cannot add more.');
            return; // Stop the function if the key exceeds 30
        }

        // Create a new status with the found key
        const newStatus = {
            color: "#ffffff",
            name: "New Status",
            id: Date.now() // id is used for React keys, not for the statusNameMap keys
        };

        // Update statuses with the new status
        const updatedStatuses = { ...statuses, [nextKey.toString()]: newStatus };

        onUpdate(updatedStatuses);
    };

    const handleDeleteStatus = (id) => {
        const updatedStatuses = { ...statuses };
        delete updatedStatuses[id];

        onUpdate(updatedStatuses);
    };

    const handleChange = (id, field, value) => {
        const updatedStatuses = { ...statuses, [id]: { ...statuses[id], [field]: value } };
        onUpdate(updatedStatuses);
    };


    const onSave = async () => {
        const payload = {
            ...SpaceService.selectedSpace,
            statusNameMap: statuses,
        };
        try {
            if (SpaceService.selectedSpace?._id) {
                await SpaceService.edit(payload);
                showMessage('Space updated successfully!');
            } else {
                await SpaceService.save(payload);
                showMessage('Space created successfully!');
            }
        } catch (error) {
            console.error('Error saving space:', error);
            showError('Error saving space. Please try again.');
        }
    };

    const handleHexInputChange = (event, key) => {
        const hexValue = event.target.value;
        if (/^#([0-9A-F]{3}){1,2}$/i.test(hexValue)) {
            handleChange(key, "color", hexValue)
        }

    };


    return (
        <div className="space-statuses">
            <div className="w-100 d-flex justify-content-end">
                <button
                    onClick={handleAddStatus}
                    style={{ padding: "3px 6px 3px 3px", width: "90px" }}
                    className="d-flex align-items-center justify-content-center btn btn-outline-dark py-0 my-2"
                >
                    <FaPlus className="me-2" />
                    <div style={{ margin: "2px", float: "right" }}>Status</div>
                </button>
            </div>
            {Object.keys(statuses).map((key) => (
                <div className="status-row d-flex align-items-center" key={key}>
                    <div
                        className="color-circle"
                        style={{ backgroundColor: statuses[key].color }}
                        onClick={() => setActiveColorPicker(key === activeColorPicker ? null : key)}
                    />

                    {activeColorPicker === key && (
                        <div className="color-picker-container card" ref={colorPickerRef}>
                            <FaTimes className="close-icon" onClick={() => setActiveColorPicker(null)} />
                            <CirclePicker
                                color={statuses[key].color}
                                onChangeComplete={(color) => handleChange(key, "color", color.hex)}
                                width="180px"
                            />
                            <input
                                type="text"
                                value={statuses[key].color}
                                className="mt-2"
                                onChange={(e) => handleHexInputChange(e, key)}
                                placeholder="#000000"
                            />
                        </div>
                    )}
                    <input
                        type="text"
                        className="status-name-input"
                        value={statuses[key].name}
                        onChange={(e) => handleChange(key, "name", e.target.value)}
                    />
                    <FiMinusCircle style={{
                        cursor: "pointer"
                    }} color="red" size={21} onClick={() => handleDeleteStatus(key)} />
                </div>
            ))}
        </div>
    );
};

export default observer(SpaceStatuses)