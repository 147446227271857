import { useContext } from "react";
import { UserContext } from "../../../store/context/UserContext";
import { useSocket } from "../socket/useSocket";

export const usePeer = (onTrackEventCallback) => {
  const { peerRef } = useContext(UserContext);
  const { socketInstance, socketEmitter, socketRemoveListeners, socketAddListeners } = useSocket();

  const peerInit = async (otherPeerSocketId) => {
    if (!peerRef.current && socketInstance()) {

      //listeners for peer connection
      // if (role === "caller")
      socketAddListeners(
        [
          {
            event: "answer",
            callback: handleResponse
          },
          {
            event: "ice-candidate",
            callback: handleNewICECandidateMsg
          },
        ]
      )

      const peer = new RTCPeerConnection({
        iceServers: [
          {
            urls: "stun:stun.stunprotocol.org",
          },
          {
            urls: "turn:numb.viagenie.ca",
            credential: "muazkh",
            username: "webrtc@live.com",
          },
        ],
      });

      peer.onicecandidate = (e) => handleICECandidateEvent(e, otherPeerSocketId);

      peer.ontrack = (e) => {
        console.log("ontrack");
        if (onTrackEventCallback) onTrackEventCallback(e.streams[0]);
      };
      peer.onnegotiationneeded = () =>
        handleNegotiationNeededEvent(otherPeerSocketId);

      peerRef.current = peer;


    };
  }

  const peerStartWaiting = async (onPeerWaitOver, otherPeerSocketId) => {
    socketAddListeners(
      [
        {
          event: "offer",
          callback: (incoming) => handleRequest(incoming, otherPeerSocketId, onPeerWaitOver)
        },
        {
          event: "ice-candidate",
          callback: handleNewICECandidateMsg
        },
      ]
    )
  }

  const peerInstance = () => {
    return peerRef.current;
  };

  const handleNegotiationNeededEvent = async (otherPeerSocketId) => {
    if (!peerRef.current) return;
    peerRef.current
      .createOffer()
      .then((offer) => peerRef.current.setLocalDescription(offer))
      .then(() => {
        const payload = {
          target: otherPeerSocketId,
          caller: socketInstance().id,
          sdp: peerRef.current.localDescription,
        };
        socketEmitter("offer", payload);
      })
      .catch((e) => console.log(e));
  };

  function handleICECandidateEvent(e, otherPeerSocketId) {
    if (e.candidate) {
      const payload = {
        target: otherPeerSocketId,
        candidate: e.candidate,
      };
      socketEmitter("ice-candidate", payload);
    }
  }

  function handleResponse(message) {
    if (!peerRef.current) return;
    const desc = new RTCSessionDescription(message.sdp);
    peerRef.current.setRemoteDescription(desc).catch((e) => console.log(e));
  }

  function handleNewICECandidateMsg(incoming) {
    if (!peerRef.current) return;
    const candidate = new RTCIceCandidate(incoming);
    peerRef.current.addIceCandidate(candidate).catch((e) => console.log(e));
  }

  async function handleRequest(incoming, otherPeerSocketId, onPeerWaitOver) {
    await peerInit(otherPeerSocketId);

    const desc = new RTCSessionDescription(incoming.sdp);

    peerRef.current
      .setRemoteDescription(desc)
      .then(async () => {
        if (onPeerWaitOver)
          await onPeerWaitOver()
      })
      .then(() => {
        return peerRef.current.createAnswer();
      })
      .then((answer) => {
        return peerRef.current.setLocalDescription(answer);
      })
      .then(() => {
        const payload = {
          target: incoming.caller,
          caller: socketInstance().id,
          sdp: peerRef.current.localDescription,
        };
        socketEmitter("answer", payload);
      });
  }

  function peerClose() {
    socketRemoveListeners(
      ["answer",
        "offer",
        "ice-candidate"])
    if (peerRef.current) {
      peerRef.current.close();
      peerRef.current = null
    }
  }

  return {
    peerInit,
    handleResponse,
    handleNewICECandidateMsg,
    peerInstance,
    peerClose,
    peerStartWaiting
  };
};
