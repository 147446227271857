export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/corporate/grid?`;
    if (sizePerPage) url += `&rows=${sizePerPage}`;
    if (filterUrl) url += `&${filterUrl}`;
    if (page) url += `&page=${page}`
    return url;
  },
  get: (id) => `/api/corporate/${id}/detail`,
  update: `/api/corporate/update`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Corporate Name",
    filterLabel: "Corporate Name",
    filterName: "address.name",
    type: "text",
  },
];

export const SERVICEREQUIRED = [
  {
    label: "Car Rental",
    value: "CarRental"
  },
  {
    label: "Car Lease",
    value: "CarLease"
  },
  {
    label: "Employee",
    value: "Employee"
  },
  {
    label: "Transport",
    value: "Transport"
  }
]
