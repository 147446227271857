import React from 'react';
import { Button, Card } from 'reactstrap';
import './DisplayEmail.scss';
import { FaPlus } from 'react-icons/fa';

const EmailContent = ({ content,  maxHeight ="500px",  onAdd}) => (
    <div className='mt-3'>
        <div>Email Content :</div>
        <Card className="p-3 border" style={{ postion: "relative", maxHeight, overflow: "scroll" }}>
            <Button color="primary py-1 d-flex align-items-center email-add-conent-btn " onClick={onAdd}>
                <FaPlus className='me-2' /> Content
            </Button>
            <div className="email-content-container" dangerouslySetInnerHTML={{ __html: content }} />
        </Card>
    </div>
);

export default EmailContent;
