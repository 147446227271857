import { makeAutoObservable } from 'mobx';
import { doGET } from '../../../util/HttpUtil';

class EmailService {
    emails = [];
    pagination = {
        page: 1,
        rowsPerPage: 10,
        total: 0,
    };
    selectedEmail = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchEmails(filterUrl) {
        try {
            let url = `/api/email/grid?rows=${this.pagination.rowsPerPage}&page=${this.pagination.page}&inbound=${filterUrl.inbound}`;
            if (filterUrl?.email) {
                url += `&to=in[${filterUrl.email}]`;
            }
    
            const response = await doGET(url);
            this.emails = response.data.rows;
    
            if (filterUrl?.email) {
                this.pagination.total = this.emails.length;
            } else if (this.pagination.page === 1) {
                this.pagination.total = response.data.total;
            }
        } catch (error) {
            console.error('Error fetching emails:', error);
        }
    }
    async fetchEmailById(emailId) {
        try {
            const response = await doGET(`/api/email/grid?_id=${emailId}`);
            if (response.data.rows.length > 0) {
                this.selectedEmail = response.data.rows[0];
                return response.data.rows[0];
            }
            return null;
        } catch (error) {
            console.error('Error fetching email by ID:', error);
            return null;
        }
    }

    setPage({page, rowsPerPage,inbound}) {
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.fetchEmails({inbound});
    }
}

export default new EmailService();
