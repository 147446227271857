export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {

    let url = `/api/citygroups/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;

    return url + `&page=${page}`;

  },
  get: (id) => `/api/citygroups/${id}/detail`,
  saveNew: `/api/citygroups/save`,
  edit: (id) => `/api/citygroups/${id}/edit`,
  delete: (id) => `/api/citygroups/${id}/delete`,
  getCities: (country) => `api/city/select/data?country=${country}`,
};

export const STRUCTURE = [
  {
    label: "Name",
    description: "description...",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Country",
    description: "description...",
    filterLabel: "Country",
    filterName: "country",
    type: "text",
  },
];