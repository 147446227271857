export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/company/grid?`;
    url += `rows=${sizePerPage ?? -1}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/company/${id}/detail`,
  save: `/api/company/create`,
  // delete: (id) => `/api/company/${id}/delete`,
  delete: (id) => `/api/company/${id}/delete`,
  bulkDelete: (id) => `/api/company/delete`,
  update: `/api/company/update`,

};

export const STRUCTURE = [
  {
    label: "Brand Name",
    filterLabel: "Brand Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Full Name",
    filterLabel: "Full Name",
    filterName: "fullName",
    type: "text",
  },
  {
    label: "Currency",
    filterLabel: "Company currency",
    filterName: "currency",
    type: "currencySelect",
  },
  {
    label: "TimeZone",
    filterLabel: "Company TimeZone",
    filterName: "timezone",
    type: "timezoneSelect",
  },
];
