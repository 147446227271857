import React, { Children, useEffect, useMemo, useState } from 'react';
import {
    DropdownToggle,
    DropdownMenu,
    UncontrolledDropdown,
    DropdownItem,
} from 'reactstrap';



function CustomDropdown({ value = "", data = [], label = "",className="",   direction = "up",onChange=()=>{} }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        
            <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle} >
                <DropdownToggle
                    caret
                    outline={true}
                    color="#ced4da"
                    className={`mx-4 d-flex align-items-center justify-content-between ${className}`}
                    style={{ border: '1px solid #ced4da', backgroundColor: '#fff' }}
                >

                    {value ? value : label}
                </DropdownToggle>

                <DropdownMenu className='shadow'>
                    {data?.map((v, i) => (
                        <DropdownItem key={i} onClick={()=>onChange(v)}>{v}</DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
       
    );
}



export default CustomDropdown;
