import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Col } from "reactstrap";
import {
  CardThreeBounce,
  EditorField,
  CompanySelect,
  LanguageSelect,
} from "../../components";

import { I18nContext } from "../../store/context/i18nContext";
import StaticContentService from "./StaticContentService";

import { UserContext } from "../../store/context/UserContext";
import ContentSelect from "../../components/Dropdowns/ContentTypeSelect/contentSelectAll";

const EditStaticContent = (props) => {
  const { t } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const { id, data, setData, hasErr, setHasErr } = props;
  const [freshData, setFreshData] = useState({});
  const { userContent } = useContext(UserContext);

  const fetch = async () => {
    console.log(userContent, "webContetnt");
    let staticContent = {
      contentType: "",
      businessUnit_id: "",
      content: "",
      lang: "",
    };
    if (id) staticContent = await StaticContentService.get(id);
    setFreshData(staticContent);
    setData(staticContent);
    setHasErr(false);
    setLoading(false);
  };
  const stringToJSON = (v) => {
    try {
      const val = JSON.parse(v);
      return val;
    } catch (error) {
      return v;
    }
    return v;
  };
  useEffect(() => {
    setLoading(true);
    fetch();
    setHasErr(false);
  }, [id]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(freshData)) {
      props?.onChange(true);
    } else {
      props?.onChange(false);
    }
  }, [data]);

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      {(id && !loading) || !id ? (
        <Form className="p-3">
          <Row className="mt-3 mb-5">
            <Col className="col-sm-4 col-12 mt-3" style={{ zIndex: '4' }}>
              <ContentSelect
                value={data?.contentType}
                onChange={(v) => setData({ ...data, contentType: v?.value })}
                error={
                  data?.contentType?.length === 0
                    ? "Please enter content Type"
                    : ""
                }
                label={"Content Type"}
              />
            </Col>
            <Col className="col-sm-4 col-12 mt-3" style={{ zIndex: '3' }}>
              <CompanySelect
                corpType="self"
                value={data?.businessUnit_id}
                label={"Business Unit"}
                onChange={(v) => setData({ ...data, businessUnit_id: v.value })}
              />
            </Col>
            <Col className="col-sm-4 col-12 mt-3" style={{ zIndex: '2' }}>
              <LanguageSelect
                value={data?.lang}
                label={"Language"}
                name
                error={
                  data?.lang?.length === 0 ? "Please enter content Type" : ""
                }
                onChange={(v) => setData({ ...data, lang: v?.value })}
                showErr={hasErr}

              />
            </Col>
          </Row>

          <Row className="mt-3">
            <EditorField
              value={stringToJSON(data.content)}
              error={
                data?.content?.length === 0 ? "Please enter content Type" : ""
              }
              onChange={(v) =>
                setData({
                  ...data,
                  content: typeof v === "object" ? JSON.stringify(v) : v,
                })
              }
              showErr={hasErr}

              type="text"
            />

          </Row>
        </Form>
      ) : (
        <CardThreeBounce />
      )}
    </React.Fragment>
  );
};

export default observer(EditStaticContent);
