export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/employee/grid?isDriver=true&rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + `&page=${page}`;
  },
  get: (id) => `/api/driver/${id}/detail`,
  saveNew: `/api/driver/new`,
  delete: (id) => `/api/driver/${id}/delete`,
  picUpload: (id) => `/api/driver/${id}/picture/save`,
  save: `/api/driver/create`,
  download: (filterUrl, template_id) => {
    let url = `/api/driver/download?template_id=${template_id}&`;
    if (filterUrl) url += filterUrl;
    return url;
  },
  edit: (driverId) => `/api/driver/update`,
  picDelete: (id, time) => `/api/driver/${time}/${id}/delete/picture`,
  changeProfilePic: (id, time) => `/api/driver/${id}/profile/picture/${time}`,
  assignDriver: (vehicleID, driverID) =>
    `/api/vehicle/${vehicleID}/driver/${driverID}/assign`,
  policeVerifyPic: (id) => `/api/driver/${id}/policePicture/save`,
};

export const STRUCTURE = [
  {
    label: "Name",
    description: "description...",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Country Code",
    description: "description...",
    filterLabel: "Country Code",
    filterName: "countryCode",
    type: "countrySelect",
    isd: true,
  },
  {
    label: "Phone No.",
    description: "description...",
    filterLabel: "Phone No.",
    filterName: "phone",
    type: "text",
  },
  {
    label: "Owner",
    description: "description...",
    filterLabel: "Owner",
    filterName: "driverOf",
    type: "vendorSelect",
  },
  {
    label: "Zone",
    description: "description...",
    filterLabel: "Zone",
    filterName: "zone_id",
    type: "zoneSelect",
  },
  {
    label: "On Duty",
    description: "description...",
    filterLabel: "On Duty",
    type: "checkBox",
    filterName: "onDuty",
  },
  {
    label: "Power Saver Mode On",
    description: "description...",
    filterLabel: "Power Saver Mode On",
    type: "checkBox",
    filterName: "powerSaver",
  },
];
