import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { ModalComponent, SearchButton } from "../../../components/index";
import Client from "../../../pages/Clients/Client";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { GroupedSelectField } from "../../SelectField/GroupedSelectField";
import ClientSelectService from "./ClientCompanyService";
import ClientCompanyGrid from "../../../pages/ClientCompanyGrid/ClientCompanyGrid";
import useDebounce from "../../../util/Debounce";

const ClientCompany = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  asLabel,
  plain,
  outline,
  filterOption
}) => {
  const [clients, setClients] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const fetchClients = async () => {
    try {
      await ClientSelectService.fetch();
      setClients(toJS(ClientSelectService.clients));
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    setClients(toJS(ClientSelectService.clients));
  }, [ClientSelectService.clients]);

  const fetchAndUpdateClient = async (id) => {
    try {
      // const client = clients.find(client => client._id === id)
      const client = clients?.some(obj => obj?.options?.some(option => option._id === id));
      if (!client) {
        await ClientSelectService.get(id);
        setClients(toJS(ClientSelectService.clients));
      }

    } catch (error) {

    }
  }

  useEffect(() => {
    if (value && clients.length) {
      fetchAndUpdateClient(value)
    }
  }, [clients, value]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectClient = (selectedValue) => {
    let selectedClient = toJS(selectedValue)
    const client = clients.find(client => client._id === selectedClient?._id)
    if (!client) {
      ClientSelectService.pushNewData(selectedClient);
      setClients(toJS(ClientSelectService.clients));
    }
    onChange({ ...selectedValue, value: selectedValue?._id });
    closeModal();
  };

  const fetchMoreData = () => {
    const page = ClientSelectService.page + 1;
    const rowsPerPage = ClientSelectService.rowsPerPage;
    const total = ClientSelectService.total;
    if (page > 0 && page <= (total % rowsPerPage === 0 ? (total / rowsPerPage) : (total / rowsPerPage) + 1)) {
      ClientSelectService.onPaginationChange(page, rowsPerPage);
    }
  }

  const fetchDataOnSearch = useDebounce((value) => {
    const filterUrl = value ? `clientName=${value}` : '';
    ClientSelectService.fetch(filterUrl);
  }, [500]
  );

  const renderSelectField = ({ disabled }) => (
    <GroupedSelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      // bottomBarChildren={() => (
      //   <BottomBarButton onClick={openModal} title={t("Manage Clients")} />
      // )}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={clients}
      required={required}
      value={value}
      grouped
      label={label || (!isDisabled && t("Client Company"))}
      error={!value ? t(`Please Select ${label || "Client"}`) : ""}
      showErr={showErr}
      plain={plain}
      outline={outline}
      inline={inline}
      filterOption={filterOption}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
      onInputChange={fetchDataOnSearch}
      onMenuScrollToBottom={fetchMoreData}
    />
  );


  return (
    <>
      {asLabel ? (<div>{clients?.find((client) => client?.value == value)?.label ?? <div style={{ color: "grey" }}>No record Found</div>}</div>) :
        <>

          {modal && (
            <ModalComponent
              position={"top"}
              size={"medium"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Client Companies")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <ClientCompanyGrid
                  value={value}
                  insidePane={true}
                  onSelect={selectClient}
                  client_id
                />

              </ModalComponent.Body>

            </ModalComponent>
          )}



          {clients.length > 0
            ? renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      }
    </>
  );
};

export default observer(ClientCompany);
