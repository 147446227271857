
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { EditLink, Layout, timestampToyyyy_mm_ddThh_mm } from "../../components";
import { Row, Col } from "react-bootstrap";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import UserMasterService from "./UserMasterService";
import EditUserMaster from "./EditUserMaster";
import { STRUCTURE } from "./UserMasterConstant";


const UserMaster = (props) => {
  const { t } = useContext(I18nContext);
  const { id } = useParams();
  const { showError } = useContext(DialogContext);
  const [data, setData] = useState({});
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(id);
  const [loading, setLoading] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);
  const [hasErr, setHasErr] = useState(false);

  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await UserMasterService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  let navigate = useNavigate();
  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    setShowDetailPage(window.location.pathname === "/userMaster/edit" || id);
  }, [id]);
  return (
    <>
      <Layout
        showDetailPage={showDetailPage}
        backDetailPage={() => {
          setShowDetailPage(false);
          if (!props?.insidePane) navigate("/userMaster");
          setSelected_id(false);
        }}
         
        title={t("User Master ")}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        page={UserMasterService.page}
        rows={UserMasterService.rows}
        total={UserMasterService.totalRecords}
        onPageChange={async (page, rows) => {
          await UserMasterService.onPaginationChange(page, rows);
        }}
      >
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>{t(" Phone no.")}</Th>
              <Th>{t("Gender")}</Th>
              <Th>{t("Make / Model")}</Th>
              <Th>{t("Carrier")}</Th>
              <Th>{t("System Version")}</Th>
              <Th>{t("Version")}</Th>
              <Th>{t("Country")}</Th>
              <Th>{t("State")}</Th>
              <Th>{t("City")}</Th>
              <Th>{t("Last Login")}</Th>
              <Th className="float-end">{t("Action")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>
        <Layout.TbodyFull>
          <Tbody>
            {UserMasterService.records?.map((user) => (
              <Tr
                key={user?._id}
              >
                <Td>+{user?.countryCode} - {user?.phone}</Td>
                <Td> {user?.gender === 1 ? "Female" : "Male"}</Td>
                <Td>{(user?.deviceInfo?.model && user?.deviceInfo?.make) ? `${user?.deviceInfo?.make} / ${user?.deviceInfo?.model}` : '-'}</Td>
                <Td>{user?.deviceInfo?.carrier ? user?.deviceInfo?.carrier : '-'}</Td>
                <Td>{user?.deviceInfo?.systemVersion ? user?.deviceInfo?.systemVersion : '-'}</Td>
                <Td>{user?.deviceInfo?.version ? user?.deviceInfo?.version : '-'} </Td>
                <Td>{user?.address.country ? user?.address.country : '-'}</Td>
                <Td>{user?.address.state ? user?.address.state : '-'}</Td>
                <Td>{user?.address.city ? user?.address.city : '-'}</Td>
                <Td>{user?.lastLogin ? timestampToyyyy_mm_ddThh_mm(user?.lastLogin) : '-'}</Td>
                <Td>
                  <EditLink
                    onClick={() => {
                      if (!props?.insidePane)
                        navigate(`/userMaster/edit/${user?._id}`);
                      setSelected_id(user?._id);
                      setShowDetailPage(true);
                    }}
                  /></Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
        <Layout.DetailPageTitle>
          <h5 style={{ paddingLeft: "9px" }}>User Details</h5>
        </Layout.DetailPageTitle>
        <Layout.DetailPageBody>
          <EditUserMaster
            insidePane={props?.insidePane}
            onChange={(v) => setIsEditDataChanged(v)}
            id={selected_id}
            data={data}
            hasErr={hasErr}
            setHasErr={setHasErr}
            setData={setData}
            fetchData={fetchData}
          />
        </Layout.DetailPageBody>
        <Layout.DetailPageFooter>
          <Row>
            <Col>
              <div
                className="d-flex justify-content-end mt-1"
                style={{ marginTop: "-2.5rem" }}
              >
              </div>
            </Col>
          </Row>
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(UserMaster);

