
import { useCallback, useContext, useMemo, useState } from "react";
import {
  Button, 
  Col,
  Row,
} from "reactstrap";
import { InputField, ModalComponent } from "../../components";
import { I18nContext } from "../../store/context/i18nContext";
import { isArrayNonEmpty } from "../../util/Util";
import InvoiceItems from "./InvoiceItems"; 
import "./style.css"; 
import { isSameObject } from "../../helpers/general";
import Booking from "../Booking/Booking";

const InvoiceExtn = ({
  invoiceExtn,
  onChange,
  tax,
  itemStatuses,
  clientId,
  handleSave,
  invoiceId,
  handleInvoiceBillingCalculateAfterTripUpdate,
  savedStatus
}) => {
  const { t } = useContext(I18nContext);
  const [showBookingGrid,setShowBookingGrid]=useState(false);

  const isTripItemExist=useMemo(()=>{
    return invoiceExtn?.items?.find(item=>item?.invoiceTrip?.tripId || !item?.manual)
  },[invoiceExtn?.items])
 
  
  const updateExtension = useCallback(
    (fieldName, newValue) => {
      const invExtn = { ...invoiceExtn } || {
        properties: [{}],
        items: [
          {
            fee: {
              feeGroups: [
                {
                  name: `Group ${invoiceExtn?.items?.length ?? 1}`,
                  charges: [{}],
                  discounts: [{}],
                  taxItem: {
                    taxes: [{}],
                  },
                },
              ],
            },
            invoiceTrip: {},
            manual:true
          },
        ],
      };
      if (fieldName && newValue !== invExtn[fieldName]) {
        invExtn[fieldName] = newValue;
        onChange(invExtn);
      }
    },
    [invoiceExtn, onChange]
  );

  const updateItems = useCallback(
    (items) => {
      if (isArrayNonEmpty(items)) {
        const invExtn = { ...invoiceExtn } || {};
        invExtn["items"] = items; 
          onChange(invExtn);
      }
    },
    [invoiceExtn, onChange]
  );

  const handleAddItem = useCallback(() => {
    const itemArray = Array.isArray(invoiceExtn?.items)
      ? [...invoiceExtn?.items]
      : [];
    const itemArrayLength = itemArray?.length;
    itemArray.push({
      manual : true,
      fee: {
        feeGroups: [
          {
            name: `Group ${itemArrayLength + 1}`,
            charges: [{
              name: "",
              unitCost: 0,
              amount: 0,
              units: 0
            }],
            disounts: [{}],
            taxItem: {
              taxes: [{}]
            },
            subTotal: 0,
            totalDiscounts: 0,
            total: 0,
            totalTaxes: 0
          },
        ],
        subTotal: 0,
        totalDiscounts: 0,
        totalTaxes: 0,
        total: 0
      },
      invoiceTrip: {},
    });
    // updateItems(itemArray);
    onChange(prev=>({
      ...prev,
      invoiceExtn:{
        ...prev?.invoiceExtn,
        items:itemArray
      }
    }))
  }, [invoiceExtn?.items, onChange]);
 
  return (
    <>
      <Row className="">

        <InvoiceItems
           items={invoiceExtn?.items} 
           itemStatuses={itemStatuses}
           savedStatus={savedStatus}
          onChange={onChange}
          tax={tax}
          handleSave={handleSave}
          invoiceId={invoiceId}
          handleInvoiceBillingCalculateAfterTripUpdate={handleInvoiceBillingCalculateAfterTripUpdate}
        />
      </Row>

      <div className="d-flex gap-2" style={{ width: '45.5%' }}>
            {!isTripItemExist?<Button
              color="light"
              onClick={() => {
                handleAddItem()
              }}
              className="d-flex align-items-center"
            >
              <i className="bx color-blue bxs-plus-circle me-2"></i>
              Add Item
            </Button>:null}
            {/* <Button
              color="light"
              onClick={() => setShowBookingGrid(prev=>!prev)}
              className="d-flex align-items-center"
            >
              <i className="bx color-blue bxs-plus-circle me-2"></i>
              Add Trip Item
            </Button> */}
      </div>

      <ModalComponent
        position={"top"}
        size={"xlarge"}
        isOpen={showBookingGrid}
        onToggle={() => setShowBookingGrid(false)}
      >

        <ModalComponent.Body>
          <Booking
          insidePane={true}
          clientId={clientId}
          clientInvoice_id={true}
          />
        </ModalComponent.Body>
      </ModalComponent>

   
    </>
  );
};

export default InvoiceExtn;
