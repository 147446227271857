/* eslint-disable no-unused-vars */
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu, Row, Col, Button } from "reactstrap";
import {
  EditLink,
  Layout,
  DeleteLink,
} from "../../components";

import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import EditDriver from "./EditDriver";
import DriverService from "./DriverService";
import { SaveButton, DeleteButton } from "../../components";
import DownloadByTemplate from "../../components/DownloadByTemplate/DownloadByTemplate";

import BulkUpload from "../../components/BulkUpload/BulkUpload";
import ReactTable from "react-table";
import "react-table/react-table.css";

// Import React Table HOC Fixed columns
import withFixedColumns from "react-table-hoc-fixed-columns";

import DataGrid from "../../components/DataGrid/DataGrid";
import CustomFieldService from "../CustomField/CustomFieldService";

const Driver = (props) => {
  const { t } = useContext(I18nContext);
  const { id } = useParams();
  const [data, setData] = useState({});
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(
    props?.insidePane ? false : id
  );
  const [loading, setLoading] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState([]);
  const [showDownload, setShowDownload] = useState(false);
  const [filterVals, setFilterVals] = useState({});

  const [driverId, setdriverId] = useState();
  const [uploadPopup, setUploadPopup] = useState(false);
  const ReactTableFixedColumns = withFixedColumns(ReactTable);
  const tableRef = useRef(null);

  useEffect(() => {
    CustomFieldService.fetch();
  }, []);

  let columns = [
    {
      Header: "Driver Name",
      accessor: "name",
      show: true,
    },
    {
      Header: "Phone No.",
      accessor: "phoneNo.countryCode",
      show: true,
    },
    {
      Header: "Owner",
      accessor: "owner_id",
      fixed: "left",

      show: true,
    },
    {
      Header: "On Duty",
      accessor: "onDuty",
      show: true,
    },

    {
      Header: "Last Login",
      accessor: "lastLogin",
      // width: 300,
      show: true,
    },
    {
      Header: "Status",
      accessor: "status",
      show: true,
    },
    {
      Header: "complianceStatus",
      accessor: "complianceStatus",
      show: true,
    },

    {
      Header: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
    },
  ];

  const fetchData = async (filterUrl) => {
    setFilterVals(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await DriverService.fetch({ filterUrl });
      await DriverService.getStructure();
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log("============", DriverService?.records);

  useEffect(() => {
    console.log(data);
  }, [data]);

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    if (!props?.insidePane)
      setShowDetailPage(window.location.pathname === "/driver/edit" || id);
  }, [id]);

  const onRowClick = (id) => {
    if (!props?.insidePane) navigate(`/driver/edit/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };

  const bulkDeleteHandler = async (e) => {
    e.stopPropagation();
    if (!selectedDriver?.length) {
      showMessage("Select Drivers to delete.", "No Driver Selected");
      return;
    }
    if (
      await showConfirm({
        title: "Delete Drivers ?",
        description: "This is an unrecoverable operation.",
        className: "col-6",
      })
    ) {
      try {
        await DriverService.bulkDelete({
          driver_ids: selectedDriver.toString(),
        });

        navigate(`/driver`);
      } catch (error) {
        showError(error);
      }
    }
    setSelectedDriver([]);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete Driver ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        const response = await DriverService.delete(id);
        navigate("/driver");
        setShowDetailPage(false);
        showMessage("Deleted");
      } catch (e) {
        showError(e);
      }
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    console.log(data);

    try {
      if (id) {
        await DriverService.edit(data, id);
        showMessage("Driver Updated successfully");
      } else {
        const response = await DriverService.save(data);
        DriverService.records.push(data);
        showMessage("Driver saved successfully");
        // setShowDetailPage(false);
        if (!props?.insidePane) {
          navigate(`/driver/edit/${response}`);
        } else navigate(`/driver`);
      }
      fetchData();
    } catch (error) {
      console.log(error);
      showError(error);
    }
  };

  const renderCell = (dataObj, field) => {
    if (field === "phoneNo.countryCode") {
      return <span>{dataObj.phoneNo.phone}</span>;
    } else if (field === "onDuty") {
      return <span>{dataObj.onDuty === true ? "true" : "false"}</span>;
    } else if (field === "status") {
      return (
        <span>
          {dataObj.status === 3
            ? "blocked"
            : dataObj.status === 2
              ? "Inactive"
              : "Blocked"}
        </span>
      );
    } else if (field === "complianceStatus") {
      return (
        <span>
          {dataObj.complianceStatus === 3
            ? "Due"
            : dataObj.complianceStatus === 2
              ? "Not Compliant"
              : "Compliant"}
        </span>
      );
    } else if (field === "color") {
      return <span style={{ color: dataObj[field] }}>{dataObj[field]}</span>;
    }
    else {
      return dataObj[field];
    }
  };

  const toggleDownload = () => {
    setShowDownload(!showDownload);
  };
  return (
    <>
      {showDownload ? (
        <DownloadByTemplate
          filter={filterVals}
          download={DriverService.downloadByTemplate}
          kind={5}
          setShowDownload={setShowDownload}
          showDownload={showDownload}
          filterStructure={DriverService.STRUCTURE}
          onApplyFilter={DriverService.setDownloadFilter}
        />
      ) : null}
      {uploadPopup ? (
        <BulkUpload
          kind={5}
          type={2}
          showBulkUpload={uploadPopup}
          setShowBulkUpload={setUploadPopup}
          fetchData={fetchData}
          title={"Drivers"}
        />
      ) : null}

      <Layout
        showDetailPage={showDetailPage}
        backDetailPage={() => {
          setShowDetailPage(false);
          if (!props?.insidePane) navigate("/driver");
          setSelected_id(false);
          setData({});
        }}
         
        title={t("Drivers")}
        filterStructure={DriverService.STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!props?.insidePane) navigate(`/driver/edit`);
          setShowDetailPage(true);
          setSelected_id(null);
        }}
        page={DriverService.page}
        rows={DriverService.rows}
        total={DriverService.totalRecords}
        totalPage={DriverService.totalPage}
        insidePane={props?.insidePane}
        columns={DriverService?.columns}
        records={DriverService?.records}
        onPageChange={async (page, rows) => {
          await DriverService.onPaginationChange(page, rows);
        }}
        increasePage={() => {
          DriverService.page++;
        }}
        fetch={DriverService.fetch}
        handleToggleVisibleColumn={DriverService?.handleToggleVisibleColumn}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              {!showDetailPage && (
                <>
                  <DropdownItem onClick={bulkDeleteHandler}>
                    Bulk Delete
                  </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={() => setUploadPopup(true)}>
                    {t("Upload")}
                  </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={toggleDownload}> Download </DropdownItem>
                </>
              )}
            </DropdownMenu>
            <DropdownMenu>
              {!showDetailPage && (
                <>
                  <DropdownItem onClick={bulkDeleteHandler}>
                    Bulk Delete
                  </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={() => setUploadPopup(true)}>
                    {t("Upload")}
                  </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={toggleDownload}> Download </DropdownItem>
                </>
              )}
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={DriverService.records}
            onEndReached={() => {
              if (DriverService?.totalPage >= DriverService?.page) {
                DriverService.page++;
                DriverService.fetch({});
              }
            }}
            onSelectChange={(data) => {
              console.log(data);
            }}
            total={DriverService.totalRecords}
            uiPreference="driver.grid"
            renderLastCol={(driver) => {
              return (
                <>
                  <EditLink
                    onClick={() => {
                      if (!props?.insidePane)
                        navigate(`/driver/edit/${driver?._id}`);
                      setSelected_id(driver?._id);
                      setShowDetailPage(true);
                      setIsEditDataChanged(false);
                    }}
                  />

                  <DeleteLink
                    onClick={(event) => {
                      onDelete(event, driver?._id);
                    }}
                  />
                </>
              );
            }}
            renderCell={renderCell}
            headers={columns}
          />
        </Layout.Table>
        <Layout.TheadFull>
          <div></div>
        </Layout.TheadFull>
        <Layout.TbodyFull>
          <div></div>
        </Layout.TbodyFull>

        <Layout.DetailPageTitle>
          <h5 style={{ paddingLeft: "9px" }}>
            {data?._id ? "Edit Driver" : "Add Driver"}
          </h5>
        </Layout.DetailPageTitle>
        <Layout.DetailPageBody>
          <EditDriver
            insidePane={props?.insidePane}
            parent={props?.parent}
            onChange={(v) => setIsEditDataChanged(v)}
            id={selected_id}
            // hasErr={hasErr}
            // setHasErr={setHasErr}
            data={data}
            setData={setData}
            fetchData={fetchData}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          <Row>
            <Col>
              <div
                className="d-flex justify-content-end mt-1"
                style={{ marginTop: "-2.5rem" }}
              >
                {data?._id && (
                  <DeleteButton onClick={(e) => onDelete(e, data?._id)} />
                )}
                <SaveButton onClick={onSave} />
              </div>
            </Col>
          </Row>
        </Layout.DetailPageFooter>
      </Layout>
      {/* <DataLabel kind={"driver"} value={"5aa777d87a69c903adb2aefb"} /> */}
    </>
  );
};

export default observer(Driver);
