
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { InputField } from "../../components";
import { I18nContext } from "../../store/context/i18nContext";
import './style.css';
import RemoveLink from "../../components/Buttons/RemoveLink";
const _ = require('lodash');
const newItem = {
    title: "",
    notes: "",
    unitCost: 0.00,
    units: 0.00,
    subtotal: 0.00,
    taxAmount: 0.00,
    total: 0.00,
    tax: 0.00,
    isAbs: false,
    taxPercent: 0.00,
}

const Items = ({ data, items = [newItem], onChange, addBooking, addExpense }) => {

    const [addModal, setAddModal] = useState(false)
    const { t } = useContext(I18nContext);


    const addItems = () => {
        onChange([...items, newItem]);
    };

    const deleteItems = (index) => {
        const updatedData = items.filter((_, i) => i !== index);
        onChange(updatedData);
    };

    const calculateTotalAmount = (items) => {
        return items.reduce((total, item) => total + parseFloat(item.total || 0), 0);
    };

    const gettotalTax = (accounts) => accounts?.reduce((totaltax, curr) => totaltax + curr.taxAmount, 0);
    const getSubtotalAmount = (items) => {
        return items.reduce((subtotalAmount, item) => subtotalAmount + parseFloat(item.cost || 0), 0);
    };

    const updateItem = ({ index, key, value }) => {
        const updatedData = [...items];
        updatedData[index] = { ...updatedData[index], [key]: value };
        if (key === "units" || key === "unitCost" || key === "taxAmount") {
            updatedData[index] = { ...updatedData[index], cost: updatedData[index].units * updatedData[index].unitCost, total: (updatedData[index].units * updatedData[index].unitCost) + updatedData[index].taxAmount, taxPercent: (updatedData[index].taxAmount ? (updatedData[index].taxAmount) / (updatedData[index].cost) : 0.00) * 100 };
        }
        onChange(updatedData);
    };

    return (

        <div>
            {/* {addModal !== false &&
                <Modal onChange={() => setAddModal(false)}>
                    {addModal == "expense" &&
                        <Expense
                            // value={value}
                            insidePane={true}
                            multiMode={false}
                        // onSelect={selectSupplier}
                        />}
                </Modal>
            } */}
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    {addBooking && <div onClick={() => { }} className="d-flex align-items-center me-4">
                        <i className="bx bxs-plus-circle font-size-20 me-2"></i>
                        Add Booking
                    </div>}
                    {addExpense &&
                        <div onClick={() => { }} className="d-flex align-items-center">
                            <i className="bx bxs-plus-circle font-size-20 me-2"></i>
                            Add Expense
                        </div>
                    }
                </div>
            </div>
            <div className="table-responsive">
                <table className="table-bordered expense">
                    <thead>
                        <tr>
                            <th>{t("Title")}</th>
                            <th>{t("Notes")}</th>
                            <th >
                                <div className="d-flex justify-content-between">
                                    <div className="w-50">{t("Cost per unit")}</div>
                                    <div className="w-50">{t("Units")}</div>
                                </div>
                            </th>
                            <th>{t("Tax")}</th>
                            <th>{t("Total")} Amount</th>
                        </tr>
                    </thead>
                    {items.map((account, index) => (
                        <tbody className="expense-table-hover position-relative">
                            <tr key={index}>
                                <td>
                                    <InputField
                                        noLabel={false}
                                        // isDisabled={true}
                                        placeholder={t("Title")}
                                        value={account.title}
                                        onChange={(v) => {
                                            updateItem({ value: v, index, key: "title" })
                                        }}
                                        type="text"
                                        style={{ minWidth: '150px' }}

                                    />
                                </td>
                                <td className="test">
                                    <InputField
                                        placeholder={t("Items notes ")}
                                        value={account.notes}
                                        onChange={(v) => {
                                            updateItem({ value: v, index, key: "notes" })
                                        }}
                                        noLabel={false}
                                        type="text"
                                        style={{ minWidth: '150px' }}

                                    />
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <div className=" d-flex align-items-center w-50">
                                            <InputField
                                                // placeholder={t("Unit Cost")}
                                                style={{ minWidth: '70px' }}

                                                value={account.unitCost === 0 ? "0.00" : account.unitCost}
                                                onChange={(v) => {
                                                    updateItem({ value: Number(v), index, key: "unitCost" })
                                                }}
                                                noLabel={false}
                                                type="number"
                                            />
                                            <div className="mt-2 ms-2 me-2" style={{ fontSize: "15px" }}>
                                                <i
                                                    onClick={() => deleteItems(index)}
                                                    className="dripicons-cross p-0"
                                                ></i>
                                            </div>
                                        </div>
                                        <div>
                                            <InputField
                                                // placeholder={t("Units")}
                                                value={account.units === 0 ? "0.00" : account.units}
                                                onChange={(v) => {
                                                    updateItem({ value: parseInt(v), index, key: "units" })
                                                }}
                                                type="number"
                                                style={{ minWidth: '50px' }}
                                                noLabel={false}
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <InputField
                                        // isDisabled={true}
                                        // placeholder={t("Tax Amount")}
                                        value={account?.taxAmount === 0 ? "0.00" : account?.taxAmount}
                                        style={{ minWidth: '70px' }}
                                        onChange={(v) => {
                                            updateItem({ value: Number(v), index, key: "taxAmount" });
                                        }}
                                        noLabel={false}
                                        type="number"
                                    />
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div style={{ width: "80px", color: "black", fontSize: "15px" }}>
                                            {data?.currency} {account?.total !== undefined ? _.round(account.total, 2).toFixed(2) : null}
                                        </div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <RemoveLink onClick={() => deleteItems(index)}/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>

            <Row className="border-top border-bottom border-dark">
                <Col className="col-12 col-sm-6 col-lg-2 pt-2">


                    {/* <Col className="col-2 pt-2"> */}
                    <Button color="light" onClick={addItems} className="d-flex align-items-center">
                        <i className="bx bx-plus  font-size-20 me-2"></i>
                        {t("Add item")}
                    </Button>
                </Col>
                <Col className="col-12 col-sm-6 col-lg-10 font-size-13">
                    <div className=" row  my-1 col-12">
                        <span className="d-flex justify-content-end col-6 col-sm-8 col-lg-11">{t("SubTotal")}: </span>
                        <div className="col-6 col-sm-4 col-lg-1 ">
                            {data?.currency} {(_.round(getSubtotalAmount(items) ?? 0, 2)).toFixed(2)}
                        </div>
                    </div>
                    <div className=" row  my-1 col-12  ">
                        <span className="d-flex justify-content-end col-6 col-sm-8 col-lg-11">{t("Total Taxes")}:</span>
                        <div className="col-6 col-sm-4 col-lg-1 ">
                            {data?.currency}   {_.round(gettotalTax(items), 2).toFixed(2)}
                        </div>
                    </div>
                    <div className=" row  my-1 col-12 d-flex justify-content-end  align-items-center " style={{ fontWeight: 'bold' }}>
                        <span className="    d-flex justify-content-end col-6 col-sm-8 col-lg-11">{t("Total Amount:")}</span>
                        <div className="col-6 col-sm-4 col-lg-1">
                            {data?.currency}  {_.round(calculateTotalAmount(items), 2).toFixed(2)}
                        </div>
                    </div>

                </Col>

            </Row>

        </div>
    );
};

export default observer(Items);

