import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu, Alert } from "reactstrap";
import { Button } from 'react-bootstrap'
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditBids from "./EditBid";
import { STRUCTURE } from "./BidConstant";
import { GridColumns } from "./BidGridColumns";
import Bidservice from "./BidService";
import { UserContext } from "../../store/context/UserContext";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import moment from "moment";

const Bids = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/bid/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)
  const [onAlert, setOnAlert] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await Bidservice.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])


  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    // if (!detailData?.address?.name) {
    //   setHasErr(true)
    //   showError(t("Please enter all required fields"));
    //   return;
    // }
    setLoading(true);
    try {
      if (editID) {
        await Bidservice.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Bid updated successfully."));
      } else {
        const bid = await Bidservice.save(detailData);
        setLoading(false);
        showMessage(t("Bid saved successfully."));
        if (!insidePane) navigate(`/bid/edit/${bid}`);
      }
      fetchData(filterURL);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await Bidservice.delete(id);
        setLoading(false);
        showMessage("Bid Deleted SuccessFully", "Deleted");
        navigate("/bid");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await Bidservice.bulkDelete(selectedIDs);
        showMessage(t("Deleted"), "Deleted Successfully");
        setShowDetailPage(false);

      } catch (e) {
        showError(e);
      }
    }
  };

  const toggleData = {
    module: [
      { label: t("RFP"), value: "forRFP" },
      { label: t("My RFP"), value: "myrfp" },
      { label: t("Bids"), value: "bids" },
      // { label: t("Suppliers"), value: "forSupplier" },
    ],
    toggleValue: "bids"
  }


  const renderLastCol = useCallback((bid) => {
    return (
      <>
        <EditLink
          onClick={() => {
            if (!insidePane)
              navigate(`/bid/edit/${bid?._id}`);
            setEditID(bid?._id);
            setShowDetailPage(true);

          }}
        />

        {userContent?.rights?.includes(1503) ?

          <DeleteLink
            onClick={(event) => onDelete(event, bid?._id)}
          />
          : null}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(bid?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode])

  const markCompletedFunc = async () => {
    try {
      await SetupProgressService?.edit("bid");
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch()
    }
  }
    , [SetupProgressService?.records])

  return (
    <>
      <Layout
        large
        toggleData={toggleData}
        compact={true}
        showToggle
        onToggle={(selectedValue) => {
          if (selectedValue === "forRFP") {
            navigate(`/rfp`);
          } else if (selectedValue === "bids") {
            navigate(`/bid`);
          } else if (selectedValue === "forSupplier") {
            navigate(`/demo`);

          } else if (selectedValue === "myrfp") {
            navigate(`/rfp`);
          }
        }}
        hideAdd={!userContent?.rights?.includes(1501)}
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/bid");
          setEditID(null);
        }}
         
        // title={t("Bids")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/bid/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={Bidservice.page}
        rows={Bidservice.rows}
        total={Bidservice.total}
        fetch={Bidservice.fetch}
      >
        <Layout.OnAlert>
          {!SetupProgressService?.records?.setUpStatus?.['bid'] ? <Alert color="danger" role="alert">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                Please list all your Bids/Dispatch Centers here.
              </div>
              <div>
                <Button onClick={markCompletedFunc}>Mark Complete</Button>
              </div>
            </div>          </Alert> : <></>}
        </Layout.OnAlert>

        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> Download </DropdownItem>
              </>

            </DropdownMenu>

          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={Bidservice.records}
            total={Bidservice.total}
            uiPreference="bid.grid"
            headers={GridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            selectedIDs={selectedIDs}
            page={Bidservice.page}
            rowsPerPage={Bidservice.rowsPerPage}
            onPaginationChange={Bidservice.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>

        <Layout.DetailPageTitle>
          {detailData?._id ?
            <div>
              <div className="d-flex justify-content-centre align-items-center">
                <div>
                  {t(`Edit Bid `)}
                </div>
              </div>
              <div className="d-flex">
                <div className="mt-3">
                  <span style={{ color: '#999999' }}>
                    {t(`Created At`)}
                  </span>
                  <span>
                    {"  :  "}
                    {moment.unix(detailData?.createdAt).format("DD-MM-YYYY  hh:mm A")}
                  </span>
                </div>
                {detailData?.expiryDate &&
                  <div className="mt-3" style={{ marginLeft: '7px' }}>
                    <span style={{ color: '#999999' }}>
                      {t(`Expired At`)}
                    </span>
                    <span>
                      {"  :  "}
                      {moment.unix(detailData?.expiryDate).format("DD-MM-YYYY  hh:mm A")}
                    </span>
                  </div>
                }
              </div>
            </div>
            :
            t("Add Bid")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditBids
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {detailData?._id ? (
            <AuditLogDialog module="Bid" id={detailData?._id} />
          ) : null

          }
          {userContent?.rights?.includes(1503) && detailData?._id ? <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} /> : null}
          {userContent?.rights?.includes(1502) ? <SaveButton onClick={onSave} loading={loading} /> : null}
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(Bids);
