import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./EmployeeConstant";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { getUiPrefRows } from "../../util/FilterUtil";
class Service {
    records = [];
    STRUCTURE = [];
    page = 1;
    rowsPerPage = null;
    filterOption = "";
    total = -1;
    version = 0;

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async (filterUrl) => {
        if (!this?.rowsPerPage) {
            const rows = await getUiPrefRows("employee.grid")
            this.rowsPerPage = rows ?? 40
        }
        if (filterUrl) {
            this.filterOption = filterUrl;
        }
        const response = await doGET(
            ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl)
        );

        if (response?.status === 200) {
            this.records = response.data.rows;
            this.page = response?.data?.page;
            if (this.page === 1) this.total = response?.data?.total;
            return response.data.rows;;
        }
    };

    edit = async function (data, id) {
        const response = await doPUT(ENDPOINTS?.update, data);
        if (response.status === 200) {
            this.version += 1;
        }

        // return response?.data;
    };

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    save = async (data) => {
        this.records.push({ ...data, roles: [0] });
        const response = await doPOST(ENDPOINTS.save, { ...data, roles: [0] });
        return response?.data?._id;
    };

    delete = async (id) => {
        const response = await doDELETE(ENDPOINTS.delete(id));
        if (response.status === 200) {
            this.fetch();
        }
    };

    bulkDelete = async (ids = []) => {
        const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
        if (response.status === 200) {
            this.fetch();
        }
    };

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch(this.filterOption);

    };

}

const StateService = new Service();
export default StateService;

