
import React, { useEffect } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";

const options = {
    plugins: {
        legend: {
            position: 'right',
            rtl: true,
            labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                padding: 20,
            }
        }
    },
}

const LineChart = ({ data }) => {

    useEffect(() => {
        //show empty data
    }, [])
    return (
        <Bar data={data}
            options={{
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                        position: 'left',
                        ltr: true,
                        labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            padding: 12,
                            font: {

                                weight: '400',
                                size: 15,
                                color: '#000'
                            }
                        }
                    },

                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        grid: {
                            display: false
                        }
                    }
                }
            }} />
    )
}

export default LineChart

