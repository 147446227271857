import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./ReportConstant";
import { doGET } from "../../..//util/HttpUtil";

class Service {
  records = [];
  page = 1;
  rows = 20;
  filterOption = "";

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterUrl) => {
    if (filterUrl) this.filterOption = filterUrl;
    else {
      let currentDate = new Date().toJSON().slice(0, 8);
      const m = currentDate.slice(5, 7);
      const s = '01'
      let end = ''
      if (m === '01' || m === '03' || m === '05' || m === '07' || m === '08' || m === '10' || m === '12') end = '31'
      else if (m === '04' || m === '06' || m === '09' || m === '11') end = '30'
      else end = '28'
      this.filterOption = 'fromDate=' + currentDate + s + '&toDate=' + currentDate + end;
    }
    const response = await doGET(
      ENDPOINTS.grid(this.filterOption)
    );
    if (response.status === 200) {
      this.records = response.data;
      return response.data;
    }
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch(this.filterOption);
  };
}

const Report = new Service();
export default Report;
