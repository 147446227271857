import { EmployeeSelect } from "../../components";
import moment from "moment";

export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Date",
      accessor: "date",
      renderer: (dataObj) => {
        const dateStr = dataObj?.date?.toString();
        const formattedDate = moment(dateStr, 'YYYYMMDD').format('DD-MM-YYYY')
        return <div>
          {formattedDate}
        </div>;
      },
      show: true,
    },
    {
      title: "Remark",
      accessor: "remark",
      show: true,
    },
    {
      title: "First Half",
      accessor: "firstHalf",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.firstHalf == true ?
            <i
              className="mdi mdi-checkbox-marked font-size-20 mx-2"
              style={{ zIndex: "999" }}
            />
            : <i
              className="mdi mdi-checkbox-blank-outline font-size-20 mx-2"
              style={{ zIndex: "999" }}
            />}
        </div>
      },
      show: true,
    },
    {
      title: "Second Half",
      accessor: "secondHalf",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.secondHalf == true ?
            <i
              className="mdi mdi-checkbox-marked font-size-20 mx-2"
              style={{ zIndex: "999" }}
            />
            : <i
              className="mdi mdi-checkbox-blank-outline font-size-20 mx-2"
              style={{ zIndex: "999" }}
            />}
        </div>
      },
      show: true,
    },
    {
      title: "Employee",
      accessor: "",
      renderer: (dataObj) => {
        return <div>
          <EmployeeSelect
            asLabel
            value={dataObj?.employee_id}
            onChange={(v) => { }}
            className=""
          />
        </div>;
      },
      show: true,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
