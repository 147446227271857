import { SelectField } from "../../../components";
import PropTypes from 'prop-types';

const BILLING_DURATION = [
    {
        label: "ON_DEMAND", value: "ON_DEMAND"
    },
    {
        label: "MONTHLY", value: "MONTHLY"
    }, {
        label: "WEEKLY", value: "WEEKLY"
    }, {
        label: "BIWEEKLY", value: "BIWEEKLY"
    }
];
const APPLICABLE_TYPE = [
    {
        label: "PER_VEHICLE", value: "PER_VEHICLE"
    },
    {
        label: "PER_PAX", value: "PER_PAX"
    }
];
const FARE_TYPE_OPTIONS = [
    {
        label: "Pick and Drop, No additional Return/Garage to Garage Fare",
        value: 0
    },
    {
        label: "Garage to Garage",
        value: 1
    },
    {
        label: "Garage to Garage = Fixed Cost",
        value: 2
    },
    {
        label: "Garage Travel Charged on Separate Per KM Basis Only",
        value: 3
    },
    {
        label: "Garage Travel Distance only added to trip distance not time",
        value: 4
    },
    {
        label: "Return Fare Applicable for both distance and time",
        value: 5
    },
    {
        label: "Return Fare Applicable for distance only",
        value: 6
    },
    {
        label: "Return Fare Applicable for distance on Separate Per KM Basis only",
        value: 7
    }
];
const RATE_TYPE = [
    {
        label: "LOCAL", value: "LOCAL"
    },
    {
        label: "OUTSTATION", value: "OUTSTATION"
    }, {
        label: "PACKAGE", value: "PACKAGE"
    }, {
        label: "SELF_DRIVE", value: "SELF_DRIVE"
    }
];

const TIME_ROUNDING_OPTIONS = [
    { label: "Charge each minute", value: 0 },
    { label: "> 30 min = 1 hour", value: 1 },
    { label: "< 1 hr = 0 hr", value: 2 },
    { label: "> 1 min = 1hr", value: 3 },
];
const DAY_END_TYPE_OPTIONS = [
    {
        label: "At Midnight",
        value: 1
    },
    {
        label: "From 24Hrs of trip start time",
        value: 2
    }
];

const DayEndSelect = ({ value, onChange }) => {
    return (<SelectField
        data={DAY_END_TYPE_OPTIONS}
        value={value}
        outline
        onChange={(v) => onChange(v.value)}
        label={"Day End Select"}
        placeholder={"Select Day End"}

    />)
}
const TimeRounding = ({ value, onChange }) => {
    return (<SelectField
        data={TIME_ROUNDING_OPTIONS}
        value={value}
        outline
        onChange={(v) => onChange(v.value)}
        label={"Time Round"}
        placeholder={"Select Time Round"}

    />)
}


const BillingDuration = (
    {
        value,
        onChange
    }
) => {


    return (
        <SelectField
            data={BILLING_DURATION}
            value={value}
            outline
            onChange={(v) => onChange(v.value)}
            label={"Billing Duration"}
            placeholder={"Select Billing Duration"}

        />
    );

}


const ApplicableType = (
    {
        value,
        onChange
    }
) => {


    return (
        <SelectField
            data={APPLICABLE_TYPE}
            value={value}
            outline
            onChange={(v) => onChange(v.value)}
            label={"Applicable Type"}
            placeholder={"Select Applicable Type"}
        />
    );

}

const FareType = (
    {
        value,
        onChange
    }
) => {


    return (
        <SelectField
            data={FARE_TYPE_OPTIONS}
            value={value}
            outline
            onChange={(v) => onChange(v.value)}
            label={"Fare Type"}
            placeholder={"Select Fare Type"}
        />
    );

}


const RateType = (
    {
        value,
        onChange
    }
) => {


    return (
        <SelectField
            data={RATE_TYPE}
            value={value}
            outline
            onChange={(v) => onChange(v.value)}
            label={"Rate Type"}
            placeholder={"Select Rate Type"}

        />
    );

}
DayEndSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

TimeRounding.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}
RateType.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
};
FareType.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired
};
ApplicableType.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
};
BillingDuration.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
};
export {
    BillingDuration,
    ApplicableType,
    FareType,
    RateType,
    TimeRounding,
    DayEndSelect
};