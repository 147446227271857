import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { Table } from "reactstrap";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import CountrySelectService from "./CountrySelectService";
import { I18nContext } from "../../../store/context/i18nContext";

const CountrySelect = ({
  isd,
  noLabel,
  innerContainerClassName,
  innerContainerStyle,
  containerStyle,
  inputGroup,
  style,
  name,
  plain,
  placeholder,
  className,
  onChange,
  value,
  showErr,
  outline,
  inline,
  borderNone,
  RightComponent,
  onClickRight,
  label,
  required,
  showClear,
  dropDownMenuWidth,
  multi,
  hideFlag = false,
  hideDropdown = false,
  ...props
}) => {

  const [COUNTRIES, setCountries] = useState([]);
  const { t } = useContext(I18nContext);

  useEffect(() => {
    const fetch = async () => {
      try {
        await CountrySelectService.fetch();
        setCountries(
          toJS(CountrySelectService.countries).map((c) => {
            return {
              ...c,
              value: isd ? c?.isd : c?.name,
              menuLabel: isd ? (
                <div className="d-flex align-items-center">
                 {!hideFlag &&  <div className="px-0 mx-0 d-flex justify-content-start" style={{
                    minWidth: '30px'
                  }}>
                    <img
                      src={c?.flag}
                      alt="flag"
                      style={{
                        display: "inline",
                        maxHeight: "15px",
                        marginRight: "10px"
                      }}
                    />
                  </div>}
                  <div style={{
                    minWidth: '30px',
                    fontSize: "14px",
                    marginRight: "6px"
                  }}>
                    {`+${c?.isd}`}
                  </div>
                  <div className="d-flex justify-content-start" style={{
                    minWidth: hideFlag ? '30px' : "60px",
                    fontSize: "14px"
                  }}>
                    {`${c?.name}`}
                  </div>
                </div>
              ) : name ? (
                <div>
                    {!hideFlag &&  <img
                    src={c?.flag}
                    alt="flag"
                    style={{
                      display: "inline",
                      maxHeight: "15px",
                      margin: "5px",
                    }}
                  />}
                  {`${c.name}`}
                </div>
              ) : (
                <div>
                  {` ${c.name}`}
                </div>
              ),
              label: isd ? (
                <div className="d-flex align-items-center">
                 {hideFlag ? null : <div className="px-0 mx-0 d-flex justify-content-start" style={{
                    minWidth: '30px'
                  }}>
                    <img
                      src={c?.flag}
                      alt="flag"
                      style={{
                        display: "inline",
                        maxHeight: "15px",
                        margin: "5px",
                        marginRight: "2px"
                      }}
                    />
                  </div>}
                  <div className="mx-1" style={{
                    minWidth: '30px',
                    fontSize: "14px"
                  }}>
                    {`+${c?.isd}`}
                  </div>
                  {/* <div className="d-flex justify-content-start" style={{
                    minWidth: '60px'
                  }}>
                    {`${c?.name}`}
                  </div> */}
                </div>
              ) : name ? (
                <div>
                    {!hideFlag &&    <img
                    src={c?.flag}
                    alt="flag"
                    style={{
                      display: "inline",
                      maxHeight: "15px",
                      margin: "5px",
                    }}
                  /> }
                  {`${c.name}`}
                </div>
              ) : (
                <div>
                  {` ${c.name}`}
                </div>
              ),
            };
          })
        );
      } catch (error) {
      }
    };
    fetch();
  }, []);

  const renderFunc = ({ data, innerRef, innerProps }) => {
    return (
      <div
        ref={innerRef}
        {...innerProps}
      >
        <Table style={{ margin: "0px" }} className="dropdown-hover">
          <thead style={{ margin: "0px" }}>
            <tr style={{ margin: "0px" }}>
              {!hideFlag && <td className="col-3 py-3">
                <img
                  src={data.flag}
                  alt="flag"
                  style={{
                    display: "inline",
                    maxHeight: "15px",
                    margin: "0px 5px",
                  }}
                />
              </td>}
              <td className="col-3 py-3">{`+${data.isd}`}</td>
              <td className="col-6 py-3">{`${data.name}`}</td>
            </tr>
          </thead>
        </Table>
      </div>
    );
  };

  const formatOptionLabel = ({ label, menuLabel }, { context }) => {
    return context === 'menu' ? menuLabel : label;
  };


  const filterOption = useCallback((option, inputValue) =>
    !inputValue ||
    ["isd", "name"]?.some(
      key =>
        `${option.data[key]}`?.toLowerCase().includes(inputValue.toLowerCase())
    ),
    []);

  const selectCountries = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  useEffect(() => {
    if (!value) {
      onChange({ value: 91 });
    }
  }, [value])


  const renderSelectField = ({ disabled }) => (
    <SelectField
      formatOptionLabel={formatOptionLabel}
      render={renderFunc}
      filterOption={filterOption}
      showClear={showClear}
      label={label}
      error={
        !value
          ? "Please Select " + (label ? label : "Country")
          : ""
      }
      required={required}
      containerStyle={containerStyle}
      data={COUNTRIES}
      inputGroup={inputGroup}
      innerContainerStyle={innerContainerStyle}
      innerContainerClassName={innerContainerClassName}
      placeholder={placeholder}
      noLabel={noLabel}
      style={style}
      plain={plain}
      className={className}
      onChange={onChange}
      value={value ? value : 91}
      outline={outline}
      showErr={showErr}
      inline={inline}
      borderNone={borderNone}
      RightComponent={RightComponent}
      onClickRight={onClickRight}
      hideDropdown={hideDropdown }

      {...props}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      formatOptionLabel={formatOptionLabel}
      render={renderFunc}
      filterOption={filterOption}
      showClear={showClear}
      label={label ? label : t("Countries")}
      error={
        !value
          ? "Please Select " + (label ? label : "Country")
          : ""
      }
      required={required}
      containerStyle={containerStyle}
      data={COUNTRIES}
      inputGroup={inputGroup}
      innerContainerStyle={innerContainerStyle}
      innerContainerClassName={innerContainerClassName}
      placeholder={placeholder}
      noLabel={noLabel}
      style={style}
      plain={plain}
      className={className}
      onChange={selectCountries}
      value={value ? COUNTRIES.filter((c) => value?.includes(c?.value)) : 91}
      outline={outline}
      showErr={showErr}
      inline={inline}
      borderNone={borderNone}
      RightComponent={RightComponent}
      onClickRight={onClickRight}
      {...props}
    />
  );

  return (
    <React.Fragment>
      {COUNTRIES.length > 0
        ? multi
          ? renderSelectFieldMultiple()
          : renderSelectField({ disabled: false })
        : renderSelectField({ disabled: true })
      }
    </React.Fragment>
  );
};

export default observer(CountrySelect);
