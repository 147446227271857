export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/drivers/data?rows=${sizePerPage}&`;
        if (filterUrl) url += filterUrl;
        return url + `&page=${page}`;
    },
    get: (id) => `/api/driver/${id}/driverDetail`,
    saveNew: `/api/driver/new`,
    delete: (id) => `/api/driver/delete/${id}`,
    picUpload: (id) => `/api/driver/${id}/picture/save`,
    save: (id) => `/api/driver/update/${id}`,
    download: (filterUrl, template_id) => {
        let url = `/api/driver/download?template_id=${template_id}&`;
        if (filterUrl) url += filterUrl;
        return url;
    },
    picDelete: (id, time) => `/api/driver/${time}/${id}/delete/picture`,
    changeProfilePic: (id, time) => `/api/driver/${id}/profile/picture/${time}`,
    assignDriver: (vehicleID, driverID) =>
        `/api/vehicle/${vehicleID}/driver/${driverID}/assign`,
    policeVerifyPic: (id) => `/api/driver/${id}/policePicture/save`,
};

export const STRUCTURE = [
    {
        label: "Name",
        description: "description...",
        filterLabel: "Name",
        filterName: "name",
        type: "text",
    },
    {
        label: "Country Code",
        description: "description...",
        filterLabel: "Country Code",
        filterName: "countryCode",
        type: "countrySelect",
        isd: true,
    },
    {
        label: "Phone No.",
        description: "description...",
        filterLabel: "Phone No.",
        filterName: "phone",
        type: "text",
    },
    {
        label: "Owner",
        description: "description...",
        filterLabel: "Owner",
        filterName: "driverOf",
        type: "vendorSelect",
    },
    {
        label: "Zone",
        description: "description...",
        filterLabel: "Zone",
        filterName: "zone_id",
        type: "zoneSelect",
    },
    {
        label: "On Duty",
        description: "description...",
        filterLabel: "On Duty",
        type: "checkBox",
        filterName: "onDuty",
    },
    {
        label: "Power Saver Mode On",
        description: "description...",
        filterLabel: "Power Saver Mode On",
        type: "checkBox",
        filterName: "powerSaver",
    },
];

export const MARKETPLACE_BASE_URL = 'http://localhost:9094/api/marketplace'

export const SELECTSTRUCTURE = {
    durations: [
        {
            label: "1 Day", value: "1 Day"
        },
        {
            label: "2 Days", value: "2 Days"
        },
        {
            label: "3 Days", value: "3 Days"
        },
        {
            label: "4 Days", value: "4 Days"
        }
    ],
    servicetype: [
        {
            label: "LOCAL",
            value: "LOCAL",
        },
        {
            label: "OUTSTATION",
            value: "OUTSTATION",
        },
        {
            label: "SELF_DRIVE",
            value: "SELF_DRIVE",
        },
        {
            label: "PACAKAGE",
            value: "PACAKAGE",
        },
        {
            label: "DISPOSAL",
            value: "DISPOSAL",
        },
        {
            label: "TRANSFER",
            value: "TRANSFER",
        },
        {
            label: "FIXED_ROUTE",
            value: "FIXED_ROUTE",
        },
        {
            label: "POINT_TO_POINT",
            value: "POINT_TO_POINT",
        },
        {
            label: "SIGHTSEEING",
            value: "SIGHTSEEING",
        },
    ]
}


