import { makeAutoObservable } from 'mobx';
import { doGET } from '../../../util/HttpUtil';
import { getTimeDifference } from '../../../util/Util';
import CustomerLabelService from '../../../components/Dropdowns/CustomerSelect/CustomerLabelService';
import moment from 'moment';

class Service {
    chats = [];
    selectedChat = null;

    constructor() {
        makeAutoObservable(this);
        this.autoSelectFirstChat();
    }

    autoSelectFirstChat = () => {
        if (this.chats.length > 0) {
            this.setSelectedChat(this.chats[0].id);
        }
    };


    setSelectedChat = (chatId) => {
        this.selectedChat = this.chats.find(chat => chat.id === chatId);

    }


    //can be used in future 

    // transformApiResponse = (response) => {
    //     const uniqueChats = new Map();
    //     response.forEach(item => {
    //         const { _id, customer_id, lastEmail, lastWhatsapp, lastMessage, lastEmailMsg, lastWhatsappMsg, hasUnreadEmail, hasUnreadWhatsapp } = item;

    //         let recentTime = lastEmail;
    //         let messageType = 'email';
    //         let message = lastEmailMsg;
    //         let unread = hasUnreadEmail ? 1 : 0;
    //         let tag = 'email';

    //         if (lastWhatsapp > recentTime) {
    //             recentTime = lastWhatsapp;
    //             messageType = 'whatsapp';
    //             message = lastWhatsappMsg;
    //             unread = hasUnreadWhatsapp ? 1 : 0;
    //             tag = 'whatsapp';
    //         }

    //         if (lastMessage > recentTime) {
    //             recentTime = lastMessage;
    //             messageType = 'message';
    //             message = lastWhatsappMsg;
    //             tag = 'sms';
    //         }

    //         if (!uniqueChats.has(customer_id)) {
    //             uniqueChats.set(customer_id, {
    //                 id: _id,
    //                 customer_id,
    //                 name: customer_id,
    //                 message,
    //                 type: messageType,
    //                 unread,
    //                 tag,
    //                 time: getTimeDifference(recentTime),
    //             });
    //         }
    //     });

    //     return Array.from(uniqueChats.values());
    // };

    fetchChatHeads = async (chatId = null) => {
        try {
            const response = await doGET("/api/conversation-head/grid?sortAsc=false&sortBy=lastMessage&rows=-1");
            if (response && response.data) {
                const processedArray = response.data.rows.map(item => {
                    let recentTime = Math.max(item.lastEmail || 0, item.lastWhatsapp || 0);
                    let messageType = item.lastEmail >= item.lastWhatsapp ? 'email' : 'whatsapp';
                    let message = messageType === 'email' ? item.lastEmailMsg : item.lastWhatsappMsg;
                    let unread = messageType === 'email' ? item.hasUnreadEmail : item.hasUnreadWhatsapp;
                    let tag = item.lastEmail >= item.lastWhatsapp ? 'email' : 'whatsapp';
                  
                    return {
                        id:item?._id,
                        customer_id: item?.customer_id,
                        name: item?.customer_id,
                        recentTime,
                        type:messageType,
                        message,
                        unread,
                        tag,
                        time: getTimeDifference(recentTime),
                    };
                });
                const uniquePhoneNumbers = new Set();
                const chatsArray = [];
                for (const chat of processedArray) {
                    const customer = await CustomerLabelService.fetchCustomerNameFromCustomerId(chat?.customer_id);
                    const phoneNo = customer?.phoneNo?.phone;
                    if (phoneNo && !uniquePhoneNumbers.has(phoneNo)) {
                        uniquePhoneNumbers.add(phoneNo);
                        chatsArray.push({ ...chat, phoneNo });
                    }
                }
    
                const filteredChatsArray = chatsArray?.filter(chat => chat.recentTime !== undefined);
    
                filteredChatsArray?.sort((a, b) => b.recentTime - a.recentTime);
                this.chats = filteredChatsArray.map(chat => ({
                    ...chat,
                    recentTime: moment.unix(chat.recentTime).format('hh:mm A')
                }));
                this.selectedChat = chatId ? this.chats.find(chat => chat.customer_id === chatId) : (this.chats.length ? this.chats[0] : null);
            }
        } catch (error) {
            console.error("Failed to fetch chat heads", error);
        }
    };
}

const InboxService = new Service();
export default InboxService;
