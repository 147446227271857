import React, { useEffect, useState } from 'react'
import BookingService from '../Booking/BookingService';
import CustomTooltip from '../Booking/components/CustomTooltip';
import { Table } from 'reactstrap';
import moment from 'moment';
import EditBooking from '../Booking/EditBooking';
import LabelledBooking from '../Booking/LabelledBooking';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const renderPassengerInfo = (passenger, index) => (
    <div className="d-flex align-items-center " key={passenger.customer_id}>
        <div className="d-flex align-items-center">
            {index >= 0 && <span className="larger-bullet">&#8226;</span>}
            <div>{passenger.name}</div>
        </div>
        <div style={{ marginLeft: '8px' }}>{passenger.phone?.countryCode ? passenger.phone?.countryCode : ''}-{passenger?.phone?.phone}</div>
        <div style={{ marginLeft: '8px' }}>{passenger.email}</div>
    </div>
);

function BookingGrid({ passengers = [], }) {
    const [loading, setLoading] = useState(false);
    const [isMailModal, setMailModal] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null);

    useEffect(() => {
        const fetchBookings = async (passengers) => {
            setLoading(true);
            try {
                const passengersId = passengers?.map(passenger => passenger.customer_id)?.join(",")
                await BookingService.fetch(`passengers.customer_id=in[${passengersId}]`);

            } catch (error) {
                console.error("Error:", error);
            } finally {
                setLoading(false);
            }
        };

        if (passengers?.length) {
            fetchBookings(passengers);
        }
    }, [passengers]);

    const toggleRow = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (BookingService?.records.length === 0) {
        return <div>No Trips found.</div>;
    }

    return (
        <div className="">
            {BookingService?.records?.length ?
                <>
                    <Table className="table-borderless table-centered table-nowrap">
                        <tbody>
                            <tr style={{ borderBottom: "1px solid #EBEBEB" }}>
                                <td>Trip No</td>
                                {/* <td>Status</td> */}
                                <td>Start Time</td>
                                <td>PickUp</td>
                                <td>Passengers</td>
                                <td></td>

                            </tr>

                            {BookingService?.records.map((data, index) => (
                                <React.Fragment key={index}>
                                    <tr className="zr_flight_accordion_row" onClick={() => toggleRow(index)} style={{ cursor: 'pointer' }}>
                                        <td>
                                            <div className="font-size-13 mb-1 fw-normal">  {data?.tripNo ?? "-"}</div>
                                        </td>
                                        {/* <td className='d-flex align-items-center'>
                                        <div>{data?.customer?.name ?? "-"}</div>

                                    </td> */}


                                        <td>
                                            <div>{moment(data?.startTime).format("DD-MMM HH:mm")}</div>
                                        </td>


                                        <td>
                                            <CustomTooltip>
                                                <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}> {data?.src?.city || ''} </CustomTooltip.Body>
                                                <CustomTooltip.ToolTip >{data?.src?.addressLine || ''}</CustomTooltip.ToolTip>
                                            </CustomTooltip>
                                        </td>
                                        <td>
                                            <>
                                                {data?.passengers?.length === 0 ? (
                                                    <div>
                                                        --
                                                    </div>
                                                ) : data?.passengers?.length === 1 ? (
                                                    <CustomTooltip>
                                                        <CustomTooltip.Body style={{ maxWidth: 140, overflowX: 'hidden' }}>  {passengers?.[0]?.name}  </CustomTooltip.Body>
                                                        <CustomTooltip.ToolTip >
                                                            {renderPassengerInfo(data?.passengers[0])}

                                                        </CustomTooltip.ToolTip>
                                                    </CustomTooltip>
                                                ) : (

                                                    <CustomTooltip>
                                                        <CustomTooltip.Body >
                                                            <div href="#" id={`not-clickable-pass-m-${data?._id}${data?._id}`} data-tip={data?.passengers?.length}
                                                                style={{ color: 'blue', textDecoration: 'underline', cursor: "pointer" }}
                                                            >           {data?.passengers?.length}
                                                            </div>
                                                        </CustomTooltip.Body>
                                                        <CustomTooltip.ToolTip >
                                                            {data?.passengers.map(renderPassengerInfo)}

                                                        </CustomTooltip.ToolTip>
                                                    </CustomTooltip>

                                                )}
                                            </>
                                        </td>


                                        <td>
                                            {expandedRow === index ? <FaChevronUp /> : <FaChevronDown />}
                                        </td>

                                    </tr>
                                   

{expandedRow === index && (
                                        <tr className="zr_flight_accordion_expand">
                                            <td colSpan="7">
                                            <LabelledBooking
                                        editID={data?._id}
                                        data={data}
                                        asLabel
                                    />
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>

                            ))}
                        </tbody>
                    </Table>
                </> :
                <>
                    <div>No Stays found .</div>
                </>
            }
        </div>
    );
}

export default BookingGrid