import React from 'react'
import "./styles.css"
import { ThreeBounce } from 'better-react-spinkit'

function FullScreenLoader({loading}) {
  return (
    <>
    {loading?<div className='full-screen-loader'>
        <ThreeBounce size={20} color="#FFF" />
    </div>:null}
    </>
  )
}

export default FullScreenLoader