export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl,kind) => {
      let url = `/api/name-master/grid?kind=${kind}&rows=${sizePerPage}`;
      return url + "&page=" + page;
    },
    get: (id) => `/api/name-master/${id}/detail`,
    save: `/api/name-master/create`,
    delete: (id) => `/api/name-master/${id}/delete`,
    update: `/api/name-master/update`,
  };
  
  export const STRUCTURE = [
    {
      label: "Name",
      filterLabel: "Name",
      filterName: "name",
      type: "text",
    },
  ];
  
  