import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { ThreeBounce } from "better-react-spinkit";
import { UserContext } from "../../store/context/UserContext";
import {
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton,
    CheckButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditOffices from "./EditOffice";
import { STRUCTURE } from "./OfficeConstant";
import { GridColumns } from "./OfficeGridColumns";
import OfficeService from "./OfficeService";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";

const Offices = ({
    value,
    insidePane,
    multiMode,
    onSelect = () => { },
    org_id,
    updateHasOffices = () => { },
    hideTitleBar,
    hideFilterBar,
    dontShowCheck = false
}) => {
    let { office_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const { userContent } = useContext(UserContext);
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(office_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/office/create" || office_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)

    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            const rows = await OfficeService.fetch(filterUrl);
            setLoading(false);
            updateHasOffices(rows?.length ? true : false)

        } catch (e) {
            showError(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        let { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        if (org_id) {
            filterObject = { ...filterObject, org_id: org_id };
            filterUrl += "org_id=" + org_id;
        }
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }, [])

    useEffect(() => {
        if (!insidePane) setEditID(office_id)
    }, [office_id])

    const onSave = async (e) => {
        e.preventDefault();
        if (!detailData?.name) {
            setHasErr(true)
            showError(t("Please enter all fields"));
            return;
        }
        setLoading(true);
        try {
            if (editID) {
                await OfficeService.edit({ data: detailData, org_id });
                setLoading(false);
                showMessage(t("Office updated successfully."));
                if (!insidePane) navigate(`/office/edit/${editID}`);
            } else {
                var officeDetailData = detailData;
                if (org_id) officeDetailData = { ...detailData, org_id: org_id }
                const office = await OfficeService?.save({
                    data: officeDetailData, org_id: org_id
                });
                setLoading(false);
                if (office) setEditID(office)
                showMessage(t("Office saved successfully."));
                if (!insidePane) navigate(`/office/edit/${office}`);
            }
            fetchData(filterURL);
        } catch (e) {
            showError(e);
            setLoading(false);
        }
        setHasErr(false)
    };

    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await OfficeService.bulkDelete(selectedIDs);
                setSelectedIDs([])
                setLoading(false);
                showMessage(t("Deleted"), t("Deleted Successfully"));
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await OfficeService.delete(id, org_id);
                setLoading(false);
                setShowDetailPage(false);
                if (!org_id) {
                    navigate(`/office`);
                }
                showMessage(t("Office Deleted"), t("Deleted"));
            } catch (e) {
                showError(e);
                setLoading(false);
            }
        }
    };

    const renderLastCol = useCallback((office) => {
        return (
            <>
                <EditLink
                    onClick={() => {
                        if (!insidePane)
                            navigate(`/office/edit/${office?._id}`);
                        setEditID(office?._id);
                        setShowDetailPage(true);
                    }}
                />
                {userContent?.rights?.includes(1503) ?
                    <DeleteLink
                        onClick={(event) => onDelete(event, office?._id)}
                    />
                    : null}
                {!multiMode && insidePane && !dontShowCheck ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(office?._id);
                        }}
                    />
                ) : null}
            </>
        );

    }, [insidePane, multiMode, userContent])



    return (
        <React.Fragment>
            <>
                <Layout
                    hideAdd={!userContent?.rights?.includes(1501)}
                    medium
                    showDetailPage={showDetailPage}
                    backDetailPage={async () => {
                        setShowDetailPage(false);
                        if (!insidePane) navigate(t("/office"));
                        setEditID(null);
                    }}
                     
                    title={t("Offices")}
                    filterValues={filterObject}
                    filterStructure={STRUCTURE}
                    onApplyFilter={fetchData}
                    onAddClick={() => {
                        if (!insidePane) navigate(`/office/create`);
                        setShowDetailPage(true);
                        setEditID(null);
                    }}
                    insidePane={insidePane}
                    page={OfficeService.page}
                    rows={OfficeService.rows}
                    total={OfficeService.total}
                    fetch={OfficeService.fetch}
                    hideTitleBar={hideTitleBar}
                    hideSearch={hideFilterBar}
                >
                    <Layout.ActionMenu>
                        <div className="layout-action-menu">
                            <DropdownMenu>
                                <>
                                    {userContent?.rights?.includes(1503) ?
                                    <>
                                        <DropdownItem onClick={() => { onBulkDelete() }}>{t("Bulk Delete")}</DropdownItem>
                                        <div class="dropdown-divider"></div>
                                        </>
                                        : null}
                                    <DropdownItem>{t("Upload")}</DropdownItem>
                                    <div class="dropdown-divider"></div>
                                    <DropdownItem>{t("Download")} </DropdownItem>
                                </>

                            </DropdownMenu>

                        </div>
                    </Layout.ActionMenu>
                    <Layout.Table>
                        <DataGrid
              gridLoading={loading}
                            data={OfficeService.records}
                            total={OfficeService.total}
                            uiPreference="office.grid"
                            headers={GridColumns}
                            selectedIDs={selectedIDs}
                            onSelectChange={(v) => {
                                onSelect(v)
                                setSelectedIDs(v)
                            }}
                            page={OfficeService.page}
                            rowsPerPage={OfficeService.rowsPerPage}
                            onPaginationChange={OfficeService.onPaginationChange}
                            renderLastCol={renderLastCol}
                        />
                    </Layout.Table>
                    <Layout.DetailPageTitle>
                        {detailData?._id ? t("Edit Office") : t("Add Office")}
                    </Layout.DetailPageTitle>

                    <Layout.DetailPageBody>
                        <EditOffices
                            editId={editID}
                            onChange={(v) => {
                                setDetailData(v)
                            }}
                            org_id={org_id}
                            hasErr={hasErr}
                        />
                    </Layout.DetailPageBody>

                    <Layout.DetailPageFooter>
                        {detailData?._id ? (
                            <AuditLogDialog module="Office" id={detailData?._id} />
                        ) : null

                        }
                        {userContent?.rights?.includes(1503) && detailData?._id ? (
                            <DeleteButton
                                loading={loading}
                                onClick={(e) => onDelete(e, detailData?._id)} />
                        ) : null}
                        {userContent?.rights?.includes(1502) ?
                            <SaveButton
                                loading={loading}
                                onClick={onSave} />
                            : null}
                    </Layout.DetailPageFooter>
                </Layout>
            </>
        </React.Fragment>
    );
};
export default observer(Offices);
