
export const GridColumns = {
  version: 1,
  columns: [

    {
      title: "Name",
      accessor: "name",
      show: true,
    },
    {
      title: "Full Name",
      accessor: "fullName",
      show: true,
    },
    {
      title: "Unique Id",
      accessor: "unique_id",
      show: true,
    },
    {
      title: "Domain",
      accessor: "domain",
      renderer: (data) => {
        return <div>{data.domain}.zoyride.com</div>;
      },
      show: true,
    },
    {
      title: "Corporate Type",
      accessor: "corpType",
      renderer: (data) => {
        return <div>
          {data?.corpType == 1 ? "Consumer Corporate" : (data?.corpType == 2) ? "Transporter Corporate" : "--"}
          {data?.status == 0 ? "New" : (data?.status == 1 ? "Active" : "InActive")}
        </div>
      },
      show: true,
    },
    {
      title: "Status",
      renderer: (data) => {
        return <div>
          {data?.status == 0 ? "New" : (data?.status == 1 ? "Active" : "InActive")}
        </div>
      },
      show: true,
    },
    {
      title: "Website",
      accessor: "website",
      renderer: (data) => {
        return <div>
          {data?.website ? data?.website : "--"}
        </div>
      },
      show: true,
    },
    {
      title: "Console",
      renderer: (data) => {
        return <div>
          {data?.console ? data?.console : "--"}
        </div>
      },
      show: true,
    },
    {
      title: "Country",
      accessor: "country",
      show: true,
    },
    {
      title: "TimeZone",
      accessor: "timeZone",
      show: true,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
    // {
    //   title: "Has SelfDrive",
    //   accessor: "hasSelfDrive",
    //   renderer: (dataObj) => {
    //     return <div>
    //       {dataObj?.hasSelfDrive == true ?
    //         <i
    //           className="mdi mdi-checkbox-marked font-size-20 mx-2"
    //           style={{ zIndex: "999" }}
    //         />
    //         : <i
    //           className="mdi mdi-checkbox-blank-outline font-size-20 mx-2"
    //           style={{ zIndex: "999" }}
    //         />}
    //     </div>
    //   },
    //   show: true,
    // },
  ],
};
