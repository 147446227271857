import React, { useContext, useState } from 'react'
import MarketplaceRegisterPopup from './MarketplaceRegisterPopup'
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from "react-router-dom";
import { doGET } from '../../../util/HttpUtil';
import { UserContext } from '../../../store/context/UserContext';
import MarketPlaceRegister from './MarketPlaceRegister';
import { ThreeBounce } from 'better-react-spinkit';
import { CardThreeBounce } from '../../../components';
import { ModalComponent } from '../../../components';

const MarketPlaceMainFile = ({ marketplace }) => {
    let navigate = useNavigate();
    const { userContent } = useContext(UserContext);

    const [clientModal, setClientModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const fetch = async () => {
        try {
            setLoading(true)
            const response = await doGET(`/api/brand-profile/${userContent?.owner_id ?? ""}/detail`);
            if (response?.status == 200) {
                // navigate("/rfp")
                navigate("/supplier")
            } else {
                setShow(true)
            }
        } catch (error) {
            setShow(true)
        }
        setLoading(false)
    };

    return (
        <>
            <div className='d-flex align-items-center'>
                <img
                    onClick={() => {
                        fetch()
                    }}
                    style={{ height: "22px", marginTop: "0px", marginRight: '15px', cursor: 'pointer' }} src={marketplace} />
            </div>
            <ModalComponent isOpen={loading} onToggle={() => {
                setLoading(false)
            }}
                modalSizeStyle={{
                    backgroundColor: "transparent",
                    boxShadow: "none"
                }}
                modalCloseButton={false}
            >
                <ThreeBounce size={20} color="pink" />
            </ModalComponent>

            <ModalComponent isOpen={show} onToggle={() => {
                setShow(false)
            }}>
                <MarketplaceRegisterPopup setClientModal={setClientModal} onClose={() => {
                    setShow(false)
                }} />
            </ModalComponent>
            {clientModal && <MarketPlaceRegister modalOpen={clientModal} toggleModal={() => {
                setClientModal(!clientModal)
            }} />}
        </>
    )
}

export default MarketPlaceMainFile