import React from 'react'
import MarketPlaceStep1Component from './MarketPlaceStep1Component'

const MarketPlaceStep1 = ({ data, setData }) => {
    return (
        <div className='mt-3' >
            <MarketPlaceStep1Component
                data={data}
                setData={setData}
                onChange={(v) => {
                    setData({ ...data, brandName: v })
                }}
                value={data?.brandName}
                label="Company Name"
                placeholder="Enter Company Name" />
            <MarketPlaceStep1Component
                data={data}
                setData={setData}
                value={data?.contactPerson?.name}
                onChange={(v) => {
                    setData({
                        ...data,
                        contactPerson: {
                            ...data.contactPerson,
                            name: v
                        }
                    });
                }}
                label="Contact Person"
                placeholder="Enter Contact Person Name" />
            <MarketPlaceStep1Component
                value={data?.contactPerson?.email}
                data={data}
                setData={setData}
                onChange={(v) => {
                    setData({
                        ...data,
                        contactPerson: {
                            ...data.contactPerson,
                            email: v
                        }
                    });
                }}
                label="Email Address"
                placeholder="Email Address" />

            <MarketPlaceStep1Component
                data={data}
                type="phone"
                setData={setData}
                value={{
                    countryCode: data?.contactPerson?.phone?.countryCode,
                    phone: data?.contactPerson?.phone?.phone,
                }}
                onChange={(v) => {
                    setData({
                        ...data,
                        contactPerson: {
                            ...data.contactPerson,
                            phone: {
                                countryCode: v?.countryCode,
                                phone: v?.phone,
                            }
                        }
                    })
                }}
                label="Phone Number"
                placeholder="Enter Phone Number" />
        </div >
    )
}

export default MarketPlaceStep1