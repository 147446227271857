import React from "react";
import { useContext } from "react";
import { I18nContext } from "../../store/context/i18nContext";

export default function Breadcrumb(props) {
  const { t } = useContext(I18nContext);
  return (
    <h5 className="d-flex align-items-center">
      {props?.parent
        ? props?.parent
          ?.split("|")
          ?.filter((v) => v !== "undefined")
          ?.map((bc) => (
            <>
              <div style={{ fontSize: 14 }}>{t(bc) + " -- "}</div>
              <i className="page-item uil-angle-right text-primary" style={{ fontSize: 30 }}></i>{" "}
            </>
          ))
        : ""}
      <div style={{ fontSize: 16 }}>{t(props?.title)}</div>
    </h5>
  );
}
