import { Image } from "../../components";
export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Name",
            renderer: (dataObj) => {
                return <div>
                    <span>{dataObj?.salutation}</span> <span>{dataObj?.name}</span>
                </div>;
            },
            show: true,
        },
        {
            title: "Designation",
            renderer: (dataObj) => {
                return <div>
                    <span>{dataObj?.supervisorExtn?.designation ? dataObj?.supervisorExtn?.designation : "--"}</span>
                </div>;
            },
            show: true,
        },
        {
            title: "Department",
            renderer: (dataObj) => {
                return <div>
                    <span>{dataObj?.supervisorExtn?.department ? dataObj?.supervisorExtn?.department : "--"}</span>
                </div>;
            },
            show: true,
        },
        {
            title: "Email",
            accessor: "email",
            show: true,
        },
        {
            title: "Phone No",
            renderer: (dataObj) => {
                return <div>
                    {dataObj?.phoneNo?.phone ? (
                        <span>+{dataObj?.phoneNo?.countryCode} {dataObj?.phoneNo?.phone}</span>
                    ) : "--"}
                </div>

            },
            show: true,
        },


        // {
        //     title: "Pic ",
        //     accessor: "pic",
        //     renderer: (dataObj) => {
        //         return <Image height="20px" src={dataObj?.pic} />;
        //     },
        //     show: true,
        //     width: 200,
        // },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
