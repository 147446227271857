import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const ProgressBar = ({ data }) => {
    const [widths, setWidths] = useState([]);
    const totalValue = data.reduce((acc, { value }) => acc + value, 0);

    useEffect(() => {
        setWidths(data.map(({ value }) => ((value / totalValue) * 100 || (data.length === 1 ? 100 : 0)).toFixed(2)));
    }, [data, totalValue]);

    const getBorderRadiusClass = (index) => {
        const firstNonZero = data.findIndex(({ value }) => value > 0);
        const lastNonZero = data.length - 1 - [...data].reverse().findIndex(({ value }) => value > 0);

        if (totalValue === 0) return index === 0 ? 'borderRadius-20' : '';
        if (index === firstNonZero && index === lastNonZero) return 'borderRadius-20';
        if (index === firstNonZero) return 'border-l-tb20';
        if (index === lastNonZero) return 'border-r-tb20';
        return '';
    };

    if (totalValue === 0) return null;

    return (
        <Row>
            <Col className="d-flex justify-content-center">
                <div className='d-flex w-100 h-15'>
                    {data.map(({ title, color }, index) => (
                        <div
                            key={index}
                            className={`h-15 ${getBorderRadiusClass(index)}`}
                            style={{ width: `${widths[index]}%`, backgroundColor: color }}
                            title={`${title}: ${data[index].value}`}
                        ></div>
                    ))}
                </div>
            </Col>
        </Row>
    );
};

ProgressBar.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
    })).isRequired
};

export default ProgressBar;
