
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { DialogContext } from '../../../store/context/DialogContext';
import { I18nContext } from '../../../store/context/i18nContext';
import { doDELETE, doGET, doPUT } from '../../../util/HttpUtil';
import SpaceService from "../listView/SpaceService";
import AllRemindersModal from './AllRemindersModal';
import CustomEvent from './CustomEvent';
import ReminderFormModal from './ReminderFormModal';
import './style.css';


const localizer = momentLocalizer(moment);

const CalenderLeads = ({ }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [isFormView, setIsFormView] = useState(false);
  const [events, setEvents] = useState([]);
  const [viewedMonthStart, setViewedMonthStart] = useState(moment().startOf('month'));
  const [viewedMonthEnd, setViewedMonthEnd] = useState(moment().endOf('month'));
  const [isGridModal, setGridModal] = useState(false);

  const [reminderFormData, setReminderFormData] = useState({});
  const [reminderGridData, setReminderGridData] = useState([]);



  useEffect(() => {
    fetchRates();
  }, [SpaceService.selectedSpace._id, viewedMonthStart, viewedMonthEnd]);

  const handleNavigate = (actionDate) => {
    setViewedMonthStart(moment(actionDate).startOf('month'));
    setViewedMonthEnd(moment(actionDate).endOf('month'));
  };

  const fetchRates = async () => {
    const queryString = `?actionDate=btw[${viewedMonthStart.format('YYYYMMDD') - 1},${parseInt(viewedMonthEnd.format('YYYYMMDD')) + 1}]&sortAsc=true&sortBy=actionDate`;
    try {
      const response = await doGET(`/api/reminder/grid${queryString}&space_id=${SpaceService.selectedSpace._id}&rows=-1`);

      const groupedByDate = response.data.rows.reduce((acc, row) => {
        const dateKey = moment(row.actionDate, 'YYYYMMDD').format('YYYY-MM-DD');
        if (!acc[dateKey]) {
          acc[dateKey] = [];
        }
        acc[dateKey].push(row);
        return acc;
      }, {});

      let currentDate = moment(viewedMonthStart);
      const endDate = moment(viewedMonthEnd);
      while (currentDate <= endDate) {
        const dateKey = currentDate.format('YYYY-MM-DD');
        if (!groupedByDate[dateKey]) {
          groupedByDate[dateKey] = [{}];
        }
        currentDate = currentDate.add(1, 'days');
      }

      const eventsMapped = Object.entries(groupedByDate).map(([date, reminders]) => {
        return {
          title: date,
          start: moment(date, 'YYYY-MM-DD').toDate(),
          end: moment(date, 'YYYY-MM-DD').toDate(),
          segments: reminders.map(reminder => ({
            ...reminder,
            title: reminder.action,
          }))
        };
      });

      console.log(eventsMapped);
      setEvents(eventsMapped);
    } catch (error) {
      console.error('Failed to fetch lead reminder:', error);
    }
  };

  const deleteEvent = async (id) => {
    try {
      const response = await doDELETE(`/api/reminder/${id}/delete`);
      fetchRates()
    } catch (error) {
      console.error('Failed to fetch reminder rates:', error);
    }
  };

  const onSelectEvent = (event) => {

  };

  const showModal = (slotInfo) => {
    const { start } = slotInfo;
    setReminderFormData({ actionDate: moment(start).format('YYYYMMDD') });
  };

  return (
    <div className="zr_leadReminders py-3">
      <div className='d-flex justify-content-center w-100' >
        <div className='flex-1' >
          <Calendar
            localizer={localizer}
            onSelectEvent={onSelectEvent}
            onNavigate={handleNavigate}
            events={events}
            startAccessor="start"
            endAccessor="end"
            views={["month"]}
            style={{ height: "calc(100vh - 150px)" }}
            onSelectSlot={showModal}
            components={{
              event: (props) => <CustomEvent
                {...props}
                onDelete={() => deleteEvent(props?.event?._id)}
                onAdd={() => {
     
                  setIsFormView(true);
                  setReminderFormData({ actionDate: moment(props?.event?.start).format('YYYYMMDD') })
                }}
                onRequestGrid={() => {
                  setGridModal(true);
                  setReminderGridData(props?.event?.segments ?? [])
                }}
                onRequestForm={(v) => {
                  setIsFormView(true);
                  setReminderFormData(v)
                }}
                fetchRates={fetchRates}
              />,
            }}
            selectable
          />
        </div>
      </div>

      <AllRemindersModal
        isOpen={isGridModal}
        toggleModal={() => {
          setGridModal(!isGridModal);
          fetchRates()
        }}
        onAdd={() => {
          setReminderFormData({})
          setIsFormView(true)
        }}
        event={reminderGridData}
        selectSegment={(v) => {
          setReminderFormData(v)
          setIsFormView(true)
        }}
      />

      <ReminderFormModal
        editId={reminderFormData?._id}
        value={reminderFormData}
        isOpen={isFormView}
        toggleModal={() => {
          setIsFormView(false)
          fetchRates()
        }}
        onDelete={() => {
          fetchRates()
        }}
      />

    </div>
  );
};

export default observer(CalenderLeads);


