import moment from "moment";
import { CompanySelect, OfficeSelect } from "../../components";
import CompanyLabel from "../../components/Dropdowns/Entity/CompanyLabel";
const AccountTypes = ["Saving", "Current", "Cash", "CreditCard", "DebitCard", "FuelCard"];


export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Type",
      accessor: "accountType",
      renderer: (dataObj) => {
        const accountType = dataObj?.accountType;
        const displayName = AccountTypes.includes(accountType) ? accountType : "Other";
        return <div>{displayName}</div>;
      },
      show: true,
    },
    {
      title: "Company",
      show: true,
      renderer: (dataObj, rowIndex, pageSize) => {
        return <div>{dataObj?.company_ids ?
          <CompanyLabel
            corpType="self"
            value={dataObj?.company_ids}
            multi
          />
          : <span style={{ fontStyle: "italic" }}>--</span>}</div>;
      },
    },
    {
      title: "Office",
      show: true,
      renderer: (dataObj) => {
        return (
          <div>
            <OfficeSelect
              asLabel
              value={dataObj?.office_id}
              onChange={(v) => { }}
              className=""
            />
          </div>
        );
      },
    },
    {
      title: "Account Name",
      accessor: "name",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.acDetail?.name}
        </div>;
      },
      show: true,
    },
    {
      title: "Account Number",
      accessor: "acDetail",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.acDetail?.accountNo}
        </div>;
      },
      show: true,
    },

    {
      title: "Account Balance",
      accessor: "balance",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.balance ? ((dataObj?.balance) / 100).toFixed(2) : 0}
          </div>
        );
      },
      show: true,
    },
    {
      title: "Opening Date",
      renderer: (dataObj) => {
        const dateStr = dataObj?.openingDate?.toString();
        const formattedDate = moment(dateStr, 'YYYYMMDD').format('DD-MM-YYYY')
        return <div>
          {formattedDate}
        </div>;
      },
      accessor: "openingDate",
      show: true,
    },
    {
      title: "Status",
      accessor: "status",
      renderer: (dataObj) => {
        return (
          <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -5 }}>
            <div
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor:
                  dataObj?.status === 1 ? 'green' : dataObj?.status == 0 ? 'red' : dataObj?.status == 2 ? 'orange' : 'skyblue',
                marginRight: '5px',

              }}
            ></div>
            <span>
              {dataObj?.status === 0 ? "Inactive" : dataObj?.status === 1 ? "Active" : dataObj?.status === 2 ? "Blocked" : "Unknown"}
            </span>
          </div>
        );

      },
      show: true,
      required: true,
    },
    // {
    //   title: "Office",
    //   accessor: "",
    //   renderer: (dataObj) => {
    //     return <div>
    //       <OfficeSelect
    //         asLabel
    //         value={dataObj?.office_id}
    //         onChange={(v) => { }}
    //         className=""
    //       />
    //     </div>;
    //   },
    //   show: true,
    // },
    // {
    //   title: "Company",
    //   accessor: "",
    //   renderer: (dataObj) => {
    //     return <div>
    //       <CompanySelect
    //         asLabel
    //         value={dataObj?.company_id}
    //         onChange={(v) => { }}
    //         className=""
    //       />
    //     </div>;
    //   },
    //   show: true,
    // },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },

  ],
};
