import React from 'react';
import './style.css';
import { FaCarAlt, FaRoute } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const TripPopup = ({ trip }) => {



    const passengers = trip?.passengers || [];
    const passengerCount = passengers.length;
    const renderPassengerInfo = (passenger, index) => (
        <div style={{borderBottom : "1px solid #EBEBEB"}} className="mb-2 d-flex flex-column align-items-end " key={passenger.customer_id}>
            <div className="d-flex align-items-center">
                <div>{passenger.name}</div>
            </div>
            {passenger.phone.phone && passenger.phone?.countryCode ? <div style={{ marginLeft: '8px' }}>{passenger.phone?.countryCode ? passenger.phone?.countryCode : ''}-{passenger?.phone?.phone}</div> : null}
            {passenger.phone.email ? <div style={{ marginLeft: '8px' }}>{passenger.email}</div> : null}
       
        </div>
    );


    //             <div>
    //                 {passengerCount === 0 ? (
    //                     <div>
    //                         --
    //                     </div>
    //                 ) : passengerCount === 1 ? (
    //                     <div>
    //                         <span id={`not-clickable-pass-${dataObj?._id}`} style={{ cursor: 'pointer' }}>
    //                             {passengers[0].name}
    //                         </span>
    //                         <Tooltip style={{ zIndex: 999999999, backgroundColor: "#FAF9F6", color: "black", fontWeight: 'bold' }} anchorSelect={`#not-clickable-pass-${dataObj?._id}`}>
    //                             {renderPassengerInfo(passengers[0])}
    //                         </Tooltip>
    //                     </div>
    //                 ) : (
    //                     <div href="#" id={`not-clickable-pass-m-${dataObj?._id}${dataObj?._id}`} data-tip={passengerCount}
    //                         style={{ color: 'blue', textDecoration: 'underline', cursor: "pointer" }}
    //                     >
    //                         {passengerCount}
    //                         <Tooltip
    //                             style={{ zIndex: 999999999, backgroundColor: "#FAF9F6", color: "black", fontWeight: 'bold' }}
    //                             anchorSelect={`#not-clickable-pass-m-${dataObj?._id}${dataObj?._id}`}>
    //                             {passengers.map(renderPassengerInfo)}
    //                         </Tooltip>
    //                     </div>
    //                 )}
    //             </div>



    return (
        <div>

            <div className='d-flex'>
                <FaRoute className="me-2 font-size-14" />
                <div>{trip.tripNo}</div>
            </div>
            <div className="trip-popup-container">
                <div className="trip-info">
                    {trip?.driver?.name && (

                        <p className="info-column">
                            <span className="info-label"><span>Driver</span> <span>:</span></span>
                            <span className="info-value">
                                {trip?.driver?._id ? <> <Link to={`/employee/edit/${trip?.driver?._id}`}
                                    replace
                                    style={{ color: "blue" }}
                                >
                                    <u>{trip.driver?.name} </u>
                                </Link></> : trip.driver?.name}
                            </span>
                        </p>

                    )}

                    {trip?.vehicle?.regNo && (

                        <p className="info-column">
                            <span className="info-label"><span>RegNo</span> <span>:</span> </span>
                            <span className="info-value">
                                {trip?.vehicle?._id ? <> <Link to={`/vehicle/edit/${trip?.vehicle?._id}`}
                                    replace
                                    style={{ color: "blue" }}
                                >
                                    <u>{trip.vehicle?.regNo} </u>
                                </Link></> : trip.vehicle?.regNo}

                            </span>
                        </p>

                    )}

                    {trip?.kind && (

                        <p className="info-column">
                            <span className="info-label"><span>Status</span> <span>:</span> </span>
                            <span className="info-value">{trip.kind}</span>
                        </p>

                    )}


                    {trip?.passengers?.length && (

                        <p className="info-column">
                            <span className="info-label"><span>Passengers</span> <span>:</span> </span>
                            <span className="info-value">
                                <div>
                                    {!passengerCount ? (
                                        <div>
                                            --
                                        </div>
                                    ) : (
                                        <div>
                                            {passengers.map(renderPassengerInfo)}
                                        </div>
                                    )}
                                </div>

                            </span>
                        </p>

                    )}




                </div>
            </div>
        </div>

    );
};

export default TripPopup;
