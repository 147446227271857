import React, { useContext, useEffect, useState } from "react";

import { Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { observer } from "mobx-react-lite";
import { Layout } from "../../components";
import PaymentLogsService from "./PaymentLogsService";

import { DialogContext } from "../../store/context/DialogContext";
import moment from "moment";

const PaymentLog = (props) => {
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);

  const fetchData = async (filterUrl) => {
    await PaymentLogsService.fetch(filterUrl);
    await PaymentLogsService.structure();
  };
  useEffect(() => {
    fetchData();

  }, []);

  return (
    <Layout
       
      title="Payment Transaction"
      filterStructure={PaymentLogsService.STRUCTURE}
      onApplyFilter={fetchData}
      page={PaymentLogsService.page}
      rows={PaymentLogsService.rows}
      total={PaymentLogsService.totalRecords}
      onPageChange={async (page, rows) => {
        await PaymentLogsService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>Corporate</Th>
            <Th>User</Th>
            <Th>Created At</Th>
            <Th>Amount</Th>

            <Th>Trans Detail</Th>
            <Th>Gateway</Th>

            <Th>Status</Th>
            <Th>Purpose</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {PaymentLogsService.records?.map((item) => (
            <Tr key={item?._id}>
              <Td scope="row">
                {
                  <>
                    <div>Client : {item.corporateName}</div>
                    <div>Merchant : {item.merchantName}</div>
                  </>
                }
              </Td>
              <Td scope="row">
                {
                  <>
                    <div>Name : {item.userName}</div>
                    <div>Phone : {item.userPhone}</div>
                  </>
                }
              </Td>
              <Td>
                {moment(item?.createdAt * 1000).format("DD-MM-yyyy hh:mm")}
              </Td>
              <Td>{item?.amount + " " + item?.currency}</Td>
              <Td></Td>
              <Td>{item?.gateway}</Td>
              <Td>{item?.statusStr}</Td>
              <Td>{item?.purpose}</Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>
    </Layout>
  );
};

export default observer(PaymentLog);
