import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./AdminDashboardConstant";
import { doGET } from "../../util/HttpUtil";

class Service {
  records = [];
  totalRecords = 0;
  places = [];
  page = 1;
  rows = 10;

  constructor() {
    makeAutoObservable(this);
  }

  get = async function (data) {

    const response = await doGET(ENDPOINTS.get, data)
    if (response.status === 200) {
      this.records = response.data;
      this.totalRecords = response.data.records;
      return response?.data;
    }
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch();
  };
}

const AdminDashboardService = new Service();
export default AdminDashboardService;
