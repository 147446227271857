export const ENDPOINTS = {
  grid: (page, sizePerPage) => {
    let s = `/api/corp/subscription/grid?rows=${sizePerPage}&page=${page}`;
    //   if (filterData) {
    //     if (filterData?.routeNo) s += "&routeNo=" + filterData?.routeNo;
    //     if (filterData?.title) s += "&title=" + filterData?.title;
    //     if (filterData?.client) s += "&clientName=" + filterData?.clientName;
    //     if (filterData?.vendorName) s += "&vendorName=" + filterData?.vendorName;
    //     if (filterData?.status) s += "&status=" + filterData?.status;
    //     if (filterData?.driverPhone) s += "&driver.phone=" + filterData?.driver?.phone;
    //     if (filterData?.vehicleReg) s += "&vehicle.regNo=" + filterData?.vehicleReg;
    //     if (filterData?.driverName) s += "&driver.name=" + filterData?.driver?.name;
    //     if (filterData?.phoneNum) {
    //       s += "&countryCode=" + filterData?.phoneNum?.countryCode;
    //       s += "&phone=" + filterData?.phoneNum?.phone;
    //     }
    //     if (filterData?.sourceCountry) s += "&country=" + filterData.sourceCountry;

    //     if (filterData?.city) s += "&city=" + filterData.city;

    //   }
    return s;
  },
  get: (id) => `/api/corp/subscription/${id}/detail`,
  getInvoices: (id) => `/api/corp/subscription/${id}/invoice/grid`,
  save: "/api/corp/subscription/save",
  // delete: (id) => `/api/route/${id}/delete`,
};

//   export const STRUCTURE = [
//     {
//       label: "Vehicle Reg No",
//       description: "description...",
//       filterLabel: "Vehicle Reg No",
//       filterName: "vehicleReg",
//       type: "text",
//     },
//     {
//       label: "Route No.",
//       description: "description...",
//       filterLabel: "Route No",
//       filterName: "routeNo",
//       type: "text",
//     },
//     {
//       label: "Route Title",
//       description: "description...",
//       filterLabel: "Route Title",
//       filterName: "title",
//       type: "text",
//     },
//     {
//       label: "Client",
//       description: "description...",
//       filterLabel: "Client Name",
//       filterName: "client",
//       type: "clientSelect",
//     },

//     {
//       label: "Vendor Name",
//       description: "description...",
//       filterLabel: "Vendor Name",
//       filterName: "vendorName",
//       type: "text",
//     },
//     {
//       label: "Driver Phone",
//       description: "description...",
//       filterLabel: "Driver Phone",
//       filterName: "driverPhone",
//       type: "text",
//     },
//     {
//       label: "Passenger Phone No",
//       description: "description...",
//       filterLabel: "Passenger No",
//       filterName: "phoneNum",
//       type: "phoneNum",
//     },
//     {
//       label: "Driver Name",
//       description: "description...",
//       filterLabel: "Driver Name",
//       filterName: "driverName",
//       type: "text",
//     },
//     {
//       label: "From Date",
//       description: "Select From Time",
//       filterLabel: "From Date",
//       filterName: "fromDate",
//       type: "date",
//     },
//     {
//       label: "To Date",
//       filterLabel: "To Date",
//       description: "To Date",
//       filterName: "tillTime",
//       type: "date",
//     },
//     {
//       label: "Country",
//       description: "description...",
//       filterLabel: "Country",
//       filterName: "sourceCountry",
//       type: "countrySelect",
//       dependencyOnMe: ["city", "state"],
//     },

//     {
//       label: "City",
//       description: "description...",
//       filterLabel: "City",
//       filterName: "city",
//       type: "citySelect",
//       dependency: {
//         country: "sourceCountry",
//         state: "state"
//       },
//     },
//     {
//       label: "Status",
//       description: "description...",
//       filterLabel: "Status",
//       filterName: "status",
//       type: "select",
//       data: [
//         { label: "All", value: "" },
//         { label: "Approved", value: "Approved" },
//         { label: "Confirmed", value: "Confirmed" },
//         { label: "Completed", value: "Completed" },
//         { label: "Assigned", value: "Assigned" },
//         { label: "Cancelled", value: "Cancelled" },
//         { label: "Created", value: "Created" },
//         { label: "On Journey", value: "On Journey" },
//         { label: "Request Cancel", value: "Request Cancel" },
//         { label: "Supplier Confirmed", value: "Supplier Confirmed" },
//       ],
//     },

//   ];
