import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import from react-router-dom
import { doGET } from "../../util/HttpUtil";
import { Container } from "reactstrap";
import { Image } from "../../components";
import "./paynow.scss";
import Placeholder from '../../assets/images/placeholder.svg'

const PayNow = () => {
  const { request_id } = useParams();
  const [buttonHTML, setButtonHTML] = useState(null);
  const [data, setData] = useState(null)

  const fetchButton = async () => {
    try {
      const response = await doGET(`/api/pay-now?paymentRequest_id=${request_id}`)
      if (response.status == 200) {
        setData(response?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchButton()
  }, [request_id])

  return (


    <React.Fragment>
      <div className="my-5 ">
        <Container>
          <div className={`zr_pymt_status h-100 `}>
            <div className="text-center fw-bold fs-1 mb-3">Payment Summary</div>
            <div className={`text-center border  rounded-3 px-3 pt-3 pb-5 payment-box shadow`}>
              <div className="d-flex flex-column flex-md-row align-items-center border-bottom pb-3 gap-3 w-responsive">
                <div className="image-div">
                  {data?.logo ? <Image
                    height="150px"
                    width="150px"
                    src={data?.logo}
                    alt="Uploaded File"
                    className="rounded border border-1 image-background"
                    style={{ minHeight: "150px", minWidth: "150px", borderRadius: "10px", objectFit: "contain", }}
                  /> : <img
                    height="150px"
                    width="150px"
                    alt="Uploaded File"
                    className="rounded border border-1 image-background"
                    style={{ minHeight: "150px", minWidth: "150px", borderRadius: "10px", objectFit: "contain", }}
                    src={Placeholder}
                  />}
                </div>
                <div className="detail-div flex-1">
                  <div className="fs-3 fw-bold text-start p-0 m-0">{data?.corpName}</div>
                  <div className="text-start fs-5 py-2">Payment For: {data?.paymentFor}</div>
                  <div className="text-start fs-5">Amount: <span className="fw-bold">{((data?.amount ?? 0) / 100).toFixed(2)} {data?.currency}</span></div>
                </div>
              </div>
              <div className="w-100 pt-5">
                {data?.content && (
                  <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                )}
              </div>
            </div>
          </div>

        </Container>
      </div>
    </React.Fragment>


  );
}

export default PayNow;
