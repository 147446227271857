import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { ModalComponent } from '../../../../../components';
import Stay from '../../../../Stay/Stay';

const SendStaysModal = ({ isModalVisible, handleClose, onSendStays, customer_id }) => {
    const [selectedStays, setSelectedStays] = useState([]);

    return (
        <ModalComponent
            position={"top"}
            size={"large"}
            onToggle={handleClose}
            isOpen={isModalVisible}
        >
            <ModalComponent.Title>
                Stays
            </ModalComponent.Title>

            <ModalComponent.Body>
                <Stay
                    passengerId={customer_id}
                    insidePane
                    multiMode
                    onSelect={(v) => {
                        setSelectedStays(v);
                    }}
                />
            </ModalComponent.Body>

            <ModalComponent.Footer>
                <Button
                    className='primary-dark-bluish'
                    onClick={() => onSendStays(selectedStays)}
                >
                    Send
                </Button>
            </ModalComponent.Footer>
        </ModalComponent>
    );
};

export default SendStaysModal;
