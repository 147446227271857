import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { CardThreeBounce } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import DocumentCategoryService from "./DocumentCategoryService";
import { SELECTSTRUCTURE } from "./DocumentCategoryConstant";
import { InputField, Checkbox, SelectField } from "../../components";
import { doGET } from "../../util/HttpUtil";

const moment = require('moment');

const EditDocumentCategories = ({ editId, onChange = () => { }, hasErr }) => {
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [parentIds, setParentIds] = useState([]);
    const fetch = async () => {
        try {
            let documentCategory = await DocumentCategoryService.get(editId);
            setData(documentCategory);
        } catch (error) {
            showError(error)
        }
    };

    const fetchCategories = async () => {
        setLoading(true);
        try {
            const response = await doGET(`/api/doc-category/grid?parent_id=notExists&rows=-1`);
            const categories = (response?.data?.rows || [])
                .filter(item => item?._id !== editId)
                .map(item => ({
                    label: item?.name,
                    value: item?._id
                }));
            setParentIds([...categories]);
            setLoading(false);
        } catch (e) {
            showError(e);
            setLoading(false);
        }

    };

    useEffect(() => {
        setData(null)
        fetchCategories();
        setLoading(true);
        if (editId) fetch();
        setLoading(false)
    }, [editId]);

    useEffect(() => {
        onChange(data)
    }, [data]);

    const seconds = data?.at;
    const dateTime = moment.unix(seconds);
    const date = dateTime.format('DD-MM-YYYY');
    const time = dateTime.format('HH:mm A');
    return (
        <React.Fragment>
            {loading ?
                <CardThreeBounce /> :
                <Form
                    className="p-3"
                    style={{ height: "100%", padding: "2rem", margin: 0 }}
                >
                    <SelectField
                        placeholder={t("Module")}
                        data={SELECTSTRUCTURE?.module}
                        value={data?.module}
                        required={true}
                        label={t("Module")}
                        onChange={(v) =>
                            setData({
                                ...data,
                                module: v?.value
                            })
                        }
                        type="text"
                        className="col-sm-8 my-3 col-12"
                    />
                    <InputField
                        required
                        value={data?.name}
                        label={t("Name")}
                        onChange={(v) => setData({ ...data, name: v })}
                        error={data?.name === 0 ? "Please enter the Name" : ""}
                        showErr={hasErr}
                        type="text"
                        className="col-sm-8 my-3 col-12"
                    />
                    {parentIds.length?
                        <SelectField
                            placeholder={t("Category")}
                            data={parentIds}
                            value={data?.parent_id}
                            label={t("Category")}
                            onChange={(v) =>
                                setData({
                                    ...data,
                                    parent_id: v?.value
                                })
                            }
                            type="text"
                            className="col-sm-8 my-3 col-12"
                        /> : null
                    }
                    <Checkbox
                        placeholder={t("Expiry")}
                        checked={data?.hasExpiry ? true : false}
                        required={true}
                        label={t("Expiry Date")}
                        onClick={() => {
                            setData({ ...data, hasExpiry: !data?.hasExpiry })
                        }}
                        showErr={hasErr}
                        type="text"
                        className="mt-3 col-sm-12 col-12 my-2"
                    />
                </Form>
            }
        </React.Fragment >
    );
};

export default observer(EditDocumentCategories);
