import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState ,useRef} from "react";
import { Form, Row, Button } from "reactstrap";
import { Address, AddressField, Checkbox, InputField } from "../../components";
import MAPComponent from "../../components/Map/Map";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import CustomFieldService from "./CustomFieldService";
import { ThreeBounce } from "better-react-spinkit";
import EmployeKindSelect from "../../components/Dropdowns/EmployeeKindSelect/EmployeKindSelect";
import { CustomFieldDisplayTypeSelect } from "../../components";

const EditCustomFields = ({ editId, onChange = () => { }, hasErr }) => {
  const [center, setCenter] = useState({})
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [options, setOptionData] = useState(data?.options ? data?.options : []);
  const optionsRef = useRef(options);


  const fetch = async () => {
    try {
      let customField = await CustomFieldService.get(editId);
      setData(customField);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(null)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, CustomFieldService?.version]);

  useEffect(() => {
    setOptionData(data?.options || []);
    onChange(data)
  }, [data]);

  useEffect(() => {
    if (JSON.stringify(data?.options) !== JSON.stringify(options)) {
      setData({ ...data, options: options });
    }
  }, [options]);


  const changeSectionField = (sectionIndex, value, field) => {
    setOptionData((X) =>
      X.map((f, curr) => {
        if (sectionIndex === curr) return { ...f, [field]: value };
        return f;
      })
    );
  };
  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="mt-3 mb-3">

            <EmployeKindSelect
              value={data?.kind}
              onChange={(v) => {
                setData({
                  ...data,

                  kind: v?.value,
                });
              }}
            // error={
            //   data?.kind?.length === 0
            //     ? "Please Engine No"
            //     : ""
            // }

            />
          </Row>
          <Row className="mt-3 mb-3">
            <InputField
              value={data?.group}
              label={"Group Name"}
              onChange={(v) => {
                setData({
                  ...data,
                  group: v,
                });
              }}
              error={data?.group?.length === 0 ? "Please Enter Group" : ""}x
              type="text"
            />
          </Row>
          <Row className="mt-3 mb-3">

            <CustomFieldDisplayTypeSelect
              value={data?.displayType}
              onChange={(v) => {
                setData({
                  ...data,
                  displayType: v?.value,
                });
              }}
              error={data?.displayType?.length === 0 ? "Please Engine No" : ""}
            // showErr={showErr}

            />
          </Row>
          <Row className=" mb-3">
            <InputField
              value={data?.section}
              label={"Section Name"}
              onChange={(v) => {
                setData({
                  ...data,
                  section: v,
                });
              }}
              error={data?.section?.length === 0 ? "Please Enter Section" : ""}

              type="text"
            />
          </Row>
          
          <Row className=" mb-3">
            <InputField
              value={data?.maxLength}
              label={"Max Length"}
              onChange={(v) => {
                setData({
                  ...data,
                  maxLength: v,
                });
              }}
              error={
                data?.maxLength?.length === 0 ? "Please Enter Max Length" : ""
              }

              type="number"
            />
          </Row>
          <Row className="mb-3">
            <InputField
              value={data?.maxValue}
              label={"Max Value "}
              onChange={(v) => {
                setData({
                  ...data,
                  maxValue: v,
                });
              }}
              error={data?.maxValue?.length === 0 ? "Please Enter Max Value" : ""}

              type="number"
            />
          </Row>
          <Row className="mb-3">
            <InputField
              value={data?.minValue}
              label={"Min Value "}
              onChange={(v) => {
                setData({
                  ...data,
                  minValue: v,
                });
              }}
              error={data?.minValue?.length === 0 ? "Please Enter Min Value" : ""}
              type="number"
            />
          </Row>
          <Row className="mb-3">
            <InputField
              value={data?.label}
              label={"Label "}
              onChange={(v) => {
                setData({
                  ...data,
                  label: v,
                });
              }}
              error={data?.label?.length === 0 ? "Please Enter Label" : ""}
              type="text"
            />
            <InputField
              value={data?.placeholder}
              label={"Placeholder"}
              onChange={(v) => {
                setData({
                  ...data,
                  placeholder: v,
                });
              }}
              error={data?.label?.length === 0 ? "Please Enter Placeholder" : ""}

              type="text"
            />
          </Row>
          <Row className="mb-3">
            {(data?.displayType === 2 ||
              data?.displayType === 4 ||
              data?.displayType === 12) && (
                <>
                  <Row className="d-flex mx-1 justify-content-between pb-2 my-1 border-dark border-bottom">
                    <Button
                      className="col-sm-3"
                      onClick={() => setOptionData([...options, {}])}
                    >
                      Add Options +
                    </Button>
                  </Row>
                  {options?.map((section, sectionIndex) => (
                    <Row className="border-bottom border-dark pt-2">
                      <Row>
                        <InputField
                          showErr={hasErr}
                          required={true}
                          value={section?.value}
                          onChange={(v) => changeSectionField(sectionIndex, v, "value")}
                          label={"Value"}
                          error={
                            data?.minValue?.length === 0 ? "Please Enter Value" : ""
                          }
                          className=" col-sm-4 px-1 py-1"
                          type="text"
                        />
                        <InputField
                          showErr={hasErr}
                          required={true}
                          value={section?.label}
                          onChange={(v) => changeSectionField(sectionIndex, v, "label")}
                          label={"Label"}
                          error={
                            data?.minValue?.length === 0 ? "Please Enter Label" : ""
                          }
                          className=" col-sm-4 px-1 py-1"
                          type="text"
                        />
                        <Checkbox
                          label="Default"
                          className="col-sm-3"
                          checked={section?.isDefault}
                          onClick={() =>
                            setOptionData(
                              options.map((f, curr) => {
                                if (sectionIndex === curr)
                                  return {
                                    ...section,
                                    isDefault: section?.isDefault ? false : true,
                                  };
                                return f;
                              })
                            )
                          }
                        />
                        <Button
                          className=" col-sm-1 px-1 btn btn-danger"
                          style={{ maxHeight: "40px" }}
                          onClick={() =>
                            setOptionData(
                              options.filter((t, tcurr) => tcurr !== sectionIndex)
                            )
                          }
                        >
                          Delete
                        </Button>
                      </Row>
                    </Row>
                  ))}
                </>
              )}
          </Row>
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditCustomFields);
