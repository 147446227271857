import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  Checkbox,
  CitySelect,
  ClientSelect,
  CountrySelect,
  DeleteLink,
  InputField,
  Layout,
} from "../../../../components";
import "../../../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../../../store/context/DialogContext";
import { I18nContext } from "../../../../store/context/i18nContext";
import SysConfigService from "./SysConfigService";

const SysConfig = () => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [hasErr, setHasErr] = useState(false);
  const [smtpData, setSmtpData] = useState({});

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setData({
        ...data,
      });
      await SysConfigService.save(data);
      showMessage("Helpline saved successfully");
      setLoading(false);
    } catch (e) {
      showError(e)
      setLoading(false);
    }
  };

  const smtpAction = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setSmtpData(data?.smtp);
      await SysConfigService.mail(smtpData);
      showMessage("Mail sent");
      setLoading(false);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await SysConfigService.fetch();
      console.log(response);
      setData(response);
      setHasErr(false);
      setLoading(false);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    setHasErr(false);
  }, []);

  if (loading) return <CardThreeBounce />;

  return (
    <>
      <Layout   title={t("System Configuration")}>
        <Layout.TbodyFull>
          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="IOS Soft Update"
                value={data?.iosSoftUpdate}
                label={"IOS Soft Update"}
                onChange={(v) =>
                  setData({
                    ...data,
                    iosSoftUpdate: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="IOS Hard Update"
                value={data?.iosHardUpdate}
                label={"IOS Hard Update"}
                onChange={(v) =>
                  setData({
                    ...data,
                    iosHardUpdate: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Android Soft Update"
                value={data?.androidSoftUpdate}
                label={"Android Soft Update"}
                onChange={(v) =>
                  setData({
                    ...data,
                    androidSoftUpdate: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Android Hard Update"
                value={data?.androidHardUpdate}
                label={"Android Hard Update"}
                onChange={(v) =>
                  setData({
                    ...data,
                    androidHardUpdate: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>
          <hr />

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Master OTP"
                value={data?.masterOtp}
                label={"Master OTP"}
                onChange={(v) =>
                  setData({
                    ...data,
                    masterOtp: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Master OTP"
                value={data?.masterOtp}
                label={"Master OTP"}
                onChange={(v) =>
                  setData({
                    ...data,
                    masterOtp: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Booking Edit Past Days"
                value={data?.pastTripEditDays}
                label={"Booking Edit Past Days"}
                onChange={(v) =>
                  setData({
                    ...data,
                    pastTripEditDays: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Server IPs"
                value={data?.serverIPs}
                label={"Server IPs"}
                onChange={(v) =>
                  setData({
                    ...data,
                    serverIPs: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Server IPs"
                value={data?.pdfAllowedIps}
                label={"Pdf API calls allow form IPs"}
                onChange={(v) =>
                  setData({
                    ...data,
                    pdfAllowedIps: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Country Code"
                value={data?.otpCountryCodes}
                label={
                  "OTP based verification for Country Codes (Comma separated)"
                }
                onChange={(v) =>
                  setData({
                    ...data,
                    otpCountryCodes: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Virtual Phone"
                value={data?.virtualPhone}
                label={"Virtual Phone for Reverser OTP verification"}
                onChange={(v) =>
                  setData({
                    ...data,
                    virtualPhone: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Currency API Key"
                value={data?.currencyAPIKey}
                label={"Currency API Key"}
                onChange={(v) =>
                  setData({
                    ...data,
                    currencyAPIKey: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="SMS India API Url"
                value={data?.smsIndiaAPI}
                label={"SMS India API Url"}
                onChange={(v) =>
                  setData({
                    ...data,
                    smsIndiaAPI: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <Checkbox
                value={data?.enablePool}
                label={"Enable Pool"}
                onClick={(v) =>
                  setData({
                    ...data,
                    enablePool: !v,
                  })
                }
                type="checkbox"
              />
            </Col>
          </Row>
          <hr />

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Primary Key"
                value={data?.primaryKey}
                label={"Primary Key"}
                onChange={(v) =>
                  setData({
                    ...data,
                    primaryKey: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Host Key"
                value={data?.hostKey}
                label={"Host Key"}
                onChange={(v) =>
                  setData({
                    ...data,
                    hostKey: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>
          <hr />

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Redis URL"
                value={data?.redisUrl}
                label={"Redis URL"}
                onChange={(v) =>
                  setData({
                    ...data,
                    redisUrl: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Redis Password"
                value={data?.redisPwd}
                label={"Redis Password"}
                onChange={(v) =>
                  setData({
                    ...data,
                    redisPwd: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>
          <hr />

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Rapid API Host"
                value={data?.rapidApiHost}
                label={"Rapid API Host"}
                onChange={(v) =>
                  setData({
                    ...data,
                    rapidApiHost: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Rapid API Key"
                value={data?.rapidApiKey}
                label={"Rapid API Key"}
                onChange={(v) =>
                  setData({
                    ...data,
                    rapidApiKey: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>
          <hr />

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Demo Phone"
                value={data?.demoPhone}
                label={"Demo Phone"}
                onChange={(v) =>
                  setData({
                    ...data,
                    demoPhone: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>
          <hr />

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="SMTP Email"
                value={data?.smtp?.smtpEmail}
                label={"Email"}
                onChange={(v) =>
                  setData({
                    ...data,
                    smtp: {
                      ...data?.smtp,
                      smtpEmail: v,
                    },
                  })
                }

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="SMTP Username"
                value={data?.smtp?.smtpUsername}
                label={"Username"}
                onChange={(v) =>
                  setData({
                    ...data,
                    smtp: {
                      ...data?.smtp,
                      smtpUsername: v,
                    },
                  })
                }

                type="text"
              />
            </Col>
          </Row>
          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="SMTP Password"
                value={data?.smtp?.smtpPassword}
                label={"Password"}
                onChange={(v) =>
                  setData({
                    ...data,
                    smtp: {
                      ...data?.smtp,
                      smtpPassword: v,
                    },
                  })
                }

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="SMTP Host"
                value={data?.smtp?.smtpHost}
                label={"Host"}
                onChange={(v) =>
                  setData({
                    ...data,
                    smtp: {
                      ...data?.smtp,
                      smtpHost: v,
                    },
                  })
                }

                type="text"
              />
            </Col>
          </Row>
          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="SMTP Type"
                value={data?.smtp?.smtpType}
                label={"Type"}
                onChange={(v) =>
                  setData({
                    ...data,
                    smtp: {
                      ...data?.smtp,
                      smtpType: v,
                    },
                  })
                }

                type="text"
              />
            </Col>
            <Col className="col-lg">
              <InputField
                placeholder="SMTP Port"
                value={data?.smtp?.smtpPort}
                label={"Port"}
                onChange={(v) =>
                  setData({
                    ...data,
                    smtp: {
                      ...data?.smtp,
                      smtpPort: v,
                    },
                  })
                }

                type="text"
              />
            </Col>
            <Col>
              <InputField
                placeholder="Email to send"
                value={data?.smtp?.email}
                label={"Email to send"}
                onChange={(v) =>
                  setData({
                    ...data,
                    smtp: {
                      ...data?.smtp,
                      email: v,
                    },
                  })
                }

                type="text"
              />
            </Col>
            <Col>
              <Button type="submit" className="btn bg-primary mt-1" onClick={smtpAction}>
                Action
              </Button>
            </Col>
          </Row>
          <hr />

          <Row className="mt-2 mb-3">
            <Col className="col-lg">
              <InputField
                placeholder="Marketing team emails comma seperated"
                value={data?.mktTeamEmails}
                label={"Marketing Team Emails"}
                onChange={(v) =>
                  setData({
                    ...data,
                    mktTeamEmails: v,
                  })
                }

                type="text"
              />
            </Col>
          </Row>
          <hr />
        </Layout.TbodyFull>
      </Layout>

      <div className="d-flex justify-content-end  ">
        <div className="me-2">
          <Button type="submit" color="primary" onClick={submitHandler}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default observer(SysConfig);
