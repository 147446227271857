import React from "react";

const Checkbox = ({
  className,
  id,
  height = 22,
  width = 22,
  label,
  desc,
  checked,
  onClick,
  isDisabled,
  style,
  leftAlign,
  noLabel,
}) => {

  return (
    <div
      style={{ ...style }}
      className={`d-flex gap-2 align-items-center w-100 ${leftAlign ? "" : "justify-content-between"} my-1 ` + className}
    >
      <input
        key={`${id}-${checked}`} // to refresh the component when the checked value changes
        onClick={onClick}
        checked={checked}
        disabled={isDisabled}
        type="checkbox"
        // className="form-check-input"
        style={{ height, width }}
        id={id}
      />
      {!noLabel && <label htmlFor={id} style={{ marginBottom: 0, minWidth: "120px", flex : 1}}>
        {label}
      </label>}

      {desc &&   <div className="flex-fill mx-2">
        {desc}
      </div>}
    </div>
  );
};
export default Checkbox;
