// import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { timeStamptoyyyy_mm_dd } from "./../../../components/index";
import {
  Button,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import {
  CardThreeBounce,
  EditLink,
  PrintLink,
  MailLink,
  DownloadLink,
  Layout,
  StatusSelect,
} from "../../../components";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import SubsInvoiceService from "./SubsInvoiceService";

const integerToDate = (str) => {
  if (str) {
    const s = `${str}`;
    const y = s.slice(0, 4);
    const m = s.slice(4, 6);
    const d = s.slice(6, 8);
    return d + "-" + m + "-" + y;
  } else return "";
};

const SubsInvoice = (props) => {
  const { t } = useContext(I18nContext);
  let { id } = useParams();
  if (props?.corpId) id = props?.corpId;
  //   const [hasErr, setHasErr] = useState(false);
  const { showMessage, showConfirm, showError } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(
    props?.corpId ? false : id
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await SubsInvoiceService.fetch(id);
      setData(res);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // to be implemented later
  const printHandler = async () => { };
  const mailHandler = async () => { };
  const downloadHandler = async (id) => {
    setLoading(true);
    try {
      await SubsInvoiceService.download(id);
      showMessage("Download successfully");
      fetchData();
    } catch (e) {
      showError(e);
    }
    fetchData();
  };

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    setShowDetailPage(
      window.location.pathname === "/supervisor/edit" || (id && !props?.corpId)
    );
  }, [id, selected_id]);

  const updateInv = async (id, st, idx, sub) => {
    setData(
      data?.map((f, x) => {
        if (idx === x) {
          return {
            ...sub,
            status: st,
          };
        }
        return f;
      })
    );
    setLoading(true);
    try {
      await SubsInvoiceService.update(id, st);
      showMessage("Status updated");
      fetchData();
    } catch (e) {
      showError(e);
    }
    fetchData();
  };

  if (loading) return <CardThreeBounce />;
  return (
    <Layout
    // showDetailPage={showDetailPage}
    //    
    //   onAddClick={() => {
    //     if (!props?.insidePane) navigate(`/supervisor/edit`);
    //     setShowDetailPage(true);
    //     setSelected_id(null);
    //   }}
    // page={SubsInvoiceService.page}
    // rows={SubsInvoiceService.rows}
    // total={SubsInvoiceService.totalRecords}
    // onPageChange={async (page, rows) => {
    //   await SubsInvoiceService.onPaginationChange(page, rows);
    // }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("Type")}</Th>
            <Th>{t("Date Created")}</Th>
            <Th>{t("Invoice Date")}</Th>

            <Th>{t("Due Date")}</Th>
            <Th>{t("For Month")}</Th>
            <Th>{t("Paid On")}</Th>
            <Th>{t("Invoice#")}</Th>
            <Th>{t("Amount")}</Th>
            <Th>{t("Status")}</Th>
            <Th className="float-end">{t("Action")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {data?.map((sub, idx) => (
            <Tr key={sub?._id}>
              {sub?.creationMode === 0 ? (
                <Button
                  className="btn btn-sm bg-warning text-white"
                  onClick={() => { }}
                >
                  Manual
                </Button>
              ) : (
                <Button
                  className="btn btn-sm bg-primary text-white"
                  onClick={() => { }}
                >
                  Trip
                </Button>
              )}
              <Td>{sub?.createdAtStr}</Td>
              <Td>{sub?.invoiceDateStr}</Td>
              <Td>{integerToDate(sub?.dueDate)}</Td>
              <Td>
                {sub?.startDate.split("-")[0] +
                  "-" +
                  sub?.startDate.split("-")[1]}
              </Td>
              <Td>{sub?.paidAt}</Td>
              <Td>{sub?.invoiceNo}</Td>
              <Td>
                {sub?.fare?.currency} {sub?.fare?.total}
              </Td>
              <Td>
                <StatusSelect dataType={sub} updateInv={updateInv} idx={idx} />
              </Td>
              <Td>
                <EditLink onClick={() => { }} />
                {/* <PrintLink
                  onClick={() => {printHandler()}}
                />
                <MailLink
                  onClick={() => {mailHandler()}}
                /> */}
                <DownloadLink
                  onClick={() => {
                    downloadHandler(sub?._id);
                  }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>
    </Layout>
  );
};

export default observer(SubsInvoice);
export { integerToDate };
