import React from 'react';
import { Tooltip } from 'react-tooltip';
import { Row } from "reactstrap";
import supervisorIcon from '../../assets/images/icons/supervisor.svg';
import driverIcon from '../../assets/images/icons/driver.svg';
import guardIcon from '../../assets/images/icons/guard.svg';
import guideIcon from '../../assets/images/icons/tour-guide.svg';
// import { LanguageSelect, SupplierSelect } from '../../components';
import { BsPersonVideo2 } from 'react-icons/bs';
import TagPicker from '../../components/TagSelector/TagPicker';
import SupplierLabel from '../../components/Dropdowns/Entity/SupplierLabel';


export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Type",
            accessor: "type",
            renderer: (dataObj) => {
                const iconsData = [
                    { condition: dataObj?.isSupervisor, text: "Supervisor", icon: supervisorIcon },
                    { condition: dataObj?.isDriver, text: "Driver", icon: driverIcon },
                    { condition: dataObj?.isGuard, text: "Guard", icon: guardIcon },
                    { condition: dataObj?.isGuide, text: "Guide", icon: guideIcon },
                    { condition: dataObj?.isSales, text: "Sales", isReactIcon: true, icon: <BsPersonVideo2 size={20} color="#7a34ff" className="me-2" /> },

                ];

                return (
                    <div className="d-flex flex-wrap">
                        {iconsData.map((item, index) => (
                            item.condition ? (
                                <div key={index}>
                                    <Tooltip style={{ zIndex: '200000' }} anchorSelect={`#not-clickable-${item.text}`}>
                                        <div>{item.text}</div>
                                    </Tooltip>
                                    <Row>
                                        <span className="col-1" id={`not-clickable-${item.text}`} data-tip={item.text} style={{ marginRight: '10px' }}>
                                            {item?.isReactIcon ? item?.icon : <img height={20} src={item.icon} alt={item.text} />}
                                        </span>
                                    </Row>
                                </div>
                            ) : (
                                <div key={index}>
                            
                                </div>
                            )
                        ))}
                    </div>

                );
            },
            show: true,
            width: 130,

        },
        {
            title: "Name",
            accessor: "name",
            show: true,
            required: true,
        },
        {
            title: "Email",
            accessor: "email",
            show: true,
            // required: true,
            renderer: (dataObj) => {
                return <p className="w-100 text-start  ">
                    {dataObj?.email ? dataObj?.email : "--"}
                </p>;
            },
        },
        {
            title: "PhoneNo",
            accessor: "phoneNo",
            renderer: (dataObj) => {
                return <p className="w-100 text-start  ">
                    +{dataObj?.phoneNo?.countryCode}-{dataObj?.phoneNo?.phone}
                </p>;
            },
            show: true,
        },

        {
            title: "Status",
            accessor: "status",
            renderer: (dataObj) => {
                return (
                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -5 }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                backgroundColor:
                                    dataObj?.status === 0 ? 'red' : dataObj?.status == 1 ? 'green' : 'blue',
                                marginRight: '5px',

                            }}
                        ></div>
                        <span>
                            {dataObj?.status === 0 ? "InActive" : dataObj?.status === 1 ? "Active" : "Blocked"}
                        </span>
                    </div>
                );

            },
            show: true,
            // required: true,
        },
        {
            title: "Compliance Status",
            accessor: "status",
            renderer: (dataObj) => {
                return (
                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -5 }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                backgroundColor:
                                    dataObj?.complianceStatus === 1 ? 'green' : 'orange',
                                marginRight: '5px',

                            }}
                        ></div>
                        <span>
                            {dataObj?.complianceStatus === 1 ? "Compliant" : "Not Compliant"}
                        </span>
                    </div>

                );

            },
            show: true,
            // required: true,
        },
        {
            title: "Tags",
            accessor: "tags",
            renderer: (dataObj) => {
                return (
                    !!dataObj?.tags && dataObj?.tags?.length > 0 ? (
                        < TagPicker
                            className="ms-3"
                            kind={"employee"}
                            onChange={(v) => { }}
                            value={dataObj?.tags}
                            onlyValues={true}
                            asLabel
                        />
                    ) : "--"
                );
            },
        },
        {
            title: "Employee Of",
            accessor: "",
            renderer: (dataObj) => {
                return <>
                    {dataObj?.employeeOf ? <SupplierLabel
                        value={dataObj?.employeeOf}
                    /> : "--"}

                </>
            },
            show: true,
            required: true,
        },

        // {
        //     title: "Language speaks",
        //     accessor: "speaks",
        //     renderer: (dataObj) => {
        //         return <div>
        //                 <LanguageSelect
        //                     asLabel
        //                     multi                       
        //                     value={dataObj?.speaks}
        //                     onChange={(v) => { }}
        //                 />
        //             </div>
        //     },        
        //     show: true,
        //     required: true,
        // },

        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
