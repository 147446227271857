import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import categoryLabelService from './CategoryLabelService';
import { ThreeBounce } from "better-react-spinkit";
import './CategoryLabel.css'; // Import the CSS file

const CategoryLabel = ({ value }) => {
  const [categoryLabel, setCategoryLabel] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategory = async () => {
      setLoading(true);
      try {
        const category = await categoryLabelService.fetchCategory(value);
        if (category && category.label) {
          setCategoryLabel(category.label);
        } else {
          setCategoryLabel('No Record Found');
        }
      } catch (error) {
        setCategoryLabel('No Record Found');
      }
      setLoading(false);
    };

    if (value) {
      fetchCategory();
    } else {
      setCategoryLabel('No Record Found');
      setLoading(false);
    }
  }, [value]);

  if (loading) {
    return <ThreeBounce size={10} color="pink" className="zr_category-loader" />;
  }

  return (
    <div className={`zr_category-label ${categoryLabel === 'No Record Found' ? 'disabled' : ''}`}>
      {categoryLabel}
    </div>
  );
};

export default observer(CategoryLabel);
