
import React, { useContext, useEffect, useRef, useState } from "react";
import { I18nContext } from "../../store/context/i18nContext";
import "./style.css";

export const DatePickerInput = (props) => {
    const {
        noLabel,
        suggestion,
        max,
        showClear,
        maxLength,
        pattern,
        identifier,
        onFocus,
        placeholder = "",
        removeBottomMargin,
        className,
        error,
        showErr,
        style,
        plain,
        inline,
        innerContainerClassName,
        onClickRight,
        onClickLeft,
        LeftComponent,
        RightComponent,
        outline,
        label,
        required,
        isReadOnly,
        isDisabled,
        hideBorder = false,
        outerClassName,
        autoFocus = false,
        onClick = () => { },
        zeroMargin
    } = props;
    const { t } = useContext(I18nContext);
    const [showClearState, setShowClearState] = useState(false);
    const inputFieldRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState(props?.value);
    const [width, setWidth] = useState(props?.value?.length);
    const [type, setType] = useState(props?.type);
    const inputRef = useRef(null);

    useEffect(() => {
        setType(props?.type);
    }, [type]);

    useEffect(() => {

        if (showClear) {
            setShowClearState(value ? true : false);
        }
    }, [value]);

    useEffect(() => {
        if (props?.value !== value) {
            setValue(props?.value);
        }

        if (showClear) {
            setShowClearState(props?.value ? true : false);
        }
    }, [props?.value]);

    const changeHandler = (evt) => {
        setWidth(evt.target.value.length);
    };

    const handleOnChange = (e) => {
        const event = {
            ...e,
            target: {
                ...e.target,
                value: (e.target.value ?? "").replace(".", ":")
            }
        }
        if (props?.onChange) props?.onChange(event)
    }

    return (
        <section
            onClick={onClick}
            style={{ ...style }}
            className={` ${!(plain || outline || inline)
                ? "d-flex justify-content-center align-items-center "
                : " "
                } inputfield__container  ${className} ${!(error && showErr) ? removeBottomMargin : " " ? "mb-3" : ""
                }`}
        >
            {suggestion && <b className="inputfield__suggestion">{suggestion}</b>}

            {!(inline || plain || outline) &&
                (noLabel !== false ? (
                    <div style={{ width: !!zeroMargin ? "fit-content" : "120px", fontSize: 12, fontWeight: "500", marginRight: !!zeroMargin ? 5 : 0 }}>
                        {label}
                        {required && <font color="red">*</font>}
                    </div>
                ) : null)}

            <div
                ref={inputFieldRef}
                className={
                    "flex-grow-1 " +
                    (plain && !isFocused ? "inputfield__plain " : "")
                    +
                    (outline
                        ? " inputfield__innercontainer_outline "
                        : inline
                            ? " inputfield__innerInline "
                            : plain
                                ? " "
                                : hideBorder
                                    ? "inputfield__innercontainer_noborder"
                                    : "inputfield__innercontainer ") +
                    (innerContainerClassName ? innerContainerClassName : "")
                }
                style={inline ? { height: "28px", backgroundColor: isDisabled ? "rgb(242, 242, 242)" : "", ...style } : { backgroundColor: isDisabled ? "rgb(242, 242, 242)" : "", ...style }}
            >
                {/* Left Component */}
                {LeftComponent && (
                    <div
                        onClick={(e) => (onClickLeft ? onClickLeft(e) : null)}
                        className={
                            onClickLeft
                                ? "component_pointer inputfield__leftComponent"
                                : "inputfield__leftComponent"
                        }
                    >
                        <props.LeftComponent />
                    </div>
                )}

                <div
                    className={inline ? "input-group" : `inputfield ps-2 ${outerClassName}`}
                    style={inline ? { height: "28px" } : {}}
                >
                    {inline && (
                        <div
                            style={{ height: "27px", fontSize: 12 }}
                            className="input-group-text"
                        >
                            {t(label)}
                        </div>
                    )}
                    <input
                        ref={inputRef}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                                handleOnChange(e)
                            }
                        }}
                        disabled={isDisabled}
                        readOnly={isReadOnly}
                        autoFocus={autoFocus}
                        maxLength={maxLength ? maxLength : "100"}
                        pattern={pattern}
                        id={`custom-input-${label}-${value}-${identifier}`}
                        name={props?.name}
                        max={max}
                        type={type}
                        required={props?.required}
                        onFocus={() => {
                            if (onFocus) onFocus();
                            setIsFocused(true);
                        }}
                        onBlur={(e) => {
                            handleOnChange(e)
                            setIsFocused(false);
                        }}
                        onChange={(e) => {
                            setValue(e.target.value);
                            if (inline) changeHandler(e);
                        }}
                        style={
                            inline
                                ? {
                                    ...style,
                                    minWidth: "40px",
                                    height: "27px",
                                    paddingLeft: "3px",
                                    paddingRight: "3px",
                                    minWidth: "100px",
                                    width:
                                        type === "text" || type === "email"
                                            ? width + 0.5 + "ch"
                                            : "auto",
                                    maxWidth:
                                        type === "text" || type === "email" ? 18 + "ch" : "auto",
                                }
                                : { ...style }
                        }
                        value={value?.toString() ? value : ""}
                        className={
                            inline ? "form-control rounded-right-1" : "inputfield__input"
                        }
                        placeholder={`${placeholder && (isFocused || noLabel)
                            ? t(placeholder)
                            : !(outline || plain)
                                ? placeholder
                                    ? t(placeholder)
                                    : ""
                                : " "
                            }`}
                    />

                    {(plain || outline) && <Label {...props} />}
                </div>

                {/* Right Component */}
                {RightComponent && (
                    <div
                        onClick={(e) => (onClickRight ? onClickRight(e) : null)}
                        style={{ height: "32px" }}
                        className={
                            onClickRight
                                ? "component_pointer inputfield__rightComponent"
                                : "inputfield__rightComponent"
                        }
                    >
                        <props.RightComponent {...props} />
                    </div>
                )}
            </div>

            {/* Error Line */}
            {error && showErr && (
                <em className="inputfield__error">
                    {error ? error : "Please enter Something"}
                </em>
            )}
        </section>
    );
};



const Label = ({ label, required, identifier, value, labelStyle }) => {
    const { t } = useContext(I18nContext);
    return (
        <label
            onClick={() => {
                document
                    .getElementById(`custom-input-${label}-${value}-${identifier}`)
                    ?.focus();
            }}
            style={{ ...labelStyle }}
            className="inputfield__label px-1"
        >
            {t(label)}
            {required && <font color="#EC734B">*</font>}
        </label>
    );
};
