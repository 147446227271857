
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { CitySelect, CountrySelect, InputField } from "../../../../components";






export default function Profile({ data, setData }) {

    return (
        <div className="active ">
            <Row className="mt-3 mb-3 ">
                <Col className="col-lg">
                    <InputField
                        placeholder="Name"
                        value={data?.name}
                        required={true}
                        label={"Name"}
                        onChange={(v) => setData({ ...data, name: v })}
                        error={
                            data?.name?.length === 0
                                ? "Please enter office name"
                                : ""
                        }

                        type="text"

                    />
                </Col>


            </Row>
            <Row className="mt-3 mb-3">
                <Col className="col-lg">
                    <CountrySelect
                        value={data?.country}

                        onChange={(v) =>
                            setData({
                                ...data,
                                country: v.value,
                            })
                        }
                    />
                </Col>
                <Col className="col-lg">
                    <CitySelect
                        value={data?.city}

                        onChange={(v) =>
                            setData({
                                ...data,
                                city: v.value

                            })
                        }
                        country={data?.country}

                    />
                </Col>


            </Row>






        </div>
    );
}
