import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { DateSelect, DeleteButton, InputField, ModalComponent, SaveButton, TimeSelect } from "../../components/index";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import EmployeeAssigner from "../Dropdowns/EmployeeSelect/EmployeeAssigner";
import { generateUniqueId } from "../dnd/board/helper";
import SpaceService from "../dnd/listView/SpaceService";
import ReminderService from "./ReminderService";
import DueDateDropdown from "../DueDateDropdown/DueDateDropdown";
import moment from "moment";
import { Checkbox } from "../../components/index";
import Editor from "../dnd/forms/itinerary/components/RichTextEditor/Editor/Editor";

const startingData = {

}

const ReminderForm = ({
    onEdit = () => { },
    editId,
    leadId,
    spaceId,
    isOpen,
    toggleModal,
}) => {

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState(startingData);

    const fetch = async () => {
        try {
            setLoading(true)
            let itineraryDetail = await ReminderService.get(editId);
            setData(itineraryDetail);
            setLoading(false)
        } catch (error) {
            showError(error)
            setLoading(false)

        }
    };

    const onSave = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            if (data?._id) {
                const lead = await ReminderService.edit(data, data?._id);
                setData(lead)
                setLoading(false);
                showMessage(t("Reminder updated successfully."));
                onEdit()
            } else {
                const lead = await ReminderService.save({
                    ...data,
                    lead_id: leadId,
                    space_id: SpaceService.selectedSpace?._id,
                });
                setData(lead)
                setLoading(false);
                showMessage(t("Reminder saved successfully."));
                onEdit()
            }
        } catch (e) {
            setLoading(false);
            showError(e);
        }
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await ReminderService.delete(id);
                setLoading(false);
                showMessage("Reminder Deleted SuccessFully", "Deleted");
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };

    useEffect(() => {
        console.log({ isOpen, editId })
        if (isOpen) {
            setLoading(true);
            if (editId) {
                fetch();
            } else {
                setData(startingData);
            }
            setLoading(false)
        }
    }, [editId, isOpen]);



    return (
        <React.Fragment>
            <ModalComponent
                position={"top"}
                size={"medium"}
                isOpen={isOpen}
                onToggle={toggleModal}
            >

                <ModalComponent.Title>
                    <div className="d-flex justify-content-between">
                        <div style={{ fontSize: "12px", }} className="d-flex align-items-center">
                            <div className="me-3">Reminder</div>

                            {/* <DueDateDropdown
                            value={data?.actionDate}
                            tooltipId={generateUniqueId()}
                            onChange={(v) => {
                                setData({
                                    ...data,
                                    actionDate: v
                                });
                            }}
                        />

                        <b className="mt-1 ms-2">
                            {data?.actionDate ? `Due Date - ${moment(data?.actionDate, 'YYYYMMDD').format("DD-MM-YYYY")}` : ""}
                        </b> */}
                            <Checkbox
                                // label={t("Completed")}\
                                noLabel
                                checked={data?.completed == 1}
                                className="col-sm-4"
                                onClick={() => {
                                    setData({
                                        ...data,
                                        completed: data?.completed == 1 ? 0 : 1
                                    })
                                }}
                            />
                        </div>


                        <EmployeeAssigner
                            className="me-5"
                            tooltipId={generateUniqueId()}
                            value={data?.assignedTo}
                            onChange={(v) => {
                                setData({ ...data, assignedTo: v })
                            }}
                        />
                    </div>

                </ModalComponent.Title>

                <ModalComponent.Body>
                    <div className="">

                        <div className="row">
                            <InputField
                                className="col-sm-4"
                                value={data?.action}
                                outline
                                label="Action"
                                onChange={(v) => {
                                    setData({ ...data, action: v })
                                }}
                            />
                            <DateSelect
                                placeholder={t("Date ")}
                                value={data?.actionDate}
                                label={t("Action Date")}
                                outline
                                onChange={(v) => {
                                    setData({ ...data, actionDate: v })

                                }}
                                type="text"
                                className="col-sm-4"
                            />

                            <TimeSelect
                                className="col-sm-4"
                                label={t("Action Time")}
                                value={data?.actionTime}
                                outline
                                onChange={(v) =>
                                    setData({
                                        ...data,
                                        actionTime: v,
                                    })
                                }
                            />

                            {/* <Checkbox
                                label={t("Completed")}
                                checked={data?.completed == 1}
                                className="col-sm-4"
                                onClick={() => {
                                    setData({
                                        ...data,
                                        completed: data?.completed == 1 ? 0 : 1
                                    })
                                }}
                            /> */}
                        </div>



                        <Editor
                            label="Notes"
                            placeholder="Notes"
                            value={data?.note}
                            onChange={(e) => {
                                setData({ ...data, note: e })
                            }}

                        />

                        {/* <textarea
                            label="Notes"
                            className="col-6 inputfield mt-0"
                            style={{ width: "100%", borderRadius: "0.375rem", outline: "none", border: "1px solid #ced4da", }}
                            rows="5"
                            placeholder="Notes"
                            value={data?.note}
                            onChange={(e) => {
                                setData({ ...data, note: e.target.value, })
                            }}
                            type="text"
                        ></textarea> */}

                    </div>


                </ModalComponent.Body>

                <ModalComponent.Footer>
                    <div className="d-flex justify-content-end">
                        <DeleteButton onClick={(e) => onDelete(e, editId)} loading={loading} />
                        <SaveButton loading={loading} onClick={onSave} disabled={loading} />

                    </div>
                </ModalComponent.Footer>

            </ModalComponent>



        </React.Fragment >
    );
};

export default observer(ReminderForm);
