import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import CustomerSelect from '../../components/Dropdowns/CustomerSelect/CustomerSelect';
import RemoveLink from '../../components/Buttons/RemoveLink';
import AddLink from '../../components/Buttons/AddLink';

const PassengerSelect = ({ passengers, onChange, space_id, label }) => {

    const handleRemovePassenger = (index) => {
        const updatedPassengers = passengers.filter((_, i) => i !== index);
        onChange(updatedPassengers);
    };

    const addPassenger = () => {
        const pax = {
            index: passengers?.length ?? 0,
            salutation: "Mr",
            phone: {
                countryCode: 91,
            }
        };
        const updatedPassengers = passengers ? [...passengers, pax] : [pax];
        onChange(updatedPassengers);
    };

    useEffect(() => {
        if (!passengers?.length) {
            addPassenger()
        }
    }, [passengers?.length])
    
    return (
        <>
            {(passengers || []).map((passenger, index) => (
                <div className='p-1 pt-1 d-flex align-items-center flex-wrap' key={index}>
                    <div className='col-1 d-flex align-items-center' style={{ width: 50 }}>
                        <FaPersonWalkingLuggage size={24} />
                        <h5 className="text-center mt-2 ms-2" style={{ fontWeight: "bold" }}>
                            {index + 1}.
                        </h5>
                    </div>

                    {
                        <div className="flex-grow-1">
                            <CustomerSelect
                                space_id={space_id}
                                label={label}
                                outline
                                value={passenger?.customer_id}
                                onChange={(v) => {
                                    const updatedPassengers = passengers.map((p, i) => (
                                        i === index ? { ...v, customer_id: v?._id, phone: v?.phoneNo ?? {} } : p
                                    ));
                                    onChange(updatedPassengers);
                                }}
                                className="col-sm-12"
                            />
                        </div>
                    }

                    <div className='d-flex align-items-center position-relative'>
                        {passengers?.length !== 1 && (
                            <RemoveLink className="ms-1" onClick={() => handleRemovePassenger(index)}/>
                        )}
                        {index === passengers?.length - 1 ? (
                            <AddLink onClick={addPassenger} size={20}/>
                        ) : <div style={{ width: "26px" }}></div>}



                    </div>
                </div>
            ))}
        </>
    );
};

export default observer(PassengerSelect);

