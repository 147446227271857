import moment from 'moment';
import React from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import AttachmentItem from './AttachmentItem';
import './DisplayEmail.scss';

const DisplayEmail = ({ email, onBack = () => { } }) => {

    return (
        <div className="zr_communication_email_display">
            <div className="zr_communication_email_header">
                <h2 className='d-flex align-items-center'>
                    <IoMdArrowBack onClick={onBack} className='me-2' size={20} />
                    {email.subject}
                </h2>
                <span className="zr_communication_email_date">
                    {moment(email.sentAt).format('MMMM Do YYYY, h:mm:ss a')}
                </span>
            </div>


            <div className="zr_communication_email_content">

                <div className="zr_communication_email_body" dangerouslySetInnerHTML={{ __html: email.content }}></div>
                {email.attachment?.urls?.length > 0 && (
                    <div className="zr_communication_email_attachments">
                        <strong>Attachments:</strong>
                        <ul>
                            {email.attachment.urls.map((file, index) => (
                                <li key={index}>
                                    <AttachmentItem file={file} index={index} />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}


            </div>

        </div>
    );
};

export default DisplayEmail;
