
import { observer } from "mobx-react-lite";
import React, {  useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  Card, CardBody, Col, Form, Row } from "reactstrap";
import {
  CardThreeBounce,
} from "../../components";
import UserMasterService from "./UserMasterService";

const EditUserMaster = (props) => {
  const {data,setData}=props
  const { id } = props;
  const [freshData, setFreshData] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasErr, setHasErr] = useState(false);
  const navigate = useNavigate();

  async function fetch() {
    if (id) {
      const user = await UserMasterService.get(id);
      setData(user);
    } else {
      setData({ name: "" })
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);

    fetch();
    setHasErr(false);
  }, [id]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(freshData)) {
      props?.onChange(true);
    } else {
      props?.onChange(false);
    }
  }, [data]);
  if (loading) return <CardThreeBounce />;
  return (
    <React.Fragment>
      {(id && !loading) || !id ? (
      <div className="p-3">
        <div>
          <Row>
            <Col>
              <Card>
                <CardBody className="p-3"> 
                <Form>
                  <div>
                  <Row> Phone : {data?.countryCode} - {data?.phone}</Row>
                  <Row> Gender : {data?.gender===1?"Female":"Male"}</Row>
                   <Row> TimeZone : {data?.timeZone}</Row>
                   <Row> Base URL : {data?.base_url}</Row>
                   <Row> Country : {data?.address?.country}</Row>
                   <Row> State : {data?.address?.country}</Row>
                   <Row>  City :{data?.address?.city}</Row> 
                   <Row> Address Line : {data?.address?.addressLine}
                  </Row>
                  </div>
                  </Form>
              </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      ) : (
        <CardThreeBounce />
        )}
    </React.Fragment>
  );
};

export default observer(EditUserMaster);

