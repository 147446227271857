import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { InputField, StateSelect, CountrySelect } from "../../components";
import RemoveLink from "../../components/Buttons/RemoveLink";
import AddLink from "../../components/Buttons/AddLink";

const LocationEntry = ({ entries, addEntry, removeEntry, onChange, t, hasErr }) => {
  const renderRow = (data, index) => (
    <Row key={index} className="align-items-center">
      <Col className="col-2">
        <CountrySelect
          value={data?.country || ""}
          label={t("Country")}
          outline
          className="col-sm-12"
          error={data?.country?.length === 0 ? t("Please enter City country") : ""}
          showErr={hasErr}
          onChange={(v) => onChange(index, { ...data, country: v?.value })}
        />
      </Col>
      <Col className="col-2">
        <StateSelect
          placeholder={t("State")}
          country={data?.country || ""}
          value={data?.state || ""}
          outline
          label={t("State")}
          onChange={(v) => onChange(index, { ...data, state: v?.value })}
          className="col-sm-12"
        />
      </Col>
      <Col className="col-2">
        <InputField
          placeholder={t("City")}
          value={data?.city || ""}
          required
          outline
          label={t("City")}
          onChange={(v) => onChange(index, { ...data, city: v })}
          error={data?.city?.length === 0 ? t("Please enter City name") : ""}
          showErr={hasErr}
          type="text"
        />
      </Col>
      <Col className="col-3">
        <InputField
          placeholder={t("Address")}
          value={data?.addressLine || ""}
          required
          outline
          label={t("Address")}
          onChange={(v) => onChange(index, { ...data, addressLine: v })}
          error={data?.addressLine?.length === 0 ? t("Please enter Address") : ""}
          showErr={hasErr}
          type="text"
        />
      </Col>
      <Col className="col-custom">
        <InputField
          placeholder={t("Lat")}
          value={data?.lat || ""}
          required
          outline
          label={t("Lat")}
          onChange={(v) => onChange(index, { ...data, lat: v })}
          error={data?.lat?.length === 0 ? t("Please enter Latitude") : ""}
          showErr={hasErr}
          type="number"
        />
      </Col>
      <Col className="col-custom">
        <InputField
          placeholder={t("Lon")}
          outline
          value={data?.lng || ""}
          required
          label={t("Lon")}
          onChange={(v) => onChange(index, { ...data, lng: v })}
          error={data?.lng?.length === 0 ? t("Please enter Longitude") : ""}
          showErr={hasErr}
          type="number"
        />
      </Col>
      {entries.length > 0 && (
        <Col className="d-flex align-items-center">
          {(entries?.length != 1) && <RemoveLink
            onClick={() => removeEntry(index)}
            className="ms-2"
            style={{ cursor: 'pointer' }}
          />}
          {index === (entries?.length - 1) && (
            <AddLink className="ms-2" size={20} onClick={addEntry} />
          )}
        </Col>
      )}
    </Row>
  );

  return (
    <>
      {entries.length === 0 ? (
        <Row>
          {renderRow({}, 0)}
          <Col className="col-1 d-flex align-items-center">
            <AddLink className="ms-2" size={20} onClick={addEntry} />
          </Col>
        </Row>
      ) : (
        entries.map((data=[{}], index) => renderRow(data, index))
      )}
    </>
  );
};

export default LocationEntry;
