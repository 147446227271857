export const RateConstants = {
    grid: (page, sizePerPage, filterUrl) => {
        const queryParams = new URLSearchParams();
        if (sizePerPage) {
            queryParams.append('rows', sizePerPage);
        }
        if (page) {
            queryParams.append('page', page);
        }
        if (filterUrl) {
            // Assuming filterUrl is a string of additional query parameters
            // such as "sort=asc&category=books"
            filterUrl.split('&').forEach(param => {
                const [key, value] = param.split('=');
                if (key && value) {
                    queryParams.append(key, value);
                }
            });
        }
        return `/api/rate/grid?withExtn=true&${queryParams.toString()}`;
    },

}