import React, { useState, useEffect, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { formatNumberToCurrency } from '../../../../helpers/general';
import moment from 'moment';
import { doPOST } from '../../../../util/HttpUtil';
import { DialogContext } from '../../../../store/context/DialogContext';
import { getYYYYMMDDToDateString } from '../../../../util/Util';

const CustomerHistory = ({ userData, gridLoading, customerId }) => {
  const [tripData, setTripData] = useState([])
  const { showError } = useContext(DialogContext)

  const getOneYearFilter = () => {
    let today = parseInt(moment().format('YYYYMMDD'), 10);
    let oneYearback = parseInt(moment().subtract(1, 'year').startOf('month').format('YYYYMMDD'), 10);
    return [oneYearback, today];

  }

  const totalRevenue = (data) => {
    const totalSum = data?.reduce((sum, item) => sum + item.total_fee, 0);
    return totalSum;
  }

  const fetchData = async (customerId) => {
    try {
      let response = await doPOST("/api/trip/customer/statistics", {
        "btwFilters": {
          "tripDate": getOneYearFilter()
        },
        "filters": {
          "passengers.customer_id": customerId
        },
        "inFilters": {
          "status": [5, 19, 20, 29, 30]
        }
      });
      setTripData(response?.data);

    } catch (error) {
      showError(error)
    }
    finally {
    }
  }

  useEffect(() => {
    fetchData(customerId)
  }, [customerId])

  const detailArray = [
    { header: "LifeLine Value", value: (formatNumberToCurrency((totalRevenue(tripData) / 100))) + " INR" ?? 0 },
    { header: "Total Order Count", value: tripData?.length ?? 0 },
    {
      header: "Customer Since",
      value: moment(userData?.joinDate, 'YYYYMMDD').isValid() ? moment(userData?.joinDate, 'YYYYMMDD').format('DD MMM YYYY') : '--'
    },
    { header: "Customer Repeat", value: tripData?.length > 1 ? "Repeat" : "Applied" }
  ];

  return (
    <>
      <div className="row d-flex align-items-center col-12 justify-content-between ms-2">
        <div className="col-6 pt-2 pb-2">
          <DetailLabel data={detailArray[0]} gridLoading={gridLoading} />
        </div>
        <div className="col-6 row d-flex  pt-2 pb-2">
          <DetailLabel data={detailArray[1]} gridLoading={gridLoading} />
        </div>
      </div>
      <div className="row d-flex align-items-center col-12 justify-content-between ms-2">
        <div className="col-6 pt-2 pb-2">
          <DetailLabel data={detailArray[2]} gridLoading={gridLoading} />
        </div>
        <div className="col-6 pt-2 row d-flex  pb-2">
          <DetailLabel data={detailArray[3]} gridLoading={gridLoading} />
        </div>
      </div>
    </>
  );
}

const DetailLabel = ({ gridLoading, data }) => {
  return (
    <div className="d-flex flex-column">
      <div className='text-light_blue font-size-12'>
        {data?.header}
      </div>
      <div className='text-light_blue font-size-12  fw-semibold'>
        {gridLoading ? <Skeleton height={20} width={60} /> : data?.value}
      </div>
    </div>
  );
}

export default CustomerHistory;
