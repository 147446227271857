export const ENDPOINTS = {
    customerGrid: (filterUrl) => {
        let url = `/api/customer/grid?`;

        if (filterUrl) {
            url += `${filterUrl}`
        }
        return url;
    },

    getCustomer: (id) => `/api/customer/${id}/detail`,
    createCustomer: `/api/customer/create`
};