import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteLink,
  EditLink,
  Layout,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditTemplates from "./EditTemplate";
import { STRUCTURE } from "./TemplateConstant";
import { GridColumns } from "./TemplateGridColumns";
import Templateservice from "./TemplateService";
import { UserContext } from "../../store/context/UserContext";

const Templates = ({ value, insidePane, multiMode, onSelect = () => { } }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/templates/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await Templateservice.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])

  }, [])

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await Templateservice.delete(id);
        setLoading(false)
        setShowDetailPage(false)
        showMessage(t("Template Deleted Successfully"));
        navigate(`/templates`);
      } catch (e) {
        setLoading(false)
        showError(e);
      }
    }
  };

  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await Templateservice.bulkDelete(selectedIDs);
        showMessage(t("Deleted"), t("Deleted Successfully"));
      } catch (e) {
        showError(e);
      }
    }
  };

  useEffect(() => {
    Templateservice?.fetch()
  }, [Templateservice?.version])

  return (
    <>
      <Layout
        showDetailPage={showDetailPage}
        large
        hideAdd={!userContent?.rights?.includes(2401)}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/templates");
          setEditID(null);
        }}
         
        title={t("Templates")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/templates/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={Templateservice.page}
        rows={Templateservice.rows}
        total={Templateservice.total}
        hideheader={true}
          hidefooter={true}
        fetch={Templateservice.fetch}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> {t("Download")} </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>

        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={Templateservice.records}
            total={Templateservice.total}
            uiPreference="template.grid"
            headers={GridColumns}
            selectedIDs={selectedIDs}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            page={Templateservice.page}
            rowsPerPage={Templateservice.rowsPerPage}
            onPaginationChange={Templateservice.onPaginationChange}
            renderLastCol={(template) => {
              return (
                <>
                  {userContent?.rights?.includes(2402) &&
                    <EditLink
                      onClick={() => {
                        if (!insidePane)
                          navigate(`/templates/edit/${template?._id}`);
                        setEditID(template?._id);
                        setShowDetailPage(true);
                      }}
                    />
                  }
                  {userContent?.rights?.includes(2403) ?
                    <DeleteLink
                      onClick={(event) => onDelete(event, template?._id)}
                    />
                    : null}

                  {/* <DeleteLink
                    onClick={(event) => onDelete(event, template?._id)}
                  /> */}

                  {!multiMode && insidePane ? (
                    <CheckButton
                      onClick={() => {
                        onSelect(template?._id);
                      }}
                    />
                  ) : null}
                </>
              );
            }}
          />
        </Layout.Table>
        <Layout.DetailPageBody>
          <EditTemplates
            onSave={()=>{fetchData(filterURL)}}
            onDelete={()=>{
              setShowDetailPage(false)
              fetchData(filterURL)}}
            insidePane={insidePane}
            onBack ={()=>{
              setShowDetailPage(false);
              if (!insidePane) navigate("/flight");
              setEditID(null);
            }}
            editId={editID}

          />
        </Layout.DetailPageBody>
      </Layout>
    </>
  );
};
export default observer(Templates);
