import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { IoIosSend } from 'react-icons/io';
import { Button } from 'reactstrap';
import { I18nContext } from '../../store/context/i18nContext';
import './Communication.scss';
import Communication from './Communication';
import SidebarModal from '../../components/HorizontalLayout/SidebarModal';

const CommunicationButton = ({ tour_id, trip_id, flights = [], trips = [], stays = [], customers = [], bookers = [] }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useContext(I18nContext);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className="zr_communication">
            <div className="zr_communication_send">
                <Button
                    onClick={toggleModal}
                    className="d-flex align-items-center"
                    style={{ height: "28px", padding: "2px 10px 2px 4px" }}
                    color="info"
                >
                    <IoIosSend className="me-2" style={{ fontSize: '20px' }} />
                    <span>{t(`Send Message`)}</span>
                </Button>
            </div>
            {isModalOpen && (
                <SidebarModal
                    isOpen={isModalOpen}
                    toggleModal={toggleModal}
                >
                    <Communication
                        tour_id={tour_id}
                        trip_id={trip_id}
                        isOpen={isModalOpen}
                        toggle={toggleModal}
                        flights={flights}
                        trips={trips}
                        stays={stays}
                        customers={customers}
                        bookers={bookers}
                    />
                </SidebarModal>
            )}
        </div>
    );
};

export default observer(CommunicationButton);
