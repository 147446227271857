import { Fragment, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { PropTypes } from "prop-types";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const ReactDropZone = (props) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: props?.maxFiles ? props?.maxFiles : 1,
    multiple: props?.multiple ? props?.multiple : false,
    accept: {
      "xlsx/*, .xls/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    if (props?.onchange) {
      props?.onchange(files);
    }
  }, [files]);

  const thumbs = files.map((file) => (
    <div className="d-flex" key={file.name}>
      {props?.showFileDetails && (
        <div>
          <p>File Name: {file.name}</p>
          {/* <p>Filet Type: {file.type}</p>
          <p>Size in Bytes: {file.size}</p>
          <p>
            Last Modified Date: {file.lastModifiedDate.toLocaleDateString()}
          </p> */}
        </div>
      )}
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <Fragment>
      <section className="container">
        <div className="dropzone">
          <div {...getRootProps({ className: "dz-message needsclick" })}>
            <input {...getInputProps({ accept: ".xlsx, .xls" })} />
            <div className="mb-3">
              <i className="display-4 text-muted uil uil-cloud-upload" />
            </div>
            <h4>Drop files here or click to upload.</h4>
          </div>
        </div>
        {props?.preview && <aside style={thumbsContainer}>{thumbs}</aside>}
      </section>
    </Fragment>
  );
};

ReactDropZone.propTypes = {
  preview: PropTypes.bool,
  showFileDetails: PropTypes.bool,
  onchange: PropTypes.func,
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
};
export default ReactDropZone;
