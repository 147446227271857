import moment from "moment";
import { Tooltip } from 'react-tooltip';


export const GridColumns = {
  version: 1,
  columns: [

    {
      title: "Destination",
      accessor: "destination",
      renderer: (dataObj) => {
        const phoneDetails = dataObj?.phone;
        return <div>
          {`+${phoneDetails?.countryCode}-${phoneDetails?.phone}`}
        </div>;
      },
      show: true,
    },
    {
      title: "Content",
      // accessor: "content",
      renderer: (dataObj) => {
        const truncateString = (inputString, maxLength) => {
          return inputString.length <= maxLength ? inputString : inputString.slice(0, maxLength) + "...";
        }

        return <div>
          {dataObj?.content?.length > 50 ?
            <div className="row">

              <Tooltip noArrow style={{
                zIndex: 999999,
                maxWidth: '300px',
                height: "auto",
                backgroundColor: '#F2F2F2',
                color: "black",
                whiteSpace: 'pre-wrap',
                overflow: 'auto',
                fontWeight: 'bold'
              }} anchorSelect={`#not-clickable-${dataObj?._id}`}>
                {dataObj?.content}
              </Tooltip>
              <div id={`not-clickable-${dataObj?._id}`}>
                {truncateString(dataObj?.content, 50)}
              </div>
            </div> :
            dataObj?.content
          }
        </div >
      },
      show: true,
    },

    {
      title: "Time",
      renderer: (dataObj) => {
        const timeStr = dataObj?.createdAt?.toString();
        const formattedTime = moment(timeStr, 'Hmm').format('hh:mm A')
        return <div>
          {formattedTime}
        </div>;
      },
      show: true,
    },
  ],
};
