import React from 'react'
import RadioSelection from '../../../components/RadioSelection'
import ToggleCardSelection from '../../RadioSelection/ToggleCardSelection'

const data = [
    { name: 'Event', value: 0 },
    { name: 'Roster', value: 'roster' },
    { name: 'Group Booking', value: 1 },
]

function SpaceTypeSelection(props) {
    return (
        <div className='mb-2'>
            <ToggleCardSelection {...props} data={data} label={""} />
        </div>
    )
}

export default SpaceTypeSelection