import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu, Alert } from "reactstrap";
import { Button } from 'react-bootstrap'
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./ClientPoolConstant";
import { GridColumns } from "./ClientPoolGridColumns";
import ClientPoolService from "./ClientPoolService";
import EditClientPools from "./EditClientPool";
import { UserContext } from "../../store/context/UserContext";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";



const ClientPools = ({ value, insidePane, multiMode, onSelect = () => { } }) => {

    let { edit_id } = useParams();
    const location = useLocation();
    const { userContent } = useContext(UserContext);


    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(edit_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/client-pool/create" || edit_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)

    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await ClientPoolService.fetch(filterUrl);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])

    }, [ClientPoolService?.version])

    useEffect(() => {
        setEditID(edit_id)
    }, [edit_id,])

    const onSave = async (e) => {
        e.preventDefault();
        if (!detailData?.name) {
            setHasErr(true)
            showError(t("Please enter all fields"));
            return;
        }
        setLoading(true);
        try {
            if (editID) {
                await ClientPoolService.edit(detailData, editID);

                showMessage(t("Client Group updated successfully."));
            } else {
                const clientPoolID = await ClientPoolService.save(detailData);
                if (clientPoolID) setEditID(clientPoolID)
                showMessage(t("Client Group saved successfully."));
                if (!insidePane) navigate(`/client-pool/edit/${clientPoolID}`);
            }
            fetchData(filterURL);
        } catch (e) {
            showError(e);
        }
        setHasErr(false)
        setLoading(false);
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            try {
                await ClientPoolService.delete(id);
                showMessage("Client Group Deleted Successfully", "Deleted");
                setShowDetailPage(false);
                navigate(`/client-pool`);
            } catch (e) {
                showError(e);
            }
        }
    };

    const renderLastCol = useCallback((clientPool) => {
        return (
            <>
                <EditLink
                    onClick={() => {
                        if (!insidePane)
                            navigate(`/client-pool/edit/${clientPool?._id}`);
                        setShowDetailPage(true);
                        setEditID(clientPool?._id);

                    }}
                />
                {userContent?.rights?.includes(1503) ?
                    <DeleteLink
                        onClick={(event) => onDelete(event, clientPool?._id)}
                    />
                    : null}


                {!multiMode && insidePane ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(clientPool?._id);
                        }}
                    />
                ) : null}
            </>
        );

    })



    const toggleData = {
        module: [
            { label: t("Clients"), value: "client" },
            { label: t("Client Group"), value: "client-pool" },
        ],
        toggleValue: "client-pool"
    }

    const markCompletedFunc = async () => {
        try {
            await SetupProgressService?.edit("clientPool");
        } catch (error) {

        }
    }

    useEffect(() => {
        if (!SetupProgressService?.records) {
            SetupProgressService.fetch()
        }
    }
        , [SetupProgressService?.records])

    return (
        <>
            <Layout
                toggleData={toggleData}
                showToggle
                compact
                onToggle={(selectedValue) => {
                    if (selectedValue === "client") {
                        navigate(`/client`);
                    } else if (selectedValue === "client-pool") {
                        navigate(`/client-pool`);
                    }
                }}
                hideAdd={!userContent?.rights?.includes(1501)}
                showDetailPage={showDetailPage}
                backDetailPage={() => {
                    setShowDetailPage(false);
                    if (!insidePane) navigate("/client-pool");
                    setEditID(null);
                }}
                 
                // title={t("Client Pools")}
                filterValues={filterObject}
                filterStructure={STRUCTURE}
                onApplyFilter={fetchData}
                onAddClick={() => {
                    if (!insidePane) navigate(`/client-pool/create`);
                    setShowDetailPage(true);
                    setEditID(null);
                }}
                insidePane={insidePane}
                page={ClientPoolService.page}
                rows={ClientPoolService.rows}
                total={ClientPoolService.total}
                fetch={ClientPoolService.fetch}
            >

                <Layout.OnAlert>
                    {!SetupProgressService?.records?.setUpStatus?.['clientPool'] ? <Alert color="danger" role="alert">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                Please create client groups for which you are creating common rates here.
                            </div>
                            <div>
                                <Button onClick={markCompletedFunc}>Mark Complete</Button>
                            </div>
                        </div>          </Alert> : <></>}
                </Layout.OnAlert>
                <Layout.ActionMenu>
                    <div className="layout-action-menu">
                        <DropdownMenu>
                            <>
                                {userContent?.rights?.includes(1503) ?
                                <>
                                    <DropdownItem>Bulk Delete</DropdownItem>
                                    <div class="dropdown-divider"></div>
                                    </>
                                    : null}
                                <DropdownItem>{t("Upload")}</DropdownItem>
                                <DropdownItem> Download </DropdownItem>
                            </>
                        </DropdownMenu>
                    </div>
                </Layout.ActionMenu>
                <Layout.Table>
                    <DataGrid
              gridLoading={loading}
                        data={ClientPoolService.records}
                        total={ClientPoolService.total}
                        uiPreference="clientPool.grid"
                        headers={GridColumns}
                        selectedIDs={selectedIDs}
                        onSelectChange={(v) => {
                            onSelect(v)
                            setSelectedIDs(v)
                        }}
                        page={ClientPoolService.page}
                        rowsPerPage={ClientPoolService.rowsPerPage}
                        onPaginationChange={ClientPoolService.onPaginationChange}
                        renderLastCol={renderLastCol}
                    />
                </Layout.Table>

                <Layout.DetailPageTitle>
                    {detailData?._id ? t("Edit Client Group") : t("Add Client Group")}
                </Layout.DetailPageTitle>

                <Layout.DetailPageBody>
                    <EditClientPools
                        editId={editID}
                        hasErr={hasErr}
                        onChange={(v) => {
                            setDetailData(v)
                        }}

                    />
                </Layout.DetailPageBody>

                <Layout.DetailPageFooter>
                    {detailData?._id ? (
                        <AuditLogDialog module="ClientPool" id={detailData?._id} />
                    ) : null

                    }
                    {userContent?.rights?.includes(1503) && detailData?._id ? (
                        <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />
                    ) : null}
                    {userContent?.rights?.includes(1502) ?
                        <SaveButton onClick={onSave} loading={loading} />
                        : null}
                </Layout.DetailPageFooter>
            </Layout>
        </>
    );
};
export default observer(ClientPools);




