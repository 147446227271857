import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET, doPOST } from "../../../util/HttpUtil";

class TaxSlabSelect {
    TaxSlab = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filterData) {
        if (this.TaxSlab.length > 0)
            return;
        try {
            const response = await doGET(ENDPOINTS.getAllTaxSlabs);
            if (response.status === 200) {
                this.TaxSlab = response.data.map((v) => { return { label: v?.name, value: v?._id, amount: v?.amount } });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const TaxSlabSelectService = new TaxSlabSelect();
export default TaxSlabSelectService;
