import { observer } from "mobx-react-lite";
import { ThreeBounce } from "better-react-spinkit";
import React, { useContext, useEffect, useState } from "react";
import "react-image-lightbox/style.css";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Form, Row } from "reactstrap";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import {
  BackArrow,
  CardThreeBounce,
  CountrySelect,
  CurrencySelect,
  CrossButton,
  DateSelect,
  InputField,
  Checkbox,
  SaveButton,
  scrollStyle,
  EditLink,
  CompanySelect,
  PaymentSelect,
  Layout,
} from "../../components";
import Tab from "../../components/Common/Tab/Tab";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { ENDPOINTS } from "./SubscriptionConstant";
import SubscriptionService from "./SubscriptionService";

const EditSubscription = (props) => {
  const { t } = useContext(I18nContext);
  // const { showConfirm, showError } = useContext(DialogContext);
  const { showMessage, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const { id } = props;
  const [showErr, setShowErr] = useState(false);
  const [hasErr, setHasErr] = useState(false);
  const [data, setData] = useState({});
  const [freshData, setFreshData] = useState({});
  const [step, setStep] = useState(1);
  const [invData, setInvData] = useState([]);

  const navigate = useNavigate();

  const fetch = async () => {
    let subsD = {};
    let iD = {};
    if (id) {
      try {
        iD = await SubscriptionService.getInvoices(id);
        subsD = await SubscriptionService.get(id);
        setFreshData(subsD);
        setData(subsD);
        setInvData(iD);
        console.log('invoice data is', invData);
      } catch (e) {
        showError(e)
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    fetch();
    setHasErr(false);
  }, [id]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(freshData)) {
      props?.onChange(true);
    } else {
      props?.onChange(false);
    }
  }, [data]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!data?.clientName || !data?.startDate) {
      setShowErr(true);
      setLoading(false);
      return;
    } else {
      setShowErr(false);
    }
    try {
      if (id) {
        await SubscriptionService.save();
        showMessage("Subscription Updated successfully");
      } else {
        const response = await SubscriptionService.save();
        SubscriptionService.records.push(data);
        showMessage("Subscription saved successfully");
        if (!props?.insidePane) {
          navigate(`/subscription/edit`);
        }
      }
      fetch();
    } catch (e) {
      showError(e)
      setLoading(false);
    }
  };

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      {(id && !loading) || !id ? (
        <div className="p-0">
          <Col
            style={props?.insidePane ? { width: "100%" } : scrollStyle}
            className="col-12 col-sm-7 p-0 pe-3"
          >
            <Card className="pt-4" style={{ borderLeft: "2px solid #ced4da" }}>
              <CardBody className="p-3">
                <BackArrow onClick={() => props?.back()} />
                <CrossButton onClick={() => props?.back()} />

                <Form className="" onSubmit={submitHandler}>
                  <Tab
                    activeTab={step}
                    onChange={(data) => {
                      setStep(data);
                    }}
                  >
                    <div num={1} label={t("Corp Subscription")}>
                      <Row className="mb-3">
                        <Col className="col-lg">
                          <InputField
                            placeholder="Client Name"
                            value={data?.clientName}
                            required={true}
                            label={"Client Name"}
                            onChange={(v) =>
                              setData({ ...data, clientName: v })
                            }
                            type="text"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="col-lg">
                          <CompanySelect
                            corpType="client"
                            client={data?._id}
                            value={data}
                            onChange={(v) => setData(v.value)}
                            className="col-lg"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col className="col-lg">
                          <CompanySelect
                            label={"Payment Select"}
                            client={data?._id}
                            value={data}
                            onChange={(v) => setData(v.value)}
                            className="col-lg"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="col-lg">
                          <InputField
                            placeholder="Tax Slab"
                            value={data?.taxSlab_id}
                            required={true}
                            label={"Tax Slab"}
                            onChange={(v) =>
                              setData({ ...data, taxSlab_id: v })
                            }
                            type="text"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="col-lg">
                          <InputField
                            placeholder="Naotification Emails"
                            value={data?.notifyEmail}
                            required={true}
                            label={"Notification Emails"}
                            onChange={(v) =>
                              setData({ ...data, notifyEmail: v })
                            }
                            type="text"
                          />
                        </Col>
                      </Row>
                    </div>
                    {/* tick */}
                    <div num={2} label={t("Plan Detail")}>
                      <Row className="mb-3">
                        <Col className="col-lg">
                          <CountrySelect
                            className="col-lg"
                            value={
                              data?.plans ? data?.plans[0]?.country : undefined
                            }
                            onChange={(v) =>
                              setData({
                                ...data,
                                plans: data?.plans?.map((p, i) => {
                                  if (i === 0) return { ...p, country: v };
                                  return p;
                                }),
                              })
                            }
                            type="text"
                          />
                        </Col>
                        <Col className="col-lg">
                          <CurrencySelect
                            value={data?.plans ? data?.plans[0]?.currency : undefined}
                            className="col-lg"
                            onChange={(v) =>
                              setData({
                                ...data,
                                plans: data?.plans?.map((p, i) => {
                                  if (i === 0) return { ...p, currency: v };
                                  return p;
                                }),
                              })
                            }
                            type="text"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col className="col-lg">
                          <InputField
                            placeholder="Minimum Rental"
                            value={data?.plans ? data?.plans[0]?.minRental : undefined}
                            required={true}
                            label={"Minimum Rental"}
                            onChange={(v) =>
                              setData({
                                ...data,
                                plans: data?.plans?.map((p, i) => {
                                  if (i === 0) return { ...p, minRental: v };
                                  return p;
                                }),
                              })
                            }
                            type="text"
                          />
                        </Col>
                      </Row>
                    </div>
                    <div num={3} label={t("SMS Actor(s)")}>
                      <Row className="mb-3">
                        <Col className="col-lg">
                          <CountrySelect
                            value={data?.smsActors ? data?.smsActors[0]?.country : undefined}
                            className="col-lg"
                            onChange={(v) =>
                              setData({
                                ...data,
                                smsActors: data?.smsActors?.map((p, i) => {
                                  if (i === 0) return { ...p, country: v };
                                  return p;
                                }),
                              })
                            }
                            type="text"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col className="col-lg">
                          <InputField
                            placeholder="URL"
                            value={data?.smsActors ? data?.smsActors[0]?.url : undefined}
                            label={"URL"}
                            onChange={(v) =>
                              setData({
                                ...data,
                                smsActors: data?.smsActors?.map((p, i) => {
                                  if (i === 0) return { ...p, url: v };
                                  return p;
                                }),
                              })
                            }
                            type="text"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col className="col-lg">
                          <Checkbox
                            label="Use Zoyride Default"
                            className="form-check me-5 "
                            onClick={() =>
                              setData({
                                ...data,
                                smsActors: data?.smsActors?.map((p, i) => {
                                  if (i === 0) return { ...p, useZoyrideDefault: true };
                                  return p;
                                }),
                              })
                            }
                            checked={data?.smsActors ? data?.smsActors[0]?.useZoyrideDefault : false}
                          />
                        </Col>
                        <Col className="col-lg">
                          <Checkbox
                            label="Provided by Zoyride"
                            className="form-check me-5 "
                            onClick={() =>
                              setData({
                                ...data,
                                smsActors: data?.smsActors?.map((p, i) => {
                                  if (i === 0) return { ...p, providedByZoyride: true };
                                  return p;
                                }),
                              })
                            }
                            checked={data?.smsActors ? data?.smsActors[0]?.providedByZoyride : false}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div num={4} label={t("Subscription Invoices")}>
                      <Layout>
                        <Layout.TheadFull>
                          <Thead className="thead-dark">
                            <Tr>
                              <Th>Invoice No.</Th>
                              <Th>Invoice Date</Th>
                              <Th>Due Date</Th>
                              <Th>Paid On</Th>
                              <Th>Invoice Status</Th>
                              <Th className="float-end">Action</Th>
                            </Tr>
                          </Thead>
                        </Layout.TheadFull>
                        <Layout.TbodyFull>
                          <Tbody>
                            {invData?.map((subs) => (
                              <>
                                <Tr>
                                  <Td scope="row">{subs?.invoiceNo}</Td>
                                  <Td> {subs?.invoiceDate} </Td>
                                  <Td> {subs?.dueDate}</Td>
                                  <Td>{subs?.paidAt}</Td>
                                  <Td>{subs?.invoiceStatus}</Td>
                                  <Td className="float-end">
                                    <EditLink
                                    // onClick={() => {
                                    //   setSelected_id(subs?._id);
                                    // }}
                                    // to={`/`}
                                    />
                                  </Td>
                                </Tr>
                              </>
                            ))}
                          </Tbody>
                        </Layout.TbodyFull>
                      </Layout>
                    </div>
                  </Tab>
                  <Row>
                    <div
                      className="d-flex justify-content-end  mt-4  "
                      style={{ marginTop: "-2.5rem" }}
                    >
                      <SaveButton />
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </div>
      ) : (
        <Row className="">
          <Card className="p-5" style={{ borderLeft: "2px solid #ced4da" }}>
            <CardBody className="p-5">
              <BackArrow onClick={() => props?.back()} />
              <CrossButton onClick={() => props?.back()} />
              <div className="d-flex align-items-center justify-content-center">
                <ThreeBounce size={20} color="#5b73e8" />
              </div>
            </CardBody>
          </Card>
        </Row>
      )}
    </React.Fragment>
  );
};

export default observer(EditSubscription);
