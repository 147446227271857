import React, { useCallback, useContext, useEffect, useState } from "react";
import { Card, Row, Col, CardBody } from "reactstrap";
import {
  Checkbox,
  EmployeeSelect,
  InputField,
  MakeModelSelect,
  PhoneNum,
  VehicleTypeSelect,
} from "../../../components";
import SupplierCompany from "../../../components/Dropdowns/SupplierCompany/SupplierCompany";
import GroupedVehicleSelect from "../../../components/Dropdowns/VehicleSelect/GroupedVehicleSelect";
import "./style.css";
import { UserContext } from "../../../store/context/UserContext";
import BookingService from "../BookingService";
import { I18nContext } from "../../../store/context/i18nContext";
import RemoveLink from "../../../components/Buttons/RemoveLink";
import AddLink from "../../../components/Buttons/AddLink";
const AssignMultipleVehicle = ({
  index,
  vehicle,
  length,
  driver,
  onPlusClick,
  deleteRow,
  onChangeVehicle,
  onChangeDriver,
}) => {
  const { userContent } = useContext(UserContext);
  const [marketVehicle, setMarketVehicle] = useState(
    vehicle?.provider_id ? false : true
  );
  const [marketDriver, setMarketDriver] = useState(
    driver?.provider_id ? false : true
  );
  const { t } = useContext(I18nContext);

  useEffect(() => {
    console.log("marketDriver", marketDriver);
    console.log("marketVehicle", marketVehicle);
  }, [marketVehicle, marketDriver]);

  useEffect(() => {
    setMarketVehicle(
      vehicle?.provider_id == userContent?.corp_id ? false : true
    );
    setMarketDriver(driver?.provider_id == userContent?.corp_id ? false : true);
  }, [userContent?.corp_id]);

  const handleVehicleDetailChange = useCallback(
    ({ _id, provider_id, providerCompany_id, regNo, makeModel_id }) => {
      const newVehicle = { ...vehicle };

      if (_id !== undefined) {
        newVehicle._id = _id;
      }

      if (provider_id !== undefined) {
        newVehicle.provider_id = provider_id;
      }

      if (providerCompany_id !== undefined) {
        newVehicle.providerCompany_id = providerCompany_id;
      }

      if (regNo !== undefined) {
        newVehicle.regNo = regNo;
      }

      if (makeModel_id !== undefined) {
        newVehicle.makeModel_id = makeModel_id;
      }

      onChangeVehicle(newVehicle);
    },
    [vehicle, onChangeVehicle]
  );

  const handleDriverInfoChange = useCallback(
    (fieldName, fieldValue) => {
      if (fieldName) {
        const newDriver = { ...driver } || {};
        newDriver[fieldName] = fieldValue;
        onChangeDriver(newDriver);
      }
    },
    [driver, onChangeDriver]
  );

  const handleVehicleSupplierChange = useCallback(
    ({ provider_id, providerCompany_id }) => {
      const newVehicle = { ...vehicle } || {};
      newVehicle.provider_id = provider_id;
      newVehicle.providerCompany_id = providerCompany_id;
      onChangeVehicle(newVehicle);
    },
    [vehicle, onChangeVehicle]
  );

  const handleDriverSupplierChange = useCallback(
    ({ provider_id, providerCompany_id }) => {
      const newDriver = { ...driver } || {};
      newDriver.provider_id = provider_id;
      newDriver.providerCompany_id = providerCompany_id;
      onChangeDriver(newDriver);
    },
    [driver, onChangeDriver]
  );

  return (
    <Row>
      <Row style={{ backgroundColor: "#fff" }}>
        <Col className="col-12 col-md-3 mt-2">
          <Checkbox
            label={t("Market Vehicle")}
            style={{ width: "100px", marginTop: "10px" }}
            className="mb-2 mt-2"
            checked={!marketVehicle}
            onClick={() => {
              setMarketVehicle(!marketVehicle);
            }}
          />
        </Col>
        {!marketVehicle ? (
          <>
            {BookingService.renderField("vehicle.providerCompany_id") && (
              <Col className="col-12 col-md-4 ">
                <SupplierCompany
                  value={vehicle?.providerCompany_id}
                  onChange={(v) =>
                    handleVehicleSupplierChange({
                      provider_id: v?.supplier_id,
                      providerCompany_id: v?.value,
                    })
                  }
                  label={"Vehicle Supplier"}
                />
              </Col>
            )}
          </>
        ) : (
          <>
            <Col className="col-12 col-md-4 ">
              <GroupedVehicleSelect
                value={vehicle?._id}
                onChange={(v) => {
                  handleVehicleDetailChange({
                    _id: v?.value,
                    provider_id: v?.supplier_id,
                    providerCompany_id: v?.value,
                  });
                }}
              />
            </Col>
            <Col className="col-12 col-md-4 ">
              <EmployeeSelect
                label={t("Driver Select")}
                driver
                value={driver?._id}
                onChange={(v) => handleDriverInfoChange("_id", v?.value)}
              />
            </Col>
          </>
        )}
        <Col className="d-flex justify-content-end gap-3 align-items-center">
          {length !== 1 && <Col className="d-flex align-items-center justify-content-end" style={{ maxWidth: 'fit-content' }}>
            <RemoveLink className="ms-1" onClick={deleteRow} />
          </Col>}
          < Col className="d-flex  align-items-center justify-content-end" style={{ maxWidth: 'fit-content' }}>
            <AddLink
              style={{ opacity: index === 0 ? 1 : 0 }}
              onClick={() => { index === 0 && onPlusClick() }}
              size={20}
            />

          </Col>
        </Col>
      </Row>
      {
        !marketVehicle && (
          <div>
            <Row className="my-2" style={{ backgroundColor: "#fff" }}>
              <Col>
                {BookingService.renderField("driver.name") && (
                  <InputField
                    outline
                    label="Driver Name"
                    placeholder={"Name"}
                    value={driver?.name}
                    onChange={(v) => handleDriverInfoChange("name", v)}
                  />
                )}
              </Col>
              <Col>
                {BookingService.renderField("driver.phone") && (
                  <PhoneNum
                    outline
                    placeholder={t("Phone No ")}
                    value={{
                      countryCode: driver?.phone?.countryCode,
                      phone: driver?.phone?.phone,
                    }}
                    label={"Phone No."}
                    onChange={(v) => handleDriverInfoChange("phone", v)}
                  />
                )}
              </Col>
              <Col>
                {BookingService.renderField("vehicle.regNo") && (
                  <InputField
                    outline
                    isReadOnly={BookingService.readOnly("vehicle.regNo")}
                    label="Reg No."
                    placeholder={"Reg No."}
                    value={vehicle?.regNo}
                    onChange={(v) =>
                      handleVehicleDetailChange({
                        regNo: v,
                      })
                    }
                  />
                )}
              </Col>
              <Col>
                {BookingService.renderField("vehicle.makeModel_id") && (
                  <MakeModelSelect
                    outline
                    value={vehicle?.makeModel_id}
                    onChange={(v) =>
                      handleVehicleDetailChange({
                        makeModel_id: v?.value,
                      })
                    }
                  />
                )}
              </Col>
            </Row>
          </div>
        )
      }
    </Row >
  );
};

export default AssignMultipleVehicle;
