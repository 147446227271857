import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Badge } from "reactstrap";

import { Layout } from "../../components";

import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { STRUCTURE } from "./AccessLogConstant";
import AccessLogService from "./AccessLogService";

const AccessLog = (props) => {
  const { id } = useParams();
  const { showConfirm, showError } = useContext(DialogContext);
  const { t } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);

  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await AccessLogService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (e) {
      showError(e);
    }
  }, []);

  let navigate = useNavigate();

  return (
    <Layout
       
      title={t("Access Logs")}
      filterStructure={STRUCTURE}
      onApplyFilter={fetchData}
      page={AccessLogService.page}
      rows={AccessLogService.rows}
      total={AccessLogService.totalRecords}
      onPageChange={async (page, rows) => {
        await AccessLogService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("Corporate")}</Th>
            <Th>{t("User Name")}</Th>
            <Th>{t("User Phone")}</Th>
            <Th>{t("User Email")}</Th>
            <Th>{t("Impersonator")}</Th>
            <Th>{t("Time")}</Th>
            <Th>{t("Status")}</Th>
            <Th>{t("Source")}</Th>
            <Th>{t("User Type")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {AccessLogService.records?.map((dta) => (
            // filter
            <Tr key={dta?._id}>
              <Td>{dta.userType !== 3 ? dta?.corporateName : ""}</Td>
              <Td>{dta.userType !== 3 ? dta?.userInfo?.name : ""}</Td>
              <Td>
                {dta.userType !== 3
                  ? `+${dta?.userInfo?.countryCode} - ${dta?.userInfo?.phone}`
                  : ""}
              </Td>
              <Td>{dta.userType !== 3 ? dta?.userInfo?.email : ""}</Td>
              <Td>{""}</Td>
              <Td>
                {dta?.dateString} - {dta?.timeString}
              </Td>
              <Td>
                <Badge
                  className={`rounded-pill ${dta?.status === 0
                      ? "bg-danger"
                      : dta?.status === 1
                        ? "bg-success"
                        : "bg-warning"
                    } `}
                >
                  {dta?.statusMessage}
                </Badge>
              </Td>
              <Td>{dta?.sourceMessage}</Td>
              <Td className="float-start">{dta?.userTypeMessage}</Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>
    </Layout>
  );
};

export default observer(AccessLog);
