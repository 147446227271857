import React, { useEffect, useState, useContext } from "react";
import SettingsService from "../SettingsService";
import { DialogContext } from "../../../store/context/DialogContext";
import { Col, Form, Row, FormGroup, Label, Input, Table } from "reactstrap";
import { InputField, SaveButton } from "../../../components";
import { useParams } from "react-router-dom";

function BookingSettings(props) {
  const { id } = props;
  const [loading, setLoading] = useState(false);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [data, setData] = useState("");
  const [hasErr, setHasErr] = useState(false);

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      if (id) {
        const res = await SettingsService.getConfigById(id);
        console.log("data is: " + res);
        setData(res);
      } else {
        const res = await SettingsService.getConfig();
        setData(res);
      }
      // console.log("bookingsettings.js: ", res);
      setHasErr(false);
    } catch (e) {
      showError(e);
      // console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const numberOnly = (element) => {
    element.target.value = element.target.value.replace(/[^0-9]/gi, "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await SettingsService.saveConfig(data);
      showMessage("Booking Settings Saved successfully");
      fetchData();
      setLoading(false);
    } catch (error) {
      showError(error);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col
            className="col-12 p-2 ps-4"
            style={{
              backgroundColor: "#EAEAEA",
              fontSize: "15px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <header>Booking Settings</header>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="sendBookingEmail"
                name="sendBookingEmail"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    sendBookingEmail: !data?.sendBookingEmail,
                  });
                }}
                checked={data?.sendBookingEmail}
              />
              <Label className="ps-2 pt-1" check for="sendBookingEmail">
                Send Booking Email
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="dutyStart"
                name="dutyStart"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, dutyStart: !data?.dutyStart });
                }}
                checked={data?.dutyStart}
              />
              <Label className="ps-2 pt-1" check for="dutyStart">
                Enable Duty Start from Console
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="dutyEnd"
                name="dutyEnd"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, dutyEnd: !data?.dutyEnd });
                }}
                checked={data?.dutyEnd}
              />
              <Label className="ps-2 pt-1" check for="dutyEnd">
                Enable Duty End from Console
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="sendTripInvoice"
                name="sendTripInvoice"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, sendTripInvoice: !data?.sendTripInvoice });
                }}
                checked={data?.sendTripInvoice}
              />
              <Label className="ps-2 pt-1" check for="sendTripInvoice">
                Send Invoice to Passenger
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="paxFareApproval"
                name="paxFareApproval"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, paxFareApproval: !data?.paxFareApproval });
                }}
                checked={data?.paxFareApproval}
              />
              <Label className="ps-2 pt-1" check for="paxFareApproval">
                Take Fare Approval from Pax at Trip End
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="allowTripPause"
                name="allowTripPause"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, allowTripPause: !data?.allowTripPause });
                }}
                checked={data?.allowTripPause}
              />
              <Label className="ps-2 pt-1" check for="allowTripPause">
                Allow Pause a trip to do other duty
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="denyVendorCreateBooking"
                name="denyVendorCreateBooking"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    denyVendorCreateBooking: !data?.denyVendorCreateBooking,
                  });
                }}
                checked={data?.denyVendorCreateBooking}
              />
              <Label className="ps-2 pt-1" check for="denyVendorCreateBooking">
                Deny Vendor Create Booking From Our behalf
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="sendAssignEmail"
                name="sendAssignEmail"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, sendAssignEmail: !data?.sendAssignEmail });
                }}
                checked={data?.sendAssignEmail}
              />
              <Label className="ps-2 pt-1" check for="sendAssignEmail">
                Send Email Link to Supplier to Assign Vehicle & Driver Directly
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="sendBookingStartOTP"
                name="sendBookingStartOTP"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    sendBookingStartOTP: !data?.sendBookingStartOTP,
                  });
                }}
                checked={data?.sendBookingStartOTP}
              />
              <Label className="ps-2 pt-1" check for="sendBookingStartOTP">
                Send Start Trip OTP for Booking
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="hasOnlineSupplier"
                name="hasOnlineSupplier"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    hasOnlineSupplier: !data?.hasOnlineSupplier,
                  });
                }}
                checked={data?.hasOnlineSupplier}
              />
              <Label className="ps-2 pt-1" check for="hasOnlineSupplier">
                Has Online Suppliers
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="sendAlertDriverLocOff"
                name="sendAlertDriverLocOff"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    sendAlertDriverLocOff: !data?.sendAlertDriverLocOff,
                  });
                }}
                checked={data?.sendAlertDriverLocOff}
              />
              <Label className="ps-2 pt-1" check for="sendAlertDriverLocOff">
                Notify Transport Team If Driver Location Not Found During
                Journey
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="hideFemalePhone"
                name="hideFemalePhone"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, hideFemalePhone: !data?.hideFemalePhone });
                }}
                checked={data?.hideFemalePhone}
              />
              <Label className="ps-2 pt-1" check for="hideFemalePhone">
                Hide female passengers phone from drivers
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="driverNotCollectAmount"
                name="driverNotCollectAmount"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    driverNotCollectAmount: !data?.driverNotCollectAmount,
                  });
                }}
                checked={data?.driverNotCollectAmount}
              />
              <Label className="ps-2 pt-1" check for="driverNotCollectAmount">
                Don't Allow driver to collect rest booking amount from passenger
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="stopAllEmail"
                name="stopAllEmail"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, stopAllEmail: !data?.stopAllEmail });
                }}
                checked={data?.stopAllEmail}
              />
              <Label className="ps-2 pt-1" check for="stopAllEmail">
                Stop sending email to anyone
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="stopAllSMS"
                name="stopAllSMS"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, stopAllSMS: !data?.stopAllSMS });
                }}
                checked={data?.stopAllSMS}
              />
              <Label className="ps-2 pt-1" check for="stopAllSMS">
                Stop sending SMS to anyone
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="bookingQCMandatory"
                name="bookingQCMandatory"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingQCMandatory: !data?.bookingQCMandatory,
                  });
                }}
                checked={data?.bookingQCMandatory}
              />
              <Label className="ps-2 pt-1" check for="bookingQCMandatory">
                Booking QC is Mandatory
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showPreStart"
                name="showPreStart"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showPreStart: !data?.showPreStart });
                }}
                checked={data?.showPreStart}
              />
              <Label className="ps-2 pt-1" check for="showPreStart">
                {
                  "Show Early Start Button to Driver for Future Duty (where duty start time is more than 30 mins)"
                }
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <InputField
              placeholder="Driver Duty Duration"
              label={"Driver Duty Duration (Hours)"}

              type="number"
              maxLength="4"
              value={data?.driverDutyDuration ? data?.driverDutyDuration : ""}
              onChange={(v) => {
                setData({ ...data, driverDutyDuration: v });
              }}
            />
            {/* </FormGroup> */}
          </Col>

          <Col className="col-12 p-3 ps-4">
            <InputField
              placeholder="Max Pre Start Duration(Mins)"
              label={"Max Pre Start Duration (Mins)"}

              type="number"
              maxLength="4"
              value={data?.preStartDuration ? data?.preStartDuration : ""}
              onChange={(v) => {
                setData({ ...data, preStartDuration: v });
              }}
            />
          </Col>

          <Col className="col-12 p-3 ps-4">
            <InputField
              placeholder="Upcoming Booking Duration (Hours)"
              label={"Upcoming Booking Duration (Hours)"}

              type="number"
              maxLength="4"
              value={data?.upcomingHrs ? data?.upcomingHrs : ""}
              onChange={(v) => {
                setData({ ...data, upcomingHrs: v });
              }}
            />
          </Col>

          <Col className="col-12 p-3 ps-4">
            <InputField
              placeholder="Minimum Booking Duration (Mins)"
              label={"Minimum Booking Duration (Mins)"}

              type="number"
              maxLength="5"
              value={
                data?.pastBookingCreationGap ? data?.pastBookingCreationGap : ""
              }
              onChange={(v) => {
                setData({ ...data, pastBookingCreationGap: v });
              }}
            />
          </Col>

          <Col className="col-12 p-3 ps-4">
            <InputField
              placeholder="Minimum Booking Duration (Mins)"
              label={"Minimum Booking Duration (Mins)"}

              type="number"
              maxLength="5"
              value={data?.minBookingDuration ? data?.minBookingDuration : ""}
              onChange={(v) => {
                setData({ ...data, minBookingDuration: v });
              }}
            />
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="askDriverFeedback"
                name="askDriverFeedback"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    askDriverFeedback: !data?.sendBookingEmail,
                  });
                }}
                checked={data?.askDriverFeedback}
              />
              <Label className="ps-2 pt-1" check for="askDriverFeedback">
                Ask Driver for Feedback at End of Journey
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="bookingHasLimit"
                name="bookingHasLimit"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, bookingHasLimit: !data?.bookingHasLimit });
                }}
                checked={data?.bookingHasLimit}
              />
              <Label className="ps-2 pt-1" check for="bookingHasLimit">
                Apply Booking Limit
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="oneTimeClient"
                name="oneTimeClient"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, oneTimeClient: !data?.oneTimeClient });
                }}
                checked={data?.oneTimeClient}
              />
              <Label className="ps-2 pt-1" check for="oneTimeClient">
                Enable One Time Client Option for Booking
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="sendAlertEmail"
                name="sendAlertEmail"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, sendAlertEmail: !data?.sendAlertEmail });
                }}
                checked={data?.sendAlertEmail}
              />
              <Label className="ps-2 pt-1" check for="sendAlertEmail">
                Send Regular Alert Email to Supervisor containing Actionable
                Items
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="sendAlertSMS"
                name="sendAlertSMS"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, sendAlertSMS: !data?.sendAlertSMS });
                }}
                checked={data?.sendAlertSMS}
              />
              <Label className="ps-2 pt-1" check for="sendAlertSMS">
                Send Regular Alert SMS to Supervisor containing Actionable Items
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="bookingAlertEmailBUWise"
                name="bookingAlertEmailBUWise"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAlertEmailBUWise: !data?.bookingAlertEmailBUWise,
                  });
                }}
                checked={data?.bookingAlertEmailBUWise}
              />
              <Label className="ps-2 pt-1" check for="bookingAlertEmailBUWise">
                Send Regular Booking Alert Email to Supervisor containing
                Actionable Items Business Unit wise
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="dutySlipRequied"
                name="dutySlipRequied"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, dutySlipRequied: !data?.dutySlipRequied });
                }}
                checked={data?.dutySlipRequied}
              />
              <Label className="ps-2 pt-1" check for="dutySlipRequied">
                Duty Slip no required while duty close
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="confirmByTptTeam"
                name="confirmByTptTeam"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    confirmByTptTeam: !data?.confirmByTptTeam,
                  });
                }}
                checked={data?.confirmByTptTeam}
              />
              <Label className="ps-2 pt-1" check for="confirmByTptTeam">
                Confirm Booking by Transport Team Required
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="askPaxSign"
                name="askPaxSign"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, askPaxSign: !data?.askPaxSign });
                }}
                checked={data?.askPaxSign}
              />
              <Label className="ps-2 pt-1" check for="askPaxSign">
                Ask for Customer Sign at Journey End for All Duties
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="autoClose"
                name="autoClose"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, autoClose: !data?.autoClose });
                }}
                checked={data?.autoClose}
              />
              <Label className="ps-2 pt-1" check for="autoClose">
                Auto close trip on reaching destination for POINT_TO_POINT and
                FIXED_ROUTE travel
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="serviceTypeRequired"
                name="serviceTypeRequired"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    serviceTypeRequired: !data?.serviceTypeRequired,
                  });
                }}
                checked={data?.serviceTypeRequired}
              />
              <Label className="ps-2 pt-1" check for="serviceTypeRequired">
                Make payment mode mandatory on create booking
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="bookerRequired"
                name="bookerRequired"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, bookerRequired: !data?.bookerRequired });
                }}
                checked={data?.bookerRequired}
              />
              <Label className="ps-2 pt-1" check for="bookerRequired">
                Booker Detail Required on booking create
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="disableAutoApprove"
                name="disableAutoApprove"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    disableAutoApprove: !data?.disableAutoApprove,
                  });
                }}
                checked={data?.disableAutoApprove}
              />
              <Label className="ps-2 pt-1" check for="disableAutoApprove">
                Disable Booking Auto Confirm
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="hideReject"
                name="hideReject"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, hideReject: !data?.hideReject });
                }}
                checked={data?.hideReject}
              />
              <Label className="ps-2 pt-1" check for="hideReject">
                Hide Duty Reject Option for Driver
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="askBookingPaxOTP"
                name="askBookingPaxOTP"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    askBookingPaxOTP: !data?.askBookingPaxOTP,
                  });
                }}
                checked={data?.askBookingPaxOTP}
              />
              <Label className="ps-2 pt-1" check for="askBookingPaxOTP">
                Ask Booking Pax OTP
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="sendTrackingUrl"
                name="sendTrackingUrl"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, sendTrackingUrl: !data?.sendTrackingUrl });
                }}
                checked={data?.sendTrackingUrl}
              />
              <Label className="ps-2 pt-1" check for="sendTrackingUrl">
                Send Tracking URL
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <InputField
              placeholder="Waiting Period"
              label={
                "Max waiting duration of driver at pickup location of passenger (minutes)"
              }

              type="number"
              maxLength="20"
              value={data?.waitingPeriod ? data?.waitingPeriod : ""}
              onChange={(v) => {
                setData({ ...data, waitingPeriod: v });
              }}
            />
          </Col>

          <Col className="col-12 p-3 ps-4">
            <InputField
              placeholder="Max Permitted Booking Per Employee"
              label={"Max Permitted Booking Per Employee"}

              type="number"
              maxLength="20"
              value={
                data?.employeeBookingLimit ? data?.employeeBookingLimit : ""
              }
              onChange={(v) => {
                setData({ ...data, employeeBookingLimit: v });
              }}
            />
          </Col>

          <Col
            className="col-12 p-2 ps-4"
            style={{
              backgroundColor: "#EAEAEA",
              fontSize: "15px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <header>
              Booking Create/Edit/Detail Page Configuration
              <span
                style={{ fontSize: "13px" }}
              >{`(Select only those fields which need to be shown on Booking Create/Edit/Detail Page)`}</span>
            </header>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showBookerOpt"
                name="showBookerOpt"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showBookerOpt: !data?.showBookerOpt });
                }}
                checked={data?.showBookerOpt}
              />
              <Label className="ps-2 pt-1" check for="showBookerOpt">
                Booking option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showItineraryOpt"
                name="showItineraryOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showItineraryOpt: !data?.showItineraryOpt,
                  });
                }}
                checked={data?.showItineraryOpt}
              />
              <Label className="ps-2 pt-1" check for="showItineraryOpt">
                Itinerary option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showEscortOpt"
                name="showEscortOpt"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showEscortOpt: !data?.showEscortOpt });
                }}
                checked={data?.showEscortOpt}
              />
              <Label className="ps-2 pt-1" check for="showEscortOpt">
                Ancillary option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showBrandingOpt"
                name="showBrandingOpt"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showBrandingOpt: !data?.showBrandingOpt });
                }}
                checked={data?.showBrandingOpt}
              />
              <Label className="ps-2 pt-1" check for="showBrandingOpt">
                Branding option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showEscortOpt"
                name="showEscortOpt"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showEscortOpt: !data?.showEscortOpt });
                }}
                checked={data?.showEscortOpt}
              />
              <Label className="ps-2 pt-1" check for="showEscortOpt">
                Escort option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showInstructionOpt"
                name="showInstructionOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showInstructionOpt: !data?.showInstructionOpt,
                  });
                }}
                checked={data?.showInstructionOpt}
              />
              <Label className="ps-2 pt-1" check for="showInstructionOpt">
                Instructions option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showCostCenterOpt"
                name="showCostCenterOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showCostCenterOpt: !data?.showCostCenterOpt,
                  });
                }}
                checked={data?.showCostCenterOpt}
              />
              <Label className="ps-2 pt-1" check for="showCostCenterOpt">
                Cost Center option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showDriverLangOpt"
                name="showDriverLangOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showDriverLangOpt: !data?.showDriverLangOpt,
                  });
                }}
                checked={data?.showDriverLangOpt}
              />
              <Label className="ps-2 pt-1" check for="showDriverLangOpt">
                Driver Language option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showMarketOpt"
                name="showMarketOpt"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showMarketOpt: !data?.showMarketOpt });
                }}
                checked={data?.showMarketOpt}
              />
              <Label className="ps-2 pt-1" check for="showMarketOpt">
                Market option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showServiceTypeOpt"
                name="showServiceTypeOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showServiceTypeOpt: !data?.showServiceTypeOpt,
                  });
                }}
                checked={data?.showServiceTypeOpt}
              />
              <Label className="ps-2 pt-1" check for="showServiceTypeOpt">
                Service Type option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showPmntDetailOpt"
                name="showPmntDetailOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showPmntDetailOpt: !data?.showPmntDetailOpt,
                  });
                }}
                checked={data?.showPmntDetailOpt}
              />
              <Label className="ps-2 pt-1" check for="showPmntDetailOpt">
                Payment Detail option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showPmntModeOpt"
                name="showPmntModeOpt"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showPmntModeOpt: !data?.showPmntModeOpt });
                }}
                checked={data?.showPmntModeOpt}
              />
              <Label className="ps-2 pt-1" check for="showPmntModeOpt">
                Payment Mode option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="sendHoldInvoicingOpt"
                name="sendHoldInvoicingOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    sendHoldInvoicingOpt: !data?.sendHoldInvoicingOpt,
                  });
                }}
                checked={data?.sendHoldInvoicingOpt}
              />
              <Label className="ps-2 pt-1" check for="sendHoldInvoicingOpt">
                Hold Invoicing option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showQcOpt"
                name="showQcOpt"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showQcOpt: !data?.showQcOpt });
                }}
                checked={data?.showQcOpt}
              />
              <Label className="ps-2 pt-1" check for="showQcOpt">
                QC option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showPaxCostCenter"
                name="showPaxCostCenter"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    showPaxCostCenter: !data?.showPaxCostCenter,
                  });
                }}
                checked={data?.showPaxCostCenter}
              />
              <Label className="ps-2 pt-1" check for="showPaxCostCenter">
                Passenger Cost Center
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showPaxEmail"
                name="showPaxEmail"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showPaxEmail: !data?.showPaxEmail });
                }}
                checked={data?.showPaxEmail}
              />
              <Label className="ps-2 pt-1" check for="showPaxEmail">
                Passenger Email
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showCashDutyOpt"
                name="showCashDutyOpt"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showCashDutyOpt: !data?.showCashDutyOpt });
                }}
                checked={data?.showCashDutyOpt}
              />
              <Label className="ps-2 pt-1" check for="showCashDutyOpt">
                Cash Duty option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showPenaltyOpt"
                name="showPenaltyOpt"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showPenaltyOpt: !data?.showPenaltyOpt });
                }}
                checked={data?.showPenaltyOpt}
              />
              <Label className="ps-2 pt-1" check for="showPenaltyOpt">
                Penalty option
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showBillAdrsOpt"
                name="showBillAdrsOpt"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showBillAdrsOpt: !data?.showBillAdrsOpt });
                }}
                checked={data?.showBillAdrsOpt}
              />
              <Label className="ps-2 pt-1" check for="showBillAdrsOpt">
                Billing Address
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showTagOpt"
                name="showTagOpt"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showTagOpt: !data?.showTagOpt });
                }}
                checked={data?.showTagOpt}
              />
              <Label className="ps-2 pt-1" check for="showTagOpt">
                Tag
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="hideDisposal"
                name="hideDisposal"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, hideDisposal: !data?.hideDisposal });
                }}
                checked={data?.hideDisposal}
              />
              <Label className="ps-2 pt-1" check for="hideDisposal">
                Hide Disposal
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="hideSightseeing"
                name="hideSightseeing"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, hideSightseeing: !data?.hideSightseeing });
                }}
                checked={data?.hideSightseeing}
              />
              <Label className="ps-2 pt-1" check for="hideSightseeing">
                Hide Sightseeing
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="hidePointToPoint"
                name="hidePointToPoint"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    hidePointToPoint: !data?.hidePointToPoint,
                  });
                }}
                checked={data?.hidePointToPoint}
              />
              <Label className="ps-2 pt-1" check for="hidePointToPoint">
                Hide Point to Point
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="hideSelfDrive"
                name="hideSelfDrive"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, hideSelfDrive: !data?.hideSelfDrive });
                }}
                checked={data?.hideSelfDrive}
              />
              <Label className="ps-2 pt-1" check for="hideSelfDrive">
                Hide Self Drive
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="hideFixedRoute"
                name="hideFixedRoute"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, hideFixedRoute: !data?.hideFixedRoute });
                }}
                checked={data?.hideFixedRoute}
              />
              <Label className="ps-2 pt-1" check for="hideFixedRoute">
                Hide Fixed Route
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showChildOpt"
                name="showChildOpt"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showChildOpt: !data?.showChildOpt });
                }}
                checked={data?.showChildOpt}
              />
              <Label className="ps-2 pt-1" check for="showChildOpt">
                Child Passenger
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showReferenceBy"
                name="showReferenceBy"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, showReferenceBy: !data?.showReferenceBy });
                }}
                checked={data?.showReferenceBy}
              />
              <Label className="ps-2 pt-1" check for="showReferenceBy">
                Referenced By
              </Label>
            </FormGroup>
          </Col>

          <Col
            className="col-12 p-2 ps-4"
            style={{
              backgroundColor: "#EAEAEA",
              fontSize: "15px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <header>
              Booking All Page Configuration
              <span
                style={{ fontSize: "13px" }}
              >{`(Select only those fields which need to be shown on Booking All Page)`}</span>
            </header>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showActualSrcOpt"
                name="showActualSrcOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showActualSrcOpt:
                        !data?.bookingAllPageFields?.showActualSrcOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showActualSrcOpt}
              />
              <Label
                className="ps-2 pt-1"
                check
                for="bookingAllPageFields?.showActualSrcOpt"
              >
                Actual Source
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showActualDstOpt"
                name="showActualDstOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showActualDstOpt:
                        !data?.bookingAllPageFields?.showActualDstOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showActualDstOpt}
              />
              <Label className="ps-2 pt-1" check for="showActualDstOpt">
                Actual Destination
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showPlannedSrcOpt"
                name="showPlannedSrcOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showPlannedSrcOpt:
                        !data?.bookingAllPageFields?.showPlannedSrcOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showPlannedSrcOpt}
              />
              <Label className="ps-2 pt-1" check for="showPlannedSrcOpt">
                Planned Source
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showPlannedDstOpt"
                name="showPlannedDstOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showPlannedDstOpt:
                        !data?.bookingAllPageFields?.showPlannedDstOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showPlannedDstOpt}
              />
              <Label className="ps-2 pt-1" check for="showPlannedDstOpt">
                Planned Destination
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showActualStartTimeOpt"
                name="showActualStartTimeOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showActualStartTimeOpt:
                        !data?.bookingAllPageFields?.showActualStartTimeOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showActualStartTimeOpt}
              />
              <Label className="ps-2 pt-1" check for="showActualStartTimeOpt">
                Actual Start Time
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showActualEndTimeOpt"
                name="showActualEndTimeOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showActualEndTimeOpt:
                        !data?.bookingAllPageFields?.showActualEndTimeOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showActualEndTimeOpt}
              />
              <Label className="ps-2 pt-1" check for="showActualEndTimeOpt">
                Actual End Time
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showPlannedStartTimeOpt"
                name="showPlannedStartTimeOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showPlannedStartTimeOpt:
                        !data?.bookingAllPageFields?.showPlannedStartTimeOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showPlannedStartTimeOpt}
              />
              <Label className="ps-2 pt-1" check for="showPlannedStartTimeOpt">
                Planned Start Time
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showServiceTypeOpt"
                name="showServiceTypeOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showServiceTypeOpt:
                        !data?.bookingAllPageFields?.showServiceTypeOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showServiceTypeOpt}
              />
              <Label className="ps-2 pt-1" check for="showServiceTypeOpt">
                Service Type
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showBookerOpt"
                name="showBookerOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showBookerOpt: !data?.bookingAllPageFields?.showBookerOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showBookerOpt}
              />
              <Label className="ps-2 pt-1" check for="showBookerOpt">
                Booker Detail
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showPaxOpt"
                name="showPaxOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showPaxOpt: !data?.bookingAllPageFields?.showPaxOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showPaxOpt}
              />
              <Label className="ps-2 pt-1" check for="showPaxOpt">
                Passengers Detail
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showCreationTimeOpt"
                name="showCreationTimeOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showCreationTimeOpt:
                        !data?.bookingAllPageFields?.showCreationTimeOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showCreationTimeOpt}
              />
              <Label className="ps-2 pt-1" check for="showCreationTimeOpt">
                Creation Time
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showCreatedByOpt"
                name="showCreatedByOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showCreationTimeOpt:
                        !data?.bookingAllPageFields?.showCreatedByOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showCreatedByOpt}
              />
              <Label className="ps-2 pt-1" check for="showCreatedByOpt">
                Created by Detail
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showDriverCmntOpt"
                name="showDriverCmntOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showDriverCmntOpt:
                        !data?.bookingAllPageFields?.showDriverCmntOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showDriverCmntOpt}
              />
              <Label className="ps-2 pt-1" check for="showDriverCmntOpt">
                Driver Comment
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showAdminCmntOpt"
                name="showAdminCmntOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showAdminCmntOpt:
                        !data?.bookingAllPageFields?.showAdminCmntOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showAdminCmntOpt}
              />
              <Label className="ps-2 pt-1" check for="showAdminCmntOpt">
                Admin Comment
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showCancelMsgOpt"
                name="showCancelMsgOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showCancelMsgOpt:
                        !data?.bookingAllPageFields?.showCancelMsgOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showCancelMsgOpt}
              />
              <Label className="ps-2 pt-1" check for="showCancelMsgOpt">
                Cancel Message
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-md-6 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="showPaxStatusOpt"
                name="showPaxStatusOpt"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    bookingAllPageFields: {
                      ...data.bookingAllPageFields,
                      showPaxStatusOpt:
                        !data?.bookingAllPageFields?.showPaxStatusOpt,
                    },
                  });
                }}
                checked={data?.bookingAllPageFields?.showPaxStatusOpt}
              />
              <Label className="ps-2 pt-1" check for="showPaxStatusOpt">
                Passenger Status
              </Label>
            </FormGroup>
          </Col>

          <Col
            className="col-12 p-2 ps-4"
            style={{
              backgroundColor: "#EAEAEA",
              fontSize: "15px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <header>Booking Pooling Configuration</header>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="bookingPooling"
                name="bookingPooling"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, bookingPooling: !data?.bookingPooling });
                }}
                checked={data?.bookingPooling}
              />
              <Label className="ps-2 pt-1" check for="bookingPooling">
                Enable Booking Pooling
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="allowReverseMatch"
                name="allowReverseMatch"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    allowReverseMatch: !data?.allowReverseMatch,
                  });
                }}
                checked={data?.allowReverseMatch}
              />
              <Label className="ps-2 pt-1" check for="allowReverseMatch">
                Allow Reverse Match, Shorter Booking created earlier will be
                merged with longer booking created later
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="poolAutoJoin"
                name="poolAutoJoin"
                type="checkbox"
                onChange={() => {
                  setData({ ...data, poolAutoJoin: !data?.poolAutoJoin });
                }}
                checked={data?.poolAutoJoin}
              />
              <Label className="ps-2 pt-1" check for="poolAutoJoin">
                Auto Join Booking Pooling
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <InputField
              placeholder="Pooling Limit"
              label={"Pooling Limit"}

              type="number"
              maxLength="4"
              value={data?.poolingLimit ? data?.poolingLimit : ""}
              onChange={(v) => {
                setData({ ...data, poolingLimit: v });
              }}
            />
          </Col>

          <Col className="col-12 p-3 ps-4">
            <InputField
              placeholder="Booking Match time interval"
              label={"Booking match finding time interval (in Minutes)"}

              type="number"
              maxLength="3"
              value={
                data?.bookingMatchInterval ? data?.bookingMatchInterval : ""
              }
              onChange={(v) => {
                setData({ ...data, bookingMatchInterval: v });
              }}
            />
          </Col>

          <Col className="col-12 p-3 ps-4">
            <InputField
              placeholder="Booking match finding distance"
              label={"Minimum Booking deviation arial distance (in Meters)"}

              type="number"
              maxLength="4"
              value={
                data?.bookingMatchDistance ? data?.bookingMatchDistance : ""
              }
              onChange={(v) => {
                setData({ ...data, bookingMatchDistance: v });
              }}
            />
          </Col>

          <Col className="col-12 p-3 ps-4">
            <InputField
              placeholder="Booking match finding distance Percent"
              label={
                "Booking match finding distance deviation in Percent of Booking Distance (in Percent)"
              }

              type="number"
              maxLength="4"
              value={data?.bookingMatchPercent ? data?.bookingMatchPercent : ""}
              onChange={(v) => {
                setData({ ...data, bookingMatchPercent: v });
              }}
            />
          </Col>

          <Col
            className="col-12 p-2 ps-4"
            style={{
              backgroundColor: "#EAEAEA",
              fontSize: "15px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <header>Email/SMS Settings</header>
          </Col>
          <Table striped bordered hover responsive>
            <tbody>
              <tr>
                <td rowspan="2">Type</td>
                <td colSpan="2">Email</td>
                <td colSpan="3">SMS</td>
              </tr>
              <tr>
                <td>Customer</td>
                <td>Booker</td>
                <td>Customer</td>
                <td>Booker</td>
                <td>Driver</td>
              </tr>
              <tr>
                <td>Booking Created</td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.createdEmail"
                      name="bookingNotifyCustomer.createdEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            createdEmail:
                              !data?.bookingNotifyCustomer?.createdEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.createdEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.createdEmail"
                      name="bookingNotifyBooker.createdEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            createdEmail:
                              !data?.bookingNotifyBooker?.createdEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.createdEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.createdSMS"
                      name="bookingNotifyCustomer.createdSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            createdSMS:
                              !data?.bookingNotifyCustomer?.createdSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.createdSMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.createdSMS"
                      name="bookingNotifyBooker.createdSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            createdSMS: !data?.bookingNotifyBooker?.createdSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.createdSMS}
                    />
                  </FormGroup>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Booking Confirmation</td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.confirmEmail"
                      name="bookingNotifyCustomer.confirmEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            confirmEmail:
                              !data?.bookingNotifyCustomer?.confirmEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.confirmEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.confirmEmail"
                      name="bookingNotifyBooker.confirmEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            confirmEmail:
                              !data?.bookingNotifyBooker?.confirmEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.confirmEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.confirmSMS"
                      name="bookingNotifyCustomer.confirmSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            confirmSMS:
                              !data?.bookingNotifyCustomer?.confirmSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.confirmSMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.confirmSMS"
                      name="bookingNotifyBooker.confirmSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            confirmSMS: !data?.bookingNotifyBooker?.confirmSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.confirmSMS}
                    />
                  </FormGroup>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Vehicle Assigned</td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.vehAssignEmail"
                      name="bookingNotifyCustomer.vehAssignEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            vehAssignEmail:
                              !data?.bookingNotifyCustomer?.vehAssignEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.vehAssignEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.vehAssignEmail"
                      name="bookingNotifyBooker.vehAssignEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            vehAssignEmail:
                              !data?.bookingNotifyBooker?.vehAssignEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.vehAssignEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.vehAssignSMS"
                      name="bookingNotifyCustomer.vehAssignSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            vehAssignSMS:
                              !data?.bookingNotifyCustomer?.vehAssignSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.vehAssignSMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.vehAssignSMS"
                      name="bookingNotifyBooker.vehAssignSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            vehAssignSMS:
                              !data?.bookingNotifyBooker?.vehAssignSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.vehAssignSMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyDriver.vehAssignSMS"
                      name="bookingNotifyDriver.vehAssignSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyDriver: {
                            ...data.bookingNotifyDriver,
                            vehAssignSMS:
                              !data?.bookingNotifyDriver?.vehAssignSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyDriver?.vehAssignSMS}
                    />
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td>Vehicle Dispatched</td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.vehDispatchEmail"
                      name="bookingNotifyCustomer.vehDispatchEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            vehDispatchEmail:
                              !data?.bookingNotifyCustomer?.vehDispatchEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.vehDispatchEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.vehDispatchEmail"
                      name="bookingNotifyBooker.vehDispatchEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            vehDispatchEmail:
                              !data?.bookingNotifyBooker?.vehDispatchEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.vehDispatchEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.vehDispatchSMS"
                      name="bookingNotifyCustomer.vehDispatchSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            vehDispatchSMS:
                              !data?.bookingNotifyCustomer?.vehDispatchSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.vehDispatchSMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.vehDispatchSMS"
                      name="bookingNotifyBooker.vehDispatchSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            vehDispatchSMS:
                              !data?.bookingNotifyBooker?.vehDispatchSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.vehDispatchSMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyDriver.vehDispatchSMS"
                      name="bookingNotifyDriver.vehDispatchSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyDriver: {
                            ...data.bookingNotifyDriver,
                            vehDispatchSMS:
                              !data?.bookingNotifyDriver?.vehDispatchSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyDriver?.vehDispatchSMS}
                    />
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td>Journey Start</td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.onJourneyEmail"
                      name="bookingNotifyCustomer.onJourneyEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            onJourneyEmail:
                              !data?.bookingNotifyCustomer?.onJourneyEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.onJourneyEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.onJourneyEmail"
                      name="bookingNotifyBooker.onJourneyEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            onJourneyEmail:
                              !data?.bookingNotifyBooker?.onJourneyEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.onJourneyEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.onJourneySMS"
                      name="bookingNotifyCustomer.onJourneySMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            onJourneySMS:
                              !data?.bookingNotifyCustomer?.onJourneySMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.onJourneySMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.onJourneySMS"
                      name="bookingNotifyBooker.onJourneySMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            onJourneySMS:
                              !data?.bookingNotifyBooker?.onJourneySMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.onJourneySMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  {/* <FormGroup className='d-flex align-items-center' check>
                    <Input
                      id="bookingNotifyDriver.onJourneySMS"
                      name="bookingNotifyDriver.onJourneySMS"
                      type="checkbox"
                onChange={() => {
                  setData({...data, sendBookingEmail: !data?.sendBookingEmail })
                }}
                    />
                  </FormGroup> */}
                </td>
              </tr>
              <tr>
                <td>Journey Completion</td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.tripCompleteEmail"
                      name="bookingNotifyCustomer.tripCompleteEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            tripCompleteEmail:
                              !data?.bookingNotifyCustomer?.tripCompleteEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.tripCompleteEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.tripCompleteEmail"
                      name="bookingNotifyBooker.tripCompleteEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            tripCompleteEmail:
                              !data?.bookingNotifyBooker?.tripCompleteEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.tripCompleteEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.tripCompleteSMS"
                      name="bookingNotifyCustomer.tripCompleteSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            tripCompleteSMS:
                              !data?.bookingNotifyCustomer?.tripCompleteSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.tripCompleteSMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.tripCompleteSMS"
                      name="bookingNotifyBooker.tripCompleteSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            tripCompleteSMS:
                              !data?.bookingNotifyBooker?.tripCompleteSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.tripCompleteSMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  {/* <FormGroup className='d-flex align-items-center' check>
                    <Input
                      id="bookingNotifyDriver.onJourneySMS"
                      name="bookingNotifyDriver.onJourneySMS"
                      type="checkbox"
                onChange={() => {
                        setData({ ...data, bookingNotifyDriver:{ ...data.bookingNotifyDriver, tripCompleteSMS: !data?.bookingNotifyDriver?.tripCompleteSMS}})
                      }}
                      checked={data?.bookingNotifyDriver?.tripCompleteSMS}
                    />
                  </FormGroup> */}
                </td>
              </tr>
              <tr>
                <td>On Cancellation</td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.cancelEmail"
                      name="bookingNotifyCustomer.cancelEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            cancelEmail:
                              !data?.bookingNotifyCustomer?.cancelEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.cancelEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.cancelEmail"
                      name="bookingNotifyBooker.cancelEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            cancelEmail:
                              !data?.bookingNotifyBooker?.cancelEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.cancelEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.cancelSMS"
                      name="bookingNotifyCustomer.cancelSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            cancelSMS: !data?.bookingNotifyCustomer?.cancelSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.cancelSMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.cancelSMS"
                      name="bookingNotifyBooker.cancelSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            cancelSMS: !data?.bookingNotifyBooker?.cancelSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.cancelSMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyDriver.cancelSMS"
                      name="bookingNotifyDriver.cancelSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyDriver: {
                            ...data.bookingNotifyDriver,
                            cancelSMS: !data?.bookingNotifyDriver?.cancelSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyDriver?.cancelSMS}
                    />
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td>On Amendment</td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.ammendmentEmail"
                      name="bookingNotifyCustomer.ammendmentEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            ammendmentEmail:
                              !data?.bookingNotifyCustomer?.ammendmentEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.ammendmentEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.ammendmentEmail"
                      name="bookingNotifyBooker.ammendmentEmail"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            ammendmentEmail:
                              !data?.bookingNotifyBooker?.ammendmentEmail,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.ammendmentEmail}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyCustomer.ammendmentSMS"
                      name="bookingNotifyCustomer.ammendmentSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyCustomer: {
                            ...data.bookingNotifyCustomer,
                            ammendmentSMS:
                              !data?.bookingNotifyCustomer?.ammendmentSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyCustomer?.ammendmentSMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyBooker.ammendmentSMS"
                      name="bookingNotifyBooker.ammendmentSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data,
                          bookingNotifyBooker: {
                            ...data.bookingNotifyBooker,
                            ammendmentSMS:
                              !data?.bookingNotifyBooker?.ammendmentSMS,
                          },
                        });
                      }}
                      checked={data?.bookingNotifyBooker?.ammendmentSMS}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="d-flex align-items-center" check>
                    <Input
                      id="bookingNotifyDriver.ammendmentSMS"
                      name="bookingNotifyDriver.ammendmentSMS"
                      type="checkbox"
                      onChange={() => {
                        setData({
                          ...data?.bookingNotifyDriver,
                          ammendmentSMS:
                            !data?.bookingNotifyDriver?.ammendmentSMS,
                        });
                      }}
                      checked={data?.bookingNotifyDriver?.ammendmentSMS}
                    />
                  </FormGroup>
                </td>
              </tr>
            </tbody>
          </Table>
          <footer className="col-12">
            <SaveButton />
          </footer>
        </Row>
      </Form>
    </div>
  );
}

export default BookingSettings;
