import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import ReminderForm from './ReminderForm';
import ReminderGrid from './ReminderGrid';
import { FaPlus } from 'react-icons/fa';

const Reminder = ({ leadId }) => {

    const [showReminderForm, setShowReminderForm] = useState(false);

    useEffect(() => {

    }, []);



    return (

        <div>

            <div style={{ borderLeft: "1px solid #EBEBEB" }} className="d-flex flex-column justify-content-between align-items-center">
                <div className="w-100 d-flex justify-content-between align-items-center" style={{ borderBottom: "1px solid #EBEBEB" }}>
                    <div className="h3 mt-2 ms-2 ">Reminders</div>
                    <div className=" ">
                        <Button
                            onClick={() => { setShowReminderForm({ status: true }) }}
                            className="d-flex align-items-center justify-content-center" style={{
                                backgroundColor: "rgb(73, 80, 87)",
                                padding: "3px"
                            }}>
                            <FaPlus className='' />
                        </Button>
                    </div>
                </div>
                <div style={{ backgroundColor: "#FBFBFC" }} className="reminders-list w-100 flex-1 text-center">
                    <ReminderGrid
                        onRequestDetail={(id) => {
                            setShowReminderForm({ status: true, id: id })
                        }}
                        leadId={leadId}
                    />
                </div>
            </div>


            {showReminderForm?.status && <ReminderForm
                isOpen={showReminderForm?.status}
                editId={showReminderForm?.id}
                leadId={leadId}
                toggleModal={() => { setShowReminderForm({ status: false }) }}
            />}

        </div>



    );
};

export default observer(Reminder);
