import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody, CardTitle, Container,
  Table
} from "reactstrap";
import toastr from "toastr";

import Delete from "../../components/Buttons/Icons/deleteButton";
import Edit from "../../components/Buttons/Icons/editButton";
import FuelPrices from "./store";


import {
  AddButton,
  Breadcrumbs,
  Filter,
  Pagination,
} from "../../components";


const FuelPrice = () => {

  const [page, setPage] = useState(1);
  const [entries, setEntries] = useState({ start: 0, end: 0 });
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [delete_id, setdelete_id] = useState(0);
  const [structure, setStructure] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  if (FuelPrices.error) toastr.error("error", FuelPrices.error);

  const fetchData = useCallback(async (values) => {
    console.log("asldkfmlaoksdmfo", values);
    await FuelPrices.getAllFuelPrices(page, rowsPerPage, values);
    await FuelPrices.getCountries();
    setEntries(() => {
      return {
        start: (page - 1) * rowsPerPage + 1,
        end: (page - 1) * rowsPerPage + FuelPrices.fuelPriceesLength,
      };
    });
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchData();
    setStructure([
      {
        label: "Date",
        description: "description...",
        filterLabel: "Date",
        filterName: "date",
        type: "dateSelect",
      },

      {
        label: "Country",
        description: "description...",
        filterLabel: "Country",
        filterName: "country",
        type: "countrySelect",
        dependencyOnMe: ["city"],
      },
      {
        label: "City",
        description: "description...",
        filterLabel: "City",
        filterName: "city",
        type: "citySelect",
        onlyCountry: "onlyCountry",
        dependency: {
          country: "country",
        },
      },
      {
        label: "Fuel Pump ",
        descrption: "description...",
        filterLabel: "Fuel Pump",
        filterName: "pump_id",
        type: "select",
        data: [
          { label: "All", value: "All" },
          { label: "HP Gas", value: "5c4181bd5862f018088f082b" },
          { label: "fuel PUmp", value: "5c416e935862f018088f0822" },

        ],
      },


    ]);
  }, [page, rowsPerPage, fetchData]);

  const handleDeleteFuelPrice = async (id) => {
    await FuelPrices.deleteFuelPriceById(id);
    fetchData();
    setconfirm_alert(false);
  };
  const getDate = (value) => {
    value = value + "";
    let date = value.slice(6);
    let month = value.charAt(4) + value.charAt(5);
    let year = value.substring(0, 4);

    return year + "-" + month + "-" + date;
  };
  return (
    <React.Fragment>
      <div className="page-content">
        {/* {confirm_alert ? (
          <DeleteAlert
            onConfirm={() => {
              handleDeleteFuelPrice(delete_id);
            }}
            onCancel={() => setconfirm_alert(false)}
          />
        ) : null} */}
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Settings" breadcrumbItem="FuelPrice" />
          {/* Table */}
          <Card>

            <CardTitle>
              <div className="mx-3 mt-3">
                <div className="row d-flex pt-2 ">
                  <div className="col-8 col-sm-11">
                    {structure.length > 0 && (
                      <Filter
                        className="mb-2"
                        structure={structure}
                        applyFunc={fetchData}
                      />
                    )}
                  </div>


                  <div className="col-2  col-sm-1">
                    <AddButton link="/fuel-price/edit" />
                  </div>




                </div>
              </div>
            </CardTitle>

            <CardBody>
              <div className="table-responsive table-scroll">
                {
                  <Table
                    className="table mb-0 table-striped"
                    style={{ minHeight: "50vh", maxHeight: "50vh" }}
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th className="col-2">Date</th>
                        <th className="col-3">Country/City</th>
                        <th className="col-1">Fuel Pump</th>
                        <th className="col-2">Cost Details</th>
                        <th className="col-1 ">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {FuelPrices.fuelprices?.map((fuelPrice) => (
                        <tr>
                          <td>{getDate(fuelPrice?.date)}</td>

                          <td>
                            {capitalize(fuelPrice?.city)}{" "}
                            {capitalize(fuelPrice?.country)}
                          </td>
                          <td>{fuelPrice.forPump ? "Yes" : "No"}</td>
                          <td>
                            {fuelPrice.diesel &&
                              `${capitalize("diesel")} ${fuelPrice.diesel} ${fuelPrice.currency
                              }`}
                            <br />
                            {fuelPrice.petrol &&
                              `${capitalize("petrol")} ${fuelPrice.petrol} ${fuelPrice.currency
                              }`}
                            <br />
                            {fuelPrice.cng &&
                              `${capitalize("cng")} ${fuelPrice.cng} ${fuelPrice.currency
                              }`}
                            <br />
                            {fuelPrice.elec &&
                              `${"elec"} ${fuelPrice.elec} ${fuelPrice.currency
                              }`}
                          </td>
                          <td>
                            <Edit to={`/fuel-price/edit/${fuelPrice?._id}`} />
                            <Delete
                              onClick={() => {
                                setconfirm_alert(true);
                                setdelete_id(fuelPrice?._id);
                              }}
                              className="mdi mdi-delete text-danger font-size-18 me-1"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                }
              </div>
            </CardBody>
            <Pagination
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              total={FuelPrices.totalFuelPrices}
              totalPages={FuelPrices.totalFuelPricePages}
              start={entries.start}
              end={entries.end}
            />
          </Card>
        </Container>
      </div>
      {/* <EditFuelPrice/> */}


    </React.Fragment>
  );
};

export default observer(FuelPrice);
