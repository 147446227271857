export const API_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};




export const SOCKET_BASE_URL = 'http://164.52.214.106:4005'

export const ENDPOINTS = {
  cpanelLogin: "/api/o/v2/cpanel",
  cpanelgetCaptcha: `/api/o/captcha/cpanelLogin?id=${Date.now()}`,
  LoginGetCaptcha: `/api/otp/create?color=FFFFFF`,
  signUpSelf: `api/o/corporate/signup`,
  updateCorporate: `/api/corporate/update`,
  getDetail: (id) => `/api/corporate/${id}/detail`,
  changePhone: "/api/o/supervisor/change/phone",
  changeEmail: "/api/o/supervisor/change/email",
  getWebContent: `/api/web-content`,
  login: "/auth/login",
  forgetPassword: "/api/o/supervisor/password/reset",
  logout: "/auth/signout",
  signUp: "/auth/b2c/new/customer",
  createOrder: "/api/o/order/create",
  makeQrCode: "/api/o/make/qrcode",
  updateProfile: " /api/customer/profile",
  updateUserAddress: "/api/customer/address",
  updateUserQR: "/api/customer/qr",
  removeQR: "/api/customer/qr",
  getProfile: "/api/corp/profile/data",
  userContent: "/api/profile",
  allProfiles: "/api/profile/all",
  allProfiles: (forSuperadmin) => {
    let url = "/api/profile/all";
    if (forSuperadmin) url += `/list?for${forSuperadmin ? "superadmin" : "supervisor"}&`;
    return url;
  },
  getCaptcha: `/api/o/captcha/forgetPassword?id=${Date.now()}`,
  getUserOrders: "/api/order/grid",
  // forgetPassword: "/auth/customer/password/reset",
  getInvoices: (page) =>
    `api/invoice/corp/subscription/grid?page=${page}&size=30`,
  updateOrder: "/api/order/update",
  getOrder: "/api/order/:id/detail",
  addProduct: "/api/product/save",
  getProducts: "/api/product/grid",
  getProductsById: (id) => `/api/product/${id}/detail`,
  delProduct: (id) => `/api/product/${id}/delete`,
  addressSuggestions: (query) => `/api/o/address/autocomplete?query=${query}`,
  getAddress: (id) => `/api/o/address/${id}`,
  getShippingCharges: `/api/o/order/shipping/amount`,
  // getAllFuelPump : `/api/fuelpump/getAllFuelPump`
  getAllFuelPump: `/api/o/language/allLang`,
  getAllClients: `/api/corp/clients/list?withSelf=true`,
  getAllCurrencies: `/api/o/currencies`,
  getAddressLine: (id) => `/api/o/maputil/autocomplete?key=${id}`,
  getClientGroups: `/api/clientgroup/map`,
  deleteSupervisorById: (id) => `/api/supervisor/${id}/delete`,
  deleteOfferById: (id) => `/api/offer/${id}/delete`,
  getAllSOSLogs: (page, sizePerPage, filterData) => {
    let s = `/api/soslog/data?sortBy=_id&sortAsc=false&rows=${sizePerPage}`;
    if (filterData) {
      if (filterData.from_date) s += "&from_date=" + filterData.from_date;
      if (filterData.to_date) s += "&to_date=" + filterData.to_date;
      if (filterData.status !== "") s += "&status=" + filterData.status;
    }
    return s + `&page=${page}`;
  },
  download: (filterUrl, template_id) => {
    let url = `/api/fuel/download/template?template_id=${template_id}&`;
    if (filterUrl) url += filterUrl;
    return url;
  }, // /api/fuel/download/template

  getAllSupervisor: (page, sizePerPage, filterUrl) => {
    let url = `/api/supervisor/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + `&page=${page}`;
  },

  getZoneById: (id) => `/api/zone/${id}/detail`,
  getAllZones: (page, sizePerPage, name) =>
    `api/zone/grid?rows=${sizePerPage}&name=${name}&page=${page}`,
  getCitiesFromCountry: (country) => `/api/city/select/data?country=${country}`,
  getAllZoneVendors: (page, sizePerPage, name) =>
    `/api/corp/client/vendor/data/vendors?page=1&rows=100&withSelf=true`,
  getAllZoneCLients: (id) =>
    `/api/corp/client/vendor/data/clients?page=1&rows=100&withSelf=true`,
  getCountries: `/api/o/country/list`,
  getVendors: `/api/corp/vendors/self/map`,
  getStatesByCountry: (country) => `/api/o/country/${country}/states`,
  getCitiesByCountryAndState: (country, state) =>
    `/api/o/country/${country}/state/${state}/city/list`,
  getSupervisorById: (id) => `/api/supervisor/${id}/get`,

  getAllZonesList: `/api/zone/map/list`,
  updateSOSLog: `/api/soslog/status/update`,
  getFilteredClients: (
    filterClientUniqueId,
    filterClientName,
    filterClientZone,
    filterClientCountry,
    filterClientCity
  ) =>
    `/api/corp/client/vendor/data/clients?page=1&rows=100&fullName=${filterClientName}&zone_id=${filterClientZone}&country=${filterClientCountry}&city=${filterClientCity}&withSelf=true&unique_id=${filterClientUniqueId}`,
  getFilteredVendors: (filterVendorName) =>
    `/api/corp/client/vendor/data/vendors?page=1&rows=100&fullName=${filterVendorName}&withSelf=true`,
  editZone: (zoneId) => `/api/zone/${zoneId}/edit`,
  saveNewOffer: `/api/offer/new`,
  saveNewZone: `/api/zone/save`,
  getEntityById: (id) => `/api/businessUnits/list?corp_id=${id}`,
  getUserRole: "/api/role/map",
  changeSupervisor: "/api/supervisor/save",
  getSupervisorZones: () => `/api/corp/zones/get`,
  getClientsByZone: (id) => `/api/zone/${id}/clientByZone`,
  getCitiesByZone: (id) => `/api/zone/${id}/citiesByZone`,
  deleteZoneById: (id) => `/api/zone/${id}/delete`,
  getAllCountries: `/api/o/country/all?rows=-1`,
  getAllSupervisorRoles: (page, sizePerPage) =>
    `/api/role/grid?rows=${sizePerPage}&page=${page}`,
  getSupervisorRoleById: (id) => `/api/role/${id}/detail`,
  saveNewSupervisorRole: `/api/role/save`,
  deleteSupervisorRoleById: (id) => `/api/role/${id}/delete`,
  getAllFuelPrices: (page, sizePerPage, filterName) => {
    let url = `/api/fuelcost/grid?rows=${sizePerPage}&page=${page}&`;
    if (filterName) url += filterName;
    return url + `&page=${page}`;
  },
  supervisorRoleMap: `/api/access/right/map`,
  getAllBookingVehicles: (page, sizePerPage, filterUrl) => {
    let url = `/api/fuel/summary/grid?sortAsc=false&sortBy=yearMonth&rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + `&page=${page}`;
  },
  getFuelById: (id) => `/api/fuel/${id}/detail`,
  deleteFuel: (id) => `/api/fuel/${id}/delete`,
  saveFuel: `/api/fuel/save`,
  getAllVehicles: `/api/assign/booking/vehicle/data?rows=-1`,
  getFuelSummaryTimeSheet: (id) => `/api/fuel/${id}/trips?forMonthly=true`,
  getAllFundAccounts: `/api/fund/account/grid`,
};

export const PackOptions = [
  {
    id: 1,
    value: "4",
    price: 20,
  },
  {
    id: 2,
    value: "20",
    price: 30,
  },
  {
    id: 3,
    value: "50",
    price: 50,
  },
  {
    id: 4,
    value: "100",
    price: 70,
  },
];

export const StatusOptions = [
  {
    id: 0,
    value: "Unpaid",
  },
  {
    id: 1,
    value: "Open",
  },
  {
    id: 2,
    value: "Packed",
  },
  {
    id: 3,
    value: "Dispatched",
  },
  {
    id: 4,
    value: "Delivered",
  },
];

export const AdhesiveCardTypes = [
  {
    id: 1,
    name: "Regular",
    desc: "Adheres to most surfaces, plastic, wood, paint and won't damage surface upon removal",
  },
  {
    id: 2,
    name: "Glass",
    desc: "Stickers come with an additional adhesive seal to adhere to outdoor and indoor windows.",
  },
  {
    id: 3,
    name: "Metal",
    desc: "Tougher adhesive and thicker materials to block interference when adhered to metal surfaces",
  },
];
export const adminHeaders = ["Date", "Order No.", "Quantity", "Cost", "Status"];

export const sideNavItems = [
  {
    link: "/orders",
    name: "Orders",
    role: "User",
    picture: "/assets/images/orders-icon.png",
  },
  {
    link: "/account",
    name: "Account",
    role: "User",
    picture: "../../assets/images/orders-icon.png",
  },
  {
    link: "/products",
    name: "Products",
    role: "Admin",
    picture: "../../assets/images/orders-icon.png",
  },
];

export const products = [
  {
    picture: "../../assets/images/qr-image.png",
    name: "Covid Stickers Glass",
    price: "$40 NZD",
  },
  {
    picture: "../../assets/images/qr-image.png",
    name: "Covid Stickers Glass",
    price: "$40 NZD",
  },
  {
    picture: "../../assets/images/qr-image.png",
    name: "Covid Stickers Glass",
    price: "$40 NZD",
  },
  {
    picture: "../../assets/images/qr-image.png",
    name: "Covid Stickers Glass",
    price: "$40 NZD",
  },
  {
    picture: "../../assets/images/qr-image.png",
    name: "Covid Stickers Glass",
    price: "$40 NZD",
  },
  {
    picture: "../../assets/images/qr-image.png",
    name: "Covid Stickers Glass",
    price: "$40 NZD",
  },
];

export const MapStyles = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [{ color: "#ffffff" }, { lightness: 17 }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [{ color: "#ffffff" }, { lightness: 18 }],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [{ color: "#ffffff" }, { lightness: 16 }],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#dedede" }, { lightness: 21 }],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [{ visibility: "on" }, { color: "#ffffff" }, { lightness: 16 }],
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ saturation: 36 }, { color: "#333333" }, { lightness: 40 }],
  },
  {
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [{ color: "#fefefe" }, { lightness: 20 }],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
  },
];


export const rateNames = [
  { label: "1 HR 20 MINS", value: "1 HR 20 MINS", },
  { label: "12 Hr 120 KMs", value: "12 Hr 120 KMs", },
  { label: "Outstation-300", value: "Outstation-300", },
  { label: "Outstation-250", value: "Outstation-250", },
  { label: "12 Hr 100 KMs", value: "12 Hr 100 KMs", },
  { label: "Airport PickOrDrop", value: "Airport PickOrDrop", },
  { label: "12 Hr 3000 KMs", value: "12 Hr 3000 KMs", },
  { label: "Shuttle", value: "Shuttle", },
  { label: "Outstation", value: "Outstation", },
  { label: "Airport Pickup", value: "Airport Pickup", },
  { label: "Airport Drop", value: "Airport Drop", },
  { label: "Airport Pick/Drop", value: "Airport Pick/Drop", },
  { label: "Pick n Drop", value: "Pick n Drop", },
  { label: "Full Day", value: "Full Day", },
  { label: "Half Day", value: "Half Day", },
  { label: "8 Hr 80 KMs", value: "8 Hr 80 KMs", },
  { label: "6 Hr 60 KMs", value: "6 Hr 60 KMs", },
  { label: "5 Hr 50 KMs", value: "5 Hr 50 KMs", },
  { label: "4 Hr 40 KMs", value: "4 Hr 40 KMs", },
  { label: "3 Hr 30 KMs", value: "3 Hr 30 KMs", },
  { label: "2 Hr 20 KMs", value: "2 Hr 20 KMs", },
  { label: "1 Hr 10 KMs", value: "1 Hr 10 KMs", }
];


export const rateNamesValues = {
  "1 HR 20 MINS": { allottedKms: 10, allottedMins: 20 },
  "12 Hr 120 KMs": { allottedKms: 120, allottedMins: 720 },
  "Outstation-300": { allottedKms: 300, allottedMins: undefined },
  "Outstation-250": { allottedKms: 250, allottedMins: undefined },
  "12 Hr 100 KMs": { allottedKms: 100, allottedMins: 720 },
  "Airport PickOrDrop": { allottedKms: 80, allottedMins: 480 },
  "12 Hr 3000 KMs": { allottedKms: 3000, allottedMins: 720 },
  "Shuttle": { allottedKms: 0, allottedMins: 0 },
  "Outstation": { allottedKms: 250, allottedMins: 1440 },
  "Airport Pickup": { allottedKms: 0, allottedMins: 0 },
  "Airport Drop": { allottedKms: 0, allottedMins: 0 },
  "Airport Pick/Drop": { allottedKms: 0, allottedMins: 0 },
  "Pick n Drop": { allottedKms: 0, allottedMins: 0 },
  "Full Day": { allottedKms: 80, allottedMins: 480 },
  "Half Day": { allottedKms: 40, allottedMins: 240 },
  "8 Hr 80 KMs": { allottedKms: 80, allottedMins: 480 },
  "6 Hr 60 KMs": { allottedKms: 60, allottedMins: 360 },
  "5 Hr 50 KMs": { allottedKms: 50, allottedMins: 300 },
  "4 Hr 40 KMs": { allottedKms: 40, allottedMins: 240 },
  "3 Hr 30 KMs": { allottedKms: 30, allottedMins: 180 },
  "2 Hr 20 KMs": { allottedKms: 20, allottedMins: 120 },
  "1 Hr 10 KMs": { allottedKms: 10, allottedMins: 60 }
}

export const pathItems = {
  "": "Home",
  "dashboard": "Dashboard",
  "booking": "Booking",
  "invoice": "Invoice",
  "route": "Route",
  "rate": "Rate",
  "user": "Customers",
  "itinerary": "Itinerary",
  "flight": "Flight",
  "stay": "Stay",
  "tour": "Tours",
  "invoiceconfig": "Invoice Config",
  "creditNote": "Credit Note",
  "bill": "Bills",
  "payment-made": "Payment Made",
  "accounts": "Accounts",
  "tax": "Tax",
  "expense": "Expense",
  "payment-gateway-account": "Payment Gateway",
  "term": "Payment Terms",
  "coming-soon": "Invoice Report",
  "timesheet": "Timesheet",
  "feedback": "Feedback",
  "incident": "Incident",
  "fuel": "Fuel",
  "supervisor": "Supervisors",
  "role": "Role",
  "zones": "Zone",
  "leave": "Leave",
  "crm": "Leads",
  "vehicle-type": "Vehicle Type",
  "superAdmin": "Super Admin",
  "make-model": "Make Model",
  "country": "Country",
  "state": "State",
  "city": "City",
  "agent": "Message Agent",
  "messagetemplate": "Message Templates",
  "language": "Language",
  "rateName": "RateName",
  "corporate": "Corporate",
  "payment-gateway": "Payment Gateway",
  "supervisor": "Supervisors",
  "role": "Role",
  "zones": "Zone",
  "leave": "Leave",
  "crm": "Leads",
  "configurations": "Configuration",
  "smsLogs": "Logs",
  "custom-field": "Custom Fields",
  "qc": "Quality Check",
  "templates": "Templates",
  "tickets": "Tickets",
  "employee": "Employees",
  "supplier": "Suppliers",
  "client": "Clients",
  "garage": "Garages",
  "vehicle": "Vehicles",
  "office": "Offices",
  "company": "My Companies",
  "transaction":"Transactions",
  "payment-received":"Payment Received"
};


export const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
