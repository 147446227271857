import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET, doPOST } from "../../../util/HttpUtil";

class ContentTypeSelect {
  contents = [];
  constructor() {
    makeAutoObservable(this);
  }
  fetch = async function (filterData) {
    if (this.contents.length > 0) return;
    try {
      const response = await doGET(ENDPOINTS.getallContents);
      console.log(response);
      if (response.status === 200) {
        this.contents = response.data.map((d) => {
          return { label: d, value: d };
        });
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };
}

const ContentSelectService = new ContentTypeSelect();
export default ContentSelectService;
