import React, { useContext, useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { I18nContext } from "../../../store/context/i18nContext";
import { useNavigate, useParams } from "react-router-dom";
import TagsSelectService from "./TagService";
import EditLink from "../../Buttons/Icons/editButton";
import DeleteLink from "../../Buttons/Icons/deleteButton";
import { DialogContext } from "../../../store/context/DialogContext";
import AddButton from "../../Buttons/AddButton";
import { Row, Col } from "reactstrap";
import { InputField } from "../../InputField/InputField";
import SaveButton from "../../Buttons/SaveButton";
import { SelectField } from "../../SelectField/SelectField";
const Tags = () => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [tags, setTags] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [hasErr, setHasErr] = useState(false);
  const [tag, setTag] = useState({});
  let navigate = useNavigate();
  const fetchData = () => {
    fetch("/api/tag/all")
      .then((response) => response.json())
      .then((data) => {
        setTags(data);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    fetchData();
  }, []);
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete Tags?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        const response = await TagsSelectService.delete(id);
        navigate(`/demo`);
      } catch (e) {
        showError(e);
      }
    }
  };
  const handleColorChange = (color) => {
    setSelectedColor(color);
  };
  const colorPalette = [
    "#ff0000",
    "#00ff00",
    "#0000ff",
    "#ffff00",
    "#00ffff",
    "#ff00ff",
  ];
  const ColorCircle = (hexCode) => {
    const style = {
      width: "10px",
      height: "10px",
      borderRadius: "25px",
      backgroundColor: hexCode,
    };

    return <div style={style} />;
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setHasErr(true);
    if (!tag.name || !tag.kind) return;
    setLoading(true);
    try {
      const response = await TagsSelectService.save(tag);
      TagsSelectService.tags.push(tag);
      showMessage("Tag Saved Successfully");
      fetchData();
    } catch (e) {
      showError(e);
    }
  };
  const KindType = [
    {
      label: "Vendor",
      value: "1",
    },
    {
      label: "Client",
      value: "2",
    },
    {
      label: "Booking",
      value: "3",
    },
    {
      label: "Route",
      value: "4",
    },
    {
      label: "Employee",
      value: "5",
    },
    {
      label: "Tariff",
      value: "6",
    },
  ];
  const ColorPalette = () => (
    <div>
      {colorPalette.map((color) => (
        <button
          key={color}
          style={{ backgroundColor: color, width: "20px", height: "20px" }}
          onClick={() => handleColorChange(color)}
        />
      ))}
    </div>
  );
  return (
    <div>
      <AddButton
        onClick={() => {
          setTag({});
          setToggle(true);
        }}
      />
      {toggle ? (
        <div>
          <Row className=" mt-4 ml-4">
            <Col className="col-lg">
              <InputField
                value={tag?.name}
                label={"Name"}
                onChange={(v) => setTag({ ...tag, name: v })}
              />
            </Col>
            <Col className="col-lg">
              <SelectField
                data={KindType}
                value={tag?.kind}
                label={"Kind"}
                onChange={(v) => setTag({ ...tag, kind: v?.value })}
              />
            </Col>

            <Col className="col-lg">
              <ColorPalette
                className="col-sm-2"
                value={tag?.color}
                onChange={(v) => setTag({ ...tag, color: v })}
              />
            </Col>
            <SaveButton onClick={submitHandler} />
          </Row>
        </div>
      ) : (
        ""
      )}
      <Layout>
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>{t("Name")}</Th>
              <Th>{t("Color")}</Th>
              <Th>{t("Kind")}</Th>
              <Th className="d-flex justify-content-end">{t("Action")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {tags?.map((ele, id) => (
              <Tr key={id}>
                <Td scope="row">{ele?.name}</Td>
                <Td scope="row">{ColorCircle(ele?.color)}</Td>

                <Td scope="row">{ele?.kind} </Td>
                <Td>
                  <EditLink
                    onClick={() => {
                      setToggle(true);
                      setTag(tags[id]);
                    }}
                  />
                  <DeleteLink
                    onClick={(event) => {
                      onDelete(event, ele?._id);
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout>
    </div>
  );
};

export default Tags;