import React from 'react'
import { SelectField, SelectOption } from '../../../components';

const MarketPlaceStep2 = ({ data, setData }) => {

    const employeeCountOptions = ['1-10', '10-100', '100-500', '500-1000'];
    return (

        <SelectOption
            options={employeeCountOptions}
            onSelect={(selected) => {
                setData({
                    ...data,
                    employeeCount: selected[0]
                })
            }}
            label='Employee Count'
        />


    )
}

export default MarketPlaceStep2