import { identity } from "lodash";

export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/ticket/grid?`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    get: (id) => `/api/ticket/${id}/detail`,
    save: `/api/ticket/create`,
    sendComments:(id)=> `/api/ticket/comment?ticket_id=${id}`,
    delete: (id) => `/api/ticket/${id}/delete`,
    bulkDelete: (id) => `/api/ticket/delete`,
    update: `/api/ticket/update`,
};

export const SELECTSTRUCTURE = {
    priority: [
        { label: "Low", value: 1 },
        { label: "Medium", value: 2 },
        { label: "High", value: 3 },
    ],

    status: [
        { label: "Open", value: 0 },
        { label: "In Progress", value: 1 },
        { label: "Closed", value: 2 },
        { label: "Rejected", value: 3 },
        { label: "Client Input Required", value: 4 },
    ]
}

export const STRUCTURE = [
    {
        label: "Category",
        filterLabel: "Category",
        filterName: "category",
        type: "text",
    },
    {
        label: "Status",
        filterLabel: "Status",
        filterName: "status",
        type: "select",
        data: SELECTSTRUCTURE?.status,

    },
    {
        label: "Priority",
        filterLabel: "Priority",
        filterName: "priority",
        type: "select",
        data: SELECTSTRUCTURE?.priority,
    },
];
