import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { CardThreeBounce, InputField, SelectField, DateSelect, } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import FundAccService from "./FundAccountService";
import { AccountTypes } from './FundAccountConstant'
const EditFundAccount = (props) => {
    const { data, setData, hasErr, setHasErr, id } = props;
    const { t } = useContext(I18nContext);
    const [freshData, setFreshData] = useState({});
    const [loading, setLoading] = useState(false);
    const { showError } = useContext(DialogContext);
    const fetch = async () => {
        let faD = {};
        try {
            if (id) faD = await FundAccService.get(id);
        } catch (error) {
            showError(error)
        }
        setFreshData(faD);
        setData(faD);
        setHasErr(false);
        setLoading(false);
    };
    useEffect(() => {
        setLoading(true);
        fetch();
        setHasErr(false);
    }, [id]);
    useEffect(() => {
        if (JSON.stringify(data) !== JSON.stringify(freshData)) {
            props?.onChange(true);
        } else {
            props?.onChange(false);
        }
    }, [data]);
    if (loading) return <CardThreeBounce />;
    return (
        <React.Fragment>
            {(id && !loading) || !id ? (
                <div className="p-0">
                    <Form className="p-4">
                        <Row>
                            <Col className="mt-3 col-sm-4 col-12">
                                <SelectField
                                    value={data?.accountKind}
                                    required={true}
                                    label={"Account kind"}
                                    data={AccountTypes}
                                    onChange={(v) => setData({ ...data, accountKind: v.value })}
                                    error={data?.accountKind?.length === 0 ? "This Field is Required" : ""}
                                    showErr={hasErr}

                                />
                            </Col>
                            <Col className="mt-3 col-sm-4 col-6">
                                <DateSelect
                                    value={data?.openingDate}
                                    required={true}
                                    label={"Account Opening Date"}
                                    onChange={(v) => setData({ ...data, openingDate: v })}
                                    error={data?.openingDate === 0 ? "This Field is Required" : ""}
                                    showErr={hasErr}

                                />
                            </Col>
                            <Col className="mt-3 col-sm-4 col-6">
                                {data?._id ? <span>Account Balance: {data?.balance}</span> : (
                                    <InputField
                                        value={data?.balance}
                                        required={true}
                                        label={"Opening Balance"}
                                        onChange={(v) => setData({ ...data, balance: v })}
                                        error={
                                            data?.balance === 0 ? "This Field is Required" : ""
                                        }
                                        showErr={hasErr}

                                        type='number'
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-3 col-sm-3 col-12">
                                <InputField
                                    value={data?.details?.accountName}
                                    required={true}
                                    label={"Account Name"}
                                    onChange={(v) => setData({ ...data, details: { ...data?.details, accountName: v } })}
                                    error={
                                        data?.accountName?.length === 0 ? "This Field is Required" : ""
                                    }
                                    showErr={hasErr}

                                />
                            </Col>
                            <Col className="mt-3 col-sm-3 col-12">
                                <InputField
                                    value={data?.details?.bankName}
                                    required={true}
                                    label={"Bank Name"}
                                    onChange={(v) => setData({ ...data, details: { ...data?.details, bankName: v } })}
                                    error={
                                        data?.bankName?.length === 0 ? "This Field is Required" : ""
                                    }
                                    showErr={hasErr}

                                />
                            </Col>
                            <Col className="mt-3 col-sm-3 col-12">
                                <InputField
                                    value={data?.details?.identificationNumber}
                                    required={true}
                                    label={"Identification Number"}
                                    onChange={(v) => setData({ ...data, details: { ...data?.details, identificationNumber: v } })}
                                    error={
                                        data?.identificationNumber?.length === 0 ? "This Field is Required" : ""
                                    }
                                    showErr={hasErr}

                                />
                            </Col>
                            <Col className="mt-3 col-sm-3 col-12">
                                <InputField
                                    value={data?.details?.bankIFSCCode}
                                    required={true}
                                    label={"IFSC Code"}
                                    onChange={(v) => setData({ ...data, details: { ...data?.details, bankIFSCCode: v } })}
                                    error={
                                        data?.bankIFSCCode?.length === 0 ? "This Field is Required" : ""
                                    }
                                    showErr={hasErr}

                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
            ) : (
                <CardThreeBounce />
            )}
        </React.Fragment>
    );
};
export default observer(EditFundAccount);