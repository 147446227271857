import React, { useEffect, useState } from 'react';
import { doGET, doPOST } from '../../util/HttpUtil';
import './Comment.scss';
import CommentItem from './CommentItem';
import { IoSend } from 'react-icons/io5';

const Comment = ({ recordType, recordOf, byType, forType }) => {
    const [comment, setComment] = useState('');
    const [commentsList, setCommentsList] = useState([]);

    useEffect(() => {
        fetchComments();
    }, [recordType, recordOf]);

    const fetchComments = async () => {
        if (recordType && recordOf) {
            try {
                const response = await doGET(`/api/comment/grid?recordType=${recordType}&recordOf=${recordOf}&rows=-1`);
                setCommentsList(response.data.rows);
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        }
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const postComment = async () => {
        try {
            await doPOST('/api/comment/create', {
                recordType,
                recordOf,
                byType,
                forType,
                content: {
                    remark: comment,
                },
            });
            setComment('');
            fetchComments();
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };

    return (
        <div className="comment-component d-flex flex-column flex-1 ">
            <div style={{ borderBottom: "1px solid #EBEBEB", }} className="flex-1 comments-grid">
                <div></div>

                {commentsList.map((comment) => (
                    <CommentItem key={comment._id} comment={comment} />
                ))}
            </div>

            <div className='d-flex align-items-center py-2 bg-white'>
                <textarea
                    type="text"
                    value={comment}
                    onChange={handleCommentChange}
                    placeholder="Write a comment..."
                    className=" my-0 ms-2 inputfield flex-1"
                    style={{ width: "100%", borderRadius: "0.375rem", outline: "none", border: "1px solid #ced4da", }}
                    rows="2"
                />

                <div className='d-flex ps-2 align-items-center'>
                    <IoSend onClick={postComment} size={16} style={{ cursor: "pointer" }} />
                    {/* <div className=' btn btn-outline-success' onClick={postComment}>Send</div> */}
                </div>
            </div>

        </div>
    );
};

export default Comment;
