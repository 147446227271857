import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Form, Row } from "reactstrap";
import { InputField, Tab } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import Entity from "../Company/Company";
import EditEntity from "../Company/EditCompany";
import EditOffice from "../Office/EditOffice";
import Office from "../Office/Office";
import EditSupervisors from "../Supervisor/EditSupervisors";
import Supervisor from "../Supervisor/Supervisor";
import SupervisorService from "./SupplierService";
import TagPicker from "../../components/TagSelector/TagPicker";


const EditSuppliers = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [data, setData] = useState(null);


  const fetch = async (editId) => {
    setLoading(true);
    try {
      let suppliers = await SupervisorService.get(editId);
      setData(suppliers);
      setLoading(false);
    } catch (error) {
      showError(error)
      setLoading(false);
    }
  };

  useEffect(() => {
    setData(null)
    setStep(1)
    setLoading(true);
    if (editId) fetch(editId);
    setLoading(false)
  }, [editId, SupervisorService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  return (
    <React.Fragment>
      {loading ?
        <div className="d-flex mt-5 justify-content-center align-items-center  flex-1">
          <ThreeBounce size={50} color="pink" />
        </div>
        :

        <Form className="p-0" style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}>
          <div style={{ marginTop: '6px' }}>
            <TagPicker
              kind={"supplier"}
              onChange={(v) => setData({ ...data, tags: v })}
              value={data?.tags}
            />
          </div>
          <Tab
            vertical={false}
            left
            activeTab={step}
            onChange={(data) => {
              setStep(data ?? 1);
            }}
            className="d-flex"
          >
            <div num={1} label={t("Details")}>
              <Row>
                <Card className="p-3 pl-1">
                  <CardBody>
                    <InputField
                      placeholder={t("Supplier")}
                      value={data?.name}
                      required={true}
                      label={t("Supplier  Name")}
                      onChange={(v) => setData({ ...data, name: v })}
                      error={data?.name?.length === 0 ? t("Please enter Supplier Name") : ""}
                      showErr={hasErr}
                      type="text"
                      className="col-sm-4 "
                    />
                  </CardBody>
                </Card>
              </Row>

              <Row>
                {(!editId) &&
                  <Card>
                    <h4 className="mt-3 ms-3">{t("Company Details")} </h4>
                    <EditEntity
                      callingType="supplier"
                      onChange={(entity) => { setData({ ...data, entityData: entity }) }}
                    />
                  </Card>
                }
              </Row>
              <Row>
                {(!editId) &&
                  <Card>
                    <h4 className="mt-3 ms-3">{t("Office  Details")}</h4>
                    <EditOffice
                      onChange={(office) => { setData({ ...data, officeData: office }) }}
                    />
                  </Card>
                }
              </Row>
              <Row>
                {(!editId) &&
                  <Card>
                    <h4 className="mt-3 ms-3">{t("Supervisor  Details")}</h4>
                    <EditSupervisors
                      hideForApiRole
                      onChange={(supervisor) => { setData({ ...data, supervisorData: supervisor }) }}
                    />
                  </Card>
                }
              </Row>
            </div>

            {editId && <div num={2} label={t("Company")}>
              {<Entity
                callingType="supplier"
                supplier_id={editId}
                insidePane={true}
                multiMode={false}
                hideFilterBar={true}
                dontShowCheck={true}
              />}
            </div>}

            {editId && <div num={3} label={t("Offices")}>
              {<Office
                org_id={editId}
                insidePane={true}
                multiMode={false}
                hideFilterBar={true}
                dontShowCheck={true}
              />}
            </div>}

            {editId && <div num={4} label={t("Supervisors")}>
              {<Supervisor
                corp_id={editId}
                insidePane={true}
                multiMode={false}
                hideFilterBar={true}
                dontShowCheck={true}
              />}

            </div>}
          </Tab>
        </Form>
      }

    </React.Fragment>
  );
};

export default observer(EditSuppliers);
