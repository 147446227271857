import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./SysConfigConstant";
import { doGET, doPOST } from "../../../../util/HttpUtil";

class Service {
  records = [];
  totalRecords = 0;

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (id) => {
    const response = await doGET(ENDPOINTS.detail);
    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
      return response.data;
    }
  };

  save = async function (data) {
    const response = await doPOST(ENDPOINTS.save, data);
    return response?.data;
  };

  mail = async function (data) {
    const response = await doPOST(ENDPOINTS.testMail, data);
    return response?.data;
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch();
  };
}

const SysConfigService = new Service();
export default SysConfigService;
