import React from "react";

export default function Step(props) {
    const { total = 0, checkSteps = [] } = props
    return (
        <>
            <div className=" d-flex">
                <div className={"stepBlock" + (checkSteps?.includes(props?.index + 1) ? " selected" : "")}>
                    <div className="circleWrapper" onClick={() => props?.updateStep(props?.index + 1)}>
                        <div className="circle">{props?.index + 1}</div>
                    </div>
                </div>
                {total?.length - 1 !== props?.index ? <hr className="beautiful-line" /> : null}

            </div>


        </>

    )
}