import { CompanySelect, SupplierSelect, ClientSelect } from "../../components";
export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Salutation",
            accessor: "salutation",
            show: true,
        },
        {
            title: "Name",
            accessor: "name",
            show: true,
        },
        {
            title: "Designation",
            accessor: "designation",
            show: true,
        },
        {
            title: "Email",
            accessor: "email",
            show: true,
        },
        {
            title: "Companies",
            accessor: "",
            renderer: (dataObj) => {
                return <div>
                    <CompanySelect
                        asLabel
                        multi
                        corpType="self"
                        value={dataObj?.entities}
                        onChange={(v) => { }}
                        className=""
                    />
                </div>;
            },
            width: 300,
            show: true,
        },
        {
            title: "Suppliers",
            accessor: "",
            renderer: (dataObj) => {
                return <div>
                    <SupplierSelect
                        asLabel
                        multi
                        value={dataObj?.suppliers}
                        onChange={(v) => { }}
                        className=""
                    />
                </div>;
            },
            width: 300,

            show: true,
        },
        {
            title: "Clients",
            accessor: "",
            renderer: (dataObj) => {
                return <div>
                    <ClientSelect
                        asLabel
                        multi
                        value={dataObj?.clients}
                        onChange={(v) => { }}
                        className=""
                    />
                </div>;
            },
            width: 300,

            show: true,
        },

        {
            title: "Phone",
            accessor: "phone",
            renderer: (dataObj) => {
                if (dataObj && dataObj?.phone) {
                    return <div>+{dataObj?.phone?.countryCode}-{dataObj?.phone?.phone} </div>;
                }

            },
            show: true,
        },

        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
