import moment from "moment";
export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Name",
            accessor: "name",
            show: true,
        },

        {
            title: "Info",
            accessor: "info",
            width: 150,
            show: true,
        },
        {
            title: "Issuing Authority",
            accessor: "issuingAuthority",
            show: true,
            required: true,
        },
        {
            title: "Start Date",
            renderer: (dataObj) => {
                const dateStr = dataObj?.startDate?.toString();
                const formattedDate = moment(dateStr, 'YYYYMMDD').format('DD  MMM  YYYY')
                const isValidDate = moment(dateStr, 'YYYYMMDD').isValid();

                return(
                <div>
                {isValidDate ? formattedDate : '--'}
            </div>
                );
            },
            accessor: "startDate",
            show: true,
        },
        {
            title: "Expiry",
            renderer: (dataObj) => {
                const dateStr = dataObj?.expiry?.toString();
                const formattedDate = moment(dateStr, 'YYYYMMDD').format('DD  MMM  YYYY')
                const isValidDate = moment(dateStr, 'YYYYMMDD').isValid();
                return(

                <div>
                {isValidDate ? formattedDate : '--'}
            </div>
                );
            },

            accessor: "expiry",
            show: true,
        },
        {
            title: "Cost",
            accessor: "cost",
            show: true,
            required: true,
        },

        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
