import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import {
  Col,
  Form,
  Row
} from "reactstrap";
import {
  AddressField,
  AmountField,
  Checkbox,
  Documents,
  EmployeeSelect,
  InputField,
  SelectField,
  VehicleSelect
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "./FuelConstant";
import FuelService from "./FuelService";
import "react-calendar/dist/Calendar.css";
import DateTimePickerWithInterval from "../../components/DateTimePickerWithInterval";
import StateService from "../Employee/EmployeeService";
import ProfileService from "../Profile/ProfileService";
import "./style.css";
import { FaDivide } from "react-icons/fa6";
import { getValue } from "../../util/Util";
import VehicleSelectService from "../../components/Dropdowns/VehicleSelect/VehicleSelectService";

const EditFuel = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    currency: "INR",
    qtyType: "LTR",
    address: {},
    fullTank: true
  });


  const [addername, setaddername] = useState("")

  const fetchAdder = async (id) => {
    try {
      const profileDetail = await ProfileService.getProfileDetail(id);
      setaddername(profileDetail?.name)
      if (!profileDetail) {
        profileDetail = await StateService.get(id);
      } else {
        console.log('Profile detail not available');
      }
      return profileDetail?.name
    } catch (error) {
    }
  };


  const fetchVehicleDetail = async (vehicle_id) => {
    try {
      let vehicle = await VehicleSelectService.get(vehicle_id);
      setData({ ...data, fuelType: data?.fuelType ?? vehicle?.vehicleExtn?.fuelType })
    } catch (error) {
      console.log("error")
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (data?.vehicle_id) {
      fetchVehicleDetail(data?.vehicle_id)
    }

  }, [data?.vehicle_id])



  const fetch = async () => {
    try {
      if (editId) {
        let fuel = await FuelService.get(editId);
        let adderName = await fetchAdder(fuel?.by)
        setData({ ...fuel, adder: adderName });
      }
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    setData({
      currency: "INR",
      qtyType: "LTR",
      address: {},
      fullTank: true
    });
    setLoading(true);
    fetch();
    setLoading(false);
  }, [editId, FuelService?.version]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <React.Fragment>
      {loading ? (
        <ThreeBounce />
      ) : (
        <Form
          className="p-3"
          style={{ height: "100%", margin: 0 }}
        >
          <Row style={{
            marginTop: 0,
            padding: "1rem",
            borderRadius: "0.5rem",
            fontSize: "12px",
          }}>
            <Card className="p-3">
              <Row className="mt-3">
                <Col>
                  <InputField
                    required
                    placeholder="Reciept No."
                    label="Reciept No."
                    value={data?.receiptNo}
                    onChange={(v) => {
                      setData({
                        ...data,
                        receiptNo: v,
                      });
                    }}
                    type="text"
                  />
                </Col>
                <Col>
                  <label className="mt-3" style={{ right: 20, position: "absolute", bottom: 50 }}>{editId && `Added By: ${addername != "" ? addername : "--"}`}</label>

                  <SelectField
                    data={SELECTSTRUCTURE.paymentMode}
                    value={data?.paymentMode}
                    onChange={(v) => {
                      setData({
                        ...data,
                        paymentMode: v.value,
                      });
                    }}
                    showErr={hasErr}
                    label={t("Payment Mode")}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <VehicleSelect
                    required={true}
                    label={"Vehicle"}
                    value={data?.vehicle_id}
                    onChange={(v) => {
                      setData({
                        ...data,
                        vehicle_id: v?.value, fuelType: null
                      });
                    }}
                    placeholder="Vehicle"
                  />
                </Col>
                <Col>
                  <EmployeeSelect
                    label={"Employee"}
                    value={data?.employee_id}
                    onChange={(v) => {
                      setData({
                        ...data,
                        employee_id: v?.value,
                      });
                    }}
                    placeholder="Employee"
                  />
                </Col>
              </Row>

              <Row className="my-2">
                <Checkbox
                  label={t("Out Station")}
                  checked={data?.isOutstation ?? false}
                  onClick={() => {
                    setData({
                      ...data,
                      isOutstation: !data?.isOutstation,
                    });
                  }}
                />
              </Row>

              <Row className="my-2">
                <Checkbox
                  label={t("Full Tank")}
                  desc={<b>* Mileage is calculated when fuel is added as full tank</b>}
                  checked={data?.fullTank ?? false}
                  onClick={() => {
                    setData({
                      ...data,
                      fullTank: !data?.fullTank,
                    });
                  }}
                />
              </Row>

              <Row>
                <Col>
                  <DateTimePickerWithInterval
                    placeholder={"Date"}
                    timestamp
                    label={t("Time")}
                    value={data?.time}
                    onChange={(v) => {
                      setData({
                        ...data,
                        time: v,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <SelectField
                    data={SELECTSTRUCTURE.fuelType}
                    editId={editId}
                    value={data?.fuelType}
                    onChange={(v) => {
                      setData({
                        ...data,
                        fuelType: v.value,
                        qtyType:
                          v.value === "PETROL" || v.value === "DIESEL"
                            ? "LTR"
                            : v.value === "CNG"
                              ? "KG"
                              : v.value === "ELECTRIC"
                                ? "KW"
                                : "LTR",
                      });
                    }}
                    showErr={hasErr}
                    label={t("Fuel Type")}
                  />
                </Col>
              </Row>

              <Row className="my-2">
                <AddressField
                  placeholder={"Address"}
                  label={"Address"}
                  value={data?.address}
                  onChange={(e) =>
                    setData({
                      ...data,
                      address: e,
                    })
                  }
                  style={{ fontSize: "16px", marginTop: 10 }}
                />
              </Row>
            </Card>

            <Card className="p-3 m-0">
              <table className="table-bordered fuel">
                <thead>
                  <tr>
                    <th style={{ maxWidth: "100px" }}>Meter Reading (KMs)</th>
                    <th>Quantity ({data?.qtyType})</th>
                    <th></th>
                    <th>Amount ({data?.currency})</th>
                    <th></th>
                    <th>Unit Price ({data?.currency})</th>
                  </tr>
                </thead>
                <tbody className="fuel-table-hover position-relative">
                  <tr>
                    <td>
                      <InputField
                        outline
                        value={!!data?.kms ? data?.kms : 0}
                        onChange={(v) => {
                          setData({
                            ...data,
                            kms: parseInt(v),
                          });
                        }}
                        type="number"
                      />
                    </td>
                    <td>
                    <AmountField outline value={getValue(data.qty, 0)}
                        scaleFactor={1000}
                        onChange={(v) => {
                          setData({
                            ...data,
                            qty: (v),
                          });
                        }}
                      />



                    </td>
                    <td>
                    </td>
                    <td>


                    <AmountField outline value={getValue(data?.amount, 0)} onChange={(v) => {
                        setData({
                          ...data,
                          amount: v,
                          unitPrice: (v / (Math.max(1000, getValue(data?.qty, 1)))) * 1000,
                        });
                      }} />
                    </td>
                    <td>=</td>
                    <td>
                      <AmountField
                        asLabel
                        outline
                        value={!data?.qty ? 0 : (getValue(data?.amount, 0) / (Math.max(1000, getValue(data?.qty, 1)))) * 1000}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
            {
              data?._id ? (
                <div className="mt-3">
                  <Documents
                    module="fuel"
                    record_id={data?._id} />
                </div>
              ) : null
            }
          </Row>
        </Form>
      )}
    </React.Fragment>
  );
};

export default observer(EditFuel);
