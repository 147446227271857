import { makeAutoObservable } from "mobx";

import { API_METHODS, ENDPOINTS } from "../../common/utils/Constants";
import { ApiHandler } from "../../store/apiHandler/apiHandler";
import { doGET, doPOST } from "../../util/HttpUtil";

class FuelStore {
  fuels = [];
  totalFuels = 0;
  totalFuelPages = 1;
  error = "";
  vehicles = [];
  fuelTypes = [];
  timeSheet = [];
  page = 1;
  rows = 20;

  fuelData = {
    _id: "",
    corp_id: "",
    status: "",
    country: "",
    zone_id: "",
    pump_id: "",
    assignedTo: "",
    seats: "",
    year: "",
    regNo: "",
    color: "",
    vehicleType: "",
    fuelType: "l",
    pic: "",
    kms: "",
    distUnit: "",
    kmsSetAt: "",
    createdAt: "",
    compliant: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  getAllFuels = async function (page, sizePerPage, filterData) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllBookingVehicles(
      page,
      sizePerPage,
      filterData
    );
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        this.fuels = response.data.rows;
        this.totalFuels = response.data.records;
        this.totalFuelPages = response.data.total;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };
  getFuelById = async function (id) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getFuelById(id);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        this.fuelData = response.data;
        return response.data;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };
  getAllVehicles = async function () {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllVehicles;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        this.vehicles = response.data.rows.map((v) => {
          return { value: v._id, label: v.regNo };
        });
        return response.data.rows;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };
  getFuelSummaryTimeSheet = async function (id) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getFuelSummaryTimeSheet(id);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        this.timeSheet = response.data;
        return response.data;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };
  setDownloadFilter = (filterData) => {
    if (filterData) this.downloadFilterOption = filterData;
    else this.downloadFilterOption = "";
  };
  downloadByTemplate = async (filterData, template_id) => {
    const response = await doGET(
      ENDPOINTS.download(this.downloadFilterOption, template_id)
    );

    if (response.status === 200) {
      console.log(response);
      return response;
    }
  };

  get fuelsLength() {
    return this.fuels.length;
  }

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.getAllFuels();
  };

  save = async function (data) {
    const response = await doPOST(ENDPOINTS.saveFuel, data);
    this.fetch();
    return response;
  };

  delete = async function (id) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.deleteFuel(id);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status === 200) {
        return response.data;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };
}

const Fuels = new FuelStore();
export default Fuels;
