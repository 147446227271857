import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Form, Row } from "reactstrap";

import {
    BackArrow,
    CardThreeBounce, CitySelect, ClientSelect, CountrySelect, CrossButton,
    InputField,
    SaveButton,
    scrollStyle, Title, Layout, Checkbox
} from "../../components";
import { Tbody, Thead, Tr, Th, Td } from "react-super-responsive-table";
import { useNavigate } from "react-router-dom";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import HolidayConfService from "./HolidayConfService";

const EditHolidayConf = (props) => {
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const { id } = props;
    const [hasErr, setHasErr] = useState(false);
    const [freshData, setFreshData] = useState({});
    const [data, setData] = useState({});
    const navigate = useNavigate();
    const { userContent } = useContext(UserContext);

    const fetch = async () => {
        let holidayConfD = {};
        if (id) holidayConfD = await HolidayConfService.get(id);
        setFreshData(holidayConfD);
        setData(holidayConfD);
        setHasErr(false);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetch();
        setHasErr(false);
    }, [id]);

    useEffect(() => {
        if (JSON.stringify(data) !== JSON.stringify(freshData)) {
            props?.onChange(true);
        } else {
            props?.onChange(false);
        }
    }, [data]);

    const submitHandler = async (e) => {
        e.preventDefault();
        // console.log(data);
        setHasErr(true);
        // if (
        //     !data?.name ||
        //     !data?.country ||
        //     !data?.client_id
        // )
        //     return;
        setLoading(true);
        try {
            if (id) await HolidayConfService.edit(data, id);
            else {
                const response = await HolidayConfService.save(data);
                HolidayConfService.records.push(data);
                if (!props?.insidePane) navigate(`/holidayconf/edit`);
            }
            props?.fetchData();
            fetch();
        } catch (e) {
            showError(e)
        }
    };

    if (loading) return <CardThreeBounce />;

    return (
        <React.Fragment>
            {(id && !loading) || !id ? (
                <div className="p-0">
                    <Row>
                        <Col
                            style={props?.insidePane ? { width: "100%" } : scrollStyle}
                            className="col-12 col-sm-7 p-0 pe-3"
                        >
                            <Card>
                                <CardBody className="p-3">
                                    <BackArrow onClick={() => props?.back()} />
                                    <CrossButton onClick={() => props?.back()} />
                                    <Title>City Groups</Title>

                                    <Form className="pt-2" onSubmit={submitHandler}>
                                        <Row className="mt-3 mb-3 ">
                                            <Col className="col-lg">
                                                <InputField
                                                    type="text"
                                                    placeholder="Group Name"
                                                    label={"Group Name"}

                                                    value={data?.name}
                                                    onChange={(v) => {
                                                        setData({
                                                            ...data,
                                                            name: v
                                                        });
                                                    }}
                                                />
                                            </Col>

                                            <Col className="col-lg">
                                                <InputField
                                                    type="text"
                                                    placeholder="Description"
                                                    label={"Description"}

                                                    value={data?.description}
                                                    onChange={(v) => {
                                                        setData({
                                                            ...data,
                                                            description: v
                                                        });
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-3 mb-3">
                                            <Col className="col-lg">
                                                <InputField
                                                    type="number"
                                                    placeholder="Year"
                                                    label={"Year"}

                                                    value={data?.year}
                                                    onChange={(v) => {
                                                        setData({
                                                            ...data,
                                                            year: v
                                                        });
                                                    }}
                                                />
                                            </Col>

                                            <Col className="col-lg">
                                                <CountrySelect
                                                    placeholder="Country"
                                                    label={"Country"}
                                                    value={data?.country}
                                                    onChange={(v) => {
                                                        setData({
                                                            ...data,
                                                            country: v.name
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3 mb-3">
                                            <Layout
                                                            gridLoading={loading}
                                                hideTitleBar={true}
                                                className={'py-2'}
                                            >
                                                <Layout.TheadFull>
                                                    <Thead className="dark">
                                                        <Tr key={data?._id}>
                                                            <Th>{t("Holiday")}</Th>
                                                            <Th>{t("Date")}</Th>
                                                            <Th>{t("Optional")}</Th>
                                                            <Th>{t("Action")}</Th>
                                                        </Tr>
                                                    </Thead>
                                                </Layout.TheadFull>

                                                <Layout.TbodyFull>
                                                    <Tbody>

                                                    </Tbody>
                                                </Layout.TbodyFull>
                                            </Layout>
                                        </Row>
                                        <div className="d-flex justify-content-end mt-4 ">
                                            <SaveButton />
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            ) : (
                <CardThreeBounce />
            )}
        </React.Fragment>
    );
};
export default observer(EditHolidayConf);