import React, { useEffect, useState } from "react";
import { Card, Container, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {  useSearchParams } from "react-router-dom";
import Zones from "./store";
import DemoMobx from "../../store/mobx/demo/demo";
import { toJS } from "mobx";
import ClientPopup from "./ClientPopup";
// import { InputField } from "../Demo/InputField/InputField";

const Filter = () => {
  const [clientModal, setClientModal] = useState(false);
  // const [cities, setCities] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [searchParams] = useSearchParams();
  const [zone, setZone] = useState(null);
  const id = searchParams.get("id");

  // const [selectedCountry, setSelectedCountry] = useState("");
  const [COUNTRIES, setCountries] = useState([]);

  useEffect(() => {
    async function fetch() {
      await DemoMobx.getCountries();
      setCountries(toJS(DemoMobx.countries));
      if (id) {
        const zone = await Zones.getZoneById(id);
        // setSelectedCountry(zone.country);
        setZone(zone);
        setClientsList(zone?.clientsList);
        // setCities(zone?.cities);
      }
    }
    fetch();
  }, [id]);


  return (
    <React.Fragment>
      {((id && zone) || !id) && (
        <div className="page-content">
         

          {clientModal && (
            <ClientPopup
              modal={clientModal}
              setModal={setClientModal}
              title={"Clients"}
              setClientsList={setClientsList}
              clientsList={clientsList}
              COUNTRIES={COUNTRIES}
            />
          )}
  <Container fluid>
            <Breadcrumbs title="Zone" breadcrumbItem="Edit Zone" />
            <Card>
                    <Button
                      onClick={() => setClientModal(true)}
                      type="button"
                      color="primary"
                    >
                      Select Clients
                    </Button>
                    {clientsList?.map((client) => {
                      return (
                        <div>
                          {client?.name}
                          <i
                            onClick={() =>
                              setClientsList(
                                clientsList.filter((c) => c._id !== client._id)
                              )
                            }
                            className="mdi mdi-delete text-danger font-size-16 m-1 mt-3"
                          ></i>
                        </div>
                      );
                    })}
                    <br />

                    <div className="d-flex justify-content-between mt-3 ">
                      <Link to="/zone">
                        <Button type="button" color="primary">
                          Back
                        </Button>
                      </Link>

                      <Button type="submit" color="primary">
                        Save
                      </Button>
                    </div>

            </Card>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default observer(Filter);
