import { makeAutoObservable, toJS } from "mobx";
import UserService from "../Users/UserService";
import { ENDPOINTS, MAP_VIEW, POLYLINE_COLOR, ROSTER_TRIP_TYPE, initialData, initialRoster } from "./RosteringConstant";
import { filterAlreadyAssignedPassengers, generateUniqueId,  getPassengers, getTripMarkers, incrementLatLng, populatePassengerPickupOrDrop, populatePassengerResidence,getAllTripsPolyLines, populateTimeAndPassengerOnBasisOfRosterType, getUniqueMarkers, getTripPolyLine, isPickerTypeEvent, filterCustomerAccordingToSpaceType, populateResidence } from "./utils/helper";
import { doGET, doPOST } from "../../util/HttpUtil";
import BookingService from "../Booking/BookingService"; 
import { decodePolyline } from "../../components";
import OfficeService from "../Office/OfficeService";
import { OfficeMarkerIcon } from "../../components/OpenStreetMap/icons";
import { getFullAddress } from "../../util/Util";
class Service {
    records = [];//original records,
    data = [];//dragged records or filtered records
    STRUCTURE = [];
    page = 1;
    rowsPerPage = null;
    filterOption = "";
    total = -1;
    mapData = {};
    passengers = [];
    selectedTab = -1;
    selectedTrips = [];
    roster = {...initialRoster};
    office={};
    loading=false;
    initialized=false;
    spaceDetail=null

    constructor() {
        makeAutoObservable(this);
    }

    fetchPassengers = async (space_id,spaceType) => {
        //TODO or we can do is pass passengers_ids from trips in notin in customer_grid then don't have to filterout passengers
        // let filterPayloadUrl = `&space_id=${space_id}${filterCustomerAccordingToSpaceType(spaceType)}`;
        let filterPayloadUrl = `&space_id=${space_id}`;
        const records = await UserService.fetch(filterPayloadUrl, true);
        const passengers = getPassengers(records);

        return passengers;
    }
    resetData=()=>{
       this.records=[];
       this.data=[];
       this.roster= {...initialRoster};
       this.spaceDetail=null;
       this.mapData = {};
       this.passengers=[];
       this.initialized=false;
    }

    fetchRecords = async (space) => {
        try {
            this.resetData();
            const space_id=space?._id;
            this.loading = true;
            let data = await BookingService.postGrid(`space_id=${space_id}&rows=-1&withExtn=true`);
            data=populatePassengerResidence(data,space?.type)
            this.records = data;
            this.data = data;
            
            await this.updatePassengerList(space);
        } catch (error) {
            
        }finally{
            this.loading=false;
            this.initialized=true;
        }
    }

    updatePolyLineForTrip=async(trip)=>{
        try {
            if(!this.loading)this.loading=true;
            const id=trip.id;
            const polyLines = [...this.mapData?.polyLines]
            const currentTripPolyLineIndex = polyLines?.findIndex(polyline => polyline?.cardId === id);
            const tripCoordiantes = getTripPolyLine(trip);
            const tripPolyLine = await this.fetchPolyLinePath(tripCoordiantes.coordinates, tripCoordiantes.cardId);

            if (currentTripPolyLineIndex >= 0) {
                polyLines[currentTripPolyLineIndex] = { ...tripPolyLine, color: POLYLINE_COLOR.selected }
            } else {
                polyLines?.push({ ...tripPolyLine, color: POLYLINE_COLOR.selected })
            }
            this.updatePolyLines(polyLines)
        } catch (error) {
            
        }finally{
            this.loading=false;
        }
    }

    updateTrip = async (id, updatedRecord, deleted = false) => {
        try {
            this.loading = true;
            const index = this.records.findIndex(record => record.id === id);
            if (index !== -1) {
                if (deleted) {
                    this.records.splice(index, 1);
                    //remove its polyline
                    this.mapData.polyLines = this.mapData.polyLines.filter(polyline => polyline?.cardId !== id);
                    //remove its passengers from trip and populate in this.passengers
                    this.passengers=this.passengers.concat(updatedRecord?.passengers??[]);
                } else {
                    this.records[index] = {
                        ...this.records[index], 
                        ...updatedRecord,
                        passengers: (populateResidence(updatedRecord, this.spaceDetail?.type)?.passengers??[] )
                    };
                    await this.updatePolyLineForTrip(this.records[index])
                }
                this.data = this.records?.filter(record => this.selectedTab == -1 || record?.status == this.selectedTab)
            }
        } catch (error) {
            
        }finally{
            this.updatePassengerAssignment();
            this.loading=false;
        }
    }

    updatePassengerList = async(space) => {
        try {
            if(space){
                this.spaceDetail=space;
            }
            let passengers=await this.fetchPassengers(space?._id,space?.type);
            const tripPassengers = this.records?.flatMap(item => (item.passengers??[]));
            this.passengers=filterAlreadyAssignedPassengers(tripPassengers,passengers);//filter passengers already assigned to trip
            this.populateMapData(this.records, passengers); //to populate markers of all customers
        } catch (error) {
            
        }
    }

    populateMarkers=(passengers)=>{
        this.mapData.markers = getUniqueMarkers(
            this.mapData?.markers ?? [],
            getPassengers(passengers, true) ?? []
        );
    }

    populateMapData = async(trips, passengers) => {
        this.mapData.selectedView = MAP_VIEW.ALL
        const polyLineCoordinates=getAllTripsPolyLines(trips);
        this.mapData.polyLines=await this.fetchAllPolyLines(polyLineCoordinates)
        this.populateMarkers(passengers);
        this.updatePassengerAssignment();
    }

    //mark assigned or unassigned passengers
    updatePassengerAssignment = () => {
        const unassignedPassengers = this.passengers.map(passenger => ({
            ...passenger,
            assigned: false
        }));
        //assigned true in trippassengers
        const tripPassengers = this.records.flatMap(item => (item.passengers ?? []));
        tripPassengers.forEach(tripPassenger => {
            tripPassenger.assigned = true;
        });
        //updating markers for all passengers
        this.populateMarkers([...unassignedPassengers, ...tripPassengers]);
    }

    toggleMapData = ({ markers, polyLines, selectedView }) => this.mapData = { markers, polyLines, selectedView }

    //to change view selected on map
    onToggleSelectedView = (selectedView) => {
        //setSelectedView and change markers
        if (selectedView == MAP_VIEW.PASSSENGER_VIEW) {
            this.mapData.markers = getPassengers(this.passengers, true)
        } else if (selectedView == MAP_VIEW.TRIP_VIEW) {
            this.mapData.markers = getTripMarkers(this.records);
        } else {
            //select all
            this.mapData.markers = [
                ...(getPassengers(this.passengers, true) ?? []),
                ...(getTripMarkers(this.records) ?? [])
            ]
        }
        this.mapData.selectedView = selectedView
    }

    setPassengers = (passengers) => this.passengers = passengers

    updateData = (data) => this.data = data;//update data on drag and drop

    //to filter data on basis of status in roster trip
    filterData = (status) => {
        this.selectedTab = status
        this.data = this.records?.filter(record => status == -1 || record?.status == status)
    }

    //add new record in roster
    addNewRecord = (space) => {
        this.records.push({
            title: null,
            status: 0,
            id: generateUniqueId(),
            tripType:ROSTER_TRIP_TYPE[space?.type],
            // startTime:,//startime will be according space?.type
            passengers: [],
            src: null,
            dst: null
        })
        this.filterData(this.selectedTab)
    }

    updatePolyLines = (polyLines) => this.mapData.polyLines = polyLines;

    saveRoster = async (data) => {
        try {
            this.loading = true;
            const trips = this.records?.map(trip => ({
                ...trip,
                passengers: populatePassengerPickupOrDrop(trip.passengers, data?.type, data?.office)
                // ...(populateTimeAndPassengerOnBasisOfRosterType(
                //     data?.startTime,
                //     data?.reachBy, 
                //     data?.date, 
                //     data?.type,
                //     populatePassengerPickupOrDrop(trip.passengers, data?.type, data?.office),
                //     data?.office
                //     ) ?? {})
            }))
            const response = await doPOST(ENDPOINTS.save, {
                // passengers:getPassengers(this.passengers),
                passengers: populatePassengerPickupOrDrop(this.passengers, data?.type, data?.office),
                ...(trips?.length && { trips }),
                ...data,
                ...this.roster,
                radius:this.roster.radius*1000,
                returning:!isPickerTypeEvent(data?.type)
            });
            this.records =populatePassengerResidence(response?.data?.trips,data?.type) /* response?.data?.trips?.map(trip => ({ ...trip, passengers: getPassengers(trip?.passengers) })); */
            this.data = this.records?.filter(record => this.selectedTab == -1 || record?.status == this.selectedTab)

            const tripPassengers = response?.data?.trips?.flatMap(item => (item.passengers??[]));
            this.passengers=filterAlreadyAssignedPassengers(tripPassengers,this.passengers);
            const polyLineCoordinates=getAllTripsPolyLines(this.data);
            this.mapData.polyLines=await this.fetchAllPolyLines(polyLineCoordinates);
            this.updatePassengerAssignment();
        
        } catch (error) {
            console.log(error);
        }finally{
            this.loading = false;
        }
    }

    updateSelectedTrips=(tripId)=>{
        if(this.selectedTrips.indexOf(tripId) == -1){
            this.selectedTrips.push(tripId)
        }else{
            this.selectedTrips.splice(this.selectedTrips.indexOf(tripId),1)
        }
    }
    updateRoster=(field,value)=>this.roster[field]=value;

    fetchPolyLinePath=async(coordinates,tripId)=>{ 
            const response=await doPOST(`/api/geocode/path`,coordinates);
            return {
                cardId:tripId,
                data:response.data,
                color:POLYLINE_COLOR.default,
                weight:  4,
                opacity: 0.7,
                fillOpacity:  0.7,
            }
    }

    fetchAllPolyLines=async(tripLocations)=>{
       try {
        // const promises=tripLocations?.map(tripCoordinates=> doPOST(`/api/geocode/path`,tripCoordinates))
        // const response=await Promise.allSettled(promises)
        const promises=tripLocations?.map(tripCoordinates=> this.fetchPolyLinePath(tripCoordinates?.coordinates,tripCoordinates?.cardId))
        const response=await Promise.allSettled(promises)
        return response?.filter(res=>res?.status=="fulfilled")?.map(res=>res?.value);
        } catch (error) {
        console.log(error);
       }
    }

    updateOffice = async (office_id, org_id) => {
        try {
            const office = await OfficeService.get({ editId: office_id, org_id });
            this.office = office
            if (office?.address) {
                if (!this.mapData?.markers) {
                    this.mapData.markers = []
                }
                this.mapData?.markers?.push({
                    type: 'office',
                    id: office?._id,
                    title: office?.name ?? "",
                    address: getFullAddress(office?.address) ?? "",
                    icon: OfficeMarkerIcon,
                    lat: office?.address?.lat,
                    lng: office?.address?.lng
                })
            }
        } catch (error) {

        }
    }
    toggleLoading=(loading)=>this.loading=loading;
}

const RosteringService = new Service();
export default RosteringService;

