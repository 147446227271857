import React from 'react';
import "./style.css"

const SearchButton = ({ onClick, height,style }) => {
    return (
        <div
            className="search-side-btn d-flex align-items-center justify-content-center"
            style={{ 
                height: height ?? "34px", 
                ...(style ? style : {}) 
              }}              
            onClick={onClick}
        >
            <i className="mdi mdi-magnify text-dark font-size-18 mx-1" />
        </div>
    );
};

export default SearchButton;
