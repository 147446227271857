import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";

import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { GroupedSelectField } from "../../SelectField/GroupedSelectField";
import { ModalComponent, SearchButton } from "../../index";
import RateVehicleService from "./RateVehicleService";
import RateDataGrid from "../../../pages/Rate/RateDataGrid/RateDataGrid";

const RateVehicle = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  asLabel,
  plain,
  outline,
  filterOption,
  client_id,
  srcCountry,
  srcState,
  srcCity,
  supplier_id,
  date,
  firstDefaultRate,
  customerType,
  tripType
}) => {
  const [rates, setRates] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext);
  const { t } = useContext(I18nContext);

  const tripTypeRateTypeMap = {
    1: "LOCAL",
    2: "RENTAL",
    4: "TRANSFER",
    5: "SELF_DRIVE",
    6: "PACKAGE",
    7: "TRANSFER",
    8: "TRANSFER",
    9: "PACKAGE",
    10: "OUTSTATION",
    11: "PACKAGE",
  }

  const fetchRates = async () => {
    try {
      const response = await RateVehicleService.fetch({ customerType, client_id, srcCountry, srcState, srcCity, date, supplier_id, rateType: tripTypeRateTypeMap[tripType] });
      if (firstDefaultRate && !value) {
        const firstOptionWithDefaultSupplierRate = response
          .flatMap(item => item.options)
          .find(option => option.supplierRate && !option?.supplier_id);
        if (firstOptionWithDefaultSupplierRate?._id) {
          onChange(firstOptionWithDefaultSupplierRate);
        }
      }
      setRates(response);
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (date) {
      fetchRates();
    }
  }, [supplier_id, client_id, date, customerType, srcCountry, srcState, srcCity, tripType]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };


  const renderSelectField = ({ disabled }) => (
    <GroupedSelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}

      className={className}
      onChange={onChange}
      data={rates}
      required={required}
      value={value}
      grouped
      label={label || (!isDisabled && t("Rate Vehicle"))}
      error={!value ? t(`Please Select ${label || "Rates"}`) : ""}
      showErr={showErr}
      plain={plain}
      outline={outline}
      inline={inline}
      filterOption={filterOption}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );


  return (
    <>
      {asLabel ? (<div>{rates?.find((rate) => rate?.value == value)?.label ?? <div style={{ color: "grey" }}>No record Found</div>}</div>) :
        <>

          {modal && (
            <ModalComponent
              position={"top"}
              size={"medium"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Rates")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <RateDataGrid
                  value={value}
                  url={RateVehicleService.makeFilterUrl({ customerType, client_id, srcCountry, srcState, srcCity, date, supplier_id,})}
                  rateType={tripTypeRateTypeMap[tripType]}
                  insidePane={true}
                  onSelect={(selectedValue) => {
                      onChange({ ...selectedValue, value: selectedValue?._id });
                      closeModal();
                  }}
                />
              </ModalComponent.Body>

            </ModalComponent>
          )}



          {rates?.length > 0
            ? renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      }
    </>
  );
};

export default observer(RateVehicle);
