import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { InputField } from "../../components";
import { Form, Row } from "reactstrap";
import { CardThreeBounce } from "../../components";
import ApiLogService from "./ApiLogService";
import { secondsToyyyy_mm_ddThh_mm } from "./../../components/index";

const ApiLogDetail = (props) => {
  const { id, data, setData } = props;
  const [loading, setLoading] = useState(false);

  async function fetch() {
    let apiD = { status: 1 };
    if (id) apiD = await ApiLogService.get(id);
    setData({
      ...apiD,
      result: apiD?.result,
      apiName: apiD?.apiName,
      phone: apiD?.phone,
      payload: apiD?.payload,
      at: apiD?.at,
    });
    setLoading(false);
  }

  const setField = (timestamp) => {
    console.log(timestamp);
    let atstr = secondsToyyyy_mm_ddThh_mm(timestamp);
    if (atstr[13] + atstr[14] > "12") {
      atstr += " PM";
    } else {
      atstr += " AM";
    }
    return atstr;
  };

  useEffect(() => {
    setLoading(true);
    fetch();
  }, [id]);

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      <Form>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Row className="mb-2 pt-4 pb-2">
            <InputField
              value={data?.apiName}
              required={true}
              label={"API Name"}
              type="text"
              isDisabled={true}
            />
          </Row>
          <Row className="mb-2 py-2 px-6">
            <InputField
              value={data?.at ? setField(data?.at) : ""}
              required={true}
              label={"At"}
              type="text"
              isDisabled={true}
            />
          </Row>
          <Row className="mb-2 py-2 px-6">
            <InputField
              value={data?.phone}
              required={true}
              label={"Phone"}
              type="text"
              isDisabled={true}
            />
          </Row>
          <Row className="mb-2 py-2 px-6">
            <div className="my-3">
              <label>Payload</label>
              <textarea
                isDisabled={true}
                id="basicpill-address-input1"
                className="form-control"
                rows="2"
                required={true}
                value={data?.payload}
              />
            </div>
          </Row>
          <Row className="mb-2 py-2 px-6">
            <div className="my-3">
            <label>Result</label>
              <textarea
                isDisabled={true}
                id="basicpill-address-input1"
                className="form-control"
                rows="2"
                required={true}
                value={data?.result}
              />
            </div>
          </Row>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default observer(ApiLogDetail);
