// import moment from "moment";
// import DatePicker, { DateObject } from "react-multi-date-picker";
// import React, { useEffect, useState } from 'react'
// import { FaRegCalendar } from "react-icons/fa";
// import './style.css'

// const MultiDatePickerCalendar = ({
//     value = [],
//     label = "Date Picker",
//     onChange,
//     hideBorder,
//     innerContainerClassName,
//     noLabel,
//     required,
//     onBlur,
//     style, className, inputStyle, minDate = moment().toDate(), outline, inline, plain }) => {

//     let calenderModalWidth = 256;

//     const [currentWidth, setCurrentWidth] = useState(window?.innerWidth)
//     const [noOFCalendarModal, setNoOFCalendarModal] = useState(1)

//     useEffect(() => {
//         window.addEventListener('resize', (event) => {
//             setCurrentWidth(event?.target?.innerWidth)
//         })
//     }, [])

//     useEffect(() => {
//         let count = Math.floor(currentWidth / calenderModalWidth);
//         if (count >= 3) {
//             count = 3;
//         } else if (count < 0) {
//             count = 1;
//         }
//         setNoOFCalendarModal(count)
//     }, [currentWidth])

//     const CustomInput = ({ onFocus, onChange }) => {
//         let customValue = `dd-mm-yyyy`;

//         if (value?.length == 1) {
//             customValue = `${moment(value?.[0], 'YYYYMMDD').format('DD-MM-YYYY')} `
//         }
//         if (value?.length > 1) {
//             customValue = `${moment(value?.[0], 'YYYYMMDD').format('DD-MM-YYYY')} + ${value?.length - 1} Days`;
//         }

//         return (
//             <div className="d-flex align-items-center" >
//                 <input
//                     style={{
//                         border: "1px solid #ced4da",
//                         backgroundColor: "#ffffff",
//                         borderRadius: "6px",
//                         display: 'flex',
//                         padding: '4px',
//                         paddingLeft: "14px",
//                         height: "36px",
//                         justifyContent: "space-between",
//                         alignItems: "center",
//                         width: "225px"
//                     }}
//                     onFocus={onFocus}
//                     value={customValue}
//                     onChange={onChange}
//                 />
//                 <FaRegCalendar size={14} style={{
//                     position: 'relative',
//                     left: "-20px",
//                 }} />
//             </div>
//         )
//     }

//     const weekDays = ["S", "M", "T", "W", "T", "F", "S"]
//     return (
//         <div className="d-flex align-items-center ">
//             {(!outline) && <div style={{ minWidth: "120px" }}>
//                 {label}
//             </div>}
//             <DatePicker
//                 style={{
//                     position: 'relative',
//                     backgroundColor: 'red'
//                 }}
//                 numberOfMonths={noOFCalendarModal}
//                 weekDays={weekDays}
//                 placeholder={label}
//                 multiple
//                 // minDate={minDate}
//                 format="DD-MM-YYYY"
//                 render={<CustomInput />}
//                 value={value?.map(item => {
//                     const date = moment(item, 'YYYYMMDD').format('DD');
//                     const month = moment(item, 'YYYYMMDD').format('MM');
//                     const year = moment(item, 'YYYYMMDD').format('YYYY');
//                     return new DateObject().set({ day: date, month: month, year: year });
//                 })}
//                 onChange={(v) => {
//                     const formattedArray = v?.map(value => {
//                         const date = value?.day
//                         const month = value?.month?.number
//                         const year = value?.year;
//                         const momentDate = moment({ year, month: month - 1, date });
//                         return parseInt(momentDate.format('YYYYMMDD'));
//                     })
//                     // for(let i =0;i<formattedArray?.length;i++){
//                     //     if(value?.includes(formattedArray[i])){

//                     //     }else{

//                     //     }
//                     // }
//                     onChange([...formattedArray])
//                 }}
//             />
//         </div>
//     )
// }

// export default MultiDatePickerCalendar


import moment from "moment";
import DatePicker, { DateObject } from "react-multi-date-picker";
import React, { useEffect, useState } from 'react';
import { FaRegCalendar } from "react-icons/fa";
import './style.css';

const MultiDatePickerCalendar = ({
    value = [],
    label = "Date Picker",
    onChange,
    hideBorder,
    innerContainerClassName,
    noLabel,
    required,
    onBlur,
    style,
    className,
    inputStyle,
    minDate = moment().toDate(),
    outline,
    inline,
    plain
}) => {

    let calenderModalWidth = 256;

    const [currentWidth, setCurrentWidth] = useState(window?.innerWidth)
    const [noOFCalendarModal, setNoOFCalendarModal] = useState(1)

    useEffect(() => {
        window.addEventListener('resize', (event) => {
            setCurrentWidth(event?.target?.innerWidth)
        })
    }, [])

    useEffect(() => {
        let count = Math.floor(currentWidth / calenderModalWidth);
        if (count >= 3) {
            count = 3;
        } else if (count < 0) {
            count = 1;
        }
        setNoOFCalendarModal(count)
    }, [currentWidth])

    const CustomInput = ({ onFocus, onChange }) => {
        let customValue = `dd-mm-yyyy`;

        if (value?.length == 1) {
            customValue = `${moment(value?.[0], 'YYYYMMDD').format('DD-MM-YYYY')} `
        }
        if (value?.length > 1) {
            customValue = `${moment(value?.[0], 'YYYYMMDD').format('DD-MM-YYYY')} + ${value?.length - 1} Days`;
        }

        return (
            <div className="d-flex align-items-center multiDatePickeOutlineClass" >
                <label style={{
                    color: "#797979B1"
                }} className="floating-label">{label}</label>
                <input
                    className="multiDatePickeOutlineClassInput"
                    style={{
                        border: "1px solid #ced4da",
                        backgroundColor: "#ffffff",
                        borderRadius: "6px",
                        display: 'flex',
                        padding: '4px',
                        paddingLeft: "14px",
                        height: "36px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%"
                    }}
                    onFocus={onFocus}
                    value={customValue}
                    onChange={onChange}
                />
                <FaRegCalendar size={14} style={{
                    position: 'relative',
                    left: "-20px",
                }} />

            </div>
        )
    }

    const weekDays = ["S", "M", "T", "W", "T", "F", "S"]
    return (
        <div className="d-flex align-items-center ">
            {/* {(!outline) && <div className="floating-label" style={{ minWidth: "120px" }}>
                {label}
            </div>} */}
            <DatePicker
                style={{
                    position: 'relative',
                    backgroundColor: 'red'
                }}
                numberOfMonths={noOFCalendarModal}
                weekDays={weekDays}
                placeholder={label}
                multiple
                // minDate={minDate}
                format="DD-MM-YYYY"
                render={<CustomInput />}
                value={value?.map(item => {
                    const date = moment(item, 'YYYYMMDD').format('DD');
                    const month = moment(item, 'YYYYMMDD').format('MM');
                    const year = moment(item, 'YYYYMMDD').format('YYYY');
                    return new DateObject().set({ day: date, month: month, year: year });
                })}
                onChange={(v) => {
                    const formattedArray = v?.map(value => {
                        const date = value?.day
                        const month = value?.month?.number
                        const year = value?.year;
                        const momentDate = moment({ year, month: month - 1, date });
                        return parseInt(momentDate.format('YYYYMMDD'));
                    })
                    // for(let i =0;i<formattedArray?.length;i++){
                    //     if(value?.includes(formattedArray[i])){

                    //     }else{

                    //     }
                    // }
                    onChange([...formattedArray])
                }}
            />
        </div>
    )
}

export default MultiDatePickerCalendar;
