export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/route/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`;
    return url;
  },
  get: (id) => `/api/route/${id}/detail`,
  save: `/api/route/create`,
  delete: (id) => `/api/route/${id}/delete`,
  bulkDelete: (id) => `/api/payment-gateway-account/delete`,
  update: `/api/route/update`,
};
export const SELECTSTRUCTURE = {
  routeType: [
    { label: "Recurring", value: 1 },
    { label: "OnDemand", value: 2 },
  ],
  kind: [
    { label: "Regular PickUp", value: 1 },
    { label: "Regular Drop", value: 2 },
    { label: "Adhoc Pickup", value: 3 },
    { label: "Adhoc Drop", value: 4 },
    { label: "Open Shuttle", value: 5 },
    { label: "Reserved Shuttle", value: 6 },
    { label: "Public Shuttle", value: 10 },
    { label: "False", value: "False" },
  ],
};

export const STRUCTURE = [
  {
    label: "Trip_Route",
    filterLabel: "Trip_Route",
    filterName: "code",
    type: "select",
    data: SELECTSTRUCTURE?.trip_Route,
  },
  {
    label: "Currency",
    filterLabel: "Currencies",
    filterName: "currencies",
    type: "currencySelect",
  },
  {
    label: "Active",
    filterLabel: "Active",
    filterName: "active",
    type: "select",
    data: SELECTSTRUCTURE?.active,
  },
];
