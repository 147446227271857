import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../../../pages/VehicleType/VehicleTypeConstant";
import { doGET } from "../../../util/HttpUtil";

class VehicleTypeSelect {
  vehicleTypes = [];
  queue = [];
  isFetching = false;
  page = 1;
  rowsPerPage = 10;
  filterOption = "";
  total = -1;

  constructor() {
    makeAutoObservable(this);
  }

  fetchSingle = async (filterUrl) => {
    return new Promise(async (resolve, reject) => {
      try {
       if(!this.vehicleTypes?.length){
         const response = await doGET(ENDPOINTS.grid(this.page, -1, filterUrl));
         if (response.status === 200) {
           if (this.page === 1) {
             this.vehicleTypes = response?.data?.rows
             this.total = response?.data?.total;
           } else {
             this.vehicleTypes = [...this.vehicleTypes, ...response?.data?.rows]
           }
         } else {
           // Handle error
           this.error = response.data;
         }
       }
       while (this.queue.length > 0) {
        const { resolve } = this.queue.shift();
        resolve();
       }

      } catch (err) {
        this.error = err;
      } finally {
        this.isFetching = false;
      }
    });
  };

  fetch = async function (filterUrl = "") {
    if (this.filterOption !== filterUrl) {
      this.page = 1;
      this.total = -1;
    }
    if (this.isFetching) {
      return new Promise((resolve, reject) => this.queue.push({ resolve, reject }));
  };
    this.isFetching = true;
    this.fetchSingle(filterUrl)
  };

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);
  };

  pushNewData = (data) => {
    this.vehicleTypes.push(data)
  }

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      return response.data
    }
  }
}

const VehicleTypeSelectService = new VehicleTypeSelect();
export default VehicleTypeSelectService;
