export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/client-pool/grid?`;
        if (sizePerPage) {
            url += `&rows=${sizePerPage}`;
        } else {
            url += `&rows=${20}`;
        }
        if (page) {
            url += `&page=${page}`;
        }
        if (filterUrl) url += "&" +filterUrl;
        return url;
    },
    get: (id) => `/api/client-pool/${id}/detail`,
    save: `/api/client-pool/create`,
    delete: (id) => `/api/client-pool/${id}/delete`,
    update: `/api/client-pool/update`,
};

export const STRUCTURE = [
    {
        label: "Name",
        filterLabel: "ClientPool Name",
        filterName: "name",
        type: "text",
    },
    // {
    //     label: "Corp_id",
    //     filterLabel: "Corp_id",
    //     filterName: "corp_id",
    //     type: "text",
    // },
    // {
    //     label: "TargetCurrency",
    //     filterLabel: "TargetCurrency",
    //     filterName: "targetCurrency",
    //     type: "text",
    // },
    {
        label: "Amount",
        filterLabel: "Amount",
        filterName: "amount",
        type: "text",
    },
    // {
    //     label: "Clients",
    //     filterLabel: "Clients",
    //     filterName: "clients",
    //     type: "text",
    // },
];





