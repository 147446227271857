
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import TripInvoiceItem from "./Component/Items/TripInvoiceItem";
import "./style.css";
import InvoiceItemManual from "./Component/InvoiceItemManual";
import _ from "lodash";
import Invoiceservice from "./InvoiceService";
const InvoiceItems = ({
  items,
  onChange,
  tax,
  itemStatuses,
  handleSave,
  invoiceId,
  handleInvoiceBillingCalculateAfterTripUpdate,
  savedStatus
}) => {
  const memoizedTax = useMemo(() => tax, [tax._id, tax.updated]);
  const tripInvoiceItemsRef = useRef(items?.filter(item=>!item.manual)?.map(() => React.createRef()));
  const manualInvoiceItemsRef = useRef(items?.filter(item=>item?.manual)?.map(() => React.createRef()));

  useEffect(()=>{
    if(tripInvoiceItemsRef.current && items?.length){
      tripInvoiceItemsRef.current = items?.filter(item=>!item.manual)?.map(() => React.createRef());
      manualInvoiceItemsRef.current =  items?.filter(item=>item?.manual)?.map(() => React.createRef());
    } 
    if( items?.length){
       manualInvoiceItemsRef.current =  items?.filter(item=>item?.manual)?.map(() => React.createRef());
    } 
  },[items])

  const updateAllBillings=async()=>{
    try {
      // await Promise.allSettled(tripInvoiceItemsRef.current?.map(ref => ref.current?.saveOrUpdateBilling(tax)));
      if(invoiceId){
        // handleSave();
      }else{
        //call this one only when creating invoice
        // await Promise.allSettled(tripInvoiceItemsRef.current?.map(ref => ref.current?.saveOrUpdateBilling(tax)));
        handleInvoiceBillingCalculateAfterTripUpdate()
      }
    } catch (error) {
      console.log(error)
    }
  }

  // useEffect(() => {
  //   if (tax?.updated && tax?._id) {
  //     console.log("callling....",tax?._id,tax?.updated,"====")
  //     updateAllBillings()
  //   }
  // }, [tax?._id]);
  useEffect(() => {
    if (memoizedTax?.updated && memoizedTax?._id ) {
      if(tripInvoiceItemsRef.current?.length){//if trip items then first update all trip billings
        updateAllBillings();
      }else if(manualInvoiceItemsRef.current?.length){
        // if(!invoiceId){
        //   handleInvoiceBillingCalculateAfterTripUpdate()
        // }else{
          onChange(prev=>({
           ...prev,
           taxItem: { tax_id: memoizedTax?._id, taxes: memoizedTax?.items },
           invoiceExtn:{
             ...prev?.invoiceExtn,
             items:prev?.invoiceExtn?.items?.filter(item=>item?.manual)?.map((item,itemIndex)=>{
                 let feeGroups=item?.fee?.feeGroups
                 let updatedFeeGroups=feeGroups?.map(feeGroup=>{
                   return {
                     ...feeGroup,
                     taxItem:{
                       tax_id: memoizedTax?._id,
                       taxes: memoizedTax?.items
                     }
                   }
                 })
                 return {...item,fee:{...item.fee,feeGroups:updatedFeeGroups}}
             })
           }
   
         }));
        // }
      }
    }
  }, [memoizedTax,invoiceId]);
  


  const handleItemChange = (index, item) => { 
       onChange(prev=>({
        ...prev,
        invoiceExtn:{
          ...prev?.invoiceExtn,
          items:prev?.invoiceExtn?.items?.map((org,itemIndex)=>{
            return itemIndex===index?{...org,...item}:org
          })
        }
      }));
  }

  const postSave=async()=>{
    try {
      if(invoiceId){
        const response = await Invoiceservice.get(invoiceId);
        onChange(prev=>({...prev,...response,savedStatus:response?.status}));
      }
    } catch (error) {
      
    }
  }
 
  return (
    <>
      {items?.length && items.map((item, index) => (
        <div key={index}>
          {
            item?.manual ?
              <InvoiceItemManual
                item={item}
                itemIndex={index}
                onChange={(newItem) => handleItemChange(index, newItem)}
                status={itemStatuses?.[index]??"idle"}
              /> :
              <TripInvoiceItem
                tax={tax}
                ref={tripInvoiceItemsRef.current?.[index]??null}
                item={item}
                onChange={(newItem) => handleItemChange(index, newItem)}
                status={itemStatuses?.[index]??"idle"}
                savedStatus={savedStatus}
                postSaveRefreshInvoice={postSave}
              />
          }
        </div>
      ))}

    </>
  );
};

export default InvoiceItems;
