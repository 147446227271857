

import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { CitySelect, CountrySelect, InputField, StateSelect } from "../../../../../components";





export default function BankAddress({ data, setData }) {
    const [hasErr, setHasErr] = useState(false);

    return (

        <>

            <Row className='mt-4 mb-3'>
                <Row className="mt-2 b-3">
                    <Col className="col-lg">
                        <Col className="col-lg">
                            <CountrySelect
                                showErr={hasErr}
                                value={data?.bankAccount?.address?.country}

                                onChange={(v) => setData({
                                    ...data, bankAccount: {
                                        ...data?.bankAccount,
                                        address: {
                                            ...data?.bankAccount?.address,
                                            country: v.value
                                        }

                                    }

                                })}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className='mt-2 mb-3'>


                    <Col className="col-lg">
                        <StateSelect
                            showErr={hasErr}
                            value={data?.bankAccount?.address?.state}

                            onChange={(v) => setData({
                                ...data, bankAccount: {
                                    ...data?.bankAccount,
                                    address: {
                                        ...data?.bankAccount?.address,
                                        state: v.value
                                    }

                                }

                            })}
                            country={data?.bankAccount?.address?.country}
                        />
                    </Col>
                    <Col className="col-lg">
                        <CitySelect
                            showErr={hasErr}
                            value={data?.bankAccount?.address?.city}
                            onChange={(v) => setData({
                                ...data, bankAccount: {
                                    ...data?.bankAccount,
                                    address: {
                                        ...data?.bankAccount?.address,
                                        city: v.value
                                    }

                                }

                            })}
                            country={data?.bankAccount?.address?.country}
                            state={data?.bankAccount?.address?.state}

                        />
                    </Col>




                </Row>
                <Col className="col-lg">
                    <InputField
                        placeholder="Address Line"
                        value={data?.bankAccount?.address?.addressLine}

                        label={"Address Line"}
                        onChange={(v) => setData({
                            ...data, bankAccount: {
                                ...data?.bankAccount,
                                address: {
                                    ...data?.bankAccount?.address,
                                    addressLine: v
                                }

                            }

                        })}

                        showErr={hasErr}

                        type="text"

                    />

                </Col>
            </Row>



        </>

    );
}
