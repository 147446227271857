import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useContext } from "react";
import { Col, Form, Row } from "reactstrap";
import { CardThreeBounce, InputField, StateSelect, CountrySelect, SelectField, Checkbox } from "../../components";
import CityService from "./CityService";
import { I18nContext } from "../../store/context/i18nContext";
import { DialogContext } from "../../store/context/DialogContext";
import { SELECTSTRUCTURE } from "./CityConstant";
import LocationEntry from "../../components/LocationEntry/LocationEntry";


const EditCities = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const { showError } = useContext(DialogContext);
  const [data, setData] = useState(null);
  const [airports, setAirports]=useState([{ country: '', state: '', city: '', addressLine: '', lat: '', lon: '' }])
  const [railwayStations, setrailwayStations]=useState([{ country: '', state: '', city: '', addressLine: '', lat: '', lon: '' }])

  const fetch = async () => {
    try {
      let states = await CityService.get(editId);
      setData(states);

      if (states.airports) setAirports(states.airports);
      if (states.railwayStations) setrailwayStations(states.railwayStations);
    } catch (error) {
      showError(error)
    }
  };


  const handleAirportChange = (index, newData) => {
    const updatedAirports = airports.map((airport, i) =>
      i === index ? newData : airport
    );
    setAirports(updatedAirports);
    onChange({ ...data, airports: updatedAirports }); 
  };


  const handleStationChange = (index, newData) => {
    const updatedStations = railwayStations.map((station, i) =>
      i === index ? newData : station
    );
    setrailwayStations(updatedStations);
    onChange({ ...data, railwayStations: updatedStations }); 
  };
  


  useEffect(() => {
    setData(null)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId]);

  useEffect(() => {
    onChange(data)
  }, [data]);

  return (
    <React.Fragment>
      {loading ?
        <CardThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="mt-3 mb-3">
            <CountrySelect
              value={data?.country}
              className="col-sm-4"
              error={data?.country?.length === 0 ? t("Please enter City country") : ""}
              showErr={hasErr}
              onChange={(v) =>
                setData({
                  ...data,
                  country: v?.value,
                })
              }
              required={true}
            />
            <StateSelect
              placeholder={t("state ")}
              country={data?.country}
              value={data?.state}
              label={t("State")}
              onChange={(v) => {
                setData({ ...data, state: v.value })
              }}
              type="text"
              className="col-sm-4"
            />
            <InputField
              placeholder={t("City Name")}
              value={data?.name}
              required={true}
              label={t("City Name")}
              onChange={(v) => setData({ ...data, name: v })}
              error={data?.name?.length === 0 ? t("Please enter City Name") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-4"
            />


          </Row>
          <Row className="mt-3 mb-3">
            <InputField
              placeholder={t("Latitude")}
              value={data?.lat}
              required={true}
              label={t("lat")}
              onChange={(v) => setData({ ...data, lat: v })}
              error={data?.lat?.length === 0 ? t("Please enter City lat") : ""}
              showErr={hasErr}
              type="number"
              className="col-sm-4"
            />
            <InputField
              placeholder={t("City lon")}
              value={data?.lon}
              required={true}
              label={t("City lon")}
              onChange={(v) => setData({ ...data, lon: v })}
              error={data?.lon?.length === 0 ? t("Please enter City lon") : ""}
              showErr={hasErr}
              type="number"
              className="col-sm-4"
            />
            <InputField
              placeholder={t("City synonyms")}
              value={data?.synonyms}
              required={true}
              label={t("City synonyms")}
              onChange={(v) => setData({ ...data, synonyms: v })}
              error={data?.synonyms?.length === 0 ? t("Please enter City synonyms") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-4"
            />
          </Row>
          <Row>
            <SelectField
              data={SELECTSTRUCTURE?.type}
              required
              className="col-sm-4"
              label={t("Select Type")}
              placeholder={t("Select Type")}
              value={data?.type}
              onChange={(v) => setData({ ...data, type: v?.value })}
            />
          </Row>
          <Row className="mt-3">
            <Col sm={2}>
              <Checkbox
                placeholder={t("Popular")}
                checked={data?.popular}
                required={true}
                label={"Popular"}
                onClick={(v) => {
                  setData({ ...data, popular: !data?.popular })
                }}
                showErr={hasErr}
                type="checkbox"
              />
            </Col>
            <Col sm={2}>
              <Checkbox
                placeholder={t("Approved")}
                checked={data?.approved}
                required={true}
                label={"Approved"}
                onClick={(v) => {
                  setData({ ...data, approved: !data?.approved })
                }}
                showErr={hasErr}
                type="checkbox"
              />
            </Col>
            <Col sm={12} className="mt-3">
              <Checkbox
                placeholder={t("Has Airport")}
                checked={data?.hasAirport}
                required={data?.hasAirport}
                label={"Has Airport"}
                onClick={(v) => {
                  setData({ ...data, hasAirport: !data?.hasAirport });
                  setAirports([{ country: data?.country, state: data?.state, city: '', addressLine: '', lat: '', lng: '' }]);
                }}
                showErr={hasErr}
                type="checkbox"
              />

              {data?.hasAirport && (
                <LocationEntry
                  entries={airports}
                  addEntry={() => setAirports([...airports, { country: data?.country, state: data?.state, city: '', addressLine: '', lat: '', lng: '' }])}
                  removeEntry={(index) => setAirports(airports.filter((_, i) => i !== index))}
                  onChange={handleAirportChange}
                  t={t}
                  hasErr={hasErr}
                />
              )}
            </Col>

            <Col sm={12} className="mt-3">
              <Checkbox
                placeholder={t("Has Train Station")}
                checked={data?.hasTrainStation}
                required={true}
                label={"Has Train Station"}
                onClick={(v) => {
                  setData({ ...data, hasTrainStation: !data?.hasTrainStation });
                  setrailwayStations([{ country: data?.country, state: data?.state, city: '', addressLine: '', lat: '', lng: '' }]);
                }}
                showErr={hasErr}
                type="checkbox"
              />

              {data?.hasTrainStation && (
                <LocationEntry
                  entries={railwayStations}
                  addEntry={() => setrailwayStations([...railwayStations, { country: data?.country, state: data?.state, city: '', addressLine: '', lat: '', lng: '' }])}
                  removeEntry={(index) => setrailwayStations(railwayStations.filter((_, i) => i !== index))}
                  onChange={handleStationChange}
                  t={t}
                  hasErr={hasErr}
                />
              )}
            </Col>
          </Row>
        </Form>
      }

    </React.Fragment>
  );
};

export default observer(EditCities);


