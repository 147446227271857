export const getDevicesInformation = async () => {
  const devices = await navigator?.mediaDevices?.enumerateDevices();
  const data = {
    audioInputDevices: [],
    audioOutputDevices: [],
  };
  devices.forEach((device) => {
    if (device.kind === "audioinput")
      data.audioInputDevices = [...data.audioInputDevices, device];
    else if (device.kind === "audiooutput")
      data.audioOutputDevices = [...data.audioOutputDevices, device];
  });
  return data;
};

export const getUserMedia = async ({ audio, video }) => {
  const black = () => {
    let canvas = Object.assign(document.createElement("canvas"));
    let stream = canvas.captureStream();
    return Object.assign(stream.getVideoTracks()[0]);
  };

  const stream = await navigator?.mediaDevices?.getUserMedia({
    audio: audio ? true : false,
    video: video ? true : false,
  });
  if (video) {
    stream.getAudioTracks()[0].enabled = false;
    stream.getVideoTracks()[0].enabled = false;
    return stream;
  }
  const finalStream = new MediaStream([black(), stream.getAudioTracks()[0]]);

  finalStream.getAudioTracks()[0].enabled = false;
  finalStream.getVideoTracks()[0].enabled = false;

  return finalStream;
};

export const getDisplayMedia = async ({ cursor }) => {
  return await navigator?.mediaDevices?.getDisplayMedia({ cursor });
};

export async function changeAudioInputDevice(
  inputDeviceIndex,
  stream,
  currentAudioSource,
  audioInputDeviceList,
  senders
) {


  console.log(inputDeviceIndex,
    stream,
    currentAudioSource,
    audioInputDeviceList,
    senders);
  if (
    audioInputDeviceList[currentAudioSource]?.deviceId === audioInputDeviceList[inputDeviceIndex]?.deviceId
  )
    return;
  if (stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  }

  const newAudioSource = audioInputDeviceList[inputDeviceIndex].deviceId;

  const constraints = {
    audio: { deviceId: newAudioSource ? { exact: newAudioSource } : undefined },
  };

  const newStream = await navigator?.mediaDevices?.getUserMedia(constraints);
  stream.removeTrack(stream.getAudioTracks()[0]);

  stream.addTrack(newStream.getTracks()[0]);

  senders
    .find((sender) => sender.track.kind === "audio")
    .replaceTrack(newStream.getTracks()[0]);
}

export async function changeAudioOutputDevice(
  outputDeviceIndex,
  element,
  audioOutputDeviceList
) {
  console.log(
    outputDeviceIndex,
    element,
    audioOutputDeviceList);

  if (
    element &&
    typeof element.sinkId !== "undefined"

  ) {
    await element.setSinkId(audioOutputDeviceList[outputDeviceIndex].deviceId);
    console.log(
      `Success, audio output device attached:`, audioOutputDeviceList[outputDeviceIndex]
    );
    // return stream;
  } else {
    console.warn("Browser does not support output device selection.");
  }
}


