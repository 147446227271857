export const ENDPOINTS = {

  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/template/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/template/${id}/detail`,
  saveNew: `/api/template/create`,
  save: `/api/template/create`,
  delete: (id) => `/api/template/${id}/delete`,
  bulkDelete: (id) => `/api/template/delete`,
  update: `/api/template/update`,
  getFields: (kind) => `/api/template/${kind}/fields`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Kind",
    filterLabel: "Kind",
    filterName: "kind",
    type: "text",
  },
  {
    label: "Content Type",
    filterLabel: "Content Type",
    filterName: "forUpload",
    type: "select",
    data: [
      {
        label: "Upload",
        value: true,
      },
      {
        label: "Download",
        value: false,
      },
    ],
  },
];

export const SELECTSTRUCTURE = {
  kindType: [
    { label: "Trip", value: "Trip", fieldType: "trip" },
    { label: "Client", value: "Client", fieldType: "client" },
    { label: "Customer", value: "Customer", fieldType: "customer" },
    { label: "Rate Name", value: "RateName", fieldType: "ratename" },
    { label: "Rate Area", value: "RateArea", fieldType: "ratearea" },
    { label: "Rate", value: "Rate", fieldType: "rate" },
    { label: "Supplier", value: "Supplier", fieldType: "supplier" },
    { label: "Vehicle", value: "Vehicle", fieldType: "vehicle" },
    { label: "Route", value: "Route", fieldType: "route" },
    { label: "Account", value: "Account", fieldType: "account" },
    { label: "Invoice", value: "Invoice", fieldType: "invoice" },
    { label: "Transaction", value: "Transaction", fieldType: "transaction" },
    { label: "Expense", value: "Expense", fieldType: "expense" },
    { label: "Employee", value: "Employee", fieldType: "employee" },
    { label: "Company", value: "Company", fieldType: "company" },
    { label: "Flight", value: "Flight", fieldType: "flight" },
    { label: "Stay", value: "Stay", fieldType: "stay" },
  ]
}

