import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import './style.css'

const AutoSuggestSelect = ({ data = [], value = "", onChange, noLabel, label = "", required = false, className, style }) => {
    const [suggestions, setSuggestions] = useState([]);

    const escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    const getSuggestions = (value) => {
        if (!data || data.length === 0) {
            return [];
        }

        const escapedValue = escapeRegexCharacters(value?.trim());
        const regex = new RegExp('^' + escapedValue, 'i');
        return data?.filter(item => regex?.test(item?.label));
    }

    const getSuggestionValue = (suggestion) => {
        return suggestion?.value;
    }

    const shouldRenderSuggestions = () => {
        return true;
    }

    const renderSuggestion = (suggestion) => {
        return <span key={suggestion?.label}>{suggestion?.label ?? ""}</span>;
    }

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const inputProps = {
        placeholder: "",
        value: data.find(val => val.value === value)?.label ?? value ?? "",
        onChange: onChange
    };

    return (
        <div className={`d-flex align-items-center ${className}`} style={{
            ...style
        }}>
            {!noLabel && <div style={{ minWidth: "120px", fontSize: 12, fontWeight: "500" }}>{label}  {required && <font color="red">*</font>}</div>}
            <div className='flex-1 w-100'>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    shouldRenderSuggestions={shouldRenderSuggestions}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />
            </div>
        </div>
    );
};

export default AutoSuggestSelect;
