import React, { useContext, useEffect, useState } from "react";
import TicketService from "./TicketsService";
import {
    Button,
} from "reactstrap";
import { DialogContext } from "../../store/context/DialogContext";


const Comments = ({ toggleCommentsModal,id,fetchComments }) => {
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState({});
    const { showError } = useContext(DialogContext);

    const sendComments = async () => {
        try {
            setLoading(true);
            await TicketService.sendComments(comment,id);
            fetchComments();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            showError(error)
        }
    };


    return (
        <div>
            <div className="mt-3">
                <div style={{ marginBottom: "0.2rem", }}><b>{"Message"}</b></div>
                <textarea
                    className="inputfield"
                    rows={7} style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", }}
                    onChange={(e) => setComment({
                        ...comment,
                        message:e?.target?.value
                    })}
                >
                </textarea>
                <Button
                    className="col-3  col-md-1  waves-light mb-1 mt-2"
                    style={{ fontSize: 14, height: "36px", float: "right", backgroundColor: "#43887F", border: "none",width:"90px" }}
                    onClick={() => {
                        sendComments();
                        toggleCommentsModal();
                    }}
                >
                    Send
                </Button>
            </div>
        </div>
    )
}

export default Comments