/* FOR Lead ... CURRENTLY INCOMPLETED */

import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../../../pages/Users/UserConstant";

class CustomerSelect {
    customers = [];
    queue = [];
    isFetching = false;
    page = 1;
    rowsPerPage = 10;
    filterOption = "";
    total = -1;

    constructor() {
        makeAutoObservable(this);
    };

    fetchSingle = async (filterUrl) => {
        return new Promise(async (resolve, reject) => {
            try {

                const response = await doGET(ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl));
                if (response.status === 200) {
                    if (this.page === 1) {
                        this.customers = response?.data?.rows
                        this.total = response?.data?.total;
                    } else {
                        this.customers = [...this.customers, ...response?.data?.rows]
                    }
                } else {
                    // Handle error
                    this.error = response.data;
                }

                // while (this.queue.length > 0) {
                //     const { resolve } = this.queue.shift();
                //     resolve();
                // }

            } catch (err) {
                this.error = err;
                // while (this.queue.length > 0) {
                //     const { reject } = this.queue.shift();
                //     reject(err);
                // }
            } finally {
                this.isFetching = false;
            }
        });
    };

    fetch = async function ({filterUrl = ""}) {
        // if (this.isFetching) {
        //     return new Promise((resolve, reject) => this.queue.push({ resolve, reject }));
        // };
        // filterUrl='a=b'
        if (this.filterOption !== filterUrl) {
            this.page = 1;
            this.total = -1;
        }
        this.filterOption = filterUrl;
        this.isFetching = true;
        this.fetchSingle(filterUrl)
    };

    onPaginationChange = async (page, rowsPerPage, space_id) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        // const filterUrl = `${this.filterOption ? `${this.filterOption}&` : ""}${space_id ? `space_id=${space_id}` : ""}`;

        await this.fetch({ filterUrl: this.filterOption });
    };

    pushNewData = (data) => {
        this.customers.push(data)
    }

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));
        if (response.status === 200) {
            return response.data
        }
    }

}

const CustomerSelectService = new CustomerSelect();
export default CustomerSelectService;
