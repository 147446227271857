import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "toastr/build/toastr.min.css";
import {
    CardThreeBounce,
    CheckButton,
    DeleteLink,
    EditLink,
    Layout,
} from "../../components";
import { Row, Col } from "reactstrap";
import "../../components/SuperResponsiveTableStyle.css";
import { I18nContext } from "../../store/context/i18nContext";
import HolidayConfService from "./HolidayConfService";
import { DialogContext } from "../../store/context/DialogContext";
import { STRUCTURE } from "./HolidayConfConstant";
import EditHolidayConf from "./EditHolidayConf";

const CityGroups = (props) => {
    const { t } = useContext(I18nContext);
    let { id } = useParams();
    if (props?.corpId) id = props?.corpId;
    const { showConfirm, showError } = useContext(DialogContext);
    const [selected_id, setSelected_id] = useState(id);
    const [showDetailPage, setShowDetailPage] = useState(
        props?.corpId ? false : id
    );

    const [loading, setLoading] = useState(false);
    const [isEditDataChanged, setIsEditDataChanged] = useState(false);

    const fetchData = async (values) => {
        if (loading) return;
        setLoading(true);
        try {
            await HolidayConfService.fetch(values, props?.corpId);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    let navigate = useNavigate();

    useEffect(() => {
        if (id !== selected_id) setSelected_id(id);
        setShowDetailPage(
            window.location.pathname === "/holidayconf/edit" || (id && !props?.corpId)
        );
    }, [id]);

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: "Delete Holiday Configuration?",
                description: "This is an unrecoverable operation.",
            })
        ) {
            try {
                await HolidayConfService.delete(id);
            } catch (e) {
                showError(e);
            }
        }
    };

    const onRowClick = (id) => {
        if (!props?.insidePane) navigate(`/holidayconf/edit/${id}`);
        setSelected_id(id);
        setShowDetailPage(true);
        setIsEditDataChanged(false);
    };
    const handleRowClick = async (holidayId) => {

        if (showDetailPage && holidayId !== selected_id && isEditDataChanged) {
            let isProceed = await showConfirm({
                isSaveWarn: true,
                title: t(`Do you want to save the changes ?`),
                description: t("Your changes will be lost if you don't save them"),
            });
            if (isProceed) onRowClick(holidayId);
        } else onRowClick(holidayId);
    };

    if (loading) return <CardThreeBounce />;
    return (
        <Layout
            showDetailPage={showDetailPage}
             
            title={t("Holiday Configuration")}
            filterStructure={STRUCTURE}
            onApplyFilter={fetchData}
            onAddClick={() => {
                if (!props?.insidePane) navigate(`/holidayconf/edit`);
                setShowDetailPage(true);
                setSelected_id(null);
            }}
            page={HolidayConfService.page}
            rows={HolidayConfService.rows}
            total={HolidayConfService.totalRecords}
            onPageChange={async (page, rows) => {
                await HolidayConfService.onPaginationChange(page, rows);
            }}
        >
            <Layout.TheadFull>
                <Thead className="thead-dark">
                    <Tr>
                        <Th>{t("Holiday Group")}</Th>
                        <Th>{t("Description")}</Th>

                        <Th>{t("Year")}</Th>
                        <Th>{t("Action")}</Th>
                    </Tr>
                </Thead>
            </Layout.TheadFull>

            <Layout.TheadCompact>
                <Thead className="thead-dark">
                    <Tr>
                        <Th>{t("Holiday Group")}</Th>
                        <Th>{t("Description")}</Th>
                        <Th>{t("Year")}</Th>
                    </Tr>
                </Thead>
            </Layout.TheadCompact>

            <Layout.TbodyFull>
                <Tbody>
                    {HolidayConfService.records?.map((holiday) => (
                        <Tr
                            key={holiday?._id}
                            onClick={showDetailPage && (() => handleRowClick(holiday?._id))}
                        >
                            <Td >{holiday?.name}</Td>
                            <Td>{holiday?.description}</Td>
                            <Td>{holiday?.year}</Td>
                            <Td>
                                <Row>
                                    <Col className="col-6">
                                        <EditLink
                                            onClick={() => {
                                                if (!props?.insidePane)
                                                    navigate(`/holidayconf/edit/${holiday?._id}`);
                                                setSelected_id(holiday?._id);
                                                setShowDetailPage(true);
                                            }}
                                        />
                                    </Col >
                                    <Col className="col-6">
                                        <DeleteLink
                                            onClick={(event) => {
                                                onDelete(event, holiday?._id);
                                            }}
                                        />
                                    </Col>
                                </Row>


                                {props?.selectMode ? (
                                    <CheckButton
                                        onClick={() => {
                                            props?.onSelect([{ ...holiday }]);
                                        }}
                                    />
                                ) : null}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Layout.TbodyFull>

            <Layout.TbodyCompact>
                <Tbody>
                    {HolidayConfService.records?.map((holiday) => (
                        <Tr
                            className={selected_id === holiday?._id && "table-row-selected"}
                            style={{ cursor: "pointer" }}
                            key={holiday?._id}
                            onClick={showDetailPage && (() => handleRowClick(holiday?._id))}
                        >
                            <Td >{holiday?.name}</Td>
                            <Td>{holiday?.description}</Td>
                            <Td>{holiday?.year}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Layout.TbodyCompact>

            <Layout.DetailPage>
                <EditHolidayConf
                    insidePane={props?.insidePane}
                    onChange={(v) => setIsEditDataChanged(v)}
                    id={selected_id}
                    back={() => {
                        setShowDetailPage(false);
                        if (!props?.insidePane) navigate("/holidayconf");
                        setSelected_id(false);
                    }}
                    fetchData={fetchData}
                />
            </Layout.DetailPage>
        </Layout>
    );
};

export default observer(CityGroups);
