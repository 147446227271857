import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { InputField, SelectField, SaveButton,Checkbox } from "../../../components";

export default function DisabledFields({ data, setData }) {
  const [showErr, setshowErr] = useState(false);

  return (
    <div className="active ">
 
          <Row>
            <h5>
              Disabled Fields
            </h5>
            <Checkbox className="col-lg" 
            checked={data?.disabledFields?.includes("Tariff.sourceCities")}
            onClick={(v) => {
              setData({ ...data,disabledFields:[...data?.disabledFields, "Tariff.sourceCities" ]});
            }}
             label={"Tariff.sourceCities"} />
             <Checkbox className="col-lg" 
           checked={data?.disabledFields?.includes("Tariff.cityGroups")}
           onClick={(v) => {
            setData({ ...data,disabledFields:[...data?.disabledFields, "Tariff.cityGroups" ]});
          }}
             label={"Tariff.cityGroups"} />
             <Checkbox className="col-lg" 
          checked={data?.disabledFields?.includes("Tariff.security")}
          onClick={(v) => {
            setData({ ...data,disabledFields:[...data?.disabledFields, "Tariff.security" ]});
          }}
             label={"Tariff.security"} />
          </Row>
    </div>
  );
}
