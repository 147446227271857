import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET } from "../../../util/HttpUtil";
class RankSelect {
    rank = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filterData) {
        if (this.rank.length > 0)
            return;
        try {
            const response = await doGET(ENDPOINTS.getRank);
            if (response.status === 200) {
                this.rank = response.data.map((v) => { return { label: v?.name, value: v?._id } });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}
const RankSelectService = new RankSelect();
export default RankSelectService;