import React, { useContext, useState } from 'react'
import { FaPhoneAlt } from 'react-icons/fa'
import { I18nContext } from '../../store/context/i18nContext'
import { UserContext } from '../../store/context/UserContext';
import { doGET, doPOST } from '../../util/HttpUtil';

const CallAdmin = ({ driverId ,className="",textColor="black"}) => {
    const { t } = useContext(I18nContext);
    const { showError } = useContext(UserContext);
    const [loading, setLoading] = useState(false);


    const notifyAdmin = async () => {
        try {
            if (loading) return;

            setLoading(true);
            const response = await doGET(`/connect/voip/notify-admin?receiver_id=${driverId}`);
            console.log(response);
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false)
        }
    }

    return (
        <div onClick={notifyAdmin} className={`d-flex align-items-center gap-2 ${className}`}>
            {!loading ? <>
                <div><FaPhoneAlt color={textColor} size={20} /></div>
                <b className={`align-self-center text-${textColor}`}>{t(`Call Driver`)}</b>
            </> :
                <div className="spinner-border spinner-border-sm mx-auto" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </div>
    )
}

export default CallAdmin