import { observer } from "mobx-react-lite";
import React, { useContext, useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Breadcrumbs, CardThreeBounce } from "../../components";
import TabV from "../../components/Common/Tab/Tab";
import { I18nContext } from "../../store/context/i18nContext";
import ChangeEmail from "./Change/ChangeEmail";
import ChangePass from "./Change/ChangePass";
import ChangePhone from "./Change/ChangePhone";
import ProfileService from "./ProfileService";
import UserProfile from "./UserProfile/UserProfile";
import { DialogContext } from "../../store/context/DialogContext";
import OrganizationProfile from "./OrganizationProfile/OrganizationProfile";
import Contact from "./Contact/Contact";
import { UserContext } from "../../store/context/UserContext";
import ContactPerson from "../ContactPerson/ContactPerson";

const Settings = (props) => {

    const { user, userContent } = useContext(UserContext);
    const [stepV, setStepV] = useState(1);
    const [data, setData] = useState({});
    const { t } = useContext(I18nContext);
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState();
    const { showConfirm, showError } = useContext(DialogContext);
    const [hasErr, setHasErr] = useState(false);
    const [configData, setConfigData] = useState({})

    const [corpId, setCorpId] = useState("")
    const [supervisorId, setSupervisorId] = useState("")


    useEffect(() => {
        setCorpId(userContent?.corp_id)
        setSupervisorId(userContent?.supervisor_id)
    }, [userContent])


    // console.log(userContent);


    // const fetchData = async () => {
    //     if (loading) return;
    //     setLoading(true);
    //     try {
    //         const res = await ProfileService.getProfile();
    //         setProfile(res);
    //         setData(res);
    //         setHasErr(false);
    //     } catch (e) {
    //         showError(e);
    //     }
    //     setLoading(false);
    // };

    // const fetchFunc = async (id) => {
    //     try {
    //         // await populateUserContent();
    //         const res = await ProfileService.getConfig(id);
    //         // console.log(res);
    //         setConfigData(res);
    //         // setCorpId(user?.businessUnit?.corp_id)
    //     } catch (error) {
    //         showError(error);
    //     }
    // }

    // useEffect(() => {
    //     // fetchData();
    //     fetchFunc(corpId);
    // }, []);

    if (loading) return <CardThreeBounce />;

    console.log(corpId, "75")

    return (

        <React.Fragment>
            <div className="page-content">
                <Row>
                    <Col className={`left-scroll-independent col-12`} >
                        <Card className="mx-0 pt-4">

                            <CardBody>
                                <TabV vertical left activeTab={stepV} onChange={(data) => { setStepV(data) }} >
                                    <div num={1} label={t("Organization Profile")}>
                                        <OrganizationProfile id={corpId} />
                                    </div>
                                    {/* <div num={2} label={t("Contact Person")}>
                                        <ContactPerson
                                            insidePane={true}
                                            multiMode={false}
                                            hideFilterBar={true}
                                            dontShowCheck={true} />
                                        {/* <Contact
                                        // configData={configData}
                                        // setConfigData={setConfigData}
                                        // fetchFunc={fetchFunc}
                                        // data={data}
                                        // setData={setData}
                                        // id={corpId}
                                        /> */}
                                    {/* </div> */}
                                    <div num={3} label={t("User Profile")}>
                                        <UserProfile
                                            data={data}
                                            setData={setData}
                                            profile={profile}
                                            setProfile={setProfile}
                                            hasErr={hasErr}
                                            setHasErr={setHasErr}
                                            //  fetchData={fetchData} 
                                            supervisorId={supervisorId} />
                                    </div>
                                    {/* <div num={4} label={t("Home Location")}>
                                        Home Location
                                    </div> */}
                                    {/* <div num={4} label={t("Change Password")}>
                                        <ChangePass />
                                    </div> */}
                                    <div num={5} label={t("Change Phone")}>
                                        <ChangePhone data={data}
                                            setData={setData}
                                            supervisorId={supervisorId}
                                            hasErr={hasErr}
                                            setHasErr={setHasErr}
                                        //  fetchData={fetchData} 
                                        />
                                    </div>
                                    <div num={6} label={t("Change Email")}>
                                        <ChangeEmail
                                            data={data}
                                            setData={setData}
                                            supervisorId={supervisorId}
                                            hasErr={hasErr}
                                            setHasErr={setHasErr}
                                        //  fetchData={fetchData}
                                        />
                                    </div>
                                </TabV>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>


    );
};

export default observer(Settings);
