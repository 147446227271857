export const ENDPOINTS = {
  grid: (page, sizePerPage) => {

    let s = `/api/corp/subscription/grid?rows=${sizePerPage}`;
    return s + "&page=" + page;
  },
  get: (id) => `/api/corp/subscription/${id}/detail`,
  save: `/api/corp/subscription/save`,
  updateInvoice: (id, st) => `/api/invoice/${id}/status/${st}/update`
};

