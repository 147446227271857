import { CompanySelect, OfficeSelect } from "../../components";

export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Name",
      accessor: "name",
      show: true,
    },

    // {
    //   title: "Office",
    //   accessor: "",
    //   renderer: (dataObj) => {
    //     return <div>
    //       <OfficeSelect
    //         asLabel
    //         corp_id={dataObj?._id}
    //         value={dataObj?.office_id}
    //         onChange={(v) => { }}
    //         className=""
    //       />
    //     </div>;
    //   },
    //   show: true,
    // },
    // {
    //   title: "Company",
    //   accessor: "",
    //   renderer: (dataObj) => {
    //     console.log(dataObj);
    //     return <div>
    //       <CompanySelect
    //         corp_id={dataObj?._id}
    //         asLabel
    //         value={dataObj?.company_id}
    //         onChange={(v) => { }}
    //         className=""
    //       />
    //     </div>;
    //   },
    //   show: true,
    // },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
