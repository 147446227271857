import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CRMService from './CRMService';
import Email from './Email/Email';
import WhatsApp from './WhatsApp/WhatsApp';
import message from '../../assets/images/icons/message.png'
import SMS from './SMS/SMS';
import './styles.scss';
import gmail from '../../assets/images/icons/gmail.png';
import smsIcon from '../../assets/images/icons/sms.png';
import whatsappIcon from '../../assets/images/icons/whatsapp.png';
import Inbox from './Inbox/Inbox';

const CRMLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    CRMService.setTabFromLocation(location.pathname);
  }, [location]);

  const renderContent = () => {
    switch (CRMService.activeTab) {
      case 'email':
        return <Email />;
      case 'whatsapp':
        return <WhatsApp />;
      case 'sms':
        return <SMS />;
      case 'inbox':
        return <Inbox />;
      default:
        return <Email />;
    }
  };

  return (
    <div className='page-content px-0'>
      <div className='zr_crm_layout'>
        <nav className="zr_crm_sidebar">
          <ul>
            <li className={CRMService.activeTab === 'inbox' ? 'active' : ''} onClick={() => CRMService.setTab('inbox', navigate)}>
              <img src={message} alt="message" />
            </li>
            <li className={CRMService.activeTab === 'email' ? 'active' : ''} onClick={() => CRMService.setTab('email', navigate)}>
              <img src={gmail} alt="Email" />
            </li>
            <li className={CRMService.activeTab === 'whatsapp' ? 'active' : ''} onClick={() => CRMService.setTab('whatsapp', navigate)}>
              <img src={whatsappIcon} alt="WhatsApp" />
            </li>
            <li className={CRMService.activeTab === 'sms' ? 'active' : ''} onClick={() => CRMService.setTab('sms', navigate)}>
              <img src={smsIcon} alt="SMS" />
            </li>
          </ul>
        </nav>
        <div className="zr_crm_content">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default observer(CRMLayout);
