import { toJS } from 'mobx'; // Import your Tooltip component
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsPersonFill, BsPersonFillAdd } from 'react-icons/bs';
import { Tooltip } from 'react-tooltip';
import { DialogContext } from '../../../store/context/DialogContext';
import SideModal from '../../Layout/SideModal/SideModal';
import { objectIdToColor, useOutsideAlerter } from '../../dnd/board/helper';
import EmployeeInfo from './EmployeeInfo';
import EmployeeSelectService from './EmployeeSelectService';
import './styles.css'; // Ensure your CSS file path is correct


const DropdownItem = ({ tooltipId, value, employee, onAdd, onRemove, onViewProfile }) => {
    return (
        <div className="employee-dropdown-item d-flex align-items-center justify-content-between" onClick={(e) => { e.stopPropagation(); onAdd(employee) }}>

            <div className=' d-flex align-items-center'>
                <EmployeeChip
                    tooltipId={tooltipId}
                    selectedIds={value}
                    isSelectable={false}
                    employee={employee}
                    onRemove={onRemove}
                />
                <span className='employee-dropdown-name'>  {employee.name}</span>
            </div>

            <div onClick={(e) => { e.stopPropagation(); onViewProfile(employee._id) }} className='employee-profile-btn'>
                Profile
            </div>

        </div>
    );
};

const EmployeeChip = ({ tooltipId, isSelectable, selectedIds = [], employee, onRemove }) => {
    const { name, _id } = employee;

    return (
        <div>
            <Tooltip style={{ zIndex: '200000' }} anchorSelect={`#not-clickable-${_id}-${isSelectable}-${tooltipId}`}>
                <div>{name}</div>
            </Tooltip>

            <div id={`not-clickable-${_id}-${isSelectable}-${tooltipId}`} className={`employee-chip ${selectedIds?.includes(_id) ? "employee-chip-selected" : ""}`}>
                <span style={{ backgroundColor: objectIdToColor(_id) }} className="employee-initials">{name.split(' ').map(n => n[0]).join('')}</span>
                {(!selectedIds?.length || selectedIds?.includes(_id)) ? <button className="remove-employee-button p-0 " onClick={(e) => { e.stopPropagation(); onRemove(_id) }}>    <i style={{ fontSize: "10px" }} className="dripicons-cross" /></button> : null}
            </div>
        </div >
    );
};

const EmployeeAssigner = ({ tooltipId = "", value = [], onChange = () => { }, asLabel, className }) => {
    const [employees, setEmployees] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showEmployeeProfile, setShowEmployeeProfile] = useState(false);
    const { showError } = useContext(DialogContext); // Ensure you have DialogContext
    const wrapperRef = useRef(null); // Create a ref for the wrapper div
    useOutsideAlerter(wrapperRef, () => setShowDropdown(false));

    const fetchEmployees = async () => {
        try {
            await EmployeeSelectService.fetch();
            updateEmployees()
        } catch (error) {
            showError("Error", error)
        }
    };



    const updateEmployees = () => {
        setEmployees(
            toJS(EmployeeSelectService.employees)?.map((employee) => {
                if (
                    employee?.isSales
                ) {
                    return {
                        ...employee,
                        value: employee._id,
                        label: employee.name
                    };
                }
                return null;
            }).filter(Boolean)
        );
    };

    useEffect(() => {
        if (EmployeeSelectService.employees.length === 0) fetchEmployees();
    }, []);

    useEffect(() => {
        updateEmployees()
    }, [EmployeeSelectService.employees]);


    const handleRemove = (id) => {
        onChange(value.filter(v => v !== id));
    };

    const handleAdd = (employee) => {
        if (!value.includes(employee._id)) {
            onChange([...value, employee._id]);
        }
    };

    const renderEmployeeSelectModal = () => {
        return (<div className="employee-select-dropdown">
            {employees.map((employee) => (
                <DropdownItem
                    tooltipId={tooltipId}
                    value={value}
                    key={employee._id}
                    onViewProfile={(v) => { setShowEmployeeProfile(v) }}
                    onRemove={handleRemove}
                    employee={employee}
                    onAdd={handleAdd} />
            ))}
        </div>
        )

        // return (
        //     <EmployeeSelectModal
        //         employees={employees}
        //         selectedEmployees={value}
        //         onAdd={handleAdd}
        //         onClose={() => setShowEmployeeSelect(false)}
        //     />
        // );
    };


    const isEmployeeExistinValue = (value ?? []).some(employeeId =>
        (employees ?? []).some(employee => employee._id === employeeId)
    );

    return (
        <div className={className}>
            <div className='position-relative ' ref={wrapperRef} >
                <div className="employee-assigner" onClick={(e) => { e.stopPropagation(); setShowDropdown(true) }}>
                    {(value && value.length > 0 && isEmployeeExistinValue) ? (
                        value.map(employeeId => {
                            const employee = employees.find(e => e._id === employeeId);
                            return employee ? (
                                <EmployeeChip tooltipId={tooltipId} isSelectable={true} key={employee._id} employee={employee} onRemove={handleRemove} />
                            ) : null;
                        })
                    ) : (
                        <button className="add-employee-button m-0 p-0" onClick={(e) => { e.stopPropagation(); setShowDropdown(true) }}>
                            {asLabel ?
                                <BsPersonFill className='m-0' size={32} style={{ border: "1px dotted #aaa", borderRadius: "50%", padding: "3px" }} color="#aaa" /> :
                                <BsPersonFillAdd className='m-0' size={32} style={{ border: "1px dotted #aaa", borderRadius: "50%", padding: "3px" }} color="#aaa" />}
                        </button>
                    )}
                </div>

                {(showDropdown && !asLabel) && renderEmployeeSelectModal()}

            </div>

            <SideModal
                visible={showEmployeeProfile}
                toggleVisible={() => { setShowEmployeeProfile(false) }}
                small
            >
                <EmployeeInfo
                    editId={showEmployeeProfile}
                />

            </SideModal>

        </div>

    );
};

export default observer(EmployeeAssigner);
