import { t } from 'i18next';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import DateSelect from '../DateSelects/DateSelect';
import MonthPicker from '../Dropdowns/MonthSelect/MonthPicker';
import CountCard from './CountCard';
import StatsChart from './StatsChart';

export const StatsCard = ({
    data,
    getData,
    getTotalValue,
    showChart,
    showDateSelect,
    showMonthSelect,
    title = "",
    placeholder = "",
    chartType = 'doughnut',
    selectedDate,
    selectedMonth,
    setSelectedMonth,
    setSelectedDate,
    redirect=()=>{},
    md = 6,
    borderedCards
}) => {

  return (
          <Col md={md} sm={12} className="d-flex flex-column">
            <Card className="py-2 px-4 col-sm-12 flex-1" style={{ height: "fit-content" }}>
                <Row className='mt-2'>
                    <Col className={`${(showChart && getTotalValue()>0 ) ? "col-sm-9" : "col-sm-12"}`}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="font-size-14 my-2 d-flex align-items-center">
                                <h2><b>{title}</b></h2>
                            </div>
                            {showDateSelect && (
                                <div className='d-flex justify-content-end pe-2 mb-2'>
                                    <DateSelect
                                        placeholder={t(placeholder)}
                                        value={selectedDate}
                                        onChange={(v) => setSelectedDate(v)}
                                        type="text"
                                    />
                                </div>
                            )}
                            {showMonthSelect ? <div className='d-flex me-0 pe-0  mb-2 justify-content-end'>
                                <MonthPicker
                                    placeholder={t(placeholder)}
                                    value={selectedMonth}
                                    onChange={(v) => {
                                        setSelectedMonth(v)
                                    }}
                                    type="text"
                                />
                            </div> : null}
                        </div>
                        <Col className="d-flex flex-wrap gap-1 align-items-start pb-2">
                          <CountCard
                              style={borderedCards ? { borderRight: "1px solid #ebebeb" } : {}}
                              title={title}
                              data={getData()}
                              className={borderedCards ? "" : "shadow"}
                              onClick={(v) => { redirect(v) }}
                          />
                      </Col>
                  </Col>
                  {showChart && getTotalValue() > 0 && (
                      <div className='d-flex flex-column col-3'>
                          <div className="col-6 flex-1 mx-auto col-sm-4 d-flex justify-content-center align-items-center w-100">
                              <StatsChart chartType={chartType} data={data} />
                          </div>
                          <div className='d-flex fw-bold justify-content-center '>
                              <div className='d-flex mt-2 ms-3' style={{ color: "grey", fontSize: "18px" }}>
                                  Total <span className='mx-2' style={{ color: "black" }}>{getTotalValue()}</span>
                              </div>
                          </div>
                      </div>
                  )}
                </Row>
            </Card>
        </Col>
  )
}