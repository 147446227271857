import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";

class AccountSelect {
    accounts = [];

    categoryCache = {};
    fetchQueue = {};
    loading = {};

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async function (corp_id) {
        try {
            const response = await doGET(`/api/account/grid${corp_id ? `?company_ids=in[${corp_id}]` : ''}`);
            if (response.status === 200) {
                this.accounts = response?.data?.rows;
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };

    fetchAccountNameFromNumber = async(accountId)=>
    {
        if(this.categoryCache[accountId]){
            return this.categoryCache[accountId];
        }

        if (this.loading[accountId]) {
            return new Promise((resolve, reject) => {
              if (!this.fetchQueue[accountId]) this.fetchQueue[accountId] = [];
              this.fetchQueue[accountId].push({ resolve, reject });
            });
          }
          this.loading[accountId] = true;

          try {
            const response = await doGET(`/api/account/grid?rows=-1`);
            if (response.status === 200) {
              const categories = response.data.rows.reduce((acc, item) => {
                acc[item._id] = { ...item, label: item?.acDetail?.name+" ("+item?.acDetail?.accountNo+")" };
                return acc;
              }, {});
      
              this.categoryCache = { ...this.categoryCache, ...categories };
      
              while (this.fetchQueue[accountId]?.length > 0) {
                const { resolve } = this.fetchQueue[accountId].shift();
                resolve(this.categoryCache[accountId]);
              }
      
              return this.categoryCache[accountId] || null;
            } else {
              throw new Error("Failed to fetch Account");
            }
          } catch (error) {
            throw new Error(error.message);
          } finally {
            delete this.loading[accountId];
          }

    }
}

const AccountSelectService = new AccountSelect();
export default AccountSelectService;
