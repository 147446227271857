
// import { toJS } from "mobx";
// import { observer } from "mobx-react-lite";
// import React, { useContext, useEffect, useState } from "react";
// import RateArea from "../../../pages/RateArea/RateArea";
// import { DialogContext } from "../../../store/context/DialogContext";
// import { I18nContext } from "../../../store/context/i18nContext";
// import { SelectField } from "../../SelectField/SelectField";
// import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
// import { BottomBarButton, ModalComponent, SearchButton } from "../../index";
// import RateAreaSelectService from "./RateAreaSelectService";
// import { ThreeBounce } from "better-react-spinkit";

// const RateAreaSelect = ({
//     style,
//     isDisabled,
//     className,
//     onChange,
//     required,
//     value,
//     label,
//     showErr,
//     inline,
//     borderNone,
//     plain,
//     asLabel,
//     multi,
// }) => {
//     const [rateAreas, setRateAreas] = useState([]);
//     const [gridLoading, setGridLoading] = useState(false);
//     const [modal, setModal] = useState(false);
//     const { showError } = useContext(DialogContext)
//     const { t } = useContext(I18nContext)

//     const fetchRateAreas = async () => {
//         setGridLoading(true)
//         try {
//             await RateAreaSelectService.fetch();
//             setRateAreas(toJS(RateAreaSelectService.rateAreas));
//             setGridLoading(false)
//         } catch (error) {
//             showError("Error", error)
//         }
//         setGridLoading(false)
//     };

//     useEffect(() => {
//         if (RateAreaSelectService.rateAreas.length === 0) fetchRateAreas();
//     }, []);

//     useEffect(() => {
//         setRateAreas(toJS(RateAreaSelectService.rateAreas));
//     }, [RateAreaSelectService.rateAreas]);

//     const openModal = () => {
//         setModal(true);
//     };

//     const closeModal = () => {
//         setModal(false);
//     };

//     const selectRateArea = (selectedValue) => {
//         onChange({ value: multi ? selectedValue[0] : selectedValue });
//         closeModal();
//     };

//     const selectRateAreas = (selectedValues) => {
//         onChange(selectedValues.map((c) => c?.value ?? c));
//     };

//     const renderSelectField = ({ disabled }) => (
//         <SelectField
//             style={style}
//             isDisabled={isDisabled || disabled}
//             changeOptionsData={openModal}
//             bottomBarChildren={() => (
//                 <BottomBarButton onClick={openModal} title={t("Manage RateArea")} />
//             )}
//             showBottomBar={true}
//             className={className}
//             onChange={onChange}
//             data={rateAreas}
//             required={required}
//             value={value}
//             plain={plain}
//             label={label || (!isDisabled && t("RateArea"))}
//             error={!value ? t(`Please Select ${label || "RateArea"}`) : ""}
//             showErr={showErr}
//             inline={inline}
//             borderNone={borderNone}
//             RightComponent={() => (
//                 <SearchButton onClick={openModal} />
//             )}
//         />
//     );

//     const renderSelectFieldMultiple = () => (
//         <SelectFieldMultiple
//             isDisabled={isDisabled}
//             style={style}
//             bottomBarChildren={() => (
//                 <BottomBarButton onClick={openModal} title={t("Manage Rate Area")} />
//             )}
//             showBottomBar={true}
//             className={className}
//             onChange={selectRateAreas}
//             data={rateAreas}
//             value={value ? rateAreas.filter((c) => value?.includes(c?.value)) : ""}
//             error={!value ? t(`Please Select ${label || "Rate Area"}`) : ""}
//             required={required}
//             label={t("Rate Area")}
//             showErr={showErr}
//             RightComponent={() => (
//                 <SearchButton onClick={openModal} />
//             )}
//         />
//     );

//     return (
//         <>
//             {gridLoading ? (
//                 <div className="d-flex align-items-center justify-content-center">
//                     <ThreeBounce size={20} color="pink" />
//                 </div>
//             ) : (
//                 <>
//                     {
//                         asLabel ? multi ?
//                             value?.length > 0 ?
//                                 (<div> {value?.map((selectedValue, index) => (

//                                     <span key={selectedValue}>
//                                         {rateAreas.find((rateArea) => rateArea.value === selectedValue)?.label}
//                                         {index < value.length - 1 && ", "}
//                                     </span>
//                                 ))}</div>) : <div style={{ color: "grey" }}>--</div>

//                             : (<div>{rateAreas?.find((rateArea) => rateArea?.value == value)?.label ?? <div style={{ color: "grey" }}>--</div>}</div>) :
//                             <>
//                                 {modal && (
//                                     <ModalComponent
//                                         position={"top"}
//                                         size={"large"}
//                                         onToggle={closeModal}
//                                         isOpen={modal}
//                                     >
//                                         <ModalComponent.Title>
//                                             {t("Rate Area")}
//                                         </ModalComponent.Title>

//                                         <ModalComponent.Body>
//                                             <RateArea
//                                                 value={value}
//                                                 insidePane={true}
//                                                 multiMode={multi}
//                                                 onSelect={multi ? selectRateAreas : selectRateArea}
//                                             />
//                                         </ModalComponent.Body>

//                                     </ModalComponent>
//                                     // <Modal onChange={closeModal}>
//                                     //     <RateArea
//                                     //         value={value}
//                                     //         insidePane={true}
//                                     //         multiMode={multi}
//                                     //         onSelect={multi ? selectRateAreas : selectRateArea}
//                                     //     />
//                                     // </Modal>
//                                 )}
//                                 {rateAreas.length > 0
//                                     ? multi
//                                         ? renderSelectFieldMultiple()
//                                         : renderSelectField({ disabled: false })
//                                     : renderSelectField({ disabled: true })}
//                             </>
//                     }
//                 </>
//             )}
//         </>
//     );
// };

// export default observer(RateAreaSelect);


import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import RateArea from "../../../pages/RateArea/RateArea";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, ModalComponent, Modal, SearchButton } from "../../index";
import RateAreaService from "./../../../pages/RateArea/RateAreaService";
import { ThreeBounce } from "better-react-spinkit";

const RateAreaSelect = ({
    style,
    isDisabled,
    className,
    onChange,
    required,
    value,
    label,
    showErr,
    inline,
    borderNone,
    plain,
    asLabel,
    multi,
}) => {
    const [rateAreas, setRateAreas] = useState([]);
    const [gridLoading, setGridLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const { showError } = useContext(DialogContext)
    const { t } = useContext(I18nContext)

    const fetchRateAreas = async () => {
        setGridLoading(true)
        try {
            const response = await RateAreaService.fetchAll();
            setRateAreas(RateAreaService.rateAreaCache);

            // setRateAreas(toJS(response));
            setGridLoading(false)
        } catch (error) {
            showError("Error", error)
        }
        setGridLoading(false)
    };

    useEffect(() => {
        if (RateAreaService.rateAreaCache.length === 0) fetchRateAreas();
    }, []);

    useEffect(() => {
        setRateAreas(RateAreaService.rateAreaCache);
      }, [RateAreaService.rateAreaCache]);

    const openModal = () => {
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    const selectRateArea = (selectedValue) => {
        fetchRateAreas()
        onChange({ value: multi ? selectedValue[0] : selectedValue });
        closeModal();
    };

    const selectRateAreas = (selectedValues) => {
        fetchRateAreas()
        onChange(selectedValues.map((c) => c?.value ?? c));
    };

    const renderSelectField = ({ disabled }) => (
        <SelectField
            style={style}
            isDisabled={isDisabled || disabled}
            changeOptionsData={openModal}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage RateAreas")} />
            )}
            showBottomBar={true}
            className={className}
            onChange={onChange}
            data={rateAreas}
            required={required}
            value={value}
            plain={plain}
            label={label || (!isDisabled && t("Rate Area"))}
            error={!value ? t(`Please Select ${label || "Rate Area"}`) : ""}
            showErr={showErr}
            inline={inline}
            borderNone={borderNone}
            RightComponent={() => (
                <SearchButton onClick={openModal} />
            )}
        />
    );

    const renderSelectFieldMultiple = () => (
        <SelectFieldMultiple
            isDisabled={isDisabled}
            style={style}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage Rate Area")} />
            )}
            showBottomBar={true}
            className={className}
            onChange={selectRateAreas}
            data={rateAreas}
            value={value ? rateAreas.filter((c) => value?.includes(c?.value)) : ""}
            error={!value ? t(`Please Select ${label || "Rate Area"}`) : ""}
            required={required}
            label={t("Rate Area")}
            showErr={showErr}
            RightComponent={() => (
                <SearchButton onClick={openModal} />
            )}
        />
    );

   

    return (
        <>
            {gridLoading ? (
                <div className="d-flex align-items-center justify-content-center">
                    <ThreeBounce size={20} color="pink" />
                </div>
            ) : (
                <>
                    {
                        asLabel ? multi ?
                            value?.length > 0 ?
                                (<div> {value?.map((selectedValue, index) => (

                                    <span key={selectedValue}>
                                        {rateAreas.find((rateArea) => rateArea.value === selectedValue)?.label}
                                        {index < value.length - 1 && ", "}
                                    </span>
                                ))}</div>) : <div style={{ color: "grey" }}>--</div>

                            : (<div>{rateAreas?.find((rateArea) => rateArea?.value == value)?.label ?? <div style={{ color: "grey" }}>--</div>}</div>) :
                            <>
                                {modal && (
                                    <ModalComponent
                                        position={"top"}
                                        size={"large"}
                                        onToggle={closeModal}
                                        isOpen={modal}
                                    >
                                        <ModalComponent.Title>
                                            {t("Rate Area")}
                                        </ModalComponent.Title>

                                        <ModalComponent.Body>
                                            <RateArea
                                                value={value}
                                                insidePane={true}
                                                multiMode={multi}
                                                onSelect={multi ? selectRateAreas : selectRateArea}
                                            />
                                        </ModalComponent.Body>

                                    </ModalComponent>
                                    // <Modal onChange={closeModal}>
                                    //   <RateArea
                                    //     value={value}
                                    //     insidePane={true}
                                    //     multiMode={multi}
                                    //     onSelect={multi ? selectRateAreas : selectRateArea}
                                    //   />
                                    // </Modal>
                                )}
                                {rateAreas?.length > 0
                                    ? multi
                                        ? renderSelectFieldMultiple()
                                        : renderSelectField({ disabled: false })
                                    : renderSelectField({ disabled: true })}
                            </>
                    }
                </>
            )}
        </>
    );
};

export default observer(RateAreaSelect);
