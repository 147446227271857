import React, { useEffect, useRef, useState } from 'react';
import NumberInput from '../../../../../../pages/Itinerary/components/NumberInput';
import { useOutsideAlerter } from '../../../../board/helper';
import './index.scss';

const DayNightPicker = ({
    value,
    onChange,
    noToggle = false,
}) => {
    
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    useOutsideAlerter(dropdownRef, () => setIsOpen(false));

    return (
        <div className="zr_daynightpicker_wrapper" ref={dropdownRef}>
            <div
                className="zr_daynightpicker_display"
                onClick={() => setIsOpen(!isOpen)}
            >

                <span>
                    <b className='font-size-14'>{value.days}</b>D     / <b className='font-size-14'>{value.nights}</b>N
                </span>
            </div>

            {isOpen && !noToggle && (
                <div className="zr_daynightpicker_modal">
                    <NumberInput
                        label="Days"
                        value={value.days}
                        min={1}
                        onChange={(v) => onChange({ ...value, days: v })}
                    />

                    <NumberInput
                        label="Nights"
                        min={1}
                        value={value.nights}
                        onChange={(v) => onChange({ ...value, nights: v })}
                    />
                </div>
            )}
        </div>
    );
};

export default DayNightPicker;
