import { useRef, useState } from "react";
import { useOutsideAlerter } from "../dnd/board/helper";
import "./styles.css"


// const calculateModalPosition = (buttonRect) => {
//   const { top, left, bottom, right, width, height } = buttonRect;
//   const modalWidth = 150;
//   const modalHeight = 150;

//   const windowWidth = window.innerWidth;
//   const windowHeight = window.innerHeight;

//   let modalTop = top;
//   let modalLeft = left - modalWidth - 10;

//   if (bottom + modalHeight > windowHeight) {
//     modalTop = top - modalHeight;
//   }
//   if (modalLeft < 0) {
//     modalLeft = right;
//   }

//   return { top: modalTop, left: modalLeft };
// };

const calculateModalPosition = (buttonRect, modalRect) => {
  const { top, left, bottom, right } = buttonRect;
  const { width: modalWidth, height: modalHeight } = modalRect;
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  let modalTop = top;
  let modalLeft = left - modalWidth - 10;

  if (bottom + modalHeight > windowHeight) {
    modalTop = top - modalHeight;
  }
  if (modalLeft < 0) {
    modalLeft = right;
  }
  return { top: modalTop, left: modalLeft };
};




const ActionMenuModal = ({ children ,orientation='horizontal'}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [isPositionCalculated, setIsPositionCalculated] = useState(false);


  useOutsideAlerter(dropdownRef, () => {
    setIsOpen(false);
    // let tableElements = document.querySelectorAll('.rt-table');
    // tableElements[0].style.overflow = "auto";
  });

  const handleButtonClick = () => {
    try {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      // const modalPosition = calculateModalPosition(buttonRect);
      setIsOpen(true);
      setIsPositionCalculated(false);
      setModalPosition(modalPosition);
  
      setTimeout(() => {
        const modalRect = dropdownRef.current.getBoundingClientRect();
        const modalPosition = calculateModalPosition(buttonRect, modalRect);
        setModalPosition(modalPosition);
        setIsPositionCalculated(true);
      }, 0);
      
    } catch (error) {
      
    }
  };

  return (
    <>
      <div className="">
        <div
          ref={buttonRef}
          onClick={(e) => {
            e.stopPropagation()
            // setIsOpen(!isOpen);
            // let tableElements = document.querySelectorAll('.rt-table');
            // tableElements[0].style.overflow = "visible";
            handleButtonClick()
          }}
        >
          <i
            style={{
              cursor: "pointer",
              borderRadius: "5px",
              backgroundColor: isOpen ? "#ccc" : "transparent",
              fontSize: "24px",
              color: "#495057"
            }}
            className={`bx bx-dots-${orientation}-rounded`}
          />
        </div>

      </div>

      {isOpen && (
        <div ref={dropdownRef} className="modal-action shadow-md rounded-2" style={{
          top: isPositionCalculated ? modalPosition.top : -9999,
          left: isPositionCalculated ? modalPosition.left : -9999,
        }} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      )}
    </>

  );
};

export default ActionMenuModal