import React, { useContext, useEffect, useState } from 'react';
import { FaCarAlt } from "react-icons/fa";
import { MdHotel, MdOutlineFlightTakeoff } from "react-icons/md";
import { Card, Row } from 'reactstrap';
import IconBarChart from '../../components/Charts/IconBarChart/IconBarChart';
import SplineChart from '../../components/Charts/SplineChart/SplineChart';
import RecentCard from '../../components/RecentCard/RecentCard';
import TotalRevenueCard from '../../components/TotalRevenueCard/TotalRevenueCard';
import { DialogContext } from '../../store/context/DialogContext';
import { doPOST } from '../../util/HttpUtil';
import { getMonthRangeFilter } from '../../util/Util';

const OverView = ({ customer_id, trip, stay, flight }) => {
    const [tripData, setTripData] = useState([]);
    const { showError } = useContext(DialogContext);

    // will populate data when stay and flights api are available
    const [stayData, setStayData] = useState([]);
    const [flightData, setFlightData] = useState([]);

    const [loading, setLoading] = useState(false);

    const fetchData = async (customerId) => {
        setLoading(true);
        try {
            let response = await doPOST("/api/trip/customer/statistics", {
                "btwFilters": {
                    "tripDate": getMonthRangeFilter(6)
                },
                "filters": {
                    "passengers.customer_id": customerId
                },
                "inFilters": {
                    "status": [5, 19, 20, 29, 30]
                }
            });
            setTripData(response?.data);
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(customer_id);
    }, [customer_id]);

    return (
        <div>
            <Row>
                <div className='col-lg-4 col-md-12 col-sm-10 my-3 mx-4'>
                    <Card className='p-3' style={{ height: "38%" }}>
                    <TotalRevenueCard loading={loading} tripData={tripData}/>
                    </Card>
                    <Card className='p-2 py-4'>
                        <IconBarChart trips={tripData?.length} stays={stayData?.length} flights={flightData?.length} customer_id={customer_id} />
                    </Card>
                </div>
                <div className='col-lg-7 col-md-12 col-sm-10 my-3'>
                    <Card className='p-2'>
                        <SplineChart stayData={stayData} tripData={tripData} flightData={flightData} />
                    </Card>
                </div>
            </Row>
            <Row>
                <Card className='col-lg-4 col-md-12 col-sm-10 mb-3 mx-4'>
                    <RecentCard
                        header="Recent Trips"
                        recentArray={trip}
                        icon={<FaCarAlt size={20} />}
                        type="trip"
                    />
                </Card>
                <Card className='col-lg-4 col-md-12 col-sm-10 mb-3'>
                    <RecentCard
                        header="Recent Flights"
                        recentArray={flight}
                        icon={<MdOutlineFlightTakeoff size={20} />}
                        type="flight"
                    />
                </Card>
                <Card className='col-lg-3 col-md-12 col-sm-10 mb-3 mx-4'>
                    <RecentCard
                        header="Recent Stays"
                        recentArray={stay}
                        icon={<MdHotel size={20} />}
                        type="stay"
                    />
                </Card>
            </Row>
        </div>
    );
};

export default OverView;
