export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/accesslog/grid?sortAsc=false&sortBy=time&rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },

  //   future refference
  //   get: (vehicleID) => `/api/vehicle/${vehicleID}/vehicleDetail`,
};

export const STRUCTURE = [
  {
    label: "Source",
    description: "description...",
    filterLabel: "Source",
    filterName: "source",
    type: "select",
    data: [
      { label: "Web", value: 1 },
      { label: "APP", value: 2 },
      { label: "API", value: 3 },
    ],
  },
  {
    label: "User Type",
    description: "description...",
    filterLabel: "User Type",
    filterName: "userType",
    type: "select",
    data: [
      { label: "User", value: 1 },
      { label: "Admin", value: 2 },
      { label: "Super Admin", value: 3 },
      { label: "Impersonation", value: 4 },
    ],
  },
  {
    label: "Status",
    description: "description...",
    filterLabel: "Status",
    filterName: "status",
    type: "select",
    data: [
      { label: "Failed", value: 0 },
      { label: "Password Accpeted", value: 1 },
      { label: "OTP Accepted", value: 2 },
    ],
  },
  {
    label: "Start Time",
    descriptionp: "description...",
    filterLabel: "Start Time",
    filterName: "startTimeFrom",
    type: "dateTimeSelect",
  },
  {
    label: "On End Time",
    description: "description...",
    filterLabel: "End Time",
    filterName: "startTimeTo",
    type: "dateTimeSelect",
  },
];
