import React from "react";
import Step from "./Step";

export default function StepNavigation(props) {
  const { checkSteps = [] } = props;
  return (
    <div className="stepWrapper">
      {props?.labelArray.map((item, index) => {
        return (
          <>
            <Step
              key={index}
              checkSteps={checkSteps}
              total={props?.labelArray}
              index={index}
              label={item}
              updateStep={props?.updateStep}
            ></Step>
          </>
        );
      })}
    </div>
  );
}
