import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import ClientSelectService from '../../../components/Dropdowns/ClientSelect/ClientSelectService'
import { InputWithDropDown } from '../MarketplaceComponents'
import { toJS } from "mobx";


const TransporterStep3 = ({ data, setData }) => {

    const [myClientsData, setMyClientsData] = useState(data?.myClients)
    const [clients, setClients] = useState([]);

    const handleChangeClients = async (e) => {
        const filteredArray = Array.isArray(e) ? e.map((x) => x.value) : [];

        setMyClientsData(filteredArray.filter((item, index) => filteredArray.indexOf(item) === index))
    }


    const fetch = async () => {
        try {
            await ClientSelectService.fetch();
            setClients(toJS(ClientSelectService.clients));
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (ClientSelectService.clients.length === 0) fetch();
    }, []);

    useEffect(() => {
        setClients(toJS(ClientSelectService.clients));
    }, []);

    console.log(clients, myClientsData, "clients")

    return (
        <>


            <>
                {/* <Row className='mt-4'>
                    <Col className=' col-sm-12 px-4'>
                        <ClientSelect
                            label="Existing Client"
                            onChange={(v) => {
                                setData({
                                    ...data, myClients: v.value
                                });
                            }}
                        />
                    </Col>
                </Row> */}
                <Row className='mt-3'>
                    <Col className=' col-sm-12 px-4'>
                        <InputWithDropDown
                            isPadding={"2px 5.3px"}
                            label="Existing Client"
                            name="sub_disease"
                            id="sub_disease"
                            data={clients}
                            isMulti={true}
                            value={clients?.filter((obj) => myClientsData?.includes(obj?.value))}
                            dataFormatted={true}
                            onChange={handleChangeClients}
                        />
                    </Col>
                </Row>


            </>




        </>
    )
}

export default TransporterStep3