import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import Supplier from "../../../pages/Suppliers/Supplier";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, Modal, ModalComponent, SearchButton } from "../../index";
import { ThreeBounce } from "better-react-spinkit";
import SupplierService from "../../../pages/Suppliers/SupplierService";

const SupplierSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  plain,
  outline,
  asLabel,
  showClear
}) => {
  const [suppliers, setSuppliers] = useState([]);
  const [modal, setModal] = useState(false);
  const [gridLoading, setGridLoading] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const fetchSuppliers = async () => {
    setGridLoading(true);
    try {
      await SupplierService.fetchAll();
      setSuppliers(SupplierService.supplierCache);
      setGridLoading(false);
    } catch (error) {
      showError(error)
    }
    setGridLoading(false);
  };

  useEffect(() => {
    if (SupplierService.supplierCache.length === 0) fetchSuppliers();
  }, []);


  useEffect(() => {
    setSuppliers(SupplierService.supplierCache);
  }, [SupplierService.supplierCache]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectSupplier = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0] : selectedValue });
    closeModal();
  };

  const selectSuppliers = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Suppliers")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      showClear={showClear}
      data={suppliers}
      required={required}
      value={value}
      label={label || (t("Supplier"))}
      error={!value ? t(`Please Select ${label || "Supplier"}`) : ""}
      showErr={showErr}
      inline={inline}
      plain={plain}
      outline={outline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Suppliers")} />
      )}
      // showBottomBar={true}
      className={className}
      plain={plain}
      outline={outline}
      onChange={selectSuppliers}
      data={suppliers}
      value={value ? suppliers.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Suppliers"}`) : ""}
      required={required}
      label={t("Select Suppliers")}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
      {gridLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <ThreeBounce size={20} color="pink" />
        </div>
      ) : (
        <>
          {asLabel ? (
            multi ? (
              value?.length > 0 ? (
                <div>
                  {value.map((selectedValue, index) => (
                    <span key={selectedValue}>
                      {suppliers.find((supplier) => supplier.value === selectedValue)?.label}
                      {index < value.length - 1 && ", "}
                    </span>
                  ))}
                </div>
              ) : (
                <div style={{ color: "grey" }}>Supplier Not Found</div>
              )
            ) : (
              <div style={{display : (value ? "block" : "none")}}>
                {suppliers?.find((supplier) => supplier?.value == value)?.label ?? (
                  <div style={{ color: "grey" }}>Supplier Not Found</div>
                )}
              </div>
            )
          ) : (
            <>
              {modal && (
                <ModalComponent position={"top"} size={"large"} onToggle={closeModal} isOpen={modal}>
                  <ModalComponent.Title>{t("Supplier")}</ModalComponent.Title>
                  <ModalComponent.Body>
                    <Supplier
                      value={value}
                      insidePane={true}
                      multiMode={multi}
                      onSelect={multi ? selectSuppliers : selectSupplier}
                    />
                  </ModalComponent.Body>
                </ModalComponent>
              )}

              {suppliers.length > 0 ? (multi ? renderSelectFieldMultiple() : renderSelectField({ disabled: false })) : renderSelectField({ disabled: true })}
            </>
          )}
        </>
      )}
    </>
  );

};

export default observer(SupplierSelect);
