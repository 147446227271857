import React, { useContext, useEffect, useState } from 'react'
import ModalComponent from '../Modal/Modal'
import { observer } from 'mobx-react-lite';
import viewService from './viewService';
import { Button, Card } from 'reactstrap'; 
import { FaPlus } from 'react-icons/fa';
import ViewGrid from './ViewGrid';
import EditCreateView from './EditCreateView';
import SaveButton from '../Buttons/SaveButton';
import { DialogContext } from '../../store/context/DialogContext';
import { I18nContext } from '../../store/context/i18nContext';
import { MdClose } from "react-icons/md";


const Views = ({filterStructure,module="views"}) => {
  const { showMessage, showError } = useContext(DialogContext);
  const { t } = useContext(I18nContext)
  const [gridModal, setGridModal] = useState(false);
  const [editInfo, setEditInfo] = useState({
    editId: null,
    visible:false
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    type: 1,
    fromDate: null,
    tillDate: null,
    module,
    filterUrl: '',
    filterObject: {},
    supervisors: []
  })

  const toggleGridModal = () => setGridModal(prev=>(!prev));
 
  useEffect(() => {
    viewService.fetch(module)
  }, [module])

  const fetchView=async()=>{
    try {
      if(!editInfo.editId) return;
      setLoading(true);
      const response=await viewService.get(editInfo.editId);
      setData(response)
    } catch (error) {
       showError(error);
    }finally{
      setLoading(false);
    }
  }

  useEffect(()=>{
     fetchView()
},[editInfo.editId])

  const onSave = async (e) => {
    e.preventDefault();
    if (!data?.name || !data?.fromDate || !data?.tillDate) {
      showError(t("Please enter all required fields"));
      return;
    }

    setLoading(true);
    try {
      if (data?._id) {
        await viewService.edit(data, data?._id);
        setLoading(false);
        showMessage(t("View updated successfully."));
      } else {
        const Space = await viewService.save({ ...data, });
        setLoading(false);
        showMessage(t("View saved successfully."));
      }
      // fetchData(filterURL);
    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };


  return (
    <>
      <div style={{height : "36px"}} onClick={toggleGridModal} className='border-1 border rounded-2 fs-6  px-3 cursor-pointer d-flex just-fy-content-center align-items-center' >
        Views
      </div>
      <ModalComponent
        position={"top"}
        size={"full-screen"}
        onToggle={toggleGridModal}
        isOpen={gridModal}
      >
        <ModalComponent.Title >
          <div className=" d-flex ">
            <h5 className="m-0">{editInfo?.visible?`${data?._id?'Edit ':'Create '} `:''} Views</h5>
          </div>
        </ModalComponent.Title>
        
        <ModalComponent.Body stretch>

          <div className='d-flex flex-1'>
            <Card className=" pb-3 w-100">
              <div  className=" ps-3 py-2 d-flex align-items-center justify-content-end">
                 <div className="d-flex align-items-center">
                  <Button
                    onClick={()=>setEditInfo(prev=>({editId:null,visible:!prev.visible}))}
                    className=" d-flex align-items-center p-1 me-2">
                    {!editInfo?.visible ?<FaPlus className='' />:<MdClose />}
                  </Button>
                </div>
              </div>
              <div className="px-2 ">
                {!editInfo?.visible ? <ViewGrid data={viewService?.records} onClick={setEditInfo}/>
                  : <EditCreateView loading={loading} editId={editInfo?.editId} filterStructure={filterStructure} data={data} onChange={setData} />}
              </div>
            </Card>
          </div>

        </ModalComponent.Body>
        <ModalComponent.Footer>
          {editInfo?.visible && <SaveButton zeroXMargin className="save-button me-0" onClick={onSave} />}
        </ModalComponent.Footer>
      </ModalComponent>
    </>
  )
}

export default observer(Views)