import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { Button } from 'reactstrap';
import { CheckedIcon } from '../../assets/images';
export function MyVerticallyCenteredModal(props) {
    console.log(props);
    return (
        <Modal
            {...props}
            size="lg"
            style={{ height: "95%" }}
             aria-labelledby="contained-modal-title-vcenter "
            centered
        >
            <div className='d-flex justify-content-center' style={{ marginTop: "100px",marginBottom: "100px" }} >
                <div className='d-flex justify-content-center' style={{ backgroundColor: "#17B117", margin: "10px 250px", borderRadius: "146px", height: "80px", width: "200px" }}>
                    <img height={40} className="mt-4" src={CheckedIcon} alt="" />
                </div>
            </div>

            <h4 className=' d-flex justify-content-center mt-4 fa-3x fw-bolder mt-3'>{props?.heading}</h4>
            <p className=' mt-3 fa-2x px-5 text-center' >
                {props?.details}
            </p>
            <div className='d-flex justify-content-center' style={{ marginTop: "100px",marginBottom: "100px" }}>
                <Button style={{ height: "fit-Content", width: "fit-Content" }} className='get-started-btn' onClick={() => props?.onHide()} ><span >{props?.buttontext}</span></Button>
            </div>
        </Modal>
    );
}