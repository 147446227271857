import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditStates from "./EditState";
import { STRUCTURE } from "./StateConstant";
import { GridColumns } from "./StateGridColumn";
import StateService from "./StateService";


const States = ({ insidePane }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/state/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await StateService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
  }, [])

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData.name) {
      showError(t("Please enter all fields"));
      return;
    }
    setLoading(true);
    try {
      if (editID) {
        await StateService.edit(detailData, editID);
        showMessage(t("State updated successfully."));
      } else {
        const stateID = await StateService.save(detailData);
        showMessage(t("State saved successfully."));
        if (!insidePane) navigate(`/state/edit/${stateID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await StateService.delete(id);
        showMessage("State Deleted Successfully", "Deleted");
        navigate("/state");
        setShowDetailPage(false);
      } catch (e) {
        showError(e);
      }
    }
  };

  return (
    <>
      <Layout
        medium
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/state");
          setEditID(null);
        }}
         
        title={t("States")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/state/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={StateService.page}
        rows={StateService.rows}
        total={StateService.total}
        fetch={StateService.fetch}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem>Bulk Delete</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> Download </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={StateService.records}
              total={StateService.total}
              uiPreference="state.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={setSelectedIDs}
              page={StateService.page}
              rowsPerPage={StateService.rowsPerPage}
              onPaginationChange={StateService.onPaginationChange}
              renderLastCol={(state) => {
                return (
                  <>
                    <EditLink
                      onClick={() => {
                        if (!insidePane)
                          navigate(`/state/edit/${state?._id}`);
                        setEditID(state?._id);
                        setShowDetailPage(true);
                      }}
                    />
                    <DeleteLink
                      onClick={(event) => onDelete(event, state?._id)}
                    />
                  </>
                );
              }}
            />
          </>
        </Layout.Table>

        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit State") : t("Add State")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditStates
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {detailData?._id && <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />}
          <SaveButton onClick={onSave} loading={loading} />
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(States);
