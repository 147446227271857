import React, { useContext } from 'react'
import { AddressField, Checkbox, InputField, NameField, PhoneNum } from '../../components'
import { I18nContext } from '../../store/context/i18nContext';
import { Row } from 'reactstrap';
import MapComponent from '../../components/Map/Map';

const UserProfile = ({ data, setData, hasErr }) => {
    const { t } = useContext(I18nContext);
    return (
        <div>
            <NameField
                onNameChange={(v) => setData({ ...data, name: v })}
                onSalutationChange={(v) => setData({ ...data, salutation: v })}
                name={data?.name}
                required={true}
                salutation={data?.salutation}
                label="Name"
                placeholder={t("Name")}
                className="col-sm-12 col-md-10"
            />

            <PhoneNum
                placeholder={t("Phone No ")}
                value={{
                    countryCode: data?.phoneNo?.countryCode,
                    phone: data?.phoneNo?.phone,
                }}
                error={data?.phoneNo && data?.phoneNo.length === 0 ? t("Please enter Phone Number") : ""}
                label={"Phone"}
                showErr={hasErr}
                required={true}
                className="col-sm-12 col-md-10"
                onChange={(v) => {
                    setData({
                        ...data,
                        phoneNo: {
                            ...data.phoneNo,
                            countryCode: v?.countryCode,
                            phone: v?.phone,
                        }
                    })
                }}
                type="text"
            />
            <InputField
                placeholder={t("Email")}
                value={data?.email}
                required={true}
                label={t("Email")}
                onChange={(v) => setData({ ...data, email: v })}
                error={data?.code?.length === 0 ? t("Please enter email") : ""}
                showErr={hasErr}
                type="text"
                className="col-sm-12 col-md-10"
            />


            <InputField
                maxLength={40}
                placeholder={t("Designation")}
                value={data?.designation}
                label={t("Designation")}
                onChange={(v) => setData({ ...data, designation: v })}
                error={data?.code?.length === 0 ? t("Please enter Designation") : ""}
                showErr={hasErr}
                type="text"
                className="col-sm-12 col-md-10"
            />
            <InputField
                maxLength={40}
                placeholder={t("Department")}
                value={data?.dept}
                label={t("Department")}
                onChange={(v) => setData({ ...data, dept: v })}
                error={data?.code?.length === 0 ? t("Please enter Dept") : ""}
                showErr={hasErr}
                type="text"
                className="col-sm-12 col-md-10"
            />

            <Checkbox
                leftAlign
                label={t("Status")}
                checked={data?.status === 1 ? true : false}
                className="col-sm-6 col-md-3 mt-3"
                onClick={() => {
                    setData({
                        ...data,
                        status: data?.status === 1 ? 0 : 1,
                    });
                }}
            />


            <Row className="mt-3">
                <Checkbox
                    leftAlign
                    label={t("Is Booker")}
                    checked={data?.isBooker}
                    className="col-sm-6 col-md-3"
                    onClick={() => {
                        setData({
                            ...data,
                            isBooker: !data?.isBooker,
                        });
                    }}
                />
                <Checkbox
                    leftAlign
                    label={t("Is Approver")}
                    checked={data?.isApprover}
                    className="col-sm-6 col-md-3"
                    onClick={() => {
                        setData({
                            ...data,
                            isApprover: !data?.isApprover,
                        });
                    }}
                />

                <Checkbox
                    leftAlign
                    label={t("Transport Adhoc")}
                    checked={data?.transportAdhoc}
                    className="col-sm-6 col-md-3"
                    onClick={() => {
                        setData({
                            ...data,
                            transportAdhoc: !data?.transportAdhoc,
                        });
                    }}
                />
                <Checkbox
                    leftAlign
                    label={t("Transport Regular")}
                    checked={data?.transportRegular}
                    className="col-sm-6 col-md-3"
                    onClick={() => {
                        setData({
                            ...data,
                            transportRegular: !data?.transportRegular,
                        });
                    }}
                />
            </Row>

            <Row className="mt-3">
                <Checkbox
                    leftAlign
                    label={t("Regular Pickup")}
                    checked={data?.availsRegularPickup}
                    className="col-sm-6 col-md-3"
                    onClick={() => {
                        setData({
                            ...data,
                            availsRegularPickup: !data?.availsRegularPickup,
                        });
                    }}
                />
                <Checkbox
                    leftAlign
                    label={t("Regular Drop")}
                    checked={data?.availsRegularDrop}
                    className="col-sm-6 col-md-3"
                    onClick={() => {
                        setData({
                            ...data,
                            availsRegularDrop: !data?.availsRegularDrop,
                        });
                    }}
                />

                <Checkbox
                    leftAlign
                    label={t("Adhoc Pickup")}
                    checked={data?.availsAdhocPickup}
                    className="col-sm-6 col-md-3"
                    onClick={() => {
                        setData({
                            ...data,
                            availsAdhocPickup: !data?.availsAdhocPickup,
                        });
                    }}
                />
                <Checkbox
                    leftAlign
                    label={t("Adhoc Drop")}
                    checked={data?.availsAdhocDrop}
                    className="col-sm-6 col-md-3"
                    onClick={() => {
                        setData({
                            ...data,
                            availsAdhocDrop: !data?.availsAdhocDrop,
                        });
                    }}
                />
            </Row>

            <Row className=" my-3">
                <AddressField
                    placeholder="Home address"
                    label="Residence"
                    value={data?.residence}
                    className={"w-100"}
                    onChange={(e) => setData({ ...data, residence: e })}
                />
            </Row>

            <Row className=" mb-3">
                <MapComponent
                    search
                    showMarker={true}
                    onDrag={(coordiantes) => {
                        setData(prev => ({
                            ...prev,
                            residence: {
                                ...prev?.residence,
                                lat: coordiantes?.lat,
                                lng: coordiantes?.lng,
                            }
                        }))
                    }}
                    centerLatLng={data?.residence}
                />
            </Row>
        </div>
    )
}

export default UserProfile