import { makeAutoObservable } from 'mobx';
import { doGET, doPOST, doPUT } from '../../../util/HttpUtil';
import moment from 'moment';
import { customerFromCustomerId } from '../../../util/Util';
import CustomerLabelService from '../../../components/Dropdowns/CustomerSelect/CustomerLabelService';

class Service {
    chats = [];
    selectedChat = null;
    messages = [];
    currentPage = 1;
    rowsPerPage = 15;
    hasMoreMessages = true;
    current_customer_id = null;
    currentPhoneNo = null;
    loadingMessages = false; 

    constructor() {
        makeAutoObservable(this);
    }

    // Fetches chat list and initializes the first chat if not provided
    fetchChats = async (chatId = null) => {
        try {
            await CustomerLabelService.fetchCustomerNameFromCustomerId();
            const response = await doGET("/api/conversation-head/grid?sortAsc=false&sortBy=lastMessage&rows=-1");
            if (response?.data) {
                const uniquePhoneNumbers = new Set();
                const chatsArray = response.data.rows.reduce((acc, chat) => {
                    const customer = customerFromCustomerId(chat?.customer_id);
                    const phoneNo = customer?.phoneNo?.phone;
                    if (phoneNo && !uniquePhoneNumbers.has(phoneNo)) {
                        uniquePhoneNumbers.add(phoneNo);
                        acc.push({
                            phone: phoneNo,
                            countryCode: customer?.phoneNo?.countryCode,
                            id: chat._id,
                            customer_id: chat.customer_id,
                            lastWhatsapp: chat.lastWhatsapp,
                            lastWhatsappMsg: chat.lastWhatsappMsg,
                            url: chat?.url,
                            unread: chat.hasUnreadWhatsapp ? 1 : 0,
                        });
                    }
                    return acc;
                }, []);

                const filteredChatsArray = chatsArray.filter(chat => chat.lastWhatsapp !== undefined);
    
                filteredChatsArray.sort((a, b) => b.lastWhatsapp - a.lastWhatsapp);
                this.chats = filteredChatsArray.map(chat => ({
                    ...chat,
                    lastWhatsapp: moment.unix(chat.lastWhatsapp).format('hh:mm A')
                }));
                this.current_customer_id = chatId || (this.chats.length ? this.chats[0]?.customer_id : null);
                let customer=await CustomerLabelService?.fetchCustomerNameFromCustomerId(this.current_customer_id);
                this.fetchMessages(customer?.phoneNo?.phone);
            }
        } catch (error) {
            console.error("Failed to fetch chats", error);
        }
    };

     markChat = async (chat_id) => {
        if (!chat_id) {
            console.error("Chat ID is required");
            return;
        }
        try {
            const response = await doPUT(`/api/conversation-head/mark?_id=${chat_id}&type=whatsapp`);
        } catch (error) {
            console.error("Failed to mark chats", error);
        }
    }

    scrollToBottom = () => {
        const chatContainer = document.querySelector('.zr_crm_whatsapp_message-list');
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight - chatContainer.clientHeight;
        }
    };
    handleFetchMessageAtBottom = async(phoneNum, customer_id) => {
        const messagesLoaded = await this.fetchMessages(phoneNum);
        if (messagesLoaded) {
            setTimeout(() => {
                this.scrollToBottom();
            }, 1000);
        }
    }

    setSelectedChat = async (customer_id) => {
        if (this.current_customer_id === customer_id) return;
        this.messages = [];
        this.currentPage = 1; 
        this.hasMoreMessages = true;
        this.current_customer_id = customer_id;
        this.currentPhoneNo = customerFromCustomerId(this.current_customer_id)?.phoneNo.phone;
    };
    fetchMessages = async (phoneNo, loadMore = false) => {
        if (!phoneNo) return;
        if (loadMore) {
            this.currentPage += 1; 
        } else {
            this.currentPage = 1; 
            this.hasMoreMessages = true; 
        }

        this.currentPhoneNo = phoneNo;

        if (this.hasMoreMessages && !this.loadingMessages) {
            this.loadingMessages = true;
            try {
                const response = await doGET(`/api/sms/grid?platform=WHATSAPP&phone.phone=${phoneNo}&rows=${this.rowsPerPage}&page=${this.currentPage}`);
                if (response?.data) {
                    const newMessages = response.data.rows.map(msg => ({
                        id: msg._id,
                        text: msg.content,
                        time: moment.unix(msg.sentAt).format('hh:mm A'),
                        sender: msg.inbound ? 'other' : 'self',
                        sentAt: msg.sentAt,
                        attachment: msg?.attachment?.urls ?? []
                    }));

                    this.hasMoreMessages = response.data.rows.length === this.rowsPerPage;

                    this.messages = loadMore ? [...this.messages, ...newMessages] : newMessages;
                    setTimeout(() => {
                        this.checkTopMessage();
                    }, 100); 
                    return true;
                }
            } catch (error) {
                console.error("Failed to fetch messages", error);
            } finally {
                this.loadingMessages = false;
            }
        }
    };
    
    checkTopMessage = () => {
        const chatContainer = document.querySelector('.zr_crm_whatsapp_message-list');
        if (chatContainer) {
            const contentHeight = chatContainer.scrollHeight;
            const containerHeight = chatContainer.clientHeight;
            
            if (contentHeight <= containerHeight && this.hasMoreMessages) {
                this.fetchMessages(this.currentPhoneNo, true);
            }
        }
    };
    

    // Sends a message to a customer
    sendMessage = async (customer_id, message, phone, countryCode) => {
        if(!customer_id) return;

        const formData = new FormData();
        formData.append('data', JSON.stringify({
            customer_id,
            content: message,
            platform: "WHATSAPP",
            phone: { phone: phone?.toString(), countryCode: countryCode?.toString() },
            smsType: "CUSTOM"
        }));

        try {
            const response = await doPOST('/api/sms/create', formData);
            if (response?.data) {
                this.messages.push({
                    id: response.data._id,
                    text: message,
                    time: new Date().toLocaleTimeString(),
                    sender: 'self'
                });
                this.fetchMessages(phone);
                this.fetchChats(customer_id);
            }
        } catch (error) {
            return error;
        }
    };

    sendAttachment = async (chatId, message, phone, countryCode, attachments) => {
        if (!chatId) return;

        const formData = new FormData();
        formData.append('data', JSON.stringify({
            customer_id: chatId,
            content: message,
            platform: "WHATSAPP",
            phone: { phone: phone.toString(), countryCode: countryCode.toString() },
            smsType: "CUSTOM"
        }));

        attachments.forEach((file, index) => formData.append(`attachment_${index}`, file));

        try {
            const response = await doPOST('/api/sms/create', formData);
            if (response?.data) {
                this.messages.push({
                    id: response.data._id,
                    text: message,
                    time: new Date().toLocaleTimeString(),
                    sender: 'self'
                });
                this.fetchMessages(phone);
                this.fetchChats(chatId);
            }
        } catch (error) {
            console.error("Failed to send attachment", error);
        }
    };
}

const WhatsAppService = new Service();
export default WhatsAppService;
