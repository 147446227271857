import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Row, Col } from "reactstrap";
import { EditLink, Layout, SaveButton, } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import FundAccService from "./FundAccountService";
import EditFundAccount from "./EditFundAccount";
const Country = (props) => {
    const { t } = useContext(I18nContext);
    const { id } = useParams();
    const { showError, showMessage } = useContext(DialogContext);
    const [selected_id, setSelected_id] = useState(id);
    const [data, setData] = useState({});
    const [showDetailPage, setShowDetailPage] = useState(id);
    const [hasErr, setHasErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEditDataChanged, setIsEditDataChanged] = useState(false);
    const fetchData = async (filterUrl) => {
        if (loading) return;
        setLoading(true);
        try {
            await FundAccService.fetch(filterUrl);
        } catch (e) {
            showError(e.message);
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    const submitHandler = async (e) => {
        e.preventDefault();
        if (!data?.details?.accountName || !data?.details?.bankIFSCCode || !data?.details?.bankName || !data?.details?.identificationNumber) {
            showError("Please Provide Bank Account Details");
            return;
        }
        if (!data?.accountKind) {
            showError("Please Provide Account Kind");
            return;
        }
        if (!data?.openingDate) {
            showError("Invalid Opening Date");
            return;
        }
        setHasErr(true);
        setLoading(true);
        try {
            const res_id = await FundAccService.save(data);
            showMessage("Saved successfully");
            navigate(`/fundaccounts/edit/${res_id}`);
        } catch (error) {
            showError(error);
        }
        fetchData();
        setLoading(false);
    };
    let navigate = useNavigate();
    useEffect(() => {
        if (id !== selected_id) setSelected_id(id);
        setShowDetailPage(window.location.pathname === "/fundaccounts/edit" || id);
    }, [id, selected_id]);
    return (
        <Layout
            showDetailPage={showDetailPage}
             
            title={t("Fund Accounts")}
            onAddClick={() => {
                if (!props?.insidePane) navigate(`/fundaccounts/edit`);
                setShowDetailPage(true);
                setSelected_id(null);
            }}
            backDetailPage={() => {
                setShowDetailPage(false);
                if (!props?.insidePane) navigate("/fundaccounts");
                setSelected_id(false);
            }}
            page={FundAccService.page}
            rows={FundAccService.rows}
            total={FundAccService.totalRecords}
            onPageChange={async (page, rows) => {
                await FundAccService.onPaginationChange(page, rows);
            }}
        >
            <Layout.TheadFull>
                <Thead className="thead-dark">
                    <Tr>
                        <Th>{t("Account Number")}</Th>
                        <Th>{t("Account Kind")}</Th>
                        <Th>{t("Bank Name")}</Th>
                        <Th>{t("Balance")}</Th>
                        <Th className="float-end">{t("Action")}</Th>
                    </Tr>
                </Thead>
            </Layout.TheadFull>
            <Layout.TbodyFull>
                <Tbody>
                    {FundAccService.records?.map((acc) => (
                        <Tr
                            key={acc?._id}
                        >
                            <Td>{acc?.details?.identificationNumber}</Td>
                            <Td>{acc?.accountKind}</Td>
                            <Td>{acc?.details?.bankName}</Td>
                            <Td>{acc?.balance}</Td>
                            <Td className="float-end">
                                <EditLink
                                    className="mx-2"
                                    onClick={() => {
                                        if (!props?.insidePane)
                                            navigate(`/fundaccounts/edit/${acc?._id}`);
                                        setSelected_id(acc?._id);
                                        setShowDetailPage(true);
                                    }}
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Layout.TbodyFull>
            <Layout.DetailPageTitle>
                <Row>
                    <h5>{data?._id ? 'Edit Fund Account' : 'Create Fund Account'}</h5>
                </Row>
            </Layout.DetailPageTitle>
            <Layout.DetailPageBody>
                <EditFundAccount
                    insidePane={props?.insidePane}
                    onChange={(v) => setIsEditDataChanged(v)}
                    id={selected_id}
                    back={() => {
                        setShowDetailPage(false);
                        if (!props?.insidePane) navigate("/country");
                        setSelected_id(false);
                    }}
                    fetchData={fetchData}
                    data={data}
                    setData={setData}
                    hasErr={hasErr}
                    setHasErr={setHasErr}
                />
            </Layout.DetailPageBody>
            <Layout.DetailPageFooter>
                <Row>
                    <Col>
                        <div
                            className="d-flex justify-content-end mt-1"
                            style={{ marginTop: "-2.5rem" }}
                        >
                            <SaveButton onClick={(e) => { submitHandler(e) }} />
                        </div>
                    </Col>
                </Row>
            </Layout.DetailPageFooter>
        </Layout>
    );
};
export default observer(Country);