import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import Template from "../../../pages/Template/Template";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, Modal, SearchButton } from "../../index";
import TemplateSelectService from "./TemplateSelectService";

const TemplateSelect = ({
    style,
    isDisabled,
    className,
    onChange,
    required,
    value,
    label,
    showErr,
    inline,
    borderNone,
    multi,
    asLabel,
    kind,
    forUpload
}) => {
    const [templates, setTemplates] = useState([]);
    const [modal, setModal] = useState(false);
    const { showError } = useContext(DialogContext)
    const { t } = useContext(I18nContext)

    const fetchTemplates = async () => {
        try {
            let filterUrl = (forUpload == true || forUpload == false) ? `forUpload=${forUpload}` : ``
            await TemplateSelectService.fetch({ kind, filterUrl });
            setTemplates(toJS(TemplateSelectService.templates));
        } catch (error) {
            showError("Error", error)
        }
    };

    useEffect(() => {
        if (TemplateSelectService.templates.length === 0) fetchTemplates();
    }, []);

    useEffect(() => {
        if (kind) fetchTemplates();
    }, [kind]);

    useEffect(() => {
        setTemplates(toJS(TemplateSelectService.templates));
    }, [TemplateSelectService.templates]);

    const openModal = () => {
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    const selectTemplate = (selectedValue) => {
        onChange({ value: multi ? selectedValue[0] : selectedValue });
        closeModal();
    };

    const selectTemplates = (selectedValues) => {
        onChange(selectedValues.map((c) => c?.value ?? c));
    };

    const renderSelectField = ({ disabled }) => (
        <SelectField
            style={style}
            isDisabled={isDisabled || disabled}
            changeOptionsData={openModal}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage Templates")} />
            )}
            showBottomBar={true}
            className={className}
            onChange={onChange}
            data={templates}
            required={required}
            value={value}
            label={label || (!isDisabled && t("Template"))}
            error={!value ? t(`Please Select ${label || "Template"}`) : ""}
            showErr={showErr}
            inline={inline}
            borderNone={borderNone}
            RightComponent={() => (
                <SearchButton onClick={openModal} />
            )}
        />
    );

    const renderSelectFieldMultiple = () => (
        <SelectFieldMultiple
            isDisabled={isDisabled}
            style={style}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage Templates")} />
            )}
            showBottomBar={true}
            className={className}
            onChange={selectTemplates}
            data={templates}
            value={value ? templates.filter((c) => value?.includes(c?.value)) : ""}
            error={!value ? t(`Please Select ${label || "Templates"}`) : ""}
            required={required}
            label={t("Select Templates")}
            showErr={showErr}
            RightComponent={() => (
                <SearchButton onClick={openModal} />
            )}
        />
    );

    return (
        <>
            {asLabel ? multi ?
                value?.length > 0 ?
                    (<div> {value?.map((selectedValue, index) => (

                        <span key={selectedValue}>
                            {templates.find((template) => template.value === selectedValue)?.label}
                            {index < value.length - 1 && ", "}
                        </span>
                    ))}</div>) : <div style={{ color: "grey" }}>No Record Found</div>

                : (<div>{templates?.find((template) => template?.value == value)?.label ?? <div style={{ color: "grey" }}>No record Found</div>}</div>) :
                <>

                    {modal && (
                        <Modal onChange={closeModal}>
                            <Template
                                value={value}
                                insidePane={true}
                                multiMode={multi}
                                onSelect={multi ? selectTemplates : selectTemplate}
                            />
                        </Modal>
                    )}
                    {templates.length > 0
                        ? multi
                            ? renderSelectFieldMultiple()
                            : renderSelectField({ disabled: false })
                        : renderSelectField({ disabled: true })}
                </>
            }
        </>
    );
};

export default observer(TemplateSelect);
