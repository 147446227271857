import React, { useContext, useEffect, useState } from 'react'
import { InputField } from '../InputField/InputField'
import { I18nContext } from '../../store/context/i18nContext';
import DateSelectCustom from '../DateTimePickerWithInterval/DateSelectCustom';
import Filter from '../../pages/Filter/Filter';
import { Col, Row } from 'reactstrap';
import SupervisiorAssigner from '../Dropdowns/SupervisiorSelect/SupervisiorAssigner';
import { generateUniqueId } from '../dnd/board/helper';
import { STRUCTURE } from '../../pages/Booking/BookingConstant';
import { prepareFilterArtifacts } from '../../util/FilterUtil';
import { ThreeBounce } from 'better-react-spinkit';

function EditCreateView({ data, onChange, filterStructure = [], loading }) {
    const { t } = useContext(I18nContext);
    const [filterObject, setFilterObject] = useState({})

    useEffect(() => {
        if (filterStructure?.length && data?.filterUrl) {
            let { filterObject } = prepareFilterArtifacts(new URLSearchParams(data?.filterUrl), filterStructure)
            setFilterObject(filterObject)
        } else {
            setFilterObject({})
        }
    }, [data?.filterUrl, filterStructure])

    const onApplyFilter = (filters) => {
        onChange(prev => ({
            ...prev,
            filterUrl: filters
        }))
    }



    return (
        <>
            {loading ?
                <div className='d-flex justify-content-center align-items-center'>
                    <ThreeBounce />
                </div>
                :
                <div>
                    <InputField
                        placeholder={t("View Name")}
                        value={data?.name}
                        label={t("View Name")}
                        onChange={(v) =>
                            onChange(prev => ({
                                ...prev,
                                name: v
                            }))
                        }
                        className={``}

                    />

                    <DateSelectCustom
                        placeholder={("dd-mm-yyyy")}
                        label="From Date"
                        value={data.fromDate}
                        onChange={(v) =>
                            onChange(prev => ({
                                ...prev,
                                fromDate: v
                            }))
                        }
                        className="col-sm-12 col-12"
                    />


                    <DateSelectCustom
                        value={data?.tillDate}
                        placeholder={("dd-mm-yyyy")}
                        label={'Till Date'}
                        onChange={(v) =>
                            onChange(prev => ({
                                ...prev,
                                tillDate: v
                            }))
                        }
                    />

                    <Row className='d-flex align-items-center'>
                        <Col sm={2} >
                            <div>Filters</div>
                        </Col>
                        <Col sm={10}  >
                            <Filter
                                className="mb-2 ms-3 "
                                isRate={false}
                                insidePane={true}
                                value={filterObject}
                                structure={STRUCTURE}
                                applyFunc={onApplyFilter}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col sm={1} >
                            <div className='fw-normal fs-6'>Supervisors</div>
                        </Col>
                        <Col sm={2} className='position-relative'>
                            <SupervisiorAssigner
                                tooltipId={generateUniqueId()}
                                value={data?.supervisors}
                                onChange={(v) => {
                                    console.log(v)
                                    onChange(prev => ({
                                        ...prev,
                                        supervisors: v
                                    }))
                                }}

                            />
                        </Col>
                    </Row>
                </div>}
        </>
    )
}

export default EditCreateView