import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./InvoiceConstant";
import { doGET, doPOST } from "../../util/HttpUtil";

class Service {

  records = [];
  totalRecords = 0;
  page = 1;
  rows = 20;
  filterOption = "";

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterUrl) => {
    if (filterUrl) this.filterOption = filterUrl;
    console.log(ENDPOINTS.grid(this.page, this.rows, filterUrl));
    const response = await doGET(ENDPOINTS.grid(this.page, this.rows, filterUrl))
    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch(this.filterOption);
  }
  print = async (id, name) => {
    var data = {
      invoice_ids: id,
      printType: 999
    }
    const response = await doPOST(ENDPOINTS.print, data);
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

}

const InvoiceService = new Service();
export default InvoiceService;
