
import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import Tab from "../../Common/Tab/Tab";
import SpaceStatuses from "./SpaceStatuses";
import SpaceService from "../listView/SpaceService";
import LeadMasterService from "../board/LeadMasterService";
import SaveButton from "../../Buttons/SaveButton";
import CustomOperations from "../../CustomField/CustomOperations";


const CustomiseSpace = ({ isOpen, toggleModal, onEdit = () => { } }) => {

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [step, setStep] = useState(null);

    const fetch = async () => {
        try {
            let space = await SpaceService.get(SpaceService.selectedSpace?._id);
            LeadMasterService.statusNameMap = space?.statusNameMap ?? {};
            LeadMasterService.statusMastersRecords = Object.keys(space?.statusNameMap ?? {});
            setData(space);
            setLoading(false)
        } catch (error) {
            showError(error)
            setLoading(false)
        }

    };

    useEffect(() => {
        if (isOpen) {
            setData({});
            setStep(null)
            setLoading(true);
            if (SpaceService.selectedSpace?._id) fetch();
        }
    }, [SpaceService.selectedSpace?._id, , SpaceService?.version, isOpen]);


    const onSave = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (data?._id) {
                await SpaceService.edit(data, data?._id);
                setLoading(false);
                showMessage(t("Space updated successfully."));
                onEdit()
            } else {
                const Space = await SpaceService.save({ ...data, });
                setLoading(false);
                showMessage(t("Space saved successfully."));
                onEdit()
            }
            // fetchData(filterURL);
        } catch (e) {
            setLoading(false);
            showError(e);
        }
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await SpaceService.delete(id);
                setLoading(false);
                showMessage("Lead Deleted SuccessFully", "Deleted");
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };


    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggleModal} style={{ minWidth: '50vw' }}>
                <ModalHeader toggle={toggleModal}>
                    Edit Space
                </ModalHeader>
                <ModalBody className="py-0 mt-0 ">

                    <Tab
                        vertical={false}
                        left
                        activeTab={step}
                        onChange={(s) => {
                            setStep(s);
                        }}
                        outerClassName = ' mt-0 pt-0 '
                        className="d-flex "
                    >
                        <div num={1} label={t("Status")}>
                            <SpaceStatuses
                                statuses={data?.statusNameMap ?? []}
                                onUpdate={(v) => {
                                    setData({ ...data, statusNameMap: v })
                                }}
                            />
                            <SaveButton zeroXMargin className="save-button me-0" onClick={onSave} />

                        </div>
                        <div num={2} label={t("Custom Fields")}>
                            <div>
                                <CustomOperations
                                    module={`lead-${SpaceService?.selectedSpace?._id}`}
                                />
                            </div>
                        </div>

                    </Tab>

                </ModalBody>

            </Modal>

        </React.Fragment>
    );
};

export default CustomiseSpace;
