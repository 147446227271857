import React from 'react'
import GroupTourSidebar from '../GroupTour/sideBar/GroupTourSidebar'
import RosteringView from './RosteringView'

function Rostering({ insidePane = false }) {
    return (
        <div className="d-flex">
            {/* <div style={{ minWidth: "200px" }} className={insidePane ? "" : "page-content"}>
                <GroupTourSidebar module="events" />  
            </div> */}
            <div className="flex-1" style={{ width: `calc(100vw -'250px'))` ,background:'rgb(252, 252, 255)'}}>
                <RosteringView />
            </div>
        </div>
    )
}

export default Rostering