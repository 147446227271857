import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../../util/HttpUtil";
import LeadMasterService from "../../dnd/board/LeadMasterService";

const ENDPOINTS = {
    grid: () => `/api/status-master/grid?rows=-1&sortAsc=true&sortBy=value&module=lead`,
    get: (id) => `/api/status-master/${id}/detail`,
    save: `/api/status-master/create`,
    delete: (id) => `/api/status-master/${id}/delete`,
    update: `/api/status-master/update`,
};

class Service {
    records = [];
    queue = [];
    isFetching = false;

    constructor() {
        makeAutoObservable(this);
    }


    fetchSingle = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this?.records?.length) {
                    const response = await doGET(ENDPOINTS.grid());
                    if (response.status === 200) {
                        this.records = response?.data?.rows
                    } else {
                        this.error = response.data;
                    }
                }

                while (this.queue.length > 0) {
                    const { resolve } = this.queue.shift();
                    resolve();
                }

            } catch (err) {
                this.error = err;
                while (this.queue.length > 0) {
                    const { reject } = this.queue.shift();
                    reject(err);
                }
            } finally {
                this.isFetching = false;
            }
        });
    }

    fetch = async function (props) {
        if (this.isFetching) {
            return new Promise((resolve, reject) => this.queue.push({ resolve, reject }));
        };
        this.isFetching = true;
        this.fetchSingle()
    };
    
    fetchForcefully = async (filterUrl) => {
        const response = await doGET(
            ENDPOINTS.grid()
        );
        if (response?.status === 200) {
            this.records = response.data.rows;
        }
    };

    edit = async function (data, id) {
        await doPUT(ENDPOINTS?.update, data);
    };

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    save = async (data) => {
        const response = await doPOST(ENDPOINTS.save, { ...data, value: this.records[this.records?.length - 1]?.value + 1 });
        if(response.status == 200 ) {
            this.records.push({ ...data, value: this.records[this.records?.length - 1]?.value + 1 });
        }
        return response?.data?._id;
    };

    delete = async (id) => {
        const response = await doDELETE(ENDPOINTS.delete(id));
        if (response.status === 200) {
            this.fetchForcefully();
            LeadMasterService.fetchStatusMaster()
        }
    };
}

const StatusMasterPickerService = new Service();
export default StatusMasterPickerService;

