import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { CurrencySelect, InputField, TimeZoneSelect, OfficeSelect } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import EntityService from "./CompanyService.js";

const EditEntities = ({ insidePane = false, editId, onChange = () => { }, callingType, corp_id }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    timezone: t("Asia/Kolkata"),
    currency: t("INR"),
  });

  const fetch = async () => {
    setLoading(true);
    try {
      let entities = await EntityService.get(editId);
      setLoading(false)
      setData(entities);
    } catch (error) {
      setLoading(false)
      showError(error)
    }
  };

  useEffect(() => {
    setData({
      timezone: t("Asia/Kolkata"),
      currency: t("INR"),
    })
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, EntityService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <Form
          className="p-3  pt-2"
        >
          <Row className="">
            <InputField
              placeholder={t("Brand Name")}
              value={data?.name}
              required={true}
              label={t("Brand Name")}
              onChange={(v) => setData({ ...data, name: v })}
              error={data?.name?.length === 0 ? t("Please enter name") : ""}
              type="text"
              className="col-sm-12"
            />

            <InputField
              required={true}
              placeholder={t("Full Name")}
              value={data?.fullName}
              label={t("Full Name")}
              onChange={(v) => setData({ ...data, fullName: v })}
              error={data?.fullName?.length === 0 ? t("Please enter full name") : ""}

              type="text"
              className="col-sm-12"
            />
            {
              ["supplier", "client"]?.includes(callingType) ? (
                corp_id ? <OfficeSelect
                  required={true}
                  corp_id={corp_id}
                  value={data?.office_id}
                  onChange={(v) => setData({ ...data, office_id: v?.value })}
                  className="col-sm-12"
                  placeholder={t("Office")}
                  label={t("Office")}
                /> : null
              ) : <OfficeSelect
                required={true}
                corp_id={corp_id}
                value={data?.office_id}
                onChange={(v) => setData({ ...data, office_id: v?.value })}
                className="col-sm-12"
                placeholder={t("Office")}
                label={t("Office")}
              />
            }
            <InputField
              placeholder={t("Tax ID/ GST ID")}
              value={data?.companyExtn?.taxId}
              label={t("Tax ID/ GST ID")}
              onChange={(v) =>
                setData({
                  ...data,
                  companyExtn: {
                    ...data?.companyExtn,
                    taxId: v,
                  }

                })
              }
              type="text"
              className="col-sm-12"
            />
            <InputField
              placeholder={t("PAN")}
              value={data?.companyExtn?.pan}
              label={t("PAN")}
              onChange={(v) =>
                setData({
                  ...data,
                  companyExtn: {
                    ...data?.companyExtn,
                    pan: v,
                  }

                })
              }
              type="text"
              className="col-sm-12"
            />
            <InputField
              placeholder={t("TAN")}
              value={data?.companyExtn?.tan}
              label={t("TAN")}
              onChange={(v) =>
                setData({
                  ...data,
                  companyExtn: {
                    ...data?.companyExtn,
                    tan: v,
                  }

                })
              }
              type="text"
              className="col-sm-12"
            />
            {callingType ? null :
              <>
                <InputField
                  placeholder={t("CIN")}
                  value={data?.companyExtn?.cin}
                  label={t("CIN")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      companyExtn: {
                        ...data?.companyExtn,
                        cin: v,
                      }

                    })
                  }
                  type="text"
                  className="col-sm-12"
                />
                <InputField
                  placeholder={t("TIN")}
                  value={data?.companyExtn?.tin}
                  label={t("TIN")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      companyExtn: {
                        ...data?.companyExtn,
                        tin: v,
                      }

                    })
                  }
                  type="text"
                  className="col-sm-12"
                />
                <InputField
                  placeholder={t("ESIC")}
                  value={data?.companyExtn?.esic}
                  label={t("ESIC")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      companyExtn: {
                        ...data?.companyExtn,
                        esic: v,
                      }

                    })
                  }
                  type="text"
                  className="col-sm-12"
                />
                <InputField
                  placeholder={t("EPFO")}
                  value={data?.companyExtn?.epfo}
                  label={t("EPFO 1")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      companyExtn: {
                        ...data?.companyExtn,
                        epfo: v,
                      }

                    })
                  }
                  type="text"
                  className="col-sm-12"
                />

                <TimeZoneSelect
                  placeholder={t("Timezone")}
                  required={true}
                  value={data?.timezone}
                  label={t("Timezone")}
                  onChange={(v) => setData({ ...data, timezone: v.value })}
                  type="text"
                  className="col-sm-12"
                />

                <CurrencySelect
                  placeholder={t("Currency")}
                  required={true}
                  value={data?.currency}
                  label={t("Currency")}
                  onChange={(v) => setData({ ...data, currency: v.value })}
                  type="text"
                  className="col-sm-12"
                />
              </>}

          </Row>

        </Form>
      }
    </React.Fragment>
  );
};
export default observer(EditEntities);
