import { CurrencyAmountField, CompanySelect } from "../../components";

export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Name",
            show: true,
            renderer: (dataObj) => {
                return <div>{dataObj?.client?.fullName} </div>;
            },
        },
        {
            title: "Tax Id",
            show: true,
            renderer: (dataObj) => {
                return <div>{dataObj?.client?.taxId} </div>;
            },
        },
        {
            title: "Company",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    <CompanySelect
                        asLabel
                        corpType="self"

                        value={dataObj?.company_id}
                        onChange={(v) => { }}
                        className=""
                    />
                </div>;
            },
        },
        
        {
            title: "Address Line",
            accessor: "addressLine",
            renderer: (dataObj) => {
                return <div>{dataObj?.client?.address?.addressLine} </div>;
            },
            show: true,

        },
        {
            title: "City",
            accessor: "city",
            renderer: (dataObj) => {
                return <div>{dataObj?.client?.address?.city} </div>;
            },
            show: true,
        },
        {
            title: "State",
            accessor: "state",
            renderer: (dataObj) => {
                return <div>{dataObj?.client?.address?.state} </div>;
            },
            show: true,

        },
        {
            title: "Country",
            accessor: "country",
            renderer: (dataObj) => {
                return <div>{dataObj?.client?.address?.country} </div>;
            },
            show: true,
        },
        {
            title: "Credit Note no",
            accessor: "creditNoteNo",
            show: true,
        },
        {
            title: "Amount",
            accessor: "amount",
            show: true,
        },
        {
            title: "Date",
            accessor: "date",
            renderer: (dataObj) => {
                return <div>
                    {(dataObj?.date?.toString())?.slice(6, 8)}-{(dataObj?.date?.toString())?.slice(4, 6)}-{(dataObj?.date.toString())?.slice(0, 4)}
                </div>;
            },
            show: true,
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};