import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import { AmountField, ModalComponent } from "../../components";
import RemoveLink from "../../components/Buttons/RemoveLink";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doGET, doPOST } from "../../util/HttpUtil";
import Booking from "../BookingsNew/Booking";
import "./style.css";


const TripSettlements = ({ data = [{}], onChange, addSettlement,header,client_id = null, onChangeExcessAmount,clientCompany_id=null}) => {

    const [addModal, setAddModal] = useState(false)
    const { t } = useContext(I18nContext)
    const { showError } = useContext(DialogContext);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalTDS, setTotalTDS] = useState(0);
    const [tripsFetched, setTripsFetched] = useState(false);
    const [tripIds, setTripIds] = useState([]);

    const updateTripSettlements = (trips) => {
        let updatedSettlements = [...data];
        updatedSettlements = updatedSettlements.map((settlement) => {
            let trip=trips.find((trip) =>   trip?._id === settlement?.trip_id);
              return {
                ...settlement,
                tripDate: trip?.tripDate,
                tripNo: trip?.tripNo,
                amountDue: trip?.due,
                totalAmount: trip?.clientAmount,
                writeOff:trip?.writeOff ?? 0,
            };
        });
        onChange([...updatedSettlements]);
    };
    

    const fetchSettledTripPayments = async () => {
        const trip_Ids = data?.map(settlement => settlement.trip_id) ?? [];
        setTripIds(trip_Ids);
        try {
            let tripIds = [];
            if (setTripIds && setTripIds.length) {
                tripIds = setTripIds.join(",");
            }
            const includedQuery = `_id=in[${tripIds}]&client.client_id=${client_id}&client.company_id=${clientCompany_id}`
            // let response = await doGET(`/api/invoice/grid?_id=in[${invoice_Ids}]`);
            let response = await doPOST(
                `/api/trip/grid`,
                setTripIds && setTripIds.length ? { queryURL: includedQuery } : {}
            );            
            updateTripSettlements(response?.data?.rows);
            setTripsFetched(true);
        } catch (error) {
            showError(error)
        }
        return setTripIds;
    };

    const calculateTotalAmountReceived = (updatedData) => {
        let total = 0;
        updatedData?.forEach((acc) => {
            total += acc?.amountSettled || 0;
        });
        setTotalAmount(total)
        // onChangeExcessAmount(total+totalTDS);
        return ;
    };
    const calculateTotalTDSReceived = (updatedData) => {
        let total = 0;
        updatedData?.forEach((acc) => {
            total += acc?.tdsSettled || 0;
        });
        setTotalTDS(total)
        // onChangeExcessAmount(total+totalAmount);
        return ;
    };
    
    const deleteItems = (index) => {
        const updatedData = data.filter((_, i) => i !== index);
        onChange(updatedData);
    };

    const updateItem = ({ index, key, value }) => {
        const updatedData = [...data];
        updatedData[index] = { ...updatedData[index], [key]: value };
        if(key==="amountSettled"){
            calculateTotalAmountReceived(updatedData);
        }
        if(key==="tdsSettled"){
            calculateTotalTDSReceived(updatedData);
        }
        onChange(updatedData);
    };


    useEffect(() => {
        calculateTotalAmountReceived(data);
        calculateTotalTDSReceived(data);
    }, [])


    useEffect(() => {
        if (data?.length && !tripsFetched) {
            fetchSettledTripPayments();
        }
        setTotalAmount(0);
        const trip_Ids = data?.map(settlement => settlement.trip_id) ?? [];
        setTripIds(trip_Ids);
    }, [tripsFetched,data])



    const formatDate = (dateInt) => {
        if (!dateInt) return '';

        // Convert the integer to a string
        const dateString = dateInt.toString();

        // Ensure dateString is a string and has the correct length
        if (typeof dateString !== 'string' || dateString.length !== 8) return dateString;
      
        // Extract year, month, and day from the dateString
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
      
        // Format the date as YYYY-MM-DD
        return `${day}-${month}-${year}`;
      };
    
    return (

        <div>
            {addModal !== false &&
                <ModalComponent
                    position={"top"}
                    size={"large"}
                    isOpen={addModal}
                    onToggle={() => setAddModal(false)}>
                    <ModalComponent.Title>
                        {t("Trips")}
                    </ModalComponent.Title>

                    <ModalComponent.Body>
                        <Booking
                            // value={value}
                            excludedTripStatuses={[1,2,3,4,5,6]}
                            clientId={client_id}
                            clientCompany_id={clientCompany_id}
                            excludedIds={tripIds}
                            insidePane={true}
                            multiMode={false}
                            // onSelect={(v) => {
                            //     addSettlement({
                            //         trip_id: v._id,
                            //         tripDate: v?.tripDate,
                            //         tripNo: v?.tripNo,
                            //         amountDue: v?.clientAmount,
                            //         totalAmount: v?.total,
                            //         writeOff:v?.writeOff ?? 0,
                            //     });
                            //     setAddModal(false)  
                            // }}
                        />
                    </ModalComponent.Body>
                </ModalComponent>
            }

            <div className="d-flex justify-content-between">
                <h5 style={{ fontWeight: '800' }}>{header ?? t("Trips")}</h5>
                <div className="d-flex">
                    <Button
                        color="light"
                        onClick={() => {
                            if(!client_id){
                                showError("Please Select Client");
                            }else{
                                setAddModal(true) 
                            }
                            }}
                        style={{ width: "fit-content" }}
                        className="h-36 d-flex align-items-center mb-2">
                        <i className="bx font-size-20 color-blue bx-plus"></i>
                        {t("Trips")}
                    </Button>

                </div>
            </div>

            <Card>
                <table className="table-bordered settledPayments">
                    <thead>
                        <tr className="py-2" style={{ backgroundColor: '#FAF9FF' }}>
                            <th style={{ width: '18%' }}>{t("Trip Detail")}</th>
                            <th style={{ width: '18%' }}>{t("Trip Amount")}</th>
                            <th style={{ width: '18%' }}>{t("Collected Amount")}</th>
                            {/* <th style={{ width: '18%' }}>{t("TDS")}</th> */}
                            <th style={{ width: '18%' }}>{t("Write off")}</th>
                            <th style={{ width: '10%' }}></th>
                        </tr>
                    </thead>
                    {data.map((trip, index) => (
                        <tbody className="settledPayments-table-hover position-relative">
                            <tr key={index} className="shadow-none">
                                <td >
                                    <div className="d-flex flex-column justify-content-center" >
                                        <div><b>{trip?.tripNo}</b></div>
                                        <div><b>{formatDate(trip?.tripDate)}</b></div>
                                    </div>
                                </td>
                                <td >
                                    <div className="d-flex justify-content-center align-items-center" >
                                    <AmountField
                                            disabled
                                            innerStyle={{ border: "0px",backgroundColor:"#f8f8f8"  }}
                                            placeholder={"Amount"}
                                            value={trip.clientAmount}
                                            onChange={(v) => {
                                                updateItem({
                                                    value: parseInt(v),
                                                    index,
                                                    key: "clientAmount"
                                                })
                                            }}
                                            style={{maxWidth:"120px"}}
                                            className={"mt-0 w-50"}
                                            type="text"
                                        />
                                        {
                                         ( !trip?.amountSettled ||  trip?.amountSettled === 0) &&
                                            <span className="d-flex justify-content-between align-items-center mb-1">
                                                (
                                                <span><b>Amount Due :</b></span>
                                                <span>{(trip?.amountDue / 100).toFixed(2)}</span>
                                                )
                                            </span>
                                        }
                                    </div>
                                </td>
                                <td >
                                    <div className="d-flex justify-content-center" >
                                        <AmountField
                                            innerStyle={{ border: "0px",}}
                                            placeholder={"Amount"}
                                            value={trip.amountSettled}
                                            onChange={(v) => {
                                                updateItem({
                                                    value:parseInt(v),
                                                    index,
                                                    key: "amountSettled"
                                                })
                                            }}
                                            style={{borderRadius: "6px 6px 6px 6px", border:"1px solid #ced4da", maxWidth:"120px",}}
                                            className={"mt-0 w-50"}
                                            type="text"
                                        />
                                    </div>
                                </td>
                                {/* <td >
                                    <div className="d-flex justify-content-center" >
                                    <AmountField
                                            innerStyle={{ border: "0px", }}
                                            placeholder={"Amount"}
                                            value={trip.tdsSettled}
                                            onChange={(v) => {
                                                updateItem({
                                                    value:parseInt(v),
                                                    index,
                                                    key: "tdsSettled"
                                                })
                                            }}
                                            style={{borderRadius: "6px 6px 6px 6px", border:"1px solid #ced4da", maxWidth:"120px",}}
                                            className={"mt-0 w-50"}
                                            type="text"
                                        />
                                    </div>
                                </td> */}
                                <td >
                                    <div className="d-flex justify-content-center" >
                                    <AmountField
                                            disabled
                                            innerStyle={{ border: "0px",backgroundColor:"#f8f8f8"  }}
                                            placeholder={"Write off"}
                                            value={trip.writeOff}
                                            onChange={(v) => {
                                                updateItem({
                                                    value:parseInt(v),
                                                    index,
                                                    key: "writeOff"
                                                })
                                            }}
                                            style={{ maxWidth:"120px"}}
                                            className={"mt-0 w-50"}
                                            type="text"
                                        />
                                    </div>
                                </td>
                                <td style={{ right: 8 }}>
                                    <div className="d-flex justify-content-center" >
                                        <RemoveLink className="ms-1" onClick={() => deleteItems(index)} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
                <div>
                        <div className=" row my-1 col-12 d-flex justify-content-end align-items-center">
                           <h5 style={{width:"fit-content"}}>{t("Total Amount Received")}: {(totalAmount / 100).toFixed(2)}</h5> 
                        </div>
                </div>
            </Card>
        </div>
    );
};

export default observer(TripSettlements);

