export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl, supplier_id = null) => {
    let url = `/api/bill/grid?`;
    if (supplier_id) url += `supplier_id=${supplier_id}&`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/bill/${id}/detail`,
  saveNew: `/api/bill/create`,
  save: `/api/bill/create`,
  delete: (id) => `/api/bill/${id}/delete`,
  update: `/api/bill/update`,
};

export const SELECTSTRUCTURE = {
  paymentTerms: [
    { label: "Due on Receipt", value: "Due on Receipt" },
    { label: "Net 7", value: "Net 7" },
    { label: "Net 15", value: "Net 15" },
    { label: "Net 30", value: "Net 30" },
    { label: "Net 60", value: "Net 60" },
    { label: "End of Month", value: "End of Month" },
    { label: "End of Next Month", value: "End of Next Month" },
  ],
  invoiceStatus: [
    { label: "Verified", value: "Verified" },
    { label: "Partially Paid", value: "Partially Paid" },

  ],
  bookingStatus: [
    { label: "Dispatched", value: "Dispatched" },
    { label: "On Journey", value: "On Journey" },
    { label: "Completed", value: "Completed" },
    { label: "Completed Offline", value: "Completed Offline" },
    { label: "Didn't Run", value: "Didn't Run" },

  ],
  Status: [
    { label: "Open", value: 0 },
    { label: "Partially Paid", value: 1 },
    { label: "Paid", value: 2 },
  ]

};

export const STRUCTURE = [
  {
    label: "Bill Date From",
    description: "Select Date",
    filterLabel: "Bill Date From",
    filterName: "startDate",
    type: "dateSelect",
  },
  {
    label: "Bill Date",
    description: "Select Date",
    filterLabel: "Bill Date",
    filterName: "billDate",
    type: "dateSelect",
  },
  {
    label: "Due Date ",
    description: "Select Date",
    filterLabel: "Due Date ",
    filterName: "dueDate",
    type: "dateSelect",
  },

  {
    label: "Account",
    description: "description....",
    filterLabel: "Account",
    filterName: "items.accountCode",
    type: "select",
    data: [
      { label: "Article Purchase", value: "Article Purchase" },
      { label: "Credit Note", value: "Credit Note" },
      { label: "Driver Cash Advance", value: "Driver Cash Advance" },
      { label: "Driver Expense", value: "Driver Expense" },
      { label: "Mobile Office", value: "Mobile Office" },
      { label: "Office Expense", value: "Office Expense" },
      { label: "Rent", value: "Rent" },
      { label: "Salary", value: "Salary" },
      { label: "Salary Advance", value: "Salary Advancee" },
      { label: "Supplier Advance", value: "Supplier Advance" },
      { label: "Suplier Payment", value: "Suplier Payment" },
      { label: "Vehicle Expense", value: "Vehicle Expense" },

    ],
  },
  {
    label: "Bill No",
    description: "description...",
    filterLabel: "Bill No",
    filterName: "billNo",
    type: "text"

  },
  {
    label: "Status",
    filterLabel: "Status",
    filterName: "status",
    type: "select",
    data: SELECTSTRUCTURE?.Status

  },
  {
    label: "Order No",
    description: "description...",
    filterLabel: "Order No",
    filterName: "order",
    type: "text",
  },
];