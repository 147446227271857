import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import ReminderService from './ReminderService'; // Adjust the import path as necessary
import EmployeeAssigner from '../Dropdowns/EmployeeSelect/EmployeeAssigner';
import { generateUniqueId } from '../dnd/board/helper';
import moment from 'moment';
import './style.css'
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

const ReminderGrid = ({ leadId, onRequestDetail = () => { } }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchItineraries = async () => {
            setLoading(true);
            try {
                await ReminderService.fetch(leadId);

            } catch (error) {
                console.error("Failed to fetch itineraries:", error);
                // Handle the error as per your error handling strategy
            } finally {
                setLoading(false);
            }
        };

        if (leadId) {
            fetchItineraries();
        }
    }, [leadId]);

    if (loading) {
        return <div>Loading...</div>; // Consider a more sophisticated loading indicator
    }

    if (ReminderService?.records.length === 0) {
        return <div>No Reminders found for this lead.</div>;
    }

    return (
        <div className="">
            {ReminderService?.records?.length ? (
                <>
                    <div className="fixTableHead">
                        <Table className="table-borderless table-centered">
                            {/* <thead>
                                <tr style={{ borderBottom: "1px solid #EBEBEB" }}>
                                    <th>Action</th>
                                    <th>Due Date</th>
                                    <th>Completed</th>
                                    <th>Assignees</th>
                                    <th className="text-muted fw-semibold text-end"></th>

                                </tr>
                                <tr className='thead-2'>
                                    <th>Action</th>
                                    <th>Due Date</th>
                                    <th>Completed</th>
                                    <th>Assignees</th>
                                    <th className="text-muted fw-semibold text-end"></th>

                                </tr>
                            </thead> */}
                            <tbody>
                                {ReminderService?.records.map((reminder, index) => (
                                    <tr key={index}>
                                        <td style={{
                                            textAlign: "center"
                                        }}>
                                                <div
                                                    style={{
                                                        width: '10px',
                                                        height: '10px',
                                                        borderRadius: '50%',
                                                        backgroundColor: reminder?.completed ? "green" : "orange",
                                                        marginRight: '5px',
                                                    }}
                                                ></div>
                                        </td>
                                        <td>
                                            <p className="mb-1 fw-normal">{reminder?.action}</p>
                                        </td>
                                        <td>
                                            <b className="mt-1 ms-2">
                                                {reminder?.actionDate ? moment(reminder?.actionDate, 'YYYYMMDD').format("DD-MM-YYYY") : ""}
                                            </b>
                                        </td>

                                        <td className="text-muted fw-semibold h4">
                                            <EmployeeAssigner
                                                tooltipId={generateUniqueId()}
                                                value={reminder?.assignedTo}
                                                asLabel
                                            />
                                        </td>
                                        <td className="text-muted text-end">
                                            <div onClick={() => onRequestDetail(reminder?._id)}>
                                                <i className="uil uil-pen font-size-18" />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </>
            ) : (
                <>
                    <div>No Reminder found for this Lead.</div>
                </>
            )}
        </div>

    );
};

export default observer(ReminderGrid);
