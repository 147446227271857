export const ENDPOINTS = {
  getEmailCredentials: `/api/corporate-config/detail/smtpCredentials`,
  editEmailCredentials: `/api/corporate-config/update/smtpCredentials`,
};

export const SELECTSTRUCTURE = {
  smtpType: [
    { label: "TLS", value: "TLS" },
    { label: "SSL", value: "SSL" },
  ],
}
