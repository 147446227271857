import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import Editqc from "./Editqc";
import { STRUCTURE } from "./qcConstant";
import { GridColumns } from "./qcGridColumn";
import QcService from "./qcService";

const Qc = ({ insidePane }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(
    insidePane ? false : window.location.pathname === "/qc/create" || edit_id
  );
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await QcService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(
      queryParams,
      STRUCTURE
    );
    setFilterURL(filterUrl);
    setFilterObject(filterObject);
    fetchData(filterUrl);
  }, []);

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])


  const onSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (editID) {
        await QcService.edit(detailData, editID);
        showMessage(t("Quality Check updated successfully."));
      } else {
        const QcID = await QcService.save(detailData);
        showMessage(t("Quality Check saved successfully."));
        if (!insidePane) navigate(`/qc/edit/${QcID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete quality check?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await QcService.delete(id);
        showMessage("Deleted Successfully", "Deleted");
      } catch (e) {
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback((qc) => {
    return (
      <>
        {userContent?.rights?.includes(5302) &&
          <EditLink
            onClick={() => {
              if (!insidePane) navigate(`/qc/edit/${qc?._id}`);
              setEditID(qc?._id);
              setShowDetailPage(true);
            }}
          />
        }
        {userContent?.rights?.includes(5303) &&
          <DeleteLink onClick={(event) => onDelete(event, qc?._id)} />
        }
      </>
    );
  }, [insidePane, userContent])

  return (
    <>
      <Layout
        medium
        hideAdd={!userContent?.rights?.includes(5301)}
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/qc");
          setEditID(null);
        }}
         
        title={t("Quality Check")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/qc/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={QcService.page}
        rows={QcService.rows}
        total={QcService.total}
        fetch={QcService.fetch}
      >
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={QcService.records}
              total={QcService.total}
              uiPreference="qc.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={setSelectedIDs}
              page={QcService.page}
              rowsPerPage={QcService.rowsPerPage}
              onPaginationChange={QcService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </>
        </Layout.Table>

        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit Quality Check") : t("Add Quality Check")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <Editqc
            editId={editID}
            onChange={(v) => {
              setDetailData(v);
            }}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {/* {userContent?.rights?.includes(4103) && detailData?._id ? ( */}

          {editID && userContent?.rights?.includes(5303) && <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} />}
          {userContent?.rights?.includes(5302) && <SaveButton onClick={onSave} />}
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(Qc);
