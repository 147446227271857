export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl, corpId) => {

    let url = `/api/transfer/group/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + `&page=${page}`;

  },
  get: (id) => `/api/transfer/group/${id}/detail`,
  saveNew: `/api/transfer/group/save`,
  edit: (id) => `/api/transfer/group/${id}/edit`,
  delete: (id) => `/api/transfer/group/${id}/delete`,
  getAddress: (key) => `/api/o/maputil/autocomplete/keyvalue?key=${key}`
};

export const STRUCTURE = [
  {
    label: "Name",
    description: "description...",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Country",
    description: "description...",
    filterLabel: "Country",
    filterName: "country",
    type: "countrySelect",
    dependencyOnMe: ["city"],
  },

  {
    label: "City",
    description: "description...",
    filterLabel: "City",
    filterName: "city",
    type: "citySelect",
    dependency: {
      country: "sourceCountry",

    },
  }
];