import RateNameSelect from "../../components/Dropdowns/RateNameSelect/RateNameSelect";
import RateAreaSelect from "../../components/Dropdowns/RateAreaSelect/RateAreaSelect";
import { SupplierSelect, VehicleTypeSelect, MakeModelSelect } from "../../components";
export const SupplierGridColumns = {
    version: 1,
    columns: [
        {
            title: "Rate Number",
            accessor: "rateNo",
            show: true,
        },
        {
            title: "Rate Name",
            show: true,
            renderer: (dataObj) => {
                return <div><RateNameSelect
                    label={"Rate Name"}
                    value={dataObj?.rateName_id}
                    className="col-sm-3"
                    asLabel
                />
                </div>
            },
        },
        {
            title: "Rate Area",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    <RateAreaSelect
                        label={"Rate Area"}
                        value={dataObj?.rateAreas}
                        className="col-sm-3"
                        multi
                        asLabel
                    />
                </div>
            },
        },
        {
            title: "Supplier",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    <SupplierSelect
                        label={"Supplier"}
                        value={dataObj?.supplier_id}
                        className="col-sm-3"
                        plain
                        asLabel
                    />
                </div>
            },
        },
        {
            title: "Vehicle Type",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    <VehicleTypeSelect
                        label={"Vehicle Type"}
                        value={dataObj?.vehicleType}
                        className="col-sm-3"
                        plain
                        asLabel
                    />
                </div>
            },
        },
        {
            title: "Make Model",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    <MakeModelSelect
                        label={"Make Model"}
                        value={dataObj?.makeModel}
                        className="col-sm-3"
                        plain
                        asLabel
                    />
                </div>
            },
        },
        {
            title: "Applies On",
            renderer: (dataObj) => {
                return (
                    <div>
                        {dataObj.appliesOn === "PER_PAX" ? < i className="bx bx-male font-size-20" style={{ marginTop: "3px", marginLeft: "2px" }}></i > : < i className="bx bx-car font-size-20" style={{ marginTop: "3px", marginLeft: "2px" }} ></i>}
                    </div>
                );
            },
            show: true,
        },
        {
            title: "Status",
            renderer: (dataObj) => {
                return (
                    < div >
                        {dataObj.status === 0 ? "Dissapproved" : dataObj.status === 1 ? "Approved" : null}
                    </div >
                );
            },
            show: true,
        },
        {
            title: "Validity",
            width: 220,
            renderer: (dataObj) => {
                return (
                    <div>From&nbsp;   <strong><span>{
                        dataObj.validFrom != undefined ? (
                            (dataObj.validFrom?.toString())?.slice(6, 8) +
                            '-' +
                            (dataObj.validFrom?.toString())?.slice(4, 6) +
                            '-' +
                            (dataObj.validFrom?.toString())?.slice(0, 4)
                        ) : (
                            '--'
                        )}</span></strong> &nbsp;Till&nbsp; <strong><span>{
                            dataObj.validTill != undefined ? (
                                (dataObj.validTill?.toString())?.slice(6, 8) +
                                '-' +
                                (dataObj.validTill?.toString())?.slice(4, 6) +
                                '-' +
                                (dataObj.validTill?.toString())?.slice(0, 4)
                            ) : (
                                '--'
                            )}</span></strong>
                    </div>
                );
            },
            show: true,
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
