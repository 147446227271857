import moment from 'moment';
 import {   momentLocalizer } from 'react-big-calendar';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom'; 
import TimeSelect from '../DateSelects/TimeSelect';
import React from 'react';

const localizer = momentLocalizer(moment);
const CURRENT_DATE = moment().toDate();

export const Toolbar = (props) => {
    const goToBack = () => {
      props.onNavigate("PREV");
    };
  
    const goToNext = () => {
      if (moment().add(2, "months").unix() > new Date(props?.date).getTime() / 1000) {
        props.onNavigate("NEXT");
      }
    };
  
    const date = moment(props.date);
  
    return (
      <div className="rbc-toolbar d-flex justify-content-between align-items-center ">
        <span className="d-flex align-items-center gap-1 position-absolute mb-2">
          <Link className="calendar-btn px-2 py-1 mt-1 rounded-2 border border-1" onClick={goToBack}>
            <FaChevronLeft size={15}/>
          </Link> 
          <div onClick={() => props.onNavigate('TODAY')} className=' px-2 py-1 mt-1 rounded-2 border border-1 cursor-pointer btn btn-outline-primary' style={{fontSize:16}}>Today</div>
          <Link className="calendar-btn px-2 py-1 mt-1 rounded-2 border border-1" onClick={goToNext}>
            <FaChevronRight size={15}/>
          </Link> 
          <TimeSelect
            placeholder="Start Time"
            label="Start Time"
            className=""
            value={props?.time}
            outline
            onChange={(v) => {
              props?.onTimeChange(v);
            }}
          />
        </span>
        <h2 className="text-center mx-auto fw-600 fs-18">
          {date.format("MMMM, YYYY")}
        </h2>
  
      </div>
    );
  };
  
  
  // example implementation of a wrapper
export const CustomDateCellWrapper = ({ children, value }) => {
    const isToday = moment().isSame(value, 'day');
    const isPastDate = moment().isAfter(value, 'day');
    
    return React.cloneElement(children, {
        style: {
            ...children.props.style,
            backgroundColor:isPastDate?'#ddd':'white' ,
            position: 'relative',
            pointerEvents:isPastDate?'none':'auto',
           
        },
        children: (
            <>
                {children.props.children}
                 
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 5,
                             color:'#000',
                            background:isToday?'#DF6463':'transparent',
                            width:30,
                            height:30,
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            borderRadius: '50%',
                            marginTop:5
                        }}
                    >
                        {moment(value).format('D')}
                    </div>
             
            </>
        )
    });
  };

export const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = '#F08180';
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'black',
      border: '0px',
      display: 'block',
      height: '100%',
      minHeight: '100px',
      position: 'relative'
    };
    return {
      style: style
    };
  }

  export const dayPropGetter = (date) => {
      const isPastDate = moment().isAfter(date, 'day');
 
        return {
            style: {
                backgroundColor:isPastDate?'#ddd': '#FFF',
                color: '#d3d3d3',
                pointerEvents: 'none',
                border:'0.5px solid #d3d3d3'
            }
        }; 
};
  export const monthHeader = ({ date, localizer }) => (
    <div className="d-flex direction-columan  fw-500 fs-16">
      <h4 className='fw-bold fs-5 text-center'>{localizer.format(date, "ddd")}</h4>
    </div>
  )
