import { makeAutoObservable } from "mobx";
import { ENDPOINTS, defaultPaymentTerms } from "./TermConstant";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { getUiPrefRows } from "../../util/FilterUtil";
class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = null;
  filterOption = "";
  total = -1;
  version = 0;
  cacheTerm = [];
  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterUrl) => {
    if (!this?.rowsPerPage) {
      const rows = await getUiPrefRows("term.grid");
      this.rowsPerPage = rows ?? 40;
    }

    if (filterUrl) {
      this.filterOption = filterUrl;
    }
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl)
    );

    if (response?.status === 200) {
      // const data = response?.data?.rows;
      // const records = [];

      // const hasPaymentEventMinusOne = data?.paymentTerms?.some(item => item.paymentEvent === -1);
      // const hasPaymentTermsZero = data?.paymentTerms?.some(item => item.paymentTerms === 0);
      // const hasPaymentTermsNinetyNine = data?.paymentTerms?.somee(item => item.paymentTerms === 99);

      // records.push(
      //   {
      //     label: "At time of Booking",
      //     isAbs: hasPaymentEventMinusOne?.baseAmount ?? false,
      //     paymentEvent: hasPaymentEventMinusOne?.paymentEvent ?? -1,
      //     value: hasPaymentEventMinusOne?.baseAmount || hasPaymentEventMinusOne?.percent || 0
      //   }
      // )

      // let filterData = data?.filter(item => ![-1, 0, 99]?.includes(item.paymentEventType))?.map((item, fIndex) => {
      //   return {
      //     label: "Days before Journey",
      //     isAbs: item?.baseAmount ?? false,
      //     paymentEvent: item?.paymentEvent ?? fIndex + 1,
      //     value: item?.baseAmount || hasPaymentEventMinusOne?.percent || 0
      //   }
      // })

      // records.push([...filterData])

      // records.push(
      //   {
      //     label: "Before Journey",
      //     isAbs: hasPaymentTermsZero?.baseAmount ?? false,
      //     paymentEvent: hasPaymentTermsZero?.paymentEvent ?? 0,
      //     value: hasPaymentTermsZero?.baseAmount || hasPaymentTermsZero?.percent || 0
      //   }
      // )

      // records.push(
      //   {
      //     label: "Post Journey",
      //     isAbs: hasPaymentTermsNinetyNine?.baseAmount ?? false,
      //     paymentEvent: hasPaymentTermsNinetyNine?.paymentEvent ?? 99,
      //     value: hasPaymentTermsNinetyNine?.baseAmount || hasPaymentTermsNinetyNine?.percent || 0
      //   }
      // )

      // this.records = { ...data, paymentTerms: records }
      this.records = response?.data?.rows
      this.page = response?.data?.page;

      if (this.page === 1) this.total = response?.data?.total;
    }
  };

  fetchAll = async function () {
    if (this.cacheTerm.length > 0) {
      return this.cacheTerm;
    }
    try {
      const response = await doGET(ENDPOINTS.getAllTerms);
      if (response.status === 200) {
        const formattedTerm = response.data.rows.map((v) => {
          return { label: v.name, value: v._id };
        });
        this.cacheTerm = formattedTerm;
        return formattedTerm;
        console.log(response);
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };

  edit = async function (data, id) {
    await doPUT(ENDPOINTS?.update, data);
    this.version += 1;
    this.cacheTerm = [];
    this.fetch();
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {

      const data = response?.data
      const records = [];

      const hasPaymentEventMinusOne = data?.paymentTerms?.filter(item => item.paymentEvent === -1)?.[0];
      const hasPaymentTermsZero = data?.paymentTerms?.filter(item => item.paymentEvent === 0)?.[0];
      const hasPaymentTermsNinetyNine = data?.paymentTerms?.filter(item => item.paymentEvent === 99)?.[0];

      records.push(
        {
          label: "At time of Booking",
          isAbs: hasPaymentEventMinusOne?.baseAmount ? false : true,
          paymentEvent: hasPaymentEventMinusOne?.paymentEvent ?? -1,
          value: hasPaymentEventMinusOne?.baseAmount || hasPaymentEventMinusOne?.percent || 0
        }
      )

      let filterData = data?.paymentTerms?.filter(item => ![-1, 0, 99]?.includes(item.paymentEvent))?.map((item, fIndex) => {
        return {
          label: "Days before Journey",
          isAbs: item?.baseAmount ? false : true,
          paymentEvent: item?.paymentEvent ?? fIndex + 1,
          value: item?.baseAmount || item?.percent || 0
        }
      })


      if (filterData?.length > 0) {
        records.push(...filterData)
      }

      records.push(
        {
          label: "Before Journey",
          isAbs: hasPaymentTermsZero?.baseAmount ? false : true,
          paymentEvent: hasPaymentTermsZero?.paymentEvent ?? 0,
          value: hasPaymentTermsZero?.baseAmount || hasPaymentTermsZero?.percent || 0
        }
      )

      records.push(
        {
          label: "Post Journey",
          isAbs: hasPaymentTermsNinetyNine?.baseAmount ? false : true,
          paymentEvent: hasPaymentTermsNinetyNine?.paymentEvent ?? 99,
          value: hasPaymentTermsNinetyNine?.baseAmount || hasPaymentTermsNinetyNine?.percent || 0
        }
      )

      let cancellationSlabsRecords = []

      const hasCancelZero = data?.cancellationSlabs?.filter(item => item.duration === 0)?.[0];


      cancellationSlabsRecords.push(
        {
          label: "Any Time",
          isAbs: hasCancelZero?.baseAmount ? false : true,
          duration: hasCancelZero?.duration ?? 0,
          value: hasCancelZero?.baseAmount || hasPaymentEventMinusOne?.percent || 0
        }
      )

      let cancelFilterData = data?.cancellationSlabs?.filter(item => ![0]?.includes(item.duration))?.map((item, fIndex) => {
        return {
          label: "Days before Journey",
          isAbs: item?.baseAmount ? false : true,
          duration: item?.duration ?? fIndex + 1,
          value: item?.baseAmount || item?.percent || 0
        }
      })


      if (cancelFilterData?.length > 0) {
        cancellationSlabsRecords.push(...cancelFilterData)
      }

      // this.records = { ...data, paymentTerms: records }
      return { ...data, paymentTerms: records, cancellationSlabs: cancellationSlabsRecords };
    }
  };

  save = async (data) => {
    // this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    this.cacheTerm = [];
    this.fetch();
    return response?.data?._id;
  };

  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.cacheTerm = [];
      this.fetch();
    }
  };

  bulkDelete = async (ids = []) => {
    const response = await doDELETE(
      ENDPOINTS.bulkDelete(),
      ids?.filter((id) => id != null)
    );
    if (response.status === 200) {
      this.fetch();
    }
  };

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);
  };
}

const TermService = new Service();
export default TermService;
