import React, { useContext, useEffect, useState, useMemo } from 'react';
import { DialogContext } from '../../../store/context/DialogContext';
import { SelectField } from '../../SelectField/SelectField';
import { I18nContext } from '../../../store/context/i18nContext';
import { doGET } from '../../../util/HttpUtil';

const CategorySelect = ({
  module,
  value,
  onChange,
  className,
  style,
  inline,
  showSubcategory,
  outline
}) => {

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const { showError } = useContext(DialogContext);
  const { t } = useContext(I18nContext);
  const subCategoryMap = useMemo(() => new Map(), []);

  const transformData = (response) => {
    const map = new Map();
    const result = [];
    for (const item of response) {
      if (item.parent_id) {
        const children = map.get(item.parent_id) || [];
        children.push({ ...item, value: item?._id, label: item?.name });
        map.set(item?.parent_id, children);
      }
    }
    for (const item of response) {
      if (!item.parent_id) {
        result.push({
          ...item,
          label: item?.name,
          value: item?._id,
          subCategories: map.get(item._id) || [],
        });
        subCategoryMap.set(item?._id, map.get(item._id) || []);
      }
    }
    return result;
  };

  const fetchCategories = async () => {
    try {
      const response = await doGET(`/api/doc-category/grid?module=${module}&rows=-1`);
      if (response.status === 200) {
        const categories = transformData(response?.data?.rows);
        setCategories(categories)
      }
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    if (module) {
      fetchCategories();
    }
  }, [module]);

  useEffect(() => {
    const selectedCategory = categories.find(category => category?.value === value?.category_id);
    setSelectedCategory(selectedCategory || {});
  }, [categories, value?.category_id]);

  return (
    <div className={`${className} d-flex  row mx-0 px-0 col-6`} style={{ ...(style ?? {}) }}>
      <div className= {showSubcategory?"col-sm-6 mx-0 px-0" :"col-sm-12 mx-0 px-0" }>
        <SelectField
          rightBorderNone
          outline={outline}
          inline={inline}
          isDisabled={module=="expense-"}
          placeholder={"Category"}
          data={categories}
          value={value?.category_id}
          required
          label={t("Category")}
          onChange={(v) => {
            onChange({ ...(value ?? {}), category_id: v?.value, subCategory_id: null });
          }}
          type="text"
          innerContainerStyle={{
            border : "0px",
            borderBottom : "1px solid #ced4da", 
            borderRadius: `${(selectedCategory?.subCategories?.length > 0) ? "8px 0 0 0px" : ""}`
          }}
          // className="my-3 me-0"
        />
      </div>

{/* If Select dropdown needed for Sub category */}

      {showSubcategory&&
        <div className={showSubcategory?"col-sm-5 mx-4 px-0" :"col-sm-6 mx-0 px-0" }>
          <SelectField
            leftBorderNone
            inline={inline}
            outline={outline}
            // isDisabled={!(selectedCategory?.subCategories?.length > 0)}
            placeholder={t("Select Sub Category")}
            data={categories.subCategories}
            value={value?.value}
            // inline
            label={t("Sub Category")}
            onChange={(v) => {
              onChange({ ...value, subCategory_id: v.value });
            }}
            type="text"
            
            innerContainerStyle={{
              border : "0px",
            borderBottom : "1px solid #ced4da", 
              borderRadius: "0 0px 0px 0px"
            }}
          />
        </div>
      }
    </div>
  );
};

export default CategorySelect;
