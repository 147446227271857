import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FaBullseye, FaChevronRight } from 'react-icons/fa';
import LeadMasterService from '../../dnd/board/LeadMasterService';
import "./StatusProgress.css";

const StatusProgress = ({
  onChange = () => { },
  value = 1,
}) => {
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    if (!value && statuses.length) {
      onChange(statuses[0])
    }
  }, [value, statuses])


  useEffect(() => {
    if (Object.keys(LeadMasterService?.statusNameMap)?.length) {
      setStatuses(toJS(Object.keys(LeadMasterService?.statusNameMap))?.map((status) => {
        if (
          status
        ) {
          const temp = toJS(LeadMasterService?.statusNameMap[status])
          return {
            ...temp,
            value: status,
            label: temp.name
          };
        }
        return null;
      }).filter(Boolean))
    }
  }, [LeadMasterService?.statusNameMap])


  const handleStatusClick = (status) => {
    onChange(status);
  };

  const handleKeyDown = (e, statusIndex) => {
    let newIndex = statusIndex;
    if (e.key === 'ArrowRight') {
      newIndex = statusIndex + 1 < statuses.length ? statusIndex + 1 : 0;
    } else if (e.key === 'ArrowLeft') {
      newIndex = statusIndex - 1 >= 0 ? statusIndex - 1 : statuses.length - 1;
    }
    const newStatus = statuses[newIndex];
    if (newStatus) {
      onChange(newStatus.value);
      // Focus the new segment
      const element = document.querySelector(`.zr_status_progress_segment:nth-child(${newIndex + 1})`);
      if (element) element.focus();
    }
  };
  
  
  return (

  <div className="zr_status_progress">
      <div className="zr_status_progress_bar">
        {statuses.map((status, index) => (
          <div 
            key={status.value}
            tabIndex={0} 
            onClick={() => handleStatusClick(status.value)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            className={`zr_status_progress_segment ${value >= status.value ? 'zr_status_progress_active' : ''}`}
          >
            {/* <FaBullseye color={`${status.color}`} className='me-2' /> */}
            {status.label}

            {value > status.value  && 
            <div style={{position : "absolute", right : "-20px", zIndex : 10}}>
              <FaChevronRight size={33} />
            </div>}

            {((value < status.value) && (index != statuses.length - 1) )&&
              <div style={{ position: "absolute", right: "-20px", zIndex: 10 }}>
                <FaChevronRight size={33} color = "#EBEBEB"/>
              </div>}

 
          </div>

        ))}
      </div>
    </div>
  );
};

export default observer(StatusProgress);
