export const ENDPOINTS = {

  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/account/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/account/${id}/detail`,
  saveNew: `/api/account/create`,
  save: `/api/account/create`,
  delete: (id) => `/api/account/${id}/delete`,
  update: `/api/account/update`,
  bulkDelete: (id) => `/api/account/delete`,

};

export const SELECTSTRUCTURE = {
  accountType: [
    { label: "Saving", value: "Saving" },
    { label: "Current", value: "Current" },
    { label: "Cash", value: "Cash" },
    { label: "Credit Card", value: "CreditCard" },
    { label: "Debit Card", value: "DebitCard" },
    { label: "Fuel Card", value: "FuelCard" },
  ],
  status: [
    { label: "Inactive", value: 0 },
    { label: "Active", value: 1 },
    { label: "blocked", value: 2 },
  ],
}

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Account Name",
    filterName: "acDetail.name",
    type: "text",
  },
  {
    label: "Type",
    filterLabel: "Account Type",
    filterName: "accountType",
    data: SELECTSTRUCTURE?.accountType,
    type: "select",
  },
  {
    label: "Status",
    filterLabel: "Account Status",
    filterName: "status",
    data: SELECTSTRUCTURE?.status,
    type: "select",
  },
  {
    label: "Opening Date",
    filterLabel: "Opening Date",
    filterName: "openingDate",
    type: "dateSelect",
  },
  {
    label: "Account No.",
    filterLabel: "Account Number",
    filterName: "acDetail.accountNo",
    type: "text",
  },
];
