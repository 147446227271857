import { ThreeBounce } from 'better-react-spinkit';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { objectIdToColor } from '../../components/dnd/board/helper';
import { doGET } from '../../util/HttpUtil';
import './style.css'; // Import the CSS file for styles

const SwitchProfile = () => {

    const [profiles, setProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchProfileList = async () => {
        setIsLoading(true);
        try {
            const response = await doGET("/api/profile/list");
            if (response.data?.length == 1) {
                const profile = response.data[0];
                const authorizationToken = localStorage.getItem("BearerToken");
                const redirectUrl = `https://${profile.domain}/login?Authorization=${authorizationToken}`;
                window.location.replace(redirectUrl);
            }
            if (response.status === 200 && response.data?.length) {
                setProfiles(response.data);
            }



        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchProfileList();
    }, []);



    const handleContinue = (profile) => {
        if (!profile) return;

        const authorizationToken = localStorage.getItem("BearerToken");
        const redirectUrl = `https://${profile.domain}/login?Authorization=${authorizationToken}`;

        window.location.replace(redirectUrl);
    };

    if (isLoading) return <div className="zr_switch-profile-loading d-flex align-items-center h-100 w-100 justify-content-center"><ThreeBounce color="pink" size={50} /></div>;

    return (
        <div className="zr_switch-profile-container">
            <div className="zr_switch-profile-heading">Select Account</div>


            {profiles.map((profile, key) => (
                <div
                    key={key}
                    onClick={() => handleContinue(profile)}
                    className={`zr_switch-profile-item `}
                >

                    <div className="zr_switch-profile-logo">
                        {profile.logo ? (
                            <img src={profile.logo} alt="logo" />
                        ) : (
                            <span style={{ backgroundColor: objectIdToColor(profile?.corp_id) }} className="zr_switch-profile-placeholder">{profile.name.split(' ').map(n => n[0]).join('')}</span>
                        )}
                    </div>
                    <div className="zr_switch-profile-name">{profile.name}</div>
                </div>
            ))}

            <div onClick={() => {
                localStorage.clear();
                window.location = "/sign-up";
            }} className="zr_switch-profile-create-new">
                Create a new account
            </div>

        </div>
    );
};

export default observer(SwitchProfile);
