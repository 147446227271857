import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useContext } from "react";
import { Button, Table } from "reactstrap";
import { SelectField } from "../../SelectField/SelectField";
import { I18nContext } from "../../../store/context/i18nContext";

import NameMasterService from "./NameMasterService";
import NameMaster from "../NameMasters/NameMaster";
import { BottomBarButton, Modal, ModalComponent, SearchButton } from "../../index";


const NameMasterSelect = ({
  style,
  isDisabled,
  className,
  label,
  placeholder,
  onChange,
  value,
  showErr,
  inline,
  borderNone,
  kind,
  multi
}) => {
  const [nameMaster, setNameMaster] = useState([]);
  const [modal, setModal] = useState(false);
  const [newData, setNewdata] = useState(false);
  const { t } = useContext(I18nContext)


  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        await NameMasterService.fetch(kind);
        setNameMaster(
          toJS(NameMasterService.nameMaster)?.map((master) => {
            return {
              ...master,
              value: master?.name,
              label: master?.name,
            };
          })
        );
      } catch (error) {
        console.log(error.message);
      }
    };
    fetch();
  }, [newData]);
  const renderFunc = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps}>
        <Table style={{ margin: "0px" }} className="dropdown-hover">
          <thead style={{ margin: "0px" }}>
            <tr style={{ margin: "0px" }}>
              <td className="col-6 py-3">{`${data.name}`}</td>
            </tr>
          </thead>
        </Table>
      </div>
    );
  };

  const selectName = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0] : selectedValue });
    closeModal();
  };

  const selectNames = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  return (
    <React.Fragment>
      {modal && (
        <ModalComponent
          position={"top"}
          size={"large"}
          onToggle={closeModal}
          isOpen={modal}
        >
          <ModalComponent.Title>
            {t("Name Master")}
          </ModalComponent.Title>

          <ModalComponent.Body>
            <NameMaster
              insidePane={true}
              kind={kind}
              setNewdata={setNewdata}
              multiMode={multi}
              onSelect={multi ? selectNames : selectName}
            />
          </ModalComponent.Body>

        </ModalComponent>
      )}

      <SelectField
        render={renderFunc}
        changeOptionsData={(e) => {
          e.preventDefault();
          setModal(true);
        }}
        bottomBarChildren={() => (
          <BottomBarButton onClick={openModal} title={t("Manage ")} />

        )}
        showBottomBar={true}
        onInputChange={(v) => {
          if (v.length > 1) fetch(v);
        }}
        className={className}
        onChange={onChange}
        data={nameMaster}
        value={value}
        label={label}
        placeholder={placeholder}
        error={value ? "Please Select NameMaster" : ""}
        showErr={showErr}
        inline={inline}
        borderNone={borderNone}
        RightComponent={() => (
          <div>
            {" "}
            <i
              role="button"
              className="mdi mdi-magnify text-dark font-size-24 mx-2"
            />
          </div>
        )}
        onClickRight={(e) => {
          setModal(true);
        }}
      />
    </React.Fragment>
  );
};

export default observer(NameMasterSelect);
