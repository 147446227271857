 

import React from 'react'
import RadioSelection from '../../../components/RadioSelection'

const data=[
    {name:'Regular Pickup',value:2},
    {name:'Regular Drop',value:3},
    {name:'Adhoc Pickup',value:4},
    {name:'Adhoc Drop',value:5},
]

function RosterTypeSelection(props) {
    return (
        <>
        <RadioSelection {...props} data={data} label={"Select Roster Type"} />
        </>
    )
}

export default RosterTypeSelection