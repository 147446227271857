import React from 'react';
import { Col } from 'reactstrap';
import './AttachmentItem.scss';
import { Image } from '../../../components';
import moment from 'moment';
import PDFLabel from '../../../components/Image/PDFLabel';

const getFilenameFromPath = (path) => {
    const parts = path.split('/');
    return parts[parts.length - 1];
};

const truncateFilename = (filename) => {
    if (filename.length > 20) {
        const start = filename.slice(0, 5);
        const end = filename.slice(-7);
        return `${start}.....${end}`;
    }
    return filename;
};

const AttachmentItem = ({ file, index }) => {
    return (
        <div className="zr_communication_email_attachment_item">
            <Col className="col-auto">
                <div className="attachment-index">{index + 1}.</div>
            </Col>

            <Col className="col-auto">
                {file.key.endsWith('.pdf') ?
                    <PDFLabel src={file?.key ?? file} /> :
                    <Image style={{ width: "60px", height: "60px" }} width="60px" height="60px" src={file.key} />
                }
            </Col>
            <Col className="ms-2 col-auto">
                <h3 className="attachment-filename">
                    {truncateFilename(getFilenameFromPath(file.key))}
                </h3>
                <p className="attachment-size">{file.value ? moment(file.value * 1000).format('YYYY-MM-DD hh:mm A') : 'Unknown Size'}</p>
            </Col>
        </div>
    );
};

export default AttachmentItem;
