import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { AddressField, InputField } from "../../../../components";
import DayNightPicker from "../../../../components/dnd/forms/itinerary/components/DayNightPicker";
import { I18nContext } from "../../../../store/context/i18nContext";
import Guides from "../../components/Guides";
import Segments from "../../components/Segments";
import Stays from "../../components/Stays";
import "./style.css";
import Counter from "../../../../components/dnd/forms/itinerary/components/DayNightPicker/Counter";
import TokenInput from "../../../../components/InputField/TokenInput";
import ItineraryPackage from "../../components/ItineraryPackage";

const ItineraryDetail = ({
    data,
    onChange = () => { },
}) => {

    const { t } = useContext(I18nContext);

    return (
        <React.Fragment>
            <div className="row mt-3 ">
                <div className="col-6 ">
                    <div className="">
                        <InputField
                            label={t("Title")}
                            type="text"
                            value={data?.title}
                            onChange={(v) => {
                                onChange({ ...data, title: v })
                            }}
                            placeholder={t("Title")}
                            outline
                            className="flex-1"
                        />

                        <AddressField
                            value={data?.location}
                            outline
                            onChange={(v) => {
                                onChange({ ...data, location: v })
                            }}
                        />
                    </div>
                </div>
                <div className="col-6 d-flex mt-2">
                    <DayNightPicker
                        value={{ days: data?.days ?? 2, nights: data?.nights ?? 1 }}
                        onChange={(v) => onChange({ ...data, days: v?.days, nights: v?.nights })}
                    />
                    <div className="mx-2">
                        <Counter
                            value={data?.persons}
                            label="Persons"
                            onChange={(v) => onChange({ ...data, persons: v })}
                        />
                    </div>
                    <div className="flex-1 mb-3 ">
                        <TokenInput
                            label={t("Highlights")}
                            type="text"
                            value={data?.highlights ?? []} // [] array of strings
                            onChange={(v) => {
                                onChange({ ...data, highlights: v })  // v should be [] array of strings
                            }}
                            placeholder={t("Highlights")}
                            outline
                            className="flex-1"
                        />
                    </div>
                </div>
            </div>

            {/* <ItineraryPackage 
                value={data}
                onChange={(v) => {
                    onChange(v)
                }}
            
            /> */}

            <div className="row">
                <div className="col-6">
                    <Segments
                        value={data?.itineraryExtn?.segments ?? []}
                        onChange={(v) => {
                            onChange({
                                ...data,
                                itineraryExtn: {
                                    ...data?.itineraryExtn,
                                    segments: v
                                }
                            })
                        }}
                    />
                </div>
                <div className="col-3">
                    <Stays
                        value={data?.itineraryExtn?.stays ?? []}
                        onChange={(v) => {
                            onChange({
                                ...data,
                                itineraryExtn: {
                                    ...data?.itineraryExtn,
                                    stays: v
                                }
                            })
                        }}
                    />
                </div>
                <div className="col-3">
                    <Guides
                        value={data?.itineraryExtn?.guides ?? []}
                        onChange={(v) => {
                            onChange({
                                ...data,
                                itineraryExtn: {
                                    ...data?.itineraryExtn,
                                    guides: v
                                }
                            })
                        }}
                    />
                </div>
            </div>
        </React.Fragment >
    );
};

export default observer(ItineraryDetail);
