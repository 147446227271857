import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FaExternalLinkAlt, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Table, Input } from 'reactstrap';
import { Checkbox, EditLink, ModalComponent } from '../../components';
import { renderDate, renderTime } from '../Flight/FlightGridColumns';
import EditStays from '../Stay/EditStay';
import StayService from '../Stay/StayService';
import GroupTourSidebarService from './sideBar/GroupTourSidebarService';
import "./style.css";

const StayGrid = ({
    getStaysRecords = () => { },
    hideAdd,
    passengers = [],
    selectedStays = [],
    completeObject = false,
    onChange = () => { },
    fixedStays = []
}) => {
    const [loading, setLoading] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null);
    const [modal, setModal] = useState(false);
    const [editId, setEditID] = useState("");
    const [stays, setStays] = useState("");

    const navigate = useNavigate();

    const fetchStays = async (passengers) => {
        setLoading(true);
        try {
            const passengersId = passengers?.map(passenger => passenger.customer_id)?.join(",");
            const staysRecords = await StayService.fetch(`guests.customer_id=in[${passengersId}]${!GroupTourSidebarService.selectedFolder._id ? "" : `&space_id=${GroupTourSidebarService.selectedFolder._id}`}`, true);
            getStaysRecords(staysRecords)
            setStays(staysRecords)
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (passengers?.length) {
            fetchStays(passengers);
        }
    }, [passengers]);

    const toggleRow = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const handleCheckboxClick = (stay) => {
        let updatedStays;
        if ((selectedStays ?? []).some(s => completeObject ? s?._id === stay?._id : s === stay?._id)) {
            updatedStays = (selectedStays ?? []).filter(s => completeObject ? s?._id !== stay?._id : s !== stay?._id);
        } else {
            updatedStays = completeObject ? [...(selectedStays ?? []), stay] : [...(selectedStays ?? []), stay?._id];
        }
        onChange(updatedStays, stay);
    };

    if ((fixedStays?.length ? fixedStays : stays).length === 0) {
        return <div>No Stays found.</div>;
    }

    return (
        <>
            <Card className="pb-3">
                <div style={{ backgroundColor: "#EBEBEB" }} className="h3 ps-3 py-2 d-flex align-items-center justify-content-between">
                    <div> Stays</div>
                    {hideAdd ? null : (
                        <div className="d-flex align-items-center">
                            <Button
                                onClick={() => setModal(true)}
                                className="d-flex align-items-center p-1 me-2">
                                <FaPlus />
                            </Button>
                        </div>
                    )}
                </div>
                {loading ? (
                    <div className='p-3'>
                        loading
                    </div>
                ) : (
                    <div className="px-2">
                        {(fixedStays?.length ? fixedStays : stays)?.length ? (
                            <>
                                <Table className="table-borderless table-centered table-nowrap">
                                    <thead>
                                        <tr style={{ borderBottom: "1px solid #EBEBEB" }}>
                                            <th></th> {/* New Column for Checkbox */}
                                            <th>Hotel Name</th>
                                            <th>Guests</th>
                                            <th>Check-In Date</th>
                                            <th>Check-Out Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(fixedStays?.length ? fixedStays : stays).map((data, index) => (
                                            <React.Fragment key={index}>
                                                <tr className="zr_flight_accordion_row" onClick={() => toggleRow(index)} style={{ cursor: 'pointer' }}>
                                                    <td>
                                                        <Input
                                                            type="checkbox"
                                                            noLabel
                                                            checked={(selectedStays ?? []).some(s => completeObject ? s?._id === data?._id : s === data?._id)}
                                                            value={(selectedStays ?? []).some(s => completeObject ? s?._id === data?._id : s === data?._id)}
                                                            onClick={() => handleCheckboxClick(data)}
                                                            className="col-2 my-2 align-content-end"
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                        {/* <Checkbox
                                                            noLabel
                                                            checked={(selectedStays ?? []).some(s => completeObject ? s?._id === data?._id : s === data?._id)}
                                                            value={(selectedStays ?? []).some(s => completeObject ? s?._id === data?._id : s === data?._id)}
                                                            onClick={() => handleCheckboxClick(data)}
                                                            type="text"
                                                            className="col-2 my-2 align-content-end"
                                                        /> */}
                                                    </td>
                                                    <td>
                                                        <div className="font-size-13 mb-1 fw-normal"> {data.hotelName ?? "-"}</div>
                                                    </td>
                                                    <td>
                                                        <div>{data?.guests?.map((guest, index) => (
                                                            <div key={index}>{guest?.name}</div>
                                                        ))}</div>
                                                    </td>
                                                    <td>
                                                        <div>{renderDate(data?.checkInDate ?? "")} {renderTime(data?.checkInTime ?? "")}</div>
                                                    </td>
                                                    <td>
                                                        <div>{renderDate(data?.checkOutDate ?? "")} {renderTime(data?.checkOutTime ?? "")}</div>
                                                    </td>
                                                    <td>
                                                        {/* <FaExternalLinkAlt
                                                            className="me-2"
                                                            onClick={() => window.open(`/stay/edit/${data?._id}`, '_blank')}
                                                            style={{ cursor: 'pointer' }}
                                                        /> */}
                                                        <EditLink
                                                            onClick={() => {
                                                                console.log(data?._id);
                                                                setEditID(data?._id);
                                                                setModal(true);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        ) : (
                            <div>No Stays found.</div>
                        )}
                    </div>
                )}
            </Card>

            {modal && (
                <ModalComponent
                    position={"top"}
                    size={"large"}
                    onToggle={() => {
                        setEditID("");
                        setModal(false);
                    }}
                    isOpen={modal}
                >
                    <ModalComponent.Body>
                        <EditStays
                            insidePane={true}
                            editId={editId}
                            onSave={() => {
                                setModal(false);
                                fetchStays(passengers);
                            }}
                            onDelete={() => {
                                setModal(false);
                                fetchStays(passengers);
                            }}
                            formConfig={{
                                showPassengers: false
                            }}
                            prePopulatedData={{
                                guests: passengers
                            }}
                        />
                    </ModalComponent.Body>
                </ModalComponent>
            )}
        </>
    );
};

export default observer(StayGrid);
