import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { UserContext } from "../../store/context/UserContext";
import "toastr/build/toastr.min.css";
import {
  Breadcrumb,
  CardThreeBounce,
  CheckButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton,
} from "../../components";
import "../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { STRUCTURE } from "./BranchConstant";
import BranchService from "./BranchService";
import EditTemplates from "./EditBranch";
import { Row, Col } from "reactstrap";

const Branch = (props) => {
  const { t } = useContext(I18nContext);
  let { id } = useParams();
  if (props?.corpId) id = props?.corpId;
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(
    props?.corpId ? false : id
  );
  const [hasErr, setHasErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);
  const [contents, setcontents] = useState([]);
  const { query } = useContext(UserContext);
  const [data, setData] = useState({ entity: "" });
  const [finalTFields, setFinalTFields] = useState([]);
  const [tFields, setTFields] = useState([]);
  const [employeename, setemployeename] = useState([]);
  const [list, setlist] = useState([]);

  let { isClone } = useParams();

  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await BranchService.fetch(filterUrl);
      setlist(response);
      const details = await BranchService.getname(data);
      setemployeename(details);
    } catch (e) {
      showError("error", e);
    }
    setLoading(false);
  };
  const forUpload = () => {
    return query?.get("forUpload") === "true" ? true : false;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setHasErr(true);
    setLoading(true);
    try {
      setData(data);
      if (id) {
        await BranchService.save(data);
        showMessage(" Updated successfully");
      } else {
        const response = await BranchService.save(data);
        BranchService.records.push(data);
        showMessage(" Saved successfully");
        if (!props?.componentView) navigate(`/branch`);
      }
      setShowDetailPage(false);
      fetchData();
    } catch (e) {
      showMessage("error", JSON.stringify(e.customMessage), "error occured");
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    setShowDetailPage(
      window.location.pathname === "/branch/edit" || (id && !props?.corpId)
    );
  }, [id]);

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete Branch ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        await BranchService.delete(id);
        showMessage(" Updated successfully");
      } catch (e) {
        showError("error", e);
      }
    }
  };

  const onRowClick = (id) => {
    if (!props?.componentView) navigate(`/branch/edit/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };
  const handleRowClick = async (templatesId) => {
    if (showDetailPage && templatesId !== selected_id && isEditDataChanged) {
      let isProceed = await showConfirm({
        isSaveWarn: true,
        title: t(`Do you want to save the changes ?`),
        description: t("Your changes will be lost if you don't save them"),
      });
      if (isProceed) onRowClick(templatesId);
    } else onRowClick(templatesId);
  };

  const getName = (data = []) => {
    let temp = [];
    temp = data?.map((e) => {
      let name = employeename?.find((item) => item?._id === e?.empId);
      return name?.name;
    });
    return temp;
  };
  console.log(list, "employessss");

  if (loading) return <CardThreeBounce />;
  return (
    <Layout
      showDetailPage={showDetailPage}
       
      title={t("Branch")}
      filterStructure={STRUCTURE}
      onApplyFilter={fetchData}
      backDetailPage={() => {
        setFinalTFields([]);
        setTFields([]);
        setData([]);
        setShowDetailPage(false);
        if (!props?.componentView) navigate("/branch");
        setSelected_id(false);
      }}
      onAddClick={() => {
        if (!props?.insidePane) navigate(`/branch/edit`);
        setShowDetailPage(true);
        setSelected_id(null);
      }}
      page={BranchService.page}
      rows={BranchService.rows}
      total={BranchService.totalRecords}
      onPageChange={async (page, rows) => {
        await BranchService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("Name")}</Th>
            <Th>{t("Address")}</Th>
            <Th>{t("Employees")}</Th>
            <Th>{t("Company")}</Th>
            <Th className="float-end mr-5">{t("Action")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {list?.map((branch, id) => (
            <Tr key={branch?._id}>
              <Td>{branch?.name}</Td>
              <Td>
                {branch?.address?.state},{branch?.address?.country}
              </Td>
              <Td>{getName(branch?.staff)?.map((e) => e + ",")}</Td>
              <Td>{branch?.entity}</Td>
              <Td className="float-end">
                <EditLink
                  onClick={() => {
                    if (!props?.componentView)
                      navigate(`/branch/edit/${branch?._id}`);
                    setSelected_id(branch?._id);
                    setShowDetailPage(true);
                  }}
                />
                <DeleteLink
                  onClick={(event) => {
                    onDelete(event, branch?._id);
                  }}
                />
                {props?.selectMode ? (
                  <CheckButton
                    onClick={() => {
                      props?.onSelect([{ ...branch }]);
                    }}
                  />
                ) : null}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>

      <Layout.DetailPageTitle>
        <Row>
          <Row className="mb-2 py-2 border-bottom-1">
            <Col>
              <Breadcrumb
                parent={props?.parent}
                title={data?._id ? "Edit Branch" : " Create Branch"}
              />
            </Col>
          </Row>
        </Row>
      </Layout.DetailPageTitle>

      <Layout.DetailPageBody>
        <EditTemplates
          componentView={props?.componentView}
          onChange={(v) => setIsEditDataChanged(v)}
          id={selected_id}
          back={() => {
            setShowDetailPage(false);
            navigate("/templates");
            setSelected_id(false);
          }}
          hasErr={hasErr}
          setHasErr={setHasErr}
          contents={contents}
          setcontents={setcontents}
          data={data}
          setData={setData}
          isClone={isClone}
          forUpload={forUpload}
          finalTFields={finalTFields}
          setFinalTFields={setFinalTFields}
          tFields={tFields}
          setTFields={setTFields}
        />
      </Layout.DetailPageBody>
      <Layout.DetailPageFooter>
        <Row>
          <Col>
            <div
              className="d-flex justify-content-end mt-1"
              style={{ marginTop: "-2.5rem" }}
            >
              <SaveButton onClick={submitHandler} />
            </div>
          </Col>
        </Row>
      </Layout.DetailPageFooter>
    </Layout>
  );
};

export default observer(Branch);
