import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { Container } from "reactstrap";
import React, { useEffect, useState } from "react";
import { doGET } from "../../util/HttpUtil";
import './payment.scss'; // Make sure to create this CSS file
import { CardThreeBounce } from "../../components";


const PaymentStatus = ({ }) => {
  const [isSuccess, setIsSuccess] = useState(null);
  const [searchParams] = useSearchParams();
  const paymentTransactionId = searchParams.get('paymentTransactionId');

  const fetchStatus = async () => {
    try {
      const response = await doGET(`/api/o/payment/txn/${paymentTransactionId}/detail`)
      if (response.status === 200) {
        if (response.data?.status === 1) {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchStatus()
  }, [paymentTransactionId])

  if (isSuccess === null) {
    return <CardThreeBounce />
  }

  return (
    <React.Fragment>
      <div className="my-5 ">
        <Container>

          <div className={`zr_pymt_status h-100 ${isSuccess ? 'zr_pymt_success' : 'zr_pymt_fail'}`}>
            {isSuccess ? (
              <div className="zr_pymt_message">
                <p style={{ fontSize: "40px" }}>SUCCESS</p>
                <FaCheckCircle className="zr_pymt_icon_success" />

                <p className="mt-3" style={{ fontSize: "24px" }}>Payment Completed</p>
              </div>
            ) : (
              <div className="zr_pymt_message">
                <p style={{ fontSize: "40px" }}>Payment Failed</p>
                <FaTimesCircle className="zr_pymt_icon_fail" />

                <p className="mt-3" style={{ fontSize: "24px" }}>If money deducted from your account then it will be credited to your account.</p>

              </div>
            ) }
          </div>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default PaymentStatus;
