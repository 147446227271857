import _ from 'lodash'

export const isSameObject=(obj1,obj2)=>_.isEqual(obj1,obj2)

export const areTripsValidForInvoice=(trips, tripIds)=> {
    if (tripIds.length === 0) return { isValid: false, message: "No trips provided" };;

    // Extract the trips that match the provided tripIds
    const filteredTrips = trips.filter(trip => tripIds.includes(trip._id));
    if(!filteredTrips)return { isValid: false, message: "No trips provided" };;

    // Check if all trips have the same client_id
    const clientId = filteredTrips[0]?.client?.client_id;
    const allSameClient = filteredTrips.every(trip => trip?.client?.client_id === clientId);

    if (!allSameClient) {
        return { isValid: false, message: "Please Select Trips of same Clients" }; // Invalid if not all trips have the same client_id
    }

    // Check if all trips have status == 5 and are not invoiced
    const allValidTrips = filteredTrips.every(trip => trip.status === 5 && !trip.hasOwnProperty('clientInvoice_id'));

    if (!allValidTrips) {
        return { isValid: false, message: "Please Select Trips which are not invoiced and completed" };; // Invalid if not all trips meet the status and invoice criteria
    }

    return { isValid: true, message: "" }; // No invalid trips
}

export const areTripsValidForModification = (trips) => {
    if (trips.length === 0) return { isValid: false, message: "No trips provided" };
    const allValidTrips = trips.every(trip => !trip.hasOwnProperty('clientInvoice_id'));

    if (!allValidTrips) {
        return { isValid: false, message: "Please Select Trips which are not invoiced" };
    }

    return { isValid: true, message: "" };
}

  
export const formatNumberToCurrency = (amount, decimal = false) => {
    if (typeof amount !== 'number') {
        return 'Invalid input';
    }

    return decimal
        ? amount.toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        : amount.toLocaleString('en-IN');
};



