import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET } from "../../../util/HttpUtil";

class DriverSelect {
    drivers = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filter) {
        try {
            const response = await doGET(ENDPOINTS.getAllDrivers(filter));
            if (response.status === 200) {
                this.drivers = response?.data?.rows;
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
    set = async function (data) {
        this.drivers = data;
    };
}

const DriverSelectService = new DriverSelect();
export default DriverSelectService;
