import React from "react";
import { Button } from "reactstrap";
import { ThreeBounce } from 'better-react-spinkit';
import { BsTrash3 } from "react-icons/bs";

export default function DeleteButton({ onClick, disabled, loading, outline = false }) {

  if (loading) {
    return (
      <div className="d-flex justify-content-end my-2">
        <Button
          type="submit"
          className="mx-2"
          style={{ height: "28px", padding: "2px 4px", width: "90px" }}
          color="danger"
        >
          <ThreeBounce size={10} color="#FFFFFF" />
        </Button>
      </div>
    );
  }
  return (
    <div className="d-flex justify-content-end my-2">
      <Button
        onClick={onClick}
        outline={outline}
        className="d-flex gap-2 align-items-center btn mx-2"
        disabled={disabled}
        style={{ height: "30px" }}
        color="danger"
      >
        <BsTrash3 size={20} />
        <div style={{ margin: "2px", float: "right" }}>Delete</div>
      </Button>
    </div>
  );
}
