/* FOR Lead ... CURRENTLY INCOMPLETED */

import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../../../pages/Employee/EmployeeConstant";

class EmployeeSelect {
    employees = [];
    queue = [];
    isFetching = false;
    page = 1;
    rowsPerPage = 10;
    filterOption = "";
    total = -1;

    // fetch Employee from employee_id

    categoryCache = {};
    fetchQueue = {};
    loading = {};

    constructor() {
        makeAutoObservable(this);
    };

    fetchEmployeeNameFromEmployeeId = async(employeeId)=>
        {
            if(this.categoryCache[employeeId]){
                return this.categoryCache[employeeId];
            }
    
            if (this.loading[employeeId]) {
                return new Promise((resolve, reject) => {
                  if (!this.fetchQueue[employeeId]) this.fetchQueue[employeeId] = [];
                  this.fetchQueue[employeeId].push({ resolve, reject });
                });
              }
              this.loading[employeeId] = true;
    
              try {
                const response = await doGET(`/api/employee/grid?rows=-1`);
                if (response.status === 200) {
                  const categories = response.data.rows.reduce((acc, item) => {
                    acc[item._id] = { ...item, label: item?.name };
                    return acc;
                  }, {});
          
                  this.categoryCache = { ...this.categoryCache, ...categories };
          
                  while (this.fetchQueue[employeeId]?.length > 0) {
                    const { resolve } = this.fetchQueue[employeeId].shift();
                    resolve(this.categoryCache[employeeId]);
                  }
          
                  return this.categoryCache[employeeId] || null;
                } else {
                  throw new Error("Failed to fetch Employees");
                }
              } catch (error) {
                throw new Error(error.message);
              } finally {
                delete this.loading[employeeId];
              }
    
        }

    fetchSingle = async (filterUrl) => {
        return new Promise(async (resolve, reject) => {
            try {

                const response = await doGET(ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl));
                if (response.status === 200) {
                    if (this.page === 1) {
                        this.employees = response?.data?.rows
                        this.total = response?.data?.total;
                    } else {
                        this.employees = [...this.employees, ...response?.data?.rows]
                    }
                } else {
                    // Handle error
                    this.error = response.data;
                }

                // while (this.queue.length > 0) {
                //     const { resolve } = this.queue.shift();
                //     resolve();
                // }

            } catch (err) {
                this.error = err;
                // while (this.queue.length > 0) {
                //     const { reject } = this.queue.shift();
                //     reject(err);
                // }
            } finally {
                this.isFetching = false;
            }
        });
    };

    fetch = async function (filterUrl = "") {
        // if (this.isFetching) {
        //     return new Promise((resolve, reject) => this.queue.push({ resolve, reject }));
        // };
        // filterUrl='a=b'
        if (this.filterOption !== filterUrl) {
            this.page = 1;
            this.total = -1;
        }
        this.filterOption = filterUrl;
        this.isFetching = true;
        this.fetchSingle(filterUrl)
    };

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch(this.filterOption);
    };

    pushNewData = (data) => {
        this.employees.push(data)
    }

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));
        if (response.status === 200) {
            return response.data
        }
    }

}

const EmployeeSelectService = new EmployeeSelect();
export default EmployeeSelectService;
