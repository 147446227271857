import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import { AmountField, ModalComponent } from "../../components";
import RemoveLink from "../../components/Buttons/RemoveLink";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doGET, doPOST } from "../../util/HttpUtil";
import Invoice from "../Invoice/Invoice";
import "./style.css";
import Booking from "../BookingsNew/Booking";


const InvoiceSettlements = ({data = [], invoiceIds=[],setInvoiceIds,onChange,addSettlement,onChangeExcessAmount, header,client_id = null,clientCompany_id=null}) => {

    const [addInvoiceModal, setAddInvoiceModal] = useState(false)
    const { t } = useContext(I18nContext)
    const { showError } = useContext(DialogContext);

    const [totalAmount, setTotalAmount] = useState(0);
    const [totalTDS, setTotalTDS] = useState(0);
    const [invoicesFetched, setInvoicesFetched] = useState(false);
    // const [invoiceIds, setInvoiceIds] = useState([]);



    // const fetchSettledInvoicePayments = async () => {
    //     const invoice_Ids = data?.map(settlement => settlement.invoice_id) ?? [];
    //     setInvoiceIds(invoice_Ids);
    //     try {
    //         let invoiceIds = [];
    //         if (invoice_Ids && invoice_Ids.length) {
    //             invoiceIds = invoice_Ids.join(",");
    //         }
    //         const excludedIdsQuery = `_id=in[${invoiceIds}]&client.org_id=${client_id}&client.company_id=${clientCompany_id}`
    //         // let response = await doGET(`/api/invoice/grid?_id=in[${invoice_Ids}]`);
    //         let response = await doPOST(
    //             `/api/invoice/grid`,
    //             invoice_Ids && invoice_Ids.length ? { queryURL: excludedIdsQuery } : {}
    //         );            
    //         updateInvoiceSettlements(response?.data?.rows);
    //         setInvoicesFetched(true);
    //     } catch (error) {
    //         showError(error)
    //     }
    //     return invoice_Ids;
    // };


    const calculateTotalAmountReceived = (updatedData) => {
        let total = 0;
        updatedData?.forEach((acc) => {
            total += acc?.amountSettled || 0;
        });
        setTotalAmount(total)
        // onChangeExcessAmount();
        return ;
    };
    const calculateTotalTDSReceived = (updatedData) => {
        let total = 0;
        updatedData?.forEach((acc) => {
            total += acc?.tdsSettled || 0;
        });
        setTotalTDS(total)
        // onChangeExcessAmount(total+totalAmount);
        // onChangeExcessAmount();
        return ;
    };

    const addInvoiceId = (invoice_id) => { 
        setInvoiceIds(prevIds=>([...prevIds, invoice_id]));
     
    }    
    
    const deleteItems = (index, invoice_id) => {
        const updatedData = data.filter((_, i) => i !== index);
        const updatedInvoiceIds = invoiceIds.filter(id => id !== invoice_id);
        setInvoiceIds(updatedInvoiceIds);
        onChange(updatedData);
        calculateTotalAmountReceived(updatedData);
    };

    const updateItem = ({ index, key, value }) => {
        const updatedData = [...data];
        updatedData[index] = { ...updatedData[index], [key]: value };
        if(key==="amountSettled"){
            calculateTotalAmountReceived(updatedData);
        }
        if(key==="tdsSettled"){
            calculateTotalTDSReceived(updatedData);
        }
        onChange(updatedData);
    };


    useEffect(() => {
        calculateTotalAmountReceived(data);
        calculateTotalTDSReceived(data);
    }, [])


    useEffect(() => {
        // if (data?.length && !invoicesFetched ) {
        //     fetchSettledInvoicePayments();
        // }
        const invoice_Ids = data?.map(settlement => settlement.invoice_id) ?? [];
        // setInvoiceIds(invoice_Ids);
    }, [invoicesFetched,data])



    const formatDate = (dateInt) => {
        if (!dateInt) return '';

        // Convert the integer to a string
        const dateString = dateInt.toString();

        // Ensure dateString is a string and has the correct length
        if (typeof dateString !== 'string' || dateString.length !== 8) return dateString;
      
        // Extract year, month, and day from the dateString
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
      
        // Format the date as YYYY-MM-DD
        return `${day}-${month}-${year}`;
      };
    
    return (

        <div>
            {addInvoiceModal !== false &&
                <ModalComponent
                    position={"top"}
                    size={"large"}
                    isOpen={addInvoiceModal}
                    onToggle={() => setAddInvoiceModal(false)}>
                    <ModalComponent.Title>
                        {t("Invoices")}
                    </ModalComponent.Title>

                    <ModalComponent.Body>
                        <Invoice
                            // value={value}
                            statuses={[2]}
                            excludedPaymentStatuses={[2]}
                            client_id={client_id}
                            clientCompany_id={clientCompany_id}
                            excludedIds={invoiceIds}
                            insidePane={true}
                            multiMode={false}
                            onSelect={(v) => {
                                addSettlement({
                                    invoice_id: v._id,
                                    invoiceDate: v?.invoiceDate,
                                    invoiceNo: v?.invoiceNo,
                                    amountDue: v?.due,
                                    totalAmount: v?.total,
                                    writeOff:v?.writeOff ?? 0,
                                    tdsSettled:0
                                });
                                addInvoiceId(v._id);
                                // setAddInvoiceModal(false) 
                            }}
                        />
                    </ModalComponent.Body>
                </ModalComponent>
            }

            <div className="d-flex justify-content-between">
                <h5 style={{ fontWeight: '800' }}>{header ?? t("Invoices")}</h5>
                <div className="d-flex gap-3">
                    <Button
                        color="light"
                        onClick={() => {
                            if(!client_id){
                                showError("Please Select Client");
                            }else{
                                setAddInvoiceModal(true) 
                            }
                            }}
                        style={{ width: "fit-content" }}
                        className="h-36 d-flex align-items-center mb-2">
                        <i className="bx font-size-20 color-blue bx-plus"></i>
                        {t("Invoices")}
                    </Button>
                </div>
            </div>

            <Card>
                <table className="table-bordered settledPayments">
                    <thead>
                        <tr className="py-2" style={{ backgroundColor: '#FAF9FF' }}>
                            <th style={{ width: '18%' }}>{t("Invoice Detail")}</th>
                            <th style={{ width: '18%' }}>{t("Invoice Amount")}</th>
                            <th style={{ width: '18%' }}>{t("Collected Amount")}</th>
                            {/* <th style={{ width: '18%' }}>{t("TDS")}</th> */}
                            <th style={{ width: '18%' }}>{t("Write off")}</th>
                            <th style={{ width: '10%' }}></th>
                        </tr>
                    </thead>
                    {data.map((invoice, index) => (
                        <tbody className="settledPayments-table-hover position-relative">
                            <tr key={index} className="shadow-none">
                                <td >
                                    <div className="d-flex flex-column justify-content-center" >
                                        <div><b>{invoice?.invoiceNo}</b></div>
                                        <div><b>{formatDate(invoice?.invoiceDate)}</b></div>
                                    </div>
                                </td>
                                <td >
                                    <div className="d-flex flex-column justify-content-center align-items-center" >
                                    <AmountField
                                            disabled
                                            innerStyle={{ border: "0px",backgroundColor:"#f8f8f8"  }}
                                            placeholder={"Amount"}
                                            value={invoice.totalAmount}
                                            onChange={(v) => {
                                                updateItem({
                                                    value: parseInt(v),
                                                    index,
                                                    key: "totalAmount"
                                                })
                                            }}
                                            style={{maxWidth:"120px"}}
                                            className={"mt-0 w-50"}
                                            type="text"
                                        />
                                        {
                                         ( !invoice?.amountSettled ||  invoice?.amountSettled === 0) &&
                                            <span className="d-flex justify-content-between align-items-center mb-1">
                                                (
                                                <span><b>Amount Due :</b></span>
                                                <span>{(invoice?.amountDue / 100).toFixed(2)}</span>
                                                )
                                            </span>
                                        }
                                    </div>
                                </td>
                                <td >
                                    <div className="d-flex justify-content-center" >
                                        <AmountField
                                            innerStyle={{ border: "0px",}}
                                            placeholder={"Amount"}
                                            value={invoice.amountSettled}
                                            onChange={(v) => {
                                                updateItem({
                                                    value:parseInt(v),
                                                    index,
                                                    key: "amountSettled"
                                                })
                                                onChangeExcessAmount();
                                            }}
                                            style={{borderRadius: "6px 6px 6px 6px", border:"1px solid #ced4da", maxWidth:"120px",}}
                                            className={"mt-0 w-50"}
                                            type="text"
                                        />
                                    </div>
                                </td>
                                {/* <td >
                                    <div className="d-flex justify-content-center" >
                                    <AmountField
                                            innerStyle={{ border: "0px", }}
                                            placeholder={"Amount"}
                                            value={invoice.tdsSettled}
                                            onChange={(v) => {
                                                updateItem({
                                                    value:parseInt(v),
                                                    index,
                                                    key: "tdsSettled"
                                                })
                                                onChangeExcessAmount();
                                            }}
                                            style={{borderRadius: "6px 6px 6px 6px", border:"1px solid #ced4da", maxWidth:"120px",}}
                                            className={"mt-0 w-50"}
                                            type="text"
                                        />
                                    </div>
                                </td> */}
                                <td >
                                    <div className="d-flex justify-content-center" >
                                    <AmountField
                                            disabled
                                            innerStyle={{ border: "0px",backgroundColor:"#f8f8f8"  }}
                                            placeholder={"Write off"}
                                            value={invoice.writeOff}
                                            onChange={(v) => {
                                                updateItem({
                                                    value:parseInt(v),
                                                    index,
                                                    key: "writeOff"
                                                })
                                            }}
                                            style={{ maxWidth:"120px"}}
                                            className={"mt-0 w-50"}
                                            type="text"
                                        />
                                    </div>
                                </td>
                                <td style={{ right: 8 }}>
                                    <div className="d-flex justify-content-center" >
                                        <RemoveLink className="ms-1" onClick={() => deleteItems(index,invoice?.invoice_id)} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
                <div>
                        <div className=" row my-1 col-12 d-flex justify-content-end align-items-center">
                           <h5 style={{width:"fit-content"}}>{t("Total Amount Received")}: {(totalAmount / 100).toFixed(2)}</h5> 
                        </div>
                </div>
            </Card>
        </div>
    );
};

export default observer(InvoiceSettlements);

