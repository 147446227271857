import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ThreeBounce } from "better-react-spinkit";
import VehicleSelectService from './VehicleSelectService';

const VehicleLabel = ({ value }) => {
  const [vehicleReg, setvehicleReg] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVehicleReg = async (value) => {
      setLoading(true);
      try {
        const vehicleDetail = await  VehicleSelectService.fetchVehicleFromVehicleId(value);
        if (vehicleDetail) {
            setvehicleReg(vehicleDetail.label);
        } else {
            setvehicleReg('No Record Found');
        }
      } catch (error) {
        setvehicleReg('No Record Found');
      }
      setLoading(false);
    };

    if (value) {
        fetchVehicleReg(value);
    } else {
        setvehicleReg('No Record Found');
      setLoading(false);
    }
  }, [value]);

  if (loading) {
    return <ThreeBounce size={10} color="pink" className="zr_category-loader" />;
  }

  return (
    <div className={`zr_category-label ${vehicleReg === 'No Record Found' ? 'disabled' : ''}`}>
      {vehicleReg}
    </div>
  );
};

export default observer(VehicleLabel);
