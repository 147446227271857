import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  CheckButton,
  DataGrid,
  Layout
} from "../../../components";
import { DialogContext } from "../../../store/context/DialogContext";
import { UserContext } from "../../../store/context/UserContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../../util/FilterUtil";
import { ClientGridColumns, RateDataGridColumns } from "../ClientRateGridColumns";
import { STRUCTURE } from "../RateConstant";
import RateDataGridService from "./RateDataGridService";

const RateDataGrid = ({url,  value,rateType, insidePane, multiMode, onSelect = () => { } }) => {

  let { rateNo } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showError } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [editID, setEditID] = useState("");
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/rate/create" || rateNo);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [selectedRateType, setSelectedRateType] = useState(rateType ?? "All");

  const fetchData = async (filterUrl) => {
    if (url) {
      filterUrl += url;
    }

    if (selectedRateType && selectedRateType != "All") {
      filterUrl += `&rateType=${selectedRateType}`;
    }

    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await RateDataGridService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };


  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [url, selectedRateType])

  useEffect(() => {
    setEditID(rateNo)
  }, [rateNo])

  useEffect(() => {
    setSelectedRateType(rateType ?? "All")
  }, [rateType])



  const renderLastCol = useCallback((rate) => {
    return (
      <>
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(rate);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])

  const toggleData = {
    module: [
      { label: "All", value: "All" },
      { label: "LOCAL", value: "LOCAL" },
      { label: "RENTAL", value: "RENTAL" },
      { label: "OUTSTATION", value: "OUTSTATION" },
      { label: "TRANSFER", value: "TRANSFER" },
      { label: "PACKAGE", value: "PACKAGE" },
      { label: "SELF_DRIVE", value: "SELF_DRIVE" },

    ],
    toggleValue: selectedRateType ?? "All"
  }

  return (
    <>
      <Layout
        large
        toggleData={toggleData}
        showToggle
        compact
        onToggle={(selectedValue) => {
          setSelectedRateType(selectedValue)
        }
        }
        hideAdd
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setEditID(null);
        }}
        hideSearch
         
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          setEditID(null);
        }}
        insidePane={insidePane}
        page={RateDataGridService.page}
        rows={RateDataGridService.rows}
        total={RateDataGridService.total}
        fetch={RateDataGridService.fetch}
      >

        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={RateDataGridService.records}
            total={RateDataGridService.total}
            uiPreference="Rate.grid"
            headers={RateDataGridColumns}
            selectedIDs={selectedIDs}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            page={RateDataGridService.page}
            rowsPerPage={RateDataGridService.rowsPerPage}
            onPaginationChange={RateDataGridService.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>

      </Layout>
    </>
  );
};
export default observer(RateDataGrid);
