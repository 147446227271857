import React, { useState, useRef, useEffect, useContext } from "react";
import axios from "axios";
import MeetScreen from "./MeetScreen/MeetScreen";
import { useSupportCall } from "../../../helpers/customHooks/supportCall/useSupportCall";
import { UserContext } from "../../../store/context/UserContext";
import { changeAudioOutputDevice, changeAudioInputDevice } from "../../../helpers/SupportServiceHelpers/utils";

export default function HelpAdminRoom({ otherPeerUsername, userStreamRef, userAudio }) {

  const { supportCallDataRef } = useContext(UserContext)
  const { supportCallScreenShareInitByAdmin, supportCallEnd, supportCallAskToShareScreenByAdmin, supportCallToggleMic, supportCallChangeSettingsHandler } = useSupportCall()
  let audioSource = "default"

  const userVideo = useRef();
  const [micStatus, setMicStatus] = useState(false);
  const [settingStatus, setSettingStatus] = useState(false);
  const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState(0);
  const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState(0);

  const onSupportUserAcceptedScreenShareRequest = () => {
    userAudio.current.pause();
    userVideo.current.srcObject = userStreamRef.current;
  }

  useEffect(() => {
    const fetchData = async () => {
      supportCallScreenShareInitByAdmin({ onSupportUserAcceptedScreenShareRequest })
    };
    fetchData();
    return supportCallEnd;
  }, []);

  const askToShareScreen = async () => {
    supportCallAskToShareScreenByAdmin({})
  };

  const changeSettingHandler = async (inputDeviceIndex, outputDeviceIndex) => {
    await changeAudioInputDevice(
      inputDeviceIndex,
      supportCallDataRef.current.localStream,
      selectedAudioInputDevice,
      supportCallDataRef.current.audioInputDevices,
      supportCallDataRef.current.senders
    );
    if (
      userAudio.current &&
      typeof userAudio.current.sinkId !== "undefined"

    ) {

      console.log(userAudio.current, userAudio.current.sinkId);

      await userAudio.current.setSinkId(supportCallDataRef.current.audioOutputDevices[outputDeviceIndex].deviceId);
      console.log(
        `Success, audio output device attached:`, supportCallDataRef.current.audioOutputDevices[outputDeviceIndex]
      );
      // return stream;
    } else {
      console.warn("Browser does not support output device selection.");
    }
    setSelectedAudioOutputDevice(outputDeviceIndex);
    setSelectedAudioInputDevice(inputDeviceIndex);
    setSettingStatus(false);
  }

  return (
    <>
      <MeetScreen
        endCall={supportCallEnd}
        user={otherPeerUsername}
        userVideo={userVideo}
        micStatus={micStatus}
        toggleMic={() => {
          supportCallToggleMic(micStatus);
          setMicStatus(!micStatus)
        }}
        settingStatus={settingStatus}
        onChange={changeSettingHandler}
        setSettingStatus={setSettingStatus}
        askToShareScreen={askToShareScreen}
        audioInputDeviceList={supportCallDataRef.current.audioInputDevices}
        audioOutputDeviceList={supportCallDataRef.current.audioOutputDevices}
        selectedAudioOutputDevice={selectedAudioOutputDevice}
        selectedAudioInputDevice={selectedAudioInputDevice}
      />
    </>
  );
}
