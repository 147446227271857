import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./VehicleConstant";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { getUiPrefRows } from "../../util/FilterUtil";
import UIPrefService from "../../components/DataGrid/UIPrefService";
class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = 40;
  filterOption = "";
  total = -1;
  infiniteScroll = false;
  version = 0;
  vehicleCache = [];
  tags = []

  constructor() {
    makeAutoObservable(this);
  }

  getSelectObject = (obj) => {
    var map = [];
    for (var i in obj) {
      map.push({ label: i, value: obj[i] });
    }
    return map;
  };

  getStructure = async () => {
    this.countryMap = (await doGET(ENDPOINTS.countryMap))?.data;
    this.STRUCTURE = [
      {
        label: "Vehicle Type",
        description: "Select Vehicle Type",
        filterLabel: "Vehicle Type",
        filterName: "vehicleType",
        type: "select",
        data: this.getSelectObject(this.vehicleMap),
      },
      {
        label: "Vendor",
        description: "Select Vendor",
        filterLabel: "Vendor",
        filterName: "vendor_id",
        type: "select",
        data: this.getSelectObject(this.vendorMap),
      },
      {
        label: "Zone",
        description: "Select Zone",
        filterLabel: "Zone",
        filterName: "zone_id",
        type: "select",
        data: this.zoneMap?.map((zone) => ({
          label: zone.name,
          value: zone._id,
        })),
      },
      {
        label: "From Date",
        description: "Select From Date",
        filterLabel: "From Date",
        filterName: "fromTime",
        type: "dateSelect",
        getTimestamp: true,
      },
      {
        label: "Till Date",
        filterLabel: "Till Date",
        description: "Select Till Date",
        filterName: "tillTime",
        type: "dateSelect",
        getTimestamp: true,
      },
      {
        label: "Reg. Number",
        description: "Vehicle Registration Number",
        filterLabel: "Reg. Number",
        filterName: "regNo",
        type: "text",
      },
      {
        label: "Status",
        description: "Is vehicle Active?",
        filterLabel: "Status",
        filterName: "status1",
        type: "select",
        data: [
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
          { label: "Sold", value: "sold" },
        ],
      },
      {
        label: "Status",
        description: "Is Vehicle in Service?",
        filterLabel: "Status",
        filterName: "status2",
        type: "select",
        data: [
          { label: "In Service", value: "inservice" },
          { label: "Assigned", value: "assigned" },
          { label: "Unassigned", value: "unassigned" },
        ],
      },
      {
        label: "GPS",
        description: "Is GPS Attatched?",
        filterLabel: "GPS",
        filterName: "gps",
        type: "select",
        data: [
          { label: "GPS Attached", value: "gspAttached" },
          { label: "GPS Not Attached", value: "gspNotAttached" },
        ],
      },
    ];
  };

  fetch = async ({ filterUrl }) => {
    const uiPref = "vehicle.grid"
    let withColumns = [];

    const uiPrefresponse = UIPrefService?.uiPrefs[uiPref]
    ? { status: 200, data: { value: UIPrefService?.uiPrefs[uiPref] } }
    : await doGET('/api/ui-preference/' + uiPref + '/detail');


    if (!UIPrefService?.uiPrefs[uiPref])
      UIPrefService?.updatePrefs(uiPref, uiPrefresponse?.data?.value ?? '[]');

    if (uiPrefresponse?.status === 200 && uiPrefresponse?.data?.value) {
      const availableColumns = JSON.parse(uiPrefresponse?.data?.value);
      const columnMap = {
        "Reg No": "regNo",
        "Driver Assigned": "assignedDriverName",
        "Driver Phone":"assignedDriverPhone",
        "Supplier": "supplierName"
      };

      Object.entries(columnMap).forEach(([key, value]) => {
        if (availableColumns?.includes(key)) {
          Array.isArray(value) ? withColumns.push(...value) : withColumns.push(value);
        }
      });
    }



    if (!this?.rowsPerPage) {
      const rows = await getUiPrefRows("vehicle.grid")
      this.rowsPerPage = rows ?? 40
    }
    if (filterUrl) {
      this.filterOption = filterUrl;
    }
    if(withColumns?.length) {
      
      filterUrl += `withColumns=${(withColumns?? []).join(",")}`;
    }
    const response = await doPOST(
      ENDPOINTS.grid(), { queryURL: ENDPOINTS.filter(this.page, this.rowsPerPage, filterUrl) }
    );
    if (response?.status === 200) {
      this.records = response.data.rows;
      this.page = response?.data?.page;
      if (this.page === 1)
        this.total = response?.data?.total;
      return response.data.rows;
    }
  };


  fetchAll = async function () {
    if (this.vehicleCache.length) {
      return this.vehicleCache
    }

    try {
      const response = await doGET(ENDPOINTS.grid(0, -1));
      if (response.status === 200) {
        this.vehicleCache = response?.data?.rows;
        return response?.data?.rows;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };


  edit = async function (data, id) {
    const response = await doPUT(ENDPOINTS?.update, data);
    if (response.status === 200) {
      this.version += 1;
      this.vehicleCache = []
      this.fetchAll()

    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      this.tags = response?.data?.vehicleExtn?.tags ?? []
    }
    return response?.data
  };

  getFuelType = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      this.tags = response?.data?.vehicleExtn ?? {}
    }
    return response?.data

  }

  updateTags = (v) => {
    this.tags = v ?? []
  }

  save = async (data) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    if (response.status === 200) {
      this.vehicleCache = []
      this.fetchAll()
    }
    return response?.data?._id;
  };


  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      // this.fetch();
      this.fetch({ filterUrl: '' });


    }
  };

  updateZone = async (vehicleID, zoneID) => {
    await doGET(ENDPOINTS.updateZone(vehicleID, zoneID));
  };

  assignDriver = async (vehicleID, driverID) => {
    try {
      const response = await doGET(ENDPOINTS.updateDriver(vehicleID, driverID));
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

  removeDriver = async (vehicleID) => {
    try {
      const response = await doGET(ENDPOINTS.removeDriver(vehicleID));
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getVehicleMap = async () => {
    if (!this.vehicleMap)
      this.vehicleMap = [{ label: 1, value: 1 }]
    var vehicleList = [];
    for (var i in this.vehicleMap) {
      vehicleList.push({ label: i, value: i });
    }
    return vehicleList;
  };

  getZoneMap = async () => {
    if (this.zoneMap)
      return this.zoneMap?.map((zone) => ({
        label: zone.name,
        value: zone._id,
      }));
    this.zoneMap = (await doGET(ENDPOINTS.zoneMap))?.data;
    return this.zoneMap?.map((zone) => ({ label: zone.name, value: zone._id }));
  };

  getCountryMap = async () => {
    if (this.countryMap)
      return this.countryMap?.map((country) => ({
        label: country.name,
        value: country.name,
      }));
    this.countryMap = (await doGET(ENDPOINTS.countryMap))?.data;
    return this.countryMap?.map((country) => ({
      label: country.name,
      value: country.name,
    }));
  };

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch({ filterUrl: this.filterOption });
  };


  bulkDelete = async (ids = []) => {
    const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
    if (response.status === 200) {
      this.fetch({ filterUrl: '' });
    }
  };

}

const VehicleService = new Service();
export default VehicleService;
