import { TbSortAscending, TbSortDescending } from "react-icons/tb";

export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/garage/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/garage/${id}/detail`,
  save: `/api/garage/create`,
  delete: (id) => `/api/garage/${id}/delete`,
  bulkDelete: (id) => `/api/garage/delete`,
  update: `/api/garage/update`,
};

export const STRUCTURE = [
  {
    label: "Garage Code",
    filterLabel: "Garage Code",
    filterName: "code",
    type: "text",
  },
  {
    label: "Garage Name",
    filterLabel: "Garage Name",
    filterName: "address.name",
    type: "text",
  },
  {
    label: "Sorting",
    filterLabel: "Sorting",
    filterName: "sorting",
    type: "sorting",
    sidx: [
      { label: "Garage Code", value: "code",  },
      { label: "Garage Name", value: "address.name" },
      { label: "City", value: "address.city" },
      { label: "Country", value: "address.country" },
    ],
    sord: [
      { label: <div className="d-flex align-items-center "><TbSortAscending className="me-2" size={20} /> Ascending </div> , value: "true" },
      { label: <div className="d-flex align-items-center "><TbSortDescending className="me-2"  size={20} /> Descending </div> , value: "false" },
    ]
  },

  {
    label: "Has Self Drive ",
    filterLabel: "Has Self Drive",
    renderer: (dataObj) => {
      return <div>
        {dataObj?.hasSelfDrive ? 'true' : 'false'}
      </div>;
    },
    filterName: "hasSelfDrive",
    type: "text",
  },
];
