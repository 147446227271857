export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/leave/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  get: (id) => `/api/leave/${id}/detail`,
  save: `/api/leave/create`,
  delete: (id) => `/api/leave/${id}/delete`,
  update: `/api/leave/update`,
};

export const STRUCTURE = [
  {
    label: "Remark",
    filterLabel: "Remark",
    filterName: "remark",
    type: "text",
  }, {
    label: "Date",
    filterLabel: "Date",
    filterName: "date",
    type: "dateSelect",
  },

];

