import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../../../pages/Office/OfficeConstant";

class OfficeSelect {
    offices = [];
    officeCache = {}
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function ({ valueLabel, corp_id }) {
        if (this.officeCache[corp_id ?? "mine"]) {
            return this.officeCache[corp_id ?? "mine"]
        }
        try {
            // console.log(ENDPOINTS.grid({ corp_id }))
            const response = await doGET(ENDPOINTS.grid({ org_id: corp_id, page: 1, sizePerPage: -1 }));
            if (response.status === 200) {
                const office = response?.data?.rows?.map((v) => {
                    if (valueLabel) {
                        return { ...v, label: v.name, value: v.name, valueId: v?._id };
                    }
                    return { ...v, label: v.name, value: v._id };
                });
                this.officeCache = { ...this.officeCache, [corp_id ? corp_id : "mine"]: office };
                return office
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const OfficeSelectService = new OfficeSelect();
export default OfficeSelectService;
