import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { ThreeBounce } from "better-react-spinkit";
import { UserContext } from "../../store/context/UserContext";
import {
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton,
    CheckButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditCreditNotes from "./EditCreditNote";
import { STRUCTURE } from "./CreditNoteConstant";
import { GridColumns } from "./CreditNoteGridColumns";
import CreditNoteService from "./CreditNoteService";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";

const CreditNotes = ({
    value,
    insidePane,
    multiMode,
    onSelect = () => { },
    corp_id,
    updateHasCreditNotes = () => { },
    hideTitleBar,
    hideFilterBar,
    dontShowCheck = false
}) => {
    let { creditNote_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const { userContent } = useContext(UserContext);
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(creditNote_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/creditNote/create" || creditNote_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)


    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            const rows = await CreditNoteService.fetch(filterUrl);
            setLoading(false);
            updateHasCreditNotes(rows?.length ? true : false)

        } catch (e) {
            showError(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        let { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        if (corp_id) {
            filterObject = { ...filterObject, corp_id: corp_id };
            filterUrl += "corp_id=" + corp_id;
        }
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }, [])

    useEffect(() => {
        if (!insidePane) setEditID(creditNote_id)
    }, [creditNote_id])

    const onSave = async (e) => {
        e.preventDefault();
        if (!detailData?.client?.fullName || !detailData?.client?.taxId || !detailData?.creditNoteNo || !detailData?.amount || !detailData?.date || (detailData?.invoices?.length == 0)) {
            setHasErr(true)
            showError(t("Please enter all required fields"));
            return;
        }
        setLoading(true);
        try {
            if (editID) {
                await CreditNoteService.edit(detailData, editID);
                setLoading(false);
                showMessage(t("Record updated successfully."));
            } else {
                var creditNoteDetailData = detailData;
                if (corp_id) creditNoteDetailData = { ...detailData, corp_id: corp_id }
                const creditNote = await CreditNoteService.save(creditNoteDetailData);
                setLoading(false);
                if (creditNote) setEditID(creditNote)
                showMessage(t("Record saved successfully."));
                if (!insidePane) navigate(`/creditNote/edit/${creditNote}`);
            }
            fetchData(filterURL);
        } catch (e) {
            showError(e);
            setLoading(false);
        }
        setHasErr(false)

    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await CreditNoteService.delete(id);
                setLoading(false);
                setShowDetailPage(false);
                showMessage(t("Deleted"), t("Deleted"));
            } catch (e) {
                showError(e);
                setLoading(false);
            }
        }
    };

    const renderLastCol = useCallback((creditNote) => {
        return (
            <>
                {userContent?.rights?.includes(4612) &&
                    <EditLink
                        onClick={() => {
                            if (!insidePane)
                                navigate(`/creditNote/edit/${creditNote?._id}`);
                            setEditID(creditNote?._id);
                            setShowDetailPage(true);
                        }}
                    />
                }
                {userContent?.rights?.includes(4613) ?
                    <DeleteLink
                        onClick={(event) => onDelete(event, creditNote?._id)}
                    />
                    : null}
                {!multiMode && insidePane && !dontShowCheck ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(creditNote?._id);
                        }}
                    />
                ) : null}
            </>
        );
    }, [insidePane, multiMode, userContent])

    return (
        <React.Fragment>
            {
                <>
                    <Layout
                        hideAdd={!userContent?.rights?.includes(4611)}
                        medium
                        showDetailPage={showDetailPage}
                        backDetailPage={async () => {
                            setShowDetailPage(false);
                            if (!insidePane) navigate("/creditNote");
                            setEditID(null);
                        }}
                         
                        title={t("Credit Notes")}
                        filterValues={filterObject}
                        filterStructure={STRUCTURE}

                        onApplyFilter={fetchData}
                        onAddClick={() => {
                            if (!insidePane) navigate(`/creditNote/create`);
                            setShowDetailPage(true);
                            setEditID(null);
                        }}
                        insidePane={insidePane}
                        page={CreditNoteService.page}
                        rows={CreditNoteService.rows}
                        total={CreditNoteService.total}
                        fetch={CreditNoteService.fetch}
                        hideTitleBar={hideTitleBar}
                        hideSearch={hideFilterBar}
                    >
                        <Layout.ActionMenu>
                            <div className="layout-action-menu">
                                <DropdownMenu>
                                    <>
                                        {userContent?.rights?.includes(1503) ?
                                        <>
                                            <DropdownItem>{t("Bulk Delete")}</DropdownItem>
                                            <div class="dropdown-divider"></div>
                                            </>
                                            : null}
                                        <DropdownItem>{t("Upload")}</DropdownItem>
                                        <div class="dropdown-divider"></div>
                                        <DropdownItem>{t("Download")} </DropdownItem>
                                    </>

                                </DropdownMenu>

                            </div>
                        </Layout.ActionMenu>
                        <Layout.Table>
                            <DataGrid
              gridLoading={loading}
                                data={CreditNoteService.records}
                                total={CreditNoteService.total}
                                uiPreference="creditNote.grid"
                                headers={GridColumns}
                                selectedIDs={selectedIDs}
                                onSelectChange={(v) => {
                                    onSelect(v)
                                    setSelectedIDs(v)
                                }}
                                page={CreditNoteService.page}
                                rowsPerPage={CreditNoteService.rowsPerPage}
                                onPaginationChange={CreditNoteService.onPaginationChange}
                                renderLastCol={renderLastCol}
                            />
                        </Layout.Table>
                        <Layout.DetailPageTitle>
                            {detailData?._id ? t("Edit Credit Note") : t("Add Credit Note")}
                        </Layout.DetailPageTitle>

                        <Layout.DetailPageBody>
                            <EditCreditNotes
                                editId={editID}
                                onChange={(v) => {
                                    setDetailData(v)
                                }}
                                corp_id={corp_id}
                                hasErr={hasErr}
                            />
                        </Layout.DetailPageBody>

                        <Layout.DetailPageFooter>
                            {detailData?._id ? (
                                <AuditLogDialog module="CreditNote" id={detailData?._id} />
                            ) : null

                            }
                            {editID && userContent?.rights?.includes(4613) ?
                                <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />
                                : null}
                            {userContent?.rights?.includes(4612) ?
                                <SaveButton onClick={onSave} loading={loading} />
                                : null}
                        </Layout.DetailPageFooter>
                    </Layout>
                </>
            }
        </React.Fragment>
    );
};
export default observer(CreditNotes);
