import React, { useState } from "react";
import { Row } from "reactstrap";
import { MapStyles } from "../../common/utils/Constants";
import PlacesWithStandaloneSearchBox from "../Searchbox/SearchBox";
import Map from "./Map";

export default function MapWithSearchBox(props) {
  const location = { lat: 12.222, lng: 76.111 };


  // useEffect(() => {
  // locations: [{ lat: 36.05298765935, lng: -112.083756616339 },
  //   { lat: 36.2169884797185, lng: -112.056727493181 }]
  //   fetch(
  //     `https://maps.googleapis.com/maps/api/geocode/json?address=${props?.value},+CA`
  //   )
  //     .then((response) => response.json())
  //     .then((res) => {
  //       console.log(res)
  //       // let latitude, longitude;
  //       // latitude = res.results[0].geometry.location.lat;
  //       // longitude = res.results[0].geometry.location.lng;
  //       // setLocation({ lat: latitude, lng: longitude });
  //     });
  // }, [props]);

  // const fetch = async() => {
  //   console.log(`/api/o/maputil/autocomplete?key=${props?.value}`)
  //   const response = await doGET(`/api/o/maputil/autocomplete?key=${props?.value}`)
  //   if (response.status === 200) {
  //     let latitude, longitude;
  //     console.log(response)
  //     latitude = response?.data[0]?.lat;
  //     longitude = response.data[0]?.lng;
  //     setLocation({ lat: latitude, lng: longitude });
  //   }
  // };

  // useEffect(() => {
  //   fetch();
  // }, [props]);

  return (
    <>
      <Row className="my-3">
        <PlacesWithStandaloneSearchBox
          onPlacesChanged={(v) => console.log(v)}
          onChange={props?.onChange}
          value={props?.value}

          label={"Address Line"}
          error={props?.value === "" ? "Please enter address Line" : ""}
        />
      </Row>
      <div
        id="gmaps-overlay"
        className="gmaps py-3"
        style={{ position: "relative" }}
      >

        <Map
          showMarker={props?.showMarker}
          mapLocation={location}
          markerLocation={location}
          mapStyle={MapStyles}
          onMapMoved={props?.onMapMoved}
        />

      </div>
    </>
  );
}
