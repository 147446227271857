import React, { useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import Image from '../../components/Image/Image';
import PDFViewer from '../../components/Image/PDF';
import { downloadMedia } from '../../util/Util';
import MediaModal from '../MediaModal/MediaModal';
import "./WADocViewer.scss";

const WADocViewer = ({ documents = [], dowloadable = false }) => {
  const [modal, setModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isDownloading, setDownloading] = useState(false);

  const toggleModal = () => setModal(!modal);

  const truncateFileName = (url) => {
    const fileName = url.split('/').pop();
    const [nameWithoutExtension, fileExtension = ''] = fileName.split(/(\.[a-zA-Z0-9]+$)/);
    const truncatedName = nameWithoutExtension.length > 15
      ? `${nameWithoutExtension.slice(0, 10)}...${nameWithoutExtension.slice(-5)}`
      : nameWithoutExtension;
    return `${truncatedName}${fileExtension}`;
  };

  const handleDocumentClick = (document) => {
    setSelectedDocument(document);
    toggleModal();
  };

  const isPdf = (doc) => doc?.type?.split('/')[0] === 'application' && doc.name.endsWith('.pdf') || doc?.key?.endsWith('.pdf') || doc?.endsWith('.pdf');

  return (
    <div className="zr_whatsapp_doc_container">
      {documents.map((document, index) => (
        <div onClick={() => handleDocumentClick(document)} key={index} className="zr_whatsapp_doc_item">
          <FaFilePdf className="zr_whatsapp_doc_icon" />
          <div className="zr_whatsapp_doc_info">
            <span className="zr_whatsapp_doc_name">{truncateFileName(document?.key)}</span>
          </div>
        </div>
      ))}

      <MediaModal isOpen={modal} toggle={toggleModal} centered>
        <MediaModal.Header
          title={"View Document"}
          onClose={toggleModal}
          onDownload={
            async () => {
              await downloadMedia((selectedDocument?.key ?? selectedDocument), "pdf")
            }
          }
        />
        <MediaModal.Body className="zr_doc_modal_body">
          {selectedDocument ? (
            isPdf(selectedDocument) ? (
              <PDFViewer src={selectedDocument.key ?? selectedDocument} alt="Document" style={{ height: "100%", width: "100%" }} />
            ) : (
              <div className='d-flex justify-content-center align-items-center'>
                <Image src={selectedDocument.key ?? selectedDocument} alt="Document" />
              </div>
            )
          ) : <p>No document selected</p>}
        </MediaModal.Body>
      </MediaModal>
    </div>
  );
};

export default WADocViewer;
