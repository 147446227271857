import React from 'react';

const initialData = {
    weekDays: {
        su: true,
        mo: true,
        tu: true,
        we: true,
        th: true,
        fr: true,
        sa: true,
    },
};


function WeekdaySelector({ data = initialData?.weekDays, onChange, disabled, noLabel }) {
    const weekdayabb = ["su", "mo", "tu", "we", "th", "fr", "sa"];

    return (
        <div style={{ display: 'flex' }}>
            <div className="col-lg" style={{ marginRight: '50px' }}>
                <div className="dowPicker ">
                    {data && weekdayabb?.map((w, idx) => (

                        <button
                            key={idx}
                            className="me-2"
                            style={{
                                backgroundColor: data?.[w] ? "green" : "white",
                                color: data?.[w] ? "white" : "black",
                                border: data?.[w] ? "1px solid green" : "1px solid #CCCCCC",
                                borderRadius: "28px",
                                boxShadow: '0px',
                                fontSize: "11px",
                                height: "20px",
                                width: "33px"
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                if (!disabled) {
                                    onChange({
                                        ...data,
                                        [w]: !data?.[w],

                                    });
                                }
                            }}
                            disabled={disabled}

                        >
                            {w.toUpperCase()}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default WeekdaySelector;
