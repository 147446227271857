import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Col, Row } from "reactstrap";
import {
  CheckButton,
  DeleteLink,
  EditLink,
  SaveButton,
  Layout,
  Breadcrumb,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import SupervisorRoleService from "./SupervisorRoleService";
import { capitalize } from "lodash";
import EditSupervisorRole from "./EditSupervisorRole";

const SupervisorRole = (props) => {
  const { id } = useParams();
  if (props?.corpId) id = props?.corpId;
  const { t } = useContext(I18nContext);
  // const navigate = useNavigate();
  const [showDetailPage, setShowDetailPage] = useState(id);
  const [delete_id, setdelete_id] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selected_id, setSelected_id] = useState(id);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);
  const [hasErr, setHasErr] = useState(false);
  const [data, setData] = useState({});
  const { showMessage, showConfirm, showError } = useContext(DialogContext);
  const [confirm_alert, setconfirm_alert] = useState(false);

  const fetchData = async (filterUrl) => {
    if (loading) return;

    setLoading(true);
    try {
      await SupervisorRoleService.fetch(filterUrl, props?.corpId);
    } catch (e) {
      showError("error", e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);

    setShowDetailPage(
      window.location.pathname === "/supervisorrole/edit" || id
    );
  }, [id]);

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete User Role ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        await SupervisorRoleService.delete(id);
        showMessage(" Deleted successfully");
      } catch (e) {
        showError("error", e);
      }
    }
  };
  const handleRowClick = async (superId) => {
    console.log(superId, "garagesID");
    if (showDetailPage && superId !== selected_id && isEditDataChanged) {
      let isProceed = await showConfirm({
        isSaveWarn: true,
        title: t(`Do you want to save the changes ?`),
        description: t("Your changes will be lost if you don't save them"),
      });
      if (isProceed) onRowClick(superId);
    } else onRowClick(superId);
  };
  const onRowClick = (id) => {
    if (!props?.insidePane) navigate(`/supervisorrole/edit/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };
  return (
    <>
      <Layout
        showDetailPage={showDetailPage}
        insidePane={props?.insidePane}
         
        title={t("Supervisor Role")}
        onApplyFilter={fetchData}
        onAddNewClick={() => {
          if (!props?.insidePane) navigate(`/supervisorrole/edit`);
          setShowDetailPage(true);
          setSelected_id(null);
        }}
        backDetailPage={() => {
          setShowDetailPage(false);
          if (!props?.insidePane) navigate("/supervisorrole");
          setSelected_id(false);
        }}
        page={SupervisorRoleService.page}
        rows={SupervisorRoleService.rows}
        total={SupervisorRoleService.totalRecords}
        onPageChange={async (page, rows) => {
          await SupervisorRoleService.onPaginationChange(page, rows);
        }}
      >
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>Name</Th>

              <Th className="float-end">Action</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {SupervisorRoleService.records?.map((supervisor) => (
              <Tr
                key={supervisor?._id}
                onClick={
                  showDetailPage && (() => handleRowClick(supervisor?._id))
                }
              // onClick={
              //   showDetailPage &&
              //   (async () => {
              //     if (
              //       showDetailPage &&
              //       supervisor?._id !== selected_id &&
              //       isEditDataChanged
              //     ) {
              //       let isProceed = await showConfirm({
              //         isSaveWarn: true,
              //         title: t(`Do you want to save the changes ?`),
              //         description: t(
              //           "Your changes will be lost if you don't save them"
              //         ),
              //       });
              //       if (isProceed) onRowClick(supervisor?._id);
              //     } else onRowClick(supervisor?._id);
              //   })
              // }
              >
                {/* <Td scope="row">{capitalize(supervisor?.name)} {!supervisor?.corp_id }</Td> */}
                <Td>
                  {capitalize(supervisor?.name)}{" "}
                  {!supervisor?.corp_id && <b>(Master Role)</b>}
                </Td>
                <Td className="float-end">
                  <EditLink
                    onClick={() => {
                      if (!props?.insidePane)
                        navigate(`/supervisorrole/edit/${supervisor?._id}`);
                      setSelected_id(supervisor?._id);
                      setShowDetailPage(true);
                    }}
                  />
                  {supervisor?.corp_id && (
                    <DeleteLink
                      onClick={(event) => {
                        onDelete(event, supervisor?._id);
                        setdelete_id(supervisor?._id);
                      }}
                    />
                  )}
                  {props?.selectMode ? (
                    <CheckButton
                      onClick={() => {
                        props?.onSelect([{ ...supervisor }]);
                      }}
                    />
                  ) : null}
                </Td>
                {/* <Td>
                <Link to={`/supervisorrole/edit/${supervisor?._id}`}>
                  <i className="mdi mdi-circle-edit-outline font-size-18 text-primary mx-1"></i>
                </Link>
                {supervisor?.corp_id && <i onClick={() => {
                  setconfirm_alert(true);
                  setdelete_id(supervisor?._id)
                }} className="mdi mdi-delete text-danger font-size-18 me-1"></i>}
              </Td> */}
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
        <Layout.DetailPageTitle>
          {/* <Breadcrumb parent={props?.parent} title={data?._id ? " Edit Supervisor Role " : " Create Supervisor Role"} /> */}

          <h5>
            {" "}
            {selected_id ? "Edit SupervisorRole" : "Create SupervisorRole"}
          </h5>
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditSupervisorRole
            //  insidePane={props?.insidePane}
            onChange={(v) => setIsEditDataChanged(v)}
            parent={props?.parent}
            id={selected_id}
            // back={() => {
            //   setShowDetailPage(false);
            //   if (!props?.insidePane) navigate("/supervisorrole");
            //   setSelected_id(false);
            // }}
            setData={setData}
            data={data}
            hasErr={hasErr}
            setHasErr={setHasErr}
            fetchData={fetchData}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          <Row>
            <Col>
              <div
                className="d-flex justify-content-end mt-1"
                style={{ marginTop: "-2.5rem" }}
              >
                {/* <SaveButton onClick={submitHandler} /> */}
              </div>
            </Col>
          </Row>
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};

export default observer(SupervisorRole);
