import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton,
  BulkUpload,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditFuel from "./EditFuel";
import { STRUCTURE } from "./FuelConstant";
import { GridColumns } from "./FuelGridColumns";
import FuelService from "./FuelService";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import { dateToTimestamp } from "../../util/Util";

const Fuel = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [uploadPopup, setUploadPopup] = useState(false);
  const [showDetailPage, setShowDetailPage] = useState(
    insidePane ? false : window.location.pathname === "/fuel/create" || edit_id
  );
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [tillDate, setTillDate] = useState(null);

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    if (!!fromDate && !!tillDate) {
      filterUrl = `time=btw[${encodeURIComponent(`${dateToTimestamp(fromDate)},${dateToTimestamp(tillDate)}`)}]`;
    }
    try {
      const res = await FuelService.fetch(filterUrl);
      setLoading(false);
      // console.log(res);
    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(
      queryParams,
      STRUCTURE
    );
    setFilterURL(filterUrl);
    setFilterObject(filterObject);
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : []);
  }, [fromDate, tillDate]);

  useEffect(() => {
    setEditID(edit_id);
  }, [edit_id]);

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.vehicle_id ||!detailData?.receiptNo) {
      setHasErr(true)
      showError(t("Please enter all required fields"));
      return;
    }
    setLoading(true);
    try {
      if (editID) {
        await FuelService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Record updated successfully."));
      } else {
        const fuel = await FuelService.save(detailData);
        showMessage(t("Record saved successfully."));
        setLoading(false);
        if (!insidePane) navigate(`/fuel/edit/${fuel}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
    setHasErr(false);
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await FuelService.delete(id);
        setLoading(false);
        showMessage("Deleted", "Deleted");
        setShowDetailPage(false);
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);

      try {
        await FuelService.bulkDelete(selectedIDs);
        setLoading(false);
        showMessage("Deleted", "Deleted Successfully");
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };

  const saveBulk = async (data) => {
    try {
      const account = await FuelService.save(data);
      if (account) {
        return { isUploaded: true, message: "" }
      }
      return { isUploaded: false, message: "Failed unexpectedly" }
    } catch (error) {
      return { isUploaded: false, message: JSON.parse(error?.message)?.customMessage ?? JSON.parse(error?.message)?.message }

    }
  };


  const renderLastCol = useCallback(
    (fuel) => {
      return (
        <>
          {userContent?.rights?.includes(3802) &&
            <EditLink
              onClick={() => {
                if (!insidePane) navigate(`/fuel/edit/${fuel?._id}`);
                setEditID(fuel?._id);
                setShowDetailPage(true);
              }}
            />
          }
          {userContent?.rights?.includes(3803) &&
            <DeleteLink onClick={(event) => onDelete(event, fuel?._id)} />
          }
          {!multiMode && insidePane ? (
            <CheckButton
              onClick={() => {
                onSelect(fuel?._id);
              }}
            />
          ) : null}
        </>
      );
    },
    [insidePane, multiMode, userContent]
  );
  return (
    <React.Fragment key={userContent?.rights}>
      <>
      {uploadPopup ? (
          <BulkUpload
            fetchData={()=>{
              fetchData(filterURL);
            }}
            show={uploadPopup}
            onClose={() => { setUploadPopup(false) }}
            title={t("Fuel")}
            kind={"Fuel"}
            upload={saveBulk}
          />
        ) : null}

        <Layout
          showDateRange
          fromDate={fromDate}
          tillDate={tillDate}
          setFromDate={setFromDate}
          setTillDate={setTillDate}
          hideAdd={!userContent?.rights?.includes(3801)}
          medium
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/fuel");
            setEditID(null);
          }}
           
          title={t("Fuel")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/fuel/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={FuelService.page}
          rows={FuelService.rows}
          total={FuelService.total}
          fetch={FuelService.fetch}
        >
          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  {/* <DropdownItem
                    onClick={() => {
                      onBulkDelete();
                    }}
                  >
                    {t("Bulk Delete")}
                  </DropdownItem> */}
                  {/* <DropdownItem onClick={() => setUploadPopup(true)}>
                    {t("Bulk Upload")}
                  </DropdownItem> */}
                  <DropdownItem> Download </DropdownItem>
                </>
              </DropdownMenu>
            </div>
          </Layout.ActionMenu>
          <Layout.Table>
            <DataGrid
              gridLoading={loading}
              data={FuelService.records}
              total={FuelService.total}
              uiPreference="fuel.grid"
              headers={GridColumns}
              onSelectChange={(v) => {
                onSelect(v);
                setSelectedIDs(v);
              }}
              selectedIDs={selectedIDs}
              page={FuelService.page}
              rowsPerPage={FuelService.rowsPerPage}
              onPaginationChange={FuelService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </Layout.Table>

          <Layout.DetailPageTitle>
            {detailData?._id ? t("Edit Fuel") : t("Add Fuel")}
          </Layout.DetailPageTitle>

          <Layout.DetailPageBody>
            <EditFuel
              editId={editID}
              onChange={(v) => {
                setDetailData(v);
              }}
              hasErr={hasErr}
            />
          </Layout.DetailPageBody>

          <Layout.DetailPageFooter>
            {editID ? (
              <AuditLogDialog module="Fuel" id={editID} />
            ) : null

            }
            {userContent?.rights?.includes(3803) && editID ? (
              <DeleteButton
                onClick={(e) => onDelete(e, detailData?._id)}
                loading={loading}
              />
            ) : null}
            {userContent?.rights?.includes(3802) && <SaveButton onClick={onSave} loading={loading} />}
          </Layout.DetailPageFooter>
        </Layout>
      </>
    </React.Fragment>
  );
};
export default observer(Fuel);
