import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { IoIosPeople } from "react-icons/io";
import { RxCross2 } from 'react-icons/rx';
import { Link } from 'react-router-dom';
import SideBarService from '../SideBarService';
import './style.css';


const TripFlyout = ({ onClose, data, visibleFlyout }) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredTrips, setFilteredTrips] = useState([]);

    const handleRowClick = (trip, index) => {

        // if (data[index]?.current?.lat && data[index]?.current?.lng) {
        //     SideBarService?.setSelectedMarkers({
        //         ...data[index],
        //         coordinate: { lat: data[index]?.current?.lat, lng: data[index]?.current?.lng },
        //         popupComponent: <div><TripPopup trip={data[index]} /></div>,
        //         markerImage: require("./../../../../src/assets/images/car-top.png"),
        //         onClose: () => {
        //             SideBarService?.setSelectedMarkers(null)
        //         }
        //     })
        // } else {
        //     SideBarService?.setSelectedMarkers({ _id: trip?._id })
        // }
        setSelectedRow(trip);
        SideBarService?.setTripId(trip?._id)
    };

    const calculateEndTime = (startTime, duration) => {

        const startTimeMilliseconds = startTime * 1000;
        const endTimeMilliseconds = startTimeMilliseconds + duration * 60 * 1000;
        const startDate = new Date(startTimeMilliseconds);
        const endDate = new Date(endTimeMilliseconds);
        const startHours = startDate.getHours();
        const startMinutes = startDate.getMinutes();
        const endHours = endDate.getHours();
        const endMinutes = endDate.getMinutes();
        const startAMPM = startHours >= 12 ? 'PM' : 'AM';
        const endAMPM = endHours >= 12 ? 'PM' : 'AM';
        const formattedStartHours = startHours % 12 || 12;
        const formattedEndHours = endHours % 12 || 12;
        const startTimeFormatted = `${formattedStartHours}:${startMinutes < 10 ? '0' : ''}${startMinutes} ${startAMPM}`;
        const endTimeFormatted = `${formattedEndHours}:${endMinutes < 10 ? '0' : ''}${endMinutes} ${endAMPM}`;

        return endTimeFormatted;
    }

    const handleSearch = (e) => {
        const { value } = e.target;
        setSearchQuery(value);
    };

    const countPassengers = (passengers) => {
        const passengersWithStatus2 = passengers.filter(passenger => passenger.status === 2);
        return passengersWithStatus2.length;
    }

    useEffect(() => {
        if (searchQuery) {
            const filtered = data?.filter(
                (trip) =>
                    trip?.tripNo.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredTrips(filtered);
        } else {
            setFilteredTrips(data || []);
        }
    }, [searchQuery, data, visibleFlyout]);

    useEffect(() => {
        setSearchQuery("")
    }, [visibleFlyout]);

    return (
        <div className='flyout'>
            <div onClick={onClose} className='flyout-close'>
                <RxCross2 />
            </div>
            <div className='padded-container'>
                <div className='flyout-header'>
                    <h2>Trips</h2>
                </div>
                <input
                    type='text'
                    placeholder='Search By Trip No'
                    className='search-field'
                    value={searchQuery}
                    onChange={handleSearch}
                />
            </div>

            <div className='flyout-table'>
                <div style={{ backgroundColor: "#f0f0f0" }}>
                    <div className='table-header'>
                        <div className='status-cell'></div>
                        <div className='header-cell'>Trip No.</div>
                        <div className='regNo-cell' >Status</div>
                        <div className='header-cell'>End Time</div>
                        <div className='header-cell' ><IoIosPeople size={25} /> </div>
                    </div>
                </div>
                <div className='table-body'>
                    {(filteredTrips).map(
                        (trip, index) => (
                            <div
                                key={index}
                                className={`table-row ${SideBarService?.tripId === trip?._id ? `selected-row-${trip?.status}` : ''
                                    }`}
                                onClick={() => handleRowClick(trip, index)}
                            >
                                <div className={`status-cell trip-status-bg-${trip?.status === 3 ? "dispatched" : trip?.status === 4 ? "live" : null} `}></div>
                                <div className='regNo-cell'>
                                    <Link to={`/booking/edit/${trip?._id}`}
                                     replace
                                        style={{ color: "blue" }}
                                    >
                                        <u>{trip?.tripNo} </u>
                                    </Link>
                                </div>
                                <div className='regNo-cell'>
                                    {trip?.status === 3
                                        ? 'Dispatched'
                                        : trip?.status === 4
                                            ? 'Live'
                                            : null}
                                </div>
                                <div className='cell'>
                                    <div className="d-flex">
                                        {
                                            calculateEndTime(trip?.startTime, trip?.duration)
                                        }
                                    </div>
                                </div>
                                <div className='cell'>
                                    <div className="d-flex">
                                        {countPassengers(trip?.passengers)}
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default observer(TripFlyout);
