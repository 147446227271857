import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ThreeBounce } from "better-react-spinkit";
import vehicleTypeLabelService from './VehicleTypeLabelService';
 

const VehicleTypeLabel = ({ id }) => {
  const [vehicleType, setVehicleType] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDetail = async () => {
        if(!id)return;
        
      setLoading(true);
      const data = await vehicleTypeLabelService.getData(id); 
      setVehicleType(data);
      setLoading(false);
    };

    fetchDetail();
  }, [id]);

  if (loading) {
    return <ThreeBounce size={10} color="pink" className="zr_company-loader" />;
  }

  return <div className="zr_company-label">{vehicleType?.name || 'Vehicle Type not found'}</div>;
};

export default observer(VehicleTypeLabel);
