// ModalContext.js
import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [size,setSize]=useState("medium");

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };


  return (
    <ModalContext.Provider value={{ isOpen, toggleModal, modalContent ,setIsOpen,setModalContent,size,setSize}}>
      {children}
    </ModalContext.Provider>
  );
};
