import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { doGET } from '../../../../util/HttpUtil';
import './style.css'; 

const isValidJson = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
};

const JsonToHtml = ({ jsonData }) => {
  const renderHtmlFromJson = (data) => {
    if (typeof data !== 'object' || data === null) {
      // Render primitive values directly
      return <span>{String(data)}</span>;
    }

    if (Array.isArray(data)) {
      // Render array elements
      return (
        <div>
          <div className='text-start'>[</div>
            <div className='text-start'>
            {data.map((item, index) => (
            <span key={index}>
              {renderHtmlFromJson(item)}
              {index < data.length - 1 ? ', ' : ''}
            </span>
          ))}
            </div>
            <div className='text-start'>]</div>
        </div>
      ); 
    }

    // Render object key-value pairs
    return (
      <div>
        {Object.keys(data).map((key) => (
          <div key={key} className='row'>
            <div className='col-4 text-start'><b>{key}:</b></div> <div className='col-8'>{renderHtmlFromJson(data[key])}</div>
          </div>
        ))}
      </div>
    );
  };

  return <div>{renderHtmlFromJson(jsonData)}</div>;
};


export const Audits = ({module, ids }) => {
  const [data, setData] = useState([]);

  const fetchAuditLogData = async () => {
    try {
      if (Array.isArray(ids) && ids.length >= 1) {
        const responses = await Promise.all(
          ids.map((_id) => doGET(`/api/audit/logs/${module}/${_id}/grid`))
        );
        const fetchedData = responses.flatMap(response => response.data ?? []);
        setData(fetchedData);
      } else {
        const response = await doGET(`/api/audit/logs/${module}/${ids}/grid`);
        const fetchedData = response.data ?? [];
        setData(fetchedData);
      }
    } catch (error) {
      console.error("Error fetching audit log data:", error);
    }
  };

  useEffect(() => {
    fetchAuditLogData();
  }, [module, ids]);


  return (
    <>
        <div className="auditTableFixHead mb-3">
          <table bordered responsive>
            <thead>
              <tr>
                <th style={{ minWidth: "140px" }}>By</th>
                <th>Field</th>
                <th>Old</th>
                <th>Modified</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? data.map((log, index) =>
                log?.changes.length > 0 && log?.changes?.map((change, changeIndex) => (
                  <tr key={`${index}-${changeIndex}`}>
                    {changeIndex === 0 && (
                      <td rowSpan={log?.changes?.length}>
                        <div className="d-flex flex-column">
                          <div>{log?.by ?? 'N/A'}</div>
                          <div>{moment.unix(log?.at ?? 0).format("DD-MM-YYYY, hh:mm A")}</div>
                        </div>
                      </td>
                    )}
                    <td>{change?.field ?? 'N/A'}</td>
                    <td>
                      {module === "Trip" && change?.field === "passengers" ? (
                        <>
                          {change?.old.length > 0 &&
                            JSON.parse(change.old)?.map((oldDetail, i) => (
                              <div key={i}>
                                {(oldDetail?.name || oldDetail?.phone?.phone || oldDetail?.email) && (
                                  <>
                                    {oldDetail?.salutation} {oldDetail?.name} {oldDetail?.phone?.phone} {oldDetail?.email}
                                  </>
                                )}
                              </div>
                            ))}
                        </>
                      ) : (
                          <div>
                            {typeof change?.old === "string" &&
                              (isValidJson(change.old) ? (
                                <JsonToHtml jsonData={JSON.parse(change.old)} />
                              ) : (
                                <span>{change.old}</span>
                              ))
                            }
                        </div>
                      )}
                    </td>
                    <td>
                      {module === "Trip" && change?.field === "passengers" ? (
                        <>
                          {change?.modified.length > 0 &&
                            JSON.parse(change.modified)?.map((modifiedDetail, i) => (
                              <div key={i}>
                                {(modifiedDetail?.name || modifiedDetail?.phone?.phone || modifiedDetail?.email) && (
                                  <>
                                    {modifiedDetail?.salutation} {modifiedDetail?.name} {modifiedDetail?.phone?.phone} {modifiedDetail?.email}
                                  </>
                                )}
                              </div>
                            ))}
                        </>
                      ) : (
                        <div>
                        {typeof change?.modified === "string" && 
                        (isValidJson(change.modified) ? (
                            <JsonToHtml jsonData={JSON.parse(change.modified)} />
                        ) : (
                            <span>{change.modified}</span>
                        ))
                    }
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ):
              <tr>
                <td colSpan="4" style={{ height: "100px" }}>
                  <h2 className="fs-5 d-flex justify-content-center align-items-center pe-5" >No Audits Yet</h2>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
    </>
  );
};

export default Audits;
