import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "reactstrap";
import { CardThreeBounce, CustomFieldComponent, Tab } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import OverView from "../Employee/Overview";
import Permit from "../Permit/Permit";
import { employeePermitConstants } from "./EmployeeConstant";
import EmployeeDetail from "./EmployeeDetail";
import EmployeeService from "./EmployeeService";
import { getYesterdayDateString } from "../../util/Util";
import BookingService from "../Booking/BookingService";

const EditEmployees = ({ editId, onChange = () => { }, hasErr }) => {

    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [step, setStep] = useState(1);
    const [trip, setTrip] = useState([]);


    const fetch = async () => {
        try {
            let employess = await EmployeeService.get(editId);
            const type = employess?.employeeOf ? "supplier" : "self";
            setData({
                ...employess,
                vehicleType: type,
                status: employess?.status ? employess?.status : 0,
                complianceStatus: employess?.complianceStatus ? employess?.complianceStatus : 0,
                phoneNo: {
                    ...employess.phoneNo,
                    countryCode: employess?.phoneNo?.countryCode ? employess?.phoneNo?.countryCode : 91,
                }
            })

            // Fetch trip data
            const yesterdayDate = getYesterdayDateString();
            const trip = await BookingService.grid(`driver._id=${editId}&rows=5&page=1&sortBy=tripDate&sortAsc=false&tripDate=gt[${yesterdayDate}]`);
            setTrip(trip);

        } catch (error) {
            showError(error);   
        }
        finally{
            setLoading(false);
        }
    };

    useEffect(() => {
        setData({
            status: 1,
            complianceStatus: 0,
            vehicleType:"self",
            phoneNo: {
                countryCode: 91,
                phone: ''
            }
        })
        // setData(null)
        setLoading(true);
        if (editId) fetch();
        setLoading(false)
    }, [editId, EmployeeService.version]);

    useEffect(() => {
        onChange(data)
    }, [data]);
    const handleChange = (value) => setData(value);

    return (
        <React.Fragment>
            {loading ?
                <CardThreeBounce
                    onChange
                /> :
                <Form
                    className="p-3 pt-0"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                >
                    {!data?._id ?
                        <div num={1} label="Details">
                            <EmployeeDetail
                                fetchData={fetch}
                                loading
                                data={data}
                                onChange={handleChange}
                            />
                        </div>
                        :
                        <Tab
                            vertical={false}
                            left
                            activeTab={step}
                            onChange={(data) => {
                                setStep(data);
                            }}
                            className="d-flex"
                            tabPaneClass="pt-0"
                        >
                            <div num={1} label="Overview">
                                <OverView
                                    hasErr={hasErr}
                                    data={data}
                                    setData={setData}
                                    employee_id={editId}
                                    trip={trip}
                                />
                            </div>
                            <div num={2} label="Details">
                                <EmployeeDetail fetchData={fetch} data={data} onChange={handleChange} />
                            </div>

                            <div num={3} label="Additional Info" className="d-flex">
                                {/* <CustomFieldComponent
                                    module={"employee"}
                                    record_id={data?._id}
                                    kind={CUSTOMEFIELDS_KIND_DATA?.Employee}
                                    propList={data?.employeeExtn?.properties}
                                    showGroup={"G1"}
                                    onChange={(v) =>
                                        setData({
                                            ...data,
                                            employeeExtn: {
                                                ...data?.employeeExtn,
                                                properties: v,
                                            },
                                        })
                                    }
                                /> */}

                                <CustomFieldComponent
                                    module={"employee"}
                                    record_id={data?._id}
                                    kind={"employee"}
                                    propList={data?.employeeExtn?.properties}
                                    onChange={(v) =>
                                        setData({
                                            ...data,
                                            employeeExtn: {
                                                ...data?.employeeExtn,
                                                properties: v,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div num={4} label='Permits' className="d-flex">
                                {editId && (
                                    <div label="Permit">
                                        <Permit permitNameData={employeePermitConstants} insidePane permitOf={editId} hideCheckBox={true} assetKind="employee" />
                                    </div>
                                )}
                            </div>
                        </Tab>
                    }
                </Form>
            }
        </React.Fragment>
    );
};

export default observer(EditEmployees);
