import React, { useEffect, useMemo, useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    UncontrolledDropdown,
} from 'reactstrap';
import './styles.css'

function PaymentReceivedDropDown({ data,value, isDisabled = false, onChange = () => { } }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

   const totalExcessAmount=useMemo(()=>data?.reduce((acc,curr)=>(acc+curr?.excessAmount??0),0),[data])

    const handleItemClick = (selectedData) => {onChange(selectedData); };

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const formatDate = (dateInt) => {
        if (!dateInt) return '';
        const dateString = dateInt.toString();
        if (typeof dateString !== 'string' || dateString.length !== 8) return dateString;
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
        return `${day}-${month}-${year}`;
    };

    return (
        <div className="trip-type-menu payment-menu">
            <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                <DropdownToggle
                    outline
                    color="secondary"
                    className="d-flex w-100 align-items-center"
                >
                    <div className="d-flex flex-1">
                        <b>Pending Excess Amount</b>: {((totalExcessAmount / 100) ?? 0).toFixed(2)}
                    </div>
                </DropdownToggle>

                <DropdownMenu className="shadow payment_dropDown">
                {data?.filter(singleData => singleData?.excessAmount !== 0)?.map((singleData, index) => (
                     <> <DropdownItem
                            key={index}
                            className="payment-dropdown-item"
                            onClick={() => handleItemClick(singleData)}
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', whiteSpace: 'normal', wordWrap: 'break-word' }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className='payment-dropdown-bullet'></div>
                                <div className='ms-2'>Date: <b>{singleData?.date ? formatDate(singleData?.date) : "--"}</b></div>
                                <div className='ms-2'>Amount: <b>{singleData?.amountReceived / 100}</b></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                               
                                <div>Transaction No.: <b>{singleData?.transactionRef ?? "--"}</b></div>
                                <div>Excess Amount: <b>{singleData?.excessAmount / 100}</b></div>
                            </div>
                        </DropdownItem>
                         <div class="dropdown-divider"></div>
                         </> 

                    ))}

                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
}

export default PaymentReceivedDropDown