import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { InputField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import LanguageService from "./LanguageService";
import FileUpload from "../../components/DocUpload/FileUpload";



const EditLanguages = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const { showError } = useContext(DialogContext);
  const [data, setData] = useState(null);

  const fetch = async () => {
    try {
      let states = await LanguageService.get(editId);
      setData(states);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(null)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId]);

  useEffect(() => {
    onChange(data)
  }, [data]);

  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="mt-3 mb-3">
            <InputField
              placeholder={t("Name")}
              value={data?.name}
              required={true}
              label={t("Name")}
              onChange={(v) => setData({ ...data, name: v })}
              error={data?.name?.length === 0 ? t("Please enter Language Name") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-6"
            />
          </Row>
          <Row className="mt-3 mb-3">
            <InputField
              placeholder={t("Code")}
              value={data?.code}
              label={t("Code")}
              onChange={(v) => setData({ ...data, code: v })}
              error={data?.code?.length === 0 ? t("Please enter Language Code") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-6"
            />
          </Row>
          <Row className="mt-3 mb-3">

            <InputField
              placeholder={t("Label")}
              value={data?.label}
              label={t("Label")}
              onChange={(v) => setData({ ...data, label: v })}
              error={data?.label?.length === 0 ? t("Please enter Language Label") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-6"
            />
          </Row>
          <div className="mt-5" />
          <FileUpload
            type="image"
            value={data?.pic}
            id={data?._id}
            module={"country"}
            field={"pic"}
            onUpload={() => {
              fetch()
            }}
          />
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditLanguages);


