import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Tooltip } from "react-tooltip";
import { FaPhoneAlt } from 'react-icons/fa'; // Ensure FaPhoneAlt is imported
import { I18nContext } from "../../store/context/i18nContext";
import "./style.scss"
const ContactPersonTooltip = ({ contactPersons, toolTipId }) => {
    const { t } = useContext(I18nContext);

    const formatPhoneNumber = (countryCode, phone) => {
        return `+${countryCode} ${phone}`;
    };

    return (
        <React.Fragment>
            <Tooltip
                style={{
                    zIndex: '200000', backgroundColor: "#fff", color: "#000", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '10px', padding: '10px',
                    opacity: "1 !important",
                }}
                className="opaque-tooltip"
                anchorSelect={`#not-clickable-${toolTipId}-cp`}
                place="bottom"
            >
                {contactPersons?.map((contactPerson, index) => (
                    <div key={index} style={{ marginBottom: contactPersons.length - 1 === index ? 0 : 10 }}>
                        {index > 0 && <hr className="my-0 mb-1 " style={{ color: 'black' }} />}
                        <div style={{ fontWeight: 'bold' }}>{contactPerson.salutation ? `${t(contactPerson.salutation)} ${contactPerson.name}` : contactPerson.name}</div>
                        <div >{t(contactPerson.designation)}</div>
                        <div style={{ fontSize: '10px', color: 'gray' }}>{contactPerson.email}</div>
                        <div style={{ fontSize: '10px', color: 'gray' }}>{formatPhoneNumber(contactPerson.phone.countryCode, contactPerson.phone.phone)}</div>
                    </div>
                ))}
            </Tooltip>

            <FaPhoneAlt id={`not-clickable-${toolTipId}-cp`} size={14} color={"green"} />

        </React.Fragment>
    );
};

export default observer(ContactPersonTooltip);
