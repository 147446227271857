import React, { useContext } from 'react'
import { Col, Row } from 'reactstrap'
import { SelectField } from '../../../components'
import { I18nContext } from '../../../store/context/i18nContext';

const ClientStep2 = ({ data, setData }) => {
    const { t } = useContext(I18nContext);
    return (
        <>
            <Row className=''>
                <Col className=' px-4'>
                    <SelectField
                        label={t("Select no of Employess")}
                        data={[
                            { label: "1-10", value: "10" },
                            { label: "10-100", value: "100" },
                            { label: "100-500", value: "1000" },
                        ]}
                        value={data?.employeeCount}
                        onChange={(v) => setData({ ...data, employeeCount: v?.value })}
                    />
                </Col>
            </Row>

        </>
    )
}

export default ClientStep2