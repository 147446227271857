import { toJS } from "mobx";
import { useEffect, useState } from "react";

import { SelectField } from "../../SelectField/SelectField";
import TaxSlabSelectService from "./TaxSlabSelectService";

export default function TaxSlabSelect(props) {
  const [taxslab, setTaxslab] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        await TaxSlabSelectService.fetch();
        setTaxslab(toJS(TaxSlabSelectService.TaxSlab));
      } catch (error) {
        console.log(error.message);
      }
    };
    fetch();
  }, []);

  return taxslab.length > 0 ? (
    <SelectField
      className={props?.className}
      onChange={props?.onChange}
      data={taxslab}

      value={props?.value}
      label={"Tax Slabs"}
      error={!props?.value ? "Please Select Tax Slab" : ""}
      showErr={props?.showErr}
      inline={props?.inline}
      borderNone={props?.borderNone}
      RightComponent={props?.RightComponent}
      onClickRight={props?.onClickRight}
      {...props}
    />
  ) : (
    <SelectField
      className={props?.className}
      onChange={props?.onChange}
      data={taxslab}

      value={props?.value}
      label={"Tax Slab"}
      error={!props?.value ? "Please Select Tax Slab" : ""}
      isDisabled={true}
      showErr={props?.showErr}
      inline={props?.inline}
      borderNone={props?.borderNone}
      RightComponent={props?.RightComponent}
      onClickRight={props?.onClickRight}
      {...props}
    />
  );
}
