import React, { useEffect, useRef, useState } from 'react';
import NumberInput from '../../../../../../pages/Itinerary/components/NumberInput';
import { useOutsideAlerter } from '../../../../board/helper';
import './index.scss';

const Counter = ({
    value,
    onChange,
    label
}) => {


    return (
        <div className="zr_daynightpicker_wrapper" >
            <div
                className="zr_daynightpicker_display"
            >

                <NumberInput
                    label={label ?? "Persons"}
                    value={value}
                    min={1}
                    onChange={(v) => onChange(v)}
                />
            </div>

        </div>
    );
};

export default Counter;
