import { makeAutoObservable } from "mobx";
import { doGET, doPOST } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../Constant";

class TagSelect {
  tags = [];
  constructor() {
    makeAutoObservable(this);
  }
  fetch = async function () {
    try {
      const response = await doGET(ENDPOINTS.getALLTags);
      if (response.status === 200) {
        this.tags = response.data?.map((v) => {
          return { label: v?.name, value: v?.name };
        });
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };
  setTags = async function (data) {
    this.tags = data;
  };
  delete = async function (id) {
    const response = await doGET(ENDPOINTS.deletetag(id))
    if (response.status === 200) {
      this.fetch();
    }
  };
  save = async function (data) {
    await doPOST(ENDPOINTS.saveNew, data)
    this.fetch();
  };
}

const TagSelectService = new TagSelect();
export default TagSelectService;