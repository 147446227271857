import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import { I18nContext } from "../../store/context/i18nContext";
import RfpSubmitRate from "./RfpSubmitRate";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { MARKETPLACE_BASE_URL } from "./MarketPlaceConstant";
import axios from "axios";
import { useParams } from "react-router-dom";


const RfpDetails = (props) => {


    const { id } = useParams()
    const [showSubmit, setShowSubmit] = useState(false)

    const { showError } = useContext(DialogContext);
    const [details, setDetails] = useState({})
    const getRfpDetails = async (filterUrl) => {

        try {
            const response = await axios.get(`${MARKETPLACE_BASE_URL}/get/rfp/${id}`)
            setDetails(response?.data?.data)

        } catch (e) {
            showError(e?.message);
        }

    };

    useEffect(() => {
        getRfpDetails()
    }, [])

    return (
        <>
            <div style={{ marginTop: "70px" }}>
                <Card className=' mt-3 m-auto br-20 box-shadow py-4' style={{ width: "98%" }}>
                    <Col>
                        <h1 className="d-flex px-4">
                            <strong>
                                RFP # {id}
                            </strong>
                        </h1>
                    </Col>
                    <Col>
                        <strong className="d-flex px-4">
                            <h3>
                                {details?.status === "Open" ? <Row className="px-2">
                                    <Col className="col-sm-1" style={{ height: "20px", width: "20px", background: "#DF6463", borderRadius: "30px" }}>
                                    </Col>
                                    <Col>
                                        Bid Now
                                    </Col>
                                </Row> : ""}
                            </h3>
                        </strong>
                    </Col>
                </Card>

                <Card className=' mt-3 m-auto br-20 box-shadow py-4' style={{ width: "98%" }}>
                    <Row>
                        <Col className="col-sm-6 " style={{ borderRight: "1px solid black" }}>
                            <Row className="mt-3 px-4">
                                <h2 style={{ fontSize: "20px" }}>
                                    <strong>
                                        Company Details
                                    </strong>
                                </h2>
                            </Row>
                            <div style={{ textAlign: "center", fontSize: "17px" }}>
                                <div className=" d-flex justify-content-around mt-3">
                                    <div className=" fw-lighter fa-15" style={{ marginLeft: "30px" }}><span className=" px-3"><i className="fas fa-building"></i></span>Company Name</div>
                                    <div className=" fw-bold fa-15">{details?.companyName}</div>
                                </div>
                                <div className=" d-flex justify-content-around mt-3">
                                    <div className=" fw-lighter fa-15" ><span className=" px-3"><i className="uil-location-point"></i></span>Office Address</div>
                                    <div className=" fw-bold fa-15">{details?.address}</div>
                                </div>
                                <div className=" d-flex justify-content-around mt-3">
                                    <div className=" fw-lighter fa-15" ><span className=" px-3"><i className="uil uil-user me-1"></i></span>Contact Person</div>
                                    <div className=" fw-bold fa-15">{details?.contactPersons ? details?.contactPersons[0]?.name : ""}</div>
                                </div>
                                <div className=" d-flex justify-content-around mt-3" >
                                    <div className=" fw-lighter fa-15" ><span className=" px-3"><i className="uil uil-envelope-alt me-1"></i></span>Emai Address</div>
                                    <div className=" fw-bold fa-15">{details?.contactPersons ? details?.contactPersons[0]?.email : ""}</div>
                                </div>
                                <div className=" d-flex justify-content-around mt-3 ">
                                    <div className=" fw-lighter fa-15" ><span className="px-3"><i className="uil uil-phone me-1"></i></span>Phone</div>
                                    <div className=" fw-bold fa-15">{details?.contactPersons ? details?.contactPersons[0]?.phone : ""}</div>
                                </div>
                            </div>

                        </Col>
                        <Col className="col-sm-6">
                            <Row>
                                <h2 style={{ padding: "15px 0px 0px 50px" }}>
                                    <strong>
                                        Requirements
                                    </strong>
                                </h2>
                                <div style={{ textAlign: "center", fontSize: "17px" }}>
                                    <Row className=" d-flex justify-content-between mt-3">
                                        <Col className="col-sm-4  fw-lighter fa-15">Pickup City</Col>
                                        <Col className="col-sm-8 fw-bold fa-15">{details?.pickUp ? details?.pickUp[0].cities.map((ele, i) => ele + " , ") : ""}</Col>
                                        <Col className="col-sm-4 mt-3 fw-lighter fa-15">Drop Off City</Col>
                                        <Col className="col-sm-8 fw-bold fa-10 mt-3">{details?.dropOf ? details?.dropOf[0].cities.map((ele, i) => ele + " , ") : ""}</Col>
                                        <Col className="col-sm-4 mt-3 fw-lighter fa-15">Pickup Time</Col>
                                        <Col className="col-sm-8 fw-bold fa-15 mt-3">{details?.pickupTime}</Col>
                                        <Col className="col-sm-4 mt-3 fw-lighter fa-15">No of Pax</Col>
                                        <Col className="col-sm-8 fw-bold fa-15 mt-3">{details?.totalpax}</Col>
                                        <Col className="col-sm-4 mt-3 fw-lighter fa-15">User Type</Col>
                                        <Col className="col-sm-8 fw-bold fa-15 mt-3">{details?.travelType}</Col>
                                        <Col className="col-sm-4 mt-3 fw-lighter fa-15">Vehicle Type</Col>
                                        <Col className="col-sm-8 fw-bold fa-15 mt-3">{details?.vehicleType}</Col>
                                        <Col className="col-sm-4 mt-3 fw-lighter fa-15">Service Type</Col>
                                        <Col className="col-sm-8 fw-bold fa-15 mt-3">{details?.serviceType}</Col>
                                    </Row>

                                </div>

                            </Row>

                        </Col>
                    </Row>





                </Card>

            </div>

            {showSubmit === false ?
                <div className="d-flex justify-content-center m-auto">
                    <button className='bid-btn mb-4 mt-5 m-auto' onClick={() => setShowSubmit(true)}><span>Participate</span></button>
                </div> : null}
            <div className=" mb-5 mt-3 ">
                {showSubmit ?
                    <div className=" mt-4 mb-5">

                        <RfpSubmitRate />



                    </div> : null}


            </div>

        </>
    );
};

export default observer(RfpDetails);
