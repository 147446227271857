import React from 'react';
import { Button } from 'reactstrap'; // Make sure to import Button from your UI library
import { ThreeBounce } from 'better-react-spinkit';
import { HiOutlineSave } from "react-icons/hi";
import { TbCloudUpload } from "react-icons/tb";

export default function SaveButton({ title = "Save", onlyIcon = false, zeroXMargin, key = 12, onClick, disabled, loading }) {

  if (loading) {
    return (
      <div key={key} className="d-flex justify-content-end my-2">
        {onlyIcon ? <ThreeBounce size={8} color="#6649f5" /> : <Button
          className="mx-2"
          style={{ height: "28px", padding: "2px 4px", width: "90px" }}
          color="success"
        >
          <ThreeBounce size={10} color="#FFFFFF" />
        </Button>}
      </div>
    );
  }
  return (
    <div className="d-flex justify-content-end my-2">
      {onlyIcon ? <div
        onClick={onClick}
        className="mx-2"
      >
        <TbCloudUpload size={24} />
      </div> :
        <Button
          onClick={onClick}
          className="d-flex gap-2 align-items-center btn"
          disabled={disabled}
          style={{ height: "30px" }}
          color="success"
        >
          <TbCloudUpload size={20} />
          <div style={{ margin: "2px", float: "right" }}>{title}</div>
        </Button>
      }
    </div>
  );
}
