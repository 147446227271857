import React, { useState, useContext } from "react";
import { SelectField } from "../../SelectField/SelectField";
import { I18nContext } from "../../../store/context/i18nContext";



export default function StatusSelect({ className, onChange, showClear, value, showErr, label }) {
    const { t } = useContext(I18nContext);

    const data = [
        { label: t("Open"), value: 0 },
        { label: t("Partially Paid"), value: 1 },
        { label: t("Paid"), value: 2 },
    ];

    return (
        <React.Fragment>
            <SelectField
                className={className}
                onChange={onChange}
                data={data}
                showClear={showClear}
                value={value}
                label={label}
                error={value ? "Please Select Kind" : ""}
                showErr={showErr}
            />
        </React.Fragment>
    );
}
