import { SelectField } from "../../components";
import DocumentCategoryService from "./DocumentCategoryService";

export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Module",
            accessor: "module",
            show: true,
            required: true,
        },
        {
            title: "Name",
            accessor: "name",
            show: true,
            required: true,
        },
        {
          title: "Parent Category",
          accessor: "parent_id",
          renderer: (dataObj) => {
            return dataObj?.parent_id ? (
              <SelectField
                asLabel
                data={DocumentCategoryService?.parentCategories}
                placeholder="Category"
                value={dataObj?.parent_id}
                label="Category"
                type="text"
                className="my-3 col-12"
              />
            ) : "--";
          },
          show: true,
        },                
        {
          title: "Action",
          accessor: "action",
          fixed: "right",
          width: 90,
          show: true,
          required: true,
        },
      ],
};
