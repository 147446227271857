import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Button, Table } from 'reactstrap';
import SupplierSelect from '../../../../../Dropdowns/Supplier/SupplierSelect';
import CoveragePicker from '../CoveragePicker';
import SupplierStatusPicker from '../SupplierStatusPicker';
import SupplierFareDetail from './SupplierFareDetail';
import SupplierFareService from './SupplierFareService';
import './index.scss';
import Checkbox from '../../../../../../pages/Offers/checkbox';

const SupplierFare = ({ itinerary_id, value, onChange, }) => {

    const [loading, setLoading] = useState(false);
    const [selectedSupplierFares, setSelectedSupplierFares] = useState([]);
    const [showSupplierFareDetail, setShowSupplierFareDetail] = useState({ status: false });

    useEffect(() => {
        const fetchSupplierFares = async () => {
            setLoading(true);
            try {
                await SupplierFareService.fetch(itinerary_id);
            } catch (error) {
                console.error("Failed to fetch itineraries:", error);
            } finally {
                setLoading(false);
            }
        };

        if (itinerary_id) {
            fetchSupplierFares();
        }
    }, [itinerary_id]);

    const handleSelectSupplierFare = (supplierFareId, isChecked) => {
        setSelectedSupplierFares(currentSelected => {
            if (isChecked) {
                return [...currentSelected, supplierFareId];
            } else {
                return currentSelected.filter(id => id !== supplierFareId);
            }
        });
    };

    const calculateTotalPrice = () => {
        return selectedSupplierFares.reduce((total, id) => {
            const supplierFare = SupplierFareService.records.find(record => record._id === id);
            return total + (supplierFare?.charges?.total || 0);
        }, 0) / 100; // Assuming the price is in cents
    };

    if (loading) {
        return <div>Loading...</div>;
    }



    return (
        <div>

            {SupplierFareService?.records?.length ?
                <>

                    <Table className="table-borderless table-centered table-nowrap">
                        <tbody>
                            <tr  style={{borderBottom : "1px solid #EBEBEB"}}>
                                <td>Select</td> {/* Checkbox header */}
                                <td>Supplier</td>
                                <td>Coverages</td>
                                <td>Status</td>
                                <td className=" text-end ">Price</td>
                                <td className="text-muted fw-semibold text-end"></td>
                            </tr>
    
                            {SupplierFareService?.records.map((supplierFare, index) => (
                                <tr key = {index}>

                                    <td>

                                        <Checkbox
                                        noLabel
                                            // label={t("Completed")}
                                            checked={selectedSupplierFares.includes(supplierFare._id)}
                                            className="col-sm-4"
                                            onClick={(e) => handleSelectSupplierFare(supplierFare._id, !selectedSupplierFares.includes(supplierFare._id))}
                                        />

                                    </td>


                                    <td>
                                        <h6 className="font-size-15 mb-1 fw-normal">     <SupplierSelect
                                            value={supplierFare?.supplier_id}
                                            onChange={() => { }}
                                            asLabel
                                        /></h6>
                             
                                    </td>

                                    <td>
                                        <CoveragePicker
                                            value={supplierFare?.coverage}
                                            onChange={(v) => {

                                            }}
                                            noToggle
                                        />
                                    </td>
                                    <td>

                                        <SupplierStatusPicker
                                            value={supplierFare?.status}
                                            onChange={(v) => {

                                            }}
                                            noToggle
                                        />
                                        {/* <span className="badge bg-soft-danger font-size-12">Cancel</span> */}
                                    </td>

                                    <td className="text-muted fw-semibold text-end h4">
                          
                                        Rs. {supplierFare?.charges?.total ? supplierFare?.charges?.total / 100 : 0}
                                    </td>

                                    <td className="text-muted text-end">
                                    <div onClick={() => { setShowSupplierFareDetail({ id: supplierFare?._id, status: true }) }}>
                                            <i className="uil uil-pen font-size-18" />
                                    </div>
                                    </td>


                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <div className="font-size-14 ">
                        Total Price for Selected Rates:  <b>Rs. {calculateTotalPrice().toFixed(2)}  </b>
                    </div>
                    
                </> :
                <>
                    <div>No Suppliers Rates found for this itinerary.</div>
                </>
            }

            {/* 
            <SupplierFareDetailCompact
                itinerary_id={itinerary_id}
                editId={null}
                isOpen={createMode}
                toggleModal={() => { setCreateMode(false) }}
            /> */}

            <div className='d-flex justify-content-end mt-2'>
                <Button onClick={() => {
                    setShowSupplierFareDetail({ status: true })
                }} className="d-flex align-items-center py-1">
                    <FaPlus className='me-2' />  Supplier charges
                </Button>
            </div>


            <SupplierFareDetail
                itinerary_id={itinerary_id}
                isOpen={showSupplierFareDetail?.status}
                editId={showSupplierFareDetail?.id}
                toggleModal={() => { setShowSupplierFareDetail({ status: false }) }}
            />
        </div>
    );
};

export default observer(SupplierFare);
