import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ThreeBounce } from "better-react-spinkit";
import EmployeeSelectService from './EmployeeSelectService';

const EmployeeLabel = ({ value }) => {
  const [employeeName, setemployeeName] = useState('');
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchEmployeeName = async (value) => {
      setLoading(true);
      try {
        const employee_name = await  EmployeeSelectService.fetchEmployeeNameFromEmployeeId(value);
        if (employee_name) {
            setemployeeName(employee_name.label);
        } else {
            setemployeeName('No Record Found');
        }
      } catch (error) {
        setemployeeName('No Record Found');
      }
      setLoading(false);
    };

    if (value) {
        fetchEmployeeName(value);
    } else {
        setemployeeName('No Record Found');
      setLoading(false);
    }
  }, [value]);

  if (loading) {
    return <ThreeBounce size={10} color="pink" className="zr_category-loader" />;
  }

  return (
    <div className={`zr_category-label ${employeeName === 'No Record Found' ? 'disabled' : ''}`}>
      {employeeName}
    </div>
  );
};

export default observer(EmployeeLabel);
