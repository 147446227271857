import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "./StateSelectConstant";

class StateSelect {
  states = {};
  constructor() {
    makeAutoObservable(this);
  }
  fetch = async function (country) {
    if (country) {
      if (this.states[country]) return;
      try {
        const response = await doGET(ENDPOINTS.getStatesByCountry(country));
        if (response.status === 200) {
          this.states = {
            ...this.states,
            [country]: Object.values(response.data.rows).map((a) => {
              return { label: a.name, value: a.name };
            }),
          };
        } else {
          this.error = response.data;
        }
      } catch (err) {
        this.error = err;
      }
    }
  };
}

const StateSelectService = new StateSelect();
export default StateSelectService;
