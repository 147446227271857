import React, { useContext, useState } from 'react'
import DeleteButton from '../Buttons/DeleteButton';
import { Button, DropdownItem, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { UserContext } from '../../store/context/UserContext';
import { ThreeBounce } from 'better-react-spinkit';
import { ImHammer2 } from 'react-icons/im';
import BookingFooterDropdown from '../../pages/Booking/components/BookingFooterDropdown';
import PrintButton from '../PrintButton/PrintButton';
import AuditLogDialog from '../../pages/Booking/components/Audit/AuditLog';
import CommentService from '../../pages/Comment/CommentService';
import { I18nContext } from '../../store/context/i18nContext';
import useBookingManagement from '../../hooks/useBookingManagement';
import TagPickerService from '../TagSelector/TagPickerService';
import { getRandomColor } from '../TagSelector/TagPicker';
import BookingService from '../../pages/Booking/BookingService';
import { DialogContext } from '../../store/context/DialogContext';
import { useNavigate } from 'react-router-dom';
import SaveButton from '../Buttons/SaveButton';
import MessageDialog from '../../pages/Invoice/Component/MessageDialog';
import ModalComponent from '../Modal/Modal';
import Feedback from '../../pages/Feedbacks/Feedback';
import InvoiceForm from '../../pages/Invoice/InvoiceForm';
import { observer } from 'mobx-react-lite';



function BookingFooter({ editID, booking, customerType, onSave, closeDuty, onDelete, fromInvoice }) {
    const [loading, setLoading] = useState(false);
    const { userContent } = useContext(UserContext);
    const navigate = useNavigate();
    const { showMessage, showError, showConfirm } = useContext(DialogContext);
    const { t } = useContext(I18nContext);
    const [modalForTripInvoice, setModalForTripInvoice] = useState(false);
    //modals
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);
    const isCloning = () => queryParams.has('clone_trip_id')

    const showInvoiceCreate = () => {
        return booking != null
            && booking._id
            && booking.status
            && [1, 2, 3, 4, 5, 6, 19, 20, 29, 30]?.includes(booking?.status) && !isCloning()
    }
    const showDeleteButton = () => [0, 1, 2, 3]?.includes(booking?.status) && !isCloning()

    const toggleSingleTripModalForInvoice = () => {
        if (booking && editID) {
            if (booking.clientInvoice_id) {
                window.open(`/invoice/edit/${booking.clientInvoice_id}`, "_blank");
            } else {
                setModalForTripInvoice(prev => !prev);
            }
        }
    }

    const onDutyClose = async () => {
        if (loading) return;
        closeDuty();
        // if (booking?.status === 5) {
        //     closeDuty()
        //     return;
        // }

        // if (
        //     await showConfirm({
        //         title: t("Close Duty"),
        //         description: t("Do you want to close this duty?"),
        //     })
        // ) {

        //     try {
        //         closeDuty()
        //     } catch (e) {
        //         showError(e);
        //     }
        // }
        // return;
    }

    return (
        <div className='zr_layout-footer d-flex justify-content-end pe-4' style={{ flexShrink: 0 }}>
            {editID && !fromInvoice && showDeleteButton() ? (
                <DeleteButton
                    loading={loading || BookingService.isBillingCalculationinProgress()}
                    onClick={onDelete}
                />) : null}
            <div className="d-flex align-items-center">
                {showInvoiceCreate() && !fromInvoice ?
                    <div className="d-flex justify-content-end my-2">
                        <Button
                            onClick={toggleSingleTripModalForInvoice}
                            className="mx-2"
                            style={{ height: "28px", padding: "2px 10px 2px 4px" }}
                            color="info"
                        >
                            <div className="d-flex justify-content-center" style={{ margin: "2px" }}>{t(`${!booking?.clientInvoice_id ? "Create Invoice" : "Show Invoice "}`)}</div>
                        </Button>
                    </div> : ""}

                {
                    ([1, 2, 3, 4, 5]?.includes(booking?.status) && !queryParams.has('clone_trip_id') && userContent?.rights?.includes(6002) && !booking?.clientInvoice_id) &&
                    <Button
                        className="mx-2 px-2"
                        style={{ padding: "3px 10px 3px 4px", }}
                        color="success"
                        onClick={onDutyClose}>
                        <div className="d-flex aling-items-center">
                            {
                                loading ? <ThreeBounce size={10} color="#FFFFFF" className="px-4" /> : <>
                                    <ImHammer2 className="flip-horizontal" />
                                    <div className="ms-2 d-flex justify-content-center">{t(`Duty Close`)}</div>

                                </>
                            }
                        </div>
                    </Button>
                }
                {userContent?.rights?.includes(6002) && (((!booking?.clientInvoice_id && !isCloning() || isCloning())) || fromInvoice) && <SaveButton disabled={(booking?.clientInvoice_id && !isCloning() && !fromInvoice) ? true : false} loading={loading || BookingService.isBillingCalculationinProgress()} onClick={(e) => onSave(e, booking, customerType)} title={isCloning() ? "Clone" : "Save"} />}
            </div>
            {modalForTripInvoice && showInvoiceCreate() && (
                <Modal
                    isOpen={modalForTripInvoice}
                    toggle={toggleSingleTripModalForInvoice}
                    size="xl"
                >
                    <ModalHeader toggle={toggleSingleTripModalForInvoice}>
                        Create Invoice
                    </ModalHeader>
                    <ModalBody>
                        <InvoiceForm
                            trip_ids={[editID]}
                            //  validateFeegroup,
                            onSave={(invoicedTripIds) => {
                                // updateBooking(invoicedTripIds);
                            }}
                            concise
                            toggleModal={toggleSingleTripModalForInvoice}
                        />
                    </ModalBody>
                    <div>
                        {/* <SaveButton loading={loading} onClick={handleInvoiceCreate} /> */}
                    </div>
                </Modal>
            )}

        </div>
    )
}

export default observer(BookingFooter);