import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu, Alert } from "reactstrap";
import { Button } from 'react-bootstrap'
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditRateAreas from "./EditRateArea";
import { STRUCTURE } from "./RateAreaConstant";
import { GridColumns } from "./RateAreaGridColumns";
import RateAreaService from "./RateAreaService";
import { UserContext } from "../../store/context/UserContext";
import Rateservice from "../Rate/RateService";
import SetupProgressService from "../Dashboard/SetUpProgressService";


const RateAreas = ({ value, insidePane, multiMode, onSelect = () => { } }) => {

    let { edit_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const { userContent } = useContext(UserContext);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(edit_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/rate-area/create" || edit_id);
    const [showRateArea, setShowRateArea] = useState(null);
    const [showRateName, setShowRateName] = useState(null);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await RateAreaService.fetch(filterUrl);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };



    const onSave = async (e) => {
        e.preventDefault();
        // if (!detailData.name) {
        //   showError(t("Please enter all fields"));
        //   return;
        // }
        setLoading(true);
        try {
            if (editID) {
                await RateAreaService.edit(detailData, editID);
                // setShowDetailPage(false);
                showMessage(t("Rate Area updated successfully."));
            } else {
                const rateArea = await RateAreaService.save(detailData);
                if (rateArea) {
                    setEditID(rateArea)
                }
                // setShowDetailPage(false);
                showMessage(t("Rate Area saved successfully."));
                if (!insidePane) navigate(`/rate-area/edit/${rateArea}`);
            }
            fetchData(filterURL);
            await RateAreaService.fetchAll();
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await RateAreaService.delete(id);
                setLoading(false);
                setShowDetailPage(false);
                showMessage(t("Rate Area Deleted"), t("Deleted"));
            } catch (e) {
                showError(e);
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }, [])

    useEffect(() => {
        setEditID(edit_id)
    }, [edit_id])


    const renderLastCol = useCallback((rateArea) => {
        return (
            <>
                <EditLink
                    onClick={() => {
                        if (!insidePane)
                            navigate(`/rate-area/edit/${rateArea?._id}`);
                        setShowDetailPage(true);
                        setEditID(rateArea?._id);

                    }}
                />
                {userContent?.rights?.includes(1903) ?
                    <DeleteLink
                        onClick={(event) => onDelete(event, rateArea?._id)}
                    />
                    : null}
                {!multiMode && insidePane ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(rateArea?._id);
                        }}
                    />
                ) : null}
            </>
        );
    }, [insidePane, multiMode])

    const toggleData = {
        module: [
            { label: t("Client Rates"), value: "forClient" },
            { label: t("Supplier Rates"), value: "forSupplier" },
            { label: t("Rate Name"), value: "rateName" },
            { label: t("Rate Area"), value: "rateArea" },
        ],
        toggleValue: "rateArea"
    }

    const markCompletedFunc = async () => {
        try {
            await SetupProgressService?.edit("rateArea");
        } catch (error) {

        }
    }

    useEffect(() => {
        if (!SetupProgressService?.records) {
            SetupProgressService.fetch()
        }
    }
        , [SetupProgressService?.records])


    return (
        <>
            <Layout
                medium
                compact
                hideAdd={!userContent?.rights?.includes(1901)}
                showToggle
                toggleData={toggleData}
                onToggle={(selectedValue) => {
                    if (selectedValue === "rateName") {
                        navigate(`/rateName`);
                    } else if (selectedValue === "rateArea") {
                        navigate(`/rate-area`);
                    } else if (selectedValue === "forClient") {
                        Rateservice.onSupplierChange(false);
                        navigate("/rate");
                    } else if (selectedValue === "forSupplier") {
                        Rateservice.onSupplierChange(true);
                        navigate("/rate");
                    }
                }}
                showRateArea={showRateArea}
                setShowRateArea={setShowRateArea}
                showRateName={showRateName}
                setShowRateName={setShowRateName}
                showDetailPage={showDetailPage}
                backDetailPage={() => {
                    setShowDetailPage(false);
                    if (!insidePane) navigate("/rate-area");
                    setEditID(null);
                }}
                 
                // title={t("Rate Areas")}
                filterValues={filterObject}
                filterStructure={STRUCTURE}
                onApplyFilter={fetchData}
                onAddClick={() => {
                    if (!insidePane) navigate(`/rate-area/create`);
                    setShowDetailPage(true);
                    setEditID(null);
                }}
                insidePane={insidePane}
                page={RateAreaService.page}
                rows={RateAreaService.rows}
                total={RateAreaService.total}
                fetch={RateAreaService.fetch}
            >

                <Layout.OnAlert>
                    {!SetupProgressService?.records?.setUpStatus?.['rateArea'] ? <Alert color="danger" role="alert">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                Please create Rate Areas here. Rate Areas are the regions where a specific rate package is applicable. For eg: Delhi NCR, PAN India, North East, Maharastra excluding Mumbai etc
                            </div>
                            <div>
                                <Button onClick={markCompletedFunc}>Mark Complete</Button>
                            </div>
                        </div>          </Alert> : <></>}
                </Layout.OnAlert>
                <Layout.ActionMenu>
                    <div className="layout-action-menu">
                        <DropdownMenu>
                            <>
                                {userContent?.rights?.includes(1903) ?
                                <>
                                    <DropdownItem>Bulk Delete</DropdownItem>
                                    <div class="dropdown-divider"></div>
                                    </>
                                    : null}
                                <DropdownItem>{t("Upload")}</DropdownItem>
                                <div class="dropdown-divider"></div>
                                <DropdownItem> Download </DropdownItem>
                            </>

                        </DropdownMenu>

                    </div>
                </Layout.ActionMenu>
                <Layout.Table>
                    <DataGrid
              gridLoading={loading}
                        data={RateAreaService.records}
                        total={RateAreaService.total}
                        uiPreference="rateArea.grid"
                        headers={GridColumns}
                        selectedIDs={selectedIDs}
                        onSelectChange={(v) => {
                            onSelect(v)
                            setSelectedIDs(v)
                        }}
                        page={RateAreaService.page}
                        rowsPerPage={RateAreaService.rowsPerPage}
                        onPaginationChange={RateAreaService.onPaginationChange}
                        renderLastCol={renderLastCol}
                    />
                </Layout.Table>
                <Layout.DetailPageTitle>
                    {detailData?._id ? t("Edit Rate Area") : t("Add Rate Area")}
                </Layout.DetailPageTitle>

                <Layout.DetailPageBody>
                    <EditRateAreas
                        editId={editID}
                        onChange={(v) => {
                            setDetailData(v)
                        }}
                    />
                </Layout.DetailPageBody>

                <Layout.DetailPageFooter>
                    {userContent?.rights?.includes(1903) && editID ?
                        <DeleteButton loading={loading} onClick={(e) => onDelete(e, detailData?._id)} />
                        : null}
                    {userContent?.rights?.includes(1902) ?
                        <SaveButton loading={loading} onClick={onSave} />
                        : null}
                </Layout.DetailPageFooter>
            </Layout>
        </>
    );
};
export default observer(RateAreas);




