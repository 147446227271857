import { OfficeSelect } from "../../components";
export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Brand Name",
      accessor: "name",
      show: true,
    },
    {
      title: "Full Name",
      accessor: "fullName",
      show: true,
    },
    {
      title: "Time Zone",
      accessor: "timezone",
      show: true,
    },
    {
      title: "Currency",
      accessor: "currency",
      show: true,
    },
    {
      title: "Tax/Gst ID",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.companyExtn?.taxId}
        </div>;
      },
      show: true,
    },
    {
      title: "Office",
      accessor: "office_id",
      renderer: (dataObj) => {
        return (
          <div>
            <OfficeSelect
              asLabel
              corp_id={dataObj?.corp_id}
              value={dataObj?.office_id}
              onChange={(v) => { }}
              className=""
            />
          </div>
        );
      },
      show: true,
    },

    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
