import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "toastr/build/toastr.min.css";
import { CardThreeBounce } from "../../../../components";
import "../../../../components/SuperResponsiveTableStyle.css";
import ConfigComponent from '../../../../components/Config/Config';

const Config = () => {
    const [loading] = useState(false);
    const [version, setVersion] = useState()
    const setNewVersion = React.useCallback((version) => {
        setVersion(version)
    }, [])
    if (loading) return <CardThreeBounce />;

    return (
        <>
            <div className="page-content pt-0">
                <ConfigComponent version={version} setVersion={setNewVersion} label="App name" type="text" fieldName="appName" />
                <ConfigComponent version={version} setVersion={setNewVersion} label="Andriod Link" type="text" fieldName="androidLink" />
                <ConfigComponent version={version} setVersion={setNewVersion} label="Ios Link" type="text" fieldName="iosLink" />
                <ConfigComponent version={version} setVersion={setNewVersion} label="Notification Emails" type="text" fieldName="notificationEmails" />
                <ConfigComponent version={version} setVersion={setNewVersion} label="Round Currenncy" type="checkBox" fieldName="roundCurrency" />
                <ConfigComponent version={version} setVersion={setNewVersion} label="Select Vehicle Driver" type="checkBox" fieldName="selectVehicleDriver" />
                <ConfigComponent version={version} setVersion={setNewVersion} label="Show Market Place" type="checkBox" fieldName="showMarketPlace" />
            </div>
        </>
    );
};

export default observer(Config);






