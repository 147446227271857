import React, { useState, useEffect } from 'react';
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import { generateRandomString } from '..';

function TimeIntegerComponent(props) {
  const { value, onChange, label, required, className = "", outline, defaultLabel } = props;
  const [internalValue, setInternalValue] = useState('');

  useEffect(() => {
    if (value) {
      const hours = Math.floor(value / 100);
      const minutes = value % 100;
      setInternalValue(formatTime(hours, minutes));
    }
  }, [value]);

  const formatTime = (hours, minutes) => {
    return `${pad(hours)}:${pad(minutes)}`;
  };

  const pad = (number) => number.toString().padStart(2, '0');

  const handleBlur = () => {
    const nums = internalValue.replace(/:/g, '');
    let formattedTime = '';
    if (nums.length <= 2) {
      formattedTime = `${pad(parseInt(nums) || 0)}:00`;
    } else if (nums.length > 2) {
      const hours = Math.min(24, parseInt(nums.substring(0, 2)) || 0);
      const minutes = Math.min(60, parseInt(nums.substring(2)) || 0);
      formattedTime = `${pad(hours)}:${pad(minutes)}`;
    }
    setInternalValue(formattedTime);
    const [h, m] = formattedTime.split(':').map(Number);
    onChange(h * 100 + m);
  };

  const handleInputChange = (e) => {
    const value = e.replace(/:/g, '');
    let formattedTime = '';
    if (value.length <= 2) {
      formattedTime = value;
    } else {
      formattedTime = `${value.substring(0, 2)}:${value.substring(2, 4)}`;
    }
    setInternalValue(formattedTime);
  };

  const handleKeyPress = (e) => {
    if (!/^\d$/.test(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };

  const id = generateRandomString(36);
  const maskGenerator = createDefaultMaskGenerator('99:99');

  return (

    <div className={`d-flex align-items-center ${className}`} >
      {defaultLabel ? <div style={{ minWidth: "120px ", fontSize: 12, fontWeight: "500" }}>
        {label}
        {required && <font color="red">*</font>}
      </div> : null}
      <div className={` flex-1`} >

        <div className='inputfield__innercontainer '>
          {outline ? <OutlineLabel id={id} label={label} required={required} /> : null}
          <div className={`inputfield ps-2`}>
            <MaskedInput
              id={id}
              className="inputfield__input"
              maskGenerator={maskGenerator}
              value={internalValue}
              onChange={handleInputChange}
              onBlur={handleBlur}
              onKeyPress={handleKeyPress}
              placeholder="hh:mm"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const OutlineLabel = ({ label, required, id }) => {
  return (
    <label
      htmlFor={id}
      className="inputfield__label ms-3 font-size-11 px-1"
    >
      {label}
      {required && <font color="#EC734B">*</font>}
    </label>
  );
};

export default TimeIntegerComponent;
