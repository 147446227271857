import React from "react";
import { withScriptjs } from "react-google-maps";
import { observer } from "mobx-react-lite";
// import { InputField } from "../Demo/InputField/InputField";

import Campuses from "./store";
import { toJS } from "mobx";
import { InputField } from "../../components";
const { compose, withProps, lifecycle } = require("recompose");
const {
  StandaloneSearchBox,
} = require("react-google-maps/lib/components/places/StandaloneSearchBox");

const PlacesWithStandaloneSearchBox = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};
      Campuses.places = [];
      this.setState({
        places: [],
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: async () => {
          Campuses.places = await refs.searchBox.getPlaces();
          this.props?.onChange(toJS(Campuses.places[0].formatted_address));
          this.setState({
            places: Campuses.places,
          });
        },
      });
    },
  }),
  withScriptjs
)((props) => (
  <div data-standalone-searchbox="">
    <StandaloneSearchBox
      ref={props?.onSearchBoxMounted}
      bounds={props?.bounds}
      onPlacesChanged={props?.onPlacesChanged}
    >
      <InputField
        onChange={(e) => props?.onChange(props?.places)}
        error={props?.error}
        value={props?.value}
        showClear={props?.showClear}
        label={props?.label}
      />
    </StandaloneSearchBox>
  </div>
));

export default observer(PlacesWithStandaloneSearchBox);
