import { toJS } from "mobx";
import { useEffect, useState, useRef,useContext } from "react";

import { API_METHODS } from "../../../common/utils/Constants";
import { ApiHandler } from "../../../store/apiHandler/apiHandler";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import CitySelectService from "./CitySelectService";
import { I18nContext } from "../../../store/context/i18nContext";

export default function CitySelect({
  country,
  state,
  hidecities,
  onChange,
  onlyCountry,
  value,
  multi,
  plain,
  placeholder,
  noLabel,
  style,
  className,
  showErr,
  required,
  innerContainerClassName,
  containerStyle,
  inline,
  borderNone,
  RightComponent,
  onClickRight,
  disabled,
  maxWidth,
  outline,
  null_is_all,

  label = "City",
  ...props
}) {
  const [cities, setCities] = useState([]);
  const { t } = useContext(I18nContext);
  const [address, setAddress] = useState({
    country: country,
    state: state,
  });
  const isNew = useRef(false);
  const [inputValue, setInputValue] = useState("");
  const [newCities, setNewCities] = useState([]);

  useEffect(() => {
    handleInputChange();
  }, [inputValue]);

  const handleInputChange = async () => {
    if (inputValue) {
      const matchingOptions = cities?.filter((option) =>
        option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
      );
      if (!matchingOptions || matchingOptions?.length === 0) {
        const { data } = await ApiHandler({
          endPoint:
            "/api/o/maputil/autocomplete/keyvalue" +
            `?country=${country}` +
            `&state=${state}` +
            `&key=${inputValue}` +
            "&onlyCities=true",
          method: API_METHODS.GET,
        });
        isNew.current = true;
        setNewCities(data?.map((c) => ({ label: c?.city, value: c?.city })));
      } else {
        isNew.current = false;
        setNewCities([]);
      }
    } else {
      isNew.current = false;
      setNewCities([]);
    }
  };


  const filterexcludedcities = (cities) => {
    if (!hidecities || hidecities.length === 0) {
      return cities;
    }
    return cities.filter(city => !hidecities?.includes(city?.value));
  }



  const handleChange = async (selectedOption) => {
    if (isNew.current) {
      try {
        if (selectedOption) {
        }
        setCities((ci) => [...ci, selectedOption]);

        await ApiHandler({
          endPoint:
            "/api/maputil/populateCity?key=" +
            selectedOption.value +
            "&country=" +
            country,
          method: API_METHODS.GET,
        });
      } catch (error) {
        // console.log(error.message);
      }
    }
    onChange(selectedOption);
  };

  const fetch = async () => {
    try {

      if (address.state && address.country) {
        await CitySelectService.fetch(address.country, address.state);
        // console.log(
        //   "))))))))))))))",
        //   CitySelectService.cities[address.country + "/" + address.state]
        // );
        setCities(
          toJS(CitySelectService.cities[address.country + "/" + address.state])
        );
      } else if (address.country && onlyCountry) {
        await CitySelectService.getCityByCountry(address.country);
        setCities(toJS(CitySelectService.cities[address.country]));
      } else {
        setCities([]);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (address.state !== state || address.country !== country) {
      setAddress({ country: country, state: state });
      if (!value || !value?.length) onChange(!multi ? { label: "", value: "" } : []);
    }
  }, [country, state]);

  useEffect(() => {
    fetch();
  }, [address]);

  return (
    <>
      {cities?.length > 0 ? (
        !multi ? (
          <SelectField
            plain={plain}
            // placeholder={placeholder}
            placeholder={
              null_is_all && (!value || value.length === 0)
                ? "All Cities"
                : placeholder || "City" 
            }
            noLabel={noLabel}
            style={style}
            className={className}
            showErr={showErr}
            required={required}
            outline={outline}
            onChange={handleChange}
            inputValue={inputValue}
            innerContainerClassName={innerContainerClassName}
            onInputChange={setInputValue}
            data={isNew.current ? filterexcludedcities(newCities) : filterexcludedcities(cities)}
            containerStyle={containerStyle}
            value={value}
            label={t(label ?? "City")}  
            error={value?.length === 0 ? t("Please Select City") : ""}
            inline={inline}
            borderNone={borderNone}
            RightComponent={RightComponent}
            onClickRight={onClickRight}
            disabled={disabled}
{...props}
          />
        ) : (
          <SelectFieldMultiple
            plain={plain}
            style={style}            
            noLabel={noLabel}
            required={required}
            className={className}
            innerContainerClassName={innerContainerClassName}
            onChange={(v) => onChange(v.map((c) => c?.value))}
            data={filterexcludedcities(cities)}
            maxWidth={maxWidth}
            containerStyle={containerStyle}
            value={
              value?.length
                ? cities.filter((c) => value?.includes(c?.value))
                : ""
            }
            error={value ? "" : t("Cities")}
            placeholder={
              null_is_all && (!value || value.length === 0)
                ? "All Cities"
                : placeholder || "Cities" 
            }
            label={t(label ?? "Cities")}  
            showErr={showErr}
            isDisabled={disabled}
            outline={outline}


          />
        )
      ) : (
        <SelectField
          plain={plain}
          containerStyle={containerStyle}
          placeholder={placeholder}
          noLabel={noLabel}
          style={style}
          className={className}
          onChange={onChange}
          data={filterexcludedcities(cities)}
          value={value}
          label={!multi ? t(label) : t(label ?? "Cities")}  
          // label={!multi ? label : label ?? "Cities"}
          isDisabled={true}
          showErr={showErr}
          innerContainerClassName={innerContainerClassName}
          inline={inline}
          outline={outline}
          borderNone={borderNone}
          RightComponent={RightComponent}
          onClickRight={onClickRight}
          disabled={disabled}

        />
      )}
    </>
  );
}
