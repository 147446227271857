import React, { useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Button, Card, Modal } from "reactstrap";
import { RFPImage } from "../../assets/images";


export const InputLabel = React.memo(({ label = "", showOptional = false, id = "", labelStyle = {}, imgSrc = "" }) => (
    <label htmlFor={id} className="m-b-5">
        <span style={labelStyle}>{imgSrc ? <img src={imgSrc} style={{ objectFit: "contain" }} alt="radio" /> : label}</span>
        {showOptional && <span className="opt-field">(Optional)</span>}
    </label>
));


export const TextAreaWithoutIcon = React.memo((props) => {
    const {
        type = "text",
        label = "",
        isDisabled = false,
        rows = "",
        cols = "",
        id = "",
        style = {},
        onChange = () => { },
        errorMessage = "",
        onBlur = () => { },
        showOptional = false,
        placeholder = "",
        value = "",
        maxLength = 200,
    } = props;

    return (
        <>
            <InputLabel id={id} label={label} showOptional={showOptional} />
            <div className="input-box" id={id}>
                <textarea
                    disabled={isDisabled}
                    maxLength={maxLength}
                    style={style}
                    rows={rows}
                    cols={cols}
                    id={id}
                    name={id}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    type={type}
                    placeholder={placeholder}
                />
            </div>
            <div className="input-error-box input-error">{errorMessage}</div>
        </>
    );
});



export const InputWithDropDown = (props) => {
    const {
        placeholder = "Select",
        label = "",
        data = [],
        id = "",
        name = "",
        isPadding = "",
        onChange = () => { },
        onBlur = () => { },
        onCreateOption = () => { },
        value = "",
        isMulti = false,
        dataFormatted = false,
        isCreatable = false,
    } = props;
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (!dataFormatted) {
            const newData = data?.map((item) => ({ label: item, value: item }));
            setOptions(newData);
        } else {
            setOptions(data);
        }
    }, [data, dataFormatted]);

    return (
        <>
            <label htmlFor="" className="m-b-5">
                {label}
            </label>

            <div className="input-box-select">
                {isMulti && !isCreatable ? (
                    <Select
                        placeholder={placeholder}
                        styles={{ control: (styles) => ({ ...styles, padding: isPadding ? isPadding : 10 }) }}
                        value={value ? value : null}
                        isMulti
                        name={name}
                        id={id}
                        options={options}
                        onChange={onChange}
                        onBlur={() => {
                            onBlur({ target: { name } });
                        }}
                    />
                ) : isCreatable ? (
                    <CreatableSelect
                        placeholder={placeholder}
                        styles={{ control: (styles) => ({ ...styles, padding: isPadding ? isPadding : 10 }) }}
                        value={value ? value : null}
                        isMulti
                        name={name}
                        id={id}
                        options={options}
                        onChange={onChange}
                        onBlur={() => {
                            onBlur({ target: { name } });
                        }}
                        onCreateOption={onCreateOption}
                    />
                ) : (
                    <Select
                        placeholder={placeholder}
                        value={{ label: value }}
                        options={options}
                        styles={{ control: (styles) => ({ ...styles, padding: 8 }) }}
                        name={name}
                        id={id}
                        onChange={({ value }) => {
                            let event = { target: { name: name, value: value } };
                            if (props?.onSelect) {
                                props?.onSelect(value);
                            }
                            onChange(event);
                        }}
                        onBlur={() => {
                            onBlur({ target: { name } });
                        }}
                    />
                )}
            </div>
        </>
    );
};