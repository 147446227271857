export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/zone/grid?`;
    url += `&sortBy=name&sortAsc=true?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`

    return url;
  },
  get: (id) => `/api/zone/${id}/detail`,
  save: `/api/zone/create`,
  delete: (id) => `/api/zone/${id}/delete`,
  bulkDelete: (id) => `/api/zone/delete`,
  update: `/api/zone/update`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Zone Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Zone Country",
    filterLabel: "Zone Country",
    filterName: "country",
    type: "countrySelect",
  },
  // {
  //   label: "Zone Corp Id",
  //   filterLabel: "Zone Corp Id",
  //   filterName: "corp_id",
  //   type: "text",
  // },
];
