import { toJS } from 'mobx'
import React, { useState, useEffect } from 'react'
import DemoMobx from '../../../store/mobx/demo/demo'
import { InputField, SelectField } from "../../../components";

export default function PhoneNo(props) {
    const [COUNTRIES, setCountries] = useState([])
    useEffect(() => {
        const fetch = async () => {
            await DemoMobx.getCountries();
            setCountries(toJS(DemoMobx.countries).map((c) => { return { value: parseInt(c._id), label: `${c._id} - ${c.label}` } }))
        }
        fetch();
    }, [])

    return (
        <React.Fragment >
            <SelectField
                onChange={(v) => props?.onCountryChange(v)}
                data={COUNTRIES}
                value={props?.selectedCountryCode}
                showClear={false}
                label={'Country Code'}
                error={props?.selectedCountryCode?.length === 0 ? 'Please choose Country Code' : ''}
                showErr={props?.showErr}
                className={"col-sm-2 mr-0 px-0 pl-5" + props?.className}
            />
            <InputField
                className={"col-4 ml-0 px-0" + props?.className}
                value={props?.phoneNumber}
                required={true}
                label={"Mobile number"}
                onChange={(v) => {
                    props?.onPhoneNumberChange(v)
                }}
                checker={(v) => v.length <= 10 && !isNaN(v)}
                error={props?.phoneNumber?.length === 0 ? "Please enter Phone Number" : ""}
                {...(props?.autoFocus && { autoFocus: props?.autoFocus })}
                type="text"
                RightComponent={props?.RightComponent}
                onClickRight={props?.onClickRight}
            />
        </React.Fragment>
    )
}
