export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/branch/data/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + `&page=${page}`;


  },
  get: (id) => `/api/branch/${id}/detail`,
  save: `/api/branch/save`,
  detail:(id)=>`/api/driver/${id}/driverDetail`,
  delete: (id) => `/api/branch/${id}/delete`,
  getname:`/api/driver/detail/all/data`,
};
export const STRUCTURE = [
  {
    label: "Name",
    description: "description...",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Country",
    description: "description...",
    filterLabel: "Country",
    filterName: "country",
    type: "countrySelect",
    dependencyOnMe: ["city","state"],
  },
  {
    label: "State",
    description: "description...",
    filterLabel: "State",
    filterName: "state",
    type: "stateSelect",
    dependencyOnMe: ["city"],
    dependency: {
      country: "country",
    },
  },
  {
    label: "City",
    description: "description...",
    filterLabel: "City",
    filterName: "city",
    type: "citySelect",
    
    dependency: {
      country: "country",
      state:"state"
    },
  },
];

export const SELECTSTRUCTURE = {
  emp: [
    { label: "Head", value: "Head" },
    { label: "Normal Employee", value: "Normal Employee" },
    { label: "Ground Operator", value: "Ground Operator" },
    
  ],
}
 
