import moment from 'moment'
import React, { useContext, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { CitySelect, CrossButton, InputField, SelectField, TimeSelect, VehicleSelect } from '../../../components'
import { I18nContext } from '../../../store/context/i18nContext'
import { TextAreaWithoutIcon } from '../MarketplaceComponents'
import { SELECTSTRUCTURE, Service_Type } from '../MarketPlaceConstant'


const StepTwo = ({ data, setData }) => {
    const { t } = useContext(I18nContext);
    // const [operatingData, setOperatingData] = useState(data?.operationZones)
    const { inventory = [] } = data
    const [inventoryData, setInventoryData] = useState(inventory)

    console.log(data, "datat")
    return (
        <>
            <div className=' m-auto mb-3' style={{ maxWidth: "580px" }}>
                <div className="separator">
                    <div className="line"></div>
                    <h2 className=" fw-bold">Requirement</h2>
                    <div className="line"></div>
                </div>
            </div>
            <Row className=''>
                <Col className=' px-4 col-sm-12'>
                    <CitySelect
                        className='col-sm-10 m-auto'
                        value={data?.city}
                        state={data?.state}
                        country={data?.country}
                        onChange={setData}
                    />
                </Col>
            </Row>

            <Row className='mt-4'>
                <Col className=' px-4 col-sm-12'>
                    <InputField
                        label={t("Count")}
                        value={data?.count}
                        onChange={(v) => setData({
                            ...data,
                            contactPerson: v
                        })}
                        placeholder="Count"
                        type="number"
                        className='col-sm-10 m-auto'

                        showErr={true}
                    />
                </Col>
            </Row>

            <Row className='mt-4'>
                <Col className=' px-4 col-sm-12'>
                    <SelectField
                        className='col-sm-10 m-auto'
                        data={SELECTSTRUCTURE.durations}
                        value={data?.duration}
                        label="Select Template"
                        onChange={(v) => setData({
                            ...data,
                            duration: v
                        })}
                    />

                </Col>
            </Row>


            <Row className='mt-4'>
                <Col className=' px-4 col-sm-12'>
                    <InputField
                        label={t("Details")}
                        value={data?.details}
                        onChange={(v) => setData({
                            ...data,
                            details: v
                        })}
                        placeholder="Details"
                        type="text"
                        className='col-sm-10 m-auto'

                        showErr={true}
                    />

                </Col>
            </Row>
            <Row className='mt-4'>
                <Col className=' px-4 col-sm-12'>
                    <div className=' d-flex flex-column'>
                        <div className='select-vehcile-type-box m-auto'>
                            <div className=' d-flex justify-content-between'>
                                <div className=' mt-3 px-3'>Type of Vehicle</div>
                                <div className='mt-2 float-end px-3'>
                                    <Button onClick={() => setInventoryData([...inventoryData, {}])} style={{ color: "#DF6463", backgroundColor: "white", border: "none" }}> <span className=' fa-1x fw-bolder' style={{}}>+</span> Add Vehicle</Button>
                                </div>
                            </div>

                        </div>
                        {inventoryData?.length > 0 ?
                            <div className='m-auto select-vehilce-card mt-3'>
                                {inventoryData?.map((inventory, i) => {
                                    return (

                                        <InventoryRow index={i} value={inventory} inventoryData={inventoryData} setInventoryData={setInventoryData} onChange={v => {
                                            setInventoryData([...inventoryData.splice(i, 1), v]);
                                        }} />


                                    )
                                })}
                            </div> : null}

                    </div>
                </Col>
            </Row>


        </>
    )
}


const InventoryRow = (props) => {
    const { value, onChange, inventoryData, setInventoryData, index } = props
    return (
        <>
            <div >
                <Row className='mt-4 px-4'>
                    <Col className=''>
                        <VehicleSelect
                            multi
                            value={value?.vehicleType}
                            onChange={onChange}

                        />
                    </Col>
                    <Col className='col-sm-4 px-4'>

                        <InputField
                            value={value?.count}
                            type="number"
                            label="Count"
                            onChange={onChange}


                        />
                    </Col>
                    <Col className='col-sm-2 px-2'>
                        <div className='cross'>

                            <CrossButton
                                onClick={() => {
                                    setInventoryData(() => {
                                        let newData = inventoryData?.slice();
                                        newData.splice(index, 1);
                                        return newData;
                                    });
                                }}

                            />
                        </div>
                    </Col>
                </Row>
            </div>


        </>
    )

}


export default StepTwo