
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  Layout
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./ClientCompanyConstant";
import { GridColumns } from "./ClientCompanyGridColumns";
import ClientCompanyService from "./ClientCompanyService";

const ClientCompanyGrid = ({
  showOnly,
  corp_id,
  value,
  breadcrumb, 
  insidePane,
  supplier_id, onlySelect,
  client_id,
  multiMode,
  onSelect = () => { },
  updateHasEntities = () => { },
  hideTitleBar,
  hideFilterBar,
  dontShowCheck = false,
  filterUrlProp,
}) => {

  let { company_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { userContent } = useContext(UserContext);
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [editID, setEditID] = useState(company_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/company/create" || company_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});


  const fetchData = async (v) => {
   let   {filterUrl}  = prepareFilters(v)
    if (loading) return;
    setLoading(true);
    try {
      // const newFilterUrl = `${filterURL}&corp_id=${userContent?.adminOf}`
      const rows = await ClientCompanyService.fetch(filterUrl);
      updateHasEntities(rows?.length ? true : false)
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const prepareFilters =  (url) => {
    let { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane, filterUrlProp)
    if(url) {
      filterUrl = url
    }
    if(client_id) {
      filterObject = { ...filterObject, client_id: "exists", owner_id: userContent?.owner_id ?? "" };
      filterUrl += "&client_id=exists&forClient=true";
    } else if (supplier_id) {
      filterObject = { ...filterObject, supplier_id: "exists", owner_id: userContent?.owner_id ?? "" };
      filterUrl += "&supplier_id=exists";
    } else if (corp_id) {
      filterObject = { ...filterObject, corp_id: corp_id, owner_id: userContent?.owner_id ?? "" };
      filterUrl += "&corp_id=" + corp_id;
      filterUrl += "&owner_id=" + userContent?.owner_id ?? ""
    } else if (userContent) {
      filterObject = { ...filterObject, corp_id: userContent?.adminOf, owner_id: userContent?.owner_id };
      filterUrl += "&corp_id=" + userContent?.adminOf;
      filterUrl += "&owner_id=" + userContent?.owner_id
    }
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    return  { filterUrl, filterObject } 
  }

  useEffect(() => {

    if (corp_id || userContent || supplier_id || client_id ) {
      fetchData();
    }
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [corp_id, supplier_id, client_id, userContent]);

  useEffect(() => {

  })

  useEffect(() => {
    if (!insidePane) setEditID(company_id)
  }, [company_id]);




  const renderLastCol = useCallback((entity) => {
    return (
      <>
      
        {!multiMode && insidePane && !dontShowCheck ? (
          <CheckButton
            onClick={() => {
              onSelect(entity);
            }}
          />
        ) : null}
      </>

    )
  }, [insidePane, userContent]);

  const onBulkDelete = async () => {
   
  };


  return (
    <>
      <Layout
        hideAdd
        medium
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/company");
          setEditID(null);
        }}
         
        breadcrumb={t(client_id ? "Clients" : supplier_id ? "Select Suppliers" : "Clients")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/company/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={ClientCompanyService.page}
        rows={ClientCompanyService.rows}
        total={ClientCompanyService.total}
        fetch={ClientCompanyService.fetch}
        hideTitleBar={hideTitleBar}
        hideSearch={hideFilterBar}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem> {t("Download")} </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={showOnly?.length ? ClientCompanyService.records?.filter(item => showOnly.some(val => val === item._id)) : ClientCompanyService.records}
              total={ClientCompanyService.total}
              uiPreference="entity.grid"
              headers={GridColumns({ isClient: client_id ? true : false, onlySelect: onlySelect })}
              selectedIDs={selectedIDs}
              onSelectChange={(v) => {
                onSelect(v)
                setSelectedIDs(v)
              }}
              page={ClientCompanyService.page}
              rowsPerPage={ClientCompanyService.rowsPerPage}
              onPaginationChange={ClientCompanyService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </>
        </Layout.Table>

      </Layout>
    </>
  );
};
export default observer(ClientCompanyGrid);
