import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";
import {
  BackArrow,
  CardThreeBounce,
  CrossButton,
  SaveButton,
  DeleteLink,
  scrollStyle,
  InputField,
  CountrySelect,
  LanguageSelect,
  CountryLanguageSelect,
  TimeZoneSelect,
  SelectField,
  DateSelect,
  TimeSelect,
  Checkbox,
  CurrencySelect,
  DriverSelect,
  VehicleSelect,
  CompanySelect,
  FileUploadViewer,
  FileUpload,
  timestampToyyyy_mm_ddThh_mm,
} from "../../components";
import DocUploader from "../Driver/DocUploader";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import NewFuelService from "./NewFuelService";
import { ENDPOINTS } from "./NewFuelConstant";
import { UserContext } from "../../store/context/UserContext";

const EditNewFuel = (props) => {
  const { data, setData, hasErr, setHasErr, id } = props;
  const { t } = useContext(I18nContext);
  const [freshData, setFreshData] = useState({});
  const [loading, setLoading] = useState(false);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const { currencyWebContent } = useContext(UserContext)

  const FuelPump = [
    {
      label: "deepak pump",
      value: "622210df4333853f3f1098f0",
    },
  ];
  const FuelType = [
    {
      label: "Diesel",
      value: "Diesel",
    },
    {
      label: "Petrol",
      value: "Petrol",
    },
    {
      label: "CNG",
      value: "CNG",
    },
    {
      label: "Electric",
      value: "Electric",
    },
  ];
  const qtytype = [
    {
      label: "Litre",
      value: "Litre",
    },
    {
      label: "KG",
      value: "KG",
    },
    {
      label: "KW",
      value: "KW",
    },
  ];

  const navigate = useNavigate();

  const fetch = async () => {
    let countryID = {
      status: 1,
      currency: currencyWebContent
    };
    if (id) countryID = await NewFuelService.get(id);
    setFreshData(countryID);
    setData(countryID);
    setHasErr(false);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetch();
    setHasErr(false);
  }, [id]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(freshData)) {
      props?.onChange(true);
    } else {
      props?.onChange(false);
    }
  }, [data]);

  const flagUpload = async (e) => {
    if (id) {
      const response = await NewFuelService.upload(id);
      console.log(response);
    }
    setData({
      ...data,
      flag: `master/fuel/${data?.name}/${e.name}`,
    });
  };

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      {(id && !loading) || !id ? (
        <div className="p-0">
          <Form className="p-4">

            <Row className="col-lg mt-4 mb-3">
              <Col>
                <CompanySelect
                  placeholder="Bu Id"
                  value={data?.bu_id}
                  required={true}
                  corpType="self"

                  label={"Business Unit"}
                  onChange={(v) => setData({ ...data, bu_id: v.value })}
                  error={data?.name?.length === 0 ? "This Field is Required" : ""}
                  showErr={hasErr}

                />
              </Col>
              <Col>
                <DriverSelect
                  placeholder="Driver"
                  value={data?.driver_id}
                  label={"Driver"}
                  onChange={(v) => setData({ ...data, driver_id: v.value })}
                  error={
                    data?.isd?.length === 0 ? "This Field is Required" : ""
                  }
                  showErr={hasErr}

                  required={true}
                  type="text"
                />
              </Col>
              <Col>
                <VehicleSelect
                  placeholder="Fuel Pump"
                  data={FuelPump}
                  value={data?.vehicle_id}
                  label={"Fuel Pump"}
                  required={true}
                  onChange={(v) => setData({ ...data, vehicle_id: v.value })}
                />
              </Col>
            </Row>

            <Row className="col-lg mt-4 mb-3">
              <Col>
                <SelectField
                  placeholder="Fuel Pump"
                  data={FuelPump}
                  value={data?.pump_id}
                  label={"Fuel Pump"}
                  onChange={(v) => setData({ ...data, pump_id: v.value })}
                />
              </Col>
              <Col>
                <DateSelect
                  placeholder="Date"
                  value={data?.at}
                  label={"Date"}
                  onChange={(v) => setData({ ...data, at: v })}
                />
              </Col>

              <Col className="col-lg">
                <TimeSelect
                  placeholder="Time"
                  value={data?.at}
                  label={"Time"}
                  onChange={(v) => setData({ ...data, at: v })}
                  error={
                    data?.currency?.length === 0 ? "This Field is Required" : ""
                  }
                  showErr={hasErr}

                />
              </Col>
            </Row>
            <Row className="col-lg mt-4 mb-3">
              <Col className="col-lg">
                <SelectField
                  label={"Fuel Type"}
                  data={FuelType}
                  value={data?.fuelType}
                  required={true}
                  onChange={(v) => setData({ ...data, fuelType: v.value })}
                />
              </Col>
              <Col className="col-lg">
                <SelectField
                  label={"Quantity Type"}
                  data={qtytype}
                  required={true}
                  value={data?.qtyType}
                  onChange={(v) => setData({ ...data, qtyType: v.value })}
                />
              </Col>
              {/* </Row>
            <Row className="col-lg mt-4 mb-3"> */}
              <Col className="col-lg">
                <InputField
                  label={"Quantity"}
                  value={data?.qty}
                  type="number"
                  required={true}
                  onChange={(v) =>
                    setData({
                      ...data,
                      qty: v,
                    })
                  }
                />
              </Col>
              <Col className="col-lg">
                <InputField
                  label={"Current KMs Reading"}
                  value={data?.kms}
                  required={true}
                  onChange={(v) =>
                    setData({
                      ...data,
                      kms: v,
                    })
                  }
                  type="number"
                />
              </Col>
            </Row>
            <Row className="col-lg mb-3">
              <Col className="col-lg">
                <InputField
                  label={"Reciept No"}
                  value={data?.receiptNo}
                  required={true}
                  onChange={(v) =>
                    setData({
                      ...data,
                      receiptNo: v,
                    })
                  }
                  type="text"
                />
              </Col>
              <Col className="col-lg">
                <CurrencySelect
                  label={"Currency"}
                  required={true}
                  value={data?.currency ? data?.currency : currencyWebContent}
                  onChange={(v) =>
                    setData({
                      ...data,
                      currency: v.value,
                    })
                  }
                />
              </Col>
              <Col className="col-lg">
                <InputField
                  label={"Cost"}
                  value={data?.cost}
                  onChange={(v) =>
                    setData({
                      ...data,
                      cost: v,
                    })
                  }
                  type="number"
                />
              </Col>
            </Row>
            <Row className="col-lg mb-3">
              {/* <Col className="col-lg">
                <Checkbox
                  label={"For OutStation"}
                  value={data?.isOutstation}
                  onClick={() =>
                    setData({
                      ...data,
                      isOutstation: !data.isOutstation
                    })
                  }
                />
              </Col> */}
              <Col className="col-lg">
                <Checkbox
                  label={t("Paid in cash")}
                  checked={data?.cash}
                  onClick={() => {
                    setData({ ...data, cash: !data?.cash });
                  }} />
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <InputField
                label={t("Remark")}
                placeholder={t("Remark")}
                value={data?.remark}
                onChange={(v) => {
                  setData({ ...data, remark: v.value });
                }}
                type="textarea"
              />
            </Row>
            <Row>
              {/* <FileUpload
                      value={data?.vehiclePic

                      }
                      onChange={(v) =>
                        setData({
                          ...data,
                          vehiclePic: {
                            ...data?.vehiclePic
                            ,
                            vehiclePic: v,

                          },
                        })
                      }
                     
                      
                      
                    /> */}
            </Row>
          </Form>
        </div>
      ) : (
        <CardThreeBounce />
      )}
    </React.Fragment>
  );
};

export default observer(EditNewFuel);
