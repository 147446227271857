export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/doc-category/grid?`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    categories:`/api/doc-category/grid?parent_id=notExists&rows=-1`,
    get: (id) => `/api/doc-category/${id}/detail`,
    save: `/api/doc-category/create`,
    delete: (id) => `/api/doc-category/${id}/delete`,
    bulkDelete: (id) => `/api/doc-category/delete`,
    update: `/api/doc-category/update`,
};

export const STRUCTURE = [
    {
        label: "No.",
        filterLabel: "no ",
        filterName: "name",
        type: "text",
    },
    {
        label: "DocumentCategory Code",
        filterLabel: "DocumentCategory Code",
        filterName: "code",
        type: "text",
    },
];
export const SELECTSTRUCTURE = {
module:[
    {label:"Vehicle",value:"vehicle"},
    {label:"Expense General",value:"expense-general"},
    {label:"Expense Vehicle",value:"expense-vehicle"},
    {label:"Expense Employee",value:"expense-employee"},
    {label:"Booking",value:"booking"},
    {label:"Customer",value:"customer"},
    {label:"Ticket",value:"ticket"},
    {label:"Incident",value:"incident"},
]
}
