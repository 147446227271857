import React, { useState, useEffect } from 'react'
import { SelectField } from '../../SelectField/SelectField';
import { SelectFieldMultiple } from '../../SelectField/SelectFieldMultiple';
import { toJS } from 'mobx';
import TariffTypeService from './TariffTypeSelectService';

export default function TariffTypeSelect(props) {
    const [tariffTypes, setTariffTypes] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            try {
                if (tariffTypes.length === 0) {
                    await TariffTypeService.fetch();
                    setTariffTypes(toJS(TariffTypeService.tariffTypes))
                }
            } catch (error) {
                console.log(error.message);
            }
        }
        fetch();
    }, [])

    return (
        <React.Fragment>
            {tariffTypes.length > 0 && <SelectField
                className={props?.className}
                onChange={props?.onChange}
                data={tariffTypes}

                value={props?.value}
                label={"Tariff Type"}
                error={
                    !props?.value
                        ? "Please Select Tariff Type"
                        : ""
                }
                required={props?.required}
                showErr={props?.showErr}
                inline={props?.inline}
                borderNone={props?.borderNone}
                RightComponent={props?.RightComponent}
                onClickRight={props?.onClickRight}
            />}
            {!(tariffTypes.length > 0) && <SelectField

                className={props?.className}
                onChange={props?.onChange}
                data={tariffTypes}
                value={props?.value}

                label={"Tariff Type"}
                isDisabled={true}
                showErr={props?.showErr}
                inline={props?.inline}
                borderNone={props?.borderNone}
                RightComponent={props?.RightComponent}
                onClickRight={props?.onClickRight}
            />
            }
        </React.Fragment>
    )
}
