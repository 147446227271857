import React from 'react';
import './sideModal.css';

const SideModal = ({ small, large, medium, className, style, visible, toggleVisible, children }) => {
  const modalClass = `zr-side-modal ${small ? 'zr-small' : ''} ${large ? 'zr-large' : ''} ${medium ? 'zr-medium' : ''
    } ${className || ''}`;

  return (
    <div
      className={modalClass}
      onClick={(e) => { e.stopPropagation()}}
      style={{ ...style, display: visible ? 'block' : 'none' }}
    >
      <button className="zr-close-btn" onClick={(e) => {e.stopPropagation(); toggleVisible() }}>×</button>
      {visible ? children : <></>}
    </div>
  );
};

export default SideModal;
