import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../../../pages/Terms/TermConstant";

class TermSelect {
  terms = [];
  constructor() {
    makeAutoObservable(this);
  }
  fetch = async function (props) {
    try {
      const response = await doGET(ENDPOINTS.grid(0, -1));
      if (response.status === 200) {
        this.terms = response?.data?.rows;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };
}

const TermSelectService = new TermSelect();
export default TermSelectService;
