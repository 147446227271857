import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IoMdArrowBack, IoMdRefresh, IoMdSend } from 'react-icons/io';
import Skeleton from 'react-loading-skeleton';
import ChatAction from '../../../components/ChatAction/ChatAction';
import CustomerLabelService from '../../../components/Dropdowns/CustomerSelect/CustomerLabelService';
import CustomerLabel from '../../../components/Dropdowns/CustomerSelect/CustomerLabel';
import MediaChatViewer from '../../../components/MediaChatViewer/MediaChatViewer';
import { getMessageTime } from './helper';
import './WhatsApp.css';
import WhatsAppService from './WhatsAppService';
import { DialogContext } from '../../../store/context/DialogContext';
import { doPOST } from '../../../util/HttpUtil';
import DynamicTextarea from '../../../components/InputField/AutoResizingTextarea';

const groupMessagesByDay = (messages) => {
    const grouped = {};
    messages?.forEach(message => {
        const day = getMessageTime(message?.sentAt);
        if (!grouped[day]) {
            grouped[day] = [];
        }
        grouped[day].push(message);
    });
    return grouped;
};

const MessageList = ({ messages, onScroll, isLoading }) => {
    const groupedMessages = groupMessagesByDay(messages);
    const messageListRef = useRef(null);
    const prevScrollHeightRef = useRef(0);

    useEffect(() => {
        const listElement = messageListRef.current;
        if (listElement && messages.length > 0) {
            const scrollHeightDiff = listElement.scrollHeight - prevScrollHeightRef.current;
            if (scrollHeightDiff > 0 && listElement.scrollTop === 0) {
                listElement.scrollTop = scrollHeightDiff;
            } else {
                listElement.scrollTop = listElement.scrollHeight;
            }
        }
    }, [messages]);

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop === 0 && !isLoading) {
            prevScrollHeightRef.current = scrollHeight;
            onScroll();
        }
    };

    return (
        <div
            className='zr_crm_whatsapp_message-list'
            id="messageList"
            ref={messageListRef}
            onScroll={handleScroll}
        >
            {Object.keys(groupedMessages).reverse().map(day => (
                <div key={day}>
                    <div className='d-flex justify-content-center my-3'>
                        <div className="d-flex align-items-center justify-content-center date">{day}</div>
                    </div>
                    
                    {groupedMessages[day]
                        .slice()
                        .reverse()
                        .filter(message => message?.text !== undefined || (message?.attachment?.length > 0))
                        .map(message => {
                            return (
                                <div className={`d-flex justify-content-${message.sender === "other" ? "start" : "end"}`} key={message.id}>
                                    <div className={`zr_crm_whatsapp_message-item zr_crm_whatsapp_message-${message.sender}`}>
                                        <div className='d-flex flex-column'>
                                            {message?.attachment?.length > 0 && (
                                                <div className='py-2 d-flex justify-content-center h-max-content'>
                                                    <MediaChatViewer url={message?.attachment[0]?.key} />
                                                </div>
                                            )}
                                            <div>
                                                {message?.text && <div className="zr_crm_whatsapp_message-text">{message?.text}</div>}
                                                <div className="zr_crm_whatsapp_message-time">{moment(message.sentAt * 1000).format('hh:mm A')}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}


                </div>
            ))}
        </div>
    );
};

const MessageInput = ({ customer_id, onSendMessage, onSendFlightDetails }) => {
    const [message, setMessage] = useState('');
    const [contactDetails, setContactDetails] = useState({});
    const { showError, showMessage } = useContext(DialogContext)
    const textAreaRef = useRef(null);
    const fetchContactDetails = useCallback(async () => {
        if (!customer_id) return;
        try {
            const customer = await CustomerLabelService.fetchCustomerNameFromCustomerId(customer_id);
            if (customer) {
                setContactDetails({
                    name: customer.label,
                    email: customer?.email,
                    countryCode: customer?.phoneNo?.countryCode,
                    phone: customer?.phoneNo?.phone,
                });
            } else {
                setContactDetails({});
            }
        } catch (error) {
            showError(error);
        }
    }, [customer_id, showError]);

    useEffect(() => {
        fetchContactDetails();
    }, [customer_id, fetchContactDetails]);

    const handleSend = () => {
        if (message.trim()) {
            onSendMessage({
                message,
                countryCode: contactDetails?.countryCode,
                phone: contactDetails?.phone,
            });
            setMessage('');
            if (textAreaRef.current) {
                textAreaRef.current.resetTextarea();
              }

        }
    };


    const sendTourDetails = async (type, data, onSuccess) => {
        try {
            if (!data || data.length === 0) {
                showError("No Tour selected");
                return; 
            }
            const payload = {
                whatsapp:true
                
                // For Future changes
                // space_id: data.space_id || null,
                // spaceTracking: data.spaceTracking || null
            };
    
            switch (type) {
                case "Flights":
                    payload.flight = true;
                    payload.flight_ids = data;
                    break;
                case "Stays":
                    payload.stay = true;
                    payload.stay_ids = data;
                    break;
                case "Trips":
                    payload.trip = true;
                    payload.trip_ids = data;
                    break;
                default:
                    throw new Error("Unknown type");
            }
    
            const response = await doPOST("/api/message/data/send/bulk", payload);
            
            if (response?.status === 200) {
                onSuccess && onSuccess();
                setTimeout(() => {
                    WhatsAppService.fetchMessages(contactDetails?.phone);
                }, 2000);
            }
        } catch (error) {
            showError(error);
        }
    };
    
    

    const handleActionChange = (type, value) => {
        switch (type) {
            case "Flights":
            case "Stays":
            case "Trips":
                sendTourDetails(type, value, () => {
                    showMessage("Message sent successfully");
                });
                break;
            case "Document":
                WhatsAppService.sendAttachment(
                    customer_id,
                    value?.message,
                    contactDetails?.phone,
                    contactDetails?.countryCode,
                    value?.attachments
                );
                break;
            default:
                console.warn("Unknown action type:", type);
                break;
        }
    };

    return (
        <div className="zr_crm_whatsapp_message-input">
            <ChatAction
                customer_id={customer_id}
                show={["Flights", "Stays", "Trips", "Create Trip"]}
                onChange={(type, value) => {
                    handleActionChange(type, value);
                }}
            />
            <DynamicTextarea
                value={message}
                ref={textAreaRef}
                onChange={(e) => {
                    setMessage(e.target.value);
                }}
                placeholder="Type a message"
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSend();

                    }
                }}
                className='zr_crm_whatsapp_message-inputField'
            />

            <div className="zr_crm_whatsapp_send">
                <div onClick={handleSend} className="zr_crm_whatsapp_send-icon">
                    <IoMdSend size={20} />
                </div>
            </div>
        </div>
    );
};

const WhatsappChat = ({ customer_id }) => {
    const { messages, fetchMessages, sendMessage, handleFetchMessageAtBottom } = WhatsAppService;
    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState(null);
    const { showError } = useContext(DialogContext)

    useEffect(() => {
        CustomerLabelService.fetchCustomerNameFromCustomerId(customer_id)
            .then(customer => {
                setCustomer(customer)
                handleFetchMessageAtBottom(customer?.phoneNo.phone, customer_id);
            })
            .catch(error => {
                console.error("Error fetching customer details:", error);
                setCustomer(null);
            });
    }, [customer_id]);

    const loadMoreMessages = useCallback(() => {
        if (!isLoading && WhatsAppService.hasMoreMessages) {
            setIsLoading(true);
            fetchMessages(customer?.phoneNo.phone, true).finally(() => setIsLoading(false));
        }
    }, [customer?.phoneNo.phone, fetchMessages, isLoading]);


    const handleSendFlightDetails = (details) => {
        sendMessage(customer?.phoneNo.phone, details);
    };

    const refreshAction = () => {
        setIsLoading(true);
        setTimeout(async () => {
            try {
                await WhatsAppService.fetchMessages(customer?.phoneNo.phone);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        }, 1000);
    };

    return (
        <div className="zr_crm_whatsapp_chat-window">
            <div className="zr_crm_whatsapp_chat-header">
                {/* <IoMdArrowBack size={24} /> */}
                <div className="zr_crm_whatsapp_chat-title"><CustomerLabel value={customer?._id} /></div>
                <IoMdRefresh color='white' size={18} className={isLoading ? 'rotating cursor-pointer' : 'cursor-pointer'} onClick={refreshAction}  />
            </div>
            <MessageList
                messages={messages}
                onScroll={loadMoreMessages}
                isLoading={isLoading}
            />
            <MessageInput
                customer_id={customer_id}
                onSendMessage={async (v) => {
                    let message=await WhatsAppService.sendMessage(
                        customer_id,
                        v?.message,
                        customer?.phoneNo?.phone,
                        customer?.phoneNo?.countryCode
                    );
                    
                    if (message) {
                        showError(message);
                    }
            
                }}
            />
        </div>
    );
};

export default observer(WhatsappChat);
