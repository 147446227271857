import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { Button, Card } from "reactstrap";
import CustomTooltip from "../../../pages/Booking/components/CustomTooltip";
import Itinerary from "../../../pages/Itinerary/Itinerary";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { doGET } from "../../../util/HttpUtil";
import Comment from "../../CommentByModule";
import ClientCompany from "../../Dropdowns/ClientCompany/ClientCompany";
import EmployeeAssigner from "../../Dropdowns/EmployeeSelect/EmployeeAssigner";
import StatusPicker from "../../Dropdowns/StatusMasterSelect/StatusPicker";
import PassengerField from "../../PassengerField/PassengerField";
import PriorityDropdown from "../../PriorityDropdown/PriorityDropdown";
import Reminder from "../../ReminderByLead/Reminder";
import ReminderService from "../../ReminderByLead/ReminderService";
import TagPicker from "../../TagSelector/TagPicker";
import { CustomFieldComponent, DeleteButton, InputField, ModalComponent, SaveButton, ToggleSelector } from "../../index";
import LeadMasterService from "../board/LeadMasterService";
import { generateUniqueId, useOutsideAlerter } from "../board/helper";
import SpaceService from "../listView/SpaceService";
import Circle from "./Circle";
import ItineraryForm from "./itinerary/ItineraryForm";
import ItineraryGrid from "./itinerary/ItineraryGrid";
import ItineraryLeadService from "./itinerary/ItineraryLeadService";
import Editor from "./itinerary/components/RichTextEditor/Editor/Editor";
import SupplierFareService from "./itinerary/components/SupplierFare/SupplierFareService";
import "./style.scss";
import StatusProgress from "../../Dropdowns/StatusMasterSelect/StatusProgress";

const newContact = {
    salutation: "",
    name: "",
    designation: "",
    email: "",
    phone: {
        countryCode: "",
        phone: ""
    }
}

const LeadForm = ({ onEdit = () => { }, editId, isOpen, toggleModal, statusMasterId }) => {

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [customerType, setCustomerType] = useState(null);
    const [showItineraryForm, setShowItineraryForm] = useState(false);
    const [isReminderOpen, setIsReminderOpen] = useState(false)
    const [initialStatus, setInitialStatus] = useState(-1)

    const dropdownRef = useRef(null);
    useOutsideAlerter(dropdownRef, () => setIsReminderOpen(false));

    const [data, setData] = useState({
        ...(statusMasterId && { status: statusMasterId }),
        status: 1,
        leadExtn: { contacts: [newContact] }
    });

    const fetch = async () => {
        try {
            let lead = await LeadMasterService.get(editId);
            setCustomerType((lead?.clientCompany_id && lead?.client_id) ? "client" : "customer")
            setInitialStatus(lead?.status)
            setData(lead);
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        if (isOpen) {
            setLoading(true);
            if (editId) {
                ReminderService.fetch(editId)
                fetch();
            } else {
                setData({ status: 1, })
            }

            setLoading(false)
        }
    }, [editId, LeadMasterService?.editVersion, isOpen]);

    useEffect(() => {
        if (!editId) {
            setCustomerType("customer")
        }
    }, [])

    const onSave = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            if (data?._id) {
                await LeadMasterService.edit(data, data?._id, initialStatus);
                LeadMasterService.toggleEditVersion()
                setLoading(false);
                showMessage(t("Lead updated successfully."));
                onEdit()
            } else {
                const lead = await LeadMasterService.save({
                    ...data,
                    space_id: SpaceService?.selectedSpace?._id
                });
                setLoading(false);
                showMessage(t("Lead saved successfully."));
                onEdit()
            }
            // fetchData(filterURL);
        } catch (e) {
            setLoading(false);
            showError(e);
        }
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await LeadMasterService.delete(id);
                setLoading(false);
                showMessage("Lead Deleted SuccessFully", "Deleted");
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };

    const cloneItinerary = async (v) => {
        try {
            let itinerary = await ItineraryLeadService.get(v);
            const { _id, version, space_id, itineraryExtn, ...rest } = itinerary;

            const today = moment().format('YYYYMMDD');
            const ratesForToday = await doGET(`/api/itinerary-rate/grid?&itinerary_id=${_id}&rows=-1&date=${today}`);
            const priceForToday = ratesForToday.data.rows?.[0]?.price ?? 0;

            const newItinerary = await ItineraryLeadService.save({
                ...rest,
                lead_id: editId,
                itineraryExtn: {
                    ...itineraryExtn,
                    clientFee: {
                        charges: [{
                            "name": "Base Charge",
                            "detail": "Base Charge for service",
                            "unitCost": priceForToday,
                            "amount": priceForToday,
                            "units": 1
                        }]
                    },
                    version: null,
                    _id: null
                },
            });

            const supplierCharges = await SupplierFareService.fetch(_id);
            const supplierChargesPayload = supplierCharges?.map((supplierCharge,) => {
                const { _id, version, ...restSupplierCharge } = supplierCharge;
                return { ...restSupplierCharge, itinerary_id: newItinerary?._id }
            })

            supplierChargesPayload?.map(async (supplierCharge) => {
                await SupplierFareService.save(supplierCharge, true)
            })

            setShowItineraryForm({ clone: false })
        } catch (error) {
            showError(error)
        }
    }

    return (
        <React.Fragment>
            <ModalComponent
                position={"bottom"}
                size={"95vw"}
                openFrom="right"
                isOpen={isOpen}
                onToggle={toggleModal}
            >
                <ModalComponent.Title>
                    <div className="d-flex justify-content-between">
                        <div className="d-flex flex-1  align-items-center">
                            <div className="me-3">{data?._id ? "Edit" : "Add"} Lead </div>
                            <div className="flex-1">
                                <StatusProgress
                                    onChange={(v) => setData({
                                        ...data,
                                        status: v
                                    })}
                                    value={data?.status}
                                />
                            </div>
                            <div className="flex-1">


                                <TagPicker
                                    className="ms-2"
                                    kind="leads"
                                    onChange={(v) => setData({ ...data, tags: v })}
                                    value={data?.tags}
                                />
                            </div>
                        </div>
                        {editId && <div className="me-5">
                            <div className="zr_reminder_wrapper" ref={dropdownRef}>


                                <div
                                    className="zr_reminder_display"
                                    onClick={() => setIsReminderOpen(!isReminderOpen)}
                                >
                                    Reminder
                                    <div className="d-flex" style={{
                                        position: "absolute",
                                        top: "-8px",
                                        right: "-8px"
                                    }}>
                                        <CustomTooltip>
                                            <CustomTooltip.Body>
                                                <Circle number={
                                                    ReminderService?.records?.filter(task => !task.completed)?.length
                                                } color="orange" />
                                            </CustomTooltip.Body>
                                            <CustomTooltip.ToolTip>
                                                <div style={{ width: "fitContent" }}>Pending</div>
                                            </CustomTooltip.ToolTip>
                                        </CustomTooltip>
                                    </div>
                                </div>
                                {isReminderOpen && (
                                    <div className="zr_reminder_modal">
                                        <Reminder
                                            leadId={editId}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>}
                    </div>

                    {/* <StatusProgress
                        onChange={(v) => setData({
                            ...data,
                            status: v
                        })}
                        value={data?.status}
                    /> */}
                </ModalComponent.Title>
                <ModalComponent.Body preventScroll >
                    <div className="row h-100" style={{ marginBottom: "65px" }}>
                        <div className={`lead-form-left-side col-12 col-md-${editId ? "8" : "12"} `}>
                            <div className="d-flex row">
                                <div className="col-12 col-md-12 ">
                                    <InputField
                                        placeholder={t("Title")}
                                        value={data?.title}
                                        label={t("Title")}
                                        onChange={(v) => setData({ ...data, title: v })}
                                        error={data?.title?.length === 0 ? t("Please enter Brand Name") : ""}
                                        outline
                                        type="text"
                                        className="col-12"
                                    />
                                    <div className="d-flex justify-content-between align-items-center ">
                                        <ToggleSelector
                                            data={[
                                                { label: "Customer", value: "customer" },
                                                { label: "Corporate", value: "client" },
                                            ]}
                                            className=" w-50"
                                            style={{ width: "40%", position: "relative", top: "2.5px" }}
                                            value={customerType}
                                            onChange={(selectedValue) => {
                                                if (selectedValue === 'customer') {
                                                    setData(prevData => {
                                                        const { client_id, clientCompany_id, ...rest } = prevData;
                                                        return { ...rest, brandName: "", fullName: "" };
                                                    });
                                                } else {
                                                    setData(prevData => {
                                                        const { brandName, fullName, ...rest } = prevData;
                                                        return { ...rest, client_id: "", clientCompany_id: "" };
                                                    });
                                                }
                                                setCustomerType(selectedValue);
                                            }}
                                        />
                                        <div className="d-flex align-items-center">

                                            <div style={{ minWidth: "120px ", fontSize: 12, fontWeight: "500" }}>
                                                Priority
                                            </div>

                                            <PriorityDropdown
                                                value={data?.priority}
                                                tooltipId={generateUniqueId()}
                                                onChange={(v) => {
                                                    setData({
                                                        ...data,
                                                        priority: v
                                                    });
                                                }}
                                            />

                                        </div>
                                        <div className="d-flex me-2 align-items-center justify-content-end">
                                            <div className="me-2">Assigned To : </div>
                                            <EmployeeAssigner
                                                tooltipId={generateUniqueId()}
                                                value={data?.assignedTo}
                                                onChange={(v) => {
                                                    setData({
                                                        ...data,
                                                        assignedTo: v
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/* <InputField
                                        placeholder={t("Title")}
                                        value={data?.title}
                                        label={t("Title")}
                                        onChange={(v) => setData({ ...data, title: v })}
                                        error={data?.title?.length === 0 ? t("Please enter Brand Name") : ""}
                                        outline
                                        type="text"
                                        className="col-12"
                                    /> */}

                                    {customerType == "client" && (
                                        <ClientCompany
                                            value={data?.clientCompany_id}
                                            onChange={(v) => setData({ ...data, client_id: v?._id, clientCompany_id: v?.client_id, })}
                                            outline
                                            className="col-6"
                                        />
                                    )}

                                    {/* {customerType == "customer" && (
                                        <InputField
                                            placeholder={t("Full Name")}
                                            value={data?.fullName}
                                            label={t("Full Name")}
                                            onChange={(v) => setData({ ...data, fullName: v })}
                                            error={data?.fullName?.length === 0 ? t("Please enter Full Name") : ""}
                                            outline
                                            type="text"
                                            className=" flex-1 col-12"
                                        />
                                    )}
                                    {customerType == "customer" && (
                                        <InputField
                                            placeholder={t("Brand Name")}
                                            value={data?.brandName}
                                            label={t("Brand Name")}
                                            onChange={(v) => setData({ ...data, brandName: v })}
                                            error={data?.brandName?.length === 0 ? t("Please enter Brand Name") : ""}
                                            outline
                                            type="text"
                                            className="col-12"
                                        />
                                    )} */}

                                </div>
                                {customerType == "customer" &&
                                    (<PassengerField
                                        customer_id={data?.customer_id}
                                        className="col-12"
                                        onChange={(v) => {
                                            setData(prev => ({
                                                ...prev,
                                                customer_id: v
                                            }))
                                        }}
                                    />)
                                }
                            </div>
                            <div className="col-12 mt-3">
                                <Editor
                                    label="Notes"
                                    placeholder="Notes"
                                    value={data?.leadExtn?.notes}
                                    onChange={(htmlContent) => {
                                        setData({
                                            ...data,
                                            leadExtn: {
                                                ...data?.leadExtn,
                                                notes: htmlContent
                                            },
                                        });
                                    }}
                                />

                                {/* <p className="mt-2 ps-1 mb-1">Notes</p>


                                <textarea
                                    label="Notes"
                                    className="col-6 inputfield mt-0"
                                    style={{ width: "100%", borderRadius: "0.375rem", outline: "none", border: "1px solid #ced4da", }}
                                    rows="5"
                                    placeholder="Notes"
                                    value={data?.leadExtn?.notes}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            leadExtn: {
                                                ...data?.leadExtn,
                                                notes: e.target.value,
                                            },

                                        });
                                    }}
                                    type="text"
                                ></textarea> */}
                            </div>
                            <hr />

                            <div>

                                {editId && <Card className=" pb-3 ">

                                    <div style={{ backgroundColor: "#EBEBEB" }} className="h3 ps-3 py-2 d-flex align-items-center justify-content-between">
                                        <div> Itineraries</div>
                                        <div className="d-flex align-items-center">
                                            <Button
                                                onClick={() => { setShowItineraryForm({ clone: true }) }}
                                                className=" d-flex align-items-center p-1 me-2">
                                                <FaSearch className='' />
                                            </Button>
                                            <Button
                                                onClick={() => { setShowItineraryForm({ status: true }) }}
                                                className=" d-flex align-items-center p-1 me-2">
                                                <FaPlus className='' />
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="px-2 ">
                                        <ItineraryGrid
                                            onRequestDetail={(id) => {
                                                setShowItineraryForm({ status: true, id: id })
                                            }}
                                            leadId={editId}
                                        />
                                    </div>
                                </Card >
                                }

                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <CustomFieldComponent
                                        module={`lead-${SpaceService?.selectedSpace?._id}`}
                                        record_id={data?._id}
                                        columns={2}
                                        kind={`lead-${SpaceService?.selectedSpace?._id}`}
                                        propList={data?.leadExtn?.properties}
                                        onChange={(v) =>
                                            setData({
                                                ...data,
                                                leadExtn: {
                                                    ...data?.leadExtn,
                                                    properties: v,
                                                },
                                            })
                                        }
                                    />
                                </div>



                            </div>

                        </div>
                        {editId && <div className=" lead-form-right-side col-12 col-md-4 d-flex flex-column h-100 ">
                            <div style={{ borderLeft: "1px solid #EBEBEB" }} className="flex-1 d-flex flex-column justify-content-between align-items-center">
                                <div className="w-100" style={{ borderBottom: "1px solid #EBEBEB", borderTop: "1px solid #EBEBEB" }}>
                                    <div className="h3 mt-2 text-center">Comments</div>
                                </div>
                                <div style={{ backgroundColor: "#FBFBFC" }} className="comments-list w-100 flex-1 text-center">
                                    <Comment
                                        recordType={"lead"}
                                        recordOf={data?._id}
                                        byType={5}
                                        forType={[5]}
                                    />
                                </div>
                            </div>
                        </div>}
                    </div>

                </ModalComponent.Body>
                <ModalComponent.Footer>
                    <div className="d-flex justify-content-end">
                        {data?._id && <DeleteButton onClick={(e) => onDelete(e, data?._id)} loading={loading} />}
                        <SaveButton loading={loading} onClick={onSave} disabled={loading} />

                    </div>
                </ModalComponent.Footer>
            </ModalComponent>

            {showItineraryForm?.status && <ItineraryForm
                isOpen={showItineraryForm?.status}
                editId={showItineraryForm?.id}
                leadId={editId}
                toggleModal={() => { setShowItineraryForm({ status: false }) }}
            />}
            {/* 
            {isReminderOpen &&

                <ModalComponent
                    position={"top"}
                    size={"large"}
                    onToggle={() => { setIsReminderOpen(false) }}
                    isOpen={isReminderOpen}
                >
                    <ModalComponent.Body>
                        <Reminder
                            leadId={editId}
                        />
                    </ModalComponent.Body>
                </ModalComponent>} */}

            {showItineraryForm?.clone && (
                <ModalComponent
                    position={"top"}
                    size={"large"}
                    onToggle={() => { setShowItineraryForm({ clone: false }) }}
                    isOpen={showItineraryForm?.clone}
                >
                    <ModalComponent.Title>
                        {t("Itinerary")}
                    </ModalComponent.Title>

                    <ModalComponent.Body>
                        <Itinerary
                            // value={value}
                            forClone
                            insidePane={true}
                            multiMode={false}
                            onSelect={async (v) => {
                                cloneItinerary(v)
                            }}
                        />
                    </ModalComponent.Body>

                </ModalComponent>
            )}



        </React.Fragment>
    );
};

export default observer(LeadForm);
