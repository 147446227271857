import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineMailOpen } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  Button,
} from "reactstrap";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import InboxService from "./InboxService";
//Import Email Sidebar
import moment from 'moment';
import { FaTicketAlt } from "react-icons/fa";
import { MdDirectionsCar } from "react-icons/md";
import driverInbox from '../../assets/images/icons/driverInbox.svg';
import InboxSideBar from "./InboxSidebar";
import "./style.css";
import "../../components/HorizontalLayout/UserGuide/style.css"
import { RxCross2 } from "react-icons/rx";
import { MakeModelSelect, AddressField, ClientSelect } from "../../components";
import CustomTooltip from "../Booking/components/CustomTooltip"
import { UserContext } from "../../store/context/UserContext";

// const LeftMenuData = [{
//   title: 'Booking', module: 'Booking', _id: 1, renderer: () => (
//     <>
//       {<FaTicketAlt />}
//     </>
//   ),
//   totalRecords: InboxService?.bookingTotal,
// }, {
//   title: 'Vehicle', module: 'Vehicle', _id: 2, renderer: () => (
//     <>
//       {<MdDirectionsCar size={18} />}
//     </>
//   ),
//   totalRecords: InboxService?.vehicleTotal,
// }, {
//   title: 'Driver', module: 'Driver', _id: 3, renderer: () => (
//     <>
//       {<img height="20" src={driverInbox} alt="Driver" className="me-2" />}
//     </>
//   ),
//   totalRecords: InboxService?.driverTotal,
// }];


const getTitle = (inbox) => {
  if (inbox?.module === "Booking") {
    return <div className="row">
      <div className="col-2">{inbox?.moduleInfo?.tripNo}</div>
      {/* <div className="col-2">{tripTypeMapping[inbox?.moduleInfo?.tripType]}</div> */}
      <div className="col-3">{inbox?.moduleInfo?.startTime ? moment.unix(inbox?.moduleInfo?.startTime).format("DD-MM-YYYY") : ""}</div>
      <div className="col-4">{inbox?.moduleInfo?.client?.client_id ? <CustomTooltip>
        <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
          <ClientSelect
            asLabel
            value={inbox?.moduleInfo?.client?.client_id}
          /></CustomTooltip.Body>
        <CustomTooltip.ToolTip >
          <ClientSelect
            asLabel
            value={inbox?.moduleInfo?.client?.client_id}
          /></CustomTooltip.ToolTip>
      </CustomTooltip>
        : null
        //  <span style={{ fontStyle: "italic" }}>Walk In</span>
      }
      </div>
      <div className="col-3">{inbox?.moduleInfo?.src?.city}</div>
    </div>
  } else if (inbox?.module === "Driver") {
    const countryCode = inbox?.moduleInfo?.phoneNo?.countryCode;
    const phone = inbox?.moduleInfo?.phoneNo?.phone;
    return <div className="row d-flex">
      <div className="col-6">{inbox?.moduleInfo?.name}</div>
      <div className="col-6">{countryCode} {phone}</div>
    </div>
  } else if (inbox?.module === "Vehicle") {
    return <div className="row d-flex">
      <div className="col-2">{inbox?.moduleInfo?.regNo}</div>
      <div className="col-6">
        <MakeModelSelect
          asLabel
          value={inbox?.moduleInfo?.makeModel}
          onChange={(v) => { }}
        />
      </div>
      <div className="col-4">{inbox?.moduleInfo?.year}</div>
    </div >
  } else {
    return null;
  }
};

const Inbox = () => {
  const { t } = useContext(I18nContext);
  const navigtate = useNavigate();
  const { userContent } = useContext(UserContext);
  const { showError } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [LeftMenuData, setLeftMenuData] = useState([
    {
      title: 'Booking', module: 'Booking', _id: 1, renderer: () => (
        <>
          {<FaTicketAlt size={18} />}
        </>
      ),
      totalRecords: InboxService?.bookingTotal,
    }, {
      title: 'Vehicle', module: 'Vehicle', _id: 2, renderer: () => (
        <>
          {<MdDirectionsCar size={18} />}
        </>
      ),
      totalRecords: InboxService?.vehicleTotal,
    }, {
      title: 'Driver', module: 'Driver', _id: 3, renderer: () => (
        <>
          {<img height="20" src={driverInbox} alt="Driver" className="me-2" />}
        </>
      ),
      totalRecords: InboxService?.driverTotal,
    }]);

  useEffect(() => {
    const updatedLeftMenuData = LeftMenuData.map(item => {
      switch (item.module) {
        case 'Booking':
          return { ...item, totalRecords: InboxService?.bookingTotal };
        case 'Vehicle':
          return { ...item, totalRecords: InboxService?.vehicleTotal };
        case 'Driver':
          return { ...item, totalRecords: InboxService?.driverTotal };
        default:
          return item;
      }
    });
    setLeftMenuData(updatedLeftMenuData);
  }, [InboxService?.driverTotal, InboxService?.vehicleTotal, InboxService?.bookingTotal]);

  const updateData = async (data, id) => {
    if (loading) return;
    setLoading(true);
    try {
      await InboxService.edit(data, id);
      // await fetchData();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };
  const fetchData = async (filterUrl = "") => {
    if (loading) return;
    setLoading(true);
    try {
      await InboxService.fetch(filterUrl = `module=Vehicle`);
      await InboxService.fetch(filterUrl = `module=Driver`);
      await InboxService.fetch(filterUrl = `module=Booking`);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    if (userContent?.rights?.includes(2)) {
      fetchData();
    }
  }, [InboxService?.rowsPerPage,])


  const handleNavigate = (dataObj) => {
    const { module, ref_id } = dataObj;
    let path;

    switch (module) {
      case 'Booking':
        path = `/booking/edit/${ref_id}`;
        break;
      case 'Driver':
        path = `/employee/edit/${ref_id}`;
        break;
      case 'Vehicle':
        path = `/vehicle/edit/${ref_id}`;
        break;
      default:
        return;
    }
    navigtate(path);
    InboxService.toggleModal()
  }

  const changePage = (page, rowsPerPage) => {
    if (
      page > 0 &&
      page <=
      (InboxService.currentModuleTotal % rowsPerPage === 0
        ? InboxService.currentModuleTotal / rowsPerPage
        : InboxService.currentModuleTotal / rowsPerPage + 1)
    ) {
      InboxService.onPaginationChange(page, rowsPerPage);
    }
  };
  const tripTypeMapping = {
    1: 'Rental',
    2: 'Shared',
    3: 'Self Drive',
    4: 'Transfer',
    5: 'Self Drive',
    6: 'Route',
    7: 'Airport Pick',
    8: 'Airport Drop',
    9: 'Tour Package',
    10: 'Outstation OneWay',
    11: 'Outstation Returning',
    12: 'Lease'
  }


  return (
    <>
      <div className="h-100 w-100 App pb-5">
        <div className="d-flex justify-content-between align-items-center mb-2 ">
          <div className='fs-4 fw-600'>{t("Inbox")}</div>
          <div className='ms-auto p-2' style={{ cursor: "pointer" }} onClick={InboxService.toggleModal}><RxCross2 size={20} />
          </div>
        </div>

        <div class="parent-container">
          <div className="d-flex">
            <InboxSideBar SidebarData={LeftMenuData} />
            <div className="w-100 mx-3 p-2" style={{ borderRadius: 5, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
              <div>
                {/* Render Email Top Tool Bar */}
                {InboxService.currentModuleRecords.length === 0 && <div className="mt-1" style={{ margin: "auto", fontSize: "1rem" }}>No Messages Found</div>}
                <ul className="message-list py-1 ps-0 hide-scrollbar" style={{ height: "65vh", overflowY: "scroll" }}>
                  {InboxService.currentModuleRecords.map((inbox, index) => {
                    return <li
                      key={index} onClick={async () => {
                        await updateData({
                          ...inbox,
                          seen: true
                        }, inbox?.ref_id);
                        handleNavigate(inbox);
                        // navigate(`/${InboxService?.module.toLowerCase()}/edit/${inbox?.ref_id}`);
                      }}
                      style={{ fontWeight: !inbox?.seen ? "800" : "400)", color: !inbox?.seen ? "black" : "inherit", cursor: "pointer", backgroundColor: index % 2 == 0 ? "#fff" : 'rgba(0, 0, 0, 0.05)' }}
                    >
                      <div className="d-flex align-items-center col-mail col-mail-1" onClick={(e) => e.stopPropagation()}>
                        <div className="px-2 me-2">
                          {!inbox?.seen ? <MdEmail size={22} /> : <HiOutlineMailOpen size={20} />}
                        </div>
                        <div className="title ms-2" style={{ left: "30px", marginTop: "2px" }}>
                          {getTitle(inbox)}
                        </div>
                        {/* <span className="star-toggle far fa-star" /> */}
                      </div>
                      <div className="col-mail col-mail-2">
                        <span className={`bg-${inbox?.priority === 1 ? "success" : inbox?.priority === 2 ? "info" : inbox?.priority === 3 ? "secondary" : inbox?.priority === 4 ? "warning" : inbox?.priority === 5 ? "danger" : "dark"} badge me-2`}>
                          {inbox?.priority === 1 ? "Info" : inbox?.priority === 2 ? "Low" : inbox?.priority === 3 ? "Medium" : inbox?.priority === 4 ? "High" : inbox?.priority === 5 ? "Critical" : null}
                        </span>
                        <div className="subject" style={{ left: "90px" }}>
                          {inbox?.detail}
                        </div>
                        <div className="dateInbox">
                          {inbox?.createdAt && (() => {
                            const dateMoment = moment.unix(inbox?.createdAt);
                            const now = moment();
                            const sameYear = dateMoment.year() === now.year();

                            // Function to get the day with the correct suffix
                            function getDayWithSuffix(day) {
                              const j = day % 10,
                                k = day % 100;
                              if (j == 1 && k != 11) {
                                return day + "st";
                              }
                              if (j == 2 && k != 12) {
                                return day + "nd";
                              }
                              if (j == 3 && k != 13) {
                                return day + "rd";
                              }
                              return day + "th";
                            }

                            if (dateMoment.isSame(now, 'day')) {
                              return dateMoment.format('HH:mm A');
                            } else if (sameYear) {
                              return `${getDayWithSuffix(dateMoment.date())} ${dateMoment.format('MMMM')}`;
                            } else {
                              return dateMoment.format('YYYY');
                            }
                          })()}
                        </div>
                      </div>
                    </li>
                  })}
                </ul>
              </div>
              <div className="d-flex gap-4" style={{ maxWidth: "fit-content", alignItems: "center", marginLeft: "auto" }}>
                <div className="pe-0 me-0 mt-2 " style={{ marginLeft: "auto" }}>   Showing &nbsp;
                  {InboxService?.currentModuleRecords?.length === 0 ? "0" : (InboxService?.page - 1) * InboxService?.rowsPerPage + 1} -{" "}
                  {InboxService?.page * InboxService?.rowsPerPage > InboxService?.currentModuleTotal
                    ? InboxService?.currentModuleTotal
                    : InboxService?.page * InboxService?.rowsPerPage}{" "}
                  {InboxService?.currentModuleTotal > -1 ? " of " + InboxService?.currentModuleTotal : ''}
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <div className="btn-group float-end">
                    <Button
                      type="button"
                      color="success"
                      size="sm"
                      className="waves-effect"
                      onClick={(e) => {
                        e.preventDefault();
                        changePage(InboxService?.page - 1, InboxService?.rowsPerPage);
                      }}
                    >
                      <i className="fa fa-chevron-left" />
                    </Button>
                    <Button
                      type="button"
                      color="success"
                      size="sm"
                      className="waves-effect"
                      onClick={(e) => {
                        e.preventDefault();
                        changePage(InboxService?.page + 1, InboxService?.rowsPerPage);
                      }}
                    >
                      <i className="fa fa-chevron-right" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default observer(Inbox);
