

export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/company/grid?forSupplier=true&`;
        if (sizePerPage) {
            url += `rows=${sizePerPage}&`;
        } else {
            url += 'rows=-1&';
        }
        // if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    getSupplier: (id) => `/api/company/grid?forSupplier=true&_id=${id}`,
    getDataBySupplierId: (id) => `/api/company/grid?forSupplier=true&supplier_id=${id}`,

};



