import React, { useContext, useEffect } from 'react';
import { Col, Row, } from "reactstrap";
import { Checkbox, InputField, NameField, PhoneNum } from '../../components';
import { I18nContext } from '../../store/context/i18nContext';
import SearchUser from '../Users/SearchUser';
import BookingService from './BookingService';
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import { Tooltip } from 'react-tooltip';
import RoutePassengerAddressPicker from '../../components/RoutePassengerAddressPicker';

const PassengersNew = (props) => {
    const { passengers, onChange, client_id, type = 1, stops = [] } = props;
    const { t } = useContext(I18nContext);

    const handleRemovePassenger = (index) => {
        const updatedPassengers = passengers.filter((_, i) => i !== index);
        onChange(updatedPassengers);
    };

    const handleFieldChange = ({ index, field, v }) => {
        const updatedPassengers = passengers?.map((p, i) => {
            if (i === index) {
                return { ...p, [field]: v };
            }
            return p;
        })
        onChange(updatedPassengers);
    };

    const fetchCustomerData = async (data, index, type) => {
        if (!data) {
            return;
        }
        try {
            let url = "";
            if (type === "phone") {
                if (!data?.countryCode || !data?.phone) {
                    return;
                }
                url = `&phoneNo.countryCode=${data?.countryCode}&phoneNo.phone=${data?.phone}`;
            } else if (type === 'email') {
                url = `&email=${data}`
            } else {
                return;
            }
            if (client_id) {
                url += `&client_id=${client_id}`
            }
            const response = await BookingService?.customerGrid(url);
            if (response?.rows?.length) {
                let updatedPassengers;
                const name = response?.rows[0]?.name;
                const salutation = response?.rows[0]?.salutation;
                const customer_id = response?.rows[0]?._id;
                const home = response?.rows[0]?.home;
                updatedPassengers = passengers?.map((p, i) => {
                    if (i === index) {
                        return {
                            ...p,
                            ...(type === "phone" && { email: response?.rows[0]?.email }),
                            ...(type === "email" && { phone: response?.rows[0]?.phoneNo }),
                            name: name,
                            salutation: salutation,
                            customer_id: customer_id,
                            ...(home && { home })
                        };
                    }
                    return p;
                })
                onChange(updatedPassengers);
            }
        } catch (error) {

        }
    }

    const addPassenger = () => {
        const pax = {
            index: passengers?.length ?? 0,
            salutation: "Mr",
            phone: {
                countryCode: 91,
            }
        };

        const updatedPassengers = passengers ? [...passengers, pax] : [pax];
        onChange(updatedPassengers);
    };

    const customerIdsArray = passengers?.map(obj => obj?.customer_id);

    useEffect(() => {
        if (!passengers?.length) {
            addPassenger()
        }
    }, [passengers?.length])

    return (
        < >
            {(passengers || [])?.map((passenger, index) => (
                <div key={index} >
                    <div className='p-1 pt-1  d-flex align-items-center flex-wrap'>
                        <div style={{ width: 50 }} className='col-1 d-flex  align-items-center'>
                            <FaPersonWalkingLuggage size={24} />
                            <h5 style={{ fontWeight: "bold" }} className="text-center mt-2 ms-2">
                                <span>{index + 1 + "."} </span>
                            </h5>
                        </div>

                        {!passenger?.anonymous ?
                            <div style={{ flex: 1 }} className="">
                                <Row className="mx-1">
                                    <Col className="col-12 col-sm-4 py-0 mb-4 mb-sm-0">
                                        <PhoneNum
                                            placeholder={t("Phone No")}
                                            value={{
                                                countryCode: passenger?.phone?.countryCode,
                                                phone: passenger?.phone?.phone,
                                            }}
                                            outline
                                            onblur={() => {
                                                if (!passenger?.customer_id) {
                                                    fetchCustomerData(passenger?.phone, index, 'phone')
                                                }
                                            }}
                                            label={t("Phone")}
                                            rightComponentStyle={{ width: "0px" }}

                                            onChange={(v) => {
                                                if (v) {
                                                    const phone = {
                                                        phone: v.phone,
                                                        countryCode: v.countryCode
                                                    };
                                                    const updatedPassengers = passengers?.map((p, i) => {
                                                        if (i === index) {
                                                            return {
                                                                ...p,
                                                                phone: phone
                                                            };
                                                        }
                                                        return p;
                                                    })
                                                    onChange(updatedPassengers);
                                                }
                                            }}
                                            RightComponent={() => {
                                                return <>
                                                    <SearchUser
                                                        style={{
                                                            backgroundColor: "#F1F1F1",
                                                            borderRadius: "0px 5px 5px 0px",
                                                            position: "relative",
                                                            height: "34px",
                                                            width: '30px',
                                                            right: '31px',
                                                            top: "1.5px",
                                                            cursor: 'pointer'
                                                        }}
                                                        hideCheckboxes={true}
                                                        onlyIcon={true}
                                                        value={customerIdsArray}
                                                        userType="Passenger"
                                                        onChange={(v) => {
                                                            if (v) {
                                                                const pax = {
                                                                    email: v?.email,
                                                                    name: v?.name,
                                                                    salutation: v?.salutation,
                                                                    phone: {
                                                                        countryCode: v?.phoneNo?.countryCode,
                                                                        phone: v?.phoneNo?.phone,

                                                                    },
                                                                    customer_id: v?._id
                                                                };
                                                                let updatedPassengers;

                                                                if (passengers[index]?.customer_id) {
                                                                    updatedPassengers = passengers ? [...passengers, pax] : [pax];
                                                                }
                                                                else {
                                                                    passengers = passengers ? [...passengers] : [];
                                                                    passengers[index] = pax;
                                                                    updatedPassengers = [...passengers]
                                                                }

                                                                // updatedPassengers = passengers ? [...passengers, pax] : [pax];
                                                                onChange(updatedPassengers);
                                                            }
                                                        }}
                                                    />
                                                </>
                                            }}
                                        />
                                        {/* } */}
                                    </Col>
                                    <Col className="col-12 col-sm-4 mb-4 mb-sm-0">
                                        <InputField
                                            value={passenger?.email}
                                            outline
                                            label={t("Email")}
                                            placeholder="abc@mail.com"
                                            type="email"
                                            onblur={() => {
                                                if (!passenger?.customer_id) {
                                                    fetchCustomerData(passenger?.email, index, 'email')
                                                }
                                            }}
                                            error={!passenger?.email ? t("Please enter Email") : ""}
                                            onChange={(v) => handleFieldChange({ index, field: 'email', v })}
                                            showClear={false}
                                            RightComponent={() => {
                                                return <>
                                                    <SearchUser
                                                        style={{
                                                            backgroundColor: "#F1F1F1",
                                                            borderRadius: "0px 5px 5px 0px",
                                                            position: "relative",
                                                            height: "34px",
                                                            width: '30px',
                                                            right: '-4px',
                                                            top: "-1px",
                                                            cursor: 'pointer'
                                                        }}
                                                        hideCheckboxes={true}
                                                        onlyIcon={true}
                                                        value={customerIdsArray}
                                                        userType="Passenger"
                                                        onChange={(v) => {
                                                            if (v) {
                                                                const pax = {
                                                                    email: v?.email,
                                                                    name: v?.name,
                                                                    salutation: v?.salutation,
                                                                    phone: {
                                                                        countryCode: v?.phoneNo?.countryCode,
                                                                        phone: v?.phoneNo?.phone,

                                                                    },
                                                                    customer_id: v?._id
                                                                };

                                                                let updatedPassengers;

                                                                if (passengers[index]?.customer_id) {
                                                                    updatedPassengers = passengers ? [...passengers, pax] : [pax];
                                                                }
                                                                else {
                                                                    passengers = passengers ? [...passengers] : [];
                                                                    passengers[index] = pax;
                                                                }
                                                                // const updatedPassengers = passengers ? [...passengers, pax] : [pax];
                                                                onChange(updatedPassengers);
                                                            }
                                                        }}
                                                    />

                                                </>
                                            }}
                                        // onClickRight={
                                        />
                                    </Col>
                                    <Col className="col-12 col-sm-4">
                                        <NameField
                                            outline
                                            onNameChange={(v) => handleFieldChange({ index, field: 'name', v })}
                                            onSalutationChange={(v) => { handleFieldChange({ index, field: 'salutation', v }) }}
                                            name={passenger?.name}
                                            salutation={passenger?.salutation}
                                            label={t("Name")}
                                        />
                                    </Col>
                                </Row>
                            </div> : null}

                        <div className=' d-flex align-items-center ' style={{ position: 'relative' }}>
                            <Checkbox
                                leftAlign
                                label={t("Anonymous")}
                                checked={passenger?.anonymous}
                                className="flex-1"
                                onClick={(v) => {
                                    handleFieldChange({ index, field: 'anonymous', v: !passenger?.anonymous })
                                }}
                            />
                            {(passengers?.length != 1) ? <div onClick={() => handleRemovePassenger(index)} style={{ cursor: "pointer" }}>
                                <i
                                    style={{
                                        fontSize: "20px",
                                        border: "1px solid red",
                                        borderRadius: "20px",
                                        color: "red",
                                    }}
                                    className="dripicons-cross"
                                />
                            </div> : null}
                            <div
                                style={{
                                    marginLeft: "5px",
                                    cursor: "pointer",
                                    width: "22px",
                                }}
                            >
                                {index == passengers?.length - 1 ? (
                                    <div
                                        style={{ cursor: "pointer" }}
                                        onClick={addPassenger}
                                    >
                                        <Tooltip noArrow style={{
                                            zIndex: 999999,
                                            maxWidth: '300px',
                                            height: "auto",
                                            backgroundColor: '#F2F2F2',
                                            color: "black",
                                            whiteSpace: 'pre-wrap',
                                            overflow: 'auto',
                                            fontWeight: 'bold'
                                        }} anchorSelect={`#not-clickable-add_passenger`}>
                                            Add Passenger
                                        </Tooltip>
                                        <i id={`not-clickable-add_passenger`}
                                            style={{
                                                fontSize: "20px",
                                                border: "1px solid black",
                                                borderRadius: "20px",
                                                color: "black",
                                            }}
                                            className="dripicons-plus"
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <RoutePassengerAddressPicker
                        passenger={passenger}
                        stops={stops}
                        type={type}
                        onChange={(field, v) => handleFieldChange({ index, field, v })}
                    />
                </div>
            ))}
        </>
    );
};

export default PassengersNew;
