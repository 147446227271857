export const ENDPOINTS = {
  getClientGroup: `/api/clientgroup/grid?page=1&rows=100`,
  getrateareas: `/api/rate-area/grid`,
  getratecalstruct: `/api/rate/calculation/grid`,
  getAllFundAccounts: `/api/fund/account/grid`,
  getratecancel: `/api/rate/cancellation/grid`,
  getrateterm: `/api/rate/term/grid`,
  getratetnc: `/api/rate/tnc/grid`,
  getAllClients: `/api/corp/clients/self/map`,
  getAllRates: `/api/tariff/names/all`,
  deleteRate: (id) => `/api/tariff/names/${id}/delete`,
  saveRates: `/api/tariff/names/save`,
  getClients: `/api/corp/clients/fetch/all`,
  getAllItems: `/api/v1/rate/items/list`,
  getAllRateItemName: `/api/o/rate/names`,
  getAllRateNames: `/api/rate-name/grid`,
  //api nhi mil rhi vehicle ki
  saveNew: `/api/tag/save`,
  getAllVehicles: ({ page, regNo }) =>
    `/api/vehicle/grid?rows=20${regNo ? `&regNo=${regNo}` : ""
    }&page=${page ? page : "1"}`,
  getAllMakes: `/api/vehicletype/make/list`,
  getALLTags: `/api/tag/all`,
  deletetag: (id) => `/api/tag/${id}/delete`,

  //NameMaster
  getAllName: `/api/cpanel/clients/list`,
  getName: (kind) => `/api/name-master/grid?kind=${kind}`,
  // getName:(type)=>`/api/name/master/${type}/all`,
  deleteName: (id) => ` /api/name/master/${id}/delete`,
  saveName: `/api/name/master/save`,
  getAllZones: `/api/zone/grid`,
  getAllCurrencies: `/api/o/currencies`,
  // getAllLanguages: `/api/o/corp/language`,
  getAllLanguages: `/api/o/language/allLang`,
  getallContents: `/api/admin/contents`,
  getAllEntities: (x) =>
    x ? `/api/businessUnits/${x}/map` : "/api/businessUnits/map",
  getEntities: (client) => `/api/businessunit/${client}/businessUnitlist`,
  getAllGarages: `/api/corp/garage/map`,
  getAllSupervisors: `/api/supervisor/getAllSupervisor`,
  getVendors: (id) => `/api/corp/client/${id}/default/vendorbu`,
  getAllDrivers: (x) =>
    `/api/drivers/data?rows=20${x ? `&name=${x}` : ""}&page=1`,
  getAllSupervisorRoles: `/api/role/map`,
  getAllCityGroups: `/api/citygroups/cityGroupsList`,
  getAllVendors: `/api/corp/vendors/self/map`,
  getVendorGroup: ` /api/vendorgroups/grid`,
  getCityGroupsByCountry: (country) =>
    `/api/citygroups/grid?country=${country}&rows=1000`,
  // getAllVendors: `/api/corp/vendors/fetch/all`,
  getAllTariffTypes: `/api/rates/tariff/type`,
  getAllTariffNames: `/api/tariff/names/all`,
  getAllTransferGroups: `/api/transfer/group/getAll`,
  getAllTimeZones: `/api/o/timezones`,
  getAllCountryLangs: `/api/language/allLang`,
  getAllTaxSlabs: `/api/taxslab/primary/all`,

  getAllExpenseAccountTypes: `/api/expenseaccount/list`,
  getAllArticles: `/api/article/list`,
  getAllCampus: (x) =>
    x ? `/api/campus/${x}/campusMap` : "/api/corp/campuses/map",
  getAllDutyHours: `/api/shift/grid`,
  getDesignations: `/ api / designation / all`,
  // getDept: `/ api / department / all`,
  getRank: `/ api / rank / all`,
};
