import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

function RadioSelection({ data, label, value, onChange, isDisabled }) {
    return (
        <>
            <div className='fw-bold mb-1 font-size-14'>{label}</div>
            {
                data?.map((d, i) => (
                    <FormGroup check key={i}>
                        <Label check>
                            <Input
                                disabled = {isDisabled}
                                checked={d.value == value} 
                                type="radio" 
                                name={d.name} 
                                onChange={(e) => {
                                    onChange(d.value)
                                }} /> 
                                {d.name}
                        </Label>
                    </FormGroup>
                ))
            }
        </>
    )
}

export default RadioSelection