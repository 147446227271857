
// import { observer } from 'mobx-react-lite';
// import React, { useContext, useEffect, useState } from 'react';
// import { BiCollapseHorizontal, BiExpandHorizontal } from "react-icons/bi";
// import { FaHourglass } from 'react-icons/fa';
// import RosteringService from "../../pages/Rostering/RosteringService";
// import { DialogContext } from '../../store/context/DialogContext';
// import { getPassengerInitials } from "../../util/Util";
// import DraggableComponent from '../dragAndDrop/DraggableComponent';
// import DroppableComponent from '../dragAndDrop/DroppableComponent';
// import PassengerCard from "./PassengerCard";
// import "./styles.css";
// import { generateUniqueId } from '../../pages/Rostering/utils/helper';
// import SpaceService from '../space/SpaceService';


// function PassengerGrid({ selectedPassenger ,collapsed,toggleCollapse}) {

//   const { showError } = useContext(DialogContext);
//   const {passengers,initialized,updatePassengerList}=RosteringService;
//   const {selectedFolder}=SpaceService

//   useEffect(()=>{
//       if(initialized && selectedFolder){
//         updatePassengerList(selectedFolder);
//       }
//   },[selectedFolder,initialized])





//   return (
//     <div className={`col-sm-12  ${collapsed ? 'col-md-3 collapsed' : 'col-md-5'}`}  >
//       <DroppableComponent droppableId="passengerGrid" className="h-100  mx-1 p-2 rounded-2 pb-5 rostering-trip-view">
//         <div className="d-flex justify-content-between align-items-center mb-2">
//           <div className="d-flex align-items-center gap-1 px-2">
//           <FaHourglass  color="#999999"/>
//           <div className="mb-0 fw-bold font-size-14">{collapsed ? '' : 'Awaiting'}</div>
//           </div>
//           <div onClick={toggleCollapse} className="bg-light p-1 rounded-2 cursor-pointer">
//             {collapsed ? <BiExpandHorizontal size={20}/> : <BiCollapseHorizontal size={20} />}
//           </div>
//         </div>
//         <div className="overflow-y-auto  mh-100">
//           {passengers.map((passenger, index) => (
//             <DraggableComponent isDragDisabled={!passenger?.residence} key={passenger?.customer_id} draggableId={passenger?.customer_id+generateUniqueId()} index={index}>
//               <div className={` p-2 ${passenger?.residence?'bg-white':'bg-gray'} border-top  ${collapsed ? 'collapsed-circle d-flex justify-content-center align-items-center my-1' : (selectedPassenger===passenger?.customer_id?'selected-passenger':'')} `}>
//                 {collapsed ? getPassengerInitials(passenger.name ?? "") : <PassengerCard passenger={passenger}/> }
//               </div>
//             </DraggableComponent>
//           ))}
//         </div>
//       </DroppableComponent>

//     </div>
//   );
// }

// export default observer(PassengerGrid);

// // //TODO add loader


import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef } from 'react';
import { BiCollapseHorizontal, BiExpandHorizontal } from "react-icons/bi";
import { FaHourglass } from 'react-icons/fa';
import RosteringService from "../../pages/Rostering/RosteringService";
import { DialogContext } from '../../store/context/DialogContext';
import { getPassengerInitials } from "../../util/Util";
import DraggableComponent from '../dragAndDrop/DraggableComponent';
import DroppableComponent from '../dragAndDrop/DroppableComponent';
import PassengerCard from "./PassengerCard";
import "./styles.css";
import { generateUniqueId } from '../../pages/Rostering/utils/helper';
import SpaceService from '../space/SpaceService';

function PassengerGrid({ selectedPassenger, collapsed, toggleCollapse,clickMarker }) {

  const { showError } = useContext(DialogContext);
  const { passengers, initialized, updatePassengerList } = RosteringService;
  const { selectedFolder } = SpaceService;
  const passengerGridRef = useRef(null);

  useEffect(() => {
    if (initialized && selectedFolder) {
      updatePassengerList(selectedFolder);
    }
  }, [selectedFolder, initialized]);

  useEffect(() => {
    try {
      if (selectedPassenger && passengerGridRef.current) {
        const selectedElement = passengerGridRef.current?.querySelector?.(`#passenger-${selectedPassenger}`);
        if (selectedElement) {
          selectedElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    } catch (error) {
      
    }
  }, [selectedPassenger]);

  return (
    <div className={`col-sm-12 ${collapsed ? 'col-md-3 collapsed' : 'col-md-5'}`} ref={passengerGridRef}>
      <DroppableComponent droppableId="passengerGrid" className="h-100 mx-1 p-2 rounded-2 pb-5 rostering-trip-view">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center gap-1 px-2">
            <FaHourglass color="#999999" />
            <div className="mb-0 fw-bold font-size-14">{collapsed ? '' : 'Awaiting'}</div>
          </div>
          <div onClick={toggleCollapse} className="bg-light p-1 rounded-2 cursor-pointer">
            {collapsed ? <BiExpandHorizontal size={20} /> : <BiCollapseHorizontal size={20} />}
          </div>
        </div>
        <div className="overflow-y-auto mh-100">
          {passengers.map((passenger, index) => (
            <DraggableComponent isDragDisabled={!passenger?.residence} key={passenger?.customer_id} draggableId={passenger?.customer_id + generateUniqueId()} index={index}>
              <div onClick={() => clickMarker(passenger?.customer_id)} id={`passenger-${passenger?.customer_id}`} className={`p-2 ${passenger?.residence ? 'bg-white' : 'bg-gray'} border-top ${collapsed ? 'collapsed-circle d-flex justify-content-center align-items-center my-1' : (selectedPassenger === passenger?.customer_id ? 'selected-passenger' : '')}`}>
                {collapsed ? getPassengerInitials(passenger.name ?? "") : <PassengerCard passenger={passenger} />}
              </div>
            </DraggableComponent>
          ))}
        </div>
      </DroppableComponent>
    </div>
  );
}

export default observer(PassengerGrid);
