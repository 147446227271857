import React, { useState } from "react";
import { SelectField } from "../../SelectField/SelectField";

export default function VehicleComplianceStatusSelect(props) {
  const data = [
    { label: "Compliant", value: 1 },
    { label: "Not Compliant", value: 2 },
    { label: "Due", value: 3 },
  ];

  return (
    <React.Fragment>
      <SelectField
        className={props?.className}
        onChange={props?.onChange}
        data={data}
        showClear={props?.showClear}
        value={props?.value}
        label={"Compliance Status"}
        error={props?.value ? "Please Select Compliance Status" : ""}
        showErr={props?.showErr}
      />
    </React.Fragment>
  );
}
