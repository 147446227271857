import React, { useEffect, useRef } from "react";
import CurrencySelect from "../Dropdowns/CurrencySelect/CurrencySelect";

export default function CurrencyAmountField({ outline, value, required, onChange, showErr, className, label, asLabel }) {
  const inputRef = useRef(null)

  useEffect(() => {
    if (!value?.amount && inputRef.current) {
      inputRef.current.value = ""
    }
  }, [value])

  useEffect(() => {
    onChange({ ...value, currency: "INR" })
  }, [])
  return (
    <>

      {asLabel ?
        <div>
          {value?.currency} {value?.amount}
        </div>

        : <div className={className + " d-flex justify-content-center align-items-center "}>
          {!outline && <div
            style={{ width: "120px", fontSize: 12, fontWeight: "500" }}
          >
            {label}
            {required && <font color="red">*</font>}
          </div>}

          <div
            style={{ margin: "10px 0px 5px 0px", height: "36px" }}
            className={" input-group  flex-1"}
          >
            <div className="input-group-prepend">
              <CurrencySelect
                className={`m-0 `}
                value={value?.currency ? value?.currency : 'INR'}
                noLabel
                innerContainerStyle={{ borderRadius: "6px 0px 0px 6px", }}
                style={{ borderRadius: "6px 0px 0px 6px", }}
                showClear={false}
                outline
                onChange={(v) => onChange({ ...value, currency: v?.value ? v?.value : 'INR' })}
                showErr={showErr}
                inputGroup
              />
            </div>
            <input
              ref={inputRef}
              placeholder={label ?? "Amount"}
              style={{ height: "36px", border: "none ", borderBottom: "1px solid #ced4da ", borderRadius: "0px", }}
              value={value?.amount ? value?.amount : 0.00}
              onChange={(e) => onChange({ ...value, amount: e?.target?.value })}
              type="number"
              className="form-control"
            />

          </div>

        </div>}
    </>

  );
}


