import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

//Import Images

const TestV2 = () => {

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="my-5 pt-sm-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="text-center">
                <div>
                </div>
                <h4 className="display-3  mt-4 mb-3">Coming Soon</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TestV2;
