import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu, Row } from "reactstrap";
import {
  BulkUpload,
  CheckButton,
  DataGrid,
  DeleteLink,
  EditLink,
  Layout,
  ModalComponent
} from "../../components";
import CustomOperations from "../../components/CustomField/CustomOperations";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import GroupTourSidebar from "../GroupTour/sideBar/GroupTourSidebar";
import GroupTourSidebarService from "../GroupTour/sideBar/GroupTourSidebarService";
import EditFlights from "./EditFlight";
import { STRUCTURE } from "./FlightConstant";
import { GridColumns, } from "./FlightGridColumns";
import FlightService from "./FlightService";
import BulkWhatsapp from "../Communication/BulkWhatsapp";
import { toJS } from "mobx";
import { TOUR_TOGGLE_DATA } from "../BookingsNew/utils/constants";
import Space from "../../components/space/Space";


const getUniquePassengers = (records) => {
  const passengersMap = new Map();
  records.forEach(record => {
    record.passengers.forEach(passenger => {
      if (!passengersMap.has(passenger.customer_id)) {
        passengersMap.set(passenger.customer_id, { ...(toJS(passenger) ?? {}), phoneNo: passenger?.phone });
      }
    });
  });
  return Array.from(passengersMap.values());
};

const Flights = ({
  value,
  insidePane,
  multiMode,
  onSelect = () => { },
  formConfig = {
    showChecks: true,
    hideFilter: false,
  },
  passengerId
}) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/flight/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [uploadPopup, setUploadPopup] = useState(false);
  const [showCustomizationOptions, setShowCustomizationOptions] = useState(false);
  const [bulkWhatsappPopup, setBulkWhatsappPopup] = useState(false);

  const multiplyFieldsBy100 = (data) => {
    const multiplyIfExists = (obj, fields) => {
      fields.forEach(field => {
        if (obj[field] !== undefined) {
          obj[field] = obj[field] * 100;
        }
      });
    };

    if (data.clientFee) {
      multiplyIfExists(data.clientFee, ['subTotal', 'totalDiscounts', 'totalTaxes']);
    }

    if (data.supplierFee) {
      multiplyIfExists(data.supplierFee, ['subTotal', 'totalDiscounts', 'totalTaxes']);
    }

    return data;
  };


  const saveBulk = async (data) => {
    try {
      const modifiedData = multiplyFieldsBy100({ ...data });
      const account = await FlightService.save({
        ...(modifiedData ?? {}),
        space_id: GroupTourSidebarService.selectedFolder.type == "single" ? null : GroupTourSidebarService.selectedFolder._id,
      });
      if (account) {
        return { isUploaded: true, message: "" }
      }
      return { isUploaded: false, message: "Failed unexpectedly" }
    } catch (error) {
      return { isUploaded: false, message: JSON.parse(error?.message)?.customMessage ?? JSON.parse(error?.message)?.message }
    }
  };

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await FlightService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };


  const preFetchGrid = () => {
    let { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    if (GroupTourSidebarService.selectedFolder?._id) {
      filterUrl += (`&space_id=${GroupTourSidebarService.selectedFolder.type == "single" ? null : GroupTourSidebarService.selectedFolder._id}`)
      setFilterURL(filterUrl)
    }

    if (passengerId) {
      filterUrl += (`&passengers.customer_id=in[${passengerId}]`)
      setFilterURL(filterUrl)
    }

    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }

  useEffect(() => {
    preFetchGrid()
  }, [GroupTourSidebarService.selectedFolder?._id, passengerId])


  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onDelete = async (event, id) => {
    console.log(event, id);
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await FlightService.delete(id);
        setLoading(false);
        showMessage("Flight Deleted SuccessFully", "Deleted");
        navigate("/flight");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await FlightService.bulkDelete(selectedIDs);
        showMessage(t("Deleted"), "Deleted Successfully");
        setShowDetailPage(false);

      } catch (e) {
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback((flight) => {
    return (
      <>
        {userContent?.rights?.includes(1502) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/flight/edit/${flight?._id}`);
              setEditID(flight?._id);
              setShowDetailPage(true);

            }}
          />
        }
        {userContent?.rights?.includes(1503) ?
          <DeleteLink
            onClick={(event) => onDelete(event, flight?._id)}
          />
          : null}
        {(!multiMode && insidePane && formConfig?.showChecks) ? (
          <CheckButton
            onClick={() => {
              onSelect(flight?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent, formConfig?.showChecks])


  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch()
    }
  }
    , [SetupProgressService?.records])

  return (
    <div className="d-flex">
      {/* {insidePane ? null : <div style={{ minWidth: "200px" }} className={insidePane ? "" : "page-content"}>

        <Space
          showEverything={false}
          module="Event"
          filterStructure = {STRUCTURE}
        />

      </div>} */}

      {insidePane ? null : <div style={{ minWidth: "200px" }} className={insidePane ? "" : "page-content"}>
        <GroupTourSidebar module="events" />
      </div>}


      <BulkUpload
        show={uploadPopup}
        onClose={() => { setUploadPopup(false) }}
        onSheetClose={() => { preFetchGrid() }}
        title={t("Flight")}
        kind={"Flight"}
        upload={saveBulk}
      />

      {bulkWhatsappPopup ? (
        <ModalComponent
          position={"top"}
          size={"full-screen"}
          isOpen={bulkWhatsappPopup}
          onToggle={() => setBulkWhatsappPopup(false)}
        >
          <ModalComponent.Title>
            <Row>
              <span style={{ fontSize: "15px" }}>
                Bulk Message
              </span>
            </Row>
          </ModalComponent.Title>
          <ModalComponent.Body>
            <BulkWhatsapp
              forFlightOnly
              onBack={() => setBulkWhatsappPopup(false)}
              customers={getUniquePassengers(FlightService?.records?.filter(v => selectedIDs.includes(v._id)))}
              space_id={GroupTourSidebarService.selectedFolder?._id}
            />
          </ModalComponent.Body>
        </ModalComponent>
      ) : null}


      <div className="flex-1" style={{ width: insidePane ?"100%": "calc(100vw - 250px)" }}>
        <Layout
          hideAdd={!userContent?.rights?.includes(1501) || insidePane}
          medium
          hideSearch={formConfig?.hideFilter}
          showToggle={!insidePane}
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/flight");
            setEditID(null);
          }}

          title={t("Flights")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/flight/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={FlightService.page}
          rows={FlightService.rows}
          total={FlightService.total}
          fetch={FlightService.fetch}
          hideheader={true}
          hidefooter={true}
          toggleData={TOUR_TOGGLE_DATA(t, "flights")}


          compact
          onToggle={(selectedValue) => {
            if (selectedValue === "tours") {
              navigate(`/tour`);
            } else if (selectedValue === "trips") {
              navigate(`/trips`);
            } else if (selectedValue === "stay") {
              navigate("/stay");
            } else if (selectedValue === "flights") {
              navigate("/flight");
            } else if (selectedValue === "guests") {
              navigate("/guests");
            } else if (selectedValue === "calender") {
              navigate("/tours/calender");
            }
          }}

        >



          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={() => setUploadPopup(true)}>
                    {t("Bulk Upload")}
                  </DropdownItem>
                  <div class="dropdown-divider"></div>
                  {GroupTourSidebarService.selectedFolder?._id ?
                  <> <DropdownItem onClick={() => setBulkWhatsappPopup(true)}>
                    {t("Bulk Message")}
                  </DropdownItem>       <div class="dropdown-divider"></div> </>: null}
                  <DropdownItem>{t("Download")} </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={() => { setShowCustomizationOptions(true) }}>{t("Customise")} </DropdownItem>
                </>

              </DropdownMenu>

            </div>
          </Layout.ActionMenu>
          <Layout.Table>
            <DataGrid
              gridLoading={loading}
              data={FlightService?.records}
              total={FlightService?.total}
              uiPreference="flight.grid"
              headers={GridColumns}
              onSelectChange={(v) => {
                onSelect(v)
                setSelectedIDs(v)
              }}
              selectedIDs={selectedIDs}
              page={FlightService.page}
              rowsPerPage={FlightService.rowsPerPage}
              onPaginationChange={FlightService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </Layout.Table>

          <Layout.DetailPageBody>
            {showDetailPage ? <EditFlights
              onSave={() => { fetchData(filterURL) }}
              onDelete={() => {
                setShowDetailPage(false)
                fetchData(filterURL)
              }}
              insidePane={insidePane}
              onBack={() => {
                setShowDetailPage(false);
                if (!insidePane) navigate("/flight");
                setEditID(null);
              }}
              editId={editID}
            /> : null}
          </Layout.DetailPageBody>

        </Layout>
        {showCustomizationOptions ? <CustomOperations
          isModal
          module="flight"
          isOpen={showCustomizationOptions}
          toggleModal={() => { setShowCustomizationOptions(false) }}
        /> : null
        }
      </div>
    </div>
  );
};
export default observer(Flights);
