export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/trip/arrival/report/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  getCampus: (id) => `/api/campus/${id}/campusMap`,
  getWorkShift: (id) => `/api/dutyhrs/corp/${id}/list`,
};

export const STRUCTURE = [
  {
    label: "From",
    description: "...",
    filterLabel: "From",
    filterName: "startTimeFrom",
    type: "dateTimeSelect",
  },
  {
    label: "To",
    description: "...",
    filterLabel: "To",
    filterName: "startTimeTo",
    type: "dateTimeSelect",
  },
  {
    label: "Route/Booking",
    description: "...",
    filterLabel: "Route/Booking",
    filterName: "bookingType",
    type: "select",
    data: [
      { label: 'All Types', value: null },
      { label: 'Booking', value: 'spot' },
      { label: 'Route', value: 'route' },
    ]
  },
  {
    label: "Client",
    description: "...",
    filterLabel: "Client",
    filterName: "corp_id",
    type: "clientSelect",
  },
  {
    label: "Campus",
    description: "...",
    filterLabel: "Campus",
    filterName: "campus_id",
    type: "campusSelect",
  },
  {
    label: "Work Shift",
    description: "...",
    filterLabel: "Work Shift",
    filterName: "shift_id",
    type: "workshiftSelect",
  },
  {
    label: "Late Pickup",
    description: "...",
    filterLabel: "Late Pickup",
    filterName: "latePickup",
    type: "checkBox",
  },
  {
    label: "Passenger No show",
    description: "...",
    filterLabel: "Passenger No show",
    filterName: "noShow",
    type: "checkBox",
  },
];

export default STRUCTURE;
