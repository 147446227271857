import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "./store/context/UserContext";
// import { Routes, BrowserRouter as Router, Route, Navigate } from "react-router-dom"
import { connect } from "react-redux";
import "./components/SuperResponsiveTableStyle.css";
import { Navigate, Route, Routes } from "react-router-dom";
import "toastr/build/toastr.min.css";
// import Dashboard from './pages/Dashboard';

import HorizontalLayout from "./components/HorizontalLayout/";
import { authRoutes, userRoutes } from "./routes/allRoutes";

// Import scss
import "./assets/scss/module.scss";
import "./assets/scss/theme.scss";
import { changeTopbarTheme } from "./store/actions";
import { I18nContext } from "./store/context/i18nContext";
import SettingUp from "./pages/Demo/SettingUp";
import Pages404 from "./pages/Utility/pages-404";
import useVersionChecker from "./hooks/useVersionChecker";
import VersionUpdatePopup from "./components/VersionUpdatePopup";
import VERSION from "./Constants";
import useSocketBookedEvent from "./hooks/useSocketBookedEvent";

const App = (props) => {
  const { isLoggedIn } = useContext(UserContext);
  const { translations, lang } = useContext(I18nContext);
  const [loggedIn, setLoggedIn] = useState(true);
  const {showPopup,toggleShowPopup} = useVersionChecker(VERSION); 
  const { BookedModal } = useSocketBookedEvent(loggedIn);

  function getLayout() {
    let layoutCls = HorizontalLayout;
    changeTopbarTheme("dark");
    return layoutCls;
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authorization = localStorage.getItem("BearerToken");
      if (authorization) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Routes>
        {authRoutes.map(({ path, Component }, key) => (
          <Route path={path} element={<Component />} key={key} />
        ))}
        {userRoutes.map(({ path, Component }, key) => (
          <Route
            path={path}
            element={
              localStorage.getItem("BearerToken") && loggedIn ? (
                <Layout>
                  {!translations[lang] ? (
                    <SettingUp />
                  ) : (
                    <Component />
                  )}
                </Layout>
              ) : (
                <Navigate to="/" />
              )
            }
            key={key}
          />
        ))} Pages404
        <Route path="*" element={<Pages404 />} />

      </Routes>
      <VersionUpdatePopup isOpen={showPopup} toggle={toggleShowPopup} />
      <BookedModal />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);

// {/* <Switch>
// { authRoutes.map((route, idx) => (
//   <Authmiddleware
//     path={route.path}
//     layout={NonAuthLayout}
//     component={route.component}
//     key={idx}
//     isAuthProtected={false}
//   />
// ))}

// { userRoutes.map((route, idx) => (
//   <Authmiddleware
//     path={route.path}
//     layout={Layout}
//     component={route.component}
//     key={idx}
//     isAuthProtected={true}
//     exact
//   />
// ))}
// </Switch> */}
