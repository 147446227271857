import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { Address, CardThreeBounce, CountrySelect, InputField, Checkbox } from "../../components";
import StateService from "./StateService";
import { I18nContext } from "../../store/context/i18nContext";
import { DialogContext } from "../../store/context/DialogContext";

const EditStates = ({ editId, onChange = () => { } }) => {

  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetch = async () => {
    try {
      let states = await StateService.get(editId);
      setData(states);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(null)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, StateService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  return (
    <React.Fragment>
      {loading ?
        <CardThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row>
            <CountrySelect
              value={data?.country}
              className="col-sm-6"
              onChange={(v) =>
                setData({
                  ...data,
                  country: v?.value,
                })
              }
              required={true}
            />
            <InputField
              placeholder={t("State")}
              value={data?.name}
              required={true}
              label={t("State")}
              onChange={(v) => setData({ ...data, name: v })}
              type="text"
              className="col-sm-6"
            />

          </Row>
          <Row className="mt-4">
            <Checkbox
              label={t("IsUT")}
              checked={data?.isUT}
              className="col-sm-6"
              onClick={(v) =>
                setData({
                  ...data,
                  isUT: !data?.isUT,
                })
              }
            />
          </Row>
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditStates);



