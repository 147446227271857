import React, { useContext, useState } from 'react';
import { Card, CardHeader, } from "reactstrap";
import { I18nContext } from '../../store/context/i18nContext';
import { Image } from '../../components';

const Helper = ({ src, alt, text }) => {
    const { t } = useContext(I18nContext);
    return (
        <div className="mx-3">
            <div className='mb-2'>
                <strong className='font-size-13'>{t(text)}</strong>
            </div>
            {
                !!src ? <Image
                src={src}
                style={{
                    display: "inline",
                    maxHeight: "300px",
                    maxWidth: "500px"
                }}
                />
                : <strong>{t("Picture not available")}</strong>
            }
        </div>
    )
}

const Pictures = ({ title, srcPath, srcSign }) => {
    return (
        <>
            {!!srcPath || srcSign ? (
                <div>
                    <Card className="pt-0">
                        <CardHeader>
                            <div
                                className="d-flex justify-content-between"
                                style={{ width: "inherit" }}
                            >
                                <strong>{title}</strong>
                            </div>
                        </CardHeader>
                        <div className="d-flex my-3 flex-md-row flex-column">
                            {!!srcPath ? <Helper alt="picture not available" text="Path Picture" src={srcPath} /> : null}
                            {srcSign ? <Helper alt="picture not available" text="Signature Picture" src={srcSign} /> : null}
                        </div>
                    </Card>
                </div>
            ) : null}
        </>
    )
}

export default Pictures;