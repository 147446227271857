import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import CustomerLabel from '../../../components/Dropdowns/CustomerSelect/CustomerLabel';
import { getFileTypeIcon } from '../../../components/MediaChatViewer/MediaChatViewer';
import { DialogContext } from '../../../store/context/DialogContext';
import { truncateStringToWords } from '../../../util/Util';
import CustomerDetails from '../components/customerDetails/CustomerDetails';
import './WhatsApp.css';
import WhatsAppService from './WhatsAppService';
import WhatsappChat from './WhatsappChat';

const ChatList = observer(({  }) => {

    const { chats, setSelectedChat,current_customer_id,} = WhatsAppService;
    const { showError } = useContext(DialogContext)

    const fetch = async () => {
        try {
            WhatsAppService?.fetchChats();
        } catch (error) {
            showError(error)
        }
    }

    useEffect(() => {
        fetch()
    }, [])

    return <div className="zr_crm_whatsapp_chat-list">
        {chats?.map(chat => (
            <div key={chat.customer_id} className={`zr_crm_whatsapp_chat-item ${current_customer_id === chat.customer_id ? "active-class" : ""}`}
            onClick={() => {
                setSelectedChat(chat.customer_id)
                WhatsAppService.markChat(chat?.id)
                }}>
                <div className='d-flex justify-content-between w-100 align-items-center'>
                    <div className="zr_crm_whatsapp_chat-name"><CustomerLabel value={chat.customer_id} /></div>
                    <div className="zr_crm_whatsapp_chat-time">{chat?.lastWhatsapp}</div>
                </div>
                <div className='d-flex justify-content-between w-100 align-items-center'>
                    <div className="zr_crm_whatsapp_chat-message d-flex align-items-center">
                        {chat.url && <span className='d-flex align-items-center p-0 m-0 me-1'>{getFileTypeIcon(chat.url)}</span>}
                        {truncateStringToWords(chat.lastWhatsappMsg,10)}
                        </div>
                    {chat?.unread > 0 && <div className="zr_crm_whatsapp_chat-unread"></div>}
                </div>
            </div>
        ))}
    </div>
});

const WhatsApp = () => {
    const {  current_customer_id } = WhatsAppService;

    return (
        <div className="zr_crm_whatsapp_container">
            <ChatList />
            <div className='zr_crm_whatsapp_chat-container'>
                <WhatsappChat customer_id={current_customer_id}/>
            </div>
            <div className="zr_crm_inbox_customerDetails">
                <CustomerDetails customerId={current_customer_id} />
            </div>

        </div>
    );
};

export default observer(WhatsApp);
