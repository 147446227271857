import React, { useContext, useEffect, useState } from "react";

import "toastr/build/toastr.min.css";
import {
    Checkbox,
    InputField,
} from "../../components";
import "../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";

import ConfigService from "./ConfigService";
import useDebounce from "../../util/Debounce";
import Skeleton from "react-loading-skeleton";


const ConfigComponent = ({ version, setVersion, label, type, fieldName }) => {
    const { t } = useContext(I18nContext);
    const { showConfirm, showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({})
    const { showMessage } = useContext(DialogContext);
    const [hasErr, setHasErr] = useState(false);


    const get = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await ConfigService.detail(fieldName);

            setData(response?.[fieldName])
            setVersion(response?.version)
        } catch (error) {
            showError(error);
        }
        setLoading(false);
    }

    const edit = useDebounce(async (data) => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await ConfigService.edit({
                version: version,
                [fieldName]: data
            }, fieldName);
            setData(response?.[fieldName])
            setVersion(response?.version)
        } catch (error) {
            showError(error);
        }
        setLoading(false);
    }, 1000)

    useEffect(() => {
        get()
    }, [])


    if (loading) return <Skeleton height={40} className="mb-3"/>;


    return (
        <div style={{
            maxWidth: '600px'
        }}>
            {type == 'checkBox' ? <div className="mt-3">
                <div className="d-flex justify-content-between mt-2">
                    <Checkbox
                        label={label}
                        checked={data}
                        onClick={(v) => {
                            edit(v)
                        }
                        }
                    />
                </div>
            </div> : <div></div>}


            {type == "text" ? <div className="mt-3">
                <div>
                    <InputField
                        placeholder={label}
                        value={data ? data : ""}
                        label={label}
                        onChange={(v) =>
                            edit(v)
                        }
                        type="text"
                    />
                </div>

            </div> : <div></div>}
        </div>
    );
};

export default ConfigComponent






