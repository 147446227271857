import React from 'react'
import { InputField } from '../../../components'
import { observer } from 'mobx-react-lite'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const EmailModal = (props) => {
    const toggle = () => {
        props?.setModal(!props?.modal);
    }

    return (
        <Modal
            isOpen={props?.modal}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle}>Please provide email to send test message</ModalHeader>
            <ModalBody>
                <InputField
                    placeholder="Email"
                    label={"Email"}

                    type="text"
                    value={props?.testData?.email}
                    onChange={(v) => {
                        console.log(props?.testData);
                        props?.setTestData({ ...props?.testData, email: v })
                    }}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => {
                    props?.handleTest();
                    toggle();
                }}>
                    Test
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default observer(EmailModal);