import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { InputField, PhoneNum, SelectField } from '../../../components';
import { DialogContext } from '../../../store/context/DialogContext';
import { I18nContext } from '../../../store/context/i18nContext';
import { UserContext } from '../../../store/context/UserContext';
import ProfileService from '../ProfileService';
// import { setDatasets } from 'react-chartjs-2/dist/utils';

const Contact = (props) => {
    const { id, data, setData } = props;
    const { showError, showMessage } = useContext(DialogContext);

    // const saveHandler = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const res = await ProfileService.saveContact(configData)
    //         showMessage("Contacts Updated");
    //         fetchFunc()
    //     } catch (error) {
    //         showError(error)
    //     }
    // }
    // const fetch = async () => {
    //     try {
    //         let tag = await ProfileService.detailcontact(id);
    //         setData(tag);
    //     } catch (error) {
    //         showError(error)
    //     }
    // };

    // useEffect(() => {
    //     setData(null)
    //     // setLoading(true);
    //     if (id) fetch();
    //     // setLoading(false)
    // }, [id]);

    return (
        <>
            <Col
                className="col-12 p-2 ps-4"
                style={{
                    backgroundColor: "#EAEAEA",
                    fontSize: "15px",
                    borderBottom: "1px solid rgba(0,0,0,.1)",
                }}
            >
                <header className="d-flex justify-content-between align-items-center">
                    <m>{`Contact Person`}</m>
                </header>
            </Col>
            <Contacts data={data} setData={setData} />
            <Col className='d-flex justify-content-end'>
                <Button
                    className='btn btn-success'
                    onClick={(e) => {
                        // saveHandler(e)
                    }}
                    style={{ height: "28px", padding: "4px 8px" }}
                >
                    Save
                </Button>
            </Col>
        </>
    )
}

function Contacts({ data, setData }) {
    const [details, setdetails] = useState(data?.contacts ? data?.contacts : [])

    useEffect(() => {
        if (JSON.stringify(data?.contacts) !== JSON.stringify(details))
            setData({ ...data, contacts: details });
    }, [details]);

    const { t } = useContext(I18nContext);

    return (
        <>
            <Row className='mt-3'>
                <Col className="d-flex justify-content-start ">
                    <Button
                        className='btn btn-dark'
                        onClick={() => setdetails([...details, {}])}
                        style={{ height: "28px", padding: "4px 8px" }}
                    >
                        Add +
                    </Button>
                </Col>
            </Row>

            {details?.map((s, i) => (
                <Row key={i} className="shadow p-3 rounded my-4">
                    <div
                        className='mb-4'
                        onClick={() => {
                            setdetails(() => {
                                let newData = details.slice();
                                newData.splice(i, 1);
                                return newData;
                            });
                        }}
                        style={{ cursor: "pointer", position: "relative" }}
                    >
                        <i
                            style={{ position: "absolute", right: 0, fontSize: "25px" }}
                            className="dripicons-cross"
                        />
                    </div>
                    <Row>
                        <Col className='col-sm-4 col-12 mt-3'>
                            <InputField label={t('Contact Person Name')} value={s?.name} onChange={(v) =>
                                setdetails(
                                    details.map((f, x) => {
                                        if (i === x)
                                            return {
                                                ...s,
                                                name: v,
                                            };
                                        return f;
                                    })
                                )
                            } />
                        </Col>
                        <Col className='col-sm-4 col-12 mt-3'>
                            <SelectField label={t('Contact Person Type')} data={[
                                { label: 'Select Type', value: 'Select Type' },
                                { label: 'Primary', value: 'Primary' },
                                { label: 'Secondary', value: 'Secondary' },
                                { label: 'Management Executive', value: 'Management Executive' },
                            ]} value={s?.type} onChange={(v) =>
                                setdetails(
                                    details.map((f, x) => {
                                        if (i === x)
                                            return {
                                                ...s,
                                                type: v.value,
                                            };
                                        return f;
                                    })
                                )
                            } />
                        </Col>
                        <Col className='col-sm-4 col-12 mt-3'>
                            <InputField label={t('Contact Person Designation')} value={s?.designation} onChange={(v) =>
                                setdetails(
                                    details.map((f, x) => {
                                        if (i === x)
                                            return {
                                                ...s,
                                                designation: v,
                                            };
                                        return f;
                                    })
                                )
                            } />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-sm-8 col-12 mt-3'>
                            <PhoneNum label={t('Person Mobile')} value={{ countryCode: s?.countryCode, phone: s?.mobile }} onChange={(v) =>
                                setdetails(
                                    details.map((f, x) => {
                                        if (i === x)
                                            return {
                                                ...s,
                                                countryCode: v.countryCode,
                                                mobile: v.phone,
                                            };
                                        return f;
                                    })
                                )
                            } />
                        </Col>
                        <Col className='col-sm-4 col-12 mt-3'>
                            <InputField label={t('Contact Person Email')} value={s?.email} onChange={(v) =>
                                setdetails(
                                    details.map((f, x) => {
                                        if (i === x)
                                            return {
                                                ...s,
                                                email: v,
                                            };
                                        return f;
                                    })
                                )
                            } />
                        </Col>
                    </Row>
                </Row>
            ))}
        </>
    );
}

export default Contact