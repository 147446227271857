
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./ContactPersonConstant";
import { GridColumns } from "./ContactPersonGridColumns";
import ContactPersonService from "./ContactPersonService";
import EditContactPersons from "./EditContactPerson";
import TagPicker from "../../components/TagSelector/TagPicker";

const ContactPersons = ({ value, insidePane, multiMode, onSelect = () => { } }) => {

    let { edit_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(edit_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/contact-person/create" || edit_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)


    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);

        if (loading) return;
        setLoading(true);
        try {
            await ContactPersonService.fetch(filterUrl);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])

    }, [])

    useEffect(() => {
        setEditID(edit_id)
    }, [edit_id])

    const onSave = async (e) => {
        e.preventDefault();
        if (!detailData.name) {
            setHasErr(true)
            showError(t("Please enter all fields"));
            return;
        }
        setLoading(true);
        try {
            if (editID) {
                await ContactPersonService.edit(detailData, editID);
                showMessage(t("Record updated successfully."));
            } else {
                const contactPersonID = await ContactPersonService.save(detailData);
                if (contactPersonID) setEditID(contactPersonID)
                showMessage(t("Record saved successfully."));
                if (!insidePane) navigate(`/contact-person/edit/${contactPersonID}`);
            }
            fetchData(filterURL);
        } catch (e) {
            showError(e);
        }
        setHasErr(false)
        setLoading(false);
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            try {
                await ContactPersonService.delete(id);
                showMessage("Deleted", "Deleted");
            } catch (e) {
                showError(e);
            }
        }
    };
    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            try {
                await ContactPersonService.bulkDelete(selectedIDs);
                showMessage("Deleted", "Deleted Successfully");
            } catch (e) {
                showError(e);
            }
        }
    };

    const renderLastCol = useCallback((contactPerson) => {
        return (
            <>
                <EditLink
                    onClick={() => {
                        if (!insidePane)
                            navigate(`/contact-person/edit/${contactPerson?._id}`);
                        setEditID(contactPerson?._id);
                        setShowDetailPage(true);
                    }}
                />

                <DeleteLink
                    onClick={(event) => onDelete(event, contactPerson?._id)}
                />

                {!multiMode && insidePane ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(contactPerson?._id);
                        }}
                    />
                ) : null}
            </>
        );
    }, [insidePane, multiMode])

    return (
        <>
            <Layout
                medium
                showDetailPage={showDetailPage}
                backDetailPage={async () => {
                    setShowDetailPage(false);
                    if (!insidePane) navigate("/contact-person");
                    setEditID(null);
                }}
                 
                title={t("Contact Person")}
                filterValues={filterObject}

                filterStructure={STRUCTURE}
                onApplyFilter={fetchData}
                onAddClick={() => {
                    if (!insidePane) navigate(`/contact-person/create`);
                    setShowDetailPage(true);
                    setEditID(null);
                }}
                insidePane={insidePane}
                page={ContactPersonService.page}
                rows={ContactPersonService.rows}
                total={ContactPersonService.total}
                fetch={ContactPersonService.fetch}
            >
                <Layout.ActionMenu>
                    <div className="layout-action-menu">
                        <DropdownMenu>
                            <>
                                <DropdownItem onClick={() => { onBulkDelete() }}>{t("Bulk Delete")}</DropdownItem>
                                <div class="dropdown-divider"></div>
                                <DropdownItem>{t("Upload")}</DropdownItem>
                                <div class="dropdown-divider"></div>
                                <DropdownItem> {t("Download")} </DropdownItem>
                            </>
                        </DropdownMenu>
                    </div>
                </Layout.ActionMenu>
                <Layout.Table>
                    <>
                        <DataGrid
              gridLoading={loading}
                            data={ContactPersonService.records}
                            total={ContactPersonService.total}
                            uiPreference="contactPerson.grid"
                            headers={GridColumns}
                            selectedIDs={selectedIDs}
                            onSelectChange={(v) => {
                                onSelect(v)
                                setSelectedIDs(v)
                            }}
                            page={ContactPersonService.page}
                            rowsPerPage={ContactPersonService.rowsPerPage}
                            onPaginationChange={ContactPersonService.onPaginationChange}
                            renderLastCol={renderLastCol}
                        />
                    </>
                </Layout.Table>

                <Layout.DetailPageTitle>
                    <div className="d-flex align-items-center">
                        <div className="me-2">
                            {detailData?._id ? t("Edit Contact Person") : t("Add Contact Person")}
                        </div>
                        <TagPicker
                            className="ms-2"
                            kind={"trip"}
                            onChange={(v) => setDetailData({ ...detailData, tags: v })}
                            value={detailData?.tags}
                        />
                    </div>
                </Layout.DetailPageTitle>

                <Layout.DetailPageBody>
                    <EditContactPersons
                        editId={editID}
                        onChange={(v) => {
                            setDetailData(v)
                        }}
                        hasErr={hasErr}
                    />
                </Layout.DetailPageBody>

                <Layout.DetailPageFooter>
                    <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} />
                    <SaveButton onClick={onSave} />
                </Layout.DetailPageFooter>
            </Layout>
        </>
    );
};
export default observer(ContactPersons);
