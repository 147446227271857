import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { Button } from 'reactstrap';
import { InputField } from '../../components';
import { DialogContext } from '../../store/context/DialogContext';
import { UserContext } from "../../store/context/UserContext";
import { doGET, doPOST } from '../../util/HttpUtil';
import FlightGrid from '../GroupTour/FlightsGrid';
import StayGrid from '../GroupTour/StayGrid';
import GroupTourSidebarService from '../GroupTour/sideBar/GroupTourSidebarService';
import CommunicationService from './CommunicationService';
import EmailModal from './EmailModal';
import RecipientsList from './RecipientsList';
import EmailContent from './displays/DisplayEmailContent';
import EmailAttachment from './EmailAttachment';

const ComposeEmail = ({ onBack, tour_id, trip_id, flights = [], trips = [], stays = [], customers = [], bookers = [] }) => {
    const notes = GroupTourSidebarService?.selectedFolder?.notes ?? 'Professional itinerary details...';
    const [data, setData] = useState({
        id: null,
        extraContent: "",
        subject: '',
        content: '',
        to: '',
        selectedFlights: [],
        selectedStays: []
    });
    const [attachments, setAttachments] = useState([]);

    const { showError, showMessage } = useContext(DialogContext);
    const { webContent } = useContext(UserContext);
    const [bodyModal, setBodyModal] = useState(false);
    const [headerContent, setHeaderContent] = useState(null);
    const [footerContent, setFooterContent] = useState(null);
    const [printContent, setPrintContent] = useState(`
        <!DOCTYPE HTML>
        <html>
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
            <title>Test</title>
            <style>
                @page { size: A4; margin-top: 20px; margin-bottom: 10px; }
                .page-footer {
                    position: fixed;
                    bottom: 10px;
                    right: 10px;
                    background-color: white;
                    padding: 5px;
                    border: 1px solid #ccc;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                }
            </style>
        </head>
        <body style="padding: 20px; padding-top:2px">
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"
            integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL"
            crossorigin="anonymous"></script>
            <table id="table-data" style="width: 100%;">
    `);

    const [FlightBodyContent, setFlightBodyContent] = useState(null);
    const [stayBodyContent, setStayBodyContent] = useState(null);
    const [combinedEmailContent, setCombinedEmailContent] = useState("");
    const [FlightsMap, setFlightsMap] = useState(() => data?.selectedFlights.reduce((map, item) => {
        map[item._id] = '';
        return map;
    }, {}));
    const [StaysMap, setStaysMap] = useState(() => data?.selectedFlights.reduce((map, item) => {
        map[item._id] = '';
        return map;
    }, {}));

    const toggleModal = () => setBodyModal(!bodyModal);

    useEffect(() => {
        const passengerEmails = (customers ?? []).map(p => p.email);
        const passengerPhones = (customers ?? []).map(p => p.phone.phone);
        if (passengerEmails?.length || passengerPhones?.length) {
            if (data.to !== passengerEmails || data.content !== notes) {
                setData(prevData => ({
                    ...prevData,
                    to: passengerEmails,
                    content: notes
                }));
            }
        }
    }, [customers, notes]);

    const handleInputChange = (field, value) => {
        if (field === "extraContent") {
            const updatedBody = `${printContent}<div style="width: 600px; margin-left: auto; margin-right: auto; padding:10px 10px 10px 0px">${value}</div>${combinedEmailContent}${footerContent}</body></html>`;

            setData(prev => ({
                ...prev,
                extraContent: value,
                body: combinedEmailContent === "" ? updatedBody : `${printContent}<div style="width: 600px; margin-left: auto; margin-right: auto; padding: 10px 10px 10px 0px">${value}</div>${combinedEmailContent}${footerContent}</body></html>`
            }));
        } else {
            setData(prev => ({ ...prev, [field]: value }));
        }
    };

    const handleGridChange = async (type, selectedItems, toggledItem) => {
        setData(prev => ({ ...prev, [type]: selectedItems }));
        await appendContent(type, selectedItems, toggledItem);
    };

    const toggleItem = (items, toggledItem, type) => {
        if (!items?.length) return [...items, toggledItem];

        const itemIndex = items.findIndex(item => item?._id === toggledItem?._id);
        if (itemIndex === -1) return [...items, toggledItem];

        const newItems = [...items];
        newItems.splice(itemIndex, 1);

        const updateContent = (map, setMap, bodyContent, setBodyContent, otherContent, label) => {
            const newMap = { ...map };
            newMap[toggledItem?._id] = "";
            setMap(newMap);

            const updatedBodyContent = Object.values(newMap).join('');
            setBodyContent(updatedBodyContent);

            return `
                ${(updatedBodyContent && updatedBodyContent !== null) ? `
                    <div style="width: 600px; margin-left: auto; margin-right: auto; padding:10px">${label} Details :</div>
                    ${updatedBodyContent}
                ` : ""}
                ${(otherContent && otherContent !== null) ? `
                    <div style="width: 600px; margin-left: auto; margin-right: auto; padding:10px">Stay Details :</div>
                    ${otherContent}
                ` : ""}
            `;
        };

        let combinedBodyContent;
        if (type === 'selectedFlights') {
            combinedBodyContent = updateContent(FlightsMap, setFlightsMap, FlightBodyContent, setFlightBodyContent, stayBodyContent, 'Flight');
        } else {
            combinedBodyContent = updateContent(StaysMap, setStaysMap, stayBodyContent, setStayBodyContent, FlightBodyContent, 'Stay');
        }

        const updatedBody = combinedBodyContent ? `
            ${printContent}
            <div style="width: 600px; margin-left: auto; margin-right: auto; padding:10px 10px 10px 0px">${data?.extraContent}</div>
            ${combinedBodyContent}
            ${footerContent}
        </body></html>
        ` : data?.extraContent !== "<p><br></p>" ? `
            ${printContent}
            <div style="width: 600px; margin-left: auto; margin-right: auto; padding:10px 10px 10px 0px">${data?.extraContent}</div>
            ${footerContent}
        </body></html>
        ` : "";

        setData(prev => ({
            ...prev,
            selectedFlights: type === 'selectedFlights' ? newItems : data?.selectedFlights,
            selectedStays: type === 'selectedStays' ? newItems : data?.selectedStays,
            body: updatedBody,
        }));

        setCombinedEmailContent(combinedBodyContent);
        return newItems;
    };

    const getHeaderFooter = async () => {
        let helpline
        try {
            const response = await doGET("/api/corporate-config/detail/helpLines")
            if (response?.status == 200 && response?.data?.helpLines?.length) {
                const helplineObj = response?.data?.helpLines?.find(v => v?.isDefault == true) || response?.data?.helpLines[0];
                helpline = helplineObj?.customerHelpline;
            }
        } catch (error) {
            showError(error)
        }
        try {
            let apiHeaderEndpoint = '/print-server/email/header'
            let apiFooterEndpoint = '/print-server/email/footer'
            const headerResponse = await doPOST(apiHeaderEndpoint, {
                VendorCompanyName: webContent?.fullName ?? webContent?.name,
                logo: `https://${window.location.host}${webContent?.logo}`
            });

            const footerResponse = await doPOST('/print-server/email/footer', {
                helpLine: helpline,
                tnc: "This is an automated email, please do not reply to this email. For any queries related to services please contact our helpline."
            });

            setHeaderContent(headerResponse?.data);
            setFooterContent(footerResponse?.data);
            setPrintContent(printContent + headerResponse?.data);

            const updatedBody = `${printContent + headerResponse?.data}${footerResponse?.data}</body></html>`
            setData(prev => ({
                ...prev,
                body: updatedBody
            }));
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        getHeaderFooter();
    }, []);

    const appendContent = async (type, selectedItems, toggledItem) => {
        let apiBodyEndpoint;
        let payload = {};
        if (type === 'selectedFlights') {
            const updatedItems = toggleItem(data?.selectedFlights, toggledItem, 'selectedFlights');
            payload = updatedItems.find(item => item?._id === toggledItem?._id) || null;
            apiBodyEndpoint = '/print-server/email/flight';
        } else if (type === 'selectedStays') {
            const updatedItems = toggleItem(data?.selectedStays, toggledItem, 'selectedStays');
            payload = updatedItems.find(item => item?._id === toggledItem?._id) || null;
            apiBodyEndpoint = '/print-server/email/stay';
        }
        try {
            let bodyresponse = null;
            if (payload) {
                let combinedBodyContent = FlightBodyContent + stayBodyContent;
                bodyresponse = await doPOST(apiBodyEndpoint, payload);
                if (type === 'selectedFlights') {
                    const newFlightsMap = { ...FlightsMap };
                    newFlightsMap[payload._id] = bodyresponse?.data;
                    setFlightsMap(newFlightsMap);
                    const combinedFlightBodyContent = Object.values(newFlightsMap).join('');
                    setFlightBodyContent(combinedFlightBodyContent);
                    combinedBodyContent = ` 
                        <div style="width: 600px; margin-left: auto; margin-right: auto; padding:10px"> Flight Details :</div> `+
                        combinedFlightBodyContent +
                        ((stayBodyContent != "" && stayBodyContent != null) ? (`
                         <div style="width: 600px; margin-left: auto; margin-right: auto; padding:10px "> Stay Details :</div>`+
                            stayBodyContent) : "");
                    setCombinedEmailContent(combinedBodyContent);
                } else if (type === 'selectedStays') {
                    const newStaysMap = { ...StaysMap };
                    newStaysMap[payload._id] = bodyresponse?.data;
                    setStaysMap(newStaysMap);
                    const combinedStayBodyContent = Object.values(newStaysMap).join('');
                    setStayBodyContent(combinedStayBodyContent);
                    combinedBodyContent = ((FlightBodyContent != "" && FlightBodyContent != null) ? (`
                          <div style="width: 600px; margin-left: auto; margin-right: auto; padding:10px"> Flight Details :</div> `+
                        FlightBodyContent) : "") +
                        `<div style="width: 600px; margin-left: auto; margin-right: auto; padding:10px"> Stay Details :</div>` +
                        combinedStayBodyContent;
                    setCombinedEmailContent(combinedBodyContent);
                }
                if (combinedBodyContent === "") {
                    setData(prev =>
                    ({
                        ...prev,
                        body: (data?.extraContent != "<p><br></p>" ? printContent +
                            `<div style="width: 600px; margin-left: auto; margin-right: auto; padding:10px 10px 10px 0px"> ${data?.extraContent}</div>` + footerContent +
                            `</table>
                    </body>
                 </html>`: "")
                    }));
                } else {
                    setData(prev =>
                    ({
                        ...prev,
                        body: (printContent + `<div style="width: 600px; margin-left: auto; margin-right: auto; padding:10px 10px 10px 0px"> ${data?.extraContent}</div>` + combinedBodyContent + footerContent + `
                            </table>
                        </body>
                     </html>`)
                    }));
                }
            }
        } catch (error) {
            console.error('Error fetching content:', error);
            showError(error);
        }
    };

    const handleSendMail = async () => {
        if (!data?.subject) {
            showError("Subject is missing");
            return;
        }

        if (!data?.to?.length) {
            showError("Recipients missing");
            return;
        }

        if (!(data.selectedFlights.length || data.selectedStays.length)) {
            showError("Select at least one flight or stay");
            return;
        }

        if (data?.extraContent === "<p><br></p>" || data?.extraContent === "") {
            showError("Email Body is missing.");
            return;
        }

        const formData = new FormData();
        formData.append('data', JSON.stringify({ ...data, type: "email", flightIds: data.selectedFlights, stayIds: data.selectedStays, messageType: "TRIP_VEHICLE_ASSIGNED_EMPLOYEE" }));

        attachments.forEach((file, index) => {
            formData.append(`attachment_${index}`, file);
        });

        try {
            const updatedData = { ...data, type: "email", flightIds: data.selectedFlights, stayIds: data.selectedStays, messageType: "TRIP_VEHICLE_ASSIGNED_EMPLOYEE" };
            const response = await doPOST("/api/email/create?hasAttachment=y", formData);

            if (response?.status === 200) {
                CommunicationService.addMail({ id: response.data._id, ...data });
                showMessage("Email Sent Successfully");
            }
            onBack();
        } catch (error) {
            showError(error);
        }
    };


    return (
        <div className='zr_communication_compose'>
            <h2 className='d-flex align-items-center'> <IoMdArrowBack onClick={onBack} className='me-2' size={20} /> Compose Email</h2>
            <div className="mb-3">
                <RecipientsList customers={customers} data={data} setData={setData} />

            </div>
            <div className="mb-3">
                <InputField
                    outline
                    placeholder="Subject"
                    value={data.subject}
                    label={"Subject"}
                    onChange={(v) => handleInputChange('subject', v)}
                    type="text"
                />
            </div>
            <div className='mb-4'>


                <EmailContent onAdd={toggleModal} content={data.body} />

            </div>


            <div className="mb-3">
                <EmailAttachment attachments={attachments} setAttachments={setAttachments} />
            </div>

            <FlightGrid
                hideAdd
                selectedFlights={data?.selectedFlights}
                completeObject
                fixedFlights={flights}
                onChange={(selectedFlights, flight) => handleGridChange('selectedFlights', selectedFlights, flight)}
            />
            <StayGrid
                hideAdd
                selectedStays={data?.selectedStays}
                fixedStays={stays}
                completeObject
                onChange={(selectedStays, stay) => handleGridChange('selectedStays', selectedStays, stay)}
            />
            <div className="mt-3 d-flex justify-content-end">
                <Button color="success" onClick={handleSendMail} className="ms-2">
                    Send <i className="fab fa-telegram-plane ms-1"></i>
                </Button>
            </div>
            <EmailModal
                isOpen={bodyModal}
                toggleModal={toggleModal}
                value={data?.extraContent}
                handleInputChange={handleInputChange}
            />
        </div>
    );
};

export default observer(ComposeEmail);
