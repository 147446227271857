import React from 'react';
import { MdPersonAddAlt1 } from 'react-icons/md';

function AddCustomer({ onAddCustomer, email }) {
    return (
        <div className="d-flex justify-content-center align-items-center h-75">
            <div className="d-flex flex-column align-items-center gap-3 p-4 rounded  ">
                <div 
                    className="add_customer_icon d-flex justify-content-center align-items-center border border-dark border-1 rounded-circle" 
                >
                    <MdPersonAddAlt1 className="text-grey fs-2" size={50} />
                </div>
                <div 
                    className="font-size-20 fw-bold text-center text-info cursor-pointer"
                    onClick={onAddCustomer}
                >
                    Onboard {email} as a New Customer
                </div>
            </div>
        </div>
    );
}

export default AddCustomer;
