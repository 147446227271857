import { toJS } from 'mobx';
import React from 'react';
import { FaMapMarkerAlt, FaUser, FaInfoCircle } from 'react-icons/fa';

function CustomRosteringPopup(data) {
  return (
    <div className=" shadow-none">
      <div className="">
        <div className="font-size-14 fw-bold">{data?.title}</div>
        <div className="">
          {/* {data?.status>=0?<p>
            <FaInfoCircle /> Status: {data?.status}
          </p>:null} */}
          <p>
            <FaMapMarkerAlt /> Address: {data?.address}
          </p>
          {/* <p>
            <FaMapMarkerAlt /> Lat: {data?.lat}, Lng: {data?.lng}
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default CustomRosteringPopup;
