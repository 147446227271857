import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditCorporate from "./EditCorporate";
import { STRUCTURE } from "./CorporateConstant";
import { GridColumns } from "./CorporateGridColumns";
import CorporateService from "./CorporateService";
import { UserContext } from "../../store/context/UserContext";

const Corporate = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/corporate/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      const response = await CorporateService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])


  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    // if (!detailData?.code) {
    //   setHasErr(true)
    //   showError(t("Please enter all required fields"));
    //   return;
    // }
    setLoading(true);
    try {
      if (editID) {
        await CorporateService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Corporate updated successfully."));
      }
      // else {
      //   const garage = await CorporateService.save(detailData);
      //   setLoading(false);
      //   showMessage(t("Garage saved successfully."));
      //   if (!insidePane) navigate(`/garage/edit/${garage}`);
      // }
      fetchData(filterURL);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };

  // const onDelete = async (event, id) => {
  //   event.stopPropagation();
  //   if (
  //     await showConfirm({
  //       title: t("Do you want to delete record?"),
  //       description: t("This is an unrecoverable operation."),
  //     })
  //   ) {
  //     setLoading(true);
  //     try {
  //       await CorporateService.delete(id);
  //       setLoading(false);
  //       showMessage("Garage Deleted SuccessFully", "Deleted");
  //       setShowDetailPage(false);

  //     } catch (e) {
  //       setLoading(false);
  //       showError(e);
  //     }
  //   }
  // };
  // const onBulkDelete = async () => {
  //   if (
  //     await showConfirm({
  //       title: t("Do you want to delete all the selected record?"),
  //       description: t("This is an unrecoverable operation."),
  //     })
  //   ) {
  //     try {
  //       await CorporateService.bulkDelete(selectedIDs);
  //       showMessage("Deleted", "Deleted Successfully");
  //       setShowDetailPage(false);

  //     } catch (e) {
  //       showError(e);
  //     }
  //   }
  // };

  return (
    <>
      <Layout
        hideAdd={!userContent?.rights?.includes(1501)}
        medium
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/corporate");
          setEditID(null);
        }}
         
        title={t("Corporate")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/corporate/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={CorporateService.page}
        rows={CorporateService.rows}
        total={CorporateService.total}
        fetch={CorporateService.fetch}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> Download </DropdownItem>
              </>

            </DropdownMenu>

          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={CorporateService.records}
            total={CorporateService.total}
            uiPreference="corporate.grid"
            headers={GridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            selectedIDs={selectedIDs}
            page={CorporateService.page}
            rowsPerPage={CorporateService.rowsPerPage}
            onPaginationChange={CorporateService.onPaginationChange}
            renderLastCol={(corporate) => {
              return (
                <>
                  <EditLink
                    onClick={() => {
                      if (!insidePane)
                        navigate(`/corporate/edit/${corporate?._id}`);
                      setEditID(corporate?._id);
                      setShowDetailPage(true);

                    }}
                  />
                  {/* {userContent?.rights?.includes(1503) ?

                    <DeleteLink
                      onClick={(event) => onDelete(event, corporate?._id)}
                    />
                    : null} */}
                  {!multiMode && insidePane ? (
                    <CheckButton
                      onClick={() => {
                        onSelect(corporate?._id);
                      }}
                    />
                  ) : null}
                </>
              );
            }}
          />
        </Layout.Table>


        <Layout.DetailPageTitle>
          {/* {detailData?._id ? t("Edit Corporate") : t("Add Garage")} */}
          {t("Edit Corporate")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditCorporate
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {/* {userContent?.rights?.includes(1503) ? <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} /> : null} */}
          <SaveButton onClick={onSave} loading={loading} />
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(Corporate);
