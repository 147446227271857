import React from "react";
import PlacesWithStandaloneSearchBox from "./SearchBox";
import Map from "./Map";
import { Row } from "reactstrap";
import { MapStyles } from "../../common/utils/Constants";
import { useState } from "react";
import { useEffect } from "react";

export default function MapWithSearchBox(props) {
  const [location, setLocation] = useState({ lat: 20, longitude: 70 });
  useEffect(() => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${props?.address},+CA`
    )
      .then((response) => response.json())
      .then((res) => {
        let latitude, longitude;
        latitude = res.results[0].geometry.location.lat;
        longitude = res.results[0].geometry.location.lng;
        setLocation({ lat: latitude, lng: longitude });
      });
  }, []);

  return (
    <>
      <Row className="my-3">
        <PlacesWithStandaloneSearchBox
          onPlacesChanged={(v) => console.log(v)}
          onChange={props?.onChange}
          value={props?.value}

          label={"Address Line"}
          error={props?.value === "" ? "Please enter address Line" : ""}
        />
      </Row>
      <div
        id="gmaps-overlay"
        className="gmaps py-3"
        style={{ position: "relative" }}
      >
        <Map
          showMarker={true}
          mapLocation={location}
          markerLocation={location}
          mapStyle={MapStyles}
          onMapMoved={props?.onMapMoved}
        />
      </div>
    </>
  );
}
