import React, { useContext, useEffect } from "react";
import { Container } from "reactstrap";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { UserContext } from "../../store/context/UserContext";
import logo from "./app-screen.png";
import './UserApps.scss'; // Make sure to create this SCSS file
import { useLocation } from "react-router-dom";
import { doGET } from "../../util/HttpUtil";

const UserApps = () => {
  const { webContent } = useContext(UserContext);
  const location = useLocation(); // Use this hook to access location details

  useEffect(() => {
    const fetchWebContent = async () => {

      try {
        const response = await doGET('/api/web-content');
        
        if (response.status === 200) {
          if (response?.data?.androidApp && navigator?.userAgent?.toLowerCase()?.includes("android")) {
            window.location.href = response?.data?.androidApp;
          } else if (response?.data?.iosApp && navigator?.userAgent?.toLowerCase()?.includes("iphone")) {
            window.location.href = response?.data?.iosApp;
          } else {
            window.location.href = response?.data?.website ??  'https://zoyride.com';
          }
        }
      } catch (error) {
        console.log(error);
      }

    };


    if (window.location.host.endsWith(".zoyride.com")) {
      fetchWebContent();
    } else { 
            window.location.href ='https://zoyride.com';
    }

  }, [location, webContent]);

  return (
    <React.Fragment>
      <div className="zr_user-app_main my-5">
        <Container className="zr_user-app_container">
          <div className="zr_user-app_header">
            {webContent?.logo && (
              <img
                src={webContent.logo}
                alt={webContent?.name}
                className="zr_user-app_logo"
              />
            )}
            {/* <div className="zr_user-app_title">{webContent?.name}</div> */}
          </div>

          <div className="zr_user-app_subtitle"> User Ride Booking App</div>

          <img
            src={logo}
            alt="App Interface"
            className="zr_user-app_screen"
          />

          <div className="zr_user-app_description">
            <ul>
              <li><strong>Instant Ride Booking:</strong> Get a ride when you need it, where you need it, with just a few taps.</li>
              <li><strong>Live Tracking:</strong> Follow your cab’s arrival in real-time for a no-wait experience.</li>
              <li><strong>Flexible Payment Options:</strong> Pay your way—cash, card, or in-app wallets, all accepted.</li>
              <li><strong>Diverse Vehicle Choices:</strong> Choose from a variety of vehicles to suit your occasion and budget.</li>
              <li><strong>24/7 Availability:</strong> Reliable service at any hour, to cater to your schedule.</li>
              <li><strong>Eco-friendly Rides:</strong> Go green with options for electric and hybrid vehicles.</li>
            </ul>
          </div>

          <div className="zr_user-app_links">
            <a href={webContent?.androidApp} target="_blank" rel="noopener noreferrer" className="zr_user-app_playstore">
              <FaGooglePlay className="icon" />
              Play Store
            </a>

            <a href={webContent?.iosApp} target="_blank" rel="noopener noreferrer" className="zr_user-app_appstore">
              <FaApple className="icon" />
              App Store
            </a>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserApps;
