import React from 'react';
import { FaFile, FaFileExcel, FaFileImage, FaFilePdf, FaFileVideo } from 'react-icons/fa';
import { FaHeadphones } from 'react-icons/fa6';
import { MdOutlineGif } from 'react-icons/md';
import { VscDebugBreakpointUnsupported } from "react-icons/vsc";
import { fileTypes } from '../../util/Util';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import DocViewer from '../DocUpload/WADocViewer';
import GIFPlayer from '../GIFPlayer/GIFPlayer';
import Image from '../Image/Image';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import ExcelChatDisplay from '../ExcelChatDisplay/ExcelChatDisplay';


export const detectFileType = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  return Object.keys(fileTypes).find(type => fileTypes[type].includes(extension)) || 'unknown';
};

export const getFileTypeIcon = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  if (fileTypes.image.includes(extension)) {
    return <FaFileImage />;
  }else if(fileTypes.gif.includes(extension)) {
    return <MdOutlineGif/>
  }else if (fileTypes.audio.includes(extension)) {
    return <div className='d-flex align-items-center p-0 m-0 '><FaHeadphones className='me-1' /> Audio</div>;
  } else if (fileTypes.video.includes(extension)) {
    return <div className='d-flex align-items-center p-0 m-0 '> <FaFileVideo className='me-1' /> Video </div>;
  } else if (fileTypes.pdf.includes(extension)) {
    return <FaFilePdf />;
  }else if (fileTypes.xlsx.includes(extension)) {
  return <FaFileExcel />;
}  else {
    return <FaFile />;
  }
};

const MediaChatViewer = ({ url }) => {
  const fileType = detectFileType(url);
  const urlInitial = "";

  const renderMedia = () => {
    switch (fileType) {
      case 'image':
        return <Image height={150} width={150} previewheight={400} previewwidth={400} src={`${urlInitial}${url}`} style={{ objectFit: "fill" }} alt="Document" />
      case 'gif':
        return <GIFPlayer src={`${urlInitial}${url}`} />
      case 'video':
        return <VideoPlayer src={`${urlInitial}${url}`}/>
      case 'audio':
        return <AudioPlayer src={`${urlInitial}${url}`} />;
      case 'pdf':
        return <DocViewer documents={[{ key: `${urlInitial}${url}` }]} dowloadable={true} />;
      case 'xlsx':
        return <ExcelChatDisplay url={`${urlInitial}${url}`} dowloadable={true} />;
      default:
        return (
          <div className='font-size-15'>
            <VscDebugBreakpointUnsupported size={30} />Unsupported file type
          </div>
        );
    }
  };

  return <>{renderMedia()}</>;
};

export default MediaChatViewer;
