import React, { useMemo } from 'react';
import { FaCarAlt } from 'react-icons/fa';
import { MdHotel, MdOutlineFlightTakeoff } from 'react-icons/md';

const IconBarChart = ({ trips = 0, stays = 0, flights = 0, customer_id }) => {
  const data = useMemo(() => {
    if (!customer_id) return [];
    
    return [
      { count: trips, icon: <FaCarAlt size={20} />, color: '#3e9efa' },
      { count: stays, icon: <MdHotel size={20} />, color: '#ffb01a' },
      { count: flights, icon: <MdOutlineFlightTakeoff size={20} />, color: 'rgb(165 159 159)' },
    ];
  }, [trips, stays, flights, customer_id]);

  const maxCount = useMemo(() => data.reduce((total, item) => total + item.count, 0), [data]);

  const calculateWidth = (count) => (maxCount ? `${(count / maxCount) * 100}%` : '0%');

  return (
    <div className="chart-container d-flex flex-column w-100">
      {data.map((item, index) => (
        <div key={index} className="d-flex justify-content-end align-items-center mt-3">
          <div className="chart-bar" style={{ height: '12px', width: calculateWidth(item.count), backgroundColor: item.color }} />
          <span className="icon_chartBar mx-1 font-size-16" style={{ color: item.color }}>{item.count}</span>
          <div className="ms-1 d-flex align-items-center">
            {item.icon}
          </div>
        </div>
      ))}
    </div>
  );
};

export default IconBarChart;
