import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../../../pages/ContactPerson/ContactPersonConstant";

class ContactPersonSelect {
    contactPersons = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (props) {
        try {
            const response = await doGET(ENDPOINTS.grid());
            if (response.status === 200) {
                this.contactPersons = response?.data?.rows?.map((v) => {
                    return { label: v.name, value: v._id };
                });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const ContactPersonSelectService = new ContactPersonSelect();
export default ContactPersonSelectService;
