import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import Editor from '../../components/dnd/forms/itinerary/components/RichTextEditor/Editor/Editor';
import { observer } from 'mobx-react-lite';

const EmailModal = ({ isOpen, toggleModal, value, handleInputChange }) => (
    <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Add Content</ModalHeader>
        <ModalBody>
            <Editor
                label=""
                placeholder="Content"
                value={value}
                onChange={htmlContent => handleInputChange('extraContent', htmlContent)}
            />
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={toggleModal}>Save</Button>
        </ModalFooter>
    </Modal>
);

export default observer(EmailModal);
