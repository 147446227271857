import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../../util/HttpUtil";

const ENDPOINTS = {
    grid: (trip_id) => {
        let url = `/api/trip-expense/grid?trip_id=${trip_id}`;
        return url;
    },
    get: (id) => `/api/trip-expense/${id}/detail`,
    save: `/api/trip-expense/create`,
    delete: (id) => `/api/trip-expense/${id}/delete`,
    bulkDelete: (id) => `/api/trip-expense/delete`,
    update: `/api/trip-expense/update`,
};

class Service {
    records = [];
    STRUCTURE = [];
    rowsPerPage = null;
    filterOption = "";
    total = -1;
    trip_id = null

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async (trip_id) => {
        this.trip_id = trip_id
        const response = await doGET(
            ENDPOINTS.grid(trip_id)
        );
        if (response?.status === 200) {
            this.records = response.data.rows;
            return response.data.rows
        }
    };

    edit = async function (data, id) {
        const response =  await doPUT(ENDPOINTS?.update, data);
        if (response.status === 200) {
            this.fetch(this.trip_id)
            return response.data ? response.data : response;
        }
    };

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    save = async (data) => {
        const response = await doPOST(ENDPOINTS.save, data);
        if (response.status == 200 ){
            this.records.push(data);
        }
        this.fetch(this.trip_id)
        return response?.data;
    };

    delete = async (id) => {
        const response = await doDELETE(ENDPOINTS.delete(id));
        if (response.status === 200) {
            this.fetch(this.trip_id)
        }
    };

}

const TripExpenseService = new Service();
export default TripExpenseService;

