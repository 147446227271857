import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Col, Button } from "reactstrap";
import { CountrySelect, CurrencySelect } from "../../../components";
// import { ENDPOINTS } from "./CreditNoteConstant";
import { CardThreeBounce, InputField, DeleteLink } from "../../../components";
import { yyyy_mm_ddtoTimeStamp } from "./../../../components/index";
// import { DialogContext } from "../../store/context/DialogContext";
import CorpSubsService from "./../CorpSubsService";

const PlansDetails = ({ data, setData, hasErr }) => {
  const [details, setdetails] = useState(data?.plans ? data?.plans : []);

  useEffect(() => {
    if (JSON.stringify(data?.plans) !== JSON.stringify(details))
      setData({ ...data, plans: details });
  }, [details]);

  return (
    <React.Fragment>
      <Row className="my-2">
        {details?.map((s, i) => (
          <Row className="shadow p-4 mb-3 bg-white rounded">
            <div
              onClick={() => {
                setdetails(() => {
                  let newData = details.slice();
                  newData.splice(i, 1);
                  return newData;
                });
              }}
              style={{ cursor: "pointer", position: "relative" }}
            >
              <i
                style={{ position: "absolute", right: 0, fontSize: "25px" }}
                className="dripicons-cross"
              />
            </div>

            <Row className="mt-4 mb-3">
              <Col className="col-sm-3">
                <CountrySelect
                  identifier={i}
                  placeholder="Country"
                  value={s?.country}
                  label={"Country"}
                  onChange={(v) =>
                    setdetails(
                      details.map((f, x) => {
                        if (i === x)
                          return {
                            ...s,
                            country: v.value,
                          };
                        return f;
                      })
                    )
                  }

                />
              </Col>

              <Col className="col-sm-3">
                <CurrencySelect
                  value={s?.currency}
                  label={"Currency"}
                  placeholder={"Currency"}
                  onChange={(v) =>
                    setdetails(
                      details.map((f, x) => {
                        if (i === x)
                          return {
                            ...s,
                            currency: v.value,
                          };
                        return f;
                      })
                    )
                  }

                />
              </Col>
              <Col className="col-sm-3">
                <InputField
                  identifier={i}
                  value={s?.minRental}
                  label={"Minimum Rental"}
                  placeholder={"Minimum Rental"}
                  onChange={(v) =>
                    setdetails(
                      details.map((f, x) => {
                        if (i === x) {
                          return {
                            ...s,
                            minRental: v,
                          };
                        }
                        return f;
                      })
                    )
                  }

                  type="number"
                  required={true}
                  hasErr={hasErr}
                />
              </Col>
              <Col className="col-sm-3">
                <InputField
                  identifier={i}
                  placeholder="Per Trip cost"
                  value={s?.perTripCost}
                  label={"Per Trip cost"}
                  onChange={(v) =>
                    setdetails(
                      details.map((f, x) => {
                        if (i === x)
                          return {
                            ...s,
                            perTripCost: v,
                          };
                        return f;
                      })
                    )
                  }

                  type="number"
                  required={true}
                  hasErr={hasErr}
                />
              </Col>
            </Row>
          </Row>
        ))}
      </Row>
      <Row className="d-flex justify-content-end ">
        <Button
          className="col-sm-1"
          onClick={() => setdetails([...details, {}])}
        >
          Add +
        </Button>
      </Row>
    </React.Fragment>
  );
};

export default observer(PlansDetails);
