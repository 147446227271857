import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { DeleteButton, SaveButton, } from "../../components";
import { useDeepCompareEffect } from "../../helpers/customHooks/useDeepCompareEffect";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import InvoiceExtn from "./InvoiceExtn";
import InvoiceFooter from "./InvoiceFooter";
import Invoiceservice from "./InvoiceService";
import { validateTripsForInvoice } from "./helper";
import InvoiceHeader from "./Component/InvoiceHeader";
import InvoiceFee from "./Component/InvoiceFee";
import { Col } from "reactstrap";
import _ from "lodash";
import BookingService from "../Booking/BookingService";
import TagPicker from "../../components/TagSelector/TagPicker";
import PaymentReceivedService from "../PaymentReceived/PaymentReceivedService";
import { doPOST } from "../../util/HttpUtil";
import Receipts from "./Receipt";
import EmployeeSupervisorLabel from "../../components/Dropdowns/EmployeeSupervisorSelect/EmployeeSupervisorLabel";

const InvoiceForm = ({
  editId,
  insidePane,
  concise = false,
  validateFeegroup,
  onDelete = () => { },
  onSave = () => { },
  trip_ids,
  toggleModal
}) => {
  const [invoice, setInvoice] = useState({
    paymentTerms: "Due on Receipt",
    status: 1,
    paymentStatus: 0,
    savedStatus:1
  });
  const [loading, setLoading] = useState(false);
  const [tax, setTax] = useState({});
  const { showError, showConfirm, showMessage } = useContext(DialogContext);
  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  let navigate = useNavigate();
  const [noOfInvoices, setNoOfInvoices] = useState(1);
  const [itemStatuses, setItemStatuses] = useState([]);
  const [paymentReceipts, setPaymentReceipts] = useState([]);
  const [error,setError]=useState(null)

  const fetchInvoice = async () => {
    setLoading(true);
    try {
      const response = await Invoiceservice.get(editId);
      setInvoice({...response,savedStatus:response?.status});
    } catch (error) {
      console.log(error);
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  function getSettlementByInvoiceId(payments, invoiceId) {
    let results = [];
    payments.forEach(payment => {
        const settlement = payment?.settlements.find(settlement => settlement?.invoice_id === invoiceId);
        if (settlement) {
            results.push({
                date: payment?.date,
                amountSettled: settlement?.amountSettled,
                tdsSettled: settlement?.tdsSettled
            });
        }
    });
    return results;
}

  const fetchPaymentReceived = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let response = await doPOST(`/api/payment-received/grid?rows=-1`);
      const payments = response?.data?.rows;
      const receipts = getSettlementByInvoiceId(payments, editId)
      setPaymentReceipts([...receipts]);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const fetchTripsAndBillings = async ({ trip_ids }) => {
    setLoading(true);
    try {
      const response = await Invoiceservice.fetchTrips(trip_ids);
      const validation = validateTripsForInvoice({ trips: response });
      if (validation.isValid && !editId) {
        const fetchedItems = response.map(trip => ({
          invoiceTrip: { trip_id: trip?._id },
          manual: false,
        }));

        const clientInfo = {
          fullName: response[0]?.passengers?.[0]?.name,
          email: response[0]?.passengers?.[0]?.email,
          phoneNo: `${response[0]?.passengers?.[0]?.phone?.countryCode}-${response[0]?.passengers?.[0]?.phone?.phone}`,
          address: response[0]?.src
        };

        let walkInInvoice = false;
        let client = {
          org_id: response[0]?.client?.client_id,
          company_id: response[0]?.client?.company_id
        };

        if (!response[0]?.client?.client_id) {
          client = { ...clientInfo };
          walkInInvoice = true;
        }
        if(response?.length && response[0]?.tripDate &&response[0]?.vendorCompany_id){
          await fetchInvoiceNumber(response[0]?.vendorCompany_id,response[0]?.tripDate)
         }
        setInvoice(prev => ({
          ...(prev ?? {}),
          // invoiceDate: response[0]?.tripDate,
          client,
          vendorCompany_id: response[0]?.vendorCompany_id,
          walkInInvoice,
          invoiceExtn: {
            ...(prev?.invoiceExtn ?? {}),
            items: fetchedItems
          }
        }));
      } else {
        showError(validation.message);
      }
    } catch (error) {
      console.error(error);
      showError(error.message || "An error occurred while fetching trips and billings");
    } finally {
      setLoading(false);
    }
  };

  const fetchInvoiceNumber = async (vendorCompany_id, invoiceDate) => {
    try {
      const obj = {};
      if (vendorCompany_id) {
        obj["vendorCompany_id"] = vendorCompany_id;
      }
      if (invoiceDate) {
        obj["invoiceDate"] = invoiceDate;
      }
      if (vendorCompany_id && invoiceDate) {
        const data = await Invoiceservice.getInvoiceNumber(obj);
        const invoiceNo = data?.invoiceNo;
        if (invoiceNo) {
          setInvoice((oldInvoice) => ({
            ...oldInvoice,
            invoiceNo: invoiceNo || "",
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  const onChange = useCallback((updatedData) => {
    setInvoice(prevInvoice => ({ ...prevInvoice, ...updatedData }));
    const updatedInvoice={...invoice, ...updatedData};
    setItemStatuses(()=> updatedInvoice?.invoiceExtn?.items.map(() => 'idle'));
  }
    , [invoice, setInvoice]);


  const handleInvoiceCreate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const nInv = await Invoiceservice.save(invoice);
      if (nInv) {
        window.open(`/invoice/edit/${nInv._id}`, "'_blank'");
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };


  useDeepCompareEffect(() => {
    async function fetchData() {
      if (!editId) {
        if (!trip_ids?.length) {
          setInvoice({
            paymentTerms: "Due on Receipt",
            status: 1,
            paymentStatus: 0,
            savedStatus:1,
            // dueDate: invoice?.invoiceDate ?? null,
            invoiceExtn: {
              properties: [{}],
              items: [
                {
                  fee: {
                    feeGroups: [
                      {
                        name: `Group ${invoice?.invoiceExtn?.items.length ?? 1}`,
                        charges: [{}],
                        discounts: [{}],
                        taxItem: {
                          taxes: [{}],
                        },
                      },
                    ],
                  },
                  invoiceTrip: {},
                  manual: true
                },
              ],
            },
          });
        }
      }
    }
    fetchData();
  }, [editId]);


  const handleDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await Invoiceservice.delete(id);
        onDelete()
      } catch (e) {
        showError(e);
      }
    }
  };

  const handleSave = async (e) => {
    // e.preventDefault();
    if (!invoice?.invoiceDate) {
      showError(t("Please fill invoice date"));
      return;
    }
    if(!invoice?.taxItem?.tax_id){
      showError(t("Please select tax"));
      return;
    }
    if (!invoice?.status) {
      showError(t("Please select status"));
      return;
    }
    if (!invoice?.vendorCompany_id) {
      showError(t("Please select serving company"));
      return;
    }

    if (!invoice?.invoiceNo) {
      showError(t("Please add invoice number"));
      return;
    }


    if (validateFeegroup && validateFeegroup(invoice?.invoiceExtn?.items)) {
      return;
    }

    setLoading(true); //TODO do not remove
    try {
      if (editId) {
        const res=await Invoiceservice.edit(invoice);
        if(res.data){
          setInvoice({...res.data,savedStatus:res.data?.status});
        }
        showMessage("Record Updated successfully");
      } else {
        // if (noOfInvoices >1) {
        //   setItemStatuses(() => invoice?.invoiceExtn?.items.map(() => 'loading'));
        //   const results = await Promise.allSettled(
        //     invoice?.invoiceExtn?.items?.map(async (item,index) => {
        //       return Invoiceservice.save({ ...invoice, invoiceExtn: { ...invoice?.invoiceExtn, items: [item] } });//pass single item
        //     })
        //   );
        //   setItemStatuses(results.map(result => result.status === 'fulfilled' ? 'success' : 'failed'))
        //   if(!results.some(result => result.status === 'rejected') && toggleModal){
        //       toggleModal()
        //   }
           
        //  const failedItems= invoice?.invoiceExtn?.items?.filter((item, index) => results[index]?.status === 'rejected' );
        //  if(failedItems?.length){
        //    setInvoice(prevInvoice => ({ ...prevInvoice, invoiceExtn: { ...prevInvoice?.invoiceExtn, items: failedItems } }));
        //    setItemStatuses(Array.from({length: failedItems?.length}, () => 'failed'));
        //    showError("Some records failed to save");
        //  }else{
        //   showMessage(t("Record saved successfully."));
        //  }

        // } 
        if (noOfInvoices > 1) {
          const items = invoice?.invoiceExtn?.items || [];
          let hasFailure = false;
          setItemStatuses(() => invoice?.invoiceExtn?.items.map(() => 'loading'));

          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            try {
              await Invoiceservice.save({ ...invoice, invoiceExtn: { ...invoice?.invoiceExtn, items: [item] } });
              setItemStatuses(prevStatuses => {
                const newStatuses = [...prevStatuses];
                newStatuses[i] = 'success';
                return newStatuses;
              });
            } catch (error) {
              setItemStatuses(prevStatuses => {
                const newStatuses = [...prevStatuses];
                newStatuses[i] = 'failed';
                return newStatuses;
              });
              hasFailure = true;
            }
          }

          if (!hasFailure && toggleModal) {
            toggleModal();
          }

          const failedItems = items.filter((item, index) => {
            return itemStatuses[index] === 'failed';
          });

          if (failedItems.length > 0) {
            setInvoice(prevInvoice => ({
              ...prevInvoice,
              invoiceExtn: { ...prevInvoice?.invoiceExtn, items: failedItems }
            }));
            setItemStatuses(Array.from({ length: failedItems.length }, () => 'failed'));
            showError("Some records failed to save");
          } else {
            showMessage(t("Record saved successfully."));
          }
        }

        else {
          const inv = await Invoiceservice.save(invoice);
          let invoicedTripIds=inv?.invoiceExtn?.items.map((item)=>{
            return item?.invoiceTrip?.trip_id;
            })
            onSave(invoicedTripIds);
          if(concise){
            window.open(`/invoice/edit/${inv?._id}`, "_blank");
            toggleModal && toggleModal();
          }else if (!insidePane) navigate(`/invoice/edit/${inv?._id}`);
          showMessage(t("Record saved successfully."));
        }
      }
      setTax(prev=>({...prev,updated:false}))
        // const inv = await Invoiceservice.save(invoice);
        // showMessage(t("Record saved successfully."));
        // if (!insidePane) navigate(`/invoice/edit/${inv?._id}`);
      // }

    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };
  const handleInvoiceBillingCalculateAfterTripUpdate=async()=>{
    try {
      const inv = await Invoiceservice.calculate({ ...invoice, taxItem: { tax_id: tax?._id, taxes: tax?.items }, });
      if(!_.isEqual(inv, invoice)){
          onChange(inv)
      }
      
    } catch (error) {
      showError(error)
    }
  }

  useEffect(() => {
    if (editId) {
      fetchInvoice();
      fetchPaymentReceived();
    } else {
      setInvoice(prev=>({
        ...prev,
        invoiceDate: parseInt(moment().format('YYYYMMDD'))
      }))
    }
  }, [editId]);

  useEffect(() => {
    if (trip_ids?.length && !editId) {
      fetchTripsAndBillings({ trip_ids });
    }
  }, [trip_ids]);


  return (
    <div div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {loading ? (<div>Loading...</div>) : (
        <>
        <div className="zr_layout-header flex-column p-3 d-flex justify-content-between" style={{ flexShrink: 0 }} >
            <div className="d-flex align-items-center px-2">
              {editId ? t("Edit Invoice") : t("Add Invoice")}
              <TagPicker
                className="ms-2"
                kind={"invoice"}
                onChange={(v) => setInvoice(prev=>({
                  ...prev,
                  tags: v
                }))}
                value={invoice?.tags}
              />
            </div>

            {editId && <div className="d-flex align-items-center flex-1">
              <div className="mt-3 mx-2">
                <span style={{ color: '#999999' }}>
                  {t(`Created At`)}
                </span>
                <span>
                  {"  "}
                  {moment.unix(invoice?.createdAt).format("DD-MM-YYYY  hh:mm A")}
                </span>
              </div>
              <div className="mt-3 col-4 d-flex">
                <span style={{ color: '#999999' }}>
                  {t(`Created By`)} &nbsp;
                </span>
                {"  "}
                <EmployeeSupervisorLabel value={invoice?.createdBy} />
              </div>
            </div>}

          </div>
          <div  style={{ overflowY: "auto", height: "100%", overflowX: "hidden" }}>
          <Card className="p-3">
            <InvoiceHeader
              hideToggle={trip_ids?.length || editId}
              invoice={invoice}
              onChange={onChange}
              // handleInvoiceChange={handleInvoiceChange}
              fetchInvoiceNumber={fetchInvoiceNumber}
              noOfInvoices={noOfInvoices}
              setNoOfInvoices={setNoOfInvoices}
              concise={concise}
              error={error}
              setError={setError}
            />
          </Card>

          <InvoiceExtn
            invoiceExtn={invoice?.invoiceExtn}
            itemStatuses={itemStatuses}
            tax={tax}
            invoiceId={invoice?._id}
            clientId={invoice?.client?.org_id} 
            savedStatus={invoice?.savedStatus}
            // onChange={(v) => {
            //   setInvoice((prev) => ({ ...prev, invoiceExtn: v }))
            // }}
              onChange={setInvoice}
              handleSave={handleSave}
              handleInvoiceBillingCalculateAfterTripUpdate={handleInvoiceBillingCalculateAfterTripUpdate}
            />
            <div className="d-flex justify-content-end gap-5">
              {paymentReceipts?.length > 0 &&
                <div className="col-sm-4">
                  <Receipts className="ms-auto" data={paymentReceipts} />
                </div>
              }
              {invoice?.invoiceExtn?.items?.length > 0 &&
                <div className="col-sm-4 me-3" >
                  <InvoiceFee invoice={invoice} onChange={onChange} tax={tax} setTax={setTax} />
                </div>}
            </div>
            {invoice?._id ? <InvoiceFooter invoiceConfig_id={invoice?.invoiceExtn?.invoiceConfigUsed} companyName={invoice?.invoiceExtn?.vendorInfo?.fullName} /> : null}
            <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              {/* {invoice?._id && userContent?.rights?.includes(4503) ? (
                <DeleteButton onClick={(e) => handleDelete(e, invoice?._id)} />
              ) : null} */}
            </div>
            <div className="d-flex align-items-center me-3">
              {userContent?.rights?.includes(4502) && <SaveButton onClick={handleSave} />}
            </div>

          </div>
          </div>
          {/* <SaveButton loading={loading} onClick={handleInvoiceCreate} /> */}
        </ >
      )}
    </div>
  );
};


export default InvoiceForm;
