import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../../util/HttpUtil";

const ENDPOINTS = {
  grid: (module = 'grouptour', type = 0) => `/api/space/grid?rows=-1&module=${module}&type=${type}`,
  get: (id) => `/api/space/${id}/detail`,
  save: `/api/space/create`,
  delete: (id) => `/api/space/${id}/delete`,
  update: `/api/space/update`,
};


class Service {
  records = [];
  total = -1;
  version = 0;
  selectedFolder = localStorage.getItem("space") && localStorage.getItem("space") != "null" ? { _id: localStorage.getItem("space") } : {};

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedFolder = (folder) => {
    this.selectedFolder = folder;
    if (folder?._id) {
      localStorage.setItem("space", folder?._id);
    } else {
      localStorage.removeItem("space");
    }
  };

  fetch = async (module, type) => {
    const response = await doGET(ENDPOINTS.grid(module, type));
    if (response?.status === 200 && Array.isArray(response.data.rows)) {
      if (!this.selectedFolder?._id) {
        // const firstChildSpace = response.data.rows.find(e => e.parentSpace_id !== undefined);
        // if (firstChildSpace) {
        //   this.selectedFolder = firstChildSpace;
        // }
        this.selectedFolder = { module, name: "Everything", _id: null, type: "single" };
      }
      const recordResponse = this.buildHierarchy(response.data.rows);
      this.records = recordResponse;
      return recordResponse
    }
  };

  buildHierarchy = (spaces) => {
    let hierarchy = [];
    let lookup = {};

    spaces.forEach(folder => {
      lookup[folder._id] = { ...folder, children: [] };
    });

    spaces.forEach(folder => {
      if (folder.parentSpace_id && lookup[folder.parentSpace_id]) {
        lookup[folder.parentSpace_id].children.push(lookup[folder._id]);
      } else {
        hierarchy.push(lookup[folder._id]);
      }
    });

    return hierarchy;
  };

  edit = async function (data) {
    const response = await doPUT(ENDPOINTS?.update, data);
    if (response.status === 200) {
      this.version += 1;
      this.fetch(data?.module);
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data, fullresponse) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    if (response.status === 200) {
      this.fetch(data?.module);
      return fullresponse ? response?.data : response?.data?._id;
    }
  };


  delete = async (id, module) => {
    const response = await doDELETE(ENDPOINTS.delete(id, module));
    if (response.status === 200) {
      this.fetch(module);
    }
  };


}

const GroupTourSidebarService = new Service();
export default GroupTourSidebarService;

