import React, { useContext, useEffect, useState } from 'react'
import { ApiHandler } from '../../../store/apiHandler/apiHandler';
import { DialogContext } from '../../../store/context/DialogContext';
import { I18nContext } from '../../../store/context/i18nContext';
import { UserContext } from '../../../store/context/UserContext'; 
import { FaCheck } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap"; 
import { InputField, NameField, PhoneNum } from '../../../components';
import LanguageMenu from '../../../components/CommonForBoth/TopbarDropdown/LanguageSelect/LanguageMenu';
import Step4 from './Step4';
import Step1 from './Step1'
import { doGET, doPUT } from '../../../util/HttpUtil';
import { ENDPOINTS } from "../../../common/utils/Constants";


function SignupProfileUpdate() {
    let navigate = useNavigate();
    const {showError}=useContext(DialogContext);
    const {t}=useContext(I18nContext);
    const {populateUserContent}=useContext(UserContext);
    const [data, setData] = useState({
        corpType: "2",
      });


    const updateAuthToken = async (refreshToken) => {
        try {
            const reqBody = {
                reqParam: {}, method: "GET", endPoint: "/auth/refresh-token",
                token: refreshToken
              };
              const newBearerTokenResponse = await ApiHandler(reqBody);
      
              if (newBearerTokenResponse?.status === 200) {
                localStorage.setItem("BearerToken", newBearerTokenResponse.data?.bearerToken);
                localStorage.setItem("TokenExpiry", newBearerTokenResponse.data.expiry);
                localStorage.setItem("RefreshToken", refreshToken);
                
                const response = await doGET(ENDPOINTS?.getDetail(
                    newBearerTokenResponse?.data?.userContent?.adminOf
                    ));
                if (response.status === 200 && response.data) {
                    setData(response.data);
                }
                // await populateUserContent();
                // navigate("/dashboard", { replace: true }); 
                // window.location.reload();
              }else{
                  showError(t("Invalid Token"))
                  navigate("/")
              }
        } catch (error) {
            console.error('Authorization token is missin');
            console.log("error", error)
            showError(error)
        }

    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const refreshToken = searchParams.get('refresh');
        const corpType=searchParams.get("corpType");
        if(corpType){
            setData(prev=>({...prev,corpType}))
        }else{
            setData(prev=>({...prev,corpType:'2'}))
        }

        if (refreshToken) {
            updateAuthToken(refreshToken)
        } else {
            console.error('Authorization token is missing');
            showError(t("Authorization token is missing"));
            navigate("/")
        }
    }, [navigate]);


    const updatedDataWithCorporateExtn = {
        ...data,
        corporateExtn: {
          ...data.corporateExtn,
        },
      };
    
      const onUpdate = async () => {
        
        try {
          const response = await doPUT(
            ENDPOINTS?.updateCorporate,
            updatedDataWithCorporateExtn
          );
    
          if (response.status === 200) {
            await populateUserContent();
            navigate("/dashboard");
    
            return;
          } else {
            showError("Error");
          }
        } catch (error) {
          showError(error);
        }
      };

  return (
    <React.Fragment>
        <>
    <div
      style={{
        overflowY: "auto",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Row className="mb-5 mt-3 d-flex justify-content-end">
        <Col className="col-4 ">
          <LanguageMenu outline />
        </Col>
      </Row>
      <div className="account-pages pt-sm-3  ">
        <Container className="d-flex justify-content-center  ">
          <Row
            className="align-items-center justify-content-center w-100"
            style={{
              border: "1px solid #D5D5D5",
              borderRadius: "1.25rem",
              maxWidth: "500px",
            }}
          >
            <Col className="flex-1 mx-6 col-6">
              <Card className="shadow-none ">
                <CardBody className="p-4">
                  <Link className="mb-4 mt-2 d-block auth-logo">
                    <img
                      src="/logo.png"
                      alt="zoyride-logo"
                      height="40"
                      className="logo logo-dark mb-1"
                    />
                  </Link>
                  <h3 className="align-items-center justify-content-center d-flex pb-3 font-size-18">
                    Signup
                  </h3>

                 
                  <div className=" d-flex justify-content-between flex-column mt-1">
                    <div>
                 
                   
                       
                        <div className="account-pages mb-2 pt-sm-2 ">
                          <Container className="d-flex justify-content-center  w-100">
                            <Row className="align-items-center justify-content-center">
                              <Col className="">
                                <div className="text-center ">
                                  <div className="">
                                  {  data?.corpType == 1 && (
                                        <Step1
                                          onChange={(v) =>
                                            setData({
                                              ...data,
                                              corporateExtn: {
                                                ...data.corporateExtn,
                                                employeeCount: v?.employeeCount,
                                                serviceRequired:
                                                  v?.serviceRequired,
                                              },
                                            })
                                          }
                                        />
                                      )}

                                      {  data?.corpType == 2 && (
                                        <Step4
                                          onChange={(v) =>
                                            setData({
                                              ...data,
                                              corporateExtn: {
                                                ...data.corporateExtn,
                                                vehicleTypeCount:
                                                  v?.vehicleTypeCount,
                                              },
                                            })
                                          }
                                        />
                                      )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                    
       
                        <Row>
                          <div onClick={() => {
                                 onUpdate()
                              }} className=" mt-4 d-flex align-items-center justify-content-center ">
                            <i
                              
                              className="bx border p-2 text-light bg-primary rounded-5 font-size-22  bx-right-arrow-alt"
                              style={{
                                backgroundColor: "white",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </Row>
                   
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
        </>
  </React.Fragment>
  )
}

export default SignupProfileUpdate