import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import {
  Address,
  CardThreeBounce,
  CountrySelect,
  CurrencySelect,
  InputField,
  CurrencyAmountField
} from "../../components";
import AddonService from "./AddonService";
import { I18nContext } from "../../store/context/i18nContext";
import { DialogContext } from "../../store/context/DialogContext";
import NameMasterSelect from "../../components/Dropdowns/NameMaster/NameMasterSelect";


const EditAddon = ({ editId, onChange = () => { } }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetch = async () => {
    try {
      let states = await AddonService.get(editId);
      setData(states);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    setData({
      // ...data,
      currency: "INR"
    });
    setLoading(true);
    if (editId) fetch();
    setLoading(false);
  }, [editId, AddonService?.version]);

  useEffect(() => {
    onChange(data);
  }, [data]);
  console.log("--------", data);
  return (
    <React.Fragment>
      {loading ? (
        <CardThreeBounce />
      ) : (
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row>
            <NameMasterSelect
              kind={"addonName"}
              placeholder={t("Name")}
              value={data?.name ? data?.name : "Name"}
              required={true}
              label={t("Name")}
              onChange={(v) => setData({ ...data, name: v?.value })}
              type="    "
              className="col-sm-12"
            />
          </Row>
          <Row>
            <CurrencyAmountField
              className="col-sm-12"
              value={
                {
                  amount: data?.unitPrice ? data?.unitPrice : "Amount",
                  currency: data?.currency ? data?.currency : "Currency",
                }
              }
              label={t("Amount")}
              onChange={(v) => {
                setData({
                  ...data,
                  unitPrice: v?.amount,
                  currency: v?.currency,
                })
              }}
            />
          </Row>
        </Form>
      )}
    </React.Fragment>
  );
};

export default observer(EditAddon);
