import { makeAutoObservable, } from "mobx";
import { ENDPOINTS } from "./UserMasterConstant";
import { doGET } from "../../util/HttpUtil";


class Service {
  records = [];
  totalRecords = 0;
  STRUCTURE = [];
  page = 1;
  rows = 20;
  filterOption = "";
  error = "";
  constructor() {
    makeAutoObservable(this);
  }


  fetch = async (filterUrl) => {
    if (filterUrl) this.filterOption = filterUrl;
    else this.filterOption = {};

    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rows, filterUrl)
    );

    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }
  };
  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    await this.fetch(this.filterOption);
  };
}

const UserMasterService = new Service();
export default UserMasterService;
