import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "./ClientCompanyConstant";

class ClientCompany {
    clients = [];
    isFetching = false;
    page = 1;
    rowsPerPage = 10;
    filterOption = "";
    total = -1;

    constructor() {
        makeAutoObservable(this);
    }

    getUniqueClient = (data) => {
        let groupedEntries = []
        let uniqueClients = [];

        data?.forEach((v) => {
            if (!uniqueClients?.includes(v?.companyGroupName)) {
                v?.companyGroupName !== null ? uniqueClients?.push(v?.companyGroupName) : console.log("")
            }
        });

        uniqueClients?.map((client, index) => {
            const groupedEntities = { label: client, options: [] }
            let entities = []
            data?.forEach((v) => {
                if (v?.companyGroupName == client) {
                    entities.push({ ...v, value: v?._id, label: v?.fullName, groupLabel: client })
                }
            });
            groupedEntities.options = entities
            groupedEntries.push(groupedEntities)
        })
        return groupedEntries;
    }

    fetchSingle = async (filterUrl) => {
        return new Promise(async (resolve, reject) => {
            try {

                const response = await doGET(ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl));
                if (response.status === 200) {
                    let groupedEntries = []
                    let uniqueClients = [];

                    response?.data?.rows?.forEach((v) => {
                        if (!uniqueClients?.includes(v?.companyGroupName)) {
                            v?.companyGroupName !== null ? uniqueClients?.push(v?.companyGroupName) : console.log("")
                        }
                    });

                    uniqueClients?.map((client, index) => {
                        const groupedEntities = { label: client, options: [] }
                        let entities = []
                        response?.data?.rows?.forEach((v) => {
                            if (v?.companyGroupName == client) {
                                entities.push({ ...v, value: v?._id, label: v?.fullName, groupLabel: client })
                            }
                        });
                        groupedEntities.options = entities
                        groupedEntries.push(groupedEntities)
                    })

                    if (this.page === 1) {
                        this.clients = groupedEntries
                        this.total = response?.data?.total;
                    } else {
                        this.clients = [...this.clients, ...groupedEntries]
                    }
                } else {
                    // Handle error
                    this.error = response.data;
                }

            } catch (err) {
                this.error = err;
            } finally {
                this.isFetching = false;
            }
        });
    };

    fetch = async function (filterUrl = "") {
        if (this.filterOption !== filterUrl) {
            this.page = 1;
            this.total = -1;
        }
        this.isFetching = true;
        this.fetchSingle(filterUrl)
    };

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch(this.filterOption);
    };


    get = async (id) => {
        const response = await doGET(ENDPOINTS.getClient(id));;
        if (response.status === 200) {
            const uniqueClients = this.getUniqueClient(response.data.rows);
            this.clients = [...this.clients, ...uniqueClients]
        }
    }
}

const ClientCompanyService = new ClientCompany();
export default ClientCompanyService;
