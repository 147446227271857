import { useContext, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { I18nContext } from "../../../store/context/i18nContext";
import OnDemandMessageForInvoice from "./OnDemandMessageForInvoice";
import { VscSend } from "react-icons/vsc";
import { ModalComponent } from "../../../components";

const MessageDialog = ({ invoice_id, toggleModal, modal }) => {
  const { t } = useContext(I18nContext);

  // const [modal, setModal] = useState(false);

  // const toggleModal = (e) => {
  //   setModal(!modal);
  //   e?.stopPropagation();
  // };
  return (
    <>
      {/* <ModalToggleButton t={t} toggleModal={toggleModal} /> */}
      <ModalComponent
        position={"top"}
        size={"medium"}
        isOpen={modal}
        onToggle={() => toggleModal()}
        modalSizeStyle={{ height: "700px" }}
      >
        <ModalComponent.Title>
          <h1>Send Invoice Info</h1>
        </ModalComponent.Title>
        <ModalComponent.Body>
          <OnDemandMessageForInvoice toggleModal={toggleModal} invoice_id={invoice_id} />
        </ModalComponent.Body>
      </ModalComponent>
    </>
  );
};
const ModalToggleButton = ({ t, toggleModal }) => {
  return (
    // <div className="d-flex justify-content-end my-2">
    <Button
      onClick={toggleModal}
      className="d-flex w-100"
      style={{ height: "28px", padding: "2px 10px 2px 4px" }}
      color="white"
    >
      <div className="me-2">
        <VscSend fontSize={14} />
      </div>
      {t(`Send Invoice`)}
    </Button>
    // </div>
  );
};
export default MessageDialog;
