import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { Button, Card } from 'reactstrap';
import { InputField } from '../../../components';
import Tokenizer from '../../../components/InputField/TokenInput';
import MinimizableComponentEmail from '../../../components/MinimizableComponentEmail';
import { DialogContext } from '../../../store/context/DialogContext';
import { UserContext } from '../../../store/context/UserContext';
import { doGET, doPOST } from '../../../util/HttpUtil';
import CommunicationService from '../../Communication/CommunicationService';
import QuillToolbar from './QuillToolbar';
import "./styles.scss";
import AttachmentUploader from '../../../components/DocUpload/AttachmentUploader';

const WriteEmail = ({ prefilledData, onClose }) => {
    const { showError, showMessage } = useContext(DialogContext);
    const { webContent } = useContext(UserContext);
    const [data, setData] = useState({ subject: '', extraContent: '', content: '', to: [] });
    const [attachments, setAttachments] = useState([]);
    const [headerContent, setHeaderContent] = useState('');
    const [footerContent, setFooterContent] = useState('');
    const quillRef = useRef(null);
    const [printContent, setPrintContent] = useState(`
        <!DOCTYPE HTML>
        <html>
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
            <title>Test</title>
            <style>
                @page { size: A4; margin-top: 20px; margin-bottom: 10px; }
                .page-footer {
                    position: fixed;
                    bottom: 10px;
                    right: 10px;
                    background-color: white;
                    padding: 5px;
                    border: 1px solid #ccc;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                }
            </style>
        </head>
        <body style="padding: 20px; padding-top:2px">
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"
            integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL"
            crossorigin="anonymous"></script>
            <table id="table-data" style="width: 100%;">
    `);

    const handleInputChange = (field, value) => {
        if (field === "extraContent") {
            const updatedBody = `${printContent}${headerContent}<div style="width: 600px; margin-left: auto; margin-right: auto; padding:10px 10px 10px 0px">${value}</div>${footerContent}</body></html>`;
            setData(prev => ({
                ...prev,
                extraContent: value,
                body: updatedBody
            }));
        } else {
            setData(prev => ({ ...prev, [field]: value }));
        }
    };

    const getHeaderFooter = async () => {
        try {
            const { data: { helpLines = [] } } = await doGET("/api/corporate-config/detail/helpLines");
            const helpline = helpLines.find(v => v?.isDefault)?.customerHelpline || helpLines[0]?.customerHelpline;
            const { data: headerData } = await doPOST('/print-server/email/header', {
                VendorCompanyName: webContent?.fullName ?? webContent?.name,
                logo: `https://${window.location.host}${webContent?.logo}`
            });
            const { data: footerData } = await doPOST('/print-server/email/footer', {
                helpLine: helpline,
                tnc: "This is an automated email, please do not reply to this email. For any queries related to services please contact our helpline."
            });
            setHeaderContent(headerData);
            setFooterContent(footerData);
            setPrintContent(printContent);
            const updatedBody = `${printContent + headerData}${footerData}</body></html>`;
            setData(prev => ({ ...prev, body: updatedBody }));
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => { getHeaderFooter(); }, [webContent]);
    useEffect(() => {
        if (prefilledData) {
            setData(prefilledData)
        }
    }, [prefilledData])

    const handleSendMail = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const invalidEmails = data?.to?.filter(email => !emailRegex.test(email));
        if (invalidEmails.length) {
            return showError("Invalid email addresses");
        }
        if (!data.subject) return showError("Subject is missing");
        if (!data.to.length) return showError("Recipients missing");
        if (data.extraContent === "<p><br></p>" || data.extraContent === "") return showError("Email Body is missing.");

        const formData = new FormData();
        formData.append('data', JSON.stringify({ ...data, type: "email", messageType: "TRIP_VEHICLE_ASSIGNED_EMPLOYEE" }));
        attachments.forEach((file, index) => formData.append(`attachment_${index}`, file));

        try {
            const response = await doPOST("/api/email/create?hasAttachment=y", formData);
            if (response.status === 200) {
                CommunicationService.addMail({ id: response.data._id, ...data });
                showMessage("Email Sent Successfully");
            }
        } catch (error) {
            showError(error);
        } finally {
            onClose();
        }
    };

    return (
        <MinimizableComponentEmail onClose={onClose} title="Compose Email">
            <div className='write-email-container'>
                <Tokenizer
                    label="Recipients"
                    value={data.to}
                    plain
                    onChange={newTokens => setData(prevData => ({ ...prevData, to: newTokens }))}
                    placeholder="Add recipient emails"
                />
                <div>
                    <InputField
                        outline
                        placeholder="Subject"
                        value={data.subject}
                        onChange={v => handleInputChange('subject', v)}
                        type="text"
                    />
                    <div className='mt-1'>
                        <div className="p-1" style={{ position: "relative", }}>
                            <div className="email-header-container" dangerouslySetInnerHTML={{ __html: headerContent }} />
                        </div>
                    </div>
                    <div className="d-flex flex-column align-items-center crm_email_quill ">
                        <ReactQuill
                            ref={quillRef}
                            value={data.extraContent}
                            onChange={(content, delta, source, editor) => {
                                if (source !== "api") {
                                    handleInputChange('extraContent', editor.getHTML());
                                }
                            }}
                            className='w-100'
                            modules={{ toolbar: "#quill-toolbar" }}
                            placeholder="Content"
                        />

                    </div>

                    <div className='mb-2'>
                        <div className="p-3" style={{ position: "relative", }}>
                            <div className="email-footer-container" dangerouslySetInnerHTML={{ __html: footerContent }} />
                        </div>
                    </div>


                    <AttachmentUploader
                        attachments={attachments}
                        multiple={true}
                        onAttachmentChange={(newAttachments) => {
                            setAttachments(newAttachments);
                        }}
                    />

                </div>

                <div className="quill-toolbar-container w-100 mt-2">
                    <QuillToolbar />

                    <div className="py-1 d-flex justify-content-end">
                        <Button color="success" onClick={handleSendMail} className="ms-2 py-1">
                            Send <i className="fab fa-telegram-plane ms-1"></i>
                        </Button>
                    </div>

                </div>
            </div>

        </MinimizableComponentEmail>
    );
};

export default WriteEmail;
