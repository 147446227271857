import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { yyyy_mm_ddtoTimeStamp } from "./../../../components/index";
import { InputField, SelectField, TaxSlabSelect, CompanySelect } from "../../../components";

export default function SubsDetail({ data, setData, hasErr, setHasErr }) {
  useEffect(() => { }, [data]);

  const paymentType = [
    {
      label: "Prepaid",
      value: 1,
    },
    {
      label: "Prepaid Anually",
      value: 2,
    },
    {
      label: "Postpaid monthly",
      value: 3,
    },
  ];

  return (
    <div className="active">
      <Row className="mt-3 mb-3 ">
        <Col className="col-lg">
          <InputField
            placeholder="Client Name"
            value={data?.clientName}
            label={"Client Name"}
            onChange={(v) =>
              setData({
                ...data,
                clientName: v,
              })
            }
            showErr={hasErr}
            isDisabled={true}
            type="text"
            required={true}
          />
        </Col>
        <Col className="col-lg">
          <CompanySelect
            value={data?.bu_id}
            label={"Business Unit"}
            onChange={(v) =>
              setData({
                ...data,
                bu_id: v.value,
              })
            }
            client={data?._id}
            required={true}
            showErr={hasErr}

          />
        </Col>
      </Row>

      <Row className="mt-3 mb-3 ">
        <Col className="col-lg">
          <SelectField
            data={paymentType}
            placeholder="Payment Type"
            value={data?.paymentType}
            label={"Payment Type"}
            onChange={(v) =>
              setData({
                ...data,
                paymentType: v.value,
              })
            }

          />
        </Col>
        <Col className="col-lg">
          <TaxSlabSelect
            placeholder="Tax Slab"
            value={data?.taxSlab_id}
            label={"Tax Slab"}
            onChange={(v) =>
              setData({
                ...data,
                taxSlab_id: v.value,
              })
            }

          />
        </Col>
      </Row>

      <Row className="mt-3 mb-3 ">
        <Col className="col-lg">
          <InputField
            placeholder="Notification Emails"
            value={data?.notifyEmail}
            label={"Notification Emails"}
            required={true}
            onChange={(v) =>
              setData({
                ...data,
                notifyEmail: v,
              })
            }
            showErr={hasErr}

            type="text"
          />
        </Col>
      </Row>
    </div>
  );
}
