import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditRoles from "./EditRoles";
import { STRUCTURE } from "./RoleConstant";
import { GridColumns } from "./RoleGridColumns";
import Roleservice from "./RoleService";
import { UserContext } from "../../store/context/UserContext";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";

const Roles = ({ value, insidePane, multiMode, onSelect = () => { } }) => {

    let { role_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const { userContent, populateUserContent, forSuperadmin } = useContext(UserContext);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(role_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/role/create" || role_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)



    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await Roleservice.fetch(filterUrl);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }, [])


    useEffect(() => {
        setEditID(role_id)
    }, [role_id])


    const onSave = async (e) => {
        e.preventDefault();
        if (!detailData?.name) {
            setHasErr(true)
            showError(t("Please enter all fields"));
            return;
        }
        setLoading(true);
        try {
            if (editID) {
                await Roleservice.edit(detailData, editID);
                setLoading(false);
                showMessage(t("Role updated successfully."));
            } else {
                const roleID = await Roleservice.save(detailData);
                setLoading(false);
                showMessage(t("Role saved successfully."));
                if (!insidePane) navigate(`/role/edit/${roleID}`);
            }
            fetchData(filterURL);
            await populateUserContent();
        } catch (e) {
            setLoading(false);
            showError(e);
        }
        setHasErr(false)
        setLoading(false);
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await Roleservice.delete(id);
                setShowDetailPage(false);
                navigate(`/role`);
                setLoading(false);
                showMessage(t(" Role Deleted"), t("Deleted "));
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };
    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            try {
                await Roleservice.bulkDelete(selectedIDs);
                showMessage(t("Deleted"), t("Deleted Successfully"));
            } catch (e) {
                showError(e);
            }
        }
    };

    const renderLastCol = useCallback((role) => {
        return (
            <>
                {(userContent?.rights?.includes(2202) || (userContent?.userType && userContent?.userType === "super-admin")) &&
                    <EditLink
                        onClick={() => {
                            if (!insidePane)
                                navigate(`/role/edit/${role?._id}`);
                            setEditID(role?._id);
                            setShowDetailPage(true);
                        }}
                    />
                }
                {(userContent?.rights?.includes(2203) || (userContent?.userType && userContent?.userType === "super-admin")) ?
                    <DeleteLink
                        onClick={(event) => onDelete(event, role?._id)}
                    />
                    : null}
                {!multiMode && insidePane ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(role?._id);
                        }}
                    />
                ) : null}
            </>
        );
    }, [insidePane, multiMode, userContent])

    return (
        <>
            <Layout
                hideAdd={!userContent?.rights?.includes(2201) && !(userContent?.userType && userContent.userType === "super-admin")}
                showDetailPage={showDetailPage}
                backDetailPage={() => {
                    setShowDetailPage(false);
                    if (!insidePane) navigate("/role");
                    setEditID(null);
                }}
                 
                title={t("Roles")}
                filterValues={filterObject}
                filterStructure={STRUCTURE}
                onApplyFilter={fetchData}
                onAddClick={() => {
                    if (!insidePane) navigate(`/role/create`);
                    setShowDetailPage(true);
                    setEditID(null);
                }}
                insidePane={insidePane}
                page={Roleservice.page}
                rows={Roleservice.rows}
                total={Roleservice.total}
                fetch={Roleservice.fetch}
            >
                <Layout.ActionMenu>
                    <div className="layout-action-menu">
                        <DropdownMenu>
                            <>
                                {(userContent?.rights?.includes(2203) || (userContent?.userType && userContent?.userType === "super-admin")) ?
                                <>  <div class="dropdown-divider"></div>
                                    <DropdownItem onClick={() => { onBulkDelete() }}>{t("Bulk Delete")}</DropdownItem>
                                    </>
                                    : null}
                                                  
                                <DropdownItem>{t("Upload")}</DropdownItem>
                                <div class="dropdown-divider"></div>
                                <DropdownItem> {t("Download")} </DropdownItem>
                            </>
                        </DropdownMenu>
                    </div>
                </Layout.ActionMenu>
                <Layout.Table>
                    <DataGrid
              gridLoading={loading}
                        data={Roleservice.records}
                        total={Roleservice.total}
                        uiPreference="Role.grid"
                        headers={GridColumns}
                        selectedIDs={selectedIDs}
                        onSelectChange={(v) => {
                            onSelect(v)
                            setSelectedIDs(v)
                        }}
                        page={Roleservice.page}
                        rowsPerPage={Roleservice.rowsPerPage}
                        onPaginationChange={Roleservice.onPaginationChange}
                        renderLastCol={renderLastCol}
                    />
                </Layout.Table>
                <Layout.DetailPageTitle>
                    {role_id ? t("Edit Role") : t("Add Role")}
                </Layout.DetailPageTitle>
                <Layout.DetailPageBody>
                    <EditRoles
                        editId={editID}
                        onChange={(v) => {
                            setDetailData(v)
                        }}
                        hasErr={hasErr}
                    />
                </Layout.DetailPageBody>

                <Layout.DetailPageFooter>
                    {detailData?._id ? (
                        <AuditLogDialog module="Role" id={detailData?._id} />
                    ) : null

                    }
                    {(userContent?.rights?.includes(2203) || (userContent?.userType && userContent?.userType === "super-admin")) && editID ?
                        (<DeleteButton loading={loading} onClick={(e) => onDelete(e, detailData?._id)} />)
                        : null}
                    {(userContent?.rights?.includes(2202) || (userContent?.userType && userContent?.userType === "super-admin")) ?
                        <SaveButton
                            disabled={!detailData?.name}
                            loading={loading}
                            onClick={onSave} />
                        : null}
                </Layout.DetailPageFooter>
            </Layout>
        </>
    );
};
export default observer(Roles);
