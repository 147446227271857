import moment from "moment";
import { mapSvgIcon, PeopleMarkerIcon, svgIcon } from "../../../components/OpenStreetMap/icons";
import { FEMALE_COLOR, MALE_COLOR } from "../../../components/Rostering/utils";
import { getFullAddress, isFemalePassenger } from "../../../util/Util";
import { POLYLINE_COLOR } from "../RosteringConstant";
import { doGET } from "../../../util/HttpUtil";
import BookingService from "../../Booking/BookingService";
import { v4 as uuid } from 'uuid';

 


export const getMarkerColor = (type) => {
  switch (type) {
    case 0:
      return "green";
    case 1:
      return "blue";
    case 2:
      return "red";
    default:
      return "green";
  }
}

export const generateUniqueId = () => {
  // return `id-${Date.now().toString()}-${Math.random().toString(36).substring(2, 9)}`;
  return uuid();
};


export const getPassengers = (passengers,forMarker=false) => {
  //populate customer_id in passengers and marker for markers
  return passengers?.map(passenger => ({
    ...passenger,
    customer_id:passenger?._id,//populate customer_id for passenger in trip
    ...(forMarker &&{
      type: 'passenger',
      id:passenger?._id,
    title: passenger?.name ?? "",
    address: getFullAddress(passenger?.residence)??"",
    icon: mapSvgIcon(getMarkerColor(0), isFemalePassenger(passenger?.salutation) ? FEMALE_COLOR : MALE_COLOR),
    lat: passenger?.residence?.lat,
    lng: passenger?.residence?.lng
    })
  }))/* ?.filter(passenger=>passenger?.residence?.lat && passenger?.residence?.lng) ?? [];//filtering to avoid null markers */
};

export const getTripMarkers = (records) => {
  return records?.map(record => ({
    ...record,
    type: 'trip',
    title: record?.title ?? "",
    address: getFullAddress(record?.src),
    icon: svgIcon,
    lat: record?.src?.lat,
    lng: record?.src?.lng
  })) ?? [];
};



//to call this func while saving trip or forming roster for passengers inside trip
export const populatePassengerPickupOrDrop = (passengers, rosterType, officeAddress) => {
  return passengers?.map(({ _id = "", version = "", ...passenger }) => ({
    ...passenger,
    ...(_id && { customer_id: _id }),
    ...(populatePickAndDropOnBasisOfRosterType(passenger, rosterType, officeAddress) ?? {}),
  }))?.filter(passenger => passenger?.pickup && passenger?.drop)//considering only those passenger whose residence is there
}

export const filterAlreadyAssignedPassengers = (tripPassengers, passengersList) => {
  return passengersList.filter(passenger => { //TODO currently differentiating using name but need to use _id
    return !tripPassengers.some(cp => cp.name === passenger.name || cp?._id === passenger?._id || cp?.customer_id === passenger?.customer_id || cp?._id === passenger?.customer_id || cp?.customer_id === passenger?._id); 
  });
};

export const populateResidence=(trip,type)=>({
  ...trip,
  id:generateUniqueId(),
  passengers: trip?.passengers?.map(passenger => ({
    ...passenger,
    ...({
      residence: passenger?.residence ? passenger?.residence : (isPickerTypeEvent(type) ? passenger?.pickup : passenger?.drop)
    })
  }))??[]
})

//for populating passenger details initially
export const populatePassengerResidence = (data, type) => data?.map(trip => populateResidence(trip, type));

export const getTripPolyLine=(trip)=>{
  const coordinates= trip?.passengers?.map(passenger=>({
    lat:passenger?.residence?.lat,
    lng:passenger?.residence?.lng
  }))??[] 
  //23-regular-drop,26-adhoc-drop  then src will be office so push office not dst as dst willbe last passenger residence
  if(trip?.src && [23,26]?.includes(trip?.tripType)){
    coordinates.unshift({lat:trip?.src?.lat,lng:trip?.src?.lng})
  }
  //22-regular-pickup,25-adhoc-pickup then dst will be office so push office not src as src willbe first passenger residence
  if(trip?.dst && [22,25]?.includes(trip?.tripType)){
    coordinates.push({lat:trip?.dst?.lat,lng:trip?.dst?.lng})
  }
  return {coordinates,cardId:trip?.id}
}

export const getAllTripsPolyLines = (trips) =>  trips?.map(trip=>getTripPolyLine(trip));

export const isPickerTypeEvent = (rosterType) => [2, 4].includes(rosterType)//2-regular-pickup,2-regular-drop,4-adhoc-pickup,4-adhoc-drop

export const passengerAddress = (passengers, isPickupType) => passengers?.length && isPickupType ? passengers[0]?.residence : passengers[passengers?.length - 1]?.residence

//trip src and dst
export const populateSrcOrDstOnBasisOfRosterTypeAndPassengers = (src, dst, rosterType, officeAddress, passengers = []) => {
  const isPickupType = isPickerTypeEvent(rosterType);
  return {
    src: isPickupType ?passengerAddress(passengers, isPickupType) ?? src  : officeAddress,//if pickup type then src will be first passenger address else if drop type then office address
    dst: isPickupType ? officeAddress :  passengerAddress(passengers, isPickupType) ?? dst//if pickup type then dst will be officeaddress and for drop type then last passenger address
  }
}
//passenger pickup an drop
export const populatePickAndDropOnBasisOfRosterType = (passenger, rosterType, officeAddress) => {
  const isPickupType = isPickerTypeEvent(rosterType);
  return {
    pickup: isPickupType ? passenger?.residence || passenger?.pickup : officeAddress,
    drop: isPickupType ? officeAddress : passenger?.residence || passenger?.drop
  }
}
//trip start and endTime
export const populateStartTimeAndEndTimeOnBasisOfRosterType = (startTime, endTime, space) => {
  const isPickupType = isPickerTypeEvent(space?.type);
  return {
    startTime: isPickupType ? (space?.fromDate && space?.dropTme ? moment(space?.fromDate + ',' + space?.dropTme,'YYYYMMDD,HHmm').unix() : startTime) ://here startTime should no be mandatory
      (space?.fromDate && space?.pickUpTme ? moment( space?.fromDate + ',' + space?.pickUpTme,'YYYYMMDD,HHmm').unix() : startTime),
    endTime: isPickupType ?(space?.fromDate && space?.dropTme ? moment(space?.fromDate + ',' + space?.dropTme,'YYYYMMDD,HHmm').unix() : endTime) :null,
  }
}

const fetchDistanceBetweenCoordinates = async ({ src, dst }) => {
  let kms = 0;
  let mins = 0;
  if (src?.lat && dst?.lat && src?.lng && dst?.lng) {
    try {
      const response = await doGET(
        `/api/o/geocode/distance-time?src=${src?.lat},${src?.lng}&dst=${dst?.lat},${dst?.lng}`
      );
      if (response.status === 200) {
        kms = response?.data?.kms;
        mins = response?.data?.mins;
      }
    } catch (error) {
      console.log(error);
    }
  }
  return { kms: kms, mins: mins };
};

//for trip
export const populateTimeAndPassengerOnBasisOfRosterType =async(pickupTime, reachBy,date, rosterType,passengers,officeAddress) => {
  //TODO throw error if no passenger allocated
  const isPickupType = isPickerTypeEvent(rosterType);
  // const { kms, mins }=await BookingService.fetchDurationBetweenLocations({src,dst});
  const promises=(passengers??[]).slice(0, -1).map((passenger, i) => {
    const nextPassenger = passengers[i + 1];
    const src={
      lat:passenger?.residence?.lat,
      lng:passenger?.residence?.lng
    }
    const dst={ 
      lat:nextPassenger?.residence?.lat,
      lng:nextPassenger?.residence?.lng
    }
    return fetchDistanceBetweenCoordinates({src,dst})
  })
  let durations=(await Promise.allSettled(promises)).map(res=>res?.value); 
  //here populate each passenger pickup time and drop time on basis of roster type
  //if pickup type then we know the end time
  // calcualte tim between last passenger(the same last passenger will act as first in droptype) (this will be calculated in both pickup and drop)
  if(passengers?.length){
    const lastOrFirstPassenger = isPickupType ? passengers[passengers.length - 1]: passengers[0] ;
    const src=isPickupType?lastOrFirstPassenger?.residence:officeAddress;
    const dst=isPickupType?officeAddress:lastOrFirstPassenger?.residence;
    const {kms,mins}=await fetchDistanceBetweenCoordinates({src,dst});
    if(isPickupType){
      durations.push({kms,mins})
    }else{
      durations.unshift({kms,mins})
    }
  }

  const totalDuration=durations.reduce((total, duration) => total + (duration?.mins??0)*60, 0);//in seconds
  const totalDistance=durations.reduce((total, duration) => total + (duration?.kms??0), 0);//in kms
  let endTime=isPickupType?moment(date + ',' + reachBy,`YYYYMMDD,${formatDropTime(reachBy)}`).unix():null;
  let startTime=!isPickupType?moment(date + ',' + pickupTime,`YYYYMMDD,${formatDropTime(pickupTime)}`).unix():null;
  //now calculate start and endtime using duration and also populate passenger pickup and drop time
  if(isPickupType){
    startTime=endTime-totalDuration;
  }else{
    endTime=startTime+totalDuration;
  }
  if(isPickupType){
    let nextDuration=0;
    durations.forEach((duration, i) => {
      const passenger = passengers[i]; 
        passenger.pickupTime = startTime + nextDuration;
        nextDuration += (duration.mins ?? 0) * 60; 
      passenger.dropTime = endTime;
    });

  }else{
    let nextDopTime=0; 

    passengers/* .reverse() */?.forEach((passenger,i) => {
      passenger.pickupTime = startTime;//as we already know startTime in dropType
       nextDopTime += (durations[/* durations.length-1- */i].mins ?? 0) * 60;
      passenger.dropTime=startTime+nextDopTime;
    });
  }
  
  return {startTime,endTime,passengers,totalDistance}
}

export const  getUniqueMarkers=(existingMarkers, newMarkers)=> {
  const markerMap = new Map(existingMarkers.map(marker => [marker.id, marker]));
  
  newMarkers.forEach(marker => {
      markerMap.set(marker.id, marker); 
  });
  
  return Array.from(markerMap.values());
}

export const filterCustomerAccordingToSpaceType = (type) => {
  switch (type) {
    case 2:
      return '&availsRegularPickup=true';
    case 3:
      return '&availsRegularDrop=true';
    case 4:
      return '&availsAdhocPickup=true';
    case 5:
      return '&availsAdhocDrop=true';

    default:
      return ""
  }
}

export const formatDropTime = (time) => {
  console.log("===",time);
  if(time){
    time=time.toString()
  }
  time = time?.replace(/^0+/, '');//removing leading zeroes

  let format=null;
  if (/^\d{3}$/.test(time)) {
    format = 'Hmm'; // Handles 900 (9:00)
  } else if (/^\d{4}$/.test(time)) {
    format = 'HHmm'; // Handles 1234 (12:34)
  }
  return   format 
};