import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { decodePolyline } from "..";
import "./styles.css"

const CustomPane = () => {
    const map = useMap();
  
    useEffect(() => {
      // Create a custom pane with a higher z-index
      map.createPane('highPane');
      map.getPane('highPane').style.zIndex = 650;
      map.createPane('lowPane');
      map.getPane('lowPane').style.zIndex = 550;
  
      return () => {
        map.remove()
      };
    }, [map]);
  
    return null;
  };
  


const OpenStreetMap= forwardRef(({
    markers = [],
    polyLines = null,
    showPopup = false,
    onMarkerClick = () => { },
    customPopup = null,
},ref) =>{
    const mapRef = useRef(null);
    const markerRefs = useRef({});

    const [data, setData] = useState({
        markers: [],
        bounds: [],
        initialized: false,
        // polyLinePath: []
        polyLinePaths: []
    });
    const updateMapData = useCallback(() => {
        try {
            if (!data.initialized) return;
            let updateData = null;

            //set markers data
            if (markers.length) {
                updateData = {
                    markers
                }
            }

            //set bounds using markers
            let bounds = markers.filter(marker => marker.lat && marker.lng).map((marker) => ([marker.lat, marker.lng]));

            // //bounds using polyline
            // // single polyline
            // if (polyLine) {
            //     let coordinates = decodePolyline(polyLine);
            //     if (coordinates.length) {
            //         coordinates = coordinates.map(coord => ([coord.lat, coord.lng]));
            //         updateData = {
            //             ...updateData,
            //             polyLinePath: coordinates
            //         }
            //         //update bounds
            //         bounds = [...bounds, ...coordinates]
            //     }
            // }
            if (polyLines?.length) {
                let allCoordinates = [];
                let allPolylinePaths = [];

                polyLines.forEach(polyLine => {
                    let coordinates = decodePolyline(polyLine.data);
                    if (coordinates.length) {
                        coordinates = coordinates.map(coord => [coord.lat, coord.lng]);
                        allPolylinePaths.push({...polyLine, path: coordinates, color: polyLine?.color??'#ccc' });
                        allCoordinates = [...allCoordinates, ...coordinates];
                    }
                });

                if (allCoordinates.length) {
                    updateData = {
                        ...updateData,
                        polyLinePaths: allPolylinePaths
                    };
                    bounds = [...bounds, ...allCoordinates];
                }
            }


            if (data.initialized && mapRef.current && bounds.length) {
                //set bounds
                // mapRef.current.fitBounds(bounds/* , { padding: [20, 20] } */);
                mapRef.current.flyToBounds(bounds/* , { padding: [20, 20] } */);
                updateData = {
                    ...updateData,
                    bounds
                }
            }
            //to rebound map to a polyline coordinates if there is any selected polyline
            if(polyLines?.filter(polyLine=>polyLine?.selected).length){
                updateMapBounds([], polyLines?.filter(polyLine=>polyLine?.selected))
            }
            //update state data if any
            if (updateData) {
                setData(prev => ({ ...prev, ...updateData }));
            }
        } catch (error) {

        }
    },[data.initialized, markers, polyLines])


    useEffect(() => {
        updateMapData();
    }, [data.initialized, markers,  polyLines])

    const updateMapBounds=(boundMarkers, polyLines) => {
        try {
            if (!data.initialized || !mapRef.current) return;
            let bounds = boundMarkers?.filter(marker => marker.lat && marker.lng).map(marker => [marker.lat, marker.lng]);
            if (polyLines?.length) {
                polyLines.forEach(polyLine => {
                    const coordinates = decodePolyline(polyLine.data);
                    if (coordinates.length) {
                        bounds = bounds.concat(coordinates.map(coord => [coord.lat, coord.lng]));
                    }
                });
            }
            if (bounds.length) {
                mapRef.current.flyToBounds(bounds, { padding: [5, 5] });
            }
        } catch (error) {

        }
    }


    useImperativeHandle(ref, () => ({
        updateMapBounds ,
        clickMarker : (markerId) => {
            try {
                //close all other markers
                Object.keys(markerRefs.current).forEach(key => { 
                     markerRefs.current?.[key]?.closePopup();
               });
   
               if (markerRefs.current[markerId]) {
                 markerRefs.current?.[markerId]?.openPopup();
                 onMarkerClick({id:markerId})
               }
             
            } catch (error) {
                
            }
          }
        
    }));

    return (
        <>
            <MapContainer
                className="markercluster-map"
                center={[28.4594965, 77.0266383]}
                zoom={15}
                maxZoom={16}
                style={{ flex: 1 }}
                whenCreated={(map) => {
                    mapRef.current = map;
                    setData(prev => ({ ...prev, initialized: true }))
                    if(mapRef.current){
                        const resizeObserver = new ResizeObserver(() => mapRef.current?.invalidateSize())
                        const container = document.getElementsByClassName('markercluster-map')
                        if (container?.length) {
                          resizeObserver.observe(container[0])
                        }
                    }
                }}
                bounds={data.bounds}
            >
                <TileLayer
                    // url="http://164.52.218.96:7020/tile/{z}/{x}/{y}.png"
                    url="https://map-tiles.zryd.in/tile/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <MarkerClusterGroup>
                    {
                        data.markers.map((marker, index) => {
                            return (marker?.lat && marker?.lng ? 
                            <Marker
                                key={index}
                                position={[marker.lat, marker.lng]}
                                icon={marker.icon}
                                eventHandlers={{ click: () => onMarkerClick(marker) }}
                                ref={(ref) => { markerRefs.current[marker.id] = ref; }}
                            >
                                {showPopup ? (
                                    <Popup offset={[0, -50]}>
                                        <>
                                            {customPopup ? (
                                                customPopup(marker)
                                            ) : (
                                                <>
                                                    <h4>Marker Information</h4>
                                                    <p>{marker.title}</p>
                                                </>
                                            )}
                                        </>
                                    </Popup>
                                ) : null}
                            </Marker> : null)
                        })
                    }
                </MarkerClusterGroup>
                {/* {data.polyLinePaths.map((polyline, index) => (
                    <Polyline key={index} positions={polyline.path} pathOptions={{color:polyline.color}} pane={polyline?.pane} weight={polyline?.weight} stroke={true} opacity={polyline.opacity} fillOpacity={polyline.fillOpacity}/>
                ))} */}
                {data.polyLinePaths.map((polyline, index) => (
                    <Polyline
                        key={index}
                        positions={polyline.path}
                        pathOptions={{
                            color: polyline.color,
                            pane: polyline.pane,
                            weight: polyline.weight,
                            opacity: polyline.opacity,
                            fillOpacity: polyline.fillOpacity
                        }}
                    />

                ))}
                <CustomPane />

                {/* <Polyline positions={data.polyLinePath} color="red" /> */}
            </MapContainer>
        </>
    );
});

export default OpenStreetMap;