import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Row } from "reactstrap";
import { CardThreeBounce, CitySelect, CountrySelect, InputField, StateSelect } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import RateAreaService from "./RateAreaService";

const EditRateAreas = ({ editId, onChange = () => { } }) => {
    const { t } = useContext(I18nContext);
    const [loading, setLoading] = useState(false);
    const { showError } = useContext(DialogContext);
    const [data, setData] = useState();

    const fetch = async () => {
        try {
            let states = await RateAreaService.get(editId);
            setData(states);
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        setData({
            regions: [
                {
                    state: "",
                    cities: [],
                    excludedCities: [],
                }
            ]
        })

        setLoading(true);
        if (editId) fetch();
        setLoading(false)
    }, [editId, RateAreaService.version]);

    useEffect(() => {
        onChange(data)
    }, [data]);

    const deleteRegion = (index) => {
        const updatedRegions = data?.regions.filter((_, i) => i !== index);
        setData({ ...data, regions: updatedRegions });
    };

    const addRegion = () => {
        const newRegion = {
            state: "",
            cities: [],
            excludedCities: [],

        };
        setData({ ...data, regions: [...(data?.regions ?? []), newRegion] })

    }

    return (
        <React.Fragment>
            {loading ?
                <CardThreeBounce /> :
                <Form
                    className="p-3"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                >
                    <div className="shadow-none">
                        <div className="d-flex justify-content-between " >
                            <Row className="row flex-1  mt-1 mb-1 me-4 ms-1 d-flex align-items-center" >
                                <InputField
                                    placeholder={t("Name")}
                                    value={data?.name}
                                    required={true}
                                    label={t("Name")}
                                    onChange={(v) => setData({ ...data, name: v })}
                                    showError={data?.name?.length === 0 ? "Please enter Name" : ""}
                                    type="text"
                                    className="col-12 col-sm-6"
                                />
                                <CountrySelect
                                    placeholder={t("Country")}
                                    value={data?.country}
                                    required={true}
                                    label={t("Country")}
                                    onChange={(v) => setData({ ...data, country: v.value })}
                                    showError={data?.country?.length === 0 ? "Please enter country" : ""}
                                    type="text"
                                    className="col-12 col-sm-6"
                                />
                            </Row>
                        </div>
                    </div>

                    {data?.regions?.map((region, index) => (
                        <div key={index} className="shadow-none">
                            <Card>
                                <div className="d-flex justify-content-between " >
                                    <Row className="row flex-1  mt-1 mb-1  ms-1 d-flex align-items-center" >
                                        <StateSelect
                                            placeholder={t("State ")}
                                            country={data?.country}
                                            value={region?.state}
                                            // hidestate={data?.regions.map((region) => region.state)}
                                            label={t("State")}
                                            onChange={(v) => {
                                                const updatedRegions = [...data?.regions];
                                                console.log(updatedRegions)
                                                updatedRegions[index] = { ...updatedRegions[index], state: v?.value }
                                                setData({ ...data, regions: updatedRegions })

                                            }}
                                            type="text"
                                            className="col-sm-12"
                                        />

                                        <CitySelect
                                            key="2"
                                            placeholder={t("Included city ")}
                                            value={region.cities}
                                            label={t("Included City")}
                                            country={data?.country}
                                            state={region?.state}
                                            null_is_all
                                            // hidecities={region?.excludedCities}
                                            multi={true}
                                            onChange={(v) => {
                                                const updatedRegions = [...data?.regions];
                                                updatedRegions[index] = { ...updatedRegions[index], cities: v }
                                                setData({ ...data, regions: updatedRegions })
                                            }}
                                            type="text"
                                            className="col-sm-12"
                                            disabled={region?.excludedCities?.length > 0}
                                        />
                                        <CitySelect
                                            key="3"
                                            noLabel
                                            placeholder={t("Select Excluded City")}
                                            value={region?.excludedCities}
                                            label={t("Excluded City")}
                                            country={data?.country}
                                            state={region?.state}
                                            multi={true}
                                            onChange={(v) => {
                                                const updatedRegions = [...data?.regions];
                                                updatedRegions[index] = { ...updatedRegions[index], excludedCities: v }
                                                setData({ ...data, regions: updatedRegions })
                                            }}
                                            type="text"
                                            className="col-sm-12"
                                            disabled={region?.cities?.length > 0}
                                        />
                                    </Row>
                                    <div>
                                        <i
                                            onClick={() => {
                                                deleteRegion(index)
                                            }}
                                            className="dripicons-cross ms-3 font-size-20 p-0 m-0 cursor-pointer"

                                        ></i>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ))}
                    <div>
                        <Button color="light" onClick={addRegion} className="d-flex h-36 align-items-center">
                            <i className="bx font-size-20 color-blue bxs-plus-circle me-2" />
                            {t("Add Regions")}
                        </Button>

                    </div>
                </Form>
            }
        </React.Fragment>
    );
};

export default observer(EditRateAreas);






