import moment from "moment";
import EmployeeLabel from "../../components/Dropdowns/EmployeeSelect/EmployeeLabel";
import EmployeeSupervisorLabel from "../../components/Dropdowns/EmployeeSupervisorSelect/EmployeeSupervisorLabel";
import VehicleLabel from "../../components/Dropdowns/VehicleSelect/VehicleLabel";
import CustomTooltip from "../Booking/components/CustomTooltip";
import { AmountField } from "../../components";

export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Vehicle",
      renderer: (dataObj) => {
        return (
          <div>
            <VehicleLabel value={dataObj?.vehicle_id}/>
          </div>
        );
      },
      show: true,
    },
    {
      title: "Employee",
      renderer: (dataObj) => {
        return (
          <div>
  
            <EmployeeLabel value={dataObj?.employee_id} />
      
          </div>
        );
      },
      show: true,
    },

    {
      title: "Date",
      renderer: (dataObj) => {
        return (
          <div>
            {moment.unix(dataObj?.time).format("DD-MM-YYYY")}
          </div>
        );
      },
      show: true,
    },
    {
      title: "Quantity",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.qty / 1000} {dataObj?.qtyType}
          </div>
        );
      },
      show: true,
    },
    {
      title: "Fuel Type",
      accessor: "fuelType",
      show: true,
    },
    {
      title: "Odometer",
      renderer: (dataObj) => {
        return <div>{dataObj?.kms} KMs</div>;
      },
      show: true,
    },
    {
      title: "AMOUNT",
      renderer: (dataObj) => {
        return (
          <div>
            <AmountField value={dataObj?.amount} asLabel rightText={dataObj?.currency}/>
            {/* {(dataObj?.amount/100).toFixed(2)}  */}
          </div>
        );
      },
      show: true,
    },
    {
      title: "Receipt No",
      renderer: (dataObj) => {
        return (
          <div>
            {(dataObj?.receiptNo)}
          </div>
        );
      },
      show: true,
    },
    // {
    //   title: "UNIT PRICE",
    //   renderer: (dataObj) => {
    //     return (
    //       <div>
    //         {dataObj?.unitPrice} {dataObj?.currency}
    //       </div>
    //     );
    //   },
    //   show: true,
    // },
    {
      title: "OUTSTATION",
      renderer: (dataObj, rowIndex,pageSize) => {

        return (
          <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -5 }}>
              {dataObj?.isOutstation === true ? (
                  <div>

                      <CustomTooltip>
                          <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                              <i id="not-clickable-88-active" className="fas fa-check-circle  font-size-12 mt-1" style={{ color: 'green' }} />
                          </CustomTooltip.Body>
                      </CustomTooltip>

                  </div>
              ) : (
               
                <CustomTooltip>
                <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                <i id="not-clickable-88-active" className="fas fa-times-circle  font-size-12 mt-1" style={{ color: 'red' }} />
                </CustomTooltip.Body>
            </CustomTooltip>
              )}
          </div>
      );
      },
      show: true,
    },
    {
      title: "FULL TANK",
      renderer: (dataObj) => {

        return (
          <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -5 }}>
              {dataObj?.fullTank === true ? (
                  <div>
                      <CustomTooltip>
                          <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                              <i id="not-clickable-88-active" className="fas fa-check-circle  font-size-12 mt-1" style={{ color: 'green' }} />
                          </CustomTooltip.Body>
                      </CustomTooltip>

                  </div>
              ) : (
                                
                <CustomTooltip>
                <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                <i id="not-clickable-88-active" className="fas fa-times-circle  font-size-12 mt-1" style={{ color: 'red' }} />
                </CustomTooltip.Body>
            </CustomTooltip>
              )}
          </div>
      );
      },
      show: true,
    },
    {
      title: "MILEAGE",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.mileage ? (dataObj?.mileage/100)?.toFixed(2): "--"} KM/{dataObj?.qtyType}
          </div>
        );
      },
      show: true,
    },
    {
      title: "PAYMENT MODE",
      accessor: "paymentMode",
      show: true,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
    {
      title: "Added By",
      accessor: "by",
      show: true,
      renderer: (dataObj) => {
        return <div>
          <EmployeeSupervisorLabel value={dataObj?.by} />
        </div>;
      },
    }
  ],
};