import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./CustomFieldConstant.js";
import { doGET, doPOST, doPUT } from "../../util/HttpUtil";

class Service {
  records = [];
  totalRecords = 0;
  STRUCTURE = [];
  page = 1;
  rows = 20;
  filterOption = "";
  downloadFilterOption = "";
  error = "";

  constructor() {
    makeAutoObservable(this);
  }

  getSelectObject = (obj) => {
    var map = [];
    for (var i in obj) {
      map.push({ label: i, value: obj[i] });
    }
    return map;
  };

  getStructure = async () => {
    this.countryMap = (await doGET(ENDPOINTS.countryMap))?.data;
    this.zoneMap = (await doGET(ENDPOINTS.zoneMap))?.data;
    this.vendorMap = (await doGET(ENDPOINTS.vendorMap))?.data;
    this.customFieldMap = (await doGET(ENDPOINTS.customFieldMap))?.data;
    this.STRUCTURE = [
      {
        label: "CustomField Type",
        description: "Select CustomField Type",
        filterLabel: "CustomField Type",
        filterName: "customFieldType",
        type: "select",
        data: this.getSelectObject(this.customFieldMap),
      },
      {
        label: "Country",
        description: "Select Country",
        filterLabel: "Country",
        filterName: "country",
        type: "select",
        data: this.countryMap.map((country) => ({
          ...country,
          label: country.name,
          value: encodeURI(country.name),
        })),
      },
      {
        label: "Vendor",
        description: "Select Vendor",
        filterLabel: "Vendor",
        filterName: "vendor_id",
        type: "select",
        data: this.getSelectObject(this.vendorMap),
      },
      {
        label: "Zone",
        description: "Select Zone",
        filterLabel: "Zone",
        filterName: "zone_id",
        type: "select",
        data: this.zoneMap.map((zone) => ({
          label: zone.name,
          value: zone._id,
        })),
      },
      {
        label: "From Date",
        description: "Select From Date",
        filterLabel: "From Date",
        filterName: "fromTime",
        type: "dateSelect",
        getTimestamp: true,
      },
      {
        label: "Till Date",
        filterLabel: "Till Date",
        description: "Select Till Date",
        filterName: "tillTime",
        type: "dateSelect",
        getTimestamp: true,
      },
      {
        label: "Reg. Number",
        description: "CustomField Registration Number",
        filterLabel: "Reg. Number",
        filterName: "regNo",
        type: "text",
      },
      {
        label: "Status",
        description: "Is customField Active?",
        filterLabel: "Status",
        filterName: "status1",
        type: "select",
        data: [
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
          { label: "Sold", value: "sold" },
        ],
      },
      {
        label: "Status",
        description: "Is CustomField in Service?",
        filterLabel: "Status",
        filterName: "status2",
        type: "select",
        data: [
          { label: "In Service", value: "inservice" },
          { label: "Assigned", value: "assigned" },
          { label: "Unassigned", value: "unassigned" },
        ],
      },
      {
        label: "GPS",
        description: "Is GPS Attatched?",
        filterLabel: "GPS",
        filterName: "gps",
        type: "select",
        data: [
          { label: "GPS Attached", value: "gspAttached" },
          { label: "GPS Not Attached", value: "gspNotAttached" },
        ],
      },
    ];
  };

  fetch = async (filterUrl) => {
    if (this.records.length > 0) return;
    if (filterUrl) {
      this.filterOption = filterUrl;
      this.downloadFilterOption = filterUrl;
    } else this.filterUrl = {};

    try {
      const response = await doGET(
        ENDPOINTS.grid(this.page, this.rows, filterUrl)
      );

      if (response.status === 200) {
        this.records = response.data.rows;
        this.totalRecords = response.data.records;
      }
    } catch (error) {
      this.error = error;
    }
  };

  edit = async function (data, id) {
    const response = await doPUT(ENDPOINTS?.edit(id), data);
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data) => {
    const response = await doPOST(ENDPOINTS.save, data);

    return response?.data?._id;
  };

  bulkDelete = async (data) => {
    const response = await doPOST(ENDPOINTS.bulkdelete, data);
    // CustomFieldSelectService.fetch();
    this.fetch();

    return response;
  };

  delete = async (id) => {
    const response = await doGET(ENDPOINTS.delete(id));
    if (response.status === 200) {
      // CustomFieldSelectService.fetch();
      this.fetch();
    }
  };

  updateZone = async (customFieldID, zoneID) => {
    const response = await doGET(ENDPOINTS.updateZone(customFieldID, zoneID));
  };

  assignDriver = async (customFieldID, driverID) => {
    try {
      const response = await doGET(
        ENDPOINTS.updateDriver(customFieldID, driverID)
      );
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

  removeDriver = async (customFieldID) => {
    try {
      const response = await doGET(ENDPOINTS.removeDriver(customFieldID));
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getCustomFieldMap = async () => {
    if (!this.customFieldMap)
      this.customFieldMap = (await doGET(ENDPOINTS.customFieldMap))?.data;
    var customFieldList = [];
    for (var i in this.customFieldMap) {
      customFieldList.push({ label: i, value: i });
    }
    return customFieldList;
  };

  getZoneMap = async () => {
    if (this.zoneMap)
      return this.zoneMap.map((zone) => ({
        label: zone.name,
        value: zone._id,
      }));
    this.zoneMap = (await doGET(ENDPOINTS.zoneMap))?.data;
    return this.zoneMap.map((zone) => ({ label: zone.name, value: zone._id }));
  };

  getCountryMap = async () => {
    if (this.countryMap)
      return this.countryMap.map((country) => ({
        label: country.name,
        value: country.name,
      }));
    this.countryMap = (await doGET(ENDPOINTS.countryMap))?.data;
    return this.countryMap.map((country) => ({
      label: country.name,
      value: country.name,
    }));
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    await this.fetch(this.filterOption);
  };

  setDownloadFilter = (filterData) => {
    if (filterData) this.downloadFilterOption = filterData;
    else this.downloadFilterOption = "";
  };

  downloadByTemplate = async (filterData, template_id) => {
    const response = await doGET(
      ENDPOINTS.download(this.downloadFilterOption, template_id)
    );

    if (response.status === 200) {
      return response;
    }
  };
}

const CustomFieldService = new Service();
export default CustomFieldService;
