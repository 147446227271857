import { observer } from 'mobx-react-lite';
import React, { forwardRef, useContext, useMemo, useState } from 'react';
import { Card, CardHeader, Col, Row, UncontrolledAccordion } from 'reactstrap';
import { MakeModelSelect } from '../../components';
import { UserContext } from '../../store/context/UserContext';
import { I18nContext } from '../../store/context/i18nContext';
import BookingService from '../Booking/BookingService';
import BillingNew from '../Booking/components/BillingNew';
import { DialogContext } from '../../store/context/DialogContext';

const BillingWrapper = forwardRef(({
  clientBilling,
  supplierBillings,
  expenses,
  updateClientBilling,
  updateSupplierBillings,
  setTrip,
  customerType,
  trip,
  billingUpdatedObject,
  fromInvoice,
  fetchBillings
}, billingRef) => {

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showMessage, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);

  const changeBillingEditState = (updatedState) => {
    BookingService.updateBillingState(updatedState)
  }

  const margin = useMemo(() => {
    if (!supplierBillings?.length) {
      return null;
    }
    return (clientBilling?.fee?.subTotal ?? 0) - (supplierBillings?.reduce((acc, billiing) => acc + (billiing?.fee?.subTotal ?? 0), 0) ?? 0)
  }, [supplierBillings, clientBilling])


  const deleteBilling = async (billing, type) => {
    setLoading(true);
    if (billing?._id) {
      try {
        await BookingService.deleteBilling(billing._id);
        fetchBillings(trip?._id);
        showMessage("Record Deleted successfully");
      } catch (error) {
        showError(error);
      }
    } else {
      const updatedClientBilling = type === "client" ? null : clientBilling;
      const updatedSupplierBillings =
        type === "supplier"
          ? supplierBillings.filter((item) => item !== billing)
          : supplierBillings;

      updateClientBilling(updatedClientBilling);
      updateSupplierBillings(updatedSupplierBillings);
    }
    setLoading(false);
  };

  const deleteSupplierBilling = async (index, e) => {
    e.preventDefault();
    if (!supplierBillings || supplierBillings.length === 0) {
      return null;
    }
    return deleteBilling(supplierBillings[index], "supplier");
  };

  const deleteClientBilling = async (e) => {
    e.preventDefault();
    return deleteBilling(clientBilling, "client");
  };

  return (
    <>
      {/* editId &&  */userContent?.rights?.includes(6005) && (
        <Row className="d-flex">
          <Col className="col-12 col-md-6">
            {BookingService.renderField("clientBilling") && (
              <UncontrolledAccordion className="mt-1" defaultOpen="1">
                <Card className="pt-0">
                  <CardHeader>
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "inherit" }}
                    >
                      <strong>{t(`Client Charges`)}  {clientBilling?.feeRate?.rateNo ? `(${clientBilling?.feeRate?.rateNo})` : ""}  </strong>

                      <span className="d-flex">
                        <span style={{ minWidth: "max-content" }} className="me-1 ">   {clientBilling?.feeRate?.name ? `${clientBilling?.feeRate?.name} |` : ""}</span>
                        <MakeModelSelect
                          asLabel
                          value={clientBilling?.feeRate?.makeModel}
                        />

                      </span>

                    </div>
                  </CardHeader>
                  <BillingNew
                    ref={(ref) => {
                      billingRef.current.clientBilling = ref;
                    }}
                    key={100}
                    expenses={expenses}
                    billingEditState={BookingService.billingEditState}
                    setBillingEditState={changeBillingEditState}
                    billing={clientBilling}
                    setBilling={updateClientBilling}
                    index={0}
                    loading={loading}
                    billingUpdatedObject={billingUpdatedObject}
                    onTripChange={(v) => {
                      setTrip((prevTrip) => ({
                        ...prevTrip,
                        tripExtn: {
                          ...prevTrip.tripExtn,
                          // requestedVehicleType: v,
                          ...v
                        },
                      }));
                    }}
                    onChange={(v) => {
                      updateClientBilling(v);
                    }}
                    type="Client"
                    customerType={customerType}
                    trip={trip}
                    fromInvoice={fromInvoice}
                    onDelete={(e) => deleteClientBilling(e)}
                    margin={margin}
                  />
                </Card>
              </UncontrolledAccordion>
            )}
          </Col>
          <Col className="col-12 col-md-6">
            {BookingService.renderField("supplierFare") && (
              <UncontrolledAccordion className="mt-1" defaultOpen="1">
                <Card className="pt-0">
                  <CardHeader>
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "inherit" }}
                    >
                      <strong>{t(`Supplier Charges`)}</strong>
                    </div>
                  </CardHeader>
                  <div>
                    {supplierBillings &&
                      supplierBillings.map((billing, index) => {
                        return (
                          <>
                            <BillingNew
                              ref={(ref) => {
                                if (!billingRef.current) billingRef.current = {};
                                if (!billingRef.current.supplierBillings) billingRef.current.supplierBillings = [];
                                billingRef.current.supplierBillings[index] = ref;
                              }}
                              trip={trip}
                              billingEditState={BookingService.billingEditState}
                              setBillingEditState={changeBillingEditState}
                              type="Supplier"
                              customerType={customerType}
                              loading={loading}
                              key={index}
                              index={index}
                              billing={billing}
                              fromInvoice={fromInvoice}
                              onDelete={(e) => {
                                deleteSupplierBilling(index, e);
                              }}
                              onChange={(newBilling) => {
                                updateSupplierBillings(prev => ([
                                  ...prev.map((sB, innerIndex) => {
                                    if (innerIndex === index) {
                                      return { ...newBilling };
                                    }
                                    return sB;
                                  })
                                ]));
                              }}
                              billingUpdatedObject={billingUpdatedObject}
                            />

                            {index != supplierBillings?.length - 1 && (
                              <hr></hr>
                            )}

                          </>
                        );
                      })}
                    <div className="d-flex justify-content-end m-2">
                      <div
                        className="btn btn-outline-dark"
                        style={{
                          padding: "3px",
                          paddingRight: "6px",
                        }}
                        onClick={() => {
                          updateSupplierBillings(prev => ([
                            ...(prev ?? []),
                            {
                              trip_id: trip?._id,
                              client_id: trip?.corp_id,
                              clientCompany_id: trip?.vendorCompany_id,
                              fee: {
                                currency: "INR",
                                feeGroups: [
                                  {
                                    name: `TRIP CHARGES`,
                                  },
                                ],
                              },
                            },
                          ]));
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <i className="bx bx-plus  font-size-18 me-1" />
                          {t("Supplier")}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </UncontrolledAccordion>
            )}
          </Col>
        </Row>
      )}
    </>
  )
})

export default observer(BillingWrapper)