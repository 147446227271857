import { Tooltip } from 'react-tooltip';
import { VehicleTypeSelect } from '../../components';
import moment from 'moment';

export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Country",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.country}
        </div>

      },
      show: true,
    },
    {
      title: "Currency",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.currency}
        </div>

      },
      show: true,
    },


    {
      title: "Travel Type",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.travelType}
        </div>
      },
      show: true,
    },

    {
      title: "For Public",
      renderer: (dataObj) => {
        return (
          <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -5 }}>
            {dataObj?.isPublic === true ? (
              <div>
                <i id="not-clickable-88-active" className="fas fa-check-circle  font-size-12 mt-1" style={{ color: 'green' }} />
              </div>
            ) : (
              <div>--</div>
            )}
          </div>
        );
      },
      show: true,
      required: true,
    },
    {
      title: "Status",
      accessor: "status",
      show: true,
      renderer: (dataObj) => {
        let statusMessage;
        let statusColor;

        if (dataObj?.status === 1) {
          statusMessage = "Created";
          statusColor = "blue";
        } else if (dataObj?.status === 2) {
          statusMessage = "Published";
          statusColor = "green";
        } else if (dataObj?.status === 3) {
          statusMessage = "Closed";
          statusColor = "orange";
        } else if (dataObj?.status === 4) {
          statusMessage = "Expired";
          statusColor = "red";
        } else {
          statusMessage = "Inactive";
          statusColor = "gray";
        }

        return <div style={{ color: statusColor }}>{statusMessage}</div>;
      },
    }

    ,

    {
      title: "Start Date",
      renderer: (dataObj) => {
        return <div>
          {(dataObj?.startDate?.toString())?.slice(6, 8)}-{(dataObj?.startDate?.toString())?.slice(4, 6)}-{(dataObj?.startDate?.toString())?.slice(0, 4)}
        </div>;
      },
      accessor: "startDate",
      show: true,
    },
    {
      title: "End Date",
      renderer: (dataObj) => {
        return <div>
          {(dataObj?.endDate?.toString())?.slice(6, 8)}-{(dataObj?.endDate?.toString())?.slice(4, 6)}-{(dataObj?.endDate?.toString())?.slice(0, 4)}
        </div>;
      },
      accessor: "endDate",
      show: true,
    },
    {
      title: "PickUp Time",
      accessor: "pickUpTime",
      renderer: (dataObj) => {
        const timeStr = dataObj?.pickUpTime?.toString();
        const formattedTime = moment(timeStr, 'HHmm').format('hh:mm A')
        return <div>
          {formattedTime}
        </div>;
      },
      show: true,
    },




    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },

  ],
};
