import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./ConfigConstant";
import { doGET, doPUT } from "../../util/HttpUtil";

class Service {

    version = 0
    constructor() {
        makeAutoObservable(this);
    }

    detail = async function (fieldName) {
        const response = await doGET(ENDPOINTS.detail(fieldName))
        if (response.status === 200) {
            this.version = response?.data?.version
            return response?.data;
        }
    };

    edit = async function (data, fieldName) {
        const response = await doPUT(ENDPOINTS?.edit(fieldName), data);
        if (response.status === 200) {
            this.version = response?.data?.version
            return response?.data;
        }
    };
}

const ConfigService = new Service();
export default ConfigService;
