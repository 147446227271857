import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./InvoiceSetupConstant";
import { doGET, doPOST } from "../../util/HttpUtil";

class Service {

    records = [];
    totalRecords = 0;
    places = [];
    page = 1;
    rows = 10;

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async function (filterUrl) {
        const response = await doGET(ENDPOINTS.grid(this.page, this.rows, filterUrl))
        if (response.status === 200) {



            this.records = response?.data;

            this.totalRecords = response?.data?.prefixes?.length;


        }

        return response
    };



    save = async function (data) {
        await doPOST(ENDPOINTS.saveNew, data)
        this.fetch();
    };



}

const InvoiceSetupService = new Service();
export default InvoiceSetupService;
