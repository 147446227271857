import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { Address, CardThreeBounce, InputField, DateSelect } from "../../components";
import TransactionService from "./TransactionService";
import { I18nContext } from "../../store/context/i18nContext";
import { DialogContext } from "../../store/context/DialogContext";


const EditTransactions = ({ editId, onChange = () => { } }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetch = async () => {
    try {
      let transactions = await TransactionService.get(editId);
      setData(transactions);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(null)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId]);

  useEffect(() => {
    onChange(data)
  }, [data]);

  return (
    <React.Fragment>
      {loading ?
        <CardThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >

          <Row className="mt-3 mb-3">
            <InputField
              placeholder="Transaction debit"
              value={data?.debit}
              required={true}
              label={"Transaction debit"}
              onChange={(v) => setData({ ...data, debit: v })}
              showError={data?.debit?.length === 0 ? "Please enter Transaction debit" : ""}
              type="text"
              className="col-sm-3"
            />
            <InputField
              placeholder="Transaction Credit"
              value={data?.credit}
              required={true}
              label={"Transaction Credit"}
              onChange={(v) => setData({ ...data, credit: v })}
              showError={data?.credit?.length === 0 ? "Please enter Transaction Credit" : ""}
              type="text"
              className="col-sm-3"
            />
            <InputField
              placeholder="Transaction Balance"
              value={data?.balance}
              required={true}
              label={"Transaction Balance"}
              onChange={(v) => setData({ ...data, balance: v })}
              showError={data?.balance?.length === 0 ? "Please enter Transaction Balance" : ""}
              type="text"
              className="col-sm-3"
            />
            <InputField
              placeholder="Transaction serialNo"
              value={data?.serialNo}
              required={true}
              label={"Transaction SerialNo"}
              onChange={(v) => setData({ ...data, serialNo: v })}
              showError={data?.credit?.length === 0 ? "Please enter Transaction SerialNo" : ""}
              type="text"
              className="col-sm-3"
            />
          </Row>
          <Row className="mt-3 mb-3">
            <InputField
              placeholder="Transaction account_id"
              value={"64d3470bc451b86648acbcef"}
              required={true}
              label={"Transaction account_id"}
              onChange={(v) => setData({ ...data, account_id: "64d3470bc451b86648acbcef" })}
              showError={data?.debit?.length === 0 ? "Please enter Transaction account_id" : ""}
              type="text"
              className="col-sm-3"
            />
            <DateSelect
              placeholder="TransactionDate"
              value={data?.transactionDate}
              required={true}
              label={"transactionDate"}
              onChange={(v) => setData({ ...data, transactionDate: v })}
              showError={data?.transactionDate?.length === 0 ? "Please enter TransactionDate" : ""}
              type="text"
              className="col-sm-3"
              timestamp
            />
          </Row>
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditTransactions);




