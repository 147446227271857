import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { InputField, SelectField, SaveButton,Checkbox } from "../../../components";

export default function CorporateConf({ data, setData }) {
  const [showErr, setshowErr] = useState(false);

  return (
    <div className="active ">
     <Row>
            <h5>Corporate Configuration</h5>
            <Checkbox
              className="col-lg"
              value={data?.enableEmpTracking}
              onChange={(v) => {
                setData({ ...data, enableEmpTracking: v });
              }}
              label={"Enable Employee Tracking"}
            />
            <Checkbox
              className="col-lg"
              value={data?.isBookingClientMovable}
              onChange={(v) => {
                setData({ ...data, isBookingClientMovable: v });
              }}
              label={"Make Booking Moveable To Other Corporate By It's Vendor"}
            />
          </Row>
          <Row>
            <Checkbox
              className="col-lg"
              value={data?.multiLogin}
              onChange={(v) => {
                setData({ ...data, multiLogin: v });
              }}
              label={"Allow Multi Login using same credentials"}
            />
            <Checkbox
              className="col-lg"
              value={data?.useZsmsGW}
              onChange={(v) => {
                setData({ ...data, useZsmsGW: v });
              }}
              label={"Use Admin SMS gateway"}
            />
          </Row>
          <Row>
            <Checkbox
              className="col-lg"
              value={data?.fakeLocations}
              onChange={(v) => {
                setData({ ...data, fakeLocations: v });
              }}
              label={"Allow to add Fake Locations"}
            />
          </Row>
    </div>
  );
}
