
import React, { useState, useEffect, useContext } from "react";
import { CitySelect, CountrySelect, InputField, StateSelect } from "../index";
import { Row } from "reactstrap";
import { I18nContext } from "../../store/context/i18nContext";



export default function Address({ data, onChange = () => { }, small, medium, large, city, state, country, addressLine, pinCode, area, lat, lng, ref_id }) {

  const [activeComponents, setActiveComponents] = useState([]);
  const { t } = useContext(I18nContext);

  const renderComponents = () => {
    return (

      < div >
        <Row className="  ">

          {activeComponents.includes("country") &&
            < CountrySelect
              className={`col-sm-${small ? 12 : (medium ? 6 : (large ? 4 : 6))}`}
              value={data?.country}
              onChange={(v) => {
                onChange({
                  ...data,
                  country: v.value,
                  state: null,
                  city: null,
                })
              }
              }
            />}
          {activeComponents.includes("state") &&
            <StateSelect
              className={`col-sm-${small ? 12 : (medium ? 6 : (large ? 4 : 6))}`}
              value={data?.state}
              onChange={(v) =>
                onChange({
                  ...data,
                  state: v.value,
                  city: null,
                })
              }
              country={data?.country}
            />}
          {activeComponents.includes("city") &&
            <CitySelect
              className={`col-sm-${small ? 12 : (medium ? 6 : (large ? 4 : 6))}`}
              value={data?.city}
              onChange={(v) =>
                onChange({
                  ...data,
                  city: v.value,
                })
              }
              country={data?.country}
              state={data?.state}
            />}


          {activeComponents.includes("pinCode") && (
            <InputField
              placeholder={t("Pin Code")}
              value={data?.pinCode}
              label={t("PinCode")}
              onChange={(v) =>
                onChange({
                  ...data,
                  pinCode: v,
                })
              }
              type="number"
              className={`col-sm-${small ? 12 : (medium ? 6 : (large ? 3 : 6))}`}

            />

          )}

        </Row>
        <Row className=" ">
          {activeComponents.includes("addressLine") && (
            <InputField
              placeholder={t("Address Line")}
              value={data?.addressLine}
              label={t("Address Line")}
              onChange={(v) =>
                onChange({
                  ...data,
                  addressLine: v,
                })
              }
              className="col-sm-12"
              type="text"
            />
          )}

          {activeComponents.includes("area") && (
            <InputField
              placeholder={t("Area")}
              value={data?.area}
              label={t("Area")}
              onChange={(v) =>
                onChange({
                  ...data,
                  area: v,
                })
              }
              type="text"
              className={`col-sm-${small ? 12 : (medium ? 6 : (large ? 3 : 6))}`}
            />

          )}
          {activeComponents.includes("lat") && (
            <InputField
              placeholder={t("Latitude")}
              value={data?.lat}
              label={t("Latitude")}
              onChange={(v) =>
                onChange({
                  ...data,
                  lat: v,
                })
              }
              type="text"
              className={`col-sm-${small ? 12 : (medium ? 6 : (large ? 3 : 6))}`}
            />
          )}
          {activeComponents.includes("lng") && (
            <InputField
              placeholder={t("Longitude")}
              value={data?.lng}
              className={`col-sm-${small ? 12 : (medium ? 6 : (large ? 3 : 6))}`}
              label={t("Longitude")}
              onChange={(v) =>
                onChange({
                  ...data,
                  lng: v,
                })
              }
              type="text"
            />

          )}
        </Row>


      </div >
    );
  };

  const giveActiveComponents = () => {
    const componentNames = {
      city,
      state,
      country,
      addressLine,
      pinCode,
      area,
      lat,
      lng,
      ref_id,

    };

    const newActiveComponents = Object.keys(componentNames)
      .filter((key) => componentNames[key])
      .map((key) => key);
    if (newActiveComponents?.length === 0) {
      setActiveComponents(["city", "state", "country", "addressLine", "pinCode", "area", "lat", "lng", "ref_id"])
    }
    else {
      setActiveComponents(newActiveComponents);
    }
  };


  useEffect(() => {
    giveActiveComponents();
  }, []);



  return (
    <div className="active">
      {renderComponents()}
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import { Row } from "reactstrap";
// import { CitySelect, CountrySelect, InputField, StateSelect } from "../index";

// export default function Address({ data, onChange = () => { }, showCSC, noLabel }) {
//   return (
//     <div className="active ">
//       <Row className="px-3">
//         {!noLabel && <div className="p-0">Address</div>}

//         {/* <div className="p-0">Address</div> */}
//         <hr />
//       </Row>
//       <Row className=" mb-3 ">
//         {!showCSC && <InputField
//           placeholder="Address Name"
//           className="col-sm-3"
//           value={data?.name}
//           label={"Address Name"}
//           onChange={(v) =>
//             onChange({
//               ...data,
//               name: v,
//             })
//           }
//           type="text"
//         />}
//         <CountrySelect
//           className="col-sm-3"
//           value={data?.country}
//           onChange={(v) =>
//             onChange({
//               ...data,
//               country: v.value,
//               state: null,
//               city: null,
//             })
//           }
//         />

//         <StateSelect
//           className="col-sm-3"
//           value={data?.state}
//           onChange={(v) =>
//             onChange({
//               ...data,
//               state: v.value,
//               city: null,
//             })
//           }
//           country={data?.country}
//         />

//         <CitySelect
//           className="col-sm-3 "
//           value={data?.city}
//           onChange={(v) =>
//             onChange({
//               ...data,
//               city: v.value,
//             })
//           }
//           country={data?.country}
//           state={data?.state}
//         />
//       </Row>

//       {!showCSC && <Row className="mt-3 mb-3 ">
//         <InputField
//           placeholder="Pin Code"
//           className="col-sm-1"
//           value={data?.pinCode}
//           label={"Pin Code"}
//           onChange={(v) =>
//             onChange({
//               ...data,
//               pinCode: v,
//             })
//           }
//           type="text"
//         />
//         <InputField
//           placeholder="Area"
//           className="col-sm-2"
//           value={data?.area}
//           label={"Area"}
//           onChange={(v) =>
//             onChange({
//               ...data,
//               area: v,
//             })
//           }
//           type="text"
//         />

//         <InputField
//           placeholder="Latitude"
//           className="col-sm-2"
//           value={data?.lat}
//           label={"Latitude"}
//           onChange={(v) =>
//             onChange({
//               ...data,
//               lat: v,
//             })
//           }
//           type="text"
//         />
//         <InputField
//           placeholder="Longitude"
//           className="col-sm-2"
//           value={data?.lon}
//           label={"Longitude"}
//           onChange={(v) =>
//             onChange({
//               ...data,
//               lon: v,
//             })
//           }
//           type="text"
//         />

//         <InputField
//           placeholder="Address Line"
//           className="col-sm-5 mt-sm-0 mt-3"
//           value={data?.addressLine}
//           label={"Address Line"}
//           onChange={(v) =>
//             onChange({
//               ...data,
//               addressLine: v,
//             })
//           }
//           type="text"
//         />
//       </Row>}
//     </div>
//   );
// }
