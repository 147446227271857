import React, { useContext, useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { I18nContext } from "../../../store/context/i18nContext";
import { useNavigate } from "react-router-dom";
import EditLink from "../../Buttons/Icons/editButton";
import DeleteLink from "../../Buttons/Icons/deleteButton";
import { DialogContext } from "../../../store/context/DialogContext";
import AddButton from "../../Buttons/AddButton";
import { Row, Col } from "reactstrap";
import { InputField } from "../../InputField/InputField";
import SaveButton from "../../Buttons/SaveButton";
import { SelectField } from "../../SelectField/SelectField";
import RateSelectService from "./RateSelectService";

const Rates = (props) => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [rates, setRates] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasErr, setHasErr] = useState(false);
  const [data, setData] = useState({});
  let navigate = useNavigate();
  const fetchData = () => {
    fetch("/api/tariff/names/all")
      .then((response) => response.json())
      .then((data) => {
        setRates(data);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    fetchData();
  }, []);
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete Rate?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        const response = await RateSelectService.delete(id);
        showMessage("Rate deleted successfully");
      } catch (e) {
        showError(e);
      }
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setHasErr(true);
    setLoading(true);
    try {
      const response = await RateSelectService.save(data);
      RateSelectService.rates.push(data);
      showMessage("Rate Saved Successfully");
      fetchData();
    } catch (e) {
      showError(e);
    }
  };

  return (
    <div>
      <AddButton
        onClick={() => {
          setData({});
          setToggle(true);
        }}
      />
      {toggle ? (
        <div>
          <Row className="mt-4 ml-4">
            <InputField
              value={data?.name}
              className="col-sm-4"
              label={"Name"}
              onChange={(v) => setData({ ...data, name: v })}
            />
            <Col className=" d-flex justify-content-center">
              <SaveButton onClick={submitHandler} />
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
      <Layout>
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>{t("Name")}</Th>
              <Th className=" float-start">{t("Action")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {rates.map((name, id) => (
              <Tr key={id}>
                <Td scope="row">{name?.name}</Td>
                <Td className="float-start">
                  <EditLink
                    onClick={() => {
                      setToggle(true);
                      setData(rates[id]);
                    }}
                  />
                  <DeleteLink
                    onClick={(event) => {
                      onDelete(event, name?._id);
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout>
    </div>
  );
};
export default Rates;
