import { observer } from "mobx-react-lite";

import React, { useContext, useEffect, useState } from "react";

import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Button, Col, Row } from "reactstrap";

import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  Checkbox,
  CityGroupSelect,
  CountrySelect,
  DeleteLink,
  InputField,
  Layout,
} from "../../../../components";
import "../../../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../../../store/context/DialogContext";
import { I18nContext } from "../../../../store/context/i18nContext";

import JourneyGapService from "./JourneyGapService";

const JourneyGap = (props) => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [modal, setModal] = useState(false);
  const [journeypap, setjourneypap] = useState([]);

  const { showMessage } = useContext(DialogContext);
  const [hasErr, setHasErr] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      data.journeyGaps = journeypap;

      setData(() => {
        return {
          ...data,
        };
      });

      await JourneyGapService.save(data);

      showMessage("JourneyGap saved successfully");
      setLoading(false);
    } catch (e) {
      showError(e)
      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await JourneyGapService.get();
      setData(response);
      setjourneypap(response?.journeyGaps);
      setHasErr(false);
      setLoading(false);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    fetchData();
    setHasErr(false);
  }, []);

  const onDelete = async (event, idx) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete Journey Gap ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        journeypap.splice(idx, 1);

        setjourneypap([...journeypap]);
      } catch (e) {
        showError(e);
      }
    }
  };

  if (loading) return <CardThreeBounce />;
  return (
    <>
      <Layout
         
        title={t("Journey Gap")}
        onAddClick={() => setjourneypap([...journeypap, {}])}
      >
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>{t("Booking to Journey Time Gap Duration (minutes)")}</Th>
              <Th>{t("Country")}</Th>

              <Th>{t("City/City Group")}</Th>
              <Th>{t("Default")}</Th>
              <Th>{t("Action")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {journeypap?.map((j, idx) => (
              <Tr key={idx}>
                <Td>
                  {" "}
                  <Row className="mt-2 mb-3">
                    <Col className="col-lg">
                      <InputField
                        identifier={idx}
                        placeholder="In Minutes"
                        value={j?.journeyGapTime}
                        label={"In Minutes"}
                        onChange={(v) =>
                          setjourneypap(
                            journeypap.map((f, x) => {
                              if (idx === x)
                                return {
                                  ...j,
                                  journeyGapTime: v,
                                };
                              return f;
                            })
                          )
                        }

                        type="number"
                      />
                    </Col>
                  </Row>
                </Td>
                <Td>
                  <Row>
                    <Col className="col-lg">
                      <CountrySelect
                        value={j?.country}
                        onChange={(v) =>
                          setjourneypap(
                            journeypap.map((f, x) => {
                              if (idx === x)
                                return {
                                  ...j,
                                  country: v.value,
                                };
                              return f;
                            })
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Td>
                <Td>
                  <Row>
                    <Col className="col-lg">
                      <CityGroupSelect
                        showErr={hasErr}
                        required={true}
                        value={j?.cityGroup}
                        maxWidth={"653px"}
                        onChange={(v) =>
                          setjourneypap(
                            journeypap.map((f, x) => {
                              if (idx === x)
                                return {
                                  ...j,
                                  cityGroup: v,
                                };
                              return f;
                            })
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Td>

                <Td>
                  <Row>
                    <Col className="col-lg">
                      <Checkbox
                        label="Default"
                        checked={j?.isDefault}
                        onClick={(v) =>
                          setjourneypap(
                            journeypap.map((f, x) => {
                              if (idx === x)
                                return {
                                  ...j,
                                  isDefault: !j?.isDefault,
                                };
                              return f;
                            })
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Td>

                <Td>
                  <DeleteLink
                    onClick={(event) => {
                      onDelete(event, idx);
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout>

      <div className="d-flex justify-content-end  ">
        <div className="me-2">
          <Button type="submit" color="primary" onClick={submitHandler}>
            Save
          </Button>{" "}
        </div>
      </div>
    </>
  );
};

export default observer(JourneyGap);
