export const ENDPOINTS = {
  grid: () => {
    let url = `/api/company/grid?`;
    return url;
  },
  filter: (page, sizePerPage, filterUrl) => {
    let url = ``;
    url += `rows=${sizePerPage ?? -1}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/company/${id}/detail`,
  save: `/api/company/create`,
  // delete: (id) => `/api/company/${id}/delete`,
  delete: (id, corp_id) => `/api/company/${id}/delete${corp_id ? `?corp_id=${corp_id}` : ""}`,
  bulkDelete: (id) => `/api/company/delete`,
  update: `/api/company/update`,

};

export const STRUCTURE = [
  {
    label: "Client Name",
    filterLabel: "Client Name",
    filterName: "clientName",
    type: "text",
  },
  {
    label: "Company Name",
    filterLabel: " Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Full Name",
    filterLabel: "Full Name",
    filterName: "fullName",
    type: "text",
  },
  {
    label: "Currency",
    filterLabel: "Company currency",
    filterName: "currency",
    type: "currencySelect",
  },
  {
    label: "TimeZone",
    filterLabel: "Company TimeZone",
    filterName: "timezone",
    type: "timezoneSelect",
  },
];
