import React, { useState, useEffect, useRef } from 'react';
import "./styles.css";

function AmountField({ defaultForm=false,outline, innerStyle, value = 0, onChange, className, style, label, disabled, required, scaleFactor = 100, rightText = "", asLabel = false, ...props }) {
    const [displayValue, setDisplayValue] = useState('');
    const [isFocused, setIsFocused] = useState(true);
    const inputRef = useRef(null);
    const { RightComponent, onClickRight } = props;

    useEffect(() => {
        setDisplayValue((value / scaleFactor).toFixed(2));
    }, [value, scaleFactor]);

    const handleFocus = () => {
        setIsFocused(true);
        setTimeout(() => {
            inputRef.current.select(); // Select all text
        }, 0);
    };

    const handleBlur = () => {
        let formattedValue = parseFloat(displayValue);
        if (!isNaN(formattedValue)) {
            formattedValue = formattedValue.toFixed(2);
        } else {
            formattedValue = '0.00';
        }
        setDisplayValue(formattedValue);
        onChange(Math.round(parseFloat(formattedValue) * scaleFactor));
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        if (!isNaN(inputValue) || inputValue === '') {
            setDisplayValue(inputValue);
        }
    };

    const handleClick = () => {
        setIsFocused(true);
        inputRef.current.focus();
    };

    const renderIntegerPart = () => {
        if (!isFocused) {
            return (
                <span style={{ fontSize: "12px", color: "black" }}>
                    {displayValue.split(".")[0]}.
                </span>
            );
        } else {
            return null;
        }
    };

    const renderDecimalPart = () => {
        if (!isFocused) {
            return (
                <span className="" style={{ fontSize: "11px", color: "#000", marginBottom: "0px" }}>
                    {displayValue.split('.')[1] || '00'}
                </span>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            {asLabel ? (
                <div>{displayValue ?? <div style={{ color: "grey" }}>{displayValue}</div>} {rightText}</div>
            ) : (
                <section
                    style={{ ...style }}
                    className={`flex-grow-1 inputfield__container ${className}`}
                >
                  
                    <div
                        style={{ height: "36px", ...(innerStyle ?? {}) }}
                        className={`inputfield ${outline ? `inputfield__innercontainer_outline` : "inputfield__innercontainer"} ${isFocused ? "" : " align-items-end pb-2"} ps-2`}
                    >
                        {outline && label && (
                                <label
                                    onClick={() => {
                                        inputRef.current.focus();
                                    }}
                                    className="input_field_focused_label px-1"
                                >
                                    {label}
                                    {required && <font color="#EC734B">*</font>}
                                </label>
                        )}
                        {defaultForm &&<span className='font-size-13 w-100'>{label ?? "Amount"}</span>}
                        <input
                            ref={inputRef}
                            disabled={disabled}
                            value={displayValue}
                            label={label ?? "Amount"}
                            className={`inputfield__input`}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            type="text"
                            style={{ textAlign: "right", fontSize: isFocused ? "12px" : "0px", paddingRight: "0px" }}
                        />
                        <div className="position-absolute" style={{
                            position: "absolute",
                            right: "10px"
                        }} onClick={handleClick}>
                            {renderIntegerPart()}
                            {renderDecimalPart()}
                        </div>
                        {RightComponent && (
                            <div
                                onClick={(e) => (onClickRight ? onClickRight(e) : null)}
                                style={{ height: "32px" }}
                                className={
                                    onClickRight
                                        ? "component_pointer inputfield__rightComponent"
                                        : "inputfield__rightComponent"
                                }
                            >
                                <props.RightComponent {...props} />
                            </div>
                        )}
                    </div>
                </section>
            )}
        </>
    );
}

export default AmountField;
