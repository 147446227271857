import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Row, Card, Col } from "reactstrap";
import {
  DateSelect,
  EmployeeSelect,
  SaveButton,
  TimeSelect,
} from "../../../components";
import { I18nContext } from "../../../store/context/i18nContext";
import TimesheetService from "../../Timesheet/TimesheetService";
import { DialogContext } from "../../../store/context/DialogContext";
import { UserContext } from "../../../store/context/UserContext";
import { MdAdd } from "react-icons/md";

const Timesheet = ({ editId, data }) => {
  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);

  const [driverDuties, setDriverDuties] = useState([]);
  const [timesheet, setTimeSheet] = useState({
    driverDuties: [{}],
  });
  const [present, setPresent] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showError, showMessage } = useContext(DialogContext);
  const fetchData = async () => {
    try {
      let timesheet = await data?.find((item) => item.vehicle_id === editId);
      if (timesheet) {
        setTimeSheet(timesheet);
        setPresent(true);
      }
      setDriverDuties(timesheet?.driverDuties ? timesheet?.driverDuties : [{}]);
    } catch (e) {
      showError(e);
    }
  };

  const sortDataByDateAndTime = (data) => {
    data.sort((a, b) => {
      if (a.startDate !== b.startDate) {
        return a.startDate - b.startDate;
      } else {
        return a.start - b.start;
      }
    });
    return data;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let noDutyAssigned = false;
      let dateCheck = false;
      let timeCheck = false;
      timesheet?.driverDuties.map((duty) => {
        if (!duty?.employee_id) {
          noDutyAssigned = true;
          return;
        }
        if (duty?.endDate && duty?.startDate && duty?.startDate > duty?.endDate) {
          dateCheck = true;
          return;
        }
        if (duty?.startDate == duty?.endDate && duty?.start > duty?.end) {
          timeCheck = true;
          return;
        }
      })
      if (noDutyAssigned) {
        showError(t("Please Select Employee"));
        setLoading(false);
        return
      }

      if (dateCheck) {
        showError(t("End date must be greater than Start date"));
        setLoading(false);
        return
      }
      if (timeCheck) {
        showError(t("End time must be greater than Start time on same day"));
        setLoading(false);
        return
      }

      const newDriverDutiesData = sortDataByDateAndTime(timesheet?.driverDuties);

      setTimeSheet({
        ...timesheet,
        driverDuties: newDriverDutiesData
      })

      if (present) {
        const res = await TimesheetService.editByVehicle(timesheet, timesheet?.id);
        setTimeSheet(res);
        showMessage(t("Timesheet updated successfully."));
        setLoading(false);
      } else {
        await TimesheetService.save(timesheet);
        showMessage(t("Timesheet Saved successfully."));
        setLoading(false);

      }
    } catch {
      showError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeSheet(prev => ({
      ...prev,
      vehicle_id: editId,
    }));
    fetchData();
  }, [editId, TimesheetService?.version]);

  useEffect(() => {
    setTimeSheet(prev => ({ ...prev, driverDuties: driverDuties }));
  }, [driverDuties]);
  return (
    <div className="active container">
      <Row className="d-flex justify-content-end"></Row>
      <Row>
        <Col className="col-sm-12 col-md-12 col-lg-12">
          {driverDuties?.map((ele, i) => (
            <div className="d-flex">

              <div className="d-flex flex-1 flex-row gap-3 pe-0 me-0">
                <EmployeeSelect
                  label={"Driver Select"}
                  outline
                  required
                  value={ele?.employee_id}
                  onChange={(v) => {
                    setDriverDuties((prevDuties) =>
                      prevDuties.map((f, x) => (i === x ? { ...ele, employee_id: v?.value } : f))
                    );
                  }}
                  style={{ minWidth: "200px" }}
                  className=""
                />
                <DateSelect
                  outline
                  value={ele?.startDate}
                  label="Start Date"
                  className="col-sm-2"
                  onChange={(v) => {
                    setDriverDuties((prevDuties) =>
                      prevDuties.map((f, x) => (i === x ? { ...ele, startDate: v } : f))
                    );
                  }}
                />
                <TimeSelect
                  outline
                  className="col-sm-2"
                  value={ele?.start}
                  label="Start"
                  onChange={(v) => {
                    setDriverDuties((prevDuties) =>
                      prevDuties.map((f, x) => (i === x ? { ...ele, start: Number(v) } : f))
                    );
                  }}
                />
                <DateSelect
                  outline
                  label="End Date"
                  className="col-sm-2"
                  value={ele?.endDate ?? ""}
                  onChange={(v) => {
                    setDriverDuties((prevDuties) =>
                      prevDuties.map((f, x) => (i === x ? { ...ele, endDate: v } : f))
                    );
                  }}
                />
                <TimeSelect
                  outline
                  value={ele?.end}
                  className="col-sm-2 me-0 "
                  label="End"
                  onChange={(v) => {
                    setDriverDuties((prevDuties) =>
                      prevDuties.map((f, x) => (i === x ? { ...ele, end: Number(v) } : f))
                    );
                  }}
                />
                <hr className="mt-2" />
              </div>

              <div className="d-flex align-items-center  justify-content-start">
                <Col
                  onClick={() => {
                    setDriverDuties((prevDuties) => {
                      let newData = [...prevDuties];
                      newData.splice(i, 1);
                      return newData;
                    });
                  }}
                  className="col-sm-1"
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    marginLeft: "5px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="bx color-blue font-size-20 bxs-x-circle  d-flex justify-content-start  p-0"
                    style={{
                      position: "absolute",
                      right: 0,
                      fontSize: "40px",
                      marginRight: "70px",
                    }}
                  />
                </Col>
                <Col
                  className={`col-1 ${i === driverDuties?.length - 1 ? 'btn btn-outline-dark' : ''} d-flex align-items-center  me-3`}
                  style={{
                    padding: "0px 0px 0px 5px",
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                  }}
                  onClick={() => {
                    setDriverDuties([...driverDuties, {}]);
                  }}
                >
                  {i === driverDuties?.length - 1 &&
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      fontSize="18"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.75 4.5a.75.75 0 0 1 .75.75V11h5.75a.75.75 0 0 1 0 1.5H12.5v5.75a.75.75 0 0 1-1.5 0V12.5H5.25a.75.75 0 0 1 0-1.5H11V5.25a.75.75 0 0 1 .75-.75Z"></path>
                    </svg>
                  }
                </Col>
              </div>
            </div>
          ))}
        </Col>
      </Row>
      <Row>
        {userContent?.rights?.includes(2602) ? (
          <SaveButton loading={loading} onClick={submitHandler} />
        ) : null}
      </Row>
    </div >
  );
};

export default observer(Timesheet);
