import { ThreeBounce } from 'better-react-spinkit';
import React from 'react';
import { Button } from 'reactstrap'; // Make sure to import Button from your UI library

export default function PrimaryButton({children , isDisabled, className, onClick, loading, title,style }) {

  const nextButtonStyle = {
    height: "35px",
    width : "max-content",
    color : "#fff",
    padding: "1px 10px 1px 10px",
    fontSize: "13px",
    borderRadius: "5px"
  }


  if (loading) {
    return (
      <Button
        style={{...nextButtonStyle,...style}}
        variant="primary"
        disabled
        className={className}
      >
        <ThreeBounce size={10} color="#FFFFFF" />
      </Button>

    );
  }
  return (
    <Button
      style={{...nextButtonStyle,...style}}
      variant="primary"
      disabled={isDisabled}
      onClick={onClick}
      className={className}

    >
      {children}
    </Button>
  );
}
