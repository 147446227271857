export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = ` /api/invoice/corp/subscription/grid?size=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  print: "/api/invoice/bulk/invoices/print2",
};

export const STRUCTURE = [
  {
    label: "From Date",
    description: "description...",
    filterLabel: "From Date",
    filterName: "fromDate",
    type: "dateSelect",
  },
  {
    label: "To Date",
    description: "description...",
    filterLabel: "To Date",
    filterName: "toDate",
    type: "dateSelect",
  },
  {
    label: "Financial Year",
    description: "description...",
    filterLabel: "Financial Year",
    filterName: "financialYear",
    type: "select",
    data: [
      { label: "2017-18", value: "20170401" },
      { label: "2018-19", value: "20180401" },
      { label: "2019-20", value: "20190401" },
      { label: "2020-21", value: "20200401" },
      { label: "2021-22", value: "20210401" },
      { label: "2022-23", value: "20220401" },
      { label: "2023-24", value: "20230401" },
      { label: "2024-25", value: "20240401" },
      { label: "2025-26", value: "20250401" },
    ],
  },
  {
    label: "Invoice No.",
    description: "description...",
    filterLabel: "Invoice No.",
    filterName: "invoiceNo",
    type: "text",
  },
];
