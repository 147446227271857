import moment from "moment";
import { OfficeSelect } from "../../components";

export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Office",
      accessor: "office_id",
      renderer: (dataObj) => {
        return (
          <div>
            <OfficeSelect
              asLabel
              value={dataObj?.office_id}
              className=""
            />
          </div>
        );
      },
      show: true,
    },

    {
      title: "Status",
      accessor: "status",
      renderer: (dataObj) => {
        return (
          <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -5 }}>
            <div
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor:
                  dataObj?.status === 0 ? 'yellow' : dataObj?.status == 1 ? 'green' : dataObj?.status == 2 ? 'blue' : 'red',
                marginRight: '5px',

              }}
            ></div>
            <span>
              {dataObj?.status === 0
                ? "Created"
                : dataObj?.status === 1
                  ? "Approved"
                  : dataObj?.status === 2
                    ? "Assigned"
                    : "Rejected"}
            </span>
          </div>
        );

      },
      show: true,
      required: true,
      // width: 200,
    },
    {
      title: "Returning",
      accessor: "returning",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.returning == true ? (
              <i
                className="mdi mdi-checkbox-marked font-size-20 mx-2"
                style={{ zIndex: "999" }}
              />
            ) : (
              <i
                className="mdi mdi-checkbox-blank-outline font-size-20 mx-2"
                style={{ zIndex: "999" }}
              />
            )}
          </div>
        );
      },
      show: true,
      // width: 200,

    },
    {
      title: "Start Time",
      accessor: "startTime",
      renderer: (dataObj) => {
        if (dataObj.startTime) {
          const timestamp = dataObj?.startTime;
          const date = moment.unix(timestamp);
          const formattedDateTime = date.format('DD-MM-YYYY, hh:mm A');

          return (
            <div>
              {formattedDateTime}
            </div>
          );
        } else {
          return null;
        }
      },
      show: true,
    }
    ,

    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
