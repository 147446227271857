import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import QuoteItem from './item';
import Title from './title';
import "./styles.scss"

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) {
    return '#FFEBE6';
  }
  if (isDraggingFrom) {
    return '#E6FCFF';
  }
  return '#EBECF0';
};

const InnerQuoteList = React.memo(function InnerQuoteList(props) {
  return props.quotes.map((quote, index) => (
    <Draggable key={quote.id} draggableId={quote.id} index={index}>
      {(dragProvided, dragSnapshot) => (
        <QuoteItem
          isHorizontal={props.horizonatalListView}
          key={quote.id}
          quote={quote}
          isDragging={dragSnapshot.isDragging}
          isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
          provided={dragProvided}
        />
      )}
    </Draggable>
  ));
});

function InnerList(props) {
  const { quotes, dropProvided, horizonatalListView } = props;
  const title = props.title ? <Title>{props.title}</Title> : null;
  return (
    <div>
      {title}
      <div className={`list-${horizonatalListView ? "hz-" : ""}dropzone`} ref={dropProvided.innerRef}>
        <InnerQuoteList horizonatalListView={horizonatalListView} quotes={quotes} />
        {dropProvided.placeholder}
      </div>
    </div>
  );
}

export default function QuoteList(props) {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId = 'LIST',
    listType,
    style,
    quotes,
    listRef,
    title,
    useClone,
    horizonatalListView,
    hide
  } = props;


if (hide) return <></>

  return (

    <Droppable
      droppableId={listId}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
      renderClone={
        useClone
          ? (provided, snapshot, descriptor) => (
            <QuoteItem
              isHorizontal={horizonatalListView}
              quote={quotes[descriptor.source.index]}
              provided={provided}
              isDragging={snapshot.isDragging}
              isClone
            />
          )
          : null
      }
    >
      {(dropProvided, dropSnapshot) => (
        <div
          className={`list-${horizonatalListView ? "hz-" : ""}wrapper`}
          style={style}
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDropDisabled={isDropDisabled}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <div ref={listRef} className={`list-${horizonatalListView ? "hz-" : ""}scroll-container`} style={scrollContainerStyle}>
              <InnerList horizonatalListView={horizonatalListView} quotes={quotes} title={title} dropProvided={dropProvided} />
            </div>
          ) : (
            <InnerList horizonatalListView={horizonatalListView} quotes={quotes} title={title} dropProvided={dropProvided} />
          )}
        </div>
      )}
    </Droppable>
  );
}
