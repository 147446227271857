import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useContext } from "react";
import { Form, Row } from "reactstrap";
import { CardThreeBounce, InputField, SalutationSelect, ClientSelect, SupplierSelect, CompanySelect, PhoneNum, TagSelector, NameField } from "../../components";
import ContactPersonService from "./ContactPersonService.js";
import { I18nContext } from "../../store/context/i18nContext";
import { DialogContext } from "../../store/context/DialogContext";

const EditContactPersons = ({ editId, onChange = () => { }, hasErr }) => {
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [step, setStep] = useState(1);


    const fetch = async () => {
        try {
            let contactPersons = await ContactPersonService.get(editId);
            setData(contactPersons);
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        setData({
            salutation: "Mr"
        })
        setLoading(true);
        if (editId) fetch();
        setLoading(false)
    }, [editId, ContactPersonService?.version]);

    useEffect(() => {
        onChange(data)
    }, [data]);

    return (
        <React.Fragment>
            {loading ?
                <CardThreeBounce /> :
                <Form
                    className="p-3"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                >

                    <Row className="mt-3 mb-3">
                        <ClientSelect
                            value={data?.clients}
                            onChange={(v) => setData({ ...data, clients: v })}
                            multi
                            className="col-sm-4"
                        />
                        <SupplierSelect
                            value={data?.suppliers}
                            multi
                            onChange={(v) => setData({ ...data, suppliers: v })}
                            className="col-sm-4"
                        />
                        <CompanySelect
                            value={data?.entities}
                            corpType="self"
                            multi
                            onChange={(v) => setData({ ...data, entities: v })}
                            className="col-sm-4"
                        />


                    </Row>
                    <Row className="mt-3 mb-3">
                        <NameField
                            onNameChange={(v) => setData({ ...data, name: v })}
                            onSalutationChange={(v) => setData({ ...data, salutation: v })}
                            name={data?.name}
                            salutation={data?.salutation}
                            label={t("Name")}
                            required={true}
                            placeholder={t("Name")}
                            className="col-sm-4"
                        />
                        <InputField
                            placeholder={t("Email")}
                            value={data?.email}
                            label={t("Email")}
                            onChange={(v) => setData({ ...data, email: v })}
                            error={data?.email?.length === 0 ? t("Please enter email") : ""}
                            showErr={hasErr}
                            type="email"
                            className="col-sm-4"
                        />
                        <PhoneNum
                            value={data?.phone}
                            className="col-sm-4"
                            error={data?.phone?.length === 0 ? t("Please enter Phone") : ""}
                            showErr={hasErr}
                            onChange={(v) =>
                                setData({
                                    ...data,
                                    phone: {
                                        ...data?.phone,
                                        countryCode: v?.countryCode,
                                        phone: v?.phone,
                                    },
                                })
                            }
                        />
                    </Row>
                    <Row className="mt-3 mb-3">
                        <InputField
                            placeholder={t("Designation")}
                            value={data?.designation}
                            label={t("Designation")}
                            onChange={(v) => setData({ ...data, designation: v })}
                            error={data?.designation?.length === 0 ? t("Please enter ContactPerson designation") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-4"
                        />
                    </Row>
                    {/* <Row className="mb-3">
                        <TagSelector
                            kind={"contact-person"}
                            onChange={(v) => {
                                setData({ ...data, tags: v })
                            }}
                            value={data?.tags}
                        // onlyBanners
                        />
                    </Row> */}
                </Form>
            }
        </React.Fragment>
    );
};
export default observer(EditContactPersons);
