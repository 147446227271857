import React from 'react';
import './CustomTooltip.scss';

const CustomTooltip = ({ children }) => {
    return <div className="CustomTooltip">{children}</div>;
};

// CustomTooltip.Body component for the visible part
CustomTooltip.Body = ({ children, style = {} }) => {
    return <div style={style} className="CustomTooltip__Body">{children}</div>;
};


CustomTooltip.ToolTip = ({ children, position }) => {
    // Position class will be determined based on the passed position prop
    const positionClass = `CustomTooltip__ToolTip--${position}`;
    return <div className={`CustomTooltip__ToolTip ${positionClass}`}>{children}</div>;
};


export default CustomTooltip;
