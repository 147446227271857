import { ThreeBounce } from 'better-react-spinkit'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Card, CardBody, Col, Form, Row, UncontrolledAccordion } from 'reactstrap'
import { AddressField, Checkbox, ClientSelect, CompanySelect, CustomFieldComponent, Documents, InputField, ToggleSelector } from '../../components'
import BookingFooter from '../../components/BookingNew/BookingFooter'
import EditBookingHeader from '../../components/BookingNew/EditBookingHeader'
import DateTimePickerWithInterval from '../../components/DateTimePickerWithInterval'
import { formatSecondsToDuration } from '../../helpers/dateHelper'
import useBookingManagement from '../../hooks/useBookingManagement'
import { DialogContext } from '../../store/context/DialogContext'
import { I18nContext } from '../../store/context/i18nContext'
import useDebounce from '../../util/Debounce'
import { doGET } from '../../util/HttpUtil'
import BookedBy from '../Booking/BookedBy'
import { SELECTSTRUCTURE, TRIP_CHARGES } from '../Booking/BookingConstant'
import BookingService from '../Booking/BookingService'
import PassengerSelect from '../Booking/PassengerSelect'
import Passengers from '../Booking/Passengers'
import Pictures from '../Booking/Pictures'
import TripExpense from '../Booking/TripExpense'
import AssignVehicle from '../Booking/components/AssignVehicleDriver'
import TripTypeDropdown from '../Booking/components/TripTypeDropdown'
import GarageService from '../Garage/GarageService'
import BillingWrapper from './BillingWrapper'
import { getDefaultClientBilling } from './utils/helper'
import DutyClosePopup from '../Booking/DutyClosePopup'
import { UserContext } from '../../store/context/UserContext'
import { getFullAddress } from '../../util/Util'
import { BiSolidCarGarage } from "react-icons/bi";

const EditBooking = ({
  editID,
  defaultCustomerType = "walkIn",
  postSave = () => { },//to update data in grid
  handleNavigation = () => { },
  fromInvoice = false,
  space_id,
  postDutyClose = () => { },
  prefetchValue,
  onChange=null
}) => {
  const [trip, setTrip] = useState(null);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext);
  const [loading, setLoading] = useState(false)
  const [expenses, setExpenses] = useState([]);
  const [clientBilling, setClientBilling] = useState(null);//single object
  const [supplierBillings, setSupplierBillings] = useState([]);//array
  const { onSave, loading: bookingLoading, closeDuty, onDelete } = useBookingManagement();
  const [billingUpdatedObject, setBillingUpdatedObject] = useState(null);
  const [customerType, setCustomerType] = useState(defaultCustomerType);
  const queryParams = new URLSearchParams(window.location.search);
  const isCloning = useMemo(() => queryParams.has('clone_trip_id'), [queryParams]);
  const [dutyCloseModal, setDutyCloseModal] = useState(false);
  const { userContent } = useContext(UserContext);

  const billingRef = useRef({
    clientBilling: null,
    supplierBillings: []
  });

  const fetchBillings = async (editID) => {
    try {
      const { clientBilling: cB, supplierBillings: sBArray } = await BookingService.fetchTripBillings(editID)
      setClientBilling(cB ?? getDefaultClientBilling(editID))
      setSupplierBillings(sBArray ?? [])
    } catch (error) {
      showError(error)
    }
  }

  const fetchTripDetail = async (editID) => {
    try {
      setLoading(true);
      const response = await BookingService.get(editID)
      if (response) {
        setTrip(response)
        onChange && onChange(response)
        if ((response?._id)) {
          setCustomerType((response?.client) ? "forClient" : "walkIn")
        }
      }
    } catch (error) {
      showError(error)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    BookingService.getFieldAccessibilityList();
  }, []);
  useEffect(() => {
    return () => {
      BookingService.updateBillingState({
        supplier: -1,
        client: false
      });

    }
  }, [])

  useEffect(() => {
    if (editID) {
      fetchTripDetail(editID);
      fetchBillings(editID);
    } else {
      setTrip(prev => ({
        ...(prev || {}),
        ...(prefetchValue ?? {}),
        tripType: 1,
        tripExtn: { ...prev?.tripExtn, otpNotRequired: true },
      }));
      setClientBilling({
        fee: {
          feeGroups: [{
            name: TRIP_CHARGES,
          }]
        }
      });
      BookingService.updateBillingState({
        supplier: -1,
        client: true,
      });
    }
  }, [editID, prefetchValue]);


  useEffect(() => {
    setBillingUpdatedObject({
      distance: trip?.tripExtn?.distance,
      startTime: trip?.startTime,
      duration: trip?.duration,
      preStartKMs: trip?.tripExtn?.preStartKMs,
      postStopKMs: trip?.tripExtn?.postStopKMs,
      expenses: expenses,
    });
  }, [
    trip?.tripExtn?.distance,
    trip?.startTime,
    trip?.duration,
    trip?.tripExtn?.preStartKMs,
    trip?.tripExtn?.postStopKMs,
    expenses,
  ]);

  useEffect(() => {
    setCustomerType(defaultCustomerType)
  }, [defaultCustomerType])

  const handleTripChange = useCallback((fieldName, fieldValue) => {
    if (fieldName && fieldValue) {
      setTrip((prevTrip) => {
        const updatedTrip = {
          ...prevTrip,
          [fieldName]: fieldValue,
        }
        updateBillingOnValueChange(updatedTrip)
        return updatedTrip;
      });
    }
  }, []);

  const handleClientCompanyChange = useCallback(
    ({ company_id, client_id, defaultCompany_id, name }) => {
      const eClient = { ...trip?.client } || {};
      if (company_id) {
        eClient["company_id"] = company_id;
      }
      if (client_id) {
        eClient["client_id"] = client_id;
        eClient["company_id"] = defaultCompany_id;
      }
      eClient["name"] = name;
      handleTripChange("client", eClient);
    },
    [trip?.client, handleTripChange]
  );

  const getPlannedAddress = (type) => {
    let address = "";
    if (trip?.tripExtn?.[type]?.addressLine) {
      address = trip?.tripExtn?.[type]?.addressLine
    }
    if (trip?.tripExtn?.[type]?.city) {
      address += `${trip?.tripExtn?.[type]?.city}, `
    }
    if (trip?.tripExtn?.[type]?.state) {
      address += `${trip?.tripExtn?.[type]?.state}, `
    }
    if (trip?.tripExtn?.[type]?.country) {
      address += `${trip?.tripExtn?.[type]?.country}`
    }
    return address
  }

  const handleStartKm = (v) => {
    setTrip((prev) => ({
      ...prev,
      tripExtn: {
        ...prev?.tripExtn,
        // distance,
        startKm: v,
      },
    }));
  };

  const handleDistance = (v) => {
    setTrip((prev) => {
      const updatedTrip = {
        ...prev,
        tripExtn: {
          ...prev?.tripExtn,
          distance: Number(v),
        }
      }
      updateBillingOnValueChange(updatedTrip)
      return updatedTrip
    });
  };

  const fetchDistanceBetweenCoordinates = useCallback(async ({ src, dst }) => {
    let kms = 0;
    let mins = 0;
    if (src?.lat && dst?.lat && src?.lng && dst?.lng) {
      try {
        const response = await doGET(
          `/api/o/geocode/distance-time?src=${src?.lat},${src?.lng}&dst=${dst?.lat},${dst?.lng}`
        );
        if (response.status === 200) {
          kms = response?.data?.kms;
          mins = response?.data?.mins;
        }
      } catch (error) {
        console.log(error);
      }
    }
    return { kms: kms, mins: mins };
  }, []);

  const handleDistanceAndTime = useDebounce(async (src, dst, newGarage = null) => {
    const tripExtn = { ...trip?.tripExtn } || {};
    let extnHasChanged = false;
    let garage = newGarage ?? tripExtn?.garage;
    if (garage) {
      garage =
        garage && garage.garageAddress
          ? garage
          : garage.garage_id
            ? await GarageService.get(garage.garage_id)
            : null;
    }
    if (garage) {
      const { kms: preStartKMs, mins: preStartMins } =
        await fetchDistanceBetweenCoordinates({
          src: { lat: garage.address?.lat, lng: garage.address?.lng },
          dst: { lat: src?.lat, lng: src?.lng },
        });
      const { kms: postStopKMs, mins: postStopMins } =
        await fetchDistanceBetweenCoordinates({
          src: { lat: dst?.lat, lng: dst?.lng },
          dst: { lat: garage?.address?.lat, lng: garage?.address?.lng },
        });
      tripExtn["preStartKMs"] = parseInt(preStartKMs) || 0;
      tripExtn["postStopKMs"] = parseInt(postStopKMs) || 0;
      tripExtn["preStartMins"] = preStartMins || 0;
      tripExtn["postStopMins"] = postStopMins || 0;
      if (newGarage?.garage_id) {
        tripExtn["garage"] = { garage_id: newGarage?.garage_id }
      }
      extnHasChanged = true;
    } else {
      tripExtn["preStartKMs"] = 0;
      tripExtn["postStopKMs"] = 0;
      tripExtn["preStartMins"] = 0;
      tripExtn["postStopMins"] = 0;
      extnHasChanged = true;
    }
    const { kms, mins } = await fetchDistanceBetweenCoordinates({
      src: { lat: src?.lat, lng: src?.lng },
      dst: { lat: dst?.lat, lng: dst?.lng },
    });
    //TODO overwrite user provided data from gps data?
    if (kms && !tripExtn?.distance) {
      tripExtn["distance"] = kms || 0;
      extnHasChanged = true;
    }
    if (extnHasChanged) {
      handleTripChange("tripExtn", tripExtn);
    }
    if (mins && !trip?.duration) {
      handleTripChange("duration", mins);
    }
  }, 600);

  const hanldeTripExtnChange = useCallback(
    (fieldName, fieldValue) => {
      if (fieldName && fieldValue) {
        const tripExtn = { ...trip?.tripExtn } || {};
        tripExtn[fieldName] = fieldValue;
        handleTripChange("tripExtn", tripExtn);
      }
    },
    [trip?.tripExtn, handleTripChange]
  );

  const onSaveBooking = async () => {
    try {
      await onSave({
        trip,
        customerType,
        clientBilling,
        supplierBillings,
        space_id,
        fromInvoice,
        email_id:prefetchValue?.email_id??"",
        sms_id:prefetchValue?.sms_id??"",
        postSave: (booking, type) => {
          setTrip(booking)
          fetchBillings(booking?._id)
          if (type === 'save' || type === 'clone') {
            handleNavigation(booking?._id)
          }
          postSave([booking], ['clone', 'save']?.includes(type));
          BookingService.updateBillingState({ client: false, supplier: -1 })
        }
      })
    } catch (error) {
      showError(error)
    }
  }

  const onDutyClose = async () => {
    try {
      await closeDuty(trip, clientBilling, supplierBillings, (booking) => {
        postSave([booking])
        //TODO here navigation also
        handleNavigation(booking?._id, true)
        postDutyClose(booking?._id)
        BookingService.updateBillingState({ client: false, supplier: -1 })
      })
    } catch (error) {
      showError(error)
    }
  }
  const deleteBooking = async () => {
    try {
      await onDelete(editID, () => {
        handleNavigation(editID, true)
        BookingService.updateBillingState({ client: false, supplier: -1 })
      })
    } catch (error) {
      showError(error)
    }
  }

  const updateBillingOnValueChange = async (updatedTrip, updatedExpenses = null) => {
    try {
      const promises = [];
      if (billingRef.current.clientBilling) {
        promises.push(billingRef.current.clientBilling?.updateBilling({ updatedTrip, expenses: updatedExpenses }))
      }
      billingRef.current?.supplierBillings?.forEach(childRef => {
        promises.push(childRef?.updateBilling({ updatedTrip, expenses: updatedExpenses }));
      });
      await Promise.allSettled(promises)
    } catch (error) {
      showError(error)
    }
  }

  return (
    <>
      <div className='d-flex flex-1 flex-column h-100'>
        {loading || bookingLoading ? (
          <div className="d-flex mt-5 justify-content-center align-items-center  flex-1">
            <ThreeBounce size={50} color="pink" />
          </div>
        ) : (
          <>
            <EditBookingHeader billing_ids={[clientBilling, ...supplierBillings]?.map(billing => billing?._id)?.filter(Boolean)} fromInvoice={fromInvoice} booking={trip} setTrip={setTrip} editID={trip?._id} />
            <Form
              className="p-0 px-3 pe-4 pb-5"
              style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
            >
              <Col className="col-sm-12">
                <Card className="p-3 py-0 mt-2 mb-2">
                  <div className="d-flex justify-content-between align-items-center flex-1">
                    {userContent?.accountType === "transporter" &&
                      <div className="d-flex align-items-center w-50">
                        {!fromInvoice ? <ToggleSelector
                          data={[
                            { label: "Walk In ", value: "walkIn" },
                            { label: "For Client", value: "forClient" },
                          ]}
                          className=" w-25"
                          style={{ width: "40%", position: "relative", top: "2.5px" }}
                          value={customerType}
                          onChange={(selectedValue) => {
                            setCustomerType(selectedValue);
                            if (selectedValue === 'walkIn') {
                              setTrip((prevTrip) => ({
                                ...prevTrip,
                                client: null
                              }));
                            }
                          }}
                          disabled={fromInvoice}
                        /> : null}

                        {BookingService.renderField("client.company_id") && customerType == "forClient" && (
                          <div className="d-flex w-100 row flex-1">
                            <div className="col-6 pe-0 ">
                              <ClientSelect
                                value={trip?.client?.client_id}
                                isDisabled={fromInvoice}
                                onChange={(v) => {
                                  handleClientCompanyChange({
                                    client_id: v?.value,
                                    defaultCompany_id: v?.defaultCompany_id
                                  });
                                  if (v.defaultVendorCompany_id) {
                                    handleTripChange("vendorCompany_id", v.defaultVendorCompany_id);
                                  }
                                }}
                                innerContainerClassName="input-group-pre"
                                className="ms-2 me-0 w-100 "
                                outline={true}
                              />
                            </div>
                            {<div className="col-sm-6 p-0">
                              <CompanySelect
                                className="ms-2 w-100"
                                outline
                                corpType="client"
                                isDisabled={!trip?.client?.client_id || fromInvoice}
                                value={trip?.client?.company_id}
                                onChange={(v) => {
                                  handleClientCompanyChange({
                                    company_id: v?.value
                                  });

                                }}
                                innerContainerClassName="input-group-post"
                                label="Client Company"
                                placeholder="Client Company"
                                corp_id={trip?.client?.client_id}
                              />
                            </div>
                            }
                          </div>
                        )}
                      </div>
                    }

                    <div className="d-flex align-items-center justify-content-end flex-1 ms-3">
                      {BookingService.renderField("vendorCompany_id") && userContent?.accountType === "transporter" ? (
                        <div className="col-sm-6 col-lg-4 ">
                          <CompanySelect
                            isDisabled={BookingService.readOnly("vendorCompany_id") || fromInvoice}
                            className="w-100"
                            outline
                            corpType="self"
                            hideWhenSingle
                            value={trip?.vendorCompany_id}
                            onChange={(v) =>
                              handleTripChange("vendorCompany_id", v?.value)
                            }
                            label="Serving Company"
                          />
                        </div>
                      ) : null}
                      {BookingService.renderField("tripType") && (
                        <>
                          <div className="col-sm-6 col-lg-3 ms-2">
                            <TripTypeDropdown
                              data={SELECTSTRUCTURE?.bookingType}
                              onChange={(v) => handleTripChange("tripType", v?.value)}
                              value={trip?.tripType}
                              isDisabled={BookingService.readOnly("tripType")}
                            />
                          </div>
                        </>
                      )}
                      <div className='ms-4' >
                        <Checkbox
                          leftAlign
                          label={t("Trip Start OTP Required")}
                          checked={!(trip?.tripExtn?.otpNotRequired)}
                          className="col-sm-6 col-md-3 "
                          onClick={(v) => {
                            setTrip((prevTrip) => ({
                              ...prevTrip,
                              tripExtn: {
                                ...trip?.tripExtn,
                                otpNotRequired: !(prevTrip?.tripExtn?.otpNotRequired)
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              <Card className="mb-2">
                <CardBody className='px-1 py-0 pb-0'>
                  {BookingService.renderField("passengers") && (
                    <>
                      {/* {space_id ? <Row className="mt-3 mb-3 ">
                        <div><b>Passengers</b></div>
                        <PassengerSelect
                          space_id={space_id}
                          passengers={trip?.passengers}
                          onChange={(v) => handleTripChange("passengers", v)}
                          label={"Select Customer"}
                        />
                      </Row> :  */}
                      <Passengers
                        client_id={trip?.client?.client_id}
                        passengers={trip?.passengers}
                        onChange={(v) => handleTripChange("passengers", v)}
                      />
                      {/* } */}
                    </>

                  )}



                  <hr className="my-1" />
                  {BookingService.renderField("bookedBy") && (
                    <BookedBy
                      className="pb-1"
                      emptyDivStyle={{
                        maxWidth: "196.5px",
                        paddingRight: "0px"
                      }}
                      clientId={trip?.client?.client_id}
                      pax={trip?.tripExtn?.pax ?? trip?.passengers?.length}
                      value={trip?.bookedBy}
                      onChange={(v) => {
                        handleTripChange("bookedBy", v);
                      }}
                      onPaxChange={(v) => {
                        setTrip((prevTrip) => ({
                          ...prevTrip,
                          tripExtn: {
                            ...prevTrip?.tripExtn,
                            pax: v,
                          }
                        }
                        ));
                      }}
                    />
                  )}

                </CardBody>
              </Card>

              <Card className="mb-1">
                <CardBody className="py-1">
                  <div className="d-flex justify-content-start align-items-center row ">
                    <div className='col-3 d-flex align-items-center'>
                      <div

                        style={{
                          width: '11px',
                          height: '11px',
                          borderRadius: '50%',
                          backgroundColor: 'green',
                          marginRight: '7px',
                        }}
                      ></div>
                      {BookingService.renderField("startTime") && (

                        <DateTimePickerWithInterval
                          placeholder="Pickup Time"
                          className="me-2 flex-1  "
                          outline
                          timestamp
                          label="Pickup Time"
                          value={trip?.startTime}
                          onChange={(v) => {
                            handleTripChange("startTime", v);
                            if (trip?.duration) {
                              let endTime = trip?.startTime + Math.ceil(trip?.duration * 60)
                              handleTripChange("duration", Math.ceil((endTime - v > 0 ? endTime - v : 0) / 60));
                            }
                            BookingService.updateBillingState({ client: true })
                            //TODO
                          }
                          }
                        />

                      )}
                    </div>

                    {BookingService.renderField("src") && (
                      <AddressField
                        style={{ flex: 1 }}
                        value={trip?.src}
                        outline
                        className="col-6"
                        cityPlaceHolder={"Pickup City"}
                        addressPlaceHolder={"Pickup Address"}
                        onChange={(v) => {
                          handleTripChange("src", v)
                          BookingService.updateBillingState({ client: true })
                          handleDistanceAndTime(v, trip?.dst)
                        }}
                      />
                    )}

                    <InputField
                      outline
                      placeholder="Trip Start KM"
                      label="Trip Start KM"
                      isDisabled={BookingService.readOnly("tripExtn.startKm")}
                      value={trip?.tripExtn?.startKm ? trip?.tripExtn?.startKm : 0}
                      onblur={(v) => {
                        let value = Number(v);
                        let endKm = Number(trip?.tripExtn?.startKm ?? 0) + Number(trip?.tripExtn?.distance ?? 0) || 0;
                        let distance = value + Number(trip?.tripExtn?.distance ?? 0);

                        handleStartKm(value);
                        if (trip?.tripExtn?.distance) {
                          handleDistance(Math.max(0, endKm - value));
                        }
                        BookingService.updateBillingState({ client: true })
                        //TODO
                      }}
                      type="number"
                      className="col-3"
                    />



                  </div>
                  {trip?.stops?.map((stop, index) => (
                    <div className="d-flex justify-content-start align-items-center ps-4 py-2" key={index}>
                      <div className='fw-bold col-1'>
                        Stop {index + 1}.
                      </div>
                      <div className='col-11'>{getFullAddress(stop?.address)}</div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-start align-items-center row ">
                    <div className='col-3 d-flex align-items-center'>


                      <div
                        style={{
                          width: '11px',
                          height: '11px',
                          backgroundColor: 'red',
                          marginRight: '7px',

                        }}
                      ></div>
                      {BookingService.renderField("endTime") && (
                        <DateTimePickerWithInterval
                          placeholder="Drop Time"
                          className="me-2 flex-1"
                          outline
                          timestamp
                          label="Drop Time"
                          value={trip?.startTime + Math.ceil(trip?.duration * 60)}
                          onChange={(v) => {
                            if (!v) return;
                            handleTripChange("duration", Math.ceil((v - trip?.startTime) / 60));
                            BookingService.updateBillingState({ client: true })
                          }
                          }
                        />
                      )}

                    </div>
                    {BookingService.renderField("dst") && (
                      <AddressField
                        style={{ flex: 1 }}
                        className="col-6"
                        outline
                        cityPlaceHolder={"Drop City"}
                        addressPlaceHolder={"Drop Address"}
                        value={trip?.dst}
                        onChange={(v) => {
                          handleTripChange("dst", v)
                          handleDistanceAndTime(trip?.src, v)
                        }}
                      />
                    )}

                      <InputField
                        outline
                        placeholder="Trip End KM"
                        label="Trip End KM"
                        isDisabled={BookingService.readOnly("tripExtn.endKm")}
                        value={trip?.tripExtn?.distance ? Number(trip?.tripExtn?.startKm ?? 0) + Number(trip?.tripExtn?.distance ?? 0) : ''}
                        // value={tripEditTempData?.endKm}
                        style={{
                          borderTopRightRadius: '0px',
                          borderBottomRightRadius: '0px',
                        }}
                        onblur={(v) => {
                          let value = Number(v);
                          let startKm = Number(trip?.tripExtn?.startKm ?? 0) || 0;
                          console.log("blur...", value)
                          handleDistance(Math.max(0, value - Number(trip?.tripExtn?.startKm ?? 0)));
                          BookingService.updateBillingState({ client: true })
                        }}
                        type="number"
                        className = "col-3"
                        error={(Number(trip?.tripExtn?.startKm ?? 0) + Number(trip?.tripExtn?.distance ?? 0) ?? 0) < Number(trip?.tripExtn?.startKm ?? 0) && 'end km should be greater than start'}
                      />


        
                  </div>

                  <div className='d-flex align-items-center row '>
                      <div className='col-3 d-flex align-items-center'>
                        <BiSolidCarGarage size = {16} />

                        <InputField
                          outline
                          placeholder="Garage to Pickup KMs"
                          label="Garage to Pickup KMs"
                          // isDisabled={BookingService.readOnly("tripExtn.startKm")}
                          value={trip?.tripExtn?.preStartKMs ? trip?.tripExtn?.preStartKMs : 0}
                          onblur={(v) => {
                            let value = parseInt(Number(v));
                            setTrip(prev => {
                              const updatedData = {
                                ...prev,
                                tripExtn: {
                                  ...(prev?.tripExtn ?? {}),
                                  preStartKMs: value
                                }
                              }
                              updateBillingOnValueChange(updatedData)
                              return updatedData
                            })
                            BookingService.updateBillingState({ client: true })
                          }}
                          type="number"
                          className="flex-1 "
                        />
                    </div>
               

                    <InputField
                      outline
                      placeholder="Garage to Pickup Mins"
                      label="Garage to Pickup Mins"
                      value={trip?.tripExtn?.preStartMins ? trip?.tripExtn?.preStartMins : 0}
                      onblur={(v) => {
                        let value = parseInt(Number(v));
                        setTrip(prev => {
                          const updatedData = {
                            ...prev,
                            tripExtn: {
                              ...(prev?.tripExtn ?? {}),
                              preStartMins: value
                            }
                          }
                          updateBillingOnValueChange(updatedData)
                          return updatedData
                        })
                        BookingService.updateBillingState({ client: true })
                      }}
                      type="number"
                      className="col-3 "
                    />
                    <InputField
                      outline
                      placeholder="Drop to Garage KMs"
                      label="Drop to Garage KMs"
                      value={trip?.tripExtn?.postStopKMs ? trip?.tripExtn?.postStopKMs : 0}
                      onblur={(v) => {
                        let value = parseInt(Number(v));
                        setTrip(prev => {
                          const updatedData = {
                            ...prev,
                            tripExtn: {
                              ...(prev?.tripExtn ?? {}),
                              postStopKMs: value
                            }
                          }
                          updateBillingOnValueChange(updatedData)
                          return updatedData
                        })
                        BookingService.updateBillingState({ client: true })
                      }}
                      type="number"
                      className="col-3 "
                    />
                    <InputField
                      outline
                      placeholder="Drop to Garage Mins"
                      label="Drop to Garage Mins"
                      value={trip?.tripExtn?.postStopMins ? trip?.tripExtn?.postStopMins : 0}
                      onblur={(v) => {
                        let value = parseInt(Number(v));
                        setTrip(prev => {
                          const updatedData = {
                            ...prev,
                            tripExtn: {
                              ...(prev?.tripExtn ?? {}),
                              postStopMins: value
                            }
                          }
                          updateBillingOnValueChange(updatedData)
                          return updatedData
                        })
                        BookingService.updateBillingState({ client: true })
                      }}
                      type="number"
                      className="col-3 "
                    />

                  </div>

                  <div className="d-flex row align-items-center">
                    <div className="mt-3 col-3 font-size-13">
                      <span style={{ color: '#999999' }}>
                        {t(`Distance`)} &nbsp;
                      </span>
                      <span>
                        {trip?.tripExtn?.distance ?? 0} KMs
                      </span>
                    </div>
                    <div className="mt-3 col-3 font-size-13">
                      <span style={{ color: '#999999' }}>
                        {t(`Duration`)} &nbsp;
                      </span>
                      <span>
                        {formatSecondsToDuration(Math.ceil(trip?.duration * 60) ?? 0)}
                      </span>
                    </div>
                    <div className="mt-3 col-3 font-size-13">
                      <span style={{ color: '#999999' }}>
                        {t(`Garage Distance`)} &nbsp;
                      </span>
                      <span>
                        {BookingService.findGarageDistance(trip) ? BookingService.findGarageDistance(trip) : 0} KMs
                      </span>
                    </div>
                    <div className="mt-3 col-3 font-size-13">
                      <span style={{ color: '#999999' }}>
                        {t(`Garage Duration`)} &nbsp;
                      </span>
                      <span>
                        {BookingService.findGarageTime(trip) ? formatSecondsToDuration(Math.ceil(BookingService.findGarageTime(trip) * 60) ?? 0) : 0}
                      </span>
                    </div>
                  </div>
                  {trip?.tripExtn && <UncontrolledAccordion className="my-1 py-1" defaultOpen="0">
                    {getPlannedAddress('planedSrc') ? <div className="d-flex align-items-center">
                      <div className="mt-3">
                        <span style={{ color: '#999999' }}>
                          {t(`Planned Pickup`)} &nbsp;
                        </span>
                        <span>
                          {getPlannedAddress('planedSrc')}
                        </span>
                      </div>
                    </div> : null}
                    {getPlannedAddress('planedDst') ? <div className="d-flex align-items-center">
                      <div className="mt-3">
                        <span style={{ color: '#999999' }}>
                          {t(`Planned Destination`)} &nbsp;
                        </span>
                        <span>
                          {getPlannedAddress('planedDst')}
                        </span>
                      </div>
                    </div> : null}
                  </UncontrolledAccordion>}
                </CardBody>
              </Card>

              <div>
                <UncontrolledAccordion className="my-1 py-1" defaultOpen="0">
                  <AssignVehicle
                    trip={trip}
                    garage={trip?.tripExtn?.garage}
                    driver={trip?.driver}
                    vehicle={trip?.vehicle}
                    onChangeVehicle={(v) => {
                      handleTripChange("vehicle", v);
                      let isBillingExist = supplierBillings?.find((supplierBilling) => (v?.provider_id && (v?.provider_id == supplierBilling?.vendor_id)))

                      if (!isBillingExist && v?.provider_id) {
                        BookingService.updateBillingState({ supplier: 0 })
                        setSupplierBillings(prev => ([
                          {
                            vendor_id: v?.provider_id,
                            fee: {
                              currency: "INR",
                              feeGroups: [
                                {
                                  name: `TRIP CHARGES`,
                                },
                              ],
                            },
                          },
                          ...(prev ?? []),
                        ]))
                      }
                    }}
                    onChangeDriver={(v) => handleTripChange("driver", v)}
                    onChangeGarage={(v) => {
                      // const garage = {garage_id : v._id, garageAddress : v.address}
                      hanldeTripExtnChange("garage", v)
                      handleDistanceAndTime(trip?.src, trip?.dst, v)
                      BookingService.updateBillingState({ client: true })
                    }}
                    onChangeTripExtn={(v) => {
                      hanldeTripExtnChange(v?.fieldName, v?.value)
                    }}
                  />
                </UncontrolledAccordion>

                <Row className='mt-2  col-md-6'>
                  {editID && !isCloning &&
                    <Col className="col-md-12 mt-2">
                      <TripExpense
                        disabled={trip?.clientInvoice_id}
                        trip_id={editID}
                        setExpenses={(v) => {
                          setExpenses(v);
                        }}
                        onChange={(v) => {
                          updateBillingOnValueChange(trip, v)
                        }}
                      />
                    </Col>}
                </Row>
                <BillingWrapper
                  clientBilling={clientBilling}
                  supplierBillings={supplierBillings}
                  trip={trip}
                  setTrip={setTrip}
                  updateClientBilling={setClientBilling}
                  updateSupplierBillings={setSupplierBillings}
                  customerType={customerType}
                  ref={billingRef}
                  billingUpdatedObject={billingUpdatedObject}
                  fromInvoice={fromInvoice}
                  fetchBillings={fetchBillings}
                />
              </div>

              <Row>
                <Col className="col-md-6">
                  <Documents
                    config={{
                      showCategory: true,
                      showExpiry: false,
                    }}
                    module="booking"
                    record_id={trip?._id}
                    multiple
                    expanded
                  />
                </Col>
              </Row>
              <Col className="col-md-6 pe-0">
                <CustomFieldComponent
                  module={"trip"}
                  record_id={trip?._id}
                  kind={"trip"}
                  propList={trip?.tripExtn?.propertyList}
                  onChange={(v) =>
                    setTrip(prev => ({
                      ...prev,
                      tripExtn: {
                        ...prev?.tripExtn,
                        propertyList: v,
                      },
                    }))
                  }
                />
              </Col>

              {/* ============= Pictures ============= */}
              <Row className="mt-2">
                <Pictures title={t(`Trip Images`)} srcPath={trip?.tripExtn?.pathPic} srcSign={trip?.tripExtn?.signPic} />
              </Row>

              {
                dutyCloseModal && <DutyClosePopup
                  trip={trip}
                  loading={loading}
                  isOpen={dutyCloseModal}
                  toggleModal={() => { setDutyCloseModal(false) }}
                  onConfirm={() => {
                    setDutyCloseModal(false)
                    onDutyClose()
                  }}
                />
              }

            </Form>
          </>
        )}
        {/* <div className='position-absolute w-100 border-top bg-white bottom-0 justify-content-end d-flex me-3 pe-5 '> */}
        <BookingFooter
          editID={editID}
          booking={trip}
          customerType={customerType}
          onSave={onSaveBooking}
          closeDuty={() => setDutyCloseModal(true)}
          onDelete={deleteBooking}
          fromInvoice={fromInvoice}
        />
        {/* </div> */}
      </div>
    </>
  )
}

export default observer(EditBooking)



