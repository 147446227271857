import React from 'react';
import moment from 'moment';
import { IoMdArrowBack } from 'react-icons/io';
import DocViewer from '../../../components/DocUpload/DocViewer';
import ReplyButton from '../../../components/Buttons/ReplyButton';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import '../Email/styles.scss'
import gmail from '../../../assets/images/icons/gmail.png';
import EmailTags from './EmailTags';
import { formatDateWithConditions } from '../../../util/Util';

const EmailDetails = ({ email, onBack,onReplyClick ,inbound, asDisplay=false}) => {
    let navigate = useNavigate();
    return (
        <div className={`${asDisplay ? 'no_margin' : ''} zr_crm_email-display`}>
            <div className="zr_crm_email-header">
                <h2 className="d-flex align-items-center ">
                    {!asDisplay && <IoMdArrowBack onClick={onBack} className='me-2' size={20} />}
                    
                    <span className='mx-2' >
                    {asDisplay &&<img height={20} className='me-2' src={gmail} alt="Email" />}
                    {email?.trips?.length>0 && <EmailTags type={"trips"} className="ms-1 mx-1" />} {email?.subject}  
                    </span>
                </h2>
              
            </div>
            <div className=' d-flex justify-content-end'>
            <span className="zr_crm_email-date">
             
                    {/* {(email?.createdAt || email?.sentAt) ? moment((email?.createdAt??email?.sentAt)*1000).format('MMM DD, YYYY h:mm A') : ""} */}
                    {(email?.createdAt || email?.sentAt) ?
                        formatDateWithConditions(email.createdAt ?? email.sentAt)
                        : ""
                    }
                </span>
                </div>
            <div className="zr_crm_email-to">
                {!asDisplay &&( inbound? `from: ${email?.from}`:`to: ${email?.to?.join(', ')}`)}
            </div>
            <div className="zr_crm_email-body">
                <div className="zr_communication_email_body" dangerouslySetInnerHTML={{ __html: email?.body }}></div>
                <DocViewer documents={email?.attachment?.urls} />
                {inbound && !asDisplay && (
                    <div className='d-flex justify-content-between'>
                        <ReplyButton onClick={onReplyClick} />
                      <Button
                            onClick={() => {
                                (email?.trips?.length > 0 )?navigate(`/booking/edit/${email?.trips[0]}`):navigate(`/booking/create?email=${email?._id}`)
                            }}
                            className="mt-2 ml-2"
                        >
                            {email?.trips?.length>0 ?"View Booking":"Create Booking"}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmailDetails;
