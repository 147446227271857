export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/trip-journey-gap/grid?rows=${sizePerPage}&`;
        if (filterUrl) url += filterUrl;
        return url + "&page=" + page;
    },
    get: (id) => `/api/trip-journey-gap/${id}/detail`,
    save: `/api/trip-journey-gap/create`,
    delete: (id) => `/api/trip-journey-gap/${id}/delete`,
    update: `/api/trip-journey-gap/update`,
    getrateareas: `/api/trip-journey-gap/grid`,
};

export const STRUCTURE = [
    {
        label: "Country",
        filterLabel: "Country",
        filterName: "country",
        type: "text",
    },

];





