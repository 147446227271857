import React, { useState, useContext } from "react";
import { SelectField } from "../../SelectField/SelectField";
import { I18nContext } from "../../../store/context/i18nContext";


export default function EmployeKindSelect(props) {
  const { t } = useContext(I18nContext);

  const data = [
    { label: "Client", value: 1 },
    { label: "Supplier", value: 2 },
    { label: "Booking", value: 3 },
    { label: "Route", value: 4 },
    { label: "Employee", value: 5 },
    { label: "Rate", value: 6 },
    { label: "Customer", value: 7 },
    { label: "Driver", value: 8 },
    { label: "Expense", value: 9 },
    { label: "Invoice", value: 10 },
    { label: "Office", value: 11 },
    { label: "Vehicle", value: 12 },
    { label: "Company", value: 13 },
  ];

  return (
    <React.Fragment>
      <SelectField
        className={props?.className}
        onChange={props?.onChange}
        data={data}
        showClear={props?.showClear}
        value={props?.value}
        label={"Kind"}
        error={props?.value ? "Please Select Kind" : ""}
        showErr={props?.showErr}
      />
    </React.Fragment>
  );
}
