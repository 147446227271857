import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

import { Col, Row } from "reactstrap";
import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton,
  itemBarSelectedStyle,
} from "../../components";
import "../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import EditStaticContent from "./EditStaticContent";
import StaticContentService from "./StaticContentService";

const StaticContent = (props) => {
  const { t } = useContext(I18nContext);
  let { id } = useParams();
  if (props?.corpId) id = props?.corpId;
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [hasErr, setHasErr] = useState(false);
  const [showDetailPage, setShowDetailPage] = useState(
    props?.corpId ? false : id
  );
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);

  const fetchData = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      await StaticContentService.fetch(values, props?.corpId);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    setShowDetailPage(
      window.location.pathname === "/staticcontent/edit" ||
      (id && !props?.corpId)
    );
  }, [id]);

  const onDelete = async (event, id, name) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: ` Delete Content (${name})?`,
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        const response = await StaticContentService.delete(id);

        navigate(`/staticcontent`);
      } catch (e) {
        showError(e);
      }
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setHasErr(true);
    // if (!data.contentType || !data.businessUnit_id) return;
    setLoading(true);
    try {
      if (id) {
        await StaticContentService.edit(data, id);
        showMessage("Static Content Updated successfully");
      } else {
        const response = await StaticContentService.save(data);
        showMessage("Static Content Saved successfully");
        setData({});
        StaticContentService.records.push(data);
        setShowDetailPage(false);
        if (!props?.insidePane) navigate(`/staticcontent`);
      }
      fetchData();
    } catch (e) {
      showError(e);
      fetchData();
    }
  };

  if (loading) return <CardThreeBounce />;
  return (
    <Layout
      showDetailPage={showDetailPage}
       
      title={t("Static Content")}
      onApplyFilter={fetchData}
      onAddClick={() => {
        if (!props?.insidePane) navigate(`/staticcontent/edit`);
        setShowDetailPage(true);
        setSelected_id(null);
      }}

      backDetailPage={() => {
        setShowDetailPage(false);
        if (!props?.insidePane) navigate("/staticcontent");
        setSelected_id(false);
      }}
      page={StaticContentService.page}
      rows={StaticContentService.rows}
      total={StaticContentService.totalRecords}
      onPageChange={async (page, rows) => {
        await StaticContentService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("Content Type")}</Th>
            <Th>{t("Language")}</Th>
            <Th>{t("Description")}</Th>

            <Th className="float-end">{t("Action")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {StaticContentService.records?.map((staticContent) => (
            <Tr key={staticContent?._id} style={id === staticContent?._id ? itemBarSelectedStyle : {}}>
              <Td>{staticContent?.contentType}</Td>
              <Td>{staticContent?.lang}</Td>
              <Td>{staticContent?.desc}</Td>
              <Td>
                <EditLink
                  onClick={() => {
                    if (!props?.insidePane)
                      navigate(`/staticcontent/edit/${staticContent?._id}`);
                    setSelected_id(staticContent?._id);
                    setShowDetailPage(true);
                  }}
                />
                <DeleteLink
                  onClick={(event) => {
                    onDelete(event, staticContent?._id, staticContent?.contentType);
                  }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>

      <Layout.DetailPageTitle>
        {
          id ? <h5>Edit Content </h5> : <h5>Create Content</h5>
        }

      </Layout.DetailPageTitle>

      <Layout.DetailPageBody>
        <EditStaticContent
          insidePane={props?.insidePane}
          onChange={(v) => setIsEditDataChanged(v)}
          id={selected_id}
          hasErr={hasErr}
          setHasErr={setHasErr}
          back={() => {
            setShowDetailPage(false);
            if (!props?.insidePane) navigate("/staticcontent");
            setSelected_id(false);
          }}
          setData={setData}
          data={data}
          fetchData={fetchData}
        />
      </Layout.DetailPageBody>

      <Layout.DetailPageFooter>
        <Row>
          <Col>
            <div className="d-flex justify-content-end mt-0">
              {data?._id && (
                <DeleteButton onClick={(e) => onDelete(e, data?._id, data?.contentType)} />
              )}
              <SaveButton onClick={submitHandler} />
            </div>
          </Col>
        </Row>
      </Layout.DetailPageFooter>
    </Layout>
  );
};

export default observer(StaticContent);
