import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import {
  CampusSelect,
  CitySelect,
  ClientSelect,
  CompanySelect,
  CountrySelect,
  CurrencySelect,
  DateSelect,
  DateTimeSelect,
  DesignationSelect,
  EmployeeSelect,
  CorporateSelect,
  FundACSelect,
  GarageSelect,
  InputField,
  LanguageSelect,
  MakeModelSelect,
  MakeSelect,
  PhoneNum,
  RankSelect,
  SalutationSelect,
  SelectField,
  SelectFieldMultiple,
  StateSelect,
  SupervisorRoleSelect,
  SupervisorSelect,
  SupplierSelect,
  TariffTypeSelect,
  TimeSelect,
  TimeZoneSelect,
  VehicleSelect,
  VehicleTypeSelect,
  VendorSelect,
  WorkShiftSelect,
  ZoneSelect,
  AccountSelect
} from "../../components";
import UserSelect from "../../components/Dropdowns/UserSelect/UserSelect";
import PhoneNo from "../Demo/PhoneNumber/PhoneNo";
import { FilterModalButton } from "./FilterModalButton";
import "./style.css";
import TagPicker from "../../components/TagSelector/TagPicker";
import RateNameSelect from "../../components/Dropdowns/RateNameSelect/RateNameSelect";
import DateTimePickerWithInterval from "../../components/DateTimePickerWithInterval";
import GroupedSortSelectField from "./GroupedSortSelectField";
import ButtonWithRef from "../../components/Buttons/ButtonWithRef";
import { extractIdsFromInFilter } from "../../util/FilterUtil";
import EmployeeSupervisorSelect from "../../components/Dropdowns/EmployeeSupervisorSelect/EmployeeSupervisorSelect";
import CategorySelect from "../../components/Dropdowns/CategorySelect/CategorySelect";
import ReferenceSelect from "../../components/Dropdowns/ReferenceSelect/ReferenceSelect";

export default function Filter({ filledStyle, outerClassName, isDisabled, onChange = () => { }, hideSearch, isRate, value = {}, structure, applyFunc = () => { }, className, insidePane, navigateOnFilter }) {
  const [enableFilter, setEnableFilter] = useState(false);
  const [modal, setModal] = useState(false);
  const location = useLocation();
  const navigation = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [valueViaProps, setValueViaProps] = useState(true);
  const [filterSearch, setFilterSearch] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterTag, setFilterTag] = useState([])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [fieldStatus, setFieldStatus] = useState(() => {
    let obj = {};
    structure.forEach((s) => {
      obj = { ...obj, [s?.filterName]: false };
    });
    return obj;
  });
  const [corpId, setCorpId] = useState('')

  useEffect(() => {
    const reSize = () => {
      let s = window.innerWidth;
      setWindowWidth(s);
    }
    window.addEventListener('resize', reSize);
  }, [])

  const [filterValues, setFilterValues] = useState({});

  const checkIsFilterDeleted = () => {
    const queryParams = new URLSearchParams(location?.search);
    let isFilterApplied = false;
    let paramsDeleted = false

    Object.keys(filterValues).forEach(filterKey => {
      if (filterValues[filterKey] || filterValues[filterKey] === 0) {
        if (filterKey === "sorting") {
          const { sortBy, sortAsc } = filterValues[filterKey];
          if (sortBy && sortAsc !== null) {
            queryParams.set("sortBy", sortBy);
            queryParams.set("sortAsc", sortAsc.toString());
            isFilterApplied = true;
          }
        } else if (filterKey !== 'createdAtlt' && filterKey !== 'createdAtgt') {
          isFilterApplied = true;
          queryParams.set(filterKey, filterValues[filterKey]);
        } else {
          if (queryParams.has(filterKey)) {
            queryParams.delete(filterKey);
            paramsDeleted = true;
          }
        }
      } else {
        if (queryParams.has(filterKey)) {
          queryParams.delete(filterKey)
          paramsDeleted = true;
        }
      }

    });
    return { isFilterApplied, paramsDeleted }
  }

  const addQueryParam = (url) => {
    // { ===== this function applies query params on the url for the applied filters ======= }
    const { pathname } = location;
    const queryParams = new URLSearchParams(location?.search);
    let isFilterApplied = false;
    let paramsDeleted = false

    Object.keys(filterValues).forEach(filterKey => {
      if (filterValues[filterKey] || filterValues[filterKey] === 0) {
        if (filterKey === "sorting") {
          const { sortBy, sortAsc } = filterValues[filterKey];
          if (sortBy && sortAsc !== null) {
            queryParams.set("sortBy", sortBy);
            queryParams.set("sortAsc", sortAsc.toString());
            isFilterApplied = true;
          }
        } else if (filterKey !== 'createdAtlt' && filterKey !== 'createdAtgt') {
          isFilterApplied = true;
          queryParams.set(filterKey, filterValues[filterKey]);
        } else {
          if (queryParams.has(filterKey)) {
            queryParams.delete(filterKey);
            paramsDeleted = true;
          }
        }
      } else {
        if (queryParams.has(filterKey)) {
          queryParams.delete(filterKey)
          paramsDeleted = true;
        }
      }

    });

    if (isFilterApplied) { setValueViaProps(false) }
    if (navigateOnFilter || paramsDeleted) {
      navigation({
        pathname,
        search: `?${queryParams.toString()}`,
        replace: true,
      });
    } else if (!url) {
      applyFunc(url)
    }
  };


  useEffect(() => {

    const filterObj = {};
    const fieldStatusObject = {};

    const selectedFilters = structure.reduce((acc, filter) => {
      if (value && (value[filter?.filterName] || value[filter?.filterName] == "")) {
        acc.push(filter);
      }
      fieldStatusObject[filter?.filterName] = (value && value[filter?.filterName]) ? true : false;

      if (filter?.type === "phoneNo") {
        filterObj[filter?.filterName + "CC"] = "";
        filterObj[filter?.filterName + "PN"] = "";
      } else {
        filterObj[filter?.filterName] = (value && value[filter?.filterName]) ?? "";
      }

      return acc;
    }, []);

    setFieldStatus(fieldStatusObject);
    setSelectedFilters(selectedFilters);
    setFilterValues(filterObj);
    if (filterObj['tags.tag_id']) {
      setFilterTag(extractIdsFromInFilter(filterObj['tags.tag_id'])?.map(tag => ({ tag_id: tag })));
    }
  }, [JSON.stringify(value)]);


  const applyClientFilter = async () => {
    // console.log("insideeeeeeee");
    //TODO this function is duplicate of above addqueryParam so remove it
    if (!enableFilter) {
      setEnableFilter(true);
    }

    let url = "", andApplied = false;
    let startTime = '', endTime = '';
    // console.log(filterValues);
    for (let currentFilter of selectedFilters) {
      if (currentFilter?.filterType === "greaterThan") {
        andApplied = true;
        startTime = filterValues['createdAtgt'];
      }

      if (currentFilter?.filterType === "lessThan") {
        andApplied = true;
        endTime = filterValues['createdAtlt'];
      }
    }

    if (startTime && endTime) {
      url += `createdAt=btw[${startTime},${endTime}]`;
    } else if (startTime) {
      url += `createdAt=gt[${startTime}]`;
    } else if (endTime) {
      url += `createdAt=lt[${endTime}]`;
    }

    if (!selectedFilters?.filterType && !andApplied) {
      for (const fv in filterValues) {
        if (filterValues[fv] || filterValues[fv] === 0) {
          if (fv === "sorting") {
            const { sortBy, sortAsc } = filterValues[fv];
            if (sortBy && sortAsc !== null) {
              url += `${andApplied ? "&" : ""}sortBy=${sortBy}&sortAsc=${sortAsc}`;
              andApplied = true;
            }
          } else {
            if (!andApplied) andApplied = true;
            else url += "&";
            url += `${fv}=${filterValues[fv]}`;
          }
        }
      }
    }

    if (navigateOnFilter && !insidePane) {
      addQueryParam(url);
    }
    // applyFunc(url);
    const { paramsDeleted } = checkIsFilterDeleted()
    if (!url && !navigateOnFilter || paramsDeleted) {
      addQueryParam(url);
      return;
    }
    applyFunc(url);
    // console.log(url);
    // if(insidePane || url){
    //   applyFunc(url);
    // }else{
    //   if (!insidePane /* && navigateOnFilter */) {
    //     addQueryParam(url);
    //   }
    // }
  };
  // console.log("====",filterTag)
  const searchButtonRef = useRef();
  const filterComponentRef = useRef(null);

  const triggerSearchButtonClick = useCallback(() => {
    if (searchButtonRef.current) {
      searchButtonRef.current.click();
    }
  }, [searchButtonRef]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        triggerSearchButtonClick();
      }
    };
    const filterComponent = filterComponentRef.current;
    if (filterComponent) {
      filterComponent.addEventListener('keypress', handleKeyPress);
    }
    return () => {
      if (filterComponent) {
        filterComponent.removeEventListener('keypress', handleKeyPress);
      }
    };
  }, [triggerSearchButtonClick]);


  const filterObjectByArray = (obj, arr) => {
    let result = {};
    for (let key in obj) {
      if (arr.includes(key)) {
        result[key] = obj[key];
      }
    }
    console.log(obj, arr, result)

    return result;
  }

  const setFilterValuesFunc = (field, v, selectedFilter) => {
    if (isDisabled) {
      return;
    }
    if (field === 'corp_id') {
      setCorpId(v)
    }

    setFilterValues((filterValues) => {
      onChange(filterObjectByArray({ ...filterValues, [field]: v }, (selectedFilter ?? selectedFilters)?.map(v => v?.filterName)))
      return { ...filterValues, [field]: v };
    });
  };

  const filterChoosed = (s) => {
    if (isDisabled) {
      return;
    }
    setOpenMenu(false);
    setFilterSearch("");
    setSelectedFilters([...selectedFilters, s]);
    setFieldStatus({ ...fieldStatus, [s?.filterName]: true });
  };

  const filterChanged = (s) => {
    if (isDisabled) {
      return;
    }
    if (s?.isOnClearFunc) s?.onClear(setFilterValuesFunc);
    const selectedFilter = selectedFilters?.filter((sf) => s?.filterName !== sf.filterName)
    setSelectedFilters(selectedFilter);
    setFilterValuesFunc(s?.filterName, "", selectedFilter);

    setFieldStatus({ ...fieldStatus, [s?.filterName]: false });
  };

  if (!value) return null;

  return (
    <React.Fragment>
      <div className={outerClassName} ref={filterComponentRef}>
        {windowWidth < 640 ? (
          <div className="d-flex flex-1 justify-content-end">
            <FilterModalButton
              onClick={() => {
                setModal(true);
              }}
              filterLength={selectedFilters?.length}
            />{" "}
          </div>
        ) : null}
        <div
          className={` ${modal
            ? "__backdrop"
            : `${windowWidth < 640
              ? "d-none"
              : ""
            }`
            }`}
        >
          <div
            style={{ position: "relative" }}
            className={` ${modal ? "__modal-filter" : ""}`}
          >
            <div
              style={{ position: "relative", fontWeight: "300" }}
              className={className}
            >
              <div
                // ref={searchButtonRef}
                className="d-flex justify-content-end w-100"
              >


                {(selectedFilters?.length > 0 || (valueViaProps && value && Object.keys(value).length > 0 && selectedFilters?.length > 0)) && (
                  <div
                    // ref={searchButtonRef}
                    className={`rounded-top rounded-bottom d-flex flex-wrap  w-sm-100 ps-1 pe-5 col-12 `}
                    id="popup"
                    style={{
                      backgroundColor: `${"#ebebeb"} `,
                      ...(filledStyle && (filledStyle ?? {}))
                    }}
                  >
                    {selectedFilters?.map((s) => {
                      switch (s?.type) {
                        case "check":
                          return (
                            <div className="filterField_parent">
                              <div className='filterField d-flex'>
                                <input
                                  type="checkbox"
                                  className="me-2"
                                  checked={filterValues[s?.filterName] == s?.checked ? true : false}
                                  onChange={(v) => {
                                    if (v.target.checked)
                                      setFilterValuesFunc(s?.filterName, s?.checked)
                                    else
                                      setFilterValuesFunc(s?.filterName, s?.notChecked)
                                  }}
                                />
                                <p style={{ fontWeight: 500, fontSize: 12 }}>is checked</p>
                              </div>
                              <i
                                onClick={() => filterChanged(s)}
                                className="dripicons-cross mx-2"
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i>
                            </div>
                          )


                        case "tagsSelect":
                          return (
                            <div className="filterField_parent d-flex align-items-center">
                              <TagPicker
                                // className="filterField pt-0 1"
                                kind={"trip"}
                                shouldFocus
                                driver
                                inline
                                value={filterTag}
                                onChange={(v) => {
                                  const newValue = v?.map(e => e?.tag_id)
                                  setFilterTag(v);
                                  const filter_string = "in[" + newValue.join(",") + "]";
                                  setFilterValuesFunc(s?.filterName, filter_string)
                                }}
                              />
                              <i
                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "0px", cursor: "pointer" }}
                              ></i>
                            </div>
                          );


                        case "sorting":
                          return (
                            <div className="filterField_input">
                              <GroupedSortSelectField
                                sidxOptions={s.sidx}
                                sordOptions={s.sord}
                                value={filterValues[s.filterName] || { sortBy: "", sortAsc: "true" }}
                                onChange={(v) => setFilterValuesFunc(s.filterName, v)}
                              />
                              <i
                                onClick={() => filterChanged(s)}
                                className="dripicons-cross"
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i>
                            </div>
                          );

                        case "text":
                          return (
                            <div className="filterField_input">
                              <InputField
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v)
                                }
                                placeholder={s?.placeholder}
                                label={s?.label}
                                showClear={false}
                                autoFocus
                                inline
                                type="text"
                                className={`pt-0 1 m-0`}
                                onClickRight={() => filterChanged(s)}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "phone":
                          return (
                            <div className="filterField_input">
                              <InputField
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}
                                checker={(v) => v.length <= 10}
                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v)
                                }
                                placeholder={s?.placeholder}
                                label={s?.label}
                                showClear={false}
                                inline
                                autoFocus
                                type="number"
                                className={`px-1 pt-0 1`}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "select":
                          return (
                            <div className="filterField_parent">
                              <SelectField
                                inline
                                value={filterValues[s?.filterName]}
                                onChange={(v) =>
                                  s?.onChange
                                    ? s?.onChange(v, setFilterValuesFunc)
                                    : setFilterValuesFunc(s?.filterName, v.value)
                                }
                                isDisabled={
                                  s?.isDisabled
                                    ? s?.isDisabled(filterValues)
                                    : false
                                }
                                label={s?.label}
                                data={
                                  s?.isDataFunc ? s?.data(filterValues) : s?.data
                                }
                                shouldFocus
                                className={`filterField pt-0 m-0`}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "date":
                          return (
                            <div className="filterField_input">
                              <InputField
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                label={s?.label}
                                onChange={(v) => {
                                  setFilterValuesFunc(s?.filterName, v)
                                }
                                }
                                autoFocus
                                showClear={false}
                                type="date"
                                inline
                                className={`pt-0 m-0`}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "month":
                          return (
                            <InputField
                              value={filterValues[s?.filterName]}
                              isDisabled={isDisabled}

                              label={s?.label}
                              onChange={(v) =>
                                setFilterValuesFunc(s?.filterName, v)
                              }
                              showClear={false}
                              type="date"
                              inline
                              autoFocus
                              RightComponent={() => (
                                <i

                                  className="dripicons-cross "
                                  id="buttonCloseFilter"
                                  style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                                ></i>
                              )}
                              onClickRight={() => filterChanged(s)}
                              className="filterField pt-0 m-0"


                            />
                          );
                        case "selectMultiple":
                          return (
                            <div className="filterField_parent">
                              <SelectFieldMultiple
                                inline
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                label={s?.label}
                                data={s?.data}
                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v)
                                }
                                shouldFocus
                                showClear={false}
                                className="filterField"
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "checkBox":
                          return (
                            <div style={{ padding: "2px 0px 2px 0px" }} className=" filterField_checkbox d-flex  align-items-center">
                              <span className="badge bg-success h5 mb-0 text-light">
                                {s?.label}
                              </span>
                              <i
                                onClick={() => filterChanged(s)}

                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "0px", cursor: "pointer" }}
                              ></i>
                            </div>
                          );
                        case "phoneNo":
                          return (
                            <div className="filterField_parent">
                              <PhoneNo
                                inline
                                autoFocus
                                className="py-2"
                                selectedCountryCode={
                                  filterValues[s?.filterName + "CC"]
                                }
                                phoneNumber={filterValues[s?.filterName + "PN"]}
                                onCountryChange={(c) =>
                                  setFilterValuesFunc(s?.filterName + "CC", c)
                                }
                                onPhoneNumberChange={(m) =>
                                  setFilterValuesFunc(s?.filterName + "PN", m)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "rateSelect":
                          return (
                            <div className="filterField_parent">
                              <RateNameSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                multi={s?.multi}
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) => {
                                  s?.dependencyOnMe?.forEach((xyz) =>
                                    setFilterValuesFunc(xyz, "")
                                  );
                                  setFilterValuesFunc(s?.filterName, v.value);
                                }}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "clientSelect":
                          return (
                            <div className="filterField_parent">
                              <ClientSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                multi={s?.multi}
                                onChange={(v) => {
                                  s?.dependencyOnMe?.forEach((xyz) =>
                                    setFilterValuesFunc(xyz, "")
                                  );
                                  setFilterValuesFunc(s?.filterName, v.value);
                                }}
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "vendorSelect":
                          return (
                            <div className="filterField_parent">
                              <VendorSelect
                                className="filterField pt-0 1"
                                inline
                                multi={s?.multi}
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) => {
                                  s?.dependencyOnMe?.forEach((xyz) =>
                                    setFilterValuesFunc(xyz, "")
                                  );
                                  setFilterValuesFunc(s?.filterName, v.value);
                                }}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "supplierSelect":
                          return (
                            <div className="filterField_parent">
                              <SupplierSelect
                                className="filterField pt-0 1"
                                inline
                                multi={s?.multi}
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) => {
                                  s?.dependencyOnMe?.forEach((xyz) =>
                                    setFilterValuesFunc(xyz, "")
                                  );
                                  setFilterValuesFunc(s?.filterName, v.value);
                                }}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "countrySelect":
                          return (
                            <div className="filterField_parent">
                              <CountrySelect
                                className="filterField pt-0 1"
                                inline
                                label={s.label}
                                isd={s.isd}
                                shouldFocus
                                name={s.name}
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) => {
                                  s?.dependencyOnMe?.forEach((xyz) =>
                                    setFilterValuesFunc(xyz, "")
                                  );
                                  setFilterValuesFunc(s?.filterName, v.value);
                                }}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "vehicleTypeSelect":
                          return (
                            <div className="filterField_parent">
                              <VehicleTypeSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                label={s?.label}
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "makeModelSelect":
                          return (
                            <div className="filterField_parent">
                              <MakeModelSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                label={s?.label}
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "stateSelect":
                          return (
                            <div className="filterField_parent">
                              <StateSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                country={
                                  s?.dependency?.country
                                    ? filterValues[s?.dependency?.country]
                                    : null
                                }
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) => {
                                  setFilterValuesFunc(s?.filterName, v.value);
                                }}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "citySelect":
                          return (
                            <div className="filterField_parent">
                              {" "}
                              <CitySelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                country={
                                  s?.dependency?.country
                                    ? filterValues[s?.dependency?.country]
                                    : null
                                }
                                state={
                                  s?.dependency?.state
                                    ? filterValues[s?.dependency?.state]
                                    : null
                                }
                                onlyCountry={s?.onlyCountry}
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) => {
                                  setFilterValuesFunc(s?.filterName, v.value);
                                }}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "currencySelect":
                          return (
                            <div className="filterField_parent">
                              {" "}
                              <CurrencySelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "entitySelect":
                          return (
                            <div className="filterField_parent">
                              <CompanySelect
                                // style={{zIndex:4}}
                                className="filterField pt-0 1"
                                inline
                                corpType="self"

                                shouldFocus
                                label={s?.label}
                                vendor={
                                  s?.dependency?.vendor
                                    ? filterValues[s?.dependency?.vendor]
                                    : null
                                }
                                client={
                                  s?.dependency?.client
                                    ? filterValues[s?.dependency?.client]
                                    : null
                                }
                                noDependency={
                                  !s?.dependency?.client && !s?.dependency?.vendor
                                }
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "languageSelect":
                          return (
                            <div className="filterField_parent">
                              {" "}
                              <LanguageSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "garageSelect":
                          return (
                            <div className="filterField_parent">
                              {" "}
                              <GarageSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "vehicleSelect":
                          return (
                            <div className="filterField_parent">
                              <VehicleSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "accountSelect":
                          return (<div className="filterField_parent">
                            <AccountSelect
                              label={"Account Select"}
                              className="filterField pt-0 1"
                              inline
                              shouldFocus
                              value={filterValues[s?.filterName]}
                              isDisabled={isDisabled}

                              onChange={(v) =>
                                setFilterValuesFunc(s?.filterName, v.value)
                              }
                            />
                            {!isDisabled ? <i

                              onClick={() => filterChanged(s)}
                              className="dripicons-cross "
                              id="buttonCloseFilter"
                              style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                            ></i> : <div className="me-2"></div>}
                          </div>)
                        case "salutationselect":
                          return (
                            <div className="filterField_parent">
                              <SalutationSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "fundACSelect":
                          return (
                            <div className="filterField_parent">
                              <FundACSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "phoneNum":
                          return (
                            <div className="filterField_checkbox">
                              <PhoneNum
                                className="col-12 col-sm-filterField pt-0 1"
                                inline
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "driverSelect":
                          return (
                            <div className="filterField_parent">
                              <EmployeeSelect
                                label={"Driver"}
                                shouldFocus
                                driver
                                inline
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                                // onChange={(v) => setFilterValuesFunc({ ...data, driver: v?.value })}
                                className="filterField pt-0 1"
                              />
                              {/* <DriverSelect
                                className="filterField pt-0 1"
                                inline
                                value={filterValues[s?.filterName]}
isDisabled = {isDisabled}
                                 
                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              /> */}
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "employeesupervisorselect":
                          return (
                            <div className="filterField_parent">
                              <EmployeeSupervisorSelect
                                label={"Added By"}
                                shouldFocus
                                driver
                                inline
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                                // onChange={(v) => setFilterValuesFunc({ ...data, driver: v?.value })}
                                className="filterField pt-0 1"
                              />
                              {/* <DriverSelect
                                className="filterField pt-0 1"
                                inline
                                value={filterValues[s?.filterName]}
isDisabled = {isDisabled}
                                 
                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              /> */}
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "referenceSelect":
                          return (
                            <div className="filterField_parent">
                              <ReferenceSelect

                                label={"Reference No."}
                                shouldFocus
                                driver
                                inline
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                                // onChange={(v) => setFilterValuesFunc({ ...data, driver: v?.value })}
                                className="filterField pt-0 1"
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "categoryselect":
                          return (
                            <div className="filterField_parent">
                              <CategorySelect
                                // className={"col-6"}
                                showSubcategory={false}
                                inline
                                module={"expense-" + filterValues?.type}
                                className="filterField pt-0 1 col-12 "
                                value={filterValues[s?.filterName]}
                                // module={"expense-" + data?.type}
                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v?.category_id)
                                }
                              />

                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );


                        case "userSelect":
                          return (
                            <div className="filterField_parent">
                              <UserSelect
                                label={"Customer"}
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                                // onChange={(v) => setFilterValuesFunc({ ...data, driver: v?.value })}
                                className="filterField pt-0 1"
                              />
                              {/* <DriverSelect
                                className="filterField pt-0 1"
                                inline
                                value={filterValues[s?.filterName]}
isDisabled = {isDisabled}
                                 
                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              /> */}
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "supervisorSelect":
                          return (
                            <div className="filterField_parent">
                              {" "}
                              <SupervisorSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "supervisorRoleSelect":
                          return (
                            <div className="filterField_parent">
                              {" "}
                              <SupervisorRoleSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "corpSelect":
                          return (
                            <div className="filterField_parent">
                              {" "}
                              <CorporateSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "zoneSelect":
                          return (
                            <div className="filterField_parent">
                              {" "}
                              <ZoneSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "makeSelect":
                          return (
                            <div className="filterField_parent">
                              <MakeSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "dateSelect":
                          return (
                            <DateSelect
                              className="filterField_checkbox px-1 pt-0 m-0"
                              inline
                              autoFocus
                              label={s?.label}
                              value={filterValues[s?.filterName]}
                              isDisabled={isDisabled}

                              onChange={(v) =>
                                setFilterValuesFunc(s?.filterName, v)
                              }
                              onClickRight={() => filterChanged(s)}
                              RightComponent={() => (
                                <i

                                  className="dripicons-cross "
                                  id="buttonCloseFilter"
                                  style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                                ></i>
                              )}
                            />
                          );
                        case "timeSelect":
                          return (
                            <div className="filterField_input">
                              <TimeSelect
                                className="filterField_checkbox pt-0 m-0 "
                                inline
                                autoFocus
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v)
                                }

                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );

                        case "dateTimeSelect":
                          return (
                            <div className="filterField_input">
                              <DateTimePickerWithInterval
                                placeholder={s?.label}
                                outerClassName={"ps-0"}
                                className="px-0 py-0 d-flex align-items-start m-0 p-0"
                                inline
                                autoFocus
                                timestamp
                                value={filterValues[s?.filterName]}
                                isDisabled={isDisabled}

                                onChange={(v) => {
                                  setFilterValuesFunc(s?.filterName, v)
                                }
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );

                        case "tariffTypeSelect":
                          return (
                            <div className="filterField_parent">
                              {" "}
                              <TariffTypeSelect
                                className="filterField pt-0 1"
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                onChange={(v) =>
                                  setFilterValuesFunc(s?.filterName, v.value)
                                }
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "campusSelect":
                          return (
                            <div className="filterField_parent">
                              <CampusSelect
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                onChange={(v) =>
                                  s?.onChange
                                    ? s?.onChange(v, setFilterValuesFunc)
                                    : setFilterValuesFunc(s?.filterName, v.value)
                                }
                                isDisabled={
                                  s?.isDisabled
                                    ? s?.isDisabled(filterValues)
                                    : false
                                }
                                label={s?.label}
                                corp_id={corpId}

                                className={`filterField pt-0 1`}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "designationSelect":
                          return (
                            <div className="filterField_parent">
                              <DesignationSelect
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                onChange={(v) =>
                                  s?.onChange
                                    ? s?.onChange(v, setFilterValuesFunc)
                                    : setFilterValuesFunc(s?.filterName, v.label)
                                }
                                isDisabled={
                                  s?.isDisabled
                                    ? s?.isDisabled(filterValues)
                                    : false
                                }
                                label={s?.label}

                                className={`filterField pt-0 1`}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "rankSelect":
                          return (
                            <div className="filterField_parent">
                              <RankSelect
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                onChange={(v) =>
                                  s?.onChange
                                    ? s?.onChange(v, setFilterValuesFunc)
                                    : setFilterValuesFunc(s?.filterName, v.value)
                                }
                                isDisabled={
                                  s?.isDisabled
                                    ? s?.isDisabled(filterValues)
                                    : false
                                }
                                label={s?.label}

                                className={`filterField py-0`}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "WorkShiftSelect":
                          return (
                            <div className="filterField_parent">
                              <WorkShiftSelect
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                onChange={(v) =>
                                  s?.onChange
                                    ? s?.onChange(v, setFilterValuesFunc)
                                    : setFilterValuesFunc(s?.filterName, v.value)
                                }
                                isDisabled={
                                  s?.isDisabled
                                    ? s?.isDisabled(filterValues)
                                    : false
                                }
                                label={s?.label}
                                corp_id={corpId}
                                className={`filterField py-0`}
                              />
                              {!isDisabled ? <i

                                onClick={() => filterChanged(s)}
                                className="dripicons-cross "
                                id="buttonCloseFilter"
                                style={{ height: "20.5px", marginTop: "3.5px", cursor: "pointer" }}
                              ></i> : <div className="me-2"></div>}
                            </div>
                          );
                        case "timezoneSelect":
                          return (
                            <div className="filterField_parent">
                              <TimeZoneSelect
                                inline
                                shouldFocus
                                value={filterValues[s?.filterName]}
                                onChange={(v) =>
                                  s?.onChange
                                    ? s?.onChange(v, setFilterValuesFunc)
                                    : setFilterValuesFunc(s?.filterName, v.value)
                                }
                                isDisabled={
                                  s?.isDisabled
                                    ? s?.isDisabled(filterValues)
                                    : false
                                }
                                label={s?.label}
                                corp_id={corpId}

                                className={`filterField py-0`}
                              />
                            </div>
                          );
                        default:
                          return null;
                      }
                    })}
                    <div style={{ height: "30px", position: "relative" }}>
                      {((selectedFilters?.length == structure?.length) || isDisabled) ? null :

                        <InputField
                          // style={{ }}
                          outerClassName={"ps-0"}
                          value={filterSearch}
                          onChange={(v) => setFilterSearch(v)}
                          // onFocus={() => setOpenMenu(true)}
                          onClick={() => setOpenMenu(!openMenu)}
                          onBlur={() => setTimeout(() => setOpenMenu(false), 250)}
                          showClear={false}
                          type="text"
                          outline
                          // autoFocus
                          onKeyDown={() => {
                            // console.log("1153")
                          }}
                          style={{ height: "24px", margin: "2px 0px 0px 0px", border: "none", backgroundColor: "#EFEFEF44" }}
                          className={`m-0 p-0`}
                        />}
                      <div>
                        {(openMenu && (!isDisabled) && selectedFilters?.length != 0) && (
                          <div
                            className={`shadow flex-wrap rounded-bottom  px-0 mx-0 filter-open-menu-selected`}
                          >
                            {structure
                              .filter(
                                (s) =>
                                  (filterSearch && s?.type === "text") ||
                                  (!selectedFilters?.some(v => v?.filterName == s?.filterName))
                              )
                              .map(
                                (s) =>
                                  !fieldStatus[s?.filterName] && (
                                    <div
                                      role="button"
                                      onClick={() => {
                                        if (s?.type === "checkBox") {
                                          s?.url ? setFilterValuesFunc(s?.filterName, s?.url) : setFilterValuesFunc(s?.filterName, "true");
                                        }
                                        if (s?.type === "text")
                                          setFilterValuesFunc(
                                            s?.filterName,
                                            filterSearch,
                                            [...selectedFilters, s]
                                          );
                                        filterChoosed(s);
                                      }}
                                      className="d-flex flex-wrap justify-content-start align-items-center col-sm-12 p-0 py-1 my-0 px-3 ps-2 mx-0 mouseHoverFilter"
                                    >

                                      <div> {s?.iconComponent ? <div className="font-size-14 ms-0 me-1">{s?.iconComponent}</div> : <i className={`font-size-14 ${s?.icon ? s?.icon : " mdi mdi-star-four-points-outline "} ms-0 me-1 mt-1 `}></i>}</div>
                                      <div className="me-1 font-size-12">  {s?.filterLabel} </div>
                                      <div>
                                        {s?.filterType === "lessThan" && <span className="lessthan-icon">{"<"} </span>}
                                        {s?.filterType === "greaterThan" && <span className="greaterthan-icon">{">"} </span>}
                                        {s?.filterType === "equals" && <span className="equals-icon">{"="} </span>}
                                        {s?.filterType === "notEquals" && <span style={{ fontStyle: 'normal' }} className="notequals-icon">{"! ="} </span>}
                                      </div>
                                      <div className="desc me-1 mt-1 font-size-9" >
                                        {s?.type === "text" && filterSearch
                                          ? filterSearch
                                          : s?.description}
                                      </div>
                                    </div>
                                  )
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}


                <div
                  className="w-100"
                  style={{ position: selectedFilters?.length == 0 ? "relative" : "static", fontWeight: "300" }}>
                  {(selectedFilters?.length === 0 && (Object.keys(value).length === 0 || !valueViaProps || selectedFilters?.length === 0)) && (
                    <div
                      className={`rounded-top ${openMenu ? " shadow " : " rounded-bottom "} d-flex flex-wrap w-100 p-0 col-12  `}
                      id="popup"
                      style={{
                        paddingBottom: ` ${selectedFilters?.length ? "  " : " 2px "
                          }`,
                        height: "30px",
                        backgroundColor: `${openMenu ? "white" : ""} `,
                        borderLeft: "1px solid #ced4da ",
                        borderRight: "1px solid #ced4da ",
                        borderTop: "1px solid #ced4da ",
                        borderBottom: `${openMenu ? "none" : "1px solid #ced4da"}`,
                      }}
                    >
                      <InputField
                        value={filterSearch}
                        onChange={(v) => setFilterSearch(v)}
                        onFocus={() => setOpenMenu(true)}
                        onBlur={() => setTimeout(() => setOpenMenu(false), 250)}
                        showClear={false}
                        outline
                        onKeyDown={() => {
                          // console.log("1237")
                        }}
                        type="text"
                        // autoFocus
                        outerClassName={"ps-0"}
                        style={{ height: "24px", marginTop: "2px", border: "none", minWidth: 220 }}
                        className={`m-0 w-100`}
                      />
                    </div>
                  )}

                  <div >
                    {(openMenu && (!isDisabled) && selectedFilters?.length === 0) && (
                      <div
                        // style={{border : "12px solid red"}}
                        className={`shadow flex-wrap rounded-bottom  px-0 mx-0 filter-open-menu`}
                      >
                        {structure
                          .filter(
                            (s) =>
                              s?.filterName.toLowerCase().includes(filterSearch.toLowerCase()) ||
                              s?.filterLabel.toLowerCase().includes(filterSearch.toLowerCase()) ||
                              (filterSearch && s?.type === "text")
                          )
                          .map(
                            (s) =>
                              !fieldStatus[s?.filterName] && (
                                <div
                                  role="button"
                                  onClick={() => {
                                    if (s?.type === "checkBox") {
                                      s?.url ? setFilterValuesFunc(s?.filterName, s?.url) : setFilterValuesFunc(s?.filterName, "true");
                                    }
                                    if (s?.type === "text")
                                      setFilterValuesFunc(
                                        s?.filterName,
                                        filterSearch,
                                        [...selectedFilters, s]
                                      );
                                    filterChoosed(s);
                                  }}
                                  className="d-flex flex-wrap  justify-content-start align-items-center col-sm-12 p-0 py-1 my-0 px-3 ps-2 mx-0 mouseHoverFilter"
                                >
                                  {/* <div> {s?.iconComponent ? <div className="font-size-14 ms-0 me-1">{s?.iconComponent}</div> : <i className={`font-size-14 ${s?.icon ? s?.icon : " mdi mdi-star-four-points-outline "} ms-0 me-1 mt-1 `}></i>}</div> */}
                                  <div className="me-1 font-size-12">  {s?.filterLabel} </div>

                                  <div>
                                    {s?.filterType === "lessThan" && <i className="lessthan-icon">{"<"} </i>}
                                    {s?.filterType === "greaterThan" && <i className="greaterthan-icon">{">"} </i>}
                                    {s?.filterType === "equals" && <i className="equals-icon">{"="} </i>}
                                    {s?.filterType === "notEquals" && <i className="notequals-icon">{"!="} </i>}
                                  </div>
                                  <div className="desc me-1 mt-1  font-size-9" >
                                    {s?.type === "text" && filterSearch
                                      ? filterSearch
                                      : s?.description}
                                  </div>
                                </div>
                              )
                          )}
                      </div>
                    )}
                  </div>
                </div>

                {!hideSearch ? <ButtonWithRef
                  id="searchButtonId"
                  color="secondary"
                  onClick={(e) => { e.preventDefault(); applyClientFilter(e) }}
                  ref={searchButtonRef}
                  style={{
                    position: "absolute",
                    height: "calc(max(100%,30px))",
                    top: !enableFilter ? "0px" : "unset",
                    bottom: "0%",
                    right: "0%",
                    marginTop: !enableFilter ? "0px" : " 0px",
                    // paddingTop: (!enableFilter ? '30px' : ' 30px'),
                    padding: "0px",
                  }}
                >
                  <i
                    style={{ fontSize: "21px", margin: "3px" }}
                    className="bx bx-search-alt-2"
                  />
                </ButtonWithRef> : null}

              </div>

            </div>
            {modal ? (
              <div
                style={{}}
                className=" waves-effect waves-light __modal-close-btn "
                onClick={() => { setModal(false); }}
              >
                <i style={{ fontSize: "25px" }} className="bx bx-x" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}
