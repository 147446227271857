import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { DialogContext } from '../../../../store/context/DialogContext';
import { DeleteButton, ModalComponent, SaveButton } from '../../../../components';
import { I18nContext } from '../../../../store/context/i18nContext';
import GroupTourSidebarService from '../GroupTourSidebarService';
import { Input } from 'reactstrap';

const ChangeSpaceModal = ({
    isOpen,
    toggleModal,
    selectedSpaceId ,

    onSave = () => { } }
) => {

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [selectedSpace, setSelectedSpace] = useState(selectedSpaceId);

    const handleSave = async () => {
        try {
            onSave(selectedSpace);
            toggleModal();

        } catch (error) {
            showError(t('Error changing space'));
        } finally {
            setLoading(false);
        }
    }

    const handleSelectSpace = (space) => {
        setSelectedSpace(space._id);
    }

  
    const spaces = [
        { name: "Everything", _id: null },
        ...(GroupTourSidebarService.records?.map(v => ({ name: v?.name, _id: v?._id })) ?? [])
    ];

    return (
        <ModalComponent
            position={"top"}
            size={"medium"}
            isOpen={isOpen}
            onToggle={toggleModal}
        >
            <ModalComponent.Title>
                <div className='d-flex justify-content-between'>
                    Change Space 
                </div>
            </ModalComponent.Title>

            <ModalComponent.Body>
                <div className='d-flex flex-column py-2'>
                    {spaces.map((space) => (
                        <div key={space._id} className="d-flex align-items-center mb-2">
                            <Input
                                type="checkbox"
                                checked={selectedSpace === space._id}
                                onChange={() => handleSelectSpace(space)}
                            />
                            <span className="ms-2">{space.name}</span>
                        </div>
                    ))}
                </div>
            </ModalComponent.Body>

            <ModalComponent.Footer>
                <div className='d-flex justify-content-end'>
                    <SaveButton
                        onClick={handleSave}
                        disabled={loading}
                    />
                </div>
            </ModalComponent.Footer>
        </ModalComponent>
    );
};

export default observer(ChangeSpaceModal);
