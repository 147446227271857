export const ENDPOINTS = {
    grid: ({ page, sizePerPage, filterUrl, org_id }) => {
        let url = `/api/office/grid?${org_id ? `org_id=${org_id}&` : ""}`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    get: ({ editId, org_id }) => `/api/office/${editId}/detail${org_id ? `?org_id=${org_id}` : ""}`,
    save: () => `/api/office/create`,
    delete: (id, org_id) => `/api/office/${id}/delete${org_id ? `?org_id=${org_id}` : ""}`,
    bulkDelete: (id) => `/api/office/delete`,
    update: ({ org_id }) => `/api/office/update${org_id ? `?org_id=${org_id}` : ""}`,
};

export const STRUCTURE = [
    {
        label: "Name",
        description: "Office name",
        filterLabel: "Office Name",
        filterName: "name",
        type: "text",
    },
    {
        label: "City",
        description: "Office city",
        filterLabel: "Office City",
        filterName: "address.city",
        type: "text",
    },
    {
        label: "Office Address",
        description: "Office Address",
        filterLabel: "Office Address",
        filterName: "address.addressLine",
        type: "text",
    },
    {
        label: "State",
        description: "Office State",
        filterLabel: "Office State",
        filterName: "address.state",
        type: "text",
    },
    {
        label: "Country",
        description: "Office Country",
        filterLabel: "Office Country",
        filterName: "address.country",
        type: "text",
    },
];
