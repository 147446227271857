import React, { useCallback, useContext, useMemo, useState } from 'react'
import GeneralDropdown from '../Dropdowns/GeneralDropdown'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'

import { useEffect } from 'react'
import RosteringService from '../../pages/Rostering/RosteringService'
import SpaceService from '../space/SpaceService'
import OfficeService from '../../pages/Office/OfficeService'
import { UserContext } from '../../store/context/UserContext'
import Space from '../space/Space'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, ModalBody, ModalHeader, UncontrolledDropdown } from 'reactstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { I18nContext } from '../../store/context/i18nContext'
import Modal from '../Common/Modal'
import CreateRoutePlanModal from './CreatePlanModal'
import { DialogContext } from '../../store/context/DialogContext'
import { formatDropTime, isPickerTypeEvent } from '../../pages/Rostering/utils/helper'
import { InputField } from '../InputField/InputField'
import TimeInput from '../DateSelects/TimeInput'
import moment from 'moment'



function RosteringHeader() {
    const { saveRoster, roster, office } = RosteringService
    const { userContent } = useContext(UserContext);
    const { showError } = useContext(DialogContext);
    const { t } = useContext(I18nContext);
    const [actionMenuOpen, setActionMenuOpen] = useState(false);
    const toggle = () => setActionMenuOpen((prevState) => !prevState);
    const [showPlanModal, setShowPlanModal] = useState(false);
    const spaceDetail = toJS(SpaceService.selectedFolder);

    const spaceType = useMemo(() => {
        switch (spaceDetail?.type) {
            case 2:
                return 'Request Pickup';
            case 3:
                return 'Request Drop';
            case 4:
                return 'Adhoc Pickup';
            case 5:
                return 'Adhoc Drop';
            default:
                return "";
        }
    }, [spaceDetail?.type])


    const onSave = async () => {
        const space = SpaceService.selectedFolder;
        if (!space?._id) {
            showError("Please select space");
            return;
        }
        const office = await OfficeService.get({ editId: space?.office_id, org_id: userContent?.corp?._id });
        await saveRoster({
            office: office?.address,
            ...(isPickerTypeEvent(space?.type) ? { reachBy: space?.dropTme } : { startTime: space?.pickUpTme }),
            type: space?.type,
            date:space?.fromDate
        });
    }
    
    return (
        <>
            <div className='d-flex align-items-center flex-column flex-md-row justify-content-between'>
                <div className='d-flex flex-column flex-md-row align-items-md-center'>
                    <Space showEverything={false} module="roster" showAsDropdown={true} />
                    <div className='d-flex gap-1 align-items-center'>
                        <InputField
                            placeholder={t("Office")}
                            value={office?.name}
                            label={t("Office")}
                            outline
                            type="text"
                            className="col-4"
                            isReadOnly
                        />
                        <InputField
                            placeholder={t("Roster type")}
                            value={spaceType}
                            label={t("Roster type")}
                            outline
                            type="text"
                            className="col-4"
                            isReadOnly
                        />
                        <InputField
                            placeholder={t(isPickerTypeEvent(spaceDetail?.type) ? "Drop Time" : "Pickup Time")}
                            value={isPickerTypeEvent(spaceDetail?.type) ? spaceDetail?.dropTme ? moment(spaceDetail?.dropTme, formatDropTime(spaceDetail?.dropTme)).format('hh:mm A') : '' : spaceDetail?.pickUpTme ? moment(spaceDetail?.pickUpTme, formatDropTime(spaceDetail?.pickUpTme)).format('hh:mm A') : ''}
                            label={t(isPickerTypeEvent(spaceDetail?.type) ? "Drop Time" : "Pickup Time")}
                            outline
                            type="text"
                            className="col-4"
                            isReadOnly
                        />
                    </div>
                </div>
                <div className='d-flex align-items-center gap-2'>
                    <CreateRoutePlanModal onSave={onSave} />
                </div>
            </div>
        </>
    )
}

export default observer(RosteringHeader)