
import { Form, Row, Card } from "reactstrap";
import { InputField } from "../../../components";
import { I18nContext } from "../../../store/context/i18nContext";
import { useContext } from "react";


const VehicleTypeCount = ({ values }) => {
    const { t } = useContext(I18nContext);
    return (
        <Card className="p-3" style={{ fontSize: "14px" }}>
            <div className="mb-3" style={{ fontWeight: "600" }}>
                {Object.entries(values || {})?.length > 0 ? "Vehicle Type Count" : ""}
            </div>
            {Object.entries(values || {}).map(([key, value]) => (
                <Row key={key} className="my-2">

                    <div className="d-flex mt-sm-0 mt-3">
                        <div style={{ width: "150px" }}>
                            {key}
                        </div>
                        <div>
                            {value}
                        </div>
                    </div>
                    {/* <InputField
                        label={key}
                        value={value}
                        type="text"
                        className="col-sm-6 mt-sm-0 mt-3"
                        isDisabled
                        plain
                    /> */}

                    {/* <InputField
                        label={t("Count")}
                        value={value}
                        type="number"
                        className="col-sm-6 mt-sm-0 mt-3"
                        isDisabled
                    /> */}
                </Row>
            ))}
        </Card>
    );
};

export default VehicleTypeCount;