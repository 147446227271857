import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../Constant";

class VendorSelect {
    vendors = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (props) {
        try {
            const response = await doGET(ENDPOINTS.getAllVendors);
            if (response.status === 200) {
                // this.vendors = response.data.map((V) => { return { value: V.key, label: V.value } })
                this.vendors = Object.keys(response.data).map((k) => { return { label: response.data[k], value: k } });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const VendorSelectService = new VendorSelect();
export default VendorSelectService;
