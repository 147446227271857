import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const StatusDropDown = ({ status, statusMappingData, data = {}, onChange, className,isDisabled=false }) => {
    const [hoverTextColor, setHoverTextColor] = useState(null);
    const [hoverBGColor, setHoverBGColor] = useState(null);


    const [statusInfo, setStatusInfo] = useState(statusMappingData[status || 0])

    useEffect(() => {
        setStatusInfo(statusMappingData[status])
    }, [status])

    const getStatusFilteredOptions = () => {
        return data[status] || [];
    };

    return (
        <div className={className}>
            <UncontrolledDropdown className="ms-2">
                <DropdownToggle
                    caret
                    outline={true}
                    disabled={isDisabled}
                    onMouseOver={() => {
                        setHoverTextColor("white");
                        setHoverBGColor(statusInfo?.color);
                    }}
                    onMouseOut={() => {
                        setHoverTextColor(null);
                        setHoverBGColor(null);
                    }}
                    color="#ced4da"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: `1px solid #ced4da`,
                        borderColor: "#ced4da",
                        color: hoverTextColor ? hoverTextColor : "black",
                        cursor: 'pointer',
                        borderRadius: "5px",
                        backgroundColor: hoverBGColor ? hoverBGColor : "white",
                        padding: "6px",
                        paddingTop: "3px",
                        paddingBottom: "3px"
                    }}
                >
                    <span className="dot me-2" style={{ backgroundColor: hoverTextColor ? hoverTextColor : statusInfo?.color, marginTop: '0px' }}></span>
                    <div className="me-2">{statusInfo?.text ?? ""}</div>
                </DropdownToggle>
                <DropdownMenu>
                    {getStatusFilteredOptions().map((item, statusIndex) => (
                        <DropdownItem id={item.value} onClick={onChange}>
                            <span className="dot" style={{ backgroundColor: item.color, marginTop: '0px' }}></span>   {item.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};

StatusDropDown.propTypes = {
    currentStatus: PropTypes.number,
    value: PropTypes.object,
    data: PropTypes.object,
};

export default StatusDropDown;
