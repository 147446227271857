import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Button, Card, } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import {
    Layout,
    Modal,
    CitySelect,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { STRUCTURE } from "./MarketPlaceConstant";
import MarketPlaceService from "./MarketPlaceService";
import Step1 from "./CompanyDetails/Step1";
import Step2 from "./CompanyDetails/Step2";
import RfpTabs from "./RfpTabs";
import { UserContext } from "../../store/context/UserContext";

const SupplierDashboard = (props) => {
    const { userContent } = useContext(UserContext);
    const { id } = useParams();
    const { showError } = useContext(DialogContext);
    const { t } = useContext(I18nContext);
    const [data, setData] = useState([{}]);
    const [showDetailPage, setShowDetailPage] = useState(props?.insidePane ? "" : id);
    const [loading, setLoading] = useState(false);
    const [selectedtab, setselectedTab] = useState("Suppliers")
    const [selectedSupplier, setSelectedSupplier] = useState([]);


    const fetchData = async (filterUrl) => {
        if (loading) return;
        setLoading(true);
        try {
            await MarketPlaceService.fetch(filterUrl);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };
    useEffect(() => {
        setData([{
            id: "#M0345",
            usertype: "Regular",
            totalpax: 800,
            vehcileType: "Hatchback",
            pickupline: "8:00 AM",
            tripType: "Outstation",
            expiryDate: "23-04-2023"

        },
        {
            id: "#M0345",
            usertype: "Regular",
            totalpax: 800,
            vehcileType: "Hatchback",
            pickupline: "8:00 AM",
            tripType: "Outstation",
            expiryDate: "23-04-2023"

        },
        {
            id: "#M0345",
            usertype: "Regular",
            totalpax: 800,
            vehcileType: "Hatchback",
            pickupline: "8:00 AM",
            tripType: "Outstation",
            expiryDate: "23-04-2023"

        }])
    }, []);

    let navigate = useNavigate();
    const supplierSteps = {
        1: <Step1 data={data} setData={setData} />,
        2: <Step2 data={data} setData={setData} />,

    }
    const [showform, setshowform] = useState(false)

    const [suplliermodal, setSuppliarModal] = useState(false)
    const labelArray = ['Step 1', 'Step 2']
    const [currentStep, updateCurrentStep] = useState(1);

    useEffect(() => {
        setSuppliarModal(true)
    }, [])

    function updateStep(step) {
        updateCurrentStep(step);

    }
    return (
        <>
            {suplliermodal ? (
                <Modal

                    modalSwitch={true}
                >
                    <div className=" d-flex justify-content-center">
                        <Card className=" client-card " style={{ width: "710px", height: "42vh !important" }}>
                            <h4 className='d-flex justify-content-center fa-2x fw-bold mt-5 mb-4 '>In which city you want to search for supplier ?</h4>
                            <div className="close-danger-btn">
                                <Button
                                    style={{ margin: "30px" }}
                                    color="danger"
                                    className="waves-effect waves-light __modal-close-btn"
                                    onClick={() => {
                                        setSuppliarModal(false)
                                    }}
                                >
                                    <i style={{ fontSize: "38px" }} className="bx bx-x" />
                                </Button>
                            </div>

                            <div className='mt-5 mb-5'>
                                <Row className=''>
                                    <Col className=' px-4 col-sm-12'>
                                        <div className=" m-auto">
                                            <CitySelect
                                                className='col-sm-8 m-auto'
                                                value={userContent?.city}
                                                state={userContent?.state}
                                                country={data?.country}
                                                onChange={(v) =>
                                                    setData({})

                                                }
                                            />
                                        </div>
                                    </Col>

                                </Row>
                            </div>

                            <div className=' m-auto mb-5  d-flex justify-content-center'>
                                <Button className="submit-btn" onClick={() =>
                                    navigate('/marketplace/supplier/dashboard')}>
                                    <span> Proceed</span>
                                </Button>

                            </div>
                        </Card >
                    </div>
                </Modal>
            ) : (
                <> </>
            )}


            {showform ? (
                <Modal
                    onChange={() => {

                        updateCurrentStep(1)
                        setshowform(false)
                    }}
                    modalSwitch={true}
                >
                    <div className=" d-flex justify-content-center">
                        <Card className=" client-card" style={{ width: "740px", height: "976px !important" }} >
                            <h4 className='d-flex justify-content-center fa-2x fw-bold mt-5 mb-4 '>Company Details</h4>
                            <div className="close-danger-btn">
                                <Button
                                    style={{ margin: "30px" }}
                                    color="danger"
                                    className="waves-effect waves-light __modal-close-btn"
                                    onClick={() => {
                                        updateCurrentStep(1)
                                        setshowform(false)
                                    }}
                                >
                                    <i style={{ fontSize: "38px" }} className="bx bx-x" />
                                </Button>
                            </div>

                            <div className='mt-4 mb-5'>
                                {supplierSteps[currentStep]}
                            </div>

                            <div className=' m-auto mb-5  d-flex justify-content-center'>

                                {currentStep === labelArray.length ?
                                    <Button className="submit-btn" >
                                        <span> Submit</span>
                                    </Button> :
                                    <button
                                        disabled={currentStep === labelArray.length} onClick={() => updateStep(currentStep + 1)}
                                        className='next-arrow-btn'>
                                        <i style={{ fontSize: "38px", color: 'white' }} className="dripicons-arrow-thin-right" />
                                    </button>}
                            </div>
                        </Card >
                    </div>
                </Modal>
            ) : (
                <> </>
            )}

            <div className=" d-flex flex-column">

                <div style={{ marginTop: "100px" }} className="d-flex justify-content-between">
                    <div className=" d-flex justify-content-lg-start px-4 ">
                        <div className=" marketplace-dashboard-header">
                            <RfpTabs selectedtab={selectedtab} setSuppliarModal={setSuppliarModal} setselectedTab={setselectedTab} />
                        </div>

                    </div>
                    <div className='px-4 d-flex justify-content-end py-3'>
                        <Button onClick={() => setshowform(true)} style={{
                            backgroundColor: "#DF6463", border: "none", borderRadius: "8px",
                            width: "120px",
                            height: "52px"
                        }}>
                            <div className=" d-flex justify-content-end mt-1">
                                <span>  <i style={{ fontSize: '25px' }} className="bx bx-plus" /> </span> <span className=" fw-bolder mb-2 py-1">Connect</span>
                            </div>

                        </Button>
                    </div>
                </div>

                {/* <div style={{ marginTop: "10px" }} className="d-flex justify-content-end">
                    <div className='px-4 d-flex justify-content-end '>
                        <Button onClick={() => setshowform(true)} style={{
                            backgroundColor: "#DF6463", border: "none", borderRadius: "8px",
                            width: "120px",
                            height: "52px"
                        }}>
                            <div className=" d-flex justify-content-end mt-2">
                                <span>  <i style={{ fontSize: '25px' }} className="bx bx-plus" /> </span> <span className=" fw-bolder mb-2">Connect</span>
                            </div>

                        </Button>
                    </div>
                </div> */}


                <div>
                    <Layout
                        showDetailPage={props?.popup ? undefined : showDetailPage}
                        backDetailPage={() => {
                            setShowDetailPage(false);
                            if (!props?.insidePane) navigate("/driver");
                        }}
                         
                        filterStructure={STRUCTURE}
                        onApplyFilter={fetchData}

                        page={MarketPlaceService.page}
                        rows={MarketPlaceService.rows}
                        total={MarketPlaceService.totalRecords}
                        insidePane={props?.insidePane}
                        onPageChange={async (page, rows) => {
                            await MarketPlaceService.onPaginationChange(page, rows);
                        }}
                    >

                        <Layout.TheadFull>
                            <Thead className="thead-dark">
                                <Tr>
                                    <Th >{t("")}</Th>
                                    < Th > {t("Name")}</Th>
                                    <Th >{t("Total Vehicle Count")}</Th>
                                    <Th className=" float-end" >{t("Action")}</Th>

                                </Tr>
                            </Thead>
                        </Layout.TheadFull>

                        <Layout.TbodyFull>
                            <Tbody>
                                {data?.map((supplier) => (
                                    <Tr>
                                        <Td scope="row">
                                            <input
                                                type="checkbox"
                                                className="select-tariff"
                                                style={{ height: "16px" }}
                                                checked={
                                                    selectedSupplier.findIndex((x) => x === supplier._id) !== -1
                                                }
                                                onChange={(e) => {
                                                    e.target.checked
                                                        ? setSelectedSupplier([...selectedSupplier, supplier._id])
                                                        : setSelectedSupplier(
                                                            selectedSupplier.filter((x) => x !== supplier._id)
                                                        );
                                                }}
                                            />
                                        </Td>
                                        <Td>{supplier?.id}</Td>
                                        <Td>{supplier?.userType}</Td>
                                        <Td>{supplier?.totalpax}</Td>


                                    </Tr>
                                ))}
                            </Tbody>
                        </Layout.TbodyFull>


                    </Layout>
                </div>
            </div>



        </>
    );
};

export default observer(SupplierDashboard);
