export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/message-template/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  get: (id) => `/api/message-template/${id}/detail`,
  save: `/api/message-template/create`,
  delete: (id) => `/api/message-template/${id}/delete`,
  update: `/api/message-template/update`,
  getmessagetype: `/api/message-template/types`
};


export const SELECTSTRUCTURE = {
  platform: [
    { label: "SMS", value: "SMS" },
    { label: "WHATSAPP", value: "WHATSAPP" },
  ],

}

export const STRUCTURE = [
  {
    label: "Platfrom",
    filterLabel: "Platfrom",
    filterName: "platfrom",
    type: "select",
    data: SELECTSTRUCTURE?.platform,
  },
  {
    label: "Corporate",
    filterLabel: "Corporate",
    filterName: "corp_id",
    type: "corpSelect",
  },
  {
    label: "Message Type",
    filterLabel: "messageType",
    filterName: "messageType",
    type: "text",
  },
  {
    label: "Content",
    filterLabel: "Content",
    filterName: "content",
    type: "text",
  },
  {
    label: "Language",
    filterLabel: "Language",
    filterName: "lang",
    type: "text",
  },


];

