import React, { useContext, useEffect, useState } from 'react'
import { CardThreeBounce, timestampToyyyy_mm_ddThh_mm } from '../../../components';
import { DialogContext } from '../../../store/context/DialogContext';
import { UserContext } from '../../../store/context/UserContext';
import ProfileService from '../ProfileService';

const Logs = ({ corpId, conf, auditlogs }) => {
    const { showError } = useContext(DialogContext);
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    // const fetchFunc = async () => {
    //     setLoading(true);
    //     try {
    //         const res = await ProfileService.getLogs(user?.businessUnit?.corp_id, conf);
    //         setDataToShow(res);
    //     } catch (error) {
    //         showError(error);
    //     }
    //     setLoading(false);
    // }

    // useEffect(() => {
    //     fetchFunc()
    // }, [])

    console.log(auditlogs, "sjsjk")

    return (
        <>
            <h4 className='text-center'>Change Logs</h4>
            {loading ? <CardThreeBounce /> : (
                <div>
                    <table className="table table-striped table-hover mt-3 w-100">
                        <thead>
                            <tr>
                                <th scope="col">By</th>
                                <th scope="col">Field</th>
                                <th scope="col">Old Value</th>
                                <th scope="col">Changed To</th>
                            </tr>
                        </thead>
                        <tbody>
                            {auditlogs?.map((e, i) => {
                                return <tr key={i} style={{ overflowX: 'scroll' }}>
                                    <td>{e?.by}
                                        <br />
                                        {timestampToyyyy_mm_ddThh_mm(e?.at)}</td>
                                    <td>{e?.changes?.map((v, idx) => {
                                        return <p key={idx}>{v?.field}</p>
                                    })}</td>
                                    <td style={{ wordBreak: 'break-all' }}>{e?.changes?.map((v, idx) => {
                                        return <p key={idx}>{v?.old}</p>
                                    })}</td>
                                    <td style={{ wordBreak: 'break-all' }}>{e?.changes?.map((v, idx) => {
                                        return <p key={idx}>{v?.modified}</p>
                                    })}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    )
}

export default Logs