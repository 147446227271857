import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, Modal, ModalComponent, SearchButton } from "../../index";
import useDebounce from "../../../util/Debounce";
import TransactionService from "../../../pages/Transaction/TransactionService";
import Employee from "../../../pages/Employee/Employee";

const ReferenceSelect = ({
  style,
  isDisabled,
  className,
  showClear,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  guide,
  driver,
  guard,
  sales,
  outline,
  asLabel,
  innerContainerClassName,
  placeholder,
  ...props
}) => {
  const [references, setReferences] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext);
  const { t } = useContext(I18nContext);

  const updateReferences = () => {
    const uniqueReferences = new Map();
    references.forEach(ref => uniqueReferences.set(ref.value, ref));
    toJS(TransactionService.records)?.forEach(dataObj => {
      const value = dataObj?.referenceNo;
      if (value && !uniqueReferences.has(value)) {
        uniqueReferences.set(value, {
          value,
          label: (
            <div className="d-flex align-items-center gap-1 justify-content-between h-100">
              <div>{value}</div>
            </div>
          ),
        });
      }
    });
    setReferences(Array.from(uniqueReferences.values()));
  };
  
  

  useEffect(() => {
    updateReferences();
  }, [TransactionService.records]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };


  //For future References  


  // const selectReference = (selectedValue) => {
  //   let selectedReference = toJS(selectedValue);
  //   const reference = references.find(reference => reference.value === selectedReference?.value);
  //   if (!reference) {
  //     TransactionService.records.push(selectedReference);
  //     updateReferences();
  //   }
  //   onChange({ ...selectedReference, value: selectedReference?.value });
  //   closeModal();
  // };

  // const selectReferences = (selectedValues) => {
  //   onChange(selectedValues.map((c) => c?.value ?? c));
  // };

  const fetchMoreData = () => {
    const page = TransactionService.page + 1;
    const rowsPerPage = TransactionService.rowsPerPage;
    const total = TransactionService.total;
    if (page > 0 && page <= (total % rowsPerPage === 0 ? (total / rowsPerPage) : (total / rowsPerPage) + 1)) {
      TransactionService.onPaginationChange(page, rowsPerPage);
    }
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage References")} />
      )}
      onMenuScrollToBottom={fetchMoreData}
      className={className}
      onChange={onChange}
      data={references}
      showClear={showClear}
      required={required}
      value={value}
      label={label || ((!isDisabled && !value) && t("Select References"))}
      error={!value ? t(`Please Select ${label || "Reference"}`) : ""}
      showErr={showErr}
      inline={inline}
      outline={outline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}

      {...props}
    />
  );

 

  return (
    <>
      {asLabel
        ? multi
          ? value?.length > 0
            ? <div>
                {value?.map((selectedValue, index) => {
                  const reference = references.find((reference) => reference.value === selectedValue);
                  return (
                    <span key={selectedValue}>
                      {reference ? reference.label : 'Unknown Reference'}
                      {index < value.length - 1 && ", "}
                    </span>
                  );
                })}
              </div>
            : <div style={{ color: "grey" }}>--</div>
          : <div>{references?.find((reference) => reference?.value === value)?.label ?? <div style={{ color: "grey" }}>--</div>}</div>
        : <>
            {references.length > 0
              ? multi
                ? renderSelectField({ disabled: false })
                : renderSelectField({ disabled: false })
              : renderSelectField({ disabled: true })}
          </>
      }
    </>
  );
};

export default observer(ReferenceSelect);

