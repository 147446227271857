import React, { useContext, useEffect, useState } from "react";
import { DeleteButton } from "react-bootstrap-table";
import {
    Button, Col,
    Form, Input, Label, Row
} from "reactstrap";
import {
    CountrySelect, InputField,
    SaveButton
} from "../../../components";
import { DialogContext } from "../../../store/context/DialogContext";
import SettingsService from "../SettingsService";

function SmsSetup() {
    const [loading, setLoading] = useState(false);
    const { showConfirm, showError } = useContext(DialogContext);
    const [data, setData] = useState([]);
    const [config, setConfig] = useState();
    const [hasErr, setHasErr] = useState(false);

    const fetchData = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const res = await SettingsService.getConfig();
            setConfig(res);
            setData(res.smsGateways);
            console.log("SmsSetup.js ", res.smsGateways);
            console.log("config ", res);
            setHasErr(false);
        } catch (e) {
            showError(e);
            // console.log(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleOnChange = (index, value, field, original) => {
        console.log(value);
        setData(
            data.map((curr, ind) => {
                if (index === ind) {
                    return { ...original, [field]: value };
                }
                return curr;
            })
        );
    };

    useEffect(() => {
        if (JSON.stringify(config?.smsGateways) !== JSON.stringify(data))
            setConfig({ ...config, smsGateways: data });
    }, [data]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            console.log("after: ", config);
            const res = await SettingsService.saveSms(config);
            console.log(res);
            fetchData();
            setLoading(false);
        } catch (error) {
            showError(error);
        }
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col
                        className="col-12 p-2 ps-4"
                        style={{
                            backgroundColor: "#EAEAEA",
                            fontSize: "15px",
                            borderBottom: "1px solid rgba(0,0,0,.1)",
                        }}
                    >
                        <header className="d-flex justify-content-between align-items-center">
                            <m>{`SMS Setups ( Note : Customer mobile and message value should be equal to $1 and $2 respectively )`}</m>
                            <m>
                                <Button
                                    type="button"
                                    className="btn ms-2 btn-primary"
                                    style={{ height: "32px", width: "32px", padding: "0px" }}
                                    onClick={() => {
                                        console.log("configAdd", config)
                                        setData([...data, {}]);
                                    }}
                                >
                                    <i style={{ fontSize: "30px" }} className="bx bx-plus"></i>
                                </Button>
                            </m>
                        </header>
                    </Col>

                    <Col className="col-12">
                        {data && data.map((e, ind) => {
                            return (
                                <Row className="mt-2">
                                    <Col className="col-md-8">
                                        <Row className="mt-2">
                                            <Col>
                                                <InputField
                                                    placeholder="eg. http://www.url.xxx/xx?field1=val1&mob=$1&msg=$2"
                                                    label={"URL"}

                                                    type="text"
                                                    value={e?.url ? e?.url : ""}
                                                    onChange={(v) => handleOnChange(ind, v, "url", e)}
                                                />
                                            </Col>
                                            <Col>
                                                <CountrySelect
                                                    isd
                                                    value={e?.country}
                                                    onChange={(v) => handleOnChange(ind, v?.isd, "country", e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-md-4">
                                        <Row className="mt-2">
                                            <Col>
                                                <Input
                                                    name="isDefault"
                                                    type="checkbox"
                                                    checked={e?.isDefault}
                                                    onClick={() =>
                                                        setData(
                                                            data.map((f, curr) => {
                                                                if (ind === curr)
                                                                    return {
                                                                        ...e,
                                                                        isDefault: !e?.isDefault
                                                                    };
                                                                return f;
                                                            })
                                                        )
                                                    }
                                                />
                                                <Label className="ps-2 pt-1" check for="isDefault">
                                                    Default
                                                </Label>
                                            </Col>
                                            <Col>
                                                <DeleteButton
                                                    onClick={() =>
                                                        setData(
                                                            data.filter(
                                                                (curr, x) => x !== ind
                                                            )
                                                        )
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            );
                        })}
                    </Col>
                    <Col>
                        <SaveButton />
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default SmsSetup;
