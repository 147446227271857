import { useCallback, useContext, useEffect } from "react";
import { BiMinus } from "react-icons/bi";
import { GoPlus } from "react-icons/go";
import { Col, Row } from "reactstrap";
import { InputField, TaxSelect } from "../../../components";
import AmountField from "../../../components/AmountField/AmountField";
import CurrencyInputField2 from "../../../components/InputField/CurrencyInputField2";
import { I18nContext } from "../../../store/context/i18nContext";
import ChargeName from "../../Booking/components/ChargeName";
import DiscountRow from "./DiscountRow";
import FeeCharges from "./FeeCharges";
import TaxItems from "./TaxItems";
import FeeTotal from "./FeeTotal";
import FeeGroupTaxSelector from "./FeeGroupTaxSelector";
import RemoveLink from "../../../components/Buttons/RemoveLink";

const LabelRow = ({ label, value }) => {
  return (
    <tr>
      <td> </td>
      <td> </td>
      <td>{label}</td>
      <td>
        {value}
      </td>
      <td></td>
    </tr>
  )
}

const FeeGroup = ({
  keyIndex,
  feeGroup,
  onChange,
  type = "",
  formConfig = {
    showMinimumFields: false,
    diablePartiesSelect: false,
    diableAddDeleteFeeGroup: false,
    diableUserInputForFee: false,
  },
  rateNames
}) => {
  const { t } = useContext(I18nContext);

  const updateFeeGroup = useCallback(
    (fieldName, fieldValue) => {
      if (fieldName && fieldValue) {
        let feeG = { ...feeGroup } || {
          charges: [{}],
          discounts: [{}],
          taxItem: {
            taxes: [{}],
          },
        };
        feeG[fieldName] = fieldValue;
        if (
          fieldName === "charges" ||
          fieldName === "discounts" ||
          fieldName === "taxItem"
        ) {
          feeG = handleCalculations(feeG);
        }
        onChange(feeG);
      }
    },
    [feeGroup, onChange]
  );

  const handleCalculations = (feeG) => {
    if (feeG) {
      const subTotal =
        feeG?.charges?.reduce(
          (acc, charge) => acc + (charge?.amount || 0),
          0
        ) || 0;
      const totalDiscounts =
        feeG?.discounts?.reduce(
          (acc, discount) => acc + (discount?.amount || 0),
          0
        ) || 0;
      const totalTaxes =
        feeG?.taxItem?.taxes?.reduce((acc, taxItem) => {
          const percent = taxItem?.percent;
          const baseAmount = taxItem?.baseAmount ?? 0;
          if (!percent && !baseAmount) {
            return acc;
          }
          if (!percent) {
            return acc + baseAmount;
          }
          return (
            acc + (((subTotal - totalDiscounts) * percent) / 10000 + baseAmount)
          );
        }, 0) || 0;
      feeG.subTotal = subTotal;
      feeG.totalDiscounts = totalDiscounts;
      feeG.totalTaxes = totalTaxes;
      feeG.total = subTotal - totalDiscounts + totalTaxes;
    }
    return feeG;
  };

  const updateCharges = useCallback(
    ({ chargeIndex, key, v }) => {
      const updatedCharges = feeGroup?.charges?.map((charge, index) => {
        if (chargeIndex !== index) {
          return charge;
        }
        const updatedCharge = { ...charge, [key]: v };
        if (key === "unitCost" && charge["units"]) {
          updatedCharge["amount"] = v * charge["units"];
        } else if (key === "units" && charge["unitCost"]) {
          updatedCharge["amount"] = v * charge["unitCost"];
        } else if (key !== "amount" && key !== "detail") {
          updatedCharge["amount"] = 0;
        }
        updatedCharge["amount"] = Number(((updatedCharge["amount"] ?? 0) / 100).toFixed(2));
        return updatedCharge;
      });
      updateFeeGroup("charges", updatedCharges);
    },
    [feeGroup, updateFeeGroup]
  );

  const updateDiscounts = useCallback(
    ({ discountIndex, key, v }) => {
      const updatedDiscounts = feeGroup?.discounts?.map((discount, index) => {
        if (discountIndex !== index) {
          return discount;
        }
        const dis = { ...discount } || {};
        dis[key] = v;
        return dis;
      });
      updateFeeGroup("discounts", updatedDiscounts);
    },
    [feeGroup, updateFeeGroup]
  );

  const removeCharge = useCallback(
    (chargeIndex) => {
      const charges = feeGroup.charges.filter(
        (_, cIndex) => cIndex !== chargeIndex
      );
      updateFeeGroup("charges", charges);
    },
    [feeGroup, updateFeeGroup]
  );

  const removeDiscount = useCallback(
    (discountIndex) => {
      const charges = feeGroup?.discounts?.filter(
        (_, cIndex) => cIndex !== discountIndex
      );
      updateFeeGroup("discounts", charges);
    },
    [feeGroup, updateFeeGroup]
  );

  const addCharge = useCallback(() => {
    if (!feeGroup?.charges) {
      updateFeeGroup("charges", [{ isManual: true, extraCharges: true }]);
    } else {
      updateFeeGroup("charges", [...feeGroup?.charges, { isManual: true, extraCharges: true, units: 0 }]);
    }
  }, [feeGroup, updateFeeGroup]);

  const addDiscount = useCallback(() => {
    if (!feeGroup?.discounts) {
      updateFeeGroup("discounts", [{}]);
    } else {
      updateFeeGroup("discounts", [...feeGroup?.discounts, {}]);
    }
  }, [feeGroup, updateFeeGroup]);

  const getGroupChargesAndDiscountSum = useCallback(() => {
    let charges = 0;
    let discounts = 0;
    feeGroup?.charges?.forEach((charge) => {
      if (charge?.amount) {
        charges += charge?.amount;
      }
    });
    feeGroup?.discounts?.forEach((discount) => {
      if (discount?.amount) {
        discounts += discount?.amount;
      }
    });
    return { charges, discounts };
  }, [feeGroup]);

  const handleTaxSelectChange = (taxItem) => {
    if (!taxItem) {
      return;
    }
    const taxI = { ...feeGroup?.taxItem } || { tax_id: "", taxes: [{}] };
    taxI.tax_id = taxItem?.value;
    const { charges, discounts } = getGroupChargesAndDiscountSum();
    taxI.taxes =
      taxItem?.items?.map((item) => ({
        ...item,
        amount: (item?.percent * (charges - discounts)) / 10000,
      })) || [];
    updateFeeGroup("taxItem", taxI);
  }

  return (
    <div key={keyIndex}>
      {!(type === "Client" || type == "Supplier") && (
        <Row>
          <Col xl={6} style={{ marginLeft: "9px" }}>
            <InputField
              isDisabled={formConfig?.diableUserInputForFee}
              required
              placeholder="Fee Group Name"
              label="Name"
              value={feeGroup?.name}
              onChange={(v) => updateFeeGroup("name", v)}
            />
          </Col>
        </Row>
      )}
      {feeGroup.name === 'ZERO TAX' &&
        <div className="tag-item-inline" style={{ color: "#fff", backgroundColor: `#a2a2a2`, fontSize: 12, fontWeight: 500, width: "fit-content" }}>
          Non Taxable Items
        </div>
      }
      <table className=" ">
        <thead>
          <tr>
            <th style={{ width: "35%" }}>Charge Type <font color="red">*</font></th>
            <th style={{ width: "25%" }}>Unit Cost <font color="red">*</font> </th>
            <th style={{ width: "20%" }}>No. of Units <font color="red">*</font></th>
            <th style={{ width: "15%" }}>Amount</th>
            <th style={{ width: "10%" }}>Action</th>
          </tr>
        </thead>
        <tbody className="">
          <FeeCharges
            charges={feeGroup?.charges}
            updateCharges={updateCharges}
            removeCharge={removeCharge}
            addCharge={addCharge}
            formConfig={formConfig}
            editAble={true}
            rateNames={rateNames}
          />

          <DiscountRow
            subTotal={feeGroup?.subTotal}
            discounts={feeGroup?.discounts}
            updateDiscounts={updateDiscounts}
            removeDiscount={removeDiscount}
            addDiscount={addDiscount}
            formConfig={formConfig}
            editAble={true}
          />
          <LabelRow label="Total Deduction" value={feeGroup?.totalDiscounts ? feeGroup?.totalDiscounts / 100 : 0} />

          {keyIndex !== 1 &&
            <LabelRow label="Taxable Amount" value={feeGroup?.subTotal
              ? ((feeGroup?.subTotal - feeGroup?.totalDiscounts ?? 0) / 100).toFixed(2)
              : 0} />
          }
          {keyIndex !== 1 && <FeeGroupTaxSelector
            isDisabled={formConfig?.diableUserInputForFee}
            handleTaxSelectChange={handleTaxSelectChange}
            value={feeGroup?.taxItem?.tax_id}
          />}
          {feeGroup?.taxItem?.taxes ?
            <TaxItems taxItem={feeGroup?.taxItem} />
            : null}
          <FeeTotal total={feeGroup?.total} totalTaxes={feeGroup?.totalTaxes} />
        </tbody>
      </table>

    </div>
  );
};
export default FeeGroup;
