import React, { useState } from 'react'
import moment from 'moment'
import { InputField } from '../../InputField/InputField';

const MonthPicker = ({

    onChange = () => { },
    value,
    className,
    required,
    label,
    plain,
    isDisabled,
    showErr,
    inline,
    outline,
    style,
    borderNone,
    RightComponent,
    autoFocus,
    onClickRight = () => { },
    noLabel,
    wrapperStyle
}) => {
    const currentMonth = moment().format('YYYY-MM');
    const handleMonthChange = (v) => {
        const month = moment(v).format('YYYYMM');
        onChange(month);
    }

    return (
        <div style={wrapperStyle}>

            <InputField
                className={className}
        
                value={currentMonth}
                required={required}
                label={label}
                isDisabled={isDisabled}
                onChange={(v) => {
                    handleMonthChange(v);
                }}
                error={value?.length === 0 ? "Please Choose a Valid Date" : ""}
                showErr={showErr}
                plain={plain}
                type="month"
                defaultValue={currentMonth}
                inline={inline}
                outline={outline}
                borderNone={borderNone}
                RightComponent={RightComponent}
                onClickRight={onClickRight}
                autoFocus={autoFocus}
                noLabel={noLabel}
            />

        </div>

    )
}

export default MonthPicker
