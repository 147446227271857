import React, { useContext, useEffect, useState } from 'react'
import { EmployeeSelect, MakeModelSelect, SaveButton } from '../../components'
import { toJS } from 'mobx';
import TimesheetService from '../Timesheet/TimesheetService';
import { DialogContext } from '../../store/context/DialogContext'; 
import { I18nContext } from '../../store/context/i18nContext';

function DriverAssignTimesheet({vehicle}) {
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState(null);
    const {showMessage,showError}=useContext(DialogContext);
    const { t } = useContext(I18nContext);
 
   
    const fetchTimeSheet=async()=>{
        try {
            setLoading(true)
            const response=await TimesheetService.fetch(`vehicle_id=${vehicle?._id}`)

           const filteredTimeSheet=response?.data?.rows?.find(t=>!t?.startDate)
           if(!filteredTimeSheet ){
            setData(response?.length?response[0]:[])
           }else{
            setData(filteredTimeSheet)
           }
           
            
        } catch (error) {
            
        }finally{
            setLoading(false)
        }
    }

    
    useEffect(()=>{
        if(vehicle?._id){
            fetchTimeSheet();
        }
    },[vehicle?._id])

    const onSave=async()=>{
try {
    if(data?._id){
        await TimesheetService.edit(data, data?._id);
        showMessage(t("Timesheet updated successfully."));
    }else{
        const leaveID = await TimesheetService.save({...data,vehicle_id:vehicle?._id});
        console.log(leaveID) 
        showMessage(t("Timesheet saved successfully."));
    }
    fetchTimeSheet()
} catch (error) {
    showError(error)
}
    }

  return (
    <div style={{height:450}} className='d-flex flex-column justify-content-between py-4 px-4 '>

          <div>
              <div className='text-center w-100 mx-auto d-flex flex-column'>
              <div className='text-center fs-5 mb-2'>{vehicle?.regNo}</div>
                <div className='mx-auto fs-5 fw-bold mb-3'>
                 <MakeModelSelect
                      asLabel
                      value={vehicle?.makeModel}
                      onChange={(v) => { }}
                  />
                </div>  
                  {" "}
                

              </div>

              <EmployeeSelect
                  label={"Driver"}
                  driver
                  outline
                  value={data?.driverDuties?.[0]?.employee_id}
                  onChange={(v) => {
                      const currentDriverDuties = data?.driverDuties ?? []
                      const updatedcurrentDriverDuties = { ...currentDriverDuties[0], employee_id: v.value }
                      currentDriverDuties[0] = updatedcurrentDriverDuties
                      console.log(toJS(currentDriverDuties))
                      setData(prevData => ({ ...prevData, driverDuties: currentDriverDuties }))
                  }}
              />
          </div>
                      <SaveButton
                loading={loading}
                onClick={onSave} />
    </div>
  )
}

export default DriverAssignTimesheet