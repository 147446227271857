import React from 'react'
import "./styles.css"
import { ThreeBounce } from 'better-react-spinkit'
import { Card, CardBody, Row } from 'reactstrap'

function LoadingView({ loading, children }) {
    return (
        <>
            {loading ? <div className='d-flex align-items-center justify-content-center'>
                <ThreeBounce size={20} color="#5b73e8" />
            </div> :  children }
        </>
    )
}

export default LoadingView