import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { ModalComponent } from '../../components';
import Editor from '../../components/dnd/forms/itinerary/components/RichTextEditor/Editor/Editor';
import { DialogContext } from '../../store/context/DialogContext';
import { UserContext } from "../../store/context/UserContext";
import { doGET, doPOST } from '../../util/HttpUtil';

import EmailContent from './displays/DisplayEmailContent';
import Tokenizer from '../../components/InputField/TokenInput';
import { ThreeBounce } from 'better-react-spinkit';

const BulkEmail = ({ onBack, customers = [] }) => {
    const [data, setData] = useState({
        subject: '',
        extraContent: '',
        content: '',
        to: customers.map(c => c.email)
    });

    const { showError, showMessage } = useContext(DialogContext);
    const { webContent } = useContext(UserContext);
    const [bodyModal, setBodyModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [headerContent, setHeaderContent] = useState(null);
    const [footerContent, setFooterContent] = useState(null);
    const [printContent, setPrintContent] = useState(`
        <!DOCTYPE HTML>
        <html>
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
            <title>Bulk Email</title>
            <style>
                @page {
                    size: A4;
                    margin-top: 20px;
                    margin-bottom: 10px;
                }
                .page-footer {
                    position: fixed;
                    bottom: 10px;
                    right: 10px;
                    background-color: white;
                    padding: 5px;
                    border: 1px solid #ccc;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                }
            </style>
        </head>
        <body style="padding: 20px; padding-top:2px">
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"
            integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL"
            crossorigin="anonymous"></script>
        <table id="table-data" style="width: 100%;">
    `);

    const toggleModal = () => setBodyModal(!bodyModal);

    const handleInputChange = (field, value) => {
        if (field === "extraContent") {
            const updatedBody = `${printContent}<div style="width: 600px; margin-left: auto; margin-right: auto; padding:10px 10px 10px 0px">${value}</div>${footerContent}</body></html>`;
            setData(prev => ({
                ...prev,
                extraContent: value,
                body: updatedBody
            }));
        } else {
            setData(prev => ({ ...prev, [field]: value }));
        }
    };

    const getHeaderFooter = async () => {
        let helpline;
        try {
            const response = await doGET("/api/corporate-config/detail/helpLines");
            if (response?.status === 200 && response?.data?.helpLines?.length) {
                const helplineObj = response?.data?.helpLines?.find(v => v?.isDefault) || response?.data?.helpLines[0];
                helpline = helplineObj?.customerHelpline;
            }
        } catch (error) {
            showError(error);
        }
        try {
            const headerResponse = await doPOST('/print-server/email/header', {
                VendorCompanyName: webContent?.fullName ?? webContent?.name,
                logo: `https://${window.location.host}${webContent?.logo}`
            });
            const footerResponse = await doPOST('/print-server/email/footer', {
                helpLine: helpline,
                tnc: "This is an automated email, please do not reply to this email. For any queries related to services please contact our helpline."
            });
            setHeaderContent(headerResponse?.data);
            setFooterContent(footerResponse?.data);
            setPrintContent(printContent + headerResponse?.data);

            const updatedBody = `${printContent}${headerResponse?.data}${footerResponse?.data}</body></html>`;
            setData(prev => ({
                ...prev,
                body: updatedBody
            }));
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        getHeaderFooter();
    }, []);

    const handleSendMail = async () => {
        if (!data?.subject) {
            showError("Subject is missing");
            return;
        }

        if (!data?.to?.length) {
            showError("Recipients are missing");
            return;
        }

        if (data?.extraContent === "<p><br></p>" || data?.extraContent === "") {
            showError("Email Body is missing.");
            return;
        }
        setLoading(true)
        try {
            const updatedData = { ...data, type: "email", messageType: "TRIP_VEHICLE_ASSIGNED_EMPLOYEE" };
            const response = await doPOST("/api/email/create?unique=y", updatedData);
            if (response?.status === 200) {
                showMessage("Email Sent Successfully");
                onBack();
            }
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false)
        }
    };

    return (
        <div className='zr_communication_compose'>

            <div className="my-3">
                <Tokenizer
                    label="Recipients"
                    value={data.to}
                    onChange={(newTokens) => setData(prevData => ({ ...prevData, to: newTokens }))}
                    placeholder="Add recipient emails"
                />
            </div>

            <div className="mb-3">
                <Input
                    type="text"
                    placeholder="Subject"
                    value={data.subject}
                    onChange={(e) => handleInputChange('subject', e.target.value)}
                />
            </div>

            <div className='mb-4'>
                <EmailContent height="calc(100vh - 400px)" maxHeight="calc(100vh - 200px)" onAdd={toggleModal} content={data.body} />
            </div>

            <div className="mt-3 d-flex justify-content-end">
                <Button color="success" onClick={handleSendMail} className="ms-2">{loading ? <><ThreeBounce color="#fff" /></> : <> Send <i className="fab fa-telegram-plane ms-1"></i></>}</Button>
            </div>
            <ModalComponent
                position={"top"}
                size={"full-screen"}
                isOpen={bodyModal}
                onToggle={toggleModal}
                centered
            >
                <ModalComponent.Title toggle={toggleModal}>Add Content</ModalComponent.Title>
                <ModalComponent.Body>
                    <Editor
                        label=""
                        placeholder="Content"
                        value={data?.extraContent}
                        onChange={(htmlContent) => handleInputChange('extraContent', htmlContent)}
                    />
                </ModalComponent.Body>
                <ModalComponent.Footer>
                    <Button color="primary" onClick={toggleModal}>Save</Button>
                </ModalComponent.Footer>
            </ModalComponent>
        </div>
    );
};

export default observer(BulkEmail);
