import { CorporateSelect } from "../../components";
import CustomTooltip from "../Booking/components/CustomTooltip";

export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Corporate",
      accessor: "corp_id",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.corp_id!=null?
          <b>
              <CorporateSelect
                asLabel
                value={dataObj?.corp_id}
                className="col-sm-4"
                style={{  zIndex: '4' }}
              />
          </b> :
          <b>All</b>
          }
          </div>;
      },
      show: true,
    },
    {
      title: "Domain",
      accessor: "corp_id",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.corp_id!=null?
          <b>
              <CorporateSelect
                asDomain
                value={dataObj?.corp_id}
                className="col-sm-4"
                style={{  zIndex: '4' }}
              />
          </b> :
          <b>All</b>
          }
          </div>;
      },
      show: true,
    },
    {
      title: "Status",
      accessor: "status",
      renderer: (dataObj) => {
        return (
          <div className="d-flex align-items-end">
            {dataObj?.status == 0 || dataObj?.status === undefined ? (
              <div>
                <i
                  id="not-clickable-isBooker-Inactive"
                  className="fas fa-circle font-size-12 mt-1"
                  style={{ color: 'red' }}
                />
              </div>
            ) : (
              <div>
                <i
                  id="not-clickable-isBooker-active"
                  className="fas fa-check-circle font-size-12"
                  style={{ color: 'green' }}
                />
              </div>
            )}
          </div>
        )
      },
      show: true,
    },
    {
      title: "SMS",
      accessor: "smsURL",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.smsURL!=null?  
          <i
            className="mdi mdi-checkbox-marked font-size-20 mx-2"
            style={{ zIndex: "999" }}
          />
          : <i
            className="mdi mdi-checkbox-blank-outline font-size-20 mx-2"
            style={{ zIndex: "999" }}
          />}
          </div>;
      },
      show: true,
    },
    {
        title: "EMAIL",
        accessor: "smtpCredentials",
        renderer: (dataObj) => {
          return <div>{dataObj?.smtpCredentials?.smtpEmail!=null?
            <i
            className="mdi mdi-checkbox-marked font-size-20 mx-2"
            style={{ zIndex: "999" }}
          />
          : <i
            className="mdi mdi-checkbox-blank-outline font-size-20 mx-2"
            style={{ zIndex: "999" }}
          />}</div>;
        },
        show: true,
      },
    {
        title: "WHATSAPP",
        accessor: "whatsAppCredentials",
        renderer: (dataObj) => {
          return <div>{dataObj?.whatsAppCredentials?.whatsAppApiRequestUrl!=null?
            <i
            className="mdi mdi-checkbox-marked font-size-20 mx-2"
            style={{ zIndex: "999" }}
          />
          : <i
            className="mdi mdi-checkbox-blank-outline font-size-20 mx-2"
            style={{ zIndex: "999" }}
          />}
          </div>;
        },
        show: true,
      },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
