import moment from "moment/moment";
let currentyear = moment().year();
let yearsArray = [];
let seatTypeSelect = [];

for (let i = currentyear; i >= 1990; i--) {
  yearsArray.push({ label: i, value: i });
}

for (let i = 1; i <= 45; i++) {
  seatTypeSelect.push({ label: i, value: i });
}

export const ENDPOINTS = {
  grid: () => {
    let url = `/api/vehicle/grid?`;
    return url;
  },
  filter: (page, sizePerPage, filterUrl) => {
    let url = ``;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },

  gridInfinite: (filterUrl) => {
    let url = `/api/vehicle/grid?`;
    if (filterUrl) url += filterUrl;
    return url;
  },
  vendorMap: `/api/corp/vendors/self/map`,
  vehicleMap: `/api/o/corp/vehicleType/map`,
  zoneMap: `/api/zone/map/list`,
  countryMap: `/api/o/country/all`,
  saveAttachment: (vehicleID) =>
    `/api/vehicle/${vehicleID}/sold/attachment/upload`,
  updateZone: (vehicleID, zoneID) =>
    `/api/vehicle/${vehicleID}/status/${zoneID}`,
  updateDriver: (vehicleID, driverID) =>
    `/api/vehicle/${vehicleID}/driver/${driverID}/assign`,
  removeDriver: (vehicleID) => `/api/vehicle/${vehicleID}/driver/unassign`,
  get: (vehicleID) => `/api/vehicle/${vehicleID}/detail`,
  save: `/api/vehicle/create`,
  update: `/api/vehicle/update`,
  delete: (id) => `/api/vehicle/${id}/delete`,
  bulkdelete: "/api/vehicle/bulk/delete",
  download: (filterUrl, template_id) => {
    let url = `/api/vehicles/download2?template_id=${template_id}&`;
    if (filterUrl) url += filterUrl;
    return url;
  },
  bulkDelete: (id) => `/api/vehicle/delete`,

  picUpload: (id) => `/api/vehicle/${id}/picture/save`,
};
const ColorBar = ({ color }) => {
  return <div style={{ widht: "100%", backgroundColor: color, color: "white" }} >{color}</div>
}
const SELECTSTRUCTURE = {
  altfuel: [
    { label: "PETROL", value: "PETROL" },
    { label: "CNG", value: "CNG" },
    { label: "DIESEL", value: "DIESEL" },
    { label: "ELECTRIC", value: "ELECTRIC" },
  ],
  fuel: [
    { label: "PETROL", value: "PETROL" },
    { label: "CNG", value: "CNG" },
    { label: "DIESEL", value: "DIESEL" },
    { label: "ELECTRIC", value: "ELECTRIC" },
  ],
  distanceUnit: [
    { label: "KM", value: "KM" },
    { label: "MILE", value: "MILE" },
  ],
  driverSide: [
    { label: "Right Drive", value: 1 },
    { label: "Left Drive", value: 2 },
  ],
  status: [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 2 },
    { label: "Under Maintanence", value: 3 },
    { label: "Sold", value: 4 },
    { label: "Dispatched", value: 5 },
    { label: "On Duty", value: 6 },
    { label: "Off Duty", value: 7 },
  ],
  complianceStatus: [
    { label: "Compliant", value: 1 },
    { label: "Not Compliant", value: 2 },
    { label: "Due", value: 3 },
  ],

  transmission: [
    { label: "Manual", value: 1 },
    { label: "Automatic", value: 2 },
    { label: "Auto Pilot", value: 3 },
  ],
  colorsArray: [
    { value: "#E74C3C", label: <ColorBar color="#E74C3C" /> },
    { value: "#3498DB", label: <ColorBar color="#3498DB" /> },
    { value: "#2ECC71", label: <ColorBar color="#2ECC71" /> },
    { value: "#9B59B6", label: <ColorBar color="#9B59B6" /> },
    { value: "#F1C40F", label: <ColorBar color="#F1C40F" /> },
    { value: "#1ABC9C", label: <ColorBar color="#1ABC9C" /> },
    { value: "#D35400", label: <ColorBar color="#D35400" /> },
    { value: "#34495E", label: <ColorBar color="#34495E" /> },
    { value: "#95A5A6", label: <ColorBar color="#95A5A6" /> },
    { value: "#E67E22", label: <ColorBar color="#E67E22" /> },
    { value: "#16A085", label: <ColorBar color="#16A085" /> },
    { value: "#F39C12", label: <ColorBar color="#F39C12" /> },
    { value: "#2980B9", label: <ColorBar color="#2980B9" /> },
    { value: "#8E44AD", label: <ColorBar color="#8E44AD" /> },
    { value: "#27AE60", label: <ColorBar color="#27AE60" /> }
  ]
  ,
  GPS: [
    { label: "Fleetx", value: "Fleetx" },
    { label: "GTrac", value: "GTrac" },
    { label: "FTrack", value: "FTrack" },
    { label: "Loconav", value: "Loconav" },
    { label: "Map My India", value: "Map My India" },
    { label: "GPSDesk", value: "GPSDesk" },
    { label: "Arya Omnitalk", value: "Arya Omnitalk" },
  ],
  years: yearsArray,
  seatTypes: seatTypeSelect,
  carColorArray: [
    { value: "White", label: "White" },
    { value: "Grey", label: "Grey" },
    { value: "Blue", label: "Blue" },
    { value: "Black", label: "Black" },
    { value: "Yellow", label: "Yellow" },
    { value: "Red", label: "Red" },
    { value: "Brown", label: "Brown" },
    { value: "Silver", label: "Silver" }
  ]
};


export const STRUCTURE = [
  // {
  //   label: "Fuel Type",
  //   filterLabel: "Fuel Type",
  //   filterName: "fuelType",
  //   type: "select",
  //   data: SELECTSTRUCTURE?.fuel
  // },
  {
    label: "Status",
    filterLabel: "Status",
    filterName: "status",
    type: "select",
    data: SELECTSTRUCTURE?.status
  },
  {
    label: "RegNo",
    filterLabel: "Vehicle RegNo",
    filterName: "regNo",
    type: "text",
  },
  {
    label: "Vehicle Type",
    filterLabel: "Vehicle Type",
    filterName: "vehicleType",
    type: "vehicleTypeSelect",
  },
  {
    label: "Make Model",
    filterLabel: "Make Model",
    filterName: "makeModel",
    type: "makeModelSelect",
  },

];

export const vehiclePermitData = [
  { label: "RC", value: "RC" },
  { label: "PUC", value: "PUC" },
  { label: "Insaurance", value: "Insaurance" },
  { label: "MCD", value: "MCD" }
]



export default SELECTSTRUCTURE;


