import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditSupervisors from "./EditSupervisors";
import { STRUCTURE } from "./SupervisorConstant";
import { GridColumns } from "./SupervisorGridColumns";
import Supervisorservice from "./SupervisorService";

const Supervisors = ({ value,
    insidePane,
    multiMode,
    onSelect = () => { },
    corp_id,
    hideTitleBar,
    breadcrumb,
    hideFilterBar,
    dontShowCheck = false,
    updateHasSupervisors = () => { },


}) => {

    let { supervisor_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const { userContent } = useContext(UserContext);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(supervisor_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/supervisor/create" || supervisor_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)



    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            const rows = await Supervisorservice.fetch(filterUrl);
            setLoading(false);

            updateHasSupervisors(rows?.length ? true : false)

        } catch (e) {
            showError(e);
            setLoading(false);

        }
    };

    useEffect(() => {
        let { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        if (corp_id) {
            filterObject = { ...filterObject, corp_id: corp_id };
            filterUrl += "corp_id=" + corp_id;
        }
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }, [])


    useEffect(() => {
        if (!insidePane) setEditID(supervisor_id)

    }, [supervisor_id])

    const isValidEmail = (email) => {
        const emailRegex = /^(?=.{1,256})(?=.{1,64}@.{1,255}$)[!#$%&'*+\-/=?^_`{|}~a-zA-Z0-9]+([.-][!#$%&'*+\-/=?^_`{|}~a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*(\.[a-zA-Z]{2,})$/;
        return emailRegex.test(email);
    };



    const onSave = async (e) => {
        e.preventDefault();
        // if (!detailData.supervisorExtn?.roles?.length || detailData.supervisorExtn?.roles?.length === 0) {
        //     setHasErr(true)
        //     showError(t("Please enter Roles"));
        //     return;
        // }
        if (!isValidEmail(detailData?.email)) {
            setHasErr(true);
            showError(t("Please enter a valid email"));
            return;
        }
        setLoading(true);
        try {
            if (editID) {
                await Supervisorservice.edit({ data: detailData, corp_id });
                setLoading(false);
                showMessage(t("Supervisor updated successfully."));
                await fetchData(filterURL);
                if (!insidePane) navigate(`/supervisor/edit/${editID}`);
            } else {
                var supervisorDetailData = detailData;
                if (corp_id) supervisorDetailData = { ...detailData, adminOf: corp_id }
                const supervisor = await Supervisorservice?.save({
                    data: supervisorDetailData, corp_id: corp_id
                });
                setLoading(false);
                if (supervisor) setEditID(supervisor)
                setLoading(false);
                showMessage(t("Supervisor saved successfully."));
                await fetchData(filterURL);
                if (!insidePane) navigate(`/supervisor/edit/${supervisor}`);
            }
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
        setHasErr(false)

    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);

            try {
                await Supervisorservice.delete(id, corp_id);
                setLoading(false);

                setShowDetailPage(false);
                showMessage(t("Supervisor Deleted Successfully"), t("Deleted"));
                if (!corp_id) {
                    navigate(`/supervisor`);
                }
            } catch (e) {
                showError(e);
                setLoading(false);

            }
        }
    };
    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);

            try {
                await Supervisorservice.bulkDelete(selectedIDs);
                setSelectedIDs([])

                setLoading(false);

                showMessage(t("Deleted"), t("Deleted Successfully"));
            } catch (e) {
                showError(e);
                setLoading(false);

            }
        }
    };

    const renderLastCol = useCallback((supervisor) => {
        return (
            <>
                {userContent?.rights?.includes(2102) &&
                    <EditLink
                        onClick={() => {
                            if (!insidePane)
                                navigate(`/supervisor/edit/${supervisor?._id}`);
                            setEditID(supervisor?._id);
                            setShowDetailPage(true);
                        }}
                    />
                }
                {userContent?.rights?.includes(2103) ?
                    <DeleteLink
                        onClick={(event) => onDelete(event, supervisor?._id)}
                    />
                    : null}

                {!multiMode && insidePane && !dontShowCheck ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(supervisor?._id);
                        }}
                    />
                ) : null}
            </>
        );
    }, [insidePane, multiMode, userContent])
    return (
        <>
            <Layout
                medium
                hideAdd={!userContent?.rights?.includes(2101)}
                showDetailPage={showDetailPage}
                backDetailPage={() => {
                    setShowDetailPage(false);
                    if (!insidePane) navigate("/supervisor");
                    setEditID(null);
                }}
                breadcrumb={breadcrumb}
                 
                title={t("Supervisors")}
                filterValues={filterObject}
                filterStructure={STRUCTURE}
                onApplyFilter={fetchData}
                onAddClick={() => {
                    if (!insidePane) navigate(`/supervisor/create`);
                    setShowDetailPage(true);
                    setEditID(null);
                }}
                insidePane={insidePane}
                page={Supervisorservice.page}
                rows={Supervisorservice.rows}
                total={Supervisorservice.total}
                fetch={Supervisorservice.fetch}
                hideTitleBar={hideTitleBar}
                hideSearch={hideFilterBar}
            >
                <Layout.ActionMenu>
                    <div className="layout-action-menu">
                        <DropdownMenu>
                            <>
                                {userContent?.rights?.includes(2103) ?
                                    <DropdownItem onClick={() => { onBulkDelete() }}>{t("Bulk Delete")}</DropdownItem>
                                    : null}
                                                    <div class="dropdown-divider"></div>
                                <DropdownItem>{t("Upload")}</DropdownItem>
                                <div class="dropdown-divider"></div>
                                <DropdownItem> {t("Download")} </DropdownItem>
                            </>
                        </DropdownMenu>
                    </div>
                </Layout.ActionMenu>
                <Layout.Table>
                    <DataGrid
              gridLoading={loading}
                        data={Supervisorservice.records}
                        total={Supervisorservice.total}
                        uiPreference="Supervisor.grid"
                        headers={GridColumns}
                        selectedIDs={selectedIDs}
                        onSelectChange={(v) => {
                            onSelect(v)
                            setSelectedIDs(v)
                        }}
                        page={Supervisorservice.page}
                        rowsPerPage={Supervisorservice.rowsPerPage}
                        onPaginationChange={Supervisorservice.onPaginationChange}
                        renderLastCol={renderLastCol}
                    />
                </Layout.Table>
                <Layout.DetailPageTitle>
                    {detailData?._id ? t("Edit Supervisor") : t("Add Supervisor")}
                </Layout.DetailPageTitle>
                <Layout.DetailPageBody>
                    <EditSupervisors
                        editId={editID}
                        onChange={(v) => {
                            setDetailData(v)
                        }}
                        corp_id={corp_id}
                        hasErr={hasErr}
                    />
                </Layout.DetailPageBody>

                <Layout.DetailPageFooter>
                    {userContent?.rights?.includes(2103) && detailData?._id ? (
                        <DeleteButton loading={loading} onClick={(e) => onDelete(e, detailData?._id)} />
                    ) : null}
                    {userContent?.rights?.includes(2102) ?
                        <SaveButton loading={loading} onClick={onSave} />
                        : null}
                </Layout.DetailPageFooter>
            </Layout>
        </>
    );
};
export default observer(Supervisors);
