export const  validateTripsForInvoice = ({ trips }) => {
    if (!trips || trips.length === 0) {
      return { isValid: false, message: "No trips provided" };
    }

    const firstTrip = trips[0];

    const { vendorCompany_id: referenceVendorCompanyId, client: referenceClient } = firstTrip;
    const referenceClientId = referenceClient?.client_id;
    const referenceCompanyId = referenceClient?.company_id;

    for (const trip of trips) {
      const { vendorCompany_id, client, status, tripNo } = trip;
      const clientId = client?.client_id;
      const companyId = client?.company_id;

      if (status !== 1 && status !== 2 && status !== 3 && status !== 4 && status !== 5) {
        return { isValid: false, message: `Trip #${tripNo} is not completed yet` };
      }

    //   if (vendorCompany_id !== referenceVendorCompanyId) {
    //     return { isValid: false, message: "Vendor Company ID does not match" };
    //   }

      if (clientId !== referenceClientId) {
        return { isValid: false, message: "Client ID does not match" };
      }

    //   if (companyId !== referenceCompanyId) {
    //     return { isValid: false, message: "Company ID does not match" };
    //   }
    }

    return { isValid: true };
  };


export const getAddressFromObj = (dataAddress) => {

    // const dataAddress = data?.InvoiceConfig?.vendorInfo?.address;
    let newAddress = ``


    if (dataAddress?.addressLine) {
        newAddress += dataAddress?.addressLine
    }
    if (dataAddress?.city && dataAddress?.addressLine) {
        newAddress += `, `
    }
    if (dataAddress?.city) {
        newAddress += dataAddress?.city
    }
    if (dataAddress?.city && dataAddress?.state) {
        newAddress += `, `
    }
    if (dataAddress?.state) {
        newAddress += dataAddress?.state
    }

    if (dataAddress?.country && dataAddress?.state) {
        newAddress += `, `
    }
    if (dataAddress?.country) {
        newAddress += dataAddress?.country
    }


    if (dataAddress?.country && dataAddress?.pinCode) {
        newAddress += `, `
    }
    if (dataAddress?.pinCode) {
        newAddress += dataAddress?.pinCode
    }

    // newAddress += `</div>`
    return newAddress
}

export const getTimeUnitAndValue=(label)=>{
  switch (label) {
    case "Due on Receipt":
      
      break;
  
    default:
      break;
  }
}