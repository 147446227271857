import React from "react";
import { useNavigate } from "react-router-dom";

const CopyLink = (props) => {
  let navigate = useNavigate();
  return (
    <div
      className={props?.className}
      style={{ display: "inline-block", color: "warning", cursor: "pointer" }}
      onClick={() => {
        if (props?.to) navigate(props?.to);
        props?.onClick();
      }}
    >
      <i
        style={{ color: "warning" }}
        className="fas fa-copy text-dark font-size-18"
      />
    </div>
  );
};
export default CopyLink;
