import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import Card from "react-bootstrap/Card";
import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  UncontrolledAccordion,
  AccordionHeader,
  AccordionItem,
  AccordionBody,
  CardHeader,
  CardBody,
} from "reactstrap";
import DatePickerComponent from "../../components/Calender/Calender";
import {
  Checkbox,
  CurrencySelect,
  ClientSelect,
  CompanySelect,
  DateTimeSelect,
  TimeSelect,
  InputField,
  VehicleSelect,
  SelectField,
  AddressField,
  PhoneNum,
  ZoneSelect,
  WorkShiftSelect,
  CustomFieldComponent,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "./Trip_RouteConstant";
import Trip_RouteService from "./Trip_RouteService";
// import Passengers from "./Passengers";
// import BookedBy from "./BookedBy";
import Attendants from "./Attendants";
import "react-calendar/dist/Calendar.css";
import ClientCompany from "../../components/Dropdowns/ClientCompany/ClientCompany";
import AssignVehicle from "../Booking/components/AssignVehicleDriver";
import AssignMultipleVehicle from "../Booking/components/AssignMultipleVehicleDriver";
import SearchUser from "../Users/SearchUser";
import "./style.css";
import Passengers from "../Booking/Passengers";
import BookedBy from "../Booking/BookedBy";
import moment from "moment";
import MultiDatePickerCalendar from "../../components/MultiDatePickerComponent/MultiDatePickerCalendar";
import { KIND_DATA } from "./RecurringTripConstant";
import { property } from "lodash";
import { CUSTOMEFIELDS_KIND_DATA } from "../../components/CustomField/CustomFieldsConstants";
import RemoveLink from "../../components/Buttons/RemoveLink";
import AddLink from "../../components/Buttons/AddLink";

const EditTrip_Routes = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const fetch = async () => {
    try {
      if (editId) {
        let paymentGateway = await Trip_RouteService.get(editId);
        setData(paymentGateway);
      } else {
        let tempRoute = {
          client: {},
          status: 1,
          runsOn: {
            runningDays: [],
          },
          stoppages: [
            {
              address: {},
            },
          ],
          passengers: [
            {
              phone: {},
            },
          ],
          transports: [
            {
              vehicle: {},
              driver: {},
            },
          ],
          attendants: [
            {
              // phone: {},
            },
          ],
          routeExtn: {
            properties: [
              {
                field_id: "",
                value: "",
              },
            ],
          },
        };
        setData(tempRoute);
      }
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    setData(null);
    setLoading(true);
    fetch();
    setLoading(false);
  }, [editId, Trip_RouteService?.version]);

  useEffect(() => {
    onChange(data);
  }, [data]);


  const addStoppage = () => {
    const updatedStoppages = [...data?.stoppages, { address: {} }];
    setData({ ...data, stoppages: updatedStoppages });
  };

  const deleteStoppage = (index) => {
    const updatedStoppages = [...data?.stoppages];
    updatedStoppages.splice(index, 1);
    setData({ ...data, stoppages: updatedStoppages });
  };
  return (
    <React.Fragment>
      {loading ? (
        <ThreeBounce />
      ) : (
        <Form
          className="p-3 pb-0"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="m-1">
            <Col className="col-2 ">
              <Checkbox
                label={t("Return Trip")}
                className="col- my-1  align-content-end"
                checked={data?.returning ?? false}
                onClick={() => {
                  setData({ ...data, returning: !data?.returning });
                }}
              />
            </Col>
            <Col className="col-2 ">
              <Checkbox
                label={t("Shuttle")}
                className="col- my-1  align-content-end"
                checked={data?.runsOn?.isShuttle ?? false}
                onClick={() => {
                  setData({
                    ...data,
                    runsOn: {
                      ...data?.runsOn,
                      isShuttle: !data?.runsOn?.isShuttle,
                    },
                  });
                }}
              />
            </Col>
            <Col className="col-2 ">
              <Checkbox
                label={t("Guarded")}
                className="col- my-1  align-content-end"
                checked={data?.isGuarded ?? false}
                onClick={() => {
                  setData({ ...data, isGuarded: !data?.isGuarded });
                }}
              />
            </Col>
            <Col className="col-3 ">
              <Checkbox
                label={t("Self Drive")}
                className="col- my-1  align-content-end"
                checked={data?.selfDrive ?? false}
                onClick={() => {
                  setData({ ...data, selfDrive: !data?.selfDrive });
                }}
              />
            </Col>
            <Col className="col-3 ">
              <Checkbox
                label={t("Active")}
                className="col-2 my-1 me-2 align-content-end"
                checked={
                  data?.status === 1 ? false : data?.status === 2 ? true : false
                }
                onClick={() => {
                  setData({
                    ...data,
                    status:
                      data?.status === 1 ? 2 : data?.status === 2 ? 1 : null,
                  });
                }}
              />
            </Col>
          </Row>
          <Card className="ps-2 pe-2 mb-2">
            <Row className="mb-1">
              <Col>
                <InputField
                  outline
                  label={t("Title")}
                  type="text"
                  value={data?.title}
                  onChange={(e) => {
                    setData({ ...data, title: e });
                  }}
                  placeholder={t("Title")}
                /></Col>
              <Col>
                <InputField
                  outline
                  label={t("Route No.")}
                  type="text"
                  value={data?.routeNo}
                  onChange={(e) => {
                    setData({ ...data, routeNo: e });
                  }}
                  placeholder={t("Route No.")}
                /></Col>
              <Col>
                <ClientCompany
                  outline
                  value={data?.client?.company_id}
                  onChange={(v) => {
                    setData({
                      ...data,
                      client: {
                        ...data?.client,
                        client_id: v?.client_id,
                        company_id: v?._id,
                        name: v?.groupLabel,
                      },
                    });
                  }}
                />
              </Col>

            </Row>
            <Row className="d-flex align-items-center">
              <Col>
                <CompanySelect
                  outline
                  corpType="supplier"
                  value={data?.vendorCompany_id}
                  onChange={(v) => {
                    setData({ ...data, vendorCompany_id: v?.value });
                  }}
                  label="Serving Company"
                  l
                /></Col>
              <Col>
                <SelectField
                  outline
                  data={SELECTSTRUCTURE.kind}
                  value={data?.kind}
                  onChange={(v) => {
                    setData({ ...data, kind: v.value });
                  }}
                  showErr={hasErr}
                  label={t("Type")}
                />
              </Col>
              <Col>
                <ZoneSelect
                  outline
                  label={"Zone"}
                  value={data?.zone_id}
                  onChange={(v) => {
                    setData({ ...data, zone_id: v.value });
                  }}
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center">
              <Col>
                <WorkShiftSelect
                  outline
                  value={data?.shift_id}
                  onChange={(v) => {
                    setData({ ...data, shift_id: v.value });
                  }}
                  label={"Shift"}
                />
              </Col>
              <Col>
                <MultiDatePickerCalendar
                  label="Running Days"
                  minDate={moment().toDate()}
                  value={data?.runsOn?.runningDays}
                  onChange={(v) => {
                    setData({
                      ...data,
                      runsOn: {
                        ...data?.runsOn,
                        runningDays: v
                      }
                    });
                  }}
                />
              </Col>
              <Col>
                <TimeSelect
                  placeholder={t("Start Time")}
                  required={true}
                  outline
                  label={t("Runs At")}
                  value={data?.runsOn?.runAt}
                  onChange={(v) => {
                    setData({
                      ...data,
                      runsOn: {
                        ...data?.runsOn,
                        runAt: Number(v)
                      }
                    });
                  }}
                />
              </Col>
            </Row>
          </Card>
          <Card className="p-3 py-1 mb-1">
            {(data?.stoppages ?? [])?.map((stoppage, index) => (
              <div key={index} className=" d-flex align-items-center">
                <div className="d-flex flex-1 col-8 align-items-center">
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      border: (index !== 0 && index != data?.stoppages?.length - 1) ? "1.5px solid grey" : "",
                      borderRadius: index !== data?.stoppages?.length - 1 ? '50%' : '0%',
                      backgroundColor: index == 0 ? "green" : index == data?.stoppages?.length - 1 ? "red" : '',
                      marginRight: '7px',
                    }}
                  ></div>
                  <AddressField
                    className="flex-1"
                    outline
                    placeholder={t("Address")}
                    label={t(`Stoppage ${index + 1}`)}
                    value={stoppage?.address}
                    onChange={(e) => {
                      const updatedStoppages = [...(data?.stoppages || [])];
                      updatedStoppages[index] = {
                        ...updatedStoppages[index],
                        address: e,
                      };
                      setData({
                        ...data,
                        stoppages: updatedStoppages,
                      });
                    }}
                    error={
                      stoppage?.address &&
                        Object.keys(stoppage.address).length === 0
                        ? t("Please enter address of the Stoppage")
                        : ""
                    }
                    showErr={hasErr}
                  />
                </div>
                <div className="d-flex col-4 align-items-center">
                  <TimeSelect
                    outline
                    className="me-2"
                    label={t("Pickup Time")}
                    value={stoppage?.pickupTime}
                    onChange={(v) => {
                      const updatedStoppages = [...(data?.stoppages || [])];
                      updatedStoppages[index] = {
                        ...updatedStoppages[index],
                        pickupTime: parseInt(v),
                      };
                      setData({
                        ...data,
                        stoppages: updatedStoppages,
                        routeExtn: {
                          duration: data?.stoppages[data?.stoppages?.length - 1]?.dropTime - data?.stoppages[0]?.pickupTime
                        }
                      });
                    }}
                  />
                  <TimeSelect
                    outline
                    className="me-2"
                    label={t("Drop Time")}
                    value={stoppage?.dropTime}
                    onChange={(v) => {
                      const updatedStoppages = [...(data?.stoppages || [])];
                      updatedStoppages[index] = {
                        ...updatedStoppages[index],
                        dropTime: parseInt(v),
                      };
                      setData({
                        ...data,
                        stoppages: updatedStoppages,
                        routeExtn: {
                          duration: data?.stoppages[data?.stoppages?.length - 1]?.dropTime - data?.stoppages[0]?.pickupTime
                        }
                      });
                    }}
                  />
                  <div className="col-1 d-flex justify-content-end">
                    {index == 0 && <AddLink className="ms-1" onClick={addStoppage} />}
                    {(index !== 0 && index !== data?.stoppages?.length - 1) &&
                      <RemoveLink className="ms-1" onClick={() => deleteStoppage(index)} />
                    }
                  </div>
                </div>

              </div>
            ))}
          </Card>
          <Passengers
            passengers={data?.passengers}
            onChange={(v) =>
              setData({
                ...data,
                passengers: v,
              })
            }
          />
          <BookedBy
            className="py-1"
            emptyDivStyle={{
              width: "190px"
            }}
            value={data?.bookedBy}
            onChange={(v) => { setData({ ...data, "bookedBy": v }) }}
          />
          <Attendants
            attendants={data?.attendants}
            onChange={(v) => {
              setData({ ...data, attendants: v });
            }}
          />
          <Card className="pt-0">
            <CardHeader>
              <div
                className="d-flex justify-content-between"
                style={{ width: "inherit" }}
              >
                <strong>{t(`Assigned Vehicle`)}</strong>
              </div>
            </CardHeader>
            <CardBody className="py-1">
              {(data?.transports ?? [])?.map((transport, index) => (
                <AssignMultipleVehicle
                  length={data?.transports?.length}
                  onPlusClick={() =>
                    setData({
                      ...data,
                      transports: [
                        ...data?.transports,
                        {
                          vehicle: {},
                          driver: {},
                        },
                      ],
                    })}
                  deleteRow={() => {
                    setData(prevData => ({
                      ...prevData,
                      transports: prevData?.transports?.filter((_, i) => i !== index)
                    }))
                  }}
                  driver={transport?.driver}
                  vehicle={transport?.vehicle}
                  onChangeVehicle={(v) => {
                    const updatedTransports = [...data?.transports];
                    updatedTransports[index] = {
                      ...updatedTransports[index],
                      vehicle: v,
                    };
                    setData({
                      ...data,
                      transports: updatedTransports,
                    });
                  }}
                  index={index}
                  onChangeDriver={(v) => {
                    const updatedTransports = [...data?.transports];
                    updatedTransports[index] = {
                      ...updatedTransports[index],
                      driver: v,
                    };
                    setData({
                      ...data,
                      transports: updatedTransports,
                    });
                  }}
                />
              ))}
            </CardBody>
          </Card>
          {editId &&
            <Card className="mb-0">
              <CustomFieldComponent
                module={"Route"}
                record_id={editId}
                kind={CUSTOMEFIELDS_KIND_DATA.Route}
                onChange={(v) =>
                  setData({
                    ...data,
                    routeExtn: {
                      ...data?.routeExtn,
                      properties: v,
                    },
                  })
                }
              />
            </Card>
          }
        </Form>
      )
      }
    </React.Fragment >
  );
};

export default observer(EditTrip_Routes);
