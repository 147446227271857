import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import EmployeeSupervisorLabelService from './EmployeeSupervisorLabelService';
import { ThreeBounce } from "better-react-spinkit";
import './EmployeeSupervisorLabel.css'; // Import the CSS file

const EmployeeSupervisorLabel = ({ value,fontSize }) => {
  // console.log(value,"-0987u890-09iu")
  const [employee_supervisorLabel, setemployee_supervisorLabel] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategory = async () => {
      setLoading(true);
      try {
        const employee_supervisor = await  EmployeeSupervisorLabelService.fetchEmployeeSupervisor(value);
        if (employee_supervisor && employee_supervisor.label) {
            setemployee_supervisorLabel(employee_supervisor.label);
        } else {
            setemployee_supervisorLabel('No Record Found');
        }
      } catch (error) {
        setemployee_supervisorLabel('No Record Found');
      }
      setLoading(false);
    };

    if (value) {
      fetchCategory();
    } else {
        setemployee_supervisorLabel('No Record Found');
      setLoading(false);
    }
  }, [value]);

  if (loading) {
    return <ThreeBounce size={10} color="pink" className="zr_category-loader" />;
  }

  return (
    <div className={`zr_category-label  ${employee_supervisorLabel === 'No Record Found' ? 'disabled' : ''} fs-${fontSize}`}>
      {employee_supervisorLabel}
    </div>
  );
};

export default observer(EmployeeSupervisorLabel);
