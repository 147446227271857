import { observer } from "mobx-react-lite";
import React, {useContext, useEffect, useState } from "react";

import { Form } from "reactstrap";

import { DialogContext } from "../../store/context/DialogContext";
import CorporateService from "./CorporateService";
import Details from "./Tabs/Details";
import CorporateConf from "./Tabs/CorporateConf";
import Tab from "../../components/Common/Tab/Tab";
import DisabledFields from "./Tabs/Disabledfields";

const EditCorporates = (props) => {
  const [loading, setLoading] = useState(false);
  const {showMessage, showError } = useContext(DialogContext);
  const [step, setStep] = useState(1);
  const { id, data, setData, showErr, setShowErr } = props;

  async function fetch() {
    if (id) {
      const corporate = await CorporateService.get(id);
      setData(corporate);
    } else {
      setData({});
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    fetch();
    setShowErr(false);
  }, [id]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setShowErr(true);
    if (!data.unique_id) return;
    try {
      if (id) await CorporateService.edit(data, id);
      showMessage("info","saved Successfully");
      props?.fetchData();
      fetch();
    } catch (error) {
      // showMessage(
      //   "error",
      //   JSON.parse(error.message).customMessage,
      //   "Duplicate"
      // );
      showError(error);
    }
  };
  return (
    <div>
      <div className="p-0">
        <Form className="p-2">
          <Tab
            vertical
            left
            activeTab={step}
            onChange={(data) => {
              setStep(data);
            }}
            className="d-flex"
          >
            <div num={1} label="Corporates Details">
              <Details
                className="bg-primary color-fff"
                data={data}
                setData={setData}
                submitHandler={submitHandler}
              />
            </div>
            <div num={2} label="Corporates Configurations">
              <CorporateConf
                className="bg-primary color-fff"
                data={data}
                setData={setData}
              />
            </div>
            <div num={3} label="Disabled Fields">
              <DisabledFields
                className="bg-primary color-fff"
                data={data}
                setData={setData}
              />
            </div>
          </Tab>
        </Form>
      </div>
    </div>
  );
};

export default observer(EditCorporates);
