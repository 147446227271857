import { makeAutoObservable } from "mobx";
import { doGET } from "../../util/HttpUtil";


class Service {
    isMapVisible = false;
    markers = [];
    center = [];
    path = [];
    isMapLoadedFirstTime = false
    selectedMarker = null;
    module = null; // vehicle / trip / employee
    vehicleId = null; // 
    tripId = null; // 
    socketTripId = null; // for disconnecting socket leave room
    socketVehicleIds = []; // for disconnecting socket leave room
    tripCache = []
    inboxCount = 0;

    constructor() {
        makeAutoObservable(this);
    }

    setMarkers = function (marker) {
        this.markers = marker;
    }

    setPath = function (path) {
        this.path = path;
    }
    
    setCenter = function (center) {
        this.center = center;
    }
    
    setSelectedMarkers = function (marker) {
        this.selectedMarker = marker;
    }

    setModule = function (value) {
        this.selectedMarker =  null;
        this.markers = [];
        this.path = [];
        this.module = value;
    }

    setVehicleId = function (value) {
        this.vehicleId = value;
    }

    setTripId = function (value) {
    
        this.tripId = value;
    }

    setSocketTripId = function (value) {
        this.socketTripId = value;
    }

    setSocketVehicleIds = function (value) {
        this.socketVehicleIds = value;
    }

    toggleMap = function () {
        if (!this?.isMapLoadedFirstTime){
            this.isMapLoadedFirstTime = true
        }
        this.isMapVisible = !this.isMapVisible;
    }

    fetchTrips = async function (props) {
        if (this.tripCache.length) {
            return this.tripCache
        }

        try {
            const response = await doGET("/api/trip/grid?status=in[3,4]&rows=-1");
            if (response.status === 200) {
                this.tripCache = response?.data?.rows;
                return response?.data?.rows;
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };

    fetchInboxCount = async function () {
        try {
            const response = await doGET("/api/inbox/count");
            if (response.status === 200) {
                this.inboxCount = response?.data;
                return response?.data;
            }
            this.inboxCount = 0;
            return 0;
        } catch (err) {
            this.inboxCount = 0;
        }
    };


}

const SideBarService = new Service();
export default (SideBarService);
