import React, { useContext, useEffect, useState } from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Col,
  Row,
  UncontrolledAccordion,
} from "reactstrap";
import { InputField } from "../InputField/InputField";
import CommentService from "./CommentService";
import { DialogContext } from "../../store/context/DialogContext";
import { SelectFieldMultiple } from "../SelectField/SelectFieldMultiple";
import { toJS } from "mobx";
import { UserContext } from "../../store/context/UserContext";
const Comments = ({
  recordType = "invoice",
  recordOf = "65112c3d9ff39b6df1e13dc6",
}) => {
  const [loading, setLoading] = useState(false);
  const { showError, showMessage } = useContext(DialogContext);
  const [data, setData] = useState({
    forType: [],
  });
  const [fetchedData, setFetchedData] = useState([]);
  const { userContent } = useContext(UserContext);
  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await CommentService.fetch(recordType, recordOf);
      setFetchedData(toJS(CommentService?.records));
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(userContent);
    fetchData();
  }, []);

  const onSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await CommentService.save(data);
      showMessage("Record saved successfully.");
      setData({});
      fetchData();
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };
  const getByType = (val) => {
    //1: Customer, 2: Driver, 3: Attendant, 4: Booker, 5: Admin, 6: System
    if (val == 1) return "Customer";
    else if (val == 2) return "Driver";
    else if (val == 3) return "Attendant";
    else if (val == 4) return "Booker";
    else if (val == 5) return "Admin";
    else return "System";
  };

  const ForType = [
    {
      label: "Pax",
      value: 1,
    },
    {
      label: "Driver",
      value: 2,
    },
    {
      label: "Attendant",
      value: 3,
    },
    {
      label: "Booker",
      value: 4,
    },
    {
      label: "Admin",
      value: 5,
    },
  ];
  function unixTimestampToTime(timestamp) {
    const date = new Date(timestamp * 1000);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    return formattedTime;
  }
  return (
    <Card
      style={{
        top: "10vh",
        margin: "5px",
        padding: "15px",
        maxHeight: "88vh",
      }}
    >
      <UncontrolledAccordion className="mt-3 py-2" defaultOpen="0">
        <AccordionItem>
          <AccordionHeader targetId="1">Comments</AccordionHeader>
          <AccordionBody accordionId="1" className="mb-2">
            <div
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "64vh",
                marginBottom: "35px",
              }}
            >
              <Row className="justify-content-center ">
                <Col
                  xs="auto"
                  style={{
                    borderRadius: "8px",
                    background: "#f8f9fa",
                    fontSize: "medium",
                  }}
                >
                  Today
                </Col>
              </Row>
              <Col className="mt-3">
                {fetchedData?.map((ele, idx) => (
                  <>
                    {ele?.by == userContent?.supervisor_id ? (
                      <Card
                        className="my-chat px-4 py-2"
                        style={{
                          background: "#f8f9fa",
                          width: "fit-content",
                          right: "-83%",
                        }}
                      >
                        <Col>
                          <strong>{userContent?.name}</strong>
                          <span style={{ marginLeft: "10px" }}>
                            {unixTimestampToTime(ele?.at)}
                          </span>
                        </Col>
                        <Col className="mt-2">{ele?.content?.remark}</Col>
                      </Card>
                    ) : (
                      <Card
                        className="other-person-chat px-4 py-2"
                        style={{
                          background: "rgba(91,115,232,.1)",
                          width: "fit-content",
                        }}
                      >
                        <Col>
                          <strong>{getByType(ele?.byType)}</strong>
                          <span style={{ marginLeft: "10px" }}>
                            {unixTimestampToTime(ele?.at)}
                          </span>
                        </Col>
                        <Col className="mt-2">{ele?.content?.remark}</Col>
                      </Card>
                    )}
                  </>
                ))}
              </Col>
            </div>
            <Row
              className="p-2"
              style={{ position: "absolute", bottom: "15px", width: "94vw" }}
            >
              <hr />
              <SelectFieldMultiple
                data={ForType}
                className="col-sm-4"
                label={"Select For"}
                placeholder={"Select For"}
                value={ForType.filter(
                  (c) => data?.forType?.findIndex((x) => x === c.value) !== -1
                )}
                onChange={(v) => {
                  console.log(v?.map((v) => v?.value));
                  setData({
                    ...data,
                    forType: v?.map((v) => v?.value),
                  });
                }}
              />
              <InputField
                className="col-sm-6"
                label={"Message.."}
                value={data?.content?.remark}
                onChange={(v) =>
                  setData({
                    ...data,
                    content: {
                      ...data?.content,
                      remark: v,
                    },
                    recordOf: recordOf,
                    recordType: recordType,
                  })
                }
              />
              <Button
                style={{
                  background: "#5b73e8",
                  height: "fit-content",
                  padding: "10px",
                }}
                className="col-sm-1 mt-2"
                onClick={onSave}
              >
                Send
                <i className="mdi mdi-send float-end"></i>
              </Button>
            </Row>
          </AccordionBody>
        </AccordionItem>
      </UncontrolledAccordion>
    </Card>
  );
};

export default Comments;
