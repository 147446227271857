import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { itemBarSelectedStyle, Layout, timeStampToyyyy_mm_ddThhmm } from "../../../components";
import { STRUCTURE } from "./ReportConstant";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import ReportService from "./ReportService";

const getProperDate = (dateStr) => {
  if (dateStr) {
    const y = dateStr.slice(0, 4);
    const m = dateStr.slice(5, 7);
    const d = dateStr.slice(8, 10);
    const t = dateStr.slice(11, dateStr.length);
    const date = d + '-' + m + '-' + y + ' : ' + t;
    return date;
  }
  else return '';
}

const getProperTime = (dateStr) => {
  if (dateStr) {
    const t = dateStr.slice(11, dateStr.length);
    return t;
  }
  return ''
}

const Report = (props) => {
  const { t } = useContext(I18nContext);
  const { id } = useParams();
  const { showError } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  //   const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await ReportService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getStatus = (num) => {
    if (num === 0) {
      return <div className='text-danger'>Pickup Pending</div>
    }
    else if (num === 2) {
      return <div className='text-info'>No Show</div>
    }
    else if (num === 3) {
      return <div className='text-success'>Travelled</div>
    }
    else {
      return (<></>)
    }
  }


  return (
    <Layout
       
      title={t("Trip Report")}
      filterStructure={STRUCTURE}
      onApplyFilter={fetchData}
      page={ReportService.page}
      rows={ReportService.rows}
      total={ReportService.totalRecords}
      onPageChange={async (page, rows) => {
        await ReportService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("Title")}</Th>
            <Th>{t("Time")}</Th>
            <Th>{t("Passenger Details")}</Th>
            <Th>{t("Passenger Status")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {ReportService.records?.map((rep) => (
            <Tr key={rep?._id} style={id === rep?._id ? itemBarSelectedStyle : {}}>
              <Td style={{ cursor: 'pointer' }} onClick>
                <b>
                  {rep?.bookingNo}
                </b>
              </Td>
              <Td className='d-flex flex-column flex-sm-row' style={{ width: 'max-content' }}>
                <span className='me-1'>
                  <i className='fa fa-calendar text-success mb-3 me-1' /> {' '} {getProperDate(timeStampToyyyy_mm_ddThhmm(rep?.startedAt))}
                </span>
                <span className='ms-1'>
                  <i className='fa fa-calendar text-danger mb-3 me-1' /> {' '} {getProperDate(timeStampToyyyy_mm_ddThhmm(rep?.stoppedAt))}
                </span>
                {/* {rep?.destination ? <span><i className='fa fa-map-marker text-warning mb-3 me-1' />{rep?.destination?.addressLine}</span>  : ' '} */}
              </Td>
              <Td>
                {
                  rep?.passengers?.map((e) => {
                    return (
                      <div key={e?.user_id} className='d-flex justify-content-between flex-column flex-sm-row'>
                        <span className='mx-2' style={{ flex: '1' }}>
                          <i className='uil uil-user me-1' style={{ fontSize: '13px' }} /> {e?.name}
                        </span>
                        <span className='mx-2' style={{ flex: '1' }}>
                          <i className='fa fa-map-marker text-success me-1' style={{ fontSize: '13px' }} />{e?.pickup?.addressLine ? e?.pickup?.addressLine < 27 ? e?.pickup?.addressLine : e?.pickup?.addressLine.slice(0, 28) + '...' : ' '}
                        </span>
                        <span className='mx-2' style={{ flex: '1' }}>
                          <i className='fa fa-map-marker text-warning me-1' style={{ fontSize: '13px' }} />{e?.drop?.addressLine ? e?.drop?.addressLine.length < 27 ? e?.drop?.addressLine : e?.drop?.addressLine.slice(0, 28) + '...' : ' '}
                        </span>
                      </div>
                    )
                  })
                }
              </Td>
              <Td className='float-start w-100'>
                {
                  rep?.passengers?.map((e) => {
                    return <div key={e?.user_id}>{getStatus(e?.status)}</div>
                  })
                }
              </Td>
              <Td>{''}</Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>
    </Layout>
  );
};

export default observer(Report);
