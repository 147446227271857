import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { IoIosSend } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from "reactstrap";
import { DeleteButton, InputField, SaveButton, SelectField } from "../../components";
import CityCountrySelectAirport from "../../components/AddressField/CityCountrySelectAirport";
import HourMinutePicker from "../../components/DateSelects/HourMinutePicker";
import DateSelectCustom from "../../components/DateTimePickerWithInterval/DateSelectCustom";
import ReminderButton from "../../components/Reminders/ReminderButton";
import TagPicker from "../../components/TagSelector/TagPicker";
import Fee from "../../components/dnd/forms/itinerary/components/SupplierFare/Fee";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import PassengerSelect from "../Booking/PassengerSelect";
import GroupTourSidebarService from "../GroupTour/sideBar/GroupTourSidebarService";
import { SELECTSTRUCTURE } from "./FlightConstant";
import FlightService from "./FlightService";
import { FaPlane } from 'react-icons/fa';
import { FaExchangeAlt } from "react-icons/fa";
import ActionMenu from "../../components/Dropdowns/ActionMenu/ActionMenu";
import ChangeSpaceModal from "../GroupTour/sideBar/changeSpaceModal/ChangeSpaceModal";
import TimeLine from "../../components/Common/TimeLine";
import DateTimePickerWithInterval from "../../components/DateTimePickerWithInterval";
import moment from "moment";
import { joinToUnixTimestamp } from "./helper";


const EditFlights = ({
  editId,
  onSave,
  onDelete,
  insidePane,
  onBack,
  prePopulatedData = null,
  formConfig = {
    showPassengers: true
  }
}) => {
  let navigate = useNavigate();
  const { t } = useContext(I18nContext);
  const { showError, showMessage, showConfirm } = useContext(DialogContext);
  const { userContent } = useContext(UserContext);
  const [hasErr, setHasErr] = useState(false)
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [changeSpaceModal, setChangeSpaceModal] = useState(false);

  const fetch = async () => {
    try {
      setLoading(true);
      let data = await FlightService.get(editId);
      setData({
        ...data,
        from: {
          city: data?.fromCity ? data?.fromCity : "",
          country: data?.fromCountry ? data?.fromCountry : "",
        },
        to: {
          city: data?.toCity ? data?.toCity : "",
          country: data?.toCountry ? data?.toCountry : "",
        },
        passengers: data?.passengers ? data?.passengers : [
          {
            index: 0,
            anonymous: false,
            salutation: "Mr",
            phone: {
              countryCode: 91,
            },
          },
        ],
      })
    } catch (error) {
      showError(error)
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editId) {
      fetch();
    } else {
      setData({
        ...data,
        from: {
          city: data?.fromCity ? data?.fromCity : "",
          country: data?.fromCountry ? data?.fromCountry : "",
        },
        to: {
          city: data?.toCity ? data?.toCity : "",
          country: data?.toCountry ? data?.toCountry : "",
        },
        passengers: data?.passengers ? data?.passengers : [
          {
            index: 0,
            anonymous: false,
            salutation: "Mr",
            phone: {
              countryCode: 91,
            },
          },
        ],
      })
      setLoading(false);
    }
  }, [editId, FlightService?.version]);

  useEffect(() => {
    if (prePopulatedData) {
      setData(prev => ({ ...prev, ...prePopulatedData }))
    }
  }, [prePopulatedData])


  const handleEditSpace = async ({ payload }) => {
    setLoading(true);
    try {
      if (editId) {
        await FlightService.edit(payload, editId);
        setLoading(false);
        showMessage(t('Space changed successfully'));
      }
      onSave();
    } catch (e) {
      showError(e);
      setLoading(false);
    }
    setHasErr(false)
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (editId) {
        await FlightService.edit(data, editId);
        showMessage("Flight updated successfully.");
      } else {
        const flight = await FlightService.save({
          ...data,
          space_id: GroupTourSidebarService?.selectedFolder?._id,
        });
        showMessage("Flight saved successfully.");
        if (!insidePane) navigate(`/flight/edit/${flight}`);
      }
      setLoading(false);
      onSave();
    } catch (error) {
      setLoading(false);
      showError(error);
    }
    setHasErr(false);
  };

  const handleDelete = async (event, id) => {
    event.stopPropagation();
    if (await showConfirm({
      title: "Do you want to delete record?",
      description: "This is an unrecoverable operation.",
    })) {
      setLoading(true);
      try {
        await FlightService.delete(id);
        showMessage("Flight Deleted Successfully", "Deleted");
        navigate("/flight");
        setLoading(false);
        onDelete();
      } catch (error) {
        setLoading(false);
        showError(error);
      }
    }
  };

  const handleChangeSpace = () => {
    setChangeSpaceModal(true)
  };

  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <div className="zr_layout-header p-2 d-flex justify-content-between" style={{ flexShrink: 0 }} >

            <div className="d-flex w-100 justify-content-between align-items-center">
              <div className="d-flex align-items-center px-2">
                {data?._id ? t("Edit Flight") : t("Add Flight")}
                <TagPicker
                  className="ms-3"
                  kind={"flight"}
                  onChange={(v) => setData(prev => ({
                    ...prev,
                    tags: v
                  }))}
                  value={data?.tags}
                />
              </div>

              {editId ? <div className="d-flex align-items-center">

                <ReminderButton
                  record_id={editId}
                  module={"flight"}
                  className={"me-0"}
                  space_id={GroupTourSidebarService?.selectedFolder?._id}
                />

                <ActionMenu
                  data={[
                    { label: 'Change Space', icon: <FaExchangeAlt />, onClick: handleChangeSpace },
                  ]}
                  menuPosition="right"
                  className="zr_action_menu me-3 ms-2"
                />
              </div>

                : null}


            </div>


            {/* <CrossButton  onClick={onBack} style={{position:"relative !important"}}/> */}
          </div>

          <Form
            className="p-3"
            style={{ overflowY: "auto", overflowX: "visible", flexGrow: 1 }}
          >
            <Row className="d-flex align-items-center">
              <Col className="col-4">
                <CityCountrySelectAirport
                  placeholder="From"
                  label="From"
                  style={{ fontSize: '16px' }}
                  value={data?.from}
                  onChange={(v) => {
                    setData({
                      ...data,
                      fromCity: v?.value,
                      fromCountry: v?.country,
                      from: {
                        ...data?.from,
                        city: v?.value,
                        country: v?.country,
                        state: v?.state,
                        lat: v?.lat,
                        lng: v?.lng,
                        addressLine: v?.addressLine
                      }
                    })
                  }}
                />

                <DateTimePickerWithInterval
                  noLabel
                  placeholder="Boarding Time"
                  className=""
                  outline
                  timestamp
                  label=""
                  value={joinToUnixTimestamp(data?.date, data?.time)}
                  onChange={(v) => {
                    const momentObj = moment.unix(v)
                    setData({ ...data, date: momentObj.format('YYYYMMDD'), time: momentObj.format('HHmm') });
                  }}
                />
              </Col>
              <Col className="col-4">
                <TimeLine
                  icon={<FaPlane style={{ fontSize: 18, color: '#9E9E9E' }} />}
                  boardingDate={data?.date}
                  boardingTime={data?.time}
                  landingDate={data?.landingDate}
                  landingTime={data?.landingTime}
                />
              </Col>
              <Col className="col-4">
                <CityCountrySelectAirport
                  placeholder="To"
                  label="To"
                  style={{ fontSize: '16px' }}
                  value={data?.to}
                  onChange={(v) => {
                    setData({
                      ...data,
                      toCity: v?.value,
                      toCountry: v?.country,
                      to: {
                        ...data?.to,
                        city: v?.value,
                        country: v?.country,
                        state: v?.state,
                        lat: v?.lat,
                        lng: v?.lng,
                        addressLine: v?.addressLine
                      }
                    });
                  }}
                />

                <DateTimePickerWithInterval
                  noLabel
                  placeholder="Landing Time"
                  className=""
                  outline
                  timestamp
                  value={joinToUnixTimestamp(data?.landingDate, data?.landingTime)}
                  onChange={(v) => {
                    const momentObj = moment.unix(v)
                    setData({ ...data, landingDate: momentObj.format('YYYYMMDD'), landingTime: momentObj.format('HHmm') });
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-5">
              <Col className="col-4">
                <InputField
                  placeholder="Airline"
                  label="Airline"
                  value={data?.airline}
                  onChange={(v) => setData({ ...data, airline: v })}
                  type="text"
                  className="input-group-prepend"
                  outline
                />
              </Col>
              <Col className="col-4">
                <InputField
                  placeholder="Flight No."
                  label="Flight no"
                  value={data?.flightNo}
                  onChange={(v) => setData({ ...data, flightNo: v })}
                  type="text"
                  className="input-group-prepend"
                  outline
                />
              </Col>
              <Col className="col-4">
                <InputField
                  outline
                  placeholder="PNR No."
                  label="PNR no"
                  value={data?.PNR}
                  onChange={(v) => setData({ ...data, PNR: v })}
                  type="text"
                  className="input-group-prepend"
                />
              </Col>
            </Row>

            <Row>
              <Col className="col-4">
                <InputField
                  outline
                  placeholder="Booking No."
                  label="Booking no"
                  value={data?.bookingNo}
                  onChange={(v) => setData({ ...data, bookingNo: v })}
                  type="text"
                  className="input-group-prepend"
                />
              </Col>
              <Col className="col-4 mt-2">
                <DateSelectCustom
                  outline={true}
                  placeholder={("Booked Date")}
                  label="Booked Date"
                  value={data?.bookingDate}
                  onChange={(v) => {
                    setData({ ...data, bookingDate: v });
                  }}
                />
              </Col>
            </Row>

            {formConfig.showPassengers ? <Row className="mt-3 mb-3 ">
              <div><b>Travellers</b></div>
              <PassengerSelect
                space_id={GroupTourSidebarService?.selectedFolder?._id}
                // client_id={trip?.client?.client_id}
                passengers={data?.passengers}
                onChange={(v) => {
                  setData({ ...data, passengers: v })
                }}
                label={""}
              />
            </Row> : null}

            <Row className="justify-content-between d-flex flex-1 ">

              <Col className="col-12  card rounded-2 py-2 ">
                <Fee
                  showDetail={false}
                  label={"Client Billing"}
                  onChange={(updatedFee) => {
                    setData({ ...data, clientFee: updatedFee });
                  }}
                  fee={data?.clientFee}
                  outline={true}
                />
              </Col>

              <Col className="col-12  card rounded-2 py-2 ">
                <Fee
                  showDetail={false}
                  label={"Supplier Billing"}
                  onChange={(updatedFee) => {
                    setData({ ...data, supplierFee: updatedFee });
                  }}
                  fee={data?.supplierFee}
                  outline={true}
                />
              </Col>
            </Row>
          </Form>

          <div className="zr_layout-footer d-flex justify-content-end align-items-center pe-4" style={{ flexShrink: 0, marginBottom: insidePane ? "20px" : "0px" }}>

            {/* {data?._id && userContent?.rights?.includes(1503) && <Button
              // onClick={toggleMessageModal}
              className="d-flex align-items-center"
              style={{ height: "28px", padding: "2px 10px 2px 4px" }}
              color="info"
            >
              <IoIosSend className="me-2" style={{ fontSize: '20px' }} />
              <span>{t(`Send Message`)}</span>
            </Button>} */}

            {data?._id && userContent?.rights?.includes(1503) && (data?._id) ? <DeleteButton onClick={(e) => handleDelete(e, data?._id)} loading={loading} /> : null}
            {userContent?.rights?.includes(1502) ? <SaveButton onClick={handleSave} loading={loading} /> : null}
          </div>

        </div >

      }


      {
        changeSpaceModal ?
          <ChangeSpaceModal
            isOpen={changeSpaceModal}
            selectedSpaceId={data?.space_id}
            toggleModal={() => { setChangeSpaceModal(false) }}
            onSave={(space_id) => {
              handleEditSpace({ payload: { ...data, space_id } })
              setData({ ...data, space_id })
            }}
          /> : null
      }
    </React.Fragment >
  );
};

export default observer(EditFlights);
