import React, { useState } from 'react';
import './styles.css';
import { CloseButton } from 'reactstrap';
import { FiMinimize2, FiMaximize2 } from "react-icons/fi";


const MinimizableComponentEmail = ({ children, onClose, title }) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const toggleMinimize = () => setIsMinimized(prev => !prev);

  return (
    <div className={`email_modal-minimizable-container ${isMinimized ? 'email_minimized' : 'email_full-screen '}`}>
      <div className={`flex-1 m-0  bg-white  email_minimizable-content-body`}>
        <div className="email_modal-minimizable-header  justify-content-between py-2">

          <div className='fs-6 fw-bold ms-2'>{title}</div>

          <div className='d-flex align-items-center gap-4'>
            <div className='cursor-pointer ' onClick={toggleMinimize}>
              {isMinimized ? <FiMaximize2 size={20} /> : <FiMinimize2 size={20} />}
            </div>
            <CloseButton onClick={onClose} className=' p-2 fs-5' />
          </div>
        </div>
        <div className="email_modal-minimizable-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default MinimizableComponentEmail;
