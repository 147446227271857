import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { CardThreeBounce, InputField, TimeZoneSelect } from "../../components";
import CountryService from "./CountryService";
import { I18nContext } from "../../store/context/i18nContext";
import { DialogContext } from "../../store/context/DialogContext";
import FileUpload from "../../components/DocUpload/FileUpload";

const EditCountries = ({ editId, onChange = () => { } }) => {

  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetch = async () => {
    try {
      let countries = await CountryService.get(editId);
      setData(countries);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(null)
    setLoading(true);
    if (editId) fetch();
    setLoading(false);
  }, [editId, CountryService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  return (

    <React.Fragment>
      {loading ?
        <CardThreeBounce /> :


        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="mt-3 mb-3">
            <InputField
              placeholder={t("Country")}
              value={data?.name}
              required={true}
              label={t("Country")}
              onChange={(v) => setData({ ...data, name: v })}
              type="text"
              className="col-sm-4"
            />
            <InputField
              placeholder={t("Currency")}
              value={data?.currency}
              required={true}
              label={t("Currency")}
              onChange={(v) => setData({ ...data, currency: v })}
              type="text"
              className="col-sm-4"
            />
            <InputField
              placeholder={t("Synonyms")}
              value={data?.synonyms}
              required={true}
              label={t("Synonyms")}
              onChange={(v) => setData({ ...data, synonyms: v })}
              type="text"
              className="col-sm-4"
            />

          </Row>

          <Row className="mt-3 mb-3">

            <InputField
              placeholder={t("isoCode")}
              value={data?.isoCode}
              required={true}
              label={t("IsoCode")}
              onChange={(v) => setData({ ...data, isoCode: v })}
              type="text"
              className="col-sm-4"
            />

            <InputField
              placeholder={t("isoCode2")}
              value={data?.isoCode2}
              required={true}
              label={t("IsoCode2")}
              onChange={(v) => setData({ ...data, isoCode2: v })}
              type="text"
              className="col-sm-4"
            />
            <InputField
              placeholder={t("ISD")}
              value={data?.isd}
              required={true}
              label={t("Isd")}
              onChange={(v) => setData({ ...data, isd: v })}
              type="number"
              className="col-sm-4"
            />
          </Row>
          <Row className="mt-3 mb-3">
            <TimeZoneSelect
              placeholder={t("TimeZone")}
              value={data?.timeZone}
              required={true}
              label={t("TimeZone")}
              onChange={(v) => setData({ ...data, timeZone: v.value })}
              type="text"
              className="col-sm-4"
            />
          </Row>
          <div className="mt-5" />
          <FileUpload
            type="image"
            value={data?.flag}
            id={data?._id}
            module={"country"}
            field={"flag"}
            onUpload={() => {
              fetch()
            }}
          />
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditCountries);



