import React, { useEffect, useState, useContext } from "react";
import SettingsService from "../SettingsService";
import { DialogContext } from "../../../store/context/DialogContext";
import { Col, Form, Row, FormGroup, Label, Input, Table } from "reactstrap";
import { InputField, SaveButton } from "../../../components";

const RouteSettings = () => {
  const [loading, setLoading] = useState(false);
  const { showMessage, showError } = useContext(DialogContext);
  const [data, setData] = useState("");

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await SettingsService.getConfig();
      setData(res);
      console.log("bookingsettings.js: ", res);

    } catch (e) {
      showError(e);
      // console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      await SettingsService.saveRoute(data);
      showMessage("Route Settings Saved successfully");
      fetchData();
      setLoading(false);
    } catch (error) {
      showError(error);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col
            className="col-12 p-2 ps-4"
            style={{
              backgroundColor: "#EAEAEA",
              fontSize: "15px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <header>Route Settings</header>
          </Col>
          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="confirmExitFromPax"
                name="confirmExitFromPax"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    confirmExitFromPax: !data?.confirmExitFromPaxx,
                  });
                }}
                checked={data?.confirmExitFromPax}
              />
              <Label className="ps-2 pt-1" check for="confirmExitFromPax">
                Confirm Female Pax Exit (If pax not dropped in 100m radius)
              </Label>
            </FormGroup>
          </Col>
          <Col className="col-12 p-3 ps-4">
            <FormGroup className="d-flex align-items-center" check>
              <Input
                id="driverCanMarkOnboard"
                name="driverCanMarkOnboard"
                type="checkbox"
                onChange={() => {
                  setData({
                    ...data,
                    driverCanMarkOnboard: !data?.driverCanMarkOnboard,
                  });
                }}
                checked={data?.driverCanMarkOnboard}
              />
              <Label className="ps-2 pt-1" check for="driverCanMarkOnboard">
                Driver can mark passenger has boarded ride, in app without OTP
                verification
              </Label>
            </FormGroup>
          </Col>

          <Col className="col-12 p-3 ps-4">
            <InputField
              placeholder="Route Forces Stop Interval"
              label={"Force Stop regular route after this duration(mins)passed"}

              type="number"
              maxLength="4"
              value={data?.stop_route_interval ? data?.stop_route_interval : ""}
              onChange={(v) => {
                setData({ ...data, stop_route_interval: v });
              }}
              className="col-sm-8"
            />
            <Col
              className="col-12 p-3 ps-4 mt-4"
              style={{
                backgroundColor: "#EAEAEA",
                fontSize: "15px",
                borderBottom: "1px solid rgba(0,0,0,.1)",
              }}
            >
              <header>Email/SMS Settings</header>
            </Col>
            <Table striped bordered hover responsive>
              <tbody>
                <tr>
                  <td rowspan="2">Type</td>
                  <td colSpan="2">Email</td>
                  <td colSpan="3">SMS</td>
                </tr>
                <tr>
                  <td>Customer</td>
                  <td>Booker</td>
                  <td>Customer</td>
                  <td>Booker</td>
                  <td>Driver</td>
                </tr>
                <tr>
                  <td>Route Confirmation</td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyCustomer.confirmEmail"
                        name="routeNotifyCustomer.confirmEmail"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyCustomer: {
                              ...data.routeNotifyCustomer,
                              confirmEmail:
                                !data?.routeNotifyCustomer?.confirmEmail,
                            },
                          });
                        }}
                        checked={data?.routeNotifyCustomer?.confirmEmail}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyBooker.confirmEmail"
                        name="routeNotifyBooker.confirmEmail"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyBooker: {
                              ...data.routeNotifyBooker,
                              confirmEmail:
                                !data?.routeNotifyBooker?.confirmEmail,
                            },
                          });
                        }}
                        checked={data?.routeNotifyBooker?.confirmEmail}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyCustomer.confirmSMS"
                        name="routeNotifyCustomer.confirmSMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyCustomer: {
                              ...data.routeNotifyCustomer,
                              confirmSMS:
                                !data?.routeNotifyCustomer?.confirmSMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyCustomer?.confirmSMS}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyBooker.confirmSMS"
                        name="routeNotifyBooker.confirmSMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyBooker: {
                              ...data.routeNotifyBooker,
                              confirmSMS: !data?.routeNotifyBooker?.confirmSMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyBooker?.confirmSMS}
                      />
                    </FormGroup>
                  </td>
                </tr>

                <tr>
                  <td>Vehicle Assigned</td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyCustomer.vehAssignEmail"
                        name="routeNotifyCustomer.vehAssignEmail"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyCustomer: {
                              ...data.routeNotifyCustomer,
                              vehAssignEmail:
                                !data?.routeNotifyCustomer?.vehAssignEmail,
                            },
                          });
                        }}
                        checked={data?.routeNotifyCustomer?.vehAssignEmail}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyBooker.vehAssignEmail"
                        name="routeNotifyBooker.vehAssignEmail"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyBooker: {
                              ...data.routeNotifyBooker,
                              vehAssignEmail:
                                !data?.routeNotifyBooker?.vehAssignEmail,
                            },
                          });
                        }}
                        checked={data?.routeNotifyBooker?.vehAssignEmail}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyCustomer.vehAssignSMS"
                        name="routeNotifyCustomer.vehAssignSMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyCustomer: {
                              ...data.routeNotifyCustomer,
                              vehAssignSMS:
                                !data?.routeNotifyCustomer?.vehAssignSMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyCustomer?.vehAssignSMS}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyBooker.vehAssignSMS"
                        name="routeNotifyBooker.vehAssignSMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyBooker: {
                              ...data.routeNotifyBooker,
                              vehAssignSMS:
                                !data?.routeNotifyBooker?.vehAssignSMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyBooker?.vehAssignSMS}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyDriver.vehAssignSMS"
                        name="routeNotifyDriver.vehAssignSMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyDriver: {
                              ...data.routeNotifyDriver,
                              vehAssignSMS:
                                !data?.routeNotifyDriver?.vehAssignSMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyDriver?.vehAssignSMS}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td>Vehicle Dispatched</td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyCustomer.vehDispatchEmail"
                        name="routeNotifyCustomer.vehDispatchEmail"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyCustomer: {
                              ...data.routeNotifyCustomer,
                              vehDispatchEmail:
                                !data?.routeNotifyCustomer?.vehDispatchEmail,
                            },
                          });
                        }}
                        checked={data?.routeNotifyCustomer?.vehDispatchEmail}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyBooker.vehDispatchEmail"
                        name="routeNotifyBooker.vehDispatchEmail"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyBooker: {
                              ...data.routeNotifyBooker,
                              vehDispatchEmail:
                                !data?.routeNotifyBooker?.vehDispatchEmail,
                            },
                          });
                        }}
                        checked={data?.routeNotifyBooker?.vehDispatchEmail}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyCustomer.vehDispatchSMS"
                        name="routeNotifyCustomer.vehDispatchSMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyCustomer: {
                              ...data.routeNotifyCustomer,
                              vehDispatchSMS:
                                !data?.routeNotifyCustomer?.vehDispatchSMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyCustomer?.vehDispatchSMS}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyBooker.vehDispatchSMS"
                        name="routeNotifyBooker.vehDispatchSMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyBooker: {
                              ...data.routeNotifyBooker,
                              vehDispatchSMS:
                                !data?.routeNotifyBooker?.vehDispatchSMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyBooker?.vehDispatchSMS}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyDriver.vehDispatchSMS"
                        name="routeNotifyDriver.vehDispatchSMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyDriver: {
                              ...data.routeNotifyDriver,
                              vehDispatchSMS:
                                !data?.routeNotifyDriver?.vehDispatchSMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyDriver?.vehDispatchSMS}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td>Journey Start</td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyCustomer.onJourneyEmail"
                        name="routeNotifyCustomer.onJourneyEmail"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyCustomer: {
                              ...data.routeNotifyCustomer,
                              onJourneyEmail:
                                !data?.routeNotifyCustomer?.onJourneyEmail,
                            },
                          });
                        }}
                        checked={data?.routeNotifyCustomer?.onJourneyEmail}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyBooker.onJourneyEmail"
                        name="routeNotifyBooker.onJourneyEmail"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyBooker: {
                              ...data.routeNotifyBooker,
                              onJourneyEmail:
                                !data?.routeNotifyBooker?.onJourneyEmail,
                            },
                          });
                        }}
                        checked={data?.routeNotifyBooker?.onJourneyEmail}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyCustomer.onJourneySMS"
                        name="routeNotifyCustomer.onJourneySMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyCustomer: {
                              ...data.routeNotifyCustomer,
                              onJourneySMS:
                                !data?.routeNotifyCustomer?.onJourneySMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyCustomer?.onJourneySMS}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyBooker.onJourneySMS"
                        name="routeNotifyBooker.onJourneySMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyBooker: {
                              ...data.routeNotifyBooker,
                              onJourneySMS:
                                !data?.routeNotifyBooker?.onJourneySMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyBooker?.onJourneySMS}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    {/* <FormGroup className='d-flex align-items-center' check>
                    <Input
                      id="routeNotifyDriver.onJourneySMS"
                      name="routeNotifyDriver.onJourneySMS"
                      type="checkbox"
                onChange={() => {
                  setData({...data, driverCanMarkOnboard: !data?.driverCanMarkOnboard })
                }}
                    />
                  </FormGroup> */}
                  </td>
                </tr>
                <tr>
                  <td>Journey Completion</td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyCustomer.tripCompleteEmail"
                        name="routeNotifyCustomer.tripCompleteEmail"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyCustomer: {
                              ...data.routeNotifyCustomer,
                              tripCompleteEmail:
                                !data?.routeNotifyCustomer?.tripCompleteEmail,
                            },
                          });
                        }}
                        checked={data?.routeNotifyCustomer?.tripCompleteEmail}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyBooker.tripCompleteEmail"
                        name="routeNotifyBooker.tripCompleteEmail"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyBooker: {
                              ...data.routeNotifyBooker,
                              tripCompleteEmail:
                                !data?.routeNotifyBooker?.tripCompleteEmail,
                            },
                          });
                        }}
                        checked={data?.routeNotifyBooker?.tripCompleteEmail}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyCustomer.tripCompleteSMS"
                        name="routeNotifyCustomer.tripCompleteSMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyCustomer: {
                              ...data.routeNotifyCustomer,
                              tripCompleteSMS:
                                !data?.routeNotifyCustomer?.tripCompleteSMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyCustomer?.tripCompleteSMS}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyBooker.tripCompleteSMS"
                        name="routeNotifyBooker.tripCompleteSMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyBooker: {
                              ...data.routeNotifyBooker,
                              tripCompleteSMS:
                                !data?.routeNotifyBooker?.tripCompleteSMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyBooker?.tripCompleteSMS}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    {/* <FormGroup className='d-flex align-items-center' check>
                    <Input
                      id="routeNotifyDriver.onJourneySMS"
                      name="routeNotifyDriver.onJourneySMS"
                      type="checkbox"
                onChange={() => {
                        setData({ ...data, routeNotifyDriver:{ ...data.routeNotifyDriver, tripCompleteSMS: !data?.routeNotifyDriver?.tripCompleteSMS}})
                      }}
                      checked={data?.routeNotifyDriver?.tripCompleteSMS}
                    />
                  </FormGroup> */}
                  </td>
                </tr>

                <tr>
                  <td>On Amendment</td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyCustomer.ammendmentEmail"
                        name="routeNotifyCustomer.ammendmentEmail"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyCustomer: {
                              ...data.routeNotifyCustomer,
                              ammendmentEmail:
                                !data?.routeNotifyCustomer?.ammendmentEmail,
                            },
                          });
                        }}
                        checked={data?.routeNotifyCustomer?.ammendmentEmail}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyBooker.ammendmentEmail"
                        name="routeNotifyBooker.ammendmentEmail"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyBooker: {
                              ...data.routeNotifyBooker,
                              ammendmentEmail:
                                !data?.routeNotifyBooker?.ammendmentEmail,
                            },
                          });
                        }}
                        checked={data?.routeNotifyBooker?.ammendmentEmail}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyCustomer.ammendmentSMS"
                        name="routeNotifyCustomer.ammendmentSMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyCustomer: {
                              ...data.routeNotifyCustomer,
                              ammendmentSMS:
                                !data?.routeNotifyCustomer?.ammendmentSMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyCustomer?.ammendmentSMS}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyBooker.ammendmentSMS"
                        name="routeNotifyBooker.ammendmentSMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data,
                            routeNotifyBooker: {
                              ...data.routeNotifyBooker,
                              ammendmentSMS:
                                !data?.routeNotifyBooker?.ammendmentSMS,
                            },
                          });
                        }}
                        checked={data?.routeNotifyBooker?.ammendmentSMS}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup className="d-flex align-items-center" check>
                      <Input
                        id="routeNotifyDriver.ammendmentSMS"
                        name="routeNotifyDriver.ammendmentSMS"
                        type="checkbox"
                        onChange={() => {
                          setData({
                            ...data?.routeNotifyDriver,
                            ammendmentSMS:
                              !data?.routeNotifyDriver?.ammendmentSMS,
                          });
                        }}
                        checked={data?.routeNotifyDriver?.ammendmentSMS}
                      />
                    </FormGroup>
                  </td>
                </tr>
              </tbody>
            </Table>
            <footer className="col-12">
              <SaveButton />
            </footer>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default RouteSettings;
