import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET, doPOST } from "../../../util/HttpUtil";

class CityGroupSelect {
    cityGroups = [null];
    countryCityGroups = [null];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filterData) {
        if (this.cityGroups[0] !== null)
            return;
        try {
            const response = await doGET(ENDPOINTS.getAllCityGroups);
            if (response.status === 200) {
                this.cityGroups = response.data;
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
    fetchCityByCountry = async function (country) {
        // console.log("fetchCityByCountry API hit")
        try {
            const response = await doGET(ENDPOINTS.getCityGroupsByCountry(country));
            if (response.status === 200) {
                // console.log("fetchCityByCountry API Success, data: ", response.data);
                this.countryCityGroups = response.data;
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const CityGroupSelectService = new CityGroupSelect();
export default CityGroupSelectService;
