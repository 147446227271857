function convertMinutesToHours(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return hours + ":" + remainingMinutes;
}

export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Country",
            accessor: "country",
            show: true,
        },
        {
            title: "Regions",
            accessor: "regions",
            show: true,
            width: 700,
            required: true,
            renderer: (dataObj) => {
                return (
                    <div className="d-flex align-items-centre">
                        {dataObj?.regions?.map((region, index) => (
                            <div key={index} className="d-flex align-items-centre">
                                {region?.state}:{' '}
                                {region?.cities?.map((city, idx) => (
                                    <span key={idx}>
                                        {city},{' '}
                                    </span>
                                ))}
                            </div>
                        ))}


                    </div>
                )
            },
        },
        {
            title: "Booking Time Gap",
            accessor: "bookingTimeGap",
            show: true,
            renderer: (dataObj) => {
                return <div className="d-flex align-items-center">
                    <div className="ms-1">{convertMinutesToHours(dataObj?.bookingTimeGap)} Hrs</div>
                </div>;
            },
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
