import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { BottomBarButton, Modal, ModalComponent, SearchButton } from "../../index";
import WorkShift from "../../../pages/WorkShift/WorkShift";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import WorkShiftSelectService from "./ShiftSelectService";

const WorkShiftSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  asLabel,
  plain,
  outline
}) => {
  const [workShifts, setWorkShifts] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const fetchWorkShifts = async () => {
    try {
      await WorkShiftSelectService.fetch();
      setWorkShifts(toJS(WorkShiftSelectService.workShifts));
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (WorkShiftSelectService.workShifts.length === 0) fetchWorkShifts();
  }, []);

  useEffect(() => {
    setWorkShifts(toJS(WorkShiftSelectService.workShifts));
  }, [WorkShiftSelectService.workShifts]);



  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectWorkShift = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0] : selectedValue });
    closeModal();
  };

  const selectWorkShifts = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage WorkShifts")} />
      )}
      showBottomBar={true}
      className={className}
      onChange={onChange}
      data={workShifts}
      required={required}
      value={value}
      label={label || (!isDisabled && t("Shift"))}
      error={!value ? t(`Please Select ${label || "Shift"}`) : ""}
      showErr={showErr}
      plain={plain}
      outline={outline}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage WorkShifts")} />
      )}
      showBottomBar={true}
      className={className}
      onChange={selectWorkShifts}
      data={workShifts}
      value={value ? workShifts.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Shift"}`) : ""}
      required={required}
      plain={plain}
      outline={outline}
      label={t("Shift")}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
      {asLabel ? multi ?
        value?.length > 0 ?
          (<div>
            {value?.map((selectedValue, index) => (
              <span key={selectedValue}>
                {workShifts.find((workShift) => workShift.value === selectedValue)?.label || ""}
                {index < value.length - 1 && ", "}
              </span>
            ))}</div>) : <div style={{ color: "grey" }}>No Record Found</div>


        : (<div>{workShifts?.find((workShift) => workShift?.value == value)?.label ?? <div style={{ color: "grey" }}>No record Found</div>}</div>) :
        <>

          {modal && (
            <ModalComponent
              position={"top"}
              size={"medium"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("WorkShift")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <WorkShift
                  value={value}
                  insidePane={true}
                  multiMode={multi}
                  onSelect={multi ? selectWorkShifts : selectWorkShift}
                />
              </ModalComponent.Body>
            </ModalComponent>
          )}

          {workShifts.length > 0
            ? multi
              ? renderSelectFieldMultiple()
              : renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      }
    </>
  );
};

export default observer(WorkShiftSelect);
