export const ENDPOINTS = {

    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/invoice-config/grid?`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    get: (id) => `/api/invoice-config/${id}/detail`,
    saveNew: `/api/invoice-config/create`,
    save: `/api/invoice-config/create`,
    delete: (id) => `/api/invoice-config/${id}/delete`,
    bulkDelete: (id) => `/api/invoice-config/delete`,
    update: `/api/invoice-config/update`,
};

export const STRUCTURE = [
    {
        label: "Invoice No ",
        filterLabel: "Invoice No ",
        filterName: "invNoPrefix",
        type: "text",
    },
    {
        label: "Header Content",
        filterLabel: "Header Content",
        filterName: "headerContent",
        type: "text",
    },
    {
        label: "Bank Details",
        filterLabel: "Bank Details",
        filterName: "Bank Details",
        type: "text",
    },
    {
        label: "Terms",
        filterLabel: "Terms",
        filterName: "terms",
        type: "text",
    },

];

export const SELECTSTRUCTURE = {
    tweakType: [
        { label: "RCM", value: "RCM" },
        { label: "SEZ", value: "SEZ" }
    ]
}
