"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const buildTokenMeta = (customizeError, value, index) => {
    return {
        key: `${JSON.stringify(value)}-${Date.now()}-${index}`,
        activated: false,
        error: customizeError,
    };
};
exports.default = buildTokenMeta;
