import { makeAutoObservable } from "mobx";
import { getUiPrefRows } from "../../util/FilterUtil";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { ENDPOINTS } from "./InboxConstant";
class Service {
  currentModuleRecords = [];
  BookingRecords = [];
  bookingIDSet = [];
  VehicleRecords = [];
  vehicleIDSet = [];
  DriverRecords = [];
  driverIDSet = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = null;
  filterOption = "";
  currentModuleTotal = -1;
  bookingTotal = -1;
  vehicleTotal = -1;
  driverTotal = -1;
  version = 0;
  isVisible = false
  module = 'Booking' //Booking,Vehicle,Driver
  constructor() {
    makeAutoObservable(this);
  }


  fetchBookingsData = async () => {
    this.bookingIDSet = new Set(this.BookingRecords.map(booking => booking.ref_id));
    let bookingIds = Array.from(this.bookingIDSet).join(",")
    let fetchBookings = await doGET(
      ENDPOINTS.bookingGrid(bookingIds)
    );
    let bookingData = [];
    if (fetchBookings.status === 200) {
      bookingData = fetchBookings.data.rows;
    }
    let updatedBookingRecords = [...this?.BookingRecords];
    updatedBookingRecords.map((BookingRecord, BookingRecordsIndex) => {
      bookingData.map((booking, index) => {
        if (booking?._id === BookingRecord?.ref_id) {
          updatedBookingRecords[BookingRecordsIndex] = { ...updatedBookingRecords[BookingRecordsIndex], moduleInfo: bookingData[index] };
        }
      });
    });
    if (this.module === "Booking") {
      this.currentModuleRecords = [...updatedBookingRecords];
    }
    this.BookingRecords = [...updatedBookingRecords];;
  }

  fetchVehicleData = async () => {
    this.vehicleIDSet = new Set(this.VehicleRecords.map(vehicle => vehicle?.ref_id));
    let vehicleIds = Array.from(this.vehicleIDSet).join(",");
    let fetchVehicles = await doGET(
      ENDPOINTS.vehicleGrid(vehicleIds)
    );
    let vehicleData = [];
    if (fetchVehicles.status === 200) {
      vehicleData = fetchVehicles.data.rows;
    }
    let updatedVehicleRecords = [...this?.VehicleRecords];
    updatedVehicleRecords.map((vehicleRecord, VehicleRecordIndex) => {
      vehicleData.map((vehicle, index) => {
        if (vehicle?._id === vehicleRecord?.ref_id) {
          updatedVehicleRecords[VehicleRecordIndex] = { ...updatedVehicleRecords[VehicleRecordIndex], moduleInfo: vehicleData[index] };
        }
      });
    });
    if (this.module === "Vehicle") {
      this.currentModuleRecords = [...updatedVehicleRecords];
    }
    this.VehicleRecords = [...updatedVehicleRecords];;
  }


  fetchDriverData = async () => {
    this.driverIDSet = new Set(this.DriverRecords.map(driver => driver?.ref_id));
    let driverIds = Array.from(this.driverIDSet).join(",");
    let fetchDrivers = await doGET(
      ENDPOINTS.employeeGrid(driverIds)
    );
    let driverData = [];
    if (fetchDrivers.status === 200) {
      driverData = fetchDrivers.data.rows;
    }
    let updatedDriverRecords = [...this.DriverRecords];
    updatedDriverRecords.map((DriverRecord, DriverRecordIndex) => {
      driverData.map((driver, index) => {
        if (driver?._id === DriverRecord?.ref_id) {
          updatedDriverRecords[DriverRecordIndex] = { ...updatedDriverRecords[DriverRecordIndex], moduleInfo: driverData[index] };
        }
      });
    });
    if (this.module === "Driver") {
      this.currentModuleRecords = [...updatedDriverRecords];
    }
    this.DriverRecords = [...updatedDriverRecords];;
  }

  fetch = async (filterUrl = "") => {
    if (!this?.rowsPerPage) {
      const rows = await getUiPrefRows("inbox.grid")
      this.rowsPerPage = rows ?? 40
    }

    if (filterUrl) {
      this.filterOption = filterUrl;
    }
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl, this?.module)
    );
    if (response?.status === 200) {
      this.currentModuleRecords = response.data.rows;

      this.page = response?.data?.page;

      switch (filterUrl) {
        case "module=Booking":
          this.BookingRecords = response.data.rows;
          this.bookingTotal = response?.data?.total ? response?.data?.total : this.bookingTotal;
          this.currentModuleTotal = this.bookingTotal;
          // this.module = "Booking";
          await this.fetchBookingsData(this.filterOption);
          break;
        case "module=Vehicle":
          this.VehicleRecords = response.data.rows;
          this.vehicleTotal = response?.data?.total ? response?.data?.total : this.vehicleTotal;
          this.currentModuleTotal = this.vehicleTotal;
          // this.module = "Vehicle";
          await this.fetchVehicleData(this.filterOption);
          break;
        case "module=Driver":
          this.DriverRecords = response.data.rows;
          this.driverTotal = response?.data?.total ? response?.data?.total : this.driverTotal;
          this.currentModuleTotal = this.driverTotal;
          // this.module = "Driver";
          await this.fetchDriverData(this.filterOption);
          break;
        default:
          break;
      }
    }
  }
  // };



  edit = async function (data, id) {
    const response = await doPUT(ENDPOINTS?.update, data);
    if (response.status === 200) {
      this.version += 1;
    }

  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    return response?.data?._id;
  };

  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };

  bulkDelete = async (ids = []) => {
    const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
    if (response.status === 200) {
      this.fetch();
    }
  };

  onPaginationChange = async (page, rowsPerPage) => {
    switch (this.module) {
      case "Booking":
        this.filterOption = "module=Booking";
        break;
      case "Vehicle":
        this.filterOption = "module=Vehicle";
        break;
      case "Driver":
        this.filterOption = "module=Driver";
        break;
      default:
        break;
    }
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);
  };

  changeModule = async (module, filterUrl = "") => {
    this.module = module;
    this.page = 1;
    this.filterOption = "";
    this.currentModuleTotal = -1;
    switch (filterUrl) {
      case "module=Booking":
        this.currentModuleRecords = this.BookingRecords
        this.currentModuleTotal = this.BookingRecords
        this.module = "Booking";
        break;
      case "module=Vehicle":
        this.currentModuleRecords = this.VehicleRecords
        this.currentModuleTotal = this.vehicleTotal
        this.module = "Vehicle";
        break;
      case "module=Driver":
        this.currentModuleRecords = this.DriverRecords
        this.currentModuleTotal = this.driverTotal
        this.module = "Driver";
        break;
      default:
        break;
    }
  }

  toggleModal = () => {
    this.isVisible = !this.isVisible
  }
}

const InboxService = new Service();
export default InboxService;

