import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  Layout
} from "../../components";
import CustomOperations from "../../components/CustomField/CustomOperations";
import ReminderCalender from "../../components/dnd/calender/ReminderCalender";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import GroupTourSidebar from "../GroupTour/sideBar/GroupTourSidebar";
import GroupTourSidebarService from "../GroupTour/sideBar/GroupTourSidebarService";
import { STRUCTURE } from "./CalenderPageConstant";
import FlightService from "./CalenderPageService";
import { TOUR_TOGGLE_DATA } from "../BookingsNew/utils/constants";

const Flights = ({
  value,
  insidePane,
  multiMode,
  onSelect = () => { },
  formConfig = {
    showChecks: true,
    hideFilter: false,
  },
  passengerId,
}) => {
  let { edit_id } = useParams();


  let navigate = useNavigate();

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showError } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/flight/create" || edit_id);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [uploadPopup, setUploadPopup] = useState(false);
  const [showCustomizationOptions, setShowCustomizationOptions] = useState(false);

  const multiplyFieldsBy100 = (data) => {
    const multiplyIfExists = (obj, fields) => {
      fields.forEach(field => {
        if (obj[field] !== undefined) {
          obj[field] = obj[field] * 100;
        }
      });
    };

    if (data.clientFee) {
      multiplyIfExists(data.clientFee, ['subTotal', 'totalDiscounts', 'totalTaxes']);
    }

    if (data.supplierFee) {
      multiplyIfExists(data.supplierFee, ['subTotal', 'totalDiscounts', 'totalTaxes']);
    }

    return data;
  };


  const saveBulk = async (data) => {
    try {
      const modifiedData = multiplyFieldsBy100({ ...data });
      const account = await FlightService.save({
        ...(modifiedData ?? {}),
        space_id: GroupTourSidebarService.selectedFolder.type == "single" ? null : GroupTourSidebarService.selectedFolder._id,
      });
      if (account) {
        return { isUploaded: true, message: "" }
      }
      return { isUploaded: false, message: "Failed unexpectedly" }
    } catch (error) {
      return { isUploaded: false, message: JSON.parse(error?.message)?.customMessage ?? JSON.parse(error?.message)?.message }
    }
  };

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await FlightService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };


  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])


  return (
    <div className="d-flex">
      {insidePane ? null : <div style={{ minWidth: "200px" }} className={insidePane ? "" : "page-content"}>
        <GroupTourSidebar module="events" />
      </div>}




      <div className="flex-1" style={{ width: "calc(100vw - 250px)" }}>
        <Layout
          hideAdd={!userContent?.rights?.includes(1501) || insidePane}
          large
          hideSearch={formConfig?.hideFilter}
          showToggle={!insidePane}
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/flight");
            setEditID(null);
          }}
           
          title={t("Flights")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/flight/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={FlightService.page}
          rows={FlightService.rows}
          total={FlightService.total}
          fetch={FlightService.fetch}
          hideheader={true}
          hidefooter={true}
          toggleData={TOUR_TOGGLE_DATA(t, "calender")}

          compact
          onToggle={(selectedValue) => {
            if (selectedValue === "tours") {
              navigate(`/tour`);
            } else if (selectedValue === "trips") {
              navigate(`/trips`);
            } else if (selectedValue === "stay") {
              navigate("/stay");
            } else if (selectedValue === "flights") {
              navigate("/flight");
            } else if (selectedValue === "guests") {
              navigate("/guests");
            } else if (selectedValue === "calender") {
              navigate("/tours/calender");
            }
          }}

        >



          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  <DropdownItem onClick={() => setUploadPopup(true)}>
                    {t("Bulk Upload")}
                  </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem>{t("Download")} </DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem onClick={() => { setShowCustomizationOptions(true) }}>{t("Customise")} </DropdownItem>
                </>
              </DropdownMenu>

            </div>
          </Layout.ActionMenu>
          <Layout.Table>
            <ReminderCalender space_id={GroupTourSidebarService?.selectedFolder?._id} />

          </Layout.Table>

          <Layout.DetailPageBody>
          <></>
          </Layout.DetailPageBody>

        </Layout>
        {showCustomizationOptions ? <CustomOperations
          isModal
          module="flight"
          isOpen={showCustomizationOptions}
          toggleModal={() => { setShowCustomizationOptions(false) }}
        /> : null
        }
      </div>
    </div>
  );
};
export default observer(Flights);
