import React, { useState, useEffect } from "react";
import { SelectField } from "../../SelectField/SelectField";
import { toJS } from "mobx";
// importContentSelectAllService from './MakeSelectService';
import ContentSelectService from "./ContentSelectAllService";

export default function ContentSelect(props) {
  const [contents, setContents] = useState([]);

  useEffect(() => {
    const fetch = async () => {

      try {
        await ContentSelectService.fetch();
        setContents(toJS(ContentSelectService.contents));
      } catch (error) {
        console.log(error.message);
      }
    };
    fetch();
  }, []);

  return (
    <React.Fragment>
      {contents.length > 0 && (
        <SelectField
          className={props?.className}
          onChange={props?.onChange}
          data={contents}

          value={props?.value}
          label={"Content Type"}
          error={props?.value ? "Please Select ContentType" : ""}
          showErr={props?.showErr}
          inline={props?.inline}
          borderNone={props?.borderNone}
          RightComponent={props?.RightComponent}
          onClickRight={props?.onClickRight}
        />
      )}
      {!contents.length > 0 && (
        <SelectField
          className={props?.className}
          onChange={props?.onChange}
          data={contents}

          value={props?.value}
          label={"Content Type"}
          error={props?.value ? "Please Select ContentType" : ""}
          isDisabled={true}
          showErr={props?.showErr}
          inline={props?.inline}
          borderNone={props?.borderNone}
          RightComponent={props?.RightComponent}
          onClickRight={props?.onClickRight}
        />
      )}
    </React.Fragment>
  );
}
