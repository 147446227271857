import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./FundAccountConstant";
import { doGET, doPOST } from "../../util/HttpUtil";
class Service {
    records = [];
    totalRecords = 0;
    page = 1;
    rows = 20;
    filterOption = '';
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async (filterUrl) => {
        if (filterUrl) this.filterOption = filterUrl;
        else this.filterOption = '';
        const response = await doGET(
            ENDPOINTS.grid(this.page, this.rows, filterUrl)
        );
        if (response.status === 200) {
            this.records = response.data.rows;
            this.totalRecords = response.data.records;
            return response.data;
        }
    };
    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));
        if (response.status === 200) {
            return response.data;
        }
    };
    save = async (data) => {
        const response = await doPOST(ENDPOINTS.save, data);
        if (response?.status === 200) return response?.data?._id
    };
    onPaginationChange = async (page, rows) => {
        this.page = page;
        this.rows = rows;
        this.fetch(this.filterOption);
    };
}
const FundAcc = new Service();
export default FundAcc;