import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../../../pages/Garage/GarageConstant";

class GarageSelect {
    garages = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (props) {
        try {
            const response = await doGET(ENDPOINTS.grid());
            if (response.status === 200) {
                this.garages = response?.data?.rows
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const GarageSelectService = new GarageSelect();
export default GarageSelectService;
