import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";

import { Col, Form, Row } from "reactstrap";
import {
  Checkbox,
  CitySelect,
  CountrySelect,
  InputField,
  PhoneNum,
  SelectField,
  StateSelect,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";

const CreateCorporate = (props) => {
  const { id, data, setData, showErr, setShowErr } = props;
  const corpTypes = [
    {
      label: "Consumer",
      value: 1,
    },
    {
      label: "Aggregator",
      value: 2,
    },
    {
      label: "Supplier",
      value: 3,
    },
  ];
  //   async function fetch() {
  //     if (id) {
  //       const state = await StateService.get(id);
  //       setData(state)
  //     } else {
  //       setData({ country: "", name: 0, desc: "" });
  //     }
  //     setLoading(false);
  //   }

  //   useEffect(() => {
  //     setLoading(true);
  //     fetch();
  //     setHasErr(false);
  //   }, [id]);

  return (
    <div>
      <div className="p-0">
        <Form className="p-2">
          <h3 className=" text-center mt-2 " style={{ fontWeight: "bold" }}>
            Company Information
          </h3>
          <Row>
            <Col className="py-2">
              <SelectField
                data={corpTypes}
                value={data?.corpType}
                label={"Corporation Type"}
                placeholder=""
                onChange={(v) => {
                  setData({ ...data, corpType: v.value });
                }}
                showErr={showErr}

              />
            </Col>
            <Col className="py-2">
              <InputField
                value={data?.unique_id}
                required={true}
                label={"Unique Id"}
                onChange={(v) => {
                  setData({ ...data, unique_id: v });
                }}
                showErr={showErr}

                type="text"
              />
            </Col>
            <Col className="py-2">
              <InputField
                value={data?.name}
                required={true}
                label={"Short Name"}
                onChange={(v) => {
                  setData({ ...data, name: v });
                }}
                showErr={showErr}

                type="text"
              />
            </Col>
          </Row>

          <Row>
            <Col className="py-2">
              <InputField
                value={data?.fullName}
                required={true}
                label={"Full Name"}
                onChange={(v) => {
                  setData({ ...data, fullName: v });
                }}
                showErr={showErr}

                type="text"
              />
            </Col>
            <Col className="py-2">
              <InputField
                value={data?.email}
                required={true}
                label={"Email"}
                onChange={(v) => {
                  setData({ ...data, email: v });
                }}
                showErr={showErr}

                type="email"
              />
            </Col>
          </Row>

          <Row>
            <Col className="py-2">
              <PhoneNum
                value={{
                  phone: data?.mobileOffice,
                  countryCode: data?.countryCodeOfc,
                }}
                onChange={(v) =>
                  setData({
                    ...data,
                    mobileOffice: v?.phone,
                    countryCodeOfc: v?.countryCode,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col className="py-2">
              <InputField
                value={data?.phoneOffice}
                label={"Phone Office(Optional)"}
                onChange={(v) => {
                  setData({ ...data, phoneOffice: v });
                }}
                showErr={showErr}

                type="number"
              />
            </Col>
            <Col className="py-2">
              <InputField
                value={data?.faxOffice}
                label={"Fax No"}
                onChange={(v) => {
                  setData({ ...data, faxOffice: v });
                }}
                showErr={showErr}

                type="number"
              />
            </Col>
          </Row>
          <hr />
          <h3 className=" text-center mt-4" style={{ fontWeight: "bold" }}>
            Business Information
          </h3>
          <Row>
            <Col className="py-2">
              <InputField
                value={data.GSTNo}
                label={"GSTIN Number"}
                onChange={(v) => {
                  setData({ ...data, GSTNo: v });
                }}
                showErr={showErr}

                type="text"
              />
            </Col>
            <Col className="py-2">
              <InputField
                value={data?.PANNo}
                label={"PAN Number"}
                onChange={(v) => {
                  setData({ ...data, PANNo: v });
                }}
                showErr={showErr}

                type="text"
              />
            </Col>
            <Col className="py-2">
              <InputField
                value={data?.TANNo}
                label={"TAN Number"}
                onChange={(v) => {
                  setData({ ...data, TANNo: v });
                }}
                showErr={showErr}

                type="text"
              />
            </Col>
            <Col className="py-2">
              <InputField
                value={data?.CINno}
                label={"CIN Number"}
                onChange={(v) => {
                  setData({ ...data, CINno: v });
                }}
                showErr={showErr}

                type="text"
              />
            </Col>
          </Row>
          <hr />

          <h3 className=" text-center mt-4" style={{ fontWeight: "bold" }}>
            Other Information
          </h3>
          <Row>
            <Col className="py-2">
              <CountrySelect
                value={data?.address?.country}
                required={true}
                // label={"Country"}
                onChange={(v) => {
                  setData({
                    ...data,
                    address: { ...data?.address, country: v?.value },
                  });
                }}
                showErr={showErr}

              />
            </Col>
            <Col className="py-2">
              <StateSelect
                value={data?.address?.state}
                required={true}
                label={"State"}
                onChange={(v) => {
                  setData({
                    ...data,
                    address: { ...data?.address, state: v?.value },
                  });
                }}
                showErr={showErr}

                country={data?.address?.country}
              />
            </Col>
            <Col className="py-2">
              <CitySelect
                value={data?.address?.city}
                required={true}
                label={"City"}
                onChange={(v) => {
                  console.log(v, "city data");
                  setData({
                    ...data,
                    address: { ...data?.address, city: v?.value },
                  });
                }}
                showErr={showErr}

                country={data?.address?.country}
                state={data?.address?.state}
              />
            </Col>
          </Row>

          <Row>
            <Col className="py-2">
              <InputField
                value={data?.address?.addressLine}
                required={true}
                label={"Organisation Address"}
                onChange={(v) => {
                  setData({
                    ...data,
                    address: { ...data?.address, addressLine: v },
                  });
                }}
                showErr={showErr}

                type="text"
              />
            </Col>
          </Row>

          <Row>
            <Col className="py-2">
              <InputField
                value={data?.website}
                label={"Website"}
                onChange={(v) => {
                  setData({ ...data, website: v });
                }}
                showErr={showErr}

                type="text"
              />
            </Col>
            <Col className="py-2">
              <InputField
                value={data?.establishYear}
                label={"Establish Year"}
                onChange={(v) => {
                  setData({ ...data, establishYear: v });
                }}
                showErr={showErr}

                type="text"
              />
            </Col>
          </Row>
          <hr />

          <h3 className=" text-center mt-4" style={{ fontWeight: "bold" }}>
            Admin Information
          </h3>
          <Row>
            <Col className="py-2">
              <InputField
                value={data?.adminName}
                required={true}
                label={"Admin Name"}
                onChange={(v) => {
                  setData({ ...data, adminName: v });
                }}
                showErr={showErr}

                type="text"
              />
            </Col>
          </Row>

          <Row>
            <Col className="py-2">
              <PhoneNum
                value={{
                  phone: data?.phoneAdmin,
                  countryCode: data?.countryCodeAdmin,
                }}
                onChange={(v) =>
                  setData({
                    ...data,
                    phoneAdmin: v?.phone,
                    countryCodeAdmin: v?.countryCode,
                  })
                }
              />
            </Col>
          </Row>
          {/* <Row>
            <Col className="py-2">
              <PhoneNum
                label="Refree Phone Number (Optional)"
                value={{
                  phone: data?.refNo,
                  countryCode: data?.refCountryCode,
                }}
                onChange={(v) =>
                  setData({
                    ...data,
                    refNo: v?.phone,
                    refCountryCode: v?.countryCode,
                  })
                }
              />
            </Col>
          </Row> */}
          <Row>
            <Col className="py-2">
              <Checkbox
                required={true}
                label="I have read and agree to the Terms and Conditions."
                className="form-check me-5"
              />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default observer(CreateCorporate);
