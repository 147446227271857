import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { Checkbox, CurrencySelect, CompanySelect, InputField, SelectField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "./PaymentGatewayConstant";
import PaymentGatewayService from "./PaymentGatewayService";

const EditPaymentGateways = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [paymentGatewayTypes, setPaymentGatewayTypes] = useState([])
  const [selectedPaymentGatewayParams, setSelectedPaymentGatewayParams] = useState([]);

  const fetch = async () => {
    try {
      let paymentGateway = await PaymentGatewayService.get(editId);
      setData(paymentGateway);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(null)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, PaymentGatewayService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);

  const fetchPaymentGatewayTypes = async () => {
    try {
      const response = await PaymentGatewayService.gettypes();
      setPaymentGatewayTypes(response);
    } catch (error) {
      showError(error);
    }
  };

  const handlePaymentGatewayChange = (selectedpay) => {
    const selectedGatewayParams = paymentGatewayTypes?.find(
      (gateway) => gateway?.name === selectedpay
    );
    setSelectedPaymentGatewayParams(selectedGatewayParams?.params || []);

  }

  useEffect(() => {
    fetchPaymentGatewayTypes();

  }, []);

  const handleParamChange = (paramName, paramValue) => {
    const updatedParams = { ...data?.params };
    updatedParams[paramName] = paramValue;


    setData({
      ...data,
      params: updatedParams,
    });
  };

  const handleChargeChange = (field, value) => {
    setData({
      ...data,
      charge: {
        ...data?.charge,
        [field]: value,
      },
    });
  };

  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="mt-3 mb-3">
            <CompanySelect
              corpType="self"

              value={data?.company_id}
              onChange={(v) => setData({ ...data, company_id: v?.value })}
              className="col-sm-6"
            />
          </Row>

          <Row className="mt-3 mb-3">

            <SelectField
              data={SELECTSTRUCTURE?.paymentGateway}
              value={data?.paymentGateway}
              onChange={(v) => {
                setData({ ...data, paymentGateway: v?.value });
                handlePaymentGatewayChange(v?.value);

              }}
              showErr={hasErr}
              label={t("Payment Gateway")}
              className="col-sm-6 col-12"
            />
            <Checkbox
              label={t("Active")}
              checked={data?.active}
              className="col-sm-6"
              onClick={() => {
                setData({
                  ...data,
                  active: !data?.active,
                });
              }}
            />
          </Row>
          {
            selectedPaymentGatewayParams?.length > 0 && (
              <Row className="mt-3 mb-3">
                {
                  selectedPaymentGatewayParams?.map((param, index) => (
                    <InputField
                      required={param.required ? true : false}
                      label={t(param?.label)}
                      className="col-sm-6"
                      key={index}
                      type='text'
                      value={param?.label}
                      onChange={(e) => handleParamChange(param?.label, e)}
                      placeholder={t(param?.label)}
                    />
                  ))
                }
              </Row>
            )
          }

          <Row className=" mb-3">
            <InputField
              label={t("Base Amount")}
              className="col-sm-6"
              type="number"
              value={data?.charge?.baseAmount || ''}
              onChange={(e) => handleChargeChange('baseAmount', e)}
              error={data?.charge?.baseAmount?.length === 0 ? t("Please enter BaseAmount") : ""}
              showErr={hasErr}
              placeholder={t("Base Amount")}
            />
            <InputField
              label={t("Percent Amount")}
              className="col-sm-6"
              type="number"
              value={data?.charge?.percentAmount || ''}
              onChange={(e) => handleChargeChange('percentAmount', e)}
              error={data?.charge?.percentAmount?.length === 0 ? t("Please enter PercentAmount") : ""}
              showErr={hasErr}
              placeholder={t("Percent Amount")}
            />
          </Row>
          <Row className="mt-3 mb-3">
            <CurrencySelect
              multi
              placeholder={t("Bank Currency ")}
              value={data?.currencies}
              label={t("Currencies")}
              onChange={(v) => {
                setData({ ...data, currencies: v })
              }}
              type="text"
              className="col-sm-6"
            />

          </Row>
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditPaymentGateways);
