import { useEffect, useState, useContext } from "react";
import { InputField } from "./InputField";
import { DialogContext } from "../../store/context/DialogContext";
import useDebounce from "../../util/Debounce";

const CurrencyInputField2 = ({ value, onChange, disabled, label, placeholder, name, id, style ,...props}) => {
    const { showError } = useContext(DialogContext);
    const [inputValue, setInputValue] = useState(value ? (value / 100).toString() : '');

    useEffect(() => {
        setInputValue(value ? (value / 100).toString() : '');
    }, [value]);

    const handleInputChange = useDebounce((v) => {
        if (v < 0) {
            showError('Discount Amount should be greater than zero');
            return;
        }
        const newValue = v;
        setInputValue(newValue);
        const convertedValue = newValue ? Math.round(parseFloat(newValue) * 100) : 0;
        if (onChange) {
            onChange(convertedValue);
        }
    }, 500);

    return (

        <InputField
            isDisabled={disabled}
            placeholder={placeholder}
            label={name}
            noLabel
            id={id}
            key={id}
            style={style}
            type="number"
            value={inputValue}
            outline
            className={"w-100"}
            onChange={handleInputChange}
            {...props}
        />

    );
};

export default CurrencyInputField2;
