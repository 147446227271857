export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/super-admin/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  get: (id) => `/api/super-admin/${id}/detail`,
  save: `/api/super-admin/create`,
  delete: (id) => `/api/super-admin/${id}/delete`,
  update: `/api/super-admin/update`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "State Name",
    filterName: "name",
    type: "text",
  }, {
    label: "Email",
    filterLabel: "State Email",
    filterName: "email",
    type: "text",
  },

];

