import { toJS } from "mobx";
import moment from "moment";
import TagPicker from "../../components/TagSelector/TagPicker";

export const GridColumns = {
    version: 1,
    columns: [
        // Customer Details
        {
            title: 'Customer Name',
            accessor: 'customerName',
            show: true,
            renderer: (dataObj) => {
                console.log({ dataObj: toJS(dataObj) }, "----------------")
                return <div>{dataObj?.passengers?.[0]?.name}</div>

            },
            width: 130
        },
        {
            title: 'Customer Phone', accessor: 'customerPhone',
            renderer: (dataObj) => {
                console.log({ dataObj: toJS(dataObj) }, "----------------")
                return <div>+{dataObj?.passengers?.[0]?.phone?.countryCode} - {dataObj?.passengers?.[0]?.phone?.phone}</div>
            },
            show: true,
            width: 130

        },

        {
            title: "Tags",
            accessor: "tags",

            renderer: (dataObj, rowIndex, pageSize) => {
                return (!!dataObj?.tags ?
                    <TagPicker
                        kind={"tour"}
                        data={dataObj?.tags?.filter(tag => tag && tag?.tag_id)}
                    />
                    : <span style={{color : "#ebebeb"}} className="text-muted">{"No Tags Found"}</span>
                );
            },
            show: true,
        },

        // {
        //     title: 'Purpose', accessor: 'purpose', show: true, width: 80,
        //     renderer: (dataObj) => {
        //         return <div>Business Trip</div>

        //     },
        // },

        // // Tour Details
        // { title: 'Start Date', accessor: 'startDate', renderer: (dataObj) => moment(dataObj.startDate).format('DD-MMM-YYYY'), show: true, width: 90 },
        // { title: 'End Date', accessor: 'endDate', renderer: (dataObj) => moment(dataObj.endDate).format('DD-MMM-YYYY'), show: true, width: 90 },
        // { title: 'Hotel Type', accessor: 'hotelType', show: true, width: 90 },
        // { title: 'Cab Type', accessor: 'cabType', show: true, width: 80 },

        // // Inward Flight/Train
        // { title: 'Inward Mode', accessor: 'inwardMode', show: true, width: 100 },
        // { title: 'Inward Number', accessor: 'inwardNumber', show: true, width: 120 },
        // { title: 'Inward Arrival Time', accessor: 'inwardArrivalTime', renderer: (dataObj) => moment(dataObj.inwardArrivalTime).format('DD-MMM-YYYY hh:mm A'), show: true, width: 160 },

        // // Outward Flight/Train
        // { title: 'Outward Mode', accessor: 'outwardMode', show: true, width: 110 },
        // { title: 'Outward Number', accessor: 'outwardNumber', show: true, width: 130 },
        // { title: 'Outward Departure Time', accessor: 'outwardDepartureTime', renderer: (dataObj) => moment(dataObj.outwardDepartureTime).format('DD-MMM-YYYY hh:mm A'), show: true, width: 180 },

        // // Trips Details
        // { title: 'Airport Pickup', accessor: 'airportPickup', show: true, width: 110 },
        // { title: 'Daily Travel', accessor: 'dailyTravel', show: true, width: 100 },
        // { title: 'Airport Drop', accessor: 'airportDrop', show: true, width: 100 },

        // // Additional Fields
        // { title: 'Group Size', accessor: 'groupSize', show: true, width: 90 },
        // { title: 'Tour Guide', accessor: 'tourGuide', show: true, width: 90 },
        // { title: 'Special Requests', accessor: 'specialRequests', show: true, width: 130 },
        // { title: 'Payment Status', accessor: 'paymentStatus', show: true, width: 120 },
        // { title: 'Booking Reference', accessor: 'bookingReference', show: true, width: 140 },

        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90, // Adjusted for minimum width
            show: true,
        },
    ],
};

export const sampleData = [
    {
        customerName: 'John Doe',
        customerPhone: '+1 123 456 7890',
        customerDesignation: 'Manager',
        purpose: 'Business Trip',
        startDate: '2024-06-01',
        endDate: '2024-06-10',
        hotelType: 'Luxury',
        cabType: 'Sedan',
        inwardMode: 'Flight',
        inwardNumber: 'AA123',
        inwardArrivalTime: '2024-06-01T10:30:00',
        outwardMode: 'Train',
        outwardNumber: 'TR456',
        outwardDepartureTime: '2024-06-10T18:00:00',
        airportPickup: 'Yes',
        dailyTravel: 'Yes',
        airportDrop: 'Yes',
        groupSize: 20,
        tourGuide: 'Jane Smith',
        specialRequests: 'Vegetarian meals',
        paymentStatus: 'Paid',
        _id: "664c3f0fbb79bc16edc32378", bookingReference: 'ABC123',
        passengers: [{
            "index": 1,
            "anonymous": false,
            "customer_id": "662b5c2b95ec86263b9580c9",
            "salutation": "Ms",
            "name": "Priya Sharma",
            "phone": {
                "countryCode": 91,
                "phone": 9810781650
            },
            "email": "priya.sharma@example.com"
        },],
    },
    {
        customerName: 'Emily Johnson',
        customerPhone: '+44 20 7123 4567',
        customerDesignation: 'Sales Executive',
        purpose: 'Client Meeting',
        startDate: '2024-07-15',
        endDate: '2024-07-20',
        hotelType: 'Business',
        cabType: 'SUV',
        inwardMode: 'Flight',
        inwardNumber: 'BA456',
        inwardArrivalTime: '2024-07-15T14:00:00',
        outwardMode: 'Flight',
        outwardNumber: 'AA789',
        outwardDepartureTime: '2024-07-20T18:30:00',
        airportPickup: 'Yes',
        dailyTravel: 'No',
        airportDrop: 'Yes',
        groupSize: 5,
        tourGuide: 'Michael Brown',
        specialRequests: 'Early check-in',
        paymentStatus: 'Pending',
        _id: "664c3f0fbb79bc16edc32378", bookingReference: 'XYZ789',
        passengers: [{
            "index": 2,
            "anonymous": false,
            "customer_id": "662b5c2b95ec86263b9580ca",
            "salutation": "Mr",
            "name": "Rahul Verma",
            "phone": {
                "countryCode": 91,
                "phone": 9810781651
            },
            "email": "rahul.verma@example.com"
        },],

    },
    {
        customerName: 'Sarah Wilson',
        customerPhone: '+61 2 9876 5432',
        customerDesignation: 'Marketing Director',
        purpose: 'Conference',
        startDate: '2024-08-10',
        endDate: '2024-08-15',
        hotelType: 'Resort',
        cabType: 'Minivan',
        inwardMode: 'Flight',
        inwardNumber: 'QF123',
        inwardArrivalTime: '2024-08-10T09:00:00',
        outwardMode: 'Flight',
        outwardNumber: 'QF456',
        outwardDepartureTime: '2024-08-15T20:00:00',
        airportPickup: 'Yes',
        dailyTravel: 'Yes',
        airportDrop: 'Yes',
        groupSize: 15,
        tourGuide: 'David Lee',
        specialRequests: 'Late check-out',
        paymentStatus: 'Paid',
        _id: "664c3f0fbb79bc16edc32378", bookingReference: 'PQR456',
        passengers: [{
            "index": 3,
            "anonymous": false,
            "customer_id": "662b5c2b95ec86263b9580cb",
            "salutation": "Mrs",
            "name": "Sunita Agarwal",
            "phone": {
                "countryCode": 91,
                "phone": 9810781652
            },
            "email": "sunita.agarwal@example.com"
        },],

    },

    {
        customerName: 'Lucas Martin',
        customerPhone: '+49 157 1234 5678',
        customerDesignation: 'IT Specialist',
        purpose: 'Tech Expo',
        startDate: '2024-09-05',
        endDate: '2024-09-10',
        hotelType: 'Standard',
        cabType: 'Compact',
        inwardMode: 'Flight',
        inwardNumber: 'LH789',
        inwardArrivalTime: '2024-09-05T13:00:00',
        outwardMode: 'Flight',
        outwardNumber: 'LH790',
        outwardDepartureTime: '2024-09-10T19:00:00',
        airportPickup: 'Yes',
        dailyTravel: 'Yes',
        airportDrop: 'Yes',
        groupSize: 1,
        tourGuide: 'Anna Klein',
        specialRequests: 'Window seat on flight',
        paymentStatus: 'Pending',
        _id: "664c3f0fbb79bc16edc32378", bookingReference: 'DEF123',
        passengers: [{
            "index": 4,
            "anonymous": false,
            "customer_id": "662b5c2b95ec86263b9580cc",
            "salutation": "Mr",
            "name": "Amit Kumar",
            "phone": {
                "countryCode": 91,
                "phone": 9810781653
            },
            "email": "amit.kumar@example.com"
        },],

    },
    {
        customerName: 'Olivia Brown',
        customerPhone: '+33 1 2345 6789',
        customerDesignation: 'HR Manager',
        purpose: 'HR Summit',
        startDate: '2024-10-20',
        endDate: '2024-10-25',
        hotelType: 'Boutique',
        cabType: 'Luxury Sedan',
        inwardMode: 'Train',
        inwardNumber: 'FR123',
        inwardArrivalTime: '2024-10-20T12:00:00',
        outwardMode: 'Flight',
        outwardNumber: 'AF456',
        outwardDepartureTime: '2024-10-25T17:00:00',
        airportPickup: 'Yes',
        dailyTravel: 'No',
        airportDrop: 'Yes',
        groupSize: 3,
        tourGuide: 'Sophie Dupont',
        specialRequests: 'Extra leg room',
        paymentStatus: 'Paid',
        _id: "664c3f0fbb79bc16edc32378", bookingReference: 'GHI456',
        passengers: [{
            "index": 5,
            "anonymous": false,
            "customer_id": "662b5c2b95ec86263b9580cd",
            "salutation": "Ms",
            "name": "Neha Singh",
            "phone": {
                "countryCode": 91,
                "phone": 9810781654
            },
            "email": "neha.singh@example.com"
        },],

    },
    {
        customerName: 'Noah Lee',
        customerPhone: '+82 10 1234 5678',
        customerDesignation: 'CEO',
        purpose: 'Investment Meeting',
        startDate: '2024-11-01',
        endDate: '2024-11-03',
        hotelType: 'Executive',
        cabType: 'Executive SUV',
        inwardMode: 'Flight',
        inwardNumber: 'KE123',
        inwardArrivalTime: '2024-11-01T09:00:00',
        outwardMode: 'Flight',
        outwardNumber: 'KE124',
        outwardDepartureTime: '2024-11-03T22:00:00',
        airportPickup: 'Yes',
        dailyTravel: 'No',
        airportDrop: 'Yes',
        groupSize: 1,
        tourGuide: 'Jay Kim',
        specialRequests: 'Private transfer',
        paymentStatus: 'Paid',
        _id: "664c3f0fbb79bc16edc32378", bookingReference: 'JKL789',
        passengers: [{
            "index": 6,
            "anonymous": false,
            "customer_id": "662b5c2b95ec86263b9580ce",
            "salutation": "Mr",
            "name": "Vikram Patel",
            "phone": {
                "countryCode": 91,
                "phone": 9810781655
            },
            "email": "vikram.patel@example.com"
        },],

    },
    {
        customerName: 'Mia Wang',
        customerPhone: '+86 21 1234 5678',
        customerDesignation: 'Product Manager',
        purpose: 'Product Launch',
        startDate: '2024-12-10',
        endDate: '2024-12-12',
        hotelType: 'Standard',
        cabType: 'Mini',
        inwardMode: 'Flight',
        inwardNumber: 'CA789',
        inwardArrivalTime: '2024-12-10T11:00:00',
        outwardMode: 'Flight',
        outwardNumber: 'CA790',
        outwardDepartureTime: '2024-12-12T20:00:00',
        airportPickup: 'Yes',
        dailyTravel: 'No',
        airportDrop: 'Yes',
        groupSize: 2,
        tourGuide: 'Chen Lu',
        specialRequests: 'Non-smoking room',
        paymentStatus: 'Pending',
        _id: "664c3f0fbb79bc16edc32378", bookingReference: 'MNO123',
        passengers: [{
            "index": 7,
            "anonymous": false,
            "customer_id": "662b5c2b95ec86263b9580cf",
            "salutation": "Mrs",
            "name": "Ritu Mehra",
            "phone": {
                "countryCode": 91,
                "phone": 9810781656
            },
            "email": "ritu.mehra@example.com"
        }],

    },
    {
        customerName: 'John Doe',
        customerPhone: '+1 123 456 7890',
        customerDesignation: 'Manager',
        purpose: 'Business Trip',
        startDate: '2024-06-01',
        endDate: '2024-06-10',
        hotelType: 'Luxury',
        cabType: 'Sedan',
        inwardMode: 'Flight',
        inwardNumber: 'AA123',
        inwardArrivalTime: '2024-06-01T10:30:00',
        outwardMode: 'Train',
        outwardNumber: 'TR456',
        outwardDepartureTime: '2024-06-10T18:00:00',
        airportPickup: 'Yes',
        dailyTravel: 'Yes',
        airportDrop: 'Yes',
        groupSize: 20,
        tourGuide: 'Jane Smith',
        specialRequests: 'Vegetarian meals',
        paymentStatus: 'Paid',
        _id: "664c3f0fbb79bc16edc32378", bookingReference: 'ABC123',
        passengers: [{
            "index": 8,
            "anonymous": false,
            "customer_id": "662b5c2b95ec86263b9580d0",
            "salutation": "Mr",
            "name": "Suresh Gupta",
            "phone": {
                "countryCode": 91,
                "phone": 9810781657
            },
            "email": "suresh.gupta@example.com"
        }],

    },
    {
        customerName: 'Emily Johnson',
        customerPhone: '+44 20 7123 4567',
        customerDesignation: 'Sales Executive',
        purpose: 'Client Meeting',
        startDate: '2024-07-15',
        endDate: '2024-07-20',
        hotelType: 'Business',
        cabType: 'SUV',
        inwardMode: 'Flight',
        inwardNumber: 'BA456',
        inwardArrivalTime: '2024-07-15T14:00:00',
        outwardMode: 'Flight',
        outwardNumber: 'AA789',
        outwardDepartureTime: '2024-07-20T18:30:00',
        airportPickup: 'Yes',
        dailyTravel: 'No',
        airportDrop: 'Yes',
        groupSize: 5,
        tourGuide: 'Michael Brown',
        specialRequests: 'Early check-in',
        paymentStatus: 'Pending',
        _id: "664c3f0fbb79bc16edc32378", bookingReference: 'XYZ789',
        passengers: [{
            "index": 9,
            "anonymous": false,
            "customer_id": "662b5c2b95ec86263b9580d1",
            "salutation": "Ms",
            "name": "Anjali Jain",
            "phone": {
                "countryCode": 91,
                "phone": 9810781658
            },
            "email": "anjali.jain@example.com"
        },],

    },


];