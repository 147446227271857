export const ENDPOINTS = {
    getAllSOSLogs: (page, sizePerPage, filterData) => {
        let s = `/api/soslog/data?sortBy=_id&sortAsc=false&rows=${sizePerPage}`;
        if (filterData) {
            if (filterData.from_date) s += "&from_date=" + filterData.from_date;
            if (filterData.to_date) s += "&to_date=" + filterData.to_date;
            if (filterData.status !== "") s += "&status=" + filterData.status;
        }
        return s + `&page=${page}`;
    },
    updateSOSLog: `/api/soslog/status/update`,
};

export const STRUCTURE = [
    {
        label: "From",
        description: "description...",
        filterLabel: "From",
        filterName: "from_date",
        type: "date",
    },
    {
        label: "To",
        description: "description...",
        filterLabel: "To",
        filterName: "to_date",
        type: "date",
    },
    {
        label: "Status",
        description: "description...",
        filterLabel: "Status",
        filterName: "status",
        type: "select",
        data: [
            { label: "Open", value: 0 },
            { label: "System Notified", value: 1 },
            { label: "Escalated", value: 2 },
            { label: "Resolved", value: 3 },
        ],
    },
];

