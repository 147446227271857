import { toJS } from "mobx";
import React, { useEffect, useState } from "react";
import { SelectField } from "../../SelectField/SelectField";
import FundACSelectService from "./FundACSelectService";
export default function FundACSelect(props) {
    const [fundAC, setFundAC] = useState([]);
    useEffect(() => {
        const fetch = async () => {
            try {
                await FundACSelectService.fetch();
                setFundAC(toJS(FundACSelectService.fundAC))
            } catch (error) {
            }
        }
        fetch();
    }, []);
    return (
        <React.Fragment>
            {fundAC.length > 0 &&
                <SelectField
                    className={props?.className}
                    onChange={props?.onChange}
                    data={fundAC}

                    value={props?.value}
                    label={"Account Select"}
                    error={
                        !props?.value
                            ? "Please Select Account"
                            : ""
                    }
                    showErr={props?.showErr}
                    inline={props?.inline}
                    borderNone={props?.borderNone}
                    RightComponent={props?.RightComponent}
                    onClickRight={props?.onClickRight}
                />}
            {!fundAC.length > 0 &&
                <SelectField
                    className={props?.className}
                    onChange={props?.onChange}
                    data={fundAC}

                    value={props?.value}
                    label={"Account Select"}
                    error={
                        !props?.value
                            ? "Please Select Account"
                            : ""
                    }
                    showErr={props?.showErr}
                    inline={props?.inline}
                    borderNone={props?.borderNone}
                    RightComponent={props?.RightComponent}
                    onClickRight={props?.onClickRight}
                />}
        </React.Fragment>
    )
}