import React, { useEffect, useState } from 'react';
// import DashboardService from "../../pages/Dashboard/DashboardService";
import { StatsCard } from './StatsCard';
import { doPOST } from '../../util/HttpUtil';

const statusMapping = {
    1: "Available",
    5: "Dispatched",
    6: "On Duty",
    7: "Off Duty",
    3: "Under Maintainance",
    4: "Sold",
    2: "In Active"
};

export const VehicleCard = ({
    showError,
    title = "",
    showDateSelect,
    showMonthSelect,
}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        fetchVehicleData();
    }, []);

  

    const fetchVehicleData = async () => {
        try {
            const body = {
                "existsFilter": {
                    "supplier_id": false
                },
                "groupBy": {
                    "status": "status"
                }
            };
            const response = await doPOST(`/api/vehicle/statistics`, body);
            if (response.status === 200) {
                const mappedData = {};
                response.data.forEach(item => {
                    const statusId = item._id.status;
                    const statusName = statusMapping[statusId];
                    const metaValue = item.count ?? "0";
                    mappedData[statusName] = { metaValue: metaValue, type: null };
                });
                setData(mappedData);
            }
        } catch (error) {
            console.log("Error fetching vehicle statistics:", error);
        }
    };


    const getTotalValue = () => {
        const totalValue = data["Available"]?.metaValue + data["In Active"]?.metaValue + data["Under Maintainance"]?.metaValue + data["Sold"]?.metaValue;
        const displayValue = totalValue != null && !isNaN(totalValue) ? totalValue : "--";
        return displayValue;
    };

    const getData = () => {
        return {
            "Available": { metaValue: data["Available"]?.metaValue ?? "0", type: null, status:1 },
            "Dispatched": { metaValue: data["Dispatched"]?.metaValue ?? "0", type: null, status:5  },
            "On Duty": { metaValue: data["On Duty"]?.metaValue ?? "0", type: null,status:6 },
            "Off Duty": { metaValue: data["Off Duty"]?.metaValue ?? "0", type: null, status:7 },
            "Under Maintainance": { metaValue: data["Under Maintainance"]?.metaValue ?? "0", type: null, status:3 },
            "Sold": { metaValue: data["Sold"]?.metaValue ?? "0", type: null, status:4 },
            "In Active": { metaValue: data["In Active"]?.metaValue ?? "0", type: null, status:2 },
        };
    };

    return (
        <StatsCard
            showDateSelect={showDateSelect}
            showMonthSelect={showMonthSelect}
            title={title}
            getData={getData}
            getTotalValue={getTotalValue}
            data={data}
            md = {12}
            borderedCards
        />
    );
};
