export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl, module) => {
    let url = `/api/inbox/grid?sortAsc=false&`;
    // if (module) url += `module=${module}&`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  bookingGrid: (bookingIds) => {
    let url = `/api/trip/grid?sortBy=startTime&sortAsc=false&`;
    if (bookingIds) url += `_id=in[${bookingIds}]&`;
    return url;
  },
  vehicleGrid: (vehicleIds) => {
    let url = `/api/vehicle/grid?`;
    if (vehicleIds) url += `_id=in[${vehicleIds}]&`;
    return url;
  },
  employeeGrid: (employeeIds) => {
    let url = `/api/employee/grid?`;
    if (employeeIds) url += `_id=in[${employeeIds}]&`;
    return url;
  },
  get: (id) => `/api/inbox/${id}/detail`,
  save: `/api/inbox/create`,
  delete: (id) => `/api/inbox/${id}/delete`,
  bulkDelete: (id) => `/api/inbox/delete`,
  update: `/api/inbox/update`,
};

export const STRUCTURE = [

];
