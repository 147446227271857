import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row,Col,Card, CardBody, CardTitle } from "reactstrap";
import {
  CardThreeBounce,
  CountrySelect,
  InputField,
  SelectField,
  Checkbox,
  Breadcrumbs,
  CorporateSelect,
  AddButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { RiDeleteBack2Fill } from "react-icons/ri";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "./MessageAgentConstant";
import MessageAgentService from "./MessageAgentService";


const EditAgents = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [showCorporates, setShowCorporates] = useState(false);

  const fetch = async () => {
    try {
      let mess = await MessageAgentService.get(editId);
      setData(mess);
      if(mess?.corp_id){
        setShowCorporates(true);
      }
    } catch (error) {
      showError(error);
    }
  };


  const deleteSMSCredential=(index)=> {
    const updatedSMSCredential= data?.SMSCredentials.slice(0, index).concat(data?.SMSCredentials.slice(index + 1));
    setData({
      ...data,
     SMSCredentials:updatedSMSCredential
    })
    return ;
  }



  useEffect(() => {
    setData(null);
    setLoading(true);
    if (editId) fetch();
    setLoading(false);
  }, [editId,MessageAgentService?.version]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <React.Fragment>
      {loading ? (
        <CardThreeBounce />
      ) : (
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
            <Row>
              <Col className="col-3">
              <Checkbox
                placeholder={t("For All")}
                checked={!showCorporates ? true : false}
                required={true}
                label={t("For All")}
                onClick={() =>{
                  setShowCorporates(!showCorporates)
                  setData({ ...data, corp_id: null})
                }}
                showErr={hasErr}
                type="text"
                className="mt-3"
              />
              </Col>
              <Col className="col-2 me-5" style={{marginLeft:"auto"}}>
              <SelectField
                  outline
                  placeholder={t("Select Status")}
                  data={SELECTSTRUCTURE?.STATUS}
                  value={data?.status}
                  label={t("Status")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      status: v?.value
                    })
                  }
                  type="text"
                />
              </Col>
            </Row>
          <Row>
              {showCorporates &&
                <CorporateSelect
                value={data?.corp_id}
                onChange={(v) => {
                  setData({ ...data, corp_id: v?.value })
                }
                }
                className="col-sm-4"
                style={{  zIndex: '4' }}
              />
              }
          </Row>
            <Row className={`d-flex justify-content-between col-"12"`}>
            <Col className="mt-4"><h3>SMS Credentials</h3></Col>
            </Row>
            <Card className="shadow">
              <CardTitle className="px-3">
                <div className={`d-flex justify-content-end col-"12"`}>
                  <AddButton
                    innerStyle={{backgroundColor:"#43887F",border:"none"}}
                    onClick={() =>
                      setData((prevData) => ({
                        ...prevData,
                        SMSCredentials: [
                          ...(prevData?.SMSCredentials || []),
                          {
                            countryCodes: [],
                            smsURL: ""
                          },
                        ],
                      }))
                    }
                  />
                </div>
              </CardTitle>
              <CardBody className="row w-100 mt-0 pt-0" data-pattern="d-flex priority-columns ng-scope">
                {data?.SMSCredentials?.map((SMSCredential, index) => {
                  return (
                    <>
                      <Card className="p-2 col-11">
                        <Col>
                          <CountrySelect
                            multi
                            isd
                            value={SMSCredential?.countryCodes}
                            required={true}
                            label={t("CountryCode")}
                            onChange={(selectedCountryCodes) => setData((prevData) => {
                              const updatedSMSCredentials = [...prevData?.SMSCredentials];
                              updatedSMSCredentials[index] = {
                                ...updatedSMSCredentials[index],
                                countryCodes: [...selectedCountryCodes],
                              };
                              return {
                                ...prevData,
                                SMSCredentials: updatedSMSCredentials,
                              };
                            })}
                            type="text"
                            className="col-sm-6"
                          />
                          <InputField
                            placeholder={t("Enter SMS Url")}
                            value={SMSCredential?.smsURL}
                            required={true}
                            label={t("Sms Url")}
                            onChange={(v) => setData((prevData) => {
                              const updatedSMSCredentials = [...prevData?.SMSCredentials];
                              updatedSMSCredentials[index] = {
                                ...updatedSMSCredentials[index],
                                smsURL: v,
                              };
                              return {
                                ...prevData,
                                SMSCredentials: updatedSMSCredentials,
                              };
                            })}
                            type="text"
                            className="col-sm-12"
                          />
                        </Col>
                      </Card>
                      <Col className="col-1 d-flex align-items-center">
                        {index != 0 ?
                          <div
                            onClick={() => {
                              deleteSMSCredential(index);
                            }}
                            style={{ cursor: "pointer" }}>
                            <i
                              style={{
                                fontSize: "20px",
                                border: "1px solid red",
                                borderRadius: "20px",
                                color: "red",
                              }}
                              className="dripicons-minus"
                            />
                          </div>
                          : null}
                      </Col>
                    </>
                  )
                })}
              </CardBody>
            </Card>
          <Row className="mt-4"><h3>Email Credentials</h3></Row>
                    <Card className='p-3 shadow-md'>
                    <Row>
                <InputField
                  placeholder={t("Enter SMTP Email")}
                  required={true}
                  value={data?.smtpCredentials?.smtpEmail}
                  error={data?.smtpCredentials?.smtpEmail?.length === 0 ? t("Enter SMTP Email") : ""}
                  showErr={hasErr}
                  label={t("SMTP Email")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      smtpCredentials: {
                        ...data?.smtpCredentials,
                        smtpEmail: v,
                      },
                    })
                  }
                  type="email"
                  className="col-sm-6"
                />
                <InputField
                  placeholder={t("Enter SMTP UserName")}
                  required={true}
                  value={data?.smtpCredentials?.smtpUsername}
                  error={data?.smtpCredentials?.smtpUsername?.length === 0 ? t("Enter SMTP UserName") : ""}
                  showErr={hasErr}
                  label={t("SMTP Username")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      smtpCredentials: {
                        ...data?.smtpCredentials,
                        smtpUsername: v,
                      },
                    })
                  }
                  type="text"
                  className="col-sm-6"
                />
              </Row>

              <Row className="mt-3">

                <InputField
                  placeholder={t("Enter SMTP Password")}
                  value={data?.smtpCredentials?.smtpPassword}
                  error={data?.smtpCredentials?.smtpPassword?.length === 0 ? t("Enter SMTP UserName") : ""}
                  showErr={hasErr}
                  label={t("SMTP Password")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      smtpCredentials: {
                        ...data?.smtpCredentials,
                        smtpPassword: v,
                      },
                    })
                  }
                  type="text"
                  className="col-sm-6"
                />
                <InputField
                  placeholder={t("Enter SMTP Host")}
                  value={data?.smtpCredentials?.smtpHost}
                  error={data?.smtpCredentials?.smtpHost?.length === 0 ? t("Enter SMTP SMTP Host") : ""}
                  showErr={hasErr}
                  label={t("SMTP Host")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      smtpCredentials: {
                        ...data?.smtpCredentials,
                        smtpHost: v,
                      },
                    })
                  }
                  type="text"
                  className="col-sm-6"
                />
              </Row>
              <Row>

                <InputField
                  placeholder={t("Enter SMTP Port")}
                  value={data?.smtpCredentials?.smtpPort}
                  label={t("SMTP Port")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      smtpCredentials: {
                        ...data?.smtpCredentials,
                        smtpPort: v,
                      },
                    })
                  }
                  error={data?.smtpCredentials?.smtpPort?.length === 0 ? t("Enter SMTP Port") : ""}
                  showErr={hasErr}
                  type="number"
                  className="col-sm-6"
                />

                <SelectField
                  placeholder={t("Enter SMTP Type")}
                  data={SELECTSTRUCTURE?.SMTPTYPE}
                  value={data?.smtpCredentials?.smtpType}
                  required={true}
                  label={t("SMTP Type")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      smtpCredentials: {
                        ...data?.smtpCredentials,
                        smtpType: v?.value,
                      },
                    })
                  }
                  type="text"
                  className="col-sm-6"
                />
              </Row>
                    </Card>
          <Row className="mt-4"><h3>Whatsapp Credentials</h3></Row>
                    <Card className='p-3 shadow-md'>
                    <Row>
                <InputField
                  placeholder={t("Enter API Request URL")}
                  required={true}
                  value={data?.whatsAppCredentials?.whatsAppApiRequestUrl}
                  error={data?.whatsAppCredentials?.whatsAppApiRequestUrl?.length === 0 ? t("API Request URL") : ""}
                  showErr={hasErr}
                  label={t("API Request URL ")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      whatsAppCredentials: {
                        ...data?.whatsAppCredentials,
                        whatsAppApiRequestUrl: v,
                      },
                    })
                  }
                  type="text"
                  className="col-sm-6"
                />
                <InputField
                  placeholder={t("Enter API key")}
                  required={true}
                  value={data?.whatsAppCredentials?.apiKey}
                  error={data?.whatsAppCredentials?.apiKey?.length === 0 ? t("Enter API key") : ""}
                  showErr={hasErr}
                  label={t("API key")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      whatsAppCredentials: {
                        ...data?.whatsAppCredentials,
                        apiKey: v,
                      },
                    })
                  }
                  type="text"
                  className="col-sm-6"
                />
                <InputField
                  placeholder={t("Enter From Phone")}
                  required={true}
                  value={data?.whatsAppCredentials?.fromPhone}
                  error={data?.whatsAppCredentials?.fromPhone?.length === 0 ? t("Enter From Phone") : ""}
                  showErr={hasErr}
                  label={t("From Phone")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      whatsAppCredentials: {
                        ...data?.whatsAppCredentials,
                        fromPhone: v,
                      },
                    })
                  }
                  type="text"
                  className="col-sm-6"
                />
                <InputField
                  placeholder={t("Enter Webhook Secret")}
                  required={true}
                  value={data?.whatsAppCredentials?.webhookSecret}
                  error={data?.whatsAppCredentials?.webhookSecret?.length === 0 ? t("Enter Webhook Secret") : ""}
                  showErr={hasErr}
                  label={t("Webhook Secret")}
                  onChange={(v) =>
                    setData({
                      ...data,
                      whatsAppCredentials: {
                        ...data?.whatsAppCredentials,
                        webhookSecret: v,
                      },
                    })
                  }
                  type="text"
                  className="col-sm-6"
                />
              </Row>
                    </Card>
        </Form>
      )}
    </React.Fragment>
  );
};

export default observer(EditAgents);
