import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CirclePicker, SketchPicker } from 'react-color'; // Import the color picker component
import { FaBullseye } from 'react-icons/fa';
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { DialogContext } from '../../../store/context/DialogContext';
import { doDELETE, doPUT } from '../../../util/HttpUtil';
import "./StatusMasterPicker.css";
import StatusMasterPickerService from './StatusMasterPickerService';
import { MdDoneAll } from 'react-icons/md';

const StatusMasterPicker = ({
  onChange = () => { },
  value,
  iconClassName,
  onlyValues = false,
  hideName,
  showAll,
  openLeft
}) => {
  const [statuses, setStatuses] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [actionMenuVisible, setActionMenuVisible] = useState({});
  const { showError } = useContext(DialogContext);

  const [editingStatusId, setEditingStatusId] = useState(null);
  const [editingLabel, setEditingLabel] = useState('');

  const [showColorPicker, setShowColorPicker] = useState({});
  const [selectedColor, setSelectedColor] = useState('#fff');

  const dropdownRef = useRef(null); // Ref for the dropdown to manage outside clicks

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
      setActionMenuVisible({})
      setInputValue('')
      setEditingStatusId(null)
      setShowColorPicker({})
      setEditingLabel('')
    }
  };

  useEffect(() => {
    if (!value && statuses.length) {
      onChange(statuses[0])
    }
  }, [value, statuses])

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showDropdown]);

  const toggleActionMenu = (statusId) => {
    setActionMenuVisible(statusId);
  };

  const fetchStatuses = async () => {
    try {
      // StatusMasterPickerService.fetch()
    } catch (error) {
      showError(error)
    }
    setLoading(false);
  };

  useEffect(() => {
    if (StatusMasterPickerService?.records?.length) {
      setStatuses(toJS(StatusMasterPickerService.records)?.map((statusMaster) => {
        if (
          statusMaster._id
        ) {
          return {
            ...statusMaster,
            rankValue: statusMaster?.value,
            value: statusMaster._id,
            label: statusMaster.name
          };
        }
        return null;
      }).filter(Boolean))
    }
  }, [StatusMasterPickerService?.records])

  useEffect(() => {
    fetchStatuses();
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // const handleCreateStatus = async () => {
  //   try {
  //     const newStatus = {
  //       color: getRandomColor(),
  //       label: inputValue,
  //       group: 'Some Group',
  //     };
  //     const response = await doPOST(`/api/status-master/create`, newStatus); // Assume response includes the created status
  //     StatusMasterPickerService.fetchForcefully()
  //     setInputValue('');
  //   } catch (error) {
  //     showError(error)
  //   }
  // };

  const handleStatusClick = (status) => {
    setActionMenuVisible({})
    onChange(status);
  };

  const handleUpdateStatus = async (status, newLabel) => {
    try {
      await doPUT(`/api/status-master/update`, {
        name: newLabel,
        color: status.color,
        module: status.module,
        value: status.rankValue,
        version: status.version,
        _id: status._id,
      });
      StatusMasterPickerService.fetchForcefully()
      setShowColorPicker({});
      setEditingStatusId(null)
      setActionMenuVisible({})
      setEditingLabel('')
    } catch (error) {
      showError(error)

    }
  };

  const handleDeleteStatus = async (statusId) => {
    try {
      await doDELETE(`/api/status-master/${statusId}/delete`);
      StatusMasterPickerService.fetchForcefully()
    } catch (error) {
      showError(error)
    }
  };

  const toggleColorPicker = (statusId, currentColor) => {
    setSelectedColor(currentColor);
    setShowColorPicker(statusId);
    setActionMenuVisible({})
  };

  const handleChangeComplete = async (color) => {
    try {
      const updatedStatus = {
        name: showColorPicker?.name,
        module: showColorPicker.module,
        value: showColorPicker.rankValue,
        version: showColorPicker.version,
        _id: showColorPicker._id,
        color: color
      };
      await doPUT(`/api/status-master/update`, updatedStatus);
      setShowColorPicker({});
      setEditingStatusId(null)
      StatusMasterPickerService.fetchForcefully()
      setActionMenuVisible({})
      setEditingLabel('')
    } catch (error) {
      showError(error)
    }
  };
  const handleHexInputChange = (event) => {
    const hexValue = event.target.value;
    if (/^#([0-9A-F]{3}){1,2}$/i.test(hexValue)) {
      setSelectedColor(hexValue);
    }
  };

  return (

    <div className='font-size-12 position-relative d-flex align-items-center' >
      <div onClick={(e) => e.stopPropagation()} className='tagIcon position-relative' >
        {hideName ?
          <span
            onClick={(e) => {
              e.stopPropagation();
              setShowDropdown(!showDropdown)
            }}>
            <FaBullseye size={16} color={statuses?.find((status) => status?.value == value)?.color} className='me-2' />
          </span> :
          <span
            style={{ backgroundColor: value == "All" ? "Green" : statuses?.find((status) => status?.value == value)?.color }}
            className={`FaStatusIcon ${iconClassName} d-flex align-items-center`}
            onClick={(e) => {
              e.stopPropagation();
              setShowDropdown(!showDropdown)
            }}>
            {value == "All" ?
              <MdDoneAll size={14} color={"#fff"} className="me-2" />
              : <FaBullseye color={`#fff`} className='me-2' />}
            {value == "All" ? <>All</> : <> {statuses?.find((status) => status?.value == value)?.name}</>}
          </span>}
        {showDropdown && (
          <div className="status-dropdown " style={openLeft ? { left: "10px" } : {}} ref={dropdownRef}>
            {/* <input
              type="text"
              placeholder="Search"
              value={inputValue}
              onChange={handleInputChange}
            /> */}
            {loading ? (
              <div>Loading...</div>
            ) : (
              <ul>
                {statuses.map(status => (
                  <li
                    key={status._id}
                    className='action-menu-list-item'
                    onClick={() => handleStatusClick(status)}
                  >
                    {editingStatusId === status._id && !showColorPicker?._id ? (
                      <input
                        type="text"
                        value={editingLabel}
                        onChange={(e) => setEditingLabel(e.target.value)}
                        onBlur={() => setEditingStatusId(null)}
                        onKeyDown={(e) => e.key === 'Enter' && handleUpdateStatus(status, editingLabel)}
                        autoFocus
                        className='m-0'
                        style={{ height: "30px" }}
                      />
                    ) : (
                      <div className='d-flex align-items-center'>
                        <FaBullseye color={`${status.color}`} className='me-2' />
                        <span className="action-menu-label">{status.label}</span>
                        <div
                          className="action-menu-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleActionMenu(status._id);
                          }}
                        >
                          <PiDotsThreeVerticalBold size={20} />
                        </div>
                      </div>
                    )}
                    {actionMenuVisible === status._id && !editingStatusId && (
                      <div className="action-menu" onClick={(e) => e.stopPropagation()}>
                        <ul>
                          <li onClick={() => { setEditingStatusId(status._id); setEditingLabel(status.label); }}>Rename</li>
                          {/* <li onClick={() => handleDeleteStatus(status._id)}>Delete</li> */}
                          <li onClick={() => toggleColorPicker(status, status.color)}>Change Color</li>
                        </ul>
                      </div>
                    )}
                  </li>
                ))}

                  {showAll ?      <li
                  className='action-menu-list-item'
                  onClick={() => {
                    setActionMenuVisible({})
                    onChange({ value: "All" });
                  }}
                >

                  <div className='d-flex align-items-center'>
                    <MdDoneAll size={14} color={"green"} className="me-2" />
                    <span className="action-menu-label">Show All</span>

                  </div>
                  </li> :  <></>}

                {showColorPicker?._id && (
                 <div className="color-picker-container card">
                                        <CirclePicker
                                            color={selectedColor}
                                            onChangeComplete={(color) => setSelectedColor(color.hex)}
                                            width="180px"
                                        />

                                        <input
                                            type="text"
                                            value={selectedColor}
                                            className='mt-2 '
                                            onChange={handleHexInputChange}
                                            placeholder="#000000"
                                        />
                    <button className="color-picker-save-btn" onClick={() => {
                      handleChangeComplete(selectedColor);
                      setShowColorPicker({}); // Optionally hide the picker after saving
                    }}>
                      Save
                    </button>
                  </div>
                )}
              </ul>
            )}
          </div>
        )}
      </div>


    </div>
  );
};

export default observer(StatusMasterPicker);
