import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../../../../../util/HttpUtil";

const ENDPOINTS = {
    grid: (itinerary_id) => {
        let url = `/api/itinerary-bids/grid?itinerary_id=${itinerary_id}`;
        return url;
    },
    get: (id) => `/api/itinerary-bids/${id}/detail`,
    save: `/api/itinerary-bids/create`,
    delete: (id) => `/api/itinerary-bids/${id}/delete`,
    bulkDelete: (id) => `/api/itinerary-bids/delete`,
    update: `/api/itinerary-bids/update`,
};

class Service {
    records = [];
    STRUCTURE = [];
    page = 1;
    rowsPerPage = null;
    filterOption = "";
    total = -1;
    itinerary_id = null
    constructor() {
        makeAutoObservable(this);
    }

    fetch = async (itinerary_id) => {
        this.itinerary_id = itinerary_id
        const response = await doGET(
            ENDPOINTS.grid(itinerary_id)
        );
        if (response?.status === 200) {
            this.records = response.data.rows;
            return response.data.rows
        }
    };

    edit = async function (data, id) {
        const response =  await doPUT(ENDPOINTS?.update, data);
        if (response.status === 200) {
            this.fetch(this.itinerary_id)
            return response.data ? response.data : response;
        }
    };

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    save = async (data, blockFetch) => {
        if (!blockFetch) this.records.push(data);
        const response = await doPOST(ENDPOINTS.save, data);
        if (!blockFetch) this.fetch(this.itinerary_id)
        return response?.data;
    };

    delete = async (id) => {
        const response = await doDELETE(ENDPOINTS.delete(id));
        if (response.status === 200) {
            this.fetch(this.itinerary_id)
        }
    };

    bulkDelete = async (ids = []) => {
        const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
        if (response.status === 200) {
            this.fetch();
        }
    };



}

const SupplierFareService = new Service();
export default SupplierFareService;

