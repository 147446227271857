import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from "react";
import { Button, CardBody, Col, Row } from "reactstrap";
import { SaveButton } from "../../../components";
import SupplierCompany from "../../../components/Dropdowns/SupplierCompany/SupplierCompany";
import { formatSecondsToDuration } from "../../../helpers/dateHelper";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { getDaysDifferenceInDays } from "../../../util/DateUtil";
import useDebounce from "../../../util/Debounce";
import BookingFee from "../../Invoice/Fee/BookingFee";
import { TRIP_CHARGES } from "../BookingConstant";
import BookingService from "../BookingService";
import FeeRate from "./FeeRate";
import PaymentCollectedTable from "./PaymentCollected/PaymentCollectedTable";
import "./style.css";

const Billing = forwardRef(({
  billing,
  setBilling,
  onChange = () => {},
  tripCorpAsClient = false,
  loading,
  onDelete,
  type,
  index,
  key,
  billingEditState,
  setBillingEditState = () => {},
  onTripChange,
  trip,
  forInvoice, 
  customerType,
  setBillingUpdated,
  tax,
  billingUpdatedObject,
}, billingRef) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [customRate, setCustomRate] = useState(null);

  useEffect(() => {
    if(customRate===null){
      setCustomRate(!billing?.feeRate?.rate_id && trip._id ? true : false);
    }
  }, [billing?.feeRate?.rate_id]);

  // useEffect(() => {
  //   if (tax?.updated && billing && tax?._id) {
  //     let feeGroupToUpdate = (billing?.fee?.feeGroups ?? [])?.find(v => v?.name == "TRIP CHARGES")
  //     if (feeGroupToUpdate) {
  //       feeGroupToUpdate.taxItem = {
  //         "tax_id": tax?._id,
  //         "taxes": tax?.items
  //       }
  //       const feeGroupIndex = billing.fee.feeGroups.findIndex(v => v.name == "TRIP CHARGES");
  //       if (feeGroupIndex !== -1) {
  //         billing.fee.feeGroups[feeGroupIndex] = feeGroupToUpdate;
  //       }
  //       saveBilling({ billingPayload: billing })
  //     }
  //   }
  // }, [tax?._id, tax?.updated]);


  const saveBilling = async ({ billingPayload }) => {
    try {
      const response = billingPayload?._id
        ? await BookingService.editBillings(billingPayload)
        : await BookingService.createBilling(billingPayload);
      if (response?.data) {
        if (type === "Client") {
          setBilling(response.data);
        }
        setBillingEditState({
          client: false,
          supplier: -1
        })
   
        onChange(response?.data);
        return response?.data;
      }
    } catch (error) {
      showError(error);
    } finally {
      if (type == "Supplier") {
        let pendingSpplierBillings = BookingService.pendingSpplierBillings;
        let pendingSpplierBillingsIndex = pendingSpplierBillings.indexOf(index);
        if (pendingSpplierBillingsIndex !== -1) {
          pendingSpplierBillings.splice(pendingSpplierBillingsIndex, 1);
        }
        BookingService.setPendingSupplierBillings(pendingSpplierBillings)
      } else if (type == "Client") {
        // BookingService.setTripId(null);
      }
    }
    return null;
  }

  const preSaveBilling = async () => {
    if (!billing) {
      return;
    }
    console.log(BookingService?.trip_id)
    if (!billingUpdatedObject?.updated || (!trip._id && !BookingService?.trip_id)) {
      return;
    }
    if (!billing.trip_id || trip?._id !== BookingService?.trip_id) {
      billing.trip_id = BookingService?.trip_id ?? trip._id;
    }
    if (type === "Client") {
      billing.type = 1;
      billing.vendor_id = null;
      billing.vendorCompany_id = null;
      if (trip.client?.client_id) {
        billing.client_id = trip.client?.client_id;
        billing.clientCompany_id = trip.client?.company_id;
      } else {
        billing.client_id = null;
        billing.clientCompany_id = null;
      }
    }
    if (type === "Supplier") {
      billing.type = 2;
      billing.client_id = null;
      billing.clientCompany_id = null;
    }
    if (billing?.feeRate?.rateName?.name) {
      billing.feeRate.name = billing.feeRate?.rateName?.name;
    }
    if (type === "Supplier" && !billing.vendor_id) {
      return;
    }



    saveBilling({ billingPayload: billing })

  };

  useImperativeHandle(billingRef, () => ({
    async billingSaveFromRef() {
      setBillingEditState({
        client: false,
        supplier: -1
      })
      setBillingUpdated(false);
      // await preSaveBilling({});
    }
  }));

  const isFeeItemPresentInFare = (charges, itemName) => {
    if (charges?.length) {
      for (let i = 0; i < charges.length; i++) {
        if (charges[i].name === itemName) {
          return true;
        }
      }
    }
    return false;
  }

  const handleFareItemsNotSelectedInBillings = (myBilling) => {
    if (myBilling?.feeRate) {
      if (myBilling.feeRate.allotedKMs && billingUpdatedObject.distance) {
        myBilling.fee = !myBilling.fee ? {} : myBilling.fee;
        myBilling.fee.feeGroups = !myBilling.fee?.feeGroups?.length ? [] : myBilling.fee?.feeGroups;
        for (let i = 0; i < myBilling.fee.feeGroups.length; i++) {
          if (!myBilling.fee.feeGroups[i]) myBilling.fee.feeGroups[i] = { name: "TRIP CHARGES" };
          if (myBilling.fee.feeGroups[i].name && myBilling.fee.feeGroups[i].name === "TRIP CHARGES") {
            if (!myBilling.fee.feeGroups[i].charges?.length) {
              myBilling.fee.feeGroups[i].charges = [];
            }
            if (!isFeeItemPresentInFare(myBilling.fee.feeGroups[i].charges, "DistanceFare")) {
              myBilling.fee.feeGroups[i].charges.push({
                name: "DistanceFare",
                units: 0,
                unitCost: 0
              });
            }
          }
        }
      }
    }
    return myBilling;
  }

  const isRoundingToMinutes = (rateExtn) => {
    return rateExtn == null || rateExtn.timeRounding == null || rateExtn.timeRounding === 0;
  }

  const expenseList = ["Parking Fare", "Toll Tax", "FasTag", "State Tax", "MCD", "Other"];

  const handleClientBillingCalculation = useDebounce(async () => {
    let myBilling = billing;

    // myBilling = handleFareItemsNotSelectedInBillings(myBilling)
    if (billingUpdatedObject && billingUpdatedObject.updated) {
      try {
        // if (myBilling?.fee?.feeGroups?.length && isRoundingToMinutes(myBilling?.feeRate?.rateExtn)) {
        //   for (let j = 0; j < myBilling?.fee?.feeGroups.length; j++) {
        //     const feeGroup = myBilling?.fee?.feeGroups[j];
        //     if (feeGroup?.charges?.length) {
        //       for (let i = 0; i < feeGroup.charges?.length; i++) {
        //         if (feeGroup.charges[i].name === "TimeFare") {
        //           feeGroup.charges[i].unitCost = feeGroup.charges[i].unitCost * 60;
        //         }
        //       }
        //     }
        //   }
        // }

        let updatedFee = {};
        if (!customRate) {
          if (myBilling?.fee) {
            if (myBilling?.fee.feeGroups?.length) {
              updatedFee.feeGroups = [];
              for (let idx = 0; idx < myBilling?.fee.feeGroups.length; idx++) {
                const FG = myBilling?.fee.feeGroups[idx];
                const localFG = { ...FG, name: FG.name, charges: [] };
                if (FG.charges?.length) {
                  for (let jdx = 0; jdx < FG.charges.length; jdx++) {
                    const charge = FG.charges[jdx];
                    if (charge && (charge.extraCharges || expenseList.indexOf(charge.name) !== -1)) {
                      localFG.charges.push(charge);
                    }
                  }
                }
                updatedFee.feeGroups.push(localFG);
              }
            }
          }
        } else {
          updatedFee = myBilling?.fee;
        }

        if (myBilling.feeRate?.rateName?.name) {
          myBilling.feeRate.name = myBilling.feeRate?.rateName?.name;
        }

        myBilling.fee = updatedFee;
        myBilling = BookingService.updateBillingsForNonTaxable(myBilling, billingUpdatedObject.expenses, myBilling?.feeRate);

        const requestBody = {
          trip: {
            startTime: billingUpdatedObject?.startTime,
            duration: billingUpdatedObject?.duration,
            tripExtn: {
              ...trip?.tripExtn,
              distance: billingUpdatedObject.distance,
              preStartKMs: billingUpdatedObject.preStartKMs,
              postStopKMs: billingUpdatedObject.postStopKMs,
              preStartMins: billingUpdatedObject.preStartMins,
              postStopMins: billingUpdatedObject.postStopMins,
            },
          },
          billing: {
            // fee: myBilling?.fee,
            fee: updatedFee,
            feeRate: myBilling?.feeRate
          },
          tripExpenses: billingUpdatedObject.expenses
        }

        const billing = await BookingService.doBillingCalculations(requestBody);
        if (billing) {
          if (setBilling) {
            setBilling(currentBilling => ({
              ...currentBilling,
              feeRate: { ...billing?.feeRate },
              fee: { ...billing?.fee }
            }));
          }
        }
        setBillingUpdated(true);
      } catch (e) {
        console.log(e);
      }
      // setLoading(false)
    }
  }, 1000)

  useEffect(() => {
    handleClientBillingCalculation();
  }, [JSON.stringify(billingUpdatedObject)]);

  const handleBillingChange = useCallback(
    (fieldName, fieldValue,billingUpdated=true) => {
      if (fieldName && fieldValue) {
        const nBilling = { ...billing } || {};
        console.log("nBilling", nBilling);

        nBilling[fieldName] = fieldValue;
        onChange(nBilling,billingUpdated);
      }
    },
    [billing, onChange]
  );

  const handleFeeCalculation = (localFee) => {
    if (localFee) {
      localFee.subTotal =
        localFee.feeGroups?.reduce(
          (acc, feeGroup) => acc + (feeGroup?.subTotal || 0),
          0
        ) || 0;
      localFee.totalDiscounts =
        localFee.feeGroups?.reduce(
          (acc, feeGroup) => acc + (feeGroup?.totalDiscounts || 0),
          0
        ) || 0;
      localFee.totalTaxes =
        localFee.feeGroups?.reduce(
          (acc, feeGroup) => acc + (feeGroup?.totalTaxes || 0),
          0
        ) || 0;
      localFee.total =
        localFee.feeGroups?.reduce(
          (acc, feeGroup) => acc + (feeGroup?.total || 0),
          0
        ) || 0;
    }
    return localFee;
  };

  const handleFeeChange = useCallback(
    (updatedFeeData) => {
      if (updatedFeeData) {
        const nBilling = {
          ...billing,
        };
        nBilling.fee = updatedFeeData;
        onChange(nBilling);
      }
    },
    [billing, onChange]
  );

  const updateFee = useCallback(
    (fieldName, fieldValue) => {
      if (fieldName && fieldValue) {
        let feeCopy = { ...billing?.fee } || {};
        feeCopy[fieldName] = fieldValue;
        if (fieldName === "feeGroups") {
          feeCopy = handleFeeCalculation(feeCopy);
        }
        handleFeeChange(feeCopy);
      }
    },
    [billing?.fee, handleFeeChange]
  );

  const addFeeGroup = useCallback(() => {
    const newFeeGroup = {
      charges: [{}],
      taxItem: {
        taxes: [{

        }]
      }
    }
    if (!billing?.fee?.feeGroups) {
      updateFee("feeGroups", [newFeeGroup]);
    } else {
      updateFee("feeGroups", [...billing?.fee.feeGroups, newFeeGroup]);
    }
  }, [billing?.fee, updateFee]);

  const removeFeeGroup = useCallback(
    (feeGroupIndex) => {
      if (billing?.fee?.feeGroups[feeGroupIndex]?.name === TRIP_CHARGES) {
        return;
      }
      const fgArray = billing?.fee?.feeGroups?.filter(
        (_, fIndex) => fIndex !== feeGroupIndex
      );
      updateFee("feeGroups", fgArray);
    },
    [billing?.fee?.feeGroups, updateFee]
  );

  const isVisible = (billingIndex) => {
    if (type == "Client") {
      return billingEditState?.client
    } else if (type == "Supplier") {
      return (billingEditState?.supplier == billingIndex)
    }
  }

  const calcTravelDays = (rateCycle) => {
    if (rateCycle == null) {
      return 1;
    }
    const startTime = trip.startTime;
    const stopTime = trip.duration && trip.duration > 0 ? trip.startTime + trip.duration * 60 : trip.startTime;
    if (rateCycle === 1) {
      if (startTime != null && stopTime != null) {
        return getDaysDifferenceInDays(startTime, stopTime);
      } else {
        return 1;
      }
    }
    if (rateCycle === 2) {
      const minutes = trip.duration && trip.duration > 0 ? trip.duration : 0;
      let days = parseInt(minutes / 1440);
      if (days === 0 || minutes > days * 1440)
        days = days + 1;
      return days;
    }
    return 1;
  }

  return (
    <CardBody className="p-0 pt-0" key={key}>
      <div className="d-flex justify-content-between">
      {
        !forInvoice ? 
        <div className={`ms-4 w-100`}>
          <div className="d-flex  align-items-center">
            <div className="mt-3 col-6 font-size-13">
              <span style={{ color: '#999999' }}>
                {t(`Extra Trip Duration`)} : &nbsp;
              </span>
              <span>
                {formatSecondsToDuration((Math.ceil(trip?.duration * 60) - Math.ceil(billing.feeRate?.allotedMins * calcTravelDays(billing.feeRate?.rateExtn?.rateCycle) * 60)) > 0 ? (Math.ceil(trip?.duration * 60) - Math.ceil(billing.feeRate?.allotedMins * calcTravelDays(billing.feeRate?.rateExtn?.rateCycle) * 60)) : 0)}
              </span>
            </div>
            <div className="mt-3 col-6 font-size-13">
              <span style={{ color: '#999999' }}>
                {t(`Extra Trip Distance`)} : &nbsp;
              </span>
              <span>
                {(Math.ceil(trip?.tripExtn?.distance) - Math.ceil(billing.feeRate?.allotedKMs * calcTravelDays(billing.feeRate?.rateExtn?.rateCycle))) > 0 ? (Math.ceil(trip?.tripExtn?.distance) - Math.ceil(billing.feeRate?.allotedKMs * calcTravelDays(billing.feeRate?.rateExtn?.rateCycle))) : 0} Kms
              </span>
            </div>
          </div>
          {isVisible(index) && (
            <div>
              {tripCorpAsClient ? (
                <div className="d-flex flex-sm-row flex-column">
                  <SupplierCompany
                    className="col-12"
                    supplierId={billing?.vendor_id}
                    value={billing?.vendorCompany_id}
                    onChange={(v) => {
                      const nBilling = { ...billing } || {};
                      nBilling["vendor_id"] = v?.supplier_id;
                      nBilling["vendorCompany_id"] = v?.value;
                      onChange(nBilling);
                    }}
                    label={"Supplier"}
                  />
                </div>
              ) : (
                <></>
              )}
              {((type === "Supplier" && billing?.vendor_id) || type == "Client") && <FeeRate
                trip={trip}
                fee={billing?.fee}
                supplier_id={type === "Supplier" ? billing?.vendor_id : null}
                corpType={type}
                customerType={customerType}
                type="billing"
                className="pt-3 col-12"
                value={billing?.feeRate}
                customRate={customRate}
                setCustomRate={setCustomRate}
                onTripChange={onTripChange}
                onChange={(v,billingUpdated=true) => {
                  handleBillingChange("feeRate", v,billingUpdated)
                }}
                hardUpdateBillingFromRate={(v) => {
                  onChange({
                    ...billing,
                    fee: v.fee,
                    feeRate: v.feeRate,
                  })
                }}
                billingUpdatedObject={billingUpdatedObject}
              />}
            </div>
          )}
        </div> : null }

        {
        <div className="d-flex justify-content-end align-items-center">
          {loading ? (
            isVisible(index) && (
              <Button
                className="mx-2"
                style={{ height: "28px", padding: "2px 4px", width: "90px" }}
                color="danger"
              >
                <ThreeBounce size={10} color="#FFFFFF" />
              </Button>
            )
          ) : (
            <></>
          )}
          </div>}
      </div>
      <div
      >
        {!(
          isVisible(index)
        ) && (
            <div
              className={`d-flex justify-content-${type == "Supplier" ? "between" : "end"} mt-2`}
              style={{ width: type == "Supplier" ? "100%" : "inherit" }}
            >
              {type == "Supplier" && !(billing?.invoice_id || billing?.bill_id || billing?.paymentMade_id) ? <button className="ms-4 d-flex justify-content-center align-items-center btn btn-outline-danger" style={{
                cursor: 'pointer',
                height: "20px",
                padding: "6px"
              }} onClick={onDelete}>
                <i
                  style={{
                    fontSize: "12px",
                    marginTop: "2px",
                    marginRight: "3px",
                  }}
                  className="bx bx-trash "
                ></i>
                <div style={{
                  fontSize: 12,
                  marginLeft: "5px"
                }}>Delete</div>
              </button> : null}

              {(billing?.invoice_id || billing?.bill_id || billing?.paymentMade_id || forInvoice) ? null :
                <i className="uil uil-pen font-size-18 me-3 d-flex" onClick={(e) => {
                  e.stopPropagation();
                  if (type == "Client") {
                    setBillingEditState({
                      ...billingEditState,
                      client: true
                    })
                  } else if (type == "Supplier")
                    setBillingEditState({
                      ...billingEditState,
                      supplier: index
                    })
                  // setBillingUpdated(true)
                }} style={{ cursor: "pointer" }} />}

            </div>
          )}
      </div>
      <div className="d-flex flex-1 flex-column">
        {!isVisible(index) && type == "Supplier" && (
          <div className="d-flex align-items-center pt-1 ps-2">
            <div
              className="ms-3 font-size-14"
              style={{
                fontWeight: "600",
              }}
            >
              Supplier:{" "}
            </div>
            <div className="d-flex flex-sm-row flex-column ms-2 font-size-14">
              <SupplierCompany
                className="col-4"
                asLabel
                supplierId={billing?.vendor_id}
                value={billing?.vendorCompany_id}
                onChange={(v) => {
                  const nBilling = { ...billing } || {};
                  nBilling["vendor_id"] = v?.supplier_id;
                  nBilling["vendorCompany_id"] = v?.value;
                  onChange(nBilling);
                }}
                label={"Supplier"}
              />
            </div>
          </div>
        )}
        <BookingFee
          billingIndex={index}
          forInvoice={forInvoice}
          fee={billing?.fee}
          billingEditState={billingEditState}
          onChange={handleFeeChange}
          type={type}
        />
      </div>


      <Row>
        <Col>
          <div className={`d-flex justify-content-${type == "Client" ? "between" : "end"}`}>
            {isVisible(index) ? (<div className={`d-flex w-100 justify-content-${type == "Client" && billing?.fee?.feeGroups?.length < 2 ? "between" : "end"} align-items-center`}>
              {type == "Client" && billing?.fee?.feeGroups?.length < 2 && <div
                className="btn btn-outline-dark ms-4"
                style={{
                  padding: "3px",
                  paddingRight: "6px",
                }}
                onClick={() => {
                  addFeeGroup()
                }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <i className="bx bx-plus  font-size-18 me-1" />
                  {t("Group")}
                </div>
              </div>}

              <div className="d-flex align-items-center">
                {type == "Supplier" && isVisible(index) ? (

                  <button
                    className=" me-2 d-flex align-items-center justify-content-center btn btn-outline-danger"

                    style={{
                      padding: "1px",
                      height: "28px",
                      width: "80px",
                      cursor: "pointer"
                    }}

                    onClick={onDelete}>
                    <i
                      style={{
                        fontSize: "12px",
                        marginTop: "2px",
                        marginRight: "5px",
                      }}
                      className="bx bx-trash"
                    ></i>
                    <div style={{
                      fontSize: 12,
                      marginLeft: "5px"
                    }}>Delete</div>
                  </button>
                ) : (
                  <></>
                )}


                <button
                  className=" d-flex align-items-center justify-content-center btn btn-outline-dark"
                  onClick={() => {
                    setBillingEditState({
                      supplier: -1,
                      client: false
                    })
                  }}
                  style={{
                    padding: "1px",
                    height: "28px",
                    // marginRight: "10px",
                    width: "80px",
                    cursor: "pointer"
                  }}
                >
                  <span className=' '>
                    {t("Cancel")}
                  </span>
                </button>
                {/* {(trip?._id && !billingUpdatedObject?.updated) && <SaveButton
                  onClick={() => {
                    preSaveBilling({});
                  }}
                />} */}
              </div>
            </div>
            ) : (
              <div></div>
            )}
          </div>
        </Col>
      </Row>

      {type === "Client" &&
        <div>
          <PaymentCollectedTable trip_id={trip?._id} />
        </div>
      }

    </CardBody>
  );
});

export default observer(Billing);
