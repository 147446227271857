// @flow
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { doPUT } from "../../../util/HttpUtil";
import SpaceService from "../listView/SpaceService";
import reorder from "../reorder";
import LeadMasterService from "./LeadMasterService";
import Column from "./Column";
import LeadRow from "./LeadRow";
import "./style.scss";

const Board = ({
  isCombineEnabled = false,
  isList,
  showStatus,
  initial,
  useClone,
  containerHeight,
  isSidebarOpen,
  withScrollableColumns
}) => {
  const [columns, setColumns] = useState(initial);
  const [statusMasters, setStatusMasters] = useState([]);
  const [refresher, setRefresher] = useState({});

  const onDragEnd = async (result) => {
    if (result.combine) {
      if (result.type === "COLUMN") {
        const shallow = [...statusMasters];
        shallow.splice(result.source.index, 1);
        setStatusMasters(shallow);
        return;
      }

      const column = columns[result.source.droppableId];
      const withQuoteRemoved = [...column];

      withQuoteRemoved.splice(result.source.index, 1);

      const orderedColumns = {
        ...columns,
        [result.source.droppableId]: withQuoteRemoved
      };
      setColumns(orderedColumns);
      return;
    }


    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;


    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (result.type === "COLUMN") {
      const reorderedorder = reorder(statusMasters, source.index, destination.index);
      setStatusMasters(reorderedorder);
      return;
    }
    const currentleadData = JSON.parse(result.draggableId)
    setRefresher({
      leadObject: { ...currentleadData, status: destination.droppableId },
      sourceId: source.droppableId,
      destinationId: destination.droppableId,
      sourceIndex: source.index,
      destinationIndex: destination.index
    })
    try {
      const response = await doPUT('/api/lead/update',
        {
          ...currentleadData,
          status: destination.droppableId,
          rank: destination.index,
        });
      setRefresher({
        leadObject: response?.data,
        destinationId: destination.droppableId,
        destinationIndex: destination.index,
        onlyUpdateLeadObject: true
      })
    } catch (error) {
      console.log(error)
    }

  };

  const updatestatusMasters = async () => {
    if (LeadMasterService?.statusMastersRecords?.length) {
      const statuses = LeadMasterService.statusMastersRecords;
      if (isList && showStatus && showStatus != "All") {
        setStatusMasters([showStatus])
      } else {
        setStatusMasters(statuses);
      }
    }
  };

  useEffect(() => {
    if (isList && showStatus && showStatus != "All") {
      setStatusMasters([showStatus])
    } else {
      updatestatusMasters()
    }
  }, [isList, showStatus, LeadMasterService.statusMastersRecords, LeadMasterService?.statusNameMap])

  useEffect(() => {
    updatestatusMasters();
  }, [LeadMasterService?.statusMastersRecords, LeadMasterService?.statusNameMap]);

  return (
    <>
      <div className="d-flex flex-column ">
        {/* {JSON.stringify(statusMasters)} */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="board"
            type="COLUMN"
            direction="horizontal"
            ignoreContainerClipping={Boolean(containerHeight)}
            isCombineEnabled={isCombineEnabled}
          >
            {(provided) => (
              <div className={isList ? "list-crm-container" : `board-container ${!isSidebarOpen ? ""  : " board-container--open "}`} ref={provided.innerRef} {...provided.droppableProps}>
                {statusMasters.map((key, index) => {
                  if (key > 0) {
                    const Component = isList ? LeadRow : Column;
                    return (
                      <div>
                        <Component
                          refresher={refresher}
                          key={`${LeadMasterService.statusNameMap[key]?.name}=${SpaceService?.selectedSpace?._id}` ?? ""}
                          index={index}
                          title={key}
                          isScrollable={withScrollableColumns}
                          isCombineEnabled={isCombineEnabled}
                          useClone={useClone}
                        />
                      </div>
                    );
                  }
                })}

                {provided.placeholder}

                {/* {!isList &&
                  <AddStatus onAdd={() => {  }} />
                } */}

              </div>
            )}
          </Droppable>
        </DragDropContext>

      </div>
    </>
  );
};

Board.defaultProps = {
  isCombineEnabled: false
};

Board.propTypes = {
  isCombineEnabled: PropTypes.bool
};

export default observer(Board);
