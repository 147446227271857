import React from "react";
import { SelectField } from "../../components";
import "./style.css";

const GroupedSortSelectField = ({ sidxOptions, sordOptions, value, onChange }) => {
  const handleSidxChange = (sortBy) => {
    onChange({ ...value, sortBy });
  };

  const handleSordChange = (sortAsc) => {
    onChange({ ...value, sortAsc: sortAsc === "true" });
  };

  return (
    <div className="zr_filter_sort-input-group d-flex align-items-center">
      <SelectField
        data={sidxOptions}
        value={sidxOptions.find(option => option.value === value.sortBy)?.value}
        onChange={(v) => handleSidxChange(v.value)}
        inline
        innerContainerClassName="rounded-top-right-0 rounded-bottom-right-0 "
        label="Sort By"
        className=" me-0 "
      />
      <SelectField
        data={sordOptions}
        value={sordOptions.find(option => option.value === (value.sortAsc ? "true" : "false"))?.value}
        onChange={(v) => handleSordChange(v.value)}
        inline
        inlineWithoutLabel
        innerContainerClassName="rounded-top-left-0 rounded-bottom-left-0 "
        className=""
      />
    </div>
  );
};

export default GroupedSortSelectField;
