import React from 'react';
import CommentItem from './commentsItem';
import {
    Button,
} from "reactstrap";
import { BsReplyAll } from "react-icons/bs";
import { Documents } from '../../components';


const CommentList = ({ comments, toggleCommentsModal,ticketStatus,id }) => {
    return (
        <div className='px-2'>
            <div className='d-flex pt-0 ticket-comments '>
                <div className='col-9'>
                    {comments?.map((comment, index) => (
                        <CommentItem key={index} {...comment} />
                    ))}
                                {ticketStatus!=2 &&
                        <Button
                        color="info"
                        className="d-flex col-4 gap-2 col-md-2 waves-light"
                        style={{ fontSize: 12, height: "36px", float: "right" }}
                        onClick={toggleCommentsModal}
                    >
                        <BsReplyAll size={20} />
                        <span>Reply</span>
                    </Button>
            }
                </div>
                <div className="col-3 px-2">
                    <Documents
                        module="ticket"
                        record_id={id}
                        config={{
                            showCategory: false,
                            showExpiry: false,
                        }}

                    />
                </div>
            </div>
            </div>
    );
};


export default CommentList;
