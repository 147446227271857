import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { BottomBarButton, Modal, ModalComponent, SearchButton } from "../../index";
import Role from "../../../pages/Roles/Role";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import RoleService from "./../../../pages/Roles/RoleService";

const RoleSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  asLabel
}) => {
  const [roles, setRoles] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)
  const [gridLoading, setGridLoading] = useState(false);


  const fetchRoles = async () => {
    setGridLoading(true);

    try {
      const response = await RoleService.fetchAll();
      setRoles(RoleService.roleCache)
      setGridLoading(false);

      // setRoles(toJS(response));
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (RoleService?.roleCache?.length === 0) fetchRoles();
  }, []);

  useEffect(() => {
    setRoles(RoleService.roleCache);
  }, [RoleService.roleCache]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectRole = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0] : selectedValue });
    closeModal();
  };

  const selectRoles = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Roles")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={roles}
      required={required}
      value={value}
      label={label || (!isDisabled && t("Role"))}
      error={!value ? t(`Please Select ${label || "Role"}`) : ""}
      showErr={showErr}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Roles")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={selectRoles}
      data={roles}
      value={value ? roles.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Roles"}`) : ""}
      required={required}
      label={label ?? t("Select Roles")}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
      {asLabel ? multi ?
        value?.length > 0 ?
          (<div>
            {value?.map((selectedValue, index) => (
              <span key={selectedValue}>
                {roles.find((role) => role.value === selectedValue)?.label}
                {index < value?.length - 1 && ", "}
              </span>
            ))}</div>) : <div style={{ color: "grey" }}>No Record Found</div>


        : (<div>{roles?.find((role) => role?.value == value)?.label ?? <div style={{ color: "grey" }}>No record Found</div>}</div>) :
        <>

          {modal && (

            <ModalComponent
              position={"top"}
              size={"large"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Roles")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                {/* <Office
                    value={value}
                    insidePane={true}
                    multiMode={multi}
                    onSelect={multi ? selectOffices : selectOffice}
                /> */}
                <Role
                  value={value}
                  insidePane={true}
                  multiMode={multi}
                  onSelect={multi ? selectRoles : selectRole}
                />
              </ModalComponent.Body>

            </ModalComponent>
            // <Modal onChange={closeModal}>
            //   <Role
            //     value={value}
            //     insidePane={true}
            //     multiMode={multi}
            //     onSelect={multi ? selectRoles : selectRole}
            //   />
            // </Modal>
          )}
          {roles?.length > 0
            ? multi
              ? renderSelectFieldMultiple()
              : renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      }
    </>
  );
};

export default observer(RoleSelect);
