import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { CitySelect, CountrySelect, InputField, SelectField, StateSelect, } from '../../../components'
import { I18nContext } from '../../../store/context/i18nContext';

const StepOne = ({ data, setData }) => {
    const { pickUp = [], dropOf = [] } = data;

    const [dropOfData, setDropOfData] = useState(dropOf)
    useEffect(() => {
        if (JSON.stringify(data?.dropOf) !== JSON.stringify(dropOfData))
            setData({ ...data, dropOf: dropOfData });
    }, [dropOfData]);

    const [pickUpData, setPickUpData] = useState(pickUp)
    useEffect(() => {
        if (JSON.stringify(data?.pickUp) !== JSON.stringify(pickUpData))
            setData({ ...data, pickUp: pickUpData });
    }, [pickUpData]);
    const { t } = useContext(I18nContext);
    // const [officeData, setOfficeData] = useState(data?.offices)
    return (
        <>
            <Row className=''>
                <Col className=' px-4 col-sm-12'>
                    <InputField
                        label={t("Total number of pax")}
                        value={data?.totalpax}
                        onChange={(v) => setData({
                            ...data,
                            totalpax: v
                        })}
                        placeholder="Total number of pax"
                        type="number"
                        className=' col-sm-12'
                        error={!data?.totalpax ? "Total number of pax" : ""}

                        showErr={true}
                    />
                </Col>
            </Row>
            <Row className=' mt-5'>
                <Col className=' px-4 col-sm-6'>
                    <SelectField
                        className=' col-sm-12'
                        data={[
                            { label: 'regular', value: 'regular' },
                            { label: 'adhoc', value: 'adhoc' }
                        ]}
                        value={data?.travelType}
                        label={t("UserType")}
                        onChange={(v) => {
                            setData({
                                ...data, travelType: v.value
                            })
                        }}
                    />
                </Col>
                <Col className=' px-4 col-sm-6'>
                    <SelectField
                        className=' col-sm-12'

                        data={[
                            { label: 'intraCity', value: 'intraCity' },
                            { label: 'OutStation', value: 'OutStation' }
                        ]}
                        value={data?.tripType}
                        label={t("Package Type")}
                        onChange={(v) => {
                            setData({
                                ...data, tripType: v.value
                            })
                        }}
                    />
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col className=' px-4 col-sm-12'>
                    <>
                        {pickUpData?.map((pd, i) => {
                            return (
                                <>
                                    <Row >
                                        <Col >
                                            <CountrySelect
                                                className=" m-auto px-2"
                                                value={pd?.country}
                                                required={true}
                                                onChange={(v) =>
                                                    setPickUpData(
                                                        pickUpData?.map((f, x) => {
                                                            if (i === x) return { ...pd, country: v.value };
                                                            return f;
                                                        })
                                                    )
                                                }
                                            />
                                        </Col>

                                    </Row>
                                    <Row className=' mt-3'>
                                        <Col >

                                            <CitySelect value={pd?.cities} className=" m-auto px-2" onChange={(v) =>
                                                setPickUpData(
                                                    pickUpData?.map((f, x) => {
                                                        if (i === x) return { ...pd, cities: v };
                                                        return f;
                                                    })
                                                )
                                            }
                                                multi={true}
                                                country={pd?.country}
                                                onlyCountry />

                                        </Col>
                                    </Row>



                                </>
                            )
                        })}

                        <div className=' mt-3 float-end px-4'>
                            <Button onClick={() => setPickUpData([...pickUpData, {}])} style={{ backgroundColor: "#DF6463", border: "none", borderRadius: "8px" }}> <span className=' fa-1x fw-bolder'>+</span> Add PickUp</Button>
                        </div>

                    </>
                </Col>
            </Row>
            <Row >
                <Col className=' px-4 col-sm-12'>
                    <>
                        {dropOfData?.map((pd, i) => {
                            return (
                                <>
                                    <Row className='mt-4'>
                                        <Col >
                                            <CountrySelect
                                                className=" m-auto px-2"
                                                value={pd?.country}
                                                required={true}
                                                onChange={(v) =>
                                                    setDropOfData(
                                                        dropOfData?.map((f, x) => {
                                                            if (i === x) return { ...pd, country: v.value };
                                                            return f;
                                                        })
                                                    )
                                                }
                                            />
                                        </Col>

                                    </Row>
                                    <Row className='mt-3'>
                                        <Col >

                                            <CitySelect value={pd?.cities} className=" m-auto px-2" onChange={(v) =>
                                                setDropOfData(
                                                    dropOfData?.map((f, x) => {
                                                        if (i === x) return { ...pd, cities: v };
                                                        return f;
                                                    })
                                                )
                                            }
                                                multi={true}
                                                country={pd?.country}
                                                onlyCountry />

                                        </Col>
                                    </Row>



                                </>
                            )
                        })}

                        <div className=' mt-3 float-end px-4'>
                            <Button onClick={() => setDropOfData([...dropOfData, {}])} style={{ backgroundColor: "#DF6463", border: "none", borderRadius: "8px" }}> <span className=' fa-1x fw-bolder'>+</span> Add DropOff</Button>
                        </div>

                    </>
                </Col>
            </Row>
            <>
                {/* {officeData?.map((office, i) => {
                    return (
                        <>
                            <Row className='mt-2 px-4'>
                                <Col className='col-sm-3'>
                                    <CountrySelect
                                        className=" m-auto "
                                        value={office?.country}
                                        required={true}
                                        onChange={(v) =>
                                            setOfficeData(
                                                officeData?.map((f, x) => {
                                                    if (i === x) return { ...office, country: v.value };
                                                    return f;
                                                })
                                            )
                                        }
                                    />
                                </Col>
                                <Col className='col-sm-3'>
                                    <CitySelect
                                        value={office?.city}

                                        className=" m-auto "
                                        country={office?.country}
                                        onChange={(v) =>
                                            setOfficeData(
                                                officeData?.map((f, x) => {
                                                    if (i === x) return { ...office, city: v };
                                                    return f;
                                                })
                                            )
                                        }
                                    />
                                </Col>
                                <Col className=' col-sm-6'>
                                    <InputField
                                        label={"Address"}
                                        className=" m-auto"
                                        value={office?.addressLine}
                                        onChange={(v) =>
                                            setOfficeData(
                                                officeData?.map((f, x) => {
                                                    if (i === x) return { ...office, addressLine: v.value };
                                                    return f;
                                                })
                                            )
                                        }
                                        placeholder="Enter Address Line"
                                        type="text"
                                        
                                        showErr={true}
                                    />
                                </Col>
                            </Row>

                            <Row className='mt-4'>

                            </Row>
                            <Row className=' mt-4'>

                            </Row></>
                    )
                })} */}

                {/* <div className=' mt-3 float-end px-4'>
                    <Button onClick={() => setOfficeData([...officeData, {}])} style={{ backgroundColor: "#DF6463", border: "none", borderRadius: "8px" }}> <span className=' fa-1x fw-bolder'>+</span> Add Office</Button>
                </div> */}

            </>

        </>
    )
}



export default StepOne