export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/adhoc-request/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  get: (id) => `/api/adhoc-request/${id}/detail`,
  save: `/api/adhoc-request/create`,
  delete: (id) => `/api/adhoc-request/${id}/delete`,
  update: `/api/adhoc-request/update`,
};

export const SELECTSTRUCTURE = {

  statusdata: [
    { label: "Created", value: 0 },
    { label: "Approved", value: 1 },
    { label: "Assigned", value: 2 },
    { label: "Rejected", value: 3 },
  ]
}



export const STRUCTURE = [
  {
    label: "Status",
    filterLabel: "Status",
    filterName: "status",
    type: "select",

    data: SELECTSTRUCTURE?.statusdata
  },
  {
    label: "Start time",
    filterLabel: "Start time",
    filterName: "startTime",
    type: "dateTimeSelect",
  },


];

