import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Row, Button, Col } from "reactstrap";
import {
  Layout,
  RetryLink,
  DetailLink,
  itemBarSelectedStyle,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { STRUCTURE } from "./ApiLogConstant";
import ApiLogService from "./ApiLogService";
import ApiLogDetail from "./ApiLogDetail";
import { secondsToyyyy_mm_ddThh_mm } from "./../../components/index";

const ApiLog = (props) => {
  const { id } = useParams();
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const { t } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(id);
  const [data, setData] = useState(id ? {} : { version: 0 });
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);
  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await ApiLogService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const smsfn = async (e) => {
    e.preventDefault();
    try {
      await ApiLogService.sms(data?._id);
      showMessage("Message sent");
      navigate(`/apiLogs/detail/${data?._id}`, { replace: true });
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (e) {
      showError(e);
    }
  }, []);

  const setField = (timestamp) => {
    let atstr = secondsToyyyy_mm_ddThh_mm(timestamp);
    if (atstr[13] + atstr[14] > "12") {
      atstr += " PM";
    } else {
      atstr += " AM";
    }
    return atstr;
  };

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    setShowDetailPage(window.location.pathname === "/apiLogs/detail" || id);
  }, [id]);

  let navigate = useNavigate();

  return (
    <Layout
      showDetailPage={showDetailPage}
      backDetailPage={() => {
        setShowDetailPage(false);
        if (!props?.insidePane) navigate("/apiLogs");
        setSelected_id(false);
      }}
       
      title={t("API Logs")}
      filterStructure={STRUCTURE}
      onApplyFilter={fetchData}
      page={ApiLogService.page}
      rows={ApiLogService.rows}
      total={ApiLogService.totalRecords}
      onPageChange={async (page, rows) => {
        await ApiLogService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("API Name")}</Th>
            <Th>{t("Phone")}</Th>
            <Th>{t("Units")}</Th>
            <Th>{t("At")}</Th>
            <Th>{t("Action")}</Th>
            <Th>{t("Retry")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {ApiLogService.records?.map((dta) => (
            // filter
            <Tr
              key={dta?._id}
              style={id === dta?._id ? itemBarSelectedStyle : {}}
            >
              <Td>{dta?.apiName}</Td>
              <Td>{dta?.phone}</Td>
              <Td>{dta?.smsSize}</Td>
              <Td>{dta?.at ? setField(dta?.at) : ""}</Td>
              <Td>
                <DetailLink
                  onClick={() => {
                    if (!props?.insidePane)
                      navigate(`/apiLogs/detail/${dta?._id}`);
                    setSelected_id(dta?._id);
                    setShowDetailPage(true);
                  }}
                />
              </Td>
              <Td>
                {dta?.isRetryEnable ? (
                  <RetryLink
                    onClick={() => {
                      if (!props?.insidePane)
                        navigate(`/apiLogs/detail/${dta?._id}`);
                      setSelected_id(dta?._id);
                      setShowDetailPage(true);
                    }}
                  />
                ) : undefined}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>
      <Layout.DetailPageTitle>
        <Row>
          <Row className="mb-2 py-2 border-bottom-1 text-bold">
            <Col>
              <h5 style={{ marginLeft: "20px" }}>Record Deatils</h5>
            </Col>
          </Row>
        </Row>
      </Layout.DetailPageTitle>
      <Layout.DetailPageBody>
        <ApiLogDetail
          insidePane={props?.insidePane}
          onChange={(v) => setIsEditDataChanged(v)}
          id={selected_id}
          data={data}
          setData={setData}
          fetchData={fetchData}
        />
      </Layout.DetailPageBody>
      <Layout.DetailPageFooter>
        <Row>
          <Col>
            <div
              className="d-flex justify-content-end mt-1"
              style={{ marginTop: "-2.5rem" }}
            >
              <div className="d-flex justify-content-end mt-4">
                <Button
                  onClick={smsfn}
                  type="submit"
                  className="mx-2"
                  color="primary"
                >
                  Send Message
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Layout.DetailPageFooter>
    </Layout>
  );
};

export default observer(ApiLog);
