import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';

const DynamicTextarea = forwardRef(({ value, onChange, onKeyDown, placeholder, className }, ref) => {
  const [rows, setRows] = useState(1);
  const textareaRef = useRef(null);

  const handleChange = (e) => {
    const textareaLineHeight = 18;
    const minRows = 1;
    const maxRows = 5;

    const previousRows = e.target.rows;
    e.target.rows = minRows;

    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      e.target.rows = maxRows;
      e.target.scrollTop = e.target.scrollHeight;
    }

    setRows(currentRows < maxRows ? currentRows : maxRows);
    onChange(e);
  };

  const handleKeyDownInternal = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      handleChange(e);
    }

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  useImperativeHandle(ref, () => ({
    resetTextarea: () => {
      setRows(1);
    }
  }));

  return (
    <textarea
      ref={textareaRef}
      rows={rows}
      value={value}
      placeholder={placeholder}
      className={className}
      style={{
        fontSize: '12px',
        lineHeight: '15px',
        resize: 'none',
        overflowY: rows >= 5 ? 'auto' : 'hidden',
      }}
      onChange={handleChange}
      onKeyDown={handleKeyDownInternal}
    />
  );
});

export default DynamicTextarea;
