import React from "react"
import { Doughnut } from "react-chartjs-2"

const DoughnutChart = ({ data }) => {
    return <div style={{ position: 'relative' }}>

        <Doughnut data={data}
            options={{
                responsive: true,
                maintainAspectRatio: false,
            }} />
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
            }}
        >
            <div style={{ fontSize: '20px', marginTop : "5px", fontWeight: 'bold' }}>
                {`${data?.datasets[0]?.data?.[0]}/${data?.datasets[0]?.data?.[1] + data?.datasets[0]?.data?.[0]}`}
            </div>
        </div>
    </div >
}

export default DoughnutChart;
