import { useContext } from "react";
import { UserContext } from "../../../store/context/UserContext";
import io from "socket.io-client";


export const useSocket = () => {
  const { socketRef } = useContext(UserContext);

  const socketInit = async (url, path, data) => {
    if (!socketRef.current) {
      if (typeof data !== "object") throw new Error("Data Not Correct");
      socketRef.current = io.connect(url, {
        path: path,
        query: { tkn: localStorage.getItem("BearerToken"), ...data },
        transports: ["websocket"],
      });
    }

    socketRef.current.on("connect_error", (err) => {
      console.log(err.message);
      socketRef.current = null
      throw new Error(err.message);
    });
  };

  const socketInstance = () => {
    return socketRef.current;
  };

  const socketEmitter = (event, payload) => {
    if (socketRef.current)
      socketRef.current.emit(event, payload, localStorage.getItem("BearerToken"));
  };

  const socketAddListeners = (listeners) => {
    if (socketRef.current) {
      listeners?.map((listen) => {
        if (listen.event && listen.callback)
          socketRef.current.on(listen.event, listen.callback);
      })
    }
  };

  const socketRemoveListeners = (events) => {
    if (socketRef.current)
      events.map((event) => socketRef.current.removeAllListeners(event))
  };

  const socketClose = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
    }
  };

  return {
    socketInit,
    socketClose,
    socketEmitter,
    socketInstance,
    socketAddListeners,
    socketRemoveListeners,
  };
};
