// #depriciated only works for lead
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Row } from 'reactstrap';
import { Checkbox, DateSelect, DeleteButton, InputField, ModalComponent, SaveButton, TimeSelect } from '../..';
import { DialogContext } from '../../../store/context/DialogContext';
import { I18nContext } from '../../../store/context/i18nContext';
import { doDELETE } from '../../../util/HttpUtil';
import EmployeeAssigner from '../../Dropdowns/EmployeeSelect/EmployeeAssigner';
import LeadSelect from '../../Dropdowns/LeadSelect/LeadSelect';
import ReminderService from '../../ReminderByLead/ReminderService';
import { generateUniqueId } from '../board/helper';
import Editor from '../forms/itinerary/components/RichTextEditor/Editor/Editor';
import SpaceService from "../listView/SpaceService";
import './style.css';


const ReminderFormModal = ({
  editId,
  isOpen,
  toggleModal,
  onSave = () => { },
  onDelete = () => { },
  value = {},
  onEdit = () => { } }) => {

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const fetch = async () => {
    try {
      setLoading(true)
      let reminderDetail = await ReminderService.get(editId);
      setData(reminderDetail);
      setLoading(false)
    } catch (error) {
      showError(error)
      setLoading(false)
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      if (data?._id) {
        const lead = await ReminderService.edit(data, data?._id);
        setData(lead)
        setLoading(false);
        showMessage(t("Reminder updated successfully."));
        onEdit()
      } else {
        const lead = await ReminderService.save({
          ...data,
          space_id: SpaceService.selectedSpace?._id,
        });
        setData(lead)
        setLoading(false);
        showMessage(t("Reminder saved successfully."));
        onEdit()
      }
    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  const handleDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        const response = await doDELETE(`/api/reminder/${data?._id}/delete`);
        setData({})
        setLoading(false);
        showMessage("Reminder Deleted SuccessFully", "Deleted");
        toggleModal(false);
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };

  useEffect(() => {
    console.log({ isOpen, editId })
    if (isOpen) {
      setLoading(true);
      if (editId) {
        fetch();
      } else {
        setData({});
      }
      setLoading(false)
    }
  }, [editId, isOpen]);

  useEffect(() => {
    if (value.actionDate) {
      setData({ actionDate: value.actionDate })
    }
  }, [value.actionDate])

  return (
    <ModalComponent
      position={"top"}
      size={"medium"}
      isOpen={isOpen}
      onToggle={toggleModal}
    >
      <ModalComponent.Title>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center'>
            <div className='me-3'>{t("Reminder")}</div>
            <Checkbox
              label={t("Completed")}
              className="col-sm-6"
              noLabel
              checked={data?.completed == 1}
              onClick={() => {
                setData({
                  ...data,
                  completed: data?.completed == 1 ? 0 : 1
                })
              }}
            />
          </div>
          <EmployeeAssigner
            className="me-5"
            tooltipId={generateUniqueId()}
            value={data?.assignedTo}
            onChange={(v) => {
              setData({ ...data, assignedTo: v })
            }}
          />
        </div>
      </ModalComponent.Title>

      <ModalComponent.Body>
        <div className='d-flex flex-column  py-2'>
          {/* <div className='d-flex justify-content-between align-items-center'>
            <div className='ms-1'>Assigned To</div>
            <EmployeeAssigner
              className=""
              tooltipId={generateUniqueId()}
              value={data?.assignedTo}
              onChange={(v) => {
                setData({ ...data, assignedTo: v })
              }}
            />
          </div> */}

          <Row>
            <InputField
              value={data?.action}
              outline
              className="col-sm-6"
              label="Action"
              onChange={(v) => {
                setData({ ...data, action: v })
              }}
            />
            <LeadSelect
              outline
              space_id={SpaceService.selectedSpace._id}
              value={data.lead_id}
              className="col-sm-6"
              onChange={(v) => {
                setData({ ...data, lead_id: v?._id })
              }}
            />

          </Row>
          <Row>

            <DateSelect
              placeholder={t("Date ")}
              value={data.actionDate}
              label={t("Action Date")}
              className="col-sm-6"
              outline
              onChange={(v) => {
                setData({ ...data, actionDate: v })

              }}
              type="text"
            />
            <TimeSelect
              className="col-sm-6"
              label={t("Action Time")}
              value={data?.actionTime}
              outline
              onChange={(v) =>
                setData({
                  ...data,
                  actionTime: v,
                })
              }
            />

          </Row>

          <Editor
            label="Notes"
            placeholder="Notes"
            value={data?.note}
            onChange={(e) => {
              setData({
                ...data,
                note: e,
              })
            }}

          />


          {/* 
          <textarea
            label="Notes"
            className="col-6 inputfield mt-0"
            style={{ width: "100%", borderRadius: "0.375rem", outline: "none", border: "1px solid #ced4da", }}
            rows="5"
            placeholder="Notes"
            value={data?.note}
            onChange={(e) => {
              setData({
                ...data,
                note: e.target.value,
              })
            }}
            type="text"
          ></textarea> */}

          <div className='d-flex justify-content-end'>
            <SaveButton
              onClick={handleSave}
            />
            {data?._id &&
              <DeleteButton
                onClick={async (e) => {

                  handleDelete(e, data?._id)

                }}
              />}
          </div>
        </div>
      </ModalComponent.Body>

    </ModalComponent>
  );
};

export default observer(ReminderFormModal);
