import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { FaCalendarAlt, FaHotel, FaInfoCircle, FaMapMarkerAlt, FaPlus, FaUserTie, FaUsers } from "react-icons/fa";
import { Button, FormGroup } from "reactstrap";
import { AddressField, Documents, InputField, ModalComponent, generateRandomString } from "../../../components";
import Tab from "../../../components/Common/Tab/Tab";
import DateSelectCustom from "../../../components/DateTimePickerWithInterval/DateSelectCustom";
import { DialogContext } from "../../../store/context/DialogContext";
import ClientCompanyGrid from "../../ClientCompanyGrid/ClientCompanyGrid";
import Supervisor from "../../Supervisor/Supervisor";
import User from "../../Users/User";
import Vehicle from "../../Vehicle/Vehicle";
import FolderItem from "./FolderItem";
import GroupTourSidebarService from "./GroupTourSidebarService";
import Places from "./events/places/Places";
import "./style.scss";
import Editor from "../../../components/dnd/forms/itinerary/components/RichTextEditor/Editor/Editor";

const GroupTourSidebar = observer(({ module }) => {
  const [collapsedSpaces, setCollapsedSpaces] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { showMessage, showError } = useContext(DialogContext);
  const [eventLoading, setEventLoading] = useState(true);
  const [eventData, setEventData] = useState({
    name: '',
    fromDate: '',
    tillDate: '',
    places: [],
    customers: [],
    suppliers: [],
    vehicles: [],
  });
  const [viewData, setViewData] = useState({
    name: '',
    supervisors: [],
    filterUrl: '',
  });


  useEffect(() => {
    const fetch = async (module) => {
      const response = await GroupTourSidebarService.fetch(module, 0);
    };

    if (module) {
      fetch(module, 0);
    }
  }, [module]);


  useEffect(() => {
    console.log({ selectedFolder: GroupTourSidebarService?.selectedFolder?._id })
  }, [GroupTourSidebarService?.selectedFolder?._id]);


  const handleCreateSpace = async (data) => {
    try {
      const parentSpace_id = await GroupTourSidebarService.save({
        module,
        ...data,
      });

      const childResponse = await GroupTourSidebarService.save({
        module,
        name: "sample 1",
        parentSpace_id,
      }, true);
      GroupTourSidebarService.setSelectedFolder(childResponse);
      return parentSpace_id
    } catch (error) {
      showError(error);
    }
  };

  const toggleCollapse = (id) => {
    setCollapsedSpaces((prevCollapsed) => ({
      ...prevCollapsed,
      [id]: !prevCollapsed[id],
    }));
  };

  const handleStartCreatingSpace = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setCurrentStep(1);
    setEventData({
      name: '',
      fromDate: '',
      tillDate: '',
      places: [],
      customers: [],
      suppliers: [],
      vehicles: [],
    });
    setViewData({
      name: '',
      supervisors: [],
      filterUrl: '',
    });
  };

  const handleCreateEvent = async () => {
    setEventLoading(true)
    try {
      if (eventData?._id) {
        await GroupTourSidebarService.edit({ ...(eventData ?? {}) });
        showMessage("Event Edited Successfully")
        // handleModalClose();
      } else {
        const parent_id = await handleCreateSpace({ ...eventData, type: 0 });
        showMessage("Event Created Successfully")
        setEventData({ ...eventData, _id: parent_id, module, type: 0 });
      }
    } catch (error) {
      showError(error);
    } finally {
      setEventLoading(false)
    }
  };

  const renderEventStep = (step) => {
    switch (step) {
      case 1:
        return (
          <FormGroup>

            {eventData?._id ? null : <div className="todo-section">
              <h3><FaInfoCircle className="fa-icon" /> Event Creation Guide</h3>
              <ul>
                <li><FaCalendarAlt className="fa-icon" /> <strong className="me-2">Specify Dates:</strong> Select the start and end dates for your event.</li>
                <li><FaUsers className="fa-icon" /> <strong className="me-2">Guests:</strong> Add the guests who will be attending the event.</li>
                <li><FaHotel className="fa-icon" /> <strong className="me-2">Hotels:</strong> Choose the hotels where guests will stay.</li>
                <li><FaMapMarkerAlt className="fa-icon" /> <strong className="me-2">Places:</strong> List the places to be visited during the event.</li>
                <li><FaUserTie className="fa-icon" /> <strong className="me-2">Supervisors:</strong> Assign supervisors to manage the event.</li>
              </ul>
              <p>Make sure to fill in all the details to create a comprehensive event plan. The more information you provide, the better the event experience will be for everyone involved.</p>
            </div>}

            <div className="row">

              <div className="col-12 col-md-6">
                <InputField
                  label="Event Name"
                  placeholder="Event Name"
                  value={eventData.name}
                  onChange={(e) => setEventData({ ...eventData, name: e })}
                />
                {/* <InputField
                  label="Event Location"
                  placeholder="Event Location"
                  value={eventData?.eventLocation}
                  onChange={(e) => setEventData({ ...eventData, eventLocation: e })}
                /> */}
                <DateSelectCustom
                  placeholder={("dd-mm-yyyy")}
                  label="From Date"
                  value={eventData.fromDate}
                  onChange={(e) => setEventData({ ...eventData, fromDate: e })}
                  className="col-sm-12 col-12"
                />
                <DateSelectCustom
                  placeholder={("dd-mm-yyyy")}
                  label="Till Date"
                  value={eventData.tillDate}
                  onChange={(e) => setEventData({ ...eventData, tillDate: e })}
                  className="col-sm-12 col-12"
                />
                <div className="">
                  <div style={{ color: "#000", marginBottom: "0.2rem", }}>{"Event Location"}</div>
                  <AddressField
                    placeholder="Address"
                    label="Address"
                    outline
                    value={eventData?.eventLocation}
                    className={"w-100"}
                    onChange={(e) => setEventData({ ...eventData, eventLocation: e })}
                  />
                </div>

                <div className="mt-3">
                  <Editor
                    placeholder="Notes"
                    value={eventData.notes}
                    onChange={(htmlContent) => setEventData({ ...eventData, notes: htmlContent })}
                  />
                </div>

                <div className="mt-2">
                  <div style={{ color: "#000", marginBottom: "0.2rem", }}>{"Event Description"}</div>
                  <textarea
                    className="inputfield"
                    rows={5}
                    style={{ borderRadius: "0.175rem", border: "1px solid #ced4da", outline: "none" }}
                    value={eventData?.description}
                    onChange={(e) => {
                      setEventData({ ...eventData, description: e.target.value });
                    }}
                  >
                  </textarea>
                </div>

              </div>

              {eventData?._id && <div className="col-12 col-md-6">

                <Documents
                  module="space"
                  record_id={eventData?._id}
                />

              </div>}

            </div>



          </FormGroup>
        );
      case 2:
        return (
          <Places
            places={eventData.places}
            setPlaces={(places) => setEventData({ ...eventData, places })}
          />
        );
      case 3:
        return (
          <FormGroup>
            <ClientCompanyGrid
              value={eventData.suppliers}
              insidePane={true}
              multiMode
              supplier_id
              breadcrumb={"Select Supplier"}
              onlySelect
              onSelect={(selectedValues) =>
                setEventData({ ...eventData, suppliers: selectedValues.map((c) => c?.value ?? c) })
              }
            />
          </FormGroup>
        );
      case 4:
        return (
          <FormGroup>
            <Vehicle
              value={eventData.vehicles}
              insidePane={true}
              breadcrumb={"Select Vehicles"}
              multiMode
              onSelect={(selectedValues) =>
                setEventData({ ...eventData, vehicles: selectedValues.map((c) => c?.value ?? c) })
              }
            />
          </FormGroup>
        );
      case 5:
        return (
          <FormGroup>
            <User
              hideToggle
              value={eventData.customers}
              insidePane={true}
              formConfig={{
                showAll: true
              }}
              breadcrumb={"Select Guests"}
              multiMode
              onSelect={(selectedValues) =>
                setEventData({ ...eventData, customers: selectedValues.map((c) => c?.value ?? c) })
              }
            />
          </FormGroup>
        );
      case 6:
        return (
          <FormGroup>
            <Supervisor
              hideToggle
              value={eventData.supervisors}
              insidePane={true}
              breadcrumb={"Select Supervisors"}
              multiMode
              onSelect={(selectedValues) =>
                setEventData({ ...eventData, supervisors: selectedValues.map((c) => c?.value ?? c) })
              }
            />
          </FormGroup>
        );
      default:
        return null;
    }
  };

  const renderSpaces = (spaces, level = 0) => {
    return spaces?.filter(space => !space?.parentSpace_id)?.map((folder) => (
      <div className="" key={folder._id} style={{ paddingLeft: `${level * 20}px` }}>
        <FolderItem
          key={folder._id}
          folder={folder}
          onToggleCollapse={() => toggleCollapse(folder._id)}
          module={module}
          handleManage={(v) => {
            if (v?.type == 0) {
              setEventData(v);
              setModalOpen(true);
              setCurrentStep(1); // Show tabs directly in manage mode
            }
          }}
          type={folder?.type}
          onSpaceClick={() => {
            if (!folder?.parentSpace_id /* || folder?.type === "single" */) {
              GroupTourSidebarService.setSelectedFolder(folder);
            }
          }}
        />
        {!collapsedSpaces[folder._id] && folder.children && renderSpaces(folder.children, level + 1)}
      </div>
    ));
  };

  return (
    <>
      <div className="p-2" style={{ maxWidth: '180px' }}>
        <div className='text-secondary fw-normal fs-6' style={{ paddingLeft: `10px` }}>
          Spaces
        </div>
        {renderSpaces([{ module, name: "Everything", _id: null, type: "single" }])}
        {renderSpaces(GroupTourSidebarService.records)}
        <div
          style={{ /* backgroundColor: "#EBEBEB", */ borderRadius: "4px", padding: "4px" }}
          className="mt-3 d-flex align-items-center clickable cursor-pointer create-space-btn"
          onClick={handleStartCreatingSpace}>
          <FaPlus className="me-2" />
          <span className="font-size-14">Create Space</span>
        </div>
      </div>

      <ModalComponent
        position={"top"}
        size={"full-screen"}
        onToggle={handleModalClose}
        isOpen={modalOpen}
      >
        <ModalComponent.Title toggle={handleModalClose}>
          <div className="d-flex">
            {eventData?._id ? "Edit" : "Create"} Space
          </div>
        </ModalComponent.Title>
        <ModalComponent.Body stretch className="">
          {eventData._id ? (
            <Tab
              vertical={false}
              left
              outerClassName={" mt-0 pt-0"}
              activeTab={currentStep}
              onChange={(data) => {
                setCurrentStep(data);
              }}
              className="d-flex"
            >
              <div num={1} label="Details">
                {renderEventStep(1)}
              </div>
              <div num={2} label="Places">
                {renderEventStep(2)}
              </div>
              <div num={3} label="Suppliers">
                {renderEventStep(3)}
              </div>
              <div num={4} label="Vehicles">
                {renderEventStep(4)}
              </div>
              <div num={5} label="Guests">
                {renderEventStep(5)}
              </div>
              <div num={6} label="Supervisors">
                {renderEventStep(6)}
              </div>
            </Tab>
          ) : (
            renderEventStep(1)
          )}
        </ModalComponent.Body>
        <ModalComponent.Footer>
          <Button color="primary" onClick={handleCreateEvent}>
            {eventData?._id ? "Save" : "Create Event"}
          </Button>
        </ModalComponent.Footer>
      </ModalComponent>
    </>
  );
});

export default GroupTourSidebar;
