import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./CompilancesConstant";
import { doGET, doPOST } from "../../util/HttpUtil";

class Service {

  records = [];
  totalRecords = 0;
  places = [];
  page = 1;
  rows = 10;

  constructor() {
    makeAutoObservable(this);
  }



  get = async function () {
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rows)
    );
    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }

    console.log(response?.data?.rows, "response?.data?.rows")
    return response?.data?.rows
  };

  getBU = async function (id) {

    const response = await doGET(ENDPOINTS.getBusinessUnit)
    return response?.data;

  };

  save = async function (data) {
    const response = await doPOST(ENDPOINTS.saveNew, data)
    this.get()

    return response?.data;
  };

  delete = async function (id) {
    const response = await doGET(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.get();
    }
  };



  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch();
  }

}

const CompilancesService = new Service();
export default CompilancesService;
