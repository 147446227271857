import moment from "moment/moment";
let currentyear = moment().year();
let yearsArray = [];
let seatTypeSelect = [];

for (let i = currentyear; i >= 1990; i--) {
  yearsArray.push({ label: i, value: i });
}

for (let i = 1; i <= 45; i++) {
  seatTypeSelect.push({ label: i, value: i });
}

export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/custom-field/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url;
  },

  get: (customFieldID) => `/api/custom-field/${customFieldID}/detail`,
  // save : (customFieldID)=>`/api/custom-field/${customFieldID}/picture/save`,
  save: `/api/custom-field/create`,
  edit: (customFieldID) => `/api/custom-field/update`,
  update: (customFieldID) => `/api/custom-field/update`,
  delete: (id) => `/api/custom-field/${id}/delete`,
};

const SELECTSTRUCTURE = {
  altfuel: [
    { label: "Petrol", value: "Petrol" },
    { label: "CNG", value: "CNG" },
    { label: "LPG", value: "LPG" },
    { label: "Diesel", value: "Diesel" },
    { label: "Electric", value: "Electric" },
  ],
  fuel: [
    { label: "Petrol", value: "Petrol" },
    { label: "CNG", value: "CNG" },
    { label: "LPG", value: "LPG" },
    { label: "Diesel", value: "Diesel" },
    { label: "Electric", value: "Electric" },
  ],
  distanceUnit: [
    { label: "KM", value: "KM" },
    { label: "MILE", value: "MILE" },
  ],
  driverSide: [
    { label: "Right Drive", value: 1 },
    { label: "Left Drive", value: 2 },
  ],
  status: [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 2 },
    { label: "Under Maintanence", value: 3 },
    { label: "Sold", value: 4 },
  ],
  transmission: [
    { label: "Manual", value: 1 },
    { label: "Automatic", value: 2 },
    { label: "Auto Pilot", value: 3 },
  ],
  GPS: [
    { label: "Fleetx", value: "Fleetx" },
    { label: "GTrac", value: "GTrac" },
    { label: "FTrack", value: "FTrack" },
    { label: "Loconav", value: "Loconav" },
    { label: "Map My India", value: "Map My India" },
    { label: "Zoyride", value: "Zoyride" },
    { label: "GPSDesk", value: "GPSDesk" },
    { label: "Arya Omnitalk", value: "Arya Omnitalk" },
  ],
  years: yearsArray,
  seatTypes: seatTypeSelect,
};

export default SELECTSTRUCTURE;
