import React, { useEffect, useRef } from "react";
import CurrencySelect from "../Dropdowns/CurrencySelect/CurrencySelect";
import AmountField from "./AmountField";

export default function AmountCurrencyField({ value, required, onChange, showErr, className, label, asLabel }) {


  useEffect(() => {
    onChange({ ...value, currency: "INR" })
  }, [])
  return (
    <>

      {asLabel ?
        <div>
          {value?.currency} {value?.amount}
        </div>

        : <div className={className + " d-flex justify-content-center align-items-center "}>
          <div
            style={{ width: "120px", fontSize: 12, fontWeight: "500" }}
          >
            {label}
            {required && <font color="red">*</font>}
          </div>

          <div
            style={{ margin: "10px 0px 5px 0px", height: "36px" }}
            className={" input-group  flex-1"}
          >
            <div className="input-group-prepend">
              <CurrencySelect
                className={`m-0 `}
                value={value?.currency ? value?.currency : 'INR'}
                noLabel
                innerContainerStyle={{ borderRadius: "6px 0px 0px 6px", }}
                style={{ borderRadius: "6px 0px 0px 6px", }}
                showClear={false}
                outline
                onChange={(v) => onChange({ ...value, currency: v?.value ? v?.value : 'INR' })}
                showErr={showErr}
                inputGroup
              />
            </div>

            <AmountField
              innerStyle={{ border: "0px" }}
              placeholder={label ?? "Amount"}
              value={value?.amount}
              onChange={(e) => onChange({ ...value, amount: e })}
              style={{ height: "36px", borderRadius: "0px 6px 6px 0px",  }}
              className={"form-control mt-0 p-0"}
              type="text"
            />

          </div>

        </div>}
    </>

  );
}


