export const ENDPOINTS = {
    // getProfile: () => `/api/profile/details`,
    changePass: `/api/member/changePassword`,
    sendCode: `/api/user/sendchangePhoneVeriCode`,
    changePhone: `/api/v2/user/changePhone`,
    sendECode: `/api/user/sendChangeEmailVerificationOTP`,
    changeEmail: `/api/user/changeEmail`,
    corpProfile: `/api/corp/profile/data`,
    profileDetail: (id) => `/api/supervisor/${id}/detail`,
    profileUpdate: `/api/supervisor/update`,
    corporateDetail: (id) => `/api/corporate/${id}/detail`,
    myCorporateDetail: () => `/api/corporate/detail`,
    corporateUpdate: `/api/corporate/update`,
    logoUpload: `/api/corporate/uploadLogo`,
    saveCorpProfile: `/api/corp/profile/save`,
    config: (id) => `/api/corp/${id}/config`,
    saveContact: `/api/corp/config/contact/save`,
    logsGrid: (id, conf) => conf ? `/api/v1/audit/CorporateConfig/${id}/grid` : `/api/v1/audit/Corporate/${id}/grid`,
    addcontact: `/api/contact-person/create`,
    updatecontact: `/api/contact-person/update`,
    detailcontact: (id) => `/api/contact-person/${id}/detail`,
    deletecontact: (id) => `/api/contact-person/${id}/delete`,
    gridcontact: `/api/contact-person/grid`


}