import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "./../../../pages/Company/CompanyConstant";

class Service {
  companiesCache = {};
  fetchQueue = {};
  loading = {};

  constructor() {
    makeAutoObservable(this);
  }

  fetchCompany = async (corpType, corp_id) => {
    if (this.companiesCache[corp_id]) {
      return this.companiesCache[corp_id];
    }

    // if (this.fetchQueue[corp_id]) {
    //   return this.fetchQueue[corp_id];
    // }

    if (this.loading[corp_id]) {
      return new Promise((resolve, reject) => {
        if(!this.fetchQueue[corp_id])
          this.fetchQueue[corp_id] = [];
        return this.fetchQueue[corp_id].push({ resolve, reject })
    });
    }

    this.loading[corp_id] = true;

    try {
      const query = corpType === "supplier" ? `supplier_id=${corp_id}` : corpType === "client" ? `client_id=${corp_id}` : `supplier_id=notExists&client_id=notExists`;
      const grid = `${ENDPOINTS.grid()}${query}`;
      const response = await doGET(grid);
      if (response.status === 200) {
        const formattedEntities = response?.data?.rows?.map((v) => ({
          ...v,
          label: v.name,
          value: v._id,
        }));
        this.companiesCache[corp_id] = formattedEntities;
        while (this.fetchQueue?.[corp_id]?.length > 0) {
          const { resolve } = this.fetchQueue[corp_id].shift();
          resolve(formattedEntities);
        }
        return formattedEntities;
      } else {
        throw new Error('Failed to fetch companies');
      }
    } catch (error) {
      throw new Error(error.message);
    } finally {
      delete this.loading[corp_id];
    }
  };
}

const CompanyService = new Service();
export default CompanyService;
