import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { BsHourglassSplit } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import { Button, Table } from 'reactstrap';
import CustomTooltip from '../../../../pages/Booking/components/CustomTooltip';
import ItineraryLeadService from './ItineraryLeadService'; // Adjust the import path as necessary
import { toJS } from 'mobx';
import SendMail from './components/SendMail/SendMail';
import { BiMailSend } from 'react-icons/bi';

const ItineraryGrid = ({ leadId, onRequestDetail = () => { } }) => {
    const [loading, setLoading] = useState(false);
    const [isMailModal, setMailModal] = useState(false);

    useEffect(() => {
        const fetchItineraries = async () => {
            setLoading(true);
            try {
                await ItineraryLeadService.fetch(leadId);

            } catch (error) {
                console.error("Failed to fetch itineraries:", error);
                // Handle the error as per your error handling strategy
            } finally {
                setLoading(false);
            }
        };

        if (leadId) {
            fetchItineraries();
        }
    }, [leadId]);

    if (loading) {
        return <div>Loading...</div>; // Consider a more sophisticated loading indicator
    }

    if (ItineraryLeadService?.records.length === 0) {
        return <div>No itineraries found for this lead.</div>;
    }

    return (
        <div className="">
            {ItineraryLeadService?.records?.length ?
                <>
                    <Table className="table-borderless table-centered table-nowrap">
                        <tbody>
                            <tr style={{ borderBottom: "1px solid #EBEBEB" }}>
                                <td>Itinerary</td>
                                <td>Status</td>
                                <td>Duration</td>
                                <td>Client Fee</td>
                                <td>Persons</td>
                                <td>Location</td>
                                {/* <td className=" ">Hotel Type</td> */}
                                <td className="text-muted fw-semibold text-end"></td>
                            </tr>

                            {ItineraryLeadService?.records.map((itinerary, index) => (
                                <tr>
                                    <td>
                                        <div className="font-size-13 mb-1 fw-normal">  {itinerary.title}</div>
                                    </td>
                                    <td className='d-flex align-items-center'>
                                        {itinerary?.active ?
                                            <CustomTooltip>
                                                <CustomTooltip.Body>
                                                    <FaCheck size={16} />
                                                </CustomTooltip.Body>
                                                <CustomTooltip.ToolTip>
                                                    <div style={{ width: "70px" }}>Active</div>
                                                </CustomTooltip.ToolTip>
                                            </CustomTooltip>
                                            :
                                            <CustomTooltip>
                                                <CustomTooltip.Body>
                                                    <BsHourglassSplit size={16}
                                                    />
                                                </CustomTooltip.Body>
                                                <CustomTooltip.ToolTip>
                                                    <div style={{ width: "70px" }}>InActive</div>
                                                </CustomTooltip.ToolTip>
                                            </CustomTooltip>
                                        }
                                </td>
                                    <td>
                                        {itinerary.days}D /{itinerary.nights}N
                                    </td>

                                    <td>
                                        {console.log(toJS(itinerary))}
                                        {((itinerary?.itineraryExtn?.clientFee?.total ?? 0) / 100).toFixed(2)}
                                    </td>


                                    <td>
                                        {itinerary?.persons ?? 0}
                                    </td>

                                    <td>
                                        {itinerary?.location?.city}, {itinerary?.location?.state ?? ""}, {itinerary?.location?.country ?? ""}
                                    </td>

                                    <td className="text-muted text-end d-flex align-items-center justify-content-end">

                                        <div
                                            style={{
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                setMailModal(true)
                                            }}>

                                            <BiMailSend size={22} className='me-2 ' />
                                        </div>

                                        <SendMail
                                            isOpen={isMailModal} 
                                            itinerary = {itinerary}
                                            onToggle={() => {
                                                setMailModal(!isMailModal)
                                            }}
                                        />

                                        <div style={{
                                            cursor: "pointer"
                                        }} onClick={() => { onRequestDetail(itinerary?._id) }} >
                                            <i className="uil uil-pen font-size-18" />
                                        </div>
                                    </td>


                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </> :
                <>
                    <div>No Itinerary found for this Lead.</div>
                </>
            }
        </div>
    );
};

export default observer(ItineraryGrid);
