import React, { useEffect, useState } from "react";
import { InputField, timeStampToyyyy_mm_ddThhmm } from "../../components";
import moment from "moment";

const yyyy_mm_ddThh_mmToSeconds = (dateTime) => {
  if (!dateTime) return null;
  const dateObject = moment(dateTime, "YYYY-MM-DDTHH:mm");
  return dateObject.isValid() ? dateObject.unix() : undefined;
};


const secondsToyyyy_mm_ddThh_mm = (seconds) => {
  if (!seconds) return null;
  return moment.unix(seconds).format("YYYY-MM-DDTHH:mm");
};

const yyyy_mm_ddThh_mmToTimeStamp = (dateTime) => {
  if (!dateTime) return null;
  const dateObject = moment(dateTime, "YYYY-MM-DDTHH:mm");
  // return dateObject.getTime();
};

const timestampToyyyy_mm_ddThh_mm = (timestamp) => {
  if (!timestamp) return;
  return moment.unix(timestamp).format('YYYY-MM-DDTHH:mm');
};

export default function DateTimeSelect(props) {
  const {
    outline,
    inline,
    className,
    label,
    isDisabled,
    required,
    borderNone,
    showErr,
    RightComponent,
    onClickRight,
    timestamp,
    value,
    style,
    outerClassName,
    outerDivStyle,
    onChange = () => { }
  } = props;


  return (
    <div className="filterField_input" style={{ paddingRight: '10px', ...outerDivStyle }}>
      <InputField
        outerClassName={outerClassName}
        style={style}
        className={className}
        value={timestampToyyyy_mm_ddThh_mm(value)}
        required={required}
        label={label || "Date Time"}
        isDisabled={isDisabled}
        onBlur={(v) => {
          onChange(yyyy_mm_ddThh_mmToSeconds(v))
        }}
        error={value?.length === 0 ? "Please Choose a Valid Date Time" : ""}
        type="datetime-local"
        inline={inline}
        outline={outline}
        borderNone={borderNone}
        showErr={showErr}
        RightComponent={RightComponent}
        onClickRight={onClickRight}
      />
    </div>
  );
}
