import { ENDPOINTS } from "../../../pages/VehicleType/VehicleTypeConstant";
import { doGET } from "../../../util/HttpUtil";
import { checkIfKeyExists } from "../../../util/Util";

 


export class VehicleTypeLabelService {


    constructor() {
        //format
        this.vehicleType = {
            // [id]: {
            //     isProcessing:true,
            //     data:null,
            //     queue:[]
            // }
        }
    }

   
    resetData = () => this.vehicleType = {};

    fetchData = async (id) => {
        try {
            const response = await doGET(ENDPOINTS.get(id));
            if (response.status === 200) {
                return response.data ? response.data : response;
            }

        } catch (error) {
            console.log(error);
        }
    }

    getData = async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!checkIfKeyExists(id, this.vehicleType)) {
                    this.vehicleType[id] = {
                        isProcessing: false,
                        data: null,
                        queue: [],
                        isProcessed: false,
                    }
                }
                if (this.vehicleType[id].data) { 
                    return resolve(this.vehicleType[id].data);
                }

                if (this.vehicleType[id].isProcessing) { 
                    this.vehicleType[id].queue.push(resolve);
                    return;
                }

                if (!this.vehicleType[id].isProcessing) { 
                    this.vehicleType[id].isProcessing = true;
                    let data = await this.fetchData(id);
                    this.vehicleType[id].data = data;
                    this.vehicleType[id].isProcessing = false;
                    while (this.vehicleType[id].queue.length > 0) {
                        const queueResolve = this.vehicleType[id].queue.shift();
                        queueResolve(data);
                    }
                }

                return resolve(this.vehicleType[id].data);
            } catch (error) {
                return reject(null);
            }
        })
    }
}

const vehicleTypeLabelService = new VehicleTypeLabelService();
Object.freeze(vehicleTypeLabelService);
export default vehicleTypeLabelService;

