import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { BsPersonVideo2 } from "react-icons/bs";
import {
    Card,
    Col,
    Row
} from "reactstrap";

import driverIcon from '../../assets/images/icons/driver.svg';
import guardIcon from '../../assets/images/icons/guard.svg';
import guideIcon from '../../assets/images/icons/tour-guide.svg';
import { AddressField, Checkbox, InputField, LanguageSelect, NameField, PhoneNum, SupplierSelect, ToggleSelector } from "../../components";
import FileUpload from "../../components/DocUpload/FileUpload";
import { I18nContext } from "../../store/context/i18nContext";
import EditPermit from "../Permit/EditPermit";
import { employeePermitConstants } from "./EmployeeConstant";

const EmployeeDetail = ({ data, editId, loading, onChange = () => { }, hasErr, fetchData }) => {

    const { t } = useContext(I18nContext);

    const [permitData, setPermitData] = useState({})
    const [vehicleType, setVehicleType] = useState(data?.vehicleType);

    return (
        <div>
            <Row>
                <Col className="flex-1 col-10">
                    <ToggleSelector
                        data={[
                            { label: "Self", value: "self" },
                            { label: "Supplier", value: "supplier" },
                        ]}
                        className=" w-25"
                        style={{ width: "25%", position: "relative", top: "2.5px" }}
                        value={data?.vehicleType}
                        onChange={(v) => {
                            setVehicleType(v)
                            onChange({
                                ...data,
                                vehicleType: v
                            })
                        }}
                    />

                </Col>
            </Row>
            <Row>
                <Col className="flex-1 col-10">
                    {vehicleType == "supplier" && <Row>
                        <SupplierSelect
                            value={data?.employeeOf}
                            required={false}
                            onChange={(v) => onChange({
                                ...data,
                                employeeOf: v?.value
                            })}
                            className="col-12 col-sm-6 col-lg-4"
                        />
                    </Row>}
                    <Row>
                        <NameField
                            onNameChange={(v) => onChange({ ...data, name: v })}
                            onSalutationChange={(v) => onChange({ ...data, salutation: v })}
                            name={data?.name}
                            salutation={data?.salutation}
                            label={t("Name")}
                            required={true}
                            placeholder={t("Name")}
                            className="col-sm-6"
                        />
                        {/* <InputField
                            placeholder={t("Name")}
                            value={data?.name}
                            required={true}
                            name="name"
                            label={t("Name")}
                            onChange={(value) => onChange({ ...data, name: value })}
                            error={data?.name?.length === 0 ? "Please enter Employee Name" : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6"
                        /> */}
                    </Row>
                    <Row className="mt-3 mb-3">
                        <PhoneNum
                            label={t('Phone')}
                            required={true}
                            name="phone"
                            value={data?.phoneNo}
                            className="col-sm-6"
                            onChange={(value) =>
                                onChange({
                                    ...data,
                                    phoneNo: {
                                        ...data?.phoneNo,
                                        countryCode: value?.countryCode,
                                        phone: value?.phone,
                                    },
                                })
                            }
                        />
                        {/* <Checkbox
                            label={t("Compliant")}
                            checked={data?.complianceStatus == 1}
                            className="col-sm-6 mb-2"
                            onClick={() => {
                                onChange({
                                    ...data,
                                    complianceStatus: data?.complianceStatus == 1 ? 0 : 1,
                                });
                            }}
                        /> */}
                    </Row>
                </Col>
                <Col className="d-flex justify-content-end col-4">
                    <FileUpload
                        create
                        type="image"
                        className=" col-sm-4  mb-2"
                        value={data?.pic}
                        id={data?._id}
                        module="employee"
                        field="pic"
                        onUpload={() => {
                            fetchData()
                        }}
                        onDelete={() => {
                            fetchData()
                        }}
                    />
                </Col>
            </Row>
            <Row className="">
                <InputField
                    placeholder={t("Email Address")}
                    value={data?.email}
                    // required={true}
                    label={t("Email")}
                    onChange={(v) => onChange({ ...data, email: v })}
                    error={data?.email?.length === 0 ? "Please enter Employee email" : ""}
                    showErr={hasErr}
                    type="email"
                    className="col-sm-4"
                    isDisabled={editId && !loading ? true : false}
                />
            </Row>
            <Row className="mt-3 mb-3">
                {/* <LanguageSelect
                    placeholder={t("Language")}
                    value={data?.lang}
                    label={t("Language")}
                    onChange={(v) => onChange({ ...data, lang: v?.code})}
                    error={data?.lang?.length === 0 ? "Please enter the language" : ""}
                    showErr={hasErr}
                    type="text"
                    className="col-sm-4"

                /> */}




                {/* <EmployeComplianceStatusSelect
                    value={data?.complianceStatus}
                    onChange={(v) => {
                        onChange({
                            ...data,
                            complianceStatus: v?.value,
                        });
                    }}
                    className="col-sm-4"
                /> */}

                {/* <LanguageSelect
                    placeholder={t("Language")}
                    value={data?.employeeExtn?.lang}
                    label={t("Language")}
                    onChange={(v) => onChange({ ...data, employeeExtn: { ...data?.employeeExtn, lang: v.name } })}
                    error={data?.employeeExtn?.lang?.length === 0 ? "Please enter the language" : ""}
                    showErr={hasErr}
                    type="text"
                    className="col-sm-4"

                /> */}


            </Row>


            <Row className="mb-3">
                <Checkbox
                    label={<div><img height="20" src={driverIcon} alt="Driver" className="me-2" />{t('Driver')}</div>}
                    checked={data?.isDriver}
                    className="col-sm-3 mb-2"
                    onClick={() => {
                        onChange({
                            ...data,
                            isDriver: !data?.isDriver,
                        });
                    }}
                />
                <Checkbox
                    label={<div><img height="20" src={guardIcon} alt="Guard" className="me-2" />{t('Guard')}</div>}
                    checked={data?.isGuard}
                    className="col-sm-3 mb-2"
                    onClick={() => {
                        onChange({
                            ...data,
                            isGuard: !data?.isGuard,
                        });
                    }}
                />
                <Checkbox
                    label={<div><img height="20" src={guideIcon} alt="Guide" className="me-2" />{t('Guide')}</div>}
                    checked={data?.isGuide}
                    className="col-sm-3 mb-2"
                    onClick={() => {
                        onChange({
                            ...data,
                            isGuide: !data?.isGuide,
                        });
                    }}
                />

                <Checkbox
                    label={<div><BsPersonVideo2 size={20} color="#7a34ff" className="me-2" />{t('Sales Person')}</div>}
                    checked={data?.isSales}
                    className="col-sm-3 mb-2"
                    onClick={() => {
                        onChange({
                            ...data,
                            isSales: !data?.isSales,
                        });
                    }}
                />
            </Row>


            {(data?.isDriver || data?.isGuide) ? <Row className="mb-3">
                <LanguageSelect
                    placeholder={t("Speaks")}
                    value={data?.speaks}
                    label={t("Speaks")}
                    multi
                    onChange={(v) => onChange({ ...data, speaks: v })}
                    error={data?.speaks?.length === 0 ? "Please enter the language" : ""}
                    showErr={hasErr}
                    type="text"
                    className="col-sm-8"

                />
                <InputField
                    placeholder={t("Experience")}
                    value={data ? data?.employeeExtn?.experience : null}
                    label={t("Experience")}
                    onChange={(v) => onChange({ ...data, employeeExtn: { ...data?.employeeExtn, experience: v } })}
                    error={data?.employeeExtn?.experience?.length === 0 ? "Please enter the Experience" : ""}
                    showErr={hasErr}
                    type="number"
                    className="col-sm-4"

                />

            </Row> : null}



            {/* <div className="active ">
                <Row className="px-3">
                    <div className="p-0">EmployeeExtension</div>
                    <hr />
                </Row>
                <Row className=" mb-3 ">
                </Row>
            </div> */}


            <Row className="mb-3">
                <AddressField className="col-sm-12"
                    label={t('Present address')}
                    value={data?.employeeExtn?.presentAddress}
                    onChange={(v) => onChange({ ...data, employeeExtn: { ...data?.employeeExtn, presentAddress: v } })} />
            </Row>
            <Row className=" mb-3">
                <AddressField className="col-sm-12"
                    label={t('Permanent address')}
                    value={data?.employeeExtn?.permanentAddress}
                    onChange={(v) => onChange({ ...data, employeeExtn: { ...data?.employeeExtn, permanentAddress: v } })}
                    error={data?.address?.length === 0 ? t("Please enter address") : ""}
                />
            </Row>

            {data?.isDriver ?
                <Card>
                    <div className='d-flex justify-content-between'>
                        <h5 className="p-0 m-0 mx-4 mt-3">
                            <strong>Permit</strong>
                        </h5>
                    </div>
                    <EditPermit
                        permitNameData={employeePermitConstants}
                        assetKind="employee"
                        onChange={(v) => {
                            onChange({ ...data, permitData: v })
                        }}
                        hasErr={hasErr}
                    />
                </Card>
                : null}

            {/* {(data?.isDriver ?
                <Card className="my-2">
                    <div className='d-flex justify-content-between'>
                        <h5 className="p-0 m-0 mx-4 mt-3">
                            <strong>Permit</strong>
                        </h5>
                    </div>
                    <CardBody>

                        <Row className="mt-3 mb-3">
                            <InputField
                                placeholder={t("Permit Name")}
                                value={data?.name}
                                required={true}
                                label={t("Permit Name")}
                                onChange={(v) => onChange({ ...data, employeeExtn: { ...data?.employeeExtn, name: v } })}

                                // onChange={(v) => setData({ ...data, name: v })}
                                error={data?.name?.length === 0 ? "Please enter Permit Name" : ""}
                                showErr={hasErr}
                                type="text"
                                className="col-sm-4"
                            />
                            <InputField
                                placeholder={t("Permit info")}
                                value={data?.info}
                                label={t("Permit Info")}
                                // onChange={(v) => setData({ ...data, info: v })}
                                onChange={(v) => onChange({ ...data, employeeExtn: { ...data?.employeeExtn, info: v } })}

                                error={data?.info?.length === 0 ? "Please enter Permit info" : ""}
                                showErr={hasErr}
                                type="text"
                                className="col-sm-4"
                            />
                            <InputField
                                placeholder={t("Issuing Authority")}
                                value={data?.issuingAuthority}
                                label={t("Issuing Authority")}
                                // onChange={(v) => setData({ ...data, issuingAuthority: v })}
                                onChange={(v) => onChange({ ...data, employeeExtn: { ...data?.employeeExtn, issuingAuthority: v } })}

                                error={data?.issuingAuthority?.length === 0 ? "Please enter IssuingAuthority" : ""}
                                showErr={hasErr}
                                // required={true}
                                type="text"
                                className="col-sm-4"
                            />

                        </Row>
                        <Row className="mt-3 mb-3">
                            <DateSelect
                                placeholder={t("Start Date")}
                                value={data?.startDate}
                                label={t(" Start Date")}
                                onChange={(v) => onChange({ ...data, startDate: v })}
                                error={data?.startDate?.length === 0 ? t("Please enter Account Start Date") : ""}
                                showErr={hasErr}
                                type="text"
                                className="col-sm-4 col-12"
                            />
                            <DateSelect
                                placeholder={t("Expiry")}
                                value={data?.expiry}
                                label={t("Expiry")}
                                onChange={(v) => onChange({ ...data, expiry: v })}
                                error={data?.expiry?.length === 0 ? t("Please enter Expiry Date") : ""}
                                showErr={hasErr}
                                type="text"
                                className="col-sm-4 col-12"
                            />
                            <InputField
                                placeholder={t("Cost")}
                                value={data?.cost}
                                label={t("Cost")}
                                onChange={(v) => onChange({ ...data, employeeExtn: { ...data?.employeeExtn, cost: v } })}
                                // onChange={(v) => setData({ ...data, cost: v })}
                                error={data?.cost?.length === 0 ? "Please enter cost" : ""}
                                showErr={hasErr}
                                type="number"
                                className="col-sm-4"
                            />

                        </Row>
                    </CardBody>

                </Card>
                : null
            )} */}

            {/* <FileUpload
                type="image"
                value={ data?.pic}
                id={ data?._id}
                module={"employee"}
                field={"pic"}
                onUpload={() => {
                    fetch()
                }}
                onDelete={() => {
                    fetch()
                }}
            /> */}
            {/* <CustomFieldComponent
                module={"supplier"}
                record_id={data?._id}
                kind={2}
                propList={data?.supplierExtension?.properties}
                showGroup={"test1"}
                onChange={(v) =>
                    setData({
                        ...data,
                        supplierExtension: {
                            ...data?.supplierExtension,
                            properties: v,
                        },
                    })
                }
            /> */}
        </div>
    );
}
export default observer(EmployeeDetail);