export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/rate-area/grid?`;
        if (sizePerPage) {
            url += `&rows=${sizePerPage}`;
        } else {
            url += `&rows=${20}`;
        }
        if (filterUrl) url += filterUrl;
        if (page) {
            url += `&page=${page}`;
        }
        return url;
    },
    get: (id) => `/api/rate-area/${id}/detail`,
    save: `/api/rate-area/create`,
    delete: (id) => `/api/rate-area/${id}/delete`,
    update: `/api/rate-area/update`,
    getrateareas: `/api/rate-area/grid`,
};

export const STRUCTURE = [
    {
        label: "Name",
        filterLabel: "Name",
        filterName: "name",
        type: "text",
    },
    // {
    //     label: "Corp_id",
    //     filterLabel: "Corp_id",
    //     filterName: "Corp_id",
    //     type: "text",
    // },

    // {
    //     label: "Country",
    //     filterLabel: "Country",
    //     filterName: "country",
    //     type: "text",
    // },

];





