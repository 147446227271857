
import { makeAutoObservable } from "mobx";
import { API_METHODS, ENDPOINTS } from "../../../common/utils/Constants";
import { ApiHandler } from "../../apiHandler/apiHandler";


class Demo {
  pumps = [];
  clients = [];
  currencies = [];
  addressLines = [];

  countries = [];
  states = {};
  cities = {};

  constructor() {
    makeAutoObservable(this)
  }
  getAllFuelPump = async function () {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllFuelPump;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status  ===  200) {
        this.pumps = response.data.map((c) => { return { ...c, label: c.name, value: c._id } });
      }
    } catch (err) {
      console.log(err);
    }
  };
  getAllClients = async function () {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllClients;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status  ===  200) {
        this.clients = response.data.map((c) => { return { ...c, label: c.name, value: c._id } });
      }
    } catch (err) {
      console.log(err);
    }
  };
  getAllCurrencies = async function () {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllCurrencies;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status  ===  200) {
        this.currencies = response.data.map((c) => { return { ...c, label: c.name, value: c.name } });
      }
    } catch (err) {
      console.log(err);
    }
  };
  getAddressLine = async function (key) {
    if (key === '') {
      this.addressLines = []
      return;
    }
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAddressLine(key);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status  ===  200) {
        this.addressLines = response.data.map((a) => { return { ...a, label: a.addressLine, value: a.addressId } })
      }
    } catch (err) {
      console.log(err);
    }
  };
  getCountries = async function () {
    if (this.countries.length > 0)
      return;
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getCountries;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status  ===  200) {
        this.countries = await JSON.parse(response.data).map((a) => { return { ...a, label: a.name, value: a.name } })
      }
    } catch (err) {
      console.log(err);
    }
  };
  getStatesByCountry = async function (country) {
    if (this.states[country])
      return;
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getStatesByCountry(country);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status  ===  200) {
        this.states = { ...this.states, [country]: Object.values(response.data).map((a) => { return { label: a, value: a } }) }
      }
    } catch (err) {
      console.log(err);
    }
  };
  getCitiesByCountryAndState = async function (country, state) {
    if (this.cities[country + '/' + state])
      return;
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getCitiesByCountryAndState(country, state);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status  ===  200) {
        this.cities = { ...this.cities, [country + '/' + state]: response.data.map((a) => { return { label: a, value: a } }) }
      }
    } catch (err) {
      console.log(err);
    }
  };
};


const DemoMobx = new Demo();

export default DemoMobx;