
import { makeAutoObservable } from "mobx";
import { ApiHandler } from "../../store/apiHandler/apiHandler";
import { API_METHODS, ENDPOINTS } from "../../common/utils/Constants";

class SupervisorRoleStore {

  supervisorRoles = [];
  totalSupervisorRoles = 0;
  totalSupervisorRolePages = 1;
  error = ""
  page = 1;
  rows = 10;

  constructor() {
    makeAutoObservable(this)
  }

  getAllSupervisorRoles = async function (page, sizePerPage, filterName) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllSupervisorRoles(page, sizePerPage, filterName);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status  ===  200) {
        this.supervisorRoles = response.data.rows;
        this.totalSupervisorRoles = response.data.records;
        this.totalSupervisorRolePages = response.data.total;
      }
      else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };

  getSupervisorRoleById = async function (id) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getSupervisorRoleById(id);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status  ===  200) {
        return response.data;
      }
      else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  get supervisorRolesLength() {
    return this.supervisorRoles.length
  }

  saveSupervisorRole = async function (supervisorRoleData) {
    const reqParam = supervisorRoleData;
    const method = API_METHODS.POST;
    const endPoint = ENDPOINTS.saveNewSupervisorRole;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status  ===  200) {
        console.log('success');
      }
      else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteSupervisorRoleById = async function (id) {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.deleteSupervisorRoleById(id);
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status  ===  200) {
        return response.data;
      }
      else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };
  getSupervisorRoleMap = async function () {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.supervisorRoleMap;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status  ===  200) {
        return response.data;
      }
      else {
        this.error = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };

}

const SupervisorRoles = new SupervisorRoleStore();
export default SupervisorRoles;