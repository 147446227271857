import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

function DraggableComponent({
    draggableId,
    index,
    style={},
    children,
    className="",
    isDragDisabled=false
}) {
  return (
    <Draggable isDragDisabled={isDragDisabled}  draggableId={draggableId} index={index}>
    {(provided) => (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ ...style, ...provided.draggableProps.style }}
            className={className}
        >
           {children}
        </div>
    )}
</Draggable>
  )
}

export default DraggableComponent