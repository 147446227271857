import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../../../pages/WorkShift/WorkShiftConstant";

class WorkShiftSelect {
  workShifts = [];
  constructor() {
    makeAutoObservable(this);
  }
  fetch = async function (props) {
    try {
      const response = await doGET(ENDPOINTS.grid());
      if (response.status === 200) {
        this.workShifts = response?.data?.rows?.map((v) => {
          return { label: v.name, value: v._id };
        });
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };
}

const WorkShiftSelectService = new WorkShiftSelect();
export default WorkShiftSelectService;
