import React from 'react';
import moment from 'moment';
import './Comment.scss';
import SupervisorSelect from '../Dropdowns/SupervisorSelect/SupervisorSelect';

const CommentItem = ({ comment }) => {

    const commentDate = moment(comment.at * 1000);
    const timeSinceComment = commentDate.fromNow();

    return (
        <div className="comment-item d-flex">
            <div>
                <SupervisorSelect
                    value={comment?.by}
                    onChange={() => { }}
                    asLabel
                />
            </div>

            <div className="comment-content d-flex flex-column align-items-end justify-content-end">
                <div className='comment-remark'>{comment.content.remark}</div>
                <div className="comment-time">{timeSinceComment}</div>
            </div>
        </div>
    );
};

export default CommentItem;
