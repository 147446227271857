import { toJS } from "mobx";
import React, { useEffect, useState } from "react";
import { SelectField } from "../../SelectField/SelectField";
import DesignationSelectService from "./DesignationSelectService";

export default function DesignationSelect(props) {
    const [desg, setDesg] = useState([]);
    useEffect(() => {
        const fetch = async () => {
            try {
                await DesignationSelectService.fetch();
                setDesg(toJS(DesignationSelectService.desg))
            } catch (error) {
            }
        }
        fetch();
    }, []);
    return (
        <React.Fragment>
            {desg.length > 0 &&
                <SelectField
                    className={props?.className}
                    onChange={props?.onChange}
                    data={desg}

                    value={props?.value}
                    label={"Designations"}
                    error={
                        props?.value
                            ? "Please Select Your Designation"
                            : ""
                    }
                    showErr={props?.showErr}
                    inline={props?.inline}
                    borderNone={props?.borderNone}
                    RightComponent={props?.RightComponent}
                    onClickRight={props?.onClickRight}
                />}
        </React.Fragment>
    )
}
