import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

export default function AddButton({
  className,
  size = 24,
  style = {},
  innerStyle = {},
  label,
  link,
  onClick = () => { } }) {


  let navigate = useNavigate();

  return (
    <div
      style={{
        color: "primary",
        display: "inline-block",
        maxWidth: "fit-content",
        ...style,
      }}
      onClick={() => {
        if (link) navigate(link);
        onClick();
      }}
    >
      <Button
        type="button"
        className={"btn ms-2 btn-primary " + className}
        style={{ height: size * 1.25, width: size * 1.25, padding: "2px", ...innerStyle }}
      >
        <i style={{ fontSize: size }} className="bx bx-plus" />
        {label}
      </Button>
    </div>
  );
}
