import { useEffect, useState } from "react";
import classNames from "classnames";
import "./style.css";
import { Button } from "reactstrap";

const PopConfirm = (props) => {
  const [visible, setVisible] = useState(false);
  const toggle = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <div className="pop-container">
        <span
          onClick={() => {
            toggle();
          }}
        >
          {props?.children}
        </span>
        <div
          className={classNames("pop-content", {
            showContent: visible,
          })}
        >
          <div className="text-content">
            <span>{props?.title}</span>
          </div>
          <div className="actions">
            <Button onClick={toggle}>No</Button>
            <Button
              onClick={() => {
                props?.onConfirm();
                toggle();
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PopConfirm;
