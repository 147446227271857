import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Form, Row } from "reactstrap";

import { BackArrow, CardThreeBounce, CitySelect, ClientSelect, CountrySelect, CrossButton, InputField, SaveButton, scrollStyle, Title } from "../../components";

import { useNavigate } from "react-router-dom";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import CityGroupService from "./CityGroupsService";

const EditCityGroups = (props) => {
  const { t } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const { id, data, setData, hasErr, setHasErr } = props;

  const [freshData, setFreshData] = useState({});
  const { userContent } = useContext(UserContext);

  const fetch = async () => {
    let cityGroupsD = {};
    if (id) cityGroupsD = await CityGroupService.get(id);
    setFreshData(cityGroupsD);
    setData(cityGroupsD);
    setHasErr(false);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetch();
    setHasErr(false);
  }, [id]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(freshData)) {
      props?.onChange(true);
    } else {
      props?.onChange(false);
    }
  }, [data]);

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      {(id && !loading) || !id ? (
        <div className="p-0" style={{ overflowY: "auto", height: "100%", overflowX: "hidden" }}>
          <Form className="p-2">
            <Row className="mt-3 mb-3 ">
              <InputField
                type="text"
                placeholder="Name"
                className="col-sm-4"
                label={"Name"}

                value={data?.name}
                onChange={(v) => {
                  setData({
                    ...data,
                    name: v,
                  });
                }}
              />


              <CountrySelect
                placeholder="Country"
                label={"Country"}
                className="col-sm-4"
                value={data?.country}
                onChange={(v) => {
                  setData({
                    ...data,
                    country: v.name,
                  });
                }}
              />
              <ClientSelect
                placeholder="Client"
                className="col-sm-4"
                label={"Client"}
                value={data?.client_id}
                onChange={(v) => {
                  setData({
                    ...data,
                    client_id: v.value,
                  });
                }}
              />
            </Row>

            <Row className="mt-3 mb-3">
              <Col className="col-lg">
                <CitySelect value={data?.cities} onChange={(v) => setData({ ...data, cities: v })} multi={true} country={data?.country} onlyCountry />
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <CardThreeBounce />
      )}
    </React.Fragment>
  );
};

export default observer(EditCityGroups);
