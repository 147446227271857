// import React, { useState } from 'react';
// import { InputField } from '../..';
// import { Dropdown } from 'reactstrap';

// const AutoSuggestField = ({ suggestData, value, onChange }) => {
//     const [inputValue, setInputValue] = useState(value);

//     const handleSelect = (selectedItem) => {
//         onChange(selectedItem.value);
//         setInputValue(selectedItem.value);
//     };

//     return (
//         <div>
//             <InputField
//                 type="text"
//                 value={value}

//                 onChange={(v) => setInputValue(v)}
//                 placeholder="Type to search"
//             />
//             <Dropdown
//                 data={suggestData}
//                 onSelect={handleSelect}
//                 />
//         </div>
//     );
// };
// export default AutoSuggestField;
import React, { useState } from 'react';
import { InputField } from '../..';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

const AutoSuggestField = ({ suggestData, value, onChange }) => {
    const [inputValue, setInputValue] = useState(value);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleSelect = (selectedItem) => {
        onChange(selectedItem.value);
        setInputValue(selectedItem.label);
        toggleDropdown();
    };

    return (
        <div style={{ display: 'flex' }}>
            <InputField
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e)}
                onClick={toggleDropdown}
                placeholder="Type to search"
            />
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret>
                </DropdownToggle>
                <DropdownMenu>
                    {suggestData.map((item) => (
                        <DropdownItem key={item.value} onClick={() => handleSelect(item)}>
                            {item.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default AutoSuggestField;

