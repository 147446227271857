import React, { useEffect, useState } from "react";
import "./style.scss";

const ModalComponent = ({
    openFrom,
    position = "center",
    size = "md",
    onToggle,
    isOpen,
    children,
    modalSizeStyle,
    modalCloseButton = true
}) => {
    const [isModalOpen, setIsModalOpen] = useState(isOpen);

    useEffect(() => {
        if (openFrom) {
            if (isOpen) {
                setIsModalOpen(true);
            } else if (isModalOpen) {
                const timeout = setTimeout(() => {
                    setIsModalOpen(false);
                }, 2000);
                return () => clearTimeout(timeout);
            }
        } else {
            setIsModalOpen(isOpen);
        }
    }, [isOpen, openFrom, isModalOpen]);

    const handleClose = (e) => {
        onToggle(e);
    };

    const modalPositionClass = `___modal ___modal-${position}`;
    const modalOpensFromClass = `${openFrom === "right" ? !isOpen && isModalOpen ? " ___modal-closeFromRight " : " ___modal-opensFromRight " : ""}`;
    const modalSizeClass = `___modal-content${size === "full-screen" ? "-full-screen" : ""} ___modal-${size}`;

    return (
        <>
            {isModalOpen && (
                <div className="___modal-overlay">
                    <div className={`${modalPositionClass} ${modalOpensFromClass}`}>
                        <div className={`${modalSizeClass}`} style={modalSizeStyle ? { ...modalSizeStyle } : {}}>
                            {modalCloseButton && (
                                <span className="___modal-close" onClick={handleClose}>
                                    &times;
                                </span>
                            )}
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

ModalComponent.Title = ({ children }) => (
    <div className="___modal-title">
        <h2>{children}</h2>
    </div>
);

ModalComponent.Body = ({ children, preventScroll = false, stretch = false }) => (
    <div className={`___modal-body ${stretch ? "___modal-body-stretch" : preventScroll ? "___modal-body-prevent" : "___modal-body-scroll"}`}>
        {children}
    </div>
);

ModalComponent.Footer = ({ children }) => (
    <div className="___modal-footer">
        <div className="___modal-footer-content">{children}</div>
    </div>
);

export default ModalComponent;
