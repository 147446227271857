import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./RateNameConstant";
import EditRateNames from "./EditRateName";
import { GridColumns } from "./RateNameGridColumns";
import RateNameService from "./RateNameService";
import Rateservice from "../Rate/RateService";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";

const RateNames = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
    const { userContent } = useContext(UserContext);
    let { edit_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(edit_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/rateName/create" || edit_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)
    const [showRateName, setShowRateName] = useState(null);
    const [showRateArea, setShowRateArea] = useState(null);
    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await RateNameService.fetch(filterUrl);
            setLoading(false);
        } catch (e) {
            showError(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }, [])


    useEffect(() => {
        setEditID(edit_id)
    }, [edit_id])
    const validateSlabs = (slabs) => {
        for (let i = 1; i < slabs.length; i++) {
            if (slabs[i].fromKM <= slabs[i - 1].tillKM) {
            setHasErr(true)
            showError(t("From KM should be greater than Till KM of previous slab"));
            return false;
            }
            if (slabs[i].fromMin <= slabs[i - 1].tillMin) {
                setHasErr(true)
                showError(t("From Min should be greater than Till Min of previous slab"));
                return false;
            }
            if (slabs[i].tillKM <= slabs[i].fromKM) {
                setHasErr(true)
                showError(t("Till KM should be greater than FromKM "));
                return false;
            }
        }
        return true;
    };
    const onSave = async (e) => {
        e.preventDefault();
        // if (!detailData?.status && !detailData?.remark) {
        //     setHasErr(true)
        //     showError(t("Please enter all required fields"));
        //     return;
        // }
        if (detailData?.isSlabRate && !validateSlabs(detailData?.slabs)) {
            return;
        }
        setLoading(true);
        try {
            if (editID) {
                await RateNameService.edit(detailData, editID);
                setLoading(false);
                // setShowDetailPage(false);
                showMessage(t("RateName updated successfully."));
                await fetchData(filterURL);
            } else {
                const rateName = await RateNameService.save(detailData);
                setLoading(false);
                // setShowDetailPage(false);
                showMessage(t("RateName saved successfully."));
                await fetchData(filterURL);
                if (!insidePane) navigate(`/rateName/edit/${rateName}`);
            }
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
        setHasErr(false)
    };
    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await RateNameService.delete(id);
                setLoading(false);
                setShowDetailPage(false);
                showMessage(t("RateName Deleted"), t("Deleted"));
            } catch (e) {
                showError(e);
                setLoading(false);
            }
        }
    };
    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await RateNameService.bulkDelete(selectedIDs);
                setLoading(false);
                showMessage(t("Deleted"), t("Deleted Successfully"));
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };

    const renderLastCol = useCallback((rateName) => {
        const isSuperAdmin = userContent?.superAdmin_id;
        const shouldShowEditAndDelete = !rateName?.corp_id || isSuperAdmin;
        return (
            <>
                {/* {!shouldShowEditAndDelete && ( */}
                    <>
                        <EditLink
                            onClick={() => {
                                if (!insidePane) navigate(`/rateName/edit/${rateName?._id}`);
                                setEditID(rateName?._id);
                                setShowDetailPage(true);
                            }}
                        />
                        <DeleteLink
                            onClick={(event) => onDelete(event, rateName?._id)}
                        />
                    </>
                {/* )} */}
                {!multiMode && insidePane ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(rateName?._id);
                        }}
                    />
                ) : null}
            </>
        );
    }, [insidePane, multiMode, userContent]);


    const toggleData = {
        module: [
            { label: t("Client Rates"), value: "forClient" },
            { label: t("Supplier Rates"), value: "forSupplier" },
            { label: t("Rate Name"), value: "rateName" },
            { label: t("Rate Area"), value: "rateArea" },
        ],
        toggleValue: "rateName"
    }
    return (
        <React.Fragment>
            <>
                <Layout
                    // hideAdd={!userContent?.rights?.includes(1901)}
                    showToggle={!userContent || !userContent.superAdmin_id}
                    // showToggle
                    compact
                    toggleData={toggleData}
                    onToggle={(selectedValue) => {
                        if (selectedValue === "rateName") {
                            navigate(`/rateName`);
                        } else if (selectedValue === "rateArea") {
                            navigate(`/rate-area`);
                        } else if (selectedValue === "forClient") {
                            Rateservice.onSupplierChange(false);
                            navigate("/rate");
                        } else if (selectedValue === "forSupplier") {
                            Rateservice.onSupplierChange(true);
                            navigate("/rate");
                        }
                    }}
                    showRateName={showRateName}
                    setShowRateName={setShowRateName}
                    showRateArea={showRateArea}
                    setShowRateArea={setShowRateArea}
                    showDetailPage={showDetailPage}
                    backDetailPage={async () => {
                        setShowDetailPage(false);
                        if (!insidePane) navigate("/rateName");
                        setEditID(null);
                    }}
                     
                    title={userContent && userContent.superAdmin_id ? t("Rate Names") : undefined}
                    // title={t("Rate Names")}
                    filterValues={filterObject}
                    filterStructure={STRUCTURE}
                    onApplyFilter={fetchData}
                    // hideSearch
                    onAddClick={() => {
                        if (!insidePane) navigate(`/rateName/create`);
                        setShowDetailPage(true);
                        setEditID(null);
                    }}
                    insidePane={insidePane}
                    page={RateNameService.page}
                    rows={RateNameService.rows}
                    total={RateNameService.total}
                    fetch={RateNameService.fetch}
                >
                    <Layout.ActionMenu>
                        <div className="layout-action-menu">
                            <DropdownMenu>
                                <>
                                    {/* {userContent?.rights?.includes(1903) ? */}
                                    <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                                    <div class="dropdown-divider"></div>
                                    {/* : null} */}
                                    <DropdownItem>{t("Upload")}</DropdownItem>
                                    <div class="dropdown-divider"></div>
                                    <DropdownItem> Download </DropdownItem>
                                </>

                            </DropdownMenu>

                        </div>
                    </Layout.ActionMenu>
                    <Layout.Table>
                        <DataGrid
              gridLoading={loading}
                            data={RateNameService.records}
                            total={RateNameService.total}
                            uiPreference="rateName.grid"
                            headers={GridColumns}
                            onSelectChange={(v) => {
                                onSelect(v)
                                setSelectedIDs(v)
                            }}
                            selectedIDs={selectedIDs}
                            page={RateNameService.page}
                            rowsPerPage={RateNameService.rowsPerPage}
                            onPaginationChange={RateNameService.onPaginationChange}
                            renderLastCol={renderLastCol}
                        />
                    </Layout.Table>


                    <Layout.DetailPageTitle>
                        {detailData?._id ? t("Edit Rate Name") : t("Add Rate Name")}
                    </Layout.DetailPageTitle>

                    <Layout.DetailPageBody>
                        <EditRateNames
                            editId={editID}
                            onChange={(v) => {
                                setDetailData(v)
                            }}
                            hasErr={hasErr}
                        />
                    </Layout.DetailPageBody>
                    <Layout.DetailPageFooter>
                        {/* {userContent?.rights?.includes(1903) ? */}
                        {editID ? (
                            <AuditLogDialog module="RateArea" id={editID} />
                        ) : null

                        }
                        {editID && <DeleteButton
                            loading={loading}
                            onClick={(e) => onDelete(e, detailData?._id)} />}
                        {/* : null} */}
                        {/* {userContent?.rights?.includes(1902) ? */}
                        <SaveButton
                            loading={loading}
                            onClick={onSave} />
                        {/* : null} */}
                    </Layout.DetailPageFooter>
                </Layout>
            </>
        </React.Fragment>
    );
};
export default observer(RateNames);
