import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import SpaceService from "../listView/SpaceService"; // Adjust the import path as necessary
import SpaceItem from "./SpaceItem";
import "./style.scss";
import { useContext } from "react";
import { DialogContext } from "../../../store/context/DialogContext";

const LeadSidebar = observer(() => {

  const [collapsedSpaces, setCollapsedSpaces] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [newSpaceName, setNewSpaceName] = useState('');
  const {showError } = useContext(DialogContext)

  const fetch = async () => {
    const response = await SpaceService.fetch();
  }

  useEffect(() => {
    fetch()
  }, []);

  const handleCreateSpace = async ({
    name,
    parentId = null
  }) => {
    try {
      const response = await SpaceService.save({
        module: "lead",
        name: name,
        parentSpace_id: parentId,
        statusNameMap: {
          1: {
            name: "New",
            color: "#d33d44"
          },
          30: {
            name: "Archived",
            color: "#5f55ee"
          },
        }
      });


      const childResponse = await SpaceService.save({
        module: "lead",
        name: "sample 1",
        parentSpace_id: response,
        statusNameMap: {
          1: {
            name: "New",
            color: "#d33d44"
          },
          30: {
            name: "Archived",
            color: "#5f55ee"
          },
        }
      }, true);

      SpaceService.setSelectedSpace(childResponse);
    } catch (error) {
      showError(error)
    }
  };



  const toggleCollapse = (id) => {
    setCollapsedSpaces((prevCollapsed) => ({
      ...prevCollapsed,
      [id]: !prevCollapsed[id]
    }));
  };

  const handleStartCreatingSpace = () => {
    setIsCreating(true);
  };

  const handleNewSpaceNameChange = (event) => {
    setNewSpaceName(event.target.value);
  };

  const handleNewSpaceKeyDown = async (event) => {
    if (event.key === 'Enter' && newSpaceName.trim() !== '') {
      await handleCreateSpace({ name: newSpaceName.trim() });
      setNewSpaceName('');
      setIsCreating(false);
    }
  };

  const renderSpaces = (spaces, level = 0) => {
    return spaces.map((space) => (
      <div className="" key={space._id} style={{ paddingLeft: `${level * 20}px` }}>
        <SpaceItem
          key={space._id}
          space={space}
          onToggleCollapse={() => toggleCollapse(space._id)}
          onSpaceClick={() => {
            if (space?.parentSpace_id) {
              SpaceService.setSelectedSpace(space);
            }
          }}
        />
        {!collapsedSpaces[space._id] && space.children && renderSpaces(space.children, level + 1)}
      </div>
    ));
  };

  return (
    <>
      <div className="p-2 ">
        {renderSpaces(SpaceService.records)}
        {isCreating ? (
          <input
            autoFocus
            type="text"
            placeholder="Space name"
            value={newSpaceName}
            onBlur={() => {
              setNewSpaceName('');
              setIsCreating(false);
            }}
            onChange={handleNewSpaceNameChange}
            onKeyDown={handleNewSpaceKeyDown}
            className="mt-2 space-creation-input" // Make sure to define this class in your CSS
          />
        ) : (
          <div
            style={{ backgroundColor: "#EBEBEB", borderRadius: "4px", padding: "4px" }}
            className="mt-2 d-flex align-items-center clickable"
            onClick={handleStartCreatingSpace}>
            <FaPlus className="me-2" />
            <span className="font-size-14">Create Space</span>
          </div>
        )}
      </div>
    </>
  );
});

export default LeadSidebar;
