

import React, { useContext } from 'react'
import { UserContext } from '../../store/context/UserContext';
import EditLink from '../Buttons/Icons/editButton';
import CheckButton from '../Buttons/Icons/checkButton';
import { useNavigate } from 'react-router-dom';

function LastColumn({booking,onEdit,onSelect,insidePane,multiMode}) {
    const {userContent}=useContext(UserContext);
    const navigate=useNavigate();

    return (
        <>
            {userContent?.rights?.includes(6002) &&
                <EditLink
                    onClick={onEdit}
                />
            }
            {!multiMode && insidePane ? (
                <div>
                    <CheckButton
                        onClick={onSelect}
                    />
                </div>
            ) : null}


        </>
    );
}

export default LastColumn