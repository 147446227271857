import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import Modal from "../../Common/Modal";
import { SelectField } from "../../SelectField/SelectField";
import Tag from "./Tag";
import TagSelectService from "./TagService";

const TagSelect = (props) => {
  const [tags, setTags] = useState([]);
  const [modal, setModal] = useState(false);


  useEffect(() => {
    const fetch = async () => {
      try {
        await TagSelectService.fetch();
        setTags(toJS(TagSelectService.tags));
      } catch (error) {
        console.log(error.message);
      }
    };
    fetch();
  }, []);

  const renderFunc = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps}>
        <Table style={{ margin: "0px" }} className="dropdown-hover">
          <thead style={{ margin: "0px" }}>
            <tr style={{ margin: "0px" }}>
              <td className="col-3 py-3">
                <img
                  src={data.pic}
                  alt="car"
                  style={{
                    display: "inline",
                    maxHeight: "40px",
                    margin: "0px 5px",
                  }}
                />
              </td>
              <td className="col-6 py-3">{`${data.regNo}`}</td>
            </tr>
          </thead>
        </Table>
      </div>
    );
  };

  return (
    <React.Fragment>
      {modal ? (
        <Modal
          onChange={() => {
            setModal(false);
          }}
        >
          <Tag />
        </Modal>
      ) : null}

      <SelectField
        render={renderFunc}
        changeOptionsData={(e) => {
          e.preventDefault()
          setModal(true);
        }}
        bottomBarChildren={() => (
          <Button
            color="primary"
            className="btn btn-primary btn-lg btn-block waves-effect waves-light mb-1"
          >
            Manage Tag
          </Button>
        )}
        showBottomBar={true}
        onInputChange={(v) => {
          if (v.length > 1) fetch(v);
        }}
        className={props?.className}
        onChange={props?.onChange}
        data={tags}

        value={props?.value}
        label={"Tag"}
        error={props?.value ? "Please Select Tag" : ""}
        showErr={props?.showErr}
        inline={props?.inline}
        borderNone={props?.borderNone}
        onClickRight={(e) => {
          e.preventDefault()
          setModal(true);
        }}
      />
    </React.Fragment>
  );
};

export default observer(TagSelect);