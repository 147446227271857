import React from 'react';
import './style.css';
import { FaCarAlt } from 'react-icons/fa';

const VehiclePopup = ({ vehicle }) => {
    return (
        <div>
          
          <div className='d-flex'>
                <FaCarAlt className="me-2 font-size-14" />
                <div>{vehicle.regNo}</div> 
          </div>
            <div className="vehicle-popup-container">
                <div className="vehicle-info">
                

                    {vehicle?.driverAssigned && (

                        <p className="info-column">
                            <span className="info-label"><span>Driver Assigned</span> <span>:</span></span>
                            <span className="info-value">{vehicle.driverAssigned}</span>
                        </p>

                    )}

                    {vehicle?.year && (

                        <p className="info-column">
                            <span className="info-label"><span>Year</span> <span>:</span> </span>
                            <span className="info-value">{vehicle.year}</span>
                        </p>

                    )}

                    {vehicle?.kind && (

                        <p className="info-column">
                            <span className="info-label"><span>Status</span> <span>:</span> </span>
                            <span className="info-value">{vehicle.kind}</span>
                        </p>

                    )}
                </div>
            </div>
        </div>

    );
};

export default VehiclePopup;
