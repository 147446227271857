import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Card, CardBody } from 'reactstrap';
import CountUp from 'react-countup';
import DashboardService from '../../../pages/Dashboard/DashboardService';


export const SosCard = ({ }) => {
  const [sosCount, setSosCount] = useState(null);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();


  const fetchSOS = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let body = {
        filters: {
          issue: "SOS",
          status: 0
      },
      groupBy:{
          issue:"issue"
      }
      }
      const response = await DashboardService.fetchSOSStats(body);
      const sosCount = response
      .filter(item => item._id.issue === "SOS")
      .reduce((acc, item) => acc + item.count, 0);
      setSosCount(sosCount)
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSOS();
  }, [])

  const redirect = () => {
    return navigate(`/incident?status=0&issue=SOS`);
}

  return (
    <React.Fragment>
      <Col md={6} xl={3} onClick={()=>{redirect()}} style={{cursor:"pointer"}}>
        <Card style={{ height: "116px" }}>
          <CardBody>
            <div className='d-flex flex-column justify-content-center h-100'>
              <h2 className="mb-1 mt-1 flex-1 d-flex justify-content-center">
                <span>
                  <CountUp
                    end={sosCount}
                    separator=","
                    prefix=""
                    suffix=""
                    decimals={0}
                  />
                </span>
              </h2>
              <h3 className="text-center text-muted mb-0">SOS</h3>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
};

