/* eslint-disable react/jsx-no-target-blank */
import { ThreeBounce } from 'better-react-spinkit'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

export default function DownloadButton(props) {
    return (
        <a href={props?.link} download={props?.name} target="_blank">
            <Button disabled={props?.isDisabled || props?.loading} type="button" className="btn btn-primary d-flex align-items-center" style={props?.style} onClick={props?.onClick}>

                {props?.loading ? <ThreeBounce color={'white'} size={8} /> : <> <i style={{ fontSize: "16px" }} className="uil-cloud-download"></i>
                    <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                        Download
                    </span> </>}

            </Button>
        </a>
    )
}
