import React, { useEffect, useRef, useState } from "react";
import CurrencySelect from "../Dropdowns/CurrencySelect/CurrencySelect";
import "./styles.css"; // Assuming styles.css contains necessary styles

export default function CurrencyAmountField100({ outline, value = { currency: "INR", amount: 0 }, required, onChange, showErr, className, label, asLabel, scaleFactor = 100 }) {
  const [displayValue, setDisplayValue] = useState('');
  const [isFocused, setIsFocused] = useState(true);
  const inputRef = useRef(null);

  useEffect(() => {
    setDisplayValue((value.amount / scaleFactor).toFixed(2));
  }, [value.amount, scaleFactor]);

  useEffect(() => {
    if (!value?.amount && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [value]);

  useEffect(() => {
    onChange({ ...value, currency: "INR" });
  }, []);

  const handleFocus = () => {
    setIsFocused(true);
    setTimeout(() => {
      inputRef.current.select(); // Select all text
    }, 0);
  };

  const handleBlur = () => {
    let formattedValue = parseFloat(displayValue);
    if (!isNaN(formattedValue)) {
      formattedValue = formattedValue.toFixed(2);
    } else {
      formattedValue = '0.00';
    }
    setDisplayValue(formattedValue);
    onChange({ ...value, amount: Math.round(parseFloat(formattedValue) * scaleFactor) });
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue) || inputValue === '') {
      setDisplayValue(inputValue);
    }
  };

  const handleClick = () => {
    setIsFocused(true);
    inputRef.current.focus();
  };

  const renderIntegerPart = () => {
    if (!isFocused) {
      return (
        <span style={{ fontSize: "11px", color: "black" }}>
          {displayValue.split(".")[0]}.
        </span>
      );
    } else {
      return null;
    }
  };

  const renderDecimalPart = () => {
    if (!isFocused) {
      return (
        <span className="" style={{ fontSize: "11px", color: "#000", marginBottom: "0px" }}>
          {displayValue.split('.')[1] || '00'}
        </span>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {asLabel ?
        <div>
          {value?.currency} {displayValue}
        </div>
        :
        <div className={className + " d-flex justify-content-center align-items-center "}>
          {!outline && <div
            style={{ width: "120px", fontSize: 11, fontWeight: "500" }}
          >
            {label}
            {required && <font color="red">*</font>}
          </div>}

          <div
            style={{ margin: "10px 0px 5px 0px", height: "36px" }}
            className={"input-group flex-1"}
          >
            <div className="input-group-prepend">
              <CurrencySelect
                className={`m-0`}
                value={value?.currency ? value?.currency : 'INR'}
                noLabel
                innerContainerStyle={{ borderRadius: "0px 0px 0px 0px" }}
                style={{ borderRadius: "6px 0px 0px 6px" }}
                showClear={false}
                outline
                onChange={(v) => onChange({ ...value, currency: v?.value ? v?.value : 'INR' })}
                showErr={showErr}
                inputGroup
              />
            </div>
            <input
              ref={inputRef}
              placeholder={label ?? "Amount"}
              style={{ height: "36px", border: "none", borderBottom: "1.5px solid #ced4da", borderRadius: "0px", textAlign: "right" }}
              value={displayValue}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              type="text"
              className="form-control"
            />
            <div className="position-absolute" style={{
              position: "absolute",
              right: "10px"
            }} onClick={handleClick}>
              {renderIntegerPart()}
              {renderDecimalPart()}
            </div>
          </div>
        </div>}
    </>
  );
}
