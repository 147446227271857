export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/fuel/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`;
    url += `&sortBy=time&sortAsc=false`
    return url;
  },
  get: (id) => `/api/fuel/${id}/detail`,
  save: `/api/fuel/create`,
  delete: (id) => `/api/fuel/${id}/delete`,
  bulkDelete: (id) => `/api/fuel/delete`,
  update: `/api/fuel/update`,
};
export const SELECTSTRUCTURE = {
  fuelType: [
    { label: "PETROL", value: "PETROL" },
    { label: "DIESEL", value: "DIESEL" },
    { label: "CNG", value: "CNG" },
    { label: "ELECTRIC", value: "ELECTRIC" },
  ],
  qtyType: [
    { label: "Litre", value: "Litre" },
    { label: "KG", value: "KG" },
    { label: "KW", value: "KW" },
  ],
  paymentMode: [
    { label: "CARD", value: "CARD" },
    { label: "CASH", value: "CASH" },
    { label: "CHEQUE", value: "CHEQUE" },
    { label: "ONLINE", value: "ONLINE" },
    { label: "UPI", value: "UPI" },
    { label: "WALLET", value: "WALLET" },
    { label: "VOUCHER", value: "VOUCHER" },
    { label: "OTHER", value: "OTHER" },
  ],

  kind: [
    { label: "Regular PickUp", value: 1 },
    { label: "Regular Drop", value: 2 },
    { label: "Adhoc Pickup", value: 3 },
    { label: "Adhoc Drop", value: 4 },
    { label: "Open Shuttle", value: 5 },
    { label: "Reserved Shuttle", value: 6 },
    { label: "Public Shuttle", value: 10 },
    { label: "False", value: "False" },
  ],
  outstation: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  fullTank: [
    { label: "Full", value:true  },
    { label: "Not Full", value: false },
  ],
};

export const STRUCTURE = [
  {
    label: "Vehicle",
    filterLabel: "Vehicle",
    filterName: "vehicle_id",
    type: "vehicleSelect",
  },
  {
    label: "Employee",
    filterLabel: "Employee",
    filterName: "employee_id",
    type: "driverSelect",
  },
  {
    label: "Added By",
    filterLabel: "Added By",
    filterName: "by",
    type: "employeesupervisorselect",
  },
  {
    label: "Full Tank",
    filterLabel: "Full Tank",
    filterName: "fullTank",
    type: "select",
    data: SELECTSTRUCTURE?.fullTank,
  },
  {
    label: "Outstation",
    filterLabel: "OutStation",
    filterName: "isOutstation",
    type: "select",
    data:SELECTSTRUCTURE.outstation
  },
  {
    label: "Payment Mode",
    filterLabel: "Payment Mode",
    filterName: "paymentMode",
    type:"select",
    data: SELECTSTRUCTURE?.paymentMode,
  },
];
