export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/vehicle-driver-timesheet/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/vehicle-driver-timesheet/${id}/detail`,
  save: `/api/vehicle-driver-timesheet/create`,
  delete: (id) => `/api/vehicle-driver-timesheet/${id}/delete`,
  update: `/api/vehicle-driver-timesheet/update`,
};

export const STRUCTURE = [
  {
    label: "Vehicle",
    filterLabel: "Vehicle",
    filterName: "vehicle_id",
    type: "vehicleSelect",
  },

  // {
  //   label: "Driver",
  //   filterLabel: "Driver",
  //   filterName: "employee_id",
  //   type: "employeeSelect",
  // },
];
