import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditTerms from "./EditTerm";
import { STRUCTURE, defaultPaymentTerms } from "./TermConstant";
import { GridColumns } from "./TermGridColumns";
import TermService from "./TermService";

const Terms = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  const { userContent } = useContext(UserContext);
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(
    insidePane ? false : window.location.pathname === "/term/create" || edit_id
  );
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false);

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await TermService.fetch(filterUrl);
      setLoading(false);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(
      queryParams,
      STRUCTURE
    );
    setFilterURL(filterUrl);
    setFilterObject(filterObject);
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : []);
  }, []);

  useEffect(() => {
    setEditID(edit_id);
  }, [edit_id]);

  const onSave = async (e) => {
    e.preventDefault();
    // if (!detailData?.status && !detailData?.remark) {
    //     setHasErr(true)
    //     showError(t("Please enter all required fields"));
    //     return;
    // }
    let transformedPaymentTerms = detailData?.paymentTerms?.map(term => {
      return {
        paymentEvent: term?.paymentEvent,
        ...(term?.isAbs ? { percent: term?.value } : { baseAmount: term?.value })
      }
    })

    let transformedCancellationSlabs = detailData?.cancellationSlabs?.map(cancelSlab => {
      return {
        duration: cancelSlab?.duration,
        ...(cancelSlab?.isAbs ? { percent: cancelSlab?.value } : { baseAmount: cancelSlab?.value })
      }
    })

    const transformData = {
      ...detailData,
      paymentTerms: transformedPaymentTerms,
      cancellationSlabs: transformedCancellationSlabs
    }

    setLoading(true);
    try {
      if (editID) {
        await TermService.edit(transformData, editID);
        setLoading(false);
        // setShowDetailPage(false);
        showMessage(t("Record updated successfully."));
      } else {
        const term = await TermService.save(transformData);
        setLoading(false);
        // setShowDetailPage(false);
        showMessage(t("Record saved successfully."));
        if (!insidePane) navigate(`/term/edit/${term}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
    setHasErr(false);
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await TermService.delete(id);
        setLoading(false);
        setShowDetailPage(false);
        showMessage(t("Deleted"), t("Deleted"));
      } catch (e) {
        showError(e);
        setLoading(false);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await TermService.bulkDelete(selectedIDs);
        setLoading(false);
        showMessage(t("Deleted"), t("Deleted Successfully"));
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };

  return (
    <React.Fragment>
      <>
        <Layout
          medium
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/term");
            setEditID(null);
          }}
           
          title={t("Terms")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/term/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={TermService.page}
          rows={TermService.rows}
          total={TermService.total}
          fetch={TermService.fetch}
        >
          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  {userContent?.rights?.includes(5903) ? (
                    <DropdownItem
                      onClick={() => {
                        onBulkDelete();
                      }}
                    >
                      {t("Bulk Delete")}
                    </DropdownItem>
                  ) : null}
                  <div class="dropdown-divider"></div>
                  <DropdownItem>{t("Upload")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem> Download </DropdownItem>
                </>
              </DropdownMenu>
            </div>
          </Layout.ActionMenu>
          <Layout.Table>
            <DataGrid
              gridLoading={loading}
              data={TermService.records}
              total={TermService.total}
              uiPreference="term.grid"
              headers={GridColumns}
              onSelectChange={(v) => {
                onSelect(v);
                setSelectedIDs(v);
              }}
              selectedIDs={selectedIDs}
              page={TermService.page}
              rowsPerPage={TermService.rowsPerPage}
              onPaginationChange={TermService.onPaginationChange}
              renderLastCol={(term) => {
                return (
                  <>
                    <EditLink
                      onClick={() => {
                        if (!insidePane) navigate(`/term/edit/${term?._id}`);
                        setEditID(term?._id);
                        setShowDetailPage(true);
                      }}
                    />
                    {userContent?.rights?.includes(5903) ? (
                      <DeleteLink
                        onClick={(event) => onDelete(event, term?._id)}
                      />
                    ) : null}
                    {!multiMode && insidePane ? (
                      <CheckButton
                        onClick={() => {
                          onSelect(term?._id);
                        }}
                      />
                    ) : null}
                  </>
                );
              }}
            />
          </Layout.Table>
          <Layout.DetailPageTitle>
            {detailData?._id ? t("Edit Term") : t("Add Term")}
          </Layout.DetailPageTitle>

          <Layout.DetailPageBody>
            <EditTerms
              editId={editID}
              onChange={(v) => {
                setDetailData(v);
              }}
              hasErr={hasErr}
            />
          </Layout.DetailPageBody>

          <Layout.DetailPageFooter>
            {userContent?.rights?.includes(5903) && detailData?._id ? (
              <DeleteButton
                loading={loading}
                onClick={(e) => onDelete(e, detailData?._id)}
              />
            ) : null}
            {userContent?.rights?.includes(5902) ? (
              <SaveButton loading={loading} onClick={onSave} />
            ) : null}
          </Layout.DetailPageFooter>
        </Layout>
      </>
    </React.Fragment>
  );
};
export default observer(Terms);
