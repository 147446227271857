import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import MarketPlaceService from "./MarketPlaceService";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css"
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import RfpTabs from "./RfpTabs";
import { UserContext } from "../../store/context/UserContext";
import { MARKETPLACE_BASE_URL } from "./MarketPlaceConstant";
import axios from "axios";
const BidsDashboard = (props) => {
    const { id } = useParams();
    const ReactTableFixedColumns = withFixedColumns(ReactTable);
    const { showError } = useContext(DialogContext);
    const { t } = useContext(I18nContext);
    const [data, setData] = useState([{}]);
    const { userContent } = useContext(UserContext)
    const [loading, setLoading] = useState(false);
    const [selectedtab, setselectedTab] = useState("Bids")
    const [companydetails, setCompanydetails] = useState({})
    const fetchData = async (filterUrl) => {
        if (loading) return;
        setLoading(true);
        try {
            await MarketPlaceService.fetch(filterUrl);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };
    const getCompanyDetails = async (filterUrl) => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await axios.get(`${MARKETPLACE_BASE_URL}/get/company/${userContent?.corp_id}`)
            setCompanydetails(response?.data?.data)
        } catch (e) {
            showError(e?.message);
        }
        setLoading(false);
    };
    useEffect(() => {
        console.log(userContent);
        setData([{
            supplier: "Kartik Transport",
            location: "New Delhi",
            s: 5,
            m: 10,
            ss: 12,
            ms: 15,
            ls: 16,
            lm: 20,
            s_12: 12,
            s_18: 19,
            s_20: 20,
            s_30: 30,
            bus: 2,
            luxb: 0,
            rating: 4,
            fullfillment: "Partial",
            price: "30,000",
            status: "Active"

        },
        {
            supplier: "Modern Transport",
            location: "Gurgaon",
            s: 5,
            m: 10,
            ss: 12,
            ms: 15,
            ls: 16,
            lm: 20,
            s_12: 12,
            s_18: 19,
            s_20: 20,
            s_30: 30,
            bus: 2,
            luxb: 0,
            rating: 4,
            fullfillment: "Partial",
            price: "30,000",
            status: "Active"

        }])
        getCompanyDetails()
    }, []);

    return (
        <>
            <div className=" d-flex flex-column">
                <div style={{ marginTop: "100px" }} className="d-flex justify-content-between">
                    <div className=" d-flex justify-content-lg-start px-4 ">
                        <div className=" marketplace-dashboard-header">
                            <RfpTabs selectedtab={selectedtab} setselectedTab={setselectedTab} />
                        </div>

                    </div>

                </div>

                <div className=" mt-5">
                    <Card className=' mt-3 m-auto br-20' style={{ height: "265px", width: "98%" }}>
                        <h1 className='mt-3  fw-bolder'>Company Details</h1>
                        <div className=" mt-4">
                            <Row>
                                <Col>
                                    <div className=" d-flex justify-content-around">

                                        <div className=" fw-lighter fa-15"><span className=" px-3">#</span>ID</div>
                                        <div className=" fw-bold fa-15">#123456</div>
                                    </div>

                                </Col>
                                <Col>
                                    <div className=" d-flex justify-content-around">
                                        <div className=" fw-lighter fa-15"><i className="uil uil-user me-1"></i></div>
                                        <div className=" fw-lighter fa-15 " style={{ marginRight: "15px" }} >Contact Person</div>
                                        <div className=" fw-bold fa-15">John Doe</div>
                                    </div>

                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className=" col-sm-6">
                                    <div className=" d-flex justify-content-around">
                                        <div className=" fw-lighter fa-15" style={{ marginLeft: "50px" }}><span className=" px-3"><i className="fas fa-building"></i></span>Company Name</div>
                                        <div className=" fw-bold fa-15">{companydetails?.companyName}</div>
                                    </div>

                                </Col>
                                <Col className=" col-sm-6">
                                    <div className=" d-flex justify-content-around">
                                        <div className=" fw-lighter fa-15"><i className="uil uil-envelope-alt me-1"></i></div>
                                        <div className=" fw-lighter fa-15">Email</div>
                                        <div className=" fw-bold fa-15 ">johndoe@gmail.com</div>
                                    </div>

                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className=" col-sm-6">
                                    <div className=" d-flex justify-content-around">
                                        <div className=" fw-lighter fa-15" style={{ marginLeft: "100px" }}><span className=" px-3"><i className="uil-location-point"></i></span>Office Address</div>
                                        <div className=" fw-bold fa-15">4140 Parker Rd. Allentown, New Mexico 31134</div>
                                    </div>

                                </Col>
                                <Col className=" col-sm-6">
                                    <div className=" d-flex justify-content-around">
                                        <div className=" fw-lighter fa-15"><i className="uil uil-phone me-1"></i></div>
                                        <div className=" fw-lighter fa-15">Phone</div>
                                        <div className=" fw-bold fa-15 ">+91-848484848484</div>
                                    </div>

                                </Col>
                            </Row>
                        </div>



                    </Card>

                </div>

                <div >
                    <Card className=' mt-3  br-20 bid-card-2' >
                        <h1 className='mt-3  fw-bolder'>Requirements</h1>
                        <div className=" mt-4">
                            <Row>
                                <Col>
                                    <div className=" d-flex justify-content-around">

                                        <div className=" fw-lighter fa-15" style={{ marginLeft: "110px" }}>City</div>
                                        <div className=" fw-bold fa-15">New Delhi</div>
                                    </div>

                                </Col>
                                <Col>
                                    <div className=" d-flex justify-content-around">

                                        <div className=" fw-lighter fa-15 " style={{ marginRight: "15px" }} >Drop Off City</div>
                                        <div className=" fw-bold fa-15">Chandigarh</div>
                                    </div>

                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className=" col-sm-6">
                                    <div className=" d-flex justify-content-around">
                                        <div className=" fw-lighter fa-15" style={{ marginLeft: "50px" }}><span className=" px-3"></span>No of Pax</div>
                                        <div className=" fw-bold fa-15">800</div>
                                    </div>

                                </Col>
                                <Col className=" col-sm-6">
                                    <div className=" d-flex justify-content-around">

                                        <div className=" fw-lighter fa-15">Pickup Time</div>
                                        <div className=" fw-bold fa-15 ">Morning</div>
                                    </div>

                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className=" col-sm-6">
                                    <div className=" d-flex justify-content-around">
                                        <div className=" fw-lighter fa-15" style={{ marginLeft: "100px" }}>UserType</div>
                                        <div className=" fw-bold fa-15">Regular</div>
                                    </div>

                                </Col>
                                <Col className=" col-sm-6">
                                    <div className=" d-flex justify-content-around">
                                        <div className=" fw-lighter fa-15">Vehicle Type</div>
                                        <div className=" fw-bold fa-15 ">Small,Bus</div>
                                    </div>

                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className=" col-sm-6">
                                    <div className=" d-flex justify-content-around">
                                        <div className=" fw-lighter fa-15" style={{ marginLeft: "100px" }}>PickUp City</div>
                                        <div className=" fw-bold fa-15">Gurgaon</div>
                                    </div>

                                </Col>
                                <Col className=" col-sm-6">
                                    <div className=" d-flex justify-content-around">
                                        <div className=" fw-lighter fa-15" style={{ marginLeft: "50px" }}>Service Type</div>
                                        <div className=" fw-bold fa-15 "> Regular Employee Pickup & Drop</div>
                                    </div>

                                </Col>
                            </Row>
                        </div>



                    </Card>

                </div>
                <div>
                    <div style={{ marginTop: "60px" }}>
                        <Card className=' mt-5  br-20 m-auto px-2' >
                            <h1 className='mt-3  fw-bolder'>Bits Submitted</h1>
                            <ReactTableFixedColumns
                                data={data}
                                columns={[
                                    {
                                        Header: "Supplier",
                                        fixed: "left",
                                        columns: [
                                            {

                                                accessor: "supplier",
                                                width: 200
                                            },

                                        ]
                                    },
                                    {
                                        Header: "Location",
                                        fixed: "left",
                                        columns: [
                                            {

                                                accessor: "location",
                                                width: 200
                                            },

                                        ]
                                    },
                                    {
                                        Header: "Inventory Count",
                                        columns: [
                                            {
                                                Header: "S",
                                                accessor: "s",
                                                width: 70
                                            }, {
                                                Header: "M",
                                                accessor: "m",
                                                width: 70
                                            },
                                            {
                                                Header: "SS",
                                                accessor: "ss",
                                                width: 70
                                            }, {
                                                Header: "MS",
                                                accessor: "ms",
                                                width: 70
                                            },
                                            {
                                                Header: "LS",
                                                accessor: "ls",
                                                width: 70
                                            },
                                            {
                                                Header: "LM",
                                                accessor: "lm",
                                                width: 70
                                            }, {
                                                Header: "12S",
                                                accessor: "s_12",
                                                width: 70
                                            },
                                            {
                                                Header: "18S",
                                                accessor: "s_18",
                                                width: 70
                                            },
                                            {
                                                Header: "30S",
                                                accessor: "s_30",
                                                width: 70
                                            }, {
                                                Header: "BUS",
                                                accessor: "bus",
                                                width: 70
                                            },
                                            {
                                                Header: "LuxB",
                                                accessor: "luxb",
                                                width: 70
                                            }, {
                                                Header: "Rating",
                                                accessor: "rating",
                                                width: 100
                                            },
                                            {
                                                Header: "Fullfillment",
                                                accessor: "fullfullment",
                                                width: 100
                                            }, {
                                                Header: "Price",
                                                accessor: "price",
                                                width: 100
                                            },
                                            {
                                                Header: "Status",
                                                accessor: "status",
                                                width: 100
                                            },


                                        ]
                                    },

                                ]}
                                defaultPageSize={50}
                                style={{ height: 500 }}
                                className="-striped"
                            />



                        </Card>

                    </div>

                </div>
            </div >



        </>
    );
};

export default observer(BidsDashboard);
