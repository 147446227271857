import { MakeModelSelect } from "../../components";

export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Vehicle Type",
            accessor: "name",
            show: true,
        },
        {
            title: "Model Name",
            accessor: "makeModels",
            show: true,
        },
        {
            title: "Default Make Model",
            renderer: (dataObj) => {
                return <MakeModelSelect
                    asLabel
                    value={dataObj?.defaultMakeModel}
                    onChange={() => { }}
                    className="col-sm-12"
                />
            },
            show: true,
        },
        {
            title: "Image",
            accessor: "pic",
            renderer: (dataObj) => {
                return <img height="20px" src={dataObj?.pic} />;
            },
            show: true,

        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
