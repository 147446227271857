import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Col } from "reactstrap";
import { Checkbox, InputField, SelectField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import BannerService from "./BannerService";
import { SELECTSTRUCTURE } from "./BannerConstant";
import { ThreeBounce } from "better-react-spinkit";


const EditBanner = ({ editId, onChange = () => { }, hasErr }) => {

  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    kind: 1,
    active: false
  });
  const fetch = async () => {
    try {
      let garage = await BannerService.get(editId);
      setData(garage);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData({
      kind: 1,
      active: false
    })
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, BannerService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  return (
    <React.Fragment>
      {loading ?

        <ThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="mt-3 mb-3">

            <InputField
              placeholder={t("Banner Name ")}
              value={data?.name}
              required={true}
              label={t("Banner Name")}
              onChange={(v) => setData({ ...data, name: v })}
              error={data?.name?.length === 0 ? t("Please enter Banner Name") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-12"
            />
          </Row>
          <Col className="d-flex mb-2">
            <Row className="col-sm-6">
              <SelectField

                data={SELECTSTRUCTURE?.kind}
                label="Kind"
                value={data?.kind ? data?.kind : 1}
                onChange={(v) => {
                  setData({ ...data, kind: v.value });
                }}
              />
            </Row>
            <Row className="col-sm-6 ps-5">
              <Checkbox
                label={t("Active")}
                checked={data?.active ? data?.active : false}

                onClick={() => {
                  setData({
                    ...data,
                    active: !data?.active,
                  });
                }}
              />
            </Row>
          </Col>
          <Row>
            <div>
              <div style={{ marginBottom: "0.5rem", }}>{t("Details")}
                {<font color="red">*</font>} </div>
              <textarea className="inputfield" rows={5} style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", }}
                value={data?.detail ?? ""}
                onChange={(e) => {
                  setData({ ...data, detail: e.target.value });
                }}
              >
              </textarea>
            </div>
          </Row>
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditBanner);
