import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./DriverConstant";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";

class Service {
  records = [];
  totalRecords = 0;
  STRUCTURE = [];
  page = 1;
  rows = 20;
  filterOption = "";
  downloadFilterOption = "";
  error = "";
  infiniteScroll = true;
  totalPage = 0;

  constructor() {
    makeAutoObservable(this);
  }

  getStructure = async () => {
    this.STRUCTURE = [
      {
        label: "Vehicle Type",
        description: "Select Vehicle Type",
        filterLabel: "Vehicle Type",
        filterName: "vehicleType",
        type: "select",
        data: this.getSelectObject(this.vehicleMap),
      },

      {
        label: "Vendor",
        description: "Select Vendor",
        filterLabel: "Vendor",
        filterName: "vendor_id",
        type: "select",
        data: this.getSelectObject(this.vendorMap),
      },
      {
        label: "Zone",
        description: "Select Zone",
        filterLabel: "Zone",
        filterName: "zone_id",
        type: "select",
        data: this.zoneMap?.map((zone) => ({
          label: zone.name,
          value: zone._id,
        })),
      },
      {
        label: "From Date",
        description: "Select From Date",
        filterLabel: "From Date",
        filterName: "fromTime",
        type: "dateSelect",
        getTimestamp: true,
      },
      {
        label: "Till Date",
        filterLabel: "Till Date",
        description: "Select Till Date",
        filterName: "tillTime",
        type: "dateSelect",
        getTimestamp: true,
      },
      {
        label: "Reg. Number",
        description: "Vehicle Registration Number",
        filterLabel: "Reg. Number",
        filterName: "regNo",
        type: "text",
      },
      {
        label: "Status",
        description: "Is vehicle Active?",
        filterLabel: "Status",
        filterName: "status1",
        type: "select",
        data: [
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
          { label: "Sold", value: "sold" },
        ],
      },
      {
        label: "Status",
        description: "Is Vehicle in Service?",
        filterLabel: "Status",
        filterName: "status2",
        type: "select",
        data: [
          { label: "In Service", value: "inservice" },
          { label: "Assigned", value: "assigned" },
          { label: "Unassigned", value: "unassigned" },
        ],
      },
      {
        label: "GPS",
        description: "Is GPS Attatched?",
        filterLabel: "GPS",
        filterName: "gps",
        type: "select",
        data: [
          { label: "GPS Attached", value: "gspAttached" },
          { label: "GPS Not Attached", value: "gspNotAttached" },
        ],
      },
    ];
  };

  getSelectObject = (obj) => {
    var map = [];
    for (var i in obj) {
      map.push({ label: i, value: obj[i] });
    }
    return map;
  };

  handleToggleVisibleColumn = (column) => {
    let updatedColumns = this.columns.map((col) => {
      if (col.accessor === column.accessor) {
        return { ...col, show: !col.show };
      }
      return col;
    });
    this.columns = updatedColumns;
  };

  fetch = async ({ filterUrl, key }) => {
    console.log(filterUrl);
    if (filterUrl) {
      this.filterOption = filterUrl;
      this.downloadFilterOption = filterUrl;
    } else filterUrl = {};

    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rows, filterUrl)
    );

    if (response?.status === 200) {
      this.records =
        !this.infiniteScroll || key === 2
          ? response.data?.rows
          : this.records.concat(response.data?.rows);
      this.totalRecords = response.data.records;
      this.totalPage = response.data.total;
      console.log("==========", response, this.records);
    }
  };

  get = async function (id) {
    const response = await doGET(ENDPOINTS.get(id));

    if (response?.status === 200) {
      return response.data;
    }
  };

  toggleInfinite = () => {
    this.infiniteScroll = !this.infiniteScroll;
  };

  saveNew = async function (data) {
    const response = await doPOST(ENDPOINTS.saveNew, data);
    return response?.data?._id;
  };

  save = async (data) => {
    const response = await doPOST(ENDPOINTS.save, data);
    return response?.data?._id;
  };

  edit = async function (data, id) {
    console.log(data);
    const response = await doPUT(ENDPOINTS?.edit(id), data);
  };

  picUpload = async function (data) {
    await doPOST(ENDPOINTS.picUpload, data);
    this.fetch();
  };
  bulkDelete = async (data) => {
    const response = await doPOST(ENDPOINTS.bulkdelete, data);
    // VehicleSelectService.fetch();
    this.fetch({});

    return response;
  };

  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      // VehicleSelectService.fetch();
      this.fetch({ key: 2 });
    }
  };

  picDelete = async function (id, time) {
    const response = await doGET(ENDPOINTS.picDelete(id, time));
    if (response.status === 200) {
    }
  };

  assignDriver = async (vehicleID, driverID) => {
    const response = await doGET(ENDPOINTS.assignDriver(vehicleID, driverID));
  };

  changeProfilePic = async function (id, time) {
    const response = await doGET(ENDPOINTS.changeProfilePic(id, time));
    if (response.status === 200) {
    }
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch();
  };

  setDownloadFilter = (filterData) => {
    if (filterData) this.downloadFilterOption = filterData;
    else this.downloadFilterOption = "";
  };

  downloadByTemplate = async (filterData, template_id) => {
    const response = await doGET(
      ENDPOINTS.download(this.downloadFilterOption, template_id)
    );

    if (response.status === 200) {
      console.log(response);
      return response;
    }
  };
}

const DriverService = new Service();
export default DriverService;
