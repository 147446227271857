
import { Circle } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { FaPaperclip } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
import Skeleton from 'react-loading-skeleton';
import IoSend from '../../assets/images/icons/IoSend.svg';
import { Documents, ModalComponent } from '../../components';
import { DialogContext } from '../../store/context/DialogContext';
import { useModal } from '../../store/context/ModalContext';
import { doGET, doPOST } from '../../util/HttpUtil';
import './Comment.scss';
import { ENDPOINTS } from './CommentConstant';
import CommentItem from './CommentItem';
import CommentService from './CommentService';


const Comment = ({ module, module_id,hideBack}) => {
    const recordType = module ??  CommentService.recordType;
    const recordOf = module_id ??  CommentService.recordOf;
    const {showError} = useContext(DialogContext);
    const { isOpen, toggleModal } = useModal();
    const [comment, setComment] = useState('');
    const [itemsList, setItemsList] = useState([]);
    const [filterType, setFilterType] = useState('all'); 
    const [loading,setloading]=useState(false)
    const [modal, setModal] = useState(false);
    const toggleModalPost = () => setModal(!modal);
    useEffect(() => {
        fetchItems();
    }, [recordType, recordOf]);


    const fetchItems = async () => {
        setloading(true)
        if (recordType && recordOf) {
            try {
                const commentsResponse = await doGET(ENDPOINTS.grid(recordType,recordOf));

                const combinedList = [
                    ...commentsResponse.data.rows.map(comment => ({ ...comment, itemType: 'comment', time: comment?.at })),
                ];
                combinedList.sort((a, b) => a.time - b.time);

                setItemsList(combinedList);
            } catch (error) {
                showError(error)
            }
            finally{
                setloading(false)
            }
        }
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const saveComment = async () => {
        if(loading) return
        if(!comment) {
            showError("Please enter comment")
            return
        }
        setloading(true)
        try {
            const response = await doPOST('/api/comment/create', {
                recordType,
                recordOf,
                byType : 5,
                content: {
                    remark: comment,
                },
            });
            if (response?.status == 200) {
                return response?.data?._id
            }
        } catch (error) {
            showError(error)
        }
        finally{
            setloading(false)
        }

    }

    const postComment = async () => {
        if(loading) return;
        setloading(true)
        try {
            await saveComment()
            setComment('');
            fetchItems();
        } catch (error) {

            console.error('Error posting comment:', error);
        }
        finally{
            setloading(false)
        }
    };


    const getCommentTime = (item) => {
        const itemDate = moment(item.time * 1000);
        const today = moment();
        const yesterday = moment().subtract(1, 'days');

        if (itemDate.isSame(today, 'day')) {
            return "Today";
        } else if (itemDate.isSame(yesterday, 'day')) {
            return "Yesterday";
        } else {
            return itemDate.format("Do MMMM");
        }
    };


    const groupItemsByDay = (items) => {
        const grouped = {};
        items.forEach(item => {
            const day = getCommentTime(item);
            if (!grouped[day]) {
                grouped[day] = [];
            }
            grouped[day].push(item);
        });
        return grouped;
    };

    const filteredItems = itemsList.filter(item => {
        if (filterType === 'all') return true;
        return filterType === 'comment' ? item?.by != "System" : item?.by == "System";
    });


    const groupedItems = groupItemsByDay(filteredItems);


    return (
        <div className="postion-relative comment-component overflow-hidden d-flex flex-column flex-1 h-100 ">
            <label className='mt-3 mx-3 font-size-16'>Comments</label>
            {hideBack ? null :  <div className=" d-flex justify-content-center mb-2" style={{position:"absolute",top:"0px",right:"-5px"}}>
                
                <div className="d-flex justify-content-between align-items-center mb-2 ">
                    
                    <div className='p-2 mt-2 mx-3 cursor-pointer' onClick={()=>{
                        isOpen &&toggleModal();
                        CommentService.toggleCommentModal({recordType:"", recordOf:""})}
                        }><RxCross2 size={20} />
                    </div>
                </div>
            </div> }

            <div className='d-flex flex-column h-100 justify-content-between'>
                <div style={{ borderBottom: "1px solid #EBEBEB", }} className="flex-1 comments-grid">
                    {Object.keys(groupedItems).map(day => (
                        <div className='my-3 overflow-auto' key={day}>
                            <div className='d-flex justify-content-center'>
                                <div className="d-flex align-items-center justify-content-center" style={{ border: "1px solid #c4c2c9", borderRadius: "17px", width: "136px", height: "35px", backgroundColor: '#faf9fe' }}>{day}</div>
                            </div>
                            {loading ? <Skeleton height={60} /> : groupedItems[day].map(comment => (
                                <CommentItem key={comment._id} comment={comment} />
                            ))}
                        </div>
                    ))}
                </div>

                <div className='d-flex align-items-center py-2 bg-white position-relative'>
                    <textarea
                        type="text"
                        value={comment}
                        onChange={handleCommentChange}
                        placeholder="Write a comment..."
                        className=" my-0 ms-2 inputfield flex-1"
                        style={{ width: "100%", borderRadius: "14px", outline: "none", border: "1px solid #faf9fe", backgroundColor: '#faf9fe', height: "65px" }}
                        rows="2"
                    />

                    <div className='d-flex ps-2 align-items-center position-absolute' style={{ right: "20px", top: "50%", transform: "translateY(-50%)" }}>
                        {loading ? <Circle /> : <><img src={IoSend} onClick={postComment} style={{ cursor: "pointer", height: "20px" }} />
                            {/* <FaPaperclip onClick={toggleModalPost} style={{ cursor: "pointer", marginLeft: '10px', fontSize: '20px' }} />  */}
                            </>
                            }

                    </div>
                </div>
            </div>

            <ModalComponent centered isOpen={modal} onToggle={toggleModalPost} size='small' style={{zIndex:"99999"}}>
                <ModalComponent.Title onToggle={toggleModalPost}>Post a comment</ModalComponent.Title>
                <ModalComponent.Body>
                <div className='d-flex flex-column mt-5' style={{height:"300px"}}>
                    <textarea
                        type="text"
                        value={comment}
                        onChange={handleCommentChange}
                        placeholder="Write a comment..."
                        className=" my-0 pt-2 inputfield flex-1 mt-3"
                        style={{ width: "100%", borderRadius: "14px", outline: "none", border: "1px solid #C6F3FF", backgroundColor: '#faf9fe', height: "65px" }}
                        rows="2"
                    />

                    <Documents
                    className={"mt-5"}
                        module="comment"
                        config={{
                            showCategory: false,
                            showExpiry: false,
                        }}
                        getRecordId={saveComment}
                        onChange={() => {
                            setComment('');
                            setModal(!modal)
                            fetchItems()
                        }}
                    />
                    </div>

                </ModalComponent.Body>
            </ModalComponent>
        </div>
    );
};

export default observer(Comment);
