import React, { useEffect, useState } from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import CountUp from 'react-countup';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import DashboardService from '../../../pages/Dashboard/DashboardService';
import { TbHexagon3D } from 'react-icons/tb';

export const CustomerCard = ({ }) => {
  const [customerCount, setCustomerCount] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchCustomers = async (currentDay) => {
    if (loading) return;
    setLoading(true);
    try {
      let body = {
        btwFilters: {
          date: [currentDay, currentDay]
        },
        existsFilter: {
          customer_id: true
        },
        groupBy: {
          date: "date",
        }
      }
      const response = await DashboardService.fetchLoggedInCustomers(body);
      const customersLoggedIn = response
        .reduce((acc, item) => acc + item.count, 0);
      setCustomerCount(customersLoggedIn)
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const currentDay = parseInt(moment().format('YYYYMMDD'));
    // const lastActiveTime = parseInt(moment().subtract(15, 'minutes').unix());
    fetchCustomers(currentDay);
  }, [])

  return (
    <React.Fragment>
      <Col md={6} xl={3}>
        <Card style={{ height: "116px" }}>
          <CardBody>
            <div className='d-flex flex-column h-100'>
              <h2 className="d-flex justify-content-center mb-1 mt-1 flex-1">
                <span>
                  <CountUp
                    end={customerCount}
                    separator=","
                    prefix=""
                    suffix=""
                    decimals={0}
                  />
                </span>
              </h2>
              <h3 className="text-center text-muted mb-0"><b>Customers Logged In</b></h3>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
};

