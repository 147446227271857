import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import Card from 'react-bootstrap/Card';
import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Col } from "reactstrap";
import { Checkbox, CurrencySelect, CompanySelect, InputField, SelectField, AddressField, PhoneNum } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "./Payment_GatewayConstant";
import Payment_GatewayService from "./Payment_GatewayService";

const EditPayment_Gateways = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    currency: "INR",
  });

  const fetch = async () => {
    try {
      if (editId) {
        let payment_Gateway = await Payment_GatewayService.get(editId);
        setData(payment_Gateway);
      }
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(null)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, Payment_GatewayService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        < Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="m-3">
            <Col className="col-10 "></Col>
            <Col className="col-2 ">
              <Checkbox
                label={t("Active")}
                className="col-2 my-3 align-content-end"
                checked={data?.active ?? false}
                onClick={() => { setData({ ...data, "active": !data?.active }) }}
              />
            </Col>
          </Row>
          <Card className="p-3">
            <Row className="mt-3 mb-3">
              <Col className="col-md-6 col-12">
                <InputField
                  required={true}
                  label={t("Holder's Name")}
                  type='text'
                  value={data?.bankAccount?.holderName || ''}
                  onChange={(e) => { setData({ ...data, "bankAccount": { ...data?.bankAccount, "holderName": e } }) }}
                  placeholder={t("Holder's Name")}
                />
              </Col>
              <Col className="col-md-6 col-12">
                <InputField
                  required={true}
                  label={t("Bank Name")}
                  type='text'
                  value={data?.bankAccount?.bankName}
                  onChange={(e) => { setData({ ...data, "bankAccount": { ...data?.bankAccount, "bankName": e } }) }}
                  placeholder={t("Bank Name")}
                />
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col className="col-md-6 col-12">
                <InputField
                  required={true}
                  label={t("Account No")}
                  type='Number'
                  value={data?.bankAccount?.accountNo}
                  onChange={(e) => { setData({ ...data, "bankAccount": { ...data?.bankAccount, "accountNo": e } }) }}
                  placeholder={t("Account No")}
                />
              </Col>
              <Col className="col-md-6 col-12">
                <InputField
                  required={true}
                  label={t("IFSC CODE")}
                  type='text'
                  value={data?.bankAccount?.ifsc}
                  onChange={(e) => { setData({ ...data, "bankAccount": { ...data?.bankAccount, "ifsc": e } }) }}
                  placeholder={t("IFSC CODE")}
                />
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col className="col-md-6 col-12">
                <InputField
                  required={true}
                  label={t("Email")}
                  type='email'
                  value={data?.bankAccount?.holderEmail}
                  onChange={(e) => { setData({ ...data, "bankAccount": { ...data?.bankAccount, "holderEmail": e } }) }}
                  placeholder={t("Email Address")}
                />
              </Col>
              <Col className="col-md-6 col-12">
                <PhoneNum
                  placeholder={t("Phone No ")}
                  value={{
                    countryCode: data?.bankAccount?.holderPhone?.countryCode,
                    phone: data?.bankAccount?.holderPhone?.phone,
                  }}
                  label={t("Phone No.")}
                  error={data?.bankAccount?.holderPhone && data?.bankAccount?.holderPhone?.phone === 0 ? t("Please enter Phone Number") : ""}
                  showErr={hasErr}
                  onChange={(e) => {
                    setData({
                      ...data, "bankAccount": {
                        ...data?.bankAccount, "holderPhone": {
                          ...data?.bankAccount?.holderPhone,
                          countryCode: e?.countryCode,
                          phone: e?.phone,
                        }
                      }
                    })
                  }}
                  type="text"
                />
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col className="col-md-6 col-12">
                <InputField
                  required={true}
                  label={t("Swift Code")}
                  type='text'
                  value={data?.bankAccount?.swiftCode}
                  onChange={(e) => { setData({ ...data, "bankAccount": { ...data?.bankAccount, "swiftCode": e } }) }}
                  placeholder={t("Swift Code")}
                />
              </Col>
              <Col className="col-md-6 col-12">
                <AddressField
                  placeholder={t("Address")}
                  label={t("Address")}
                  value={data?.bankAccount?.address}
                  onChange={(e) => { setData({ ...data, "bankAccount": { ...data?.bankAccount, "address": e } }) }}
                  error={data?.address?.length === 0 ? t("Please enter address") : ""}
                  showErr={hasErr}
                />
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col className="col-md-6 col-12">
                <CurrencySelect
                  required={true}
                  placeholder={t("Select Currency")}
                  value={data?.currency}
                  label={t("Currency")}
                  onChange={(v) => setData({ ...data, currency: v.value })}
                />
              </Col>
              <Col className="col-md-6 col-12">
                <InputField
                  required={true}
                  label={t("Pin Code")}
                  type='Number'
                  value={data?.bankAccount?.address?.pinCode}
                  onChange={(e) => { setData({ ...data, "bankAccount": { ...data?.bankAccount, "address": { ...data?.bankAccount?.address, "pinCode": e } } }) }}
                  placeholder={t("Enter Pin Code")}
                />
              </Col>
            </Row>
          </Card>
        </Form>
      }
    </React.Fragment >
  );
};

export default observer(EditPayment_Gateways);
