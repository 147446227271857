import React from 'react';

const QuillToolbar = () => (
    <div style={{border : "none"}} id="quill-toolbar">
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
        <select className="ql-header custom-select" defaultValue="">
            <option value="1"></option>
            <option value="2"></option>
            <option value=""></option>
        </select>
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
        <button className="ql-indent" value="-1"></button>
        <button className="ql-indent" value="+1"></button>
        <button className="ql-align" value=""></button>
        <button className="ql-align" value="center"></button>
        <button className="ql-align" value="right"></button>
        <button className="ql-align" value="justify"></button>
        <button className="ql-clean"></button>
    </div>
);

export default QuillToolbar;
