import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { FaCarAlt } from "react-icons/fa";
import MAPComponent from "../Map/Map";
import FlyoutButton from "./components/FlyoutButton";
import { FaRoute } from "react-icons/fa";
import VehicleModule from "./modules/VehicleModule";
import TripModule from "./modules/TripModule";
import SideBarService from "./SideBarService";
import { toJS } from "mobx";
import { RxCross2 } from "react-icons/rx";
import socketInstance from "../../service/socket";

const SideMap = () => {

    const { module } = SideBarService;

    return (
        <div className="bird-eye-container App">
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <FlyoutButton
                        onClick={() => {
                            let socket = socketInstance.getSocket();
                            if (SideBarService?.socketTripId) {
                                socket?.emit('leaveRoom', 'trip-' + SideBarService?.socketTripId, (response) => {
                                    console.log("trip room leave response:", response);
                                    SideBarService?.setSocketTripId(null)
                                    SideBarService?.setTripId(null)
                                });
                            }
                            SideBarService?.setModule("vehicle")
                        }}
                        text="Vehicles"
                        icon={<FaCarAlt className="me-2 font-size-14" />}
                        className="m-2"
                        active={module == "vehicle"}
                    />
                    <FlyoutButton
                        onClick={() => {
                            let socket = socketInstance.getSocket();
                            const socketVehicleIds = SideBarService?.socketVehicleIds
                            if (socketVehicleIds?.length) {
                                socketVehicleIds.forEach(vehicleId => {
                                    socket?.emit('leaveRoom', 'vehicle-' + vehicleId, (response) => {
                                        console.log("vehicle room leave response for :",vehicleId, response);
                                        // Remove the current vehicleId from socketVehicleIds
                                        SideBarService.setSocketVehicleIds(SideBarService.socketVehicleIds.filter(id => id !== vehicleId));
                                    });
                                });
                            }
                            SideBarService?.setModule("trip")
                        }}
                        text="Trip"
                        icon={<FaRoute className="me-2 font-size-14" />}
                        className="m-2"
                        active={module == "trip"}
                    />
                </div>
                <div style={{
                    cursor: "pointer"
                }} onClick={() => {
                    SideBarService?.toggleMap()
                }}><RxCross2 size={20} />
                </div>
            </div>

            <div className="flyout-container-vehicle ">
                {module == "vehicle" ? <VehicleModule /> : module == "trip" ? <TripModule /> : null}
            </div>

            <MAPComponent
                markers={toJS(SideBarService?.markers)}
                polyLinePath={toJS(SideBarService?.path) ?? []}
                centerLatLng={toJS(SideBarService?.center) ?? {}}
                selectedMapMarker={toJS(SideBarService?.selectedMarker)}
                clustering
                mapContainerStyle={{ height: "calc(100vh - 170px)" }}
            />

        </div>
    );
};

export default observer(SideMap);
