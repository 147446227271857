import React from 'react';
import './ToggleCardSelection.css';

function ToggleCardSelection({ data, label, value, onChange, isDisabled }) {
    return (
        <>
            <div className='zr_space_fw-bold zr_space_mb-1 zr_space_font-size-14'>{label}</div>
            <div className="zr_space_toggle-card-container">
                {data.map((d, i) => (
                    <div
                        key={i}
                        className={`zr_space_toggle-card ${d.value == value ? 'zr_space_active' : ''} ${isDisabled ? 'zr_space_disabled' : ''}`}
                        onClick={() => !isDisabled && onChange(d.value)}
                    >
                        {d.name}
                    </div>
                ))}
            </div>
        </>
    );
}

export default ToggleCardSelection;
