
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { ENDPOINTS } from '../../../common/utils/Constants';
import { EditLink, InputField } from "../../../components";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { doPOST } from '../../../util/HttpUtil';
import OtpInput from '../../Authentication/OtpInput';
import ProfileService from "../ProfileService";

function ChangeEmail({ data, setData, profile, hasErr, fetchData, supervisorId }) {
    const [imageURL, setImageUrl] = useState();
    const [step, setStep] = useState(0);
    const [captcha, setCapcha] = useState()

    const [emaildata, setemaildata] = useState({});
    const [loading, setLoading] = useState(false);
    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const fetch = async () => {
        try {
            let tag = await ProfileService.getProfileDetail(supervisorId);
            setData(tag);
            setemaildata({
                ...emaildata, email: tag.email
            })
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        setData(null)
        setLoading(true);
        if (supervisorId) fetch();
        setLoading(false)
    }, [supervisorId]);

    const getCaptcha = async () => {
        const dta = {
            for: "captcha",
        };
        try {
            let res = await doPOST(ENDPOINTS.LoginGetCaptcha, dta);
            setImageUrl(res.data.image);
            setemaildata({
                ...emaildata,
                captchaOTP_Id: res.data.captcha_id, otpId: null, otp: null
            })

        } catch (error) {
            showError(error);
        }
    };
    const sendChangedEmail = async () => {

        try {
            let res = await doPOST(ENDPOINTS.changeEmail, emaildata);
            showMessage("Email updated Successfully");

            setStep(3);
        } catch (error) {
            showError(error);
        }
    };
    const newdata = {
        ...emaildata,
        platform: "web",
    };

    const handleClick = () => {

        getCaptcha();
        // setemaildata({
        //     ...emaildata,
        //     otpId: null,
        //     otp: null
        // });
        setStep(1);
    };

    useEffect(() => {
        console.log(emaildata, '-');
    }, [emaildata])



    const gettoken = async () => {
        try {
            const response = await doPOST("/auth/token", newdata)
            if (response.status === 200) {
                setemaildata({
                    ...emaildata, otpId: response.data?.email
                })
                setStep(2)
            } else {
                showError("Incorrect OTP Entered");

            }
        } catch (error) {
            showError(error);
        }
    };

    return (
        <>
            <Card style={{ maxWidth: '800px' }} className="shadow-none " >
                <CardBody className="p-4">
                    <Row>
                        {/* <Form className="px-5" style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}> */}

                        <Row className="mt-3 mb-3  align-items-center ">
                            {(step === 0 || step === 1 || step === 2) && (
                                <div className="d-flex align-items-center justify-content-start ">
                                    <InputField
                                        style={{ minWidth: '120px' }}
                                        className="col-sm-6 col-12"
                                        outline
                                        isDisabled={step === 0}
                                        label={"Email"}
                                        placeholder="abc@mail.com"
                                        type="email"
                                        showErr={true}
                                        basic
                                        value={
                                            emaildata?.email
                                        }
                                        onChange={(v) => {
                                            setemaildata({ ...emaildata, email: v });

                                        }}
                                        required
                                        error={
                                            emaildata?.email?.length === 0
                                                ? "Please enter Email"
                                                : ""
                                        }
                                    />
                                    <div>
                                        {step === 0 && <EditLink className="ms-2"
                                            onClick={() => { handleClick() }} />}
                                    </div>

                                </div>
                            )}
                        </Row>
                        <Row>
                            {(step === 1) && (
                                <div>
                                    <div className="d-flex flex-sm-row flex-column justify-content-start align-items-center">
                                        <div
                                            style={{ border: "1px solid #ced4da", borderRadius: '0.5rem', minWidth: '180px' }}
                                            className="col-sm-6 my-3 col-12 d-flex "
                                        >
                                            {imageURL && (

                                                <img
                                                    src={`data:image/jpeg;base64,${imageURL}`}
                                                    alt=""
                                                    height={32}
                                                    style={{ margin: 'auto' }}
                                                />
                                            )}
                                            <div className=" ">
                                                <i
                                                    id="captcha"
                                                    onClick={() => getCaptcha()}
                                                    style={{
                                                        fontSize: "25px",
                                                        cursor: "pointer",
                                                    }}
                                                    className="uil-redo rounded-right pe-2"
                                                />
                                            </div>
                                        </div>
                                        <div className="ms-4 col-3" style={{ marginRight: '30px', minWidth: '180px' }}>
                                            <OtpInput
                                                value={emaildata?.captcha}
                                                onChange={(v) => {
                                                    setemaildata({ ...emaildata, captcha: v });


                                                }}
                                                error={
                                                    emaildata?.captcha?.length === 0
                                                        ? "Please enter Captcha"
                                                        : ""
                                                }
                                                className="flex-1 mb-1"
                                            />
                                        </div>
                                    </div>
                                    <Button
                                        onClick={gettoken}
                                        className="mt-lg-0 mt-4"
                                        style={{ padding: "2px 4px", borderRadius: '30px' }}
                                        color="success"
                                    >
                                        <div
                                            style={{ margin: "2px", float: "right" }}
                                        >Send OTP</div>
                                    </Button>
                                </div>
                            )}

                            {step === 2 && (
                                <div >
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div style={{ minWidth: '180px' }} className="col-4">
                                            <OtpInput
                                                label="Email OTP"
                                                value={emaildata?.otp}
                                                onChange={(v) => {
                                                    setemaildata({ ...emaildata, otp: v })
                                                }}

                                                error={
                                                    emaildata?.otp?.length === 0
                                                        ? "please enter the email otp"
                                                        : ""
                                                }
                                                className=" "
                                            />
                                        </div>

                                        <Button
                                            onClick={() => { handleClick() }}
                                            className="mx-2"
                                            style={{ padding: "2px 4px", position: "relative", top: "10px", borderRadius: '30px' }}
                                            color="success"
                                        >
                                            <div
                                                style={{ margin: "4px", float: "right" }}
                                            >Resend OTP</div>
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() => {

                                            }}
                                            className="mx-2"
                                            style={{ padding: "2px 4px", position: "relative", top: "20px", right: "4px", borderRadius: '30px' }}
                                            color="success"
                                        >
                                            <div
                                                style={{ margin: "4px", float: "right" }}
                                                onClick={() => sendChangedEmail()}
                                            >Save Email</div>
                                        </Button>

                                    </div>

                                </div>

                            )}


                            {(step === 3) && (
                                <div className="d-flex align-items-center justify-content-start">
                                    <InputField
                                        isDisabled

                                        label={"Email"}
                                        placeholder="abc@mail.com"
                                        type="email"
                                        showErr={true}
                                        basic
                                        value={
                                            emaildata?.email
                                        }

                                        required
                                        error={
                                            emaildata?.email?.length === 0
                                                ? "Please enter Email"
                                                : ""
                                        }
                                    />
                                </div>
                            )}
                        </Row>
                        {/* </Form> */}

                        <Col>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    );
}

export default ChangeEmail;





