import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";
import {
  BackArrow,
  CardThreeBounce,
  CrossButton,
  SaveButton,
  scrollStyle,
} from "../../../../components";

import PaymentGatewayService from "./PaymentGatewayService";

import PaymentOptions from "./Tabs/PaymentOptions";
import Details from "./Tabs/Details";
import PaymentDetails from "./Tabs/PaymentDetails";

import BankDetails from "./Tabs/BankDetails";
import BankAddress from "./Tabs/BankAddress";
import { DialogContext } from "../../../../store/context/DialogContext";
import Tab from "../../../../components/Common/Tab/Tab";

const PaymentGateway = (props) => {
  const { data, setData, hasErr, setHasErr, id } = props;
  const [freshData, setFreshData] = useState({});
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const navigate = useNavigate();

  async function fetch() {
    let transfergroupD = {};
    if (id) transfergroupD = await PaymentGatewayService.get(id);
    setFreshData(transfergroupD);
    setData(transfergroupD);
    setHasErr(false);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    try {
      fetch();
      setHasErr(false);
    } catch (error) {
      showError(error);
    }
  }, [id]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(freshData)) {
      props?.onChange(true);
    } else {
      props?.onChange(false);
    }
  }, [data]);

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      <div>
        <Form className="p-4">
          <Tab
            vertical
            left
            activeTab={step}
            onChange={(data) => {
              setStep(data);
            }}
            className="d-flex"
          >
            <div num={1} label="Payment Gateway Details">
              <PaymentDetails
                className="bg-primary color-fff"
                data={data}
                setData={setData}
              />
            </div>

            <div num={2} label="Payment Gateway Options">
              <PaymentOptions
                className="bg-primary color-fff"
                data={data}
                setData={setData}
              />
            </div>

            <div num={3} label={"Details"}>
              <Details
                className="bg-primary color-fff"
                data={data}
                setData={setData}
                kind={data?.pgKind}
              />
            </div>

            <div num={4} label="Bank Details">
              <BankDetails
                className="bg-primary color-fff"
                data={data}
                setData={setData}
              />
            </div>

            <div num={5} label="Bank Adress">
              <BankAddress
                className="bg-primary color-fff"
                data={data}
                setData={setData}
              />
            </div>
          </Tab>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default observer(PaymentGateway);
