import React, { useContext, useState } from "react";
import "./style.css";
import { Button, Col, Row } from "reactstrap";
import { SelectField } from "../../../../components";
import coustomerServiceIcon from "../../../../assets/images/icons/customer_service.png";
import { UserContext } from "../../../../store/context/UserContext";

export default function MeetScreen({
  endCall,
  user,
  selectedAudioOutputDevice,
  onChange,
  selectedAudioInputDevice,
  askToShareScreen,
  userVideo,
  audioInputDeviceList,
  audioOutputDeviceList,
  toggleMic,
  micStatus,
  setSettingStatus,
  settingStatus,
}) {
  const { supportCallScreenShareStatus } = useContext(UserContext);
  const [a, setA] = useState(selectedAudioInputDevice);
  const [b, setB] = useState(selectedAudioOutputDevice);
  return (
    <>
      <div className="page-content">
        <Row>
          <Col className={"px-0 meet-screen"}>
            <div className="meet-screen-top-bar">
              <div style={{ fontSize: "25px", color: "whitesmoke" }}>
                {user}
              </div>
            </div>
            <div className="meet-screen-main">
              <div
                className="meet-screen-main-blank"
                style={{ opacity: supportCallScreenShareStatus === 'screen-share-started' ? 0 : 1 }}
              >
                <img
                  width={50}
                  height={50}
                  src={coustomerServiceIcon}
                  alt="user"
                />
              </div>
              <video
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#383850",
                }}
                autoPlay
                ref={userVideo}
              />
            </div>
            <div className="px-1 meet-screen-btns">
              <div className="mx-2 d-block">
                <button
                  type="button"
                  className=" btn-sidebar noti-icon waves-effect p-1 px-2"
                  onClick={toggleMic}
                  data-toggle="fullscreen"
                >
                  <i
                    className={
                      "text-icon mdi mdi-microphone" + (micStatus ? "" : "-off")
                    }
                  ></i>
                </button>
              </div>
              <div className="mx-2 d-block">
                <button
                  type="button"
                  className=" btn-sidebar noti-icon waves-effect p-1 px-2"
                  onClick={endCall}
                  data-toggle="fullscreen"
                >
                  <i
                    style={{ color: "red" }}
                    className={"text-icon mdi mdi-phone-hangup"}
                  ></i>
                </button>
              </div>
              <div className="mx-2 d-block">
                <button
                  type="button"
                  className=" btn-sidebar noti-icon waves-effect p-1 px-2"
                  onClick={askToShareScreen}
                  disabled={supportCallScreenShareStatus === 'asking' || supportCallScreenShareStatus === 'screen-share-started'}
                  data-toggle="fullscreen"
                >
                  <i
                    className={
                      "text-icon mdi mdi-cast" +
                      (supportCallScreenShareStatus === 'screen-share-started' ? "" : "-off")
                    }
                  ></i>
                </button>
              </div>
              <div className="mx-2 d-block">
                <button
                  type="button"
                  className=" btn-sidebar noti-icon waves-effect p-1 px-2"
                  onClick={() => setSettingStatus(!settingStatus)}
                  data-toggle="fullscreen"
                  style={{
                    backgroundColor: "unset",
                    position: "absolute",
                    right: "30px",
                    top: "12px",
                  }}
                >
                  <i
                    style={{ color: "white" }}
                    className={"mx-3 text-icon mdi mdi-cog"}
                  ></i>
                </button>
              </div>
            </div>
            {settingStatus && (
              <div className="meet-screen-settings">
                <div>
                  <p>Microphone</p>
                  <SelectField
                    label={"Microphone"}
                    value={a}
                    style={{ width: "350px" }}
                    onChange={(v) => setA(v.value)}
                    data={audioInputDeviceList ? audioInputDeviceList.map((aid, i) => {
                      return { value: i, label: aid.label };
                    }) : []}
                  />
                </div>
                <div>
                  <p>Speakers</p>
                  <SelectField
                    label={"Speaker"}
                    value={b}
                    style={{ width: "350px" }}
                    onChange={(v) => setB(v.value)}
                    data={audioOutputDeviceList ? audioOutputDeviceList.map((aod, i) => {
                      return { value: i, label: aod.label };
                    }) : []}
                  />
                </div>
                <Button onClick={() => onChange(a, b)} className="btn-primary">
                  Change
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
