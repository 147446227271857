import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const VersionUpdatePopup = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} >
        <div className='fs-5'> Update Available</div>
        </ModalHeader>
      <ModalBody className='fs-5'>
        A new version of the application is available. Please refresh the page to update.
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => window.location.reload(true)}>
          Refresh
        </Button>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default VersionUpdatePopup