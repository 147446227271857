import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./PassengerFieldConstant";
import { doGET, doPOST } from "../../util/HttpUtil";
class Service {

    records = [];
    STRUCTURE = [];
    page = 1;
    rowsPerPage = null;
    filterOption = "";
    total = -1;
    version = 0
    totalRecords = 0;
    rows = 10;

    constructor() {
        makeAutoObservable(this);
    }

    customerGrid = async (filterUrl) => {
        const response = await doGET(ENDPOINTS.customerGrid(filterUrl));
        if (response.status === 200) {
            this.version = 0
            return response.data ? response.data : response;
        }
    };

    getCustomer = async (id) => {
        const response = await doGET(ENDPOINTS.getCustomer(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    save = async (data) => {
        const response = await doPOST(ENDPOINTS.createCustomer, data);
        return response?.data?._id;
    };
}

const PassengerFieldService = new Service();
export default PassengerFieldService;
