export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/status-master/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/status-master/${id}/detail`,
  save: `/api/status-master/create`,
  delete: (id) => `/api/status-master/${id}/delete`,
  bulkDelete: (id) => `/api/status-master/delete`,
  update: `/api/status-master/update`,
};

export const SELECTSTRUCTURE = {
  corpType: [
    { label: "Client", value: "Client" },
    { label: "Transporter", value: "Transporter" },   
  ],
};



export const STRUCTURE = [
  {
    label: "Module",
    filterLabel: "Module",
    filterName: "module",
    type: "text",
  },
  {
    label: "Name",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Name",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Value",
    filterLabel: "Value",
    filterName: "value",
    type: "text",
  },
  
];
