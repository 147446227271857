import React, { useEffect, useState } from 'react';
import { FcStatistics } from "react-icons/fc";
import Skeleton from 'react-loading-skeleton';
import { convertMinutesToHumanReadable } from '../../util/Util';

const DetailDurationCard = ({ loading, tripData }) => {

    const [travelStats, setTravelStats]= useState({
        totalDistance:0,
        totalDuration:0
      })

    const calculateTotalDistanceAndDuration = (data) => {
        let totalDuration = 0;
        let totalDistance = 0;
      
        data?.forEach(item => {
          totalDuration += item.totalDuration;
          totalDistance += item.totalDistance;
        });
        setTravelStats({ totalDuration, totalDistance })
      };
    


    useEffect(() => {
        calculateTotalDistanceAndDuration(tripData)
    }, [tripData])
    

    return (
        <>
            <span className='fw-bold font-size-16'>
                Trip Statistics <FcStatistics size={20} />
            </span>
            <hr />
        <div className="chart-container d-flex flex-column w-100">
            <div className='d-flex flex-column gap-2 justify-content-between align-items-between'>
            <div className='font-size-16'>
                    <span className='px-1 text-crm-blue-greyish font-weight-400'>
                        Total Trips:
                    </span>
                    {loading ? <Skeleton/> :<span className='fw-bold'>{tripData?.length}</span>} 
                </div>
                <div className='font-size-16'>
                    <span className='px-1 text-crm-blue-greyish font-weight-400'>
                        Distance Travelled:
                    </span>
                    {loading ? <Skeleton/> :<span className='fw-bold'> {travelStats?.totalDistance?.toLocaleString('en-US')} km </span>}
                </div>
                <div className='font-size-16 '>
                    <span className='px-1 text-crm-blue-greyish font-weight-400'>
                        Duration Travelled:
                    </span>

                    {loading ? <Skeleton/>:<span className='fw-bold'> {convertMinutesToHumanReadable(travelStats?.totalDuration)}</span>} 
                </div>

            </div>
        </div>
        </>
    );
};

export default DetailDurationCard;
