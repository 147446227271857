import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import CustomerLabel from '../../../components/Dropdowns/CustomerSelect/CustomerLabel';
import { DialogContext } from '../../../store/context/DialogContext';
import CustomerDetails from '../components/customerDetails/CustomerDetails';
import './SMS.css';
import SMSChat from './SMSChat';
import SMSService from './SMSService';

const SMSList = observer(() => {
  const { SMSs, setSelectedSMS, selectedChat } = SMSService;
  const { showError } = useContext(DialogContext);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        await SMSService.fetchChats();
      } catch (error) {
        showError(error);
      }
    };

    fetchChats();
  }, []);

  return (
    <div className="zr_crm_sms_chat-list">
      {SMSs?.map((sms) => (
        <div
          key={sms.customer_id}
          className={`zr_crm_sms_chat-item ${selectedChat === sms.customer_id ? 'active-class' : ''}`}
          onClick={() => setSelectedSMS(sms.customer_id)}
        >
          <div className="d-flex justify-content-between w-100 align-items-center">
            <div className="zr_crm_sms_chat-name">
              <CustomerLabel value={sms.customer_id} />
            </div>
            <div className="zr_crm_sms_chat-time">{sms?.lastWhatsapp}</div>
          </div>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <div className="zr_crm_sms_chat-message">{sms.lastWhatsappMsg}</div>
          </div>
        </div>
      ))}
    </div>
  );
});

const SMS = () => {
  const { selectedChat } = SMSService;
  return (
    <div className="zr_crm_sms_container">
      <SMSList />
      <div className="zr_crm_sms_chat-container">
        <SMSChat chatId={selectedChat} />
      </div>
      <div className="zr_crm_inbox_customerDetails">
        <CustomerDetails customerId="66a76dc62315af1476de400b" />
      </div>
    </div>
  );
};

export default observer(SMS);
