import React from 'react';

const Card = ({ children }) => {
  return (
    <div className=" bg-white d-flex mt-2 borderRadius-10 pb-3  flex-column">
      {children}
    </div>
  );
}

Card.Header = ({ children }) => {
    return (
      <div className="border-t-lr10 bg-lightest_blue d-flex flex-row font-size-20 p-3  align-items-center ">
        {children}
      </div>
    );
  }

Card.Body = ({ children}) => {
  return (
    <div className="d-flex flex-row justify-content-between w-100 flex-wrap">
      {children}
    </div>
  );
}

export default Card;
