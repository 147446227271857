/* FOR Lead ... CURRENTLY INCOMPLETED */

import { makeAutoObservable, toJS } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../../../pages/Users/UserConstant";


class CustomerSelect {
    customers = [];
    queue = [];
    isFetching = false;
    page = 1;
    rowsPerPage = 10;
    filterOption = "";
    total = -1;

    chatsCache = {};
    fetchQueue = {};
    loading = {};

    constructor() {
        makeAutoObservable(this);
    };

    fetchCustomerNameFromCustomerId = async (customerId) => {
        try {
        if (this.chatsCache[customerId]) {
            return this.chatsCache[customerId];
        }

        if (this.isFetching) {
            return new Promise((resolve, reject) => {
                this.queue.push({resolve,reject,customerId});
            });
            }
            if (!this.customers.length) {
                this.isFetching = true;
                const response = await doGET(`/api/customer/grid?rows=-1&any=y`);
                this.isFetching = false;
                if (response.status === 200) {
                    const chats = response.data.rows.reduce((acc, item) => {
                        acc[item?._id] = { ...item, label: item?.name };
                        return acc;
                    }, {});
                    this.customers = response?.data?.rows ?? []
                    this.chatsCache = { ...this.chatsCache, ...chats };
                } else {
                    throw new Error("Failed to fetch Customers");
                }
            }
            while (this.queue?.length > 0) {
                const { resolve, customerId } = this.queue.shift();
                resolve(this.chatsCache[customerId]);
            }
            console.log("returning=====",this.customers.length)
            return this.chatsCache[customerId] || null;
        } catch (error) {
            throw new Error(error.message);
        } finally {
            delete this.loading[customerId];
        }
    }

    fetchSingle = async (filterUrl) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await doGET(ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl));
                if (response.status === 200) {
                    if (this.page === 1) {
                        this.customers = response?.data?.rows;
                        this.total = response?.data?.total;
                    } else {
                        this.customers = [...this.customers, ...response?.data?.rows];
                    }
                } else {
                    this.error = response.data;
                }
            } catch (err) {
                this.error = err;
            } finally {
                this.isFetching = false;
            }
        });
    };

    fetch = async (filterUrl = "") => {
        if (this.filterOption !== filterUrl) {
            this.page = 1;
            this.total = -1;
        }
        this.filterOption = filterUrl;
        this.isFetching = true;
        this.fetchSingle(filterUrl);
    };

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch(this.filterOption);
    };

    pushNewData = (data) => {
        this.customers.push(data);
    }

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));
        if (response.status === 200) {
            return response.data;
        }
    }
}

const CustomerSelectService = new CustomerSelect();
export default CustomerSelectService;
