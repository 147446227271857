import React from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { InputField } from '../../../components';

const NumberInput = ({ value, onChange, label, min = 0, max = 1000000 }) => {
    return (
        <div className='d-flex w-100 align-items-center justify-content-between'>
            <div>{label}</div>

            <div className="ms-2 d-flex align-items-center">
                <div onClick={() => onChange(Math.max(min, value - 1))} className='btn'>
                    <FaMinus className='h-100' />
                </div>
                <InputField
                    outline
                    className="mx-2 mt-0"
                    style={{ width: "50px" , height : "34px", marginTop : "2px"}}
                    value={value}
                    onChange={(e) => {
                        const newValue = Math.max(min, Math.min(max, parseInt(e, 10)));
                        onChange(isNaN(newValue) ? min : newValue);
                    }}
                />
                <div onClick={() => onChange(Math.min(max, value + 1))}  className='btn'>
                    <FaPlus className='h-100'  />
                </div>
            </div>
        </div>
    );
};

export default NumberInput;
