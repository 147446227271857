import React, { useContext, useEffect, useState } from "react";
import LoginCredentials from "./LoginCredentials";
import LoginOtp from "./LoginOtp";
import { DialogContext } from "../../store/context/DialogContext";
import { doGET, doPOST } from "../../util/HttpUtil";
import { useNavigate, useLocation } from "react-router-dom";
import { ENDPOINTS } from "../../common/utils/Constants";
import Signup from "./Signup";

const Auth = ({ isCpanelLogin }) => {
  const location = useLocation();
  const currentRoute = location.pathname;

  const [data, setData] = useState({
    authType: "otp",
    phone: "",
    language: "en",
    captchaQuery: "",
  });

  const [loading, setLoading] = useState(false);
  const { showError, showMessage } = useContext(DialogContext);
  const [step, setStep] = useState(currentRoute === "/sign-up" ? 2 : 0);

  const [captcha, setCapcha] = useState("");
  const [otp_id, setOtp] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [otpId, setOtpId] = useState("");
  const [webContent, setWebContent] = useState(null)

  const [errorMessage, setErrorMessage] = useState({
    phone: "",
    captchaQuery: "",
  });
  const [imageURL, setImageUrl] = useState();
  const [selecteToggle, setSelectedToggle] = useState(true);

  let navigate = useNavigate();

  const getCaptcha = async () => {
    const dta = {
      for: "captcha",
    };
    try {
      let res = await doPOST(ENDPOINTS.LoginGetCaptcha, dta);
      setImageUrl(res.data.image);
      setCapcha(res.data.captcha_id);
    } catch (error) {
      showError(error);
    }
  };


  function changeFavicon(src) {
    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = src;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  const getWebContent = async () => {
    try {
      const response = await doGET(ENDPOINTS?.getWebContent);
      if (response.status == 200) {
        document.title = response?.data?.name
        changeFavicon(response?.data?.favicon)
        setWebContent(response?.data)
      }
    } catch (error) {

    }
  }


  useEffect(() => {
    getWebContent()
  }, [])

  const getOTP = async () => {
    if (!data?.email && (!data?.phone)) {
      setErrorMessage({
        ...errorMessage,
        email: "Email is required",
        phone: "Phone number is required",

      });
      return;
    }
    if (!data?.captchaQuery) {
      setErrorMessage({
        ...errorMessage,
        captchaQuery: "Captcha is required",
      });
      return;
    }

    const newData = {
      ...(data?.phone && !selecteToggle && { phone: data?.phone }),
      ...(data?.phone && !selecteToggle && { countryCode: data?.countryCode ? data?.countryCode : "91" }),
      ...(data?.email && selecteToggle && { email: data?.email }),
      platform: "web",
      captchaOTP_Id: captcha,
      captcha: data?.captchaQuery,
      //   captchaQuery: ,
      //   captcha_id: captcha,
      //   otp_id: otp_id
    };

    try {
      const res = await doPOST("/auth/token", newData);
      if (res?.status === 200) {
        showMessage("OTP SENT SUCCESSFULLY", "Success");
        setOtpId(data?.email ? res?.data?.email : res?.data?.phone);
        setStep(1);
      }
    } catch (error) {
      getCaptcha()
      setErrorStatus(error);
      setData((prev) => ({
        ...prev,
        captchaQuery: "",
      }));
      try {
        const parsedMessage = JSON.parse(error?.message);
        showError(parsedMessage?.customMessage ?? parsedMessage?.message ?? "Internal Server Error");
      } catch (e) {
        showError(error?.message ?? "Internal Server Error");
      }
    }
  };

  const isInspecting = typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object";

  const verifyOTP = async (otp) => {
    const verifyData = {
      email: data?.email,
      countryCode: data?.countryCode,
      otpValue: data?.otp,
      otpId: otpId,
      userType: "supervisor",
    };
    try {
      const res = await doPOST(ENDPOINTS.login, verifyData);
      if (res?.status === 200) {
        navigate("/dashboard");
        setOtp(res.otp_id);
        return;
      } else {
        showError("Incorrect OTP Entered");
        return;
      }
    } catch (error) {
      showError(error);
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-center row"
        style={{
          margin: "auto",
          height: isInspecting ? "100vh" : "auto",
        }}
      >
        <div className=" col-12 ">
          {step === 0 ? (
            <LoginCredentials
              errorMessage={errorMessage}
              getOTP={getOTP}
              data={data}
              setData={setData}
              webContent={webContent}
              loading={loading}
              isCpanelLogin={isCpanelLogin}
              setLoading={setLoading}
              captcha={captcha}
              setCapcha={setCapcha}
              onClickSignUp={() => navigate("/sign-up")}
              getCaptcha={getCaptcha}
              imageURL={imageURL}
              setSelectedToggle={setSelectedToggle}
              selecteToggle={selecteToggle}
            />
          ) : step === 1 ? (
            <LoginOtp
              errorStatus={errorStatus}
              setErrorStatus={setErrorStatus}
              otpId={otpId}
              webContent={webContent}
              data={data}
              setData={setData}
              setStep={setStep}
              getOTP={getOTP}
              isCpanelLogin={isCpanelLogin}
              captcha={captcha}
              setCapcha={setCapcha}
              loading={loading}
              setLoading={setLoading}
            />
          ) : (
            <Signup
              errorStatus={errorStatus}
              getOTP={getOTP}
              loading={loading}
              setLoading={setLoading}
              captcha={captcha}
              setCapcha={setCapcha}
              onClickLogin={() => navigate("/")}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Auth;
