
import moment from "moment";

export const ConvertIntoUnixTimeStamp = (date) => {//date is in YYYY-MM-DD format
    const formattedDate = moment(date, 'YYYYMMDD');
    return formattedDate.unix();
}

export const formatSecondsToDuration = (totalSeconds) => {
  const days = Math.floor(totalSeconds / (24 * 3600));
  totalSeconds %= 24 * 3600;
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  let result="";
  if(days>0){
    if (days > 1) {
      result+=`${days} days `
    } else {
      result+=`${days} day `
    }
  }
  if(hours>0){
    if (hours > 1) {
      result+=` ${hours} hours `
    } else {
      result+=` ${hours} hour `
    }
  }
  if(minutes>0){
    if (minutes > 1) {
      result+=` ${minutes} minutes `
    } else {
      result+=` ${minutes} minute `
    }
  }
 return result?.trim();
}
 