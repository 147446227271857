export const SELECTSTRUCTURE = {
    monthly: [
        { label: "Jan", value: "Jan" },
        { label: "Feb", value: "Feb" },
        { label: "Mar", value: "Mar" },
        { label: "Apr", value: "Apr" },
        { label: "May", value: "May" },
        { label: "Jun", value: "Jun" },
        { label: "Jul", value: "Jul" },
        { label: "Aug", value: "Aug" },
        { label: "Sep", value: "Sep" },
        { label: "Oct", value: "Oct" },
        { label: "Nov", value: "Nov" },
        { label: "Dec", value: "Dec" },
    ],
    weekly: [
        { label: "Mon", value: "Mon" },
        { label: "Tues", value: "Tues" },
        { label: "Wed", value: "Wed" },
        { label: "Thur", value: "Thur" },
        { label: "Fri", value: "Fri" },
        { label: "Sat", value: "Sat" },
        { label: "Sun", value: "Sun" },
    ],
}

export const ENDPOINTS = {

    getMonthlyInvoice: (isCurrent, month) => {
        if (isCurrent) return `/api/invoice/current-stats/stats`;
        return `/api/invoice/single-month-stats/stats?month=${month}&`;
    },
    getYearlyInvoice: (month) => `/api/invoice/monthly-bar-stats/stats?month=${month}&`,
    // getTripsToday: () => `/api/trip/current-stats/stats`,
    getTripsToday: () => `/api/trip/day-grouped-stats/new/stats?days=1`,
    // getTrips: (date) => `/api/trip/day-grouped-stats/new/stats?fresh=true&date=${date}`,
    getInvoices:`/api/invoice/statistics`,
    getTrips:  `api/trip/statistics`,
    getTripsRevenue:  `api/trip/revenue/statistics`,
    getVehicleStats:  `/api/vehicle/statistics`,
    getRegisteredCustomers:  `/api/customer/statistics`,
    getLoggedInCustomers:  `/api/user-activity/statistics`,
    getSOSStats:  `/api/incident/statistics`,
    getStats:  `/api/stats`,
    // getLast10daysTrip: (X) => `/api/trip/current-stats/stats?days=${X}`,
    getLast10daysTrip: () => `api/trip/day-grouped-stats/new/stats?fresh=false`,
    // getVehicleStats: () => `/api/vehicle/stats?fresh=true`,
    getEmployeeStats:  `/api/employee/statistics`,
    getTripsMonthly: (startDate, endDate) => `/api/trip/monthly-grouped-stats/new/stats?fresh=false&endDate=${endDate}&startDate=${startDate}`,
    // grid: (page, sizePerPage, filterUrl, forSupplier) => {
    //     let url = `/api/rate/grid?withExtn=true&`;
    //     if (sizePerPage) url += `rows=${sizePerPage}&`;
    //     if (filterUrl) url += `${filterUrl}&`;
    //     if (forSupplier) url += `ofSupplier=${forSupplier}&`;
    //     if (page) url += `page=${page}`
    //     return url;
    // },
    // get: (id) => `/api/rate/${id}/detail`,
    // saveNew: `/api/rate/create`,
    // save: `/api/rate/create?forSupplier=true`,
    // delete: (id) => `/api/rate/${id}/delete`,
    // update: `/api/rate/update`,
    // fetchAll: (rateNo, forSupplier) => {
    //     return forSupplier === true ? `/api/rate/grid?rateNo=${rateNo}&withExtn=true&ofSupplier=true` : `/api/rate/grid?rateNo=${rateNo}&withExtn=true`
    // }
};

export const COLOR_MAP = [
    '#AEB6BF', // pending
    '#2980B9', // Upcoming
    '#FFBF00', // Dispatched
    '#52BE80', // Completed
    '#F1948A', // Cancelled
    '#CB4335', // Error
];



export const getColor = (index, label) => {
    const color = COLOR_MAP[index];
    if (!color) return null;
    if (label === "Created") {
        return COLOR_MAP[0];
    } else if (label === "Confirmed") {
        return COLOR_MAP[1];
    } else if (label === "Dispatch") {
        return COLOR_MAP[2];
    } else if (label === "Completed") {
        return COLOR_MAP[3];
    } else if (label === "Cancelled") {
        return COLOR_MAP[4];
    } else if (label === "Error") {
        return COLOR_MAP[5];
    }
};

export const getColorMap = (index, label) => {
    const color = getColor(index, label);
    if (!color) return null;

    return color.replace(/,\s*0\.\d+\)/, ', 1)');
};
