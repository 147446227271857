import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./SubsInvoiceConstant";
import { doGET, doPOST } from "../../../util/HttpUtil";

class Service {
  records = [];
  totalRecords = 0;
  page = 1;
  rows = 10;

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async function (corpId) {
    const response = await doGET(ENDPOINTS.grid(this.page, this.rows, corpId));
    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
      return response.data.rows;
    }
  };

  update = async function (id) {
    const response = await doGET(ENDPOINTS.update(id));
    this.fetch()
  }

  print = async function (data) {
    const response = await doPOST(ENDPOINTS.print, data);
    this.fetch()
  }

  email = async function (id) {
    const response = await doGET(ENDPOINTS.email(id));
    this.fetch()
  }

  download = async function (id) {
    const response = await doGET(ENDPOINTS.download(id));
    this.fetch()
  }

  update = async function (id, st) {
    const response = await doGET(ENDPOINTS.updateInvoice(id, st));
    this.fetch()
  }

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch();
  };
}

const SubsInvoiceService = new Service();
export default SubsInvoiceService;
