export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/o/country/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  get: (id) => `/api/country/${id}/detail`,
  save: `/api/country/create`,
  delete: (id) => `/api/country/${id}/delete`,
  update: `/api/country/update`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Country Name",
    filterName: "name",
    type: "countrySelect",
  },
  // {
  //   label: "Synonyms",
  //   filterLabel: "Synonyms",
  //   filterName: "synonyms",
  //   type: "text",
  // },
  {
    label: "ISD",
    filterLabel: "ISD",
    filterName: "isd",
    type: "text",
  },
  {
    label: "Currency",
    filterLabel: "Currency",
    filterName: "currency",
    type: "currencySelect",
  },
  {
    label: "Iso Code",
    filterLabel: "Iso Code",
    filterName: "isoCode",
    type: "text",
  },
  {
    label: "Time Zone",
    filterLabel: "Time Zone",
    filterName: "timeZone",
    type: "timezoneSelect",
  },
];

