import { CompanySelect, SupplierSelect } from "../../components";

export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Bill No",
            accessor: "billNo",
            show: true,
        },
        {
            title: "Bill Date",
            renderer: (dataObj) => {
                return <div>
                    {(dataObj?.billDate?.toString())?.slice(6, 8)}-{(dataObj?.billDate?.toString())?.slice(4, 6)}-{(dataObj?.billDate?.toString())?.slice(0, 4)}
                </div>;
            },
            accessor: "billDate",
            show: true,
        },
        {
            title: "Due Date",
            renderer: (dataObj) => {
                return <div>
                    {(dataObj?.dueDate?.toString())?.slice(6, 8)}-{(dataObj?.dueDate?.toString())?.slice(4, 6)}-{(dataObj?.dueDate?.toString())?.slice(0, 4)}
                </div>;
            },
            accessor: "dueDate",
            show: true,
        },
        {
            title: "Status",
            accessor: "status",
            renderer: (dataObj) => {
                return <div>
                    {dataObj?.status === 0 ? "Open" : dataObj?.status === 1 ? "Partially Paid" : dataObj?.status === 2 ? "Paid" : null}
                </div>;
            },
            show: true,
        },
        {
            title: "Supplier",
            accessor: "",
            renderer: (dataObj) => {
                return <div>
                    <SupplierSelect
                        asLabel
                        value={dataObj?.supplier_id}
                        onChange={(v) => { }}
                        className=""
                    />
                </div>;
            },
            show: true,
        },
        {
            title: "Company",
            accessor: "",
            renderer: (dataObj) => {
                return <div>
                    <CompanySelect
                        corpType="supplier"
                        corp_id={dataObj?.supplier_id}
                        asLabel
                        value={dataObj?.company_id}
                        onChange={(v) => { }}
                        className=""
                    />
                </div>;
            },
            show: true,
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
