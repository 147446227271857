import { CompanySelect, OfficeSelect } from "../../components";
export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Name",
      accessor: "name",
      show: true,
    },

    // {
    //   title: "Office",
    //   accessor: "",
    //   renderer: (dataObj) => {
    //     return <div>
    //       <OfficeSelect
    //         asLabel
    //         value={dataObj?.office_id}
    //         onChange={(v) => { }}
    //         className=""
    //       />
    //     </div>;
    //   },
    //   show: true,
    // },
    {
      title: "Company",
      accessor: "",
      renderer: (dataObj) => {
        return <div>
          <CompanySelect
            corp_id={dataObj?._id}
            asLabel
            corpType="client"
            value={dataObj?.company_id}
            onChange={(v) => { }}
            className=""
            displayAll
            noChecks
          />
        </div>;
      },
      show: true,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
