import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./TaxSlabConstant";
import { doGET, doPOST } from "../../util/HttpUtil";

class Service {
  records = [];
  totalRecords = 0;
  places = [];
  page = 1;
  rows = 20;
  filterOption = "";
  error = "";

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterUrl) => {
    if (filterUrl) this.filterUrl = filterUrl;
    else this.filterOption = {};
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rows, filterUrl)
    );
    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      return response.data;
    }
  };

  save = async (data) => {
    const response = await doPOST(ENDPOINTS.save, data);

    return response;
  };

  addToInvoice = async (invId, taxSlabId) => {
    const response = await doPOST(ENDPOINTS.addToInvoice(invId, taxSlabId));
    return response;
  };

  delete = async (id) => {
    const response = await doGET(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch(this.filterOption);
  };
}

const TaxSlabService = new Service();
export default TaxSlabService;
