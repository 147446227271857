import React from 'react'
import MarketPlaceStep3Component from './MarketPlaceStep3Component'

const MarketPlaceStep3 = ({ data, setData }) => {
    return (
        <MarketPlaceStep3Component
            data={data}
            setData={setData}
            label="Address"
            onChange={(v) =>
                setData({
                    ...data,
                    address: {
                        ...data.address,
                        city: v?.city,
                        addressLine: v?.addressLine,
                        country: v?.country,
                        state: v?.state,
                    }
                })}
            placeholder="Enter Address" />
    )
}

export default MarketPlaceStep3