import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { IoMdArrowBack } from 'react-icons/io';
import CustomerLabel from '../../../components/Dropdowns/CustomerSelect/CustomerLabel';
import { getMessageTime } from '../WhatsApp/helper';
import "./SMS.css";
import SMSService from './SMSService';

const groupMessagesByDay = (messages) => {
    const grouped = {};
    messages?.forEach(message => {
        const day = getMessageTime(message?.sentAt);
        if (!grouped[day]) {
            grouped[day] = [];
        }
        grouped[day].push(message);
    });
    return grouped;
};

function scrollToBottom() {
    var messageList = document.getElementById("messageList");
    if (messageList) {
        messageList.scrollTop = messageList.scrollHeight;
    }
}
function checkDateFormat(day) {
    const date = moment(day, "DD/MM/YYYY", true);
    if (date.isValid()) {
        return date.format("MMMM D");
    } else {
        return day;
    }
}


const MessageList = ({ messages }) => {
    const groupedMessages = groupMessagesByDay(messages);

    useEffect(() => {
        if (messages?.length) {
            scrollToBottom();
        }
    }, [messages])
    return (
        <div className='zr_crm_sms_message-list' id="messageList">
            {Object.keys(groupedMessages).reverse().map(day => (
                <div key={day}>
                    <div className='d-flex justify-content-center my-3'>
                        <div className="d-flex align-items-center justify-content-center date">{checkDateFormat(day)} </div>
                    </div>
                    {groupedMessages[day].slice().reverse().map(message => (
                        message?.text?.length > 0 && (
                            <div className={`d-flex justify-content-left`}>
                                <div key={message.id} className={`zr_crm_sms_message-item zr_crm_sms_message-self`}>
                                    <div className="zr_crm_sms_message-text">{message.text}</div>
                                    <div className="zr_crm_sms_message-time">{moment(message.sentAt * 1000).format('hh:mm A')}</div>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            ))}
        </div>
    )
};


const SMSChat = ({ chatId }) => {
    const { messages, fetchMessages } = SMSService;
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchMessages(chatId);
    }, [chatId, fetchMessages]);


    return (
        <div className="zr_crm_sms_chat-window">
            <div className="zr_crm_sms_chat-header">
                {/* <IoMdArrowBack size={24} /> */}
                <div className="zr_crm_sms_chat-title"><CustomerLabel value={chatId} /></div>
                {/* <div className="zr_crm_sms_search">
                    <FaSearch />
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search message"
                    />
                </div> */}
            </div>
            <MessageList messages={messages} />
        </div>
    );
};

export default observer(SMSChat);
