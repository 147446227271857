import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  CheckButton,
  DeleteLink,
  EditLink,
  DeleteButton,
  SaveButton,
  Layout,
} from "../../components";
import { Row, Col } from "reactstrap";
import "../../components/SuperResponsiveTableStyle.css";
import { I18nContext } from "../../store/context/i18nContext";
import ClientGroupsService from "./ClientGroupsService";
import EditClientGroups from "./EditClientGroups";
import { DialogContext } from "../../store/context/DialogContext";
import { STRUCTURE } from "./ClientGroupsConstant";

const ClientGroups = (props) => {
  const { t } = useContext(I18nContext);
  let { id } = useParams();
  if (props?.corpId) id = props?.corpId;
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(
    props?.corpId ? false : id
  );
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);
  const [hasErr, setHasErr] = useState(false);
  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await ClientGroupsService.fetch(filterUrl, props?.corpId);
      // console.log("fetched data: ", toJS(ClientGroupsService.records));
    } catch (e) {
      showError("error", e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    setShowDetailPage(
      window.location.pathname === "/clientgroups/edit" ||
      (id && !props?.corpId)
    );
  }, [id]);

  const onDelete = async (event, id, name) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: `Delete Client Group ${name}?`,
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        await ClientGroupsService.delete(id);
      } catch (e) {
        showError("error", e);
      }
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setHasErr(true);
    setLoading(true);
    try {
      // if (id) await ClientGroupsService.edit(data, id);
      // else {
      const response = await ClientGroupsService.save(data);
      ClientGroupsService.records.push(data);
      showMessage("ClientGroups Saved Successfully");
      setShowDetailPage(false);
      fetchData();
    } catch (e) {
      showMessage("error", "error", JSON.stringify(e));
      fetchData();
    }
  };

  const onRowClick = (id) => {
    if (!props?.insidePane) navigate(`/clientgroups/edit/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };
  const handleRowClick = async (clientgroupsId) => {
    if (showDetailPage && clientgroupsId !== selected_id && isEditDataChanged) {
      let isProceed = await showConfirm({
        isSaveWarn: true,
        title: t(`Do you want to save the changes ?`),
        description: t("Your changes will be lost if you don't save them"),
      });
      if (isProceed) onRowClick(clientgroupsId);
    } else onRowClick(clientgroupsId);
  };

  if (loading) return <CardThreeBounce />;
  return (
    <Layout
      showDetailPage={showDetailPage}
       
      title={t("Client Groups")}
      filterStructure={STRUCTURE}
      onApplyFilter={fetchData}
      onAddClick={() => {
        if (!props?.insidePane) navigate(`/clientgroups/edit`);
        setShowDetailPage(true);
        setSelected_id(null);
      }}
      backDetailPage={() => {
        setShowDetailPage(false);
        if (!props?.insidePane) navigate("/clientgroups");
        setSelected_id(false);
      }}
      page={ClientGroupsService.page}
      rows={ClientGroupsService.rows}
      total={ClientGroupsService.totalRecords}
      insidePane={props?.insidePane}
      onPageChange={async (page, rows) => {
        await ClientGroupsService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("Name")}</Th>
            <Th>{t("Clients")}</Th>
            <Th className="float-end">{t("Action")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {ClientGroupsService.records?.map((clientgroups) => (
            <Tr
              key={clientgroups?._id}
              onClick={
                showDetailPage && (() => handleRowClick(clientgroups?._id))
              }
            >
              <Td>{clientgroups?.name}</Td>
              <Td>{clientgroups?.clients.map((e) => e.name).join(", ")}</Td>
              <Td>
                <EditLink
                  onClick={() => {
                    if (!props?.insidePane)
                      navigate(`/clientgroups/edit/${clientgroups?._id}`);
                    setSelected_id(clientgroups?._id);
                    setShowDetailPage(true);
                  }}
                />

                <DeleteLink
                  onClick={(event) => {
                    onDelete(event, clientgroups?._id, clientgroups?.name);
                  }}
                />

                {props?.selectMode ? (
                  <CheckButton
                    onClick={() => {
                      props?.onSelect([{ ...clientgroups }]);
                    }}
                  />
                ) : null}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>

      <Layout.DetailPageTitle>
        {id ? <h5>
          Edit Client Group
        </h5> : <h5>
          Create Client Group
        </h5>}
      </Layout.DetailPageTitle>

      <Layout.DetailPageBody>
        <EditClientGroups
          insidePane={props?.insidePane}
          onChange={(v) => setIsEditDataChanged(v)}
          id={selected_id}
          data={data}
          setData={setData}
          fetchData={fetchData}
          hasErr={hasErr}
          setHasErr={setHasErr}
        />
      </Layout.DetailPageBody>
      <Layout.DetailPageFooter>
        <Row>
          <Col>
            <div className="d-flex justify-content-end mt-0">
              {data?._id && (
                <DeleteButton onClick={(e) => onDelete(e, data?._id, data?.name)} />
              )}
              <SaveButton onClick={submitHandler} />
            </div>
          </Col>
        </Row>
      </Layout.DetailPageFooter>
    </Layout>
  );
};

export default observer(ClientGroups);
