import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  CheckButton,
  DeleteLink,
  DeleteButton,
  SaveButton,
  EditLink,
  Layout,
  Breadcrumb,
} from "../../components";
import { Col, Row, Button } from "reactstrap";
import "../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { STRUCTURE } from "./CampusConstant";
import CampusService from "./CampusService";
import EditCampus from "./EditCampus";

const Campus = (props) => {
  const { t } = useContext(I18nContext);
  let { id } = useParams();
  if (props?.corpId) id = props?.corpId;
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [hasErr, setHasErr] = useState(false);
  const [showDetailPage, setShowDetailPage] = useState(
    props?.corpId ? false : id
  );
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);

  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await CampusService.fetch(filterUrl, props?.corpId);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    setShowDetailPage(
      window.location.pathname === "/campus/edit" || (id && !props?.corpId)
    );
  }, [id]);

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete Campus?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        const response = await CampusService.delete(id);
        navigate(`/campus`);
      } catch (e) {
        showError(e);
      }
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setHasErr(true);
    if (
      !data.name ||
      /*!data?.address.addressLine ||*/
      !data?.address.city ||
      !data?.address.state ||
      !data?.address.country
    )
      return;
    setLoading(true);
    try {
      if (id) await CampusService.edit(data, id);
      else {
        const response = await CampusService.save(data);
        CampusService.records.push(data);
        if (!props?.insidePane) navigate(`/campus/edit/${response}`);
      }
      fetchData();
    } catch (e) {
      showError(e);
    }
  };

  const onRowClick = (id) => {
    if (!props?.insidePane) navigate(`/campus/edit/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };
  const handleRowClick = async (campusId) => {
    console.log(campusId, "campusID");
    if (showDetailPage && campusId !== selected_id && isEditDataChanged) {
      let isProceed = await showConfirm({
        isSaveWarn: true,
        title: t(`Do you want to save the changes ?`),
        description: t("Your changes will be lost if you don't save them"),
      });
      if (isProceed) onRowClick(campusId);
    } else onRowClick(campusId);
  };

  if (loading) return <CardThreeBounce />;
  return (
    <Layout
      showDetailPage={showDetailPage}
       
      title={t("Campus")}
      filterStructure={STRUCTURE}
      onApplyFilter={fetchData}
      onAddClick={() => {
        if (!props?.insidePane) navigate(`/campus/edit`);
        setShowDetailPage(true);
        setSelected_id(null);
      }}
      backDetailPage={() => {
        setShowDetailPage(false);
        if (!props?.insidePane) navigate("/campus");
        setSelected_id(false);
      }}
      insidePane={props?.insidePane}
      page={CampusService.page}
      rows={CampusService.rows}
      total={CampusService.totalRecords}
      onPageChange={async (page, rows) => {
        await CampusService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("Name")}</Th>
            <Th>{t("Address Line")}</Th>
            <Th>{t("City")}</Th>
            <Th>{t("State")}</Th>
            <Th>{t("Country")}</Th>
            <Th className="d-flex justify-content-end">{t("Action")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {CampusService.records?.map((campus) => (
            <Tr
              key={campus?._id}
              onClick={showDetailPage && (() => handleRowClick(campus?._id))}
            >
              <Td scope="row">{campus?.name}</Td>
              <Td>{campus?.address?.addressLine?.substring(0, 40)}</Td>
              <Td>{campus?.address?.city}</Td>
              <Td>{campus?.address?.state}</Td>
              <Td>{campus?.address?.country}</Td>
              <Td>
                <EditLink
                  onClick={() => {
                    if (!props?.insidePane)
                      navigate(`/campus/edit/${campus?._id}`);
                    setSelected_id(campus?._id);
                    setShowDetailPage(true);
                  }}
                />

                {props?.selectMode ? (
                  <CheckButton
                    onClick={() => {
                      props?.onSelect([{ ...campus }]);
                    }}
                  />
                ) : null}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>

      <Layout.DetailPageTitle>
        <Breadcrumb
          parent={props?.parent}
          title={data?._id ? "Campus [" + data?.name + "]" : " Create Campus"}
        />
      </Layout.DetailPageTitle>

      <Layout.DetailPageBody>
        <EditCampus
          // insidePane={props?.insidePane}
          // sourceForBreadcrumb={props?.insidePane ? props?.sourceForBreadcrumb : " > " + "Campus"}
          onChange={(v) => setIsEditDataChanged(v)}
          id={selected_id}
          hasErr={hasErr}
          setHasErr={setHasErr}
          back={() => {
            setShowDetailPage(false);
            if (!props?.insidePane) navigate("/campus");
            setSelected_id(false);
          }}
          setData={setData}
          data={data}
          fetchData={fetchData}
        />
      </Layout.DetailPageBody>

      <Layout.DetailPageFooter>
        <Row>
          <Col>
            <div className="d-flex justify-content-end mt-0">
              {data?._id && (
                <DeleteButton onClick={(e) => onDelete(e, data?._id)} />
              )}
              <SaveButton onClick={submitHandler} />
            </div>
          </Col>
        </Row>
      </Layout.DetailPageFooter>
    </Layout>
  );
};

export default observer(Campus);
