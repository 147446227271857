import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";
import {
  BackArrow,
  CardThreeBounce,
  CrossButton,
  SaveButton,
  scrollStyle,
} from "../../components";
import SubsInvoice from "./SubsInvoice/SubsInvoice";
import CorpSubsService from "./CorpSubsService";
import { DialogContext } from "../../store/context/DialogContext";
import Tab from "../../components/Common/Tab/Tab";
import { I18nContext } from "../../store/context/i18nContext";

import SubsDetail from "./Tabs/SubsDetail";
import PlansDetails from "./Tabs/PlansDetails";
import SMSActors from "./Tabs/SMSActors";

const EditCorpSubs = (props) => {
  const { data, setData, hasErr, setHasErr, id } = props;
  const [freshData, setFreshData] = useState({});
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const { t } = useContext(I18nContext);

  const navigate = useNavigate();

  async function fetch() {
    let subsD = {};
    if (id) subsD = await CorpSubsService.get(id);
    setFreshData(subsD);
    setData(subsD);
    setHasErr(false);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    try {
      fetch();
      setHasErr(false);
    } catch (error) {
      showError(error);
    }
  }, [id]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(freshData)) {
      props?.onChange(true);
    } else {
      props?.onChange(false);
    }
  }, [data]);

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      <div>
        <Form className="p-4">
          <Tab
            // vertical
            // left
            activeTab={step}
            onChange={(data) => {
              setStep(data);
            }}
            className="d-flex"
          >
            <div num={1} label={t("Subscription Detail")}>
              <SubsDetail
                className="bg-primary color-fff"
                data={data}
                setData={setData}
                hasErr={hasErr}
                setHasErr={setHasErr}
              />
            </div>

            <div num={2} label={t("Plan Detail")}>
              <PlansDetails
                className="bg-primary color-fff"
                data={data}
                setData={setData}
                hasErr={hasErr}
                setHasErr={setHasErr}
              />
            </div>

            <div num={3} label={t("SMS Actors")}>
              <SMSActors
                className="bg-primary color-fff"
                data={data}
                setData={setData}
                hasErr={hasErr}
                setHasErr={setHasErr}
              />
            </div>

            <div num={4} label={t("Subscription Invoices")}>
              <SubsInvoice insidePane={true} corpId={id} />
            </div>
          </Tab>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default observer(EditCorpSubs);
