
import { CompanySelect } from "../../components";

export const GridColumns = {
  version: 1,
  columns: [
    // {
    //   title: "Company",
    //   accessor: "",
    //   renderer: (dataObj) => {
    //     return <div>
    //       <CompanySelect
    //         asLabel
    //         value={dataObj?.company_id}
    //         onChange={(v) => { }}
    //         className=""
    //       />
    //     </div>;
    //   },
    //   show: true,
    // },

    {
      title: "Currencies",
      accessor: "currencies",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj.currencies && dataObj.currencies.length > 0
              ? dataObj.currencies.join(', ')
              : 'No currencies selected'}
          </div>
        );
      },
      show: true,
    },
    {
      title: "Kind",
      accessor: "paymentGateway",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.paymentGateway === "PAYTM" ? "PAYTM" : (dataObj?.paymentGateway === "PAYU" ? "PAYU" : (dataObj?.paymentGateway === "PAYPAL" ? "PAYPAL" : (dataObj?.paymentGateway === "RAZORPAY" ? "RAZORPAY" : (dataObj?.paymentGateway === "PAYUBIZ" ? "PAYUBIZ" : "Other"))))}

          </div>
        );
      },
      show: true,
    },





    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },

    {
      title: "Active",
      accessor: "active",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.active ? 'True' :
            'False'
          }
        </div>
      },
      show: true,
    },
  ],
};
