import { ThreeBounce } from "better-react-spinkit";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { ENDPOINTS } from "../../common/utils/Constants";
import { InputField, PhoneNum, ToggleSelector } from "../../components";
import LanguageMenu from "../../components/CommonForBoth/TopbarDropdown/LanguageSelect/LanguageMenu";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import { doPOST } from "../../util/HttpUtil";
import OtpInput from "./OtpInput";

const LoginCredentials = (props) => {
  const { showError } = useContext(DialogContext);
  const { userContent, populateUserContent } = useContext(UserContext);
  const { t } = useContext(I18nContext);

  const {
    getOTP,
    data,
    setData,
    loading,
    errorStatus,
    isCpanelLogin,
    setCapcha,
    errorMessage,
    onClickSignUp,
    getCaptcha,
    imageURL,
    selecteToggle,
    setSelectedToggle,
    webContent
  } = props;

  const loginData = useRef(
    isCpanelLogin
      ? {
        email: "",
        password: "",
        captchaQuery: "",
      }
      : {
        authType: "password",
        countryCode: 91,
        password: "",
        language: "en",
        phone: null,
        email: "",
        emailPhone: "",
        captchaQuery: "",
        captcha_id: " ",
      }
  );

  useEffect(() => {
    document.body.className = "authentication-bg";
    const fetch = async () => {
      if (isCpanelLogin) getCaptcha();
      else {
        await populateUserContent();
        setData({ ...data, countryCode: userContent?.countryCode });
        loginData.current = {
          ...loginData.current,
          countryCode: userContent?.countryCode,
        };
      }
    };
    fetch();
    getCaptcha();
    return function cleanup() {
      document.body.className = "";
    };
  }, []);

  useEffect(() => {
    const myBtn = document.getElementById("captcha");
    if (errorStatus) {
      myBtn.click();
    }
  }, [errorStatus]);

  return (
    <React.Fragment>
      <div
        style={{
          overflowY: "auto",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row className="mb-4 mt-2 p-0 d-flex justify-content-end">
          <Col className="col-2 p-0 ml-5">
            <LanguageMenu outline />
          </Col>
        </Row>
        <div className="account-pages pt-sm-3 ">
          <Container className="d-flex justify-content-center w-100 p-3">
            <Row
              className="align-items-center justify-content-center"
              style={{
                border: "1px solid #D5D5D5",
                borderRadius: "1.25rem",
                overflow: "hidden",
                width: "100%",
                maxWidth: "500px",
              }}
            >
              <Col className="flex-1 mx-6" md={8} lg={6} xl={6}>
                <Card className="shadow-none w-100">
                  <CardBody className="p-4" style={{}}>
                    <Link className="mb-5 mt-2 d-block auth-logo">
                      <img
                        src={webContent?.logo ? `${webContent?.logo}` : "/logo.png"}
                        alt="ride-wise"
                        className="logo logo-dark mb-2"
                      />
                    </Link>
                    <h3 className="align-items-center justify-content-center d-flex pb-3 font-size-18">
                      {isCpanelLogin ? "Cpanel Login" : "Login"}
                    </h3>
                    <div>
                      {!isCpanelLogin && <div>
                        <ToggleSelector
                          style={{ fontSize: "16px" }}
                          data={[
                            { label: "Email", value: "email" },
                            { label: "Phone", value: "phone" },
                          ]}
                          value={selecteToggle ? "email" : "phone"}
                          onChange={(e) => {
                            if (e === "phone") {
                              setData((prevData) => ({
                                ...prevData,
                                phone: "",
                                countryCode: "",
                                email: ""
                              }));
                            } else {
                              setData((prevData) => ({
                                ...prevData,
                                email: "",
                                phone: "",
                                countryCode: "",
                              }));
                            }
                            setSelectedToggle(e == "email");
                          }}
                        />
                      </div>}
                      <div className="form-horizontal">
                        <div className="text-center mt-2">
                          <div className="">
                            <Row className="d-flex  align-items-center font-size-18">
                              {selecteToggle && (
                                <InputField
                                  outline
                                  value={data?.email}
                                  label={"Email"}
                                  labelStyle={{ fontSize: "16px" }}
                                  placeholder="abc@mail.com"
                                  type="email"
                                  showErr={true}
                                  basic
                                  onChange={(v) => {
                                    setData({ ...data, email: v });

                                    loginData.current = {
                                      ...loginData.current,
                                      email: v,
                                    };
                                  }}
                                  required
                                  className="col-12 font-size-18"
                                  error={
                                    data?.email?.length === 0
                                      ? "Please enter Email"
                                      : ""
                                  }
                                  style={{ fontSize: "18px", minHeight: '39px' }}
                                />
                              )}
                              {!selecteToggle && (
                                <PhoneNum
                                  outline
                                  placeholder={t("Phone No ")}
                                  required={true}
                                  value={{
                                    countryCode: data?.countryCode
                                      ? data?.countryCode
                                      : 91,
                                    phone: data?.phone,
                                  }}
                                  label={"CountryCode"}
                                  className="col-12"
                                  onChange={(v) => {
                                    setData({
                                      ...data,
                                      countryCode: v?.countryCode,
                                      phone: v?.phone,
                                    });
                                  }}
                                  showErr={true}
                                  error={
                                    data?.phone?.length === 0
                                      ? "Please enter Phone Number"
                                      : ""
                                  }
                                  countrySelectStyle={{ fontSize: "16x" }}
                                  inputStyle={{ fontSize: "16px" }}
                                  type="text"
                                />
                              )}
                            </Row>
                            <Row
                              style={{ padding: "0px 10px" }}
                              className="d-flex  align-items-center mt-1  "
                            >
                              <Col
                                style={{
                                  border: "1px solid #ced4da",
                                  borderRadius: "0.5rem",
                                  minWidth: "160px",
                                }}
                                className="   col-md-6 col-12 my-3 d-flex flex-row  m-0 p-0"
                              >
                                <img
                                  src={`data:image/jpeg;base64,${imageURL}`}
                                  alt=""
                                  height={32}
                                  style={{
                                    margin: "auto",
                                    marginTop: "3px",
                                    objectFit: "contain",
                                  }}
                                />
                                <div className=" d-flex">
                                  <i
                                    id="captcha"
                                    onClick={() => getCaptcha()}
                                    style={{
                                      fontSize: "25px",
                                      cursor: "pointer",
                                    }}
                                    className="uil-redo rounded-right me-md-3 me-2  "
                                  />
                                </div>
                              </Col>
                              <Col className=" p-0 ps-2 col-md-6 col-12 ">
                                <OtpInput
                                  value={data?.captchaQuery}
                                  onChange={(v) => {
                                    setData({ ...data, captchaQuery: v });

                                    loginData.current = {
                                      ...loginData.current,
                                      captchaQuery: v,
                                    };
                                  }}
                                  error={
                                    data?.captchaQuery?.length === 0
                                      ? "Please enter Captcha"
                                      : ""
                                  }
                                  className="flex-1 mb-1"
                                />
                              </Col>
                            </Row>
                            {errorMessage?.captchaQuery ? (
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: "30px",
                                  fontSize: "10px",
                                }}
                              >
                                {errorMessage?.captchaQuery}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="mt-5">
                          <Button
                            className="btn btn-secondary waves-effect waves-light"
                            style={{
                              margin: "auto",
                              width: "100%",
                              borderRadius: "15px",
                              padding: "7px 5px",
                            }}
                            disabled={
                              (selecteToggle &&
                                (!data?.captchaQuery || !data?.email)) ||
                              (!selecteToggle &&
                                (!data?.captchaQuery || !data?.phone))
                            }
                            type="submit"
                            onClick={getOTP}
                          >
                            {loading ? (
                              <ThreeBounce size={18} color="#FFFFFF" />
                            ) : (
                              <div style={{ fontSize: "18px" }}>Send OTP</div>
                            )}
                          </Button>
                        </div>

                        {!isCpanelLogin && <p className=" mt-3 text-center font-size-16">
                          Don't have account
                          <span
                            onClick={onClickSignUp}
                            className="m-1"
                            style={{
                              color: "#DF6463",
                              fontSize: "18px",
                              cursor: "pointer",
                            }}
                          >
                            Signup
                          </span>
                          now !
                        </p>}
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-3 text-center">
                  <p className="font-size-16">
                    {webContent?.copyright ? webContent?.copyright : `© ${new Date().getFullYear()} Logic First Global Pvt Ltd`}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginCredentials;
