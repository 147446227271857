import React from 'react'

import { Modal } from 'reactstrap';

export const ModalPopup = (props) => {

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function tog_scroll() {
        props?.setIsOpen(props?.isOpen)
        removeBodyCss()
    }


    return (
        <Modal
            isOpen={props?.isOpen}
            toggle={() => {
                tog_scroll()
            }}
            scrollable={true}
            className="modal-fullscreen"
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                    {props?.title}
                </h5>
                <button
                    type="button"
                    onClick={() => props?.setIsOpen(false)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">

                {props?.body}
                {/* style={{ 'position': 'fixed', 'bottom': '0' }} */}
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() =>
                            props?.setIsOpen(false)
                        }
                    >
                        Close
                    </button>
                    <button type="button" onClick={() => {
                        props?.saveChanges();
                        props?.setIsOpen(false)
                    }} className="btn btn-primary">
                        Save changes
                    </button>
                </div>
            </div>
        </Modal>
    )
}
