import React, { useState, useEffect, useRef, useContext } from 'react';
import { doDELETE, doGET, doPOST, doPUT } from '../../util/HttpUtil';
import "./style.css";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { DialogContext } from '../../store/context/DialogContext';
import { CirclePicker } from 'react-color'; // Import the color picker component
import { FaTag } from "react-icons/fa";
import TagPickerService from './TagPickerService';
import { observer } from 'mobx-react-lite';

export const getRandomColor = () => {
    const letters = '23456789A';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
};

const TagPicker = ({
    kind,
    onChange,
    value,
    iconClassName,
    className,
    inline,
    style,
    data,
    asLabel,
    onlyPicker = false,
    onlyValues = false
}) => {
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState(value || []);
    const [showDropdown, setShowDropdown] = useState(inline ? true : false);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [tagsByKind, setTagsByKind] = useState([])
    const [actionMenuVisible, setActionMenuVisible] = useState({});
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [editingTagId, setEditingTagId] = useState(null);
    const [editingLabel, setEditingLabel] = useState('');

    const [showColorPicker, setShowColorPicker] = useState({});
    const [selectedColor, setSelectedColor] = useState('#fff');

    const dropdownRef = useRef(null); // Ref for the dropdown to manage outside clicks

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
            setActionMenuVisible({})
            setInputValue('')
            setEditingTagId(null)
            setShowColorPicker({})
            setEditingLabel('')
        }
    };

    useEffect(() => {
        if (value?.length)
            setSelectedTags(value?.map((tag) => {
                const foundTag = tagsByKind.find(completeTagObject => completeTagObject._id === tag.tag_id)
                return { ...foundTag, _id: tag?.tag_id }
            })?.filter(v => v?.label))
    }, [value, tagsByKind])

    useEffect(() => {
        if (showDropdown) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showDropdown]);

    const toggleActionMenu = (tagId) => {
        setActionMenuVisible(tagId);
    };

    const fetchTags = async () => {
        try {
            const res = await TagPickerService.fetch()
        } catch (error) {
            showError(error)
        }
        setLoading(false);
    };

    useEffect(() => {
        if (TagPickerService?.records?.length) {
            const filteredTags = TagPickerService?.records.filter(tag => tag.kind === kind);
            setTagsByKind(filteredTags)
            if (selectedTags.length) {
                setTags(filteredTags.filter(tag => !selectedTags.find(selected => selected._id === tag._id)));
            } else {
                setTags(filteredTags)
            }
        }
    }, [TagPickerService?.records])

    useEffect(() => {
        fetchTags();
    }, [kind]);

    useEffect(() => {
        if (selectedTags.length) {
            setTags(tagsByKind.filter(tag => !selectedTags.find(selected => selected._id === tag._id)));
        } else {
            setTags(tagsByKind)
        }
    }, [selectedTags, tagsByKind]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleCreateTag = async () => {
        try {
            const newTag = {
                kind: kind,
                color: getRandomColor(),
                label: inputValue,
                group: 'Some Group',
            };
            const response = await doPOST(`/api/tag-element/create`, newTag); // Assume response includes the created tag
            TagPickerService.fetchForcefully()
            setInputValue('');
        } catch (error) {
            showError(error)
        }
    };

    const handleTagClick = (tag) => {
        setActionMenuVisible({})
        const updatedSelectedTags = [...selectedTags, tag];
        setSelectedTags(updatedSelectedTags);
        onChange(updatedSelectedTags?.map((t) => {
            return { ...t, tag_id: t?._id }
        }));
    };

    const handleUpdateTag = async (tag, newLabel) => {
        try {
            const updatedTag = { ...tag, label: newLabel };
            await doPUT(`/api/tag-element/update`, updatedTag);
            TagPickerService.fetchForcefully()
            setShowColorPicker({});
            setEditingTagId(null)
            setActionMenuVisible({})
            setEditingLabel('')
        } catch (error) {
            showError(error)

        }
    };

    const handleDeleteTag = async (tagId) => {
        try {
            await doDELETE(`/api/tag-element/${tagId}/delete`);
            TagPickerService.fetchForcefully()
        } catch (error) {
            showError(error)
        }
    };

    const handleRemoveSelectedTag = (tagId) => {
        const updatedSelectedTags = selectedTags.filter(tag => tag._id !== tagId);
        setSelectedTags(updatedSelectedTags);
        onChange(updatedSelectedTags?.map((t) => {
            return { ...t, tag_id: t?._id }
        }));
    };


    const toggleColorPicker = (tagId, currentColor) => {
        setSelectedColor(currentColor);
        setShowColorPicker(tagId);
        setActionMenuVisible({})
    };

    const handleHexInputChange = (event) => {
        const hexValue = event.target.value;
        if (/^#([0-9A-F]{3}){1,2}$/i.test(hexValue)) {
            setSelectedColor(hexValue);
        }
    };

    // Function to handle color change
    const handleChangeComplete = async (color) => {
        try {
            const updatedTag = { ...showColorPicker, color: color };
            await doPUT(`/api/tag-element/update`, updatedTag);
            setShowColorPicker({});
            setEditingTagId(null)
            TagPickerService.fetchForcefully()
            setActionMenuVisible({})
            setEditingLabel('')
        } catch (error) {
            showError(error)
        }
        // handleUpdateTag(editingTagId, editingLabel, color.hex); // Now passing color.hex as the new color
    };

    if (!!data) {
        return (
            <>
                {data?.map((selectedValue, index) => {
                    const selectedTag = tags.find((t) => t?._id == selectedValue?.tag_id)
                    return <span className=' px-1 me-2 rounded-2' key={index} style={{ backgroundColor: `${selectedTag?.color}22`, color: `${selectedTag?.color}` }}>
                        {selectedTag?.label}
                    </span>
                })?.filter(value => value)}
            </>
        )
    }

    return (

        <div
            onClick={(e) => {
                e.stopPropagation();
                if (inline) {
                    setShowDropdown(!showDropdown)
                }
            }}
            style={!onlyValues ? { height: inline ? "22px" : "30px", ...style } : {}} className={`position-relative d-flex align-items-center ${className} ${inline ? "tag-inline_wrapper" : " "}`} >
            {!onlyValues && <div onClick={(e) => e.stopPropagation()} className={inline ? ' position-relative' : 'tagIcon position-relative'}>
                {
                    inline ? <span className={`inline_tag-label mb-1`} onClick={(e) => {
                        e.stopPropagation();
                        setShowDropdown(!showDropdown)
                    }}>
                        Tags
                    </span>

                        : <span className={`FaTagIcon ${iconClassName}`} onClick={(e) => {
                            e.stopPropagation();
                            setShowDropdown(!showDropdown)
                        }}>
                            <FaTag size={13} />
                        </span>
                }
                {showDropdown && (
                    <div className="tag-dropdown" ref={dropdownRef}>
                        <input
                            type="text"
                            placeholder="Search or Create Tag"
                            style={{ fontSize: 12 }}
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={(e) => e.key === 'Enter' && handleCreateTag()}
                            maxLength={15}
                        />
                        {loading ? (
                            <div>Loading...</div>
                        ) : (
                            <ul>
                                {tags.map(tag => (
                                    <li
                                        key={tag._id}
                                        className='action-menu-list-item'
                                        style={{ backgroundColor: `${tag.color}22` }}
                                        onClick={() => handleTagClick(tag)}
                                    >
                                        {editingTagId === tag._id && !showColorPicker?._id ? (
                                            <input
                                                type="text"
                                                value={editingLabel}
                                                onChange={(e) => setEditingLabel(e.target.value)}
                                                onBlur={() => setEditingTagId(null)}
                                                onKeyDown={(e) => e.key === 'Enter' && handleUpdateTag(tag, editingLabel)}
                                                autoFocus
                                                className='m-0'
                                                style={{ height: "30px" }}
                                            />
                                        ) : (
                                            <>
                                                <span style={{ color: `${tag.color}`, fontSize: 12, fontWeight: 500 }} className="action-menu-label">{tag.label}</span>
                                                <div
                                                    className="action-menu-icon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        toggleActionMenu(tag._id);
                                                    }}
                                                >
                                                    <PiDotsThreeVerticalBold size={20} />
                                                </div>
                                            </>
                                        )}
                                        {actionMenuVisible === tag._id && !editingTagId && (
                                            <div className="action-menu" onClick={(e) => e.stopPropagation()}>
                                                <ul>
                                                    <li onClick={() => { setEditingTagId(tag._id); setEditingLabel(tag.label); }}>Rename</li>
                                                    <li onClick={() => handleDeleteTag(tag._id)}>Delete</li>
                                                    <li onClick={() => toggleColorPicker(tag, tag.color)}>Change Color</li>
                                                </ul>
                                            </div>
                                        )}



                                    </li>
                                ))}

                                {showColorPicker?._id && (
                                    <div className="color-picker-container card">
                                        <CirclePicker
                                            color={selectedColor}
                                            onChangeComplete={(color) => setSelectedColor(color.hex)}
                                            width="180px"
                                        />

                                        <input
                                            type="text"
                                            value={selectedColor}
                                            className='mt-2 '
                                            onChange={handleHexInputChange}
                                            placeholder="#000000"
                                        />
                                        <button className="color-picker-save-btn" onClick={() => {
                                            handleChangeComplete(selectedColor);
                                            setShowColorPicker({}); // Optionally hide the picker after saving
                                        }}>
                                            Save
                                        </button>
                                    </div>
                                )}
                            </ul>
                        )}
                    </div>
                )}
            </div>}

            {!onlyPicker &&
                <div
                    className='d-flex flex-wrap'
                >
                    {selectedTags.map((tag, index) => (
                        <div onClick={(e) => e.stopPropagation()} key={index} className={` ${inline ? "tag-item-inline" : "tag-item"} `} style={{ color: tag?.color, backgroundColor: `${tag.color}22`, fontSize: inline ? 11 : 12, fontWeight: 500 }}>
                            {tag.label}
                            {
                                !asLabel ? (
                                    <span style={{ color: "#fff", backgroundColor: `${tag.color}` }} className={`${inline ? "tag-remove-icon_inline" : "tag-remove-icon "} p-0 m-0`} onClick={() => handleRemoveSelectedTag(tag._id)}>
                                        <i className=" color-blue bx bx-x font-size-14 ms-0 me-0  p-0 pt-0" />
                                    </span>
                                ) : null
                            }
                        </div>
                    ))}

                </div>}

        </div>
    );
};

export default observer(TagPicker);
