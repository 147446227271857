export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/credit-note/grid?rows=${sizePerPage}&`;
        if (filterUrl) url += filterUrl;
        return url + "&page=" + page;
    },
    get: ({ editId, corp_id }) => `/api/credit-note/${editId}/detail${corp_id ? `?corp_id=${corp_id}` : ""}`,
    save: `/api/credit-note/create`,
    delete: (id) => `/api/credit-note/${id}/delete`,
    update: `/api/credit-note/update`,
};

export const STRUCTURE = [
    {
        label: "Name",
        filterLabel: "Credit Note Name",
        filterName: "client.fullName",
        type: "text",
    },
    {
        label: "Tax Id",
        filterLabel: "Credit Note Tax Id",
        filterName: "client.taxId",
        type: "text",
    },
    {
        label: "Amount",
        filterLabel: "Credit Note Amount",
        filterName: "amount",
        type: "text",
    },
    {
        label: "Credit Note Number",
        filterLabel: "Credit Note Number",
        filterName: "creditNoteNo",
        type: "text",
    },
    {
        label: "Date",
        filterLabel: "Credit Note Date",
        filterName: "date",
        type: "dateSelect",
    },
];
