export const ENDPOINTS = {
  getRateItems: () => `/api/o/rate/names`,
  grid: (page, sizePerPage, filterUrl, forSupplier) => {
    let url = `/api/rate/grid?withExtn=true&`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (forSupplier) {
      url += `supplierRate=true&`
    } else {
      url += `supplierRate=false&`
    }
    if (page) url += `page=${page}`;
    return url;
  },
  rateDataGrid: (page, sizePerPage, filterUrl, forSupplier) => {
    let url = `/api/o/rate/data?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (forSupplier) {
      url += `supplierRate=true&`
    } else {
      url += `supplierRate=false&`
    }
    if (page) url += `page=${page}`;
    return url;
  },
  get: (id) => `/api/rate/${id}/detail`,
  saveNew: `/api/rate/create`,
  save: (forSupplier) => {
    let url = `/api/rate/create`;
    if (forSupplier) return (url += `?forSupplier=true`);
    return url;
  },
  delete: (id) => `/api/rate/${id}/delete`,
  update: `/api/rate/update`,
  fetchAll: (rateNo, forSupplier) => {
    return forSupplier === true
      ? `/api/rate/grid?rateNo=${rateNo}&withExtn=true&rows=-1`
      : `/api/rate/grid?rateNo=${rateNo}&withExtn=true&rows=-1`;
  },
};

export const SELECTSTRUCTURE = {
  status: [
    { label: "Approved", value: 1 },
    { label: "Disapproved", value: 0 },
  ],
  rateType: [
    { label: "Pick and Drop", value: 1 },
    { label: "Per Seat", value: 2 },
    { label: "Garage to Garage", value: 3 },
    { label: "Return Fare Applicable.", value: 4 },
    {
      label: "Fixed cost for empty pickup and empty drop off trips.",
      value: 5,
    },
    { label: "Variable cost for garage trips", value: 6 },
    { label: "Discounted return fare (%)", value: 7 },
  ],
  appliesOn: [
    { label: "PER_VEHICLE", value: "PER_VEHICLE" },
    { label: "PER_PAX", value: "PER_PAX" },
  ],
  tariffType: [
    { label: "LOCAL", value: "LOCAL" },
    { label: "OUTSTATION", value: "OUTSTATION" },
    { label: "PACKAGE", value: "PACKAGE" },
    { label: "SELF_DRIVE", value: "SELF_DRIVE" },
  ],
  timeUnit: [
    { label: "HOUR", value: "HOUR" },
    { label: "MINUTE", value: "MINUTE" },
  ],
  distUnit: [
    { label: "KM", value: "KM" },
    { label: "MILE", value: "MILE" },
  ],
  billingDuration: [
    { label: "MONTHLY", value: "MONTHLY" },
    { label: "WEEKLY", value: "WEEKLY" },
    { label: "BIWEEKLY", value: "BIWEEKLY" },
    { label: "ON_DEMAND", value: "ON_DEMAND" },
  ],
  timeRounding: [
    { label: "Charge each minute", value: 0 },
    { label: "> 30 min = 1 hour", value: 1 },
    { label: "< 1 hr = 0 hr", value: 2 },
    { label: "> 1 min = 1hr", value: 3 },
  ],
  rateCycles: [
    { label: "At Midnight", value: 1 },
    { label: "From 24Hrs of trip start time", value: 2 },
  ],
  timeUnitShort: [
    { label: "Hour", value: "Hr" },
    { label: "Min", value: "Min" },
  ],
  fareType: [
    { label: "Pick and Drop", value: 0 },
    { label: "Garage to Garage", value: 1 },
    { label: "Garage to Garage = Fixed Cost", value: 2 },
    { label: "Garage Travel Charged on Separate Per KM Basis Only", value: 3 },
    {
      label: "Garage Travel Distance only added to trip distance not time",
      value: 4,
    },
    { label: "Return Fare Applicable for both distance and time", value: 5 },
    { label: "Return Fare Applicable for distance only", value: 6 },
    {
      label:
        "Return Fare Applicable for distance on Separate Per KM Basis only",
      value: 7,
    },
  ],
};

export const RATESTRUCTURE = [
  {
    label: "Name",
    description: "description...",
    filterLabel: "Rate Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Vendopr",
    description: "description...",
    filterLabel: "Vendor",
    filterName: "vendor",
    type: "vendorSelect",
  },
];

export const STRUCTURE = [
  {
    label: "Rate Name",
    // description: "description...",
    filterLabel: "Name",
    filterName: "rateName_id",
    type: "rateSelect",
  },
  {
    label: "Client",
    // description: "description...",
    filterLabel: "Client",
    filterName: "client_id",
    icon: "bx bx-badge-check ",

    type: "clientSelect",
    dependencyOnMe: ["clientBU_id"],
  },
  {
    label: "Supplier",
    // description: "description...",
    filterLabel: "Supplier",
    filterName: "supplier_id",
    type: "supplierSelect",
  },

  {
    label: "Country",
    // description: "description...",
    filterLabel: "Country",
    filterName: "srcCountry",
    type: "countrySelect",
    dependencyOnMe: ["srcCity"],
  },
  {
    label: "State",
    // description: "description...",
    filterLabel: "State",
    filterName: "srcState",
    type: "stateSelect",
    onlyCountry: "onlyState",
    dependency: {
      country: "srcCountry",
    },
  },
  {
    label: "City",
    // description: "description...",
    filterLabel: "City",
    filterName: "srcCity",
    type: "citySelect",
    onlyCountry: "onlyCountry",
    dependency: {
      country: "srcCountry",
      state: "srcState",
    },
  },
  // {
  //   label: "City Group",
  //   // description: "description...",
  //   filterLabel: "City Group",
  //   filterName: "destinationCity",
  //   type: "cityGroupSelect",
  // },
  {
    label: "Rate Type",
    // description: "description...",
    filterLabel: "Rate Type",
    filterName: "rateType",
    type: "select",
    data: [
      { label: "Outstation", value: "OUTSTATION" },
      { label: "Local", value: "LOCAL" },
      { label: "Disposal", value: "DISPOSAL" },
      { label: "Sightseeing", value: "SIGHTSEEING" },
      { label: "Fixed Route", value: "FIXED_ROUTE" },
      { label: "Self Drive", value: "SELF_DRIVE" },
      { label: "Point to Point", value: "POINT_TO_POINT" },
    ],
  },
  // {
  //   label: "Tariff Name",
  //   // description: "description...",
  //   filterLabel: "Tariff Name",
  //   filterName: "name",
  //   type: "text",
  // },
  {
    label: "Rate No",
    // description: "description...",
    filterLabel: "Rate No",
    filterName: "rateNo",
    type: "text",
  },
  // {
  //   label: "Returning fare applicable",
  //   // description: "description...",
  //   filterLabel: "Returning fare applicable",
  //   type: "checkBox",
  //   filterName: "tariffStructure.isReturnApplicable",
  // },
  // {
  //   label: "Garage to Garage",
  //   // description: "description...",
  //   filterLabel: "Garage to Garage",
  //   type: "checkBox",
  //   filterName: "garageToGarage",
  // },
];

// export const STRUCTURE = [
//   {
//     label: "Name",
//     filterLabel: "Rate Name",
//     filterName: "name",
//     type: "text",
//   },
//   {
//     label: "Original_id",
//     filterLabel: "Rate original_id",
//     filterName: "original_id",
//     type: "text",
//   },
//   {
//     label: "Company_id",
//     filterLabel: "Rate company_id",
//     filterName: "company_id",
//     type: "text",
//   },
//   {
//     label: "office_id",
//     filterLabel: "Rate office_id",
//     filterName: "office_id",
//     type: "text",
//   },

// ];
