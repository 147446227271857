import { makeAutoObservable } from "mobx";


class Service {
    isVisible = false

    constructor() {
        makeAutoObservable(this);
    }


    toggleModal = () => {
        this.isVisible = !this.isVisible
    }




}

const userGuideService = new Service();
export default (userGuideService);
