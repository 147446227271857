import React from 'react' 


const Receipts = ({data,className}) => {
    const formatDate = (dateInt) => {
        if (!dateInt) return '';
    
        // Convert the integer to a string
        const dateString = dateInt.toString();
    
        // Ensure dateString is a string and has the correct length
        if (typeof dateString !== 'string' || dateString.length !== 8) return dateString;
      
        // Extract year, month, and day from the dateString
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
      
        // Format the date as YYYY-MM-DD
        return `${day}-${month}-${year}`;
      };
    
  return (
    <div className={`card shadow ${className}`}>
               <div className='w-100 px-2 pt-2' style={{ backgroundColor: "#EBEBEB" }}><h3>Payment Receipts</h3></div>
               <table className="table-bordered receipt">
               <thead>
                   <tr>
                       <th className="ms-3" >Date</th>
                       <th className="ms-3" >Amount Settled</th>
                       {/* <th className="ms-3" >TDS Settled</th> */}
                   </tr>
               </thead>
               {data.map((receipt, index) => (
                   <tbody className="receipt-table-hover position-relative">
                       <tr key={index}>
                           <td>{formatDate(receipt?.date)}</td>
                           <td>{(receipt?.amountSettled/100).toFixed(2)} </td>
                           {/* <td>{(receipt?.tdsSettled/100).toFixed(2)} </td> */}
                       </tr>
                   </tbody>
               ))}
           </table>
    </div>
  )
}

export default Receipts;