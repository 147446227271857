import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditMakeModel from "./EditMakeModel";
import { STRUCTURE } from "./MakeModelConstant";
import { GridColumns } from "./MakeModelGridColumn";
import MakeModelService from "./MakeModelService";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";


const MakeModel = ({ insidePane }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/make-model/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)


  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await MakeModelService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
  }, [])

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.make || !detailData?.model) {
      setHasErr(true)
      showError(t("Please enter all fields"));
      return;
    }
    setHasErr(false)
    setLoading(true);
    try {
      if (editID) {
        await MakeModelService.edit(detailData, editID);
        showMessage(t("Record updated successfully."));
      } else {
        const makeModelID = await MakeModelService.save(detailData);
        showMessage(t("Record saved successfully."));
        if (!insidePane) navigate(`/make-model/edit/${makeModelID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await MakeModelService.delete(id);
        showMessage("Deleted", "Deleted");
        if (!insidePane) navigate("/make-model");
        setShowDetailPage(false);
      } catch (e) {
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback((makeModel) => {
    return (

      <>
        <EditLink
          onClick={() => {
            if (!insidePane)
              navigate(`/make-model/edit/${makeModel?._id}`);
            setEditID(makeModel?._id);
            setShowDetailPage(true);
          }}
        />
        <DeleteLink
          onClick={(event) => onDelete(event, makeModel?._id)}
        />
      </>
    );
  }, [insidePane])


  return (
    <>
      <Layout
        medium
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/make-model");
          setEditID(null);
        }}
         
        title={t("MakeModel")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/make-model/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={MakeModelService.page}
        rows={MakeModelService.rows}
        total={MakeModelService.total}
        fetch={MakeModelService.fetch}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem>Bulk Delete</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> Download </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={MakeModelService.records}
              total={MakeModelService.total}
              uiPreference="make-model.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={setSelectedIDs}
              page={MakeModelService.page}
              rowsPerPage={MakeModelService.rowsPerPage}
              onPaginationChange={MakeModelService.onPaginationChange}
              renderLastCol={renderLastCol}


            />
          </>
        </Layout.Table>

        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit Make Model") : "Add Make Model"}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditMakeModel
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {detailData?._id ? (
            <AuditLogDialog module="MakeModel" id={detailData?._id} />
          ) : null

          }
          {detailData?._id ?
            <DeleteButton loading={loading} onClick={(e) => onDelete(e, detailData?._id)} /> : null}
          <SaveButton onClick={onSave} loading={loading} />
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(MakeModel);
