import { observer } from "mobx-react-lite";

import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, } from "reactstrap";


import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,

  Checkbox,
} from "../../../../components";
import "../../../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../../../store/context/DialogContext";
import { I18nContext } from "../../../../store/context/i18nContext";




import TariffSettingsService from "./TariffSettingsService";





const TariffSettings = (props) => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({})
  const [tariffsettings, settariffsettings] = useState([])

  const { showMessage } = useContext(DialogContext);
  const [hasErr, setHasErr] = useState(false);

  const submitHandler = async (e) => {
    console.log("submit handler");
    e.preventDefault();

    setLoading(true);

    try {

      await TariffSettingsService.save(data)
      showMessage("TariffSettings saved successfully");
      setLoading(false)
    } catch (e) {
      showError(e)
      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await TariffSettingsService.get();
      setData(response)

      setHasErr(false);
      setLoading(false);

    } catch (e) {


      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {

    setLoading(true);

    fetchData();
    setHasErr(false);

  }, []);



  if (loading) return <CardThreeBounce />;
  return (<div className="p-4 mt-3">



    <Row className="mt-5">


      <Col className='col-lg  mt-3' style={{ backgroundColor: "#EAEAEA", fontSize: "15px", borderBottom: "1px solid rgba(0,0,0,.1)" }}>
        <header>Tariff Settings</header>
      </Col>
    </Row>

    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Auto Approve Tariff"

          checked={data?.tariffAutoApprove}

          onClick={() => {
            setData({ ...data, tariffAutoApprove: !data?.tariffAutoApprove })
          }}
        />


      </Col>
      <Col className="col-lg">

        <Checkbox
          label="Allow Different Source and Destination Garage"

          checked={data?.diffSrcDstGarage
          }

          onClick={() => {
            setData({
              ...data, diffSrcDstGarage
                : !data?.diffSrcDstGarage

            })
          }}
        />


      </Col>

    </Row>

    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Zone Required"

          checked={data?.zoneRequired}

          onClick={() => {
            setData({
              ...data, zoneRequired
                : !data?.zoneRequired
            })
          }}
        />


      </Col>


    </Row>






    <Row className="mt-5">


      <Col className='col-lg  mt-3' style={{ backgroundColor: "#EAEAEA", fontSize: "15px", borderBottom: "1px solid rgba(0,0,0,.1)" }}>
        <header>Tariff Create/Edit/Detail Page Configuration (Select only those fields which need to be shown on Tariff Create/Edit/Detail Page)</header>
      </Col>
    </Row>

    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Tariff Category"

          checked={data?.showTarCatOpt_t}

          onClick={() => {
            setData({ ...data, showTarCatOpt_t: !data?.showTarCatOpt_t })
          }}
        />


      </Col>

    </Row>


    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Vendor Business Unit"

          checked={data?.showVendorBuOpt_t
          }

          onClick={() => {
            setData({
              ...data, showVendorBuOpt_t
                : !data?.showVendorBuOpt_t

            })
          }}
        />


      </Col>
      <Col className="col-lg">

        <Checkbox
          label="Serving Business Unit"

          checked={data?.showServingBuOpt_t
          }

          onClick={() => {
            setData({
              ...data, showServingBuOpt_t
                : !data?.showServingBuOpt_t

            })
          }}
        />


      </Col>

    </Row>



    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Market"

          checked={data?.showMarketOpt_t}

          onClick={() => {
            setData({ ...data, showMarketOpt_t: !data?.showMarketOpt_t })
          }}
        />


      </Col>
      <Col className="col-lg">

        <Checkbox
          label="Booking Limit"

          checked={data?.showBookingLimOpt_t}

          onClick={() => {
            setData({ ...data, showBookingLimOpt_t: !data?.showBookingLimOpt_t })
          }}
        />


      </Col>

    </Row>




    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Tax (Override)"

          checked={data?.showTaxOpt_t
          }

          onClick={() => {
            setData({
              ...data, showTaxOpt_t
                : !data?.showTaxOpt_t

            })
          }}
        />


      </Col>
      <Col className="col-lg">

        <Checkbox
          label="Fuel Surcharge"

          checked={data?.showFuelSurOpt_t
          }

          onClick={() => {
            setData({
              ...data, showFuelSurOpt_t
                : !data?.showFuelSurOpt_t
            })
          }}
        />


      </Col>

    </Row>


    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Cancellation Charges"

          checked={data?.showCancelOpt_t}

          onClick={() => {
            setData({ ...data, showCancelOpt_t: !data?.showCancelOpt_t })
          }}
        />


      </Col>
      <Col className="col-lg">

        <Checkbox
          label="Ammendment Charges"

          checked={data?.showAmmendOpt_t
          }

          onClick={() => {
            setData({
              ...data, showAmmendOpt_t
                : !data?.showAmmendOpt_t
            })
          }}
        />


      </Col>

    </Row>



    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Payment Terms"

          checked={data?.showPaymentOpt_t
          }

          onClick={() => {
            setData({
              ...data, showPaymentOpt_t
                : !data?.showPaymentOpt_t

            })
          }}
        />


      </Col>
      <Col className="col-lg">

        <Checkbox
          label="Blackout Days"

          checked={data?.showBlackoutOpt_t}

          onClick={() => {
            setData({ ...data, showBlackoutOpt_t: !data?.showBlackoutOpt_t })
          }}
        />


      </Col>

    </Row>


    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Description"

          checked={data?.showDescOpt_t}

          onClick={() => {
            setData({
              ...data, showDescOpt_t
                : !data?.showDescOpt_t

            })
          }}
        />


      </Col>
      <Col className="col-lg">

        <Checkbox
          label="Terms and Conditions"

          checked={data?.showTncOpt_t}

          onClick={() => {
            setData({ ...data, showTncOpt_t: !data?.showTncOpt_t })
          }}
        />


      </Col>

    </Row>


    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Hide Disposal"

          checked={data?.hideDisposal}

          onClick={() => {
            setData({ ...data, hideDisposal: !data?.hideDisposal })
          }}
        />


      </Col>
      <Col className="col-lg">

        <Checkbox
          label="Hide Sightseeing"

          checked={data?.hideSightseeing}

          onClick={() => {
            setData({ ...data, hideSightseeing: !data?.hideSightseeing })
          }}
        />


      </Col>

    </Row>



    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Hide Point to Point"

          checked={data?.hidePointToPoint
          }

          onClick={() => {
            setData({
              ...data, hidePointToPoint
                : !data?.hidePointToPoint
            })
          }}
        />


      </Col>
      <Col className="col-lg">

        <Checkbox
          label="Hide Self Drive"

          checked={data?.hideSelfDrive}

          onClick={() => {
            setData({
              ...data, hideSelfDrive
                : !data?.hideSelfDrive
            })
          }}
        />


      </Col>

    </Row>


    <Row className="mt-3 mb-3">
      <Col className="col-lg">

        <Checkbox
          label="Hide Fixed Route"

          checked={data?.hideFixedRoute}

          onClick={() => {
            setData({
              ...data, hideFixedRoute
                : !data?.hideFixedRoute

            })
          }}
        />


      </Col>


    </Row>














    <div className="d-flex justify-content-end  ">
      <div className="me-2">
        <Button type="submit" color="primary" onClick={submitHandler}>Save</Button>{' '}
      </div>




    </div>

  </div>

  );
};

export default observer(TariffSettings);


