import React, { useContext, useEffect } from 'react';
import { Card, CardBody, Col, Row } from "reactstrap";
import { InputField, NameField, PhoneNum } from '../../components';
import { I18nContext } from '../../store/context/i18nContext';
import BookingService from './BookingService';
import SearchUser from '../Users/SearchUser';

const BookedBy = ({ value, onChange, clientId, emptyDivStyle, className, pax = 0, onPaxChange = () => { }, showPaxCount = true }) => {
    const { t } = useContext(I18nContext);
    const handleUpdate = (fieldName, fieldValue) => {
        if (fieldName && fieldValue != null) {
            const val = value || {};
            onChange({ ...val, [fieldName]: fieldValue });
        }
    }

    const fetchCustomerData = async (data, type) => {
        try {
            let url;
            if (type == "phone" && data) {
                url = `&phoneNo.countryCode=${data?.countryCode}&phoneNo.phone=${data?.phone}`;
            }
            else if (type == 'email' && data) {
                url = `&email=${data}`
            }
            if (clientId) {
                url += `&client_id=${clientId}`
            }
            url += `&isBooker=true`
            const response = await BookingService?.customerGrid(url);
            if (type == 'email' && response?.rows?.length > 0) {
                const phone = response?.rows[0]?.phoneNo;
                const name = response?.rows[0]?.name;
                const salutation = response?.rows[0]?.salutation;
                const customer_id = response?.rows[0]?._id;
                onChange({
                    ...value,
                    name: name,
                    salutation: salutation,
                    customer_id: customer_id,
                    phone: phone
                })
            } else if (type == 'phone' && response?.rows?.length > 0) {
                const email = response?.rows[0]?.email;
                const name = response?.rows[0]?.name;
                const salutation = response?.rows[0]?.salutation;
                const customer_id = response?.rows[0]?._id;
                onChange({
                    ...value,
                    name: name,
                    salutation: salutation,
                    email: email,
                    customer_id: customer_id,
                })
            }
        } catch (error) {

        }
    }


    return (

        <div className='p-1 pt-1 d-flex align-items-center'>

            <div style={{ width: 42 }}/*  className='col-1' */>
                <strong>{t("Booker")}</strong>
            </div>

            <div style={{ flex: 1 }} className="">
                <Row className="mx-1">
                    <Col className="col-12 col-sm-4 py-0 mb-4 mb-sm-0">
                        <PhoneNum
                            placeholder={t("Phone No ")}
                            value={{
                                countryCode: value?.phone?.countryCode ?? 91,
                                phone: value?.phone?.phone ?? ""
                            }}
                            hideFlag
                            outline
                            hideDropdown
                            onblur={() => {
                                if (!value?.customer_id) {
                                    fetchCustomerData(value?.phone, 'phone')
                                }
                            }}
                            label={t("Phone")}
                            rightComponentStyle={{ width: "0px" }}
                            onChange={(v) => {
                                onChange({
                                    ...value,
                                    phone: {
                                        ...value?.phone, phone: v?.phone, countryCode: v?.countryCode
                                    }
                                })
                            }}
                            RightComponent={() => {
                                return <>
                                    <SearchUser
                                        style={{
                                            // backgroundColor: "#F1F1F1",
                                            borderRadius: "0px",
                                            position: "relative",
                                            height: "34px",
                                            width: '30px',
                                            right: '31px',
                                            top: "1.5px",
                                            cursor: 'pointer'
                                        }}
                                        onlyIcon={true}
                                        value={[]}
                                        hideCheckboxes={true}
                                        userType="Passenger"
                                        onChange={(v) => {
                                            if (v) {
                                                const pax = {
                                                    email: v?.email,
                                                    name: v?.name,
                                                    salutation: v?.salutation,
                                                    phone: {
                                                        countryCode: v?.phoneNo?.countryCode,
                                                        phone: v?.phoneNo?.phone,

                                                    },
                                                    customer_id: v?._id
                                                };
                                                onChange(pax)
                                            }
                                        }}
                                    />
                                </>
                            }}
                        />
                        {/* } */}
                    </Col>
                    <Col className="col-12 col-sm-4 mb-4 mb-sm-0">
                        <InputField
                            value={value?.email}
                            outline
                            onblur={() => {
                                if (!value?.customer_id) {
                                    fetchCustomerData(value?.email, 'email')
                                }
                            }}
                            label="Email"
                            placeholder="abc@mail.com"
                            type={t("email")}
                            error={!value?.email ? "Please enter Email" : ""}
                            onChange={(v) => handleUpdate("email", v)}
                            showClear={false}
                            RightComponent={() => {
                                return <>
                                    <SearchUser
                                        style={{
                                            // backgroundColor: "#F1F1F1",
                                            borderRadius: "0px",
                                            position: "relative",
                                            height: "34px",
                                            width: '30px',
                                            right: '-4px',
                                            top: "-1px",
                                            cursor: 'pointer'
                                        }}
                                        onlyIcon={true}
                                        value={[]}
                                        hideCheckboxes={true}
                                        userType="Passenger"
                                        onChange={(v) => {
                                            if (v) {
                                                const pax = {
                                                    email: v?.email,
                                                    name: v?.name,
                                                    salutation: v?.salutation,
                                                    phone: {
                                                        countryCode: v?.phoneNo?.countryCode,
                                                        phone: v?.phoneNo?.phone,

                                                    },
                                                    customer_id: v?._id
                                                };
                                                onChange(pax)
                                            }
                                        }}
                                    />

                                </>
                            }}
                        // onClickRight={
                        />
                    </Col>
                    <Col className="col-12 col-sm-4">

                        <NameField
                            hideDropdown
                            outline
                            onNameChange={(v) => handleUpdate("name", v)}
                            onSalutationChange={(v) => handleUpdate("salutation", v)}
                            name={value?.name}
                            salutation={value?.salutation ? value?.salutation : "Mr"}
                            label={t("Name")}
                        />
                    </Col>
                </Row>
            </div>

            {showPaxCount ? <InputField
                type="number"
                style={{ width: "130px" }}
                label={"Total Pax"}
                outline
                value={pax}
                onChange={(v) => onPaxChange(v)}
            /> : null}

        </div>
    )

};

export default BookedBy;
