import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import { CardThreeBounce, Checkbox, InputField, SelectField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import RateNameService from "./RateNameService";
import { billingDurationData, slabCondition } from "./RateNameConstant";
import { GoPlus } from "react-icons/go";
import { BiMinus } from "react-icons/bi";
import TimeIntegerComponent from "../../components/DateSelects/TimeIntegerInput";


const EditRateNames = ({ editId, onChange = () => { }, hasErr }) => {

    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [slab, setSlab] = useState([{}]);
    const [data, setData] = useState({
        appliesOn: "PER_VEHICLE"
    });
    const fetch = async () => {
        try {
            let rateName = await RateNameService.get(editId);
            setSlab(rateName?.slabs || []);
            setData(rateName);
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        setData({
            appliesOn: "PER_VEHICLE"
        })
        setLoading(true);
        if (editId) fetch();
        setLoading(false)
    }, [editId, RateNameService?.version]);


    useEffect(() => {
        onChange(data)
    }, [data]);
    useEffect(() => {
        setData({ ...data, slabs: slab })
    }, [slab])

    const updatedValue = ({ value, index, field }) => {
        console.log({ value, index, field }, "=============");
        setSlab((prevSlab) => {
            const newSlab = [...prevSlab];
            newSlab[index][field] = value;
            return newSlab;
        });
    };


    return (
        <React.Fragment>
            {loading ?
                <CardThreeBounce /> :
                <Form
                    className="p-3"
                    style={{ height: "100%", padding: "2rem", margin: 0 }}
                >
                    <Checkbox
                        placeholder={t("isSlabRate")}
                        checked={data?.isSlabRate ? true : false}
                        value={data?.isSlabRate}
                        required={true}
                        label={t("Slab Wise Rate")}
                        onClick={(v) => {
                            setData(
                                { ...data, isSlabRate: !data?.isSlabRate }
                            );
                        }}
                        showErr={hasErr}
                        type="text"
                        className="col-12 col-md-6 col-lg-2 mt-2"
                    />
                    <InputField
                        label={t("Name")}
                        placeholder={t(" Enter Name")}
                        value={data?.name}
                        onChange={(v) => {
                            setData({ ...data, name: v })
                        }}
                        required={true}
                        className="col-sm-8"
                    />
                    <SelectField
                        data={[
                            { label: "LOCAL", value: "LOCAL" },
                            { label: "RENTAL", value: "RENTAL" },
                            { label: "TRANSFER", value: "TRANSFER" },
                            { label: "OUTSTATION", value: "OUTSTATION" },
                            { label: "PACKAGE", value: "PACKAGE" },
                            { label: "SELF_DRIVE", value: "SELF_DRIVE" },
                        ]}
                        value={data?.rateType}
                        onChange={(v) => {
                            setData({ ...data, rateType: v.value });
                        }}
                        label={t("Type")}
                        className="col-sm-8"
                    />
                    {!data?.isSlabRate && <InputField
                        label={t("Distance(Km)")}
                        placeholder={t(" Enter Distance")}
                        value={data?.allotedKMs}
                        onChange={(v) => {
                            setData({ ...data, allotedKMs: v })
                        }}
                        required={true}
                        className="col-sm-8"
                        type="number"
                    />}
                    {!data?.isSlabRate &&
                        <Col className="col-sm-8 d-flex mt-2">
                          <label style={{width:"14%"}} className="mt-2">
                          Time(Hours)
                          </label>
                          <div style={{width:"89%"}}>
                            <TimeIntegerComponent
                                label={t("Time(Hours)")}
                                placeholder={t(" Enter Time")}
                                value={data?.allotedMins}
                                onChange={(v) => {
                                    setData({ ...data, allotedMins: v })
                                }}
                                required={true}
                                type="number"
                            />
                          </div>
                        </Col>}

                    <SelectField
                        data={billingDurationData}
                        value={data?.billingDuration}
                        onChange={(v) => {
                            setData({ ...data, billingDuration: v.value })
                        }}
                        label={t("Billing Duration")}
                        placeholder={t("Billing Duration")}
                        className="col-12 col-sm-8"
                    />
                    {data?.isSlabRate && <SelectField
                        data={slabCondition}
                        value={data?.slabCondition}
                        onChange={(v) => {
                            setData({ ...data, slabCondition: v.value })
                        }}
                        label={t("Slab Condition")}
                        placeholder={t("Slab Condition")}
                        className="col-12 col-sm-8"
                    />}
                    <Checkbox
                        checkedHandleIcon={<i className="bx bx-car font-size-20" style={{ marginTop: "3px", marginLeft: "2px" }}></i>}
                        uncheckedHandleIcon={<i className="bx bx-male font-size-20" style={{ marginTop: "3px", marginLeft: "2px" }}></i>}
                        placeholder={t("appliesOn")}
                        checked={(data?.appliesOn === "PER_PAX") ? false : true}
                        value={data?.appliesOn}
                        required={true}
                        label={t("Applies On")}
                        onClick={(v) => {
                            setData(
                                { ...data, appliesOn: data?.appliesOn === "PER_PAX" || null ? "PER_VEHICLE" : "PER_PAX" }
                            );
                        }}
                        showErr={hasErr}
                        type="text"
                        className="col-12 col-md-6 col-lg-2 mt-2"
                    />

                    {data?.isSlabRate && <div>
                        <Row className="mt-4 mb-4" style={{ overflowX: "invisible" }}>
                            <table className="table-bordered rate" >
                                <thead>
                                    <tr>
                                        <th>From KM <font color="red">*</font></th>
                                        <th>Till KM <font color="red">*</font></th>
                                        <th>From Mins <font color="red">*</font></th>
                                        <th>Till Mins <font color="red">*</font></th>
                                        <th>Alloted KMs <font color="red">*</font></th>
                                        <th>Alloted Hours <font color="red">*</font></th>

                                        <th style={{ width: "0.5%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {slab?.map((item, index) => (
                                        <tr
                                            key={index}
                                        >
                                            <td >
                                                {index != 0 && <InputField
                                                    plain
                                                    outline
                                                    style={{ paddingLeft: "10px" }}
                                                    noLabel
                                                    isDisabled={index == 0}
                                                    placeholder={"From KM"}
                                                    showError={hasErr}
                                                    value={slab[index]?.fromKM}
                                                    error={
                                                        index >= 1 && slab[index]?.fromKM < slab[index - 1]?.tillKM
                                                            ? "From KM must be greater than Till KM of previous slab" : ""
                                                    }
                                                    onChange={(v) => {
                                                        updatedValue({ value: v, index, field: 'fromKM' })
                                                        updatedValue({ value: index + 1, index, field: 'index' })
                                                    }}
                                                />}
                                            </td>
                                            <td >
                                                {!(index === slab.length - 1 && slab.length !== 1) && (
                                                    <InputField
                                                        plain
                                                        outline
                                                        showError={hasErr}
                                                        noLabel
                                                        error={
                                                            slab[index]?.fromKM > slab[index]?.tillKM
                                                                ? "Till Km must be greater than From Km " : ""
                                                        }
                                                        style={{ paddingLeft: "10px" }}
                                                        placeholder={"Till KM"}
                                                        isDisabled={index === slab.length - 1 && slab.length !== 1}
                                                        value={slab[index]?.tillKM}
                                                        onChange={(v) => {
                                                            updatedValue({ value: v, index, field: 'tillKM' })
                                                            { index == 0 && updatedValue({ value: index + 1, index, field: 'index' }) }

                                                        }}
                                                    />
                                                )}

                                            </td>
                                            <td >
                                                {index != 0 && <InputField
                                                    plain
                                                    outline
                                                    noLabel
                                                    placeholder={"From Min"}
                                                    error={
                                                        index >= 1 && slab[index]?.fromKM < slab[index - 1]?.tillKM
                                                            ? "From Min must be greater than Till Min of previous slab" : ""
                                                    }
                                                    style={{ paddingLeft: "10px" }}
                                                    isDisabled={index == 0}
                                                    value={slab[index]?.fromMin}
                                                    onChange={(v) => updatedValue({ value: v, index, field: 'fromMin' })}

                                                />}
                                            </td>
                                            <td >
                                                {!(index === slab.length - 1 && slab.length !== 1) && <InputField
                                                    plain
                                                    outline
                                                    noLabel
                                                    placeholder={"Till Min"}
                                                    error={
                                                        slab[index]?.fromMin > slab[index]?.tillMin
                                                            ? "Till Min must be greater than From Min " : ""
                                                    }
                                                    style={{ paddingLeft: "10px" }}
                                                    isDisabled={index == slab.length - 1 && slab.length != 1}
                                                    value={slab[index]?.tillMin}
                                                    onChange={(v) => updatedValue({ value: v, index, field: 'tillMin' })}
                                                />}
                                            </td>
                                            <td >
                                                <InputField
                                                    plain
                                                    outline
                                                    noLabel
                                                    placeholder={"Alloted Kms"}
                                                    style={{ paddingLeft: "10px" }}
                                                    value={slab[index]?.allotedKMs}
                                                    onChange={(v) => updatedValue({ value: v, index, field: 'allotedKMs' })}

                                                />
                                            </td>
                                            <td >
                                                <TimeIntegerComponent
                                                    plain
                                                    outline
                                                    noLabel
                                                    placeholder={"Alloted Hours"}
                                                    style={{ paddingLeft: "10px" }}
                                                    value={slab[index]?.allotedMins}
                                                    onChange={(v) => updatedValue({ value: v, index, field: 'allotedMins' })}

                                                />
                                            </td>
                                            <td>   <td>
                                                <div className="d-flex justify-content-between" style={{ marginLeft: "10px" }}>
                                                    <div
                                                        style={{
                                                            marginLeft: "5px",
                                                            marginTop: "10px",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <div
                                                            className="btn btn-outline-dark d-flex align-items-center"
                                                            style={{
                                                                border: "1px solid #707070",
                                                                borderRadius: "50%",
                                                                cursor: "pointer",
                                                                padding: "1px",
                                                                // backgroundColor:"ebebeb"
                                                            }}

                                                        >
                                                            <BiMinus onClick={() => {
                                                                const newSlab = slab.filter((_, i) => i !== index);
                                                                setSlab(newSlab);
                                                            }} fontSize="16" />
                                                        </div>
                                                    </div>
                                                    {index == slab.length - 1 && <div
                                                        style={{
                                                            marginLeft: "5px",
                                                            marginTop: "10px",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <div
                                                            className="btn btn-outline-dark d-flex align-items-center"
                                                            style={{
                                                                border: "1px solid #707070",
                                                                borderRadius: "50%",
                                                                cursor: "pointer",
                                                                padding: "1px"
                                                            }}

                                                        >
                                                            <GoPlus onClick={() => setSlab([...slab, {}])} fontSize="16" />
                                                        </div>
                                                    </div>}
                                                </div></td></td>
                                        </tr>
                                    ))}
                                </tbody>
                                {/* ))} */}
                            </table>
                        </Row>
                    </div>
                    }
                    {/* {data?.isSlabRate && <Button color="light" onClick={() => setSlab([...slab, {}])} className="d-flex align-items-center" style={{ width: "120px", }}>
                        <i className="bx color-blue bxs-plus-circle font-size-20 me-2"></i>
                        {t("Add Slabs")}
                    </Button>} */}
                </Form>
            }

        </React.Fragment >
    );
};

export default observer(EditRateNames);
