import moment from 'moment';
import TagPicker from '../../components/TagSelector/TagPicker';
export const renderDate = (date) => {
  if (date) {
    const dateStr = String(date); // Ensure date is treated as a string
    const day = dateStr.substring(6, 8);
    const month = dateStr.substring(4, 6);
    const year = dateStr.substring(0, 4);
    return `${day}-${month}-${year}`;
  }
  return "";
};

export const renderTime = (date) => {
  if (!date) return "";

  const dateStr = String(date).padStart(4, '0'); // Ensure the date string is always 4 characters
  let hours = parseInt(dateStr.substring(0, 2), 10);
  const minutes = dateStr.substring(2, 4);

  const period = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12; // Convert 0 and 12 to 12 for 12-hour clock

  const formattedTime = `${String(hours).padStart(2, '0')}:${minutes}${period}`;
  return formattedTime;
};





export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Flight No",
      accessor: "flightNo",
      show: true,
    },
    {
      title: "PNR",
      accessor: "PNR",
      show: true,
    },
    {
      title: "Booking No",
      accessor: "bookingNo",
      show: true,
    },
    {
      title: "Airline",
      accessor: "airline",
      show: true,
    },
    {
      title: "From",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.fromCity || dataObj?.fromCountry ? 
          <div>
              {dataObj?.fromCity ? `${dataObj?.fromCity},` : null}{" "}{" "}<span>{dataObj?.fromCountry}{" "}</span><span>{dataObj?.fromCityCode}</span>
          </div> : <div></div>}
        </div>
      },
      show: true,
    },
    {
      title: "To",
      renderer: (dataObj) => {

        return <div>
          {dataObj?.toCity || dataObj?.toCountry ?
            <div>
              {dataObj?.toCity ? `${dataObj?.toCity},` : null}{" "}{" "}<span>{dataObj?.toCountry}{" "}</span><span>{dataObj?.toCityCode}</span>
            </div> : <div></div>}
        </div>
 
      },
      show: true,
    },
    {
      title: "Date",
      accessor: "date",
      renderer: (dataObj) => dataObj.date ? <div>{renderDate(dataObj.date ?? "") }</div> : "" ,
      show: true,
    },
    {
      title: "Time",
      accessor: "time",
      renderer: (dataObj) => dataObj.time ? <div>{renderTime(dataObj.time ?? "")}</div> : "",
      show: true,
    },
    {
      title: "Passengers",
      accessor: "passengers",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.passengers?.map((passenger, index) => (
              <div key={index}><span>{passenger?.name}</span></div>
            ))}
          </div>
        );
      },
      show: true,
    },
    {
      title: "Tags",
      accessor: "tags",
      width: 400,
      renderer: (dataObj, rowIndex, pageSize) => {
        return (!!dataObj?.tags ?
          <TagPicker
            kind={"flight"}
            data={dataObj?.tags?.filter(tag => tag && tag?.tag_id)}
          />
          : <span style={{ color: "#ebebeb" }} className="text-muted">{"No Tags Found"}</span>
        );
      },
      show: true,
    },

    {
      title: "Status",
      accessor: "status",
      renderer: (dataObj) => {
        const statusMap = {
          1: "Booked",
          2: "Cancelled",
          3: "Delayed",
        };
        return statusMap[dataObj?.status] || "-";
      },
      show: true,
    },
  

    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};



