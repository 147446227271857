import moment from "moment";
import TimesheetService from "../../Timesheet/TimesheetService";
import { TRIP_CHARGES } from "../../Booking/BookingConstant";

export const formatTripStatus = (selectedValue) => {
    switch (selectedValue) {
        case (0):
            return ({ label: "Created", value: 0 });
        case (1):
            return ({ label: "Confirmed", value: 1 });
        case (2):
            return ({ label: "Ready", value: 2 });
        case (3):
            return ({ label: "Dispatched", value: 3 });
        case (4):
            return ({ label: "Live", value: 4 });
        case (5):
            return ({ label: "Completed", value: 5 });
        case (6):
            return ({ label: "Cancelled", value: 6 });
        case (7):
            return ({ label: "Error", value: 7 });
        case (8):
            return ({ label: "Paused", value: 8 });
        case (9):
            return ({ label: "Expired", value: 9 });
        case (10):
            return ({ label: "Rejected", value: 10 });
        case (100):
            return ({ label: "All", value: 100 }); 
        case ("19|20"):
            return({ label: "Invoiced", value: "19|20" });
        case ("29|30"):
            return({ label: "Settled", value: "29|30" });
        default:
            break;
    }
    return;
}


export const checkParrangersAnonymous = (passengers) => {
    return passengers?.map(passenger => {
        return passenger?.anonymous == true ? { "anonymous": true } : passenger;
    });
};

export const toSelectDriver = (bookingTime, bookingStartDate, startTime, endTime, startDate, endDate) => {
    if (!startDate || !startTime) {
        return true;
    }
    if (bookingStartDate < startDate) {
        return false;
    }
    if (endDate && bookingStartDate > endDate) {
        return false;
    }
    if (bookingTime < startTime && bookingStartDate === startDate) {
        return false;
    }
    if (endTime && endDate && bookingTime > endTime && bookingStartDate === endDate) {
        return false;
    }
    return true;
};

export const fetchDrivers = async (vehicleId,booking) => {
    try {
        let timeSheetFilterURl = `vehicle_id=${vehicleId}`;
        await TimesheetService.fetch(timeSheetFilterURl);
        const timeSheetList = TimesheetService?.records;

        const timestamp = booking?.startTime ? booking?.startTime : Math.floor(Date.now() / 1000);
        const currentTime = parseInt(moment.unix(timestamp).format('HHmm'));
        const bookingStartDate = parseInt(moment.unix(timestamp).format('YYYYMMDD'));
        const filteredEmployeeIds = new Set();
        timeSheetList.forEach((item) => {
            item.driverDuties.forEach((duty) => {
                const startTime = duty?.start;
                const endTime = duty?.end;
                const startDate = duty?.startDate;
                const endDate = duty?.endDate;
                if (toSelectDriver(currentTime, bookingStartDate, startTime, endTime, startDate, endDate)) {
                    filteredEmployeeIds.add(duty.employee_id);
                }
            });
        });
        const filteredEmployeeIdsArray = Array.from(filteredEmployeeIds);
        if (filteredEmployeeIdsArray?.length > 0) {
            const selectedDriver = filteredEmployeeIdsArray[0];
            return selectedDriver;
        }
        return null
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getDefaultClientBilling = (trip_id) => {
    return {
      trip_id: trip_id,
      feeRate: {
        rateExtn: {
  
        }
      },
      fee: {
        feeGroups: [{
          name: TRIP_CHARGES,
        }]
      }
    }
  }