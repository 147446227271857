import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./RateNameConstant";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { getUiPrefRows } from "../../util/FilterUtil";
import { IoTime } from "react-icons/io5";
import { GiPathDistance } from "react-icons/gi";
import moment from 'moment';

const minuteConversion = (totalMinutes) => {
    const duration = moment.duration(totalMinutes, 'minutes');
    const days = Math.floor(totalMinutes / (24 * 60));
    const hours = duration.hours();
    const minutes = duration.minutes();

    let formattedTime = '';

    if (days > 0) {
        formattedTime += `${days} day `;
    }

    if (hours > 0) {
        formattedTime += `${hours} hr `;
    }

    if (minutes > 0) {
        formattedTime += `${minutes} min`;
    }
    return formattedTime;
}

class Service {
    records = [];
    STRUCTURE = [];
    page = 1;
    rowsPerPage = null;
    filterOption = "";
    total = -1;
    version = 0;
    cacheRateName = [];
    constructor() {
        makeAutoObservable(this);
    }

    fetch = async (filterUrl) => {
        if (!this?.rowsPerPage) {
            const rows = await getUiPrefRows("rateName.grid")
            this.rowsPerPage = rows ?? 40
        }

        if (filterUrl) {
            this.filterOption = filterUrl;
        }
        const response = await doGET(
            ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl)
        );

        if (response?.status === 200) {
            this.records = response.data.rows;
            this.page = response?.data?.page;
            if (this.page === 1) this.total = response?.data?.total;
        }
    };

    fetchAll = async function (isSlabRate) {
        // if (this.cacheRateName.length > 0 ) {
        //     return this.cacheRateName
        // }
        try {
            const response = await doGET(ENDPOINTS.getAllRateNames(isSlabRate));
            if (response.status === 200) {
                const formattedRateName = response.data.rows.map((v) => {
                    return {
                        ...v, 
                        label: (
                            <div className="ps-2" >
                                <div>
                                    <div style={{ verticalAlign: 'top' }}>{v?.name}</div>
                                </div>
                                <div className="d-flex">
                                    {v?.allotedKMs > 0 && (
                                        <div className="me-2" style={{ fontSize: '11px', verticalAlign: 'top' }}>
                                            <div >{v?.allotedKMs}KM</div>
                                        </div>
                                    )}
                                    {v?.allotedMins > 0 && (
                                        <div style={{ fontSize: '11px', verticalAlign: 'top' }}>
                                            <div >{minuteConversion(v?.allotedMins)}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                        , value: v._id
                    };
                });
                this.cacheRateName = formattedRateName;
                return formattedRateName;
                console.log(response);
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };

    edit = async function (data, id) {
        await doPUT(ENDPOINTS?.update, data);
        this.version += 1;
        this.cacheRateName = [];
        this.fetch();

    };

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    save = async (data) => {
        // this.records.push(data);
        const response = await doPOST(ENDPOINTS.save, data);
        this.cacheRateName = [];
        this.fetch();
        return response?.data?._id;
    };

    delete = async (id) => {
        const response = await doDELETE(ENDPOINTS.delete(id));
        if (response.status === 200) {
            this.cacheRateName = [];
            this.fetch();
        }
    };

    bulkDelete = async (ids = []) => {
        const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
        if (response.status === 200) {
            this.fetch();
        }
    };

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch(this.filterOption);

    };

}

const RateNameService = new Service();
export default RateNameService;

