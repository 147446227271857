import { MakeModelSelect, SupplierSelect, VehicleTypeSelect } from "../../components";
import { FaCopy } from "react-icons/fa";
import toastr from "toastr";
import { copyToClipboard } from "../../util/Util";

export const GridColumns = {
    version: 1,
    columns: [

        {
            title: "Reg No",
            accessor: "regNo",

            show: true,
            required: true,
        },
        {
            title: "Supplier",
            accessor: "",
            renderer: (dataObj) => {
                return (
                    dataObj?.supplier_id ? <SupplierSelect
                        asLabel
                        value={dataObj?.supplier_id}
                        onChange={(v) => { }}
                        className=""
                    /> : <div>--</div>
                )
            },
            show: true,
        },
        {
            title: "Driver Assigned",
            accessor: "assignedDriverName",
            renderer: (dataObj) => {
                return (
                    dataObj?.assignedDriverName ?<div>{dataObj?.assignedDriverName}</div> : <div>--</div>
                )
            },
            show: true,
        },
        {
            title: "Driver Phone",
            accessor: "assignedDriverPhone",
            renderer: (dataObj, rowIndex, pageSize) => {
                const copyText = dataObj?.assignedDriverPhone?.phone ? `${dataObj?.assignedDriverPhone.countryCode ? dataObj?.assignedDriverPhone.countryCode : ''} - ${dataObj?.assignedDriverPhone.phone}` : null
                const contentToCopy = dataObj?.assignedDriverPhone?.phone ? `${dataObj?.assignedDriverPhone.phone}` : null
                return (
                    <>
                        {copyText}
                        {copyText ? <div className="ps-2 cursor-pointer text-black" onClick={() => copyToClipboard(contentToCopy)}><FaCopy /> </div> : null}
                    </>
                )

            },
            show: true,
        },

        {
            title: "Make Model",
            renderer: (dataObj) => {
                return (
                    <MakeModelSelect
                        asLabel
                        value={dataObj?.makeModel}
                        onChange={(v) => { }}
                    />
                )
            },
            show: true,
        },
        // {
        //     title: "Vehicle Type",
        //     show: true,
        //     renderer: (dataObj) => {
        //         return <div>
        //             <VehicleTypeSelect
        //                 asLabel
        //                 value={dataObj?.vehicleType}
        //                 onChange={(v) => { }}
        //                 className=""
        //             />
        //         </div>
        //     },
        // },
        {
            title: "Status",
            renderer: (dataObj) => {
                let text = "";
                let color = "";

                if (dataObj?.status === 1) {
                    text = "Active";
                    color = '#2ecc71'
                } else if (dataObj?.status === 2) {
                    text = "InActive";
                    color = '#e74c3c'
                } else if (dataObj?.status === 3) {
                    text = "Under Maintanence";
                    color = '#40E0D0'
                } else if (dataObj?.status === 4) {
                    text = "Sold";
                    color = '#CCCCFF'
                } else if (dataObj?.status === 5) {
                    text = "Dispatched";
                    color = '#FFBF00'
                } else if (dataObj?.status === 6) {
                    text = "On Duty";
                    color = '#FF7F50'
                } else if (dataObj?.status === 7) {
                    text = "Off Duty";
                    color = '#8e44ad'
                }
                return <div>
                    <div className="d-flex align-items-center">
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                backgroundColor: color,
                                marginRight: '5px',

                            }}
                        ></div>
                        <div>{text}</div>
                    </div>
                    {/* {dataObj.status === 1 ? "Active" : dataObj.status === 2 ? "Inactive" : dataObj.status === 3 ? "Under Maintanence" : dataObj?.status === 4 ? "Sold" : null} */}
                </div>;
            },
            show: true,
            required: true,
        },
        {
            title: "Compliance  Status",
            renderer: (dataObj) => {
                let text = "--";
                let color = "";

                if (dataObj?.complianceStatus == 1) {
                    text = "Compliant";
                    color = '#52BE80'
                } else if (dataObj?.complianceStatus == 2) {
                    color = '#CB4335'
                    text = "Not Compliant";
                }
                else if (dataObj?.complianceStatus == 3) {
                    text = "Due";
                    color = '#FFBF00'
                }
                return <div>
                    <div className="d-flex align-items-center">
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                backgroundColor: color,
                                marginRight: '5px',

                            }}
                        ></div>
                        <div>{text ?? "--"}</div>
                    </div>
                </div>;
            },
            show: true,
            required: true,
        },
        {
            title: "Year",
            accessor: "year",
            show: true,
        },

        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 120,
            show: true,
            required: true
        },
    ]
};