import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../Constant";
class RateItemSelect {
  rateItem = [];
  constructor() {
    makeAutoObservable(this);
  }
  fetch = async function () {
    if (!this.rateItem || this.rateItem.length === 0) {
      try {
        const response = await doGET(ENDPOINTS.getAllRateItemName);
        if (response.status === 200) {

          this.rateItem = response.data;
        } else {
          this.error = response.data;
        }
      } catch (err) {
        this.error = err;
      }
    }
  };
  getRateItems = async function () {
    if (this.rateItem && this.rateItem.length > 0) {
      return this.rateItem;
    }
    await this.fetch();
    return this.rateItem;
  }
}
const RateItemService = new RateItemSelect();
export default RateItemService;
