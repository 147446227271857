export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/lead/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/lead/${id}/detail`,
  save: `/api/lead/create`,
  delete: (id) => `/api/lead/${id}/delete`,
  bulkDelete: (id) => `/api/lead/delete`,
  update: `/api/lead/update`,
};

export const SELECTSTRUCTURE = {
  corpType: [
    { label: "Client", value: "Client" },
    { label: "Transporter", value: "Transporter" },   
  ],
};



export const STRUCTURE = [
  {
    label: "Lead Code",
    filterLabel: "Lead Code",
    filterName: "code",
    type: "text",
  },
  {
    label: "Lead Name",
    filterLabel: "Lead Name",
    filterName: "address.name",
    type: "text",
  },
  {
      label: "Has Self Drive ",
      filterLabel: "Has Self Drive",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.hasSelfDrive ? 'true' : 'false'}
        </div>;
      }, 
      filterName: "hasSelfDrive",
      type: "text",
    },
];
