
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  CheckButton,
  DeleteLink,
  SaveButton,
  DeleteButton,
  EditLink,
  Layout,
} from "../../components";
import { Col, Row } from "reactstrap";
import "../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { STRUCTURE } from "./ConvertCurrencyConstant";
import ConvertCurrencyService from "./ConvertCurrencyService";
import EditConvertCurrency from "./EditConvertCurrency";

const ConvertCurrency = (props) => {
  const { t } = useContext(I18nContext);
  let { id } = useParams();
  if (props?.corpId) id = props?.corpId;
  const { showMessage, showConfirm, showError } = useContext(DialogContext);
  const [hasErr, setHasErr] = useState(false);
  const [data, setData] = useState({});
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(
    props?.corpId ? false : id
  );

  const [loading, setLoading] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);

  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await ConvertCurrencyService.fetch(filterUrl, props?.corpId);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    setHasErr(true);

    setLoading(true);
    try {
      if (id) {
        await ConvertCurrencyService.edit(data, id);
        showMessage("Updated successfully");
      }
      else {
        const response = await ConvertCurrencyService.save(data);


        ConvertCurrencyService.records.push(data);
        showMessage("Saved successfully");
        if (!props?.insidePane) navigate(`/convertcurrency/edit/${response?.data?._id}`);
      }
      fetchData();
      setLoading(false)
    } catch (e) {
      showError(e)
    }
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete ConvertCurrency ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        await ConvertCurrencyService.delete(id);
      } catch (e) {
        showError(e);
      }
    }
  };

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    setShowDetailPage(
      window.location.pathname === "/convertcurrency/edit" || (id && !props?.corpId)
    );
  }, [id]);



  const onRowClick = (id) => {
    if (!props?.insidePane) navigate(`/convertcurrency/edit/${id}`);
    setSelected_id(id);
    setShowDetailPage(true);
    setIsEditDataChanged(false);
  };
  const handleRowClick = async (convertcurrencyId) => {
    console.log(convertcurrencyId, "convertcurrencyID");
    if (showDetailPage && convertcurrencyId !== selected_id && isEditDataChanged) {
      let isProceed = await showConfirm({
        isSaveWarn: true,
        title: t(`Do you want to save the changes ?`),
        description: t("Your changes will be lost if you don't save them"),
      });
      if (isProceed) onRowClick(convertcurrencyId);
    } else onRowClick(convertcurrencyId);
  };

  if (loading) return <CardThreeBounce />;
  return (
    <Layout
      showDetailPage={showDetailPage}
       
      title={t("ConvertCurrency")}
      filterStructure={STRUCTURE}
      backDetailPage={() => {
        setShowDetailPage(false);
        if (!props?.insidePane) navigate("/convertcurrency");
        setSelected_id(false);
      }}
      onApplyFilter={fetchData}
      onAddClick={() => {
        if (!props?.insidePane) navigate(`/convertcurrency/edit`);
        setShowDetailPage(true);
        setSelected_id(null);
      }}
      page={ConvertCurrencyService.page}
      rows={ConvertCurrencyService.rows}
      total={ConvertCurrencyService.totalRecords}
      onPageChange={async (page, rows) => {
        await ConvertCurrencyService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("From")}</Th>
            <Th>{t("Target")}</Th>
            <Th>{t("Conversion Rate")}</Th>
            <Th>{t("Markup")}</Th>

            <Th className="float-end">{t("Action")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {ConvertCurrencyService.records?.map((convertcurrency) => (
            <Tr
              key={convertcurrency?._id}
              onClick={showDetailPage && (() => handleRowClick(convertcurrency?._id))}
            >
              <Td >{convertcurrency?.from}</Td>
              <Td >{convertcurrency?.target}</Td>
              <Td >{convertcurrency?.conversionRate}</Td>

              <Td >{convertcurrency?.manual ? "Manual" : convertcurrency?.markup ? convertcurrency?.markup + " %" : "0 %"}</Td>


              <Td className="float-end">
                <EditLink
                  onClick={() => {
                    if (!props?.insidePane)
                      navigate(`/convertcurrency/edit/${convertcurrency?._id}`);
                    setSelected_id(convertcurrency?._id);
                    setShowDetailPage(true);
                  }}
                />
                <DeleteLink
                  onClick={(event) => {
                    onDelete(event, convertcurrency?._id);
                  }}
                />

                {props?.selectMode ? (
                  <CheckButton
                    onClick={() => {
                      props?.onSelect([{ ...convertcurrency }]);
                    }}
                  />
                ) : null}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>

      <Layout.DetailPageTitle >
        {/* <Row>
          <Row className="mb-2 py-2 border-bottom-1"> 
           <Col>
              <div>{data?.name}</div>
              <div>{data?.phone}</div>
            </Col>
          </Row>
        </Row> */}
        <h5>ConvertCurrency</h5>
      </Layout.DetailPageTitle>

      <Layout.DetailPageBody>
        <EditConvertCurrency
          insidePane={props?.insidePane}
          onChange={(v) => setIsEditDataChanged(v)}
          id={selected_id}
          back={() => {
            setShowDetailPage(false);
            if (!props?.insidePane) navigate("/convertcurrency");
            setSelected_id(false);
          }}
          data={data}
          setData={setData}
          hasErr={hasErr}
          setHasErr={setHasErr}
          fetchData={fetchData}
        />
      </Layout.DetailPageBody>

      <Layout.DetailPageFooter>
        <Row>
          <Col>
            <div
              className="d-flex justify-content-end mt-1"
              style={{ marginTop: "-2.5rem" }}
            >
              {data?._id && <DeleteButton onClick={(e) => onDelete(e, data?._id)} />}
              <SaveButton onClick={submitHandler} />
            </div>
          </Col>
        </Row>
      </Layout.DetailPageFooter>

    </Layout>
  );
};

export default observer(ConvertCurrency);
