import { CorporateSelect } from "../../components";

export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Corporate",
      accessor: "corp_id",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.corp_id != null ?
            <b>
              <CorporateSelect
                asLabel
                value={dataObj?.corp_id}
                className="col-sm-4"
                style={{ zIndex: '4' }}
              />
            </b> :
            <b>All</b>
          }
        </div>;
      },
      show: true,
      width: 200,
    },
    {
      title: "Domain",
      accessor: "corp_id",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.corp_id != null ?
            <b>
              <CorporateSelect
                asDomain
                value={dataObj?.corp_id}
                className="col-sm-4"
                style={{ zIndex: '4' }}
              />
            </b> :
            <b>All</b>
          }
        </div>;
      },
      show: true,
      width: 200,
    },
    {
      title: "Language",
      accessor: "lang",
      width: 100,
      show: true,
    },
    {
      title: "Platform",
      accessor: "platform",
      width: 150,
      show: true,
    },
    {
      title: "MessageType",
      accessor: "messageType",
      width: 300,
      show: true,
    },
    {
      title: "Content",
      accessor: "content",
      show: true,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
