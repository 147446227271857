import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import "./style.css";
import { FaRegCircle, FaCheckCircle } from "react-icons/fa";

const Stepper = ({
    onChange = () => { },
    value = 1,
    data = [],
}) => {


    const handleStatusClick = (status) => {
        onChange(status.value);
    };

    const isActive = useCallback((status) => {
        return (value === status.value || value === status.otherValue)
    }, [value, data, onChange]);

    return (
        <div key={value} className=''>
            <div className="fv_status_progress">
                {data.map((status) => (
                    <div
                        key={status.value}
                        tabIndex={0}
                        onClick={(e) => {
                            if (status.value <= value || !status?.selectable(value)) return;
                            handleStatusClick(status)
                        }}
                        // className={`fv_status_progress_segment ${status.value < value ? "complete" : ""}  `}
                        className="fv_status_progress_segment"
                        style={{
                            // backgroundColor: status.backgroundColor,
                            fontWeight: (isActive(status)) ? '600' : '400',
                            fontSize: (isActive(status)) ? 13 : 12,
                            backgroundColor: (isActive(status)) ? status.backgroundColor : 'none',
                            color: isActive(status) ? status.color : '',
                        }}
                    >
                        {status.value > value ? <FaRegCircle style={{ color: status.backgroundColor }} size={14} /> : null}
                        {status.value < value ? <FaCheckCircle style={{ color: status.backgroundColor }} size={14} /> : null}
                        {status.value == value ? <FaCheckCircle style={{ color: "white" }} size={14} /> : null}
                        &nbsp;&nbsp;{status.label}
                    </div>
                ))}
            </div>

        </div>
    );
};

export default observer(Stepper);
