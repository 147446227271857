import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { CardThreeBounce, InputField, ToggleSelector } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import Roleservice from "./RoleService";

function GroupedCheckboxes({ rights, onChange, selectedRights = [] }) {

    const dataarray = rights;
    if (!rights || !Array.isArray(rights)) {
        return null

    }
    const groupedData = {};
    dataarray?.forEach(item => {
        const groupName = item.group;
        if (!groupedData[groupName]) {
            groupedData[groupName] = [];
        }
        groupedData[groupName].push(item);
    });

    const isSelected = (right) => {
        return selectedRights.includes(right);
    }
    const toggleRight = (right) => {
        if (isSelected(right)) {
            onChange(selectedRights.filter(r => r !== right));
        } else {
            onChange([...selectedRights, right]);
        }
    }

    return (
        <div>
            {Object.keys(groupedData).map(groupName => (
                <div key={groupName}>
                    <div >
                        <h2>{groupName}</h2>
                    </div>
                    <div style={{ border: '1px solid #ccc', marginBottom: '30px', borderRadius: '5px' }}>
                        {groupedData[groupName].map(item => (

                            <div key={item.code} style={{ borderBottom: '1px solid #DFDFDF', marginBottom: '15px', marginTop: '15px' }}>
                                <Row style={{ padding: '10px' }}>
                                    <div style={{ display: 'flex' }} className="col-sm-4" >

                                        <input
                                            type="checkbox"
                                            id={`item-${item.name}`}
                                            name={`item-${item.name}`}
                                            value={item.name}
                                            checked={isSelected(item.code)}
                                            onClick={() => {
                                                toggleRight(item.code)
                                            }}

                                        />
                                        <h3 style={{ marginLeft: '10px', fontWeight: 'bold', font: '15PX' }}>{item.name}</h3>
                                    </div>
                                    <div
                                        className="col-sm-8"
                                        style={{ fontSize: '14px', color: '#555' }}></div>
                                </Row>
                                <Row style={{ padding: '10px' }}>
                                    <div className="col-md-4 col-12 mb-2">{item.description}</div>
                                    <Row className="d-flex col-sm-8">
                                        {item.rights && Array.isArray(item.rights) && item.rights.map(right => (
                                            <label className="col-4" style={{ display: 'flex' }} key={right.value}>
                                                <input
                                                    style={{ marginRight: '10px', display: 'flex' }}
                                                    type="checkbox"
                                                    id={`right-${right.value}`}
                                                    name={`right-${right.value}`}
                                                    value={right.value}
                                                    checked={isSelected(right.value)}
                                                    onClick={() => toggleRight(right.value)}
                                                />
                                                <div>
                                                    {right.name}
                                                </div>
                                            </label>
                                        ))}
                                    </Row>
                                </Row>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}



const EditRoles = ({ editId, onChange = () => { }, hasErr }) => {
    const { t } = useContext(I18nContext);
    const { userContent, forSuperadmin, setForSuperadmin } = useContext(UserContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [allRights, setAllRights] = useState([]);

    const fetch = async () => {
        setLoading(true);
        try {
            let roles = await Roleservice.get(editId);
            setData(roles);
            // setRightsselected(roles.rights || []);
        } catch (error) {
            showError(error)
        }
        setLoading(false)
    };

    useEffect(() => {
        setData(null)
        setLoading(true);
        if (editId) fetch();
        setLoading(false)
    }, [editId, Roleservice.version]);

    useEffect(() => {
        onChange(data)
    }, [data]);

    const fetchallRights = async () => {
        try {
            let roles = await Roleservice.getallRights(editId, forSuperadmin);
            setAllRights(roles);
        } catch (error) {
            showError(error)
        }
    };
    useEffect(() => {
        fetchallRights()
    }, [forSuperadmin]);

    useEffect(() => {
        setData({
            ...data,
            forCpanel: forSuperadmin,
        })
    }, [forSuperadmin]);

    return (
        <React.Fragment>
            {loading ?
                <CardThreeBounce /> :
                <Form
                    className="p-3"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                >
                    <Row>
                        {(userContent?.userType === "super-admin") && <ToggleSelector
                            data={[
                                { label: "For Supervisor", value: "supervisor" },
                                { label: "For Superadmin", value: "super-admin" },
                            ]}
                            className=" w-50"
                            style={{ width: "15%", position: "relative", top: "2.5px" }}
                            value={forSuperadmin ? "superadmin" : "supervisor"}
                            onChange={(v) => { v === "super-admin" ? setForSuperadmin(true) : setForSuperadmin(false) }}
                        />
                        }
                    </Row>
                    <Row className="mt-3 mb-3">
                        <InputField
                            placeholder={t("Role Name")}
                            value={data?.name}
                            required={true}
                            label={t("Role Name")}
                            onChange={(v) => setData({ ...data, name: v })}
                            error={data?.name?.length === 0 ? t("Please enter Role Name") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-md-4  col-12 mb-5 "
                        />
                        <GroupedCheckboxes
                            onChange={(v) => {
                                setData({ ...data, rights: v });
                            }}
                            selectedRights={data?.rights}
                            rights={allRights}
                        />
                    </Row>
                </Form>
            }
        </React.Fragment>
    );
};

export default observer(EditRoles);
