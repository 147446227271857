
import { observer } from "mobx-react-lite";

import React, { useContext, useEffect, useState } from "react";

import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Button, Col, Row } from "reactstrap";

import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  Checkbox,
  DeleteLink,
  LanguageSelect,
  Layout,
} from "../../../../components";
import "../../../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../../../store/context/DialogContext";
import { I18nContext } from "../../../../store/context/i18nContext";

import LanguagesService from "./LanguagesService";

const Languages = (props) => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [languages, setlanguages] = useState([]);

  const { showMessage } = useContext(DialogContext);
  const [hasErr, setHasErr] = useState(false);

  // const toggle = () => setModal(!modal);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!data.languageMaps) {
      showError("No Language Present");
      return;
    }
    let check = false;
    data?.languageMaps?.map(e => {
      if (!e?.language) {
        check = true;
      }
    })
    if (check) {
      showError("Language Cannot be Empty");
      return;
    }
    setLoading(true);
    try {
      await LanguagesService.save(data);
      showMessage("Languages saved successfully");
      fetchData()
    } catch (e) {
      showError(e)
    }
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await LanguagesService.get();
      setData(response);
      setHasErr(false);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    setHasErr(false);
  }, []);

  const onDelete = async (event, idx) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Delete Language ?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      try {
        setData({
          ...data,
          languageMaps: data?.languageMaps?.filter((e, i) => i !== idx)
        })
        // languages.splice(idx, 1);
        // setlanguages([...languages]);
        showMessage("Language Deleted Successfully");
      } catch (e) {
        showError(e);
      }
    }
  };

  if (loading) return <CardThreeBounce />;
  return (
    <>
      <Layout
        layoutForm={true}
         
        title={t("Languages")}
        onAddClick={() => {
          setData({ ...data, languageMaps: [...data?.languageMaps, {}] });
        }}
      >
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>{t("Language")}</Th>
              <Th>{t("Default")}</Th>
              <Th className="float-end">{t("Action")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {data?.languageMaps?.map((l, idx) => (
              <Tr key={idx} className='py-2'>
                <Td>
                  <LanguageSelect
                    className="col-sm-8"
                    value={l?.language}
                    onChange={(v) =>
                      setData({
                        ...data,
                        languageMaps: data?.languageMaps?.map((el, i) => {
                          if (i === idx) return { ...el, language: v?.value }
                          return el;
                        })
                      })
                    }
                  />
                </Td>
                <Td>
                  <Checkbox
                    label="Default"
                    checked={l?.isDefault}
                    onClick={() =>
                      setData({
                        ...data,
                        languageMaps: data?.languageMaps?.map((el, i) => {
                          if (i === idx) return { ...el, isDefault: true }
                          return { ...el, isDefault: false };
                        })
                      })
                    }
                  />
                </Td>

                <Td className=" mt-2 float-end">
                  <DeleteLink
                    onClick={(event) => {
                      onDelete(event, idx);
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout>

      {data?.languageMaps?.length > 0 && <div className="d-flex justify-content-end  ">
        <div className="me-2">
          <Button type="submit" color="primary" onClick={submitHandler}>
            Save
          </Button>
        </div>
      </div>}
    </>
  );
};

export default observer(Languages);
