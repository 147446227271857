import { observer } from "mobx-react-lite";

import React, { useContext, useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Row, Table } from "reactstrap";

import "toastr/build/toastr.min.css";
import { CardThreeBounce, Checkbox, InputField } from "../../../../components";
import "../../../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../../../store/context/DialogContext";
import { I18nContext } from "../../../../store/context/i18nContext";

import RouteSettingsService from "./RouteSettingsService";

const RouteSettings = (props) => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [routesettings, setroutesettings] = useState([]);

  const { showMessage } = useContext(DialogContext);
  const [hasErr, setHasErr] = useState(false);

  const submitHandler = async (e) => {
    console.log("submit handler");
    e.preventDefault();

    setLoading(true);

    try {
      data.languageMaps = routesettings;

      setData(() => {
        return {
          ...data,
        };
      });

      await RouteSettingsService.save(data);
      console.log("xsnkdnskn");

      showMessage("RouteSettings saved successfully");
      setLoading(false);
    } catch (e) {
      showError(e)
      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await RouteSettingsService.get();
      setData(response);

      setHasErr(false);
      setLoading(false);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    fetchData();
    setHasErr(false);
  }, []);

  if (loading) return <CardThreeBounce />;
  return (
    <div className="p-4 mt-3">
      <Row className="mt-5">
        <Col
          className="col-lg  mt-3"
          style={{
            backgroundColor: "#EAEAEA",
            fontSize: "15px",
            borderBottom: "1px solid rgba(0,0,0,.1)",
          }}
        >
          <header>Route Settings</header>
        </Col>
      </Row>

      <Row className="mt-3 mb-3">
        <Col className="col-lg">
          <Checkbox
            label="Driver can mark passenger has boarded ride, in app without OTP verification"
            checked={data?.driverCanMarkOnboard}
            onClick={() => {
              setData({
                ...data,
                driverCanMarkOnboard: !data?.driverCanMarkOnboard,
              });
            }}
          />
        </Col>
      </Row>

      <Row className="mt-3 mb-3">
        <Col className="col-lg">
          <Checkbox
            label="Confirm Female Pax Exit (If pax not dropped in 100m radius)"
            checked={data?.confirmExitFromPax}
            onClick={() => {
              setData({
                ...data,
                confirmExitFromPax: !data?.confirmExitFromPax,
              });
            }}
          />
        </Col>
      </Row>

      <Row className="mt-3 mb-3">
        <Col className="col-lg">
          <InputField
            placeholder="Route Forced Stop Interval"
            value={data?.stop_route_interval}
            label={"Force stop regular route after this duration (mins) passed"}
            onChange={(v) =>
              setData({
                ...data,
                stop_route_interval: v,
              })
            }

            type="text"
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col
          className="col-12 p-2 ps-4 "
          style={{
            backgroundColor: "#EAEAEA",
            fontSize: "15px",
            borderBottom: "1px solid rgba(0,0,0,.1)",
          }}
        >
          <header>Email/SMS Settings</header>
        </Col>
        <Table striped bordered hover responsive>
          <tbody>
            <tr>
              <td rowspan="2">Type</td>
              <td colSpan="2">Email</td>
              <td colSpan="3">SMS</td>
            </tr>
            <tr>
              <td>Customer</td>
              <td>Booker</td>
              <td>Customer</td>
              <td>Booker</td>
              <td>Driver</td>
            </tr>

            <tr>
              <td>Routing Confirmation</td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyCustomer.confirmEmail"
                    name="routeNotifyCustomer.confirmEmail"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyCustomer: {
                          ...data.routeNotifyCustomer,
                          confirmEmail:
                            !data?.routeNotifyCustomer?.confirmEmail,
                        },
                      });
                    }}
                    checked={data?.routeNotifyCustomer?.confirmEmail}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyBooker.confirmEmail"
                    name="routeNotifyBooker.confirmEmail"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyBooker: {
                          ...data.routeNotifyBooker,
                          confirmEmail: !data?.routeNotifyBooker?.confirmEmail,
                        },
                      });
                    }}
                    checked={data?.routeNotifyBooker?.confirmEmail}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyCustomer.confirmSMS"
                    name="routeNotifyCustomer.confirmSMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyCustomer: {
                          ...data.routeNotifyCustomer,
                          confirmSMS: !data?.routeNotifyCustomer?.confirmSMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyCustomer?.confirmSMS}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyBooker.confirmSMS"
                    name="routeNotifyBooker.confirmSMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyBooker: {
                          ...data.routeNotifyBooker,
                          confirmSMS: !data?.routeNotifyBooker?.confirmSMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyBooker?.confirmSMS}
                  />
                </FormGroup>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Vehicle Assigned</td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyCustomer.vehAssignEmail"
                    name="routeNotifyCustomer.vehAssignEmail"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyCustomer: {
                          ...data.routeNotifyCustomer,
                          vehAssignEmail:
                            !data?.routeNotifyCustomer?.vehAssignEmail,
                        },
                      });
                    }}
                    checked={data?.routeNotifyCustomer?.vehAssignEmail}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyBooker.vehAssignEmail"
                    name="routeNotifyBooker.vehAssignEmail"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyBooker: {
                          ...data.routeNotifyBooker,
                          vehAssignEmail:
                            !data?.routeNotifyBooker?.vehAssignEmail,
                        },
                      });
                    }}
                    checked={data?.routeNotifyBooker?.vehAssignEmail}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyCustomer.vehAssignSMS"
                    name="routeNotifyCustomer.vehAssignSMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyCustomer: {
                          ...data.routeNotifyCustomer,
                          vehAssignSMS:
                            !data?.routeNotifyCustomer?.vehAssignSMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyCustomer?.vehAssignSMS}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyBooker.vehAssignSMS"
                    name="routeNotifyBooker.vehAssignSMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyBooker: {
                          ...data.routeNotifyBooker,
                          vehAssignSMS: !data?.routeNotifyBooker?.vehAssignSMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyBooker?.vehAssignSMS}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyDriver.vehAssignSMS"
                    name="routeNotifyDriver.vehAssignSMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyDriver: {
                          ...data?.routeNotifyDriver,
                          vehAssignSMS: !data?.routeNotifyDriver?.vehAssignSMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyDriver?.vehAssignSMS}
                  />
                </FormGroup>
              </td>
            </tr>
            <tr>
              <td>Vehicle Dispatched</td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyCustomer.vehDispatchEmail"
                    name="routeNotifyCustomer.vehDispatchEmail"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyCustomer: {
                          ...data.routeNotifyCustomer,
                          vehDispatchEmail:
                            !data?.routeNotifyCustomer?.vehDispatchEmail,
                        },
                      });
                    }}
                    checked={data?.routeNotifyCustomer?.vehDispatchEmail}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyBooker.vehDispatchEmail"
                    name="routeNotifyBooker.vehDispatchEmail"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyBooker: {
                          ...data?.routeNotifyBooker,
                          vehDispatchEmail:
                            !data?.routeNotifyBooker?.vehDispatchEmail,
                        },
                      });
                    }}
                    checked={data?.routeNotifyBooker?.vehDispatchEmail}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyCustomer.vehDispatchSMS"
                    name="routeNotifyCustomer.vehDispatchSMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyCustomer: {
                          ...data?.routeNotifyCustomer,
                          vehDispatchSMS:
                            !data?.routeNotifyCustomer?.vehDispatchSMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyCustomer?.vehDispatchSMS}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyBooker.vehDispatchSMS"
                    name="routeNotifyBooker.vehDispatchSMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyBooker: {
                          ...data.routeNotifyBooker,
                          vehDispatchSMS:
                            !data?.routeNotifyBooker?.vehDispatchSMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyBooker?.vehDispatchSMS}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyDriver.vehDispatchSMS"
                    name="routeNotifyDriver.vehDispatchSMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyDriver: {
                          ...data?.routeNotifyDriver,
                          vehDispatchSMS:
                            !data?.routeNotifyDriver?.vehDispatchSMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyDriver?.vehDispatchSMS}
                  />
                </FormGroup>
              </td>
            </tr>
            <tr>
              <td>Journey Start</td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyCustomer.onJourneyEmail"
                    name="routeNotifyCustomer.onJourneyEmail"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyCustomer: {
                          ...data.routeNotifyCustomer,
                          onJourneyEmail:
                            !data?.routeNotifyCustomer?.onJourneyEmail,
                        },
                      });
                    }}
                    checked={data?.routeNotifyCustomer?.onJourneyEmail}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyBooker.onJourneyEmail"
                    name="routeNotifyBooker.onJourneyEmail"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyBooker: {
                          ...data.routeNotifyBooker,
                          onJourneyEmail:
                            !data?.routeNotifyBooker?.onJourneyEmail,
                        },
                      });
                    }}
                    checked={data?.routeNotifyBooker?.onJourneyEmail}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyCustomer.onJourneySMS"
                    name="routeNotifyCustomer.onJourneySMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyCustomer: {
                          ...data.routeNotifyCustomer,
                          onJourneySMS:
                            !data?.routeNotifyCustomer?.onJourneySMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyCustomer?.onJourneySMS}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyBooker.onJourneySMS"
                    name="routeNotifyBooker.onJourneySMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyBooker: {
                          ...data.routeNotifyBooker,
                          onJourneySMS: !data?.routeNotifyBooker?.onJourneySMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyBooker?.onJourneySMS}
                  />
                </FormGroup>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Journey Completion</td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyCustomer.tripCompleteEmail"
                    name="routeNotifyCustomer.tripCompleteEmail"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyCustomer: {
                          ...data.routeNotifyCustomer,
                          tripCompleteEmail:
                            !data?.routeNotifyCustomer?.tripCompleteEmail,
                        },
                      });
                    }}
                    checked={data?.routeNotifyCustomer?.tripCompleteEmail}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyBooker.tripCompleteEmail"
                    name="routeNotifyBooker.tripCompleteEmail"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyBooker: {
                          ...data.routeNotifyBooker,
                          tripCompleteEmail:
                            !data?.routeNotifyBooker?.tripCompleteEmail,
                        },
                      });
                    }}
                    checked={data?.routeNotifyBooker?.tripCompleteEmail}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyCustomer.tripCompleteSMS"
                    name="routeNotifyCustomer.tripCompleteSMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyCustomer: {
                          ...data.routeNotifyCustomer,
                          tripCompleteSMS:
                            !data?.routeNotifyCustomer?.tripCompleteSMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyCustomer?.tripCompleteSMS}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyBooker.tripCompleteSMS"
                    name="routeNotifyBooker.tripCompleteSMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyBooker: {
                          ...data.routeNotifyBooker,
                          tripCompleteSMS:
                            !data?.routeNotifyBooker?.tripCompleteSMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyBooker?.tripCompleteSMS}
                  />
                </FormGroup>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>On Amendment</td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyCustomer.ammendmentEmail"
                    name="routeNotifyCustomer.ammendmentEmail"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyCustomer: {
                          ...data.routeNotifyCustomer,
                          ammendmentEmail:
                            !data?.routeNotifyCustomer?.ammendmentEmail,
                        },
                      });
                    }}
                    checked={data?.routeNotifyCustomer?.ammendmentEmail}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyBooker.ammendmentEmail"
                    name="routeNotifyBooker.ammendmentEmail"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyBooker: {
                          ...data.routeNotifyBooker,
                          ammendmentEmail:
                            !data?.routeNotifyBooker?.ammendmentEmail,
                        },
                      });
                    }}
                    checked={data?.routeNotifyBooker?.ammendmentEmail}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyCustomer.ammendmentSMS"
                    name="routeNotifyCustomer.ammendmentSMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyCustomer: {
                          ...data.routeNotifyCustomer,
                          ammendmentSMS:
                            !data?.routeNotifyCustomer?.ammendmentSMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyCustomer?.ammendmentSMS}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyBooker.ammendmentSMS"
                    name="routeNotifyBooker.ammendmentSMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data,
                        routeNotifyBooker: {
                          ...data.routeNotifyBooker,
                          ammendmentSMS:
                            !data?.routeNotifyBooker?.ammendmentSMS,
                        },
                      });
                    }}
                    checked={data?.routeNotifyBooker?.ammendmentSMS}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup className="d-flex align-items-center" check>
                  <Input
                    id="routeNotifyDriver.ammendmentSMS"
                    name="routeNotifyDriver.ammendmentSMS"
                    type="checkbox"
                    onChange={() => {
                      setData({
                        ...data?.routeNotifyDriver,
                        ammendmentSMS: !data?.routeNotifyDriver?.ammendmentSMS,
                      });
                    }}
                    checked={data?.routeNotifyDriver?.ammendmentSMS}
                  />
                </FormGroup>
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>

      <div className="d-flex justify-content-end  ">
        <div className="me-2">
          <Button type="submit" color="primary" onClick={submitHandler}>
            Save
          </Button>{" "}
        </div>
      </div>
    </div>
  );
};

export default observer(RouteSettings);
