// AuditLogDialog.jsx

import React, { useContext, useState } from "react";
import { FiDatabase } from "react-icons/fi";
import { I18nContext } from "../../../../store/context/i18nContext";
import { useModal } from "../../../../store/context/ModalContext";
import { CrossButton, Tab } from "../../../../components";
import Audits from "./Audit";

const AuditLogDialog = ({ modules = null, module = null, id = null, asLabel=false }) => {
  const { t } = useContext(I18nContext);
  const [step, setStep] = useState(1);
  const { isOpen, toggleModal, setIsOpen, setModalContent,setSize } = useModal();

  const handleAuditButtonClick = (e) => {
    toggleModal();
    setSize("medium");
    e?.stopPropagation();
    setModalContent({
      title:
        <div className="d-flex justify-content-between align-items-center"
          style={{
            paddingLeft: "30px",
            maxHeight: "max-content",
            zIndex: 2,
            marginRight: "10px",
            marginTop: "10px",
          }}
        >
          <h3 className="align-self-center fs-5 me-3"> Audit Logs</h3>
          <div>
            <CrossButton
              style={{ position: 'relative', right: "-12px", top: "0px" }}
              onClick={(e) => {
                setIsOpen(false);
                e?.stopPropagation();
              }}
            />
          </div>
        </div>,
      body: <>
        {(Array.isArray(modules) && modules.length >= 1) ?
          <Tab
            vertical={false}
            left
            activeTab={step}
            onChange={(data) => {
              setStep(data);
            }}
            className="d-flex"
            outerClassName="mt-0 pt-0 ms-4"
          >
            {modules.map((module, index) => {
              return (
                <div num={index + 1} label={t(module?.key)}>
                  <Audits module={module?.key} ids={module?.value} />
                </div>
              )
            })}
          </Tab> :
          <div className="h-100">
            <Audits module={module} ids={id} />
          </div>
        }
      </>,
      footer: <></>,
    });
  };

  return (
    <>
      <AuditLogToggleButton asLabel={asLabel} t={t} handleAuditButtonClick={(e) => { handleAuditButtonClick(e) }} />
    </>
  );
};

const AuditLogToggleButton = ({ t, handleAuditButtonClick, asLabel }) => {
  const containerClass = asLabel ? "d-flex" : "d-flex justify-content-end my-2";
  const buttonClass = asLabel ? "d-flex gap-2 align-items-center" : "d-flex gap-2 align-items-center btn btn-secondary";
  const buttonStyle = { height: 30 };

  return (
    <div onClick={handleAuditButtonClick} className={containerClass}>
      <div className={buttonClass} style={buttonStyle}>
        <FiDatabase size={20} />
        <b className="align-self-center">{t('Audit Log')}</b>
      </div>
    </div>
  );
};

export default AuditLogDialog;
