import React, { useEffect, useState } from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import CountUp from 'react-countup';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import DashboardService from '../../../pages/Dashboard/DashboardService';
import { RiHome3Line } from 'react-icons/ri';


export const SettledTripCard = ({ }) => {
  const [settledTripCount, setSettledTripCount] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchTrips = async (currentDay) => {
    if (loading) return;
    setLoading(true);
    try {
      let body = {
        inFilters:{
          status:[29, 30]
      },
      groupBy:{
        status:"status"
    }
      }
      const response = await DashboardService.fetchTripStats(body);
      const settledTrips = response
      .filter(item => item._id.issue === "SOS")
      .reduce((acc, item) => acc + item.count, 0);
      setSettledTripCount(settledTrips)
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const currentDay = parseInt(moment().format('YYYYMMDD'));
    fetchTrips(currentDay);
  }, []);

  return (
    <React.Fragment>
      <Col md={6} xl={3}>
        <Card style={{ height: "116px" }}>
          <CardBody>
            <div className='d-flex flex-column h-100'>
              <h2 className="flex-1 mb-1 mt-1 d-flex justify-content-center">
                <span>
                  <CountUp
                    end={settledTripCount}
                    separator=","
                    prefix=""
                    suffix=""
                    decimals={0}
                  />
                </span>
              </h2>
              <h3 className="text-center text-muted mb-0">Settled Trips</h3>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
};

