import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { FaBed, FaExchangeAlt, FaEye, FaPlane, FaPlaneArrival, FaShoppingBasket, FaUmbrellaBeach } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
// import { useOutsideAlerter } from '../../../../board/helper';
import './index.scss';
import { useOutsideAlerter } from '../../../../../components/dnd/board/helper';

const kindOptions = [
    { key: 0, label: "Arrival", icon: FaPlaneArrival, color: "#17a2b8" },
    { key: 1, label: "Transfer", icon: FaExchangeAlt, color: "#6c757d" },
    { key: 2, label: "Flight", icon: FaPlane, color: "#007bff" },
    { key: 3, label: "Stay", icon: FaBed, color: "#28a745" },
    { key: 4, label: "SightSeeing", icon: FaEye, color: "#ffc107" },
    { key: 5, label: "Leisure", icon: FaUmbrellaBeach, color: "#f5634a" },
    { key: 6, label: "Market Visit", icon: FaShoppingBasket, color: "#6f42c1" },
];

const SegmentKindPicker = ({
    noToggle = false,
    value,
    onChange
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    useOutsideAlerter(dropdownRef, () => setIsOpen(false));

    const handleSelect = (key) => {
        onChange(key);
        setIsOpen(false);
    };

    const selectedOption = kindOptions.find(option => option.key === value);

    const tooltipId = `tooltip-${Math.random().toString(36).substr(2, 9)}`;
    return (
        <div className="zr_it_segement_kind_wrapper" ref={dropdownRef}>

            {selectedOption?.icon && (
                <Tooltip style={{ zIndex: '200000' }} anchorSelect={`#${tooltipId}`}>
                    <div>{selectedOption.label}</div>
                </Tooltip>
            )}


            <div
                id={`${tooltipId}`}
                className={`zr_it_segement_kind_display ${noToggle ? ' zr_it_segement_kind_item_aslabel ' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedOption?.icon ? React.createElement(selectedOption.icon, { size: 14, color: selectedOption.color }) : <span className='text-muted m-0 p-0 '>Kind</span>} {noToggle ? "" : <span className='ms-2'>{selectedOption?.label ?? ""} </span>}
            </div>

            {isOpen && !noToggle && (
                <ul className="zr_it_segement_kind_modal">
                    {kindOptions.map(option => (
                        <li
                            key={option.key}
                            className="zr_it_segement_kind_item"
                            onClick={() => handleSelect(option.key)}
                        >
                            {React.createElement(option.icon, { size: 14, color: option.color })}
                            <span className="ms-2">{option.label}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default observer(SegmentKindPicker);
