export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/o/city/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },
  get: (id) => `/api/city/${id}/detail`,
  save: `/api/city/create`,
  delete: (id) => `/api/city/${id}/delete`,
  update: `/api/city/update`,
  bulkDelete: (id) => `/api/city/delete`,
};

export const SELECTSTRUCTURE = {
  
  type: [
    { label: "Metro City", value: 1 },
    { label: "Tier 1 City", value: 2 },
    { label: "Tier 2 City", value: 3 },
  ],
}


export const STRUCTURE = [
  {
    label: "City",
    filterLabel: "City",
    filterName: "name",
    type: "text",
    dependency: {
      country: "country",
      state: "state"
    },

  },

  {
    label: "State",
    filterLabel: "State ",
    filterName: "state",
    type: "text",
    dependencyOnMe: ["city"],
    dependency: {
      country: "country",
    },
  },
  {
    label: "Country",
    filterLabel: "Country",
    filterName: "country",
    type: "text",
  },
  {
    label: "Lat",
    filterLabel: "Lat",
    filterName: "lat",
    type: "text",
  },
  {
    label: "lon",
    filterLabel: "lon",
    filterName: "lon",
    type: "text",
  },
  {
    label: "Synonyms",
    filterLabel: "Synonyms",
    filterName: "synonyms",
    type: "text",
  },
];





