export const GridColumns = {
    version: 1,
    columns: [
        {
            title: " Name",
            accessor: "name",
            show: true,
        },

        {
            title: " Number of Clients",
            renderer: (dataObj) => {
                return <p className="w-100 text-start  ">
                    {dataObj?.clients?.length > 0 ? dataObj?.clients?.length : 0}
                </p>;
            },
            show: true,
        },
        // {
        //     title: "Target Currency",
        //     accessor: "targetCurrency",
        //     show: true,
        // },
        // {
        //     title: "Amount",
        //     accessor: "amount",
        //     show: true,
        // },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
