import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './styles.scss';
import { FaFileExcel, FaFilePdf, FaFileVideo, FaMusic } from 'react-icons/fa';
import { MdOutlineGif } from 'react-icons/md';
import axios from 'axios';

const PDFViewer = ({ src, alt, style }) => (
    <object data={src} type="application/pdf" style={style} aria-label={alt}>
        <embed src={src} type="application/pdf" />
        <p>This browser does not support PDFs. Please download the PDF to view it: <a href={src}>Download PDF</a>.</p>
    </object>
);

const ImageViewer = ({ src, alt, style }) => (
    <img src={src} alt={alt} style={style} />
);

const GIFPlayer = ({ src, alt, style }) => (
    <img src={src} alt={alt} style={style} />
);

const AudioPlayer = ({ src, style }) => (
    <div className='d-flex justify-content-center'>
        <audio controls>
            <source src={src} type="audio/mp3" style={style} />
            Your browser does not support the audio element.
        </audio>
    </div>
);

const VideoPlayer = ({ src,style }) => (
    <div className='d-flex justify-content-center'>
        <video controls  style={style}>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </div>
);

const FileViewer = ({ document,styleConfig }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [src, setSrc] = useState(null);
    const [fileType, setFileType] = useState(document?.type ?? document);
    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const fetchUploadedDocument = async (url) => {
        try {
            const response = await axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    Authorization: localStorage.getItem("BearerToken"),
                },
                withCredentials: true
            });
            const objectUrl = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
            setSrc(objectUrl);
            setFileType(response.data.type);
        } catch (error) {
            console.error("Error fetching document:", error);
        }
    };

    useEffect(() => {
        if (document) {
            if (typeof document === 'string' && document.startsWith("blob:")) {
                setSrc(document);
            } else if (typeof document === 'string' && document.startsWith("/api")) {
                fetchUploadedDocument(document);
            } else {
                setSrc(URL.createObjectURL(document));
                setFileType(document?.type ?? "");
            }
        }
    }, [document]);

    const getFilePreview = () => {
        if (!document) return <p>No document selected</p>;

        switch (true) {
            case fileType.includes('pdf'):
                return <PDFViewer src={src} alt="Document" style={styleConfig?.pdf} />;
            case fileType.includes('audio'):
                return <AudioPlayer src={src} style={styleConfig?.audio} />;
            case fileType.includes('video'):
                return <VideoPlayer src={src} style={styleConfig?.video} />;
            case fileType.includes('gif'):
                return <GIFPlayer src={src} alt="Document" style={styleConfig?.gif} />;
            default:
                return <ImageViewer src={src} alt="Document" style={styleConfig?.image} />;
        }
    };

    const getIcon = () => {
        switch (true) {
            case fileType.includes('pdf'):
                return <FaFilePdf size={40} />;
            case fileType.includes('audio'):
                return <FaMusic size={30} className='mx-3' />;
            case fileType.includes('video'):
                return <FaFileVideo size={30} className='mx-3' />;
            case fileType.includes('gif'):
                return <MdOutlineGif size={30} className='mx-3' />;
            case fileType.includes('xlsx'):
                return <FaFileExcel size={30} className='mx-3' />;
            default:
                return <img src={src} alt={document?.name} height={40} width={40} />;
        }
    };

    return (
        <>
            <div onClick={toggleModal} className='cursor-pointer'>
                {getIcon()}
            </div>

            <Modal size="xl" isOpen={isModalOpen} toggle={toggleModal} centered>
                <ModalHeader toggle={toggleModal}>View Document</ModalHeader>
                <ModalBody className="zr_doc_field_modal_body d-flex justify-content-center cursor-pointer">
                    {getFilePreview()} 
                </ModalBody>
            </Modal>
        </>
    );
};

export default FileViewer;