import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from "react";
import { Button, CardBody, Col, Row } from "reactstrap";
import SupplierCompany from "../../../components/Dropdowns/SupplierCompany/SupplierCompany";
import { formatSecondsToDuration } from "../../../helpers/dateHelper";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { getDaysDifferenceInDays } from "../../../util/DateUtil";
import useDebounce from "../../../util/Debounce";
import BookingFee from "../../Invoice/Fee/BookingFee";
import BookingService from "../BookingService";
import FeeRate from "./FeeRate";
import PaymentCollectedTable from "./PaymentCollected/PaymentCollectedTable";
import "./style.css";
import { CompanySelect, DeleteButton, SupplierSelect } from "../../../components";
import _ from "lodash";
import LoadingView from "../../../components/Loader/LoadingView";

const BillingNew = forwardRef(({
  billing,
  setBilling,
  onChange = () => { }, 
  loading,
  onDelete,
  type,
  index,
  key,
  billingEditState,
  setBillingEditState = () => { },
  onTripChange,
  trip,
  forInvoice,
  customerType,
  billingUpdatedObject,
  fromInvoice = false,
  margin=null
}, billingRef) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [customRate, setCustomRate] = useState(null); 
  const {toggleCalculatingBilling,calculatingBilling}=BookingService
 
  useEffect(() => {
    if(type==="Supplier"){
      setCustomRate(false);
      return;
    }
    // if(customRate===null){
    setCustomRate(!billing?.feeRate?.rate_id && trip?._id ? true : false);
    // }
  }, [billing?.feeRate?.rate_id, trip?._id,type]);

  useImperativeHandle(billingRef, () => ({
    async updateBilling({updatedTrip,expenses}) {
      handleClientBillingCalculation({updatedTrip,expenses})
    }
  }));

  const expenseList = ["Parking Fare", "Toll Tax", "FasTag", "State Tax", "MCD", "Other"];

  const handleClientBillingCalculation = useDebounce(async ({updatedTrip,updatedBilling=null,expenses=null}) => {
    let myBilling =updatedBilling?_.clone(updatedBilling): _.clone(billing);
    const exp=expenses?.length ? expenses :billingUpdatedObject?.expenses
  
    try {
      toggleCalculatingBilling(`${type}-${index}`,true);//ex client-0 or supplier-0
      let updatedFee = {};
      if (!customRate) {
        if (myBilling?.fee) {
          if (myBilling?.fee.feeGroups?.length) {
            updatedFee.feeGroups = [];
            for (let idx = 0; idx < myBilling?.fee.feeGroups.length; idx++) {
              const FG = myBilling?.fee.feeGroups[idx];
              const localFG = { ...FG, name: FG.name, charges: [] };
              if (FG.charges?.length) {
                for (let jdx = 0; jdx < FG.charges.length; jdx++) {
                  const charge = FG.charges[jdx];
                  if (charge && (charge.extraCharges || expenseList.indexOf(charge.name) !== -1)) {
                    localFG.charges.push(charge);
                  }
                }
              }
              updatedFee.feeGroups.push(localFG);
            }
          }
        }
      } else {
        updatedFee = myBilling?.fee;
      }

      if (myBilling?.feeRate?.rateName?.name) {
        myBilling.feeRate.name = myBilling.feeRate?.rateName?.name;
      }

      myBilling.fee = updatedFee;
      myBilling = BookingService.updateBillingsForNonTaxable(myBilling, exp, myBilling?.feeRate);

      const requestBody = {
        trip: {
          startTime: updatedTrip?.startTime,
          duration: updatedTrip?.duration,
          tripExtn: {
            ...trip?.tripExtn,
            distance: updatedTrip?.tripExtn?.distance,
            preStartKMs: updatedTrip?.tripExtn?.preStartKMs,
            postStopKMs: updatedTrip?.tripExtn?.postStopKMs,
            preStartMins: updatedTrip?.tripExtn?.preStartMins,
            postStopMins: updatedTrip?.tripExtn?.postStopMins,
          },
        },
        billing: {
          // fee: myBilling?.fee,
          fee: updatedFee,
          feeRate: myBilling?.feeRate
        },
        tripExpenses: exp
      }

      const nbilling = await BookingService.doBillingCalculations(requestBody);
      if (nbilling) {
        // if (setBilling) {
        //   setBilling(currentBilling => ({
        //     ...currentBilling,
        //     feeRate: { ...nbilling?.feeRate },
        //     fee: { ...nbilling?.fee }
        //   }));
        // }
        onChange({
          ...billing,
          feeRate: { ...nbilling?.feeRate },
          fee: { ...nbilling?.fee }
        });
      }

    } catch (e) {
      console.log(e);
    }finally{
      toggleCalculatingBilling(`${type}-${index}`,false);
    }

  }, 1000)

  const handleBillingChange = useCallback(
    (fieldName, fieldValue, billingUpdated = true) => {
      if (fieldName && fieldValue) {
        const nBilling = { ...billing } || {};
        console.log("nBilling", nBilling);

        nBilling[fieldName] = fieldValue;
        onChange(nBilling, billingUpdated);
      }
    },
    [billing, onChange]
  );

  const handleFeeCalculation = (localFee) => {
    if (localFee) {
      localFee.subTotal =
        localFee.feeGroups?.reduce(
          (acc, feeGroup) => acc + (feeGroup?.subTotal || 0),
          0
        ) || 0;
      localFee.totalDiscounts =
        localFee.feeGroups?.reduce(
          (acc, feeGroup) => acc + (feeGroup?.totalDiscounts || 0),
          0
        ) || 0;
      localFee.totalTaxes =
        localFee.feeGroups?.reduce(
          (acc, feeGroup) => acc + (feeGroup?.totalTaxes || 0),
          0
        ) || 0;
      localFee.total =
        localFee.feeGroups?.reduce(
          (acc, feeGroup) => acc + (feeGroup?.total || 0),
          0
        ) || 0;
    }
    return localFee;
  };

  const handleFeeChange = useCallback(
    (updatedFeeData) => {
      if (updatedFeeData) {
        const nBilling = {
          ...billing,
        };
        nBilling.fee = updatedFeeData;
        onChange(nBilling);
      }
    },
    [billing, onChange]
  );

  const updateFee = useCallback(
    (fieldName, fieldValue) => {
      if (fieldName && fieldValue) {
        let feeCopy = { ...billing?.fee } || {};
        feeCopy[fieldName] = fieldValue;
        if (fieldName === "feeGroups") {
          feeCopy = handleFeeCalculation(feeCopy);
        }
        handleFeeChange(feeCopy);
      }
    },
    [billing?.fee, handleFeeChange]
  );

  const addFeeGroup = useCallback(() => {
    const newFeeGroup = {
      charges: [{}],
      taxItem: {
        taxes: [{

        }]
      }
    }
    if (!billing?.fee?.feeGroups) {
      updateFee("feeGroups", [newFeeGroup]);
    } else {
      updateFee("feeGroups", [...billing?.fee.feeGroups, newFeeGroup]);
    }
  }, [billing?.fee, updateFee]);

  const isVisible = (billingIndex) => {
    if (type == "Client") {
      return billingEditState?.client
    } else if (type == "Supplier") {
      return (billingEditState?.supplier == billingIndex)
    }
  }

  const calcTravelDays = (rateCycle) => {
    if (rateCycle == null) {
      return 1;
    }
    const startTime = trip.startTime;
    const stopTime = trip.duration && trip.duration > 0 ? trip.startTime + trip.duration * 60 : trip.startTime;
    if (rateCycle === 1) {
      if (startTime != null && stopTime != null) {
        return getDaysDifferenceInDays(startTime, stopTime);
      } else {
        return 1;
      }
    }
    if (rateCycle === 2) {
      const minutes = trip.duration && trip.duration > 0 ? trip.duration : 0;
      let days = parseInt(minutes / 1440);
      if (days === 0 || minutes > days * 1440)
        days = days + 1;
      return days;
    }
    return 1;
  }

  return (
    <CardBody className="p-0 pt-0 position-relative" key={key}>
      <div className="d-flex justify-content-between">

        <div className={`ms-4 w-100`}>
          <div className="d-flex  align-items-center">
            <div className="mt-3 col-6 font-size-13">
              <span style={{ color: '#999999' }}>
                {t(`Extra Trip Duration`)} : &nbsp;
              </span>
              <span>
                {formatSecondsToDuration(BookingService.findTripExtraDuration(trip, billing))}
              </span>
            </div>
            <div className="mt-3 col-6 font-size-13">
              <span style={{ color: '#999999' }}>
                {t(`Extra Trip Distance`)} : &nbsp;
              </span>
              <span>
                {BookingService.findTripExtraDistance(trip, billing)} KMs
              </span>
            </div>
          </div>
          {isVisible(index) && (
            <div >
              {type=="Supplier" ? (
                <div className="mt-2 d-flex flex-column flex-md-row col-12 gap-2 pe-2">
                  <div className="flex-1">
                  <SupplierSelect
                    value={billing?.vendor_id}
                    className={"my-0 w-100"}
                    outline
                    onChange={(v) => {
                      const nBilling = { ...billing } || {};
                      nBilling["vendor_id"] = v?.value;
                      onChange(nBilling);
                    }}
                  />
                   </div> 
                   <div className="flex-1">

                  <CompanySelect
                    className="my-0 "
                    outline
                    corpType="supplier"
                    value={billing?.vendorCompany_id}
                    onChange={(v) => {
                      const nBilling = { ...billing } || {};
                      nBilling["vendorCompany_id"] = v?.value;
                      onChange(nBilling);
                    }}
                    innerContainerClassName="input-group-post"
                    label="Supplier Company"
                    corp_id={billing?.vendor_id}
                  />
                  </div>
                  {/* <SupplierCompany
                    className="col-12"
                    supplierId={billing?.vendor_id}
                    value={billing?.vendorCompany_id}
                    onChange={(v) => {
                      const nBilling = { ...billing } || {};
                      nBilling["vendor_id"] = v?.supplier_id;
                      nBilling["vendorCompany_id"] = v?.value;
                      onChange(nBilling);
                    }}
                    label={"Supplier"}
                  /> */}

                </div>
              ) : (
                null
              )}
              {((type === "Supplier" && billing?.vendor_id) || type == "Client") && <FeeRate
                trip={trip}
                fee={billing?.fee}
                supplier_id={type === "Supplier" ? billing?.vendor_id : null}
                corpType={type}
                customerType={customerType}
                type="billing"
                toggleCalculatingBilling={(value)=>toggleCalculatingBilling(`${type}-${index}`,value)}
                className="pt-3 col-12"
                value={billing?.feeRate}
                customRate={customRate}
                setCustomRate={setCustomRate}
                onTripChange={onTripChange}
                onChange={(v, billingUpdated = true) => {
                  handleBillingChange("feeRate", v, billingUpdated)
                  // handleClientBillingCalculation(trip,{
                  //   ...billing,
                  //   feeRate: v
                  // })
                }}
                hardUpdateBillingFromRate={(v) => {
                  onChange({
                    ...billing,
                    fee: v.fee,
                    feeRate: v.feeRate,
                  })
                }}
                billingUpdatedObject={billingUpdatedObject}
              />}
            </div>
          )}
        </div>

        {
          <div className="d-flex justify-content-end align-items-center">
            {loading ? (
              isVisible(index) && (
                <Button
                  className="mx-2"
                  style={{ height: "28px", padding: "2px 4px", width: "90px" }}
                  color="danger"
                >
                  <ThreeBounce size={10} color="#FFFFFF" />
                </Button>
              )
            ) : (
              <></>
            )}
          </div>}
       </div>
      <LoadingView loading={calculatingBilling[`${type}-${index}`]} >
        <div>
          {!(isVisible(index)) && (
            <div
              className={`d-flex justify-content-${type == "Supplier" ? "between" : "end"} mt-2`}
              style={{ width: type == "Supplier" ? "100%" : "inherit" }}
            >
              {type == "Supplier" && !(billing?.invoice_id || billing?.bill_id || billing?.paymentMade_id) ? <DeleteButton loading={loading} outline onClick={onDelete} /> : null}

              {(billing?.invoice_id || billing?.bill_id || billing?.paymentMade_id) && !fromInvoice ? null :
                <i className="uil uil-pen font-size-18 me-3 d-flex" onClick={(e) => {
                  e.stopPropagation();
                  if (type == "Client") {
                    setBillingEditState({
                      client: true
                    })
                  } else if (type == "Supplier")
                    setBillingEditState({
                      supplier: index
                    })
                  // setBillingUpdated(true)
                }} style={{ cursor: "pointer" }} />}

            </div>
          )}
        </div>
        <div className="d-flex flex-1 flex-column">
          {!isVisible(index) && type == "Supplier" && (
            <div className="d-flex align-items-center pt-1 ps-2">
              <div
                className="ms-3 font-size-14"
                style={{
                  fontWeight: "600",
                }}
              >
                Supplier:{" "}
              </div>
              <div className="d-flex flex-sm-row flex-column ms-2 font-size-14">
                <SupplierCompany
                  className="col-4"
                  asLabel
                  supplierId={billing?.vendor_id}
                  value={billing?.vendorCompany_id}
                  onChange={(v) => {
                    const nBilling = { ...billing } || {};
                    nBilling["vendor_id"] = v?.supplier_id;
                    nBilling["vendorCompany_id"] = v?.value;
                    onChange(nBilling);
                  }}
                  label={"Supplier"}
                />
              </div>
            </div>
          )}
          <BookingFee
            billingIndex={index}
            forInvoice={fromInvoice}
            fee={billing?.fee}
            billingEditState={billingEditState}
            onChange={handleFeeChange}
            type={type}
            margin={margin}
          />
        </div>
      </LoadingView>


      <Row className="py-2 pe-3">
        <Col>
          <div className={`d-flex justify-content-${type == "Client" ? "between" : "end"}`}>
            {isVisible(index) ? (<div className={`d-flex w-100 justify-content-${type == "Client" && billing?.fee?.feeGroups?.length < 2 ? "between" : "end"} align-items-center`}>
              {type == "Client" && billing?.fee?.feeGroups?.length < 2 && <div
                className="btn btn-outline-dark ms-4"
                style={{
                  padding: "3px",
                  paddingRight: "6px",
                }}
                onClick={() => {
                  addFeeGroup()
                }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <i className="bx bx-plus  font-size-18 me-1" />
                  {t("Group")}
                </div>
              </div>}

              <div className="d-flex align-items-center">
                {type == "Supplier" && isVisible(index) ? (
                  <DeleteButton outline onClick={onDelete} loading={loading} />
                ) : null}


                <button
                  className=" d-flex align-items-center justify-content-center btn btn-outline-dark"
                  onClick={() => {
                    setBillingEditState({
                      supplier: -1,
                      client: false
                    })
                  }}
                  style={{
                    padding: "1px",
                    height: "28px",
                    width: "80px",
                    cursor: "pointer"
                  }}
                >
                  <span className=' '>
                    {t("Cancel")}
                  </span>
                </button>
                {/* {(trip?._id && !billingUpdatedObject?.updated) && <SaveButton
                  onClick={() => {
                    preSaveBilling({});
                  }}
                />} */}
              </div>
            </div>
            ) : null}
          </div>
        </Col>
      </Row>

      {type === "Client" &&
        <div>
          <PaymentCollectedTable trip_id={trip?._id} />
        </div>
      }

    </CardBody>
  );
});

export default observer(BillingNew);
