import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Checkbox, InputField, TermSelect, TimeSelect } from '../../../components';
import RateItemSelect from '../../../components/Dropdowns/RateItemSelect/RateitemSelect';
import WeekdaySelector from '../../../components/WeekDaySelect/WeekDaySelect';
import { BillingDuration, DayEndSelect, FareType, TimeRounding } from '../component/Components';

const RateExtn = ({ value, onChange, type = "", isWeekDaySelector = true }) => {

    const handleOnChange = (fieldName, fieldValue) => {
        const val = value || {};
        onChange({ ...val, [fieldName]: fieldValue });
    }

    return (<div>
        {type !== "billing" && <Row>
            <Col>
                <RateItemSelect
                    outline
                    multi={true}
                    value={value?.rateItems}
                    onChange={(v) => handleOnChange("rateItems", v)}
                />
            </Col>
            <Col>
                <InputField
                    outline
                    label="Sub Type"
                    value={value?.subType}
                    onChange={(v) => handleOnChange("subType", v)}
                />
            </Col>
            <Col>
                <BillingDuration
                    value={value?.billingDuration}
                    onChange={(v) => handleOnChange("billingDuration", v)}
                />
            </Col>
        </Row>}
        {type === "billing" && <Row>
            <Col>
                <FareType
                    value={value?.fareType}
                    onChange={(v) => handleOnChange("fareType", v)}
                />
            </Col>
            <Col>
                <TimeRounding
                    value={value?.timeRounding}
                    onChange={(v) => handleOnChange("timeRounding", v)}
                />
            </Col>
            <Col>
                <DayEndSelect
                    value={value?.rateCycle}
                    onChange={(v) => handleOnChange("rateCycle", v)}
                />
            </Col>
        </Row>}
        <Row>
            <Col>
                <Checkbox
                    checked={value?.expensesNonTaxable ? true : false}
                    value={value?.expensesNonTaxable ? true : false}
                    label={"Exclude Toll/parking from taxation"}
                    onClick={(v) => handleOnChange("expensesNonTaxable", v)}
                />
            </Col>
        </Row>
        {type === "billing" && <Row>
            {type!=='billing'?<Col>
                <WeekdaySelector value={value?.weekDay}
                    onChange={(v) => handleOnChange("weekDay", v)}
                />
            </Col>:null}
            <Col>
                <Checkbox
                    key={"nightChargeCheckbox"}
                    placeholder={"Night Charge"}
                    checked={value?.nightCharge}
                    value={value?.nightCharge}
                    required={false}
                    label={"Night Charge"}
                    onClick={() => handleOnChange("nightCharge", (value?.nightCharge) ? !(value?.nightCharge) : true)}
                    showErr={false}
                    type="text"
                    className="col-sm-2 col-6 my-1"
                />
            </Col>
            {value?.nightCharge && <Col lg="3">
                <TimeSelect
                    outline
                    label="Night Start Time"
                    value={value?.nightStartTime}
                    onChange={(v) => handleOnChange("nightStartTime", v)}
                />
            </Col>}

            {type === "billing" && value?.nightCharge && <Col lg="3">
                <TimeSelect
                    outline
                    label="Night End Time"
                    value={value?.nightEndTime}
                    onChange={(v) => handleOnChange("nightEndTime", v)}
                />
            </Col>}
        </Row>}
        {/* <Row>
            {type === "billing" && value?.nightCharge && <Col xl="4">
                <TimeSelect
                    outline
                    label="Night End Time"
                    value={value?.nightEndTime}
                    onChange={(v) => handleOnChange("nightEndTime", v)}
                />
            </Col>}

        </Row> */}
        <Row>
            <Col>
                <TermSelect
                    onChange={(v) => {
                        handleOnChange("termsNPolicy_id", v?.value)
                    }}
                    value={value?.termsNPolicy_id}
                    outline
                />
            </Col>
            {/* <Col>
                <InputField
                    outline
                    label="Terms And Policy"
                    value={value?.termsNPolicy_id}
                    onChange={(v) => handleOnChange("termsNPolicy_id", v)}
                />
            </Col> */}
        </Row>
    </div>);

}
RateExtn.propTypes = {
    value: PropTypes.shape({
        rate: PropTypes.number,
        type: PropTypes.string,
        description: PropTypes.string,
        // Include other fields as necessary
    }),
    onChange: PropTypes.func.isRequired
};
export default RateExtn;