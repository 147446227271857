import { makeAutoObservable } from "mobx";
import { doGET } from "../../util/HttpUtil";
import { ENDPOINTS } from "./FeedbackConstant";

class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = 40;
  filterOption = "";
  total = -1;

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterUrl) => {
    if (filterUrl) {
      this.filterOption = filterUrl;
    }
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl)
    );

    if (response?.status === 200) {
      this.records = response.data.rows;
      this.page = response?.data?.page;
      if (this.page === 1) this.total = response?.data?.total;
    }
  };

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);

  };

}

const FeedbackService = new Service();
export default FeedbackService;

