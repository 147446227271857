
import React, { useContext } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import StatusMasterPickerService from "../../Dropdowns/StatusMasterSelect/StatusMasterPickerService";
import "./style.scss";

const DeleteStatus = ({ id }) => {

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const onDelete = async (e) => {
        e.stopPropagation()
        e.preventDefault();
        if (
            await showConfirm({
                title: t("Do you want to delete Status?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            try {
                await StatusMasterPickerService.delete(id);
                showMessage(t("Lead Deleted successfully."));

            } catch (e) {
                showError(e);
            }
        }
    }


    return (
        <React.Fragment>
            <div
                onClick={onDelete}
                className="d-flex justify-content-center align-items-center"
            >
                <MdOutlineDeleteOutline size={18} />
            </div>
        </React.Fragment>
    );
};

export default DeleteStatus;
