import { useContext, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import socketInstance, { SOCKET_EVENT } from '../service/socket';
import { UserContext } from '../store/context/UserContext';



const useSocketBookedEvent = (loggedIn) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [booking,setBooking]=useState(null);
    const {userContent}=useContext(UserContext)
    
    const setUpSocketInteractions=async()=>{
        if(!userContent?.owner_id){
            return;
        }
        let socket = socketInstance.getSocket();
        if (!socket) {
            socket = await socketInstance.initializeSocket();
        }
        socket?.emit('joinRoom', userContent.owner_id, (response) => { 
            console.log("owner id room join response:", response);
        });
        socket?.on(SOCKET_EVENT.TRIP_CREATED, (data) => {
            console.log("event.........",data)
            setIsModalOpen(true);
            setBooking(data);
        });

    }
     
    useEffect(() => {
       
        setUpSocketInteractions()
    }, [loggedIn,userContent]);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
       if(isModalOpen){
          setBooking(null);
       }
    };

    const navigateToTrip=()=>{
        if(booking){
            window.open(`/booking/edit/${booking?.trip_id}`, "_blank");
            toggleModal();
        }
    }

    // Modal component
    const BookedModal = () => (
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>New Booking Confirmation </ModalHeader>
            <ModalBody>
                A new booking has been created. Click to view the details of the trip or make further modifications if needed.
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggleModal}>Cancel</Button>
                <Button color="primary" onClick={navigateToTrip}>View Booking</Button>
            </ModalFooter>
        </Modal>
    );

    return { BookedModal, isModalOpen, toggleModal };
};

export default useSocketBookedEvent;
