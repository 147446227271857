import React, { useState, useEffect } from 'react'
import { SelectField } from '../../SelectField/SelectField';
import { toJS } from 'mobx';
import SupervisorSelectService from './SupervisorSelectService';

export default function SupervisorSelect(props) {
    const [supervisors, setSupervisors] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            try {
                await SupervisorSelectService.fetch();
                setSupervisors(toJS(SupervisorSelectService.supervisors))
            } catch (error) {
                console.log(error.message);
            }
        }
        fetch();
    }, [])

    return (
        <React.Fragment>
            {supervisors.length > 0 &&
                <SelectField
                    className={props?.className}
                    onChange={props?.onChange}
                    data={supervisors}

                    value={props?.value}
                    label={"Supervisor"}
                    error={
                        props?.value
                            ? "Please Select Supervisor"
                            : ""
                    }
                    showErr={props?.showErr}
                    inline={props?.inline}
                    borderNone={props?.borderNone}
                    RightComponent={props?.RightComponent}
                    onClickRight={props?.onClickRight}
                    {...props}
                />}
            {!supervisors.length > 0 &&
                <SelectField
                    className={props?.className}
                    onChange={props?.onChange}
                    data={supervisors}

                    value={props?.value}
                    label={"Supervisor"}
                    isDisabled={true}
                    error={
                        props?.value
                            ? "Please Select Supervisor"
                            : ""
                    }
                    showErr={props?.showErr}
                    inline={props?.inline}
                    borderNone={props?.borderNone}
                    RightComponent={props?.RightComponent}
                    onClickRight={props?.onClickRight}
                />}
        </React.Fragment>
    )
}
