import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import { useCallback, useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { rateNames, rateNamesValues } from '../../../common/utils/Constants';
import { InputField, MakeModelSelect, ToggleSelector, VehicleTypeSelect } from '../../../components';
import AutoSuggestSelect from '../../../components/AutoSuggestSelect/AutoSuggestSelect';
import TimeInput from '../../../components/DateSelects/TimeInput';
import RateItemService from '../../../components/Dropdowns/RateItemSelect/RateItemSelectService';
import RateVehicle from '../../../components/Dropdowns/RateVehicle/RateVehicle';
import { DialogContext } from '../../../store/context/DialogContext';
import { UserContext } from "../../../store/context/UserContext";
import { I18nContext } from '../../../store/context/i18nContext';
import { doPOST } from '../../../util/HttpUtil';
import { getTimestampToDateInteger } from '../../../util/Util';
import RateExtn from '../../Rate/EditSections/RateExtn';
import { ApplicableType, RateType } from '../../Rate/component/Components';
import RateNameService from '../../RateName/RateNameService';
import BookingService from '../BookingService';

const FeeRate = ({
    type = "",
    value,
    supplier_id,
    corpType,
    fee,
    onChange,
    className,
    onTripChange = () => { },
    hardUpdateBillingFromRate,
    trip,
    customerType,
    billingUpdatedObject,
    customRate,
    setCustomRate,
    toggleCalculatingBilling=()=>{}
}) => {
    const { userContent } = useContext(UserContext);
    const { t } = useContext(I18nContext);
    // const [customRate, setCustomState] = useState(!value?.rate_id && trip._id ? true : false);
    const { showConfirm, showError } = useContext(DialogContext);

    const expenseList = ["Parking Fare", "Toll Tax", "FasTag", "State Tax", "MCD", "Other"];
    const SELECTSTRUCTURE = {
        fareType: [
            { label: "Pick and Drop", value: 0 },
            { label: "Garage to Garage", value: 1 },
            { label: "Garage to Garage = Fixed Cost", value: 2 },
            { label: "Garage Travel Charged on Separate Per KM Basis Only", value: 3 },
            {
                label: "Garage Travel Distance only added to trip distance not time",
                value: 4,
            },
            { label: "Return Fare Applicable for both distance and time", value: 5 },
            { label: "Return Fare Applicable for distance only", value: 6 },
            {
                label:
                    "Return Fare Applicable for distance on Separate Per KM Basis only",
                value: 7,
            },
        ],
        dayEndTypes: [
            { label: "From 24Hrs of trip start time", value: 1 },
            { label: "At Midnight", value: 2 },
        ]
    };


    const handleOnChange = useCallback(async (fieldName, fieldValue) => {
        if (fieldName === "rateVehicle") {
            const rateID = fieldValue._id
            const requestedVehicleType = fieldValue.vehicleType;
            const val = value || {};
            const changedValue = {
                ...val,
                ...fieldValue,
                rate_id: rateID,
            }
            onChange(changedValue);
            onTripChange({requestedVehicleType,requestedMakeModel:fieldValue?.makeModel})
        }
        else if (fieldName && fieldValue) {
            const val = value || {};
            onChange({ ...val, [fieldName]: fieldValue });
        }
    }, [value, onChange]);

    const checkStandardFaresTypeBeforePassingInPayload = (charge) => {
        if (value?.isCustomType) {
            return true;
        }
        if (["BaseFare", "TimeFare", "DistanceFare", "NightCharge", "DriverAllowance", "GarageFare"].indexOf(charge.name) !== -1) {
            return false;
        }
        return true;
    }

    const onRateChange = async (v) => {
        try {
            toggleCalculatingBilling(true)
            const changedValue = { ...v };
            // const rateName = await RateNameService.get(v.rateName_id);
            // if (rateName) {
            //     changedValue.allotedKMs = rateName.allotedKMs;
            //     changedValue.allotedMins = rateName.allotedMins;
            //     changedValue.billingDuration = rateName.billingDuration;
            // }
              
            if (changedValue?.rateName) {
                changedValue.allotedKMs = changedValue?.rateName.allotedKMs;
                changedValue.allotedMins = changedValue?.rateName.allotedMins;
                changedValue.billingDuration = changedValue?.rateName.billingDuration;
            }

            const updatedFee = {};
            if (fee) {
                if (fee.feeGroups?.length) {
                    updatedFee.feeGroups = [];
                    for (let idx = 0; idx < fee.feeGroups.length; idx++) {
                        const FG = fee.feeGroups[idx];
                        const localFG = { ...FG, name: FG.name, charges: [] };
                        if (FG.charges?.length) {
                            for (let jdx = 0; jdx < FG.charges.length; jdx++) {
                                const charge = FG.charges[jdx];
                                if (charge && ((charge.extraCharges && checkStandardFaresTypeBeforePassingInPayload(charge)) || expenseList.indexOf(charge.name) !== -1)) {
                                    localFG.charges.push(charge);
                                }
                            }
                        }
                        updatedFee.feeGroups.push(localFG);
                    }
                }
            }

            let myBilling = {
                "fee": updatedFee,
                "feeRate": { ...changedValue, rate_id: changedValue._id }
            }
            myBilling = BookingService.updateBillingsForNonTaxable(myBilling, billingUpdatedObject?.expenses, myBilling?.feeRate);


            const calculatePayload = {
                "trip": {
                    "startTime": trip?.startTime ?? 0,
                    "duration": trip?.duration ?? 0,
                    "tripExtn": {
                        "noOfTrips": 1,
                        "childPax": 0,
                        "pax": trip?.passengers?.length ?? 0,
                        "gpsDistance": 0,  
                        "garageKMs": 0,
                        distance: billingUpdatedObject?.distance,
                        preStartKMs: billingUpdatedObject?.preStartKMs,
                        postStopKMs: billingUpdatedObject?.postStopKMs,
                        preStartMins: billingUpdatedObject?.preStartMins,
                        postStopMins: billingUpdatedObject?.postStopMins,
                    }
                },
                "billing": {
                    "fee": myBilling.fee,//updatedFee,
                    "feeRate": { ...changedValue, rate_id: changedValue._id }
                },
                "tripExpenses": billingUpdatedObject?.expenses
            }

            const response = await doPOST(`/api/billing/calculate`,
                calculatePayload
            );

            const rateNameResponse = await RateItemService?.getRateItems();
            let rateNameObject = {}

            toJS(rateNameResponse)?.map((rateName) => {
                rateNameObject[rateName?.value] = rateName?.label
                rateNameObject[rateName?.label] = rateName?.label
            });

            handleOnChange('rateVehicle', response?.data?.feeRate)
            hardUpdateBillingFromRate(response?.data)
        } catch (e) {
            showError(e);
        }finally{
            toggleCalculatingBilling(false);
        }
    };

    return (
        <div className={`${className}`}>
            <Row className='d-flex align-items-center w-100'>
                {userContent?.rights?.includes(6004) && corpType === 'Client' ?
                    <Col className='col-4'>
                        <ToggleSelector
                            data={[
                                { label: "Standard ", value: "standardRate" },
                                { label: "Custom", value: "customRate" },
                            ]}
                            value={customRate ? "customRate" : "standardRate"}
                            onChange={(selectedValue) => {
                                // if(selectedValue=="customRate"){
                                //     onChange({ ...value, rate_id: null })
                                // }
                                onChange({ ...value, isCustomType: selectedValue === "customRate" },false)
                                setCustomRate(selectedValue === "customRate");
                            }}
                        />
                    </Col>
                    : ""}
                {!customRate ?
                    <Col className='d-flex col-8' style={{ top: '-2px' }}>
                        <RateVehicle
                            value={value?.rate_id}
                            client_id={corpType !== "Supplier" ? trip?.client?.client_id : null}
                            srcCountry={trip?.src?.country}
                            srcState={trip?.src?.state}
                            tripType={trip?.tripType}
                            firstDefaultRate={corpType === "Supplier"}
                            srcCity={trip?.src?.city}
                            validFrom={trip?.src?.city}
                            supplier_id={supplier_id}
                            customerType={customerType}
                            date={getTimestampToDateInteger(trip?.startTime)}
                            onChange={(v) => {
                                onRateChange(v)
                            }}
                            className="col-12"
                        />
                    </Col> :
                    <Col>
                        <AutoSuggestSelect
                            label={value?.rateName_id ? "" : "Package Name"}
                            placeholder={"Package Name"}
                            outline
                            required
                            data={rateNames}
                            value={value?.name}
                            onChange={(a, { newValue }) => {
                                onChange({
                                    ...(value ?? {}),
                                    name: newValue,
                                    allotedKMs: rateNamesValues[newValue]?.allottedKms ?? 0,
                                    allotedMins: rateNamesValues[newValue]?.allottedMins ?? 0,
                                });
                            }}
                        />
                    </Col>
                }
            </Row>

            {customRate &&
                <div className=''>
                    <Row className='d-flex align-items-center w-100'>
                        <Col>
                            <MakeModelSelect
                                outline
                                value={value?.makeModel}
                                onChange={(v) => {
                                    if (v?._id) {
                                        handleOnChange("makeModel", v?._id)
                                        if (!trip?.tripExtn?.requestedMakeModel) {
                                        onTripChange({ requestedMakeModel:v?._id})
                                    }
                                }
                                }}
                            />
                        </Col>
                        <Col>
                            <VehicleTypeSelect
                                outline
                                value={value?.vehicleType}
                                onChange={(v) => {
                                    if (v?._id) {
                                        handleOnChange("vehicleType", v?._id);
                                        onTripChange({requestedVehicleType:v?._id})
                                    }
                                }
                                }
                            />
                        </Col>

                    </Row>
                    <Row className='d-flex align-items-center w-100'>
                        <Col>
                            <InputField
                                outline
                                label="Included KMs"
                                value={value?.allotedKMs}
                                onChange={(v) => {
                                    handleOnChange("allotedKMs", v)
                                }}
                            />
                        </Col>

                        <Col>
                            <TimeInput
                                outline
                                label={"Included Mins (hh:mm)"}
                                value={value?.allotedMins}
                                onChange={(v) => {
                                    handleOnChange("allotedMins", v)
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className='d-flex align-items-center w-100'>
                        <RateExtn
                            type={type}
                            value={value?.rateExtn}
                            onChange={(v) => handleOnChange("rateExtn", v)}
                        ></RateExtn>
                    </Row>

                    {type !== "billing" && <Row>
                        <Col>
                            <InputField
                                outline
                                label="Rate Number"
                                value={value?.rateNo}
                                onChange={(v) => handleOnChange("rateNo", v)}
                                className="col-12 col-md-12 col-lg-6 ms-3"
                            />
                        </Col>
                        <Col>
                            <InputField
                                outline
                                label="Rate Name"
                                value={value?.rateName}
                                onChange={(v) => handleOnChange("rateName", v)}
                                className="col-12 col-md-12 col-lg-6 ms-3"
                            />
                        </Col>
                        <Col>
                            <RateType
                                value={value?.rateType}
                                onChange={(v) => handleOnChange("rateType", v)}
                                className="col-12 col-md-12 col-lg-6 ms-3"
                            />
                        </Col>
                    </Row>}

                    {type !== "billing" && <Row>
                        <Col>
                            <ApplicableType
                                className="col-12 col-md-12 col-lg-6 ms-3"
                                value={value?.appliesOn}
                                onChange={(v) => handleOnChange("appliesOn", v)}
                            />
                        </Col>

                    </Row>}


                </div>
            }

        </div>
    );
}
FeeRate.propTypes = {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}
export default FeeRate;