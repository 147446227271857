import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "reactstrap";
import { Documents } from "../../components";
import Tab from "../../components/Common/Tab/Tab";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import Permit from "../Permit/Permit";
import VehicleProfile from "./Tabs/VehicleProfile";
import VehicleService from "./VehicleService";
import Timesheet from "./Tabs/Timesheet";

import TimesheetService from "../Timesheet/TimesheetService";
import { vehiclePermitData } from "./VehicleConstant";
import OverView from "./Tabs/OverView";
import { getYesterdayDateString } from "../../util/Util";
import BookingService from "../Booking/BookingService";

const EditVehicle = ({ editId, onChange = () => { } }, hasErr) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [step, setStep] = useState(1);
  const [trip, setTrip] = useState([]);
  const [isDCO, setIsDCO] = useState(false);
  const setInitialValues = () => {
    const currentYear = new Date().getFullYear();
    setData({
      status: 1,
      vehicleType: "self",
      year: data?.year ?? currentYear,
      vehicleExtn: {
        ...(data?.vehicleExtn ?? {}),
        fuelType: data?.vehicleExtn?.fuelType ?? "PETROL",
        seats: 5,
        wheels: 4,
        transmission: "Manual"
      },
    });
  };

  const fetch = async () => {
    const currentYear = new Date().getFullYear();
    try {
      setLoading(true);
      let vehicle = await VehicleService.get(editId);
      const type = vehicle?.supplier_id ? "supplier" : "self";
      await TimesheetService.fetch();

      const yesterdayDate = getYesterdayDateString();
      const trip = await BookingService.grid(`vehicle._id=${editId}&rows=5&page=1&sortBy=tripDate&sortAsc=false&tripDate=gt[${yesterdayDate}]`);
      setTrip(trip);
      setData({
        ...vehicle,
        vehicleType: type,
        status: vehicle?.status ? vehicle?.status : 0,
        ...(!vehicle?.year && {
          year: currentYear,
        }),
        ...(!vehicle?.vehicleExtn?.fuelType && {
          vehicleExtn: {
            ...(vehicle?.vehicleExtn ?? {}),
            fuelType: vehicle?.vehicleExtn?.fuelType ?? "PETROL",
          },
        }),
      });
    } catch (error) {
      showError(error);

    }
    finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setInitialValues();
    if (editId) fetch();
  }, [editId, VehicleService?.version]);

  useEffect(() => {
    onChange(data);
  }, [data]);
  return (
    <React.Fragment>
      {loading ? (
        <div className="d-flex mt-5 justify-content-center align-items-center  flex-1">
          <ThreeBounce size={50} color="pink" />
        </div>
      ) : (
        <Form>
          <div
            className="d-flex justify-content-end"
            style={
              data?._id
                ? { marginTop: "6px", marginRight: "28px" }
                : { marginRight: "14px", marginTop: "6px" }
            }
          >
          </div>
          {!data?._id ? (
            <div num={1} label="Profile">
              <VehicleProfile
                className="bg-primary color-fff"
                data={data}
                isDCO={isDCO}
                setIsDCO={setIsDCO}
                setData={setData}
                hasErr={hasErr}
              />
              {editId && (
                <div label={t("Permit")}>
                  <Permit
                    permitNameData={vehiclePermitData}
                    insidePane
                    permitOf={editId}
                    hideFilterBar={true}
                    assetKind="vehicle"
                  />
                </div>
              )}
            </div>
          ) : (
            <Tab
              vertical={false}
              left
              activeTab={step}
              onChange={(data) => {
                setStep(data);
              }}
              className="d-flex"
            >
                  <div num={1} label={t("Overview")}>
                    <OverView
                      hasErr={hasErr}
                      data={data}
                      setData={setData}
                      vehicle_id={editId}
                      trip={trip}
                    />
                  </div>
              <div num={2} label={t("Profile")}>
                <VehicleProfile
                  className="bg-primary color-fff"
                  data={data}
                  isDCO={isDCO}
                  setIsDCO={setIsDCO}
                  setData={setData}
                  hasErr={hasErr}
                />
              </div>



              
              <div num={3} label={t("Timesheet")}>
                <Timesheet
                  data={TimesheetService?.records}
                  editId={editId}
                />
              </div>
              <div num={4} label={t("Documents")} className="d-flex">
                <Documents
                  config={{
                    showCategory: true,
                    showExpiry: true,
                  }}
                  module="vehicle"
                  record_id={data?._id}
                  col={8}
                />
              </div>

              <div num={4} label={t("Permits")} className="d-flex">
                {editId && (
                  <div label={t("Permit")}>
                    <Permit
                      insidePane
                      permitNameData={vehiclePermitData}
                      permitOf={editId}
                      hideFilterBar={true}
                      assetKind="vehicle"
                      hideCheckBox={true}
                      permintKind="vehicle"
                      permintTitle="Vehicle"
                    />
                  </div>
                )}
              </div>
            </Tab>
          )}
        </Form>
      )}
    </React.Fragment>
  );
};

export default observer(EditVehicle);
