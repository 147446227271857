import { toJS } from "mobx";
import { useCallback, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import RateItemService from "../../../components/Dropdowns/RateItemSelect/RateItemSelectService";
import { TRIP_CHARGES } from "../../Booking/BookingConstant";
import FeeGroup from "./FeeGroup";
import { AmountField, TaxSelect } from "../../../components";
import FeeSummaryRow from "./FeeSummaryRow";
import FeeGroupAsLabel from "./FeeGroupAsLabel";
const BookingFee = ({
    fee,
    onChange,
    forInvoice,
    type,
    billingIndex,
    billingEditState,
    formConfig = {
        showMinimumFields: false,
        diablePartiesSelect: false,
        diableAddDeleteFeeGroup: false,
    },
    margin
}) => {

    const [rateNames, setRateNames] = useState({});

    const updateFee = useCallback(
        (fieldName, fieldValue) => {
            if (fieldName && fieldValue) {
                let feeCopy = { ...fee } || {};
                feeCopy[fieldName] = fieldValue;
                if (fieldName === "feeGroups") {
                    feeCopy = handleFeeCalculation(feeCopy);
                }
                onChange(feeCopy);
            }
        },
        [fee, onChange]
    );

    const updateFeeGroupArray = useCallback(
        (index, newFeeGroup) => {
            const feeGroupArray = [...fee?.feeGroups];
            feeGroupArray[index] = newFeeGroup;
            updateFee("feeGroups", feeGroupArray);
        },
        [updateFee, fee]
    );

    const addFeeGroup = useCallback(() => {
        const newFeeGroup = {
            charges: [{}],
            taxItem: {
                taxes: [{

                }]
            }
        }
        if (!fee?.feeGroups) {
            updateFee("feeGroups", [newFeeGroup]);
        } else {
            updateFee("feeGroups", [...fee.feeGroups, newFeeGroup]);
        }
    }, [fee, updateFee]);



    const fetchRateNames = async () => {
        const rateNameResponse = await RateItemService?.getRateItems();
        let rateNameObject = {}

        toJS(rateNameResponse)?.map((rateName) => {
            rateNameObject[rateName?.value] = rateName?.label
            rateNameObject[rateName?.label] = rateName?.label
        });
        setRateNames(rateNameObject)
        return rateNameObject
    }

    useEffect(() => {
        fetchRateNames()
    }, [])

    const handleFeeCalculation = (localFee) => {
        if (localFee) {
            localFee.subTotal =
                localFee.feeGroups?.reduce(
                    (acc, feeGroup) => acc + (feeGroup?.subTotal || 0),
                    0
                ) || 0;
            localFee.totalDiscounts =
                localFee.feeGroups?.reduce(
                    (acc, feeGroup) => acc + (feeGroup?.totalDiscounts || 0),
                    0
                ) || 0;
            localFee.totalTaxes =
                localFee.feeGroups?.reduce(
                    (acc, feeGroup) => acc + (feeGroup?.totalTaxes || 0),
                    0
                ) || 0;
            localFee.total =
                localFee.feeGroups?.reduce(
                    (acc, feeGroup) => acc + (feeGroup?.total || 0),
                    0
                ) || 0;
        }
        return localFee;
    };

    const removeFeeGroup = useCallback(
        (feeGroupIndex) => {
            if (fee?.feeGroups[feeGroupIndex]?.name === TRIP_CHARGES) {
                return;
            }
            const fgArray = fee?.feeGroups?.filter(
                (_, fIndex) => fIndex !== feeGroupIndex
            );
            updateFee("feeGroups", fgArray);
        },
        [fee?.feeGroups, updateFee]
    );

    const isVisible = (billIndex) => {
        if (type == "Client") {
            return billingEditState?.client
        } else if (type == "Supplier") {
            return (billingEditState?.supplier == billIndex)
        }
    }

    return (
        <>
            <div className={`ps-${forInvoice ? "0" : "3"} pe-3 pt-2 w-100`}>
                {fee?.feeGroups && fee?.feeGroups.length > 0 && (
                    fee?.feeGroups?.map((feeGroup, index) =>
                        (isVisible(billingIndex)) ?
                            //edit state of fee group
                            <div style={{ position: "relative" }}>
                                {fee?.feeGroups?.length !== 1 && (
                                    <RxCross2 style={{
                                        border: "1px solid red",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                        padding: "2px",
                                        cursor: "pointer",
                                        position: "absolute",
                                        right: '0px',
                                        top: '0px'
                                    }} color="red" onClick={() => {
                                        removeFeeGroup(index)
                                    }} fontSize="16" />
                                )}
                                <FeeGroup
                                    className="w-100"
                                    formConfig={formConfig}
                                    onDeleteFeeGroup={() => removeFeeGroup(index)}
                                    key={index}
                                    keyIndex={index}
                                    onChange={(newFeeGroup) =>
                                        updateFeeGroupArray(index, newFeeGroup)
                                    }
                                    feeGroup={feeGroup}
                                    type={type}
                                    billingEditState={billingEditState}
                                    rateNames={rateNames}
                                />

                                {type == "Client" && < hr className="my-1" />}
                            </div> :
                            (<>
                                {feeGroup.name === 'ZERO TAX' &&
                                    <div className="tag-item-inline" style={{ color: "#fff", backgroundColor: `#a2a2a2`, fontSize: 12, fontWeight: 500, width: "fit-content" }}>
                                        Non Taxable Items
                                    </div>
                                }
                                <FeeGroupAsLabel
                                    key={index}
                                    rateNames={rateNames}
                                    feeGroup={feeGroup}
                                />
                                {type == "Client" && < hr className="my-1" />}
                            </>)
                    )
                )}
            </div>
            {fee &&
                <div className="px-3 py-2">
                    <table className="">
                        <FeeSummaryRow title="Sub Total" value={((fee.subTotal ?? 0) / 100).toFixed(2)} />
                        <FeeSummaryRow title="Total Taxes" value={((fee.totalTaxes ?? 0) / 100).toFixed(2)} />
                        <FeeSummaryRow title="Total" value={((fee.total ?? 0) / 100).toFixed(2)} />
                        {type === "Client" && margin !== null && <FeeSummaryRow type={margin < 0 ? 'danger' : 'success'} title="Margin" value={((margin ?? 0) / 100).toFixed(2)} />}
                    </table>
                </div>
            }
        </>
    );
};
export default BookingFee;