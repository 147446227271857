import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { SelectField } from "../../SelectField/SelectField";
import { I18nContext } from "../../../store/context/i18nContext";

const PaymentStatusSelect = ({
  data,
  plain,
  totalRows,
  asLabel,
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  outline,
  inline,
  borderNone,
  multi,
  withImage,
}) => {
  const { t } = useContext(I18nContext);
  const paymentStatus = data.map((status) => {
    return {
      ...status,
      value: status?.value,
      label: (
        <div className="d-flex justify-content-start align-items-center">
          <div>
            {/*
              <div>
              <Tooltip
                            style={{ zIndex: "1000" }}
                            anchorSelect="#not-clickable-isBooker-active"
                          >
                            <div>Active</div>
                          </Tooltip> </div>*/}
            {status?.renderer()}
          </div>
          <div className="ms-2" style={{ verticalAlign: "top" }}>
            {status?.label}
          </div>
        </div>
      ),
    };
  }); 
  const renderSelectField = ({ disabled }) => (
    <SelectField
      plain={plain ? plain : null}
      style={style}
      isDisabled={isDisabled || disabled}
      className={className}
      onChange={onChange}
      data={paymentStatus}
      required={required}
      value={value}
      label={label || (!isDisabled && t("Payment Status"))}
      error={!value ? t(`Please Select ${label || "Payment Status"}`) : ""}
      showErr={showErr}
      inline={inline}
      outline={outline}
      borderNone={borderNone}
    />
  );

  return (
    <>
      {asLabel ? (
        <div>
          {paymentStatus?.find((paymentStatus) => paymentStatus?.value == value)
            ?.label ? (
            <>
              <div className="d-flex align-items-center ">
                {/* {
            <Image style={{
            display: "inline",
            maxHeight: "15px",
            maxWidth: "30px",
            marginRight: "10px"
          }} src={paymentStatus?.find((paymentStatus) => paymentStatus?.value == value)?.pic} />}  */}
                {
                  paymentStatus?.find(
                    (paymentStatus) => paymentStatus?.value == value
                  )?.renderer
                }{" "}
                {
                  paymentStatus?.find(
                    (paymentStatus) => paymentStatus?.value == value
                  )?.label
                }
              </div>
            </>
          ) : (
            <div style={{ color: "grey" }}>--</div>
          )}
        </div>
      ) : (
        <>
          {paymentStatus?.length > 0
            ? renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      )}
    </>
  );
};

export default observer(PaymentStatusSelect);
