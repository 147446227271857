import { makeAutoObservable, toJS } from "mobx";
import { ENDPOINTS } from "./NewFuelConstant";
import { doGET, doPOST } from "../../util/HttpUtil";

class Service {
  records = [];
  totalRecords = 0;
  country = [];
  page = 1;
  rows = 20;
  filterOption = "";
  error = "";

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterUrl) => {
    if (filterUrl) this.filterOption = filterUrl;
    else this.filterOption = {};
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rows, filterUrl)
    );
    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
      return response.data;
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      return response.data;
    }
  };

  save = async (data) => {
    const response = await doPOST(ENDPOINTS.save, data);
    return response;
  };

  delete = async (id) => {
    const response = await doGET(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };

  upload = async (id) => {
    const response = await doPOST(ENDPOINTS.flagUpload(id));
    if (response.status === 200) {
      return response.data;
    }
  };

  downloadByTemplate = async (filterData, template_id) => {
    const response = await doGET(
      ENDPOINTS.download(this.downloadFilterOption, template_id)
    );

    if (response.status === 200) {
      console.log(response);
      return response;
    }
  };
  setDownloadFilter = (filterData) => {
    if (filterData) this.downloadFilterOption = filterData;
    else this.downloadFilterOption = "";
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch(this.filterOption);
  };
}

const NewFuel = new Service();
export default NewFuel;
