import React, { useEffect, useMemo, useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    UncontrolledDropdown,
} from 'reactstrap';
import './styles.css'



function LayoutHeaderDropdown({ data, value, isDisabled = false, onChange = () => { }, style = {}, customValue = null }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        setSelected(data?.find(trip => trip?.value === value))
    }, [data, value])

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <div className="trip-type-menu" style={style}>
            <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                <DropdownToggle
                    caret={!customValue}
                    outline={true}
                    color="light"
                    // color="#ced4da"
                    className="mx-2 d-flex w-100 align-items-center  fw-bold text-black  rounded-2"
                    style={{border:'1px solid #d9d9d9',  minWidth: '100px' }}
                >
                    {customValue ?
                        customValue :
                        <div className='d-flex flex-1 '>
                            {selected?.label}
                        </div>}
                </DropdownToggle>

                <DropdownMenu className='shadow'>
                    {data?.map((item, index) => (

                        <DropdownItem key={index} onClick={() => onChange(item.value)}>
                            {item?.label}
                        </DropdownItem>
                    ))}

                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
}



export default LayoutHeaderDropdown;
