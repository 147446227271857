import { makeAutoObservable } from "mobx";
import { doGET, doPOST } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../Constant";

class NameMasterSelect {
  nameMaster = [];
  version = 0;
  constructor() {
    makeAutoObservable(this);
  }
  fetch = async function (kind) {
    try {
      const response = await doGET(ENDPOINTS.getName(kind));
      if (response.status === 200) {
        this.nameMaster = response.data?.rows;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };

  delete = async function (id) {
    const response = await doGET(ENDPOINTS.deleteName(id))
    if (response.status === 200) {
      this.fetch();
    }
  };
  save = async function (data) {
    await doPOST(ENDPOINTS.saveName, data)
    this.fetch();
  };
}

const NameMasterService = new NameMasterSelect();
export default NameMasterService;