import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { CardThreeBounce, InputField, MakeModelSelect, CountrySelect } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import VehicleTypeService from "./VehicleTypeService";
import FileUpload from "../../components/DocUpload/FileUpload";

const EditVehicleTypes = ({ editId, onChange = () => { }, hasErr }) => {

  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetch = async () => {
    try {
      let vehicleTypes = await VehicleTypeService.get(editId);
      setData(vehicleTypes);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(null)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, VehicleTypeService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  return (
    <React.Fragment>
      {loading ?
        <CardThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row>
            <InputField
              placeholder={t("Vehicle Type ")}
              value={data?.name}
              required={true}
              label={t("Vehicle Type")}
              onChange={(v) => setData({ ...data, name: v })}
              error={data?.name?.length === 0 ? t("Please enter VehicleType Name") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-12"
            />
            <InputField
              placeholder={t("Make Model")}
              value={data?.makeModels}
              required={true}
              label={t("Model Type")}
              onChange={(v) => setData({ ...data, makeModels: v })}
              error={data?.makeModels?.length === 0 ? t("Please enter Model Name") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-12"
            />
            <MakeModelSelect
              value={data?.defaultMakeModel}
              onChange={(v) => setData({ ...data, defaultMakeModel: v?.value })}
              className="col-sm-12"
            />
            <CountrySelect
              placeholder={t("Country ")}
              value={data?.country}
              label={t("Country")}
              onChange={(v) => {
                setData({ ...data, country: v.value })
              }}
              type="text"
              className="col-sm-12"
            />
            <FileUpload
              type="image"
              value={data?.pic}
              id={data?._id}
              module={"vehicle-type"}
              field={"pic"}
              onUpload={() => {
                fetch()
              }}
            />
          </Row>
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditVehicleTypes);



