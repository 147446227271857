import { makeAutoObservable } from "mobx";
import SupervisorSelectService from "../../components/Dropdowns/SuperviosrSelect/SupervisorSelectService";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { ENDPOINTS } from "./SupervisorConstant";
import { getUiPrefRows } from "../../util/FilterUtil";
class Service {
    records = [];
    STRUCTURE = [];
    page = 1;
    rowsPerPage = null;
    filterOption = "";
    total = -1;
    version = 0;

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async (filterUrl) => {
        if(!this?.rowsPerPage) {
            const rows = await getUiPrefRows("Supervisor.grid")
           this.rowsPerPage = rows ?? 40
          }

        if (filterUrl) {
            this.filterOption = filterUrl;
        }
        const response = await doGET(
            ENDPOINTS.grid({ page: this.page, sizePerPage: this.rowsPerPage, filterUrl })
        );

        if (response?.status === 200) {
            this.records = response.data.rows;
            this.page = response?.data?.page;
            if (this.page === 1) this.total = response?.data?.total;
        }
        return response.data.rows

    };


    edit = async function ({ data, corp_id }) {
        const response = await doPUT(ENDPOINTS?.update({ corp_id }), { ...data, corp_id: corp_id });
        if (response?.status === 200) {
            this.version += 1;

            SupervisorSelectService?.fetch({})
        }
    };
    get = async ({ editId, corp_id }) => {

        const response = await doGET(ENDPOINTS.get({ editId, corp_id }));
        console.log(ENDPOINTS.get({ editId, corp_id }))
        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };


    save = async ({ data, corp_id }) => {
        this.records.push(data);
        const response = await doPOST(ENDPOINTS.save(), data);
        if (response?.status === 200) {
            SupervisorSelectService?.fetch({})
        }
        return response?.data?._id;
    };


    delete = async (id, corp_id) => {
        const response = await doDELETE(ENDPOINTS.delete(id, corp_id));
        if (response.status === 200) {
            if (corp_id) {
                const filterUrl = "corp_id=" + corp_id;
                this.fetch(filterUrl);

            } else {
                this.fetch();

            }
        }
    };

    bulkDelete = async (ids = []) => {
        const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
        if (response.status === 200) {
            this.fetch();
        }
    };

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch(this.filterOption);

    };

}

const SupervisorService = new Service();
export default SupervisorService;
