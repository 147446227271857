export const GridColumns = {
    version: 1,
    columns: [
        {
            title: " Name",
            accessor: "name",
            show: true,
        },
        {
            title: "Currency",
            accessor: "currency",
            show: true,
            width: 200
        },
        {
            title: "ISD",
            accessor: "isd",
            show: true,
        },
        {
            title: "isoCode",
            accessor: "isoCode",
            show: true,
        },
        {
            title: "isoCode2",
            accessor: "isoCode2",
            show: true,
            width: 200
        },
        // {
        //     title: "synonyms ",
        //     accessor: "synonyms",
        //     show: true,
        //     width: 300,
        // },
        {
            title: "Timezone ",
            accessor: "timeZone",
            show: true,
            width: 200,
        },
        {
            title: "Flag ",
            accessor: "flag",
            renderer: (dataObj) => {
                return <img height="20px" src={dataObj?.flag} />;
            },
            show: true,
            width: 200,
        },

        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
