import React from 'react'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "./style.css"


export default function EditorField(props) {
    return (
        <React.Fragment>
            <Editor
                initialContentState={props?.value}
                onContentStateChange={props?.onChange}
                // editorState={props?.value}
                toolbarClassName="toolbarClassName"
                wrapperClassName="editor__wrapper"
                editorClassName="editor__class"
                showErr={props?.showErr}
            />
        </React.Fragment>
    )
}
