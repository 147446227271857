import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import { UserContext } from "../../../store/context/UserContext";
import { I18nContext } from "../../../store/context/i18nContext";

import { MdDashboard, MdEmojiTransportation } from "react-icons/md";
import { FaFileInvoice, FaHotel, FaMapMarkedAlt, FaPlane } from "react-icons/fa";
import { BiSolidOffer } from "react-icons/bi";
import { MdDirectionsCar } from "react-icons/md";
import { BsFillFuelPumpFill, BsSuitcaseFill } from "react-icons/bs";
import { FaRoute } from "react-icons/fa";
import { MdAccountBalance, MdLiveHelp } from "react-icons/md";
import { GiHomeGarage } from "react-icons/gi";
import { GiTicket } from "react-icons/gi";
import { HiTemplate } from "react-icons/hi";
import { HiOfficeBuilding } from "react-icons/hi";
import { IoMdContact } from "react-icons/io";
import { MdFeedback } from "react-icons/md";
import { FaTag } from "react-icons/fa6";
import { GrDocumentConfig } from "react-icons/gr";
import { IoPeople } from "react-icons/io5";
import { GoTag } from "react-icons/go";
import { FaUserTie } from "react-icons/fa6";
import { FaCarOn } from "react-icons/fa6";
import { FaTicketAlt } from "react-icons/fa";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";
import { TbReportMoney } from "react-icons/tb";
import { FaPersonFallingBurst } from "react-icons/fa6";
import { FaFileCircleCheck } from "react-icons/fa6";
import { FaHandHoldingUsd } from "react-icons/fa";
import { FaHandHoldingHand } from "react-icons/fa6";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { FaMoneyCheck } from "react-icons/fa6";
import { TbReportAnalytics } from "react-icons/tb";
import { FaCalendarAlt } from "react-icons/fa";
import { FaBuildingColumns } from "react-icons/fa6";
import { FaRegCalendarDays } from "react-icons/fa6";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaUserClock } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa6";
import { BsClipboard2CheckFill } from "react-icons/bs";
import { GiVerticalBanner } from "react-icons/gi";
import { FaTags } from "react-icons/fa";
import { TbReport } from "react-icons/tb";
import { FaSackDollar } from "react-icons/fa6";
import { GrUserWorker } from "react-icons/gr";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { FaCaravan } from "react-icons/fa6";
import { FaUserNurse } from "react-icons/fa";
import { FaLanguage } from "react-icons/fa";
import { FaTreeCity } from "react-icons/fa6";
import { FaUserSecret } from "react-icons/fa";
import { GiPoliceOfficerHead } from "react-icons/gi";
import { FaBuildingUser } from "react-icons/fa6";
import { MdRateReview } from "react-icons/md";
import { FaCar } from "react-icons/fa";
import { LuGlobe2 } from "react-icons/lu";
import { FaMapSigns } from "react-icons/fa";
import { IoLogoModelS } from "react-icons/io";
import { MdEditDocument } from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { GrTransaction } from "react-icons/gr";

const MenuDropdown = () => {
  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [path, setPath] = useState("");
  const location = useLocation();

  useEffect(() => {
    const p = window.location.pathname.split("/");
    setPath(p[1]);
  }, [location.pathname]);

  const menuItem = (to, label, accessRight, icon) => {
    return !accessRight || userContent?.rights?.includes(accessRight) || (userContent?.userType && userContent?.userType === "super-admin") ? (
      <Link to={to}>
        <DropdownItem
          className={`menu-subhead ${path === to.substring(1) ? " menu-active " : ""
            }`}
        >
          <div className="d-flex align-items-center">
            {icon ?
              <div className="me-2 font-size-16">
                {icon}
              </div>
              :
              <i
                className={`bx  me-2 ${path === to.substring(1) ? " bxs-circle" : "bx-circle"
                  }`}
                style={{ fontSize: "12px" }}
              />
            }
            <div style={{
              position: 'relative',
              top: "2px"
            }}>
              {label}
            </div>
          </div>


        </DropdownItem>
      </Link>
    ) : null;
  };

  return (
    <div className="m-auto" key={userContent?.rights}>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className="d-inline-block"
      >
        <div className="container">
          <span className="text-center" style={{ alignItems: "center" }}>
            <DropdownToggle
              className="btn header-item waves-effect me-3"
              tag="button"
            >
              <i
                className="bx bxs-dashboard  mt-0"
                style={{ fontSize: "27px", marginRight: "4px", float: "left" }}
              />
            </DropdownToggle>
            {/* 
            {userContent?.rights?.includes(6000) ? (
              <Dropdown className="d-none d-sm-inline  btn header-item waves-effect" tag="button">
                <Link to="/booking" className="dropdown-item">
                  <div
                    className="d-none d-xl-block d-lg-block d-flex"
                    style={{ fontSize: "18px", float: "left" }}
                  >
                    {t("Bookings")}
                  </div>
                </Link>
              </Dropdown>
            ) : null}

            {userContent?.rights?.includes(4500) ? (
              <Dropdown className="d-none d-sm-inline btn header-item waves-effect" tag="button">
                <Link to="/invoice" className="dropdown-item">
                  <div
                    className="d-none d-xl-block d-lg-block d-flex"
                    style={{ fontSize: "18px", float: "left" }}
                  >
                    {t("Invoice")}
                  </div>
                </Link>
              </Dropdown>
            ) : null}
            {userContent?.rights?.includes(3400) ? (
              <Dropdown className="d-none d-sm-inline  btn header-item waves-effect" tag="button">
                <Link to="/marketplace" className="dropdown-item">
                  <div
                    className="d-none d-xl-block d-lg-block d-flex"
                    style={{ fontSize: "18px", float: "left" }}
                  >
                    {t("Marketplace")}
                  </div>
                </Link>
              </Dropdown>
            ) : null} */}
          </span>
        </div>

        <DropdownMenu className="dropdown-menu-main dropdown-menu-xxl  p-4">
          {!userContent?.rights?.includes(1) ? (
            <Row className="">
              <Col className="col-12 col-xl-2 col-md-4">
                <DropdownItem className="menu-heading" header>
                  {t("Operations")}
                </DropdownItem>
                {menuItem("/dashboard", t("Dashboard"), 6000, <MdDashboard />)}
                {menuItem("/booking", t("Booking"), 6000, <FaTicketAlt />)}
                {menuItem("/invoice", t("Invoice"), 4500, <FaFileInvoice />)}
                {/* {menuItem("/route", t("Route"), 5600, <FaRoute />)} */}
                {menuItem("/rate", t("Rate"), 1900, <FaTag />)}
                {/* {menuItem("/addon", t("Addon Rate"), 3800, <GoTag />)} */}
                {/* {menuItem("/offer", t("Offers"), 5000, <BiSolidOffer size={18} />)} */}
                {menuItem("/rostering", t("Rostering"), 1500, <FaRoute />)}
                {menuItem("/request", t("Adhoc Request"), 1500, <FaCarOn />)}
                {menuItem("/user", t("Customers"), 3500, <IoPeople size={18} />)}
                {menuItem("/itinerary", t("Itinerary"), 6100, <FaMapMarkedAlt />)}
                {menuItem("/flight", t("Flight"), 6100, <FaPlane />)}
                {menuItem("/stay", t("Stay"), 6100, <FaHotel />)}
                {/* {menuItem("/group-tour", t("Group Tour"), 6100, <IoPeople size={18} />)} */}
                {menuItem("/tour", t("Tours"), 6100, <BsSuitcaseFill />)}
                {menuItem("/guests", t("Guests"), 3500, <IoPeople size={18} />)}

              </Col>

              <Col className="col-12 col-xl-2 col-md-4 border-left">
                <DropdownItem className="menu-heading" header>
                  {t("Finance")}
                </DropdownItem>
                {menuItem("/invoiceconfig", t("Invoice Config"), 4600, <LiaFileInvoiceDollarSolid />)}
                {menuItem("/creditNote", t("Credit Note"), 4610, <LiaFileInvoiceSolid />)}
                {menuItem("/bill", t("Bills"), 4100, <FaMoneyCheck />)}
                {menuItem("/payment-made", t("Payment Made"), 4300, <FaHandHoldingHand />)}
                {menuItem("/payment-received", t("Payment Received"), 4300, <FaFileInvoiceDollar />)}
                {menuItem("/accounts", t("Accounts"), 4100, <MdAccountBalance />)}
                {menuItem("/tax", t("Tax"), 2300, <FaSackDollar />)}
                {menuItem("/expense", t("Expense"), 4400, <FaHandHoldingUsd size={18} />)}
                {menuItem("/transaction", t("Transaction"), 1500, <GrTransaction size={20} />)}
                {menuItem("/payment-gateway-account", t("Payment Gateway"), 4100, <FaMoneyCheckAlt />)}
                {menuItem("/term", t("Payment Terms"), 1900, <FaFileCircleCheck size={18} />)}
              </Col>

              <Col className="col-12 col-xl-2 col-md-4 border-left">
                <DropdownItem className="menu-heading" header>
                  {t("Report")}
                </DropdownItem>
                {menuItem("/coming-soon", t("Invoice Report"), null, <TbReportMoney size={18} />)}
                {/* {menuItem("/timesheet", t("Timesheet"), 5200, <FaCalendarAlt />)} */}
                {menuItem("/feedback", t("Feedback"), 4000, <MdFeedback />)}
                {menuItem("/incident", t("Incident"), 5900, <FaPersonFallingBurst />)}
                {menuItem("/fuel", t("Fuel"), 3800, <BsFillFuelPumpFill />)}
              </Col>

              <Col className="col-12 col-xl-2 col-md-4 border-left">
                <DropdownItem className="menu-heading" header>
                  {t("Masters")}
                </DropdownItem>
                {menuItem("/vehicle", t("Vehicle"), 2600, <MdDirectionsCar size={18} />)}
                {menuItem("/employee", t("Employee"), 1400, <FaUserNurse />)}
                {userContent?.accountType === "transporter" ? menuItem("/client", t("Clients"), 1300, <FaUserTie />) : ""}
                {userContent?.accountType === "transporter" ? menuItem("/supplier", t("Suppliers"), 2700, <FaCaravan />) : ""}

                {menuItem("/office", t("Offices"), 1500, <HiOfficeBuilding size={18} />)}
                {menuItem("/company", t("My Companies"), 1700, <FaBuildingColumns />)}
                {menuItem("/garage", t("Garages"), 1500, <GiHomeGarage />)}
                {menuItem("/workshift", t("Shift"), 2000, <FaRegCalendarDays />)}
                {menuItem("/contact-person", t("Contact Person"), 1500, <IoMdContact size={20} />)}

                {/* {menuItem("/translations", t("Translations"))} */}
                {/* {menuItem("/fuel-price", t("Fuel-Price"),3900)}
                  {menuItem("/fuel-credit", t("Fuel-credit"),3900)} */}
                {/* {menuItem("/fundaccounts", t("Fund Account"))} */}
                {/* {menuItem("/transaction", t("Transaction"), 1500,<GrTransaction size={20}/>)} */}
                {/* {menuItem("/paymentreceived", t("Payment Received"), 4300)} */}
              </Col>

              <Col className="col-12 col-xl-2 col-md-4 border-left">
                <DropdownItem className="menu-heading" header>
                  {t("Users")}
                </DropdownItem>

                {menuItem("/supervisor", t("Supervisors"), 2100, <GrUserWorker />)}
                {menuItem("/role", t("Role"), 2200, <FaUsers />)}
                {menuItem("/zones", t("Zone"), 2800, <FaMapLocationDot />)}
                {menuItem("/leave", t("Leave"), 4700, <FaUserClock />)}
                {menuItem("/leads", t("Leads"), 1500, <MdLiveHelp />)}
              </Col>

              <Col className="col-12 col-xl-2 col-md-4 border-left">
                <DropdownItem className="menu-heading" header>
                  {t("Configurations")}
                </DropdownItem>

                {menuItem("/configurations", t("Configuration"), 1500, <GrDocumentConfig />)}
                {menuItem("/smsLogs", t("Logs"), 1100, <TbReport size={18} />)}
                {/* {menuItem("/tag-element", t("Email Logs"), 1500)} */}
                {menuItem("/custom-field", t("Custom Fields"), 1500, <MdFormatListBulletedAdd size={18} />)}

                {menuItem("/payment-gateway", t("Payment Gateway"), 21)}
                {menuItem("/qc", t("Quality Check"), 5300, <BsClipboard2CheckFill />)}
                {menuItem("/templates", t("Templates"), 2400, <HiTemplate size={18} />)}
                {/* {menuItem("/banner", t("Banner"), 2400, <GiVerticalBanner />)} */}

                <DropdownItem className="menu-heading py-2" header>
                  {t("Support")}
                </DropdownItem>

                {menuItem("/tickets", t("Tickets"), 5900, <GiTicket size={20} />)}
              </Col>
            </Row>
          ) : (
            <Row style={{ overflow: "hidden" }}>
              <Col className="col-12 col-sm-2 border-left">
                <DropdownItem className="menu-heading" header>
                  {t("Masters")}
                </DropdownItem>
                {menuItem("/vehicle-type", t("Vehicle Type"), 20, <FaCar />)}
                {menuItem("/doc-category", t("Document Category"), 20, <MdEditDocument />)}
                {menuItem("/superAdmin", t("Super Admin"), 20, <GiPoliceOfficerHead size={18} />)}
                {menuItem("/role", t("Role"), 2200, <FaUsers />)}
                {menuItem("/make-model", t("Make Model"), 20, <IoLogoModelS size={18} />)}
                {menuItem("/country", t("Country"), 20, <LuGlobe2 />)}
                {menuItem("/state", t("State"), 20, <FaMapSigns />)}
                {menuItem("/city", t("City"), 20, <FaTreeCity />)}
                {menuItem("/agent", t("Message Agent"), 20, <FaUserSecret />)}
                {menuItem("/messagetemplate", t("Message Templates"), 20, <HiTemplate size={18} />)}
                {menuItem("/language", t("Language"), 20, <FaLanguage />)}
                {menuItem("/rateName", t("RateName"), 20, <MdRateReview />)}
                {menuItem("/corporate", t("Corporate"), 20, <FaBuildingUser />)}
                {menuItem("/payment-gateway", t("Payment Gateway"), 21, <FaMoneyCheckAlt />)}
                {menuItem("/tax", t("Tax"), 20, <FaSackDollar />)}
                {menuItem("/tickets", t("Tickets"), 20, <GiTicket size={20} />)}
              </Col>
            </Row>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default MenuDropdown;
