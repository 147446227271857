import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Garage from "../../../pages/Garage/Garage";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, ModalComponent, SearchButton } from "../../index";
import GarageSelectService from "./GarageSelectService";

const GarageSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  module,
  showErr,
  outline,
  inline,
  borderNone,
  multi,
      ...props 

}) => {
  const [garages, setGarages] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const fetchGarages = async () => {
    try {
      await GarageSelectService.fetch();
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (GarageSelectService.garages.length === 0) fetchGarages();
  }, []);

  const updateGarages = () => {
    setGarages(
      toJS(GarageSelectService.garages)?.map((garage) => {
        return {
          ...garage,
          value: garage._id,
          label: module == "Booking" ? <div> {garage?.code}, {garage?.address?.city}</div> : garage.code
        };
      }).filter(Boolean)
    );
  }

  useEffect(() => {
    updateGarages();
  }, [GarageSelectService.garages]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectGarage = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0] : selectedValue });
    closeModal();
  };

  const selectGarages = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const filterOption = useCallback((option, inputValue) => {
    return (
      !inputValue ||
      option.data?.code?.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.data?.address.city?.toLowerCase().includes(inputValue.toLowerCase())
    );
  }, []);
  const renderSelectField = ({ disabled }) => (
    <SelectField
      filterOption={filterOption}
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Garages")} />
      )}
      outline={outline}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={garages}
      required={required}
      value={value}
      label={label || (!isDisabled && t("Garage"))}
      error={!value ? t(`Please Select ${label || "Garage"}`) : ""}
      showErr={showErr}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
      {...props}

    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      filterOption={filterOption}
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Garages")} />
      )}
      outline={outline}
      // showBottomBar={true}
      className={className}
      onChange={selectGarages}
      data={garages}
      value={value ? garages.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Garages"}`) : ""}
      required={required}
      label={label || (!isDisabled && t("Select Garages"))}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
      {modal && (
        <ModalComponent
          position={"top"}
          size={"large"}
          onToggle={closeModal}
          isOpen={modal}
        >
          <ModalComponent.Title>
            {t("Employee")}
          </ModalComponent.Title>

          <ModalComponent.Body>
            <Garage
              value={value}
              insidePane={true}
              multiMode={multi}
              onSelect={multi ? selectGarages : selectGarage}
            />
          </ModalComponent.Body>

        </ModalComponent>
      )}


      {garages.length > 0
        ? multi
          ? renderSelectFieldMultiple()
          : renderSelectField({ disabled: false })
        : renderSelectField({ disabled: true })}
    </>
  );
};

export default observer(GarageSelect);
