import React, { useEffect } from "react";
import { FormGroup, Label } from "reactstrap";
import { GoPlus } from "react-icons/go";
import { BiMinus } from "react-icons/bi";
import { AddressField } from "../../../../../components";
import "./style.scss";

const Places = ({ places = [{}], setPlaces }) => {
    useEffect(() => {
        if (places.length === 0) {
            addPlace();
        }
    }, [places]);

    const addPlace = () => setPlaces([...places, {}]);

    const removePlace = (index) => setPlaces(places.filter((_, i) => i !== index));

    const handlePlaceChange = (index, value) => {
        setPlaces(
            places.map((place, i) =>
                i === index ? value : place
            )
        );
    };

    return (
        <FormGroup>

            {(places ?? []).map((place, index) => (
                <div key={index} className="d-flex my-2 align-items-center">
                    <AddressField
                        placeholder="Address"
                        label="Address"
                        outline
                        value={place}
                        className={"flex-1"}
                        onChange={(v) => handlePlaceChange(index, v)}
                    />
                    {places.length > 1 && (
                        <div onClick={() => removePlace(index)} className="action-icon-zr  ">
                            <div className="btn btn-outline-dark d-flex align-items-center action-btn-zr">
                                <BiMinus fontSize="16" />
                            </div>
                        </div>
                    )}
                    {(places.length == index + 1) ? <div onClick={addPlace} className="action-icon-zr ms-2 ">
                        <div className="btn btn-outline-dark d-flex align-items-center action-btn-zr">
                            <GoPlus fontSize="16" />
                        </div>
                    </div> : <div style={{ width: "28px" }}></div>}
                </div>
            ))}

        </FormGroup>
    );
};

export default Places;
