import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { InputField, CurrencySelect } from "../../../../../components";

export default function BankDetails({ data, setData }) {
  const [hasErr, setHasErr] = useState(false);
  useEffect(() => { }, [data]);

  return (
    <div className="active ">
      <Row className="mt-3 mb-3 ">
        <Col className="col-12 col-sm-6 mb-3">
          <InputField
            placeholder="Bank"
            value={data?.bankAccount?.bank}
            label={"Bank"}
            onChange={(v) =>
              setData({
                ...data,
                bankAccount: {
                  ...data?.bankAccount,
                  bank: v,
                },
              })
            }
            showErr={hasErr}

            type="text"
          />
        </Col>

        <Col className="col-12 col-sm-6">
          <InputField
            placeholder="Bank Account No"
            value={data?.bankAccount?.accountNo}
            label={"Bank Account No"}
            onChange={(v) =>
              setData({
                ...data,
                bankAccount: {
                  ...data?.bankAccount,
                  accountNo: v,
                },
              })
            }
            showErr={hasErr}

            type="number"
          />
        </Col>
      </Row>

      <Row className="mt-3 mb-3 ">
        <Col className="col-12 col-sm-6 mb-3">
          <InputField
            placeholder="IFSC Code"
            value={data?.bankAccount?.ifsc}
            label={"IFSC Code"}
            onChange={(v) =>
              setData({
                ...data,
                bankAccount: {
                  ...data?.bankAccount,
                  ifsc: v,
                },
              })
            }
            showErr={hasErr}

            type="text"
          />
        </Col>

        <Col className="col-12 col-sm-6">
          <InputField
            placeholder="Holder Name"
            value={data?.bankAccount?.holderName}
            label={"Holder Name"}
            onChange={(v) =>
              setData({
                ...data,
                bankAccount: {
                  ...data?.bankAccount,
                  holderName: v,
                },
              })
            }
            showErr={hasErr}

            type="text"
          />
        </Col>
      </Row>

      <Row className="mt-3 mb-3 ">
        <Col className="col-12 col-sm-6 mb-3">
          <InputField
            placeholder="Account Type"
            value={data?.bankAccount?.accountType}
            label={"Account Type"}
            onChange={(v) =>
              setData({
                ...data,
                bankAccount: {
                  ...data?.bankAccount,
                  accountType: v,
                },
              })
            }
            showErr={hasErr}

            type="text"
          />
        </Col>

        <Col className="col-12 col-sm-6">
          <InputField
            placeholder="Branch Name"
            value={data?.bankAccount?.branchName}
            label={"Branch Name"}
            onChange={(v) =>
              setData({
                ...data,
                bankAccount: {
                  ...data?.bankAccount,
                  branchName: v,
                },
              })
            }
            showErr={hasErr}

            type="text"
          />
        </Col>
      </Row>

      <Row className="mt-3 mb-3 ">
        <Col className="col-12 col-sm-6 mb-3">
          <InputField
            placeholder="Bank Name"
            value={data?.bankAccount?.bankName}
            label={"Bank Name"}
            onChange={(v) =>
              setData({
                ...data,
                bankAccount: {
                  ...data?.bankAccount,
                  bankName: v,
                },
              })
            }
            showErr={hasErr}

            type="text"
          />
        </Col>

        <Col className="col-12 col-sm-6">
          <InputField
            placeholder="Swift Code"
            value={data?.bankAccount?.swiftCode}
            label={"Swift Code"}
            onChange={(v) =>
              setData({
                ...data,
                bankAccount: {
                  ...data?.bankAccount,
                  swiftCode: v,
                },
              })
            }
            showErr={hasErr}

            type="text"
          />
        </Col>
      </Row>

      <Row className="mt-3 mb-3 ">
        <Col className="col-12 col-sm-6 mb-3">
          <InputField
            placeholder="Acquirer Account Code"
            value={data?.bankAccount?.acquirerAccCode}
            label={"Acquirer Account Code"}
            onChange={(v) =>
              setData({
                ...data,
                bankAccount: {
                  ...data?.bankAccount,
                  acquirerAccCode: v,
                },
              })
            }
            showErr={hasErr}

            type="number"
          />
        </Col>

        <Col className="col-12 col-sm-6">
          <CurrencySelect
            showErr={hasErr}
            required={true}
            value={data?.bankAccount?.currency}
            onChange={(v) =>
              setData({
                ...data,
                bankAccount: {
                  ...data?.bankAccount,
                  currency: v.value,
                },
              })
            }
          />
        </Col>
      </Row>
    </div>
  );
}
