import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET, doPOST } from "../../../util/HttpUtil";

class SupervisorRoleSelect {
    supervisorRoles = [null];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filterData) {
        if (this.supervisorRoles[0] != null)
            return;
        try {
            const response = await doGET(ENDPOINTS.getAllSupervisorRoles);
            if (response.status === 200) {
                ;
                this.supervisorRoles = Object.keys(response.data).map((k) => { return { label: response.data[k], value: k } });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const SupervisorRoleSelectService = new SupervisorRoleSelect();
export default SupervisorRoleSelectService;
