
import { CompanySelect } from "../../components";

export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Account Number",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.bankAccount?.accountNo}
          </div>
        );
      },
      show: true,
    },
    {
      title: "Bank Name",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.bankAccount?.bankName}
          </div>
        );
      },
      show: true,
    },
    {
      title: "Account Holder Name",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.bankAccount?.holderName}
          </div>
        );
      },
      show: true,
    },
    {
      title: "IFSC Code",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.bankAccount?.ifsc}
          </div>
        );
      },
      show: true,
    },
    {
      title: "Phone Number",
      renderer: (dataObj) => {
        return (
          <div>
            <p className="w-100 text-start  ">
              +{dataObj?.bankAccount?.holderPhone?.countryCode}-{dataObj?.bankAccount?.holderPhone?.phone}
            </p>
          </div>
        );
      },
      show: true,
    },

    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },

    {
      title: "Active",
      accessor: "active",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.active ? 'True' :
            'False'
          }
        </div>
      },
      show: true,
    },
  ],
};
