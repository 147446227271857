import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Row } from "reactstrap";
import { BulkUpload, CardThreeBounce, DateSelect, Documents, InputField, SelectField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "./PermitConstant";
import PermitService from "./PermitService";
import { AutoSuggest } from 'react-autosuggestions';
// import { vehiclePermitData } from "./PermitConstant";
import AutoSuggestSelect from "../../components/AutoSuggestSelect/AutoSuggestSelect";


const EditPermits = ({ editId, permitNameData = [], onChange = () => { }, hasErr, permitOf, assetKind, permintKind = "", permitTitle = "" }) => {

    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const fetch = async () => {
        try {
            let permit = await PermitService.get(editId);
            setData(permit);
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        setData({
            permitOf: permitOf,
            assetKind: assetKind
        });
        setLoading(true);
        if (editId) fetch();
        setLoading(false)
    }, [editId, permitOf, assetKind, PermitService?.version]);


    useEffect(() => {
        onChange(data)
    }, [data]);

    return (
        <React.Fragment>
            {loading ?
                <CardThreeBounce /> :
                <Form
                    className="p-3"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                >
                    <Row className="mt-3">
                        <AutoSuggestSelect
                            label="Permit Name"
                            required
                            className="col-sm-6"
                            data={permitNameData ?? []}
                            value={data?.name}
                            onChange={(event, { newValue }) => {
                                setData({
                                    ...data,
                                    name: newValue
                                })
                            }}
                        />
                        {/* <InputField
                            placeholder={t("Permit Name")}
                            value={data?.name}
                            required={true}
                            label={t("Permit Name")}
                            onChange={(v) => setData({ ...data, name: v })}
                            error={data?.name?.length === 0 ? "Please enter Permit Name" : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6"
                        /> */}
                        <InputField
                            placeholder={t("Permit info")}
                            value={data?.info}
                            label={t("Permit Info")}
                            onChange={(v) => setData({ ...data, info: v })}
                            error={data?.info?.length === 0 ? "Please enter Permit info" : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6"
                        />
                    </Row>
                    <Row className="mt-3 mb-3">
                        <InputField
                            placeholder={t("Cost")}
                            value={data?.cost}
                            label={t("Cost")}
                            onChange={(v) => setData({ ...data, cost: v })}
                            error={data?.cost?.length === 0 ? "Please enter cost" : ""}
                            showErr={hasErr}
                            type="number"
                            className="col-sm-6"
                        />

                        <InputField
                            placeholder={t("Issuing Authority")}
                            value={data?.issuingAuthority}
                            label={t("Issuing Authority")}
                            onChange={(v) => setData({ ...data, issuingAuthority: v })}
                            error={data?.issuingAuthority?.length === 0 ? "Please enter IssuingAuthority" : ""}
                            showErr={hasErr}
                            required={true}
                            type="text"
                            className="col-sm-6"
                        />

                    </Row>
                    <Row className="mt-3 mb-3">
                        <DateSelect
                            placeholder={t("Start Date")}
                            value={data?.startDate}
                            label={t(" Start Date")}
                            onChange={(v) => setData({ ...data, startDate: v })}
                            error={data?.startDate?.length === 0 ? t("Please enter Account Start Date") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6 col-12"
                        />
                        <DateSelect
                            placeholder={t("Expiry")}
                            value={data?.expiry}
                            label={t("Expiry")}
                            onChange={(v) => setData({ ...data, expiry: v })}
                            error={data?.expiry?.length === 0 ? t("Please enter Expiry Date") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6 col-12"
                        />
                    </Row>
                    <Row>
                        {
                            editId ?
                                <Row className="mt-5" >
                                    <Documents
                                        config={{
                                            showCategory: true,
                                            showExpiry: true,
                                        }}
                                        module="permit"
                                        record_id={data?._id} />
                                </Row>
                                :
                                null
                        }
                    </Row>
                </Form>
            }
        </React.Fragment>
    );
};

export default observer(EditPermits);
