import React, { useContext, useEffect, useState } from 'react'
import { ModalComponent } from '../../../components'
import { FaCheck } from "react-icons/fa";
import MarketPlaceStep1 from './MarketPlaceStep1';
import MarketPlaceStep2 from './MarketPlaceStep2';
import MarketPlaceStep3 from './MarketPlaceStep3';
import MarketPlaceStep4 from './MarketPlaceStep4';
import { DialogContext } from '../../../store/context/DialogContext';
import { doPUT } from '../../../util/HttpUtil';
import { useNavigate } from 'react-router-dom';

const MarketPlaceRegister = ({ toggleModal, modalOpen }) => {
    const { showError } = useContext(DialogContext);
    let navigate = useNavigate();
    const [step, setStep] = useState(0);

    const [data, setData] = useState({})

    const updateData = async () => {
        try {
            const response = await doPUT(`/api/brand-profile/update`, data);
            if (response?.status == 200) {
                navigate("/rfp")
            }
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        if (step == 3) {
            updateData()
        }
        if (step > 3) {
            toggleModal()
        }
    }, [step])

    const goBack = () => {
        setStep(Math.max(0, step - 1))
    }

    const goNext = () => {
        try {
            if (step == 0 && data?.brandName && data?.contactPerson?.name && data?.contactPerson?.email && data?.contactPerson?.phone?.phone && data?.contactPerson?.phone?.countryCode) {
                setStep(step + 1);
            } else if (
                step == 1 &&
                data?.employeeCount
            ) {
                setStep(step + 1);
            } else if (
                step == 2 &&
                data?.address?.addressLine &&
                data?.address?.city &&
                data?.address?.state &&
                data?.address?.country
            ) {
                setStep(step + 1);
            } else if (step < 3) {
                showError("Please fill all data");
            }
        } catch (error) { }
    };
    return (
        <ModalComponent
            position=""
            size=""
            modalSizeStyle={{
                top: "-20px"
            }}
            isOpen={modalOpen}
            onToggle={toggleModal}
        >
            <ModalComponent.Body>
                <div className='py-3' style={{
                    display: "flex",
                    flexDirection: "column",

                    height: "70vh",
                    overflow: "hidden",
                    width: "30vw",
                }}>
                    {step < 3 && <div className='d-flex flex-column align-items-center justify-content-between h-100 flex-1'>

                        <div>
                            <div className='d-flex justify-content-center my-1' style={{
                                fontSize: "20px",
                            }}>Register For MarketPlace</div>
                            <div
                                style={{
                                    textAlign: "center",
                                    marginTop: "1px",
                                    marginBottom: "1px",
                                    display: "flex",
                                    paddingLeft: "50px",
                                    paddingRight: "50px",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                {Array.from({ length: 3 }).map((_, index) => (
                                    <React.Fragment key={index}>
                                        <div
                                            style={{
                                                backgroundColor:
                                                    step === index
                                                        ? "#F08180"
                                                        : step > index
                                                            ? "#099206"
                                                            : "#ced4da",
                                                width: "25px",
                                                height: "25px",
                                                borderRadius: "50%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {step > index && (
                                                <div id="myDiv">
                                                    <FaCheck
                                                        style={{
                                                            color: "#fff",
                                                            fontSize: "12px",
                                                            top: "-5px",
                                                            right: "-7px",
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {index < 2 && (
                                            <div
                                                style={{
                                                    width: "105px",
                                                    height: "1px",
                                                    backgroundColor: "#ced4da",
                                                }}
                                            ></div>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>

                            {step == 0 && < MarketPlaceStep1 data={data} setData={setData} />}
                            {step == 1 && <MarketPlaceStep2 data={data} setData={setData} />}
                            {step == 2 && <MarketPlaceStep3 data={data} setData={setData} />}
                        </div>
                        <div className='d-flex justify-content-center align-items-center my-2 mt-5'>
                            <i
                                onClick={goBack}
                                className="bx border p-2 bg-light rounded-5 font-size-22 bx-left-arrow-alt"
                                style={{
                                    marginRight: "20px",
                                    cursor: "pointer",
                                }}
                            />
                            <i
                                onClick={() => {
                                    goNext();
                                }}
                                className="bx border p-2 text-light bg-primary rounded-5 font-size-22  bx-right-arrow-alt"
                                style={{
                                    backgroundColor: "white",
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </div>}
                    {(step == 3) &&
                        <MarketPlaceStep4 setStep={setStep} />
                    }
                </div>
            </ModalComponent.Body>
        </ModalComponent >
    )
}

export default MarketPlaceRegister