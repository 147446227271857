import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { SelectOption } from "../../../components";

const Step3 = ({ onChange }) => {
  const employeeCountOptions = ['1-10', '10-100', '100-500', '500-1000'];
  const [data, setData] = useState({});
  const [employeeCount, setEmployeeCount] = useState('');


  const servicesOption = ['Dedicated car', 'Adhoc', 'Self-Drive', 'Shuttle', 'Employee Transport'];
  const [selectedServices, setSelectedServices] = useState([]);

  const handleEmployeeCountSelect = (selected) => {
    setEmployeeCount(selected[0]);
    onChange({ employeeCount: selected[0], serviceRequired: selectedServices });
  };

  const handleServicesSelect = (selected) => {
    setSelectedServices(selected);
    onChange({ employeeCount, serviceRequired: selected });
  };

  return (
    <>
      <Row>
        <Col className="col-12">
          <div>
            <SelectOption
              options={employeeCountOptions}
              onSelect={handleEmployeeCountSelect}
              label='Employee Count'
            />
            <SelectOption
              options={servicesOption}
              multi
              onSelect={handleServicesSelect}

              label='Services'
            />

          </div>
        </Col>

      </Row>

    </>
  );
};

export default Step3;
