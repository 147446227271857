import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { AmountField, InputField, ModalComponent, SaveButton, SelectField } from "../../../components";
import DateTimePickerWithInterval from "../../../components/DateTimePickerWithInterval";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import TripExpenseService from "./TripExpenseService";
import './index.scss';
import { Table, Tbody, Td, Tr } from "react-super-responsive-table";
import { Button } from "reactstrap";


const AddTripExpense = ({
    trip_id,
    isOpen,
    toggleModal,
    setExpenses,
}) => {
    const { t } = useContext(I18nContext);
    const { showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([{ category: "Toll Tax" }]);

    const onClickAdd = () => {
        setData(prev => [...prev, { category: "Toll Tax" }])
    }

    const removeExpense = (index) => {
        setData(prev => {
            const newItems = [...prev];
            newItems.splice(index, 1);
            return newItems;
        });
    }

    const handleChange = (index, field, newValue) => {
        const newData = data.map((item, idx) => {
            if (index === idx) {
                return { ...item, [field]: newValue };
            }
            return item;
        });
        setData(newData);
    };

    const saveSingleExpense = async (data) => {
        try {
            if (data?._id) {
                await TripExpenseService.edit(data, data?._id);
            } else {
                await TripExpenseService.save({
                    ...data,
                    trip_id,
                    currency: "INR",
                });
            }
        } catch (e) {
            console.log("error: ", e)
            showError(e);
        }
    }

    const onSave = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const promises = [];
            data.forEach(dataObj => {
                promises.push(saveSingleExpense(dataObj));
            });
            await Promise.all(promises);
            showMessage(t("Trip Expense saved successfully."));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log("error: ", e);
            showError(e);
        } finally {
            const response = await TripExpenseService.fetch(trip_id);
            setExpenses(response);
            toggleModal();
        }
    };

    return (
        <React.Fragment>
            <ModalComponent
                position={"center"}
                size={"large"}
                isOpen={isOpen}
                onToggle={toggleModal}
            // height={"large"}
            >
                <ModalComponent.Title>Trip Expense</ModalComponent.Title>

                <ModalComponent.Body scroll>
                    {
                        data.map((e, index) => (
                            <Tr key={index} className="row-t">
                                <Td className="col-6 col-md-3">
                                    <SelectField
                                        zeroMargin={true}
                                        data={[
                                            { label: "Toll Tax", value: "Toll Tax" },
                                            { label: "State Tax", value: "State Tax" },
                                            { label: "FasTag", value: "FasTag" },
                                            { label: "Parking Fare", value: "Parking Fare" },
                                            { label: "MCD", value: "MCD" },
                                            { label: "Other", value: "Other" },
                                        ]}
                                        value={e?.category}
                                        onChange={(v) => {
                                            handleChange(index, "category", v.value);
                                        }}
                                        label={t("Name")}
                                    />
                                </Td>
                                <Td className="col-6 col-md-2">
                                    <div className="d-flex align-items-center">
                                        <div style={{ width: "fit-content", marginRight: 5 }}> Amount</div>
                                        <AmountField
                                            className={"my-0"}
                                            placeholder="Amount Price"
                                            value={e?.amount}
                                            onChange={(v) => {
                                                handleChange(index, "amount", v);
                                            }}
                                            type="text"
                                        />
                                    </div>
                                </Td>
                                <Td className="col-6 col-md-3">
                                    <InputField
                                        value={e?.detail}
                                        onChange={(v) => {
                                            handleChange(index, "detail", v);
                                        }}
                                        label="Details"
                                        zeroMargin={true}
                                    />
                                </Td>
                                <Td className="col-6 col-md-3">
                                    <DateTimePickerWithInterval
                                        placeholder="Paid At"
                                        label="Paid At"
                                        timestamp
                                        value={e?.paidAt}
                                        onChange={(v) => {
                                            handleChange(index, "paidAt", v);
                                        }}
                                        zeroMargin={true}
                                    />
                                </Td>
                                <Td className="col-1">
                                    <i
                                        onClick={() => { removeExpense(index) }}
                                        className="dripicons-cross ms-3 font-size-20 p-0 m-0 cursor-pointer"
                                    ></i>
                                </Td>
                            </Tr>
                        ))
                    }

                    <div className="d-flex justify-content-end align-items-center my-2">
                        <Button color="light" onClick={onClickAdd} className="d-flex h-36 align-items-center">
                            <i className="bx font-size-20 color-blue bxs-plus-circle me-2" />
                            {t("Add Trip Expense")}
                        </Button>
                    </div>

                </ModalComponent.Body>
                <ModalComponent.Footer>
                    <div className="d-flex justify-content-end">
                        <SaveButton loading={loading} onClick={onSave} disabled={loading} />
                    </div>
                </ModalComponent.Footer>
            </ModalComponent>
        </React.Fragment >
    );
};

export default observer(AddTripExpense);