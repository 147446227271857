export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/language/grid?rows=${sizePerPage}&`;
        if (filterUrl) url += filterUrl;
        return url + "&page=" + page;
    },
    get: (id) => `/api/language/${id}/detail`,
    save: `/api/language/create`,
    delete: (id) => `/api/language/${id}/delete`,
    update: `/api/language/update`,
};

export const STRUCTURE = [
    {
        label: "Name",
        filterLabel: "Name",
        filterName: "name",
        type: "text",

    },
    {
        label: "Code",
        filterLabel: "Code",
        filterName: "code",
        type: "text",
    },
    {
        label: "Label",
        filterLabel: "label",
        filterName: "label",
        type: "text",
    },

];





