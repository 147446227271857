import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Layout } from "../../../components";
import { STRUCTURE, TripStructure, SeatStructure, ComplaintStructure } from "./ReportConstant";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import ReportService from "./ReportService";
import { integerToDate } from '../../CorpSubs/SubsInvoice/SubsInvoice'

const Report = (props) => {
  const { t } = useContext(I18nContext);
  const { id } = useParams();
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);

  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await ReportService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout
       
      title={t("Trip Report")}
      filterStructure={STRUCTURE}
      onApplyFilter={fetchData}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("Date")}</Th>
            <Th>{t("Total Trips")}</Th>
            <Th>{t("Seats Occupied")}</Th>
            <Th>{t("No Shows")}</Th>
            <Th>{t("Vaccant Seats")}</Th>
            <Th>{t("Delayed Trips")}</Th>
            <Th><></></Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {ReportService.records?.map((rep, ind) => (
            <Tr key={rep?.Id}>
              <Td>
                {integerToDate(rep?.date)}
              </Td>
              <Td>{rep?.trips && rep?.bookings ? rep?.trips + rep?.bookings : '0'}</Td>
              <Td>{rep?.occupied ? rep?.occupied : '0'}</Td>
              <Td>{rep?.noShows ? rep?.noShows : '0'}</Td>
              <Td>{rep?.vacancy ? rep?.vacancy : '0'}</Td>
              <Td>{rep?.delayedTrips ? rep?.delayedTrips : '0'}</Td>
              <Td><></></Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>
    </Layout>
  );
};

export default observer(Report);
