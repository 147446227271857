import React from 'react';
import Dropzone from 'react-dropzone';
import FileViewer from './FileViewer';
import { formatBytes } from '..';
import './Attachment.css';

const AttachmentUploader = ({
    attachments = [], // attachments
    onAttachmentChange, // handle attachment changes
    multiple = true // allow multiple file uploads by default
}) => {

    const handleDelete = (index) => {
        const newAttachments = attachments.filter((_, i) => i !== index);
        onAttachmentChange(newAttachments);
    };

    const handleAcceptedFiles = (acceptedFiles) => {
        const newFiles = multiple ? [...attachments, ...acceptedFiles] : [acceptedFiles[0]];
        onAttachmentChange(newFiles);
    };

    return (
        <div className="mt-3">
            <div className="mt-3">
                {attachments.length > 0 ? (
                    attachments.map((file, index) => (
                        <div key={index} className="zr_communication_attachment_file_item">
                            <FileViewer
                                document={file}
                                styleConfig={{
                                    image: { maxWidth: '100%', maxHeight: '600px' },
                                    video: { maxWidth: '100%', maxHeight: '600px' },
                                    pdf: { width: "100%", height: "600px" } //  todo sanyam height hardcode ???
                                }} />
                            <span className="flex-1 me-2 font-size-14">{file.name}</span>
                            <span className="font-size-12 text-dropzone-dark-grey">{formatBytes(file.size)}</span>
                            <button
                                className="zr_communication_attachment_file_delete"
                                onClick={() => handleDelete(index)}
                            >
                                &times;
                            </button>
                        </div>
                    ))
                ) : (
                    <p></p>
                )}
            </div>
            <Dropzone onDrop={handleAcceptedFiles} disabled={!multiple && attachments.length >= 1}>
                {({ getRootProps, getInputProps }) => (
                    <div className={`zr_communication_attachment_dropzone ${!multiple && attachments.length >= 1 ? 'disabled' : ''}`} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="font-size-12 text-dropzone-dark-grey">
                            Drag & Drop your file here or Click to Select Files
                        </div>
                        <div className="font-size-10 text-dropzone-light-grey">
                            Maximum file size: 50 MB
                        </div>
                    </div>
                )}
            </Dropzone>
        </div>
    );
};

export default AttachmentUploader;
