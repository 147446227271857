import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Label, Row } from "reactstrap";
import {
  Address,
  CardThreeBounce,
  Checkbox,
  DriverSelect,
  EmployeeSelect,
  SelectField,
  VehicleSelect,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import QcService from "./qcService";

const EditQc = ({ editId, onChange = () => { } }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetch = async () => {
    try {
      let states = await QcService.get(editId);
      setData(states);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    setData(null);
    setLoading(true);
    if (editId) fetch();
    setLoading(false);
  }, [editId]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const qcType = [
    {
      label: "Vehicle",
      value: 1,
    },
    {
      label: "Driver",
      value: 2,
    },
    {
      label: "Escort/Guide",
      value: 3,
    },
    {
      label: "Self Drive",
      value: 4,
    },
  ];
  return (
    <React.Fragment>
      {loading ? (
        <CardThreeBounce />
      ) : (
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row>
          
            <SelectField
              data={qcType}
              value={data?.entityType}
              label={t("Company Type")}
              className="col-sm-12"
              onChange={(v) =>
                setData({
                  ...data,
                  entityType: v?.value,
                })
              }
              required={true}
            />

            {data?.entityType == 1 && (
              <VehicleSelect
                value={data?.vehicle?.vehicle_id}
                className="col-sm-4"
                onChange={(v) =>
                  setData({
                    ...data,
                    vehicle: {
                      ...data?.vehicle,
                      vehicle_id: v?.value,
                      regNo: v?.regNo,
                      year: v?.year,
                      makeModel: v?.value,
                    },
                  })
                }
              />
            )}
            {data?.entityType == 2 && (
              <EmployeeSelect
                value={data?.driver?.driver_id}
                label={t("Driver")}
                driver
                className="col-sm-4"
                onChange={(v) =>
                  console.log(v)
                  // setData({
                  //   ...data,
                  //   vehicle: {
                  //     ...data?.vehicle,
                  //     vehicle_id: v?.value,
                  //     regNo: v?.regNo,
                  //     year: v?.year,
                  //     makeModel: v?.value,
                  //   },
                  // })
                }
              />
            )}
            <EmployeeSelect
              value={data?.employee_id}
              guide
              label={t("Guide")}
              onChange={(v) => setData({ ...data, employee_id: v?.value })}
              className="col-sm-4"
            />
          </Row>
          <Label className="mt-4">{("Status")}</Label>
          <Row className="mt-2">
            <Checkbox
              label={t("In Progress")}
              checked={data?.status == 0}
              onClick={() =>
                setData({
                  ...data,
                  status: 0,
                })
              }
              className="col-sm-4"
            />
            <Checkbox
              label={t("Approved")}
              checked={data?.status == 1}
              onClick={() =>
                setData({
                  ...data,
                  status: 1,
                })
              }
              className="col-sm-4"
            />
            <Checkbox
              label={t("Rejected")}
              checked={data?.status == 2}
              onClick={() =>
                setData({
                  ...data,
                  status: 2,
                })
              }
              className="col-sm-4"
            />
          </Row>
          <Row>
            <Address
              medium
              country
              state
              placeholder={t("Quality Check Address")}
              label={t("Quality Check Address ")}
              city
              addressLine
              data={data?.address}
              onChange={(v) => setData({ ...data, address: v })}
            />
          </Row>
        </Form>
      )}
    </React.Fragment>
  );
};

export default observer(EditQc);
