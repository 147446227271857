import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { DialogContext } from '../../store/context/DialogContext';
import './VideoPlayer.css';
import { FaPlay } from 'react-icons/fa';
import { downloadMedia } from '../../util/Util';
import MediaModal from '../MediaModal/MediaModal';

const VideoPlayer = ({ src, placeholder = "Loading...", style, ...restProps }) => {
  const [videoSrc, setVideoSrc] = useState();
  const [loading, setLoading] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showError } = useContext(DialogContext);
  const videoRef = useRef(null);

  const fetchVideo = async (src) => {
    if (!src) return;

    setLoading(true);
    try {
      const response = await axios({
        url: src,
        headers: {
          Authorization: localStorage.getItem("BearerToken"),
        },
        withCredentials: true,
        responseType: "blob",
      });

      const reader = new FileReader();
      reader.onload = () => {
        setVideoSrc(reader.result);
      };
      reader.readAsDataURL(response.data);
    } catch (error) {
      console.error("Error fetching video:", error);
      showError("Error fetching video");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (src && src.startsWith("/api")) {
      fetchVideo(src);
    }
  }, [src]);

  const toggleModal = () => setIsModalOpen(!isModalOpen);


  if (loading) {
    return <Skeleton className="loading-skeleton" height={restProps?.height ?? 200} width={restProps?.width ?? 300} />;
  }

  return (
    <div className="video-player-container" style={style}>
      {src && videoSrc ? (
        <>
          <div className="video-preview-container" onClick={toggleModal}>
            <video
              className="video-element"
              {...restProps}
              ref={videoRef}
              src={videoSrc}
              style={{ objectFit: 'cover', cursor: 'pointer', maxWidth: '150px' }}
              type="video/mp4"
              controls={false}
            >
              Your browser does not support the video tag.
            </video>
            <div className="play-icon-container">
              <FaPlay className="play-icon" />
            </div>
          </div>
          <MediaModal isOpen={isModalOpen} toggle={toggleModal} centered>
            <MediaModal.Header
              title={"Video"}
              onClose={toggleModal}
              onDownload={
                async () => {
                await downloadMedia(videoSrc, "video")
              }}

            />
            <MediaModal.Body>
              <video
                controls
                className="video-element"
                {...restProps}
                ref={videoRef}
                src={videoSrc}
                type="video/mp4"
              >
                Your browser does not support the video tag.
              </video>
            </MediaModal.Body>
          </MediaModal>
        </>
      ) : (
        <i className='bx bx-video' />
      )}
    </div>
  );
};

export default VideoPlayer;
