import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import "react-image-lightbox/style.css";
import { Col, Form, Row } from "reactstrap";
import {
  CardThreeBounce,
  changeValueOfidx,
  Checkbox,
  CitySelect,
  CountrySelect,
  CustomFieldComponent,
  DateSelect,
  InputField,
  LanguageSelect,
  PhoneNum,
  removeitemOnce,
  StateSelect,
  VendorSelect,
  ZoneSelect,
} from "../../components";
import Tab from "../../components/Common/Tab/Tab";
import { I18nContext } from "../../store/context/i18nContext";
import DocUploader from "./DocUploader";
import { ENDPOINTS } from "./DriverConstant";
import DriverService from "./DriverService";
import { UserContext } from "../../store/context/UserContext";
import VehicleStatusSelect from "../../components/Dropdowns/VehicleStatusSelect/VehicleStatusSelect";
import VehicleComplianceStatusSelect from "../../components/Dropdowns/VehicleComplianceStatusSelect/VehicleComplianceStatusSelect";
import ExperienceSelect from "../../components/Dropdowns/ExperienceSelect/ExperienceSelect";
import AppStatusSelect from "../../components/Dropdowns/AppStatusSelect/AppStatusSelect";
import { CUSTOMEFIELDS_KIND_DATA } from "../../components/CustomField/CustomFieldsConstants";

const EditDriver = (props) => {
  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { id, data, setData, hasErr } = props;
  const [freshData, setFreshData] = useState({});
  const [step, setStep] = useState(0);

  const fetch = async () => {
    let driverD = {
      authType: "password",
      countryCode: userContent?.countryCode
        ? userContent?.countryCode
        : 91,
      password: "",
      language: "en",
      phone: null,
      email: "",
      // emailPhone: "",
      // driverDetail: {
      //   address: {
      //     country: userContent?.country
      //       ? userContent?.country
      //       : "India",
      //   },
      //   permanentAddress: {
      //     country: userContent?.country
      //       ? userContent?.country
      //       : "India",
      //   },
      // },
    };
    if (id) driverD = await DriverService.get(id);
    setFreshData(driverD);
    setData(driverD);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetch();
    // setHasErr(false);
  }, [id]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(freshData)) {
      props?.onChange(true);
    } else {
      props?.onChange(false);
    }
  }, [data]);

  if (loading) return <CardThreeBounce />;

  return (
    <React.Fragment>
      {(id && !loading) || !id ? (
        <Form>
          <Tab
            vertical
            left
            activeTab={step}
            onChange={(data) => {
              setStep(data);
            }}
            className="d-flex"
          >
            <div num={1} label={t("Profile")}>
              <Row className="mb-3">
                {/* <Col className="col-lg">
                  <VendorSelect
                    label="Owner"
                    value={data?.driverOf}
                    onChange={(v) => {
                      setData({
                        ...data,
                        driverOf: v?.value,
                        corpName: v?.label,
                      });
                    }}
                  />
                </Col> */}
              </Row>
              <Row className="mb-3">
                <Col className="col-lg">
                  <InputField
                    placeholder="Name"
                    value={data?.name}
                    required={true}
                    label={"Driver Name"}
                    onChange={(v) => setData({ ...data, name: v })}
                    error={
                      data?.name?.length === 0 ? "Please enter Driver Name" : ""
                    }
                    // showErr={hasErr}

                    type="text"
                  />
                </Col>
                <Col className="col-lg">
                  <AppStatusSelect
                    value={data?.driverExtn?.appStatus}
                    onChange={(v) =>
                      setData({
                        ...data,
                        driverExtn: {
                          ...data?.driverExtn,
                          appStatus: v.value,
                        },
                      })
                    }

                  />
                </Col>
                <Col className="col-lg">
                  <ExperienceSelect
                    value={data?.driverExtn?.experience}
                    onChange={(v) =>
                      setData({
                        ...data,
                        driverExtn: {
                          ...data?.driverExtn,
                          experience: v.value,
                        },
                      })
                    }
                  // showErr={showErr}

                  />
                </Col>

                <Col className="col-lg ">
                  <PhoneNum
                    required={true}
                    value={data?.phoneNo}
                    onChange={(v) =>
                      setData({
                        ...data,
                        phoneNo: {
                          ...data.phoneNo,
                          countryCode: v?.countryCode,
                          phone: v?.phone,
                        },
                      })
                    }
                  // showErr={hasErr}

                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="col-lg">
                  <VehicleStatusSelect
                    value={data?.status}
                    onChange={(v) => {
                      setData({
                        ...data,
                        status: v?.value,
                      });
                    }}
                  // showErr={showErr}

                  />
                </Col>

                <Col className="col-lg">
                  <VehicleComplianceStatusSelect
                    value={data?.complianceStatus}
                    onChange={(v) => {
                      setData({
                        ...data,
                        complianceStatus: v?.value,
                      });
                    }}
                  // showErr={showErr}

                  />
                </Col>
                <Col className="col-lg">
                  <InputField
                    value={data?.driverExtn?.empNo}
                    onChange={(v) =>
                      setData({
                        ...data,
                        driverExtn: {
                          ...data?.driverExtn,
                          empNo: v,
                        },
                      })
                    }
                    label={"Employee Number"}
                    placeholder="Employee Number"
                    type="text"
                    error={
                      data?.driverExtn?.empNo?.length === 0
                        ? "Please enter Employee Number"
                        : ""
                    }
                  // showErr={hasErr}

                  />
                </Col>
                <Col className="col-lg">
                  <InputField
                    value={data?.email}
                    onChange={(v) => {
                      setData({
                        ...data,
                        email: v,
                      });
                    }}
                    label={"Email"}
                    placeholder="Email"
                    type="email"
                    error={
                      data?.email?.length === 0 ? "Please enter Email" : ""
                    }
                    required={true}
                  // showErr={hasErr}

                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col className="col-lg">
                  <Col className="col-12">
                    <LanguageSelect
                      value={data?.driverExtn?.speaks}
                      onChange={(v) =>
                        setData({
                          ...data,
                          driverExtn: {
                            ...data?.driverExtn,
                            speaks: v,
                          },
                        })
                      }
                      label="Language Select"
                      multi
                      className=""
                    // showErr={showErr}

                    />
                  </Col>
                </Col>
              </Row>
            </div>
            {data?._id && (
              <div num={2} label={t("Pictures")}>
                <DocUploader
                  fileType="file"
                  data={data?.driverDetail?.pictures}
                  setDriverData={setData}
                  driverData={data}
                  onRemove={(param) => {
                    console.log(param);
                    setData({
                      ...data,
                      driverDetail: {
                        ...data?.driverDetail,
                        pictures: param,
                      },
                    });
                  }}
                  idd={data?._id}
                  uploadApi={ENDPOINTS.picUpload(id)}
                  fetchData={fetch}
                  makeProfilePic={true}
                  typeStructure={[
                    { label: "Select type", value: "Select type" },
                    { label: "Driving License", value: "Driving License" },
                    { label: "Aadhar", value: "Aadhar" },
                    { label: "Other", value: "Other" },
                  ]}
                />
              </div>
            )}
            <div num={3} label={t("Address")}>
              <div>{"Current Address"}</div>
              <hr />

              <Row className="mb-3">
                <Col className="col-lg">
                  <InputField
                    value={data?.driverExtn?.presentAddress?.addressLine}
                    onChange={(v) =>
                      setData({
                        ...data,
                        driverExtn: {
                          ...data?.driverExtn,
                          presentAddress: {
                            ...data?.driverExtn?.presentAddress,
                            addressLine: v,
                          },
                        },
                      })
                    }
                    label={"Address Line"}
                    placeholder="Address Line"
                    type="text"
                    error={
                      data?.driverExtn?.presentAddress?.addressLine?.length ===
                        0
                        ? "Please enter Address"
                        : ""
                    }
                  // showErr={hasErr}

                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col className="col-lg">
                  <CountrySelect
                    showErr={hasErr}
                    value={data?.driverExtn?.presentAddress?.country}
                    onChange={(v) =>
                      setData({
                        ...data,
                        driverExtn: {
                          ...data?.driverExtn,
                          presentAddress: {
                            ...data?.driverExtn?.presentAddress,
                            country: v?.value,
                            state: null,
                            city: null,
                          },
                        },
                      })
                    }
                  />
                </Col>
                <Col className="col-lg">
                  <StateSelect
                    showErr={hasErr}
                    value={data?.driverExtn?.presentAddress?.state}
                    onChange={(v) =>
                      setData({
                        ...data,
                        driverExtn: {
                          ...data?.driverExtn,
                          presentAddress: {
                            ...data?.driverExtn?.presentAddress,
                            state: v?.value,
                            city: null,
                          },
                        },
                      })
                    }
                    country={data?.driverExtn?.presentAddress?.country}
                  />
                </Col>
                <Col className="col-lg">
                  <CitySelect
                    showErr={hasErr}
                    value={data?.driverExtn?.presentAddress?.city}
                    onChange={(v) =>
                      setData({
                        ...data,
                        driverExtn: {
                          ...data?.driverExtn,
                          presentAddress: {
                            ...data?.driverExtn?.presentAddress,
                            city: v?.value,
                          },
                        },
                      })
                    }
                    country={data?.driverExtn?.presentAddress?.country}
                    state={data?.driverExtn?.presentAddress?.state}
                  />
                </Col>
              </Row>

              <div className="mt-1">{"Permanent Address"}</div>
              <hr />

              <Row className="mb-3">
                <Col className="col-lg">
                  <InputField
                    value={data?.driverExtn?.permanentAddress?.addressLine}
                    onChange={(v) =>
                      setData({
                        ...data,
                        driverExtn: {
                          ...data?.driverExtn,
                          permanentAddress: {
                            ...data?.driverExtn?.permanentAddress,
                            addressLine: v,
                          },
                        },
                      })
                    }
                    label={"Address Line"}
                    placeholder="Address Line"
                    type="text"
                    error={
                      data?.driverExtn?.permanentAddress?.addressLine
                        ?.length === 0
                        ? "Please enter Address"
                        : ""
                    }
                  // showErr={hasErr}

                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col className="col-lg">
                  <CountrySelect
                    showErr={hasErr}
                    value={data?.driverExtn?.permanentAddress?.country}
                    onChange={(v) =>
                      setData({
                        ...data,
                        driverExtn: {
                          ...data?.driverExtn,
                          permanentAddress: {
                            ...data?.driverExtn?.permanentAddress,
                            country: v?.value,
                            state: null,
                            city: null,
                          },
                        },
                      })
                    }
                  />
                </Col>
                <Col className="col-lg">
                  <StateSelect
                    showErr={hasErr}
                    value={data?.driverExtn?.permanentAddress?.state}
                    onChange={(v) =>
                      setData({
                        ...data,
                        driverExtn: {
                          ...data?.driverExtn,
                          permanentAddress: {
                            ...data?.driverExtn?.permanentAddress,
                            state: v?.value,
                            city: null,
                          },
                        },
                      })
                    }
                    country={data?.driverExtn?.permanentAddress?.country}
                  />
                </Col>
                <Col className="col-lg">
                  <CitySelect
                    showErr={hasErr}
                    value={data?.driverExtn?.permanentAddress?.city}
                    onChange={(v) =>
                      setData({
                        ...data,
                        driverExtn: {
                          ...data?.driverExtn,
                          permanentAddress: {
                            ...data?.driverExtn?.permanentAddress,
                            city: v?.value,
                          },
                        },
                      })
                    }
                    country={data?.driverExtn?.permanentAddress?.country}
                    state={data?.driverExtn?.permanentAddress?.state}
                  />
                </Col>
              </Row>
            </div>
            <div num={4} label={t("Other Details")}>
              <CustomFieldComponent
                kind={CUSTOMEFIELDS_KIND_DATA?.Driver}
                propList={data?.driverExtn?.properties}
                onChange={(v) =>
                  setData({
                    ...data,
                    driverExtn: {
                      ...data?.driverExtn,
                      properties: v,
                    },
                  })
                }
              />
              <Row className="mb-3">
                <Col className="col-lg">
                  <LanguageSelect
                    multi={true}
                    label={"Language"}
                    value={data?.languages}
                    onChange={(v) => setData({ ...data, languages: v })}
                    maxWidth={"653px"}
                  />
                </Col>
              </Row>
            </div>
            {/* <div num={5} label={t("Additional Properties")}>
              {data?.driverDetail?.driverPermit?.map((driverPermit, idx) => (
                <>
                  <Row className="mb-3">
                    <Col className="col-lg">
                      <InputField
                        value={driverPermit?.name}
                        onChange={(v) =>
                          setData({
                            ...data,
                            driverDetail: {
                              ...data?.driverDetail,
                              driverPermit: changeValueOfidx(
                                data?.driverDetail?.driverPermit,
                                idx,
                                "name",
                                v
                              ),
                            },
                          })
                        }
                        label={"Name"}
                        placeholder="Name"
                        type="text"
                        error={
                          driverPermit?.name?.length === 0
                            ? "Please enter name"
                            : ""
                        }
                        // showErr={hasErr}
                        
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-lg">
                      <InputField
                        value={driverPermit?.detail}
                        onChange={(v) =>
                          setData({
                            ...data,
                            driverDetail: {
                              ...data?.driverDetail,
                              driverPermit: changeValueOfidx(
                                data?.driverDetail?.driverPermit,
                                idx,
                                "detail",
                                v
                              ),
                            },
                          })
                        }
                        label={"Detail"}
                        placeholder="Detail"
                        type="text"
                        error={
                          driverPermit?.detail?.length === 0
                            ? "Please enter details"
                            : ""
                        }
                        
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-lg">
                      <InputField
                        value={driverPermit?.cost}
                        onChange={(v) =>
                          setData({
                            ...data,
                            driverDetail: {
                              ...data?.driverDetail,
                              driverPermit: changeValueOfidx(
                                data?.driverDetail?.driverPermit,
                                idx,
                                "cost",
                                v
                              ),
                            },
                          })
                        }
                        label={"Cost"}
                        placeholder="Cost"
                        type="text"
                        error={
                          driverPermit?.cost?.length === 0
                            ? "Please enter cost"
                            : ""
                        }
                        
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-lg">
                      <DateSelect
                        label={"Expiry"}
                        value={driverPermit?.expiry}
                        onChange={(v) =>
                          setData({
                            ...data,
                            driverDetail: {
                              ...data?.driverDetail,
                              driverPermit: changeValueOfidx(
                                data?.driverDetail?.driverPermit,
                                idx,
                                "expiry",
                                v
                              ),
                            },
                          })
                        }
                      />
                    </Col>
                  </Row>

                  <a
                    type="button"
                    href={driverPermit?.attachmentUrl}
                    className="mt-2  col-2 mb-3"
                    target="__blank"
                  >
                    Download
                  </a>
                  <i
                    role="button"
                    className="mt-2 dripicons-cross font-size-16 col-1  text-red "
                    onClick={() => {
                      setData({
                        ...data,
                        driverDetail: {
                          ...data?.driverDetail,
                          driverPermit: removeitemOnce(
                            data?.driverDetail?.driverPermit,
                            idx
                          ),
                        },
                      });
                    }}
                  />
                </>
              ))}
            </div>
            {data?._id ? (
              <div num={6} label={t("Police Verification")}>
                <Row>
                  <Col className="col-12 d-flex justify-content-between">
                    <Checkbox
                      label={t("Police Verified")}
                      onClick={() =>
                        setPoliceVerifyD({
                          ...policeVerifyD,
                          policeVerify: !policeVerifyD?.policeVerify,
                        })
                      }
                    />
                    <DateSelect
                      label={t("Date")}
                      onChange={(v) =>
                        setPoliceVerifyD({
                          ...policeVerifyD,
                          policeVerifyDate: v,
                        })
                      }
                    />
                  </Col>
                  <Col className="col-12">
                    <DocUploader
                      fileType="file"
                      data={[
                        {
                          name: data?.driverDetail?.policeVerifyPic?.name,
                          url: data?.driverDetail?.policeVerifyPic?.url,
                        },
                      ]}
                      uploadApi={ENDPOINTS.policeVerifyPic(id)}
                      fetchData={fetch}
                      policeVerifyD={policeVerifyD}
                      policeverify={true}
                    />
                  </Col>
                </Row>
              </div>
            ) : null} */}
          </Tab>
        </Form>
      ) : (
        <Row>
          <CardThreeBounce />
        </Row>
      )}
    </React.Fragment>
  );
};

export default observer(EditDriver);
