import { toJS } from "mobx";
import React, { useEffect, useState } from "react";
import { SelectField } from "../../SelectField/SelectField";
import RankSelectService from "./RankSelectService";

export default function RankSelect(props) {
    const [rank, setRank] = useState([]);
    useEffect(() => {
        const fetch = async () => {
            try {
                await RankSelectService.fetch();
                setRank(toJS(RankSelectService.rank))
            } catch (error) {
            }
        }
        fetch();
    }, []);
    return (
        <React.Fragment>
            {rank.length > 0 &&
                <SelectField
                    className={props?.className}
                    onChange={props?.onChange}
                    data={rank}

                    value={props?.value}
                    label={"Rank"}
                    error={
                        props?.value
                            ? "Please Select Your Rank"
                            : ""
                    }
                    showErr={props?.showErr}
                    inline={props?.inline}
                    borderNone={props?.borderNone}
                    RightComponent={props?.RightComponent}
                    onClickRight={props?.onClickRight}
                />}
        </React.Fragment>
    )
}
