import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import { InputField, Modal, ModalComponent, SupplierSelect } from "../../components";
import { I18nContext } from "../../store/context/i18nContext";
import Expense from "../Expense/Expense";
import RemoveLink from "../../components/Buttons/RemoveLink";

const newItem = {
    expense_id: undefined,
    amountPaid: 0,
}

const ExpensePayments = ({ data = [], onChange, header, supplier_id = null }) => {

    const [addModal, setAddModal] = useState(false)
    const { t } = useContext(I18nContext);

    const [totalAmountPaid, setTotalAmountPaid] = useState(0);

    const addItems = () => {
        onChange([...data, newItem]);
    };

    const deleteItems = (index) => {
        const updatedData = data.filter((_, i) => i !== index);
        onChange(updatedData);
    };

    const updateItem = ({ index, key, value }) => {
        const updatedData = [...data];
        updatedData[index] = { ...updatedData[index], [key]: value };
        onChange(updatedData);
    };

    useEffect(() => {
        let total = 0;
        data?.forEach((acc, accIndex) => {

            total += acc?.amountPaid
        })
        setTotalAmountPaid(total)
    }, [data])



    return (

        <div>
            {addModal !== false &&
                <ModalComponent
                    position={"top"}
                    size={"large"}
                    isOpen={addModal}
                    onToggle={() => setAddModal(false)}>
                    <ModalComponent.Title>
                        {t("Expenses")}
                    </ModalComponent.Title>

                    <ModalComponent.Body>
                        <Expense
                            // value={value}
                            supplier_id={supplier_id}
                            insidePane={true}
                            multiMode={false}
                            onSelect={(v) => {
                                onChange([...data, {
                                    company_id: v?.company_id,
                                    amountPaid: v?.total,
                                    totalExpense: v?.total,
                                    expense_id: v?._id,
                                    supplier_id: v?.supplier_id,
                                    expenseHead: v?.expenseHead,
                                }]);
                                setAddModal(false)
                            }}
                        />
                    </ModalComponent.Body>
                </ModalComponent>
            }


            <div className="d-flex justify-content-between">
                <h5 style={{ fontWeight: '800' }}>{header ?? t("Expenses")}</h5>
                <div className="d-flex">

                    {/* <div onClick={() => { setAddModal(true) }} className="d-flex align-items-center">
                        <i className="bx bx-plus font-size-20 me-2"></i>
                        Expense
                    </div> */}

                    <Button
                        color="light"
                        onClick={() => { setAddModal(true) }}
                        style={{ width: "fit-content" }}
                        className="h-36 d-flex align-items-center mb-2">
                        <i className="bx font-size-20 color-blue bx-plus"></i>
                        {t("Expense")}
                    </Button>

                </div>
            </div>

            <Card>
                {/* <Row className="bg-light py-2">
                    <Col className="col-6 ps-3">Supplier</Col>
                    <Col className="col-6">Amount Paid</Col>
                </Row> */}

                <table className="table-bordered expense">
                    <thead>
                        <tr className="py-2" style={{ backgroundColor: '#FAF9FF' }}>
                            <th style={{ width: '40%' }}>{t("Supplier")}</th>
                            <th style={{ width: '50%' }}>{t("Amount Paid")}</th>
                            <th style={{ width: '10%' }}></th>
                        </tr>
                    </thead>
                    {data.map((account, index) => (
                        <tbody className="expense-table-hover position-relative">
                            <tr key={index} className="shadow-none">
                                <td>
                                    <div className="col-sm-6 d-flex flex-column justify-content-center align-items-start">
                                        <div> <SupplierSelect
                                            label={"Vehicle Type"}
                                            value={account?.supplier_id}
                                            className="col-sm-3"
                                            plain
                                            asLabel
                                        /></div>
                                    </div>
                                </td>
                                <td>
                                    <InputField
                                        placeholder={t("Amount Paid")}
                                        value={account.amountPaid}
                                        onChange={(v) => {
                                            updateItem({
                                                value: Number(v) > account?.totalExpense ? account?.totalExpense : Number(v),
                                                index,
                                                key: "amountPaid"
                                            })


                                        }}
                                        outline
                                        type="number"
                                        style={{ minWidth: '70px' }}

                                    // className="col-sm-6"
                                    />
                                </td>
                                <td style={{ right: 8 }}>
                                    <RemoveLink className="ms-1" onClick={() => deleteItems(index)} />
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>

                {/* <table>
                    <tr className="bg-light py-2">
                        <th style={{ width: '40%' }}>Supplier</th>
                        <th style={{ width: '40%' }}>Amount Paid</th>
                        <th style={{ width: '20%' }}></th>
                    </tr>

                    {data.map((account, index) => (
                        <tr key={index} className="shadow-none">
                            <td>
                                <div className="col-sm-6 d-flex flex-column justify-content-center align-items-start">

                                <div>{account?.supplier}</div>
                                </div>
                            </td>
                            <td>
                                <InputField
                                    placeholder={t("Amount Paid")}
                                    value={account.amountPaid}
                                    onChange={(v) => {
                                        updateItem({
                                            value: Number(v) > account?.totalExpense ? account?.totalExpense : Number(v),
                                            index,
                                            key: "amountPaid"
                                        })


                                    }}
                                    noLabel
                                    type="number"
                                // className="col-sm-6"

                                />
                            </td>
                            <td>
                                <i
                                    onClick={() => deleteItems(index)}
                                    className="bx color-blue font-size-20 bxs-x-circle    p-0"
                                ></i>
                            </td>
                            <div key={index} className="shadow-none">
                                <div style={{ position: "relative" }}>
                                    <div className="row col-12 d-flex justify-content-end" >


                                    </div>
                                    <div style={{ position: "absolute", right: -8, top: 16, }} className=" ">
                                        <i
                                            onClick={() => deleteItems(index)}
                                            className="bx color-blue font-size-20 bxs-x-circle    p-0"
                                        ></i>
                                    </div>
                                </div>

                                <hr className="p-0 m-0" />
                            </div>
                        </tr>
                    ))}
                </table> */}
            </Card>



            <Row className="border-top border-bottom border-dark">

                <Col className="col-2 pt-2">
                    {/* <Button color="light" onClick={addItems} className="d-flex align-items-center">
                        <i className="bx color-blue bxs-plus-circle font-size-20 me-2"></i>
                        Add item
                    </Button> */}
                </Col>
                <Col className="col-10">
                    <div className=" row my-1 col-12 d-flex justify-content-end align-items-center ">
                        {t("Total Amount Paid")}: {totalAmountPaid}
                    </div>

                </Col>

            </Row>

        </div>
    );
};

export default observer(ExpensePayments);

