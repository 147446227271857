import { makeAutoObservable } from "mobx";
import { getUiPrefRows } from "../../../util/FilterUtil";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "./../RateConstant";

class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = null;
  filterOption = "";
  total = -1;
  version = 0;
  

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterUrl) => {
    if (!this.rowsPerPage) {
      const rows = await getUiPrefRows("Rate.grid");
      this.rowsPerPage = rows ?? 40;
    }
    if (filterUrl) {
      this.filterOption = filterUrl;
    }
    const response = await doGET(
      ENDPOINTS.rateDataGrid(this.page, this.rowsPerPage, filterUrl, this.forSupplier)
    );

    if (response?.status === 200) {
      this.records = response.data.rows;
      this.page = response?.data?.page;
      if (this.page === 1) this.total = response?.data?.total;
    }
  };


  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);
  };

}

const RateDataGridService = new Service();
export default RateDataGridService;
