import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { CardThreeBounce, Checkbox, ClientSelect, CurrencySelect, InputField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import ClientPoolService from "./ClientPoolService";

const EditClientPools = ({ editId, onChange = () => { }, hasErr }) => {
    const { t } = useContext(I18nContext);
    const [loading, setLoading] = useState(false);
    const { showError } = useContext(DialogContext);
    const [data, setData] = useState(null);

    const fetch = async () => {
        try {
            let states = await ClientPoolService.get(editId);
            setData(states);
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        setData(null)
        setLoading(true);
        if (editId) fetch();
        setLoading(false)
    }, [editId, ClientPoolService?.version]);

    useEffect(() => {
        onChange(data)
    }, [data]);

    return (
        <React.Fragment>
            {loading ?
                <CardThreeBounce /> :
                <Form
                    className="p-3"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                >
                    <Row className="mt-3 mb-3">
                        <InputField
                            placeholder={t("Name")}
                            value={data?.name}
                            required={true}
                            label={t("Name")}
                            onChange={(v) => setData({ ...data, name: v })}
                            error={data?.name?.length === 0 ? "Please enter Name" : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6"
                        />
                        <ClientSelect
                            value={data?.clients}
                            onChange={(v) => setData({ ...data, clients: v })}
                            multi
                            className="col-sm-6"
                        />
                        {/* <CurrencySelect
                            placeholder={t("Target Currency ")}
                            value={data?.targetCurrency}
                            label={t("Target Currency")}
                            onChange={(v) => setData({ ...data, targetCurrency: v?.value})}
                            error={data?.targetCurrency?.length === 0 ? t("Please enter Target Currency ") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-3"
                        /> */}

                    </Row>
                    {/* <Row className="mt-3 mb-3">
                        <Checkbox
                            placeholder={t("Is Absolute")}
                            checked={data?.isAbsolute}
                            value={data?.isAbsolute}
                            label={t("Is Absolute")}
                            onClick={(v) => setData({ ...data, isAbsolute: !data?.isAbsolute })}
                            error={data?.isAbsolute?.length === 0 ? t("Please enter isAbsolute") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6"
                        />
                        <InputField
                            placeholder={t("amount")}
                            value={data?.amount}
                            label={t("Amount ")}
                            onChange={(v) => setData({ ...data, amount: v })}
                            error={data?.amount?.length === 0 ? t("Please enter amount") : ""}
                            showErr={hasErr}
                            type="number"
                            className="col-sm-6"
                        />

                    </Row> */}
                </Form>
            }
        </React.Fragment>
    );
};

export default observer(EditClientPools);


