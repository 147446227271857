import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import Employee from '../../../pages/Employee/Employee';
import SupervisorService from '../../../pages/Supervisor/SupervisorService';
import { DialogContext } from '../../../store/context/DialogContext';
import { I18nContext } from '../../../store/context/i18nContext';
import useDebounce from '../../../util/Debounce';
import { SelectField } from '../../SelectField/SelectField';
import { SelectFieldMultiple } from '../../SelectField/SelectFieldMultiple';
import { BottomBarButton, ModalComponent, SearchButton } from '../../index';
import EmployeeSelectService from '../EmployeeSelect/EmployeeSelectService';

const EmployeeSupervisorSelect = ({
  style,
  isDisabled,
  className,
  showClear,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  guide,
  driver,
  guard,
  sales,
  outline,
  asLabel,
  innerContainerClassName,
  placeholder,
  ...props
}) => {
  const [combinedData, setCombinedData] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext);
  const { t } = useContext(I18nContext);

  const fetchAndCombineData = async () => {
    try {
      await EmployeeSelectService.fetch();
      await SupervisorService.fetch();
      console.log(toJS(SupervisorService.records))
      const employees = toJS(EmployeeSelectService.employees).map((employee) => ({
        ...employee,
        value: employee._id,
        label: (
          <div className="d-flex align-items-center gap-1 justify-content-between h-100">
            <div><b>{employee.name}</b></div>
            <div className="text-secondary"> +{employee?.phoneNo?.countryCode} {employee?.phoneNo?.phone}</div>
          </div>
        ),
        type: 'Employee',
      }));
      const supervisors = toJS(SupervisorService.records).map((supervisor) => ({
        ...supervisor,
        value: supervisor._id,
        label: (
          <div className="d-flex align-items-center gap-1 justify-content-between h-100">
            <div><b>{supervisor.name}</b></div>
            <div className="text-secondary"> +{supervisor?.phoneNo?.countryCode} {supervisor?.phoneNo?.phone}</div>
          </div>
        ),
        type: 'Supervisor',
      }));
      setCombinedData([...supervisors,...employees]);
    } catch (error) {
      showError("Error", error.message);
    }
  };

  useEffect(() => {
    fetchAndCombineData();
  }, []);

  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);

  const selectHandler = (selectedValue) => {
    let selectedItem = toJS(selectedValue);
    onChange({ ...selectedItem, value: selectedItem?._id });
    closeModal();
  };

  const selectMultipleHandler = (selectedValues) => {
    onChange(selectedValues.map((item) => item?.value ?? item));
  };

  const fetchMoreData = () => {
    const employeePage = EmployeeSelectService.page + 1;
    const supervisorPage = SupervisorService.page + 1;
    SupervisorService.onPaginationChange(supervisorPage, SupervisorService.rowsPerPage);
    EmployeeSelectService.onPaginationChange(employeePage, EmployeeSelectService.rowsPerPage);
   
  };

  const fetchDataOnSearch = useDebounce((value) => {
    const filterUrl = `name=${value}`;
    EmployeeSelectService.fetch(filterUrl);
    SupervisorService.fetch(filterUrl);
  }, [500]);

  const filterOption = (option, searchText) => option?.data?.name?.toLowerCase().includes(searchText.toLowerCase());

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Employees and Supervisors")} />
      )}
      onMenuScrollToBottom={fetchMoreData}
      className={className}
      onChange={onChange}
      data={combinedData}
      showClear={showClear}
      required={required}
      value={value}
      label={label || ((!isDisabled && !value) && t("Select Employees or Supervisors"))}
      error={!value ? t(`Please Select ${label || "Employee or Supervisor"}`) : ""}
      showErr={showErr}
      inline={inline}
      outline={outline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
      onInputChange={fetchDataOnSearch}
      filterOption={filterOption}
      innerContainerClassName={value ? innerContainerClassName : ``}
      {...props}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Employees and Supervisors")} />
      )}
      className={className}
      onMenuScrollToBottom={fetchMoreData}
      outline={outline}
      onChange={selectMultipleHandler}
      data={combinedData}
      value={value ? combinedData.filter((item) => value?.includes(item?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Employees or Supervisors"}`) : ""}
      required={required}
      label={label || ((!isDisabled && !value) && t("Select Employees or Supervisors"))}
      showErr={showErr}
      onInputChange={fetchDataOnSearch}
      innerContainerClassName={value ? innerContainerClassName : ``}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
      {asLabel
        ? multi
          ? value?.length > 0
            ? <div>
                {value?.map((selectedValue, index) => {
                  const item = combinedData.find((item) => item.value == selectedValue);
                  return (
                    <span key={selectedValue}>
                      {item ? item.label : 'Unknown Item'}
                      {index < value.length - 1 && ", "}
                    </span>
                  );
                })}
              </div>
            : <div style={{ color: "grey" }}>--</div>
          : <div>{combinedData?.find((item) => item?.value == value)?.label ?? <div style={{ color: "grey" }}>--</div>}</div>
        : <>
            {modal && (
              <ModalComponent
                position={"top"}
                size={"large"}
                onToggle={closeModal}
                isOpen={modal}
              >
                <ModalComponent.Title>
                  {t("Select Employee or Supervisor")}
                </ModalComponent.Title>
                <ModalComponent.Body>
                  <Employee
                    value={value}
                    insidePane={true}
                    multiMode={multi}
                    onSelect={multi ? selectMultipleHandler : selectHandler}
                  />
                </ModalComponent.Body>
              </ModalComponent>
            )}
            {combinedData.length > 0
              ? multi
                ? renderSelectFieldMultiple()
                : renderSelectField({ disabled: false })
              : renderSelectField({ disabled: true })}
          </>
      }
    </>
  );
};

export default observer(EmployeeSupervisorSelect);
