import React from "react"
import ReactDOM from "react-dom/client";
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import store from "./store"
import { UserProvider } from "./store/context/UserContext";
import { DialogProvider } from "./store/context/DialogContext";
import { I18nProvider } from "./store/context/i18nContext";
import { CookiesProvider } from "react-cookie";
import "react-datepicker/dist/react-datepicker.css";
import { ModalProvider } from "./store/context/ModalContext";
import GeneralModal from "./components/GeneralModal";






const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <CookiesProvider>
        <UserProvider>
          <I18nProvider>
            <DialogProvider>
            <ModalProvider>
              <App />
              <GeneralModal />
          </ModalProvider>
            </DialogProvider>
          </I18nProvider>
        </UserProvider>
      </CookiesProvider>
    </BrowserRouter>
  </Provider>
)

serviceWorker.unregister()
