import moment from 'moment';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { CardThreeBounce, CrossButton, MakeModelSelect } from '../../../../components';
import { doGET } from '../../../../util/HttpUtil';
import Billing from '../../../Booking/components/Billing';
import { getAddressFromObj } from '../../helper';
import { UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody, Card,Col } from 'reactstrap';
import { DialogContext } from '../../../../store/context/DialogContext';
import BookingService from '../../../Booking/BookingService';
import { FaPen } from 'react-icons/fa';
import DetailPageModal from '../../../../components/Layout/DetailPage/DetailPageModal';
import EditBooking from '../../../BookingsNew/EditBooking';
import SidebarModal from '../../../../components/HorizontalLayout/SidebarModal';
import EditBookingHeader from '../../../../components/BookingNew/EditBookingHeader';
import BillingNew from '../../../Booking/components/BillingNew';
import { ThreeBounce } from 'better-react-spinkit';

const TripInvoiceItem = forwardRef(({ tax, item, onChange,savedStatus ,postSaveRefreshInvoice},tripInvoiceRef) => {
    const { manual, summary, details, fee, invoiceTrip, billing_id } = item;
    const [billing, setBilling] = useState({});
    const [trip, setTrip] = useState({});
    const [loading, setLoading] = useState(false);
    const {showError}=useContext(DialogContext)
    const [isOpen, setIsOpen] = useState(false);
    const [showTripEditPage,setShowTripEditPage]=useState(false); 
    const tripIdRef = useRef(null); 

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const fetchDetails = async () => {
        setLoading(true);
        try {
            const tripResponse = await doGET(`/api/trip/${invoiceTrip.trip_id}/detail`);
            setTrip(tripResponse.data);

            const billingResponse = await doGET(`/api/billing/grid?trip_id=${invoiceTrip.trip_id}&type=1`);
            setBilling( billingResponse.data?.rows[0]);
            onChange({ billing_id:  billingResponse.data?.rows?.[0]._id})
            postSaveRefreshInvoice && postSaveRefreshInvoice();
        } catch (error) {
            console.error('Error fetching details:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if ((invoiceTrip?.trip_id && tripIdRef.current !== invoiceTrip?.trip_id)  ) {
            fetchDetails();
            tripIdRef.current = invoiceTrip?.trip_id; 
        }
    }, [invoiceTrip?.trip_id ]);


    useImperativeHandle(tripInvoiceRef, () => ({
        saveOrUpdateBilling(tax) {
            // return new Promise((resolve,reject)=>setTimeout( reject, 5000));
            return new Promise(async (resolve, reject) => {
                try {
                    const billingPayload={...billing}
                    if (!tax?.updated || !billingPayload || !tax?._id) {
                        return;
                    }
                    let feeGroupToUpdate = (billingPayload?.fee?.feeGroups ?? [])?.find(v => v?.name == "TRIP CHARGES")
                    if (feeGroupToUpdate) {
                        feeGroupToUpdate.taxItem = {
                            "tax_id": tax?._id,
                            "taxes": tax?.items
                        }
                        const feeGroupIndex = billingPayload.fee.feeGroups.findIndex(v => v.name == "TRIP CHARGES");
                        if (feeGroupIndex !== -1) {
                            billingPayload.fee.feeGroups[feeGroupIndex] = feeGroupToUpdate;
                        }
                        const response = billingPayload?._id
                            ? await BookingService.editBillings(billingPayload)
                            : await BookingService.createBilling(billingPayload);
                        if (response?.data) {
                            setBilling(response.data);
                            onChange({ billing_id: response?.data?._id });
                            return response?.data;
                        }
                    }
                } catch (error) {
                    showError(error);
                } finally {
                    return resolve(true);
                }
            })
        }
    }));

    const postSave=()=>{
        setShowTripEditPage(false);
        fetchDetails();
        postSaveRefreshInvoice && postSaveRefreshInvoice();
    }
    return (
        <>
        <UncontrolledAccordion className='my-2'> 
            <AccordionItem >
                <AccordionHeader className=' w-100 col-12' targetId="1"   >
                {manual ? "" :
                        <div className='d-flex align-items-center w-100 gap-2'>
                           <span><b>#{loading? <ThreeBounce size={5} color="#5b73e8" />:trip?.tripNo}</b></span>
                            
                                <>
                                    {trip?.startTime &&
                                        <>
                                            <span>{"|"}</span>
                                            <div><b>Start Time :</b><span>{" "}{moment.unix(trip.startTime).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                            </div>
                                        </>
                                    }
                                    {(trip?.startTime && trip?.duration) &&
                                        <>
                                            <span>{"|"}</span>
                                            <div>
                                                <b>End Time :</b><span>{" "}{moment.unix(trip.startTime + Math.ceil(trip.duration * 60)).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                            </div>
                                        </>
                                    }
                                    {trip?.vehicle?.regNo &&
                                        <>
                                            <span>{"|"}</span>
                                            <div>
                                                <b> Vehicle :</b> <span>{trip?.vehicle?.regNo}</span>
                                            </div>
                                        </>
                                    }
                                    {trip?.dutySlipNo &&
                                        <>
                                            <span>{"|"}</span>
                                            <div>
                                                <b> Duty Slip No  :</b> <span>{trip?.dutySlipNo}</span>
                                            </div>
                                        </>

                                    }
                                </>
                           
                        </div>}
                </AccordionHeader>
                <AccordionBody accordionId="1">
                    <Card className='p-2 pe-0 shadow-md'>
                        {loading ? (
                            <div><CardThreeBounce size={3}/></div>
                        ) : (
                            <>
                               {invoiceTrip?.trip_id && savedStatus==1? 
                               <div className='ml-auto ms-auto p-2 me-3 cursor-pointer' onClick={()=>setShowTripEditPage(true)}>
                                  <FaPen className='' />
                               </div>:null}
                                <div className='d-flex flex-column flex-md-row'>
                                    {invoiceTrip?.trip_id && (
                                        <div className="col-12 col-md-6">
                                            <div className="d-flex row align-items-center my-1">
                                                <div className="col-6">
                                                    <div className="d-flex">
                                                        <Col sm={5}><b>Start Time</b></Col>
                                                        <Col sm={7}>{trip?.startTime ? moment.unix(trip.startTime).format('MMMM Do YYYY, h:mm a') : ""}</Col>
                                                    </div>
                                                    {trip?.startTime && trip?.duration && (
                                                        <div className="d-flex">
                                                            <Col sm={5}><b>End Time</b></Col>
                                                            <Col sm={7}>{trip?.startTime && trip?.duration
                                                                ? moment.unix(trip.startTime + Math.ceil(trip.duration * 60)).format('MMMM Do YYYY, h:mm a')
                                                                : "--"}
                                                            </Col>
                                                        </div>
                                                    )}
                                                    <div className="d-flex mt-1">
                                                        <Col sm={5}><b>Vehicle</b></Col>
                                                        <Col sm={7}>{trip?.vehicle?.regNo || ""}</Col>
                                                    </div>
                                                    <div className="d-flex mt-1">
                                                        <Col sm={5}><b>Make Model</b></Col>
                                                        <Col sm={7}>{<MakeModelSelect asLabel value={trip?.vehicle?.makeModel_id || ""} />}</Col>
                                                    </div>
                                                    <div className="d-flex mt-1">
                                                        <Col sm={5}><b>Duty Slip No</b></Col>
                                                        <Col sm={7}>{trip?.dutySlipNo || "--"}</Col>
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1 font-size-12">
                                                        <Col sm={5}><b>PickUp</b></Col>
                                                        <Col sm={7}>{trip?.src ? getAddressFromObj(trip?.src) : "--"}</Col>
                                                    </div>
                                                    {trip?.dst && (
                                                        <div className="d-flex align-items-center mt-1 mb-2 font-size-12">
                                                            <Col sm={5}><b>Drop Point</b></Col>
                                                            <Col sm={7}>{trip?.dst ? getAddressFromObj(trip?.dst) : "--"}</Col>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className='col-12 col-md-6'>
                                        <BillingNew
                                            billing={billing}
                                            trip={trip}
                                            loading={loading}
                                            forInvoice
                                            tax={tax}
                                            setBilling={setBilling}
                                            type={"Client"}
                                        />
                                        {/* <pre>{JSON.stringify(billing, null, 2)}</pre> */}
                                    </div>
                                </div>
                            </>
                        )}
                    </Card>
                </AccordionBody>
            </AccordionItem> 
        </UncontrolledAccordion>


            {showTripEditPage && (
                <SidebarModal
                    isOpen={showTripEditPage}
                    large
                    toggleModal={() => setShowTripEditPage(false)}
                >
                    < >
                        <CrossButton onClick={() =>setShowTripEditPage(false)} />
                        <EditBooking
                            trip={trip}
                            setTrip={setTrip}
                            editID={trip?._id}
                            fromInvoice
                            postSave={postSave}
                            postDutyClose={fetchDetails}
                        />
                    </>
                </SidebarModal>
            )}
      
      {/* {showTripEditPage?<div className='bg-light flex-1 position-fixed d-flex align-items-center' style={{inset:0}}> */}
        {/* <DetailPageModal
           insidePane={true}
           back={()=>setShowTripEditPage(false)}
          _body={
            <EditBooking
            trip={trip}
            setTrip={setTrip}
            editID={trip?._id}
            />
          }
          show={showTripEditPage}  
          large={true}
        /> */}
      {/* </div>:null} */}
 
        </>
    );

});

export default TripInvoiceItem;
