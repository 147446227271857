import React, { useEffect, useState } from 'react'
import DownloadService from './DowloadService'
import { RxCross2 } from 'react-icons/rx'
import "./style.css"
import { ENDPOINTS } from './DownloadConstant'
import { doGET } from '../../util/HttpUtil'
import DownloadButton from '../Buttons/DownloadButton'
import { timeStamptoyyyy_mm_dd } from '..'
const Download = () => {
    const module =DownloadService.module;
    const[data,setData] = useState([])
  const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchItems();
    }, []);


    const fetchItems = async () => {
      if (loading) return;
      if (module) {
          setLoading(true);
            try {
                const response = await doGET(ENDPOINTS.grid(module));
                setData(response?.data?.rows);
            } catch (error) {
                console.error('Error fetching items:', error);
            }
    setLoading(false);
        }
    };
  return (
    <div className="h-100 w-100 App pb-5">
    <div className="d-flex justify-content-between align-items-center mb-2 ">
      <div className='fs-4 fw-600'>{"Download"}</div>
      <div className='ms-auto p-2' style={{ cursor: "pointer" }} onClick={()=>DownloadService.toggleModal("")}><RxCross2 size={20} />
      </div>
    </div>
    <div className="w-100 mx-3 p-2" style={{ borderRadius: 5, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
    <div>
  <ul className="message-list py-1 ps-0 hide-scrollbar" style={{ height: "65vh", overflowY: "scroll" }}>
    <li className='mb-2' style={{ display: "flex", fontWeight: "bold", backgroundColor: "lightgray" }}>
      <div className="col-mail col-mail-1" style={{ flex: 1 }}>
        <div className="px-2 me-2">Label</div>
      </div>
      <div className="col-mail col-mail-2" style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
        <div className="subject" style={{ left: "90px" }}>Started At</div>
        <div className="date" style={{ right: "50px" }}>Download</div>
      </div>
    </li>
    {data?.map((ele, index) => {
      return (
        <li
          key={index}
          style={{ fontWeight: "400", color: "black", cursor: "pointer", backgroundColor: index % 2 === 0 ? "#fff" : "rgba(0, 0, 0, 0.05)" }}
        >
          <div className="d-flex align-items-center col-mail col-mail-1" onClick={(e) => e.stopPropagation()} style={{ flex: 1 }}>
            <div className="px-2 me-2">{ele?.label}</div>
          </div>
          <div className="col-mail col-mail-2" style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
            <div className="subject" style={{ left: "90px" }}>{timeStamptoyyyy_mm_dd(ele?.startedAt)}</div>
            <div className="date mt-1" style={{ right: "50px" }}><DownloadButton link={ele?.url} /></div>
          </div>
        </li>
      );
    })}
  </ul>
</div>

            
            </div>
  </div>
  )
}

export default Download