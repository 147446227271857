import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import {
  CardThreeBounce, Checkbox, CorporateSelect, SelectField
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "./MessageTemplateConstant";
import MessageTemplateService from "./MessageTemplateService";

const EditTemplates = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [messagetemp, setmessagetemp] = useState(null);
  const [tempVariables, setTempVariables] = useState(null);
  const [showCorporates, setShowCorporates] = useState(false);


  const fetch = async () => {
    try {
      let states = await MessageTemplateService.get(editId);
      setData(states);
    } catch (error) {
      showError(error);
    }
  };
  const fetchmessdata = async () => {
    try {
      let messdata = await MessageTemplateService?.getmessagetype();
      setmessagetemp(messdata);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    fetchmessdata()
  }, [editId]);

  useEffect(() => {
    setData(null);
    setLoading(true);
    if (editId) fetch();
    setLoading(false);
  }, [editId, MessageTemplateService?.version]);

  const getKeyValues = () => {
    const message = messagetemp?.find(item => item?.name === data?.messageType);
    if (!message) {
      return null;
    }
    if (data?.platform === 'SMS') {
      return message?.smsKeys;
    } else if (data?.platform === 'WHATSAPP') {
      console.log(message?.whatsAppKeys)
      return message?.whatsAppKeys;
    } else {
      return null;
    }
  }
  useEffect(() => {
    const variables = getKeyValues();
    setTempVariables(variables);
  }, [data?.messageType, data?.platform])

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <React.Fragment>
      {loading ? (
        <CardThreeBounce />
      ) : (
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row>
            <Checkbox
              placeholder={t("For All")}
              checked={!showCorporates ? true : false}
              required={true}
              label={t("For All")}
              onClick={() => {
                setShowCorporates(!showCorporates)
                setData({ ...data, corp_id: null })
              }}
              showErr={hasErr}
              type="text"
              className="mt-3 col-sm-3"
            />
            {showCorporates &&
              <CorporateSelect
                value={data?.corp_id}
                onChange={(v) => {
                  setData({ ...data, corp_id: v?.value })
                }
                }
                className="col-sm-4"
                style={{ zIndex: '4' }}
              />
            }
            <SelectField
              data={SELECTSTRUCTURE?.platform}
              value={data?.platform}
              label={t("Platform")}
              onChange={(v) => {
                setData({ ...data, platform: v?.value });
              }}
              type="text"
              error={data?.platform?.length === 0 ? t("Enter Platform") : ""}
              showErr={hasErr}
              placeholder={t("Platform")}
              className="col-sm-3"
            />
            <SelectField
              data={messagetemp}
              placeholder={t("Message Type")}
              value={data?.messageType}
              label={t("Message Type")}
              error={data?.messageType?.length === 0 ? t("Enter MessageType") : ""}
              showErr={hasErr}
              onChange={(v) => setData({ ...data, messageType: v?.value })}
              type="text"
              className="col-sm-5"
            />
          </Row>
          <Row className="mt-2">
            <div><b>Template Variables :</b></div>
            <div className=" align-items-center gap-3 bg-white mt-2">
              {tempVariables?.map((variable) => <div style={{ flex: "wrap" }}><b>{variable}</b></div>)}
            </div>
          </Row>
          <Row className="mt-3">
            <textarea
              className="col-sm-12 p-2"
              style={{ borderColor: "hsl(0, 0%, 80%)", outline: "none" }}
              rows="20"
              placeholder={t("Enter content")}
              value={data?.content}
              label={t("Content")}
              onChange={(v) => setData({ ...data, content: v?.target?.value })}
              type="text"
            />

          </Row>
        </Form>
      )}
    </React.Fragment>
  );
};

export default observer(EditTemplates);
