import { AccountSelect, ClientSelect, CompanySelect, EmployeeSelect, PaymentStatusSelect, SupervisorRoleSelect, SupervisorSelect } from "../../components";
import AccountLabel from "../../components/Dropdowns/AccountSelect/AccountLabel";
import EmployeeSupervisorLabel from "../../components/Dropdowns/EmployeeSupervisorSelect/EmployeeSupervisorLabel";
import EmployeeSupervisorSelect from "../../components/Dropdowns/EmployeeSupervisorSelect/EmployeeSupervisorSelect";
import Supervisor from "../Supervisor/Supervisor";
import { SELECTSTRUCTURE } from "./PaymentReceivedConstant";

const paidTo = {
    1: "Paid To Vendor",
    2: "Paid To Driver",
    3: "Paid To Agent",
    4: "Paid to Vehicle Owner",
    5: "Others"
}

export const GridColumns = {
    version: 1,
    columns: [
        // {
        //     title: "Account ID",
        //     accessor: "",
        //     show: true,
        //     renderer: (dataObj) => {
        //         return <div>
        //             {dataObj?.account_id}
        //         </div>;
        //     },
        // },
        {
            title: "Account",
            accessor: "account_id",
            show: true,
            renderer: (dataObj) => {
                return <div>
                  <AccountLabel value={dataObj?.account_id} />
                </div>;
            },
        },
        {
            title: "Client",
            accessor: "client_id",
            renderer: (dataObj) => {
                return <div>
                    <ClientSelect
                        asLabel
                        corpType="supplier"
                        value={dataObj?.client_id}
                        className=""
                    />
                </div>;
            },
            show: true,
        },
        {
            title: "Client Company",
            accessor: "clientCompany_id",
            renderer: (dataObj) => {
                return <div>
                    <CompanySelect
                        corpType="client"
                        corp_id={dataObj?.client_id}
                        asLabel
                        value={dataObj?.clientCompany_id}
                        className=""
                    />
                </div>;
            },
            show: true,
        },
        {
            title: "Company",
            accessor: "company_id",
            renderer: (dataObj) => {
                return <div>
                    <CompanySelect
                        asLabel
                        value={dataObj?.company_id}
                        className=""
                    />
                </div>;
            },
            show: true,
        },
        {
            title: "Added By",
            accessor: "createdBy",
            renderer: (dataObj) => {
                return <div>
                <EmployeeSupervisorLabel
                    value={dataObj?.createdBy}
                />
            </div>;              
            },
            show: true,
        },
        {
            title: "Amount Received",
            accessor: "amountReceived",
            renderer: (dataObj) => {
                return <div>
                    {dataObj?.amountReceived ?
                        `${dataObj?.currency ? dataObj?.currency : "INR"} ${(dataObj?.amountReceived / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
                        "--"
                    }
                </div>;
            },
            show: true,
        },
        {
            title: "TDS",
            accessor: "tds",
            renderer: (dataObj) => {
                return <div>
                    {dataObj?.tds ?
                        `${dataObj?.currency ? dataObj?.currency : "INR"} ${(dataObj?.tds / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
                        "--"
                    }
                </div>;
            },
            show: true,
        },
        {
            title: "Excess Amount",
            accessor: "excessAmount",
            renderer: (dataObj) => {
                return <div>
                    {dataObj?.excessAmount ?
                        `${dataObj?.currency ? dataObj?.currency : "INR"} ${(dataObj?.excessAmount / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
                        "--"
                    }
                </div>;
            },
            show: true,
        },
        {
            title: "Transaction Ref No",
            accessor: "transactionRef",
            renderer: (dataObj) => {
                return <div>
                  {(!dataObj?.transactionRef ||dataObj?.transactionRef==="")  ?"--":  dataObj?.transactionRef }
                </div>;
            },
            show: true,
        },
        {
            title: "Date",
            accessor: "date",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    {dataObj?.date ? `${dataObj.date.toString().slice(6, 8)}-${dataObj.date.toString().slice(4, 6)}-${dataObj.date.toString().slice(0, 4)}` : "--"}
                </div>;
            },
        },
        {
            title: "Status",
            accessor: "status",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    <PaymentStatusSelect
                        asLabel
                        outline
                        value={dataObj?.status}
                        label={"Status"}
                        data={SELECTSTRUCTURE?.paymentStatus}
                        placeholder={"Status"}
                    />
                </div>;
            },
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
