import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { AmountField, DeleteButton, Documents, InputField, ModalComponent, SaveButton, ToggleSelector } from "../../../components";
import DateTimePickerWithInterval from "../../../components/DateTimePickerWithInterval";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import TripExpenseService from "./TripExpenseService";
import './index.scss';


const SupplierFareDetail = ({
    onEdit = () => { },
    editId,
    trip_id,
    isOpen,
    toggleModal,
    setExpenses,
    setShowTripExpenseDetail
}) => {
    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({ category: "Toll Tax" });

    const fetch = async () => {
        try {
            setLoading(true)
            let tripDetail = await TripExpenseService.get(editId);
            setData(tripDetail);
            setLoading(false)

        } catch (error) {
            showError(error)
            setLoading(false)

        }
    };

    const onSave = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            if (data?._id) {
                const lead = await TripExpenseService.edit(data, data?._id);
                setExpenses(await TripExpenseService.fetch(trip_id))
                setData(lead)
                setLoading(false);
                showMessage(t("Trip Expense successfully."));
            } else {
                const lead = await TripExpenseService.save({
                    ...data,
                    trip_id,
                    currency: "INR",
                });
                const response = await TripExpenseService.fetch(trip_id)
                setExpenses(response)
                setShowTripExpenseDetail({ id: response[0]?._id, status: true })
                setData(lead)
                setLoading(false);
                showMessage(t("Trip Expense saved successfully."));
            }
        } catch (e) {
            setLoading(false);
            showError(e);
        }
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await TripExpenseService.delete(id);
                setExpenses(await TripExpenseService.fetch(trip_id))
                setLoading(false);
                showMessage("Trip Expense Deleted SuccessFully", "Deleted");
                toggleModal()
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };

    useEffect(() => {
        if (isOpen) {
            setData({ category: "Toll Tax" });
            setLoading(true);
            if (editId) {
                fetch();
            } else {

            }
            setLoading(false)
        }
    }, [editId]);


    return (
        <React.Fragment>
            <ModalComponent
                position={"center"}
                size={"md"}
                isOpen={isOpen}
                onToggle={toggleModal}
            >

                <ModalComponent.Title>
                    Trip Expense
                </ModalComponent.Title>

                <ModalComponent.Body>


                    <ToggleSelector
                        data={[
                            { label: "Toll Tax", value: "Toll Tax" },
                            { label: "State Tax", value: "State Tax" },
                            { label: "FasTag", value: "FasTag" },
                            { label: "Parking Fare", value: "Parking Fare" },
                            { label: "MCD", value: "MCD" },
                            { label: "Other", value: "Other" },
                        ]}
                        value={data?.category}
                        onChange={(v) => {
                            setData({ ...data, category: v });
                        }}
                        label={t("Account Status")}
                        className="col-sm-6 col-12"
                        customStyleClass="sm-screen-margin"
                    />

                    <div className="d-flex align-items-center">
                        <div style={{ width: "120px" }}> Amount</div>
                        <AmountField
                            className={"my-0"}
                            placeholder="Amount Price"
                            value={data?.amount}
                            onChange={(v) => {
                                setData({ ...data, amount: v });
                            }}
                            type="text"
                        />
                    </div>


                    <InputField
                        value={data?.detail}
                        onChange={(v) => {
                            setData({ ...data, detail: v });
                        }}
                        label="Details"
                    />



                    <DateTimePickerWithInterval
                        className="flex-1"
                        placeholder="Paid At"
                        label="Paid At"
                        timestamp
                        value={data?.paidAt}
                        onChange={(v) => {
                            setData({ ...data, paidAt: v });
                        }}
                    />

                    {editId &&
                        <Documents
                            module="trip-expense"
                            record_id={data?._id}
                        />}
                </ModalComponent.Body>

                <ModalComponent.Footer>
                    <div className="d-flex justify-content-end">
                        {data?._id && <DeleteButton onClick={(e) => onDelete(e, data?._id)} loading={loading} />}
                        <SaveButton loading={loading} onClick={onSave} disabled={loading} />
                    </div>
                </ModalComponent.Footer>

            </ModalComponent>



        </React.Fragment>
    );
};

export default observer(SupplierFareDetail);

