import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import CalendarView from '../CalendarView'
import { I18nContext } from '../../store/context/i18nContext';
import { FaPen } from 'react-icons/fa';
import moment from 'moment';
import TimeSelect from '../DateSelects/TimeSelect';

const findNextFutureDate = (datesArray) => {
    const today = moment();
    const sortedDates = datesArray.sort((a, b) => moment(a, 'YYYYMMDD') - moment(b, 'YYYYMMDD'));

    for (const date of sortedDates) {
        if (moment(date, 'YYYYMMDD').isAfter(today)) {
            return date;
        }
    }
    
    return null;
};

function ShuttleScheduler({onChange=()=>{},schedule={},disabled=false}) {
    const { t } = useContext(I18nContext)
    const [visible, setVisible] = useState(false);
    const toggleModal = () => setVisible(prev => !prev);
    const [events, setEvents] = useState([]);//format as YYYYMMDD from schedule runTime data
    const [time, setTime] = useState(''); //format time from last runTime data
    const [nextRun,setNextRun]=useState({
        date:null,
        time:null
    })

    // useEffect(() => {
    //     if (events.length > 0) {
    //         const closestFutureDate = findNextFutureDate(events);
    //         setNextRun({
    //             date: closestFutureDate,
    //             time: moment(time, 'HHmm').format('HHmm')
    //         });
    //         const updatedRunTimes = events.map(event => ({ key: event, value: time }));
    //         onChange({
    //             ...schedule,
    //             runTimes: updatedRunTimes
    //         });
    //     }
    // }, [events, time]);

    useEffect(() => {
        if (schedule?.runTimes?.length) {
            const eventKeys = schedule.runTimes.map(runTime => parseInt(runTime.key));
            setEvents(eventKeys);
            const lastRunTime = schedule.runTimes[schedule.runTimes.length - 1];
            if (lastRunTime?.value) {
                setTime(lastRunTime.value);
            }
            //set Next run date and time
            const closestFutureDate = findNextFutureDate(eventKeys);
            const firstFutureRunTime = schedule.runTimes?.find(runTime=>runTime.key===closestFutureDate)
            setNextRun({
                date: closestFutureDate,
                time:firstFutureRunTime?.value
            })
        }
    }, [schedule]);

    const onChangeHandler = useCallback((updatedEvents) => {
        if (updatedEvents.length > 0) {
            const closestFutureDate = findNextFutureDate(updatedEvents);
            setNextRun({
                date: closestFutureDate,
                time: moment(time, 'HHmm').format('HHmm')
            });
            setEvents(updatedEvents)
            const updatedRunTimes = updatedEvents.map(event => ({ key: event, value: time }));
            onChange(prevTrip=>({
                ...prevTrip,
                schedule:{
                    ...(prevTrip?.schedule??{}),
                    runTimes: updatedRunTimes
                }
            }));
        }
    },[onChange,setNextRun,setEvents,events,time]);

    useEffect(()=>{
        if(time){
            setTime(time)
            onChange(prevTrip=>({
                ...prevTrip,
                schedule:{
                    ...(prevTrip?.schedule??{}),
                    runTimes: events.map(event => ({ key: event, value: time }))
                }
            }));
        }
    },[time])

    return (
        <>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center gap-1'>
                    Next Run:
                    {nextRun?.date ? <>
                    <div className='mx-1 border border-1 rounded-2 py-2 px-3'>{moment(nextRun?.date, 'YYYYMMDD').format('DD-MM-YYYY')}</div> 
                    </>:<div onClick={toggleModal} className='py-2 link-info cursor-pointer'>Select Dates & Time</div>}
                    {/* {time ? <div className='mx-1 border border-1 rounded-2 py-2 px-3'>{moment(time, 'HHmm').format("hh:mm A")}</div> : null} */}
                    {time ?
                        <TimeSelect
                            placeholder="Next Run Time"
                            label="Next Run Time"
                            className=""
                            value={nextRun?.time}
                            isDisabled={disabled}
                            outline
                            onChange={(v) => {
                                const closestFutureDate = findNextFutureDate(events);
                                //update next run
                                setNextRun({
                                    date: closestFutureDate,
                                    time: v
                                });
                                //update schedule data also
                                onChange(prevTrip => ({
                                    ...prevTrip,
                                    schedule: {
                                        ...(prevTrip?.schedule ?? {}),
                                        runTimes: events.map(event => ({ key: event, value: event === closestFutureDate ? v : time }))
                                    }
                                }));
                            }}
                        /> : null}
                    {events?.length>1 ? `+${events?.length-1} more days` : ''}
                </div>
                <FaPen onClick={toggleModal} size={20} className=' cursor-pointer ' />
            </div>
            <Modal
                toggle={toggleModal}
                isOpen={visible}
                scrollable={true}
                className="modal-xl zr_action_prevent_modal"
                backdrop="static"
            >
                <ModalHeader toggle={toggleModal}>
                    {t("Shuttle Scheduler")}
                </ModalHeader>

                <ModalBody>
                    <CalendarView disabled={disabled} events={events} onChange={onChangeHandler} showTimeSelector={true} onTimeChange={setTime} time={time} />
                </ModalBody>
            </Modal>

        </>
    )
}

export default ShuttleScheduler