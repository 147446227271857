export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/log/map?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
};

export const STRUCTURE = [
  {
    label: "Date From",
    description: "Select Date",
    filterLabel: "Date From",
    filterName: "createdAtgt",
    type: "dateTimeSelect",
    filterType: "greaterThan"
  },
  {
    label: "Date To",
    description: "Select Date",
    filterLabel: "Date To",
    filterName: "createdAtlt",
    type: "dateTimeSelect",
    filterType: "lessThan"
  },
];