import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { FaBullseye } from "react-icons/fa";
import { GoTriangleDown, GoTriangleRight } from "react-icons/go";
import CrmListItemHorizontal from "../items/LeadListItemHorizontal";
import QuoteList from "../styles/list";
import LeadMasterService from "./LeadMasterService";
import "./style.scss";

const LeadRow = ({ refresher, title, index, isScrollable, isCombineEnabled, useClone }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [leads, setLeads] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [totalDisplay, setTotalDisplay] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showLeads, setShowLeads] = useState(true);
  const listRef = useRef();

  const refresh = () => {
    setPage(1)
    setTotal(1)
    setHasMore(true)
  }

  useEffect(() => {
    if (title == LeadMasterService.statusRefresher?.status || title == LeadMasterService.statusRefresher?.prevStatus) {
      refresh();
    }
  }, [LeadMasterService.statusRefresher?.version])

  const makeLeadObject = (leadItem) => {
    const { brandName, fullName } = leadItem;
    const renderer = () => (
      <CrmListItemHorizontal
        onDelete={() => { refresh() }}
        onEdit={() => { refresh() }}
        title={brandName}
        author={fullName}
        lead={leadItem}
        avatarUrl={`https://api.multiavatar.com/${fullName}.svg`}
      />
    );

    return {
      id: JSON.stringify(leadItem), // Assuming each item has a unique ID
      item: leadItem,
      content: brandName,
      renderer,
    };
  }

  const fetchLeads = useCallback(async () => {
    if (!hasMore || loading) return;
    setLoading(true)
    const response = await LeadMasterService.fetchLeadsByStatus({ status: title, page });
    const leadRows = response?.rows?.map(makeLeadObject);
    if (page === 1) {
      setTotal(response.total ?? 0);
      setTotalDisplay(response.total ?? 0);
    }
    setPage((prevPage) => prevPage + 1);
    setHasMore((page === 1 ? response.total ?? 0 : total) > leads.length + leadRows.length);
    if (page == 1) {
      setLeads(leadRows);

    } else {
      setLeads((prevLeads) => [...prevLeads, ...leadRows]);

    }
    setLoading(false)
  }, [title, hasMore, page, total, leads.length]);


  useEffect(() => {
    const fillScreenWithData = async () => {
      // Calculate if the current data fills the screen
      if ((listRef?.current && hasMore) || page == 1) {
        const { clientHeight, scrollHeight } = (listRef?.current ?? { clientHeight: 1, scrollHeight: 2 });
        if ((scrollHeight <= clientHeight) || page == 1) {
          await fetchLeads();
        }
      }
    };


    fillScreenWithData();
  }, [leads, hasMore, fetchLeads]);

  useEffect(() => {
    if (refresher?.destinationId) {
      const isSourceUpdated = refresher?.sourceId == title
      const isDestinationUpdated = refresher?.destinationId == title
      const isSourceAndDestinationSame = refresher?.destinationId == refresher?.sourceId

      if (isSourceUpdated && !refresher?.onlyUpdateLeadObject && !isSourceAndDestinationSame) {
        const newLeads = [...leads];
        newLeads.splice(refresher.sourceIndex, 1);
        setLeads(newLeads);
        setTotalDisplay((prevTotalDisplay) => prevTotalDisplay - 1);

      }

      if (isDestinationUpdated && !refresher?.onlyUpdateLeadObject && !isSourceAndDestinationSame) {
        const newLeads = [...leads];
        newLeads.splice(refresher.destinationIndex, 0, makeLeadObject(refresher.leadObject));
        setLeads(newLeads);
        setTotalDisplay((prevTotalDisplay) => prevTotalDisplay + 1);
      }

      if (isSourceAndDestinationSame && isDestinationUpdated && !refresher?.onlyUpdateLeadObject) {
        const newLeads = [...leads];
        newLeads.splice(refresher.sourceIndex, 1);
        newLeads.splice(refresher.destinationIndex, 0, makeLeadObject(refresher.leadObject));
        setLeads(newLeads);
      }

      if ((isDestinationUpdated && refresher?.onlyUpdateLeadObject)) {
        const updatedLeads = leads.map(lead => {
          if (lead.item._id === refresher.leadObject._id) {
            return makeLeadObject(refresher.leadObject)
          }
          return lead;
        });
        setLeads(updatedLeads);
      }
    }
  }, [refresher?.sourceId, refresher?.destinationId, refresher?.leadObject?._id, refresher?.leadObject?.version,])

  useEffect(() => {
    const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom && hasMore) {
        fetchLeads();
      }
    };

    const listElement = listRef?.current;

    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [fetchLeads, hasMore]);




  return (
    <Draggable draggableId={title} index={index}>
      {(provided, snapshot) => (
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="row-crm-container"
          ref={provided.innerRef}
          {...provided.draggableProps}>

          <div className="column-header" isDragging={snapshot.isDragging}>
            <div
              className="item-h4 d-flex  align-items-center  px-0"
              isDragging={snapshot.isDragging}
              {...provided.dragHandleProps}
              aria-label={`${title} quote list`}
            >



                <div style={{ cursor: "pointer" }} >
                  {showLeads ?
                    <GoTriangleDown size={18} onClick={() => {
                      setShowLeads(false)
                    }} />
                    :
                    <GoTriangleRight size={18} onClick={() => {
                      setShowLeads(true)
                    }} />}

                </div>

              <div className=" d-flex  align-items-center ">
                  <FaBullseye size={20} color={LeadMasterService.statusNameMap[title]?.color} className='me-2' />
                  {LeadMasterService.statusNameMap[title]?.name}
                </div>
                <div className="item-column-length ms-2">
                  {totalDisplay ?? 0}
                </div>
           
              </div>

          </div>

          <div className="ps-2">
            {showLeads && <div style={{ borderBottom: "1px solid #EBEBEB" }} className="row font-size-11 ps-2 border-b">
              <div className="col">Title</div>
              <div className="col">Assignees</div>
              <div className="col">Priority</div>
            </div>}


            <QuoteList
              hide={!showLeads}
              horizonatalListView
              listId={title}
              listRef={listRef}
              listType="QUOTE"
              style={{
                backgroundColor: snapshot.isDragging ? "#BBB" : null
              }}
              quotes={leads}
              internalScroll={isScrollable}
              isCombineEnabled={Boolean(isCombineEnabled)}
              useClone={Boolean(useClone)}
            />
          </div>


        </div>
      )}
    </Draggable>
  );
};

export default observer(LeadRow);
