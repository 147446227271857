import React, { useState } from 'react'
import { Button, Card, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { RFPImage, marketplaceRegisterIcon } from '../../../assets/images';
import MarketPlaceregisterComponet from './MarketPlaceregisterComponet';

const MarketplaceRegisterPopup = ({ onClose, setClientModal }) => {


    return (
        <div className=" " style={{
            backgroundColor: "white",
            borderRadius: "25px"
        }}>
            <Card className=" pt-3" style={{
                width: "550px",
                height: "550px"
            }}>
                <div className=" d-flex justify-content-center align-items-center">
                    <div className=" d-flex justify-content-center align-items-center" style={{ background: "#F2F4FC", boxSizing: "border-box", width: "50px", height: "50px", borderRadius: "100px", marginRight: "10px" }} >
                        <img width="100px" height="22px" src={RFPImage} alt="" />
                    </div>
                    <h4 className='d-flex justify-content-center font-size-20 fw-bold'>RFP : Request for Proposal</h4>
                </div>

                {/* <div className="close-danger-btn">
                    <Button
                        color="danger"
                        className="waves-effect waves-light __modal-close-btn"
                        onClick={() => {
                            setregisterpopup(false)
                        }}
                    >
                        <i style={{ fontSize: "20px" }} className="bx bx-x" />
                    </Button>
                </div> */}

                <div className='px-5 mt-3 mb-1' style={{
                    color: "#575B5B",
                    fontStyle: "Urbanist"
                }}>
                    <p className='mb-0 '>In the world of procurement, finding the right suppliers and obtaining competitive pricing is essential for businesses to thrive. One effective way to achieve this is by utilizing Request for Proposals (RFPs) and leveraging our online marketplace.By utilizing online marketplaces for RFPs,</p>
                </div>
                <div className='mt-2 mb-1 px-5 m-auto'>
                    <b className=' para font-size-12'>
                        By utilizing online marketplaces for RFPs, you can unlock several advantages
                    </b>
                </div>

                <div className='pe-3 ps-3 mt-2 mb-3'>
                    <MarketPlaceregisterComponet heading="Increased Supplier Access:"
                        data="Online marketplaces provide access to a diverse range of suppliers, expanding your options and increasing competition."
                    />

                    <MarketPlaceregisterComponet heading="Time and Cost Savings:"
                        data="The streamlined RFP process through online marketplaces saves time by automating tasks like proposal submissions, communication, and evaluation. Additionally, increased competition among suppliers often results in cost savings for your organization"
                    />

                    <MarketPlaceregisterComponet heading="Enhanced Transparency: "
                        data="Online marketplaces offer transparency by enabling you to view supplier profiles, ratings, and reviews from previous customers. This information aids in making well-informed decisions."
                    />
                    <MarketPlaceregisterComponet heading="Improved Efficiency: "
                        data="The centralized nature of online marketplaces simplifies the entire procurement process, from floating an RFP to selecting a supplier. This efficiency allows you to focus on other critical aspects of your business."
                    />
                </div>

                <div className=' m-auto mb-5  d-flex justify-content-center align-items-center'>
                    <Button style={{
                        width: "200px",
                        height: "30px"
                    }} className="submit-btn d-flex align-items-center  justify-content-center" onClick={() => {
                        setClientModal(true)
                        onClose()
                    }}>
                        Register For Marketplace
                    </Button>

                </div>
            </Card >


        </div>
    )
}
export default MarketplaceRegisterPopup