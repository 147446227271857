import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import Office from "../../../pages/Office/Office";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, ModalComponent, SearchButton } from "../../index";
import OfficeSelectService from "./OfficeSelectService";
import { ThreeBounce } from "better-react-spinkit";
import { UserContext } from "../../../store/context/UserContext";

const OfficeSelect = ({
  style,
  isDisabled,
  className,
  onChange = () => { },
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  plain,
  asLabel,
  outline,
  valueLabel = false,
  noLabel,
  corp_id,
  placeholder
}) => {
  const [offices, setOffices] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)
  const { userContent } = useContext(UserContext)

  const fetchOffices = async () => {
    try {
      const office = await OfficeSelectService.fetch({ valueLabel, corp_id: userContent?.corp_id == corp_id ? undefined : corp_id });
      if (office) {
        setOffices(office);
      }
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (OfficeSelectService.offices.length === 0)
      fetchOffices();
  }, [valueLabel, userContent]);

  useEffect(() => {
    setOffices(toJS(OfficeSelectService.offices));
  }, [OfficeSelectService.offices]);


  useEffect(() => {
    offices?.map(office => {
      if (office?.value == value) {
        onChange(office)
      }
    })
  }, [offices]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectOffice = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0] : selectedValue });
    closeModal();
  };

  const selectOffices = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      // bottomBarChildren={() => (
      //   <BottomBarButton onClick={openModal} title={t("Manage Offices")} />
      // )}
      // showBottomBar={true}
      className={className}
      noLabel={noLabel}
      onChange={onChange}
      data={offices}
      plain={plain}
      outline={outline}
      required={required}
      value={value}
      label={label || (!isDisabled && t("Office"))}
      placeholder={placeholder || t("Office")}
      error={!value ? t(`Please Select ${label || "Office"}`) : ""}
      showErr={showErr}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        // <BottomBarButton onClick={openModal} title={t("Manage Offices")} />
        <div></div>
      )}
      // showBottomBar={true}
      className={className}
      onChange={selectOffices}
      data={offices}
      noLabel={noLabel}
      value={value ? offices.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Offices"}`) : ""}
      required={required}
      label={t("Select Offices")}
      plain={plain}
      outline={outline}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
      {
        asLabel ? <div>{offices?.find((office) => office?.value == value)?.label ?? <div style={{ color: "grey" }}>--</div>}</div> :
          <>

            {modal && (
              <ModalComponent
                position={"top"}
                size={"large"}
                onToggle={closeModal}
                isOpen={modal}
              >
                <ModalComponent.Title>
                  {t("Office")}
                </ModalComponent.Title>

                <ModalComponent.Body>
                  <Office
                    org_id={corp_id}
                    value={value}
                    insidePane={true}
                    multiMode={multi}
                    onSelect={multi ? selectOffices : selectOffice}
                  />
                </ModalComponent.Body>

              </ModalComponent>
            )}


            {offices.length > 0
              ? multi
                ? renderSelectFieldMultiple()
                : renderSelectField({ disabled: false })
              : valueLabel ? <ThreeBounce size={10} color="pink" /> : renderSelectField({ disabled: true })}
          </>
      }
    </>
  );
};

export default observer(OfficeSelect);
