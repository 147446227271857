import React, { useContext, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { I18nContext } from '../../store/context/i18nContext';
import ToggleSelector from '../Toggle/ToggleSelector';
import { observer } from 'mobx-react-lite';
import RosteringService from '../../pages/Rostering/RosteringService';
import { InputField } from '../InputField/InputField';
import AddRemoveButton from '../AddRemoveButton';

const CreateRoutePlanModal = ({onSave}) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const { t } = useContext(I18nContext);
    const { roster, updateRoster } = RosteringService

    return (
        <div>
            <Button
                className="mx-2 w-100 mx-auto mb-1"
                style={{ height: "28px", padding: "2px 10px 2px 4px", marginTop: '8px' }}
                color="success"
                onClick={toggle}>
                <div className="d-flex justify-content-center" style={{ margin: "2px" }}>{t(`Make Plan`)}</div>
            </Button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Create Route Plan</ModalHeader>
                <ModalBody>
                    <Form>
                        {/* <FormGroup check>
                            <Label check>
                                <Input type="checkbox" id="byStoppage" />{' '}
                                By Stoppage
                            </Label>
                        </FormGroup> */}

                        <Row form className='d-flex align-items-center'>
                            <Col md={6}>
                                <InputField
                                    outline
                                    placeholder="Radius (KM)"
                                    label="Radius (KM)"
                                    value={roster?.radius}
                                    type="number"
                                    className=""
                                    onChange={(v) => updateRoster('radius', v)}
                                />
                            </Col>
                            {/* <Col md={6} >
                                <FormGroup check>
                                    <Label check className='d-flex align-items-center gap-2'>
                                        <Input checked={roster?.returning} onChange={() => updateRoster('returning', !roster?.returning)} type="checkbox" id="byStoppage" />{' '}
                                        Returning
                                    </Label>
                                </FormGroup>
                            </Col> */}
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <InputField
                                    outline
                                    placeholder="Max Deviation in Meter(s)"
                                    label="Max Deviation in Meter(s)"
                                    value={roster?.deviationMeters}
                                    type="number"
                                    className=""
                                    onChange={(v) => updateRoster('deviationMeters', v)}
                                />
                            </Col>
                            <Col md={6}>
                                <InputField
                                    outline
                                    placeholder="Max Deviation in Percent"
                                    label="Max Deviation in Percent"
                                    value={roster?.deviationPercent}
                                    type="number"
                                    className=""
                                    onChange={(v) => updateRoster('deviationPercent', v)}
                                />
                            </Col>
                        </Row>
                        {/* <ToggleSelector
                            data={[
                                { label: "Single Vehicle Type", value: true },
                                { label: "Multiple Vehicle Type", value: false },
                            ]}
                            className=" w-50"
                            value={roster.singleVehicle}
                            onChange={(selectedValue) => {
                                updateRoster('singleVehicle', selectedValue)
                            }}
                        /> */}

                        {roster?.clusterSizes?.map((cluster, index) => (
                            <div key={index} className='d-flex flex-1'>
                                <Row form className='flex-1 me-1'>
                                    <Col md={6}>
                                        <InputField
                                            outline
                                            placeholder="Max. Passengers Per Vehicle	"
                                            label="Max. Passengers Per Vehicle	"
                                            value={cluster?.size}
                                            type="number"
                                            className=""
                                            onChange={(v) => updateRoster('clusterSizes', roster.clusterSizes.map((c, i) => i === index ? { ...c, size: v } : c))}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputField
                                            outline
                                            placeholder="No. of Vehicles"
                                            label="No. of Vehicles"
                                            value={cluster?.cars}
                                            type="number"
                                            className=""
                                            onChange={(v) => updateRoster('clusterSizes', roster.clusterSizes.map((c, i) => i === index ? { ...c, cars: v } : c))}
                                        />
                                    </Col>
                                </Row>
                                <AddRemoveButton hideSub={index == 0}
                                    onAdd={() => updateRoster('clusterSizes', roster.clusterSizes.concat({}))}
                                    onRemove={() => updateRoster('clusterSizes', roster.clusterSizes.filter((_, i) => i !== index))}
                                />
                            </div>
                        ))}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={()=>{
                        onSave();
                        setModal(false);
                    }}>Create</Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default observer(CreateRoutePlanModal);
