import React, { useContext, useState, useEffect } from 'react'
import { Button, Col, Form, Row } from 'reactstrap'
import { EditLink, InputField, Modal, SaveButton, } from '../../../components'
import { DialogContext } from '../../../store/context/DialogContext'
import { I18nContext } from '../../../store/context/i18nContext'
import { UserContext } from '../../../store/context/UserContext'
import DocUploader from '../../Driver/DocUploader'
import { ENDPOINTS } from '../ProfileConstant'
import ProfileService from '../ProfileService'
import Logs from './Logs'
import EntityButton from "./components/AuditLog"
import { useNavigate, useParams } from 'react-router-dom'
import { doGET } from '../../../util/HttpUtil'
import FileUpload from '../../../components/DocUpload/FileUpload'
// import { userEvent } from '@testing-library/user-event/dist/types/setup'

const OrganizationProfile = (props) => {
    const { t } = useContext(I18nContext);
    const { showError, showMessage } = useContext(DialogContext);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);


    const { id } = props;
    const [data, setData] = useState({});
    const { user, setUser, getUser } = useContext(UserContext)
    const [showLogsP, setShowLogsP] = useState(false);
    const [conf, setConf] = useState(false);
    const [auditlogs, setAuditLogs] = useState([])
    const [selected_id, setSelected_id] = useState(id);
    const [showDetailPage, setShowDetailPage] = useState(id);

    const fetch = async () => {
        try {
            let tag = await ProfileService.getCorporateDetail(id);
            setData(tag);
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        setData(null)
        setLoading(true);
        if (id) fetch();
        setLoading(false)
    }, [id]);


    const saveHandler = async (e) => {
        e.preventDefault();
        try {
            const res = await ProfileService.editCorporate(data)
            showMessage('success', "Organization Profile Updated");
            // getUser()
        } catch (error) {
            showError(error)
        }
    }

    // const showLogs = (e, config) => {
    //     e.preventDefault();
    //     setShowLogsP(true);
    //     setConf(config);
    // }

    // const getAuditLogs = async (e, type = "") => {
    //     e.preventDefault()
    //     try {
    //         showLogs(e, false)
    //         let res;

    //         if (type === "corporate") {

    //             res = await doGET(`/api/audit/CorporateConfig/${id}/grid`);
    //         } else {
    //             res = await doGET(`/api/audit/Corporate/${id}/grid`);

    //         }
    //         setAuditLogs(res?.data?.rows)
    //     } catch (error) {

    //     }
    // }



    return (
        <>
            {/* {JSON.stringify(data)} */}
            {showLogsP && <Modal className='w-75' onChange={() => setShowLogsP(false)}>
                <Logs corpId={props?.corpId} auditlogs={auditlogs} conf={conf} />
            </Modal>}
            <Col
                className="col-12 p-2 ps-4"
                style={{
                    backgroundColor: "#EAEAEA",
                    fontSize: "15px",
                    borderBottom: "1px solid rgba(0,0,0,.1)",
                }}
            >
                <header className="d-flex justify-content-between align-items-center">
                    <m>{`Corporate/Organization Profile`}</m>
                </header>
            </Col>
            <Form className='p-4'>
                {/* <Row>
                    <Col className='col-sm-6 col-12 mb-3'>
                        <InputField value={user?.corporate?.name} onChange={(v) => { setUser({ ...user, corporate: { ...user?.corporate, name: v } }) }} label={t(' Name(Short)')} required />
                    </Col>
                    <Col className='col-sm-6 col-12 mb-3'>
                        <InputField value={user?.corporate?.fullName} onChange={(v) => { setUser({ ...user, corporate: { ...user?.corporate, fullName: v } }) }} label={t(' Full Name')} required />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-sm-6 col-12 mb-3'>
                        <InputField value={user?.corporate?.corpType} onChange={(v) => { setUser({ ...user, corporate: { ...user?.corporate, corpType: v } }) }} label={t('Corp Type')} required />
                        Uique Id {user?.corporate?.corpType}
                    </Col>
                    <Col className='col-sm-6 col-12 mb-3'>
                        <InputField value={user?.corporate?.unique_id} onChange={(v) => { setUser({ ...user, corporate: { ...user?.corporate, unique_id: v } }) }} label={t('Unique Id')} required />
                    </Col>
                </Row> */}


                <Row>
                    <Col className='col-sm-6 col-12 mb-3'>
                        <InputField
                            label={t('Brand Name')}
                            placeholder="Brand Name"
                            style={{ minWidth: '150px' }}

                            value={data?.name}
                            onChange={(v) => {
                                setData({ ...data, name: v })
                            }}
                            required={true}
                        />
                    </Col>

                    <Col className='col-sm-6 col-12 mb-3'>
                        <InputField
                            label={t('Full Name')}
                            placeholder="Full Name"
                            style={{ minWidth: '150px' }}
                            value={data?.fullName}
                            onChange={(v) => {
                                setData({ ...data, fullName: v })
                            }}
                            required={true}
                        />
                        {/* <InputField value={user?.fullName} onChange={(v) => { setUser({ ...data, corporate: { ...user?.corporate, fullName: v } }) }} label={t(' Full Name')} required /> */}
                    </Col>
                </Row>
                <Row>
                    <Col className='col-sm-6 col-12 mb-3'>
                        <InputField
                            hideBorder
                            isReadOnly
                            label={t(' Unique Id')}
                            placeholder="Unique Id"
                            style={{ minWidth: '150px' }}
                            value={data?.unique_id}
                        // onChange={(v) => {
                        //     setData({ ...data, unique_id: v })
                        // }}
                        // required={true}
                        />
                    </Col>
                    <Col className='col-sm-6 col-12 mb-3'>
                        <InputField
                            hideBorder
                            isReadOnly
                            label={t('Corp Type')}
                            style={{ minWidth: '150px' }}
                            placeholder="Corp Type"
                            value={data?.corpType == 1 ? "Corporate Consumer" : "Transport Provider"}
                        // onChange={(v) => {
                        //     setData({ ...data, corpType: v })
                        // }}
                        // required={true}
                        />
                    </Col>
                </Row>
                <Row>
                    {/* <Col className='col-sm-6 col-12 mb-3'>
                        <InputField value={user?.corporate?.adminName} onChange={(v) => { setUser({ ...user, corporate: { ...user?.corporate, adminName: v } }) }} label={t('Admin Name')} required />
                    </Col>
                    <Col className='col-sm-6 col-12 mb-3'>
                        <InputField value={user?.corporate?.email} onChange={(v) => { setUser({ ...user, corporate: { ...user?.corporate, email: v } }) }} label={t('Admin Email')} required />
                    </Col> */}
                    {/* <Col className='col-sm-6 col-12 mb-3 float-lg-right'>
                        <EditLink
                            onClick={() => {
                                if (!props?.insidePane)
                                    navigate(`/company/edit/${user?._id}`);
                                setSelected_id(user?._id);
                                setShowDetailPage(true);
                            }}
                        />
                    </Col> */}
                </Row>
                <hr />
                {/* <h3 className='mt-3 mb-4' style={{ fontWeight: '500' }}>Business Unit Address</h3>
                <Row>
                    <Col className='col-sm-4 col-12 mb-3'>
                        <InputField value={user?.corporate?.address?.country} onChange={() => { }} label={t('Country')} isDisabled={true} />
                    </Col>
                    <Col className='col-sm-4 col-12 mb-3'>
                        <InputField value={user?.corporate?.address?.state} country={user?.corporate?.address?.country} label={t('State')} onChange={() => { }} isDisabled={true} />
                    </Col>
                    <Col className='col-sm-4 col-12 mb-3'>
                        <InputField value={user?.corporate?.address?.city} country={user?.corporate?.address?.country} label={t('City')} state={user?.corporate?.address?.state} onChange={() => { }} isDisabled={true} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-sm-6 col-12 mb-3'>
                        <InputField value='' onChange={() => { }} label={t('Area')} isDisabled={true} />
                    </Col>
                    <Col className='col-sm-6 col-12 mb-3'>
                        <InputField value={user?.corporate?.GSTNo} onChange={() => { }} label={t('GSTIN')} isDisabled={true} />
                    </Col>
                </Row>

                <Row>
                    <Col className='col-lg mb-2'>
                        <InputField value={user?.corporate?.address?.addressLine} onChange={() => { }} label={t('Organization Address')} isDisabled={true} />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className='col-sm-6 col-12 mt-3'>
                        <InputField value={user?.corporateConfig?.tan} onChange={(v) => { setUser({ ...user, corporateConfig: { ...user?.corporateConfig, tan: v } }) }} label={t('TAN')} />
                    </Col>
                    <Col className='col-sm-6 col-12 mt-3'>
                        <InputField value={user?.corporateConfig?.pan} onChange={(v) => { setUser({ ...user, corporateConfig: { ...user?.corporateConfig, pan: v } }) }} label={t('PAN')} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-sm-6 col-12 mt-3'>
                        <InputField value={user?.corporateConfig?.epfo} onChange={(v) => { setUser({ ...user, corporateConfig: { ...user?.corporateConfig, epfo: v } }) }} label={t('EPFO')} />
                    </Col>
                    <Col className='col-sm-6 col-12 mt-3'>
                        <InputField value={user?.corporateConfig?.esic} onChange={(v) => { setUser({ ...user, corporateConfig: { ...user?.corporateConfig, esic: v } }) }} label={t('ESIC')} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-sm-6 col-12 mt-3'>
                        <InputField value={user?.corporateConfig?.service_Tax_No} onChange={(v) => { setUser({ ...user, corporateConfig: { ...user?.corporateConfig, service_Tax_No: v } }) }} label={t('Service Tax No.')} />
                    </Col>
                </Row> */}

                {/* pictures */}
                <Row className='mt-3'>
                    {/* <Col className='col-sm-6 col-12 pe-4'> */}
                    {/* <DocUploader
                            fileType="file"
                            data={[{ name: "Company Logo", url: user?.corporate?.logo }]}
                            onRemove={(param) => {
                                setUser({
                                    ...user,
                                    corporate: {
                                        ...user?.corporate,
                                        logo: param,
                                    },
                                });
                            }}
                            uploadApi={ENDPOINTS.logoUpload}
                            fetchData={getUser}
                        /> */}

                    <FileUpload
                        type="image"
                        className="d-flex justify-content-center col-sm-6  mb-2"
                        value={data?.logo}
                        id={id}
                        module={"corporate"}
                        field={"logo"}
                        onUpload={() => {
                            fetch()
                        }}
                        onDelete={() => {
                            fetch()
                        }}
                    />

                    <FileUpload
                        type="image"
                        fileSizeText="5 to 10 KB"
                        fileSize={10}
                        className="d-flex justify-content-center col-sm-6  mb-2"
                        value={data?.favicon}
                        id={id}
                        module={"corporate"}
                        field={"favicon"}
                        onUpload={() => {
                            fetch()
                        }}
                        onDelete={() => {
                            fetch()
                        }}
                    />
                    {/* </Col> */}
                    <Col className='col-sm-6 col-12 ps-4'>
                        {/* <DocUploader
                            fileType="file"
                            data={[{ name: "Company Thumbnail", url: user?.corporate?.thumbnail }]}
                            onRemove={(param) => {
                                setUser({
                                    ...user,
                                    corporate: {
                                        ...user?.corporate,
                                        thumbnail: param,
                                    },
                                });
                            }}
                            uploadApi={ENDPOINTS.logoUpload}
                            fetchData={getUser}
                        /> */}
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col className='d-flex justify-content-end'>

                        {/* <Button onClick={(e) => { showLogs(e, true) }} className="mx-2 my-2" style={{ height: "28px", padding: "2px 4px" }} color="warning" data-toggle="tooltip" data-placement="top" title="Show Corporate  Config Audit Log ">
                            <i style={{ fontSize: "18px", marginRight: "5px", marginBottom: '5px' }} className="uil uil-database"></i>
                        </Button> */}
                        {/* <EntityButton entity={entity} id={_id} /> */}
                        {/* <Button onClick={(e) => { getAuditLogs(e, "corporate") }} className="mx-2 my-2" style={{ height: "28px", padding: "2px 4px" }} color="warning" data-toggle="tooltip" data-placement="top"
                            title="Show Corporate Audit Log">
                            <i style={{ fontSize: "18px", marginRight: "5px" }} className="uil uil-database"></i>
                        </Button>

                        <Button onClick={(e) => { getAuditLogs(e) }} className="mx-2 my-2" style={{ height: "28px", padding: "2px 4px" }} color="warning" data-toggle="tooltip" data-placement="top"
                            title="Show  Audit Log">
                            <i style={{ fontSize: "18px", marginRight: "5px" }} className="uil uil-database"></i>
                        </Button> */}
                        <SaveButton onClick={(e) => saveHandler(e)} />
                    </Col>
                </Row>

            </Form>
        </>
    )
}


//     const saveHandler = async (e) => {
//         e.preventDefault();
//         try {
//             const res = await ProfileService.saveCorpProfile(user)
//             showMessage('success', "Organization Profile Updated");
//             getUser()
//         } catch (error) {
//             showError(error)
//         }
//     }

//     const showLogs = (e, config) => {
//         e.preventDefault();
//         setShowLogsP(true);
//         setConf(config);
//     }

//     return (
//         <>
//             {showLogsP && <Modal className='w-75' onChange={() => setShowLogsP(false)}>
//                 <Logs corpId={props?.corpId} conf={conf} />
//             </Modal>}
//             <Col
//                 className="col-12 p-2 ps-4"
//                 style={{
//                     backgroundColor: "#EAEAEA",
//                     fontSize: "15px",
//                     borderBottom: "1px solid rgba(0,0,0,.1)",
//                 }}
//             >
//                 <header className="d-flex justify-content-between align-items-center">
//                     <m>{`Corporate/Organization Profile`}</m>
//                 </header>
//             </Col>
//             <Form className='p-4'>
//                 <Row>
//                     <Col className='col-sm-6 col-12 mb-3'>
//                         <InputField value={user?.corporate?.name} onChange={(v) => { setUser({ ...user, corporate: { ...user?.corporate, name: v } }) }} label={t('Organization Name(Short)')} required />
//                     </Col>
//                     <Col className='col-sm-6 col-12 mb-3'>
//                         <InputField value={user?.corporate?.fullName} onChange={(v) => { setUser({ ...user, corporate: { ...user?.corporate, fullName: v } }) }} label={t('Organization Full Name')} required />
//                     </Col>
//                 </Row>
//                 <Row>
//                     <Col className='col-sm-6 col-12 mb-3'>
//                         <InputField value={user?.corporate?.brandName} onChange={(v) => { setUser({ ...user, corporate: { ...user?.corporate, brandName: v } }) }} label={t('Brand Name')} required />
//                     </Col>
//                     <Col className='col-sm-6 col-12 mb-3'>
//                         <InputField value={user?.corporate?.unique_id} onChange={(v) => { setUser({ ...user, corporate: { ...user?.corporate, unique_id: v } }) }} label={t('Unique Id')} required />
//                     </Col>
//                 </Row>
//                 <Row>
//                     <Col className='col-sm-6 col-12 mb-3'>
//                         <InputField value={user?.corporate?.adminName} onChange={(v) => { setUser({ ...user, corporate: { ...user?.corporate, adminName: v } }) }} label={t('Admin Name')} required />
//                     </Col>
//                     <Col className='col-sm-6 col-12 mb-3'>
//                         <InputField value={user?.corporate?.email} onChange={(v) => { setUser({ ...user, corporate: { ...user?.corporate, email: v } }) }} label={t('Admin Email')} required />
//                     </Col>
//                 </Row>
//                 <hr />
//                 <h3 className='mt-3 mb-4' style={{ fontWeight: '500' }}>Business Unit Address</h3>
//                 <Row>
//                     <Col className='col-sm-4 col-12 mb-3'>
//                         <InputField value={user?.corporate?.address?.country} onChange={() => { }} label={t('Country')} isDisabled={true} />
//                     </Col>
//                     <Col className='col-sm-4 col-12 mb-3'>
//                         <InputField value={user?.corporate?.address?.state} country={user?.corporate?.address?.country} label={t('State')} onChange={() => { }} isDisabled={true} />
//                     </Col>
//                     <Col className='col-sm-4 col-12 mb-3'>
//                         <InputField value={user?.corporate?.address?.city} country={user?.corporate?.address?.country} label={t('City')} state={user?.corporate?.address?.state} onChange={() => { }} isDisabled={true} />
//                     </Col>
//                 </Row>
//                 <Row>
//                     <Col className='col-sm-6 col-12 mb-3'>
//                         <InputField value='' onChange={() => { }} label={t('Area')} isDisabled={true} />
//                     </Col>
//                     <Col className='col-sm-6 col-12 mb-3'>
//                         <InputField value={user?.corporate?.GSTNo} onChange={() => { }} label={t('GSTIN')} isDisabled={true} />
//                     </Col>
//                 </Row>

//                 <Row>
//                     <Col className='col-lg mb-2'>
//                         <InputField value={user?.corporate?.address?.addressLine} onChange={() => { }} label={t('Organization Address')} isDisabled={true} />
//                     </Col>
//                 </Row>
//                 <hr />
//                 <Row>
//                     <Col className='col-sm-6 col-12 mt-3'>
//                         <InputField value={user?.corporateConfig?.tan} onChange={(v) => { setUser({ ...user, corporateConfig: { ...user?.corporateConfig, tan: v } }) }} label={t('TAN')} />
//                     </Col>
//                     <Col className='col-sm-6 col-12 mt-3'>
//                         <InputField value={user?.corporateConfig?.pan} onChange={(v) => { setUser({ ...user, corporateConfig: { ...user?.corporateConfig, pan: v } }) }} label={t('PAN')} />
//                     </Col>
//                 </Row>
//                 <Row>
//                     <Col className='col-sm-6 col-12 mt-3'>
//                         <InputField value={user?.corporateConfig?.epfo} onChange={(v) => { setUser({ ...user, corporateConfig: { ...user?.corporateConfig, epfo: v } }) }} label={t('EPFO')} />
//                     </Col>
//                     <Col className='col-sm-6 col-12 mt-3'>
//                         <InputField value={user?.corporateConfig?.esic} onChange={(v) => { setUser({ ...user, corporateConfig: { ...user?.corporateConfig, esic: v } }) }} label={t('ESIC')} />
//                     </Col>
//                 </Row>
//                 <Row>
//                     <Col className='col-sm-6 col-12 mt-3'>
//                         <InputField value={user?.corporateConfig?.service_Tax_No} onChange={(v) => { setUser({ ...user, corporateConfig: { ...user?.corporateConfig, service_Tax_No: v } }) }} label={t('Service Tax No.')} />
//                     </Col>
//                 </Row>

//                 {/* pictures */}
//                 <Row className='mt-3'>
//                     <Col className='col-sm-6 col-12 pe-4'>
//                         <DocUploader
//                             fileType="file"
//                             data={[{ name: "Company Logo", url: user?.corporate?.logo }]}
//                             onRemove={(param) => {
//                                 setUser({
//                                     ...user,
//                                     corporate: {
//                                         ...user?.corporate,
//                                         logo: param,
//                                     },
//                                 });
//                             }}
//                             uploadApi={ENDPOINTS.logoUpload}
//                             fetchData={getUser}
//                         />
//                     </Col>
//                     <Col className='col-sm-6 col-12 ps-4'>
//                         <DocUploader
//                             fileType="file"
//                             data={[{ name: "Company Thumbnail", url: user?.corporate?.thumbnail }]}
//                             onRemove={(param) => {
//                                 setUser({
//                                     ...user,
//                                     corporate: {
//                                         ...user?.corporate,
//                                         thumbnail: param,
//                                     },
//                                 });
//                             }}
//                             uploadApi={ENDPOINTS.logoUpload}
//                             fetchData={getUser}
//                         />
//                     </Col>
//                 </Row>
//                 {/* <Row className='mt-3'>
//                     <Col className='d-flex justify-content-end'>

//                         <Button onClick={(e) => { showLogs(e, true) }} className="mx-2 my-2" style={{ height: "28px", padding: "2px 4px" }} color="warning" data-toggle="tooltip" data-placement="top" title="Show Corporate  Config Audit Log ">
//                             <i style={{ fontSize: "18px", marginRight: "5px", marginBottom: '5px' }} className="uil uil-database"></i>
//                         </Button>
//                         <Button onClick={(e) => { showLogs(e, false) }} className="mx-2 my-2" style={{ height: "28px", padding: "2px 4px" }} color="warning" data-toggle="tooltip" data-placement="top"
//                             title="Show Corporate Audit Log">
//                             <i style={{ fontSize: "18px", marginRight: "5px" }} className="uil uil-database"></i>
//                         </Button>
//                         <SaveButton onClick={(e) => saveHandler(e)} />
//                     </Col>
//                 </Row> */}
//                 <BigButton />
//             </Form>
//         </>
//     )
// }

export default OrganizationProfile