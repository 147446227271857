import React, { useContext } from 'react';
import { ModalComponent } from '../../../components';
import EmployeeAssigner from '../../Dropdowns/EmployeeSelect/EmployeeAssigner';
import { Button, Table } from 'reactstrap'; // Assuming you're using Reactstrap
import { FaCheck, FaPlus } from 'react-icons/fa'; // For the check icon
import { RxCross2 } from 'react-icons/rx'; // Assuming this is the correct import for your cross icon
import moment from 'moment';
import { generateUniqueId } from '../board/helper';
import { I18nContext } from '../../../store/context/i18nContext';

const AllRemindersModal = ({ isOpen, toggleModal, event, selectSegment, onAdd }) => {
  const { t } = useContext(I18nContext);

  return (
    <ModalComponent
      position={"top"}
      size={"medium"}
      isOpen={isOpen}
      onToggle={toggleModal}
    >
      <ModalComponent.Title>
        <div className='d-flex justify-content-between'>
          <span> {t("Reminders")}</span>

          <Button
            onClick={() => { onAdd() }}

            className=" d-flex align-items-center p-1 bg-success  me-4">
            <FaPlus size={10} className='' />
          </Button>
        </div>
      </ModalComponent.Title>
      <ModalComponent.Body>
        <div className="allRemainderFixHeaderTable">
          <Table className="table-borderless table-centered">
            <thead>
              <tr style={{ borderBottom: "1px solid #EBEBEB" }}>
                <th>Status</th>
                <th>Action</th>
                <th>Due Date</th>
                <th>Assignees</th>
                <th className="text-muted fw-semibold text-end"></th>
              </tr>
              <tr className='thead-2'>
                <th>Status</th>
                <th>Action</th>
                <th>Due Date</th>
                <th>Assignees</th>
                <th className="text-muted fw-semibold text-end"></th>
              </tr>
            </thead>
            <tbody>
              {(event).map((segment, index) => (
                <tr key={index}>
                  <td style={{
                    textAlign: "center"
                  }}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: segment?.completed ? "green" : "orange",
                        marginRight: '5px',
                      }}
                    ></div>
                  </td>

                  <td>{segment.action}</td>
                  <td><b>{segment?.actionDate ? moment(segment?.actionDate, 'YYYYMMDD').format("DD-MM-YYYY") : ""}</b></td>
                  <td>
                    <EmployeeAssigner
                      tooltipId={generateUniqueId()}
                      value={segment?.assignedTo}
                      asLabel
                    />
                  </td>
          
                  <td className="text-muted text-end">
                    <div style={{
                      cursor: "pointer"
                    }} onClick={() => { selectSegment(segment) }}>
                      <i className="uil uil-pen font-size-18" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </ModalComponent.Body>
    </ModalComponent>
  );
};

export default AllRemindersModal;
