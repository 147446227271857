import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import {
  AddressField,
  Checkbox,
  CurrencySelect,
  DateSelect,
  DateTimeSelect,
  InputField,
  PhoneNum,
  ToggleSelector
} from "../../components";
import Customfieldservice from '../../pages/CustomFields/CustomFieldService';
import DocField from "../DocUpload/DocField";
import { SelectField } from "../SelectField/SelectField";
import "./style.css";
import SELECTSTRUCTURE from "../../pages/Vehicle/VehicleConstant";
import DateTimePickerWithInterval from "../DateTimePickerWithInterval";


const CustomFieldComponent = ({
  kind,
  propList = [],
  columns = 1,
  onChange,
  module = "",
  record_id = "",
  showGroup = "",
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fields, setFields] = useState([
    {
      groupName: "Group Name",
      sections: [
        {
          sectionName: "Section Name",
          fields: [
            {
              label: "Field Label",
              value: "64a3c685fa31ce38d12ed0f1",
              displayType: 2,
              options: [
                {
                  label: "Option 1",
                  value: "option1",
                  isDefault: true,
                },
                {
                  label: "Option 2",
                  value: "option2",
                  isDefault: false,
                },
              ],
            },
            // Rest of the fields in the section...
          ],
        },
        // Rest of the sections in the group...
      ],
    },
    // Rest of the groups...
  ]);
  const [values, setValues] = useState({});


  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await Customfieldservice.fetch();
      } catch (error) {
        console.log(error.message);
      }
    };
    fetch();
  }, []);



  useEffect(() => {
    if (propList?.length) {
      let propObj = {}
      propList?.forEach((field, index) => {
        propObj[field?.field_id] = field?.value ?? ""
      });
      setValues(propObj)
    }
  }, [propList]);




  useEffect(() => {
    const filteredResponse = Customfieldservice?.records.filter(
      (row) => row.module === kind
    );

    const manipulated = filteredResponse.map((entry) => {
      let v;
      propList?.map((d) => {
        if (d.field_id === entry._id) {
          v = d.value;
        }
      });
      return { ...entry, field_id: entry?._id, value: v };
    });

    let temp = [];
    const groupedFields = manipulated?.reduce((acc, row) => {
      const { group, section } = row;
      if (!acc[group]) {
        acc[group] = {};
      }
      if (!acc[group][section]) {
        acc[group][section] = [];
      }
      const field = {
        label: row.label,
        field_id: row?.field_id,
        placeholder: row.placeholder,
        value: row.value ? row.value : "",
        displayType: row.fieldType,
        options: row.options || [],
      };
      temp.push({
        field_id: row.field_id,
        value: row.value === undefined ? "" : row.value,
      });
      acc[group][section].push(field);
      return acc;
    }, {});
    setData(temp);
    const mappedFields = Object.entries(groupedFields).map(
      ([groupName, sections]) => {
        const mappedSections = Object.entries(sections).map(
          ([sectionName, fields]) => ({
            sectionName: sectionName ?? "NoGroup",
            fields,
          })
        );

        return {
          groupName: groupName ?? "NoGroup",
          sections: mappedSections,
        };
      }
    );
    let flagData = mappedFields;
    setFields(mappedFields);
    let flag = 0;
    if (showGroup.length > 0) {
      setFields(
        mappedFields?.map((group) => {
          if (group?.groupName === showGroup) {
            flag = 1;
            return group;
          }
        })
      );
    }

    flag === 0 ? setFields(flagData) : console.log("nothing");
  }, [Customfieldservice.records]);



  return (
    <>
      {!loading && (
        <div className="row">
          {fields.map((group, index) =>
            group?.groupName || group?.groupName == "" ? (
              <div className={`col-12 col-lg-${12 / columns} `}>
                <Card className="p-3 mb-2 border " key={group?.groupName}>
                  {group?.groupName && group?.groupName != "undefined" ? <strong>
                    <h2 className="">{group?.groupName}</h2>
                  </strong> : null}
                  {group?.sections.map((section, index) => (
                    <div
                      key={section?.sectionName ?? Date?.now()}
                      className={section?.sectionName && section?.sectionName != "undefined" ? "mb-3 section-border" : ""}
                    >
                      {section?.sectionName && section?.sectionName != "undefined" ? <div className="section-title">
                        <div className="mb-5 ">{section?.sectionName}</div>
                      </div> : null}

                      {section?.fields.map((field) => (
                        <div key={field.field_id} className="">
                          {field.displayType === "text" && (
                            <>
                              <InputField
                                value={values[field?.field_id]}
                                onChange={
                                  (v) => {
                                    const temp = data?.map((d) => {
                                      if (d?.field_id === field?.field_id) {
                                        return {
                                          field_id: field?.field_id,
                                          value: v,
                                        };
                                      } else {
                                        return d;
                                      }
                                    });
                                    setData(temp);
                                    if (onChange) onChange(temp);
                                  }
                                  // setData([...data, { field_id: "", value: v }])
                                }
                                label={field?.label}
                                placeholder={field?.placeholder}
                                type="text"
                                removeBottomMargin
                              />
                            </>

                          )}
                          {field.displayType === "dropdown" && (
                            <SelectField
                              // innerContainerStyle={props?.innerContainerStyle}
                              // className={props?.className}
                              data={field.options}
                              value={(values[field?.field_id] ?? "")}
                              label={field?.label}
                              onChange={
                                (v) => {
                                  const temp = data?.map((d) => {
                                    if (d?.field_id === field?.field_id) {
                                      return {
                                        field_id: field?.field_id,
                                        value: toJS(v)?.value,
                                      };
                                    } else {
                                      return d;
                                    }
                                  });
                                  if (temp?.length) {
                                    setData(temp);
                                    if (onChange) onChange(temp);
                                  }

                                }
                              }
                            // wrapperStyle={props?.wrapperStyle}
                            // containerStyle={props?.containerStyle}
                            // error={props?.value ? "Please Select Salutation" : ""}
                            // showErr={props?.showErr}
                            // inline={props?.inline}
                            // borderNone={props?.borderNone}
                            // RightComponent={props?.RightComponent}
                            // onClickRight={props?.onClickRight}
                            />
                          )}

                          {field.displayType === "checkbox" && (
                            <>
                              <Checkbox
                                label={field?.label}
                                checked={JSON.parse(values[field?.field_id] ? `${values[field?.field_id]}` : "false")}
                                onClick={(v) => {
                                  const temp = data?.map((d) => {
                                    if (d?.field_id === field?.field_id) {
                                      return {
                                        field_id: field?.field_id,
                                        value: v,
                                      };
                                    } else {
                                      return d;
                                    }
                                  });
                                  // updateValues({ fieldId: field?.field_id, value  : v})
                                  setData(temp);
                                  if (onChange) onChange(temp);
                                }}
                                style={{ minHeight: "36px" }}
                              />
                            </>

                          )}

                          {field.displayType === "toggle" && (
                            <ToggleSelector
                              style={{ fontSize: '12px', maxWidth: "100%", display: "flex", flex: "wrap", }}
                              data={field.options}
                              value={(values[field?.field_id] ?? "")}

                              onChange={
                                (v) => {
                                  const temp = data?.map((d) => {
                                    if (d?.field_id === field?.field_id) {
                                      return {
                                        field_id: field?.field_id,
                                        value: v,
                                      };
                                    } else {
                                      return d;
                                    }
                                  });
                                  setData(temp);
                                  if (onChange) onChange(temp);
                                }
                              }
                            />

                          )}
                          {field.displayType === 5 && (
                            <DateSelect
                              value={(values[field?.field_id] ?? "")}
                              label={field?.label}
                              onChange={
                                (v) => {
                                  const temp = data?.map((d) => {
                                    if (d?.field_id === field?.field_id) {
                                      return {
                                        field_id: field?.field_id,
                                        value: v,
                                      };
                                    } else {
                                      return d;
                                    }
                                  });
                                  setData(temp);
                                  if (onChange) onChange(temp);
                                }
                              }
                            />
                          )}

                          {field.displayType === 6 && (
                            <DateTimePickerWithInterval
                              timestamp
                              label={field?.label}
                              value={(values[field?.field_id] ?? "")}
                              onChange={
                                (v) => {
                                  const temp = data?.map((d) => {
                                    if (d?.field_id === field?.field_id) {
                                      return {
                                        field_id: field?.field_id,
                                        value: v,
                                      };
                                    } else {
                                      return d;
                                    }
                                  });
                                  setData(temp);
                                  if (onChange) onChange(temp);
                                }
                              }
                            />
                          )}
                          {field.displayType === 7 && (
                            <div>
                              <SelectField
                                required={true}
                                data={SELECTSTRUCTURE.years}
                                value={(values[field?.field_id] ?? "")}
                                onChange={
                                  (v) => {
                                    const temp = data?.map((d) => {
                                      if (d?.field_id === field?.field_id) {
                                        return {
                                          field_id: field?.field_id,
                                          value: toJS(v)?.value,
                                        };
                                      } else {
                                        return d;
                                      }
                                    });
                                    if (temp?.length) {
                                      setData(temp);
                                      if (onChange) onChange(temp);
                                    }

                                  }
                                }
                                label={field?.label ?? 'Year'}
                                placeholder={field?.placeholder}

                              />

                              {/* <InputField
                              onChange={
                                (v) => {
                                  console.log(v)
                                }
                              }
                              label={field?.label}
                              placeholder={field?.placeholder}
                              type="week"
                              showErr={true}
                            /> */}
                            </div>
                          )}
                          {field.displayType === 8 && (
                            <InputField
                              value={values[field?.field_id]}
                              onChange={
                                (v) => {
                                  const temp = data?.map((d) => {
                                    if (d?.field_id === field?.field_id) {
                                      return {
                                        field_id: field?.field_id,
                                        value: v,
                                      };
                                    } else {
                                      return d;
                                    }
                                  });
                                  setData(temp);
                                  if (onChange) onChange(temp);
                                }
                              }
                              label={field?.label ?? 'Month'}
                              placeholder={field?.placeholder}
                              type="month"
                            />
                          )}

                          {field.displayType === 10 && (
                            <InputField
                              value={values[field?.field_id]}
                              onChange={
                                (v) => {
                                  const temp = data?.map((d) => {
                                    if (d?.field_id === field?.field_id) {
                                      return {
                                        field_id: field?.field_id,
                                        value: v,
                                      };
                                    } else {
                                      return d;
                                    }
                                  });
                                  setData(temp);
                                  if (onChange) onChange(temp);
                                }
                              }
                              label={field?.label}
                              placeholder={field?.placeholder}
                              type="number"
                              showErr={true}
                            />
                          )}

                          {field.displayType === 12 && (
                            <CurrencySelect
                              data={field.options}
                              value={(values[field?.field_id] ?? "")}
                              label={field?.label}
                              onChange={
                                (v) => {
                                  const temp = data?.map((d) => {
                                    if (d?.field_id === field?.field_id) {
                                      return {
                                        field_id: field?.field_id,
                                        value: toJS(v)?.value,
                                      };
                                    } else {
                                      return d;
                                    }
                                  });
                                  if (temp?.length) {
                                    setData(temp);
                                    if (onChange) onChange(temp);
                                  }

                                }
                              }
                            />
                          )}

                          {field.displayType === 13 && (
                            <div>
                              <PhoneNum
                                // value={ typeof(values[field?.field_id])  == "object" ?  values[field?.field_id] : JSON.parse(values[field?.field_id] ?? "{}")}
                                value={JSON.parse(values[field?.field_id] ? `${values[field?.field_id]}` : "{}")}

                                onChange={
                                  (v) => {
                                    const temp = data?.map((d) => {
                                      if (d?.field_id === field?.field_id) {
                                        return {
                                          field_id: field?.field_id,
                                          value: JSON.stringify({
                                            phone: v?.phone,
                                            countryCode: v?.countryCode,
                                          }),
                                        };
                                      } else {
                                        return d;
                                      }
                                    });
                                    setData(temp);
                                    if (onChange) onChange(temp);
                                  }
                                }
                                label={field?.label}
                                placeholder={field?.placeholder}
                                type="number"

                              />
                            </div>
                          )}

                          {field.displayType === 14 && (
                            <InputField
                              placeholder={"Email"}
                              label={field?.label}
                              type="email"
                              showErr={true}
                              value={values[field?.field_id]}
                              onChange={
                                (v) => {
                                  const temp = data?.map((d) => {
                                    if (d?.field_id === field?.field_id) {
                                      return {
                                        field_id: field?.field_id,
                                        value: v,
                                      };
                                    } else {
                                      return d;
                                    }
                                  });
                                  setData(temp);
                                  if (onChange) onChange(temp);
                                }
                              }
                            />
                          )}
                          {/* {JSON.stringify(values)} */}

                          {field.displayType === 15 && (
                            <AddressField
                              value={JSON.parse(values[field?.field_id] ? `${values[field?.field_id]}` : "{}")}
                              onChange={
                                (v) => {
                                  const temp = data?.map((d) => {
                                    if (d?.field_id === field?.field_id) {
                                      return {
                                        field_id: field?.field_id,
                                        value: JSON.stringify(v),
                                      };
                                    } else {
                                      return d;
                                    }
                                  });
                                  setData(temp);
                                  if (onChange) onChange(temp);
                                }
                              }
                              label={field?.label ?? 'Address'}
                              placeholder={field?.placeholder}
                            />
                          )}
                          {field.displayType === 16 && (
                            <DocField
                              config={{
                                showCategory: false,
                                showExpiry: false,
                              }}
                              record_id={record_id}
                              field_id={field?.field_id}
                              value={(values[field?.field_id] ?? "")}
                              // value={field?.value}
                              module={module}
                              onChange={(v) => {
                                const temp = data?.map((d) => {
                                  if (d?.field_id === field?.field_id) {
                                    return {
                                      field_id: field?.field_id,
                                      value: v,
                                    };
                                  } else {
                                    return d;
                                  }
                                });
                                setData(temp);
                                if (onChange) onChange(temp);
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </Card>
              </div>
            ) : (
              <></>
            )
          )}
        </div>
      )}
    </>
  );
};

export default observer(CustomFieldComponent);
