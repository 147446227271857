import React from 'react'
import { marketplaceRegisterIcon } from '../../../assets/images';


const MarketPlaceregisterComponet = ({ heading, data }) => {
    return (
        <div className='d-flex align-items-center'>
            <div className=" d-flex justify-content-center align-items-center" style={{ background: "#F08180", boxSizing: "border-box", width: "35px", height: "35px", borderRadius: "100px", marginRight: "10px" }} >
                <img width="25px" height="25px" src={marketplaceRegisterIcon} alt="" color='FFFFFF' style={{
                    color: "FFFFFF"
                }} />
            </div>
            <div style={{
                flex: 1,
                fontStyle: "Urbanist",
                color: "#676D6B"
            }} className='mt-2'><span className="fw-bold fa-1x mb-2">{heading}</span>  {data} </div>
        </div>
    )
}

export default MarketPlaceregisterComponet