import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu, Alert } from "reactstrap";
import { Button } from 'react-bootstrap'
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditPayment_Gateways from "./EditPayment_Gateway";
import { STRUCTURE } from "./Payment_GatewayConstant";
import { GridColumns } from "./Payment_GatewayGridColumns";
import Payment_GatewayService from "./Payment_GatewayService";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";


const Payment_Gateways = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/payment-gateway-account/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      const res = await Payment_GatewayService.fetch(filterUrl);
      setLoading(false);
      console.log(res)
    } catch (e) {
      setLoading(false);

      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])


  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    e.preventDefault();
    // if (!detailData?.payment_Gateway) {
    //   setHasErr(true)
    //   showError(t("Please enter all required fields"));
    //   return;
    // }
    setLoading(true);
    try {
      if (editID) {
        await Payment_GatewayService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Record updated successfully."));
      } else {
        const payment_Gateway = await Payment_GatewayService.save(detailData);
        showMessage(t("Record saved successfully."));
        setLoading(false);
        if (!insidePane) navigate(`/payment-gateway-account/edit/${payment_Gateway}`);
      }
      fetchData(filterURL);

    } catch (e) {
      showError(e);
      setLoading(false);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await Payment_GatewayService.delete(id);
        setLoading(false);
        showMessage("Deleted", "Deleted");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);

      try {
        await Payment_GatewayService.bulkDelete(selectedIDs);
        setLoading(false);
        showMessage("Deleted", "Deleted Successfully");
        navigate('/payment-gateway-account')
        setShowDetailPage(false);
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback((payment_Gateway) => {
    return (
      <>
        {userContent?.rights?.includes(4102) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/payment-gateway-account/edit/${payment_Gateway?._id}`);
              setEditID(payment_Gateway?._id);
              setShowDetailPage(true);

            }}
          />
        }
        {userContent?.rights?.includes(4103) &&
          <DeleteLink
            onClick={(event) => onDelete(event, payment_Gateway?._id)}
          />}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(payment_Gateway?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])

  const markCompletedFunc = async () => {
    try {
      await SetupProgressService?.edit("paymentGateway");
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch()
    }
  }
    , [SetupProgressService?.records])
  return (
    <React.Fragment>
      <>
        <Layout
          hideAdd={!userContent?.rights?.includes(4101)}
          medium
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/payment-gateway-account");
            setEditID(null);
          }}
           
          title={t("Payment Gateway")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/payment-gateway-account/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={Payment_GatewayService.page}
          rows={Payment_GatewayService.rows}
          total={Payment_GatewayService.total}
          fetch={Payment_GatewayService.fetch}
        >

          <Layout.OnAlert>
            {!SetupProgressService?.records?.setUpStatus?.['paymentGateway'] ? <Alert color="danger" role="alert">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  Set up your payment gateway. Provide your bank details here. ( Missing Payment gateway charges section)
                </div>
                <div>
                  <Button onClick={markCompletedFunc}>Mark Complete</Button>
                </div>
              </div>          </Alert> : <></>}


            <Alert color="success" role="alert">
              <div className="d-flex flex-column justify-content-start align-items-start font-size-14">
                <div style={{ fontWeight: 600 }}>
                  Payment Gateway Charges:
                </div>
                <div>
                  &#x2022; Diners, Amex Cards, International Cards, Corporate (Business) Credit Cards will be charged at 4%
                </div>
                <div>
                  &#x2022; Rest all Debit Credit cards, UPI, Net Banking, Wallets will be charged at 3%
                </div>
                <div>
                  * All payments will be settled on the same day in your bank account
                </div>
              </div>
            </Alert>

          </Layout.OnAlert>
          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem>{t("Upload")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem> Download </DropdownItem>
                </>

              </DropdownMenu>

            </div>
          </Layout.ActionMenu>
          <Layout.Table>
            <DataGrid
              gridLoading={loading}
              data={Payment_GatewayService.records}
              total={Payment_GatewayService.total}
              uiPreference="payment_Gateway.grid"
              headers={GridColumns}
              onSelectChange={(v) => {
                onSelect(v)
                setSelectedIDs(v)
              }}
              selectedIDs={selectedIDs}
              page={Payment_GatewayService.page}
              rowsPerPage={Payment_GatewayService.rowsPerPage}
              onPaginationChange={Payment_GatewayService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </Layout.Table>


          <Layout.DetailPageTitle>
            {detailData?._id ? t("Edit Payment Gateway") : t("Add Payment Gateway")}
          </Layout.DetailPageTitle>

          <Layout.DetailPageBody>
            <EditPayment_Gateways
              editId={editID}
              onChange={(v) => {
                setDetailData(v)
              }}
              hasErr={hasErr}
            />
          </Layout.DetailPageBody>

          <Layout.DetailPageFooter>
            {editID ? (
              <AuditLogDialog module="PaymentGatewayAccount" id={detailData?._id} />
            ) : null

            }
            {editID && userContent?.rights?.includes(4103) ? <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} /> : null}
            {userContent?.rights?.includes(4102) && <SaveButton onClick={onSave} loading={loading} />}
          </Layout.DetailPageFooter>
        </Layout>
      </>

    </React.Fragment>
  );
};
export default observer(Payment_Gateways);
