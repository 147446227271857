
export const initialData = [
    {
        version: 0,
        _id: "6675510d995e47691298e98c1",
        title: "10_P_Gen_DLF_-Gurgaon", 
        src: {
            addressLine: "Sector 58",
            city: "Gurgaon",
            state: "Haryana",
            country: "India",
            lat: 28.4300136,
            lon: 77.1205434
        },
        dst: {
            addressLine: "DLF Epitome, DLF Phase III",
            city: "Gurgaon",
            state: "Haryana",
            country: "India",
            lat: 28.489891100000005,
            lon: 77.09227680000004
        },
        startDate: 20240601,
        endDate: 20240630,
        startAt: 905,
        stopAt: 1005,
        status: 0,
        journeyStatus: 0,
        passengers: [
            {
                _id: "5d7f83e11e48ea1921c9c0b4",
                name: "ANURAG SHRIVASTAV",
                countryCode: 91,
                phone: 1892346638,
                salutation: 'Mrs',
                email: "testt111@gmail.com122",
                address: {
                    addressLine: "Sector 58",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.4200136,
                    lon: 77.1105434
                },
                gender: 1,
                emp_no: "FT122",
                employeeOf: "5d7f5e0ba30653739b3d9af9",
                employee_id: "5d807f04d5cf0d6ff2b33fca",
                pickupTime: 9.05,
                dropTime: 9.05,
                pickup: {
                    addressLine: "Sector 58",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.4200136,
                    lon: 77.1105434
                },
                drop: {
                    addressLine: "DLF Epitome, DLF Phase III",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.489891100000005,
                    lon: 77.09227680000004
                },
                status: 0,
                distance: 7.972593798146868
            },
            {
                _id: "5d7f83df1e48ea1921c9c095",
                name: "VINAY SHARMA",
                countryCode: 91,
                phone: 1892346635,
                email: "testt111@gmail.com119",
                address: {
                    addressLine: "Sector 55",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.4281173,
                    lon: 77.1100123
                },
                gender: 1,
                emp_no: "FT119",
                employeeOf: "5d7f5e0ba30653739b3d9af9",
                employee_id: "5d807f04d5cf0d6ff2b33fc2",
                pickupTime: 9.13,
                dropTime: 9.13,
                pickup: {
                    addressLine: "Sector 55",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.4281173,
                    lon: 77.1100123
                },
                drop: {
                    addressLine: "DLF Epitome, DLF Phase III",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.489891100000005,
                    lon: 77.09227680000004
                },
                status: 0,
                distance: 7.084367019487279
            },
            {
                _id: "5d7f83df1e48ea1921c9c086",
                name: "VINAY KUMAR",
                countryCode: 91,
                phone: 1892346634,
                email: "testt111@gmail.com118",
                address: {
                    addressLine: "Sector 54",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.4348171,
                    lon: 77.10894119999999
                },
                gender: 0,
                emp_no: "FT118",
                employeeOf: "5d7f5e0ba30653739b3d9af9",
                employee_id: "5d807f04d5cf0d6ff2b33fbe",
                pickupTime: 9.25,
                dropTime: 9.25,
                pickup: {
                    addressLine: "Sector 54",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.4348171,
                    lon: 77.10894119999999
                },
                drop: {
                    addressLine: "DLF Epitome, DLF Phase III",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.489891100000005,
                    lon: 77.09227680000004
                },
                status: 0,
                distance: 6.336914146602593
            }
        ],
        distance: 17,
        duration: 34,
        returning: false,
        polyline: "gxmlD}ssuMeACWE_@QIICGOIg@YuAu@L{AK_@[y@ES@[Hk@BOc@Ms@g@gD}@_CUqAIw@IsEoAuAWm@GBVHXBb@lApAp@t@MPiCuCy@BuAEeAQ}@Ig@AUBKFMNAVJz@DR_@Lk@NKTD`BMbICjC??@|ECbH?|G?jAFPJX?n@eLKkA@g@Fo@PYPIFOFQ@OAQGKGEISG[ES?w@LeCh@gF`AmBb@mGhAeHzAcCn@eEfA}DfAyBp@{Bn@{C|@gCt@}Bv@eBb@wAXyHdBeEt@mBl@eFjA}A`@QMIUEU@QFSdCq@~Cw@|@U|IuAdDm@tB[t@IbBa@jDgAvFwA|Ae@lDgApCu@zBk@|@UrFcAdFcAC[[cDJ_@~@kB^}@BUC[WiBu@yFQqAJG??HA?D^rC|@`H@ZCXYn@iAzBKZd@~CFXpAQfCc@pFgAPI\\G~@_@h@]DEHGPGTATFTNPX@TCXIVKPIFOFQ@OAQGKGEISG[ES?w@LeCh@gF`AmBb@mGhAeHzAcCn@eEfA}DfAyBp@{Bn@{C|@gCt@}Bv@eBb@wAX{A\\YCeC^{Dr@oE|@_E~@cEbA}A^k@RoD`AgBd@}Bf@aBZwA^qDt@yEz@oBZ_HpA{FhAi@D}FnA_LxBkNlBo[lD_Mp@s@@gA@cBHk@?uE@cID}CH]Oq@@{IDoC@kAKmDq@{IeBkDq@{Fe@_H]i@De@He@N]Pa@^i@j@oBvDeBnDgEhIy@`By@rAWXMJa@Tk@Fu@C}@UcAa@gBw@cCgAeF{B{CwAaHqC[EWAg@Jc@VQXuCpFmBtDy@~AWRu@l@gAv@g@NSLMFkAaAsBeBCa@@SHMLG\\ExDdDJBZGXQ`Au@`@c@h@y@h@gAhAaClBoDt@iAf@c@ZO^Id@?x@PnIrDjEjBrDbBjEjBJKT?J?FAn@Hl@AVGHWb@s@jA_CwAcAyCgBiBgAC?G?COBGDAD?DB?BbCxA"
    },
    {
        version: 0,
        _id: "6675510d995e47691298e98c",
        title: "11_P_Gen_DLF_-Gurgaon",
        corp_id: "5d7f5e0ba30653739b3d9af9",
        shift_id: "5d7f5e0ba30653739b3d9afb",
        dstCampus_id: "5d7f608aa30653739b3db096",
        src: {
            addressLine: "Sector 58",
            city: "Gurgaon",
            state: "Haryana",
            country: "India",
            lat: 28.4200136,
            lon: 77.1105434
        },
        dst: {
            addressLine: "DLF Epitome, DLF Phase III",
            city: "Gurgaon",
            state: "Haryana",
            country: "India",
            lat: 28.489891100000005,
            lon: 77.09227680000004
        },
        startDate: 20240601,
        endDate: 20240630,
        startAt: 905,
        stopAt: 1005,
        status: 1,
        journeyStatus: 0,
        passengers: [
            {
                _id: "5d7f83e11e48ea1921c9c0b1",
                name: "ANURAG SHRIVASTAV",
                countryCode: 91,
                phone: 1892346638,
                email: "testt111@gmail.com122",
                address: {
                    addressLine: "Sector 58",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.4200136,
                    lon: 77.1105434
                },
                gender: 1,
                emp_no: "FT122",
                employeeOf: "5d7f5e0ba30653739b3d9af9",
                employee_id: "5d807f04d5cf0d6ff2b33fca",
                pickupTime: 9.05,
                dropTime: 9.05,
                pickup: {
                    addressLine: "Sector 58",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.4200136,
                    lon: 77.1105434
                },
                drop: {
                    addressLine: "DLF Epitome, DLF Phase III",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.489891100000005,
                    lon: 77.09227680000004
                },
                status: 0,
                distance: 7.972593798146868
            },
            {
                _id: "5d7f83df1e48ea1921c9c092",
                name: "VINAY SHARMA",
                countryCode: 91,
                phone: 1892346635,
                email: "testt111@gmail.com119",
                address: {
                    addressLine: "Sector 55",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.4281173,
                    lon: 77.1100123
                },
                gender: 1,
                emp_no: "FT119",
                employeeOf: "5d7f5e0ba30653739b3d9af9",
                employee_id: "5d807f04d5cf0d6ff2b33fc2",
                pickupTime: 9.13,
                dropTime: 9.13,
                pickup: {
                    addressLine: "Sector 55",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.4281173,
                    lon: 77.1100123
                },
                drop: {
                    addressLine: "DLF Epitome, DLF Phase III",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.489891100000005,
                    lon: 77.09227680000004
                },
                status: 0,
                distance: 7.084367019487279
            },
            {
                _id: "5d7f83df1e48ea1921c9c083",
                name: "VINAY KUMAR",
                countryCode: 91,
                phone: 1892346634,
                email: "testt111@gmail.com118",
                address: {
                    addressLine: "Sector 54",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.4348171,
                    lon: 77.10894119999999
                },
                gender: 0,
                emp_no: "FT118",
                employeeOf: "5d7f5e0ba30653739b3d9af9",
                employee_id: "5d807f04d5cf0d6ff2b33fbe",
                pickupTime: 9.25,
                dropTime: 9.25,
                pickup: {
                    addressLine: "Sector 54",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.4348171,
                    lon: 77.10894119999999
                },
                drop: {
                    addressLine: "DLF Epitome, DLF Phase III",
                    city: "Gurgaon",
                    state: "Haryana",
                    country: "India",
                    lat: 28.489891100000005,
                    lon: 77.09227680000004
                },
                status: 0,
                distance: 6.336914146602593
            }
        ],
        distance: 17,
        duration: 34,
        returning: false,
        polyline: 
"i}cmDk`bwMlAbA`AqBJMNI|CWtMkAc@_IEKMQoHaHsDaDmAw@gCoAsAm@a@_@OMEGCEYBO@KHQ\\Kz@AV?j@KVgAzBw@tAmDjG_F`JsJtQqI~OiB`Dr@f@Vg@x@n@??y@o@Wf@s@g@}@hBiBrDuD|G}AlCgFpIMNQLQ@YGAAIC[L_EvFqDhEmArAmIbJU`@CLHJdK~MVZQR{C`Em@x@??mDzEcBwBWEcD_DcE{DaJ~LkBdCWLCJIHMF]CU[COg@UUGUXgArAo@p@qExFwAzBeDlE}@pA]l@sChGuDhJsBlEa@h@sAvAaB~@{EbBiIhF}ClBiLzHaMnIgHvEINs@v@m@f@g@j@Yj@Q~@UzD]pCu@bDq@hBc@z@aAvA]TqBrBiDlCmAz@sBnBaBdAmErBsCdBaCrByAlBs@jAeA`Cw@dCm@rDUjD@tDXhDT|AvAjFhE~MpHbUfClIbAbE^|BTfDFnBA~AUvE[`C_@jBaAhDo@~Aw@zAkCzDsO`R_AtAs@zAy@vC[hDDtDTlBf@pBhAbCj@~@xEfG|IlK~@rAzBxD`FnJpEbIvBbEtGvKnB`DzChEd@`Ap@jB`AhB~AjBjAt@lAd@lGbC`DjAzAbA|@nAv@fBVrAL`ARjEHzB@|@`@~L`AvXVnHB|B{@fTy@lSe@vMSlCYxBg@tBoB~FyExOkA|Da@`BKv@g@dFMxCEpIMfGS~KEnEUhGIjFMnKi@xQ}@rWg@jSEvDHnDHzDdA|WLhIEvF]xH{@nNi@zMa@nGo@tCWjAiB~DgBfDsBvCcAlAaA~@cC~AoAt@cDnAaB~@gCfAmRdJmLzFsQzIsG|CwBbAoHrEoJjFoGzDoChBOH?HCLq@l@cEhCuAZm@Fo@f@Yh@Ij@?h@d@bCnEfMdFrOrC`JdAfElBlHtDrL~BdI~AfFdDhKz@fBdAfBlB|BlAnA|@v@d@Zf@TvXxE~IfBlAXdCl@zErAfBj@x@`@NJdAx@d@b@bA|Av@hBv@zB|BxHrC~GlFnK~ApC`AlAf@j@jA|@dAf@h@NbAPbAJjBHnHJ~HTza@jAdCZdCb@|MpChNnDlCv@rAn@hAt@l@h@dDdEzExG~LvPbFdHzCrEdBzDt@|BtCnHdArBzAbBz@j@dAd@`Ct@fSnH`CdAtA|@j@h@fAtA|A`DpUzj@dC`Gx@tAr@x@hBxAnF`D|YpQhTfM~AdApKrGtKbG~EpC~FfDnAt@v@XtBhAl@ZRL`BXpAHbD@fCSdD}@jEgBdBu@jA_@d@MpAqAvC_@pBMhBEnBNdD|@fA\\t@j@dBvAxEpD`KlIjGvFfFhEJJN?VIvAeAp@u@l@gAhCqF`BuCj@q@ZUf@OXCr@H`HrC|K~EhElBr@RBERCT?jAHl@IZo@z@{A`@_Ay@m@y@m@{DyBm@]C?IK@ILCDHbCvA"
    },
    // Add more objects here if needed
];
  
 export const MAP_VIEW={
    ALL:0,
    PASSSENGER_VIEW:1,
    TRIP_VIEW:2
 } 

 export const POLYLINE_COLOR={
    selected:'#A020F0',
    default:'rgba(43,44,170,0.5)'
 }

 export const ENDPOINTS = {
    save: `/api/trip-maker/plan`, 

 }   

 export const ROSTER_TRIP_TYPE={
    2:22,
    3:23,
    4:25,
    5:26,
 }

 export const initialRoster={
    radius: 0,
    returning: false,
    clusterSizes: [{
        size: 0,
        cars: 0,
        filled: 0
    }],
    deviationMeters: 0,
    deviationPercent: 0,
    withOnWay: true
};