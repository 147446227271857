import React, { useEffect, useState, useContext } from "react";
import SettingsService from "../SettingsService";
import { DialogContext } from "../../../store/context/DialogContext";
import { Col, Form, Row } from "reactstrap";
import {

  SaveButton,
  Checkbox,

  SelectFieldMultiple,
} from "../../../components";

const MyVehicleTypes = () => {
  const [loading, setLoading] = useState(false);
  const { showMessage, showError } = useContext(DialogContext);
  const [data, setData] = useState("");
  const [vehicleMap, setVehicleMap] = useState([]);
  const [vehicleCat, setVehicleCat] = useState([]);
  const [hasErr, setHasErr] = useState(false);

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await SettingsService.getConfig();
      setData(res);
      setHasErr(false);
    } catch (e) {
      showError(e);
      // console.log(e);
    }
    setLoading(false);
  };
  const fetchVehicle = async () => {
    if (loading) return;
    setLoading(true);
    setVehicleMap(await SettingsService.getVehicleMap());
    setLoading(false);
  };
  const fetchVehicleCaT = async () => {
    const resp = await fetch("/api/vtgroup/vehicle/category/map");
    const data = await resp.json();
    setVehicleCat(data);
  };
  useEffect(() => {
    fetchData();
    fetchVehicle();
    fetchVehicleCaT();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await SettingsService.saveVehicleType(data);
      showMessage("Vehicle Type Saved successfully");
      fetchData();
      setLoading(false);
    } catch (error) {
      showError(error);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col
            className="col-12 p-2 ps-4"
            style={{
              backgroundColor: "#EAEAEA",
              fontSize: "15px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <header>Enable Vehicle Types</header>
          </Col>

          {/* <Col className="col-md-6 p-3 ps-4">
            {vehicleMap.map((ele, id) => (
              <Checkbox label={ele.name}></Checkbox>
            ))}
          </Col> */}
          <Col className="p-2 mt-2 mb-2">
            <SelectFieldMultiple
              multi
              required={true}
              data={vehicleMap}
              value={data?.vehicleTypes}
              onChange={(v) => setData({ ...data, vehicleTypes: v?.value })}
              showErr={hasErr}
              label={"Vehicle Type"}
            />
          </Col>
          <Col
            className="col-12 p-2 ps-4"
            style={{
              backgroundColor: "#EAEAEA",
              fontSize: "15px",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <header>Enable Vehicle categories</header>
          </Col>
          <Col className="col-md-6 p-3 ps-4">
            {vehicleCat?.map((ele, id) => {
              return (
                <Checkbox
                  key={id}
                  className="col-lg"
                  label={ele.name}
                  checked={data?.vehicleCategories?.includes(ele._id)}
                  onClick={() => {
                    console.log(ele._id);
                    setData({
                      ...data,
                      vehicleCategories: [...data?.vehicleCategories, ele.id],
                    });
                  }}
                />
              );
            })}
          </Col>
          <footer className="col-12">
            <SaveButton />
          </footer>
        </Row>
      </Form>
    </div>
  );
};

export default MyVehicleTypes;
