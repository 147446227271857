import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FaCog, FaEllipsisV } from 'react-icons/fa';
import PropTypes from 'prop-types';
import "./style.css"

const ActionMenu = ({ data, toggleLabel, toggleIcon, toggleColor, menuPosition, className }) => {
    return (
        <UncontrolledDropdown className={className}>
            <DropdownToggle
                caret
                color={toggleColor}
                className="action-menu-toggle"
            >
                {toggleIcon ? toggleIcon : <FaCog size = {16} color = {"#000"}/>}
                {toggleLabel}
            </DropdownToggle>
            <DropdownMenu right={menuPosition === 'left'} className="action-menu-dropdown">
                {data.map((item, index) => (
                    <DropdownItem key={index} onClick={item.onClick}>
                        {item.icon && <span className="me-2">{item.icon}</span>}
                        {item.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};


ActionMenu.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            icon: PropTypes.element,
            onClick: PropTypes.func.isRequired,
        })
    ).isRequired,
    toggleLabel: PropTypes.string,
    toggleIcon: PropTypes.element,
    toggleColor: PropTypes.string,
    menuPosition: PropTypes.oneOf(['left', 'right']),
    className: PropTypes.string,
};

ActionMenu.defaultProps = {
    toggleLabel: '',
    toggleIcon: null,
    toggleColor: 'primary',
    menuPosition: 'left',
    className: '',
};

export default ActionMenu;




// .my-custom-class .dropdown-toggle {
//   background-color: #28a745; /* Custom green color */
//   border-color: #28a745;
// }

// .my-custom-class .dropdown-item {
//   display: flex;
//   align-items: center;
// }

// .my-custom-class .dropdown-item span {
//   margin-right: 8px;
// }


// =============================================
// const MyComponent = () => {
//   return (
//     <div>
//       <ActionMenu
//         data={[
//           { label: 'Change Space', icon: <FaExchangeAlt />, onClick: handleChangeSpace },
//           { label: 'Edit', icon: <FaEdit />, onClick: handleEdit },
//           { label: 'Delete', icon: <FaTrashAlt />, onClick: handleDelete },
//         ]}
//         toggleLabel=""
//         toggleIcon={<FaEllipsisV />}
//         toggleColor="link"
//         menuPosition="right"
//         className="my-custom-class"
//       />
//     </div>
//   );
// };
