
import React from 'react';
import { AmountField, TaxSelect } from '../../../components';
import DiscountRow from './DiscountRow';
import FeeCharges from './FeeCharges';
import TaxItems from './TaxItems';
import FeeTotal from './FeeTotal';

function FeeGroupAsLabel({ feeGroup, rateNames }) {
  return (
    <table className="">
      <thead>
        <tr style={{ /* lineHeight: "0.8" */ }}>
          <th style={{ width: "20%" }}>Charge Type</th>
          <th>Unit Cost</th>
          <th>No. of Units</th>
          <th className="text-end">Amount</th>
        </tr>
      </thead>
      
      <tbody className="">
          <FeeCharges  charges={feeGroup?.charges} rateNames={rateNames}/>
      </tbody>

      <tfoot>
         <DiscountRow
            subTotal={feeGroup?.subTotal}
            discounts={feeGroup?.discounts}
           />
        {feeGroup?.taxItem?.tax_id && (
          <tr style={{ /* lineHeight: "0.5" */ }}>
            <td></td>
            <td></td>
            <td><b>Taxes</b></td>
            <td className="w-100 d-flex justify-content-end">
              <b>
                <TaxSelect
                  asLabel
                  selectZeroTax
                  outline
                  noLabel
                  value={feeGroup?.taxItem?.tax_id ?? ""}
                  className="col-sm-12 m-0"
                />
              </b>
            </td>
          </tr>
        )}
        <TaxItems  asLabel taxItem={feeGroup?.taxItem}/>
        <FeeTotal total={feeGroup?.total} totalTaxes={feeGroup?.totalTaxes} asLabel />
      </tfoot>
    </table>
  );
}

export default FeeGroupAsLabel;
