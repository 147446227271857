import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CheckButton,
  DataGrid, Layout,
  DeleteLink
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./FeedbackConstant";
import { GridColumns } from "./FeedbackGridColumns";
import FeedbackService from "./FeedbackService";
import { UserContext } from "../../store/context/UserContext";


const Feedbacks = ({ value, insidePane, multiMode, onSelect = () => { }, recordType, recordOf, hideFilterBar }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const { userContent } = useContext(UserContext);

  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/ feedback/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)


  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await FeedbackService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    let { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    if (recordOf) {
      filterObject = { ...filterObject, recordOf: recordOf, recordType: recordType }
      filterUrl += `recordType=${recordType}&recordOf=${recordOf}`;
    }
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [recordType, recordOf, editID])

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await FeedbackService.delete(id);
        setLoading(false);
        setShowDetailPage(false);
        showMessage(t("Deleted"), t("Deleted"));
      } catch (e) {
        showError(e);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  return (
    <>
      <Layout
        hideAdd={!userContent?.rights?.includes(4001)}
        large
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/feedback");
          setEditID(null);
        }}
         
        title={t(" Feedbacks")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}

        insidePane={insidePane}
        page={FeedbackService.page}
        rows={FeedbackService.rows}
        total={FeedbackService.total}
        fetch={FeedbackService.fetch}
      >

        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={FeedbackService.records}
            total={FeedbackService.total}
            uiPreference="feedback.grid"
            headers={GridColumns}
            selectedIDs={selectedIDs}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            page={FeedbackService.page}
            rowsPerPage={FeedbackService.rowsPerPage}
            onPaginationChange={FeedbackService.onPaginationChange}
            renderLastCol={(feedback) => {
              return (
                <>
                  {userContent?.rights?.includes(4003) ?
                    <DeleteLink
                      onClick={(event) => onDelete(event, feedback?._id)}
                    />
                    : null}
                  {!multiMode && insidePane ? (
                    <CheckButton
                      onClick={() => {
                        onSelect(feedback?._id);
                      }}
                    />
                  ) : null}
                </>
              );
            }}
          />
        </Layout.Table>
        <Layout.DetailPageTitle>
          {/* {detailData?._id ? t("Edit  Feedback") : t("Add  Feedback")} */}
          <br />
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>

        </Layout.DetailPageBody>


      </Layout>
    </>
  );
};
export default observer(Feedbacks);
