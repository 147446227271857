import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { TfiTwitter } from "react-icons/tfi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, DropdownItem, DropdownMenu, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    BulkUpload,
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    DownloadByTemplate,
    EditLink,
    InputField,
    Layout,
    ModalComponent,
    SaveButton
} from "../../components";
import CustomOperations from "../../components/CustomField/CustomOperations";
import SideBarService from "../../components/HorizontalLayout/SideBarService";
import ComponentAsModal from "../../components/Modal/ComponentAsModal";
import PrintButton from "../../components/PrintButton/PrintButton";
import TagPicker, { getRandomColor } from "../../components/TagSelector/TagPicker";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { isArrayNonEmpty } from "../../util/Util";
import Feedback from "../Feedbacks/Feedback";
import InvoiceForm from "../Invoice/InvoiceForm";
import Invoiceservice from "../Invoice/InvoiceService";
import { STRUCTURE, TRIP_CHARGES, TRIP_TYPE } from "./BookingConstant";
import { GridColumns, statusMapping } from "./BookingGridColumns";
import { default as BookingService, default as Bookingservice } from "./BookingService";
import EditBooking from "./EditBooking";
import PrintDuty from "./PrintDuty";
import AuditLogDialog from "./components/Audit/AuditLog";
import BookingStatusModifier from "./components/BookingStatusModifier";
import MessageDialog from "./components/OnDemandMessage/MessageDialog";
import StartStop from "./components/StartStop";
import './style.css';
import { FaCarRear } from "react-icons/fa6";
import Vehicle from "../Vehicle/Vehicle";
import TimesheetService from "../Timesheet/TimesheetService";
import Employee from "../Employee/Employee";
import _ from "lodash";
import { FaUser } from "react-icons/fa";
import BookingFooterDropdown from "./components/BookingFooterDropdown";
import TagPickerService from "../../components/TagSelector/TagPickerService";
import { ImHammer2 } from "react-icons/im";
import { ThreeBounce } from "better-react-spinkit";
import { FaClone } from "react-icons/fa";
import GroupTourSidebar from "../GroupTour/sideBar/GroupTourSidebar";
import DownloadService from "../../components/Download/DowloadService";
import CommentService from "../Comment/CommentService";
import BulkInvoice from "../../components/BulkInvoice";
import { areTripsValidForInvoice } from "../../helpers/general";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { BiPrinter } from "react-icons/bi";
import { MdOutlineFeedback } from "react-icons/md";
import { MdOutlineInsertComment } from "react-icons/md";
import { FaRegMessage } from "react-icons/fa6";
import { FaRegClone } from "react-icons/fa";



const useCustomQueryParams = () => {
    const location = useLocation();
    // const prevQueryParamsRef = useRef(new URLSearchParams(location.search));
    // const currentQueryParamsRef = useRef(new URLSearchParams(location.search));

    const queryParams = useMemo(() => {
        return new URLSearchParams(location.search)
        // const pathname = location.pathname;
        // if (pathname.startsWith('/booking/create') || pathname.startsWith('/booking/edit')) {
        //     return prevQueryParamsRef.current;
        // } else {
        // const newQueryParams = new URLSearchParams(location.search);
        //     prevQueryParamsRef.current = newQueryParams;
        //     return newQueryParams;
        // }
    }, [location.pathname, location.search]);

    return queryParams;
};

const getDefaultClientBilling = (trip_id) => {
    return {
        trip_id: trip_id,
        feeRate: {
            rateExtn: {

            }
        },
        fee: {
            feeGroups: [{
                name: TRIP_CHARGES,
            }]
        }
    }
}


const statusFilteredOptions = {
    0: [
        { label: "Created", value: 0, color: "#AEB6BF" },
        { label: "Confirmed", value: 1, color: "#2980B9" }
    ],
    1: [
        // { label: "Created", value: 0 },
        { label: "Confirmed", value: 1, color: "#2980B9" },
    ],
    2: [
        { label: "Dispatch", value: 3, color: "#FFBF00" },
    ],
    3: [
        { label: "Completed", value: 5, color: "#52BE80" },
        { label: "Cancelled", value: 6, color: "#F1948A" },
    ],
    4: [
        { label: "Completed", value: 5, color: "#52BE80" },
        { label: "Cancelled", value: 6, color: "#F1948A" },
        { label: "Error", value: 7, color: "#CB4335" },
    ],
    5: [
        { label: "Cancelled", value: 5, color: "#52BE80" },
        { label: "Error", value: 6, color: "#F1948A" },
    ],
    6: [
        { label: "Completed", value: 5, color: "#52BE80" },
        { label: "Cancelled", value: 6, color: "#F1948A" },
        { label: "Error", value: 7, color: "#CB4335" },
    ]
}

const errorStatus = [
    { label: "Vehicle Missing", value: 1 },
    { label: "Driver Missing", value: 2 },
    { label: "Not Started", value: 3 },
    { label: "Not Closed", value: 4 },
    { label: "Break Down", value: 5 },
]

const Bookings = ({ value, insidePane, multiMode, onSelect = () => { }, bookedBy, passengerTrip, clientId, }) => {
    const billingRef = useRef([]);

    const isTour = useMemo(() => _.includes(window.location.pathname, "trips"), [window.location.pathname])

    const { userContent } = useContext(UserContext);
    const location = useLocation();
    const queryParams = useCustomQueryParams();
    let { edit_id } = useParams();
    let navigate = useNavigate();
    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [detailLoading, setDetailLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [vehicleModal, setVehicleModal] = useState(false);
    const [driverModal, setDriverModal] = useState(false);
    const [uploadPopup, setUploadPopup] = useState(false);
    const [vehicle, setVehicle] = useState();
    const [driver, setDriver] = useState();
    const [trip, setTrip] = useState({ tripType: 1 });
    const [clientBilling, setClientBilling] = useState({});
    const [supplierBillings, setSupplierBillings] = useState([]);
    const [allTripBillings, setAllTripBillings] = useState([]);
    const [filteredData, setFilteredData] = useState([...Bookingservice?.records]);
    const [editID, setEditID] = useState(edit_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/booking/create" || edit_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [fromDate, setFromDate] = useState(queryParams.get('fromDate')?queryParams.get('fromDate'):moment().subtract(1, 'day').startOf('day').format('YYYYMMDD'));
    const [tillDate, setTillDate] = useState(queryParams.get('tillDate')?queryParams.get('tillDate'):moment().add(6, 'day').endOf('day').format('YYYYMMDD'));
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)
    const [modalForTripInvoice, setModalForTripInvoice] = useState(false);
    const [customerType, setCustomerType] = useState(null);
    const [tripStatus, setTripStatus] = useState({ label: t("All"), value: 100 });
    const [showFeedback, setShowFeedback] = useState(false);
    const [showDownload, setShowDownload] = useState(false);
    const [showCustomizationOptions, setShowCustomizationOptions] = useState(false)
    const [printModal, setPrinttModal] = useState(false);
    const [billingUpdated, setBillingUpdated] = useState(false);
    const [billingUpdatedObject, setBillingUpdatedObject] = useState({ updated: false });
    const queryParamsRef = useRef({ prev: new URLSearchParams(), current: new URLSearchParams(location.search) });
    const [selectedTripsIdsForInvoice, setSelectedTripIdsForInvoice] = useState([]);
    const bulkInvoiceRef = useRef({
        modalVisible: false,
        selectedTripsIdsForInvoice: [],
    })

    useEffect(()=>{
        return ()=>{
            BookingService.resetState();
        }
    },[])
    const isParamsUpdated = () => {
        const pathname = location.pathname;
        if (pathname === '/booking' || pathname === '/booking/') {
            const currentQuerySearch = new URLSearchParams(location.search)
            if ((queryParamsRef.current?.prev?.toString() != currentQuerySearch.toString()) || (!currentQuerySearch?.size && !queryParamsRef.current.prev?.size)) {
                queryParamsRef.current.prev = currentQuerySearch
                queryParamsRef.current.current = currentQuerySearch
                return true
            }

        }
        return false
    };

    const saveBulk = async (data) => {
        try {
            const booking = await BookingService.bulkUpload(data);
            if (booking) {
                return { isUploaded: true, message: "" }
            }
            return { isUploaded: false, message: "Failed unexpectedly" }
        } catch (error) {
            return { isUploaded: false, message: JSON.parse(error?.message)?.customMessage ?? JSON.parse(error?.message)?.message }

        }
    };

    const navigation = useNavigate();

    const isCloning = () => queryParams.has('clone_trip_id')
    const toggleMessageModal = (e) => {
        setShowMessageModal(!showMessageModal);
        e?.stopPropagation();
    };
    useEffect(() => {
        setBillingUpdatedObject((prev) => {
            return { ...prev, updated: billingUpdated }
        })
    }, [billingUpdated]);

    const toggleData = isTour ? {
        module: [
            { label: t("Tours"), value: "tours" },
            { label: t("Trips"), value: "trips" },
            { label: t("Stays"), value: "stay" },
            { label: t("Flights"), value: "flights" },
            { label: t("Guests"), value: "guests" },
            { label: t("Calender"), value: "calender" },

        ],
        toggleValue: "trips"
    }
        : {
            module: [
                { label: t("All"), value: 100 },
                { label: t("New"), value: 0 },
                { label: t("Confirmed"), value: 1 },
                { label: t("Ready"), value: 2 },
                { label: t("Dispatched"), value: 3 },
                { label: t("Live"), value: 4 },
                { label: t("Completed"), value: 5 },
                { label: t("Cancelled"), value: 6 },
                { label: t("Rejected"), value: 10 },
                { label: t("Invoiced"), value: "19|20" },
                { label: t("Settled"), value: "29|30" },
            ],
            toggleValue: tripStatus?.value
        }

    const fetchData = useCallback(async () => {
        setDetailLoading(true);
        let nTrip = null, cBilling = null, sBillings = null;
        try {
            if (editID) {
                const { booking: b, clientBilling: cB, supplierBillings: sB } = await Bookingservice.fetchTripAndBilling(editID, isCloning());
                if (b) { nTrip = b; }
                if (cB) { cBilling = cB; }
                if (sB) { sBillings = sB }
                setTrip(nTrip || {});
            }
        } catch (e) {
            showError(e);
        } finally {
            setDetailLoading(false);
        }
        setClientBilling(cBilling || getDefaultClientBilling(editID));
        setSupplierBillings(sBillings || []);
    }, [editID, showError, queryParams]);

    useEffect(() => {
        fetchData();
    }, [fetchData])


    const updateBooking =async (invoiceIds) => {
            let IDs = invoiceIds.join(',');
            const idFilter = `_id=in[${IDs}]`;
            let filterUrl = "";
          
            if (idFilter) {
              filterUrl += `${idFilter}`;
            }
          
            let invoicedBookings = await BookingService.fetch(filterUrl);
          
            const invoicedBookingsMap = new Map(invoicedBookings.map((invoicedBooking) => [invoicedBooking._id, invoicedBooking]));
            const updatedBookings = filteredData.map((booking) =>
              invoicedBookingsMap.has(booking._id) ? { ...toJS(booking), ...invoicedBookingsMap.get(booking._id) } : toJS(booking)
            );
            setFilteredData(prev=>([...prev,...updatedBookings]));
            fetchGrid(filterURL);
      };
    

    const fetchBillingGridFromTripGrid = async () => {
        if (Bookingservice.records?.length) {
            const trip_ids = Bookingservice.records.map(e => e._id);
            const _allTripBillings = await BookingService.fetchBillingGridFromTripGrid(trip_ids.toString());
            const billing_trips_ids = Object.keys(_allTripBillings);
            const tripBillings = {};
            for (let index = 0; index < billing_trips_ids?.length; index++) {
                const tid = billing_trips_ids[index];
                const { clientBilling, supplierBillings } = _allTripBillings[tid];
                let clientAmount = clientBilling?.fee?.total ? clientBilling.fee.total : 0;
                let supplierAmount = 0;
                if (supplierBillings?.length) {
                    for (let j = 0; j < supplierBillings.length; j++) {
                        const supplierBilling = supplierBillings[j];
                        if (supplierBilling?.fee?.total != null) {
                            supplierAmount = supplierAmount + supplierBilling.fee.total;
                        }
                    }
                }
                tripBillings[tid] = {
                    clientAmount: (clientAmount / 100).toFixed(2),
                    supplierAmount: (supplierAmount / 100).toFixed(2),
                    makeModel: clientBilling?.feeRate?.makeModel
                }
            }
            setAllTripBillings(tripBillings);
        }
    }

    const fetchGridAfterGrid = async () => {
        const statusFilter = tripStatus?.value !== undefined && tripStatus?.value !== 100 ? `&status=in[${tripStatus.value}]` : '';
        let filter = `${statusFilter}&`;
        if (filterURL) {
            filter += `${filterURL}&`;
        }
        if (fromDate && tillDate) {
            filter += `&tripDate=btw[${encodeURIComponent(`${fromDate},${tillDate}`)}]`;
        }
        try {
            await Bookingservice.fetch(filter);
            await fetchBillingGridFromTripGrid();
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
    }

    const fetchGrid = useCallback(async (filterUrl) => {
        setLoading(true);
        try {
            const tripDateQuery = new URLSearchParams(filterUrl).get('tripDate');
            if((!fromDate || !tillDate) && !tripDateQuery){
                return;
            }
            if (filterUrl) filterUrl += `&`;
            const tripDate=`btw[${encodeURIComponent(`${fromDate},${tillDate}`)}]`
            if (decodeURIComponent(tripDateQuery) !== decodeURIComponent(tripDate)) {
                filterUrl += `&tripDate=${tripDate}`
            }  
            const queryParams=new URLSearchParams(window.location.search)
            const fromDateQuery=queryParams.get('fromDate');
            const tillDateQuery=queryParams.get('tillDate');
            if(fromDateQuery!==fromDate || tillDateQuery!==tillDate){
                queryParams.set('fromDate',fromDate);
                queryParams.set('tillDate',tillDate);
                navigation({
                    pathname: location.pathname,
                    search: `?${queryParams.toString()}`,
                    replace: true,
                });
            }
            // //if !tripDate in query params then check for fromDate and till Date in query params
            // if (!tripDate/* !fromDate && !tillDate */) {
            //     const fromDate = queryParams?.get('fromDate')
            //     const tillDate = queryParams?.get('tillDate');
            //     if (!fromDate || !tillDate) {
            //         return
            //     }
            //     if (filterUrl) filterUrl += `&`;
            //     if (filterUrl) {
            //         filterUrl += `tripDate=btw[${encodeURIComponent(`${fromDate},${tillDate}`)}]`;
            //     } else {
            //         filterUrl = `tripDate=btw[${encodeURIComponent(`${fromDate},${tillDate}`)}]`;
            //     }
            // }
            if (queryParams.has('sortBy')) {
                if (filterUrl) filterUrl += `&`;
                filterUrl += `&sortBy=${queryParams.get('sortBy')}`;
                filterUrl += `&sortAsc=${queryParams.get('sortAsc') ?? false}`;
            }
            const statusFilter = tripStatus?.value !== undefined && tripStatus?.value !== 100 && !filterUrl?.includes("status") ? `&status=${tripStatus.value}` : '';
            filterUrl += `${statusFilter}`
            // if (fromDate && tillDate) {
            //     filterUrl += `&tripDate=btw[${encodeURIComponent(`${fromDate},${tillDate}`)}]`;
            // }
            await Bookingservice.fetch(filterUrl);
            setLoading(false);
            await fetchBillingGridFromTripGrid();
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
    }, [showError, fromDate, tillDate,tripStatus.value,queryParams]);


    useEffect(() => {
        if (isParamsUpdated()) {
            if (!queryParams.has('sortBy') && !queryParams.has('sortAsc')) {
                queryParams.set('sortBy', 'startTime');
                queryParams.set('sortAsc', false);
                navigation({
                    pathname: location.pathname,
                    search: `?${queryParams.toString()}`,
                    replace: true,
                });
                return;
            }
            let { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane);
            if (bookedBy) {
                filterObject = {
                    ...filterObject,
                    bookedBy: {
                        customer_id: bookedBy
                    }
                };
                filterUrl += `&bookedBy.customer_id=${bookedBy}`;
            }

            if (passengerTrip) {
                filterObject = {
                    ...filterObject, passengers: {
                        0: {
                            customer_id: passengerTrip
                        }
                    }
                };
                filterUrl += `&passengers.0.customer_id=${passengerTrip}`;
            }

            if (clientId) {
                filterObject = {
                    ...filterObject,
                    client: {
                        corp_id: clientId
                    }
                };
                filterUrl += `&client.client_id=${clientId}`;
            }

            if (queryParams.has('status')) {
                const status = queryParams?.get('status');
                filterUrl +=`status=${status}`
                filterData(parseInt(status));
            }

            const fromDateLocal = fromDate ?? queryParams?.get('fromDate');
            const tillDateLocal = tillDate ?? queryParams?.get('tillDate');
            if (fromDateLocal && tillDateLocal) {
                if (filterUrl) filterUrl += "&";
                filterUrl += `&tripDate=btw[${encodeURIComponent(`${fromDateLocal},${tillDateLocal}`)}]`;
                setFromDate(fromDateLocal);
                setTillDate(tillDateLocal);
            } else {
                const fromDate = moment().subtract(1, 'day').startOf('day').format('YYYYMMDD');
                const tillDate = moment().add(6, 'day').endOf('day').format('YYYYMMDD');
                filterUrl += `&tripDate=btw[${encodeURIComponent(`${fromDate},${tillDate}`)}]`;
                queryParams.set('fromDate', fromDate);
                queryParams.set('tillDate', tillDate);
                setFromDate(moment().subtract(1, 'day').startOf('day').format('YYYYMMDD'));
                setTillDate(moment().add(6, 'day').endOf('day').format('YYYYMMDD'));
                navigation({
                    pathname: location.pathname,
                    search: `?${queryParams.toString()}`,
                    replace: true,
                });
                return;
            }
console.log("tripStatus======================",tripStatus?.value)
            setFilterURL(filterUrl);
            setFilterObject(filterObject);
            fetchGrid(filterUrl);
            multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : []);
        }
    }, [fetchGrid, value, multiMode]);

    const checkClients = (selectedIDs) => {
        if (selectedIDs.length > 1) {
            const filteredRecords = Bookingservice?.records.filter(record => selectedIDs?.includes(record?._id));
            // Get the client of the first record for comparison
            const firstClient = filteredRecords[0]?.client;
            return filteredRecords.every(booking =>
                booking?.client?.client_id === firstClient?.client_id
            );
        } else return true;
    };

    useEffect(() => {
        setClientBilling(currentBilling => ({
            ...currentBilling,
            client_id: trip?.client?.client_id || ""
        }));
    }, [trip?.client?.client_id]);

    useEffect(() => {
        setClientBilling(currentBilling => ({
            ...currentBilling,
            clientCompany_id: trip?.client?.company_id || ""
        }));
    }, [trip?.client?.company_id]);

    useEffect(() => {
        console.log({ pendingSpplierBillings: toJS(BookingService.pendingSpplierBillings) })
    }, [BookingService.pendingSpplierBillings,])

    useEffect(() => {
        console.log({ trip_id: BookingService.trip_id })
    }, [BookingService.trip_id])

    const toSelectDriver = (bookingTime, bookingStartDate, startTime, endTime, startDate, endDate) => {
        if (!startDate || !startTime) {
            return true;
        }
        if (bookingStartDate < startDate) {
            return false;
        }
        if (endDate && bookingStartDate > endDate) {
            return false;
        }
        if (bookingTime < startTime && bookingStartDate === startDate) {
            return false;
        }
        if (endTime && endDate && bookingTime > endTime && bookingStartDate === endDate) {
            return false;
        }
        return true;
    };
    const fetchDrivers = async (vehicleId) => {
        try {
            let timeSheetFilterURl = `vehicle_id=${vehicleId}`;
            await TimesheetService.fetch(timeSheetFilterURl);
            const timeSheetList = TimesheetService?.records;

            const timestamp = trip?.startTime ? trip?.startTime : Math.floor(Date.now() / 1000);
            const currentTime = parseInt(moment.unix(timestamp).format('HHmm'));
            const bookingStartDate = parseInt(moment.unix(timestamp).format('YYYYMMDD'));
            const filteredEmployeeIds = new Set();
            timeSheetList.forEach((item) => {
                item.driverDuties.forEach((duty) => {
                    const startTime = duty?.start;
                    const endTime = duty?.end;
                    const startDate = duty?.startDate;
                    const endDate = duty?.endDate;
                    if (toSelectDriver(currentTime, bookingStartDate, startTime, endTime, startDate, endDate)) {
                        filteredEmployeeIds.add(duty.employee_id);
                    }
                });
            });
            const filteredEmployeeIdsArray = Array.from(filteredEmployeeIds);
            console.log(filteredEmployeeIdsArray);
            if (filteredEmployeeIdsArray?.length > 0) {
                const selectedDriver = filteredEmployeeIdsArray[0];
                setDriver(selectedDriver);
                return selectedDriver;
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const updateTrip = async () => {
            if (vehicle) {
                const selectedDriver = await fetchDrivers(vehicle);
                const tripData = await BookingService.get(trip?._id);
                const tripExtn = {
                    ...tripData?.tripExtn
                };
                const updatedTrip = {
                    ...trip,
                    tripExtn: {
                        ...tripExtn
                    },
                    vehicle: {
                        ...trip?.vehicle,
                        _id: vehicle
                    },
                    driver: {
                        ...trip?.driver,
                        _id: selectedDriver
                    }
                };
                const toSaveTrip = {
                    ...trip,
                    ...(customerType === "walkIn" && { client: null }),
                    passengers: checkParrangersAnonymous(trip?.passengers)
                };
                setTrip(toSaveTrip);
                setLoading(true);
                try {
                    // const bookingID = await Bookingservice.edit(updatedTrip);

                    let updatedBillings = [];
                    if (clientBilling) {
                        const t = BookingService.getBillingProcessedObjectForSaveUpdate(updatedTrip._id, updatedTrip, clientBilling, "Client", false);
                        if (t) updatedBillings.push(t);
                    }
                    if (supplierBillings?.length) {
                        for (let i = 0; i < supplierBillings.length; i++) {
                            const t = BookingService.getBillingProcessedObjectForSaveUpdate(updatedTrip._id, updatedTrip, supplierBillings[i], "Supplier", false);
                            if (t) updatedBillings.push(t);
                        }
                    }

                    const booking = await Bookingservice.editAll({ trips: [updatedTrip], billings: updatedBillings, billingUpdated: false });
                    const bookingID=booking?._id
                    showMessage("Record Updated successfully");
                    await updateBiling(bookingID);
                    await fetchGrid(filterURL);
                    await fetchData();
                } catch (error) {
                    showError(error);
                    setHasErr(true);
                } finally {
                    setHasErr(false)
                    setLoading(false);
                }
            }
        };
        updateTrip();
    }, [vehicle]);

    useEffect(() => {
        const updateTrip = async () => {
            if (driver && trip?._id) {
                const tripData = await BookingService.get(trip?._id);
                const tripExtn = {
                    ...tripData?.tripExtn
                };
                const updatedTrip = {
                    ...trip,
                    tripExtn: {
                        ...tripExtn
                    },
                    vehicle: {
                        ...trip?.vehicle,
                        _id: vehicle
                    },
                    driver: {
                        ...trip?.driver,
                        _id: driver
                    }
                };
                const toSaveTrip = {
                    ...trip,
                    ...(customerType === "walkIn" && { client: null }),
                    passengers: checkParrangersAnonymous(trip?.passengers)
                };
                setTrip(toSaveTrip);
                setLoading(true);
                try {
                    // const bookingID = await Bookingservice.edit(updatedTrip);

                    let updatedBillings = [];
                    if (clientBilling) {
                        const t = BookingService.getBillingProcessedObjectForSaveUpdate(updatedTrip._id, updatedTrip, clientBilling, "Client", false);
                        if (t) updatedBillings.push(t);
                    }
                    if (supplierBillings?.length) {
                        for (let i = 0; i < supplierBillings.length; i++) {
                            const t = BookingService.getBillingProcessedObjectForSaveUpdate(updatedTrip._id, updatedTrip, supplierBillings[i], "Supplier", false);
                            if (t) updatedBillings.push(t);
                        }
                    }

                    const booking = await Bookingservice.editAll({ trips: [updatedTrip], billings: updatedBillings, billingUpdated: false });
                    const bookingID=booking?._id
                    showMessage("Record Updated successfully");
                    await updateBiling(bookingID);
                    await fetchGrid(filterURL);
                    await fetchData();
                } catch (error) {
                    showError(error);
                    setHasErr(true);
                } finally {
                    setHasErr(false)
                    setLoading(false);
                }
            }
        }
        updateTrip();
    }, [driver]);



    const validatePassengerDetails = (passengers) => {
        const hasMissingDetails = passengers?.some(passenger => !passenger?.anonymous && !passenger?.name);
        return hasMissingDetails
    };

    const checkParrangersAnonymous = (passengers) => {
        return passengers?.map(passenger => {
            return passenger?.anonymous == true ? { "anonymous": true } : passenger;
        });
    };

    const updateBiling = async (bookingID) => {
        BookingService.setTripId(bookingID);
        try {
            const promisses = [];
            if (billingUpdated) {
                billingRef.current?.clientBillings?.forEach((childRef, i) => {
                    promisses.push(childRef?.billingSaveFromRef());
                });
                billingRef.current?.supplierBillings?.forEach(childRef => {
                    promisses.push(childRef?.billingSaveFromRef());
                });
            }
            await Promise.all(promisses);
        } catch (error) {
            throw error;
        }
    };

    const onSave = async (e) => {
        e.preventDefault();

        if (!trip?.vendorCompany_id) {
            setHasErr(true)
            showError(t("Please add serving company"));
            return;
        }
        if (!trip?.tripType) {
            setHasErr(true)
            showError(t("Please add trip type"));
            return;
        }
        if (!trip?.passengers?.length > 0) {
            setHasErr(true)
            showError(t("Please Add Passengers "));
            return;
        }

        if (validatePassengerDetails(trip?.passengers)) {
            setHasErr(true);
            showError(t("Please Enter Passenger Details"));
            return
        }

        if (!trip?.startTime) {
            setHasErr(true)
            showError(t("Please Enter Trip Start Time"));
            return;
        }
        if (trip?.duration < 0) {
            setHasErr(true)
            showError(t(" Trip End Time cannot be less than Trip Start Time"));
            return;
        }
        if (!trip?.src) {
            setHasErr(true)
            showError(t("Please Enter  Pick Up Location"));
            return;
        }
        if(trip?.tripType===TRIP_TYPE.Route && trip?.routeType===4){
            if(!trip?.schedule?.runTimes?.length){
                setHasErr(true)
                showError(t("Please Enter  Schedule"));
                return;
            } 
            const scheduleWithoutTime=trip?.schedule?.runTimes?.find(s=>!s?.value)
            if(scheduleWithoutTime){
                setHasErr(true)
                showError(t("Please Enter  Schedule Time"));
                return;
            }  
        }
        const toSaveTrip = {
            ...trip,
            ...(customerType === "walkIn" && { client: null }),
            passengers: checkParrangersAnonymous(trip?.passengers),
            tripExtn: {
                ...trip?.tripExtn,
                ...(trip?.tripType===TRIP_TYPE.Route && { 
                   routeDNA:{
                    routeNo:trip?.tripNo,
                    passengers:trip?.passengers,
                    src:trip?.src,
                    dst:trip?.dst,
                    stops:trip?.stops,
                    ...(trip?.routeType===4 && {
                        runTimes:trip?.schedule?.runTimes
                    })
                   }
                 }),
            }
        };



        setLoading(true);

        if (isCloning()) {
            delete toSaveTrip?._id;
            delete toSaveTrip?.version;
            delete toSaveTrip?.tripNo;
            delete toSaveTrip?.createdAt;
            toSaveTrip.billingUpdated = true;
            toSaveTrip.status = 0;
            delete toSaveTrip.createdBy;
            delete toSaveTrip.corp_id;
            delete toSaveTrip.paymentPercent;
            delete toSaveTrip.errorStatus;
            delete toSaveTrip.clientInvoice_id;
            delete toSaveTrip.liveTrackingCode;
            delete toSaveTrip.refNo;
            delete toSaveTrip?.tripExtn?._id
            delete toSaveTrip?.tripExtn?.version
        }
        setTrip(toSaveTrip)

        try {
            let updatedBillings = [];
            if (clientBilling) {
                const t = BookingService.getBillingProcessedObjectForSaveUpdate(editID && !isCloning() ? toSaveTrip._id : null, toSaveTrip, clientBilling, "Client", billingUpdatedObject?.updated);
                if (t) updatedBillings.push(t);
            }
            if (supplierBillings?.length) {
                for (let i = 0; i < supplierBillings.length; i++) {
                    const t = BookingService.getBillingProcessedObjectForSaveUpdate(editID && !isCloning() ? toSaveTrip._id : null, toSaveTrip, supplierBillings[i], "Supplier", billingUpdatedObject?.updated);
                    if (t) updatedBillings.push(t);
                }
            }

            if (editID && !isCloning()) {
                // const bookingID = await Bookingservice.edit(toSaveTrip);
                const booking= await Bookingservice.editAll({ trips: [toSaveTrip], billings: updatedBillings, billingUpdated: billingUpdatedObject?.updated });
                const bookingID=booking?._id
                showMessage("Record Updated successfully");
                await updateBiling(bookingID);
                fetchGrid(filterURL)
                fetchData();
            } else {
                // const bookingID = await Bookingservice.save(toSaveTrip);
                const booking = await Bookingservice.saveAll({ trips: [toSaveTrip], billings: updatedBillings, billingUpdated: billingUpdatedObject?.updated });
                const bookingID=booking?._id
                await updateBiling(bookingID);
                if (!insidePane) navigate(isTour ? `/booking/edit/${bookingID}` : `/booking/edit/${bookingID}`);
                if (bookingID) setEditID(bookingID)
                showMessage(t("Record saved successfully."));
                fetchGrid(filterURL)
            }
        } catch (e) {
            showError(e);
        }
        setHasErr(false)
        setLoading(false);
    };

    const onStartStop = async (e, payload, start) => {
        e.preventDefault();
        setLoading(true);
        try {
            await Bookingservice.startStop(start, payload);
            showMessage("Record Updated successfully");
            fetchGrid(filterURL)
            fetchData();
        } catch (e) {
            showError(e);
        } finally {
            setHasErr(false)
            setLoading(false);
        }
    };
    const onStatusChange = async (e, payload) => {
        e.preventDefault();
        setLoading(true);
        try {
            await Bookingservice.modifyStatus(payload);
            // showMessage("Record Updated successfully");
            fetchGrid(filterURL)
            fetchData();
            // setStatusInfo(statusMapping[trip?.status || 0])
        } catch (e) {
            showError(e);
        } finally {
            setHasErr(false)
            setLoading(false);
        }
    };

    const [statusInfo, setStatusInfo] = useState(statusMapping[trip?.status || 0])

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            //setLoading(true)

            try {
                await Bookingservice.delete(id);
                showMessage("Deleted", "Deleted");
                if (isTour) navigate(isTour ? `/trips` : `/booking`);
            } catch (e) {
                showError(e);
            } finally {
                // setLoading(false)
                setShowDetailPage(false);
            }
        }
    };

    const showInvoiceCreate = (booking=trip) => {
        return booking != null
            && booking._id
            && booking.status
            && [1,2,3,4,5,19,20,29,30]?.includes(booking?.status)
            // && (booking.status === 1 || booking.status === 2 || booking.status === 3 || booking.status === 4 || booking.status === 5)
    }

    useEffect(() => {
        setStatusInfo(statusMapping[trip?.status || 0])
    }, [trip?.status])

    const toggleSingleTripModalForInvoice = () => {
        if (trip && editID) {
            if (trip.clientInvoice_id) {
                window.open(`/invoice/edit/${trip.clientInvoice_id}`, "_blank");
            } else {
                setModalForTripInvoice(!modalForTripInvoice);
            }
        }
    }

    const toggleModal = async () => {
        if (modal) {
            setModal(prev => {
                bulkInvoiceRef.current.modalVisible = !prev;
                bulkInvoiceRef.current.selectedTripsIdsForInvoice = []
                return !prev
            })
            setSelectedIDs([]);
            setSelectedTripIdsForInvoice([]);
            fetchGrid(filterURL);
            return;
        }
        if (!selectedTripsIdsForInvoice?.length && !selectedIDs.length) {
            setHasErr(true);
            showError(t("Select Trips For Creating Bulk Invoice"));
            return;
        }
        let selectedTripIds = [...selectedTripsIdsForInvoice];
        if (!selectedTripIds?.length) { //if not present in selectedTripsIdsForInvoice ( ie before modal is opened)
            selectedTripIds = [...selectedIDs];
        }
        const ids = selectedTripIds?.length > 0 ? selectedTripIds : [editID];
        const trips = await Bookingservice.postGrid(`_id=in[${(ids ?? [])?.join(',')}]&rows=-1`);
        const { isValid, message } = areTripsValidForInvoice(trips, selectedTripIds);
        if (!isValid) {
            setHasErr(true);
            showError(t(message));
            return;
        }
        if (!selectedTripsIdsForInvoice.length) {//for first when scenerios when modal is not empty
            setSelectedTripIdsForInvoice(selectedTripIds);
            bulkInvoiceRef.current.selectedTripsIdsForInvoice = selectedTripIds
        }
        setModal(prev => {
            bulkInvoiceRef.current.modalVisible = !prev;
            return !prev
        });
    }

    const printInvoice = async (e) => {
        setLoading(true);
        try {
            await BookingService.print([editID] || [], { ...{ templateName: "dutySlip" } } || {})
            // onSuccess()
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
    };

    const filterData = (selectedValue) => {
        switch (selectedValue) {
            case t(0):
                setTripStatus({ label: t("Created"), value: 0 });
                break;
            case t(1):
                setTripStatus({ label: t("Confirmed"), value: 1 });
                break;
            case t(2):
                setTripStatus({ label: t("Ready"), value: 2 });
                break;
            case t(3):
                setTripStatus({ label: t("Dispatched"), value: 3 });
                break;
            case t(4):
                setTripStatus({ label: t("Live"), value: 4 });
                break;
            case t(5):
                setTripStatus({ label: t("Completed"), value: 5 });
                break;
            case t(6):
                setTripStatus({ label: t("Cancelled"), value: 6 });
                break;
            case t(7):
                setTripStatus({ label: t("Error"), value: 7 });
                break;
            case t(8):
                setTripStatus({ label: t("Paused"), value: 8 });
                break;
            case t(9):
                setTripStatus({ label: t("Expired"), value: 9 });
                break;
            case t(10):
                setTripStatus({ label: t("Rejected"), value: 10 });
                break;
            case t(100):
                setTripStatus({ label: t("All"), value: 100 });
                break;
            case t("19|20"):
                setTripStatus({ label: t("Invoiced"), value: "19|20" });
                break;
            case t("29|30"):
                setTripStatus({ label: t("Settled"), value: "29|30" });
                break;
            default:
                break;
        }
        return;
    }

    const toggleDownload = () => {
        setShowDownload(prev => {
            if (!prev) {
                let filterUrl = filterURL
                if (queryParams.has('sortBy')) {
                    if (filterUrl) filterUrl += `&`;
                    filterUrl += `sortBy=${queryParams.get('sortBy')}`;
                    filterUrl += `&sortAsc=${queryParams.get('sortAsc') ?? false}`;
                }
                const statusFilter = tripStatus?.value !== undefined && tripStatus?.value !== 100 ? `&status=${tripStatus.value}` : '';
                filterUrl += `${statusFilter}`
                BookingService.setDownloadFilter(filterUrl)
            }
            return !prev
        });
    };

    const handleDateRangeChange = (type, date) => {
        try {
            if (!date) {
                queryParams.delete(type);
            } else {
                queryParams.set(type, date)
            }
            const { pathname } = location;
            navigation({
                pathname,
                search: `?${queryParams.toString()}`,
                replace: true,
            });
        } catch (error) {

        }
    }
    const handleTripChange = useCallback((fieldName, fieldValue) => {
        if (fieldName && fieldValue) {
            setTrip((prevTrip) => {
                return {
                    ...prevTrip,
                    [fieldName]: fieldValue,
                };
            });
        }
    }, []);

    const onCloneClick = () => {
        queryParams.set('clone_trip_id', editID);
        const { pathname } = location;
        navigation({
            pathname,
            search: `?${queryParams.toString()}`,
            replace: true,
        });
    }

    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                const response = await BookingService.bulkDelete(selectedIDs);
                if (response?.err) {
                    showError(response?.err);
                } else {
                    showMessage(t("Deleted"), t("Deleted Successfully"));
                    setSelectedIDs([])
                }
            } catch (e) {
                showError(e);
            } finally {
                setLoading(false);
            }
        }
    };
    

    const toShowDutyCose = () => {
        const filteredTag = TagPickerService?.records?.find(tag => tag.kind === 'trip' && tag?.label?.toLowerCase() === 'verified');
        let tagIds = trip?.tags?.map(tag => tag?.tag_id) ?? [];
        const isVerified = tagIds?.includes(filteredTag?._id);
        return !isVerified
    }
    const closeDuty = async () => {
        try {
            setLoading(true)
            const filteredTag = TagPickerService?.records?.find(tag => tag.kind === 'trip' && tag?.label?.toLowerCase() === 'verified');
            let tagIds = trip?.tags?.map(tag => tag?.tag_id) ?? [];

            if (filteredTag) {
                if (!tagIds?.includes(filteredTag?._id)) {
                    tagIds = [...tagIds, filteredTag?._id]
                }
            }
            if (!filteredTag) {
                const newTag = {
                    kind: 'trip',
                    color: getRandomColor(),
                    label: 'VERIFIED',
                    group: 'Some Group',
                };
                const response = TagPickerService.save(newTag); // Assume response includes the created tag
                tagIds = [...tagIds, response?.data?._id]
            }

            // const tripData = await BookingService.get(trip?._id);
            const toSaveTrip = {
                ...trip,
                status: 5,
                tags: tagIds?.map(tag => ({ tag_id: tag }))
            };
            // const b_id = await BookingService.edit(toSaveTrip);

            let updatedBillings = [];
            if (clientBilling) {
                const t = BookingService.getBillingProcessedObjectForSaveUpdate(toSaveTrip._id, toSaveTrip, clientBilling, "Client", billingUpdatedObject?.updated);
                if (t) updatedBillings.push(t);
            }
            if (supplierBillings?.length) {
                for (let i = 0; i < supplierBillings.length; i++) {
                    const t = BookingService.getBillingProcessedObjectForSaveUpdate(toSaveTrip._id, toSaveTrip, supplierBillings[i], "Supplier", billingUpdatedObject?.updated);
                    if (t) updatedBillings.push(t);
                }
            }

            const booking = await Bookingservice.editAll({ trips: [toSaveTrip], billings: updatedBillings, billingUpdated: billingUpdatedObject?.updated });
            const bookingID=booking?._id
            updateBiling(bookingID);
            showMessage("Duty Closed successfully");
            setTrip(toSaveTrip);
            setShowDetailPage(false);
            let returnUrl = window.location.search ? new URLSearchParams(window.location.search).get("return") : "";
            navigate(returnUrl ? returnUrl : `/booking`);
            setEditID(null)
            fetchGrid(filterURL);
        } catch (error) {
            showError(error)
        } finally {
            setLoading(false)
        }
    }

    const onDutyClose = async () => {
        if (loading) return;
        if (trip?.status === 5) {
            closeDuty()
            return;
        }

        if (
            await showConfirm({
                title: t("Close Duty"),
                description: t("Do you want to close this duty?"),
            })
        ) {

            try {
                closeDuty()
            } catch (e) {
                showError(e);
            }
        }
        return;


    }

    



    return (
        <React.Fragment key={userContent?.rights}>
            <>
                {showDownload ? <DownloadByTemplate
                    filter={filterObject}
                    download={BookingService.downloadByTemplate}
                    kind={"Trip"}
                    onClose={setShowDownload}
                    show={showDownload}
                    filterStructure={STRUCTURE}
                    onApplyFilter={BookingService.setDownloadFilter}
                /> : null}


                <div className={isTour ? "d-flex" : ""}>

                    <BulkUpload
                        show={uploadPopup}
                        onClose={() => { setUploadPopup(false) }}
                        title={t("Trip")}
                        kind={"Trip"}
                        upload={saveBulk}
                    />

                    {isTour ? <div style={{ minWidth: "200px" }} className={insidePane ? "" : "page-content"}>
                        <GroupTourSidebar module="events" />
                    </div> : null}

                    <div className="flex-1" style={{ width: `calc(100vw - ${isTour ? '250px' : 0})` }}>
                        <Layout
                            compact
                            dateRangeClass="ms-2"
                            hideAdd={!userContent?.rights?.includes(6001)}
                            fromDate={fromDate}
                            tillDate={tillDate}
                            defaultValue={[fromDate, tillDate]?.filter(date => date)?.map(date => moment(parseInt(date), 'YYYYMMDD'))}
                            setFromDate={(date) => {
                                // handleDateRangeChange('fromDate', date)
                                setFromDate(date)
                            }}
                            setTillDate={(date) => {
                                // handleDateRangeChange('tillDate', date)
                                setTillDate(date)
                            }}
                            onDownloadClick={() => DownloadService.toggleModal("Trip")}
                            data={filteredData}
                            setData={setFilteredData}
                            large
                            showDateRange
                            toggleData={toggleData}
                            showToggle
                            toggleType={isTour ? "" : "dropdown"}
                            onToggle={(selectedValue) => {
                                if (isTour) {
                                    if (selectedValue === "tours") {
                                        navigate(`/tour`);
                                    } else if (selectedValue === "trips") {
                                        navigate(`/trips`);
                                    } else if (selectedValue === "stay") {
                                        navigate("/stay");
                                    } else if (selectedValue === "flights") {
                                        navigate("/flight");
                                    } else if (selectedValue === "guests") {
                                        navigate("/guests");
                                    } else if (selectedValue === "calender") {
                                        navigate("/tours/calender");
                                    }
                                } else {
                                    filterData((selectedValue));
                                    queryParams.set('status', selectedValue)
                                    navigation({
                                        pathname: location.pathname,
                                        search: `?${queryParams.toString()}`,
                                        replace: true,
                                    })
                                }
                            }}

                            showDetailPage={showDetailPage}
                            showViews={isTour}
                            backDetailPage={async () => {
                                setShowDetailPage(false);
                                queryParams.delete('clone_trip_id');
                                // if (!insidePane) navigate(isTour ? `/trips` : `/booking`);
                                let returnUrl = window.location.search ? new URLSearchParams(window.location.search).get("return") : "";
                                if (!insidePane) navigate(isTour ? `/trips` : (returnUrl ? returnUrl : `/booking`));
                                setEditID(null);
                            }}
                             
                            title={t("Bookings")}
                            filterValues={filterObject}
                            filterStructure={STRUCTURE}
                            onApplyFilter={url=>{
                                BookingService.updatePage();
                                fetchGrid(url)
                            }}

                            onAddClick={() => {
                                const encodedUrl = decodeURI(`/booking/create?return=${encodeURIComponent(`${window.location?.pathname}${window.location.search}`)}`);
                                if (!insidePane) navigate(isTour ? `/trips/create` : encodedUrl);
                                setCustomerType(null)
                                setShowDetailPage(true);
                                setTrip({})
                                setEditID(null);
                            }}
                            insidePane={insidePane}
                            page={Bookingservice.page}
                            rows={Bookingservice.rows}
                            total={Bookingservice.total}
                            fetch={Bookingservice.fetch}
                            showTimeFilter={true}
                            onTimeSortOrderChange={(selectedvalue)=>{ 
                                queryParams.set('sortAsc',selectedvalue)
                                navigation({
                                    pathname: location.pathname,
                                    search: `?${queryParams.toString()}`,
                                    replace: true,
                                });
                            }}

                        >
                            <Layout.ActionMenu>
                                <div className="layout-action-menu">
                                    <DropdownMenu>
                                        <>
                                            <DropdownItem onClick={() => { onBulkDelete() }}  >{t("Bulk Delete")}</DropdownItem>
                                            <div class="dropdown-divider"></div>
                                            <DropdownItem
                                                onClick={() => {
                                                    if (!selectedIDs || selectedIDs.length == 0) {
                                                        showError("No trip selected");
                                                        return;
                                                    }
                                                    setPrinttModal(!modal);
                                                }}
                                            >
                                                {t("Print Duty")}
                                            </DropdownItem>
                                            <div class="dropdown-divider"></div>
                                            <DropdownItem onClick={() => setUploadPopup(true)}>
                                                {t("Bulk Upload")}
                                            </DropdownItem>
                                            <div class="dropdown-divider"></div>
                                            <DropdownItem onClick={toggleDownload}>{t("Download")}</DropdownItem>
                                            <div class="dropdown-divider"></div>
                                            <DropdownItem
                                                onClick={toggleModal}
                                            >
                                                {t("Bulk Invoice")}
                                            </DropdownItem>
                                            {/* <DropdownItem onClick={() => { setShowCustomizationOptions(true) }}>{t("Customise")} </DropdownItem>
                                            {modal && (
                                                <Modal
                                                    isOpen={modal}
                                                    toggle={toggleModal}
                                                    size="xl"
                                                >
                                                    <ModalHeader toggle={toggleModal}>
                                                        Create Invoice
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        <InvoiceForm
                                                            toggleModal={toggleModal}
                                                            trip_ids={selectedIDs}
                                                            //  validateFeegroup,
                                                            onSave={(invoicedTripIds) => {
                                                                updateBooking(invoicedTripIds);
                                                            }
                                                            }
                                                            concise
                                                        />
                                                    </ModalBody>
                                                    <div> 
                                                    </div>
                                                </Modal>
                                            )} */}

                                        </>
                                    </DropdownMenu>
                                </div>
                            </Layout.ActionMenu>
                            <Layout.Table>
                                <DataGrid
              gridLoading={loading}
                                    data={Bookingservice.records}
                                    allTripBillings={allTripBillings}
                                    total={Bookingservice.total}
                                    uiPreference="Booking.grid"
                                    headers={GridColumns}
                                    selectedIDs={Array.from(new Set([...selectedIDs, ...selectedTripsIdsForInvoice]))}// here passing selectedTripsIdsForInvoice only for showing selected checkbox and for invoice
                                    onSelectChange={async (v) => {
                                        onSelect(v)
                                        setSelectedIDs(v);
                                        //not getting updated state here hence used ref
                                        if (bulkInvoiceRef.current.modalVisible || bulkInvoiceRef.current.selectedTripsIdsForInvoice?.length) {
                                            //here send only those ids which are not in selectedTripsIdsForInvoice as the one which are in selectedTripsIdsForInvoice are already selected to avoid extra data in api call
                                            const alreadyPresentTripsIds = new Set(bulkInvoiceRef.current.selectedTripsIdsForInvoice);
                                            const newSelectedIds = v?.filter(value => !alreadyPresentTripsIds.has(value));

                                            const deletedValues = bulkInvoiceRef.current.selectedTripsIdsForInvoice?.filter(value => !v?.includes(value));
                                            if (!newSelectedIds?.length) {
                                                //all values are already there so update values which one are coming as all are valid (previously checked)
                                                bulkInvoiceRef.current.selectedTripsIdsForInvoice = v;
                                                setSelectedTripIdsForInvoice(v)
                                                return;
                                            }
                                            //add one trip_id from prev selectedTripsIdsForInvoice to match selected client purpose
                                            const tripIds = [bulkInvoiceRef.current.selectedTripsIdsForInvoice[0], ...newSelectedIds];
                                            const trips = await Bookingservice.postGrid(`_id=in[${(tripIds ?? [])?.join(',')}]&rows=-1`);
                                            const { isValid, message } = areTripsValidForInvoice(trips, tripIds)
                                            if (!isValid) {
                                                if (deletedValues?.length) {
                                                    bulkInvoiceRef.current.selectedTripsIdsForInvoice = bulkInvoiceRef.current.selectedTripsIdsForInvoice?.filter(value => !deletedValues?.includes(value));
                                                    setSelectedTripIdsForInvoice(bulkInvoiceRef.current.selectedTripsIdsForInvoice)
                                                }
                                                setHasErr(true);
                                                showError(t(message));
                                                return;
                                            }
                                            const updatedTripIds = Array.from(new Set([...v]));
                                            bulkInvoiceRef.current.selectedTripsIdsForInvoice = updatedTripIds;
                                            setSelectedTripIdsForInvoice(updatedTripIds)
                                        }
                                    }}
                                    page={Bookingservice.page}
                                    rowsPerPage={Bookingservice.rowsPerPage}
                                    onPaginationChange={Bookingservice.onPaginationChange}
                                    renderLastCol={(booking) => {
                                        return (
                                            <>
                                                {userContent?.rights?.includes(6002) &&
                                                    <EditLink
                                                        onClick={() => {
                                                            setShowDetailPage(true);
                                                            if (!insidePane)
                                                                navigate(isTour ? `/trips/edit/${booking?._id}` : `/booking/edit/${booking?._id}?return=${encodeURIComponent(`/booking?${queryParamsRef.current.current?.toString()}`)}`);
                                                            setEditID(booking?._id);
                                                            setBillingUpdated(false);
                                                        }}
                                                    />
                                                }
                                                {!multiMode && insidePane ? (
                                                    <div>
                                                        <CheckButton
                                                            onClick={() => {
                                                                onSelect(booking?._id);
                                                            }}
                                                        />
                                                    </div>
                                                ) : null}


                                            </>
                                        );
                                    }}
                                    showActionMenu={(booking) => {
                                        return true || (!(booking?.status == 3 || booking?.status == 4)) || (booking?.status != 3 && booking?.status != 4 && booking?.status != 5 && booking?.status != 6 && userContent?.rights?.includes(6003))
                                    }}
                                    renderActionMenu={(booking) => {
                                        return (
                                            <>
                                                <div className="d-flex flex-column">
                                                    {booking?.status !== 4 && userContent?.rights?.includes(6003) &&

                                                        <div
                                                            className="zr_action_item"
                                                            onClick={() => {
                                                                setTrip(booking)
                                                                setVehicleModal(true)
                                                            }}
                                                        >
                                                            <div className="me-2">
                                                                <FaCarRear fontSize={12} />

                                                            </div>
                                                            Assign Vehicle
                                                        </div>
                                                    }
                                                    {booking?.status !== 4 && userContent?.rights?.includes(6003) &&

                                                        <div
                                                            className="zr_action_item"
                                                            onClick={() => {
                                                                setTrip(booking)
                                                                setDriverModal(true)
                                                            }
                                                            }
                                                        >
                                                            <div className="me-2">
                                                                <FaUser fontSize={12} />

                                                            </div>
                                                            Assign Driver
                                                        </div>

                                                    }
                                                    {(booking?.status === 3 || booking?.status === 4) ? (
                                                        <div
                                                            className="zr_action_item"
                                                            onClick={() => {
                                                                SideBarService.toggleMap()
                                                                SideBarService.setModule("trip")
                                                                SideBarService.setTripId(booking?._id)
                                                            }}>
                                                            <TfiTwitter className="me-2 font-size-16" /> Track Vehicle
                                                        </div>
                                                    ) : null
                                                    }
                                                    {showInvoiceCreate(booking) ?
                                                          <div
                                                          className="zr_action_item"
                                                         onClick={  () => {
                                                                      if (booking && booking?._id) {
                                                                        // setShowDetailPage(true);
                                                                          if (booking.clientInvoice_id) {
                                                                              window.open(`/invoice/edit/${booking?.clientInvoice_id}`, "_blank");
                                                                          } else {
                                                                              setTrip(booking)
                                                                              setEditID(booking?._id)
                                                                              setModalForTripInvoice((prev)=>!prev)
                                                                      }
                                                                    //   navigate(`/booking/edit/${booking?._id}`);
                                                                  }}}
                                                          >
                                                              <FaFileInvoiceDollar className="me-2 font-size-16" /> {t(`${!booking?.clientInvoice_id ? "Create Invoice" : "View Invoice "}`)}
                                                          </div>: ""}
                                                    {/* {
                                                        <div
                                                        className="zr_action_item"
                                                       onClick={  () => {
                                                                    if (booking && booking?._id) {
                                                                        if (booking.clientInvoice_id) {
                                                                            window.open(`/invoice/edit/${booking.clientInvoice_id}`, "_blank");
                                                                        } else {
                                                                            setTrip(booking)
                                                                            setEditID(booking?._id)
                                                                            setModalForTripInvoice((prev)=>!prev)
                                                                    }
                                                                }}}
                                                        >
                                                            <TfiTwitter className="me-2 font-size-16" /> {t(`${!booking?.clientInvoice_id ? "Create Invoice" : "View Invoice "}`)}
                                                        </div>
                                                    
                                                    } */}

                                                    {(booking?.status == 0 || booking?.status == 1 || booking?.status == 2 || booking?.status == 3 ||
                                                        booking?.status == 4) && booking?.status == 5 && userContent?.rights?.includes(6003) &&

                                                        <div
                                                            className="zr_action_item"
                                                            onClick={(event) => onDelete(event, booking._id)}
                                                        >
                                                            <div className="me-2">
                                                                <DeleteLink
                                                                />
                                                            </div>
                                                            Delete Trip
                                                        </div>

                                                    }
                                                    {
                                                        <>

                                                            <div className="zr_action_item" onClick={() => {
                                                                setTrip(booking)
                                                                navigate(`/booking/edit/${booking?._id}?clone_trip_id=${booking?._id}&return=${encodeURIComponent(`${window.location?.pathname}${window.location.search}`)}`)
                                                                setEditID(booking?._id);
                                                                setShowDetailPage(true);
                                                                setBillingUpdated(false);
                                                            }}  >
                                                                <div className="me-2">
                                                                    <FaClone />
                                                                </div>
                                                                Clone Booking
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </>
                                        )
                                    }}
                                />
                            </Layout.Table>
                            <Layout.DetailPageTitle>
                                <div >
                                    {trip?._id && !isCloning() ? (
                                        <div>
                                            <div className="d-flex justify-content-centre align-items-center">
                                                <div>
                                                    {t(`Edit Booking `)}
                                                    <span>{"  #"}{trip?.tripNo}</span>
                                                </div>
                                                {/* <div className="status-info " style={{
                                        border: `1px solid ${statusInfo.color}`,
                                        borderColor: statusInfo.color,
                                        color: statusInfo.color,
                                        cursor: 'pointer'
                                    }}>
                                        <span className="dot" style={{ backgroundColor: statusInfo.color, marginTop: '0px' }}></span>
                                        {statusInfo.text}
                                    </div> */}
                                                <BookingStatusModifier
                                                    apiMethod={onStatusChange}
                                                    currentStatus={trip?.status}
                                                    trip_id={editID}
                                                    // statusInfo={statusInfo}
                                                    statusMapping={statusMapping}
                                                />
                                            </div>

                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center flex-1">
                                                    <div className="mt-3">
                                                        <span style={{ color: '#999999' }}>
                                                            {t(`Created At`)}
                                                        </span>
                                                        <span>
                                                            {"  "}
                                                            {moment.unix(trip?.createdAt).format("DD-MM-YYYY  hh:mm A")}
                                                        </span>
                                                    </div>
                                                    <div style={{ marginLeft: '15px' }}>
                                                        {trip?.tripExtn && trip?.tripExtn?.updatedAt &&
                                                            <div className="mt-3">
                                                                <span style={{ color: '#999999' }}>
                                                                    {t(`Updated At`)}
                                                                </span>
                                                                <span>
                                                                    {"  "}
                                                                    {moment.unix(trip?.tripExtn?.updatedAt).format("DD-MM-YYYY  hh:mm A")}
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                    <TagPicker
                                                        className="ms-2"
                                                        kind={"trip"}
                                                        onChange={(v) => setTrip({
                                                            ...trip,
                                                            tags: v
                                                        })}
                                                        value={trip?.tags}
                                                    />
                                                </div>
                                                <InputField
                                                    outline
                                                    placeholder="Duty Slip No."
                                                    label="Duty Slip No."
                                                    value={trip?.dutySlipNo}
                                                    onChange={(v) => handleTripChange("dutySlipNo", v)}
                                                    type="text"
                                                    className="input-group-prepend mx-1 col-3"
                                                />
                                                <BookingFooterDropdown className="mt-1">
                                                    <>
                                                        {trip?._id ? <DropdownItem>
                                                         <div onClick={printInvoice} className="d-flex gap-2">
                                                            <div><BiPrinter size={20} /></div>
                                                            <b className="align-self-center">{t(`Print`)}</b>   
                                                         </div>
                                                        </DropdownItem> : null}

                                                        {trip?._id ? <DropdownItem>
                                                            <div onClick={toggleMessageModal} className="d-flex gap-2">
                                                                <div><FaRegMessage size={20} /></div>
                                                                <b className="align-self-center">{t(`Send Message`)}</b>
                                                            </div>
                                                        </DropdownItem> : null}
                                                        {trip?._id ? <DropdownItem>
                                                            <AuditLogDialog 
                                                                modules={[
                                                                    {key:"Trip",value:trip?._id},
                                                                    {key:"Billing",value:[clientBilling,...supplierBillings]?.map(billing=>billing?._id)?.filter(Boolean)}
                                                                ]} 
                                                                 />
                                                        </DropdownItem> : null}
                                                        {editID ? <DropdownItem>
                                                            <div  onClick={() => setShowFeedback(true)} className="d-flex gap-2">
                                                                <div><MdOutlineFeedback size={20} /></div>
                                                                <b className="align-self-center">{t(`Feedback`)}</b>
                                                            </div>
                                                        </DropdownItem> : null}
                                                        {trip?._id ? <DropdownItem>
                                                            <div onClick={() => CommentService.toggleModal({ recordType: "trip", recordOf: trip?._id })} className="d-flex gap-2">
                                                                <div><MdOutlineInsertComment size={20} /></div>
                                                                <b className="align-self-center">{t(`Comments`)}</b>
                                                            </div>
                                                        </DropdownItem> : null}
                                                        {editID && !queryParams.has('clone_trip_id') ? <DropdownItem>
                                                            <div  onClick={onCloneClick} className="d-flex gap-2">
                                                                <div><FaRegClone size={20} /></div>
                                                                <b className="align-self-center">{t(`Clone Booking`)}</b>
                                                            </div>
                                                        </DropdownItem> : null}
                                                    </>
                                                </BookingFooterDropdown>
                                            </div>
                                        </div>

                                    ) : <div className="d-flex align-items-center">
                                        <div> {t(`${isCloning() ? 'Clone' : 'Add'} Booking`)}</div>
                                        <TagPicker
                                            className="ms-2"
                                            kind={"trip"}
                                            onChange={(v) => setTrip({
                                                ...trip,
                                                tags: v
                                            })}
                                            value={trip?.tags}
                                        />
                                    </div>}
                                </div>
                            </Layout.DetailPageTitle>

                            <Layout.DetailPageBody>
                                <EditBooking
                                    ref={billingRef}
                                    detailLoading={detailLoading}
                                    customerType={customerType}
                                    setCustomerType={setCustomerType}

                                    editId={editID}
                                    hasErr={hasErr}
                                    trip={trip}
                                    setTrip={React.useCallback((v) => {
                                        setTrip(v)
                                    }, [])}
                                    clientBilling={clientBilling}
                                    setClientBilling={setClientBilling}
                                    supplierBillings={supplierBillings}
                                    setSupplierBillings={setSupplierBillings}
                                    billingUpdated={billingUpdated}
                                    setBillingUpdated={(v) => {
                                        setBillingUpdated(v)
                                    }}
                                    billingUpdatedObject={billingUpdatedObject}
                                    setBillingUpdatedObject={(v) => {
                                        setBillingUpdatedObject((prev) => {
                                            return { ...prev, ...v }
                                        })
                                    }}
                                />
                            </Layout.DetailPageBody>
                            <Layout.DetailPageFooter>
                                {editID ? (
                                    <DeleteButton
                                        loading={loading}
                                        onClick={(e) => {
                                            onDelete(e, editID);
                                        }}
                                    />) : null}
                                <div className="d-flex align-items-center">
                                    {showInvoiceCreate() ?
                                        <div className="d-flex justify-content-end my-2">
                                            <Button
                                                onClick={toggleSingleTripModalForInvoice}
                                                className="mx-2"
                                                style={{ height: "28px", padding: "2px 10px 2px 4px" }}
                                                color="info"
                                            >
                                                <div className="d-flex justify-content-center" style={{ margin: "2px" }}>{t(`${!trip?.clientInvoice_id ? "Create Invoice" : "Show Invoice "}`)}</div>
                                            </Button>
                                        </div> : ""}

                                    {
                                        ([1, 2, 3, 4, 5]?.includes(trip?.status) && !queryParams.has('clone_trip_id') && userContent?.rights?.includes(6002) && !trip?.clientInvoice_id) &&
                                        <Button
                                            className="mx-2 px-2"
                                            style={{ padding: "3px 10px 3px 4px", }}
                                            color="success"
                                            onClick={onDutyClose}>
                                            <div className="d-flex aling-items-center">
                                                {
                                                    loading ? <ThreeBounce size={10} color="#FFFFFF" className="px-4" /> : <>
                                                        <ImHammer2 className="flip-horizontal" />
                                                        <div className="ms-2 d-flex justify-content-center">{t(`Duty Close`)}</div>

                                                    </>
                                                }
                                            </div>
                                        </Button>
                                    }
                                    {userContent?.rights?.includes(6002) && ((!trip?.clientInvoice_id && !isCloning() || isCloning())) && <SaveButton disabled={(trip?.clientInvoice_id && !isCloning()) ? true : false} loading={loading} onClick={onSave} title={isCloning() ? "Clone" : "Save"} />}
                                </div>
                            </Layout.DetailPageFooter>
                        </Layout>
                    </div>
                </div>
                {showMessageModal ? <MessageDialog trip={trip} modal={showMessageModal} toggleModal={() => { toggleMessageModal() }} /> : null}
                <PrintDuty
                    modal={printModal}
                    setModal={setPrinttModal}
                    trip_ids={selectedIDs}
                    loading={loading}
                    onSuccess={() => setSelectedIDs([])}
                    setLoading={setLoading}
                />

                {showCustomizationOptions ? <CustomOperations
                    isModal
                    module="trip"
                    isOpen={showCustomizationOptions}
                    toggleModal={() => { setShowCustomizationOptions(false) }}
                /> : null
                }

                {showFeedback &&
                    (<ModalComponent
                        position={"top"}
                        size={"large"}
                        onToggle={() => {
                            setShowFeedback(false)
                        }}
                        isOpen={showFeedback}
                    >
                        <ModalComponent.Title>
                            {t("FeedBacks")}
                        </ModalComponent.Title>

                        <ModalComponent.Body>
                            <Feedback
                                recordType="trip"
                                recordOf={trip._id}
                                insidePane={true}
                                multiMode={true}
                            />
                        </ModalComponent.Body>

                    </ModalComponent>)
                }
                {vehicleModal && (
                    <ModalComponent
                        position={"top"}
                        size={"large"}
                        onToggle={() => setVehicleModal(false)}
                        isOpen={vehicleModal}
                    >
                        <ModalComponent.Title>
                            {t("Vehicle")}
                        </ModalComponent.Title>

                        <ModalComponent.Body>
                            <Vehicle
                                value={value}
                                insidePane={true}
                                onSelect={(val) => {
                                    setVehicle(val?._id)
                                    setVehicleModal(false)
                                }
                                }
                                fullHeight
                            />
                        </ModalComponent.Body>

                    </ModalComponent>
                )}
                {driverModal && (
                    <ModalComponent
                        position={"top"}
                        size={"large"}
                        onToggle={() => setDriverModal(false)}
                        isOpen={driverModal}
                    >
                        <ModalComponent.Title>
                            {t("Employee")}
                        </ModalComponent.Title>

                        <ModalComponent.Body>
                            <Employee
                                value={driver}
                                insidePane={true}
                                onSelect={(val) => {
                                    setDriver(val?._id)
                                    setDriverModal(false);
                                }}
                                fullHeight

                            />
                        </ModalComponent.Body>

                    </ModalComponent>
                )}
                <BulkInvoice
                    onSave={(invoicedTripIds) => {
                        // updateBooking(invoicedTripIds);
                        fetchGrid(filterURL);//updateBooking not working so changing it for fetchGrid for now
                    }}
                    visible={modal && selectedTripsIdsForInvoice.length > 0}
                    onClose={toggleModal}
                    selectedIDs={selectedTripsIdsForInvoice}
                />
                {modalForTripInvoice && showInvoiceCreate() && (
                    <ModalComponent
                        position={"top"}
                        size={"full-screen"}
                        onToggle={toggleSingleTripModalForInvoice}
                        isOpen={modalForTripInvoice}
                    >
                        <ModalComponent.Title>
                            {t("Create Invoice")}
                        </ModalComponent.Title>

                        <ModalComponent.Body>
                            <InvoiceForm
                                trip_ids={[editID]}
                                //  validateFeegroup,
                                onSave={(invoicedTripIds) => {
                                    // updateBooking(invoicedTripIds);
                                    fetchGrid(filterURL);//updateBooking not working so changing it for fetchGrid for now
                                }}
                                concise
                                toggleModal={toggleSingleTripModalForInvoice}
                            />
                        </ModalComponent.Body>
                    </ModalComponent>)
                                    }
            </>
        </React.Fragment>
    );
};
export default observer(Bookings);
