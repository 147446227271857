import React, { useRef, useState } from 'react';
import { RiFlag2Fill, RiFlag2Line } from 'react-icons/ri';
import './PriorityDropdown.scss';
import { useOutsideAlerter } from '../dnd/board/helper';
import { FaBan } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';

const PriorityDropdown = ({ value, onChange, toolTipId }) => {

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    useOutsideAlerter(dropdownRef, () => setIsOpen(false));

    const priorityColor = {
        1: "red",
        2: "orange",
        3: "blue",
        4: "grey",
    }

    const priorityName = {
        1: "Urgent",
        2: "High",
        3: "Normal",
        4: "Low",
    }

    const handleSelect = (priority) => {
        onChange(priority);
        setIsOpen(false);
    };

    return (
        <div className='d-flex'>

            <div className="zr_priority_dropdown" ref={dropdownRef}>
                <Tooltip
                    style={{
                        zIndex: '200000',
                    }}
                    anchorSelect={`#not-clickable-${toolTipId}-priority`}
                >
                    <div>
                        {priorityName[value] ?? ""} Priority
                    </div>
                </Tooltip>

                <div id={`not-clickable-${toolTipId}-priority`} >
                    {value ? <RiFlag2Fill
                        className='ms-2'
                        size={14}
                        color={priorityColor[value]}
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsOpen(!isOpen)
                        }}
                    /> :
                        <RiFlag2Line
                            className='ms-2'
                            size={14}
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsOpen(!isOpen)
                            }}
                        />}
                </div>

                {isOpen && (
                    <ul className="zr_priority_list">
                        {[1, 2, 3, 4].map((priority) => (
                            <li
                                key={priority}
                                className={`zr_priority_item ${value === priority ? 'zr_priority_selected' : ''}`}
                                onClick={() => handleSelect(priority)}
                            >
                                <RiFlag2Fill
                                    className='me-2'
                                    size={14}
                                    color={priorityColor[priority]}
                                />
                                {priorityName[priority]}
                            </li>
                        ))}
                        <hr className='my-0' />
                        <li
                            className={`zr_priority_item `}
                            onClick={() => handleSelect(0)}
                        >
                            <FaBan className='me-2' size={14} color={"#707070"} />
                            Clear
                        </li>
                    </ul>
                )}

            </div>

        </div>);
};

export default PriorityDropdown;
