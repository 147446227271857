import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./AccessLogConstant";
import { doGET } from "../../util/HttpUtil";

class Service {
  records = [];
  totalRecords = 0;
  page = 1;
  rows = 20;
  constructor() {
    makeAutoObservable(this);
  }

  fetch = async function (filterUrl) {
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rows, filterUrl)
    );
    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }
  };

  // future refference
  //   get = async function (id) {
  //     const response = await doGET(ENDPOINTS.get(id));

  //     if (response?.status === 200) {
  //       return response.data;
  //     }
  //   };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch();
  };
}

const AccessLogService = new Service();
export default AccessLogService;
