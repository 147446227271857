import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Col, Row } from "reactstrap";
import "toastr/build/toastr.min.css";
import {
  CardThreeBounce,
  EditLink,
  Layout,
  SaveButton,
  itemBarSelectedStyle,
} from "../../components";
import RedirectLink from "../../components/Buttons/Icons/redirectbutton";
import "../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import EditCorpSubs from "../CorpSubs/EditCorpSubs";
import { STRUCTURE } from "./CorporateConstant";
import CorporateService from "./CorporateService";
import CorpSubsService from "../CorpSubs/CorpSubsService";
import CreateCorporate from "./CreateCorporate";
import EditCorporates from "./EditCorporates";

const Corporates = (props) => {
  const { t } = useContext(I18nContext);
  const { id } = useParams();
  const [data, setData] = useState({});
  const [showErr, setShowErr] = useState(false);
  const { showMessage, showConfirm, showError } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [selectDetailPage, setSelectPage] = useState(false);
  const [showDetailPage, setShowDetailPage] = useState(id);
  const [loading, setLoading] = useState(false);
  const [isEditDataChanged, setIsEditDataChanged] = useState(false);
  const [showSubsPage, setShowSubsPage] = useState(false);

  const fetchData = async (filterUrl) => {
    if (loading) return;
    setLoading(true);
    try {
      await CorporateService.fetch(filterUrl);
      console.log("corporate DATA: ", CorporateService);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);

    setShowDetailPage(window.location.pathname === "/corporate/edit" || id);
  }, [id]);

  const saveSubscription = async (e) => {
    e.preventDefault();
    setShowErr(true);
    setLoading(true);
    try {
      await CorpSubsService.save(data);
      showMessage("Subscription saved successfully");
      fetchData();
    } catch (e) {
      showError(e);
    }
    fetchData();
  };

  const onSave = async (e) => {
    e.preventDefault();
    setShowErr(true);
    if (!data.unique_id) return;
    try {
      if (id) {
        await CorporateService.edit2(data, id);
        showMessage("Corporate saved successfully");
      } else {
        await CorporateService.save(data);
        showMessage("Corporate saved successfully");
        setData({});
        setShowDetailPage(false);
        navigate(`/corporate`, { replace: true });
      }
    } catch (error) {
      // showMessage(
      //   "error",
      //   JSON.parse(error.message).customMessage,
      //   "Duplicate"
      // );
      showError(error);
    }
    fetchData();
  };

  if (loading) return <CardThreeBounce />;
  return (
    <Layout
      showDetailPage={showDetailPage}
       
      title={t("Corporates")}
      filterStructure={STRUCTURE}
      onApplyFilter={fetchData}
      backDetailPage={() => {
        setShowDetailPage(false);
        if (!props?.insidePane) navigate("/corporate");
        setSelected_id(false);
        setShowSubsPage(false);
        setData({})
      }}
      onAddClick={() => {
        if (!props?.insidePane) navigate(`/corporate/create`);
        setSelectPage(true);
        setShowDetailPage(true);
        setSelected_id(null);
      }}
      page={CorporateService.page}
      rows={CorporateService.rows}
      total={CorporateService.totalRecords}
      onPageChange={async (page, rows) => {
        await CorporateService.onPaginationChange(page, rows);
      }}
    >
      <Layout.TheadFull>
        <Thead className="thead-dark">
          <Tr>
            <Th>{t("UniqueId")}</Th>
            <Th>{t("Name")}</Th>
            <Th>{t("Billing Trips")}</Th>
            <Th>{t("Didn't Run")}</Th>
            <Th>{t("SMS")}</Th>
            <Th>{t("Domain")}</Th>
            <Th>{t("B2C Domain")}</Th>
            <Th className="float-end">{t("Action")}</Th>
          </Tr>
        </Thead>
      </Layout.TheadFull>

      <Layout.TbodyFull>
        <Tbody>
          {CorporateService.records?.map((corporate, idx) => (
            <Tr
              key={idx}
              style={id === corporate?._id ? itemBarSelectedStyle : {}}
            >
              <Td>{corporate?.unique_id}</Td>
              <Td scope="row">{corporate?.fullName}</Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td>
                <EditLink
                  onClick={() => {
                    if (!props?.insidePane)
                      navigate(`/corporate/edit/${corporate?._id}`);
                    setSelected_id(corporate?._id);
                    setShowDetailPage(true);
                    setSelectPage(false);
                    setShowSubsPage(false)

                  }}
                />
                <RedirectLink
                  onClick={() => {
                    if (!props?.insidePane)
                      navigate(`/corporate/edit/${corporate?._id}`);
                    setShowDetailPage(true);
                    setShowSubsPage(true)
                    setSelectPage(false);

                  }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Layout.TbodyFull>

      <Layout.DetailPageTitle>
        {/* <Row>
          <Row className="mb-2 py-2 border-bottom-1">
            <Col>
              <img
                src={data?.pic}
                height="40px"
                width="50px"
                style={{ objectFit: "cover" }}
              />
            </Col>
            <Col>
              <div>{data?.name}</div>
              <div>{data?.addr?.city}</div>
            </Col>
          </Row>
        </Row> */}
        <h5>corporates</h5>
      </Layout.DetailPageTitle>
      <Layout.DetailPageBody>
        {showSubsPage ?
          <EditCorpSubs
            insidePane={props?.insidePane}
            onChange={(v) => setIsEditDataChanged(v)}
            id={selected_id}
            hasErr={showErr}
            setHasErr={setShowErr}
            setData={setData}
            data={data}
            fetchData={fetchData}
          />
          : selectDetailPage ? (
            <CreateCorporate
              insidePane={props?.insidePane}
              onChange={(v) => setIsEditDataChanged(v)}
              id={selected_id}
              data={data}
              showErr={showErr}
              setShowErr={setShowErr}
              setData={setData}
              fetchData={fetchData}
            />
          ) : (
            <EditCorporates
              insidePane={props?.insidePane}
              onChange={(v) => setIsEditDataChanged(v)}
              id={selected_id}
              data={data}
              showErr={showErr}
              setShowErr={setShowErr}
              setData={setData}
              fetchData={fetchData}
            />
          )}
      </Layout.DetailPageBody>
      <Layout.DetailPageFooter>
        <Row>
          <Col>
            <div
              className="d-flex justify-content-end mt-1"
              style={{ marginTop: "-2.5rem" }}
            >
              <SaveButton onClick={showSubsPage ? saveSubscription : onSave} />
            </div>
          </Col>
        </Row>
      </Layout.DetailPageFooter>
    </Layout>
  );
};

export default observer(Corporates);
