import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { ENDPOINTS } from "./TripJourneyGapConstant";
import { getUiPrefRows } from "../../util/FilterUtil";
class Service {
    records = [];
    STRUCTURE = [];
    page = 1;
    rowsPerPage = null;
    filterOption = "";
    total = -1;
    version = 0;
    rateAreaCache = [];

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async (filterUrl) => {
        if (!this?.rowsPerPage) {
            const rows = await getUiPrefRows("rateArea.grid")
            this.rowsPerPage = rows ?? 40
        }
        if (filterUrl) {
            this.filterOption = filterUrl;
        }
        const response = await doGET(
            ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl)
        );

        if (response?.status === 200) {
            this.records = response.data.rows;
            this.page = response?.data?.page;
            if (this.page === 1) this.total = response?.data?.total;
        }
    };
    fetchAll = async function (props) {
        if (this.rateAreaCache.length > 0) {
            return this.rateAreaCache;
        }
        try {
            const response = await doGET(ENDPOINTS.grid());
            if (response.status === 200) {
                const formattedRateArea = response.data.rows.map((k) => { return { label: k.name, value: k._id } });
                this.rateAreaCache = formattedRateArea;
                return formattedRateArea;
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };

    edit = async function (data, id) {
        const response = await doPUT(ENDPOINTS?.update, data);
        if (response.status === 200) {
            this.version += 1;
            this.rateAreaCache = [];
            this.fetch()
        }
    };

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    save = async (data) => {
        this.records.push(data);
        const response = await doPOST(ENDPOINTS.save, data);
        this.rateAreaCache = [];
        this.fetch()
        this.fetchAll()
        return response?.data?._id;
    };

    delete = async (id) => {
        const response = await doDELETE(ENDPOINTS.delete(id));
        if (response.status === 200) {
            this.fetch();
            this.rateAreaCache = [];
        }
    };

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch(this.filterOption);

    };

}
const TripJourneyGapService = new Service();
export default TripJourneyGapService;



