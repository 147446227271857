import { doGET } from "./HttpUtil";

export const prepareFilterArtifacts = (queryParams, STRUCTURE, insidePane, filterUrlProp) => {
    let filterObject = {};
    let filterUrl = "";

    if (filterUrlProp) {
        const params = new URLSearchParams(filterUrlProp);
        params.forEach((value, key) => {
            filterObject[key] = value;
        });

        if (filterUrl) filterUrl += "&";
        filterUrl += filterUrlProp;
    }
    
    if (insidePane) {
        return { filterObject, filterUrl };
    }

    STRUCTURE?.forEach((filter) => {
        const { filterName, type } = filter;
        const filterValue = queryParams?.get(filterName);

        if (type === "sorting") {
            const sortBy = queryParams?.get("sortBy");
            const sortAsc = queryParams?.get("sortAsc");

            if (sortBy && sortAsc !== null) {
                filterObject[filterName] = { sortBy, sortAsc: sortAsc === 'true' };
                if (filterUrl) filterUrl += "&";
                filterUrl += `sortBy=${sortBy}&sortAsc=${sortAsc}`;
            }
        } else {
            if (filterValue) {
                filterObject[filterName] = filterValue;
                if (filterUrl) filterUrl += "&";
                filterUrl += `${filterName}=${filterValue}`;
            }
        }
    });

  

    return { filterObject, filterUrl };
};



export const getUiPrefRows = async (uiPreference) => {
    try {
        const response = await doGET("/api/ui-preference/" + `${uiPreference}.rows` + "/detail");
        if (response?.status == 200 && response?.data?.value) {
            return response.data?.value;
        }
    } catch (error) {
        return null
    }

    return null
}

export const  extractIdsFromInFilter = (inputString) => {
    // "in[...]" patterns
    const regex = /in\[([a-fA-F0-9,]+)\]/g;
    let matches = [];
    let match;

    while ((match = regex.exec(inputString)) !== null) { 
        matches = matches.concat(match[1].split(','));
    }
    return matches;
};
