import React, { useContext, useImperativeHandle, useRef, useState } from 'react'
import { DropdownItem, DropdownMenu } from 'reactstrap';
import { DialogContext } from '../../store/context/DialogContext';
import BookingService from '../../pages/Booking/BookingService';
import { I18nContext } from '../../store/context/i18nContext';
import BulkUpload from '../BulkUpload/BulkUpload';
import { observer } from 'mobx-react-lite';
import DownloadByTemplate from '../DownloadByTemplate/DownloadByTemplate';
import { STRUCTURE } from '../../pages/Booking/BookingConstant';
import PrintDuty from '../../pages/Booking/PrintDuty';
import BulkInvoice from '../BulkInvoice';
import { areTripsValidForInvoice } from '../../helpers/general';
import BulkModification from '../BulkModification';

const LayoutActionMenu = React.forwardRef(({
    fetchGrid,
    selectedIDs,
    setSelectedTripIdsForInvoice,
    setSelectedIDs,
    filterObject,
    selectedTripsIdsForInvoice,
    tripStatus }
    , ref) => {
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const { t } = useContext(I18nContext)
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(null);
    const [showBulkInvoiceModal, setShowBulkInvoiceModal] = useState(false);
    const bulkInvoiceRef = useRef({
        modalVisible: false,
        selectedTripsIdsForInvoice: [],
    })

    const clearSelection = () => {
        setSelectedTripIdsForInvoice([]);
        setSelectedIDs([])
    }

    useImperativeHandle(ref, () => ({
        //logic for adding selectedIds in selectedTripIdsForInvoice
        //here remove the ref and use state selectedTripIdsForInvoice and showBulkInvoiceModal
        async updateSelectedIdsForInvoice(v) {
            try {
                if (bulkInvoiceRef.current.modalVisible || bulkInvoiceRef.current.selectedTripsIdsForInvoice?.length) {
                    //here send only those ids which are not in selectedTripsIdsForInvoice as the one which are in selectedTripsIdsForInvoice are already selected to avoid extra data in api call
                    const alreadyPresentTripsIds = new Set(bulkInvoiceRef.current.selectedTripsIdsForInvoice);
                    const newSelectedIds = v?.filter(value => !alreadyPresentTripsIds.has(value));

                    const deletedValues = bulkInvoiceRef.current.selectedTripsIdsForInvoice?.filter(value => !v?.includes(value));
                    if (!newSelectedIds?.length) {
                        //all values are already there so update values which one are coming as all are valid (previously checked)
                        bulkInvoiceRef.current.selectedTripsIdsForInvoice = v;
                        if (!v.length) {
                            bulkInvoiceRef.current.modalVisible = false;
                            setShowBulkInvoiceModal(false);
                        }
                        setSelectedTripIdsForInvoice(v)
                        return;
                    }
                    //add one trip_id from prev selectedTripsIdsForInvoice to match selected client purpose
                    const tripIds = [bulkInvoiceRef.current.selectedTripsIdsForInvoice[0], ...newSelectedIds];
                    const trips = await BookingService.postGrid(`_id=in[${(tripIds ?? [])?.filter(Boolean)?.join(',')}]&rows=-1`);
                    const { isValid, message } = areTripsValidForInvoice(trips, tripIds)
                    if (!isValid) {
                        if (deletedValues?.length) {
                            bulkInvoiceRef.current.selectedTripsIdsForInvoice = bulkInvoiceRef.current.selectedTripsIdsForInvoice?.filter(value => !deletedValues?.includes(value));
                            setSelectedTripIdsForInvoice(bulkInvoiceRef.current.selectedTripsIdsForInvoice)
                        }
                        showError(t(message));
                        return;
                    }
                    const updatedTripIds = Array.from(new Set([...v]));
                    bulkInvoiceRef.current.selectedTripsIdsForInvoice = updatedTripIds;
                    setSelectedTripIdsForInvoice(updatedTripIds)
                }
            } catch (error) {
                showError(error);
            }
        }
    }))

    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                const response = await BookingService.bulkDelete(selectedIDs);
                if (response?.err) {
                    showError(response?.err);
                } else {
                    showMessage(t("Deleted"), t("Deleted Successfully"));
                    clearSelection && clearSelection()
                }
            } catch (e) {
                showError(e);
            } finally {
                setLoading(false);
            }
        }
    };

    const saveBulk = async (data) => {
        try {
            const booking = await BookingService.bulkUpload(data);
            if (booking) {
                return { isUploaded: true, message: "" }
            }
            return { isUploaded: false, message: "Failed unexpectedly" }
        } catch (error) {
            return { isUploaded: false, message: JSON.parse(error?.message)?.customMessage ?? JSON.parse(error?.message)?.message }

        }
    };

    const toggleDownload = () => {
        setModal(prev => {
            // if (!prev) {
            //     BookingService.setDownloadFilter(BookingService.filterOption)
            // }
            if (!prev) {
                let filterUrlParams = new URLSearchParams(BookingService.filterOption)
                const queryParams = new URLSearchParams(window.location.search)
                if (queryParams.has('sortBy')) {
                    // filterUrl += `sortBy=${queryParams.get('sortBy')}`;
                    filterUrlParams.set('sortAsc', queryParams.get('sortAsc') ?? false);
                    // filterUrl += `&sortAsc=${queryParams.get('sortAsc') ?? false}`;
                }
                const statusFilter = tripStatus?.value !== undefined && tripStatus?.value !== 100 ? `&status=${tripStatus.value}` : '';
                if (statusFilter) {
                    filterUrlParams.set('status', statusFilter)
                }
                // filterUrl += `${statusFilter}`
                BookingService.setDownloadFilter(filterUrlParams.toString())
            }
            return "download"
        });
    };

    const toggleBulkInvoiceModal = async () => {
        if (showBulkInvoiceModal) {
            setShowBulkInvoiceModal(prev => {
                bulkInvoiceRef.current.modalVisible = false;
                bulkInvoiceRef.current.selectedTripsIdsForInvoice = []
                return !prev
            })
            clearSelection && clearSelection()
            fetchGrid();
            return;
        }
        if (!selectedTripsIdsForInvoice?.length && !selectedIDs.length) {
            showError(t("Select Trips For Creating Bulk Invoice"));
            return;
        }
        let selectedTripIds = [...selectedTripsIdsForInvoice];
        if (!selectedTripIds?.length) { //if not present in selectedTripsIdsForInvoice ( ie before modal is opened)
            selectedTripIds = [...selectedIDs];
        }
        const ids = selectedTripIds?.length > 0 ? selectedTripIds : [];
        const trips = await BookingService.postGrid(`_id=in[${(ids ?? [])?.filter(Boolean)?.join(',')}]&rows=-1`);
        const { isValid, message } = areTripsValidForInvoice(trips, selectedTripIds);
        if (!isValid) {
            showError(t(message));
            return;
        }
        if (!selectedTripsIdsForInvoice.length) {//for first when scenerios when modal is not empty
            setSelectedTripIdsForInvoice(selectedTripIds);
            bulkInvoiceRef.current.selectedTripsIdsForInvoice = selectedTripIds
        }
        setShowBulkInvoiceModal(prev => {
            bulkInvoiceRef.current.modalVisible = !prev;
            return !prev
        });
    }

    const toggleModals = (modalType = null) => setModal(modalType)

    return (
        <>
            <div className="layout-action-menu">
                <DropdownMenu>
                    <>
                        <DropdownItem onClick={() => { onBulkDelete() }}  >{t("Bulk Delete")}</DropdownItem>
                        <div class="dropdown-divider"></div>
                        <DropdownItem
                            onClick={() => {
                                if (!selectedIDs || selectedIDs.length == 0) {
                                    showError("No trip selected");
                                    return;
                                }
                                setModal('print');
                            }}
                        >
                            {t("Print Duty")}
                        </DropdownItem>
                        <div class="dropdown-divider"></div>
                        <DropdownItem onClick={() => toggleModals("upload")}>
                            {t("Bulk Upload")}
                        </DropdownItem>
                        <div class="dropdown-divider"></div>
                        <DropdownItem onClick={toggleDownload}>{t("Download")}</DropdownItem>
                        <div class="dropdown-divider"></div>
                        <DropdownItem onClick={toggleBulkInvoiceModal}>
                            {t("Bulk Invoice")}
                        </DropdownItem>
                        <div class="dropdown-divider"></div>
                        <DropdownItem onClick={() => toggleModals("bulkModification")}>
                            {t("Bulk Modification")}
                        </DropdownItem>

                    </>
                </DropdownMenu>
            </div>

            <PrintDuty
                modal={modal === "print"}
                setModal={toggleModals}
                trip_ids={selectedIDs}
                loading={loading}
                onSuccess={clearSelection}
                setLoading={setLoading}
            />

            <BulkUpload
                show={modal === "upload"}
                onClose={toggleModals}
                title={t("Trip")}
                kind={"Trip"}
                upload={saveBulk}
            />

            {modal === "download" ? <DownloadByTemplate
                filter={filterObject}
                download={BookingService.downloadByTemplate}
                kind={"Trip"}
                onClose={toggleModals}
                show={modal === "download"}
                filterStructure={STRUCTURE}
                onApplyFilter={BookingService.setDownloadFilter}
            /> : null}

            <BulkInvoice
                visible={showBulkInvoiceModal && selectedTripsIdsForInvoice.length > 0}
                onClose={toggleBulkInvoiceModal}
                selectedIDs={selectedTripsIdsForInvoice}
            />


            <BulkModification
                visible={modal === "bulkModification"}
                onClose={toggleModals}
                selectedIDs={selectedIDs}
                onSave={() => { clearSelection(); fetchGrid() }}
            />

        </>

    )
});

export default observer(LayoutActionMenu)


// //not getting updated state here hence used ref
// if (bulkInvoiceRef.current.modalVisible || bulkInvoiceRef.current.selectedTripsIdsForInvoice?.length) {
//     //here send only those ids which are not in selectedTripsIdsForInvoice as the one which are in selectedTripsIdsForInvoice are already selected to avoid extra data in api call
//     const alreadyPresentTripsIds = new Set(bulkInvoiceRef.current.selectedTripsIdsForInvoice);
//     const newSelectedIds = v?.filter(value => !alreadyPresentTripsIds.has(value));

//     const deletedValues = bulkInvoiceRef.current.selectedTripsIdsForInvoice?.filter(value => !v?.includes(value));
//     if (!newSelectedIds?.length) {
//         //all values are already there so update values which one are coming as all are valid (previously checked)
//         bulkInvoiceRef.current.selectedTripsIdsForInvoice = v;
//         setSelectedTripIdsForInvoice(v)
//         return;
//     }
//     //add one trip_id from prev selectedTripsIdsForInvoice to match selected client purpose
//     const tripIds = [bulkInvoiceRef.current.selectedTripsIdsForInvoice[0], ...newSelectedIds];
//     const trips = await BookingService.postGrid(`_id=in[${(tripIds ?? [])?.join(',')}]`);
//     const { isValid, message } = areTripsValidForInvoice(trips, tripIds)
//     if (!isValid) {
//         if (deletedValues?.length) {
//             bulkInvoiceRef.current.selectedTripsIdsForInvoice = bulkInvoiceRef.current.selectedTripsIdsForInvoice?.filter(value => !deletedValues?.includes(value));
//             setSelectedTripIdsForInvoice(bulkInvoiceRef.current.selectedTripsIdsForInvoice)
//         }
//         setHasErr(true);
//         showError(t(message));
//         return;
//     }
//     const updatedTripIds = Array.from(new Set([...v]));
//     bulkInvoiceRef.current.selectedTripsIdsForInvoice = updatedTripIds;
//     setSelectedTripIdsForInvoice(updatedTripIds)
// }