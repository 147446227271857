import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { GrDocumentExcel } from "react-icons/gr";
import CustomTooltip from "../../../pages/Booking/components/CustomTooltip";
import Vehicle from "../../../pages/Vehicle/Vehicle";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import useDebounce from "../../../util/Debounce";
import { SelectField } from "../../SelectField/SelectField";
import { BottomBarButton, MakeModelSelect, ModalComponent, SearchButton, SupplierSelect } from "../../index";
import MakeModelService from "./../../../pages/MakeModel/MakeModelService";
import VehicleSelectService from "./VehicleSelectService";

const GroupedVehicleSelect = ({
  asLabel,
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  showClear,
  label,
  showErr,
  inline,
  outline,
  borderNone,
  innerContainerClassName,
}) => {
  const [vehicles, setVehicles] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const updateVehicles = () => {
    setVehicles(
      toJS(VehicleSelectService?.vehicles)?.map((vehicle, idx) => {
        return {
          ...vehicle,
          value: vehicle?._id,
          menuLabel: <div className="d-flex flex-column gap-1 justify-content-between flex-1" key={idx}>
            <div className="d-flex position-relative  justify-content-between flex-1 primary-text">
              <div>
              <SupplierSelect
                asLabel
                value={vehicle?.supplier_id}
              />
              </div>
              <div style={{position : "absolute", right : 0, top : 0}} className="d-flex">
                {vehicle?.complianceStatus == 2 ? (
                  <div>
                  </div>
                ) : (
                  <div>
                    <CustomTooltip>
                      <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                        <i id="not-clickable-isBooker-active" className="fas fa-check-circle font-size-12" style={{ color: 'green' }} />
                      </CustomTooltip.Body>
                      {/* <CustomTooltip.ToolTip position={"top"}>
                      <div>Compliant</div>
                    </CustomTooltip.ToolTip> */}
                    </CustomTooltip>
                  </div>
                )}
                {/* <div>{vehicle?.complianceStatus == 2 ? <GrDocumentExcel color="#CB4335" /> : <GrDocumentVerified color="#52BE80" />}</div> */}
              </div>

            </div>
            <div style={{ fontSize: "12px" }}> {vehicle?.regNo}</div>
            <div className="d-flex">
              <MakeModelSelect asLabel value={vehicle?.makeModel} />

            </div>
          </div>,
          label: <div className="d-flex flex-wrap gap-1 justify-content-between" key={idx}>
          <div className="d-flex col-12">
            <div className="col-6" style={{ fontSize: "12px" }}>
              {vehicle?.regNo}
            </div>
            <div className="col-6">
              <MakeModelSelect asLabel value={vehicle?.makeModel} />
            </div>
          </div>
          <div className="col-12 primary-text mb-1">
            <SupplierSelect asLabel value={vehicle?.supplier_id} />
          </div>
        </div>
        
        };
      })
    );
    // fetchVehicles();
  }

  const formatOptionLabel = ({ label, menuLabel }, { context }) => {
    return context === 'menu' ? menuLabel : label;
  };

  const fetchVehicles = async () => {
    if (!MakeModelService?.cacheMakeModel?.length) {
      MakeModelService?.fetch()
    }
    try {
      await VehicleSelectService.fetch();
      updateVehicles()
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (VehicleSelectService.vehicles.length === 0) fetchVehicles();
  }, []);

  useEffect(() => {
    updateVehicles()
    // fetchVehicles()
  }, [VehicleSelectService.vehicles]);

  const fetchAndUpdateVehicles = async (id) => {
    try {
      const vehicle = vehicles.find(vehicle => vehicle._id === id)
      if (!vehicle) {
        const vehicle = await VehicleSelectService.get(id);
        VehicleSelectService.pushNewData(vehicle);
        updateVehicles();
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (value && vehicles.length) {
      fetchAndUpdateVehicles(value)
    }
  }, [vehicles, value]);


  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectVehicle = (selectedValue) => {
    fetchVehicles();
    let selectedVehicle = toJS(selectedValue)
    const client = vehicles.find(vehicle => vehicle._id === selectedVehicle?._id)
    if (!client) {
      VehicleSelectService.pushNewData(selectedVehicle);
      updateVehicles()
    }
    onChange({ ...selectedValue, value: selectedValue?._id });
    closeModal();
  };

  const filterOption = useCallback((option, inputValue) =>
    !inputValue ||
    ['regNo'].some(
      key =>
        option.data?.[key]?.toLowerCase().includes(inputValue.toLowerCase())
    ),
    []);

  const fetchMoreData = () => {
    const page = VehicleSelectService.page + 1;
    const rowsPerPage = VehicleSelectService.rowsPerPage;
    const total = VehicleSelectService.total;
    if (page > 0 && page <= (total % rowsPerPage === 0 ? (total / rowsPerPage) : (total / rowsPerPage) + 1)) {
      VehicleSelectService.onPaginationChange(page, rowsPerPage);
    }
  }

  const fetchDataOnSearch = useDebounce((value) => {
    const filterUrl = `regNo=${value}`;
    VehicleSelectService.fetch(filterUrl);
  }, [500]
  );

  const renderSelectField = ({ disabled }) => (
    <SelectField
      grouping={true}
      filterOption={filterOption}
      style={style}
      outline={outline ?? false}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Vehicle")} />
      )}
      InputGroupChild={() => {
        return <div  className="d-flex justify-content-center align-items-center">
          {/* {!value && <i className="mdi font-size-20  mdi-car-hatchback"></i>} */}
          {
            toJS(VehicleSelectService?.vehicles)?.map((vehicle, idx) => {
              if (vehicle?._id == value)
                return <div style = {{minWidth : "20px"}} key={idx}>
                  <div className="px-2 d-flex">
                    <div >{vehicle?.complianceStatus == 2 ? <GrDocumentExcel color="#CB4335" /> :
                       <i  className="fas fa-check-circle font-size-14 me-2" style={{ color: 'green', backgroundColor: "transparent", }} /> }
                     </div>
                  </div>
                </div>;
            })
          }
        </div>
      }}
      inputGroupClass="assignedVehicleDriver"
      showBottomBar={true}
      className={className}
      onChange={onChange}
      data={vehicles}
      showClear={showClear}
      required={required}
      value={value}
      label={label || ((!isDisabled && !value) && t("Vehicle"))}
      error={!value ? t(`Please Select ${label || "Vehicle"}`) : ""}
      showErr={showErr}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton height={`${"34px"}`} onClick={openModal} />
      )}
      onMenuScrollToBottom={fetchMoreData}
      onInputChange={fetchDataOnSearch}
      formatOptionLabel={formatOptionLabel}
      innerContainerClassName={value ? innerContainerClassName : ``}

    />
  );


  return (
    <>
      {asLabel ? (<div>{vehicles?.find((vehicle) => vehicle?.value == value)?.label ?? <div style={{ color: "grey" }}>No record Found</div>}</div>) :
        <>
          {modal && (
            <ModalComponent
              position={"top"}
              size={"large"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Vehicle")}
              </ModalComponent.Title>
              <ModalComponent.Body>
                <Vehicle
                  value={value}
                  insidePane={true}
                  onSelect={selectVehicle}
                />
              </ModalComponent.Body>
            </ModalComponent>
          )}
          {vehicles.length > 0 ? renderSelectField({ disabled: false }) : renderSelectField({ disabled: true })}
        </>
      }
    </>
  );
};

export default observer(GroupedVehicleSelect);
