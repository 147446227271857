import React, { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { doGET, doPOST } from '../../../../util/HttpUtil';
import { DialogContext } from '../../../../store/context/DialogContext';


function EntityButton({ id, entity }) {
    const { showError, showMessage, showLogsP, setShowLogsP } = useContext(DialogContext);


    const [conf, setConf] = useState(false);
    const handleClick = async () => {

        setShowLogsP((showLogsP) => !showLogsP)

        try {
            let res;
            res = await doGET(`/api/v1/audit/${entity}/${id}/grid`);

            if (res === "success") {

                showMessage("Welcome");
                showLogs(true);

            }
            else {
                showError("Something went wrong");
            }
        } catch (error) {
            showError(error)
        }
    };
    const showLogs = (e, config) => {
        e.preventDefault();
        setShowLogsP(true);
        setConf(config);
    }
    //  logsGrid: (id, conf) => conf ? `/api/v1/audit/CorporateConfig/${id}/grid` : `/api/v1/audit/Corporate/${id}/grid`,
    return (
        <>
            <Button className="mx-2 my-2" style={{ height: "28px", padding: "2px 4px" }} color="warning"
                onClick={handleClick}
            >
                <i style={{ fontSize: "18px", marginTop: "2px", marginRight: "5px" }} className="uil uil-database"></i>
                <div style={{ margin: "2px", float: "right" }}>Audit</div>
            </Button>
        </>
    );
}

export default EntityButton;