import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { BsPersonVideo2 } from "react-icons/bs";
import {
    Row
} from "reactstrap";

import driverIcon from '../../../assets/images/icons/driver.svg';
import guardIcon from '../../../assets/images/icons/guard.svg';
import guideIcon from '../../../assets/images/icons/tour-guide.svg';
import { I18nContext } from "../../../store/context/i18nContext";
import { AddressField, Checkbox, InputField, LanguageSelect, PhoneNum } from "../../index";
import EmployeeService from "../../../pages/Employee/EmployeeService";
import { DialogContext } from "../../../store/context/DialogContext";
const EmployeeInfo = ({ editId, onChange = () => { }, hasErr }) => {

    const { t } = useContext(I18nContext);
    const [data, setData] = useState({});
    const { showError } = useContext(DialogContext);


    const fetch = async () => {
        try {
            let employess = await EmployeeService.get(editId);
            setData({
                ...employess,
                status: employess?.status ? employess?.status : 0,
                complianceStatus: employess?.complianceStatus ? employess?.complianceStatus : 0,
                phoneNo: {
                    ...employess.phoneNo,
                    countryCode: employess?.phoneNo?.countryCode ? employess?.phoneNo?.countryCode : 91,
                }
            });
        } catch (error) {
            showError(error)
        }
    };


    useEffect(() => {
       if(editId) fetch()
    }, [editId])

    return (
        <div>
            <Row className="mb-3 ">

                <InputField
                    placeholder={t("Full Name")}
                    plain
                    value={data?.name}
                    // required={true}
                    // label={t("Brand Name")}
                    style={{ fontSize: "35px", height: "45px" , marginTop : "0px !important" }}
                    onChange={(v) => setData({ ...data, name: v })}
                    noLabel
                    error={data?.name?.length === 0 ? t("Please enter Name") : ""}
                    type="text"
                    className="col-sm-12"
                />

                
                <PhoneNum
                    isDisabled
                    label={t('Phone')}
                    required={true}
                    outline
                    name="phone"
                    value={data?.phoneNo}
                    className="col-sm-12 mt-2"
                    onChange={(value) =>
                        onChange({
                            ...data,
                            phoneNo: {
                                ...data?.phoneNo,
                                countryCode: value?.countryCode,
                                phone: value?.phone,
                            },
                        })
                    }
                />

        
                <InputField
                    placeholder={t("Email Address")}
                    value={data?.email}
                    required={true}
                    outline
                    is
                    label={t("Email")}
                    onChange={(v) => onChange({ ...data, email: v })}
                    error={data?.email?.length === 0 ? "Please enter Employee email" : ""}
                    showErr={hasErr}
                    type="email"
                    className="col-sm-12"
                    isDisabled={true}
                />
                {(data?.isDriver || data?.isGuide) ? 
                
                    <InputField
                        outline
                        placeholder={t("Experience")}
                        value={data ? data?.employeeExtn?.experience : null}
                        label={t("Experience")}
                        onChange={(v) => onChange({ ...data, employeeExtn: { ...data?.employeeExtn, experience: v } })}
                        error={data?.employeeExtn?.experience?.length === 0 ? "Please enter the Experience" : ""}
                        showErr={hasErr}
                        type="number"
                        className="col-sm-12"
                        isDisabled={true}

                    />

           : null}





                <div className="mt-2">{t('Present address')}</div>
                <AddressField className="col-sm-12"
                    label={t('Present address')}
                    isDisabled
                    outline
                    value={data?.employeeExtn?.presentAddress}
                    onChange={(v) => onChange({ ...data, employeeExtn: { ...data?.employeeExtn, presentAddress: v } })} />

                <div className="mt-2">{t('Permanent address')}</div>
                <AddressField className="col-sm-12"
                    label={t('Permanent address')}
                    outline
                    isDisabled
                    value={data?.employeeExtn?.permanentAddress}
                    onChange={(v) => onChange({ ...data, employeeExtn: { ...data?.employeeExtn, permanentAddress: v } })}
                    error={data?.address?.length === 0 ? t("Please enter address") : ""}
                />

                <Checkbox
                    label={<div><img height="20" src={driverIcon} alt="Driver" className="me-2" />{t('Driver')}</div>}
                    checked={data?.isDriver}
                    className="col-sm-12 mb-2"
                    outline
                    isDisabled
                    onClick={() => {
                        onChange({
                            ...data,
                            isDriver: !data?.isDriver,
                        });
                    }}
                />
                <Checkbox
                    label={<div><img height="20" src={guardIcon} alt="Guard" className="me-2" />{t('Guard')}</div>}
                    checked={data?.isGuard}
                    className="col-sm-12 mb-2"
                    onClick={() => {
                        onChange({
                            ...data,
                            isGuard: !data?.isGuard,
                        });

                    }}
                    isDisabled
                />
                <Checkbox
                    label={<div><img height="20" src={guideIcon} alt="Guide" className="me-2" />{t('Guide')}</div>}
                    checked={data?.isGuide}
                    className="col-sm-12 mb-2"
                    onClick={() => {
                        onChange({
                            ...data,
                            isGuide: !data?.isGuide,
                        });
                    }}
                    isDisabled
                />

                <Checkbox
                    label={<div><BsPersonVideo2 size={20} color="#7a34ff" className="me-2" />{t('Sales Person')}</div>}
                    checked={data?.isSales}
                    className="col-sm-12 mb-2"
                    onClick={() => {
                        onChange({
                            ...data,
                            isSales: !data?.isSales,
                        });
                    }}
                    isDisabled
                />

                <hr/>

                <Checkbox
                    label={t("Active")}
                    checked={data?.status == 1}
                    outline
                    isDisabled
                    className="col-sm-12 mb-2"
                    onClick={() => {
                        onChange({
                            ...data,
                            status: data?.status == 1 ? 0 : 1,
                        });
                    }}
                />

                <Checkbox
                    outline
                    isDisabled
                    label={t("Compliant")}
                    checked={data?.complianceStatus == 1}
                    className="col-sm-12 mb-2"
                    onClick={() => {
                        onChange({
                            ...data,
                            complianceStatus: data?.complianceStatus == 1 ? 0 : 1,
                        });
                    }}
                />

                <hr/>

                {(data?.isDriver || data?.isGuide) ? 
                    <LanguageSelect
                        placeholder={t("Speaks")}
                        outline
                        value={data?.speaks}
                        label={t("Speaks")}
                        multi
                        onChange={(v) => onChange({ ...data, speaks: v })}
                        error={data?.speaks?.length === 0 ? "Please enter the language" : ""}
                        showErr={hasErr}
                        type="text"
                        className="col-sm-12"
                        isDisabled={true}

                    />
                   

           : null}
            </Row>



        </div>
    );
}
export default observer(EmployeeInfo);