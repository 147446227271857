export const GridColumns = {
    version: 1,
    columns: [
        {
            title: " Name",
            accessor: "name",
            show: true,
        },
        {
            title: " Code",
            accessor: "code",
            show: true,
        },
        {
            title: "Label",
            accessor: "label",
            show: true,
        },
        {
            title: "Pic ",
            accessor: "pic",
            renderer: (dataObj) => {
                return <img height="20px" src={dataObj?.pic} />;
            },
            show: true,
            width: 200,
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
