export const ENDPOINTS = {
  grid: (page, sizePerPage, filterData) => {

    let s = `/api/holiday/group/grid?rows=${sizePerPage}`;
    // console.log("FilterData: ", filterData);
    if (filterData?.name) s += "&name=" + filterData.name;
    if (filterData?.country) s += "&country=" + filterData.country;

    return s + `&page=${page}`;

  },
  get: (id) => `/api/holiday/group/${id}/get`,
  saveNew: `/api/holiday/group/save`,
  edit: (id) => `/api/holiday/group/${id}/edit`,
  delete: (id) => `/api/holiday/group/${id}/delete`
};

export const STRUCTURE = [
  {
    label: "Name",
    description: "description...",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Year",
    description: "description...",
    filterLabel: "Year",
    filterName: "Year",
    type: "number",
  },
];