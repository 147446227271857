
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";


import "toastr/build/toastr.min.css";
import {
  AddButton,
  Breadcrumb,
  Breadcrumbs,
  CardThreeBounce,
  Checkbox,
  CitySelect,
  ClientSelect,
  CountrySelect,
  DeleteLink,
  InputField,
  Layout,

} from "../../../../components";
import "../../../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../../../store/context/DialogContext";
import { I18nContext } from "../../../../store/context/i18nContext";
import { UserContext } from "../../../../store/context/UserContext";
import HelplineService from "./HelplineService";
import RemoveLink from "../../../../components/Buttons/RemoveLink";





const Helpline = () => {
  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({})
  const [modal, setModal] = useState(false);
  const [helpline, sethelpline] = useState([])
  const [version, setVersion] = useState()

  const { showMessage } = useContext(DialogContext);
  const [hasErr, setHasErr] = useState(false);

  const setNewVersion = React.useCallback((version) => {
    setVersion(version)
  }, [])

  const submitHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      data.helpLines = helpline
      data.version = version;

      setData(() => {
        return {
          ...data,
        }
      })

      const response = await HelplineService.editHelpLines(data)

      setNewVersion(response?.version)


      showMessage("Helpline saved successfully");
      setLoading(false)
    } catch (e) {
      showError(e)
      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await HelplineService.getHelpLines();
      setNewVersion(response?.version)
      setData(response);
      sethelpline(response?.helpLines ?? []);
      setHasErr(false);
      setLoading(false);

    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const deleteHelpline=(index)=> {
    const updatedHelplines= helpline.slice(0, index).concat(helpline.slice(index + 1));
    setData({
      ...data,
      helpLines:updatedHelplines
    })
    sethelpline([...updatedHelplines])
    return ;
  }


  useEffect(() => {
    setLoading(true);
    fetchData();
    setHasErr(false);

  }, []);




  if (loading) return <CardThreeBounce />;


  return (
    <>

      <div className="page-content pt-0">

        <Card className="mx-0 pt-3">

          <CardTitle className="px-3">
            <div className={`d-flex justify-content-between col-"12"`}>
              <Breadcrumbs title="Helpline" breadcrumbItem="Helpline" />
              {userContent?.rights?.includes(1501) &&
                <AddButton
                  onClick={() => sethelpline([...helpline, {
                    customerHelpline: "",
                    driverHelpline: "",
                    country: "India",
                    isDefault: false,
                  }])}
                />
              }
            </div>
          </CardTitle>

          <CardBody className="w-100" data-pattern="priority-columns ng-scope">
            <table className=" striped-table">

              {helpline?.map((l, idx) => (
                <Tr
                  key={idx}
                  className="w-80"
                >

                  <Td className="w-100">
                    <Row className="mb-3">
                      <Col className="col-3">
                        <InputField
                          placeholder="Customer Helpline "
                          value={l?.customerHelpline}
                          label={"Customer Helpline"}
                          onChange={(v) =>
                            sethelpline(
                              helpline.map((f, x) => {
                                if (idx === x) return {
                                  ...f, customerHelpline: v
                                };
                                return f;

                              })
                            )
                          }
                          type="text"
                        />

                      </Col>
                      <Col className="col-3">
                        <InputField
                          placeholder="Driver Helpline "
                          value={l?.driverHelpline}
                          label={"Driver Helpline"}
                          onChange={(v) =>
                            sethelpline(
                              helpline.map((f, x) => {
                                if (idx === x) return {
                                  ...f, driverHelpline: v
                                };
                                return f;
                              })
                            )
                          }
                          type="text"

                        />
                      </Col>

                      <Col className="col-2">
                        <CountrySelect
                          value={l?.country}
                          showErr={hasErr}
                          required={true}
                          onChange={(v) =>
                            sethelpline(
                              helpline.map((f, x) => {
                                if (idx === x) return {
                                  ...f, country: v?.value
                                };
                                return f;
                              })
                            )
                          }
                        />
                      </Col>

                      <Col className="col-2 d-flex align-items-cente">
                        <Checkbox
                          label="Default"
                          checked={l?.isDefault}
                          onClick={(v) =>
                            sethelpline(
                              helpline.map((f, x) => {
                                if (idx === x) return {
                                  ...f, isDefault: v
                                };
                                return f;
                              })
                            )
                          }
                        />
                      </Col>
                      <Col className="col-2 d-flex align-items-center">
                        {idx != 0 ?
                            <RemoveLink className="ms-1" onClick={() => deleteHelpline(idx)}/>
                          : null}
                      </Col>
                    </Row>
                  </Td>
                </Tr>
              ))}

            </table>
          </CardBody>
        </Card>
        {userContent?.rights?.includes(1502) &&
          <div className="d-flex justify-content-end  ">
            <div className="me-2">
              <Button type="submit" color="primary" onClick={submitHandler}>Save</Button>{' '}
            </div>
          </div>
        }
      </div>
    </>

  );
};

export default observer(Helpline);






