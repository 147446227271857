export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/make-model/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`;
    return url;
  },
  grid1:'/api/make-model/grid?rows=-1',
  get: (id) => `/api/make-model/${id}/detail`,
  save: `/api/make-model/create`,
  delete: (id) => `/api/make-model/${id}/delete`,
  update: `/api/make-model/update`,
};


export const SELECTSTRUCTURE = {
  bodyType: [
    { label: "HatchBack", value: "HATCHBACK" },
    { label: "Sedan", value: "SEDAN" },
    { label: "MPV", value: "MPV" },
    { label: "SUV", value: "SUV" },
    { label: "Truck", value: "TRUCK" },
    { label: "Bus", value: "BUS" },
    { label: "VAN", value: "VAN" },
  ],
};

export const STRUCTURE = [
  {
    label: "Make",
    filterLabel: "Make",
    filterName: "make",
    type: "text",
  },
  {
    label: "Model",
    filterLabel: "Model",
    filterName: "model",
    type: "text",
  },
  {
    label: "Body Type",
    filterLabel: "Body Type ",
    filterName: "bodyType",
    type: "select",
    data: SELECTSTRUCTURE?.bodyType,
  },
];
export default SELECTSTRUCTURE;



