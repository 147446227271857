export const ENDPOINTS = {
  grid: (page, sizePerPage) => {
    let s = `/api/compliance/grid?rows=${sizePerPage}`;

    return s + `&page=${page}`;
  },

  get: `/api/compliance/grid`,
  saveNew: `/api/compliance/save`,
  delete: (id) => `/api/compliance/${id}/delete`,
  getBusinessUnit: `/api/businessUnits/list`

};

