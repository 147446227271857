import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./PermitConstant";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";

class Service {
    records = [];
    STRUCTURE = [];
    page = 1;
    rowsPerPage = 40;
    filterOption = "";
    total = -1;
    version=0;

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async (filterUrl) => {
        if (filterUrl) {
            this.filterOption = filterUrl;
        }
        const response = await doGET(
            ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl)
        );

        if (response?.status === 200) {
            this.records = response.data.rows;
            this.page = response?.data?.page;
            if (this.page === 1) this.total = response?.data?.total;
        }
    };

    edit = async function (data, id) {
        const response =await doPUT(ENDPOINTS?.update, data);
        if (response?.status === 200) {
            this.version += 1;
            // this.fetch()
          }
    };

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    save = async (data) => {
        this.records.push({ ...data, roles: [0] });
        const response = await doPOST(ENDPOINTS.save, { ...data, roles: [0] });
        return response?.data?._id;
    };

    delete = async (id,permitOf,assetKind) => {
        const response = await doDELETE(ENDPOINTS.delete(id,permitOf,assetKind));
        // if (response.status === 200) {
        //     this.fetch({ key: 2 });
        // }
        //&assetKind=${assetKind}
        if (permitOf) {
            const filterUrl = `permitOf=${permitOf}&assetKind=${assetKind}`;
            // const filterUrl = "permitOf=" + permitOf;
            this.fetch(filterUrl);
    
          } else {
            this.fetch();
    
          }
    };

    bulkDelete = async (ids = []) => {
        const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
        if (response.status === 200) {
            this.fetch();
        }
    };

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch(this.filterOption);

    };

}

const StateService = new Service();
export default StateService;

