import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

import { Layout, } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { STRUCTURE } from "./MarketPlaceConstant";
import MarketPlaceService from "./MarketPlaceService";

import RfpTabs from "./RfpTabs";

const QueriesDashboard = (props) => {
    const { id } = useParams();
    const { showError } = useContext(DialogContext);
    const { t } = useContext(I18nContext);
    const [data, setData] = useState([{}]);
    const [selected_id, setSelected_id] = useState(props?.insidePane ? "" : id);
    const [showDetailPage, setShowDetailPage] = useState(props?.insidePane ? "" : id);
    const [loading, setLoading] = useState(false);
    const [selectedtab, setselectedTab] = useState("Queries")
    const [selectedQuery, setSelectedQuery] = useState([]);


    const fetchData = async (filterUrl) => {
        if (loading) return;
        setLoading(true);
        try {
            await MarketPlaceService.fetch(filterUrl);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };
    useEffect(() => {
        setData([{
            company: "Katik Transport",
            officeAddress: "New Delhi",
            contactPerson: "John Doe",
            city: "New Delhi",
            vehcile: "Small",
            count: 10,


        },
        {
            company: "Katik Transport",
            officeAddress: "New Delhi",
            contactPerson: "John Doe",
            city: "New Delhi",
            vehcile: "Small",
            count: 10,


        }, {
            company: "Katik Transport",
            officeAddress: "New Delhi",
            contactPerson: "John Doe",
            city: "New Delhi",
            vehcile: "Small",
            count: 10,


        }])
    }, []);

    let navigate = useNavigate();


    return (
        <>
            <div className=" d-flex flex-column">
                <div style={{ marginTop: "100px" }} className="d-flex justify-content-between">
                    <div className=" d-flex justify-content-lg-start px-4 ">
                        <div className=" marketplace-dashboard-header">
                            <RfpTabs selectedtab={selectedtab} setselectedTab={setselectedTab} />
                        </div>

                    </div>

                </div>
                <div>
                    <Layout
                        showDetailPage={props?.popup ? undefined : showDetailPage}
                        backDetailPage={() => {
                            setShowDetailPage(false);
                            if (!props?.insidePane) navigate("/driver");
                            setSelected_id(false);
                        }}
                         
                        filterStructure={STRUCTURE}
                        onApplyFilter={fetchData}

                        page={MarketPlaceService.page}
                        rows={MarketPlaceService.rows}
                        total={MarketPlaceService.totalRecords}
                        insidePane={props?.insidePane}
                        onPageChange={async (page, rows) => {
                            await MarketPlaceService.onPaginationChange(page, rows);
                        }}
                    >

                        <Layout.TheadFull>
                            <Thead className="thead-dark">
                                <Tr>
                                    <Th ></Th>
                                    < Th > {t("Company")}</Th>
                                    <Th >{t("Office Address")}</Th>
                                    <Th >{t("Contact Person")}</Th>
                                    <Th >{t("City")}</Th>
                                    <Th >{t("Vehicle")}</Th>
                                    <Th >{t("Count")}</Th>
                                    <Th className=" float-end" >{t("Status")}</Th>

                                </Tr>
                            </Thead>
                        </Layout.TheadFull>

                        <Layout.TbodyFull>
                            <Tbody>
                                {data?.map((query) => (
                                    <Tr>
                                        <Td scope="row">
                                            <input
                                                type="checkbox"
                                                className="select-tariff"
                                                style={{ height: "16px" }}
                                                checked={
                                                    selectedQuery.findIndex((x) => x === query._id) !== -1
                                                }
                                                onChange={(e) => {
                                                    e.target.checked
                                                        ? setSelectedQuery([...selectedQuery, query._id])
                                                        : setSelectedQuery(
                                                            selectedQuery.filter((x) => x !== query._id)
                                                        );
                                                }}
                                            />
                                        </Td>
                                        <Td>{query?.company}</Td>
                                        <Td>{query?.officeAddress}</Td>
                                        <Td>{query?.contactPerson}</Td>
                                        <Td>{query?.city}</Td>
                                        <Td>{query?.vehcile}</Td>
                                        <Td>{query?.count}</Td>
                                        <Td><i className="mdi mdi-email-outline font-size-16 align-middle me-2"></i></Td>


                                    </Tr>
                                ))}
                            </Tbody>
                        </Layout.TbodyFull>


                    </Layout>
                </div>
            </div>



        </>
    );
};

export default observer(QueriesDashboard);
