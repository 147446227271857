import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../../util/HttpUtil";
import LeadMasterService from "../board/LeadMasterService";

const ENDPOINTS = {
  grid: () =>  `/api/space/grid?module=lead&rows=-1`,
  get: (id) => `/api/space/${id}/detail`,
  save: `/api/space/create`,
  delete: (id) => `/api/space/${id}/delete`,
  update: `/api/space/update`,
};

class Service {
  records = [];
  total = -1;
  version = 0;
  selectedSpace = {};
  
  constructor() {
    makeAutoObservable(this);
  }

  setSelectedSpace = (space) => {
    this.selectedSpace = space;
    LeadMasterService.statusNameMap = space?.statusNameMap ?? {};
    LeadMasterService.statusMastersRecords = Object.keys(space?.statusNameMap ?? {});
  };

  fetch = async () => {
    const response = await doGET(ENDPOINTS.grid());
    if (response?.status === 200 && Array.isArray(response.data.rows)) {
      if (!this.selectedSpace?._id) {
        const firstChildSpace = response.data.rows.find(e => e.parentSpace_id !== undefined);
        if (firstChildSpace) {
          this.selectedSpace = firstChildSpace;
          LeadMasterService.statusNameMap = firstChildSpace?.statusNameMap ?? {};
          LeadMasterService.statusMastersRecords = Object.keys(firstChildSpace?.statusNameMap ?? {});
        }
      }
      const recordResponse = this.buildHierarchy(response.data.rows);
      this.records = recordResponse;
      return recordResponse

    }
  };

  buildHierarchy = (spaces) => {
    let hierarchy = [];
    let lookup = {};

    spaces.forEach(space => {
      lookup[space._id] = { ...space, children: [] };
    });


    spaces.forEach(space => {
      if (space.parentSpace_id && lookup[space.parentSpace_id]) {
        lookup[space.parentSpace_id].children.push(lookup[space._id]);
      } else {
        hierarchy.push(lookup[space._id]);
      }
    });

    return hierarchy;
  };

  edit = async function (data) {
    const response = await doPUT(ENDPOINTS?.update, data);
    if (response.status === 200) {
      this.version += 1;
      this.fetch(); 
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data, fullresponse) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    if (response.status === 200) {
      this.fetch(); 
      return fullresponse ? response?.data : response?.data?._id; 
    }
  };


  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };


}

const SpaceService = new Service();
export default SpaceService;

