import { tokenUtil } from "./TokenUtil";

export const doGET = async function (url) {
  try {
    const reqBody = {
      reqParam: {}, method: "GET", endPoint: url,
      token: await tokenUtil.getBearerToken()
    };
    const response = await tokenUtil?.callAPI(reqBody);
    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      localStorage.clear();
      window.location = "/";
    }
    throw new Error(response?.data);
  } catch (err) {
    throw new Error(err?.message);
  }
};

export const doPOST = async function (url, data, forSupplier) {
  try {
    const reqBody = {
      reqParam: data, method: "POST", endPoint: url,
      token: await tokenUtil.getBearerToken()
    };
    const response = await tokenUtil?.callAPI(reqBody);
    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {

      localStorage.clear();
      window.location = "/";
    }
    throw new Error(response?.data);
  } catch (err) {
    throw new Error(err?.message);
  }
};

export const doDELETE = async function (url, data) {
  try {
    const response = await tokenUtil?.callAPI({
      reqParam: data, method: "DELETE", endPoint: url,
      token: await tokenUtil.getBearerToken()
    });
    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      localStorage.clear();
      window.location = "/";
    }
    throw new Error(response?.data);
  } catch (err) {
    throw new Error(err?.message);
  }
};

export const doPUT = async function (url, data) {
  try {
    const response = await tokenUtil?.callAPI({
      reqParam: data, method: "PUT", endPoint: url,
      token: await tokenUtil.getBearerToken()
    });
    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      localStorage.clear();
      window.location = "/";
    }
    throw new Error(response?.data);
  } catch (err) {
    throw new Error(err?.message);
  }
}


