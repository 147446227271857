
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Row } from "reactstrap";
import { InputField, VehicleTypeSelect, MakeModelSelect, CardThreeBounce } from "../../components";
import { I18nContext } from "../../store/context/i18nContext";
import { multiplyBy10ToPower } from "../../util/Util";
import RateItem from "./RateItem";


const RateItems = ({ rates, onChange, addRateItems, deleteRateItems, slabs, slabRate }) => {
  const { t } = useContext(I18nContext);
  // const lodash = require('lodash');

  // const updatedValue = ({ value, index, rateIndex, field }) => {
  //   onChange({ field, value, index, rateIndex });
  // };

  if (slabRate && !slabs) {
    return <span className="px-2" style={{ fontWeight: 'bold', color: '#ff4500' }}>Select Rate Name</span>;
  }
  return (
    <React.Fragment className="">
      <div className="shadow-none">
        <div>
          <Row className="mt-4 mb-4" style={{ overflowX: "invisible" }}>
            {
              slabRate ? slabs?.map((item, idx) => (
                <div key={idx}>
                  <span>
                    Rate Items for Slab {idx + 1}</span>
                  <span className="px-4">
                    {item.fromKM && <span className="px-2" style={{ fontWeight: 'bold', color: '#ff4500' }}>From Km: {item.fromKM}</span>}
                    {item.tillKM && <span className="px-2" style={{ fontWeight: 'bold', color: '#ff4500' }}> Till Km: {item.tillKM}</span>}
                    {item.fromMin && <span className="px-2" style={{ fontWeight: 'bold', color: '#ff4500' }}> From Min: {item.fromMin}</span>}
                    {item.tillMin && <span className="px-2" style={{ fontWeight: 'bold', color: '#ff4500' }}> Till Min: {item.tillMin}</span>}
                  </span>
                  <Row className="mt-4 mb-4" style={{ overflowX: "invisible" }}>
                    <RateItem rates={rates} item={item} onChange={onChange} deleteRateItems={deleteRateItems} addRateItems={(v) => {
                      console.log(v);
                      addRateItems(v)
                    }} />

                  </Row>
                  <hr />
                </div>
              )) : <RateItem rates={rates} onChange={onChange} deleteRateItems={deleteRateItems} addRateItems={(v) => {
                addRateItems(v)
              }} />
            }

          </Row>
        </div>
      </div>

      {/* <Button color="light" onClick={addRateItems} className="d-flex align-items-center" style={{ width: "150px", }}>
        <i className="bx color-blue bxs-plus-circle font-size-20 me-2"></i>
        {t("Add Rate Items")}
      </Button> */}
    </React.Fragment >
  );
};
export default observer(RateItems);


