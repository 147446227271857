import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";

import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./WorkShiftConstant";
import { GridColumns } from "./WorkshiftGridColumn";
import ShiftService from "./WorkShiftService";
import EditWorkShift from "./EditWorkShift";
import { UserContext } from "../../store/context/UserContext";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";


const WorkShifts = ({ insidePane, multiMode, dontShowCheck = false, onSelect = () => { }, }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const { userContent } = useContext(UserContext);
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(
    insidePane ? false : window.location.pathname === "/state/create" || edit_id
  );
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)


  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await ShiftService.fetch(filterUrl);
      setLoading(false);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(
      queryParams,
      STRUCTURE
    );
    setFilterURL(filterUrl);
    setFilterObject(filterObject);
    fetchData(filterUrl);
  }, []);

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])


  const onSave = async (e) => {
    e.preventDefault();
    if (!(detailData?.startTime && detailData?.endTime && detailData?.name)) {
      setHasErr(true)
      showError(t("Please enter all required fields"));
      return;
    }

    if (!detailData?.weekDays || !Object.values(detailData.weekDays).some(day => day)) {
      setHasErr(true);
      showError(t("Atleast one day should be selected for shift"));
      return;
    }

    setLoading(true);
    try {
      if (editID) {
        await ShiftService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Work Shift updated successfully."));
      } else {
        const shiftID = await ShiftService.save(detailData);
        setLoading(false);
        if (shiftID) setEditID(shiftID)
        showMessage(t("Work Shift saved successfully."));
        if (!insidePane) navigate(`/workshift/edit/${shiftID}`);
      }
      // setDetailData({});
      fetchData(filterURL);
    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)

  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete Shift?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await ShiftService.delete(id);
        setLoading(false);
        showMessage("Work Shift Deleted SuccessFully", "Deleted");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
        setLoading(false);
      }
    }
  };


  const renderLastCol = useCallback((shift) => {
    return (
      <>
        {userContent?.rights?.includes(2002) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/workshift/edit/${shift?._id}`);
              setEditID(shift?._id);
              setShowDetailPage(true);
            }}
          />
        }
        {userContent?.rights?.includes(2003) ?
          <DeleteLink
            onClick={(event) => onDelete(event, shift?._id)}
          />
          : null}
        {!multiMode && insidePane && !dontShowCheck ? (
          <CheckButton
            onClick={() => {
              onSelect(shift?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, userContent])

  return (
    <React.Fragment>
      <>
        <Layout
          hideAdd={!userContent?.rights?.includes(2001)}
          medium
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/workshift");
            setEditID(null);
          }}
           
          title={t("Work Shift")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/workshift/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={ShiftService.page}
          rows={ShiftService.rows}
          total={ShiftService.total}
          fetch={ShiftService.fetch}
        >
          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  {userContent?.rights?.includes(2003) ?
                  <>
                    <DropdownItem>{t("Bulk Delete")}</DropdownItem>
                    <div class="dropdown-divider"></div>
                    </>
                    : null}
                                   
                  <DropdownItem>{t("Upload")}</DropdownItem>
                  <div class="dropdown-divider"></div>
                  <DropdownItem>{t("Download")} </DropdownItem>
                </>

              </DropdownMenu>
            </div>
          </Layout.ActionMenu>
          <Layout.Table>
            <>
              <DataGrid
              gridLoading={loading}
                data={ShiftService.records}
                total={ShiftService.total}
                uiPreference="shift.grid"
                headers={GridColumns}
                selectedIDs={selectedIDs}
                onSelectChange={setSelectedIDs}
                page={ShiftService.page}
                rowsPerPage={ShiftService.rowsPerPage}
                onPaginationChange={ShiftService.onPaginationChange}
                renderLastCol={renderLastCol}
              />
            </>
          </Layout.Table>

          <Layout.DetailPageTitle>
            {detailData?._id ? t("Edit Shift") : t("Add Shift")}
          </Layout.DetailPageTitle>

          <Layout.DetailPageBody>
            <EditWorkShift
              editId={editID}
              onChange={(v) => {
                setDetailData(v);
              }}
            />
          </Layout.DetailPageBody>

          <Layout.DetailPageFooter>
            {detailData?._id ? (
              <AuditLogDialog module="Shift" id={detailData?._id} />
            ) : null

            }
            {userContent?.rights?.includes(2003) && detailData?._id ?
              <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />
              : null}
            {userContent?.rights?.includes(2002) ?
              <SaveButton onClick={onSave} loading={loading} />
              : null}

          </Layout.DetailPageFooter>
        </Layout>
      </>

    </React.Fragment>
  );
};
export default observer(WorkShifts);
