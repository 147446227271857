import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { AddressField, InputField } from "../../components";
import MAPComponent from "../../components/Map/Map";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import OfficeService from "./OfficeService";

const EditOffices = ({ org_id, editId, onChange = () => { }, hasErr }) => {
    const [center, setCenter] = useState({})
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const fetch = async () => {
        setLoading(true);
        try {
            let office = await OfficeService.get({ editId, org_id });
            setLoading(false)
            setData(office);
        } catch (error) {
            setLoading(false)
            showError(error)
        }
    };
    useEffect(() => {
        setData(null)
        setLoading(true);
        if (editId) fetch();
        setLoading(false)
    }, [editId, OfficeService?.version]);

    useEffect(() => {
        onChange(data)
    }, [data]);
    const handleMarkerDrag = (coordinates) => {
        setCenter(coordinates)
        setData({
            ...data,
            address: {
                ...data?.address,
                lat: center?.lat,
                lng: center?.lng,
            }
        });
    };


    return (
        <React.Fragment>
            {loading ?
                <ThreeBounce /> :
                <Form
                    className="p-3 pt-2"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                >

                    <Row className="">
                        <InputField
                            placeholder={t("Office Name")}
                            value={data?.name}
                            required={true}
                            label={t("Office Name")}
                            onChange={(v) => setData({ ...data, name: v })}
                            error={data?.name?.length === 0 ? t("Please enter Office Name") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-12 col-md-8"
                        />
                    </Row>
                    <div className="col-sm-12 col-md-8 mb-3">
                        <AddressField
                            placeholder={t("Address")}
                            label={t("Address")}
                            value={data?.address}
                            onChange={(v) => setData({ ...data, address: v })}
                            error={data?.address?.length === 0 ? t("Please enter address") : ""}
                            showErr={hasErr}
                        />
                    </div>
                    <Row className=" mb-3">
                        <MAPComponent
                            search
                            showMarker={true}
                            onDrag={handleMarkerDrag}
                            mapContainerStyle={{ height: "330px" }}

                        />
                    </Row>

                </Form>
            }
        </React.Fragment>
    );
};

export default observer(EditOffices);
