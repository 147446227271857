export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/offer/grid?`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    get: (id) => `/api/offer/${id}/detail`,
    save: `/api/offer/create`,
    delete: (id) => `/api/offer/${id}/delete`,
    bulkDelete: (id) => `/api/offer/delete`,
    update: `/api/offer/update`,
};

export const STRUCTURE = [
    {
        label: "Title",
        filterLabel: "Offer Title",
        filterName: "title",
        type: "text",
    },
    {
        label: "Sub Title",
        filterLabel: "Offer Sub Title",
        filterName: "subTitle",
        type: "text",
    },
    {
        label: "Details",
        filterLabel: "Detail",
        filterName: "detail",
        type: "text",
    },
    {
        label: "Percent Amount",
        filterLabel: "Percent Amount",
        filterName: "percentAmount",
        type: "text",
    },
    {
        label: "Max Discount",
        filterLabel: "Max Discount",
        filterName: "maxDiscount",
        type: "text",
    },
    {
        label: "Min Purchase",
        filterLabel: "Min Purchase",
        filterName: "minPurchase",
        type: "text",
    },
    {
        label: "Currency",
        filterLabel: "Currency",
        filterName: "currency",
        type: "currencySelect",
    },
    {
        label: "Coupon Code",
        filterLabel: "Coupon Code",
        filterName: "couponCode",
        type: "text",
    },
    {
        label: "Max Uses",
        filterLabel: "Max Uses",
        filterName: "maxUses",
        type: "text",
    },
    {
        label: "Used Till ",
        filterLabel: "Used Till",
        filterName: "usedTill",
        type: "text",
    },
    {
        label: "Valid Till ",
        filterLabel: "Valid Till",
        filterName: "validTill",
        type: "dateTimeSelect",
    },
    {
        label: "Valid From ",
        filterLabel: "Valid From",
        filterName: "validFrom",
        type: "dateTimeSelect",
    },
     
    
];
