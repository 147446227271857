import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { DateTimeSelect, CurrencySelect, CardThreeBounce, Checkbox, InputField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import FileUpload from "../../components/DocUpload/FileUpload";
import { I18nContext } from "../../store/context/i18nContext";
import OfferService from "./OfferService";
import { ThreeBounce } from "better-react-spinkit";
import DateTimePickerWithInterval from "../../components/DateTimePickerWithInterval";

const EditOffers = ({ editId, onChange = () => { }, hasErr }) => {

    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const fetch = async () => {
        try {
            setLoading(true);
            let Offer = await OfferService.get(editId);
            setData(Offer);
            setLoading(false);
        } catch (error) {
            showError(error)
            setLoading(false);
        }
    };

    useEffect(() => {
        setData(null)
        if (editId) fetch();
    }, [editId]);

    useEffect(() => {
        onChange(data)
    }, [data]);

    return (
        <React.Fragment>
            {loading ?
                <div className="d-flex justify-content-center align-items-center">
                    <ThreeBounce size={50} color="pink" />
                </div> :
                <Form
                    className="p-3 pt-0"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                >
                    <Row className="mt-3 ">
                        <InputField
                            placeholder={t("Title")}
                            value={data?.title}
                            required={true}
                            label={t("Title")}
                            onChange={(v) => setData({ ...data, title: v })}
                            error={data?.title?.length === 0 ? t("Please enter Title") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6"

                        />
                        <InputField
                            placeholder={t("Sub Title")}
                            value={data?.subTitle}
                            label={t("Sub Title")}
                            onChange={(v) => setData({ ...data, subTitle: v })}
                            error={data?.subTitle?.length === 0 ? t("Please enter subTitle") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6"

                        />
                    </Row>
                    <Row>
                        <InputField
                            placeholder={t("Base Amount")}
                            value={data?.baseAmount}
                            required={true}
                            label={t("Base Amount")}
                            onChange={(v) => setData({ ...data, baseAmount: v })}
                            error={data?.baseAmount?.length === 0 ? t("Please enter base amount") : ""}
                            showErr={hasErr}
                            type="number"
                            className="col-sm-6"
                        />

                        <InputField
                            placeholder={t("Percent Amount")}
                            value={data?.percentAmount}
                            required={true}
                            label={t("Percent Amount")}
                            onChange={(v) => setData({ ...data, percentAmount: v })}
                            error={data?.percentAmount?.length === 0 ? t("Please enter percent amount") : ""}
                            showErr={hasErr}
                            type="number"
                            className="col-sm-6"
                        />
                    </Row>
                    <Row>

                        <InputField
                            placeholder={t("Max Discount")}
                            value={data?.maxDiscount}
                            label={t("Max Discount")}
                            onChange={(v) => setData({ ...data, maxDiscount: v })}
                            error={data?.maxDiscount?.length === 0 ? t("Please enter max discount") : ""}
                            showErr={hasErr}
                            type="number"
                            className="col-sm-6"
                        />

                        <InputField
                            placeholder={t("Min Purchase")}
                            value={data?.minPurchase}
                            required={true}
                            label={t("Min Purchase")}
                            onChange={(v) => setData({ ...data, minPurchase: v })}
                            error={data?.minPurchase?.length === 0 ? t("Please enter Min Purchase") : ""}
                            showErr={hasErr}
                            type="number"
                            className="col-sm-6"
                        />
                    </Row>


                    <Row>
                        <InputField
                            placeholder={t("Coupon Code")}
                            value={data?.couponCode}
                            required={true}
                            label={t("Coupon Code")}
                            onChange={(v) => setData({ ...data, couponCode: v })}
                            error={data?.couponCode?.length === 0 ? t("Please Enter Coupon Code") : ""}
                            showErr={hasErr}
                            type="text"
                            className="col-sm-6 mt-3"
                        />

                        <CurrencySelect
                            // style={{ width: '122px' }}
                            placeholder={t("Bank Currency ")}
                            value={data?.currency}
                            label={t("Currency")}
                            onChange={(v) => {
                                setData({ ...data, currency: v.value })
                            }}
                            type="text"
                            className="col-sm-6"
                        />
                    </Row>
                    <Row>
                        <InputField
                            placeholder={t("Max Uses")}
                            value={data?.maxUses}
                            label={t("Max Uses")}
                            onChange={(v) => setData({ ...data, maxUses: v })}
                            error={data?.maxUses?.length === 0 ? t("Please Enter  Max Uses") : ""}
                            showErr={hasErr}
                            type="number"
                            className="col-sm-6 "
                        />

                        <InputField
                            value={data?.usedTill}
                            placeholder={t("Use Till")}
                            label={t(" Use Till")}
                            onChange={(v) => setData({ ...data, usedTill: v })}
                            error={data?.usedTill?.length === 0 ? t("Please Enter Used Till") : ""}
                            showErr={hasErr}
                            type="number"
                            className="col-sm-6 "
                        />
                    </Row>
                    <Row>
                        <DateTimePickerWithInterval
                            placeholder={t("Valid From")}
                            label={t("Valid From")}
                            value={data?.validFrom}
                            className="col-sm-6"
                            onChange={(v) =>
                                setData({
                                    ...data,
                                    validFrom: v,
                                })
                            }
                            type="datetime"
                            timestamp={true}
                        />



                        <DateTimePickerWithInterval
                            placeholder={t("Valid Till")}
                            label={t("Valid Till")}
                            value={data?.validTill}
                            className="col-sm-6"
                            onChange={(v) =>
                                setData({
                                    ...data,
                                    validTill: v,
                                })
                            }
                            type="text"
                            timestamp={true}
                        />
                    </Row>


                    <div className="my-4  d-flex flex-md-row flex-column justify-content-center align-items-center ">
                        <Checkbox
                            label={t("Coupon Required")}
                            checked={data?.couponRequired}
                            className="col-12 col-md-4 d-flex justify-content-start align-items-center"
                            onClick={(v) =>
                                setData({
                                    ...data,
                                    couponRequired: !data?.couponRequired,
                                })
                            }
                        />

                        <Checkbox
                            label={t("Active")}
                            checked={data?.active}
                            className="col-12 col-md-4 d-flex justify-content-center align-items-center"
                            onClick={(v) =>
                                setData({
                                    ...data,
                                    active: !data?.active,
                                })
                            }
                        />

                        <Checkbox
                            label={t("Is Default")}
                            checked={data?.isDefault}
                            className="col-12 col-md-4 d-flex justify-content-end align-items-center"
                            onClick={(v) =>
                                setData({
                                    ...data,
                                    isDefault: !data?.isDefault,
                                })
                            }
                        />

                    </div>
                    <Row className="d-flex justify-content-center">

                        <FileUpload
                            type="image"
                            className="d-flex justify-content-center col-sm-6  mb-2"
                            value={data?.pic}
                            id={data?._id}
                            module={"offer"}
                            field={"pic"}
                            onUpload={() => {
                                fetch()
                            }}
                            onDelete={() => {
                                fetch()
                            }}
                        />

                        <FileUpload
                            type="image"
                            className="d-flex justify-content-center col-sm-6"
                            value={data?.smallPic}
                            id={data?._id}
                            module={"offer"}
                            field={"pic"}
                            onUpload={() => {
                                fetch()
                            }}
                            onDelete={() => {
                                fetch()
                            }}
                        />
                    </Row>

                    <Row>
                        <div>
                            <div style={{ marginBottom: "0.2rem", }}>{t("Details")}
                                {<font color="red">*</font>} </div>
                            <textarea className="inputfield" rows={5} style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", }}
                                value={data?.detail ?? ""}
                                onChange={(e) => {
                                    setData({ ...data, detail: e.target.value });
                                }}
                            >
                            </textarea>
                        </div>
                    </Row>



                    {/* <InputField
                        placeholder={t("Details")}
                        className="col-sm-"
                        value={data?.details}
                        required={true}
                        label={t("Details")}
                        onChange={(v) => setData({ ...data, details: v })}
                        error={data?.details?.length === 0 ? t("Please enter Desc") : ""}
                        showErr={hasErr}
                        type="text"
                        style={{ height: '30px' }}

                    /> */}

                </Form>
            }
        </React.Fragment>
    );
};

export default observer(EditOffers);
