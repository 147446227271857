import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';  

const FloatingButton = ({ position, style, children, onClick }) => {
  const getPositionStyles = () => {
    const { top, right, bottom, left } = position;
    return {
      top: top !== undefined ? top : 'auto',
      right: right !== undefined ? right : 'auto',
      bottom: bottom !== undefined ? bottom : 'auto',
      left: left !== undefined ? left : 'auto',
      background:'#DF6463',
    };
  };

  return (
    <button onClick={onClick} className="floating-button " style={{ ...getPositionStyles(), ...style }}>
      {children}
    </button>
  );
};

FloatingButton.propTypes = {
  position: PropTypes.shape({
    top: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
  }),
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};

FloatingButton.defaultProps = {
  position: {},
  style: {},
};

export default FloatingButton;
 
