import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Badge, Button, Card } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import {
    Layout,
    Modal,
    CitySelect,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { MARKETPLACE_BASE_URL, STRUCTURE } from "./MarketPlaceConstant";
import MarketPlaceService from "./MarketPlaceService";

import StepNavigation from "./StepNavigation";
import ClientStep1 from "./ClientSection/ClientStep1";
import Step1 from "./RFPSection/Step1";
import Step2 from "./RFPSection/Step2";
import Step3 from "./RFPSection/Step3";
import Step4 from "./RFPSection/Step4";
import RfpTabs from "./RfpTabs";
import { RFPImage } from "../../assets/images";
import ClientStep2 from "./ClientSection/ClientStep2";
import ClientStep3 from "./ClientSection/ClientStep3";
import TransporterStep1 from "./TransporterSection/TransporterStep1";
import TransporterStep2 from "./TransporterSection/TransporterStep2";
import TransporterStep3 from "./TransporterSection/TransporterStep3";
import TransporterStep4 from "./TransporterSection/TransporterStep4";
import TransporterStep5 from "./TransporterSection/TrasnporterStep5";
import axios from "axios";
import moment from "moment";
import { UserContext } from "../../store/context/UserContext";
import { MyVerticallyCenteredModal } from "./MyVerticallyCenteredModal";
import { doPOST } from "../../util/HttpUtil";

const MarketPlaceDashboard = (props) => {
    const { id } = useParams();
    const { showError, showMessage } = useContext(DialogContext);
    const { t } = useContext(I18nContext);
    const [data, setData] = useState([{}]);
    const [rfpdata, setRfpData] = useState({})
    const [selected_id, setSelected_id] = useState(props?.insidePane ? "" : id);
    const [showDetailPage, setShowDetailPage] = useState(props?.insidePane ? "" : id);
    const [loading, setLoading] = useState(false);
    const [selectedtab, setselectedTab] = useState("RFP")
    const [registerpopup, setregisterpopup] = useState(false)
    const [registerData, setRegisterData] = useState([])
    const [showform, setshowform] = useState(false)
    const [modalShow, setModalShow] = React.useState(false);
    const [suplliermodal, setSuppliarModal] = useState(false)
    const [clientmodal, setClientModal] = useState(false)
    const [transportermodal, setTransporterModal] = useState(false)
    const labelArray = ['Step 1', 'Step 2', 'Step 3', 'Label 4']
    const clientArray = ['Step 1', 'Step 2', 'Step 3']
    const transporterArray = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step5']
    const [currentStep, updateCurrentStep] = useState(1);
    const [checkSteps, setCheckSteps] = useState([1])
    const { userContent } = useContext(UserContext);
    const [showSubmit, setShowSubmit] = useState(false)
    const [transpoterdetails, setTransporterDetail] = useState({})
    // const [companyId, setCompanyId] = useState('648070109880f1854a5c2881')

    const companyId = userContent?.corp_id;


    const getCompanyDetails = async (filterUrl) => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await axios.get(`${MARKETPLACE_BASE_URL}/get/company/${companyId}`)

            console.log(response?.data?.data);
            setTransporterDetail(response?.data?.data)
            setregisterpopup(response?.data?.data ? false : true)
        } catch (e) {
            showError(e?.message);
        }
        setLoading(false);
    };


    const fetchData = async (filterUrl) => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await axios.get(`${MARKETPLACE_BASE_URL}/get/rfp/all`)
            console.log(response?.data);
            setData(response?.data?.data)

        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };
    const submitHandler = async () => {
        try {
            setRfpData({ ...rfpdata, companyName: transpoterdetails?.companyName })
            const response = await axios.post(`${MARKETPLACE_BASE_URL}/create/rfp`, rfpdata)
            if (response.status === 200) {
                setModalShow(true);
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    heading="Registration Complete"
                    details="We will verify your application and activate your account.Our team may reach out to you in case more information required."
                    buttontext="Okay"
                />
                setshowform(false)
            }
            else {
                showError(response?.data?.message);

            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getCompanyDetails()
        console.log(rfpdata);
        fetchData()

    }, []);

    let navigate = useNavigate();

    const transporterSteps = {
        1: <TransporterStep1 data={registerData} setData={setRegisterData} />,
        2: <TransporterStep2 data={registerData} setData={setRegisterData} />,
        3: <TransporterStep3 data={registerData} setData={setRegisterData} />,
        4: <TransporterStep4 data={registerData} setData={setRegisterData} />,
        5: <TransporterStep5 data={registerData} setData={setRegisterData} />,
    }
    const clientsSteps = {
        1: <ClientStep1 data={registerData} setData={setRegisterData} />,
        2: <ClientStep2 data={registerData} setData={setRegisterData} />,
        3: <ClientStep3 data={registerData} setData={setRegisterData} />,
    }
    const rfpSteps = {
        1: <Step1 data={rfpdata} setData={setRfpData} />,
        2: <Step2 data={rfpdata} setData={setRfpData} />,
        3: <Step3 data={rfpdata} setData={setRfpData} />,
        4: <Step4 data={rfpdata} setData={setRfpData} />,

    }
    function updateStep(step) {
        updateCurrentStep(step);
        setCheckSteps(prev => [...prev, step])
    }

    const registerCompany = async (e) => {
        try {
            if (userContent?.corpType === 1) {
                registerData.companyType = 1

            } else {
                registerData.companyType = 2
            }
            registerData._id = userContent?.corp_id

            const response = await axios.post(`${MARKETPLACE_BASE_URL}/register`, registerData)
            setShowSubmit(true)
            setCheckSteps([1])
            updateCurrentStep(1)
            setModalShow(true)
            if (userContent?.corpType === 1) {
                setClientModal(false)
            } else {
                setTransporterModal(false)

            }


        } catch (error) {
            console.log(error, "ee")
            setCheckSteps([1])
            updateCurrentStep(1)
            if (userContent?.corpType === 1) {
                setClientModal(false)
            } else {
                setTransporterModal(false)

            }

        }
    }

    // const registerCompany = async (e) => {
    //     try {
    //         const response = await axios.post(`${MARKETPLACE_BASE_URL}/profile/update/company/${companyId}`, transpoterdetails)
    //         setShowSubmit(true)
    //         setCheckSteps([1])
    //         updateCurrentStep(1)
    //         setModalShow(true)
    //         setTransporterModal(false)

    //     } catch (error) {
    //         showError(error);
    //         setCheckSteps([1])
    //         updateCurrentStep(1)

    //     }
    // }
    return (
        <>

            {transportermodal ? (
                <Modal
                    onChange={() => {

                        updateCurrentStep(1)
                        setCheckSteps([1])
                        setshowform(false)
                    }}
                    modalSwitch={true}
                >
                    <div className=" d-flex justify-content-center">
                        <Card className={` client-card h-100 ${currentStep === transporterArray.length ? "w-80" : "w-50"}`} style={{ marginRight: `${currentStep === transporterArray.length ? "250px" : ""}` }}>
                            <h4 className='d-flex justify-content-center fa-2x fw-bold mt-5 mb-4 '>Register for Market Place</h4>
                            <div className="close-danger-btn">
                                <Button
                                    style={{ margin: "30px" }}
                                    color="danger"
                                    className="waves-effect waves-light __modal-close-btn"
                                    onClick={() => {
                                        updateCurrentStep(1)
                                        setCheckSteps([1])
                                        setTransporterModal(false)
                                    }}
                                >
                                    <i style={{ fontSize: "38px" }} className="bx bx-x" />
                                </Button>
                            </div>
                            <div className=' d-flex justify-content-center'>
                                <StepNavigation checkSteps={checkSteps} labelArray={transporterArray} currentStep={currentStep} updateStep={updateStep}></StepNavigation>
                            </div>
                            <div className='mt-4 mb-5'>
                                {transporterSteps[currentStep]}
                            </div>

                            <div className=' m-auto mb-5  d-flex justify-content-center'>

                                {currentStep === transporterArray.length ?
                                    <Button className="submit-btn" onClick={(e) => registerCompany(e)}>
                                        <span> Submit</span>
                                    </Button> :
                                    <button
                                        disabled={currentStep === transporterArray.length} onClick={() => updateStep(currentStep + 1)}
                                        className='next-arrow-btn'>
                                        <i style={{ fontSize: "38px", color: 'white' }} className="dripicons-arrow-thin-right" />
                                    </button>}
                            </div>
                        </Card >
                    </div>
                </Modal>
            ) : (
                <> </>
            )}
            {clientmodal ? (
                <Modal
                    onChange={() => {

                        updateCurrentStep(1)
                        setCheckSteps([1])
                        setshowform(false)
                    }}
                    modalSwitch={true}
                >
                    <div className=" d-flex justify-content-center">
                        <Card className=" client-card h-100 w-50">
                            <h4 className='d-flex justify-content-center fa-2x fw-bold mt-5 mb-4 '>Register for Market Place</h4>
                            <div className="close-danger-btn">
                                <Button
                                    style={{ margin: "30px" }}
                                    color="danger"
                                    className="waves-effect waves-light __modal-close-btn"
                                    onClick={() => {
                                        updateCurrentStep(1)
                                        setCheckSteps([1])
                                        setClientModal(false)
                                    }}
                                >
                                    <i style={{ fontSize: "38px" }} className="bx bx-x" />
                                </Button>
                            </div>
                            <div className=' d-flex justify-content-center'>
                                <StepNavigation checkSteps={checkSteps} labelArray={clientArray} currentStep={currentStep} updateStep={updateStep}></StepNavigation>
                            </div>
                            <div className='mt-4 mb-5'>
                                {clientsSteps[currentStep]}
                            </div>

                            <div className=' m-auto mb-5  d-flex justify-content-center'>

                                {currentStep === clientArray.length ?
                                    <Button className="submit-btn" onClick={(e) => registerCompany(e)}>
                                        <span> Submit</span>
                                    </Button> :
                                    <button
                                        disabled={currentStep === labelArray.length} onClick={() => updateStep(currentStep + 1)}
                                        className='next-arrow-btn'>
                                        <i style={{ fontSize: "38px", color: 'white' }} className="dripicons-arrow-thin-right" />
                                    </button>}
                            </div>
                        </Card >
                    </div>
                </Modal>
            ) : (
                <> </>
            )}

            {registerpopup ? (
                <Modal

                    modalSwitch={true}
                >
                    <div className=" d-flex justify-content-center">
                        <Card className="register-popup w-75 h-50">
                            <div className=" d-flex justify-content-center ">
                                <div className="px-2 py-4 mt-4 d-flex justify-content-center" style={{ background: "#F2F4FC", boxSizing: "border-box", width: "60px", height: "60px", borderRadius: "100px", marginRight: "10px" }} >
                                    <img width="100px" height="25px" src={RFPImage} alt="" />
                                </div>
                                <h4 className='d-flex justify-content-center fa-2x fw-bold mt-5 mb-4 '>RFP : Request for Proposal</h4>
                            </div>

                            <div className="close-danger-btn">
                                <Button
                                    style={{ margin: "30px" }}
                                    color="danger"
                                    className="waves-effect waves-light __modal-close-btn"
                                    onClick={() => {
                                        setregisterpopup(false)
                                    }}
                                >
                                    <i style={{ fontSize: "38px" }} className="bx bx-x" />
                                </Button>
                            </div>

                            <div className='px-5 register-popup-h1 m-auto'>
                                <p>In the world of procurement, finding the right suppliers and obtaining competitive pricing is essential for businesses to thrive. One effective way to achieve this is by utilizing Request for Proposals (RFPs) and leveraging our online marketplace.By utilizing online marketplaces for RFPs,</p>
                            </div>
                            <div className='mt-4 px-5 mb-2 d-flex justify-content-center'>
                                <p className='register-popup-h2 fw-bold'>
                                    By utilizing online marketplaces for RFPs, you can unlock several advantages
                                </p>
                            </div>

                            <div className=' px-4 mb-3'>
                                <ul className=' '>
                                    <li className="mb-3 fa-2x" ><span className="fw-bold fa-1x">Increased Supplier Access:</span> Online marketplaces provide access to a diverse range of suppliers, expanding your options and increasing competition.</li>
                                    <li className="mb-3 fa-2x"  >  <span className="fw-bold fa-1x"> Time and Cost Savings:</span> The streamlined RFP process through online marketplaces saves time by automating tasks like proposal submissions, communication, and evaluation. Additionally, increased competition among suppliers often results in cost savings for your organization.</li>
                                    <li className="mb-3 fa-2x" >  <span className="fw-bold fa-1x"> Enhanced Transparency:</span> Online marketplaces offer transparency by enabling you to view supplier profiles, ratings, and reviews from previous customers. This information aids in making well-informed decisions.</li>
                                    <li className="mb-3 fa-2x" >    <span className="fw-bold fa-1x"> Improved Efficiency:</span> The centralized nature of online marketplaces simplifies the entire procurement process, from floating an RFP to selecting a supplier. This efficiency allows you to focus on other critical aspects of your business.</li>

                                </ul>
                            </div>

                            <div className=' m-auto mb-5  d-flex justify-content-center'>
                                <Button style={{
                                    width: "432px",
                                    height: "69px"
                                }} className="submit-btn" onClick={() => {
                                    setregisterpopup(false)
                                    if (userContent?.corpType === 1) {
                                        setClientModal(true)

                                    } else {
                                        setTransporterModal(true)

                                    }


                                }}>
                                    <span>Register For Marketplace</span>
                                </Button>

                            </div>
                        </Card >
                    </div>
                </Modal>
            ) : (
                <> </>
            )}

            {suplliermodal ? (
                <Modal

                    modalSwitch={true}
                >
                    <div className=" d-flex justify-content-center">
                        <Card className=" client-card " style={{ width: "710px", height: "42vh !important" }}>
                            <h4 className='d-flex justify-content-center fa-2x fw-bold mt-5 mb-4 '>In which city you want to search for supplier ?</h4>
                            <div className="close-danger-btn">
                                <Button
                                    style={{ margin: "30px" }}
                                    color="danger"
                                    className="waves-effect waves-light __modal-close-btn"
                                    onClick={() => {
                                        setSuppliarModal(false)
                                    }}
                                >
                                    <i style={{ fontSize: "38px" }} className="bx bx-x" />
                                </Button>
                            </div>

                            <div className='mt-5 mb-5'>
                                <Row className=''>
                                    <Col className=' px-4 col-sm-12'>
                                        <div className=" m-auto">
                                            <CitySelect
                                                className='col-sm-8 m-auto'
                                                value={data?.city}
                                                state={data?.state}
                                                country={data?.country}
                                                onChange={(v) =>
                                                    setData({})

                                                }
                                            />
                                        </div>
                                    </Col>

                                </Row>
                            </div>

                            <div className=' m-auto mb-5  d-flex justify-content-center'>


                                <Button className="submit-btn" onClick={() =>
                                    navigate('/marketplace/supplier/dashboard')}>
                                    <span> Proceed</span>
                                </Button>

                            </div>
                        </Card >
                    </div>
                </Modal>
            ) : (
                <> </>
            )}

            {showform ? (
                <Modal
                    onChange={() => {

                        updateCurrentStep(1)
                        setCheckSteps([1])
                        setshowform(false)
                    }}
                    modalSwitch={true}
                >
                    <div className=" d-flex justify-content-center">
                        <Card className=" client-card h-100 w-50">
                            <h4 className='d-flex justify-content-center fa-2x fw-bold mt-5 mb-4 '>Please fill your RFP details</h4>
                            <div className="close-danger-btn">
                                <Button
                                    style={{ margin: "30px" }}
                                    color="danger"
                                    className="waves-effect waves-light __modal-close-btn"
                                    onClick={() => {
                                        updateCurrentStep(1)
                                        setCheckSteps([1])
                                        setshowform(false)
                                    }}
                                >
                                    <i style={{ fontSize: "38px" }} className="bx bx-x" />
                                </Button>
                            </div>
                            <div className=' d-flex justify-content-center'>
                                <StepNavigation checkSteps={checkSteps} labelArray={labelArray} currentStep={currentStep} updateStep={updateStep}></StepNavigation>
                            </div>
                            <div className='mt-4 mb-5'>
                                {rfpSteps[currentStep]}
                            </div>

                            <div className=' m-auto mb-5  d-flex justify-content-center'>

                                {currentStep === labelArray.length ?
                                    <Button className="submit-btn" onClick={submitHandler} >
                                        <span> Submit</span>
                                    </Button> :
                                    <button
                                        disabled={currentStep === labelArray.length} onClick={() => updateStep(currentStep + 1)}
                                        className='next-arrow-btn'>
                                        <i style={{ fontSize: "38px", color: 'white' }} className="dripicons-arrow-thin-right" />
                                    </button>}
                            </div>
                        </Card >
                    </div>
                </Modal>

            ) : (
                <> </>
            )}

            <div className=" d-flex flex-column ">

                <div style={{ marginTop: "100px" }} className="d-flex justify-content-between">
                    <div className=" d-flex justify-content-lg-start px-4 ">
                        <div className=" marketplace-dashboard-header">
                            <RfpTabs selectedtab={selectedtab} setSuppliarModal={setSuppliarModal} setselectedTab={setselectedTab} />
                        </div>

                    </div>
                    <div className='px-4 d-flex justify-content-end py-3'>
                        <Button onClick={() => setshowform(true)} style={{
                            backgroundColor: "#DF6463", border: "none", borderRadius: "8px",
                            width: "120px",
                            height: "52px"
                        }}>
                            <div className=" d-flex justify-content-center mt-1">
                                <span >  <i style={{ fontSize: '25px' }} className="bx bx-plus" /> </span> <span className=" fw-bolder mb-2 py-1">Add RFP</span>
                            </div>

                        </Button>
                    </div>
                </div>

                <div>
                    <Layout
                        showDetailPage={props?.popup ? undefined : showDetailPage}
                        backDetailPage={() => {
                            setShowDetailPage(false);
                            if (!props?.insidePane) navigate("/driver");
                            setSelected_id(false);
                        }}
                         
                        title={t("RFP (Client + Suppliers)")}
                        filterStructure={STRUCTURE}
                        onApplyFilter={fetchData}

                        page={MarketPlaceService.page}
                        rows={MarketPlaceService.rows}
                        total={MarketPlaceService.totalRecords}
                        insidePane={props?.insidePane}
                        onPageChange={async (page, rows) => {
                            await MarketPlaceService.onPaginationChange(page, rows);
                        }}
                    >

                        <Layout.TheadFull>
                            <Thead className="thead-dark">
                                <Tr>
                                    <Th >{t("ID")}</Th>
                                    <Th >{t("User Type")}</Th>
                                    <Th >{t("#Pax")}</Th>
                                    <Th >{t("#Cab")}</Th>
                                    <Th >{t("Pick & Drop Off")}</Th>
                                    <Th >{t("Vehicle Type")}</Th>
                                    <Th >{t("Pickup Time")}</Th>
                                    <Th >{t("Infracity/Oustattion")}</Th>
                                    <Th >{t("Expiry")}</Th>
                                    <Th className="float-end">{t("Action")}</Th>
                                </Tr>
                            </Thead>
                        </Layout.TheadFull>

                        <Layout.TbodyFull>
                            <Tbody>
                                {data?.map((rfp) => (
                                    <Tr>
                                        <Td>{rfp?._id}</Td>
                                        <Td>{rfp?.travelType}</Td>
                                        <Td>{rfp?.totalpax}</Td>
                                        <Td></Td>
                                        <Td></Td>
                                        <Td></Td>
                                        <Td>{rfp?.pickupTime}</Td>
                                        <Td>{rfp?.tripType}</Td>
                                        <Td>{moment(rfp?.expiryDate).format('YYYY-MM-DD')}</Td>
                                        <Td><Button className="my-1" style={{ background: "#DF6463", border: "none" }} onClick={() => navigate(`/marketplace/rfp/details/${rfp._id}`)}>Bid now</Button></Td>

                                    </Tr>
                                ))}
                            </Tbody>
                        </Layout.TbodyFull>


                    </Layout>
                </div>
            </div>

            <MyVerticallyCenteredModal
                show={showSubmit}
                onHide={() => setShowSubmit(false)}
            />



        </>
    );
};

export default observer(MarketPlaceDashboard);
