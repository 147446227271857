import { makeAutoObservable, toJS } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import VehicleTypeSelectService from "../VehicleTypeSelect/VehicleTypeSelectService";
import MakeModelSelectService from "../MakeModelSelect/MakeModelSelectService";

class RateVehicle {

    constructor() {
        makeAutoObservable(this);
    }
    
    makeFilterUrl = ({ customerType, client_id, srcCountry, srcState, srcCity, date, supplier_id, rateType }) => {
        let rateUrl = `withRateName=true&withExtn=true&rows=-1`;
        if (srcCountry) {
            rateUrl = rateUrl + "&srcCountry=" + srcCountry;
        }
                    // todo commented because rates are not mapped correctly for trvlez for rental and local

        // if (rateType) {
        //     rateUrl = rateUrl + "&rateType=" + rateType;
        // }
        if (srcState) {
            rateUrl = rateUrl + "&srcState=" + srcState;
        }
        if (srcCity) {
            rateUrl = rateUrl + "&srcCity=" + srcCity;
        }
        if (client_id) {
            if (customerType === "walkIn") {
                rateUrl = rateUrl + "&forB2C=true";
            } else {
                rateUrl = rateUrl + "&client_id=" + client_id;
            }
        } else if (supplier_id) {
            rateUrl = rateUrl + "&ofSupplier=true&supplier_id=" + supplier_id;
        } else {
            rateUrl = rateUrl + "&forB2C=true"
        }
        if (date) {
            rateUrl = rateUrl + "&date=" + date;
        }
        return rateUrl
    }



    fetch = async function ({ customerType, client_id, srcCountry, srcState, srcCity, date, supplier_id, rateType }) {
        try {
            if (VehicleTypeSelectService?.vehicleTypes?.length == 0) {
                await VehicleTypeSelectService?.fetch()
            }

            const url = this.makeFilterUrl({ customerType, client_id, srcCountry, srcState, srcCity, date, supplier_id })
            const response = await doGET(`/api/o/rate/data?${url}`);

            let vehicleTypes = {}
            let makeModels = {}
            VehicleTypeSelectService?.vehicleTypes?.map((vehicleType) => {
                vehicleTypes[toJS(vehicleType)?._id] = toJS(vehicleType)?.name
            })
            MakeModelSelectService?.makeModel?.map((mm) => {
                makeModels[toJS(mm)?._id] = toJS(mm)?.make + " " + toJS(mm)?.model
            })

            if (response.status === 200) {
                let groupedEntries = []
                let uniqueRates = []
                response?.data?.rows?.forEach((v) => {
                    if (!uniqueRates?.includes(v?.name)) {
                        v?.name !== null ? uniqueRates?.push(v?.name) : console.log("")
                    }
                });
                uniqueRates?.map((rate, index) => {
                    const groupedEntities = { label: rate, options: [] }
                    let entities = []
                    response?.data?.rows?.forEach((v) => {
                        if (v?.name == rate) {
                            entities.push({
                                ...v,
                                value: v?._id,
                                label: `${makeModels?.[v?.makeModel] ?? v?.vehicleTypeDetail?.makeModels} ${(v.supplierRate && !v?.supplier_id) ? "(default)" : ""}` ,
                                groupLabel: rate
                            })
                        }
                    });
                    groupedEntities.options = entities
                    groupedEntries.push(groupedEntities)
                })
                return groupedEntries
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const RateVehicleService = new RateVehicle();
export default RateVehicleService;
