import React, { useContext, useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { ENDPOINTS } from "../../common/utils/Constants";
import { InputField, NameField, PhoneNum } from "../../components";
import LanguageMenu from "../../components/CommonForBoth/TopbarDropdown/LanguageSelect/LanguageMenu";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { doGET, doPOST, doPUT } from "../../util/HttpUtil";
import OtpInput from "./OtpInput";
import SignupOtp from "./SignupOtp";
import Step0 from "./SignupSteps/Step0";
import Step1 from "./SignupSteps/Step1";
import Step4 from "./SignupSteps/Step4";

const Signup = (props) => {
  const { showError, showMessage } = useContext(DialogContext);
  const { userContent, populateUserContent } = useContext(UserContext);
  const [step, setStep] = useState(0);
  const [isTermsSelected, setIsTermsSelected] = useState(false);

  const [signupdata, setSignupdata] = useState({});
  const [updatedata, setUpdateData] = useState({});

  const [imageURL, setImageUrl] = useState();

  const [data, setData] = useState({
    corpType: "2",
  });
  const {
    getOTP,
    loading,
    errorStatus,
    setLoading,
    isCpanelLogin,
    captcha,
    setCapcha,
    onClickLogin,
    errorMessage,
  } = props;
  let navigate = useNavigate();

  useEffect(() => {
    document.body.className = "authentication-bg";
    const fetch = async () => {
      if (isCpanelLogin) getCaptcha();
      else {
        await populateUserContent();
      }
    };
    fetch();
    getCaptcha();
    return function cleanup() {
      document.body.className = "";
    };
  }, []);

  const getCaptcha = async () => {
    const dta = {
      for: "captcha",
    };
    let res = await doPOST(ENDPOINTS.LoginGetCaptcha, dta);
    try {
      setImageUrl(res.data.image);
      setCapcha(res.data.captcha_id);
      setData({
        ...data,
        supervisorTO: {
          ...data?.supervisorTO,
          captchaOTP_Id: res.data.captcha_id,
          phoneNo: {
            ...data?.supervisorTO?.phoneNo,
            countryCode: 91,
          },
        },
      });
    } catch (error) {
      showMessage(error);
    }
  };

  useEffect(() => {
    const myBtn = document.getElementById("capcha");
    if (errorStatus) {
      myBtn.click();
    }
  }, [errorStatus]);

  const updatedDataWithCorporateExtn = {
    ...updatedata,
    corporateExtn: {
      ...data.corporateExtn,
    },
  };

  const onUpdate = async () => {
    if (loading) return;
    try {
      const response = await doPUT(
        ENDPOINTS?.updateCorporate,
        updatedDataWithCorporateExtn
      );

      if (response.status === 200) {
        await populateUserContent();
        navigate("/dashboard");

        return;
      } else {
        showError("Error");
      }
    } catch (error) {
      showError(error);
    }
  };

  const getdetail = async (id) => {
    if (loading) return;
    try {
      const response = await doGET(ENDPOINTS?.getDetail(id));
      if (response.status === 200) {
        if (response.data) {
          setUpdateData(response.data);
        }

        return;
      } else {
        showError("Error");
      }
    } catch (error) {
      showError(error);
    }
  };

  const onSignUp = async () => {
    if (loading) return;
    try {
      const response = await doPOST(ENDPOINTS?.signUpSelf, data);
      if (response?.data?.userContent?.consoleDomain) {
        window.location.replace(`${window.location.protocol}//${response?.data?.userContent?.consoleDomain}/signup-profile?refresh=${response.data.refreshToken}&corpType=${data?.corpType}`);
        return;
      }
      if (response.status === 200) {
        localStorage.setItem("BearerToken", response.data.bearerToken);
        localStorage.setItem("RefreshToken", response.data.refreshToken);
        localStorage.setItem("TokenExpiry", response.data.expiry);

        const adminOf = response?.data?.userContent?.adminOf;

        const res = await getdetail(adminOf);
        setStep(3);

        return;
      } else {
        showError("Incorrect OTP Entered");
      }
    } catch (error) {
      getCaptcha();
      setStep(1);
      showError(error);
    }
  };

  const getSignupOTP = async () => {
    const emailOTPData = {
      platform: "web",
      email: data?.supervisorTO?.email,
      captcha: data?.supervisorTO?.captchaQuery,
      captchaOTP_Id: data?.supervisorTO?.captchaOTP_Id,
      phone: data?.supervisorTO?.phoneNo?.phone,
      countryCode: data?.supervisorTO?.phoneNo?.countryCode
        ? data?.supervisorTO?.phoneNo?.countryCode
        : 91,
    };

    let emailOTPId = "";
    let phoneOTPId = "";

    try {
      const emailOTPResp = await doPOST("/auth/token", emailOTPData);

      emailOTPId = emailOTPResp?.data?.email;
      phoneOTPId = emailOTPResp?.data?.phone;

      showMessage("OTP SENT SUCCESSFULLY <br/> TO PHONE AND EMAIL", "Success");

      setData({
        ...data,
        supervisorTO: {
          ...data?.supervisorTO,
          emailOTPId: emailOTPId,
          phoneOTPId: phoneOTPId,
        },
      });
    } catch (error) {
      getCaptcha();
      setStep(1);
      showError(error);
    }
  };

  useEffect(() => {
    if (step === 2) {
      getSignupOTP();
    }
  }, [step]);

  useEffect(() => {
    if (step == 3) {
      onSignUp();
    }
  }, [step]);

  useEffect(() => {
    if (step == 4) {
      onUpdate();
    }
  }, [step]);

  const goBack = () => {
    if (step > 0) {
      if (step == 2) {
        getCaptcha();
      }
      setStep(step - 1);
    }
  };

  const goNext = () => {
    try {
      if (!isTermsSelected) {
        showError("Please Accept Terms & Conditions");
        return;
      }
      if (step == 0 && data?.corpType && data?.name && data?.fullName) {
        getCaptcha();
        setStep(step + 1);
      } else if (
        step == 1 &&
        data?.supervisorTO?.salutation &&
        data?.supervisorTO?.email &&
        data?.supervisorTO?.name &&
        data?.supervisorTO?.phoneNo?.phone &&
        data?.supervisorTO?.captchaQuery
      ) {
        setStep(step + 1);
      } else if (
        step == 2 &&
        data?.supervisorTO?.emailOTPValue &&
        data?.supervisorTO?.phoneOTPValue
      ) {
        setStep(step + 1);
      } else if (
        step == 3 &&
        ((data?.corporateExtn?.employeeCount &&
          data?.corporateExtn?.serviceRequired) ||
          data?.corporateExtn?.vehicleTypeCount)
      ) {
        setStep(step + 1);
      } else {
        showError("Please fill all data");
      }
    } catch (error) { }
    // if (step <= 5) {

    //   setStep(step + 1);
    // }
  };
  return (
    <React.Fragment>
      <div
        style={{
          overflowY: "auto",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row className="mb-5 mt-3 d-flex justify-content-end">
          <Col className="col-4 ">
            <LanguageMenu outline />
          </Col>
        </Row>
        <div className="account-pages pt-sm-3  ">
          <Container className="d-flex justify-content-center  ">
            <Row
              className="align-items-center justify-content-center w-100"
              style={{
                border: "1px solid #D5D5D5",
                borderRadius: "1.25rem",
                maxWidth: "500px",
              }}
            >
              <Col className="flex-1 mx-6 col-6">
                <Card className="shadow-none ">
                  <CardBody className="p-4">
                    <Link className="mb-4 mt-2 d-block auth-logo">
                      <img
                        src="/logo.png"
                        alt="zoyride-logo"
                        height="40"
                        className="logo logo-dark mb-1"
                      />
                    </Link>
                    <h3 className="align-items-center justify-content-center d-flex pb-3 font-size-18">
                      Signup
                    </h3>

                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "1px",
                        marginBottom: "1px",
                        display: "flex",
                        paddingLeft: "50px",
                        paddingRight: "50px",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {Array.from({ length: 4 }).map((_, index) => (
                        <React.Fragment key={index}>
                          <div
                            style={{
                              backgroundColor:
                                step === index
                                  ? "#F08180"
                                  : step > index
                                    ? "#099206"
                                    : "#ced4da",
                              width: "30px",
                              height: "23px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {step > index && (
                              <div id="myDiv">
                                <FaCheck
                                  style={{
                                    color: "#fff",
                                    fontSize: "12px",
                                    top: "-5px",
                                    right: "-7px",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          {index < 3 && (
                            <div
                              style={{
                                width: "105px",
                                height: "1px",
                                backgroundColor: "#ced4da",
                              }}
                            ></div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <div className=" d-flex justify-content-between flex-column mt-1">
                      <div>
                        {step === 0 && (
                          <Step0
                            value={data}
                            onChange={(v) => setData({ ...data, ...v })}
                          />
                        )}
                        {(step === 1 || step === 2) && (
                          <div className="account-pages">
                            <Row className="align-items-center justify-content-center">
                              <Col className="">
                                <div className="text-center ">
                                  <div className="">
                                    <Row>
                                      <NameField
                                        required={true}
                                        outline
                                        className={`col-sm-12 ${data?.supervisorTO?.name?.length === 0 ? "mb-1" : "mb-2"
                                          }`}
                                        onNameChange={(v) =>
                                          setData({
                                            ...data,
                                            supervisorTO: {
                                              ...data?.supervisorTO,
                                              name: v,
                                            },
                                          })
                                        }
                                        onSalutationChange={(v) =>
                                          setData({
                                            ...data,
                                            supervisorTO: {
                                              ...data?.supervisorTO,
                                              salutation: v,
                                            },
                                          })
                                        }
                                        name={data?.supervisorTO?.name}
                                        salutation={
                                          data?.supervisorTO?.salutation
                                        }
                                        label="Name"
                                        // showErr={true}

                                        error={
                                          data?.supervisorTO?.name?.length === 0
                                            ? "Please enter Name"
                                            : ""
                                        }
                                        salutationStyle={{ fontSize: "18x" }}
                                        inputStyle={{ fontSize: "18px" }}
                                      />
                                      <PhoneNum
                                        outline
                                        showErr={true}
                                        required={true}
                                        className="mb-2 col-12"
                                        value={{
                                          countryCode: data?.supervisorTO
                                            ?.phoneNo?.countryCode
                                            ? data?.supervisorTO?.phoneNo
                                              ?.countryCode
                                            : 91,
                                          phone:
                                            data?.supervisorTO?.phoneNo?.phone,
                                        }}
                                        error={
                                          data?.supervisorTO?.phoneNo
                                            ?.countryCode?.length === 0
                                            ? "Please enter CountryCode"
                                            : ""
                                        }
                                        countrySelectStyle={{ fontSize: "18x" }}
                                        inputStyle={{ fontSize: "18px" }}
                                        onChange={(v) =>
                                          setData({
                                            ...data,
                                            supervisorTO: {
                                              ...data?.supervisorTO,
                                              phoneNo: {
                                                ...data?.supervisorTO?.phoneNo,
                                                phone: v?.phone,
                                                countryCode: v?.countryCode,
                                              },
                                            },
                                          })
                                        }
                                      />
                                      <InputField
                                        outline
                                        value={data?.supervisorTO?.email}
                                        label={"Email"}
                                        placeholder="abc@mail.com"
                                        type="email"
                                        showErr={true}
                                        basic
                                        style={{ fontSize: "16px" }}
                                        onChange={(v) =>
                                          setData({
                                            ...data,
                                            supervisorTO: {
                                              ...data?.supervisorTO,
                                              email: v,
                                            },
                                          })
                                        }
                                        required
                                        error={
                                          data?.supervisorTO?.email?.length ===
                                            0
                                            ? "Please enter Email"
                                            : ""
                                        }
                                        className={
                                          data?.supervisorTO?.email?.length ===
                                            0
                                            ? "mb-1"
                                            : "mb-2"
                                        }
                                        labelStyle={{ fontSize: "12px" }}
                                      />
                                    </Row>
                                  </div>
                                </div>
                                {step === 1 && (
                                  <Row className="d-flex justify-content-between align-items-center px-2">
                                    <div
                                      style={{
                                        border: "1px solid #ced4da",
                                        borderRadius: "0.5rem",
                                        width: "fit-content",
                                      }}
                                      className="col-6 my-3 d-flex flex-row"
                                    >
                                      <img
                                        src={`data:image/jpeg;base64,${imageURL}`}
                                        alt=""
                                        height={32}
                                        style={{ margin: "auto" }}
                                      />

                                      <div className=" ">
                                        <i
                                          id="captcha"
                                          onClick={() => getCaptcha()}
                                          style={{
                                            fontSize: "25px",
                                            cursor: "pointer",
                                          }}
                                          className="uil-redo rounded-right pe-2"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="ms-0 col-6 pe-0 "
                                      style={{
                                        minWidth: "180px",
                                      }}
                                    >
                                      <OtpInput
                                        value={data?.captchaQuery}
                                        onChange={(v) =>
                                          setData({
                                            ...data,
                                            supervisorTO: {
                                              ...data?.supervisorTO,
                                              captchaQuery: v,
                                            },
                                          })
                                        }
                                        error={
                                          data?.captchaQuery?.length === 0
                                            ? "Please enter Captcha"
                                            : ""
                                        }
                                        className="flex-1 mb-1"
                                      />
                                    </div>
                                  </Row>
                                )}

                                {step === 2 && (
                                  <SignupOtp
                                    onresendOTPClick={() => {
                                      getCaptcha();
                                      setStep(1);
                                    }}
                                    onChange={(v) =>
                                      setData({
                                        ...data,
                                        supervisorTO: {
                                          ...data?.supervisorTO,
                                          phoneOTPValue: v?.phoneOTPValue,
                                          emailOTPValue: v?.emailOTPValue,
                                        },
                                      })
                                    }
                                    getSignupOTP={getSignupOTP}
                                  />
                                )}
                              </Col>
                            </Row>
                          </div>
                        )}
                        {step === 0 && (
                          <div style={{ fontSize: "16px" }}>
                            <div
                              className="d-flex ps-5  mt-5"
                              style={{ marginLeft: "20px" }}
                            >
                              <div className="mt-3 me-3 ms-3 ps-1">
                                <input
                                  type="checkbox"
                                  checked={isTermsSelected}
                                  onClick={() => {
                                    setIsTermsSelected(!isTermsSelected);
                                  }}
                                />
                              </div>
                              <p className=" mt-3 text-center">
                                I Agree
                                <span
                                  className="m-1"
                                  style={{
                                    color: "#ff0000",
                                    cursor: "pointer",
                                  }}
                                >
                                  Terms & Conditions
                                </span>
                              </p>
                            </div>
                            <p className=" mt-3 text-center">
                              Already have account
                              <span
                                onClick={onClickLogin}
                                className="m-1"
                                style={{ color: "#ff0000", cursor: "pointer" }}
                              >
                                Login
                              </span>
                              now !
                            </p>
                          </div>
                        )}
                        {step >= 1 && (
                          <div className="account-pages mb-2 pt-sm-2 ">
                            <Container className="d-flex justify-content-center  w-100">
                              <Row className="align-items-center justify-content-center">
                                <Col className="">
                                  <div className="text-center ">
                                    <div className="">
                                      {step === 3 && data?.corpType == 1 && (
                                        <Step1
                                          onChange={(v) =>
                                            setData({
                                              ...data,
                                              corporateExtn: {
                                                ...data.corporateExtn,
                                                employeeCount: v?.employeeCount,
                                                serviceRequired:
                                                  v?.serviceRequired,
                                              },
                                            })
                                          }
                                        />
                                      )}

                                      {step === 3 && data?.corpType == 2 && (
                                        <Step4
                                          onChange={(v) =>
                                            setData({
                                              ...data,
                                              corporateExtn: {
                                                ...data.corporateExtn,
                                                vehicleTypeCount:
                                                  v?.vehicleTypeCount,
                                              },
                                            })
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        )}
                        {step >= 0 && (
                          <Row>
                            <div className=" mt-4 d-flex align-items-center justify-content-center ">
                              {step != 3 && (
                                <i
                                  onClick={goBack}
                                  className="bx border p-2 bg-light rounded-5 font-size-22 bx-left-arrow-alt"
                                  style={{
                                    marginRight: "20px",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                              <i
                                onClick={() => {
                                  goNext();
                                }}
                                className="bx border p-2 text-light bg-primary rounded-5 font-size-22  bx-right-arrow-alt"
                                style={{
                                  backgroundColor: "white",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </Row>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Signup;
