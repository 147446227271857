import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import {
  Checkbox,
  InputField,
  TimeSelect
} from "../../components";
import WeekdaySelector from "../../components/WeekDaySelect/WeekDaySelect";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import ShiftService from "./WorkShiftService";
import RemoveLink from "../../components/Buttons/RemoveLink";

const EditWorkShift = ({ editId, onChange = () => { }, hasErr }) => {

  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    openBookingTimes: [
      { startTime: '', endTime: '' }
    ],
    dropTimes: [],
    pickupTimes: [],
    weekDays: {
      su: true,
      mo: true,
      tu: true,
      we: true,
      th: true,
      fr: true,
      sa: true,
    },

  });


  const fetch = async () => {
    try {
      setLoading(true)
      let shift = await ShiftService.get(editId);
      setData(shift);
      setLoading(false)
    } catch (error) {
      showError(error);
      setLoading(false)
    }
  };

  useEffect(() => {
    setData({
      dropTimes: [],
      pickupTimes: [],
      openBookingTimes: [
        { startTime: '', endTime: '' }
      ],
      weekDays: {
        su: true,
        mo: true,
        tu: true,
        we: true,
        th: true,
        fr: true,
        sa: true,
      },
    });
    if (editId) fetch();
  }, [editId, ShiftService?.version]);


  useEffect(() => {
    onChange(data);
  }, [data]);

  const handleTimeChange = (index, field, value) => {
    const updatedOpenBookingTimes = [...data.openBookingTimes];
    if (!updatedOpenBookingTimes[index]) {
      updatedOpenBookingTimes[index] = {};
    }
    updatedOpenBookingTimes[index][field] = value;
    setData({ ...data, openBookingTimes: updatedOpenBookingTimes });
  };

  const handleAddTimeRange = () => {
    const updatedOpenBookingTimes = [...data.openBookingTimes, { startTime: '', endTime: '' }];
    setData({ ...data, openBookingTimes: updatedOpenBookingTimes });
  };
  const handleDeleteTimeRange = (index) => {
    const updatedOpenBookingTimes = [...data.openBookingTimes];
    updatedOpenBookingTimes.splice(index, 1);
    setData({ ...data, openBookingTimes: updatedOpenBookingTimes });
  };

  return (
    <React.Fragment>
      {loading ?
        <div className="d-flex mt-5 justify-content-center align-items-center  flex-1">
          <ThreeBounce size={50} color="pink" />
        </div>
        :
        (
          <Form
            className="p-3"
            style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
          >
            <Row className="mt-3 mb-4 ">
              <InputField
                className="col-sm-6"
                placeholder={t("Name")}
                value={data?.name}
                required={true}
                label={t("Name")}
                onChange={(v) => setData({ ...data, name: v })}
                error={data?.name?.length === 0 ? t("Please enter name") : ""}
                showErr={hasErr}
                type="text"
              />
            </Row>
            <Row>
              <Col className="col-sm-6">
                <TimeSelect
                  placeholder={t("Start Time")}
                  required={true}
                  label={t("Start Time")}
                  error={data?.startTime?.length === 0 ? t("Please enter Start Time") : ""}
                  showErr={hasErr}
                  value={data?.startTime}
                  onChange={(v) => {
                    setData({
                      ...data,
                      startTime: Number(v),
                    });
                  }}
                />
              </Col>
              <Col className="col-sm-6">
                <TimeSelect
                  required={true}
                  label={t("End Time")}
                  value={data?.endTime}
                  error={data?.endTime?.length === 0 ? t("Please enter End Time") : ""}
                  showErr={hasErr}
                  onChange={(v) => {
                    setData({
                      ...data,
                      endTime: Number(v),
                    });
                  }}
                />
              </Col>

            </Row>
            <Row className="mt-4 mb-4">
              <Col className="col-sm-6">
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '160px', fontSize: '12px', fontWeight: '500' }}>
                    {t("Weekdays")}
                    <span style={{ color: 'red' }}>*</span>

                  </div>
                  <div className="dowPicker">
                    <WeekdaySelector
                      data={data?.weekDays}
                      onChange={(v) => setData({ ...data, weekDays: v })}
                    />
                  </div>
                </div>
              </Col>
              <Col className="col-sm-3">
                <Checkbox
                  label={t("Fixed Adhoc Schedule")}
                  checked={data?.hasFixedAdhocSchedule == 1}
                  onClick={() =>
                    setData({
                      ...data,
                      hasFixedAdhocSchedule: !data?.hasFixedAdhocSchedule,
                    })
                  }
                />
              </Col>
              <Col className="col-sm-3">
                <Checkbox
                  label={t("Open Adhoc Schedule")}
                  checked={data?.hasOpenAdhocSchedule == 1}
                  onClick={() =>
                    setData({
                      ...data,
                      hasOpenAdhocSchedule: !data?.hasOpenAdhocSchedule,
                    })
                  }
                />
              </Col>

            </Row>
            <Row className="mt-4">
              <Button color="light"
                onClick={() => {
                  setData({
                    ...data,
                    dropTimes: [...data?.dropTimes, ''],
                  });
                }}
                style={{ maxWidth: "210px" }}
                className="d-flex h-36 me-3 align-items-center mb-2">
                <i className=" font-size-20 color-blue fas fa-plus me-2" />
                {t("Drop Times")}
              </Button>

            </Row>
            <div className="d-flex flex-wrap">
              {data?.dropTimes?.map((time, i) => (
                <div key={i} className="col-12 col-sm-6 col-md-3 mb-2">

                  {/* <div className="col-3 mb-2"> */}
                  <div className="d-flex  align-items-center" >
                    <TimeSelect
                      className=""
                      outline
                      value={time}
                      onChange={(v) => {
                        setData({
                          ...data,
                          dropTimes: data?.dropTimes.map((f, x) => (i === x ? Number(v) : f)),
                        });
                      }}

                    />
                    <RemoveLink
                      className="ms-1"
                      onClick={() => {
                        const updatedTimes = [...data?.dropTimes];
                        updatedTimes.splice(i, 1);
                        setData({
                          ...data,
                          dropTimes: updatedTimes,
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            <Row className="mt-4">
              <Button color="light"
                onClick={() => {
                  setData({
                    ...data,
                    pickupTimes: [...data.pickupTimes, ''],
                  });
                }}
                style={{ maxWidth: "210px" }}
                className="d-flex h-36 me-3 align-items-center mb-2">
                <i className=" font-size-20 color-blue fas fa-plus me-2" />
                {t("Pickup Times")}
              </Button>
            </Row>
            <div className="d-flex flex-wrap">
              {data?.pickupTimes?.map((time, i) => (
                <div key={i} className="col-12 col-sm-6 col-md-3 mb-2">

                  <div className="d-flex  align-items-center" >
                    <TimeSelect
                      className=""
                      outline
                      value={time}
                      onChange={(v) => {
                        setData({
                          ...data,
                          pickupTimes: data.pickupTimes.map((f, x) => (i === x ? Number(v) : f)),
                        });
                      }}
                    />
                    <RemoveLink
                      className="ms-1"
                      onClick={() => {
                        const updatedTimes = [...data.pickupTimes];
                        updatedTimes.splice(i, 1);
                        setData({
                          ...data,
                          pickupTimes: updatedTimes,
                        });
                      }}
                    />
                  </div>
                </div>

              ))}
            </div>
            <Row className="mt-4">
              <Button color="light" onClick={handleAddTimeRange} className="d-flex h-36 me-3 align-items-center mb-2"
                style={{ maxWidth: "210px" }}
              >
                <i className=" font-size-20 color-blue fas fa-plus me-2 " />
                {t("Open Booking Times")}
              </Button>

            </Row>

            <div>
              {data?.openBookingTimes?.map((time, index) => (
                <div key={index} className="mb-2">
                  <div className="d-flex justify-content-start align-items-center">
                    <label htmlFor={`startTime-${index}`}>{t("Start Time")}</label>
                    <TimeSelect
                      outline
                      className="ms-2"
                      value={time?.startTime}
                      onChange={(v) => handleTimeChange(index, 'startTime', v)}
                    />
                    <label htmlFor={`endTime-${index}`} className="ms-4">{t("End Time")}</label>

                    <TimeSelect
                      outline
                      className="ms-2"
                      value={time?.endTime}
                      onChange={(v) => handleTimeChange(index, 'endTime', v)}
                    />
                    <RemoveLink className="ms-1" onClick={() => handleDeleteTimeRange(index)} />
                  </div>
                </div>

              ))}
            </div>
          </Form>
        )
      }
    </React.Fragment >
  );
};

export default observer(EditWorkShift);
