import React, { useState } from 'react';
import { ModalComponent } from '../../../../../components';
import Flight from '../../../../Flight/Flight';
import { Button } from 'reactstrap';

const SendFlightsModal = ({ isModalVisible, handleClose, onSendFlights, customer_id }) => {

    const [selectedFlights,setSelectedFlights]=useState([]);
    return (
        <ModalComponent
            position={"top"}
            size={"large"}
            onToggle={handleClose}
            isOpen={isModalVisible}
        >
            <ModalComponent.Title>
                Flights
            </ModalComponent.Title>

            <ModalComponent.Body>
                <Flight
                    passengerId={customer_id}
                    insidePane
                    multiMode
                    onSelect={(v) => {
                        setSelectedFlights(v);
                    }}
                />
            </ModalComponent.Body>

            <ModalComponent.Footer>
                <Button className='primary-dark-bluish' onClick={()=>onSendFlights(selectedFlights)}>
                    Send
                </Button>
            </ModalComponent.Footer>
        </ModalComponent>
    );
};

export default SendFlightsModal;
