export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Header",
            accessor: "header",
            show: true,
        },
        {
            title: "Details",
            accessor: "details",
            show: true,
        },
        {
            title: "Status",
            accessor: "status",
            show: true,
            required: true,
            renderer: (dataObj) => {
                // return <div>
                //     {dataObj?.priority === 0 ? "Open" : (dataObj?.status === 1 ? "In Progress" : (dataObj?.status === 2 ? "Closed" : (dataObj?.status === 3 ? "Rejected" : "Client Input Required")))}
                // </div>;
                return <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -5 }}>
                    <div
                        style={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor:
                                dataObj?.status === 0 ? 'red' : (dataObj?.status == 1 ? 'orange' : (dataObj?.status == 2 ? 'green' : (dataObj?.status == 3 ? 'grey' : 'blue'))),
                            marginRight: '5px',

                        }}
                    ></div>
                    <span>
                        {dataObj?.status === 0 ? "Open" : (dataObj?.status === 1 ? "In Progress" : (dataObj?.status === 2 ? "Closed" : (dataObj?.status === 3 ? "Rejected" : "Client Input Required")))}
                    </span>
                </div>
            },

        },
        {
            title: "Priority",
            accessor: "priority",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    {dataObj?.priority === 1 ? "Low" : (dataObj?.priority === 2 ? "Medium" : "High")}
                </div>;
            },
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
        },
    ],
};
