import moment from 'moment';
import React from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import './DisplaySMS.scss';

const DisplaySMS = ({ sms, onBack = () => {} }) => {
    return (
        <div className="zr_communication_display sms">
  
            <div className="sms-header">
                <h2 className='d-flex align-items-center'>    <IoMdArrowBack onClick={onBack} className='me-2' size={20} />SMS</h2>
                <span className="sms-date">{moment(sms.sentAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
            </div>
   
            <div className="sms-body">
                <p>{sms.content}</p>
            </div>
        </div>
    );
};

export default DisplaySMS;
