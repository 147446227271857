export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/taxslab/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + "&page=" + page;
  },

  get: (id) => `/api/taxslab/${id}/get`,
  save: `/api/taxslab/save`,
  edit: (taxslabID) => `/api/taxslab/${taxslabID}/edit`,
  delete: (id) => `/api/taxslab/${id}/delete`,
  addToInvoice: (invId, taxSlabId) => `/api/invoice/${invId}/amend/tax/${taxSlabId}`
};

export const STRUCTURE = [
  {
    label: "Name",
    description: "description...",
    filterLabel: "TaxSlab Name",
    filterName: "name",
    type: "text",
  },
];
