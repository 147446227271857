import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Card, Form } from "reactstrap";
import { CardThreeBounce, Tab } from "../../components";
import SupplierFare from "../../components/dnd/forms/itinerary/components/SupplierFare";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import ItineraryService from "./ItineraryService";
import ItineraryDetail from "./module/itineraryDetail";
import ItineraryRate from "./module/itineraryRate";

const EditItineraries = ({
    editId,
    onChange = () => { },
    insidePane,
}) => {
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([{}]);
    const [step, setStep] = useState(1);

    const fetch = async () => {
        try {
            if (editId) {
                let itinerary = await ItineraryService.get(editId);
                setData(itinerary);
            } else {
                let tempData = {
                    highlights: ["Click To edit this highilight"],
                    persons : 1,
                    days : 2, 
                    nights : 2,
                    itineraryExtn: {
                        stays: [{}],
                        guides: [{}],
                        segments: [{ day: 1 }]
                    }
                };
                setData(tempData);
            }
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        onChange(data)
    }, [data]);

    useEffect(() => {
        setData(null)
        setLoading(true);
        fetch();
        setLoading(false)
    }, [editId]);

    return (
        <React.Fragment>
            {loading ?
                <CardThreeBounce /> :
                <Form
                    className="p-3 pt-0"
                    style={{ height: "100%", padding: "2rem", paddingTop: 0, margin: 0 }}
                >
                    {(!editId || insidePane) ? <>
                        <ItineraryDetail
                            data={data}
                            onChange={(v) => {
                                setData(v)
                            }}
                        /> </> :
                        <Tab
                            vertical={false}
                            left
                            outerClassName = " mt-0 pt-0 "
                            activeTab={step}
                            onChange={(data) => {
                                setStep(data);
                            }}
                            className="d-flex"
                        >
                            <div num={1} label={t("Details")} className="d-flex">
                                <ItineraryDetail
                                    data={data}
                                    onChange={(v) => {
                                        setData(v)
                                    }}
                                />
                            </div>


                            <div num={2} label={t("Rates")} className="d-flex">
                                {editId && (
                                    <div className="col-12 ">
                                     
                                                <ItineraryRate
                                                    itinerary_id={editId}
                                                />
                                         
                                    </div>
                                )}
                            </div>

                            <div num={3} label={t("Charges")} className="d-flex">
                                <div className="mt-3 row">
                                  
                                    {editId && (
                                        <div className="col-12 ">
                                            <Card className="pb-3 ">
                                                {/* <div style={{ backgroundColor: "#EBEBEB" }} className="h3 ps-3 py-2"> Supplier Charges</div> */}
                                                {editId && <div className="p-2 ">
                                                    <SupplierFare
                                                        itinerary_id={editId}
                                                    />
                                                </div>}
                                            </Card>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </Tab>}
                </Form>
            }
        </React.Fragment >
    );
};

export default observer(EditItineraries);
