import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import {
  Checkbox,
  CompanySelect,
  InputField,
  SelectField,
} from "../../../../../components";

export default function PaymentDetails({ data, setData }) {
  const [hasErr, setHasErr] = useState(false);
  useEffect(() => {
    console.log("payment gateway changed", data?.pgKind);
  }, [data]);

  const paymentType = [
    {
      label: "PAYTM",
      value: "PAYTM",
    },
    {
      label: "PAYU",
      value: "PAYU",
    },
    {
      label: "PAYPAL",
      value: "PAYPAL",
    },
    {
      label: "RAZORPAY",
      value: "RAZORPAY",
    },
    {
      label: "PAYUBIZ",
      value: "PAYUBIZ",
    },
    {
      label: "DPO",
      value: "DPO",
    },
    {
      label: "STANDARDBANK",
      value: "STANDARDBANK",
    },
  ];

  return (
    <div className="active">
      <Row className="mt-3 mb-3 ">
        <Col className="col-12 col-sm-6 mb-3">
          <InputField
            placeholder="Title"
            value={data?.title}
            required={true}
            label={"Title"}
            onChange={(v) =>
              setData({
                ...data,
                title: v,
              })
            }
            error={data?.name?.length === 0 ? "Please enter TItle" : ""}
            showErr={hasErr}

            type="text"
          />
        </Col>
        <Col className="col-12 col-sm-6">
          <CompanySelect
            client={data?.corp_id}
            value={data?.bu_id}
            onChange={(v) =>
              setData({
                ...data,
                bu_id: v.value,
              })
            }
            label="Business Unit"
          />
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col className="col-12 col-sm-6 mb-3">
          {data?._id ? (
            <InputField
              isDisabled={true}
              placeholder="Payment Gateway"
              value={data?.pgKind}
              required={true}
              label={"Payment Gateway"}
              showErr={hasErr}

              type="text"
            />
          ) : (
            <SelectField
              data={paymentType}
              value={data?.pgKind}
              onChange={(v) =>
                setData({
                  ...data,
                  pgKind: v.value,
                })
              }
              label={"Payment Gateway"}
            />
          )}
        </Col>
        <Col className="col-12 col-sm-6">
          <Checkbox
            label="Is Active"
            checked={data?.active}
            onClick={(v) =>
              setData({
                ...data,
                active: !data?.active,
              })
            }
          />
        </Col>
      </Row>
    </div>
  );
}
